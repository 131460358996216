import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useStore } from "react-redux";
import { useParams } from "react-router";
import datasource from "../../../../../assets/icons/Datasource.svg";
import noteIco from "../../../../../assets/images/noteIco.png";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { dictionaryEndpoint } from "../../../../../store/actions";
import { setSelectedPodList } from "../../../../../store/modules/common/podSelectionModalAction";
import {
  executeTransformerApi,
  saveTransformerNoteClick,
  setDataSourcePodDetails,
  setFlowsCurrenttransformer,
  setTransformerNames,
  showFlowsTransformdetails,
  updateFlowsElements,
} from "../../../../../store/modules/flows/flowsActions";
import Buttons from "../../../../Common/button/Buttons";
import {
  CLOSE,
  DESCRIPTION,
  DOMAIN_LARGE,
  EDIT,
  EDITOR,
  PERSONCREATED,
  PERSONEDIT,
  PLAY,
  PLUS,
  PROPERTIES,
  SAVE,
} from "../../../../Common/iconSource";
import PODSelection from "../../../../Common/PODSelection";
import { Literals } from "../../../common/literals";
import SourceTypeImage from "../../../common/sourceTypeImage";
import DatasetOutputNameInput from "../commonFunctions/useDatasetNameInput";

import EditorIndex from "./editor/EditorIndex";
import SelectDataSource from "./SelectDataSource";
import { getObjectLength, showSimpleLoading } from "../../../common/helperFunctions";
import { ICON_WARNING } from "../../../../Common/newIconSource";

const { TabPane } = Tabs;

const LeftPaneHeader = ({ toggleModal, showEditPod, showResetColumns }) => {
  return (
    <>
      <div className="d-flex justify-content-between padding-2 py-1 custom-border-bottom">
        <div className="flow-property-heading d-flex align-items-center">
          <PROPERTIES />
          <span className="paragraph ml-2">Properties</span>
          {showResetColumns ? <small className="error  ml-3"><ICON_WARNING color="red" width="12" height="12" /> Source Column changes found, please click on execute to reflect the changes. </small> : null}
        </div>
        <div className="d-flex">
          {!showEditPod ? (
            <Buttons
              props={{
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                buttonEvent: () => {
                  toggleModal(true);
                },
                ImgSrc: () => <PLUS />,
                isDisable: window.location.href.includes("viewFlows")
                  ? true
                  : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : (
            <Buttons
              props={{
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                buttonEvent: () => {
                  toggleModal(true);
                },
                ImgSrc: () => <EDIT />,
                isDisable: window.location.href.includes("viewFlows")
                  ? true
                  : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

const Leftpane = ({
  toggleModal,
  showEditPod,
  data,
  showResetColumns
}) => {
  let podDetails = data;

  return (
    <>
      <div className="custom-border-right h-100 position-relative">
        <LeftPaneHeader
          toggleModal={toggleModal}
          showEditPod={showEditPod}
          showResetColumns={showResetColumns}
        />
        {data && getObjectLength(data) && (
          <>
            <div className="data-source-props-winwrap">
              <div>
                <div>
                  <div className="d-flex custom-border-bottom p-2">
                    <SourceTypeImage
                      align="center"
                      dataName={
                        (podDetails &&
                          Object.keys(podDetails).length > 0 &&
                          podDetails.source_type) ||
                        ""
                      }
                    />
                    <div>
                      <p className="label">POD Name</p>
                      <p className="paragraph two-line-ellipsis custom-ellipsis text-uppercase">
                        {podDetails &&
                          Object.keys(podDetails).length > 0 &&
                          podDetails.table_name}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex custom-border-bottom padding-2">
                    <span className="dsrc-lf-icon">
                      <DOMAIN_LARGE />
                    </span>
                    <div>
                      <p className="label">Domain Name</p>
                      <p className="paragraph two-line-ellipsis custom-ellipsis">
                        {podDetails &&
                          Object.keys(podDetails).length > 0 &&
                          podDetails?.data_domain_detail?.data_domain_name?.toUpperCase()}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex custom-border-bottom padding-2">
                    <span className="dsrc-lf-icon">
                      <DESCRIPTION />
                    </span>
                    <div>
                      <p className="label">Description</p>
                      <p className="paragraph two-line-ellipsis custom-ellipsis">
                        {podDetails &&
                          Object.keys(podDetails).length > 0 &&
                          podDetails.pod_description}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex custom-border-bottom padding-2">
                    <span className="dsrc-lf-icon">
                      <PERSONCREATED />
                    </span>
                    <div>
                      <p className="label">Created By</p>
                      <p className="paragraph">
                        {podDetails &&
                          Object.keys(podDetails).length > 0 &&
                          podDetails.created_by_user?.username}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex custom-border-bottom padding-2">
                    <span className="dsrc-lf-icon">
                      <PERSONEDIT />
                    </span>
                    <div>
                      <p className="label">Updated By</p>
                      <p className="paragraph">
                        {podDetails &&
                          Object.keys(podDetails).length > 0 &&
                          podDetails?.updated_by_user?.username}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex custom-border-bottom padding-2">
                    <span className="dsrc-lf-icon mr-2">
                      <img src={noteIco} height={15} width={15} alt="Note" />{" "}
                    </span>
                    <div>
                      <p className="paragraph" style={{ color: "#FFA501" }}>
                        To avoid processing unnecessary data, please make sure
                        to apply the relevant filter after the Source
                        Transformer, before joining or further processing
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      </div>
    </>
  );
};

const DataSource = ({
  flowsElements,
  transformerNameList,
  currentTransformer,
  isExecutePermission,
  ...props
}) => {
  const [modalState, setModalState] = useState(false);
  const [tempDictionaryTableData, setTempDictionaryTableData] = useState(null);
  const [activeKey, setActiveKey] = useState("properties");
  const [toggleDefaultView, setToggleDefaultView] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [showResetColumns, setshowResetColumns] = useState(false)

  const [disableExecute, setdisableExecute] = useState(false);
  const [showLoading, setshowLoading] = useState(false);

  const [showEditPod, setshowEditPod] = useState(false);
  const [transformerName, settransformerName] = useState("");
  const dispatch = useDispatch();

  const { flowId } = useParams();


  useEffect(() => {
    if (flowsElements && flowsElements.length) {
      let currentTransformerContent = flowsElements.find(
        (item) => item.id === currentTransformer.id
      )?.content;

      if (
        currentTransformerContent &&
        Object.keys(currentTransformerContent).length !== 0
      ) {

        setToggleDefaultView(false);
        setshowEditPod(true);
        getDictionaryTableData(currentTransformerContent?.table_id, 'alreadyExistPodId', '', 'checkColumns')

      } else {
        setshowEditPod(false);
        setToggleDefaultView(true);
        setTempDictionaryTableData(null);
      }

    }
  }, [currentTransformer]);

  //flowsElement




  useEffect(() => {
    let currentDataSource = flowsElements.find(
      (item) => item.id === currentTransformer.id
    );
    if (
      currentDataSource &&
      currentDataSource?.content?.outcomeColumns?.length
    ) {
      setdisableExecute(false);
    } else {
      setdisableExecute(true);
    }
    return () => {
      console.log('unmount')
      // generateDag()
    }
  }, []);





  const onChange = (activeKey) => {
    setActiveKey(activeKey);
    setEditMode(false);
  };

  const [podId, setPodId] = useState(null);

  const toggleModal = (status) => {
    setModalState(status);
  };

  const onRowSelection = (record, rowIndex) => {
    setPodId(record.key);
  };

  const save = (selectedPODsList) => {
    store.dispatch(setSelectedPodList(selectedPODsList));
    if (podId !== null) {
      setdisableExecute(false);
    }

    getDictionaryTableData(podId, '', '', 'checkColumns');
    if (toggleDefaultView) {
      setToggleDefaultView(false);
    }
    setModalState(false);
  };

  const store = useStore();


  const executeTransformer = async () => {

    if (tempDictionaryTableData && tempDictionaryTableData?.table_id) {
      let obj = {};
      obj[currentTransformer.id] = {
        podId: tempDictionaryTableData?.table_id,
        podName: tempDictionaryTableData?.table_name,
        dataDomain:
          tempDictionaryTableData?.data_domain_detail?.data_domain_name,
      };
      let temp = store.getState().FlowsReducer.dataSourcePodDetails ?? [];
      let index =
        temp &&
        temp.length &&
        temp.findIndex((ele) => Object.keys(ele)[0] === currentTransformer.id);
      if (index > -1) {
        temp[index] = obj;
      } else {
        temp.push(obj);
      }
      setPodId(tempDictionaryTableData?.table_id);
      dispatch(setDataSourcePodDetails(temp));
    }

    let payload = {
      flow_body: {
        Id: `${tempDictionaryTableData?.data_domain_detail?.data_domain_name}.${tempDictionaryTableData?.table_name}`,
        currentTransformerID: currentTransformer.id,
        NodeType: `${currentTransformer.type}`,
        Args: [
          {
            Name: "data_domain",
            Value: `${tempDictionaryTableData?.data_domain_detail?.data_domain_name}`,
          },
          {
            Name: "pod_name",
            Value: `${tempDictionaryTableData?.table_name}`,
          },
          {
            Name: "pod_id",
            Value: `${tempDictionaryTableData?.table_id}`,
          },
        ],
      },
    };


    let finalFlowsElements = flowsElements.map((item) => {
      if (item.id === currentTransformer.id) {
        item["transformerName"] = `${tempDictionaryTableData?.data_domain_detail?.data_domain_name}.${tempDictionaryTableData?.table_name}`;
        item.content.executed_DAG = payload.flow_body;
      }
      return item;
    });
    settransformerName(`${tempDictionaryTableData?.data_domain_detail?.data_domain_name}.${tempDictionaryTableData?.table_name}`);

    dispatch(updateFlowsElements([...finalFlowsElements]));

    setdisableExecute(true);

    try {
      let response = await dispatch(
        executeTransformerApi(flowId, payload, currentTransformer.id)
      );

      if (response.status === "success") {
        setdisableExecute(false);
        // transformerNameList[currentTransformer.id] =
        //   tempDictionaryTableData?.table_id;
        // dispatch(setTransformerNames({ ...transformerNameList }));




        getDictionaryTableData(tempDictionaryTableData?.table_id, 'alreadyExistPodId', 'executeClick', payload.flow_body);
      }
    } catch (error) {
      setdisableExecute(false);
    }
  };

  const getDictionaryTableData = async (...args) => {
    let response;
    setshowLoading(true)
    setdisableExecute(true)

    try {
      response = await store.dispatch(dictionaryEndpoint(args[0], false, false));

      let tempContent = currentTransformer?.content;

      let isTransformerNameExist = false;

      if (response?.data?.column_detail?.length > 0) {
        let isPodIdPresent = flowsElements?.findIndex(item => item.id !== currentTransformer?.id && item?.content?.table_id === response?.data?.table_id)
        if (isPodIdPresent > -1) {
          isTransformerNameExist = true
        }
        setshowEditPod(true);


      } else {
        setshowEditPod(false);
      }

      if( response?.data?.table_id){
        setdisableExecute(false)

        setshowLoading(false)
      }
      

      if (
        !isTransformerNameExist  && response?.data?.table_id
      ) {

        if (args[2] === 'executeClick' ) {
          tempContent.table_id = response?.data?.table_id;
          tempContent.executed_DAG = getObjectLength(args[3]) ? args[3] : undefined
          tempContent.outcomeColumns = response?.data?.column_detail.map((itm) => {
            return {
              id: itm.column_id,
              Type: itm.column_datatype,
              Name: itm.column_name,
              checked: false,
            };
          });
          let newFlowsElements = flowsElements.map((item) => {
            return item.id === currentTransformer.id
              ? { ...item, content: tempContent }
              : item;
          });
          dispatch(updateFlowsElements([...newFlowsElements]));

        }

        if (args[1] === 'alreadyExistPodId') {
          settransformerName(`${response?.data?.data_domain_detail?.data_domain_name}.${response?.data?.table_name}`);

        }
        if (args[3] === 'checkColumns' && tempContent.outcomeColumns?.length) {

          let bussinessTerm = response?.data?.column_detail.map((ele) => ele.column_name);
          let isNotExist = false;
          let inputColumns = response?.data?.column_detail;
          if (inputColumns && inputColumns.length) {
            tempContent.outcomeColumns.forEach((item, index) => {

              if (!bussinessTerm.includes(item.Name)) {
                isNotExist = true;
              }
              if (inputColumns?.length === tempContent.outcomeColumns.length && (item.Type !== inputColumns[index].column_datatype || item.Name !== inputColumns[index].column_name )) {
                isNotExist = true;

              }

            });
            if (inputColumns?.length !== tempContent.outcomeColumns.length) {
              isNotExist = true;
            }
          }

          if (isNotExist) {
            setshowResetColumns(true)
          }
        }

        let obj = {};
        obj[currentTransformer.id] = {
          podId: response?.data?.table_id,
          podName: response?.data?.table_name,
          dataDomain:
            response?.data?.data_domain_detail?.data_domain_name,
        };
        let temp = store.getState().FlowsReducer.dataSourcePodDetails ?? [];
        let index =
          temp &&
          temp.length &&
          temp.findIndex((ele) => Object.keys(ele)[0] === currentTransformer.id);
        if (index > -1) {
          temp[index] = obj;
        } else {
          temp.push(obj);
        }
        setPodId(response?.data?.table_id);
        setdisableExecute(false)

        dispatch(setDataSourcePodDetails(temp));
        setTempDictionaryTableData(response?.data);


      } else {
        emitToastNotification(
          "error",
          Literals.DATA_SOURCE_ALREADY_EXIST_PLEASE_SELECT_OTHER_DATASOURCE
        );
        setshowLoading(false)
        setdisableExecute(false)


      }
    } catch (error) {
      setshowLoading(false);
      setshowEditPod(true)
      setdisableExecute(false)
    }
  };

  const closeLeftSideContent = () => {
    dispatch(setFlowsCurrenttransformer({}));
    dispatch(showFlowsTransformdetails(false));
  };

  return (
    <>
      <div className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
        <div className="d-flex align-items-center">
          <img src={datasource} alt={`datasource`} />
          <h3 className="subtitle">Datasource</h3>
          <Tabs
            onChange={onChange}
            activeKey={activeKey}
            className="custom-tab tab-with-icon"
          >
            <TabPane tab={<PROPERTIES />} key="properties"></TabPane>
            {/* <TabPane tab={<FILTER />} key="filter">
                        </TabPane> */}
            <TabPane tab={<EDITOR />} key="editor"></TabPane>
          </Tabs>
        </div>

        <div className="d-flex align-items-center">
          <DatasetOutputNameInput
            setTransformerName={() => { }}
            isdisabled={true}
            transformerName={transformerName}
          />

          <Buttons
            props={{
              buttonText: "",
              buttonClassName:
                "custom-btn-outline custom-btn btn-with-icon mr-2",
              buttonEvent: () => {
                activeKey !== "editor" || (activeKey === "editor" && !editMode)
                  ? closeLeftSideContent()
                  : setEditMode(false);
                onChange("properties");
              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_SECONDARY,
            }}
          />

          {activeKey === "editor" ? (
            <Buttons
              props={{
                buttonText: !editMode ? "Edit" : "Save",
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                buttonEvent: !editMode
                  ? () => {
                    setEditMode(true);
                    dispatch(saveTransformerNoteClick(false));
                  }
                  : () => {
                    dispatch(saveTransformerNoteClick(true));
                  },
                ImgSrc:
                  activeKey === "editor" && !editMode ? () => <EDIT /> : () => <SAVE />,
                isDisable: window.location.href.includes("viewFlows")
                  ? true
                  : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
          {activeKey !== "editor" ? (
            <Buttons
              props={{
                buttonText: "Execute",
                buttonClassName: showResetColumns ? "execute-btn-gradient custom-btn btn-with-text" : "custom-btn-primary custom-btn btn-with-text",
                buttonEvent:
                  activeKey === "editor"
                    ? () => {
                      setEditMode(true);
                    }
                    : () => {
                      setshowResetColumns(false)
                      executeTransformer();
                    },
                ImgSrc:
                  activeKey === "editor" ? () => <EDIT /> : () => <PLAY />,
                isDisable: !isExecutePermission || (disableExecute ? true : false),
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
        </div>
      </div>
      {activeKey === "properties" && (
        <>
          {toggleDefaultView && (
            <>
              <div className="d-flex bg-white justify-content-between custom-border-bottom padding-2">
                <div className="d-flex align-items-center">
                  <PROPERTIES />
                  <p className="paragraph mb-0 ml-2">Properties</p>

                </div>
                <div className="d-flex flex-row">

                  {!showEditPod ? (
                    <Buttons
                      props={{
                        buttonText: "POD",
                        buttonClassName:
                          "custom-btn-primary custom-btn btn-with-text",
                        buttonEvent: () => {
                          toggleModal(true);
                        },
                        ImgSrc: () => <PLUS />,
                        isDisable: window.location.href.includes("viewFlows")
                          ? true
                          : false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                      }}
                    />
                  ) : (
                    <Buttons
                      props={{
                        buttonText: "POD",
                        buttonClassName:
                          "custom-btn-primary custom-btn btn-with-text",
                        buttonEvent: () => {
                          toggleModal(true);
                        },
                        ImgSrc: () => <EDIT />,
                        isDisable: window.location.href.includes("viewFlows")
                          ? true
                          : false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="no-datasource">
                <SelectDataSource />
              </div>
              <PODSelection
                isMultiSelection={false}
                save={save}
                podId={podId}
                openModal={modalState}
                onClose={() => {
                  toggleModal(false);
                }}
                onRowSelection={onRowSelection}
              />
            </>
          )}
          {!toggleDefaultView && (
            <div className="bg-white section-with-drag flow-with-drag">
              <PODSelection
                isMultiSelection={false}
                save={save}
                podId={podId}
                openModal={modalState}
                onClose={toggleModal}
                onRowSelection={onRowSelection}
              />
              {showLoading ? showSimpleLoading('Please wait source details loading...') : <Leftpane
                toggleModal={toggleModal}
                data={tempDictionaryTableData}
                showEditPod={showEditPod}
                showResetColumns={showResetColumns}
              />}

            </div>
          )}
        </>
      )}

      {activeKey === "editor" && (
        <EditorIndex editMode={editMode} setEditMode={setEditMode} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    transformerNameList: state.FlowsReducer.transformerName,
    flowId: state.FlowsReducer.id,
  };
};
export default connect(mapStateToProps)(DataSource);
