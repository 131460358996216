import React, { Fragment } from 'react';
import { LARGEUSERGROUP } from '../../../Common/iconSource';

const CreateUserGroupMsg = ({ forPage }) => {

    return (
        <div className="empty-domain">
             <div><LARGEUSERGROUP/></div>
            {forPage === "createDataDomain" ?
                <h2 className='head'>Create a data domain to add usergroups</h2> :
                forPage === "createUserGroup" ?
                <Fragment>
                    <h2 className='head'>No Usergroup Added</h2>
                    <p className='para'>Click on the '+ Add' button to add Usergroup</p>
                    </Fragment>
                     : null
            }

        </div>
    )
};

export default CreateUserGroupMsg;