import React, { useState } from "react";
import Buttons from "../../../../Common/button/Buttons";
import { Literals } from "../../../common/literals";
import { InputNumber } from "antd";

function SampleRows({ rowCount, setrowCount, setToggleValue, toggleValue, isDisabled }) {

  const toggleBtnOption = [
    { label: "123 Count", value: "count", disabled: isDisabled },
    { label: "% Percentage", value: "percentage", disabled: isDisabled },
    // { label: 'Orange', value: 'Orange', disabled: true },
  ];
  const setPageView = (view) => {
    setToggleValue(view);
    setrowCount('')
  };
  return (
    <div className="d-flex align-items-end py-2">
      <div className="toggle-btn-with-text">
        <p className="label">sample rows</p>
        <Buttons
          props={{
            buttonText: "",
            toggleBtnOption: toggleBtnOption,
            toggleBtnValue: toggleValue,
            buttonClassName: "",
            buttonEvent: setPageView,
            ImgSrc: () => <></>,
            isDisable: isDisabled,
            buttonType: Literals.BTN_TOGGLE,
          }}
        />
      </div>

      <input
        type="number"
        className="custom-input-field"
        placeholder="Enter count"
        min={0}

        step={10}
        disabled={isDisabled}
        max={toggleValue === 'percentage' ? "100" : ''}
        value={rowCount}
        onChange={(e) => { toggleValue === 'percentage' ? e.target.value < 100 && e.target.value >= 0 ? setrowCount(e.target.value) : setrowCount(100) : e.target.value >= 0 ? setrowCount(e.target.value) : setrowCount(0) }}
      />
    </div>
  );
}

export default SampleRows;
