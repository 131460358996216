
import React, { useEffect, useState } from 'react';
import { EDIT, EXITFULLSCREEN, FULLSCREEN } from '../../../../Common/iconSource';
import { Literals } from "../../../common/literals";

import Buttons from '../../../../Common/button/Buttons';
import OutputDataSet from './OutputDataSet';

import { connect, useSelector, useStore } from 'react-redux';

import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { fetchPreviewTable } from '../../../../../store/modules/common/VirtualDataTable/action';
import { executeTransformerApi, saveFlow, setExecutedTransformerPayload, setTransformerNames, showJupyterNoteBook, updateFlowsElements } from '../../../../../store/modules/flows/NewFlowsRedux/flowsActions';
import { CreateNotebook, LoginNoteBook, resetScriptRedux, saveJupyterNotebook, saveNoteBookDone, setScriptCommonPaylod, setScriptOutputDataset, setScriptPythonCode } from '../../../../../store/modules/flows/NewFlowsRedux/scriptTransformer/scriptAction';
import { getObjectLength } from '../../../common/helperFunctions';

import { SCRIPTDEFAULTVIEW } from './ScriptDefaultInfography';
import { checkOutcomeColumnPresent, getInputFrame, getInputTransformerDetails } from '../commonFunctions/FlowsHelperFunction';
import { cloneDeep } from 'lodash';
import { setLoadingStatus } from '../../../../../store/modules/common/loadingActions';
import NotebookInfography, { createNotebookInfoImg } from './NotebookInfography';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';

const ScriptIndex = (props) => {
    const { flowsElements, setshowOverlay, runClick, setshowCloseWarning, currentTransformer, transformerNameList, isExecutePermission, setisrunDisabled, setupdatedFlowRedux, updatedFlowRedux, isJupyterPermission } = props
    const { flowId } = useParams()


    const store = useStore();
    const [toggleDefaultView, setToggleDefaultView] = useState(false)
    const [expandTable, setexpandTable] = useState(false)
    const [showApplyButton, setshowApplyButton] = useState(false)
    const [showNotebookError, setshowNotebookError] = useState(false)


    const [pythonCode, setpythonCode] = useState('')
    const [additional_settings, setAdditionalSettings] = useState({})


    const setOutputDataset = (outputDataSetList) => {
        let Frame = [];


        if (outputDataSetList.length && currentTransformer.id) {

            // if (validateValue) {
            let transformerNameList = store.getState().FlowsReducer.transformerName;
            let isNameAlreadyExist = checkTransformerNameExist(outputDataSetList)

            if (!isNameAlreadyExist && outputDataSetList.length) {


                Frame = store.dispatch(getInputFrame())
                let outputDataSetValue = outputDataSetList.map((ele) => ele.value);

                let payload = {
                    format: 'HTML',
                    task_name: currentTransformer.transformerName
                }

                let finalFlowsElements = cloneDeep(flowsElements)
                let nodes = finalFlowsElements?.nodes.map(item => {
                    if (item.id === currentTransformer.id) {
                        item.paired = outputDataSetList.map((ele) => ele.id)
                        item.content['additional_settings'] = additional_settings
                        item.content['outputdatasetList'] = outputDataSetList;
                        item['scriptOutputDataset'] = outputDataSetList
                    }
                    return item
                })
                finalFlowsElements['nodes'] = nodes
                store.dispatch(updateFlowsElements(finalFlowsElements));
                // setupdatedFlowRedux(finalFlowsElements)
                store.dispatch(setScriptOutputDataset(outputDataSetList))

                store.dispatch(saveFlow(flowId, false, false, undefined)).then((saveFlowresp) => {
                    if (saveFlowresp?.status === 'success') {
                        store.dispatch(CreateNotebook(flowId, payload, false)).then((resp) => {
                            if (resp && resp.data && resp.data.code) {
                                setshowCloseWarning(false)
                                setshowApplyButton(true)

                                store.dispatch(saveNoteBookDone(false))

                                let element = document.querySelector("#html-content");


                                let temp = resp.data.code;


                                outputDataSetValue.forEach((item, i) => {
                                    transformerNameList[outputDataSetList[i]?.id] = outputDataSetList[i]?.value
                                })

                                store.dispatch(setTransformerNames(transformerNameList))


                                element.innerHTML = temp;
                                setpythonCode(temp)



                            }

                        }).catch((e) => {
                            let finalFlowsElements = cloneDeep(flowsElements)
                            let nodes = finalFlowsElements?.nodes.map(item => {
                                if (item.id === currentTransformer.id) {

                                    item.content['additional_settings'] = additional_settings
                                    item.content['outputdatasetList'] = outputDataSetList;
                                    item['scriptOutputDataset'] = outputDataSetList
                                }
                                return item
                            })
                            finalFlowsElements['nodes'] = nodes
                            // setupdatedFlowRedux(finalFlowsElements)
                            store.dispatch(updateFlowsElements(finalFlowsElements))

                        })

                    }
                }).catch((err) => {
                    console.log('save flow api')
                })

            }
            // }

        }

    }

    const applyNewOuputDataSet = (outputDataSetList) => {
        // setshowCloseWarning(true)

        if (outputDataSetList.length) {
            let isNameAlreadyExist = checkTransformerNameExist(outputDataSetList, true)
            if (!isNameAlreadyExist) {
                let transformerNameList = store.getState().Lab.transformerName;

                outputDataSetList.forEach((ele) => {
                    transformerNameList[ele.id] = ele.value
                })

                store.dispatch(setScriptOutputDataset(outputDataSetList))
                let finalFlowsElements = cloneDeep(flowsElements)
                let nodes = finalFlowsElements?.nodes.map(item => {
                    if (item.id === currentTransformer.id) {
                        item.paired = outputDataSetList.map((ele) => ele.id)
                        item.content['additional_settings'] = additional_settings
                        item.content['outputdatasetList'] = outputDataSetList;
                        item.content['pythonCode'] = '' //resp.data.code

                    }
                    return item
                })
                finalFlowsElements['nodes'] = nodes
                // setupdatedFlowRedux(finalFlowsElements)
                store.dispatch(updateFlowsElements(finalFlowsElements))
                setshowCloseWarning(false)
                emitToastNotification('success', 'Changes applied successfully');
                store.dispatch(saveFlow(flowId, false, false, undefined)).then((saveFlowresp) => {

                }).catch((err) => {
                    console.log('save flow api')
                })
            }
        }

    }

    const openJupyterNotebook = () => {

        // setshowCloseWarning(true)

        let outputDataSetList = store.getState().LabTransformer.Script.scriptOutputDataset;

        if (outputDataSetList.length && currentTransformer.id) {

            store.dispatch(LoginNoteBook(flowId, { task_name: currentTransformer.transformerName }, true)).then((resp) => {
                if (resp && resp.data?.script_url) {
                    let finalFlowsElements = cloneDeep(flowsElements)
                    let nodes = finalFlowsElements?.nodes.map(item => {
                        if (item.id === currentTransformer.id) {
                            item.paired = outputDataSetList.map((ele) => ele.id)
                            item.content['additional_settings'] = additional_settings
                            item.content['outputdatasetList'] = outputDataSetList;
                            item.content['pythonCode'] = '' //resp.data.code

                        }
                        return item
                    })
                    finalFlowsElements['nodes'] = nodes
                    // setupdatedFlowRedux(finalFlowsElements)
                    store.dispatch(updateFlowsElements(finalFlowsElements))
                    store.dispatch(showJupyterNoteBook(true))
                }
            }).catch((e) => {
                emitToastNotification('error', e.message)
                store.dispatch(setLoadingStatus(false));

            })
        }

    }


    const checkTransformerNameExist = (outputDatasetArray, isapplyNewNode = false) => {
        let key = 'value'

        let outputDataSetValue = outputDatasetArray.map((ele) => ele.value)
        let validateValue = false
        if (outputDataSetValue?.length) {
            validateValue = outputDataSetValue.every((el) => {
                if (typeof el === 'string') {
                    return el?.trim()?.length > 0
                }
                else if (typeof el === 'object') {
                    return el[0]?.trim().length > 0 && el[1]?.trim().length > 0
                }

            })
            if (!validateValue) { // if empty field found then will give an warning if empty field found then will not allow to call create notebook api
                emitToastNotification('error', 'Empty output dataset found, please provide name.');
                return true;
            }

        }

        let uniqueDatasetArray = [...new Map(outputDatasetArray.map(item =>
            [item[key], item])).values()];
        let isExist = false


        if (uniqueDatasetArray?.length !== outputDatasetArray.length) {
            let tempId = uniqueDatasetArray.map((item) => item?.id)

            let findDuplicateValue = outputDatasetArray.find((item) => !tempId.includes(item?.id))?.value
            if (findDuplicateValue) {
                isExist = true
                emitToastNotification('error', `"${findDuplicateValue}" A transformer with this name already exists. Please rename the transformer.`
                )
            }
            return isExist
        } else {
            isExist = false

            let transformerNameList = store.getState().Lab.transformerName;
            if (isapplyNewNode) {
                for (const key in transformerNameList) {
                    const element = transformerNameList[key];
                    let findIndex = uniqueDatasetArray.findIndex((item) => item?.value === element && item?.id === key)
                    if (findIndex > -1) {
                        uniqueDatasetArray.splice(findIndex, 1)
                    }
                }
            }
            let tempValues = uniqueDatasetArray.map((item) => item.value);

            if (tempValues && tempValues.length) {
                for (const key in transformerNameList) {
                    const element = transformerNameList[key];
                    if (tempValues.includes(element)) {
                        emitToastNotification('error', `"${element}" A transformer with this name already exists. Please rename the transformer.`)

                        isExist = true
                    }
                }
                return isExist

            }

        }

    }


    useEffect(() => {
        const getColumn = async () => {
            store.dispatch(resetScriptRedux())
            setpythonCode('')
            let newcurrentTransformer = {}
            if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

                newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
            } else {
                newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

            }
            let currentScriptContent = newcurrentTransformer?.content


            if (currentScriptContent && Object.keys(currentScriptContent)?.length !== 0) {

                if (currentScriptContent?.additional_settings && getObjectLength(currentScriptContent?.additional_settings)) {
                    let additional_settings = cloneDeep(currentScriptContent?.additional_settings)
                    setAdditionalSettings({

                        configuration: additional_settings?.configuration ?? null,

                        user_libs: additional_settings?.user_libs ?? '',

                        cluster_id: additional_settings?.cluster_id ?? null


                    })
                }
                setshowApplyButton(true)
                if (currentScriptContent[`outputdatasetList`] && currentScriptContent[`outputdatasetList`].length) {
                    store.dispatch(setScriptOutputDataset(currentScriptContent[`outputdatasetList`]))

                }

                getUpdatedCode();


            } else {
                setshowApplyButton(false)

                store.dispatch(resetScriptRedux())
            }




            let InputTransformer = store.dispatch(getInputTransformerDetails(currentTransformer, flowsElements))

            let inputColumns = [];
            let showOverlayflag = false;
            let column = [];
            if (InputTransformer && InputTransformer?.length) {
                // InputTransformer?.forEach((item) => {
                //     inputColumns = [...inputColumns, ...item?.content?.outcomeColumns]
                // })

                for (let i = 0; i < InputTransformer.length; i++) {
                    let ele = InputTransformer[i];
                    inputColumns = await store.dispatch(checkOutcomeColumnPresent(ele));
                    if (inputColumns?.length) {
                        column = [...column, ...inputColumns]
                    } else {
                        showOverlayflag = true;

                    }

                }
                if (showOverlayflag) {
                    setshowOverlay(true)
                } else {
                    setshowOverlay(false)
                }
                if (column?.length) {

                    setToggleDefaultView(false)
                }



            } else if (!InputTransformer?.length) {
                setToggleDefaultView(true);

            }
        }
        getColumn();



    }, [])


    const getUpdatedCode = () => {

        // if (currentTransformer?.content?.executed_DAG && Object.keys(currentTransformer?.content?.executed_DAG).length > 0) {
        if (runClick) {
            setTimeout(() => {
                let newcurrentTransformer = flowsElements?.nodes.find(item => item.id === currentTransformer.id)
                let commonPayload = {}
                commonPayload['format'] = "HTML";
                commonPayload['task_name'] = newcurrentTransformer.transformerName;
                // commonPayload['flow_body'] = currentTransformer?.content?.executed_DAG
                store.dispatch(saveJupyterNotebook(flowId, commonPayload, false)).then((resp) => {
                    if (resp && resp.status === 'success') {
                        store.dispatch(setLoadingStatus(false))
                        setpythonCode(resp.data.code)
                        setshowNotebookError(false)

                        // store.dispatch(setScriptPythonCode({ code: resp.data.code, id: currentTransformer.id }))

                    }
                }).catch(() => {
                    setshowNotebookError(true)
                })

            }, 3000);
        } else {
            let newcurrentTransformer = flowsElements?.nodes.find(item => item.id === currentTransformer.id)
            let commonPayload = {}
            commonPayload['format'] = "HTML";
            commonPayload['task_name'] = newcurrentTransformer.transformerName;
            // commonPayload['flow_body'] = currentTransformer?.content?.executed_DAG
            store.dispatch(saveJupyterNotebook(flowId, commonPayload, false)).then((resp) => {
                if (resp && resp.status === 'success') {
                    store.dispatch(setLoadingStatus(false))
                    setpythonCode(resp.data.code)
                    setshowNotebookError(false)

                    // store.dispatch(setScriptPythonCode({ code: resp.data.code, id: currentTransformer.id }))

                }
            }).catch(() => {
                setshowNotebookError(true)
            })
        }

        // } else {
        //     setpythonCode('')
        // }

    }

    const loader = ` <div
            class='clarista-loader h-100 d-flex align-items-center justify-content-center'
        >
            <div class="loader-circle-2 d-flex align-items-center justify-content-center">
                <svg
                    className="blink2"
                    width="49"
                    height="30"
                    viewBox="0 0 29 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.0504 19.7771H26.5824C24.7842 24.3164 20.5021 27.2275 14.9357 27.2275C13.2219 27.2275 11.6425 26.935 10.2148 26.4117C13.4066 25.2464 15.8149 22.8962 17.0504 19.7771Z"
                        fill="#275A89"
                    />
                    <path
                        d="M10.2148 2.52444C11.6425 2.00109 13.2219 1.70859 14.9357 1.70859C20.5021 1.70859 24.7842 4.61976 26.5824 9.1582H17.0504C15.8149 6.03996 13.4066 3.69001 10.2148 2.52444Z"
                        fill="#275A89"
                    />
                    <path
                        d="M8.32048 11.1797H2.41797C3.53157 8.36887 6.18314 6.56605 9.63009 6.56605C10.6912 6.56605 11.6693 6.74714 12.5532 7.07115C10.5767 7.793 9.08546 9.24831 8.32048 11.1797Z"
                        fill="#2C83C4"
                    />
                    <path
                        d="M12.5532 21.8631C11.669 22.1871 10.6912 22.3682 9.62982 22.3682C6.18286 22.3682 3.53129 20.5656 2.41797 17.7551H8.32048C9.08546 19.6862 10.5767 21.1415 12.5532 21.8631Z"
                        fill="#2C83C4"
                    />
                </svg>
            </div>
           
        </div>`

    useEffect(() => {
        let element = document.getElementById("html-content");
        if (element && pythonCode !== '') {

            element.innerHTML = pythonCode


        } else if (element && pythonCode === '' && showApplyButton && !showNotebookError) {
            element.innerHTML = loader  //`<div class='d-flex align-items-center w-100 h-100 justify-content-center paragraph'  >Script loading...</div>`
        } else if (element && pythonCode === '' && !showApplyButton && !showNotebookError) {
            const notebookInfographyHTML = createNotebookInfoImg;
            // Set the HTML markup as the innerHTML of the element
            element.innerHTML = notebookInfographyHTML;
        } else if (element && pythonCode === '' && !showApplyButton && showNotebookError) {
            const notebookInfographyHTML = createNotebookInfoImg;
            element.innerHTML = ` <div class='d-flex align-items-center w-100 h-100 justify-content-center error' >Could not fetch the updated script code now, please retry after sometime.</div>`

        }
    }, [pythonCode, showApplyButton, showNotebookError])

    return (
        <div className="bg-white script-maindiv transformer-details-maindiv">
            {/* show in align */}
            {toggleDefaultView ? <div className="source-not-found">
                <SCRIPTDEFAULTVIEW />
            </div> : <>
                <div className="col-4 pl-1 pt-2">
                    <OutputDataSet runClick={runClick} setshowCloseWarning={setshowCloseWarning} isExecutePermission={isExecutePermission} additional_settings={additional_settings} setAdditionalSettings={setAdditionalSettings} showApplyButton={showApplyButton} setOutputDataset={setOutputDataset} setisrunDisabled={setisrunDisabled} applyNewOuputDataSet={applyNewOuputDataSet} />
                </div>
                <div className={expandTable ? "full-screen-active scriptEditor" : " col-8 pt-1 scriptEditor"}>
                    <div className="d-flex justify-content-end custom-border-bottom padding-2">

                        <div className="d-flex justify-content-end ">
                            {expandTable ?
                                <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { setexpandTable(false) }, ImgSrc: () => <EXITFULLSCREEN />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                :
                                <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { setexpandTable(true) }, ImgSrc: () => <FULLSCREEN />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                            }
                            {showApplyButton ? <Buttons props={{ buttonText: 'Edit', buttonClassName: "custom-btn-outline btn-outline custom-btn btn-with-text ml-2", buttonEvent: () => { openJupyterNotebook() }, toggleBtnValue: '', ImgSrc: () => <EDIT />, isDisable: !isExecutePermission || runClick ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT, toggleBtnOption: null }} /> : null}
                        </div>
                    </div>
                    <div style={{ height: !expandTable ? 'calc(100vh - 13.4375rem)' : 'calc(100vh - 3.25rem)' }}>

                        <div id="html-content">

                        </div>



                    </div>
                    {/* <NotebookInfography /> */}

                </div>
            </>}
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        flowsElements: state.Lab.flowsElements,
        currentTransformer: state.Lab.currentTransformer,
        flowId: state.Lab.id,

    };
};
export default connect(mapStateToProps)(ScriptIndex);
