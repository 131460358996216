import React, { useEffect, useRef } from 'react'
import { DatePicker, Input, Popover, Switch, TimePicker, Tooltip } from 'antd'
import NoResultsFound from '../../flows/createFlows/NoResultsFound'
import SingleSelection from '../../../Common/dropDown/SingleSelection'
import { dataTypeList, getObjectLength, onlyDateRegex, onlyDatetimeRegex, onlytimeRegex } from '../../common/helperFunctions'
import dayjs from 'dayjs'
import { getParametersList, getSchedulerParametersList, updateParametersList } from '../../../../store/modules/flows/NewFlowsRedux/flowsActions'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { ICON_PARAMETER } from '../../../Common/newIconSource'
import { CLOSE } from '../../../Common/iconSource'
import { Literals } from '../../common/literals'
import Buttons from '../../../Common/button/Buttons'
import { useSelector, useStore } from 'react-redux'
import { cloneDeep } from 'lodash'
import { GetSchedulerDetails, SetSchedulerPayload } from '../../../../store/modules/scheduler/action'
import { useParams } from 'react-router-dom'


function ParameterPopup({ flowsAlertTransformerList, isExecutePermission, showParameterBtn, setshowParameterBtn }) {
    const [openParamaterDrawer, setParamaterDrawer] = React.useState(false)
    const [parameters, setParameters] = React.useState([])
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)
    const { schedulerId } = useParams();
    const [ShowInfography, setShowInfography] = React.useState(false)
    const store = useStore()

    useEffect(() => {
        // console.log(schedulerPayload, flowsAlertTransformerList)
        if (schedulerPayload['task_type']?.toLowerCase() === 'flow') {
            if (getObjectLength(flowsAlertTransformerList)) {
                let alreadySelectedFlow = schedulerPayload['old_flow_id'];
                let currentFlow = flowsAlertTransformerList?.selectedFlowDetails?.id;
                if (alreadySelectedFlow && currentFlow && alreadySelectedFlow === currentFlow) {
                    // if (schedulerPayload?.param_secret?.length) {
                    //     setParameters(schedulerPayload?.param_secret);
                    //     setshowParameterBtn(true)
                    // } else {
                     
                        getSchedulerParams()
                    // }
                } else {
                    if (flowsAlertTransformerList?.selectedFlowDetails?.id) {
                        getParams(flowsAlertTransformerList?.selectedFlowDetails?.id)

                    }
                }
            }
        }


    }, [flowsAlertTransformerList])



    const onParamInputChange = ({ key, value, id }) => {
        parameterChangesMade.current = true;
        if (key === 'data_type' || key === 'type') {
            setParameters(prev => {
                prev = prev?.map(p => (
                    {
                        ...p,
                        [key]: p?.id === id ? value : p?.[key],
                        param_value: p?.id === id ? '' : p?.['param_value'],
                        secret_value: p?.id === id ? '' : p?.['secret_value']
                    }
                ))

                return prev
            })
        } else {
            setParameters(prev => {
                prev = prev?.map(p => (
                    {
                        ...p,
                        [key]: p?.id === id ? value : p?.[key]
                    }
                ))


                return prev
            })
        }

    }

    const isDuplicateParams = React.useMemo(() => {
        const paramArr = parameters.map(p => p.name).filter(v => v !== '');
        const isDuplicate = paramArr.some((item, idx) => {
            return paramArr.indexOf(item) !== idx
        });
        return isDuplicate
    }, [parameters])

    const isParamAlreadyExist = (param) => {
        if (param.name === '') return false
        return parameters.filter(v => v.name === param.name)?.length > 1
    }

    function Validate(e) {


        let keyCode = e.key

        //Regex to allow only Alphabets Numbers Dash Underscore and Space
        let pattern = /^[a-z0-9_\bBackspaceArrowLeftArrowRight]*$/;

        //Validating the textBox value against our regex pattern.
        let isValid = pattern.test(keyCode);
        let match = keyCode.match(pattern)
        if ((!e.target?.value?.length && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106))) || (e.target?.selectionStart === 0 && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)))) {
            e.preventDefault()
        } else if (!isValid) {
            e.preventDefault()
        }
    }

    const parameterContent = () => {

        return (<div>

            {
                ShowInfography ? <div className='' style={{ height: '15.625rem', overflow: 'hidden', width: "33.75rem" }}> <NoResultsFound msg={'Parameter list not found'} height='250px' imgheight='150' imgwidth='150' /></div> : <div className=' ' >
                    <div className='d-flex'>
                        {
                            isDuplicateParams ?
                                <div className='col-12 mb-1'>
                                    <div className='text-center alert alert-danger p-1 small mb-0'>Duplicate Parameter(s) Found</div>
                                </div> : ''
                        }
                        {
                            parameters?.length > 0
                                ? <>
                                    <div className='col-2 pr-2'>
                                        <label className='fontSizeLabel fontInterSemiBold mb-3'>Name</label>
                                    </div>

                                    <div className='col-3'>
                                        <label className='fontSizeLabel fontInterSemiBold mb-3'>Datatype</label>
                                    </div>

                                    <div className='col-5'>
                                        <label className='fontSizeLabel fontInterSemiBold mb-1'>Value</label>

                                    </div>
                                    <div className='col-2 ml-3'>
                                        <label className='fontSizeLabel fontInterSemiBold mr-5 ml-1'>Secret</label>

                                    </div>
                                </>
                                : ''
                        }
                    </div>
                    <div style={{ height: '15.625rem', overflowY: 'auto', overflowX: "hidden", paddingBottom: "1.25rem" }}>

                        {

                            parameters?.map(p => (
                                <div className='d-flex parm-itm mb-3' >
                                    <div className='col-2 mt-1 mr-0 pr-0'>
                                        <Input
                                            className={`custom-input-field ${isParamAlreadyExist(p) ? 'form-control is-invalid' : ''}`}
                                            value={p?.name}
                                            onKeyDown={(e) => Validate(e)}
                                            disabled={true}
                                            onChange={(e) => onParamInputChange({ key: 'name', value: e.target.value?.toLowerCase(), id: p?.id })}
                                        />
                                    </div>
                                    <div className='col-3  p-0'>
                                        <SingleSelection
                                            props={{
                                                placeholder: "Select",
                                                dropDownValue: p?.data_type,
                                                onClickEvent: (e) => onParamInputChange({ key: 'data_type', value: e, id: p?.id }),
                                                optionList: dataTypeList,
                                                isDisable: true,
                                                isSortOptionDisable: true,
                                                selectClassName: 'w-100'
                                            }}
                                        />
                                    </div>
                                    <div className='col-5'>
                                        <div className="p-0">
                                            {p?.type === 'PARAMETER' && p?.data_type?.match(onlyDateRegex)?.length ?
                                                <DatePicker
                                                    picker="date"

                                                    placeholder="Please Enter Date"
                                                    className={
                                                        "custom-input-field w-100"
                                                    }
                                                    value={p?.type === 'PARAMETER' ? p?.param_value ? dayjs(p?.param_value, 'YYYY-MM-DD') : null : p.secret_value ? dayjs(p.secret_value, 'YYYY-MM-DD') : null}
                                                    format={"YYYY-MM-DD"}

                                                    onChange={(e, val) => {

                                                        onParamInputChange({ key: p?.type === 'PARAMETER' ? 'param_value' : 'secret_value', value: val, id: p?.id })
                                                    }}

                                                />
                                                :
                                                p?.type === 'PARAMETER' && p.data_type?.match(onlytimeRegex)?.length ? <TimePicker picker="time"
                                                    placeholder="Please select time"
                                                    className={
                                                        "custom-input-field w-100"
                                                    }
                                                    value={p?.type === 'PARAMETER' ? p?.param_value ? dayjs(p?.param_value, 'HH:mm:ss') : null : p.secret_value ? dayjs(p.secret_value, 'HH:mm:ss') : null}
                                                    format={"HH:mm:ss"}
                                                    disabled={false}
                                                    onChange={(e, val) => {
                                                        onParamInputChange({ key: p?.type === 'PARAMETER' ? 'param_value' : 'secret_value', value: val, id: p?.id })

                                                    }}
                                                ></TimePicker> :
                                                    p?.type === 'PARAMETER' && p?.data_type?.match(onlyDatetimeRegex)?.length ?
                                                        <DatePicker
                                                            picker="date"
                                                            showTime
                                                            placeholder="Please Enter Date And Time"
                                                            className={
                                                                "custom-input-field w-100"
                                                            }
                                                            value={p?.type === 'PARAMETER' ? p?.param_value ? dayjs(p?.param_value, "YYYY-MM-DD HH:mm:ss") : null : p.secret_value ? dayjs(p.secret_value, "YYYY-MM-DD HH:mm:ss") : null}

                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            onChange={(e, val) => {

                                                                onParamInputChange({ key: p?.type === 'PARAMETER' ? 'param_value' : 'secret_value', value: val, id: p?.id })

                                                            }}

                                                        /> : p?.type === 'PARAMETER' ? <textarea style={{ overflow: p?.param_value ? 'auto' : 'hidden' }} className={`custom-input-field parameter-text-area`}  //hide scrollbar if no value present
                                                            type='text'
                                                            value={p?.param_value ?? null}
                                                            placeholder='Enter Value'
                                                            disabled={!isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view')}
                                                            onChange={(e) => onParamInputChange({ key: 'param_value', value: e.target.value, id: p?.id })} rows="4" cols="50">

                                                        </textarea>



                                                            : p?.type === 'SECRET' ? <textarea key={`${p?.id}pass`} className={`custom-input-field parameter-text-area password-type-textarea`}
                                                                style={{ overflow: p?.secret_value ? 'auto' : 'hidden' }}
                                                                placeholder='****'
                                                                type='text'
                                                                disabled={!isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view')}
                                                                value={p?.secret_value ?? null}
                                                                onChange={(e) => onParamInputChange({ key: 'secret_value', value: e.target.value, id: p?.id })} rows="4" cols="50">

                                                            </textarea> : null


                                            }
                                        </div>


                                    </div>
                                    <div className='col-2 ml-3'>
                                        <Switch
                                            className={`custom-switch-btn `}
                                            checkedChildren="Yes" unCheckedChildren="No"
                                            checked={p?.type === 'SECRET' ? true : false}
                                            onChange={(e) => onParamInputChange({ key: 'type', value: e ? 'SECRET' : 'PARAMETER', id: p?.id })}
                                            disabled={true}
                                        />
                                    </div>

                                </div>
                            ))
                        }
                    </div>

                    <div className='param-draw-done align-items-center border-top d-flex justify-content-center px-2 py-2 w-100'>
                        <Buttons
                            props={{
                                buttonText: 'Save Parameters',
                                buttonClassName: `custom-btn-primary custom-btn btn-with-text ml-2`,
                                buttonEvent: (e) => {
                                    e.stopPropagation()
                                    applyParamsList(parameters);
                                    setParamaterDrawer(false)
                                },
                                ImgSrc: () => { },
                                isDisable: !parameters?.length || !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view'),
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </div>
                </div>
            }
        </div>)
    }

    const getParams = (flowId) => {
        store.dispatch(getParametersList(flowId)).then((resp) => {
            if (resp?.data?.length) {
                let list = resp?.data;
                list.forEach(element => {
                    element['isExisting'] = true
                    element['flow'] = flowId
                });
                setParameters(list);
                applyParamsList(list)
                setShowInfography(false)
                setshowParameterBtn(true);

            } else {
                applyParamsList([])
                setShowInfography(true)
                setshowParameterBtn(false);


            }
        }).catch((e) => {
            applyParamsList([])
            setShowInfography(true)
            setshowParameterBtn(false);


        })
    }

    const getSchedulerParams = () => {
        store.dispatch(getSchedulerParametersList(schedulerId)).then((resp) => {
            if (resp?.data?.length) {
                let list = resp?.data;
                list.forEach(element => {
                    element['isExisting'] = true
                });
                setParameters(list);
                applyParamsList(list)
                setShowInfography(false)
                setshowParameterBtn(true);

            } else {
                applyParamsList([])
                setShowInfography(true)
                setshowParameterBtn(false);


            }
        }).catch((e) => {
            applyParamsList([])
            setShowInfography(true)
            setshowParameterBtn(false);


        })
    }

    const checkValidation = (formGroupData) => {
        if (formGroupData.length) {


            let validateValue = formGroupData.every((el) => {
                if (el.type === 'PARAMETER') {
                    return el?.param_value?.trim().length > 0
                }
                if (el.type === "SECRET") {
                    return true
                }

            })


            if (!validateValue) {
                emitToastNotification('warning', 'Empty field found in parameter.')
                return false
            } else {
                return true
            }
        } else {
            return true
        }

    };
    const applyParamsList = (parametersList) => {
        if (checkValidation(parametersList)) {
            const newPayload = cloneDeep(schedulerPayload)
            newPayload['param_secret'] = parametersList;
            newPayload['flow'] = flowsAlertTransformerList?.selectedFlowDetails?.id;
            store.dispatch(SetSchedulerPayload(newPayload))
            setParamaterDrawer(false)

            // store.dispatch(updateParametersList(parameters)).then((resp) => {
            // })
        }

    }

    const parameterChangesMade = useRef(false)

    return (
        showParameterBtn ? <Popover autoAdjustOverflow={true} id='param-popover' rootClassName='paramater-scheduler-popover'
            title={<div className='d-flex align-items-center justify-content-between p-3'>
                <label className='mb-0 fontSizeHeading fontInterSemibold'>Parameters</label>
                <Buttons props={{
                    buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-3", tooltip: "Close", tooltipPlacement: "bottomLeft", buttonEvent: () => {
                        // if (parameterChangesMade?.current) {
                        //     if (window.confirm('Unsaved changes will be lost. Are you sure, want to continue?')) {
                        //         setParameters([]);
                        //         setParamaterDrawer(false)
                        //     }
                        // } else {
                        //     setParameters([]);
                        setParamaterDrawer(false)
                        // }


                    }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY
                }} />


            </div>}
            content={parameterContent}
            trigger="click"
            open={openParamaterDrawer}
            onOpenChange={(value) => {
                // if (parameterChangesMade?.current) {
                //     if (window.confirm('Unsaved changes will be lost. Are you sure, want to continue?')) {
                //         setParameters([]);
                //         setParamaterDrawer(false)
                //     }
                // } else {
                //     setParameters([]);
                //     setParamaterDrawer(false)
                // }
                setParamaterDrawer(!openParamaterDrawer)
            }}
            placement='bottom'

        >
            <Buttons
                props={{
                    tooltip: "Show Parameters",
                    tooltipPlacement: 'top',
                    buttonText: "",
                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon  parameter-btn mt-4
                                ${openParamaterDrawer ? 'active' : ''}
                                ${parameters?.length > 0 ? 'rdg-filter-active' : ''}
                                `,
                    buttonEvent: () => {

                        // // getParams(flowsAlertTransformerList?.selectedFlowDetails?.id)
                        // if (parameters?.length) {
                        //     if (schedulerPayload?.param_secret?.length) {
                        //         setParameters(schedulerPayload?.param_secret);
                        //     } else {
                        //         getSchedulerParams()
                        //     }
                        //     setParamaterDrawer(true);

                        // } else {

                        setParamaterDrawer(true);
                        // }


                    },
                    ImgSrc: () => <ICON_PARAMETER />,
                    isDisable: false,//!isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view'),
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                }}
            />
        </Popover> : null
    )
}

export default ParameterPopup