import { Switch } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'

const StoreCacheOption = ({
    isEditMode = false,
    isEditing = false,
    disabled = false
}) => {

    const dispatch = useDispatch()

    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)

    const [checked, setChecked] = React.useState((() => {
        return !!reduxData?.cacheEnabled
    })())

    const handleCache = (bool) => {
        setChecked(bool)
        reduxData['cacheEnabled'] = bool
        dispatch(setPodCreationData(reduxData))
    }
    
    const isDisabled = React.useMemo(() => {
        if(isEditMode) return !isEditing
        return false
    }, [isEditMode, isEditing])

    return (
        <React.Fragment>
            <div className='d-flex align-items-center'>
                <label className='mb-0 mr-2 fontSizeLabel fontInterSemiBold text-dark'>Store Cache</label>
                <Switch
                    key={'cacheEn-' + checked ? '1' : '0'}
                    className='custom-switch-btn'
                    checkedChildren="Yes" unCheckedChildren="No"
                    size='small'
                    defaultChecked={checked}
                    // checked={checked}
                    onChange={(chk) => handleCache(chk)}
                    disabled={isDisabled || disabled}
                />
            </div>
        </React.Fragment>
    )
}

export default StoreCacheOption