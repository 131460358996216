import React from 'react'
import { ICON_ARROW_RIGHT, ICON_CALENDAR } from '../../../Common/newIconSource'
import { Dropdown } from 'antd'

const DurationDropdown = ({
    menuItems = [],
    className = '',
    setParentActiveKey = () => {},
    defaultSelectedKey = ''
}) => {

    const [activeKey, setActiveKey] = React.useState(defaultSelectedKey ?? "")

    return (
            <Dropdown 
                menu={{ 
                        items: menuItems,
                        selectable: true,
                        onSelect: ({key}) => {
                            setActiveKey(key)
                            setParentActiveKey(key)
                        },
                        selectedKeys: activeKey,
                        multiple: false
                     }} 
                trigger={['hover']}
                className={`nusg-duration-ddl ${className}`}
                >
                <div className='d-flex align-items-center nusg-dropdown-selected'>
                    <div className='nusg-dropdown-ic'>
                        <ICON_CALENDAR color='#565656'/>
                    </div>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <span className='fontSizeHeading nusg-dropdown-label'>
                            {menuItems?.find(m => m?.key === activeKey)?.label ?? 'Select'}
                        </span>
                        <span className='bx-rotate-90 ml-2'>
                            <ICON_ARROW_RIGHT height='10' width='10'/>
                        </span>
                    </div>
                </div>
            </Dropdown>
    )
}

export default DurationDropdown