import React from 'react'
import { ICON_CONFIRM } from '../../Common/newIconSource'

const CopilotInfoCard = ({
    heading = 'Heading',
    paragraph = 'Some text',
    onClick = () => {},
    style = {}
}) => {
    return (
        <div className='cpilot-pop mb-1 info-card' style={{width: 'auto', ...style}} onClick={onClick}>
            <div className='d-flex align-items-center px-3 py-3'>
                <div className='mr-2'>
                    <ICON_CONFIRM color='#333333' height='24' width='24'/>
                </div>
                <div style={{color: '#333333'}}>
                    <h4 className='fontInterSemiBold mb-1 fontSizeLargeHeading text-capitalize'>{heading}</h4>
                    <p className='fontSizeHeading mb-0'>{paragraph}</p>
                </div>
            </div>
        </div>
    )
}

export default CopilotInfoCard