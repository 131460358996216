import React from 'react'
import LandingpageFirstHeader from '../common/LandingpageFirstHeader'
import { ICON_LOGS, ICON_STATS } from '../../Common/newIconSource'
import { Tabs } from 'antd'
import './usageAndJobs.scss'
import UsageStats from './components/UsageStats'
import UsageActivity from './components/UsageActivity'
import { cloneDeep } from 'lodash'

const NewUsageIndex = () => {

    const [activeTabKey, setActiveTabKey] = React.useState('1')
    const tabItems = () => {
        return [
            {
                key: '1',
                label: <span>
                    <ICON_STATS /> STATS
                </span>,
                children: <UsageStats changeTab={false}/>,
            },
            {
                key: '2',
                label: <span>
                    <ICON_LOGS /> ACTIVITY
                </span>,
                children: <UsageActivity changeTab={false} />,
            },
        ]
    }

    const [tabsMenu, setTabsMenu] = React.useState(tabItems())


    const onTabChange = (key) => {
        setActiveTabKey(key);
        let arr = cloneDeep(tabItems())
        if(key === '2'){
            arr[0]['children']=<UsageStats changeTab={true} />
            arr[1]['children']=<UsageActivity changeTab={true} />
        }else{
            arr[0]['children']=<UsageStats changeTab={false} />
            arr[1]['children']=<UsageActivity changeTab={false} />
        }
       

        setTabsMenu([...arr])
    }

    return (
        <div className='new-usage-wrapper'>
            <LandingpageFirstHeader title={'Usage'} />

            <div className=''>
                <Tabs
                    className='dark-detail-tab new-usage-main-tabs'
                    defaultActiveKey="1"
                    activeKey={activeTabKey}
                    items={tabsMenu}
                    onChange={onTabChange}
                />
            </div>
        </div>
    )
}

export default NewUsageIndex