import React from 'react'
import { TwoSections } from '../../../Common/twoSections/TwoSections'
import { SchedulerDetailsForm } from './SchedulerDetailsForm'
import { SchedulerRightSide } from './SchedulerRightSide'

export const Settings = ({
    isEditor = false
}) => {

    return <>
        <div className='scheduler-settings-wrapper'>
            <TwoSections
                leftTemplate={<SchedulerDetailsForm isEditor={isEditor}/>}
                rightTemplate={<SchedulerRightSide isEditor={isEditor}/>}
                height={'calc(100vh - 74px)'}
                rightCardView={false}
            />
        </div>
    </>
}
