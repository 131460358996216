import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector, useStore } from "react-redux"
import logoImage from '../../../../assets/images/new-logo-2.png'
import star from '../../../../assets/icons/Star.svg'
import Buttons from '../../../Common/button/Buttons'

import { Literals } from '../../common/literals'
import { CLOSE, SAVE, HAMBURGER, DELETEICON, DUPLICATE, EDIT, PLAY, FILLEDSTAR, STAR, LINEAGE_ICON, RENAME, CLOCK } from '../../../Common/iconSource'
import { toggleSidebarCollapse } from '../../../../store/layout/actions';
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { useParams } from 'react-router';
import { EditFlowsAPI, deleteParameterDetails, executeEntireFlow, fetchFlowsDetails, getParametersList, saveFlow, updateParametersList, } from '../../../../store/modules/flows/NewFlowsRedux/flowsActions'
import ActionPopUp from '../../common/actionPopUp'
import { DatePicker, Drawer, Input, Popover, Switch, TimePicker, Tooltip } from 'antd'

import { createBookmark } from '../../../../store/modules/flows/landingPage/landingPageAction'
import { SAVE_FLOW_CLICK, SET_ENTIREFLOW_EXECUTE_START, SET_TRANSFORMER_PROGRESS } from '../../../../store/modules/flows/NewFlowsRedux/flowsTypes'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { DeleteNoteContent, convertDOMElementToImage, dataTypeList, getConfirmationModal, getDeleteMessage, getMonthName, getRelativeTime, getTime, onlyDateRegex, onlyDatetimeRegex, onlytimeRegex, preventSpecialCharsRegex } from '../../common/helperFunctions'
import NewFlowsPopUp from './NewFlowsPopUp'
import _, { cloneDeep } from 'lodash'
import { ExecuteTransformer, RunTransformer } from '../transformers/commonFunctions/FlowsHelperFunction'
import { setLoadingStatus } from '../../../../store/modules/common/loadingActions'
import { ICON_CHECK, ICON_INFO_FILLED, ICON_MINUS, ICON_PARAMETER, ICON_PLUS } from '../../../Common/newIconSource'
import SingleSelection from '../../../Common/dropDown/SingleSelection'
import dayjs from 'dayjs'
import NoResultsFound from '../../flows/createFlows/NoResultsFound'
import ClusterStatusNotification from '../../clusters/ClusterStatusNotification'
import Notifications from '../../../Common/notifications/Notifications'
import { useLocation } from 'react-router-dom'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'

const Header = (props) => {
  const { flowName,
    // transformerProgress,
    showJupyterNoteBook,
    isEditPermission = true,
    showDatabricksNotebook
  } = props
  const searchParams = new URLSearchParams(document.location.search)

  const history_id = searchParams.get("history_id");
  const versionHistory = JSON.parse(localStorage.getItem("versionHistory"));
  const history = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation()
  const [editFlowName, seteditFlowName] = useState(false)
  const [newFlowName, setnewFlowName] = useState()
  const [bookmark, setbookmark] = useState(false)
  const [openParamaterDrawer, setParamaterDrawer] = React.useState(false)
  const [parameters, setParameters] = React.useState([])
  const [parametersActive, setParametersActive] = React.useState(false);
  const [ShowInfography, setShowInfography] = React.useState(false)
  let userDetails = useSelector(state => state.UserReducer?.user?.userDetails)
  const [disableExecuteFlow, setdisableExecuteFlow] = useState(false)
  const [domainId, setDomainId] = useState(false) // Domain id is the id from the landing page
  const { flowId } = useParams();

  const [openModal, setOpenModal] = useState(false)

  const store = useStore()
  useEffect(() => {
    getParams()

    const dm = location?.state?.domainId
    setDomainId(dm)

  }, [])

  useEffect(() => {
    setbookmark(store.getState().Lab.bookmark);
    setnewFlowName(store.getState().Lab.name);
  }, [store.getState().Lab.name?.length])


  const currentConditionTypeRef = React.useRef({ current: {} })


  useEffect(() => {
    // if (editFlowName) {
    setnewFlowName(store.getState().Lab.name)
    // }
  }, [openModal])
  const toggleSidebarHandler = () => {
    dispatch(toggleSidebarCollapse());
  }

  const saveFlowApi = (btnType) => {
    dispatch({
      type: SAVE_FLOW_CLICK,
      payload: true
    })
    dispatch(setLoadingStatus(true));
    dispatch(EditFlowsAPI({ ApiType: "DELETE", flow_Id: flowId }));
    if (!isEditPermission) {
      history(`${Literals.links.CLARISTA_LAB}#flows`, { state: { domainId } })
    } else {
      setTimeout(() => {

        convertDOMElementToImage(".react-flow__pane", "exact").then((canvasImage) => {  //.react-flow__viewport

          dispatch(saveFlow(flowId, true, true, canvasImage)).then((resp) => {
            if (resp) {
              history(`${Literals.links.CLARISTA_LAB}#flows`, { state: { domainId } })
            }
          }).catch((err) => {
            history(`${Literals.links.CLARISTA_LAB}#flows`, { state: { domainId } });
          })
        })

      }, 500);
    }

  }
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);

  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };
  const [openWarningModal, setOpenWarningModal] = useState(false)
  const openSaveChangesWarning = () => {

    // currentConditionTypeRef.current = {
    //   message: 'Unsaved changes will be lost. Are you sure, want to continue?',
    //   onConfirm: () => {
    //     dispatch(EditFlowsAPI({ ApiType: "DELETE", flow_Id: flowId })); history(`${Literals.links.CLARISTA_LAB}#flows`)
    //     setOpenWarningModal(false);
    //     return true
    //   },
    //   onCancel: () => {
    //     setOpenWarningModal(false);
    //     return false
    //   }
    // }


    // if (isEditMode) {

    //   setOpenWarningModal(true);
    // } else {
    dispatch(EditFlowsAPI({ ApiType: "DELETE", flow_Id: flowId })); history(`${Literals.links.CLARISTA_LAB}#flows`)
    // }
  };

  const deleteFlow = async (id) => {
    setOpenDeletePopup(false);
    try {
      dispatch(fetchFlowsDetails({ method: "DELETE", endPoint: `flow/${id}` })).then((resp) => {
        if (resp.status === 'success') {
          history(Literals.links.FLOWS)
        }
      })
    } catch (error) { }

  }

  const bookmarkData = (id, bookmarkStatus = false) => {

    store.dispatch(createBookmark(id, !bookmark)).then((resp) => {
      if (resp.status === 'success') {
        setbookmark(!bookmark)
      }
    })
  }

  const executeCompleteFlow = () => {
    setdisableExecuteFlow(true)
    store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: true });
    if (isEditPermission) {
      store.dispatch(ExecuteTransformer('entireflow', undefined)).then((resp) => {
        emitToastNotification('success', 'Flow execution started')
        store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false });
      }).catch((err) => {
        setdisableExecuteFlow(false)
        store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false });
      })
    } else {
      store.dispatch(RunTransformer('entireflow', undefined)).then((resp) => {
        emitToastNotification('success', 'Flow execution started')
        store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false });
      }).catch((err) => {
        setdisableExecuteFlow(false)
        store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false });
      })
    }

  }

  // useEffect(() => {
  //   if (transformerProgress?.length) {
  //     let flowsExecutionState = transformerProgress.find((ele) => ele.transformer === "__all__")?.state;

  //     if (flowsExecutionState && (flowsExecutionState?.toLowerCase() === 'done' || flowsExecutionState?.toLowerCase() === 'success' || flowsExecutionState?.toLowerCase() === 'aborted' || flowsExecutionState?.toLowerCase() === 'error' || flowsExecutionState?.toLowerCase() === 'failed')) {

  //       store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false })

  //       setdisableExecuteFlow(false)

  //     }
  //   } else {


  //   }

  // }, [transformerProgress])


  // useEffect(() => {
  //   let interval = null
  //   // console.log({ 'isViewMode': isViewMode, '!isViewMode': !isViewMode })
  //   // if (!isViewMode) {
  //   interval = setInterval(() => {
  //     store.dispatch(saveFlow(flowId, false)).then((res) => {
  //       setsaveFlowTime(new Date().toISOString())
  //     })
  //   }, 120000);
  //   // }

  //   return () => {
  //     clearInterval(interval);
  //   }
  // }, [])

  const editFlowNameHandle = () => {
    seteditFlowName(true)
    setOpenModal(true)

  }

  const genarateId = () => {
    let cloneParam = cloneDeep(parameters)
    const ids = cloneParam.map(object => object.id);

    if (ids?.length) {

      return Math.max(...ids) + 1;
    } else {
      return 1
    }
  }

  const handleAddParams = (lastId = 0) => {

    setParameters(prev => {
      const newArr = [{ id: genarateId(), name: '', data_type: "varchar", param_value: '', secret_value: "", type: "PARAMETER", isExisting: false, flow: flowId }, ...prev]
      return newArr
    })
    setShowInfography(false)
  }

  const handleRemoveParam = (id, item) => {

    if (item?.isExisting) {
      dispatch(deleteParameterDetails(id)).then((resp) => {
        setParameters(prev => {
          prev = prev.filter(p => p?.id !== id)
          return prev
        })
      }).catch((e) => {

      })
    } else {
      setParameters(prev => {
        prev = prev.filter(p => p?.id !== id)
        return prev
      })
    }
    setOpenDeletePopup(false);

  }

  // function maskTextAreaValue(value) {
  //   // const textarea = document.getElementById('password');
  //   if (value && value?.length) {
  //     const maskedValue = '*'?.repeat(value?.length);
  //     // textarea.classList.add('masked');
  //     // textarea.value = maskedValue;
  //     return maskedValue
  //   } else {
  //     return ''
  //   }

  // }

  // function setPassword() {
  //   const textarea = document.getElementById('password');
  //   const secretPassword = 'mySecret\nPassword';
  //   textarea.value = secretPassword;
  //   maskPassword();
  // }

  const onParamInputChange = ({ key, value, id }) => {
    parameterChangesMade.current = true;
    if (key === 'data_type') {  // || key === 'type'
      setParameters(prev => {
        prev = prev?.map(p => (
          {
            ...p,
            [key]: p?.id === id ? value : p?.[key],
            param_value: p?.id === id ? '' : p?.['param_value'],
            secret_value: p?.id === id ? '' : p?.['secret_value']
          }
        ))

        return prev
      })
    } else if (key === 'type') {
      setParameters(prev => {
        prev = prev?.map(p => (
          {
            ...p,
            [key]: p?.id === id ? value : p?.[key],
            param_value: p?.id === id && p?.['secret_value'] ? p?.['secret_value'] : p?.['param_value'],
            secret_value: p?.id === id && p?.['param_value'] ? p?.['param_value'] : p?.['secret_value'],
          }
        ))

        return prev
      })
    } else {
      setParameters(prev => {
        prev = prev?.map(p => (
          {
            ...p,
            [key]: p?.id === id ? value : p?.[key]
          }
        ))


        return prev
      })
    }

  }

  const isDuplicateParams = React.useMemo(() => {
    const paramArr = parameters.map(p => p.name).filter(v => v !== '');
    const isDuplicate = paramArr.some((item, idx) => {
      return paramArr.indexOf(item) !== idx
    });
    return isDuplicate
  }, [parameters])

  const isParamAlreadyExist = (param) => {
    if (param.name === '') return false
    return parameters.filter(v => v.name === param.name)?.length > 1
  }

  function Validate(e) {


    let keyCode = e.key

    //Regex to allow only Alphabets Numbers Dash Underscore and Space
    let pattern = /^[a-z0-9_\bBackspaceArrowLeftArrowRight]*$/;

    //Validating the textBox value against our regex pattern.
    let isValid = pattern.test(keyCode);
    let match = keyCode.match(pattern)
    if ((!e.target?.value?.length && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106))) || (e.target?.selectionStart === 0 && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)))) {
      e.preventDefault()
    } else if (!isValid) {
      e.preventDefault()
    }
  }

  const customFormat = (value) => `custom format:****`;


  const parameterContent = () => {

    return (<div>

      {
        ShowInfography ? <div className='flow-header-param-cont-wrap' style={{ height: '300px', overflow: 'hidden', width: "800px", padding: "10px 10px 56px 10px" }}> <NoResultsFound msg={'Parameter list not found, click on `+Add` button.'} submsg={"Parameters are applied for only Filter, SQL, Script, Target transformers."} height='300px' imgheight='150' imgwidth='150' /></div> :
          <div className='param-cont-wrap flow-header-param-cont-wrap' style={{ width: "800px", padding: "10px 0px 56px 17px" }}>
            <div className='row'>
              {
                isDuplicateParams ?
                  <div className='col-12 mb-1'>
                    <div className='text-center alert alert-danger p-1 small mb-0'>Duplicate Parameter(s) Found</div>
                  </div> : ''
              }
              {
                parameters?.length > 0
                  ? <>
                    <div className='col-2 pr-2'>
                      <label className='fontSizeLabel fontInterSemiBold mb-3'>Name<Tooltip title="Only lowercase letters are allowed"><span className='ml-1 cursor-pointer'><ICON_INFO_FILLED height='12' width='12'></ICON_INFO_FILLED></span></Tooltip></label>
                    </div>
                    <div className='col-2 pr-2'>
                      <label className='fontSizeLabel fontInterSemiBold mb-3'>Description</label>
                    </div>

                    <div className='col-2'>
                      <label className='fontSizeLabel fontInterSemiBold mb-3'>Datatype</label>
                    </div>

                    <div className='col-6  d-flex justify-content-between align-items-center pr-2'>
                      <label className='fontSizeLabel fontInterSemiBold mb-1'>Value</label>
                      <label className='fontSizeLabel fontInterSemiBold mr-5 ml-1'>Secret</label>
                    </div>


                  </>
                  : ''
              }
            </div>
            <div style={{ height: '250px', overflowY: 'auto', overflowX: "hidden", }}>
              {

                parameters?.map(p => (
                  <div className='row mb-3'>
                    <div className='col-2 align-items-center mr-0 pr-0'>
                      <Input
                        className={`custom-input-field ${isParamAlreadyExist(p) ? 'form-control is-invalid' : ''}`}
                        value={p?.name}
                        onKeyDown={(e) => Validate(e)}
                        disabled={p?.isExisting}
                        onChange={(e) => onParamInputChange({ key: 'name', value: e.target.value?.toLowerCase(), id: p?.id })}
                      />
                    </div>
                    <div className='col-2 align-items-center mr-0 pr-0'>
                      <Input
                        className={`custom-input-field `}
                        value={p?.description}
                        disabled={!isEditPermission}
                        onChange={(e) => onParamInputChange({ key: 'description', value: e.target.value, id: p?.id })}
                      />
                    </div>
                    <div className='col-2 align-items-center mr-0 pr-0'>
                      <SingleSelection
                        props={{
                          placeholder: "Select",
                          dropDownValue: p?.data_type,
                          onClickEvent: (e) => onParamInputChange({ key: 'data_type', value: e, id: p?.id }),
                          optionList: dataTypeList,
                          isDisable: !isEditPermission,
                          isSortOptionDisable: true,
                          selectClassName: 'w-100'
                        }}
                      />
                    </div>


                    <div className='col-4'>
                      <div className="p-0">
                        {p?.type === 'PARAMETER' && p?.data_type?.toLowerCase() === 'boolean' ? <SingleSelection
                          props={{
                            placeholder: "Select",
                            dropDownValue: p?.param_value,
                            onClickEvent: (e) => onParamInputChange({ key: 'param_value', value: e, id: p?.id }),
                            optionList: [{ Name: "True", value: "true", Type: "true" }, { Name: "False", value: "false", Type: "false" },],
                            isDisable: !isEditPermission,
                            isSortOptionDisable: true,
                            selectClassName: 'w-100'
                          }}
                        /> : p?.type === 'PARAMETER' && p?.data_type?.match(onlyDateRegex)?.length ?
                          <DatePicker
                            picker="date"

                            placeholder="Please Enter Date"
                            className={
                              "custom-input-field w-100"
                            }
                            value={p?.type === 'PARAMETER' ? p?.param_value ? dayjs(p?.param_value, 'YYYY-MM-DD') : null : p.secret_value ? dayjs(p.secret_value, 'YYYY-MM-DD') : null}
                            format={"YYYY-MM-DD"}

                            onChange={(e, val) => {

                              onParamInputChange({ key: p?.type === 'PARAMETER' ? 'param_value' : 'secret_value', value: val, id: p?.id })
                            }}
                            disabled={!isEditPermission}
                          />
                          :
                          p?.type === 'PARAMETER' && p.data_type?.match(onlytimeRegex)?.length ? <TimePicker picker="time"
                            placeholder="Please select time"
                            className={
                              "custom-input-field w-100"
                            }
                            value={p?.type === 'PARAMETER' ? p?.param_value ? dayjs(p?.param_value, 'HH:mm:ss') : null : p.secret_value ? dayjs(p.secret_value, 'HH:mm:ss') : null}
                            format={"HH:mm:ss"}
                            disabled={!isEditPermission}
                            onChange={(e, val) => {
                              onParamInputChange({ key: p?.type === 'PARAMETER' ? 'param_value' : 'secret_value', value: val, id: p?.id })

                            }}
                          ></TimePicker> :
                            p?.type === 'PARAMETER' && p?.data_type?.match(onlyDatetimeRegex)?.length ?
                              <DatePicker
                                picker="date"
                                showTime
                                placeholder="Please Enter Date And Time"
                                className={
                                  "custom-input-field w-100"
                                }
                                value={p?.type === 'PARAMETER' ? p?.param_value ? dayjs(p?.param_value, "YYYY-MM-DD HH:mm:ss") : null : p.secret_value ? dayjs(p.secret_value, "YYYY-MM-DD HH:mm:ss") : null}

                                format="YYYY-MM-DD HH:mm:ss"
                                onChange={(e, val) => {

                                  onParamInputChange({ key: p?.type === 'PARAMETER' ? 'param_value' : 'secret_value', value: val, id: p?.id })

                                }}
                                disabled={!isEditPermission}
                              /> : p?.type === 'PARAMETER' ? <textarea style={{ overflow: p?.param_value ? 'auto' : 'hidden' }} className={`custom-input-field parameter-text-area`}  //hide scrollbar if no value present
                                type='text'
                                value={p?.param_value ?? null}
                                placeholder='Enter Value'
                                disabled={!isEditPermission}
                                onChange={(e) => onParamInputChange({ key: 'param_value', value: e.target.value, id: p?.id })} rows="4" cols="50">

                              </textarea>

                                // <Input
                                //   className={`custom-input-field `}
                                //   type='text'
                                //   value={p?.param_value}
                                //   placeholder='Enter Value'
                                //   onChange={(e) => onParamInputChange({ key: 'param_value', value: e.target.value, id: p?.id })}
                                // />

                                : p?.type === 'SECRET' ? <textarea key={`${p?.id}pass`} className={`custom-input-field parameter-text-area password-type-textarea`}
                                  style={{ overflow: p?.secret_value ? 'auto' : 'hidden' }}
                                  placeholder='****'
                                  type='text'
                                  value={p?.secret_value ?? null}
                                  disabled={!isEditPermission}
                                  onChange={(e) => onParamInputChange({ key: 'secret_value', value: e.target.value, id: p?.id })} rows="4" cols="50">

                                </textarea> : null

                          // : <input
                          //   className={`custom-input-field `}
                          //   placeholder='****'
                          //   type='password'
                          //   value={p?.secret_value}
                          //   onChange={(e) => {
                          //     const newValue = e.target?.value?.includes('\n') ? 'true' : 'false'
                          //     console.log(newValue);
                          //     ; onParamInputChange({ key: 'secret_value', value: e.target.value, id: p?.id })
                          //   }}
                          // />
                        }
                      </div>

                    </div>
                    <div className='col-2 pl-2'>
                      <div className='d-flex align-items-center'>

                        <Switch
                          className={`custom-switch-btn ml-2`}
                          checkedChildren="Yes" unCheckedChildren="No"
                          checked={p?.type === 'SECRET' ? true : false}
                          onChange={(e) => onParamInputChange({ key: 'type', value: e ? 'SECRET' : 'PARAMETER', id: p?.id })}
                          disabled={!isEditPermission}
                        />
                        <Buttons
                          props={{
                            buttonClassName: `danger-btn  error-red custom-btn-outline custom-btn btn-with-icon ml-2`,
                            buttonEvent: (e) => {
                              e.stopPropagation();
                              parameterToDelete.current = {
                                id: p.id,
                                p: p
                              }
                              setOpenDeletePopup(true);
                              // handleRemoveParam(p?.id, p);

                            },
                            ImgSrc: () => <ICON_MINUS />,
                            isDisable: !isEditPermission,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                          }}
                        />


                      </div>
                    </div>
                  </div>
                ))
              }
            </div>

            <div className='param-draw-done align-items-center border-top d-flex justify-content-center px-2 py-2 w-100'>
              <Buttons
                props={{
                  buttonText: 'Save',
                  buttonClassName: `custom-btn-primary custom-btn btn-with-text ml-2`,
                  buttonEvent: (e) => {
                    e.stopPropagation()
                    applyParamsList();

                  },
                  ImgSrc: () => { },
                  isDisable: !parameters?.length,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                }}
              />
            </div>
          </div>
      }
    </div>)
  }


  const getParams = () => {



    dispatch(getParametersList(flowId)).then((resp) => {
      if (resp?.data?.length) {
        let list = resp?.data;
        list?.forEach(element => {
          element['isExisting'] = true
        });
        setParameters(list);
        setShowInfography(false)
        setParametersActive(true);
        // setParamaterDrawer(true);
      } else {
        setParametersActive(false);

        setShowInfography(true)
        // handleAddParams(_.last(parameters)?.id)
        // setParamaterDrawer(true);
      }
    }).catch((e) => {
      setShowInfography(true)


      // handleAddParams(_.last(parameters)?.id)
      // setParamaterDrawer(true);
    })
  }

  const checkValidation = (formGroupData) => {
    if (formGroupData.length) {
      let validateColumn = formGroupData.every((el) => {
        return el?.name?.trim().length > 0
      })
      let validateDatatype = formGroupData.every((el) => {
        return el?.data_type && el?.data_type?.trim().length > 0
      })
      let validateValue = formGroupData.every((el) => {
        if (el.type === 'SECRET') {
          return true;
        } else if (el.type === 'PARAMETER') {
          return el?.param_value?.trim().length > 0
        }

      })



      if (!validateColumn || !validateValue || !validateDatatype) {
        emitToastNotification('warning', 'Empty field found in parameter.')
        return false
      } else {
        return true
      }
    } else {
      return true
    }

  };
  const applyParamsList = () => {
    if (checkValidation(parameters)) {
      dispatch(updateParametersList(parameters)).then((resp) => {
        setParamaterDrawer(false);
        setParameters([]);
        getParams();
      })
    }

  }

  const parameterToDelete = useRef()
  const parameterChangesMade = useRef(false)

  const parameterIcon = useMemo(() => <Popover rootClassName='flows-param-popover' placement="bottomLeft" title={<div className='d-flex align-items-center justify-content-between  p-3 '>
    <label className='mb-0 fontSizeHeading fontInterSemibold'>Parameters</label>
    <div className='d-flex justify-content-end'>
      <span className='underline-button'
        onClick={(e) => { e?.stopPropagation(); handleAddParams(_.first(parameters)?.id) }}>
        <ICON_PLUS /> Add
      </span>
      <Buttons props={{
        buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-3", tooltip: "Close", tooltipPlacement: "bottomLeft", buttonEvent: () => {
          if (parameterChangesMade?.current) {
            if (window.confirm('Unsaved changes will be lost. Are you sure, want to continue?')) {
              setParameters([]);
              setParamaterDrawer(false)
            }
          } else {
            setParameters([]);
            setParamaterDrawer(false)
          }
          getParams();

        }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY
      }} />

    </div>

  </div>} content={parameterContent}
    trigger="click"
    open={openParamaterDrawer}
  // onOpenChange={(open) => {
  //   setParamaterDrawer(open);
  //   if (open === false) {
  //     setParameters([]);
  //   }
  // }}
  >
    <Buttons
      props={{
        tooltip: "Show Parameters",
        tooltipPlacement: 'top',
        buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2 
                        ${openParamaterDrawer || parametersActive ? 'active' : ''}
                        ${parameters?.length > 0 || parametersActive ? 'rdg-filter-active' : ''}
                        `,
        buttonEvent: () => {

          getParams();
          setParamaterDrawer(true)


        },
        ImgSrc: () => <ICON_PARAMETER />,
        isDisable: false, //!isEditPermission,
        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
      }}
    />
  </Popover>, [parameters, isEditPermission, parametersActive, openParamaterDrawer])

  return (
    <div className="flow-header" style={history_id ? { filter: 'grayscale(1)', paddingTop: '5px' } : {}}>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div
            className="hamburger"
            onClick={() => { toggleSidebarHandler() }}
          // onMouseOver={() => toggleSidebarHandler()}
          >
            {/* <ICON_MENU /> */}
            <HAMBURGER />
          </div>
          <img className="cursor-pointer" src={logoImage}
            onClick={() => {
              if (userDetails?.openAi?.is_active) {
                return history(Literals.links.CLARISTA_HOME)
              }
              else if (userDetails?.designation === Literals.DATA_ANALYST)
                return history(Literals.links.CLARISTA_NAVIGATOR)
              else if (userDetails?.designation === Literals.DATA_SCIENTIST)
                return history(Literals.links.CLARISTA_LAB)
              else if (userDetails?.designation === Literals.DATA_CONSUMER)
                return history(Literals.links.CLARISTA_PULSE)
              else
                return history(Literals.links.CLARISTA_NAVIGATOR)
            }}
            alt="Clarista" />

          <h3 className="section-heading">Flows</h3>
          {!editFlowName ? <p className="subtitle">{newFlowName}</p> :
            <input type='text' className='form-control' value={newFlowName} onChange={(e) => setnewFlowName(e.target.value)}></input>
          }
          {
            editFlowName ? ''
              :
              <Buttons
                props={{
                  tooltip: "Rename Flow",
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon mx-2",
                  buttonEvent: (e) => {
                    e.stopPropagation()
                    if (!isEditPermission) return
                    editFlowNameHandle();
                  }, ImgSrc: () => <RENAME />,
                  isDisable: !isEditPermission,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }}
              />
          }

          <Tooltip title={bookmark ? Literals.REMOVE_FAVOURITES : Literals.ADD_FAVOURITES}><button className={bookmark ? 'custom-btn custom-btn-outline table-icon  pb-1 cursor-pointer filled-star-icon' : 'custom-btn custom-btn-outline table-icon pb-1 cursor-pointer star-icon'}
            onClick={(e) => { bookmarkData(flowId); e.stopPropagation() }}>
            {bookmark ? <FILLEDSTAR /> : <STAR />}
          </button></Tooltip>
        </div>
        <div className="d-flex align-items-center">
          <ClusterStatusNotification fromRoute={"flows"} />
          <Notifications isPulse={true} />
          {
            history_id ?
              <div style={{ color: "white", display: "table-cell", gap: '10px' }} className="align-items-center d-flex flex-row-reverse h-100 mr-3" >

                <p className="align-items-center d-block d-flex label p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", margin: "0", background: '#ababab' }}>
                  <i className="mr-1 bx bxs-calendar-alt"></i>
                  {versionHistory?.log_time === null ? '' : new Date(versionHistory?.log_time).getUTCDate()}
                  {versionHistory?.log_time === null ? '' : ' ' + getMonthName.short[new Date(versionHistory?.log_time).getUTCMonth()]}
                  {versionHistory?.log_time === null ? '' : ' ' + new Date(versionHistory?.log_time).getUTCFullYear()}
                </p>
                <p className="align-items-center d-flex font-w-600 mb-0 p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", textAlign: "end", margin: "0", background: '#ababab' }}>
                  <i className="mr-1 bx bxs-time"></i>{versionHistory?.log_time === null ? '00:00' : getTime(versionHistory?.log_time)}
                </p>
                <p className="align-items-center d-flex font-w-600 mb-0 p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", textAlign: "end", margin: "0", background: '#ababab' }}>
                  <i className="mr-1 bx bxs-purchase-tag"></i>{`version: ${versionHistory?.log_id}`}
                </p>
              </div> : ""
          }
          {/* {saveFlowTime ? <span className='label label-gray text-small mr-2'> <CLOCK color='#828282' height='15' width='15' /> {saveFlowTime ? getRelativeTime(saveFlowTime) : ''}</span> : null} */}
          {parameterIcon}
          <Buttons
            props={{
              tooltip: `Show Lineage`,
              buttonText: 'Lineage',
              buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon",
              buttonEvent: () => {
                if (isEditPermission) {
                  let path = `/viewLineage/Flow/${flowId}`;

                  window.open(path)
                } else {

                }

              },
              ImgSrc: () => <LINEAGE_ICON />,
              isDisable: !isEditPermission,
              buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
            }}

          />

          <Buttons props={{ buttonText: '', tooltip: "Execute Flow", buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { executeCompleteFlow() }, ImgSrc: () => <PLAY />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
          {/* {isViewMode ? <Buttons props={{
            tooltip: "Edit Flow", buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {
              dispatch(EditFlowsAPI({ ApiType: "GET", flow_Id: flowId })).then((res) => {
                if (res.status === 'success') {
                  history(`${Literals.links.FLOWS_EDIT}${flowId}`, { replace: true })
                }
              });
            }, ImgSrc: () => <EDIT />, isDisable: !isEditPermission || disableExecuteFlow, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
          }} /> : null} */}
          {/* {!showJupyterNoteBook && !SQLEditMode && !showDatabricksNotebook ? <Tooltip title="Save Flow"> <Buttons props={{ buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { saveFlowApi(Literals.BUTTON_UPDATE) }, ImgSrc: () => <SAVE />, isDisable: !isEditPermission, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /></Tooltip> : null} */}
          {!showJupyterNoteBook && !showDatabricksNotebook ? <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1", tooltip: "Close Flow", tooltipPlacement: "bottomRight", buttonEvent: () => { saveFlowApi() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} /> : null}

        </div>
      </div>
      {
        getConfirmationModal({
          open: openWarningModal,
          setOpen: setOpenWarningModal,
          body: currentConditionTypeRef?.current?.message,
          onConfirm: currentConditionTypeRef?.current?.onConfirm,
          onCancel: currentConditionTypeRef?.current?.onCancel
        })
      }
      <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent} />

      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={getDeleteMessage({
          title: 'Parameter'
        })}
        onConfirm={() => handleRemoveParam(parameterToDelete?.current?.id, parameterToDelete.current?.p)}
      />

      <NewFlowsPopUp openModal={openModal} setOpenModal={(value) => {
        setOpenModal(value);
        seteditFlowName(false)
      }} editFlowName={editFlowName} CurrentflowName={newFlowName} flowDesc={store.getState().Lab.description} flowId={flowId} />

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // flowName: state.Lab.name,
    // flowsElements: state.Lab.flowsElements,
    // currentTransformer: state.Lab.currentTransformer,
    // // transformerProgress: state.Lab.transformerProgress,
    // SQLEditMode: state.Lab.SQLEditMode
  };
};
export default Header