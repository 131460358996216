import React from 'react'
import "./status.scss"

const Status = ({Icon, message, color, background}) => {
    return (
        <div className="status-container" style={{background: background}}>
            <span>{Icon}</span><span style={{color: color}}>{message}</span>
        </div>
    )
}

export default Status;