import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import { useStore } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import SingleSelection from '../../Common/dropDown/SingleSelection';
import { getObjectLength, preventSpecialCharsRegex } from '../common/helperFunctions';
import Buttons from '../../Common/button/Buttons';
import { CLOSE, SAVE } from '../../Common/iconSource';
import { Literals } from '../common/literals';
import { DatePicker, Switch, TimePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { scheduleClusterAction } from '../../../store/modules/cluster/Cluster';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import MultiSelection from '../../Common/dropDown/MultiSelection';
import moment from 'moment-timezone';
import { ICON_INFO_FILLED } from '../../Common/newIconSource';
import { emitToastNotification } from '../../../helpers/toast_helper';
import TimeZoneDropdown from '../scheduler/components/TimeZoneDropdown';

const ScheduleCluster = ({ openModal, setOpenModal, getClusterListApi, showStopped, selectedScheduleItem }) => {

    const history = useNavigate();
    const [dayselection, setdayselection] = useState([])
    const [start_time, setstart_time] = useState(null)
    const [end_time, setend_time] = useState(null);
    const [validUntil, setvalidUntil] = useState(selectedScheduleItem?.scheduled_until);
    const [isDisable, setisDisable] = useState(false);
    const [is_active, setis_active] = useState(selectedScheduleItem?.is_scheduled);
    const [selectedTimeZone, setselectedTimeZone] = useState(moment.tz.guess())

    const store = useStore()

    useEffect(() => {
        setdayselection([]);
        setstart_time(null)
        setend_time(null)
        setvalidUntil(null)
    }, [])

    useEffect(() => {
        // if (selectedScheduleItem?.schedule_config?.start_days && selectedScheduleItem?.schedule_config?.end_days) {

        //     let selectedConfig = convertUtcToLocal(selectedScheduleItem?.schedule_config?.start_time, selectedScheduleItem?.schedule_config?.end_time, selectedScheduleItem?.schedule_config?.start_days, selectedScheduleItem?.schedule_config?.end_days, moment.tz.guess())
        //     let localstartDays = daysList?.map((item) => {
        //         if (selectedConfig?.localStartDays?.includes(item.key)) {
        //             return item.value
        //         }
        //     }).filter(item => item);

        //     let localendDays = daysList?.map((item) => {
        //         if (selectedConfig?.localEndDays?.includes(item.key)) {
        //             return item.value
        //         }
        //     }).filter(item => item);
        //     setdayselection(localendDays);
        //     setstart_time(selectedConfig?.startLocalTime)
        //     setend_time(selectedConfig?.endLocalTime)
        //     setvalidUntil(selectedScheduleItem?.scheduled_until)
        //     setis_active(selectedScheduleItem?.is_scheduled)
        // } else {
        setdayselection(selectedScheduleItem?.schedule_config.start_days);
        setstart_time(selectedScheduleItem?.schedule_config?.start_time)
        setend_time(selectedScheduleItem?.schedule_config?.end_time)
        setvalidUntil(selectedScheduleItem?.scheduled_until)
        setis_active(selectedScheduleItem?.is_scheduled)
        // }

    }, [selectedScheduleItem])




    let daysList = [
        { key: 1, Name: "Monday", Type: "", value: "MON" },
        { key: 2, Name: "Tuesday", Type: "", value: "TUE" },
        { key: 3, Name: "Wednesday", Type: "", value: "WED" },
        { key: 4, Name: "Thursday", Type: "", value: "THU" },
        { key: 5, Name: "Friday", Type: "", value: "FRI" },
        { key: 6, Name: "Saturday", Type: "", value: "SAT" },
        { key: '0', Name: "Sunday", Type: "", value: "SUN" }
    ];



    const CreateCluster = () => {

        let payload = {
            "days": dayselection,
            "start_days": dayselection,  //utcstartDays,
            "end_days": dayselection, //utcendDays,
            "start_time": start_time && start_time !== "" ? start_time : null,//newUTCTimeandDays?.startUtc ?? null,
            "end_time": end_time && end_time !== "" ? end_time : null,//newUTCTimeandDays?.endUtc ?? null,
            "scheduled_until": null,
            "cron_timezone": selectedTimeZone,
            is_scheduled: is_active
        }
        store.dispatch(scheduleClusterAction(selectedScheduleItem?.id, payload)).then((resp) => {
            setisDisable(false)
            setOpenModal(false);
            getClusterListApi(showStopped)
        }).catch(e => setisDisable(false))
        // }




    }

    const disabledEndTime = (current) => {
        if (!start_time) {
            return false;
        }

        const startTimePlus15 = dayjs(start_time, 'HH:mm').add(15, 'minute');
        console.log(current, startTimePlus15)
        return current && current.isBefore(startTimePlus15, 'minute');
    };



    return (
        <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true}>
            <ModalHeader>

                <div className="d-flex align-items-center">

                    <h2 className="section-heading">{'Schedule Cluster'}</h2>

                </div>
                <div className="tab-menu right-flow-header">
                    {/* <Tooltip title={is_active ? 'Scheduled' : 'Unscheduled'}>
                        <Switch
                            className={`custom-switch-btn mr-3`}
                            checkedChildren="Yes" unCheckedChildren="No"
                            checked={is_active}
                            onChange={(e) => { setis_active(e) }}
                            disabled={false}
                        />
                    </Tooltip> */}
                    <Buttons props={{
                        buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {
                            setOpenModal(false)

                        }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                    }} />
                </div>
            </ModalHeader>
            <ModalBody >

                <div className='d-flex align-items-center justify-content-center w-100'>
                    <div className='col-6'>

                        <MultiSelection props={{
                            label: "Select Days ", placeholder: "", dropDownValue: dayselection, onClickEvent: (value) => {
                                setdayselection(value)
                            }, optionList: daysList, isSortOptionDisable: true, isDisable: false
                        }} />

                    </div>
                    <div className='col-6'>
                        <TimeZoneDropdown fromRoute='cluster' selectedTimeZone={selectedTimeZone} setselectedTimeZone={setselectedTimeZone} />

                    </div>
                    {/* <div className='col-6 '>
                        <label className="label"> Scheduled Until  </label>
                        <DatePicker
                            picker="date"
                            placeholder="Please select date"
                            className={
                                !validUntil
                                    ? "custom-input-field "
                                    : "custom-input-field"
                            }
                            disabledDate={(current) => current && (current < moment().endOf('day'))}
                            value={validUntil ? dayjs(validUntil, "YYYY-MM-DD") : null}
                            format="YYYY-MM-DD"
                            onChange={(e, val) => {

                                //let value=e.target.value
                                let value = val;
                                setvalidUntil(value)
                            }}
                        />
                    </div> */}

                </div>
                <div className='d-flex align-items-center mt-3 justify-content-center w-100'>
                    <div className='col-6 '>
                        {/* <span className='ml-1' style={{ fontStyle: "italic", fontSize: "9px" }}>{'(UTC hh:mm)Time Zone'}</span> */}
                        <label className="label"> Start Time  </label>
                        <TimePicker picker="time"
                            placeholder="Please select start time"
                            className={
                                "custom-input-field w-100"
                            }
                            minuteStep={15}

                            value={start_time ? dayjs(start_time, 'HH:mm') : null}
                            format={"HH:mm"}
                            changeOnScroll
                            needConfirm={false}
                            disabled={false}

                            onChange={(e, val) => {
                                setstart_time(val)

                            }}
                        ></TimePicker>
                    </div>
                    <div className='col-6'>
                        {/* <span className='ml-1' style={{ fontStyle: "italic", fontSize: "9px" }}>{'(UTC hh:mm)Time Zone'}</span>  */}
                        <label className="label"> End Time </label>
                        {/* <TimePicker picker="time"
                            placeholder="Please select end time"
                            className={
                                "custom-input-field w-100"
                            }
                            minuteStep={15} 
                            
                            value={end_time ? dayjs(end_time, 'HH:mm') : null}
                            format={"HH:mm"}
                            disabled={false}
                            // disabledTime={(current) => {console.log(current)}}
                            onChange={(e, val) => {
                                setend_time(val)

                            }}
                        ></TimePicker> */}
                        <TimePicker
                            picker="time"
                            placeholder="Please select end time"
                            className="custom-input-field w-100"
                            minuteStep={15}
                            value={end_time ? dayjs(end_time, 'HH:mm') : null}
                            format={"HH:mm"}
                            changeOnScroll

                            needConfirm={false}
                            disabled={false}
                            onChange={(e, val) => {
                                setend_time(val)

                            }}
                        />
                    </div>


                </div>


            </ModalBody>
            <ModalFooter>

                <Buttons props={{ buttonText: 'Save', buttonClassName: "custom-btn-primary custom-btn text-center  btn-with-text", buttonEvent: () => { CreateCluster() }, ImgSrc: () => <SAVE />, isDisable: isDisable, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
            </ModalFooter>
        </Modal>
    )
}

export default ScheduleCluster

{/* <span className='ml-1' style={{ fontStyle: "italic", fontSize: "9px" }}>{'(UTC)Time Zone'}</span>  */ }
