import React, { useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux';
import { checkOutcomeColumnPresent, getInputTransformerDetails } from '../commonFunctions/FlowsHelperFunction';
import SingleSelection from '../../../../Common/dropDown/SingleSelection';
import { MERGE_LEFT_ICON, MERGE_RIGHT_ICON, MINUS, PLUS } from '../../../../Common/iconSource';
import Buttons from '../../../../Common/button/Buttons';
import { Literals } from '../../../common/literals';
import { getObjectLength } from '../../../common/helperFunctions';
import { cloneDeep } from 'lodash';
import { Tooltip } from 'antd';
import { LandingSimpleTable } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { emitToastNotification } from '../../../../../helpers/toast_helper';

function MergeKeysSelection({ updatedFlowRedux, isExecutePermission, setshowCloseWarning, setshowOverlay, setupdatedFlowRedux, setToggleDefaultView }) {
    let flowsElements = useSelector(state => state.Lab.flowsElements);
    let currentTransformer = useSelector(state => state.Lab.currentTransformer);
    let store = useStore();

    const [InputTransformers, setInputTransformers] = useState({});
    const [mergeKeysRow, setmergeKeysRow] = useState([]);
    const [tableData, settableData] = useState([]);
    const [columns, setcolumns] = useState([]);
    const [columnList1, setcolumnList1] = useState([]);
    const [columnList2, setcolumnList2] = useState([]);



    const tableColumns = [
        {
            name: <></>,
            key: "input1",
            sortable: false,
            resizable: false,
            // width: 100
        },
        {
            name: <></>,
            key: "input2",
            sortable: false,
            resizable: false,
            // width: 250
        },

    ];
    

    useEffect(() => {
        const getColumn = async () => {
            let InputTransformers = store.dispatch(getInputTransformerDetails())

            let columnList1 = [];
            let columnList2 = [];


            if (InputTransformers && InputTransformers?.length === 2) {
                setToggleDefaultView(false);
                setInputTransformers(InputTransformers)
              
                tableColumns[0]['name'] = (<label className="font-w-600 grey-color label mb-0 paragraph">
                    {InputTransformers[0]?.transformerName}
                </label>)
                tableColumns[1]['name'] = (<label className="font-w-600 grey-color label mb-0 paragraph">
                    {InputTransformers[1]?.transformerName}
                </label>)



                setcolumns(tableColumns)
                updateRedux({}, InputTransformers)  //this uncomment to show the updated keys but i think this will update default selction of joint type
                let inputColumns1 = [];
                let inputColumns2 = [];

                if (InputTransformers && InputTransformers?.length) {
                    inputColumns1 = await store.dispatch(checkOutcomeColumnPresent(InputTransformers[0]));
                    inputColumns2 = await store.dispatch(checkOutcomeColumnPresent(InputTransformers[1]));
                }

                if (inputColumns1?.length) {
                    inputColumns1?.forEach((item) => {
                        columnList1.push({
                            id: item.id,
                            Name: item.Name,
                            Type: item.Type,
                            checked: false,
                        });
                    })
                    setshowOverlay(false)
                } else {
                    setshowOverlay(true)
                }

                if (inputColumns2?.length) {
                    inputColumns2?.forEach((item) => {
                        columnList2.push({
                            id: item.id,
                            Name: item.Name,
                            Type: item.Type,
                            checked: false,

                        });
                    })
                    setshowOverlay(false)
                } else {
                    setshowOverlay(true)
                }
                setcolumnList1(columnList1);
                setcolumnList2(columnList2)



            }

        }
        getColumn()
    }, [])

    useEffect(() => {
        let newcurrentTransformer = {}

        if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

            newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
        } else {
            newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

        }

        let firstKeyRows = {}

        firstKeyRows[InputTransformers[0]?.transformerName] = null;
        firstKeyRows[InputTransformers[1]?.transformerName] = null;

        let cloneMergeList = newcurrentTransformer?.content?.primaryKeysCollection;

        if (cloneMergeList?.length) {
            setmergeKeysRow(cloneMergeList)
        } else if (!cloneMergeList?.length) {
            setmergeKeysRow([firstKeyRows])
        }

    }, [updatedFlowRedux])



    const isColumnValuePresent = (columnList, columnValue) => {

        if (columnList?.length && columnValue) {
            let isPresent = columnList.findIndex(element => element.Name === columnValue);
            if (isPresent > -1) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }


    useEffect(() => {
        let temp = [];
        let rows = [...mergeKeysRow];

        if (rows?.length) {
            temp = rows.map((v, index) => ({
                input1: (
                    <>
                        <div className="align-items-center d-flex h-100 justify-content-start">
                            <MERGE_LEFT_ICON></MERGE_LEFT_ICON>
                            <SingleSelection
                                props={{
                                    placeholder: "Select primary key1",
                                    dropDownValue: v[InputTransformers[0]?.transformerName],
                                    onClickEvent: (val) => {
                                        onInputChangeHandler(InputTransformers[0]?.transformerName, val, index, InputTransformers[1]?.transformerName, 'first');
                                    },
                                    allowClear: true,
                                    isSortOptionDisable: true,

                                    optionList: columnList1,
                                    isDisable: !isExecutePermission,
                                    selectClassName: (!isColumnValuePresent(columnList1, v[InputTransformers[0]?.transformerName]))
                                        ? "invalid-column-present col-10 custom-select-dropdown ml-1 mt-1 p-0"
                                        : " w-100 col-10 custom-select-dropdown ml-1 mt-1 p-0",
                                }}
                            />
                        </div>
                    </>
                ),
                input2: (
                    <>
                        <div className="align-items-center right-merge-wrap h-100">
                            <MERGE_RIGHT_ICON />
                            <SingleSelection
                                props={{
                                    placeholder: "Select primary key2",
                                    dropDownValue: v[InputTransformers[1]?.transformerName],
                                    onClickEvent: (val) => {
                                        onInputChangeHandler(InputTransformers[1]?.transformerName, val, index, InputTransformers[0]?.transformerName, 'second');
                                    },
                                    allowClear: true,
                                    isSortOptionDisable: true,

                                    optionList: columnList2,
                                    isDisable: !isExecutePermission,
                                    selectClassName: (!isColumnValuePresent(columnList2, v[InputTransformers[1]?.transformerName]))
                                        ? "invalid-column-present  col-10 custom-select-dropdown ml-1 p-0"
                                        : " col-10 custom-select-dropdown ml-1 p-0",
                                }}
                            />
                            <Buttons
                                props={{
                                    buttonText: "",
                                    buttonClassName: "danger-btn mb-1  custom-btn btn-with-icon ml-2",
                                    buttonEvent: () => {
                                        onRemoveField(index);
                                        setshowCloseWarning(true)
                                    },
                                    toggleBtnValue: "",
                                    ImgSrc: () => <MINUS />,
                                    isDisable: !isExecutePermission,
                                    buttonType: Literals.BTN_TERTIARY,
                                    toggleBtnOption: null,
                                }}
                            />
                        </div>

                    </>
                ),

            }));
            settableData([...temp]);

        }
    }, [mergeKeysRow, columnList1, columnList2, InputTransformers])

    const updateRedux = (newcontent) => {

        let InputTransformers = store.dispatch(getInputTransformerDetails())
        let cloneFlowsElements = {}
        if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {
            cloneFlowsElements = cloneDeep(updatedFlowRedux);

        } else {

            cloneFlowsElements = cloneDeep(flowsElements);
        }
        let selectedFirstSecond1 = {
            datasource0: InputTransformers[0]?.transformerName,
            datasource1: InputTransformers[1]?.transformerName
        }
       
        cloneFlowsElements?.nodes.forEach((item) => {
            if (item.id?.toString() === currentTransformer?.id?.toString()) {
                item.content['selectedFirstSecond'] = selectedFirstSecond1
                // console.log(item.content,newcontent,{...item.content,...newcontent})
                item.content = { ...item.content, ...newcontent };
            }
        })
        // console.log(cloneFlowsElements)
        setupdatedFlowRedux(cloneFlowsElements)
    }

    const getDataType = (actualDataType = '', index) => {

        if (actualDataType.includes('(')) {
            return actualDataType.split('(')[0]
        } else {
            return actualDataType
        }

    }

    const onInputChangeHandler = (key, value, index, key2, dropDownChange) => {
        let cloneMergeList = cloneDeep(mergeKeysRow);

        let dataType1 = null;
        let dataType2 = null;

        if (dropDownChange === 'first') {
            dataType1 = columnList1.find(item => item?.Name === value)?.Type;
            dataType2 = columnList2.find(item => item?.Name === cloneMergeList[index][key2])?.Type;

        } else {
            dataType1 = columnList2.find(item => item?.Name === value)?.Type;
            dataType2 = columnList1.find(item => item?.Name === cloneMergeList[index][key2])?.Type;

        }


        if ((!cloneMergeList[index][key2] && !cloneMergeList[index][key]) || (dataType1 && dataType2 && value && ((getDataType(dataType1)?.toLowerCase() === getDataType(dataType2)?.toLowerCase()) || (getDataType(dataType1)?.toLowerCase()?.includes('char') && getDataType(dataType2)?.toLowerCase()?.includes('char'))))) {
            cloneMergeList[index][key] = value;
        } else if (value && dataType1 && dataType2 && getDataType(dataType1)?.toLowerCase() !== getDataType(dataType2)?.toLowerCase()) {
            emitToastNotification('warning', `Data type should be same for merge keys`)
        }
        else {
            cloneMergeList[index][key] = value;

        }


        setmergeKeysRow(cloneMergeList);
        let newObj = {};
        newObj[key] = cloneMergeList[index][key];
        newObj[key2] = cloneMergeList[index][key2];

        cloneMergeList[index] = newObj;

        let newcontent = {
            primaryKeysCollection: cloneMergeList
        }
        setshowCloseWarning(true);



        updateRedux(newcontent)


    }

    const onAddKeys = () => {
        let cloneMergeList = cloneDeep(mergeKeysRow);
        let emptyRow = {}
        emptyRow[InputTransformers[0]?.transformerName] = null;
        emptyRow[InputTransformers[1]?.transformerName] = null;

        cloneMergeList = [...mergeKeysRow, emptyRow]

        setmergeKeysRow(cloneMergeList);
        let newcontent = {
            primaryKeysCollection: cloneMergeList
        }
        setshowCloseWarning(true)

        updateRedux(newcontent)

    }

    const onRemoveField = (index) => {
        let cloneMergeList = cloneDeep(mergeKeysRow);

        cloneMergeList.splice(index, 1)
        setmergeKeysRow(cloneMergeList);

        let newcontent = {
            primaryKeysCollection: cloneMergeList
        }
        updateRedux(newcontent)
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center padding-2 py-2">
                <div className="d-flex align-items-center">

                    <p className="section-caption ">
                        Merge Keys
                        <Tooltip
                            title={
                                "Create a group for each unique combination of these column"
                            }
                            placement="bottom"
                        >
                            {" "}
                            <i
                                className="bx bxs-info-circle"
                                style={{ color: "#828282", fontSize: "0.75rem" }}
                            ></i>
                        </Tooltip>{" "}
                    </p>
                </div>


                <div className="d-flex align-items-center">
                    <Buttons
                        props={{
                            buttonText: "Add",

                            buttonClassName:
                                "custom-btn custom-btn-outline btn-outline btn-with-text",
                            buttonEvent: () => { onAddKeys() },
                            ImgSrc: () => <PLUS />,
                            isDisable: !isExecutePermission,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </div>
            </div>
            <div className='key-table'>

                <LandingSimpleTable
                    rowsData={tableData}
                    cols={columns}
                    tableHeight={"calc(100vh - 24.5rem)"}
                />

            </div>
        </div>
    )
}

export default MergeKeysSelection