import React, { useState, useRef, useCallback } from "react";
// import ReactFlow, {
//   ReactFlowProvider,

//   Controls
// } from "react-flow-renderer";
import ReactFlow, { Controls } from 'reactflow';
import 'reactflow/dist/style.css';

import "./styles.scss";
import InitialElements from "./initialElements";

let id = 0;
const getId = () => `dndnode_${id++}`;

const onInit = (instance) => {
  setTimeout(() => {
    instance.fitView()
  }, 100)
}
export default function App({ elements, model, resetEdgeColors }) {
  const reactFlowWrapper = useRef(null);
  const onLoad = useCallback((instance) => {
    if (instance && instance !== null) {
      let yaxis = -600;
      if (model.toLowerCase() === 'connection' || model.toLowerCase() === 'domain' || model.toLowerCase() === "source") {
        yaxis = 0
      }
      if (model.toLowerCase() === "pod") {
        yaxis = -600
      }
      instance.setTransform({ x: 100, y: yaxis, zoom: 1})
     

      instance.fitView({ padding: 0.1, includeHiddenNodes: true })
    }
  }, []);

  const initialNodes = elements.filter(item => !item.source)
  const initialEdges = elements.filter(item => item.source)
  // const defaultViewport = { x: 200, y: 200, zoom: 0.1 };
  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  // const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  return (
    <div style={{ height: '90vh' }} className="dndflow">
      <ReactFlow ref={reactFlowWrapper} onInit={onLoad} nodes={initialNodes} edges={initialEdges} 
        minZoom={0.1}
       
        fitView >
        <Controls style={{ display: "flex", flexDirection: "row", right: "0px" }} />
      </ReactFlow>
      {/* <ReactFlowProvider>
        <div
          className="reactflow-wrapper"
          style={{ height: "calc(100vh - 72px)", width: "500px" }}
          ref={reactFlowWrapper}
        >
          <ReactFlow
            elements={elements}
            onLoad={onLoad}
            onClick={resetEdgeColors}
            nodesDraggable={false}
            fitView
          >
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider> */}
    </div>
  );
}
