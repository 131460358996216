import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Buttons from "../../../../Common/button/Buttons";
import { Literals } from "../../../common/literals";
import { CLOSE, SAVE } from "../../../../Common/iconSource";
import { getUserGroupList } from "../../../../../store/modules/UserManagement/users/usersActions";
import { emitToastNotification } from "../../../../../helpers/toast_helper";

const UserGroupSecondaryHeader = ({
  viewMode,
  groupId,
  groupName,
  groupDescription,
  selectedUsers,
  isLeader = false
}) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const history = useNavigate();

  const isSuperUser = useSelector(state => state.UserReducer.user.userDetails.is_superuser)

  const createUserGroupAPI = (type, groupId, payload) => {
    let endPoint;
    let method = "POST";

    if (type === "createGroup") {
      endPoint = "group_manager/group/";
    } else if (type === "editGroup") {
      endPoint = `group_manager/group/${groupId}/`;
      method = "PATCH";
    } else if (type === "addMember") {
      endPoint = `group_manager/group/${groupId}/add_member_user/`;
    } else {
      endPoint = `group_manager/group/${groupId}/add_leader_user/`;
    }

    return dispatch(
      getUserGroupList({
        method,
        endPoint,
        payload,
      })
    );
  };

  const extractUserIds = () => {
    const memberIds = [];
    const leaderIds = [];

    selectedUsers.forEach((user) => {
      if (user.type === "leader") {
        leaderIds.push(user.id);
      } else {
        memberIds.push(user.id);
      }
    });

    return [memberIds, leaderIds];
  };

  const createUserGroupHandler = async () => {
    try {
      const response = await createUserGroupAPI("createGroup", null, {
        name: groupName,
        description: groupDescription,
      });

      if (response.status === "success") {
        const groupId = response?.data?.id;

        const [memberIds, leaderIds] = extractUserIds();

        if (memberIds.length) {
          await createUserGroupAPI("addMember", groupId, {
            ids: memberIds,
          });
        }

        if (leaderIds.length) {
          await createUserGroupAPI("addLeader", groupId, {
            ids: leaderIds,
          });
        }

        history(redirectBack());
      }
    } catch (error) {
      console.error("Error creating user group", error.message);
      emitToastNotification("error", error.message);
    }
  };

  const editUserGroupHandler = async () => {
    try {
      const response = await createUserGroupAPI("editGroup", groupId, {
        description: groupDescription,
        name: groupName,
      });

      if (response.status === "success") {
        const [memberIds, leaderIds] = extractUserIds();

        if (memberIds.length) {
          await createUserGroupAPI("addMember", groupId, {
            ids: memberIds,
          });
        }

        if (leaderIds.length) {
          await createUserGroupAPI("addLeader", groupId, {
            ids: leaderIds,
          });
        }

        history(redirectBack());
      }
    } catch (error) {
      console.error("Error creating user group", error.message);
      emitToastNotification("error", error.message);
    }
  };
  const redirectBack = () => {

    return Literals?.links?.USER_MANAGEMENT + '#usergroup'

    // if (location?.state && location?.state?.tabName) {

    //   return { pathname: Literals.links.USER_MANAGEMENT, state: { tabName: location?.state?.tabName } }
    // }
    // else {
    //   return { pathname: Literals.links.USER_MANAGEMENT }
    // }
  }
  const saveUserGroupHandler = () => {
    if (viewMode.includes("edit")) {
      editUserGroupHandler();
    } else {
      createUserGroupHandler();
    }
  };

  return (
    <div className="sub-header create-pod-secondary-header">
      <div className="sub-header-left">
        <h2 className="section-heading">{window.location.href?.includes('/editUserGroup')?
        'Edit Usergroup':'Create Usergroup'}</h2>
      </div>
      <div className="sub-header-right">
        <Buttons
          props={{
            buttonText: "",
            buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
            buttonEvent: () => {
              history(redirectBack());
            },
            ImgSrc: () => <CLOSE />,

            isDisable: false,
            buttonType: Literals.BTN_TERTIARY,
          }}
        />
        <Buttons
          props={{
            buttonText: "Save",
            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
            buttonEvent: () => {
              saveUserGroupHandler();
            },
            ImgSrc: () => <SAVE />,
            isDisable: !(isSuperUser || isLeader),
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
      </div>
    </div>
  );
};

export default UserGroupSecondaryHeader;
