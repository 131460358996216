import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { Dropdown, Input, Menu, Tooltip } from "antd";
import ActionPopUp from '../../../common/actionPopUp';
import { deleteDomainTags, deleteSavedTag, setTagList, updateDomainTags, updateTag } from '../../../../../store/modules/dataCatalogue/landingPage/landingPageActions';
import { getLandingPageData, getTagList } from '../../../../../store/modules/dataCatalogue/landingPage/landingPageActions';
import { CLOSE, DELETEICON, EDIT, SAVE } from '../../../../Common/iconSource';
import { Literals } from "../../../common/literals";
import Buttons from '../../../../Common/button/Buttons';
import { toTitleCase } from '../../../common/helperFunctions';
import { ICON_DELETE, ICON_LOADING_CIRCLE } from '../../../../Common/newIconSource';
import { emitToastNotification } from '../../../../../helpers/toast_helper';

const LandingPageTagList = ({ 
    tagData, 
    tags, 
    setAllTags = () => {}, 
    unChangedTagData,
    disableButtons = false,
    domainId
}) => {
    const dispatch = useDispatch();

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const [openRenamePopup] = useState(false);
    const [renamePopUpContent] = useState(undefined);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);


    const deleteTag = () => {
        setOpenDeletePopup(false);
        setDeleting(true)
        dispatch(deleteDomainTags(tagData?.id))
        .then(
            () => {

                let prevData = unChangedTagData
                prevData = prevData?.filter(t => t?.id !== tagData?.id)
                dispatch(setTagList(prevData));

                setDeleting(false)
                // dispatch(getTagList())
                // dispatch(getLandingPageData())
            }
        )
        .catch(() => {
            setDeleting(false)
        })
    }

    const closeDeletePopUp = () => {
        setOpenDeletePopup(false)
    }


    const openDeleteDialog = () => {
        setDeletePopUpContent(
            {
                heading: "Delete",
                modalClass: "delete-popup",
                message: "This action will delete tag permanently.",
                headingIcon: "bx bx-error",
                button: [
                    {
                        name: "Delete",
                        action: deleteTag,
                    },
                    {
                        name: "Cancel",
                        action: closeDeletePopUp,
                    }
                ],
                elements: [],
                closeActionPopup: closeDeletePopUp
            }
        )
        setOpenDeletePopup(true);
    }


    const tagColor = (colorname) => {
        let color = '';
        for (let x in tags) {
            if (tags[x].id === colorname) {
                color = tags[x].color;
                break;
            }
        }
        
        return color+'95';
    }
    const connMenu = (
        <span onClick={(e) => openDeleteDialog(e)}>
          {/* <Menu.Item key="2" > */}
            <i className="bx bx-trash-alt"></i>
          {/* </Menu.Item> */}
        </span>
    )

    const handleInput = (val) => {
        setAllTags(prev => {
            return prev.map(v => ({...v, name: v.id === tagData?.id ? val : v.name}))
        })
    }

    const handleEdit = () => {
        setAllTags(prev => {
            return prev.map(v => (
                {
                    ...v,
                    isEdit: v.id === tagData?.id, 
                    name: v.isEdit ? unChangedTagData.find(itm => itm.id === v?.id).name : v.name 
                }
            ))
        })
    }

    const handleClose = () => {
        let originalName = unChangedTagData.find(v => v.id === tagData?.id).name??''
        setAllTags(prev => {
            return prev.map(v => (
                {
                    ...v,
                    isEdit: v.id === tagData?.id ? false : v.isEdit, 
                    name: v.id === tagData?.id ? originalName : v.name
                }
            ))
        })
    }

    const handleUpdate = () => {

        if(tagData?.name?.trim() === "") {
            emitToastNotification("error", "Tag Name Cannot Be Empty")
            return
        }

        setUpdating(true)
        let payload = {
            name: tagData?.name,
            color: tagData?.color,
            data_domain: domainId
        }
        dispatch(updateDomainTags(tagData?.id, payload))
        .then((res) => {
            let data = res?.data
            let prevData = unChangedTagData
            prevData = prevData?.map(t => t?.id === data?.id ? ({...data, isEdit: false}) : ({...t}))
            dispatch(setTagList(prevData));
            // dispatch(getTagList())
            // .then((res)=>{
            //     setAllTags(() => {
            //         return res.data.map(v => (
            //             {
            //                 ...v,
            //                 isEdit: false, 
            //                 name: v.name
            //             }
            //         ))
            //     })
            //     dispatch(getLandingPageData())
            // })
            setUpdating(false)
            handleClose()
        })
        .catch(()=>{
            setUpdating(false)
        })
    }

    return (
        <>
            <div className='landingCard-tag-menu-list'>
                <div className="landingCard-tag-menu-list_left w-100">
                    <ul className="landingCard-tag-menu-ullist">
                        <li className="landingCard-tag-menu-listItem">
                            <i className='bx bxs-circle mr-2' style={{ color: `${tagColor(tagData?.color)}` }}></i>
                            {
                                tagData?.isEdit
                                ?   <Input 
                                        className='custom-input-field text-uppercase' 
                                        defaultValue={tagData?.name?.toUpperCase()} 
                                        onChange={(e) => handleInput(e.target.value?.toUpperCase())}
                                    />
                                :   <Tooltip 
                                        placement='topLeft' 
                                        title={tagData?.name?.toUpperCase()}
                                    >
                                        <span className="paragraph text-with-ellipsis">{tagData?.name?.toUpperCase()}</span>
                                    </Tooltip>
                            }
                            {/* <span className="paragraph">{tagData?.name}</span> */}
                        </li>
                    </ul>
                </div>
                <div className="landingCard-tag-menu-list_right d-flex align-items-center ml-1">
                    {
                        tagData?.isEdit
                        ?   <>
                                <Buttons 
                                    props={{ 
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1", 
                                        buttonEvent: () => handleUpdate(), 
                                        ImgSrc: () => updating ? <ICON_LOADING_CIRCLE/> : <SAVE />, 
                                        isDisable: disableButtons || updating || tagData?.name?.trim() === "", 
                                        buttonType:Literals.BTN_PRIMARY_WITH_TEXT
                                        }} 
                                />
                                <Buttons 
                                    props={{ 
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon", 
                                        buttonEvent: () => handleClose(), 
                                        ImgSrc: () => <CLOSE />, 
                                        isDisable: disableButtons, 
                                        buttonType:Literals.BTN_PRIMARY_WITH_TEXT
                                        }} 
                                />
                            </>
                        :   <>
                                <Buttons 
                                    props={{ buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1", 
                                    buttonEvent: () => handleEdit(), 
                                    ImgSrc: () => <EDIT />, 
                                    isDisable: disableButtons || deleting, 
                                    buttonType:Literals.BTN_PRIMARY_WITH_TEXT
                                    }} 
                                />
                                <Buttons 
                                    props={{ buttonClassName: "custom-btn-outline error-red custom-btn btn-with-icon", 
                                    buttonEvent: () => { openDeleteDialog() }, 
                                    ImgSrc: () => deleting ? <ICON_LOADING_CIRCLE/> : <ICON_DELETE />, 
                                    isDisable: disableButtons || deleting, 
                                    buttonType:Literals.BTN_PRIMARY_WITH_TEXT
                                    }} 
                                />
                            </>
                    }
                </div>
            </div>
            <ActionPopUp openModal={openDeletePopup} actionData={deletePopUpContent} />
            <ActionPopUp openModal={openRenamePopup} actionData={renamePopUpContent} />
        </>
    )
}
export default LandingPageTagList