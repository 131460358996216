import React, { useEffect, useRef, useState } from "react";

import { connect, useDispatch, useStore } from "react-redux";


import { checkOutcomeColumnPresent, getInputTransformerDetails, orderRetainCheck, orderRetainMergeCheck } from "../commonFunctions/FlowsHelperFunction";
import _, { cloneDeep } from "lodash";
import { getConfirmationModal, getObjectLength, mergeArrayByProperty, mergeArrayByPropertyJoinType, showSimpleLoading } from "../../../common/helperFunctions";

import { RESETFILTER } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import Buttons from "../../../../Common/button/Buttons";
import DndMergeColumnList from "../commonFunctions/dndMultiColumnsVirtualList/DndMergeColumnList";
import SearchInput from "../../../../Common/search/Search";
import NoResultsFound from "../../createFlows/NoResultsFound";
import { Input } from "antd";



const ColumnSelection = ({
    flowsElements,
    isExecutePermission,
    currentTransformer,
    updatedFlowRedux,
    setupdatedFlowRedux,
    setshowCloseWarning,
    setshowOverlay,
    ...props
}) => {
    const [columnList, setcolumnList] = useState([]);
    const [originalColumnList, setoriginalColumnList] = useState([]);

    const [reOrderRender, setreOrderRender] = useState(true)
    const [openWarningModal, setopenWarningModal] = useState(false)
    const [showResetColumns, setshowResetColumns] = useState(false)
    const [searchText, setsearchText] = useState('')
    const [showLoader, setshowLoader] = useState(false)

    const store = useStore();
    const dispatch = useDispatch();


    const columnListRef = useRef([])
    useEffect(() => {
        let list = cloneDeep(columnList);
        // let checkedName = list.map(item => item?.checked ? item.id : undefined).filter(item=>item)
        let cloneOriginalColList = cloneDeep(originalColumnList);

        cloneOriginalColList.forEach((col) => {
            let checkedIndex = list.findIndex(item => item?.id === col.id);
            if (checkedIndex > -1) {
                col.checked = list[checkedIndex]['checked'];

            }

        })
        setoriginalColumnList(cloneOriginalColList);

        if (cloneOriginalColList && cloneOriginalColList.length) {

            columnListRef.current = cloneOriginalColList;

        }
        let currentFlowsElements = {}

        if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

            currentFlowsElements = cloneDeep(updatedFlowRedux)
        } else {
            currentFlowsElements = cloneDeep(flowsElements)

        }

        let nodes = currentFlowsElements?.nodes?.map(item => {
            if (item.id === currentTransformer.id) {
                let list = columnListRef.current.filter(item => item?.checked);

                item.content.orderList = columnListRef.current;
                item.content.selectedColumns = list?.length ? list : [];


            }
            return item
        })
        let finalFlowsElements = cloneDeep(flowsElements)
        finalFlowsElements['nodes'] = nodes;
        setupdatedFlowRedux(finalFlowsElements)
        // store.dispatch(updateFlowsElements(finalFlowsElements))
        // }
    }, [columnList])



    const Selectall = (checked) => {
        if (checked) {
            let temp = columnList.map((ele) => {
                ele.checked = true;
                return ele;
            });
            setcolumnList([...temp]);

        } else {
            let temp = columnList.map((ele) => {
                ele.checked = false;
                return ele;
            });
            setcolumnList([...temp]);

        }
        setshowCloseWarning(true)

    };

    const getDataType = (actualDataType = '', index) => {

        if (actualDataType.includes('(')) {
            return actualDataType.split('(')[0]
        } else {
            return actualDataType
        }

    }
    const getDataTypeFormat = (actualDataType = '', index) => {

        if (actualDataType.includes('(')) {
            let splitStr = actualDataType.split('(');

            return splitStr[1]?.split(')')[0];
        } else {
            return ''
        }

    }

    const createColumnsList = (target = [], source = [], prop) => {

        let newColumnList = [];
        if (!target?.length) {
            source.forEach(sourceElement => {
                sourceElement['showError'] = false
                // sourceElement['Alias'] = sourceElement?.Alias ? sourceElement.Alias : sourceElement.Name
                newColumnList.push(sourceElement);
            })
            return newColumnList;
        } else {

            let elementFound = iselementFound();
            let noneFindElement = isnoneFindElement();


            if (elementFound?.length && noneFindElement?.length) {
                newColumnList = [...elementFound, ...noneFindElement]
                setshowResetColumns(true);

            }
            else if (elementFound?.length) {
                newColumnList = [...elementFound]

            } else if (noneFindElement?.length) {
                newColumnList = [...noneFindElement]
                setshowResetColumns(true);



            } else {
                setshowResetColumns(false)

            }
            let cloneSource = cloneDeep(source)

            const getChecked = (column) => {
                if (elementFound?.length) {
                    return elementFound.find(item => item?.Name === column?.Name && item['frame'] === column['frame'])?.checked
                } else if (target?.length) {

                    return target.find(item => item?.Name === column?.Name)?.checked
                }
            }
            cloneSource = cloneSource?.map(sourceElement => {
                sourceElement['showError'] = false;
                sourceElement['checked'] = getChecked(sourceElement);
                // sourceElement['Alias'] = sourceElement.Alias ? sourceElement.Alias : sourceElement.Name;
                return sourceElement;
            })
           
            let mergeList = cloneDeep(mergeArrayByPropertyJoinType([...cloneSource], [...newColumnList], 'Name', true))  //----------------------------this will return unique column remove duplicates from source columns i.e if duplicate found it will take newColumnList column
            let newcurrentTransformer = {}

            if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

                newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id?.toString() === currentTransformer.id?.toString())
            } else {
                newcurrentTransformer = flowsElements?.nodes.find((item) => item.id?.toString() === currentTransformer.id?.toString())

            }

            let sortedMergeList = mergeList?.sort((a, b) => {
                if (a.dataSource < b.dataSource) return -1;
                if (a.dataSource > b.dataSource) return 1;
                return 0;
            });
            let orderList = newcurrentTransformer?.content?.orderList;

            if (orderList?.length) {

                return orderRetainMergeCheck([...orderList], [...sortedMergeList]);
            } else {
                return mergeList
            }

        }

        function isnoneFindElement() {

            if (target?.length) {
                let targetElement = cloneDeep(target)
                source.forEach(sourceElement => {
                    targetElement = targetElement.filter((item) => {
                        if (item['Name'] !== sourceElement['Name']) {
                            item['showError'] = true;
                            item['checked'] = true;
                            // item['Alias'] = item.Alias ? item.Alias : item.Name
                            return item;
                        }
                    })
                })
                return targetElement
            }

        }

        function iselementFound() {

            if (target?.length) {
                let targetElement = cloneDeep(target)
                let foundEleList = []
                source.forEach(sourceElement => {
                    targetElement.forEach((item) => {
                        if (item['Name'] === sourceElement['Name'] && item['frame'] === sourceElement['frame']) {
                            let obj = cloneDeep(sourceElement)
                            obj['showError'] = false;
                            obj['checked'] = true
                            obj['Alias'] = item.Alias ? item.Alias : item.Name

                            foundEleList.push(obj)
                        }
                    })
                })
                return foundEleList


            }

        }



    }


    useEffect(() => {

        const getColumn = async () => {
            let InputTransformer = dispatch(getInputTransformerDetails());


            let inputColumns = [];

            let InputTransformers = store.dispatch(getInputTransformerDetails())
       
            if (InputTransformers && InputTransformers?.length === 2) {

                let inputColumns1 = [];
                let inputColumns2 = [];
                setshowLoader(true)
                if (InputTransformers && InputTransformers?.length) {
                    inputColumns1 = await store.dispatch(checkOutcomeColumnPresent(InputTransformers[0]));
                    inputColumns2 = await store.dispatch(checkOutcomeColumnPresent(InputTransformers[1]));
                }


                if (inputColumns1?.length) {
                    let transformerName = InputTransformers[0]?.transformerName;
                    let podType = `${transformerName}`;

                    inputColumns1.forEach((item, index) => {
                        inputColumns.push({
                            id: `${item.id}_${transformerName}`,
                            dataSource: 'left',
                            Name: item.Name,
                            Type: item.Type,
                            Alias: item.Name,
                            checked: false,
                            frame: podType,
                            showError: false

                        });

                    });
                    setshowOverlay(false)
                } else {
                    setshowLoader(false)

                    setshowOverlay(true)
                }

                if (inputColumns2?.length) {
                    let transformerName = InputTransformers[1]?.transformerName;
                    let podType = `${transformerName}`;

                    inputColumns2.forEach((item, index) => {
                        inputColumns.push({
                            id: `${item.id}_${transformerName}`,
                            dataSource: 'right',
                            Name: item.Name,
                            Type: item.Type,
                            Alias: item.Name,
                            checked: false,
                            frame: podType,
                            showError: false

                        });

                    });

                    setshowOverlay(false)
                } else {
                    setshowLoader(false)

                    setshowOverlay(true)
                }
                let newcurrentTransformer = {}

                if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

                    newcurrentTransformer = cloneDeep(updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id))
                } else {
                    newcurrentTransformer = cloneDeep(flowsElements?.nodes.find((item) => item.id === currentTransformer.id))

                }

                if (InputTransformer && InputTransformer?.length && inputColumns?.length) {

                    let column = []
                    if (!newcurrentTransformer?.content?.selectedColumns && newcurrentTransformer?.content?.outcomeColumns?.length) {

                        let newColumnList = cloneDeep(newcurrentTransformer?.content?.outcomeColumns);
                        newColumnList.forEach((item) => {
                            item['Alias'] = item.Name
                            item['frame'] = item.type

                        })

                        newcurrentTransformer['content']['selectedColumns'] = newColumnList;
                    } else if (newcurrentTransformer?.content?.selectedColumns) {

                        newcurrentTransformer['content']['selectedColumns'] = newcurrentTransformer?.content?.selectedColumns;
                    } else if (!newcurrentTransformer?.content?.selectedColumns && !newcurrentTransformer?.content?.outcomeColumns?.length) {


                        newcurrentTransformer['content']['selectedColumns'] = []
                    }

                    column = createColumnsList(newcurrentTransformer?.content?.selectedColumns, inputColumns, 'Name')

                    if (column?.length) {

                        setcolumnList([...column]);
                        setoriginalColumnList([...column])
                        setshowLoader(false)


                    } else {

                        setcolumnList([])
                        setshowLoader(false)


                    }


                }

            }






        }
        getColumn()

        // let InputTransformer = dispatch(getInputTransformerDetails());


        // let inputColumns = [];

        // InputTransformer.forEach((element) => {
        //     let cloneinputColumns = cloneDeep(element?.content?.outcomeColumns)
        //     if (cloneinputColumns && cloneinputColumns.length) {
        //         let transformerName = element?.transformerName;
        //         let podType = `${transformerName}`;

        //         cloneinputColumns.forEach((item, index) => {
        //             inputColumns.push({
        //                 id: `${item.id}_${transformerName}`,
        //                 dataSource: transformerName === InputTransformer[0].transformerName ? 'left' : 'right',
        //                 Name: item.Name,
        //                 Type: item.Type,
        //                 Alias: item.Name,
        //                 checked: false,
        //                 frame: podType,
        //                 showError: false

        //             });

        //         });
        //     }

        // });
        // let newcurrentTransformer = {}

        // if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

        //     newcurrentTransformer = cloneDeep(updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id))
        // } else {
        //     newcurrentTransformer = cloneDeep(flowsElements?.nodes.find((item) => item.id === currentTransformer.id))

        // }

        // if (InputTransformer && InputTransformer?.length && inputColumns?.length) {

        //     let column = []
        //     if (!newcurrentTransformer?.content?.selectedColumns && newcurrentTransformer?.content?.outcomeColumns?.length) {

        //         let newColumnList = cloneDeep(newcurrentTransformer?.content?.outcomeColumns);
        //         newColumnList.forEach((item) => {
        //             item['Alias'] = item.Name
        //             item['frame'] = item.type

        //         })

        //         newcurrentTransformer['content']['selectedColumns'] = newColumnList;
        //     } else if (newcurrentTransformer?.content?.selectedColumns) {


        //         newcurrentTransformer['content']['selectedColumns'] = newcurrentTransformer?.content?.selectedColumns;
        //     } else if (!newcurrentTransformer?.content?.selectedColumns && !newcurrentTransformer?.content?.outcomeColumns?.length) {


        //         newcurrentTransformer['content']['selectedColumns'] = []
        //     }




        //     column = createColumnsList(newcurrentTransformer?.content?.selectedColumns, inputColumns, 'Name')



        //     if (column?.length) {

        //         setcolumnList([...column]);
        //         setoriginalColumnList([...column])


        //     } else {

        //         setcolumnList([])


        //     }


        // } 

    }, []);


    const currentConditionTypeRef = useRef(null)
    const onResetEvent = () => {
        setshowCloseWarning(true)

        currentConditionTypeRef.current = {
            message: 'Columns that are not found in input source that will be removed',
            onConfirm: () => {
                setreOrderRender(false)
                let cloneCols = cloneDeep(originalColumnList);

                cloneCols = cloneCols.filter((item) => !item.showError);

                if (cloneCols?.length) {

                    setcolumnList([...cloneCols])
                    setoriginalColumnList([...cloneCols])

                }
                setopenWarningModal(false);
                setshowResetColumns(false);

                setTimeout(() => {
                    setreOrderRender(true)
                }, 50);
                return true
            },
            onCancel: () => {
                setopenWarningModal(false);
                return false
            }
        }
        setopenWarningModal(true)
    }
    const getActualData = (columnListClone) => {
        let clone = cloneDeep(originalColumnList);
        // console.log(clone,originalColumnList)
        if (searchText !== '' && clone?.length) {
            return clone?.filter((item) => item.Name?.toLowerCase().includes(searchText?.toLowerCase()) || item.Alias.toLowerCase()?.includes(searchText.toLowerCase()))
        } else {

            return originalColumnList;
        }

    }
    const resetOneTime = useRef(1);
    useEffect(() => {
        if (searchText !== '') {
            resetOneTime.current = 2;
            setreOrderRender(false)
            setTimeout(() => {
                setreOrderRender(true)
            }, 50);
        } else if (resetOneTime.current === 2) {
            setreOrderRender(false)
            setTimeout(() => {
                setreOrderRender(true)
            }, 50);
            resetOneTime.current = 1;
        }
    }, [searchText])

    return (
        <>
            {
                getConfirmationModal({
                    open: openWarningModal,
                    setOpen: setopenWarningModal,
                    body: currentConditionTypeRef?.current?.message,
                    onConfirm: currentConditionTypeRef?.current?.onConfirm,
                    onCancel: currentConditionTypeRef?.current?.onCancel
                })
            }
            <div className="transformer-details-maindiv ">
                <div className="d-flex justify-content-between padding-1 py-1">
                    <div className="d-flex align-items-center">

                        <p className="section-caption">
                            Keep Columns
                        </p>
                    </div>
                    <div className="d-flex align-items-center">
                        <SearchInput searchData={searchText} setSearchData={setsearchText} />
                    </div>

                </div>
                {showResetColumns ? (
                    <Buttons
                        props={{
                            tooltip: 'Reset Columns',
                            buttonText: "Reset Columns",
                            buttonClassName: "custom-btn-outline btn-outline reset-col-btn custom-btn btn-with-text ml-2 mr-2",
                            buttonEvent: onResetEvent,
                            toggleBtnValue: "",
                            ImgSrc: () => <RESETFILTER />,
                            isDisable: !isExecutePermission,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            toggleBtnOption: null,
                        }}
                    />
                ) : null}
                {columnList?.length ? <>

                    <div className="h-100">
                        <div
                            className="" //schemaTransformer-table
                            style={{ overflow: "hidden" }}
                        >
                            {getActualData(columnList).length ? <DndMergeColumnList
                                reOrderRender={reOrderRender} setreOrderRender={setreOrderRender}

                                actualData={getActualData(columnList)}
                                originalColumnList={originalColumnList}
                                stopDraggingRow={
                                    searchText !== '' || !isExecutePermission ? true : false
                                }
                                isExecutePermission={isExecutePermission}
                                setupdatedcolumnList={setoriginalColumnList}
                                setshowCloseWarning={
                                    setshowCloseWarning

                                }

                                setNewColumnList={setcolumnList}
                                Selectall={Selectall}

                            /> : !getActualData(columnList).length && searchText !== '' ? <NoResultsFound imgheight="140" imgwidth="140" height="calc(100vh - 240px)"></NoResultsFound> :
                                null}
                        </div>
                    </div>

                </> : showLoader ? showSimpleLoading('Please wait, input columns are being fetched…') : null}

            </div>



        </>
    );
};
const mapStateToProps = (state) => {
    return {
        flowsElements: state.Lab.flowsElements,
        currentTransformer: state.Lab.currentTransformer,
    };
};
export default connect(mapStateToProps)(ColumnSelection);
