import { emitToastNotification } from "../../../../helpers/toast_helper";
import { fetchData } from "../../../../services/apiService";

const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const dashboardCopilot = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}copilot/generate_dashboard/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const createPodCopilot = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}copilot/generate_sql/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const createBusinessTermCopilot = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}copilot/generate_business_terms/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const createClassifiersCopilot = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}copilot/generate_classifiers/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};