import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import sticky from '../../../../../assets/icons/Sticky.svg';
import { CLOSE, CODEEDITOR, EDIT, EXITFULLSCREEN, FULLSCREEN, PLAY, PYTHONSCRIPTICON, SAVE, SCRIPT, SCRIPTEDITOR } from '../../../../Common/iconSource';
import { Literals } from "../../../common/literals";

import Buttons from '../../../../Common/button/Buttons';
import OutputDataSet from './OutputDataSet';

import { connect, useSelector, useStore } from 'react-redux';

import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { fetchPreviewTable, setActivePodDetails } from '../../../../../store/modules/common/VirtualDataTable/action';
import { executeTransformerApi, saveTransformerNoteClick, setExecutedTransformerPayload, setFlowsCurrenttransformer, setTransformerNames, showFlowsTransformdetails, showJupyterNoteBook, updateFlowsElements } from '../../../../../store/modules/flows/flowsActions';
import { CreateNotebook, LoginNoteBook, resetScriptRedux, saveJupyterNotebook, saveNoteBookDone, setScriptCommonPaylod, setScriptOutputDataset, setScriptPythonCode } from '../../../../../store/modules/flows/scriptTransformer/scriptAction';
import { deleteCookies, getObjectLength } from '../../../common/helperFunctions';
import DatasetOutputNameInput from '../commonFunctions/useDatasetNameInput';
import EditorIndex from '../DataSource/editor/EditorIndex';
import { SCRIPTDEFAULTVIEW } from './ScriptDefaultInfography';
import SqlEditor from '../../../dataCatalogue/preview/components/SqlEditor';
import { getInputFrame, getInputTransformerDetails } from '../FlowsHelperFunction';
import { cloneDeep } from 'lodash';
import { setLoadingStatus } from '../../../../../store/modules/common/loadingActions';

const { TabPane } = Tabs;
const ScriptIndex = (props) => {
    const { flowsElements, currentTransformer, transformerNameList, isExecutePermission, isEditPermission, isJupyterPermission } = props
    const { flowId } = useParams()

    const [editMode, setEditMode] = useState(false)
    const [showBtnLoading, setShowBtnLoading] = useState(false)
    const [activeKey, setActiveKey] = useState('scriptEditor')
    const [disableExcute, setdisableExcute] = useState(false)
    const store = useStore();
    const [toggleDefaultView, setToggleDefaultView] = useState(true)
    const [expandTable, setexpandTable] = useState(false)
    const [showApplyButton, setshowApplyButton] = useState(false)
    const [transformerName, settransformerName] = useState('')
    const [showRedBtn, setshowRedBtn] = useState(false);

    const onChange = activeKey => {
        setActiveKey(activeKey)
    };

    const [pythonCode, setpythonCode] = useState('')
    const [commonPayload, setcommonPayload] = useState({})
    const [additional_settings, setAdditionalSettings] = useState({
        "cpu_request": "2",
        "mem_request": "4G",
        "configuration": "4GB |2 CORE",
        "extra_resource_limits": "",
        "user_libs": ""
    })
    // const jupyterCode = useSelector(state => state.Flows.Script.scriptPythonCode);
    // const jupyterCodeId = useSelector(state => state.Flows.Script.scriptCurrentId);
    const saveNoteBookDoneFlag = useSelector(state => state.Flows.Script.saveNoteBookDone);
    useEffect(() => {

        if (!transformerName) {
            setshowRedBtn(true)
        }
    }, [transformerName])

    useEffect(() => {
        deleteCookies()
        // getUpdatedCode();


        // if (jupyterCode !== '' && jupyterCodeId === currentTransformer.id) {
        //     setpythonCode(jupyterCode);
        // let finalFlowsElements = flowsElements.map(item => {
        //     if (item.id === currentTransformer.id) {
        //         // item.content['pythonCode'] = jupyterCode
        //     }
        //     return item
        // })
        // store.dispatch(updateFlowsElements([...finalFlowsElements]))
        // }



    }, [saveNoteBookDoneFlag])


    const setOutputDataset = (outputDataSetList) => {
        let Frame = []

        if (outputDataSetList.length && currentTransformer.id && transformerName !== '') {

            // if (validateValue) {

            let isNameAlreadyExist = checkTransformerNameExist(outputDataSetList)

            if (!isNameAlreadyExist && outputDataSetList.length && transformerName !== '') {
                let transformerNameList = store.getState().FlowsReducer.transformerName;
                let outputDataSetValue = outputDataSetList.map((ele) => ele.value)



                Frame = store.dispatch(getInputFrame())
                outputDataSetValue = outputDataSetList.map((ele) => ele.value)

                let payload = {
                    format: "HTML",
                    "flow_body": {
                        "Id": transformerName,
                        "NodeType": "nb_python3",
                        currentTransformerID: currentTransformer.id,


                        "Args": [
                            {
                                "Name": "input",
                                "Value": Frame
                            },
                            {
                                "Name": "output",
                                "Value": outputDataSetValue
                            },
                            {
                                "Name": "script_name",
                                "Value": ""
                            },
                            {
                                "Name": "kernel",
                                "Value": "python3"
                            },
                            {
                                "Name": "cpu_request",
                                "Value": additional_settings.cpu_request
                            },
                            {
                                "Name": "mem_request",
                                "Value": `${additional_settings.mem_request}`
                            },
                            {
                                "Name": "extra_resource_limits",
                                "Value": additional_settings.extra_resource_limits
                            },
                            {
                                "Name": "configuration",
                                "Value": additional_settings.configuration

                            },
                            {
                                "Name": "user_libs",
                                "Value": additional_settings.user_libs

                            },

                        ]
                    }
                }


                setcommonPayload({ ...payload })
                let temp = store.getState().FlowsReducer.executedTransformerPayload;
                if (temp?.length) {
                    let existedTransformerIndex = temp.findIndex((ele) => ele.currentTransformerID === currentTransformer.id);

                    if (existedTransformerIndex > -1) {
                        temp.splice(existedTransformerIndex, 1, payload['flow_body'])
                    }
                }

                store.dispatch(setExecutedTransformerPayload(temp))

                store.dispatch(setScriptCommonPaylod(payload))
                store.dispatch(CreateNotebook(flowId, payload)).then((resp) => {

                    if (resp && resp.data && resp.data.code) {
                        setshowApplyButton(true)
                        // store.dispatch(setScriptPythonCode({ code: resp.data.code, id: currentTransformer.id }));
                        store.dispatch(saveNoteBookDone(false
                        ))

                        let element = document.querySelector("#html-content");


                        let temp = resp.data.code;

                        // let scriptMatches = temp.match(/(?<=<script>\s*).*?(?=\s*<\/script>)/gs)

                        // scriptMatches.forEach(text => {
                        //     const script = document.createElement("script");

                        //     const scriptText = document.createTextNode(text);

                        //     script.appendChild(scriptText);
                        //     document.body.appendChild(script);
                        // })
                        outputDataSetValue.forEach((item, i) => {
                            transformerNameList[outputDataSetList[i]?.id] = outputDataSetList[i]?.value
                            store.dispatch(setTransformerNames(transformerNameList))
                        })
                        element.innerHTML = temp;

                        let finalFlowsElements = flowsElements.map(item => {
                            if (item.id === currentTransformer.id) {
                                item.paired = outputDataSetList.map((ele) => ele.id)
                                item.content['additional_settings'] = additional_settings
                                item.content['outputdatasetList'] = outputDataSetList;
                                item.content['pythonCode'] = '' //resp.data.code
                                item.transformerName = transformerName
                                item.content.executed_DAG = payload.flow_body;
                            }
                            return item
                        })

                        store.dispatch(updateFlowsElements([...finalFlowsElements]))
                        store.dispatch(setScriptOutputDataset(outputDataSetList))


                    }
                    // store.dispatch(CreateNotebook(flowId, payload)).then((resp) => {

                    //     if (resp && resp.data && resp.data.code) {
                    //         setshowApplyButton(true)

                    //         store.dispatch(setScriptPythonCode({ code: resp.data.code, id: currentTransformer.id }));
                    //         store.dispatch(saveNoteBookDone(false
                    //         ))

                    //         let finalFlowsElements = flowsElements.map(item => {
                    //             if (item.id === currentTransformer.id) {
                    //                 item.paired = outputDataSetList.map((ele) => ele.id)
                    //                 item.content['additional_settings'] = additional_settings
                    //                 item.content['outputdatasetList'] = outputDataSetList;
                    //                 item.content['pythonCode'] = resp.data.code
                    //                 item.transformerName = transformerName
                    //                 item.content.executed_DAG = payload.flow_body;
                    //             }
                    //             return item
                    //         })

                    //         store.dispatch(updateFlowsElements([...finalFlowsElements]))
                    //         store.dispatch(setScriptOutputDataset(outputDataSetList))


                    //     }
                })
                    .catch((e) => {


                        let finalFlowsElements = flowsElements.map(item => {
                            if (item.id === currentTransformer.id) {

                                item.content['additional_settings'] = additional_settings
                                item.transformerName = transformerName
                                item.content.executed_DAG = payload.flow_body;
                            }
                            return item
                        })

                        store.dispatch(updateFlowsElements([...finalFlowsElements]))

                    })



            }
            // }

        } else if (transformerName === '') {
            emitToastNotification('info', 'Please enter script transformer name to create noetbook ')
        }

    }

    const applyNewOuputDataSet = (outputDataSetList) => {


        if (outputDataSetList.length) {
            let isNameAlreadyExist = checkTransformerNameExist(outputDataSetList, true)
            if (!isNameAlreadyExist) {
                let transformerNameList = store.getState().FlowsReducer.transformerName

                outputDataSetList.forEach((ele) => {
                    transformerNameList[ele.id] = ele.value
                })

                store.dispatch(setScriptOutputDataset(outputDataSetList))

                let Frame = []
                Frame = store.dispatch(getInputFrame())

                let outputDataSetValue = outputDataSetList.map((ele) => ele.value)
                let payload = {
                    "flow_body": {
                        "Id": transformerName,
                        "NodeType": "nb_python3",
                        currentTransformerID: currentTransformer.id,

                        "Args": [
                            {
                                "Name": "input",
                                "Value": Frame
                            },
                            {
                                "Name": "output",
                                "Value": outputDataSetValue
                            },
                            {
                                "Name": "script_name",
                                "Value": ""
                            },
                            {
                                "Name": "kernel",
                                "Value": "python3"
                            },
                            {
                                "Name": "cpu_request",
                                "Value": additional_settings.cpu_request
                            },
                            {
                                "Name": "mem_request",
                                "Value": `${additional_settings.mem_request}`
                            },
                            {
                                "Name": "extra_resource_limits",
                                "Value": additional_settings.extra_resource_limits
                            },
                            {
                                "Name": "configuration",
                                "Value": additional_settings.configuration
                            },
                            {
                                "Name": "user_libs",
                                "Value": additional_settings.user_libs

                            },
                        ]
                    }
                }
                setcommonPayload({ ...payload })

                let finalFlowsElements = flowsElements.map(item => {
                    if (item.id === currentTransformer.id) {
                        item.paired = outputDataSetList.map((ele) => ele.id)
                        item.content['additional_settings'] = additional_settings
                        item.content['outputdatasetList'] = outputDataSetList;
                        item.content.executed_DAG = payload.flow_body
                    }
                    return item
                })
                store.dispatch(updateFlowsElements([...finalFlowsElements]))

                store.dispatch(setScriptCommonPaylod(payload))
                emitToastNotification('success', 'Changes applied successfully')
            }
        }

    }

    const openJupyterNotebook = () => {


        let outputDataSetList = store.getState().Flows.Script.scriptOutputDataset;
        let Frame = []
        if (outputDataSetList.length && currentTransformer.id && transformerName) {
            let outputDataSetValue = outputDataSetList.map((ele) => ele.value)

            Frame = store.dispatch(getInputFrame())
            let payload = {
                "flow_body": {
                    "Id": transformerName,
                    "NodeType": "nb_python3",
                    currentTransformerID: currentTransformer.id,

                    "Args": [
                        {
                            "Name": "input",
                            "Value": Frame
                        },
                        {
                            "Name": "output",
                            "Value": outputDataSetValue
                        },
                        {
                            "Name": "script_name",
                            "Value": ""
                        },
                        {
                            "Name": "kernel",
                            "Value": "python3"
                        },
                        {
                            "Name": "cpu_request",
                            "Value": additional_settings.cpu_request
                        },
                        {
                            "Name": "mem_request",
                            "Value": `${additional_settings.mem_request}`
                        },
                        {
                            "Name": "extra_resource_limits",
                            "Value": additional_settings.extra_resource_limits
                        },
                        {
                            "Name": "configuration",
                            "Value": additional_settings.configuration
                        },
                        {
                            "Name": "user_libs",
                            "Value": additional_settings.user_libs

                        },
                    ]
                }
            }
            setcommonPayload({ ...payload })
            let temp = store.getState().FlowsReducer.executedTransformerPayload;
            if (temp?.length) {
                let existedTransformerIndex = temp.findIndex((ele) => ele.currentTransformerID === currentTransformer.id);

                if (existedTransformerIndex > -1) {
                    temp.splice(existedTransformerIndex, 1, payload['flow_body'])
                }
            }

            store.dispatch(setExecutedTransformerPayload(temp))

            store.dispatch(setScriptCommonPaylod(payload))

            store.dispatch(LoginNoteBook(flowId, payload)).then((resp) => {
                if (resp && resp.data?.script_url) {
                    let finalFlowsElements = flowsElements.map(item => {
                        if (item.id === currentTransformer.id) {
                            item.paired = outputDataSetList.map((ele) => ele.id)
                            item.content['additional_settings'] = additional_settings
                            item.content['outputdatasetList'] = outputDataSetList;
                            item.content['pythonCode'] = ''//resp.data.code
                            item.content.executed_DAG = payload.flow_body;

                            item.transformerName = transformerName
                        }
                        return item
                    })

                    store.dispatch(updateFlowsElements([...finalFlowsElements]))
                    store.dispatch(showJupyterNoteBook(true))
                }
            }).catch((e) => {
                store.dispatch(setLoadingStatus(false));

            })
        }

    }


    const checkTransformerNameExist = (outputDatasetArray, isapplyNewNode = false) => {
        let key = 'value'

        let outputDataSetValue = outputDatasetArray.map((ele) => ele.value)
        let validateValue = false
        if (outputDataSetValue?.length) {
            validateValue = outputDataSetValue.every((el) => {
                if (typeof el === 'string') {
                    return el?.trim()?.length > 0
                }
                else if (typeof el === 'object') {
                    return el[0]?.trim().length > 0 && el[1]?.trim().length > 0
                }

            })
            if (!validateValue) { // if empty field found then will give an warning if empty field found then will not allow to call create notebook api
                emitToastNotification('error', 'Empty output dataset found, please provide name.');
                return true;
            }

        }

        let uniqueDatasetArray = [...new Map(outputDatasetArray.map(item =>
            [item[key], item])).values()];
        let isExist = false


        if (uniqueDatasetArray?.length !== outputDatasetArray.length) {
            let tempId = uniqueDatasetArray.map((item) => item?.id)

            let findDuplicateValue = outputDatasetArray.find((item) => !tempId.includes(item?.id))?.value
            if (findDuplicateValue) {
                isExist = true
                emitToastNotification('error', `"${findDuplicateValue}" A transformer with this name already exists. Please rename the transformer.`
                )
            }
            return isExist
        } else {
            isExist = false

            let transformerNameList = store.getState().FlowsReducer.transformerName;
            if (isapplyNewNode) {
                for (const key in transformerNameList) {
                    const element = transformerNameList[key];
                    let findIndex = uniqueDatasetArray.findIndex((item) => item?.value === element && item?.id === key)
                    if (findIndex > -1) {
                        uniqueDatasetArray.splice(findIndex, 1)
                    }
                }
            }
            let tempValues = uniqueDatasetArray.map((item) => item.value);

            if (tempValues && tempValues.length) {
                for (const key in transformerNameList) {
                    const element = transformerNameList[key];
                    if (tempValues.includes(element)) {
                        emitToastNotification('error', `"${element}" A transformer with this name already exists. Please rename the transformer.`)

                        isExist = true
                    }
                }
                return isExist

            }

        }

    }
    const getTabArray = async (outputName) => {



        let currentTabArray = [] //flowsElements.find(item => item.id === currentTransformer.id).tabArray
        let outputDataSetList = store.getState().Flows.Script.scriptOutputDataset;

        let outcomeColumns = {}
        let temp = []
        let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')

        for (let i = 0; i < outputName?.length; i++) {

            let index = currentTabArray.findIndex((item) => item.transformerId === outputDataSetList[i].id);
            if (index === -1 && outputName[i] && outputName[i] !== '') {

                currentTabArray.unshift({ transformerId: outputDataSetList[i].id, podName: `${outputName[i]}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: `${outputName[i]}` })
            }
            try {

                let transformerProgress = store.getState().FlowsReducer.transformerProgress

                let currentStatus = ''
                if (transformerProgress && transformerProgress.length) {
                    currentStatus = transformerProgress.find(element => {
                        return element.crnt_transformer === currentTransformer.id
                    })?.state;
                }
                let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')

                temp = await getPreviewResult({ podName: `${outputName[i]}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : '', })

                if (temp && temp.length) {
                    outcomeColumns[outputDataSetList[i].id] = temp;
                }

            } catch (error) {

            }
        }




        return ({ columnObj: outcomeColumns, currentTabArray: currentTabArray })



    }
    const executeTransformer = async () => {
        let outputName = ''

        let outputDataSetList = store.getState().Flows.Script.scriptOutputDataset;
        let Frame = []
        if (outputDataSetList.length && currentTransformer.id) {

            let outputDataSetValue = outputDataSetList.map((ele) => ele.value)


            // let currentDataSource = flowsElements.find(item => item.id === currentTransformer.id)
            // if (currentDataSource?.content[`pythonCode`] !== '') {
            //     setpythonCode(currentDataSource?.content[`pythonCode`])
            //     // store.dispatch(setScriptPythonCode({ code: currentDataSource?.content[`pythonCode`], id: currentTransformer.id }))
            // }
            Frame = store.dispatch(getInputFrame())
            setdisableExcute(true)
            setShowBtnLoading(true)
            let payload = {
                "flow_body": {
                    "Id": transformerName,
                    "NodeType": "nb_python3",
                    currentTransformerID: currentTransformer.id,

                    "Args": [
                        {
                            "Name": "input",
                            "Value": Frame
                        },
                        {
                            "Name": "output",
                            "Value": outputDataSetValue
                        },
                        {
                            "Name": "script_name",
                            "Value": ""
                        }, {
                            "Name": "kernel",
                            "Value": "python3"
                        },
                        {
                            "Name": "cpu_request",
                            "Value": additional_settings.cpu_request
                        },
                        {
                            "Name": "mem_request",
                            "Value": `${additional_settings.mem_request}`
                        },
                        {
                            "Name": "extra_resource_limits",
                            "Value": additional_settings.extra_resource_limits
                        },
                        {
                            "Name": "user_libs",
                            "Value": additional_settings.user_libs

                        },
                        {
                            "Name": "configuration",
                            "Value": additional_settings.configuration
                        }
                    ]
                }
            }

            store.dispatch(executeTransformerApi(flowId, payload, currentTransformer.id)).then(async (resp) => {

                setdisableExcute(false)
                setShowBtnLoading(false)
                setshowRedBtn(false)

                if (resp.status === 'success') {
                    let newStatusInterval = setInterval(async () => {

                        let transformerProgress = store.getState().FlowsReducer.transformerProgress

                        let currentStatus = ''
                        if (transformerProgress && transformerProgress.length) {
                            currentStatus = transformerProgress.find(element => {
                                return element.crnt_transformer === currentTransformer.id
                            })?.state;
                        }

                        // let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
                        if (currentStatus === 'success') {
                            outputName = payload?.flow_body?.Args[1]?.Value
                            // store.dispatch(setActivePodDetails({ podName: `${outputName[0]}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', }))

                            if (outputName && outputName.length) {

                                let results = await getTabArray(outputName)
                                let flowsElements = store.getState().FlowsReducer.flowsElements
                                let finalFlowsElements = flowsElements.map(item => {
                                    if (item.id === currentTransformer.id) {

                                        item.transformerName = transformerName
                                        item.content.executed_DAG = payload.flow_body;
                                        item.content['outputdatasetList'] = outputDataSetList;
                                        item.content['additional_settings'] = additional_settings
                                        item.content['pythonCode'] = ''//store.getState().Flows.Script.scriptPythonCode;
                                        item.scriptCommonPayload = store.getState().Flows.Script.scriptCommonPayload;

                                        item.scriptOutputDataset = store.getState().Flows.Script.scriptOutputDataset;
                                        item.tabArray = [...results?.currentTabArray]
                                    }
                                    return item
                                })

                                if (Object.keys(results?.columnObj)?.length) {
                                    updateElementStatus({ ...results?.columnObj })


                                }
                                store.dispatch(updateFlowsElements([...finalFlowsElements]))
                            }
                            clearInterval(newStatusInterval)

                        }
                    }, 1000);

                }
            }).catch((err) => {
                let finalFlowsElements = flowsElements.map(item => {
                    if (item.id === currentTransformer.id) {

                        item.content['additional_settings'] = additional_settings
                        item.transformerName = transformerName
                        item.content.executed_DAG = payload.flow_body;
                    }
                    return item
                })

                store.dispatch(updateFlowsElements([...finalFlowsElements]))
                setdisableExcute(false)
                setShowBtnLoading(false)


            })
        }
    }

    const getUUID = () => {
        let id = uuidv4();

        return id;

    }
    const getPreviewResult = (podDetails) => {
        if (podDetails) {


            let request = {
                uuid: getUUID(),
                connection_name: podDetails.connectionName,
                table_name: podDetails.podName,
                page: 0,
                schema_name: podDetails.dataDomain,
                do_count: false,
                sort_body: [],
                filter_body: [],
                table_ids: []

            }
            return store.dispatch(fetchPreviewTable(request, false)).then((response) => {
                if (response?.status === 'success' && response?.data) {
                    let responseData = response.data.result;

                    if (responseData && responseData !== null) {

                        let outcomeColumns = []
                        if (responseData?.data_header?.length) {
                            responseData.data_header.forEach((item, index) => {
                                outcomeColumns.push({
                                    id: `${item}_${index}`,
                                    Name: item,
                                    Type: responseData?.data_dtype[index],
                                    checked: false,
                                    type: podDetails.podName,

                                })
                            })
                            if (outcomeColumns?.length) {
                                return outcomeColumns
                            } else {
                                return []
                            }
                        }

                    }
                }
            })

        }
    }


    const updateElementStatus = (outcomeColumns) => {
        let flowsElements = store.getState().FlowsReducer.flowsElements
        let pairedTransformers = flowsElements.find((ele) => ele.id === currentTransformer.id)?.paired
        let newflowsElements = [...flowsElements]
        let outputDataSetList = store.getState().Flows.Script.scriptOutputDataset;
        if (pairedTransformers && pairedTransformers.length) {
            newflowsElements.forEach((element) => {
                if (pairedTransformers.includes(element.id)) {

                    if (outcomeColumns) {

                        // element['input_source'][currentTransformer.id] = outcomeColumns[element.id]
                        element.content.outcomeColumns = outcomeColumns[element.id]

                    }

                    if (outputDataSetList && outputDataSetList.length) {
                        let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
                        let outputValue = outputDataSetList.find((ele) => ele.id === element.id);
                        if (outputValue && outputValue.value) {
                            element.transformerName = outputValue.value
                            element.tabArray = [{ transformerId: outputValue.id, podName: `${outputValue.value}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: outputValue.value }]
                        }
                    }

                }
            })
        }

        store.dispatch(updateFlowsElements(newflowsElements))
    }

    useEffect(() => {

        store.dispatch(resetScriptRedux())
        setpythonCode('')

        let currentScriptContent = flowsElements.find(item => item.id === currentTransformer.id)?.content



        if (currentScriptContent && Object.keys(currentScriptContent)?.length !== 0) {

            if (currentScriptContent?.additional_settings && getObjectLength(currentScriptContent?.additional_settings)) {
                let additional_settings = cloneDeep(currentScriptContent?.additional_settings)
                setAdditionalSettings({
                    cpu_request: additional_settings?.cpu_request ?? '2',
                    mem_request: additional_settings?.mem_request ?? '4G',
                    configuration: additional_settings?.configuration ?? "4GB |2 CORE",
                    extra_resource_limits: additional_settings?.extra_resource_limits ?? '',
                    user_libs: additional_settings?.user_libs ?? '',


                })
            }
            setshowApplyButton(true)
            if (currentScriptContent[`outputdatasetList`] && currentScriptContent[`outputdatasetList`].length) {
                store.dispatch(setScriptOutputDataset(currentScriptContent[`outputdatasetList`]))

            }
            getUpdatedCode();
            // if (currentScriptContent[`pythonCode`] !== "") {

            //     setpythonCode(currentScriptContent[`pythonCode`])
            //     store.dispatch(setScriptPythonCode({ code: currentScriptContent[`pythonCode`], id: currentTransformer.id }))
            // }


        } else {
            setshowApplyButton(false)

            store.dispatch(resetScriptRedux())
        }

        if (Object.keys(transformerNameList).length && currentTransformer?.id && transformerNameList[currentTransformer?.id]) {
            setshowRedBtn(false)

            settransformerName(transformerNameList[currentTransformer.id])
        }

        let InputTransformer = store.dispatch(getInputTransformerDetails(currentTransformer, flowsElements))

        let inputColumns = cloneDeep(InputTransformer[0]?.content?.outcomeColumns);
        if (InputTransformer && InputTransformer?.length && inputColumns?.length) {

            setToggleDefaultView(false)
            setdisableExcute(false);

        } else {
            setToggleDefaultView(true)

            setdisableExcute(true);
        }




    }, [currentTransformer])





    const closeLeftSideContent = () => {
        store.dispatch(setFlowsCurrenttransformer({}))
        store.dispatch(showFlowsTransformdetails(false))
    }

    const getUpdatedCode = () => {
        let commonPayload = {};
        if (currentTransformer?.content?.executed_DAG && Object.keys(currentTransformer?.content?.executed_DAG).length > 0) {
            commonPayload['format'] = "HTML";
            commonPayload['flow_body'] = currentTransformer?.content?.executed_DAG
            store.dispatch(saveJupyterNotebook(flowId, commonPayload)).then((resp) => {
                if (resp && resp.status === 'success') {
                    store.dispatch(setLoadingStatus(false))
                    setpythonCode(resp.data.code)
                    // store.dispatch(setScriptPythonCode({ code: resp.data.code, id: currentTransformer.id }))

                }
            })
        } else {
            setpythonCode('')
        }

    }

    useEffect(() => {
        let element = document.getElementById("html-content");
        if (element) {

            element.innerHTML = pythonCode


        }
    }, [pythonCode])

    return (
        <>

            <div className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
                <div className="d-flex align-items-center">

                    <PYTHONSCRIPTICON />
                    <h3 className="subtitle">Script</h3>


                    <Tabs onChange={onChange} activeKey={activeKey} className="custom-tab tab-with-icon">
                        <TabPane tab={<SCRIPTEDITOR />} key="scriptEditor">
                        </TabPane>
                        <TabPane tab={<img src={sticky} alt={`sticky`} />} key="sticky">
                        </TabPane>

                    </Tabs>
                </div>
                <div className="d-flex align-items-center">
                    <DatasetOutputNameInput transformerName={transformerName} setTransformerName={settransformerName} />

                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { activeKey !== 'sticky' || (activeKey === 'sticky' && !editMode) ? closeLeftSideContent() : setEditMode(false); onChange('properties') }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />

                    {activeKey === 'sticky' ? <Buttons props={{ buttonText: !editMode ? 'Edit' : 'Save', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: !editMode ? () => { setEditMode(true); store.dispatch(saveTransformerNoteClick(false)) } : () => { store.dispatch(saveTransformerNoteClick(true)) }, ImgSrc: !editMode ? () => <EDIT /> : () => <SAVE />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}


                    {/* <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} /> */}
                    {activeKey !== 'sticky' ? <Buttons props={{ buttonText: 'Execute', buttonClassName: `${showRedBtn ? 'execute-btn-gradient' : ''} custom-btn-primary custom-btn btn-with-text`, showLoading: showBtnLoading, buttonEvent: activeKey === 'sticky' ? () => { setEditMode(true) } : () => { executeTransformer() }, ImgSrc: activeKey === 'editor' ? () => <EDIT /> : () => <PLAY />, isDisable: !isExecutePermission || transformerName === '' || disableExcute ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}

                </div>
            </div>
            {
                activeKey === 'scriptEditor' && !toggleDefaultView ? <div className="d-flex bg-white  section-with-drag flow-filter-height h-100">
                    <div className={expandTable ? "full-screen-active scriptEditor" : "w-70 custom-border-right scriptEditor"}>
                        <div className="d-flex justify-content-between custom-border-bottom padding-2">
                            <div className="d-flex align-items-center">
                                {/* <PYTHONSCRIPTICON height='16' width='16'/> */}
                                <CODEEDITOR height='16' width='16' color='#2c83c4' />
                                <p className="subtitle mb-0 ml-2">CodeEditor</p>
                            </div>
                            <div className="d-flex justify-content-end ">
                                {expandTable ?
                                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { setexpandTable(false) }, ImgSrc: () => <EXITFULLSCREEN />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                    :
                                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { setexpandTable(true) }, ImgSrc: () => <FULLSCREEN />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                }
                                {showApplyButton ? <Buttons props={{ buttonText: 'Edit', buttonClassName: "custom-btn-primary custom-btn btn-with-text ml-2", buttonEvent: () => { openJupyterNotebook() }, toggleBtnValue: '', ImgSrc: () => <EDIT />, isDisable: !isJupyterPermission || !isEditPermission || window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT, toggleBtnOption: null }} /> : null}
                            </div>
                        </div>
                        <div style={{ height: !expandTable ? 'calc(100vh - 132px)' : 'calc(100vh - 52px)' }}>

                            <div id="html-content"></div>

                        </div>
                        {/* <SqlEditor
                            height={!expandTable ? 'calc(100vh - 132px)' : 'calc(100vh - 52px)'}
                            readOnly={
                                true}
                            language="python"
                            fromRoute="sql_transformer"
                            setQueryvalue={() => { }}
                            ExecuteQueryClick={() => { }}
                            QueryValue={pythonCode}
                        /> */}
                        {/* <div className=''> */}
                        {/* <ScriptEditor readOnly={true}   height={"calc(100% - 94px)"} editorValue={pythonCode} seteditorValue={() => { }} /> */}
                        {/* </div> */}
                    </div>
                    <div className="w-30 h-100">

                        <OutputDataSet isEditPermission={isEditPermission} additional_settings={additional_settings} setAdditionalSettings={setAdditionalSettings} showApplyButton={showApplyButton} setOutputDataset={setOutputDataset} applyNewOuputDataSet={applyNewOuputDataSet} setdisableExcute={setdisableExcute} />
                    </div>


                </div> : activeKey === 'scriptEditor' && toggleDefaultView ? <div className="no-datasource">
                    <SCRIPTDEFAULTVIEW />
                </div> : null

            }

            {
                activeKey === 'sticky' && <EditorIndex editMode={editMode} setEditMode={setEditMode} />
            }


        </>
    )
}

const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer,
        flowId: state.FlowsReducer.id,


        transformerNameList: state.FlowsReducer.transformerName

    };
};
export default connect(mapStateToProps)(ScriptIndex);
