import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { DatePicker, Input, Select, Switch, Tooltip } from 'antd'
import { MINUS } from '../../../Common/iconSource'
import { LandingSimpleTable } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import { Literals } from '../../common/literals'
import { ICON_CATALOG, ICON_DELETE, ICON_INFO, ICON_INFO_FILLED, ICON_PLUS } from '../../../Common/newIconSource'
import SingleSelection from '../../../Common/dropDown/SingleSelection'
import Buttons from '../../../Common/button/Buttons'
import { filterOptions, mergeArrayByProperty, pxToRem, remToPx, stringFilterOptions } from '../../common/helperFunctions'
import { cloneDeep, isArray } from 'lodash'
import IconToggleButton from '../../../Common/iconToggleButton/IconToggleButton'
import dayjs from 'dayjs'

// const criteriaOptions = mergeArrayByProperty(filterOptions,stringFilterOptions,'Name')

export const HealthView = ({
    fieldValues,
    setFieldValues = () => { },
    isEdit, 
    rerender, 
    setrerender,
    selectedOwners = [],
    selectedStewards = [],
    critUUID = ''
}) => {

    const [data, setData] = useState([])
    const [betweenValue, setBetweenValue] = useState({})
    const [condition, setCondition] = useState('AND')
    const [ddlOpen, setDdlOpen] = useState(false)
    const [activeCriteriaKey, setActiveCriteriaKey] = useState('none')
    const [ddlInputValue, setDdlInputValue] = React.useState('')

    useEffect(() => {
        setCondition(fieldValues?.quality_check?.[0]?.operation ? 'AND' : 'OR')
    }, [fieldValues])

    const criteriaOptions = React.useMemo(() => {
        let arr = [], dataType = ''

        let __text = [
            {
                label: 'Text contains',
                value: 'Text contains',
                key: 'LIKE'
            },
            {
                label: 'Text does not contain',
                value: 'Text does not contain',
                key: 'NOT LIKE'
            },
            {
                label: 'Regex Like',
                value: 'Regex Like',
                key: 'regexp_like'
            },
            // {
            //     label: 'Text starts with',
            //     value: 'Text starts with',
            //     key: 's%'
            // },
            // {
            //     label: 'Text ends with',
            //     value: 'Text ends with',
            //     key: '%s'
            // },
            // {
            //     label: 'Text is exactly',
            //     value: 'Text is exactly',
            //     key: '=='
            // },
        ]

        let __date = [
            {
                label: dataType?.split('(')?.[0] === "time" ? 'Time is' : 'Date is',
                value: dataType?.split('(')?.[0] === "time" ? 'Time is' : 'Date is',
                key: 'date is'
            },
            {
                label: dataType?.split('(')?.[0] === "time" ? 'Time is before' : 'Date is before',
                value: dataType?.split('(')?.[0] === "time" ? 'Time is before' : 'Date is before',
                key: 'date before'
            },
            {
                label: dataType?.split('(')?.[0] === "time" ? 'Time is after' : 'Date is after',
                value: dataType?.split('(')?.[0] === "time" ? 'Time is after' : 'Date is after',
                key: 'date after'
            },
            // {
            //     label: dataType?.split('(')?.[0] === "time" ? 'Time is between' : 'Date is between',
            //     value: dataType?.split('(')?.[0] === "time" ? 'Time is between' : 'Date is between',
            //     key: 'date between'
            // },
        ]
        
        let __numeric = [
            {
                label: 'Greater than',
                value: '>',
                key: '>'
            },
            {
                label: 'Greater than or equal to',
                value: '>=',
                key: '>='
            },
            {
                label: 'Less than',
                value: '<',
                key: '<'
            },
            {
                label: 'Less than or equal to',
                value: '<=',
                key: '<='
            },
            {
                label: 'Is equal to',
                value: '=',
                key: '='
            },
            {
                label: 'Is not equal to',
                value: '<>',
                key: '<>'
            },
            {
                label: 'Is between',
                value: 'between',
                key: 'BETWEEN'
            },
            {
                label: 'Is not between',
                value: 'not between',
                key: 'NOT BETWEEN'
            }
        ]

        let __common = [
            // {
            //     label: 'None',
            //     value: 'none',
            //     key: 'none'
            // },
            {
                type: 'divider'
            },
            {
                label: 'Is empty',
                value: 'Is empty',
                key: 'IS'
            },
            {
                label: 'Is not empty',
                value: 'Is not empty',
                key: 'IS NOT'
            }
        ]

        if(['char', 'varchar', 'string'].includes(dataType))
            arr = [
                ...__common,
                {
                    type: 'divider'
                },
                ...__text
            ]
        // else if(['date', 'time', 'datetime', 'timestamp'].includes(dataType?.split('(')?.[0]))
        //     arr = [
        //         ...__common,
        //         {
        //             type: 'divider'
        //         },
        //         ...__date,
        //         {
        //             type: 'divider'
        //         },
        //         ...__numeric
        //     ]
        else if(['int', 'bigint', 'smallint', 'integer', 'decimal', 'float', 'real', 'double'].includes(dataType?.split('(')?.[0]))
            arr = [
                ...__common,
                {
                    type: 'divider'
                },
                ...__numeric
            ]
        else if(dataType === 'boolean')
            arr = [
                ...__common,
                {
                    type: 'divider'
                },
                {
                    label: 'Is equal to',
                    value: '=',
                    key: '='
                },
            ]
        else
            arr = [
                ...__common,
                {
                    type: 'divider'
                },
                ...__text,
                {
                    type: 'divider'
                },
                ...__date,
                {
                    type: 'divider'
                },
                ...__numeric,
            ]

        return arr
    }, [])

    React.useEffect(() => {
        let val = criteriaOptions?.find(c => c?.key === activeCriteriaKey)?.label ?? ''
        setDdlInputValue(val === 'None' ? '' : val)
    }, [activeCriteriaKey, criteriaOptions])

    useEffect(() => {
        const criteriaFields = fieldValues?.quality_check;
      

        if (criteriaFields) {
       
            let temp = criteriaFields.map((item, index) => {
                return {
                    id: item?.id,
                    name: item?.name || !isEdit
                        ?
                        <Input
                            type="text"

                            defaultValue={item?.name}
                            key={item.id}
                            onKeyDown={(e) => e.stopPropagation()}
                            onClick={(e) => e?.stopPropagation()}
                            onChange={(e) => onInputChange({ key: 'name', value: e?.target?.value, id: item?.id })}
                            className='custom-input-field dict-color-dark w-100'
                            disabled={isEdit}
                        />
                        :
                        <p className='mb-0 fontSizeLabel text-black-50'>No Name Provided</p>
                    ,
                    description: item?.description || !isEdit
                        ?
                        <Input
                            type="text"
                            key={item.id}
                            onKeyDown={(e) => e.stopPropagation()}
                            onClick={(e) => e?.stopPropagation()}
                            defaultValue={item?.description}
                            onChange={(e) => onInputChange({ key: 'description', value: e?.target?.value, id: item?.id })}
                            className='custom-input-field dict-color-dark w-100'
                            disabled={isEdit}
                        />
                        :
                        <p className='mb-0 fontSizeLabel text-black-50'>No Description Provided</p>,
                    health: <p className='dict-color-dark text-with-ellipsis text-uppercase'>
                        {item?.health}
                    </p>,
                    criteria: <>
                        <div className='d-flex align-items-center h-100 w-100'>
                        <Select
                            key={`healthCriteri-${index}-of-${fieldValues?.quality_check?.length ?? 0}-${critUUID}`}
                            defaultValue={
                                criteriaOptions?.find(c => c?.key?.toLowerCase() === item?.criteria?.toLowerCase())?.value ?? 'None'
                                // item?.criteria === "" ? 'none' : item?.criteria
                            }
                            style={{
                                width: '100%',
                            }}
                            onChange={(val) => {
                                let value = criteriaOptions?.find(c => c?.value?.toLowerCase() === val?.toLowerCase())?.key
                                
                                onInputChange({ key: 'criteria', value, id: item?.id })
                            }}
                            options={criteriaOptions?.filter(f => f?.type !== 'divider')}
                            disabled={isEdit}
                            className='health-criteria-sel'
                            onClick={(e) => e?.stopPropagation()}
                        />



                            {/* <SingleSelection
                                props={{
                                    placeholder: "",
                                    dropDownValue: item?.criteria,
                                    onClickEvent: (value) => {
                                        onInputChange({ key: 'criteria', value, id: item?.id })
                                    },
                                    optionList: criteriaOptions,
                                    isDisable: isEdit,
                                    selectClassName: 'w-100'
                                }}
                            /> */}
                        </div>
                    </>,
                    // operation: <>
                    //     <Switch
                    //         className='custom-switch-btn'
                    //         checkedChildren="AND" unCheckedChildren="OR" 
                    //         checked={item?.operation}
                    //         onChange={(check) => onInputChange({key: 'operation', value: check, id: item?.id})}
                    //         disabled={isEdit}
                    //     />
                    // </>,
                    value: <>
                        <div className='align-items-center h-100' style={{display: 'grid', gridTemplateColumns: 'auto 25px', gap: 10}}>

                            {

                                item?.criteria?.includes('date')
                                ?
                                    // item?.criteria === 'date between'
                                    // ?
                                    // <>
                                    //     <div className='d-flex justify-content-between h-100 align-items-center w-100' style={{ gap: 10 }}>
                                    //         <DatePicker
                                    //             key={item.id + 'b1'}
                                    //             picker="date"
                                    //             showTime
                                    //             placeholder="Date Time"
                                    //             className={'custom-input-field dict-color-dark'}
                                    //             value={item?.value ? dayjs(
                                    //                 isArray(item?.value) ? item?.value?.[0] : item?.value, 
                                    //                 "YYYY-MM-DD HH:mm:ss"
                                    //                 ) : null}
                                    //             format="YYYY-MM-DD HH:mm:ss"
                                    //             onChange={(e, val) => {
                                    //                 setBetweenValue(prev => {
                                    //                     console.log({ss:prev[item?.id]?.[1], i:item?.value?.[1]})
                                    //                     prev[item?.id] = [val, prev[item?.id]?.[1] ?? item?.value?.[1] ?? '']
                                    //                     console.log({va:prev[item?.id]})

                                    //                     onInputChange({ key: 'value', value: prev[item?.id], id: item?.id })

                                    //                     return prev
                                    //                 })
                                    //             }}
                                    //             onKeyDown={(e) => e.stopPropagation()}
                                    //             onClick={(e) => e?.stopPropagation()}
                                    //             disabled={isEdit}
                                    //         />
                                    //         <DatePicker
                                    //             key={item.id + 'b2'}
                                    //             picker="date"
                                    //             showTime
                                    //             placeholder="Date Time"
                                    //             className={'custom-input-field dict-color-dark'}
                                    //             value={item?.value ? dayjs(
                                    //                 isArray(item?.value) ? item?.value?.[1] : item?.value, 
                                    //                 "YYYY-MM-DD HH:mm:ss"
                                    //                 ) : null}
                                    //             format="YYYY-MM-DD HH:mm:ss"
                                    //             onChange={(e, val) => {
                                    //                 setBetweenValue(prev => {
                                    //                     prev[item?.id] = [prev[item?.id]?.[0] ?? item?.value?.[0] ?? '', val]

                                    //                     onInputChange({ key: 'value', value: prev[item?.id], id: item?.id })

                                    //                     return prev
                                    //                 })
                                    //             }}
                                    //             onKeyDown={(e) => e.stopPropagation()}
                                    //             onClick={(e) => e?.stopPropagation()}
                                    //             disabled={isEdit}
                                    //         />
                                    //     </div>
                                    // </>
                                    // :
                                    <>
                                        <DatePicker
                                            key={`${item.id}_ht_inp_${index}_${item?.criteria}`}
                                            picker="date"
                                            showTime
                                            placeholder="Date Time"
                                            className={'custom-input-field dict-color-dark'}
                                            value={item?.value ? dayjs(item?.value, "YYYY-MM-DD HH:mm:ss") : null}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            onChange={(e, val) => {
                                                onInputChange({ key: 'value', value: val, id: item?.id })
                                            }}
                                            onKeyDown={(e) => e.stopPropagation()}
                                            onClick={(e) => e?.stopPropagation()}
                                            disabled={isEdit}
                                        />
                                    </>
                                :
                                item?.criteria?.includes('BETWEEN')
                                    ?
                                    <div className='d-flex justify-content-between h-100 align-items-center w-100' style={{ gap: 10 }}>
                                        <Input
                                            type="text"
                                            key={item.id}
                                            onKeyDown={(e) => e.stopPropagation()}
                                            onClick={(e) => e?.stopPropagation()}
                                            defaultValue={isArray(item?.value) ? item?.value?.[0] : item?.value}
                                            onChange={(e) => {
                                                setBetweenValue(prev => {

                                                    prev[item?.id] = [e?.target?.value, prev[item?.id]?.[1] ?? item?.value?.[1] ?? '']

                                                    onInputChange({ key: 'value', value: prev[item?.id], id: item?.id })

                                                    return prev
                                                })
                                            }}
                                            className='custom-input-field dict-color-dark'
                                            disabled={isEdit}
                                        />
                                        <Input
                                            type="text"
                                            key={item.id}
                                            defaultValue={isArray(item?.value) ? item?.value?.[1] : item?.value}
                                            onChange={(e) => {
                                                setBetweenValue(prev => {
                                                    prev[item?.id] = [prev[item?.id]?.[0] ?? item?.value?.[0] ?? '', e?.target?.value]

                                                    onInputChange({ key: 'value', value: prev[item?.id], id: item?.id })

                                                    return prev
                                                })
                                            }}
                                            className='custom-input-field dict-color-dark'
                                            disabled={isEdit}
                                            onKeyDown={(e) => e.stopPropagation()}
                                            onClick={(e) => e?.stopPropagation()}
                                        />
                                    </div>
                                    :
                                    item?.criteria?.includes('IS')
                                        ?
                                        <p className='mb-0 fontSizeLabel dict-color-dark'>BLANK/NULL</p>
                                        :
                                        <Input
                                            type="text"
                                            key={`${item.id}_ht_inp_${index}_${item?.criteria}`}
                                            defaultValue={item?.value}
                                            onChange={(e) => onInputChange({ key: 'value', value: e?.target?.value, id: item?.id })}
                                            className='custom-input-field dict-color-dark w-100'
                                            disabled={isEdit}
                                            onKeyDown={(e) => e.stopPropagation()}
                                            onClick={(e) => e?.stopPropagation()}
                                        />
                            }
                            {
                                isEdit ? ''
                                :
                                <div className='d-flex justify-content-end align-items-center h-100'>
                                    <Buttons
                                        props={{
                                            tooltipPlacement: 'right',
                                            buttonText: "",
                                            tooltip: "Remove",
                                            buttonClassName: `custom-btn-outline border-0 bg-transparent custom-btn btn-with-icon error-red red-border`,
                                            buttonEvent: () => {
                                                onRemove(item?.id)
                                            },
                                            ImgSrc: () => <ICON_DELETE />,
                                            isDisable: isEdit,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                </div>
                            }   
                        </div>
                    </>,
                    // delete: <>
                    //     {
                    //         isEdit ? ''
                    //         :
                    //         <div className='d-flex justify-content-end align-items-center h-100'>
                    //             <Buttons
                    //                 props={{
                    //                     tooltipPlacement: 'right',
                    //                     buttonText: "",
                    //                     tooltip: "Remove",
                    //                     buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red red-border`,
                    //                     buttonEvent: () => {
                    //                         onRemove(item?.id)
                    //                     },
                    //                     ImgSrc: () => <MINUS />,
                    //                     isDisable: isEdit,
                    //                     buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    //                 }}
                    //             />
                    //         </div>
                    //     }
                    // </>
                }
            })

            setData([...temp])

        }

    }, [fieldValues, betweenValue, isEdit, rerender, critUUID])
    
    const onInputChange = ({ key, value, id }) => {
        setFieldValues(prev => {
            if (key === 'criteria') {
                prev['quality_check'] = prev?.quality_check?.map(f => (
                    {
                        ...f,
                        [key]: f?.id === id ? value : f[key],
                        value: f?.id === id
                            ? value?.includes('IS')
                                ? 'BLANK/NULL' : ''
                            : f?.value
                    }
                ))
            }
            else
                prev['quality_check'] = prev?.quality_check?.map(f => ({ ...f, [key]: f?.id === id ? value : f[key] }))


            return { ...prev }
        })
        setTimeout(() => {
            setrerender(true)
        }, 500);
    }

    const addCriteria = () => {
        setFieldValues(prev => {
            prev['quality_check'] = [
                ...prev?.quality_check,
                {
                    criteria: null,
                    operation: condition ?? 'AND',
                    value: '',
                    id: Math.random(),
                    name: '',
                    description: ''
                }
            ]

            return { ...prev }
        })
    }

    const onRemove = (id) => {
        let cloneDeepValues = cloneDeep(fieldValues);
        let filteredValue = cloneDeepValues['quality_check'].filter(f => f?.id !== id)
        cloneDeepValues['quality_check'] = filteredValue;
        setFieldValues(cloneDeepValues)
        setTimeout(() => {
            setrerender(true)
        }, 500);
    }

    const handleNotify = (check) => {
        setFieldValues(prev => ({ ...prev, notify: check }))
    }

    React.useEffect(() => {
        if(selectedOwners?.length === 0 && selectedStewards?.length === 0)
        handleNotify(false)
    }, [selectedOwners, selectedStewards])

    // console.log({fieldValues})
    return (
        <div className='px-2'>
            <Card className='card custom-card p-0 shadow-none border-0'>
                <div className='dict-title-strip'>
                    <div className='align-items-center w-100' style={{display: 'grid', gridTemplateColumns: '25% 25% auto 100px'}}>
                        <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                            Health Checks
                        </label>
                        {/* <div className='d-flex'> */}
                            <div className='d-flex' style={{marginLeft: 3}}>
                                <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold mr-2'>
                                    Operation :
                                </label>
                                <IconToggleButton
                                    onChange={(e) => {
                                        const val = e.target.value
                                        // setCondition(val)
                                        setFieldValues(prev => {
                                            return {
                                                ...prev,
                                                quality_check: prev?.quality_check?.map(h => ({ ...h, operation: val }))
                                            }
                                        })
                                    }}
                                    value={fieldValues?.quality_check?.[0]?.operation}
                                  
                                    disabled={isEdit}
                                    className={'mr-1'}
                                />
                            </div>
                            <div className='d-flex ml-2'>
                                <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                                    Notify
                                    <Tooltip title={`Turning Notify "ON" will send email notifications to Data Steward(s) and Data Owner(s)`}
                                        placement='topLeft'
                                    >
                                        <span className='pl-1 pr-2 position-relative noti-info-ic' style={{top: -1}}>
                                            <ICON_INFO_FILLED color='currentColor' height='14' width='14' />
                                        </span>
                                    </Tooltip>:
                                </label>
                                <Switch
                                    className={`custom-switch-btn ${isEdit ? 'ml-2' : 'mx-2'}`}
                                    checkedChildren="Yes" unCheckedChildren="No"
                                    checked={fieldValues?.notify}
                                    onChange={handleNotify}
                                    disabled={isEdit || (
                                        selectedOwners?.length === 0 
                                        && selectedStewards?.length === 0)}
                                />
                            </div>
                            {/* {
                                !isEdit
                                    ?
                                    <div className='vertical-separator'></div>
                                    : ''
                            } */}
                            <div>
                            {
                                !isEdit
                                    ? <span className='underline-button only-text fontInterSemiBold ml-1'
                                        onClick={() => addCriteria()}>
                                        <ICON_PLUS />
                                        <span>Add Criteria</span>
                                    </span>
                                    : ''
                            }
                            </div>
                        {/* </div> */}
                    </div>
                </div>
                <CardBody className='p-0 podsdomain-acc-list-data-dict dict-detail-table health-tbl-wrap mx-n2'>
                    {
                        data.length
                            ? <LandingSimpleTable
                                cols={columns}
                                rowsData={data}
                                tableHeight={(data?.length * 41) + 41}
                                showCursor={false}
                            />
                            : <div className='p-3 text-center text-black-50 no-dat-found'>No Health Found</div>
                    }

                </CardBody>
            </Card>
        </div>
    )
}

const columns = [
    {
        name: <label className='mt-0 grey-color label'>Name</label>,
        key: "name",
        sortable: false,
        resizable: false,
        width: remToPx(pxToRem(200))
    },
    {
        name: <label className='mt-0 grey-color label'>Description</label>,
        key: "description",
        sortable: false,
        resizable: false,
        width: remToPx(pxToRem(200))
    },
    {
        name: <label className='mt-0 grey-color label'>Condition</label>,
        key: "criteria",
        sortable: false,
        resizable: false,
        width: remToPx(pxToRem(200))
    },
    {
        name: <label className='mt-0 grey-color label'>Values</label>,
        key: "value",
        sortable: false,
        resizable: false,
        width: remToPx(pxToRem(200))
    }
]