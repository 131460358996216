import _, { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'
import { v4 as uuidv4 } from 'uuid'
import useDebounce from '../../../../../customHooks/useDebounce'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import { fetchDatabaseSchemaList } from '../../../../../store/actions'
import { fetchPreviewTable } from '../../../../../store/modules/common/VirtualDataTable/action'
import { updateFlowsElements } from '../../../../../store/modules/flows/flowsActions'
import { setTargetTableDetails } from '../../../../../store/modules/flows/targetTransformer/targetTransformer'
import Buttons from '../../../../Common/button/Buttons'
import DataGridTable from '../../../../Common/dataGridTable/DataGridTable'
import SingleSelection from '../../../../Common/dropDown/SingleSelection'
import { NOTEINFO, TABLEICON } from '../../../../Common/iconSource'

import { getObjectLength, podNameRegex } from '../../../common/helperFunctions'
import { Literals } from '../../../common/literals'
import SourceTypeImage from '../../../common/sourceTypeImage'
import { NoTableSelected } from './NoTableSelected'
import S3schemaTree from './S3schemaTree'
import SchemaTree from './SchemaTree'
import TargetColumnSchema from './TargetColumnSchema'
import { getInputTransformerDetails } from '../FlowsHelperFunction';
import { useParams } from 'react-router-dom';
function TargetConnectionModalStep2({ isDoneBtnClick, setdoneBtnClick, onClose, connectionType }) {
    let connectionDetails = useSelector(state => state.Flows.TargetTransformer.connectionDetails)
    const currentTransformer = useSelector(state => state.FlowsReducer.currentTransformer)
    const flowsElements = useSelector(state => state.FlowsReducer.flowsElements)
    const targetTableDetails = useSelector(state => state.Flows.TargetTransformer.targetTableDetails)
    const store = useStore()
    const { flowId } = useParams()
    const [connectionName, setconnectionName] = useState('')
    const [uniqUUID, setUniqUUID] = useState('')

    const [selectedTableName,] = useState('')
    const [targetTableData, settargetTableData] = useState({})
    const [selectedSchema, setselectedSchema] = useState('')
    const [inputTableData, setinputTableData] = useState({})
    const [toggleValue, settoggleValue] = useState('Existing')
    const [schemaDropdownValue, setschemaDropdownValue] = useState([])
    const [newTableName, setnewTableName] = useState('')
    const [schemaTableName, setschemaTableName] = useState('')
    const [columnList, setcolumnList] = useState([])
    const [treeData, setTreeData] = useState([])
    const [showTableNameExistError, setshowTableNameExistError] = useState(false)
    const debounceTerm = useDebounce(newTableName, 500)
    useEffect(() => {

        if (toggleValue === 'New' && debounceTerm !== '') {
            let tableList = targetTableDetails.connnectionTableList
            let isTransformerNameExist = false
            if (tableList && tableList.length) {
                tableList.forEach((ele) => {
                    if (ele[0] === debounceTerm) {
                        isTransformerNameExist = true
                    }
                })
                if (isTransformerNameExist) {
                    setshowTableNameExistError(true)

                } else {
                    setshowTableNameExistError(false)

                }
            }
        }
    }, [debounceTerm])
    const getCurrentPODName = () => {
        return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails?.podName
    }

    const getCurrentPODCategoryName = () => {
        return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails?.dataDomain
    }

    useEffect(() => {
        if (connectionDetails && getObjectLength(connectionDetails) > 0) {
            setconnectionName(connectionDetails?.connection_name)
        }
    }, [connectionDetails])

    useEffect(() => {
        if (isDoneBtnClick && !showTableNameExistError) {

            let tableDetails = { ...targetTableDetails }
            let column = [];
            if (targetTableData && Object.keys(targetTableData).length && targetTableData.data_header?.length) {
                targetTableData.data_header.forEach(
                    (item, index) => {
                        column.push({ id: index, Name: item, Type: targetTableData.data_dtype[index], checked: false, })
                    }
                )
            }
            if ((toggleValue === 'Existing' && column.length) || (toggleValue === 'New' && newTableName !== '')) {
                tableDetails.targetColumns = toggleValue === 'Existing' ? column : columnList;
                tableDetails.schemaName = connectionType && connectionType !== 's3' && connectionType !== 'azure_adls' ? selectedSchema : `${connectionName}__temp__`;
                tableDetails.tableName = toggleValue === 'New' ? newTableName : schemaTableName;
                tableDetails.sourceTypeImage = connectionDetails?.source_type
                tableDetails.connectionName = connectionName
                tableDetails.typeOfTable = toggleValue
                store.dispatch(setTargetTableDetails(tableDetails))
                let finalFlowsElements = flowsElements.map(item => {
                    if (item.id === currentTransformer.id) {
                        item.content.targetTableDetails = tableDetails
                        item.content.connectionDetails = connectionDetails
                    }
                    return item
                })
                store.dispatch(updateFlowsElements([...finalFlowsElements]))
                setdoneBtnClick(false)

                onClose()

            } else if ((toggleValue === 'New' && newTableName === '')) {
                emitToastNotification('info', 'Please Enter Table Name and Schema datails')
                setdoneBtnClick(false)

            } else if (toggleValue === 'Existing' && column.length) {
                emitToastNotification('info', 'Please select target table datails')
                setdoneBtnClick(false)


            } else if (!column.length) {
                emitToastNotification('info', 'Target table datails not found')
                setdoneBtnClick(false)

            }
        } else {

            setdoneBtnClick(false)

        }
    }, [isDoneBtnClick])

    const fetchSchemaList = () => {
        try {
            const requestBody = {

                "connection_name": connectionName
            }

            store.dispatch(fetchDatabaseSchemaList(requestBody)).then(
                res => {
                    if (res) {
                        const data = res.data.data_record;
                        const schemaNames = data.flat();
                        let sourceDropdownList = []
                        schemaNames.forEach((schemaName, index) => {
                            sourceDropdownList.push({ key: index, Name: schemaName, Type: "", value: schemaName })

                        })
                        setschemaDropdownValue([...sourceDropdownList])
                    }
                })
        } catch (e) {

        }
    }
    useEffect(() => {
        if (toggleValue === 'New') {
            fetchSchemaList()
        }
    }, [toggleValue])

    useEffect(() => {
        if (currentTransformer && flowsElements && flowsElements.length) {

            let inputSource = store.dispatch(getInputTransformerDetails())
            let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
            let podDetails = {}
            if (inputSource?.length) {
                inputSource.forEach((item) => {

                    podDetails = { transformerId: item.id, podName: `${item?.transformerName}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: item?.transformerName, transformerType: item?.type, podId: item?.content?.table_id ?? '' }
                })
            }
            let inputColumns = cloneDeep(inputSource[0]?.content?.outcomeColumns);


            if (inputSource && inputSource?.length && inputColumns?.length) {
                let column = [];
                inputColumns.forEach(
                    item => {
                        column.push({ id: item.id, checked: false, Name: item.Name, Type: item.Type, Label: item.Name, columnValue: item.Name })
                    }
                )

                setcolumnList([...column])
            }



            if (podDetails) {

                let id = uuidv4();
                setUniqUUID(id)
                let request = {
                    uuid: id,
                    connection_name: !podDetails.podId && podDetails.connectionName ? podDetails.connectionName : 'hive',
                    schema_name: podDetails.dataDomain,
                    table_name: podDetails.podName,
                    page: 0,
                    do_count: false,
                    sort_body: [],
                    filter_body: [],
                    table_ids: podDetails.podId ? [podDetails.podId?.toString()] : []

                }

                setinputTableData({})

                if (request?.uuid) {
                    store.dispatch(fetchPreviewTable(request, false)).then((response) => {
                        if (response?.status === 'success' && response?.data?.result) {
                            let responseData = response.data.result;

                            if (responseData && responseData !== null) {

                                setinputTableData(responseData)

                            } else {
                                setinputTableData({})

                            }
                        }
                    })
                }

            } else {
                setinputTableData({})
            }


        }
    }, [currentTransformer, toggleValue])

    let toggleBtnOption = [{ label: 'Existing Table', value: 'Existing' }, { label: `New Table`, value: 'New' },]
    const preventSpaceEvent = (e) => {
        if (e.keyCode === 32) {
            e.preventDefault();
        }
    }

    const checkRegex = (key,e) => {
        
        if (key !== '_' && !(podNameRegex.test(key))) {
            e.returnValue = false;
            e.preventDefault() 
        } 
    }
    const [sizes, setSizes] = useState([235, 265]);

    const layoutCSS = {
        //   height: '100%',
        //   display: 'flex',
        //   alignItems: 'center',
        //   justifyContent: 'center'
    };


    return (

        <div className='modal_section_with_left_form w-100'>

            <div className='form_left col-4 p-3 m-0 target-md-left-sd'>
                <div className="d-flex custom-border-bottom icon-circle pb-2 justify-content-start">
                    <div className='align-items-start icon-box target-md-ic  justify-content-start'>

                        <SourceTypeImage dataName={connectionDetails && getObjectLength(connectionDetails) > 0 ? connectionDetails?.source_type : null} />
                    </div>
                    <div className="pl-2 custom-flex-width">
                        <p className="label mb-0" style={{ color: '#828282', fontSize: '12px' }}>Connection Name</p>
                        <p style={{ fontSize: '14px' }} className="text-uppercase paragraph p-0 m-0 custom-ellipsis one-line-ellipsis">{connectionDetails && getObjectLength(connectionDetails) > 0 ? connectionDetails?.connection_name : ''}</p>
                    </div>
                </div>


                <div className="d-flex justify-content-between padding-2 px-0 custom-border-bottom height-60">
                    <div className="toggle-btn-with-text target-modal-block-rd w-100 m-0">
                        <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: toggleValue, buttonClassName: "", buttonEvent: settoggleValue, ImgSrc: () => <></>, isDisable: false, buttonType: Literals.BTN_TOGGLE }} />
                    </div>
                </div>

                {toggleValue === 'Existing' ?
                    connectionType && connectionType?.toLowerCase() !== 's3' && connectionType?.toLowerCase() !== 'azure_adls'
                        ? <SchemaTree treeData={treeData} setTreeData={setTreeData} connectionName={connectionName} setSchemaDropdownValue={setschemaDropdownValue} settargetTableData={settargetTableData} setnewTableName={setschemaTableName} selectedSchema={setselectedSchema} />
                        : <><S3schemaTree treeData={treeData} setTreeData={setTreeData} connectionName={connectionName} setSchemaDropdownValue={setschemaDropdownValue} settargetTableData={settargetTableData} setnewTableName={setschemaTableName} selectedSchema={setselectedSchema} /></> :
                    <div>
                        {connectionType && connectionType?.toLowerCase() !== 's3' && connectionType?.toLowerCase() !== 'azure_adls' ? <div className="mt-2">
                            <SingleSelection props={{
                                label: "Select a schema", placeholder: "", dropDownValue: selectedSchema, onClickEvent: (value) => {
                                    setselectedSchema(value)
                                }, optionList: schemaDropdownValue
                            }} />

                        </div> : null}
                        <div className="mt-2">
                            <label className='label'>Table Name</label>
                            <input value={newTableName} className={showTableNameExistError ? 'form-control is-invalid' : 'form-control'} onKeyDown={(e) => { checkRegex(e.key,e) }} onChange={(e) => setnewTableName((e.target.value).toLowerCase())} type="text"></input>
                            <p className='tablename-note'><NOTEINFO /><span className='ml-1'>Only Lowercase and Underscores allowed</span></p>
                            {showTableNameExistError ? <p className='error'><NOTEINFO /> This Name Already Exist, Please Enter Another Table Name</p> : null}

                        </div>
                    </div>}

            </div>
            <div className='section_content_right col-8 p-0 m-0 '>

                <div style={{ height: '500px' }} className="SplitPane target-md-spane horizontal">
                    <SplitPane
                        split="horizontal"
                        sizes={sizes}

                        onChange={setSizes}
                        // minSize={200}
                        className={`flow-splitpane target-md`}
                    // defaultSize={200}

                    // maxSize={600}


                    // allowResize={true}
                    // onDragFinished={(size) => {

                    // }}
                    // size={`50%`}
                    >
                        <div className='h-100' style={{ ...layoutCSS }}>
                            {toggleValue === 'New' ?

                                <div className='w-100 h-100 target-col-sch'>
                                    <TargetColumnSchema columnList={columnList} setColumnList={setcolumnList} /></div> :
                                <>
                                    <div style={{ flex: '0 0 100%' }} className='d-flex align-items-center padding-2 modal-ic-fix'><TABLEICON /> <span className='ml-2'>Target Table</span></div>

                                    {targetTableData && Object.keys(targetTableData).length ? <>
                                        {/* <div className='d-flex align-items-center'><TABLEICON /> <span className='ml-2'>Target Table</span></div> */}
                                        <div className="w-100 custom-virtual-data-table h-100 target-md-upper-tbl">
                                            <DataGridTable resp={targetTableData}
                                                splitSize={sizes[0] - 50}
                                                fromRoute={"targetmodal2"}
                                                
                                                CurrentPODName={getCurrentPODName()}
                                                isSplitPane={true}
                                                CurrentDomainName={getCurrentPODCategoryName()}
                                                isShowHeader={false}
                                            // gridHeight={"calc(100vh - 420px)"}
                                            />


                                        </div>

                                    </> : <div style={{ flex: '0 0 100%' }}> <NoTableSelected fromModal={true} /></div>}
                                </>
                            }
                        </div>
                        <>
                            <div style={{ ...layoutCSS }}>

                                <div className="flow-lower-table bg-white h-100">
                                    <div className='d-flex align-items-center padding-2 modal-ic-fix'><TABLEICON /> <span className='ml-2'>Input Table</span></div>
                                    <div className='custom-virtual-data-table w-100 h-100 target-md-lower-tbl'>
                                        {inputTableData && Object.keys(inputTableData).length ? <DataGridTable resp={inputTableData}
                                            CurrentDomainName={()=>{}}
                                            CurrentPODName={()=>{}}
                                           
                                            isSplitPane={true}
                                            splitSize={sizes[1] - 50}
                                            uniqUUID={uniqUUID}
                                            setUniqUUID={setUniqUUID}
                                            fromRoute={"targetmodal2"}
                                            isShowHeader={false}
                                        /> : null}
                                    </div>


                                </div>
                            </div>
                        </>
                    </SplitPane>
                </div>
            </div>

        </div>
    )
}

export default TargetConnectionModalStep2
