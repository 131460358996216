import React from 'react'
import { eChartsThemeObject, getObjectLength, getReloadErrorTemplate, numberAbbr, pxToRem, remToPx, showVerticalBarLoader } from '../../common/helperFunctions'
import ReactECharts from 'echarts-for-react'
import 'echarts-wordcloud';
import _, { isArray } from 'lodash'
import { ICON_BAR_CHART } from '../../../Common/newIconSource'
import macarons from './echartsThemeMacarons.json'

const ChatContentGraph = ({
    loading = false,
    chartData = {},
    error = undefined,
    reload = () => {},
    setError = () => {},
    index,
    isTracker = false,
    hideTools = false,
    showMinimal = false,
    chartType = 'bar',
    chartExpanded = false,
    chatWindowRef = {}
}) => {

    const [graphWidth, setGraphWidth] = React.useState('100%')
    const [chartFailed, setChartFailed] = React.useState(false)

    React.useEffect(() => {
        const el = document.querySelector('#chatContGraph')
        if(el) {
            const elWidth = el?.offsetWidth
            if(elWidth) setGraphWidth(elWidth - 100)
        }
    }, [])

    React.useEffect(() => {
        let data = chartData
        if(data?.type?.toLowerCase() === 'multiline') {
            const multilineCheckArray = _.isArray(data?.['y-axis']?.[0] ?? [])
            setChartFailed(multilineCheckArray)
        }
        else if(data?.type?.toLowerCase() === 'multibar') {
            const multibarCheckArray = _.isArray(data?.['y-axis']?.[0] ?? [])
            setChartFailed(multibarCheckArray)
        }
        else if(data?.type?.toLowerCase() === 'pie') {
            const pieCheck = data?.['y-axis']?.length === 0 || data?.['y-axis']?.[0]?.length === 0
            setChartFailed(pieCheck)
        }
        // else if(data?.type?.toLowerCase() === 'bar') {
        //     if(data?.['x-axis']?.length === 1 && data?.['y-axis']?.length === 0) {
        //         setChartFailed(true)
        //     }
        // }
        
    }, [chartData])

    const dynamicFS = (px) => remToPx(pxToRem(px)) // Dynamic Font Size in PIXEL based on Base Font Size
    
    const getChartsOption = (type) => {
        if(!chartData || getObjectLength(chartData) === 0) return {}
        let data = chartData
        const fs = dynamicFS(14)
        switch (type) {
            case 'kpi':
                let kpi = data?.['x-axis']?.[0]
                let kpiObj = {}
                
                kpi = isNaN(kpi) ? kpi : parseFloat(kpi)?.toFixed(2)

                kpiObj = {
                    tooltip: kpi ?? '',
                    value: isNaN(kpi) ? kpi : numberAbbr(kpi) ?? '-'
                }
                return {
                    graphic: {
                        elements: [
                            {
                                type: 'text',
                                left: 'center',
                                top: '30%',
                                style: {
                                    text: data?.x_axis_label,
                                    fontSize: dynamicFS(30),
                                    fontWeight: 'bold',
                                    lineDash: [0, 200],
                                    lineDashOffset: 0,
                                    fill: 'transparent',
                                    stroke: '#333333',
                                    lineWidth: 1
                                },
                                keyframeAnimation: {
                                    duration: 3000,
                                    loop: false,
                                    keyframes: [
                                        {
                                            percent: 0.7,
                                            style: {
                                                fill: 'transparent',
                                                lineDashOffset: 200,
                                                lineDash: [200, 0]
                                            }
                                        },
                                        {
                                            // Stop for a while.
                                            percent: 0.8,
                                            style: {
                                                fill: 'transparent'
                                            }
                                        },
                                        {
                                            percent: 1,
                                            style: {
                                                fill: '#333333'
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                type: 'text',
                                left: 'center',
                                top: '40%',
                                style: {
                                    text: kpiObj?.value,
                                    fontSize: (() => {
                                        if(kpiObj?.value?.length > 10 && kpiObj?.value?.length < 24)
                                            return dynamicFS(40)
                                        else if(kpiObj?.value?.length > 24)
                                            return dynamicFS(24)
                                        return dynamicFS(60)                                        
                                    })(),
                                    fontWeight: 'bold',
                                    lineDash: [0, 200],
                                    lineDashOffset: 0,
                                    fill: 'transparent',
                                    stroke: '#256fa7',
                                    lineWidth: 1,
                                    width: kpiObj?.value?.length > 10 ? dynamicFS(500) : undefined,
                                    overflow: 'break',
                                    textAlign: kpiObj?.value?.length < 8 || kpiObj?.value?.length > 44 ? 'center' : 'left'
                                },
                                keyframeAnimation: {
                                    duration: 3000,
                                    loop: false,
                                    keyframes: [
                                        {
                                            percent: 0.7,
                                            style: {
                                                fill: 'transparent',
                                                lineDashOffset: 200,
                                                lineDash: [200, 0]
                                            }
                                        },
                                        {
                                            // Stop for a while.
                                            percent: 0.8,
                                            style: {
                                                fill: 'transparent'
                                            }
                                        },
                                        {
                                            percent: 1,
                                            style: {
                                                fill: '#2c83c4'
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
            }
            case 'line':
                const linecheckArray = _.isArray(data?.['y-axis']?.[0] ?? {})

                if(!linecheckArray) {
                    return getChartsOption('multiline')
                }

                return {
                    // title: {
                    //     text: data?.Title,
                    //     padding: [0, 0, 20, 0]
                    // },
                    textStyle: {
                        fontSize: fs
                    },
                    grid: { top: dynamicFS(38), right: dynamicFS(50), bottom: dynamicFS(60), left: dynamicFS(50) },
                    legend: {
                        data: data?.legends?.map(v => ({name: v}))
                    },
                    xAxis : { 
                        name: data?.x_axis_label,
                        type: 'category',
                        data: data?.['x-axis'],
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: data?.y_axis_label,
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    series: [
                        {
                            name: data?.y_axis_label,
                            data: data?.['y-axis']?.[0] ?? [],
                            type: 'line',
                            smooth: true,
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        textStyle: {
                            fontSize: fs
                        }
                    },
                };
            case 'multiline':
                const multilineCheckArray = _.isArray(data?.['y-axis']?.[0] ?? [])
                return {
                    // title: {
                    //     text: data?.Title,
                    //     padding: [0, 0, 20, 0],
                    // },
                    textStyle: {
                        fontSize: fs
                    },
                    legend: {
                        data: data?.legends?.map(v => ({name: v})),
                        type: 'scroll'
                    },
                    grid: { top: dynamicFS(60), right: dynamicFS(50), bottom: dynamicFS(60), left: dynamicFS(50), padding: [dynamicFS(10), dynamicFS(10)] },
                    xAxis : { 
                        name: data?.x_axis_label,
                        type: 'category',
                        data: data?.['x-axis'],
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: data?.y_axis_label,
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    series: !multilineCheckArray ? data?.['y-axis']?.map(v => ({
                        ...v,
                        type: 'line',
                        smooth: true
                    })) : []
                    ,
                    // series: data?.['y-axis']?.map((v, i) => (
                    //     {
                    //         name: data?.legends[i] ?? '',
                    //         data: v,
                    //         type: 'line',
                    //         smooth: true,
                    //     }
                    // )),
                    // series: [
                    //     {
                    //         name: data?.legends[0],
                    //         data: data?.['y-axis'][0],
                    //         type: 'line',
                    //         smooth: true,
                    //         // itemStyle: {
                    //         //     color: '#EE6666'
                    //         // },
                    //     },
                    //     // {
                    //     //     name: multiline_demo?.legends[1],
                    //     //     data: multiline_demo?.y_axis[1],
                    //     //     type: 'line',
                    //     //     smooth: true,
                    //     //     itemStyle: {
                    //     //         color: '#5470C6'
                    //     //     },
                    //     // },
                    // ],
                    tooltip: {
                        trigger: 'axis',
                        textStyle: {
                            fontSize: fs
                        }
                    },
                };
            
            case 'bar':
                if(data?.['x-axis']?.length === 1 && data?.['y-axis']?.length === 0) {
                    
                    data = {
                        ...data,
                        'x-axis': [data?.x_axis_label],
                        'y-axis': [data?.['x-axis']]
                    }
                    // console.log({data})
                }

                const checkArray = _.isArray(data?.['y-axis']?.[0] ?? {})

                let obj = {
                    // grid: {
                    //     height: 150,
                    //     // top: 50, right: 108, bottom: 0, left: 106
                    // },
                    // title: {
                    //   text: data?.Title,
                    //   padding: [0, 0, 20, 0],
                    //   top: 5
                    // },
                    textStyle: {
                        fontSize: fs
                    },
                    tooltip: {
                        textStyle: {
                            fontSize: fs
                        }
                    },
                    legend: {
                      data: data?.legends?.map(v => ({name: v})),
                      padding: [dynamicFS(5), dynamicFS(10)]
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01],
                        axisLabel: {
                            fontSize: fs
                        } 
                    },
                    yAxis: {
                        type: 'category',
                        name: data?.y_axis_label,
                        data: data?.['x-axis'],
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    series: [{
                        name: data?.legends?.[0],
                        type: 'bar',
                        data: checkArray ? data?.['y-axis']?.[0] ?? [] : [],
                        smooth: true,
                    }]
                };
                
                if(!checkArray) {
                    return getChartsOption('multibar')
                }
                return obj
            
            case 'column':
                if(data?.['x-axis']?.length === 1 && data?.['y-axis']?.length === 0) {
                    
                    data = {
                        ...data,
                        'x-axis': [data?.x_axis_label],
                        'y-axis': [data?.['x-axis']]
                    }
                    // console.log({data})
                }

                const checkColArray = _.isArray(data?.['y-axis']?.[0] ?? {})

                let colObj = {
                    // grid: {
                    //     height: 150,
                    //     // top: 50, right: 108, bottom: 0, left: 106
                    // },
                    // title: {
                    //   text: data?.Title,
                    //   padding: [0, 0, 20, 0],
                    //   top: 5
                    // },
                    textStyle: {
                        fontSize: fs
                    },
                    tooltip: {
                        textStyle: {
                            fontSize: fs
                        }
                    },
                    legend: {
                      data: data?.legends?.map(v => ({name: v})),
                      padding: [dynamicFS(5), dynamicFS(10)]
                    },
                    xAxis: {
                        name: data?.x_axis_label,
                        data: data?.['x-axis'],
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    yAxis: {
                        name: data?.y_axis_label,
                        axisLabel: {
                            fontSize: fs
                        }
                        // data: bar2_demo?.['y-axis']
                    },
                    series: [{
                        name: data?.legends?.[0] ?? '',
                        type: 'bar',
                        data: checkColArray ? data?.['y-axis']?.[0] ?? [] : [],
                        smooth: true,
                    }]
                };
                
                if(!checkColArray) {
                    return getChartsOption('multicolumn')
                }
                return colObj
            
            case 'multibar':
                const multibarCheckArray = _.isArray(data?.['y-axis']?.[0] ?? [])
                return {
                    // grid: {
                    //     height: 150,
                    //     // top: 50, right: 108, bottom: 0, left: 106
                    // },
                    // title: {
                    //   text: data?.Title,
                    //   padding: [0, 0, 20, 0],
                    //   top: 5
                    // },
                    textStyle: {
                        fontSize: fs
                    },
                    tooltip: {
                        textStyle: {
                            fontSize: fs
                        }
                    },
                    legend: {
                        data: data?.legends?.map(v => ({name: v})),
                        padding: [dynamicFS(5), dynamicFS(10)],
                        type: 'scroll'
                    },
                    xAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: fs
                        }
                        // name: data?.x_axis_label,
                        // data: data?.['x-axis'],
                    },
                    yAxis: {
                        type: 'category',
                        // name: data?.y_axis_label,
                        data: data?.['x-axis'],
                        axisLabel: {
                            fontSize: fs
                        }
                        // data: bar2_demo?.['y-axis']
                    },
                    series: !multibarCheckArray ? data?.['y-axis']?.map(v => ({
                        ...v,
                        type: 'bar',
                        smooth: true
                    })) : []
                    ,
                    // series: data?.['y-axis']?.map((v, i) => (
                    //     {
                    //         name: data?.legends[i] ?? '',
                    //         data: v,
                    //         type: 'bar',
                    //         smooth: true,
                    //     }
                    // )),
                    // series: [
                    //     {
                    //         name: multibar_demo?.['y-axis'][0],
                    //         type: 'bar',
                    //         data: multibar_demo?.y_axis[0],
                    //         smooth: true,
                    //         itemStyle: {
                    //             color: '#EE6666'
                    //         },
                    //     },
                    //     {
                    //         name: multibar_demo?.['y-axis'][1],
                    //         type: 'bar',
                    //         data: multibar_demo?.y_axis[1],
                    //         smooth: true
                    //     }
                    // ]
                };
            
            case 'pie':
                const pieDataObjarr = data?.['x-axis']?.map((v, i) => {
                    return {
                        value: data?.['y-axis']?.[0]?.[i] ?? [],
                        name: v
                    }
                })
                return {
                    //Default theme
                    // "color": [
                    //     "#5470c6",
                    //     "#91cc75",
                    //     "#fac858",
                    //     "#ee6666",
                    //     "#73c0de",
                    //     "#3ba272",
                    //     "#fc8452",
                    //     "#9a60b4",
                    //     "#ea7ccc"
                    // ],
                    textStyle: {
                        fontSize: fs
                    },
                    "series": [
                        {
                            "type": "pie",
                            "colorBy": "data",
                            "legendHoverLink": true,
                            "selectedMode": false,
                            "selectedOffset": 10,
                            "clockwise": true,
                            "startAngle": 90,
                            "minAngle": 0,
                            "minShowLabelAngle": 0,
                            "avoidLabelOverlap": true,
                            "stillShowZeroSum": true,
                            "percentPrecision": 2,
                            "showEmptyCircle": true,
                            "emptyCircleStyle": {
                                "color": "lightgray",
                                "borderColor": "#000",
                                "borderWidth": 0,
                                "borderType": "solid",
                                "borderDashOffset": 0,
                                "borderCap": "butt",
                                "borderJoin": "bevel",
                                "borderMiterLimit": 10,
                                "opacity": 1
                            },
                            "data": isArray(pieDataObjarr) ? pieDataObjarr : [],
                            "radius": [
                                "0%",
                                "60%"
                            ],
                            "center": [
                                "50%",
                                "50%"
                            ],
                            "label": {
                                "show": true,
                                "margin": 8
                            },
                            "labelLine": {
                                "show": true,
                                "showAbove": false,
                                "length": 15,
                                "length2": 15,
                                "smooth": false,
                                "minTurnAngle": 90,
                                "maxSurfaceAngle": 90
                            }
                        }
                    ],
                    "legend": 
                        {
                            "data": data?.['x-axis'],
                            "type": "scroll",
                            // "selected": {},
                            // "show": true,
                            "padding": [dynamicFS(5), dynamicFS(10), dynamicFS(5), dynamicFS(10)],
                            // "itemGap": 10,
                            // "itemWidth": 25,
                            // "itemHeight": 14,
                            "backgroundColor": "#f3f3f3",
                            "borderColor": "#ccc",
                            "borderWidth": 1,
                            "borderRadius": 6,
                            // "pageButtonItemGap": 5,
                            // "pageButtonPosition": "end",
                            // "pageFormatter": "{current}/{total}",
                            // "pageIconColor": "#2f4554",
                            // "pageIconInactiveColor": "#aaa",
                            // "pageIconSize": 15,
                            // "animationDurationUpdate": 800,
                            // "selector": false,
                            // "selectorPosition": "auto",
                            // "selectorItemGap": 7,
                            // "selectorButtonGap": 10,
                            // top: 0
                            orient: 'horizontal',
                            // orient: isTracker ? 'horizontal' : 'vertical',
                            left: 'left'
                        },
                    "tooltip": {
                        "show": true,
                        "trigger": "item",
                        "triggerOn": "mousemove|click",
                        "axisPointer": {
                            "type": "line"
                        },
                        "showContent": true,
                        "alwaysShowContent": false,
                        "showDelay": 0,
                        "hideDelay": 100,
                        "enterable": false,
                        "confine": false,
                        "appendToBody": false,
                        "transitionDuration": 0.4,
                        "textStyle": {
                            "fontSize": dynamicFS(14)
                        },
                        "borderWidth": 0,
                        "padding": dynamicFS(5),
                        "order": "seriesAsc"
                    },
                    // "title": [
                    //     {
                    //         "show": true,
                    //         "text": data?.Title,
                    //         "target": "blank",
                    //         "subtarget": "blank",
                    //         "padding": [0, 0, 20, 0],
                    //         "itemGap": 10,
                    //         "textAlign": "auto",
                    //         "textVerticalAlign": "auto",
                    //         "triggerEvent": false,
                    //         top: 35
                    //     }
                    // ]
                }
            
            case 'funnel':

                const dataArray = data?.['x-axis']?.map((v, i) => {
                    return {
                        value: data?.['y-axis']?.[0]?.[i] ?? [],
                        name: v
                    }
                })
                
                return {
                    // title: {
                    //   text: 'Funnel'
                    // },
                    textStyle: {
                        fontSize: fs
                    },
                    tooltip: {
                      trigger: 'item',
                      formatter: '{a} <br/>{b} : {c}',
                      textStyle: {
                        fontSize: fs
                    }
                    },
                    // toolbox: {
                    //   feature: {
                    //     dataView: { readOnly: false },
                    //     restore: {},
                    //     saveAsImage: {}
                    //   }
                    // },
                    legend: {
                      data: data?.legends?.map(l => ({name: l})),
                      type: 'scroll'
                    },
                    series: [
                      {
                        name: data?.x_axis_label,
                        type: 'funnel',
                        left: '10%',
                        top: dynamicFS(60),
                        bottom: dynamicFS(60),
                        width: '80%',
                        // min: 0,
                        // max: 100,
                        minSize: '0%',
                        maxSize: '100%',
                        sort: 'descending',
                        gap: 2,
                        label: {
                          show: true,
                          position: 'inside'
                        },
                        labelLine: {
                          length: 10,
                          lineStyle: {
                            width: 1,
                            type: 'solid'
                          }
                        },
                        itemStyle: {
                          borderColor: '#fff',
                          borderWidth: 1
                        },
                        emphasis: {
                          label: {
                            fontSize: dynamicFS(20)
                          }
                        },
                        data: isArray(dataArray) ? [...dataArray] : []
                        // data: [
                        //   { value: 60, name: 'Visit' },
                        //   { value: 40, name: 'Inquiry' },
                        //   { value: 20, name: 'Order' },
                        //   { value: 80, name: 'Click' },
                        //   { value: 100, name: 'Show' }
                        // ]
                      }
                    ]
                };

            case 'gauge':
                return {
                    textStyle: {
                        fontSize: fs
                    },
                    grid: {
                        height: dynamicFS(200)
                    },
                    tooltip: {
                      formatter: '{a} <br/>{b} : {c}%',
                      textStyle: {
                        fontSize: fs
                    }
                    },
                    legend: {
                        data: data?.legends?.map(l => ({name: l})),
                        type: 'scroll'
                    },
                    series: [
                      {
                        progress: {
                            show: true
                        },
                        name: data?.Title,
                        type: 'gauge',
                        detail: {
                          formatter: '{value}'
                        },
                        data: [
                          {
                            value: data?.['y-axis']?.[0] ?? [],
                            name: data?.y_axis_label
                          }
                        ]
                      }
                    ]
                };

            case 'radar':
                return {
                    // title: {
                    //   text: 'Basic Radar Chart'
                    // },
                    textStyle: {
                        fontSize: fs
                    },
                    tooltip: {
                        // formatter: '{a}'
                        textStyle: {
                            fontSize: fs
                        }
                    },
                    legend: {
                        data: data?.legends,
                        // data: data?.legends?.map(l => ({name: l})),
                        type: 'scroll'
                    },
                    radar: {
                      // shape: 'circle',
                      name: data?.x_axis_label,
                      indicator: data?.['x-axis']?.map(v => ({
                        name: v
                      }))
                    //   indicator: [
                    //     { name: 'Sales', max: 6500 },
                    //     { name: 'Administration', max: 16000 },
                    //     { name: 'Information Technology', max: 30000 },
                    //     { name: 'Customer Support', max: 38000 },
                    //     { name: 'Development', max: 52000 },
                    //     { name: 'Marketing', max: 25000 }
                    //   ]
                    },
                    series: [
                      {
                        name: data?.y_axis_label,
                        type: 'radar',
                        tooltip: {
                            trigger: 'item'
                        },
                        // tooltip: {
                        //     formatter: '{a}'
                        // },
                        data: [
                            {
                                value: data?.['y-axis']?.[0] ?? [],
                                name: data?.y_axis_label
                            }
                        ]
                        // data: [
                        //   {
                        //     value: [4200, 3000, 20000, 35000, 50000, 18000],
                        //     name: 'Allocated Budget'
                        //   },
                        //   {
                        //     value: [5000, 14000, 28000, 26000, 42000, 21000],
                        //     name: 'Actual Spending'
                        //   }
                        // ]
                      }
                    ]
                };
            case 'stackedbar':
                const stackedbarCheckArray = _.isArray(data?.['y-axis']?.[0] ?? [])
                return {
                    textStyle: {
                        fontSize: fs
                    },
                    tooltip: {
                        textStyle: {
                            fontSize: fs
                        }
                    },
                    legend: {
                        data: data?.legends?.map(v => ({name: v})),
                        padding: [dynamicFS(5), dynamicFS(10)],
                        type: 'scroll'
                    },
                    xAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    yAxis: {
                        name: data?.y_axis_label,
                        data: data?.['x-axis'],
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    series: !stackedbarCheckArray ? data?.['y-axis']?.map(v => (
                        {
                            name: v?.name,
                            data: v?.data ?? [],
                            type: 'bar',
                            stack: 'total',
                            smooth: true,
                            // label: {
                            //     show: true
                            // },
                            emphasis: {
                                focus: 'series'
                            },
                            // areaStyle: {}
                        }
                    )) : [],
                };
            case 'stackedcolumn':
                const stackedcolumnCheckArray = _.isArray(data?.['y-axis']?.[0] ?? [])
                return {
                    textStyle: {
                        fontSize: fs
                    },
                    tooltip: {
                        textStyle: {
                            fontSize: fs
                        }
                    },
                    legend: {
                        data: data?.legends?.map(v => ({name: v})),
                        padding: [dynamicFS(5), dynamicFS(10)],
                        type: 'scroll'
                    },
                    xAxis: {
                            type: 'category',
                            data: data?.['x-axis'],
                            axisLabel: {
                            fontSize: fs
                        }
                        },
                    yAxis: {
                            type: 'value',
                            axisLabel: {
                            fontSize: fs
                        }
                        },
                    series: !stackedcolumnCheckArray ? data?.['y-axis']?.map(v => (
                        {
                            name: v?.name,
                            data: v?.data ?? [],
                            type: 'bar',
                            stack: 'total',
                            smooth: true,
                            // label: {
                            //     show: true
                            // },
                            emphasis: {
                                focus: 'series'
                            },
                            // areaStyle: {}
                        }
                    )) : [],
                };
            case 'multicolumn':
                
                return {
                    textStyle: {
                        fontSize: fs
                    },
                    tooltip: {
                        textStyle: {
                            fontSize: fs
                        }
                    },
                    legend: {
                        data: data?.legends?.map(v => ({name: v})),
                        padding: [dynamicFS(5), dynamicFS(10)],
                        type: 'scroll'
                    },
                    xAxis: {
                            type: 'category',
                            data: data?.['x-axis'],
                            axisLabel: {
                                fontSize: fs
                            }
                        },
                    yAxis: {
                            type: 'value',
                            axisLabel: {
                                fontSize: fs
                            }
                        },
                    series: data?.['y-axis']?.map(v => (
                        {
                            name: v?.name,
                            data: v?.data ?? [],
                            type: 'bar',
                            barGap: 0,
                            smooth: true,
                            // label: {
                            //     show: true,
                            //     position: 'insideBottom',
                            //     distance: 15,
                            //     align: 'left',
                            //     verticalAlign: 'middle',
                            //     rotate: 90,
                            //     formatter: '{c}  {name|{a}}',
                            //     fontSize: 11,
                            //     rich: {
                            //       name: {}
                            //     }
                            // },
                            emphasis: {
                                focus: 'series'
                            },
                        }
                    )),
                };
            
            case 'stackedarea':
                
                return {
                    // title: {
                    //     text: data?.Title,
                    //     padding: [0, 0, 20, 0]
                    // },
                    textStyle: {
                        fontSize: fs
                    },
                    grid: { top: dynamicFS(38), right: dynamicFS(50), bottom: dynamicFS(60), left: dynamicFS(50) },
                    legend: {
                        data: data?.legends?.map(v => ({name: v})),
                        type: 'scroll'
                    },
                    xAxis : { 
                        name: data?.x_axis_label,
                        type: 'category',
                        data: data?.['x-axis'],
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: data?.y_axis_label,
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    series: data?.['y-axis']?.map(v => (
                        {
                            name: v?.name,
                            data: v?.data ?? [],
                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            areaStyle: {}
                        }
                    )),
                    tooltip: {
                        trigger: 'axis',
                        textStyle: {
                            fontSize: fs
                        }
                    },
                };
            
            case 'area':
                
                return {
                    // title: {
                    //     text: data?.Title,
                    //     padding: [0, 0, 20, 0]
                    // },
                    textStyle: {
                        fontSize: fs, // Set your desired global font size here
                    },
                    grid: { top: dynamicFS(38), right: dynamicFS(50), bottom: dynamicFS(60), left: dynamicFS(50) },
                    legend: {
                        data: data?.legends?.map(v => ({name: v}))
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data?.['x-axis'],
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    series: [{
                            name: data?.['y_axis_label'],
                            data: data?.['y-axis']?.[0] ?? [],
                            type: 'line',
                            smooth: true,
                            areaStyle: {}
                        }],
                    
                    tooltip: {
                        trigger: 'axis',
                        textStyle: {
                            fontSize: fs
                        }
                    },
                };
            
            case 'scatter':
                
                return {
                    // title: {
                    //     text: data?.Title,
                    //     padding: [0, 0, 20, 0]
                    // },
                    textStyle: {
                        fontSize: fs, // Set your desired global font size here
                    },
                    grid: { top: dynamicFS(38), right: dynamicFS(50), bottom: dynamicFS(60), left: dynamicFS(50) },
                    legend: {
                        data: data?.legends?.map(v => ({name: v})),
                        type: 'scroll'
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data?.['x-axis'],
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: fs
                        }
                    },
                    series: {
                            name: data?.['y_axis_label'],
                            symbolSize: dynamicFS(10),
                            data: data?.['y-axis']?.[0] ?? [],
                            type: 'scatter'
                        },
                    tooltip: {
                        trigger: 'axis',
                        textStyle: {
                            fontSize: fs
                        }
                    },
                };

            case 'wordcloud':
                const wordData = (() => {
                    function truncateText(text, maxLength) {
                        if (text.length > maxLength) {
                            return text.substring(0, maxLength) + '...';
                        }
                        return text;
                    }
                    let arr = []
                    data?.['x-axis']?.forEach((el, idx) => {
                        arr.push({
                            name: truncateText(el, 8),
                            value: data?.['y-axis']?.[0]?.[idx],
                            originalName: el
                        })
                    })
                    return arr
                })()
                return {
                    textStyle: {
                        fontSize: fs, // Set your desired global font size here
                    },
                    tooltip: {
                        formatter: function (params) {
                            return params.data.originalName + ': ' + params.data.value;
                        },
                        textStyle: {
                            fontStyle: "normal",
                            fontFamily: "InterSemibold",
                            fontSize: fs
                        }
                    },
                    series: [{
                        type: 'wordCloud',
                        // gridSize: 12,
                        // sizeRange: isTracker ? [15, 50] : [30, 80],
                        // rotationRange: [0, 90],
                        // rotationStep: 90,
                        // The shape of the "cloud" to draw. Can be any polar equation represented as a
                        // callback function, or a keyword present. Available presents are circle (default),
                        // cardioid (apple or heart shape curve, the most known polar equation), diamond (
                        // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.
                        // Shapes: pentagon, star, random-light, random-dark, circle, cardioid, diamond, triangle-forward, triangle, triangle-upright, apple, heart shape curve
                        // shape: 'square',
                        // width: 600,
                        // height: 400,
                        // drawOutOfBound: false,

                        shape: 'circle',
                        keepAspect: true,
                        left: 'center',
                        top: 'center',
                        width: '80%',
                        height: '80%',
                        sizeRange: [dynamicFS(12), dynamicFS(60)],
                        rotationRange: [0, 90],
                        // rotationRange: [-90, 90],
                        rotationStep: 45,
                        gridSize: 8,
                        drawOutOfBound: false,
                        textStyle: {
                            color: function () {
                                return 'rgb(' + [
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160),
                                    Math.round(Math.random() * 160)
                                ].join(',') + ')';
                            },
                            emphasis: {
                                shadowBlur: 10,
                                shadowColor: '#333'
                            },
                            fontFamily: 'InterSemiBold'
                        },
                        data: [...wordData]
                    }]
                }
        
            default:
                return {}
        }
    }

    const temp = {
        0: 'bar',
        1: 'line',
        2: 'pie',
        3: 'funnel',
        4: 'gauge',
        5: 'radar',
        6: 'multiline',
        7: 'multibar',
    }

    const commonHeight = isTracker ? showMinimal ? dynamicFS(330) : dynamicFS(260) : dynamicFS(360)

    const options = React.useMemo(() => {
        let obj = {...getChartsOption(chartType ?? 'multiline')}
        if(showMinimal) {
            obj = {
                ...obj,
                title: {
                    // left: 'center',
                    text: chartData?.Title,
                    textStyle: {
                        fontFamily: 'InterSemibold',
                        fontSize: dynamicFS(16)
                    },
                    "padding": [dynamicFS(10), 0, dynamicFS(20), dynamicFS(5)],
                    "itemGap": dynamicFS(10),
                    "textAlign": "auto",
                    "textVerticalAlign": "auto",
                    "triggerEvent": false,
                    // top: 35
                },
                grid: {
                    top: isTracker ? dynamicFS(100) : dynamicFS(130), 
                    // right: 108,
                    //  bottom: 0, 
                    //  left: 106
                },
                legend: {
                    ...getChartsOption(chartType ?? 'multiline')?.legend,
                    top: dynamicFS(38),
                    textStyle: {
                        fontSize: dynamicFS(12), // Font size for legend text
                    },
                    itemWidth: remToPx(pxToRem(22)), // Width of the legend icon
                    itemHeight: remToPx(pxToRem(12)), // Height of the legend icon
                }
            }
        }

        return obj

    }, [chartData, showMinimal, chartType])
    
    const fitInChatWindow = () => {
        let clientHeight = chatWindowRef?.current?.clientHeight
        return clientHeight - 50
    }

    return (
        <div id='chatContGraph' 
        // onClick={(e) => {e.stopPropagation()}}
        >
            {
                loading
                ?   <div className='d-flex flex-wrap align-items-center justify-content-center w-100 align-content-center' style={{height: dynamicFS(isTracker ? showMinimal ? 330 : 260 : 360)}}>
                        {showVerticalBarLoader()}
                        <div className='fontInterSemiBold mt-4 color-primary fontSizeHeading pt-2'>Loading Chart...</div>
                    </div>
                // ?   <ClaristaLoader/>
                :   error ? <div className='d-flex align-items-center justify-content-center w-100 flex-wrap chat-grph-err' style={{height: commonHeight}}>
                        {getReloadErrorTemplate({errorMessage: error, onReload: () => reload(), hideReloadBtn: true})}
                    </div>
                    :
                    chartData?.type && !chartFailed
                    ?
                    <ReactECharts
                        key={`${chartData?.Title ?? '-chart-'}${index}`}
                        opts={{renderer: chartData?.type?.toLowerCase() === 'kpi' ? 'svg' : 'canvas'}}
                        option={
                            {
                                ...options,
                                // ...getChartsOption(chartData?.payload?.type?.toLowerCase() ?? 'multiline'),
                                toolbox: {
                                    show: !hideTools,
                                        //     !hideTools &&
                                        //    ["line", "multiline", "multicolumn", "bar", "multibar", "stackedbar", "stackedcolumn", "stackedarea", "area", "column"]
                                        //    ?.includes(chartData?.payload?.type?.toLowerCase()),
                                    feature: {
                                      dataZoom: {
                                        yAxisIndex: "none"
                                      },
                                    //   magicType: {
                                    //     type: ["line", "bar"]
                                    //   },
                                      restore: { show: true },
                                      saveAsImage: {}
                                    },
                                },
                                tooltip: (() => {
                                    if(options?.tooltip) {
                                        return {
                                            ...options?.tooltip,
                                            // trigger: 'axis',
                                            // axisPointer: {
                                            //     type: 'shadow' // Display tooltip as a shadow
                                            // }
                                        }
                                    }
                                })(),
                                xAxis: (() => {
                                    if(options?.xAxis) {
                                        return {
                                            ...options?.xAxis,
                                            nameTruncate: {
                                                maxWidth: 80, // Adjust as needed
                                                ellipsis: '...'
                                            },
                                            axisLabel: {
                                                formatter: function (value) {
                                                    // Customize the truncation logic here
                                                    const maxLength = 8; // Set your desired maximum label length
                                                    if (value.length > maxLength) {
                                                        return value.substring(0, maxLength) + '...';
                                                    }
                                                    return value;
                                                }
                                            },
                                            // splitLine: {
                                            //     show: false // Hide Y-axis grid lines
                                            // }
                                        }
                                    }
                                })(),
                                yAxis: (() => {
                                    if(options?.yAxis) {
                                        return {
                                            ...options?.yAxis,
                                            nameTruncate: {
                                                maxWidth: 80, // Adjust as needed
                                                ellipsis: '...'
                                            },
                                            axisLabel: {
                                                formatter: function (value) {
                                                    // Customize the truncation logic here
                                                    const maxLength = 15; // Set your desired maximum label length
                                                    if (value.length > maxLength) {
                                                        return value.substring(0, maxLength) + '...';
                                                    }
                                                    return value;
                                                }
                                            },
                                            // splitLine: {
                                            //     show: false // Hide Y-axis grid lines
                                            // }
                                        }
                                    }
                                })(),
                                grid: (() => {
                                    if(options?.grid) {
                                        return {
                                            ...options?.grid,
                                            left: 60,
                                            right: 110,
                                            bottom: 30,
                                            containLabel: true,
                                        }
                                    }
                                    return {}
                                })()
                            }
                        }

                        theme={
                            {
                                // ...macarons,
                                color: [
                                    ...eChartsThemeObject?.color

                                    // '#255d91',
                                    // '#4d90cc',
                                    // '#2d679d',
                                    // '#629dd2',
                                    // '#3982c6',
                                    // '#77aad8',
                                    // '#466c8e',
                                    // '#7da7cf',
                                    // '#1f68aa',
                                    // '#7baad6',


                                    // '#004cc9',
                                    // '#0061ff',
                                    // '#1b71ff',
                                    // '#3682ff',
                                    // '#5093ff',
                                    // '#6ba3ff',
                                    // '#86b4ff',
                                    // '#a1c5ff',
                                    // '#bcd5ff',
                                    // '#d7e6ff',
                                    // '#f2f7ff',

                                    // '#2078BA',
                                    // '#C1DB36',
                                    // '#542F99',
                                    // '#E9B93C',
                                    // '#7EE9F6',
                                    // '#42BA72',
                                    // '#114E7A',
                                    // '#FFDB7E',
                                    // '#CBE9F6',
                                    // '#51545E'

                                    /**
                                     * Clarista Custom
                                     */
                                    // "#2c83c4",
                                    // "#e0a932",
                                    // "#05253d",
                                    // "#4fd499",
                                    // "#714b08",
                                    // "#e33a85",
                                    // "#c5ab67",
                                    // "#e98199",
                                    // "#4e4ab3",
                                    // "#b60404",
                                    // "#8dbefa",
                                    // "#ff6347",
                                    // "#a092f1",
                                    // "#0a5a3b",
                                    // "#d6e862",
                                    // "#6699ff",
                                    // "#ff6666",
                                    // "#68008f",
                                    // "#7d7d7d",
                                    // "#56e1e1"

                                    /**
                                     * AIR BNB
                                     */
                                    // '#29696B',
                                    // '#5BCACE',
                                    // '#F4B02A',
                                    // '#F1826A',
                                    // '#792EB2',
                                    // '#C96EC6',
                                    // '#921E50',
                                    // '#B27700',
                                    // '#9C3498',
                                    // '#9C3498',
                                    // '#E4679D',
                                    // '#C32F0E',
                                    // '#9D63CA'

                                    /**
                                     * Google
                                     */
                                    // '#3366cc',
                                    // '#dc3912',
                                    // '#ff9900',
                                    // '#109618',
                                    // '#990099',
                                    // '#0099c6',
                                    // '#dd4477',
                                    // '#66aa00',
                                    // '#b82e2e',
                                    // '#316395',
                                    // '#994499',
                                    // '#22aa99',
                                    // '#aaaa11',
                                    // '#6633cc',
                                    // '#e67300',
                                    // '#8b0707',
                                    // '#651067',
                                    // '#329262',
                                    // '#5574a6',
                                    // '#3b3eac',
                                ]
                            }
                        }
                        style={{height: chartExpanded ? fitInChatWindow() : `${commonHeight}px`, width: '100%', margin: 0, transition: '.3s'}}
                        className='pt-2'
                    />
                    :   <div className='no-chart-data-found-temp py-5 text-center text-black-50 d-flex 
                        align-items-center justify-content-center align-content-center flex-wrap' 
                            style={{minHeight: commonHeight}} >
                                <div className='w-100 mb-3'>
                                    <ICON_BAR_CHART height='50' width='50' color='#33333370' />
                                </div>
                                <div className='w-100' style={{lineHeight: 1.4}}>
                                No Chart Data Found
                                </div>
                    </div>
            }
        </div>
    )
}

export default ChatContentGraph