import React from "react";

const AddUserGroupDetails = ({
  groupName,
  viewMode,
  groupDescription,
  onChange,
  isLeader = false,
  isSuperUser = false
}) => {



  return (
    <div className="user-group-details">
      <div className="user-group-details-header">User Group Details</div>
      <div className="user-group-details-fields">
        <div className="form-group">
          <label className="label">User Group Name</label>
          <input
            value={groupName}
            placeholder="Untitled Usergroup"
            className="custom-input-field"
            disabled={!(isSuperUser || isLeader) || viewMode.includes('view')}
            onChange={(e) => {
              onChange("groupName", e.target.value);
            }}
          ></input>
        </div>
        <div className="form-group">
          <label className="label">User Group Description</label>
          <textarea
            value={groupDescription}
            placeholder="Please Enter Description"
            disabled={!(isSuperUser || isLeader) || viewMode.includes('view')}
            onChange={(e) => {
              onChange("groupDescription", e.target.value);
            }}
            className="custom-textarea-field"
          />
        </div>
      </div>
    </div>
  );
};

export default AddUserGroupDetails;
