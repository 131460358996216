import React, { createContext, useState } from "react"

const ConnectionContext = createContext({
    fromConnectionRoute: false,
    setFromConnectionRoute: () => {},
    connectionStatus: null, 
    setConnectionStatus: () => {},
    contextSearchValue: '',
    leftSideObject: {},
    setLeftSideObject: () => {},
    rightSideObject: {},
    setRightSideObject: () => {}
})


export const ConnectionProvider = ({ children }) => {
    const [fromConnectionRoute, setFromConnectionRoute] = useState(false)
    const [connectionStatus, setConnectionStatus] = useState(null)
    const [contextSearchValue, setContextSearchValue] = useState('')
    const [leftSideObject, setLeftSideObject] = useState({})
    const [rightSideObject, setRightSideObject] = useState({})
    
    return (
        <ConnectionContext.Provider value={{
            fromConnectionRoute, 
            setFromConnectionRoute, 
            connectionStatus, 
            setConnectionStatus, 
            contextSearchValue, 
            setContextSearchValue,
            leftSideObject,
            setLeftSideObject,
            rightSideObject,
            setRightSideObject
        }}>
            {children}
        </ConnectionContext.Provider>
    )
}


export default ConnectionContext;