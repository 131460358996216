export const SET_LANDINGPAGE_DATA = "SET_LANDINGPAGE_DATA";
export const SET_LANDINGPAGE_FILTER = "SET_LANDINGPAGE_FILTER";
export const SET_TAG_LIST = "SET_TAG_LIST";
export const SET_LANDINGPAGE_TAG = "SET_LANDINGPAGE_TAG";
export const OPEN_MODEL_LANDING = "OPEN_MODEL_LANDING"
export const SET_SELECTED_POD_LIST = "SET_SELECTED_POD_LIST"
export const SET_LANDING_PAGE_DEFAULT = "SET_LANDING_PAGE_DEFAULT";
export const IS_TAG_LIST = "IS_TAG_LIST";
export const CREATE_FOLDER = "CREATE_FOLDER";
export const DATA_DOMAIN_LIST="DATA_DOMAIN_LIST"
export const DOMAIN_GROUP_DATA="DOMAIN_GROUP_DATA"

