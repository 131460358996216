import React, { useState, useEffect } from 'react'
import { useSelector, useStore } from "react-redux"

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Buttons from '../../../Common/button/Buttons';
import { CHECK, CLOSE } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';

import LandingPageIndex from '../landingpage/LandingPageIndex';
import { setSelectedAlertDetails } from '../../../../store/modules/alert/createAlert/createAlertAction';




const AlertSelectionModal = ({ isModalOpen, onClose }) => {
    const store = useStore()
    const selectedAlertTransformer = useSelector(state => state.Alert.CreateAlert.selectedAlertDetails)
    const [selectedAlertInfo, setselectedAlertInfo] = useState({})
    useEffect(() => {
      

        if (selectedAlertTransformer?.id) {

            setselectedAlertInfo(selectedAlertTransformer)
        }
    }, selectedAlertTransformer)
    return (
        <Modal size="xl" isOpen={isModalOpen} className="custom-modal connection-modal" centered={true}>
            <ModalHeader>

                <div className="paragraph font-w-600">Select Alert</div>
                <Buttons props={{ tooltip: 'Close', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon float-right", buttonEvent: () => { onClose() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

            </ModalHeader>
            <ModalBody className='overflow-hidden'>
                <div className='alert-select-modal'>
                    <LandingPageIndex fromRoute="scheduler" selectedAlertInfo={selectedAlertInfo} setselectedAlertInfo={setselectedAlertInfo} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Buttons props={{ tooltip: 'Done', buttonText: 'Done', buttonClassName: " custom-btn-primary custom-btn btn-with-text float-right", buttonEvent: () => { store.dispatch(setSelectedAlertDetails(selectedAlertInfo)); onClose() }, ImgSrc: () => <CHECK />, isDisable: Object.keys(selectedAlertInfo).length ? false : true, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />

            </ModalFooter>

        </Modal >
    )
}

export default AlertSelectionModal
