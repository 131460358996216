export const tags = [
  {
    id: "red",
    color: "#ff2f39"
  },
  {
    id: "orange",
    color: "#FAB43A",
  },
  {
    id: "green",
    color: "#00CC92",
  },
  {
    id: "yellow",
    color: "#ddca00",
  },
  {
    id: "grey",
    color: "#AFAFAF",
  },
  {
    id: "purple",
    color: "#5e0c6b",
  },
  {
    id: "blue",
    color: "#0056ff",
  },
  {
    id: "magenta",
    color: "#FF00FF",
  },
  {
    id: "pink",
    color: "#FE7BAA",
  },
];