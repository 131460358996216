import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { createSettingsDataCategory, deleteCategoryPolicyFile, getCategoryPolicyFileContent, getCategoryPolicyFiles, updateCategoryPolicyFileName, updateSettingsDataCategory, uploadCategoryPolicyFile } from '../../../../store/modules/dataDictionary/action';
import Buttons from '../../../Common/button/Buttons';
import MultiSelection from '../../../Common/dropDown/MultiSelection';
import { CHECK, CLOSE, PLUS, CATEGORY, UPLOAD } from '../../../Common/iconSource';
import { getRelativeTime, getObjectLength, getTime, preventSpecialCharsRegex, toTitleCase } from '../../common/helperFunctions';
import { Literals } from '../../common/literals';
import FileExplorerTileView from './FileExplorerTileView';
import { ICON_SAVE } from '../../../Common/newIconSource';

const initialFieldValue = {
    id:null,
    name: '',
    description: '',
    owners: [],
    stewards: [],
    files: []
}

const DataCategoryModal = ({
    openModal=false, 
    setOpenModal=()=>{},
    getList=()=>{},
    selectedData={},
    viewOnly=false,
    setViewOnly=()=>{},
    isEdit=false,
    setIsEdit=()=>{},
    usersList=[]
}) => {

    const dispatch = useDispatch()

    const [fieldsValue, setFieldsValue] = useState(
            {
                ...initialFieldValue
            }
        )

    const [isLoading, setIsLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [created, setCreated] = useState(false)

    useEffect(()=>{
        if(isEdit) {
            let values = {
                id: selectedData?.id,
                name: selectedData?.name,
                description: selectedData?.description,
                owners: getObjectLength(selectedData?.owner_steward_detail) ? selectedData?.owner_steward_detail?.owner.map(v=>`${v.id}`) : [],
                stewards: getObjectLength(selectedData?.owner_steward_detail) ? selectedData?.owner_steward_detail?.steward.map(v=>`${v.id}`) : [],
                files: []
            }
            setFieldsValue({...values})
            dispatch(getCategoryPolicyFiles(selectedData?.id))
            .then(res=>{
                setFieldsValue(
                    {
                        ...values,
                        files: res.data.map((v=>({name:v.file_name, size:v.file_size, file_upload_time:v.file_upload_time, id:v.id, edit: false})))
                    }
                )
            })
            // .catch(()=>{
            //     emitToastNotification('error', 'Failed to load uploaded files.')
            // })
        }
    },[isEdit, selectedData])

    const handleFields = (value, key) => {
        if(key === 'name') {
            value = value.toUpperCase()
        }
        setFieldsValue(prev=>({...prev, [key]: value}))
    }
    
    const handleAdd = () => {
        setIsLoading(true)
        let payload = createPayload()
        dispatch(createSettingsDataCategory(payload))
        .then((res)=>{
            getList()
            setIsLoading(false)
            // setFieldsValue({...initialFieldValue})
            setFieldsValue({...fieldsValue, id: res.data.id})
            setCreated(true)
            emitToastNotification('success',Literals.ADD_SUCCESSFUL )
        })
        .catch(()=>{
            emitToastNotification('error', Literals.ADD_FAILED)
            setCreated(false)
            setIsLoading(false)
        })
    }

    const handleUpdate = () => {
        setIsLoading(true)
        let payload = createPayload()
        dispatch(updateSettingsDataCategory(selectedData?.id, payload))
        .then(()=>{
            getList()
            setIsLoading(false)
            setOpenModal(false)
            emitToastNotification('success',Literals.UPDATE_SUCCESSFUL)
        })
        .catch(()=>{
            emitToastNotification('error',Literals.UPDATE_FAILED )
            setIsLoading(false)
        })
    }

    const createPayload = () => {
        let payload = {
            name: fieldsValue.name,
            description: fieldsValue.description
        }
        payload['owner_steward'] = fieldsValue.owners.map(v=>({user:parseInt(v.split('::')[0]), user_type:'OWNER'}))
        payload['owner_steward'] = [...payload['owner_steward'], ...fieldsValue.stewards.map(v=>({user:parseInt(v.split('::')[0]), user_type:'STEWARD'}))]
        return payload
    }

    const submitButton = {
        getText: () => {
            if(isEdit) {
                if(isLoading) return 'Updating...'
                return 'Update'
            }
            else {
                if(isLoading) return 'Saving...'
                return 'Save'
            }
        },
        onClick: () => {
            if(isEdit) handleUpdate()
            else handleAdd()
        },
        getIcon: () => {
            if(isLoading) return <i className='bx bx-loader-alt bx-spin mr-1'></i>
            else if(isEdit) return <CHECK/>
            else return <ICON_SAVE/>
        },
        getDisableStatus: () => {
            return _.isEmpty(fieldsValue.name.trim()) || isLoading
        }
    }

    const handleUpload = (files) => {
        for (let x = 0; x < files.length; x++) {
            uploadFileSubmit(files[x]);
        }
    }

    const uploadFileSubmit = (file) => {
        let formData = new FormData();
        formData.append('file', file)
        formData.append('category', fieldsValue.id)
        formData.append('file_name', file.name)
        setUploading(true)
        dispatch(uploadCategoryPolicyFile(formData))
        .then((res)=>{
            setFieldsValue(prev=>(
                {
                    ...prev,
                    files: [...prev.files, {name:file.name, size:file.size, id:res.data.id, file_upload_time:file.file_upload_time, edit:false}]
                }
            ))
            setUploading(false)
        })
        .catch(err=>{
            setUploading(false)
            console.error(err)
        })
    }

    const onDeleteFile = (file_id) => {
        if(!window.confirm('Delete file?')) {
            return
        }
        dispatch(deleteCategoryPolicyFile(file_id))
        .then(()=>{
            refreshFiles()
        })
        .catch((err)=>{console.error(err)})
    }

    const onDownloadFile = (file_id) => {
        dispatch(getCategoryPolicyFileContent(file_id))
        .then((resp)=>{
            const byteCharacters = atob(resp.data.file_content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray]);
            const href = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.download = resp.data.file_name;
            a.href = href;
            a.click();
            a.remove();
        })
        .catch((err)=>{console.error(err)}) 
    }

    const onEditButtonChange = (info, newName, index) => {
        if(info.edit) {
            let payload = {
                category: selectedData?.id,
                file_name: newName
            }
            dispatch(updateCategoryPolicyFileName(info.id, payload))
            .then(()=>{
                emitToastNotification('success', 'Successfully Updated Name')
                refreshFiles()
            })
            .catch((err)=>{console.error(err)})
        }
        else {
            setFieldsValue(prev=> {
                prev.files[index].edit = !info.edit
                return (
                    {
                        ...prev,
                        files: [...prev.files]
                    }
                )
            })
        }
    }

    const refreshFiles = () => {
        dispatch(getCategoryPolicyFiles(fieldsValue.id))
        .then(res=>{
            setFieldsValue(prev=>(
                {
                    ...prev,
                    files: res.data.map((v=>({name:v.file_name, size:v.file_size, id:v.id, file_upload_time:v.file_upload_time, edit:false})))
                }
            ))
        })
        .catch(()=>{})
    }

    return (
        <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true} onClosed={()=>{setOpenModal(false); setViewOnly(false); setIsEdit(false); setFieldsValue({...initialFieldValue}); setCreated(false)}}>
            <ModalHeader className='d-flex align-items-center' close={<Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType:Literals.BTN_TERTIARY}} />}>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className='d-flex align-items-center'>
                        <CATEGORY height='18' width='18' />
                        <h2 className="section-heading">
                            {
                               viewOnly?"View Data Category":
                                isEdit
                                ?
                                'Edit Data Category'
                                :
                                'Create New Data Category'
                            }
                        </h2>
                    </div>
                </div>                  
            </ModalHeader>
            <ModalBody>
                <div className=''>
                    <div className='row cat-modal-set' style={{margin: '0 -10px'}}>
                        <div className='col-6 mb-2'>
                            <label className='font-w-600 grey-color label'>Category Name</label>
                            <div>
                                <input
                                    required
                                    placeholder=''
                                    type="text"
                                    value={fieldsValue.name}
                                    onChange={(e) => {
                                        if(preventSpecialCharsRegex.test(e.target.value) || e.target.value === '') {
                                            handleFields(e.target.value, 'name') 
                                        }
                                    }}
                                    className={`custom-input-field`}
                                    disabled={viewOnly?true: false}
                                />
                            </div>
                        </div>
                        <div className='col-6 mb-2'>
                            <label className='font-w-600 grey-color label'>Description</label>
                            <div>
                                <input
                                    required
                                    placeholder=''
                                    type="text"
                                    value={fieldsValue.description}
                                    onChange={(e) => handleFields(e.target.value, 'description')}
                                    className={`custom-input-field`}
                                    disabled={viewOnly?true: false}
                                />
                            </div>
                        </div>
                        <div className='col-6 mb-2'>
                            <label className='font-w-600 grey-color label'>Data Owner</label>
                            <div>
                                <MultiSelection
                                    props={{
                                        placeholder: "Select Data Owners",
                                        dropDownValue: fieldsValue.owners,
                                        label:"",
                                        onClickEvent: (value) => handleFields(value, 'owners'),
                                        isDisable: viewOnly ?? false,
                                        selectClassName: 'w-100',
                                        optionList: usersList?.map(o => ({value: o.Value, Name: `${o.Name} (${o.Email})`}))
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-6 mb-2'>
                            <label className='font-w-600 grey-color label'>Data Stewards</label>
                            <div>
                                <MultiSelection
                                    props={{
                                        placeholder: "Select Data Stewards",
                                        dropDownValue: fieldsValue.stewards,
                                        label:"",
                                        onClickEvent: (value) => handleFields(value, 'stewards'),
                                        isDisable: viewOnly ?? false,
                                        selectClassName: 'w-100',
                                        optionList: usersList?.map(o => ({value: o.Value, Name: `${o.Name} (${o.Email})`}))
                                    }}
                                />
                            </div>
                        </div>
                        {
                            isEdit
                            ?
                            <>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Created by</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={selectedData?.created_by_user?.username}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Created on</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={`${getRelativeTime(selectedData?.created_on)}, ${getTime(selectedData?.created_on)}`}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Updated by</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={selectedData?.updated_by_user?.username}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Updated on</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={`${getRelativeTime(selectedData?.updated_on)} , ${getTime(selectedData?.updated_on)}`}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </>
                            :   ''
                        }
                    </div>
                    {
                        fieldsValue.id && fieldsValue.id !== null
                        ?
                        <>
                            <div className='d-flex mt-2 justify-content-between align-items-center'>
                                <label className='font-w-600 grey-color label mb-0'>Supporting Documents</label>
                                
                              {viewOnly?"":  <label style={{opacity: uploading ? '.5' : '1'}} className='custom-btn-primary custom-btn btn-with-text'>
                                    <UPLOAD />
                                    Upload
                                    <input 
                                        type={'file'}
                                        className='d-none'
                                        accept=".csv,.parquet,.txt"
                                        multiple={false}
                                        onChange={(e)=>handleUpload(e.target.files)}
                                        disabled={uploading}
                                    />
                                </label>}
                            </div>
                            <hr className='mt-2 mx-n2'/>
                            {
                                uploading
                                ?
                                <div className='align-items-center d-flex justify-content-center mb-3 text-black-50 w-100'>
                                    <i className='bx bx-loader-alt bx-spin mr-1'></i>
                                    please wait uploading...
                                </div>
                                : ''
                            }
                            <div className='d-flex flex-wrap' style={{gap:10, opacity: uploading ? '.5' : '1'}}>
                                {
                                    fieldsValue?.files?.length>0
                                    ?
                                    fieldsValue.files.map((file,index)=>(
                                        <FileExplorerTileView
                                            key={`pol-file-${index}`}
                                            info={file}
                                            onDelete={onDeleteFile}
                                            onDownload={onDownloadFile}
                                            onEditButtonChange={onEditButtonChange}
                                            index={index}
                                        />
                                    ))
                                    :
                                    <div className='text-center text-black-50 w-100'>No Files Found</div>
                                }
                            </div>
                        </>
                        :
                        ''
                    }
                    
                </div>
            </ModalBody>
            {
                !created
                ?
                <ModalFooter>
                  {viewOnly?"":  <Buttons props={{ 
                            buttonText: submitButton.getText(), 
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text", 
                            buttonEvent: () => submitButton.onClick(), 
                            ImgSrc: () => submitButton.getIcon(), 
                            isDisable: submitButton.getDisableStatus(),
                            buttonType:Literals.BTN_PRIMARY_WITH_TEXT
                        }} 
                    />}
                </ModalFooter>
                :   ''
            }
        </Modal>
    )
}

export default DataCategoryModal