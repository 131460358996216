import {
  RESET_DASHBOARD_REDUX_STATE,
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_LAYOUTS,
  SET_DASHBOARD_BREAKPOINT,
  SET_DASHBOARD_TABLE_DATA,
  SET_ANALYSIS_DATA,
  DATA_DOMAIN_LIST,
  SET_FULL_SCREEN,
  SET_DASHBOARDLOADING_STATUS,
  EDIT_DASHBOARD_ITEMS,
  SET_DASHBOARD_STATE,
  SET_DASHBOARD_REFRESHDATA,
  SET_DASHBOARD_DEFAULT,
  SETS_ACTIVE_KEY,
  SET_OPEN_ANALYSIS,
  SET_DASHBOARD_DEFAULTWITHANALYSIS,
  SET_VIEW,
  SET_SELECTED_ANALYSIS,
  SET_ANALYSIS_FILTERS_DATA,
  SET_ANALYSIS_GRAPHS_DATA,
  SET_ANALYSIS_FILTER_GRAPHS_DATA,
  SET_DYNAMIC_FILTERS,
  SET_APPLIED_FILTER_NAME,
  SET_FILTERS_COUNT,
  RESET_ANALYSIS_FILTERS_DATA,
  SET_SELECTED_FILTER_VALUES,
  SET_ANALYSIS_FILTERS_LIST,
  SET_INITIAL_EDIT_FILTERS,
} from "./dashboardTypes";
import { setLoadingStatus } from "../../common/loadingActions";
import { fetchData } from "../../../../services/apiService";
import "toastr/build/toastr.min.css";
import { convertDOMElementToImage } from "../../../../components/modules/common/helperFunctions";
import { emitToastNotification } from "../../../../helpers/toast_helper";

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;
var VISUALIZATION_BASE_URL = window._env_.REACT_APP_VISUALIZATION_BASE_URL;

export const resetDashboardReduxState = () => {
  return {
    type: RESET_DASHBOARD_REDUX_STATE,
  };
};

export const setDashboardData = (data) => {
  return {
    type: SET_DASHBOARD_DATA,
    payload: data,
  };
};
export const setGraphDynamicFilters = (data) => {
  return {
    type: SET_DYNAMIC_FILTERS,
    payload: data,
  };
};

export const setAppliedFilterName = (data) => {
  return {
    type: SET_APPLIED_FILTER_NAME,
    payload: data,
  };
};

export const setDashboardSelectedAnalysis = (data) => {
  return {
    type: SET_SELECTED_ANALYSIS,
    payload: data,
  };
};

export const setDashboardLayouts = (layouts) => {
  return {
    type: SET_DASHBOARD_LAYOUTS,
    payload: layouts,
  };
};

export const setDashboardBreakpoint = (breakpoint) => {
  return {
    type: SET_DASHBOARD_BREAKPOINT,
    payload: breakpoint,
  };
};

export const fetchAnalyticsDashboardDetails = ({
  method,
  endPoint,
  payload,
}) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(method, `${BASE_URL}${endPoint}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const setDashboardTableData = (data) => ({
  type: SET_DASHBOARD_TABLE_DATA,
  payload: data,
});

export const setAnalysisData = (data) => {
  return {
    type: SET_ANALYSIS_DATA,
    payload: data,
  };
};

export const setDataDomainList = (list) => ({
  type: DATA_DOMAIN_LIST,
  payload: list,
});

export const setFullScreen = (data) => ({
  type: SET_FULL_SCREEN,
  payload: data,
});

export const createDashboardApi = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise(async (resolve, reject) => {
      const thumbnail = await convertDOMElementToImage(
        ".react-grid-layout",
        "visible"
      );
      dispatch(
        fetchData("POST", `${BASE_URL}dashboard/ `, { ...payload })
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));

          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            dispatch(resetAnalysisFiltersData());
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const bookmarkDashboard = (type, reportId) => {

  return (dispatch) => {
      // dispatch(setLoadingStatus(true));

      return new Promise((resolve, reject) => {

          dispatch(
              fetchData(type, `${BASE_URL}dashboard/${reportId}/bookmark/`)
          )
              .then((resp) => {
                  dispatch(setLoadingStatus(false));
                  if (resp.status !== 'success') {

                      reject(resp.message)
                  } else {

                      emitToastNotification('success',resp.message)

                      resolve(resp);
                  }
              })
              .catch((err) => {
                  dispatch(setLoadingStatus(false));
                  emitToastNotification('error', err?.message);
                  reject(err);
              })
      });
  };
};

export const setDashboardLoadingStatus = (data) => {
  return {
    type: SET_DASHBOARDLOADING_STATUS,
    payload: data,
  };
};

export const getDashboardListApi = (domain_id) => {
  return (dispatch) => {
    dispatch(setAnalysisFiltersData({}));
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}dashboard/?data_domain_id=${domain_id}`))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message);

          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const getDashboardDetails = (dashboard_id, showToast = false) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}dashboard/${dashboard_id}/`))
        .then((resp) => {
          if (resp.status === false) {
            reject(resp.message);
            if(showToast) {
              emitToastNotification('error', resp.message);
            }
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if(showToast) {
            emitToastNotification('error', err.message);
          }
          reject(err)
        });
    });
  };
};

export const createDashboardListApi = (payload) => {
  return (dispatch) => {
    dispatch(setAnalysisFiltersData({}));
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("post", `${BASE_URL}dashboard/`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message);

          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const deleteDashboardApi = (id) => {
  return (dispatch) => {
    dispatch(setAnalysisFiltersData({}));
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${BASE_URL}dashboard/${id}/`))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message);

          reject(err)
          dispatch(setLoadingStatus(false));
        });
    });
  };
};


export const editDashboardItems = (data) => {
  return {
    type: EDIT_DASHBOARD_ITEMS,
    payload: data,
  };
};

export const setDashboardState = (data) => {
  return {
    type: SET_DASHBOARD_STATE,
    payload: data,
  };
};

export const editDashboardApi = (dashboard_id, payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise(async (resolve, reject) => {
      dispatch(
        fetchData("PATCH", `${VISUALIZATION_BASE_URL}superset/dashboard/${dashboard_id}/?edit=true`, {
          ...payload,

        })
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const dashboardChartPreviewApi = (payload) => {
  return (dispatch) => {
    dispatch(setDashboardLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}chart/chart_preview/`, payload))
        .then((resp) => {
          dispatch(setDashboardLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setDashboardLoadingStatus(false));
        });
    });
  };
};

export const getDashboardPreviewTable = (payload, isSearchPaylaod) => {
  return (dispatch) => {
    dispatch(setDashboardLoadingStatus(false));

    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}preview`, payload))
        .then((resp) => {
          dispatch(setDashboardLoadingStatus(false));
          if (resp.status !== "success") {
            emitToastNotification('error', resp.message);
            reject(resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setDashboardLoadingStatus(false));

          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const assignDashboardTag = (payload, dashboardId, showToast = true) => {
  return (dispatch) => {
      return new Promise((resolve, reject) => {
          dispatch(
              fetchData('POST', `${BASE_URL}dashboard/${dashboardId}/tag/`, payload)
          )
              .then((resp) => {
                  if (showToast) emitToastNotification("success", resp.message);

                  resolve(resp);
              })
              .catch((err) => {
                  if (showToast) emitToastNotification("error", err.message);

                  reject(err);
              });
      });
  };
};

export const unassignDashboardTag = (tagId, dashboardId, showToast = true) => {
  return (dispatch) => {
      return new Promise((resolve, reject) => {
          dispatch(
              fetchData('DELETE', `${BASE_URL}dashboard/${dashboardId}/tag/?tag=${tagId}`)
          )
              .then((resp) => {
                  if (showToast) emitToastNotification("success", resp.message);

                  resolve(resp);
              })
              .catch((err) => {
                  if (showToast) emitToastNotification("error", err.message);

                  reject(err);
              });
      });
  };
};

export const setDashboardRefreshdata = (data) => {
  return {
    type: SET_DASHBOARD_REFRESHDATA,
    payload: data,
  };
};

export const setDashboardDefault = (data) => {
  return {
    type: SET_DASHBOARD_DEFAULT,
    payload: data,
  };
};

export const setsActiveKey = (key) => ({
  type: SETS_ACTIVE_KEY,
  payload: key,
});

export const setOpenAnalysis = (data) => ({
  type: SET_OPEN_ANALYSIS,
  payload: data,
});

export const setDashboardDefaultWithAnalysis = () => ({
  type: SET_DASHBOARD_DEFAULTWITHANALYSIS,
});

export const setView = (data) => ({
  type: SET_VIEW,
  payload: data,
});

export const setAnalysisFiltersData = (payload) => ({
  type: SET_ANALYSIS_FILTERS_DATA,
  payload,
});

export const setAnalysisGraphData = (payload) => ({
  type: SET_ANALYSIS_GRAPHS_DATA,
  payload,
});

export const setAnalysisFilterGraphData = (payload) => ({
  type: SET_ANALYSIS_FILTER_GRAPHS_DATA,
  payload,
});

export const setFiltersCount = (payload) => ({
  type: SET_FILTERS_COUNT,
  payload,
});

export const resetAnalysisFiltersData = () => {
  return {
    type: RESET_ANALYSIS_FILTERS_DATA,
  };
};

export const setSelectedFilterValues = (payload) => {
  return {
    type: SET_SELECTED_FILTER_VALUES,
    payload,
  };
};

export const setAnalysisFiltersList = (payload) => {
  return {
    type: SET_ANALYSIS_FILTERS_LIST,
    payload,
  };
};

export const setInitialEditFilters = (payload) => {
  return {
    type: SET_INITIAL_EDIT_FILTERS,
    payload,
  };
};
