import { Dropdown, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector, useStore } from 'react-redux';

import { Card, CardBody } from "reactstrap";
import { getLandingPageData, openModel, removePOD } from '../../../../store/modules/dataCatalogue/landingPage/landingPageActions.js';
import { Literals } from "../../common/literals";

import Highlighter from "react-highlight-words";
import "toastr/build/toastr.min.css";
import { setLoadingStatus } from "../../../../store/modules/common/loadingActions";
import Buttons from "../../../Common/button/Buttons";
import { CREATED_ON, DELETEICON, EDIT, SMALLDATADOMAIN, SMALLGROUP, SMALLUSERS } from "../../../Common/iconSource";
import {  getRelativeTime, permissions } from "../../common/helperFunctions";
import { CreateRuleContext } from "../../common/Rules-context";

const UserManagementCard = ({ domainDetails,searchData,isUserGroup, selectedDomainList, clickEdit, clickDelete, icon, tabName, redirectTo }) => {

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
  const dispatch = useDispatch()
  const tagList = useSelector(state => state.DataCatalogue?.LandingPage?.tagList);
  const context = useContext(CreateRuleContext)
  const store = useStore()
  const [showPopUp, setShowPopUp] = useState(false)
  const [optionOpen, setOptionOpen] = useState(false)

  const isSuperUser = useSelector(state => state.UserReducer.user.userDetails.is_superuser)

  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };
  const deletePOD = () => {
    setOpenDeletePopup(false);
    try {
      let requestBody = {
        "action": "delete_pod",
        "table_name": domainDetails[1],
        "data_domain_id": domainDetails[2],
        "data_domain_name": domainDetails[3]
      }
      dispatch(removePOD(requestBody)).then(
        res => {
          if (res.status === 'success') {
            try {
              dispatch(getLandingPageData()).then((resp) => {
                dispatch(setLoadingStatus(false))
              })
            }
            catch (e) {

            }

          }
        }
      ).catch(err => err

      )
    }
    catch (e) {

    }
  };

  const openDeleteDialog = () => {

    setDeletePopUpContent({
      modalClass: "delete-popup",
      heading: "Delete",
      message: "This action will delete POD permanently.",
      button: [
        {
          name: "Delete",
          action: (e) => deletePOD(e),
        }
      ],
      elements: [],
      closeActionPopup: closeDeletePopUp,
    });
    setOpenDeletePopup(true);
  };

  const openAddTag = () => {
    dispatch(openModel('tag'))
  }



  useEffect(() => {
    if (showPopUp) {
     
    }
  }, [showPopUp, domainDetails, tagList])


  function renderDataDomainCount() {
    if (tabName === "DataDomain") {
      return null
    }
    return (


      <section className="list-section">
        <div className="list-column-first"><SMALLDATADOMAIN /></div>
        <div className="list-column-second"><span className="label">{Literals.DATA_DOMAIN}</span></div>
        <div className="list-column">
          <Tooltip placement="top" title={domainDetails.domainCount}>
            <span className="paragraph">
            {domainDetails.domainCount}
              {/* < Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={domainDetails?.domainCount?.toString()} /> */}
            </span>
          </Tooltip>
        </div>
      </section>
    )
  }


  function renderUserGroupCount() {
    if (tabName === "Usergroup") {
      return null
    }
    return (
      <section className="list-section">
        <div className="list-column-first"><SMALLGROUP /></div>
        <div className="list-column-second"><span className="label">{Literals.USERGROUP}</span></div>
        <div className="list-column">
          <Tooltip placement="top" title={domainDetails.userGroups}>
            <span className="paragraph">{domainDetails.userGroups}</span>
          </Tooltip>
        </div>
      </section>

    )
  }

  const handleEditDomainDisable = () => {
    if(tabName?.toLocaleLowerCase() === 'usergroup') {
      if(isSuperUser) return false
      return !(!!domainDetails?.data?.permission?.find(v => v === permissions.usergroup.leader))
    }
    if(isSuperUser || domainDetails?.isOwner) return false
    return true
  }

  const handleEditDeleteDisable = (type = 'delete') => {
    if(tabName?.toLocaleLowerCase() === 'usergroup') {
      if(type === 'delete') return !isSuperUser
    }
    else {
      if(isSuperUser && domainDetails?.isOwner) return false
      return true 
    }
  }

  const optionList = [
    {
      label: <span className={`dbox-opt-itm ${handleEditDomainDisable() ? 'disabled' : ''}`}
                  onClick={(e) => { 
                    e.stopPropagation();
                    e.preventDefault();
                    if(handleEditDomainDisable()) return
                    clickEdit(domainDetails)
                    }}>
                  <EDIT />
                  Edit
                </span>,
      key: '0',
      disabled: handleEditDomainDisable()
    },
    {
      label: <span className={`dbox-opt-itm danger ${handleEditDeleteDisable('delete') ? 'disabled' : ''}`} 
          onClick={(e) => { 
            e.stopPropagation(); 
            e.preventDefault();
            if(handleEditDeleteDisable('delete')) return
            clickDelete(domainDetails)
          }}>
        <DELETEICON />
        Delete
      </span>,
      key: '1',
      disabled: handleEditDeleteDisable('delete')
    },
  ]

  return (
    <div key={domainDetails.key} className="h-100 position-relative">
      <Card onClick={(e) => { e.preventDefault(); e.stopPropagation(); redirectTo(domainDetails.key) }}>
        <CardBody>
        <div className='data-view-dbox'>
            <div className='_src_img'>
              <div className="usr-icon-box">
                {icon}
              </div>
            </div>
            <div className='desc-box'>
                <div className="d-flex align-items-start">
                    <Tooltip placement='topLeft' title={tabName === "DataDomain" ? domainDetails.dataDomain : domainDetails.userGroupName}>
                        <label style={{width: 'calc(100% - 3.25rem)'}} className='small mt-1 mb-1 title-txt font-w-600 text-uppercase text-with-ellipsis'>
                          {tabName === "DataDomain" ?< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight= {domainDetails.dataDomain?domainDetails.dataDomain:''} />   :< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight= { domainDetails.userGroupName? domainDetails.userGroupName:''} />}
                        </label>
                    </Tooltip>
                    <Dropdown
                      menu={{
                        items: optionList,
                        onClick: (e) => e.domEvent.stopPropagation()
                      }}
                      trigger={[ "hover" ]}
                      placement="bottomRight"
                      onOpenChange={(bool) => setOptionOpen(bool)}
                      overlayStyle={{border:'solid 1px #e4e4e4'}}
                    >
                          <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                    </Dropdown>
                </div>
                <div>
                    <Tooltip placement='topLeft' title={domainDetails.description}>
                    <p style={{minHeight: 'auto'}} className='mb-1 paragraph text-black-50 small desc-txt text-with-ellipsis'>
                        {domainDetails.description?.length>0? 
                        <Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={domainDetails?.description??''}>{domainDetails.description}</Highlighter>
                        :"No description has been given yet."}
                    </p>
                    </Tooltip>
                </div>
                {!isUserGroup?renderUserGroupCount():null}
                {renderDataDomainCount()}
                {!isUserGroup?  <section className="list-section">
                    <div className="list-column-first"><SMALLUSERS /></div>
                    <div className="list-column-second"><span className="label">{Literals.USERS}</span></div>
                    <div className="list-column">
                      <Tooltip placement="top" title={domainDetails.users}>
                        <span className="paragraph">{domainDetails.users}</span>
                      </Tooltip>
                    </div>
                  </section>:null}
                  <section className="list-section">
                    <div className="list-column-first"><span className="smallUser"><CREATED_ON /></span></div>
                    <div className="list-column-second"><span className="label">{Literals.UPDATED_ON}</span></div>
                    <div className="list-column">
                      <Tooltip placement="top" title={getRelativeTime(domainDetails.updated_on)}>
                        <span className="paragraph"> 

                        {/* {getRelativeTime(domainDetails.updated_on)} */}
                        {/* {getRelativeTime(domainDetails.updated_on)?getRelativeTime(domainDetails.updated_on)?.toLocaleString():''} */}
                        < Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight= {getRelativeTime(domainDetails.updated_on)?getRelativeTime(domainDetails.updated_on)?.toLocaleString():''} /> 
                        </span>
                      </Tooltip>
                    </div>
                  </section>
                  <section className="list-section">
                    <div className="list-column-first"><SMALLUSERS /></div>
                    <div className="list-column-second"><span className="label">{Literals.CREATED_BY}</span></div>
                    <div className="list-column">
                      <Tooltip placement="top" title={domainDetails.createdBy}>
                        <span className="paragraph d-block text-with-ellipsis">
                          {/* {domainDetails.createdBy?domainDetails.createdBy?.toLocaleString  ():''} */}
                          < Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight= {domainDetails.createdBy?domainDetails.createdBy?.toLocaleString  ():''} />
                        </span>
                      </Tooltip>
                    </div>
                  </section>
            </div>
        </div>
        </CardBody>
      </Card>
    </div>
  );
};
export default UserManagementCard;