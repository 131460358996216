import React, { useContext, useEffect } from 'react'
import ConnectionList from '../../../connections/landingPage/components/ConnectionsList'
import ConnectionContext from '../../../connections/landingPage/components/ConnectionContext';

function ConnectionSelectStep1({ searchValue, isExecutePermission }) {

  const { setFromConnectionRoute } = useContext(ConnectionContext);
  useEffect(() => {
    setFromConnectionRoute(true);
    return () => {
      setFromConnectionRoute(false);
    };
  }, []);

  return (
    <div className="connection-modal"> <ConnectionList
      searchValue={searchValue}
      selectedFilters={[]}
      filterList={[]}
      layout="grid"
      fromModal={true}
      isChangeModal={false}
      disableUnselected={!isExecutePermission ? true : false}
      setChangedConn={() => { }}
      fromModalRoute={'target_transformer'}
    />
    </div>
  )
}

export default ConnectionSelectStep1