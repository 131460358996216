import { Space, Spin, Tooltip } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import {
  resetAllDataGridTableFilters,
  setActiveTabKey,
  setCurrentTableRowCount,
  setFilteringColumns,
  setHidingColumns,
  setPreviewFlag,
  setQueryKillFlag,
  setResponseQuery,
  setRowCountByUUID,
  setSortingColumns,
} from "../../../../../store/modules/common/DataGridTable/dataGridTableRedux";
import {
  getPreviewTable,
  resetPreviewNextPageCounter,
  resetPreviewTableRecords,
  setPreviewDataMap,
  setPreviewTableData,
  setPreviewTableFilters,
  setPreviewTableFlag,
  setPreviewTableQuery,
  setPreviewTableSort,
  setQueryError,
} from "../../../../../store/modules/dataCatalogue/preview/previewActions";
import DataGridTable from "../../../../Common/dataGridTable/DataGridTable";
import DataTabs from "../../../../Common/dataTabs/DataTabs";
import { CLOSE, REPORT_ICON, SQLLARGE } from "../../../../Common/iconSource";
import { CustomSkeleton } from "../../../../Common/skeleton/CustomSkeleton";
import { ErrorComponent, QueryKillError, getConfirmationModal, getObjectLength, getSimpleTutorialMessageTemplate, convertBase64ToString, getReloadErrorTemplate } from "../../../common/helperFunctions";
// import { ErrorComponent, QueryKillError, convertBase64ToString, getConfirmationModal, getObjectLength } from "../../../common/helperFunctions";
import PodMetaHeader from "../../summary/components/PodMetaHeader";
import SplitPaneView from "./SplitPaneView";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Buttons from "../../../../Common/button/Buttons";
import { Literals } from "../../../common/literals";
import PreviewSearchBody from "./previewSearch/PreviewSearchBody";
import ClaristaLoader from "../../../../Common/claristaLoader/ClaristaLoader";
import Walkthrough from "../../../../Common/walkthrough/Walkthrough";
import { setNavigatorTutorialStart } from "../../../../../store/modules/common/tutorialRedux";
import { STATUS } from "react-joyride";
import { getUploadCatalog } from "../../../../../store/actions";

const PreviewPageLayout = forwardRef(
  (
    {
      dictSchemaData,
      isDeletePermission,
      isEditPermission,
      isPreviewGrid,
      setIsGridLoaded,
      isDataGridReady = true,
      gridHeight,
      isTutorialActive = false,
      isViewPermission = false, 
      showPage = false,
      props
    },
    ref
  ) => {
    const podDetails = useSelector(
      (state) => state.DataCatalogue.Dictionary.dictionaryPodData
    );
    const searchMode = useSelector(
      (state) => state.DataCatalogue.PreviewPage.searchMode
    );

    const store = useStore();
    const { previewDataMap } = store.getState().DataCatalogue.PreviewPage;
    let previewFlag;

    let appliedSortArray = useRef();
    appliedSortArray.current = useSelector(
      (state) => state.DataCatalogue?.PreviewPage?.sort
    );
    let searchKeyword = useRef("");

    searchKeyword.current = useSelector(
      (state) => state.DataCatalogue?.PreviewPage?.searchKeyword
    );
    let sqlEditorQuery = useRef("");
    sqlEditorQuery.current = useSelector(
      (state) => state.DataCatalogue?.PreviewPage?.sqlEditorQuery
    );

    let executedQuery = useRef("");
    executedQuery.current = useSelector(
      (state) => state.DataCatalogue?.PreviewPage?.query
    );
    const expandDictTable = useSelector(
      (state) => state.DataCatalogue.Dictionary.expandDictionaryTable
    );
    const previewTableResponse = useSelector(
      (state) => state.DataCatalogue?.PreviewPage?.previewPageData
    );

    const isApiError = useSelector(
      (state) => state.DataCatalogue?.PreviewPage?.isApiError
    );
    const errorQ = useSelector(state => state.DataCatalogue.PreviewPage.queryErrorDetail);

    const dispatch = useDispatch();
    const [dataGridTableResponse, setDataGridTableResponse] = useState({});
    const [resDataGridTableResponse, setResDataGridTableResponse] = useState({});
    const [initialRecords, setInitialRecords] = useState({});
    const [initialRecordsCount, setInitialRecordsCount] = useState();
    const [showLoader, setshowLoader] = useState(false);
    const [isPreviewAlreadyLoading, setPreviewAlreadyLoading] = useState(false);
    const [, setrecheckStatus] = useState(false);
    const [sqlTableDetails, setsqlTableDetails] = useState([]);
    const [previewTableResult, setpreviewTableResult] = useState({});
    const [, setactiveSortColumnType] = useState("");
    const [resetQuery, setresetQuery] = useState(false);
    const [uniqUUID, setUniqUUID] = useState(previewTableResponse?.uuid);
    const [resultUniqUUID, setResultUniqUUID] = useState(uuidv4());
    const [activeTab, setActiveTab] = useState("0");
    const [defaultTab] = useState("0");
    const [isLoadingRunning, setLoadMoreRunning] = useState(false);
    const [queryModalDetail, setQueryModalDetail] = useState({}); // TO RESET MODAL NAME AND DESCRIPTION VALUE STATE PRESENT IN <SplitPaneView/>
    const [sourceDetails, setSourceDetails] = useState(undefined); // Used For File System Unstructured Only
    const [storedData, setStoredData] = React.useState({})
    const [storedFilter, setStoredFilter] = React.useState({})
    const [resultTabStoredData, setResultTabStoredData] = React.useState({})
    const [resultTabStoredFilter, setResultTabStoredFilter] = React.useState({})
    const [pageNo, setPageNo] = React.useState(0)
    const [resultPageNo, setResultPageNo] = React.useState(0)
    const [downloadQuery, setDownloadQuery] = React.useState('')
    const [resDownloadQuery, setResDownloadQuery] = React.useState('')
    const [initialQuery, setInitialQuery] = React.useState('')
    const [splitSize, setSplitSize] = React.useState(['100%', '0%'])

    const [tabsList, setTabsList] = useState([
      {
        podId: "0",
        key: "0",
        tabTitle: "All Records",
        tabContent: null,
        closable: false,
      },
    ]);

    const [columnsRegex, setColumnsRegex] = useState([]);
    const [isReady, setReady] = useState(true);
    const [isResReady, setResReady] = useState(true);
    const [isFetchingRecords, setFetchingRecords] = useState(true);
    const [openResetPopup, setOpenResetPopup] = useState(false);
    const [isQueryKill, setisQueryKill] = useState(false)
    const [previewError, setPreviewError] = useState(undefined)
    const [previewErrorPayload, setPreviewErrorPayload] = useState({})
    // let isQueryKill = store.getState().CommonComponent?.DataGridTable?.isQueryKill;

    const { default_param } = podDetails

    const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false



    /* React.useEffect(() => {
      if(podDetails?.is_unstructured && !sourceDetails) {
        dispatch(getUploadCatalog(false, false))
        .then(res => {
          setSourceDetails(res?.data)
        })
        .catch(() => {})
      }
    }, [podDetails, sourceDetails]) */

    useEffect(() => {
      const podData = store?.getState().DataCatalogue.Dictionary.dictionaryPodData

      if (podData && podData?.table_name) {
        let uuid = uuidv4();
        setUniqUUID(uuid);
        let payload = createRequestBody(false, uuid);

        if (window?.history?.state?.col_id) {
          payload['fromProgressBar'] = true
          payload['health_column'] = [window?.history?.state?.col_name]
          payload['is_healthy'] = window?.history?.state?.healthy
        }

        // if(default_param && getObjectLength(default_param) > 0) {
        //   payload['params'] = default_param
        // }

        try {

          if (isPreviewGrid) {
            store.dispatch(setPreviewTableData([]));
            setpreviewTableResult({});
            setDataGridTableResponse({})
            return
          }
          getPreviewTableResult(payload, false, true);

        }
        catch (e) {
          console.log(e);
        }
        setPreviewAlreadyLoading(true);
        setColumnsRegex([...podData?.column_detail]);
      }
      else {
        setFetchingRecords(false)
      }

      return () => {
        if (isPreviewGrid) return  // To Prevent All Records Data get Empty when coming back from GPT Search Module


        store.dispatch(setPreviewDataMap({}));
      };
    }, []);

    useEffect(() => {
      if (initialRecords && initialRecords?.uuid) {
        // console.log({i:initialRecords?.uuid, s:store.getState().CommonComponent?.DataGridTable?.uuid})
        if (
          initialRecords?.uuid ===
          store.getState().CommonComponent?.DataGridTable?.uuid
        ) {

          setInitialRecordsCount(
            store?.getState()?.CommonComponent?.DataGridTable?.rowCount
          );
        }
      }
    }, [
      initialRecords,
      store?.getState()?.CommonComponent?.DataGridTable?.rowCount,
      store.getState().CommonComponent?.DataGridTable?.uuid,
    ]);

    // useEffect(() => {
    //     setInitialRecordsCount(store?.getState()?.CommonComponent?.DataGridTable?.rowCount)
    // }, [initialRecords])



    const [walkthroughSteps, setWalkThroughSteps] = React.useState(
      [
        {
          content: getSimpleTutorialMessageTemplate(
            'Welcome To, Preview',
            "Here you will able to see all records of the POD"
          ),
          locale: { skip: <strong>SKIP</strong> },
          placement: "center",
          target: "body"
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Column',
              'Table header shows all information of the columns including Dictionary details and Health with filtering based on health, click on the colored dot at the bottom to filter on healthy and missing'
            )}
          </>,
          placement: "bottom",
          target: '#prev-col-0',
          title: ""
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Hide Fields',
              'You can hide columns as per your need'
            )}
          </>,
          placement: "right",
          target: '#prevHideFieldBtn',
          title: "",
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Filter',
              'You can filter on over all records'
            )}
          </>,
          placement: "bottom",
          target: '#prevFilterBtn',
          title: "",
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Sort',
              "You can also sort on overall records"
            )}
          </>,
          placement: "bottom",
          target: '#prevSortBtn',
          title: "",
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Parameters',
              'Get results based on Parameters you created')}
          </>,
          placement: "bottom",
          target: '#prevParamBtn',
          title: ""
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Row Count',
              'Shows the actual row count of the pod')}
          </>,
          placement: "bottom",
          target: '#prevRowCount',
          title: ""
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Column Count',
              'Shows the actual column count of the pod')}
          </>,
          placement: "bottom",
          target: '#prevColCount',
          title: ""
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Download',
              'Download data which is loaded')}
          </>,
          placement: "bottom",
          target: '#prevDownloadBtn',
          title: ""
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Expand',
              'Expand based on your choice for full screen view')}
          </>,
          placement: "left",
          target: '#prevExpandBtn',
          title: ""
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('SQL',
              'Explore more in the SQL Editor')}
          </>,
          placement: "top",
          target: '#prevSqlBtn',
          title: ""
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Play With Chat GPT',
              'You can use Chat GPT also for getting data as per your need'
            )}
          </>,
          placement: "bottom",
          target: '#gptBtn',
          title: "",
          locale: { last: "Got it" }
        },
        {
          content: <>
            {getSimpleTutorialMessageTemplate('Charts',
              "Let's move on to Charts, click on this"
            )}
          </>,
          placement: "bottom",
          target: '#tb-of-Charts-2',
          title: "",
          locale: { last: "Yes" }
        },
      ]
    )

    const [walkthrough, setWalkThrough] = React.useState({
      run: false,
      steps: [...walkthroughSteps]
    })

    const [spotClick, setSpotClick] = React.useState(false)

    const walkthroughCallback = (d) => {
      if (d?.status === 'running') {
        if (d?.index === d?.size - 1) {
          if (spotClick) return
          setSpotClick(true)
        }
        else {
          if (!spotClick) return
          setSpotClick(false)
        }
      }
      else if (d?.status === STATUS.SKIPPED) {
        if (!isTutorialActive) return
        dispatch(setNavigatorTutorialStart('landing_page', false))
      }
    }

    // useEffect(() => {
    //   startWalkthrough(isTutorialActive)
    // }, [isTutorialActive])

    const startWalkthrough = (bool) => {
      setWalkThrough({ steps: walkthroughSteps, run: bool })
    }

    useEffect(() => {
      if (isApiError) {
        setshowLoader(false);
      }
    }, [isApiError]);

    useEffect(() => {
      if (!searchMode) {
        dispatch(
          setPreviewDataMap({
            preview_query: previewDataMap["preview_query"],
            execute_query: null,
          })
        );
      }
      else {
        setshowSplitView(false)
      }
    }, [searchMode])

    useImperativeHandle(ref, () => ({
      removeTabFromTabsListParent() {
        removeTabFromTabsList("1");
      },
      onChangeTabParent() {
        onChangeTab("0")

      },
      executeQueryApiParent() {
        let newUUID = uuidv4()
        setUniqUUID(newUUID)
        setTimeout(() => {
          ExecuteQueryApi(undefined, newUUID);
        }, 1000)
      },
    }));

    useEffect(() => {
      if (
        getObjectLength(previewTableResult) > 0 &&
        previewTableResult.result.data_header?.length
      ) {
        setshowLoader(false);
        setrecheckStatus(false);
        let columns = [];

        if (uniqUUID === previewTableResult?.uuid) {
          for (
            let index = 0;
            index < previewTableResult?.result?.data_header?.length;
            index++
          ) {
            if (
              previewTableResult?.result?.data_dtype &&
              previewTableResult?.result?.data_dtype[index]
            ) {
              columns.push({
                data_type: previewTableResult?.result?.data_dtype[index],
                technical_name: previewTableResult?.result?.data_header[index],
                business_name: previewTableResult?.result?.data_header[index],
              });
            } else {
              columns.push({
                data_type: "",
                technical_name: previewTableResult?.result?.data_header[index],
                business_name: previewTableResult?.result?.data_header[index],
              });
            }
          }

          if (columns.length) {
            if (sqlTableDetails?.length === 0)
              generateSqlTableInfo(columns);
          }
        }
      }
    }, [previewTableResult]);

    const onChangeTab = (key) => {
      // if (isLoadingRunning || !isReady) {
      //   emitToastNotification(
      //     "info",
      //     "Can't switch tab while data is loading."
      //   );
      //   return;
      // }
      // setReady(false);
      setActiveTab(key);
      // console.log(props, key)
      // if (props && props?.from === 'reports') {
      //   if (key && key === "0") {
      //     dispatch(setPreviewFlag("Query"));
      //     localStorage.setItem("previewFlag", "Query");
      //     let uuid = uuidv4();
      //     setUniqUUID(uuid);
      //     let request = {
      //       uuid: uuid,
      //       table_name: props?.responseData?.table_name,
      //       page: 0,
      //       do_count: false,
      //       sort_body: [],
      //       filter_body: [],
      //       table_ids: [props?.responseData?.table],
      //       query: convertBase64ToString(props?.responseData?.query_string)
      //     }
      //     setInitialRecordsCount(undefined)
      //     dispatch(setCurrentTableRowCount([]))

      //     store.dispatch(setActiveTabKey('Result'))
      //     localStorage.setItem("previewFlag", "Query");
      //     store.dispatch(setPreviewFlag("Query"))

      //     store.dispatch(setResponseQuery(resp.data.query))
      //     getPreviewTableResult(request, false, true).then((res) => {

      //       dispatch(setPreviewTableQuery(res.data.query));
      //     })
      //   } else {

      //     store.dispatch(setActiveTabKey('Result'))
      //     localStorage.setItem("previewFlag", "Query");
      //     store.dispatch(setPreviewFlag("Query"))

      //     store.dispatch(setResponseQuery(props?.GPTSQLQuery))
      //     dispatch(setPreviewTableQuery(props?.GPTSQLQuery));

      //   }
      //   setTimeout(() => {
      //     setReady(true);
      //   }, 1000);
      // } else {

      /* if (key && key === "0") {

        if ((isPreviewGrid &&
          (previewDataMap?.preview_query === undefined || previewDataMap?.preview_query === null))
          ||
          (isPreviewGrid && initialRecordsCount === undefined)
        ) {
          let uuid = uuidv4();
          setUniqUUID(uuid);
          let payload = createRequestBody(false, uuid)

          setInitialRecordsCount(undefined)
          dispatch(setCurrentTableRowCount([]))
          getPreviewTableResult(payload, false, true)

          setTimeout(() => {
            setReady(true);
          }, 1000);
          dispatch(setActiveTabKey(key));
          dispatch(resetPreviewNextPageCounter());
          setPreviewAlreadyLoading(true);
          dispatch(setQueryError(""))
          dispatch(setPreviewFlag("Preview"));
          localStorage.setItem("previewFlag", "Preview");

          return
        }

        dispatch(setQueryError(""))
        dispatch(setPreviewFlag("Preview"));
        localStorage.setItem("previewFlag", "Preview");
        console.log('previewDataMap -->', { previewDataMap })
        // setDataGridTableResponse({ result: previewDataMap?.preview_query });
        // dispatch(setCurrentTableRowCount(initialRecordsCount));
        console.log("___fref", storedData?.filterRef)
        setDataGridTableResponse({ result: storedData?.response })
        setPageNo(storedData?.pageNo)
        setDownloadQuery(storedData?.query)
        setStoredFilter(storedData?.filterRef ?? {})
        setDownloadQuery(storedData?.query)
        dispatch(setRowCountByUUID({ uuid: storedData?.uuid, rowCount: initialRecordsCount }));
      } */
      if (key && key === "1") {
        dispatch(setPreviewFlag("Query"));
        localStorage.setItem("previewFlag", "Query");

        if (isPreviewGrid) {
          let uuid = uuidv4();
          setUniqUUID(uuid);
          ExecuteQueryApi(undefined, uuid)
        }
        else if (isResReady) {
          ExecuteQueryApi(undefined, resultUniqUUID);
        }

      }
      // }
      dispatch(setActiveTabKey(key));
      dispatch(resetPreviewNextPageCounter());
      setPreviewAlreadyLoading(true);
      /* if (key && key == "0")
        setTimeout(() => {
          setReady(true);
        }, 1000); */
    };

    const setToInitialState = () => {
      setDataGridTableResponse(initialRecords);
      dispatch(setCurrentTableRowCount(initialRecordsCount));
      dispatch(resetPreviewNextPageCounter());
      dispatch(
        setPreviewDataMap({
          preview_query: initialRecords?.result,
          execute_query: null,
        })
      );
    };

    const removeTabFromTabsList = (key, removeSqlEditorQuery = true) => {
      const newTabsList = tabsList.filter((tab) => tab.key !== key);
      setTabsList(newTabsList);
      dispatch(
        setPreviewDataMap({
          preview_query: previewDataMap["preview_query"],
          execute_query: null,
        })
      );
      setActiveTab("0");
      dispatch(setActiveTabKey("0"));
      if (removeSqlEditorQuery) {
        dispatch(setPreviewTableQuery(""));
      }
      setToInitialState();
      dispatch(resetPreviewNextPageCounter());
      setPreviewAlreadyLoading(true);
    };

    useEffect(() => {
      // console.log({previewDataMap, tabsList})
      if (
        previewDataMap &&
        previewDataMap?.execute_query &&
        Object.keys(previewDataMap.execute_query).length
      ) {
        if (tabsList.length === 1) {
          if (isPreviewGrid) {
            if (store.getState().DataCatalogue?.PreviewPage?.sqlEditorQuery?.trim() !== "") {
              setTabsList((tabList) => {
                return [
                  ...tabList,
                  {
                    podId: "1",
                    key: "1",
                    tabTitle: "Result",
                    tabContent: null,
                    closable: true,
                  },
                ];
              });
              setActiveTab("1");
              dispatch(setActiveTabKey("1"));
            }
          }
          else {
            setTabsList((tabList) => {
              return [
                ...tabList,
                {
                  podId: "1",
                  key: "1",
                  tabTitle: "Result",
                  tabContent: null,
                  closable: true,
                },
              ];
            });
            setActiveTab("1");
            dispatch(setActiveTabKey("1"));
          }
        }
      } else {
        setTabsList([
          {
            podId: "0",
            key: "0",
            tabTitle: "All Records",
            tabContent: null,
            closable: false,
          },
        ]);
        setActiveTab("0");
        dispatch(setActiveTabKey("0"));
      }
    }, [previewDataMap]);

    useEffect(() => {
      if (Object.keys(previewDataMap).length) {
        setshowLoader(false);
        setrecheckStatus(false);
      }
    }, [activeTab, previewTableResponse]);
    /* console.log({
      storedData,
      storedFilter,
      resultTabStoredData,
      resultTabStoredFilter,
      uniqUUID,
      resultUniqUUID
    }) */
    const resetFilter = (onlyResetState = false, resetFilterClick) => {
      if (resetFilterClick === "reset") {
        dispatch(setQueryError(""));
        dispatch(setPreviewTableQuery(""));
        setresetQuery(true);
        setshowSplitView(false);
      }
      dispatch(setSortingColumns([]));
      dispatch(setFilteringColumns([]));
      dispatch(setHidingColumns([]));

      setactiveSortColumnType("");
      dispatch(setPreviewTableSort([]));
      dispatch(setPreviewTableFilters([]));
      dispatch(resetPreviewTableRecords());

      dispatch(setPreviewTableFlag("Preview"));
      dispatch(setPreviewFlag("Preview"));
      localStorage.setItem("previewFlag", "Preview");
      localStorage.removeItem("columnDetails");
      localStorage.removeItem("appliedFilter");
      localStorage.removeItem("sortCriteria");
    };

    const getPreviewTableResult = (
      payload,
      showLoader = false,
      initialApi = false,
      forTab = activeTab
    ) => {

      /* if(initialApi && dataGridTableResponse?.result) {
        return
      } */

      if(forTab === '0') {
        setFetchingRecords(true)
      }
      if (initialApi) {
        setPreviewError(undefined)
      }
      return new Promise((resv, rej) => {

        try {
          setisQueryKill(false)
          dispatch(getPreviewTable(payload, false, true, false, !initialApi, true))
            .then((response) => {
              if (
                response?.status.toLowerCase() === "success" &&
                response?.data
              ) {
                let responseData = response.data;

                const obj = {
                  response: responseData?.result,
                  pageNo: payload['page'],
                  filterRef: {},
                  uuid: payload['uuid'],
                  query: responseData?.query
                }
                if(initialApi) {
                  setDownloadQuery(obj.query)
                }
                if (forTab === '0') {
                  setStoredData(obj)
                  setUniqUUID(obj.uuid)
                  setPageNo(obj.pageNo)
                  setDownloadQuery(obj.query)
                  setStoredFilter({})
                }
                else {
                  setResultTabStoredData(obj)
                  setResultUniqUUID(obj.uuid)
                  setResultPageNo(obj.pageNo)
                  setResDownloadQuery(obj.query)
                  setResultTabStoredFilter({})
                }

                store.dispatch(setPreviewTableData(responseData.result));
                setpreviewTableResult(responseData);

                if (forTab === '0') {
                  setDataGridTableResponse(responseData);
                }
                else {
                  setResDataGridTableResponse(responseData);
                }

                if (initialApi) {
                  setInitialRecords(responseData);
                  setInitialQuery(responseData?.query ?? '')
                }
                if (initialApi && !isPreviewGrid) {
                  startWalkthrough(isTutorialActive)
                }

                setshowLoader(false);
              } else {
                setshowLoader(false);
              }
              if (localStorage.getItem("previewFlag") === "Query") {
                dispatch(setQueryError(""));
              }
              if (setIsGridLoaded) setIsGridLoaded(true);

              setFetchingRecords(false)
              resv(response);
            })
            .catch((e) => {
              setshowLoader(false);
              if (initialApi) {
                setPreviewErrorPayload(payload)
                setPreviewError(e?.message)
              }
              if (localStorage.getItem("previewFlag") === "Query") {
                dispatch(setQueryError(e.message));
              }
              setFetchingRecords(false)
              rej(e);
            });
        } catch (error) {
          setshowLoader(false);
          setFetchingRecords(false)
          rej(error);
        }
      });
    };

    const ExecuteQueryApi = (queryFromEditor, uuid) => {
      setResReady(false);
      resetFilter(true);
      store.dispatch(setPreviewFlag("Query"));
      localStorage.setItem("previewFlag", "Query");

      let new_uuid = uuidv4();
      setResultUniqUUID(new_uuid)
      
      let payload = createRequestBody(false, uuid ?? new_uuid);
      payload["query"] = queryFromEditor ? queryFromEditor :
        store.getState().DataCatalogue?.PreviewPage?.sqlEditorQuery;

      let domain_name = getCurrentPODCategoryName()
      let pod_name = getCurrentPODName()
      payload["selected_tables"] = [`${domain_name}.${pod_name}`]

      if (tabsList.length == 2) {
        dispatch(setActiveTabKey("1"));
        setActiveTab("1");
      }
      dispatch(
        setPreviewDataMap({
          preview_query: previewDataMap["preview_query"],
          execute_query: { data_header: [], data_record: [], data_count: 0, data_dtype: [], iserror: false },
        })
      );

      dispatch(setCurrentTableRowCount(''))

      if (queryFromEditor === undefined && payload?.query?.trim() === '') {
        setResReady(true)
        onChangeTab("0")
        return
      }

      if (!queryFromEditor && resultTabStoredData?.response) {
        dispatch(
          setPreviewDataMap({
            preview_query: resultTabStoredData?.response,
            execute_query: { data_header: [], data_record: [], data_count: 0, data_dtype: [], iserror: true },
          })
        );
        setResDataGridTableResponse({
          query: resultTabStoredData?.query,
          result: resultTabStoredData?.response,
          uuid: resultTabStoredData?.uuid
        })
        setResultUniqUUID(payload["uuid"])
        // setResultUniqUUID(resultTabStoredData?.uuid)
        setResReady(true)
      }
      else {
        getPreviewTableResult(payload, false, false, '1')
          .then((resp) => {
            setResDataGridTableResponse(resp.data);
            setResReady(true);
          })
          .catch((error) => {
            if (!isPreviewGrid) {
              dispatch(
                setPreviewDataMap({
                  preview_query: previewDataMap["preview_query"],
                  execute_query: { data_header: [], data_record: [], data_count: 0, data_dtype: [], iserror: true },
                })
              );
              setResDataGridTableResponse({
                result: {
                  data_header: [], data_record: [], data_count: 0, data_dtype: [],
                  isError: true,
                  error: error?.message,
                  query: payload["query"]
                }
              })

              setResultTabStoredData(() => {
                return {
                  response: {
                    data_header: [], data_record: [], data_count: 0, data_dtype: [],
                    isError: true,
                    error: error?.message,
                    query: payload["query"]
                  },
                  pageNo: payload['page'],
                  filterRef: {},
                  uuid: payload['uuid'],
                  query: payload["query"]
                }
              })
            }
            else {
              dispatch(
                setPreviewDataMap({
                  preview_query: previewDataMap["preview_query"],
                  execute_query: payload.query,
                })
              );
            }
            setResReady(true);
          });
      }
      setPreviewAlreadyLoading(true);
    };

    const generateSqlTableInfo = (columns) => {
      if (podDetails) {
        let sqlTables = [];
        columns.forEach((e) => {
          sqlTables.push({
            name: `"${e.technical_name}"`,
            description: "",
            type: "Column",
            tableName: podDetails.table_name,
          });
        });
        sqlTables.push({
          name: `${getCurrentPODCategoryName()}.${getCurrentPODName()}`,
          description: "",
          type: "Table",
          tableName: "",
        });

        setsqlTableDetails([...sqlTables]);
      }
    };

    const [showSplitView, setshowSplitView] = useState(false);

    useEffect(() => {
      if (!showSplitView) {
        dispatch(
          setPreviewDataMap({
            preview_query: previewDataMap["preview_query"],
            execute_query: null,
          })
        );
      }
    }, [showSplitView]);

    const setSplitView = () => {
      if (isLoadingRunning) {
        emitToastNotification("info", "Please wait while data is loading.");
        return;
      }
      if (isAnyFilterApplied()) {
        setOpenResetPopup(true);
        return;
        // if (!window.confirm('This action will reset all applied filters. \nDo you want to proceed?')) {
        //     return;
        // }
      }
      /* let { newUUID } = resetAppliedFilters();
      // const rowCountMap = store?.getState()?.CommonComponent?.DataGridTable?.rowCountMap
      dispatch(setRowCountByUUID({ uuid: newUUID, rowCount: initialRecordsCount }));

      setResultTabStoredData({})
      setResultTabStoredFilter({})
      setStoredData({
        response: initialRecords?.result,
        filterRef: {},
        pageNo: 0,
        uuid: newUUID,
        query: initialQuery
      })
      setStoredFilter({})
      setPageNo(0)
      setResultPageNo(0)
      setDownloadQuery(initialQuery)
      setResDownloadQuery('') */

      // let newUUID = uuidv4()
      // setUniqUUID(newUUID)
      // setTabsList([
      //     { podId: "0", key: '0', tabTitle: 'All Records', tabContent: null, closable: false }
      // ])
      // setActiveTab("0");
      // dispatch(setPreviewTableQuery(""))
      // setPreviewAlreadyLoading(true)
      // setToInitialState()
      // setshowSplitView(!showSplitView)
    };

    const getLatestData = (obj) => {
      if (obj?.pagination) {
        setStoredData(prev => {
          const prevData = prev?.response?.data_record
          const newData = [...prevData, ...obj?.response?.data_record]

          return {
            ...obj,
            response: {
              ...obj?.response,
              data_record: [...newData],
              data_count: newData?.length
            }
          }

        })
        setPageNo(obj.pageNo)
      }
      else {
        setStoredData(obj)
        setPageNo(obj.pageNo)
      }
      if (obj?.filterRef) {
        setStoredFilter(obj?.filterRef ?? {})
      }
    }
    
    const getSplitViewLatestData = (obj, tab = '0') => {
      if (obj?.pagination) {
        const prevFunc = prev => {
          const prevData = prev?.response?.data_record
          const newData = [...prevData, ...obj?.response?.data_record]

          return {
            ...obj,
            response: {
              ...obj?.response,
              data_record: [...newData],
              data_count: newData?.length
            }
          }

        }
        if (tab === '0') {
          setStoredData(prevFunc)
          setPageNo(obj?.pageNo)
          setDownloadQuery(obj.query)
        }
        else {
          setResultTabStoredData(prevFunc)
          setResultPageNo(obj?.pageNo)
          setResDownloadQuery(obj.query)
        }
      }
      else {
        if (tab === '0') {
          setStoredData(obj)
          setPageNo(obj?.pageNo)
          setDownloadQuery(obj.query)
        }
        else {
          setResultTabStoredData(obj)
          setResultPageNo(obj?.pageNo)
          setDownloadQuery(obj.query)
        }
      }
      if (obj?.filterRef) {
        if (tab === '0') {
          setStoredFilter(obj?.filterRef ?? {})
        }
        else {
          setResultTabStoredFilter(obj?.filterRef ?? {})
        }
      }
    }

    const resetAppliedFilters = (btnClick = '') => {
      // let newUUID = uuidv4();
      // setUniqUUID(newUUID);
      setTabsList([
        {
          podId: "0",
          key: "0",
          tabTitle: "All Records",
          tabContent: null,
          closable: false,
        },
      ]);
      setQueryModalDetail({})
      setActiveTab("0");
      // setReady(true);
      dispatch(setQueryKillFlag(false));
      dispatch(setPreviewTableQuery(""));
      setPreviewAlreadyLoading(true);
      // dispatch(setSortingColumns([]))
      // dispatch(setFilteringColumns([]));
      // dispatch(setHidingColumns([]));
      dispatch(setResponseQuery(`SELECT * FROM "hive"."${getCurrentPODCategoryName()}"."${getCurrentPODName()}"`))
      // setshowSplitView(!showSplitView)
      // setToInitialState()
      // dispatch(resetAllDataGridTableFilters())
      if (btnClick !== 'addReport') {

        setshowSplitView(!showSplitView);
        setToInitialState()
        setOpenResetPopup(false);

      } else if (btnClick === 'addReport') {
        // setReady(false);

        dispatch(
          setPreviewDataMap({
            preview_query: previewDataMap["preview_query"],
            execute_query: null,
          })
        );
        dispatch(setQueryError(""))
        dispatch(setPreviewFlag("Preview"));
        localStorage.setItem("previewFlag", "Preview");
        // console.log('previewDataMap -->', {previewDataMap})
        // setDataGridTableResponse({ result: previewDataMap?.preview_query });
        // dispatch(setCurrentTableRowCount(initialRecordsCount));

        // setTimeout(() => {
        //   setReady(true);

        // }, 2000);
      }

      // return { newUUID }
    };

    const getCurrentPODName = () => {
      return podDetails && podDetails?.table_name;
    };

    const getCurrentPODID = () => {
      return podDetails && podDetails?.table_id;
    };

    const getCurrentPODCategoryName = () => {
      return podDetails && podDetails?.data_domain_detail?.data_domain_name;
    };

    const mergeArray = (arrayElement) => {
      let merge = [];

      arrayElement.forEach((ele) => {
        merge = [...merge, ...ele[Object.keys(ele)[0]]];
      });

      return merge;
    };

    const createRequestBody = (isPaginate, uuid = "") => {
      if (!isPaginate) {
        dispatch(resetPreviewNextPageCounter());
      }

      previewFlag = localStorage.getItem("previewFlag");
      let filterCriteria;
      let filterArray = JSON.parse(localStorage.getItem("appliedFilter"));
      if (filterArray && filterArray.length) {
        filterCriteria = mergeArray(filterArray);
      }
      let sortArray = JSON.parse(localStorage.getItem("sortCriteria"));

      let request = {
        uuid: uuid,
        table_name: getCurrentPODName(),
        page: 0,
        do_count: false,
        sort_body: sortArray && sortArray.length ? sortArray : [],
        filter_body:
          filterCriteria && filterCriteria.length ? filterCriteria : [],
        table_ids: [getCurrentPODID()],
      };

      if (isPaginate && previewFlag === "Search") {
        request["keyword"] =
          store.getState().DataCatalogue.PreviewPage.searchKeyword;
        request["columns"] = previewDataMap["preview_query"].data_header;
      }

      if (previewFlag === "Query" && isPaginate) {
        request["query"] =
          store.getState().DataCatalogue.PreviewPage.sqlEditorQuery;
      }
      return request;
    };

    const isAnyFilterApplied = () => {
      let sort = store.getState().CommonComponent?.DataGridTable?.sortArray;
      let filter = store.getState().CommonComponent?.DataGridTable?.filterArray;
      let hideFields =
        store.getState().CommonComponent?.DataGridTable?.hiddenFieldsArray;

      return sort.length > 0 || filter.length > 0 || hideFields.length > 0;
    };

    const isLoadingMore = (loading) => setLoadMoreRunning(loading);

    // const uuidValue = React.useMemo(() => {
    //   if (activeTab === '0')
    //     return uniqUUID
    //   return resultUniqUUID
    // }, [activeTab, uniqUUID, resultUniqUUID])

    // const setUuidValue = (uid) => {
    //   if (activeTab === '0') setUniqUUID(uid)
    //   else setResultUniqUUID(uid)
    // }
    // const setSplitViewFilterStoredData = (data) => {
    //   if (activeTab === '0') setStoredData(data)
    //   else setResultTabStoredData(data)
    // }

    const downloadQueryValue = React.useMemo(() => {
      if (activeTab === '0')
        return downloadQuery
      return resDownloadQuery
    }, [activeTab, downloadQuery, resDownloadQuery])

    // const splitViewStoredFilterValue = React.useMemo(() => {
    //   if (activeTab === '0')
    //     return storedFilter
    //   return resultTabStoredFilter
    // }, [activeTab, storedFilter, resultTabStoredFilter])

    const handleSplitView = () => {
      setshowSplitView((show) => {
        if(show) {
          setSplitSize(['100%', '0%'])
        }
        else setSplitSize(['55%', '45%'])

        return !show
      })
    }

    return (
      <div style={{ display: showPage ? 'block' : 'none', position: "relative", background: "white", height: '100%' }}>
        <Walkthrough
          walkthrough={walkthrough}
          showSkipButton={true}
          spotlightClicks={spotClick}
          disableOverlayClose={true}
          callback={walkthroughCallback}
        />
        {getConfirmationModal({
          open: openResetPopup,
          setOpen: setOpenResetPopup,
          body: <>This action will reset all applied filters.<br />Do you want to proceed?</>,
          onConfirm: resetAppliedFilters
        })}
        <div
          className={`preview-search ${searchMode && !isPreviewGrid ? "active" : ""
            }`}
        >
          {
            searchMode ?
              <PreviewSearchBody isSearchMode={searchMode} sqlTableDetails={sqlTableDetails} />
              : ''
          }
        </div>

        <div
          className={`preview-loader preview-main-content ${searchMode && !isPreviewGrid ? "active" : ""
            }`}
        >
          {/* <Space size="middle"> */}
              {/* {isApiError ? <ResultNotFound refreshPage={refreshPage} /> :  */}
              <>
                {!expandDictTable ? (
                  <>
                    <DataTabs
                      props={{
                        activeKey: activeTab,
                        defaultKey: defaultTab,
                        onChange: onChangeTab,
                        tabArray: tabsList,
                        onEdit: removeTabFromTabsList,
                      }}
                    />
                    <div className="preview-table-metainfo">
                      <PodMetaHeader />
                      <div className="preview-table-actions"></div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <React.Fragment>
                  <SplitPaneView
                    parentSplitSize={splitSize}
                    resultResp={resDataGridTableResponse?.result}
                    isEnableAnalyze={
                      store.getState().CommonComponent?.DataGridTable
                        ?.activeTabKey === "0"
                        ? true
                        : false
                    }
                    isDisableHideBtn={
                      store.getState().CommonComponent?.DataGridTable
                        ?.activeTabKey === "1"
                        ? true
                        : false
                    }
                    isPreviewPage={true}
                    CurrentPODName={getCurrentPODName}
                    CurrentPODID={podDetails && podDetails?.table_id}
                    CurrentDomainName={getCurrentPODCategoryName}
                    expandTable={expandDictTable}
                    ExecuteQueryClick={ExecuteQueryApi}
                    scroll={{
                      // y: 300,
                      x: "100vw",
                    }}
                    showLoader={showLoader}
                    sqlTableDetails={sqlTableDetails}
                    resetAppliedFilters={resetAppliedFilters}
                    setSplitview={handleSplitView}
                    resetQuery={resetQuery}
                    isSplitPane={false}
                    setshowLoader={setshowLoader}
                    // uniqUUID={uuidValue}
                    downloadQuery={downloadQueryValue}
                    resetAll
                    // setUniqUUID={(uid) => {
                    //   console.log({uid})
                    //   setUuidValue(uid)
                    // }}
                    isPreviewDataAlreadyLoading={isPreviewGrid ? true : isPreviewAlreadyLoading}
                    setPreviewAlreadyLoading={setPreviewAlreadyLoading}
                    isLoadingMore={isLoadingMore}
                    isDeletePermission={isDeletePermission}
                    isEditPermission={isEditPermission}
                    isViewPermission={isViewPermission}
                    isResultGridReady={isResReady}
                    queryModalDetail={queryModalDetail}
                    activeTab={activeTab}
                    isUnstructured={podDetails && podDetails?.is_unstructured}
                    connectionName={
                      podDetails && podDetails?.connection === 'upload' ? undefined
                        : podDetails?.connection
                    }
                    resetResultTab={() => {
                      dispatch(
                        setPreviewDataMap({
                          preview_query: previewDataMap["preview_query"],
                          execute_query: null,
                        })
                      )
                    }}
                    resultRecPageNo={resultPageNo}
                    resultRecStoredFilter={resultTabStoredFilter}
                    resultRecStoredData={resultTabStoredData}
                    resultRecUUID={resultUniqUUID}
                    setResultAllRecUUID={setResultUniqUUID}
                    resultRecDownloadQuery={resDownloadQuery}
                    resultRecGetData={(obj) => getSplitViewLatestData(obj, '1')}

                    originalChildren={
                      <>
                        <div
                          className={`prev-page-layout custom-virtual-data-table ${showSplitView ? 'split-active' : ''}`}
                        >
                          {
                            previewError
                              ? getReloadErrorTemplate({
                                errorMessage: previewError,
                                onReload: () => {
                                  getPreviewTableResult(previewErrorPayload, false, true);
                                }
                              })
                              :
                              isQueryKill ? <QueryKillError /> :
                                (isPreviewGrid && errorQ !== "" && activeTab == 1)
                                  ? <ErrorComponent msg={"Failed to execute query"} />
                                  : dataGridTableResponse &&
                                    Object.keys(dataGridTableResponse).length > 0
                                    && isReady
                                    && !isFetchingRecords // To Rerender the DataGrid to prevent width issue
                                    ? (
                                      <DataGridTable
                                        resp={dataGridTableResponse?.result}
                                        requestedTableId={podDetails && podDetails?.table_id}
                                        isUnstructured={podDetails && podDetails?.is_unstructured}
                                        connectionName={
                                          podDetails && podDetails?.connection === 'upload' ? undefined
                                            : podDetails?.connection
                                        }
                                        // connectionType={
                                        //   sourceDetails && sourceDetails?.source
                                        // }
                                        CurrentPODName={getCurrentPODName}
                                        CurrentDomainName={getCurrentPODCategoryName}
                                        expandTable={expandDictTable}
                                        isSplitPane={false}
                                        setshowLoader={setshowLoader}
                                        showLoader={showLoader}
                                        isEnableAnalyze={true}
                                        isDataGridReady={isDataGridReady}
                                        isPreviewPage={true}
                                        isPreviewDataAlreadyLoading={true}
                                        setPreviewAlreadyLoading={setPreviewAlreadyLoading}
                                        uniqUUID={uniqUUID}
                                        setUniqUUID={setUniqUUID}
                                        showDictionaryDetails={(isPreviewGrid && activeTab == "1") ? false : true}
                                        dictSchemaData={dictSchemaData}
                                        showTechnicalTerm={true}
                                        columnsRegex={columnsRegex}
                                        gridHeight={gridHeight}
                                        getLatestData={getLatestData}
                                        parentPageNo={pageNo}
                                        downloadQuery={downloadQuery}
                                      />
                                    ) : (
                                      <>
                                        {isFetchingRecords ? <ClaristaLoader isCancel={true} setKillQueryDone={setisQueryKill} /> : null}
                                      </>
                                    )}
                        </div>
                      </>
                    }
                  />
                </React.Fragment>
              </>
          {!searchMode &&
            dataGridTableResponse &&
            Object.keys(dataGridTableResponse).length > 0 ? (
            !expandDictTable ? (
              <div
                id="prevSqlBtn"
                className={`sql-editor-toggle fixed-button-query custom-btn-primary ${noOpenAiAccess ? '' : 'adjust-cpilot-icon'} ${!isReady ? 'disabled' : ''}`}
                onClick={() => {
                  // if (isReady) setSplitView()
                  handleSplitView()
                }}
              >
                {showSplitView ? (
                  <CLOSE height="16" width="16" />
                ) : (
                  <Tooltip title="Report" placement="left">  <REPORT_ICON height="28" width="28" /></Tooltip>
                )}
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
);

export default PreviewPageLayout;

const tableSkeleton = () => {
  return (
    <>
      <div style={{ height: "calc(100vh - 190px)" }}>
        <div className="p-3"></div>
        <div className="text-center mb-3">
          <i className="mr-1 bx bx-loader-alt bx-spin"></i>Please wait...
        </div>
        <div>
          {[...Array(15)].map((v, i) => (
            <div key={`col-skeleton-row-${v}${i}`} className={`row mb-2 mx-n2`}>
              {[...Array(12)].fill("1").map((col, i) => (
                <div key={`col-skeleton-${i}`} className={`col-${col} px-2`}>
                  <CustomSkeleton height="25px" width="100%" isActive={false} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
