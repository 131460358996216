import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setSidebarActiveRoute } from '../../../store/actions'
import LandingPageSubHeader from '../../Common/landingPageSubHeader/LandingPageSubHeader'
import LandingpageFirstHeader from '../../modules/common/LandingpageFirstHeader'
import JobsIndex from './components/JobsIndex'
import { UsageLogs } from './components/UsageLogs'
import './usageAndJobs.scss'
import { ICON_ANALYZE, ICON_CLOSE, ICON_LOGS, ICON_USAGE } from '../../Common/newIconSource'
import LandingPageSubHeaderLeftSide from '../../Common/landingPageSubHeader/LandingPageSubHeaderLeftSide'
import { LandingPageSubHeaderContext } from '../../Common/landingPageSubHeader/LandingPageSubHeaderContext'
import Buttons from '../../Common/button/Buttons'
import { Literals } from '../common/literals'
import { useNavigate } from 'react-router-dom'

export const UsageAndJobs = (props) => {

    let { fromRoute = 'Usage' } = props

    const [, setSearchData] = useState('');
    const [activeTab, setActiveTab] = useState('jobs');

    const dispatch = useDispatch()
    const history = useNavigate()


    const tabsMenuList = [
        { title: 'Jobs', key: 'jobs', Icon: () => <><ICON_ANALYZE/></>, onClickEvent: (key) => { setActiveTab(key) } },
        // { title: 'Usage', key: 'usage', Icon: () => <><ICON_LOGS/></>, onClickEvent: (key) => { setActiveTab(key) } }
    ]

    useEffect(() => {
        dispatch(setSidebarActiveRoute('/usage'))
    }, [])


    return (
        <>
            <div className='usage-and-jobs-wrapper'>
                <LandingpageFirstHeader title={'Usage and Jobs'} flag={false} backRoute={"/usage"} />
                <div className='comm-subheader flow-sub-header custom-border-top custom-border-bottom'>
                <LandingPageSubHeaderContext.Provider value={{ 
                    defaultActiveTab: activeTab,
                    tabsMenuList: tabsMenuList,
                    setSearchData: setSearchData,
                    fromRoute: fromRoute,
                    activeTab:activeTab
                 }}>
                    <LandingPageSubHeaderLeftSide />
                    <div>
                        <Buttons
                            props={{
                                tooltip: 'Close',
                                tooltipPlacement: 'left',
                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon`,
                                buttonEvent: () => {
                                    history('/')
                                },
                                ImgSrc: () => <ICON_CLOSE/>,
                                isDisable: false,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </div>
                </LandingPageSubHeaderContext.Provider>

                </div>
                {/* <LandingPageSubHeader 
                    props={{ 
                        defaultActiveTab: activeTab,
                        tabsMenuList: tabsMenuList,
                        setSearchData: setSearchData,
                        fromRoute: fromRoute,
                        activeTab:activeTab
                    }} /> */}
                    <div className='padding-2' style={{height:"calc(100vh - 69px)"}}>
                        {
                            activeTab === 'usage' ?
                            <UsageLogs showFilters={true}/> : <JobsIndex showFilters={true}/>
                        }
                    </div>
            </div>
        </>
    )
}
