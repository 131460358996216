import _, { cloneDeep, isArray } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { createNewSettingsAdditionalDetails, updateSettingsAdditionalDetails } from '../../../../store/modules/dataDictionary/action';
import Buttons from '../../../Common/button/Buttons';
import SingleSelection from '../../../Common/dropDown/SingleSelection';
import { CHECK, CLOSE, COLUMN, PLUS, SAVE } from '../../../Common/iconSource';
import { checkSpecialCharRegex, getRelativeTime, getFieldTypeIcon, getTime, podNameRegex, preventSpecialCharsRegex } from '../../common/helperFunctions';
import { Literals } from '../../common/literals';
import { Select, Switch } from 'antd';
import { ICON_EDITBOX, ICON_SAVE } from '../../../Common/newIconSource';

const choices = [
    {
        Name: 'DROPDOWN', 
        value: 'DROPDOWN',
        icon: () => <span className='mr-1'>{getFieldTypeIcon('DROPDOWN')}</span>
    },
    {
        Name: 'TOGGLE', 
        value: 'TOGGLE',
        icon: () => <span className='mr-1'>{getFieldTypeIcon('TOGGLE')}</span>
    },
    {
        Name: 'TEXT', 
        value: 'TEXT',
        icon: () => <span className='mr-1'>{getFieldTypeIcon('TEXT')}</span>
    },
    {
        Name: 'DATE', 
        value: 'DATE',
        icon: () => <span className='mr-1'>{getFieldTypeIcon('DATE')}</span>
    },
    {
        Name: 'NUMERIC', 
        value: 'NUMERIC',
        icon: () => <span className='mr-1'>{getFieldTypeIcon('NUMERIC')}</span>
    }
]

const initialFieldValue = {
    name: '',
    type: choices[0].value,
    value: [''],
    description: '',
    default_value: null
}

const AdditionalFieldModal = ({
    openModal, 
    setOpenModal=()=>{},
    getList,
    selectedData,
    viewOnly,
    setViewOnly,
    isEdit,
    setIsEdit
}) => {
    
    const dispatch = useDispatch()

    const [fieldsValue, setFieldsValue] = useState(
            {
                ...initialFieldValue
            }
        )

    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        if(isEdit) {
            setFieldsValue(
                {
                    name: selectedData.name,
                    type: selectedData.type.toUpperCase(),
                    value: isArray(selectedData.value) ? selectedData.value.join(',') : [''],
                    description: selectedData.description,
                    default_value: selectedData?.default_value ?? null
                }
            )
        }
    },[isEdit, selectedData])
    
    const handleFields = (value, key) => {
        // if(key === 'value') {
            // value = value.split(',')
        // }
        if(key === 'name') {
            value = value.toUpperCase()
        }
        else if(key === 'type') {
            if(value?.toLowerCase() === 'toggle') {
                setFieldsValue(prev=>({...prev, [key]: value, default_value: !!value}))
                return
            }
            else {
                setFieldsValue(prev=>({...prev, [key]: value, default_value: ""}))
                return
            }
        }
        setFieldsValue(prev=>({...prev, [key]: value}))
    }
    
    const handleAdd = () => {
        let payload = {...fieldsValue}
        if(payload.type!==choices[0].value) {
            delete payload.value
        }
        else if(payload?.value?.includes(',')){
            payload.value = payload?.value?.split(',')?.map(v => v?.trim())
        }
        setIsLoading(true)
        dispatch(createNewSettingsAdditionalDetails(payload))
        .then(()=>{
            getList()
            setIsLoading(false)
            setFieldsValue({...initialFieldValue})
            emitToastNotification('success', 'Successfully Added')
            setOpenModal(false)
        })
        .catch(()=>{
            emitToastNotification('error', 'Failed to add')
            setIsLoading(false)
        })
    }

    const handleUpdate = () => {
        setIsLoading(true)
        let payload = {...fieldsValue}
        if(payload.type!==choices[0].value) {
            delete payload.value
        }
        if(payload?.value?.includes(',')) {
            payload['value'] = payload?.value?.split(',')?.map(v => v?.trim())
        }

        dispatch(updateSettingsAdditionalDetails(selectedData.id, payload))
        .then(()=>{
            getList()
            setIsLoading(false)
            setOpenModal(false)
            emitToastNotification('success', Literals.UPDATE_SUCCESSFUL)
        })
        .catch(()=>{
            emitToastNotification('error', Literals.UPDATE_FAILED)
            setIsLoading(false)
        })
    }

    const submitButton = {
        getText: () => {
            if(isEdit) {
                if(isLoading) return 'Updating...'
                else if(viewOnly) return 'Edit'
                return 'Update'
            }
            else {
                if(isLoading) return 'Saving...'
                return 'Save'
            }
        },
        onClick: () => {
            if(isEdit) {
                if(viewOnly) setViewOnly(false)
                else handleUpdate()
            }
            else handleAdd()
        },
        getIcon: () => {
            if(isLoading) return <i className='bx bx-loader-alt bx-spin mr-1'></i>
            else if(isEdit) {
                if(viewOnly) return <ICON_EDITBOX/>
                return <CHECK/>
            }
            else return <ICON_SAVE/>
        },
        getDisableStatus: () => {
            return _.isEmpty(fieldsValue.name.trim()) || isLoading
        }
    }

    return (
        <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true} onClosed={()=>{setOpenModal(false); setViewOnly(false); setIsEdit(false); setFieldsValue({...initialFieldValue})}}>
            <ModalHeader className='d-flex align-items-center' close={<Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType:Literals.BTN_TERTIARY}} />}>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className='d-flex align-items-center settings-addfield-modal-ic'>
                        <COLUMN/>
                        <h2 className="section-heading">
                            {
                                viewOnly?'View Additional Details':
                                isEdit
                                ?
                                'Edit Additional Details'
                                :
                                'Create New Entry in  Additional Details'
                            }
                        </h2>
                    </div>
                </div>                  
            </ModalHeader>
            <ModalBody>
                <div className='px-2'>
                    <div className='row'>
                        <div className='col-4 mb-2'>
                            <label className='font-w-600 grey-color label'>Field Name</label>
                            <div>
                                <input
                                    required
                                    placeholder=''
                                    type="text"
                                    value={fieldsValue.name}
                                    onChange={(e) => {
                                        if(preventSpecialCharsRegex.test(e.target.value) || e.target.value === '') {
                                            handleFields(e.target.value, 'name')
                                        }
                                    }}
                                    className={`custom-input-field`}
                                    disabled={viewOnly?true: false}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-2'>
                            <label className='font-w-600 grey-color label'>Field Type</label>
                            <div>
                                <SingleSelection
                                    props={{
                                        placeholder: "Select",
                                        dropDownValue: fieldsValue.type,
                                        onClickEvent: (val)=> handleFields(val, 'type'),
                                        optionList: choices,
                                        isDisable: viewOnly?true:false,
                                    }}
                                />
                            </div>
                        </div>
                        {
                            fieldsValue.type===choices[0].value
                            ?
                            <div className='col-4 mb-2'>
                                <label className='font-w-600 grey-color label'>Possible Values (Optional)</label>
                                <div>
                                    <input
                                        required
                                        placeholder='values separated by comma (,)'
                                        type="text"
                                        value={fieldsValue.value}
                                        onChange={(e) => handleFields(e.target.value, 'value')}
                                        className={`custom-input-field`}
                                        disabled={viewOnly?true: false}
                                    />
                                </div>
                            </div>
                            :   ''
                        }
                        <div className='col-4 mb-2'>
                            <label className='font-w-600 grey-color label'>Default Value</label>
                            <div>
                                {
                                    (() => {
                                        switch (fieldsValue.type) {
                                            case choices[0].value:
                                                return <>
                                                    <Select
                                                        key={`def-val-${fieldsValue?.value?.toString()}`}
                                                        className='addfield-def-fld'
                                                        defaultValue={
                                                            fieldsValue?.default_value ?? ''
                                                            // isArray(fieldsValue?.value)
                                                            // ?
                                                            // fieldsValue?.value[0]
                                                            // :   ''
                                                        }
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        disabled={viewOnly}
                                                        onChange={(val) => {
                                                            handleFields(val, 'default_value')
                                                        }}
                                                        options={
                                                            isArray(fieldsValue?.value)
                                                            ?
                                                            fieldsValue?.value?.filter(v => v?.trim() !== '').map(f => (
                                                                {
                                                                    value: f,
                                                                    label: f
                                                                }
                                                            ))
                                                            :   fieldsValue?.value?.split(',')?.filter(v => v?.trim() !== '').map(f => (
                                                                    {
                                                                        value: f,
                                                                        label: f
                                                                    }
                                                                ))
                                                                ?? []
                                                        }
                                                    />
                                                </>

                                            case choices[1].value:
                                                return <>
                                                    <Switch
                                                        size='small'
                                                        defaultChecked={false}
                                                        checked={fieldsValue?.default_value ?? false}
                                                        onChange={(bool) => {
                                                            handleFields(bool, 'default_value')
                                                        }}
                                                        disabled={viewOnly}
                                                    />
                                                </>

                                            case choices[2].value:
                                            case choices[3].value:
                                            case choices[4].value:
                                                return <>
                                                    <input
                                                        placeholder=''
                                                        type={
                                                            fieldsValue?.type === 'NUMERIC' 
                                                            ? 'number' 
                                                                :
                                                                fieldsValue?.type === 'DATE'
                                                                ? 'date'
                                                                : 'text'
                                                            }
                                                        defaultValue={fieldsValue.value}
                                                        onChange={(e) => handleFields(e.target.value, 'default_value')}
                                                        className={`custom-input-field`}
                                                        disabled={viewOnly}
                                                    />
                                                </>
                                        
                                            default:
                                                return <></>
                                        }
                                    })()
                                }
                            </div>
                        </div>
                        <div className='col-8 mb-2'>
                            <label className='font-w-600 grey-color label'>Description</label>
                            <div>
                                {
                                    viewOnly
                                    ?
                                    <p className='mb-0 add-field-custom-desc' style={{fontSize: '0.75rem'}}>
                                        {fieldsValue.description}
                                    </p>
                                    :
                                    <input
                                        required
                                        placeholder={viewOnly ? 'No Description' : ''}
                                        type="text"
                                        value={fieldsValue.description}
                                        onChange={(e) => handleFields(e.target.value, 'description')}
                                        className={`custom-input-field`}
                                        disabled={viewOnly}
                                    />
                                }
                            </div>
                        </div>
                        {
                            isEdit
                            ?
                            <>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Created by</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={selectedData.created_by_user.username}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Created on</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={`${getRelativeTime(selectedData.created_on)}, ${getTime(selectedData.created_on)}`}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Updated by</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={selectedData.updated_by_user.username}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Updated on</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={`${getRelativeTime(selectedData.updated_on)}, ${getTime(selectedData.updated_on)}`}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </>
                            :   ''
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
               {
            //    viewOnly?"":
                 <Buttons props={{ 
                        buttonText: submitButton.getText(), 
                        buttonClassName: "custom-btn-primary custom-btn btn-with-text", 
                        buttonEvent: () => submitButton.onClick(), 
                        ImgSrc: () => submitButton.getIcon(), 
                        isDisable: submitButton.getDisableStatus(),
                        buttonType:Literals.BTN_PRIMARY_WITH_TEXT
                    }} 
                />}
            </ModalFooter>
        </Modal>
    )
}

export default AdditionalFieldModal