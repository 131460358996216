import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Buttons from '../../../Common/button/Buttons';
import { CREATEFLOWS, CLOSE, CHECK } from '../../../Common/iconSource'
import { Literals } from '../../common/literals'
import { editFlowNameApiCall, generateFlowIDApi, getFlowDetails, setToDefault, updateFlowsBasicDetails } from '../../../../store/modules/flows/flowsActions'
import { useStore } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { resetScriptRedux } from '../../../../store/modules/flows/scriptTransformer/scriptAction';
import { resetTargetRedux } from '../../../../store/modules/flows/targetTransformer/targetTransformer';
import { getConnectionListV2, resetCreatePodData } from '../../../../store/actions';
import { useParams } from 'react-router-dom';
import { SHOW_BRICKS_MENU } from '../../../../store/modules/flows/flowsTypes';
import { columnNameRegex, podNameRegex, preventSpecialCharsRegex } from '../../common/helperFunctions';

const NewFlowsPopUp = ({ openModal, setOpenModal, editFlowName = false, CurrentflowName = '', flowDesc = '' }) => {
    const history = useNavigate();
    const [flowName, setFlowName] = useState(``)
    const [descript, setDescript] = useState(``);
    const [isDisable, setisDisable] = useState(false);

    const { flowId } = useParams()

    useEffect(() => {
        if (!editFlowName) {

            setFlowName(``)
            setDescript(``)
            store.dispatch(resetScriptRedux());
            store.dispatch(resetTargetRedux())
            store.dispatch(setToDefault())
            store.dispatch(resetCreatePodData())
        } else {
            setFlowName(CurrentflowName)
            setDescript(flowDesc)
        }
    }, [])

    const store = useStore()

    const generateFlowID = async () => {
        let payload = {
            name: flowName,
            description: descript
        }
        setisDisable(true)
        try {
            let response = await store.dispatch(generateFlowIDApi(payload))
            if (response.status === "success") {
                setisDisable(false)

                store.dispatch(updateFlowsBasicDetails(response.data));
                store.dispatch(getConnectionListV2()).then((res) => {
                    if (res?.data?.length) {
                        let bricksExist = res.data.findIndex((ele) => ele.source_type?.toLowerCase() === 'databricks')
                        if (bricksExist > -1) {
                            store.dispatch({ type: SHOW_BRICKS_MENU, payload: true })
                        } else {

                            store.dispatch({ type: SHOW_BRICKS_MENU, payload: false })

                        }
                    }
                })
                // history(Literals.links.FLOWS_EDIT + response.data.id)
                history(Literals.links.FLOWS + '/' + response.data.id)

                // history('/flows/' + response.data.id)
            }
        }
        catch (error) {
            setisDisable(false)

        }

    }


    const editFlowNameHandle = () => {

        if (flowName !== '') {
            store.dispatch(editFlowNameApiCall(flowId, {
                name: flowName,
                description: descript
            })).then((resp) => {
                if (resp.status === 'success') {
                    getFlowDetailsHandle()
                }
            })
        }

    }

    const getFlowDetailsHandle = () => {

        store.dispatch(getFlowDetails(flowId)).then((resp) => {
            if (resp && resp.data) {
                let responseData = resp.data
                let obj = { name: responseData.name, description: responseData.description, id: responseData.id, bookmark: responseData.bookmark }
                store.dispatch(updateFlowsBasicDetails(obj))
                setOpenModal(false)
            }
        })

    }

    return (
        <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true}>
            <ModalHeader>

                <div className="d-flex align-items-center">
                    <CREATEFLOWS />
                    <h2 className="section-heading">{editFlowName ? 'Edit Flow Details' : 'Create New Flow'}</h2>
                </div>
                <div className="tab-menu right-flow-header">
                    <Buttons props={{
                        buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {
                            setOpenModal(false)
                            setFlowName(``)
                            setDescript(``)
                        }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                    }} />
                </div>
            </ModalHeader>
            <ModalBody >
                <label className="label">Flow Name *</label>
                <input type="text" name="flowname" className="custom-input-field mb-3" value={flowName} onChange={(e) => {

                    if (preventSpecialCharsRegex.test(e.target.value) || e.target.value === "") {
                        setFlowName(e.target.value);
                        setisDisable(false)
                    }
                }} />
                <label className="label">Description</label>
                <textarea id="descript" className="custom-textarea-field" rows="8" value={descript} type="text" onChange={(e) => {
                    setDescript(e.target.value)
                }} />
            </ModalBody>
            <ModalFooter>
                {/* <Buttons props={{
                    buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => {
                        setOpenModal(false)
                        setFlowName(``)
                        setDescript(``)
                    }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                }} /> */}
                <Buttons props={{buttonId: "primary-focus-btn", buttonText: 'Done', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { editFlowName ? editFlowNameHandle() : generateFlowID() }, ImgSrc: () => <CHECK />, isDisable: isDisable || flowName === ``, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
            </ModalFooter>
        </Modal>
    )
}

export default NewFlowsPopUp
