import React from 'react'
import { Select, Tooltip } from 'antd';
const { Option } = Select;
function MultiSelection(props) {
    const { label, selectClassName, placeholder, dropDownValue = [], onClickEvent, optionList, isDisable, isSortOptionDisable = false, wrapClassName, bordered = true, maxTagCount } = props.props;



    return (
        <div className={`${bordered ? maxTagCount ? " full-height-dropdown" : 'custom-multi-select custom-select-dropdown' : ''} ${wrapClassName}`}>
            {label && <label className="label">{label}</label>}
            <Select
                showSearch
                mode="multiple"
                allowClear

                maxTagPlaceholder={(omittedValues) => (
                    <Tooltip title={omittedValues.map(({ value, Name }) => value).join(', ')}>
                        <span>More...</span>
                    </Tooltip>
                )}
                className={selectClassName}
                disabled={isDisable}
                placeholder={placeholder}
                autoFocus={true}
                value={dropDownValue}
                maxTagTextLength={4}
                onChange={onClickEvent}
                maxTagCount={maxTagCount ?? 'responsive'}
                bordered={bordered}
                filterOption={(input, option) => {
                    const name = optionList?.find(d => d?.value === option?.value)?.Name ?? ''
                    return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                }
                }
                filterSort={isSortOptionDisable === true ? () => { } : (optionA, optionB) => {
                    const nmA = optionList?.find(d => d?.value === optionA?.value)?.Name ?? ''
                    const nmB = optionList?.find(d => d?.value === optionB?.value)?.Name ?? ''
                    return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                }
                }
            >
                {props.children && props.children.length ?
                    props.children
                    :
                    optionList?.length && optionList.map((ele, index) => <Option disabled={ele.isDisableOption} key={index} value={ele.value}><span className="element-name">{ele.Name}</span></Option>
                    )
                }
            </Select>
        </div>
    )
}

export default MultiSelection
