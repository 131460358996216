import React, { useState, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import './css/App.scss';
import { updateUserDetails, setUpdatedUserData, changeUserPassword, setUser } from '../../../store/userAuthentication/userActions';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,

} from "reactstrap";
import PasswordPolicy from '../../../components/Common/PasswordPolicy';
import { CLOSE, PROFILE_ICON_BLACK, TICK, EDIT } from '../../../components/Common/iconSource';
import Buttons from '../../Common/button/Buttons';
import { Literals } from '../common/literals';
import { List, Switch } from 'antd';
import ClaristaLoader from '../../Common/claristaLoader/ClaristaLoader';
import DATA_ANALYST from "../../../assets/images/persona/data_analyst.svg";
import DATA_CONSUMER from "../../../assets/images/persona/data_consumer.svg";
import DATA_SCIENTIST from "../../../assets/images/persona/data_scientist.svg";
import { stringToColor } from '../common/helperFunctions';
import { emitToastNotification } from '../../../helpers/toast_helper';

function UpdateProfile({ show, handleClose, setIsEditMode, isEditMode, showPasswordChange, setShowPasswordChange }) {

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [firstName, setfirstName] = useState(null);
    const [lastName, setlastName] = useState(null);
    const [designation, setDesignation] = useState(Literals.DATA_ANALYST);
    const [isSuperUser, setIsSuperUser] = useState(null);
    const [oldPassword, setOldPassword] = useState(null)
    const [progressBarValue, setprogressBarValue] = useState(null)
    let userId = React.useRef(null)
    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)
    const [confirmPassword, setconfirmPassword] = useState(null);
    const [buttonClick] = useState(false);
    const [isPasswordMatch, setisPasswordMatch] = useState(true)
    const [showLoading, setShowLoading] = useState(false);
    const [formChange, setformChange] = useState(false);
    const [, setPasswordFormChange] = useState(false);
    const [selectedPersona, setselectedPersona] = useState(0)
    const [isReady, setReady] = useState(false);
   

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const userDetails = user.userDetails
            setEmail(userDetails?.email);
            setfirstName(userDetails.first_name);
            setlastName(userDetails.last_name);
            setIsSuperUser(userDetails.is_superuser)
            setDesignation(userDetails.designation)
            userId.current = userDetails.id
        }
    }, [show]);
    const store = useStore();



    // Match New Password and Confirm Password
    const matchPassword = () => {

        if (password && confirmPassword && password !== confirmPassword) {
            setisPasswordMatch(false)
            setPasswordFormChange(false)
            return false
        } else if (password && !confirmPassword) {
            setisPasswordMatch(false)
            setPasswordFormChange(false)
            return false
        } else if (password && confirmPassword && password === confirmPassword) {
            setisPasswordMatch(true)
            setPasswordFormChange(true)
            return true
        } else if (!password && !confirmPassword) {
            setisPasswordMatch(true)
            setPasswordFormChange(true)
            return false
        }
    }
    const onFormChange = (e) => {
        if (e.target.value) {
            setformChange(true)
        }
    }

    // On Click Edit =Profile
    function onEditMode() {
        setIsEditMode(!isEditMode)
        setShowPasswordChange(false)
    }

    // On Click Edit Password
    function onEditPassword() {
        setShowPasswordChange(true)
        setisPasswordMatch(true)
        setIsEditMode(false)
    }

    // Change Password
    async function changePassword() {
        try {
            if (matchPassword()) {
                setShowLoading(true)
                const passwordPayload = {
                    new_password: password,
                    confirm_password: confirmPassword,
                    old_password: oldPassword
                }
                const response = await store.dispatch(changeUserPassword(passwordPayload))
                if (response.status === 'success') {
                    handleClose()
                    setShowPasswordChange(false)
                    setShowLoading(false)
                } else {
                    setShowLoading(false)
                }
            }
        } catch (e) {
            setShowLoading(false)
        }
    }
    //User Profile Update
    async function onSave() {

        if(!firstName || firstName.trim() === "") {
            emitToastNotification('error', 'Please Provide First Name')
            return
        }
    
        // if(!lastName || lastName.trim() === "") {
        //     emitToastNotification('error', 'Please Provide Last Name')
        //     return
        // }
        
        setShowLoading(true)
        let userPayload = {
            first_name: firstName?.trim(),
            last_name: lastName?.trim() ?? null,
            designation: designation
        }

        if(lastName?.trim() === "") {
            userPayload.last_name = null
        }

        try {
            const response = await store.dispatch(updateUserDetails(userPayload, userId.current))
            if (response.status === 'success') {
                let user = store.getState().UserReducer?.user
                user.userDetails = {...user.userDetails, ...response.data}
                // console.log({user})
                
                store.dispatch(setUser(user))
    
                setUpdatedUserData({...user.userDetails, ...response.data})
                setIsEditMode(false)
                setShowLoading(false)
                setformChange(false)
    
                /**
                 * Reloading So that from wherever user perform this action
                 * should get the updated user info
                 */
                // window.location.reload()
                // SocketContext.callUserList();

            } else {
                setShowLoading(false)
                setformChange(false)
            }
        }
        catch(err) {
            setShowLoading(false)
            setformChange(false)
        }
    }

    useEffect(() => {

        if (userDetailsRedux?.designation === Literals.DATA_ANALYST) {

            setDesignation(Literals.DATA_ANALYST)
            setselectedPersona(0)


        } else if (userDetailsRedux?.designation === Literals.DATA_SCIENTIST) {

            setDesignation(Literals.DATA_SCIENTIST)
            setselectedPersona(1)
        } else if (userDetailsRedux?.designation === Literals.DATA_CONSUMER) {
            setDesignation(Literals.DATA_CONSUMER)
            setselectedPersona(2)

        } else {

            setDesignation(Literals.DATA_ANALYST)
            setselectedPersona(0)
        }
    }, [show])

    const setDesignationValue = (value) => {
        if (value === 0) {

            setDesignation(Literals.DATA_ANALYST)
        } else if (value === 1) {

            setDesignation(Literals.DATA_SCIENTIST)
        } else if (value === 2) {

            setDesignation(Literals.DATA_CONSUMER)
        }
        setselectedPersona(value)
    }
    return (
        <Modal
            isOpen={show}
            toggle={() => handleClose()}
            centered backdrop="static"
        >
            <div className='modal-header px-3'>
                <div className='py-1'>
                    <span className='mr-2'><PROFILE_ICON_BLACK /></span>
                    <span className="section-heading">My Profile</span>
                </div>
                <div className='py-1'>
                    <Buttons 
                        props={{ 
                            buttonText: '', 
                            buttonClassName: "custom-btn custom-btn-outline btn-with-icon", 
                            buttonEvent: () => { 
                                handleClose(); 
                                setIsEditMode(false); 
                                setShowPasswordChange(false); 
                                setformChange(false); 
                            }, 
                            ImgSrc: () => <CLOSE />, 
                            isDisable: false, 
                            buttonType: Literals.BTN_SECONDARY 
                        }} 
                    />
                </div>
            </div>
            <ModalBody className="px-3 py-2" >

                <form className="" autoComplete="off">

                    {showPasswordChange ?
                        <React.Fragment>
                            <div className="form-group mb-2 text-left">
                                <label htmlFor="exampleInputtext8" className="label label-color">Enter Old Password</label>
                                <input type="password" placeholder="Enter Old Password" onChange={(e) => setOldPassword(e.target.value)} className={"form-control custom-input-field"} id="exampleInputtext5" />
                            </div>
                            <div className="form-group mb-3 text-left">
                                <div className="invalid-feedback">
                                    Please provide a valid password.
                                </div>
                            </div>

                            <PasswordPolicy
                                newPassword={password}
                                confirmPassword={confirmPassword}
                                setconfirmPassword={setconfirmPassword}
                                isPasswordMatch={password == confirmPassword}
                                setNewPassword={setPassword}
                                setprogressBarValue1={setprogressBarValue}
                                updateProfile={true}
                                setReady={setReady}
                            />

                        </React.Fragment>
                        : <>
                            <div className="row">

                                <div className="d-flex flex-column col-9">
                                    <div className="form-group mb-1 text-left">
                                        <label htmlFor="" className="label label-color mb-1">First Name</label>
                                        <input 
                                            type="text" 
                                            readOnly={!isEditMode} 
                                            onKeyUp={(e) => onFormChange(e)} 
                                            value={firstName} 
                                            placeholder="Enter First Name" 
                                            onChange={(e) => setfirstName(e.target.value)} 
                                            className={
                                                buttonClick && 
                                                !firstName 
                                                ? "custom-input-field is-invalid form-control form-Fonts fontInterSemiBold text-dark" 
                                                : "custom-input-field form-control form-Fonts fontInterSemiBold text-dark"
                                            } 
                                            id="exampleInputtext4" />
                                        <div className="invalid-feedback">
                                            Please provide a valid First Name.
                                        </div>
                                    </div>
                                    <div className="form-group mb-1 text-left">
                                        <label htmlFor="" className="label label-color mb-1">Last Name</label>
                                        <input 
                                            type="text" 
                                            readOnly={!isEditMode} 
                                            onKeyUp={(e) => onFormChange(e)} 
                                            value={
                                                (() => {
                                                    if(!isEditMode && (!lastName || lastName === ""))
                                                    return "Not Provided"
                                                    return lastName ?? ""
                                                })()
                                            } 
                                            placeholder="Enter Last Name" 
                                            onChange={(e) => setlastName(e.target.value)} 
                                            className={
                                                buttonClick && 
                                                !lastName ? "custom-input-field is-invalid form-control form-Fonts fontInterSemiBold text-dark" 
                                                : "custom-input-field form-control form-Fonts fontInterSemiBold text-dark"
                                            } 
                                            id="exampleInputtext5" 
                                            style={!isEditMode && (!lastName || lastName === "") ? { opacity: '.5' } : {}}
                                            />
                                        <div className="invalid-feedback">
                                            Please provide a valid  Last Name.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 d-flex justify-content-center align-items-center">
                                    <div className="initials-icon" style={{background: stringToColor(`${firstName} ${lastName}`)}}>
                                        {firstName && firstName[0].toUpperCase()}
                                        {lastName && lastName[0].toUpperCase()}
                                    </div>
                                </div>
                            </div>

                            <div className="row d-flex">
                                <div className="form-group col-12 mb-3 text-left">
                                    <label htmlFor="exampleInputtext3" className="label label-color mb-1">Email </label>
                                    <input 
                                         type="text"
                                         readOnly={true}
                                         onKeyUp={(e) => onFormChange(e)}
                                         value={email}
                                         onChange={(e) => setEmail(e.target.value)}
                                         placeholder="email"
                                         className={
                                           buttonClick && !email
                                             ? "custom-input-field is-invalid form-control form-Fonts fontInterSemiBold text-dark"
                                             : "custom-input-field form-control form-Fonts fontInterSemiBold text-dark"
                                         }
                                         id="exampleInputtext3"
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a Email
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="form-group col-12 mb-3 text-left">
                                    <label htmlFor="exampleInputtext3" className="label label-color">Persona </label>
                                    <div className='d-flex align-items-center m-0 p-0 w-100 justify-content-center persona-choice-wrap'>
                                        <div onClick={() => isEditMode ? setDesignationValue(0) : null} className={` cursor-pointer ${selectedPersona === 0 ? 'persona-selected' : 'persona-container'}`}>
                                            <img src={DATA_ANALYST} alt={Literals.DATA_ANALYST} height={150} width={150}></img>
                                            <label className='label text-center  cursor-pointer w-100 persona-title'>{Literals.DATA_ANALYST}</label>
                                        </div>
                                        <div onClick={() => isEditMode ? setDesignationValue(1) : null} className={`cursor-pointer ${selectedPersona === 1 ? 'persona-selected' : 'persona-container'}`}>
                                            <img src={DATA_SCIENTIST} alt={Literals.DATA_SCIENTIST} height={150} width={150}></img>
                                            <label className='label text-center  cursor-pointer w-100 persona-title'>{Literals.DATA_SCIENTIST}</label>
                                        </div>
                                        <div onClick={() => isEditMode ? setDesignationValue(2) : null} className={`cursor-pointer ${selectedPersona === 2 ? 'persona-selected' : 'persona-container'}`}>
                                            <img src={DATA_CONSUMER} alt={Literals.DATA_CONSUMER} height={150} width={150}></img>
                                            <label className='label text-center  cursor-pointer w-100 persona-title'>{Literals.DATA_CONSUMER}</label>
                                        </div>
                                    </div>
                                    {/* <input type="text" readOnly={!isEditMode} onKeyUp={(e) => onFormChange(e)} value={designation} onChange={(e) => setDesignation(e.target.value)} placeholder="designation" className={buttonClick && !designation ? "custom-input-field is-invalid form-control form-Fonts" : "custom-input-field form-control form-Fonts"} id="exampleInputtext3" />
                                    <div className="invalid-feedback">
                                        Please provide a valid Designation
                                    </div> */}
                                </div>
                            </div>
                            {
                                isSuperUser ?
                                    <div className="row d-flex justify-content-end">
                                        <div className="form-group col-12 mb-3">
                                            <label className="align-items-center d-flex mr-1">
                                                <i className='bx bx-check-circle mr-1 text-success'></i> Super User
                                            </label>
                                            <div className="custom-control custom-checkbox">
                                                {/* <input type="checkbox" onChange={()=>{}} className="custom-input-field custom-control-input" id="CustomCheck1" checked={isSuperUser} /> */}

                                            </div>
                                        </div>
                                    </div> : ''
                            }
                        </>
                    }


                </form>
            </ModalBody>
            <ModalFooter className="">
                <div className="d-flex flex-end">

                    {showPasswordChange ? <Buttons
                        props={{
                            buttonText: ` Done `,
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { changePassword() },
                            ImgSrc: () => <TICK />,
                            isDisable: !isReady,
                            // isDisable: showLoading || !formChange || !isReady, 
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }}
                    /> :
                        isEditMode ?
                            <Buttons
                                props={{
                                    buttonText: `Save`,
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { onSave() },
                                    ImgSrc: () => showLoading ? <i className="bx bx-loader-alt bx-spin mr-1"></i> : <TICK />,
                                    // isDisable: !oldPassword || !password || !confirmPassword || showLoading, 
                                    isDisable: showLoading,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                            :
                            <Buttons
                                props={{
                                    buttonText: 'Edit',
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { setIsEditMode(true) },
                                    ImgSrc: () => <EDIT />,
                                    // isDisable: !oldPassword || !password || !confirmPassword || showLoading, 
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />}
                </div>
            </ModalFooter>

        </Modal>
    );
}

export default UpdateProfile;

