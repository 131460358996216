import React,{useRef} from "react";
import Buttons from "../../../../Common/button/Buttons";
import { PLUS } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import RowFilters from "./RowFilters";
import NoDataInfo from "./NoDataInfo";
import "./RowLevelMasking.scss";
import DataGridAddFilter from "../../../../Common/dataGridTable/DataGridAddFilter";
import { forwardRef } from "react";
const RowLevelMasking =forwardRef (({props 

},ref) => {

  const{filters, addNewFilter, setFilters, viewMode ,isEditorPermission,
    initialColumns,
    setFilterPop,
    setAppliedFilter,
    appliedFilter,
    policyData
  }=props

   
  return (
    <div className="policy-row-mask">
      <div className="policy-row-mask-header">
        <div className="policy-row-mask-title">
          <h4>{Literals.ROW_LEVEL_MASKING}</h4>
        </div>
        <div className="policy-row-mask-options">
         {/* {viewMode.includes("view")?"": <Buttons
            props={{
              buttonText: Literals.FILTER,
              buttonClassName:
                "custom-btn-primary custom-btn btn-with-text ml-2",
              buttonEvent: () => {
                addNewFilter();
              },
              ImgSrc: () => <PLUS />,
              isDisable: viewMode.includes("view"),
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
            }}
          />} */}
        </div>
      </div>
      <div className="policy-row-mask-table">
      <DataGridAddFilter
            ref={ref}
            props={{
              icon: () => <></>,
              title: "Filter",
              onSampleEvent: () => { },
              initialColumn: initialColumns,
              isPolicy:true,
              setAppliedFilter: setAppliedFilter,
              appliedFilter: appliedFilter,
              setFilterPop: setFilterPop,
              isdisabled:viewMode === 'view' || !isEditorPermission,
              ctx: ``,
              defaultToggleValue: policyData?.filter_row?.[0]?.operation
            }}
          />
        {/* {filters.length ? (
          <RowFilters
            filters={filters}
            setFilters={setFilters}
            viewMode={viewMode}
          />
        ) : (
          <NoDataInfo category="rowLevelMasking" />
        )} */}
      </div>
    </div>
  );
});

export default RowLevelMasking;
