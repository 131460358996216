import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";
import { setSearchMode } from "../../../../../../store/modules/dataCatalogue/preview/previewActions";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useSelector } from "react-redux";
import {
  MAGNIFY,
  CLOSE,
  CLARISTA_LOGO,
  CHATGPT_ICON,
  MICROPHONE_ICON,
  DISABLE_MICROPHONE,

} from "../../../../../Common/iconSource";
import SearchIcon from "../../../../../Common/searchIcon/SearchIcon";
import { resetDictionaryRedux } from "../../../../../../store/actions";
import { resetAllDataGridTableFilters } from "../../../../../../store/modules/common/DataGridTable/dataGridTableRedux";
const SearchBox = ({
  onSearchHandle,
  searchText,
  setSearchText,
  resetData,
  summaryLoaded,
  isSearching,
  props
}) => {
  const dispatch = useDispatch();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();


  const searchMode = useSelector(
    (state) => state.DataCatalogue.PreviewPage.searchMode
  );
  const isChatgpt = useSelector(
    (state) => state.DataCatalogue.PreviewPage.isChatgpt
  );
  const searchRef = useRef("");

  useEffect(() => {
    if (searchMode) searchRef.current.focus();
  }, [searchMode]);


  useEffect(() => {
    if (!listening && transcript !== "") {
      setSearchText(transcript);
      resetTranscript();
      // onSearchHandle(transcript);
    }
  }, [listening])
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input
          prefix={
            <span className="ml-2 mr-2">
              {
                // isChatgpt ? <CHATGPT_ICON /> :
                  <CLARISTA_LOGO height={20} width={20} />
              }
            </span>
          }
          disabled={!searchMode}
          ref={searchRef}
          spellCheck={false}
          autoFocus
          placeholder={listening ? "Listening..." : isChatgpt ? "Ask Clarista..." : "Ask Clarista ..."}
          className={`preview-search-input ${!searchMode ? 'invisible' : ''} `}
          id="preview-search-box"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter" || e.keyCode === 13) {
              onSearchHandle();
              // setSearch(true);
            }
          }}
          suffix={
            <>
              {searchText ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    // dispatch(setSearchMode(false));
                    setSearchText("");
                  }}
                >
                  Clear
                  {/* <CLOSE width="20" height="20" /> */}
                </span>
              ) : (
                ""
              )}
              {browserSupportsSpeechRecognition ? !listening ? <div style={{ cursor: "pointer" }} onClick={SpeechRecognition.startListening}><MICROPHONE_ICON /></div> : <div style={{ cursor: "pointer" }} onClick={SpeechRecognition.stopListening}><DISABLE_MICROPHONE /></div> : ""}

            </>
          }
        />
        <div
          className={`search-close ${isChatgpt ? "chatgpt" : ""}`}
          onClick={() => {
            resetData();
            setSearchText("");
            if (props && props?.from === 'reports') {

              dispatch(resetDictionaryRedux());
              dispatch(resetAllDataGridTableFilters())
              props && props?.fetchPreview(props?.responseData)
            }

            props && props?.setshowChatGptMode(false)
            dispatch(setSearchMode(false));
          }}
        >
          <CLOSE height={15} width={15} />
        </div>
      </div>
      {/* <PreviewSuggestion /> */}
    </div>
  );
};

export default SearchBox;
