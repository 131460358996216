import React from 'react'
import CopilotAlertCapsule from './CopilotAlertCapsule'
import ConversationSender from './ConversationSender'
import ReplyMessage from './ReplyMessage'
import SuggestionPill from './SuggestionPill'
import AutoGrowInput from '../connections/landingPage/components/autoGrowInput/AutoGrowInput'
import CopilotLoader from './CopilotLoader'
import { useDispatch, useSelector } from 'react-redux'
import { ICON_CLOSE, ICON_TABLE } from '../../Common/newIconSource'
import { createPodCopilot } from '../../../store/modules/common/ClaristaCopilot/ClaristaCopilotReducer'
import CopilotMic from './CopilotMic'
import Buttons from '../../Common/button/Buttons'
import { Literals } from '../common/literals'

const SqlCopilot = React.forwardRef(({
    props
}, ref) => {

    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [conversations, setConversations] = React.useState(props?.sqlChat ? [...props?.sqlChat] : [])
    const [hideMic, setHideMic] = React.useState(false)
    const [micListening, setMicListening] = React.useState(false)

    const createPodRedux = useSelector(state => state?.DataCatalogue?.PodCreationData)

    const { schemaListData, tableData, currentSelectedConnection } = createPodRedux

    const selectedTables = schemaListData?.filter(s => s?.children)?.map(s => s?.children)?.flat()?.filter(s => s?.isChild && s?.isChecked) ?? []
    
    const chatInputRef = React.useRef({})
    const cpilotContentRef = React.useRef({})

    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    React.useImperativeHandle(ref, () => {
        return {
            syncChatWithParent: () => {
                props?.setSqlChat([...conversations])
            }
        }
    }, [conversations])

    React.useEffect(() => {
        return () => {
            props?.setSqlChat([...conversations])
        }
    }, [conversations])

    React.useEffect(() => {
        if(selectedTables?.length === 0) {
            chatInputRef?.current?.setInputDisabled(true)
            setConversations([])
        }
        else {
            chatInputRef?.current?.setInputDisabled(false)
        }
    }, [createPodRedux])

    const handleChat = (msg) => {
        chatInputRef?.current?.setInputValue('')
        setConversations(prev => ([...prev, {user_type: "HUMAN", message: msg}]))
        setHideMic(false)
        setError(undefined)
        setLoading(true)
        let payload = {
            tables: [
                ...Object.keys(tableData)?.filter(k => k !== 'result')
                ?.map(v => (
                    {
                        table_name: props?.isPodOfPod ? v : `${currentSelectedConnection}.${v}`,
                        columns: tableData[v]?.data_header ?? props?.columnMap[v] ?? [],
                    }
                ))
            ],
            user_chat: [
                ...conversations,
                {
                    "user_type": "HUMAN",
                    "message": msg
                }
            ]
        }

        chatInputRef?.current?.setInputDisabled(true)
        setTimeout(() => {
            cpilotContentRef?.current?.scrollTo(0, cpilotContentRef?.current?.scrollHeight)
        }, 500)

        dispatch(createPodCopilot(payload, false))
        .then(res => {
            let dat = res?.data?.user_chat
            setConversations([...dat])
            chatInputRef?.current?.setInputDisabled(false)
            setLoading(false)

            setTimeout(() => {
                cpilotContentRef?.current?.scrollTo(0, cpilotContentRef?.current?.scrollHeight)
            }, 500)
        })
        .catch(err => {
            console.error({err})
            setLoading(false)
            setError(err?.message)
            setConversations(prev => prev?.slice(0, prev?.length - 1))
            chatInputRef?.current?.setInputValue(msg)
            chatInputRef?.current?.setInputDisabled(false)
            setTimeout(() => {
                cpilotContentRef?.current?.scrollTo(0, cpilotContentRef?.current?.scrollHeight)
            }, 500)
        })
    }

    return (
        <div className='sql-cpilot-wrap'>
            <div className='cpilot-content-wrap' ref={cpilotContentRef}>

                {
                    selectedTables?.length > 0 ? ''
                    :
                    <div className='pt-2'>
                        <h4 className='cpilot-head-lg fontInterSemiBold text-capitalize'>
                            Hello {`${userDetailsRedux?.first_name ?? ''} ${userDetailsRedux?.last_name ?? ''}`}!
                        </h4>
                        <h4 className='cpilot-head-md fontInterSemiBold'>
                            Just ask and get SQL with copilot
                        </h4>
                    </div>
                }
                <div className='p-2'></div>
                {
                    selectedTables?.length > 0 ? ''
                    :
                    <div className='mb-4'>
                        <CopilotAlertCapsule
                            content={
                                <div className='fontSizeHeading'>
                                    To unlock Copilot's assistance, simply select a table from the left panel.
                                </div>
                            }
                            onClick={() => {
                                props?.setHighlightSchemaPane(true)

                                setTimeout(() => {
                                    props?.setHighlightSchemaPane(false)
                                }, 1000)
                            }}
                        />
                    </div>
                }
                {
                    selectedTables?.length > 0
                    ?
                    <>
                        <label className='mb-2 fontSizeLabel fontInterSemiBold'>Selected tables</label>
                        <div className='cpilot-simple-msg-card border-0 rounded p-2' style={{width: 'fit-content', cursor: 'default'}}>
                            <div className='bg-white rounded'>
                                {
                                    selectedTables
                                    ?.map((t, i) => (
                                        <div key={`${t?.parentName}.${t?.name}-${i}`} className='d-flex px-2 py-1'>
                                            <div className='d-flex mr-2'><ICON_TABLE width='14' height='14' /></div>
                                            <div className='fontSizeLabel fontInterSemiBold'>
                                                {t?.parentName}.{t?.name}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                    :   ''
                }
                <div>
                    {
                        conversations?.map((c, i) => (
                            <div
                                key={`copilot-conversation-${i + 1}`}
                            >
                                {
                                    c?.user_type?.toLowerCase() === "human"
                                    ?
                                    <ConversationSender
                                        msg={c?.message ?? ''}
                                    />
                                    :
                                    <ReplyMessage
                                        msg={c?.message ?? ''}
                                        sql={c?.sql_query ?? ''}
                                    />
                                }
                            </div>
                        ))
                    }
                </div>
                {
                    error ? <CopilotAlertCapsule content={<div className='fontSizeHeading'>{error}</div>}/> 
                    :
                    loading ? <div className='my-3 px-3'><CopilotLoader/></div> : ''
                }

                {
                    selectedTables?.length > 0 && conversations?.length === 0
                    ?
                    <div className='my-2'>
                        <SuggestionPill
                            msg={"Ask Clarista to write SQL"}
                        />
                    </div>
                    : ''
                }

                {
                    // selectedTables?.length > 0
                    // ?
                    // <div className='my-3'>
                    //     {
                    //         [
                    //             'Write code to retrieve all data from selected tables',
                    //             'Select specific columns from selected tables'
                    //         ]?.map((s, i) => (
                    //             <SuggestionPill
                    //                 key={`sugg-${i}`}
                    //                 msg={s}
                    //                 onClick={handleChat}
                    //             />
                    //         ))
                    //     }
                    // </div>
                    // :   ''
                }
            </div>

            
            <div className='cpilot-ask-inp'>
                <AutoGrowInput
                    placeholder={selectedTables?.length === 0 
                        ? "Select table to get started" 
                        : "Start typing your query in plain english..."}
                    onEnterCallback={val => {
                        handleChat(val)
                    }}
                    setFocus={focus => {
                        if(micListening) {
                           setHideMic(false) 
                        }
                        else {
                            setHideMic(focus)

                        }
                    }}
                    ref={chatInputRef}
                />
                <div className={`cpilot-clearbtn ${!hideMic || micListening ? 'd-none' : ''}`}>
                    <Buttons
                        props={{
                            buttonText: '',
                            tooltip: '',
                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon bg-transparent`,
                            buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                chatInputRef?.current?.setInputValue("")
                                setHideMic(false)                             
                            },
                            ImgSrc: () => <ICON_CLOSE/>,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }}
                    />
                </div>
                <span className={hideMic && !micListening ? 'd-none' : ''}>
                    <CopilotMic
                        inputRef={chatInputRef}
                        getListening={listening => {
                            setMicListening(listening)
                        }}
                        disabled={selectedTables?.length === 0}
                    />
                </span>
            </div>
        </div>
    )
})

export default SqlCopilot