import { Switch, Tooltip, Popover, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Buttons from '../../../Common/button/Buttons'
import { CLOCK, DELETEICON, EDIT, PLAY, SCHEDULER, SWITCH, USERACTIVE, SAVE, CLOSE, COPY, USERINACTIVE, LINEAGE_ICON, CRAWLER } from '../../../Common/iconSource'
import { LandingSimpleTable } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import { Literals } from '../../common/literals'
import {  getRelativeTime, permissions } from "../../common/helperFunctions";
import Highlighter from "react-highlight-words";
import { useStore, useDispatch, useSelector } from 'react-redux'
import { ExecuteScheduler, setSelectedScheduler, setSelectedTempScheduler } from '../../../../store/modules/scheduler/action'
import {
    duplicate
} from "../../../../store/modules/dataCatalogue/dictionary/dictionaryAction";
import eventBasedIcon from "../../../../assets/images/trigger-event.png"
import { cloneDeep } from 'lodash'
import { ICON_EVENT } from '../../../Common/newIconSource'



export const SchedulersList = ({ openDeleteDialog = () => { }, fromRoute, schedulerData = [], searchData, getSchedulerList }) => {

    let originalCols = [
        {
            name: 'Scheduler Name',
            key: "scheduler_name",
            sortable: true,
            resizable: true,
            width: "18%"

        },
        {
            name: 'Description',
            key: "description",
            sortable: true,
            resizable: true,
            width: fromRoute.toLocaleLowerCase() === 'schedulermodal' ? "30%" : "22%"
        },
        {
            name: 'Last Run On',
            key: "last_run_on",
            sortable: true,
            resizable: true,
            width: fromRoute.toLocaleLowerCase() === 'schedulermodal' ? "12%" : "8%",



        },
        {
            name: <div className='text-center'>Last Run Status</div>,
            key: "last_run_status",
            sortable: false,
            resizable: true,
            width: fromRoute.toLocaleLowerCase() === 'schedulermodal' ? "12%" : "11%"

        },
        {
            name: 'Last Updated On',
            key: "last_updated_on",
            sortable: true,
            resizable: false,
            width: fromRoute.toLocaleLowerCase() === 'schedulermodal' ? "12%" : "10%"

        },
        {
            name: <div className='text-center'>State</div>,
            key: "active",
            sortable: false,
            resizable: false,
            // width:
            width: "15%"

        },
        {
            name: <div className='text-center'>Action</div>,
            key: "action_btn",
            sortable: false,
            resizable: false,
            width: "15%"
        }
    ];
    const dispatch = useDispatch();
    const history = useNavigate()
    const [data, setData] = useState([])
    const store = useStore()
    const [renameText, setRenameText] = useState({});
    const [visible, setVisible] = useState({});
    const { schedulerId } = useParams();
    const selectedScheduler = useSelector(state => state.Scheduler.selectedScheduler)

    const [columns, setColumns] = useState(originalCols)
    const [selectedRowIndex, setselectedRowIndex] = useState(undefined)
    const handleSaveClick = (val) => {
        try {
            dispatch(duplicate({ name: renameText[val.id] }, "scheduler", val.id)).then((res) => {
                if (res.status === 'success') {
                    getSchedulerList();
                }
            })
        }
        catch (err) {

        }
    }

    useEffect(() => {
        if (selectedScheduler?.id && schedulerId) {
            setselectedRowIndex(selectedScheduler.id)
        }
    }, [selectedScheduler])

    useEffect(() => {


        let copyCols = cloneDeep(columns)
        if (fromRoute.toLocaleLowerCase() === 'schedulermodal') {
            setColumns(copyCols.slice(0, copyCols.length - 1))
        } else {
            setColumns([...originalCols])
        }
    }, [fromRoute])
    useEffect(() => {

        let temp = schedulerData.map((item) => {
            return !schedulerId || schedulerId?.toString() !== item.id.toString() ? {
                id: item.id,
                key: item.id,
                type: item.type,
                name: item.name,
                scheduler_name: listTemplate.scheduler_name(item.type.toLocaleLowerCase() === 'time' ? <CLOCK height='18' width='18' /> : item.type.toLocaleLowerCase() === 'partition' ? <div style={{
                    width: "18px",
                    height: " 18px",
                    borderRadius: "50%",
                    // border: " 1.5px solid #2C83C4",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "transparent",
                    zIndex: 1,
                }}><CRAWLER height='18' width='18' /></div> : <div style={{
                    width: "18px",
                    height: " 18px",
                    borderRadius: "50%",
                    // border: " 1.5px solid #2C83C4",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "transparent",
                    zIndex: 1,
                }}><ICON_EVENT height='18' width='18' /></div>, item.name, searchData),
                description: listTemplate.description(item.description, searchData),
                last_run_on: listTemplate.last_run_on(item.created_on, searchData),
                last_run_status: listTemplate.last_run_status(item.latest_status, searchData),
                last_updated_on: listTemplate.last_updated_on(item.updated_on, searchData),
                active: listTemplate.status(item.active, () => { }),
                action_btn: listTemplate.action(
                    openDeleteDialog, 
                    (click) => { click === 'editClick' ? history(Literals.links.SCHEDULER_EDIT + item.id) : store.dispatch(ExecuteScheduler(item.id)) }, 
                    item.id, 
                    item.active, 
                    item, 
                    renameText, 
                    setRenameText, 
                    visible, 
                    setVisible, 
                    handleSaveClick
                    ),
            } : null
        }).filter(item => item && item)
        setData([...temp])
    }, [searchData, schedulerData, visible, renameText])



    const onRowClick = (record) => {
        if (fromRoute.toLocaleLowerCase() === 'schedulermodal') {
            setselectedRowIndex(record.id)
            dispatch(setSelectedTempScheduler(record))
            // localStorage.setItem('selectedScheduler', JSON.stringify(record))


        } else {

            history(Literals.links.SCHEDULER_VIEW + record.id)
        }
    }

    const getComparator = (sortColumn) => {
        switch (sortColumn) {
            case 'scheduler_name':
                return (a, b) => a[sortColumn]?.props?.children?.props?.children[1]?.props?.children?.props?.title !== undefined ? a[sortColumn]?.props?.children?.props?.children[1]?.props?.children?.props?.title.localeCompare(b[sortColumn]?.props?.children?.props?.children[1]?.props?.children?.props?.title) : null
            case 'description':
            case 'last_run_on':
            case 'last_updated_on':
                return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.title !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.title.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.title) : null
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }

    return <>
        <div style={fromRoute.toLocaleLowerCase() === 'schedulermodal' ? { height: "calc(100vh - 225px)" } : {}} className='p-1 scheduler-list-wrap'>
            <LandingSimpleTable
                cols={columns}
                rowsData={data}
                showCursor={true}
                autoWidth={true}
                getComparator={getComparator}
                onRowClick={onRowClick}
                selectedRowIndex={selectedRowIndex}
                tableHeight={fromRoute.toLocaleLowerCase() === 'schedulermodal' ? 503 : undefined}
            />
        </div>
    </>
}

const listTemplate = {

    scheduler_name: (icon, text = '', searchD) => {
        return <>
            <div className='sch-ls-name'>
                <span className='sch-ls-ic'>{icon}</span>
                <span className='sch-ls-text text-with-ellipsis text-uppercase'><Tooltip placement="bottom" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></span>
            </div>
        </>
    },
    description: (text = '', searchD) => {
        return <>
            {text ? <div className='text-with-ellipsis'><Tooltip placement="bottom" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px" }}>No description has been given yet.</span></label>}
        </>
    },
    last_run_on: (item = '', searchD) => {
        return <>
            <div className='text-with-ellipsis'>
                <Tooltip placement="top" title={getRelativeTime(item, undefined)}>
                    {getRelativeTime(item)}
                </Tooltip>
            </div>
            {/* <div className='text-with-ellipsis'><Tooltip placement="bottom" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div> */}
        </>
    },
    last_run_status: (text = '', searchD) => {
        return <>
            <div className='text-with-ellipsis text-center'><Tooltip placement="bottom" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div>
        </>
    },
    last_updated_on: (item = '', searchD) => {
        return <>
            <div className='text-with-ellipsis'>
                <Tooltip placement="top" title={getRelativeTime(item, undefined)}>
                    {getRelativeTime(item)}
                </Tooltip>
            </div>
            {/* <div className='text-with-ellipsis'><Tooltip placement="bottom" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div> */}
        </>
    },
    status: (checked = true, onClick = () => { }) => {
        return <>
            <div className='text-center h-100'>
                <div className='d-flex align-items-center justify-content-center h-100'>
                    {
                        checked
                            ? <USERACTIVE />
                            : <USERINACTIVE />
                    }

                </div>
            </div>
        </>
    },
    action: (onDelete = () => { }, onExecute = () => { }, id, isActive, item, renameText, setRenameText, visible, setVisible, handleSaveClick) => {
        const isEditor = !!item?.permission?.find(v => v === permissions.scheduler.editor)
        return <>
            <div className='d-flex h-100 align-items-center justify-content-center'>
                <Buttons
                    props={{
                        buttonText: "",
                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                        buttonEvent: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onExecute()
                        },
                        tooltip: "Execute",
                        ImgSrc: () => <PLAY />,
                        isDisable: !isEditor || !isActive,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />

                <Buttons
                    props={{
                        buttonText: "",
                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                        buttonEvent: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onExecute('editClick')
                        },
                        tooltip: "Edit",
                        ImgSrc: () => <EDIT />,
                        isDisable: !isEditor,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />
                <Buttons
                    props={{
                        tooltip: `Show Lineage`,
                        buttonText: 'Lineage',
                        buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon",
                        buttonEvent: (e) => {

                            e.stopPropagation();
                            let path = `/viewLineage/scheduler/${item.id}`;

                            window.open(path)

                        },
                        ImgSrc: () => <LINEAGE_ICON />,

                        buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                    }}

                />
                <Popover
                    content={
                        <div onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}

                            style={{ display: "flex", padding: "10px", alignItems: "center" }}>
                            <Tooltip title={renameText[id] ?? `${item.name}_copy`}>
                                <Input value={renameText[id] ?? `${item.name}_copy`} onChange={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setRenameText({ ...renameText, [id]: e.target.value })
                                }} />
                            </Tooltip>
                            <Buttons
                                props={{
                                    buttonText: "",
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1",
                                    buttonEvent: (e) => {
                                        e.preventDefault();
                                        handleSaveClick(item);
                                        // updateDocumentHandler();
                                    },
                                    ImgSrc: () => <SAVE />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_SECONDARY,
                                }}
                            />
                        </div>

                    }


                    title={
                        <div onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                            style={{ display: "flex", padding: "10px", justifyContent: "space-between" }}><span>Create a Copy</span> <Buttons
                                props={{
                                    buttonText: "",
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setVisible({ ...renameText, [id]: false });
                                    },
                                    ImgSrc: () => <CLOSE />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_TERTIARY,
                                }}
                            />
                        </div>

                    }
                    onOpenChange={(visible) =>
                        setVisible({ ...renameText, [id]: visible }
                        )}
                    trigger="click"
                    open={(isEditor && visible[id]) ?? false}
                >

                    <Buttons
                        props={{
                            buttonText: "",
                            buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon ",
                            buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setRenameText({ ...renameText, [item.id]: item?.name + '_copy' })
                                setVisible({ ...visible, [id]: true });
                            },
                            ImgSrc: () => <COPY />,
                            tooltip: "Duplicate",
                            isDisable: !isEditor,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </Popover>
                    <Buttons
                        props={{
                            buttonText: "",
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon error-red",
                            buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if(!isEditor) return
                                onDelete(id)
                            },
                            ImgSrc: () => <DELETEICON />,
                            tooltip: "Delete",
                            isDisable: !isEditor,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                {/* <Tooltip placement={"bottom"} title="Delete">
                    <button
                        disabled={!isEditor}
                        className={
                            "custom-btn-outline custom-btn btn-with-icon error-red"
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onDelete(id)
                        }}
                    >
                        <DELETEICON />
                    </button>
                </Tooltip> */}
            </div>
        </>
    }
}
