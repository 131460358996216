import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { connect } from 'react-redux'

import _ from 'lodash'
import { getGlobalConnectionCache } from '../../../store/modules/UserManagement/connection/connectionActions'
import { ALERT, DASHBOARD, DATADOMAIN, DELETEICON, EDIT, NOTIFICATION, PLUS } from '../../Common/iconSource'
import { Literals } from '../common/literals'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip } from 'antd'
import { deleteDashboardApi, editDashboardApi, getDashboardListApi } from '../../../store/modules/analytics/dashboard/dashboardActions'
import { useNavigate } from 'react-router-dom'
import { getDataDomainList, setSidebarActiveRoute } from '../../../store/actions'
import { DeleteNoteContent, ErrorComponent, NoDataComponent, getChartType, getDeleteMessage, getRelativeTime, permissions, showSimpleLoading } from '../common/helperFunctions'
import Buttons from '../../Common/button/Buttons'

import LandingPageSubHeader from '../../Common/landingPageSubHeader/LandingPageSubHeader'
import { LandingSimpleTable } from '../../Common/landingSimpleTable/LandingSimpleTable'
import NoSearchResultFound from '../common/NoSearchResultFound'
import NewFlowsPopUp from '../flows/createFlows/NewFlowsPopUp'
import ActionPopUp from '../common/actionPopUp'
import LoadingComponent from '../common/loadingComponent'
import LandingpageFirstHeader from '../common/LandingpageFirstHeader'
import EditDashBoard from './EditDashBoard'
import './DashboardIndex.scss'
import Filter from '../../Common/filter/Filter'
import DashboardNameModal from './DashboardNameModal'
import { ICON_DASHBOARD } from '../../Common/newIconSource'
import DeleteModal from '../../Common/deleteModal/DeleteModal'

function DashboardIndex(props) {
  let { fromRoute = 'Flows', loadingStatus } = props
  const [openModal, setOpenModal] = useState(false)
  const history = useNavigate()
  const store = useStore()

  const [showLoadingMsg, setshowLoadingMsg] = useState(true)
  const [searchData, setSearchData] = useState('');
  const flowFilters = useSelector(state => state.App)
  const [errMsg, setErrorMsg] = useState("");
  const [responseData, setresponseData] = useState([])
  const [editURL, seteditURL] = useState('')
  const [showIframe, setShowIframe] = useState(false)
  const [selectedDashboardName, setSelectedDashboardName] = useState('')
  const [activeTab, setActiveTab] = useState("");
  const [tabsMenuList, settabsMenuList] = useState([]);
  const [dataDomainList, setdataDomainList] = useState([]);

  const currentActiveTab = useRef('')
  const currentDashboardIdRef = useRef({ current: {} })

  var VISUALIZATION_BASE_URL = window._env_.REACT_APP_VISUALIZATION_BASE_URL;

  const [filterList, setfilterList] = useState([{

    header: '',
    selectAll: false,
    list: [{ id: 1, checked: false, name: 'Created By Me' }]
  },


  {
    header: 'Data Domain',
    selectAll: false,
    list: [],
  },
  {
    header: 'Dashboard Name',
    selectAll: false,
    list: [],
  },
  ])



  let TableHeight;
  if (window.innerHeight >= 750 && window.innerHeight < 1000) {
    TableHeight = Math.round((window.innerHeight - 280))
  } else if (window.innerHeight >= 1000) {
    TableHeight = Math.round((window.innerHeight - 250))
  } else {
    TableHeight = Math.round(window.innerHeight - 200)
  }

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
  const [listData, setlistData] = useState([])

  const [showListView, setshowListView] = useState(true)
  const [showGridView, setshowGridView] = useState(false)

  const [showFilter, setshowFilter] = useState(false)
  const [selectedFilters, setselectedFilters] = useState([])

  const dispatch = useDispatch();
  const [empty, setEmpty] = useState(false);


  useEffect(() => {
    dispatch(getGlobalConnectionCache())

    dispatch(setSidebarActiveRoute('/dashboard'))

  }, [])


  const openDeleteDialog = (id) => {

    currentDashboardIdRef.current = id

    // setDeletePopUpContent({
    //   modalClass: "delete-popup",
    //   heading: "Delete",
    //   message: DeleteNoteContent(id, 'Dashboard', '', `Are you sure ? `, false),
    //   // headingIcon: "bx bx-error",
    //   button: [
    //     {
    //       name: "Delete",
    //       action: () => {
    //         dispatch(deleteDashboardApi(id)).then((rsp) => {
    //           if (rsp.status === 'success') {
    //             closeDeletePopUp();

    //             if (dataDomainList?.length) {
    //               let findItem = dataDomainList.find((item) => item.data_domain_id?.toString() === currentActiveTab.current?.toString())
    //               if (findItem) {
    //                 // setlistData([])
    //                 getDashboardList(findItem.data_domain_name)
    //               }
    //             }

    //           }
    //         })
    //       },
    //     },
    //   ],
    //   elements: [],
    //   closeActionPopup: closeDeletePopUp,
    // });
    setOpenDeletePopup(true);
  };

  useEffect(() => {

    if (responseData?.length > 0 && !_.isEqual(flowFilters.Filters.flow?.selectedFilters ?? [], selectedFilters)) {
      setselectedFilters(flowFilters.Filters.flow?.selectedFilters)
    }
  }
    , [responseData])

  const handleDelete = id => {
    dispatch(deleteDashboardApi(id)).then((rsp) => {
      if (rsp.status === 'success') {
        closeDeletePopUp();

        if (dataDomainList?.length) {
          if (currentActiveTab?.current === '') {
            currentActiveTab.current = activeTab
          }
          let findItem = dataDomainList.find((item) => item.data_domain_id?.toString() === currentActiveTab.current?.toString())
          if (findItem) {
            // setlistData([])
            getDashboardList(findItem.data_domain_name)
          }
        }

      }
    })
      .catch(() => {

      })
  }

  const setshowFilterPopup = () => {
    setshowFilter(!showFilter)
  }
  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };
  const setData = (data) => {
    if (data && data?.length) {


      let data_arr = data.map((ele) => {

        const isEditor = !!ele?.permission?.find(v => v === permissions.dashboard.editor)

        return {
          dashboard_name: <div className='flexible-search-wrap d-flex align-items-center font-w-600 text-uppercase'><label className='pr-4 mb-0'>   <span className="primary-svg-color">  <ICON_DASHBOARD height='18' width='24' /></span></label><Tooltip placement="bottom" title={ele?.name}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.name ? ele?.name : ''} /></Tooltip></div>,
          description: ele.description ? <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={`${ele?.description?.slice(0, 300)}...`}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.description ? ele?.description : ''} /></Tooltip></span> : <span style={{ marginLeft: "0px" }}>No description has been given yet.</span>,
          created_on: <Tooltip placement="top" title={getRelativeTime(ele?.created_on)}>{getRelativeTime(ele?.created_on)}</Tooltip>,
          // created_on: <Tooltip placement="right" title={getRelativeTime(ele?.created_on)}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={getRelativeTime(ele?.created_on) ? getRelativeTime(ele?.created_on) : ''} /></Tooltip>,
          created_by: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={ele.created_by}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele.created_by ? ele.created_by : ''} /></Tooltip></span>,
          updated_on: getRelativeTime(ele?.updated_on),
          updated_by: <span className='flexible-search-wrap'><Tooltip placement="bottom" title={ele?.updated_by}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.updated_by ? ele?.updated_by : ''} /></Tooltip></span>,
          data_domain: (
            ele?.data_domain?.length === 0 ? <span className='text-black-50'>N/A</span>
              :
              <Tooltip
                placement="top"
                title={ele.data_domain?.join(",")?.toUpperCase()}
                className="text-capitalize"
              >
                <Highlighter
                  searchWords={[searchData?.toLocaleLowerCase()]}
                  autoEscape={true}
                  textToHighlight={
                    ele.data_domain && ele.data_domain.length ? ele.data_domain?.join(", ")?.toUpperCase() : ""
                  }
                >

                </Highlighter>
              </Tooltip>
          ),
          icon: <div className='d-flex align-items-center justify-content-center h-100'>
            <Space size={4} className='justify-content-end h-100'>

              <Tooltip placement='bottom' title={'Edit'}>
                <Buttons
                  props={{
                    tooltip: 'Edit',
                    tooltipPlacement: 'bottom',
                    buttonText: "",
                    buttonClassName: "custom-btn custom-btn-outline table-icon edit-icon",
                    buttonEvent: (e) => {
                      e.stopPropagation();
                      let accessToken = JSON.parse(localStorage.getItem('user'))?.token
                      let editURL = `${VISUALIZATION_BASE_URL}login?token=${accessToken}&next=${VISUALIZATION_BASE_URL}superset/dashboard/${ele.id}/?edit=true&standalone=1`;
                      seteditURL(editURL);
                      setSelectedDashboardName(ele?.name)
                      history(Literals.links.EDIT_DASHBOARD, { state: { dashboardURL: editURL } })
                      setShowIframe(true)
                    },
                    ImgSrc: () => <EDIT />,
                    isDisable: !isEditor,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />

              </Tooltip>

              <Tooltip placement='bottom' title={'Delete'}>
                <Buttons
                  props={{
                    tooltip: 'Delete',
                    tooltipPlacement: 'bottom',
                    buttonText: "",
                    buttonClassName: "custom-btn custom-btn-outline table-icon error-red",
                    buttonEvent: (e) => { e.stopPropagation(); openDeleteDialog(ele?.id) },
                    ImgSrc: () => <DELETEICON />,
                    isDisable: !isEditor,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />
                {/* <button disabled={!isDelete} className="custom-btn custom-btn-outline table-icon error-red" onClick={(e) => { e.stopPropagation(); openDeleteDialog(ele?.id) }}><DELETEICON /></button> */}
              </Tooltip>
            </Space>
          </div>,
          key: ele.id,

          allData: ele,

        }

      })
      setlistData([...data_arr])
    } else {
      setlistData([])

    }
  }


  const getDataDomainList1 = (data) => {
    const dataDomainList = new Set();
    data.map(item => {
      for (let i = 0; i < item?.data_domain?.length; i++) {
        dataDomainList.add(item?.data_domain[i])
      }
    });

    const tempList = [...dataDomainList];

    createDomainFilterList(tempList, false, {})

  }


  const createDomainFilterList = (list = [], isSelectedFilter = false, selectedItem) => {


    if (!isSelectedFilter && list.length) {
      let domainFilterList = [];
      list.forEach((item, index) => domainFilterList.push({ id: index, checked: false, name: getChartType(item), }));

      let temp = [...filterList];

      temp[1]['list'] = domainFilterList;


      setfilterList([...temp])

    } else {
      let temp = [...filterList];

      temp[1]['list'].forEach((item, index) => {
        if (item?.name?.toLowerCase() === selectedItem?.name?.toLowerCase()) {
          item.checked = selectedItem.checked;
        }
      });
      setfilterList([...temp])
    }

  }

  const filterData = () => {
    let dataDomain = []
    let favourites = []
    const myMail = store.getState().UserReducer.user.userDetails.email;
    let currentActiveTab = store.getState().FlowsReducer.currentActiveTabFlowLanding

    if (selectedFilters.length > 0) {
      selectedFilters.forEach(val => {
        if (val['Data Domain']) {
          dataDomain = val['Data Domain'].map(val => val.name) || []
        }
        if (val['emptyheader']) {
          favourites = val['emptyheader'].map(val => val.name) || []
        }
      })
    }
    let oldObj = []
    // if (currentActiveTab === 'favourite' && favouriteList.length) {
    //     oldObj = [...favouriteList]
    // } else { 

    oldObj = [...responseData]
    // }

    oldObj.forEach(val => {

      if (favourites.length) {

        if (favourites.includes('Created by me') && favourites.includes('Favourites')) {

          oldObj = oldObj.filter(a => a.created_by?.toLowerCase().includes(myMail.toLowerCase()) && a?.bookmark === true);

        } else if (favourites.includes('Created by me')) {



          oldObj = oldObj.filter(a => a.created_by?.toLowerCase().includes(myMail.toLowerCase()))


        }
      }
      if (dataDomain.length) {
        oldObj = oldObj.filter(a => {
          return a?.data_domain?.length && a.data_domain.find((item, i) => {
            return dataDomain.includes(item)
          });
        })
      }

    })
    if (selectedFilters.length) {
      return (oldObj)
    }
    //setAlertData(oldObj)
    else {
      return (responseData)
    }
    //setAlertData(alertDataClone)

  }



  const getDashboardList = (domain_name) => {

    store.dispatch(getDashboardListApi(domain_name)).then((resp, reject) => {

      if (resp.data?.length <= 0) {
        setEmpty(true);
      }
      if (resp && resp.data.length) {
        setshowLoadingMsg(false)
        setEmpty(false);
        if (resp.data[0].view_type == "grid") {
          setshowGridView(true);
          setshowListView(false)

        }
        else {
          setshowListView(true)
          setshowGridView(false);

        }
        setresponseData(resp.data)
        getDataDomainList1(resp?.data);
        setData(resp.data)
      } else {
        setshowLoadingMsg(false)
        setEmpty(true);
        setlistData([])

      }

    }).catch((err) => {
      setshowLoadingMsg(false)
      setEmpty(false);

      setErrorMsg(err?.message)
    })
  }
  useEffect(() => {

    // getDashboardList()
    getDomainList()
  }, [])

  const getDomainList = async () => {
    let res = await dispatch(getDataDomainList());
    let temp = [...res.data];
    setdataDomainList([...temp])
    let tabsMenuList1 = []
    if (temp && temp.length) {
      tabsMenuList1 = temp.map((element, i) => ({
        key: element.data_domain_id.toString(),
        tabTitle: <div className='d-flex align-items-center'><DATADOMAIN height='15' width='15'   ></DATADOMAIN><div style={{

          // fontWeight: '600',
          fontFamily: "InterMedium"
        }} className='ml-2'>{element.data_domain_name?.toUpperCase()}</div></div>,
        tabContent: null,
        closable: false,

      }))
      setActiveTab(temp[0]?.data_domain_id?.toString())
      settabsMenuList([...tabsMenuList1]);
      getDashboardList(temp[0]?.data_domain_name)
    }
  }
  useEffect(() => {
    if (filterData().length && searchData !== '') {
      let filteredData = filterData().filter((data) => {
        return (data?.name?.toLowerCase().includes(searchData?.toLowerCase()) ||
          data?.description?.toLowerCase().includes(searchData?.toLowerCase()) ||
          getRelativeTime(data?.created_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
          data?.created_by?.toLowerCase().includes(searchData?.toLowerCase()) ||
          getRelativeTime(data?.updated_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
          data?.updated_by?.toLowerCase().includes(searchData?.toLowerCase())) ||
          data?.data_domain?.find((item, i) => {

            return (item?.toLowerCase().includes(searchData?.toLowerCase()))

          });
      })
      setData(filteredData)
    } else {
      setData(filterData())

    }


    // getVirtualTableRecords(data,columns)
  }, [searchData, selectedFilters])

  let columns = [
    {
      name: <span style={{ paddingLeft: '48px' }}>Dashboard Name</span>,
      dataIndex: "dashboard_name",

      resizable: true,
      key: "dashboard_name",

      width: "20%"
    },
    // {
    //   name: Literals.DESCRIPTION,
    //   dataIndex: "description",
    //   key: "description",
    //   width: "30%",
    //   resizable: true,
    //   className: "description",

    //   sortable: false

    // },

    {
      name: 'Data Domains',
      dataIndex: "data_domain",
      key: "data_domain",
      resizable: false,
      sortable: false

    },
    {
      name: Literals.CREATED_ON,
      dataIndex: "created_on",
      key: "created_on",
      resizable: false,

    },
    {
      name: Literals.CREATED_BY,
      dataIndex: "created_by",
      key: "created_by",
      resizable: false,

    },

    {
      name: Literals.UPDATED_BY,
      dataIndex: 'updated_by',
      key: "updated_by",

    },
    {
      dataIndex: 'icon',
      resizable: false,
      width: "12%",
      name: <div className='text-center'>Action</div>,
      key: 'icon',
      sortable: false,


    }
  ];



  const onRowSelection = (record, rowIndex, e) => {
    let accessToken = JSON.parse(localStorage.getItem('user'))?.token

    let viewURL = `${VISUALIZATION_BASE_URL}login?token=${accessToken}&next=${VISUALIZATION_BASE_URL}superset/dashboard/${record?.allData?.id}/?standalone=1`;

    seteditURL(viewURL);
    setSelectedDashboardName(record?.allData?.name)
    history(Literals.links.VIEW_DASHBOARD, { state: { dashboardURL: viewURL } })
    setShowIframe(true)

  }

  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'dashboard_name':
        return (a, b) => a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight) : null
      case 'created_on':
        return (a, b) => a[sortColumn]?.props?.title !== undefined ? a[sortColumn]?.props?.title?.localeCompare(b[sortColumn]?.props?.title) : null
      case 'created_by':
        return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
      case 'updated_by':
        return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }

  const getFormattedData = (allData = []) => {

    let dataDomain = []
    let favourites = []
    if (selectedFilters.length > 0) {
      selectedFilters.forEach(val => {
        if (val['Data Domain']) {
          dataDomain = val['Data Domain'].map(val => val.name) || []
        }
        if (val['emptyheader']) {
          favourites = val['emptyheader'].map(val => val.name) || []
        }
      })
    }

    let categories = [];

    const dataDomainList = new Set();
    allData.map(item => {
      for (let i = 0; i < item?.data_domain?.length; i++) {
        dataDomainList.add(item?.data_domain[i])
      }
    });

    dataDomainList.forEach((name, key) => categories.push({ id: key, checked: dataDomain.includes(name), name: name, category: name }))

    const obj2 = {
      header: 'Data Domain',
      selectAll: false,
      list: categories
    }
    const obj = {
      header: '',
      list: [{ id: 1, checked: favourites.length > 0 && favourites.includes('Created by me'), name: 'Created by me' }]
    }
    return [obj, obj2]
  }
  const getFiltersStatus = () => {
    let filters = [...selectedFilters],
      status = null
    if (filters.length === 0) status = false
    else if (filters.length > 0) {
      status = !filters.map(v => v[Object.keys(v)[0]].length > 0).every(v => v === false)
    }

    return status

  }
  const onTabChange = (key) => {

    setActiveTab(key);
    currentActiveTab.current = key
    if (dataDomainList?.length) {
      let findItem = dataDomainList.find((item) => item.data_domain_id?.toString() === key?.toString())
      if (findItem) {
        // setlistData([])
        getDashboardList(findItem.data_domain_name)
      }
    }
  }


  return (
    <React.Fragment>

      <LandingpageFirstHeader title={Literals.DASHBOARD} flag={false} backRoute={"/dashboard"} />
      {/* {showIframe ? <EditDashBoard dashboardName={selectedDashboardName} setShowIframe={setShowIframe} dashboardURL={editURL}></EditDashBoard> : */}
      <div className='dashboard-index-page'>
        <LandingPageSubHeader props={{ hideToggle: true, tabsMenuList: tabsMenuList, activeTab: activeTab, defaultActiveTab: activeTab, onTabChange: onTabChange, isDashboardIndex: true, setSearchData: setSearchData, moduleType: "flow", defaultValue: showListView ? 'listView' : 'gridView', setshowFilterPopup: setshowFilterPopup, setshowGridView: setshowGridView, setshowListView: setshowListView, isFilterApplied: getFiltersStatus(), fromRoute: fromRoute, isDisableAllOptions: empty }} />
        <Buttons props={{
          tooltip: 'Create Dashboard',
          buttonText: '', buttonClassName: "custom-btn-primary custom-btn fixed-button", buttonEvent: () => {
            setOpenModal(true)

          }, ImgSrc: () => <PLUS />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
        }} />
        {errMsg?.length > 0 ? <ErrorComponent msg={errMsg} /> : listData && listData?.length ? <div className="flow-landing-content">



          {
            showListView &&
            <div className="padding-2">
              <LandingSimpleTable
                cols={columns}
                rowsData={listData}
                autoWidth={true}

                onRowClick={(record, index, e) => { onRowSelection(record, index, e) }}
                getComparator={getComparator}
                tableHeight={'calc(100vh - 124px)'}
              // tableHeight={TableHeight}
              /> </div>
          }


        </div> :
          empty ?
            <NoDataComponent logo={<ICON_DASHBOARD width="60" height="60" color="black" />}
              message=" Click On (+) To Create Dashboard" /> :

            (!loadingStatus ? (searchData !== '' || selectedFilters.length) &&
              (listData && !listData?.length) ? <NoSearchResultFound mainMsg={searchData === '' ? 'No Flow List Found' : ''} subMsg={'Please try Search / Filter with another term'} />
              : showLoadingMsg ? showSimpleLoading("Please Wait Loading Dashboard List...") : null : null)}

        <DashboardNameModal openModal={openModal} setOpenModal={setOpenModal} seteditURL={seteditURL} setShowIframe={setShowIframe} />
        {props.loadingStatus ? <LoadingComponent /> : null}

      </div>
      {/* } */}
      {showFilter ? <Filter props={{ filterList: getFormattedData(responseData), type: "flow", selectedFilters: selectedFilters, setselectedFilters: setselectedFilters, setshowFilter: setshowFilter }} /> : null}

      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={getDeleteMessage({
          title: 'Dashboard'
        })}
        onConfirm={() => handleDelete(currentDashboardIdRef?.current)}
      />

      {/* <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent} /> */}



    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    loadingStatus: state.LoadingReducer.loadingStatus,
  };
};
export default connect(mapStateToProps)(DashboardIndex)
