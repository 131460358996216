
import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import {
  DICTIONARY_GRAPH_LOADER, EDIT_DICTIONARY_SEARCH, EDIT_DICTIONARY_TABLE, EDIT_HEADER_DESCRIPTION, EXPAND_DICTIONARY_TABLE, RESET_GRAPH_SAMPLE_SELECTED, RESET_REDUX_STATE, SAVE_APPLIED_FILTERS, SAVE_EDIT, SET_DICIONARY_DEFAULT, SET_DICTIONARY_TABLE_DATA, SET_GRAPH_DATA, SET_GRAPH_SAMPLE_SELECTED, SET_INITIAL_CATALOGUE_TAB, SET_PREVIEW_POD_DATA, UNABLE_GRAPH_VIEW
} from "./dictionaryActionTypes";

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const dictionaryEndpoint = (table_id, loadPreview, showLoader = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    dispatch(setDictionaryTableData([], [], ""));
    dispatch(setGraphData([]));
    const searchParams = new URLSearchParams(document.location.search)
    const history_id = searchParams.get("history_id");
    const start_date = searchParams.get("start_date");
    const end_date = searchParams.get("end_date");

    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}catalog_home/v2/${table_id}/${history_id ? `?history_id=${history_id}&start_date=${start_date}&end_date=${end_date}` : ""}`))
        .then((resp) => {
          if (
            resp.status === "success"
          ) {
            setDictionaryData(resp.data, dispatch, loadPreview);
            if (showLoader) {
              dispatch(setLoadingStatus(false));
            }
            resolve(resp);
          } else {
            if (showLoader) {
              dispatch(setLoadingStatus(false));
            }
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (err?.status !== 404) {

            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const getConnectionList = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}connection`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {


            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

        });
    });
  };
};
export const getConnectionListV2 = (showLoader = true) => {
  return (dispatch) => {
    if (showLoader) {

      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}connection/`))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {


            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err.message);
          reject(err)
        });
    });
  };
};

const setDictionaryData = (data, dispatch, loadPreview) => {
  const podData = data;
  const podColumnCount = data?.column_count;
  const podColumnDetail = data?.column_detail;
  dispatch(
    setDictionaryTableData(podData, podColumnDetail, podColumnCount)
  );

};

export const duplicate = (payload, type, tableId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoadingStatus(true));
      dispatch(fetchData("POST", `${BASE_URL}${type}/${tableId}/copy/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            emitToastNotification('success', resp.message);
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
          dispatch(setLoadingStatus(false));
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        })
    });
  };
}

export const saveEditTableV2 = (payload, tableId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("PUT", `${BASE_URL}pod_crud/v2/${tableId}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            emitToastNotification('success', resp.message);
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            dispatch(saveEditFlag(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(saveEditFlag(false));
          reject(err);
        });
    });
  };
};

export const saveEditTable = ({ payload }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}pod_crud`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            emitToastNotification('success', resp.message);
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            dispatch(saveEditFlag(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(saveEditFlag(false));
          reject(err);
        });
    });
  };
};

export const fetchGraphData = ({ payload }) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoadingStatus(true));
      dispatch(fetchData("POST", `${BASE_URL}column_stat`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};
export const fetchGraphDataV2 = (table_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoadingStatus(true));
      dispatch(fetchData("GET", `${BASE_URL}column_stat?table_id=${table_id}`))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          if (err?.message === 'Pod has not been analyzed yet. Run refresh button for stats') {

            emitToastNotification('warning', err?.message);
          } else {
            emitToastNotification('error', err?.message);

          }
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};
export const fetchColummGraphData = (table_id, column_id, showError = true) => {

  return (dispatch) => {
    return new Promise((resolve, reject) => {


      dispatch(fetchData("GET", `${BASE_URL}column_stat?table_id=${table_id}&column_id=${column_id}`))
        .then((resp) => {
          if (resp.status === "success") {

            resolve(resp);
          } else {
            if (showError) {
              emitToastNotification('error', resp.message);
            }

            reject(resp?.message);
          }
        })
        .catch((err) => {
          if (err?.message === 'Pod has not been analyzed yet. Run refresh button for stats') {
            if (showError) {
              emitToastNotification('warning', err?.message);
            }
          }
          else {
            if (showError) {
              emitToastNotification('error', err?.message);
            }

          }

          reject(err);
        });
    });
  };
};

export const refreshGraphData = ({ payload }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoadingStatus(true));
      dispatch(fetchData("POST", `${BASE_URL}column_stat`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            emitToastNotification('info', resp?.message);
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }

        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};
export const refreshGraphDataV2 = ({ payload }, table_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {

      dispatch(fetchData("POST", `${BASE_URL}column_stat?table_id=${table_id}`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            emitToastNotification('info', resp?.message);
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }

        })
        .catch((err) => {
          emitToastNotification('error', err?.message);

          reject(err);
        });
    });
  };
};
export const refreshRowCount = (payload, table_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {

      dispatch(fetchData("POST", `${BASE_URL}column_stat?table_id=${table_id}&calc_stats=1`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            // emitToastNotification('info', resp?.message);
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};


export const retrieveRowCount = (table_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}catalog_home/v2/${table_id}/retreive_row_count/`))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const resetDictionaryRedux = () => ({
  type: RESET_REDUX_STATE,
  payload: {},
});

export const setDictionaryTableData = (podData, podColumnDetail, columnCount) => ({
  type: SET_DICTIONARY_TABLE_DATA,
  payload: { podData, podColumnDetail, columnCount },
});

export const setPreviewTableData = (podData, tableData, columnCount) => ({
  type: SET_PREVIEW_POD_DATA,
  payload: { podData, tableData, columnCount },
});

export const setAppliedFiltersDictionaryLanding = (data) => ({
  type: SAVE_APPLIED_FILTERS,
  payload: data,
});


export const expandDictionaryTable = (value) => ({
  type: EXPAND_DICTIONARY_TABLE,
  payload: value,
});

export const editDictionaryTable = (value) => ({
  type: EDIT_DICTIONARY_TABLE,
  payload: value,
});
export const saveEditFlag = (value) => ({
  type: SAVE_EDIT,
  payload: value,
});
export const setGraphData = (graphData) => ({
  type: SET_GRAPH_DATA,
  payload: { graphData },
});
export const editHeaderDescription = (value) => ({
  type: EDIT_HEADER_DESCRIPTION,
  payload: value,
});
export const editDictionarySearch = (value) => ({
  type: EDIT_DICTIONARY_SEARCH,
  payload: value,
});
export const unableGraphView = (value) => ({
  type: UNABLE_GRAPH_VIEW,
  payload: value,
});
export const loadDictionaryGraph = (value) => ({
  type: DICTIONARY_GRAPH_LOADER,
  payload: value,
});

export const setDictionaryDefault = () => {
  return {
    type: SET_DICIONARY_DEFAULT,
  };
};

export const setInitialCatalogueTab = (payload) => {
  return {
    type: SET_INITIAL_CATALOGUE_TAB,
    payload,
  };
};

export const setGraphSampleSelected = (payload) => {
  return {
    type: SET_GRAPH_SAMPLE_SELECTED,
    payload,
  };
};

export const resetGraphSampleSelected = () => {
  return {
    type: RESET_GRAPH_SAMPLE_SELECTED,
    payload: 1000,
  };
};

export const getPodChartList = (table_id) => {
  if (table_id === undefined) return
  return (dispatch) => {
    // dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}chart/?table_id=${table_id}`))
        .then((resp) => {
          // dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          // dispatch(setLoadingStatus(false));
          emitToastNotification('error', err.message);
          reject(err)
        });
    });
  };
};

export const deleteChart = (chart_id) => {
  if (chart_id === undefined) return
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${BASE_URL}chart/${chart_id}/`))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err.message);
          reject(err)
        });
    });
  };
};

export const bookmarkChart = (type, reportId) => {

  return (dispatch) => {
    // dispatch(setLoadingStatus(true));

    return new Promise((resolve, reject) => {

      dispatch(
        fetchData(type, `${BASE_URL}chart/${reportId}/bookmark/`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status !== 'success') {

            reject(resp.message)
          } else {

            emitToastNotification('success', resp.message)

            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        })
    });
  };
};
