import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { setTagsList } from "../../../../../store/modules/accessManagement/actions";
import Buttons from "../../../../Common/button/Buttons";
import { CLOSE, TICK, USERMANAGEMENT } from "../../../../Common/iconSource";
import SearchInput from "../../../../Common/search/Search";
import PodTaglist from "../../../analytics/components/PodTaglist";
import { Literals } from "../../../common/literals";
import "./UserGroupModal.scss";
import UserGroupSelectionTable from "./UserGroupSelectionTable";
import { ICON_USERS_GROUP } from "../../../../Common/newIconSource";

const UserGroupModal = ({
  isModalOpen,
  toggleModal,
  domainName,
  selectedUserGroups,
  setSelectedUserGroups,
}) => {
  const dispatch = useDispatch();
  const { tagsList, userGroups } = useSelector(
    (state) => state.AccessManagement
  );

  const [selectedUserGroupsTemp, setSelectedUserGroupsTemp] = useState([]);
  const [searchData, setSearchData] = useState("");

  const removeGroupFromSelectedGroups = (groupId) => {
    setSelectedUserGroupsTemp((groups) => {
      const newGroups = [...groups].filter((group) => group.id !== groupId);
      return newGroups;
    });
  };

  useEffect(() => {
    const tagsList = selectedUserGroupsTemp.map((group) => {
      return { podId: group.id, podName: group.name };
    });
    dispatch(setTagsList(tagsList));
  }, [selectedUserGroupsTemp]);

  useEffect(() => {
    setSelectedUserGroupsTemp(selectedUserGroups);
  }, [selectedUserGroups]);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedUserGroupsTemp([]);
    } else {
      setSelectedUserGroupsTemp(selectedUserGroups);
    }
  }, [isModalOpen]);

  return (
    <Modal
      size="lg"
      toggle={toggleModal}
      isOpen={isModalOpen}
      className="custom-modal user-group-modal"
      centered={true}
    >
      <ModalHeader>
        <div className="d-flex align-items-center">
          <ICON_USERS_GROUP height="20" />
          <h2 className="section-heading">All usergroups in ({domainName?.toUpperCase()})</h2>
        </div>
        <div className="tab-menu right-flow-header">
          <SearchInput searchData={searchData} setSearchData={setSearchData} />
          <Buttons
            props={{
              buttonText: "",
              buttonClassName:
                "custom-btn-outline custom-btn btn-with-icon ml-2",
              buttonEvent: () => {
                toggleModal();
              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_TERTIARY,
            }}
          />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="podtag-list">
          <PodTaglist
            tagList={tagsList}
            removeTags={removeGroupFromSelectedGroups}
          />
        </div>
        <UserGroupSelectionTable
          searchData={searchData}
          userGroups={userGroups}
          selectedUserGroups={selectedUserGroupsTemp}
          setSelectedUserGroups={setSelectedUserGroupsTemp}
        />
      </ModalBody>
      <ModalFooter>
        <Buttons
          props={{
            buttonText: "Done",
            buttonClassName: "custom-btn-primary custom-btn btn-with-text ml-2",
            buttonEvent: () => {
              toggleModal();
              setSelectedUserGroups(selectedUserGroupsTemp);
            },
            ImgSrc: () => <TICK />,
            isDisable: selectedUserGroupsTemp?.length === 0,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default UserGroupModal;
