import React from "react";
import {
  ADDACCESSMANAGEMENT,
  NOFILTER,
  NOUSERGROUP,
} from "../../../../Common/iconSource";
import "./NoDataInfo.scss";

const NoDataInfo = ({ category }) => {
  const noDataMap = {
    usergroup: {
      icon: <NOUSERGROUP />,
      title: "No Usergroups Selected",
      text: "Click on the +Usergroups button to add usergroups",
    },
    viewUserGroup: {
      icon: <NOUSERGROUP />,
      title: "No Usergroups Present",
    },
    rowLevelMasking: {
      icon: <NOFILTER />,
      title: "No Filters Added",
      text: "Click on the +Filter to add filters as row level mask",
    },
    addPolicy: {
      icon: <ADDACCESSMANAGEMENT />,
      title: "Add a Policy",
      text: "Click on the +Policy button to add policies.",
    },
    users: {
      icon: <NOUSERGROUP />,
      title: "No User Added",
      text: "Click on the '+Add' button to add User.",
    },
  };

  const componentData = noDataMap[category];

  return (
    <div className="no-data-container">
      <div className="no-data-content">
        {componentData.icon}
        <h3>{componentData.title}</h3>
        <p>{componentData.text}</p>
      </div>
    </div>
  );
};

export default NoDataInfo;
