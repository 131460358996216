export const EDIT_DESCRIPTION = "EDIT_DESCRIPTION";
export const SET_SUMMARY_POD_DATA = "SET_SUMMARY_POD_DATA";
export const SET_DOCUMENT_TABLE_DATA = "SET_DOCUMENT_TABLE_DATA";
export const SET_EDITOR_DATA = "SET_EDITOR_DATA";
export const SET_EDITOR_DATA_COUNT = "SET_EDITOR_DATA_COUNT";
export const SET_INSIGHT_DATA = "SET_INSIGHT_DATA";
export const SET_SUMMARY_TIMELINE = "SET_SUMMARY_TIMELINE";
export const SET_TIMELINE_COUNT = "SET_TIMELINE_COUNT";
export const SET_INSIGHT_DATE = "SET_INSIGHT_DATE";
export const INSIGHT_CHART_STATE = "INSIGHT_CHART_STATE";
export const SELECT_INSIGHT_CHART_VIEW = "SELECT_INSIGHT_CHART_VIEW";
export const EDIT_SUMMARY_EDITOR = "EDIT_SUMMARY_EDITOR";
export const SET_UPLOAD_DOC_FLAG = "SET_UPLOAD_DOC_FLAG";
export const SET_EDITOR_HTML = "SET_EDITOR_HTML";
export const SET_EDITOR_STATE = "SET_EDITOR_STATE";
export const RESET_SUMMARY_STATE = "RESET_SUMMARY_STATE";
export const SET_SUMMARY_PAGE_DEFAULT = "SET_SUMMARY_PAGE_DEFAULT";
export const SET_UPLOADED_FILES = "SET_UPLOADED_FILES";
export const SET_PAGE_COUNT = "SET_PAGE_COUNT";

