import React, { useState, useEffect } from "react";
import {  useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useHideSidebar from "../../../../../customHooks/useHideSidebar";
import UserGroupHeader from "../../../common/LandingpageFirstHeader";
import UserGroupSecondaryHeader from "./UserGroupSecondaryHeader";
import "./userGroup.scss";
import LoadingComponent from "../../../common/loadingComponent";
import AddUserGroupDetails from "./AddUserGroupDetails";
import AddUsersTable from "./AddUsersTable";
import { getUserGroupList } from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import { SET_ACTIVE_UM_TAB_NAME } from "../../../../../store/modules/UserManagement/userGroup/UMActionTypes";
import { Literals } from "../../../common/literals";
import { generateHtmlTitle, permissions } from "../../../common/helperFunctions";

const CreateUserGroup = () => {
  const location = useLocation();
  const pathname = location?.pathname
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loadingStatus } = useSelector((state) => state.LoadingReducer);

  const [viewMode, setViewMode] = useState("create");
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [existingUserIds, setExistingUserIds] = useState([]);
  const [isLeader, setIsLeader] = useState(false);

  const isSuperUser = useSelector(state => state.UserReducer?.user?.userDetails?.is_superuser)

  const onInputChangeHandler = (field, value) => {
    if (field === "groupName") {
      setGroupName(value);
    } else if (field === "groupId") {
      setGroupId(value);
    } else {
      setGroupDescription(value);
    }
  };

  const onChangeUserTypeHandler = (index, type) => {

    setSelectedUsers((users) => {
      const newUsers = [...users];
      newUsers[index].type = type;
      return newUsers;
    });
  };

  const fetchUserGroupDetailsHandler = async (id) => {
    try {
      const response = await dispatch(
        getUserGroupList({
          method: "GET",
          endPoint: `group_manager/group/${id}/`,
        })
      );

      if (response.status === "success") {
        onInputChangeHandler("groupName", response?.data?.name);
        onInputChangeHandler("groupDescription", response?.data?.description);
        onInputChangeHandler("groupId", response?.data?.id);
        const selectedUsers = response?.data?.user.user_detail.map((user) => {
          return { ...user, type: user.permissions[0] };
        });
        setSelectedUsers(selectedUsers);
        setExistingUserIds(selectedUsers.map((user) => user.id));
        setIsLeader(!!response?.data?.permission?.find(v => v === permissions.usergroup.leader))

        let title = generateHtmlTitle(response?.data?.name?.toUpperCase() + ' | User Group', 'User Management')
        document.title = title
      }
    } catch (error) {
        let title = generateHtmlTitle("User Group", 'User Management')
        document.title = title
     }
  };

  useHideSidebar();

  useEffect(() => {
    dispatch({ type: SET_ACTIVE_UM_TAB_NAME, payload: Literals.USERGROUP })

    if (pathname.includes("edit") && id) {
      setViewMode("edit");
    }
  }, [pathname]);

  useEffect(() => {
    dispatch({ type: SET_ACTIVE_UM_TAB_NAME, payload: Literals.USERGROUP })
    if (viewMode === "edit" && id) {
      fetchUserGroupDetailsHandler(id);
    }
  }, [viewMode, id]);

  return (
    <div className="user-groups">
      {loadingStatus && <LoadingComponent />}
      <UserGroupHeader
        title={"User Management"}
        flag={true}
        backRoute="/userManagement/?activeTab=userGroup"
      />
      <UserGroupSecondaryHeader
        viewMode={viewMode}
        groupId={groupId}
        groupName={groupName}
        groupDescription={groupDescription}
        selectedUsers={selectedUsers}
        isLeader={isLeader}
      />
      <div className="user-groups-container">
        <div className="user-groups-left-side">
          <AddUserGroupDetails
            viewMode={viewMode}
            groupName={groupName}
            groupDescription={groupDescription}
            onChange={onInputChangeHandler}
            isLeader={isLeader}
            isSuperUser={isSuperUser}
          />
        </div>
        <div className="user-groups-right-side">
          <AddUsersTable
            existingUserIds={existingUserIds}
            viewMode={viewMode}
            groupId={groupId}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            onChangeUserType={onChangeUserTypeHandler}
            isLeader={isLeader}
            isSuperUser={isSuperUser}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateUserGroup;
