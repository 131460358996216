import { RESET_CREATE_ALERT_REDUX, SET_SELECTED_ALERT_DETAILS, SET_ALERT_APPLIED_FILTERS, SET_ALERT_PREVIEW_TABLES, SET_ALERT_PREVIEW_TABLE_RESULT, SET_ALERT_QUERY_TABLES_RESULT, SET_ALERT_RESPONSE_SQL_QUERY, SET_ALERT_SQL_EDITOR_QUERY, SET_FLOWS_ALERT_TRANSFORMER_LIST, SET_SELECTED_ALERT_PODLIST, SET_SELECTED_ALERT_TRANSFORMER } from "./createAlertActiontype";
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import { setSelectedRulePODList } from "../../dataCatalogue/landingPage/landingPageActions";
import { emitToastNotification } from "../../../../helpers/toast_helper";
const BASE_URL = window._env_.REACT_APP_API_BASE_URL;
export const setSelectedAlertPODList = (list) => {
    return {
        type: SET_SELECTED_ALERT_PODLIST,
        payload: list,
    };
}
export const setSelectedAlertDetails = (list) => {
    return {
        type: SET_SELECTED_ALERT_DETAILS,
        payload: list,
    };
}

export const setAlertSqlEditorQuery = (query) => {
    return {
        type: SET_ALERT_SQL_EDITOR_QUERY,
        payload: query,
    };
}

export const setAlertSqlResponseQuery = (query) => {
    return {
        type: SET_ALERT_RESPONSE_SQL_QUERY,
        payload: query,
    };
}
export const ResetCreateAlertRedux = () => {
    return {
        type: RESET_CREATE_ALERT_REDUX,
        payload: '',
    };
}

export const setAlertPreviewTableResult = (payload) => {
    return {
        type: SET_ALERT_PREVIEW_TABLE_RESULT,
        payload: payload,
    };
}

export const setAlertPreviewTables = (payload) => {
    return {
        type: SET_ALERT_PREVIEW_TABLES,
        payload: payload,
    };
}

export const setAlertQueryResult = (payload) => {
    return {
        type: SET_ALERT_QUERY_TABLES_RESULT,
        payload: payload,
    };
}





export const setAlertAppliedFilters = (payload) => {
    return {
        type: SET_ALERT_APPLIED_FILTERS,
        payload: payload,
    };
}

export const getAlertPreviewTable = (payload, isSearchPaylaod) => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}preview`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        dispatch(setAlertSqlResponseQuery(resp.data.query));

                        resolve(resp);

                    }



                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};


export const RetrieveAlert = (id) => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        const searchParams = new URLSearchParams(document.location.search)
        const history_id = searchParams.get("history_id");
        const start_date = searchParams.get("start_date");
        const end_date = searchParams.get("end_date");
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rule/${id}/${history_id ? `?history_id=${history_id}&start_date=${start_date}&end_date=${end_date}` : ""}`, {})
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};


export const RetrieveAllMyAlerts = (page, limit, start_date, end_date) => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rule/execution_retrieve_all/?page=${page}&limit=${limit}&start_date=${start_date}&end_date=${end_date}`, {})
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};


export const getAlertAssignee = (payload) => {
    return (dispatch) => {
        // dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rule/list_assignee_user/?${payload.conditionType}=${payload.ids?.toString()}`, {})
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                    }

                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};
export const CreateAlert = (payload) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}rule/`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {
                        emitToastNotification('success', resp.message);

                        resolve(resp);
                    }


                })
                .catch((err) => {
                    emitToastNotification('error', err.message)

                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};

export const UpdateAlert = (payload, id) => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("PUT", `${BASE_URL}rule/${id}/`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {
                        emitToastNotification('success', resp.message);

                        resolve(resp);
                    }

                })
                .catch((err) => {
                    emitToastNotification('error', err.message);

                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};


export const getExcecutionRetrive = (id) => {
    return (dispatch) => {
        // dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rule/${id}/execution_retrieve/`, {})
            )
                .then((resp) => {
                    // dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                    }

                })
                .catch((err) => {
                    // dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};

export const getFlowsAlertTransformerListv1 = (id, selectedFlowDetails) => {
    return (dispatch) => {
        // dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}flow/${id}/get_alerts/`, {})
            )
                .then((resp) => {
                    // dispatch(setLoadingStatus(false));

                    resolve(resp)
                })
                .catch((err) => {
                    // dispatch(setLoadingStatus(false));
                    emitToastNotification('error', err.message)
                    dispatch({ type: SET_SELECTED_ALERT_TRANSFORMER, payload: { flowId: null, alertList: [], selectedFlowDetails: null } })

                    reject(err);
                })
        });
    };
};

export const getFlowsAlertTransformerList = (id, selectedFlowDetails) => {  //added old link and replace with v2
    return (dispatch) => {
        // dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}flow/${id}/get_alerts/`, {})
            )
                .then((resp) => {
                    // dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {
                        if (resp.data && resp.data.length) {


                            dispatch({ type: SET_SELECTED_ALERT_TRANSFORMER, payload: { flowId: id, alertList: resp.data, selectedFlowDetails: selectedFlowDetails } })
                            resolve(resp);
                        } else {

                            emitToastNotification('info', 'There are no alert transformers in the flow , select other flow')
                            dispatch({ type: SET_SELECTED_ALERT_TRANSFORMER, payload: { flowId: id, alertList: [], selectedFlowDetails: null } })

                        }

                    }

                })
                .catch((err) => {
                    // dispatch(setLoadingStatus(false));
                    dispatch(getFlowsAlertTransformerListv1(id, selectedFlowDetails)).then((resp) => {
                        if (resp.status !== 'success') {
                            emitToastNotification('error', resp.message)
    
                        } else {
                            if (resp.data && resp.data.length) {
                              
    
                                dispatch({ type: SET_SELECTED_ALERT_TRANSFORMER, payload: { flowId: id, alertList: resp.data, selectedFlowDetails: selectedFlowDetails } })
                                resolve(resp);
                            } else {
    
                                emitToastNotification('info', 'There are no alert transformers in the flow , select other flow')
                                dispatch({ type: SET_SELECTED_ALERT_TRANSFORMER, payload: { flowId: id, alertList: [], selectedFlowDetails: null } })
    
                            }
    
                        }
    
                    }).catch(()=>{
                        
                    }
                
                    )
                  
                })
        });
    };
};