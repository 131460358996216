import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    CardText,
    
  } from "reactstrap";
  import {Tooltip} from 'antd';
  import React from 'react';
  import Buttons from "../../../Common/button/Buttons";
import { ACCORDIONDOWNARROW, REDIRECTJOBS } from "../../../Common/iconSource";
import { Literals } from "../../common/literals";
import SourceTypeImage from "../../common/sourceTypeImage";
import { getNodeTypeIcon, useCenteredTree } from './helpers';
  
  export default [
    {
      id: "1",
      type: "input",
      data: {
        label: (
          <>
            Welcome to <strong>React Flow!</strong>
          </>
        )
      },
      position: { x: 250, y: 0 }
    },
    {
      id: "2a",
      type: "input",
      data: {
        label: <>Parent 4</>
      },
      position: { y: 0, x: 850 }
    },
    {
      id: "12",
      type: "default",
      data: {
        label: "Parent 2"
      },
      position: { x: 450, y: 0 }
    },
    {
      id: "13",
      type: "input",
      data: {
        label: "Parent 3"
      },
      position: { x: 650, y: 0 }
    },
    {
      id: "2",
      data: {
        label: (
          <>
            This is a <strong>default node</strong>
          </>
        )
      },
      position: { x: 100, y: 100 }
    },
    {
      id: "3",
      data: {
        label: (
          <>
            This one has a <strong>custom style</strong>
          </>
        )
      },
      position: { x: 400, y: 100 },
      style: {
        background: "#D6D5E6",
        color: "#333",
        border: "1px solid #222138",
        width: 180
      }
    },
    {
      id: "4",
      position: { x: 250, y: 200 },
      data: {
        label: "Another default node"
      }
    },
    {
      id: "5",
      data: {
        label: "Node id: 5"
      },
      position: { x: 250, y: 325 }
    },
    {
      id: "6",
      type: "output",
      data: {
        label: (
          <>
            An <strong>output node</strong>
          </>
        )
      },
      position: { x: 100, y: 480 }
    },
    {
      id: "customNode",
      type: "default",
      style:{padding:"0px !important",border:"none"},
      data: {
        label: (
          <Card className="lin-tree-node custom-card p-0">
          <CardBody className="p-2">
            <div className="align-items-center d-flex">
            <div className="lin-icon-wrap">

               
                     { getNodeTypeIcon("flow")}
              </div>
                
              <div className={` ml-2 w-100`}>
                <Tooltip placement="topLeft" title={""}>
                
                 <p className="mb-0 text-with-ellipsis lin-tree-node-heading">HELLO</p>
                
                </Tooltip>
                <Tooltip  placement="topLeft" title={""}>
  <p className="mb-0 small text-black-50 lin-tree-node-description">
                 Pod
                 </p>
                </Tooltip>
              </div>
            </div>
        
          </CardBody>
          <CardFooter className={`d-flex justify-content-between p-2 bg-white`}>
           
  
            
         
              <Buttons 
                props={{
                    tooltip:  'Expand',
                    buttonText: '', 
                    buttonClassName: "custom-btn custom-btn-outline btn-with-icon ml-2", 
                    buttonEvent: () => {
                     
          
                      
                    }, 
                    ImgSrc: () => <span ><ACCORDIONDOWNARROW /></span>, 
                    isDisable: false, 
                    buttonType: Literals.BTN_SECONDARY
                }} 
              />
             
            
          
            
  
           
            
          </CardFooter>
        </Card>
        )
      },
      position: { x: 850, y: 580 }
    },
    {
      id: "7",
      type: "output",
      data: { label: "Another output node" },
      position: { x: 400, y: 450 }
    },
  
    { id: "e1-2", source: "12", target: "2", label: "this is an edge label" },
    {
      id: "e12-3",
      source: "1",
      target: "3"
    },
    {
      id: "e13-3",
      source: "13",
      target: "3"
    },
    { id: "e1-3", source: "1", target: "3" },
    {
      id: "e3-4",
      source: "3",
      target: "4",
      animated: true,
      label: "animated edge"
    },
    {
      id: "e4-5",
      source: "4",
      target: "5",
      arrowHeadType: "arrowclosed",
      label: "edge with arrow head"
    },
    {
      id: "e2a-5",
      source: "2a",
      target: "5"
    },
    {
      id: "e2a-6",
      source: "2a",
      target: "customNode"
    },
    {
      id: "e5-6",
      source: "5",
      target: "6",
      type: "smoothstep",
      label: "smooth step edge"
    },
    {
      id: "e5-7",
      source: "5",
      target: "7",
      type: "step",
      style: { stroke: "#f6ab6c" },
      label: "a step edge",
      animated: true,
      labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
    }
  ];
  