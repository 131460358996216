import {
  SET_USERGROUPS_LEADER_LIST,
  SET_USERGROUPS_REMOVE_LEADER,
  SET_USERGROUPS_USER_LIST,
  SET_USERGROUP_DESCRIPTION,
  SET_USERGROUP_NAME,
  SAVE_USERGROUP,
  SET_USER_GROUP_ID,
  SET_USERS_EXISTED_IN_GROUP,
  SET_USER_GROUP_PAGE_DEFAULT,
} from "./UMActionTypes";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { disabledUMHeaderButtons } from "../dataDomains/dataDomainActions";
import { Literals } from "../../../../components/modules/common/literals";

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const getUserGroupList = ({ method, endPoint, payload, showLoader=true }) => {
  return (dispatch) => {
    if (showLoader) {

      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData(method, `${BASE_URL}${endPoint}`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            // if (showLoader) {
              dispatch(setLoadingStatus(false));
            // }
            if (resp.data.length <= 0) {
              dispatch(disabledUMHeaderButtons({ isDisable: true, dataTab: Literals.USERGROUP }))
            } else {
              dispatch(disabledUMHeaderButtons({ isDisable: false, dataTab: Literals.USERGROUP }))

            }
            resolve(resp);
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const createEditUser = ({ method, endPoint, payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(method, `${BASE_URL}${endPoint}/`, payload))
        .then((resp) => {
          resolve(resp);
          if (resp.data.id) {
            emitToastNotification('success', "User Created Successfully");
          }
          // else {
          //   emitToastNotification('error', "error in creating user");
          // }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};
export const setUMLeadersList = (data) => {
  return {
    type: SET_USERGROUPS_LEADER_LIST,
    payload: data,
  };
};

export const setUserGroupPageDefault = () => {
  return {
    type: SET_USER_GROUP_PAGE_DEFAULT,
  };
};

export const setUsersExistedInGroup = (data) => {
  return {
    type: SET_USERS_EXISTED_IN_GROUP,
    payload: data,
  };
};

export const setRemovedLeader = (data) => {
  return {
    type: SET_USERGROUPS_REMOVE_LEADER,
    payload: data,
  };
};

export const setUserGroupUserList = (data) => {
  return {
    type: SET_USERGROUPS_USER_LIST,
    payload: data,
  };
};

export const setUserGroupName = (data) => {
  return {
    type: SET_USERGROUP_NAME,
    payload: data,
  };
};

export const setUserGroupDescription = (data) => ({
  type: SET_USERGROUP_DESCRIPTION,
  payload: data,
});

export const saveUserGroup = (data) => ({
  type: SAVE_USERGROUP,
  payload: data,
});

export const setUserGroupId = (id) => ({
  type: SET_USER_GROUP_ID,
  payload: id,
});
