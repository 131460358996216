import React, { useEffect } from "react";
import { Literals } from "../../modules/common/literals";
import { Radio, Tooltip } from "antd";

function Buttons({ props }) {

  const {
    buttonText,
    buttonClassName,
    onEnterCall,
    toggleBtnClassName,
    buttonEvent,
    toggleBtnValue,
    showLoading,
    tooltipPlacement = 'bottom',
    tooltip = false,
    ImgSrc = () => {
      return <></>;
    },
    isDisable = false,
    buttonType,
    toggleBtnOption,
    actionType = 'button',
    buttonId = '',
    buttonWrapperClass = ''
  } = props;

  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  useEffect(() => { }, [toggleBtnOption])

  useEffect(() => {
    if (buttonType === Literals.BTN_PRIMARY_WITHOUT_TEXT) {

    }
  }, [tooltip]);

  useEffect(() => {
    if (buttonId === 'primary-focus-btn') {
      let primaryBtn = document.getElementById('primary-focus-btn');
  
      if (primaryBtn) {
        primaryBtn.focus();
      }
    }
  }, [buttonId])


  const handleBtnPrimaryWithTextContent = (content) => {
    if (tooltip && tooltip?.trim() !== '') {
      return <Tooltip open={tooltipOpen && tooltip && tooltip?.trim() !== ""} destroyTooltipOnHide placement={tooltipPlacement ? tooltipPlacement : "bottom"} title={tooltip}>
        {content}
      </Tooltip>
    }
    return content
  }

  return (
    <div className={`custom-btn-grp ${buttonWrapperClass}`}>

      {buttonType === Literals.BTN_PRIMARY_WITH_TEXT && (
        <button id={buttonId}

          className={buttonClassName}
          onClick={(e) => buttonEvent(e)}
          disabled={isDisable}
          type={actionType}
          onMouseEnter={() => {
            setTooltipOpen(true)
          }}
          // onKeyDown={(e) => {
          //   if (e?.key?.toLowerCase() === 'enter') {
          //     alert('here')
          //     // onEnterCall()
          //   }
          // }}
          onMouseLeave={() => {
            setTooltipOpen(false)
          }}
          onBlur={() => {
            setTooltipOpen(false)
          }}
        >

          {handleBtnPrimaryWithTextContent(
            <>
              {
                showLoading
                  ? (<i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>)
                  : <ImgSrc />
              }
              {buttonText}
            </>
          )}

          {/* <Tooltip open={tooltipOpen && tooltip && tooltip?.trim() !== ""} destroyTooltipOnHide placement={tooltipPlacement ? tooltipPlacement : "bottom"} title={tooltip}>
          {showLoading ? (
            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
          ) :
            <ImgSrc />}
            {buttonText}</Tooltip> */}
        </button>
      )}
      {(buttonType === Literals.BTN_PRIMARY_WITHOUT_TEXT ||
        buttonType === Literals.BTN_SECONDARY ||
        buttonType === Literals.BTN_TERTIARY) &&
        (!!tooltip ? (
          <Tooltip open={tooltipOpen && tooltip && tooltip?.trim() !== ""} destroyTooltipOnHide placement={tooltipPlacement ? tooltipPlacement : "bottom"} title={tooltip}>
            <button id={buttonId}
              // onKeyDown={(e) => {
              //   if (e?.key?.toLowerCase() === 'enter') {

              //     // onEnterCall()
              //   }
              // }}
              className={buttonClassName}
              onClick={(e) => buttonEvent(e)}
              disabled={isDisable}
              type={actionType}
              onMouseEnter={() => {
                setTooltipOpen(true)
              }}
              onMouseLeave={() => {
                setTooltipOpen(false)
              }}
              onBlur={() => {
                setTooltipOpen(false)
              }}
            >
              {<ImgSrc />}
            </button>
          </Tooltip>
        ) : (
          <button id={buttonId}
            // onKeyDown={(e) => {
            //   if (e?.key?.toLowerCase() === 'enter') {

            //     // onEnterCall()
            //   }
            // }}
            className={buttonClassName}
            onClick={(e) => {
              buttonEvent(e);
            }}
            disabled={isDisable}
            type={actionType}
          >
            {<ImgSrc />}
          </button>
        ))}

      {buttonType === Literals.BTN_TOGGLE && (
        <div id={buttonId} className={`toggleAntD ${toggleBtnClassName}`}>
          <Radio.Group

            onChange={(e) => { e.stopPropagation(); buttonEvent(e.target.value) }}
            value={toggleBtnValue}

            disabled={isDisable}
            buttonStyle="solid"
          >
            {toggleBtnOption.map((item, i) => {
              return (
                <Tooltip key={`${new Date().getMilliseconds()}${item.value}${i}`} placement="bottom" title={item.tooltip ?? null}>
                  <Radio.Button disabled={item.disabled ?? false} destroy value={item.value}>{item.label}</Radio.Button>
                </Tooltip>)
            })}
          </Radio.Group>
        </div>
      )}
    </div>
  );
}

export default Buttons;
