import { Badge, Checkbox, Radio, Tooltip, Tree } from 'antd';
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";



import { DATASET, DOMAIN_SMALL } from '../../../../Common/iconSource';

import { v4 as uuidv4 } from 'uuid';
import { fetchDatabaseSchemaList, fetchDatabaseTableList } from '../../../../../store/actions';
import { fetchPreviewTable } from '../../../../../store/modules/common/VirtualDataTable/action';
import { setSchemaTreeMap, setTargetTableDetails } from '../../../../../store/modules/flows/targetTransformer/targetTransformer';
import { LandingSimpleTable, LandingSimpleTableInner } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { showSimpleLoading } from '../../../common/helperFunctions';

const SchemaTree = ({
    connectionName,
    setSchemaDropdownValue,
    settargetTableData,
    setnewTableName,
    selectedSchema,
    treeData = [],
    setTreeData = () => { }
}) => {
    const store = useStore();
    const dispatch = useDispatch();
    const { schemaTreeMap } = useSelector(state => state.Flows.TargetTransformer);
    const sourceDropdownList = [

    ];
    const targetTableDetails = useSelector(state => state.Flows.TargetTransformer.targetTableDetails)

    const [data, setData] = useState([...treeData]);
    const [, setCheckedItems] = useState([]);
    const [showLoader, setshowLoader] = useState(false)
    const [, setIsGenerateQueryBtnVisible] = useState(false);

    useEffect(() => {
        setData([...treeData])
    }, [treeData])

    const fetchSchemaList = () => {
        setshowLoader(true)

        try {
            const requestBody = {

                "connection_name": connectionName
            }

            dispatch(fetchDatabaseSchemaList(requestBody)).then(
                res => {
                    if (res) {

                        const data = res.data.data_record;
                        const schemaNames = data.flat();

                        setData(() => {
                            let temp = schemaNames.map((v, i) => (
                                {
                                    name: v,
                                    rawName: v,
                                    id: i,
                                    isExpanded: false,
                                    isLoaded: false,
                                    children: []
                                }
                            ))
                            return temp
                        })

                        const { schemaTreeMap } = store.getState().Flows.TargetTransformer;
                        const newschemaTreeMap = cloneDeep(schemaTreeMap);

                        schemaNames.forEach((schemaName, index) => {
                            sourceDropdownList.push({ key: index, Name: schemaName, Type: "", value: schemaName })
                            newschemaTreeMap[schemaName] = {};
                        })
                        setSchemaDropdownValue([...sourceDropdownList])
                        dispatch(setSchemaTreeMap(newschemaTreeMap));
                        setshowLoader(false)

                    }
                }
            )
        }
        catch (error) {
            setshowLoader(false)

        }
    }


    const onLoadData = ({ key, children, id }) => {
        const schemaName = key;
        return new Promise((resolve) => {
            if (children.length) {
                resolve();
                toggleSubRow(data, id)
                return;
            }

            try {
                let requestBody = {

                    "connection_name": connectionName,
                    "schema_name": schemaName
                }

                dispatch(fetchDatabaseTableList(requestBody)).then(
                    res => {
                        if (res) {
                            let targetDetails = { ...targetTableDetails }
                            targetDetails['connnectionTableList'] = res.data.data_record
                            let temp = [...data]
                            setData(prev => {
                                prev = prev.map((item, i) => {
                                    return (
                                        item.name === schemaName
                                            ? {
                                                ...item,
                                                isLoaded: true,
                                                children:
                                                    targetDetails.connnectionTableList.map((d, c_idx) => (
                                                        {
                                                            name: d[0],
                                                            rawName: d[0],
                                                            isChecked: false,
                                                            isChild: true,
                                                            id: `${i}-${c_idx}`,
                                                            parentId: item.id,
                                                            parentName: item.name
                                                        }
                                                    ))
                                            }
                                            : { ...item }
                                    )
                                })
                                temp = [...prev]
                                return prev
                            })
                            toggleSubRow(temp, id)




                            store.dispatch(setTargetTableDetails(targetDetails))




                            if (!schemaName.includes("results")) {
                                const { schemaTreeMap } = store.getState().Flows.TargetTransformer;
                                const newschemaTreeMap = cloneDeep(schemaTreeMap);
                                newschemaTreeMap[schemaName].tableCount = res.data.data_record.length;
                                dispatch(setSchemaTreeMap(newschemaTreeMap));
                            }
                        }

                    }
                )
            }
            catch (e) {

            }
            resolve();

        });
    }

    const onChildClick = (schemaName, tableName, isChecked, id) => {
        if (!isChecked) {
            importPodInTable(schemaName, tableName, id);
        }
        else {
            removePodFromTable(schemaName, tableName, id);
        }
    };

    function toggleSubRow(rows, id) {
        const rowIndex = rows.findIndex((r) => r.id === id);
        const row = rows[rowIndex];
        const { children } = row;
        if (!children) return rows;

        const newRows = [...rows];
        newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
        if (!row.isExpanded) {
            newRows.splice(rowIndex + 1, 0, ...children);
        } else {
            newRows.splice(rowIndex + 1, children.length);
        }
        setData([...newRows])
        setTreeData([...newRows])
    }

    function handleSubRow(rows, id) {

        const row = rows.find((r) => r.id === id);
        if (!row || !row.parentId) {
            setData([...rows])
        }

        const newRows = rows.map(r => ({ ...r, isChecked: r.id === id }));

        const parentRowIndex = newRows.findIndex((r) => r.id === row.parentId);
        const { children } = newRows[parentRowIndex];

        if (children) {
            const newChildren = children.map((sr) => ({ ...sr, isChecked: sr.id === id }));
            newRows[parentRowIndex] = { ...newRows[parentRowIndex], children: newChildren };
        }

        setData([...newRows])
        setTreeData([...newRows])
    }


    const createRequestBodyHandler = (schemaName, tableName) => {
        let id = uuidv4();

        let requestBody = {
            uuid: id,
            connection_name: connectionName,
            schema_name: schemaName,
            table_name: tableName,
            page: 0,
            do_count: false,
            sort_body: [],
            filter_body: [],
            table_ids: []

        }

        return requestBody;
    }


    const importPodInTable = async (schemaName, tableName, id) => {
        let dataList = [...data]
        try {
            const { schemaTreeMap } = store.getState().Flows.TargetTransformer;
            settargetTableData({})
            const requestBody = createRequestBodyHandler(schemaName, tableName);
            const { data } = await dispatch(fetchPreviewTable(requestBody));
            settargetTableData(data.result)
            handleSubRow(dataList, id)
            selectedSchema(schemaName)
            setnewTableName(tableName)
        }
        catch (error) {

        }
    }

    const removePodFromTable = (schemaName, tableName, id) => {
        const { schemaTreeMap } = store.getState().Flows.TargetTransformer;
        const newschemaTreeMap = cloneDeep(schemaTreeMap);

        const tableCount = newschemaTreeMap[schemaName].tableCount;

        const allAvailableTables = extractAllAvailableTableNames(newschemaTreeMap);
        let currentTableIndex, newActiveTableName;


        if (tableName) {
            currentTableIndex = allAvailableTables.indexOf(tableName);
            delete newschemaTreeMap[schemaName][tableName];
        }
        else {
            newschemaTreeMap[schemaName] = {
                tableCount
            };
        }


        if (allAvailableTables[currentTableIndex + 1]) {
            newActiveTableName = `${schemaName}-${allAvailableTables[currentTableIndex + 1]}`
        }
        else if (allAvailableTables[currentTableIndex - 1]) {
            newActiveTableName = `${schemaName}-${allAvailableTables[currentTableIndex - 1]}`
        }
        else {
            newActiveTableName = "Results";
        }


        dispatch(setSchemaTreeMap(newschemaTreeMap));
        handleSubRow(data, id)
    }


    const extractAllAvailableTableNames = (schemaTreeMap) => {
        let availableTableNames = [];

        Object.values(schemaTreeMap).forEach((schemaData) => {
            availableTableNames = availableTableNames.concat(Object.keys(schemaData).filter((tableKey) => tableKey !== "tableCount"));
        })


        return availableTableNames;
    }

    const setCheckedItemsHandler = () => {
        const checkedItems = [];

        for (let key in schemaTreeMap) {
            const schemaName = key;
            const schemaTableNames = Object.keys(schemaTreeMap[schemaName]).filter((tableKeys) => tableKeys !== "tableCount");

            const tableCount = schemaTreeMap[schemaName].tableCount;

            if (schemaTableNames.length === tableCount) {
                checkedItems.push(schemaName);
            }
            else {
                schemaTableNames.forEach((tableName) => {
                    checkedItems.push(`${schemaName}-${tableName}`);
                })
            }


        }
        setCheckedItems(checkedItems);
    }



    useEffect(() => {
        if (connectionName && treeData.length === 0) {
            fetchSchemaList();
        }
    }, [connectionName, treeData]);


    useEffect(() => {
        setCheckedItemsHandler();
    }, [schemaTreeMap])

    useEffect(() => {
        const schemaNames = Object.keys(schemaTreeMap);

        if (schemaNames.length > 1) {
            for (let schemaName of schemaNames) {
                if (Object.keys(schemaTreeMap[schemaName]).length > 1) {
                    setIsGenerateQueryBtnVisible(true);
                }
            }
        }
    }, [schemaTreeMap])

    const parentNameTemplate = (val, isOpen = false, id, row) => {
        let anyChildActive = row?.children.some(v => v.isChecked)
        let colorChange = (isOpen || anyChildActive)
        let isEmpty = (row.isLoaded && row.children.length === 0)
        return (
            <div className={`create-pod-tree-schema ${colorChange ? 'color-primary' : ''}`}>
                <i onClick={() => {
                    if (!isOpen && !row.isLoaded) onLoadData({ key: val, children: row.children, id, val })
                    else toggleSubRow(data, id)
                }}
                    className={`cursor-pointer h6 mb-0 mr-1 bx bx-${!isOpen ? 'caret-right' : 'caret-down'}`}></i>
                <DOMAIN_SMALL />
                <Tooltip title={val.toUpperCase()} placement={'right'}>
                    <p style={{ maxWidth: `calc(100% - ${!isEmpty ? '57' : '76'}px)` }} className={`w-auto text-with-ellipsis mb-0 font-w-600 paragraph text-uppercase pl-1 ${colorChange ? 'color-primary' : ''}`}>
                        {`${val}`}
                    </p>
                </Tooltip>
                {
                    isEmpty
                        ?
                        <Badge style={{ backgroundColor: '#fbad58' }} title={`No Tables Found`} className="custom-tree-badge" count={'Empty'} />
                        : ''
                }
            </div>
        )
    }

    const childNameTemplate = ({ name, isChecked = false, ...row }) => {

        const parentName = row.parentId !== undefined
            && data.find(
                item => (item.id === row.parentId)
            )?.name
        return (
            <React.Fragment>
                <Tooltip title={name} placement="left">
                    <p
                        className={`${isChecked ? 'color-primary font-w-600' : ''} paragraph mb-0 mx-n2 text-with-ellipsis position-relative`}
                        onClick={() => {
                            onChildClick(parentName, name, isChecked, row.id)
                        }}
                        style={{ paddingLeft: '51px' }}
                    >
                        <i style={{
                            position: 'absolute',
                            top: '4px',
                            fontSize: '24px',
                            left: '22px',
                            cursor:"pointer"

                        }}
                            className={`bx bx-radio-circle${isChecked ? '-marked color-primary' : ' text-black-50'}`}></i>
                        <DATASET height='18' width='18' /><span className="p-1"></span>
                        {name}
                    </p>
                </Tooltip>
            </React.Fragment>
        )
    }

    const columns = [
        {
            name: 'Tables',
            key: "name",
            sortable: false,
            resizable: false,
            renderCell:({ row }) => {
                const hasChildren = row.children !== undefined;
                return (
                    <>
                        {hasChildren && (
                            parentNameTemplate(row.name, row.isExpanded, row.id, row)
                        )}
                        <div className="rdg-cell-value">
                            {
                                !hasChildren && (childNameTemplate(row))
                            }
                        </div>
                    </>
                );
            }
        }
    ];

    return (
        <div className="create-pod-table-container pt-2 custom-dgt-tree" style={{ height: 'calc(100% - 99px)' }}>
            <div className="create-pod-table-header">
                <h3>Select Tables</h3>
            </div>
            {data?.length && !showLoader ?  <LandingSimpleTable
                cols={columns}
                rowsData={data}
                showCursor={false}
                tableHeight={'calc(100% - 36px)'}
                rowHeight={30}
                headerRowHeight={0}
            /> : !showLoader ? <span className='label' style={{ justifyContent: "center", display: "flex", color: "#d3d3d3", font: "30px" }}>No Tables Found</span> : ''
            }
            {showLoader ? showSimpleLoading("Please Wait Loading Tables List...") : null}
        </div>
    )
}

export default SchemaTree
