import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect, useDispatch, useSelector, useStore } from 'react-redux'
import { Literals } from '../../../common/literals'
import { CLOSE, DISMISSCIRCLE, ERRORCIRCLE, PLAY } from '../../../../Common/iconSource'

import FiltersIndex from '../filters/FiltersIndex'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Buttons from '../../../../Common/button/Buttons'
import DatasetOutputNameInput from './useDatasetNameInput'
import { setFlowsCurrenttransformer, showFlowsTransformdetails } from '../../../../../store/modules/flows/flowsActions'
import { Tabs, Tooltip } from 'antd'
import { ICON_EYEOPEN, ICON_LOADING_CIRCLE, ICON_PLAY, ICON_SETTINGS } from '../../../../Common/newIconSource'
import TransformerPreview from './TransformerPreview'
import { ExecuteTransformer, RunTransformer, checkIsEditorFlow, getStatus, getStatusTooltipMsg, getTextClass, getTransformerName } from './FlowsHelperFunction'

import { RetriveExecutionFlows, saveFlow, updateFlowsElements } from '../../../../../store/modules/flows/NewFlowsRedux/flowsActions'
import SchemaTransformerIndex from '../schema/SchemaTransformerIndex'
import SQLIndex from '../sql/SQLIndex'
import ScriptIndex from '../script/ScriptIndex'
import ScriptOutputNodeIndex from '../scriptOutput/ScriptOutputNodeIndex'
import AggregateTransformerIndex from '../aggregate/AggregateTransformerIndex'
import DataSource from '../dataSource/DataSource'
import { convertDOMElementToImage, getConfirmationModal, getObjectLength } from '../../../common/helperFunctions'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import MergeTransformerIndex from '../merge/MergeTransformerIndex'
import { SAVE_FLOW_CLICK } from '../../../../../store/modules/flows/NewFlowsRedux/flowsTypes'
import { useParams } from 'react-router-dom'
import _, { cloneDeep } from 'lodash'
import TargetIndex from '../target/TargetIndex'
import { OVERLAYINFOGRAPHY } from './OverlayInfography'
import { resetTargetRedux } from '../../../../../store/modules/flows/targetTransformer/targetTransformer'
import { setConnectionSelectedConn } from '../../../../../store/modules/UserManagement/connection/connectionActions'
import AlertTransformerIndex from '../alert/AlertTransformerIndex'




const { TabPane } = Tabs;

const TransformerStatus = () => {
    const dispatch = useDispatch();
    const transformerProgress = useSelector(state => state.Lab.transformerProgress)
    const currentTransformer = useSelector(state => state.Lab.currentTransformer);

    const [status, setStatus] = useState('Not Executed')


    useMemo(() => {
        let status = transformerProgress.find(element => {
            return element.crnt_transformer === currentTransformer?.id
        })?.state;
        setStatus(status)

    }, [transformerProgress])

    return (
        status?.toLocaleLowerCase() === 'failed' ? <Tooltip placement='bottom' title={dispatch(getStatusTooltipMsg())}>
            <div key={new Date().getTime()} className="transformer-status d-flex align-items-center cursor-pointer mr-2">
                <span className='mr-1'>Status:</span> {dispatch(getStatus())}  <span className={`${dispatch(getTextClass())} ml-1`}>{status}</span>
            </div>
        </Tooltip> : <></>

    );
};

const ConfigureTransformerModal = (props) => {
    let { currentTransformer, setOpenModal, openModal, newActiveKey = '' } = props
    const [activeKey, setactiveKey] = useState("configure");
    const [runClick, setRunClick] = useState(false);
    const [updatedFlowRedux, setupdatedFlowRedux] = useState({});
    const [isrunDisabled, setisrunDisabled] = useState(false)
    const [showCloseWarning, setshowCloseWarning] = useState(false);
    const [showOverlay, setshowOverlay] = useState(false);
    const [callPreview, setcallPreview] = useState(false);
    const [transformerName, setTransformerName] = useState('');
    const [hideExploreTab, sethideExploreTab] = useState(false);
    let hideExploreTabRef = useRef(false)
    const dispatch = useDispatch()
    const store = useStore()
    let RetriveInterval = useRef([]);
    let runDisable = useRef(false);
    let currentActiveKey = useRef('configure');

    const [TabsList, setTabsList] = useState()

    const clearAllTimeout = () => {
        // console.log(RetriveInterval.current);
        RetriveInterval.current.forEach(clearTimeout);
        RetriveInterval.current = [];
    }
    const openSaveChangesWarning = () => {

        if (window.confirm('Unsaved changes will be lost. Are you sure, want to close?')) {
            setRunClick(false)
            // clearTimeout(RetriveInterval.current)
            clearAllTimeout()
            setupdatedFlowRedux({})
            closeLeftSideContent()

            setOpenModal(false)
        }
    };


    useEffect(() => {

        if (newActiveKey !== '') {
            let cloneTabs = TabsMenuList//cloneDeep(TabsMenuList);
            cloneTabs[0].children = memoizedSelectedPage;
            cloneTabs[1].children = <TransformerPreview callPreview={false} activatedKey={newActiveKey} setcallPreview={setcallPreview} />;

            setTabsList(cloneTabs)
            currentActiveKey.current = newActiveKey;
            setactiveKey(newActiveKey)


        } else {
            setactiveKey('configure')

        }
        return () => {
            dispatch(showFlowsTransformdetails(false));

        }

    }, [newActiveKey])

    const { flowId } = useParams();
    // const transformerProgress = useSelector(state => state.Lab.transformerProgress)

    useEffect(() => {


        hideExploreTabRef.current = hideExploreTab
    }, [hideExploreTab])

    const callLatestStatusApi = () => {

        if (!openModal || (!window.location.pathname.toLowerCase().includes('/flows'))) {
            // clearTimeout(RetriveInterval.current);
            clearAllTimeout()
            return;
        }

        let apiPayload = {
            page: "",
            limit: "",
            execution_id: "",
            flow_Id: flowId,
        }

        store.dispatch(RetriveExecutionFlows(apiPayload)).then((res) => {
            if (res?.data?.length) {
                let status = res?.data.find(element => {
                    return element.crnt_transformer?.toString() === currentTransformer?.id?.toString()
                })?.state;


                if (status !== '' && (status?.toLowerCase() === 'success' || status?.toLowerCase() === 'done' || status?.toLowerCase() === 'finished' || status?.toLowerCase() === 'failed' || status?.toLowerCase() === 'error' || status?.toLowerCase() === 'aborted')) {
                    setRunClick(false);
                    let cloneTabs = TabsMenuList//cloneDeep(TabsMenuList);
                 
                    if (!hideExploreTabRef.current ) {

                        if (runDisable.current) {

                            setcallPreview(true);
                            cloneTabs[1].disabled = false;
                            if ((currentTransformer?.type === 'script' && currentActiveKey?.current === 'configure') || (currentActiveKey?.current === 'preview')) {
                                cloneTabs[0].children = memoizedSelectedPage;
                                cloneTabs[1].children = <TransformerPreview callPreview={true} activatedKey={currentActiveKey?.current} setcallPreview={setcallPreview} />;

                            }



                            setTabsList(cloneTabs)

                            runDisable.current = false;
                        }
                    }
                    // clearTimeout(RetriveInterval.current)
                    clearAllTimeout()


                } else if (status && (status?.toLowerCase() === 'running' || status?.toLowerCase() === 'queued' || status?.toLowerCase() === 'starting')) {

                    setRunClick(true);
                    let cloneTabs = TabsMenuList//cloneDeep(TabsMenuList);

                    if (runDisable.current === false) {
                        cloneTabs[1].disabled = true;
                        if ((currentTransformer?.type === 'script' && currentActiveKey?.current === 'configure') || (currentActiveKey?.current === 'preview')) {
                            cloneTabs[0].children = <ShowSelectedPage />;
                            cloneTabs[1].children = <TransformerPreview callPreview={callPreview} activatedKey={currentActiveKey?.current} setcallPreview={setcallPreview} />;
                        }

                        setTabsList(cloneTabs)

                        runDisable.current = true;
                    }

                }
            }
            let id = setTimeout(() => {

                callLatestStatusApi();

            }, 5000);

            RetriveInterval.current = [...RetriveInterval.current, id]
        }).catch((err) => {
            let id1 = setTimeout(() => {
                callLatestStatusApi();
            }, 5000);
            RetriveInterval.current = [...RetriveInterval.current, id1]
        })
    }


    const closeLeftSideContent = () => {
        // dispatch(saveFlow(store.getState().Lab?.id, true))
        setRunClick(false)
        setcallPreview(false);
        setupdatedFlowRedux({})
        dispatch(setFlowsCurrenttransformer({}));
        dispatch(showFlowsTransformdetails(false));
        store.dispatch(resetTargetRedux({}));
        dispatch(setConnectionSelectedConn([]));
        setOpenModal(false)

    };

    const onChange = (activeKey) => {
        currentActiveKey.current = activeKey


        // let cloneTabs = cloneDeep(TabsMenuList);


        let cloneTabs = TabsMenuList;

        if (activeKey === 'preview') {

            cloneTabs[0].children = memoizedSelectedPage;
            cloneTabs[1].children = <TransformerPreview callPreview={true} activatedKey={activeKey} setcallPreview={setcallPreview} />;

            setTabsList(cloneTabs)

        } else {
            setcallPreview(false);
            cloneTabs[0].children = memoizedSelectedPage;
            cloneTabs[1].children = <></>;

            setTabsList(cloneTabs)
        }

        setactiveKey(activeKey);
        // clearTimeout(RetriveInterval.current)
        clearAllTimeout()
    };

    const memoizedTabs = useMemo(() => {
        return <div className='configure-tabs-div'>
            <Tabs
                className='configure-tabs'
                items={TabsList}
                activeKey={activeKey}
                onChange={onChange} />
        </div>

    }, [openModal, TabsList, activeKey, callPreview]);//added  activeKey to reflect change




    const memoizedComponent = useMemo(() => {

        return <ModalBody >
            {memoizedTabs}


        </ModalBody>
    }, [activeKey, openModal, callPreview, TabsList]);


    const ShowSelectedPage = () => {
        switch (currentTransformer && currentTransformer.type) {
            case "datasource":
                return <DataSource setshowCloseWarning={setshowCloseWarning} isExecutePermission={store.dispatch(checkIsEditorFlow())} updatedFlowRedux={updatedFlowRedux} setupdatedFlowRedux={setupdatedFlowRedux} />

            case "merge":
                return <MergeTransformerIndex setshowOverlay={setshowOverlay} setshowCloseWarning={setshowCloseWarning} isExecutePermission={store.dispatch(checkIsEditorFlow())} updatedFlowRedux={updatedFlowRedux} setupdatedFlowRedux={setupdatedFlowRedux} />

            case "filters":

                return <FiltersIndex activeKey={activeKey} setshowOverlay={setshowOverlay} setshowCloseWarning={setshowCloseWarning} isExecutePermission={store.dispatch(checkIsEditorFlow())} updatedFlowRedux={updatedFlowRedux} setupdatedFlowRedux={setupdatedFlowRedux} />

            case "script":
                return <ScriptIndex runClick={runDisable.current} setshowOverlay={setshowOverlay} setshowCloseWarning={setshowCloseWarning} isExecutePermission={store.dispatch(checkIsEditorFlow())} setisrunDisabled={setisrunDisabled} updatedFlowRedux={updatedFlowRedux} setupdatedFlowRedux={setupdatedFlowRedux} />

            case "scriptOutput":
                return <ScriptOutputNodeIndex isExecutePermission={store.dispatch(checkIsEditorFlow())} />

            // case "databricks":
            //     return <DatabricksIndex isJupyterPermission={isJupyterPermission} isEditPermission={isEditPermission} isExecutePermission={store.dispatch(checkIsEditorFlow())} />

            // case "bricksOutput":
            //     return <DatabricksOutputNodeIndex isExecutePermission={store.dispatch(checkIsEditorFlow())} />

            case "sql":
                return <SQLIndex setshowOverlay={setshowOverlay} setshowCloseWarning={setshowCloseWarning} isExecutePermission={store.dispatch(checkIsEditorFlow())} updatedFlowRedux={updatedFlowRedux} setupdatedFlowRedux={setupdatedFlowRedux} />

            case "target":
                return <TargetIndex hideExploreTab={hideExploreTab} sethideExploreTab={sethideExploreTab} TabsMenuList={TabsMenuList} setTabsList={setTabsList} setshowOverlay={setshowOverlay} setshowCloseWarning={setshowCloseWarning} isExecutePermission={store.dispatch(checkIsEditorFlow())} updatedFlowRedux={updatedFlowRedux} setupdatedFlowRedux={setupdatedFlowRedux} setisrunDisabled={setisrunDisabled} />

            case "alert":
                return <AlertTransformerIndex setshowOverlay={setshowOverlay} setshowCloseWarning={setshowCloseWarning} isExecutePermission={store.dispatch(checkIsEditorFlow())} updatedFlowRedux={updatedFlowRedux} setupdatedFlowRedux={setupdatedFlowRedux} setisrunDisabled={setisrunDisabled} />

            case "schema":
                return <SchemaTransformerIndex setshowOverlay={setshowOverlay} setshowCloseWarning={setshowCloseWarning} isExecutePermission={store.dispatch(checkIsEditorFlow())} setisrunDisabled={setisrunDisabled} updatedFlowRedux={updatedFlowRedux} setupdatedFlowRedux={setupdatedFlowRedux} />

            case "aggregate":
                return <AggregateTransformerIndex setshowOverlay={setshowOverlay} setshowCloseWarning={setshowCloseWarning} isExecutePermission={store.dispatch(checkIsEditorFlow())} updatedFlowRedux={updatedFlowRedux} setupdatedFlowRedux={setupdatedFlowRedux} />

            default:
                return <></>

        }

    }

    const memoizedSelectedPage = useMemo(() => {


        return <ShowSelectedPage />


    }, [openModal]);  //activeKey

    const memoizedPreview = useMemo(() => {


        return <TransformerPreview callPreview={callPreview} activatedKey={newActiveKey ?? activeKey} setcallPreview={setcallPreview} />

    }, [openModal, activeKey, newActiveKey]);

    let TabsMenuList = [{ key: 'configure', label: <span><ICON_SETTINGS></ICON_SETTINGS>Configure</span>, children: memoizedSelectedPage, disabled: false, hideTab: false }, // !dispatch(checkIsEditorFlow())
    { key: 'preview', label: <span><ICON_EYEOPEN></ICON_EYEOPEN>Explore</span>, children: memoizedPreview, disabled: false, hideTab: false }]

    useEffect(() => {

        callLatestStatusApi();
        let cloneTabs = TabsMenuList //cloneDeep(TabsMenuList);
        // cloneTabs[0].children = <ShowSelectedPage />;
        // cloneTabs[1].children = <TransformerPreview callPreview={callPreview} setcallPreview={setcallPreview} />;

        setTabsList(cloneTabs)
        localStorage.setItem('openConfigModal', true)
        // setTabsList(TabsMenuList);

        return () => {
            setOpenModal(false);
            setshowOverlay(false)
            setcallPreview(false);
            localStorage.setItem('openConfigModal', false)

            // clearTimeout(RetriveInterval.current)
            clearAllTimeout()
        }
    }, [])

    useEffect(() => {
        if (runClick && runDisable.current) {
            setcallPreview(false);
        }
        if (!runDisable.current && !runClick && TabsList?.length) {
            setcallPreview(true);
            setTimeout(() => {
                setcallPreview(false);

            }, 100);
        }
    }, [TabsList, runClick])
    return <Modal isOpen={openModal} backdrop={false} className='custom-modal transformer-configure-modal' id='transformer-configure-modal' fade={true} centered={true}>
        {showOverlay ? <div className="overlay overlay_1">
            <div className='d-flex align-items-center justify-content-center flex-column'>
                <OVERLAYINFOGRAPHY />
                <label className='' style={{ fontSize: "15px", color: "#FFFF", textWrap: "wrap" }}>Unable to change current settings.</label>

                <label style={{ fontSize: "15px", color: "#FFFF", textWrap: "wrap" }}> Fix and re-execute your previous connected transformer.</label>
                <Buttons props={{
                    buttonText: 'Close', buttonClassName: "custom-btn-outline mt-2 btn-outline-close custom-btn  btn-with-text mr-1", buttonEvent: () => {
                        closeLeftSideContent()
                        
                    }, ImgSrc: () => <CLOSE color='#000' />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }} />
            </div>
        </div> : null}

        <ModalHeader>

            <div className="d-flex align-items-center">
                <DatasetOutputNameInput
                    isdisabled={currentTransformer.type?.toLowerCase() === 'datasource' ? true : false}
                    transformerId={currentTransformer?.id}
                    setshowCloseWarning={setshowCloseWarning}
                    setupdatedFlowRedux={setupdatedFlowRedux}
                    updatedFlowRedux={updatedFlowRedux}
                    setTransformerName={setTransformerName}
                />

            </div>
            <div className="tab-menu right-flow-header ">
                <TransformerStatus />
                <Buttons
                    props={{
                        buttonText: !store.dispatch(checkIsEditorFlow()) ? "Run" : "Save & Run",
                        buttonClassName:
                            "custom-btn-primary custom-btn btn-with-text mr-1",
                        buttonEvent: () => {

                            if (updatedFlowRedux?.nodes?.length && currentTransformer?.id) {

                                let newNode = updatedFlowRedux?.nodes.map(
                                    (item) => {
                                        if (item?.id === currentTransformer.id && transformerName?.length && item.transformerName !== transformerName) {
                                            item['transformerName'] = transformerName;

                                        }
                                        return item
                                    }
                                );
                                updatedFlowRedux['nodes'] = newNode;

                            }

                            if (!store.dispatch(checkIsEditorFlow())) {


                                setRunClick(true);
                                let cloneTabs = TabsMenuList//cloneDeep(TabsMenuList);
                                cloneTabs[1].disabled = true;
                                if (currentTransformer?.type === 'script') {
                                    cloneTabs[0].children = <ShowSelectedPage />;
                                    cloneTabs[1].children = <TransformerPreview callPreview={callPreview} activatedKey={activeKey} setcallPreview={setcallPreview} />;
                                }

                                setTabsList(cloneTabs)
                                runDisable.current = true
                                setshowCloseWarning(false)
                                dispatch(RunTransformer('', undefined)).then((resp) => {
                                    setupdatedFlowRedux({})

                                    callLatestStatusApi();

                                }).catch((e) => {
                                    setRunClick(false)

                                })
                            } else {

                                if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {
                                    dispatch(updateFlowsElements(updatedFlowRedux));
                                }

                                setRunClick(true);

                                let cloneTabs = TabsMenuList//cloneDeep(TabsMenuList);
                                cloneTabs[1].disabled = true;
                                if (currentTransformer?.type === 'script') {
                                    cloneTabs[0].children = <ShowSelectedPage />;
                                    cloneTabs[1].children = <TransformerPreview callPreview={callPreview} activatedKey={activeKey} setcallPreview={setcallPreview} />;
                                }

                                setTabsList(cloneTabs)
                                runDisable.current = true;
                                setshowCloseWarning(false)
                                dispatch(ExecuteTransformer('', undefined)).then((resp) => {
                                    setupdatedFlowRedux({})


                                    callLatestStatusApi();


                                }).catch((e) => {
                                    setRunClick(false);

                                })
                            }


                        },
                        ImgSrc: () => runClick ? <ICON_LOADING_CIRCLE color='#FFF' /> : <ICON_PLAY />,
                        isDisable: runClick || isrunDisabled
                            ? true
                            : false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />
                <Buttons props={{
                    buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1", buttonEvent: () => {
                        // (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length && (!(_.isEqual(updatedFlowRedux, store?.getState().Lab.flowsElements)))) || ( 
                        if (showCloseWarning) {
                            openSaveChangesWarning();
                        } else {
                            setOpenModal(false)
                            closeLeftSideContent()

                        }
                    }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                }} />

            </div>
        </ModalHeader>

        {memoizedComponent}

    </Modal>
    // ), [openModal,activeKey])
    // return memoizedModal;

}

const mapStateToProps = (state) => {
    return {
        currentTransformer: state.Lab.currentTransformer,

    };
};
export default connect(mapStateToProps)(ConfigureTransformerModal)