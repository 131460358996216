import React, { useEffect, useState } from 'react'
import MergeKeys from './mergedColumns/MergeKeys'
import OutcomeColumns from './mergedColumns/OutcomeColumns'
import PrimaryKeySelection from './mergedColumns/PrimaryKeySelection'
import { connect, useSelector, useStore } from 'react-redux'
import NoSource from './NoSource'
import OneSource from './OneSource'
import { getInputTransformerDetails } from '../FlowsHelperFunction'
const MergedColumns = (props) => {
    const { currentTransformer, flowsElements, outputDatasetName, setoutputDatasetName, setdisableExcute } = props
    const store = useStore()
    const [swap, setSwap] = useState(false)
    const [selectedPOD, setSelectedPOD] = useState(null)
    const [contextValue, setcontextValue] = useState(2)


    useEffect(() => {
        setcontextValue(contextSwitch());

    }, [currentTransformer])

    const contextSwitch = () => {
        let count = 0

        let InputTransformer = store.dispatch(getInputTransformerDetails())

        if (InputTransformer.length > 0) {
            InputTransformer?.forEach(element => {
                if (element?.content?.outcomeColumns?.length) {

                    count = InputTransformer?.length
                }
            });

            return count
        } else {
            return count
        }
    }

    return (
        <>
            {
                (contextValue === 2) &&
                <div className="d-flex bg-white section-with-drag merge-drag flow-with-drag">
                    <div className="w-50 custom-border-right position-relative">
                        <MergeKeys swap={swap} setSwap={setSwap} setSelectedPOD={setSelectedPOD} selectedPOD={selectedPOD} />
                        <PrimaryKeySelection swap={swap} selectedPOD={selectedPOD} setSelectedPOD={setSelectedPOD} />
                    </div>
                    <div className="w-50">
                        <OutcomeColumns setoutputDatasetName={setoutputDatasetName} outputDatasetName={outputDatasetName}  setdisableExcute={setdisableExcute}/>
                    </div>
                </div>
            }
            {
                (contextValue === 0) &&
                <div className="no-datasource">
                    <NoSource />
                </div>
            }
            {
                (contextValue === 1) &&
                <div className="no-datasource">
                    <OneSource />
                </div>
            }
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer,
        // transformerProgress: state.FlowsReducer.transformerProgress,

    };
};
export default connect(mapStateToProps)(MergedColumns)