import React, { useEffect, useRef, useState } from 'react'
import NoResultsFound from '../../createFlows/NoResultsFound'
import { FLOW_INPUT_ICON, FLOW_OUTPUT_ICON, USAGETIME } from '../../../../Common/iconSource'
import useVirtualTableAction from '../../../../Common/virtualDataTable/useVirtualTableAction';
import { useSelector, useStore } from 'react-redux';
import { createActivePodDetails, createActivePodDetailsCurrentTransformer } from './FlowsHelperFunction';
import { v4 as uuidv4 } from "uuid";
import { resetVirtualTableActions, setActivePodDetails } from '../../../../../store/modules/common/VirtualDataTable/action';
import { List, Tooltip } from 'antd';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';
import { QueryKillError, getObjectLength } from '../../../common/helperFunctions';
import DataGridTable from '../../../../Common/dataGridTable/DataGridTable';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { getExcecutionRetrive } from '../../../../../store/modules/alert/createAlert/createAlertAction';
import Plot from 'react-plotly.js'
import { getAlertLogsGraphData } from '../../../../../store/modules/flows/NewFlowsRedux/flowsActions';
import { useParams } from 'react-router-dom';
import LogsGraph from '../alert/LogsGraph';

function TransformerPreview({ callPreview, setcallPreview, activatedKey }) {

    const store = useStore();
    const [uuid, setuuid] = useState("");
    const [inputputList, setinputputList] = useState([]);
    const [outputList, setoutputList] = useState([]);
    const [getTableData, setgetTableData] = useState(false);
    const [tableData, settableData] = useState({});
    const [graphData, setgraphData] = useState([])
    const [layout, setlayout] = useState({})
    const [isPreviewDataLoading, setIsPreviewDataLoading] = useState(false);
    const [activeTabKey, setactiveTabKey] = useState('');
    const [isOutputTabActive, setisOutputTabActive] = useState(false)
    const tableDataRef = useRef({})
    const callApiRef = useRef(0);

    const plotlyConfig = {
        displaylogo: false,
        responsive: true,
        editable: false
    }


    let isQueryKill = useSelector((state => state.CommonComponent?.DataGridTable?.isQueryKill))
    let currentTransformer = useSelector(state => state?.Lab?.currentTransformer);
    let transformerProgress = useSelector(state => state?.Lab?.transformerProgress);
    const { flowId } = useParams();

    const getUUID = () => {
        let id = uuidv4();
        setuuid(id)
    };
    const { previewTableData } = useVirtualTableAction(
        getTableData,
        uuid
    );

    useEffect(() => {
        if (previewTableData) {
            setcallPreview(false);
            tableDataRef.current = previewTableData
            settableData(previewTableData)
            setIsPreviewDataLoading(false);
            setgetTableData(false)

        }
    }, [previewTableData])

    useEffect(() => {
       
        if (!callPreview) {
            setcallPreview(false);
            callApiRef.current = 0;
            return;
        }
        if (callPreview && callApiRef.current < 1) {
            let currentStatus;
            let outputList = store.dispatch(createActivePodDetailsCurrentTransformer());

            if (outputList?.length) {
                setoutputList(outputList);
              
            }
    
            let transformerDetail = outputList[0];

            if (transformerProgress && transformerProgress.length) {
                currentStatus = transformerProgress.find(element => {
                    return element.crnt_transformer === transformerDetail?.transformerId
                })?.state;
            }
            if (currentStatus === 'success' && (transformerDetail?.podName && currentTransformer?.type !== 'datasource') || (transformerDetail?.podId !== '' && currentTransformer?.type === 'datasource')) {
                callApiRef.current = callApiRef.current + 1;
                if (currentTransformer?.type === 'alert') {
                    store.dispatch(getAlertLogsGraphData(flowId, currentTransformer?.id)).then((resp) => {
                        if (resp && resp.status === "success" && resp.data?.length) {

                            setgraphData([...resp?.data])
                            // generateGraphData([...resp.data])

                        } else {


                        }
                    });
                }
                getPreview(transformerDetail);


                setcallPreview(false);

            }
        }

    }, [callPreview, transformerProgress])


    const getPreview = (transformerDetail) => {


        settableData({});

        getUUID();
        store.dispatch(resetVirtualTableActions()); //check hang screen check hang screen

        setactiveTabKey(transformerDetail?.transformerId)
        let currentStatus = ''

        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === transformerDetail?.transformerId
            })?.state;
        }
        if (currentStatus === 'success' && (transformerDetail?.podName && currentTransformer?.type !== 'datasource') || (transformerDetail?.podId !== '' && currentTransformer?.type === 'datasource')) {

            store.dispatch(
                setActivePodDetails(
                    transformerDetail
                )
            );

            setTimeout(() => {
                if (activatedKey === 'preview') {
                    setIsPreviewDataLoading(true);
                    setgetTableData(true);
                }

            }, 100);  // decrease the time call for api of preview because its call when execute click

        } else {
            settableData({});
            setIsPreviewDataLoading(false);

        }


    }

    useEffect(() => {
        let inputputList = store.dispatch(createActivePodDetails());
        if (inputputList?.length) {
            setinputputList(inputputList);
        }

        let outputList = store.dispatch(createActivePodDetailsCurrentTransformer());

        if (outputList?.length) {
            setoutputList(outputList);
            getPreview(outputList[0]);
            setisOutputTabActive(true)
        }

        if (currentTransformer?.type === 'alert' && !graphData?.length) {
            store.dispatch(getAlertLogsGraphData(flowId, currentTransformer?.id)).then((resp) => {
                if (resp && resp.status === "success" && resp.data?.length) {

                    setgraphData([...resp?.data])
                    // generateGraphData([...resp.data])

                } else {


                }
            });
        }

    }, [])


    const generateGraphData = (data) => {
        let graph = []

        let x = []
        let y = []
        if (data && data.length) {
            data.forEach((ele, i) => {
                let date = new Date(ele.start_date_time).toLocaleString()
                if (ele.state?.toLowerCase() === 'error') {
                    x.push(date.toString())
                    y.push(0)
                } else {
                    x.push(date.toString())
                    y.push(ele.exception_count)
                }

            })
            graph = [{
                x: x,
                y: y,
                type: 'line',
                mode: 'lines+markers',
                hovertemplate:
                    '<br><b>Count</b>: %{y}<br>' +
                    "<extra></extra>",

            }]

            setgraphData([...graph])
            let layout = {
                showLegend: false,
                xaxis: {
                    title: 'Execution Start Date',
                    tickmode: "array", // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
                    tickvals: [graph[0]?.x[0], graph[0]?.x[x.length - 1]],
                    ticktext: [graph[0]?.x[0], graph[0]?.x[x.length - 1]],
                    //insightChartView === "hour" ?[hourAxisData[0],hourAxisData[hourAxisData.length-1]] : [xAxisData[0],xAxisData[xAxisData.length-1]],
                    tickfont: {
                        size: 9,
                        color: '#495057'
                    },
                    zeroline: false,
                },
                yaxis: {
                    title: "Exception Count",
                    // nticks: 0,
                    // tickmode: "auto",
                    rangemode: "nonnegative",
                    tickfont: {
                        size: 8,
                        color: '#495057'
                    },
                },
                hovermode: 'x',
                margin: {
                    l: 70,
                    r: 20,
                    b: 50,
                    t: 20,
                    pad: 0
                    // l: 50,
                    // r: 50,
                    // b: 100,
                    // t: 40,
                    // pad: 8
                },
                autoexpand: true,
                autosize: true,
            }
            setlayout(layout)
        }
    }
    const getCurrentPODName = () => {
        return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails
            ?.podName;
    };

    const getCurrentPODCategoryName = () => {
        return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails
            ?.dataDomain;
    };

    const getPODId = () => {
        return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails
            ?.podId
            ? store.getState().CommonComponent?.VirtualDataTable?.activePodDetails
                ?.podId
            : null;
    };
    const onTabChange = (transformerDetail, from = '') => {
        setactiveTabKey(transformerDetail?.transformerId);
        if (outputList?.length) {
            let findIndex = outputList.findIndex((item) => item.transformerId === transformerDetail?.transformerId)
            if (findIndex > -1) {
                setisOutputTabActive(true)
            } else {
                setisOutputTabActive(false)

            }

        }

        getPreview(transformerDetail, from)

    }

    return (
        <div className='transformer-preview-section'>
            <div className='result-details'>
                <div className='input-output-details'>
                    <div className='title'>
                        <span className='mr-2'>
                            <FLOW_INPUT_ICON  height='17' width='17'/>
                        </span>
                        Input


                    </div>
                    <div className='input-list'>
                        <List
                            itemLayout="horizontal"
                            dataSource={inputputList}
                            renderItem={(item, index) => (
                                <List.Item onClick={() => { onTabChange(item, 'inputTab') }} className={item.transformerId === activeTabKey ? 'active ' : ''}>
                                    <List.Item.Meta

                                        title={<Tooltip title={item.transformerName}><div className="list-item-title">{item.transformerName}</div></Tooltip>}


                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    <div className='title'>
                        <span className='mr-1'>
                            <FLOW_OUTPUT_ICON  height='19' width='20'/>
                        </span>  Output

                    </div>
                    <div className='output-list'>
                        <List
                            itemLayout="horizontal"
                            dataSource={outputList}
                            renderItem={(item, index) => (
                                <List.Item onClick={() => { onTabChange(item) }} className={item.transformerId === activeTabKey ? 'active ' : ''}>
                                    <List.Item.Meta

                                        title={<Tooltip title={item.transformerName}><div className="list-item-title" >{item.transformerName}</div></Tooltip>}

                                    />
                                </List.Item>
                            )}
                        />
                    </div>

                </div>
                {currentTransformer?.type === 'alert' && isOutputTabActive ? <div className='preview-table-result d-flex flex-column'>
                    <div className='resultTable'>
                        {isPreviewDataLoading ? (
                            <>
                                <div style={{ height: "100%" }} className="no-flow-preview-data">
                                    <ClaristaLoader isCancel={true} uuid={uuid} />
                                </div>
                            </>
                        ) :
                        isQueryKill ? <QueryKillError topPercentage={'25%'}/> : (
                                tableData &&
                                    Object.keys(tableData).length !== 0 ? (
                                    <div className="flow-lower-section h-100">
                                        <div id="flow-lower-table-sec" className="flow-lower-table bg-white custom-virtual-data-table h-100">
                                            <DataGridTable

                                                resp={tableData}
                                                uniqUUID={uuid}
                                                setUniqUUID={setuuid}
                                                requestedTableId={getPODId()}
                                                CurrentPODName={getCurrentPODName}
                                                CurrentDomainName={getCurrentPODCategoryName}
                                                isPreviewDataAlreadyLoading={isPreviewDataLoading}
                                                setPreviewAlreadyLoading={setIsPreviewDataLoading}
                                                showDictionaryDetails={false}
                                                gridHeight={'100%'}
                                            />

                                        </div>
                                    </div>
                                ) : <> <NoResultsFound msg='No Data Found' submsg={`Click on 'Run' button to get the result`} /></>
                            )}
                    </div>
                    <div className='alert-graph'>
                        <p className="section-caption">
                            Performance Over Time
                        </p>
                        <div className="chart p-2">
                            {graphData?.length ?
                                // <Plot
                                //     useResizeHandler={true}
                                //     data={graphData}
                                //     layout={layout}
                                //     // layout={plotlyLayout(graph?.column?.name, graph?.column?.dataType)}
                                //     style={{ width: "100%", height: "200px" }}
                                //     config={plotlyConfig}
                                // /> 
                                <LogsGraph data={graphData} />

                                :
                                <div style={{ height: "12.5rem", color: "#D3D3D3", fontWeight: "500" }} className="d-flex flex-column align-items-center justify-content-center w-100 paragraph ">
                                    <USAGETIME height="35" width="35" color="#D3D3D3" ></USAGETIME>
                                    No Logs Found</div>
                            }
                            {/* <GraphIndex customHeight={300} fromViewAnalysis={true} subType={'basic'} isViewMode={true} graphSubType={'basic'} graphType={'Line'} graphData={graphData} graphId={''} graphLayout={{ xAxisLabel: 'Timestamp', yAxisLabel: 'Exceptions', showLegend: false, orientation: 'v',...layout }} activeTabName='Query' />, */}
                        </div>
                    </div>
                </div> : <div className='preview-table-result'>
                    {isPreviewDataLoading ? (
                        <>
                            <div className="no-flow-preview-data ">
                                <ClaristaLoader isCancel={true} uuid={uuid} />
                            </div>
                        </>
                    ) :
                        isQueryKill ? <QueryKillError /> : (
                            tableData &&
                                Object.keys(tableData).length !== 0 ? (
                                <div className="flow-lower-section h-100">
                                    <div id="flow-lower-table-sec" className="flow-lower-table bg-white custom-virtual-data-table h-100">
                                        <DataGridTable

                                            resp={tableData}
                                            uniqUUID={uuid}
                                            setUniqUUID={setuuid}
                                            requestedTableId={getPODId()}
                                            CurrentPODName={getCurrentPODName}
                                            CurrentDomainName={getCurrentPODCategoryName}
                                            isPreviewDataAlreadyLoading={isPreviewDataLoading}
                                            setPreviewAlreadyLoading={setIsPreviewDataLoading}
                                            showDictionaryDetails={false}
                                            gridHeight={'calc(100% - 15px)'}
                                        />

                                    </div>
                                </div>
                            ) : <> <NoResultsFound msg='No Data Found' submsg={`Click on 'Run' button to get the result`} /></>
                        )}

                </div>}

            </div>

        </div>
    )
}

export default TransformerPreview