import { cloneDeep, isNumber } from "lodash";
import { getObjectLength } from "../../common/helperFunctions";
import { updateFlowsElements } from "../../../../store/modules/flows/flowsActions";

export const getInputTransformerDetails = () => {
    return (dispatch, getState) => {
        let currentTransformer = getState().FlowsReducer?.currentTransformer;
        let flowsElements = getState().FlowsReducer?.flowsElements;

        if (flowsElements?.length && currentTransformer?.id) {
            let InputTransformer = []
            flowsElements.forEach(
                (item) => {
                    if (item.id?.includes('reactflow__edge') && item?.target === currentTransformer.id) {
                        InputTransformer.push(flowsElements.find((ele) => ele.id === item.source));
                    }
                }
            );
            return InputTransformer;
        }
    }
}

export const setTransformerNameinflowselement = (transformerName) => {
    return (dispatch, getState) => {
        let currentTransformer = getState().FlowsReducer?.currentTransformer;
        let flowsElements = cloneDeep(getState().FlowsReducer?.flowsElements);

        if (flowsElements?.length && currentTransformer?.id) {

            flowsElements.forEach(
                (item) => {
                    if (item?.id === currentTransformer.id) {
                        item['transformerName'] = transformerName;

                    }
                }
            );
            dispatch(updateFlowsElements
                ([...flowsElements]))
        }
    }
}
export const getInputFrame = () => {
    return (dispatch, getState) => {
        let currentTransformer = getState().FlowsReducer?.currentTransformer;
        let flowsElements = getState().FlowsReducer?.flowsElements;
        let Frame = "";
        let currentTransformerType = currentTransformer?.type;
        let inputTransformer = dispatch(getInputTransformerDetails())
        if (currentTransformerType !== 'sql' && currentTransformerType !== 'script' && currentTransformerType !== 'databricks') {

            if (inputTransformer && inputTransformer?.length) {

                let podType = inputTransformer[0]?.type;
                let transformerName = inputTransformer[0]?.transformerName;

                if (podType.includes("datasource")) {
                    let podDetails = getState().FlowsReducer.dataSourcePodDetails;

                    if (podDetails.length) {
                        let pod = podDetails.find((item) => Object.keys(item)[0] === inputTransformer[0]?.id);

                        if (pod) {
                            Frame = `${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName
                                }`;
                        }
                    }
                } else if (podType.includes("scriptOutput")) {
                    // let scriptOutputDataSet =
                    //     getState().Flows.Script.scriptOutputDataset;

                    // if (scriptOutputDataSet && scriptOutputDataSet.length) {
                    //     Frame = scriptOutputDataSet.find((item) => item.id === inputTransformer[0]?.id)?.value;
                    // } else {
                    Frame = inputTransformer[0]?.id.split("|")[1];
                    // }
                } else {
                    Frame = transformerName;
                }

                return Frame;
            } else {
                return ''
            }


        } else {
            let Frame = [];
            if (inputTransformer && inputTransformer.length) {
                inputTransformer.forEach((ele) => {
                    let podType = ele?.type;
                    let transformerName = ele?.transformerName;

                    if (podType.includes("datasource")) {
                        let podDetails = getState().FlowsReducer.dataSourcePodDetails;

                        if (podDetails.length) {
                            let pod = podDetails.find((item) => Object.keys(item)[0] === ele?.id);

                            if (pod) {
                                Frame.push(
                                    `${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName
                                    }`
                                );
                            }
                        }
                    } else if (podType.includes("scriptOutput")) {
                        // let scriptOutputDataSet =
                        //     getState().Flows.Script.scriptOutputDataset;

                        // if (scriptOutputDataSet && scriptOutputDataSet.length) {
                        //     Frame.push(
                        //         scriptOutputDataSet.find((item) => item.id === ele)?.value
                        //     );
                        // } else {
                        Frame.push(ele?.id?.split("|")[1]);
                        // }
                    } else {
                        Frame.push(transformerName);
                    }

                });
                console.log(Frame)
                return Frame
            } else {
                return Frame
            }

        }
    }
};

export const isTransformerNamePresent = (transformerId) => {
    return (dispatch, getState) => {
        let TransformerNameList = getState().FlowsReducer?.transformerName;

        return TransformerNameList[transformerId]?.length > 0 || isNumber(TransformerNameList[transformerId]) > 0 ? true : false

    }
}

export const getTransformerName = (transformerId) => {
    return (dispatch, getState) => {
        let TransformerNameList = getState().FlowsReducer?.transformerName;
        let flowsElements = cloneDeep(getState().FlowsReducer?.flowsElements);
        if (isNumber(TransformerNameList[transformerId]) > 0 && flowsElements?.length) {
            return flowsElements.find((item) => item.id === transformerId)?.transformerName;
        } else {

            return TransformerNameList[transformerId]?.length > 0 || isNumber(TransformerNameList[transformerId]) > 0 ? TransformerNameList[transformerId] : 'Transformer Name Missing'
        }

    }
}

export const orderRetainCheck = (orderList, mergeList) => {
    let cloneMergeList;
    if (orderList?.length) {
        cloneMergeList = mergeList.sort((a, b) => {
            const indexA = orderList.findIndex(item => item?.Name === a?.Name);
            const indexB = orderList.findIndex(item => item?.Name === b?.Name);

            // Handle cases where the element is not in the preferred order
            if (indexA === -1 && indexB === -1) {
                return 0; // No change in order
            } else if (indexA === -1) {
                return 1; // Move element A to the end
            } else if (indexB === -1) {
                return -1; // Move element B to the end
            }

            return indexA - indexB;
        });


    }


    if (orderList?.length) {
        orderList?.forEach((item, Index) => {
            if (item?.id?.toString()?.includes("emptyColumn")) {
                cloneMergeList.splice(Index, 0, item)
            }
        })
    }

    if (cloneMergeList?.length) {

        return cloneMergeList;
    } else {
        return mergeList
    }
}