import { Avatar, Tooltip ,Switch,Popover} from 'antd'
import React, { useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { LandingSimpleTable } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import {  toTitleCase } from '../../common/helperFunctions'
import Buttons from '../../../Common/button/Buttons'
import { useDispatch } from 'react-redux'

import {  DELETEICON, HIDEPREVIEW,CLOSE,PREVIEW,SEARCH,RESETFILTER} from '../../../Common/iconSource'
import { Literals } from '../../common/literals'
import { useNavigate } from 'react-router-dom'
import { getSettingsAdditionalDetailsList } from '../../../../store/modules/dataDictionary/action'
import { useStore } from 'react-redux'
import { SET_SELECTED_BUSINESS_TERM_TEMP } from '../../../../store/modules/dataCatalogue/dictionary/dictionaryActionTypes'
import _, { cloneDeep, isArray } from 'lodash'

export const DataDictionaryListView = ({
    listData = [],
    searchData = '',
    columns,
    setColumn,
    openDeleteDialog = () => { },
    onScrollToBottom = () => { },
    loadMore = false,
    fromRoute,
    colsList,
    setColsList,
    setselectedIndex,
    selectedIndex,
}) => {
    const dispatch=useDispatch();

    const history = useNavigate()

    const store = useStore()

    const [data, setData] = useState([])
    const additionalFields=React.useRef([]);
    const [hideFieldSearchValue, setHideFieldSearchValue] = useState('');

    const [showHideFieldPop, setHideFieldPop] = useState(false);
    const handleAllFields = (isVisible) => {
        let colsCopy = [...colsList].map(col=>{
            
            if(col.isRequired){
                return {...col , visible:true}
            }
            else{

                return {...col,visible:isVisible}
            }
           
        
        })
        setColsList([...colsCopy])
        setColumn([...generateColumns(colsCopy)])
    }


    const handleHideFieldVisibleChange = (visible) => {
        setHideFieldPop(visible)
    }
    const onHideFieldSwitchChange = (checked, key) => {
        setColsList(prev => {
            prev = prev.map(item => {

                if(item.key==key){
                    return {
                        ...item,
                        visible:checked
                    }
                }
                else{
                    return {...item}
                }
            })
            setColumn([...generateColumns(prev)])
            return [...prev]
        })
    }
    const hidePopover = {
        header: () => {
          return <>
            <div className="d-flex justify-content-between card-padding padding-2">
              <p className="section-heading m-0">Hide Fields</p>
              <div className="d-flex">
                <Buttons
                  props={{
                    buttonText: "",
                    buttonClassName:
                      "custom-btn-outline custom-btn btn-with-icon mr-2",
                    buttonEvent: (e) => { 
                        e.stopPropagation()
                        e.preventDefault()
                        setHideFieldSearchValue('')
                        handleAllFields(false)
                    },
                    ImgSrc: () => <RESETFILTER />,
                    isDisable: false,
                    buttonType: Literals.BTN_TERTIARY,
                  }}
                />
                <Buttons
                  props={{
                    buttonText: "",
                    buttonClassName:
                      "custom-btn-outline custom-btn btn-with-icon",
                    buttonEvent: () => {
                      setHideFieldPop(false);
                    },
                    ImgSrc: () => <CLOSE />,
                    isDisable: false,
                    buttonType: Literals.BTN_TERTIARY,
                  }}
                />
              </div>
            </div>
          </>
        },
        body: () => {
          return <>
            <div>
              <div className="d-flex justify-content-between card-padding custom-border-bottom padding-2">
                <div className="d-flex align-items-center">
               
    
                  <div
                    className="custom-search"
                    style={{ width: "270px" }}
                  >
                    <input
                      id="hide-field-searchbox"
                      type="text"
                      onChange={(e) => setHideFieldSearchValue(e.target.value) }
                      className="custom-search-input"
                      placeholder={Literals.PLACE_HOLDER}
                      autoFocus
                      value={hideFieldSearchValue}
                    />
                    <div className="custom-search-icon">
                      <SEARCH />
                    </div>
                    <div className="custom-close-icon" onClick={() => setHideFieldSearchValue('')}>
                      <CLOSE />
                    </div>
                  </div>
                </div>
              
              </div>
              <div className="simple-divider"></div>
              <div className="row" style={{ padding: "10px" }}>
                <div className="col-6 pr-1">
                  <Buttons
                    props={{
                      buttonText: "Hide All",
                      buttonClassName:
                        "custom-btn-outline custom-btn btn-with-text w-100",
                      buttonEvent: () => { handleAllFields(false) },
                      toggleBtnValue: "",
                      ImgSrc: () => <HIDEPREVIEW />,
                      isDisable: false,
                      buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                      toggleBtnOption: null,
                    }}
                  />
                </div>
                <div className="col-6 pl-1">
                  <Buttons
                    props={{
                      buttonText: "Show All",
                      buttonClassName:
                        "custom-btn-outline custom-btn btn-with-text w-100",
                      buttonEvent: () => { handleAllFields(true) },
                      toggleBtnValue: "",
                      ImgSrc: () => <PREVIEW />,
                      isDisable: false,
                      buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                      toggleBtnOption: null,
                    }}
                  />
                </div>
              </div>
              <div className="simple-divider"></div>
              <div className="h-100">
                <div
                  className="overflow-auto"
                  style={{ height: "300px" }}
                >
                    {
                        colsList?.filter(item=>item?.name?.toLocaleLowerCase()?.includes(hideFieldSearchValue?.toLocaleLowerCase()))?.length>0
                        ?
                        colsList?.filter(item=>item?.name?.toLocaleLowerCase()?.includes(hideFieldSearchValue?.toLocaleLowerCase()))
                        .map((column, index) => (
                            <>
                            <div key={`hide-field-map-${index}`} className="flows-filter-wrapper custom-border-bottom padding-2">
                                <div className="flows-filter-section">
                                <div className="d-flex" style={{ gap: "10px" }}>
                                    <div>
                                    <Switch
                                        checked={column.visible}
                                        size={"small"}
                                        onChange={(e) =>
                                            onHideFieldSwitchChange(e, column.key)
                                        }
                                        disabled={column.isRequired}
                                    />
                                    </div>
                                    <div>
                                    <label className="mb-0 text-with-ellipsis" style={{maxWidth: 250}}>
                                        <Tooltip placement="topLeft" title={column?.name}>
                                        {column?.name}
                                        </Tooltip>
                                    </label>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </>
                        ))
                        :
                        <div className="p-3 text-center">No Match Found</div>
                    }
                </div>
              </div>
            </div>
          </>
        }
    }

    const getAdditionalDetailsList=()=>{
        let additionalColumns=[];

        dispatch(getSettingsAdditionalDetailsList()).then((res)=>{
            let tempData={};

            res.data.forEach((item)=>{
                let options=null;
                additionalFields.current.push(item);
                if(!_.isEmpty(item.value)){
                    options=item.value.map(val=>{return {Name:val,value:val}})
                }
                tempData[item.name]="";
                
                additionalColumns.push({

                    name: item.name,
                    key: item.name,
                    sortable: false,
                    resizable: false,
                    visible: false,
                    isRequired: false, 
                    type:item.type,
                    value:options,
                    frozen: false,

                    width: 180,


                   
                    },
                )
            })
            let tempColumns=originalColumns.slice(0,originalColumns.length-1)
            let temp=[...tempColumns,...additionalColumns
                
                ];
            setColsList(temp);
        })
    }

    const generateColumns = (columns_list) => {
        let cols = [], colsListCopy = [...columns_list]
        cols = colsListCopy.filter(v=>v.visible===true).map(v=>{
            return {
                name: v.name,
                key: v.key,
                sortable: false,
                resizable: true,
                visible:v.visible,
                width: v.width,
                frozen: v.frozen??false
            }
        })
        if(fromRoute=="Data Dictionary"){
            let temp=cols.filter(item=>item.key!=="action_btn");
            temp.unshift(            
                    {
                    name: "",
                    key: "action_btn",
                    frozen:true,
                    sortable: false,
                    resizable: false,
                    width: "2%"
                })
                return temp;
            // setColumn([  
                //{
            //     name: <div className='text-center'>Action</div>,
            //     key: "action_btn",
            //     frozen:true,
            //     sortable: false,
            //     resizable: false,
            //     width: "10%"
            // }
            //,...temp])

        }
        else{
        cols.push(
            {
                name: <div className='text-center'>Action</div>,
                key: "action_btn",
                sortable: false,
                resizable: false,
                width: '2%'
            })
        }
       
        return cols
    }

    useEffect(()=>{
        let tempOriginalColumns=originalColumns.slice(0,originalColumns.length-1);
        let colListKeys=colsList.map(item=>item.key)
        let originalColumnKeys=tempOriginalColumns.map(item=>item.key);
        if(_.isEqual(colListKeys,originalColumnKeys))
              getAdditionalDetailsList();
    },[])


    const generateAdditionalFieldData=(addfield)=>{
      
        let addFieldTemp={}
        let additionalFieldColumns=colsList.filter(item=>{
            return !originalColumnNames.includes(item.key)
        }).map(item=>item.name)
     additionalFieldColumns.forEach(colName=>{
            let item=addfield.filter(item=>item.name==colName)[0];
            addFieldTemp[colName]= getFieldTemplate(item?.name, item?.type,item?.value,item?.options);
        })

        return addFieldTemp;

    }



    const getFieldTemplate=(name,type,text,dropdownValues)=>{


        switch(type){

            case 'DROPDOWN':
                return  <>
                <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={text}>{text??"Not Applicable"}</Tooltip></div>
            </>
        case 'TOGGLE':
            return <>
               {!text?"Not Applicable": <div>

                    <Switch
                        checked={text}
                        size='small'
                        disabled={true}
                    />
                </div>}
            </>
        case 'TEXT':
        case 'NUMERIC':
        case 'DATE':
            return <>
                 <>
            <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={text}>{text??"Not Applicable"}</Tooltip></div>
        </>
            </>
        default: return <div>Not Applicable</div>


        }

        return <div>Not Applicable</div>
    }
    useEffect(() => {

        let temp = listData.map((item) => {

            let addFieldData=generateAdditionalFieldData(item.add_field);
            return {
                ...addFieldData,
                id: item.id,
                key: item.id,

                data: item,
                name: listTemplate.data_category(item?.category_detail?.name ?? '', searchData),
                business_term: listTemplate.business_term(item.business_term.toUpperCase(), searchData),
                owners: listTemplate.owners(item.owner_steward_detail.owner, searchData),
                tech_term:listTemplate.tech_term(item.tech_term,searchData),
                stewards: listTemplate.stewards(item.owner_steward_detail.steward, searchData),
                description: listTemplate.description(item.description, searchData),
                classifications: listTemplate.classifications(item.classification_detail, searchData),
                action_btn: listTemplate.action(() => { }, () => { }, openDeleteDialog, item.id)

            }
        })
        setData([...temp])
    }, [listData,columns])



    useEffect(() => {
        if(setColumn){
        let copycol = cloneDeep(columns.length==0?originalColumns:columns);
        if (fromRoute === 'schema-table') {
            setColumn(copycol.slice(0, copycol.length ))
        }
        else if(fromRoute=="Data Dictionary"){
            let temp=copycol.filter(item=>item.key!=="action_btn");
            setColumn([  {
                name:"",
                key: "action_btn",
                frozen:true,
                sortable: false,
                resizable: false,
                width: "5%"
            },...temp])


        }
        else {
            setColumn(copycol,
                {
                    name: <div className='text-center'>Action</div>,
                    key: "action_btn",
                    sortable: false,
                    resizable: false,
                    width: 100
                })

        }
    }
    }, [fromRoute])
    const onRowClick = (record) => {
        if (fromRoute === 'schema-table') {
            setselectedIndex(record.id)

            store.dispatch({ type: SET_SELECTED_BUSINESS_TERM_TEMP, payload: record })
        } else {

            history(Literals.links.DATA_DICTIONARY_VIEW + record.id)
        }
    }



    const getComparator = (sortColumn) => {
        switch (sortColumn) {
            case 'created_by':
                return (a, b) => {
                    let textA = `${a.data.created_by_user.first_name} ${a.data.created_by_user.last_name}`
                    let textB = `${b.data.created_by_user.first_name} ${b.data.created_by_user.last_name}`
                    return textA !== undefined ? textA.localeCompare(textB) : null
                }
            case 'updated_by':
                return (a, b) => {
                    let textA = `${a.data.updated_by_user.first_name} ${a.data.updated_by_user.last_name}`
                    let textB = `${b.data.updated_by_user.first_name} ${b.data.updated_by_user.last_name}`
                    return textA !== undefined ? textA.localeCompare(textB) : null
                }
            case 'classifications':
                return (a, b) => {
                    let textA = a.data?.classification_detail?.map(v => v.name).join(', ')
                    let textB = b.data?.classification_detail?.map(v => v.name).join(', ')
                    return textA !== undefined ? textA.localeCompare(textB) : null
                }
            case 'business_term':
                return (a, b) => a.data[sortColumn] !== undefined ? a.data[sortColumn].localeCompare(b.data[sortColumn]) : null
            case 'description':
                return (a, b) => a.data[sortColumn] !== undefined ? a.data[sortColumn].localeCompare(b.data[sortColumn]) : null
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }

    return <>
    <div className='d-flex align-items-center justify-content-end pb-3 pr-2' style={{marginTop: '-41px'}}>
                        {
                            colsList.filter(col=>col.visible===false).length>0
                            ?
                            <p className='paragraph text-black-50 mr-2 mb-0'>
                                {colsList.filter(col=>col.visible===false).length} field(s) are hidden
                            </p>
                            : ''
                        }
                        <Popover
                            placement="bottomLeft"
                            key='dgt-hide-pop-over0001'
                            title={hidePopover.header}
                            content={hidePopover.body}
                            trigger="click"
                            autoAdjustOverflow={true}
                            open={showHideFieldPop}
                            onOpenChange={handleHideFieldVisibleChange}
                        >
                            <Buttons
                                props={{
                                    buttonText: "",
                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon
                                     ${columns?.every(col=>col.visible===true) ? '' : 'rdg-filter-active' }`,
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        setHideFieldPop(true)
                                    },
                                    tooltip: "Show Hidden Fields",
                                    ImgSrc: () =>  <HIDEPREVIEW />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_TERTIARY,
                                }}
                            />
                        </Popover>
                        
                </div>
        <div className='data-dict-list-table h-100'>
            <LandingSimpleTable
                cols={columns}
                rowsData={data}
                showCursor={true}
                getComparator={getComparator}
                onRowClick={onRowClick}
                tableHeight={'100%'}
                onScrollToBottom={onScrollToBottom}
                loadMore={loadMore}
                selectedRowIndex={selectedIndex}
            />
        </div>
    </>
}

const listTemplate = {
    data_category: (text = '', searchD) => {
        return <>
            <Tooltip title={text === '' ? 'Category Not Available' : text} placement="topLeft">
                <div className='categoryTag d-inline text-uppercase'>
                    {
                        text === ''
                        ?   'N/A'
                        :
                        <Highlighter
                            searchWords={[searchD?.toLocaleLowerCase()]}
                            autoEscape={true}
                            textToHighlight={text ? text : ''}
                        />
                    }
                </div>
            </Tooltip>
        </>
    },
    business_term: (text = '', searchD) => {
        return <>
            <div className='d-flex align-items-center'>
                {/* {
                    getColumnDataType(dtype)
                } */}
                <label className='mb-0 ml-1 text-with-ellipsis'>
                    <Tooltip placement='topLeft' title={text}>
                        <Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} />
                    </Tooltip>
                </label>
            </div>
        </>
    },
    technical_term: (arr) => {
        return <>
            {
                arr.map((v, i) => {
                    return <>
                        {
                            i < 1
                                ?
                                <div key={`tech-term-${i}`} className='custom-badge success mr-2'>
                                    {v}
                                </div>
                                : ''
                        }
                        {
                            i === 1
                                ?
                                <Tooltip placement='bottomLeft' title={arr.slice(1).join(',')}>
                                    <div key={`tech-term-${i}`} className='custom-badge success'>
                                        +{arr.length - 1}
                                    </div>
                                </Tooltip>
                                : ''
                        }
                    </>
                })
            }
        </>
    },
    description: (text = '', searchD) => {
        return <>
            <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div>
        </>
    },
    owners: (owners = [], searchD) => {
        // if (owners.length === 0) {
        //     return owners.length
        // }
        owners = owners.map(v => `${v.first_name} ${v.last_name}`)
        return <>
            <div className='d-flex'>
                {/* {_.isEmpty(owners) ? <div style={{ paddingLeft: "28px" }}>-</div> : ''} */}
                {_.isEmpty(owners) ? <div style={{ paddingLeft: "3px" }}><label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span >{"No Data Owners"}</span></label></div> : ''}

                {/* {owners.map((name, i) => {
                    return (
                        <>
                            {i < 1 ? (
                                <Tooltip key={`owners-ls-${i}`} placement='topLeft' title={name}>
                                    <div
                                        className='text-with-ellipsis w-auto'
                                    >
                                        <Highlighter
                                            searchWords={[searchD?.toLocaleLowerCase()]}
                                            autoEscape={true}
                                            textToHighlight={name ? name : ''}
                                        />
                                        {/* {name} 
                                    </div>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                            {i === 1 ? (
                                <Tooltip
                                    placement="topLeft"
                                    title={owners.slice(1).join(", ")}
                                >
                                    <span
                                        key={`owners-ls-${i}`} className="ml-1"
                                    >
                                        +{owners.length - 1}
                                    </span>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </>
                    );
                })} */}
                {isArray(owners) && owners.length ? <Avatar.Group maxCount={2}>
                    {owners.map((name, i) => {
                        return (

                            <Tooltip placement='topLeft' title={toTitleCase(name)}>
                                <Avatar style={{
                                    backgroundColor: "#2c83c4",
                                    color: "white"
                                }}>{name[0].toUpperCase()}</Avatar>

                            </Tooltip>


                        );
                    })}
                </Avatar.Group> : ''}
            </div>
        </>
    },
    stewards: (stewards = [], searchD) => {
        // if (stewards.length === 0) {
        //     return stewards.length
        // }
        stewards = stewards.map(v => `${v.first_name} ${v.last_name}`)
        return <>
            <div className='d-flex'>
            {/* {_.isEmpty(stewards) ? <div style={{ paddingLeft: "28px" }}>-</div> : ''} */}
            {_.isEmpty(stewards) ? <div style={{ paddingLeft: "3px" }}><label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span >{"No Data Stewards"}</span></label></div> : ''}
                {/* {stewards.length && stewards.map((name, i) => {
                    return (
                        <>
                            {i < 1 ? (
                                <Tooltip key={`stewards-ls-${i}`} placement='topLeft' title={name}>
                                    <div
                                        className='text-with-ellipsis w-auto'
                                    >
                                        <Highlighter
                                            searchWords={[searchD?.toLocaleLowerCase()]}
                                            autoEscape={true}
                                            textToHighlight={name ? name : ''}
                            
                                        {/* {name} 
                                    </div>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                            {i === 1 ? (
                                <Tooltip
                                    placement="topLeft"
                                    title={stewards.slice(1).join(", ")}
                                >
                                    <span
                                        key={`stewards-ls-${i}`} className="ml-1"
                                    >
                                        +{stewards.length - 1}
                                    </span>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </>
                    );
                })} */}
                {isArray(stewards) && stewards.length ? <Avatar.Group maxCount={2}>
                    {stewards.map((name, i) => {
                        return (

                            <Tooltip placement='topLeft' title={toTitleCase(name)}>
                                <Avatar style={{
                                    backgroundColor: "#2c83c4",
                                    color: "white"
                                }}>{name[0].toUpperCase()}</Avatar>

                            </Tooltip>


                        );
                    })}
                </Avatar.Group> : ''}

            </div>
        </>
    },
    created_by: (obj = {}, searchD) => {
        let text = `${obj.first_name} ${obj.last_name}`
        return <>
            <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div>
        </>
    },
    updated_by: (obj = {}, searchD) => {
        let text = `${obj.first_name} ${obj.last_name}`
        return <>
            <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div>
        </>
    },
    
    classifications: (arr = '', searchD) => {
        return <>
            {
                arr.length === 0
                    ?
                    <div className='custom-badge text-black-50'>
                        Not Applicable
                    </div>
                    :
                    arr.map((v, i) => {
                        return <>
                            {
                                i < 1
                                    ?
                                    <Tooltip title={v.name} placement="topLeft">
                                        <div key={`clss--${i}`} className='classificationTag mr-2 text-uppercase'>
                                            <Highlighter
                                                searchWords={[searchD?.toLocaleLowerCase()]}
                                                autoEscape={true}
                                                textToHighlight={v.name ? v.name : ''}
                                            />
                                        </div>
                                    </Tooltip>
                                    : ''
                            }
                            {
                                i === 1
                                    ?
                                    <Tooltip title={arr.map(v => v.name).slice(1).join(", ")} placement="topLeft">
                                        <div key={`clss--${i}`} className='classificationTag'>
                                            +{arr.length - 1}
                                        </div>
                                    </Tooltip>
                                    : ''
                            }
                        </>
                    })
            }


            {/* {
                text !== null
                ?   <Tooltip title={text} placement="topLeft">
                        <div className='classificationTag d-inline text-uppercase'>
                            <Highlighter
                                searchWords={[searchD?.toLocaleLowerCase()]} 
                                autoEscape={true} 
                                textToHighlight={text ? text : ''} 
                            />
                        </div>
                    </Tooltip>
                :   <label className='label mb-0 custom-badge bg-transparent text-black-50 px-0'>
                        Not Applicable
                    </label>
            } */}
        </>
    },
    tech_term: (arr = '', searchD) => {
        return <>
          {

arr.length === 0
    ?
    <div className='custom-badge text-black-50'>
        N/A
    </div>
    :
    arr.map((v, i) => {
        return <>
            {
                i < 1
                    ?
                    <Tooltip title={v.tech_term} placement="topLeft">
                        <div key={`clss--${i}`} className='custom-badge text-black-50 mr-2 text-uppercase'>
                            <Highlighter
                                className='text-elipse'
                                searchWords={[searchD?.toLocaleLowerCase()]}
                                autoEscape={true}
                                textToHighlight={v.tech_term ? v.tech_term : ''}
                            />
                        </div>
                    </Tooltip>
                    : ''
            }

            {
                i === 1
                    ?
                    <Tooltip title={arr.map(v => v.tech_term).slice(1).join(", ")} placement="topLeft">
                        <div key={`clss--${i}`} className='custom-badge text-black-50'>
                            +{arr.length - 1}
                        </div>
                    </Tooltip>
                    : ''
            }
        </>
    })
}


            {/* {
                text !== null
                ?   <Tooltip title={text} placement="topLeft">
                        <div className='classificationTag d-inline text-uppercase'>
                            <Highlighter
                                searchWords={[searchD?.toLocaleLowerCase()]} 
                                autoEscape={true} 
                                textToHighlight={text ? text : ''} 
                            />
                        </div>
                    </Tooltip>
                :   <label className='label mb-0 custom-badge bg-transparent text-black-50 px-0'>
                        Not Applicable
                    </label>
            } */}
        </>
    },
    action: (onAlertClick = () => { }, onAnalyzeClick = () => { }, onDelete = () => { }, id) => {
        return <>
            <div className='d-flex h-100 align-items-center justify-content-center'>
                {/* <Buttons
                    props={{
                        buttonText: "",
                        buttonClassName:
                            "custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent",
                        buttonEvent: (e) => {
                            onAlertClick()
                        },
                        tooltip: "Notification",
                        ImgSrc: () =>  <ALERT />,
                        isDisable: false,
                        buttonType: Literals.BTN_TERTIARY,
                    }}
                />
                <Buttons
                    props={{
                        buttonText: "",
                        buttonClassName:
                            "custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent",
                        buttonEvent: (e) => {
                            onAnalyzeClick()
                        },
                        tooltip: "Analyze",
                        ImgSrc: () =>  <PIE />,
                        isDisable: false,
                        buttonType: Literals.BTN_TERTIARY,
                    }}
                /> */}
                <Buttons
                    props={{
                        buttonText: "",
                        tooltip: "Delete",
                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red border-0 bg-transparent`,
                        buttonEvent: (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            onDelete(id)
                        },
                        ImgSrc: () => <DELETEICON />,
                        isDisable: false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />
            </div>
        </>
    }
}

let originalColumnNames=["name","business_term","description","owners","stewards","classifications","action_btn","tech_term"]
let originalColumns = [
    {
        name: 'Data Category',
        key: "name",
        sortable: false,
        isRequired:true,
        visible:true,
        frozen: true,
        resizable: false,
        width:"10%"

    },
    {
        name: 'Display Name',
        key: "business_term",
        sortable: true,
        width:"15%",

        isRequired:true,
        frozen:true,
        visible:true,
        resizable: false
    },
    {
        name: 'Technical Name',
        key: "tech_term",
        sortable: false,
        width:"15%",

        isRequired:true,
        frozen:true,
        visible:true,
        resizable: false
    },
    /* {
        name: 'Techincal Terms',
        key: "column_techincal_terms",
        sortable: false,
        resizable: false,
        width: 300
    }, */
    {
        name: 'Description',
        key: "description",
        sortable: true,
        visible:true,
        isRequired:true,
        resizable: false,
        width: "25%"
    },
    {
        name: 'Classifications',
        key: "classifications",
        sortable: true,
        isRequired:true,
        visible:true,
        resizable: false,
        width:"15%"
    },
    {
        name: 'Owner(s)',
        key: "owners",
        sortable: false,
        resizable: false,
        isRequired:true,
        visible:true,
        width:"10%"


    },
    {
        name: 'Steward(s)',
        key: "stewards",
        sortable: false,
        resizable: false,
        visible:true,
        isRequired:true,
        width:"10%"
    },
    /* {
        name: 'Domain Name',
        key: "domain_name",
        sortable: true,
        resizable: false
    }, */
    
    {
        name: <div className='text-center'>Action</div>,
        key: "action_btn",
        sortable: false,
        resizable: false,
        width: "2%"
    }
];