import { SET_USER, SET_USER_ADDRESS } from "./actionType";
import { fetchData } from "../../services/apiService";
import { useStore } from "react-redux";
import axios from "axios";
import { setLoadingStatus } from "../modules/common/loadingActions";
// Notifications
import toastr from "toastr";
import Crypto from "crypto-js";

import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../helpers/toast_helper";
import { msalInstance } from "../../components/modules/common/helperFunctions";
import { cloneDeep, isString } from "lodash";
export const getUserSession = () => (dispatch) => {
  let local = localStorage.getItem("user");

  if (local) {
    local = JSON.parse(local);
    dispatch(setUser(local));
  }
};

export const setUserToLocalStorage = (
  user,
  userData,
  refreshToken,
  tokens,
  expiryTime,
  refresh_expiry
) => {
  if (userData) {
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...user,
        // token: tokens,
        // refreshToken: refreshToken,
        userDetails: userData,
        // expiryTime: expiryTime,
        // refresh_expiry: refresh_expiry
      })
    );
  } else {
    let local = localStorage.getItem("user");
    if (local) {
      local = JSON.parse(local);
      local["family_name"] = user["family_name"];
      local["name"] = user["name"];
      local["phone_number"] = user["phone_number"];
      localStorage.setItem("user", JSON.stringify({ ...local }));
    }
  }
};

export const removeMsalToken = () => {
  for (var key in sessionStorage) {
    if (key.indexOf("msal") !== -1) {
      sessionStorage.removeItem(key);
    }
  }
};


export const removeUserToLocalStorage = () => {
  localStorage.removeItem("user");
  localStorage.clear();
  // localStorage.setItem('redirectFrom', window.location.pathname)

  // addWindowReloadListener()
  // window.location.reload()

};

function clearCookie(name, domain) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=" + domain + "; path=/";
}

function clearCookiesForDomain(domain) {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(domain)) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      clearCookie(name, domain);
    }
  }
}

export const encryptMessage = (message) => {
  let key = window._env_.REACT_APP_SHARED_SECRET ?? "123";
  key = key.slice(0, 16);
  key = Crypto.enc.Utf8.parse(key);
  var iv = key;
  var encrypted = Crypto.AES.encrypt(message, key, {
    iv: iv,
    mode: Crypto.mode.CBC,
  });

  encrypted = encrypted.toString();

  return encrypted;
};

export const decryptMessage = (message) => {
  let key = window._env_.REACT_APP_SHARED_SECRET ?? "123";
  key = key.slice(0, 16);
  key = Crypto.enc.Utf8.parse(key);
  var iv = key;
  var bytes = Crypto.AES.decrypt(message, key);
  var originalText = bytes.toString(Crypto.enc.Utf8);


  return originalText;
};

export const callSSOTenantOauth2 = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {

      dispatch(
        fetchData(
          "GET",
          window._env_.REACT_APP_API_BASE_URL + "control_centre/auth/",
          {}
        )
      )
        .then((resp) => {
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification("error", resp.message);
          } else {
            let cloneWindow = JSON.parse(JSON.stringify(window._env_));

            resolve(resp);
          }
        })
        .catch((err) => {
          console.log(err)

          reject(err);
        });
    });
  };
};

export const signIn = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // payload.password = encryptMessage(payload.password);

      dispatch(
        fetchData(
          "POST",
          window._env_.REACT_APP_API_BASE_URL + "auth/login/?is_encrypted=false",
          payload
        )
      )
        .then((resp) => {
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification("error", resp.message);
          } else {
            let responseData = resp;

            resolve(resp);
          }
        })
        .catch((err) => {
          console.log(err)
          if (err && err.message) {
            emitToastNotification("error", err.message);
          } else if (err?.data?.message) {
            emitToastNotification("error", err?.data?.message);

          }
          reject(err);
        });
    });
  };
};
export const microsoftSignIn = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(
          "POST",
          window._env_.REACT_APP_API_BASE_URL +
          "social_auth/microsoft/graph-token-validation",
          payload
        )
      )
        .then((resp) => {
          if (resp.status === 'error') {
            reject(resp.message);
            emitToastNotification("error", resp.message);
          } else {
            resolve(resp);
            let responseData = resp.data;
            // dispatch(getProfileData(responseData));
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message)
          reject(err);
        });
    });
  };
};

export const logoutUser = () => (dispatch) => {

  return new Promise((resolve, reject) => {
    dispatch(setLoadingStatus(true));

    // let refreshToken = null;
    // let local = localStorage.getItem("user");
    // if (local) {
    //   local = JSON.parse(local);
    //   refreshToken = local["refreshToken"];
    // }
    let payload = {}// { refresh: refreshToken };

    dispatch(
      fetchData(
        "POST",
        window._env_.REACT_APP_API_BASE_URL + "auth/logout/",
        payload
      )
    )
      .then(async (resp) => {
        let temp = localStorage.getItem("isMicrosoftAuthenticated");
        if (temp == "true") {
          localStorage.setItem("isMicrosoftAuthenticated", "false");

          // await msalInstance.logoutPopup()
        }
        dispatch(setLoadingStatus(false));
        clearCookiesForDomain(window._env_.REACT_APP_VISUALIZATION_BASE_URL);
        removeMsalToken();
        // jupyter notebook logout
        JupyterNotebookLogout();

        dispatch(userLogut());
        removeUserToLocalStorage();

        resolve(resp);
      })
      .catch((err) => {
        dispatch(setLoadingStatus(false));
        clearCookiesForDomain(window._env_.REACT_APP_VISUALIZATION_BASE_URL);
        removeMsalToken();
        // jupyter notebook logout
        JupyterNotebookLogout();

        dispatch(userLogut());
        removeUserToLocalStorage();

        // reject(err);

      });
  });
};
export const fetchAccessToken = (refresh_token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let requestParams = {
      url: window._env_.REACT_APP_API_BASE_URL + "auth/token/refresh/", //Baseurl.url + endpoint,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ refresh: refresh_token }),
    };
    axios(requestParams)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const userRegister = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // payload.password = encryptMessage(payload.password);

      dispatch(
        fetchData(
          "POST",
          window._env_.REACT_APP_API_BASE_URL + "auth/register",
          payload
        )
      )
        .then((resp) => {
          if (resp.status == "success") {
            emitToastNotification("success", resp.message);
            if (payload.action == "Edit_User_Detail") {
              setUser(payload);
              setUserToLocalStorage(payload);
            }
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const forgotPassword = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(
          "POST",
          window._env_.REACT_APP_API_BASE_URL +
          "user-reset/request-reset-password-otp/",
          payload
        )
      )
        .then((resp) => {
          if (resp.status == "success") {
            emitToastNotification("success", "OTP has been sent over email.");
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const verifyOTP = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // payload.new_password = encryptMessage(payload.new_password);
      // payload.confirm_password = encryptMessage(payload.confirm_password);

      dispatch(
        fetchData(
          "POST",
          window._env_.REACT_APP_API_BASE_URL +
          "user-reset/set-new-password-otp/",
          payload
        )
      )
        .then((resp) => {
          if (resp.status == "success") {
            emitToastNotification(
              "success",
              "Your password has been reset successfully!"
            );
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const resendOTPAPI = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", window._env_.REACT_APP_AUTHENTICATION, payload))
        .then((resp) => {
          if (resp.status == "success") {
            emitToastNotification("success", "OTP sent again over email.");
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const supersetLogin = () => {
  return new Promise(async (res, rej) => {
    const BaseUrl = window._env_.REACT_APP_VISUALIZATION_BASE_URL;

    console.log('supersetLogin hit');
    const link = await retriveSupersetAPIBaseURL(`${BaseUrl}saml/login/`);

    var i = document.createElement("iframe");
    i.style.display = "none";
    i.id = "visualization_login";
    let retryCount = 1
    function receiveMessage(event) {
      const data = event?.data;
      let obj = {}
      if (isString(data)) {

        try {

          obj = JSON.parse(data)
        } catch (e) {
          obj['message'] = ''
        }
        let onPage = obj?.message ?? '';
        if (onPage === 'welcome_page') {
          if (i) {
            i?.parentNode?.removeChild(i);
          }
        }
        // setTimeout(() => {
        //   let onPage = ''
        //   if (isString(data)) {

        //     try {

        //       obj = JSON.parse(data)
        //     } catch (e) {
        //       obj['message'] = ''
        //     }
        //     onPage = obj?.message;
        //   }
        //   if (onPage === 'login_page' && retryCount === 1) {  //retry the login 2 times

        //     if (i) {
        //       i?.parentNode?.removeChild(i);
        //     }
        //     let new_iframe = document.createElement("iframe");
        //     new_iframe.style.display = "none";
        //     new_iframe.id = "new_visualization_login";
        //     new_iframe.src = link;
        //     document.body.appendChild(new_iframe);
        //     retryCount++;
        //     new_iframe.onload = function () {
        //       setTimeout(() => {
        //         new_iframe?.parentNode?.removeChild(i);
        //       }, 30000);
        //       return res(link);
        //     };
        //     console.log(retryCount)
        //   }
        // }, 30000);

      }
    }
    window.addEventListener('message', receiveMessage, false);

    i.onload = function () {
      setTimeout(() => {
        i?.parentNode?.removeChild(i);
      }, 300000);
      return res(link);
    };

    i.src = link;
    document.body.appendChild(i);
    if (link) {
      localStorage.setItem('supersetLoginDone', true)
    }
    return res(link);
  })

}

export const retriveSupersetAPIBaseURL = (endpoint) => {
  const API_BASE_URL = window._env_.REACT_APP_VISUALIZATION_BASE_URL;
  const TENANT_HOST_URL = window._env_.REACT_APP_PUBLIC_API_BASE_URL;
  let locaAPIURL = JSON.parse(localStorage.getItem('TENANT_HOST_URL'));
  return new Promise((res, rej) => {
    if (!API_BASE_URL || (endpoint && endpoint?.startsWith('undefined'))) {
      if (locaAPIURL?.REACT_APP_VISUALIZATION_BASE_URL) {

        if (endpoint && endpoint?.includes('undefined')) {
          let newEndpoint = cloneDeep(endpoint);
          let url = newEndpoint;
          const undefinedStr = "undefined";
          // Find the index of the first occurrence of "undefined"
          let indexOfUndefined = url.indexOf("undefined");

          const startIndex = indexOfUndefined + undefinedStr?.length;

          if (startIndex !== -1) {
            // Extract the substring starting from the first occurrence of "undefined"
            let substringAfterUndefined = url.substring(startIndex);
            endpoint = `${locaAPIURL?.REACT_APP_VISUALIZATION_BASE_URL}${substringAfterUndefined}`;

            return res(endpoint)
          } else {
            res(endpoint)

            console.log("The string 'undefined' was not found in the URL.");
          }
        } else {
          return res(endpoint)
        }
      } else {
        // let requestParams = {
        //   url: `${TENANT_HOST_URL}tenant/tenant_host/`,
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json;charset=UTF-8",
        //   }
        // };
        window.fetch(
          `${TENANT_HOST_URL}tenant/tenant_host/`,
          {
            method: 'POST',
            data: JSON.stringify({
              "UI_HOST": "dev.claristaconnect.com"
            }),
            headers:
            {
              "Content-Type": "application/json;charset=UTF-8",
            }
          }

        ).then((resp) => {

          if (resp.status === 200) {
            let data = resp?.data?.data

            window._env_['REACT_APP_API_BASE_URL'] = `https://${data?.APP_HOST}/`

            window._env_['REACT_APP_VISUALIZATION_BASE_URL'] = `https://${data?.SUPERSET_HOST}/`
            window._env_['REACT_APP_TRINO_HOST_URL'] = `${data?.TRINO_HOST}`

            let host_url = {
              'REACT_APP_API_BASE_URL': `https://${data?.APP_HOST}/`,

              'REACT_APP_VISUALIZATION_BASE_URL': `https://${data?.SUPERSET_HOST}/`,
              'REACT_APP_TRINO_HOST_URL': `${data?.TRINO_HOST}`,

            }
            localStorage.setItem('TENANT_HOST_URL', JSON.stringify(host_url))
            if (endpoint) {
              let newEndpoint = cloneDeep(endpoint);
              let url = newEndpoint;
              const undefinedStr = "undefined";
              // Find the index of the first occurrence of "undefined"
              let indexOfUndefined = url.indexOf("undefined");

              const startIndex = indexOfUndefined + undefinedStr?.length;

              if (startIndex !== -1) {
                // Extract the substring starting from the first occurrence of "undefined"
                let substringAfterUndefined = url.substring(startIndex);
                endpoint = `${data?.SUPERSET_HOST}${substringAfterUndefined}`;

                return res(endpoint)
              } else {
                res(endpoint)

              }
            }
          }
        })
      }
    } else {
      res(endpoint)
    }
  })
}

export const supersetLogout = () => {
  return new Promise(async (res, rej) => {
    const BaseUrl = window._env_.REACT_APP_VISUALIZATION_BASE_URL;

    console.log('supersetLogot hit');
    const link = await retriveSupersetAPIBaseURL(`${BaseUrl}logout/`);
    var i = document.createElement("iframe");
    i.style.display = "none";
    i.id = "visualization_logout";
    i.onload = function () {
      i.parentNode.removeChild(i);
      return res(link);
    };
    i.src = link;
    document.body.appendChild(i);

    // return res(link);
  })

}

export const JupyterNotebookLogout = () => {
  const BaseUrl = window._env_.REACT_APP_API_BASE_URL;

  const link = `${BaseUrl}jupyterhub/hub/logout`;

  var i = document.createElement("iframe");
  i.style.display = "none";
  i.id = "notebook_iframe";
  i.onload = function () {
    i.parentNode.removeChild(i);
  };
  i.src = link;
  document.body.appendChild(i);
  console.log('notebook logout hit')
  supersetLogout();

  return link;

}
export const callMeApi = () => {
  let accessToken = null
  let local = localStorage.getItem("user");
  if (local) {
    local = JSON.parse(local);
    accessToken = local["token"];
  }
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(
          "GET",
          window._env_.REACT_APP_API_BASE_URL + "profile/me/",
          "",
          // accessToken
          null
        )
      )
        .then(async (resp) => {


          resolve(resp);

        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const getProfileData = (responseData) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(
          "GET",
          window._env_.REACT_APP_API_BASE_URL + "profile/me/",
          "",
          null
        )
      )
        .then(async (resp) => {
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification("error", resp.message);
          } else {

            let openai_res = null
            try {
              openai_res = await dispatch(getOpenAiStatus())
            }
            catch {
              openai_res = null
            }
            resp.first_name = resp.first_name?.split(' ').slice(0, 2).join(' ');
            resp.last_name = resp.last_name?.split(' ').slice(0, 2).join(' ');

            await dispatch(
              setUser(
                responseData?.userInformation,
                // responseData?.tokens.access,
                // responseData?.tokens.refresh,
                { ...resp.data, openAi: openai_res?.data ?? null },
                // responseData?.tokens?.access_expiry,
                // responseData?.tokens?.refresh_expiry

              )
            );
            setUserToLocalStorage(
              responseData?.userInformation,
              // responseData?.tokens.access,
              // responseData?.tokens.refresh,
              { ...resp.data, openAi: openai_res?.data ?? null },
              // responseData?.tokens?.access_expiry,
              // responseData?.tokens?.refresh_expiry

            );
            // emitToastNotification("success", "Login Successful");
            // addWindowReloadListener()

            // supersetLogin()
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
export const setUser = (user, userData, refreshToken, tokens, expiryTime, refresh_expiry) => {


  if (userData) {

    return {
      type: SET_USER,
      payload: {
        ...user,
        // token: tokens,
        // refreshToken: refreshToken,
        userDetails: userData,
        // expiryTime: expiryTime,
        // refresh_expiry: refresh_expiry

      },
    };
  } else {
    return {
      type: SET_USER,
      payload: user,
    };
  }
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"))?.userDetails;
};

export const isUserLogin = () => {
  let user = JSON.parse(localStorage.getItem("user"))
  // console.log(user)
  if (user?.userDetails?.email?.length) {
    return true
  } else {
    return false
  }
};

export const updateUserDetails = (payload, id) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(
          "PUT",
          `${window._env_.REACT_APP_API_BASE_URL}profile/${id}/`,
          payload
        )
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification("error", resp.message);
          } else {
            resolve(resp);
            /**
                * Reloading So that from wherever user perform this action
                * should get the updated user info
                */
            dispatch(getProfileData())

            emitToastNotification("success", resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const changeUserPassword = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      // payload.new_password = encryptMessage(payload.new_password);
      // payload.confirm_password = encryptMessage(payload.confirm_password);

      // payload.old_password = encryptMessage(payload.old_password);

      dispatch(
        fetchData(
          "POST",
          `${window._env_.REACT_APP_API_BASE_URL}auth/change-password/`,
          payload
        )
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification("error", resp.message);
          } else {
            let responseData = resp.data.tokens;
            let local = JSON.parse(localStorage.getItem("user"));

            // local["token"] = responseData?.access;
            // local["expiryTime"] = responseData?.access_expiry
            // local["refreshToken"] = responseData?.refresh
            // local["refresh_expiry"] = responseData?.refresh_expiry

            localStorage.setItem("user", JSON.stringify({ ...local }));

            dispatch(
              setUser(
                local,
                // local["token"],
                // local["refreshToken"],
                local["userDetails"],
                // local["expiryTime"],
                // local["refresh_expiry"]
              )
            );

            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const getOpenAiStatus = (showToast = false) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('GET', `${window._env_.REACT_APP_API_BASE_URL}control_centre/openai/`)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};

export const setUpdatedUserData = (userDetails) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  userData['userDetails'] = userDetails;

  localStorage.setItem("user", JSON.stringify(userData));
};

export const userLogut = () => {
  return {
    type: SET_USER,
    payload: {},
  };
};
