import { Checkbox, Input, Popover, Tooltip, Tree } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { ICON_CLOSE, ICON_DEAD, ICON_FILE, ICON_FOLDER, ICON_HOME, ICON_REFRESH, ICON_SEARCH } from '../../../../Common/newIconSource';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { createFolderApiV2, deleteFolderApiV2, deleteFolderTargetTransformer, fetchFileSystem } from '../../../../../store/actions';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';
import { Literals } from '../../../common/literals';
import Buttons from '../../../../Common/button/Buttons';
import SearchInput from '../../../../Common/search/Search';
import { getConfirmationModal, getDeleteMessage, getObjectLength, getReloadErrorTemplate, podNameRegex } from '../../../common/helperFunctions';
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux';
import _ from 'lodash';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import Highlighter from 'react-highlight-words';
import { setTargetPodCreationData } from '../../../../../store/modules/flows/NewFlowsRedux/targetTransformer/targetTransformer';
import { CLOSE, PLUS, SAVE } from '../../../../Common/iconSource';
import { prefix } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../../../Common/deleteModal/DeleteModal';

const S3DirectoryTree = ({

    podData = {},
    toggleValue = 'Existing',
    podRetrievedConnection = '',
    previewHandlerRef,

    checkedElementKeys = [],
    setCheckedElementKeys = () => { },
    connectionType,
    connectionName,
    isExecutePermission,
    selectedExplorerFolder = {},
    setselectedExplorerFolder = () => { },

}) => {

    const reduxData = useSelector(state => state.LabTransformer.TargetTransformer)
    const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
    const [inputName, setInputName] = useState("")

    const [error, setError] = React.useState(undefined)
    const [empty, setEmpty] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [treeData, setTreeData] = React.useState([])
    const [selectedKeys, setSelectedKeys] = React.useState([]
        // (() => {
        //     if (selectedExplorerFolder?.selectedFolder?.length) {
        //         return [...selectedExplorerFolder?.folderPath]
        //     }
        //     return []
        // })()
    );
    const [expandedKeys, setExpandedKeys] = React.useState([...reduxData?.explorerExpandedKeys]);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [searchData, setSearchData] = React.useState('');
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [treeDisabled, setTreeDisabled] = React.useState(false);
    const [overlayLoading, setOverlayLoading] = React.useState(false);
    const [treeHeight, setTreeHeight] = React.useState('calc(100vh - 310px)'); // For Virtual Scroll
    const [isTreeHeightCalculated, setTreeHeightCalculated] = React.useState(false); // For Virtual Scroll
    const [selectedFiles, setSelectedFiles] = React.useState([...checkedElementKeys]); // For Unstructured
    // const [selectedFiles, setSelectedFiles] = React.useState([...checkedFiles]); // For Unstructured
    const [visible, setVisible] = React.useState(false);
    const [visibleRootPopup, setVisibleRootPopup] = React.useState(false);
    const [renameText, setRenameText] = React.useState('');

    const treeRef = useRef(null)
    const deleteFolder = useRef({

    })

    const selectedFolderRef = useRef([])

    const [checkedKeys, setCheckedKeys] = React.useState(
        (() => {
            if (reduxData?.selectedFolder) {
                return [...reduxData?.selectedFolder]
            }
            return []
        })()
    )



    const currentSelectedInfo = React.useRef({});

    const dispatch = useDispatch()
    const store = useStore()




    React.useEffect(() => {

        if (connectionName) {

            fetchAllData()
        }
    }, [connectionName, toggleValue])

    React.useEffect(() => {
        if (treeData?.length > 0 && !isTreeHeightCalculated) {
            const el = document.querySelector('.exp-dir-target-wrap .ant-tree.ant-tree-directory')
            if (el?.scrollHeight) {
                setTreeHeight(el?.scrollHeight)
                setTreeHeightCalculated(true)
            }
        }

    }, [treeData, isTreeHeightCalculated, selectedKeys])

    React.useEffect(() => {
        // console.log(selectedKeys, selectedFolderRef?.current)
        if (treeData?.length) {

            if (treeRef.current && selectedFolderRef?.current.length > 0) {

                // Use the scrollTo method provided by antd Tree component
                treeRef.current.scrollTo({ key: selectedFolderRef?.current[0] });
            }

        }
    }, [selectedKeys])



    const handleSaveClick = async (item) => {

        if (renameText) {
            let payload = {
                connection_name: connectionName,
                "folder": item === 'root_folder' ? renameText : `${item?.key}/${renameText}`
            }
            try {
                const { status } = await dispatch(createFolderApiV2(payload, false, false))
                if (status && item !== 'root_folder') {
                    setSelectedKeys([`${item?.key}/${renameText}`])
                    selectedFolderRef.current = [`${item?.key}/${renameText}`]

                    setExpandedKeys([...expandedKeys, item.key])
                    setselectedExplorerFolder({ selectedFolder: [renameText], folderPath: `${item?.key}/${renameText}`, expandedKeys: [...expandedKeys, item.key] })

                    onLoadData(item.key, undefined);

                    if (treeRef.current) {

                        // Use the scrollTo method provided by antd Tree component
                        treeRef.current.scrollTo({ key: `${item?.key}/${renameText}` });
                    }
                    setRenameText('')
                    setVisible(false)
                }

                if (status && item === 'root_folder') {
                    setSelectedKeys([`${renameText}`])
                    selectedFolderRef.current = [renameText]
                    setselectedExplorerFolder({ selectedFolder: [renameText], folderPath: renameText, expandedKeys: expandedKeys })
                    fetchAllData()
                    setRenameText('')
                    setVisibleRootPopup(false);
                }


            }
            catch (err) {

            }

        }

    }

    const checkRegex = (key, e) => {
        let keyCode = e.key

        //Regex to allow only Alphabets Numbers Dash Underscore and Space
        let pattern = /^[a-zA-Z0-9_]*$/;

        //Validating the textBox value against our regex pattern.
        let isValid = pattern.test(keyCode);
        let match = keyCode.match(pattern)
        if ((!e.target?.value?.length && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106))) || (e.target?.selectionStart === 0 && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)))) {
            e.preventDefault()
        } else if (!isValid) {
            e.preventDefault()
        }
    }

    const treeDataMemo = React.useMemo(() => {


        const loop = (data) =>
            data.map((item) => {
                const strTitle = item.name;
                const strTitleLower = item?.name?.toLowerCase();
                const index = strTitleLower.indexOf(searchData?.toLowerCase());
                const beforeStr = strTitle.substring(0, index);
                const afterStr = strTitle.slice(index + searchData.length);

                const title =
                    index > -1 ? (

                        <div className='d-flex align-items-center justify-content-between position-relative'>
                            <span style={{ textOverflow: "ellipsis", overflow: "hidden" }} className='col-8 pl-0 pr-0' title={strTitle}>
                                {
                                    searchData === ''
                                        ? <>
                                            {beforeStr}
                                            <span className="site-tree-search-value">{searchData}</span>
                                            {afterStr}
                                        </>
                                        :
                                        <Highlighter
                                            searchWords={[searchData?.toLocaleLowerCase()]}
                                            autoEscape={true}
                                            textToHighlight={item?.name}
                                        >
                                            <span className="">{item?.name}</span>
                                        </Highlighter>
                                }
                            </span>
                            {item?.key === selectedKeys[0] ?
                                <div className='d-flex align-items-center folder-action-btn'>
                                    <Buttons
                                        props={{
                                            tooltip: 'Remove Folder',
                                            buttonText: "Add",
                                            tooltipPlacement: "right",
                                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                                            buttonEvent: (e) => {

                                                e.stopPropagation();
                                                e.preventDefault();
                                                if (!isExecutePermission) return
                                                deleteFolder.current = item
                                                setOpenDeletePopup(true);
                                                // setRenameText('')
                                                // setVisible(true)
                                            },
                                            ImgSrc: () => <i class='bx bxs-trash' style={{ color: "#FFF", fontSize: "1.3125rem" }}></i>, //<PLUS width='12' height='12' />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                                        }}
                                    />
                                    <Popover
                                        // destroyTooltipOnHide={true}
                                        content={
                                            <div style={{

                                                padding: "0.3125rem 0.6875rem",

                                            }}>
                                                <label className='label text-black-50'>Folder Name</label>
                                                <form
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Tooltip title={renameText}>

                                                        <Input

                                                            onKeyDown={(e) => { e.stopPropagation(); checkRegex(e.key, e) }}
                                                            defaultValue={renameText}
                                                            onClick={(e) => e?.stopPropagation()}
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                setRenameText(e.target.value);
                                                            }}
                                                            className="custom-inputfield"
                                                        />
                                                    </Tooltip>
                                                    <Buttons
                                                        props={{
                                                            actionType: "submit",
                                                            buttonText: "",
                                                            buttonClassName:
                                                                "custom-btn-outline custom-btn btn-with-icon ml-1",
                                                            buttonEvent: (e) => {
                                                                e.preventDefault();
                                                                handleSaveClick(item);
                                                            },
                                                            ImgSrc: () => <SAVE />,
                                                            isDisable: false,
                                                            buttonType: Literals.BTN_SECONDARY,
                                                        }}
                                                    />
                                                </form>
                                            </div>

                                        }
                                        title={
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                style={{
                                                    display: "flex",
                                                    padding: "0.625rem",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <span>Create New Folder</span>{" "}
                                                <Buttons
                                                    props={{
                                                        buttonText: "",
                                                        buttonClassName:
                                                            "custom-btn-outline custom-btn btn-with-icon",
                                                        buttonEvent: (e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            setVisible(false);
                                                        },
                                                        ImgSrc: () => <CLOSE />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_TERTIARY,
                                                    }}
                                                />
                                            </div>
                                        }
                                        onOpenChange={(visible) => setVisible(visible)}
                                        trigger="click"
                                        open={isExecutePermission && visible}
                                        placement="right"
                                    >
                                        <Buttons
                                            props={{
                                                tooltip: 'Add Folder',
                                                buttonText: "Add",
                                                tooltipPlacement: "right",
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                                                buttonEvent: (e) => {

                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    if (!isExecutePermission) return
                                                    setRenameText('')
                                                    setVisible(true)
                                                },
                                                ImgSrc: () => <i className='bx bxs-folder-plus' style={{ color: "#FFF", fontSize: "1.25rem" }}></i>, //<PLUS width='12' height='12' />,
                                                isDisable: false,
                                                buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                                            }}
                                        />
                                    </Popover>


                                </div>
                                // <Popover
                                //     // destroyTooltipOnHide={true}
                                //     content={
                                //         <div style={{

                                //             padding: "5px 11px",

                                //         }}>
                                //             <label className='label text-black-50'>Folder Name</label>
                                //             <form
                                //                 onClick={(e) => {
                                //                     e.stopPropagation();
                                //                     e.preventDefault();
                                //                 }}
                                //                 style={{
                                //                     display: "flex",
                                //                     alignItems: "center",
                                //                 }}
                                //             >
                                //                 <Tooltip title={renameText}>

                                //                     <Input

                                //                         onKeyDown={(e) => { e.stopPropagation(); checkRegex(e.key, e) }}
                                //                         defaultValue={renameText}
                                //                         onClick={(e) => e?.stopPropagation()}
                                //                         onChange={(e) => {
                                //                             e.preventDefault();
                                //                             e.stopPropagation();
                                //                             setRenameText(e.target.value);
                                //                         }}
                                //                         className="custom-inputfield"
                                //                     />
                                //                 </Tooltip>
                                //                 <Buttons
                                //                     props={{
                                //                         actionType: "submit",
                                //                         buttonText: "",
                                //                         buttonClassName:
                                //                             "custom-btn-outline custom-btn btn-with-icon ml-1",
                                //                         buttonEvent: (e) => {
                                //                             e.preventDefault();
                                //                             handleSaveClick(item);
                                //                         },
                                //                         ImgSrc: () => <SAVE />,
                                //                         isDisable: false,
                                //                         buttonType: Literals.BTN_SECONDARY,
                                //                     }}
                                //                 />
                                //             </form>
                                //         </div>

                                //     }
                                //     title={
                                //         <div
                                //             onClick={(e) => {
                                //                 e.stopPropagation();
                                //                 e.preventDefault();
                                //             }}
                                //             style={{
                                //                 display: "flex",
                                //                 padding: "10px",
                                //                 justifyContent: "space-between",
                                //             }}
                                //         >
                                //             <span>Create New Folder</span>{" "}
                                //             <Buttons
                                //                 props={{
                                //                     buttonText: "",
                                //                     buttonClassName:
                                //                         "custom-btn-outline custom-btn btn-with-icon",
                                //                     buttonEvent: (e) => {
                                //                         e.stopPropagation();
                                //                         e.preventDefault();
                                //                         setVisible(false);
                                //                     },
                                //                     ImgSrc: () => <CLOSE />,
                                //                     isDisable: false,
                                //                     buttonType: Literals.BTN_TERTIARY,
                                //                 }}
                                //             />
                                //         </div>
                                //     }
                                //     onOpenChange={(visible) => setVisible(visible)}
                                //     trigger="click"
                                //     open={isExecutePermission && visible}
                                //     placement="right"
                                // >
                                //     <Buttons
                                //         props={{
                                //             tooltip: 'Add Folder',
                                //             buttonText: "Add",
                                //             tooltipPlacement: "right",
                                //             buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                                //             buttonEvent: (e) => {

                                //                 e.stopPropagation();
                                //                 e.preventDefault();
                                //                 if (!isExecutePermission) return
                                //                 setRenameText('')
                                //                 setVisible(true)
                                //             },
                                //             ImgSrc: () => <i className='bx bxs-folder-plus' style={{ color: "#FFF", fontSize: "20px" }}></i>, //<PLUS width='12' height='12' />,
                                //             isDisable: false,
                                //             buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                                //         }}
                                //     />
                                // </Popover>

                                : null}
                        </div>
                    ) : (

                        <div className='d-flex align-items-center justify-content-between position-relative'>
                            <span style={{ textOverflow: "ellipsis", overflow: "hidden" }} className='col-8 pl-0 pr-0' title={strTitle}>
                                {strTitle}
                            </span>
                            {item?.key === selectedKeys[0] ? <div className='d-flex align-items-center folder-action-btn'>
                                <Buttons
                                    props={{
                                        tooltip: 'Remove Folder',
                                        buttonText: "Add",
                                        tooltipPlacement: "right",
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                                        buttonEvent: (e) => {

                                            e.stopPropagation();
                                            e.preventDefault();
                                            if (!isExecutePermission) return
                                            deleteFolder.current = item
                                            setOpenDeletePopup(true)

                                            // setRenameText('')
                                            // setVisible(true)
                                        },
                                        ImgSrc: () => <i class='bx bxs-trash' style={{ color: "#FFF", fontSize: "1.3125rem" }}></i>, //<PLUS width='12' height='12' />,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                                    }}
                                />
                                <Popover
                                    // destroyTooltipOnHide={true}
                                    content={
                                        <div style={{

                                            padding: "0.3125rem 0.6875rem",

                                        }}>
                                            <label className='label text-black-50'>Folder Name</label>

                                            <form
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Tooltip title={renameText}>
                                                    <Input
                                                        onKeyDown={(e) => { e.stopPropagation(); checkRegex(e.key, e) }}
                                                        value={renameText}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setRenameText(e.target.value);
                                                        }}
                                                        className="custom-inputfield"
                                                    />
                                                </Tooltip>
                                                <Buttons
                                                    props={{
                                                        actionType: "submit",
                                                        buttonText: "",
                                                        buttonClassName:
                                                            "custom-btn-outline custom-btn btn-with-icon ml-1",
                                                        buttonEvent: (e) => {
                                                            e.preventDefault();
                                                            handleSaveClick(item);
                                                        },
                                                        ImgSrc: () => <SAVE />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_SECONDARY,
                                                    }}
                                                />
                                            </form>
                                        </div>
                                    }
                                    title={
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            style={{
                                                display: "flex",
                                                padding: "0.625rem",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <span>Create New Folder</span>{" "}
                                            <Buttons
                                                props={{
                                                    buttonText: "",
                                                    buttonClassName:
                                                        "custom-btn-outline custom-btn btn-with-icon",
                                                    buttonEvent: (e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setVisible(false);
                                                    },
                                                    ImgSrc: () => <CLOSE />,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_TERTIARY,
                                                }}
                                            />
                                        </div>
                                    }
                                    onOpenChange={(visible) => setVisible(visible)}
                                    trigger="click"
                                    open={isExecutePermission && visible}
                                    placement="right"
                                >
                                    <Buttons
                                        props={{
                                            tooltip: 'Add Folder',
                                            buttonText: "Add",
                                            tooltipPlacement: "right",
                                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                                            buttonEvent: (e) => {

                                                e.stopPropagation();
                                                e.preventDefault();
                                                if (!isExecutePermission) return
                                                setRenameText('')
                                                setVisible(true)
                                            },
                                            ImgSrc: () => <i className='bx bxs-folder-plus' style={{ color: "#FFF", fontSize: "1.25rem" }}></i>, //<PLUS width='12' height='12' />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                                        }}
                                    />
                                </Popover>

                            </div>
                                : null}
                        </div>
                    );
                let children = []
                if (item.children?.length) {
                    children = loop(item.children)

                    item['children'] = children;
                }

                return {
                    ...item,
                    name: strTitle,
                    title
                };
            });

        let clone = [...treeData].filter(el => {
            if (el?.children) {
                return el?.name?.toLowerCase()?.includes(searchData?.toLowerCase()) || el?.children?.some(e => e?.name?.toLowerCase()?.includes(searchData?.toLowerCase()))
            }
            return el?.name?.toLowerCase()?.includes(searchData?.toLowerCase())
        })




        return loop(clone);
    }, [searchData, treeData, selectedFiles, selectedKeys, visible, renameText]);

    const fetchAllData = async () => {
        let reduxData = store?.getState().LabTransformer.TargetTransformer;

        if (reduxData?.length > 0) return

        setLoading(true)
        setError(undefined)
        let keysArr = []



        const requestBody = {
            connection_name: connectionName,
            prefix: "",
            next_token: ""
        };
        dispatch(fetchFileSystem(requestBody, false))
            .then(res => {
                let folders = res?.data?.folder
                folders = folders.map(f => {
                    return {
                        title: f.name,
                        name: f?.name,
                        key: `${f?.name}`,
                        icon: <ICON_FOLDER height='0.875rem' width='0.875rem' />,
                        isFolder: true,
                        location: f?.path
                    }
                })


                setEmpty(folders?.length === 0)
                setTreeData([...folders])


                reduxData.explorerTreeData = [...folders]
                setReduxData(reduxData)


                setLoading(false)


                if (selectedExplorerFolder?.selectedFolder?.length) {
                    if (selectedExplorerFolder?.expandedKeys?.length) {
                        setExpandedKeys([...selectedExplorerFolder?.expandedKeys])

                        autoLoad([...selectedExplorerFolder?.expandedKeys]);

                    } else {

                        setSelectedKeys([...selectedExplorerFolder?.selectedFolder])
                        if (!selectedFolderRef.current?.length) {

                            selectedFolderRef.current = selectedExplorerFolder?.selectedFolder;
                        }

                    }
                } else {
                    if (selectedFolderRef.current?.length) {

                        setSelectedKeys(selectedFolderRef.current)
                    } else {
                        setSelectedKeys([])

                    }
                }

                if (connectionName !== podRetrievedConnection) return

                else {

                }

            })
            .catch(err => {
                setError(getReloadErrorTemplate(
                    {
                        errorMessage: err?.message,
                        onReload: fetchAllData
                    }
                ))
                setLoading(false)
            })
    }

    const autoLoad = async (keys) => {
        setOverlayLoading(true)
        const sourcePath = podData?.source_detail?.[0]
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            await onLoadData({ key })
        }

        onExpand(keys)
        if (selectedExplorerFolder?.selectedFolder?.length && !selectedFolderRef.current?.length) {
            if (!selectedFolderRef.current?.length) {

                selectedFolderRef.current = [selectedExplorerFolder?.folderPath];
            }
            setSelectedKeys([selectedExplorerFolder?.folderPath])
        } else {
            if (selectedFolderRef.current?.length) {

                setSelectedKeys(selectedFolderRef.current)
            }

        }

        // onSelect([_.last(keys)], { node: { location: sourcePath, autoLoad: true } })

        setOverlayLoading(false)




    }

    const setReduxData = (data) => {
        dispatch(setTargetPodCreationData(data))
    }

    const onSelect = (keys, info, fromModal = false) => {
        if (treeDisabled) return
        let reduxData = store?.getState()?.LabTransformer.TargetTransformer;
        if (!info?.node?.autoLoad && !fromModal && getObjectLength(reduxData?.tableData?.result) > 0) {
            if (reduxData?.selectedFolder?.[0] === keys[0]) return
            currentSelectedInfo.current = { keys, info }
            setOpenConfirm(true)
            return
        }
        setSelectedKeys([...keys])
        selectedFolderRef.current = [...keys]

        reduxData.selectedFolder = [info?.node.name]
        reduxData.folderPath = info?.node?.key //info?.node?.location


        setReduxData(reduxData)

        setselectedExplorerFolder({ selectedFolder: reduxData.selectedFolder, folderPath: reduxData.folderPath, expandedKeys: expandedKeys })

        setSelectedFiles(prev => {
            prev = prev?.filter(f => f?.parentName === keys?.[0])
            return [...prev]
        })

    };

    const onExpand = (keys, info) => {

        if (treeDisabled) return
        let reduxData = store?.getState()?.LabTransformer.TargetTransformer
        setExpandedKeys([...keys])

        reduxData.explorerExpandedKeys = [...keys]
        setReduxData(reduxData)
    };

    const onConfirmModal = () => {

        onSelect(currentSelectedInfo.current?.keys, currentSelectedInfo.current?.info, true)

    }

    const onCancelModal = () => {
        currentSelectedInfo.current = {}
    }

    const onCheck = (checkedKeysValue, info, fromModal = false) => {
        // console.log({checkedKeysValue})
        let reduxData = store?.getState()?.LabTransformer.TargetTransformer

        if (
            !fromModal
            &&
            getObjectLength(reduxData?.tableData?.result) > 0
            &&
            checkedKeys?.length !== checkedKeysValue?.length
        ) {
            currentSelectedInfo.current = { keys: checkedKeysValue, info }
            setOpenConfirm(true)
            return
        }

        if (info?.node?.autoLoad) {
            setCheckedKeys(podData?.pod_file_info?.file_names)
            setCheckedElementKeys(podData?.pod_file_info?.file_names)
            reduxData.selectedFolder = [...podData?.pod_file_info?.file_names]
        }
        else {
            setCheckedKeys(checkedKeysValue);
            setCheckedElementKeys(checkedKeysValue)
            reduxData.selectedFolder = [...checkedKeysValue]
        }

        reduxData.folderPath = info?.node?.location

        setReduxData(reduxData)
    };



    const onLoadData = ({ key, children, ...rest }) => {
        // if(treeDisabled) return
        return new Promise((resolve) => {

            const parentName = key ?? rest?.name

            if (children) {
                resolve();
                return;
            }
            const requestBody = {
                connection_name: connectionName,
                prefix: key
            }

            dispatch(fetchFileSystem(requestBody))
                .then(res => {
                    let reduxData = store?.getState()?.LabTransformer.TargetTransformer

                    let folders = res?.data?.folder
                    let objects = res?.data?.object
                    let temp = []
                    folders?.forEach(f => {
                        temp.push({
                            title: <div className='d-flex align-items-center justify-content-between'>
                                <span className='ml-1 mr-1'>{f?.name}</span>
                                {/* <Buttons
                                    props={{
                                        tooltip: 'Add Folder',
                                        buttonText: "Add",
                                        tooltipPlacement: "right",
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                                        buttonEvent: () => {

                                        },
                                        ImgSrc: () => <PLUS width='12' height='12' />,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                                    }}
                                /> */}
                            </div>,
                            key: `${parentName}/${f?.name}`,
                            name: f.name,
                            path: `${parentName}/${f?.name}`,
                            icon: <ICON_FOLDER height='14' width='14' />,
                            location: f?.path,
                            isFolder: true,
                            parentName
                        })
                    })
                    objects?.forEach(f => {
                        temp.push({
                            title: f?.name,
                            name: f.name,
                            key: `${parentName}/${f?.name}`,
                            path: `${parentName}/${f?.name}`,
                            location: f?.path, // Full Path of the Object
                            isLeaf: true,
                            disabled: !isExecutePermission,
                            selectable: false,
                            isFile: true,
                            icon: <ICON_FILE height='0.875rem' width='0.875rem' />,
                            parentName
                        })
                    })


                    setTreeData((origin) => {
                        const newData = updateTreeData(origin, key, temp)

                        reduxData.explorerTreeData = [...newData]
                        setReduxData(reduxData)

                        return newData
                    });


                    resolve()
                })
                .catch(err => {

                })
        }
        )
    }

    useEffect(() => {
        setInputName("");
    }, [openDeletePopup])

    const handleDelete = async () => {
        console.log(deleteFolder.current)
        setOpenDeletePopup(false)

        let response;
        let folder_name = `${deleteFolder.current.parentName}/${deleteFolder.current.name}`
        let payload = {
            connection_name:connectionName,
            folder: folder_name
        }

        try {
            response = await store.dispatch(deleteFolderTargetTransformer(payload
            ))

            if (response.status === "success") {
                fetchAllData()
            }

        }
        catch (error) { }

    }
    // treeRef.current.scrollTo({ key: selectedKeys[0] });
    return (
        <div className={`h-100 exp-dir-target-wrap ${isExecutePermission ? 'src-target-view' : ''}`}>
            {
                loading ? <ClaristaLoader />
                    :
                    error ? error
                        : empty
                            ? <div className='my-5 mx-auto w-50'>
                                <div className='alert alert-info text-center fontSizeHeading'>
                                    No Folders Found
                                </div>
                            </div>
                            :
                            <div className='position-relative'>
                                {
                                    overlayLoading ? <div className='d-block position-absolute h-100 w-100'
                                        style={{ top: 0, left: 0, zIndex: 2, background: '#ffffff6e' }}>
                                        <ClaristaLoader />
                                    </div>
                                        : ''
                                }
                                <div className='target-tree-header-pane d-flex align-items-center justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <ICON_HOME height='1rem' width='1rem' color='#2C83C4' />
                                        <label className='mb-0 color-primary ml-2 fontInterSemiBold fontSizeHeading'
                                            style={{ lineHeight: 1 }}
                                        >
                                            Root
                                        </label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {
                                            openSearch
                                                ? <SearchInput
                                                    searchData={searchData}
                                                    setSearchData={setSearchData}
                                                    searchBoxOpen={false}
                                                    showSearchClose={false}
                                                />
                                                : ''
                                        }
                                        <Buttons
                                            props={{
                                                tooltip: openSearch ? "Close Search" : "Open Search",
                                                buttonText: "",
                                                tooltipPlacement: "right",
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                                                buttonEvent: () => {
                                                    setSearchData('')
                                                    setOpenSearch(!openSearch)
                                                },
                                                ImgSrc: () => openSearch ? <ICON_CLOSE /> : <ICON_SEARCH />,
                                                isDisable: false,
                                                buttonType: Literals.BTN_TERTIARY,
                                            }}
                                        />
                                        <Popover
                                            // destroyTooltipOnHide={true}
                                            content={
                                                <div style={{

                                                    padding: "0.3125rem 0.6875rem",

                                                }}>
                                                    <label className='label text-black-50'>Folder Name</label>

                                                    <form
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                        }}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Tooltip title={renameText}>
                                                            <Input
                                                                onKeyDown={(e) => { e.stopPropagation(); checkRegex(e.key, e) }}
                                                                value={renameText}
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    setRenameText(e.target.value);
                                                                }}
                                                                className="custom-inputfield"
                                                            />
                                                        </Tooltip>
                                                        <Buttons
                                                            props={{
                                                                actionType: "submit",
                                                                buttonText: "",
                                                                buttonClassName:
                                                                    "custom-btn-outline custom-btn btn-with-icon ml-1",
                                                                buttonEvent: (e) => {
                                                                    e.preventDefault();
                                                                    handleSaveClick('root_folder');
                                                                },
                                                                ImgSrc: () => <SAVE />,
                                                                isDisable: false,
                                                                buttonType: Literals.BTN_SECONDARY,
                                                            }}
                                                        />
                                                    </form>
                                                </div>
                                            }
                                            title={
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        padding: "0.625rem",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <span>Create New Folder</span>{" "}
                                                    <Buttons
                                                        props={{
                                                            buttonText: "",
                                                            buttonClassName:
                                                                "custom-btn-outline custom-btn btn-with-icon",
                                                            buttonEvent: (e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setVisibleRootPopup(false);
                                                            },
                                                            ImgSrc: () => <CLOSE />,
                                                            isDisable: false,
                                                            buttonType: Literals.BTN_TERTIARY,
                                                        }}
                                                    />
                                                </div>
                                            }
                                            onOpenChange={(visible) => setVisibleRootPopup(visible)}
                                            trigger="click"
                                            open={isExecutePermission && visibleRootPopup}
                                            placement="right"
                                        >
                                            <Buttons
                                                props={{
                                                    tooltip: 'Add Folder',
                                                    buttonText: "Add",
                                                    tooltipPlacement: "right",
                                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                                                    buttonEvent: (e) => {

                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        if (!isExecutePermission) return
                                                        setVisibleRootPopup(true)
                                                    },
                                                    ImgSrc: () => <i className='bx bxs-folder-plus' style={{ color: "#2c83c4", fontSize: "1.25rem" }}></i>, //<PLUS width='12' height='12' />,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                                                }}
                                            />
                                        </Popover>

                                    </div>

                                </div>
                                {
                                    treeDataMemo.length === 0 ? <div className='text-center text-black-50 p-3 fontSizeHeading'>No Data Found</div>
                                        :
                                        <Tree.DirectoryTree
                                            ref={treeRef}
                                            // selectable={!treeDisabled}
                                            expandAction={!treeDisabled}
                                            selectedKeys={selectedKeys}
                                            expandedKeys={expandedKeys}
                                            onCheck={onCheck}
                                            onSelect={onSelect}
                                            onExpand={onExpand}
                                            treeData={treeDataMemo}
                                            loadData={onLoadData}
                                            checkedKeys={checkedKeys}
                                            disabled={treeDisabled}
                                            height={treeHeight}
                                            className='target-pod-crt-tree-comp'
                                            checkable={false}
                                            selectable={true}
                                        />
                                }
                                {/* {
                                treeDisabled ? <div className='position-absolute d-block h-100 w-100'
                                style={{top: 0, left: 0, zIndex: 2, background: '#ffffff6e', cursor: 'not-allowed'}}
                                >
                                </div>
                                :   ''
                            } */}
                            </div>
            }
            {
                getConfirmationModal({
                    open: openConfirm,
                    setOpen: setOpenConfirm,
                    body: 'This will reset the preview, are you sure want to continue?',
                    onConfirm: onConfirmModal,
                    onCancel: onCancelModal
                })
            }
            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={<>
                    <p className="fontSizeHeading font-weight-bold mb-2">
                        {getDeleteMessage({ title: 'Delete Folder' })}
                    </p>
                    <p className="fontSizeHeading font-weight-normal">
                        To confirm deletion, type <em>Folder name</em> in the text input field.
                    </p>
                    <input
                        type="text"
                        // onPaste={(e)=>e.preventDefault()} 
                        // onCopy={(e)=>e.preventDefault()} 
                        // onDrop={(e)=>e.preventDefault()} 
                        placeholder='Example: type "ABCD" to delete domain ABCD'
                        value={inputName}
                        onChange={(e) => { setInputName(e.target.value) }}
                        className="form-control"
                    />

                </>}
                onConfirm={() => { handleDelete() }}
                deleteBtnDisabled={!(deleteFolder.current?.name?.toUpperCase() === inputName.toUpperCase())}
            />
        </div>
    )
}

export default S3DirectoryTree

const updateTreeData = (list, key, children) =>
    list.map((node) => {
        if (node.key === key) {
            return {
                ...node,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });