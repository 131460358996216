import { Checkbox, Tabs, Tooltip } from "antd";
import _, { cloneDeep } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector, useStore } from "react-redux";
import Buttons from "../../../../Common/button/Buttons";
import {
  CLOSE,
  CODEEDITOR,
  DATASET,
  EDIT,
  EDITOR,
  INPUT,
  LINK,
  PLAY,
  PLUS,
  RESETFILTER,
  SAVE,
  TABLEICON,
  TABLEINSERT,
  TABLEUPDATE,
  TARGET,
} from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import DraggableList from "../../draggableList";

import { useParams } from "react-router-dom";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { setActivePodDetails } from "../../../../../store/modules/common/VirtualDataTable/action";
import {
  executeTransformerApi,
  saveTransformerNoteClick,
  setFlowsCurrenttransformer,
  showFlowsTransformdetails,
  updateFlowsElements,
} from "../../../../../store/modules/flows/flowsActions";
import {
  resetTargetRedux,
  setConnectionDetails,
  setTargetTableDetails,
} from "../../../../../store/modules/flows/targetTransformer/targetTransformer";
import {
  getColumnDataType,
  getConfirmationModal,
  getObjectLength,
  mergeArrayByProperty,
} from "../../../common/helperFunctions";
import SourceTypeImage from "../../../common/sourceTypeImage";
import { ConnectionProvider } from "../../../connections/landingPage/components/ConnectionContext";

import DatasetOutputNameInput from "../commonFunctions/useDatasetNameInput";
import EditorIndex from "../DataSource/editor/EditorIndex";
import { SCRIPTDEFAULTVIEW } from "../script/ScriptDefaultInfography";
import { NoTableSelected } from "./NoTableSelected";
import TargetConnectionModal from "./TargetConnectionModal";
import { setConnectionSelectedConn } from "../../../../../store/modules/UserManagement/connection/connectionActions";
import { getInputFrame, getInputTransformerDetails, orderRetainCheck } from "../FlowsHelperFunction";
import { ContentViewGridTable } from "../../../../Common/contentGridTable/ContentViewGridTable";
import { DndVirtualList } from "../../../../Common/dndVirtualList/DndVirtualList";
const { TabPane } = Tabs;

const TargetIndex = ({
  flowsElements,
  transformerNameList,
  transformerLoading,
  currentTransformer,
  appliedFilterCriteria,
  isExecutePermission,
  ...props
}) => {
  const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false);
  const [toggleValue, setToggleValue] = useState("overwrite");
  const [context] = useState("overwrite");
  const [disableExcute, setdisableExcute] = useState(true);
  const [columnList, setcolumnList] = useState([]);
  const [targetColumns, settargetColumns] = useState([]);
  const [activeKey, setActiveKey] = useState("properties");
  const dispatch = useDispatch();
  const [toggleDefaultView, setToggleDefaultView] = useState(true);
  const [showResetColumns, setshowResetColumns] = useState(false)
  const [openWarningModal, setopenWarningModal] = useState(false)

  const targetTableDetails = useSelector(
    (state) => state.Flows.TargetTransformer.targetTableDetails
  );
  let connectionDetails = useSelector(
    (state) => state.Flows.TargetTransformer.connectionDetails
  );
  const [schemaName, setschemaName] = useState("");
  const [sourceType, setsourceType] = useState("");
  const [tableName, settableName] = useState("");
  const [showBtnLoading, setShowBtnLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [connectionName, setconnectionName] = useState("");
  const [allChecked, setallChecked] = useState(false);
  const [transformerName, settransformerName] = useState("");
  const [showRedBtn, setshowRedBtn] = useState(false);

  const [isdropTable, setisdropTable] = useState(false);
  const store = useStore();
  const { flowId } = useParams();
  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };

  // useEffect(() => {
  //   if (columnList && columnList.length) {
  //     setallChecked(columnList.every((ele) => ele.checked === true));
  //   }
  // }, [columnList]);

  const columnListRef = useRef([])
  useEffect(() => {
    if (columnList && columnList.length) {

      columnListRef.current = columnList
      setallChecked(columnList.every((ele) => ele?.checked === true))

    }
    return () => {
      let currentFlowsElements = cloneDeep(store.getState().FlowsReducer.flowsElements)
      let finalFlowsElements = currentFlowsElements.map(item => {
        if (item.id === currentTransformer.id) {
          let list = columnListRef.current.filter(item => item?.checked)
          if (list?.length) {
            item.content.orderList = columnList;
          }

        }
        return item
      })
      store.dispatch(updateFlowsElements([...finalFlowsElements]))
    }
  }, [columnList])


  useEffect(() => {

    if (!transformerName) {
      setshowRedBtn(true)
    }
  }, [transformerName])
  useEffect(() => {
    let content = flowsElements.find(
      (ele) => ele.id === currentTransformer.id
    )?.content;

    // if (_.isEmpty(content)) {
    //   console.log('reset')
    //   // store.dispatch(resetTargetRedux());
    //   setconnectionName("");
    //   settargetColumns([]);
    //   settableName("");

    //   setschemaName("");
    //   setsourceType("");
    // }content && "outcomeColumns" in content &&

    if (
      targetTableDetails &&
      targetTableDetails.targetColumns?.length
    ) {
      settargetColumns(targetTableDetails.targetColumns);
      setschemaName(targetTableDetails.schemaName);
      setsourceType(targetTableDetails.sourceTypeImage);
      settableName(targetTableDetails.tableName);
      setconnectionName(targetTableDetails.connectionName);
    }
  }, [targetTableDetails]);

  const closeConnectionModalHandler = () => {
    setIsConnectionModalOpen(false);
  };

  const createColumnsList = (target = [], source = [], prop) => {

    let newColumnList = [];

    if (!target?.length) {
      source.forEach(sourceElement => {
        sourceElement['showError'] = false;
        newColumnList.push(sourceElement);
      })
      return newColumnList;
    } else {

      let elementFound = iselementFound();
      let noneFindElement = isnoneFindElement();


      if (elementFound?.length && noneFindElement?.length) {
        newColumnList = [...elementFound, ...noneFindElement]
        setshowResetColumns(true)
        setdisableExcute(true)

      }
      else if (elementFound?.length) {
        newColumnList = [...elementFound]
        // setshowResetColumns(true)
        // setdisableExcute(true)

      } else if (noneFindElement?.length) {
        newColumnList = [...noneFindElement]
        setshowResetColumns(true);
        setdisableExcute(true)

      } else {
        setshowResetColumns(false)
        setdisableExcute(false)


      }


      let cloneSource = cloneDeep(source)
      const getChecked = (column) => {
        if (elementFound?.length) {
          return elementFound.find(item => item?.Name === column?.Name)?.checked
        } else if (target?.length) {

          return target.find(item => item?.Name === column?.Name)?.checked
        }
      }
      cloneSource = cloneSource.map(sourceElement => {//----------------------------this will return an all sourcecolumns with
        sourceElement['showError'] = false;
        sourceElement['checked'] = getChecked(sourceElement);
        return sourceElement;
      })

      // return (mergeArrayByProperty(newColumnList, cloneSource, 'Name'))  //----------------------------this will return unique column remove duplicates from source columns i.e if duplicate found it will take newColumnList column
      let mergeList = (mergeArrayByProperty(newColumnList, cloneSource, 'Name'))  //----------------------------this will return unique column remove duplicates from source columns i.e if duplicate found it will take newColumnList column
      let orderList = currentTransformer?.content?.orderList;
      return orderRetainCheck(orderList, mergeList);
    }

    function isnoneFindElement() {  //----------------------------this function return an target column which is not present in sourcecolumn
      let obj = {}
      let list = [];
      if (target?.length) {
        let targetElement = cloneDeep(target)
        source.forEach(sourceElement => {
          targetElement = targetElement.filter((item) => {

            if (item['Name'] !== sourceElement['Name'] && !item?.id?.toString().includes('emptyColumn_')) {
              item['showError'] = true;
              item['checked'] = true;
              return item;
            }
          })
        })

        return targetElement
      }

    }

    function iselementFound() {

      if (target?.length) {
        let targetElement = cloneDeep(target)  //----------------------------this function will return an target columns which is  present in sourcecolumns but with source column details
        let foundEleList = []
        source.forEach(sourceElement => {
          targetElement.forEach((item) => {
            if (item['Name'] === sourceElement['Name'] && item['id'] !== sourceElement['id']) {
              item = cloneDeep(sourceElement)
              item['showError'] = false;
              item['checked'] = true;
              foundEleList.push(item)
            }
          })
        })
        return foundEleList


      }

    }



  }


  useEffect(() => {
    if (
      Object.keys(transformerNameList).length &&
      currentTransformer.id &&
      transformerNameList[currentTransformer.id]
    ) {
      setshowRedBtn(false)

      settransformerName(transformerNameList[currentTransformer.id]);
    }
    let transformerContent = flowsElements.find(
      (item) => item.id === currentTransformer.id
    )?.content;

    if (transformerContent?.isdropTable === true) {
      setisdropTable(transformerContent?.isdropTable);
    }
    if (transformerContent?.mode) {
      setToggleValue(transformerContent?.mode === 'insert' ? 'insert' : 'overwrite')
    }


    if (
      transformerContent &&
      !_.isEmpty(transformerContent) &&
      "targetTableDetails" in transformerContent &&
      "connectionDetails" in transformerContent
    ) {
      store.dispatch(
        setConnectionDetails(transformerContent.connectionDetails)
      );
      store.dispatch(
        setTargetTableDetails(transformerContent.targetTableDetails)
      );
    } else {
      store.dispatch(resetTargetRedux());
      setconnectionName("");
      settargetColumns([]);
      settableName("");
      dispatch(setConnectionSelectedConn([]));
      setschemaName("");
      setsourceType("");
    }
    let InputTransformer = dispatch(getInputTransformerDetails(currentTransformer, flowsElements))

    let inputColumns = cloneDeep(InputTransformer[0]?.content?.outcomeColumns);


    if (InputTransformer && InputTransformer?.length && inputColumns?.length) {

      let column = []

      column = createColumnsList(currentTransformer?.content?.outcomeColumns, inputColumns, 'Name')


      if (column?.length) {


        setcolumnList([...column])
        setdisableExcute(false);
        setToggleDefaultView(false)
        setShowBtnLoading(false);

      } else {

        setcolumnList([])
        setToggleDefaultView(true)
        setShowBtnLoading(false);
        setdisableExcute(true);
      }

    } else {
      setToggleDefaultView(true)
      setShowBtnLoading(false);
      setdisableExcute(true);
    }

  }, [currentTransformer]);

  const setPageView = (view) => {
    let finalFlowsElements = flowsElements.map((item) => {
      if (item.id === currentTransformer.id) {
        item.content.mode = view;
      }
      return item;
    });
    if (view === 'insert' && isdropTable) {
      setisdropTable(false)
    }
    store.dispatch(updateFlowsElements([...finalFlowsElements]));


    setToggleValue(view);
  };

  let toggleBtnOption = [
    {
      label: (
        <>
          <TABLEUPDATE />
          {`Overwrite`}
        </>
      ),
      value: "overwrite",
    },
    {
      label: (
        <>
          <TABLEINSERT />
          {`Insert`}
        </>
      ),
      value: "insert",
    },
  ];



  const executeTransformer = async () => {
    if (currentTransformer.id && transformerName !== "") {
      if (targetColumns?.length && columnList?.length) {

        let columnValue = [];
        if (targetColumns?.length && columnList?.length) {

          if (!isdropTable) {
            targetColumns.forEach((item, index) => {
              columnValue.push({
                frame: columnList[index].checked && columnList[index].Name ? columnList[index].Name : "",
                main_target: item.Name,
                target_column_datatype: item.Type
              });
            });
          } else {
            columnList.forEach((item, index) => {

              if (item.checked && item.Name !== "") {
                columnValue.push({
                  frame: item.Name,
                  main_target: !isdropTable
                    ? targetColumns[index]?.Name
                    : item.Name,
                  target_column_datatype: item.Type
                });
              }
            });
          }

        }
        if (columnValue?.length) {
          setShowBtnLoading(true);
          setdisableExcute(true);
          let payload = {
            flow_body: {
              Id: `${transformerName}`,
              NodeType: `target`,
              currentTransformerID: currentTransformer.id,

              Args: [
                {
                  Name: "frame",
                  Value: store.dispatch(getInputFrame()),
                },
                {
                  Name: "mode",
                  Value: toggleValue,
                },
                {
                  Name: "new",
                  Value:
                    targetTableDetails.typeOfTable &&
                      targetTableDetails.typeOfTable === "New"
                      ? true
                      : false,
                },
                {
                  Name: "drop",
                  Value: toggleValue === "overwrite" ? isdropTable : false,
                },
                {
                  Name: "columns",
                  Value: columnValue,
                },
                {
                  Name: "target",
                  Value: {
                    source_type: sourceType,
                    connection:
                      targetTableDetails?.connectionName,
                    schema: schemaName,
                    table: tableName,
                  },
                },
              ],
            },
          };
          try {
            let response =
              flowId &&
              (await store.dispatch(
                executeTransformerApi(flowId, payload, currentTransformer.id)
              ));

            setShowBtnLoading(false);
            setdisableExcute(false);
            setshowRedBtn(false)

            if (response.status === "success") {
              let outcomeColumns = columnList.map(
                (item, i) =>
                  item.checked === true && {
                    id: item.id,
                    Name: item.Name,
                    Type: item.Type,
                    checked: true
                  }
              ).filter(ele => ele);


              let finalFlowsElements = flowsElements.map((item) => {
                if (item.id === currentTransformer.id) {

                  item.content.outcomeColumns = outcomeColumns;
                  item.content.orderList = columnList;

                  item.content.isdropTable = isdropTable;
                  item.transformerName = transformerName;
                  item.content.mode = toggleValue;
                  item.content.targetTableDetails = targetTableDetails;
                  item.content.executed_DAG = payload.flow_body

                }
                return item;
              });
              store.dispatch(updateFlowsElements([...finalFlowsElements]));
            }
          } catch (error) {
            let finalFlowsElements = flowsElements.map(item => {
              if (item.id === currentTransformer.id) {
                item.transformerName = transformerName
                item.content.executed_DAG = payload.flow_body;
              }
              return item
            })
            store.dispatch(updateFlowsElements([...finalFlowsElements]))
            setShowBtnLoading(false);
            setdisableExcute(false);
            setshowRedBtn(false)

          }
        } else {
          emitToastNotification(
            "warning",
            "Please select atleast one column "
          );
        }
      } else {
        emitToastNotification(
          "info",
          "Please add target table to execute transformer "
        );
      }
    } else if (transformerName === "") {
      emitToastNotification(
        "info",
        "Please enter transformer name to execute transformer "
      );
    }
  };

  const onChecked = (text, type) => {

    let tempOutcomeColumns = [...columnList]

    if (
      tempOutcomeColumns?.length &&
      tempOutcomeColumns.filter(
        (item) => item.Type === type && item.Name === text
      )[0] &&
      tempOutcomeColumns.filter(
        (item) => item.Type === type && item.Name === text
      )[0]?.checked
    ) {
      setallChecked(false);

      tempOutcomeColumns.filter(
        (item) => item.Type === type && item.Name === text
      )[0].checked = false;
    } else {
      tempOutcomeColumns.filter(
        (item) => item.Type === type && item.Name === text
      )[0].checked = true;
    }

    let customContent = flowsElements.find(
      (item) => item.id === currentTransformer.id
    ).content;
    customContent.outcomeColumns = [...tempOutcomeColumns];

    setcolumnList([...tempOutcomeColumns]);
  };

  const selectall = (checked) => {
    if (checked) {
      setallChecked(true);
      let tempOutcomeColumns = [...columnList];

      tempOutcomeColumns.forEach((item) => (item.checked = true));
      setcolumnList([...tempOutcomeColumns]);
    } else {
      setallChecked(false);
      let tempOutcomeColumns = [...columnList];

      tempOutcomeColumns.forEach((item) => (item.checked = false));

      setcolumnList([...tempOutcomeColumns]);
    }
  };


  const closeLeftSideContent = () => {
    dispatch(setFlowsCurrenttransformer({}));
    dispatch(showFlowsTransformdetails(false));
  };

  const currentConditionTypeRef = useRef(null)
  const onResetEvent = () => {

    currentConditionTypeRef.current = {
      message: 'Columns that are not found in input source that will be removed',
      onConfirm: () => {
        let cloneCols = cloneDeep(columnList);
        cloneCols = cloneCols.map((item, i) => {
          if (item.showError) {
            item.id = `emptyColumn_${new Date().getTime()}_${i}}`
            item.checked = false
            item.Name = ""
            item.Type = ""
            item.showError = false
          }
          return item
        });
        // cloneCols = cloneCols.filter((item) => !item.showError);
        if (cloneCols?.length) {

          setcolumnList([...cloneCols])
        }
        setopenWarningModal(false);
        setshowResetColumns(false)
        setdisableExcute(false)


        return true
      },
      onCancel: () => {
        setopenWarningModal(false);
        return false
      }
    }
    setopenWarningModal(true)
  }

  const targetColumnsCard = React.useMemo(() => {
    return targetColumns.map((item) => {
      const { Name, Type } = item;
      return (
        <>
          <div className="d-flex justify-content-end">
            <div className="link-btn">
              {context === "Update" ? (
                <Buttons
                  props={{
                    buttonText: "",
                    buttonClassName:
                      "custom-btn custom-btn-outline btn-with-icon",
                    buttonEvent: () => { },
                    ImgSrc: () => <LINK />,
                    isDisable: false,
                    buttonType: Literals.BTN_SECONDARY,
                  }}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="list-without-drag margin-left-2">
              <div className="d-flex align-items-center w-50 ">
                {getColumnDataType(Type)}
                <span className="paragraph">{Name}</span>
              </div>

            </div>
          </div>
        </>

      );
    })
  }, [targetColumns])

  return (
    <>
      {
        getConfirmationModal({
          open: openWarningModal,
          setOpen: setopenWarningModal,
          body: currentConditionTypeRef?.current?.message,
          onConfirm: currentConditionTypeRef?.current?.onConfirm,
          onCancel: currentConditionTypeRef?.current?.onCancel
        })
      }
      <ConnectionProvider>
        <div className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
          <div className="d-flex align-items-center">
            <TARGET />
            <h3 className="subtitle">Target</h3>
            <Tabs
              onChange={onChange}
              activeKey={activeKey}
              className="custom-tab tab-with-icon"
            >
              <TabPane tab={<CODEEDITOR />} key="properties"></TabPane>
              <TabPane tab={<EDITOR />} key="editor"></TabPane>
            </Tabs>
          </div>
          <div className="d-flex align-items-center">
            {showResetColumns ? (
              <Buttons
                props={{
                  tooltip: 'Reset Columns',
                  buttonText: "",
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2 mr-2",
                  buttonEvent: onResetEvent,
                  toggleBtnValue: "",
                  ImgSrc: () => <RESETFILTER />,
                  isDisable: window.location.href.includes("viewFlows")
                    ? true
                    : false,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  toggleBtnOption: null,
                }}
              />
            ) : null}
            <DatasetOutputNameInput
              transformerName={transformerName}
              setTransformerName={settransformerName}
            />

            <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline custom-btn btn-with-icon mr-2",
                buttonEvent: () => {
                  activeKey !== "editor" || (activeKey === "editor" && !editMode)
                    ? closeLeftSideContent()
                    : setEditMode(false);
                  onChange("properties");
                },
                ImgSrc: () => <CLOSE />,

                isDisable: false,
                buttonType: Literals.BTN_SECONDARY,
              }}
            />
            {activeKey === "editor" ? (
              <Buttons
                props={{
                  buttonText: !editMode ? "Edit" : "Save",
                  buttonClassName:
                    "custom-btn-primary custom-btn btn-with-text",
                  buttonEvent: !editMode
                    ? () => {
                      setEditMode(true);
                      dispatch(saveTransformerNoteClick(false));
                    }
                    : () => {
                      dispatch(saveTransformerNoteClick(true));
                    },
                  ImgSrc:
                    !editMode ? () => <EDIT /> : () => <SAVE />,
                  isDisable: window.location.href.includes("viewFlows")
                    ? true
                    : false,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                }}
              />
            ) : null}

            {activeKey !== "editor" ? (
              <Buttons
                props={{
                  buttonText: "Execute",
                  buttonClassName:
                    `${showRedBtn ? 'execute-btn-gradient' : ''} custom-btn-primary custom-btn btn-with-text`,
                  showLoading: showBtnLoading,
                  buttonEvent:
                    activeKey === "editor"
                      ? () => {
                        setEditMode(true);
                      }
                      : () => {
                        executeTransformer();
                      },
                  ImgSrc:
                    activeKey === "editor" ? () => <EDIT /> : () => <PLAY />,
                  isDisable:
                    !isExecutePermission
                      ||
                      transformerName === "" ||
                      disableExcute ||
                      showResetColumns
                      ? true
                      : false,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                }}
              />
            ) : null}
          </div>
        </div>
        {activeKey === "properties" && !toggleDefaultView ? (
          <>
            <div className="d-flex bg-white section-with-drag">
              <div className="w-50 custom-border-right">
                <div className="d-flex justify-content-between padding-2 py-1 custom-border-bottom">
                  <div className="d-flex align-items-center">
                    <INPUT />
                    <p className="paragraph ml-2 one-line-ellipsis custom-ellipsis">
                      Input Columns
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    {/* <div className="content-with-checkbox">
                                <Checkbox />
                                <p className="custom-ellipsis one-line-ellipsis">Distinct Records</p>
                            </div> */}
                    {/* <Buttons props={{ buttonText: '', buttonClassName: "custom-btn ml-2 custom-btn-outline btn-with-icon", buttonEvent: () => { onAllChecked() }, ImgSrc: () => <SELECTALL />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} /> */}
                  </div>
                </div>

                <div className="d-flex justify-content-between padding-2 custom-border-bottom height-60">
                  <div className="triple-toggle-btn overwrite-insert-toggle">
                    <Buttons
                      props={{
                        buttonText: "",
                        toggleBtnOption: toggleBtnOption,
                        toggleBtnValue: toggleValue,
                        buttonClassName: "btn custom-btn",
                        buttonEvent: setPageView,
                        ImgSrc: () => <></>,
                        isDisable: false,
                        buttonType: Literals.BTN_TOGGLE,
                      }}
                    />
                  </div>

                  {/* merge-select-checkbox */}
                </div>
                {toggleValue === "overwrite" ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="ml-2">
                      <Tooltip placement="topRight" title="Select All">
                        <Checkbox
                          disabled={
                            window.location.href.includes("viewFlows")
                              ? true
                              : false
                          }
                          checked={allChecked}
                          onChange={(e) => selectall(e.target.checked)}
                        />
                      </Tooltip>
                    </div>{" "}
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ padding: "6px" }}
                    >
                      <input
                        type="checkbox"
                        disabled={
                          window.location.href.includes("viewFlows")
                            ? true
                            : false
                        }
                        checked={isdropTable}
                        onChange={(e) => {
                          let finalFlowsElements = flowsElements.map((item) => {
                            if (item.id === currentTransformer.id) {
                              item.content.isdropTable = e.target.checked;
                            }
                            return item;
                          });
                          store.dispatch(updateFlowsElements([...finalFlowsElements]));
                          setisdropTable(e.target.checked)
                        }}
                      />
                      <span className="paragraph ml-1 font-weight-bold">
                        Drop table and re-create schema
                      </span>
                    </div>{" "}
                  </div>
                ) : null}

                {toggleValue === "insert" ? (
                  <div className=" ml-2">
                    <Tooltip placement="topRight" title="Select All">
                      <Checkbox
                        disabled={
                          window.location.href.includes("viewFlows")
                            ? true
                            : false
                        }
                        checked={allChecked}
                        onChange={(e) => selectall(e.target.checked)}
                      />
                    </Tooltip>
                  </div>
                ) : null}
              </div>
              <div className="w-50">
                <div className="d-flex justify-content-between custom-border-bottom padding-2 py-1">
                  <div className="d-flex align-items-center">
                    <DATASET width="18" height="18" />
                    <p className="paragraph ml-2">Target Table</p>
                  </div>
                  <div className="d-flex align-items-center">
                    {/* <Buttons props={{
                                        buttonText: '', buttonClassName: "custom-btn ml-2 mr-2 danger-btn btn-with-icon", buttonEvent: () => {
                                        }, ImgSrc: () => <RELOAD />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                                    }} /> */}
                    <Buttons
                      props={{
                        buttonClassName:
                          "custom-btn-outline custom-btn btn-with-icon",
                        buttonEvent: () => {
                          setIsConnectionModalOpen(true);
                          if (targetColumns?.length) {
                            let transformerContent = flowsElements.find(
                              (item) => item.id === currentTransformer.id
                            )?.content;
                            if (transformerContent?.connectionDetails) {
                              dispatch(setConnectionSelectedConn(transformerContent?.connectionDetails));
                              dispatch(setConnectionDetails(transformerContent?.connectionDetails));
                            } else {
                              dispatch(setConnectionSelectedConn(transformerContent?.connectionDetails));
                              dispatch(setConnectionDetails(transformerContent?.connectionDetails));
                            }

                          } else {
                            dispatch(setConnectionSelectedConn([]));
                            dispatch(setConnectionDetails([]));

                          }
                        },
                        tooltip: targetColumns.length ? 'Edit Target Table' : 'Add Target Table',
                        ImgSrc: () =>
                          targetColumns.length ? <EDIT /> : <PLUS />,
                        isDisable:
                          window.location.href.includes("viewFlows"),
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex custom-border-bottom padding-2 height-60">
                  <div className="d-flex w-50">
                    <SourceTypeImage dataName={sourceType} />
                    <div className="pl-1 custom-flex-width">
                      <p className="label">Connection Name</p>
                      <p className="text-uppercase paragraph custom-ellipsis one-line-ellipsis">
                        {connectionName}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex w-50">
                    <span style={{ marginRight: "10px" }}>
                      <TABLEICON width={"18"} height={"18"} />
                    </span>
                    <div className="pl-1 custom-flex-width">
                      <p className="label">Table Name</p>
                      <p className="text-uppercase paragraph custom-ellipsis one-line-ellipsis">
                        {tableName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-drag-undrag">
              <div className="w-50 ">
                {columnList.length ? (
                  <DraggableList
                    isdropTable={isdropTable}
                    onChecked={onChecked}
                    draggableList={[...columnList]}
                    stopDraggingRow={
                      window.location.href.includes("viewFlows") ? true : false
                    }
                    setcolumnList={setcolumnList}
                    mergeShowCheckBox={[]}
                    isHeight100={false}
                  />
                ) : null}
              </div>
              {/* padding-top-2 */}
              {!isdropTable ? (
                <div
                  style={{ paddingTop: "6px" }}
                  className={
                    isdropTable
                      ? "w-50 padding-right-2  padding-bottom-2 "
                      : "w-50 padding-right-2  padding-bottom-2 "
                  }
                >
                  {targetColumns.length <= 0 ? (
                    <NoTableSelected />
                  ) : (
                    <DndVirtualList
                      data={targetColumnsCard}
                      rawData={targetColumns}
                      setData={() => { }}
                      stopDraggingRow={true}
                      isHeight100={false}
                    />

                  )}
                </div>
              ) : (
                <div className="w-50 padding-right-2 padding-top-2 padding-bottom-2 "></div>
              )}
            </div>
            <TargetConnectionModal
              isModalOpen={isConnectionModalOpen}
              onClose={closeConnectionModalHandler}
            />
          </>
        ) : activeKey === "properties" && toggleDefaultView ? (
          <div className="no-datasource">
            <SCRIPTDEFAULTVIEW />
          </div>
        ) : null}

        {activeKey === "editor" && (
          <EditorIndex editMode={editMode} setEditMode={setEditMode} />
        )}
      </ConnectionProvider>
      {/* {props.loadingStatus ? <LoadingComponent /> : null} */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    transformerLoading: state.FlowsReducer.transformerLoading,
    appliedFilterCriteria: state.FlowsReducer.appliedFilterCriteria,
    loadingStatus: state.LoadingReducer.loadingStatus,

    transformerNameList: state.FlowsReducer.transformerName,
  };
};
export default connect(mapStateToProps)(TargetIndex);
