
import "toastr/build/toastr.min.css";
import { convertDOMElementToImage, getObjectLength } from '../../../components/modules/common/helperFunctions';
import { emitToastNotification } from '../../../helpers/toast_helper';
import { fetchData } from '../../../services/apiService';
import { setLoadingStatus } from '../common/loadingActions';
import {
  ADD_EDGES, ADD_EDITOR_TEXT, SAVE_NOTE_CLICK, SET_CURRENT_EXECUTING_TRANSFORMER_ID, SET_CURRENT_SCRIPTOUTPUT_NODE, SET_DATASOURCE_PODS, SET_FILTER_TRANSFORMER_CRITERIA, SET_FLOWS_CURRENTTRANSFORMER, SET_FLOWS_SHOWLEFTSIDEDETAILS, SET_SQL_EDIT_MODE, SET_SQL_TRANSFORMER_QUERY, SET_TO_DEFAULT, SET_TRANSFORMERLOADING_STATUS, SET_TRANSFORMER_MAXID,
  SET_TRANSFORMER_NAME, SET_TRANSFORMER_PAYLOAD, SET_TRANSFORMER_PROGRESS, SHOW_FLOWS_DATABRICKSNOTEBOOK,
  SET_TRANSFORMER_STATUS_CHANGE, SHOW_FLOWS_ATTOP, SHOW_FLOWS_JUPYTERNOTEBOOK, SHOW_FLOWS_LOGS_LOWERSECTION, SHOW_FLOWS_LOWERSECTION,
  SHOW_FLOWS_TRANSFORMDETAILS, UPDATE_FLOWS_BASICDETAILS, UPDATE_FLOWS_ELEMENTS
  , SHOW_FLOWS_TIMELINE_LOWERSECTION
} from './flowsTypes';
import { resetScriptRedux, setBricksOutputDataset, setScriptOutputDataset } from './scriptTransformer/scriptAction';
import { getConnectedEdges } from 'reactflow';
import { cloneDeep, forIn, isNumber } from 'lodash';
var BASE_URL = window._env_.REACT_APP_API_BASE_URL;


export const showFlowsLowerSection = (data) => {
  return {
    type: SHOW_FLOWS_LOWERSECTION,
    payload: data
  };
}
export const showTimelineLowerSection = (data) => {
  return {
    type: SHOW_FLOWS_TIMELINE_LOWERSECTION,
    payload: data
  };
}

export const showFlowsLogsLowerSection = (data) => {
  return {
    type: SHOW_FLOWS_LOGS_LOWERSECTION,
    payload: data
  };
}
export const saveTransformerNoteClick = (data) => {
  return {
    type: SAVE_NOTE_CLICK,
    payload: data
  };
}

export const showJupyterNoteBook = (data) => {
  return {
    type: SHOW_FLOWS_JUPYTERNOTEBOOK,
    payload: data
  };
}

export const showDatabricksNoteBook = (data) => {
  return {
    type: SHOW_FLOWS_DATABRICKSNOTEBOOK,
    payload: data
  };
}

export const showFlowsAttop = (data) => {
  return {
    type: SHOW_FLOWS_ATTOP,
    payload: data
  };
}
export const setSQlTransformerQuery = (data) => {
  return {
    type: SET_SQL_TRANSFORMER_QUERY,
    payload: data
  };
}
export const showFlowsTransformdetails = (data) => {
  return {
    type: SHOW_FLOWS_TRANSFORMDETAILS,
    payload: data
  };
}

export const setSQLeditMode = (data) => {
  return {
    type: SET_SQL_EDIT_MODE,
    payload: data
  };
}
export const updateFlowsElements = (data) => {
  return {
    type: UPDATE_FLOWS_ELEMENTS,
    payload: data
  };
}


export const updateFlowsBasicDetails = (data) => {
  return {
    type: UPDATE_FLOWS_BASICDETAILS,
    payload: data
  };
}
export const setFlowsCurrenttransformer = (data) => {
  return {
    type: SET_FLOWS_CURRENTTRANSFORMER,
    payload: data
  };
}
export const setCurrentScriptoutputNode = (data) => {
  return {
    type: SET_CURRENT_SCRIPTOUTPUT_NODE,
    payload: data
  };
}
export const setDataSourcePodDetails = (data) => {
  return {
    type: SET_DATASOURCE_PODS,
    payload: data
  };
}

export const setToDefault = (data) => {
  return {
    type: SET_TO_DEFAULT,
    payload: data
  };
}

export const setFilterTransformerFilterCriteria = (data) => {
  return {
    type: SET_FILTER_TRANSFORMER_CRITERIA,
    payload: data
  };
}
export const setTransformerLoadingStatus = (data) => {
  return {
    type: SET_TRANSFORMERLOADING_STATUS,
    payload: data
  };
}

export const setExecutedTransformerPayload = (data) => {
  return {
    type: SET_TRANSFORMER_PAYLOAD,
    payload: data
  };
}


export const addEdges = (data) => {
  return {
    type: ADD_EDGES,
    payload: data
  };
}

export const setTransformerMaxId = (data) => {
  return {
    type: SET_TRANSFORMER_MAXID,
    payload: data
  };
}
export const setTransformerNames = (data) => {
  return {
    type: SET_TRANSFORMER_NAME,
    payload: data
  };
}
export const addEditorText = (data) => {
  return {
    type: ADD_EDITOR_TEXT,
    payload: data
  };
}

const removeElements = (elementToRemove, flowsElements) => {

  const copyFlowsElements = cloneDeep(flowsElements)

  const initialEdges = flowsElements.filter(item => item.source);
  const Edges = getConnectedEdges(elementToRemove, initialEdges)?.map((item) => item.id)
  const removeElementId = elementToRemove.map((item) => item.id)

  let newFlowsElements = []
  if (Edges?.length) {
    newFlowsElements = copyFlowsElements.filter((item) => !Edges.includes(item.id) && !removeElementId.includes(item.id))
  } else {
    newFlowsElements = copyFlowsElements.filter((item) => !removeElementId.includes(item.id))

  }
  return newFlowsElements
}

export const flowsExitMode = (flowId) => {
  return (dispatch) => {

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("DELETE", `${BASE_URL}flow/${flowId}/edit_mode/`, {})
      )
        .then((resp) => {

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)

          } else {

            resolve(resp);
            dispatch(setLoadingStatus(false));

          }

        })
        .catch((err) => {

          reject(err);
          dispatch(setLoadingStatus(false));
        })
    });
  };
};

export const deleteTransformer = (transformerId, flowId) => {
  return (dispatch, getState) => {


    let transformerNameList = getState().FlowsReducer.transformerName;
    let flowsElements = getState().FlowsReducer.flowsElements
    let elementToRemove = flowsElements.find((ele) => ele.id === transformerId)

    dispatch(setLoadingStatus(true));

    dispatch(fetchData(`DELETE`, `${BASE_URL}flow/${flowId}/delete_transformer/?transformer=${transformerNameList[transformerId] ? transformerNameList[transformerId] : 'null'}`, ''))
      .then((resp) => {
        if (resp.status === "success") {
          dispatch(setLoadingStatus(false));
        } else {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', resp?.message);
        }
      }).catch((err) => {
        dispatch(setLoadingStatus(false));
        emitToastNotification('error', err?.message);

      })
    if (flowsElements && flowsElements.length) {


      if (elementToRemove.type === 'script' || elementToRemove.type === 'databricks') {
        let executedNodes = getState().FlowsReducer.executedTransformerPayload;
        let scriptPairedOutputNode = elementToRemove.paired;
        let tempNode = []
        if (scriptPairedOutputNode && scriptPairedOutputNode?.length) {
          for (let index = 0; index < scriptPairedOutputNode?.length; index++) {
            let findNode = flowsElements.find((ele) => ele.id === scriptPairedOutputNode[index])
            if (findNode && findNode.id) {
              tempNode.push(findNode)
            }
          }
          for (let index = 0; index < scriptPairedOutputNode.length; index++) {

            if (executedNodes?.length) {
              let existedTransformerIndex = executedNodes.findIndex((item) => (item?.Id?.toLowerCase() === scriptPairedOutputNode[index]?.toLowerCase()))
              if (existedTransformerIndex > -1) {
                executedNodes.splice(existedTransformerIndex, 1)
              }
            }
          }
        }
        let newFlowsElements = removeElements([...tempNode, elementToRemove], flowsElements)

        newFlowsElements.forEach((element) => {
          if (element && element?.input_source && Object.keys(element.input_source).some(r => scriptPairedOutputNode?.indexOf(r) >= 0)) {
            for (let index = 0; index < scriptPairedOutputNode?.length; index++) {
              const node = scriptPairedOutputNode[index];

              delete element['input_source'][node]
            }
          }
          if (element && element?.tabArray && element?.tabArray?.length) {
            for (let index = 0; index < scriptPairedOutputNode?.length; index++) {
              const node = scriptPairedOutputNode[index];
              let findindex = element.tabArray.findIndex((ele) => ele.transformerId === node)
              if (findindex > -1) {
                element.tabArray.splice(findindex, 1)
              }

            }

          }
        })
        let transformerNameList = getState().FlowsReducer.transformerName



        if (executedNodes?.length) {
          let existedTransformerIndex = executedNodes.findIndex((ele) => (ele?.Id?.toLowerCase() === transformerId?.toLowerCase()) || (ele.Id.toLowerCase() === transformerNameList[transformerId]?.toLowerCase()));

          if (existedTransformerIndex > -1) {
            executedNodes.splice(existedTransformerIndex, 1)
          }

          dispatch(setExecutedTransformerPayload(executedNodes))

        }
        for (let index = 0; index < scriptPairedOutputNode?.length; index++) {
          const node = scriptPairedOutputNode[index];
          delete transformerNameList[node]
        }
        for (const key in transformerNameList) {

          if (key === transformerId) {
            delete transformerNameList[key]
          }
        }

        dispatch(setTransformerNames(transformerNameList))
        let edges = getState().FlowsReducer.edges
        if (edges && edges.length) {
          let existedTransformerIndex = edges.map((ele, index) => {
            if (ele?.source?.toLowerCase() === transformerId?.toLowerCase() || scriptPairedOutputNode?.includes(ele?.source) || scriptPairedOutputNode?.includes(ele?.destination) || ele?.destination?.toLowerCase() === transformerId?.toLowerCase()) return index
          })

          if (existedTransformerIndex.length) {
            existedTransformerIndex.forEach(elementIndex => {
              edges.splice(elementIndex, 1)

            });
          }

          dispatch(addEdges(edges))
        }
        dispatch(setScriptOutputDataset([]))
        dispatch(setBricksOutputDataset([]))
        dispatch(resetScriptRedux())
        dispatch(updateFlowsElements([...newFlowsElements]))

        dispatch(showFlowsTransformdetails(false))
        dispatch(showFlowsLowerSection(false))
      } else {
        let newFlowsElements = removeElements([elementToRemove], flowsElements)
        if (newFlowsElements?.length) {
          newFlowsElements.forEach((element) => {
            if (element && element.input_source && Object.keys(element.input_source).includes(transformerId)) {
              delete element['input_source'][transformerId]
            }
            if (element && element.tabArray && element.tabArray.length) {
              let index = element.tabArray.findIndex((ele) => ele?.transformerId === transformerId)
              if (index > -1) {
                element.tabArray.splice(index, 1)
              }
            }
          })
        }
        let transformerNameList = getState().FlowsReducer.transformerName;
        let temp = getState().FlowsReducer.executedTransformerPayload;

        if (temp?.length) {
          let existedTransformerIndex = temp.findIndex((ele) => (ele?.Id?.toLowerCase() === transformerId?.toLowerCase()) || (ele.Id.toLowerCase() === transformerNameList[transformerId]?.toLowerCase()));

          if (existedTransformerIndex > -1) {
            temp.splice(existedTransformerIndex, 1)
          }

          dispatch(setExecutedTransformerPayload(temp))

        }
        let edges = getState().FlowsReducer.edges
        if (edges && edges.length) {
          let existedTransformerIndex = edges.map((ele, index) => {
            if (ele?.source?.toLowerCase() === transformerId?.toLowerCase() || ele?.destination?.toLowerCase() === transformerId?.toLowerCase()) return index
          }).filter((item) => item && item)

          if (existedTransformerIndex.length) {
            existedTransformerIndex.forEach(elementIndex => {
              edges.splice(elementIndex, 1)

            });
          }

          dispatch(addEdges(edges))
        }
        for (const key in transformerNameList) {

          if (key === transformerId) {
            delete transformerNameList[key]
          }
        }
        if (elementToRemove?.type?.toLowerCase() === "datasource") {
          let temp = getState().FlowsReducer.dataSourcePodDetails
            ? getState().FlowsReducer.dataSourcePodDetails
            : [];
          let index =
            temp &&
            temp.length &&
            temp.findIndex((ele) => Object.keys(ele)[0] === transformerId);
          if (index > -1) {
            temp.splice(index, 1);
          }
          dispatch(setDataSourcePodDetails(temp));
        }
        dispatch(setTransformerNames(transformerNameList))
        dispatch(updateFlowsElements([...newFlowsElements]))
        dispatch(showFlowsTransformdetails(false))
        dispatch(showFlowsLowerSection(false))


      }



    }




  }
}

export const generateFlowIDApi = (payload) => {

  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(`POST`, `${BASE_URL}flow/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));


            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
       
          if (err.message) {

            emitToastNotification('error', err?.message);
          }
          emitToastNotification('error', err?.data?.message);
          reject(err);
        })
    })
  }
}

export const executeEntireFlow = (id, payload) => {
  return (dispatch, getState) => {

    dispatch(setLoadingStatus(true))
    dispatch(setTransformerLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(`POST`, `${BASE_URL}flow/${id}/execute_transformer/`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false))

          if (resp.status === "success") {
            dispatch(setTransformerLoadingStatus(false));
            emitToastNotification('success', resp.message)
            resolve(resp)
          } else {
            dispatch(setTransformerLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false))

          dispatch(setTransformerLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        })
    })
  }
}



export const executeTransformerApi = (id, payload, currentTransformerID) => {
  return (dispatch, getState) => {
  
    let newFlowsElements = getState().FlowsReducer.flowsElements;
    let edges = []
    let nodes = [payload?.flow_body]
    let NodeDag = {}
    if (newFlowsElements?.length) {
      newFlowsElements.forEach((item) => {
        if (!item.id.includes('reactflow__edge') && item.id !== currentTransformerID) {
          NodeDag = {
            Id: item.transformerName,
            Args: [],
            NodeType: item.type === 'script' ? 'nb_python3' : item.type,
            currentTransformerID: item.id
          }
          if (item.content.executed_DAG && item?.content?.executed_DAG?.Id) {
            item.content.executed_DAG['Id'] = item.transformerName
            nodes.push(item.content.executed_DAG)
          } else {

            nodes.push(NodeDag)
          }
        } else if (item.id.includes('reactflow__edge')) {
          edges.push({ source: item.source, destination: item.target })
        }

      })
    }


    let newPayload = {
      "dag": {
        nodes: nodes,
        edges: edges,

      },
      task_name: payload['flow_body']?.Id
    }


    dispatch(setTransformerLoadingStatus(true));
    let apiPayload = {
      page: '',
      limit: '',
      execution_id: '',
      flow_Id: id
    }

    dispatch({ type: SET_CURRENT_EXECUTING_TRANSFORMER_ID, payload: currentTransformerID, })
    return new Promise((resolve, reject) => {
      dispatch(fetchData(`POST`, `${BASE_URL}flow/${id}/execute_transformer/`, newPayload))
        .then((resp) => {

          dispatch(setTransformerLoadingStatus(false));

          if (resp.status === "success") {
            dispatch(setTransformerLoadingStatus(false));
            dispatch(RetriveExecutionFlows(apiPayload))
            resolve(resp)
          } else {
            dispatch(setTransformerLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {


          dispatch(setTransformerLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        })
    })
  }
}


export const saveFlow = (id, showLoader = false) => {
  return async (dispatch, getState) => {
    let newFlowsElements = getState().FlowsReducer.flowsElements;
    let edges = []
    let nodes = []
    let NodeDag = {}
    let reduxTransformerName = getState().FlowsReducer.transformerName;
    if (newFlowsElements?.length) {
      newFlowsElements.forEach((item) => {
        if (!item.id.includes('reactflow__edge')) {
          NodeDag = {
            Id: item.transformerName,
            Args: [],
            NodeType: item.type,
            currentTransformerID: item.id
          }
          if (item.content.executed_DAG && item?.content?.executed_DAG?.Id) {
            nodes.push(item.content.executed_DAG)
          } else {

            nodes.push(NodeDag)
          }
        } else if (item.id.includes('reactflow__edge')) {
          edges.push({ source: item.source, destination: item.target })
        }

      })
    }

    if (Object.values(reduxTransformerName)?.length && showLoader) {//msg will display for manual save not for auto save
      let isEmptyTransformerName = Object.values(reduxTransformerName).findIndex((item) => !item.length || isNumber(item));

      if (isEmptyTransformerName > -1) {
        let nodePresentIndex = -1;
        if (newFlowsElements?.length) {
          nodePresentIndex = newFlowsElements.findIndex((item) => item.id === Object.keys(reduxTransformerName)[isEmptyTransformerName])
        }
        if (nodePresentIndex > -1) {
          dispatch(setLoadingStatus(false));

          emitToastNotification('warning', 'Transformer name not found');
          return;
        }

      }
    }

    if (!nodes?.length && !edges?.length) {
      dispatch(setLoadingStatus(false));
      if (showLoader) {   //msg will display for manual save not for auto save

        emitToastNotification('warning', 'Please add atleast one transformer to save the flow');
      }

      return;
    } else {  // if atleast one node on canvas then only save will call
      if (showLoader) {
        dispatch(setLoadingStatus(true));
      }
      let payload = {
        "name": "string",
        "description": "string",
        "priority": "string",
        "scheduled": false,
        "schedule_body": {},
        "flow_body": {
          nodes: [],
          edges: [],

        },
        "flow_redux": {

        },
        "pod_ids": [],

        "thumbnail": showLoader ? await convertDOMElementToImage(".react-flow__viewport", "exact") : undefined
      }

      let flowsElements = getState().FlowsReducer.flowsElements;


      let podsDetails = getState().FlowsReducer?.dataSourcePodDetails;
      let pod_ids = podsDetails?.length ? podsDetails.map((ele, i) => podsDetails[i][Object.keys(ele)[0]]['podId']) : []

      payload['flow_redux']['flowsElements'] = flowsElements;
      payload['flow_redux']['dataSourcePodDetails'] = podsDetails
      payload['flow_redux']['transformerNames'] = reduxTransformerName;
      payload['flow_redux']['maxTransformerId'] = getState().FlowsReducer.maxTransformerId;
      payload['name'] = getState().FlowsReducer.name
      payload['description'] = getState().FlowsReducer.description
      payload['pod_ids'] = pod_ids
      payload['flow_body']['nodes'] = nodes ? nodes : []
      payload['flow_body']['edges'] = edges ? edges : []


      return new Promise((resolve, reject) => {
        dispatch(fetchData(`PUT`, `${BASE_URL}flow/${id}/?end_activity=${showLoader ? true : false}`, payload))
          .then((resp) => {
            if (resp.status === "success") {
              dispatch(setLoadingStatus(false));
              // dispatch({
              //   type: SAVE_FLOW_CLICK,
              //   payload: false
              // })
              resolve(resp)
            } else {
              dispatch(setLoadingStatus(false));
              emitToastNotification('error', resp?.message);
              reject(resp?.message);
            }
          }).catch((err) => {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', err?.message);
            reject(err);
          })
      })
    }

  }
}

export const fetchFlowsDetails = ({ method, endPoint, payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(method, `${BASE_URL}${endPoint}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}

export const flowsDomainFilter = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}flow/?data_domain_id=${payload}`))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}

export const RetriveExecutionFlows = (payload) => {
  return (dispatch, getState) => {
 
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}flow/${payload.flow_Id}/latest_status/`))


        .then((resp) => {
          if (resp.status === "success") {

            if (resp?.data?.length) {
              if (resp?.data?.length) {
                let data = resp.data.map((ele) => {
                  ele.state = ele.state ? ele.state?.toLowerCase() : ''
                  return ele
                })
                dispatch({ type: SET_TRANSFORMER_PROGRESS, payload: data })

              }
              dispatch({ type: SET_TRANSFORMER_STATUS_CHANGE, payload: { status: true } })


            }

            resolve(resp)
          } else {

            dispatch({ type: SET_TRANSFORMER_STATUS_CHANGE, payload: { status: false } })

            // emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch({ type: SET_TRANSFORMER_STATUS_CHANGE, payload: false })

          // emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}

export const EditFlowsAPI = (payload) => {
  return (dispatch, getState) => {

    return new Promise((resolve, reject) => {
      dispatch(fetchData(payload.ApiType, `${BASE_URL}flow/${payload.flow_Id}/edit_mode/`))


        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp)
          } else {


            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch({ type: SET_TRANSFORMER_STATUS_CHANGE, payload: false })

          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}
export const editFlowNameApiCall = (id, payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData('PATCH', `${BASE_URL}flow/${id}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}
export const getFlowDetails = (id) => {
  const searchParams = new URLSearchParams(document.location.search)
  const history_id = searchParams.get("history_id");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");

  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    let apiPayload = {
      page: '',
      limit: '',
      execution_id: '',
      flow_Id: id
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData('GET', `${BASE_URL}flow/${id}/${history_id ? `?history_id=${history_id}&start_date=${start_date}&end_date=${end_date}` : ""}`))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            dispatch(RetriveExecutionFlows(apiPayload))
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}


export const setFlowsShowleftsidedetails = (data) => {
  return {
    type: SET_FLOWS_SHOWLEFTSIDEDETAILS,
    payload: data
  };
}
