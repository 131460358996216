import { RESET_CREATE_ALERT_REDUX, SET_SELECTED_ALERT_TRANSFORMER, SET_FLOWS_ALERT_TRANSFORMER_LIST, SET_ALERT_APPLIED_FILTERS, SET_ALERT_PREVIEW_TABLES, SET_ALERT_PREVIEW_TABLE_RESULT, SET_ALERT_QUERY_TABLES_RESULT, SET_ALERT_RESPONSE_SQL_QUERY, SET_ALERT_SQL_EDITOR_QUERY, SET_SELECTED_ALERT_PODLIST, SET_SELECTED_ALERT_DETAILS } from "./createAlertActiontype";


const initialState = {
    selectedAlertPodList: [],
    alertSqlEditorQuery: '',
    queryResult: {},
    previewTableResult: {},
    sqlEditorQuery: '',
    sqlResponseQuery: '',
    responseQuery: '',
    previewAlertTables: [],
    appliedFilters: [],
    selectedAlertDetails: {},
    flowsAlertTransformerList: {},
    selectedAlertTransformer: {}
}

const CreateAlertReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case SET_SELECTED_ALERT_PODLIST:
            return {
                ...state,
                selectedAlertPodList: payload,
            }
        case SET_SELECTED_ALERT_DETAILS:
            return {
                ...state,
                selectedAlertDetails: payload,
            }
        case SET_ALERT_SQL_EDITOR_QUERY:
            return {
                ...state,
                alertSqlEditorQuery: payload,
            }
        case SET_ALERT_RESPONSE_SQL_QUERY:
            return {
                ...state,
                sqlResponseQuery: payload,
            }
        case SET_SELECTED_ALERT_TRANSFORMER:
            return {
                ...state,
                selectedAlertTransformer: payload,
            }
        case RESET_CREATE_ALERT_REDUX:

            initialState['previewAlertTables'] = []
            return { ...initialState }

        case SET_ALERT_PREVIEW_TABLES:
            return {
                ...state,
                previewAlertTables: payload,
            }
        case SET_FLOWS_ALERT_TRANSFORMER_LIST:
            return {
                ...state,
                flowsAlertTransformerList: payload,
            }

        case SET_ALERT_PREVIEW_TABLE_RESULT:
            return {
                ...state,
                previewTableResult: payload,
            }
        case SET_ALERT_QUERY_TABLES_RESULT:
            return {
                ...state,
                queryResult: payload,
            }
        case SET_ALERT_APPLIED_FILTERS:
            return {
                ...state,
                appliedFilters: payload
            }

        default:
            return state;

    }
}

export default CreateAlertReducer