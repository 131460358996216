import React, { useEffect, useRef, useState } from "react";

import { cloneDeep, isArray, isEmpty } from "lodash";
import { Input, DatePicker, TimePicker, Select, Tag, Tooltip } from "antd";
import Buttons from "../../../../Common/button/Buttons";
import SingleSelection from "../../../../Common/dropDown/SingleSelection";
import { MINUS } from "../../../../Common/iconSource";
import dayjs from "dayjs";

import { correctVarcharTerm, correctFloatTerm, correctIntegerTerm, dateOptions, filterOptions, numericFilterOptions, DATATYPEGROUPS } from "../../../common/helperFunctions";
import { Literals } from "../../../common/literals";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { ICON_CLOSE, ICON_PARAMETER } from "../../../../Common/newIconSource";
import DataGridCriteriaDropdown from "../../../../Common/dataGridTable/newDataGridTable/DataGridCriteriaDropdown";
import { getParametersList } from "../../../../../store/modules/flows/NewFlowsRedux/flowsActions";
import { useStore } from "react-redux";
import MultiSelection from "../../../../Common/dropDown/MultiSelection";

const FilterFormGroup = ({
  isdisabled = false,
  formGroup,
  formGroupIndex,
  filterColumns,
  removeFormGroup,
  setFormData,
  from = '',
  isFilterTransformer = '',
  submitFlags,
  submitedForm,
}) => {
  const [columnValue, setColumnValue] = useState(
    formGroup.column ? formGroup.column : undefined
  );
  const [filterOption, setFilterOption] = useState(
    formGroup.actualCriteria ? formGroup.actualCriteria : formGroup.criteria ? formGroup.criteria : undefined
  );
  const [ParameterActive, setParameterActive] = useState(
    formGroup.parameterActive ? formGroup.parameterActive : false
  );
  const [filterValue, setFilterValue] = useState(
    formGroup.actualValue ? formGroup.actualValue : undefined
  );
  const [filterValue1, setFilterValue1] = useState(
    isArray(formGroup.actualValue) ? formGroup.actualValue[0] : undefined
  );
  const [filterValue2, setFilterValue2] = useState(
    isArray(formGroup.actualValue) ? formGroup.actualValue[1] : undefined
  );

  const store = useStore()
  const [parameters, setParameters] = React.useState([])

  const [filterType, setFilterType] = useState(
    formGroup.datatype ? formGroup.datatype : ""
  );


  const formGroupRef = useRef([])
  useEffect(() => {
    if (formGroup?.length) {
      setColumnValue(formGroup.column)
      setFilterOption(formGroup.criteria)
    }
  }, [formGroup])


  let booleanValueOptions = [{ Name: "True", value: "@true" }, { Name: "False", value: "@false" }] // { Name: "Null", value: "@null" }

  const setDataType = (value) => {
    filterColumns.map((column) => {
      if (column.Name === value) {
        setFilterType(column.Type);
      }
    });
  };

  const setFilterCriteriaValue = (value, valueType) => {
    if (filterOption?.toLowerCase() === "between" || filterOption?.toLowerCase() === "not between") {
      let filterValue = "";
      if (valueType === "firstValue") {
        if (filterValue2 !== "") {
          filterValue = [value?.trim(), filterValue2?.trim()];
        }

        setFilterValue1(value);
      } else {
        if (filterValue1 !== "") {
          filterValue = [filterValue1?.trim(), value?.trim()];
        }
        setFilterValue2(value);
      }
      if (filterValue !== "") {
        setFilterValue(filterValue);
        setFormData(
          formGroupIndex,
          columnValue,
          filterOption,
          filterValue,
          filterType
        );
      }
    }
  };
  let helperText = null;


  useEffect(() => {
    if (filterOption?.toLowerCase() === "is" || filterOption?.toLowerCase() === "is not") {
      setFilterValue("BLANK/NULL")
      setFormData(
        formGroupIndex,
        columnValue,
        filterOption,
        "BLANK/NULL",
        filterType
      )
    }
    if (filterOption?.toLowerCase() === "in" || filterOption?.toLowerCase() === "not in") {
      let clonelist = cloneDeep(parameters);
      clonelist.forEach((ele) => {
        ele.isDisableOption = false;
      })
      setParameters(clonelist)
    }
   
  

    if (filterOption !== 'in' && filterOption !== 'not in' && ParameterActive) {
      let clonelist = cloneDeep(parameters);
      if(!filterValue?.length){
        clonelist.forEach((ele) => {
          ele.isDisableOption = false;
        })
      }
     
      setParameters(clonelist)
    }

  }, [filterOption])

  const currentErrorIndex = useRef(null)
  const showCriteriaError = useRef(false)
  const isColumnValuePresent = () => {


    if (from === 'filterTransformer' && filterColumns?.length && formGroup?.column !== "") {
      let isPresent = filterColumns.findIndex(element => element.columnValue === formGroup?.column);
      if (isPresent > -1) {
        return true

      } else {
        currentErrorIndex.current = formGroupIndex
        return false
      }
    } else {
      return true
    }
  }


  const getColumnId = (value) => {
    if (value && filterColumns?.length) {
      let isPresent = true;
      return filterColumns.find(element => element.Name === value)?.id
    }
  }
  let onlyDateRegex = /\b(?:date)\b/gi;
  let onlytimeRegex = /\b(?:time)\b/gi;
  let onlyDatetimeRegex = /\b(?:timestamp|datetime)\b/gi;

  const getParams = () => {
    store.dispatch(getParametersList(store?.getState().Lab?.id)).then((resp) => {
      if (resp?.data?.length) {
        let list = resp?.data;
        list?.forEach(element => {
          element['Name'] = element.name;
          element['value'] = element?.name;

          if (filterOption !== 'in' && filterOption !== 'not in' && formGroup?.parameterActive) {

            if (filterValue?.length) {
              if (filterValue?.includes(element.name)) {

                element['isDisableOption'] = false;
              } else {
                element['isDisableOption'] = true;
              }
            } else {
              element['isDisableOption'] = false;
            }

          }

        });

        setParameters(list);

      }
    })
  }

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    return (
      <Tag
        color={'#2c83c4'}

        closable={false}

        style={{
          marginInlineEnd: 4,
        }}
      >
        {value}
      </Tag>
    );
  };

  useEffect(() => {
    if (from === 'filterTransformer') {

      getParams();
    }
  }, [])



  useEffect(() => {

    if (from === 'filterTransformer' && filterColumns?.length && formGroup?.column !== "") {

      let element = filterColumns.find(element => element.columnValue === formGroup?.column);

      if (element?.Type !== formGroup?.datatype) {

        setFilterType(element?.Type);
        if (element?.Type?.toLowerCase() === 'date' || element?.Type?.toLowerCase() === 'time' || element?.Type?.toLowerCase() === 'timestamp' || element?.Type?.toLowerCase() === 'datetime') {
          setFilterValue(undefined)
          setFilterValue1(undefined)
          setFilterValue2(undefined)

        }
      }

    }
  }, [filterColumns])

  const getParameterByType = (filterType) => {
    let optionList = [];
    let splitType=filterType?.split('(')[0];
    let matchedDatatypeGroup = []
    if (DATATYPEGROUPS?.NUMERIC_DATATYPE?.findIndex((item) => item?.Name?.toLowerCase() === splitType?.toLowerCase()) > -1) {
      matchedDatatypeGroup = DATATYPEGROUPS?.NUMERIC_DATATYPE;

    } else if (DATATYPEGROUPS?.STRING_DATATYPE?.findIndex((item) => item?.Name?.toLowerCase() === splitType?.toLowerCase()) > -1) {
      matchedDatatypeGroup = DATATYPEGROUPS?.STRING_DATATYPE;

    } else if (DATATYPEGROUPS?.BOOLEAN_DATATYPE?.findIndex((item) => item?.Name?.toLowerCase() === splitType?.toLowerCase()) > -1) {
      matchedDatatypeGroup = DATATYPEGROUPS?.BOOLEAN_DATATYPE;

    } else if (DATATYPEGROUPS?.DATE_DATATYPE?.findIndex((item) => item?.Name?.toLowerCase() === splitType?.toLowerCase()) > -1) {
      matchedDatatypeGroup = DATATYPEGROUPS?.DATE_DATATYPE;

    }

    if (matchedDatatypeGroup?.length) {
      parameters?.forEach((ele) => {
        let findIndex = matchedDatatypeGroup?.findIndex((item) => item.Name?.toLowerCase() === ele?.data_type?.toLowerCase());
        if (findIndex > -1) {
          optionList.push(ele);
        }
      })
    }

    return optionList;
  }



  return (
    <div key={formGroupIndex} className="form-group">
      <div className="filter-fields">
        <div className="">
          <SingleSelection
            props={{
              isSortOptionDisable: true,
              label: "",
              selectClassName:
                (!isColumnValuePresent() && currentErrorIndex.current === formGroupIndex)
                  ? "is-invalid custom-select-dropdown"
                  : "custom-select-dropdown",
              placeholder: `${filterColumns.length} Column Names`,
              dropDownValue: columnValue,
              onClickEvent: (value) => {
                setColumnValue(value);
                setDataType(value);
                setFilterValue('')
                setFilterValue1('')
                setFilterValue2('')
                setFilterOption('')
                setFormData(
                  formGroupIndex,
                  value,
                  '',
                  '',
                  '',
                  getColumnId(value),
                  '',
                  false
                );
                filterColumns.map((column) => {
                  if (column.Name === value && column.Type === 'boolean') {
                    setFilterValue('')
                  }
                });
              },
              optionList: filterColumns,
              isDisable: isdisabled,
            }}
          />
          {(!isColumnValuePresent() && currentErrorIndex.current === formGroupIndex) ? <span className="error">Column not present in the list.</span> : <></>}
        </div>

        {
          <>
            <div className=" padding-left-2 padding-right-2">
              <DataGridCriteriaDropdown
                column={{ name: columnValue, dtype: filterType ?? formGroup?.datatype }}
                // { () => filterColumns?.map((item) => {
                //   item['name'] = item.Name;
                //   item['dtype'] = item.Type;
                //   return item
                // })}
                disable={isdisabled}
                parentClassName={isFilterTransformer === 'newFilterTransformer' ? 'old-filter-criteria-box-width ' : 'old-filter-criteria-box-width'} //new-filter-criteria-box-width
                setConList={(value) => {
                  setFilterOption(value);
                  setFilterValue1('')
                  setFilterValue2('')
                  setFormData(
                    formGroupIndex,
                    columnValue,
                    value,
                    '',
                    filterType,
                    getColumnId(columnValue),
                    '',
                    false
                  );
                  value?.toLowerCase() === "is" || value?.toLowerCase() === "is not" ? setFilterValue("BLANK/NULL") : setFilterValue('')
                }}
                activeCriteria={filterOption}
              >

              </DataGridCriteriaDropdown>
            </div>
            <div className="d-flex align-item-center justify-content-start">
              {(!ParameterActive || filterOption?.toLowerCase() === "is" || filterOption?.toLowerCase() === "is not") ? <div className="col-8 pl-0">
                {/* || filterOption?.toLowerCase() === 'in' || filterOption?.toLowerCase() === 'not in' */}
                {filterOption?.toLowerCase() !== "between" && filterOption?.toLowerCase() !== "not between" && filterType?.toLowerCase() !== 'boolean' ? (
                  <div className="">
                    {filterType?.match(onlyDateRegex)?.length && filterOption?.toLowerCase() !== "is" && filterOption?.toLowerCase() !== "is not" ?
                      <DatePicker
                        picker="date"

                        placeholder="Please Enter a Filter Date"
                        className={
                          !filterValue
                            ? "custom-input-field "
                            : "custom-input-field"
                        }
                        value={filterValue ? dayjs(filterValue, "YYYY-MM-DD") : null}
                        format="YYYY-MM-DD"
                        onChange={(e, val) => {

                          //let value=e.target.value
                          let value = val;
                          setFilterValue(value);
                          setFormData(
                            formGroupIndex,
                            columnValue,
                            filterOption,
                            value,
                            filterType,
                            getColumnId(columnValue)

                          );
                        }}

                      />
                      :
                      filterOption?.toLowerCase() !== "is" && filterOption?.toLowerCase() !== "is not" && filterType?.match(onlytimeRegex)?.length ? <TimePicker picker="time"
                        placeholder="Please select time"
                        className={
                          !filterValue
                            ? "custom-input-field "
                            : "custom-input-field"
                        }
                        value={filterValue ? dayjs(filterValue, "HH:mm:ss") : null}
                        disabled={isdisabled}
                        onChange={(e, val) => {

                          //let value=e.target.value
                          let value = val;

                          setFilterValue(value);
                          setFormData(
                            formGroupIndex,
                            columnValue,
                            filterOption,
                            value,
                            filterType,
                            getColumnId(columnValue)

                          );
                        }}
                      ></TimePicker> :
                        filterOption?.toLowerCase() !== "is" && filterOption?.toLowerCase() !== "is not" && (filterType?.match(onlyDatetimeRegex)?.length) ?
                          <DatePicker
                            picker="date"
                            showTime
                            placeholder="Please Enter a Filter Date"
                            className={
                              !filterValue
                                ? "custom-input-field "
                                : "custom-input-field"
                            }
                            value={filterValue ? dayjs(filterValue, "YYYY-MM-DD HH:mm:ss") : null}
                            format="YYYY-MM-DD HH:mm:ss"
                            onChange={(e, val) => {

                              //let value=e.target.value
                              let value = val;
                              setFilterValue(value);
                              setFormData(
                                formGroupIndex,
                                columnValue,
                                filterOption,
                                value,
                                filterType,
                                getColumnId(columnValue)

                              );
                            }}

                          />


                          // <Input

                          // type="date"

                          // placeholder="Please Enter a Filter Date"
                          // min="1920-01-01" max="2100-01-01"
                          // className={
                          //   !filterValue
                          //     ? "custom-input-field "
                          //     : "custom-input-field"
                          // }
                          // value={filterValue}

                          // onChange={(e) => {


                          //   let value=e.target.value
                          //   setFilterValue(value);
                          //   setFormData(
                          //     formGroupIndex,
                          //     columnValue,
                          //     filterOption,
                          //     value,
                          //     filterType
                          //   );
                          // }}
                          // />

                          :
                          filterOption?.toLowerCase() === "in" || filterOption?.toLowerCase() === "not in" ?
                            <div className="custom-multi-select custom-select-dropdown IN-Select">
                              <Select
                                mode="tags"
                                style={{
                                  width: '100%',
                                }}
                                showSearch={false}
                                allowClear={true}
                                filterOption={false}
                                className={!filterValue
                                  ? ""
                                  : ""}
                                value={!filterValue || filterValue === '' ? [] : filterValue}
                                maxTagCount={4 ??'responsive'}
                                onChange={(value) => {

                                  setFilterValue(value);
                                  setFormData(
                                    formGroupIndex,
                                    columnValue,
                                    filterOption,
                                    value,
                                    filterType,
                                    getColumnId(columnValue)

                                  );
                                }}
                                tokenSeparators={[',']}
                                options={[]}
                                disabled={isdisabled}
                              /></div>
                            : <input
                              disabled={isdisabled || filterOption?.toLowerCase() === "is" || filterOption?.toLowerCase() === "is not"}
                              className={
                                !filterValue
                                  ? "custom-input-field "
                                  : "custom-input-field"
                              }
                              type={filterOption?.toLowerCase() !== "is" && filterOption?.toLowerCase() !== "is not" && ["integer", "int", "decimal", "tinyint", "bigint", "smallint", "double", "real"].includes(filterType) ? "number" : "text"}
                              step={["integer", "tinyint", "bigint", "smallint"] ? 1 : 0.00000000001}
                              placeholder="Please Enter a Filter Value"
                              value={filterValue}
                              onChange={(e) => {
                                let value = "";
                                if (filterType == "varchar" || filterType == "string" || filterType == "char")
                                  value = correctVarcharTerm(e.target.value);
                                else if (filterType == "double")
                                  value = correctFloatTerm(e.target.value);
                                else if (filterType == "integer")
                                  value = correctIntegerTerm(e.target.value)
                                else
                                  value = e.target.value


                                setFilterValue(value);
                                setFormData(
                                  formGroupIndex,
                                  columnValue,
                                  filterOption,
                                  value,
                                  filterType,
                                  getColumnId(columnValue)
                                );
                              }}
                            />}
                  </div>
                ) : filterType.toLowerCase() === 'boolean' ? <div className="w-40"> <SingleSelection
                  props={{
                    label: "",
                    selectClassName:
                      (!isColumnValuePresent() && currentErrorIndex.current === formGroupIndex)
                        ? "is-invalid custom-select-dropdown"
                        : "custom-select-dropdown",

                    placeholder: "Select Column Value",
                    dropDownValue: filterValue,
                    onClickEvent: (value) => {
                      setFilterValue(value);
                      setFormData(
                        formGroupIndex,
                        columnValue,
                        filterOption,
                        value,
                        filterType,
                        getColumnId(columnValue)

                      );

                    },
                    optionList: booleanValueOptions,
                    isDisable: isdisabled || filterOption?.toLowerCase() === "is" || filterOption?.toLowerCase() === "is not",
                  }}
                /></div> : (
                  <div className="d-flex align-items-center">
                    <div className="w-40">
                      {
                        filterOption !== "is" && filterOption !== "is not" && filterType?.toLowerCase() === 'date'
                          ?
                          <DatePicker
                            picker="date"
                            placeholder="Please Enter a Filter Date"
                            className={
                              !filterValue1
                                ? "custom-input-field"
                                : "custom-input-field"
                            }
                            value={filterValue1 ? dayjs(filterValue1, "YYYY-MM-DD") : null}
                            disabled={isdisabled}
                            // value={filterValue1}
                            format="YYYY-MM-DD"
                            onChange={(e, val) => {
                              setFilterCriteriaValue(val, "firstValue")
                            }}

                          />
                          :
                          filterOption !== "is" && filterOption !== "is not" && filterType?.toLowerCase() === 'time' ? <TimePicker picker="time"
                            placeholder="Please select time"
                            className={
                              !filterValue1
                                ? "custom-input-field"
                                : "custom-input-field"
                            }
                            value={filterValue1 ? dayjs(filterValue1, "HH:mm:ss") : null}
                            disabled={isdisabled}

                            onChange={(e, val) => {

                              setFilterCriteriaValue(val, "firstValue")
                            }}
                          ></TimePicker> :
                            filterOption !== "is" && filterOption !== "is not" && (filterType?.toLowerCase() === "datetime" || filterType?.toLowerCase() === "timestamp") ?
                              <DatePicker
                                picker="date"
                                showTime
                                placeholder="Please Enter a Filter Date"
                                className={
                                  !filterValue1
                                    ? "custom-input-field"
                                    : "custom-input-field"
                                }
                                value={filterValue1 ? dayjs(filterValue1, "YYYY-MM-DD HH:mm:ss") : null}
                                format="YYYY-MM-DD HH:mm:ss"
                                onChange={(e, val) => {
                                  setFilterCriteriaValue(val, "firstValue")
                                }}
                                disabled={isdisabled}

                              /> :

                              <input
                                disabled={isdisabled || filterOption?.toLowerCase() === "is" || filterOption?.toLowerCase() === "is not"}
                                className={
                                  !filterValue1
                                    ? "custom-input-field"
                                    : "custom-input-field"
                                }
                                type={filterOption !== "is" && filterOption !== "is not" && ["integer", "decimal", "tinyint", "bigint", "smallint", "double"].includes(filterType) ? "number" : "text"}

                                placeholder="Please Enter a Filter Value"
                                value={filterValue1}
                                onChange={(e) => {
                                  setFilterCriteriaValue(e.target.value, "firstValue");
                                }}
                              />
                      }
                    </div>
                    <label className="caption mb-0 w-20 text-center">AND</label>
                    <div className="w-40">
                      {
                        filterOption !== "is" && filterOption !== "is not" && filterType?.toLowerCase() === 'date'
                          ?
                          <DatePicker
                            picker="date"
                            placeholder="Please Enter a Filter Date"
                            className={
                              !filterValue2
                                ? "custom-input-field"
                                : "custom-input-field"
                            }
                            value={filterValue2 ? dayjs(filterValue2, "YYYY-MM-DD") : null}

                            // value={filterValue2}
                            format="YYYY-MM-DD"
                            onChange={(e, val) => {
                              setFilterCriteriaValue(val, "secondValue")
                            }}
                            disabled={isdisabled}

                          />
                          :
                          filterOption?.toLowerCase() !== "is" && filterOption?.toLowerCase() !== "is not" && filterType?.toLowerCase() === 'time' ? <TimePicker picker="time"
                            placeholder="Please select time"
                            className={
                              !filterValue2
                                ? "custom-input-field"
                                : "custom-input-field"
                            }
                            value={filterValue2 ? dayjs(filterValue2, "HH:mm:ss") : null}
                            disabled={isdisabled}

                            onChange={(e, val) => {

                              setFilterCriteriaValue(val, "secondValue")
                            }}
                          ></TimePicker> :
                            filterOption?.toLowerCase() !== "is" && filterOption?.toLowerCase() !== "is not" && (filterType?.toLowerCase() === "datetime" || filterType?.toLowerCase() === "timestamp") ?
                              <DatePicker
                                picker="date"
                                showTime
                                placeholder="Please Enter a Filter Date"
                                className={
                                  !filterValue2
                                    ? "custom-input-field"
                                    : "custom-input-field"
                                }
                                value={filterValue2 ? dayjs(filterValue2, "YYYY-MM-DD HH:mm:ss") : null}
                                format="YYYY-MM-DD HH:mm:ss"
                                onChange={(e, val) => {
                                  setFilterCriteriaValue(val, "secondValue")
                                }}
                                disabled={isdisabled}

                              /> :
                              <input
                                disabled={isdisabled}
                                className={
                                  !filterValue2
                                    ? "custom-input-field"
                                    : "custom-input-field"
                                }
                                type={filterOption?.toLowerCase() !== "is" && filterOption?.toLowerCase() !== "is not" && ["integer", "decimal", "tinyint", "bigint", "smallint", "double"].includes(filterType) ? "number" : "text"}

                                placeholder="Please Enter a Filter Value"
                                value={filterValue2}
                                onChange={(e) => {
                                  setFilterCriteriaValue(e.target.value, "secondValue");
                                }}
                              />
                      }
                    </div>
                  </div>
                )}
              </div> : null}
              {from === 'filterTransformer' && filterOption?.toLowerCase() !== "is" && filterOption?.toLowerCase() !== "is not" ? <div className={`${ParameterActive && filterOption?.toLowerCase() !== "in" && filterOption?.toLowerCase() !== "not in" ? 'col-8 ' : 'col-8'} pl-0 d-flex justify-content-start`}>
                {ParameterActive ? <div className="w-100 mr-3">

                  {/* <Select
                    showSearch
                    mode="multiple"
                    tagRender={tagRender}
                    maxCount={1}
                    style={{
                      width: '100%',
                    }}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip title={omittedValues.map(({ value, Name }) => value).join(', ')}>
                        <span>More...</span>
                      </Tooltip>
                    )}
                    className="custom-select-dropdown parameter-tags mr-3 "

                    options={parameters}
                    placeholder={"Select Parameter"}
                    value={filterValue}
                    onChange={(value) => {
                      setFilterValue(value);
                      setParameterActive(true);
                      setFormData(
                        formGroupIndex,
                        columnValue,
                        filterOption,
                        value,
                        filterType,
                        getColumnId(columnValue),
                        true,
                        value
                      );

                    }}

                  /> */}
                  <MultiSelection
                    props={{
                      label: "",
                      selectClassName:
                        "custom-select-dropdown parameter-select-dropdown mr-3 ",

                      placeholder: "Select Parameter",

                      dropDownValue: !filterValue || filterValue === '' ? [] : filterValue,
                      onClickEvent: (value) => {


                        if (filterOption !== 'in' && filterOption !== 'not in') {
                          let clonelist = cloneDeep(parameters);
                          clonelist.forEach((ele) => {
                            if (value?.length) {
                              if (value?.includes(ele.value)) {

                                ele.isDisableOption = false;
                              } else {
                                ele.isDisableOption = true
                              }
                            } else {
                              ele.isDisableOption = false;
                            }

                          })
                          setParameters(clonelist);
                          setFilterValue(value?.toString());
                          setParameterActive(true);
                          setFormData(
                            formGroupIndex,
                            columnValue,
                            filterOption,
                            value?.toString(),
                            filterType,
                            getColumnId(columnValue),
                            true,
                            value?.toString()
                          );
                        } else {
                          setFilterValue(value);
                          setParameterActive(true);
                          setFormData(
                            formGroupIndex,
                            columnValue,
                            filterOption,
                            value,
                            filterType,
                            getColumnId(columnValue),
                            true,
                            value
                          );
                        }


                      },
                      optionList: getParameterByType(filterType),
                      isDisable: isdisabled,
                    }}
                  />
                </div> : null}

                <Buttons
                  props={{
                    tooltip: "Add Parameter",
                    tooltipPlacement: 'bottom',
                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2 ${ParameterActive ? 'active' : ''}`,
                    // ${openParamaterDrawer ? 'active' : ''}


                    buttonEvent: () => {


                      setParameterActive(!ParameterActive)
                      setFilterValue('');
                      setFormData(
                        formGroupIndex,
                        columnValue,
                        filterOption,
                        '',
                        filterType,
                        getColumnId(columnValue),
                        true,
                        ''
                      );


                    },
                    ImgSrc: () => <ICON_PARAMETER />,
                    isDisable: isdisabled,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />
              </div> : null}

            </div>




          </>
        }
        <div className={`remove-filter ${window.location.href.includes("viewAlert")  //disable in viewmode
          ? true
          : window.location.href.includes("viewAlert") || window.location.href.includes("viewFlows") ? 'disabled' : ''}`} onClick={(e) => {
            e.stopPropagation()
            removeFormGroup(false, formGroupIndex);
          }}
        >
          <ICON_CLOSE />
        </div>
      </div>
      {
        isEmpty(filterOption?.toLowerCase()) ? null : helperText ? (<div
          className="col-md-12 m-t-5 p-l-0 d-flex"
          style={{ flexDirection: "column", textAlign: "left", padding: "0px", color: " rgb(250, 173, 20)", fontSize: "10px" }}
        >

          <label className=" m-b-0" >
            <i className="fa fa-info-circle" aria-hidden="true"></i>
            {helperText ? <small style={{ color: " rgb(250, 173, 20)", fontSize: '10px' }} className="m-t-2">{helperText}</small> : null}
          </label>

        </div>) : null
      }
    </div >
  );
};

export default FilterFormGroup;