import React from "react";
import "./PolicyLeftSide.scss";

const PolicyLeftSide = ({
  policyName,
  policyDescription,
  onChange,
  viewMode,
  policyNameError,
  isEditorPermission = false
}) => {
  return (
    <div className="policy-left-bar w-100">
      <div className="policy-details-header">Policy Details</div>
      <div className="policy-details">
        <div className="form-group">
          <label className="label">Policy Name</label>
          <input
            value={policyName}  
            placeholder="Plese Enter Policy Name"
            className="custom-input-field"
            disabled={viewMode.includes("view")}
            onChange={(e) => {
              onChange("policyName", e.target.value);
            }}
          ></input>
          {policyNameError && <small>{policyNameError}</small>}
        </div>
        <div className="form-group">
          <label className="label">Policy Description</label>
          <textarea
            value={policyDescription}
            placeholder="Please Enter Description"
            disabled={viewMode.includes("view") || !isEditorPermission}
            onChange={(e) => {
              onChange("policyDescription", e.target.value);
            }}
            className="custom-textarea-field"
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyLeftSide;
