import React, { useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { getColumnDataType } from "../../modules/common/helperFunctions";
const { Option } = Select;
function SingleSelection({ props }) {
  const {
    id = '',
    label,
    allowClear=false,
    selectClassName,
    isSortOptionDisable = false,
    placeholder,
    dropDownValue = [],
    defaultOpenDropdown = false,
    onClickEvent,
    optionList,
    isDisable,
    className = '',
    optionClassName = '',
    bordered = true
  } = props;
  const [customOptionList, setCustomOptionList] = useState([]);

  useEffect(() => {
    if (optionList && optionList.length) {
      setCustomOptionList([...optionList]);
    } else {
      setCustomOptionList([]);
    }
  }, [optionList]);

  const getClusterState = () => {
    let clusterState = customOptionList.find((ele, index) => ele.state && ele.value === dropDownValue)

    if (clusterState) {
      return clusterState.state
    } else {
      return false
    }
  }



  return (
    <div className={`${bordered ? 'custom-select-dropdown' : ''} ${selectClassName}`}>
      <div className="d-flex align-items-center">
        {getClusterState() ? <Tooltip title={getClusterState()} ><div className={getClusterState()?.toLowerCase() + ' ' + 'mb-0 '}></div></Tooltip> : null}

        {label && <label className="label">{label}</label>}
      </div>
      <Select
        id={id}
        bordered={bordered}
        allowClear={ allowClear}
        defaultOpen={defaultOpenDropdown}
        showSearch
        disabled={isDisable}
        placeholder={placeholder}
        optionFilterProp="children"
        value={dropDownValue}
        onClick={e => e.stopPropagation()}
        onChange={(value) => onClickEvent(value)}
      
        filterOption={(input, option) => {
         
          return option?.value?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 || option?.label?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
        }
        }
        filterSort={
          isSortOptionDisable === true ? () => { } :
            (optionA, optionB) =>
              optionA?.value?.toString()?.toLowerCase()?.localeCompare(optionB?.value?.toString()?.toLowerCase())
        }
        className={className}
      >
        {customOptionList?.length &&
          customOptionList.map((ele, index) => (

            <Option disabled={ele.isDisableOption} className={optionClassName} key={index} label={ele.Name} value={ele.value ? ele.value : ele.Name}>
              {ele.Type !== "" && (
                <span className="element-type">
                  {getColumnDataType(ele.Type)}
                </span>
              )}
              {ele.icon ? ele.icon() : null}{" "}
              <Tooltip title={ele.Name}> <span className="element-name ml-1 ">{ele.Name}</span>{" "}</Tooltip>
              {ele.subTitle ? <div className="d-flex justify-content-between align-items-center"><span className="small ml-1 "> {ele.subTitle}</span>
                {ele.state ? <Tooltip title={ele.state} ><div className={ele.state?.toLowerCase()}></div></Tooltip> : null}  </div> : null}


            </Option>
          ))}
      </Select>
    </div >
  );
}

export default SingleSelection;
