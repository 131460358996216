import React from 'react'
import { Tabs, Tooltip } from 'antd';
import { FILLEDPIN } from '../iconSource';

// const { TabPane } = Tabs;
function DataTabs({ props }) {
    const { activeKey, showTooltip = true, defaultKey, onChange, tabArray = [], onEdit, addButton } = props
   
    return (

        <div className="data-tab">  
            {/* <Tooltip placement="top" title={element.tabTitle}></Tooltip> <Tooltip placement="topLeft" title={element.tabTitle}>*/}
            <Tabs onEdit={onEdit} size='small' hideAdd activeKey={tabArray.length === 1 ? tabArray[0].key : activeKey} defaultActiveKey={defaultKey} onChange={onChange} type="card" items={tabArray && tabArray.length > 0 && tabArray.map((element, i) => {
                const id = String(i + 1);
                return {
                    label: element.tabTitle,
                    key: element.key, // `${id}-${element.id}`,
                    children: element.tabContent ? element?.tabContent(element.key) : null  ,
                    closable: element.closable,
                    disabled: element.disabled
                };
            }
                // <TabPane disabled={element.disabled} tab={element.bookmark !== undefined && element.bookmark === true ? <span><i><FILLEDPIN /></i>{element.tabTitle}</span> : !showTooltip ? element.tabTitle : <Tooltip placement="topLeft" title={element.tabTitle}>{element.tabTitle}</Tooltip>} key={element.key} closable={element.closable}>
                //     {element.tabContent ? element?.tabContent(element.key) : null}
                // </TabPane>
            )}>

            </Tabs>
            {addButton && addButton}
        </div>
    )
}

export default DataTabs
