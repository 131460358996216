import { Tabs } from 'antd';
import _, { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector, useStore } from 'react-redux';
import Buttons from '../../../../Common/button/Buttons';
import { ALERTBELL, CLOSE, CODEEDITOR, EDIT, EDITOR, INPUT, PLAY, RESETFILTER, SAVE, TABLEINSERT, TABLEUPDATE } from '../../../../Common/iconSource';
import { Literals } from "../../../common/literals";
import DraggableList from '../../draggableList';

import { executeTransformerApi, saveTransformerNoteClick, setFlowsCurrenttransformer, showFlowsTransformdetails, updateFlowsElements } from '../../../../../store/modules/flows/flowsActions';

import { ConnectionProvider } from '../../../connections/landingPage/components/ConnectionContext';


import { useParams } from 'react-router-dom';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { setActivePodDetails } from '../../../../../store/modules/common/VirtualDataTable/action';
import SearchInput from '../../../../Common/search/Search';
import DatasetOutputNameInput from '../commonFunctions/useDatasetNameInput';
import EditorIndex from '../DataSource/editor/EditorIndex';
import { SCRIPTDEFAULTVIEW } from '../script/ScriptDefaultInfography';
import { getInputFrame, getInputTransformerDetails, mergeArraysOrderRetain, orderRetainCheck } from '../FlowsHelperFunction';
import { getConfirmationModal, getObjectLength, mergeArrayByProperty } from '../../../common/helperFunctions';
const { TabPane } = Tabs;

const AlertTransformerIndex = ({
    flowsElements,
    transformerNameList,

    currentTransformer,

    isExecutePermission,

}) => {

    const [disableExcute, setdisableExcute] = useState(true)
    const [columnList, setcolumnList] = useState([])
    const [originalcolumnList, setOriginalcolumnList] = useState([])
    const [activeKey, setActiveKey] = useState('properties')
    const dispatch = useDispatch();
    const [toggleDefaultView, setToggleDefaultView] = useState(true)
    const [showBtnLoading, setShowBtnLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [openWarningModal, setopenWarningModal] = useState(false)
    const [showResetColumns, setshowResetColumns] = useState(false)
    const [allChecked, setallChecked] = useState(false)
    const [transformerName, settransformerName] = useState('')
    const [showRedBtn, setshowRedBtn] = useState(false);

    const [mergeShowCheckBox, setmergeShowCheckBox] = useState([])
    const [searchData, setSearchData] = useState('');
    const store = useStore()
    const { flowId } = useParams();

    useEffect(() => {
        if (transformerName === '') {
            setshowRedBtn(true)
        }
    }, [transformerName])

    const onChange = activeKey => {
        setActiveKey(activeKey)
    };

    const columnListRef = useRef([])
    useEffect(() => {
        if (columnList && columnList.length) {
            setOriginalcolumnList([...columnList])
            columnListRef.current = columnList
            setallChecked(columnList.every((ele) => ele?.checked === true))

        }
        return () => {
            let currentFlowsElements = cloneDeep(store.getState().FlowsReducer.flowsElements)
            let finalFlowsElements = currentFlowsElements.map(item => {
                if (item.id === currentTransformer.id) {
                    let list = columnListRef.current.filter(item => item?.checked)
                    if (list?.length) {
                        item.content.appliedFilterCriteria = columnListRef.current.filter(item => item.checked);
                        item.content.orderList = columnList;
                    }

                }
                return item
            })
            store.dispatch(updateFlowsElements([...finalFlowsElements]))
        }
    }, [columnList])



    const createdraggableList = (list) => {

        if (Array.isArray(list)) {
            if (searchData.length !== 0) {
                return originalcolumnList.filter(itm => itm.Name.toLowerCase().includes(searchData.toLowerCase()))
            }
            else {
                return originalcolumnList

            }

        }
        else {
            return []
        }
    }


    const createColumnsList = (target = [], source = [], prop) => {

        let newColumnList = [];


        if (!target?.length) {
            source.forEach(sourceElement => {
                sourceElement['showError'] = false;
                newColumnList.push(sourceElement);
            })
            return newColumnList;
        } else {

            let elementFound = iselementFound();
            let noneFindElement = isnoneFindElement();


            if (elementFound?.length && noneFindElement?.length) {
                newColumnList = [...elementFound, ...noneFindElement]
                setshowResetColumns(true)
                setdisableExcute(true)

            }
            else if (elementFound?.length) {
                newColumnList = [...elementFound]
                // setshowResetColumns(true)
                // setdisableExcute(true)

            } else if (noneFindElement?.length) {
                newColumnList = [...noneFindElement]
                setshowResetColumns(true);
                setdisableExcute(true)

            } else {
                setshowResetColumns(false)
                setdisableExcute(false)


            }
            let cloneSource = cloneDeep(source)
            const getChecked = (column) => {
                if (elementFound?.length) {
                    return elementFound.find(item => item?.Name === column?.Name)?.checked
                } else if (target?.length) {

                    return target.find(item => item?.Name === column?.Name)?.checked
                }
            }
            cloneSource = cloneSource.map(sourceElement => {//----------------------------this will return an all sourcecolumns with
                sourceElement['showError'] = false;
                sourceElement['checked'] = getChecked(sourceElement);
                return sourceElement;
            })


            // return (mergeArrayByProperty(newColumnList, cloneSource, 'Name'))  //----------------------------this will return unique column remove duplicates from source columns i.e if duplicate found it will take newColumnList column
            let mergeList = (mergeArrayByProperty(newColumnList, cloneSource, 'Name'))  //----------------------------this will return unique column remove duplicates from source columns i.e if duplicate found it will take newColumnList column
            let orderList = currentTransformer?.content?.orderList;
            return orderRetainCheck(orderList,mergeList);
            // let cloneMergeList
            // if (orderList?.length) {
            //     cloneMergeList = mergeList.sort((a, b) => {
            //         const indexA = orderList.findIndex(item => item?.Name === a?.Name);
            //         const indexB = orderList.findIndex(item => item?.Name === b?.Name);

            //         // Handle cases where the element is not in the preferred order
            //         if (indexA === -1 && indexB === -1) {
            //             return 0; // No change in order
            //         } else if (indexA === -1) {
            //             return 1; // Move element A to the end
            //         } else if (indexB === -1) {
            //             return -1; // Move element B to the end
            //         }

            //         return indexA - indexB;
            //     });


            // }


            // if (orderList?.length) {
            //     orderList?.forEach((item, Index) => {
            //         if (item?.id?.toString()?.includes("emptyColumn")) {
            //             cloneMergeList.splice(Index, 0, item)
            //         }
            //     })
            // }


            // return cloneMergeList;
        }



        function isnoneFindElement() {  //----------------------------this function return an target column which is not present in sourcecolumn
            let obj = {}
            let list = [];
            if (target?.length) {
                let targetElement = cloneDeep(target)
                source.forEach(sourceElement => {
                    targetElement = targetElement.filter((item) => {
                        if (item['Name'] !== sourceElement['Name']  && !item?.id?.toString()?.includes('emptyColumn_')) {
                            item['showError'] = true;
                            item['checked'] = true;
                            return item;
                        }
                    })
                })

                return targetElement
            }

        }

        function iselementFound() {

            if (target?.length) {
                let targetElement = cloneDeep(target)  //----------------------------this function will return an target columns which is  present in sourcecolumns but with source column details
                let foundEleList = []
                source.forEach(sourceElement => {
                    targetElement.forEach((item) => {
                        if (item['Name'] === sourceElement['Name'] && item['id'] !== sourceElement['id']) {
                            item = cloneDeep(sourceElement)
                            item['showError'] = false;
                            item['checked'] = true;
                            foundEleList.push(item)
                        }
                    })
                })
                return foundEleList


            }

        }



    }


    useEffect(() => {
        if (
            Object.keys(transformerNameList).length &&
            currentTransformer.id &&
            transformerNameList[currentTransformer.id]
        ) {
            setshowRedBtn(false)

            settransformerName(transformerNameList[currentTransformer.id]);
        }

        let InputTransformer = dispatch(getInputTransformerDetails())

        let inputColumns = cloneDeep(InputTransformer[0]?.content?.outcomeColumns);


        if (InputTransformer && InputTransformer?.length && inputColumns?.length) {

            let column = []

            column = createColumnsList(currentTransformer?.content?.appliedFilterCriteria && currentTransformer?.content?.appliedFilterCriteria.length ? currentTransformer?.content?.appliedFilterCriteria : currentTransformer?.content?.outcomeColumns, inputColumns, 'Name')


            if (column?.length) {

                setOriginalcolumnList([...column])
                setcolumnList([...column])
                setdisableExcute(false)
                setToggleDefaultView(false)
                setShowBtnLoading(false);

            } else {

                setOriginalcolumnList([])
                setcolumnList([])
                setToggleDefaultView(true)
                setShowBtnLoading(false);
                setdisableExcute(true);
            }


        } else {
            setToggleDefaultView(true)
            setShowBtnLoading(false);
            setdisableExcute(true);
        }


    }, [currentTransformer]);

    const executeTransformer = async () => {
        if (currentTransformer.id && transformerName !== '') {
            let columnValue = []
            if (columnList?.length) {
                columnList.forEach((item, index) => {

                    if (item.checked) {
                        columnValue.push(
                            item.Name
                        )
                    }
                })
            }

            if (columnValue && columnValue?.length) {
                setShowBtnLoading(true)
                setdisableExcute(true)
                let payload = {
                    flow_body: {
                        Id: `${transformerName}`,
                        currentTransformerID: currentTransformer.id,
                        NodeType: `alert`,
                        Args: [
                            {
                                "Name": "frame",
                                "Value": dispatch(getInputFrame())
                            },

                            {
                                "Name": "columns",
                                "Value": columnValue
                            },

                        ]
                    }
                }
                try {
                    let response = flowId && await store.dispatch(executeTransformerApi(flowId, payload, currentTransformer.id))

                    setShowBtnLoading(false)
                    setdisableExcute(false)
                    setshowRedBtn(false)

                    if (response.status === 'success') {


                        let finalFlowsElements = flowsElements.map(item => {
                            if (item.id === currentTransformer.id) {
                                item.content.outcomeColumns = columnList.filter(item => item.checked);
                                item.content.orderList = columnList
                                item.content.appliedFilterCriteria = columnList.filter(item => item.checked);
                                item.transformerName = transformerName
                                item.content.executed_DAG = payload.flow_body;
                            }
                            return item
                        })
                        store.dispatch(updateFlowsElements([...finalFlowsElements]))
                    }

                }
                catch (error) {

                    let finalFlowsElements = flowsElements.map(item => {
                        if (item.id === currentTransformer.id) {
                            item.transformerName = transformerName
                            item.content.executed_DAG = payload.flow_body;
                            item.content.appliedFilterCriteria = columnList.filter(item => item.checked);
                        }
                        return item
                    })
                    store.dispatch(updateFlowsElements([...finalFlowsElements]))
                    setShowBtnLoading(false)
                    setdisableExcute(false)
                }

            } else {
                emitToastNotification('warning', 'Please select atleast one column. ')

            }
        } else if (transformerName === '') {
            emitToastNotification('info', 'Please enter transformer name to execute transformer ')
        }

    }

    const onChecked = (text, type) => {

        let tempOutcomeColumns = [...columnList]

        if (tempOutcomeColumns.filter(item => (item.Type === type && item.Name === text))[0]?.checked) {
            setallChecked(false)

            tempOutcomeColumns.filter(item => (item.Type === type && item.Name === text))[0]['checked'] = false
        }
        else {
            tempOutcomeColumns.filter(item => (item.Type === type && item.Name === text))[0]['checked'] = true
        }



        setcolumnList([...tempOutcomeColumns])
    }

    const selectall = (checked) => {

        if (checked) {
            setallChecked(true)
            let tempOutcomeColumns = [...columnList]
            tempOutcomeColumns.forEach(item => item.checked = true)
            setcolumnList([...tempOutcomeColumns])
        } else {
            setallChecked(false)
            let tempOutcomeColumns = [...columnList]
            tempOutcomeColumns.forEach(item => item.checked = false)
            setcolumnList([...tempOutcomeColumns])

        }
    }

    const closeLeftSideContent = () => {
        dispatch(setFlowsCurrenttransformer({}));
        dispatch(showFlowsTransformdetails(false));
    };
    const currentConditionTypeRef = useRef(null)
    const onResetEvent = () => {

        currentConditionTypeRef.current = {
            message: 'Columns that are not found in input source that will be removed',
            onConfirm: () => {
                let cloneCols = cloneDeep(columnList);
                // cloneCols = cloneCols.map((item, i) => {
                //     if (item.showError) {
                //         item.id = `emptyColumn_${new Date().getTime()}_${i}}`
                //         item.checked = false
                //         item.Name = ""
                //         item.Type = ""
                //         item.showError = false
                //     }
                //     return item
                // });
                cloneCols = cloneCols.filter((item) => !item.showError); /// directly remove error columns

                if (cloneCols?.length) {

                    setcolumnList([...cloneCols])
                }
                setopenWarningModal(false);
                setshowResetColumns(false)
                setdisableExcute(false)

                return true
            },
            onCancel: () => {
                setopenWarningModal(false);
                return false
            }
        }
        setopenWarningModal(true)
    }

    return (
        <>
            {
                getConfirmationModal({
                    open: openWarningModal,
                    setOpen: setopenWarningModal,
                    body: currentConditionTypeRef?.current?.message,
                    onConfirm: currentConditionTypeRef?.current?.onConfirm,
                    onCancel: currentConditionTypeRef?.current?.onCancel
                })
            }
            <ConnectionProvider>
                <div className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
                    <div className="d-flex align-items-center">
                        <ALERTBELL />
                        <h3 className="subtitle">Alert</h3>
                        <Tabs onChange={onChange} activeKey={activeKey} className="custom-tab tab-with-icon">
                            <TabPane tab={<CODEEDITOR />} key="properties">
                            </TabPane>
                            <TabPane tab={<EDITOR />} key="editor">
                            </TabPane>
                        </Tabs>
                    </div>
                    <div className="d-flex align-items-center">
                        {showResetColumns ? (
                            <Buttons
                                props={{
                                    tooltip: 'Reset Columns',
                                    buttonText: "",
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2 mr-2",
                                    buttonEvent: onResetEvent,
                                    toggleBtnValue: "",
                                    ImgSrc: () => <RESETFILTER />,
                                    isDisable: window.location.href.includes("viewFlows")
                                        ? true
                                        : false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    toggleBtnOption: null,
                                }}
                            />
                        ) : null}
                        <DatasetOutputNameInput transformerName={transformerName} setTransformerName={settransformerName} />

                        <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { closeLeftSideContent() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                        {activeKey === 'editor' ? <Buttons props={{ buttonText: !editMode ? 'Edit' : 'Save', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: !editMode ? () => { setEditMode(true); dispatch(saveTransformerNoteClick(false)) } : () => { dispatch(saveTransformerNoteClick(true)) }, ImgSrc: !editMode ? () => <EDIT /> : () => <SAVE />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}

                        {activeKey !== 'editor' ? <Buttons props={{ buttonText: 'Execute', buttonClassName: `${showRedBtn ? 'execute-btn-gradient' : ''} custom-btn-primary custom-btn btn-with-text`, showLoading: showBtnLoading, buttonEvent: activeKey === 'editor' ? () => { setEditMode(true) } : () => { executeTransformer() }, ImgSrc: activeKey === 'editor' ? () => <EDIT /> : () => <PLAY />, isDisable: !isExecutePermission || transformerName === '' || disableExcute || showResetColumns ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}

                    </div>
                </div>
                {activeKey === 'properties' && !toggleDefaultView ? <>
                    <div className="d-flex bg-white section-with-drag">
                        <div className="w-100 custom-border-right">
                            <div className="d-flex justify-content-between padding-2 py-1 custom-border-bottom">
                                <div className="d-flex align-items-center">
                                    <INPUT />
                                    <p className="paragraph ml-2 one-line-ellipsis custom-ellipsis">Input Columns</p>
                                    <div className=" ml-4 merge-select-checkbox">
                                        <input type="checkbox" disabled={window.location.href.includes('viewFlows') ? true : false} checked={allChecked} onChange={(e) => selectall(e.target.checked)} />
                                    </div>
                                    <div className="d-flex align-items-center">

                                        <SearchInput searchData={searchData} setSearchData={setSearchData} />
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="section-drag-undrag h-100 flows-target-props-win">

                        <div className="w-50 h-100  alert-props-win">
                            {columnList.length && createdraggableList(columnList)?.length > 0 ? <DraggableList onChecked={onChecked}
                                draggableList={createdraggableList(columnList)}
                                setcolumnList={setcolumnList}
                                stopDraggingRow={window.location.href.includes('viewFlows') ? true : false}
                                selectedFirstSecond={flowsElements.find(item => item.id === currentTransformer.id).content.selectedFirstSecond} /> : <div className='d-flex align-items-center justify-content-center text-muted h-100'>No Result Found</div>}
                        </div>
                    </div>

                </> : activeKey === 'properties' && toggleDefaultView ? <div className="no-datasource"><SCRIPTDEFAULTVIEW /></div> : null}

                {
                    activeKey === 'editor' && <EditorIndex editMode={editMode} setEditMode={setEditMode} />
                }
            </ConnectionProvider>
            {/* {props.loadingStatus ? <LoadingComponent /> : null} */}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer,
        transformerLoading: state.FlowsReducer.transformerLoading,
        appliedFilterCriteria: state.FlowsReducer.appliedFilterCriteria,
        loadingStatus: state.LoadingReducer.loadingStatus,
        transformerNameList: state.FlowsReducer.transformerName,



    };
};
export default connect(mapStateToProps)(AlertTransformerIndex);