
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER'
export const AUTHENTICATE = 'AUTHENTICATE'
export const SET_CURRENT_TOKENS = 'SET_CURRENT_TOKENS'

export const USER_LOGIN = 'USER_LOGIN';
export const USER_REGISTER = 'USER_REGISTER';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
export const USER_LOGOUT = 'USER_LOGOUT';

export const SET_DATA='SET_DATA';
export const SET_USER='SET_USER'