import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector, useStore } from "react-redux";
import {

  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import DefaultFlow from '../../../../assets/images/DefaultFlow.png';

import { GetAlertDataApi } from "../../../../store/modules/alert/alertLanding/alertLandingAction";
import {
  getExcecutionRetrive,
  getFlowsAlertTransformerList,
  ResetCreateAlertRedux,
  RetrieveAlert,
  setAlertAppliedFilters,
  setAlertPreviewTableResult,
  setAlertPreviewTables,
  setAlertQueryResult,
  setAlertSqlEditorQuery,
  setAlertSqlResponseQuery,
  setSelectedAlertPODList,
} from "../../../../store/modules/alert/createAlert/createAlertAction";
import {
  fetchPreviewTable,
  resetVirtualTableRedux,
  setActivePodDetails,
} from "../../../../store/modules/common/VirtualDataTable/action";
import { ACCORDIONDOWNARROW, ALERTBELLLARGE, BLACK_LOGS, DOWNLOAD, EXITFULLSCREEN, FLOWTABICON, FULLSCREEN, LOADINGCIRCLE, LOGS, PLAY, PLUS, PROPERTIES, REDIRECTJOBS, REFRESH, REFRESHLOGS, TIMELINE, USAGETIME } from "../../../Common/iconSource";
import useVirtualTableAction from "../../../Common/virtualDataTable/useVirtualTableAction";
import LandingpageFirstHeader from "../../common/LandingpageFirstHeader";
import LoadingComponent from "../../common/loadingComponent";
import PODSelection from "../../../Common/PODSelection";
import { getAuditData } from "../../../../store/app/appAction";
import CustomTimeline from '../../../Common/timeline/CustomTimeline';
import AlertDetails from "./AlertDetails";
import AlertSqlEditor from "./AlertSqlEditor";
import AlertSubHeader from "./AlertSubHeader";
import ListofPodDetails from "./ListofPodDetails";
import ResultSection from "./ResultSection";
import { NoConditionSelected } from "./SelectconditionInfography.";
import DataTabs from "../../../Common/dataTabs/DataTabs";
import AlertLogs from "./AlertLogs";
import AlertExceptionPreviewModal from "./AlertExceptionPreviewModal";
import ExceptionErrorLogModal from "./ExceptionErrorLogModal";
import { setSelectedPodList } from "../../../../store/modules/common/podSelectionModalAction";
import Accordian from "../../../Common/accordion/Accordian";
import { Literals } from "../../common/literals";
import Buttons from "../../../Common/button/Buttons";

import { resetAllDataGridTableFilters, setActiveTabKey, setPreviewFlag, setQueryKillFlag, setResponseQuery } from "../../../../store/modules/common/DataGridTable/dataGridTableRedux";
import { getPreviewTable, saveSQlTrigger, setPreviewTableQuery } from "../../../../store/modules/dataCatalogue/preview/previewActions";
import DataGridAddFilter from "../../../Common/dataGridTable/DataGridAddFilter";
// import createPlotlyComponent from "react-plotly.js/factory";
// import Plotly from "plotly.js";

import { List, Tooltip } from "antd";
import ActionPopUp from "../../common/actionPopUp";
import FlowSelectionModal from "./FlowSelectionModal";
import { AddFlowDefaultView } from "./AddFlowDefaultView";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { executeEntireFlow, getFlowDetails, RetriveExecutionFlows } from "../../../../store/modules/flows/flowsActions";
import { SET_FLOWS_ALERT_TRANSFORMER_LIST, SET_SELECTED_ALERT_TRANSFORMER } from "../../../../store/modules/alert/createAlert/createAlertActiontype";
import { openModel, resetGraphSampleSelected, setSidebarActiveRoute } from "../../../../store/actions";
import { DeleteNoteContent, generateHtmlTitle, getConfirmationModal, getRelativeTime, getTime, permissions } from "../../common/helperFunctions";
import { v4 as uuidv4 } from 'uuid'
import { getGlobalConnectionCache } from "../../../../store/modules/UserManagement/connection/connectionActions";
import { fetchFlowsLandingPageList, getFlowsDetails } from "../../../../store/modules/flows/landingPage/landingPageAction";

import Plot from 'react-plotly.js'
import DeleteModal from "../../../Common/deleteModal/DeleteModal";
import { ICON_ALERT, ICON_ARROW_RIGHT } from "../../../Common/newIconSource";
import LeftSidePanelToggler from "../../../Common/leftSidePanelToggler/LeftSidePanelToggler";
import { cloneDeep } from "lodash";

const alertPermissions = permissions.alerts

function CreateAlertIndex(props) {
  const childAddFilterRef = useRef();
  const editorRef = useRef();

  const { alertId } = useParams();
  const selectedAlertPodlist = useSelector(
    (state) => state.Alert.CreateAlert.selectedAlertPodList
  );
  const [conditionType, setconditionType] = useState("filter");
  const [newResultsTabArray, setNewResultsTabArray] = useState([]);
  const [logResultTabArray, setlogResultTabArray] = useState([]);

  const [title, setTitle] = useState("Alerts");
  const [activeKey, setActiveKey] = useState("");
  const [createAlertClick, setcreateAlertClick] = useState(false);
  const [activePODName, setactivePODName] = useState("");
  const [layout, setlayout] = useState({})
  const [logsRowSelectedIndex, setlogsRowSelectedIndex] = useState(0)
  const [execeptionId, setexeceptionId] = useState()

  const [logData, setLogData] = useState([]);
  const [isShowFilter, setisShowFilter] = useState(true)
  const [isDisableHideBtn, setisDisableHideBtn] = useState(false)
  let currentInterval = useRef();
  let currenExecutionId = useRef()
  const QueryTableResult = useSelector(
    (state) => state.Alert.CreateAlert.queryResult
  );
  const previewTableResult = useSelector(
    (state) => state.Alert.CreateAlert.previewTableResult
  );
  const appliedFilterCriteria = useSelector(
    (state) => state.Alert.CreateAlert.appliedFilters
  );
  const sqlQuery = useSelector(
    (state) => state.Alert.CreateAlert.alertSqlEditorQuery
  );


  const currentUUIDTAB = useRef({})
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  // const [openConditionWarnPopup, setOpenConditionWarnPopup] = useState(false);
  const [getTableData, setgetTableData] = useState(false);
  const [virtualTableData, setVirtualTableData] = useState({});
  const [tableTitle, settableTitle] = useState("Query Result");
  const [currentPodColumns, setcurrentPodColumns] = useState([]);
  const [retriveAlertData, setretriveAlertData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewTabsActiveKey, setviewTabsActiveKey] = useState("details");
  const [alertName, setalertName] = useState("Create Alert");
  const [executionLogs, setexecutionLogs] = useState([]);
  const [openExceptionModal, setOpenExceptionModal] = useState(false);
  const [openFailureModal, setOpenFailureModal] = useState(false);
  const [errorLogs, setErrorLogs] = useState();
  const [exceptionData, setExceptionData] = useState({});
  const [exceptionDataTimeStamp, setexceptionDataTimeStamp] = useState("");
  const [graphData, setgraphData] = useState([])
  const [showSkelaton, setshowSkelaton] = useState(false)
  const [showLogsRefresh, setshowLogsRefresh] = useState(false)
  const [alreadyAppliedFilters, setalreadyAppliedFilters] = useState([])

  const [expandTable, setexpandTable] = useState(false)
  const [isMultiSelection, setisMultiSelection] = useState(false)
  const flowsAlertTransformerList = useSelector(state => state.Alert.CreateAlert?.flowsAlertTransformerList)
  const [AlertTransformList, setAlertTransformList] = useState([])

  const [alertTransformerName, setalertTransformerName] = useState('')
  const [alertTransformerID, setalertTransformerID] = useState('')
  const [showLogs, setShowLogs] = useState(true)
  const [showPage, setshowPage] = useState(true)
  const [readOnlyEnable, setreadOnlyEnable] = useState(false)

  const [parentError, setparentError] = useState(undefined)
  const currentPodIdRef = React.useRef({ current: {} })
  const currentConditionTypeRef = React.useRef({ current: {} })
  const location = useLocation()

  const searchParams = new URLSearchParams(document.location.search)
  const history_id = searchParams.get("history_id");
  const history = useNavigate();
  let showResultTabActive = useRef(false)
  const [isPreviewDataLoading, setIsPreviewDataLoading] = useState(false);

  const checkIsEditor = () => {
    const isRoleEditor = retriveAlertData === undefined ? false : !!retriveAlertData?.permission?.find(v => v === alertPermissions.editor)
    if (isRoleEditor) return true
    return undefined
  }

  const isDeletePermission = retriveAlertData === undefined ? true : checkIsEditor() ?? !!retriveAlertData?.permission?.find(v => v === alertPermissions.delete)
  const isEditPermission = retriveAlertData === undefined ? true : checkIsEditor() ?? !!retriveAlertData?.permission?.find(v => v === alertPermissions.edit)

  let tableHeight;
  if (window.innerHeight >= 750 && window.innerHeight < 1000) {
    tableHeight = Math.round(window.innerHeight - 380);
  } else if (window.innerHeight >= 1000) {
    tableHeight = Math.round(window.innerHeight - 380);
  } else {
    tableHeight = Math.round(window.innerHeight / 2 - 80);
  }

  let uuid = useRef('')
  const [uuidState, setuuid] = useState("")

  useEffect(() => {
    store.dispatch(setSidebarActiveRoute('/alerts'))
    store.dispatch(getGlobalConnectionCache())
    return () => {

      store.dispatch(ResetCreateAlertRedux())
      store.dispatch(setSelectedPodList([]))
      store.dispatch(openModel(false))

      store.dispatch(resetAllDataGridTableFilters())
      store.dispatch(resetGraphSampleSelected())

    }
  }, [])

  useEffect(() => {
    if (retriveAlertData && retriveAlertData?.name) {
      let title = generateHtmlTitle(retriveAlertData.name.toUpperCase(), 'Alert')
      document.title = title
    }
  }, [retriveAlertData])

  const resetAllData = (conditionType) => {
    if (selectedAlertPodlist && selectedAlertPodlist[0]?.podId) {
      store.dispatch(
        setActivePodDetails({
          podName: selectedAlertPodlist[0].podName,
          dataDomain: selectedAlertPodlist[0].dataDomain,
          podId: selectedAlertPodlist[0].podId,
        })
      );
      setActiveKey(selectedAlertPodlist[0]?.podId);

    }

    setisDisableHideBtn(false)
    setisShowFilter(true)
    if (conditionType === 'sql') {
      setisMultiSelection(true)
    } else {
      setisMultiSelection(false)
    }
    if (conditionType === 'flow') {
      store.dispatch(setSelectedAlertPODList([]));
      store.dispatch(setAlertAppliedFilters([]));
      store.dispatch(setSelectedPodList([]))
      setAlertTransformList([])
      setNewResultsTabArray([])
      setVirtualTableData({})
    } else if (conditionType === 'sql' || conditionType === 'filter') {

      if ((appliedFilterCriteria && appliedFilterCriteria.length) || (sqlQuery !== '') || (alertTransformerName !== '')) {

        store.dispatch(resetAllDataGridTableFilters())
        store.dispatch(setSelectedAlertPODList([]));
        store.dispatch(setAlertAppliedFilters([]));
        store.dispatch(setSelectedPodList([]))

        setAlertTransformList([])
        setNewResultsTabArray([])
        setcurrentPodColumns([])
        setVirtualTableData({})
        innerContent()
      }
      store.dispatch(setAlertAppliedFilters([]));
      store.dispatch(setAlertSqlEditorQuery(''));
      localStorage.setItem("previewFlag", "Preview")
      store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: { flowId: null, alertList: [], selectedFlowDetails: null } })

    }
  }


  useEffect(() => {
    let execeptionId = location?.state?.exceptionid


    if (location?.hash !== '' && location?.hash?.includes('#logs')) {
      let execution_id = location?.hash?.split('=')[1];

      setexeceptionId(Number(execution_id))
      setlogsRowSelectedIndex(Number(execution_id))

    }
    if (location?.hash !== '' && location?.hash?.includes('#fromHomeLogs')) {
      let execution_id = location?.hash?.split('=')[1];

      // setexeceptionId(Number(execution_id))
      // setlogsRowSelectedIndex(Number(execution_id))
      onViewTabChange("logs")

    }
    if (execeptionId && location?.state?.from === 'myalert') {
      setTitle('Notifications')

      setexeceptionId(execeptionId)
      setlogsRowSelectedIndex(Number(execeptionId))

    } else if (!execeptionId && location?.state?.from === 'alert') {
      retriveAlertDetails()
      setTitle('Alerts')
    } else if (!execeptionId && !location?.state?.from) {
      retriveAlertDetails()
      setTitle('Alerts')
    }
  }, [alertId, location?.state?.exceptionid]);

  useEffect(() => {
    if (flowsAlertTransformerList?.alertList?.length) {

      if (viewTabsActiveKey === 'details') {
        store.dispatch(resetAllDataGridTableFilters())
        setVirtualTableData({})
      }
      let PriorityList = [];
      flowsAlertTransformerList.alertList.forEach((ele, index) => {
        PriorityList.push({ key: `${ele}-${index}`, title: ele.transformerName, id: ele.currentTransformerID, updated_on: flowsAlertTransformerList?.selectedFlowDetails?.updated_on })
      })
      setAlertTransformList([...PriorityList])
    } else {
      setAlertTransformList([])
    }
  }, [flowsAlertTransformerList])

  const onExecuteTransformerClick = (alertName = '', flowId, playbtnClick = false) => {
    getUUID()
    let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
    clearInterval(currentInterval.current)
    if ((alertTransformerName || alertName) && flowId) {
      setNewResultsTabArray([{
        podId: "0",
        key: '0',
        tabTitle: `Result`,
        podName: "",
        databaseName: "",
        tabContent: null,
        closable: false,
      }])
      setActiveKey('0');
      if (GlobalConnectionCache && GlobalConnectionCache !== '') {

        let payload = {

          connection_name: GlobalConnectionCache ? GlobalConnectionCache : '',
          table_ids: [],
          uuid: uuid.current,
          table_name: alertName ? alertName : alertTransformerName,
          schema_name: `flow_${flowId}`,
          page: 0,
          do_count: false,
          sort_body: [],
          filter_body: [],

        };

        store.dispatch(setActivePodDetails({ connectionName: GlobalConnectionCache ? GlobalConnectionCache : '', podName: payload?.table_name, dataDomain: payload?.schema_name }))


        setshowSkelaton(true)
        if (!playbtnClick && (window.location.href?.includes('viewAlert') || window.location.href?.includes('editAlert'))) {
          store.dispatch(setActivePodDetails({ connectionName: GlobalConnectionCache ? GlobalConnectionCache : '', podName: payload?.table_name, dataDomain: payload?.schema_name }))
          try {
            store
              .dispatch(getPreviewTable(payload))
              .then((response) => {
                setshowSkelaton(false)
                if (response?.data?.result) {
                  setparentError(undefined)

                  clearInterval(currentInterval.current)
                  // if (uuid.current === response?.data?.uuid) {
                  store.dispatch(setAlertQueryResult(response?.data?.result));
                  store.dispatch(setAlertSqlResponseQuery(response?.data?.query));
                  // }
                  store.dispatch(setActivePodDetails({ connectionName: GlobalConnectionCache ? GlobalConnectionCache : '', podName: payload?.table_name, dataDomain: payload?.schema_name }))




                }
                // else {
                //   setVirtualTableData({})

                //   store.dispatch(setAlertQueryResult({}));

                // }
              })
              .catch((e) => {
                clearInterval(currentInterval.current)

                setshowSkelaton(false)
                setVirtualTableData({})
                emitToastNotification('info', Literals.PLEASE_EXECUTE_ALERT_TRANSFORMER_FOR_RESULT)
                store.dispatch(setAlertSqlResponseQuery(''));
                setparentError(e.message)
                store.dispatch(setAlertQueryResult({}));
              });
          } catch (error) {
            clearInterval(currentInterval.current)
            setshowSkelaton(false)

          }
        } else {

          getAlertTransformerPreview(payload, flowId)
        }

        // getAlertTransformerPreview(payload, flowId)
      } else {
        emitToastNotification('error', `Global cache connection does not exist, please select one connection as a cache <br></br><a class="text-underline"
          href="/connections"
          target="_blank"> Go to connections </a>`, { timeOut: 0 })
        return;
      }

    } else {
      setVirtualTableData({})
      emitToastNotification('info', Literals.PLEASE_SELECT_ALERT_TRANSFORMER)
    }
  }
  const onSelectAlertTransformer = (alertName, alertId) => {
    if (window.location.href.includes("viewAlert")) return
    setalertTransformerID(alertId)
    setalertTransformerName(alertName);

    onExecuteTransformerClick(alertName, flowsAlertTransformerList?.flowId, false)

    store.dispatch(resetAllDataGridTableFilters())
    setVirtualTableData({})

  }



  const getAlertTransformerPreview = (payload, flowId = '') => {

    if (payload) {

      store.dispatch(executeEntireFlow(flowId !== '' ? flowId : flowsAlertTransformerList?.flowId, {})).then((resp) => {
        if (resp && resp.status === 'success' && resp.data) {
          emitToastNotification('info', Literals.PLEASE_WAIT_FLOWS_CURRENTLY_RUNNING)

          currenExecutionId.current = resp.data

          currentInterval.current = setInterval(() => {
            let apiPayload = {
              page: '',
              limit: '',
              execution_id: currenExecutionId.current,
              flow_Id: flowId
            }
            let currentState = ''
            // if (currentState.toLowerCase() !== 'done' && currentState.toLowerCase() !== 'success' && currentState.toLowerCase() !== 'error' && currentState.toLowerCase() !== 'failed') {
            store.dispatch(RetriveExecutionFlows(apiPayload)).then((res) => {
              if (res?.data?.length) {
                let data = res.data.map((ele) => {
                  ele.state = ele.state ? ele.state?.toLowerCase() : ''
                  return ele
                })

              }
              let responseData = res.data

              // let flowsStatus = responseData.find((ele) => ele.transformer === "__all__");
              let flowsStatus = responseData.find((ele) => ele.transformer === payload?.table_name);

              if (flowsStatus && flowsStatus?.state) {

                currentState = flowsStatus?.state
                if (currentState.toLowerCase() === 'done' || currentState.toLowerCase() === 'success') {
                  let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
                  if (GlobalConnectionCache && GlobalConnectionCache !== '') {

                    store.dispatch(setActivePodDetails({ podName: payload?.table_name, dataDomain: payload?.schema_name, connectionName: GlobalConnectionCache ? GlobalConnectionCache : '' }))
                    try {
                      store
                        .dispatch(getPreviewTable(payload))
                        .then((response) => {
                          setshowSkelaton(false)
                          if (response?.data?.result) {

                            clearInterval(currentInterval.current)
                            // if (uuid.current === responseData.uuid) {
                            store.dispatch(setAlertQueryResult(response?.data?.result));
                            store.dispatch(setAlertSqlResponseQuery(response?.data?.query));
                            // }


                          }
                          // else {
                          //   setVirtualTableData({})

                          //   store.dispatch(setAlertQueryResult({}));

                          // }
                        })
                        .catch((e) => {
                          clearInterval(currentInterval.current)

                          setshowSkelaton(false)
                          setVirtualTableData({})
                          store.dispatch(setAlertSqlResponseQuery(''));

                          store.dispatch(setAlertQueryResult({}));
                        });
                    } catch (error) {
                      clearInterval(currentInterval.current)
                      setshowSkelaton(false)

                    }
                  } else {
                    emitToastNotification('error', `Global cache connection does not exist, please select one connection as a cache <br></br><a class="text-underline"
                    href="/connections"
                    target="_blank"> Go to connections </a>`, { timeOut: 0 })
                    return;
                  }

                } else if (currentState.toLowerCase() === 'error' || currentState.toLowerCase() === 'failed') {
                  setshowSkelaton(false)
                  clearInterval(currentInterval.current)
                }
              }

            }).catch((e) => {
              setshowSkelaton(false)
              clearInterval(currentInterval.current)
            })

          }, 3000);





        }
      }).catch((e) => {
        setshowSkelaton(false)
        clearInterval(currentInterval.current)
      }
      )
    }
  }
  useEffect(() => {


    if (execeptionId) {
      retriveAlertDetails();

      setviewTabsActiveKey('logs')
      setVirtualTableData({})
      setActiveKey('logs')
      setlogResultTabArray([{
        podId: "logs",
        key: "logs",
        tabTitle: `Logs`,
        podName: "",
        databaseName: "",
        tabContent: null,
        closable: false,
      }])
      setshowSkelaton(true)
      store.dispatch(getExcecutionRetrive(alertId)).then((resp) => {
        if (resp && resp.status === "success" && resp.data?.length) {
          let sortDateWise = resp.data.sort((a, b) => new Date(a.start_date_time).getTime() - new Date(b.start_date_time).getTime())
          setexecutionLogs([...sortDateWise]);

          generateGraphData([...sortDateWise])
          getUUID()

          let findIndex = resp.data.findIndex((ele) => ele.id === execeptionId)

          if (findIndex && findIndex > -1 && resp?.data[findIndex].state.toLowerCase() === "success") {
            setlogsRowSelectedIndex(execeptionId)


            getPreviewTableResult([], true, resp?.data[findIndex]);
          }

        }

      });
    }
  }, [execeptionId])
  useEffect(() => {
    setconditionType("filter");

    return () => {

      clearInterval(currentInterval.current)
    }
  }, [])
  const getQueryResult = (payload) => {
    setreadOnlyEnable(true)
    setshowSkelaton(true)

    try {
      store
        .dispatch(getPreviewTable(payload, false))
        .then((resp) => {
          if (resp && resp.status === "success" && resp.data) {
            setreadOnlyEnable(false)
            setshowSkelaton(false)



            showResultTabActive.current = false

            if (uuid.current === resp.data.uuid) {

              setparentError(undefined)

              let responseData = resp.data.result;

              store.dispatch(setAlertSqlResponseQuery(resp.data.query));
              store.dispatch(setAlertQueryResult(responseData));

              store.dispatch(
                setPreviewTableQuery(
                  resp.data.query
                )
              );
              setIsPreviewDataLoading(true)

              store.dispatch(setActiveTabKey('Result'))
              localStorage.setItem("previewFlag", "Query");
              store.dispatch(setPreviewFlag("Query"))
              store.dispatch(resetVirtualTableRedux())
              store.dispatch(setResponseQuery(resp.data.query))
            } else {
              setIsPreviewDataLoading(true)
              store.dispatch(setAlertQueryResult([]));

            }

          } else {
            setIsPreviewDataLoading(false)
            setreadOnlyEnable(false)
            setshowSkelaton(false)

          }
        })
        .catch((err) => {
          setparentError(err?.message)
          setshowSkelaton(false)

          setIsPreviewDataLoading(false)
          setreadOnlyEnable(false)

        });
    } catch (error) {
      setIsPreviewDataLoading(false)
      setreadOnlyEnable(false)
      setshowSkelaton(false)


    }
  };

  const getUUID = () => {

    let id = uuidv4();

    uuid.current = id
    setuuid(id)

    return id;

  }
  const conditionTypeRef = useRef(null);
  const retriveAlertDetails = () => {

    if (
      alertId &&
      (window.location.href.includes("editAlert") ||
        window.location.href.includes("viewAlert"))
    ) {
      setshowPage(false)
      store.dispatch(getGlobalConnectionCache());
      store.dispatch(RetrieveAlert(alertId)).then((resp) => {
        if (resp && resp.data) {
          let responseData = resp.data;
          setretriveAlertData(responseData);

          if (responseData.type === 'sql') {
            setisMultiSelection(true)
          } else {
            setisMultiSelection(false)
          }

          if (responseData.type === "sql") {
            setconditionType("sql");
            conditionTypeRef.current = 'sql'
          } else if (responseData.type === "filter") {
            setconditionType("filter");

            conditionTypeRef.current = 'filter'

          } else if (responseData.type === "flow") {
            setconditionType("flow");
            conditionTypeRef.current = 'flow'

          }

          showResultTabActive.current = true;

          setalertName(responseData.name);
          if (responseData && responseData.filter_body) {


            setOpenDeletePopup(false)



            store.dispatch(
              setAlertPreviewTables(responseData.filter_body?.previewAlertTables)
            );
            store.dispatch(
              setAlertAppliedFilters(responseData.filter_body?.appliedFilters)
            );
            store.dispatch(
              setAlertSqlEditorQuery(
                responseData.filter_body?.alertSqlEditorQuery
              )
            );

            store.dispatch(
              setPreviewTableQuery(
                responseData.filter_body?.alertSqlEditorQuery
              )
            );

            store.dispatch(
              setSelectedAlertPODList(
                responseData.filter_body?.selectedAlertPodList
              )
            );

            store.dispatch(
              setSelectedPodList(
                responseData.filter_body?.selectedAlertPodList
              )
            );
            if (responseData.alert_transformer !== null && responseData?.filter_body?.selectedFlowDetails) {
              store.dispatch(getFlowsDetails(responseData?.filter_body?.selectedFlowDetails?.flowId)).then((resp) => {
                if (resp?.data && resp?.data?.id) {
                  let selectedFlow = resp?.data //resp.data.find((ele) => ele.id === responseData?.filter_body?.selectedFlowDetails?.flowId)
                  if (selectedFlow?.name) {
                    responseData.filter_body.selectedFlowDetails['selectedFlowDetails'] = selectedFlow
                    store.dispatch(getFlowsAlertTransformerList(responseData?.filter_body?.selectedFlowDetails?.flowId, responseData?.filter_body?.selectedFlowDetails)).then((resp) => {
                      if (resp?.data && resp?.data?.length) {
                        let transformer = resp.data.find((ele) => ele.currentTransformerID === responseData.alert_transformer)
                        if (transformer && transformer.transformerName) {
                          responseData.filter_body.selectedFlowDetails['alertList'] = resp.data

                          setalertTransformerName(transformer.transformerName);
                          setalertTransformerID(responseData.alert_transformer)
                          onExecuteTransformerClick(transformer.transformerName, responseData?.filter_body?.selectedFlowDetails?.flowId, false)

                          store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: responseData.filter_body?.selectedFlowDetails })
                        } else {
                          responseData.filter_body.selectedFlowDetails['alertList'] = resp.data

                          emitToastNotification('warning', Literals.SELECTED_ALERT_DOES_NOT_EXIST_NOW)
                          store.dispatch(
                            setSelectedAlertPODList(
                              responseData.filter_body?.selectedAlertPodList
                            )
                          );
                          store.dispatch(
                            setSelectedPodList(
                              responseData.filter_body?.selectedAlertPodList
                            )
                          );
                          store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: responseData.filter_body?.selectedFlowDetails })

                        }
                      }
                    })
                  } else {
                    emitToastNotification('warning', Literals.SELECTED_FLOW_DOES_NOT_EXIST_NOW)
                  }
                }
              })

            }
            // let queryResult = responseData?.filter_body.previewAlertTables.find((ele) => Object.keys(ele).includes('Result'))
            // store.dispatch(setAlertQueryResult(queryResult));
            getUUID()


            store.dispatch(setAlertSqlResponseQuery(responseData.query));
            ///////////////////////////// 1st time show result tab active in view and edit mode


            store.dispatch(resetVirtualTableRedux())

            let query = responseData.filter_body?.alertSqlEditorQuery
            let appliedFilters = responseData.filter_body?.appliedFilters
            let conditionType = responseData.type
            let selectedAlertPodList = responseData.filter_body?.selectedAlertPodList

            if (conditionType === 'sql') {
              setisMultiSelection(true)
            } else {
              setisMultiSelection(false)
            }

            if (query !== '' && conditionType === 'sql') {

              localStorage.setItem("previewFlag", "Query");
              store.dispatch(setPreviewFlag("Query"))

              let Analysispayload = {
                uuid: uuid.current,
                table_name: '',
                page: 0,
                do_count: false,
                sort_body: [],
                filter_body: [],
                table_ids: [],
                query: query,
              }
              getQueryResult(Analysispayload)

            } else if (appliedFilters && appliedFilters.length && conditionType === 'filter') {
              localStorage.setItem("previewFlag", "Preview");
              store.dispatch(setPreviewFlag("Preview"))
              store.dispatch(
                setActivePodDetails({
                  podName: selectedAlertPodList[0].podName,
                  dataDomain: selectedAlertPodList[0].dataDomain,
                  podId: selectedAlertPodList[0].podId
                })
              );
              setTimeout(() => {
                getPreviewTableResult(appliedFilters, false, {})
                showResultTabActive.current = false

              }, 500);
            }

          }


          setshowPage(true)
        }

      }).catch((e) => {
        setshowPage(true)
      })
    }
  };

  const { previewTableData } = useVirtualTableAction(getTableData, uuid.current, setparentError);
  let columns = [];
  let dataSource = [];
  const store = useStore();

  useEffect(() => {

    store.dispatch(setAlertPreviewTableResult(previewTableData));
    if (activeKey === "0" && conditionType === 'sql') {
      localStorage.setItem("previewFlag", "Query");
      store.dispatch(setPreviewFlag("Query"))
      // store.dispatch(setActiveTabKey("Result"))
    }
    else {
      localStorage.setItem("previewFlag", "Preview")
      store.dispatch(setPreviewFlag("Preview"))
      // store.dispatch(setActiveTabKey(activeKey))
    }
    // alert(`previewcall ${showResultTabActive.current}`)
    if (showResultTabActive.current === true) {
      showResultTabActive.current = false
    }
    setIsPreviewDataLoading(true)
    setshowSkelaton(false)
    setgetTableData(false);

  }, [previewTableData]);


  const createRule = () => {
    setcreateAlertClick(!createAlertClick);
  };
  const TabContents = (key) => {
    switch (key) {
      case "details":
        return (
          <AlertDetails
            isDisabled={true}
            conditionType={conditionType}
            setconditionType={(value) => { setconditionType(value); conditionTypeRef.current = value }}
            isRulesPublish={createAlertClick}
            retriveAlert={retriveAlertData}
            retriveAlertDetails={retriveAlertDetails}
          />
        );
      case "logs":
        return (
          <AlertLogs logs={executionLogs} onLogsRowClick={onLogsRowClick} logsRowSelectedIndex={logsRowSelectedIndex} />
        );

      case "timeline":
        return (
          <CustomTimeline type={"ALERT"} id={alertId} showCard={false} />
        )

      default:
        break;
    }
  };

  let viewAlertDetailsTabArray = [
    {
      key: "details",
      tabTitle: (
        <Tooltip title='Details' placement="topLeft">
          <div className="d-flex align-items-center">
            <span className="tabs-icon margin-right-2">
              <PROPERTIES />
            </span>
            {
              viewTabsActiveKey === 'details' || history_id
                ? <span className="tabs-title">{"Details"}</span>
                : ''
            }
          </div>
        </Tooltip>
      ),
      tabContent: TabContents,
      closable: false,
    },
    {
      key: "logs",
      tabTitle: (

        <div className="d-flex align-items-center">
          <Tooltip title='Logs' placement="topLeft"><div className="d-flex align-items-center">
            <span className="tabs-icon margin-right-2">
              <USAGETIME />
            </span>
            {
              viewTabsActiveKey === 'logs' || history_id
                ? <span className="tabs-title pr-2">{"Logs"} </span>
                : ''
            }
          </div></Tooltip>

          {viewTabsActiveKey === 'logs' ? <span className={`${showLogsRefresh ? 'bx-spin' : ''} d-flex`} onClick={() => { refreshLogsStatus() }}> <Tooltip title="Refresh Logs"><REFRESHLOGS width="25" height="25" color="#2C83C4" /> </Tooltip>  </span> : null}
        </div>

      ),
      tabContent: TabContents,
      closable: false,
    },
    // {
    //   key: "timeline",
    //   tabTitle: (
    //     <Tooltip title='Timeline' placement="topLeft">
    //       <div className="d-flex align-items-center">
    //         <span className="tabs-icon margin-right-2">
    //           <TIMELINE />
    //         </span>
    //         {
    //           viewTabsActiveKey === 'timeline'
    //             ? "Timeline"
    //             : ''
    //         }
    //       </div>
    //     </Tooltip>

    //   ),
    //   tabContent: TabContents,
    //   closable: false
    // }
  ];

  useEffect(() => {

    if (selectedAlertPodlist && selectedAlertPodlist.length === 1) {

      if (conditionType !== "filter" && conditionType !== "sql") {
        openWarningDialog(
          "filter"
        )
        // setconditionType("filter");
      }


      const resultTabs = [
        {
          podId: "0",
          key: "0",
          tabTitle: "Result",
          podName: "Result",
          databaseName: "resultTab",
          tabContent: null,
          closable: false,
        },
      ];

      selectedAlertPodlist.forEach((pod) => {
        resultTabs.push({
          ...pod,
          key: pod.podId,
          tabTitle: pod.podName?.toUpperCase(),
          tabContent: null,
          closable: false,
        });
      });
      setNewResultsTabArray(resultTabs);

      if (showResultTabActive.current === false) {
        store.dispatch(
          setActivePodDetails({
            podName: selectedAlertPodlist[0].podName,
            dataDomain: selectedAlertPodlist[0].dataDomain,
            podId: selectedAlertPodlist[0].podId
          })
        );

        setActiveKey(selectedAlertPodlist[0].podId);

        setactivePODName(selectedAlertPodlist[0].podName);
        settableTitle(selectedAlertPodlist[0].podName);


        setNewResultsTabArray(resultTabs);
        setIsPreviewDataLoading(true)
        if (!window.location.href.includes('viewAlert')) {

          getUUID();
        }

        setshowSkelaton(true)

        setgetTableData(true);
      } else if (showResultTabActive.current === true) {
        setActiveKey('0')
        setshowSkelaton(true)

      }

    } else if (selectedAlertPodlist && selectedAlertPodlist.length > 1) {

      if ((conditionTypeRef.current !== null && conditionTypeRef.current !== 'sql') || (window.location.href.includes('createAlert') && conditionTypeRef.current === null && conditionType !== 'sql')) {

        openWarningDialog(
          "sql"
        )
        //  setconditionType("sql")
      }
      settableTitle(selectedAlertPodlist[0].podName);

      const resultTabs = [
        {
          podId: "0",
          key: "0",
          tabTitle: "Result",
          podName: "Result",
          databaseName: "resultTab",
          tabContent: null,
          closable: false,
        },
      ];

      selectedAlertPodlist.forEach((pod) => {
        resultTabs.push({
          ...pod,
          key: pod.podId,
          tabTitle: pod.podName?.toUpperCase(),
          tabContent: null,
          closable: false,
        });
      });

      setNewResultsTabArray(resultTabs);

      if (showResultTabActive.current === false) {
        getUUID()

        setActiveKey(selectedAlertPodlist[0].podId);

        setactivePODName(selectedAlertPodlist[0].podName);
        store.dispatch(
          setActivePodDetails({
            podName: selectedAlertPodlist[0].podName,
            dataDomain: selectedAlertPodlist[0].dataDomain,
            podId: selectedAlertPodlist[0].podId

          })
        );
        setNewResultsTabArray(resultTabs);
        setIsPreviewDataLoading(true)
        setshowSkelaton(true)
        setgetTableData(true);
      } else if (showResultTabActive.current === true) {
        setActiveKey('0')
        setshowSkelaton(true)
      }
    } else if (selectedAlertPodlist && !selectedAlertPodlist.length) {
      store.dispatch(setAlertAppliedFilters([]));

      store.dispatch(setAlertSqlEditorQuery(''));
      store.dispatch(setPreviewTableQuery(''));
      setalreadyAppliedFilters([{
        uid: new Date().getTime(),
        column: "",
        criteria: "",
        value: "",
        datatype: "",
        operation: 'AND',
      }])
    }

    setisDisableHideBtn(false)
    setisShowFilter(true)
    // if (
    //   selectedAlertPodlist &&
    //   selectedAlertPodlist.length <= 0 &&
    //   window.location.href.includes("createAlert")
    // ) {
    //   history.push("/alerts");
    // }
  }, [selectedAlertPodlist]);

  useEffect(() => {
    if (previewTableResult && previewTableResult?.data_header?.length > 0) {

      createTable(
        previewTableResult,
        previewTableResult?.data_header,
        previewTableResult.data_record,
        previewTableResult?.data_dtype,
        false
      );
    }
  }, [previewTableResult]);

  useEffect(() => {
    if (QueryTableResult && QueryTableResult?.data_header?.length > 0) {


      setActiveKey("0");
      settableTitle("Query Result");
      if (conditionType === 'sql') {
        setisDisableHideBtn(true)
        setisShowFilter(true)
      }

      createTable(
        QueryTableResult,
        QueryTableResult?.data_header,
        QueryTableResult.data_record,
        QueryTableResult?.data_dtype,
        true
      );
    }
  }, [QueryTableResult]);

  const createTableColumns = (header, dataTypes) => {
    let tableColumns = [];
    header.forEach((ele, i) => {
      tableColumns.push({
        Name: ele,
        Type: dataTypes.length > 0 ? dataTypes[i] : "",
        Label: ele,
        columnValue: `'${ele}'`,
      });
    });
    return tableColumns;
  };

  const createTable = (
    completeData,
    header,
    records,
    datatype,
    isQueryResult
  ) => {
    header.forEach((ele) => {
      columns.push({
        title: ele,
        dataIndex: ele,
        key: ele,
      });
    });
    dataSource = [...records];
    if (activeKey === '0' && conditionType === 'sql' && !isQueryResult) {
      return;
    }
    //  else {

    setVirtualTableData(completeData);
    // }

    let tableColumns = [];
    if (datatype && datatype.length) {
      tableColumns = createTableColumns(header, datatype);
    } else {
      tableColumns = createTableColumns(header, []);
    }
    setcurrentPodColumns([...tableColumns]);
    let temp = store.getState().Alert.CreateAlert.previewAlertTables;
    if (!isQueryResult) {
      if (temp && temp.length && activePODName) {
        let index = temp.findIndex(
          (ele) => Object.keys(ele)[0] === activePODName
        );
        if (index > -1) {
          let object = {};

          object[activePODName] = { podColumn: tableColumns };
          temp[index] = object;
        } else {
          let object = {};

          object[activePODName] = { podColumn: tableColumns };
          temp.push(object);
        }
      } else {
        let object = {};
        object[activePODName] = { podColumn: tableColumns };
        temp.push(object);
      }
    }

    if (viewTabsActiveKey === 'logs') {
      settableTitle('')
      setActiveKey('logs')
    }
    if (isQueryResult && viewTabsActiveKey !== 'logs') {
      let index = temp.findIndex((ele) => Object.keys(ele)[0] === "Result");
      if (index > -1) {
        let object = {};
        // object['Result'] = { columns: [...columns], dataSource: [...dataSource], dataTypes: [...tableColumns] }
        object["Result"] = { podColumn: tableColumns };
        temp[index] = object;
      } else {
        let object = {};
        // object['Result'] = { columns: [...columns], dataSource: [...dataSource], dataTypes: [...tableColumns] }
        object["Result"] = { podColumn: tableColumns };
        temp.push(object);
      }
    }
    if (viewTabsActiveKey !== 'logs') {
      store.dispatch(setAlertPreviewTables([...temp]));
      setalreadyAppliedFilters([...appliedFilterCriteria])
    }
  };

  const onChange = (activeKey) => {

    setIsPreviewDataLoading(true)
    setActiveKey(activeKey);
    setVirtualTableData({});
    store.dispatch(setQueryKillFlag(false))

    if (conditionType === 'sql') {
      setisMultiSelection(true)
    } else {
      setisMultiSelection(false)
    }

    let previewTables = store.getState().Alert.CreateAlert.previewAlertTables;
    let pod = {};

    if (activeKey !== "0") {
      getUUID()
      setgetTableData(false);
      pod = selectedAlertPodlist.find((ele) => ele.podId === Number(activeKey));


      if (pod && pod.dataDomain && pod.podName) {
        setactivePODName(pod.podName);
        store.dispatch(
          setActivePodDetails({
            podName: pod.podName,
            dataDomain: pod.dataDomain,
            podId: pod.podId
          })
        );
        setshowSkelaton(true)
        setIsPreviewDataLoading(true)
        setgetTableData(true);
      } else {
        setshowSkelaton(false)
        setIsPreviewDataLoading(false)

      }



      if (conditionType !== 'flow') {
        setisDisableHideBtn(true)
        setisShowFilter(true)
      }
    } else {
      setisDisableHideBtn(false)
      setisShowFilter(false)
      store.dispatch(resetVirtualTableRedux())
      let query = store.getState().Alert.CreateAlert.alertSqlEditorQuery
      let appliedFilters = store.getState().Alert.CreateAlert.appliedFilters

      if (query !== '' && conditionType === 'sql') {
        getUUID()

        localStorage.setItem("previewFlag", "Query");
        store.dispatch(setPreviewFlag("Query"))
        editorRef.current.executeQuery(query);
      } else if (appliedFilters && appliedFilters.length && conditionType === 'filter') {
        getUUID()

        localStorage.setItem("previewFlag", "Preview");
        store.dispatch(setPreviewFlag("Preview"))
        store.dispatch(
          setActivePodDetails({
            podName: selectedAlertPodlist[0].podName,
            dataDomain: selectedAlertPodlist[0].dataDomain,
            podId: selectedAlertPodlist[0].podId
          })
        );
        // setTimeout(() => {
        getPreviewTableResult(appliedFilters, false, {})
        // }, 100);
      }
    }
    if (previewTables && previewTables.length && pod) {
      let table = previewTables.find(
        (ele) => Object.keys(ele)[0] === pod?.podName
      );


      if (table) {
        let tableInfo = table[Object.keys(table)[0]];

        if (tableInfo && tableInfo.podColumn && tableInfo.podColumn.length) {
          setcurrentPodColumns([...tableInfo.podColumn]);
        }

      }

    }

    if (activeKey === "0" && conditionType === 'sql') {
      localStorage.setItem("previewFlag", "Query");
      store.dispatch(setPreviewFlag("Query"))
      setisDisableHideBtn(true)

      // store.dispatch(resetVirtualTableRedux())
    }
    else {
      localStorage.setItem("previewFlag", "Preview")
      store.dispatch(setPreviewFlag("Preview"))
      setisDisableHideBtn(false)

      // store.dispatch(setActiveTabKey(activeKey))
    }
  };

  const setAppliedFilters = (filterCriteriaGroup) => {
    getUUID()
    let previewTables = store.getState().Alert.CreateAlert.previewAlertTables;
    store.dispatch(setAlertAppliedFilters(filterCriteriaGroup));
    store.dispatch(
      setActivePodDetails({
        podName: selectedAlertPodlist[0].podName,
        dataDomain: selectedAlertPodlist[0].dataDomain,
        podId: selectedAlertPodlist[0].podId
      })
    );
    if (filterCriteriaGroup && filterCriteriaGroup.length) {
      if (previewTables && previewTables.length && activePODName !== "") {
        let table = previewTables.find(
          (ele) => Object.keys(ele)[0] === activePODName
        );
        let tableIndex = previewTables.findIndex(
          (ele) => Object.keys(ele)[0] === activePODName
        );

        if (table) {
          let tableInfo = table[Object.keys(table)[0]];

          if (tableInfo && tableInfo.appliedFilterCriteria?.length) {
          } else if (tableInfo && tableIndex > -1) {
            tableInfo["appliedFilterCriteria"] = filterCriteriaGroup;
            previewTables[tableIndex] = tableInfo;
          } else {
            let obj = {};
            obj[activePODName] = {
              appliedFilterCriteria: filterCriteriaGroup,
            };
            previewTables.push(obj);
          }
          store.dispatch(setAlertPreviewTables(previewTables));
        }
      }
      getPreviewTableResult(filterCriteriaGroup, false, {});
    }
  };

  const getPreviewTableResult = (
    appliedFilterCriteria = [],
    isExceptionPayload = false,
    record = {},

  ) => {
    setshowSkelaton(true)
    getUUID()
    // console.log(uuid.current)
    let podName =
      store.getState().CommonComponent.VirtualDataTable?.activePodDetails
        ?.podName;
    let dataDomain =
      store.getState().CommonComponent.VirtualDataTable?.activePodDetails
        ?.dataDomain;
    let podId = store.getState().CommonComponent.VirtualDataTable?.activePodDetails?.podId
    let connectionName = store.getState().CommonComponent.VirtualDataTable?.activePodDetails?.connectionName
    let payload = {};
    if (isExceptionPayload && isExceptionPayload === true) {

      let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
      if (GlobalConnectionCache && GlobalConnectionCache !== '') {
        store.dispatch(setActivePodDetails({ connectionName: GlobalConnectionCache ? GlobalConnectionCache : '', podName: `rule_name_${alertId}_${record.id}`, dataDomain: `rule_${alertId}`, podId: '' }))
        payload = {
          connection_name: GlobalConnectionCache ? GlobalConnectionCache : '',
          table_ids: [], //podId ? [podId.toString()] : [],
          uuid: uuid.current, //?? getUUID(),
          table_name: `rule_name_${alertId}_${record.id}`,
          schema_name: `rule_${alertId}`,
          page: 0,
          do_count: false,
          sort_body: [],
          filter_body: appliedFilterCriteria,


        };
      } else {
        emitToastNotification('error', `Global cache connection does not exist, please select one connection as a cache <br></br><a class="text-underline"
          href="/connections"
          target="_blank"> Go to connections </a>`, { timeOut: 0 })
        return;
      }
    } else {
      payload = {
        connection_name: connectionName ? connectionName : 'hive',
        table_ids: podId ? [podId.toString()] : [],
        uuid: uuid.current,// ?? getUUID(),
        table_name: podName,
        schema_name: dataDomain,
        page: 0,
        do_count: false,
        sort_body: [],
        filter_body: appliedFilterCriteria,

      }
      // payload = {
      //   action: "preview_query",
      //   table_name: podName,
      //   data_domain_name: dataDomain,
      //   sort: [],
      //   filter: appliedFilterCriteria,
      //   page: 0,
      // };
    }
    setIsPreviewDataLoading(true)
    try {
      store
        .dispatch(getPreviewTable(payload, false))
        .then((response) => {
          setshowSkelaton(false)
          if (response?.status === "success" && response?.data?.result) {

            setparentError(undefined)

            if (isExceptionPayload && isExceptionPayload === true) {

              let epocTime = new Date(record.start_date_time).toLocaleString();
              // setexceptionDataTimeStamp(epocTime);
              // setExceptionData(response.data);
              // setOpenExceptionModal(true);

              let responseData = response?.data?.result;


              setlogResultTabArray([{
                podId: "logs",
                key: "logs",
                tabTitle: `Logs:${epocTime}`,
                podName: "",
                databaseName: "",
                tabContent: null,
                closable: false,
              }])
              setActiveKey("logs");
              // if (uuid.current === responseData.uuid) {

              store.dispatch(setAlertQueryResult(responseData));
              store.dispatch(setAlertSqlResponseQuery(response?.data?.query));
              // }


            } else {
              let responseData = response?.data?.result;

              setActiveKey("0");
              // store.dispatch(setActiveTabKey('Result'))
              if (conditionType === 'sql') {
                localStorage.setItem("previewFlag", "Query");
                store.dispatch(setPreviewFlag("Query"))
                setisDisableHideBtn(true)
                setisShowFilter(true)

                store.dispatch(resetVirtualTableRedux())

              } else {
                setisDisableHideBtn(true)
                setisShowFilter(false)
              }
              // if (uuid.current === responseData.uuid) {
              store.dispatch(setAlertQueryResult(responseData));
              store.dispatch(setAlertSqlResponseQuery(response?.data?.query));
              store.dispatch(setResponseQuery(response?.data?.query))
              // }
            }
          } else {
            if (!isExceptionPayload || isExceptionPayload === false) {
              store.dispatch(setAlertQueryResult({}));
            }
          }
        })
        .catch((e) => {
          setshowSkelaton(false)
          setparentError(e.message)

          store.dispatch(setAlertQueryResult({}));
        });
    } catch (error) {
      store.dispatch(setAlertQueryResult({}));
      setparentError(error.message)

      setshowSkelaton(false)
    }
  };
  const toggleModalHandler = () => {
    if (conditionType === 'filter' && appliedFilterCriteria?.length) {
      openWarningDialog('filter', 'Once you change the Pod, current applied filters will be reset. Do you want to proceed?')
    } else {

      setIsModalOpen(!isModalOpen);
    }
  };

  const onProceedHandler = (selectedPODsList) => {
    store.dispatch(setSelectedAlertPODList(selectedPODsList));
    store.dispatch(setSelectedPodList(selectedPODsList));

    toggleModalHandler();
  };

  const onEdit = (targetKey, action) => {
    if (action === "remove") {
      remove(targetKey);
    }
  };

  const remove = (targetKey) => {
    removePods(Number(targetKey));
  };
  const removePods = (id) => {
    //Add here
    let list = [...selectedAlertPodlist];

    let index = list.findIndex((ele) => ele.podId === id);
    if (index > -1) {
      list.splice(index, 1);
    }

    store.dispatch(setSelectedAlertPODList([...list]));
    store.dispatch(setSelectedPodList([...list]));
  };

  const generateGraphData = (data) => {
    let graph = []

    let x = []
    let y = []
    if (data && data.length) {
      data.forEach((ele, i) => {
        let date = new Date(ele.start_date_time).toLocaleString()
        if (ele.state?.toLowerCase() === 'error') {
          x.push(date.toString())
          y.push(0)
        } else {
          x.push(date.toString())
          y.push(ele.exception_count)
        }

      })
      graph = [{
        x: x,
        y: y,
        type: 'line',
        mode: 'lines+markers',
        hovertemplate:
          '<br><b>Count</b>: %{y}<br>' +
          "<extra></extra>",

      }]




      setgraphData([...graph])
      let layout = {
        showLegend: false,
        xaxis: {
          title: 'Execution Start Date',
          tickmode: "array", // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
          tickvals: [graph[0]?.x[0], graph[0]?.x[x.length - 1]],
          ticktext: [graph[0]?.x[0], graph[0]?.x[x.length - 1]],
          //insightChartView === "hour" ?[hourAxisData[0],hourAxisData[hourAxisData.length-1]] : [xAxisData[0],xAxisData[xAxisData.length-1]],
          tickfont: {
            size: 9,
            color: '#495057'
          },
          zeroline: false,
        },
        yaxis: {
          title: "Exception Count",
          // nticks: 0,
          // tickmode: "auto",
          rangemode: "nonnegative",
          tickfont: {
            size: 8,
            color: '#495057'
          },
        },
        hovermode: 'x',
        margin: {
          l: 70,
          r: 20,
          b: 50,
          t: 20,
          pad: 0
          // l: 50,
          // r: 50,
          // b: 100,
          // t: 40,
          // pad: 8
        },
        autoexpand: true,
        autosize: true,
      }
      setlayout(layout)
    }
  }
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);

  const openDeleteDialog = (podId) => {

    currentPodIdRef.current = podId

    // setDeletePopUpContent({
    //   modalClass: "delete-popup",
    //   heading: "Delete Pod",
    //   message: conditionType !== 'flow' ? Literals.DELETE_POD_MSG : Literals.DELETE_FLOW_MSG,
    //   headingIcon: "bx bx-error",
    //   button: [
    //     {
    //       name: "Delete",
    //       action: () => { onDeletePod(podId) },
    //     },
    //     {
    //       name: "Cancel",
    //       action: closeDeletePopUp,
    //     },
    //   ],
    //   elements: [],
    //   closeActionPopup: closeDeletePopUp,
    // });
    setOpenDeletePopup(true);
  };


  const [isSaveConfirm, setisSaveConfirm] = useState(false)

  const openSaveChangesWarning = () => {

    currentConditionTypeRef.current = {
      message: 'Unsaved changes will be lost. Are you sure, want to continue?',
      onConfirm: () => {
        setisSaveConfirm(true);
        setOpenWarningModal(false);
        return true
      },
      onCancel: () => {
        setisSaveConfirm(false);
        return false
      }
    }

    // setDeletePopUpContent({
    //   modalClass: "warning-popup",
    //   heading: "Warning",
    //   message: Literals.CONFIRMATION_MSG_FOR_UNSAVED,
    //   headingIcon: "bx bx-error",
    //   button: [
    //     {
    //       name: "Ok",
    //       action: () => { 
    //         setisSaveConfirm(true); 
    //         closeDeletePopUp(); 
    //         return true
    //       },
    //     },
    //     {
    //       name: "Cancel",
    //       action: () => { 
    //         setisSaveConfirm(false); 
    //         closeDeletePopUp(); 
    //         return false 
    //       },
    //     },
    //   ],
    //   elements: [],
    //   closeActionPopup: closeDeletePopUp,
    // });
    setOpenWarningModal(true);
  };

  const openWarningDialog = (conditionType, msg = null) => {

    currentConditionTypeRef.current = {
      conditionType,
      message: msg ? msg : 'Once you change the condition type, current alert details will be reset. Do you want to proceed?',
      onConfirm: () => handleConditionChangeWarning(conditionType),
      onCancel: () => { }
    }

    // setDeletePopUpContent({
    //   modalClass: "warning-popup",
    //   heading: "Warning",
    //   message: 'Once you change the condition type, current alert details will be reset.',
    //   headingIcon: "bx bx-error",
    //   button: [
    //     {
    //       name: "Ok",
    //       action: () => { 
    //         setconditionType(conditionType); 
    //         conditionTypeRef.current = conditionType; 
    //         closeDeletePopUp(); 
    //         resetAllData(conditionType) 
    //       },
    //     },
    //     {
    //       name: "Cancel",
    //       action: closeDeletePopUp,
    //     },
    //   ],
    //   elements: [],
    //   closeActionPopup: closeDeletePopUp,
    // });

    if (conditionType === 'sql') {
      setisMultiSelection(true)
    } else {
      setisMultiSelection(false)
    }
    if ((appliedFilterCriteria && appliedFilterCriteria.length) || (sqlQuery !== '') || (alertTransformerName !== '') || (flowsAlertTransformerList?.flowId)) {

      setOpenWarningModal(true);

    } else {
      conditionTypeRef.current = conditionType;
      resetAllData(conditionType)
      setconditionType(conditionType)
    }

  };

  const handleConditionChangeWarning = conditionType => {
    setconditionType(conditionType);
    conditionTypeRef.current = conditionType;
    setOpenWarningModal(false);
    resetAllData(conditionType)
  }

  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };

  // const closeConditionWarnPopUp = () => {
  //   setOpenConditionWarnPopup(false);
  // };
  useEffect(() => {
    if (viewTabsActiveKey === 'logs') {
      refreshLogsStatus();

    }


  }, [showLogs])

  const refreshLogsStatus = () => {
    setshowLogsRefresh(true)
    store.dispatch(getExcecutionRetrive(alertId)).then((resp) => {
      setshowLogsRefresh(false)

      if (resp && resp.status === "success" && resp.data?.length) {
        setexecutionLogs([...resp.data]);
        let record = resp.data[resp?.data?.length - 1];
        if (record && record?.state?.toLowerCase() === "success") {

          setlogsRowSelectedIndex(record.id)

          getPreviewTableResult([], true, record);
        }
        setlogsRowSelectedIndex(record?.id)
        generateGraphData([...resp.data])


      }
    }).catch((ele) => {
      setshowLogsRefresh(false)

    });
  }
  const onViewTabChange = (activeKey) => {
    getUUID()
    if (conditionType === 'sql') {
      setisMultiSelection(true)
    } else {
      setisMultiSelection(false)
    }
    setviewTabsActiveKey(activeKey);
    if (activeKey === "logs") {

      setVirtualTableData({})
      setActiveKey('logs')

      setlogResultTabArray([{
        podId: "logs",
        key: "logs",
        tabTitle: `Logs`,
        podName: "",
        databaseName: "",
        tabContent: null,
        closable: false,
      }])
      store.dispatch(getExcecutionRetrive(alertId)).then((resp) => {
        if (resp && resp.status === "success" && resp.data?.length) {

          setexecutionLogs([...resp.data]);
          setlogsRowSelectedIndex(resp.data[resp?.data?.length - 1]?.id)
          generateGraphData([...resp.data])
          if (resp?.data[resp?.data?.length - 1].state.toLowerCase() === "success") {
            setshowSkelaton(true)

            getPreviewTableResult([], true, resp.data[resp?.data?.length - 1]);
          } else {
            setshowSkelaton(false)

          }

        } else {
          setshowSkelaton(false)

        }
      });

    } else {
      setActiveKey('0')

      store.dispatch(resetAllDataGridTableFilters())
      setVirtualTableData({})
      setshowSkelaton(true)

      showResultTabActive.current = true
      // setActiveKey(selectedAlertPodlist[0].podId.toString())
      // store.dispatch(
      //   setActivePodDetails({
      //     connectionName:'hive',
      //     podName: selectedAlertPodlist[0].podName,
      //     dataDomain: selectedAlertPodlist[0].dataDomain,
      //   })
      // );
      // setTimeout(() => {
      //   setgetTableData(true)
      // }, 500);
      let query = store.getState().Alert.CreateAlert.alertSqlEditorQuery
      let appliedFilters = store.getState().Alert.CreateAlert.appliedFilters



      if (query !== '' && conditionType === 'sql') {

        localStorage.setItem("previewFlag", "Query");
        store.dispatch(setPreviewFlag("Query"))

        getUUID()
        let Analysispayload = {
          uuid: uuid.current,
          table_name: '',
          page: 0,
          do_count: false,
          sort_body: [],
          filter_body: [],
          table_ids: [],
          query: query,
        }
        getQueryResult(Analysispayload)

      } else if (appliedFilters && appliedFilters.length && conditionType === 'filter') {
        setActiveKey(selectedAlertPodlist[0].podId)
        store.dispatch(
          setActivePodDetails({
            connectionName: 'hive',
            podName: selectedAlertPodlist[0].podName,
            dataDomain: selectedAlertPodlist[0].dataDomain,
          })
        );

        localStorage.setItem("previewFlag", "Preview");
        store.dispatch(setPreviewFlag("Preview"))

        setTimeout(() => {
          getPreviewTableResult(appliedFilters, false, {})
          showResultTabActive.current = false

        }, 500);
      } else if (alertTransformerName !== '' && conditionType === 'flow') {

        onExecuteTransformerClick(alertTransformerName, flowsAlertTransformerList?.flowId, false)

      }
    }
  };


  const onLogsRowClick = (record, index) => {



    if (record && record?.state?.toLowerCase() === "success") {

      setlogsRowSelectedIndex(record.id)

      getPreviewTableResult([], true, record);
    } else if (
      (record?.state?.toLowerCase() === "error" || record?.state?.toLowerCase() === "failed") &&
      record?.error_message !== null
    ) {
      setOpenFailureModal(true);
      record['alert_name'] = alertName;

      setErrorLogs(record);
    }
  };
  const onDeletePod = (podDetails) => {

    if (podDetails && conditionType !== 'flow') {

      let list = [...selectedAlertPodlist];
      let index = list.findIndex((ele) => ele.podId === podDetails);
      if (index > -1) {
        list.splice(index, 1);
      }
      if (conditionType === 'filter') {
        setcurrentPodColumns([])
        innerContent()
      }

      store.dispatch(setAlertSqlEditorQuery(''))
      store.dispatch(setPreviewTableQuery(''))
      store.dispatch(setSelectedAlertPODList([...list]));
      store.dispatch(setSelectedPodList([...list]));


      closeDeletePopUp()
    } else if (conditionType === 'flow') {
      store.dispatch(setSelectedAlertPODList([]));
      store.dispatch(setAlertAppliedFilters([]));
      store.dispatch(setSelectedPodList([]))
      setAlertTransformList([])
      store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: { flowId: null, alertList: [], selectedFlowDetails: null } })

      setNewResultsTabArray([])
      setVirtualTableData({})
      closeDeletePopUp()

    }
  }

  let newAccordianList = [
    {
      key: "conditionType",
      header: conditionType.toLowerCase() === 'sql' ? "SQL Editor" : conditionType.toLowerCase() === 'filter' ? 'Filter'
        : <> Flow Alert Transformer {flowsAlertTransformerList?.selectedFlowDetails?.updated_on ? <span className="click-to-select pl-2 pr-2">
          <label className="mb-0 text-black-50 font-italic text-capitalize small">Last updated on: {getRelativeTime(flowsAlertTransformerList?.selectedFlowDetails?.updated_on)}</label>
          <label className="mb-0 ml-2 text-black-50 font-italic text-lowercase small">Updated by user: {flowsAlertTransformerList?.selectedFlowDetails?.updated_by_user?.email}</label>
        </span> : null}</>,
      innerContent: () => innerContent(conditionType),
      addButton: conditionType.toLowerCase() === 'sql' ? <div className="d-flex sql-btn align-items-center">
        {expandTable ?
          <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon", buttonEvent: (e) => { e.stopPropagation(); setexpandTable(false) }, ImgSrc: () => <EXITFULLSCREEN />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
          :
          <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon", buttonEvent: (e) => { e.stopPropagation(); setexpandTable(true) }, ImgSrc: () => <FULLSCREEN />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
        }
        <Buttons
          props={{
            buttonText: "",
            buttonClassName:
              "custom-btn-outline custom-btn btn-with-icon  mr-2",
            buttonEvent: (e) => {
              e.stopPropagation();
              store.dispatch(saveSQlTrigger({ trigger: 'download_click' }))
              // editorRef.current.downloadQueryHandler();
            },
            ImgSrc: () => <DOWNLOAD />,
            isDisable: false,
            buttonType: Literals.BTN_TERTIARY,
          }}
        />
        {window.location.href.includes("viewAlert") ? "" : <Buttons
          props={{
            buttonText: "",
            buttonClassName: "custom-btn-outline custom-btn  mr-2 btn-with-icon",
            buttonEvent: (e) => {
              e.stopPropagation();
              // editorRef.current.executeQuery();
              store.dispatch(saveSQlTrigger({ trigger: 'run_click' }))

            },
            ImgSrc: () => <PLAY />,
            isDisable: false,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />}
      </div> : conditionType.toLowerCase() === 'flow' ?
        <div className="d-flex align-items-center">
          <Buttons
            props={{
              buttonText: "",
              tooltip: "Redirect to flow",
              buttonClassName:
                "custom-btn-outline custom-btn  mr-2 btn-with-icon",
              buttonEvent: (e) => {
                e.stopPropagation();
                window.open(`${Literals.links.FLOWS_VIEW}${flowsAlertTransformerList?.flowId}`);
              },
              ImgSrc: () => <REDIRECTJOBS />,
              isDisable: false,
              buttonType: Literals.BTN_SECONDARY,
            }}
          />
          <Buttons
            props={{
              buttonText: "Execute",
              tooltip: "Execute Alert Transformer",
              buttonClassName:
                "custom-btn-outline custom-btn  mr-2 btn-with-text",
              buttonEvent: (e) => {
                e.stopPropagation();
                onExecuteTransformerClick('', flowsAlertTransformerList?.flowId, true)
              },
              ImgSrc: () => <PLAY />,
              isDisable: false,
              buttonType: Literals.BTN_TERTIARY,
            }}
          /></div> : null
    },
  ];
  const plotlyConfig = {
    displaylogo: false,
    responsive: true,
    editable: false
  }


  let graphAccordian = [
    {
      key: 'conditionType',
      header: 'Performance Over Time',
      innerContent: () =>
        <div className="chart p-2">
          {graphData?.length ? <Plot
            useResizeHandler={true}
            data={graphData}
            layout={layout}
            // layout={plotlyLayout(graph?.column?.name, graph?.column?.dataType)}
            style={{ width: "100%", height: "200px" }}
            config={plotlyConfig}
          /> :
            <div style={{ height: "200px", color: "#D3D3D3", fontWeight: "500" }} className="d-flex flex-column align-items-center justify-content-center w-100 paragraph ">
              <USAGETIME height="35" width="35" color="#D3D3D3" ></USAGETIME>
              No Logs Found</div>
          }
          {/* <GraphIndex customHeight={300} fromViewAnalysis={true} subType={'basic'} isViewMode={true} graphSubType={'basic'} graphType={'Line'} graphData={graphData} graphId={''} graphLayout={{ xAxisLabel: 'Timestamp', yAxisLabel: 'Exceptions', showLegend: false, orientation: 'v',...layout }} activeTabName='Query' />, */}
        </div>
    }
  ]

  const innerContent = () => {
    switch (conditionType) {
      case "":
        return (<div className="no-preview-table-data ">
          <NoConditionSelected />
        </div>)

      case "sql":
        return (

          <AlertSqlEditor
            ref={editorRef}
            uuid={uuid.current}
            getUUID={getUUID}
            activeKey={activeKey}
            expandTable={expandTable}
            setexpandTable={setexpandTable}
            setVirtualTableData={setVirtualTableData}
            editorHeight={200}
            setSavingQuery={() => { }}
            editorExpand={false}
            handleEditorExpand={() => { }}
            handleQueryBar={() => { }}
            readOnlyEnable={readOnlyEnable}
            setreadOnlyEnable={setreadOnlyEnable}

            setshowSkelaton={setshowSkelaton}
          />

        );

      case "filter":
        return <DataGridAddFilter
          ref={childAddFilterRef}
          props={{
            icon: () => <PROPERTIES />,
            title: "Filter",
            onSampleEvent: () => { },
            isFilterTransformer: false,
            initialColumn: currentPodColumns,
            setAppliedFilter: setAppliedFilters,
            appliedFilter: alreadyAppliedFilters,
            isdisabled: window.location.href.includes("viewAlert") ? true : false,
            ctx: ``
          }}
        />

      case 'flow':
        return <div className="w-100 d-flex align-items-center justify-content-center p-2 " style={{ minHeight: "50%", maxHeight: "50%", overflow: "auto" }}>
          <List
            itemLayout="horizontal"
            className=" col-6 alert-transformer-list"
            dataSource={AlertTransformList}
            renderItem={item => (

              <List.Item className={alertTransformerID !== '' && alertTransformerID === item.id ? 'active-list-item alert-active-li' : window.location.href.includes("viewAlert") ? 'cursor-not-allowed' : 'alert-inactive-li'}
                onClick={() => onSelectAlertTransformer(item.title, item.id)} >

                <List.Item.Meta
                  avatar={<ICON_ALERT height="18" width="18" />}
                  title={item.title}
                  className={window.location.href.includes("viewAlert") ? "" : "cursor-pointer"}
                />
                {alertTransformerName === '' ? <span className="click-to-select pr-2">
                  {/* <label className="mb-0 text-black-50 font-italic text-capitalize small">Click to select alert transformer</label> */}
                </span> : null}
                {/* <span className="click-to-select pr-2">
                  <label className="mb-0 text-black-50 font-italic text-capitalize small">Last updated on:{getRelativeTime(item.updated_on)}</label>
                </span>  */}
              </List.Item>
            )}
          />
          {flowsAlertTransformerList?.selectedFlowDetails?.thumbnail ? <div className="col-6" style={{ height: "calc(100% - 55px)", padding: "20px" }}>
            <div className="alert-flow-thumbnail-wrap" style={{ backgroundImage: `url(${flowsAlertTransformerList?.selectedFlowDetails?.thumbnail !== '' ? flowsAlertTransformerList?.selectedFlowDetails?.thumbnail : DefaultFlow})` }}>
              {/* <a 
                target='_blank' 
                download={'thumbnail.png'}
                href={flowsAlertTransformerList?.selectedFlowDetails?.thumbnail !== '' ? flowsAlertTransformerList?.selectedFlowDetails?.thumbnail : DefaultFlow}>
                <i className="bx bx-download font-weight-bold display-4"></i>
              </a> */}
            </div>
            {/* <img style={{objectFit:"cover",border:"1px solid black",}} src={flowsAlertTransformerList?.selectedFlowDetails?.thumbnail !== '' ? flowsAlertTransformerList?.selectedFlowDetails?.thumbnail : DefaultFlow}  height={270} width={450} alt="no flow selected"></img> */}
          </div> : null}
        </div>

      default:
        break;
    }

  };

  const getCurrentPODName = () => {
    return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails?.podName
  }

  const getCurrentPODCategoryName = () => {
    return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails?.dataDomain
  }

  const getPODId = () => {
    return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails?.podId
  }

  const setUniqUUID = (uid) => {
    uuid.current = uid
    setuuid(uid);
  }


  return (

    <div>
      <LandingpageFirstHeader title={title} />
      <AlertSubHeader
        ruleName={alertName}
        alertId={alertId}
        createRule={createRule}
        isEditPermission={isEditPermission}
        isSaveConfirm={isSaveConfirm}
        setisSaveConfirm={setisSaveConfirm}
        openSaveChangesWarning={openSaveChangesWarning}
        showLogs={showLogs}
        setShowLogs={setShowLogs}
        retriveAlertData={retriveAlertData}
      />
      {showPage ? <div className="alert-landing-body overflow-hidden">
        <div id="alert-log-prop" className={`alert-form-left section_with_left_form ${!showLogs ? 'hide-alert-logs' : ''}`}>
          <div className="form_left alert-time-index position-relative">
            {window.location.href.includes("viewAlert") ? (
              <div className="custom-tab-with-text position-relative">
                <DataTabs
                  props={{
                    activeKey: viewTabsActiveKey,
                    tabArray: !history_id
                      ? viewAlertDetailsTabArray
                      : viewAlertDetailsTabArray.filter(v => v.key !== 'timeline'),
                    showTooltip: false,
                    onChange: (key) => {
                      onViewTabChange(key);
                    },
                    onEdit: () => { },
                  }}
                />
                {/* <Tooltip placement="right" title={'Collapse'}>
                  <span className="alert-prop-toggle">
                    <i className="bx bx-arrow-from-right"></i>
                  </span>
                </Tooltip> */}
              </div>
            ) : (
              <AlertDetails
                isDisabled={true}
                selectedAlertTransformer={alertTransformerID}
                conditionType={conditionType}
                setconditionType={openWarningDialog}
                setcreateAlertClick={setcreateAlertClick}
                isRulesPublish={createAlertClick}
                retriveAlert={retriveAlertData}

              />
            )}


            <LeftSidePanelToggler open={showLogs} setOpen={setShowLogs} />
          </div>
          <div className={`section_content_right ${conditionType === 'filter' ? 'filter-section' : ''} ${viewTabsActiveKey === 'logs' ? 'logs-section' : ''}`} style={{ marginTop: viewTabsActiveKey === 'logs' ? '-10px' : '' }}>
            {
              viewTabsActiveKey !== 'logs' ? <div className="listofpod-section">
                <ListofPodDetails
                  showLogs={showLogs}
                  podList={selectedAlertPodlist}
                  readOnly={false}
                  conditionType={conditionType}
                  toggleModalHandler={toggleModalHandler}
                  onDeletePod={openDeleteDialog}
                />
              </div> : null}

            <div className="condition-type-section">

              {
                viewTabsActiveKey === 'logs' ? <Accordian
                  props={{
                    isDisable: false,
                    accordionList: graphAccordian,
                    Icon: () => <ACCORDIONDOWNARROW />,
                    defaultExpandedPanel: ['conditionType'],
                  }}
                /> : <Accordian
                  props={{
                    isDisable: false,
                    accordionList: newAccordianList,
                    Icon: () => <ACCORDIONDOWNARROW />,
                    defaultExpandedPanel: ['conditionType'],
                  }}
                />
              }
            </div>



            {/* {conditionType === "" ? (

            ): null}
            {conditionType === "sql" ? (
             

            ) : null}

            {conditionType === "filter" ? (

            ): null} */}
            {(selectedAlertPodlist && selectedAlertPodlist.length) || (conditionType === 'flow' && newResultsTabArray.length) ? <ResultSection
              tableData={virtualTableData}
              VirtualTableHeight={tableHeight}
              tabArray={viewTabsActiveKey !== 'logs' ? newResultsTabArray : logResultTabArray}
              onTabChange={onChange}
              uuid={uuidState}
              setUniqUUID={setUniqUUID}
              requestedTableId={getPODId()}
              currentPODName={getCurrentPODName}
              currentDomainName={getCurrentPODCategoryName}
              tableTitle={tableTitle}
              activeTabKey={activeKey}
              onEdit={onEdit}
              isShowFilter={isShowFilter}
              isDisableHideBtn={isDisableHideBtn}
              showLoader={showSkelaton}
              setshowLoader={setshowSkelaton}
              setIsPreviewDataLoading={setIsPreviewDataLoading}
              isPreviewDataLoading={isPreviewDataLoading}
              parentError={parentError}
            /> : null}
          </div>
        </div>
      </div> : null
      }
      {/* {props.loadingStatus ? <LoadingComponent /> : null} */}
      <PODSelection
        isMultiSelection={isMultiSelection}
        save={onProceedHandler}
        podId={""}
        openModal={isModalOpen}
        onClose={() => {
          toggleModalHandler();
        }}
        onRowSelection={() => { }}
      />
      <AlertExceptionPreviewModal
        openExceptionModal={openExceptionModal}
        setOpenExceptionModal={setOpenExceptionModal}
        exceptionData={exceptionData}
        setExceptionData={setExceptionData}
        exceptionDataTimeStamp={exceptionDataTimeStamp}
      />
      <ExceptionErrorLogModal
        openFailureModal={openFailureModal}
        setOpenFailureModal={setOpenFailureModal}
        errorLogs={errorLogs}
      />
      {
        getConfirmationModal({
          open: openWarningModal,
          setOpen: setOpenWarningModal,
          body: currentConditionTypeRef?.current?.message,
          onConfirm: currentConditionTypeRef?.current?.onConfirm,
          onCancel: currentConditionTypeRef?.current?.onCancel
        })
      }
      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={
          DeleteNoteContent(
            currentPodIdRef?.current,
            'Pod',
            `Warning: After deleting this Pod, Query editor/Applied Filter will be reset.`,
            true,
            false
          )
        }
        onConfirm={() => onDeletePod(currentPodIdRef?.current)}
      />
      {/* <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent}
      /> */}

    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    // loadingStatus: state.LoadingReducer.loadingStatus,
  };
};

export default connect(mapStateToProps)(CreateAlertIndex);
