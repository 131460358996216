import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../common/loadingComponent";
import UserGroupCardView from "../userGroup/components/UserGroupCardView";
import UserGroupListView from "./components/UserGroupListView";
import { PLUS } from "../../../Common/iconSource";
import { Literals } from "../../common/literals";
import Buttons from "../../../Common/button/Buttons";

const UserGroups = (props) => {
  const loadingStatus = useSelector(
    (state) => state.LoadingReducer.loadingStatus
  );

  const isSuperUser = useSelector(state => state.UserReducer.user.userDetails.is_superuser)

  const history = useNavigate();

  
  const layOutReturn = () => {
    return props.layout === "grid" ? (
      <div>
        <UserGroupCardView
          showDataDomainDetails={true}
          setShowDataDomainDetails={true}
          searchData={props.setSearchData}
        />
      </div>
    ) : (
      <UserGroupListView
        showDataDomainDetails={true}
        setShowDataDomainDetails={true}
        searchData={props.setSearchData}
      />
    );
  };

  return (
    <div style={{height: 'calc(100vh - 70px)', overflowY: 'auto'}}>
      {layOutReturn()}
      {loadingStatus ? <LoadingComponent /> : null}
      <Buttons
        props={{
          tooltip: "Create UserGroup",
          buttonText: "",
          buttonClassName:
            "custom-btn-primary custom-btn fixed-button",
          buttonEvent: () => {
            history(Literals.links.CREATE_USER_MANAGEMENT, {state: { tabName: Literals.USERGROUP, activeTab: Literals.USERGROUP } });
            window.history.replaceState({ state: { tabName: Literals.USERGROUP } },'')

          },
          ImgSrc: () => <PLUS />,
          isDisable: !isSuperUser,
          buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
        }}
      />
    </div>
  );
};
export default UserGroups;
