import { Avatar, Checkbox, Input, List, Popover, Select, Switch, Tooltip, Row, Col, message } from 'antd'
import _, { cloneDeep, filter, isArray, map, set, uniqBy } from 'lodash'
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { fetchFileSystemPreview, fetchTableDataV2, getActivePodData, getCreatePodPreviewTable, setActivePodPODDetails, setPodRightSideData } from '../../../../../store/actions'
import { SET_SELECTED_BUSINESS_TERM, SET_SELECTED_BUSINESS_TERM_TEMP } from '../../../../../store/modules/dataCatalogue/dictionary/dictionaryActionTypes'
import { getBusinessTermsOfPod, getClassificationData, getSettingsAdditionalDetailsList, getSettingsDataCategory } from '../../../../../store/modules/dataDictionary/action'
import Buttons from '../../../../Common/button/Buttons'
import { MultipleColsDndList } from '../../../../Common/dndVirtualList/MultipleColsDndList'

import SingleSelection from '../../../../Common/dropDown/SingleSelection'
import { CLOSE, EXITFULLSCREEN, FULLSCREEN, HIDEPREVIEW, PLUS, PREVIEW, RESETFILTER, SAVE, SEARCH, SIXDOTS, CATEGORY, USERTAG, FILTER, REDIRECTJOBS } from '../../../../Common/iconSource'
import SearchInput from '../../../../Common/search/Search'
import { businessTermStatusOptions, columnNameRegexExp, correctBusinessTerm, dataTypeList, getColumnDataType, getObjectLength, getReloadErrorTemplate, pxToRem, removeStartEndUnderscores, removeStartingUnderscores, remToPx, schemaTableOptionalFieldHelpContent, showSimpleLoading, toTitleCase } from '../../../common/helperFunctions'
import { Literals } from '../../../common/literals'
import BusinessTermModal from '../../../dataDictionary/components/BusinessTermModal'
import CategoryModal from '../../../dataDictionary/components/categoryModal'
import ClassificationModal from '../../../dataDictionary/components/classificationModal'
import DataCategoryModal from '../../../dataDictionary/components/DataCategoryModal'
import ManualCreationModal from '../../../dataDictionary/components/ManualCreationModal'
import SettingsClassificationModal from '../../../dataDictionary/components/SettingsClassificationModal'
import FormatColHelplinkTable from './FormatColHelplinkTable'

import Highlighter from "react-highlight-words";
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import { BusinessTermInput } from './BusinessTermInput'
import DebounceInput from '../../../../Common/debounceInput/DebounceInput'
import DictionaryTableFilter from '../../../../Common/dictionaryTableFilter/DictionaryTableFilter'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import './PodCreationSchema.scss'
import SplitPane from 'split-pane-react/esm/SplitPane'
import DataGridTable from '../../../../Common/dataGridTable/DataGridTable'
import { ICON_ALERT, ICON_ARCHIVED, ICON_AUTO_CLASSIFY, ICON_CLEAR, ICON_EYECLOSE, ICON_EYEOPEN, ICON_GUIDE, ICON_INFO_FILLED, ICON_SEARCH, ICON_TABLE } from '../../../../Common/newIconSource'
import { v4 as uuidv4 } from 'uuid'
import ContainsPII from '../../../dataDictionary/components/ContainsPII'
import ClaristaCopilot from '../../../copilot'
import Draggable from 'react-draggable'
import { CustomSkeleton } from '../../../../Common/skeleton/CustomSkeleton'

const columnWidth = [260, 100, 180, 180, 180, 180, 180, 180];

const statusOptions = [...businessTermStatusOptions]

const status_color_map = {
    pending: { bg: '#E7B505', text: '#FFF' },
    active: { bg: '#4CAF50', text: '#FFF' },
    archived: { bg: '#848483', text: '#FFF' }
}

const formatPlaceholderMap = {
    decimal: '10,0',
    char: '1',
    varchar: '100',
    date: '%Y-%m-%d',
    time: '%h:%i:%s %p',
    timestamp: '%Y-%m-%d %h:%i:%s %p',
}

const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    background: '#FFF'
};

const PodCreationSchema = forwardRef(({
    podId,
    showNote = false,
    isDataCatalog = false,
    setSearchValue = () => { },
    searchValue = '',
    usersList = [],
    isUpdateView = false,
    isReadOnly = false,
    setNewBusinessTerms = () => { },
    showLeftPanel = false,
    dictionaryRefresh = false,
    getSchemaData = () => { },
    connectionChanged = false,
    disableRowReorder = false,
    disableCheckbox = false,
    schemaUUID = '',
    isIFDB = false, // Import From Database -> For Business Term Creation,
    currentActiveTabKey = '',
    isPodOfPod = false,
    isFileUpload = false,
    isCustomQuery = false,
    setPodPreviewRunning = () => { },
    isUnstructured = false
}, ref) => {


    let initialColumns = [
        { key: 'business_term', title: 'Display Name', isRequired: true, visible: true, frozen: true },
        // { key: 'technical_term', title: 'Technical Name', isRequired: true, visible: true },
        // { key: 'optional', title: 'Format', isRequired: true, visible: true },
        { key: 'description', title: 'Description', isRequired: true, visible: true },
        // { key: 'category', title: 'Data Category', isRequired: true, visible: true },
        { key: 'classifications', title: 'Data Classification', isRequired: true, visible: true },
        // { key: 'status', title: 'Status', isRequired: true, visible: true },
        { key: 'pii', title: 'PII', isRequired: true, visible: true },
        { key: 'data_owners', title: 'Data Owners', isRequired: false, visible: isDataCatalog },
        { key: 'data_stewards', title: 'Data Stewards', isRequired: false, visible: isDataCatalog }
    ]

    if (isDataCatalog) {
        initialColumns = initialColumns.filter((item) => item.key !== 'optional')
    }
    const dispatch = useDispatch()

    const store = useStore()

    const columnIndex = useRef({});
    const additionalFields = useRef([]);
    const businessTermListRef = useRef([]);

    const reduxData = useSelector((state) => state.DataCatalogue.PodCreationData);
    const reduxSchemaUUID = reduxData?.schemaUUID
    const schemaTableData = reduxData?.schemaTableData
    // const { virtualTablesDataMap, previewTableData, podRightSideData, activePodTabDataKey, podUserInputDataMap } = useSelector((state) => state.CreatePODReducer);
    const { selectedConn } = useSelector((state) => state.UserManagement.ConnectionReducer);

    const [visible, setVisible] = useState(false);


    const [showDataTypeDropdown, setshowDataTypeDropdown] = useState(false)
    const [isInputReady, setIsInputReady] = useState(true)
    const [isSchemaReady, setIsSchemaReady] = useState(false)
    const [dataCategoryList, setDataCategoryList] = useState([])
    const [dataClassificationList, setDataClassificationList] = useState([])
    const [businessTermList, setBusinessTermList] = useState([])
    const [data, setData] = useState(reduxSchemaUUID === schemaUUID ? [...schemaTableData] : [])
    const [demo, setDemo] = useState([])
    const [showHideFieldPop, setHideFieldPop] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [expanded, setExpand] = useState(false)
    const [hideFieldSearchValue, setHideFieldSearchValue] = useState('')
    const [addModals, setAddModals] = useState({ business_term: false, category: false, classification: false })
    const [columns, setColumns] = useState([])
    const [colsList, setColsList] = useState([...initialColumns])
    const [selectedCategoriesFilter, setSelectedCategoriesFilter] = useState([]);
    const [selectedClassificationsFilter, setSelectedClassificationsFilter] = useState([]);
    const [selectedSingleClassifier, setSelectedSingleClassifier] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [categoriesPresent, setCategoriesPresent] = useState({});
    const [classificationsPresent, setClassificationsPresent] = useState({});
    const [openformatHelpModal, setopenformatHelpModal] = useState(false);
    const [openBusinessTermModal, setopenBusinessTermModal] = useState(false);
    const [openClassificationModal, setOpenClassificationModal] = useState(false);
    const [showFilterPop, setFilterPop] = useState(false);
    const [currRowId, setCurrRowId] = useState(null);
    const [populateSearchData, setPopulateSearchData] = useState("") //for auto populating search box of Business Term Modal.
    const [filterList, setFilterList] = useState({ category: [], classifiers: [] })
    const [isRowReorder, setRowReorder] = useState(!disableRowReorder)

    const [sizes, setSizes] = React.useState(["80%", "20%"])
    const [showResultTable, setShowResultTable] = React.useState(true)

    const [fetchedAddFields, setFetchedAddFields] = React.useState({})
    const [fetchedAddFieldsCols, setFetchedAddFieldsCols] = React.useState([])
    const [showAllCols, setShowAllCols] = React.useState(true)    
    const [activeDrags, setActiveDrags] = React.useState(0)

    const [autoClassify, setAutoClassify] = React.useState(
        isCustomQuery || selectedConn?.file_system || isFileUpload
            ?
            reduxData.tableData?.['result']?.['autoClassify'] ?? false
            :
            reduxData.tableData?.[currentActiveTabKey]?.['autoClassify'] ?? false
    )
    const [autoClassifyLoader, setAutoClassifyLoader] = React.useState(false)

    const [unsavedDataObj, setUnsavedDataObj] = React.useState({})

    const [schemaErr, setSchemaError] = React.useState(undefined)

    const categoryRefFilter = useRef([]);
    const classificationRefFilter = useRef([]);
    const isFilterRef = useRef(false);
    const categoryRef = useRef([])
    const categoryIndexRef = useRef(null)
    const classificationRef = useRef([])
    const searchedBizTermRef = useRef([])
    const activeClassifier = useRef(null)
    const activeCategory = useRef(null)
    const localSearchRef = useRef({})

    const addButtons = [
        { title: "Display Name", event: () => { setModalState(true, 'business_term') } },
        { title: "Category", event: () => { setModalState(true, 'category') } },
        { title: "Classification", event: () => { setModalState(true, 'classification') } }
    ]

    React.useImperativeHandle(ref, () => ({
        updateSchema() {
            if (isIFDB) {
                generateTableData(fetchedAddFields, false)
                setColsList([...colsList, ...fetchedAddFieldsCols])
            }
        },
    }))

    useEffect(() => {

        return () => {
            let reduxData = store.getState().DataCatalogue.PodCreationData;
            // console.log({sc:reduxData?.schemaUUID, schemaUUID})
            if (reduxData?.schemaUUID !== schemaUUID) {
                setData([])
                setSchemaTableData([])
            }
            else {
                setIsInputReady(false)
                reduxData['schemaBusinessTerms'] = _.uniqBy([
                    ...searchedBizTermRef.current,
                    ...businessTermList
                ], 'id')
                // reduxData['schemaBusinessTerms'] = [...searchedBizTermRef.current]
                dispatch(setPodCreationData(reduxData))
                setTimeout(() => setIsInputReady(true), 500)
            }

            setSearchValue('')
        }
    }, [])

    useEffect(() => {
        getSchemaData(data)
    }, [data])

    useEffect(() => {
        setIsInputReady(false)
        setTimeout(() => setIsInputReady(true), 50)

        if (searchValue?.trim() !== '') {
            setRowReorder(false)
        }
        else {
            setRowReorder(!disableRowReorder)
        }

    }, [searchValue])

    useEffect(() => {
        setIsInputReady(false)
        setTimeout(() => setIsInputReady(true), 800)
    }, [isReadOnly])

    useEffect(() => {
        if (schemaTableData.length > 0) {
            handleSaveBusinessTerms()
        }
    }, [schemaTableData, businessTermList, dataCategoryList, dataClassificationList])

    useEffect(() => {
        getBusinessTermList()
    }, [])


    const onRowClick = (row, col) => {
        let id = row.curr_bizterm_id ?? undefined
        // let id = searchedBizTermRef.current.find((item) => item?.business_term?.toLowerCase() === row?.business_term?.toLowerCase())?.id;
        if (id) {
            window.open(`/data-dictionary/${id}`, '_blank')
        } else {
            emitToastNotification('info', 'Dictionary details not found for this display name');

        }
    }
    useEffect(() => {
        if (isUpdateView || isDataCatalog) {
            // //console.log('__cpsv',{businessTermList})
            if ((businessTermList.length > 0) || (searchedBizTermRef.current.length > 0)) {
                getAdditionalDetailsList()
                // getDataCategoryList()
                getDataClassificationList()
            }
        }
        else {
            getAdditionalDetailsList()
            // getDataCategoryList()
            getDataClassificationList()
        }
    }, [businessTermList])

    useEffect(() => {
        let isAllSelected = data.every(item => item?.is_row_selected === true)
        setSelectAll(isAllSelected)
        if (data.length) setIsSchemaReady(true)
        if (schemaTableData.length) setIsSchemaReady(true)
    }, [data, schemaTableData])

    // useEffect(() => {
    //     let tempData = [...schemaTableData];
    //     let tempCategoriesPresent = {};
    //     let tempClassificationsPresent = {};
    //     tempData.forEach(item => {
    //         tempCategoriesPresent[item.category] = true;
    //     })
    //     tempData.forEach(item => {
    //         item.classifications.forEach((classification) => {
    //             tempClassificationsPresent[classification] = true;

    //         })
    //     })
    //     setCategoriesPresent({ ...tempCategoriesPresent })
    //     setClassificationsPresent({ ...tempClassificationsPresent });
    // }, [schemaTableData])

    React.useEffect(() => {
        let clone = [...schemaTableData]
        clone = clone?.map(c => ({...c, __searched: false}))

        let podReduxData = store.getState().DataCatalogue.PodCreationData
        let podData = cloneDeep(podReduxData)
        podData.schemaTableData = clone
        dispatch(setPodCreationData(podData))
    }, [searchValue])

    useEffect(() => {
        // const FILTER_COLUMNS = ['technical_term', 'business_term']
        const FILTER_COLUMNS = colsList.filter(c => c.visible === true).map(itm => itm.key)
        let clone = [...schemaTableData]
        let newData = clone.filter(item => [...FILTER_COLUMNS, 'technical_term'].some(key => {
            let data = item[key];

            if (key === "data_owners" || key === "data_stewards" || key === 'classifications') {
                if (isArray(item[key])) {
                    data = item[key].join(' ')
                }
                else {
                    data = ''
                }
            }
            if (typeof data === 'boolean') {
                data = item[key].toString() ?? ''
            }
            if (typeof data === 'object') {
                data = ''
            }

            if(searchValue?.trim() === "") {
                return data
            }

            return data?.toLowerCase()?.includes(searchValue.toLowerCase()) 
                    || (item?.__searched === true && searchValue?.trim() !== '')
        }))
        ?.map(d => ({
                ...d, 
                __searched: searchValue?.trim() === '' 
                    ? false 
                    : searchValue?.trim() !== '' || d?.__searched === true
                })
            )


        /**************************NEW FILTER DESIGN LOGIC****************************/
        if (filterList?.category?.length > 0 || filterList?.classifiers?.length > 0) {
            const catFilter = filterList?.category?.filter(v => v.selected)?.map(v => v.name)
            const clsFilter = filterList?.classifiers?.filter(v => v.selected)?.map(v => v.name)
            if (catFilter && catFilter?.length > 0) {
                newData = newData.filter(item => {
                    let term = item?.category?.toLowerCase()
                    return catFilter.includes(term === '' ? '(blank)' : term)
                }
                )
            }
            if (clsFilter && clsFilter?.length > 0) {
                newData = newData.filter(item => {
                    let classificationsList = [...item?.classifications ?? []]
                    if (classificationsList?.length === 0) {
                        classificationsList = ['(blank)']
                    }
                    return clsFilter.some(val => classificationsList?.map(v => v?.toLowerCase()).includes(val))
                }
                )
            }
        }
        setData([...newData])
        let rows = [...newData]
        generateTemplate(rows)
    }, [colsList,
        showDataTypeDropdown,
        schemaTableData,
        categoryRef.current,
        // selectedClassificationsFilter,
        classificationRef.current,
        dataCategoryList,
        dataClassificationList,
        // selectedCategoriesFilter,
        businessTermList,
        selectAll,
        isInputReady,
        searchValue,
        filterList
    ])
    
    useEffect(() => {
        if (isUpdateView) return
        if (filterList?.category?.length === 0) {
            setFilterList(
                {
                    category: dataCategoryList?.map(c => ({ name: c?.name?.toLowerCase(), selected: false })),
                    classifiers: dataClassificationList?.map(c => ({ name: c?.name?.toLowerCase(), selected: false }))
                }
            )
        }
    }, [dataCategoryList, dataClassificationList])

    const generateTemplate = (rows) => {
        let temp = [], obj = {};
        let cols = [...generateColumns(colsList)]
        temp = rows.map((v, i) => {
            Object.keys(v).forEach(key => {
                obj[key] = getFieldTemplate(key, i, v[key], v)
            })
            // console.log(i, {obj})
            return { ...obj }
        })
        // console.log({temp})
        setDemo([...temp])
        setColumns([...cols])
    }

    const getTechTermBizTermMapping = async (techTerms) => {
        // let tech_term = [...techTerms]
        // let payload = { tech_term }
        try {
            let podReduxData, podData, outputRegex

            podReduxData = store.getState().DataCatalogue.PodCreationData
            podData = cloneDeep(podReduxData)
            outputRegex = podData?.['output_regex'] ?? []

            let newData = outputRegex?.map(item => ({ tech_term: item?.tech_term, data_dict_options: item?.data_dict_options }))
            return newData

            /* let res = await dispatch(getSearchedBusinessTerms(payload))
            let data = res.data.map(item => {
                return {
                    ...item,
                    data_dict_options: [...item.data_dict_options.filter(v => v.data_dict !== null)]
                }
            })
            return data */
        }
        catch (err) {
            console.error(err)
        }
        return []
    }
    // console.log({data})
    const generateTableData = async (addFields = {}, firstCall = false) => {

        // console.log({'reduxData?.schemaUUID': reduxData?.schemaUUID, schemaUUID, firstCall})
        let virtualTblData = cloneDeep(reduxData?.tableData)
            , podReduxData = await store.getState().DataCatalogue.PodCreationData
            , podData = cloneDeep(podReduxData)
            , outputRegex = []



        if (firstCall && reduxData?.schemaUUID === schemaUUID && !isIFDB) {
            searchedBizTermRef.current = [...reduxData?.['schemaBusinessTerms']]
            let mapping = await getTechTermBizTermMapping(reduxData?.tableData?.result?.data_header)
            if (mapping.length) {
                let searchedBizTermList = _.uniqBy(
                    mapping.map(term => term.data_dict_options[0] ?? null, 'id')
                        .filter(v => v?.business_term !== '' && v !== null)
                )
                searchedBizTermRef.current = [...searchedBizTermRef.current, ...searchedBizTermList]
            }
            let nonExistTerm = [...reduxData?.schemaTableData]
            nonExistTerm = nonExistTerm?.reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr?.__custom_row_id]: { ...curr }
                }
            }, {})

            setUnsavedDataObj({ ...nonExistTerm })

            return
        }
        else if (firstCall && reduxData?.schemaUUID !== schemaUUID) {
            setIsInputReady(false)
            setTimeout(() => setIsInputReady(true), 50)
        }
        else if (isIFDB) {
            setIsInputReady(false)
            setTimeout(() => setIsInputReady(true), 50)
        }

        let resultsData;

        resultsData = podReduxData?.tableData?.result;

        if (isDataCatalog) {
            let currPodDetail = store.getState().DataCatalogue.Dictionary.dictionaryPodData

            let tempHeaders = currPodDetail?.column_detail?.map(v => v.column_source_name)
            let tempDataTypes = currPodDetail?.column_detail?.map(v => v.column_datatype.includes('(') ? v.column_datatype.split('(')[0] : v.column_datatype)

            resultsData = {
                data_header: [...tempHeaders],
                data_dtype: [...tempDataTypes]
            }
        }


        outputRegex = podData?.['output_regex'] ?? []

        let schemaTableData = []
            , schemaTableDataPayload = [];

        let originalHeaders = resultsData?.data_header
            , headers = resultsData?.data_header
            , dataTypes = resultsData?.data_dtype
        let currPodData = {};
        if (isDataCatalog) {
            currPodData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
            headers = await currPodData?.column_detail?.map(itm => itm.column_name)
            originalHeaders = [...headers]
            dataTypes = await currPodData?.column_detail?.map(itm => itm.column_datatype)
        }

        headers = headers.map(header => (
            removeStartEndUnderscores(header.toString().replaceAll(columnNameRegexExp, '_'))
        ))

        //START:: Technical Term & Business Term Mapping Code
        let mapping = [], techBizMap = {}, techBizDatatypeMap = {}, searchedBizTermList = [];
        if (isDataCatalog) {
            techBizMap = currPodData?.column_detail.reduce((prev, curr) => (
                { ...prev, [curr?.column_name]: { id: curr?.data_dict, tech_term: curr?.column_source_name } }
            ), {})
        }
        else {
            let techTermHeaders = [...originalHeaders];
            // let techTermHeaders = [...headers];
            if (isUpdateView) {
                const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
                const selectedCols = podData.column_detail.map(v => v?.column_source_name?.toLowerCase())
                techTermHeaders = headers.filter(itm => !selectedCols.includes(itm.toLowerCase()))
            }
            // console.log({techTermHeaders})
            if (techTermHeaders.length) {
                mapping = await getTechTermBizTermMapping(techTermHeaders)
                // console.log({mapping})
                if (mapping.length) {
                    searchedBizTermList = _.uniqBy(
                        mapping.map(term => term.data_dict_options[0] ?? null, 'id')
                            .filter(v => v?.business_term !== '' && v !== null)
                    )
                    searchedBizTermRef.current = [...searchedBizTermList]
                    techBizMap = mapping.reduce((prev, curr) => {
                        let key = curr.tech_term.toLowerCase()
                            , val = curr.data_dict_options?.[0]?.business_term
                        return ({ ...prev, [key]: val ?? undefined })
                    }, {})
                    techBizDatatypeMap = mapping.reduce((prev, curr) => {
                        let key = curr.tech_term.toLowerCase()
                            , val = curr.data_dict_options?.[0]?.data_type_option?.[0] ?? undefined
                        return ({ ...prev, [key]: val ?? undefined })
                    }, {})
                }
            }
        }
        // console.log({techBizMap, techBizDatatypeMap})
        //::END

        let bizTermClone = isUpdateView ? [...businessTermList] : [...searchedBizTermList]
            , businessTerms = bizTermClone?.map(v => v?.business_term) ?? []


        let podDescriptions = {}
        if (isUpdateView) {
            const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
            podData.column_detail.forEach(v => {
                let temp = {}
                temp['column_datatype'] = v.column_datatype
                // temp['column_datatype'] = v.column_datatype.includes('(') ? v.column_datatype.split('(')[0] : v.column_datatype
                temp['custom_datatype'] = v?.custom_datatype ?? ''
                // temp['custom_datatype'] = v.column_datatype.includes('(') ? v.column_datatype.split('(')[1].split('').filter(v => v !== ')').join('') : ''
                temp['column_description'] = v.column_description
                temp['column_name'] = v.column_name
                temp['pii'] = v.pii
                temp['data_dict'] = v.data_dict
                podDescriptions[v.column_source_name !== null ? v.column_source_name.toLocaleLowerCase() : v.column_name.toLocaleLowerCase()] = temp
                return podDescriptions
            })
        }

        const getFieldData = (srcName, field, index, header = '') => {
            srcName = srcName.toLocaleLowerCase()
            if (podDescriptions?.[srcName]?.[field] !== undefined) {
                if (field === 'column_datatype') {
                    return podDescriptions?.[srcName]?.[field]?.split('(')?.[0]
                }
                else if (field === 'custom_datatype') {
                    if (podDescriptions?.[srcName]?.['column_datatype']?.includes('(') && podDescriptions?.[srcName]?.[field] === "")
                        return podDescriptions?.[srcName]?.['column_datatype']?.split('(')?.[1]?.split(')')?.filter(v => v !== '')[0]
                }
                return podDescriptions?.[srcName]?.[field]
            }
            else {
                if (field === 'pii') return false
                if (field === 'column_datatype') {
                    if(techBizDatatypeMap[srcName]?.column_datatype) {
                        return techBizDatatypeMap[srcName]?.column_datatype 
                    }
                    return dataTypes[index]?.includes('(') ? dataTypes[index]?.split('(')?.[0] : dataTypes[index]
                }
                if (field === 'column_name') return header
                if (field === 'data_dict') return header
                if (field === 'custom_datatype')
                    return (() => {
                        if(techBizDatatypeMap[srcName]?.custom_datatype && techBizDatatypeMap[srcName]?.custom_datatype !== "") {
                            let val = techBizDatatypeMap[srcName]?.custom_datatype
                            let returnVal = val
                            if(val?.includes('(')) {
                                returnVal = val?.split('(')?.[1]?.split(')')?.filter(v => v !== '')[0]
                            }
                            return returnVal
                        }
                        else if(techBizDatatypeMap[srcName]?.column_datatype) {
                            if (techBizDatatypeMap[srcName]?.column_datatype?.includes('(')) {
                                return techBizDatatypeMap[srcName]?.column_datatype?.split('(')?.[1]?.split(')')?.filter(v => v !== '')[0]
                            }
                            else {
                                return ''
                            }
                        }
                        else if (dataTypes[index]?.includes('(')) { // example: decimal(12,3)
                            return dataTypes[index]?.split('(')?.[1]?.split(')')?.filter(v => v !== '')[0]
                        }
                        return ''
                    })()
                else return ''
            }
        }

        headers.forEach((header, index) => {
            columnIndex.current[`${header.toLocaleLowerCase()}`] = index;
            let validTechTerm = originalHeaders[index]?.toString()
            let currBusTerm;
            if (isDataCatalog) {
                currBusTerm = businessTermList.find(itm => {
                    if (techBizMap[header] && techBizMap[header].id) {
                        return itm.id === techBizMap[header].id
                    }
                    else {
                        return itm.business_term.toLocaleLowerCase() === header.toLocaleLowerCase()
                    }
                })
            }
            else {
                currBusTerm = searchedBizTermList.find(itm => {
                    let bt = techBizMap[validTechTerm] ?? techBizMap[validTechTerm.toLowerCase()] ?? header.toLocaleLowerCase()
                    return itm?.business_term?.toLocaleLowerCase() === bt.toLocaleLowerCase()
                })
            }
            let currAddField = currBusTerm?.add_field?.reduce((a, c) => ({ ...a, [c.name]: c.value }), {}) ?? {}
            let rowChecked = disableCheckbox
                ? true
                : (isUpdateView && !connectionChanged)
                    ? Object.keys(podDescriptions).map(v => v.toLocaleLowerCase()).includes(validTechTerm?.toLowerCase())
                    : true
            if (isUpdateView) {
                searchedBizTermList = _.uniqBy([
                    ...searchedBizTermList,
                    ...businessTermList
                ], 'id')
                currBusTerm = searchedBizTermList.find(term => term.business_term.toLocaleLowerCase() === getFieldData(validTechTerm, 'column_name', index, header).toLocaleLowerCase())


                /***********Code To Handle data_dict: null***********/
                const currentPodColDetails = podDescriptions?.[validTechTerm?.toLowerCase()]
                if (currentPodColDetails?.data_dict === null) {
                    currBusTerm = {
                        business_term: currentPodColDetails?.column_name,
                        description: currentPodColDetails?.column_description,
                    }
                }

            }
            // console.log({t: techBizDatatypeMap[validTechTerm]?.column_datatype})
            let tech_term = isDataCatalog ? techBizMap[header].tech_term.toLocaleLowerCase() : validTechTerm.toLocaleLowerCase()
            let values = {
                technicalTerm: tech_term,
                // technicalTerm: isDataCatalog ? techBizMap[header].tech_term.toLocaleLowerCase() : validTechTerm.toLocaleLowerCase(),
                businessTerm:
                    (isDataCatalog || isUpdateView)
                        ? currBusTerm?.business_term ?? header.toLocaleLowerCase()
                        :
                        techBizMap[validTechTerm] ?? businessTerms.find(itm => itm.toLocaleLowerCase() === header.toLocaleLowerCase()) ?? header.toLocaleLowerCase() ?? '',
                classifications: currBusTerm?.classification_detail?.map(v => v.name) ?? [],
                category: currBusTerm?.category_detail?.name ?? '',
                dataOwners: currBusTerm?.owner_steward_detail?.owner?.map(v => `${v.first_name} ${v.last_name}`) ?? '',
                dataStewards: currBusTerm?.owner_steward_detail?.steward?.map(v => `${v.first_name} ${v.last_name}`) ?? '',
                description: currBusTerm?.description ?? toTitleCase(header).replaceAll('_', ' ') ?? '',
                optional: isUpdateView
                    ? getFieldData(validTechTerm, 'custom_datatype', index, header)
                    : (() => {
                        if(techBizDatatypeMap[validTechTerm]?.custom_datatype && techBizDatatypeMap[validTechTerm]?.custom_datatype !== "") {
                            let val = techBizDatatypeMap[validTechTerm]?.custom_datatype
                            let returnVal = val
                            if(val?.includes('(')) {
                                returnVal = val?.split('(')?.[1]?.split(')')?.filter(v => v !== '')[0]
                            }
                            return returnVal
                        }
                        else if (techBizDatatypeMap[validTechTerm]?.column_datatype) {

                            if (techBizDatatypeMap[validTechTerm]?.column_datatype?.includes('(')) {
                                return techBizDatatypeMap[validTechTerm]?.column_datatype?.split('(')?.[1]?.split(')')?.filter(v => v !== '')[0]
                            }
                            else {
                                return ''
                            }

                        }
                        else if (dataTypes[index]?.includes('(')) { // example: decimal(12,3)
                            return dataTypes[index]?.split('(')?.[1]?.split(')')?.filter(v => v !== '')[0]
                        }
                        return ''
                    })(),
                isRowSelected: rowChecked,
                dataType: isUpdateView
                    ? getFieldData(validTechTerm, 'column_datatype', index, header)
                    : 
                        techBizDatatypeMap[validTechTerm]?.column_datatype
                        ? techBizDatatypeMap[validTechTerm]?.column_datatype 
                        : dataTypes[index]?.includes('(') ? dataTypes[index]?.split('(')?.[0] : dataTypes[index]
                    ,
                // : dataTypes[index],
                outputRegex: outputRegex?.find(v => v?.column_source_name?.toLowerCase() === tech_term) ?? {},
                status: currBusTerm?.status ?? 'ACTIVE',
                pii: currBusTerm?.pii
            }
            // console.log('__cpsv',{index, values})
            schemaTableData.push({
                __custom_row_id: index,
                curr_bizterm_id: currBusTerm?.id ?? null,
                // curr_category_id: currBusTerm?.category_detail?.id ?? null,
                // curr_classifications_id: currBusTerm?.classification_detail?.map(v => ({classification: v?.id})) ?? [],
                // sixdots: <SIXDOTS></SIXDOTS>,
                is_row_selected: values.isRowSelected ?? true,
                business_term: values.businessTerm ?? header.toLocaleLowerCase(),
                technical_term: values.technicalTerm,
                datatype: values.dataType,
                source_datatype: dataTypes[index],
                description: values.description,
                classifications: values.classifications,
                category: values.category,
                data_owners: values.dataOwners,
                data_stewards: values.dataStewards,
                optional: values.optional,
                output_regex: values.outputRegex,
                status: values?.status,
                pii: values?.pii,
                ...{ ...addFields, ...currAddField }
            });

            // console.log('__cpsv', {schemaTableData})
            schemaTableDataPayload = generateSchemaTablePayload(schemaTableData)
        });

        // console.log({schemaTableData})
        let selectedClasses = []
        let isAutoClassify = reduxData?.tableData?.result?.autoClassify
        if (isAutoClassify) {
            schemaTableData = schemaTableData?.map(d => (
                d?.curr_bizterm_id === null
                    ?
                    {
                        ...d,
                        classifications: (() => {
                            let classes = outputRegex?.find(o => o?.tech_term === d?.technical_term)?.class_detail
                            selectedClasses = [...selectedClasses, ...classes]
                            return classes
                        })()
                    }
                    : { ...d }
            ))
        }

        schemaTableData?.forEach(v => {
            if (v?.output_regex?.has_classify_match) {
                let classes = v?.output_regex?.class_detail
                selectedClasses = [...selectedClasses, ...classes]
            }
        })
        // console.log({selectedClasses})

        setSelectedSingleClassifier(uniqBy(selectedClasses, 'id')?.[0] ?? null)

        setSelectedClassificationsFilter(uniqBy(selectedClasses, 'id'))

        let nonExistTerm = [...schemaTableData]
        // let nonExistTerm = schemaTableData?.filter(t => t?.curr_bizterm_id === null || t?.curr_bizterm_id === undefined)
        nonExistTerm = nonExistTerm?.reduce((prev, curr) => {
            return {
                ...prev,
                [curr?.__custom_row_id]: { ...curr }
            }
        }, {})
        // console.log({nonExistTerm})
        setUnsavedDataObj({ ...nonExistTerm })

        podData.schemaTableData = schemaTableData;
        podData.schemaTableDataPayload = [...schemaTableDataPayload];

        podData['schemaUUID'] = schemaUUID

        dispatch(setPodCreationData(podData));
        // console.log({schemaTableData})
    };

    const generateSchemaTablePayload = (schData) => {
        if (isDataCatalog) return []
        let podReduxData, podData, outputRegex

        let clone = [...schData]

        podReduxData = store.getState().DataCatalogue.PodCreationData
        podData = cloneDeep(podReduxData)
        outputRegex = podData?.['output_regex'] ?? []

        clone = clone.filter(item => item.is_row_selected === true)
        let schemaPayload = clone.map(item => (
            {
                column_datatype: item.datatype,
                column_source_datatype: item.source_datatype,
                custom_datatype: item.optional,
                column_source_name: item.technical_term,
                column_name: item.business_term,
                // column_name: correctBusinessTerm(item.business_term),
                pii: false,
                column_description: item.description,
                data_dict: item?.curr_bizterm_id ?? null,
                // data_dict: getBusinessTermId(item.business_term),
                regex: outputRegex?.find(v => v.column_source_name.toLowerCase() === item.technical_term.toLowerCase())?.regex ?? null
            }
        ))
        //console.log('__cpsv', {schemaPayload})
        return schemaPayload
    }

    const nonResetColumns = ['technical_term', 'business_term', 'datatype', 'optional', 'description', 'sixdots', 'data_owners', 'data_stewards']

    const generateColumns = (columns_list) => {
        let cols = [], colsListCopy = [...columns_list]
        const colWid = {
            business_term: '25%' ?? 350,
            technical_term: '20%' ?? 250,
            optional: 100,
            // description: '25%',
            category: 160,
            classifications: 210,
        }
        cols = colsListCopy.filter(v => v.visible === true).map(v => {
            return {
                name: <div className='align-items-center d-flex new-schma-head'>
                    <Tooltip title={v.key === 'technical_term' || v.key === 'optional'|| v.key === 'business_term' ? '' : v.title} placement='topLeft'>
                        <span className={`font-w-600 grey-color label mb-0 paragraph text-with-ellipsis`}>
                            {
                                (v.key === 'business_term' && !isDataCatalog && !isReadOnly && !disableCheckbox)
                                    ?
                                    <span>
                                        <Tooltip onClick={(e) => { e.stopPropagation() }} title={`${selectAll ? 'Uns' : 'S'}elect All`} placement="bottomLeft">
                                            <Checkbox checked={selectAll} onChange={(e) => { e?.stopPropagation(); onSelectAllHandler(e) }} className='mr-2' />
                                        </Tooltip>
                                    </span>
                                    : ''
                            }
                            {
                                v.key === 'business_term' ? <span style={{ marginLeft: '0px' }}>{v.title}</span> : v.title
                            }

                            {v.isRequired ? <span></span> : ''}
                        </span>
                    </Tooltip>
                    {
                        v.key === 'business_term' && !isDataCatalog
                            ? <Popover
                                placement="top"
                                content={
                                    <div className='py-2 px-3'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <span className='d-block mr-1 rounded' style={{ width: '1rem', height: '1rem', background: '#d3f9e3', border: 'solid 1px #03dc62' }}></span>
                                            <label className='mb-0 paragraph'>- Existing Display Names</label>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <span className='d-block mr-1 rounded' style={{ width: '1rem', height: '1rem', background: '#fcf4b0', border: 'solid 1px #fcf4b0' }}></span>
                                            <label className='mb-0 paragraph'>- Non Existing Display Names</label>
                                        </div>
                                    </div>
                                }
                                trigger={'hover'}
                            >
                                {/* <span className=" text-black-50 cursor-pointer ">  <ICON_GUIDE height='12px' width='12px'></ICON_GUIDE></span> */}
                                <i className="bx bx-help-circle m-1 cursor-pointer text-black-50"></i>
                            </Popover> : ''
                    }
                    {
                        v.key === 'optional'
                            ?
                            //  <Popover
                            //     placement="top"
                            //     content={
                            //         schemaTableOptionalFieldHelpContent()
                            //     }
                            //     trigger={'hover'}
                            // >
                            // </Popover>
                            <span onClick={() => setopenformatHelpModal(true)} className="m-1 text-black-50 cursor-pointer ">  <ICON_GUIDE height='10px' color='#2c83c4' width='10px'></ICON_GUIDE></span>
                            // <i className="bx bx-info-circle m-1 text-black-50 cursor-pointer color-primary" onClick={() => setopenformatHelpModal(true)}></i>
                            : !nonResetColumns.includes(v.key) && !isDataCatalog && !isReadOnly
                                ? <Tooltip
                                    placement="right"
                                    title={`Reset All ${v.title}`}
                                    trigger={'hover'}
                                >
                                    <i className="reset-all-biz-attr bx bx-reset m-1 text-black-50" onClick={() => resetAllFields(v.key)} style={{ cursor: 'pointer' }}></i>
                                </Tooltip>
                                : ''
                    }
                    {/* {
                        v.key === 'sixdots'
                            ? <div style={{ background: "white" }}></div>
                            : ''
                    } */}
                </div>
                ,
                key: v.key,
                sortable: false,
                resizable: false,
                width:
                    /**
                     * Commenting For Future use
                     */
                    // colsListCopy.filter(v => v.visible === true).length === 6 
                    // ? (() => {
                    //     /**
                    //      * Calculating Height Manually
                    //      */
                    //     let el = document.querySelector('.crt-pod-schm-wrap')
                    //     if(el === null || el === undefined) {
                    //         return colWid[v.key] ?? undefined
                    //     }
                    //     // console.log({el})
                    //     const clientWidth = el?.clientWidth
                    //     console.log({clientWidth})
                    //     if(v.key === 'status' || v.key === 'pii') {
                    //         return 150
                    //     }
                    //     else if(v.key === 'category' || v.key === 'classifications') {
                    //         return 200
                    //     }
                    //     else if(v.key === 'business_term') {
                    //         return Number(((25 / 100) * clientWidth).toFixed(0))
                    //     }
                    //     else if(v.key === 'description') {
                    //         console.log({d:(clientWidth - (700 + ((25 / 100) * clientWidth))).toFixed(0)})
                    //         return Number((clientWidth - (700 + ((25 / 100) * clientWidth))).toFixed(0))
                    //     }
                    //     return Number((clientWidth/colsListCopy.filter(v => v.visible === true)?.length).toFixed(0))
                    //   })()
                    // : 
                    colWid[v.key] ?? undefined,
                frozen: v.frozen ?? false,
                ...v
            }
        })

        return cols
    }

    let currentClickedIndex = useRef([])
    const adddropdownindex = (index) => {
        currentClickedIndex.current.push(index);
    }
    const removedropdownindex = (index) => {
        currentClickedIndex.current = currentClickedIndex.current.filter((item) => item !== index)
    }

    const getDataTypeList = (selectedValue, index, __id) => {
        return (
            <div className='data-type-list'>

                <List
                    size='small'
                    itemLayout="horizontal"
                    dataSource={getSelectOptions('datatype')}
                    renderItem={(ele) => (
                        <List.Item className='schma-hover-dtype-list' style={ele.value === selectedValue ? { background: "#d2e7f7" } : {}} onClick={(e) => {
                            e.stopPropagation()
                            onInputChangeHandler('datatype', ele.value, index, __id); setVisible(false)
                        }}>
                            <List.Item.Meta
                                avatar={<span className="element-type">
                                    {getColumnDataType(ele.Type)}
                                </span>}
                                title={<Tooltip title={ele.Name}> <span className="element-name ml-1 mt-1">{ele.Name}</span>{" "}</Tooltip>}

                            />
                        </List.Item>
                    )}
                />
            </div>
        );
    }

    const setBusinessTermRedux = (value) => {
        let termList = isUpdateView ? [...businessTermList] : [...searchedBizTermRef.current]
        if (termList?.length) {
            let term = termList.find((item) => correctBusinessTerm(item.business_term) === correctBusinessTerm(value));
            if (term && term.id) {
                dispatch(
                    { type: SET_SELECTED_BUSINESS_TERM, payload: term }
                )
            }
        }
    }

    const getFieldTemplate = (key, index, value = '', data) => {
        // const isDisabled = data?.['business_term']===toTitleCase(data?.['technical_term']??'')??false
        // console.log({data})
        const isBizTermAttrs = isUpdateView
            ? data?.curr_bizterm_id === null
                ? false
                : isValidBusinessTerm(data?.['business_term']) ?? false
            : isValidBusinessTerm(data?.['business_term']) ?? false
        const fieldVisiblity = isBizTermAttrs || isDataCatalog || isReadOnly
        const isRowUnselected = !data['is_row_selected']
        let classificationName = []
        if (key === 'classifications') {
            classificationName = value.map((item) => item.name ?? item)?.slice(1) ?? []
        }

        if(data?.copilotFetching) {
            return <div className='d-flex flex-column justify-content-center h-100'>
                <CustomSkeleton height='1.5rem' width='100%' isActive={true} />
            </div>
        }

        switch (key) {
            // case 'sixdots':
            //     return <span className='cursor'><SIXDOTS /></span>
            case 'status':
                // const isAnyStatusEmpty = schemaTableData?.some(t => t?.status === '' || !t?.status)
                return <div className='align-items-center d-flex h-100 status-wrap'>
                    {
                        isBizTermAttrs
                            ?
                            <div className='d-flex text-uppercase fontInterSemiBold px-2 py-1 rounded'
                                style={{
                                    background: status_color_map[value?.toLowerCase()]?.bg,
                                    color: status_color_map[value?.toLowerCase()]?.text,
                                    lineHeight: 1.4,
                                    fontSize: 9
                                }}>
                                {value}
                            </div>
                            :
                            <>
                                <Select
                                    disabled={fieldVisiblity}
                                    size='small'
                                    showSearch={false}
                                    optionFilterProp="children"
                                    value={value?.toLowerCase()}
                                    onChange={(val) => {
                                        onInputChangeHandler('status', val, index, data.__custom_row_id)
                                    }}
                                    filterOption={(input, option) =>
                                        option.value?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.value.toString().toLowerCase().localeCompare(optionB.value.toString().toLowerCase())
                                    }
                                >
                                    {
                                        statusOptions?.map((ele, index) => (
                                            <Select.Option key={index} value={ele.value ? ele.value : ele.Name}>
                                                <div className='align-items-center option-select-customized d-flex w-100 px-1'>
                                                    <span className='sphere' style={{ background: ele?.color }}></span>
                                                    <span className='small ml-2 dict-color-dark'>{ele.Name}</span>
                                                </div>
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                                {
                                    (!isDataCatalog || !isReadOnly) // && isAnyStatusEmpty
                                        ?
                                        <Buttons
                                            props={{
                                                tooltipPlacement: 'right',
                                                tooltip: 'Apply to all new Display Names',
                                                buttonText: '',
                                                buttonClassName: "apall-btn custom-btn custom-btn-outline ml-1",
                                                buttonEvent: (e) => {
                                                    e.stopPropagation()
                                                    if (isDataCatalog || isReadOnly) return;
                                                    onApplyStatus(value)
                                                },
                                                ImgSrc: () => <i className='bx bx-check-double'></i>,
                                                isDisable: false,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        />
                                        : ''
                                }
                            </>
                    }
                </div>
            case 'pii':

                return <div className='d-flex align-items-center h-100'>
                    {
                        // isBizTermAttrs ?
                            value
                                ?
                                <div className='d-flex align-items-center'>
                                    <ContainsPII />
                                </div>
                                : <div className='text-black-50 small'>N/A</div>
                            // :
                            // <Switch
                            //     // size={'small'}
                            //     className='custom-switch-btn danger'
                            //     checkedChildren="Yes" unCheckedChildren="No"
                            //     checked={value}
                            //     onChange={(val) => {
                            //         onInputChangeHandler('pii', val, index, data.__custom_row_id)
                            //     }}
                            //     disabled={fieldVisiblity}
                            // />
                    }
                </div>
            case 'technical_term':
                return <>
                    <div className={`${isRowUnselected ? 'row-unchoosed' : ''} d-flex align-items-center `}>
                        {(!isDataCatalog && !isReadOnly) ? <Popover
                            placement="topLeft"
                            content={
                                getDataTypeList(data['datatype'], index, data.__custom_row_id)
                            }
                            // onVisibleChange={(visible) => setVisible(visible)}
                            // visible={visible}
                            trigger={'hover'}
                        >
                            <Tooltip title={'' ?? data['datatype']}> <span className='mr-2 cursor-pointer icon-box' >{getColumnDataType(data['datatype'])}</span></Tooltip>
                        </Popover> :

                            <Tooltip title={'' ?? data['datatype']}> <span className='mr-2 cursor-pointer icon-box' >{getColumnDataType(data['datatype'])}</span></Tooltip>

                        }
                        <Tooltip title={value} placement='topLeft'>
                            <label className='mb-0 paragraph text-with-ellipsis'>
                                <Highlighter searchWords={[searchValue?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value}>

                                    {value}
                                </Highlighter>
                            </label>
                        </Tooltip>
                    </div>
                </>
            case 'classifications':
                const isAnyClassifierEmpty = schemaTableData?.some(t => t?.classifications?.length === 0)
                return <>
                    <div className={`${isRowUnselected ? 'row-unchoosed' : ''} d-flex align-items-center h-100`} style={{ flex: '0 0 100%' }}>


                        <div className={`tag-list clsf m-0 w-100 ${(!isDataCatalog && !isReadOnly) ? 'dic-apall-wrap' : ''}`} style={{ border: "none" }}>
                            {value.length ?
                                <React.Fragment>

                                    {value.map((tag, index) => {
                                        return index < 1 ? <div key={index} className={`classificationTag cont font-w-600`}>

                                            <Tooltip placement="topLeft" title={tag.name ? tag?.name?.toUpperCase() : tag}><div className="text-elipse">{`${tag.name ? tag?.name?.slice(0, 10)?.toUpperCase() : tag.toUpperCase()} ${tag?.name?.length > 10 || tag?.length > 10 ? '...' : ''} `}</div></Tooltip>

                                        </div> : <></>
                                    })
                                    }
                                    {value.length > 1 ? <div className="classificationTag font-w-600 ml-1 text-center px-2">

                                        <Tooltip title={classificationName.toString()}> <span className="text-with-ellipsis">{`+${value.length - 1}`}</span></Tooltip>

                                    </div> : null}

                                    <div className='d-flex align-items-center'>
                                        {
                                            !fieldVisiblity
                                                ? <Buttons
                                                    props={{
                                                        buttonText: '',
                                                        buttonClassName: "edt-btn custom-btn custom-btn-outline ml-1 w-100",
                                                        buttonEvent: (e) => {
                                                            e.stopPropagation()
                                                            categoryIndexRef.current = index
                                                            setCurrRowId(data?.__custom_row_id)
                                                            activeClassifier.current = data?.classifications?.[0]
                                                            setSelectedSingleClassifier(data?.classifications?.[0])
                                                            // setSelectedClassificationsFilter(data?.classifications)
                                                            setOpenClassificationModal(true);
                                                        },
                                                        ImgSrc: () => <i className='bx bx-pencil'></i>,
                                                        isDisable: fieldVisiblity,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                    }}
                                                />
                                                : ''
                                        }
                                        {
                                            (!isDataCatalog || !isReadOnly) && isAnyClassifierEmpty
                                                // (!isDataCatalog || !isReadOnly) && !fieldVisiblity // To Disable For Valid Data
                                                ?
                                                <Buttons
                                                    props={{
                                                        tooltipPlacement: 'right',
                                                        tooltip: 'Apply to all new Display Names',
                                                        buttonText: '',
                                                        buttonClassName: "apall-btn custom-btn custom-btn-outline ml-1",
                                                        buttonEvent: (e) => {
                                                            e.stopPropagation()
                                                            if (isDataCatalog || isReadOnly) return;
                                                            onApplyClassification(value.map(v => v.name ?? v))
                                                        },
                                                        ImgSrc: () => <i className='bx bx-check-double'></i>,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                    }}
                                                />
                                                : ''
                                        }
                                    </div>
                                </React.Fragment> :
                                fieldVisiblity
                                    ? <p className='text-black-50 mb-0 small pl-2' style={{ lineHeight: 1.4 }}>N/A</p>
                                    :
                                    <div className='col-12 m-0 p-0'>
                                        <Buttons
                                            props={{
                                                buttonText: fieldVisiblity ? 'N/A' : 'Select',
                                                buttonClassName: "custom-btn custom-btn-classification select  w-100",
                                                buttonEvent: (e) => {
                                                    e.stopPropagation()
                                                    categoryIndexRef.current = index
                                                    setCurrRowId(data.__custom_row_id)
                                                    setOpenClassificationModal(true);

                                                },
                                                ImgSrc: () => fieldVisiblity ? <></> : <PLUS />,
                                                isDisable: fieldVisiblity,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        /></div>

                            }
                        </div>
                    </div>
                </>


            case 'business_term':
                return <div onKeyDown={(e) => e.stopPropagation()}
                    className={`${data?.output_regex?.has_classify_match ? 'has-match-cell' : ''} 
                            ${isRowUnselected ? 'row-unchoosed' : ''} 
                            d-flex h-100 align-items-center borderless 
                            ${isBizTermAttrs ? 'success' : 'warning'}
                            ${isRowReorder && !isReadOnly ? 'cursor' : ''}
                        `}>


                            {
                                data?.status?.toLowerCase() === "archived"
                                ? <span title='Archived Display Name' className='arch-indicator'>
                                    <ICON_ARCHIVED color='currentColor' height='10' width='10' />
                                    </span>
                                : ''
                            }
                    {
                        !(isDataCatalog || isReadOnly || disableCheckbox)
                            ? <div className='d-flex align-items-center '>
                                <Checkbox
                                    className='mr-2'
                                    checked={data?.['is_row_selected'] ?? false}
                                    // onClick={(e) => {
                                    //     e.stopPropagation()
                                    // }}
                                    onChange={(e) => {
                                        // e.stopPropagation()
                                        onCheckHandler(e, index, data?.__custom_row_id)
                                    }}
                                />
                            </div>
                            : <Tooltip placement="bottom" title="View Display Name Details">
                                <button className="custom-btn-outline  mr-2 bg-transparent  custom-btn btn-with-icon"
                                    onClick={(e) => {
                                        onRowClick(data, index)
                                    }
                                    }
                                >
                                    <REDIRECTJOBS />
                                </button>
                            </Tooltip>
                    }

                    {/* <Tooltip title={ '' ?? data['datatype']}> */}
                    <Popover
                        destroyTooltipOnHide
                        placement="bottomLeft"
                        title={
                            isReadOnly ? <></>
                                :
                                <>
                                    <div className='d-flex align-items-center justify-content-between px-2 py-2 fontSizeLabel fontInterSemiBold'>
                                        <span>Datatypes</span>

                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                                e?.stopPropagation()
                                                setopenformatHelpModal(true)
                                            }}
                                        >
                                            <ICON_GUIDE height='12px' color='#2c83c4' width='12px'></ICON_GUIDE>
                                        </span>
                                    </div>
                                </>
                        }
                        content={
                            isReadOnly ? <></>
                                :
                                <>
                                    <div className='dtype-li-wrap'>
                                        {
                                            getSelectOptions('datatype')?.map((d, i) => (
                                                <div key={`dtype-li-${i}`}
                                                    className={`cursor-pointer dtype-li-itm px-2 py-2 ${d?.Type === data?.datatype ? 'active' : ''}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (d?.Type === data?.datatype) return
                                                        onInputChangeHandler('datatype', d?.Type, index, data?.__custom_row_id)
                                                    }}
                                                >
                                                    <div className='fontSizeLabel fontInterSemiBold d-flex align-items-center'>
                                                        <span className='dtype-ic mr-1'>
                                                            {getColumnDataType(d?.Type)}
                                                        </span>
                                                        {d?.Name}
                                                    </div>
                                                    <div>
                                                        {
                                                            !Object.keys(formatPlaceholderMap).includes(d?.Type) ? ''
                                                                :
                                                                d?.Type !== data?.datatype ? ''
                                                                    :
                                                                    <DebounceInput
                                                                        placeholder={formatPlaceholderMap[d?.Type] ?? 'Enter Format'}
                                                                        type="text"
                                                                        // fieldValue={value}
                                                                        defaultValue={data?.optional ?? ''}
                                                                        onDebounce={(textVal) => {
                                                                            let schData = store.getState().DataCatalogue.PodCreationData.schemaTableData

                                                                            let clone_obj = schData.find(v => v.__custom_row_id === data.__custom_row_id)
                                                                            clone_obj['optional'] = textVal
                                                                            if (
                                                                                clone_obj['datatype'] === 'boolean'
                                                                                || !Object.keys(formatPlaceholderMap).includes(clone_obj['datatype'])
                                                                            ) {
                                                                                clone_obj['optional'] = ''
                                                                            }
                                                                            schData = schData.map(v => v.__custom_row_id === data.__custom_row_id ? ({ ...clone_obj }) : ({ ...v }))
                                                                            if (clone_obj?.curr_bizterm_id === null || clone_obj?.curr_bizterm_id === undefined) {
                                                                                setUnsavedDataObj(prev => {
                                                                                    prev[clone_obj?.__custom_row_id] = { ...clone_obj }
                                                                                    return prev
                                                                                })
                                                                            }
                                                                            setSchemaTableData([...schData])
                                                                        }}
                                                                        className={`custom-input-field border text-with-ellipsis sch-opt-field`}
                                                                        readOnly={(isDataCatalog || isReadOnly)}
                                                                    />
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                        }>
                        <span className='mr-2 cursor-pointer icon-box dtype-ic-bx' >
                            {
                                (getColumnDataType(data['datatype']) === "") ?
                                    <span
                                        className='d-flex align-items-center justify-content-center p-1 border rounded text-danger'
                                    >
                                        <ICON_ALERT height='14' width='14' color='currentColor' />
                                    </span>
                                    : getColumnDataType(data['datatype'])
                            }
                        </span>
                    </Popover>
                    {/* </Tooltip> */}

                    <div className='tag-list' style={{ border: "none" }}>
                        <div className='d-flex align-items-center' style={{ flex: '0 0 100%' }}>
                            <div className={`w-100 ${data?.copilotPopulated ? 'auto-classified-inp' : ''}`}>
                                <Tooltip className='d-flex' placement="topLeft" title={correctBusinessTerm(value).toUpperCase()}> {
                                    (isInputReady)
                                        ?
                                        <>
                                            <BusinessTermInput
                                                placeholder={''}
                                                type="text"
                                                style={(isDataCatalog || isReadOnly)
                                                    ? { backgroundColor: 'transparent', color: '#17171A', fontSize: '10px', fontWeight: "500", padding: '10px 0' } : {}}
                                                className={`text-uppercase text-with-ellipsis 
                                            ${isBizTermAttrs ? "businesstermTag" : 'warning_businessTerm'}`
                                                }
                                                defaultValue={correctBusinessTerm(value).toUpperCase() ?? ''}
                                                onPause={(val, dictDetails) => {
                                                    let isDictFound = getObjectLength(dictDetails) > 0 && dictDetails?.business_term === val

                                                    let storedUnsaved = unsavedDataObj[data?.__custom_row_id] ?? {}

                                                    let dictionarDetails = isDictFound
                                                        ? dictDetails
                                                        : { ...storedUnsaved, business_term: val }
                                                    if (!isDictFound) {
                                                        dictionarDetails = {
                                                            ...dictionarDetails,
                                                            category_detail: {
                                                                id: getCategoryId(dictionarDetails?.category),
                                                                name: dictionarDetails?.category
                                                            },
                                                            classification_detail: (() => {
                                                                if (dictionarDetails?.classifications?.length > 0) {
                                                                    if (typeof dictionarDetails?.classifications?.[0] === 'string') {
                                                                        return dictionarDetails?.classifications?.map(c => (
                                                                            {
                                                                                id: getClassificationId(c),
                                                                                name: c
                                                                            }
                                                                        ))
                                                                    }
                                                                    return dictionarDetails?.classifications
                                                                }
                                                                return []
                                                            })(),
                                                            owner_steward_detail: {
                                                                owner: dictionarDetails?.data_owners?.length > 0
                                                                    ?
                                                                    dictionarDetails?.data_owners?.map(o => ({ first_name: o?.split(' ')?.[0], last_name: o?.split(' ')?.[1] }))
                                                                    : [],
                                                                steward: dictionarDetails?.data_stewards?.length > 0
                                                                    ?
                                                                    dictionarDetails?.data_stewards?.map(o => ({ first_name: o?.split(' ')?.[0], last_name: o?.split(' ')?.[1] }))
                                                                    : []
                                                            }
                                                        }
                                                    }

                                                    onInputChangeHandler(
                                                        key, correctBusinessTerm(val).toLocaleLowerCase(), index, data.__custom_row_id, dictionarDetails
                                                    )
                                                }}
                                                disabled={(isDataCatalog || isReadOnly)}
                                                fieldValue={value}
                                                row={data}
                                            />
                                        </>
                                        : ''
                                }
                                </Tooltip>
                                {/* </Highlighter> */}
                            </div>{!(isDataCatalog || isReadOnly) ? <Buttons
                                props={{
                                    buttonText: '',
                                    buttonClassName: isBizTermAttrs ? "custom-btn-businessterm custom-btn  w-100 ml-1" : 'custom-btn-warning-businessterm custom-btn  w-100 ml-1',
                                    buttonEvent: (e) => {
                                        e.stopPropagation()
                                        categoryIndexRef.current = index
                                        setopenBusinessTermModal(true);
                                        setCurrRowId(data.__custom_row_id)
                                        setBusinessTermRedux(value)
                                        setPopulateSearchData(value)
                                    },
                                    isDisable: (isDataCatalog || isReadOnly),

                                    ImgSrc: () => <SEARCH height='10' width='10' />,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            /> : null}</div>

                        <Tooltip placement='topLeft' title={data?.technical_term}>
                            <div className='text-dark small text-with-ellipsis d-block' style={{ lineHeight: 1.4, paddingTop: 1, width: isReadOnly ? '100%' : 'calc(100% - 40px)' }}>
                                Technical Name: {data?.technical_term}
                            </div>
                        </Tooltip>
                    </div>

                </div>
            case 'category':
                const isAnyCategoryEmpty = schemaTableData?.some(t => t?.category === '' || !t?.category)
                return <>

                    <div className={`${isRowUnselected ? 'row-unchoosed' : ''} d-flex h-100 align-items-center`}>
                        {!value
                            ? <div className='col-12 m-0 p-0 '>
                                {
                                    fieldVisiblity
                                        ? <p className='text-black-50 mb-0 small pl-2' style={{ lineHeight: 1.4 }}>N/A</p>
                                        :
                                        <Buttons
                                            props={{
                                                buttonText: fieldVisiblity ? 'N/A' : 'Select',
                                                buttonClassName: "custom-btn-category select custom-btn w-100",
                                                buttonEvent: (e) => {
                                                    e.stopPropagation()
                                                    categoryIndexRef.current = index
                                                    setCurrRowId(data.__custom_row_id)
                                                    setOpenCategoryModal(true);

                                                },
                                                isDisable: fieldVisiblity,

                                                ImgSrc: () => fieldVisiblity ? <></> : <PLUS />,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        />
                                }
                            </div> : ''}
                        <div className={`tag-list m-0 w-100 ${(!isDataCatalog && !isReadOnly) ? 'dic-apall-wrap' : ''}`} style={{ border: "none" }}>
                            {
                                value
                                    ? <div className='d-flex align-items-center h-100' style={{ flex: '0 0 100%' }}>
                                        <div className={`categoryTag cont`}>
                                            <Tooltip className='d-block align-items-center' style={{ gap: '10px' }} placement="topLeft" title={value.toUpperCase()}>
                                                <div className="text-with-ellipsis">{`${value?.toUpperCase()}`}
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className='d-flex align-items-center'>

                                            {!fieldVisiblity
                                                ?
                                                <button
                                                    style={{ width: '24px', height: '24px' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        categoryIndexRef.current = index
                                                        activeCategory.current = { id: getCategoryId(data?.category), name: data?.category }
                                                        setCurrRowId(data?.__custom_row_id)
                                                        setOpenCategoryModal(true);

                                                    }}
                                                    className='custom-btn-outline rounded-circle custom-btn ml-1'
                                                    disabled={fieldVisiblity}
                                                >
                                                    <i className='bx bx-pencil'></i>
                                                </button>
                                                : ''
                                            }
                                            {
                                                (!isDataCatalog || !isReadOnly) && isAnyCategoryEmpty
                                                    //    (!isDataCatalog || !isReadOnly) && !fieldVisiblity // To Disable For Valid Data
                                                    ?
                                                    <Buttons
                                                        props={{
                                                            tooltipPlacement: 'right',
                                                            tooltip: 'Apply to all new Display Names',
                                                            buttonText: '',
                                                            buttonClassName: "apall-btn custom-btn custom-btn-outline ml-1",
                                                            buttonEvent: (e) => {
                                                                e.stopPropagation()
                                                                if (isDataCatalog || isReadOnly) return;
                                                                onApplyCategory(value)
                                                            },
                                                            ImgSrc: () => <i className='bx bx-check-double'></i>,
                                                            isDisable: false,
                                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                        }}
                                                    />
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                    : ''}

                        </div>

                    </div>
                </>
            case 'datatype':
                return <>
                    <div className={`${isRowUnselected ? 'row-unchoosed' : ''} d-flex h-100 align-items-center borderless`}>
                        <SingleSelection
                            props={{
                                placeholder: "Select Data Type",
                                dropDownValue: value,
                                onClickEvent: (val) => {
                                    onInputChangeHandler(key, val, index, data.__custom_row_id)
                                },
                                optionList: getSelectOptions(key),
                                isDisable: (isDataCatalog || isReadOnly),
                                selectClassName: 'w-100'
                            }}
                        />
                    </div>
                </>
            case 'optional':
            case 'description':
                return <>
                    <div className={`${isRowUnselected ? 'row-unchoosed' : ''} ${key === 'description' ? data?.copilotPopulated ? 'auto-classified-inp' : '' : ''}`} onKeyDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
                        {
                            isInputReady
                                ?
                                (data?.datatype === 'boolean' && key === 'optional')
                                    ? <p className='mb-0 paragraph text-black-50'>N/A</p>
                                    :
                                    <Tooltip title={value ?? ''} placement="topLeft">
                                        {isDataCatalog ? <p className="paragraph m-0" style={{ fontSize: "12px" }}>{value ?? 'No description has been given yet.'}</p> :
                                            // <Highlighter searchWords={[searchValue?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value}>
                                            (key === 'description' && fieldVisiblity)
                                                ?
                                                <Input
                                                    placeholder={'Description Not Provided'}
                                                    type="text"
                                                    value={value ?? ''}
                                                    onClick={e => e.stopPropagation()}
                                                    onChange={e => e.stopPropagation()}
                                                    className={`custom-input-field border text-with-ellipsis`}
                                                    readOnly={true}
                                                />
                                                :
                                                key === 'optional' && !Object.keys(formatPlaceholderMap).includes(data?.datatype) ? ''
                                                    :
                                                    <DebounceInput
                                                        placeholder={key === 'optional' ? formatPlaceholderMap[data?.datatype] ?? 'Enter Format' : key === 'description' ? 'Please Enter Description' : ''}
                                                        type="text"
                                                        // fieldValue={value}
                                                        defaultValue={value ?? ''}
                                                        onDebounce={(textVal) => {
                                                            let schData = store.getState().DataCatalogue.PodCreationData.schemaTableData

                                                            let clone_obj = schData.find(v => v.__custom_row_id === data.__custom_row_id)
                                                            clone_obj[key] = textVal
                                                            if (
                                                                clone_obj['datatype'] === 'boolean'
                                                                || !Object.keys(formatPlaceholderMap).includes(clone_obj['datatype'])
                                                            ) {
                                                                clone_obj['optional'] = ''
                                                            }
                                                            schData = schData.map(v => v.__custom_row_id === data.__custom_row_id ? ({ ...clone_obj }) : ({ ...v }))
                                                            if (clone_obj?.curr_bizterm_id === null || clone_obj?.curr_bizterm_id === undefined) {
                                                                setUnsavedDataObj(prev => {
                                                                    prev[clone_obj?.__custom_row_id] = { ...clone_obj }
                                                                    return prev
                                                                })
                                                            }
                                                            setSchemaTableData([...schData])
                                                        }}
                                                        className={`custom-input-field border text-with-ellipsis ${key === 'optional' ? 'sch-opt-field' : ''}`}
                                                        readOnly={key === 'description' ? fieldVisiblity : (isDataCatalog || isReadOnly)}
                                                    />
                                        }
                                    </Tooltip>
                                : ''
                        }
                    </div>
                </>
            case 'data_owners':
            case 'data_stewards':
                return <>
                    <div className={`${isRowUnselected ? 'row-unchoosed' : ''} d-flex paragraph schema-table-avatar h-100 align-items-center`}>
                        {_.isEmpty(value) ? <div style={{ paddingLeft: "3px", lineHeight: 1 }}><label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span >{key === 'data_owners' ? "No Owners" : "No Stewards"}</span></label></div> : ''}

                        {isArray(value) && value.length ? <Avatar.Group maxCount={2}>
                            {value.map((name, i) => {
                                return (

                                    <Tooltip key={`avt-${i}-${name}`} placement='topLeft' title={toTitleCase(name)}>
                                        <Avatar style={{
                                            backgroundColor: "#2c83c4",
                                            color: "white"
                                        }}>{name[0].toUpperCase()}</Avatar>

                                    </Tooltip>


                                );
                            })}
                        </Avatar.Group> : ''}
                    </div>
                </>
            default: {
                let obj = colsList.find(item => item.key === key)
                switch (obj?.type ?? '') {
                    case 'TEXT':
                    case 'DATE':
                    case 'NUMERIC':
                        return <>
                            {
                                isInputReady
                                    ?
                                    <div onKeyDown={(e) => e.stopPropagation()} className={`${isRowUnselected ? 'row-unchoosed' : ''} option-select-customized d-flex align-items-center h-100`}>
                                        <Input
                                            type={obj?.type === 'NUMERIC' ? 'number' : obj?.type?.toLocaleLowerCase() ?? 'text'}
                                            defaultValue={value ?? ''}
                                            onClick={(e) => e.stopPropagation()}
                                            onBlur={(e) => onInputChangeHandler(key, e.target.value, index, data.__custom_row_id)}
                                            className={`custom-input-field border`}
                                            disabled={fieldVisiblity}
                                            placeholder={!fieldVisiblity ? `Enter ${obj?.title?.toLowerCase() ?? 'value'}` : ''}
                                        />
                                        {
                                            ((!isDataCatalog || !isReadOnly) && !fieldVisiblity)
                                                ?
                                                <Tooltip title='Apply to all new Display Names' placement='right'>
                                                    <i className='bx bxs-checkbox-checked ml-1' onClick={() => onApplyAddFields(key, value)} ></i>
                                                </Tooltip>
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }
                        </>
                    case 'TOGGLE':
                        return <div className={`${isRowUnselected ? 'row-unchoosed' : ''} option-select-customized d-flex align-items-center h-100`} onClick={e => e.stopPropagation()}>
                            <Switch
                                checked={value ?? false}
                                onChange={(check) => onInputChangeHandler(key, check, index, data.__custom_row_id)}
                                size='small'
                                disabled={fieldVisiblity}
                            />
                            {
                                ((!isDataCatalog || !isReadOnly) && !fieldVisiblity)
                                    ?
                                    <Tooltip title='Apply to all' placement='right'>
                                        <i className='bx bxs-checkbox-checked ml-1' onClick={(e) => { e.stopPropagation(); onApplyAddFields(key, value) }} ></i>
                                    </Tooltip>
                                    : ''
                            }
                        </div>
                    case 'DROPDOWN':
                        return <div className={`${isRowUnselected ? 'row-unchoosed' : ''} d-flex align-items-center h-100 borderless`}>

                            <div className='custom-select-dropdown w-100'>
                                <Select
                                    showSearch
                                    disabled={fieldVisiblity}
                                    optionFilterProp="children"
                                    value={isArray(value) ? '' : value}
                                    onClick={e => e.stopPropagation()}
                                    onChange={(val) => onInputChangeHandler(key, val, index, data.__custom_row_id)}
                                    filterOption={(input, option) =>
                                        option.value?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.value.toString().toLowerCase().localeCompare(optionB.value.toString().toLowerCase())
                                    }
                                >
                                    {
                                        obj?.value.map((ele, index) => (
                                            <Select.Option key={index} value={ele.value ? ele.value : ele.Name}>
                                                <div className='align-items-center option-select-customized d-flex w-100 px-1'>
                                                    <Tooltip title={ele.Name} placement="topLeft">
                                                        <label className='mb-0 text-with-ellipsis'>{ele.Name}</label>
                                                    </Tooltip>
                                                    {
                                                        ((!isDataCatalog || !isReadOnly) && !fieldVisiblity)
                                                            ? <Tooltip title='Apply to all new Display Names' placement='right'>
                                                                <i className='bx bxs-checkbox-checked' onClick={(e) => { e.stopPropagation(); onApplyAddFields(key, ele.value) }} ></i>
                                                            </Tooltip>
                                                            : ''

                                                    }
                                                </div>
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        </div>
                    default: return <></>
                }
            }
        }
    }

    const getSelectOptions = (key) => {
        switch (key) {
            case 'datatype':
                return dataTypeList
            case 'category':
                return dataCategoryList
            case 'business_term':
                return businessTermList
            default:
                return []
        }
    }

    const getBusinessTermList = () => {
        if (podId) {
            dispatch(getBusinessTermsOfPod(podId))
                .then((res) => {
                    let data = res.data
                    if (data.length === 0) {
                        getAdditionalDetailsList()
                        // getDataCategoryList()
                        getDataClassificationList()
                    }
                    let temp = data.map((item) =>
                    ({
                        // Name: item.business_term,
                        // value: item.business_term,
                        ...item
                    }))
                    businessTermListRef.current = [...data]
                    //console.log('__cpsv', {temp})
                    setBusinessTermList([...temp]);

                    let cats = [], clss = []
                    data.forEach(item => {
                        if (item?.category_detail) {
                            cats.push({ ...item?.category_detail })
                        }

                        let cls = item?.classification_detail
                        cls.forEach(v => clss.push({ ...v }))
                    })

                    let n_cats = [...cats]?.map(c => (c?.name?.trim() === '' ? { ...c, name: '(BLANK)' } : { ...c }))
                    let n_clss = [...clss, { name: '(BLANK)', selected: false }]
                    n_cats = _.uniqBy(n_cats.map(v => ({ name: v.name.toLowerCase(), selected: false })), 'name')
                    n_clss = _.uniqBy(n_clss.map(v => ({ name: v.name.toLowerCase(), selected: false })), 'name')

                    setFilterList({ category: [...n_cats], classifiers: [...n_clss] })

                })
                .catch((err) => {
                    setSchemaError(err?.message)
                    setBusinessTermList([])
                })
        }
    }

    const getDataCategoryList = () => {
        dispatch(getSettingsDataCategory(false))
            .then((res) => {
                let temp = res.data.map((item) =>
                ({
                    ...item,
                    Name: item.name,
                    value: item.name
                }))
                setDataCategoryList([...temp]);
            })
    }

    const getDataClassificationList = () => {
        dispatch(getClassificationData(false))
            .then((res) => {
                let temp = res.data.map((item) =>
                ({
                    ...item,
                    Name: item.name,
                    value: item.name,
                }))
                setDataClassificationList([...temp]);
            })
    }

    const getAdditionalDetailsList = () => {
        let additionalColumns = [];

        dispatch(getSettingsAdditionalDetailsList(false))
            .then((res) => {
                let tempData = {};
                res.data.forEach((item) => {
                    let options = null;
                    additionalFields.current.push(item);
                    if (!_.isEmpty(item.value)) {
                        options = item.value.map(val => { return { Name: val, value: val } })
                    }
                    tempData[item.name] = "";
                    additionalColumns.push(
                        {
                            key: item.name,
                            title: item.name,
                            isRequired: false,
                            visible: false,
                            width: item.type === 'TOGGLE' ? 60 : 150,
                            frozen: false,
                            type: item.type,
                            value: options
                        },
                    )
                })
                let addFields = additionalColumns.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value ?? '' }), {})
                generateTableData(addFields, true)
                let temp = [...colsList, ...additionalColumns];
                setColsList(temp);

                setFetchedAddFields({ ...addFields })
                setFetchedAddFieldsCols([...additionalColumns])
            })
            .catch(() => generateTableData({}, true))

    }

    const resetAllFields = (key) => {
        setIsInputReady(false)
        let clone = [...schemaTableData]
        clone = clone.map(item => (
            isValidBusinessTerm(item?.business_term) && item?.curr_bizterm_id
                ? { ...item }
                : { ...item, [key]: isArray(item[key]) ? [] : '' }
        )
        )
        setSchemaTableData(clone)
        setTimeout(() => setIsInputReady(true), 50)
    }

    const setSchemaTableData = (sdata = []) => {
        let clone = [...sdata]
        let schemaPayload = generateSchemaTablePayload(clone)
        let podReduxData = store.getState().DataCatalogue.PodCreationData
        let podData = cloneDeep(podReduxData)

        const viewDataClone = [...data]
        clone = clone?.map(c => ({...c, __searched: searchValue?.trim() === "" ? false : !!viewDataClone?.find(v => v?.__custom_row_id === c?.__custom_row_id)?.__searched}))
        podData.schemaTableData = [...clone];
        podData.schemaTableDataPayload = [...schemaPayload];

        if (sdata?.length === 0) // Emptying the schema related redux
        {
            podData.output_regex = []
        }

        dispatch(setPodCreationData(podData));
    }

    const onApplyAddFields = (key, value) => {
        setIsInputReady(false)
        let clone = [...schemaTableData]
        clone = clone.map(item => (
            isValidBusinessTerm(item?.business_term) && item?.curr_bizterm_id
                ? { ...item }
                : { ...item, [key]: _.isEmpty(_.isArray(item[key]) ? '' : item[key]) ? value : item[key] }
        )
        )
        setSchemaTableData(clone)
        setTimeout(() => setIsInputReady(true), 50)
    }

    const onApplyClassification = (classifications) => {
        let clone = [...schemaTableData]
        clone = clone.map(item => (
            isValidBusinessTerm(item?.business_term) && item?.curr_bizterm_id
                ? { ...item }
                : { ...item, classifications: item.classifications?.length === 0 ? [...classifications] : [...item.classifications] }
        )
        )
        setSchemaTableData(clone)
    }

    const onApplyCategory = (category) => {
        let clone = [...schemaTableData]
        clone = clone.map(item => (
            isValidBusinessTerm(item?.business_term) && item?.curr_bizterm_id
                ? { ...item }
                : { ...item, category: _.isEmpty(item.category) ? category : item.category }
        )
        )
        setSchemaTableData(clone)
    }

    const onApplyStatus = (status) => {
        let clone = [...schemaTableData]
        clone = clone.map(item => (
            isValidBusinessTerm(item?.business_term) && item?.curr_bizterm_id
                ? { ...item }
                : { ...item, status: !item?.curr_bizterm_id ? status : item.status }
        )
        )
        setSchemaTableData(clone)
    }

    const onCheckHandler = (e, index, __id) => {
        let clone = [...schemaTableData]
        let clone_obj = clone.find(v => v.__custom_row_id === __id)
        clone_obj['is_row_selected'] = e.target.checked
        clone = clone.map(v => v.__custom_row_id === __id ? ({ ...clone_obj }) : ({ ...v }))
        setSchemaTableData([...clone])
    }

    const onSelectAllHandler = (e) => {
        let isChecked = e.target.checked
        let clone = [...schemaTableData].map(item => ({ ...item, is_row_selected: isChecked }))
        setSchemaTableData(clone)
        setSelectAll(isChecked)
    }
    // console.log({unsavedDataObj})
    const onInputChangeHandler = async (key, value, index, __id, dataDict = {}) => {

        /**
         * For Description Field Separate Code Is There
         */

        let clone = [...schemaTableData]
        let clone_obj = clone.find(v => v.__custom_row_id === __id)
        clone_obj[key] = value
        if (key === 'classifications') {
            if(value?.length > 0) {
                clone_obj['classifications'] = value
                clone_obj['pii'] = value?.[0]?.pii
            }
            else {
                clone_obj['classifications'] = []
                clone_obj['pii'] = false
            }
        }
        if (key === 'category') {
            clone_obj['data_owners'] = dataCategoryList.find(cat => cat.name === value)?.owner_steward_detail?.owner.map(v => `${v.first_name} ${v.last_name}`)
            clone_obj['data_stewards'] = dataCategoryList.find(cat => cat.name === value)?.owner_steward_detail?.steward.map(v => `${v.first_name} ${v.last_name}`)
        }
        if (key === 'business_term' && value?.length <= 250) {
            let currBusTerm = { ...dataDict }
            if (dataDict && Object.keys(dataDict).length > 0) {
                currBusTerm = { ...dataDict }
                if (
                    // dataDict?.curr_bizterm_id || 
                    dataDict?.id) {
                    searchedBizTermRef.current = _.uniqBy([
                        ...searchedBizTermRef.current,
                        currBusTerm
                    ], 'id')
                }
            }
            if (dataDict === undefined) {
                currBusTerm = searchedBizTermRef.current.find(itm => itm?.business_term?.toLocaleLowerCase() === value.toLocaleLowerCase()) ?? ''
            }

            if(currBusTerm?.data_type_option && currBusTerm?.data_type_option?.length > 0) {
                clone_obj['datatype'] = currBusTerm?.data_type_option?.[0]?.column_datatype
                clone_obj['optional'] = currBusTerm?.data_type_option?.[0]?.custom_datatype
            }
            else {
                clone_obj['datatype'] = currBusTerm?.source_datatype ?? clone_obj?.source_datatype
                clone_obj['optional'] = ''
            }

            // let currBusTerm = businessTermList.find(itm => itm?.business_term?.toLocaleLowerCase() === value.toLocaleLowerCase()) ?? ''
            let currAddField = currBusTerm?.add_field?.reduce((a, c) => ({ ...a, [c.name]: c.value }), {}) ?? {}
            clone_obj['category'] = currBusTerm?.category_detail?.name ?? ''
            clone_obj['classifications'] = currBusTerm?.classification_detail?.map(v => v.name) ?? []
            clone_obj['data_owners'] = currBusTerm?.owner_steward_detail?.owner?.map(v => `${v.first_name} ${v.last_name}`) ?? ''
            clone_obj['data_stewards'] = currBusTerm?.owner_steward_detail?.steward?.map(v => `${v.first_name} ${v.last_name}`) ?? ''
            clone_obj['description'] = currBusTerm?.description ?? ''
            clone_obj['curr_bizterm_id'] = currBusTerm?.id ?? null
            clone_obj['status'] = currBusTerm?.status ?? 'ACTIVE'
            clone_obj['pii'] = currBusTerm?.pii ?? false
            clone_obj = { ...clone_obj, ...currAddField }
        } else if (key === 'business_term' && value?.length > 250) {
            emitToastNotification('warning', `Current display name length is: ${value.length} and ${Literals.BUSINESS_TERM_LENGTH_MSG}`)
        }

        if (
            clone_obj['datatype'] === 'boolean'
            || !Object.keys(formatPlaceholderMap).includes(clone_obj['datatype'])
        ) {
            clone_obj['optional'] = ''
        }

        if (key === 'datatype') {
            clone_obj['optional'] = ''
        }

        clone = clone.map(v => v.__custom_row_id === __id ? ({ ...clone_obj }) : ({ ...v }))

        if (clone_obj?.curr_bizterm_id === null || clone_obj?.curr_bizterm_id === undefined) {
            setUnsavedDataObj(prev => {
                prev[clone_obj?.__custom_row_id] = { ...clone_obj }
                return prev
            })
        }

        setSchemaTableData([...clone])

        if (key === 'datatype') {
            setIsInputReady(false)
            setTimeout(() => setIsInputReady(true), 50)
        }
    }

    const onHideFieldSwitchChange = (checked, key) => {
        setColsList(prev => {
            prev = prev.map((item, index) => ({ ...item, visible: item.key === key ? checked : item.visible, width: columnWidth[index] ?? 120 }))
            setColumns([...generateColumns(prev)])
            return [...prev]
        })

        setTimeout(() => {
            correctDataGridWidthIssue()
        }, 500)
    }

    const correctDataGridWidthIssue = () => {
        const el = document.querySelector('.new-schemalist-dnd-wrap .custom-vlist-dgt')
        if (el) {
            if (!el.style['grid-template-columns']?.includes('!important')) {
                if (
                    el.style['grid-template-columns']?.split(' ')[0] === "80px" ||
                    el.style['grid-template-columns']?.split(' ')[0] === "100px"
                ) {
                    let arr = el.style['grid-template-columns']?.split(' ')
                    arr[0] = "300px"
                    arr[1] = "200px"
                    el.style['grid-template-columns'] = arr?.join(" ")
                }
            }
        }
    }

    const onRowReorder = (newData) => {
        setIsInputReady(false)
        setData([...newData])
        setSchemaTableData(newData)
        setTimeout(() => setIsInputReady(true), 50)
    }

    const handleAllFields = (isVisible) => {
        let colsCopy = [...colsList].map((col, index) => ({ ...col, visible: col.isRequired ? true : isVisible, width: columnWidth[index] ?? 120 }))
        setColsList([...colsCopy])
        setColumns([...generateColumns(colsCopy)])

        setTimeout(() => {
            correctDataGridWidthIssue()
        }, 500)
    }

    const hideFieldsList = useMemo(() => {
        return _.sortBy(
            colsList.filter(item => item.title.toLocaleLowerCase().includes(hideFieldSearchValue.toLocaleLowerCase())),
            [(o) => !o.isRequired]
        )
    }, [colsList, hideFieldSearchValue])

    const hidePopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Hide Fields</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                tooltip: "Reset",
                                buttonText: "",
                                buttonClassName:
                                    "error-red custom-btn-outline custom-btn btn-with-icon mr-1",
                                buttonEvent: (e) => {
                                    e.stopPropagation()
                                    setColsList((prev) => {
                                        prev = prev.map(el => ({ ...el, visible: el?.isRequired }))
                                        return prev
                                    })
                                    setIsSchemaReady(false)
                                    setTimeout(() => {
                                        setIsSchemaReady(true)
                                    }, 500)
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setHideFieldPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div>
                    <div className="new-hide-fields-popover-wrap" style={{ width: 222 }}>
                        <div className="pt-3 pb-0" style={{paddingLeft: '0.875rem', paddingRight: '0.875rem'}}>


                            <div
                                className="custom-search new-hide-field-search"
                            >
                                <input
                                    id="hide-field-searchbox"
                                    type="text"
                                    onChange={(e) => setHideFieldSearchValue(e.target.value)}
                                    className="custom-search-input"
                                    placeholder={Literals.PLACE_HOLDER}
                                    autoFocus
                                    value={hideFieldSearchValue}
                                />
                                <div className="custom-search-icon">
                                    <SEARCH />
                                </div>
                                <div className="custom-close-icon" onClick={() => setHideFieldSearchValue('')}>
                                    <CLOSE />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div style={{ padding: "3px 10px 3px" }}>
                        <div className="d-flex align-items-center p-1 new-hide-show-hide-btn-prev"
                            onClick={() => {
                                setShowAllCols(!showAllCols)
                                handleAllFields(!showAllCols)
                            }}
                        >
                            <span className='hide-field-toggle-btn' style={{ cursor: "pointer" }}>
                                {
                                    showAllCols ?
                                        <ICON_EYECLOSE height="14" width="14" color="currentColor" />
                                        :
                                        <ICON_EYEOPEN height="14" width="14" color="currentColor" />
                                }
                            </span>
                            <label
                                className='position-relative new-hidefield-dgt-hide-cols-item d-block fontSizeLabel mb-0 ml-2 text-with-ellipsis'
                                style={{ fontFamily: 'InterRegular', lineHeight: '13px', cursor: 'pointer' }}
                            >
                                {
                                    showAllCols ? 'Hide All' : 'Show All'
                                }
                            </label>
                        </div>
                    </div>

                    <div className="h-100">
                        <div
                            className="overflow-auto"
                            style={{ height: "182px" }}
                        >
                            {
                                hideFieldsList.length > 0
                                    ?
                                    hideFieldsList
                                        .map((column, index) => (
                                            <React.Fragment key={`hide-field-map-${index}`}>
                                                <>
                                                    <div key={`hide-field-map-${index}`} className="d-flex align-items-center position-relative" style={{ padding: '4px 14px 4px 14px' }}>
                                                        <span
                                                            className="new-hide-field-control-btn hide-field-toggle-btn"
                                                            onClick={() => {
                                                                if (column?.isRequired) return
                                                                onHideFieldSwitchChange(!column.visible, column.key)
                                                            }}
                                                            style={
                                                                column?.isRequired
                                                                    ? { cursor: 'not-allowed', color: '#333333 !important', opacity: '.6' } : {}
                                                            }
                                                        >
                                                            {
                                                                column.visible
                                                                    ? <ICON_EYEOPEN height="14" width="14" color="currentColor" />
                                                                    : <ICON_EYECLOSE height="14" width="14" color="currentColor" />
                                                            }
                                                        </span>
                                                        <label
                                                            className='position-relative new-hidefield-dgt-hide-cols-item d-block fontSizeLabel mb-0 ml-2 text-dark text-with-ellipsis'
                                                            title={column.title}
                                                            style={{ fontFamily: 'InterRegular' }}
                                                        >
                                                            {/* {getColumnDataType(column.Type)} */}
                                                            {column.title}
                                                        </label>
                                                    </div>
                                                </>
                                            </React.Fragment>
                                        ))
                                    :
                                    <div className="p-3 text-center">No Match Found</div>
                            }
                        </div>
                    </div>
                </div>
            </>
        }
    }

    const handleHideFieldVisibleChange = (visible) => {
        setHideFieldPop(visible)
    }

    const handleSaveBusinessTerms = () => {
        let clone = [...schemaTableData]
        let newBizTerms = clone.filter(
            item => (!isValidBusinessTerm(item.business_term) && item.is_row_selected === true)
                || (item?.curr_bizterm_id === null && item?.is_row_selected === true)
        )
        let payload = newBizTerms.map(item => {
            // const catId = item?.curr_category_id
            const catId = item.category === '' ? null
                : getCategoryId(item.category)

            return (
                {
                    business_term: correctBusinessTerm(item.business_term),
                    status: item?.status?.toUpperCase() ?? '',
                    pii: item?.pii ?? false,
                    category: catId,
                    classification: item?.classifications?.length === 0 ? []
                        : isArray(item?.classifications)
                            // ? item?.curr_classifications_id 
                            ? item?.classifications?.map(v => ({ classification: getClassificationId(v?.name ?? v) }))
                            : [{ classification: getClassificationId(item.classifications) }],
                    description: item.description,
                    add_field: Object.keys(item).map(key => (
                        {
                            add_field: getAdditionalFieldId(key),
                            value: isArray(item[key]) ? '' : item[key]
                        }
                    )
                    ).filter(v => v.add_field !== '')
                }
            )
        }
        )
        //console.log('__cpsv', {payload})
        setNewBusinessTerms([...payload])
    }

    const handleFilterVisibleChange = (visible) => {
        setFilterPop(visible)
    }
    const setModalState = (state, key) => {
        return setAddModals(prev => ({ ...prev, [key]: state }))
    }

    const isValidBusinessTerm = (businessTerm) => {
        const storedBizTermList = businessTermList.length === 0 ? businessTermListRef.current ?? [] : businessTermList

        // console.log({
        //     businessTermList, 
        //     storedBizTermList, 
        //     businessTermListRef: businessTermListRef.current, 
        //     searchedBizTermRef:searchedBizTermRef.current})
        let clone = _.uniqBy([...storedBizTermList, ...searchedBizTermRef.current], 'id')
        // console.log({clone})
        return clone.map(term => term?.business_term?.toLocaleLowerCase())?.includes(businessTerm?.toLowerCase())
    }

    const getBusinessTermId = (term) => {
        let clone = _.uniqBy([...businessTermList, ...searchedBizTermRef.current], 'id')
        let obj = clone.find(obj => obj.business_term === term)
        return obj === undefined ? '' : obj.id
    }

    const getCategoryId = (category) => {
        if (category === null) return ''
        let obj = dataCategoryList.find(cat => cat.name === category)
        return obj === undefined ? '' : obj.id
    }

    const getClassificationId = (classification) => {
        if (isArray(classification)) {
            return classification.map(item => dataClassificationList.find(cls => cls.name === item)?.id) ?? []
        }
        let obj = dataClassificationList.find(item => item.name === classification)
        return obj === undefined ? '' : obj.id
    }

    const getAdditionalFieldId = (field) => {
        let obj = additionalFields.current?.find(item => item.name === field)
        return obj === undefined ? '' : obj.id
    }
    const saveBusinessTerm = (val) => {
        setIsInputReady(false)
        if (val && val.id) {
            let currBusTerm = { ...val.data }
            searchedBizTermRef.current = _.uniqBy([
                ...searchedBizTermRef.current,
                currBusTerm
            ], 'id')
            onInputChangeHandler('business_term', val['data']['business_term'], categoryIndexRef.current, currRowId, val.data)
        }
        setTimeout(() => setIsInputReady(true), 50)

    }
    const saveCategory = (val) => {
        if (val) {
            setIsInputReady(false)
            // if (isFilterRef.current) {
            //     categoryRefFilter.current = val
            //     isFilterRef.current = false;
            //     setSelectedCategoriesFilter([...val])
            // }

            // else {
            setSelectedCategories(val)
            onInputChangeHandler('category', val[0]['name'], categoryIndexRef.current, currRowId)
            categoryRef.current = [...val]

            // }


            setTimeout(() => setIsInputReady(true), 50)
        }
        else {
            setSelectedCategories(val)
            onInputChangeHandler('category', '', categoryIndexRef.current, currRowId)
        }
    }

    const saveClassification = (val) => {
        // let valId = val.map(item => item.id)
        if (val) {
            setIsInputReady(false)
            // if (isFilterRef.current) {
            //     classificationRefFilter.current = val
            //     isFilterRef.current = false;

            //     setSelectedClassificationsFilter([...val])
            // }
            // else {
            // setSelectedCategories(val)

            setSelectedSingleClassifier(val)

            // setSelectedClassificationsFilter(val)
            onInputChangeHandler('classifications', [val], categoryIndexRef.current, currRowId)
            // let valName = val.map(v => v.name)
            // classificationRef.current = [...valName]
            // }
            setTimeout(() => setIsInputReady(true), 50)
        }
        else {
            setSelectedSingleClassifier(val)
            onInputChangeHandler('classifications', [], categoryIndexRef.current, currRowId)
        }
    }

    // useEffect(() => {
    //     if (openCategoryModal) {
    //         getDataCategoryList()
    //     }
    // }, [openCategoryModal])

    useEffect(() => {
        if (openClassificationModal) {
            getDataClassificationList()
        }
    }, [openClassificationModal])

    const showCopilot = React.useMemo(() => {

        if(isUpdateView) {
            return data?.length > 0 && !isReadOnly
        }
        return data?.length > 0

    }, [data, isUpdateView, isReadOnly])

    return (
        <>
            {
                !isSchemaReady && schemaErr === undefined ? showSimpleLoading('Loading Schema...') : ''
            }
            {
                schemaErr ?
                    getReloadErrorTemplate({ errorMessage: schemaErr, hideReloadBtn: true })
                    :
                    <SplitPane
                        split='horizontal'
                        sizes={sizes}
                        onChange={setSizes}
                        className={`pod-crt-sch-splitpane 
                        ${!isSchemaReady ? 'd-none' : ''} 
                        ${!showResultTable ? 'hide-sch-table' : ''}
                        ${expanded ? 'full-screen-active' : ''}
                    `}
                    >

                        <div className={`position-relative w-100 h-100 d-block bg-white ${showResultTable ? 'pb-1' : ''}`}>
                            <div className={`${expanded ? 'h-100 padding-2' : 'h-100 pt-2 px-2'} ${!isSchemaReady ? 'd-none' : ''}`}>
                                {
                                    isSchemaReady && showNote
                                        ? <div className="alert alert-info border-0 mb-1 px-2 py-2 rounded-0 small">
                                            Note: Data Classification and Data Category cannot be empty, also special characters are not allowed in Display Name.
                                        </div>
                                        : ''
                                }
                                <div className={`d-flex align-items-center justify-content-between mb-1`}>
                                    <div className='align-items-center d-flex'>
                                        {
                                            <div className={`d-flex align-items-center pr-2 mr-2 ${!isDataCatalog ? 'border-right' : ''}`}>
                                                <SearchInput
                                                    searchData={searchValue}
                                                    setSearchData={setSearchValue}
                                                    searchBoxOpen={true}
                                                    isDisable={autoClassifyLoader}
                                                    onClearCallback={() => {
                                                        setIsSchemaReady(false)
                                                        setTimeout(() => {
                                                            setIsSchemaReady(true)
                                                        }, 500)
                                                    }}
                                                    useDelay={false}
                                                    useButtonClick={true}
                                                    ref={localSearchRef}
                                                />
                                                <button className='custom-btn-outline custom-btn btn-with-icon border bg-light ml-1'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        localSearchRef?.current?.search()
                                                    }}
                                                >
                                                    <ICON_SEARCH />
                                                </button>
                                                {
                                                    searchValue !== ""
                                                    ?
                                                    <Tooltip title="Clear Search Input" placement='right'>
                                                        <button 
                                                            className='custom-btn-outline custom-btn btn-with-icon ml-1'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                localSearchRef?.current?.clear()
                                                            }}
                                                        >
                                                            <ICON_CLEAR />
                                                        </button>
                                                    </Tooltip>
                                                    :   ''
                                                }
                                            </div>
                                        }
                                        {/* {
                                        expanded || isDataCatalog
                                            ? <div className={`d-flex pr-2 mr-2 ${!isDataCatalog ? 'border-right' : ''}`}>
                                                <SearchInput searchData={searchValue} setSearchData={setSearchValue} searchBoxOpen={true} />
                                            </div>
                                            : ''
                                    } */}
                                        {/* {
                                        !isDataCatalog
                                            ? addButtons.map(btn => (
                                                <Buttons
                                                    key={`${btn.title}-add-btn`}
                                                    props={{
                                                        buttonText: btn.title,
                                                        buttonClassName: "bg-light border color-primary custom-btn btn-with-text mr-2",
                                                        buttonEvent: () => { btn.event() },
                                                        ImgSrc: () => <PLUS />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                    }}
                                                />
                                            ))
                                            : ''
                                    } */}
                                        {
                                            !_.isEmpty(searchValue)
                                                ? <label className='mb-0 paragraph text-black-50'>
                                                    {data.length === 0 ? 'no' : data.length} search result{data.length === 1 ? '' : 's'} found for "{searchValue}"
                                                </label>
                                                : ''
                                        }


                                        {/* <div className='d-flex align-items-center'>
                                            <Checkbox
                                                className='mr-1'
                                            />
                                            <label className='mb-0 fontSizeLabel fontInterSemiBold text-dark d-flex align-items-center'>
                                                Keep Original Column Names
                                                    <Tooltip title="Replace all Business Terms to Technical Terms with one click" placement='right' >
                                                        <span className='d-flex ml-1 label-color'>
                                                            <ICON_INFO_FILLED color='currentColor' height='12' width='12' />
                                                        </span>
                                                    </Tooltip>
                                            </label>
                                        </div> */}
                                    </div>
                                    <div className='align-items-center d-flex'>
                                        {
                                            // data?.length === 0 ? <div></div>
                                            // :
                                            <>
                                                {
                                                    colsList.filter(col => col.visible === false).length > 0
                                                        ? <p className='paragraph text-black-50 mr-2 mb-0'>
                                                            {colsList.filter(col => col.visible === false).length} field(s) are hidden
                                                        </p>
                                                        : ''
                                                }
                                                <Popover
                                                    placement="bottomRight"
                                                    key='hide-pop-over-create-pod-view'
                                                    title={<></>}
                                                    content={hidePopover.body}
                                                    trigger="click"
                                                    autoAdjustOverflow={true}
                                                    open={showHideFieldPop}
                                                    onOpenChange={handleHideFieldVisibleChange}
                                                    arrow={false}
                                                >
                                                    <Buttons
                                                        props={{
                                                            tooltipPlacement: "left",
                                                            buttonText: "",
                                                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon
                                                            ${colsList.every(col => col.visible === true) ? '' : 'rdg-filter-active'}`,
                                                            buttonEvent: (e) => {
                                                                e.stopPropagation();
                                                                setHideFieldPop(true)
                                                            },
                                                            tooltip: "Show Hidden Fields",
                                                            ImgSrc: () => <HIDEPREVIEW />,
                                                            isDisable: autoClassifyLoader || data?.length === 0,
                                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                        }}
                                                    />
                                                </Popover>
                                            </>
                                        }
                                        <div>
                                            {
                                                // data?.length === 0 ? ''
                                                // :
                                                <DictionaryTableFilter
                                                    filterList={filterList}
                                                    setFilterList={setFilterList}
                                                    disabled={data?.length === 0}
                                                />
                                            }
                                        </div>

                                        {
                                            !isDataCatalog
                                                ?
                                                <>

                                                    <Buttons
                                                        props={{
                                                            tooltipPlacement: "left",
                                                            buttonText: "",
                                                            buttonClassName: `ml-1 custom-btn-outline custom-btn btn-with-icon ${showResultTable ? 'rdg-filter-active' : ''}`,
                                                            buttonEvent: (e) => {
                                                                e.stopPropagation();
                                                                if (showResultTable) {
                                                                    setSizes(['100%', '0%'])
                                                                } else {
                                                                    setSizes(['80%', '20%'])
                                                                }
                                                                setShowResultTable(!showResultTable)
                                                            },
                                                            tooltip: showResultTable ? "Hide Table" : "Show Table",
                                                            ImgSrc: () => showResultTable ? <ICON_TABLE /> : <ICON_TABLE />,
                                                            isDisable: false,
                                                            buttonType: Literals.BTN_TERTIARY,
                                                        }}
                                                    />

                                                    <Buttons
                                                        props={{
                                                            tooltipPlacement: "left",
                                                            buttonText: "",
                                                            buttonClassName: `ml-1 custom-btn-outline custom-btn btn-with-icon`,
                                                            buttonEvent: (e) => {
                                                                e.stopPropagation();
                                                                setExpand(!expanded)
                                                            },
                                                            tooltip: expanded ? "Exit Full Screen" : "Expand",
                                                            ImgSrc: () => expanded ? <EXITFULLSCREEN /> : <FULLSCREEN />,
                                                            isDisable: false,
                                                            buttonType: Literals.BTN_TERTIARY,
                                                        }}
                                                    />
                                                </>
                                                : ''
                                        }

                                    </div>
                                </div>
                                <div className={`crt-pod-schm-wrap ${columns.length === initialColumns?.filter(d => d?.visible)?.length ? 'default-fit' : 'default-fit-' + columns.length}`}
                                // <div className={`crt-pod-schm-wrap ${columns.length === 6 ? 'default-fit' : 'default-fit-' + columns.length}`}
                                    style={{ height: expanded ? 'calc(100% - 30px)' : isDataCatalog ? '94%' : 'calc(100% - 34px)' }}>
                                    {
                                        autoClassifyLoader ? showSimpleLoading('Loading Schema...')
                                            :
                                            demo.length > 0 && data.length > 0
                                                ?
                                                <MultipleColsDndList
                                                    key={`pod-crt-schtbl-c-${colsList?.length}`}
                                                    // colsList={columns}
                                                    colsList={(() => {
                                                        let cols = [...columns].filter(v => v.visible)
                                                        if ([6, 7, 8]?.includes(cols?.length)) {
                                                            return cols
                                                        }
                                                        else {
                                                            cols = cols?.map(c => (
                                                                {
                                                                    ...c,
                                                                    width: c?.key === 'business_term'
                                                                        ? 300
                                                                        : c?.key === 'description'
                                                                            ? 250
                                                                            : c?.width ?? 180
                                                                }
                                                            ))
                                                        }

                                                        return cols
                                                    })()}
                                                    // colsList={(() => {
                                                    //     let cols = [...columns].filter(v => v.visible)
                                                    //     let lim = isDataCatalog ? 7 : 8
                                                    //     const colWid = {
                                                    //         business_term: 200, 
                                                    //         technical_term: 200, 
                                                    //         optional: 100, 
                                                    //         description: '25%'
                                                    //     }
                                                    //     cols = cols.map(v => (
                                                    //         {
                                                    //             ...v,
                                                    //             width: colWid[v.key] ?? undefined,
                                                    //             // width: cols.length > lim ? 250 : v.key === 'description' ? '30%' : v.key === 'business_term' ? '27%' : v.key === 'technical_term' ? '15%' : v.key === 'optional' ? '8%' : undefined,
                                                    //             frozen: v.key === 'business_term' ? cols.length > lim : false
                                                    //         }
                                                    //     ))
                                                    //     console.log({cols})
                                                    //     return cols
                                                    // })()}
                                                    onlyFirstColumnDraggable={true}
                                                    rowsList={demo ?? []}
                                                    // onRowClick={onRowClick}

                                                    rawData={data}
                                                    setData={onRowReorder}
                                                    customWrapClass='new-schemalist-dnd-wrap'
                                                    rowHeight={remToPx(pxToRem(56))}
                                                    headerRowHeight={remToPx(2)}
                                                    // borderBottom={true}
                                                    rowReorder={!isDataCatalog && isRowReorder && !isReadOnly}
                                                />
                                                : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ ...layoutCSS }}>
                            <div className='custom-virtual-data-table w-100 h-100 pod-crt-prev-tbl'>
                                {
                                    getObjectLength(reduxData?.tableData?.result) === 0 ?
                                        <div className='py-5 my-5'>
                                            <div className='text-center mb-2'>
                                                <ICON_TABLE height='50' width='50' color='#828282' />
                                            </div>
                                            <div className='w-50 mx-auto text-center'>
                                                <p className='fontSizeHeading text-dark' style={{ fontFamily: 'InterRegular' }}>
                                                    No Result Found
                                                </p>
                                            </div>
                                        </div>
                                        :
                                        <DataGridTable
                                            resp={reduxData?.tableData?.result}
                                            isSplitPane={true}
                                            isShowHeader={false}
                                            isPreviewDataAlreadyLoading={true}
                                            stopColumnReorder={true}
                                            autoAdjustColWidth={true}
                                            isUnstructured={true}
                                        />
                                }
                            </div>
                        </div>
                    </SplitPane>
            }

            {
                showCopilot
                ?
                <Draggable 
                    onStart={() => setActiveDrags(prev => ++prev)}
                    onStop={() => setActiveDrags(prev => --prev)}
                    handle='.c-cpilot-sec-wrap, .cpilot-pop-head'
                    axis='x'
                    bounds={{top: 0, left: -(document.querySelector('.pod-crt-parent').clientWidth - 400), right: 0, bottom: 0}}
                >
                    <div style={{zIndex: 12, position: 'relative'}}>
                        <ClaristaCopilot
                            type="schema"
                            extraProps={{
                                isPodOfPod,
                                isCustomQuery,
                                getNewBusinessTerms: () => {
                                    let clone = [...schemaTableData]
                                    let newBizTerms = clone.filter(
                                        item => (!isValidBusinessTerm(item.business_term) && item.is_row_selected === true)
                                            || (item?.curr_bizterm_id === null && item?.is_row_selected === true)
                                    )
                                    return newBizTerms
                            
                                },
                                schemaTableData,
                                setSchemaTableData,
                                setIsInputReady
                            }}
                        />
                    </div>
                </Draggable>
                :   ''
            }

            <ManualCreationModal
                openModal={addModals.business_term}
                setOpenModal={(state) => setModalState(state, 'business_term')}
            />
            <DataCategoryModal
                openModal={addModals.category}
                setOpenModal={(state) => setModalState(state, 'category')}
                usersList={usersList}
            />
            <SettingsClassificationModal
                openModal={addModals.classification}
                setOpenModal={(state) => setModalState(state, 'classification')}
            />
            <CategoryModal
                usersList={usersList}
                save={saveCategory}
                isModalOpen={openCategoryModal}
                onClose={() => {
                    activeCategory.current = null
                    setOpenCategoryModal(false)
                }}
                activeCategory={activeCategory.current}
            />
            <FormatColHelplinkTable isModalOpen={openformatHelpModal} onClose={() => setopenformatHelpModal(false)}></FormatColHelplinkTable>
            <BusinessTermModal
                save={saveBusinessTerm}
                selectedIds={selectedCategories}
                setSelectedIds={setSelectedCategories}
                isModalOpen={openBusinessTermModal}
                onClose={() => {
                    setopenBusinessTermModal(false)
                }}
                isSingleSelection={true}
                fromRoute={"schema-table"}
                populateSearchData={populateSearchData}
            />
            <ClassificationModal
                save={saveClassification}
                isModalOpen={openClassificationModal}
                onClose={() => {
                    activeClassifier.current = null
                    setOpenClassificationModal(false)
                }}
                activeClassifier={activeClassifier.current}
            />

        </>
    )
})

export default PodCreationSchema