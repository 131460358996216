import { List, Popover, Tooltip } from 'antd'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { LandingSimpleTableInner } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import Buttons from '../../../Common/button/Buttons'
import {  DELETEICON } from '../../../Common/iconSource'
import { Literals } from '../../common/literals'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'react-redux'
import { SET_SELECTED_BUSINESS_TERM_TEMP } from '../../../../store/modules/dataCatalogue/dictionary/dictionaryActionTypes'
import '../dataDictionary.scss'
import ContainsPII from './ContainsPII'
import { NonPII, businessTermStatusOptions, pxToRem, remToPx } from '../../common/helperFunctions'
import { ICON_INFO_FILLED } from '../../../Common/newIconSource'

const statusOptions = [...businessTermStatusOptions]

export const DataDictionaryMiniListView = ({
    listData = [],
    searchData = '',
    openDeleteDialog = () => { },
    onScrollToBottom = () => { },
    loadMore = false,
    fromRoute,
    setselectedIndex,
    selectedIndex,
    hideAction = false,
    chooseableList = false,
    activeDomain = ''
}) => {
    const history = useNavigate()
    const store   = useStore()

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        let temp = listData.map((item) => {
            return {
                id: item?.id,
                key: item?.id,
                data: item,
                category: listTemplate.data_category(item?.category_detail?.name, searchData),
                business_term: listTemplate.business_term(item?.business_term?.toUpperCase(), item, searchData),
                description: listTemplate.description(item?.description, searchData),
                classifications: listTemplate.classifications(item?.classification_detail, searchData, item),
                add_field: listTemplate.additional_fields(item?.add_field, item),
                pii: <a 
                    href={Literals.links.DATA_DICTIONARY_VIEW + item.id} 
                    onClick={(e) => e?.preventDefault()} 
                    className='custom-anchor d-flex align-items-center h-100'>
                        {item?.pii ? <ContainsPII/> : <NonPII/>}
                    </a>,
                action_btn: listTemplate.action(() => { }, () => { }, openDeleteDialog, item?.id)
            }
        })
        setData([...temp])
    }, [listData])

    

    const columnsList = React.useMemo(() => {
        let arr = [
            {
                name: <label className='mt-0 text-dark label'>Display Name</label>,
                key: "business_term",
                width: 'minmax(262px, 300px)',
        
                sortable: false,
                resizable: false
            },
            {
                name: <label className='mt-0 text-dark label'>Classifier</label>,
                key: "classifications",
                width: 220,
        
                sortable: false,
                resizable: false
            },
            // {
            //     name: <label className='mt-0 text-dark label'>Category</label>,
            //     key: "category",
            //     width: 200,
        
            //     sortable: false,
            //     resizable: false
            // },
            {
                name: <label className='mt-0 text-dark label'>Properties</label>,
                key: "add_field",
                // width: 250,
        
                sortable: false,
                resizable: false
            },
            {
                name: <label className='mt-0 text-dark label'>PII</label>,
                key: "pii",
                width: 180,
                sortable: false,
                resizable: false
            },
            {
                name: <label className='mt-0 text-dark label text-right w-100'>Delete</label>,
                key: "action_btn",
                sortable: false,
                resizable: false,
                width: 100
            }
        ]
        if(hideAction) {
            arr = arr?.filter(v => v?.key !== 'action_btn')
        }
        return arr

    }, [hideAction])

    const onRowClick = (record) => {
        if (fromRoute === 'schema-table' || chooseableList) {
            setselectedIndex(record.id)
            store.dispatch({ type: SET_SELECTED_BUSINESS_TERM_TEMP, payload: record })
        } else {
            history(Literals.links.DATA_DICTIONARY_VIEW + record.id, { state: { domain: activeDomain } })
        }
    }

    const getComparator = (sortColumn) => {
        switch (sortColumn) {
            case 'created_by':
                return (a, b) => {
                    let textA = `${a.data.created_by_user.first_name} ${a.data.created_by_user.last_name}`
                    let textB = `${b.data.created_by_user.first_name} ${b.data.created_by_user.last_name}`
                    return textA !== undefined ? textA.localeCompare(textB) : null
                }
            case 'updated_by':
                return (a, b) => {
                    let textA = `${a.data.updated_by_user.first_name} ${a.data.updated_by_user.last_name}`
                    let textB = `${b.data.updated_by_user.first_name} ${b.data.updated_by_user.last_name}`
                    return textA !== undefined ? textA.localeCompare(textB) : null
                }
            case 'classifications':
                return (a, b) => {
                    let textA = a.data?.classification_detail?.map(v => v.name).join(', ')
                    let textB = b.data?.classification_detail?.map(v => v.name).join(', ')
                    return textA !== undefined ? textA.localeCompare(textB) : null
                }
            case 'business_term':
                return (a, b) => a.data[sortColumn] !== undefined ? a.data[sortColumn].localeCompare(b.data[sortColumn]) : null
            case 'description':
                return (a, b) => a.data[sortColumn] !== undefined ? a.data[sortColumn].localeCompare(b.data[sortColumn]) : null
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }

    return <>
        <div className='h-100'>
            <LandingSimpleTableInner
                cols={columnsList}
                rowsData={data}
                showCursor={true}
                getComparator={getComparator}
                onRowClick={onRowClick}
                tableHeight={'100%'}
                onScrollToBottom={onScrollToBottom}
                loadMore={loadMore}
                selectedRowIndex={selectedIndex}
                rowHeight={remToPx(pxToRem(60))}
                headerRowHeight={remToPx(pxToRem(40))}
            />
        </div>
    </>
}

const bxIconMap = {
    text: 'bx-text',
    dropdown: 'bx-list-ul',
    date: 'bx-calendar',
    toggle: 'bx-toggle-left',
}

const listTemplate = {
    additional_fields: (fields, data) => {
        const text = fields?.map(f => `${f?.name}: ${f?.value}`)?.join(', ')
        if(text === '') return <div className='text-black-50'>
                <a 
                href={Literals.links.DATA_DICTIONARY_VIEW + data.id} 
                onClick={(e) => e?.preventDefault()} 
                className='d-block fontSizeLabel custom-anchor'>
                    N/A
                </a>
        </div>
        return <>
            <Popover
                trigger={'hover'}
                title={<>
                    <div className='pt-2 px-2 pb-0 fontSizeHeading fontInterSemiBold'>
                        Properties
                        <div className='pt-2 mx-n2'>
                            <div style={{borderTop: 'solid 1px #0505050f'}} className='d-flex align-items-start justify-content-between w-100 position-relative py-2'>
                                <div className='fontSizeLabel grey-color fontInterSemiBold w-50 px-2'>Name</div>
                                {/* <span className='position-absolute d-block' style={{top: 0, left: 'calc(50% - 5px)', width: 1, height: 'calc(100% + 0px)', background: '#0505050f'}}></span> */}
                                <div className='fontSizeLabel grey-color fontInterSemiBold w-50 pl-2'>Value</div>
                            </div>
                        </div>
                    </div>
                </>}
                placement='topLeft'
                content={<>
                    <div>
                        <List
                            size="small"
                            className='border-0'
                            style={{maxHeight: '12.5rem', overflowY: 'auto', maxWidth: '15.625rem'}}
                            bordered
                            dataSource={fields}
                            renderItem={(item) => <List.Item className='p-2'>
                                    <div className='d-flex align-items-start justify-content-between w-100 position-relative'>
                                        <div style={{fontSize: '0.625rem', wordBreak: 'break-all', paddingLeft: '0.875rem'}} className='fontInterSemiBold w-50 pr-2 position-relative'>
                                            <i 
                                                style={{position: 'absolute', top: 3, left: 0}}
                                                className={`bx ${bxIconMap[item?.type?.toLowerCase()]} mr-1`}></i>
                                             {item?.name}</div>
                                        <span className='position-absolute d-block' style={{top: -8, left: 'calc(50% - 0.125rem)', width: 1, height: 'calc(100% + 1rem)', background: '#0505050f'}}></span>
                                        <div style={{fontSize: '0.625rem'}} className='fontInterSemiBold w-50 pl-2 h-100'>{item?.value?.toString()}</div>
                                    </div>
                                </List.Item>
                            }
                        />
                    </div>
                </>}
            >
                <a 
                    href={Literals.links.DATA_DICTIONARY_VIEW + data.id} 
                    onClick={(e) => e?.preventDefault()} 
                    className='custom-anchor d-flex h-100'
                >
                    <div className='text-with-ellipsis dict-addfield-tag my-auto'>
                        {/* <Tooltip placement="topLeft" title={text}> */}
                            {text}            
                        {/* </Tooltip> */}
                    </div>
                </a>
            </Popover>
        </>

    },
    data_category: (text = '', searchD) => {
        return <>
        {
            text === "" ? 
            <div className='fontSizeLabel text-black-50'>
                N/A
            </div>
            :
            <Tooltip title={text} placement="topLeft">
                <div className='categoryTag d-inline text-uppercase'>
                    {text}
                    {/* <Highlighter
                        searchWords={[searchD?.toLocaleLowerCase()]}
                        autoEscape={true}
                        textToHighlight={text ? text : ''}
                    /> */}
                </div>
            </Tooltip>
        }
        </>
    },
    business_term: (text = '', data = {},  searchD) => {
        return <>
        <a 
            href={Literals.links.DATA_DICTIONARY_VIEW + data.id} 
            onClick={(e) => e?.preventDefault()} 
            className='custom-anchor d-flex align-items-center h-100'>
            <Tooltip title={`STATUS: ${data?.status}`} placement='right'>
                <span className='sphere mr-2' style={{background: statusOptions?.find(s => s?.value === data?.status?.toLowerCase())?.color}}></span>
            </Tooltip>
            <div className='dict-line-height'>
                {/* {
                    getColumnDataType(dtype)
                } */}
                <div className='dict-line-height mb-2 fontInterSemiBold'>
                    <Tooltip className='dict-new-nm d-block text-with-ellipsis' placement='topLeft' title={`Display Name: ${text}`}>
                        {/* <Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /> */}
                        {text}
                    </Tooltip>
                </div>
                <Tooltip placement='topLeft' title={`Description: ${data?.description}`}>
                    <div style={{color: '#444'}} className='dict-new-nm dict-line-height fontSizeLabel text-with-ellipsis'>
                        {
                            data?.description === ''
                            ? 'No Description Provided'
                            :  `Desc: ${data?.description}`
                        }
                    </div>
                </Tooltip>
            </div>
        </a>
        </>
    },
    description: (text = '', searchD) => {
        return <>
            <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div>
        </>
    },
    created_by: (obj = {}, searchD) => {
        let text = `${obj.first_name} ${obj.last_name}`
        return <>
            <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div>
        </>
    },
    updated_by: (obj = {}, searchD) => {
        let text = `${obj.first_name} ${obj.last_name}`
        return <>
            <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></div>
        </>
    },
    
    classifications: (arr = '', searchD, data = {}) => {
        return <>
            <a 
            href={Literals.links.DATA_DICTIONARY_VIEW + data.id} 
            onClick={(e) => e?.preventDefault()} 
            className='d-block custom-anchor'>

                {
                    arr.length === 0
                        ?
                        <div className='fontSizeLabel text-black-50'>
                            N/A
                        </div>
                        :
                        arr.map((v, i) => {
                            return <>
                                {
                                    i < 1
                                        ?
                                        <Tooltip title={v.name} placement="topLeft">
                                            <div key={`clss--${i}`} className='classificationTag mr-2 text-uppercase'>
                                                {/* <Highlighter
                                                    searchWords={[searchD?.toLocaleLowerCase()]}
                                                    autoEscape={true}
                                                    textToHighlight={v.name ? v.name : ''}
                                                /> */}
                                                {v?.name}
                                            </div>
                                        </Tooltip>
                                        : ''
                                }
                                {
                                    i === 1
                                        ?
                                        <Tooltip title={arr.map(v => v.name).slice(1).join(", ")} placement="topLeft">
                                            <div key={`clss--${i}`} className='classificationTag'>
                                                +{arr.length - 1}
                                            </div>
                                        </Tooltip>
                                        : ''
                                }
                            </>
                        })
                }
            </a>
        </>
    },
    action: (onAlertClick = () => { }, onAnalyzeClick = () => { }, onDelete = () => { }, id) => {
        return <>
            <a 
                href={Literals.links.DATA_DICTIONARY_VIEW + id} 
                onClick={(e) => e?.preventDefault()} 
            className='custom-anchor d-flex h-100 align-items-center justify-content-end'>
                {/* <Buttons
                    props={{
                        buttonText: "",
                        buttonClassName:
                            "custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent",
                        buttonEvent: (e) => {
                            onAlertClick()
                        },
                        tooltip: "Notification",
                        ImgSrc: () =>  <ALERT />,
                        isDisable: false,
                        buttonType: Literals.BTN_TERTIARY,
                    }}
                />
                <Buttons
                    props={{
                        buttonText: "",
                        buttonClassName:
                            "custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent",
                        buttonEvent: (e) => {
                            onAnalyzeClick()
                        },
                        tooltip: "Analyze",
                        ImgSrc: () =>  <PIE />,
                        isDisable: false,
                        buttonType: Literals.BTN_TERTIARY,
                    }}
                /> */}
                <Buttons
                    props={{
                        buttonText: "",
                        tooltip: "Delete",
                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red border-0 bg-transparent`,
                        buttonEvent: (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            onDelete(id)
                        },
                        ImgSrc: () => <DELETEICON />,
                        isDisable: false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />
            </a>
        </>
    }
}