import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Jobs } from "../../usageAndJobs/components/Jobs";


const LogsLowerFlowsSection = ({
  flowId,
showLogs
}) => {

  let flowName = useSelector(state => state.FlowsReducer.name)
  return <div className="flow-lower-section h-100">
    <div id="flow-lower-table-sec" className="flow-lower-table bg-white  h-100"> <Jobs flowId={flowId} showFilters={false} activeKey={'flows'} flowName={flowName} fromFlowLogs={true} flowsexecutionId={''} getLogs={showLogs} />
    </div>
  </div>
};

export default LogsLowerFlowsSection;
