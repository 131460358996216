import React from "react";

const StatusTab = ({ children, type, withDot = false }) => {
  let className;

  switch (type) {
    case "success":
      className = "status-tab-success";
      break;
    case "warn":
      className = "status-tab-warn";
      break;

    case "error":
      className = "status-tab-error";
      break;

    default:
      className = "status-tab-success";
  }

  return (
    <div className={`status-tab ${className} ${withDot ? "with-dot" : ""}`}>
      {children}
    </div>
  );
};

export default StatusTab;
