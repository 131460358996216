import React, { useEffect, useState } from 'react'
import MergeKeysSelection from './MergeKeysSelection';
import SearchInput from '../../../../Common/search/Search';
import ColumnSelection from './ColumnSelection';
import { SCRIPTDEFAULTVIEW } from '../script/ScriptDefaultInfography';

import MergeTypeSelection from './MergeTypeSelection';
import { getObjectLength } from '../../../common/helperFunctions';
import { getInputTransformerDetails } from '../commonFunctions/FlowsHelperFunction';
import { useStore } from 'react-redux';

function MergeTransformerIndex({ updatedFlowRedux, setshowCloseWarning, isExecutePermission, setshowOverlay, setupdatedFlowRedux }) {


    const [toggleDefaultView, setToggleDefaultView] = useState(false);
    const [NewFlowsRedux, setNewFlowsRedux] = useState({});

    const store = useStore()
    useEffect(() => {

        if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes?.length) {

            setupdatedFlowRedux(NewFlowsRedux);
        }
    }, [NewFlowsRedux])

    useEffect(() => {
        let InputTransformers = store.dispatch(getInputTransformerDetails())

        if (InputTransformers && InputTransformers?.length === 2) {
            setToggleDefaultView(false)
        } else {
            setToggleDefaultView(true)

        }
        if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

            setNewFlowsRedux(updatedFlowRedux)
        }
    }, [])

    const isMergeTypeSelected = () => {

        let currentTransformer = store.getState().Lab.currentTransformer;
        if (getObjectLength(NewFlowsRedux)) {
            let findType = NewFlowsRedux.nodes?.find(ele => ele.id === currentTransformer?.id)
            if (findType && findType?.content && findType?.content?.selectedJoin) {
                return true;
            } else {
                return false
            }
        } else {
            return false
        }
    }
    return (
        <>
            <div className="transformer-details-maindiv aggrigate-main-div">
                {!toggleDefaultView ? (
                    <>

                        <div className="bg-white section-with-drag p-3 flow-filter-height h-100">
                            <div className="h-100">
                                <div className="d-flex bg-white section-with-drag merge-drag flow-with-drag">
                                    <div
                                        style={{ width: "55%" }}
                                        className="custom-dashed-border-right position-relative pr-3"
                                    >
                                        <p className="section-caption ">
                                            Choose a join type
                                        </p>
                                        <MergeTypeSelection isExecutePermission={isExecutePermission} setshowCloseWarning={setshowCloseWarning} updatedFlowRedux={NewFlowsRedux} setupdatedFlowRedux={setNewFlowsRedux} />
                                        <div className="merge-keys-table-container">
                                            {isMergeTypeSelected() ? <MergeKeysSelection isExecutePermission={isExecutePermission} setshowCloseWarning={setshowCloseWarning} updatedFlowRedux={NewFlowsRedux} setupdatedFlowRedux={setNewFlowsRedux} setToggleDefaultView={setToggleDefaultView} setshowOverlay={setshowOverlay} /> : null}
                                        </div>
                                    </div>
                                    <div className="pl-1 " style={{ width: "45%" }}>
                                        <div className="h-100">
                                            <div className="aggrigate-right-table"
                                                style={{ overflow: "auto" }}  // height: "calc(100vh - 207px)" 
                                            >
                                                <ColumnSelection isExecutePermission={isExecutePermission} setshowOverlay={setshowOverlay} setshowCloseWarning={setshowCloseWarning} updatedFlowRedux={NewFlowsRedux} setupdatedFlowRedux={setNewFlowsRedux} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : toggleDefaultView ? (
                    <div className="source-not-found">
                        <SCRIPTDEFAULTVIEW />
                    </div>
                ) : null}

            </div>
        </>
    );
}

export default MergeTransformerIndex