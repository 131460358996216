import React, { lazy } from "react";
import { Link, Navigate, Redirect } from "react-router-dom";


import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";



import UserManagement from "../components/modules/userManagementNew/index";
import CreateUserGroup from "../components/modules/userManagementNew/userGroup/components/CreateUserGroup";
import ViewUserDetails from "../components/modules/userManagementNew/users/components/ViewUserDetails.js";




import CreatePOD2 from "../components/modules/dataCatalogue/createPOD2";

import PodCreation from "../components/modules/dataCatalogue/podCreation/PodCreation";





import ConnectionsLandingPage from "../components/modules/connections/landingPage/index";
import CreateDatadomain from "../components/modules/userManagementNew/dataDomains/components/CreateDatadomain";
import EditDatadomain from "../components/modules/userManagementNew/dataDomains/components/EditDatadomain";
import DataDomainDetails from "../components/modules/userManagementNew/dataDomains/components/DataDomainDetails";
import Lineage from '../components/modules/lineage/index';
import ViewLineage from '../components/modules/lineage/components/viewLineage';




import UserGroupDetailsView from "../components/modules/userManagementNew/userGroup/components/UserGroupDetailsView";





import FlowsLandingPage from "../components/modules/flows/landingPage/FlowsLandingPage";

import CreateFlows from "../components/modules/flows/createFlows/Index";

import ViewFlowIndex from "../components/modules/flows/ViewFlowIndex";
import EditFlowIndex from "../components/modules/flows/EditFlowIndex";
import LandingPageIndex from "../components/modules/alert/landingpage/LandingPageIndex";
import CreateAlertIndex from "../components/modules/alert/createAlert/CreateAlertIndex";
import CreatePolicy from "../components/modules/dataCatalogue/accessMangament/components/CreatePolicy";
import FileUpload from "../components/modules/dataCatalogue/file-upload/fileUpload";
// import UserAuth from "../components/modules/UserAuthentication/userAuth";
import { UsageAndJobs } from "../components/modules/usageAndJobs";
import { Scheduler } from "../components/modules/scheduler";
import CreateSchedulerIndex from "../components/modules/scheduler/components/CreateSchedulerIndex";
import ViewSchedulerIndex from "../components/modules/scheduler/components/ViewSchedulerIndex";
import EditSchedulerIndex from "../components/modules/scheduler/components/EditSchedulerIndex";
import { DataDictionary } from "../components/modules/dataDictionary";
import ViewDataDictionaryIndex from "../components/modules/dataDictionary/components/ViewDataDictionaryIndex";
import EditDataDictionaryIndex from "../components/modules/dataDictionary/components/EditDataDictionaryIndex";
import ManualCreation from "../components/modules/dataDictionary/components/ManualCreation";
import { Literals } from "../components/modules/common/literals";
import DashboardIndex from "../components/modules/dashboard/DashboardIndex";
import EditDashBoard from "../components/modules/dashboard/EditDashBoard";
import PodCreationFileUpload from "../components/modules/dataCatalogue/podCreation/components/PodCreationFileUpload";
import PodCreationPodOfPod from "../components/modules/dataCatalogue/podCreation/components/PodCreationPodOfPod";
import MainLandingPage from "../components/Common/mainLandingPage";
import FlowsNewLandingPage from "../components/modules/flows/landingPage/FlowsNewLandingPage";
import CatalogLandingPage from "../components/modules/dataCatalogue/landingPage/CatalogLandingPage";
import DashboardNewIndexPage from "../components/modules/dashboard/DashboardNewIndexPage";
import viewAlertIndex from "../components/modules/alert/createAlert/viewAlertIndex";
import editAlertIndex from "../components/modules/alert/createAlert/editAlertIndex";
import ImportFromDatabase from "../components/modules/dataDictionary/components/ImportFromDatabase";
import PulseLandingPage from "../components/Common/mainLandingPage/components/PulseLandingPage";
import ReportsDetailView from "../components/modules/genAi/components/ReportsDetailView";
import TalkDataIndex from "../components/modules/genAi/components/TalkDataIndex";
import TrackedDataIndex from "../components/modules/genAi/components/TrackedDataIndex";
import FlowsIndex from "../components/modules/flowsNew/createFlows/FlowsIndex.js";
import TalkDataTrace from "../components/modules/genAi/components/TalkDataTrace.js";
import ChartIndex from "../components/modules/dashboard/ChartIndex.js";
import Logout from "../components/modules/UserAuthentication/Logout.js";
import { ClusterIndex } from "../components/modules/clusters/ClusterIndex.js";
import SchedulerDetailsNewPage from "../components/modules/scheduler/components/SchedulerDetailsNewPage.js";
import TalkDataTraceIndex from "../components/modules/genAi/components/TalkDataTraceIndex.js";
import StartPage from "../components/modules/startpage/index.js";
import NewUsageIndex from "../components/modules/usageAndJobs/NewUsageIndex.js";
import PodCreationUnstructured from "../components/modules/dataCatalogue/podCreation/components/PodCreationUnstructured.js";

// import SearchLandingPage from "../components/modules/searchLandingPage";

const LandingPage = lazy(() => import('../components/modules/dataCatalogue/landingPage/landingPage.js'));
const PageContainer = lazy(() => import('../components/modules/common/PageContainer'))
const UserAuth = lazy(() => import("../components/modules/UserAuthentication/userAuth"));
const UnstructuredCreatePod = lazy(() => import("../components/modules/dataCatalogue/podCreation/components/PodCreationUnstructured.js"));

const SearchLandingPage = lazy(() => import('../components/modules/searchLandingPage'))

const routeLinks = Literals.links
const authProtectedRoutes = [
  { path: routeLinks.PAGE_404, component: Pages404 },
  { path: routeLinks.PAGE_COMING_SOON, component: PagesComingsoon },

  {
    exact: true,
    path: routeLinks.CREATE_USER_MANAGEMENT,
    component: CreateUserGroup,
  },
  { exact: true, path: routeLinks.VIEW_USER_MANAGEMENT, component: ViewUserDetails },
  {
    exact: true,
    path: routeLinks.EDIT_USER_MANAGEMENT,
    component: CreateUserGroup,
  },


  // {
  //   exact: true,
  //   path: routeLinks.LINEAGE,
  //   component: Lineage,

  // },
  {
    exact: true,
    path: routeLinks.VIEW_LINEAGE,
    component: ViewLineage,

  },
  {
    exact: true,
    path: routeLinks.CREATE_DOMAIN_USER_MANAGEMENT,
    component: CreateDatadomain,
  },
  {
    exact: true,
    path: routeLinks.EDIT_DOMAIN_USER_MANAGEMENT,
    component: EditDatadomain,
  },
  {
    exact: true,
    path: routeLinks.VIEW_USER_GROUP_USER_MANAGEMENT,
    component: UserGroupDetailsView,
  },
  {
    exact: true,
    path: routeLinks.VIEW_DOMAIN_USER_MANAGEMENT,
    component: EditDatadomain,
    // component: DataDomainDetails,
  },
  { exact: true, path: routeLinks.USER_MANAGEMENT, component: UserManagement },



  { exact: true, path: routeLinks.POD_CREATION, component: PodCreation },
  // { exact: true, path: '/pod-creation-v1', component: CreatePOD2 },
  { exact: true, path: routeLinks.DATA_CATALOG_POD_OF_POD, component: PodCreationPodOfPod },
  {
    exact: true,
    path: routeLinks.VIEW_DATA_CATALOG,
    component: PageContainer,
  },
  { exact: true, path: routeLinks.DATA_CATALOG_FILE_UPLOAD, component: PodCreationFileUpload },
  
  { exact: true, path: routeLinks.DATA_DICTIONARY_IMPORT_DB, component: ImportFromDatabase },

  { exact: true, path: routeLinks.UNSTRUCTURED_POD_CREATION, component: UnstructuredCreatePod },

  { exact: true, path: routeLinks.CREATE_ALERT, component: CreateAlertIndex },
  { exact: true, path: routeLinks.EDIT_ALERT, component: editAlertIndex },
  { exact: true, path: routeLinks.VIEW_ALERT, component: viewAlertIndex },
  // { exact: true, path: routeLinks.ALERTS, component: LandingPageIndex },





  // Access Management
  {
    exact: true,
    path: routeLinks.CREATE_ACCESS_POLICY,
    component: CreatePolicy,
  },
  {
    exact: true,
    path: routeLinks.VIEW_ACCESS_POLICY,
    component: CreatePolicy,
  },
  {
    exact: true,
    path: routeLinks.EDIT_ACCESS_POLICY,
    component: CreatePolicy,
  },

  {
    exact: true,
    path: routeLinks.EDIT_ACCESS_POLICY_POLICY_ID,
    component: CreatePolicy,
  },


  { exact: true, path: routeLinks.CONNECTIONS, component: ConnectionsLandingPage },

  // { exact: true, path: routeLinks.DASHBOARD, component: DashboardIndex },
  // { exact: true, path: routeLinks.DASHBOARD, component: DashboardNewIndexPage },

  { exact: true, path: routeLinks.EDIT_DASHBOARD, component: EditDashBoard },
  { exact: true, path: routeLinks.DASHBOARD_ID, component: EditDashBoard },
  { exact: true, path: routeLinks.VIEW_DASHBOARD, component: EditDashBoard },
  { exact: true, path: routeLinks.CREATE_DASHBOARD, component: EditDashBoard },

  { exact: true, path: routeLinks.VIEW_CHART, component: ChartIndex },


  // { exact: true, path: routeLinks.FLOWS, component: FlowsLandingPage },
  // { exact: true, path: routeLinks.CREATE_FLOWS, component: CreateFlows },
  // { exact: true, path: routeLinks.EDIT_FLOWS, component: EditFlowIndex },
  // { exact: true, path: routeLinks.VIEW_FLOWS, component: ViewFlowIndex },

  { exact: true, path: routeLinks.FLOWS_NEW, component: FlowsIndex },
  { exact: true, path: '/flow/:flowId', component: FlowsIndex },

  { exact: true, path: routeLinks.USAGE, component: NewUsageIndex}, //UsageAndJobs },
  { exact: true, path: routeLinks.CLUSTERS, component: ClusterIndex },


  { exact: true, path: routeLinks.CREATESCHEDULER, component: SchedulerDetailsNewPage },  //SchedulerDetailsNewPage
  { exact: true, path: routeLinks.VIEWSCHEDULER, component: ViewSchedulerIndex },
  { exact: true, path: routeLinks.EDITSCHEDULER, component: EditSchedulerIndex },
  // { exact: true, path: routeLinks.SCHEDULER, component: Scheduler },



  { exact: true, path: routeLinks.DATA_DICTIONARY_MANUAL_CREATION, component: ManualCreation },
  { exact: true, path: routeLinks.VIEW_DATA_DICTIONARY, component: ViewDataDictionaryIndex },
  // { exact: true, path: Literals.links.EDIT_DATA_DICTIONARY, component: EditDataDictionaryIndex },
  { exact: true, path: routeLinks.DATA_DICTIONARY, component: DataDictionary },


  // { exact: true, path: routeLinks.HOME, component: SearchLandingPage },
  { exact: true, path: routeLinks.CLARISTA_NAVIGATOR, component: CatalogLandingPage },
  { exact: true, path: routeLinks.CLARISTA_LAB, component: FlowsNewLandingPage },
  { exact: true, path: routeLinks.CLARISTA_PULSE, component: PulseLandingPage },
  // { exact: true, path: routeLinks.CLARISTA_PULSE, component: DashboardNewIndexPage },
  { exact: true, path: routeLinks.PULSE_LANDING, component: PulseLandingPage },
  { exact: true, path: routeLinks.PULSE_REPORT_DETAIL, component: ReportsDetailView },
  { exact: true, path: routeLinks.PULSE_TALK, component: TalkDataIndex },
  { exact: true, path: routeLinks.PULSE_TRACKER_DETAIL, component: TrackedDataIndex },
  { exact: true, path: routeLinks.PULSE_TALK_TRACE, component: TalkDataTraceIndex },
  { exact: true, path: routeLinks.CLARISTA_HOME, component: StartPage },
  // { exact: true, path: '/new-usage', component: NewUsageIndex },



  {
    path: "/",
    exact: true,
    component: () => <Link to={routeLinks.CLARISTA_HOME} />,
  },
];

const errorRoutes = [
  { path: routeLinks.PAGE_500, component: Pages500 },

  {
    path: "*",
    component: Pages404,
  },
]
const publicRoutes = [
  { path: routeLinks.VERIFY_OTP, component: UserAuth },
  { exact: true, path: routeLinks.LOGIN, component: UserAuth },
  { path: routeLinks.FORGOT_PASSWORD, component: UserAuth },
  { path: routeLinks.LOGOUT, component: Logout },

  {
    path: "/",
    exact: true,
    component: () => <Link to={routeLinks.LOGIN} />,
  },


];

export { authProtectedRoutes, publicRoutes, errorRoutes };
