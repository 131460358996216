import React from "react";
import { SAVE, CLOSE } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import Buttons from "../../../../Common/button/Buttons";
import { Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { createTenantEmail, getTenantEmail } from "../../../../../store/modules/UserManagement/users/usersActions";
import ClaristaLoader from "../../../../Common/claristaLoader/ClaristaLoader";
import { ICON_LOADING_CIRCLE } from "../../../../Common/newIconSource";

const EmailTenant = ({ handleClose }) => {

    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const [fieldValues, setFieldValues] = React.useState({
        host: '',
        port: '',
        username: '',
        sender_name: '',
        sender_email: '',
        password: '',
        use_tls: false,
        is_primary: false,
    })

    React.useEffect(() => {
        getDetails()
    }, [])

    const getDetails = () => {
        setLoading(true)
        dispatch(getTenantEmail())
        .then(res => {
            let data = res?.data ?? []
            let el = data?.[0]
            setFieldValues({
                host: el?.host,
                is_primary: el?.is_primary,
                password: el?.password,
                port: el?.port,
                sender_email: el?.sender_email,
                sender_name: el?.sender_name,
                use_tls: el?.use_tls,
                username: el?.username
            })
            setLoading(false)
        })
        .catch(err => {
            setLoading(false)
        })
    }

    const handleSubmit = () => {
        setSaving(true)
        let payload = {
            ...fieldValues
        }
        dispatch(createTenantEmail(payload))
        .then(res => {
            setSaving(false)
        })
        .catch(err => {
            setSaving(false)
        })
    }

    const onInputHandler = (key, value) => {
        setFieldValues(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    console.log({ fieldValues })

    return (
        <>

            {/**
     * To Prevent Browser Auto fill Functionality
     */}

            <form className="SAML-main-container"
                onSubmit={(e) => {
                    e?.preventDefault()
                    handleSubmit()
                }}
            >
            <div style={{ position: 'fixed', left: -999 }}>
                <input type="email" />
                <input type="password" />
            </div>
                <div className="SAML-topheading d-flex align-items-center justify-content-between p-2 w-100">
                    <p className="heading-title">Email Client Configuration</p>
                    <div className="flex-end d-flex align-items-center">

                        <Buttons props={{
                            actionType: 'submit',
                            buttonText: saving ? 'Saving...' : 'Save',
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: () => {},
                            ImgSrc: () => saving ? <ICON_LOADING_CIRCLE/> : <SAVE />,
                            isDisable: loading || saving,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }} />


                        <Buttons props={{

                            actionType: 'button',
                            buttonText: '', 
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon mx-1", 
                            buttonEvent: () => {
                                handleClose()
                            }, 
                            ImgSrc: () => <CLOSE />, 
                            isDisable: false, 
                            buttonType: Literals.BTN_TERTIARY
                        }} />

                    </div>
                </div>

                <div className="SAML-details position-relative">
                    {
                        loading
                        ?   <div className='d-block position-absolute h-100 w-100' 
                                style={{top: 0, left: 0, zIndex: 2, background: '#fff'}}>
                                    <ClaristaLoader/>
                            </div>
                        :   ''
                    }
                    <>
                        <p className="heading-2">Client Settings</p>
                        {/* <div className="d-flex align-items-center mt-1">
                <Checkbox></Checkbox>
                <label className="checkbox-label d-flex align-items-center">Enable SAML Integration</label>
            </div> */}

                        <div className="row mt-1 align-items-center">
                            {/* <div className="col-3 form-group">
                <div className='d-flex align-items-center justify-content-between p-0'>
                    <label className="label">
                        Tenant
                    </label>
                </div>
                <input className="custom-input-field w-100" type="number" min={0}></input>
                </div> */}
                            <div className="col-3 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label">
                                        Host
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    type="text"
                                    value={fieldValues?.host}
                                    onChange={(e) => {
                                        onInputHandler('host', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                            <div className="col-3 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label">
                                        Port
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    type="text"
                                    value={fieldValues?.port}
                                    onChange={(e) => {
                                        onInputHandler('port', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-1 align-items-center">
                            <div className="col-3 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label">
                                        Username
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    type="text"
                                    value={fieldValues?.username}
                                    onChange={(e) => {
                                        onInputHandler('username', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                            <div className="col-3 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label">
                                        Sender Name
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    type="text"
                                    value={fieldValues?.sender_name}
                                    onChange={(e) => {
                                        onInputHandler('sender_name', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                            <div className="col-3 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label">
                                        Sender Email
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    type="email"
                                    value={fieldValues?.sender_email}
                                    onChange={(e) => {
                                        onInputHandler('sender_email', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mt-1 align-items-center">
                            <div className="col-3 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label">
                                        Password
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    type="password"
                                    value={fieldValues?.password}
                                    onChange={(e) => {
                                        onInputHandler('password', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                            <div className="col-3 form-group mb-0">
                                <div className="d-flex align-items-center mt-1">
                                    <Checkbox
                                        value={fieldValues?.use_tls}
                                        onChange={(e) => {
                                            onInputHandler('use_tls', e?.target?.checked)
                                        }}
                                        disabled={saving}
                                    />
                                    <label className="checkbox-label d-flex align-items-center">Use TLS</label>
                                </div>
                            </div>
                            <div className="col-3 form-group mb-0">
                                <div className="d-flex align-items-center mt-1">
                                    <Checkbox
                                        value={fieldValues?.is_primary}
                                        onChange={(e) => {
                                            onInputHandler('is_primary', e?.target?.checked)
                                        }}
                                        disabled={saving}
                                    />
                                    <label className="checkbox-label d-flex align-items-center">Is Primary</label>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </form>

        </>
    );
};

export default EmailTenant;
