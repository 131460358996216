import React, { useState } from 'react';
import './usageLogs.scss'
import { LastRun } from '../../scheduler/components/LastRun';


export const SchedulerJobs = () => {

    const [isRefreshing, setRefreshing] = useState(false)

    return (
        <>
            <LastRun
                isFromJobs={true}
                showFilters={true}
                showRefresh={true}
                executionId=''
                isRefreshing={isRefreshing}
                setRefreshing={setRefreshing}
            />
        </>
    )
};
