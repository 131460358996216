import React, { useEffect, useState } from "react";
// import datasource from "../../../../assets/icons/Datasource.svg";
// import target from "../../../../assets/icons/Target.svg";
// import databricks from "../../../../assets/icons/Databricks.svg";
// import merge from "../../../../assets/icons/Merge.svg";
// import filter from "../../../../assets/icons/Filter.svg";
// import script from "../../../../assets/icons/scriptblackoutline.svg";
// import sql from "../../../../assets/icons/SQLblackoutline.svg";
// import alert from "../../../../assets/icons/alertIcon.svg";
// import schema from "../../../../assets/icons/Schema.svg";
// import aggregate from "../../../../assets/icons/aggregateIcon.svg";
// import resizer from "../../../../assets/icons/Resizer.svg";

import datasource from "../../../../assets/icons/flows_icon/Datasource.svg";
import target from "../../../../assets/icons/flows_icon/Target.svg";
import databricks from "../../../../assets/icons/flows_icon/Databricks.svg";
import merge from "../../../../assets/icons/flows_icon/Merge.svg";
import filter from "../../../../assets/icons/flows_icon/Filter.svg";
import script from "../../../../assets/icons/flows_icon/Script.svg";
import sql from "../../../../assets/icons/flows_icon/Sql.svg";
import alert from "../../../../assets/icons/flows_icon/Alert.svg";
import schema from "../../../../assets/icons/flows_icon/Schema.svg";
import aggregate from "../../../../assets/icons/flows_icon/Aggregate.svg";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { Literals } from "../../common/literals";
import { getConnectionListV2 } from "../../../../store/actions";

const Menubar = () => {
  const dispatch = useDispatch();
  const showBricksIcon = useSelector((state) => state.FlowsReducer.showBricksIcon);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };


  const showInfoMsg = () => {
    emitToastNotification("info", Literals.FLOWS_MENU_ONCLICK_MSG);
  };



  return (
    <>
      <ul className="flow-control">
        {/* <li  id="group1"> */}
        <li
          onClick={() => showInfoMsg()}
          onDragStart={(event) => onDragStart(event, `datasource`)}
          draggable
        >
          <Tooltip placement="bottom" title="DataSource">
            {" "}
            <img src={datasource} height="34" width="34" alt={`datasource`} />
          </Tooltip>
        </li>
        <li onClick={() => showInfoMsg()} onDragStart={(event) => onDragStart(event, `filters`)} draggable>
          <Tooltip placement="bottom" title="Filter Transformer">
            {" "}
            <img height="33" width="33" src={filter} alt={`filter`} />
          </Tooltip>
        </li>

        <li
          onClick={() => showInfoMsg()}
          onDragStart={(event) => {
            onDragStart(event, `merge`);
          }}
          draggable
        >
          <Tooltip placement="bottom" title="Merge Transformer">
            {" "}
            <img height="33" width="33" src={merge} alt={`merge`} />{" "}
          </Tooltip>
        </li>
        <li onClick={() => showInfoMsg()} onDragStart={(event) => onDragStart(event, `schema`)} draggable>
          <Tooltip placement="bottom" title="Schema Transformer">
            <img height="27" width="30" src={schema} alt={`schema`} />{" "}
          </Tooltip>
        </li>


        <li onClick={() => showInfoMsg()} onDragStart={(event) => onDragStart(event, `aggregate`)} draggable>
          <Tooltip placement="bottom" title="Aggregate Transformer">
            <img height="27" width="30" src={aggregate} alt={`aggregate`} />{" "}
          </Tooltip>
        </li>

        {/* </li> */}


        {/* <li id="group2"> */}

        <li onClick={() => showInfoMsg()} onDragStart={(event) => onDragStart(event, `sql`)} draggable>
          <Tooltip placement="bottom" title="SQL Transformer">
            <img height="33" width="32" src={sql} alt={`sql`} />{" "}
          </Tooltip>
        </li>



        <li onClick={() => showInfoMsg()} onDragStart={(event) => onDragStart(event, `script`)} draggable>
          {/* <SCRIPTOUTLINE /> */}
          <Tooltip placement="bottom" title="Script Transformer">
            <img height="30" width="30" src={script} alt={`script`} />{" "}
          </Tooltip>
        </li>

        {/* {showBricksIcon ?
          <li onClick={() => showInfoMsg()} onDragStart={(event) => onDragStart(event, `databricks`)} draggable>
            <Tooltip placement="bottom" title="Databricks Transformer">
              <img height="27" width="30" src={databricks} alt={`databricks`} />{" "}
            </Tooltip>
          </li>
          : ''} */}
        <div id="transformerGroup1"></div>

        <div id="transformerGroup2"></div>
        {/* </li> */}

        {/* <li id="group3"> */}

        <li onClick={() => showInfoMsg()} onDragStart={(event) => onDragStart(event, `alert`)} draggable>
          <Tooltip placement="bottom" title="Alert Transformer">
            {" "}
            <img height="30" width="30" src={alert} alt={`sql`} />
          </Tooltip>
        </li>

        <li onClick={() => showInfoMsg()} onDragStart={(event) => onDragStart(event, `target`)} draggable>
          <Tooltip placement="bottom" title="Target Transformer">
            {" "}
            <img src={target} alt={`target`} />
          </Tooltip>
        </li>
        {/* </li> */}

      </ul>
    </>
  );
};

export default Menubar;
