import React, { useEffect, useState } from 'react';

import Plot from 'react-plotly.js'



function DoughnutChart({ graphData }) {
    const [graphValue, setgraphValue] = useState()
    const config = {
        displaylogo: false,
        responsive: true,
        editable: false
    }
    useEffect(() => {
        if (graphData) {
            const nullVal = Math.round(graphData?.percentage_na);
            const not_null = Math.round(100 - nullVal)
    
            const data = [
                {
                    values: [not_null, nullVal],
                    labels: [`Not null ${not_null}%`, `Null ${nullVal}%`],
                    domain: {column: 0},
                   
                    hoverinfo: 'label',
                    hole: .5,
                    type: 'pie',
                    marker :{ colors: ['#00CC92', '#FF565E'] }
                  }
            ]
            setgraphValue(data);
        }
    }, [graphData]);

    // useEffect(() => {
    //     if (graphData) {
    //         let not_null
    //         let nullVal = Math.round(graphData?.percentage_na);
    //         not_null = Math.round(100 - nullVal)
    //         let value = [not_null, nullVal]
    //         let newGraphValue = {

    //             series: [not_null, nullVal],
    //             options: {
    //                 pie: {
    //                     donut: {
    //                         size: '60%'
    //                     }
    //                 },
    //                 chart: {
    //                     type: 'donut',
        
    //                 },
    //                 tooltip:{
    //                     enabled: false
    //                 },
    //                 dataLabels: {
    //                     enabled: false
    //                 },
    //                 responsive: [{
    //                     breakpoint: 0,
    //                     options: {
    //                         chart: {
    //                             width: 150
    //                         },
    //                         legend: {
    //                             show: true
    //                         },
    //                     }
    //                 }],
    //                 fill: {
    //                     colors: ['#00CC92', '#FF565E']
    //                 },
    //                 legend: {
    //                     position: 'bottom',
    //                     offsetY: 0,
    //                     height: 50,
        
    //                 },
    //                 colors: ['#00CC92', '#FF565E'],
    //                 labels: [`Not null ${not_null}%`, `Null ${nullVal}%`],
        
    //             },
    //         }
    //         setgraphValue({ ...newGraphValue, series: [...value] })
    //     }
    // }, [graphData])

    return (
        <React.Fragment>
            {graphValue ? 
                <Plot  useResizeHandler={true} 
                    style={{ width:'100%', height:'300px' }}
                    data={graphValue}
                    config={config}
                /> 
                : 
            null}
           {/* {graphValue!==undefined? <ReactApexChart options={graphValue.options} series={graphValue.series} type="donut" />:null} */}

        </React.Fragment >
    )
}

export default DoughnutChart
