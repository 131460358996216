import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'
import "./rightSideContainer.scss";

import {
  COLUMN, FOLDER, RIGHTARROW
} from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";


import { Steps, Tooltip } from "antd";
import {
  getActivePodData,
  setActivePodPODDetails
} from "../../../../../store/actions";
import Buttons from "../../../../Common/button/Buttons";
import DataGridTable from "../../../../Common/dataGridTable/DataGridTable";
import DataTabs from "../../../../Common/dataTabs/DataTabs";

import ExplorerTab from "./explorertab";

import SearchInput from "../../../../Common/search/Search";
import { getConfirmationModal, getObjectLength, getTableSkeleton } from "../../../common/helperFunctions";
import { CreatePodSchemaView } from "../../createPOD2/components/CreatePodSchemaView";
import { getUserGroupList } from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import { useParams } from "react-router-dom";
const { Step } = Steps;

const RightSideContainer = ({
  podName,
  setpodnameError,
  isFolderCreated,
  isReadOnly,
  setIsFolderCreated,
  isPreview,
  deleteFolder,
  setPodName,
  selectedFiles,
  setSelectedFiles,
  getCurrentStep = () => { },
  isUpdateView,
  setNewBusinessTerms = () => { },
  isSchemaTableReadyToShow,
  schemaLoadingMessage,
  source,
  uuid,
  fileUploadConnName,
  setSheetsList = () => { },
  format,
  setSheets = () => { },
  showLeftPanel = true
}) => {
  const dispatch = useDispatch();

  const { podId } = useParams()

  const {
    previewTableData,
    podUserInputDataMap,
    activePodTabDataKey,
    virtualTablesDataMap,
  } = useSelector((state) => state.CreatePODReducer);


  const activePodTabData = podUserInputDataMap.find(
    (data) => data.key == activePodTabDataKey
  );
  const { activeCreatePodName } = virtualTablesDataMap[activePodTabDataKey];
  const {
    activePODKey,

    virtualTableTabs,
    virtualTableData,
    explorerTableTabs,
  } = activePodTabData.podRightSide;

  const [defaultPaneSize,] = useState("52vh");
  const [virtualTableHeight, setVirtualTableHeight] = useState("");
  const [, setEditorHeight] = useState(250);

  const [pod, setPod] = useState("");

  const [current, setCurrent] = React.useState(0);
  const [schemaTooltipMsg, setSchemaTooltipMsg] =
    useState("Navigate to Schema");
  const [showSchemaPop, setSchemaPop] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [dataGridReady, setDataGridReady] = useState(true);
  const [openResetPopup, setOpenResetPopup] = useState(false)

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => getCurrentStep(current), [current]);

  useEffect(() => {
    if (podName != pod) setPod(podName);
  }, [podName]);

  useEffect(() => {
    setDataGridReady(false)
    setTimeout(() => {
      setDataGridReady(true)
    },1000)
  }, [showLeftPanel])

  const resizePanesHandler = (size) => {
    const virtualTableRef = document.querySelector(".Pane2");
    if (virtualTableRef.offsetHeight > 700) {
      setVirtualTableHeight(virtualTableRef.offsetHeight - 40);
    } else {
      if (size < 0) {
        size = 0;
      }
      const maxHeight = window.innerHeight - 145;
      const btmPaneHeight = maxHeight - size;
      setVirtualTableHeight(btmPaneHeight);
    }

    setEditorHeight(size - 65);
  };

  const calculateTableHeightHandler = () => {
    let virtualTableHeight;

    if (window.innerHeight >= 750 && window.innerHeight < 1000) {
      virtualTableHeight = Math.round(window.innerHeight - 280);
    } else if (window.innerHeight >= 1000) {
      virtualTableHeight = Math.round(window.innerHeight - 250);
    } else {
      virtualTableHeight = Math.round(window.innerHeight / 2 - 70);
    }

    setVirtualTableHeight(virtualTableHeight);
  };

  const onCustomTabChangeHandler = (key) => {
    const [activePodKey, activePodData] = dispatch(getActivePodData());
    activePodTabData.podRightSide.activeTabKey = key;
    dispatch(setActivePodPODDetails(activePodKey, activePodData));
  };

  useEffect(() => {
    calculateTableHeightHandler();
    getUsersList()
  }, []);

  useEffect(() => {
    if (isPreview) onCustomTabChangeHandler("2");
  }, [isPreview]);

  useEffect(() => {
    const [activePodKey, activePodData, virtualTablesDataMap] = dispatch(
      getActivePodData()
    );

    const [activeSchemaName, activeTableName] = activeCreatePodName.split("-");

    const allSchemasNames = Object.keys(
      virtualTablesDataMap[activePodKey]
    ).filter((name) => name !== "results" && name !== "activeCreatePodName");

    let tabs = [...virtualTableTabs];
    let newActivePodKey = activePODKey;


    allSchemasNames.forEach((schemaName) => {
      const tableNames = Object.keys(
        virtualTablesDataMap[activePodKey][schemaName]
      ).filter((name) => name !== "tableCount");

      if (tableNames.length) {
        tableNames.forEach((tableName) => {
          const existingTab = virtualTableTabs.find((tab) => {
            return tab.tabTitle === tableName;
          });

          let newKey;

          if (!existingTab) {
            newKey = (+tabs[tabs.length - 1].key + 1).toString();

            tabs = [
              ...tabs,
              {
                key: newKey,
                tabTitle: tableName,
                closable: true,
                tabContent: null,
                schemaName: schemaName,
              },
            ];

            if (tableName === activeTableName) newActivePodKey = newKey;
          } else if (tableName === activeTableName)
            newActivePodKey = existingTab.key;
        });
      }
    });


    let allExisitngTableNames = [];
    allSchemasNames.forEach((schemaName) => {
      const tableNames = Object.keys(
        virtualTablesDataMap[activePodKey][schemaName]
      ).filter((name) => name !== "tableCount");
      allExisitngTableNames = allExisitngTableNames.concat(tableNames);
    });

    const filteredTabs = [tabs[0]];
    tabs.slice(1).forEach((tab) => {
      if (allExisitngTableNames.includes(tab.tabTitle)) {
        filteredTabs.push(tab);
      }
    });

    activePodData.podRightSide.virtualTableTabs = filteredTabs;
    activePodData.podRightSide.activePODKey = newActivePodKey;

    if (activeCreatePodName.toLowerCase().includes("results")) {
      activePodData.podRightSide.activePODKey = "1";
    }


    if (!activeSchemaName.toLowerCase().includes("results")) {
      activePodData.podRightSide.virtualTableData =
        virtualTablesDataMap[activePodKey][activeSchemaName][activeTableName];
    } else {
      if (Object.keys(virtualTablesDataMap[activePodKey]["results"]).length) {
        activePodData.podRightSide.virtualTableData =
          virtualTablesDataMap[activePodKey]["results"]["results"];
      } else {
        activePodData.podRightSide.virtualTableData = {};
      }
    }

    dispatch(setActivePodPODDetails(activePodKey, activePodData));
  }, [virtualTablesDataMap]);

  const getUsersList = () => {
    dispatch(getUserGroupList({ method: "GET", endPoint: "profile/" }))
      .then(res => {
        let list = res?.data?.map(ele => ({ Name: `${ele?.first_name} ${ele?.last_name}`, Value: ele?.id }))
        setUsersList([...list]);
      })
      .catch(err => console.error(err))
  };

  const steps = [
    {
      title: (
        <div
          className="d-flex align-items-center step-title"
          style={{ gap: 5 }}
        >
          {<FOLDER height="20" />}
          <label className="mb-0">{"Explorer"}</label>
        </div>
      ),
      content: (
        <>
          {
            <ExplorerTab
              setPodName={setPodName}
              isReadOnly={isReadOnly}
              setpodnameError={setpodnameError}
              podName={pod}
              source={source}
              isFolderCreated={isFolderCreated}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setIsFolderCreated={setIsFolderCreated}
              deleteFolder={deleteFolder}
              isUpdateView={isUpdateView}
              uuid={uuid}
              fileUploadConnName={fileUploadConnName}
              setSheetsList={setSheetsList}
              setSelectedSheets={setSheets}
            />
          }
        </>
      ),
    },
    {
      title: (
        <div
          className="d-flex align-items-center step-title"
          style={{ gap: 5 }}
        >
          <COLUMN />
          <label className="mb-0">Schema</label>
        </div>
      ),
      content: <>{
        // isSchemaTableReadyToShow
        //   ? 
        <CreatePodSchemaView
          podId={podId}
          dictionaryRefresh={isSchemaTableReadyToShow}
          isReadOnly={isReadOnly}
          showNote={true}
          isFileUpload={true}
          setNewBusinessTerms={setNewBusinessTerms}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          usersList={usersList}
          isUpdateView={isUpdateView}
          disableRowReorder={format && ['csv', 'txt'].includes(format.toLowerCase())}
          disableCheckbox={format && ['csv', 'txt'].includes(format.toLowerCase())}
        />
        // : showSimpleLoading(schemaLoadingMessage)
      }</>,

      // content: (
      //   <CreatePodSchema
      //     searchValue={searchValue}
      //     virtualTableData={virtualTableData}
      //     isReadOnly={isReadOnly}
      //     isUpdateView={isUpdateView}
      //   />
      // ),
    },
  ];
  const handleSchemaPopVisible = (visible) => {
    setSchemaPop(visible);
  };

  const handleNextBtnVisibilty = () => {
    if (getObjectLength(previewTableData) > 0) {
      let headers = previewTableData?.data_header;
      let duplicateArr = headers.filter(
        (col, index) => headers.indexOf(col) !== index
      );
      return duplicateArr.length > 0;
    } else return true;
  };
  const [sizes, setSizes] = useState(["70%", '30%']);

  const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
  return (
    <div className="right-bar-content-fileupload create-pod-rside" style={{ height: "80vh" }}>
      <SplitPane
        split="horizontal"
        sizes={sizes}
        onChange={setSizes}
      // defaultSize={defaultPaneSize}
      // maxSize={450}
      // onDragFinished={(size) => {
      //   resizePanesHandler(size);
      // }}
      >
        <div style={{ ...layoutCSS }}>

          <div className="h-100 w-100 p-2 create-pod-steps">
            <Steps current={current}
              items={
                steps.map(item => (
                  { key: item.title, title: item.title }
                ))
              }
            >

            </Steps>
            <div className="border-top my-2 mx-n2"></div>
            <div className="mt-2 position-relative steps-content-wrap with-note">
              <div className="steps-content">{steps[current]?.content}</div>
              <div className="steps-action">
                {current < steps.length - 1 && (
                  <Tooltip
                    placement="left"
                    title={schemaTooltipMsg}
                    open={showSchemaPop}
                    onOpenChange={handleSchemaPopVisible}
                  >
                    <Buttons
                      props={{

                        buttonText: "Next",
                        buttonClassName:
                          "custom-btn-primary custom-btn btn-with-text",
                        buttonEvent: () => {
                          setSchemaPop(false);
                          setSchemaTooltipMsg("Go to Schema");
                          next();
                        },
                        ImgSrc: () => <RIGHTARROW />,

                        isDisable: handleNextBtnVisibilty(),
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                      }}
                    />
                  </Tooltip>
                )}
                {/* {current === steps.length - 1 && (
                      <Button type="primary" onClick={() => message.success('Processing complete!')}>
                        Done
                      </Button>
                    )} */}
                {current > 0 && (
                  <div className="d-flex">
                    <div style={{ margin: "0 10px" }}>

                      <SearchInput searchData={searchValue} setSearchData={setSearchValue} />
                    </div>
                    <Buttons
                      props={{

                        buttonText: "Previous",
                        buttonClassName:
                          "custom-btn-primary custom-btn btn-with-text",
                        buttonEvent: () => {
                          if (isReadOnly) {
                            prev()
                          }
                          else {
                            setOpenResetPopup(true)
                          }
                          // else if (window.confirm('This action will reset all fields of the schema. \nDo you want to proceed?')) {
                          //   prev();
                          // }
                        },
                        ImgSrc: () => (
                          <span className="prev-ic">
                            <RIGHTARROW />
                          </span>
                        ),
                        isDisable: false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                      }}
                    />
                  </div>



                )}
              </div>
            </div>
          </div>
        </div>


        <div style={{ ...layoutCSS }}>

          <div className="virtual-table-wrapper h-100 create-pod-bottom-dgt-table w-100">
            <DataTabs
              props={{
                activeKey: "1",
                defaultKey: "1",
                onChange: () => { },
                tabArray: explorerTableTabs,
                onEdit: () => { },
              }}
            />

            {Object.keys(virtualTableData).length ? (
              <div className="custom-virtual-data-table h-100 mt-2">
                {
                  dataGridReady
                  ? <DataGridTable
                      resp={virtualTableData}
                      isSplitPane={true}
                      isShowHeader={false}
                      isEnableAnalyze={true}
                      isPreviewDataAlreadyLoading={true}
                    />
                  : getTableSkeleton(10)
                }
              </div>
            ) : (
              <div className="align-items-center d-flex h-100 justify-content-center">
                <div className="text-center">
                  <div>
                    <i className="bx bx-table h1 text-black-50"></i>
                  </div>
                  <div>
                    <label className="text-black-50">No Results To Show</label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </SplitPane>
      {
        getConfirmationModal({
          open: openResetPopup,
          setOpen: setOpenResetPopup,
          onConfirm: () => { prev() },
          heading: 'Confirm',
          body: <span>
            This action will reset all fields of the schema. <br/>Do you want to proceed?
          </span>
        })
      }
    </div>
  );
};

export default RightSideContainer;
