import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { DndVirtualList } from "../../../Common/dndVirtualList/DndVirtualList";
import { SIXDOTS } from "../../../Common/iconSource";
import { Checkbox, Tooltip } from "antd";
import { getColumnDataType } from "../../common/helperFunctions";

const DraggableList = ({ ...props }) => {
  const {
    draggableList,
    isdropTable,
    onChecked,
    selectedFirstSecond,
    setcolumnList = () => { },
    stopDraggingRow = false,
    isHeight100 = true,
  } = props;

  const [cards, setCards] = useState([]);
  const [showCheckBox, setshowCheckBox] = useState([]);
  const targetTableDetails = useSelector(
    (state) => state.Flows.TargetTransformer.targetTableDetails
  );
  const currentTransformer = useSelector(
    (state) => state.FlowsReducer.currentTransformer
  );


  useEffect(() => {
    setshowCheckBox([]);
    if (
      targetTableDetails &&
      targetTableDetails.targetColumns?.length &&
      isdropTable === false
    ) {
      if (currentTransformer && currentTransformer.type === "target") {
        if (
          draggableList &&
          draggableList.length > targetTableDetails.targetColumns?.length
        ) {
          let difference = Math.abs(
            draggableList.length - targetTableDetails.targetColumns?.length
          );
          let temp = [];
          let newDragList = [...draggableList];

          difference = draggableList.length - difference;
          for (let i = difference; i < draggableList.length; i++) {
            temp = [...temp, i];

          }

          setshowCheckBox([...temp]);
        }
        let temp = [...draggableList];

        if (
          draggableList &&
          draggableList.length < targetTableDetails.targetColumns?.length
        ) {
          let difference = Math.abs(
            targetTableDetails.targetColumns?.length - draggableList?.length
          );
          for (let i = 0; i < difference; i++) {
            temp = [
              ...temp,
              {
                id: `emptyColumn_${new Date().getTime()}_${i}}`,
                checked: false,
                Name: "",
                Type: "",
              },
            ];
          }

          cardsView([...temp]);

          setcolumnList([...temp]);
        }
      }
    } else if (isdropTable === true) {

      setshowCheckBox([]);
    }
  }, [targetTableDetails]);

  useEffect(() => {
    if (targetTableDetails && targetTableDetails.targetColumns?.length && isdropTable === false) {
      if (currentTransformer && currentTransformer.type === "target") {
        if (
          draggableList &&
          draggableList.length > targetTableDetails.targetColumns?.length
        ) {
          let difference = Math.abs(
            draggableList.length - targetTableDetails.targetColumns?.length
          );
          let temp = [];
          let newDragList = [...draggableList];

          difference = draggableList.length - difference;
          for (let i = difference; i < draggableList.length; i++) {
            temp = [...temp, i];

            if (newDragList[i] && newDragList[i]?.checked) {
              newDragList[i].checked = false;
            }
          }

          setshowCheckBox([...temp]);
          // setcolumnList([...newDragList])
        }
        let temp = [...draggableList];
        if (
          draggableList &&
          draggableList.length < targetTableDetails.targetColumns?.length
        ) {
          let difference = Math.abs(
            targetTableDetails.targetColumns?.length - draggableList.length
          );
          for (let i = 0; i < difference; i++) {
            temp = [
              ...temp,
              {
                id: `emptyColumn_${new Date().getTime()}_${i}}`,
                checked: false,
                Name: "",
                Type: "",
              },
            ];
          }

          setcolumnList([...temp]);
        }
      }
    } else {

    }
    if (draggableList.length) {
      cardsView([...draggableList]);
    }
  }, [draggableList]);

  const selectDataSource = (type) => {
    if (selectedFirstSecond) {
      let temp = Object.values(selectedFirstSecond).find(
        (item) => item === type
      );

      return `${Object.keys(selectedFirstSecond).find(
        (key) => selectedFirstSecond[key] === temp
      )}`;
    }
  };


  const cardsView = (colList) => {

    if (colList.length > 0) {
      let newcardlist = colList.map((card, i) => {
        return (
          <div
            className={`new-drag-node-list ${selectDataSource(card?.type)} ${window.location.href.includes("viewFlows") ||
              card?.id?.toString()?.includes("emptyColumn") ||
              (showCheckBox?.length &&
                showCheckBox.includes(i) &&
                isdropTable === false)
              ? "new-drag-node-disable"
              : ""
              }`}
          >
            <div>
              <Checkbox
                className="margin-right-2"
                title={
                  showCheckBox?.length && showCheckBox.includes(i)
                    ? "Already selected this column name "
                    : ""
                }
                disabled={
                  window.location.href.includes("viewFlows") ||
                    card?.id?.toString()?.includes("emptyColumn") ||
                    (showCheckBox?.length &&
                      showCheckBox.includes(i) &&
                      isdropTable === false)
                    ? true
                    : false
                }
                checked={
                  card?.checked && (!showCheckBox?.includes(i) || isdropTable === true) ? true : false
                }
                onChange={(e) =>
                  onChecked(
                    card?.Name,
                    card?.Type,
                    i,
                    card?.id
                  )
                }
              />
            </div>
            <div className={`nd-list-content ${card?.showError ? 'invalid-column-present' : ''}`}>
              <div className="align-items-center d-flex h-100 w-100">
                {getColumnDataType(card?.Type)}
                <label className="mb-0 label text-with-ellipsis ml-1">
                  <Tooltip title={card?.Name}>{card?.Name}</Tooltip>
                </label>
              </div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <div className="cursor">
                  {" "}
                  <SIXDOTS />
                </div>
              </div>
            </div>
          </div>
        );
      });
      setCards([...newcardlist]);
    } else {
      return [];
    }
  };

  return (
    <DndVirtualList
      data={cards}
      rawData={draggableList}
      setData={setcolumnList}
      stopDraggingRow={stopDraggingRow}
      isHeight100={isHeight100}
    />

  );
};

export default DraggableList;
