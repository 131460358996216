import React, { useState } from "react";
import { Tooltip, Popover, Input, Dropdown } from "antd";
import ActionPopUp from "../../common/actionPopUp";
import "toastr/build/toastr.min.css";
import Buttons from "../../../Common/button/Buttons";
import { Card, CardBody } from "reactstrap";
import "./alertGridView.scss";
import {
  CREATED_ON,
  DELETEICON,
  EDIT,
  FILLEDSTAR,
  PRIORITY,
  PURPOSE,
  COPY,
  SAVE,
  CLOSE,
  STAR,
  DOMAIN_SMALL,
  PLAY,
  ALERT,
  ACCORDIONDOWNARROW,
  UPDATED_BY,
  USER,
} from "../../../Common/iconSource";
import { Literals } from "../../common/literals";
import {
  BookmarkAlertDataApi,
  DeleteAlertDataApi,
  TriggerAlertDataApi,
} from "../../../../store/modules/alert/alertLanding/alertLandingAction";
import {
  duplicate
} from "../../../../store/modules/dataCatalogue/dictionary/dictionaryAction";
import { useDispatch } from "react-redux";
import { DeleteNoteContent, getAlertPriorityIcon, getRelativeTime, permissions, titleCase, toTitleCase } from "../../common/helperFunctions";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import { options } from "../../../../components/modules/common/toastrProperties";
import Highlighter from "react-highlight-words";
import { ICON_CATALOG_DOMAIN } from "../../../Common/newIconSource";
import DeleteModal from "../../../Common/deleteModal/DeleteModal";
import { emitToastNotification } from "../../../../helpers/toast_helper";

toastr.options = {
  ...options,
};

const alertPermissions = permissions.alerts

const AlertGridView = ({ selectedAlertInfo, setselectedAlertInfo, fromRoute, data, getType, searchValue, getRelativeTime, GetData }) => {

  const history = useNavigate();
  const dispatch = useDispatch();

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);

  const currentAlertRef = React.useRef({ current: {} })

  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };

  const openDeleteDialog = (val) => {

    currentAlertRef.current = val


    setOpenDeletePopup(true);
  };

  const handleDelete = data => {
    dispatch(DeleteAlertDataApi(data.id)).then(() => {
      GetData();
      closeDeletePopUp();
    })
      .catch(() => { })
  }

  const handleSelection = (record) => { };

  const handleEdit = (id, AlertDetails) => {
    if (!fromRoute && fromRoute !== 'scheduler') {

      history(`${Literals.links.ALERTS_EDIT}${id}`, { state: { exceptionid: null, from: 'alert' } })
    } else {
      setselectedAlertInfo(AlertDetails)
    }

    // history.push(`/editAlert/${id}`);
  };

  return (
    <>
      <div className="alertgridview-responsive new-box-style">
        {data.length ?
          data.map((val) => {
            return (
              <div key={`alert-gd-vw-${val?.id}`}>
                <Grid
                  fromRoute={fromRoute}
                  selectedAlertInfo={selectedAlertInfo}
                  setselectedAlertInfo={setselectedAlertInfo}
                  data={val}
                  searchValue={searchValue}
                  getType={getType}
                  handleSelection={handleSelection}
                  getRelativeTime={getRelativeTime}
                  GetData={GetData}
                  handleEdit={handleEdit}
                  openDeleteDialog={openDeleteDialog}
                />
              </div>
            );
          }) : null}
      </div>

      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={DeleteNoteContent(currentAlertRef?.current?.id, 'Alert', `Warning: Deleting this Alert might effect Scheduler.`)}
        onConfirm={() => handleDelete(currentAlertRef?.current)}
      />

      {/* <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent}
      /> */}
    </>
  );
};

export default AlertGridView;

const Grid = ({
  data,
  handleSelection,
  handleEdit,

  searchValue,
  openDeleteDialog,
  getType,
  getRelativeTime,
  fromRoute,
  selectedAlertInfo,
  setselectedAlertInfo,
  GetData,
}) => {

  const history = useNavigate();
  const [renameText, setRenameText] = useState(`${data.name}_copy`);
  const [visible, setVisible] = useState(false);
  const [optionOpen, setOptionOpen] = useState(false)
  const dispatch = useDispatch();

  const checkIsEditor = () => {
    const isRoleEditor = !!data?.permission?.find(v => v === alertPermissions.editor)
    if (isRoleEditor) return true
    return undefined
  }

  const isDeletePermission = checkIsEditor() ?? !!data?.permission?.find(v => v === alertPermissions.delete)
  const isEditPermission = checkIsEditor() ?? !!data?.permission?.find(v => v === alertPermissions.edit)

  const unique_domain_list = [...new Set(data.data_domain?.map((item, i) => item?.data_domain_name?.toLowerCase()))]

  React.useEffect(() => {
    if(!optionOpen) {
      setVisible(false)
    }
  }, [optionOpen])

  const handleSaveClick = () => {
    if(renameText !== ''){
      try {
        dispatch(duplicate({ name: renameText }, "rule", data.id)).then((res) => {
          if (res.status === "success") {
            GetData();
          }
        })
      }
      catch (err) {
  
      }
    }else{
      emitToastNotification('warning','Please provide a name, to create a copy of alert')
    }
   
  }


  const optionList = [
    {
      label: <span className={`dbox-opt-itm ${!isEditPermission ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(TriggerAlertDataApi(data)).then(() => {
            // emitToastNotification('success', 'Execution started successfully!')
          });
        }}>
        <PLAY />
        Execute
      </span>,
      key: '0',
      disabled: !isEditPermission
    },
    {
      label: <span className={`dbox-opt-itm ${!isEditPermission ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!isEditPermission) return
          handleEdit(data.id, data);
        }}>
        <EDIT />
        Edit
      </span>,
      key: '1',
      disabled: !isEditPermission
    },
    {
      label: <Popover
        destroyTooltipOnHide={true}
        placement="right"
        content={
          <form onClick={(e) => {
            e.stopPropagation();
          }}
            style={{ display: "flex", padding: "10px", alignItems: "center" }}>


            <Tooltip title={renameText}>
              <Input onKeyDown={(e) => {e.stopPropagation()}} value={renameText} onChange={(e) => setRenameText(e.target.value)} />
            </Tooltip>
            <Buttons
              props={{
                actionType: "submit",
                buttonText: "",
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1",
                buttonEvent: (e) => {
                  e.preventDefault();
                  handleSaveClick();
                },
                ImgSrc: () => <SAVE />,
                isDisable: false,
                buttonType: Literals.BTN_SECONDARY,
              }}
            />
          </form>
        }
        title={
          <div onClick={(e) => {
            e.stopPropagation();
          }}
            style={{ display: "flex", padding: "10px", justifyContent: "space-between" }}><span>Create a Copy</span> <Buttons
              props={{
                buttonText: "",
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setVisible(false);
                },
                ImgSrc: () => <CLOSE />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            /></div>
        }
        onOpenChange={(visible) => setVisible(visible)}
        trigger="click"
        open={isEditPermission && visible}
      >
        <span className={`dbox-opt-itm ${!isEditPermission ? 'disabled' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isEditPermission) return
            setVisible(true)
          }}>
          <COPY />
          Duplicate
        </span>
      </Popover>,
      key: '3',
      disabled: !isEditPermission
    },
    {
      label: <span className={`dbox-opt-itm danger ${!isDeletePermission ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!isDeletePermission) return
          openDeleteDialog(data);
        }}>
        <DELETEICON />
        Delete
      </span>,
      key: '4',
      disabled: !isDeletePermission
    },
  ]

  return (
    <Card
      onClick={(e) => {
        if (!fromRoute && fromRoute !== 'scheduler') {

          e.stopPropagation();
          // history.push(`/viewAlert/${data.id}`);
          history(`${Literals.links.ALERTS_VIEW}${data.id}`, { state: { exceptionid: null, from: 'alert' } })
        } else {
          setselectedAlertInfo(data)
        }


      }}
      className={`border-0 ${selectedAlertInfo?.id === data?.id ? 'alert-selected-card' : ''}`}
    >
      <CardBody>

        <div className='data-view-dbox' onClick={() => { }}>
          <div className='_src_img'>
            <div className="prsp-idt text-center mt-1">
              <Tooltip title={`Purpose: ${data.purpose} Alert`} placement={'right'}>
                <div className={`prsp-ic noti-alert-prps-ic ${data.purpose.toLowerCase() === "quality"
                  ? "quality"
                  : "business"}`}>
                  <ALERT />
                  {/* <PURPOSE /> */}
                </div>
              </Tooltip>
            </div>

            <div className="alert-extra-identifiers">
              <Tooltip title={`Priority: ${data.priority}`} placement={'right'}>
                {getAlertPriorityIcon(data?.priority?.toLowerCase(), 'mx-auto')}

                {/* <div className="prior-idt">
                  <div className="prior-ic">

                    <ACCORDIONDOWNARROW />
                  </div>
                  <div className="prior-sep"></div>
                  <div className="prior-text text-uppercase">
                    {data?.priority?.charAt(0)}
                  </div>
                </div> */}
              </Tooltip>
            </div>
            <div className={`alert-icon-box`}>
              <Tooltip title={toTitleCase(data?.type ?? '')} placement={'right'}>
                <span>
                  {getType(data.type, 'gridview')}
                </span>
              </Tooltip>
            </div>
          </div>
          <div className='desc-box'>
            <div className="d-flex align-items-start">
              <Tooltip placement='topLeft' title={data?.name?.toUpperCase()}>
                <label style={{ width: 'calc(100% - 52px)' }} className='paragraph mt-1 title-txt font-w-600 text-uppercase text-with-ellipsis'>
                  <Highlighter searchWords={[searchValue?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={data.name ? data.name : ''}> {data.name}</Highlighter>
                </label>
              </Tooltip>
             {fromRoute !== 'scheduler'? <Buttons
                props={{
                  buttonText: '',
                  tooltip: data.bookmark ? Literals.REMOVE_FAVOURITES : Literals.ADD_FAVOURITES,
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                  buttonEvent: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    dispatch(BookmarkAlertDataApi(data.id, data.bookmark)).then(
                      () => {
                        GetData();
                      }
                    );
                  },
                  ImgSrc: () => {
                    return data.bookmark ? <FILLEDSTAR /> : <STAR />;
                  },
                  isDisable: false,
                  buttonType: Literals.BTN_TERTIARY
                }}
              />:null}


              {fromRoute !== 'scheduler' 
                ? <Dropdown
                    destroyPopupOnHide={true}
                    menu={{
                      items: optionList,
                      onClick: (e) => e.domEvent.stopPropagation()
                    }}
                    trigger={["hover"]}
                    placement="bottomRight"
                    onOpenChange={(bool) => setOptionOpen(bool)}
                    overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                  >
                <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
              </Dropdown> 
              : null}
            </div>
            <div>
              <Tooltip placement='topLeft' title={data.description}>
                <p className='mb-1 paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                  {data.description?.length > 0 ?
                    <Highlighter searchWords={[searchValue?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={data.description ? data.description : ''}>{data.description}</Highlighter>
                    : "No description has been given yet."}
                </p>
              </Tooltip>
            </div>
            <ul className="landingCard-profile-list">
              <li className="landingCard-profile-listitem">
                <UPDATED_BY height="14" width="14" />
                <span className="label label-gray ml-1">{'Last Updated'} </span>
                <Tooltip placement="top" title={getRelativeTime(new Date(data.updated_on), undefined)}>
                  <span className="paragraph ml-2">
                    {getRelativeTime(data?.updated_on)}
                    {/* <Highlighter searchWords={searchValue && searchValue.length ? [searchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={getRelativeTime(new Date(data.created_on), undefined)} /> */}

                  </span>
                </Tooltip>
              </li>
              <li className="landingCard-profile-listitem">
                <USER height="14" width="14" />
                <span className="label label-gray ml-1">{Literals.UPDATED_BY} </span>
                <Tooltip placement="top" title={data?.updated_by_user?.email}>
                  <span className="paragraph ml-3">

                    <Highlighter searchWords={searchValue && searchValue.length ? [searchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.updated_by_user?.email} />

                  </span>
                </Tooltip>
              </li>
            </ul>
            {/* <div className="round-tags-container mt-1">
              <span className="alert-dmn-ic"><ICON_CATALOG_DOMAIN width="14" height="14" /></span>
              {
                unique_domain_list.map((v, i) => {

                  return <>
                    {
                      i < 2
                        ?
                        <div key={`alert-domains-ls-${i}`} className='custom-badge success mr-2 text-uppercase'>
                          {v}
                        </div>

                        : ''
                    }
                  </>
                })
              }
            </div> */}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};