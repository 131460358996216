
import React, { useContext, useEffect, useState } from 'react'
import { LandingPageSubHeaderContext } from './LandingPageSubHeaderContext';
import { Tabs } from 'antd';

function LandingPageSubHeaderLeftSide({ isDashboardIndex = false, onTabChange }) {

    const TabsMenuContext = useContext(LandingPageSubHeaderContext)
    const [activeTab, setActiveTab] = useState(TabsMenuContext.defaultActiveTab);


    //*************************THIS CODE IS EFFECTING IN DATA DICTIONARY HEADER********************//
    useEffect(() => {
        setActiveTab(TabsMenuContext.defaultActiveTab)
    }, [TabsMenuContext])

    return (

        <div className="tab-menu left-flow-header ">
            {!isDashboardIndex ? TabsMenuContext.tabsMenuList.map((menu, i) =>
                <p key={`${i}-${menu}`} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setActiveTab(menu.key); menu.onClickEvent(menu.key) }} className={activeTab?.toLowerCase() === menu?.key?.toLowerCase() ? 'active subtitle' : 'subtitle'}>{menu.Icon()} {menu.title}</p>

            ) :
                TabsMenuContext.tabsMenuList?.length ? <Tabs
                    activeKey={activeTab}
                    defaultActiveKey={activeTab}
                    onChange={(key) => { onTabChange(key);setActiveTab(key) }}
                    items={TabsMenuContext.tabsMenuList?.length && TabsMenuContext.tabsMenuList.map((element, i) => {
                        const id = String(i);
                        return {
                            label: element.tabTitle,
                            key: element.key,
                            children: element.tabContent ? element?.tabContent(element.key) : null,
                            closable: element.closable,
                            disabled: element.disabled
                        };
                    })}
                /> : null}
        </div>

    )
}

export default LandingPageSubHeaderLeftSide

