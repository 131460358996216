import useIntersection from '../../../customHooks/useIntersection';
import React, { useEffect } from "react";

function LazyLoadingWrapper({ paginateMethod,scrollbarHeight, TableDiv, showLoader, setScroll, stopLazyload = false }) {

    const { bottomRef, rootRef } = useIntersection(() => { if(!stopLazyload){paginateMethod()} });
    useEffect(() => {
        if (setScroll) {
            setRef()
        }

    }, [bottomRef, rootRef, setScroll])



    const setRef = () => {

        let position = Math.round(rootRef.current.scrollHeight - rootRef.current.offsetHeight)
        if (position > 0) {
            rootRef.current.scrollTo(0, position - (position/2));

        }
    }

    return (
        <div id='timeline-list' className="" style={{overflowY: 'auto', overflowX: 'hidden', height:scrollbarHeight?scrollbarHeight: 'calc(100% - 0px)'}} ref={rootRef}>
            {<TableDiv />}
            <div ref={bottomRef} id="table-bottom" style={{height: 1}} className={`text-center w-100 ${stopLazyload ? 'd-none': ''}`}>{showLoader ? <span className="bx bx-loader bx-spin font-size-16 align-middle "></span> : ''}</div>
        </div>
    )
}

export default LazyLoadingWrapper
