import { Tooltip } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import useDebounce from '../../../../../customHooks/useDebounce';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { CHECKOUTPUTNAME, ERRORCOLORCLOSE, OUTPUTNAMEICON } from '../../../../Common/iconSource';
import { preventEnterEvent, preventSpaceEvent } from '../../../common/helperFunctions';

import { setTransformerNameinflowselement } from '../FlowsHelperFunction';



function DatasetOutputNameInput({ transformerName, isdisabled = false, setTransformerName, transformerId }) {

    const [term, setTerm] = useState('')
    const [showSuccess, setshowSuccess] = useState(false)
    const [showError, setshowError] = useState(false)
    const [animate, setAnimate] = useState(false)

    const [firstTimeSet, setfirstTimeSet] = useState(false)
    const currentTransformer = useSelector(state => state.FlowsReducer.currentTransformer)
    const transformerNameList = useSelector(state => state.FlowsReducer.transformerName)

    const debouncedSearchTerm = useDebounce(term, 700);
    const store = useStore()

    useEffect(() => {
        if (_.isEmpty(term)) {
            setAnimate(true)
        }
    }, [term])

    useEffect(
        () => {

            if (debouncedSearchTerm && !firstTimeSet) {


                if (Object.keys(transformerNameList)) {
                    let isTransformerNameExist = false
                    for (const key in transformerNameList) {
                        const element = transformerNameList[key];
                        if (element === debouncedSearchTerm) {
                            isTransformerNameExist = true
                        }
                    }
                    if (!isTransformerNameExist) {
                        setshowSuccess(true);
                        setshowError(false)
                        setTransformerName(debouncedSearchTerm)
                        store.dispatch(setTransformerNameinflowselement(debouncedSearchTerm))
                        if (transformerId) {
                            transformerNameList[transformerId] = debouncedSearchTerm

                        } else {

                            transformerNameList[currentTransformer.id] = debouncedSearchTerm
                        }
                    } else {
                        setshowSuccess(false)
                        setTransformerName('')
                        if (transformerId) {
                            transformerNameList[transformerId] = ''

                        } else {

                            transformerNameList[currentTransformer.id] = ''
                        }


                        setshowError(true)
                        emitToastNotification('error', 'This name is already exist, please enter other transformer name')

                    }
                } else {
                    setshowSuccess(true)
                    setshowError(false)
                    if (transformerId) {
                        transformerNameList[transformerId] = debouncedSearchTerm

                    } else {

                        transformerNameList[currentTransformer.id] = debouncedSearchTerm
                    }

                    setTransformerName(debouncedSearchTerm)
                    store.dispatch(setTransformerNameinflowselement(debouncedSearchTerm))

                }

            } else if (!debouncedSearchTerm) {










            }

        },

        [debouncedSearchTerm]
    );

    useEffect(() => {
        let transformerNameList = store.getState().FlowsReducer.transformerName;
        if (Object.keys(transformerNameList).length && currentTransformer.id && transformerNameList[currentTransformer.id]) {
            setTerm(transformerNameList[currentTransformer.id])
            setshowSuccess(true)
            setfirstTimeSet(true)
        }

    }, [])

    useEffect(() => {
        if (transformerName !== '') {
            setTerm(transformerName)
            transformerNameList[currentTransformer.id] = transformerName

            setshowSuccess(true)
            setfirstTimeSet(true)
        }
    }, [transformerName])


    return (

        <span className={`position-relative mr-2 d-flex align-items-center`}>

            <div className={`custom-search-icon output-name-ic mr-1 ${_.isEmpty(term) && animate ? 'bx-tada required' : ''}`}>
                <OUTPUTNAMEICON />
            </div>
            <Tooltip placement='bottom' title={!showError ? "Only alphanumeric character and underscores are allowed in output dataset name" : "This name is already exist"}>
                <div className={`custom-search ${_.isEmpty(term) && animate ? 'bx-flashing' : ''}`}>
                    <input
                        type="text"
                        placeholder='Enter Transformer Name'
                        onClick={() => setAnimate(false)}
                        onBlur={() => setAnimate(_.isEmpty(term))}
                        onChange={(e) => {

                            if (!e.target.value) {

                                setshowSuccess(true)
                                setshowError(false)
                                setTransformerName('')
                                store.dispatch(setTransformerNameinflowselement(''))

                                if (transformerId) {
                                    transformerNameList[transformerId] = ''

                                } else {

                                    transformerNameList[currentTransformer.id] = ''
                                }
                            }
                            setTerm(e.target.value); setshowError(false);
                            setfirstTimeSet(false);
                            setshowSuccess(false);
                        }}
                        onKeyDown={(e) => { preventEnterEvent(e); preventSpaceEvent(e) }}
                        className={showSuccess ? "custom-search-input trans-input transformerName_input_success pl-2" : showError ? "custom-search-input trans-input transformerName_input_error pl-2" : 'custom-search-input trans-input pl-2'}
                        value={term}
                        disabled={isdisabled || window.location?.href?.includes('viewFlows')}

                    />
                    {/* <p className='tablename-note'><NOTEINFO />Only alphanumeric character and underscores are allowed</p> */}

                    <div className="custom-close-icon">
                        {showSuccess && <CHECKOUTPUTNAME />}
                        {showError && <ERRORCOLORCLOSE />}
                    </div>
                    {/* <img
                        src={CloseIcon}
                        alt="SearchIcon"
                        
                        
                    /> */}
                </div></Tooltip>

        </span>



    );
}




export default DatasetOutputNameInput
