export const RESET_DASHBOARD_REDUX_STATE = "RESET_DASHBOARD_REDUX_STATE";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const SET_DASHBOARD_LAYOUTS = "SET_DASHBOARD_LAYOUTS";
export const SET_DASHBOARD_BREAKPOINT = "SET_DASHBOARD_BREAKPOINT";
export const SET_DASHBOARD_TABLE_DATA = "SET_DASHBOARD_TABLE_DATA";
export const SET_ANALYSIS_DATA = "SET_ANALYSIS_DATA";
export const DATA_DOMAIN_LIST = "DATA_DOMAIN_LIST";
export const SET_FULL_SCREEN = "SET_FULL_SCREEN";
export const SET_DASHBOARDLOADING_STATUS = "SET_DASHBOARDLOADING_STATUS";
export const EDIT_DASHBOARD_ITEMS = "EDIT_DASHBOARD_ITEMS";
export const SET_DASHBOARD_STATE = "SET_DASHBOARD_STATE";
export const SET_DASHBOARD_REFRESHDATA = "SET_DASHBOARD_REFRESHDATA";
export const SET_DASHBOARD_DEFAULT = "SET_DASHBOARD_DEFAULT";
export const SETS_ACTIVE_KEY = "SETS_ACTIVE_KEY";
export const SET_DYNAMIC_FILTERS = "SET_DYNAMIC_FILTERS";
export const SET_SELECTED_ANALYSIS = "SET_SELECTED_ANALYSIS";

export const SET_OPEN_ANALYSIS = "SET_OPEN_ANALYSIS";
export const SET_DASHBOARD_DEFAULTWITHANALYSIS =
  "SET_DASHBOARD_DEFAULTWITHANALYSIS";
export const SET_VIEW = "SET_VIEW";
export const SET_ANALYSIS_FILTERS_DATA = "SET_ANALYSIS_FILTERS_DATA";
export const SET_ANALYSIS_GRAPHS_DATA = "SET_ANALYSIS_GRAPHS_DATA";
export const SET_ANALYSIS_FILTER_GRAPHS_DATA =
  "SET_ANALYSIS_FILTER_GRAPHS_DATA";

export const SET_APPLIED_FILTER_NAME = "SET_APPLIED_FILTER_NAME";
export const SET_FILTERS_COUNT = "SET_FILTERS_COUNT";

export const RESET_ANALYSIS_FILTERS_DATA = "RESET_ANALYSIS_FILTERS_DATA";
export const SET_SELECTED_FILTER_VALUES = "SET_SELECTED_FILTER_VALUES";
export const SET_ANALYSIS_FILTERS_LIST = "SET_ANALYSIS_FILTERS_LIST";
export const SET_INITIAL_EDIT_FILTERS = "SET_INITIAL_EDIT_FILTERS";
