import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchUserGroupList } from "../../../../../store/modules/accessManagement/actions";
import ColumnLevelMasking from "./ColumnLevelMasking";
import RowLevelMasking from "./RowLevelMasking";
import UserGroupModal from "./UserGroupModal";
import UserGroups from "./UserGroups";
import { forwardRef } from "react";
const PolicyRightSide =forwardRef(({props},ref)=>{
  
  const dispatch = useDispatch();
  const [isUserGroupModalOpen, setIsUserGroupModalOpen] = useState(false);
 const {    domainName,
  domainId,
  selectedUserGroups,
  setSelectedUserGroups,
  columnDetails,
  filters,
  initialColumns,
  setFilterPop,
  setAppliedFilter,
  appliedFilter,
  setFilters,
  addNewFilter,
  onChangePermission,
  groupPermissions,
  maskedColumns,
  setMaskedColumns,
  viewMode,
  isEditorPermission = false,
  policyData = {}
}=props

  const toggleUserGroupModal = () => {
    setIsUserGroupModalOpen((isUserGroupModalOpen) => !isUserGroupModalOpen);
  };

  const fetchUserGroupsHandler = () => {
    dispatch(fetchUserGroupList(domainId));
  };

  useEffect(() => {
    if (domainId) {
      fetchUserGroupsHandler();
    }
  }, [domainId]);

  return (
    <Fragment>
      <UserGroups
        toggleModal={toggleUserGroupModal}
        selectedUserGroups={selectedUserGroups}
        setSelectedUserGroups={setSelectedUserGroups}
        onChangePermission={onChangePermission}
        groupPermissions={groupPermissions}
        viewMode={viewMode}
        isEditorPermission={isEditorPermission}
      />
      <RowLevelMasking
      ref={ref}
       props={ {filters, addNewFilter, setFilters, viewMode, initialColumns,
        setFilterPop,
        setAppliedFilter,
        appliedFilter,
        isEditorPermission,
        policyData
  }
  }
      />
      <ColumnLevelMasking
        columnDetails={columnDetails}
        maskedColumns={maskedColumns}
        setMaskedColumns={setMaskedColumns}
        viewMode={viewMode}
        isEditorPermission={isEditorPermission}
      />
      <UserGroupModal
        domainName={domainName}
        selectedUserGroups={selectedUserGroups}
        setSelectedUserGroups={setSelectedUserGroups}
        isModalOpen={isUserGroupModalOpen}
        toggleModal={toggleUserGroupModal}
      />
    </Fragment>
  );
});

export default PolicyRightSide;
