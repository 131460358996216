import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setSidebarActiveRoute } from '../../../store/actions'
import { ConnectionProvider } from '../connections/landingPage/components/ConnectionContext'
import LandingPage from './components/LandingPage'

import './dataDictionary.scss'

export const DataDictionary = ({ 
  fromRoute,
  populateSearchData = '',
   ...props 
  }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setSidebarActiveRoute('/data-dictionary'))
  }, [])
  return (
    <>
      <ConnectionProvider>
        <LandingPage 
          fromRoute={fromRoute} 
          populateSearchData={populateSearchData}
          {...props} 
        />
      </ConnectionProvider>
    </>
  )
}
