import { emitToastNotification } from "../../../helpers/toast_helper";
import { fetchData } from "../../../services/apiService";
import { setLoadingStatus } from "../common/loadingActions";
import {
  SET_POD_DETAILS,
  SET_TAGS_LIST,
  SET_USER_GROUPS_LIST,
  SET_POLICY_LIST,
  SET_SELECTED_POLICY,
} from "./actionTypes";

const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const setTagsList = (payload) => {
  return {
    type: SET_TAGS_LIST,
    payload,
  };
};

export const setPolicyList = (payload) => {
  return {
    type: SET_POLICY_LIST,
    payload,
  };
};

export const setSelectedPolicy = (payload) => {
  sessionStorage.setItem("selectedPolicy", JSON.stringify(payload));

  return {
    type: SET_SELECTED_POLICY,
    payload,
  };
};

export const fetchPodDetails = (podId) => async (dispatch) => {
  try {
    dispatch(setLoadingStatus(true));
    const searchParams = new URLSearchParams(document.location.search)
    const history_id=searchParams.get("history_id");
    const start_date=searchParams.get("start_date");
    const end_date=searchParams.get("end_date");

    const { status, data, message } = await dispatch(
      fetchData("GET", `${BASE_URL}catalog_home/v2/${podId}/${history_id?`?history_id=${history_id}&start_date=${start_date}&end_date=${end_date}`:""}`)
    );

    if (status === "success") {
      let podDetails = {};

      // data.data_pod.data_header.forEach((key, index) => {
      //   podDetails[key] = data.data_pod.data_record[0][index];
      // });
      podDetails['table_name'] = data.table_name;
      podDetails['data_domain_name'] = data.data_domain_detail.data_domain_name
      podDetails['data_domain_id'] = data.data_domain_detail.data_domain_id
      podDetails['permission'] = data.permission
      const columnDetails = data.column_detail
      // const columnDetails =
      //  data.data_column.data_record.map((details) => {
      //   const columnData = {};
      //   details.forEach((value, index) => {
      //     columnData[data.data_column.data_header[index]] = value;
      //   });
      //   return columnData;
      // });

      // data.data_column.data_header.forEach((key, index) => {
      //   columnDetails[key] = data.data_column.data_record[0][index];
      // });

      dispatch({
        type: SET_POD_DETAILS,
        payload: {
          podDetails,
          columnDetails,
        },
      });
    } else if (status === "success" && data?.data_pod?.data_count === 0) {
      emitToastNotification("info", "No data in records");
    } else {
      emitToastNotification("error", message);
    }

    dispatch(setLoadingStatus(false));
  } catch (error) {
    console.error("Error fetching podDetails");
    emitToastNotification("error", error.message);
    dispatch(setLoadingStatus(false));
  }
};

export const fetchUserGroupList = (domainId) => async (dispatch) => {
  try {
    dispatch(setLoadingStatus(true));
    const { status, data, message } = await dispatch(
      fetchData(
        "GET",
        `${BASE_URL}group_manager/group/?data_domain_id=${domainId}`
      )
    );

    if (status === "success") {
      dispatch({
        type: SET_USER_GROUPS_LIST,
        payload: data,
      });
    } else {
      emitToastNotification("error", message);
    }

    dispatch(setLoadingStatus(false));
  } catch (error) {
    console.error("Error");
    emitToastNotification("error", error.message);
  }
};

export const fetchPolicyList = (podId) => async (dispatch) => {
  try {
    dispatch(setLoadingStatus(true));
    const { status, data, message } = await dispatch(
      fetchData("GET", `${BASE_URL}policy/?table_id=${podId}`)
    );

    if (status === "success") {
      dispatch(setPolicyList(data));
    } else {
      emitToastNotification("error", message);
    }

    dispatch(setLoadingStatus(false));
  } catch (error) {
    console.error("Error fetching policy list");
    emitToastNotification("error", error.message);
    dispatch(setLoadingStatus(false));
  }
};

export const fetchPolicyDetails = (policyId) => async (dispatch) => {
  try {
    dispatch(setLoadingStatus(true));
    const { status, data, message } = await dispatch(
      fetchData("GET", `${BASE_URL}policy/${policyId}/`)
    );

    if (status === "success") {
      dispatch(setSelectedPolicy(data));
    } else {
      emitToastNotification("error", message);
    }

    dispatch(setLoadingStatus(false));
  } catch (error) {
    console.error("Error fetching policy list");
    emitToastNotification("error", error.message);
    dispatch(setLoadingStatus(false));
  }
};
export const deletePolicy = (policyId) => async (dispatch, getState) => {
  try {
    dispatch(setLoadingStatus(true));

    const { status, message } = await dispatch(
      fetchData("DELETE", `${BASE_URL}policy/${policyId}/`)
    );

    if (status === "success") {
      let { policyList } = getState().AccessManagement;
      policyList = policyList.filter((policy) => policy.id !== policyId);
      dispatch(setPolicyList(policyList));
    } else {
      emitToastNotification("error", message);
    }

    dispatch(setLoadingStatus(false));
  } catch (error) {
    dispatch(setLoadingStatus(false));
    console.error("Error deleting policy");
    emitToastNotification("error", error.message);
  }
};
