import React from 'react';
import DatabricksLogo from "../../../../../assets/icons/DatabricksLogo.svg";
import { CLARISTA_LOGO, LOADEXISTING, PLUS } from '../../../../Common/iconSource';
import Buttons from '../../../../Common/button/Buttons';
import { Literals } from '../../../common/literals';
import SingleSelection from '../../../../Common/dropDown/SingleSelection';
import { Tooltip } from 'antd';


function DatabricksLandingPage({ disableExcute = false, setshowDatabricksLandingPage, bricksList, sethostValue, hostValue, createClusterConfig, selectedBricksConnection, setselectedBricksConnection, setshowloadExisting }) {
    return (
        <div className='no-datasource bricks-landingpage flex-column '>
            <img src={DatabricksLogo} alt="Databricks" height={100} width={250} ></img>
            <h5 className='text-center mt-4'>Select your Workspaces</h5>

            <div className='mt-3 d-flex align-items-center' >
                <div className="form-group col-6">
                    <SingleSelection props={{
                        label: "Databricks Connection", className: " custom-select-dropdown", placeholder: "Select a connection", dropDownValue: selectedBricksConnection, onClickEvent: (value) => {
                            setselectedBricksConnection(value);
                            let host = bricksList.find((item) => item.Name?.toLowerCase() === value?.toLowerCase())

                            if (host.fullObj?.masked_properties?.host !== '') {

                                sethostValue(host.fullObj?.masked_properties?.host)
                            }

                        }, isDisable: window.location.href.includes('viewFlows') ? true : false, optionList: bricksList, isSortOptionDisable: true
                    }} />
                </div>
                <div className='form-group col-6'>
                    <label className='label'> HOST </label>
                    <Tooltip title={hostValue} placement="topLeft">  <input className='custom-input-field text-elipse' placeholder='Host' type="text" readOnly="true" value={hostValue}></input></Tooltip>
                </div>

            </div>
            <div className='mt-2 d-flex align-items-center justify-content-center databricks-landing-btn '>
                {/* <Buttons props={{ buttonText: 'Crate New', buttonClassName: "custom-btn-primary databricks-btn  custom-btn btn-with-text", showLoading: false, buttonEvent: () => {setshowDatabricksLandingPage(false);createClusterConfig() }, ImgSrc: ()=><PLUS />, isDisable: disableExcute ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> */}
                <Buttons props={{ buttonText: 'Load Databricks Notebook', buttonClassName: "w-100 custom-btn-primary databricks-btn custom-btn btn-with-text", showLoading: false, buttonEvent: () => { setshowloadExisting(true); setshowDatabricksLandingPage(false); createClusterConfig() }, ImgSrc: () => <LOADEXISTING width='12px' height='12px' />, isDisable: !hostValue ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
            </div>
            <div className='databricks-note'>
                <div>
                    <CLARISTA_LOGO width={'15px'} height={'15px'} />
                    <span className="ml-2">Clarista supports following features in databricks.</span>
                </div>
                <ul>
                    <li>Cluster Management</li>
                    <li>Notebook Management</li>
                    <li>Task Management</li>
                </ul>
            </div>
        </div>
    )
}

export default DatabricksLandingPage