import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector, useStore } from "react-redux";
import LandingPageSubHeader from "../../../Common/landingPageSubHeader/LandingPageSubHeader";
import LandingpageFirstHeader from "../../common/LandingpageFirstHeader";
import NoSearchResultFound from '../../common/NoSearchResultFound';

import _ from 'lodash';
import { useNavigate, useParams } from "react-router-dom";
import {
  setSidebarActiveRoute
} from "../../../../store/actions";
import { setLoadingStatus } from "../../../../store/modules/common/loadingActions";
import {
  DeleteScheduler, getSchedulerLandingPage
} from "../../../../store/modules/scheduler/action";
import { RESET_SCHEDULER_REDUX_ } from "../../../../store/modules/scheduler/SchedulerActionType";
import Buttons from "../../../Common/button/Buttons";
import Filter from "../../../Common/filter/Filter";
import { CRAWLER, PLUS, SCHEDULER } from "../../../Common/iconSource";
import ActionPopUp from "../../common/actionPopUp";
import {
  getRelativeTime,
  NoDataComponent,
  ErrorComponent,
  getDeleteMessage
} from "../../common/helperFunctions";
import { Literals } from "../../common/literals";
import LoadingComponent from "../../common/loadingComponent";
import { SchedulersGrid } from "./SchedulersGrid";
import { SchedulersList } from "./SchedulersList";
import { setSelectedPodList } from "../../../../store/modules/common/podSelectionModalAction";
import { Tooltip } from "antd";
import DeleteModal from "../../../Common/deleteModal/DeleteModal";

function LandingPage(props) {
  const history = useNavigate();
  const store = useStore()
  let { fromRoute = "scheduler", loadingStatus } = props;
  // const loadingStatus = useSelector(state => state.LoadingReducer.loadingStatus)
  const { schedulerId } = useParams()
  const [Selectedid, setshowSelectedCard] = useState(null);
  const selectedTempScheduler = useSelector(state => state.Scheduler.selectedTempScheduler)
  const [isButtonActive, setIsButtonActive] = useState(false);

  const currentSchedulerIdRef = React.useRef({current: {}})

  useEffect(() => {

    if (selectedTempScheduler?.id) {
      setshowSelectedCard(selectedTempScheduler?.id)
    } else {
      setshowSelectedCard(null)
    }
  }, [selectedTempScheduler])

  useEffect(() => {
    if (fromRoute.toLowerCase() !== 'schedulermodal') {

      dispatch({ type: RESET_SCHEDULER_REDUX_, payload: {} })
      dispatch(setSelectedPodList([]));
    }
  }, [fromRoute])


  const [showFilter, setshowFilter] = useState(false);
  const [showListView, setshowListView] = useState(false);
  const [showGridView, setshowGridView] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [empty, setEmpty] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
  const [schedulerData, setSchedulerData] = useState([]);
  const [schedulerOriginalData, setschedulerOriginalData] = useState([]);

  const schedulerFilter = useSelector(state => state.App);
  const [selectedFilters, setSelectedFilters] = useState([]);


  const [filterList,] = useState([
    {
      header: "",
      selectAll: false,
      list: [{ id: 1, checked: false, name: "Created By Me" }],
    },
    {
      header: "Scheduler Type",
      selectAll: false,
      list: [
        { id: 1, checked: false, name: "Event Based", actualValue: "EVENT" },
        { id: 2, checked: false, name: "Time Based", actualValue: "TIME" },
        { id: 3, checked: false, name: "Crawler", actualValue: "PARTITION" },
      ],
    },
    {
      header: "Scheduler State",
      selectAll: false,
      list: [
        { id: 1, checked: false, name: "Active", actualValue: true },
        { id: 2, checked: false, name: "Inactive", actualValue: false },
      ],
    },
    {
      header: "Scheduler Status",
      selectAll: false,
      list: [
        { id: 1, checked: false, name: "Success", actualValue: "SUCCESS" },
        { id: 2, checked: false, name: "Running", actualValue: "RUNNING" },
        { id: 3, checked: false, name: "Failed", actualValue: "FAILED" },
        // { id: 4, checked: false, name: "Null-Type", actualValue: null },
      ],
    },
  ]);

  const openDeleteDialog = (id) => {

    currentSchedulerIdRef.current = id

    // setDeletePopUpContent({
    //   modalClass: "delete-popup",
    //   heading: "Delete",
    //   message: "This action will delete this scheduler.",
    //   button: [
    //     {
    //       name: "Delete",
    //       action: () => {
    //         dispatch(DeleteScheduler(id)).then((resp) => {
    //           if (resp.status === "success") {
    //             getSchedulerList();
    //           }
    //         });
    //         setOpenDeletePopup(false);
    //       },
    //     },
    //   ],
    //   elements: [],
    //   closeActionPopup: () => setOpenDeletePopup(false),
    // });
    setOpenDeletePopup(true);
  };

  const handleDelete = id => {
    dispatch(DeleteScheduler(id)).then((resp) => {
      if (resp.status === "success") {
        getSchedulerList();
        setOpenDeletePopup(false);
      }
    })
    .catch(() => {})
  }

  const filterData = () => {
    let schedulerType = [];
    let schedulerStatus = [];
    let schedulerState = [];
    const myMail = store.getState().UserReducer.user.userDetails.email;

    let empty = [];
    if (selectedFilters.length > 0) {
      selectedFilters.forEach((val) => {
        if (val["Scheduler Type"]) {
          schedulerType = val["Scheduler Type"].map((val) => val.actualValue) || [];
        }
        if (val["Scheduler Status"]) {
          schedulerStatus = val["Scheduler Status"].map((val) => val.actualValue) || [];

        }
        if (val["Scheduler State"]) {
          schedulerState = val["Scheduler State"].map((val) => val.actualValue) || [];

        }
        if (val["emptyheader"]) {
          empty = val["emptyheader"].map((val) => val.name) || [];
        }
      });
    }

    let obj = [...schedulerOriginalData];

    if (schedulerId) {
      obj = obj.filter((item) => {
        if (schedulerId && schedulerId?.toString() !== item.id.toString()) {
          return item
        }
      })
    }

    obj.forEach((val) => {
      if (schedulerType.length) {
        obj = obj.filter((a) => {
          if (schedulerType.includes('EVENT') && (a.type?.toLowerCase() === 'pod' || a.type?.toLowerCase() === 'scheduler')) {
            return a;
          } else {
            return schedulerType.includes(a?.type);
          }
        });
      }

      if (schedulerState.length) {
        obj = obj.filter((a) => {

          return schedulerState.includes(a?.active);
        });
      }
      if (schedulerStatus.length) {
        obj = obj.filter((a) => {
          return schedulerStatus.includes(a?.latest_status);
        });
      }
      if (empty.length) {
        obj = obj.filter(a => a.created_by_user?.email?.toLowerCase().includes(myMail.toLowerCase()))
      }
    });

    if (selectedFilters.length) {
      return obj;
    } else {
      return (schedulerOriginalData);
    }
  };

  const setshowFilterPopup = () => {
    setshowFilter(!showFilter);
  };

  const dispatch = useDispatch();
  const getSchedulerList = () => {
    dispatch(getSchedulerLandingPage()).then((resp) => {
      setErrMsg('')

      if (resp.data.length == 0) {
        setEmpty(true);
      }
      // if (resp.data[0]?.view_type === "grid") {

      //   setshowGridView(true);
      //   setshowListView(false)

      // }
      // else {
      //   setshowListView(true)
      //   setshowGridView(false);

      // }
      if (resp && resp.data?.length) {
        setSchedulerData(resp.data);
        setschedulerOriginalData(resp.data);
      } else {
        dispatch(setLoadingStatus(false));

        setSchedulerData([]);
        setschedulerOriginalData([]);
      }
    }).catch((e) => {
      setLoadingStatus(false);
      setErrMsg(e.message)
    })
  };
  useEffect(() => {
    getSchedulerList();

  }, []);

  useEffect(() => {

    if (filterData().length && searchData !== "") {
      let filteredData = filterData().filter((data) => {
        return (
          data?.name?.toLowerCase().includes(searchData?.toLowerCase()) ||
          data?.description
            ?.toLowerCase()
            .includes(searchData?.toLowerCase()) ||
          getRelativeTime(data?.created_on)
            ?.toLowerCase()
            .includes(searchData?.toLowerCase()) ||
          data?.created_by_user?.email
            ?.toLowerCase()
            .includes(searchData?.toLowerCase()) ||
          getRelativeTime(data?.updated_on)
            ?.toLowerCase()
            .includes(searchData?.toLowerCase()) ||
          data?.updated_by_user?.email
            ?.toLowerCase()
            .includes(searchData?.toLowerCase())
        );
      });

      setSchedulerData([...filteredData]);
      /*if(filteredData.length){
      setSchedulerData([...filteredData]);

      }
      else{
        setSchedulerData([]);
      }*/
    } else {
      setSchedulerData(filterData());
    }
  }, [searchData, selectedFilters]);

  // useEffect(() => {

  //   if (schedulerData?.length > 0 && !_.isEqual(schedulerFilter.Filters.scheduler?.selectedFilters ?? [], selectedFilters)) {
  //     setSelectedFilters(schedulerFilter.Filters.scheduler?.selectedFilters)
  //   }
  // }
  //   , [schedulerData])

  const tabsMenuList = [
    {
      title: "All",
      key: "all",
      Icon: () => (
        <>
          <SCHEDULER />
        </>
      ),
      onClickEvent: (key) => {
        setActiveTab(key);
      },
    },
  ];

  useEffect(() => {
    dispatch(setSidebarActiveRoute("/scheduler"));
  }, []);

  const getFiltersStatus = () => {
    let filters = [...selectedFilters],
        status = null
    if (filters.length === 0) status = false
    else if (filters.length > 0) {
        status = !filters.map(v => v[Object.keys(v)[0]].length > 0).every(v => v === false)
    }
    console.log(status, selectedFilters)
    return status

}


  return (
    <>
      {/* schedulerFilter.Filters.scheduler?.filterList ?? */}
      {showFilter ? (
        <Filter
          props={{
            filterList: filterList,
            type: "scheduler",
            selectedFilters: selectedFilters,
            setselectedFilters: setSelectedFilters,
            setshowFilter: setshowFilter,
          }}
        />
      ) : null}
      <div className="scheduler-wrapper vh-100">
        {fromRoute.toLowerCase() !== 'schedulermodal' ? <LandingpageFirstHeader
          title={"Scheduler"}
          flag={false}
          backRoute={"/scheduler"}
        /> : null}
        <LandingPageSubHeader
          props={{
            defaultActiveTab: "all",
            moduleType: "scheduler",
            tabsMenuList: tabsMenuList,
            defaultValue: showListView ? 'listView' : 'gridView',
            setSearchData: setSearchData,
            setshowFilterPopup: setshowFilterPopup,
            setshowGridView: setshowGridView,
            setshowListView: setshowListView,
            fromRoute: fromRoute,
            isFilterApplied:getFiltersStatus(),
            isDisableAllOptions: empty
          }}
        />
        {/* <Buttons
          props={{
            buttonText: "",
            buttonClassName:
              "custom-btn-primary custom-btn fixed-button addbutton_plusicon",
            buttonEvent: () => {
              history.push(Literals.links.CREATESCHEDULER);
            },
            tooltip: "Add Scheduler",
            ImgSrc: () => <PLUS />,
            isDisable: false,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />  */}
        {fromRoute.toLowerCase() !== 'schedulermodal' ? <div className="landingPage scheduler-pg">
          {isButtonActive && (
            <div
              className="rotate-button-backdrop"
              onClick={() => setIsButtonActive(false)}
            ></div>
          )}
          <div className="options-wrapper">
            <Buttons
              props={{
                buttonText: "",
                buttonClassName: `custom-btn-primary custom-btn fixed-button addbutton_plusicon ${isButtonActive ? "rotate-icon" : ""
                  }`,
                buttonEvent: () => {
                  setIsButtonActive((isButtonActive) => !isButtonActive);
                },
                tooltip: "Add",
                tooltipPlacement: "left",
                ImgSrc: () => <PLUS />,
                isDisable: false,
                buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
              }}
            />
            <ul
              className={`add-options-list ${isButtonActive ? "active-list" : ""}`}
            >
              <Tooltip placement="left" title={`Flows`}>
                <li onClick={() => history(Literals.links.CREATESCHEDULER_ALERTFLOWS)}>
                  {/* setIsConnectionModalOpen(true) */}
                  <SCHEDULER height="20" width="20" />
                </li>
              </Tooltip>
              {/* <Tooltip placement="left" title="Crawler">
                <li onClick={() => history(Literals.links.CREATESCHEDULER_CRAWLER)}>
                  <CRAWLER height="20" width="20" />
                </li>
              </Tooltip> */}
            </ul>
          </div></div>
          : null}
        <div className="" style={{height: 'calc(100% - 72px)', overflow: 'auto'}}>
          {errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> : activeTab === "all" && schedulerData.length ? (
            showGridView ? (
              <SchedulersGrid
                openDeleteDialog={openDeleteDialog}
                schedulerData={schedulerData}
                getSchedulerList={getSchedulerList}
                searchData={searchData}
                fromRoute={fromRoute}
                Selectedid={Selectedid}
                filterData={filterData}
                loadingStatus={loadingStatus}
              />
            ) : showListView ? (
              <SchedulersList
                openDeleteDialog={openDeleteDialog}
                schedulerData={schedulerData}
                fromRoute={fromRoute}
                getSchedulerList={getSchedulerList}
                searchData={searchData}
                filterData={filterData}
              />
            ) : (
              ""
            )

          ) : empty ?
            <NoDataComponent logo={<SCHEDULER width="60" height="60" color="black" />}
              message=" Click On (+) To Create Schedule" /> : (
              !loadingStatus && (searchData !== '' || selectedFilters.length) ?
                <NoSearchResultFound mainMsg={searchData === '' ? 'No Scheduler List Found' : ''} subMsg={'Please try Search / Filter with another term'} /> :
                null
            )
          }
        </div>
        {fromRoute !== 'schedulerModal'&& props.loadingStatus ? <LoadingComponent /> : null}
      </div>
      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={getDeleteMessage({
          title: 'Scheduler'
        })}
        onConfirm={() => handleDelete(currentSchedulerIdRef?.current)}
      />
      {/* <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent}
      /> */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loadingStatus: state.LoadingReducer.loadingStatus,
  };
};
export default connect(mapStateToProps)(LandingPage)