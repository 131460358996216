const SET_POD_CREATION_DATA = "SET_POD_CREATION_DATA"
const SET_POD_CREATION_DATA_WITH_KEY = "SET_POD_CREATION_DATA_WITH_KEY"
const RESET_POD_CREATION_DATA = "RESET_POD_CREATION_DATA"

export const setPodCreationData = (data) => {

    return {
        type: SET_POD_CREATION_DATA,
        payload: data
    };
};

export const setPodCreationDataWithKey = (key = '', data) => {

    return {
        type: SET_POD_CREATION_DATA_WITH_KEY,
        payload: {key, data}
    };
};

export const resetPodCreationData = () => {
    
    return {
        type: RESET_POD_CREATION_DATA,
        payload: {
            podDetails: {
                name: '',
                description: '',
                domain: ''
            },
            fileOptions: {
                delimeter: ',',
                encoding: 'utf_8',
                format: 'csv',
                headerCount: 1,
                escapeChar: "",
                quoteChar: "",
                skipFooter: "",
                skipRows: "",
                nullFormat: "NULL"
            },
            explorerTreeData: [],
            selectedFolder: [],
            explorerExpandedKeys: [],
            currentSelectedConnection: '',
            tableData: {
                result: {}
            },
            schemaTableData: [],
            schemaListData: [],
            schemaQueryMap: {
                result: ''
            },
            parameters: [],
            clarista_engine: true
        }
    };
};

const initialState = {
    podDetails: {
        name: '',
        description: '',
        domain: ''
    },
    fileOptions: {
        delimeter: ',',
        encoding: 'utf_8',
        format: 'csv',
        headerCount: 1,
        escapeChar: "",
        quoteChar: "",
        skipFooter: "",
        skipRows: "",
        nullFormat: "NULL"
    },
    explorerTreeData: [],
    selectedFolder: [],
    explorerExpandedKeys: [],
    currentSelectedConnection: '',
    tableData: {
        result: {}
    },
    schemaTableData: [],
    schemaListData: [],
    schemaQueryMap: {
        result: ''
    },
    parameters: [],
    clarista_engine: true
}

const PodCreationData = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case SET_POD_CREATION_DATA:
            return {
                ...state,
                ...payload,
            }

        case SET_POD_CREATION_DATA_WITH_KEY:
            let {key, data} = payload
            return {
                ...state,
                [key]: data
            }

        case RESET_POD_CREATION_DATA:
            return {...payload}

        default:
            return state;
    }
}
export default PodCreationData;