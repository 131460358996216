import React, { Fragment } from "react";
import { Tooltip } from "antd";

const SmallerDetailsTile = ({ object,isUserGroup }) => {
  const { icon, label, value } = object;

  function renderUserGroupUpperSection(){
    return(
      <div  className="data-domain-small-details">
            <div className="icon-box">
              <span className="icon-span">{icon}</span>
            </div>
            <div className="data-domain-small-content">
              <label className="label">{label}</label>
              <Tooltip placement="topLeft" title={value}>
                <p>{value}</p>
              </Tooltip>
            </div>
          </div>
    )
  }
  function renderDataDomainUpperSection(){
    return (

      <div  className="data-domain-small-details">
        <div className="icon-box">
          <span className="icon-span">{icon}</span>
        </div>
        <div className="data-domain-small-content">
          <label className="label">{label}</label>
          <Tooltip placement="topLeft" title={value}>
            <p className="text-with-ellipsis d-block">{value?value:'N/A'}</p>
          </Tooltip>
        </div>
      </div>
      // <div className="data-domain-tile">
      //     <div className="d-flex align-items-center">
      //         {icon}
      //         <Tooltip placement="top" title={label}><span className="detail-header">{label?label.toUpperCase():null}</span></Tooltip>
      //     </div>
      //     <p className="detail-value"><Tooltip placement="top" title={value}>{value}</Tooltip></p>
      // </div>
  )
  }
  return (
    
    <Fragment>
              {isUserGroup ? renderUserGroupUpperSection() : renderDataDomainUpperSection() }
    </Fragment>
  );
};

export default SmallerDetailsTile;
