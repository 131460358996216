import React from 'react'

const NoGraphDataFound = ({ submsg = '', msg = "Sorry, we couldn't find any data ",color="#828282", height = '', imgwidth = "198", imgheight = "127" }) => <div className='d-flex noresultfound-wrapper flex-column justify-content-center align-items-center' style={{ height: height }}>
    <svg xmlns="http://www.w3.org/2000/svg" width={imgwidth} height={imgheight} viewBox="0 0 105 105" fill="none">
        <path d="M1.99994 0.000103568C0.895395 0.000134395 0 0.895556 0 2.0001V102.143C0 102.896 0.3034 103.632 0.832419 104.165C1.36546 104.696 2.10161 105 2.85545 105H100.788C101.892 105 102.787 104.105 102.787 103V101.286C102.787 100.181 101.892 99.2859 100.787 99.2859H7.7109C6.60633 99.2859 5.7109 98.3905 5.7109 97.2859V2.00006C5.7109 0.895464 4.81544 2.49913e-05 3.71084 5.58191e-05L1.99994 0.000103568Z" fill={color} />
        <path d="M100.144 36.1349C102.519 36.1349 104.444 34.2102 104.444 31.8367V14.1178C104.444 12.9857 103.987 11.8799 103.186 11.0795C102.387 10.2785 101.278 9.82007 100.144 9.82007H83.7176C81.3426 9.82007 79.417 11.7438 79.417 14.1178C79.417 16.4927 81.3426 18.4174 83.7176 18.4174H89.3935L59.0613 54.2308L58.9056 43.4637C58.8784 41.6718 57.7415 40.0821 56.0561 39.4787C54.3717 38.8734 52.4839 39.3791 51.3256 40.7465L11.5987 87.6537C10.0621 89.4656 10.2857 92.1785 12.0996 93.7133C13.9115 95.248 16.623 95.0223 18.1586 93.2105L50.4697 55.0581L50.6272 65.8262C50.6525 67.6172 51.7865 69.2068 53.475 69.8118C55.1594 70.4153 57.0481 69.9086 58.2054 68.5431L95.8473 24.0999V31.837C95.8473 34.2102 97.7729 36.1349 100.144 36.1349Z" fill={color} />
    </svg>
    <p className='label noresultmsg mt-3 mb-0'>{msg}</p>
    <p style={{ color: "rgb(130 130 130)" }} className='noresultsubmsg dict-gray-color'>{submsg}</p>

</div>

export default NoGraphDataFound