import React, { useState, useEffect } from "react";
import { Literals } from "../../../common/literals";
import AddUsersTable from "../../../../Common/landingPageTable/LandingPageTable";
import { Checkbox, Tooltip } from "antd";
import NoSearchResultFound from "../../../common/NoSearchResultFound";

const UserSelectionTable = ({
  searchData = '',
  users = [],
  selectedUsers,
  setSelectedUsers,
  allSelected,
  selectableUsersId,
}) => {

  const [selectedIds, setSelectedIds] = useState([]);

  const onSelectUserHandler = (user, e) => {
    e.stopPropagation()
    setSelectedUsers((users) => {
      const newUsers = [...users];
      const index = newUsers.findIndex((u) => u.id === user.id);

      if (index > -1) {
        newUsers.splice(index, 1);
      } else {
        newUsers.push(user);
      }

      return newUsers;
    });
    setSelectedIds((users) => {
      const newUsers = [...users];
      const index = newUsers.findIndex((u) => u.id === user.id);

      if (index > -1) {
        newUsers.splice(index, 1);
      } else {
        newUsers.push(user);
      }

      return newUsers;
    });


  };

  const selectAllUsersHandler = (checked) => {
    if (checked) {
      const selectedUsers = users.filter((user) =>
        selectableUsersId.includes(user.id)
      );

      setSelectedUsers(selectedUsers);
    } else {
      setSelectedUsers([]);
    }
  };

  useEffect(() => {
    const selectedIds = selectedUsers.map((user) => user.id) || [];
    setSelectedIds(selectedIds);
  }, [selectedUsers]);

  const usersHeadings = [
    {
      title: (
        <span>
          <Checkbox
            className="mr-2"
            checked={allSelected}
            disabled={searchData?.trim() !== ''}
            onChange={(e) => selectAllUsersHandler(e.target.checked)}
          />
          {Literals.FIRSTNAME}
        </span>
      ),
      dataIndex: "firstName",
      key: "firstName",
      width: "15%",
      sorter: {
        compare: (a, b) =>
          a.name?.props?.children[1]?.props?.title !== undefined
            ? a.name?.props?.children[1]?.props?.title.localeCompare(
              b.name?.props?.children[1]?.props?.title
            )
            : null,
      },
    },
    {
      title: Literals.LASTNAME,
      dataIndex: "lastName",
      key: "lastName",
      width: "15%",
    },
    {
      title: 'Designation',
      dataIndex: "designation",
      key: "designation",
      width: "20%",
    },
    {
      title: Literals.EMAIL,
      dataIndex: "email",
      key: "email",
      width: "40%",
    },
    {
      title: Literals.STATUS,
      dataIndex: "status",
      key: "status",
      width: "15%",
    },
    {
      title: Literals.SUPER_USER,
      dataIndex: "superUser",
      key: "superUser",
      width: "15%",
    },
  ];

  const usersData = [];



  users.forEach((user) => {
    if ((
      user?.first_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
      user?.last_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
      user?.email?.toLowerCase().includes(searchData?.toLowerCase())) && 
      (!user?.is_superuser)
      // user?.email?.toLowerCase().includes(searchData?.toLowerCase())) && (!user?.is_superuser && user?.is_verified)
    ) {

      usersData.push({
        firstName: (
          <div className="user-group-selection-name">
            <Checkbox
              checked={selectedIds.includes(user.id)}
              disabled={user.is_superuser}
              // disabled={user.is_superuser || !user.is_verified}
              onChange={(e) => onSelectUserHandler(user, e)}
            />
            <Tooltip placement="top" title={user.first_name}>
              {user.first_name}
            </Tooltip>
          </div>
        ),
        lastName: (
          <Tooltip placement="top" title={user.last_name}>
            {user.last_name}
          </Tooltip>
        ),
        email: (
          <Tooltip placement="top" title={user.email}>
            {user.email}
          </Tooltip>
        ),
        designation: (
          <Tooltip
            placement="top"
            title={user.designation}
          >
            {user.designation}
          </Tooltip>
        ),
        status: (
          <Tooltip
            placement="top"
            title={user.is_verified ? "Active" : "Inactive"}
          >
            {user.is_verified ? "Active" : "Inactive"}
          </Tooltip>
        ),
        superUser: (
          <Tooltip placement="top" title={user.is_superuser ? "Yes" : "No"}>
            {user.is_superuser ? "Yes" : "No"}
          </Tooltip>
        ),
      });
    }
  });

  return (
    usersData && usersData?.length > 0 ? <AddUsersTable
      props={{
        pagination: false,
        size: "small",
        columns: usersHeadings,
        dataSource: usersData,
        showSorterTooltip: false,
      }}
    /> : (searchData !== '') &&
      (usersData && !usersData.length) ? <NoSearchResultFound mainMsg={searchData === '' ? 'No Flow List Found' : ''} subMsg={'Please try Search / Filter with another term'} />
      : null


  );
};

export default UserSelectionTable;
