import React from 'react'
import ConnectionList from '../../../connections/landingPage/components/ConnectionsList'
import { useDispatch, useSelector } from 'react-redux'
import { getConnectionListCreatePODAction } from '../../../../../store/actions'
import { setConnectionDataList } from '../../../../../store/modules/UserManagement/connection/connectionActions'
import ConnectionContext from '../../../connections/landingPage/components/ConnectionContext'
import { ErrorComponent, NoDataComponent, getReloadErrorTemplate } from '../../../common/helperFunctions'
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader'
import { resetPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import Walkthrough from '../../../../Common/walkthrough/Walkthrough'
import { ACTIONS } from 'react-joyride'
import { ICON_CONNECTION } from '../../../../Common/newIconSource'
import SearchInput from '../../../../Common/search/Search'

const SelectConnection = ({
    setChangedConn = () => {},
    isChangeModal = false,
    isEditing = true,
    isEditMode = false,
    tutorialStepsIdMap = {},
    isTutorialActive = false,
    setConnectionsLoaded = () => {},
    searchConn = '',
    setNoConnections = () => {},
    isUnstructured = false
}) => {

    const [empty, setEmpty] = React.useState(false)
    const [errMsg, setErrMsg] = React.useState(undefined)
    const [loading, setLoading] = React.useState(false)

    const walkthroughSteps = React.useMemo(() => {
        let steps = [
            {
                content: <>
                    <h4 className='fontSizeHeading font-w-600 color-primary'>Welcome, To The Create Pod</h4>
                    <p className='text-dark mb-0 paragraph'>
                        Let's start with the tutorial
                    </p>
                </>,
                locale: { skip: <strong>SKIP</strong> },
                placement: "center",
                target: "body",
                continuous: true
            },
            {
                content: <>
                    <h4 className='fontSizeHeading font-w-600 color-primary'>Select Connection</h4>
                    <p className='text-dark mb-0 paragraph'>
                        Choose any connection from this grid
                    </p>
                </>,
                placement: "bottom",
                target: tutorialStepsIdMap.step1,
                title: "Step 1",
                hideFooter: true,
                disableBeacon: true
            },
            {
                content: <>
                    <h4 className='fontSizeHeading font-w-600 color-primary'>Explorer</h4>
                    <p className='text-dark mb-0 paragraph'>
                        Once you choose connection then you can move forward to Explorer
                    </p>
                </>,
                placement: "bottom",
                target: tutorialStepsIdMap.step2,
                title: "Step 2",
                locale: { last: "Got it" },
                hideFooter: true,
                disableBeacon: true
            }
        ]

        return steps
    }, [tutorialStepsIdMap])

    const [{run, steps, stepIndex}, setWalkThrough] = React.useState({
        run: false,
        steps: [...walkthroughSteps],
        stepIndex: 0
    })

    React.useEffect(() => {
        setWalkThrough(prev => ({...prev, steps: walkthroughSteps}))
    }, [tutorialStepsIdMap])
    
    const { setFromConnectionRoute, setConnectionStatus } = React.useContext(ConnectionContext);
    const selectedConn = useSelector((state) => {
        return state.UserManagement.ConnectionReducer.selectedConn
    })
    const currentSelectedConnection = useSelector((state) => {
        return state?.DataCatalogue?.PodCreationData?.currentSelectedConnection
    })

    const dispatch = useDispatch()

    React.useEffect(() => {
        getConnectionList()
        setFromConnectionRoute(true)

        const nextButton = document.querySelector(tutorialStepsIdMap?.step2)
        if(nextButton) {
            nextButton.addEventListener('click', () => {
                setWalkThrough(prev => ({...prev, stepIndex: prev.stepIndex + 1}))
            })
        }
    }, [])

    React.useEffect(() => {
        if(selectedConn?.connection_name) {
            if(selectedConn?.connection_name !== currentSelectedConnection) {
                dispatch(resetPodCreationData())
            }
        }
    }, [selectedConn])

    const getConnectionList = () => {
        setLoading(true)
        dispatch(getConnectionListCreatePODAction(true, false)).then(
          res => {
            let conData = res?.data

            if(isUnstructured) {
                conData = conData?.filter(c => c?.file_system)
            }

            if(conData) {
                setEmpty(
                    conData?.length === 0
                    ||
                    conData?.filter(c => c?.assigned_as_cache === false && c?.assigned_as_upload === false)?.length === 0
                )
                setNoConnections(
                    conData?.length === 0
                    ||
                    conData?.filter(c => c?.assigned_as_cache === false && c?.assigned_as_upload === false)?.length === 0
                )
            }
            dispatch(setConnectionDataList(conData?.filter(d => d?.data_domain?.length > 0)))
            setLoading(false)
            setTimeout(() => {
                setConnectionsLoaded(true)
                setWalkThrough(prev => ({...prev, run: isTutorialActive}))
            }, 1000)
          }
        )
        .catch(err => {
            setErrMsg(err.message)
            setLoading(false)
        })
    }
    
    const walkthroughCallback = ({action, index}) => {
        if(action === ACTIONS.NEXT && index === 0) {
            setWalkThrough(prev => ({...prev, stepIndex: index + 1}))
        }
    }

    return (
        <div className='h-100' style={{overflowY:' auto'}}>
        {/* <div className='h-100' style={{height: 'calc(100vh - 80px)', overflowY:' auto'}}> */}
            {
                loading ? <ClaristaLoader/>
                :
                errMsg 
                ?   getReloadErrorTemplate({errorMessage: errMsg, onReload: getConnectionList})
                // ?   <ErrorComponent msg={errMsg} />
                :   empty 
                    ?   <div className='my-5 mx-auto w-50 no-connection-view'>
                            <NoDataComponent
                                logo={<ICON_CONNECTION height='50' width='50' />}
                                message={'No Connections Available To Select'}
                            />
                        </div>
                    :   <>
                            <ConnectionList
                                searchValue={searchConn}
                                selectedFilters={[]}
                                filterList={[]}
                                isChangeModal={isChangeModal} 
                                setChangedConn={setChangedConn} 
                                fromModal={true} 
                                layout="grid"
                                disableUnselected={isEditMode && !isEditing}
                                setWalkThroughStep={(index) => setWalkThrough(prev => ({...prev, stepIndex: index}))}
                            />

                            <Walkthrough
                                walkthrough={{run, steps, stepIndex}}
                                stepIndex={stepIndex}
                                spotlightClicks={true}
                                showSkipButton={false}
                                disableOverlayClose={true}
                                callback={walkthroughCallback}
                            />
                        </>
            }
        </div>
    )
}

export default SelectConnection