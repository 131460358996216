const initialState = {
   isEdit:false,
   saveClicked:false
}

const EntitlementReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch(type){

        case "SET_EDIT_MODE": return {
            ...state,isEdit:payload
        }
        case "SET_SAVE_CLICKED":
            return {
                ...state,
                saveClicked:payload
            }
        case "RESET_ENTITLEMENT":
            return{
                ...initialState
            }
        default:
            return {...state}
    }
}

export default EntitlementReducer
