import { combineReducers } from "redux";

import Script from "./scriptTransformer/scriptReducer";
import { TargetTransformer } from "./targetTransformer/targetTransformer";


const Flows = combineReducers({
    Script,
    TargetTransformer,
});
export default Flows
