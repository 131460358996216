import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import {
  getUserGroupList
} from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import { NoDataComponent, getDeleteMessage} from '../../../common/helperFunctions';

import {
  setUserList
} from "../../../../../store/modules/UserManagement/users/usersActions";
import { USER } from "../../../../Common/iconSource";
import ActionPopUp from "../../../common/actionPopUp";
import { Literals } from "../../../common/literals";
import LoadingComponent from "../../../common/loadingComponent";
import NoSearchResultFound from "../../../common/NoSearchResultFound";
import UserCard from "./UserCard";
import DeleteModal from "../../../../Common/deleteModal/DeleteModal";
import _ from "lodash";

const UserPageCardView = ({
  searchData,
  usersList,
  getUsersList,
  onEditUser,
  isEmpty = false
}) => {
  const dispatch = useDispatch();
  const store = useStore();

  const data = [];
  const loadingStatus = useSelector(
    (state) => state.LoadingReducer.loadingStatus
  );

  const CurrentUserDetails = useSelector(state => state.UserReducer.user.userDetails)

  const [empty,setEmpty]=useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);

  const currentUserRef = React.useRef({current: {}})

  useEffect(() => {
    if(usersList.length===0 && isEmpty)
      setEmpty(true);
    else
      setEmpty(false);
    dispatch(setUserList(usersList));
  }, [usersList, isEmpty]);

  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };

  const clickDelete = (record) => {

    currentUserRef.current = record

    // setDeletePopUpContent({
    //   modalClass: "delete-popup",
    //   heading: "Delete",
    //   message: Literals.POP_UP_MESSAGE_DELETE,
    //   headingIcon: "bx bx-error",
    //   button: [
    //     {
    //       name: "Delete",
    //       action: () => deleteUser(record.key),
    //     },
    //     {
    //       name: "Cancel",
    //       action: closeDeletePopUp,
    //     },
    //   ],
    //   elements: [],
    //   closeActionPopup: closeDeletePopUp,
    // });
    setOpenDeletePopup(true);
  };

  const deleteUser = async (id) => {
    setOpenDeletePopup(false);
    let response;
    try {
      response = await store.dispatch(
        getUserGroupList({
          method: "DELETE",
          endPoint: "profile/" + id + "/",
        })
      );
      if (response.status === "success") {
        emitToastNotification('success', response?.message);
        getUsersList();
      }
    } catch (error) {}
  };

  const filterData =
    usersList &&
    usersList.filter((data) => {
      return (
        data?.first_name
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.last_name
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.email
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.designation
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.status
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.createdOn
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.createdBy
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase())
      );
    });

  const tableData = [];
  filterData &&
    filterData.map((value) =>
      tableData.push({
        key: value?.id,
        firstName: value?.first_name ? value?.first_name : '',
        lastName: value?.last_name ? value?.last_name : '',
        emailAddress: value?.email,
        designation: value?.designation,
        status: value?.is_verified === true ? "Active" : "Inactive",
        superuser: value?.is_superuser ? Literals.YES : Literals.NO,
        createdBy: value?.created_by_user?.email,
        updatedBy: value?.updated_by_user?.email,
      })
    );
  function getCardView() {

    const userData = _.sortBy(tableData,[
      (o) => o?.key !== CurrentUserDetails?.id, 
      (o) => o?.firstName?.toLocaleLowerCase()
    ])

    return userData.map((ele, i) => (
      <UserCard
        key={i}
        userDetails={ele}
        searchData={searchData}
        selectedDomainList={data}
        clickEdit={onEditUser}
        clickDelete={clickDelete}
        icon={<USER color="#2c83c4"/>}
        tabName="Usergroup"
        CurrentUserDetails={CurrentUserDetails}
      />
    ));
  }

  return (
    <div>
      { loadingStatus ? <LoadingComponent /> :  empty?<NoDataComponent logo={<USER width="60" height="60" color="black"/>} message="
      Click On (+) To Create User "/>:searchData?.length > 0 && filterData?.length === 0 ? (
        <NoSearchResultFound />
      ) : (
        <div style={{height: 'calc(100vh - 4.5625rem)', overflowY: 'auto'}}>
          <div className="user-gridview new-box-style">{getCardView()}</div>
        </div>
      )}
    
      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={getDeleteMessage({
          title: 'User'
        })}
        onConfirm={() => deleteUser(currentUserRef?.current?.key)}
      />

      {/* <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent}
      /> */}
    </div>
  );
};

export default UserPageCardView;