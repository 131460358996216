import {
  RESET_DASHBOARD_REDUX_STATE,
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_LAYOUTS,
  SET_DASHBOARD_BREAKPOINT,
  SET_DASHBOARD_TABLE_DATA,
  SET_ANALYSIS_DATA,
  DATA_DOMAIN_LIST,
  SET_FULL_SCREEN,
  SET_DASHBOARDLOADING_STATUS,
  EDIT_DASHBOARD_ITEMS,
  SET_DASHBOARD_STATE,
  SET_DASHBOARD_REFRESHDATA,
  SET_DASHBOARD_DEFAULT,
  SETS_ACTIVE_KEY,
  SET_OPEN_ANALYSIS,
  SET_DASHBOARD_DEFAULTWITHANALYSIS,
  SET_VIEW,
  SET_ANALYSIS_FILTERS_DATA,
  SET_ANALYSIS_GRAPHS_DATA,
  SET_ANALYSIS_FILTER_GRAPHS_DATA,
  SET_DYNAMIC_FILTERS,
  SET_SELECTED_ANALYSIS,
  SET_APPLIED_FILTER_NAME,
  SET_FILTERS_COUNT,
  RESET_ANALYSIS_FILTERS_DATA,
  SET_SELECTED_FILTER_VALUES,
  SET_ANALYSIS_FILTERS_LIST,
  SET_INITIAL_EDIT_FILTERS,
} from "./dashboardTypes";

const initialState = {
  data: {},
  layouts: {
    // lg: [],
    md: [],
    // sm: [],
    // xs: []
  },
  breakpoint: "lg",
  tableData: [],
  analysisData: [],
  dataDomainList: [],
  fullscreen: false,
  loadingStatus: false,
  description: "",
  name: "",
  title: "",
  dashboardId: "",
  dashboardState: "",
  refreshData: {},
  activeKey: "",
  openAnalysis: {},
  view: "",
  analysisFiltersData: {},
  analysisGraphData: {},
  analysisFilterGraphData: {},
  filtersCount: 0,
  graphDynamicFilters: null,
  appliedFilterName: "",
  selectedFilterValues: {},
  selectedAnalysis: [],
  analysisFiltersList: [],
  initialEditFilters: true,
};

const DashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_DASHBOARD_REDUX_STATE:
      return initialState;

    case SET_DASHBOARD_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case SET_SELECTED_ANALYSIS:
      return {
        ...state,
        selectedAnalysis: payload,
      };
    case SET_DASHBOARD_LAYOUTS:
      return {
        ...state,
        layouts: action.payload,
      };
    case SET_DASHBOARD_BREAKPOINT:
      return {
        ...state,
        breakpoint: action.payload,
      };
    case SET_DASHBOARD_TABLE_DATA:
      return {
        ...state,
        tableData: action.payload,
      };
    case SET_ANALYSIS_DATA:
      return {
        ...state,
        analysisData: [...payload],
      };
    case DATA_DOMAIN_LIST:
      return {
        ...state,
        dataDomainList: payload,
      };
    case SET_FULL_SCREEN:
      return {
        ...state,
        fullscreen: payload,
      };
    case SET_DASHBOARDLOADING_STATUS:
      return {
        ...state,
        loadingStatus: payload,
      };
    case SET_DYNAMIC_FILTERS:
      return {
        ...state,
        graphDynamicFilters: payload,
      };
    case EDIT_DASHBOARD_ITEMS:
      return {
        ...state,
        description: payload.description,
        name: payload.name,
        title: payload.title,
        dashboardId: payload.dashboardId,
      };
    case SET_DASHBOARD_STATE:
      return {
        ...state,
        dashboardState: payload,
      };
    case SET_DASHBOARD_REFRESHDATA:
      return {
        ...state,
        refreshData: payload,
      };
    case SETS_ACTIVE_KEY:
      return {
        ...state,
        activeKey: payload,
      };
    case SET_OPEN_ANALYSIS:
      return {
        ...state,
        openAnalysis: payload,
      };
    case SET_DASHBOARD_DEFAULT:
      return initialState;
    case SET_DASHBOARD_DEFAULTWITHANALYSIS:
      return {
        ...state,
        data: {},
        layouts: {
          md: [],
        },
        breakpoint: "lg",
        tableData: [],
        analysisData: [],
        dataDomainList: [],
        fullscreen: false,
        loadingStatus: false,
        description: "",
        name: "",
        title: "",
        dashboardId: "",
        dashboardState: "",
        refreshData: {},
        activeKey: "",
      };

    case SET_ANALYSIS_FILTERS_DATA:
      return {
        ...state,
        analysisFiltersData: payload,
      };

    case SET_ANALYSIS_GRAPHS_DATA:
      return { ...state, analysisGraphData: action.payload };

    case SET_ANALYSIS_FILTER_GRAPHS_DATA:
      return { ...state, analysisFilterGraphData: action.payload };

    case SET_APPLIED_FILTER_NAME:
      return { ...state, appliedFilterName: action.payload };

    case SET_FILTERS_COUNT:
      return { ...state, filtersCount: action.payload };

    case RESET_ANALYSIS_FILTERS_DATA:
      return {
        ...state,
        analysisFiltersData: {},
        analysisGraphData: {},
        analysisFilterGraphData: {},
        filtersCount: 0,
        graphDynamicFilters: null,
        appliedFilterName: "",
        selectedAnalysis: [],
        selectedFilterValues: [],
        analysisFiltersList: [],
        initialEditFilters: true,
      };

    case SET_SELECTED_FILTER_VALUES:
      return { ...state, selectedFilterValues: action.payload };

    case SET_ANALYSIS_FILTERS_LIST:
      return { ...state, analysisFiltersList: action.payload };

    case SET_INITIAL_EDIT_FILTERS:
      return {
        ...state,
        initialEditFilters: action.payload,
      };

    default:
      return state;
  }
};

export default DashboardReducer;
