import React, { useEffect, useState} from 'react';
import DataGrid from "react-data-grid";
import './contentGridTable.scss';

function isAtBottom({ currentTarget }) {

    return (
      currentTarget.scrollTop + 10 >=
      currentTarget.scrollHeight - currentTarget.clientHeight
    );
}


    
function isAtTop({ currentTarget }) {

    return (
      currentTarget.scrollTop === 0
    );
}

const createColumns = colsNumber => {
    let cols = []
    let temp = Array(colsNumber).fill(0)
    temp.map((el, i) => cols.push({
        name: `col_${el}${i}`,
        key: i
    }))

    return cols
}

const createRows = (data, cols) => {
    let r = [], s = [], chunk, d = [...data]

    while (d.length > 0) {
        chunk = d.splice(0,cols.length)
        r.push(chunk)
    }

    r.forEach(el=>{
        let t = {}
        for (let j = 0; j < cols.length; j++) {
            t[j] = el[j] !== undefined ? el[j] : ''
        }
        s.push(t)
    })
    
    return s
}

export const ContentViewGridTable = React.forwardRef(({ 
    data = [],
    colsNumber = 3,
    rowHeight = 10,
    loadMore = false,
    onScrollToBottom = () => { },
    onScrollToTop = () => { },
}, ref) => {

    const [columns,setColumns] = useState([...createColumns(colsNumber)])
    const [rows, setRows] = useState([...createRows(data, columns)])

    const gridRef = React.useRef(null)

    React.useImperativeHandle(ref, () => {
        return {
            scrollToColumn: (idx) => {
                gridRef?.current?.scrollToCell({ idx });
            },
            scrollToRow: (rowIdx) => {
                gridRef?.current?.scrollToCell({ rowIdx });
            },
            scrollToCell: (idx, rowIdx) => {
                gridRef?.current?.scrollToCell({ idx, rowIdx });
            }
        }
    }, [])

    useEffect(()=>{
        let cols=createColumns(colsNumber);
        setColumns(cols);
        setRows(createRows(data, cols));
    },[colsNumber])
    
    useEffect(()=>{
        setRows([...createRows(data, columns)])
    },[data])

    const handleScroll = (event) => {
        if(isAtTop(event)) onScrollToTop(isAtTop(event))
        else {
            if(isAtBottom(event)) onScrollToBottom(isAtBottom(event))

            if(!isAtBottom(event)) return
        }
    };
    

    return <>
        <div className={`position-relative h-100`}>
            <DataGrid
                ref={gridRef}
                className={`custom-content-dgt h-100`}
                columns={columns}
                rows={rows}
                defaultColumnOptions={{
                    sortable: false,
                    resizable: false,
                }}
                noRowsFallback={<div className='p-2 text-black-50 text-center'>No Records Found</div>}
                rowHeight={rowHeight}
                headerRowHeight={0}
                onScroll={handleScroll}
                onCellClick={(a, e) => {
                    e.preventGridDefault()
                }}
            />
            {
                loadMore  
                ?   <div className='load-more-dgt-wrapper'>
                        <div className="load-more-dgt">
                            <i className="bx bx-loader-alt bx-spin mr-2"></i>
                            <label className="small mb-0">
                            Loading More...
                            </label>
                        </div>
                    </div>
                :   ''
            }
        </div>
    </>;
});