import React from 'react'
import { ICON_SORT_ATOZ, ICON_SORT_ZTOA } from '../../newIconSource'
import './newDataGridTable.scss'

const DataGridSortComp = React.forwardRef(({
    colFilterRef = {},
    column = {},
    setParentSort = () => {}
}, ref) => {

    const [sorting, setSorting] = React.useState(null)

    React.useImperativeHandle(ref, () => {
        return {
            setSortingCriteria: (sort) => {
                setSorting(sort)
            }
        }
    }, [])

    React.useEffect(() => {
        setParentSort(sorting)
    }, [sorting])

    const setValue = (sort) => {
        colFilterRef.current[column?.name] = {
            ...colFilterRef.current[column?.name],
            sorting: sort
        }
        setSorting(sort)
    }

    return (
        <div className='new-dgt-sort-comp'>
            <div className={`p-2 d-flex align-items-center prv-flt-itm mb-1 ${sorting === 'asc' ? 'active' : ''}`}
                onClick={() => {
                    if(sorting === 'asc') {
                        setValue(null)
                    }
                    else {
                        setValue('asc')
                    }
                }}
            >
                <ICON_SORT_ATOZ width='15' height='15'/>
                <span className='mb-0 fontSizeHeading ml-2'>Sort A to Z</span>
            </div>
            <div className={`p-2 d-flex align-items-center prv-flt-itm ${sorting === 'desc' ? 'active' : ''}`}
                onClick={() => {
                    if(sorting === 'desc') {
                        setValue(null)
                    }
                    else {
                        setValue('desc')
                    }
                }}
            >
                <ICON_SORT_ZTOA width='15' height='15'/>
                <span className='mb-0 fontSizeHeading ml-2'>Sort Z to A</span>
            </div>
        </div>
    )
})

export default DataGridSortComp