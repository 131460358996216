import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "./ConnectionModal.scss";

import { CLOSE, TICK } from '../../../../Common/iconSource';
import { Literals } from '../../../common/literals';
import Buttons from '../../../../Common/button/Buttons';
import ConnectionsList from './ConnectionsList';

import { getConnectionListCreatePODAction, resetPodRightSideData, resetAllCreatePodData, resetCreatePodData } from '../../../../../store/modules/dataCatalogue/createPOD/createPODActions'
import { getDataDomainList, setDataDomainList } from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions'
import { setConnectionDataList, setConnectionSelectedConn } from '../../../../../store/modules/UserManagement/connection/connectionActions'
import ConnectionsHeader from './ConnectionsHeader';
import ConnectionContext from './ConnectionContext';
import _ from 'lodash';
import { setFilter } from '../../../../../store/app/appAction';
import { getFormattedDataConnectionFilter } from '../../../common/helperFunctions';

const ConnectionModal = ({ 
    isModalOpen, 
    onClose, 
    isRedirect = true, 
    setChangedConn = () => {},
    isChangeModal = false,
    changedConn = undefined,
    setReloadCreatePod = () => {},
    setConnectionChanged = () => {}
}) => {
    const dispatch = useDispatch();
    const history = useNavigate();

    const { datadomainList } = useSelector(state => state.UserManagement.DataDomain);
    const { connectionStatus, setConnectionStatus } = useContext(ConnectionContext);
    const [searchValue, setSearchValue] = useState("")
    const [selectedFilters, setselectedFilters] = useState([])
    const [datadomainLabelClone, setDataDomainLabelClone] = useState([])
    const [dbDataWithDbClone, setDbDataWithDbClone] = useState([])

    const getFormattedData = (c_data) => {
        return getFormattedDataConnectionFilter(c_data, datadomainLabelClone, selectedFilters)
    }
    const listAllConnectionsHandler = () => {
        try {
            dispatch(getConnectionListCreatePODAction(true)).then(
                res => {
                    dispatch(setConnectionDataList(res.data))
                }
            )
        }
        catch (error) {
            console.error("Error Fetching Connections List", error.message);
        }
    }

    const fetchDataDomainListHandler = () => {
        try {
            dispatch(getDataDomainList()).then(
                res => {
                    dispatch(setDataDomainList(res.data))
                }
            )
        }
        catch (error) {
            console.error("Error Fetching Data domain List", error.message);
        }
    }

    const onCloseHandler = () => {
        onClose();
        setselectedFilters([])
        dispatch(setFilter({connection:{}}))
        setConnectionStatus(null);
        dispatch(resetPodRightSideData())
    }

    const onDoneHandler = () => {
        onClose();
        setConnectionStatus(null)
        if(isChangeModal && changedConn) {
            dispatch(setConnectionSelectedConn(changedConn));
            dispatch(resetAllCreatePodData())
            dispatch(resetCreatePodData())
            setTimeout(() => {
                setConnectionChanged(true)
                setReloadCreatePod(true)
            }, 500)
            return
        }
        if(isRedirect){
            history(Literals.links.POD_CREATION);
        }
        // dispatch(resetPodRightSideData())
        dispatch(resetAllCreatePodData())
    }

    useEffect(() => {
        listAllConnectionsHandler()
        if (datadomainList.length === 0) {
            fetchDataDomainListHandler()
        }
    }, [])

    return (
        <Modal size="xl" isOpen={isModalOpen} className="custom-modal connection-modal" centered={true}>
            <ModalHeader>
                <ConnectionsHeader setSearchValue={setSearchValue} dbDataWithDbClone={dbDataWithDbClone} getFormattedData={getFormattedData} setselectedFilters={setselectedFilters} selectedFilters={selectedFilters} layout="grid" route="data-catalogue" onClose={onCloseHandler} />
            </ModalHeader>
            <ModalBody className='select-conn-modal-body'>
                <ConnectionsList isChangeModal={isChangeModal} setChangedConn={setChangedConn} fromModal={true} searchValue={searchValue} setdbdataWithDbClone={setDbDataWithDbClone} selectedFilters={selectedFilters} setdatadomainlabelClone={setDataDomainLabelClone} layout="grid" setlayout={()=>{}} />
            </ModalBody>
            <ModalFooter>
                {/* <Buttons props={{ tooltip: 'Cancel', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { onCloseHandler() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} /> */}
                <Buttons
                    props={{
                        buttonText: 'Done',
                        buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { onDoneHandler() },
                        ImgSrc: () => <TICK />,
                        isDisable: connectionStatus && connectionStatus.color === "#00CC92" ? false : true,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }}
                />
            </ModalFooter>
        </Modal>
    )
}

export default ConnectionModal
