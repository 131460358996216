import React, { useEffect } from 'react'
import CatalogGridIndex from './CatalogGridIndex';
import { ConnectionProvider } from '../../modules/connections/landingPage/components/ConnectionContext';
import DictionaryGridIndex from './DictionaryGridIndex';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarActiveRoute } from '../../../store/actions';
import { Literals } from '../../modules/common/literals';
import { getSimpleTutorialMessageTemplate } from '../../modules/common/helperFunctions';
import Walkthrough from '../walkthrough/Walkthrough';
import { setNavigatorTutorialStart } from '../../../store/modules/common/tutorialRedux';
import Draggable from 'react-draggable';
import ClaristaCopilot from '../../modules/copilot';

const NavigatorIndex = (props) => {

    const dispatch = useDispatch()

    const tutorial = useSelector(state => state?.CommonComponent?.TutorialRedux?.navigator?.landing_page)
    
    const [spotClick, setSpotClick] = React.useState(false)
    const [walkthroughSteps, setWalkThroughSteps] = React.useState(
        [
            {
                content: getSimpleTutorialMessageTemplate(
                    'Welcome To, Clarista Navigator', 
                    "Here you can see all your PODs and Dictionary"
                    ),
                locale: { skip: <strong>SKIP</strong> },
                placement: "center",
                target: "body"
            },
            {
                content: <>
                    {getSimpleTutorialMessageTemplate('Search Bar', 
                    'Search by name, description or by user email'
                    )}
                </>,
                placement: "bottom",
                target: '#landing-search-lg',
                title: ""
            },
            {
                content: <>
                    {getSimpleTutorialMessageTemplate('Tabs', 
                    'Switch tabs according to your need, what to see'
                    )}
                </>,
                placement: "top",
                target: '#landingContentTab-tab-pod',
                // target: '#landingContentTab',
                title: "",
            },
            {
                content: <>
                    {getSimpleTutorialMessageTemplate('Domains', 
                    "All available domains which list's the related data."
                    )}
                </>,
                placement: "top",
                target: '#landingDomainTab',
                title: "",
            },
            {
                content: <>
                    {getSimpleTutorialMessageTemplate('Filter', 
                    'Filter out data based on connection and tags')}
                </>,
                placement: "left",
                target: '#landingPodFilter',
                title: ""
            },
            {
                content: <>
                    {getSimpleTutorialMessageTemplate('Tags', 
                    'Shows the list of available tags and also you can create or remove')}
                </>,
                placement: "left",
                target: '#tagLiBtn',
                title: ""
            },
            {
                content: <>
                    {getSimpleTutorialMessageTemplate('Favourite', 
                    'Activating this will show all available favourite marked data')}
                </>,
                placement: "left",
                target: '#landingFavBtn',
                title: ""
            },
            {
                content: <>
                    {getSimpleTutorialMessageTemplate('POD', 
                    "You can click to see all information of pod"
                    )}
                </>,
                placement: "top",
                target: '#podCrd0',
                title: "",
            },
            {
                content: <>
                    {getSimpleTutorialMessageTemplate('Create POD', 
                    'You can create pod by clicking this (+) Button'
                    )}
                </>,
                placement: "top",
                target: '#createPodBtn',
                title: "",
                locale: { next: "Got it" }
            },
            {
                content: <>
                    {getSimpleTutorialMessageTemplate('POD Detail', 
                    "Let's go in POD, click on this card"
                    )}
                </>,
                placement: "top",
                target: '#podCrd0',
                locale: { last: "Yes" },
                title: "",
            },
        ]
    )
    
    const [walkthrough, setWalkThrough] = React.useState({
        run: false,
        steps: [...walkthroughSteps]
    })


    const [activeDrags, setActiveDrags] = React.useState(0)
    
    const wrapperRef = React.useRef({})


    React.useEffect(() => {
        startWalkthrough(tutorial)
    }, [tutorial])

    const getCurrentLandingPage = () => {
        switch (props?.activeTabKey) {
            case 'pod':
                return <CatalogGridIndex startWalkthrough={startWalkthrough} {...props} wrapperRef={wrapperRef} />
            case 'business':
                return <DictionaryGridIndex {...props} />
            default:
                return <></>
        }
    }

    const startWalkthrough = (bool) => {
        setWalkThrough({steps: walkthroughSteps, run: bool })
    }

    const walkthroughCallback = (d) => {
        const el = document.querySelector('#main-lpg-wrap')
        if(el) {
            if(d?.status === 'running') {
                el.classList.add('stop-scroll')
                if(d?.index === d?.size - 1) {
                    if(spotClick) return
                    setSpotClick(true)
                }
                else {
                    if(!spotClick) return
                    setSpotClick(false)
                }
            }
            else {
                el.classList.remove('stop-scroll')
                if(!tutorial) return
                dispatch(setNavigatorTutorialStart('landing_page', false))
            }
        }
    }

    return <>
        <ConnectionProvider>
            {getCurrentLandingPage()}
            {
                ![undefined, null, '']?.includes(props?.fromRoute) || props?.activeTabKey !== 'pod' || [null, ""]?.includes(props?.activeDomain) ? ''
                :
                <Draggable 
                    onStart={() => setActiveDrags(prev => ++prev)}
                    onStop={() => setActiveDrags(prev => --prev)}
                    handle='.c-cpilot-sec-wrap, .cpilot-pop-head'
                    axis='x'
                    bounds={{top: 0, left: -(wrapperRef?.current?.clientWidth ? wrapperRef?.current?.clientWidth - 420 : 700), right: 0, bottom: 0}}
                >
                    <div style={{zIndex: 12, position: 'fixed', right: 0, bottom: 0, width: '100%'}}>
                        <ClaristaCopilot
                            type="talkdata"
                            extraProps={{
                                dataDomainId: props?.activeDomain
                            }}
                        />
                    </div>
                </Draggable>
            }
            <Walkthrough
                walkthrough={walkthrough}
                showSkipButton={true}
                spotlightClicks={spotClick}
                disableOverlayClose={true}
                callback={walkthroughCallback}
            />
        </ConnectionProvider>
    </>
    
}

export default NavigatorIndex