import React, { useEffect, useState } from 'react'
import Buttons from '../../../../Common/button/Buttons'
import { CLOSE, PLAY, PROPERTIES, EDITOR, MERGE, EDIT, SAVE } from '../../../../Common/iconSource'
import { Literals } from '../../../common/literals'

import MergedColumns from './MergedColumns'
//import 'antd/dist/antd.css';
import { Tabs } from 'antd';

import { connect, useDispatch, useStore } from 'react-redux'
import { updateFlowsElements, showFlowsTransformdetails, setFlowsCurrenttransformer, executeTransformerApi, saveTransformerNoteClick, setTransformerNames } from '../../../../../store/modules/flows/flowsActions'
import { setActivePodDetails } from '../../../../../store/modules/common/VirtualDataTable/action'
import { useParams } from 'react-router'
import EditorIndex from '../DataSource/editor/EditorIndex'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import DatasetOutputNameInput from '../commonFunctions/useDatasetNameInput'
import { getInputTransformerDetails } from '../FlowsHelperFunction'
import { cloneDeep } from 'lodash'

const { TabPane } = Tabs;

const MergeIndex = (props) => {
    // const [showDummy, setShowDummy] = useState(false)

    const { flowsElements, currentTransformer, transformerNameList, isExecutePermission } = props
    const { flowId } = useParams()
    const dispatch = useDispatch()
    const store = useStore()
    const [activeKey, setActiveKey] = useState('properties')
    const [disableExcute, setdisableExcute] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [outputDatasetName, setoutputDatasetName] = useState('')
    const [showRedBtn, setshowRedBtn] = useState(false);

    useEffect(() => {

        if (!outputDatasetName) {
            setshowRedBtn(true)
        }
    }, [outputDatasetName])
    const [showBtnLoading, setShowBtnLoading] = useState(false)
    useEffect(() => {

        setdisableExcute(true)
        setshowRedBtn(false)
    }, [])
    const onChange = activeKey => {
        setEditMode(false)
        setActiveKey(activeKey)
    };

    const closeLeftSideContent = () => {
        dispatch(setFlowsCurrenttransformer({}))
        dispatch(showFlowsTransformdetails(false))
    }


    useEffect(() => {
        let currentMerge = flowsElements.find(item => item.id === currentTransformer.id)
        if (Object.keys(transformerNameList).length && currentTransformer?.id && transformerNameList[currentTransformer?.id]) {
            setoutputDatasetName(transformerNameList[currentTransformer.id])
        } if (currentMerge?.content?.primaryKeysCollection?.length) {
            let frame1 = Object.keys(currentMerge.content.primaryKeysCollection[0]).filter(item => !item.includes(`key`))[0]
            let frame2 = Object.keys(currentMerge.content.primaryKeysCollection[0]).filter(item => !item.includes(`key`))[1]

            let frame1Array = currentMerge.content.primaryKeysCollection.filter(item => item[frame1])
            let frame2Array = currentMerge.content.primaryKeysCollection.filter(item => item[frame2])

            let columnSelected = cloneDeep(currentMerge.content.outcomeColumns);
            if (frame1Array?.length && frame2Array?.length && columnSelected?.length) {
                setdisableExcute(false)
            } else {
                setdisableExcute(true)

            }
        }

    }, [flowsElements])


    const executeTransformer = async () => {

        let currentMerge = flowsElements.find(item => item.id === currentTransformer.id)


        let frame1 = currentMerge.content?.selectedFirstSecond?.datasource0;//Object.keys(currentMerge.content.primaryKeysCollection[0]).filter(item => !item.includes(`key`))[0]
        let frame2 = currentMerge.content?.selectedFirstSecond?.datasource1; //Object.keys(currentMerge.content.primaryKeysCollection[0]).filter(item => !item.includes(`key`))[1]


        let frame1Array = currentMerge.content.primaryKeysCollection.map(item => `frame1|${item[frame1]}`)
        let frame2Array = currentMerge.content.primaryKeysCollection.map(item => `frame2|${item[frame2]}`)

        let outputFields = []
        let columnSelected = cloneDeep(currentMerge.content.outcomeColumns);

        currentMerge.content.outcomeColumns.forEach(
            item => {
                if (item.type === `${frame1}`) {
                    outputFields.push(`frame1|${item.Name}`)
                }
                else if (item.type === `${frame2}`) {
                    outputFields.push(`frame2|${item.Name}`)
                }
            }
        )

        let valueArr = columnSelected.map((item) => item.Name);
        let isDuplicateIndex = valueArr.map(function (item, idx) {
            if (valueArr.indexOf(item) !== idx) {
                return idx
            }
        }).filter((ele) => ele && ele)


        if (isDuplicateIndex?.length) {
            let duplicateColumnNames = isDuplicateIndex.map((item) => valueArr[item])
            emitToastNotification('error', `Duplicates columns in merge transformers - Please resolve following duplicates : ${duplicateColumnNames.toString()}`)
        } else {
            let InputTransformer = store.dispatch(getInputTransformerDetails())
            let dataSourcePaired = InputTransformer.filter(item => item?.type === `datasource`)
            let domainList = []
            let podDetails = store.getState().FlowsReducer.dataSourcePodDetails;

            if (podDetails.length) {
                dataSourcePaired?.forEach((item) => {

                    let pod = podDetails.find((podItem) => item.id === podItem?.id);
                    if (pod) {
                        domainList.push(
                            `${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName
                            }`
                        );
                    }
                });
            }


            let domain1 = domainList.find(item => item.split(`#`)[0] === frame1)?.split(`#`)[1]
            let domain2 = domainList.find(item => item.split(`#`)[0] === frame2)?.split(`#`)[1]


            if (frame1Array?.length && frame2Array?.length && outputFields?.length) {


                setShowBtnLoading(true)

                let payload = {
                    flow_body: {
                        Id: `${outputDatasetName}`,
                        NodeType: `join`,
                        currentTransformerID: currentTransformer.id,

                        Args: [
                            {
                                "Name": "frame1",
                                "Value": domain1 ? `${domain1}.${frame1}` : frame1
                            },
                            {
                                "Name": "frame2",
                                "Value": domain2 ? `${domain2}.${frame2}` : frame2
                            },
                            {
                                "Name": "merge_type",
                                "Value": "join columns"
                            },
                            {
                                "Name": "join_type",
                                "Value": `${currentMerge.content.selectedJoin}`
                            },
                            {
                                "Name": "keys1",
                                "Value": [...frame1Array]
                            },
                            {
                                "Name": "keys2",
                                "Value": [...frame2Array]
                            },
                            {
                                "Name": "output_fields",
                                "Value": [...outputFields]
                            }
                        ]
                    }
                }
                setShowBtnLoading(true)
                try {
                    let response = await dispatch(executeTransformerApi(flowId, payload, currentTransformer.id))
                    let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
                    if (response.status === 'success') {
                        let outcomeColumns = currentMerge?.content?.outcomeColumns.map(function (item) {
                            if (item?.checked) {
                                item.checked = false;
                            }
                            return item
                        })

                        transformerNameList[currentTransformer.id] = outputDatasetName
                        dispatch(setTransformerNames({ ...transformerNameList }))
                        dispatch(setActivePodDetails({ podName: `${outputDatasetName}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache' }))
                        setShowBtnLoading(false)
                        setshowRedBtn(false)


                        let finalFlowsElements = flowsElements.map(item => {
                            if (item.id === currentTransformer.id) {
                                item.transformerName = outputDatasetName;
                                item.content.outcomeColumns = outcomeColumns;
                                item.content.executed_DAG = payload.flow_body;
                            }
                            return item
                        })

                        dispatch(updateFlowsElements([...finalFlowsElements]))
                    } else {
                        setShowBtnLoading(false)
                        setshowRedBtn(false)


                    }

                }
                catch (error) {
                    let finalFlowsElements = flowsElements.map(item => {
                        if (item.id === currentTransformer.id) {
                            item.transformerName = outputDatasetName
                            item.content.executed_DAG = payload.flow_body;
                        }
                        return item
                    })

                    dispatch(updateFlowsElements([...finalFlowsElements]))
                    setShowBtnLoading(false)
                    setshowRedBtn(false)


                }
            } else {
                emitToastNotification('warning', 'Please select merge keys and atleast one output column')

            }

        }

    }




    return (
        <>
            <div className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
                <div className="d-flex align-items-center">
                    <MERGE />
                    <h3 className="subtitle">Merge</h3>
                    {/* <img src={properties} alt={`properties`} className="mr-3 ml-3" />
                        <img src={sticky} alt={`sticky`} /> */}
                    <Tabs onChange={onChange} activeKey={activeKey} className="custom-tab tab-with-icon">
                        <TabPane tab={<PROPERTIES />} key="properties">
                        </TabPane>
                        <TabPane tab={<EDITOR />} key="sticky">
                        </TabPane>
                    </Tabs>
                </div>
                <div className="d-flex align-items-center">
                    <DatasetOutputNameInput setTransformerName={setoutputDatasetName} isdisabled={false} transformerName={outputDatasetName} />

                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { activeKey !== 'sticky' || (activeKey === 'sticky' && !editMode) ? closeLeftSideContent() : setEditMode(false); onChange('properties') }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />

                    {activeKey === 'sticky' ? <Buttons props={{ buttonText: !editMode ? 'Edit' : 'Save', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: !editMode ? () => { setEditMode(true); dispatch(saveTransformerNoteClick(false)) } : () => { dispatch(saveTransformerNoteClick(true)) }, ImgSrc: activeKey === 'editor' ? () => <EDIT /> : () => <SAVE />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}

                    {activeKey !== 'sticky' ? <Buttons props={{ buttonText: 'Execute', buttonClassName: `${showRedBtn ? 'execute-btn-gradient' : ''} custom-btn-primary custom-btn btn-with-text`, buttonEvent: activeKey === 'sticky' ? () => { setEditMode(true) } : () => { executeTransformer() }, ImgSrc: activeKey === 'sticky' ? () => <EDIT /> : () => <PLAY />, isDisable: !isExecutePermission || outputDatasetName === '' || disableExcute ? true : false, showLoading: showBtnLoading, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}
                    {/* <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { closeLeftSideContent() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                    <Buttons props={{ buttonText: 'Execute', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { executeTransformer() }, ImgSrc: () => <PLAY />, isDisable: disableExcute, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> */}
                </div>
            </div>

            {
                (activeKey === 'properties') && <MergedColumns outputDatasetName={outputDatasetName} setoutputDatasetName={setoutputDatasetName} setdisableExcute={setdisableExcute} />
            }
            {
                (activeKey === 'sticky') && <EditorIndex editMode={editMode} setEditMode={setEditMode} />
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer,
        transformerNameList: state.FlowsReducer.transformerName,

        flowId: state.FlowsReducer.id
    };
};
export default connect(mapStateToProps)(MergeIndex)