import { combineReducers } from "redux";
import { PodSelectionModalReducer } from "./podSelectionModalAction";
import VirtualDataTable from "./VirtualDataTable/reducer";
import DataGridTable from "./DataGridTable/dataGridTableRedux";
import TutorialRedux from "./tutorialRedux";
import GenAi from "./GenAi/GenAiReducer";
import Notifications from "./Notifications/NotificationsReducer";

const CommonComponent = combineReducers({
  VirtualDataTable,
  PodSelectionModalReducer,
  DataGridTable,
  TutorialRedux,
  GenAi,
  Notifications
});
export default CommonComponent
