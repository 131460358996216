import { Checkbox, Popover, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { MultipleColsDndList } from './MultipleColsDndList'
import SingleSelection from '../../../Common/dropDown/SingleSelection'
import { SIXDOTS } from '../../../Common/iconSource'
import { columnNameRegex, columnNameRegexExp, dataTypeList, removeStartingUnderscores, schemaTableOptionalFieldHelpContent } from '../../common/helperFunctions'
import FormatColHelplinkTable from '../../dataCatalogue/createPOD2/components/FormatColHelplinkTable'
import { useRef } from 'react'

const DndSchemaList = ({ actualData, reOrderRender, setreOrderRender, stopDraggingRow = false, setNewColumnList = () => { }, Selectall = () => { } }) => {

    const [dataTypes, setDataTypes] = useState([...dataTypeList])
    const [openformatHelpModal, setopenformatHelpModal] = useState(false);

    const [businessTerms, setBusinessTerms] = useState(
        [
            {
                Name: "Sec_ID",
                value: "sec_id",
            },
            {
                Name: "Issuer_Name",
                value: "issuer_name",
            },
            {
                Name: "Ticker",
                value: "ticker",
            },
        ]
    )

    const [demo, setDemo] = useState([])
    const [columns, setColumns] = useState([])

    const [colsList, setColsList] = useState([
        { key: 'checked', title: '', resizable: false, isRequired: false, disabled: true, visible: true, width: 5, frozen: true },
        { key: 'old_name', title: <div className='text-dark'>Input Column Name</div>, resizable: false, isRequired: true, disabled: true, visible: true, width: 180 },
        { key: 'new_name', title: <div className='text-dark'>New Column Name</div>, resizable: false, isRequired: true, disabled: window.location.href.includes("viewFlows") ? true : false, visible: true, width: 180 },
        { key: 'new_datatype', title: <div className='text-dark'>Data Type</div>, resizable: false, isRequired: true, disabled: window.location.href.includes("viewFlows") ? true : false, visible: true, width: 180 },
        {
            key: 'format', title:
                <div className='text-dark'>Format
                    <i className="bx bx-info-circle m-1 text-black-50 cursor-pointer color-primary" onClick={() => setopenformatHelpModal(true)}></i>

                </div>
            ,

            resizable: false, isRequired: false, visible: true, disabled: false, width: 100
        },
    ])


    function Validate(e) {
      
        let keyCode = e.key

        //Regex to allow only Alphabets Numbers Dash Underscore and Space
        let pattern = /^[a-zA-Z0-9_]*$/;

        //Validating the textBox value against our regex pattern.
        let isValid = pattern.test(keyCode);
        let match = keyCode.match(pattern)
        if ((!new_name_aggrigate.current?.value?.length && (e.keyCode > 47 && keyCode < 58)) || (new_name_aggrigate.current?.selectionStart === 0 && (e.keyCode > 47 && keyCode < 58))) {
            e.preventDefault()
        } else if (!isValid) {
            e.preventDefault()
        }
    }

    const new_name_aggrigate = useRef()

    const getFieldTemplate = (key, index, value = '', additionalClasses = '', isDisable = false, column) => {

        switch (key) {
            case 'checked':
                return <div className='d-flex align-items-center'>
                    <Checkbox checked={value} className="mr-2 " disabled={window.location.href.includes("viewFlows") ? true : isDisable} onChange={(e) => onInputChangeHandler(key, e.target.checked, index)}>
                    </Checkbox>
                    <div className='cursor'> <SIXDOTS /></div>
                </div>

            case 'old_name':
            case 'new_name':

                return <div className='d-flex align-items-center justify-content-left'>

                    <div className='' onKeyDown={(e) => e.stopPropagation()}>
                        {reOrderRender ? <Tooltip title={value}>

                            {key === 'old_name' ? <div className={column?.showError ? 'old-name-error' : ''} style={{ fontFamily: 'InterRegular', fontSize: "11px", textAlign: "left" ,color:"black"}}>{value}</div> : <input
                                required
                                type="text"
                                ref={new_name_aggrigate}
                                // key={column.id}
                                defaultValue={value}
                                onKeyDown={(e) => Validate(e)}
                                onClick={(e) => e?.stopPropagation()}
                                onChange={(e) => {
                                
                                    // if (columnNameRegex.test(e.target.value) || e.target.value === "") {
                                    onInputChangeHandler(key, e.target.value, index)

                                    // if (key === 'format') {
                                    //     onInputChangeHandler(key, e.target.value, index)
                                    // }
                                }}
                                placeholder=''
                                className={`custom-input-field text-dark ${additionalClasses}`}
                                disabled={window.location.href.includes("viewFlows") ? true : isDisable}
                            />}
                        </Tooltip> : null}
                    </div>
                </div>

            case 'format':
                return <div className='d-flex align-items-center justify-content-left'>

                    <div className='' onKeyDown={(e) => e.stopPropagation()}>
                        {reOrderRender ? <Tooltip title={value}>

                            {key === 'old_name' ? <div className={column?.showError ? 'old-name-error' : ''} style={{ fontFamily: 'InterRegular', fontSize: "11px", textAlign: "left" }}>{value}</div> : <input
                                required
                                type="text"
                                // ref={new_name_aggrigate}
                                // key={column.id}
                                defaultValue={value}
                                onKeyDown={(e) => e.stopPropagation()}
                                onClick={(e) => e?.stopPropagation()}
                                onChange={(e) => {
                                   
                                    // if (columnNameRegex.test(e.target.value) || e.target.value === "") {
                                    onInputChangeHandler(key, e.target.value, index)

                                    // if (key === 'format') {
                                    //     onInputChangeHandler(key, e.target.value, index)
                                    // }
                                }}
                                placeholder=''
                                className={`custom-input-field text-dark ${additionalClasses}`}
                                disabled={window.location.href.includes("viewFlows") ? true : isDisable}
                            />}
                        </Tooltip> : null}
                    </div>
                </div>
            case 'new_datatype':
                return <>
                    <div className='d-flex align-items-center h-100' onKeyDown={(e) => e.stopPropagation()}>
                        <SingleSelection
                            props={{
                                placeholder: "Select",
                                dropDownValue: value,
                                onClickEvent: (val) => onInputChangeHandler(key, val, index),
                                optionList: key === 'new_name' ? businessTerms : dataTypes,
                                isDisable: window.location.href.includes("viewFlows") ? true : isDisable,
                                isSortOptionDisable: true,
                                selectClassName: 'w-100'
                            }}
                        />
                    </div>
                </>

            default:
                break;
        }
    }

    const generateColumns = (columns_list) => {
        let cols = [], colsListCopy = [...columns_list]
        cols = colsListCopy.filter(v => v.visible === true).map(v => {
            return v.key !== 'checked' ? {
                name: <label className='font-w-600 small mb-0'>
                    {v.title}
                    {v.isRequired ? <span><sup>*</sup></span> : ''}
                </label>,
                key: v.key,
                sortable: false,
                resizable: false,
                width: v.width,
                frozen: v.frozen ?? false
            } : {
                name: <div className='d-flex align-items-center'><Checkbox checked={isAllSelected()} disabled={window.location.href.includes("viewFlows") ? true : false} onChange={(e) => Selectall(e.target.checked)}></Checkbox></div>,
                key: v.key,
                sortable: false,
                resizable: false,
                width: v.width,
                frozen: v.frozen ?? false
            }
        })
        /* cols.push({
            name: <label className='w-100 text-center font-w-600 grey-color label mb-0 paragraph'>
                Action
            </label>,
            key: 'action_btns',
            sortable: false,
            resizable: falsee,
            width: 20,


        }) */
        return cols
    }

    const isAllSelected = () => {
        return actualData.every((ele) => ele?.checked === true)
    }
    useEffect(() => {
        let temp = [], cols = [], obj = {};
        let rows = [...actualData]
        temp = rows.map((v, i) => {

            Object.keys(v).forEach(key => obj[key] = getFieldTemplate(key, i, v[key], '', key === 'old_name' ? true : v[key]?.disabled, v))
            return { ...obj }

        })
        setDemo([...temp])
        cols = [...generateColumns(colsList)]
        setColumns([...cols])

    }, [actualData, reOrderRender])


    const onInputChangeHandler = (field, value, index) => {

        let dataCopy = [...actualData]

        dataCopy[index][field] = value
        if (field === 'new_name') {
            // removeStartingUnderscores(value.replaceAll(columnNameRegexExp, '_'))

            dataCopy[index]['text'] = value
        }
        setNewColumnList([...dataCopy])

    }

    return (
        <>
            <div className='custom-card padding-2 bg-white pb-0' style={{ height: '100%', overflow: 'hidden    ' }}>
                <div className='flows-dnd-list' style={{ overflow: 'auto', height: 'calc(100% - 0px)' }}>
                    <MultipleColsDndList
                        setreOrderRender={setreOrderRender}
                        colsList={columns}
                        rowsList={demo}
                        stopDraggingRow={stopDraggingRow}
                        setData={setNewColumnList}
                        rawData={actualData}
                    />
                </div>
            </div>

            <FormatColHelplinkTable isModalOpen={openformatHelpModal} onClose={() => setopenformatHelpModal(false)}></FormatColHelplinkTable>
        </>
    )
}

export default DndSchemaList;
