import { emitToastNotification } from "../../../helpers/toast_helper";
import { fetchData } from "../../../services/apiService";
import { setLoadingStatus } from "../common/loadingActions";

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const getClusterList = (showStopped,state=undefined) => {
    return (dispatch) => {
      dispatch(setLoadingStatus(true));

      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            "GET",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/?${showStopped !== undefined?'include_stopped='+''+showStopped:''}${state !== undefined?'state='+''+state:''} `,
            {}
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));

            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp?.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));

            emitToastNotification("error", err?.message);
           
            reject(err);
          });
      });
    };
  };

  export const getClusterTypeList = (type) => {
    return (dispatch) => {
      dispatch(setLoadingStatus(true));

      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            "GET",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/?${type !== undefined?'type'+'='+type:''} `,
            {}
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));

            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp?.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));

            emitToastNotification("error", err?.message);
           
            reject(err);
          });
      });
    };
  };

  export const autoShutdownApi = (method,payload) => {
    return (dispatch) => {
      dispatch(setLoadingStatus(true));

      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            method,
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/auto_shutdown/`,
            payload
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));

            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp?.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));

            emitToastNotification("error", err?.message);
           
            reject(err);
          });
      });
    };
  };

  
  export const saveQuantity = (method,payload,cluster_id) => {
    return (dispatch) => {
      dispatch(setLoadingStatus(true));

      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            "POST",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/${cluster_id}/quantity/`,
            payload
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));

            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp?.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));

            emitToastNotification("error", err?.message);
           
            reject(err);
          });
      });
    };
  };

  export const saveClusterUserGroups = (method,payload,cluster_id) => {
    return (dispatch) => {
      dispatch(setLoadingStatus(true));

      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            "POST",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/${cluster_id}/group/`,
            payload
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));

            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp?.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));

            emitToastNotification("error", err?.message);
           
            reject(err);
          });
      });
    };
  };

  export const getCreditAmountBalance = () => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            "GET",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/wallet/`,
            {}
          )
        )
          .then((resp) => {
            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
            console.log(err)
            emitToastNotification("error", err.message);
           
            reject(err);
          });
      });
    };
  };

  export const getCreditHistory = (drilldown='monthly',cluster_id=undefined,endDate=undefined) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            "GET",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/credit_usage/?granularity=${drilldown}${cluster_id?`&cluster_id=${cluster_id}`:''}${endDate?`&end_date=${endDate}`:''}`,  //?cluster_id=<integer>&state=RUNNING&drilldown=Weekly&start_date=<date>
            {}
          )
        )
          .then((resp) => {
            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp?.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
            emitToastNotification("error", err?.message);
           
            reject(err);
          });
      });
    };
  };

  export const getCreditUsageChart = (granularity='monthly',cluster_id=undefined,endDate=undefined) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            "GET",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/credit_usage_plot/?granularity=${granularity}${cluster_id?`&cluster_id=${cluster_id}`:''}${endDate?`&end_date=${endDate}`:''}`,  //?cluster_id=<integer>&state=RUNNING&drilldown=Weekly&start_date=<date>
            {}
          )
        )
          .then((resp) => {
            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
         
            emitToastNotification("error", err.message);
           
            reject(err);
          });
      });
    };
  };

  export const saveCreditValue = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}control_centre/cluster/wallet/`, payload)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}

  export const startClusterAction = (id) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(setLoadingStatus(true));                   
        dispatch(
          fetchData(
            "GET",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/${id}/status/`,
            {}
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));
            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp.message);
            } else {
              emitToastNotification("success", resp.message);
           
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));
            emitToastNotification("error", err.message);

            console.log(err)
           
            reject(err);
          });
      });
    };
  };

  export const stopClusterAction = (id) => {
    return (dispatch) => {
      dispatch(setLoadingStatus(true));   
      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            "DELETE",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/${id}/status/`,
            {}
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));
            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp.message);
            } else {
              emitToastNotification("success", resp.message);
           
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));
            console.log(err)
            emitToastNotification("error", err.message);
           
            reject(err);
          });
      });
    };
  };

  export const pinClusterAction = (id,pinned) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(setLoadingStatus(true));
        dispatch(
          fetchData(
            pinned?"DELETE":"GET",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/${id}/pin/`,
            {}
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));
            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));
            console.log(err)
           
            reject(err);
          });
      });
    };
  };

  export const unpinCluster = (id) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
  
        dispatch(
          fetchData(
            "DELETE",
            window._env_.REACT_APP_API_BASE_URL + `control_centre/cluster/${id}/pin/`,
            {}
          )
        )
          .then((resp) => {
            if (resp.status === false) {
              reject(resp.message);
              emitToastNotification("error", resp.message);
            } else {
           
              resolve(resp);
            }
          })
          .catch((err) => {
            console.log(err)
           
            reject(err);
          });
      });
    };
  };

  export const createClusterAction = (payload, showToast = true) => {
    return (dispatch) => {
      dispatch(setLoadingStatus(true)); //add loader

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}control_centre/cluster/`, payload)
            )
                .then((resp) => {
                dispatch(setLoadingStatus(false));

                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                  dispatch(setLoadingStatus(false));

                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}

export const scheduleClusterAction = (id,payload, showToast = true) => {
  return (dispatch) => {
      return new Promise((resolve, reject) => {
          dispatch(
              fetchData("PUT", `${BASE_URL}control_centre/cluster/${id}/schedule/`, payload)
          )
              .then((resp) => {
                  if (showToast) {
                      emitToastNotification("success", resp.message)
                  }
                  resolve(resp)
              })
              .catch((err) => {
                  if (showToast) {
                      emitToastNotification('error', err?.message);
                  }
                  reject(err);
              })
      });
  };
}


export const deleteScheduleClusterAction = (id,method, showToast = true) => {
  return (dispatch) => {
      return new Promise((resolve, reject) => {
          dispatch(
              fetchData(method, `${BASE_URL}control_centre/cluster/${id}/schedule/`, {})
          )
              .then((resp) => {
                  if (showToast) {
                      emitToastNotification("success", resp.message)
                  }
                  resolve(resp)
              })
              .catch((err) => {
                  if (showToast) {
                      emitToastNotification('error', err?.message);
                  }
                  reject(err);
              })
      });
  };
}
const initialState = {
  
}

// export const Cluster = (state = initialState, action) => {
//     const { type, payload } = action;
//     switch (type) {


//         case RESET_CLUSTER:
           
//             return initialState
//         default:
//             return state;
//     }
// }
