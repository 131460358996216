export const SET_PREVIEW_TABLE = "SET_PREVIEW_TABLE";
export const  SET_PREVIEW_TABLE_ROWSET=" SET_PREVIEW_TABLE_ROWSET"
export const  SET_PREVIEW_TABLE_RECORDS=" SET_PREVIEW_TABLE_RECORDS"
export const  SET_PREVIEW_INITIAL_STATE=" SET_PREVIEW_INITIAL_STATE"
export const  SET_PREVIEW_NEXT_URI=" SET_PREVIEW_NEXT_URI"
export const  SET_PREVIEW_FILTERS=" SET_PREVIEW_FILTERS"
export const  SET_PREVIEW_SORT=" SET_PREVIEW_SORT"
export const  SET_PREVIEW_QUERY=" SET_PREVIEW_QUERY"
export const  SET_PREVIEW_FLAG=" SET_PREVIEW_FLAG"
export const  SET_PREVIEW_SEARCH_TERM=" SET_PREVIEW_SEARCH_TERM"
export const RESET_NEXT_RECORD_TOKEN="RESET_NEXT_RECORD_TOKEN"
export const SET_QUERY_ERROR="SET_QUERY_ERROR"
export const SET_PREVIEW_DATA_MAP = "SET_PREVIEW_DATA_MAP"
export const SET_SEARCH_MODE="SET_SEARCH_MODE"
export const SET_PREVIEW_SEARCH_TEXT = "SET_PREVIEW_SEARCH_TEXT";

export const SET_CORELATION_GRAPH="SET_CORELATION_GRAPH"
export const RESET_TABLE_RECORDS="RESET_TABLE_RECORDS"
export const SET_NEXT_PAGE_COUNTER="SET_NEXT_PAGE_COUNTER"

export const RESET_NEXT_PAGE_COUNTER="RESET_NEXT_PAGE_COUNTER"
export const SET_ALL_DATA_LOADED="SET_ALL_DATA_LOADED"
export const SET_ROW_COUNT="SET_ROW_COUNT"
export const SET_IS_API_ERROR="SET_IS_API_ERROR"
export const SET_PREVIEW_PAGE_DEFAULT = "SET_PREVIEW_PAGE_DEFAULT";
export const SET_CHATGPT="SET_CHATGPT"
export const SET_SAVED_QUERY_SELECTED_INDEX = "SET_SAVED_QUERY_SELECTED_INDEX";
export const SET_ANALYZE_MODAL_VISIBILITY = "SET_ANALYZE_MODAL_VISIBILITY";
export const SET_SAVE_SQL_TRIGGER = "SET_SAVE_SQL_TRIGGER";


