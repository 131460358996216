
import { fetchData } from "../../services/apiService";
import { emitToastNotification } from "../../helpers/toast_helper";
import { setLoadingStatus } from "../modules/common/loadingActions";


const BaseUrl = window._env_.REACT_APP_API_BASE_URL

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;


export const getVersionData=({content_id,module_type,page})=>{
  return (dispatch) => {
		dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}audit_log/version_log/?page=${page??1}&content_id=${content_id}&&module_type=${module_type}`))
				.then((resp) => {
					if (resp.status === "success") {


						dispatch(setLoadingStatus(false));
						resolve(resp)
					} 
          else if (resp.status === "success" && resp.message === "No results found") {
            dispatch(setLoadingStatus(false));

            resolve(resp);
          } 
          
          else {
						dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}

export const getAuditData=({category,id,action,page,username,table_name,domain,start_date,end_date})=>{
  return (dispatch) => {
		// dispatch(setLoadingStatus(true));

		return new Promise((resolve, reject) => {
			dispatch(fetchData('GET', `${BASE_URL}audit_log/audit_log/?page=${page??1}&limit=100${id?`&module_id=${id}`:""}${action?`&action=${action}`:""}${category?`&module_type=${category}`:""}${username?`&module_id=${username}`:""}${table_name?`&table_name=${table_name}`:""}${domain?`&domain=${domain}`:""}${start_date?`&start_date=${start_date}`:""}${end_date?`&end_date=${end_date}`:""}`))
				.then((resp) => {
					if (resp.status === "success") {


						// dispatch(setLoadingStatus(false));
						resolve(resp)
					} 
          else if (resp.status === "success" && resp.message === "No results found") {
            dispatch(setLoadingStatus(false));

            resolve(resp);
          } 
          
          else {
						// dispatch(setLoadingStatus(false));
						emitToastNotification('error', resp?.message);
						reject(resp?.message);
					}
				}).catch((err) => {
					// dispatch(setLoadingStatus(false));
					emitToastNotification('error', err?.message);
					reject(err);
				});
		})
	}
}


export const setFilter = (data) => ({
    type: "SET_FILTER",
    payload: data,
  });
  
  export const setViewMode = (data) => {
 
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(
          fetchData("POST", `${BaseUrl}profile/view/`,data)
        ).then((resp) => {
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
          .catch((err) => {
          });
      });
    }
  }