import { Tooltip } from 'antd';
import _, { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import useDebounce from '../../../../../customHooks/useDebounce';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { CHECKOUTPUTNAME, ERRORCOLORCLOSE, NOTEINFO, OUTPUTNAMEICON, RENAME } from '../../../../Common/iconSource';
import { getObjectLength, preventEnterEvent, preventSpaceEvent } from '../../../common/helperFunctions';
import '../../Newflow.scss';
import { getTransformerName, isTransformerNameAlreadyPresent, setTransformerNameinflowselement } from './FlowsHelperFunction';
import { saveFlow, updateFlowsElements } from '../../../../../store/modules/flows/NewFlowsRedux/flowsActions';



function DatasetOutputNameInput({updatedFlowRedux, setshowCloseWarning, setupdatedFlowRedux, isdisabled = false, setTransformerName = () => { }, transformerId }) {

    const [term, setTerm] = useState('')
    const [showSuccess, setshowSuccess] = useState(false)
    const [showError, setshowError] = useState(false)
    const [animate, setAnimate] = useState(false)

    const [firstTimeSet, setfirstTimeSet] = useState(false)
    const currentTransformer = useSelector(state => state.Lab.currentTransformer)
    const flowsElements = useSelector(state => state.Lab.flowsElements)
    const transformerNameList = useSelector(state => state.Lab.transformerName)

    const debouncedSearchTerm = useDebounce(term, 100);
    const store = useStore()

    useEffect(() => {
        if (_.isEmpty(term)) {
            setAnimate(true)
        } else {
            setAnimate(false)

        }
    }, [term])

    const updateRedux = (transformerName) => {

        let cloneflowsElements = {};
        if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {
            cloneflowsElements= cloneDeep(updatedFlowRedux)
        }else{
            cloneflowsElements= cloneDeep(flowsElements)

        }
        if (cloneflowsElements?.nodes?.length && currentTransformer?.id) {

            let newNode = cloneflowsElements?.nodes.map(
                (item) => {
                    if (item?.id === currentTransformer.id) {
                        item['transformerName'] = transformerName;

                    }
                    return item
                }
            );
            cloneflowsElements['nodes'] = newNode;
            setupdatedFlowRedux(cloneflowsElements);
            setTransformerName(transformerName)
            // store.dispatch(updateFlowsElements(cloneflowsElements));
        }
    }

    useEffect(
        () => {

            if (debouncedSearchTerm && !firstTimeSet) {
                // if (Object.keys(transformerNameList)) {
                let isTransformerNameExist = store.dispatch(isTransformerNameAlreadyPresent(debouncedSearchTerm))
                if (!isTransformerNameExist) {
                    // setshowSuccess(true);
                    setshowError(false)
                    setTransformerName(debouncedSearchTerm)
                    updateRedux(debouncedSearchTerm)
                    // store.dispatch(setTransformerNameinflowselement(debouncedSearchTerm))
                    if (transformerId) {
                        transformerNameList[transformerId] = debouncedSearchTerm

                    } else {

                        transformerNameList[currentTransformer.id] = debouncedSearchTerm
                    }
                } else {
                    setshowSuccess(false)
                    setTransformerName('')
                    if (transformerId) {
                        transformerNameList[transformerId] = ''

                    } else {

                        transformerNameList[currentTransformer.id] = ''
                    }


                    setshowError(true)
                    emitToastNotification('error', 'Transformer with this name already exist, please enter other transformer name')

                }
                // } 

            }

        },

        [debouncedSearchTerm]
    );

    useEffect(() => {
        if(getObjectLength(currentTransformer)){
            setTerm(store.dispatch(getTransformerName(transformerId)))
            // setshowSuccess(true)
            setfirstTimeSet(true)
    
        }
     
    }, [currentTransformer])

    // useEffect(() => {
    //     if (transformerName !== '') {
    //         setTerm(transformerName)
    //         transformerNameList[currentTransformer.id] = transformerName

    //         setshowSuccess(true)
    //         setfirstTimeSet(true)
    //     }
    // }, [transformerName])

    function Validate(e) {

        // if (e?.key?.toLowerCase() === "enter" && !_.isEmpty(term)) {
        //     setAnimate(_.isEmpty(term));
        //     let isTransformerNameExist = store.dispatch(isTransformerNameAlreadyPresent(term))
        //     if (!isTransformerNameExist) {
        //         setshowSuccess(true)
        //         setTransformerName(term)

        //         let cloneflowsElements = cloneDeep(flowsElements);
        //         if (cloneflowsElements?.nodes.length && currentTransformer?.id) {

        //             let newNode = cloneflowsElements?.nodes.map(
        //                 (item) => {
        //                     if (item?.id === currentTransformer.id) {
        //                         item['transformerName'] = term;

        //                     }
        //                     return item
        //                 }
        //             );
        //             cloneflowsElements['nodes'] = newNode;
        //             store.dispatch(updateFlowsElements(cloneflowsElements));
        //         }

        //         setTimeout(() => {
        //             store.dispatch(saveFlow(store.getState().Lab?.id, true, false, undefined)).then((resp) => {

        //             }).catch((err) => {

        //             })
        //         }, 500);
        //     } else {
        //         setshowError(true)

        //     }
        // } else {
        let keyCode = e.key

        //Regex to allow only Alphabets Numbers Dash Underscore and Space
        let pattern = /^[a-z0-9_\b]*$/;

        //Validating the textBox value against our regex pattern.
        let isValid = pattern.test(keyCode);


        if ((!e.target?.value?.length && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)) && e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39) || (e.target?.selectionStart === 0 && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)) && e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39)) {
            e.preventDefault()
        } else if (!isValid && e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39) {
            e.preventDefault()
        }
        // }




    }


    return (

        <span className={`position-relative mr-2 d-flex align-items-center`}>

            <div className={`custom-search-icon output-name-ic mr-1 ${_.isEmpty(term) && animate ? 'bx-flashing required' : ''}`}>
                {/* <OUTPUTNAMEICON /> */}
                <RENAME />
            </div>
            {/* "Only alphanumeric character and underscores are allowed in output dataset name" */}
            <Tooltip placement='bottom' title={!showError ? term : "This name is already exist"}>
                <div className={`custom-search ${_.isEmpty(term) && animate ? 'bx-flashing' : ''} ${showSuccess ? "custom-close-icon-success" : showError ? 'custom-close-icon-error' : ''}`}>
                    <input
                        type="text"
                        placeholder='Enter Transformer Name'
                        onClick={() => setAnimate(false)}
                        onBlur={() => {
                            setAnimate(_.isEmpty(term));
                            let isTransformerNameExist = store.dispatch(isTransformerNameAlreadyPresent(term))
                            if (!isTransformerNameExist) {
                                setshowSuccess(true)
                            } else {
                                setshowError(true)

                            }
                        }}

                        onChange={(e) => {

                            if (!e.target.value) {

                                // setshowSuccess(true)
                                setshowError(false)
                                setTransformerName('')
                                updateRedux('')

                                // store.dispatch(setTransformerNameinflowselement(''))

                                if (transformerId) {
                                    transformerNameList[transformerId] = ''

                                } else {

                                    transformerNameList[currentTransformer.id] = ''
                                }
                            }
                            setshowCloseWarning(true)
                            setTerm(e.target.value); setshowError(false);
                            setfirstTimeSet(false);
                            setshowSuccess(false);
                        }}
                        onKeyDown={(e) => { Validate(e) }}
                        className={showSuccess ? "custom-search-input trans-input transformerName_input_success pl-2" : showError ? "custom-search-input trans-input transformerName_input_error pl-2" : 'custom-search-input trans-input pl-2'}
                        value={term}
                        disabled={isdisabled || window.location?.href?.includes('viewFlows')}

                    />
                    {/* <p className='tablename-note'><NOTEINFO />Only alphanumeric character and underscores are allowed</p> */}

                    {/* <div className={showSuccess ? "custom-close-icon-success" : showError ? 'custom-close-icon-error' : ''}>
                        {showSuccess && <CHECKOUTPUTNAME />}
                        {showError && <ERRORCOLORCLOSE />}
                    </div> */}
                    {/* <img
                        src={CloseIcon}
                        alt="SearchIcon"
                        
                        
                    /> */}
                </div></Tooltip>
            {showError ? <label style={{ fontSize: "10px" }} className='error m-2'><NOTEINFO width='13' height='13' color='#ff565e' /> Transformer with this name already exist</label> : null}

        </span>



    );
}




export default DatasetOutputNameInput
