import { cloneDeep } from "lodash";
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import {
  getActivePodData,
  setActivePodPODDetails,
  setPodRightSideData,
} from "../../../../../store/actions";
import { getObjectLength } from "../../../common/helperFunctions";
import { Literals } from "../../../common/literals";
import ConnectionContext from "../../../connections/landingPage/components/ConnectionContext";
import SqlEditor from "../../preview/components/SqlEditor";
import "./CreatePodEditor.scss";

const CreatePodEditor = forwardRef(
  ({
    readOnly,
    editorWidth,
    editorHeight,
    fetchQueryResults,
    setIsEditing = () => { },
    isPodWithinPod = false,
    showSqlEditor = true,
    setStoredResultQuery = () => { },
    storedResultQuery = ''
  }, ref) => {
    const dispatch = useDispatch();
    const store = useStore()
    const { selectedConn } = useSelector(
      (state) => state.UserManagement.ConnectionReducer
    );
    const { podUserInputDataMap, activePodTabDataKey, virtualTablesDataMap, podRightSideData } =
      useSelector((state) => state.CreatePODReducer);

    //Extracting Data From REDUX
    // const activePodTabData = podUserInputDataMap.find(
    //   (data) => data.key == activePodTabDataKey
    // );
    const { activePODKey } = podRightSideData

    const { activeCreatePodName } = virtualTablesDataMap[1];
    const { query, queryCreatingResults } = podRightSideData;
    // const { activeCreatePodName } = virtualTablesDataMap[activePodTabDataKey];
    // const { query, queryCreatingResults } = activePodTabData.podRightSide;

    const [sqlTableDetails, setSqlTableDetails] = useState([]);
    const connectionContext = useContext(ConnectionContext)

    useImperativeHandle(ref, () => ({
      executeQuery: () => {
        if (query) {
          fetchQueryResults(query);
        }
      },
    }));

    const createSqlEditorDetailsHandler = () => {
      const isPodOfPod = (window.location.href.toLocaleLowerCase().includes(Literals.POD_OF_POD) || isPodWithinPod)
      const sqlTableDetails = [];
      const createPodTablesDataMap = virtualTablesDataMap[1];

      Object.entries(createPodTablesDataMap).forEach(
        ([schemaName, schemaData]) => {
          if (!schemaName.includes("results")) {
            if (Object.entries(schemaData).length) {
              Object.entries(schemaData).forEach(([tableName, tableData]) => {
                if (tableData.data_header) {
                  tableData.data_header.forEach((d) => {
                    sqlTableDetails.push({
                      name: d,
                      description: "",
                      type: "Column",
                      tableName: tableName,
                    });
                  });
                  sqlTableDetails.push({
                    name: !isPodOfPod ? `${selectedConn?.connection_name}.${schemaName}.${tableName}` : `${schemaName}.${tableName}`,
                    description: "",
                    type: "Table",
                    tableName: "",
                  });
                }
              });
            }
          }
        }
      );


      setSqlTableDetails(sqlTableDetails);
    };

    const generateQueryHandler = () => {
      if (!activeCreatePodName.toLowerCase().includes("results")) {
        const [schemaName, tableName] = activeCreatePodName.split("-");
        const createPodTablesDataMap = virtualTablesDataMap[1];

        // const createPodTablesDataMap =
        //   virtualTablesDataMap[activePodTabDataKey];

        let queryStringStart = "SELECT ";
        let queryString = "";
        let queryStringEnd = "";
        if (window.location.href.toLocaleLowerCase().includes(Literals.POD_OF_POD) || isPodWithinPod) {
          queryStringEnd = `FROM ${schemaName}.${tableName}`;
        }
        else {
          queryStringEnd = `FROM ${selectedConn?.connection_name}.${schemaName}.${tableName}`;
        }

        const tableColumns =
          createPodTablesDataMap?.[schemaName]?.[tableName]?.data_header;
        const lastColumnIndex = tableColumns?.length - 1;
        const results =
          createPodTablesDataMap?.[schemaName]?.[tableName]?.data_record;



        if (results?.length) {
          tableColumns.forEach((column, index) => {
            if (index !== lastColumnIndex) {
              queryString += `"${column}",`;
            } else {
              queryString += `"${column}"`;
            }
          });

          onQueryChangeHandler(
            `${queryStringStart} ${queryString} ${queryStringEnd}`
          );

        } else {
          onQueryChangeHandler("");
        }
      } else {
        const createPodTablesDataMap = virtualTablesDataMap[1];
        // const createPodTablesDataMap =
        //   virtualTablesDataMap[activePodTabDataKey];

        //If Results doesnt exist
        if (!Object.keys(createPodTablesDataMap?.["results"]).length) {
          onQueryChangeHandler(storedResultQuery);
        } else {
          onQueryChangeHandler(storedResultQuery);
        }
      }
    };

    const onQueryChangeHandler = (query) => {
      setIsEditing(true)
      if (activeCreatePodName.toLocaleLowerCase() === 'results' || activeCreatePodName.toLocaleLowerCase() === 'results-results') {
        setStoredResultQuery(query)
      }
      let podData = store.getState().CreatePODReducer.podRightSideData;
      let podRightData = cloneDeep(podData)
      podRightData.query = query;
      dispatch(setPodRightSideData(podRightData));
    };

    useEffect(() => {
      // if (Object.keys(virtualTablesDataMap[activePodTabDataKey]).length) {
      if (Object.keys(virtualTablesDataMap[1]).length) {
        createSqlEditorDetailsHandler();
        generateQueryHandler();
      }
    }, [virtualTablesDataMap]);

    console.log(query)

    return (
      <div className="create-pod-editor h-100">
        {
          showSqlEditor
            ? <SqlEditor
              width={editorWidth}
              readOnly={readOnly}
              height={editorHeight}
              QueryValue={query}
              ExecuteQueryClick={fetchQueryResults}
              setQueryvalue={onQueryChangeHandler}
              sqlTableDetails={sqlTableDetails}
              wrapEnabled={true}
            />
            : <>
              <div className="alert alert-info border-0 mb-1 px-3 py-2 rounded-0">
                Note: Click on "Results" tab to navigate back to editor.
              </div>
              <div className="border shadow-sm h-100" style={{ overflowY: 'auto' }}>
                <SqlEditor
                  width={editorWidth}
                  readOnly={true}
                  height={editorHeight}
                  QueryValue={query}
                  ExecuteQueryClick={fetchQueryResults}
                  setQueryvalue={onQueryChangeHandler}
                  sqlTableDetails={sqlTableDetails}
                  wrapEnabled={true}
                />
              </div>
              {/* <div className="alert alert-light border shadow-sm h-100" style={{ overflowY: 'auto' }}>
                <code className="color-primary">{query}</code>
              </div> */}
            </>
        }

      </div>
    );
  }
);

CreatePodEditor.defaultProps = {
  readOnly: false,
  editorWidth: "100%",
  editorHeight: 250,
};

export default CreatePodEditor;
