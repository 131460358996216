import React from 'react'
import './tutorialHelpButton.scss'
import { ICON_ARROW_LEFT, ICON_CLOUD_FOLDER, ICON_CLOUD_UPARROW, ICON_CONFIRM, ICON_CREATE_POD, ICON_DASHBOARD, ICON_DATABASE, ICON_DISCOVER, ICON_FLOWS, ICON_FOLDER, ICON_PLAY, ICON_SEARCH } from '../newIconSource'
import { List, Tooltip } from 'antd'
import CommonModal from '../CommonModal/CommonModal'
import { useDispatch, useSelector } from 'react-redux'
import { setNavigatorTutorialStart, setTutorialStart } from '../../../store/modules/common/tutorialRedux'
import { useLocation, useNavigate } from 'react-router'
import { Literals } from '../../modules/common/literals'
import { isArray } from 'lodash'
import logo_image from '../../../assets/images/new-logo-2.png'
import { setPulseTutorialStart } from '../../../store/modules/common/tutorialRedux'

const objectKeys = {
    navigator: {
        parent: 'navigator',
        landing_page : 'landing_page',
        create_pod   : 'create_pod',
        file_system  : 'file_system',
        file_upload  : 'file_upload',
        pod_of_pod   : 'pod_of_pod',
    },
    pulse: {
        parent: 'pulse',
        dashboard: 'dashboard',
    }
}

const colorMap = {
    pulse: '#C60C4F',
    navigator: '#2c72a7',
    lab: '#7a28ff'
}

const TutorialHelpButton = ({
    openModal    = false,
    setOpenModal = () => {}
}) => {

    const [open, setOpen]                   = React.useState(openModal)
    const [showNavigator, setShowNavigator] = React.useState(false)
    const [showPulse, setShowPulse]         = React.useState(false)
    const [showLab, setShowLab]             = React.useState(false)

    const dispatch = useDispatch()
    const location = useLocation()
    const history  = useNavigate()
    
    const tutorialRedux        = useSelector(state => state?.CommonComponent?.TutorialRedux)
    const isNavigatorTutorial  = tutorialRedux?.navigator?.landing_page
    const isCreatePodTutorial  = tutorialRedux?.navigator?.create_pod
    const isFileSystemTutorial = tutorialRedux?.navigator?.file_system
    const isFileUploadTutorial = tutorialRedux?.navigator?.file_upload
    const isPoPTutorial        = tutorialRedux?.navigator?.pod_of_pod

    const isPulseTutorial        = tutorialRedux?.pulse?.dashboard

    React.useEffect(() => {
        setOpen(openModal)
    }, [openModal])

    React.useEffect(() => {
        if(!open) setOpenModal(false)
    }, [open])

    const navigatorData = React.useMemo(() => [
        {
            title        : 'Clarista Navigator',
            description  : 'Create, Search, Filter and eye on all available Pods',
            parent_key   : objectKeys.navigator.parent,
            tutorial_key : objectKeys.navigator.landing_page,
            is_active    : isNavigatorTutorial,
            callback     : () => {
                resetOtherTutorials(objectKeys.navigator.parent, objectKeys.navigator.landing_page)
            },
            icon: <ICON_SEARCH height='25' width='25' color={colorMap.navigator}/>
        },
        {
            title        : 'Create Pod From Database',
            description  : 'Create Pod from schema based database',
            parent_key   : objectKeys.navigator.parent,
            tutorial_key : objectKeys.navigator.create_pod,
            is_active    : isCreatePodTutorial && (!isFileSystemTutorial && !isPoPTutorial && !isFileUploadTutorial),
            callback     : () => {
                resetOtherTutorials(objectKeys.navigator.parent, objectKeys.navigator.create_pod)
            },
            icon: <ICON_DATABASE height='25' width='25' color={colorMap.navigator}/>
        },
        {
            title        : 'Create Pod From Data Lake (AWS S3 / AZURE BLOB STORAGE)',
            description  : 'Create Pod from folder present on your cloud(s3, azure)',
            parent_key   : objectKeys.navigator.parent,
            tutorial_key : objectKeys.navigator.create_pod,
            is_active    : isFileSystemTutorial ?? false,
            callback     : () => {
                const obj = objectKeys.navigator

                tutorialRedux[obj.parent][obj.file_system]  = isFileSystemTutorial ? false : true
                tutorialRedux[obj.parent][obj.create_pod]   = true
                tutorialRedux[obj.parent][obj.file_upload]  = false
                tutorialRedux[obj.parent][obj.landing_page] = false
                tutorialRedux[obj.parent][obj.pod_of_pod]   = false
                dispatch(setTutorialStart(tutorialRedux))
                
                // dispatch(setNavigatorTutorialStart('file_system', isFileSystemTutorial ? false : true))
            },
            icon: <ICON_CLOUD_FOLDER height='25' width='25' color={colorMap.navigator}/>
        },
        {
            title        : 'Create Pod From POD of PODs',
            description  : 'Create Pod from existing Pods',
            parent_key   : objectKeys.navigator.parent,
            tutorial_key : objectKeys.navigator.create_pod,
            is_active    : isPoPTutorial ?? false,
            callback     : () => {
                const obj = objectKeys.navigator

                tutorialRedux[obj.parent][obj.file_system]  = false
                tutorialRedux[obj.parent][obj.create_pod]   = true
                tutorialRedux[obj.parent][obj.file_upload]  = false
                tutorialRedux[obj.parent][obj.landing_page] = false
                tutorialRedux[obj.parent][obj.pod_of_pod]   = isPoPTutorial ? false : true
                dispatch(setTutorialStart(tutorialRedux))

                // dispatch(setNavigatorTutorialStart('pod_of_pod', isPoPTutorial ? false : true))
            },
            icon: <img src={logo_image} style={{width: 25}} className='img-fluid' alt='pod-of-pod' />
        },
        {
            title        : 'Create Pod From File Upload',
            description  : 'some description that will be displayed in list',
            parent_key   : objectKeys.navigator.parent,
            tutorial_key : objectKeys.navigator.create_pod,
            is_active    : isFileUploadTutorial ?? false,
            callback     : () => {
                const obj = objectKeys.navigator

                tutorialRedux[obj.parent][obj.file_system]  = false
                tutorialRedux[obj.parent][obj.create_pod]   = true
                tutorialRedux[obj.parent][obj.file_upload]  = isFileUploadTutorial ? false : true
                tutorialRedux[obj.parent][obj.landing_page] = false
                tutorialRedux[obj.parent][obj.pod_of_pod]   = false
                dispatch(setTutorialStart(tutorialRedux))

                // dispatch(setNavigatorTutorialStart('file_upload', isFileUploadTutorial ? false : true))
            },
            icon: <ICON_CLOUD_UPARROW height='25' width='25' color={colorMap.navigator}/>
        }
    ], [
        isNavigatorTutorial,
        isCreatePodTutorial,
        isFileSystemTutorial,
        isPoPTutorial,
        isFileUploadTutorial
    ])

    const pulseData = React.useMemo(() => [
        {
            title        : 'Dashboard',
            description  : 'Create, Search, Filter and eye on all available Dashboards',
            parent_key   : objectKeys.pulse.parent,
            tutorial_key : objectKeys.pulse.dashboard,
            is_active    : isPulseTutorial,
            callback     : () => {
                dispatch(setPulseTutorialStart('dashboard', true))
            },
            icon: <ICON_SEARCH height='25' width='25' color={colorMap.pulse}/>
        },
    ], [isPulseTutorial])

    const resetOtherTutorials = (parent_key = '', prevent_key = '' ) => {
        const stopKeys = Object.keys(tutorialRedux[parent_key])
        stopKeys.forEach(k => {
            tutorialRedux[parent_key][k] = k === prevent_key
        })
        dispatch(setTutorialStart(tutorialRedux))
    }

    const handleStart = (item) => {
        // dispatch(setNavigatorTutorialStart(item?.tutorial_key, true))
        if(item.parent_key === objectKeys.navigator.parent) {
            const invalidURL = location?.pathname !== Literals.links.CLARISTA_NAVIGATOR
            if(invalidURL)
                history(Literals.links.CLARISTA_NAVIGATOR)
        }
        else if(item.parent_key === objectKeys.pulse.parent) {
            const invalidURL = location?.pathname !== Literals.links.CLARISTA_PULSE
            if(invalidURL)
                history(Literals.links.CLARISTA_PULSE)
        }
        if(item?.callback) item?.callback()
        setOpen(false)
    }

    return (
        <>
            {/* <Tooltip title={'Tutorial Help'} placement='right'>
                <div className='tutor-help-btn' onClick={() => setOpen(true)}>
                    <ICON_CONFIRM height='20' width='20' color='#fff' />
                </div>
            </Tooltip> */}
            <CommonModal
                open={open}
                setOpen={setOpen}
                header='Available Tutorials'
                footer=''
                body={
                    <>
                        <div className='tutor-li-wrap position-relative'>
                            <div 
                                onClick={() => setShowNavigator(!showNavigator)} 
                                className='tutor-heading cursor-pointer navigator d-flex align-items-center'>
                                <div>
                                    <ICON_DISCOVER color={colorMap.navigator} /> 
                                </div>
                                <div className='ml-3'>
                                    <span>Clarista Navigator</span>
                                    <p className='mb-0 text-black-50 h6'>Pod and Business Glossary</p>
                                </div>
                                <span className={`ml-auto p-3 ${showNavigator ? 'bx-rotate-90' : 'bx-rotate-270'}`}
                                    style={{transition: '.3s'}}
                                >
                                    <ICON_ARROW_LEFT width='18' height='18' color={colorMap.navigator} />
                                </span>
                            </div>
                            <List
                                className={`tutor-collapse ${showNavigator ? 'show' : 'fade'}`}
                                itemLayout="horizontal"
                                dataSource={navigatorData}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            // avatar={<ICON_CONFIRM/>}
                                            avatar={item?.icon}
                                            title={item.title}
                                            description={item?.description}
                                        />
                                        <button 
                                            className='custom-btn custom-btn-outline btn-with-text mr-2'
                                            onClick={() => {
                                                handleStart(item)
                                            }}
                                        >
                                            <ICON_PLAY/> Start
                                        </button>
                                    </List.Item>
                                )}
                            />
                            <div
                                onClick={() => setShowLab(!showLab)}  
                                className='tutor-heading cursor-pointer lab d-flex align-items-center'>
                                <div>
                                    <ICON_FLOWS color={colorMap.lab} /> 
                                </div>
                                <div className='ml-3'>
                                    <span>Clarista Lab</span>
                                    <p className='mb-0 text-black-50 h6'>Flows, Alerts and Scheduler</p>
                                </div>
                                <span className={`ml-auto p-3 ${showLab ? 'bx-rotate-90' : 'bx-rotate-270'}`}
                                    style={{transition: '.3s'}}
                                >
                                    <ICON_ARROW_LEFT width='18' height='18' color={colorMap.lab} />
                                </span>
                            </div>
                            <List
                                className={`tutor-collapse ${showLab ? 'show' : 'fade'}`}
                                itemLayout="horizontal"
                                dataSource={[]}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<ICON_CONFIRM/>}
                                            title={item.title}
                                            description={item?.description}
                                        />
                                        <button 
                                            className='custom-btn custom-btn-outline btn-with-text mr-2'
                                            onClick={() => {
                                                handleStart(item)
                                            }}
                                        >
                                            <ICON_PLAY/> Start
                                        </button>
                                    </List.Item>
                                )}
                            />
                            <div 
                                onClick={() => setShowPulse(!showPulse)}  
                                className='tutor-heading cursor-pointer pulse d-flex align-items-center'>
                                <div>
                                    <ICON_DASHBOARD color={colorMap.pulse} /> 
                                </div>
                                <div className='ml-3'>
                                    <span>Clarista Pulse</span>
                                    <p className='mb-0 text-black-50 h6'>Dashboard</p>
                                </div>
                                <span className={`ml-auto p-3 ${showPulse ? 'bx-rotate-90' : 'bx-rotate-270'}`}
                                    style={{transition: '.3s'}}
                                >
                                    <ICON_ARROW_LEFT width='18' height='18' color={colorMap.pulse} />
                                </span>
                            </div>
                            <List
                                className={`tutor-collapse ${showPulse ? 'show' : 'fade'}`}
                                itemLayout="horizontal"
                                dataSource={pulseData}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={item?.icon}
                                            title={item.title}
                                            description={item?.description}
                                        />
                                        <button 
                                            className='custom-btn custom-btn-outline btn-with-text mr-2'
                                            onClick={() => {
                                                handleStart(item)
                                            }}
                                        >
                                            <ICON_PLAY/> Start
                                        </button>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default TutorialHelpButton