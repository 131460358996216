export const SET_USERGROUPS_LEADER_LIST = 'SET_USERGROUPS_LEADER_LIST';
export const SET_USERGROUPS_REMOVE_LEADER = 'SET_USERGROUPS_REMOVE_LEADER';
export const SET_USERGROUPS_USER_LIST = 'SET_USERGROUPS_USER_LIST';
export const SET_USERGROUP_NAME = 'SET_USERGROUP_NAME';
export const SET_USERGROUP_DESCRIPTION = 'SET_USERGROUP_DESCRIPTION';
export const SAVE_USERGROUP = 'SAVE_USERGROUP';
export const SET_USER_GROUP_ID = 'SET_USER_GROUP_ID';
export const SET_USERS_EXISTED_IN_GROUP = "SET_USERS_EXISTED_IN_GROUP";
export const SET_USER_GROUP_PAGE_DEFAULT = "SET_USER_GROUP_PAGE_DEFAULT";
export const SET_ACTIVE_UM_TAB_NAME = "SET_ACTIVE_UM_TAB_NAME";



