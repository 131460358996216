import React, { useState, useEffect } from 'react';
import { getRelativeTime,NoDataComponent,ErrorComponent, permissions } from '../../../common/helperFunctions';
import DeleteDomainModal from './DeleteDomainModal';
import CreateDataDomainModal from './CreateDataDomainModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from "react-redux";
import { getDataDomainList, setDataDomainName, setDataDomainDescription, setDataDomainId } from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions';
import LoadingComponent from '../../../common/loadingComponent';
import NoSearchResultFound from '../../../common/NoSearchResultFound';
import UserManagementCard from '../../common/UserManagementCard';
import { DATADOMAIN } from "../../../../Common/iconSource";
import { Literals } from '../../../common/literals';
import { setLoadingStatus } from '../../../../../store/modules/common/loadingActions';

const DataDomainListView = ({ searchData ,setLayout}) => {
    const [domainList, setDomainList] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openDomainModal, setOpenDomainModal] = useState(false);
    const [empty,setEmpty]=useState(false);
    const [errMsg,setErrMsg]=useState("");
    const history = useNavigate();
    const store = useStore();
    const dispatch = useDispatch();
    const domainData = [];
    const [deleteValue, setDeleteValue] = useState("");
    const loadingStatus = useSelector(state => state.LoadingReducer.loadingStatus);

    useEffect(() => {
        getDataDomainListData();
    }, [])

    useEffect(() => {
        dispatch(setDataDomainName(""));
        dispatch(setDataDomainDescription(""));
        dispatch(setDataDomainId(""));
    }, [])

    function clickEdit(dataDomain){
        history(Literals.links.USER_MANAGEMENT_EDIT_DATA_DOMAIN + dataDomain.key,{state:{tabName:Literals.DATA_DOMAIN}})
        window.history.replaceState({state:{tabName:Literals.DATA_DOMAIN}},'')

    };

    const clickDelete = async (value) =>{
        
        setDeleteValue(value);
        setOpenDeleteModal(true)
    };

    const redirectTo = async (key) =>{
        history(Literals.links.USER_MANAGEMENT_DATA_DOMAIN + key,{state:{tabName:Literals.DATA_DOMAIN}})
        window.history.replaceState({state:{tabName:Literals.DATA_DOMAIN}},'')

    };
  
    const getDataDomainListData = async () => {
        let response;
        try {
            response = await store.dispatch(getDataDomainList());
            if (response.status === "success") {
                // setLayout(response.data[0]?.view_type??"grid");
                if(response.data.length==0){
                    setEmpty(true);
                }
                setDomainList(response.data);
            }
            else{
                setErrMsg(response.message);
                setLoadingStatus(false);
            }
        } catch (error) { 
            setErrMsg(error.message);
            setLoadingStatus(false);
        }
    };




    const filterData = domainList && domainList.filter((data) => {
        return (data?.data_domain_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
            data?.data_domain_description?.toLowerCase().includes(searchData?.toLowerCase()) ||
            // getRelativeTime(data?.created_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
            data?.created_by_user?.username?.toLowerCase().includes(searchData?.toLowerCase()) ||
            getRelativeTime(data?.updated_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
            data?.updated_by_user?.username?.toLowerCase().includes(searchData?.toLowerCase()))
    })

    filterData && filterData.map((value) =>
        domainData.push(
            {
                key: value?.data_domain_id,
                dataDomain: value?.data_domain_name.toUpperCase(),
                description: value?.data_domain_description,
                // createdOn: value?.created_on,
                createdBy: value?.created_by_user?.username,
                updated_on: value?.updated_on,
                updatedBy: value?.updated_by_user?.username,
                userGroups: value?.group,
                users: value?.user,
                data: value,
                isEditor: !!value?.permission?.find(v => v === permissions.domain.editor),
                isOwner: !!value?.permission?.find(v => v === permissions.domain.owner)
            }
        ))

  function getCardView(){
      return(
        domainData.map((ele,i) =>
                <UserManagementCard key={i} 
                domainDetails={ele} 
                searchData={searchData}
                selectedDomainList={domainData} 
                clickEdit={clickEdit} 
                clickDelete={clickDelete} 
                icon = {<DATADOMAIN color="#2c83c4"/>}
                tabName="DataDomain"
                redirectTo = {redirectTo}/>
            )
      )
  }
    
    return (
        <div>
            {loadingStatus ? <LoadingComponent />:errMsg.length>0?<ErrorComponent msg={errMsg}/>: empty?<NoDataComponent logo={<DATADOMAIN width="60" height="60" color="black"/>} message="
            Click On (+) To Create Data Domain"/>:searchData?.length > 0 && filterData?.length === 0 ?
            <NoSearchResultFound /> :
            
            <div className="data-domain-gridview usr-manage-usr-home new-box-style"> {getCardView()} </div>}
            <DeleteDomainModal openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} deleteValue={deleteValue} getDataDomainListData={getDataDomainListData} />        
            <CreateDataDomainModal heading={'Edit Data domain'} openDomainModal={openDomainModal} setOpenDomainModal={setOpenDomainModal} edit={true} />
        </div>
    )

}

export default DataDomainListView;