import React from 'react'
import './landingDomainTabs.scss'
import { Tabs, Tooltip } from 'antd'
import { ICON_CATALOG_DOMAIN, ICON_CLOSE, ICON_ERROR, ICON_SEARCH } from '../../newIconSource';
import { useDispatch } from 'react-redux';
import { getDataDomainList, setDataDomainList, setUserSelectedDomain } from '../../../../store/actions';
import { NoDataComponent, showOneLineError } from '../../../modules/common/helperFunctions';
import { DOMAIN_LARGE } from '../../iconSource';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import SearchInput from '../../search/Search';
import { sortBy } from 'lodash';

const LandingDomainTabs = ({
    activeDomain = '',
    setActiveDomain = () => { },
    isDomainFailed = false,
    setDomainFailed = () => { },
    activeTabKey = '',
    isDataLoading = false
}) => {

    const dispatch = useDispatch()

    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [searchData, setSearchData] = React.useState('')
    const [openSearch, setOpenSearch] = React.useState(false)
    const [searchAutoFocus, setSearchAutoFocus] = React.useState(false)

    React.useEffect(() => {
        /**For Handling ALL Option in Business Glossary**/
        if (activeTabKey !== 'business') {
            if (activeDomain === '' || activeDomain === null || activeDomain === 0 || activeDomain === isNaN) {
                setActiveDomain(data?.[0]?.data_domain_id)
            }
        }
    }, [activeTabKey, activeDomain, data])

    React.useEffect(() => {
        setLoading(true)
        dispatch(getDataDomainList(false, false)).then(
            res => {
                if (res?.data?.length) {
                    setData([...res?.data])
                }
                else {
                    if (activeTabKey === 'business') {
                        setActiveDomain(0)
                    }
                }
                if (activeDomain === null || activeDomain === 0 || activeDomain === isNaN) {
                    setActiveDomain(res?.data?.[0]?.data_domain_id)
                }
                dispatch(setDataDomainList(res.data))
                setLoading(false)
            }
        )
            .catch((err) => {
                setLoading(false)
                setError(err?.message)
            })
    }, [])

    React.useEffect(() => {
        if (isDomainFailed && data && data?.length) {
            setActiveDomain(data?.[0]?.data_domain_id)
        }
    }, [isDomainFailed, data])

    const removeUnderscores = (text) => {
        return text?.replaceAll('_', ' ')
    }

    const items = React.useMemo(() => {
        let list = data?.map((item) => {
            return {
                key: item.data_domain_id,
                label: <span className='text-uppercase'> <ICON_CATALOG_DOMAIN /> {removeUnderscores(item.data_domain_name)}</span>,
                value: removeUnderscores(item.data_domain_name),
                disabled: isDataLoading
            }
        })

        /**
         * For Sort By Active Domain
         */
        /* if(![undefined, null, 0, '']?.includes(activeDomain)) {
            list = sortBy(list, [d => d?.key?.toString() !== activeDomain?.toString()])
        } */
        
        if (activeTabKey === 'business') {
            list = [
                {
                    key: 0,
                    label: <span className='text-uppercase'> <ICON_CATALOG_DOMAIN /> ALL</span>,
                    value: 'all',
                    disabled: isDataLoading
                },
                ...list
            ]
        }

        list = list.filter(t => t?.value?.toLowerCase()?.includes(searchData?.toLowerCase()))
        if (searchData?.trim() !== '' && list?.length === 0) {
            list = [
                {
                    key: -1,
                    label: <span className='text-uppercase'> <ICON_ERROR color='#d6d6d6' /> No Domains Found</span>,
                    value: 'not found',
                    disabled: true
                }
            ]
        }

        return list
    }, [data, searchData, activeTabKey, isDataLoading])

    const onChange = (key) => {

        if (isDataLoading) {
            emitToastNotification('info', 'Please wait data is loading')
            return
        }

        key = parseInt(key)

        setActiveDomain(key)

        setDomainFailed(false)

        dispatch(setUserSelectedDomain({ data_domain_id: key === 0 ? null : key }, false))
            .catch(err => console.error(err?.message))
    };

    return (
        <div>
            {
                loading
                    ? <div className="align-items-center d-flex justify-content-start py-1 text-center loading-dm">
                        <i className="bx bx-loader-alt bx-spin mr-1"></i>{" "}
                        <label className="mb-0 label">Loading Domains...</label>
                    </div>
                    : error ? showOneLineError()
                        :
                        items.length > 0
                            ? <div className={`dm-search-li-wrap ${openSearch ? 'active' : ''}`}>
                                {
                                    openSearch
                                        ? <span className='position-relative'>
                                            <SearchInput
                                                searchData={searchData}
                                                setSearchData={setSearchData}
                                                autoFocus={searchAutoFocus}
                                                placeholder='Search domains...'
                                            />
                                            <Tooltip placement='top' title={searchData?.trim() !== '' ? 'Clear' : 'Close'}>
                                                <span className='dm-search-close'
                                                    onClick={() => {
                                                        if (searchData?.trim() === '') {
                                                            setOpenSearch(false)
                                                            setSearchAutoFocus(false)
                                                        }
                                                        else setSearchData('')
                                                    }}
                                                >
                                                    <ICON_CLOSE color='#333333' />
                                                </span>
                                            </Tooltip>
                                        </span>
                                        : <Tooltip placement='top' title={'Search Domains'}>
                                            <span className='dm-toggle-search'
                                                onClick={() => {
                                                    setOpenSearch(true)
                                                    setSearchAutoFocus(true)
                                                }}
                                            >
                                                <ICON_SEARCH />
                                            </span>
                                        </Tooltip>
                                }
                                <Tabs
                                    id='landingDomainTab'
                                    className='lpg-domains-tab'
                                    activeKey={activeDomain}
                                    items={items}
                                    onChange={onChange}
                                />
                            </div>
                            : <div className='d-flex justify-content-end no-domain-info align-items-center w-100'>
                                <NoDataComponent logo={<DOMAIN_LARGE
                                    width="60" height="60" color="black" />}
                                    message="No Domain List Found" />
                            </div>

            }
        </div>
    )
}

export default LandingDomainTabs