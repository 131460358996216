import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import {
  getUserGroupList, setUserList
} from "../../../../../store/modules/UserManagement/users/usersActions";
import Buttons from "../../../../Common/button/Buttons";
import { NoDataComponent, getDeleteMessage} from '../../../common/helperFunctions';
import LoadingComponent from "../../../common/loadingComponent";
import { resendEmail } from "../../../../../store/modules/UserManagement/users/usersActions";
import { CRON, DELETEICON, EDIT, USERACTIVE, USERINACTIVE ,USER, RESEND_EMAIL} from "../../../../Common/iconSource";
import { LandingSimpleTable } from "../../../../Common/landingSimpleTable/LandingSimpleTable";
import ActionPopUp from "../../../common/actionPopUp";
import { Literals } from "../../../common/literals";
import NoSearchResultFound from "../../../common/NoSearchResultFound";
import { ICON_SUPERUSER } from "../../../../Common/newIconSource";
import DeleteModal from "../../../../Common/deleteModal/DeleteModal";

function UserPageListView({ searchData, usersList, getUsersList, onEditUser, isEmpty = false }) {
  const [openDomainModalUser, setOpenDomainModalUser] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
  const [empty,setEmpty]=useState(false);

  const currentUserRef = React.useRef({current: {}})

  const history = useNavigate();
  const loadingStatus = useSelector(
    (state) => state.LoadingReducer.loadingStatus
  );

  const CurrentUserDetails = useSelector(state => state.UserReducer.user.userDetails)

  const store = useStore();
  const dispatch = useDispatch();
  useEffect(() => {
    if (usersList.length===0 && isEmpty)
      setEmpty(true);
    else
      setEmpty(false);
    dispatch(setUserList(usersList));
  }, [usersList]);
  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };
  const deleteUser = async (id) => {
    let response;
    try {
      response = await store.dispatch(
        getUserGroupList({
          method: "DELETE",
          endPoint: "profile/" + id + "/",
          
        })
      );
      if (response.status === "success") {
        emitToastNotification('success', response?.message);
        getUsersList();
        setOpenDeletePopup(false);
      }
    } catch (error) { }
  };
  const openDeleteDialog = (record) => {

    currentUserRef.current = record

    // setDeletePopUpContent({
    //   modalClass: "delete-popup",
    //   heading: "Delete",
    //   message: Literals.POP_UP_MESSAGE_DELETE,
    //   headingIcon: "bx bx-error",
    //   button: [
    //     {
    //       name: "Delete",
    //       action: () => deleteUser(record?.id),
    //     },
    //     {
    //       name: "Cancel",
    //       action: closeDeletePopUp,
    //     },
    //   ],
    //   elements: [],
    //   closeActionPopup: closeDeletePopUp,
    // });
    setOpenDeletePopup(true);
  };

  const onRowClick = (record) => {

    if(record.key !== CurrentUserDetails?.id && !CurrentUserDetails?.is_superuser) {
      emitToastNotification('info', "You do not have permission")
      return
    }

    history(Literals.links.USER_MANAGEMENT_USER + record.key, {state: { tabName: Literals.USERS } });
    window.history.replaceState({state:{tabName:Literals.USERS}},'')

  };

  const coulumnData = [
    {
      name: Literals.FIRSTNAME,
      dataIndex: "firstName",
      key: "firstName",
      sorter: {
        compare: (a, b) =>
          a.firstName?.props?.children[1]?.props?.title !== undefined
            ? a.firstName?.props?.children[1]?.props?.title?.localeCompare(
              b.firstName?.props?.children[1]?.props?.title
            )
            : null,
      },
    },
    {
      name: Literals.LASTNAME,
      dataIndex: "lastName",
      key: "lastName",
      sorter: {
        compare: (a, b) =>
          a.lastName?.props?.title !== undefined
            ? a.lastName?.props?.title?.localeCompare(b.lastName?.props?.title)
            : null,
      },
    },
    {
      name: Literals.EMAIL,
      dataIndex: "emailAddress",
      key: "emailAddress",
      className: "single-line-ellipsis",
    },
    {
      name: Literals.DESIGNATION,
      dataIndex: "designation",
      key: "designation",
      sorter: {
        compare: (a, b) =>
          a.designation?.props?.title !== undefined
            ? a.designation?.props?.title?.localeCompare(
              b.designation?.props?.title
            )
            : null,
      },
    },
    {
      name: Literals.STATUS,
      dataIndex: "status",
      key: "status",
      sortable:false,
      sorter: {
        compare: (a, b) =>
          a.status?.props?.title !== undefined
            ? a.status?.props?.title?.localeCompare(b.status?.props?.title)
            : null,
      },
    },
    {
      name: Literals.SUPER_USER,
      dataIndex: "superuser",
      sortable:false,

      key: "superuser",
      sorter: {
        compare: (a, b) =>
          a?.superuser?.props?.children?.props?.children !== undefined
            ? a?.superuser?.props?.children?.props?.children?.localeCompare(
              b?.superuser?.props?.children?.props?.children
            )
            : null,
      },
    },
    {
      name: <div className="text-center">Action</div>,
      key: "action",
      sortable:false,
      dataIndex: 'action'
    },
  ];
  const filterData =
    usersList &&
    usersList.filter((data) => {
      return (
        data?.first_name
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.last_name
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.email
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.designation
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.status
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.createdOn
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.createdBy
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase())
      );
    });
    const resendEmailFunc=(id)=>{

      dispatch(resendEmail({id:id}))
    }
  const tableData = [];
  filterData &&
    filterData.map((value) =>
      tableData.push({
        completeValue: {
          firstName: value.first_name,
          lastName: value.last_name,
          designation: value.designation,
          emailAddress: value.email,
          superuser: value.is_superuser ? "Yes" : "No",

        },
        key: value?.id,
      
        firstName: (
          
            <Tooltip placement="topLeft" title={value?.first_name}>
              <div className="pr-1 d-flex align-items-center">< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.first_name} />  <span className="pl-2">{value?.is_superuser ? <ICON_SUPERUSER height="18" width="18" /> : null}</span></div>
             
            </Tooltip>
         
        ),
        lastName: (
          <Tooltip placement="topLeft" title={value?.last_name}>
            <span>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.last_name} /></span>
          </Tooltip>
        ),
        emailAddress: (
          <Tooltip placement="topLeft" title={value?.email}>
            <span>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.email} /></span>
          </Tooltip>
        ),
        designation: (
          <Tooltip placement="topLeft" title={value?.designation}>
            <span>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.designation} /></span>
          </Tooltip>
        ),
        status: (
          <Tooltip
            placement="topLeft"
            title={value?.is_verified === true ? "Active" : "Inactive"}
          >
            <span
              className={
                value?.is_verified === true
                  ? "status-active"
                  : "status-inactive"
              }
            >
              {value?.is_verified === true ? <USERACTIVE /> : <USERINACTIVE />}
            </span>
          </Tooltip>
        ),
        superuser: (
          <Tooltip placement="topLeft" title={value?.is_superuser}>
            <span>{value?.is_superuser ? Literals.YES : Literals.NO}</span>
          </Tooltip>
        ),
        action:  
          <div className="d-flex justify-content-center align-items-center h-100">
            {/* <Buttons props={{ buttonText: '', tooltip: 'Resend Email', buttonClassName: "custom-btn-outline custom-btn resend-email", buttonEvent: (e) => { e.stopPropagation(); e.preventDefault();
                resendEmailFunc(value?.id);}, ImgSrc: () => <RESEND_EMAIL />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} /> */}
       
            <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline ml-1 mr-1 custom-btn btn-with-icon",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if(CurrentUserDetails?.id !== value?.id && !CurrentUserDetails?.is_superuser)
                  {
                    return
                  }
                  onEditUser( {
                    firstName: value.first_name,
                    lastName: value.last_name,
                    designation: value.designation,
                    emailAddress: value.email,
                    superuser: value.is_superuser ? "Yes" : "No",
                    key:value?.id
                  });
                },
                tooltip: "Edit",
                ImgSrc: () => <EDIT />,
                isDisable: CurrentUserDetails?.id !== value?.id && !CurrentUserDetails?.is_superuser,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
            <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline ml-1 mr-1 custom-btn btn-with-icon error-red ",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if(CurrentUserDetails?.id === value?.id || !CurrentUserDetails?.is_superuser) return
                  openDeleteDialog(value);
                },
                tooltip: "Delete",
                ImgSrc: () => <DELETEICON />,
                isDisable: CurrentUserDetails?.id === value?.id || !CurrentUserDetails?.is_superuser,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          </div>
       
      })
    );
  let TableHeight;
  if (window.innerHeight >= 750 && window.innerHeight < 1000) {
    TableHeight = Math.round((window.innerHeight - 280))
  } else if (window.innerHeight >= 1000) {
    TableHeight = Math.round((window.innerHeight - 150))
  } else {
    TableHeight = Math.round(window.innerHeight - 100)
  }
  const getComparator = (sortColumn) => {
    switch (sortColumn) {

      case 'firstName':
        return (a, b) => a?.completeValue?.[sortColumn]?.localeCompare(b?.completeValue?.[sortColumn]) ?? null
      case 'lastName':
        return (a, b) => a[sortColumn]?.props?.title !== undefined ? a[sortColumn]?.props?.title?.localeCompare(b[sortColumn]?.props?.title) : null
      case 'designation':
        return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
      case 'emailAddress':
        return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }
  return (
    <div className="user-management-table user-table">
      {  loadingStatus ? <LoadingComponent /> : empty?<NoDataComponent logo={<USER width="60" height="60" color="black"/>} message="
      Click On (+) To Create User "/>:
      searchData?.length > 0 && filterData?.length === 0 ? (
        <NoSearchResultFound />
      ) :   (        
        <LandingSimpleTable
          cols={coulumnData}
          rowsData={tableData}
          autoWidth={true}
          selectedRowIndex={undefined}
          onRowClick={(record, index) => { onRowClick(record, index) }}
          getComparator={getComparator}
          tableHeight={'calc(100vh - 90px)'}
          
        />
      )}

      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={getDeleteMessage({
          title: 'User'
        })}
        onConfirm={() => deleteUser(currentUserRef?.current?.id)}
      />

      {/* <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent}
      /> */}
    </div>
  );
}

export default UserPageListView;
