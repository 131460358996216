import { GET_ALERT_DATA, OPEN_FILTER } from "./alertLandingActionType";

const initialState = {
    alertData: [],
    openFilter: ''
}

const GetAlertDataReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALERT_DATA:
            return {
                ...state
            }
        case OPEN_FILTER:
            return {
                ...state,
                openFilter: payload
            }
        default:
            return state;
    }
}

export default GetAlertDataReducer

