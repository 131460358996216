import React from 'react'
import PodCreation from '../../dataCatalogue/podCreation/PodCreation'

const ImportFromDatabase = () => {
  return (
    <React.Fragment>
        <PodCreation isDataDictionary={true} />
    </React.Fragment>
  )
}

export default ImportFromDatabase