import React, { Fragment } from "react";
import DetailDescTile from "../../common/DetailDescTile";
import SmallerDetailsTile from "../../common/SmallerDetailsTile";

const DomainDetailsUpperSection = ({ domainDetails }) => {
  const { domainDetail, userGroup, users, createdBy, updatedOn, isUserGroup } =
    domainDetails;

    function renderUserGroupUpperSection(){
        return (
            <div className="data-domain-details-upper-section usr-manage-usr-grp">
              <DetailDescTile domainDetails={domainDetail} />
              <div className="data-domain-details-insights">
                <div className="data-domain-details-header">
                  <span className="domain-details-title">
                    {isUserGroup ? "User Group" : "Data Domain"} Details
                  </span>
                </div>
                <div className="data-domain-details-content">
                  <SmallerDetailsTile object={userGroup} isUserGroup={isUserGroup} />
                  <SmallerDetailsTile object={users}  isUserGroup={isUserGroup} />
                  <SmallerDetailsTile object={createdBy}  isUserGroup={isUserGroup} />
                  <SmallerDetailsTile object={updatedOn}  isUserGroup={isUserGroup} />
                </div>
              </div>
            </div>
          )
    }

    function renderDataDomainUpperSection(){
        return (
          <div className="">
            <div className="data-domain-details-upper-section">
              <div className="data-domain-details-grid">
                  <div className="" style={{maxWidth: '50vw'}}>
                      <div className="upper-left">
                          <DetailDescTile domainDetails={domainDetail} />
                      </div>
                  </div>
                  <div className="">

                  <div className="data-domain-details-insights h-100">
                    <div className="data-domain-details-header">
                      <span className="domain-details-title">
                        {isUserGroup ? "User Group" : "Data Domain"} Details
                      </span>
                    </div>
                    <div className="data-domain-details-content usr-manage-usr-data-domain">
                      <SmallerDetailsTile object={userGroup} isUserGroup={isUserGroup} />
                      <SmallerDetailsTile object={users}  isUserGroup={isUserGroup} />
                      <SmallerDetailsTile object={createdBy}  isUserGroup={isUserGroup} />
                      <SmallerDetailsTile object={updatedOn}  isUserGroup={isUserGroup} />
                    </div>
                  </div>

                      {/* <div className="upper-right">
                            <div className="upper-right-header border-bottom mb-0">
                              <span className="domain-details-title font-w-600">
                                {isUserGroup ? 'User Group':'Data Domain'} Details
                              </span>
                            </div>
                                <div className="row">
                                  <div className="col-3">
                                      <SmallerDetailsTile object={userGroup}  isUserGroup={isUserGroup} />
                                  </div>
                                  <div className="col-3">
                                      <SmallerDetailsTile object={users}  isUserGroup={isUserGroup} />
                                  </div>
                                  <div className="col-3">
                                      <SmallerDetailsTile object={createdBy}  isUserGroup={isUserGroup} />
                                  </div>
                              
                                  <div className={isUserGroup ?"col-4" : "col-3"}>
                                      <SmallerDetailsTile object={createdOn}  isUserGroup={isUserGroup} />
                                  </div>
                            </div>
                          </div> */}
                  </div>
              </div>
            </div>
          </div>
            
        )
    }

  return (
    
    <Fragment>
        {isUserGroup ? renderUserGroupUpperSection() : renderDataDomainUpperSection() }
    </Fragment>
  );
};

export default DomainDetailsUpperSection;
