import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    Card, CardBody, CardHeader
} from 'reactstrap';
import CloseIcon from '../../../../../assets/images/icons/bx-x1.svg'
import './LandingPageTag.scss'
import LandingPageTagList from './LandingPageTagList';
import { createDomainTags, getDomainTags, saveTag, setTagList } from '../../../../../store/modules/dataCatalogue/landingPage/landingPageActions';
import { getTagList, openModel } from '../../../../../store/modules/dataCatalogue/landingPage/landingPageActions';
import { tags } from '../landingPageTagHelper'
import { Literals } from "../../../common/literals";
import { TAG, TICK, CLOSE, PLUS } from '../../../../Common/iconSource';
import Buttons from '../../../../Common/button/Buttons';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';
import { ICON_LOADING_CIRCLE } from '../../../../Common/newIconSource';
import _, { sortBy } from 'lodash';

const LandingPageTag = ({
    onClose = () => {},
    domainId
}) => {
    const dispatch = useDispatch();
    const tagData = useSelector(state => state.DataCatalogue?.LandingPage?.tagList);

    const [selectedTag, setSelectedTag] = useState({});
    const [tagName, setTagname] = useState('');
    const [tagNameExist, setTagNameExist] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [allTags, setAllTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    // const [availableTags, setAvailableTags] = useState([]);)

    useEffect(() => {
        setAllTags(sortBy([...tagData.map(item => ({...item, isEdit: false}))], [function(o) { return o?.name; }]))
    }, [tagData])

    React.useEffect(() => {
        fetchTags()
    }, [domainId])

    const fetchTags = () => {
        setLoading(true)
        dispatch(getDomainTags(domainId))
        .then(res => {
            const data = res?.data ?? []
            // setAvailableTags([...data])
            dispatch(setTagList(data));
            setLoading(false)
        })
        .catch(err => {
            console.error({err})
            setLoading(false)
        })
    }
    
    const setTag = (tag) => {
        setSelectedTag(tag);
        setErrorMessage('')
        setTagNameExist(false)
    }

    const setName = (event) => {
        setTagname(event.target.value?.toUpperCase())
        setTagNameExist(false);
    }
    const createTag = () => {
        let exist = false;

        if (selectedTag === undefined || selectedTag === null || selectedTag.id === undefined) {
            setErrorMessage('Select a color first.')
            exist = true;
        }
        if (!exist && tagName.trim() === '') {
            setErrorMessage('Enter tag name')
            exist = true;
        }

        if (exist) {
            setTagNameExist(true)
        } else {
            setCreating(true)
            let requestBody = {
                name: tagName,
                color: selectedTag.id,
                data_domain: domainId
            }
            dispatch(createDomainTags(requestBody)).then(
                (res) => {
                    setTagname('');
                    setSelectedTag({})

                    let prevData = tagData
                    prevData = [...tagData, res?.data]
                    dispatch(setTagList(prevData));
                    setCreating(false)
                    // dispatch(getTagList())
                }
            ).catch(
                () => {
                    setCreating(false)
                }
            );
        }

    }

    const getPendingTagColors = () => {
        let pendingColorList = [];
        for (let x in tags) {
            let color = tags[x].id;
            let exist = false;
            for (let y in tagData.data_record) {
                let savedTag = tagData.data_record[y][1];
                savedTag = savedTag.split('#')[0];
                if (color === savedTag) {
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                pendingColorList.push({...tags[x], color: tags[x].color+'95'})
            }
        }
        return pendingColorList;
    }

    return (
        <div className="landingPage-tag">
            <Card>
                <CardHeader>
                    <div className="landingPage-tag-title">
                        <TAG height='20' width='20' />
                        <span className="section-heading">{Literals.TAGS}</span>
                    </div>
                    {/* <div className="landingPage-tag-close" onClick={() => dispatch(openModel(''))}>
                            <img src={CloseIcon} alt="Close" />
                        </div> */}
                    <Buttons props={{ buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { dispatch(openModel('')); onClose() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
                </CardHeader>
                <CardBody>
                    <p className="subtitle m-0">{Literals.AVAILABLE_TAGS}</p>
                    <div className="tag-list py-0">
                        {
                            loading ? <ClaristaLoader height='200px'/> :
                            tagData?.length === 0 ? <span className='py-2 d-block'>No tag(s) available</span> :
                            allTags
                            ?.map((tag, i) =>
                                <LandingPageTagList 
                                    key={i} 
                                    tags={tags} 
                                    tagData={tag} 
                                    setAllTags={setAllTags} 
                                    unChangedTagData={tagData} 
                                    disableButtons={loading || creating}
                                    domainId={domainId}
                                />
                            )
                        }
                    </div>
                    {
                        loading ? ''
                        :
                        <>
                            <p className="subtitle m-0">{Literals.ADD_A_NEW_TAG}</p>

                            <div className="padding-2">
                                <p className="label mb-1">{Literals.SELECT_COLOR}</p>
                                <div className="landingPage-tag-selectColor-palette">
                                    {getPendingTagColors().length === 0 ? 'No color available' : getPendingTagColors().map((tag, i) =>
                                        <span key={i} className={`landing-tag`} onClick={() => setTag(tag)} ><i key={i} style={{ color: tag.color }} className={`bx bxs-circle  ${tag.id === selectedTag.id ? 'active' : ''}`} ></i></span>
                                    )}
                                </div>
                                <label className="landingPage-tag-label">
                                    {Literals.NAME} {' '}
                                </label>
                                <input 
                                    className="custom-input-field text-uppercase" 
                                    value={tagName?.toUpperCase()} 
                                    onChange={(event) => setName(event)} 
                                    type="text" 
                                    name="name" 
                                    disabled={loading || creating}
                                    />
                                {tagNameExist ? <div className='pt-1 tag-error text-danger ' style={{fontSize:"10px"}}>{errorMessage}</div> : null}
                            </div>
                        </>
                    }
                </CardBody>
                <div className="padding-2 d-flex justify-content-end">
                    <Buttons 
                        props={{ 
                            buttonId: 'tagLiBtn', 
                            buttonText: creating ? 'Creating...' : 'Add', 
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text", 
                            buttonEvent: () => { createTag() }, 
                            ImgSrc: () => creating ? <ICON_LOADING_CIRCLE/> : <PLUS />, 
                            isDisable: loading || creating, 
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT 
                            }} 
                    />
                </div>
            </Card>
        </div>
    )
}

export default LandingPageTag