import React, { useEffect, useRef, useState } from "react";
import SearchInput from "../../../../Common/search/Search";
import ColumnLevelTable from "../../../../Common/landingPageTable/LandingPageTable";
import { Literals } from "../../../common/literals";
import "./ColumnLevelMasking.scss";
import { Switch, Tooltip } from "antd";
import { getColumnDataType } from "../../../common/helperFunctions";
import Highlighter from "react-highlight-words";
import { useParams } from "react-router-dom";
import { getBusinessTermsOfPod } from "../../../../../store/modules/dataDictionary/action";
import { useStore } from "react-redux";
import { LandingSimpleTable } from "../../../../Common/landingSimpleTable/LandingSimpleTable";
import ClaristaLoader from "../../../../Common/claristaLoader/ClaristaLoader";

const ColumnLevelMasking = ({
  columnDetails,
  maskedColumns,
  setMaskedColumns,
  viewMode,
  isEditorPermission=false

}) => {
  console.log(isEditorPermission)
  const [searchData, setSearchData] = useState("");
  const [columnListData, setcolumnListData] = useState("");
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const { podId } = useParams();
  const store = useStore()
  const businesstermResp = useRef({})
  const currentMaskedCol = useRef([])

  useEffect(() => {
    if (podId) {
      let resp = []
      setLoading(true)
      store.dispatch(getBusinessTermsOfPod(podId)).then((response) => {
        setData(response)
        businesstermResp.current = response
        setLoading(false)
      }).catch(() => {
        setLoading(false)
        genarateData({})
      })

    }
  }, [columnDetails])
  useEffect(() => {

    // genarateData(businesstermResp.current)
    if (maskedColumns.length) {
      currentMaskedCol.current = maskedColumns
    }
  }, [searchData, maskedColumns, isEditorPermission])

  const genarateData = (resp) => {
    if (columnDetails.length) {
      let category_detail = '';
      let classification_detail = []

      const FILTER_COLS = ['column_name', 'column_description', 'column_source_name', 'category_detail' , 'classification_detail']
      let colDetails = columnDetails?.filter(c => {
        return FILTER_COLS?.some(k => {
          if(k === 'category_detail') {
            let cats = resp?.data?.find((ele) => ele?.business_term === c?.column_name)?.category_detail?.name ?? '';
            return cats?.toLowerCase()?.includes(searchData?.toLowerCase())
          }
          else if(k === 'classification_detail') {
            let classifiers = resp?.data?.find((ele) => ele?.business_term === c?.column_name)?.classification_detail ?? [];
            return classifiers?.map(v => v?.name?.toLowerCase())?.join(',')?.includes(searchData?.toLowerCase())
          }
          return c[k]?.toLowerCase()?.includes(searchData?.toLowerCase())
        })
      })

      let temp = colDetails.map((column) => {

        if (resp?.data?.length) {
          category_detail = resp.data.find((ele) => ele.business_term === column.column_name)?.category_detail?.name;
          classification_detail = resp.data.find((ele) => ele.business_term === column.column_name)?.classification_detail;
        }
        
        return ({
            key: column.column_id,
            data: { ...column, classifiers: classification_detail, category: category_detail },
            classification: <div className='d-flex align-items-center h-100' style={{ flex: '0 0 100%' }}>


              <div className={`tag-list m-0 w-100 `} style={{ border: "none" }}>
                {classification_detail && classification_detail.length ?
                  <React.Fragment>

                    {classification_detail.map((tag, index) => {
                      return index < 1 ? <div key={index} className={`classificationTag cont font-w-600`}>

                        <Tooltip placement="topLeft" title={tag.name ? tag?.name?.toUpperCase() : tag}><div className="text-elipse">{`${tag.name ? tag?.name?.slice(0, 10)?.toUpperCase() : tag.toUpperCase()} ${tag?.name?.length > 10 || tag?.length > 10 ? '...' : ''} `}</div></Tooltip>

                      </div> : <></>
                    })
                    }
                    {classification_detail?.length > 1 ? <div className="classificationTag font-w-600 ml-1 text-center px-2">

                      <Tooltip title={classification_detail?.filter((a,i) => i !== 0 )?.map(e => e?.name).join(', ')}> <span className="text-with-ellipsis">{`+${classification_detail?.length - 1}`}</span></Tooltip>

                    </div> : null}

                  </React.Fragment> :
                  <p className='text-black-50 mb-0 small pl-2'>N/A</p>
                }
              </div>
            </div>,
            category: <div className='d-flex h-100 align-items-center'>

              <div className={`tag-list m-0 w-100 }`} style={{ border: "none" }}>
                {
                  category_detail && category_detail !== ''
                    ? <div className='d-flex align-items-center h-100' style={{ flex: '0 0 100%' }}>
                      <div className={`categoryTag cont`}>
                        <Tooltip className='d-flex align-items-center' style={{ gap: '10px' }} placement="topLeft" title={category_detail?.toUpperCase()}>
                          <div className="text-with-ellipsis">{`${category_detail?.toUpperCase()}`}
                          </div>

                        </Tooltip>
                      </div>

                    </div>
                    : <div className='col-12 m-0 p-0 '>

                      <p className='text-black-50 mb-0 small pl-2'>N/A</p>


                    </div>}


              </div>

            </div>,
            name: <Tooltip placement="top" title={`${column.column_name?.toUpperCase()}`}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={column.column_name?.toUpperCase() ? column.column_name?.toUpperCase() : ''} /></Tooltip>,
            tech_name: <div className={`d-flex align-items-center `}>


               <span style={{zIndex: 0}} className='mr-2 cursor-pointer icon-box' >{getColumnDataType(column['column_datatype'])}</span>


              <Tooltip title={column['column_source_name']} placement='topLeft'>
                <label className='mb-0 paragraph text-with-ellipsis'>
                  < Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={column['column_source_name'] ? column['column_source_name'] : ''} />
                </label>
              </Tooltip>
              {/* {
                !isDataCatalog ? <span className='ml-2 cursor-pointer icon-box'>{getColumnDataType(data['datatype'])}</span> : ''} */}


            </div>,

            description: column.column_description ? <Tooltip placement="top" title={`${column.column_description}`}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={column.column_description ? column.column_description : ''} /></Tooltip> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px" }}>No description has been given yet.</span></label>,
            dataType: column.column_datatype,
            mask: (
              <Switch
                size="small"
                checked={checkIfMaskedColumn(column.column_name)}
                disabled={ !isEditorPermission || viewMode.includes("view")}
                onChange={(checked) =>onSwitchChangeHandler(checked, column.column_name)
                }
              />
            )
        })
      });
      
      setcolumnListData([...temp])
      return temp
    }
    else {
      return []
    }
  }

  const onSwitchChangeHandler = (checked, columnName) => {
    if(isEditorPermission){
      setMaskedColumns((columns) => {

        const newColumns = [...columns];
        if (checked) {
          newColumns.push({ name: columnName }); //, mask: "XXXX"
        } else {
          const index = newColumns.findIndex(
            (column) => column.name.toLocaleLowerCase() === columnName.toLocaleLowerCase()
          );
          if (index !== -1) {
  
            newColumns.splice(index, 1);
          }
        }
        currentMaskedCol.current = newColumns;
     
  
        return newColumns;
      });
    }
    
  };

  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'name':
        sortColumn = 'column_name'
        return (a, b) => a?.data?.[sortColumn]?.localeCompare(b?.__data?.[sortColumn])??null
      case 'tech_name':
        sortColumn = 'column_source_name'
        return (a, b) => a?.data?.[sortColumn]?.localeCompare(b?.__data?.[sortColumn])??null
      case 'description':
        sortColumn = 'column_description'
        return (a, b) => a?.data?.[sortColumn]?.localeCompare(b?.__data?.[sortColumn])??null
      case 'category':
          return (a, b) => a?.data?.category?.localeCompare(b?.__data?.category?.name)??null
      case 'classification':
          return (a, b) => a?.data?.classifiers?.map(v => v.name)?.join(',')?.localeCompare(b?.__data?.classifiers?.map(v => v.name)?.join(','))??null
      default:
          break;
    }
  }

  const columnListHeadings = [
    {
      name: Literals.BUSINESS_TERM,
      dataIndex: "name",
      key: "name",
      sortable: true,
      width: "15%",

      sorter: {
        compare: (a, b) =>
          a.name?.props?.children[1]?.props?.title !== undefined
            ? a.name?.props?.children[1]?.props?.title.localeCompare(
              b.name?.props?.children[1]?.props?.title
            )
            : null,
      },
    },
    {
      name: Literals.TECHNICAL_TERM,
      dataIndex: "tech_name",
      key: "tech_name",
      width: "15%",
      sortable: true
    },
    {
      name: Literals.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      width: "25%",
      sortable: true,
    },
    {
      name: Literals.DATA_CATEGORY,
      dataIndex: "category",
      key: "category",
      width: "15%",

      sortable: true
    },
    {
      name: Literals.DATA_CLASSIFICATION,
      dataIndex: "classification",
      key: "classification",
      width: "20%",

      sortable: true
    },
    {
      name: Literals.MASK,
      dataIndex: "mask",
      key: "mask",
      width: "10%",
      sortable: false,

    },
  ];

  const checkIfMaskedColumn = (columnName) => {

    let index = -1
    
    if (currentMaskedCol.current?.length) {

      index = currentMaskedCol.current.findIndex(
        (column) => column.name.toLocaleLowerCase() === columnName.toLocaleLowerCase()
      );
    }
    if (index !== -1) { return true; } else {

      return false;
    }
  };

  const colData = React.useMemo(() => {
    if(!data) return []
    const arr = genarateData(data)
    return arr
  }, [data, searchData, maskedColumns, isEditorPermission])

  return (
    <div className="policy-column-mask mb-1">
      <div className="policy-column-mask-header">
        <div className="policy-column-mask-title">
          <h4>{Literals.COLUMN_LEVEL_MASKING}</h4>
        </div>
        <div className="policy-column-mask-options">
          <SearchInput searchData={searchData} setSearchData={setSearchData} />
        </div>
      </div>
      <div className="policy-column-mask-table crt-pod-schm-wrap ">
        {
          loading ? <ClaristaLoader/>
          :
          <LandingSimpleTable
            cols={columnListHeadings}
            rowsData={colData ?? []}
            // rowsData={columnListData}

            onRowClick={() => { }}
            getComparator={getComparator}
            tableHeight={300 - 58}
          // tableHeight={TableHeight}
          />
        }
      </div>
    </div>
  );
};

export default ColumnLevelMasking;
