
import {

    UPDATE_FLOWS_ELEMENTS,
    SET_FLOWS_CURRENTTRANSFORMER,

    UPDATE_FLOWS_BASICDETAILS,

    SET_TO_DEFAULT,

    SAVE_FLOW_CLICK,
    SET_TRANSFORMER_PROGRESS,

    SET_TRANSFORMER_MAXID,
    SET_TRANSFORMER_NAME,
    SET_CURRENT_SCRIPTOUTPUT_NODE,

    SHOW_BRICKS_MENU,
    SHOW_FLOWS_TRANSFORMDETAILS,
    SET_FLOWS_PERMISSIONS,
    SHOW_FLOWS_LOGS_LOWERSECTION,
    SHOW_FLOWS_JUPYTERNOTEBOOK
} from './flowsTypes'

const initialState = {

    showTransformDetails: false,
    flowsElements: { nodes: [], edges: [] },
    currentTransformer: {},
    name: null,
    description: null,
    id: null,
    bookmark: false,
    showJupyterNoteBook: false,
    saveFlowClick: false,
    transformerName: {},
    maxTransformerId: 0,
    transformerProgress: [],
    showBricksIcon: false,
    flowsPermissions: [],
    showLogsLowerSection: false

}

const Lab = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {


        case SHOW_BRICKS_MENU:
            return {
                ...state,
                showBricksIcon: payload
            };
        case SET_TRANSFORMER_PROGRESS:
            return {
                ...state,
                transformerProgress: payload
            };
        case SHOW_FLOWS_LOGS_LOWERSECTION:
            return {
                ...state,
                showLogsLowerSection: payload
            };

        case SET_TRANSFORMER_MAXID:
            return {
                ...state,
                maxTransformerId: payload
            };

        case SHOW_FLOWS_JUPYTERNOTEBOOK:
          
            return {
                ...state,
                showJupyterNoteBook: payload
            };

        case SET_FLOWS_PERMISSIONS:
            return {
                ...state,
                flowsPermissions: payload
            };
        case SET_TRANSFORMER_NAME:
            return {
                ...state,
                transformerName: payload
            };

        case SHOW_FLOWS_TRANSFORMDETAILS:
            return {
                ...state,
                showTransformDetails: payload
            };

        case UPDATE_FLOWS_ELEMENTS:
            return {
                ...state,
                flowsElements: payload
            };
        case SET_FLOWS_CURRENTTRANSFORMER:
            return {
                ...state,
                currentTransformer: payload
            };
        case SET_CURRENT_SCRIPTOUTPUT_NODE:
            return {
                ...state,
                currentScriptOutput: payload
            };
        case UPDATE_FLOWS_BASICDETAILS:
            return {
                ...state,
                name: payload.name,
                description: payload.description,
                id: payload.id,
                bookmark: payload?.bookmark ? payload?.bookmark : false
            };

        case SAVE_FLOW_CLICK:
            return {
                ...state,
                saveFlowClick: payload
            };
        case SET_TO_DEFAULT:
            initialState['transformerName'] = {}
            initialState['currentTransformer'] = {}
            initialState['flowsElements'] = []


            return initialState
        default:
            return state;

    }
}

export default Lab