import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import toastr from "toastr";
import { emitToastNotification } from "../../../../helpers/toast_helper";

const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const fetchFlowsLandingPageList = (data_domain_id, editor, mode = '') => {

    return (dispatch, getState) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}flow/?data_domain_id=${data_domain_id ? data_domain_id : ''}&${editor}&${mode}`)
            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                if (resp.status === false) {
                    reject(resp.message);
                    emitToastNotification('error', resp.message);
                } else {
                    // dispatch(setChartResponseQuery(resp.data.query))
                    resolve(resp);
                }
            })
                .catch((err) => {
                    emitToastNotification('error', err.message)
                    dispatch(setLoadingStatus(false));
                    reject(err)
                });
        });
    };
}

export const getFlowsDetails = (flowId) => {

    return (dispatch, getState) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}flow/${flowId}/`)
            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                if (resp.status === false) {
                    reject(resp.message);
                    emitToastNotification('error', resp.message);
                } else {
                    // dispatch(setChartResponseQuery(resp.data.query))
                    resolve(resp);
                }
            })
                .catch((err) => {
                    emitToastNotification('error', err.message)
                    dispatch(setLoadingStatus(false));
                    reject(err)
                });
        });
    };
}
export const createBookmark = (id, bookmarkStatus) => {
    // let bookmark = bookmarkStatus ? 'delete_bookmark' : 'create_bookmark'
    return (dispatch, getState) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(bookmarkStatus ?"GET":"DELETE" , `${BASE_URL}flow/${id}/bookmark/`)
            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error', resp.message);
                } else {
                    // toastr.success(resp.message);
                    emitToastNotification('success', resp.message)
                    // dispatch(setChartResponseQuery(resp.data.query))
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                });
        });
    };
}