import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import {
    Card, CardBody, CardHeader, Progress
} from 'reactstrap';


import DoughnutChart from './DoughnutChart';
import FrequencyGraph from './FrequencyGraph';

import {
    fetchGraphData,
    refreshGraphData,
    setGraphData,
    loadDictionaryGraph,
    fetchGraphDataV2,
    refreshGraphDataV2
} from "../../../../../store/modules/dataCatalogue/dictionary/dictionaryAction";
import { setAnalyzeModalVisibility, SetCorelationGraph } from "../../../../../store/modules/dataCatalogue/preview/previewActions";
import { get } from "lodash";
import LengthStat from './LengthStat';
import { Literals } from "../../../common/literals";
import { getRelativeTime, getTime } from '../../../common/helperFunctions';
import { ANALYTICS, REFRESH, CLOSE } from '../../../../Common/iconSource';
import Buttons from '../../../../Common/button/Buttons';
import SingleSelection from "../../../../Common/dropDown/SingleSelection";
import { CustomSkeleton } from '../../../../Common/skeleton/CustomSkeleton';


function Analyze({ setshowLoader, columns, showColumnsDropdown = true }) {
    let calledApiOnce = useRef(null)

    const [columnDetails, setcolumnDetails] = useState({});
    const [selectedColumn, setselectedColumn] = useState(columns && columns.length ? columns[0].Name : '')
    const [isGraphDataSet, setisGraphDataSet] = useState(false);
    const [correlationData, setCorrelationData] = useState({})
    const statData = useSelector(state => state.DataCatalogue.Dictionary.graphData)
    const coGraphData = useSelector(state => state.DataCatalogue?.PreviewPage.coGraphData)

    const [analyzeData, setanalyzeData] = useState({})
    const [corelationGraphData, setcorelationtGraphData] = useState([])

    const catalogueDictionary = useSelector((state) => state.DataCatalogue.Dictionary);
    const dispatch = useDispatch();
    const tableId = get(catalogueDictionary, `dictionaryPodData.table_id`, "");
    const [, setIsGraphDataEmpty] = useState(false);
    const [refreshApiStatus, setRefreshApiStatus] = useState(false);
    const [graphCalcCount, setGraphCalcCount] = useState(0);
    const [selectedSample, setSelectedSample] = useState(catalogueDictionary.sampleSelected);


    const sampleOptions = [
        { Name: 1000, Type: '' },
        { Name: 5000, Type: '' },
        { Name: 10000, Type: '' },
        { Name: 100000, Type: '' },
        { Name: 1000000, Type: '' },
        { Name: 'ALL', Type: '' }
    ]

    function closeModel() {
        dispatch(setAnalyzeModalVisibility(false))
        setanalyzeData({})
    }

    const getColumnId = (columnName) => {
        if (catalogueDictionary && catalogueDictionary.dictionaryTableData?.length) {
            let element = catalogueDictionary.dictionaryTableData.find((ele) => {
                return columnName === ele.column_name;
            });
            if (element) {
                return element?.column_id;
            }
        }
    }

    useEffect(() => {
       
        let column = JSON.parse(localStorage.getItem('columnDetails'))

        if (column) {
            setcolumnDetails({ ...column });
        }
        if (statData?.length && column) {
            setisGraphDataSet(false);
            setshowLoader(false)
            let columnStats;
            let columnID = getColumnId(column.name)

            if (columnID) {
                columnStats = statData.find((ele) => {
                    return ele.column_id === columnID;
                })
                if (columnStats) {

                    setanalyzeData({ ...columnStats })
                }

            }
        } else if (!isGraphDataSet) {

            getGraphData();

        }

        if (!coGraphData?.length) {
            // getCoGraphData();

        } else {
            setcorelationtGraphData([...coGraphData]);
        }

    }, [statData])

    const getCoGraphData = () => {
        let payload = {
            action: "get_corr",
            table_id: tableId,
        };

        store.dispatch(fetchGraphData({ payload })).then((response) => {
            if (response && response.data && response.data?.length) {
                let data = response.data;

                if (data.length) {
                    setCorrelationData(data)
                    dispatch(SetCorelationGraph(data))
                }
            }

        }).catch(() => {

            setshowLoader(false);

        })
    }

    const store = useStore();
    useEffect(() => {
        if (refreshApiStatus === true) {
            setTimeout(() => {

                getGraphData();
            }, 30000);
        }
    }, [graphCalcCount]);

    const getGraphData = async () => {


        // setshowLoader(true);

        let response;

        try {
            // let payload = {
            //     action: "get_stats",
            //     table_id: tableId,
            // };

            response = await store.dispatch(fetchGraphDataV2(tableId));
            if (
                response.status === "success" &&
                response?.data?.column_stats?.length === 0
            ) {
                setSelectedSample(response?.data?.sample)
                setIsGraphDataEmpty(true);
                refreshGraph();
            } else if (
                response.status === "success" &&
                response?.data?.column_stats?.length > 0
            ) {
                dispatch(setGraphData(response?.data?.column_stats));
                setSelectedSample(response?.data?.sample)
                // mapGraphData(response.data);
                setIsGraphDataEmpty(false);
                setRefreshApiStatus(false);
                dispatch(loadDictionaryGraph(false));
            } else if (
                response.status === "success" &&
                response.message === "Stats calculation in progress"
            ) {

                setRefreshApiStatus(true);
                setGraphCalcCount(graphCalcCount + 1);
            }
        } catch (error) {

            setshowLoader(false)
            setRefreshApiStatus(false);
            dispatch(loadDictionaryGraph(false));

        }

    };

    const refreshGraph = async (sample = 1000) => {
        setSelectedSample(sample)
        let response;
        let payload = {
            sample: sample,
            force: false,
        };
        try {
            response = await store.dispatch(refreshGraphDataV2({ payload }, tableId));
            if (
                response?.status === "success" &&
                response?.message === "Stats calculation Started"
            ) {
                setRefreshApiStatus(true);
                setTimeout(() => {

                    getGraphData();
                    // getCoGraphData();
                }, 30000);
            }
        } catch (error) {

        }
    };

    const getProgressBarValue = (value) => {

        if (analyzeData) {
            return Math.round((value / analyzeData?.total_count) * 100);
        }
    }

    const formatDateTimeString = (analyzeDate) => {
        const date = getRelativeTime(analyzeDate);
        const time = getTime(analyzeDate);
        return `(${date} / ${time})`;
    }

    const setSelecteAnalysisColumn = (value) => {


        setselectedColumn(value)
        let findIndex = columns.findIndex((ele) => ele.Name === value)
        if (findIndex > -1) {
            // localStorage.setItem('columnDetails', JSON.stringify({ dataType: columns[findIndex].Type, name: columns[findIndex].Name }))
            let column = { dataType: columns[findIndex].Type, name: columns[findIndex].Name }
            if (column) {
                setcolumnDetails({ ...column });
                localStorage.setItem(
                    "columnDetails",
                    JSON.stringify({
                        dataType: column.dataType,
                        name: column.name,
                    })
                );
            }
            if (statData.length && column) {
                setisGraphDataSet(false);
                setshowLoader(false)
                let columnStats;
                let columnID = getColumnId(column.name)

                if (columnID) {
                    columnStats = statData.find((ele) => {
                        return ele.column_id === columnID;
                    })
                    if (columnStats) {

                        setanalyzeData({ ...columnStats })
                    }

                }
            } else if (!isGraphDataSet) {

                getGraphData();
            }

            if (!coGraphData?.length) {
                // getCoGraphData();

            } else {
                setcorelationtGraphData([...coGraphData]);
            }
        } else {
            localStorage.setItem('columnDetails', JSON.stringify({ dataType: columns[0].Type, name: columns[0].Name }))

        }
    }

    const columnNameType = JSON.parse(localStorage.getItem('columnDetails'))

    return (
        <>
            <div className="analyze-backdrop" onClick={closeModel}></div>
            <div className="analyze-tag">
                {analyzeData && analyzeData !== null ?
                    <Card>
                        <CardHeader>
                            <div className="analyze-tag-header">
                                <div className="analyze-tag-headings">
                                    <h4 className="m-0">
                                        <ANALYTICS />
                                        <span className="subtitle ml-2">{Literals.ANALYZE}</span>
                                    </h4>
                                    <p className="caption">last refresh on <span className="pl-1">{refreshApiStatus ? 'updating...' : analyzeData && formatDateTimeString(analyzeData.updated_on)} </span></p>
                                </div>

                                <div className="analyze-tag-close">
                                    <span className="align-items-center d-inline-flex mr-2">
                                        <label className="mb-0 label mr-2">Sample selected:</label>
                                        <SingleSelection
                                            props={{
                                                label: "",
                                                // placeholder: "Select Sample",
                                                selectClassName: 'sel-sample-drop',
                                                dropDownValue: selectedSample,
                                                onClickEvent: (value) => {
                                                    refreshGraph(value)
                                                },
                                                optionList: sampleOptions,
                                                isDisable: refreshApiStatus,
                                            }}
                                        />
                                    </span>
                                    <Buttons props={{ tooltip: 'Refresh', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { refreshGraph() }, ImgSrc: () => <span className={`${refreshApiStatus ? 'bx-spin' : ''} d-flex`}><REFRESH /></span>, isDisable: refreshApiStatus, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
                                    <Buttons props={{ tooltip: 'Close', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { closeModel() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                </div>
                            </div>

                        </CardHeader>
                        <CardBody>
                            <div className="analyze-scroll">
                                <div className="analyze-upper-card">
                                    <div className="analyze-common-card-header">
                                        <div className='row'>
                                            <div className='col-6 align-self-center'>
                                                <h4 className="subtitle m-0">{Literals.SUMMARY}</h4>
                                            </div>
                                            <div className={`col-6`}>
                                                {
                                                    showColumnsDropdown ?
                                                        refreshApiStatus ? <CustomSkeleton width='100%' height='26px' /> :
                                                            <SingleSelection
                                                                props={{
                                                                    label: "",
                                                                    placeholder: "Select Column Name",
                                                                    dropDownValue: selectedColumn,
                                                                    onClickEvent: (value) => {
                                                                        setSelecteAnalysisColumn(value)
                                                                    },
                                                                    optionList: columns,
                                                                    isDisable: false,
                                                                }}
                                                            /> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="analyze-upper-second-card">
                                        <div className="left-section">
                                            <div className="left-section-first">
                                                <p className='text-uppercase'>{Literals.BUSINESS_NAME}
                                                    {
                                                        refreshApiStatus ? <CustomSkeleton width='80px' height='18px' />
                                                            : <span>{columnDetails.name}</span>
                                                    }
                                                </p>
                                                <p>{Literals.TOTAL_COUNT}
                                                    {
                                                        refreshApiStatus ? <CustomSkeleton width='80px' height='18px' />
                                                            : <span>{analyzeData.total_count}</span>
                                                    }
                                                </p>
                                                <p>{Literals.DATA_TYPE}
                                                    {
                                                        refreshApiStatus ? <CustomSkeleton width='80px' height='18px' />
                                                            : <span>{columnDetails.dataType}</span>
                                                    }
                                                </p>
                                            </div>
                                            <div className="left-section-second">
                                                <ul>
                                                    <li>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="label m-0">{Literals.UNIQUE}</p>
                                                            {
                                                                refreshApiStatus ? <CustomSkeleton width='80px' height='18px' /> :
                                                                    <p className="paragraph m-0">{analyzeData?.unique_count}</p>
                                                            }
                                                        </div>
                                                        {
                                                            refreshApiStatus ? <CustomSkeleton width='100%' height='18px' /> :
                                                                <Progress color='success' value={analyzeData && analyzeData.unique_count > 0 ? getProgressBarValue(analyzeData?.unique_count) : 0}></Progress>
                                                        }
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="label m-0">{Literals.MISSING}</p>
                                                            {
                                                                refreshApiStatus ? <CustomSkeleton width='80px' height='18px' /> :
                                                                    <p className="paragraph m-0">{analyzeData?.count_na}</p>
                                                            }
                                                        </div>

                                                        {
                                                            refreshApiStatus ? <CustomSkeleton width='100%' height='18px' /> :
                                                                <Progress color="success" value={analyzeData && analyzeData.count_na > 0 ? getProgressBarValue(analyzeData?.count_na) : 0}></Progress>
                                                        }
                                                    </li>
                                                    <li>
                                                        {
                                                            analyzeData?.zeroes !== null ?
                                                                <>
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <p className="label m-0">{Literals.ZEROES}</p>
                                                                        {
                                                                            refreshApiStatus ? <CustomSkeleton width='80px' height='18px' /> :
                                                                                <p className="paragraph m-0">{analyzeData?.zeroes}</p>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        refreshApiStatus ? <CustomSkeleton width='100%' height='18px' /> :
                                                                            <Progress color="success" value={analyzeData && analyzeData.zeroes > 0 ? getProgressBarValue(analyzeData?.zeroes) : 0}></Progress>
                                                                    }
                                                                </> : ''
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={`right-section p-2 ${refreshApiStatus ? 'd-block' : ''}`}>
                                            {
                                                refreshApiStatus ? <CustomSkeleton width='100%' height='300px' /> :
                                                    <DoughnutChart graphData={analyzeData} />
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="analyze-bottom-card">
                                    <div className="analyze-common-card-header">
                                        <h4 className="subtitle m-0">{Literals.FREQUENCY_ANALYSIS}</h4>
                                    </div>
                                    <div className="analyze-bottom-graph p-2">
                                        {
                                            refreshApiStatus ? <CustomSkeleton width='100%' height='300px' /> :
                                                <FrequencyGraph graphData={analyzeData} columnNameType={columnNameType} columnDetails={columnDetails} />
                                        }
                                    </div>
                                </div>

                                {/* <div className="summary-background mt-2">
                            <h4>{Literals.LENGTH_STATISTICS}</h4>
                        </div> */}

                                {/* <div className="length-graph-box">
                            {analyzeData && analyzeData.max_value ? < LengthStat graphData={analyzeData} /> : <span>No Data Avaliable</span>}
                        </div>
                        <div className="heat-map">
                            <div className="summary-background mt-2">
                                <h4>{Literals.CORELATION}</h4>
                            </div>
                            <HeatMap graphData={corelationGraphData} correlationData={correlationData} />
                        </div> */}
                            </div>
                        </CardBody>
                    </Card>
                    : null
                }
            </div>
        </>
    )
}

export default Analyze
