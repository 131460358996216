import { SET_ACCESS_MANAGENEBT_PAGE_DEFAULT, SET_TABLE_ID , SET_TEMPSELECTED_USERGROUP , SET_TEMP_MASKCOLUMN , SET_DATA_DOMAIN } from './accessManagementActionTypes';
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { emitToastNotification } from '../../../../helpers/toast_helper';

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;
const LANDING_PAGE_API_URL = window._env_.REACT_APP_API_BASE_URL;

export const getAMPolicyList = ({ method, endPoint, payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(method, `${BASE_URL}${endPoint}`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        })
    })
  }
}

export const setAccessManagementPageDefault = () => {
  return {
    type: SET_ACCESS_MANAGENEBT_PAGE_DEFAULT,
  };
};

export const setTempSelectedUserGroup = (data) => {
  return {
    type: SET_TEMPSELECTED_USERGROUP,
    payload: data,
  };
}

export const setTempMaskColumn = (data) => {
  return {
    type: SET_TEMP_MASKCOLUMN,
    payload: data,
  };
}

export const createPolicy = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${BASE_URL}policy/`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const readPolicy = (endPoint) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}${endPoint}`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const updatePolicy = (id, payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("PUT", `${LANDING_PAGE_API_URL}policy/${id}/`,payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const setTableId = (data) => {
  return {
    type: SET_TABLE_ID,
    payload: data,
  };
}

export const setDataDomain = (data) => {
  return {
    type: SET_DATA_DOMAIN,
    payload: data,
  };
}