import {
  Row,
  Col,
  Card,

} from "reactstrap";
import { useDispatch } from "react-redux"
import React, { useState } from "react"
import Buttons from "../../../../Common/button/Buttons"
import { Button, Popover } from 'antd';
import { Input, Tooltip } from 'antd';


import { CLOSE, DELETEICON, DOWNLOAD, EDIT, SAVE } from "../../../../Common/iconSource"
import { downloadPodDocuments, SummaryPodDocuments, UpdateSummaryPodDocsV2, DeletePodDoc,retreivePodDoc } from "../../../../../store/modules/dataCatalogue/summary/summaryActions"
import { Literals } from "../../../common/literals"
import ActionPopUp from "../../../common/actionPopUp";
import toastr from "toastr";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { getDeleteMessage } from "../../../common/helperFunctions";
import DeleteModal from "../../../../Common/deleteModal/DeleteModal";

const FileContainer = ({ 
  podName, 
  getSummaryDocument, 
  tableId, 
  userId, 
  f, i, 
  checkbar, 
  Icon, 
  isDelete = true, 
  isCancel = false, 
  isDownload = true, 
  isEdit = true, 
  download,
  isDeletePermission,
  isEditPermission,
}) => {
  const dispatch = useDispatch()
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [renameText, setRenameText] = useState(f.file_name?.split('.')[0] ?? "");
  const [visible, setVisible] = useState(false);

  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);

  const openDeletePopupHandler = () => {
    // setDeletePopUpContent({
    //   heading: "Delete",
    //   modalClass: "delete-popup",

    //   message: "This action will delete the file permanently",
    //   button: [
    //     {
    //       name: "Delete",
    //       action: () => deleteDocumentHandler(),
    //     },

    //   ],
    //   elements: [],
    //   closeActionPopup: closeDeletePopupHandler,
    // });

    setIsDeletePopUpOpen(true);
  }

  const closeDeletePopupHandler = () => {
    setIsDeletePopUpOpen(false);
  }
  const downloadDocumentHandler = async () => {
    let payload = {
     
      id: f.id,
    
    }
    dispatch(retreivePodDoc({payload}))
  }

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  const hide = () => {
    setVisible(false);
  };
  const updateDocumentHandler = () => {
    dispatch(UpdateSummaryPodDocsV2({ id: f.id, table: tableId, file_name: `${renameText}.${f.file_name.split(".")[1]}` }))
      .then((response) => {
        getSummaryDocument();
        emitToastNotification('success', response?.message)
      })
  }
  const deleteDocumentHandler = () => {

    const payload = {
      id: f.id
    };


    
    dispatch(DeletePodDoc(payload)).then((response) => {
      getSummaryDocument();
      emitToastNotification('success', response?.message)
      
    }).catch(err => {
      console.error('Error Deleting document')
    });
    
    

    
    
    
    closeDeletePopupHandler();
  };
  return <> <Card
    className="mt-1 mb-0 shadow-none border-box dz-processing col-4 p-0 mr-2 dz-image-preview dz-success dz-complete position-relative"
    key={i + "-file"}
  >
    <div className="p-2 ">
      <Row>
        <Col className="col-auto pr-0">
          <div style={{width: '3.75rem', height: '3.75rem'}} className="p-2 d-flex align-items-center justify-content-center bx-border-circle">
            {Icon}
          </div>
        </Col>

        <div className='catalog-buttons-container'>
          {isCancel ? <Buttons
            props={{
              buttonText: "",
              buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
              buttonEvent: () => {

              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_TERTIARY,
            }}
          /> : null}
          {isEdit ?

            <Popover
              content={<div style={{ display: "flex", padding: "10px", alignItems: "center" }}>

                <Input value={renameText} onChange={(e) => setRenameText(e.target.value)} />
                <Buttons
                  props={{
                    buttonText: "",
                    buttonClassName: "custom-btn-primary custom-btn btn-with-icon ml-1",
                    buttonEvent: (e) => {
                      e.preventDefault();
                      updateDocumentHandler();
                    },
                    ImgSrc: () => <SAVE />,
                    isDisable: false,
                    buttonType: Literals.BTN_SECONDARY,
                  }}
                />
              </div>}


              title={<div style={{ display: "flex", padding: "10px", justifyContent: "space-between" }}><span>Rename</span> <Buttons
                props={{
                  buttonText: "",
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                  buttonEvent: () => {
                    setVisible(false);
                  },
                  ImgSrc: () => <CLOSE />,
                  isDisable: false,
                  buttonType: Literals.BTN_TERTIARY,
                }}
              /></div>}
              onOpenChange={handleVisibleChange}
              trigger="click"
              open={visible}
            >
              <Buttons
                props={{
                  buttonText: "",
                  buttonClassName: "custom-btn-outline custom-btn-borderless custom-btn btn-with-icon mr-1",
                  buttonEvent: (e) => {
                    setVisible(true);
                  },
                  ImgSrc: () => <EDIT />,
                  isDisable: !isEditPermission,
                  buttonType: Literals.BTN_SECONDARY,
                }}
              />
            </Popover> : null}
          {isDownload ?
            <Buttons
              props={{
                buttonText: "",
                buttonClassName: "custom-btn-outline custom-btn-borderless custom-btn btn-with-icon mr-1",
                buttonEvent: (e) => {
                  e.preventDefault()
                  downloadDocumentHandler()
                },
                ImgSrc: () => <DOWNLOAD />,
                isDisable: false,
                buttonType: Literals.BTN_SECONDARY,
              }}
            /> : null}
          {isDelete ? <Buttons
            props={{
              buttonText: "",
              buttonClassName: "custom-btn custom-btn-outline btn-with-icon error-red",
              buttonEvent: (e) => {
                e.preventDefault()
                openDeletePopupHandler(f)
              },
              ImgSrc: () => <DELETEICON />,
              isDisable: !isDeletePermission,
              buttonType: Literals.BTN_SECONDARY,
            }}
          /> : null}
        </div>
        <Col className='catalog-right-column'>
          <p className="text-muted font-dependency font-weight-bold text-left width-decider">
            <Tooltip placement="top" title={f.file_name}>
              {f.file_name?.length > 8 ? `${f.file_name.slice(0, 8)}...` : f.file_name}
            </Tooltip>
          </p>
          <p className="mb-0 text-left">
            <span className="file-size fontSizeLabel">{f.file_size}</span>
          </p>
        </Col>

      </Row>
    </div>
  </Card>
      <DeleteModal
        open={isDeletePopUpOpen}
        setOpen={setIsDeletePopUpOpen}
        body={getDeleteMessage({
          title: 'Document'
        })}
        onConfirm={() => deleteDocumentHandler()}
      />
    {/* <ActionPopUp
      openModal={isDeletePopUpOpen}
      actionData={deletePopUpContent}
    /> */}
    </>
}

export default FileContainer;