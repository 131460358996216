import React from 'react'
import { LandingPageSubHeaderContext } from './LandingPageSubHeaderContext'
import LandingPageSubHeaderLeftSide from './LandingPageSubHeaderLeftSide'
import LandingPageSubHeaderRightSide from './LandingPageSubHeaderRightSide'

function LandingPageSubHeader({ props }) {
    let { hideAllOptions = false, isDisableAllOptions = false, isDictionaryPage = false, searchData = ''} = props
    return (
        <div className={props.fromRoute !== 'alert' && props.fromRoute !== 'scheduler' ?"comm-subheader flow-sub-header custom-border-top custom-border-bottom":"flow-sub-header custom-border-bottom padding-2"}>
            <LandingPageSubHeaderContext.Provider value={{ ...props }}>
                
               <LandingPageSubHeaderLeftSide {...props} />
               {
                !hideAllOptions
                ?
                <LandingPageSubHeaderRightSide 
                    isDisableAllOptions={isDisableAllOptions} 
                    isDictionaryPage={isDictionaryPage}
                    searchData={searchData}     
                    {...props}
                />
                :   ''
               }
            </LandingPageSubHeaderContext.Provider>

        </div>
    )
}

export default LandingPageSubHeader
