import { Select, Switch } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeProviderMFAStatus, createTenantEmail, createTenantMicrosoft, getTenantEmail, getTenantMicrosoft } from '../../../../../store/modules/UserManagement/users/usersActions'
import { ICON_ALERT, ICON_CHECK, ICON_LOADING_CIRCLE } from '../../../../Common/newIconSource'

const MicrosoftClientForm = React.forwardRef(({
    fieldValues = {},
    setFieldValues = () => { },
    loading = false,
    setLoading = () => { },
    isActiveToggle,
    setisActiveToggle,
    saving = false,
    setSaving = () => { }
}, ref) => {

    const dispatch = useDispatch()

    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showError, setShowError] = React.useState(false)

    React.useImperativeHandle(ref, () => {
        return {
            save: () => {
                handleSave()
            }
        }
    }, [fieldValues])

    React.useEffect(() => {
        setFieldValues(prev => (
            {
                ...prev,
                microsoft: {
                    client_id: '',
                    client_secret: '',
                    valid_email_domain: [],
                    provider: 'microsoft',
                    tenant_id: '',
                    mfa_enabled:false
                }
            }
        ))
        getDetails();
    }, [])
    // console.log({fieldValues})
    const getDetails = () => {
        setLoading(true)
        dispatch(getTenantMicrosoft())
            .then(res => {
                let data = res?.data ?? []
                let el = {};
                if (data?.length) {
                    el = data?.find(item => item.provider === "microsoft");
                }
                setFieldValues(prev => (
                    {
                        ...prev,
                        microsoft: {
                            client_id: el?.client_id,
                            client_secret: el?.client_secret,
                            is_active: el?.is_active ? true : false,
                            valid_email_domain: el?.valid_email_domain ?? [],
                            provider: 'microsoft',
                            tenant_id: el?.tenant_id,
                            mfa_enabled:el?.mfa_enabled ?? false
                        }
                    }
                ))
                setisActiveToggle(el?.is_active)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const handleSave = () => {
        setShowError(false)
        setShowSuccess(false)
        setSaving(true)
        let values = fieldValues?.microsoft
        let payload = {
            ...values
        }
        setTimeout(() => {
            dispatch(createTenantMicrosoft(payload))
                .then(res => {
                    setSaving(false)
                    setShowSuccess(true)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 5000)
                })
                .catch(err => {
                    setSaving(false)
                    setShowError(true)
                    setTimeout(() => {
                        setShowError(false)
                    }, 5000)
                })
        }, 5000)
    }

    const onInputHandler = (key, value) => {

        setFieldValues(prev => {
            return {
                ...prev,
                microsoft: {
                    ...prev?.microsoft,
                    [key]: value
                }
            }
        })
    }

    return (
        <div>
            <div className='row'>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Client id
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="text"
                        value={fieldValues?.microsoft?.client_id ?? ''}
                        onChange={(e) => {
                            onInputHandler('client_id', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Client secret
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="password"
                        value={fieldValues?.microsoft?.client_secret ?? ''}
                        onChange={(e) => {
                            onInputHandler('client_secret', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                {/* <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Provider
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="text"
                        value={fieldValues?.microsoft?.provider ?? ''}
                        onChange={(e) => {
                            onInputHandler('provider', e?.target?.value)
                        }}
                        disabled={true}
                        required
                    />
                </div> */}
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Tenant id
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="text"
                        value={fieldValues?.microsoft?.tenant_id}
                        onChange={(e) => {
                            onInputHandler('tenant_id', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted m-0">
                            Valid email domain
                        </label>
                    </div>
                    <Select
                        mode="tags"
                        className="custom-input-field w-100"
                        value={fieldValues?.microsoft?.valid_email_domain}
                        onChange={(value) => {
                            onInputHandler('valid_email_domain', value)
                        }}
                        tokenSeparators={[',']}
                        options={[]}
                    />

                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Two-Factor Authentication<span style={{ fontSize: "9px", fontStyle: "italic", }}>{"On (TOTP Authenticator)"}</span>
                        </label>
                    </div>
                    <Switch
                        size='small'
                        checked={fieldValues?.microsoft?.mfa_enabled}

                        onChange={(checked) => {
                            onInputHandler('mfa_enabled', checked)
                            dispatch(changeProviderMFAStatus(true,'microsoft', checked)).then((res)=>{
                                
                            })

                        }}
                    />
                </div>

                {
                    showSuccess
                        ?
                        <div className='col-12 mt-2'>
                            <div className='bg-light border border-success fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-success'>
                                <span className='mr-2'><ICON_CHECK width='16' height='16' color='currentColor' /></span>
                                <span>
                                    Succesfully created Microsoft Client
                                </span>
                            </div>
                        </div>
                        : ''
                }
                {
                    saving
                        ?
                        <div className='col-12 mt-2'>
                            <div className='bg-light border border-primary fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-primary'>
                                <span className='mr-2'><ICON_LOADING_CIRCLE width='16' height='16' color='currentColor' /></span>
                                <span>
                                    Creating Microsoft Client
                                </span>
                            </div>
                        </div>
                        : ''
                }
                {
                    showError
                        ?
                        <div className='col-12 mt-2'>
                            <div className='bg-light border border-danger fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-danger'>
                                <span className='mr-2'><ICON_ALERT width='16' height='16' color='currentColor' /></span>
                                <span>
                                    Failed to create Microsoft Client
                                </span>
                            </div>
                        </div>
                        : ''
                }
            </div>
        </div>
    )
})

export default MicrosoftClientForm