import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';
import { getLineageData as retreiveLineageData } from '../../../../store/modules/lineage/lineageAction';
import LineageCard from './lineageCard';
import Graph from './Graph';
import { ALERT_LINEAGE, CHART, CLOSE, CONNECTOR, DASHBOARD, TABLEICON, DATASET, DOMAIN_SMALL, RIGHTARROW, SCHEDULER, SMALLFLOWS, SMALLUSERS, USERMANAGEMENT, ALERT, PODICON } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import { generateHtmlTitle, NoDataComponent } from '../../common/helperFunctions';
import NoSearchResultFound from '../../common/NoSearchResultFound';
import Buttons from '../../../Common/button/Buttons';
import _ from 'lodash';
import { ICON_CATALOG, ICON_CATALOG_DOMAIN, ICON_CHART, ICON_DASHBOARD, ICON_USERS_GROUP } from '../../../Common/newIconSource';

let id = 0;
let edgeId = 0;

const URL_MAPPING = {
    'connection': Literals.links.CONNECTIONS,
    'domain': Literals.links.USER_MANAGEMENT_DATA_DOMAIN,
    'pod': Literals.links.DATA_CATALOG_POD_DETAILS,
    'chart': Literals.links.DATA_CATALOG_POD_DETAILS,
    'flow': `${Literals.links.FLOWS}/`,
    'rule': Literals.links.ALERTS_VIEW,
    "scheduler": Literals.links.SCHEDULER_VIEW,
    'alert': Literals.links.ALERTS_VIEW,
    'group': Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS,
    'user': Literals.links.USER_MANAGEMENT_USER,
    'dashboard': Literals.links.DASHBOARD_PULSE
}

const MODEL_ICON_MAPPING = {
    "alert": <ALERT />,
    "flow": <SMALLFLOWS />,
    "connection": <CONNECTOR />,
    "user": <SMALLUSERS />,
    "scheduler": <SCHEDULER />,
    "pod": <ICON_CATALOG />,
    "group": <ICON_USERS_GROUP />,
    "domain": <ICON_CATALOG_DOMAIN />,
    "chart": <ICON_CHART />,
    "source": <ICON_CATALOG />,
    "dashboard": <ICON_DASHBOARD />
}
const TYPE_MAPPING = {
    "alert": "Alert",
    "flow": "Flow",
    "connection": "Connection",
    "user": "User",
    "scheduler": "Scheduler",
    "pod": "POD",
    "group": "Group",
    "domain": "Domain",
    "chart": "Chart",
    "source": "Source",
    "dashboard": "Dashboard"
}

const COLOR_MAPPING = {

    'data_domain': "rgba(39, 90, 137, 0.4)",
    'domain': "rgba(39, 90, 137, 0.4)",
    'pod_superset': 5,
    'source': " rgba(44, 131, 196, 0.4)",
    'table': "rgba(130, 130, 130, 0.4)",
    'connection': "rgba(130, 130, 130, 0.7)",
    'pod': "rgba(0, 188, 212, 0.4)",
    'chart': "rgba(205, 220, 57, 0.4)",
    'dashboard': "rgba(122, 40, 255, 0.4)",
    'rule': "rgba(255, 187, 1, 0.4)",
    'visualize': "rgba(205, 220, 57, 0.4)",
    'flow': "rgba(255, 87, 34, 0.4)",
    'alert': "rgba(255, 187, 1, 0.4)",
    'scheduler': "rgba(255, 69, 228, 0.4)",
    'user': "rgba(255, 69, 228, 0.4)",
    'group': "rgba(255, 69, 228, 0.4)"
}

const getId = () => `dndnode_${id++}`;
const getEdgeId = () => `edge_${edgeId++}`;
const TYPES = ['connection', 'data_domain', 'pod', 'flow', 'rule', 'alert', 'group', 'user', 'scheduler', 'table', 'source', 'chart', 'dashboard', 'visualize']
const position = {
    'data_domain': 0,
    'domain': 0,
    'pod_superset': 6,
    'connection': 1,
    'table': 3,
    'source': 2,
    'pod': 4,
    'chart': 5,
    'dashboard': 6,
    'rule': 6,
    'visualize': 6,
    'flow': 5,
    'alert': 6,
    'scheduler': 7,
    'user': 2,
    'group': 1
}
let xy_pos = [[50, 0], [50, 250], [50, 500], [50, 750], [50, 1000], [50, 1250], [50, 1500], [50, 1750]];
const GraphPage = ({ pk, model, connectionName }) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [elements, setElements] = useState([]);
    const [clickedNode, setClickedNode] = useState("");
    const [lineageName, setLineageName] = useState("");
    const [empty, setEmpty] = useState(false);
    const allElements = useRef([]);
    const store = useStore();

    useEffect(() => {
        createNodesAndEdges();

        return () => {
            xy_pos = [[50, 0], [50, 250], [50, 500], [50, 750], [50, 1000], [50, 1250], [50, 1500], [50, 1750]];

        }

    }, [])

    useEffect(() => {
        colorSelectedEdges();
    }, [clickedNode])

    useEffect(() => {
        if (lineageName) {
            let title = generateHtmlTitle(lineageName?.toUpperCase(), 'Lineage')
            document.title = title
        }
    }, [lineageName])



    const linkDomain = (Elements, podId) => {
        //node which is clicked
        let selectedNode = Elements.filter(item => item.id == podId || item.id == clickedNode?.node_id)[0];
        //his source node
        let nodeId = podId ?? clickedNode?.node_id
        let sourceNodeId = Elements.filter(item => item.target == nodeId && item.source?.includes("dndnode"))[0];
        //the domain node
        let domainId = Elements.filter(item => item?.item?.desc?.toLowerCase()?.includes("domain") && item?.item?.name == selectedNode?.item?.domain)[0];

        //connection tables connected to domain
        let domainConnectionTables = Elements.filter(item => item?.source == domainId?.id);
        //connection tables connected to source
        let sourceConnectionTables = Elements.filter(item => item?.target == sourceNodeId?.source);

        //finding the common connection tables
        let commonNodes = [domainId?.id];
        let commonEdges = [];
        if (podId) {
            commonEdges.push(sourceNodeId?.id);
        }

        domainConnectionTables.forEach(domainEdge => {
            sourceConnectionTables.forEach(source => {
                if (domainEdge.target == source.source) {
                    commonNodes.push(source.source);
                    commonEdges.push(source.id);
                    commonEdges.push(domainEdge.id);
                }
            })
        })

        let tempElements = Elements;
        tempElements.forEach(item => {
            if (commonNodes.includes(item.id)) {

                item.data = {
                    label: (
                        <>
                            <LineageCard headerColor={COLOR_MAPPING[item?.item?.desc]} nodeDatum={{ ...item.item, podId: item.item?.podId }} isConnected={true} setClickedNode={setClickedNode} loadData={loadData} />
                        </>
                    )
                }
            }
            else if (commonEdges.includes(item.id)) {
                item.style = { stroke: "#2C83C4", strokeWidth: "2" }

            }
        })

        //    allElements.current=[...tempElements];
        //    setElements([...tempElements]);
    }

    const sortElements = (data) => {

        let temp = [...data];
        for (let i = 0; i < temp.length; i++) {

            for (let j = 0; j < temp.length - i - 1; j++) {
                if (temp[j]?.style?.stroke == "#2C83C4") {
                    let buffer = { ...temp[j] };
                    temp[j] = { ...temp[j + 1] };
                    temp[j + 1] = { ...buffer };
                }
            }
        }
        return temp;
    }

    const colorParentsOnly = (elements, connectedNodes, nodeId) => {

        elements.forEach(item => {

            if (item.source && item.target) {
                if (item.target == nodeId) {
                    item.style = { stroke: "#2C83C4", strokeWidth: "2" }
                    connectedNodes.push(item.source)
                }

            }

        })

    }


    const resetEdge = (elements) => {
        elements.forEach(item => {

            if (item.source) {

                item.style = { stroke: "#b5b5b5", strokeWidth: '1' }
                item.animated = false;



            }

        })
    }


    const resetEdgeColors = () => {
        let tempElements = [...elements];


        resetEdge(tempElements);
        tempElements.forEach((item) => {


            item.data = {
                label: (
                    <>
                        <LineageCard headerColor={COLOR_MAPPING[item?.item?.desc]} nodeDatum={{ ...item.item, podId: item.item?.podId }} isConnected={false} setClickedNode={setClickedNode} loadData={loadData} />
                    </>
                )
            }

        })
        setElements([...tempElements]);
    }
    const colorSelectedEdges = () => {

        let tempElements = [...elements];

        let connectedNodes = [];
        let direction = [];

        resetEdge(tempElements);



        tempElements.forEach(item => {

            if (item.source) {
                if (item.source == clickedNode?.node_id || item.target == clickedNode?.node_id) {
                    item.style = { stroke: "#2C83C4", strokeWidth: "2" }
                    connectedNodes.push(item.source == clickedNode?.node_id ? item.target : item.source)
                    direction.push(item.source == clickedNode?.node_id ? "down" : "up")
                }

            }

        })



        tempElements.forEach((item) => {
            if (connectedNodes.includes(item.id)) {


                item.data = {
                    label: (
                        <>
                            <LineageCard headerColor={COLOR_MAPPING[item?.item?.desc]} nodeDatum={{ ...item.item, podId: item.item?.podId }} isConnected={true} setClickedNode={setClickedNode} loadData={loadData} />
                        </>
                    )
                }
            }
            else if (item.data) {


                item.data = {
                    label: (
                        <>
                            <LineageCard headerColor={COLOR_MAPPING[item?.item?.desc]} nodeDatum={{ ...item.item, podId: item.item?.podId }} isConnected={false} setClickedNode={setClickedNode} loadData={loadData} />
                        </>
                    )
                }
            }
        })
        if (clickedNode.desc == "pod")
            linkDomain(tempElements);
        if (["source", "alert", "flow", "chart", "dashboard", "group"].includes(clickedNode.desc)) {
            let temp = [...connectedNodes];
            temp.forEach((item, index) => {
                if (direction[index] == "up") {
                    if (clickedNode?.desc == "source")
                        colorParentsOnly(tempElements, connectedNodes, item);
                    else
                        linkDomain(tempElements, item)

                }
            })
        }


        let sorted = sortElements(tempElements);

        setElements([...sorted])

    }

    const isPresent = (item, type) => {
        let selectedNode = allElements.current.filter(element => type == "source" ? item.source && item.source == element.item?.id : (item.name && element.item?.name && element.item?.name === item.name && (element?.item?.desc == type)));

        return selectedNode[0];

    }


    const loadData = async (pk, model, node_id, up, connection) => {
        let side = up ? "PARENT" : "CHILD";

        let data = await getLineageData(pk, model, side, model?.toLowerCase() == "source" ? connection : null);
        let current_row = position[model.toLowerCase()]
        data = data.data;
        let nodes_arr = [];
        let edges_arr = [];



        if (data.pod?.length > 0) {
            data.pod.forEach(item => {
                if (data.data_domain)
                    data.data_domain.forEach(domain => {
                        if (domain.pod == item.name)
                            item.domain = domain.name;
                    })
            })
            let temp = [];
            data.data_domain.forEach((item) => {
                if (!isPresent(item, "data_domain")) {
                    temp.push(item)
                }
            })
            data.data_domain = temp;

        }
        if (model.toLowerCase() !== "connection") {

            data.data_domain = [];


        }
        let chartPodId = allElements.current.find((item) => item.item?.desc === 'pod')?.item?.id;

        allElements.current.forEach((item) => {


            if (item.target) {
                edges_arr.push({ ...item })
            }
            else {


                nodes_arr.push({
                    ...item,
                    data: {
                        label: (
                            <>
                                <LineageCard ChartPodId={chartPodId} headerColor={COLOR_MAPPING[item?.item?.desc]} nodeDatum={{ ...item.item, podId: data?.pod_id }} setClickedNode={setClickedNode} loadData={loadData} />
                            </>
                        )
                    },

                })
            }
        })

        TYPES.forEach((type) => {

            if (data[type]) {
                let temp = data[type];


                temp.forEach((item, index) => {

                    let selectedNode = isPresent(item, type)

                    if (selectedNode) {

                        let node_row = position[type];


                        edges_arr.push({
                            id: getEdgeId(),
                            source: node_row < current_row ? selectedNode.id : node_id,
                            target: node_row < current_row ? node_id : selectedNode.id,
                            type: "bezier edge",


                        })
                    }
                    else {
                        let sourceName = "";

                        if (type == "source") {
                            if (!item.source)
                                return;
                            sourceName = item.source.split("/").slice(-1)[0];
                            item.name = sourceName;
                            item.id = item.source;

                        }


                        let id = getId();
                        let node_row = position[type];

                        nodes_arr.push({
                            id: id,
                            type: "default",
                            item: { ...item, node_id: id, desc: type, id: item.id ?? item.name, podId: data?.pod_id },
                            data: {
                                label: (
                                    <>
                                        <LineageCard headerColor={COLOR_MAPPING[type]} nodeDatum={{ ...item, desc: type, node_id: id, id: item.id ?? item.name, podId: data?.pod_id }} setClickedNode={setClickedNode} loadData={loadData} />
                                    </>
                                )
                            },
                            position: { x: xy_pos[position[type]][0], y: xy_pos[position[type]][1] }
                        })
                        edges_arr.push({
                            id: getEdgeId(),
                            source: node_row < current_row ? id : node_id,
                            target: node_row < current_row ? node_id : id,
                            type: "bezier edge",

                        })
                        xy_pos[position[type]][0] += 300;
                    }
                })
            }
        })
        let temp = [...nodes_arr, ...edges_arr]

        resetEdge(temp);
        allElements.current = [...temp]
        setElements([...temp]);

    }


    const createNodesAndEdges = async () => {

        let data = await getLineageData(model == "Source" ? pk.replaceAll("__", "/") : pk, model, model == "Domain" ? "CHILD" : "PARENT", model == "Source" ? connectionName : null);




        let current_row = position[model?.toLowerCase()];
        data = data.data;
        if (_.isEmpty(data)) {
            setEmpty(true);
            return;
        }

        const isPodOfPod = data.type == "POD of PODs";
        let nodes_arr = [];
        let edges_arr = [];
        let source_id = getId();

        if (data.pod?.length > 0) {
            data.pod.forEach(item => {
                if (data.data_domain)
                    data.data_domain.forEach(domain => {
                        if (domain.pod == item.name)
                            item.domain = domain.name;
                    })
            })
            let temp = [];
            data.data_domain.forEach((item) => {
                if (!isPresent(item, "data_domain")) {
                    temp.push(item)
                }
            })
            data.data_domain = temp;

        }
        if (model == "Source") {
            data.id = data?.name;

            data.name = data?.name?.split("/")?.slice(-1)[0];

        }


        nodes_arr.push({

            id: source_id,
            type: "default",
            item: {

                connection: model == "Source" ? connectionName : null,
                name: data.name, desc: model.toLowerCase() == "domain" ? "data_domain" : model.toLowerCase(), id: data.id,
                node_id: source_id,
                type: data.type,
                podId: data?.pod_id,

            },
            data: {
                label: (
                    <>
                        <LineageCard origin={true} headerColor={COLOR_MAPPING[model.toLowerCase() == "domain" ? "data_domain" : model.toLowerCase()]} nodeDatum={{
                            name: data.name, connection: model == "Source" ? connectionName : null,
                            podId: data?.pod_id,
                            desc: model.toLowerCase() == "domain" ? "data_domain" : model.toLowerCase(), id: data.id ?? data.name, node_id: source_id, type: data.type
                        }} setClickedNode={setClickedNode} loadData={loadData} />
                    </>
                )
            },
            position: { x: xy_pos[position[model.toLowerCase()]][0], y: xy_pos[position[model.toLowerCase()]][1] }
        })

        xy_pos[position[model.toLowerCase()]][0] += 300;

        if (model.toLowerCase() !== "connection") {
            data.data_domain = [];
        }


        TYPES.forEach((type) => {

            if (data[type]) {
                let temp = data[type];
                temp.forEach((item, index) => {
                    let id = getId();
                    let node_row = position[type];
                    let sourceName = "";
                    if (type == "source") {
                        sourceName = item.source?.split("/")?.slice(-1)[0] ?? item.name;
                        item.name = sourceName;
                        item.id = item.source ?? item.id;
                        item.podId = data?.pod_id;

                        item.desc = isPodOfPod ? "pod" : "source"


                    }

                    nodes_arr.push({
                        id: id,
                        type: "default",
                        item: { ...item, node_id: id, desc: isPodOfPod ? "pod" : type, id: item.id ?? item.name, podId: data?.pod_id, },
                        data: {
                            label: (
                                <>
                                    <LineageCard headerColor={COLOR_MAPPING[isPodOfPod ? "pod" : type]} nodeDatum={{ ...item, desc: isPodOfPod ? "pod" : type, node_id: id, id: item.id ?? item.name, podId: data?.pod_id }} setClickedNode={setClickedNode} loadData={loadData} />
                                </>
                            )
                        },
                        position: { x: xy_pos[position[type]][0], y: xy_pos[position[type]][1] }
                    })
                    edges_arr.push({
                        id: getEdgeId(),
                        source: node_row < current_row ? id : source_id,
                        target: node_row < current_row ? source_id : id,
                        type: "bezier edge",

                    })
                    xy_pos[position[type]][0] += 300;

                })

            }
        })

        allElements.current = [...nodes_arr, ...edges_arr];
        setLineageName(data.name);
        setElements([...allElements.current]);
        loadData(model == "Source" ? pk.replaceAll("__", "/") : pk, model, source_id, false, model.toLowerCase() == "source" ? connectionName : null)
    }
    const getLineageData = async (pk, model, direction, connection) => {
        let response;
        let tempModel = model == "data_domain" ? "domain" : model.toLowerCase()
        response = await dispatch(retreiveLineageData(pk, tempModel, direction, connection))
        return response;




    }

    return (
        <>
            <div onClick={resetEdgeColors}>
                <div style={{ borderBottom: '1px solid #ebebeb' }} className="align-items-center d-flex justify-content-between padding-2 py-1">

                    <div className="pod-meta-container">
                        <div className="pod-meta-header">
                            <div className="pod-meta-headings d-flex">
                                <p className="subtitle m-0 text-uppercase d-flex align-items-center"> {MODEL_ICON_MAPPING[model.toLowerCase()]} <span className="mx-2 summary-pod-database">{model.toLowerCase() === 'flow' ? 'flows' : model.toLowerCase() === 'group' ? 'usergroup' : model.toLowerCase() === 'domain' ? 'data domain' : model.replaceAll('_', ' ')}</span> <RIGHTARROW /></p>
                                <p className="subtitle m-0 d-flex align-items-center ml-2"> <span className="summary-pod-ellipsis">{lineageName?.toUpperCase()}</span> </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='d-flex'>
                            <Buttons
                                props={{
                                    tooltip: 'Close',
                                    tooltipPlacement: "left",
                                    buttonText: '',
                                    buttonClassName: "custom-btn custom-btn-outline btn-with-icon ml-2",
                                    buttonEvent: () => {
                                        let path = ""
                                        path = URL_MAPPING[model.toLowerCase()];
                                        
                                        if (model != "Connection" && model != "Chart" && model?.toLowerCase() != 'dashboard') {
                                            path += pk.toString();
                                        }
                                        else if (model === "Connection") {
                                            path += `?connection_name=${pk}&open=true`
                                        }
                                        else if (model === "Chart") {
                                            path += window.location?.search?.split('=')[1] + '#charts'
                                        }
                                        if (path) {
                                            history(path);
                                        }
                                        // if (history.length == 1) {
                                        //     // history.goBack();

                                        //     path = URL_MAPPING[model.toLowerCase()]


                                        //     history(path);
                                        // } else {
                                        //     // history.goBack();
                                        //     history(`/lineage?type=${TYPE_MAPPING[model.toLowerCase()]}`)

                                        // }
                                    },
                                    ImgSrc: () => <CLOSE />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_SECONDARY
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {!empty ? <Graph elements={elements} model={model} resetEdgeColors={resetEdgeColors} /> : <NoDataComponent
                message={`NO DATA FOUND FOR ${model.toUpperCase()}`} />}

        </>)


}


export default GraphPage;