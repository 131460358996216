import React, { Fragment, useState } from 'react';

import "./CreateButton.scss";

import { Literals } from "../../common/literals";
import Buttons from '../../../../components/Common/button/Buttons'

import { PLUS} from '../../../Common/iconSource';

const CreateButton = ({ openConnectionModal,title, isDisabled = false }) => {
 

  const [isButtonActive, setIsButtonActive] = useState(false);

  const toggleButtonHandler = () => {
    setIsButtonActive((isButtonActive) => !isButtonActive);
  };

  const closeButtonHandler = () => {
    setIsButtonActive(false);
  };

  const openConnectionModalHandler = () => {
    openConnectionModal();
  };

  return (
    <Fragment>
      {isButtonActive && (
        <div
          className="rotate-button-backdrop"
          onClick={openConnectionModalHandler}
        ></div>
      )}
      <div className="options-wrapper">
        <Buttons
          props={{
            buttonText: "",
            buttonClassName: `custom-btn-primary custom-btn fixed-button addbutton_plusicon ${
              isButtonActive ? "rotate-icon" : ""
            }`,
            buttonEvent: () => {
              openConnectionModalHandler();
            },
            tooltip:  title,
            ImgSrc: () => <PLUS />,
            isDisable: isDisabled,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
        <ul
          className={`add-options-list ${isButtonActive ? "active-list" : ""}`}
        >
          
         
        </ul>
      </div>
    </Fragment>
  );
};

export default CreateButton;
