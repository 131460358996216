import React from 'react';
import { Tooltip, DatePicker } from "antd";
import { Literals } from "../../common/literals";
import { useDispatch } from 'react-redux'
import SearchInput from '../../../Common/search/Search';
import Buttons from '../../../Common/button/Buttons';
import { BELLALERT, BLUE_DASHBOARD, FILTER, LISTVIEW, NOTIFICATION, PLUS, TABVIEW } from '../../../Common/iconSource';
import { openFilter } from '../../../../store/modules/alert/alertLanding/alertLandingAction';
import { setViewMode } from '../../../../store/app/appAction';

import { openModel } from '../../../../store/actions';
import {
    ALERT
} from "../../../Common/iconSource";
import moment from 'moment';

const { RangePicker } = DatePicker
const currentDate = moment(new Date()).format("YYYY-MM-DD");

const AlertLandingHeader = ({ 
    searchValue,
    isFilterApplied, 
    activeTab, 
    setActiveTab, 
    datePickerValue, 
    setstartDate, 
    setendDate, 
    setSearchValue, 
    fromRoute, 
    layout, 
    setLayout, 
   
    toggleModalHandler,
    isDisableAllOptions = false
}) => {
    const dispatch = useDispatch();


    const tabsMenuList = [{ title: 'Alerts', key: 'alerts', icon: () => <ALERT />, onClickEvent: (key) => { setActiveTab(key);setSearchValue("") } }, { title: 'Notifications', key: 'myalerts', icon: () => <span className='noti-icon-alerts'><NOTIFICATION/></span>, onClickEvent: (key) => { setActiveTab(key) ; setSearchValue("")} }]
    const defaultPickerValue = [moment(new Date()).subtract(1, 'month'), moment(new Date())]

    const toggleBtnOption = [
        { label: <TABVIEW />, value: 'grid' },
        { label: <LISTVIEW />, value: 'list' },
    ];
    const setView = (view) => {
        dispatch(setViewMode({module:"rule",
        view_type: view})).then(()=>{
            setLayout(view);

        })
    }
    const onCalendarChange = (dates, dateString, info) => {
        const currentDate = new Date();
        if (dateString[0] && dateString[1]) {
            let startDate = dateString[0];
            let endDate = dateString[1];
         

            setstartDate(startDate)
            setendDate(endDate)

        }
    };

    let AlertTabsList = []

    if (fromRoute || fromRoute === 'scheduler') {
        AlertTabsList = tabsMenuList.slice(0, 1)
    } else {
        AlertTabsList = tabsMenuList

    }



    return (
        <div className="sub-header">
            <div className="sub-header-left ">
                <div className="tab-menu d-flex">
                    {AlertTabsList.map((menu, i) =>
                        <p key={`${i}-${menu}`} onClick={() => {;setSearchValue(""); setActiveTab(menu.key); menu.onClickEvent(menu.key) }} className={activeTab?.toLowerCase() === menu?.key?.toLowerCase() ? 'active subtitle' : 'subtitle'}>{menu.icon()} {menu.title}</p>

                    )}
                </div>

                {/* <span className = 'tab-look'><BLUE_DASHBOARD /> <span>Alerts</span></span>
                <span className = 'tab-look'><BLUE_DASHBOARD /> <span>My Alerts</span></span> */}
            </div>
            <div style={{ justifyContent: "flex-end" }} className="sub-header-right d-flex align-items-center">


                <Tooltip placement="topLeft" title={'Search Alert'}><SearchInput searchData={searchValue} isDisable={isDisableAllOptions} setSearchData={setSearchValue} /></Tooltip>
                {activeTab == "alerts" ?

                    <>
                        <div style={{ position: 'relative' }}><Tooltip placement="topLeft" title={Literals.ICON_FILTER}>
                            <Buttons props={{
                                buttonText: '', buttonClassName: `${isFilterApplied ? 'rdg-filter-active' : ''}  custom-btn-outline custom-btn btn-with-icon ml-2 mr-2`, buttonEvent: () => {
                                    dispatch(openFilter(true));
                                    dispatch(openModel('filter'))
                                }, ImgSrc: () => <FILTER />, isDisable: isDisableAllOptions, buttonType: Literals.BTN_TERTIARY
                            }} /></Tooltip>
                        </div>
                        {/* <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: layout, buttonClassName: "toggle-btn custom-btn btn-with-icon ", buttonEvent: setView, ImgSrc: () => <></>, isDisable: isDisableAllOptions, buttonType: Literals.BTN_TOGGLE }} /> */}
                        {!fromRoute || fromRoute !== 'scheduler' ? <Buttons props={{
                            buttonText: '',
                            buttonClassName: "custom-btn-primary custom-btn fixed-button addbutton_plusicon",
                            buttonEvent: () => {
                                toggleModalHandler()
                            },
                            tooltip: 'Add Alert',
                            ImgSrc: () => <PLUS />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                        }} /> : ''}
                    </> :
                    <RangePicker className='col-8 w-100  ml-2 mr-2' defaultValue={datePickerValue}  onChange={(e) => {
                        if (e == null) {
                            setstartDate("");
                            setendDate(currentDate);
                        }
                    }} onCalendarChange={onCalendarChange} />


                }

            </div>

        </div>
    )
}

export default AlertLandingHeader;