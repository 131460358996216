import Markdown from 'markdown-to-jsx'
import React from 'react'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash';
import { getObjectLength } from '../modules/common/helperFunctions';

const ChartComponent = ({
    option = {}
}) => {
    
    const getChart = () => {

        let parsed;

        try {
            parsed = JSON?.parse(option)
        }
        catch(error) {
            console.error('Invalid JSON:', error);
            parsed = {}
        }

        try {
            if(_.isObject(parsed) && getObjectLength(parsed))
            return <ReactEcharts
                option={parsed}
                style={{ width: '100%', height: '16rem' }}
                opts={{ renderer: 'canvas' }}
            />
            return ''
        }
        catch(err) {
            console.log("MD Chart ERROR::",{err, option})
            return ''
        }
    }

    return <div className='md-chart'>
        {getChart()}
    </div>

}

const MarkdownText = ({
    markdown = ''
}) => {

    return <Markdown
        options={{
            overrides: {
                ChartComponent: ChartComponent,
            }
        }}
    >
        {markdown ?? ''}
    </Markdown>
}

export default MarkdownText