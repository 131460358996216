const SET_SCHEMA_TREE_MAP = "SET_SCHEMA_TREE_MAP";
const SET_CONNECTION_DETAILS = "SET_CONNECTION_DETAILS";
const SET_TARGET_TABLE_DETAILS = "SET_TARGET_TABLE_DETAILS"
const RESET_TARGET_REDUX = "RESET_TARGET_REDUX"
const SET_TARGET_POD_CREATION_DATA = "SET_TARGET_POD_CREATION_DATA"
const SET_SCHEMA_DIRECTORY_TREE_MAP = "SET_SCHEMA_DIRECTORY_TREE_MAP"
export const setSchemaTreeMap = (payload) => {
    return {
        type: SET_SCHEMA_TREE_MAP,
        payload: payload
    }
}

export const setSchemaDirectoryTreeMap = (payload) => {
    return {
        type: SET_SCHEMA_DIRECTORY_TREE_MAP,
        payload: payload
    }
}

export const resetTargetRedux = (payload) => {
    return {
        type: RESET_TARGET_REDUX,
        payload: payload
    }
}
export const setConnectionDetails = (data) => {
    return {
        type: SET_CONNECTION_DETAILS,
        payload: data
    }
}
export const setTargetTableDetails = (data) => {
    return {
        type: SET_TARGET_TABLE_DETAILS,
        payload: data
    }
}


export const setTargetPodCreationData = (data) => {

    return {
        type: SET_TARGET_POD_CREATION_DATA,
        payload: data
    };
};

const initialState = {
    connectionDetails: [],
    schemaTreeMap: {},
    podCreationData: {},
    targetTableDetails: {
        schemaName: '',
        tableName: '',
        targetColumns: [],
    },
    explorerTreeData: [],
    selectedFolder: [],
    explorerExpandedKeys: [],

    schemaDirectoryTreeMap: {},
    explorerDirectoryTreeData: [],
    selectedDirectoryFolder: [],
    explorerDirectoryExpandedKeys: [],

}

export const TargetTransformer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_TARGET_POD_CREATION_DATA:
            return {
                ...state,
                ...payload,
            }


        case SET_SCHEMA_TREE_MAP:
            return {
                ...state,
                schemaTreeMap: payload
            };
        case SET_SCHEMA_DIRECTORY_TREE_MAP:
            return {
                ...state,
                schemaDirectoryTreeMap: payload
            };
        case SET_CONNECTION_DETAILS:
            return {
                ...state,
                connectionDetails: payload
            };
        case SET_TARGET_TABLE_DETAILS:
            return {
                ...state,
                targetTableDetails: payload
            };
        case RESET_TARGET_REDUX:
            initialState.targetTableDetails.schemaName = ''
            initialState.targetTableDetails.tableName = ''
            initialState.targetTableDetails.targetColumns = []

            return initialState
        default:
            return state;
    }
}
