import { Switch, Tooltip } from 'antd';
import copy from 'copy-to-clipboard';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { createNewOutboundConnection, deleteOutboundConnection, getOutboundConnections, updateOutboundConnection } from '../../../../../store/modules/UserManagement/connection/connectionActions';
import Buttons from '../../../../Common/button/Buttons';
import { CHECK, CLOSE, CONNECTIONS, COPY, DELETEICON, DOWNLOAD, EDIT, EYECLOSE, EYEOPEN, OUTBOUND, PLUS, USERACTIVE, USERINACTIVE } from '../../../../Common/iconSource';
import { LandingSimpleTable } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { CustomSkeleton } from '../../../../Common/skeleton/CustomSkeleton';
import ActionPopUp from '../../../common/actionPopUp';
import {  ErrorComponent, getRelativeTime, NoDataComponent } from '../../../common/helperFunctions';
import { Literals } from '../../../common/literals';
import ConnectionGuideModal from './ConnectionGuideModal';

const OutboundConnections = forwardRef((props, ref) => {

    const { setAddButtonDisable = () => { }, isAddButtonDisable } = props

    const dispatch = useDispatch()
    const store = useStore()

    const [data, setData] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [showAccessKey, setShowAccessKey] = useState(false)
    const [showSecurityKey, setShowSecurityKey] = useState(false)
    const [connectionDetails, setConnectionDetails] = useState({})
    const [isConnectionCreated, setConnectionCreated] = useState(false)
    const [isEditing, setEditing] = useState(false)
    const [inputName, setInputName] = useState('')
    const [empty, setEmpty] = useState(false);
    const [inputDesc, setInputDesc] = useState('')
    const [accessKeyCopied, setAccessKeyCopied] = useState(false)
    const [secretKeyCopied, setSecretKeyCopied] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const [errMsg, setErrMsg] = useState('');
    
    useEffect(() => {
        getOutboundConnectionsList()
        return () => {
            setErrMsg('')
        }
    }, [])





    useEffect(() => {
        if (accessKeyCopied) {
            setTimeout(() => setAccessKeyCopied(false), 2000)
        }
        if (secretKeyCopied) {
            setTimeout(() => setSecretKeyCopied(false), 2000)
        }
    }, [secretKeyCopied, accessKeyCopied])

    useEffect(() => {
        if (!openModal) {
            setConnectionCreated(false)
            setEditing(false)
            setConnectionDetails({})
        }
    }, [openModal])

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setOpenModal(true)
        },
        closeModal: () => {
            setOpenModal(false)
        }
    }), []);

    const getOutboundConnectionsList = () => {
        dispatch(getOutboundConnections())
            .then(resp => {
                if (resp.status === "success") {
                    if (resp.data.length == 0)
                        setEmpty(true);
                    createListData(resp.data)
                    setAddButtonDisable(stopAddConnectionFlag(resp.data))
                    if (resp.data.length === 0) {
                        setData(['null'])
                    }
                }
            })
            .catch(e => {
                setErrMsg(e.message)
                emitToastNotification('error', e)
            })
    }

    const stopAddConnectionFlag = (d) => {
        let userDetails = store.getState().UserReducer?.user?.userDetails
        return d.filter(v => v.created_by_user.email === userDetails.email).length === 2
    }

    const createOutboundConnection = (e) => {
        e.preventDefault()
        if (isConnectionCreated) {
            setInputName('')
            setInputDesc('')
            setConnectionDetails({})
            setConnectionCreated(false)
            setOpenModal(false)
        }
        else {
            let payload = {
                name: inputName,
                description: inputDesc
            }
            dispatch(createNewOutboundConnection(payload))
                .then(resp => {

                    if (resp.status === "success") {
                        emitToastNotification('success', resp?.message)
                        let conDetails = { ...connectionDetails }
                        conDetails['access_key'] = resp?.data?.access_key
                        conDetails['secret_key'] = resp?.data?.secret_key
                        setConnectionDetails({ ...conDetails })
                        setConnectionCreated(true)
                        getOutboundConnectionsList()
                        setEmpty(false)
                    }
                })
                .catch(e => {

                })
        }
    }

    const deleteConnection = (id) => {
        dispatch(deleteOutboundConnection(id))
            .then(resp => {
                if (resp.status === "success") {
                    emitToastNotification('success', resp?.message)
                    getOutboundConnectionsList()
                    setOpenDeletePopup(false)
                }
            })
            .catch(() => {
                emitToastNotification('error', Literals.UNABLE_TO_DELETE_CREDENTIAL)
            })
    }

    const updateConnection = (id, e) => {
        e.preventDefault()
        let payload = {
            name: inputName,
            description: inputDesc,
            active: connectionDetails.active
        }
        dispatch(updateOutboundConnection(payload, id))
            .then(resp => {
                if (resp.status === "success") {
                    emitToastNotification('success', resp?.message)
                    getOutboundConnectionsList()
                    setOpenModal(false)
                }
            })
            .catch(e => {
                emitToastNotification('error', e)

            })
    }

    const openDeleteDialog = (record) => {
        setDeletePopUpContent({
            modalClass: "delete-popup",
            heading: "Delete",
            message: <>
                <p className='mb-0'>Delete <strong>{record?.name}</strong> connection ?</p>
            </>,
            button: [
                {
                    name: "Delete",
                    action: () => {
                        deleteConnection(record?.id);
                    },
                },
            ],
            elements: [],
            closeActionPopup: () => setOpenDeletePopup(false),
        });
        setOpenDeletePopup(true);
    };

    const handleUpdate = (record) => {
        setConnectionDetails(record)
        setInputName(record?.name)
        setInputDesc(record?.description)
        setEditing(true)
        setOpenModal(true)
    }

    const handleActive = (isChecked) => {
        let temp = { ...connectionDetails }
        temp['active'] = isChecked
        setConnectionDetails({ ...temp })
    }

    const createListData = (data) => {
        let temp = [...data]
        data && data.length && data.forEach((el, index) => {
            temp[index]['ui_is_active'] = <div className='d-flex align-items-center h-100 out-conn-status'>
                {
                    el?.active ? <USERACTIVE /> : <USERINACTIVE />
                }
            </div> 

            temp[index]['data'] = el
            temp[index]['name_to_view'] = <Tooltip className='text-with-ellipsis' title={el?.name} placement={'topLeft'}>
                {el?.name}
            </Tooltip>
            temp[index]['created_on'] = <Tooltip placement="top" title={getRelativeTime(el?.created_on, undefined)}>
                                            {getRelativeTime(el?.created_on)}
                                        </Tooltip>
            temp[index]['user_email'] = el?.created_by_user?.email
            temp[index]['user_fname'] = el?.created_by_user?.first_name
            temp[index]['user_lname'] = el?.created_by_user?.last_name
            temp[index]['user_id'] = el?.created_by_user?.id
            temp[index]['user_username'] = el?.created_by_user?.username
            temp[index]['custom_action'] = <div className='d-flex align-items-center h-100 justify-content-center'>
                <button onClick={(e) => { e.stopPropagation(); handleUpdate(el) }} className='custom-btn custom-btn-outline mr-1'><EDIT /></button>
                <button onClick={(e) => { e.stopPropagation(); openDeleteDialog(el) }} className='custom-btn custom-btn-outline error-red'><DELETEICON /></button>
            </div>
            temp[index]['__data'] = el
        })

        setData([...temp])
    }

    const columns = [
        {
            name: 'Credential Name',
            key: "name_to_view",
            resizable: false,
        },
        {
            name: 'Description',
            key: "description",
            resizable: false,
            width: 300,
        },
        {
            name: 'Status',
            key: "ui_is_active",
            resizable: false,
            sortable: false
        },
        {
            name: 'Created On',
            key: "created_on",
            resizable: false
        },
        {
            name: 'User',
            key: "user_email",
            resizable: false,
            width: 200,
        },
        {
            name: <div className='text-center'>Action</div>,
            key: "custom_action",
            resizable: false,
            sortable: false,
            width: 180
        }
    ];

    const getComparator = (sortColumn) => {
        switch (sortColumn) {
            case 'name_to_view':
                return (a, b) => a['name'] !== undefined ? a['name'].localeCompare(b['name']) : null
            case 'description':
            case 'created_on':
                return (a, b) => a?.__data?.created_on?.localeCompare(b?.__data?.created_on)??null
            case 'user_email':
                return (a, b) => a[sortColumn] !== undefined ? a[sortColumn].localeCompare(b[sortColumn]) : null
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }

    const onRowClick = (record) => {
        setConnectionDetails(record)
        setInputName(record?.name)
        setInputDesc(record?.description)
        setEditing(null)
        setOpenModal(true)
    }

    const d = new Blob([`ACCESS KEY: ${connectionDetails?.access_key?.toString()} \nSECRET KEY: ${connectionDetails?.secret_key?.toString()}`], { type: 'text/plain' })

    const downloadLink = window.URL.createObjectURL(d)

    return (
        <>
            <div className='padding-1 outbound-conn-wrap' style={{ height: 'calc(100% - 3.75rem)' }}>
                {
                    // data[0] === 'null' || data[0]==null ?
                    // <div className='p-5 text-center'>
                    //     <div className='mb-2'>
                    //         <span className='no-ob-conn'>
                    //             <OUTBOUND/>
                    //         </span>
                    //     </div>
                    //     <p className='h5 text-black-50'>No Outbound Connection Found</p>
                    //     <label className='text-black-50'>Click on '+' to add</label>
                    // </div> :
                    errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> :
                    empty ?
                        <NoDataComponent logo={<OUTBOUND width="60" height="60" color="black" />}
                            message=" Click On (+) To Create Outbound Connection" /> :
                        data.length
                            ? <LandingSimpleTable
                                cols={columns}
                                rowsData={data}
                                onRowClick={onRowClick}
                                getComparator={getComparator}
                                showCursor={false}
                                tableHeight={(data?.length * 41) + 41}
                            />
                            : <>
                                {
                                    [...Array(2)].map((v, i) =>
                                        <div key={`col-skeleton-row-${v}${i}`} className="row mb-2 mx-n2">
                                            {['2', '2', '2', '2', '2', '2'].map((col, i) =>
                                                <div key={`col-skeleton-${i}`} className={`col-${col} px-2`}>
                                                    <CustomSkeleton
                                                        height="41"
                                                        width="100%"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            </>
                }
                {
                    isAddButtonDisable
                        ?
                        <div className='py-3 small text-black-50 text-center'>Note: Only 2 Connections Are Allowed</div> :
                        ''
                }
            </div>
            <Modal style={{ width: 400 }} size="xs" isOpen={openModal} className="custom-modal" centered={true} onClosed={() => { setOpenModal(false); setConnectionDetails({}) }}>
                <ModalHeader className='d-flex align-items-center' close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />}>
                    <div>
                        <div className='d-flex out-bound-conn-create'>
                            <CONNECTIONS />
                            {
                                isEditing === null
                                    ? <h2 className="section-heading" style={{lineHeight: '1.4'}}>Outbound Connection Details</h2>
                                    : <h2 className="section-heading" style={{lineHeight: '1.4'}}>{isEditing ? 'Update' : 'Create'} Outbound Connection</h2>
                            }
                        </div>
                    </div>
                </ModalHeader>
                <form onSubmit={(e) => isEditing ? updateConnection(connectionDetails?.id, e) : createOutboundConnection(e)}>
                    <ModalBody>
                        <div className='padding-1 pt-0'>
                            <label className='font-w-600 label text-black-50' htmlFor='credentialName'>Credential Name</label>
                            <input id='credentialName'
                                required
                                type={'text'}
                                className="custom-input-field"
                                onChange={e => setInputName(e.target.value)}
                                defaultValue={connectionDetails?.name}
                                readOnly={(isConnectionCreated || isEditing === null)}
                            />
                            <label className='font-w-600 label text-black-50 mt-2' htmlFor='description'>Description</label>
                            <textarea id='description'
                                required style={{ height: 50 }}
                                className="custom-textarea-field"
                                onChange={e => setInputDesc(e.target.value)}
                                defaultValue={connectionDetails?.description}
                                readOnly={(isConnectionCreated || isEditing === null)}
                            ></textarea>
                            {
                                isEditing
                                    ? <>
                                        <label className='font-w-600 label text-black-50' htmlFor='credentialName'>Access Key</label>
                                        <p className='mb-2'>{connectionDetails?.access_key}</p>
                                    </>
                                    : ''
                            }
                            {
                                isEditing ?
                                    <>
                                        <label className='font-w-600 label text-black-50 mt-2' htmlFor='isActive'>Active</label>
                                        <Switch
                                            id='isActive'
                                            defaultChecked={connectionDetails.active}
                                            onChange={(e) => handleActive(e)}
                                            className="ml-2"
                                            size='small'
                                            disabled={isEditing === null}
                                        />
                                    </> : ''
                            }
                            {
                                isConnectionCreated ?
                                    <>
                                        <label className='font-w-600 label text-black-50 mt-2' htmlFor='accessKey'>Access Key</label>
                                        <div className='d-flex'>
                                            <div className='w-100'>
                                                {/* <span className="eye-indicator-wrap"> */}
                                                    <input id='accessKey' required type={showAccessKey ? "text" : "password"}
                                                        className="custom-input-field"
                                                        value={connectionDetails?.access_key}
                                                        readOnly={true}
                                                    />
                                                    {/* <span className="pass-indicator" onClick={() => setShowAccessKey(!showAccessKey)}>
                                                        {showAccessKey ? <EYEOPEN /> : <EYECLOSE />}
                                                    </span>
                                                </span> */}
                                            </div>
                                            <div className='ml-2 d-flex'>
                                                <Buttons props={{ actionType: 'button', buttonText: '', buttonClassName: `custom-btn-outline custom-btn`, buttonEvent: () => { setShowAccessKey(!showAccessKey) }, ImgSrc: () => <>{showAccessKey ? <EYEOPEN /> : <EYECLOSE />}</>, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                                <Tooltip title="Access Key Copied!" placement='right' trigger={'click'} open={accessKeyCopied}>
                                                    <Buttons props={{ actionType: 'button', buttonText: '', buttonClassName: `${accessKeyCopied ? 'rdg-filter-active' : ''} custom-btn-outline custom-btn`, buttonEvent: () => { copy(connectionDetails?.access_key); setAccessKeyCopied(true) }, ImgSrc: () => <><COPY /></>, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                                </Tooltip>
                                                
                                            </div>
                                        </div>
                                        <label className='font-w-600 label text-black-50 mt-2' htmlFor='securityKey'>Secret Key</label>
                                        <div className='d-flex'>
                                            <div className='w-100'>
                                                {/* <span className="eye-indicator-wrap"> */}
                                                    <input id='securityKey' required type={showSecurityKey ? "text" : "password"}
                                                        value={connectionDetails?.secret_key}
                                                        className="custom-input-field"
                                                        readOnly={true}
                                                    />
                                                    {/* <span className="pass-indicator" onClick={() => setShowSecurityKey(!showSecurityKey)}>
                                                        {showSecurityKey ? <EYEOPEN /> : <EYECLOSE />}
                                                    </span>
                                                </span> */}
                                            </div>
                                            <div className='ml-2 d-flex'>
                                                <Buttons props={{ actionType: 'button', buttonText: '', buttonClassName: `custom-btn-outline custom-btn`, buttonEvent: () => { setShowSecurityKey(!showSecurityKey) }, ImgSrc: () => <>{showSecurityKey ? <EYEOPEN /> : <EYECLOSE />}</>, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                                <Tooltip title="Secret Key Copied!" placement='right' trigger={'click'} visible={secretKeyCopied}>
                                                    <Buttons props={{ actionType: 'button', buttonText: '', buttonClassName: `${secretKeyCopied ? 'rdg-filter-active' : ''} custom-btn-outline custom-btn`, buttonEvent: () => { copy(connectionDetails?.secret_key); setSecretKeyCopied(true) }, ImgSrc: () => <><COPY /></>, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <label className='label text-warning mb-0'>
                                                <i className='bx bx-error-circle'></i> Secret Key is not accessible once the connection is created. <br />
                                                It is recommended to copy or download the keys.
                                            </label>
                                        </div>
                                    </> : ''
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {
                            isConnectionCreated ?
                                <a download='keys.txt' href={downloadLink}>
                                    <Buttons props={{ actionType: 'button', buttonText: 'Download Keys', buttonClassName: "custom-btn-outline custom-btn btn-with-text mr-2", buttonEvent: () => { }, ImgSrc: () => <DOWNLOAD />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
                                </a>
                                : ''
                        }
                        {
                            isEditing !== null
                                ?
                                <Buttons
                                    props={{
                                        actionType: 'submit',
                                        buttonText: isConnectionCreated ? 'Done' : isEditing ? 'Update' : 'Create',
                                        buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                        buttonEvent: () => { },
                                        ImgSrc: () => (isConnectionCreated || isEditing) ? <CHECK /> : <PLUS />,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />
                                : ''
                        }
                    </ModalFooter>
                </form>
            </Modal>

            <ActionPopUp
                openModal={openDeletePopup}
                actionData={deletePopUpContent}
            />
        </>
    )
})

export default OutboundConnections;