import { message, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useStore } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Label } from 'reactstrap';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { CreateAlert, getAlertAssignee, UpdateAlert } from '../../../../store/modules/alert/createAlert/createAlertAction';
import MultiSelection from '../../../Common/dropDown/MultiSelection';
import SingleSelection from '../../../Common/dropDown/SingleSelection';
import { Literals } from '../../common/literals';

import AlertSchedule from './AlertSchedule';


function AlertDetails({
    ruleDetails,
    showLogs,
    setcreateAlertClick,
    conditionType,
    setconditionType,
    retriveAlert,
    isRulesPublish,
    selectedAlertTransformer,
    retriveAlertDetails = () => { }
}) {
    const selectedAlertPodlist = useSelector(state => state.Alert.CreateAlert.selectedAlertPodList);
    const flowsAlertTransformerList = useSelector(state => state.Alert.CreateAlert?.flowsAlertTransformerList)


    const { alertId } = useParams()
    const history = useNavigate()
    let ConditionTypeList = [
        // { key: 0, Name: "Select a condition type", Type: "", value: "" },
        { key: 1, Name: "Filter Condition", Type: "", value: "filter" },
        { key: 2, Name: "Flow Condition", Type: "", value: "flow" },
        { key: 3, Name: "SQL", Type: "", value: "sql" },
    ];
    let AlertPurposeList = [
        // { key: 0, Name: "Select a alert purpose", Type: "", value: "" },
        { key: 1, Name: "Quality Alert", Type: "", value: "Quality" },
        { key: 2, Name: "Business Alert", Type: "", value: "Business" }
    ];
    let PriorityList = [
        // { key: 0, Name: "Select a priority", Type: "", value: "" },
        { key: 1, Name: "High", Type: "", value: "High" },
        { key: 2, Name: "Medium", Type: "", value: "Medium" },
        { key: 3, Name: "Low", Type: "", value: "Low" }
    ];
    const [schedule, setschedule] = useState(false)
    const [notify, setnotify] = useState(false)
    const [alertName, setalertName] = useState('')
    const [message, setMessage] = useState('Please check the alert for more details')
    const [notifySubject, setnotifySubject] = useState(null)

    const [alertNameEmpty, setalertNameEmpty] = useState(false)
    const [description, setDescription] = useState('')

    const [alertPurpose, setalertPurpose] = useState('Quality')
    const [priority, setpriority] = useState('Medium')
    const [isDisabled, setisDisabled] = useState(false)
    const [assignee, setassignee] = useState([])
    const [podIds, setpodIds] = useState([])
    const [selectedAssignee, setselectedAssignee] = useState([])
    const store = useStore()
    const [scheduleBody, setscheduleBody] = useState({
        startHour: [],
        startMinute: [],
        Day: [],
        Month: [],
        DayMonth: [],
        cron: '',
        frequency: 'daily'
    })

    // useEffect(() => {
    //     if (!notifySubject && !alertName) {

    //         setnotifySubject('Please check the alert for more details')
    //     }
    // }, [])

    useEffect(() => {
        if (alertId && window.location.href.includes('viewAlert')) {
            setisDisabled(true)
        }
    }, [alertId])

    const [apiScheduleBody, setapiScheduleBody] = useState({
        "frequency": '*',
        "startHour": '*',
        "startMinute": '*',
        "Month": '*',
        "DayMonth": '*',
        "Day": '*',
        "cron": '*'
    })

    const setScheduleBodyValue = (payload) => {

        let body = { ...scheduleBody };
        let apiBody = { ...apiScheduleBody }

        body[payload.key] = payload.arrayValue
        apiBody[payload.key] = payload.value
        setscheduleBody({ ...body })
        setapiScheduleBody({ ...apiBody })

    }
    const preventSpaceEvent = (e) => {
        // if (e.keyCode === 32) {
        //     e.preventDefault();
        // }
    }



    useEffect(() => {
        if (ruleDetails)
            setschedule(ruleDetails?.schedule)
    }, [ruleDetails])

    const getAssigneeId = () => {
        let userid = []
        assignee.forEach((ele) => {
            if (selectedAssignee.includes(ele.value)) {
                userid.push(ele.key)
            }
        })

        return userid
    }

    const assignee_user = useRef([])
    useEffect(() => {
        let selectedPodList = store.getState().Alert.CreateAlert.selectedAlertPodList;
        let pods = []
        if (selectedPodList && selectedPodList.length) {
            selectedPodList.forEach(element => {
                pods.push(element.podId)
            });
        }
        let flowId = flowsAlertTransformerList.flowId ? flowsAlertTransformerList.flowId : ''
        let children = []
        setpodIds([...pods])

        let payload = {
            'conditionType': 'pod',
            "ids": [...pods]
        }
        if (conditionType?.toLowerCase() === 'flow' && flowId !== '') {
            payload = {
                'conditionType': 'flow',
                "ids": flowId
            }
        }

        if (retriveAlert && retriveAlert.name && retriveAlert?.assignee_user?.length) {
            assignee_user.current = retriveAlert?.assignee_user;
        }
        if (payload && payload.ids?.toString()) {
            store.dispatch(getAlertAssignee(payload)).then((resp) => {

                if (resp && resp.data?.length) {
                    let users = resp.data;
                    users.forEach((ele) => {

                        children.push({ key: ele.id, Name: ele.email, Type: "", value: ele.email })

                    })
                    setassignee([...children]);

                    console.log(assignee_user.current, '164')

                    if (assignee_user.current.length) {
                        let userid = []
                        userid = assignee_user.current.map((item) => item && item.email)


                        if (userid.length) { // && userid[0] !== undefined

                            setselectedAssignee([...userid])
                        } else {
                            console.log(userid, '175')
                            setselectedAssignee([])
                        }
                    } else {
                        console.log(retriveAlert, '178')

                        setselectedAssignee([])
                    }

                }
            })
        }
        // }
        setMessage(retriveAlert?.notify_message)

        if (retriveAlert?.notify_subject === "") {

            // setnotifySubject('Please check the alert for more details')
            setnotifySubject(null)
        } else {
            setnotifySubject(retriveAlert?.notify_subject)

        }

        if (retriveAlert && retriveAlert.notify) {
            setnotify(true)

        }
        if (retriveAlert && retriveAlert.name) {
            setalertName(retriveAlert.name)
            setDescription(retriveAlert.description);
            setschedule(retriveAlert.schedule)
            // setselectedAssignee([...retriveAlert?.assignee])

        }
        retriveAlert?.purpose && setalertPurpose(retriveAlert?.purpose)
        retriveAlert?.priority && setpriority(retriveAlert?.priority)

        if (retriveAlert && retriveAlert.schedule) {
            let body = retriveAlert.schedule_body;
            let schedule_body = { ...scheduleBody };

            schedule_body['startHour'] = body?.startHour?.split(',')
            schedule_body['startMinute'] = body?.startMinute?.split(',')
            schedule_body['Month'] = body?.Month?.split(',')
            schedule_body['DayMonth'] = body?.Date?.split(',')
            schedule_body['Day'] = body?.Day?.split(',');
            schedule_body['cron'] = body?.cron;
            schedule_body['frequency'] = body.frequency

            setapiScheduleBody({ ...body })
            setscheduleBody({ ...schedule_body })
        }

    }, [retriveAlert, selectedAlertPodlist, flowsAlertTransformerList])


    useEffect(() => {
        if (isRulesPublish && alertName !== '') {
            setalertNameEmpty(false)
            setcreateAlertClick(false)

            let scheduleBody = { ...apiScheduleBody }
            if (scheduleBody.frequency === 'daily') {
                scheduleBody['Month'] = '*'
                scheduleBody['DayMonth'] = '*'
                scheduleBody['Day'] = '*'
            } else if (scheduleBody.frequency === 'custom') {
                scheduleBody['startHour'] = '*'
                scheduleBody['startMinute'] = '*'
                scheduleBody['Month'] = '*'
                scheduleBody['DayMonth'] = '*'
                scheduleBody['Day'] = '*'

            } else if (scheduleBody.frequency === 'hourly') {
                scheduleBody['startHour'] = '*'
                scheduleBody['Month'] = '*'
                scheduleBody['DayMonth'] = '*'
                scheduleBody['Day'] = '*'
            } else if (scheduleBody.frequency === 'day') {
                scheduleBody['Month'] = '*'
                scheduleBody['DayMonth'] = '*'
            }

            if (!schedule) {
                scheduleBody['frequency'] = '*'
            }


            let assignee = getAssigneeId()
            let selectedPodList = store.getState().Alert.CreateAlert.selectedAlertPodList;
            let pods = []
            if (selectedPodList && selectedPodList.length) {
                selectedPodList.forEach(element => {
                    pods.push(element.podId)
                });
            }
            if (pods.length) {
                setpodIds([...pods])
            }

            let queryResult = store.getState().Alert.CreateAlert.queryResult;
            if ((conditionType === 'flow' && selectedAlertTransformer !== '') || conditionType === 'sql' || conditionType === 'filter') {


                let payload = {
                    "pod": podIds.length ? [...podIds] : pods,
                    "name": alertName,
                    "type": conditionType,
                    "flow": conditionType === 'flow' ? store.getState().Alert.CreateAlert?.flowsAlertTransformerList?.flowId : null,
                    "alert_transformer": conditionType === 'flow' ? selectedAlertTransformer : null,
                    "query": store.getState().Alert.CreateAlert.sqlResponseQuery,
                    "assignee": assignee,
                    "description": description,
                    "priority": priority,
                    "purpose": alertPurpose,
                    "scheduled": schedule,
                    "notify": notify,
                    "notify_subject":  notifySubject ? notifySubject : alertName, //notify ? notifySubject : '',
                    "notify_message": message? message:'Please check the alert for more details',//notify ? message : '',
                    "filter_body": { selectedFlowDetails: store.getState().Alert.CreateAlert?.flowsAlertTransformerList, appliedFilters: store.getState().Alert.CreateAlert.appliedFilters, selectedAlertPodList: store.getState().Alert.CreateAlert.selectedAlertPodList, alertSqlEditorQuery: store.getState().Alert.CreateAlert.alertSqlEditorQuery, previewAlertTables: store.getState().Alert.CreateAlert.previewAlertTables },
                    "schedule_body": scheduleBody
                }
                if (payload.query !== '' && Object.keys(queryResult).length) {


                    if (window.location.href.includes('editAlert')) {
                        store.dispatch(UpdateAlert(payload, alertId)).then((resp) => {

                            if (resp && resp.status === 'success') {
                                // emitToastNotification('success', 'Alert Updated Successfully')
                                // retriveAlertDetails()
                                // history(`${Literals.links.CLARISTA_LAB}/#alert`)
                                history(`${Literals.links.ALERTS_VIEW}${alertId}`, { replace: true })

                            }
                        })
                    } else {
                        console.log(payload,notifySubject,message)
                        store.dispatch(CreateAlert(payload)).then((resp) => {

                            if (resp && resp.status === 'success') {
                                history(`${Literals.links.ALERTS_VIEW}${resp?.data?.id}`)

                                // history(`${Literals.links.CLARISTA_LAB}/#alert`)
                            }
                        })
                    }
                } else {
                    emitToastNotification('info', 'Please Generate Result')
                }
            } else if (conditionType === 'flow' && selectedAlertTransformer === '') {
                emitToastNotification('info', 'Please Select, One Alert Transformer')

            }
        }
        if (isRulesPublish && alertName === '') {
            setcreateAlertClick(false)
            emitToastNotification('error', 'Please enter alert name')
            setalertNameEmpty(true)
        }

    }, [isRulesPublish])


    return (
        <>
            <div className="form-group">
                <label className='label'>Alert Name</label>
                <input type="text" onKeyDown={(e) => preventSpaceEvent(e)} onChange={(e) => { setalertName(e.target.value); setalertNameEmpty(false) }} value={alertName} disabled={isDisabled} className={!alertNameEmpty ? "form-control" : "is-invalid form-control"} />
                {alertNameEmpty ? <span className="error">Please Enter Alert Name</span> : null}
            </div>
            <div className="form-group">
                <SingleSelection props={{
                    label: "Condition Type", className: "custom-select-dropdown", placeholder: "Select a condition type", dropDownValue: conditionType, onClickEvent: (value) => {
                        setconditionType(value)
                    }, isDisable: isDisabled, optionList: selectedAlertPodlist && selectedAlertPodlist.length > 1 ? ConditionTypeList.slice(1, conditionType.length) : ConditionTypeList
                }} />
            </div>
            <div className="form-group">
                <label className='label'>Description</label>
                <textarea style={{ resize: 'none' }} value={description} onChange={(e) => setDescription(e.target.value)} disabled={isDisabled} className="form-control" />
            </div>

            <div className="form-group">
                <SingleSelection props={{
                    label: "Alert Purpose", placeholder: "Select a alert purpose", className: "custom-select-dropdown", dropDownValue: alertPurpose, onClickEvent: (value) => {
                        setalertPurpose(value)

                    }, optionList: AlertPurposeList, isDisable: isDisabled
                }} />
            </div>
            <div className="form-group">
                <SingleSelection props={{
                    label: "Priority", placeholder: "Select priority", className: "custom-select-dropdown", dropDownValue: priority, onClickEvent: (value) => {
                        setpriority(value)
                    }, optionList: PriorityList, isDisable: isDisabled
                }} />
            </div>

            <div className="form-group">

                <MultiSelection props={{
                    label: "Assignee", placeholder: "Select assignee", className: "custom-select-dropdown", dropDownValue: selectedAssignee, onClickEvent: (value) => {
                        setselectedAssignee(value ? [...value] : [])
                    }, optionList: assignee, children: [], isDisable: isDisabled
                }} />
            </div>

            <div className="form-group d-flex justify-content-between mb-0">
                <label className='label'>Notify   <Tooltip
                    placement="right"
                    title={'When activated, the user shall be notified via email each time this alert is executed.'}
                >
                    <span className="icon-column-details">
                        <i className="bx bx-help-circle"></i>
                    </span>
                </Tooltip>

                </label>

                <div className="custom-control custom-switch" dir="ltr">
                    <input type="checkbox" onChange={(e) => { e.target.checked ? setnotify(true) : setnotify(false) }} checked={notify || false} value={notify || false} className="custom-control-input" disabled={isDisabled} id="customSwitch2" />
                    <label className="custom-control-label" htmlFor="customSwitch2"></label>
                </div>

            </div>
            {notify ? <><div className="form-group">
                <label className='label'>Subject</label>
                <input type="text" placeholder='Please Enter Subject ' onChange={(e) => setnotifySubject(e.target.value)} value={notifySubject} disabled={isDisabled} className={"form-control"} />
                {alertNameEmpty ? <span className="error">Please Enter Subject</span> : null}
            </div>

                <div className="form-group">
                    <label className='label'>Message</label>
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} disabled={isDisabled} className="form-control" />
                </div></> : null}

            {/* <div className="form-group d-flex justify-content-between mb-0">
                <label className='label'>Schedule   <Tooltip
                    placement="bottomLeft"
                    title={Literals.RULES_SCHEDULER_NOTE}
                >
                    <span className="icon-column-details">
                        <i className="bx bx-help-circle"></i>
                    </span>
                </Tooltip>

                </label>

                <div className="custom-control custom-switch" dir="ltr">
                    <input type="checkbox" onChange={(e) => { e.target.checked ? setschedule(true) : setschedule(false) }} checked={schedule || false} value={schedule || false} className="custom-control-input" disabled={isDisabled} id="customSwitch2" />
                    <label className="custom-control-label" htmlFor="customSwitch2"></label>
                </div>

            </div> */}
            {schedule ?
                <div>
                    <AlertSchedule isDisabled={isDisabled} scheduleBody={scheduleBody} setscheduleBody={setScheduleBodyValue} ruleDetails={ruleDetails} />
                </div> : null}
        </>
    )
}

export default AlertDetails
