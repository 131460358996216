import { useDrag, useDrop } from "react-dnd";
import React from "react";
import { Row } from "react-data-grid";
import './DraggableRowRenderer.scss'

export function DraggableRowRenderer({
  rowIdx,
  isRowSelected,
  onRowReorder,
  ...props
},) {

  const [{ isDragging }, drag] = useDrag({
    type: "ROW_DRAG",
    item: { index: rowIdx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "ROW_DRAG",
    drop({ index }) {
      onRowReorder(index, rowIdx, isOver, isDragging);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop({ shallow: true }),
    }),

  });

  return (
    <Row
      // ref={useCombinedRefs(drag, drop)}
      ref={(ref) => {
        if (ref) {
          drag(ref.firstElementChild);
        }
        drop(ref);
      }}
      rowIdx={rowIdx}
     
      isRowSelected={isRowSelected}
      className={` ${isOver ? 'on-vlist-over' : ''} ${isDragging ? 'on-vlist-dragging' : ''}`}
      {...props}
    />
  );
}
