import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getUserGroupList } from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import Buttons from "../../../../Common/button/Buttons";
import { CHECK, CLOSE, USERMANAGEMENT } from "../../../../Common/iconSource";
import SearchInput from "../../../../Common/search/Search";
import { Literals } from "../../../common/literals";
import UserSelectionTable from "./UserSelectionTable";
import NoSearchResultFound from "../../../common/NoSearchResultFound";

const AddUserModal = ({
  isModalOpen,
  toggleModal,
  selectedUsers,
  setSelectedUsers,
}) => {
  const dispatch = useDispatch();

  const [selectedUsersTemp, setSelectedUsersTemp] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectableUsersId, setSelectableUsersId] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [searchData, setSearchData] = useState("");

  const fetchUsersList = async () => {
    try {
      const response = await dispatch(
        getUserGroupList({ method: "GET", endPoint: "group_manager/user/" })
      );
      if (response.status === "success") {
        const usersList = response.data.map((user) => ({
          ...user,
          type: "user",
        }));
        setUsersList(usersList);
      }
    } catch (error) {
      console.error("Error fetching Users", error.message);
    }
  };

  useEffect(() => {
    fetchUsersList();
  }, []);

  useEffect(() => {
    if (usersList.length) {
      const selectableUsersId = [];

      usersList.forEach((user) => {
        if (!user.is_superuser) {
        // if (!user.is_superuser && user.is_verified) {
          selectableUsersId.push(user.id);
        }
      });

      setSelectableUsersId(selectableUsersId);
    }
  }, [usersList]);

  useEffect(() => {
    setSelectedUsersTemp(selectedUsers);
  }, [selectedUsers]);

  useEffect(() => {
    if (selectedUsersTemp.length && (selectedUsersTemp.length === selectableUsersId.length) ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedUsersTemp]);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedUsersTemp([]);
    } else {
      setSelectedUsersTemp(selectedUsers);
    }
  }, [isModalOpen]);

  return (
    <Modal
      size="lg"
      toggle={toggleModal}
      isOpen={isModalOpen}
      className="custom-modal user-group-modal"
      centered={true}
    >
      <ModalHeader>
        <div className="d-flex align-items-center">
          <USERMANAGEMENT width="18" height="18" />
          <h2 className="section-heading">{Literals.ADD_USERS}</h2>
        </div>
        <div className="tab-menu right-flow-header">
          <SearchInput 
            searchData={searchData} 
            setSearchData={setSearchData}
            isDisable={!usersList || usersList?.length === 0}
          />
          <Buttons
            props={{
              buttonText: "",
              buttonClassName:
                "custom-btn-outline custom-btn btn-with-icon ml-2",
              buttonEvent: () => {
                toggleModal();
              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_TERTIARY,
            }}
          />
        </div>
      </ModalHeader>
      <ModalBody>
        {
          !usersList || usersList?.length === 0
          ?
          <div className="py-3 text-black-50 text-center">
            No Users Present
          </div>
          :
          <UserSelectionTable
            users={usersList}
            searchData={searchData}
            selectedUsers={selectedUsersTemp}
            setSelectedUsers={setSelectedUsersTemp}
            allSelected={allSelected}
            setAllSelected={setAllSelected}
            selectableUsersId={selectableUsersId}
          />
        }
      </ModalBody>
      <ModalFooter>
        <Buttons
          props={{
            buttonText: "Done",
            buttonClassName: "custom-btn-primary custom-btn btn-with-text ml-2",
            buttonEvent: () => {
              toggleModal();
              setSelectedUsers(selectedUsersTemp);
            },
            ImgSrc: () => <CHECK />,
            isDisable: !usersList || usersList?.length === 0 || selectedUsersTemp?.length === 0,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AddUserModal;
