import { GET_ALERT_DATA, OPEN_FILTER } from "./alertLandingActionType";
import { options } from '../../../../components/modules/common/toastrProperties';
import toastr from 'toastr';
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import { emitToastNotification } from "../../../../helpers/toast_helper";

export const getAlertData = () => {
  return {
    type: GET_ALERT_DATA
  };
}

export const openFilter = (payload) => {
  return {
    type: OPEN_FILTER,
    payload: payload
  }
}

toastr.options = {
  ...options
}

const BaseUrl = window._env_.REACT_APP_API_BASE_URL


export const GetAlertDataApi = (data_domain_id, editor, mode = '') => {

  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${BaseUrl}rule/?data_domain_id=${data_domain_id ? data_domain_id : ''}&${editor}&${mode}`)
      ).then((resp) => {
        dispatch(setLoadingStatus(false));
        if (resp.status === false) {
          reject(resp.message);
          emitToastNotification('error', resp.message)
        } else {
          resolve(resp);
        }
      })
        .catch((err) => {
          emitToastNotification('error', err.message)
          dispatch(setLoadingStatus(false));
          reject(err)
        });
    });
  }
}

export const DeleteAlertDataApi = (id) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("DELETE", `${BaseUrl}rule/${id}/`)
      ).then((resp) => {
        dispatch(setLoadingStatus(false));
        if (resp.status === false) {
          reject(resp.message);
          emitToastNotification('error', resp.message)
        } else {
          resolve(resp);
        }
      })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    });
  }
}

export const BookmarkAlertDataApi = (id, isBookmark) => {

  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${BaseUrl}rule/${id}/${isBookmark ? 'delete_bookmark/' : 'create_bookmark/'}`)
      ).then((resp) => {
        dispatch(setLoadingStatus(false));
        if (resp.status === false) {
          reject(resp.message);
          emitToastNotification('error', resp.message)
        } else {
          emitToastNotification('success', resp.message)

          resolve(resp);
        }
      })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    });
  }
}

export const TriggerAlertDataApi = (data) => {
  if (data.id) {
    emitToastNotification('success', 'Execution started successfully!')
    // https://dev.stellar-data.com/django-app/rule/7/trigger_rule/
    let id = data.id
    return (dispatch) => {
      // dispatch(setLoadingStatus(true));
      return new Promise((resolve, reject) => {
        dispatch(
          fetchData("GET", `${BaseUrl}rule/${id}/trigger_rule/`)
        ).then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
          .catch((err) => {
            dispatch(setLoadingStatus(false));
          });
      });
    }
    // return (dispatch) => {
    //   dispatch(setLoadingStatus(true));
    //   return new Promise((resolve, reject) => {
    //     dispatch(
    //       fetchData("GET", `${BaseUrl}rule​/${data.id}​/trigger_rule​/`)
    //     ).then((resp) => {
    //       dispatch(setLoadingStatus(false));
    //       if (resp.status === false) {
    //         reject(resp.message);
    //         toastr.error(resp.message)
    //       } else {
    //         toastr.success('Execution started successfully!')

    //         resolve(resp);
    //       }
    //     })
    //       .catch((err) => {
    //         dispatch(setLoadingStatus(false));
    //       });
    //   });
    // }
  }

}