import React, { useEffect, useRef, useState } from 'react'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import { ICON_DASHBOARD } from '../../../Common/newIconSource'
import { CLOCK, FLOWS, PLAY, PLUS, REDIRECTJOBS } from '../../../Common/iconSource'
import FlowSelectionModal from '../../alert/createAlert/FlowSelectionModal'
import { useSelector, useStore } from 'react-redux'
import { SetSchedulerPayload, setSelectedTempScheduler } from '../../../../store/modules/scheduler/action'
import { cloneDeep } from 'lodash'
import SchedulerModal from './SchedulerModal'
import SingleSelection from '../../../Common/dropDown/SingleSelection'
import PODSelection from '../../../Common/PODSelection'
import { setSelectedPodList } from '../../../../store/modules/common/podSelectionModalAction'
import { dictionaryEndpoint } from '../../../../store/actions'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import SqlEditor from '../../dataCatalogue/preview/components/SqlEditor'
import { InputNumber, Radio, Tooltip } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { getPreviewTable, saveSQlTrigger } from '../../../../store/modules/dataCatalogue/preview/previewActions'
import { getRelativeTime } from '../../common/helperFunctions'
import PODSelectionNewModal from './PODSelectionNewModal'

function TriggerEventModelSelection({ isExecutePermission, modelType = 'scheduler' }) {

    const [openSchedulerModal, setopenSchedulerModal] = useState(false)

    const [OpenPODModal, setOpenPODModal] = useState(false);
    const [selectedPodDetails, setselectedPodDetails] = useState([]);
    const [savepodlist, setsavepodlist] = useState([]);
    const [sqlCount, setsqlCount] = useState(null);
    const [previoussqlCount, setprevioussqlCount] = useState('NA');
    const [lastCountFlag, setLastCountFlag] = useState('true')
    const [tagList, settagList] = useState([]);
    const [lastRunDate, setlastRunDate] = useState('');
    const [currentRunDate, setcurrentRunDate] = useState('');
    const [podId, setPodId] = useState(null)
    const [podData, setpodData] = useState(null)
    const [Query, setQuery] = useState('');
    const [schedulerOffsetTime, setschedulerOffsetTime] = useState()

    const [sqlTables, setsqlTables] = useState([])

    const [selectedTypeName, setselectedTypeName] = useState('');
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)
    const selectedScheduler = useSelector(state => state.Scheduler.selectedScheduler)

    const failureSelectOptions = [
        { Name: 'On Success', value: 'SUCCESS' },
        // { Name: 'On Run', value: 'RUN' },
        { Name: 'On Failure', value: 'FAILED' },

    ]
    const [failureSelectValue, setFailureSelectValue] = useState(failureSelectOptions[0].Name)
    const store = useStore()

    useEffect(() => {
        const newPayload = cloneDeep(schedulerPayload);
        newPayload['trigger_type'] = modelType?.toUpperCase();
        let scheduler = { name: selectedScheduler['name'], id: selectedScheduler['id'] }
        if (selectedScheduler && selectedScheduler.name) {

            setselectedTypeName(selectedScheduler.name)
            newPayload['dependant_scheduler'] = selectedScheduler.id
            newPayload['cron_redux']['selectedScheduler'] = scheduler

            store.dispatch(setSelectedTempScheduler(selectedScheduler))

        } else {

            setselectedTypeName('')

        }


        store.dispatch(SetSchedulerPayload(newPayload))


    }, [modelType, selectedScheduler])

    const onetimeCallCountApi = useRef(0);

    useEffect(() => {


        if (schedulerPayload && (schedulerPayload?.trigger_type?.toLocaleLowerCase() === 'scheduler' || schedulerPayload?.trigger_type?.toLocaleLowerCase() === 'pod')) {

            setFailureSelectValue(schedulerPayload?.dependant_status)

            if (schedulerPayload['last_count']) {
                setprevioussqlCount(schedulerPayload['last_count'])
            }
            setLastCountFlag(schedulerPayload['last_count_flag']?.toString())
            if (schedulerPayload['cron_redux']?.selectedPOdList) {

                setselectedPodDetails(schedulerPayload['cron_redux']['selectedPOdList'])
                setsavepodlist(schedulerPayload['cron_redux']['selectedPOdList'])
                store.dispatch(setSelectedPodList(schedulerPayload['cron_redux']['selectedPOdList']))


            }

            if (schedulerPayload['cron_redux']?.sqlEditorQuery) {

                if (onetimeCallCountApi.current === 0) {
                    setQuery(schedulerPayload['cron_redux']['sqlEditorQuery'])
                    onetimeCallCountApi.current = 1;
                    onExecuteQuery(schedulerPayload['cron_redux']['sqlEditorQuery'], false)

                }
            }

            if (schedulerPayload?.date_time && schedulerPayload?.date_time !== '') {
                // setcurrentRunDate(getRelativeTime(schedulerPayload?.date_time, 'datetime'))
            }
            if (schedulerPayload?.last_run_date && schedulerPayload?.last_run_date !== '') {
                setlastRunDate(getRelativeTime(schedulerPayload?.last_run_date, 'datetime'))
            }


        }

        if (schedulerPayload && schedulerPayload?.monitor_frequency) {
            setschedulerOffsetTime(schedulerPayload?.monitor_frequency)
        }

    }, [schedulerPayload])

    const save = (selectedPODsList) => {

        if (selectedPODsList.length) {
            setselectedPodDetails([...selectedPODsList])
            setsavepodlist([...selectedPODsList])

            store.dispatch(setSelectedPodList(selectedPODsList))

            setOpenPODModal(false)
            let sqlSuggestionList = []
            selectedPODsList.forEach(async (element) => {

                let response = await store.dispatch(
                    dictionaryEndpoint(element.table_id, false)
                );

                if (response?.data?.column_detail?.length) {

                    response.data.column_detail.forEach(itm => {
                        sqlSuggestionList.push({ name: `${itm.column_name}`, description: '', type: 'Column', tableName: element.table_name },)
                    })
                }
                sqlSuggestionList.push({ name: `${element.data_domain_detail.data_domain_name}.${element.table_name}`, description: '', type: 'Table', tableName: '' })

                if (sqlSuggestionList?.length) {
                    setsqlTables([...sqlSuggestionList])
                }
            })


            setQuery(`select * from ${selectedPODsList[0].data_domain_detail.data_domain_name}.${selectedPODsList[0].table_name}`)

            let newPayload = cloneDeep(schedulerPayload);
            newPayload['pod_ids'] = selectedPODsList.map((pod) => pod.table_id)
            newPayload['cron_redux']['selectedPOdList'] = selectedPODsList;
            newPayload['cron_redux']['sqlEditorQuery'] = `select * from ${selectedPODsList[0].data_domain_detail.data_domain_name}.${selectedPODsList[0].table_name}`;
            newPayload['dependant_query'] = `select count(1) from ( select * from ${selectedPODsList[0].data_domain_detail.data_domain_name}.${selectedPODsList[0].table_name})`
            store.dispatch(SetSchedulerPayload(newPayload))

        } else {
            setselectedTypeName('');
            emitToastNotification('info', 'Please select one pod')
        }

        // else if (selectedPODsList.length) {
        //     setselectedTypeName(selectedPODsList[0].table_name);
        //     store.dispatch(setSelectedPodList(selectedPODsList))

        //     setOpenPODModal(false)
        //     let newPayload = cloneDeep(schedulerPayload);
        //     newPayload['pod_ids'] = selectedPODsList.map((pod) => pod.table_id)
        //     newPayload['cron_redux']['selectedPOdList'] = selectedPODsList;

        //     store.dispatch(SetSchedulerPayload(newPayload))


        // } 
    }
    const onRowSelection = (record, rowIndex) => {

        setPodId(record.table_id);
        setpodData(record);

    }

    const onExecuteQuery = (sqlEditorQuery, isStoredQuery = true) => {
        let uuid = uuidv4();

        if (sqlEditorQuery !== '') {
            setsqlCount(null)

            setcurrentRunDate('')
            let sqlQuery = `select count(1) from  (${sqlEditorQuery})`
            let payload = {
                uuid: uuid,
                table_name: '',
                page: 0,
                do_count: false,
                sort_body: [],
                filter_body: [],
                table_ids: [],
                query: sqlQuery,
            }
            try {
                store
                    .dispatch(getPreviewTable(payload, true, false))
                    .then((resp) => {
                        if (resp && resp.status === "success" && resp.data?.result) {
                            let responseData = resp.data?.result;

                            setsqlCount(responseData.data_record[0][0])
                            setcurrentRunDate(getCurrentDateTime())
                            if (isStoredQuery) {
                                let newPayload = cloneDeep(schedulerPayload);
                                newPayload['last_count'] = responseData.data_record[0][0];
                                // newPayload['last_count_timestamp'] = getCurrentDateTime()
                                setQuery(sqlEditorQuery);
                                newPayload['dependant_query'] = sqlQuery;
                                newPayload['cron_redux']['sqlEditorQuery'] = sqlEditorQuery;
                                store.dispatch(SetSchedulerPayload(newPayload))
                            }




                        } else {
                            setsqlCount(null)

                            setcurrentRunDate('')
                        }
                    })
                    .catch((err) => {
                        setsqlCount(null)

                        setcurrentRunDate('')
                    });
            } catch (error) { }
        }
    }

    // useEffect(() => {
    //     if (selectedPodDetails && selectedPodDetails.length) {
    //         selectedPodDetails?.forEach((item) => {
    //             if ((!item['table_name'] || !item['table_id']) && (item['podId'] || item['podName'])) {
    //                 item['table_name'] = item['podName'];
    //                 item['table_id'] = item['podId']
    //             }
    //         })
    //         settagList([...selectedPodDetails]);
    //     } else {
    //         settagList([]);

    //     }

    // }, [selectedPodDetails]);
    useEffect(() => {
        if (savepodlist && savepodlist.length) {
            savepodlist?.forEach((item) => {
                if ((!item['table_name'] || !item['table_id']) && (item['podId'] || item['podName'])) {
                    item['table_name'] = item['podName'];
                    item['table_id'] = item['podId']
                }
            })
            settagList([...savepodlist]);
        } else {
            settagList([]);
            setselectedPodDetails([])

        }

    }, [savepodlist]);

    const getCurrentDateTime = () => {
        return getRelativeTime(new Date().toISOString(), 'datetime')
    }

    const removeTags = (id) => {

        setQuery('')

        setsqlCount(null)

        setcurrentRunDate('')
        setprevioussqlCount('NA')
        setlastRunDate('')

        setselectedPodDetails((selectedPODsList) => {

            const newList = [...selectedPODsList];
            const index = newList.findIndex((ele) => ele.table_id === id);

            if (index !== -1) {
                newList.splice(index, 1);
            }

            settagList(newList);
            store.dispatch(setSelectedPodList(newList))
            let newPayload = cloneDeep(schedulerPayload);
            newPayload['pod_ids'] = newList?.map((pod) => pod?.table_id).filter(item => item)
            newPayload['cron_redux']['selectedPOdList'] = newList;

            store.dispatch(SetSchedulerPayload(newPayload))
            return newList;
        });


    };

    const createSqlTableSuggestion = (selectedPODsList) => {
        let sqlSuggestionList = []
        selectedPODsList.forEach(async (element) => {

            let response = await store.dispatch(
                dictionaryEndpoint(element.table_id, false)
            );

            if (response?.data?.column_detail?.length) {

                response.data.column_detail.forEach(itm => {
                    sqlSuggestionList.push({ name: `${itm.column_name}`, description: '', type: 'Column', tableName: element.table_name },)
                })
            }
            sqlSuggestionList.push({ name: `${element.data_domain_detail.data_domain_name}.${element.table_name}`, description: '', type: 'Table', tableName: '' })

            if (sqlSuggestionList?.length) {
                setsqlTables([...sqlSuggestionList])
            }
        })

    }

    const changeQuery = (query) => {

        // setQuery(query);

        let newPayload = cloneDeep(schedulerPayload);
        newPayload['dependant_query'] = `select count(1) from  (${query})`
        newPayload['cron_redux']['sqlEditorQuery'] = query;

        store.dispatch(SetSchedulerPayload(newPayload))

        if (!sqlTables.length && selectedPodDetails.length && !window?.location?.href?.toLowerCase().includes('scheduler/view')) {
            createSqlTableSuggestion(selectedPodDetails)
        }


    }

    const getRemainingTags = (tagList) => {
        if (tagList?.length > 2) {
            return tagList?.map((item, index) => index >= 2 && item?.table_name?.toUpperCase()).filter(item => item)?.toString()
        }
    }

    // console.log(tagList)

    return (
        modelType?.toLowerCase() === 'scheduler' ? <>
            <SchedulerModal isModalOpen={openSchedulerModal} onClose={() => setopenSchedulerModal(false)} fromRoute='schedulerModal' />

            <div className="d-flex justify-content-start padding-2 px-0 ">

                <div className='col-3 pl-0'>
                    <div>
                        <label className='mt-0 label'>Status </label>
                    </div>
                    <div className='mb-2'>
                        <SingleSelection
                            props={{
                                label: "",
                                placeholder: "",
                                dropDownValue: failureSelectValue,
                                onClickEvent: (value) => {
                                    setFailureSelectValue(value);
                                    let newPayload = cloneDeep(store.getState().Scheduler.schedulerPayload);
                                    newPayload['dependant_status'] = value
                                    store.dispatch(SetSchedulerPayload(newPayload))

                                },
                                optionList: failureSelectOptions,
                                isDisable: window?.location?.href?.toLowerCase().includes('scheduler/view'),
                            }}
                        />
                    </div>
                </div>
                <div className='col-6'>

                    {/* <div className={'d-flex justify-content-start pr-0 m-0'} style={{ border: "1px solid #d3d3d3", borderRadius: '0px', height: "28px" }}>

                        <div className='col-8' style={{ marginTop: "3px" }}>
                            <span> {selectedTypeName ? <span className='ml-2'> {selectedTypeName}</span> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px", marginRight: "4px", fontSize: "9px" }}>{`Click on '+' to add ${modelType?.toLowerCase()}`}</span></label>}</span>
                        </div>

                        {!window?.location?.href?.toLowerCase().includes('scheduler/view') ? <div style={{ marginTop: "1px" }} className={`col-4  w-100 p-0`}>
                            <Buttons
                                props={{
                                    buttonText: modelType?.toLowerCase(),
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text w-100",
                                    buttonEvent: (e) => {
                                        setopenSchedulerModal(true)

                                        e.stopPropagation();

                                    },
                                    ImgSrc: () => <PLUS />,
                                    isDisable: !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view'),
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        </div> : null}
                    </div> */}


                    <label style={{ textTransform: "capitalize" }} className='mt-0 label'>Selected Scheduler</label>


                    <div className="d-flex align-items-center p-0 m-0"  >

                        <div onClick={(e) => {

                            !selectedTypeName?.length ? setopenSchedulerModal(true) : setopenSchedulerModal(false)

                        }} className='select-model-input col-8 m-0 cursor-pointer'>{selectedTypeName ? <div className='d-flex align-items-center justify-content-between'>
                            {selectedTypeName} <span className='cursor-pointer' onClick={(e) => {
                                window.open(`${Literals.links.SCHEDULER_VIEW}${selectedScheduler?.id}`, '_blank')
                            }}><REDIRECTJOBS /></span>
                        </div> : 'Click here to add scheduler'}



                        </div>
                        <div className={`col-4 w-100 m-0 p-0`}>
                            <Buttons
                                props={{
                                    buttonText: 'Select Scheduler',
                                    buttonClassName: "custom-btn-primary select-model-btn custom-btn btn-with-text w-100",
                                    buttonEvent: (e) => {
                                        setopenSchedulerModal(true)

                                        e.stopPropagation();

                                    },
                                    ImgSrc: () => <PLUS />,
                                    isDisable: !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view'),
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        </div>
                    </div>



                </div>
            </div>
        </> : <>
            <PODSelectionNewModal selectedPodDetails={selectedPodDetails} setselectedPodDetails={setselectedPodDetails} data={podData} isMultiSelection={true} save={save} podId={podId} isModalOpen={OpenPODModal} onClose={() => { setOpenPODModal(false) }} onRowSelection={onRowSelection} fromRoute={'alertflows'} />

            <div className='col-5 m-0 p-0'>
                <div>
                    <label className='mt-0 label'>Selected POD </label>

                </div>
                <div className={'d-flex justify-content-start pr-0 m-0'} style={{ border: "1px solid #d3d3d3", borderRadius: '0px', padding: "2px" }}>

                    <div className='col-8' style={{ marginTop: tagList?.length ? '0px' : "3px" }}>
                        <span>{tagList.length ? <div className='podtag-list-scheduler'>
                            {tagList.map((tag, index) => {
                                return index < 2 ? <div key={index+'-'+tag.table_id} className="podTag">

                                    <Tooltip placement="topLeft" title={tag?.table_name?.toUpperCase()}><span className="">{`${tag?.table_name?.slice(0, 10)?.toUpperCase()}...`}</span></Tooltip>
                                    {!window?.location?.href?.toLowerCase().includes('scheduler/view') ? <i className='bx bx-x cursor-pointer' style={{ color: "white", fontSize: "10px" }} onClick={() => { removeTags(tag.table_id) }}></i> : null}

                                </div> : <></>
                            })
                            }
                            {tagList.length > 2 ? <div className="podTag">

                                <Tooltip title={getRemainingTags(tagList)}>  <span className="">{`+ ${tagList.length - 2}`}</span></Tooltip>

                            </div> : null}
                        </div> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px", marginRight: "4px", fontSize: "9px" }}>{`Click + to add ${modelType?.toUpperCase()}`}</span></label>}</span>
                    </div>

                    {!window?.location?.href?.toLowerCase().includes('scheduler/view') ? <div className={`col-4  w-100 m-0 p-0`}>
                        <Buttons
                            props={{
                                buttonText: 'Select',
                                buttonClassName: "custom-btn-primary custom-btn btn-with-text w-100",
                                buttonEvent: (e) => {
                                    setOpenPODModal(true)

                                    e.stopPropagation();

                                },
                                ImgSrc: () => <PLUS />,
                                isDisable: !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view'),
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            }}
                        />
                    </div> : null}
                </div>
            </div>

            <div className='d-flex align-items-center' style={{ height: "200px" }}>
                <div className='col-7 pl-0 mt-3'>
                    <label className='mt-0 paragraph'>{`Select count(1) from (`}</label>
                    <div style={{ height: "145px" }} className="scheduler-sqlEdition position-relative">
                        <SqlEditor
                            height={'120px'} readOnly={window.location.href?.includes('scheduler/view/') || !isExecutePermission ? true : false} ExecuteQueryClick={onExecuteQuery} sqlTableDetails={sqlTables} getInstantQuery={changeQuery} QueryValue={Query}
                        />
                        <div>

                            <Buttons
                                props={{
                                    buttonText: "",
                                    buttonClassName:
                                        "custom-btn-primary position-absolute float-execute-btn float-right",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        store.dispatch(saveSQlTrigger({ trigger: 'run_click' }))
                                        // onExecuteQuery()


                                    },
                                    tooltip: "Execute",
                                    ImgSrc: () => <PLAY width='15' height='15' />,
                                    isDisable: window.location.href?.includes('scheduler/view/') ? true : false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                        </div>
                    </div>


                    <div>
                        <label className='mt-0 paragraph'>{`)`}</label>
                    </div>

                </div>
                <div style={{ height: "185px" }} className='col-5 p-0 mt-5 ml-1'>

                    <div className='col-12 pl-0'>
                        <label className='mt-0 mb-0 label'>Monitor Frequency</label>
                        <div>
                            <InputNumber
                                min={60}
                                keyboard={true}
                                value={schedulerOffsetTime}
                                onChange={(e) => {

                                    setschedulerOffsetTime(e)
                                    let newPayload = cloneDeep(schedulerPayload);
                                    newPayload['monitor_frequency'] = e
                                    newPayload['last_count'] = sqlCount
                                    store.dispatch(SetSchedulerPayload(newPayload))
                                }}
                                disabled={window?.location?.href?.toLowerCase().includes('scheduler/view')}
                                step={10}
                                className='moniter-time  w-100 p-0'
                            />
                        </div>
                    </div>

                    <div className='d-flex mt-2'>
                        <div className="col-6 pl-0 mt-1">
                            <label htmlFor="schedulerDesc" className='mt-1 label'>Current Count</label>
                            <p htmlFor="schedulerDesc" style={{ lineHeight: 0 }} className='mt-0 text-xl label'>{sqlCount}</p>
                            {currentRunDate !== '' ? <p htmlFor="schedulerDesc" className="small text-muted mt-0 mb-0" style={{ lineHeight: 1, fontSize: '8px' }}>{currentRunDate}</p> : ''}

                        </div>
                        <div className="col-6 pl-0 mt-1">
                            <label htmlFor="schedulerDesc" className='mt-1 label'>Last Run Count</label>
                            <p htmlFor="schedulerDesc" style={{ lineHeight: 0 }} className='mt-0 text-xl  label'>{previoussqlCount}</p>
                            {lastRunDate !== '' ? <p htmlFor="schedulerDesc" className="small text-muted mt-0 mb-0" style={{ lineHeight: 1, fontSize: '8px' }} >{lastRunDate
                            }</p> : ''}

                        </div>
                    </div>
                    <div className="col-12 d-flex p-0 mt-4">
                        <label htmlFor="schedulerDesc" className=' col-3 p-0 mt-1 mr-3 label'>Trigger On :
                        </label>
                        <Radio.Group disabled={window?.location?.href?.toLowerCase().includes('scheduler/view')} onChange={(e) => {
                            setLastCountFlag(e.target.value)
                            let newPayload = cloneDeep(schedulerPayload)
                            newPayload['last_count'] = sqlCount
                            newPayload['last_count_flag'] = e.target.value === 'true' ? true : false
                            store.dispatch(SetSchedulerPayload(newPayload))
                        }} value={lastCountFlag}>
                            <Radio style={{ fontSize: "0.75rem" }} value={'false'}>Current  Count <b>{'>'}</b>  0 </Radio>
                            <Radio style={{ fontSize: "0.75rem" }} value={'true'}>Current <b>{'!='}</b> Last Run Count</Radio>
                        </Radio.Group>

                    </div>

                </div>

            </div>

        </>




    )
}

export default TriggerEventModelSelection