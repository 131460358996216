export const RESET_ANALYSIS_REDUX_STATE = "RESET_ANALYSIS_REDUX_STATE";
export const SET_SELECTED_ANALYSIS_PODS = "SET_SELECTED_ANALYSIS_PODS";
export const CLEAR_SELECTED_ANALYSIS_PODS = "CLEAR_SELECTED_ANALYSIS_PODS";
export const SET_PREVIEW_RESPONSE_QUERY = "SET_PREVIEW_RESPONSE_QUERY";
export const SET_ANALYSIS_PREVIEW_TABLES = "SET_ANALYSIS_PREVIEW_TABLES";
export const SET_QUERY_EDITOR_QUERY = "SET_QUERY_EDITOR_QUERY";
export const SET_QUERY_RESULT = "SET_QUERY_RESULT";
export const SET_PREVIEW_TABLE_RESULT = "SET_PREVIEW_TABLE_RESULT";
export const SET_SELECTED_CHART = "SET_SELECTED_CHART";
export const SET_GRAPH_HEIGHT = "SET_GRAPH_HEIGHT";
export const SET_CHART_RESPONSE_QUERY = "SET_CHART_RESPONSE_QUERY";
export const SET_GRAPH_PREVIEW_REQUEST = "SET_GRAPH_PREVIEW_REQUEST";

export const SET_PUBLISH_BUTTON_CLICK = "SET_PUBLISH_BUTTON_CLICK";

export const DATA_DOMAIN_LIST = "DATA_DOMAIN_LIST";
export const FILTER_CHART_LIST = "FILTER_CHART_LIST";
export const SET_GRAPH_COMMON_LAYOUT = "SET_GRAPH_COMMON_LAYOUT";
export const SET_SORT_ORDER_LIST = "SET_SORT_ORDER_LIST";
export const SET_CHART_FILTER_RESULT = "SET_CHART_FILTER_RESULT";
export const SET_COLUMNS_LIST = "SET_COLUMNS_LIST";
export const SET_FILTER_DATA = "SET_FILTER_DATA";
export const SET_GRAPH_DATA = "SET_GRAPH_DATA";
export const SET_CONTROL_LIST = "SET_CONTROL_LIST";
export const SET_APPLY_STYLE_CLICK = "SET_APPLY_STYLE_CLICK";

export const SET_KPI_PARAMETERS = "SET_KPI_PARAMETERS";
export const SET_DONE_BTN_CLICK = "SET_DONE_BTN_CLICK";
