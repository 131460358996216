import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import NoSearchResultFound from '../../../common/NoSearchResultFound';
import ConnectionListAccordion from './ConnectionListAccordion';
import _, { cloneDeep, filter } from 'lodash';

import { FLOWS, INBOUND } from '../../../../Common/iconSource';
import { ErrorComponent, getObjectLength, NoDataComponent } from '../../../common/helperFunctions';
const ConnectionList = ({ searchValue = '',
    layout,
    fromModal = false,
    fromModalRoute = "",
    setdbdataWithDbClone = () => { },
    setdatadomainlabelClone = () => { },
    filterList = [],
    getDataStatus = () => { },
    setChangedConn = () => { },
    isChangeModal = false,
    setErrMsg = () => { },
    errMsg = '',
    empty = false,
    disableUnselected = false,
    setWalkThroughStep = () => { },
    setEmpty = () => { }
}) => {
    const store = useStore()
    const [openCreateConnection, setOpenCreateConnection] = useState(false);
    const [openEditConnection, setOpenEditConnection] = useState(false);
    const datadomainList = useSelector(state => state.UserManagement.DataDomain.datadomainList)
    const connectionData = useSelector(state => state.UserManagement.ConnectionReducer.dataList)
    const openModel1 = useSelector(state => state.UserManagement?.ConnectionReducer?.openModel)
    // const openModel1 = useSelector(state => state.DataCatalogue?.LandingPage?.openModel)
    const dispatch = useDispatch()
    const [datadomainLabel, setDataDomainLabel] = useState([])
    const [dbDataWithDb, setDbDataWithDb] = useState()
    const [datadomainLabelClone, setDataDomainLabelClone] = useState([])
    const [dbDataWithDbClone, setDbDataWithDbClone] = useState()
    const [assignedConnections, setAssignedConnections] = useState([])


    useEffect(() => getDataStatus(empty), [empty])

    useEffect(() => {
        return () => {
            setErrMsg('')
        }
    }, [])

    useEffect(() => {
        if (connectionData?.length) {

            const databaseSet = new Set();
            let c_data = cloneDeep(connectionData)
            // let assigned_conn = [...c_data.filter(v=>v.connection_name==='azure_adls')]
            let assigned_conn = [...c_data.filter(v => v?.assigned_as_cache || v?.assigned_as_upload)]
            // let assigned_conn = [...c_data.filter(v => _.isEmpty(v.data_domain))]
            let new_assigned_conn = assigned_conn.map(v => (
                {
                    ...v,
                    data_domain: [{ data_domain_id: 0, data_domain_name: "[assigned_connection]" }],
                    isAssignedConnection: true,
                    assignedAs: [{ text: 'Cache', val: v.assigned_as_cache }, { text: 'Upload', val: v.assigned_as_upload }]
                }
            ))
            if (fromModal === true) {
                new_assigned_conn = []
            } else {

                setAssignedConnections([...new_assigned_conn])
            }
            c_data.forEach(itm => itm?.data_domain.map(v => databaseSet.add(v.data_domain_id)))
            let databaseClone = [...databaseSet];
            let dbDataWithDb1 = [...c_data]
            let assgnConn = [...new_assigned_conn]

            if (c_data?.length && searchValue !== '') {
                dbDataWithDb1 = c_data.filter((acc) => {
                    return (
                        acc?.connection_name?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase()) ||
                        acc?.connection_description?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase()) ||
                        acc?.source_type?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase()) ||
                        acc?.created_on?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase()) ||
                        acc?.updated_by_user?.email?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase())
                    )
                })

                assgnConn = new_assigned_conn?.filter((acc) => {
                    return (
                        acc?.connection_name?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase()) ||
                        acc?.connection_description?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase()) ||
                        acc?.source_type?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase()) ||
                        acc?.created_on?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase()) ||
                        acc?.updated_by_user?.email?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase())
                    )
                })
            }


            dbDataWithDb1.unshift(...assgnConn)

            if (filterList && getObjectLength(filterList)) {
                dbDataWithDb1 = dbDataWithDb1?.filter(c => {
                    if (filterList?.sources?.some(s => s?.selected)) {
                        return filterList?.sources?.filter(s => s?.selected)?.map(s => s?.name)?.includes(c?.source_type)
                    }
                    return true
                })

                dbDataWithDb1 = dbDataWithDb1?.filter(c => {
                    if (filterList?.domains?.some(s => s?.selected)) {
                        return c?.data_domain?.map(d => d?.data_domain_name)?.some(d => filterList?.domains?.filter(s => s?.selected)?.map(s => s?.name)?.includes(d))
                    }
                    return true
                })
            }


            setDbDataWithDb(dbDataWithDb1)
            setdbdataWithDbClone(dbDataWithDb1)
            setDbDataWithDbClone(dbDataWithDb1)

            if (datadomainList?.length) {
                let domainList = datadomainList.filter(value => databaseClone.includes(value?.data_domain_id));

                setdatadomainlabelClone(domainList)
                setDataDomainLabel(domainList)
                setDataDomainLabelClone(domainList)
            }


        }
    }, [connectionData, datadomainList,searchValue,filterList])

    
    


    return (
        <>

            {
                errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> :
                    searchValue?.length === 0 &&
                        empty ?
                        <NoDataComponent logo={<INBOUND width="60" height="60" color="black" />}
                            message=" Click on (+) to Create Connection" /> :

                        (searchValue?.length > 0 || filterList?.sources?.some(s => s?.selected) || filterList?.domain?.some(s => s?.selected))
                            && dbDataWithDb?.length === 0 ?
                            <NoSearchResultFound subMsg='Please try Search / Filter with another term' mainMsg='No Connection List Found' />
                            :
                            <>
                                <ConnectionListAccordion
                                    unqiueId={`conn-list`}
                                    fromModal={fromModal}
                                    fromModalRoute={fromModalRoute}
                                    dbDataWithDb={dbDataWithDb}
                                    openCreateConnection={openCreateConnection}
                                    setOpenCreateConnection={setOpenCreateConnection}
                                    openEditConnection={openEditConnection}
                                    setOpenEditConnection={setOpenEditConnection}
                                    layout={layout}
                                    searchValue={searchValue}
                                    assignedConnection={assignedConnections}
                                    setChangedConn={setChangedConn}
                                    isChangeModal={isChangeModal}
                                    disableUnselected={disableUnselected}
                                    setWalkThroughStep={setWalkThroughStep}
                                    setEmpty={setEmpty}
                                />
                            </>
            }
        </>
    )
}
export default ConnectionList;