import { Input, Tooltip } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { Literals } from '../../modules/common/literals'
import Buttons from '../button/Buttons'

import { setViewMode } from '../../../store/app/appAction'
import { openModel } from '../../../store/modules/dataCatalogue/landingPage/landingPageActions.js'
import { CLOSE, FILTER, LISTVIEW, REFRESH, SEARCH, TABVIEW } from '../iconSource'
import SearchInput from '../search/Search'
import { LandingPageSubHeaderContext } from './LandingPageSubHeaderContext'
import Search from 'antd/lib/transfer/search'


function LandingPageSubHeaderRightSide({
    closeButtonEvent,
    isPodSelectionModal,
    hideToggle = false,
    setSearchData,
    showFilterModal,
    isDisableAllOptions = false,
    isDictionaryPage = false,
    searchData
}) {

    const store = useStore()
    const context = useContext(LandingPageSubHeaderContext);
    const [toggleValue, setToggleValue] = useState('gridView')
    const toggleBtnOption = [
        { label: <TABVIEW />, value: 'gridView' },
        { label: <LISTVIEW />, value: 'listView' },

    ];
    // const setPageView = (view) => {
    //     store.dispatch(setViewMode({
    //         module: context.moduleType,
    //         view_type: view === "gridView" ? "grid" : "list"
    //     })).then((res) => {


    //         setToggleValue(view)
    //         if (view === 'gridView') {

    //             context.setshowGridView(true); context.setshowListView(false)
    //         } else {
    //             context.setshowListView(true); context.setshowGridView(false)
    //         }

    //     })

    // }

    useEffect(() => {
        setToggleValue(context.defaultValue)
    }, [context.defaultValue])

    const onFilterClick = () => {
        if (isPodSelectionModal) {
            showFilterModal()
        }
        else {
            context && context.setshowFilterPopup()
        }
        store.dispatch(openModel('filter'))
    }

    return (
        <div className="tab-menu right-flow-header">
            {
                !isDictionaryPage
                    ? <>
                        <Tooltip placement='top' title='Search flows' >
                            <SearchInput searchData={searchData} setSearchData={isPodSelectionModal ? setSearchData : context.setSearchData} isDisable={isDisableAllOptions} />
                        </Tooltip>


                        {!hideToggle ? <Buttons props={{ tooltip: 'Filter', buttonText: '', buttonClassName: `${context.isFilterApplied ? 'rdg-filter-active' : ''} custom-btn-outline custom-btn btn-with-icon mr-2 ml-2`, buttonEvent: () => onFilterClick(), ImgSrc: () => <FILTER />, isDisable: isDisableAllOptions, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}
                    </>
                    : ''
            }
            {/* <Buttons props={{ tooltip:'Filter', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2 ml-2", buttonEvent: isPodSelectionModal ? showFilterModal : context?.setshowFilterPopup, ImgSrc: () => <FILTER />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} /> */}

            {/* {!isPodSelectionModal && !hideToggle && <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: toggleValue, buttonClassName: "toggle-btn custom-btn btn-with-icon ", buttonEvent: setPageView, ImgSrc: () => <></>, isDisable: isDisableAllOptions, buttonType: Literals.BTN_TOGGLE }} />} */}

            {isPodSelectionModal && !hideToggle && <Buttons props={{ tooltip: 'Close', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { closeButtonEvent() }, ImgSrc: () => <CLOSE />, isDisable: isDisableAllOptions, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />}
        </div>
    )
}

export default LandingPageSubHeaderRightSide
