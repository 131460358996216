import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { LandingSimpleTable, LandingSimpleTableInner } from '../../../Common/landingSimpleTable/LandingSimpleTable';
function AlertLogs({ logs, onLogsRowClick, logsRowSelectedIndex = 0 }) {
    const [logsData, setlogsData] = useState([])
    useEffect(() => {
        if (logs && logs.length) {
            logs.sort((a, b) => new Date(b.start_date_time) - new Date(a.start_date_time))
        }
        let temp = logs.map((item, index) => {
            return {
                id: item.id,
                key: item.id,
                startDate: <div>
                    <Tooltip placement='bottom' title={new Date(item.start_date_time).toLocaleString()}> {new Date(item.start_date_time).toLocaleDateString()}</Tooltip>
                </div>,
                exception_count: <div>
                    <p>{item.exception_count}</p>
                </div>,

                status: <div className='d-flex align-items-center h-100'>
                    {item.state?.toLowerCase() === 'success' ? <Tooltip className='d-flex' title={item.state}> <i className='bx bxs-check-circle' style={{ fontSize: "20px", color: '#00CC92' }}  ></i></Tooltip> : item.state?.toLowerCase() === 'error' || item.state?.toLowerCase() === 'failed' ?
                        <Tooltip title={item.state}>  <i className='bx bxs-x-circle' style={{ fontSize: "20px", color: '#eb1321' }} ></i></Tooltip> : <Tooltip title={item.state}>  <i className='bx bx-run' style={{ fontSize: "10px", color: '#2C83C4' }} > Running</i></Tooltip>}
                </div>,
                state: item.state,
                allData: item,
                error_message: item.error_message,
                start_date_time: item.start_date_time
            }
        })
        setlogsData([...temp])
    }, [logs])

   
    const columns = [
        {
            name: 'Timestamp',
            dataIndex: "start_date_time",
            key: "startDate",
            sortable: true,
            resizable: false,
            sorter: {
                compare: (a, b) => a?.timestamp?.props?.children?.props?.title !== undefined ? a?.timestamp?.props?.children?.props?.title?.localeCompare(b?.timestamp?.props?.children?.props?.title) : null
            },

            width: "40"
        },
        {
            name: 'Exceptions',
            dataIndex: "exception_count",
            key: "exception_count",
            className: "exceptions",
            sortable: false,
            resizable: false,
            width: "17"
        },
        {
            name: 'Status',
            dataIndex: "status",
            key: "status",
            sortable: false,
            resizable: false,
            width: "13"
        },

    ];

    const getComparator = (sortColumn) => {
        switch (sortColumn) {
            case 'startDate':
                return (a, b) => a[sortColumn]?.props?.children?.props?.title !== undefined ? a[sortColumn]?.props?.children?.props?.title?.localeCompare(b[sortColumn]?.props?.children?.props?.title) : null
            default:
                break;
        }
    }

    return (
        <div>
            <div id="alert-logs-tab" className="analytics-tab-table alert-logs-tab">
                <div className="analytics-dash-table mt-3">



                    <LandingSimpleTableInner
                        cols={columns}
                        autoWidth={false}
                        rowsData={logsData}
                        selectedRowIndex={logsRowSelectedIndex}
                        onRowClick={(record, index) => { onLogsRowClick(record, index) }}
                        getComparator={getComparator}
                        tableHeight={'calc(100vh - 208px)'}
                        resizable={false}
                        // tableHeight={'calc(100vh - 195px)'}

                    />

                </div>
            </div>
        </div>
    )
}

export default AlertLogs
