import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams } from "react-router-dom";
import useHideSidebar from "../../../../../customHooks/useHideSidebar";
import { getDataDomainDetail } from "../../../../../store/modules/UserManagement/dataDomains/dataDomainActions";
import {
  getUserGroupList,
  setUserGroupUserList,
  setUsersExistedInGroup
} from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import { SET_ACTIVE_UM_TAB_NAME } from "../../../../../store/modules/UserManagement/userGroup/UMActionTypes";
import {
  CREATED_BY,
  CREATED_ON,
  SMALLDATADOMAIN,
  SMALLGROUP,
  SMALLUSERS,
  USERSGROUP
} from "../../../../Common/iconSource";
import {  generateHtmlTitle, getRelativeTime } from "../../../common/helperFunctions";
import LandingpageFirstHeader from "../../../common/LandingpageFirstHeader";
import { Literals } from "../../../common/literals";
import LoadingComponent from "../../../common/loadingComponent";
import UserList from "../../userGroup/components/UserList";
import AccessPolicyList from "./AccessPolicyList ";
import ConnectionList from "./ConnectionList";
import DataDomainHeader from "./DataDomainHeader";
import "./dataDomainPage.scss";
import DomainDetails from "./DomainDetails";
import DomainDetailsLowerSection from "./DomainDetailsLowerSection";
import PodList from "./PodList";

const DataDomainDetails = ({ isUserGroups, ...props }) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [path, setpath] = useState("");
  const [isUserGroup, setisUserGroup] = useState(false);
  const store = useStore();
  const [details, setDetails] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [dataDomains, setDataDomains] = useState([]);
  const [userList, setUserList] = useState([]);
  const loadingStatus = useSelector(
    (state) => state.LoadingReducer.loadingStatus
  );
  const [tab, setTab] = useState("");

  useEffect(() => {
    setTimeout(() => {
      let el = document.querySelector('.usr-manage-usr-dataDomain-scrollable')
      if (el) {
        el.scroll(0, 0)
      }
    }, 1000)
  }, [])

  useEffect(() => {

    if (isUserGroups) {
      dispatch({ type: SET_ACTIVE_UM_TAB_NAME, payload: Literals.USERGROUP })

      setisUserGroup(true);
      setpath("UserGroup");
      setTab("userGroup");
      getUserGroupDetails();
    } else {
      dispatch({ type: SET_ACTIVE_UM_TAB_NAME, payload: Literals.DATA_DOMAIN })

      setisUserGroup(false);
      setpath("DataDomain");
      setTab("dataDomain");
      getDataDomainDetails();
    }
  }, [isUserGroups]);

  const getDataDomainDetails = async () => {
    let response;
    try {
      response = await store.dispatch(getDataDomainDetail(id));
      if (response.status === "success") {
        setDetails(response?.data);
        setUserGroups(response?.data?.group?.group_detail);
      }
    } catch (error) { }
  };




  const getDataDomainList = (list) => {
    let newList = [];
    for (let i = 0; i < list?.owner_permission.length; i++) {
      let obj = {
        key: list?.owner_permission[i]?.data_domain_id,
        dataDomain: list?.owner_permission[i]?.data_domain_name.toUpperCase(),
        description: list?.owner_permission[i]?.data_domain_description,
        // createdOn: getRelativeTime(list?.owner_permission[i]?.created_on),
        createdBy: list?.owner_permission[i]?.created_by_user?.username,
        updatedOn: getRelativeTime(list?.owner_permission[i]?.updated_on),
        updatedBy: list?.owner_permission[i]?.updated_by_user?.username,
        userGroups: list?.owner_permission[i]?.group,
        users: list?.owner_permission[i]?.user
      }
      newList.push(obj);
    }
    for (let i = 0; i < list?.user_permission.length; i++) {
      let obj = {
        key: list?.user_permission[i]?.data_domain_id,
        dataDomain: list?.user_permission[i]?.data_domain_name.toUpperCase(),
        description: list?.user_permission[i]?.data_domain_description,
        // createdOn: getRelativeTime(list?.user_permission[i]?.created_on),
        createdBy: list?.user_permission[i]?.created_by_user?.username,
        updatedOn: getRelativeTime(list?.user_permission[i]?.updated_on),
        updatedBy: list?.user_permission[i]?.updated_by_user?.username,
        userGroups: list?.user_permission[i]?.group,
        users: list?.user_permission[i]?.user
      }
      newList.push(obj);


    }

    setDataDomains([...newList]);
  };

  const getUserGroupDetails = async () => {
    let response;
    try {
      response = await store.dispatch(
        getUserGroupList({
          method: "GET",
          endPoint: `group_manager/group/${id}/`,
        })
      );
      if (response.status === "success") {
        setDetails(response?.data);
        getDataDomainList(response?.data?.data_domain);
        setUserList(response?.data?.user?.user_detail);
        dispatch(setUserGroupUserList(response?.data?.user));
        dispatch(setUsersExistedInGroup(response?.data));

        let title = generateHtmlTitle(response?.data?.name?.toUpperCase() + ' | User Group', 'User Management')
        document.title = title
      }
    } catch (error) { 
      let title = generateHtmlTitle("User Group", 'User Management')
      document.title = title
    }
  };

  const domainDetails = {
    domainDetail: {
      icon: isUserGroup ? <SMALLGROUP /> : <SMALLUSERS />,
      name: details?.data_domain_name || details?.name,
      description: details?.data_domain_description || details?.description,
    },
    userGroup: {
      icon: isUserGroup ? <SMALLDATADOMAIN /> : <USERSGROUP height="18" width="18" />,
      label: isUserGroup ? Literals.DOMAINS : Literals.USER_GROUPS,
      value: isUserGroup ? details?.data_domain?.count : details?.group?.count,
    },
    users: {
      icon: <SMALLUSERS />,
      label: Literals.USERS,
      value: isUserGroup ? details?.user?.count : details?.user,
    },
    createdBy: {
      icon: <CREATED_BY />,
      label: Literals.CREATED_BY,
      value: details?.created_by_user?.username,
    },
    updatedOn: {
      icon: <CREATED_ON height="18" />,
      label: Literals.UPDATED_ON,
      value: getRelativeTime(details.updated_on),
    },
    isUserGroup: isUserGroup,
  };

  useHideSidebar();

  return (
    <div className="data-domain-detail">
      <LandingpageFirstHeader
        title={Literals.USER_MANAGEMENT_HEADING}
        flag={true}
        backRoute={"/userManagement/?activeTab=" + tab}
      />
      <DataDomainHeader
        path={path}
        isUserGroup={isUserGroup}
        details={details}
        id={id}
      />
      <div className="data-domain-detail-page">
        <div
          className={`data-domain-detail-left-side usr-manage-usr-grp
           ${isUserGroup
              ? "data-domain-detail-usergroup"
              : "data-domain-detail-data-domain"
            }`}
        >
          <DomainDetails domainDetails={domainDetails} />
          <div className={`${!isUserGroup ? 'usr-manage-usr-dataDomain-scrollable' : ''}`}>
            {isUserGroup ? (
              <div className="usergroup-details-lower-section">
                <AccessPolicyList id={id} />
                <br></br>
                <DomainDetailsLowerSection
                  icon={<SMALLGROUP height="20" width="20" />}
                  heading={"Associated With The Following Domains"}
                  List={dataDomains}
                  dataDomain={true}
                  isUserGroup={isUserGroups}
                  id={id}
                />
              </div>

            ) : (
              <DomainDetailsLowerSection
                icon={<USERSGROUP height="20" width="20" />}
                heading={"List Of User Groups"}
                List={userGroups}
                userGroup={true}
                isUserGroup={isUserGroups}
                id={id}
              />
            )}
            {!isUserGroups ? <ConnectionList id={id} /> : null}
            {!isUserGroups ? <PodList id={id} /> : null}
          </div>


        </div>
        {isUserGroup ? (
          <div className="data-domain-detail-right-side">
            <UserList usersList={userList} />
          </div>
        ) : null}
      </div>

      {loadingStatus ? <LoadingComponent /> : null}
    </div>
  );
};

export default DataDomainDetails;
