import { combineReducers } from "redux";
import CreateAlert from "./createAlert/createAlertReducer";
import GetAlertDataReducer from "./alertLanding/alertLandingReducer";


const Alert = combineReducers({
    CreateAlert,
    GetAlertDataReducer
});
export default Alert
