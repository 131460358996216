import React, { useEffect, useRef, useState } from "react";
import Buttons from "../../../../Common/button/Buttons";
import { Literals } from "../../../common/literals";
import {

  MINUS,
  PLAY,
  PLUS,
  PROPERTIES,

} from "../../../../Common/iconSource";


import { connect, useDispatch, useStore } from "react-redux";

import _, { cloneDeep } from "lodash";
import { Tabs, Tooltip } from "antd";

import { columnNameRegex, columnNameRegexExp, getObjectLength, removeStartingUnderscores, showSimpleLoading } from "../../../common/helperFunctions";

import SingleSelection from "../../../../Common/dropDown/SingleSelection";
import { SCRIPTDEFAULTVIEW } from "../script/ScriptDefaultInfography";
import { LandingSimpleTable } from "../../../../Common/landingSimpleTable/LandingSimpleTable";

import { checkOutcomeColumnPresent, getInputFrame, getInputTransformerDetails } from "../commonFunctions/FlowsHelperFunction";


const dummyData = {
  old_name: "",
  aggregate_function: "",
  new_name: "",
  id: "",
  dataType: "",
  originalColumnName: "",
};

const groupKeysData = {
  key_name: "",
  id: "",
  dataType: "",
};

const AggregateTransformerIndex = ({
  flowsElements,
  transformerLoading,
  transformerNameList,
  currentTransformer,
  setshowCloseWarning,
  appliedFilterCriteria,
  setshowOverlay,
  isExecutePermission,
  updatedFlowRedux,
  setupdatedFlowRedux,
  ...props
}) => {



  const [outcomecolumnList, setoutcomecolumnList] = useState([]);
  const [groupbyColumnList, setgroupbyColumnList] = useState([]);

  const [transformerName, settransformerName] = useState("");
  const [data, setData] = useState([dummyData]);
  const [groupKeys, setgroupKeys] = useState([]);
  const [demo, setDemo] = useState([]);


  const dispatch = useDispatch();
  const store = useStore();

  const [showLoader, setshowLoader] = useState(false);


  const [toggleDefaultView, setToggleDefaultView] = useState(false);


  // const isfieldEmpty = () => {
  //   let validateColumn = data.every((el) => {
  //     return el.originalColumnName.trim().length > 0;
  //   });
  //   let validateCriteria = data.every((el) => {
  //     return el.aggregate_function.trim().length > 0;
  //   });
  //   let validateValue = data.every((el) => {
  //     if (typeof el?.new_name === "string") {
  //       return el?.new_name?.trim()?.length > 0;
  //     } else if (typeof el?.new_name === "object") {
  //       return (
  //         el?.new_name?.[0]?.trim().length > 0 &&
  //         el?.new_name?.[1]?.trim().length > 0
  //       );
  //     }
  //   });
  //   if (!validateColumn || !validateCriteria || !validateValue) {
  //     emitToastNotification(
  //       "info",
  //       Literals.EMPTY_FIELD_FOUND_IN_AGGREGATE_TRANSFORMER
  //     );
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const getAggregateFunc = (selectedColumnType) => {

    let extractDatatype = selectedColumnType?.split("(")[0];

    switch (extractDatatype?.toLowerCase()) {
      case "string":
      case "varchar":
      case "char":
      case "date":
      case "time":
      case "timestamp":
        return [
          { Name: "Max", value: "max" },
          { Name: "Min", value: "min" },
          { Name: "Arbitrary", value: "arbitrary" },
          { Name: "Count", value: "count" },
          { Name: "Count distinct", value: "count distinct" },
        ];

        break;
      case "integer":
      case "number":
      case "smallint":
      case "int":
      case "float":
      case "double":
      case "decimal":
      case "tinyint":
      case "bigint":
      case "real":
        return [
          { Name: "Average", value: "avg" },
          { Name: "Count", value: "count" },
          { Name: "Max", value: "max" },
          { Name: "Min", value: "min" },
          { Name: "Sum", value: "sum" },
          { Name: "Stddev", value: "stddev" },
          { Name: "Arbitrary", value: "arbitrary" },
          { Name: "Variance", value: "variance" },
          { Name: "Count distinct", value: "count distinct" },
        ];

      default:
        break;
    }
  };

  useEffect(() => {
    updateTransformerContent();
  }, [groupKeys, data])


  const updateTransformerContent = () => {
    // !isfieldEmpty() &&
    // if (
    //   groupKeys &&
    //   groupKeys.length && data?.length
    // ) {


    // data.forEach((item) => {
    //   if (item.aggregate_function === "count_distinct") {
    //     item.aggregate_function = "count";
    //     item["old_name"] = `distinct ${item["old_name"]}`;

    //   }
    // });

    let newflowsElements = cloneDeep(store.getState().Lab.flowsElements)
    settransformerName(transformerName)
    newflowsElements?.nodes.forEach((item) => {
      if (item.id === currentTransformer.id) {
        item.content.aggregate_columns = data;
        item.content.groupByColumns = groupKeys;
      }

    });
    setupdatedFlowRedux(newflowsElements)
    // store.dispatch(updateFlowsElements(flowsElements));


    // };
  }



  useEffect(() => {
    const getColumn = async () => {


      let newcurrentTransformer = {}

      if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

        newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
      } else {
        newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

      }

      let sourceContent = newcurrentTransformer?.content;



      let groupKeys = [];
      let data = [];

      if (sourceContent?.aggregate_columns?.length) {
        data = sourceContent?.aggregate_columns;
        setData(sourceContent?.aggregate_columns);
      } else {
        setData([{ ...dummyData }]);
        data = [{ ...dummyData }];
      }

      if (sourceContent?.groupByColumns?.length) {
        groupKeys = sourceContent?.groupByColumns;
        setgroupKeys(sourceContent?.groupByColumns);
      } else {
        groupKeys = [{ ...groupKeysData }];
        setgroupKeys([{ ...groupKeysData }]);
      }
      let InputTransformer = dispatch(getInputTransformerDetails(newcurrentTransformer, flowsElements))

      // let inputColumns = cloneDeep(InputTransformer[0]?.content?.outcomeColumns);
      let inputColumns = [];

      if (InputTransformer && InputTransformer?.length) {
        setshowLoader(true)
        inputColumns = await dispatch(checkOutcomeColumnPresent(InputTransformer[0]));
        setshowLoader(false)

      }
      if (InputTransformer && InputTransformer?.length && inputColumns?.length) {

        let column = [];
        inputColumns.forEach((item) => {
          column.push({
            id: item.id,
            Name: item.Name,
            Type: item.Type,
            Label: item.Name,
            columnValue: item.Name,
          });
        });
        if (column?.length) {
          let newoutcomeColumns = getNewColumnList(
            "outcomeColumns",
            "",
            column,
            column,
            groupKeys,
            data
          );
          setgroupbyColumnList([...newoutcomeColumns]);
          let groupByColumns = getNewColumnList(
            "groupKeys",
            "",
            column,
            column,
            groupKeys,
            data
          );
          setoutcomecolumnList([...groupByColumns]);

          setToggleDefaultView(false)
          setshowOverlay(false);


        } else {

          setgroupbyColumnList([]);

          setoutcomecolumnList([]);
          setshowOverlay(true);


        }


      } else if (!InputTransformer?.length) {
        setToggleDefaultView(true);
        setshowLoader(false)


      } else if (!inputColumns?.length) {
        setshowOverlay(true);
        setshowLoader(false)

      }
    }
    getColumn()
  }, []);



  const onAddGroupKeys = () => {
    setshowCloseWarning(true)
    setgroupKeys([
      ...groupKeys,
      {
        id: "",
        key_name: "",
      },
    ]);
  };

  const onAddFields = () => {
    setshowCloseWarning(true)
    setData([
      ...data,
      {
        old_name: "",
        aggregate_function: "",
        new_name: "",
        id: "",
        dataType: "",
        originalColumnName: "",
      },
    ]);
  };

  const onRemoveField = (index) => {
    setshowCloseWarning(true)
    let dataCopy = [...data];
    let columnValue = dataCopy[index]["originalColumnName"];
    dataCopy.splice(index, 1);
    let newColumnList1 = getNewColumnList(
      "removekeyOutcomeColumn",
      columnValue,
      outcomecolumnList,
      groupbyColumnList,
      groupKeys,
      dataCopy
    );
    setoutcomecolumnList([...newColumnList1]);
    let newColumnList2 = getNewColumnList(
      "removekeyGroupKeys",
      columnValue,
      outcomecolumnList,
      groupbyColumnList,
      groupKeys,
      dataCopy
    );
    setgroupbyColumnList([...newColumnList2]);

    setData([...dataCopy]);
  };

  const onRemoveGroupKeys = (index) => {
    setshowCloseWarning(true)
    let dataCopy = [...groupKeys];
    let columnValue = dataCopy[index]["key_name"]

    dataCopy.splice(index, 1);

    let newColumnList = getNewColumnList(
      "removekeyGroupKeys",
      columnValue,
      outcomecolumnList,
      groupbyColumnList,
      groupKeys,
      dataCopy
    );
    setgroupbyColumnList([...newColumnList]);
    let newColumnList1 = getNewColumnList(
      "removekeyOutcomeColumn",
      columnValue,
      outcomecolumnList,
      groupbyColumnList,
      groupKeys,
      dataCopy
    );
    setoutcomecolumnList([...newColumnList1]);

    setgroupKeys([...dataCopy]);
  };
  const onInputChangeHandler = (field, value, index) => {
    let dataCopy = [...data];


    dataCopy[index][field] = value;

    if (field === 'aggregate_function') {
      if (new_name_aggrigate.current[index]) {
        new_name_aggrigate.current[index].value = `${dataCopy[index]['originalColumnName']?.toLowerCase()}_${value?.toLowerCase()}`?.replace(' ', '_')
      }
      dataCopy[index]['new_name'] = `${dataCopy[index]['originalColumnName']?.toLowerCase()}_${value?.toLowerCase()}`?.replace(' ', '_')
    }

    if (field === "originalColumnName") {
      if (new_name_aggrigate.current[index]) {

        new_name_aggrigate.current[index].value = `${value?.toLowerCase()}_${dataCopy[index]['aggregate_function']?.toLowerCase()}`?.replace(' ', '_')
      }

      dataCopy[index]['new_name'] = `${value?.toLowerCase()}_${dataCopy[index]['aggregate_function']?.toLowerCase()}`?.replace(' ', '_')
      let selectedColumn = outcomecolumnList.find(
        (item) => item.Name.toLowerCase() === value.toLowerCase()
      );
      if (selectedColumn && selectedColumn.id) {
        dataCopy[index]["id"] = selectedColumn.id;
        if (field === 'new_name') {
          removeStartingUnderscores(value.replaceAll(columnNameRegexExp, '_'))
          dataCopy[index]["new_name"] = value;

        }
        dataCopy[index]["old_name"] = value;
        dataCopy[index]["dataType"] = selectedColumn.Type;
      }
      let newColumnList = getNewColumnList(
        "outcomeColumns",
        value,
        outcomecolumnList,
        groupbyColumnList,
        groupKeys,
        dataCopy
      );

      setgroupbyColumnList([...newColumnList]);
    }
    setshowCloseWarning(true)

    setData([...dataCopy]);
  };

  const getNewColumnList = (
    from,
    value,
    outcomecolumnList,
    groupbyColumnList,
    groupKeys,
    data
  ) => {
    let copyGroupbyColumns = cloneDeep(groupbyColumnList);
    let copyOutcomeColumns = cloneDeep(outcomecolumnList);
    let selectedGroupKeys = groupKeys.map((item) => item.key_name);
    let selectedOldColumn = data.map((item) => item.originalColumnName);

    let newColumnList = [];
    if (from === "outcomeColumns") {
      newColumnList = copyGroupbyColumns.map((ele) => {
        if (selectedOldColumn.includes(ele.Name) || selectedGroupKeys.includes(ele.Name)) {
          ele.isDisableOption = true;
        } else {
          ele.isDisableOption = false;
        }
        return ele;
      });
    } else if (from === "groupKeys") {

      newColumnList = copyOutcomeColumns.map((ele) => {
        if (selectedGroupKeys?.includes(ele.Name)) {
          ele.isDisableOption = true;
        }
        else {
          ele.isDisableOption = false;
        }
        return ele;
      });
    }

    if (from === "removekeyOutcomeColumn") {

      newColumnList = copyOutcomeColumns.map((ele) => {
        if (ele.Name === value) {
          ele.isDisableOption = false;
        }
        return ele;
      });
    }
    if (from === "removekeyGroupKeys") {

      newColumnList = copyGroupbyColumns.map((ele) => {
        if (!selectedOldColumn.includes(value) && ele.Name?.toLowerCase() === value) {
          ele.isDisableOption = false;
        }
        return ele;
      });
    }

    return newColumnList;
  };

  const onGroupKeySelect = (field, value, index) => {
    let dataCopy = [...groupKeys];

    dataCopy[index][field] = value;
    setgroupKeys([...dataCopy]);
    let selectedColumn = groupbyColumnList.find(
      (item) => item.Name.toLowerCase() === value.toLowerCase()
    );
    if (selectedColumn && selectedColumn.id) {
      dataCopy[index]["id"] = selectedColumn.id;
      dataCopy[index]["new_name"] = value;
      dataCopy[index]["dataType"] = selectedColumn.Type;
    }

    let newColumnList1 = getNewColumnList(
      "groupKeys",
      value,
      outcomecolumnList,
      groupbyColumnList,
      dataCopy,
      data
    );

    setoutcomecolumnList([...newColumnList1]);

    let newColumnList2 = getNewColumnList(
      "outcomeColumns",
      value,
      outcomecolumnList,
      groupbyColumnList,
      dataCopy,
      data
    );

    setgroupbyColumnList([...newColumnList2]);
  };

  function Validate(e) {
    e.stopPropagation()
    let keyCode = e.key

    //Regex to allow only Alphabets Numbers Dash Underscore and Space
    let pattern = /^[a-zA-Z0-9_]*$/;

    //Validating the textBox value against our regex pattern.
    let isValid = pattern.test(keyCode);
    let match = keyCode.match(pattern)
    if ((!new_name_aggrigate.current?.value?.length && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106))) || (new_name_aggrigate.current?.selectionStart === 0 && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)))) {
      e.preventDefault()
    } else if (!isValid) {
      e.preventDefault()
    }
  }

  const new_name_aggrigate = useRef([])

  useEffect(() => {
    let temp = [];
    let rows = [...data];

    temp = rows.map((v, index) => ({
      originalColumnName: (
        <>
          <div className="align-items-center d-flex h-100 justify-content-between">
            <SingleSelection
              props={{
                placeholder: "Select column",
                dropDownValue: v.originalColumnName,
                isSortOptionDisable: true,
                onClickEvent: (val) => {
                  currentSelctedColValue.current = val;
                  onInputChangeHandler("originalColumnName", val, index);
                },
                optionList: outcomecolumnList,
                isDisable: !isExecutePermission,
                selectClassName: (!isColumnValuePresent(outcomecolumnList, v.originalColumnName, "originalColumnName"))
                  ? "invalid-column-present w-100 custom-select-dropdown"
                  : " w-100 custom-select-dropdown",
              }}
            />
          </div>
        </>
      ),
      aggregate_function: (
        <>
          <div className="align-items-center d-flex h-100 justify-content-between">
            <SingleSelection
              props={{
                placeholder: "Select function",
                dropDownValue: v.aggregate_function,
                onClickEvent: (val) =>
                  onInputChangeHandler("aggregate_function", val, index),
                optionList: getAggregateFunc(v.dataType),
                isDisable: !isExecutePermission,
                selectClassName: "w-100",
              }}
            />
          </div>
        </>
      ),
      new_name: (
        <>
          <div className="align-items-center d-flex h-100 justify-content-between">

            <input
              disabled={!isExecutePermission}
              key={v.id}
              ref={(element) => (new_name_aggrigate.current[index] = element)}
              itemRef="new_name_aggrigate"
              onKeyDown={(e) => Validate(e)}
              onClick={(e) => e.stopPropagation()}
              className={"custom-input-field"}
              type="text"
              placeholder="Enter Value"
              defaultValue={v.new_name}
              onChange={(e) => {
                // if (columnNameRegex.test(e.target.value) || e.target.value === "") {
                onInputChangeHandler("new_name", e?.target?.value, index);
                // }

              }}
            />
            <Buttons
              props={{
                buttonText: "",
                buttonClassName: "danger-btn custom-btn btn-with-icon ml-2",
                buttonEvent: () => {
                  onRemoveField(index);
                },
                toggleBtnValue: "",
                ImgSrc: () => <MINUS />,
                isDisable: !isExecutePermission,
                buttonType: Literals.BTN_TERTIARY,
                toggleBtnOption: null,
              }}
            />
          </div>
        </>
      ),
    }));

    setDemo([...temp]);
  }, [data, groupbyColumnList, outcomecolumnList]);

  const currentSelctedGroup = useRef('')
  const currentSelctedColValue = useRef('')

  const isColumnValuePresent = (columnList, columnValue = '', key) => {

    if (columnList?.length && columnValue !== "") {
      let isPresent = columnList.findIndex(element => element.Name === columnValue);
      if (isPresent > -1) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const getGroupByRows = (v, i) => {
    return {
      columns: (
        <>
          <div
            key={`aggregate-colli-${i}`}
            className="align-items-center w-100 d-flex h-100 agg-grp-key-tbl-inp"
          >
            <SingleSelection
              props={{
                placeholder: "Select column",
                dropDownValue: v.key_name,
                onClickEvent: (value) => { currentSelctedGroup.current = value; onGroupKeySelect("key_name", value, i) },
                optionList: groupbyColumnList,
                isSortOptionDisable: true,

                isDisable: !isExecutePermission,
                selectClassName: (!isColumnValuePresent(groupbyColumnList, v.key_name))
                  ? "invalid-column-present w-100 custom-select-dropdown"
                  : " w-100 custom-select-dropdown",
              }}
            />
            <Buttons
              props={{
                buttonText: "",
                buttonClassName: "danger-btn custom-btn btn-with-icon ml-2",
                buttonEvent: () => {
                  onRemoveGroupKeys(i);
                },
                toggleBtnValue: "",
                ImgSrc: () => <MINUS />,
                isDisable: !isExecutePermission,
                buttonType: Literals.BTN_TERTIARY,
                toggleBtnOption: null,
              }}
            />

          </div>
        </>
      ),
    };
  };
  return (
    <>
      {!showLoader ? <div className="transformer-details-maindiv aggrigate-main-div">
        {!toggleDefaultView ? (
          <>
            <div className="bg-white section-with-drag p-3 flow-filter-height h-100">
              <div className="h-100">
                <div className="d-flex bg-white section-with-drag merge-drag flow-with-drag">
                  <div
                    style={{ width: "30%" }}
                    className="custom-dashed-border-right position-relative pr-3"
                  >
                    <div className="d-flex justify-content-between padding-2 py-1 custom-border-bottom">
                      <div className="d-flex align-items-center">

                        <p className="paragraph ">
                          Group Keys
                          <Tooltip
                            title={
                              "Create a group for each unique combination of these column"
                            }
                            placement="bottom"
                          >
                            {" "}
                            <i
                              class="bx bxs-info-circle"
                              style={{ color: "#828282", fontSize: "0.75rem" }}
                            ></i>
                          </Tooltip>{" "}
                        </p>
                      </div>

                      <div className="d-flex align-items-center">
                        <Buttons
                          props={{
                            buttonText: "Add Key",

                            buttonClassName:
                              "custom-btn custom-btn-outline btn-outline btn-with-text",
                            buttonEvent: () => onAddGroupKeys(),
                            ImgSrc: () => <PLUS />,
                            isDisable: !isExecutePermission,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                          }}
                        />
                      </div>
                    </div>

                    <div className="aggrigate-left-table" style={{ height: "calc(100vh - 12.9375rem)" }}>
                      {/* <DndVirtualList */}
                      <LandingSimpleTable
                        rowsData={groupKeys.map((v, i) => {
                          return getGroupByRows(v, i);
                        })}
                        cols={groupByColumns}
                        tableHeight={"100%"}
                      />
                      {/* rawData={[]} */}
                      {/* /> */}
                    </div>
                  </div>
                  <div className="pl-3 " style={{ width: "70%" }}>
                    <div className="d-flex justify-content-between custom-border-bottom padding-2  py-1">
                      <div className="d-flex align-items-center op-col-agg-ic">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.39615 4.81048C8.16831 4.81048 8.79427 5.43643 8.79427 6.20859V17.7929C8.79427 18.5651 8.16831 19.191 7.39615 19.191H5.39885C4.6267 19.191 4.00073 18.5651 4.00073 17.7929V6.20859C4.00073 5.47505 4.56566 4.87344 5.28418 4.81511L5.39885 4.81048H7.39615ZM18.6019 4.81048C19.3741 4.81048 20 5.43643 20 6.20859V17.7929C20 18.5651 19.3741 19.191 18.6019 19.191H16.6046C15.8324 19.191 15.2065 18.5651 15.2065 17.7929V6.20859C15.2065 5.43643 15.8324 4.81048 16.6046 4.81048H18.6019ZM12.9886 4.80981C13.7608 4.80981 14.3867 5.43577 14.3867 6.20793V17.7923C14.3867 18.5645 13.7608 19.1904 12.9886 19.1904H10.9913C10.2192 19.1904 9.59319 18.5645 9.59319 17.7923V6.20793C9.59319 5.43577 10.2192 4.80981 10.9913 4.80981H12.9886ZM7.39615 6.00886H5.39885L5.35305 6.01413C5.26482 6.03483 5.19912 6.11404 5.19912 6.20859V17.7929C5.19912 17.9033 5.28854 17.9927 5.39885 17.9927H7.39615C7.50647 17.9927 7.59588 17.9033 7.59588 17.7929V6.20859C7.59588 6.09828 7.50647 6.00886 7.39615 6.00886ZM18.6019 6.00886H16.6046C16.4943 6.00886 16.4049 6.09828 16.4049 6.20859V17.7929C16.4049 17.9033 16.4943 17.9927 16.6046 17.9927H18.6019C18.7122 17.9927 18.8016 17.9033 18.8016 17.7929V6.20859C18.8016 6.09828 18.7122 6.00886 18.6019 6.00886ZM12.9886 6.0082H10.9913C10.881 6.0082 10.7916 6.09762 10.7916 6.20793V17.7923C10.7916 17.9026 10.881 17.992 10.9913 17.992H12.9886C13.0989 17.992 13.1883 17.9026 13.1883 17.7923V6.20793C13.1883 6.09762 13.0989 6.0082 12.9886 6.0082Z"
                            fill="#1F1F3F"
                          />
                        </svg>
                        <p className="paragraph ml-2">
                          Output Columns
                          <Tooltip
                            title={
                              "Select aggregation to be computed for each column"
                            }
                            placement="bottom"
                          >
                            {" "}
                            <i
                              className="bx bxs-info-circle"
                              style={{ color: "#828282", fontSize: "0.75rem" }}
                            ></i>
                          </Tooltip>{" "}
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <Buttons
                          props={{
                            buttonText: "Add Agg. Function",

                            buttonClassName:
                              "custom-btn custom-btn-outline btn-with-text btn-outline",
                            buttonEvent: () => onAddFields(),
                            ImgSrc: () => <PLUS />,
                            isDisable: !isExecutePermission,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                          }}
                        />
                      </div>

                    </div>
                    <div className="h-100">
                      <div className="aggrigate-right-table"
                        style={{ overflow: "auto", height: "calc(100vh - 207px)" }}
                      >
                        <LandingSimpleTable
                          cols={columns}
                          rowsData={demo}
                          tableHeight={"100%"}
                        // autoWidth={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : toggleDefaultView ? (
          <div className="source-not-found">
            <SCRIPTDEFAULTVIEW />
          </div>
        ) : null}

      </div> :
        showSimpleLoading('Please wait loading transformer details...')}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.Lab.flowsElements,
    currentTransformer: state.Lab.currentTransformer,
  };
};
export default connect(mapStateToProps)(AggregateTransformerIndex);

const columns = [
  {
    name: (
      <label className="font-w-600 grey-color label mb-0 paragraph">
        Agg. On Columns
      </label>
    ),
    key: "originalColumnName",
    sortable: false,
    resizable: false,

    // width:10
  },
  {
    name: (
      <label className="font-w-600 grey-color label mb-0 paragraph">
        Agg. Function
      </label>
    ),
    key: "aggregate_function",
    sortable: false,
    resizable: false,
    // width:50
  },
  {
    name: (
      <label className="font-w-600 grey-color label mb-0 paragraph">
        Agg. Column Name
      </label>
    ),
    key: "new_name",
    sortable: false,
    resizable: false,
    // width:100

  },
];

const groupByColumns = [
  {
    name: (
      <label className="font-w-600 grey-color label mb-0 paragraph">
        Agg. By Columns
      </label>
    ),
    key: "columns",
    sortable: false,
    resizable: false,
  },
];
