import React from "react"

export const LIN_POD_ICON = () => <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.875 12.3724C5.875 8.85732 8.72451 6.00781 12.2396 6.00781H34.7604C38.2754 6.00781 41.125 8.85732 41.125 12.3724V34.8932C41.125 38.4082 38.2754 41.2578 34.7604 41.2578H12.2396C8.72451 41.2578 5.875 38.4082 5.875 34.8932V12.3724ZM12.2396 8.94531C10.3469 8.94531 8.8125 10.4797 8.8125 12.3724V16.7786H16.6458V8.94531H12.2396ZM8.8125 19.7161V27.5495H16.6458V19.7161H8.8125ZM19.5833 19.7161V27.5495H27.4167V19.7161H19.5833ZM30.3542 19.7161V27.5495H38.1875V19.7161H30.3542ZM27.4167 30.487H19.5833V38.3203H27.4167V30.487ZM30.3542 38.3203H34.7604C36.6531 38.3203 38.1875 36.786 38.1875 34.8932V30.487H30.3542V38.3203ZM30.3542 16.7786H38.1875V12.3724C38.1875 10.4797 36.6531 8.94531 34.7604 8.94531H30.3542V16.7786ZM27.4167 8.94531H19.5833V16.7786H27.4167V8.94531ZM8.8125 30.487V34.8932C8.8125 36.786 10.3469 38.3203 12.2396 38.3203H16.6458V30.487H8.8125Z" fill="#212121"/>
</svg>

export const LIN_TABLE_ICON = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.48278 0.666626C2.82363 0.666626 0.667969 2.82229 0.667969 5.48144V22.5185C0.667969 25.1776 2.82363 27.3333 5.48278 27.3333H22.5198C25.1789 27.3333 27.3346 25.1776 27.3346 22.5185V5.48144C27.3346 2.82229 25.1789 0.666626 22.5198 0.666626H5.48278ZM2.89019 5.48144C2.89019 4.04959 4.05093 2.88885 5.48278 2.88885H22.5198C23.9517 2.88885 25.1124 4.04959 25.1124 5.48144V8.81477H2.89019V5.48144ZM11.0383 11.037H16.9643V16.9629H11.0383V11.037ZM8.81612 11.037V16.9629H2.89019V11.037H8.81612ZM8.81612 19.1851V25.1111H5.48278C4.05093 25.1111 2.89019 23.9503 2.89019 22.5185V19.1851H8.81612ZM11.0383 25.1111V19.1851H16.9643V25.1111H11.0383ZM19.1865 16.9629V11.037H25.1124V16.9629H19.1865ZM19.1865 19.1851H25.1124V22.5185C25.1124 23.9503 23.9517 25.1111 22.5198 25.1111H19.1865V19.1851Z"
        fill="#17171A"
      />
    </svg>
  );
export const LIN_ALERT_ICON = () => <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4377 3.24365C27.0188 3.24365 32.3923 8.43512 32.6183 15.0234L32.6252 15.4312V22.0888L34.8677 27.2173C34.9798 27.4739 35.0378 27.7509 35.0378 28.0311C35.0378 29.1528 34.1283 30.0623 33.0066 30.0623L25.3127 30.0648C25.3127 32.7571 23.13 34.9398 20.4377 34.9398C17.8414 34.9398 15.7192 32.9102 15.5709 30.3511L15.5619 30.061L7.8843 30.0623C7.60587 30.0623 7.33043 30.005 7.07505 29.8942C6.0461 29.4471 5.57428 28.2508 6.02122 27.2218L8.25015 22.0903V15.431C8.25113 8.67946 13.6973 3.24365 20.4377 3.24365ZM22.8744 30.061L18.0002 30.0648C18.0002 31.4109 19.0915 32.5023 20.4377 32.5023C21.7047 32.5023 22.7458 31.5356 22.864 30.2994L22.8744 30.061ZM20.4377 5.68115C15.0424 5.68115 10.6884 10.0268 10.6877 15.4312V22.5968L8.50368 27.6248H32.3855L30.1877 22.5984L30.1878 15.452L30.1818 15.0861C30.0013 9.83169 25.7053 5.68115 20.4377 5.68115Z" fill="#17171A"/>
</svg>
export const LIN_CHART_ICON=()=> <svg width="24"
height="24"
viewBox="0 0 24 24"
fill="none"
xmlns="http://www.w3.org/2000/svg"
><path
  d="M3 3.75C3 3.33579 3.33579 3 3.75 3C4.16421 3 4.5 3.33579 4.5 3.75V19.5H20.25C20.6642 19.5 21 19.8358 21 20.25C21 20.6642 20.6642 21 20.25 21H3.75C3.33579 21 3 20.6642 3 20.25V3.75Z"
  fill="#17171A"/>
<path
  d="M17 4C15.3431 4 14 5.34315 14 7C14 8.65685 15.3431 10 17 10C18.6569 10 20 8.65685 20 7C20 5.34315 18.6569 4 17 4ZM15.5 7C15.5 6.17157 16.1716 5.5 17 5.5C17.8284 5.5 18.5 6.17157 18.5 7C18.5 7.82843 17.8284 8.5 17 8.5C16.1716 8.5 15.5 7.82843 15.5 7Z"
  fill="#17171A"
/>
<path
  d="M6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9ZM9 7.5C8.17157 7.5 7.5 8.17157 7.5 9C7.5 9.82843 8.17157 10.5 9 10.5C9.82843 10.5 10.5 9.82843 10.5 9C10.5 8.17157 9.82843 7.5 9 7.5Z"
  fill="#17171A"
/>
<path
  d="M15 12C13.3431 12 12 13.3431 12 15C12 16.6569 13.3431 18 15 18C16.6569 18 18 16.6569 18 15C18 13.3431 16.6569 12 15 12ZM13.5 15C13.5 14.1716 14.1716 13.5 15 13.5C15.8284 13.5 16.5 14.1716 16.5 15C16.5 15.8284 15.8284 16.5 15 16.5C14.1716 16.5 13.5 15.8284 13.5 15Z"
  fill="#17171A"
/>
</svg>

export const PROPERTIES = () => (
<svg
width="24"
height="24"
viewBox="0 0 24 24"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M8.75 13.5C10.2862 13.5 11.5735 14.5658 11.9126 15.9983L21.25 16C21.6642 16 22 16.3358 22 16.75C22 17.1297 21.7178 17.4435 21.3518 17.4932L21.25 17.5L11.9129 17.5007C11.5741 18.9337 10.2866 20 8.75 20C7.21345 20 5.92594 18.9337 5.58712 17.5007L2.75 17.5C2.33579 17.5 2 17.1642 2 16.75C2 16.3703 2.28215 16.0565 2.64823 16.0068L2.75 16L5.58712 15.9993C5.92594 14.5663 7.21345 13.5 8.75 13.5ZM8.75 15C7.98586 15 7.33611 15.4898 7.09753 16.1725L7.07696 16.2352L7.03847 16.3834C7.01326 16.5016 7 16.6242 7 16.75C7 16.9048 7.02011 17.055 7.05785 17.1979L7.09766 17.3279L7.12335 17.3966C7.38055 18.0431 8.01191 18.5 8.75 18.5C9.51376 18.5 10.1632 18.0107 10.4021 17.3285L10.4422 17.1978L10.4251 17.2581C10.4738 17.0973 10.5 16.9267 10.5 16.75C10.5 16.6452 10.4908 16.5425 10.4731 16.4428L10.4431 16.3057L10.4231 16.2353L10.3763 16.1024C10.1188 15.4565 9.48771 15 8.75 15ZM15.25 4C16.7866 4 18.0741 5.06632 18.4129 6.49934L21.25 6.5C21.6642 6.5 22 6.83579 22 7.25C22 7.6297 21.7178 7.94349 21.3518 7.99315L21.25 8L18.4129 8.00066C18.0741 9.43368 16.7866 10.5 15.25 10.5C13.7134 10.5 12.4259 9.43368 12.0871 8.00066L2.75 8C2.33579 8 2 7.66421 2 7.25C2 6.8703 2.28215 6.55651 2.64823 6.50685L2.75 6.5L12.0874 6.49833C12.4265 5.06582 13.7138 4 15.25 4ZM15.25 5.5C14.4859 5.5 13.8361 5.98976 13.5975 6.6725L13.577 6.73515L13.5385 6.88337C13.5133 7.0016 13.5 7.12425 13.5 7.25C13.5 7.40483 13.5201 7.55497 13.5579 7.69794L13.5977 7.82787L13.6234 7.89664C13.8805 8.54307 14.5119 9 15.25 9C16.0138 9 16.6632 8.51073 16.9021 7.82852L16.9422 7.69781L16.9251 7.75808C16.9738 7.59729 17 7.4267 17 7.25C17 7.14518 16.9908 7.04251 16.9731 6.94275L16.9431 6.80565L16.9231 6.73529L16.8763 6.60236C16.6188 5.95647 15.9877 5.5 15.25 5.5Z"
  fill="#1F1F3F"
/>
</svg>
);

export const EDITOR = () => (
<svg
width="24"
height="24"
viewBox="0 0 24 24"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M17.1111 4C18.7066 4 20 5.2934 20 6.88889V13.0033C20 13.5337 19.7892 14.0424 19.4142 14.4175L14.4175 19.4142C14.0424 19.7892 13.5337 20 13.0033 20H6.88889C5.2934 20 4 18.7066 4 17.1111V6.88889C4 5.2934 5.2934 4 6.88889 4H17.1111ZM17.1111 5.33333H6.88889C6.02978 5.33333 5.33333 6.02978 5.33333 6.88889V17.1111C5.33333 17.9702 6.02978 18.6667 6.88889 18.6667H12.8889V15.7778C12.8889 14.2373 14.0947 12.9785 15.6139 12.8934L15.7778 12.8889H18.6667V6.88889C18.6667 6.02978 17.9702 5.33333 17.1111 5.33333ZM17.7236 14.2222H15.7778C14.9616 14.2222 14.2923 14.8508 14.2274 15.6502L14.2222 15.7778V17.7236L17.7236 14.2222Z"
  fill="#1F1F3F"
/>
</svg>)
export const LIN_FOLDER_ICON = () => (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.821 2.5H17.75C18.8867 2.5 19.8266 3.34297 19.9785 4.43788L19.9948 4.59595L20 4.75V13.75C20 14.9409 19.0748 15.9156 17.904 15.9948L17.75 16H2.25C1.05914 16 0.0843599 15.0748 0.0051899 13.904L0 13.75V6.499L6.20693 6.5L6.40335 6.4914C6.79396 6.4572 7.16896 6.3214 7.49094 6.0977L7.64734 5.9785L11.821 2.5ZM6.20693 0C6.66749 0 7.1153 0.14129 7.49094 0.40235L7.64734 0.5215L9.75 2.273L6.68706 4.82617L6.60221 4.88738C6.51363 4.94232 6.41452 4.9782 6.31129 4.9927L6.20693 5L0 4.999V2.25C0 1.05914 0.92516 0.0843599 2.09595 0.00518989L2.25 0H6.20693Z"
        fill="#2C83C4"
      />
    </svg>
  );
  
export const LIN_FLOWS_ICON = () => <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M34.6382 30.0281L34.6382 24.6028L29.2129 24.6028L29.2129 30.0281L34.6382 30.0281ZM37.375 21.866L26.476 21.866L26.476 32.7649L37.375 32.7649L37.375 21.866Z" fill="#17171A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.2955 28.1257L9.39551 24.2257L5.49552 28.1257L9.39551 32.0257L13.2955 28.1257ZM9.39551 20.3552L1.62504 28.1257L9.39551 35.8962L17.166 28.1257L9.39551 20.3552Z" fill="#17171A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.34907 11.3441C11.2772 11.3441 12.8402 9.78108 12.8402 7.85298C12.8402 5.92488 11.2772 4.36184 9.34907 4.36184C7.42097 4.36184 5.85794 5.92488 5.85794 7.85298C5.85794 9.78108 7.42097 11.3441 9.34907 11.3441ZM9.34907 14.081C12.7887 14.081 15.5771 11.2926 15.5771 7.85298C15.5771 4.41336 12.7887 1.625 9.34907 1.625C5.90946 1.625 3.12109 4.41336 3.12109 7.85298C3.12109 11.2926 5.90946 14.081 9.34907 14.081Z" fill="#17171A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.09665 21.7808V12.658H10.8335V21.7808H8.09665Z" fill="#17171A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.3946 26.342L28.1665 26.342L28.1665 29.0789L15.3946 29.0789L15.3946 26.342Z" fill="#17171A"/>
</svg>

export const LIN_USER_GROUP_ICON = () => <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.9564 23.5C27.3898 23.5 29.3627 25.4727 29.3627 27.9062L29.3603 30.1045C29.6626 35.4994 25.5073 38.1898 17.8018 38.1898C10.1212 38.1898 5.875 35.5346 5.875 30.1815V27.9062C5.875 25.4727 7.84775 23.5 10.2812 23.5H24.9564ZM36.7082 23.5C39.1416 23.5 41.1144 25.4727 41.1144 27.9062L41.1122 29.453C41.3778 34.2925 37.7062 36.7188 30.9953 36.7188C30.0843 36.7188 29.228 36.6744 28.4281 36.5855C29.2819 35.8002 29.918 34.8625 30.3232 33.7736L30.9953 33.7812C36.2364 33.7812 38.3319 32.3965 38.1769 29.5335V27.9062C38.1769 27.0951 37.5194 26.4375 36.7082 26.4375H30.6462C30.3489 25.282 29.7088 24.2643 28.8411 23.4988L36.7082 23.5ZM24.9564 26.4375H10.2812C9.47009 26.4375 8.8125 27.0951 8.8125 27.9062V30.1815C8.8125 33.5479 11.5383 35.2523 17.8018 35.2523C24.0404 35.2523 26.6133 33.5865 26.4252 30.1868V27.9062C26.4252 27.0951 25.7676 26.4375 24.9564 26.4375ZM17.625 5.875C21.6815 5.875 24.9699 9.16344 24.9699 13.2199C24.9699 17.2765 21.6815 20.5649 17.625 20.5649C13.5685 20.5649 10.2801 17.2765 10.2801 13.2199C10.2801 9.16344 13.5685 5.875 17.625 5.875ZM32.3125 8.8125C35.5571 8.8125 38.1875 11.4428 38.1875 14.6875C38.1875 17.9321 35.5571 20.5625 32.3125 20.5625C29.0679 20.5625 26.4375 17.9321 26.4375 14.6875C26.4375 11.4428 29.0679 8.8125 32.3125 8.8125ZM17.625 8.8125C15.1908 8.8125 13.2176 10.7858 13.2176 13.2199C13.2176 15.6541 15.1908 17.6274 17.625 17.6274C20.0592 17.6274 22.0324 15.6541 22.0324 13.2199C22.0324 10.7858 20.0592 8.8125 17.625 8.8125ZM32.3125 11.75C30.6901 11.75 29.375 13.0652 29.375 14.6875C29.375 16.3099 30.6901 17.625 32.3125 17.625C33.9349 17.625 35.25 16.3099 35.25 14.6875C35.25 13.0652 33.9349 11.75 32.3125 11.75Z" fill="#17171A"/>
</svg>

export const LIN_ANALYTICS_ICON = () => <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.34226 16.2499C11.3607 16.2499 12.997 17.8862 12.997 19.9047V30.4701C12.997 32.4887 11.3607 34.1249 9.34226 34.1249C7.32379 34.1249 5.6875 32.4887 5.6875 30.4701L5.6875 19.9047C5.6875 17.8862 7.32379 16.2499 9.34226 16.2499ZM19.9048 1.625C21.9232 1.625 23.5596 3.26129 23.5596 5.27976V30.4701C23.5596 32.4887 21.9232 34.1249 19.9048 34.1249C17.8862 34.1249 16.25 32.4887 16.25 30.4701V5.27976C16.25 3.26129 17.8862 1.625 19.9048 1.625ZM30.4673 8.125C32.4857 8.125 34.1221 9.76121 34.1221 11.7798V30.4701C34.1221 32.4887 32.4857 34.1249 30.4673 34.1249C28.4487 34.1249 26.8125 32.4887 26.8125 30.4701V11.7798C26.8125 9.76121 28.4487 8.125 30.4673 8.125ZM9.34226 18.6874C8.66999 18.6874 8.125 19.2324 8.125 19.9047V30.4701C8.125 31.1424 8.66999 31.6874 9.34226 31.6874C10.0145 31.6874 10.5595 31.1424 10.5595 30.4701V19.9047C10.5595 19.2324 10.0145 18.6874 9.34226 18.6874ZM19.9048 4.0625C19.2325 4.0625 18.6875 4.60748 18.6875 5.27976V30.4701C18.6875 31.1424 19.2325 31.6874 19.9048 31.6874C20.577 31.6874 21.1221 31.1424 21.1221 30.4701V5.27976C21.1221 4.60748 20.577 4.0625 19.9048 4.0625ZM30.4673 10.5625C29.795 10.5625 29.25 11.1075 29.25 11.7798V30.4701C29.25 31.1424 29.795 31.6874 30.4673 31.6874C31.1395 31.6874 31.6846 31.1424 31.6846 30.4701V11.7798C31.6846 11.1075 31.1395 10.5625 30.4673 10.5625Z" fill="#17171A"/>
</svg>

export const LIN_DOMAIN_ICON = () => <svg style={{padding: '2px'}} width="35" height="39" viewBox="0 0 35 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.875 8.1C0.875 6.78795 1.53023 5.64262 2.48395 4.71848C3.42985 3.8019 4.72579 3.04115 6.20888 2.43329C9.17867 1.21611 13.1721 0.5 17.5 0.5C21.8279 0.5 25.8212 1.21611 28.7911 2.43329C30.2742 3.04115 31.5702 3.8019 32.5161 4.71848C33.4698 5.64262 34.125 6.78795 34.125 8.1V30.9C34.125 32.2121 33.4698 33.3575 32.5161 34.2816C31.5702 35.1982 30.2742 35.9589 28.7911 36.5667C25.8212 37.7839 21.8279 38.5 17.5 38.5C13.1721 38.5 9.17867 37.7839 6.20888 36.5667C4.72579 35.9589 3.42985 35.1982 2.48395 34.2816C1.53023 33.3575 0.875 32.2121 0.875 30.9V8.1ZM3.99219 8.1C3.99219 8.49313 4.18013 8.97227 4.75122 9.52565C5.33013 10.0866 6.24062 10.6564 7.484 11.1661C9.96719 12.1838 13.5071 12.85 17.5 12.85C21.4929 12.85 25.0328 12.1838 27.5159 11.1661C28.7593 10.6564 29.6699 10.0866 30.2489 9.52565C30.82 8.97227 31.0078 8.49313 31.0078 8.1C31.0078 7.70687 30.82 7.22773 30.2489 6.67435C29.6699 6.11338 28.7593 5.54353 27.5159 5.03393C25.0328 4.0162 21.4929 3.35 17.5 3.35C13.5071 3.35 9.96719 4.0162 7.484 5.03393C6.24062 5.54353 5.33013 6.11338 4.75122 6.67435C4.18013 7.22773 3.99219 7.70687 3.99219 8.1ZM31.0078 12.6456C30.3409 13.0644 29.5934 13.4379 28.7911 13.7667C25.8212 14.9839 21.8279 15.7 17.5 15.7C13.1721 15.7 9.17867 14.9839 6.20888 13.7667C5.40662 13.4379 4.65914 13.0644 3.99219 12.6456V30.9C3.99219 31.2931 4.18013 31.7723 4.75122 32.3256C5.33013 32.8866 6.24062 33.4565 7.484 33.966C9.96719 34.9839 13.5071 35.65 17.5 35.65C21.4929 35.65 25.0328 34.9839 27.5159 33.966C28.7593 33.4565 29.6699 32.8866 30.2489 32.3256C30.82 31.7723 31.0078 31.2931 31.0078 30.9V12.6456Z" fill="#17171A"/>
</svg>

export const LIN_DASHBOARD_ICON = () => <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_4832_1410" fill="white">
    <rect x="5.875" y="6.00781" width="15.6667" height="19.5833" rx="2"/>
    </mask>
    <rect x="5.875" y="6.00781" width="15.6667" height="19.5833" rx="2" stroke="#17171A" strokeWidth="5" mask="url(#path-1-inside-1_4832_1410)"/>
    <mask id="path-2-inside-2_4832_1410" fill="white">
    <rect x="5.875" y="29.5078" width="15.6667" height="11.75" rx="2"/>
    </mask>
    <rect x="5.875" y="29.5078" width="15.6667" height="11.75" rx="2" stroke="#17171A" strokeWidth="5" mask="url(#path-2-inside-2_4832_1410)"/>
    <mask id="path-3-inside-3_4832_1410" fill="white">
    <rect x="25.4583" y="6.00781" width="15.6667" height="11.75" rx="2"/>
    </mask>
    <rect x="25.4583" y="6.00781" width="15.6667" height="11.75" rx="2" stroke="#17171A" strokeWidth="5" mask="url(#path-3-inside-3_4832_1410)"/>
    <mask id="path-4-inside-4_4832_1410" fill="white">
    <rect x="25.4583" y="21.6746" width="15.6667" height="19.5833" rx="2"/>
    </mask>
    <rect x="25.4583" y="21.6746" width="15.6667" height="19.5833" rx="2" stroke="#17171A" strokeWidth="5" mask="url(#path-4-inside-4_4832_1410)"/>
</svg>

export const LIN_USER_ICON = () => <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.5001 4.64995C18.281 4.64995 14.0501 8.88086 14.0501 14.1C14.0501 19.319 18.281 23.5499 23.5001 23.5499C28.7191 23.5499 32.9501 19.319 32.9501 14.1C32.9501 8.88086 28.7191 4.64995 23.5001 4.64995ZM16.5001 14.1C16.5001 10.234 19.6341 7.09995 23.5001 7.09995C27.3662 7.09995 30.5001 10.234 30.5001 14.1C30.5001 17.9659 27.3662 21.1 23.5001 21.1C19.6341 21.1 16.5001 17.9659 16.5001 14.1Z" fill="#17171A" stroke="#17171A" strokeWidth="0.1"/>
    <path d="M11.7703 25.8001C9.145 25.8001 6.9998 27.9063 6.9998 30.5501C6.9998 34.5436 8.96746 37.555 12.0402 39.5143C15.0634 41.4419 19.1352 42.3501 23.4998 42.3501C27.8643 42.3501 31.9362 41.4419 34.9594 39.5143C38.0322 37.555 39.9998 34.5436 39.9998 30.5501C39.9998 27.9271 37.873 25.8001 35.2498 25.8001H11.7703ZM9.4498 30.5501C9.4498 29.2778 10.4796 28.2501 11.7703 28.2501H35.2498C36.5199 28.2501 37.5498 29.28 37.5498 30.5501C37.5498 33.6067 36.0979 35.8827 33.6423 37.4484C31.137 39.0458 27.5713 39.9001 23.4998 39.9001C19.4283 39.9001 15.8626 39.0458 13.3573 37.4484C10.9016 35.8827 9.4498 33.6067 9.4498 30.5501Z" fill="#212121" stroke="#17171A" strokeWidth="0.1"/>
</svg>

export const LIN_SCHEDULER_ICON = () => <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.7871 6.25C21.7871 4.45507 20.332 3 18.5371 3H7.03711C5.24218 3 3.78711 4.45507 3.78711 6.25V17.75C3.78711 19.5449 5.24218 21 7.03711 21H12.8089C12.5124 20.5368 12.2729 20.0335 12.1006 19.5H7.03711C6.07061 19.5 5.28711 18.7165 5.28711 17.75V8.5H20.2871V11.3135C20.8206 11.4858 21.3239 11.7253 21.7871 12.0218V6.25ZM7.03711 4.5H18.5371C19.5036 4.5 20.2871 5.2835 20.2871 6.25V7H5.28711V6.25C5.28711 5.2835 6.07061 4.5 7.03711 4.5ZM23.7871 17.5C23.7871 14.4624 21.3247 12 18.2871 12C15.2495 12 12.7871 14.4624 12.7871 17.5C12.7871 20.5376 15.2495 23 18.2871 23C21.3247 23 23.7871 20.5376 23.7871 17.5ZM18.2871 17.5H20.2871C20.5632 17.5 20.7871 17.7239 20.7871 18C20.7871 18.2762 20.5632 18.5 20.2871 18.5H17.7871C17.5139 18.5 17.2919 18.2809 17.2872 18.0089L17.2871 17.9999V14.9999C17.2871 14.7238 17.511 14.4999 17.7871 14.4999C18.0632 14.4999 18.2871 14.7238 18.2871 14.9999V17.5Z" fill="#17171A"/>
</svg>
