import React, { useEffect } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Buttons from "../../../Common/button/Buttons";
import { CHECK, CLOSE, PLUS } from "../../../Common/iconSource";
import { Literals } from "../../common/literals";
import { SET_FLOWS_ALERT_TRANSFORMER_LIST } from "../../../../store/modules/alert/createAlert/createAlertActiontype";

import { Scheduler } from "../index";
import { setSelectedScheduler } from "../../../../store/modules/scheduler/action";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { ClassificationTable } from "./SettingsClassifications";
import SettingsClassificationModal from "./SettingsClassificationModal";
import { NonPII, getObjectLength, getRelativeTime, getReloadErrorTemplate, pxToRem, remToPx } from "../../common/helperFunctions";
import { LandingSimpleTable } from "../../../Common/landingSimpleTable/LandingSimpleTable";
import _ from "lodash";
import ClaristaLoader from "../../../Common/claristaLoader/ClaristaLoader";
import { getClassificationData } from "../../../../store/modules/dataDictionary/action";
import { Tooltip } from "antd";
import ContainsPII from "./ContainsPII";

const ClassificationModal = ({
  isModalOpen,
  onClose,
  save,
  reset,
  present,
  selectedSingleClassifier = null,

  activeClassifier
}) => {
  const [selected, setSelected] = React.useState([]);
  const [selectedSingle, setSelectedSingle] = React.useState(selectedSingleClassifier ?? null);
  const [openModal, setOpenModal] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(undefined);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [data, setData] = React.useState([])

  const rawData = React.useRef([])

  const dispatch = useDispatch()

  const onSaveHandler = () => {
    save(selectedSingle);
    // save(selected);
  };

  useEffect(() => {
    if(isModalOpen) {

      setSelectedIndex(activeClassifier?.id ?? null)
      getList()
    }
  }, [isModalOpen])

  useEffect(() => {
    if (reset) {
      setSelected([]);
      setSelectedSingle(null);
    }
  }, [reset]);

  useEffect(() => {
    if (selectedSingleClassifier) {
      setSelectedSingle(selectedSingleClassifier);
    }
  }, [selectedSingleClassifier]);

  useEffect(() => {
    if (present) setSelected([...present]);
  }, [present]);

  const generateRow = (data) => {
    let temp = [...data];
   temp=temp.map((item, index) => {
        return {
            id:item.id,
            key:item.id,
            data: item,
            active: selectedSingle?.id === selectedIndex,
            classification_name: <>
                <div class="text-uppercase dict-color-dark text-with-ellipsis fontSizeLabel">{item.name}</div>
            </>,
            description: item?.description === ''
                  ?   <div className='text-muted'>No Description</div>
                  :
                  <Tooltip placement='topLeft' title={item?.description}>
                      <div className='text-with-ellipsis'>
                          {item?.description}
                      </div>
                  </Tooltip>,
            created_on: <Tooltip placement='topLeft' title={getRelativeTime(item?.updated_on)}>
                          <div className='text-with-ellipsis'>
                              {getRelativeTime(item?.updated_on)}
                          </div>
                      </Tooltip>,
            pii:  <div className="d-flex h-100 align-items-center">
                    {item?.pii ? <ContainsPII/> : <NonPII/>}
            </div>,
            // pattern: item?.pattern === ''
            //         ?   <div className='text-black-50'>N/A</div>
            //         :
            //         <Tooltip placement='topLeft' title={item?.pattern}>
            //             <div className='text-with-ellipsis'>
            //                 {item?.pattern}
            //             </div>
            //         </Tooltip>,
            index
        }
    })

    return temp
  }

  const getList = () => {
    setLoading(true)
    dispatch(getClassificationData(false, false))
    .then(res=>{
        let temp = generateRow(res?.data)
        setData([...temp])
        rawData.current=[...res?.data];
        setLoading(false)
    })
    .catch(err=>{
        setErrMsg(err?.message)
        setData([])
        setLoading(false)
    })
  }

  const onRowClick = (record) => {
      setSelectedSingle(record?.data)
      setSelected([record?.data]);
      setSelectedIndex(record?.id);
      activeClassifier = record?.id
  }

  
  return (
    <Modal
      size="lg"
      isOpen={isModalOpen}
      className="custom-modal classification-select-modal"
      centered={true}
    >
      <ModalHeader>
      <div className="d-flex align-items-center justify-content-between w-100">
          <div className="modal-heading-logs">Select Classification</div>

       {/* {hideAddButton?"":   <Buttons
            props={{
              tooltip: "",
              buttonText: "Add Classification",
              buttonClassName:
                "custom-btn-outline custom-btn btn-with-text border mr-2",
              buttonEvent: () => { setOpenModal(true) },
              ImgSrc: () => <PLUS />,
              isDisable: false,
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
            }}
          />} */}
        </div>
        <Buttons
          props={{
            tooltip: "Close",
            buttonText: "",
            buttonClassName:
              "custom-btn-outline custom-btn btn-with-icon float-right",
            buttonEvent: () => {
              onClose();
            },
            ImgSrc: () => <CLOSE />,
            isDisable: false,
            buttonType: Literals.BTN_TERTIARY,
          }}
        />
      </ModalHeader>
      <ModalBody className="overflow-hidden">
        <div className="classification-modal classifier-sel-mod" style={{ height: '413px' }}>
        <>
            {
                errMsg ? getReloadErrorTemplate({errorMessage: errMsg, onReload: getList})
                :
                loading ? <ClaristaLoader/>
                :
                <LandingSimpleTable
                    rowsData={
                      data
                        // (() => {
                        //     let dat = [...data]
                        //     dat = _.sortBy(dat, [o => o?.id !== selectedIndex])
                        //     return dat ?? []
                        // })()
                    }
                    cols={modalColumns}
                    tableHeight={'100%'}
                    onRowClick={onRowClick}
                    selectedRowIndex={selectedIndex}
                    rowHeight={remToPx(pxToRem(41))}
                    headerRowHeight={remToPx(2)}
                />
            }
        </>
        </div>
      </ModalBody>
      <ModalFooter>
        <Buttons
          props={{
            tooltip: "Clear",
            buttonText: "Clear",
            buttonClassName:
              " custom-btn-outline custom-btn btn-with-text mr-2",
            buttonEvent: () => {
              activeClassifier = null
              save(null)
              onClose();
            },
            ImgSrc: () => <CLOSE />,
            isDisable: false,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
        <Buttons
          props={{
            tooltip: "Done",
            buttonText: "Done",
            buttonClassName:
              " custom-btn-primary custom-btn btn-with-text float-right",
            buttonEvent: () => {
              onSaveHandler();
              onClose();
            },
            ImgSrc: () => <CHECK />,
            isDisable: !selectedIndex,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
      </ModalFooter>
      <SettingsClassificationModal
            openModal={openModal}
            setOpenModal={setOpenModal}
        />
    </Modal>
  );
};

export default ClassificationModal;

const modalColumns=[
  {
      name: 'Classifiers',
      key: "classification_name",
      sortable: false,
      resizable: false,
      width:"33%",
  },
  {
      name: 'Description',
      key: "description",
      sortable: false,
      resizable: false,
      width: "33%"
  },
  {
      name: 'Pii',
      key: "pii",
      sortable: false,
      resizable: false,
      width: 120,
  },
  {
      name: 'Created On',
      key: "created_on",
      sortable: false,
      resizable: false,
      // width:"33%",
  }
]
