import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { CLOSE, MERGE, OUTBOUND, SELECT, } from '../../../../Common/iconSource'
import Buttons from '../../../../Common/button/Buttons'
import { Literals } from '../../../common/literals'
import { LandingSimpleTable } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { cloneDeep } from 'lodash';
import notebookstep1 from '../../../../../assets/images/Guide_Images/step-1.png'
import notebookstep2 from '../../../../../assets/images/Guide_Images/step-2.png'
import tableauNewSource from '../../../../../assets/images/Guide_Images/tbapop.png'
import newDatasourceWindow from '../../../../../assets/images/Guide_Images/newDatasourceWindow.png'
import otherDatabase from '../../../../../assets/images/Guide_Images/otherDatabase.png'
import tableview from '../../../../../assets/images/Guide_Images/tableview.png'
import powerbi_step1 from '../../../../../assets/images/Guide_Images/powerbi-step1.png'
import powerbi_step2 from '../../../../../assets/images/Guide_Images/powerbi-step2.png'
import powerbi_step6 from '../../../../../assets/images/Guide_Images/powerbi-step6.png'
import powerbi_step7 from '../../../../../assets/images/Guide_Images/powerbi-step7.png'
import toolbarribbon from '../../../../../assets/images/Guide_Images/toolbarribbon.jpg'
import getdata from '../../../../../assets/images/Guide_Images/getdata.png'
import userpass from '../../../../../assets/images/Guide_Images/userpass.png'
import navigator from '../../../../../assets/images/Guide_Images/navigator.png'
import encodeb64 from '../../../../../assets/images/Guide_Images/encodeb64.png'
import postmanheader from '../../../../../assets/images/Guide_Images/postmanheader.png'
import querypostman from '../../../../../assets/images/Guide_Images/querypostman.png'
import postmanresponse from '../../../../../assets/images/Guide_Images/postmanresponse.png'
import cmd1 from '../../../../../assets/images/Guide_Images/cmd1.png'
import cmd2 from '../../../../../assets/images/Guide_Images/cmd2.png'
import cmd3 from '../../../../../assets/images/Guide_Images/cmd3.png'
import cmd4 from '../../../../../assets/images/Guide_Images/cmd4.png'
import postman1 from '../../../../../assets/images/Guide_Images/postman1.png'
import postman2 from '../../../../../assets/images/Guide_Images/postman2.png'

import apioutput9 from '../../../../../assets/images/Guide_Images/apioutput9.png'
import code1 from '../../../../../assets/images/Guide_Images/code-1.png'
import code2 from '../../../../../assets/images/Guide_Images/code-2.png'
import SqlEditor from '../../../dataCatalogue/preview/components/SqlEditor';
import { useStore } from 'react-redux'
import { Divider, Typography } from 'antd';
import { DownloadGuideFileAPI, DownloadWHLFileAPI } from '../../../../../store/modules/UserManagement/connection/connectionActions';
const { Title, Paragraph, Text, Link } = Typography;

const ConnectionGuideModal = ({ openModal, onClose, handleSelect, currentSelected }) => {
    const store = useStore()
    let userDetails = store.getState().UserReducer?.user?.userDetails

    let REACTAPP_TRINO_HOST_URL = window._env_.REACT_APP_TRINO_HOST_URL

    const code1Content = `from clarista import Clarista
kwargs = {
    "catalog": "hive",
    "host": "${REACTAPP_TRINO_HOST_URL}",
    "access_key": "**************",
    "port": 443,
    "impersonation_user": "${userDetails.email ?? 'test@email.com'}",
    "secret_key": "****************************",
}
clarista = Clarista(**kwargs)`

    const apicode1 = `import requests
import base64

import warnings
warnings.filterwarnings("ignore")`

    const apicode2 = `USER_NAME = "${userDetails.email ?? 'test@email.com'}"
ACCESS_KEY = "*********************************"
SECRET_KEY = "****************************************************************"
TRINO_HOST = 'https://${REACTAPP_TRINO_HOST_URL}/v1/statement/'

Authorization = "Basic "+ base64.b64encode((ACCESS_KEY+':'+SECRET_KEY).encode('utf-8')).decode('utf-8')`

    const apicode3 = `headers = {
    "X-Trino-User": USER_NAME,
    "Authorization": Authorization,
    "X-Trino-Time-Zone": "UTC",
    "X-Trino-Catalog": "hive",
    "User-Agent": "trino-cli"
}

data_domain="esg"
pod="carbon_original"

query = f"select * from {data_domain}.{pod} limit 10"`

    const apicode4 = `res = requests.post(
    TRINO_HOST,
    headers=headers,
    data = query,
    verify=False
).json()
next_uri = res['nextUri']
status = res['stats']['state']`

    const apicode5 = `res`
    const output5 = `{'id': '20221226_092455_00331_4dinm',
 'infoUri': 'https://${REACTAPP_TRINO_HOST_URL}/ui/query.html?20221226_092455_00331_4dinm',
 'nextUri': 'https://${REACTAPP_TRINO_HOST_URL}/v1/statement/queued/20221226_092455_00331_4dinm/y2443c2c978f08dae0eed9bf3ad6133ea2cc50f87/1',
 'stats': {'state': 'QUEUED',
  'queued': True,
  'scheduled': False,
  'nodes': 0,
  'totalSplits': 0,
  'queuedSplits': 0,
  'runningSplits': 0,
  'completedSplits': 0,
  'cpuTimeMillis': 0,
  'wallTimeMillis': 0,
  'queuedTimeMillis': 0,
  'elapsedTimeMillis': 0,
  'processedRows': 0,
  'processedBytes': 0,
  'physicalInputBytes': 0,
  'peakMemoryBytes': 0,
  'spilledBytes': 0},
 'warnings': []
}`
    const apicode6 = `data = []
while status in ["QUEUED", "RUNNING"]:
    res = requests.get(
        next_uri,
        headers=headers,
        verify=False
    ).json()
    if 'data' in res:
        data.append(res['data'])
    status = res['stats']['state']
    if status != 'FINISHED':
        next_uri = res['nextUri']`

    const apicode7 = `columns = res['columns']
data = res['data']`
    const apicode8 = `import pandas as pd
df = pd.DataFrame(data, columns=[col['name'] for col in columns])`
    const apicode9 = `df.head()`


    const showDomainsContent = {
        height: '40px',
        content: `# ListingDomain
domain = clarista.list_domain()`}

    const creatingColContent = {
        height: '40px',
        content: `# Creating Column Domain in Schema name table
schema_name = domain['domain'][0]`
    }

    const listingTableContent = {
        height: '40px',
        content: `#Listing Table storing schema names in domain variable
pod = clarista.list_pod(domain= schema_name)`
    }

    const extractingPodsContent = {
        height: '40px',
        content: `#Extracting pods from table pod
pod_name = pod['table_name'][0]`}

    const describingTableContent = {
        height: '40px',
        content: `#Describing table
pod_desc = clarista.describe_pod(pod = pod_name,domain = schema_name)`}

    const readingTableContent = {
        height: '120px',
        content: `#Reading table
pod_df = clarista.read_pod(pod = pod_name,domain = schema_name)
pod_df = clarista.read_pod(
    query = f'SELECT * FROM {domain_name}.{pod_name} limit 10'
    )`}

    const readingTableFromQuery = {
        height: '80px',
        content: `#Reading table from query
pod_df = clarista.read_sql(
    query = f'SELECT * FROM {domain_name}.{pod_name} limit 10'
    )`}

    const codeArr = [showDomainsContent, creatingColContent, listingTableContent, extractingPodsContent, describingTableContent, readingTableContent, readingTableFromQuery]

    const apiCurlCMD = `curl 7.82.0 (x86_64-pc-win32) libcurl/7.82.0 Schannel zlib/1.2.12 libssh2/1.10.0
Release-Date: 2022-03-05
Protocols: dict file ftp ftps gopher gophers http https imap imaps ldap ldaps mqtt pop3 pop3s rtsp scp sftp smb smbs smtp smtps telnet tftp 
Features: AsynchDNS HSTS IPv6 Kerberos Largefile NTLM SPNEGO SSL SSPI Unicode UnixSockets alt-svc libz`

    const apiConcateAccessKeyCode = `67a5a628be79ddfd03d6879a82a5a827:405c6bd03f6cb10ade526a6cfe5eeaeb57e6780fbab45582c87c001ef49c0702`
    const apiEncodingCode = `echo -n '67a5a628be79ddfd03d6879a82a5a827:405c6bd03f6cb10ade526a6cfe5eeaeb57e6780fbab45582c87c001ef49c0702' | base64`
    const apiPythonReqCode = `curl -k https://${REACTAPP_TRINO_HOST_URL}/v1/statement/ \ 
> --header "Authorization: Basic NjdhNWE2MjhiZTc5ZGRmZDAzZDY4NzlhODJhNWE4Mjc6NDA1YzZiZDAzZjZjYjEwYWRlNTI2YTZjZmU1ZWVhZWI1N2U2NzgwZmJhYjQ1NTgyYzg3YzAwMWVmNDljMDcwMg==" \ 
> --header "X-Trino-User: ${userDetails.email ?? 'test@email.com'}" \ 
> --header "X-Trino-Time-Zone: UTC" \ 
> --header "X-Trino-Catalog: hive" \ 
> --header "User-Agent: trino-cli" \ 
> --data "select * from esg.carbon_original limit 10" 
    
{"id":"20221226_085143_00297_4dinm","infoUri":"https://${REACTAPP_TRINO_HOST_URL}/ui/query.html?20221226_085143_00297_4dinm","nextUri":"https://${REACTAPP_TRINO_HOST_URL}/v1/statement/queued/20221226_085143_00297_4     
dinm/y0c1c17339fdd6ebe45891c8d1246783a98756cd8/1","stats":{"state":"QUEUED","queued":true,"scheduled":false,"nodes":0,"totalSplits":0,"queuedSplits":0,"runningSplits":0,"completedSplits":0,"cpuTimeMillis":0,"wall 
TimeMillis":0,"queuedTimeMillis":0,"elapsedTimeMillis":0,"processedRows":0,"processedBytes":0,"physicalInputBytes":0,"peakMemoryBytes":0,"spilledBytes":0},"warnings":[]}`

    const downloadWHLFile = () => {
        store.dispatch(DownloadGuideFileAPI())
     
    }
 
    const getInfographic = (type) => {
        switch (type) {
            case 'python':
                return <div>
                    <Typography>
                        <Title level={2}>Python</Title>
                        <Title level={4}>Steps:</Title>
                        <ul>
                            <li>
                                <Paragraph>
                                    Go to Connections and select Outbound Connections.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on Add Button and give the credential name and description. Click on Create button.
                                </Paragraph>
                                <div className='d-block w-100 mt-2'>
                                    <img className='border mb-2' height={200} width={300} src={notebookstep1}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    After that, you will see Access Key and Secret Key. You are requested to download keys which will be used in making the external connection
                                </Paragraph>
                                <div className='d-block w-100 mt-2'>
                                    <img className='border mb-2' height={200} width={300} src={notebookstep2}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on Done button to save the connection.
                                </Paragraph>
                            </li>
                        </ul>
                        <Divider />
                        <Title level={4}>
                            External Connectivity Steps:
                        </Title>
                        <Paragraph>To make the connection from Jupyter Notebook we need the following requirements-</Paragraph>
                        <ul>
                            <li>
                                <Paragraph>
                                    Make sure you have the Python installed in your system and ensure you can run the pip from the command line. Check PYPI documentation for any further help.<a href=" https://packaging.python.org/en/latest/tutorials/installing-packages/" target="_blank"> https://packaging.python.org/en/latest/tutorials/installing-packages/</a>
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    Now download the Clarista .whl file from this <Link href='#' onClick={() => store.dispatch(DownloadGuideFileAPI('clarista-1.0.0-py3-none-any', 'outbound_connection/download_whl/'))} strong>link</Link>. Save this file anywhere on your local system.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    Open Command Prompt, Anaconda prompt or any other Python Terminal and change directory to the .whl file you just downloaded and install Clarista python library using below command. All the dependencies will also be downloaded.
                                    <br />
                                    <Text code>pip install clarista-1.0.0-py3-none-any.whl</Text>
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    After the installation, you can import the Clarista python package for connecting to data sources in Clarista.io.
                                </Paragraph>
                            </li>
                        </ul>
                        <Divider />
                        <Title level={4}>Usage:</Title>
                        <ul>
                            <li>
                                <Paragraph>Open the Jupyter notebook or any other python IDE from where you would like to connect the Clarista with.</Paragraph>
                            </li>
                            <li>
                                <Paragraph>Import Clarista library and pass the parameters under **kwargs.</Paragraph>
                            </li>
                        </ul>
                        <Paragraph className='pl-4 w-75'>
                            <blockquote>
                                <Text strong>Catalog:</Text> hive
                            </blockquote>
                            <blockquote>
                                <Text strong>Host:</Text> {`${REACTAPP_TRINO_HOST_URL}`}
                            </blockquote>
                            <blockquote>
                                <Text strong>Access Key:</Text> Access key you have downloaded while making outbound connection
                            </blockquote>
                            <blockquote>
                                <Text strong>Port:</Text> 443
                            </blockquote>
                            <blockquote>
                                <Text strong>Impersonation User:</Text> The users email you want to impersonate or from where you have made the outbound connection and downloaded key
                            </blockquote>
                            <blockquote>
                                <Text strong>Secret Key:</Text> Secret key you have downloaded while making outbound connection.
                            </blockquote>
                        </Paragraph>
                        <Title level={4}>
                            Run the following command.
                        </Title>
                        <div className='d-block text-center w-100 mt-2'>
                            <div className='row m-0'>
                                <div className='col-1 px-0 text-left'>
                                    <code className='color-primary m-0'>{`In [ ]:`}</code>
                                </div>
                                <div className='col-10 pl-0'>
                                    <SqlEditor
                                        QueryValue={code1Content}
                                        fromRoute="usage"
                                        width={"100%"}
                                        readOnly={true}
                                        showGutter={false}
                                        wrapEnabled={true}
                                        className='guide-editor'
                                        mode='python'
                                        height={'180px'}
                                    />
                                </div>
                            </div>
                        </div>
                        <Title level={4}>
                            Here you go:
                        </Title>
                        <Paragraph>
                            <Text strong>You have successfully made the external connection</Text> with Jupyter Notebook, and you can access the Clarista through your codes.
                        </Paragraph>
                        <Paragraph>
                            Here are few examples-
                        </Paragraph>
                        <div className='d-block text-center w-100 mt-2'>
                            {
                                codeArr.map((v, i) =>
                                    <React.Fragment>
                                        <div id={`cout-code-${i}`} className='row mb-3 mx-0'>
                                            <div className='col-1 text-left px-0'>
                                                <code className='color-primary'>{`In [ ]:`}</code>
                                            </div>
                                            <div className='col-10 pl-0'>
                                                <SqlEditor
                                                    QueryValue={v.content}
                                                    fromRoute="usage"
                                                    width={"100%"}
                                                    height={v.height}
                                                    readOnly={true}
                                                    showGutter={false}
                                                    wrapEnabled={true}
                                                    className='guide-editor'
                                                    mode='python'
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </Typography>
                </div>
            case 'tableau':
                return <div>
                    <Typography>
                        <Title level={2}>Tableau</Title>
                        <Paragraph>
                            Tableau is a visual analytics platform transforming the way we use data to solve problems- empowering people and organizations to make the most of their data.
                        </Paragraph>
                        <Title level={4}>Steps:</Title>
                        <ul>
                            <li>
                                <Paragraph>
                                    Go to Connections and select Outbound Connections.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on Add Button and give the credential name and description. Click on Create button.
                                </Paragraph>
                                <div className='d-block w-100 mt-2'>
                                    <img className='border mb-2' height={200} width={300} src={notebookstep1}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    After that, you will see Access Key and Secret Key. You are requested to download keys which will be used in making the external connection
                                </Paragraph>
                                <div className='d-block w-100 mt-2'>
                                    <img className='border mb-2' height={200} width={300} src={notebookstep2}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on Done button to save the connection.
                                </Paragraph>
                            </li>
                        </ul>
                        <Title level={4}>External Connectivity Steps:</Title>
                        <Paragraph>To make the connection from Tableau we need the following requirements-</Paragraph>
                        <Paragraph strong>Requirements-</Paragraph>
                        <ul>
                            <li>
                                <Paragraph>You will require the JDBC driver to connect to Clarista from Tableau. Download the Clarista JDBC driver from this <Link href='#' onClick={() => store.dispatch(DownloadGuideFileAPI('clarista-1.0.0-jdbc', 'outbound_connection/download_jdbc/'))} strong>link</Link>.</Paragraph>
                            </li>
                            <li>
                                <Paragraph>Once downloaded, you must add the JAR file to a directory where your tableau is located.
                                    <br />
                                    <code className='mt-2 color-primary border px-1 rounded'>
                                        Path: C:\Program Files\Tableau\Drivers\
                                    </code>
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>Now, you need to download the Properties file from this <Link href='#' onClick={() => store.dispatch(DownloadGuideFileAPI('clarista_properties', 'outbound_connection/download_properties/'))} strong>link</Link>. The file is needed to provide the information of impersonate user who wants to make the external connection with Tableau

                                </Paragraph>
                            </li>
                        </ul>
                        <Divider />

                        <Title level={4}>
                            Properties File Contains--
                        </Title>
                        <Paragraph className='pl-4 w-75'>
                            <blockquote>
                                <Text strong>Source=</Text> tableau
                            </blockquote>
                            <blockquote>
                                <Text strong>SSL=</Text> true
                            </blockquote>
                            <blockquote>
                                <Text strong>SSLVerification=</Text> NONE
                            </blockquote>
                            <blockquote>
                                <Text strong>sessionUser=</Text> {`${userDetails.email ?? 'test@email.com'}`}
                            </blockquote>

                        </Paragraph>
                        <Divider />
                        <Paragraph>Save this file as Properties type or with “. properties” extension.
                            Note- Session user is the user email you want to impersonate or from where you have made the outbound connection and downloaded access key and secret key.</Paragraph>
                        <Title level={4}>
                            Steps from Tableau-
                        </Title>
                        <ul>
                            <li>
                                <Paragraph>
                                    Open the Tableau Desktop from where you would like to connect the Clarista with.
                                </Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' style={{ height: '200px' }} src={tableauNewSource}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on Data and select New Data Source.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    Now Select Other Databases (JDBC) option to connect to a server.
                                </Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' height={500} src={newDatasourceWindow}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    The dialog box will open, fill the details as following
                                </Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' width={300} src={otherDatabase}></img>
                                </div>
                            </li>
                            <li>
                                <blockquote>
                                    <Text strong>URL:</Text> jdbc:trino://{`${REACTAPP_TRINO_HOST_URL}`}:443/hive
                                </blockquote>
                                <blockquote>
                                    <Text strong>Dialect:</Text> PostgresSQL
                                </blockquote>
                                <blockquote>
                                    <Text strong>Username:</Text> Access key you have downloaded while making outbound connection.
                                </blockquote>
                                <blockquote>
                                    <Text strong>Secret Key:</Text> Secret key you have downloaded while making outbound connection.
                                </blockquote>
                                <blockquote>
                                    <Text strong>Properties File:</Text> Upload the properties file which you have created above.
                                </blockquote>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on <strong>Sign In</strong>. Your Tableau connection with Clarista will be made.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    <strong>Here you go</strong>. Choose the Database, Schema and Table you want to work with.
                                </Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' width={300} src={tableview}></img>
                                </div>
                            </li>
                        </ul>
                    </Typography>
                </div>

            case 'power_bi':


                return <div>
                    <Typography>
                        <Title level={2}>Power BI</Title>
                        <Paragraph>Power BI is a unified, scalable platform for self-service and enterprise business intelligence (BI).
                            You can connect to Power BI and visualize any data, and seamlessly infuse the visuals into the apps you use every day.
                            This method uses Microsoft Power BI Custom Connector for importing the data from Clarista into Power BI to interactively transform, visualize and analyse the same.</Paragraph>
                        <Title level={4}>Steps:</Title>
                        <ul>
                            <li>
                                <Paragraph>
                                    Go to Connections and select Outbound Connections.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on Add Button and give the credential name and description. Click on Create button.
                                </Paragraph>
                                <div className='d-block w-100 mt-2'>
                                    <img className='border mb-2' height={200} width={300} src={notebookstep1}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    After that, you will see Access Key and Secret Key. You are requested to download keys which will be used in making the external connection
                                </Paragraph>
                                <div className='d-block w-100 mt-2'>
                                    <img className='border mb-2' height={200} width={300} src={notebookstep2}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on Done button to save the connection.
                                </Paragraph>
                            </li>
                        </ul>
                        <Divider />
                        <Title level={4}>
                            External Connectivity Steps:
                        </Title>
                        <Paragraph>To make the connection from Power BI we need the following requirements-</Paragraph>
                        <Paragraph strong>Requirements-</Paragraph>
                        <ul>
                            <li>
                                <Paragraph>Download the connector .mez file for Clarista Power BI connector from this <Link href='#' onClick={() => store.dispatch(DownloadGuideFileAPI('clarista-1.0.0-powerbi_connector', 'outbound_connection/download_powerbi_connector/'))} strong>link</Link> and put it in your local <strong>[Documents]\Power BI Desktop\Custom Connectors</strong> folder. If the folder doesn't exist, create it.</Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' height={500} src={powerbi_step1}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>To adjust the data extension security settings, in Power BI Desktop, <strong>{'select File > Options and settings > Options > Security'}</strong>.</Paragraph>

                            </li>
                            <li>
                                <Paragraph>Under <strong>Data Extensions</strong> , select <strong> (Not Recommended) Allow any extension to load without validation or warning.</strong></Paragraph>
                            </li>
                            <li>
                                <Paragraph>Select <strong> OK</strong>, and then restart Power BI Desktop.</Paragraph>

                            </li>
                            <li>
                                <Paragraph>After restarting Go to <strong>Get data</strong> option in Toolbar Ribbon and select <strong>More...</strong></Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' height={500} src={toolbarribbon}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>After this search for <strong>Clarista</strong> in data sources, you should be able to see the connector listed in your <strong>Get Data</strong> window as shown below:</Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' style={{height:'200px'}} src={getdata}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>Populate the required and optional Parameters provided below to communicate with Clarista environment and hit <strong>OK</strong>.</Paragraph>
                                <Paragraph className='pl-4 w-75'>
                                    <blockquote>
                                        <Text strong>Host:</Text> {`${REACTAPP_TRINO_HOST_URL}`}
                                    </blockquote>
                                    <blockquote>
                                        <Text strong>Port:</Text> 443
                                    </blockquote>
                                    <blockquote>
                                        <Text strong>Catalog:</Text> hive
                                    </blockquote>
                                    <blockquote>
                                        <Text strong>User:</Text> {`${userDetails.email}`}
                                    </blockquote>
                                </Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' height={500} src={powerbi_step6}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>Now you will see a screen asking for username and password. Fill in your <strong>access key</strong> as User Name and <strong>secret key</strong> as Password.</Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' height={300} src={userpass}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>After logging in you should be able to see the data domains assigned to you under <strong>hive</strong> catalog.</Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' height={500} src={navigator}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>Choose the desired objects you want to import and click Load if you want to import data directly. Clicking Transform Data allows you to transform data before importing it into the analytical storage engine.</Paragraph>
                                <div className='text-left'>
                                    <img className='border img-fluid mb-2' height={500} src={powerbi_step7}></img>
                                </div>
                            </li>
                        </ul>

                    </Typography>
                </div>

            case 'api':


                return <div>
                    <Typography>
                        <Title level={2}>REST API</Title>

                        <Title level={4}>Steps:</Title>
                        <ul>
                            <li>
                                <Paragraph>
                                    Go to Connections and select Outbound Connections.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on Add Button and give the credential name and description. Click on Create button.
                                </Paragraph>
                                <div className='d-block w-100 mt-2'>
                                    <img className='border mb-2' height={200} width={300} src={notebookstep1}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    After that, you will see Access Key and Secret Key. You are requested to download keys which will be used in making the external connection
                                </Paragraph>
                                <div className='d-block w-100 mt-2'>
                                    <img className='border mb-2' height={200} width={300} src={notebookstep2}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    Click on Done button to save the connection.
                                </Paragraph>
                            </li>
                        </ul>
                        <Divider />
                        <Title level={4}>
                            Connecting using requests in Python:
                        </Title>
                        <Paragraph>This approach uses python request library for REST API calls in python. Below is an example how you can execute queries using this method.</Paragraph>

                        <ul>
                            <li>
                                <Paragraph>Import required libraries:</Paragraph>
                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`In [1]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={apicode1}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='python'
                                                height={'120px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Paragraph>Use your username, access key and secret key generated above in below code:</Paragraph>
                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`In [2]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={apicode2}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='python'
                                                height={'140px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Paragraph>Set headers for REST API calls as below:</Paragraph>
                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`In [3]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={apicode3}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='python'
                                                height={'220px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Paragraph>Calling REST API:</Paragraph>
                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`In [4]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={apicode4}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='python'
                                                height={'160px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>

                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`In [5]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={apicode5}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='python'
                                                height={'20px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>

                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`Out [5]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={output5}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='JSON'
                                                height={'400px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <Paragraph>no puts the above query in “QUEUED” state. To start execution and get data from REST API you need to keep calling “nextUri” until the query is “FINISHED” state.</Paragraph>
                            <li>

                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`In [6]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={apicode6}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='python'
                                                height={'260px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li>

                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`In [7]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={apicode7}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='python'
                                                height={'40px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>

                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`In [8]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={apicode8}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='python'
                                                height={'40px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>

                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`In [9]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <SqlEditor
                                                QueryValue={apicode9}
                                                fromRoute="usage"
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                className='guide-editor'
                                                mode='python'
                                                height={'20px'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>

                                <div className='d-block text-center w-100 mt-2'>
                                    <div className='row m-0'>
                                        <div className='col-1 px-0 text-left'>
                                            <code className='color-primary m-0'>{`Out [9]:`}</code>
                                        </div>
                                        <div className='col-10 pl-0'>
                                            <img className='border img-fluid mb-2' style={{ height: '200px', width: "100%" }} src={apioutput9}></img>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <Paragraph>In this way you can utilize python’s request method to call REST API.</Paragraph>

                        </ul>
                        <Divider />

                        <Title level={4}>
                            Connecting using curl method in terminal-
                        </Title>
                        <Paragraph>
                            This approach uses curl method for REST API calls in any terminal. Below is an example how you can execute queries using this method.
                        </Paragraph>
                        <ul>
                            <li>
                                <Paragraph>

                                    Make sure you have ‘curl’ installed in system. It is mostly preinstalled on almost all Linux distributions. You can check this using command ‘curl –version’
                                </Paragraph>
                                <pre className='bg-dark small text-white'>
                                    {apiCurlCMD}
                                </pre>
                            </li>
                            <li>
                                <Paragraph>

                                    Concatenate the access key with a colon, and the secret key. For example, your access key and a secret key becomes the following string:
                                </Paragraph>
                                <pre className='bg-dark small text-white'>
                                    {apiConcateAccessKeyCode}
                                </pre>
                            </li>
                            <li>
                                <Paragraph>
                                    Encode this string in base64 encoding.
                                </Paragraph>
                                <pre className='bg-dark small text-white'>
                                    {apiEncodingCode}
                                </pre>
                            </li>
                            <li>
                                <Paragraph>
                                    You can call the REST API using above encoded string in Authorization header using this command. Make sure to add additional headers as in python request method.
                                </Paragraph>
                                <pre className='bg-dark small text-white'>
                                    {apiPythonReqCode}
                                </pre>
                            </li>
                            <li>
                                <Paragraph>
                                    Trino puts the above query in “QUEUED” state. To start execution and get data from REST API you need to keep calling “nextUri” until the query is “FINISHED” state.
                                </Paragraph>

                            </li>

                        </ul>
                        <Divider />

                        <Title level={4}>
                            Connecting using Postman-
                        </Title>
                        <Paragraph>
                            This approach uses Postman tool for calling REST API.
                        </Paragraph>
                        <ul>
                            <li>
                                <Paragraph>
                                    You will need following host and headers for calling the REST API
                                </Paragraph>
                                <div className='text-left'>
                                <Paragraph className='pl-4 w-75'>
                                    <blockquote>
                                        <Text strong>Host:</Text> {`${REACTAPP_TRINO_HOST_URL}/v1/statement/`}
                                    </blockquote>
                                    <blockquote>
                                        <Text strong>Headers:</Text> <br/>
                                        <code>
                                            X-Trino-User: {`${userDetails.email}`}
                                        </code><br/>
                                        <code>
                                            X-Trino-Time-Zone: UTC
                                        </code><br/>
                                        <code>
                                            X-Trino-Catalog: hive
                                        </code><br/>
                                        <code>
                                            Authorization: Basic ********************************
                                        </code>
                                    </blockquote>
                                </Paragraph>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    Make sure to encode the access key and secret key in base64 encoding. If you don’t have any base64 encoder available on your system, you can go to <Link target={'_blank'} href='https://www.base64encode.org/' strong>base64 encode</Link> and perform the steps below.
                                </Paragraph>
                                <blockquote>
                                    <Text>Join your access key and secret key with a colon (:). For example, your access and secret key will be like this -</Text>
                                    <br/>
                                    <code className='font-w-600'>67a5a628be79ddfd03d6879a82a5a827:405c6bd03f6cb10ade526a6cfe5eeaeb57e6780fbab45582c87c001ef49c0702</code>
                                </blockquote>
                                <blockquote>
                                    <Text>Paste this string inside the box as shown and click on <strong>Encode</strong>.</Text>
                                </blockquote>
                                <div className='text-left'>
                                    <img style={{ height: '500px' }} className='border img-fluid mb-2' src={encodeb64}></img>
                                </div>
                                <blockquote>
                                    <Text>Now copy the resulted base64 encoded string and use this in <strong>Authorization</strong> Header for API calls as explained below.</Text>
                                </blockquote>
                            </li>
                            <li>
                                <Paragraph>
                                    Select POST as a request method in Postman. Use host as specified above. Use required headers in <strong>Headers</strong> section of Postman. Make sure that you have followed step 2 and using <strong>Authorization</strong> as <strong>{`Basic <base64 encoded credentials>`}</strong> format, otherwise you will get error that credentials are empty. For example, your headers will look like this:
                                </Paragraph>
                                <div className='text-left'>
                                    <img style={{ height: '300px' }} className='border img-fluid mb-2' src={postmanheader}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    You can send the query in <strong>Body</strong> section of Postman as <strong>raw data</strong>. After calling the REST API in postman you will get response as follows:
                                </Paragraph>
                                <div className='text-left'>
                                    <img style={{ height: '500px' }} className='border img-fluid mb-2' src={querypostman}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    You can send the query in <strong>Body</strong> section of Postman as <strong>raw data</strong>. After calling the REST API in postman you will get response as follows:
                                </Paragraph>
                                <div className='text-left'>
                                    <img style={{ height: '500px' }} className='border img-fluid mb-2' src={querypostman}></img>
                                </div>
                            </li>
                            <li>
                                <Paragraph>
                                    Trino puts the above query in <strong>QUEUED</strong> state as shown in the response. Now you need to keep calling the <strong>nextUri</strong> received in response in <strong>FINISHED</strong> state. After the query is finished you will see <strong>columns</strong> as well as <strong>data</strong> keys in response, which will be the results of query
                                </Paragraph>
                                <div className='text-left'>
                                    <img style={{ height: '500px' }} className='border img-fluid mb-2' src={postmanresponse}></img>
                                </div>
                            </li>


                        </ul>
                    </Typography>
                </div>

            default:
                break;
        }
    }
    const [infoGraphic, setInfoGraphic] = useState([{
        id: 1,
        name: 'Python',
        infoGraphic: getInfographic('python')
    }])

    let FILE_OPTIONS = [{
        id: 1,
        name: 'Python',
        infoGraphic: getInfographic('python')
    }, {
        id: 2,
        name: 'Tableau',
        infoGraphic: getInfographic('tableau')
    }, {
        id: 3,
        name: 'Power BI',
        infoGraphic: getInfographic('power_bi')

    }, {
        id: 4,
        name: 'API',
        infoGraphic: getInfographic('api')

    },]


    useEffect(() => {
        let el = document.querySelector('#conn-guide-mod')
        if(el) {
            el.scroll(0, 0)
        }
    }, [infoGraphic])

    return (
        <Modal size="xl" isOpen={openModal} className="connection-guide-modal" centered={true}>
            <ModalHeader >
                <div className="d-flex align-items-center ">

                    <OUTBOUND height='20' width='20'></OUTBOUND><div><h2 className="section-heading ml-2">  Clarista Outbound Connection</h2></div>

                </div>

                <div className="">
                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { onClose(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                </div>
            </ModalHeader>
            <ModalBody className="p-0">
                <div className="merge-join-content">
                    <div style={{ width: "15%" }} className="join-list custom-border-right">
                        {
                            FILE_OPTIONS.map(
                                item => {
                                    const { id, Img, name, InfoGraphic } = item
                                    return (
                                        <React.Fragment key={id}>
                                            <div
                                                onClick={() => {
                                                    setInfoGraphic([item])
                                                }}
                                                className={`join-list-item ${infoGraphic[0].id === id ? `selected-list-item` : ``}`}
                                            >
                                                {/* <Img /> */}
                                                <p className="label mb-0">{name}</p>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            )
                        }
                    </div>
                    <div id="conn-guide-mod" style={{ width: "85%", overflow: "auto" }} className="infographic">
                        {
                            infoGraphic.map(
                                item => {
                                    const { id, Img, name, infoGraphic } = item
                                    return (infoGraphic)
                                }
                            )
                        }
                    </div>
                </div>
            </ModalBody>

        </Modal>
    )
}

export default ConnectionGuideModal