import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import DataDomainListView from '../dataDomains/components/DataDomainListView';
import DataDomainCardView from '../dataDomains/components/DataDomainCardView';
import CreateButton from "../common/CreateButton";
import ConnectionModal from "../../connections/landingPage/components/ConnectionModal";
import { Literals } from "../../common/literals";
import { useSelector } from "react-redux";


const DataDomains = (props) => {
  //const loadingStatus = useSelector(state => state.LoadingReducer.loadingStatus);
  const history = useNavigate();
  const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false)
  const isSuperUser = useSelector(state => state?.UserReducer?.user?.userDetails?.is_superuser)


  const openConnectionModalHandler = () => {
    history({ pathname: Literals.links.CREATE_DOMAIN_USER_MANAGEMENT, state: { tabName: 'Data Domain' } })
    window.history.replaceState({ state: { tabName: 'Data Domain' }},'')

  }

  const layOutReturn = () => {
    
    return (
      props.layout === 'grid' ?
        <div>
          <DataDomainCardView 
            showDataDomainDetails={true} 
            // setLayout={props.setLayout} 
            setShowDataDomainDetails={true} 
            searchData={props.setSearchData} 
          />
        </div> :
        <DataDomainListView 
          showDataDomainDetails={true} 
          // setLayout={props.setLayout}  
          setShowDataDomainDetails={true} 
          searchData={props.setSearchData} 
        />
    )
  }
  return (
    <div style={{height: 'calc(100vh - 4.375rem)', overflowY: 'auto'}}>
      {/* <UsersMain searchData={searchData} />
      {loadingStatus ? <LoadingComponent /> : null} */}

      {layOutReturn()}
      <CreateButton isDisabled={!isSuperUser} openConnectionModal={openConnectionModalHandler} title="Add Data Domain" />
    </div>
  );
};
export default DataDomains;
