import React, { forwardRef, useState, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import ActionPopUp from "../../common/actionPopUp";

import {
  getPreviewTable,
  SaveQueryAPI,
  saveSQlTrigger,
  setPreviewTableQuery,
} from "../../../../store/modules/dataCatalogue/preview/previewActions";

import { TextFile } from "../../common/helperFunctions";
import SqlEditor from "../../dataCatalogue/preview/components/SqlEditor";

import {

  setAlertQueryResult,
  setAlertSqlEditorQuery,
  setAlertSqlResponseQuery,
} from "../../../../store/modules/alert/createAlert/createAlertAction";
import { setActiveTabKey, setPreviewFlag, setResponseQuery } from "../../../../store/modules/common/DataGridTable/dataGridTableRedux";
import { resetVirtualTableRedux } from "../../../../store/modules/common/VirtualDataTable/action";
import Buttons from "../../../Common/button/Buttons";
import { DOWNLOAD, EXITFULLSCREEN, FULLSCREEN, PLAY } from "../../../Common/iconSource";
import { Literals } from "../../common/literals";


const AlertSqlEditor = forwardRef(
  (
    {
      selectedQuery,
      tableId,
      editorHeight,
      readOnlyEnable,
      isEditingQuery,
      setIsEditingQuery,
      getSavedQueries,
      closeEditor,
      expandTable,
      setexpandTable,
      uuid = '',
      getUUID = () => { },
      setreadOnlyEnable,
      setVirtualTableData = () => { },
      activeKey,
      setshowSkelaton
    },
    ref
  ) => {
    // console.log(uuid)
    const dispatch = useDispatch();
    const store = useStore();
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [saveModalInput, setSaveModalInput] = useState([]);
    const [sqlTableDetails, setsqlTableDetails] = useState([]);

    const [Query, setQuery] = useState("");
    const sqlQuery = useSelector(
      (state) => state.Alert.CreateAlert.alertSqlEditorQuery
    );
    const previewTables = useSelector(
      (state) => state.Alert?.CreateAlert?.previewAlertTables
    );
    const selectedPodList = useSelector(
      (state) => state.Alert.CreateAlert.selectedAlertPodList
    );

    useEffect(() => {

      CreateSqlTableDetails(previewTables, selectedPodList);
    }, [previewTables, selectedPodList]);
    const getPreviewTableResult = (payload) => {

      // let Analysispayload = {
      //   uuid: uuid,
      //   table_name: '',
      //   page: 0,
      //   do_count: false,
      //   sort_body: [],
      //   filter_body: [],
      //   table_ids: [],
      //   query: Query !== '' ? Query : sqlQuery,
      // }
      try {
        setshowSkelaton(true)
        setreadOnlyEnable(true)
        store
          .dispatch(getPreviewTable(payload, false))
          .then((resp) => {
            if (resp && resp.status === "success" && resp.data?.result) {
              let responseData = resp.data?.result;
              store.dispatch(setAlertSqlResponseQuery(resp.data.query));
              store.dispatch(setAlertQueryResult(responseData));
              store.dispatch(
                setPreviewTableQuery(
                  resp.data.query
                )
              );
              store.dispatch(setActiveTabKey('Result'))
              localStorage.setItem("previewFlag", "Query");
              store.dispatch(setPreviewFlag("Query"))
              store.dispatch(resetVirtualTableRedux())
              store.dispatch(setResponseQuery(resp.data.query))

              setshowSkelaton(false)

              setreadOnlyEnable(false)

            } else {
            }
          })
          .catch((err) => {
            setshowSkelaton(false)

            setreadOnlyEnable(false)

          });
      } catch (error) {
        setshowSkelaton(false)

        setreadOnlyEnable(false)

      }
    };

    const mergeArray = (arrayElement) => {
      let merge = [];

      arrayElement.forEach((ele) => {
        merge = [...merge, ...ele[Object.keys(ele)[0]]];
      });

      return merge;
    };
    const executeQuery = (queryfromeditor) => {
      let uuid = getUUID();
      // console.log(uuid)
      let appliedFilter = store.getState().Alert.CreateAlert.appliedFilters;
      // let Filters = [];
      // if (appliedFilter.length) {
      //   Filters = mergeArray(appliedFilter);
      // }
      // console.log(queryfromeditor, sqlQuery)
      let payload = {
        uuid: uuid,
        table_name: '',
        page: 0,
        do_count: false,
        sort_body: [],
        filter_body: appliedFilter,
        table_ids: [],
        query: queryfromeditor ? queryfromeditor : sqlQuery,
      }
      getPreviewTableResult(payload, false);
      setexpandTable(false)
    };

    useImperativeHandle(
      ref,
      () => ({
        executeQuery: (query) => {
          executeQuery(query)
        },
        downloadQueryHandler: () => {
          downloadQueryHandler()
        }
      }),

    )
    useEffect(() => {

      if (sqlQuery) {
        setQuery(sqlQuery);
      }
      // if (sqlQuery === '') {
      //   setQuery('')
      // }
    }, [sqlQuery]);

    const setQueryvalue = (query) => {

      if (query) {
        setQuery(query);

        store.dispatch(setAlertQueryResult({}));
        if (activeKey === '0') {

          setVirtualTableData({})
        }
        dispatch(setAlertSqlEditorQuery(query));
        dispatch(setPreviewTableQuery(query));

      }

      // else {
      //   setQuery(query);

      //   dispatch(setAlertSqlEditorQuery(""));
      //   dispatch(setPreviewTableQuery(""));
      // }
    };

    const createPayloadHandler = () => {


      if (isEditingQuery) {
        return {
          action: "edit_query",
          table_id: tableId ? tableId : "",
          query_name: saveModalInput[0] ? saveModalInput[0] : "",
          query_description: saveModalInput[1] ? saveModalInput[1] : "",
          created_by: "admin",
          old_query_name: selectedQuery.name,
          query_string: selectedQuery.queryString,
          updated_by: selectedQuery.updatedBy,
        };
      } else {
        return {
          action: "save_query",
          table_id: tableId ? tableId : "895",
          query_name: saveModalInput[0] ? saveModalInput[0] : "Query 1",
          query_description: saveModalInput[1]
            ? saveModalInput[1]
            : "sample query1 1st save in table",
          query_string:
            Query !== "" ? Query : " SELECT * FROM snow_tpch1000.customer",
          created_by: "admin",
        };
      }
    };

    const saveQueryHandler = () => {
      const payload = createPayloadHandler();

      try {
        dispatch(SaveQueryAPI(payload)).then((res) => {
          if (res.status === "success") {
            getSavedQueries();
          }

          closeSaveModalHandler();
          closeEditor();
        });
      } catch (error) {
        console.error("Error saving preview query", error);
      }

      setQueryvalue("");
      dispatch(setPreviewTableQuery(""));
    };

    const closeSaveModalHandler = () => {
      setIsSaveModalOpen(false);
    };

    const openSaveModalHandler = () => {
      if (Query) {
        setIsSaveModalOpen(true);
      }
    };

    const downloadQueryHandler = () => {
      if (Query) {
        TextFile(Query);
      }
    };

    useEffect(() => {
      if (selectedQuery?.name) {
        setSaveModalInput([selectedQuery?.name, selectedQuery?.description]);
      }
    }, [selectedQuery]);

    const saveModalContent = {
      heading: "Save Query",
      message: "",
      headingIcon: "bx bx-save",
      button: [
        {
          name: "save",
          action: saveQueryHandler,
        },
      ],
      elements: [
        {
          label: "Query Name",
          type: "input",
          currentValue: isEditingQuery ? selectedQuery.name : "",
          placeholder: "Enter Query Name",
        },
        {
          label: "Query Discription",
          type: "textarea",
          currentValue: isEditingQuery ? selectedQuery.description : "",
          placeholder: "Enter Query Discription",
        },
      ],
      closeActionPopup: closeSaveModalHandler,
    };
    const CreateSqlTableDetails = (tables, selectedPodList) => {
      let sqlTables = [];

      if (tables && tables.length) {
        tables.forEach((element) => {
          let tableInfo = element[Object.keys(element)[0]];
          if (tableInfo && tableInfo?.podColumn) {
            tableInfo.podColumn.map((e) => {
              sqlTables.push({
                name: e.Name,
                description: "",
                type: "Column",
                tableName: Object.keys(element)[0],
              });
            });
          }
        });
        if (selectedPodList) {
          selectedPodList.forEach((pod) => {
            sqlTables.push({
              name: `${pod.dataDomain}.${pod.podName}`,
              description: "",
              type: "Table",
              tableName: "",
            });
          });
        }
      }

      if (sqlTables.length) {
        setsqlTableDetails([...sqlTables]);
      } else {
        setsqlTableDetails([]);
      }
    };

    return (
      <div className="sql-editor-container">
        <div className={expandTable ? "bg-white  alert-full-screen-sql" : "bg-white flow-filter-height flow-sql-prop-edit h-100"}>
          {expandTable ?
            <div className="d-flex align-items-center justify-content-end"> <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline custom-btn btn-with-icon  mr-2",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  // downloadQueryHandler();
                  dispatch(saveSQlTrigger({ trigger: 'download_click' }))

                },
                ImgSrc: () => <DOWNLOAD />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            />
              <Buttons
                props={{
                  buttonText: "",
                  buttonClassName: "custom-btn-outline custom-btn  mr-2 btn-with-icon",
                  buttonEvent: (e) => {
                    e.stopPropagation();
                    // executeQuery();
                    dispatch(saveSQlTrigger({ trigger: 'run_click' }))

                  },
                  ImgSrc: () => <PLAY />,
                  isDisable: window.location.href.includes("viewAlert") ? true : false,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                }}
              />
              <Buttons props={{ buttonText: '', tooltip: "Exit Full Screen", buttonClassName: "custom-btn-outline mr-2 mt-2 mb-2 float-right custom-btn btn-with-icon", buttonEvent: (e) => { e.stopPropagation(); setexpandTable(false) }, ImgSrc: () => <EXITFULLSCREEN />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
            </div>
            :
            null
          }
          {/* <div
          className="editor-options-header custom-border-bottom padding-1 margin-bottom-2"
          ref={ref}
        > */}
          {/* <div className="editor-options-title"> */}
          {/* <span className="subtitle">{Literals.SQL_EDITORS}</span> */}
          {/* </div> */}
          {/* <div className="d-flex align-items-center">
            <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline custom-btn btn-with-icon mr-2",
                buttonEvent: () => {
                  if (isEditingQuery) {
                    setIsEditingQuery(false);
                  }
                  closeEditor();
                },
                ImgSrc: () => <CLOSE />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            />
            <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline custom-btn btn-with-icon mr-2",
                buttonEvent: () => {},
                ImgSrc: () => <UNDO />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            />
            <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline custom-btn btn-with-icon mr-2",
                buttonEvent: () => {},
                ImgSrc: () => <REDO />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            />
            <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline custom-btn btn-with-icon mr-2",
                buttonEvent: () => {
                  downloadQueryHandler();
                },
                ImgSrc: () => <DOWNLOAD />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            />
            <Buttons
              props={{
                buttonText: "RUN",
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                buttonEvent: () => {
                  executeQuery();
                },
                ImgSrc: () => <PLAY />,
                isDisable: false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          </div> */}


          {/* </div> */}

          <SqlEditor
            sqlTableDetails={sqlTableDetails}
            readOnly={window.location.href.includes("viewAlert") || readOnlyEnable ? true : false}
            setQueryvalue={setQueryvalue}
            QueryValue={Query}
            debounceTime={1}
            ExecuteQueryClick={executeQuery}
            downloadQueryHandler={downloadQueryHandler}
            height={!expandTable ? editorHeight : 'calc(100vh - 100px)'}
          />
          <ActionPopUp
            openModal={isSaveModalOpen}
            actionData={saveModalContent}
            inputValueState={saveModalInput}
            modalSize={"lg"}
            setinputValueState={setSaveModalInput}
          />
        </div>
      </div>
    );
  }
);

export default AlertSqlEditor;
