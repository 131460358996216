import { Avatar, ConfigProvider, Progress } from 'antd'
import React, { useMemo } from 'react'
import { eChartsThemeObject, pxToRem, remToPx } from '../../common/helperFunctions'
import ReactEcharts from 'echarts-for-react';
import SourceTypeImage from '../../common/sourceTypeImage';
import { ICON_CATALOG, ICON_CATALOG_DOMAIN, ICON_CHAT, ICON_DASHBOARD, ICON_FLOWS, ICON_SCHEDULER, ICON_USER } from '../../../Common/newIconSource';
import DurationDropdown from './DurationDropdown';
import DomainDropdown from './DomainDropdown';
import { useState } from 'react';
import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { getUsageStats } from '../../../../store/modules/jobsUsage/jobsAction';
import { getDataDomainConnectionList } from '../../../../store/actions';
import NoGraphDataFound from '../../clusters/NoGraphDataFound';
import { getCreditUsageChart } from '../../../../store/modules/cluster/Cluster';
import { cloneDeep } from 'lodash';
import macarons from '../../genAi/components/echartsThemeMacarons.json';
import NoResultsFound from '../../flows/createFlows/NoResultsFound';
import { Literals } from '../../common/literals';
import { useNavigate } from 'react-router-dom';

const UsageStats = () => {

    const avtSize = { xs: 24, sm: 32, md: 40, lg: 40, xl: 50, xxl: 64 }
    const [selectedDomain, setselectedDomain] = useState('all');
    const [domainCount, setdomainCount] = useState('');
    const [usageData, setusageData] = useState({});
    const [connectionProviders, setconnectionProviders] = useState([]);
    const [usageGraphData, setusageGraphData] = useState({})
    const [duration, setduration] = useState('daily');
    const [showdefaultGraph, setshowdefaultGraph] = useState(false);
    const navigate = useNavigate()
    const store = useStore();

    const dynamic = px => remToPx(pxToRem(px))

    useEffect(() => {
        if (selectedDomain !== '') {
            store.dispatch(getUsageStats(selectedDomain && selectedDomain === 'all' ? undefined : selectedDomain)).then((resp) => {
                if (resp?.status === 'success') {
                    setusageData(resp?.data);
                }
            });
            store.dispatch(getDataDomainConnectionList({ data_domain_id: selectedDomain && selectedDomain === 'all' ? undefined : selectedDomain })).then((resp) => {
                if (resp?.status === 'success' && resp.data?.length) {
                    let providers = resp.data.map((item) => !item?.assigned_as_cache && !item?.assigned_as_upload && item?.source_type)?.filter((item) => item)
                    setconnectionProviders([...new Set(providers)]);
                }
            })
            store.dispatch(getCreditUsageChart(duration, undefined, undefined)).then((resp) => {

                let graphData = cloneDeep(resp.data);
                /**For Circle ICON Legend */
                // graphData['legend']['data'] = graphData?.legend?.data?.map(l => ({name: l, icon: 'circle'}));
                // graphData['legend']['itemWidth'] = 8
                // graphData['legend']['itemHeight'] = 8
                graphData['series'][0]['type'] = "bar";
                if(graphData['series'][0]['data']?.length){
                   
                    graphData['series'][0]['data']= graphData['series'][0]['data']?.map(element => {
                       return element?.toFixed(2);
                    });
                    
                }
                graphData['grid'] = { top: dynamic(38), right: dynamic(50), bottom: dynamic(60), left: dynamic(50) }

                graphData['textStyle'] = {
                    fontSize: dynamic(14)
                }
                graphData['tooltip'] = {
                    trigger: 'axis',
                    textStyle: {
                        fontSize: dynamic(14)
                    }
                }
                graphData['xAxis']['splitLine'] = {
                    show: false
                }
                graphData['yAxis']['splitLine'] = {
                    show: false
                }
                graphData['xAxis'] = {
                    ...graphData['xAxis'],
                    axisLabel: {
                        fontSize: dynamic(14)
                    }
                }
                graphData['yAxis'] = {
                    ...graphData['yAxis'],
                    axisLabel: {
                        fontSize: dynamic(14)
                    }
                }
                graphData['legend'] = {
                    textStyle: {
                        fontSize: dynamic(12), // Font size for legend text
                    },
                    itemHeight: dynamic(12),
                    itemWidth: dynamic(18),
                }

                if (!graphData?.xAxis?.data?.length) {
                    setshowdefaultGraph(true)
                } else {
                    setshowdefaultGraph(false)

                    setusageGraphData(graphData)
                }
            }).catch((e) => {
                setshowdefaultGraph(true)
            })
        }
    }, [selectedDomain, duration]);

    const creditUsageGraph = useMemo(() => {
        return showdefaultGraph ? <NoGraphDataFound height="17rem" imgheight='50' imgwidth='50' /> : <ReactEcharts
            option={usageGraphData}
            style={{ height: "100%", width: "100%", padding: "0px", margin: "0px" }}
            theme={eChartsThemeObject}
        />

        // <ReactEcharts className='credit-usage-graph' style={{ height: "272px", width: document.getElementById('usage-graph-div')?.clientWidth ? `${(document.getElementById('usage-graph-div')?.clientWidth + 100)}px` : "850px" }} option={usageGraphData} theme={
        //     {
        //         ...macarons,
        //         color: [
        //             "#082B59",
        //             "#1B78F2",
        //             "#8DB9F2",
        //             "#79CFD9",
        //             "#F2F2F2",
        //             "#496C94",
        //             "#D8E8F2",
        //             "#77C5D6"

        //         ]
        //     }}
        // />

    }
        , [usageGraphData, showdefaultGraph])

    return (
        <div className='new-usage-stats-wrapper h-100'>
            <div>
                <div className='d-flex align-items-center  justify-content-between'>
                    <div>
                        <label className='mb-0 fontInterSemiBold fontSizeLargeHeading'>
                            Stats
                        </label>
                    </div>
                    <div>
                        <DomainDropdown selectedDomain={selectedDomain}
                            setselectedDomain={setselectedDomain} setdomainCount={setdomainCount} />
                    </div>
                </div>
                <div className='n-usg-stats-upper'>
                    <div>
                        <div className='nusg-kpi-row pl-1'>
                            <div className='kpi-holder cursor-pointer'>
                                <div className='d-flex justify-content-between align-items-center mt-1 w-100'>
                                    <div>
                                        <p className='nusg-kpi-heading fontInterSemiBold fontSizeHeading'>
                                            Connection
                                        </p>
                                        <label className='nusg-kpi-val fontInterSemiBold'>
                                            {usageData?.connection?.total_count}
                                        </label>
                                    </div>
                                    <div>
                                        <div>
                                            <ConfigProvider
                                                theme={{
                                                    components: {
                                                        Avatar: {
                                                            groupOverlapping: '-18%'
                                                        }
                                                    }
                                                }}
                                            >
                                                <Avatar.Group
                                                    size={avtSize?.lg}
                                                    maxCount={1}
                                                    maxStyle={{ background: '#6b6b6b' }}
                                                    className='nusg-conn-avt-grp'
                                                >

                                                    {connectionProviders?.map((item) => <Avatar
                                                        size={avtSize?.lg}
                                                        icon={<SourceTypeImage dataName={item?.toLowerCase()}
                                                            extraClassName={'nusg-conn-avt'}
                                                        />}
                                                    />)}

                                                </Avatar.Group>
                                            </ConfigProvider>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                [
                                    { icon: <ICON_CATALOG_DOMAIN color='currentColor' />, title: 'Domains', value: selectedDomain === 'all' ? domainCount : '1', redirectUrl: `${Literals.links.USER_MANAGEMENT}#datadomain` },
                                    { icon: <ICON_CATALOG color='currentColor' />, title: 'PODs', value: usageData?.pod?.total_count, redirectUrl: `${Literals.links.CLARISTA_NAVIGATOR}#pod` },
                                    { icon: <ICON_FLOWS color='currentColor' />, title: 'Flows', value: usageData?.flow?.total_count, redirectUrl: `${Literals.links.CLARISTA_LAB}#flows` },
                                ]
                                    ?.map((kpi, idx) => (
                                        <React.Fragment key={`upper-kip-${idx}`} >
                                            <div className='cursor-pointer kpi-holder' onClick={() => navigate(kpi.redirectUrl)}>
                                                <div className=' d-flex align-items-center'>
                                                    <div>
                                                        <span className='nusg-kpi-ic'>
                                                            {kpi?.icon}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <p className='nusg-kpi-heading fontInterSemiBold fontSizeHeading'>
                                                            {kpi?.title}
                                                        </p>
                                                        <label className='nusg-kpi-val fontInterSemiBold'>
                                                            {kpi?.value}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                            }
                        </div>
                        <hr className='nusg-kpi-sep' style={{ borderColor: '#d5d5d5' }} />
                        <div className='nusg-kpi-row'>
                            {
                                [
                                    { icon: <ICON_DASHBOARD color='currentColor' />, title: 'Dashboards', value: usageData?.dashboard?.total_count, redirectUrl: `${Literals.links.CLARISTA_PULSE}#dashboards` },
                                    { icon: <ICON_CHAT color='currentColor' />, title: 'TALKdata', value: usageData?.question?.total_count, redirectUrl: `${Literals.links.CLARISTA_PULSE}#chats` },
                                    { icon: <ICON_USER color='currentColor' />, title: 'Users', value: usageData?.user?.total_count, redirectUrl: `${Literals.links.USER_MANAGEMENT}#users` },
                                    { icon: <ICON_SCHEDULER color='currentColor' />, title: 'Schedulers', value: usageData?.scheduler?.total_count, redirectUrl: `${Literals.links.CLARISTA_LAB}#scheduler` },
                                ]
                                    ?.map((kpi, idx) => (
                                        <React.Fragment key={`upper-kip-${idx}`} >
                                            <div className='cursor-pointer kpi-holder' onClick={() => navigate(kpi.redirectUrl)}>
                                                <div className=' d-flex align-items-center'>
                                                    <div>
                                                        <span className='nusg-kpi-ic'>
                                                            {kpi?.icon}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <p className='nusg-kpi-heading fontInterSemiBold fontSizeHeading'>
                                                            {kpi?.title}
                                                        </p>
                                                        <label className='nusg-kpi-val fontInterSemiBold'>
                                                            {kpi?.value}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                            }
                        </div>

                    </div>
                    <div>
                        <div>
                            <div className='fontSizeHeading fontInterSemiBold'>TALKdata</div>
                            {/* <label className='mb-0 text-black-50 fontSizeHeading'>For Last 30 Days</label> */}
                        </div>
                        {usageData?.feedback?.total_count ? <div className=''>
                            <div className='n-usg-upper-graph'>
                                <Progress
                                    className='usage-circle-progress'
                                    type="circle"
                                    percent={Math.round((usageData?.feedback?.disliked_count / usageData?.question?.total_count) * 100)}
                                    strokeWidth={dynamic(20)}
                                    strokeLinecap={'butt'}
                                    trailColor='#B0BAFF'
                                    strokeColor={'#ff04008c'}
                                    status='normal'
                                    showInfo={true}
                                />
                            </div>
                            <div className='d-flex justify-content-center' style={{ gap: dynamic(15) }}>
                                <div className='d-flex align-items-start'>
                                    <div className='pt-1'>
                                        <span className='n-usg-legend mr-1' style={{ background: '#ff04008c' }}></span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <p className='fontSizeLabel text-dark mb-0'>
                                            Unsatisfied Feedback
                                        </p>
                                        <label className='mb-0 fontSizeLabel text-dark'>{usageData?.feedback?.disliked_count}</label>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start'>
                                    <div className='pt-1'>
                                        <span className='n-usg-legend mr-1' style={{ background: '#B0BAFF' }}></span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <p className='fontSizeLabel text-dark mb-0'>
                                            Total Questions
                                        </p>
                                        <label className='mb-0 fontSizeLabel text-dark'>{usageData?.question?.total_count}</label>
                                    </div>
                                </div>
                            </div>
                        </div> : <div className="no-flow-preview-data text-center">
                            <NoResultsFound imgheight='80' imgwidth='120' msg='' submsg='No feedbacks received yet' />
                        </div>}

                    </div>
                </div>
            </div>
            <div>
                <div className='d-flex align-items-center n-usg-heading justify-content-between'>
                    <div>
                        <label className='mb-0 fontInterSemiBold fontSizeLargeHeading'>
                            Clarista Usage
                        </label>
                    </div>
                    <div>
                        <DurationDropdown
                            defaultSelectedKey={duration}
                            setParentActiveKey={setduration}

                            menuItems={[
                                {
                                    label: 'Daily',
                                    key: 'daily',
                                },
                                {
                                    label: 'Monthly',
                                    key: 'monthly',
                                },
                                {
                                    label: 'Yearly',
                                    key: 'yearly',
                                },
                            ]}
                        />
                    </div>
                </div>
                <div id={'usage-graph-div'} className='n-usg-lower-graph'>
                    {creditUsageGraph}
                    {/* <div className='d-flex justify-content-center' style={{ gap: 15 }}>
                        <div className='d-flex align-items-start'>
                            <div className='pt-1'>
                                <span className='n-usg-legend mr-1' style={{ background: '#5A6ACF' }}></span>
                            </div>
                            <div className='d-flex flex-column'>
                                <p className='fontSizeLabel text-dark mb-0'>
                                    Usage
                                </p>
                                {/* <label className='mb-0 fontSizeLabel text-dark'>40%</label> 
                            </div>
                        </div>
                        <div className='d-flex align-items-start'>
                            <div className='pt-1'>
                                <span className='n-usg-legend mr-1' style={{ background: '#CFD4F5' }}></span>
                            </div>
                            <div className='d-flex flex-column'>
                                <p className='fontSizeLabel text-dark mb-0'>
                                    Time
                                </p>
                                {/* <label className='mb-0 fontSizeLabel text-dark'>60%</label> 
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default UsageStats