import React from 'react'
import { Literals } from '../../common/literals';
import { FILLEDSTAR, STAR } from '../../../Common/iconSource';
import Buttons from '../../../Common/button/Buttons';
import { ICON_CHART, ICON_CHECK, ICON_CLOSE, ICON_EDITBOX, ICON_LINK, ICON_LOADING_CIRCLE, ICON_SAVE, ICON_SHARE } from '../../../Common/newIconSource';
import PulseSearchBox from '../../../Common/mainLandingPage/components/PulseSearchBox';
import { Button, Select, Space, Tooltip } from 'antd';
import CommonModal from '../../../Common/CommonModal/CommonModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton';
import { useDispatch } from 'react-redux';
import { bookmarkChat, updateChat } from '../../../../store/modules/common/GenAi/GenAiReducer';
import ShareChatModal from './ShareChatModal';
import DataStewardsView from '../../dataDictionary/components/DataStewardsView';
import MarkdownText from '../../../Common/MarkdownText';

const TalkDataQuestionHeader = React.forwardRef(({
    activeDomain = '',
    setActiveDomain = () => {},
    setSearchFocus = () => {},
    usersList = [],
    usersLoading = false,
    query = "",
    questValue = '',
    setQuestValue = () => {},
    onSend = () => {},
    chatData = {},
    fetchingConv = false,
    convError = false,
    chatError = ''
}, ref) => {

    const history = useNavigate()
    const dispatch = useDispatch()
    let location = useLocation()
    let fromStartPage = location?.state?.fromStartPage ?? false

    const [shareModalOpen, setShareModalOpen] = React.useState(false)
    const [bookmark, setBookmark] = React.useState(false)
    const [bookmarkLoading, setBookmarkLoading] = React.useState(false)
    const [sharedUsers, setSharedUsers] = React.useState([])
    const [chatName, setChatName] = React.useState("")
    const [newName, setNewName] = React.useState("")
    const [isEditingName, setEditingName] = React.useState(false)
    const [isUpdating, setUpdating] = React.useState(false)
    const [chatSharedUsers, setChatSharedUsers] = React.useState([])

    const searchBoxRef = React.useRef({})

    React.useImperativeHandle(ref, () => {
        return {
            focus: () => {
                searchBoxRef?.current?.focus()
            }
        }
    }, [])

    React.useEffect(() => {
        if(chatData?.chat_detail?.bookmark) {
            setBookmark(chatData?.chat_detail?.bookmark)
        }
        if(chatData?.chat_detail?.chatusers) {
            let usrs = chatData?.chat_detail?.chatusers?.map(u => u?.id)
            setSharedUsers(usrs)
        }
        if(chatData?.chat_detail?.name) {
            setChatName(chatData?.chat_detail?.name)
            setNewName(chatData?.chat_detail?.name)
        }
    }, [chatData])


    React.useEffect(() => {
        let usrs = usersList?.filter(u => sharedUsers?.includes(u?.data?.id))?.map(u => ({...u?.data}))
        setChatSharedUsers(usrs)
    }, [sharedUsers, usersList])

    const handleBookmark = (book) => {
        const id = chatData?.chat_detail?.id

        if(id === undefined) return

        setBookmarkLoading(true)
        dispatch(bookmarkChat(id, book, true))
        .then(() => {
            setBookmarkLoading(false)
            setBookmark(book)
        })
        .catch(() => {
            setBookmarkLoading(false)
        })
    }
    
    const handleUpdateName = () => {
        if(newName?.trim() === '') return
        setUpdating(true)
        let payload = {
            name: newName
        }
        dispatch(updateChat(chatData?.chat_detail?.id, payload))
        .then(() => {
            setChatName(newName)
            setUpdating(false)
            setEditingName(false)
        })
        .catch(() => {
            setUpdating(false)
        })
    }

    return (
        <div className='talk-data-chat-header d-block px-2 py-1'>
            <div className={`talk-data-ques-header align-items-center ${chatSharedUsers?.length > 0 ? 'shared-user-extend' : ''}`}>
                <div>
                    <div className='ques-bar d-flex'>
                        {
                            chatData?.chat_detail?.name
                            ?
                            <>
                                {
                                    isEditingName
                                    ?
                                    <>
                                        <input
                                            className='custom-input-field fontInterSemiBold'
                                            defaultValue={chatName}
                                            value={newName}
                                            style={{fontSize: 18, color: '#256fa7'}}
                                            onChange={(e) => {
                                                setNewName(e?.target?.value)
                                            }}
                                            disabled={isUpdating}
                                        />
                                    </>
                                    :
                                    <>
                                        <Tooltip title={chatName ?? ''} 
                                        placement='bottomLeft' >
                                            <h2 
                                            className='d-block text-with-ellipsis text-capitalize mb-0' style={{color: "#256fa7", lineHeight: '1.4'}}>
                                                {chatName ?? ''}
                                            </h2>
                                        </Tooltip>
                                    </>
                                }
                                <span className='ml-2'>
                                    {
                                        isEditingName
                                        ?
                                        <Buttons
                                            props={{
                                                buttonText: isUpdating ? 'Updating...' : 'Save',
                                                tooltip: '',
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-text btn-large border",
                                                buttonEvent: (e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    handleUpdateName()
                                                },
                                                ImgSrc: () => isUpdating ? <ICON_LOADING_CIRCLE/> : <ICON_SAVE />,
                                                isDisable: isUpdating || fetchingConv,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        />
                                        :
                                        <Buttons
                                            props={{
                                                buttonText: '',
                                                tooltip: 'Edit',
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large",
                                                buttonEvent: (e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setEditingName(true)
                                                },
                                                ImgSrc: () => <ICON_EDITBOX />,
                                                isDisable: fetchingConv,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        />
                                    }
                                </span>
                            </>
                            :
                                convError
                                ?
                                <h2 className='d-block text-with-ellipsis text-capitalize mb-0 text-danger'>
                                    Error!
                                </h2>
                                :
                                    chatData?.chat_detail?.name === undefined
                                    || chatData?.chat_detail?.name === null 
                                    || chatError?.length > 0 ? ''
                                    :
                                    <CustomSkeleton height='24px' width='400px'/>
                        }
                    </div>
                </div>
                <div>
                    {/* <div className='talkData-ask-input'> */}
                    {
                        chatError === ""
                        ?
                        <PulseSearchBox
                            activeDomain={activeDomain}
                            setActiveDomain={setActiveDomain}
                            setFocus={setSearchFocus}
                            searchValue={questValue}
                            setSearchValue={setQuestValue}
                            onSearch={onSend}
                            disableDomain={true}
                            onEnterCallback={onSend}
                            disableInput={fetchingConv || chatError?.length > 0}
                            suggestionData={chatData?.chat_detail?.top_question ?? []}
                            suggestVisibility={true}
                            showSuggestion={false}
                            ref={searchBoxRef}
                        />
                        :   ""
                    }
                    {/* </div> */}
                </div>
                <div>
                    <div className='d-flex align-items-center justify-content-end' style={{gap: 10}}>
                        
                        {
                            chatSharedUsers?.length === 0 ? ''
                            :
                            <>
                                {/* <label className='mb-0 label chat-shared-label mr-1'>Shared With:</label> */}
                                <DataStewardsView 
                                    data={chatSharedUsers} 
                                    maxCount={2} 
                                    wrapperClass="talk-data-header-usr-shared"
                                    avatarClass="talk-data-header-usr-shared-avt"
                                />
                            </>
                        }

                        {
                            chatData?.chat_detail?.id
                            ?
                            <>
                                <Buttons
                                    props={{
                                        buttonText: '',
                                        tooltip: bookmarkLoading ? 'Adding to Favourite' : bookmark ? Literals.REMOVE_FAVOURITES : Literals.ADD_FAVOURITES,
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large",
                                        buttonEvent: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleBookmark(!bookmark)
                                        },
                                        ImgSrc: () => bookmarkLoading ? <ICON_LOADING_CIRCLE/> : bookmark ? <FILLEDSTAR/> : <STAR />,
                                        isDisable: fetchingConv || bookmarkLoading || chatError?.length > 0,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />
                                <Buttons
                                    props={{
                                        buttonText: '',
                                        tooltip: 'Share',
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large",
                                        buttonEvent: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setShareModalOpen(true)
                                        },
                                        ImgSrc: () => <ICON_SHARE />,
                                        isDisable: chatError?.length > 0 || fetchingConv,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />
                            </>
                            :   ''
                        }
                        <Buttons
                            props={{
                                buttonText: '',
                                tooltip: 'Close',
                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large",
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    let domainId = chatData?.data_domain

                                    if(!domainId) {
                                        domainId = chatData?.chat_detail?.data_domain
                                    }

                                    if(fromStartPage) {
                                        history(Literals.links.CLARISTA_HOME, { state: { domainId} })
                                    }
                                    else {
                                        history(Literals.links.PULSE_LANDING + '#chats', { state: { domainId } })
                                    }
                                },
                                ImgSrc: () => <ICON_CLOSE />,
                                isDisable: fetchingConv,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            }}
                        />
                    </div>
                </div>
            </div>
            <ShareChatModal
                open={shareModalOpen}
                setOpen={setShareModalOpen}
                usersList={usersList}
                usersLoading={usersLoading}
                chatId={chatData?.chat_detail?.id}
                sharedUsers={sharedUsers}
                setSharedUsers={setSharedUsers}
                title={chatName ?? ''}
            />
        </div>
    )
})

export default TalkDataQuestionHeader