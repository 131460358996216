import React from 'react';
import ReactEcharts from 'echarts-for-react';
import macarons from '../../../genAi/components/echartsThemeMacarons.json'
import { dateformater, pxToRem, remToPx } from '../../../common/helperFunctions';


const LogsGraph = ({ data }) => {
  // Parse JSON data
  const parsedData = data.map(item => ({
    date: new Date(item.start_date_time),
    exceptionCount: item.exception_count
  }));

  // Extract x and y values
  const xData = parsedData.map(item => dateformater(item?.date,'daydatetime'));
  const yData = parsedData.map(item => item?.exceptionCount);

  // Define chart options
  // const options = {
  //   xAxis: {
  //     type: 'category',
  //     data: xData
  //   },
  //   yAxis: {
  //     type: 'value'
  //   },
  //   series: [
  //     {
  //       data: yData,
  //       type: 'line'
  //     }
  //   ]
  // };

  const dynamic = px => remToPx(pxToRem(px))

  const options = {
    textStyle: {
      fontSize: dynamic(14)
    },
    grid: { top: 10, right: 10, bottom: 24, left: 50 },
    xAxis: {
      type: 'category',
      data:xData,
      name:'Time',
      nameLocation:'center',
      nameGap:"50",
      axisLabel: {
        fontSize: dynamic(14)
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        fontSize: dynamic(14)
      }
    },
    series: [
      {
        data: yData,
        type: 'line',
        smooth: true,
      },
    ],
    tooltip: {
      trigger: 'axis',
      textStyle: {
        fontSize: dynamic(14)
      }
    },
  };

  return <ReactEcharts option={options} style={{height:"100%", width: "100%",padding:"0px" ,margin:"0px"}}  theme={
    {
        ...macarons,
        color: [
            '#2078BA',
            '#C1DB36',
            '#542F99',
            '#E9B93C',
            '#7EE9F6',
            '#42BA72',
            '#114E7A',
            '#FFDB7E',
            '#CBE9F6',
            '#51545E'

        ]
    }}
    />;
};

export default LogsGraph;
