import { Avatar, Tooltip } from 'antd'
import React from 'react'
import { ICON_BAG, ICON_USER } from '../../../Common/newIconSource'
import { stringToColor } from '../../common/helperFunctions'

const demo = [
    {
        "id": 4,
        "email": "rutuja@clarista.io",
        "last_name": "Shah",
        "user_type": "STEWARD",
        "first_name": "Rutuja"
    },
    {
        "id": 3,
        "email": "aman.jain@atcs.com",
        "last_name": "Jain",
        "user_type": "STEWARD",
        "first_name": "Aman"
    },
    {
        "id": 2,
        "email": "bhagyashri@clarista.io",
        "last_name": "Shetage",
        "user_type": "STEWARD",
        "first_name": "Bhagyashri"
    },
    {
        "id": 1,
        "email": "prokel@clarista.io",
        "last_name": "Thaosen",
        "user_type": "STEWARD",
        "first_name": "Prokel"
    },
]

const colorList = ['#1A7AB1', '#DB4B1D', '#8AA90B', '#1A7AB1', '#1677ff']

const DataStewardsView = ({
    data = [],
    maxCount = 3,
    wrapperClass = '',
    avatarClass = ''
}) => {
    return (
        <div className='align-items-center d-flex'>
            {/* <div className='mr-2'>
                <label className='m-0 grey-color label'>Data {type === 'stewards' ? 'Stewards' : 'Owners'}</label>
            </div> */}
            <Avatar.Group
                maxCount={maxCount}
                className={`fontInterSemiBold ${wrapperClass}`}
                maxStyle={{
                    color: '#FFF',
                    backgroundColor: '#455A64',
                }}
                >
                {
                    data?.map((v, i) => (
                        <Tooltip title={`${v?.first_name} ${v?.last_name}`} placement="top">
                            <Avatar
                                className={`fontInterSemiBold text-uppercase ${avatarClass}`}
                                style={{
                                    backgroundColor: stringToColor(`${v?.first_name} ${v?.last_name}`) ?? '#1A7AB1',
                                    // backgroundColor: colorList[i] ?? '#1A7AB1',
                                }}
                            >
                                {v?.first_name?.charAt(0)}
                                {v?.last_name?.charAt(0)}
                            </Avatar>
                        </Tooltip>
                    ))
                }
            </Avatar.Group>
        </div>
    )
}

export default DataStewardsView