import React, { useEffect, useState } from 'react'
import Buttons from '../../../../../Common/button/Buttons'
import { Literals } from '../../../../common/literals'
import { INTERCHANGE, LEFTJOIN, PLUS, OUTERJOIN, INNERJOIN, RIGHTJOIN, MERGEKEYS } from '../../../../../Common/iconSource'
import MergeTypeSelection from './MergeTypeSelection'
import LeftJoinInfoGraphic from './infoGraphic/LeftJoinInfoGraphic'
import RightJoinInfoGraphics from './infoGraphic/RightJoinInfoGraphics'
import OuterJoinInfoGraphic from './infoGraphic/OuterJoinInfoGraphic'
import { updateFlowsElements } from '../../../../../../store/modules/flows/flowsActions'

import { connect, useDispatch } from 'react-redux'
import InnerJoinInfoGraphics from './infoGraphic/InnerJoinInfoGraphic'
const MergeKeys = ({ swap, setSwap, flowsElements, currentTransformer, selectedPOD, setSelectedPOD }) => {
    const MERGE_TYPE = [{
        id: 1,
        Img: () => <LEFTJOIN />,
        name: `Left Join`,
        value: 'LEFT OUTER JOIN',
        InfoGraphic: () => <LeftJoinInfoGraphic />
    },
    {
        id: 2,
        Img: () => <INNERJOIN />,
        name: `Inner Join`,
        value: 'INNER JOIN',
        InfoGraphic: () => <InnerJoinInfoGraphics />
    },
    {
        id: 3,
        Img: () => <RIGHTJOIN />,
        name: `Right Join`,
        value: 'RIGHT OUTER JOIN',
        InfoGraphic: () => <RightJoinInfoGraphics />
    },
    {
        id: 4,
        Img: () => <OUTERJOIN />,
        name: `Outer Join`,
        value: 'FULL OUTER JOIN',
        InfoGraphic: () => <OuterJoinInfoGraphic />
    }
    ]

    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)
    const [currentJoin, setCurrentJoin] = useState([{
        id: 2,
        Img: () => <INNERJOIN />,
        name: `Inner Join`,
        value: 'INNER JOIN',
        InfoGraphic: () => <InnerJoinInfoGraphics />
    }])
    const onClose = (data) => setOpenModal(data)

    const SelectedJoin = () => {
        const { id, Img, name, InfoGraphic } = currentJoin[0]
        return <Img />
    }

    useEffect(() => {
        let customContent = flowsElements.find(item => item.id === currentTransformer.id)?.content
        let selectedType = MERGE_TYPE.find((item) => item?.value?.toLocaleLowerCase() === customContent?.selectedJoin?.toLocaleLowerCase())
        if (selectedType && selectedType?.name) {

            setCurrentJoin([selectedType])
        } else {
            setCurrentJoin([MERGE_TYPE[1]])
        }

    }, [currentTransformer])


    const handleSelect = (data) => {
        setCurrentJoin(data)
        let customContent = flowsElements.find(item => item.id === currentTransformer.id).content

        customContent.selectedJoin = data[0].value

        let newflowsElements = flowsElements.map(
            item => {
                if (item.id === currentTransformer.id) {
                    item.content = customContent
                }
                return item
            }
        )
        dispatch(updateFlowsElements([...newflowsElements]))


    }

    const incrementPrimaryKeysCollection = () => {
        let tempPrimaryKeysCollection = flowsElements.find(item => item.id === currentTransformer.id)?.content?.primaryKeysCollection
        // let tempKey
        // if(tempPrimaryKeysCollection.length === 0)
        // {
        //     tempKey = 0
        // }
        // else
        // {
        //     tempKey = tempPrimaryKeysCollection.map( item => item.key).sort().pop()
        // }

        let tempSelectedPOD = JSON.parse(JSON.stringify(selectedPOD))
        tempSelectedPOD.key = new Date().getTime()
        let tempContent = flowsElements.find(item => item.id === currentTransformer.id).content
        tempContent.primaryKeysCollection.push(tempSelectedPOD)
        let newflowsElements = flowsElements.map(
            item => {
                if (item.id === currentTransformer.id) {
                    item.content = tempContent
                }
                return item
            }
        )
        dispatch(updateFlowsElements([...newflowsElements]))
    }

    const swapButton = () => {
        setSwap(!swap)
        let temp;
        let tempSelectedFirstSecond = flowsElements.find(item => item.id === currentTransformer.id)?.content?.selectedFirstSecond

        temp = tempSelectedFirstSecond.datasource0
        tempSelectedFirstSecond.datasource0 = tempSelectedFirstSecond.datasource1
        tempSelectedFirstSecond.datasource1 = temp

        let newflowsElements = flowsElements.map(
            item => {
                if (item.id === currentTransformer.id) {
                    item.content.selectedFirstSecond = tempSelectedFirstSecond
                }
                return item
            }
        )
        dispatch(updateFlowsElements([...newflowsElements]))
    }
    useEffect(() => {


        let customContent = flowsElements.find(item => item.id === currentTransformer.id).content

        if (!("selectedJoin" in customContent)) {
            customContent.selectedJoin = currentJoin[0].name

            let newflowsElements = flowsElements.map(
                item => {
                    if (item.id === currentTransformer.id) {
                        item.content = customContent
                    }
                    return item
                }
            )
            dispatch(updateFlowsElements([...newflowsElements]))
        }
    }, [flowsElements, currentTransformer])
    return (
        <>
            <div className="d-flex justify-content-between padding-2 custom-border-bottom">
                <div className="d-flex align-items-center">
                    <MERGEKEYS />
                    <p className="paragraph ml-2">Merge Keys</p>
                </div>

                <div className="d-flex align-items-center">
                    <Buttons props={{ buttonText: '', tooltip: "Swap Merge Keys", buttonClassName: "custom-btn custom-btn-outline btn-with-icon", buttonEvent: () => { swapButton() }, ImgSrc: () => <INTERCHANGE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                    <Buttons props={{ buttonText: '', tooltip: currentJoin[0].name, buttonClassName: "custom-btn ml-2 mr-2 custom-btn-outline btn-with-icon", buttonEvent: () => { setOpenModal(true) }, ImgSrc: () => { return SelectedJoin() }, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                    <Buttons props={{ buttonText: '', tooltip: "Add Primary key collection", buttonClassName: "custom-btn custom-btn-outline btn-with-icon", buttonEvent: () => { incrementPrimaryKeysCollection() }, ImgSrc: () => <PLUS />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT }} />
                </div>
            </div>
            <MergeTypeSelection openModal={openModal} onClose={onClose} handleSelect={handleSelect} MERGE_TYPE={MERGE_TYPE} currentSelected={currentJoin[0]} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer
    };
};
export default React.memo(connect(mapStateToProps)(MergeKeys))