import React, { useState, useEffect, Fragment } from 'react';
import { Literals } from '../../../common/literals';
import classnames from "classnames";
import AddUserGroupModel from './AddUserGroupModel';
import { getUserGroupList } from '../../../../../store/modules/UserManagement/userGroup/UMActions'
import { useStore, useSelector, useDispatch } from "react-redux";
import { getEntitlementList } from '../../../../../store/modules/entitlements/entitlementAction';
import { fetchDataDomainDetails } from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions';
import { MINUS, PLUS, SMALLGROUP, WHITEFILTER, WHITEPLUS } from '../../../../Common/iconSource';
import Buttons from '../../../../Common/button/Buttons';
import CreateUserGroupMsg from '../../common/CreateUserGroupMsg';
import { assignEntitlement } from '../../../../../store/modules/entitlements/entitlementAction';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { RolePrivilegeModal } from '../../../common/helperFunctions';
const CreateDataDomainRight = ({ id, groupDetails, isSaveDisable }) => {
    const store = useStore();
    const [userGroupList, setUserGroupList] = useState([]);
    const [entitlementId, setEntitlementId] = useState()
    const [editor, setEditor] = useState(false)
    const [openModal, setOpenModal] = useState(false);

    const [userGroups, setUserGroups] = useState([]);
    const [entitlements, setEntitlements] = useState([]);
    const [openUserGroupModal, setOpenUserGroupModal] = useState(false);
    const [selectedUGId, setSelectedUGId] = useState(null);
    const [selectedUGType, setSelectedUGType] = useState("member")
    const dataDomainId = useSelector(state => state.UserManagement.DataDomain.dataDomainId);
    const dispatch = useDispatch();
    useEffect(() => {
        getUserGroupListData();
        getEntitlementsListData();
    }, []);

    useEffect(() => {
        setUserGroupList([...groupDetails]);
    }, [groupDetails])

    const deleteRow = (userGroup) => {
        let tempGroup = [...userGroupList]
        let index = userGroupList.findIndex((ele) => {
            return ele.id === userGroup.id;
        })
        if (index > -1) {
            tempGroup.splice(index, 1);
            setUserGroupList([...tempGroup]);
            let groups = [...userGroups]
            groups.push({ id: userGroup.id, name: userGroup.name });
            setUserGroups([...groups]);
        }
    };

    const addUserGroupToList = (groupId, userType, isEditor) => {
        let tempGroup = [...userGroupList]
        let index = userGroupList.findIndex((ele) => {
            return ele.id === groupId;
        })
        let selectedEntitltment = entitlements.find((ele) => {
            return ele.id === entitlementId;
        })
        if (index > -1) {
            tempGroup[index] = { ...tempGroup[index], permission: [userType] }
            setUserGroupList([...tempGroup])
        } else {
            let newUserGroup;
            let groups = userGroups
            let i = userGroups.findIndex((ele) => {
                return ele.id === groupId
            })
            if (i > -1) {
                newUserGroup = {
                    name: userGroups[i].name,
                    id: groupId,
                    permission: [userType],
                    description: userGroups[i].description,
                    editor: isEditor,
                    data_domain: userGroups[i].data_domain
                }
                tempGroup.push(newUserGroup);
                setUserGroupList(tempGroup);
                groups.splice(i, 1);
                setUserGroups([...groups]);
            }
        }
    };

    const getEntitlementsListData = () => {
        // dispatch(getEntitlementList()).then(res=>{

        //     setEntitlements(res.data);
        // })

    }
    const getUserGroupListData = async () => {
        let response;
        try {
            response = await store.dispatch(getUserGroupList(
                { method: "GET", endPoint: "group_manager/group/" }));
            if (response.status === "success") {
                setUserGroups(response.data)
            }
        } catch (error) { }
    }
    const addGroupToDomain = async (groupId, userType, isEditor, isUpdate) => {
        let response
        let type;
        if (userType === 'owner') type = 'add_owner_group';
        if (userType === 'member') type = 'add_member_group';
        let payload = {
            ids: [groupId]
        }
        try {
            // response = await store.dispatch(fetchDataDomainDetails(
            //     { method: "POST", endPoint: `data_domain/${dataDomainId}/${type}`, payload }
            // ));
            response = await assignEntitlementToUserGroup(groupId, userType, isEditor, isUpdate);

            if (response.status === "success") {

                addUserGroupToList(groupId, userType, isEditor);
                setOpenUserGroupModal(false);
                isSaveDisable(true)
            }
        } catch (error) { }
    }
    const addUserGroup = () => {
        setOpenUserGroupModal(true);
    }
    const assignEntitlementToUserGroup = (groupId, role, isEditor, isUpdate) => {

        return new Promise((resolve, reject) => {
            let payload = {


                "editor": isEditor,
                "group": groupId,
                "data_domain": dataDomainId,
                "view_permission": [],
                "owner": role === 'owner'

            }
            try {
                dispatch(assignEntitlement(payload, isUpdate)).then((res) => {

                    resolve(res);
                    //     getDataDomainDetails();
                    // setOpenUserGroupModal(false);
                })
            }
            catch (err) {
                reject(err);
            }
        })

    }

    const removeGroupFromDomain = async (userGroup) => {
        let response;
        let payload = {
            group: userGroup.id,
            data_domain: dataDomainId
        }
        try {
            response = await store.dispatch(fetchDataDomainDetails(
                { method: "POST", endPoint: `entitlement/remove_onboard`, payload }
            ));
            if (response.status === "success") {
                deleteRow(userGroup)
            }
        } catch (error) { }
    }

    const getEntitlementId = (searchText) => {
        let selectedEntitlement = entitlements.find(el => el.name == searchText)
        return selectedEntitlement?.id ?? null;
    }
    function renderUserGroupTable() {
        return (
            <Fragment>
                {userGroupList.map((userGroup, index) => (
                    <div key={index} className='row mb-3'>
                        <div className='col-3'>
                        <p className='text-with-ellipsis'>  {userGroup.name}</p>
                        </div>
                        <div className='col-3 '>
                           <p className='text-with-ellipsis'> {userGroup.description}</p>
                        </div>
                        <div className='col-3'>
                            <div className="select-grp-types">
                                <p className={classnames({ 'owner-style': userGroup.permission[0] === 'owner' })} onClick={(e) => addGroupToDomain(userGroup.id, 'owner', userGroup.editor, true)}><WHITEFILTER />Owner</p>
                                <p className={classnames({ 'user-style': userGroup.permission[0] === 'member' })} onClick={(e) => addGroupToDomain(userGroup.id, 'member', userGroup.editor, true)}><WHITEFILTER />Member</p>
                            </div>

                        </div>
                        <div className='col-2'>
                            {userGroup.editor ? 'Editor' : 'Viewer'}
                        </div>
                        <div className='col-1 text-right'>
                            <span className='float-right'>
                                <Buttons props={{
                                    buttonText: '', buttonClassName: "custom-btn-outline ml-1 mr-1 custom-btn btn-with-icon error-red red-border", buttonEvent: (e) => {
                                        removeGroupFromDomain(userGroup)
                                    }, tooltip: 'Delete', ImgSrc: () => <MINUS />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                                }} />
                            </span>
                        </div>
                    </div>
                ))}
            </Fragment>
        )
    }

    const setRole = (editor) => {
        setEditor(editor)
    }

    return (
        <div>
            <div className='Create-Domain-right'>
                <div className="sub-header lower-subheader px-0">
                    <div className="sub-header-left">
                        <div className="heading-content">
                            <span className="domain-icon"><SMALLGROUP /></span>
                            <p>{Literals.ADD_USERGROUPS}</p>
                        </div>
                    </div>
                    <div className="sub-header-right">
                        <ul className="landingPage_navbar-nav-right">
                            <li className="landingPage_nav-item">
                                <Buttons props={{
                                    tooltip: 'Add',
                                    buttonText: Literals.ADD, buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                    // buttonText: Literals.ADD, buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon", 
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    buttonEvent: () => {
                                        addUserGroup()
                                    }, ImgSrc: () => <PLUS />, isDisable: false,
                                }} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-3'>
                    <label>{Literals.USERGROUPNAME}</label>
                </div>
                <div className='col-3'>
                    <label >{Literals.DESCRIPTION }</label>
                </div>
                <div className='col-3'>
                    <div style={{ display: "flex", alignItems: "center" }}>{Literals.TYPE} <InfoCircleFill style={{ color: "#2C83C4",cursor:"pointer" }}
                        onClick={() => {
                            setOpenModal(true);
                        }}
                        className="ml-2" /></div>
                </div>
                <div className='col-2'>
                    <label>{Literals.ROLES}</label>
                </div>
                <div className='col-1 text-right'>
                    <label>{Literals.ACTIONS}</label>
                </div>
            </div>
            {userGroupList.length > 0 ? renderUserGroupTable() : <CreateUserGroupMsg forPage={"createUserGroup"} />}
            <AddUserGroupModel
                openUserGroupModal={openUserGroupModal}
                setOpenUserGroupModal={setOpenUserGroupModal}
                userGroups={userGroups}
                entitlements={entitlements}
                entitlementId={entitlementId}
                setEntitlementId={setEntitlementId}
                selectedUGType={selectedUGType}
                setSelectedUGType={setSelectedUGType}
                selectedUGId={selectedUGId}
                setSelectedUGId={setSelectedUGId}
                addGroupToDomain={addGroupToDomain}
                dataDomainId={dataDomainId}
                setRole={setRole}
                isEditor={editor}
            />
            <RolePrivilegeModal openModal={openModal} setOpenModal={setOpenModal} />

        </div>

    )
}

export default CreateDataDomainRight;