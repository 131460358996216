import React, { useEffect, useState } from 'react'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { getPreviewTable, setChatGpt, setPreviewTableQuery, setSearchMode } from '../../../../store/modules/dataCatalogue/preview/previewActions';
import { convertBase64ToString, generateHtmlTitle, getObjectLength, getReloadErrorTemplate } from '../../common/helperFunctions';
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader';
import DataGridTable from '../../../Common/dataGridTable/DataGridTable';
import useHideSidebar from '../../../../customHooks/useHideSidebar';
import { ICON_CLARISTA, ICON_CLOSE, ICON_REPORT } from '../../../Common/newIconSource';
import { Literals } from '../../common/literals';
import Buttons from '../../../Common/button/Buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { getReportDetails } from '../../../../store/modules/common/GenAi/GenAiReducer';
import { CHATGPT_ICON, REPORT_ICON } from '../../../Common/iconSource';
import { Tooltip } from 'antd';
import "../../dataCatalogue/preview/preview.scss"
import PreviewSearchBody from '../../dataCatalogue/preview/components/previewSearch/PreviewSearchBody';
import { dictionaryEndpoint } from '../../../../store/actions';
import { resetAllDataGridTableFilters, setActiveTabKey, setPreviewFlag, setResponseQuery } from '../../../../store/modules/common/DataGridTable/dataGridTableRedux';

const ReportsDetailView = () => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const { reportId } = useParams()

    const [previewError, setPreviewError] = React.useState(undefined)
    const [previewLoading, setPreviewLoading] = React.useState(false)
    const [uniqUUID, setUniqUUID] = React.useState('')
    const [previewData, setPreviewData] = React.useState({});
    const [responseData, setresponseData] = React.useState({});
    const [showChatGptMode, setshowChatGptMode] = React.useState(false);
    const [sqlTableDetails, setsqlTableDetails] = useState([]);
    const [storePreviewData, setstorePreviewData] = useState({});
    const [storedGPTSQL, setStoredGPTSQL] = useState(undefined);

    const previewTableResponse = useSelector(
        (state) => state.DataCatalogue?.PreviewPage?.previewPageData
    );

    const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

    // useEffect(() => {

    //     if(getObjectLength(previewTableResponse) > 0){
    //         setstorePreviewData(previewTableResponse)
    //     }

    // }, [previewTableResponse])

    useHideSidebar(true)

    React.useEffect(() => {
        // dispatch(resetAllDataGridTableFilters())
    }, [])

    React.useEffect(() => {
        if (reportId) {
            dispatch(getReportDetails(reportId)).then((res) => {
                if (res?.data?.table) {
                    setresponseData(res?.data)
                    fetchPreview(res.data)

                    let title = generateHtmlTitle(res?.data?.query_name?.toUpperCase() + ' | Report', 'Pulse')
                    document.title = title
                }
            })
            .catch(() => {
                let title = generateHtmlTitle('Report', 'Pulse')
                document.title = title
            })
        }

    }, [reportId])


    const fetchPreview = (tableDetails) => {



        if (storePreviewData && getObjectLength(storePreviewData) > 0) {
            // setPreviewData(storePreviewData)
        } else {
            setPreviewData({})

            setPreviewLoading(true)
            setPreviewError(undefined)
            let uuid = uuidv4()
            setUniqUUID(uuid)

            let request = {
                uuid: uuid,
                table_name: tableDetails?.table_name,
                page: 0,
                do_count: false,
                sort_body: [],
                filter_body: [],
                table_ids: [tableDetails?.table],
                query: convertBase64ToString(tableDetails?.query_string)
            }

            dispatch(getPreviewTable(request, false))
                .then((res) => {
                    setPreviewData(res?.data?.result ?? {})
                    setPreviewLoading(false);
                    setstorePreviewData(res?.data?.result ?? {})
                    // dispatch(
                    //     setPreviewTableQuery(
                    //         res.data.query
                    //     )
                    // );
                    // dispatch(setActiveTabKey('Result'))
                    // localStorage.setItem("previewFlag", "Query");
                    // dispatch(setPreviewFlag("Query"))

                    // dispatch(setResponseQuery(res.data.query))

                    let previewTableResult = res?.data?.result;
                    let columns = [];

                    for (
                        let index = 0;
                        index < previewTableResult?.data_header?.length;
                        index++
                    ) {
                        if (
                            previewTableResult?.data_dtype &&
                            previewTableResult?.data_dtype[index]
                        ) {
                            columns.push({
                                data_type: previewTableResult?.data_dtype[index],
                                technical_name: previewTableResult?.data_header[index],
                                business_name: previewTableResult?.data_header[index],
                            });
                        } else {
                            columns.push({
                                data_type: "",
                                technical_name: previewTableResult?.data_header[index],
                                business_name: previewTableResult?.data_header[index],
                            });
                        }
                    }

                    if (columns.length) {
                        if (sqlTableDetails?.length === 0)
                            generateSqlTableInfo(tableDetails,columns);
                    }
                })
                .catch((err) => {
                    setPreviewError(err?.message ?? 'Error!')
                    setPreviewLoading(false)
                })
        }

    }

    const generateSqlTableInfo = (tableDetails,columns) => {
     
        if (tableDetails.table_name && columns?.length) {
            let sqlTables = [];
            columns.forEach((e) => {
                sqlTables.push({
                    name: `"${e.technical_name}"`,
                    description: "",
                    type: "Column",
                    tableName: tableDetails.table_name,
                });
            });
            sqlTables.push({
                name: `${tableDetails?.data_domain_name}.${tableDetails?.table_name}`,
                description: "",
                type: "Table",
                tableName: "",
            });
            if (sqlTables?.length) {


                setsqlTableDetails([...sqlTables]);
            }
        }
    };


    return (
        <div>
            <LandingpageFirstHeader title={'Report'} />
            <div className='padding-2 preview-page-view m-0' style={{ height: 'calc(100vh - 36px)' }}>
                <div className='fontSizeHeading d-flex justify-content-between align-items-center text-uppercase mb-2'>
                    <div className='align-items-center d-flex'>
                        <REPORT_ICON width='20' height='20'color='black' />
                        <label className='ml-2 mb-0 fontSizeHeading fontInterSemiBold'>{responseData?.table_name}</label>
                    </div>

                    {!showChatGptMode ? <div className='d-flex align-items-center'>
                        {/* {
                            noOpenAiAccess ? ''
                            :
                            <Buttons
                                props={{
                                    buttonText: '',
                                    tooltip: 'Ask Clarista',
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                                    tooltipPlacement: 'top',
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setshowChatGptMode(true);
                                        dispatch(setSearchMode(true));
                                        dispatch(setChatGpt(true));
                                        dispatch(dictionaryEndpoint(responseData?.table, false, false))
                                    },
                                    ImgSrc: () => <ICON_CLARISTA />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_TERTIARY
                                }}
                            />
                        } */}
                        <Buttons
                            props={{
                                tooltipPlacement: 'left',
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    history(Literals.links.PULSE_LANDING + '#reports')
                                },
                                tooltip: "",
                                ImgSrc: () => <ICON_CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </div> : null}
                </div>

                <div
                    className={`preview-search ${showChatGptMode ? "active" : ""
                        }`}
                >
                    {
                        showChatGptMode
                        ?
                        <PreviewSearchBody isSearchMode={showChatGptMode} sqlTableDetails={sqlTableDetails} props={{
                                setshowChatGptMode: setshowChatGptMode, fetchPreview: fetchPreview,
                                responseData: responseData, from: 'reports', reportId: Number(reportId),
                                parentQuery: responseData?.query_string,
                                GPTSQLQuery: storedGPTSQL
                            }} 
                            setStoredGPTSQL={setStoredGPTSQL}
                        />
                        :   ''
                    }
                </div>
                <div
                    className={`preview-loader preview-main-content ${showChatGptMode ? "active" : ""
                        }`}
                >
                    {
                        previewLoading
                            ? <ClaristaLoader height='100vh' isCancel={true} />
                            : previewError
                                ? getReloadErrorTemplate({
                                    errorMessage: previewError,
                                    onReload: () => fetchPreview(responseData)
                                })
                                :
                                getObjectLength(previewData) > 0 ? <div className='rep-det-tbl custom-virtual-data-table px-0'>
                                    <DataGridTable
                                        resp={previewData}
                                        showDictionaryDetails={false}
                                        stopColumnReorder={true}
                                        uniqUUID={uniqUUID}
                                        parentQuery={convertBase64ToString(responseData?.query_string)}
                                        setUniqUUID={setUniqUUID}
                                        isCustomSql={true}
                                        CurrentDomainName={() => responseData?.data_domain_name}
                                        CurrentPODName={() => responseData?.table_name}
                                        requestedTableId={responseData?.table}
                                    />
                                </div> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default ReportsDetailView