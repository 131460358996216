import React, { Component } from 'react';
import { WithRouterHOC } from '../services/WithRouterHOC';
class NonAuthLayout extends Component {
    constructor(props) {
        super(props);
     
        this.state = {};
        this.capitalizeFirstLetter.bind(this);
    }

    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    componentDidMount() {
        let currentage = this.capitalizeFirstLetter(this.props.router.location.pathname);
        

        document.title =
            currentage + " | Clarista";
    }
    render() {
        return <React.Fragment>
           
            {this.props.children}
        </React.Fragment>;
    }
}

export default (WithRouterHOC(NonAuthLayout));