import { Dropdown, Tooltip } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Handle, getConnectedEdges, getIncomers } from 'reactflow';
import { connect, useDispatch, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteTransformer, showFlowsTransformdetails } from '../../../../../store/modules/flows/NewFlowsRedux/flowsActions';
import { CHECKMARKCIRCLE, DATASOURCE, DELETETRANSFORMER, DISMISSCIRCLE, ERRORCIRCLE, LOADINGCIRCLE, LOADINGCIRCLEQUEUE, STOPOCTAGON } from '../../../../Common/iconSource';
import { addSelectedTransformerClass } from '../../../common/helperFunctions';
import { ExecuteTransformer, checkIsEditorFlow, getTransformerName, isTransformerNamePresent, transformerMenuItem } from '../../transformers/commonFunctions/FlowsHelperFunction';

import ConfigureTransformerModal from '../../transformers/commonFunctions/ConfigureTransformerModal';

const Datasource = memo((props) => {
    let { currentTransformer, executeEntireFlow, transformerProgress, id, flowsElements } = props
    const { flowId } = useParams();
    const [openConfigureModal, setopenConfigureModal] = useState(false)
    const [openDropdownMenu, setopenDropdownMenu] = useState(false)

    let clickCount = useRef(0)
    const [newActiveKey, setnewActiveKey] = useState('')

    let store = useStore()
    let dispatch = useDispatch()


    const getStatus = () => {


        if (transformerProgress && transformerProgress.length) {
            let currentStatus = ''
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;

            if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'starting' || currentStatus?.toLowerCase() === 'running')) {

                return <span className='bx-spin d-flex'><LOADINGCIRCLE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'success' || currentStatus?.toLowerCase() === 'done' || currentStatus?.toLowerCase() === 'finished')) {

                return <CHECKMARKCIRCLE />
            }
            else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error')) {


                return <DISMISSCIRCLE />
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'queued')) {

                return <span className='bx-spin d-flex'><LOADINGCIRCLEQUEUE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'aborted')) {


                return <span className='d-flex'> <STOPOCTAGON /></span>
            }
            else {


                return <ERRORCIRCLE />
            }

        }
        else if (executeEntireFlow && !transformerProgress.length) {
            return <ERRORCIRCLE />

        } else if (!transformerProgress.length && !executeEntireFlow) {
            return <ERRORCIRCLE />
        }




    }

    const getErrorMsg = () => {
        let error_msg = transformerProgress.find(element => {
            return element.crnt_transformer === id
        })?.error_msg;
        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        return (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error' || currentStatus?.toLowerCase() === 'aborted') ? error_msg : null

    }
    const getInitialStatus = () => {

        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        if (currentStatus) {
            return currentStatus?.toLowerCase();
        } else {

            return 'initial'
        }
    }

    // useEffect(() => {
    //     if (currentTransformer?.id) {

    //         addSelectedTransformerClass(currentTransformer?.id, currentTransformer?.type)
    //     }
    // }, [flowsElements, currentTransformer])

    const onDeleteClick = () => {
        store.dispatch(deleteTransformer(id, flowId))
    }
    const onRunClick = () => {
        store.dispatch(ExecuteTransformer('menuClick'))
    }


    useEffect(() => {
        if (!openConfigureModal) {
            setnewActiveKey('')

        } else {
            dispatch(showFlowsTransformdetails(true));

        }
    }, [openConfigureModal]);

    const showDatasourceTransformerName = () => {
        let name = dispatch(getTransformerName(id));
        if (name?.length && name.split('.')[1]) {
            return name.split('.')[1]
        } else {
            return name;
        }
    }


    return (

        <>
            {openConfigureModal ? <ConfigureTransformerModal setOpenModal={setopenConfigureModal} newActiveKey={newActiveKey} openModal={openConfigureModal} /> : null}
            <Dropdown
                destroyPopupOnHide={true}
                menu={{
                    items: dispatch(transformerMenuItem(onRunClick, setopenConfigureModal, setnewActiveKey, onDeleteClick, store.dispatch(checkIsEditorFlow()))),
                    onClick: (e) => e.domEvent.stopPropagation()
                }}
                open={openDropdownMenu}
                // trigger={["click"]}
                onOpenChange={() => setopenDropdownMenu(false)}
                placement="bottomRight"

                overlayStyle={{ border: 'solid 1px #e4e4e4' }}
            >

                <div className='transformer-transperent-div'>
                    <div className={`transformer-box data-box ${currentTransformer && currentTransformer.id === id ? `transformer-active` : ``}`}>
                        <Handle type="source" color='red' position="right"
                        />

                        <div>
                            <div className='transformer-icon'
                                onClick={() => {
                                    setopenDropdownMenu(false)
                                    setopenConfigureModal(true);
                                }
                                }
                                onDoubleClickCapture={() => {
                                    setopenDropdownMenu(false)
                                    setopenConfigureModal(true);
                                }
                                }
                               
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    setopenDropdownMenu(true);
                                }}
                            >
                                <DATASOURCE color='#692AA4' />
                            </div>



                            {getErrorMsg() ? <div className="transformer-bottom-img" >
                                <Tooltip placement='bottomLeft' title={getErrorMsg()}>  {getStatus()}</Tooltip>
                            </div> : getInitialStatus() === 'initial' ? <Tooltip title={'Transformer not executed yet'}><div className="transformer-bottom-img" >
                                {getStatus()}
                            </div></Tooltip> : <div className="transformer-bottom-img" >
                                {getStatus()}
                            </div>}

                            <Tooltip title={dispatch(getTransformerName(id))}>
                                <div style={{color:'#692AA4'}} className={dispatch(isTransformerNamePresent(id)) ? "transformer-bottom-name" : "error-transformer-bottom-name"} >

                                    {
                                        showDatasourceTransformerName()
                                    }

                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>

            </Dropdown >

        </>


    )
})
const mapStateToProps = (state) => {
    return {
        flowsElements: state.Lab.flowsElements,
        currentTransformer: state.Lab.currentTransformer,
        executeEntireFlow: state.Lab.executeEntireFlowStart,
        transformerProgress: state.Lab.transformerProgress,
    };
};
export default connect(mapStateToProps)(Datasource)

