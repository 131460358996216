import React from 'react';

const NoDataRecordFound = () => {
    return (
        <div className="no-data-div">
            <p>No Data Record Found</p>
        </div>
    )
}

export default NoDataRecordFound;