import React, { useEffect, useRef, useState } from "react";
import PreviewSearchBox from "./PreviewSearchBox";
import PreviewDropdown from "./PreviewDropdown";
import PreviewSteps from "./PreviewSteps";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessTermsOfPod } from "../../../../../../store/modules/dataDictionary/action";
import {
  GetSearchResult,
  setPreviewTableQuery,
} from "../../../../../../store/modules/dataCatalogue/preview/previewActions";
import { v4 as uuidv4 } from "uuid";

import { ErrorComponent } from "../../../../common/helperFunctions";
import { emitToastNotification } from "../../../../../../helpers/toast_helper";
import { useParams } from "react-router-dom";


var summaryHeight = "200px";
const Loading_Messages = [
  "<span>Your request is being processed, please wait</span><div class='dot-container'><div class='dot-typing'></div></div>",
  "<span>Almost there, just a few more seconds</span><div  class='dot-container'><div class='dot-typing'></div></div>",
];
const PreviewSearchBody = ({ 
  sqlTableDetails, 
  isSearchMode, 
  props,
  setStoredGPTSQL = () => {}
}) => {
  const [search, setSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [summaryLoaded, setSummaryLoaded] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isGridLoaded, setIsGridLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataComing, setIsDataComing] = useState(false);
  const [expandDropdown, setExpandDropdown] = useState(false);

  const [isError, setIsError] = useState(false);
  const [Query, setQuery] = useState();
  const previewRef = useRef();
  const currentUID = useRef();

  const { podId } = useParams()

  const searchMode = useSelector(
    (state) => state.DataCatalogue.PreviewPage.searchMode
  );
  const isChatgpt = useSelector(
    (state) => state.DataCatalogue.PreviewPage.isChatgpt
  );
  let timer;
  let searchTimer;

  useEffect(() => {

    return () => {
      resetData()
    }
  }, [])

  const resetData = () => {
    if (document.getElementById("timeline-summary")) {
      document.getElementById("timeline-summary").innerText = "";
    }
    summaryHeight = "200px";
    previewRef?.current?.onChangeTabParent();

    dispatch(setPreviewTableQuery(""));
    setIsDataComing(false);
    setCurrentStep(0);
    setIsError(false);
    setSummaryLoaded(false);
    setSearch(false);
    setIsGridLoaded(false);
    setQuery("");
    setExpandDropdown(false);

  };
  const onSearchHandle = (text) => {
    let query = text || searchText;
    if (query.length) {
      setIsLoading(true);
      resetData();

      if (timer) {
        clearTimeout(timer);
      }
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
      searchTimer = setTimeout(() => {
        let payload = {
          pod_id: podDetails?.table_id,
          generated_prompt: "",
          question: query,
          mode: isChatgpt ? "chatgpt-ask" : "ask"

        };
        if (props && props?.from === 'reports' && props?.reportId) {
          payload['report_id'] = props?.reportId
          delete payload['pod_id']
        }
        let tempUid = uuidv4();
        currentUID.current = tempUid
        getSearchResult(payload, 0, false, tempUid);

        setSearch(true);
        setIsLoading(false);
      }, 1000);
    }
    else {
      emitToastNotification('info', "Please enter query")
    }
  };
  const dispatch = useDispatch();

  const podDetails = useSelector(
    (state) => state.DataCatalogue.Dictionary.dictionaryPodData
  );
  const [schemaDetails, setSchemaDetails] = useState([]);
  React.useEffect(() => {

    if (podDetails?.table_id) {
      dispatch(getBusinessTermsOfPod(podDetails?.table_id ?? podId, false))
        .then((res) => {
          let data = res.data;

          let leastData = [];

          if (props && props?.from === 'reports' && sqlTableDetails?.length) {
            leastData = sqlTableDetails?.map((v) => {
              if (v?.type === 'Column') {
                return {
                  business_term: v.name.replace(/['"]+/g, ''),
                  technical_term:
                    v.name.replace(/['"]+/g, ''),
                  category: '',
                  classifications: '',
                }
              }
            }).filter((item) => item);

            setSchemaDetails([...leastData]);

          } else {
            leastData = data?.map((v) => ({
              business_term: v.business_term,
              technical_term:
                podDetails?.column_detail?.find(
                  (itm) =>
                    itm?.column_name?.toLowerCase() ===
                    v?.business_term?.toLowerCase()
                )?.column_source_name ?? "",
              category: v?.category_detail?.name ?? '',
              classifications: v.classification_detail?.map((v) => v.name),
            }));
            setSchemaDetails([...leastData]);

          }

          // }



        })
        .catch(() => { });
    }

  }, [podDetails]);
  
  useEffect(() => {
    if(isSearchMode) {
      setIsError(false)
    }
  }, [isSearchMode])

  useEffect(() => {
    if (summaryLoaded && isGridLoaded) {
      resetData();

      setSearchText("")
    }
  }, [isChatgpt])


  function checkOverflow(el) {
    var curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === "visible") el.style.overflow = "hidden";

    var isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
  }

  const animateText = (text) => {
    return new Promise((res, rej) => {
      // const charsTemp = text.split("\n");
      // let chars = [];
      // for (let i = 0; i < charsTemp.length; i++) {
      //   for (let j = 0; j < charsTemp[i].length; j++) {
      //     chars.push(charsTemp[i][j]);
      //   }
      //   if (i !== charsTemp.length - 1) {
      //     chars.push("\n");
      //   }
      // }
      let elem = document.getElementById("timeline-summary");
      // var typewriter = new Typewriter(elem, {
      //   loop: false,
      //   delay: 75,
      //   onanimationend: () => res()
      // });

      // typewriter.typeString(text)
      //   .start();
      // let index = 0;
      elem.textContent=text;
      res()
      // const typing = () => {

      //   let el = document.querySelector(".preview-card");
      //   let isOverflowing = checkOverflow(el);
      //   if (isOverflowing) {
      //     var height = el.offsetHeight;
      //     var newHeight = height + 20;
      //     summaryHeight = newHeight + "px";
      //     el.style.height = summaryHeight;
      //   }

      //   if (index < chars.length) {
      //     elem.textContent += chars[index];
      //     index++;
      //     timer = setTimeout(typing, 25);
      //   } else {
      //     res();
      //   }
      // };

      // typing();
    });
  };
  const getSearchResult = (payload, count, tempIsDataComing, uuid) => {
    dispatch(GetSearchResult(payload))
      .then(async (res) => {
        if (currentUID.current !== uuid) {
          return;
        }
        if (res.data.response_end == undefined) {
          setIsError(true);
          return;
        }


        let incomingWord = res.data.explanation_partial;
        incomingWord = incomingWord == "\n" ? "" : incomingWord;
        if (incomingWord.length) {
          if (!tempIsDataComing) {
            // isDataComing = true;
            tempIsDataComing = true;
            setIsDataComing(true);
            document.getElementById("timeline-summary").innerText = "";
          }
          await animateText(incomingWord);

        } else {
          if (res.data.response_end == false) {
            document.getElementById("timeline-summary").innerHTML =
              count > 5 ? Loading_Messages[1] : Loading_Messages[0];
          }

        }
        if (res.data.query.trim().length) {

          dispatch(setPreviewTableQuery(res.data.query));
          setQuery(res.data.query);

          if (props && props?.from === 'reports') {
            props['GPTSQLQuery'] = res.data.query;
            setStoredGPTSQL(res?.data?.query)
          }

        }
        else {
          if (props && props?.from === 'reports') {
            setStoredGPTSQL(undefined)
          }
        }
        if (res.data.response_end == false) {
          let payload = {
            pod_id: podDetails?.table_id,
            generated_prompt: res.data.generated_prompt,
            question: searchText,
            mode: isChatgpt ? "chatgpt-ask" : "ask"
          };
          if (props && props?.from === 'reports' && props?.reportId) {

            payload['report_id'] = props?.reportId
            delete payload['pod_id']
          }
          getSearchResult(payload, count + 1, tempIsDataComing, uuid);
        } else {

          if (currentUID.current !== uuid)
            return;
          setSummaryLoaded(true);
          setCurrentStep(2);
        }
      })
      .catch((err) => {
        setIsError(true);
        return;
        // getSearchResult(payload, count, tempIsDataComing);
      });
  };

  return (
    <div>
      <div className="preview-sch-box">
        <PreviewDropdown schemaDetails={schemaDetails} expandDropdown={expandDropdown} setExpandDropdown={setExpandDropdown} />

        <div></div>
        <PreviewSearchBox
          summaryLoaded={summaryLoaded}
          searchText={searchText}
          resetData={resetData}
          setSearchText={setSearchText}
          onSearchHandle={onSearchHandle}
          isSearching={search}
          props={props}
        />
      </div>
      {isError ? <ErrorComponent msg={"Please try again"} /> : ""}
      {isLoading && !isError ? (
        <p className="load-text">Please wait fetching data ...</p>
      ) : (
        ""
      )}

      {/* {search && !isError ? ( */}
      {searchMode ? <PreviewSteps
        sqlTableDetails={sqlTableDetails}
        schemaDetails={schemaDetails}
        summaryHeight={summaryHeight}
        currentStep={currentStep}
        setExpandDropdown={setExpandDropdown}
        summaryLoaded={summaryLoaded}
        setIsGridLoaded={setIsGridLoaded}
        Query={Query}
        search={search}
        isError={isError}
        isDataComing={isDataComing}
        setQuery={setQuery}
        isGridLoaded={isGridLoaded}
        ref={previewRef}
        props={props}
        podDetails={podDetails}
      /> : ""}
      {/* ) : (
        ""
      )} */}
    </div>
  );
};

export default PreviewSearchBody;
