import { setLoadingStatus } from "../common/loadingActions";
import { fetchData } from "../../../services/apiService";
import React from 'react';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { emitToastNotification } from '../../../helpers/toast_helper';
const BASE_URL = window._env_.REACT_APP_API_BASE_URL;



export const setEditMode=(val)=>{

    return {
        type:"SET_EDIT_MODE",
        payload:val
    }
}
export const setSaveClicked=(val)=>{

    return {
        type:"SET_SAVE_CLICKED",
        payload:val
    }
}
export const resetEntitlement=()=>{
    return {
        type:"RESET_ENTITLEMENT"
    }
}


export const removeEntitlementGroup=(data)=>{
    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}entitlement/remove/`,data)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
}
export const assignEntitlement=(data,isUpdate = false)=>{

    // alert(isUpdate)
    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}entitlement/assign_onboard/?update=${isUpdate}`,data)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        emitToastNotification('success', resp.message)
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
}
export const deleteEntitlement=(id)=>{
    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("DELETE", `${BASE_URL}entitlement/${id}/`, {
                   
                })
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };

}
export const updateEntitlement=(id,data)=>{
    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("PUT", `${BASE_URL}entitlement/${id}/`,data)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
}


export const createEntitlement=(data)=>{
    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}entitlement/`,data)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
}

export const getEntitlementDetails=(id)=>{
    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}entitlement/${id}/`, {
                   
                })
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };

}
export const getEntitlementList=()=>{
    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}entitlement/`, {
                   
                })
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };

}
export const getPermissionData = (contentName) => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}entitlement/get_permission/?content_type_name=${contentName}`, {
                   
                })
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};

export const getEntitlementOnBoardList = () => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}entitlement/onboard/`, {})
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
}

export const updateUsersOnboard = (payload) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}entitlement/onboard/`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        emitToastNotification('success', resp.message)
                        resolve(resp);
                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
}