import { Timeline ,Tooltip} from 'antd'
import React, { useEffect,useRef,useState } from 'react'

import { useParams } from 'react-router-dom'
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'
import {  CHANGELOGEDIT,CLOSE, EDIT, EDITOR, PLUS, PODICON, TIMELINE,REFRESH,PROPERTIES,LOGS,REFRESHLOGS, VERSION_ICON, USER, CLOCK } from '../iconSource';
import { NoDataComponent } from '../../modules/common/helperFunctions';
import { getAuditData ,getVersionData} from '../../../store/app/appAction';
import LazyLoadingWrapper from '../../modules/common/LazyLoadingWrapper';
import { Literals } from '../../modules/common/literals';
import Buttons from '../button/Buttons';

import "./customTimeliine.scss";
import SqlEditor from '../../modules/dataCatalogue/preview/components/SqlEditor';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { convertBase64ToString,getMonthName,getTime,queryStatus } from '../../modules/common/helperFunctions';
import { emitToastNotification } from '../../../helpers/toast_helper';
import DataTabs from '../dataTabs/DataTabs';
import { ICON_CALENDAR, ICON_VERSION } from '../newIconSource';
const ACTION_MAPPING={
    "UPDATED":"edit",
    "EDITED":"edit",
    "ADDED":"add"
}

const URL_MAPPING={
    "DATA DICTIONARY":"data-dictionary",
    POD:"dataCatalog/podDetails",
    ALERT:"viewAlert",
    FLOW:"viewFlows"

}

 const CustomTimeline = ({type,id, showCard = true,scrollbarHeight, isDictionary = false}) => {
    const [openModal, setOpenModal] = useState(false)
    const [isVersion,setIsVersion]=useState(true);
    const [timeline,setTimeLine]=useState([]);
    const [data,setData]=useState([]);
    const [viewTabsActiveKey, setviewTabsActiveKey] = useState("version");

    const [scroll,setScroll]=useState(true)
    const dispatch=useDispatch();
    const [stopTimelineLazyload,setStopTimelineLazyLoad]=useState(false);
    const [isRefreshing,setIsRefreshing]=useState(false);
    const pageNo=useRef(1)
    const [row,setRow]=useState({})
    let flowName = useSelector(state => state.FlowsReducer.name)
    const getAuditDataFunc = (isRefresh) => {
        setIsRefreshing(true);
        if(isRefresh)
            pageNo.current=1;

        dispatch(getAuditData({ category: type, id: id ,page:pageNo.current})).then((res) => {
            if (res.status === "success" && res.message === "Invalid Page Requested") {
                emitToastNotification('info',Literals.NO_MORE_USAGE_LOGS_TO_LOAD )
                setIsRefreshing(false);

                setStopTimelineLazyLoad(true)
            }
           
            else{
                let temp = res?.data?.results;

                if(temp.length==0){
                    setIsRefreshing(false);

                    setStopTimelineLazyLoad(true);
                }
                else{
                setIsRefreshing(false);
                pageNo.current+=1;
                //  setPageNo(prev=>prev+1)
              if(temp){
                if(!temp.hasNext)
                    setStopTimelineLazyLoad(true);
                setData(temp);

              }
            }
            }
        
        }).catch(()=>{
            setIsRefreshing(false);

        })
      }
      const onViewTabChange = (activeKey) => {
        if(activeKey=="version"){
            setIsVersion(true);
        }
        else{
            setIsVersion(false);
        }
        setviewTabsActiveKey(activeKey);}
        const getTimelineDataOnLazyLoad=()=>{
            getAuditDataFunc();
        }
  

      const getVersionDataFunc=(isRefresh)=>{
        setIsRefreshing(true);
        if(isRefresh)
            pageNo.current=1;

        dispatch(getVersionData({ content_id:id,module_type:type,pageNo})).then((res) => {
            if (res.status === "success" && res.message === "Invalid Page Requested") {
                emitToastNotification('info',Literals.NO_MORE_USAGE_LOGS_TO_LOAD )
                setIsRefreshing(false);

                setStopTimelineLazyLoad(true)
            }
           
            else{
                let temp = res?.data?.output;

                if(temp.length==0){
                    setIsRefreshing(false);

                    setStopTimelineLazyLoad(true);
                }
                else{
                setIsRefreshing(false);
                pageNo.current+=1;
                //  setPageNo(prev=>prev+1)
              if(temp){
                if(!temp.hasNext)
                    setStopTimelineLazyLoad(true);
                setData(temp);

              }
            }
            }
        
        }).catch(()=>{
            setIsRefreshing(false);

        })
      }
      useEffect(() => {
        pageNo.current=1;
        if(isVersion)
        getVersionDataFunc();
        else
        getAuditDataFunc();
      }, [isVersion])
    
    useEffect(()=>{
        let tempdata=data.filter(item=>item.log_time!=="")?.map(item=>{
            // let formattedDate = moment(item.log_time).format("DD-MM-YYYY hh:mm:ss");
            let timeFromNow=moment(item.log_time).fromNow();
            return {
                log_time:item.log_time,
                id: item.content_id,
                field: `${item.action} : ${item.content_name}`,
                info_action: `${item.action_message}`,
                timePassed:timeFromNow,
                info_desc: `${item.user_name} `,
                user:item.user_name,
                data:item,
                action: ACTION_MAPPING[item.action]

            }
        })
        tempdata=tempdata.sort(function (a, b) {
            return moment(b.log_time).diff(a.log_time);            
        });
        setTimeLine(tempdata);
    },[data])

    useEffect(()=>{
        getVersionDataFunc()
    },[])

    const convertDateFormat=(time)=>{
        let formattedDate = moment(time).utc().format("YYYY-MM-DD HH:mm:ss");
        return formattedDate;

    }
    
    const timelineContent=()=>{
        return (
            <Timeline className='pl-3 pt-3'>
            {
                timeline.length>0?   timeline.filter(v=>v.data.log_time!="").map((v,i)=>  
                        <Timeline.Item 
                            // key={`changelog-tmln-${v.id}`}
                            // style={isVersion?{cursor:"pointer"}:{}}
                            onClick={()=>{
                                
                                // if(isVersion){
                                    
                                //     localStorage.setItem("versionHistory",JSON.stringify(v.data));
                                //     // window.open(`/${URL_MAPPING[type]}/${v.id}?history_id=${v?.data?.log_id}&start_date=${convertDateFormat(v.data.log_time)}&end_date=${i==0 ?convertDateFormat(new Date()):convertDateFormat(timeline[i-1].data.log_time)}&action=${v.data?.action}`)
                                // }
                                // else{
                                    setOpenModal(true)
                            
                                    setRow(v.data)
                                // }
                                }}
                                
                            className='chnglog-tmln'
                            dot={
                                
                            isVersion?<div className=''> <VERSION_ICON height='18' width='18' /></div>:    <Tooltip title={v.user}> <div className='changelog-action-ic'>
                                    {/* <EDIT/> */}
                                    <span style={{color:"white"}}>{v?.user?.charAt(0)?.toUpperCase()??""}</span>   
                                </div>
                                </Tooltip>
                            }
                        >
                            <div id={`timelineAct-${i}`} className='pt-1'>
                                <p className='small mb-1 fontSizeLabel fontInterSemiBold'>
                                    {/* {
                                        isDictionary ? v?.data?.content_type : v.field
                                    } */}
                                    {v.field}
                                </p>
                                {/* <p className='small text-black-50 mb-1' ><ICON_VERSION width='14' height='14' color='#33333380' /> <span className='pl-1'></span> Version ID : {i+1}</p> */}

                                {   isVersion
                                    ?  <p className='small text-black-50 mb-1'><ICON_CALENDAR height='14' width='14' color='#33333380' /> <span className='pl-1'></span> {v.timePassed}</p>
                                    :  <p className='small text-black-50 mb-1'><ICON_CALENDAR height='14' width='14' color='#33333380' /> <span className='pl-1'></span> {v.info_action}</p> 
                                }
                                {
                                    v?.info_desc && v?.info_desc?.trim() !== ''
                                    ?
                                    <p className='small text-black-50' ><USER width='14' height='14' color='#33333380' /> <span className='pl-1'></span> {v.info_desc}</p>
                                    :   ''
                                }

                            </div>
                        </Timeline.Item>
                        ): 
                        <div className='no-mod-dat-found'>
                            <NoDataComponent logo={<TIMELINE width="60" height="60" color="black" />}
                            message="No Logs Found" />
                        </div>
            }
        </Timeline>

    )
}
    const refreshTimeline=()=>{
        
        getVersionDataFunc(true)
    }

    return (
        <div className='h-100'>
            <div className={`custom-timeline-div form_left h-100 ${type=="ALERT"?"alert-timeline":""}`}>
<Card className={!showCard ? 'border-0 shadow-none p-0 h-100' : 'card custom-card p-0 h-100 '}>
              <CardHeader className='padding-2 rbg-white d-flex ' style={{justifyContent:"space-between"}}>

       
     
<div className={`font-weight-bold text-uppercase`}  style={{display:"flex"}}>
       
       
        <div className='font-weight-bold text-uppercase cust-tm-heading'>Timeline</div>

                <Buttons
                    props={{
                        buttonId: "timelineRefershBtn",
                        tooltip: "Refresh",
                        buttonText: "",
                        buttonClassName: "custom-btn-outline ml-2 custom-btn btn-with-icon",
                        buttonEvent: () => {
                        refreshTimeline()
                        },
                        ImgSrc: () => <span 
                        className={`d-flex`}><REFRESH /></span>,
                        isDisable: isRefreshing,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />
</div>
                </CardHeader>
                <CardBody id='timelineList' className={`${timeline.length===0?'no-dat-in-tmln':''} ${!showCard || scrollbarHeight?'p-0':''}`}  style={{overflowY: 'auto'}}>
                <LazyLoadingWrapper
                paginateMethod={getTimelineDataOnLazyLoad}
                TableDiv={timelineContent}
                scrollbarHeight={scrollbarHeight}
                showLoader={false}
                
                setScroll={ stopTimelineLazyload ? false : scroll}
                stopLazyload={stopTimelineLazyload}
            />
                </CardBody>
            </Card>
            </div>

     {type=="POD"?       <Modal size="md" isOpen={openModal} className="custom-modal" centered={true} onClosed={()=>setOpenModal(false)}>
            <ModalHeader close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType:Literals.BTN_TERTIARY}} />}>
            <div className="d-flex align-items-center justify-content-between w-100">
                    <div className='d-flex'>
                        <TIMELINE/>
                        <h2 className="section-heading">Timeline Details</h2>
                    </div>
                    <div className='mb-n1 mr-3 small'>
                        <label className='d-flex justify-content-end mb-0 small text-right' style={{lineHeight: 1}}>Status: 
                          <span className={`d-flex ml-1 mb-0 text-capitalize 
                            ${row?.status === queryStatus.finished ||
                              row?.status === queryStatus.success ? 'text-success' 
                            : row?.status === queryStatus.running ? 'text-primary'
                            : row?.status === queryStatus.queued ? 'text-warning'
                            : row?.status === queryStatus.failed ? 'text-danger'
                            : 'text-black-50'} `}>
                            <i className={`mr-1 bx bx-${
                                      row?.status === queryStatus.finished ||
                                      row?.status === queryStatus.success ? 'check-circle' 
                                    : row?.status === queryStatus.running ? 'run'
                                    : row?.status === queryStatus.queued ? 'dots-horizontal-rounded'
                                    : row?.status === queryStatus.failed ? 'x-circle'
                                    : 'text-black-50'
                                }
                            `}></i>

                            { row?.status && row?.status.length > 0 
                            ? row?.status.toLowerCase()
                            : 'No Status' }
                          </span>
                        </label>
                        <label className='mb-0 small text-black-50 d-block text-right'>{row?.user_name}</label>
                    </div>
                </div>                 
            </ModalHeader>
            <ModalBody>
            <div>
                <>
                  <div className="row mx-n2 py-2 my-1">
                    {
                      row?.query_string !== null && row?.query_string !== "" ?
                      <div className="col-12 mb-4">
                        <div className='custom-card shadow-none border h-100'>
                          <SqlEditor
                              QueryValue={convertBase64ToString(row?.query_string??"")}
                              width={"100%"}
                              height={"100px"}
                              readOnly={true}
                              showGutter={false}
                              wrapEnabled={true}
                          />
                        </div>
                      </div> : ''
                    }
                    <div className='col-12'>
                        <div className='align-items-center border custom-card d-flex h-100 justify-content-between py-1 shadow-none'>
                            <div>
                                <label className='label mb-0'>Log Time</label>
                            </div>
                            <div>
                                <label className='small mb-0'><i className="mr-1 bx bxs-calendar-alt"></i>{new Date(row?.log_time).getDate()} { getMonthName.short[new Date(row?.log_time).getMonth()]} {new Date(row?.log_time).getFullYear()}</label>
                                </div>
                            <div>
                                <label className='small mb-0'><i class="mr-1 bx bx-time"></i>{getTime(row.log_time)}</label>
                            </div>
                        </div>
                    </div>
                    {
                      row.status_message !== null && row.status_message !== "" ?
                      <div className='col-12 mt-4'>
                          <div className='custom-card shadow-none border h-100'>
                              <div>
                                  <label className='label text-black-50'>Status Message</label>
                              </div>
                              <div>
                                  <p className="word-break-all">{row.status_message}</p>
                              </div>
                          </div>
                      </div> : ''
                    }
                  </div>
                </>
            </div>
            </ModalBody>  
            <ModalFooter>
            </ModalFooter>
        </Modal>:""}
        </div>
    )
}

export default CustomTimeline;