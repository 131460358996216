import React, { useEffect, useState } from "react";

import Plot from 'react-plotly.js'


const Graph = ({ graphData, columnNameType, columnDetails }) => {
  const [graphDetails, setGraphDetails] = useState([]);

  const plotlyConfig = {
    displaylogo: false,
    responsive: true,
    editable: false
  }

  const generateBarChartCoordinates = (data) => {
    
    return [
        {
            x: Object.keys(data),
            y: Object.values(data),
            type: "bar",
            marker: {
              color: "#1f77b4",
              opacity: 0.5,
          }
        }
    ]
  }
  const generateHistogramChartCoordinates = (data) => {
    
    return [
        {
            x: convertToXAxisRange(Object.keys(data)),
            y: Object.values(data),
            type: "bar",
            marker: {
              color: "#1f77b4",
              opacity: 0.5,
              line: {
              color:  "#fff", 
              width: 1
            } 
          }
        }
    ]
  }

  const convertToXAxisRange = (data) => {
    
    let a = []
    let asc = data.sort((a,b) => Number(a).toFixed(2) - Number(b).toFixed(2))
    a = asc.map((ele, index)=>{
      let first = Number(ele).toFixed(2)
      if(asc[index + 1]) {
        let newEl = `${first} - ${Number(asc[index + 1]).toFixed(2)}`
        return newEl
      }
      else {
        return first
      }
    })
    return a
  }

  const plotlyLayout = (col_details) => {
    
    return {
      bargap: columnDetails.dataType === 'integer' || columnDetails.dataType === 'float' || columnDetails.dataType === 'double' ? 0 : undefined, 
      bargroupgap: columnDetails.dataType === 'integer' || columnDetails.dataType === 'float' || columnDetails.dataType === 'double' ? 0 : undefined, 
      
      
      
      yaxis: {title: "Count"},
       
        xaxis: {
          title: col_details.name,
          tickmode: "array",
          ticktext: [
            graphDetails[0]?.x[0],
            graphDetails[0]?.x[graphDetails[0].x.length - 1],
          ], 
          tickvals: [
            graphDetails[0]?.x[0],
            graphDetails[0]?.x[graphDetails[0].x.length - 1],
          ],
          autotick: false,
          
          
          
          
          
          tickfont: {
            family: "Old Standard TT, serif",
            size: 14,
            color: "black",
          },
      }
    }
  }

  useEffect(() => {
    if(graphData && graphData?.top_10_value_count) {
      const graphDetails = (graphData?.column_datatype === "double" || 
        graphData?.column_datatype === "real" || 
        graphData?.column_datatype === "numeric" || 
        graphData?.column_datatype === "integer" || 
        graphData?.column_datatype === "tinyint" || 
        graphData?.column_datatype === "smallint" || 
        graphData?.column_datatype === "bigint" || 
        graphData?.column_datatype === "decimal" || 
        graphData?.column_datatype === "float") ?
      generateHistogramChartCoordinates(graphData?.top_10_value_count) : generateBarChartCoordinates(graphData?.top_10_value_count);
      setGraphDetails(graphDetails);
    }

  }, [graphData]);

  return (
    <React.Fragment>
       <Plot  
            useResizeHandler={true} 
            data={graphDetails}
            style={{width: "100%", height: "300px"}}
            config={plotlyConfig}
            layout={plotlyLayout(columnNameType)}
        />
      {/* <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={90}
      /> */}
    </React.Fragment>
  );
};
export default Graph;
