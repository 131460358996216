import { Timeline } from 'antd'
import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import moment from 'moment';
import { useDispatch } from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'
import CustomTimeline from '../../../Common/timeline/CustomTimeline';
import { CHANGELOGADD, CHANGELOGEDIT, REFRESH, CLOSE, EDIT, EDITOR, PLUS, PODICON, TIMELINE } from '../../../Common/iconSource'
import { getChangeLogById } from "../../../../store/modules/dataDictionary/action";
import { NoDataComponent } from '../../common/helperFunctions';
import Buttons from '../../../Common/button/Buttons';
import { Literals } from '../../common/literals';



const ACTION_MAPPING = {
    "UPDATED": "edit",
    "EDITED": "edit",
    "ADDED": "add"
}

export const ChangeLogView = ({ saveClick, setShowChangeLogs = () => { }, isDictionary = false }) => {

    const dispatch = useDispatch();
    const [isRefreshing, setIsRefreshing] = useState(false);

    const { dataDictionaryId } = useParams()
    const [timeline, setTimeLine] = useState([]);


    const getAuditDataFunc = () => {

        setIsRefreshing(true);

        dispatch(getChangeLogById({ id: parseInt(dataDictionaryId) })).then((res) => {

         
            let temp = res?.data?.results;
            temp = temp?.map(item => {
                let formattedDate = moment(item.log_time).format("DD-MM-YYYY");
                return {

                    id: item.content_id,
                    field: `${item.content_type} : ${item.content_name}`,
                    info_action: `${item.action_message}`,
                    info_desc: `By ${item.user_name} at ${formattedDate}`,

                    action: ACTION_MAPPING[item.action]

                }
            })
            setTimeLine(temp);
            setIsRefreshing(false);


        }).catch(() => {
            setIsRefreshing(false);

        })
    }

    useEffect(() => {

        if (dataDictionaryId || saveClick) {
            getAuditDataFunc();
        }
    }, [dataDictionaryId, saveClick])
    const refreshTimeline = () => {
        getAuditDataFunc()
    }
    return (
        <div className='h-100'>
            <CustomTimeline 
                type={"DATA DICTIONARY"} 
                scrollbarHeight={"100%"} 
                id={dataDictionaryId} 
                isDictionary={isDictionary}
            />
        </div>
    )
}