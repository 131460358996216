import React, { useState, useEffect } from 'react'
//import 'antd/dist/antd.css';
import { Select } from 'antd';
import { Literals } from '../../common/literals';
const { Option } = Select;
function AlertSchedule({ isDisabled, scheduleBody, setscheduleBody }) {



    const [month, setmonth] = useState([]);
    const [day, setday] = useState([]);


    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => <Option value={start + (i * step)} key={start + (i * step)}>{start + (i * step)}</Option>);

    const scheduleType = [{ name: 'Daily', value: 'daily' }, { name: 'Monthly', value: 'monthly' },
    { name: 'Day', value: 'day' }, { name: 'Hourly', value: 'hourly' }, { name: 'Custom', value: 'custom' }]
    let hoursRange = range(0, 23, 1)

    let minuteRange = range(0, 59, 1);
    let daysList = [{ name: 'Monday', value: '1' }, { name: 'Tuesday', value: '2' }, { name: 'Wendesday', value: '3' }, { name: 'Thursday', value: '4' }, { name: 'Friday', value: '5' }, { name: 'Saturday', value: '6' }, { name: 'Sunday', value: '7' }]
    let monthList = [{ name: 'JAN', value: '1' }, { name: 'FEB', value: '2' }, { name: 'MAR', value: '3' }, { name: 'APR', value: '4' }, { name: 'MAY', value: '5' }, { name: 'JUN', value: '6' }, { name: 'JUL', value: '7' }, { name: 'AUG', value: '8' }, { name: 'SEP', value: '9' }, { name: 'OCT', value: '10' }, { name: 'NOV', value: '11' }, { name: 'DEC', value: '12' }]
    let days = range(1, 31, 1)
    useEffect(() => {
        let month = [];
        monthList.forEach((ele) => {
            month.push(<Option value={ele.value} key={ele.value}>{ele.name}</Option>);
        });
        setmonth([...month])
        let days = [];
        daysList.forEach((ele) => {
            days.push(<Option value={ele.value} key={ele.value}>{ele.name}</Option>);
        });
        setday([...days]);
        setscheduleBody({ key: 'frequency', arrayValue: 'daily', value: 'daily' })

    }, [])


    return (
        <div>
            <div className="form-group">
                <label>Frequency</label>
                <select value={scheduleBody.frequency} onChange={(e) => { setscheduleBody({ key: 'frequency', arrayValue: e.target.value, value: e.target.value }) }} disabled={isDisabled} className="form-control">
                    {scheduleType.map((type, index) => <option key={index} value={type.value}>{type.name}</option>)
                    }
                </select>
            </div>
            <React.Fragment>
                {
                    scheduleBody.frequency === 'hourly' ? <div className="form-group">
                        <label>{Literals.MINUTE}</label>
                        <Select
                            mode="tags"
                            allowClear
                            value={scheduleBody.startMinute}
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            onChange={(value) => { setscheduleBody({ key: 'startMinute', value: value.join(), arrayValue: value }) }}
                            maxTagCount='responsive'
                            disabled={isDisabled}
                        >
                            {minuteRange}
                        </Select>
                    </div> : null
                }
                {scheduleBody.frequency !== 'custom' && scheduleBody.frequency !== 'hourly' ? <><div className="form-group">
                    <label>{Literals.HOURS}</label>
                    <Select
                        mode="tags"
                        allowClear
                        value={scheduleBody.startHour}
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        onChange={(value) => { setscheduleBody({ key: 'startHour', value: value.join(), arrayValue: value }) }}
                        maxTagCount='responsive'
                        disabled={isDisabled}
                    >
                        {hoursRange}
                    </Select>
                </div>
                    <div className="form-group">
                        <label>{Literals.MINUTE}</label>
                        <Select
                            mode="tags"
                            allowClear
                            value={scheduleBody.startMinute}
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            onChange={(value) => { setscheduleBody({ key: 'startMinute', value: value.join(), arrayValue: value }) }}
                            maxTagCount='responsive'
                            disabled={isDisabled}
                        >
                            {minuteRange}
                        </Select>
                    </div>
                </> : null}
                {scheduleBody.frequency === 'monthly' ? <> <div className="form-group">
                    <label>{Literals.MONTH}</label>
                    <Select
                        mode="tags"
                        allowClear
                        value={scheduleBody.Month}
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        onChange={(value) => { setscheduleBody({ key: 'Month', value: value.join(), arrayValue: value }) }}
                        maxTagCount='responsive'
                        disabled={isDisabled}
                    >
                        {month}
                    </Select>
                </div>
                    <div className="form-group">
                        <label>{Literals.DAY_IN_MONTH}</label>
                        <Select
                            mode="tags"
                            allowClear
                            value={scheduleBody.DayMonth}
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            onChange={(value) => { setscheduleBody({ key: 'DayMonth', value: value.join(), arrayValue: value }) }}
                            maxTagCount='responsive'
                            disabled={isDisabled}
                        >
                            {days}
                        </Select>
                    </div>
                    <div className="form-group">
                        <label>{Literals.DAY}</label>
                        <Select
                            mode="tags"
                            allowClear
                            value={scheduleBody.Day}
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            onChange={(value) => { setscheduleBody({ key: 'Day', value: value.join(), arrayValue: value }) }}
                            maxTagCount='responsive'
                            disabled={isDisabled}
                        >
                            {day}
                        </Select>
                    </div></> : scheduleBody.frequency === 'day' ? <div className="form-group">
                        <label>{Literals.DAY}</label>
                        <Select
                            mode="tags"
                            allowClear
                            value={scheduleBody.Day}
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            onChange={(value) => { setscheduleBody({ key: 'Day', value: value.join(), arrayValue: value }) }}
                            maxTagCount='responsive'
                            disabled={isDisabled}
                        >
                            {day}
                        </Select>
                    </div> : null

                }            </React.Fragment>
            {scheduleBody.frequency === 'custom' ? <React.Fragment>

                <div className="form-group">
                    <label>Custom</label>
                    <input type="text" value={scheduleBody.cron} disabled={isDisabled} onChange={(e) => { setscheduleBody({ key: 'cron', value: (e.target.value), arrayValue: e.target.value }) }} className="form-control" />

                </div>
            </React.Fragment> : null}


        </div >
    )
}

export default AlertSchedule

