import React,{useEffect, useState} from 'react';
import Buttons from '../../../Common/button/Buttons';
import { 
  PLUS ,SCHEDULER} from '../../../Common/iconSource';
import { Tabs } from 'antd';
import DataTabs from '../../../Common/dataTabs/DataTabs';
import { Literals } from '../../common/literals';
import RolesComponent from './components/RolesComponent';
import { useDispatch } from 'react-redux';
import LoadingComponent from '../../common/loadingComponent';
import { resetEntitlement } from '../../../../store/modules/entitlements/entitlementAction';
import { connect } from 'react-redux';
import EntitlementOnBoard from './components/EntitlementOnBoard';


const Entitlement = (props) => {
const dispatch=useDispatch();
    useEffect(()=>{

      return ()=>{
        dispatch(resetEntitlement());
      }
    },[])
  
   return <div >
      {props.loadingStatus ? <LoadingComponent /> : null}
      <EntitlementOnBoard/>


      {/* <RolesComponent 
       searchValue={props.searchValue}
       setSearchValue={props.setSearchValue}
      
      /> */}
     
  </div>
};

const mapStateToProps = (state) => {
  return {
    loadingStatus: state.LoadingReducer.loadingStatus,
  };
};

export default connect(mapStateToProps)(Entitlement);