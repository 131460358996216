import React from 'react';
import { Modal, ModalBody } from "reactstrap";
import { BLACK_LOGS } from '../../../Common/iconSource';


const AlertExceptionPreviewModal = ({ openExceptionModal, setOpenExceptionModal, exceptionData, setExceptionData, exceptionDataTimeStamp }) => {
    let columns = []
    columns = exceptionData?.data_header?.map((ele) => {
        return {
            title: ele,
            dataIndex: ele,
            key: ele,
        }

    });

    let dataSource = []
    dataSource = exceptionData?.data_record


    return (
        <Modal size="xl" isOpen={openExceptionModal} className="drop-modal datarule-modal">
            <ModalBody>
                <div className="modal-heading ">
                    <p className="modal-heading-logs"><BLACK_LOGS /> Logs : {exceptionDataTimeStamp} </p>
                    <button className="close" onClick={() => {
                        setOpenExceptionModal(false)
                        setExceptionData([])
                    }}><i className="bx bx-x"></i></button>
                </div>
                <div className="pl-2 pr-2">
                  
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AlertExceptionPreviewModal;