import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useHideSidebar from "../../../../../customHooks/useHideSidebar";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { getUserGroupList } from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import { SET_ACTIVE_UM_TAB_NAME } from "../../../../../store/modules/UserManagement/userGroup/UMActionTypes";
import { SMALLGROUP, USERSGROUP } from "../../../../Common/iconSource";
import SearchInput from "../../../../Common/search/Search";
import ActionPopUp from "../../../common/actionPopUp";
import { generateHtmlTitle, getRelativeTime } from "../../../common/helperFunctions";
import UserDetailsHeader from "../../../common/LandingpageFirstHeader";
import { Literals } from "../../../common/literals";
import LoadingComponent from "../../../common/loadingComponent";
import NoSearchResultFound from "../../../common/NoSearchResultFound";
import UserManagementCard from "../../common/UserManagementCard";
import CreateNewUserModal from "./CreateNewUserModal";
import UserGeneralDetails from "./UserGeneralDetails";
import ViewUserSecondaryHeader from "./ViewUserSecondaryHeader";
import UserGroupListView from "../../userGroup/components/UserGroupListView";
import { ICON_USERS_GROUP } from "../../../../Common/newIconSource";
import SAMLDetails from "./SAMLDetails";

const intialFieldsState = {
  firstName: "",
  lastName: "",
  email: "",
  designation: "",
  isSuperUser: false,
  id: "",
};

const ViewUserDetails = () => {
  const { id } = useParams();
  const history = useNavigate();
  const store = useStore();
  const dispatch = useDispatch();
  const loadingStatus = useSelector(
    (state) => state.LoadingReducer.loadingStatus
  );

  const CurrentUserDetails = useSelector(state => state.UserReducer.user.userDetails)

  const [userDetails, setUserDetails] = useState(null);
  const [userGroupsList, setUserGroupsList] = useState([]);

  const [userDataFields, setUserDataFields] = useState(intialFieldsState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchData, setSearchData] = useState("");

  const [selectedId, setSelectedId] = useState("");
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const data = []
  useEffect(() => {
    dispatch({ type: SET_ACTIVE_UM_TAB_NAME, payload: Literals.USERS })

    let title = generateHtmlTitle("User", 'User Management')
    document.title = title

    //  fetchAssociatedUserGroups()
  }, []);


  const onInputChangeHandler = (field, value) => {

    if(['firstName', 'lastName'].includes(field)) {
      value = value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      });
    }

    setUserDataFields((fields) => {
      return { ...fields, [field]: value };
    });
  };



  const onEditUserHandler = () => {
    setIsModalOpen(true);
    const userFields = {
      firstName: userDetails.first_name,
      lastName: userDetails.last_name,
      email: userDetails.email,
      designation: userDetails.designation,
      isSuperUser: userDetails.is_superuser,
      id: userDetails.id,
    };

    setUserDataFields(userFields);
  };

  const fetchUserDetails = async () => {
    try {
      const response = await dispatch(
        getUserGroupList({
          method: "GET",
          endPoint: `group_manager/user/${id}/`,
        })
      );
      if (response.status === "success") {

        setUserDetails(response.data);
        fetchAssociatedUserGroups()
      }
    } catch (error) {
      console.error("Error fetching user details", error.message);
    }
  };


  const fetchAssociatedUserGroups = async () => {
    try {
      const response = await dispatch(
        getUserGroupList({
          method: "GET",
          endPoint: `group_manager/group/?user_id=${id}`,
        })
      );
      if (response.status === "success") {
        setUserGroupsList(response?.data)
      }
    } catch (error) {
      console.error("Error fetching user details", error.message);
    }
  };



  useHideSidebar();

  useEffect(() => {
    fetchUserDetails();

  }, [id]);

  function clickEdit(group) {
    history(Literals.links.USER_MANAGEMENT_EDIT_USER_GROUP + group.key, { state: { tabName: Literals.USERGROUP } });
  }

  function redirectTo(key) {
    history(Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS + key, { state: { tabName: Literals.USERGROUP } });
  }
  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };
  function clickDelete(group) {
    setSelectedId(group.key);
    setOpenDeletePopup(true);
    setDeletePopUpContent({
      modalClass: "delete-popup",
      heading: "Delete",
      message: "This action will delete the group permanently.",
      headingIcon: "bx bx-error",
      button: [
        {
          name: "Delete",
          action: () => deleteId(group.key),
        },
        {
          name: "Cancel",
          action: closeDeletePopUp,
        },
      ],
      elements: [],
      closeActionPopup: closeDeletePopUp,
    });
    setOpenDeletePopup(true);
  }

  const deleteId = async (id) => {
    setOpenDeletePopup(false);
    let response;
    try {
      response = await store.dispatch(
        getUserGroupList({
          method: "DELETE",
          endPoint: "group_manager/group/" + id + "/",
        })
      );
      if (response.status === "success") {
        emitToastNotification('success', response?.message);
        fetchAssociatedUserGroups();
      } else {
        emitToastNotification('error', response?.message);
      }
    } catch (error) {
      emitToastNotification('error', error?.message);
    }
  };



  const filterData =
    userGroupsList &&
    userGroupsList.filter((data) => {
      return (
        data?.name
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.description
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        getRelativeTime(data?.updated_on)
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.created_by_user?.username
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase())
      );
    });

  filterData &&
    filterData.map((value) =>
      data.push({
        key: value?.id,
        userGroupName: value?.name,
        domainCount: value?.data_domain,
        description: value?.description,
        createdOn: getRelativeTime(value?.updated_on),
        createdBy: value?.created_by_user?.username,
        users: value?.user?.count,
        data: { ...value }
      })
    );

  const getView = {
    list: <div className="data-domain-listview usr-manage-user" style={{ background: "white" }}>
      <UserGroupListView preData={userGroupsList} searchData={searchData} />
    </div>,
    grid: <div className="data-domain-gridview usr-manage-user" style={{ background: "white" }}>{getCardView()}</div>
  }

  function getCardView() {
    return data.map((ele, i) => (
      <UserManagementCard
        key={i}
        searchData={searchData}
        domainDetails={ele}
        selectedDomainList={data}
        clickEdit={clickEdit}
        clickDelete={clickDelete}
        icon={<USERSGROUP />}
        tabName="Usergroup"
        redirectTo={redirectTo}
      />
    ));
  }

  function renderLowerSection() {
    return (
      <div className="dataDomain-lower-part">
        <div className="lower-subheader">
          <div className="sub-header-left">
            <div className="heading-content">
              <span className="domain-icon"><ICON_USERS_GROUP height="25" /></span>
              <p>List Of User Groups</p>
            </div>
          </div>
          <div className="sub-header-right">
            <ul className="landingPage_navbar-nav-right">
              <li className="landingPage_nav-item mr-2">
                <SearchInput searchData={searchData} setSearchData={setSearchData} />
              </li>
            </ul>
          </div>
        </div>

        {(searchData?.length > 0 && filterData?.length === 0) || data.length == 0 ? (
          <NoSearchResultFound />
        ) : (
          getView.list
        )}

        <ActionPopUp
          openModal={openDeletePopup}
          actionData={deletePopUpContent}
        />
      </div>
    )
  }

  return (
    <div className="user-details-page">
      <UserDetailsHeader
        title={Literals.USER_MANAGEMENT}
        flag={true}
        backRoute="/userManagement/?activeTab=users"
      />
      <ViewUserSecondaryHeader isDisabled={!CurrentUserDetails?.is_superuser && CurrentUserDetails?.id !== userDetails?.id} onEdit={onEditUserHandler} />
      <div className="user-details-content">
        <UserGeneralDetails userDetails={userDetails} />
        {renderLowerSection()}
       
      </div>

      <CreateNewUserModal
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        viewMode={"edit"}
        userData={userDataFields}
        onChange={onInputChangeHandler}
        onSuccess={fetchUserDetails}
      />

      {loadingStatus && <LoadingComponent />}
    </div>
  );
};

export default ViewUserDetails;
