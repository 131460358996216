import React from 'react'
import TalkDataIndex from './TalkDataIndex'
import '../genAi.scss'
import { v4 as uuidv4 } from "uuid";

const FloatingTalkData = React.forwardRef(({
    dataDomainId,
    podIds,
    dashboardId
}, ref) => {

    const [rerender, setRerender] = React.useState(false)

    React.useImperativeHandle(ref, () => {
        // Future use
        return {
            reRenderChat: () => {
                setRerender(true)
            }
        }
    }, [])

    React?.useEffect(() => {
        if(rerender) {
            setTimeout(() => setRerender(false), 500)
        }
    }, [rerender])

    const extraPayload = React.useMemo(() => {
        if(dashboardId) {
            return {dashboard_id: dashboardId}
        }
        else if(podIds && podIds?.length > 0) {
            return {pod_ids: podIds}
        }
        return {}
    }, [podIds, dashboardId])

    const uuid = React.useMemo(() => {
        return uuidv4()
    }, [dataDomainId, rerender])

    return (
        <div className='float-talkdata'>
            {
                rerender ? ''
                :
                <TalkDataIndex
                    isFloating={true}
                    dataDomainId={dataDomainId}
                    extraPayload={extraPayload}
                    uuidProp={uuid}
                />
            }
        </div>
    )
})

export default FloatingTalkData