import { Progress, Select } from 'antd'
import React from 'react'
import { ICON_CATALOG, ICON_CATALOG_DOMAIN } from '../../../Common/newIconSource'
import { LandingSimpleTableInner } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import DictColumnStat from './DictColumnStat'
import { LoadingOutlined } from '@ant-design/icons';
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { pxToRem, remToPx } from '../../common/helperFunctions'

const StatsHealthView = ({
    overallHealth = 0,
    fieldValues = {},
    setFieldValues = () => {},
    activePod,
    previewRef,
    loading = false,
    activeTabKey,
    podHealthReloading = false,
    initialData = {},
    defaultDomain = null,
    previewLoading = false
}) => {
    const [domainList, setDomainList] = React.useState([])
    const [activeDomain, setActiveDomain] = React.useState('')
    
    const podHealthRef = React.useRef({})

    const totalPods = fieldValues?.col_term?.length ?? 0

    React.useEffect(() => {
        setActiveDomain(defaultDomain?.name?.toString())
    }, [defaultDomain])

    React.useEffect(() => {
        if(podHealthReloading) {
            podHealthRef.current = {}
        }
    }, [podHealthReloading])

    const rowData = React.useMemo(() => {

        const data = [...fieldValues?.col_term]

        const dataObj = data?.reduce((prev, curr) => {
            return (
                { 
                    ...prev, 
                    [curr?.data_domain_name]: [...data?.filter(d => d?.data_domain_name === curr?.data_domain_name)]
                }
            )
        }, {})

        setDomainList(Object.keys(dataObj)?.map(d => ({Name: d, value: d})))
        setActiveDomain(
            activeDomain === "" || !(Object.keys(dataObj)?.find(d => d === defaultDomain?.name))
            ? Object.keys(dataObj)?.[0]
            : activeDomain
        )

        let arr = []
        for (const key in dataObj) {
                const item = dataObj[key]
                arr.push({
                    title: key,
                    data: item?.map(item => {

                        return {
                            pods: <div className='d-flex h-100 w-100 align-items-center'>
                                <DictColumnStat 
                                    item={item} 
                                    colStats={podHealthRef?.current}
                                    metaData={{table_id: item?.table_id, column_id: item?.column_id}}
                                    isActive={activePod ? activePod?.toLowerCase() === item?.table_name?.toLowerCase() : false}
                                    activeTabKey={activeTabKey}
                                />
                            </div>,
                            data: item
                        }
                    })
                })
        }
        
        return arr

    }, [fieldValues, activePod, podHealthReloading])

    const getTemplate = (item) => {
        return <>
            <div>
                {/* <div title={item?.title?.toUpperCase()} className='fontSizeLabel py-2 px-2 dict-color-dark text-uppercase text-with-ellipsis'>
                    <ICON_CATALOG_DOMAIN height='15' width='15' color='#0A3053' />
                    <span className='p-1'></span>
                    {item?.title}
                </div> */}
                <LandingSimpleTableInner
                    cols={columns}
                    rowsData={item?.data ?? []}
                    rowHeight={remToPx(pxToRem(64))}
                    tableHeight={'100%'}
                    headerRowHeight={0}
                    onRowClick={(record) => {
                        if(previewLoading) {
                            emitToastNotification('info', "Please wait preview is loading...")
                            return
                        }
                        previewRef?.current?.setPod(record?.data?.table_name)
                    }}
                />
            </div>
        </>
    }

    const onDomainChange = (key) => {
        setActiveDomain(key)
    };

    return (
        <div className='stats-health-view-wrap'>
            <div className='key-points pl-2'>
                <div className='mb-4'>
                    <div>
                        <div className='dict-title-strip mb-2'>
                            <div className='d-flex align-items-center w-100 justify-content-between'>
                                <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                                    Total Health
                                </label>
                            </div>
                        </div>
                        {/* <span className='bold-h-line'></span> */}
                        
                    </div>
                </div>
                <div className='pt-3 text-center position-relative pr-2'>
                    {
                        loading
                        ?   <LoadingOutlined className='mt-3' style={{ fontSize: '2.5rem', color: '#2c83c4' }} spin />
                        :
                        <Progress
                            size={remToPx(pxToRem(75))}
                            strokeLinecap="butt"
                            type="circle"
                            percent={overallHealth?.toFixed(0) ?? 0}
                            format={(percent) => <span title={`${overallHealth?.toFixed(2)}%`} className='dict-color-dark'>{percent}%</span>}
                            strokeWidth={10}
                            strokeColor={'#2C83C4'}
                        />
                    }
                </div>
                <div>
                    <p style={{fontSize: '1rem', marginBottom: '2.8125rem'}} className='text-muted text-center mt-3'>
                        Average Health in <br/> <span className='dict-color-dark' style={{fontSize: '1.25rem'}}>{totalPods} Pod(s)</span>
                        {/* Average Health of <br/><span title={initialData?.business_term?.toUpperCase()} className='total-health-bizterm'>{initialData?.business_term?.toUpperCase()}</span> in <span className='dict-color-dark' style={{fontSize: 20}}>{totalPods} Pod(s)</span> */}
                    </p>
                </div>
                {/* <div className='d-flex justify-content-between'>
                    <div>
                        <div className='stats-label-color fontSizeHeading fontInterSemiBold mb-1'>
                            Total Pod
                        </div>
                        <span className='bold-h-line'></span>
                        <div>
                            <h2 className='dict-color-dark pt-1'>{totalPods}</h2>
                        </div>
                    </div>
                    <div className='align-self-center pr-3 mr-1 mt-2'>
                        <ICON_CATALOG height='35' width='35'/>
                    </div>
                </div> */}
            </div>

            <div className='dict-health-list-wrap' style={{height: 'calc(100vh - 24.75rem)'}}>
            <div className='ml-2 pb-1'>
                <div className='dict-title-strip mb-2'>
                    <div className='d-flex align-items-center w-100 justify-content-between'>
                        <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                            Pod Health
                        </label>
                    </div>
                </div>
                {/* <span className='bold-h-line'></span> */}
            </div>
                <div className='my-2 mx-2'>
                    <Select
                        size='middle'
                        disabled={loading}
                        placeholder="Select Domain"
                        showSearch={true}
                        optionFilterProp="children"
                        loading={loading}
                        value={
                            loading ? 'Loading...' :
                            domainList?.find(d => d?.value === activeDomain)?.value?.replaceAll("_", " ") ?? 'Select Domain'
                        }
                        onChange={onDomainChange}
                        filterOption={(input, option) => {
                                const name = domainList?.find(d => d?.value === option?.value)?.Name ?? ''
                                return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                            }
                        }
                        filterSort={(optionA, optionB) => {
                                const nmA = domainList?.find(d => d?.value === optionA?.value)?.Name ?? ''
                                const nmB = domainList?.find(d => d?.value === optionB?.value)?.Name ?? ''
                                return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                            }
                        }
                        >
                        {
                            domainList?.map((ele, index) => (
                                <Select.Option key={index} value={ele.value ? ele.value : ele.Name}>
                                    <div title={ele?.Name} className='align-items-center d-flex px-1'>
                                        <span className='mr-1 dict-stat-pod-item'><ICON_CATALOG_DOMAIN width='17' height='17' /></span>
                                        <span className='fontSizeHeading ml-2 text-with-ellipsis text-uppercase'>{ele?.Name?.replaceAll("_", " ")}</span>
                                    </div>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </div>
                <div className='dict-hide-scroll' style={{height: 'calc(100% - 6rem)', overflowY: 'auto'}}>
                    {
                        podHealthReloading ? <ClaristaLoader/>
                        :
                        rowData?.filter(d => d?.title === activeDomain)?.length === 0
                        ?   <div className='px-3 py-5 text-black-50 fontSizeHeading text-center'>
                            No Pods Found
                        </div>
                        :
                        rowData?.filter(d => d?.title === activeDomain).map(item => (
                            getTemplate(item)
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

const columns = [
    {
        name: <label className='mt-0 fontSizeLabel fontInterSemiBold grey-color label'>Pods</label>,
        key: "pods",
        // width: 262,
        sortable: false,
        resizable: false
    }
]

export default StatsHealthView