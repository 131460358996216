import React, { useEffect, useState } from 'react'
import MainLandingPage from '../../../Common/mainLandingPage'
import CombineLandingIndex from '../../../Common/claristaNewLandingPage/CombineLandingIndex'
import { ALERT, FLOWS, SCHEDULER } from '../../../Common/iconSource'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSidebarActiveRoute } from '../../../../store/actions'
import { Literals } from '../../common/literals'

const FlowsNewLandingPage = () => {
    const location = useLocation()
    const [defaultTabKey, setdefaultTabKey] = useState('flows')

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(setSidebarActiveRoute(Literals.links.CLARISTA_LAB))
    }, [])

    useEffect(() => {
        if (location?.hash) {
            let hashParam = location.hash?.replace('#', '')
             if(hashParam){
                setdefaultTabKey(hashParam)
             }
           
        }
    }, [location])


    const tabsMenuList = [
        {
            key: 'flows',
            label: <span> <FLOWS /> Flows </span>
        },
        // {
        //     key: 'alert',
        //     label: <span> <ALERT /> Alert </span>
        // },
        {
            key: 'scheduler',
            label: <span> <SCHEDULER /> Scheduler </span>
        }
    ]

    const Component = React.useMemo(() => {
        return <MainLandingPage
                    page='lab'
                    tabsMenuList={tabsMenuList}
                    IndexComponent={(props) => <CombineLandingIndex {...props} />}
                    searchPlaceholder='Search in lab...'
                    defaultTabKey={defaultTabKey}
                />
    }, [defaultTabKey])

    return Component
}

export default FlowsNewLandingPage