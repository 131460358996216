import React from "react";
import Buttons from "../../../../Common/button/Buttons";
import { PLUS } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import NoDataInfo from "./NoDataInfo";
import "./UserGroups.scss";
import UserGroupsTable from "./UserGroupsTable";

const UserGroups = ({
  toggleModal,
  selectedUserGroups,
  setSelectedUserGroups,
  onChangePermission,
  groupPermissions,
  viewMode,
  isEditorPermission = false
}) => {
  return (
    <div className="policy-user-groups">
      <div className="policy-user-groups-header">
        <div className="policy-user-groups-title">
          <h4>{Literals.USER_GROUPS}</h4>
        </div>
        <div className="policy-user-groups-options">
       {viewMode.includes("view")?"":  <Buttons
            props={{
              buttonText: Literals.USER_GROUPS,
              buttonClassName:
                "custom-btn-primary custom-btn btn-with-text ml-2",
              buttonEvent: () => {
                toggleModal();
              },
              ImgSrc: () => <PLUS />,
              isDisable: !isEditorPermission || viewMode.includes("view"),
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
            }}
          />}
        </div>
      </div>
      <div className="policy-user-groups-table">
        {selectedUserGroups.length ? (
          <UserGroupsTable
            selectedUserGroups={selectedUserGroups}
            setSelectedUserGroups={setSelectedUserGroups}
            onChangePermission={onChangePermission}
            groupPermissions={groupPermissions}
            viewMode={viewMode}
            isEditorPermission={isEditorPermission}
          />
        ) : (
          <NoDataInfo category="usergroup" />
        )}
      </div>
    </div>
  );
};

export default UserGroups;
