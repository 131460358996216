import { Input } from 'antd'
import React from 'react'
import { debounce } from 'lodash'

const DebounceInput = ({
    onChangeIfCondition = () => true,
    onChangeElseCondition = () => {},
    useWithConditions = false,
    ...props
}) => {

    const [value, setValue] = React.useState("")
    const [isDefaultSet, setIsDefaultSet] = React.useState(false)

    React.useEffect(() => {
        if(useWithConditions && !isDefaultSet) {
            setValue(props?.defaultValue)
            setIsDefaultSet(true)
        }
    }, [props?.defaultValue, useWithConditions])

    const changeHandler = event => {
        props.onDebounce(event.target.value)
    }
    
    const debouncedChangeHandler = React.useCallback(debounce(changeHandler, 300), []);

    return (
        <>
            {
                useWithConditions
                ?
                <Input
                    value={value}
                    onChange={(e) => {
                        let val = e?.target?.value
                        if (onChangeIfCondition(e)) {
                            setValue(() => {
                                debouncedChangeHandler(e)
                                return val
                            })
                        }
                        else onChangeElseCondition()
                    }}
                    // onChange={debouncedChangeHandler}
                    {...props}
                />
                :
                <Input
                    onChange={debouncedChangeHandler}
                    {...props}
                />
            }
        </>
    )
}

export default DebounceInput