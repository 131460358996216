import { Drawer, Popover } from 'antd'
import React from 'react'
import { ICON_CLOSE, ICON_DOWNLOAD, ICON_EXITFULLSCREEN, ICON_EYEOPEN, ICON_FILE, ICON_FILE_CSV, ICON_FILE_DOC, ICON_FILE_JPG, ICON_FILE_PDF, ICON_FILE_PNG, ICON_FILE_PPT, ICON_FILE_XLS, ICON_FULLSCREEN, ICON_LOADING_CIRCLE } from '../../../../Common/newIconSource'
import { useDispatch } from 'react-redux'
import { getQuickUnstructuredPreview } from '../../../../../store/actions'
import Buttons from '../../../../Common/button/Buttons'
import { Literals } from '../../../common/literals'
import Axios from 'axios'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import './UnstructuredFileName.scss'
import { fetchData } from '../../../../../services/apiService'
import { getReloadErrorTemplate } from '../../../common/helperFunctions'
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader'
import _ from 'lodash'

const FILE_ICON = {
    doc: <ICON_FILE_DOC width='14' height='14' color={'currentColor'}/>,
    pdf: <ICON_FILE_PDF width='14' height='14' color={'currentColor'}/>,
    csv: <ICON_FILE_CSV width='14' height='14' color={'currentColor'}/>,
    ppt: <ICON_FILE_PPT width='14' height='14' color={'currentColor'}/>,
    jpg: <ICON_FILE_JPG width='14' height='14' color={'currentColor'}/>,
    xls: <ICON_FILE_XLS width='14' height='14' color={'currentColor'}/>,
    png: <ICON_FILE_PNG width='14' height='14' color={'currentColor'}/>,
}

const unsupportedType = ['doc', 'docx', 'rtf', 'xls', 'xlsx', 'ppt', 'pptx', 'tiff', 'heic']

const failedDocMessage = 'Failed to load document'

const UnstructuredFileName = ({
    dataObj = {},
    connectionName = "",
    smallRowHeight = false
    // connectionType
}) => {

    const dispatch = useDispatch()

    const [open, setOpen] = React.useState(false)
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [urlResponse, setUrlResponse] = React.useState(undefined)
    const [downloading, setDownloading] = React.useState(false)
    const [expanded, setExpanded] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [loading, setLoading] = React.useState(false)

    const { document_name = "", document_type = "", prefix = "", connection_name = "" } = dataObj

    React.useEffect(() => {
        if(!urlResponse && open) {
            setError(undefined)
            setLoading(true)
            dispatch(getQuickUnstructuredPreview(
                prefix,
                document_name,
                connection_name ?? connectionName,
                true,
                false
            ))
            .then(res => {
                // if(connectionType === 'azure_adls') {
                //     setUrlResponse(res?.data?.response)
                // }
                // else if(connectionType === 's3') {
                //     setUrlResponse(res?.data)
                // }
                // else {
                    let url = ''
                    if(typeof res?.data === 'object') {
                        url = res?.data?.response
                    }
                    else url = res?.data 
                    setUrlResponse(url)

                    dispatch(fetchData("GET", url))
                    .then(() => {
                        setError(undefined)
                    })
                    .catch((err) => {
                        console.log("ERROR::",err)
                        if(err && _.isString(err)) {
                            setError(err)
                        }
                        else if(typeof err === 'object') {
                            if(err?.status === 403) {
                                setError(err?.data ?? err?.message ?? failedDocMessage)
                            }
                            else {
                                setError(err?.message ?? failedDocMessage)
                            }
                        }
                        else {
                            setError(failedDocMessage)
                        }
                    });
                // }

            })
            .catch((err) => {
                setError(err?.message ?? 'Failed to load')
                setLoading(false)
            })
        }
    }, [open])

    const downloadFile = async () => {
        setDownloading(true)
        let url = urlResponse
        let fileName = document_name
        try {
            let { data: content } = await Axios.get(url, { responseType: "arraybuffer", headers: { 'Content-Type': 'application/json' } })
            let extension = document_type
            // let extension = _.last(fileName.split('.'))
            const file = new File([content], `${fileName}`, { type: 'application/' + extension })
            var FileSaver = require('file-saver');
            FileSaver.saveAs(file);
            setDownloading(false)
            emitToastNotification('success', `"${fileName}" Downloaded Successfully`)
        }
        catch (err) {
            console.error(err)
            setDownloading(false)
            emitToastNotification('error', `Failed to download "${fileName}"`)
        }
    }

    const onDrawerClose = () => {
        setOpenDrawer(false)
        setExpanded(false)
    }

    return (
        <div className='d-flex align-items-center h-100 py-1 text-with-ellipsis'>
            <span className='mr-1 position-relative doc-file-name-type' style={{top: -1}}>
                {FILE_ICON[document_type] ?? <ICON_FILE height='14' width='14' color='currentColor' />}
            </span>
            <Popover
                open={open}
                onOpenChange={bool => setOpen(bool)}
                trigger={'click'}
                title=""
                content={
                    <>
                        <div className='py-2'>
                            {
                                unsupportedType?.includes(document_type?.toLowerCase()) ? ''
                                :
                                <div className='unst-file-opt d-flex align-items-center px-2 py-1'
                                    onClick={e => {
                                        e?.stopPropagation()
                                        e?.preventDefault()
                                        setOpenDrawer(true)
                                        setOpen(false)
                                    }}
                                >
                                    <span className='mr-2 d-flex'>
                                        <ICON_EYEOPEN height='12' width='12' />
                                    </span>
                                    <span className='d-flex fontSizeHeading'>
                                        Quick Preview
                                    </span>
                                </div>
                            }
                            <div className={`unst-file-opt d-flex align-items-center px-2 py-1 ${downloading ? 'disabled' : ''}`}
                                onClick={e => {
                                    e?.stopPropagation()
                                    e?.preventDefault()
                                    if(downloading) return
                                    downloadFile()
                                }}
                            >
                                <span className='mr-2 d-flex'>
                                    {
                                        downloading ? <ICON_LOADING_CIRCLE height='12' width='12' />
                                        :
                                        <ICON_DOWNLOAD height='12' width='12' />
                                    }
                                </span>
                                <span className='d-flex fontSizeHeading'>
                                    {
                                        downloading ? 'Downloading...' : 'Download'
                                    }
                                </span>
                            </div>
                        </div>
                    </>
                }
                placement='rightTop'
                arrow={false}
            >
                <span 
                    title={document_name}
                    className={`w-fit-content rounded px-2 d-block text-with-ellipsis ${!smallRowHeight ? 'py-1' : ''}`}
                    style={{
                        background: '#EAEDEF', 
                        outline: open ? "dashed 1.5px #2c83c4" : "none",
                        lineHeight: '1.0625rem',
                        cursor: 'pointer'
                    }}
                >
                    {/* <span className='mr-1 position-relative' style={{top: -1}}>
                        {FILE_ICON[document_type] ?? <ICON_FILE height='14' width='14' color='currentColor' />}
                    </span> */}
                    {document_name ?? ""}
                </span>
            </Popover>

            <Drawer 
                title={
                    <div className='d-flex align-items-center justify-content-between'>
                        <label className='mb-0 fontSizeLargeHeading fontInterSemiBold d-flex align-items-center'>
                            <span className='mr-2 d-flex'>
                                <ICON_FILE width='16' height='16'/>
                            </span>

                            {document_name ?? ""}
                        </label>
                        <div className='d-flex align-items-center'>
                            <Buttons
                                props={{
                                    buttonText: "",
                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2 border-0`,
                                    buttonEvent: () => {
                                        downloadFile()
                                    },
                                    ImgSrc: () => downloading ? <ICON_LOADING_CIRCLE/> : <ICON_DOWNLOAD/>,
                                    isDisable: downloading,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                            <Buttons
                                props={{
                                    buttonText: "",
                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2 border-0`,
                                    buttonEvent: () => {
                                        setExpanded(!expanded)
                                    },
                                    ImgSrc: () => expanded ? <ICON_EXITFULLSCREEN/> : <ICON_FULLSCREEN/>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                            <Buttons
                                props={{
                                    buttonText: "",
                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon border-0`,
                                    buttonEvent: () => {
                                        onDrawerClose()
                                    },
                                    ImgSrc: () => <ICON_CLOSE/>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                        </div>
                    </div>
                } 
                onClose={onDrawerClose} 
                open={openDrawer}
                rootClassName='unst-quick-preview-drawer'
                closable={false}
                width={expanded ? "100%" : "50%"}
               
            >
                <div className='d-block bg-light h-100 w-100 position-absolute' style={{top: 0, left: 0, zIndex: loading || error ? 0 : -1}}>

                    {
                        loading ? <ClaristaLoader/>
                        :
                        error
                        ?
                        <>
                            <div className='d-flex flex-column align-items-center h-100 justify-content-center'>
                                {getReloadErrorTemplate({
                                    errorMessage: error,
                                    hideReloadBtn: true,
                                    wrapClass: 'w-100'
                                })}
                            </div>
                        </>
                        :   ''
                    }
                </div>
                <iframe
                    title={urlResponse}
                    src={urlResponse}
                    style={{
                        height: 'calc(100% - 10px)', 
                        width: '100%', 
                        border: 'solid 1px #dcdcdc', 
                        borderRadius: '6px'
                    }}
                    onLoad={() => setLoading(false)}
                />
            </Drawer>
        </div>
    )
}

export default UnstructuredFileName