import { combineReducers } from "redux";
import Analysis from "./analysis/analytsisReducer";
import Dashboard from "./dashboard/dashboardReducer"


const analyticsReducer = combineReducers({
    Analysis,
    Dashboard
});



export default analyticsReducer