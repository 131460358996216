import { Popover } from 'antd'
import React, { useMemo } from 'react'
import { ICON_ARROW_RIGHT, ICON_CATALOG_DOMAIN, ICON_FILTER } from '../../../Common/newIconSource'
import CustomDropdownContent from '../../../Common/CustomDropdownContent'
import { useDispatch } from 'react-redux'
import { FILTERICON } from '../../../Common/iconSource'

const ActionDropdown = ({
    changeTab = false,
    popoverWrapperClass = '',
    activeModule = 'pod',
    setselectedAction = '',
    selectedAction=''
}) => {
    const actionsType = [
        'LOGGED IN',
        'LOGGED OUT',
        'CREATED',
        'UPDATED',
        'DELETED',
        'EXECUTED',
        'READ'
    ]
    const mod = {
        pod: 'pod',
        flows: 'flow',
        scheduler: 'scheduler',
        dashboard: 'dashboard',
        chart: 'chart',
        connection: 'connection',
        domain: 'data_domain',
        user: 'user',
        usergroup: 'group',
        openAi: 'control_centre',
        downloaded: 'download',
    }

    const dispatch = useDispatch()


    const [menuList, setMenuList] = React.useState([])
    const [searchDomain, setSearchDomain] = React.useState('')



    const actionListSet = (activeModule) => {
        let actions = []
        let menuList = [{
            label: 'ALL',
            tooltip: 'ALL',
            key: 'all',
            active: true,
            disabled: false,
            data: {}
        }]
        switch (activeModule) {
            case mod.pod:
                actions = actionsType?.slice(2, 5)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break;

            case mod.flows:
                actions = actionsType?.slice(2, 6)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break

            case mod.scheduler:
                actions = actionsType?.slice(2, 6)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break

            case mod.dashboard:
                actions = actionsType?.slice(2, 5)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break
            case mod.chart:
                actions = actionsType?.slice(2, 5)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break
            case mod.openAi:
                actions = actionsType?.slice(2, 5)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break
            case mod.connection:
                actions = actionsType?.slice(2, 5)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break

            case mod.usergroup:
                actions = actionsType?.slice(2, 5)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])

                break
            case mod.downloaded:
                actions = actionsType?.slice(2, 5)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break
            case mod.domain:
                actions = actionsType?.slice(2, 5)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break
            case mod.user:
                actions = actionsType?.slice(0, 6)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])

                break
            default:
                actions = actionsType?.slice(2, 5)?.map((item) => (
                    {
                        label: item.toUpperCase(),
                        tooltip: item.toUpperCase(),
                        key: item,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ))
                setMenuList([...menuList, ...actions])
                break

        }
    }

    
    useMemo(() => {
        if (selectedAction === 'all') {
            setMenuList(prev => {
                prev = prev?.map(m => ({ ...m, active: 'all' === m?.key }))
                return prev
            });
        }
    }, [selectedAction])

    useMemo(() => {

        actionListSet(activeModule)

    }, [changeTab, activeModule])

    const items = React.useMemo(() => {
        let list = [...menuList]
        list = list?.filter(d => d?.label?.toLowerCase()?.includes(searchDomain?.toLowerCase()))
        return list
    }, [menuList, searchDomain])

    return (
        <Popover
            trigger={'hover'}
            title={''}
            destroyTooltipOnHide
            content={
                <CustomDropdownContent
                    items={items ?? []}
                    searchValue={searchDomain}
                    setSearchValue={setSearchDomain}
                    emptyDescription='No Action'
                    searchPlaceholder='Search Action...'
                    onItemClick={(itm) => {
                        setselectedAction(itm.key);


                        setMenuList(prev => {
                            prev = prev?.map(m => ({ ...m, active: itm?.key === m?.key }))
                            return prev
                        });

                    }}

                />
            }
            onOpenChange={(visible) => {
                if (!visible) setSearchDomain("")
            }}
            placement='bottom'
            arrow={false}
            rootClassName={`nusg-domain-ddl ${popoverWrapperClass}`}
        >
            <label className='label'>Select Action</label>
            <div className='d-flex cursor-pointer align-items-center nusg-dropdown-selected' style={{ width: '11.25rem' }}>
                <div className='nusg-dropdown-ic' style={{ background: '#FFE6CC' }}>
                    <ICON_FILTER height='20' width='20' color='#565656' />
                </div>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <span className='fontSizeHeading nusg-dropdown-label text-with-ellipsis'
                        title={menuList?.find(m => m?.active)?.label ?? "Select Domain"}
                        style={{ width: '7.25rem' }}>
                        {
                            menuList?.find(m => m?.active)?.label ?? "Select Domain"
                        }
                    </span>
                    <span className='bx-rotate-90 ml-2'>
                        <ICON_ARROW_RIGHT height='10' width='10' />
                    </span>
                </div>
            </div>
        </Popover>
    )
}

export default ActionDropdown