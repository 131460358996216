import React from 'react'
import LandingpageFirstHeader from '../../../modules/common/LandingpageFirstHeader'
import './pulseLandingPage.scss'
import '../../../../../src/assets/scss/media/pulse_media.scss'
import PulseCompanyLogo from './PulseCompanyLogo'
import PulseSearchBox from './PulseSearchBox'
import { LandingContentTabs } from './LandingContentTabs'
import { ICON_CATALOG_DOMAIN, ICON_CHAT, ICON_DASHBOARD, ICON_GROUP, ICON_REPORT, ICON_TAG, ICON_TAG_GROUP, ICON_TRACK } from '../../newIconSource'
import SearchInput from '../../search/Search'
import ChatGridViewIndex from '../../../modules/genAi/components/ChatGridViewIndex'
import ReportsGridViewIndex from '../../../modules/genAi/components/ReportsGridViewIndex'
import { getUserSelectedDomain, setSidebarActiveRoute, setUserSelectedDomain } from '../../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import DashboardGridListIndex from '../../claristaNewLandingPage/DashboardGridListIndex'
import useHideSidebar from '../../../../customHooks/useHideSidebar'
import { useLocation, useNavigate } from 'react-router-dom'
import { Literals } from '../../../modules/common/literals'
import { FILLEDSTAR, REPORT_ICON, STAR } from '../../iconSource'
import Buttons from '../../button/Buttons'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import TrackerIndex from '../../../modules/genAi/components/TrackerIndex'
import { getOpenAiStatus, setUpdatedUserData, setUser } from '../../../../store/userAuthentication/userActions'
import LandingPageTag from '../../../modules/dataCatalogue/landingPage/components/LandingPageTag'
import { Modal } from 'reactstrap'

const PulseLandingPage = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const history = useNavigate()

    const [searchTerm, setSearchTerm] = React.useState('') // for data search
    const [questValue, setQuestValue] = React.useState('')
    const [activeTabKey, setActiveTabKey] = React.useState('')
    const [activeDomain, setActiveDomain] = React.useState('')
    const [isDomainFailed, setDomainFailed] = React.useState(false)
    const [showFavourite, setshowFavourite] = React.useState(false)
    const [noDomainAccess, setNoDomainAccess] = React.useState(false)
    const [groupByTags, setGroupByTags] = React.useState(true)
    const [openTags, setOpenTags] = React.useState(false)

    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)
    let user = useSelector((state) => state.UserReducer?.user)

    const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

    React.useEffect(() => {
        dispatch(setSidebarActiveRoute('/clarista-pulse'))
    }, [])

    React.useEffect(() => {
        if (location?.hash) {
            let hashParam = location.hash?.replace('#', '')
            if(noOpenAiAccess && ['trackers', 'chats']?.includes(hashParam)) {
                setActiveTabKey('dashboards')
            }
            else if (hashParam) {
                setActiveTabKey(hashParam)
            }
        }
        else {
            if(noOpenAiAccess) {
                setActiveTabKey('dashboards')
            }
            else {
                setActiveTabKey('trackers')
            }
        }
    }, [location])

    const tabsMenuList = [
        {
            key: 'trackers',
            label: <span> <ICON_TRACK /> Trackers </span>,
            disabled: noDomainAccess || noOpenAiAccess
        },
        {
            key: 'chats',
            label: <span> <ICON_CHAT /> TALKdata </span>,
            disabled: noDomainAccess || noOpenAiAccess
        },
        {
            key: 'dashboards',
            label: <span> <ICON_DASHBOARD /> Dashboards </span>,
            disabled: noDomainAccess
        },
        {
            key: 'reports',
            label: <span> <REPORT_ICON color='#00000' /> Reports </span>,
            disabled: noDomainAccess
        }
    ]

    useHideSidebar(true)

    React.useEffect(() => {
        const locationDomainId = location?.state?.domainId
        if (locationDomainId) {
            setActiveDomain(locationDomainId)
            dispatch(setUserSelectedDomain({ data_domain_id: locationDomainId }, false))
                .catch(err => console.error(err?.message))
        }
        else {
            dispatch(getUserSelectedDomain())
                .then(res => {
                    const domain = res?.data?.domain ?? undefined
                    if (domain && domain?.id) {
                        setActiveDomain(domain?.id)
                    }
                    else if (domain) {
                        setActiveDomain(domain?.id ?? 0)
                    }
                    else {
                        setNoDomainAccess(true)
                    }
                })
                .catch(err => {
                    setDomainFailed(true)
                    console.error(err?.message)
                })
    
                dispatch(getOpenAiStatus())
                .then(res => {
                    let openai_data = res?.data
                    let details = {...user}
                    details.userDetails = {...user.userDetails, openAi: openai_data}
                    dispatch(setUser(details))
                    setUpdatedUserData({...details.userDetails, openAi: openai_data})
                })
                .catch(err => {
                    console.error({err})
                    let details = {...user}
                    details.userDetails = {...user.userDetails, openAi: null}
                    dispatch(setUser(details))
                    setUpdatedUserData({...details.userDetails, openAi: null})
                })
        }
    }, [])

    React.useEffect(() => {
        setshowFavourite(false)

        if(activeTabKey === 'chats') {
            const pulseSearchElement = document?.querySelector('#pulseTalkSearchInp')
            if(pulseSearchElement) {
                pulseSearchElement.focus()
            }
        }
    }, [activeTabKey])

    const getActiveTabLayout = () => {
        switch (activeTabKey) {
            case 'chats':
                return <>
                    <ChatGridViewIndex
                        activeDomain={activeDomain}
                        searchTerm={searchTerm} 
                        showFavourite={showFavourite}
                    />
                </>
            case 'reports':
                return <>
                    <ReportsGridViewIndex
                        activeDomain={activeDomain}
                        searchTerm={searchTerm}
                        showFavourite={showFavourite}
                    />
                </>
            case 'dashboards':
                return <>
                    <DashboardGridListIndex
                        activeDomain={activeDomain}
                        searchTerm={searchTerm}
                        showFavourite={showFavourite}
                        groupByTags={groupByTags}
                    />
                </>
            case 'trackers':
                return <>
                    <TrackerIndex
                        activeDomain={activeDomain}
                        searchTerm={searchTerm}
                    />
                </>

            default:
                return <></>
        }
    }

    const handleQuestion = () => {

        if (questValue?.trim() === '') {
            return
        } 
        if(!activeDomain || activeDomain === '' || activeDomain === undefined || activeDomain === null) {
            emitToastNotification('info', 'Please Select Domain To Proceed')
            return
        }

        history(Literals.links.PULSE_TALK + `?dm=${activeDomain}`, { state: { query: questValue } })
    }

    const displayUserName = () => {
        let name = userDetailsRedux?.first_name
        if(userDetailsRedux?.last_name)
        name += " " + userDetailsRedux?.last_name
        return name ?? 'Unknown User'
    }

    return (
        <div className='talk-index-main'>
            <LandingpageFirstHeader 
                title={'Pulse'} 
                isPulse={true} 
                activeDomain={activeDomain}
                showFeedNotification={true}
            />
            <div className='pulse-landing-wrap'>
                <section className='pulse-hero-sec'>
                    <div className='pulse-legend'>
                        <h2 className='mb-2'>Hello, <span className='fontInterSemiBold text-capitalize'>{`${displayUserName()}`}!</span></h2>
                        <p className='mb-4'>
                            With Pulse find what you're looking for quickly and accurately.
                        </p>
                        <div>
                            <PulseSearchBox
                                activeDomain={activeDomain}
                                setActiveDomain={setActiveDomain}
                                isDomainFailed={isDomainFailed}
                                setDomainFailed={setDomainFailed}
                                activeTabKey={activeTabKey}
                                searchValue={questValue}
                                setSearchValue={setQuestValue}
                                onSearch={handleQuestion}
                                onEnterCallback={handleQuestion}
                            />
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-end w-100' style={{height: '8rem'}}>
                            <PulseCompanyLogo 
                                activeDomain={activeDomain}
                            />
                        </div>
                    </div>
                </section>
                <section className='pt-3 bg-white position-sticky' style={{ top: -22, zIndex: 1 }}>
                    <h4 className="fontInterSemiBold h6 text-dark relevant-list-head">Relevant list for you</h4>
                    <div className='d-flex align-items-center justify-content-between pulse-land-tabs' style={{ borderBottom: 'solid 1px #D9D9D9' }}>
                        <div style={{ flex: '0 0 470px' }}>
                            <LandingContentTabs
                                setSearchTerm={setSearchTerm}
                                tabItems={tabsMenuList}
                                activeTabKey={activeTabKey}

                                setActiveTabKey={setActiveTabKey}
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                            {
                                ['dashboards']?.includes(activeTabKey)
                                ?
                                <>
                                    <Buttons
                                        props={{
                                            buttonId: 'tagLiBtn',
                                            tooltip: "Tags",
                                            tooltipPlacement: "top",
                                            buttonText: "",
                                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2`,
                                            buttonEvent: () => { setOpenTags(true) },
                                            ImgSrc: () => <ICON_TAG />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                    <Modal
                                        size="md"
                                        isOpen={openTags}
                                        className="custom-modal"
                                        centered={true}
                                    >
                                        <LandingPageTag 
                                            onClose={() => setOpenTags(false)} 
                                            domainId={activeDomain}
                                        />
                                    </Modal>
                                    <Buttons
                                        props={{
                                            tooltip: !groupByTags ? "Group By Tags" : "Ungroup",
                                            tooltipPlacement: "top",
                                            buttonText: "",
                                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2
                                                ${groupByTags ? 'rdg-filter-active' : ''}
                                            `,
                                            buttonEvent: () => { 
                                                setGroupByTags(!groupByTags)
                                            },
                                            ImgSrc: () => <ICON_TAG_GROUP />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                </>
                                :   ''
                            }

                            {['reports', 'chats','dashboards']?.includes(activeTabKey) ? <Buttons
                                props={{
                                    tooltip: showFavourite ? "Hide Favourite" : "Show Favourite",
                                    tooltipPlacement: "top",
                                    buttonText: "",
                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2  ${showFavourite ? 'active' : ''}`,
                                    buttonEvent: () => { setshowFavourite(!showFavourite) },
                                    ImgSrc: () => showFavourite ? <FILLEDSTAR /> : <STAR
                                    />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            /> : null}
                            <SearchInput
                                placeholder='Search here...'
                                searchData={searchTerm}
                                setSearchData={setSearchTerm}
                                isDisable={noDomainAccess}
                            />

                        </div>
                    </div>
                </section>
                <section className='pt-2'>
                    {
                        noDomainAccess
                        ?
                        <div style={{height: 'calc(100vh - 312px)'}}
                            className='align-items-center d-flex flex-column justify-content-center text-black-50'
                        >
                            <ICON_CATALOG_DOMAIN height='60' width='60' color='currentColor'/>
                            <label className='fontInterSemiBold fontSizeLargeHeading mt-3'>
                                No domain access provided. Contact admin.
                            </label>
                        </div>
                        :
                        getActiveTabLayout()
                    }
                </section>
            </div>
        </div>
    )
}

export default PulseLandingPage