
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Buttons from '../../../Common/button/Buttons'
import { ALERTBELL, CLOSE } from '../../../Common/iconSource'
import { Literals } from '../../common/literals'

function ExceptionErrorLogModal({ openFailureModal, setOpenFailureModal, errorLogs, logDetails }) {
   
    return (
        <div>
            {/* <Modal size="xs" isOpen={openFailureModal}  style={{minHeight:'200px',maxHeight:'300px'}} className="drop-modal datarule-modal">
                <ModalBody>
                    <div className="modal-heading">
                        <p>Logs</p>
                        <button className="close" onClick={() => setOpenFailureModal(false)}><i className="bx bx-x"></i></button>
                    </div>
                    <div className="error-log" style={{minHeight:'200px',maxHeight:'300px',overflow:"auto"}}>
                        <p>{errorLogs}</p>
                    </div>
                </ModalBody>
            </Modal> */}
            <Modal size="lg" isOpen={openFailureModal} className="custom-modal" centered={true} onClosed={() => setOpenFailureModal(false)}>
                <ModalHeader close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenFailureModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className='d-flex align-items-center'>
                            <ALERTBELL />
                            <h2 className="section-heading">Alert Logs : {errorLogs?.allData?.rule_detail?.name}</h2>
                        </div>
                        <div className='mb-n1 mr-3 small'>
                            <label className='d-block mb-0 small text-right' style={{ lineHeight: 0,color:'red' }}>Status: Failed</label>
                            <label className='mb-0 small text-black-50 mt-2 d-block text-right'>{errorLogs?.allData?.execution_by_user?.email}</label>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='usage-drawer'>
                        <div>
                            <div className='py-2'>
                                <div className='row mx-n2'>


                                    <div className='col-12 h-100'>

                                        <div>
                                            <p>
                                                {errorLogs?.error_message}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ExceptionErrorLogModal
