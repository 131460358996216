
import React from 'react';
import {  useParams ,useLocation} from 'react-router-dom';

import LandingpageFirstHeader from "../../common/LandingpageFirstHeader";
import GraphPage from './GraphPage';
const ViewLineage=()=>{


    const {model,pk}=useParams();
    const { search } = useLocation();
    let query=new URLSearchParams(search);
    let connection_name=query.get("connection_name")



    return(
        <>
        <LandingpageFirstHeader title={"Lineage"}
          flag={false}
          backRoute={"/lineage"}/>
        {/* <TreePage pk={pk} model={model} setActivePage={()=>{}} record={{}} /> */}
        <GraphPage pk={pk} model={model} connectionName={connection_name}/>
        </>
        )


}


export default ViewLineage;