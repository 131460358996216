import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useStore } from "react-redux"
import logoImage from '../../../../assets/images/new-logo-2.png'
import star from '../../../../assets/icons/Star.svg'
import Buttons from '../../../Common/button/Buttons'

import { Literals } from '../../common/literals'
import { CLOSE, SAVE, HAMBURGER, DELETEICON, DUPLICATE, EDIT, PLAY, FILLEDSTAR, STAR, LINEAGE_ICON, RENAME, CLOCK } from '../../../Common/iconSource'
import { toggleSidebarCollapse } from '../../../../store/layout/actions';
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { useParams } from 'react-router';
import { editFlowNameApiCall, EditFlowsAPI, executeEntireFlow, executeTransformerApi, fetchFlowsDetails, generateFlowIDApi, getFlowDetails, RetriveExecutionFlows, saveFlow, updateFlowsBasicDetails, updateFlowsElements } from '../../../../store/modules/flows/flowsActions'
import ActionPopUp from '../../common/actionPopUp'
import { Tooltip } from 'antd'

import { createBookmark } from '../../../../store/modules/flows/landingPage/landingPageAction'
import { SAVE_FLOW_CLICK, SET_ENTIREFLOW_EXECUTE_START, SET_TRANSFORMER_PROGRESS } from '../../../../store/modules/flows/flowsTypes'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { DeleteNoteContent, getConfirmationModal, getMonthName, getRelativeTime, getTime } from '../../common/helperFunctions'
import NewFlowsPopUp from './NewFlowsPopUp'
import { ICON_MENU } from '../../../Common/newIconSource'
import { cloneDeep } from 'lodash'


const Header = (props) => {
  const { flowName,
    transformerProgress,
    isViewMode,
    SQLEditMode,
    isEditMode,
    showJupyterNoteBook,
    isEditPermission = true,
    isDeletePermission = true,
    isExecutePermission = true,
    showDatabricksNotebook
  } = props
  const searchParams = new URLSearchParams(document.location.search)

  const history_id = searchParams.get("history_id");
  const versionHistory = JSON.parse(localStorage.getItem("versionHistory"));
  const history = useNavigate()
  const dispatch = useDispatch();
  const [editFlowName, seteditFlowName] = useState(false)
  const [newFlowName, setnewFlowName] = useState(flowName)
  const [bookmark, setbookmark] = useState(false)
  const [disableExecuteFlow, setdisableExecuteFlow] = useState(false)
  const { flowId } = useParams()
  const [saveFlowTime, setsaveFlowTime] = useState();

  const [flowsExecutionState, setflowsExecutionState] = useState('');
  const [openModal, setOpenModal] = useState(false)

  let RetriveInterval = useRef()
  const store = useStore()


  useEffect(() => {
    setbookmark(store.getState().FlowsReducer.bookmark)
  }, [flowName])

  const currentConditionTypeRef = React.useRef({ current: {} })


  useEffect(() => {
    // if (editFlowName) {
    setnewFlowName(store.getState().FlowsReducer.name)
    // }
  }, [openModal])
  const toggleSidebarHandler = () => {
    dispatch(toggleSidebarCollapse());
  }
  const saveFlowApi = (btnType) => {

    dispatch({
      type: SAVE_FLOW_CLICK,
      payload: true
    })
    setTimeout(() => {
      dispatch(saveFlow(flowId, true)).then((resp) => {
        if (resp) {
          if (btnType === Literals.BUTTON_UPDATE) {
            emitToastNotification('success', Literals.FLOW_UPDATE_SUCCESSFULL)
            history(`${Literals.links.FLOWS_VIEW}${flowId}`)
          } else {
            history(`${Literals.links.CLARISTA_LAB}#flows`)

          }
        }
      })
    }, 500);

  }
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);

  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };
  const [openWarningModal, setOpenWarningModal] = useState(false)
  const openSaveChangesWarning = () => {

    currentConditionTypeRef.current = {
      message: 'Unsaved changes will be lost. Are you sure, want to continue?',
      onConfirm: () => {
        dispatch(EditFlowsAPI({ ApiType: "DELETE", flow_Id: flowId })); history(`${Literals.links.CLARISTA_LAB}#flows`)
        setOpenWarningModal(false);
        return true
      },
      onCancel: () => {
        setOpenWarningModal(false);
        return false
      }
    }


    if (isEditMode) {

      setOpenWarningModal(true);
    } else {
      dispatch(EditFlowsAPI({ ApiType: "DELETE", flow_Id: flowId })); history(`${Literals.links.CLARISTA_LAB}#flows`)
    }
  };

  const deleteFlow = async (id) => {
    setOpenDeletePopup(false);
    try {
      dispatch(fetchFlowsDetails({ method: "DELETE", endPoint: `flow/${id}` })).then((resp) => {
        if (resp.status === 'success') {
          history(Literals.links.FLOWS)
        }
      })
    } catch (error) { }

  }

  const bookmarkData = (id, bookmarkStatus = false) => {

    store.dispatch(createBookmark(id, bookmark)).then((resp) => {
      if (resp.status === 'success') {
        setbookmark(!bookmark)
      }
    })
  }

  const getFlowDetailsHandle = () => {

    store.dispatch(getFlowDetails(flowId)).then((resp) => {
      if (resp && resp.data) {
        let responseData = resp.data
        let obj = { name: responseData.name, description: responseData.description, id: responseData.id, bookmark: responseData.bookmark }
        store.dispatch(updateFlowsBasicDetails(obj))

      }
    })

  }

  const executeCompleteFlow = () => {
    let payload = {
      "dag": {
        nodes: [],
        edges: []
      }
    }
    let newFlowsElements = cloneDeep(store.getState().FlowsReducer.flowsElements);
    let edges = []
    let nodes = []
    let NodeDag = {}
    if (newFlowsElements?.length) {
      newFlowsElements.forEach((item) => {
        if (!item.id.includes('reactflow__edge')) {
          NodeDag = {
            Id: item.transformerName,
            Args: [],
            NodeType: item.type === 'script' ? 'nb_python3' : item.type,
            currentTransformerID: item.id
          }
          if (item.content.executed_DAG && item?.content?.executed_DAG?.Id) {
            item.content.executed_DAG['Id'] = item.transformerName
            nodes.push(item.content.executed_DAG)
          } else {

            nodes.push(NodeDag)
          }
        } else if (item.id.includes('reactflow__edge')) {
          edges.push({ source: item.source, destination: item.target })
        }

      })
    }

    payload['dag']['nodes'] = nodes
    payload['dag']['edges'] = edges
    if (nodes?.length) {
      setdisableExecuteFlow(true)
      store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: true })
      dispatch(executeEntireFlow(flowId, payload)).then((res) => {
        if (res?.status === 'success' && res?.data) {
          store.dispatch({ type: SET_TRANSFORMER_PROGRESS, payload: [] })
        }
      }).catch((e) => {
        setdisableExecuteFlow(false)

      })
    } else {
      emitToastNotification('warning', 'No any transformer present on canvas')
    }


  }

  useEffect(() => {
    if (transformerProgress?.length) {
      let flowsExecutionState = transformerProgress.find((ele) => ele.transformer === "__all__")?.state;

      if (flowsExecutionState && (flowsExecutionState?.toLowerCase() === 'done' || flowsExecutionState?.toLowerCase() === 'success' || flowsExecutionState?.toLowerCase() === 'aborted' || flowsExecutionState?.toLowerCase() === 'error' || flowsExecutionState?.toLowerCase() === 'failed')) {

        store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false })

        setdisableExecuteFlow(false)

        // store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false })

      }
    } else {
      // store.dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false })

      // setdisableExecuteFlow(false)

    }

  }, [transformerProgress])


  useEffect(() => {
    let interval = null
    console.log({ 'isViewMode': isViewMode, '!isViewMode': !isViewMode })
    if (!isViewMode) {
      interval = setInterval(() => {
        store.dispatch(saveFlow(flowId, false)).then((res) => {
          setsaveFlowTime(new Date().toISOString())
        })
      }, 120000);
    }

    return () => {
      clearInterval(interval);
    }
  }, [])

  const editFlowNameHandle = () => {
    setOpenModal(true)

  }


  return (
    <div className="flow-header" style={history_id ? { filter: 'grayscale(1)', paddingTop: '5px' } : {}}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className="hamburger" onClick={toggleSidebarHandler}>
            {/* <ICON_MENU /> */}
            <HAMBURGER />
          </div>
          <img className="clarista-logo" src={logoImage} alt="Clarista" />
          <h3 className="section-heading">Flows</h3>
          {!editFlowName ? <p className="subtitle">{newFlowName}</p> :
            <input type='text' className='form-control' value={newFlowName} onChange={(e) => setnewFlowName(e.target.value)}></input>
          }
          {
            editFlowName ? ''
              :
              <Buttons
                props={{
                  tooltip: "Rename Flow",
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon mx-2",
                  buttonEvent: (e) => {
                    e.stopPropagation()
                    if (!isEditPermission) return
                    editFlowNameHandle();
                  }, ImgSrc: () => <RENAME />,
                  isDisable: !isEditPermission,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }}
              />
          }

          <Tooltip title={bookmark ? Literals.REMOVE_FAVOURITES : Literals.ADD_FAVOURITES}><button className={bookmark ? 'custom-btn custom-btn-outline table-icon  pb-1 cursor-pointer filled-star-icon' : 'custom-btn custom-btn-outline table-icon pb-1 cursor-pointer star-icon'}
            onClick={(e) => { bookmarkData(flowId); e.stopPropagation() }}>
            {bookmark ? <FILLEDSTAR /> : <STAR />}
          </button></Tooltip>
        </div>
        <div className="d-flex align-items-center">
          {
            history_id ?
              <div style={{ color: "white", display: "table-cell", gap: '10px' }} className="align-items-center d-flex flex-row-reverse h-100 mr-3" >

                <p className="align-items-center d-block d-flex label p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", margin: "0", background: '#ababab' }}>
                  <i className="mr-1 bx bxs-calendar-alt"></i>
                  {versionHistory?.log_time === null ? '' : new Date(versionHistory?.log_time).getUTCDate()}
                  {versionHistory?.log_time === null ? '' : ' ' + getMonthName.short[new Date(versionHistory?.log_time).getUTCMonth()]}
                  {versionHistory?.log_time === null ? '' : ' ' + new Date(versionHistory?.log_time).getUTCFullYear()}
                </p>
                <p className="align-items-center d-flex font-w-600 mb-0 p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", textAlign: "end", margin: "0", background: '#ababab' }}>
                  <i className="mr-1 bx bxs-time"></i>{versionHistory?.log_time === null ? '00:00' : getTime(versionHistory?.log_time)}
                </p>
                <p className="align-items-center d-flex font-w-600 mb-0 p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", textAlign: "end", margin: "0", background: '#ababab' }}>
                  <i className="mr-1 bx bxs-purchase-tag"></i>{`version: ${versionHistory?.log_id}`}
                </p>
              </div> : ""
          }
          {saveFlowTime && !isViewMode ? <span className='label label-gray text-small mr-2'> <CLOCK color='#828282' height='15' width='15' /> {saveFlowTime ? getRelativeTime(saveFlowTime) : ''}</span> : null}
          {isViewMode ? <Buttons
            props={{
              tooltip: `Show Lineage`,
              buttonText: 'Lineage',
              buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon",
              buttonEvent: () => {


                let path = `/viewLineage/Flow/${flowId}`;

                window.open(path)

              },
              ImgSrc: () => <LINEAGE_ICON />,

              buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
            }}

          /> : null}

          {isViewMode ? <Buttons props={{ buttonText: '', tooltip: "Execute Flow", buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { executeCompleteFlow() }, ImgSrc: () => <PLAY />, isDisable: !isExecutePermission || disableExecuteFlow, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}
          {isViewMode ? <Buttons props={{
            tooltip: "Edit Flow", buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {
              dispatch(EditFlowsAPI({ ApiType: "GET", flow_Id: flowId })).then((res) => {
                if (res.status === 'success') {
                  history(`${Literals.links.FLOWS_EDIT}${flowId}`, { replace: true })
                }
              });
            }, ImgSrc: () => <EDIT />, isDisable: !isEditPermission || disableExecuteFlow, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
          }} /> : null}
          {!isViewMode && !showJupyterNoteBook && !SQLEditMode && !showDatabricksNotebook ? <Tooltip title="Save Flow"> <Buttons props={{ buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { saveFlowApi(Literals.BUTTON_UPDATE) }, ImgSrc: () => <SAVE />, isDisable: !isEditPermission, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /></Tooltip> : null}
          {!showJupyterNoteBook && !showDatabricksNotebook ? <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2", tooltip: "Close Flow", tooltipPlacement: "left", buttonEvent: () => { openSaveChangesWarning() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} /> : null}

        </div>
      </div>
      {
        getConfirmationModal({
          open: openWarningModal,
          setOpen: setOpenWarningModal,
          body: currentConditionTypeRef?.current?.message,
          onConfirm: currentConditionTypeRef?.current?.onConfirm,
          onCancel: currentConditionTypeRef?.current?.onCancel
        })
      }
      <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent} />

      <NewFlowsPopUp openModal={openModal} setOpenModal={setOpenModal} editFlowName={true} CurrentflowName={store.getState().FlowsReducer.name} flowDesc={store.getState().FlowsReducer.description} flowId={flowId} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    flowName: state.FlowsReducer.name,
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    transformerProgress: state.FlowsReducer.transformerProgress,
    SQLEditMode: state.FlowsReducer.SQLEditMode
  };
};
export default connect(mapStateToProps)(Header)