import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from "reactstrap";
import { Literals } from "../../../common/literals";

import "toastr/build/toastr.min.css";
import { CREATED_BY, updated_on, TAG, UPDATED_BY, CREATED_ON } from "../../../../Common/iconSource";

import { getRelativeTime } from "../../../common/helperFunctions";
import SourceTypeImage from "../../../common/sourceTypeImage";

const DataDomainPODGrid = ({ podDetails, selectedRulesPODList }) => {
  const history = useNavigate();
  const tagList = useSelector(state => state.DataCatalogue?.LandingPage?.tagList);
  const [, setselectedPODList] = useState([])
  const [showPopUp, ] = useState(false)

  


  useEffect(() => {
    setselectedPODList([...selectedRulesPODList])
  }, [selectedRulesPODList])


  

  const sourcetyprfn = (dataname) => {
    return <SourceTypeImage dataName={dataname} />
  }
  

  useEffect(() => {
    if (showPopUp) {
     
    }
  }, [showPopUp, podDetails, tagList])

  

  return (
    <div key={podDetails[0]} className="h-100 position-relative">
      <Card className="h-100" onClick={(e) => { e.preventDefault(); e.stopPropagation(); history(Literals.links.DATA_CATALOG_POD_DETAILS + podDetails.table_id) }}>
        <CardBody>
          <div className="landingCard-circle">
            <div className="icon-box">
              {sourcetyprfn(podDetails.source_type)}
            </div>
          </div>
          <div className="landingCard-header">
            <Tooltip placement="topLeft"  title={podDetails.table_name.toUpperCase()}><p style={{fontSize:'13px'}} className="section-heading custom-ellipsis one-line-ellipsis">{podDetails.table_name.toUpperCase()}</p></Tooltip>
          </div>
          <div className="landingCard-description">
            <p  className="paragraph m-0 text-with-ellipsis">
            {/* <p  className="paragraph m-0 custom-ellipsis two-line-ellipsis"> */}
              <Tooltip placement="topLeft" title={podDetails.pod_description}>
                {podDetails.pod_description}
              </Tooltip>
            </p>
          </div>
          <ul className="landingCard-profile-list">
            <li className="landingCard-profile-listitem">
              <CREATED_ON />
              <span className="label">{Literals.UPDATED_ON} </span>
              <Tooltip placement="top" title={getRelativeTime(podDetails.updated_on)}>
                <span className="paragraph">{getRelativeTime(podDetails.updated_on)}</span>
              </Tooltip>
            </li>
            <li className="landingCard-profile-listitem">
              <CREATED_BY />
              <span className="label">{Literals.CREATED_BY}</span>
              <Tooltip placement="top" title={podDetails.created_by_user.email}>
                <span className="paragraph">{podDetails.created_by_user.email}</span>
              </Tooltip>
            </li>

            <li className="landingCard-profile-listitem">
              <UPDATED_BY />
              <span className="label">{Literals.UPDATED_BY} </span>
              <Tooltip placement="top" title={podDetails.updated_by_user.email}>
                <span className="paragraph">{podDetails.updated_by_user.email}</span>
              </Tooltip>
            </li>
          </ul>
          <div className="round-tags-container">
          {
                  podDetails?.tag?.map((v, i)=>{
                  
                    return <>
                        {
                            i<2
                            ?
                            <div key={`table_tag-ls-${i}`} className={`${v?.color} round-tags-item`}>
                              <span>{<TAG />}</span>
                              <Tooltip placement="top" title={v?.name}>
                                <span className="label">{v?.name}</span>
                              </Tooltip>
                            </div> 
                            : ''
                        }  
                        {
                            i === 2
                            ?
                            <div key={`table_tag-ls-${i}`} className={`${v?.color} round-tags-item`}>
                              <span>{<TAG />}</span>
                              <Tooltip placement="top" title={podDetails?.tag?.map(t=>t.name).slice(2).join(',')}>
                                <span className="label">+{podDetails?.tag?.length-2}</span>
                              </Tooltip>
                            </div>
                            : ''
                        }
                    </>
                })
            }
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
export default DataDomainPODGrid;
