import React, { useState, useEffect } from "react";
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import Buttons from "../../Common/button/Buttons";
import { CLOSE, DELETEICON, SAVE } from "../../Common/iconSource";
import "./actionPopUp.scss";
import { Literals } from "./literals";

const ActionPopUp = ({
  openModal,
  actionData,
  inputValueState,
  setinputValueState,
  modalSize
}) => {
  
  const [] = useState(openModal);
  useEffect(() => { }, [inputValueState]);

  const updateInputState = (index, value) => {
    setinputValueState((inputValueState) => {
      const newInputValueState = [...inputValueState];
      newInputValueState[index] = value;
      return newInputValueState;
    })
  };

  return (
    <div>
      {actionData !== undefined ? (
        <Modal
          isOpen={openModal}
          centered
          size={modalSize?modalSize:"sm"} className={`${actionData.modalClass} custom-modal `}
        >
          <ModalHeader>
            <div className="d-flex align-items-center">
              {actionData.headingIcon ? (
                <i className={actionData.headingIcon}></i>
              ) : null}
              {actionData.heading ? (
                <h2 className={`section-heading ${actionData.headingIcon ? 'ml-1' : 'ml-0'}`} id="exampleModalLabel">
                  {actionData.heading}
                </h2>
              ) : null}
            </div>
            <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: actionData.closeActionPopup, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
          </ModalHeader>
          <form
            onSubmit={(e) => {
              e?.preventDefault()
            }}
          >
            <ModalBody>
              {actionData.message ? (
                <h5 className="message mb-0">{actionData.message}</h5>
              ) : (
                <>
                  {actionData.elements.map((value, index) => (
                    <div key={index}>
                      {value.type.toLowerCase() === "input" ? (
                        <div className="form-group">
                          <label
                            htmlFor="recipient-name"
                            className="col-form-label"
                          >
                            {value.label}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={inputValueState[index] || ""}
                            id="recipient-name"
                            placeholder={value.placeholder}
                            onChange={(e) =>
                              updateInputState(index, e.target.value)
                            }
                          />
                        </div>
                      ) : null}

                      {value.type.toLowerCase() === "textarea" ? (
                        <div className="form-group">
                          <label htmlFor="message-text" className="col-form-label">
                            {value.label}
                          </label>
                          <textarea
                            className="form-control"
                            id="message-text"
                            value={inputValueState[index] || ""}
                            placeholder={value.placeholder}
                            onChange={(e) =>
                              updateInputState(index, e.target.value)
                            }
                          ></textarea>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {actionData.button.map((value, index) => (
                <div key={index}>
                  {value.name.toLowerCase() === "ok" ? (
                    <button
                      type="button"
                      className="custom-btn-primary custom-btn btn-with-text"
                      onClick={() => value.action(inputValueState)}
                    >
                      {value.name.toUpperCase()}
                    </button>
                  ) : null}
                  {value.name.toLowerCase() === "cancel" ? (
                    <button
                      type="button"
                      className="btn btn-disable"
                      onClick={value.action}
                    >
                      {value.name.toUpperCase()}
                    </button>
                  ) : null}
                  {value.name.toLowerCase() === "delete" ? (
                    <Buttons props={{
                      buttonText: value.title ?? 'delete', buttonClassName: "danger-btn custom-btn btn-with-text", buttonEvent: () => {
                        value.action();
                      },
                      ImgSrc: () => <DELETEICON />,
                      isDisable: false,
                      buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }}
                    />
                  ) : null}
                  {value.name.toLowerCase() === "continue" ? (
                    <button
                      type="button"
                      className="custom-btn-primary custom-btn btn-with-text"
                      onClick={value.action}
                    >
                      {value.name.toUpperCase()}
                    </button>
                  ) : null}
                  {value.name.toLowerCase() === "save" ? (
                    <Buttons props={{
                      actionType: 'submit',
                      buttonText: 'SAVE', buttonClassName: "custom-btn-primary custom-btn btn-with-text", 
                      buttonEvent: () => {
                        value.action();
                      },
                      ImgSrc: () => <SAVE />,
                      isDisable: value.isDisabled !== undefined ? value.isDisabled : false,
                      buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }}
                    />
                  ) : null}
                </div>
              ))}
            </ModalFooter>
          </form>
        </Modal>
      ) : null}
    </div>
  );
};

export default ActionPopUp;
