import React, { useEffect, useState } from 'react'
import Buttons from '../../../../../Common/button/Buttons'
import SingleSelection from '../../../../../Common/dropDown/SingleSelection'
import { Literals } from '../../../../common/literals'
import { MINUS } from '../../../../../Common/iconSource'
import { connect, useDispatch } from 'react-redux'
import { updateFlowsElements } from '../../../../../../store/modules/flows/flowsActions'
import { emitToastNotification } from '../../../../../../helpers/toast_helper'
import { getInputTransformerDetails } from '../../FlowsHelperFunction'
import { cloneDeep } from 'lodash'


const PrimaryKeySelection = (props) => {

    const dispatch = useDispatch()
    const { currentTransformer, flowsElements, swap, selectedPOD, setSelectedPOD } = props

    const [columnList, setColumnList] = useState(null)
    const [primaryKeysCollection, setprimaryKeysCollection] = useState([])




    const updateSelection = (selectedFor, selectedValue, collectionNumber, item, dropDown) => {
        let tempPrimaryKeysCollection = flowsElements.find(item => item.id === currentTransformer.id)?.content?.primaryKeysCollection



        let newtempPrimaryKeysCollection = tempPrimaryKeysCollection.map(
            item => {
                if (item.key === collectionNumber) {
                    let columnList1 = []
                    let columnList2 = []

                    columnList1 = !swap ? columnList[`${Object.keys(columnList)[0]}`] : columnList[`${Object.keys(columnList)[1]}`]

                    columnList2 = !swap ? columnList[`${Object.keys(columnList)[1]}`] : columnList[`${Object.keys(columnList)[0]}`]



                    let otherdropdownValueIndex = Object.keys(item).findIndex((item) => item?.toLocaleLowerCase() !== selectedFor?.toLocaleLowerCase() && item !== 'key')



                    let value1; let value2;

                    // if (index !== -1) {
                    value1 = columnList1.find((ele) => ele.Name?.toLowerCase() === (dropDown === 'selection1' ? selectedValue?.toLowerCase() : item[Object.keys(item)[otherdropdownValueIndex]]?.toLowerCase()))
                    value2 = columnList2.find((ele) => ele.Name?.toLowerCase() === (dropDown === 'selection2' ? selectedValue?.toLowerCase() : item[Object.keys(item)[otherdropdownValueIndex]]?.toLowerCase()))

                    // }
                    console.log(value1,value2,otherdropdownValueIndex,selectedValue)
                    if (value1?.Type && value2?.Type && value1?.Type.toLowerCase() === value2?.Type.toLowerCase()) {
                        item[`${selectedFor}`] = selectedValue
                    } else if (value1 && value2 && value1.Type.toLowerCase() !== value2.Type.toLowerCase()) {
                        emitToastNotification('warning', `Data type should be same for merge keys`)
                    }
                     else {
                        item[`${selectedFor}`] = selectedValue
                    }
                }
                return item
            }
        )

        let newflowsElements = flowsElements.map(
            item => {
                if (item.id === currentTransformer.id) {
                    item.content.primaryKeysCollection = newtempPrimaryKeysCollection
                }
                return item
            }
        )
        dispatch(updateFlowsElements([...newflowsElements]))
        setprimaryKeysCollection(newtempPrimaryKeysCollection)
    }



    useEffect(() => {
        let tempSelectedPOD = {}
        let tempColumns = {}
        let InputTransformer = dispatch(getInputTransformerDetails())

        if (InputTransformer.length > 0) {

            InputTransformer.forEach((element) => {
                let temp = []
                let transformerName = element.transformerName;


                let inputColumns = cloneDeep(element?.content?.outcomeColumns);
                if (inputColumns?.length) {
                    inputColumns.forEach((item) => {
                        temp.push(
                            {
                                id: item?.id,
                                Name: item.Name,
                                Type: item.Type,
                                Label: item.Name,
                                columnValue: item.Name,
                                transformerType: item.transformerType
                            }
                        )
                    })
                }

                tempSelectedPOD[`${transformerName}`] = ``
                tempColumns[`${transformerName}`] = temp
            })
            let tempContent = flowsElements.find(item => item.id === currentTransformer.id).content

            setColumnList(tempColumns)
            setSelectedPOD(tempSelectedPOD)


            if (!('selectedFirstSecond' in tempContent)) {
                let newSelectedPOD = JSON.parse(JSON.stringify(tempSelectedPOD))
                newSelectedPOD.key = new Date().getTime()
                if(!tempContent.primaryKeysCollection && !tempContent.primaryKeysCollection?.length){
                    tempContent.primaryKeysCollection = [];
                    tempContent.primaryKeysCollection.push(newSelectedPOD)
                }
                // tempContent.primaryKeysCollection = []
                // tempContent.primaryKeysCollection.push(newSelectedPOD)
                tempContent.selectedFirstSecond = { datasource0: `${Object.keys(tempSelectedPOD)[0]}`, datasource1: `${Object.keys(tempSelectedPOD)[1]}` }
                let newflowsElements = flowsElements.map(
                    item => {
                        if (item.id === currentTransformer.id) {
                            item.content = tempContent
                        }
                        return item
                    }
                )
                dispatch(updateFlowsElements([...newflowsElements]))
                console.log(tempContent.primaryKeysCollection)
                // setprimaryKeysCollection(tempContent.primaryKeysCollection)


            } else if ('selectedFirstSecond' in tempContent && (!Object.keys(tempSelectedPOD).includes(tempContent.selectedFirstSecond['datasource0']) || !Object.keys(tempSelectedPOD).includes(tempContent.selectedFirstSecond['datasource1']))) {
                let newSelectedPOD = JSON.parse(JSON.stringify(tempSelectedPOD))
                newSelectedPOD.key = 1
                if(tempContent.primaryKeysCollection && !tempContent.primaryKeysCollection?.length){
                    tempContent.primaryKeysCollection = []
                    tempContent.primaryKeysCollection.push(newSelectedPOD)
                }
                tempContent.selectedFirstSecond = { datasource0: `${Object.keys(tempSelectedPOD)[0]}`, datasource1: `${Object.keys(tempSelectedPOD)[1]}` }
                let newflowsElements = flowsElements.map(
                    item => {
                        if (item.id === currentTransformer.id) {
                            item.content = tempContent
                        }
                        return item
                    }
                )
                dispatch(updateFlowsElements([...newflowsElements]))
                console.log(tempContent.primaryKeysCollection)

                // setprimaryKeysCollection(tempContent.primaryKeysCollection)


            }

            if (tempContent?.primaryKeysCollection?.length) {
                setprimaryKeysCollection(tempContent.primaryKeysCollection)
            }

        }


    }, [currentTransformer])


    const filterPrimaryKeysCollection = (collectionNumber) => {
        let tempPrimaryKeysCollection = flowsElements.find(item => item.id === currentTransformer.id)?.content?.primaryKeysCollection

        let newtempPrimaryKeysCollection = tempPrimaryKeysCollection.filter(item => !(item.key === collectionNumber))

        let newflowsElements = flowsElements.map(
            item => {
                if (item.id === currentTransformer.id) {
                    item.content.primaryKeysCollection = newtempPrimaryKeysCollection
                }
                return item
            }
        )
        dispatch(updateFlowsElements([...newflowsElements]))


    }

    const isColumnValuePresent = (columnList, columnValue = '') => {

        if (columnList?.length && columnValue !== "") {
            let isPresent = columnList.findIndex(element => element.Name === columnValue);
            if (isPresent > -1) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    return (
        <>
            <div className='h-100 position-relative'>
                <div className="flows-options-grid mt-2">
                    {/* <div className="d-flex padding-top-2 padding-left-2 padding-right-40"> */}
                    {selectedPOD && Object.keys(selectedPOD).length !== 0 && <>  <label className="label custom-ellipsis one-line-ellipsis d-block mb-2">{
                        !swap ? Object.keys(selectedPOD)[0] : Object.keys(selectedPOD)[1]
                    }</label>
                        {`     `}
                        <label className="label custom-ellipsis one-line-ellipsis d-block mb-2">{
                            !swap ? Object.keys(selectedPOD)[1] : Object.keys(selectedPOD)[0]
                        }</label></>}
                </div>
                <div className='merge-flow-col-wrap'>
                    <div>
                        {
                            flowsElements.find(item => item.id === currentTransformer.id)?.content?.primaryKeysCollection !== undefined
                            && flowsElements.find(item => item.id === currentTransformer.id)?.content?.primaryKeysCollection.map(
                                item => (
                                    <div id="flows-merge-key-inputs" key={item.key} className="flows-options-grid mb-1">
                                        {/* <div key={item.key} className="d-flex padding-right-2 mt-1 padding-left-2 sss"> */}
                                        {columnList && Object.keys(columnList).length !== 0 &&
                                            <>
                                                <SingleSelection props={{
                                                    label: '', selectClassName: (!isColumnValuePresent(!swap ? columnList[`${Object.keys(columnList)[0]}`] : columnList[`${Object.keys(columnList)[1]}`], item[`${!swap ? Object.keys(selectedPOD)[0] : Object.keys(selectedPOD)[1]}`]))
                                                        ? "invalid-column-present " : "custom-select-dropdown datasource0 flows-select-custom", placeholder: "Select a column", dropDownValue: item[`${!swap ? Object.keys(selectedPOD)[0] : Object.keys(selectedPOD)[1]}`] !== ''?item[`${!swap ? Object.keys(selectedPOD)[0] : Object.keys(selectedPOD)[1]}`]:null, onClickEvent:
                                                        (value) => {
                                                            updateSelection(!swap ? Object.keys(selectedPOD)[0] : Object.keys(selectedPOD)[1], value, item.key, item, 'selection1')
                                                        }, optionList: !swap ? columnList[`${Object.keys(columnList)[0]}`] : columnList[`${Object.keys(columnList)[1]}`], isDisable: window.location.href.includes('viewFlows') ? true : false, isSortOptionDisable: true
                                                }} />
                                                <SingleSelection props={{
                                                    label: '', selectClassName: (!isColumnValuePresent(!swap ? columnList[`${Object.keys(columnList)[1]}`] : columnList[`${Object.keys(columnList)[0]}`], item[`${!swap ? Object.keys(selectedPOD)[1] : Object.keys(selectedPOD)[0]}`]))
                                                        ? "invalid-column-present " : "custom-select-dropdown ml-2 mr-2 datasource1 flows-select-custom", placeholder: "Select a column", dropDownValue: item[`${!swap ? Object.keys(selectedPOD)[1] : Object.keys(selectedPOD)[0]}`]!== ''?item[`${!swap ? Object.keys(selectedPOD)[1] : Object.keys(selectedPOD)[0]}`]:null,
                                                    onClickEvent: (value) => {
                                                        updateSelection(!swap ? Object.keys(selectedPOD)[1] : Object.keys(selectedPOD)[0], value, item.key, item, 'selection2')
                                                    }
                                                    , optionList: !swap ? columnList[`${Object.keys(columnList)[1]}`] : columnList[`${Object.keys(columnList)[0]}`], isDisable: window.location.href.includes('viewFlows') ? true : false, isSortOptionDisable: true
                                                }} />
                                            </>
                                        }
                                        <Buttons props={{ buttonText: '', buttonClassName: "custom-btn danger-btn btn-with-icon", buttonEvent: () => { filterPrimaryKeysCollection(item.key) }, ImgSrc: () => <MINUS />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT }} />
                                    </div>
                                )
                            )
                        }
                    </div>

                </div>

            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer,
        allTransformerName: state.FlowsReducer.transformerName
    };
};
export default React.memo(connect(mapStateToProps)(PrimaryKeySelection))