import React from 'react'
import './copilot.scss'
import { ICON_ARROW_LEFT, ICON_ARROW_RIGHT, ICON_CLARISTA, ICON_CLEAR, ICON_CLOSE } from '../../Common/newIconSource'
import SqlCopilot from './SqlCopilot'
import { Literals } from '../common/literals'
import Buttons from '../../Common/button/Buttons'
import CopilotInfoCard from './CopilotInfoCard'
import SchemaCopilot from './SchemaCopilot'
import ClassifierCopilot from './ClassifierCopilot'
import { useDispatch, useSelector } from 'react-redux'
import DashboardCopilot from './DashboardCopilot'
import FloatingTalkData from '../genAi/components/FloatingTalkData'
import { getOpenAiStatus, setUpdatedUserData, setUser } from '../../../store/userAuthentication/userActions'

const ClaristaCopilot = React.forwardRef(({
    type = 'sql',
    extraProps = {}
}, ref) => {

    const dispatch = useDispatch()

    const [animate, setAnimate] = React.useState(true)
    const [isMouseEnter, setMouseEnter] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [showInfo, setShowInfo] = React.useState(true)
    const [expanded, setExpanded] = React.useState(false)

    const sqlCopilotRef = React.useRef({})
    const talkDataCopilotRef = React.useRef({})

    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)
    let user = useSelector((state) => state.UserReducer?.user)

    React.useImperativeHandle(ref, () => {
        // Future use
    }, [])
    
    React.useEffect(() => {
        let animateTimeout = null
        if(!isMouseEnter) animateTimeout = setTimeout(() => setAnimate(!animate), 5000)
        else clearTimeout(animateTimeout)

        return () => {
            clearTimeout(animateTimeout)
        }
    }, [animate, isMouseEnter])

    React.useEffect(() => {
        setTimeout(() => {
            setShowInfo(false)
        }, 10000)

        dispatch(getOpenAiStatus())
        .then(res => {
            let openai_data = res?.data
            let details = {...user}
            details.userDetails = {...user.userDetails, openAi: openai_data}
            dispatch(setUser(details))
            setUpdatedUserData({...details.userDetails, openAi: openai_data})
        })
        .catch(err => {
            console.error({err})
            let details = {...user}
            details.userDetails = {...user.userDetails, openAi: null}
            dispatch(setUser(details))
            setUpdatedUserData({...details.userDetails, openAi: null})
        })

    }, [])

    const getPopoverContent = () => {
        switch (type) {
            case 'sql':
                return <>
                    <SqlCopilot
                        props={extraProps}
                        ref={sqlCopilotRef}
                    />
                </>
            case 'schema':
                return <>
                    <SchemaCopilot
                        props={extraProps}
                    />
                </>
            case 'classifier':
                return <>
                    <ClassifierCopilot
                        props={extraProps}
                    />
                </>
            case 'dashboard':
                return <>
                    <DashboardCopilot
                        props={extraProps}
                    />
                </>
            case 'talkdata':
                return <>
                    <FloatingTalkData
                        dataDomainId={extraProps?.dataDomainId}
                        podIds={extraProps?.podIds}
                        dashboardId={extraProps?.dashboardId}
                        props={extraProps}
                        ref={talkDataCopilotRef}
                    />
                </>
            default:
                return <></>
        }
    }

    const onCloseCallback = () => {
        switch (type) {
            case 'sql':
                sqlCopilotRef?.current?.syncChatWithParent()
                break;
            default:
                break;
        }
    }

    const displayUserName = () => {
        let name = userDetailsRedux?.first_name
        if(userDetailsRedux?.last_name)
        name += " " + userDetailsRedux?.last_name
        return name ?? 'Unknown User'
    }

    const infoMsg = {
        sql: 'Need help with SQL queries? Ask me!',
        schema: 'Auto-generate data in one click!',
        classifier: 'Generate classifiers in a snap',
        dashboard: 'Need a Chart? Ask me to create one',
        talkdata: extraProps?.dashboardId ? 'Ask me anything about your dashboard' : 'Ask me to analyze your pod data'
    }

    if(userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false)
    return <></>

    return (
        <>
            <div className={`clarista-copilot-starter ${showInfo ? 'info-card-holder' : ''} ${type === 'talkdata' && open ? 'talkdata-content' : ''} ${expanded ? 'talkdata-exp' : ''}`}>
                
                {/* <Popover 
                    content={getPopoverContent()} 
                    title={
                        <div className='position-relative'>
                            <div className='cpilot-pop-head'>
                                <label className='mb-0 fontSizeHeading fontInterSemiBold'>Clarista Copilot</label>

                            </div>
                            <Buttons
                                props={{
                                    buttonWrapperClass: "cpilot-close-btn-top",
                                    buttonClassName: `ml-1 custom-btn-outline custom-btn btn-with-icon`,
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        setOpen(false)
                                        onCloseCallback()
                                    },
                                    ImgSrc: () => <ICON_CLOSE/>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                        </div>
                    } 
                    trigger="click"
                    placement='topRight'
                    arrow={false}
                    // open={true}
                    rootClassName='clarista-copilot-popover'
                >  */}
               
                    <div className={`cpilot-pop ${expanded && type !== 'talkdata' ? 'expanded' : ''} ${type === 'talkdata' ? 'float-talkdata-window' : ''} mb-3 ${!open ? 'd-none' : ''}`}>
                        <div className='cpilot-pop-head'>
                            <label className='mb-0 fontSizeHeading fontInterSemiBold'>Clarista Copilot</label>

                        </div>
                        <div className='cpilot-action-btn-top d-flex align-items-center'>
                            {
                                type === 'talkdata'
                                ? <Buttons
                                        props={{
                                            tooltip: '',
                                            tooltipPlacement: 'left',
                                            buttonWrapperClass: "cpilot-close-btn-top",
                                            buttonText: "Clear",
                                            buttonClassName: `custom-btn-outline border custom-btn btn-with-text mr-2`,
                                            buttonEvent: (e) => {
                                                e.stopPropagation();
                                                talkDataCopilotRef?.current?.reRenderChat()
                                            },
                                            ImgSrc: () => <ICON_CLEAR/>,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                :   ''
                            }
                            <Buttons
                                props={{
                                    tooltip: expanded ? 'Shrink' : 'Expand',
                                    tooltipPlacement: 'left',
                                    buttonWrapperClass: "cpilot-close-btn-top",
                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon border-0`,
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        setExpanded(!expanded)
                                    },
                                    ImgSrc: () => expanded ? <ICON_ARROW_RIGHT/> : <ICON_ARROW_LEFT/>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                            <Buttons
                                props={{
                                    buttonWrapperClass: "cpilot-close-btn-top",
                                    buttonClassName: `ml-2 custom-btn-outline custom-btn btn-with-icon`,
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        setOpen(false)
                                        onCloseCallback()
                                    },
                                    ImgSrc: () => <ICON_CLOSE/>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                        </div>
                        <div className={`cpilot-pop-body`}>
                            {getPopoverContent()}
                        </div>
                    </div>
                    {
                        showInfo
                        ?
                        <CopilotInfoCard
                            heading={`Hi ${displayUserName() ?? ''},`}
                            paragraph={infoMsg[type] ?? 'Play with Clarista Copilot'}
                            onClick={() => {
                                setOpen(!open)
                                setShowInfo(false)
                            }}
                            style={{cursor: 'pointer'}}
                        />
                        :   ''
                    }

                    {/* <div>
                        <div className='c-cpilot-float-btn ml-auto'
                            onMouseEnter={() => {
                                setMouseEnter(true)
                            }}
                            onMouseLeave={() => {
                                setMouseEnter(false)
                            }}
                            onClick={() => {
                                setOpen(!open)
                                setShowInfo(false)
                            }}
                            style={open ? {cursor: 'grab'} : {}}
                        >
                            <div className={`c-cpilot-bg-wrap ${animate ? 'clarista-cpilot-logo-anim' : ''}`}></div>
                            <div className='c-cpilot-sec-wrap'>
                                <ICON_CLARISTA/>
                            </div>
                        </div>
                    </div> */}
                {/* </Popover> */}
            </div>
            <>
                <div className='c-cpilot-float-btn ml-auto'
                    onMouseEnter={() => {
                        setMouseEnter(true)
                    }}
                    onMouseLeave={() => {
                        setMouseEnter(false)
                    }}
                    onClick={() => {
                        setOpen(!open)
                        setShowInfo(false)
                    }}
                    onTouchStart={() => {
                        setOpen(!open)
                        setShowInfo(false)
                    }}
                    style={open ? {cursor: 'grab'} : {}}
                >
                    <div className={`c-cpilot-bg-wrap ${animate ? 'clarista-cpilot-logo-anim' : ''}`}></div>
                    <div className='c-cpilot-sec-wrap'>
                        <ICON_CLARISTA/>
                    </div>
                </div>
            </>
        </>
    )
})

export default ClaristaCopilot