import React, { useEffect } from 'react'
import '../dataCatalogue/landingPage/components/LandingPageHeader.scss';
import Profile from "../common/Profile";
import { Link } from "react-router-dom";
import { setOpenAnalysis } from '../../../store/modules/analytics/dashboard/dashboardActions'
import { toggleSidebarCollapse } from '../../../store/layout/actions';
import { useDispatch, useSelector } from 'react-redux'
import { HAMBURGER } from "../../Common/iconSource"
import { useNavigate } from 'react-router-dom';
import { Literals } from './literals';
import logoImage from "../../../assets/images/new-logo-2.png";
import { getMonthName, getTime } from './helperFunctions';
import FeedbackNotification from '../genAi/components/FeedbackNotification';
import { getDataDomainList, setDataDomainList } from '../../../store/actions';
import ClusterStatusNotification from '../clusters/ClusterStatusNotification';
import Notifications from '../../Common/notifications/Notifications';

const LandingpageFirstHeader = React.forwardRef(({
    title,
    backRoute,
    flag,
    from,
    clickEdit,
    refreshAnalysisData,
    view,
    isPulse = false,
    showFeedNotification = false,
    activeDomain,
    hideLogo = false,
    hideFeedbackNotification = false
}, ref) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { isSidebarVisible } = useSelector(state => state.Layout)
    const searchParams = new URLSearchParams(document.location.search)
    const history_id = searchParams.get("history_id");
    const versionHistory = JSON.parse(localStorage.getItem("versionHistory"));

    const { datadomainList } = useSelector(state => state.UserManagement.DataDomain)
    let userDetails = useSelector(state => state.UserReducer?.user?.userDetails)

    const notificationRef = React.useRef({})
    const clusterRef = React.useRef({})

    React.useImperativeHandle(ref, () => ({
        refreshNotification: () => {
            notificationRef?.current?.refreshNotification()
        },
        // refreshClusterNotification: () => {
        //     clusterRef?.current?.refreshNotification()
        // }
    }), [datadomainList])

    React.useEffect(() => {
        if (datadomainList?.length === 0) {
            dispatch(getDataDomainList(false, false, false))
                .then(res => {
                    dispatch(setDataDomainList(res.data))
                }).catch(() => {

                })
        }
    }, [])

    useEffect(() => {
        return () => {
            if (view && view === 'Analysis') {
                dispatch(setOpenAnalysis({ layout: 'analysis', activeTab: 'analysis' }))
            }
        }
    }, [view])

    const toggleSidebarHandler = () => {
        dispatch(toggleSidebarCollapse());
    }

    return (
        <div className="main-header" style={history_id ? { backgroundColor: "#6b6b6b", filter: 'grayscale(1)' } : {}}>
            <div className="left">
                {
                // !isSidebarVisible &&
                    <div 
                        className="hamburger" 
                        onClick={() => { toggleSidebarHandler() }}
                        // onMouseOver={() => toggleSidebarHandler()}
                    >
                        {/* <ICON_MENU /> */}
                        <HAMBURGER />
                    </div>
                }
                {
                    hideLogo ? ''
                    :
                    <img className="cursor-pointer" src={logoImage}
                        onClick={() => {
                            if(userDetails?.openAi?.is_active) {
                                return history(Literals.links.CLARISTA_HOME)
                            }
                            else if (userDetails?.designation === Literals.DATA_ANALYST)
                                return history(Literals.links.CLARISTA_NAVIGATOR)
                            else if (userDetails?.designation === Literals.DATA_SCIENTIST)
                                return history(Literals.links.CLARISTA_LAB)
                            else if (userDetails?.designation === Literals.DATA_CONSUMER)
                                return history(Literals.links.CLARISTA_PULSE)
                            else
                                return history(Literals.links.CLARISTA_NAVIGATOR)
                        }}
                        alt="Clarista" style={{ filter: isPulse ? 'none' : 'brightness(9)', height: '1.125rem' }} />
                }
                {
                    isPulse ? ''
                        :
                        <h3 className={`section-heading text-capitalize ${isPulse ? 'color-primary' : 'text-white'}`}>{`${title}`}</h3>
                }

                {
                    isPulse && !hideLogo ?
                        <>
                            <div className='vertical-separator header-sep' style={isPulse ? { marginLeft: 0 } : {}}></div>
                            <div className='fontInterSemiBold' style={{ color: '#C60C4F', fontSize: '0.875rem' }}>
                             Pulse
                            </div>
                        </>
                        : ''
                }
            </div>
            <div className='d-flex align-items-center h-100'>

                <ClusterStatusNotification fromRoute={isPulse?'pulse':''} />
                {
                    history_id ?
                        <div style={{ color: "white", display: "table-cell", gap: '10px' }} className="align-items-center d-flex flex-row-reverse h-100 mr-3" >

                            <p className="align-items-center d-block d-flex label p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", margin: "0", background: '#ababab' }}>
                                <i className="mr-1 bx bxs-calendar-alt"></i>
                                {versionHistory?.log_time === null ? '' : new Date(versionHistory?.log_time).getUTCDate()}
                                {versionHistory?.log_time === null ? '' : ' ' + getMonthName.short[new Date(versionHistory?.log_time).getUTCMonth()]}
                                {versionHistory?.log_time === null ? '' : ' ' + new Date(versionHistory?.log_time).getUTCFullYear()}
                            </p>
                            <p className="align-items-center d-flex font-w-600 mb-0 p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", textAlign: "end", margin: "0", background: '#ababab' }}>
                                <i className="mr-1 bx bxs-time"></i>{versionHistory?.log_time === null ? '00:00' : getTime(versionHistory?.log_time)}
                            </p>
                            <p className="align-items-center d-flex font-w-600 mb-0 p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", textAlign: "end", margin: "0", background: '#ababab' }}>
                                <i className="mr-1 bx bxs-purchase-tag"></i>{`Version ID: ${versionHistory?.log_id}`}
                            </p>
                        </div> : ""
                }



                {
                    /***
                     * For now we are showing Feedback Notification option to the user
                     * who are editor of atleast one domain
                     */
                    // showFeedNotification &&


                    /***
                     * Deprecating This Component By <Notifications/>
                     */
                    /* datadomainList &&
                        datadomainList?.some(d => d?.permission?.includes('editor_datadomain'))
                        && !hideFeedbackNotification
                        // datadomainList?.find(d => d?.data_domain_id === Number(activeDomain))?.permission?.includes('editor_datadomain') 
                        ? <FeedbackNotification ref={notificationRef} /> : '' */

                }

                {
                    // window?.location?.href?.includes('new-usage')
                    // ?
                    <Notifications isPulse={isPulse} />
                    // :   ''
                }



                {from && from === 'viewAnalytics' ?
                    <div className="d-flex">
                        <button className="btn btn-secondary btn-icon mr-2" onClick={() => refreshAnalysisData()}>
                            <i className='bx bx-refresh'></i>
                        </button>
                        <button className="btn btn-primary" onClick={() => clickEdit()}>
                            <i className="bx bx-edit-alt"></i>
                            Edit
                        </button>
                    </div>
                    : < Profile />}
            </div>
        </div>
    )
})

export default LandingpageFirstHeader
