import React, { useEffect, useState } from "react"
import Cron from "react-js-cron"

import 'react-js-cron/dist/styles.css'
import noteIco from '../../../../assets/images/noteIco.png'
import cronstrue from 'cronstrue';
//import 'antd/dist/antd.css';
import { useSelector, useStore } from "react-redux";
import { SetSchedulerPayload } from "../../../../store/modules/scheduler/action";
import { cloneDeep } from "lodash";
import TimeZoneDropdown from "./TimeZoneDropdown";
import moment from "moment-timezone";

function CronDashboard({ isExecutePermission }) {
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)
    const store = useStore()
    const [cronValue, setcronValue] = useState("0,8 13 * * 1")
    const momentTz = moment.tz(moment.tz.guess());
    const offset = momentTz.utcOffset();
    const name = moment.tz.guess();
    const gmt = `UTC${offset ? momentTz.format("Z") : ""}`;
    const fullName = `${gmt} - ${name}`;
    const [selectedTimeZone, setselectedTimeZone] = useState();

    const dropDownHeader = ['Hour', 'Minitue', 'Day of month', 'Day of week', 'Month of year']

    useEffect(() => {
        if (schedulerPayload?.cron && schedulerPayload?.cron !== '' && schedulerPayload?.cron !== cronValue) {

            setcronValue(schedulerPayload.cron)

        }
        if (schedulerPayload?.cron_redux && schedulerPayload?.cron_redux?.cron_timezone) {

            setselectedTimeZone(schedulerPayload?.cron_redux?.cron_timezone)

        }
    }, [schedulerPayload])

    useEffect(() => {
        if (window?.location?.href?.toLowerCase()?.includes('scheduler/create')) {
            setselectedTimeZone(name)
        } else if (schedulerPayload?.cron_redux && schedulerPayload?.cron_redux?.cron_timezone) {

            setselectedTimeZone(schedulerPayload?.cron_redux?.cron_timezone)

        } else {
            setselectedTimeZone('UTC')
        }
    }, [])


    useEffect(() => {
        // console.log(cronValue)
        setpayload(cronValue)
    }, [cronValue, selectedTimeZone])




    const setpayload = (value) => {


        const newPayload = cloneDeep(schedulerPayload)
        newPayload['cron'] = value;
        newPayload['cron_redux']['cron_timezone'] = selectedTimeZone;
        store.dispatch(SetSchedulerPayload(newPayload))
    }


    return (
        <div>
            <TimeZoneDropdown disabled={window.location.href.includes('scheduler/view') || !isExecutePermission} selectedTimeZone={selectedTimeZone} setselectedTimeZone={setselectedTimeZone} />

            <div className={`cron-dropdown mt-5`}>
                <Cron value={cronValue} disabled={window.location.href.includes('scheduler/view') || !isExecutePermission} setValue={(cron) => {
                    setpayload(cron);
                }} />
            </div>
            <div>
                <div className="d-flex align-items-center mt-2 p-0">
                    <span className='dsrc-lf-icon mr-2'>
                        <img src={noteIco} height={15} width={15} alt='Note' />
                    </span>
                    <div>
                        <p className="label mb-0" style={{ color: '#FFA501' }}> Double click on a dropdown option to automatically select / unselect a periodicity  </p>
                    </div>
                </div>
            </div>
            <hr></hr>
            <span style={{ fontSize: "0.75rem", fontWeight: '600', color: "#4F5C6B", fontStyle: "italic", }}>{`Scheduler will Trigger at  ${cronstrue.toString(cronValue)}`}</span>

        </div>
    )
}

export default CronDashboard