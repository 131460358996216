import React from 'react'
import './newDataGridTable.scss'
import { Radio, Space } from 'antd'
import DataGridFilterByCondition from './DataGridFilterByCondition';
import DataGridFilterByValue from './DataGridFilterByValue';

const DataGridFilterCombination = React.forwardRef(({
    column = {},
    colFilterRef = {},
    setParentFilters = () => {},
    domainName = '',
    tableName = '',
    tableId,
    setFilterType = () => {},
    setParentFilterValues = () => {},
    setFilterByValData = () => {},
    filterType,
    isCustomSql = false,
    staticQuery,
    uniqUUID
}, ref) => {

    const [value, setValue] = React.useState('values');

    const filterConditionRef = React.useRef({})
    const filterValuesRef = React.useRef({})

    React.useEffect(() => {
        setValue(filterType)
    }, [filterType])

    const onChange = (e) => {
        let val =  e?.target?.value
        setValue(val);
        setFilterType(val)
        colFilterRef.current[column?.name] = {
            ...colFilterRef.current[column?.name],
            filterType: val
        }
    };

    React.useImperativeHandle(ref, () => {
        return {
            setConditions: (list) => {
                filterConditionRef?.current?.setConditionList(list)
            },
            setConditionOperation: (op) => {
                filterConditionRef?.current?.setConOperation(op)
            },
            setInvertByValue: (invert) => {
                filterValuesRef?.current?.setIsInvert(invert)
            },
            resetFilterByValues: () => {
                filterValuesRef?.current?.resetSelectedValues()
            },
            setFilterByValues: (val) => {
                filterValuesRef?.current?.setFilterValues(val)
            }
        }
    }, [value])

    return (
        <div className='new-dgt-flt-comb-comp'>
            <Radio.Group 
                className='w-100 pt-1'
                value={value}
                onChange={onChange} 
            >
                <Space direction="vertical" className='w-100'>
                    <div className='mb-1'>
                        <Radio value={'condition'} className='px-2 fontSizeHeading' style={{fontFamily: 'InterRegular', zIndex: 1}}>
                            Filter by condition
                        </Radio>
                        <div style={{display: value === 'condition' ? 'block' : 'none'}} className='fontSizeHeading position-relative'>
                            <DataGridFilterByCondition
                                column={column}
                                colFilterRef={colFilterRef}
                                setParentFilters={setParentFilters}
                                ref={filterConditionRef}
                            />
                        </div>
                    </div>
                    <div>
                        <Radio value={'values'} className='px-2 fontSizeHeading' style={{fontFamily: 'InterRegular'}}>
                            Filter by values
                        </Radio>
                        <div style={{display: value === 'values' ? 'block' : 'none'}} className='fontSizeHeading pt-3 px-2'>
                            <DataGridFilterByValue
                                column={column}
                                colFilterRef={colFilterRef}
                                domainName={domainName}
                                tableId={tableId}
                                tableName={tableName}
                                setParentFilterValues={setParentFilterValues}
                                setFilterByValData={setFilterByValData}
                                staticQuery={staticQuery}
                                isCustomSql={isCustomSql}
                                uniqUUID={uniqUUID}
                                ref={filterValuesRef}
                            />
                        </div>
                    </div>
                </Space>
            </Radio.Group>
        </div>
    )
})

export default DataGridFilterCombination