import _ from "lodash";
import React from "react";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { setToastNotificationMsg } from "../store/modules/common/loadingActions";

export const emitToastNotification = (
  type,
  msg,
  title = '',
  options = {
    timeOut: 2000,
    autoClose: 2000,
    closeMethod: 'fadeOut',
    closeEasing: 'swing',
    closeOnClick: false,
    draggable: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    enableHtml: true,
    type: type?.toLowerCase(),
    escapeHtml: false,
    preventDuplicates: true,

  },
  limit = 0,
) => {

  // // const oldMsg = localStorage.getItem('toastNotificationMsg');
  // // if ((oldMsg && oldMsg === msg) || msg === '' || msg === undefined || msg === null) {
  // //   return <ToastContainer autoClose={2000} />;
  // // } else {
  // //   localStorage.setItem('toastNotificationMsg', msg)
  // //   switch (type?.toLowerCase()) {
  // //     case "success":
  // //       toast.success(msg, options);
  // //       return <ToastContainer autoClose={2000} />
  // //       break;
  // //     case "error":

  // //       toast.error(msg, options);
  // //       return <ToastContainer autoClose={2000} limit={limit} />
  // //       break;
  // //     case "warning":
  // //       toast.warning(msg, options);
  // //       return <ToastContainer autoClose={2000} />

  // //       break;
  // //     case "info":
  // //       toast.info(msg, options);
  // //       return <ToastContainer autoClose={2000} />

  // //       break;
  // //     case "remove":
  // //       toast.remove();
  // //       return <ToastContainer autoClose={2000} />

  // //       break;
  // //     default:

  // //       return <ToastContainer autoClose={2000} />


  // //       break;
  // //   }
  // }
  const showToast = (message, type = 'default') => {
    const toastId = message; // Use message as the unique identifier
    if ( msg === '' || msg === undefined || msg === null) {
      return <ToastContainer autoClose={2000} />;
    } else {
      if (!toast.isActive(toastId)) {
        switch (type.toLowerCase()) {
          case 'success':
            toast.success(message, { toastId, ...options });
            break;
          case 'error':
            toast.error(message, { toastId, ...options });
            break;
          case 'warning':
            toast.warning(message, { toastId, ...options });
            break;
          case 'info':
            toast.info(message, { toastId, ...options });
            break;

          case "remove":
            toast.remove();
            break;
          default:

            break;
        }
      }
    }
  };


  showToast(msg, type)
  return <ToastContainer autoClose={2000} />
};
