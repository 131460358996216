import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import { useStore } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import SingleSelection from '../../Common/dropDown/SingleSelection';
import { preventSpecialCharsRegex } from '../common/helperFunctions';
import Buttons from '../../Common/button/Buttons';
import { CLOSE, SAVE } from '../../Common/iconSource';
import { Literals } from '../common/literals';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { createClusterAction } from '../../../store/modules/cluster/Cluster';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import moment from 'moment';

const CreateCluster = ({ openModal, setOpenModal, showStopped, getClusterListApi, editFlowName = false, CurrentflowName = '', flowDesc = '' }) => {
    const history = useNavigate();
    const [Name, setName] = useState(``)
    const [descript, setDescript] = useState(``);
    const [size, setSize] = useState(`M`);
    const [plan, setPlan] = useState(`ON_DEMAND`);
    const [type, settype] = useState(`CORE`);
    const [validUntil, setvalidUntil] = useState(null);
    let toggleBtnOption = [{ label: 'On Demand', value: 'ON_DEMAND', disabled: false }, { label: `Reserved`, value: 'RESERVED', disabled: false },]
    let toggleTypeBtnOption = [{ label: 'Core', value: 'CORE', disabled: false }, { label: `Notebook`, value: 'NOTEBOOK', disabled: false },]

    let SizeList = [
        { key: 1, Name: "XS | 2GB RAM 0.5 CPU", Type: "", value: "XS" },
        { key: 2, Name: "S | 4GB RAM 1 CPU", Type: "", value: "S" },
        { key: 3, Name: "XS | 8GB RAM 2 CPU", Type: "", value: "M" },
        { key: 4, Name: "S | 16GB RAM 4 CPU", Type: "", value: "L" },
        { key: 5, Name: "L | 32GB RAM 8 CPU", Type: "", value: "XL" },
        { key: 6, Name: "XL | 64GB RAM 16 CPU", Type: "", value: "XXL" }
    ];


    const [isDisable, setisDisable] = useState(false);

    const { flowId } = useParams()
    const store = useStore()


    useEffect(() => {
        if (plan?.toLowerCase() === 'reserved') {
            const currentDate = moment.utc(); // Get the current date
            const nextYear = moment(currentDate).add(365, 'days'); // Add 1 year

            setvalidUntil(nextYear?.format('YYYY-MM-DD'))
        } else {
            setvalidUntil(null)
        }

    }, [plan])

    useEffect(() => {


        return () => {
//clear modal after close
            setName(``)
            setvalidUntil(null)
            setSize(`M`);
            setPlan(`RESERVED`);
            settype(`CORE`);

        }
    }, [])


    const CreateCluster = () => {
        setisDisable(true)
        let payload = {
            "name": Name,
            "size": size,
            "plan": plan,
            "type": type,
            "description": descript,
            "valid_until": validUntil
        }
        if (name !== '' && size !== '') {
            store.dispatch(createClusterAction(payload)).then((resp) => {
                setisDisable(false)
                setOpenModal(false);
                getClusterListApi(showStopped)
            }).catch(e => setisDisable(false))
        }

    }



    return (
        <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true}>
            <ModalHeader>

                <div className="d-flex align-items-center">

                    <h2 className="section-heading">{'Create Cluster'}</h2>
                </div>
                <div className="tab-menu right-flow-header">
                    <Buttons props={{
                        buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {
                            setOpenModal(false)

                        }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                    }} />
                </div>
            </ModalHeader>
            <ModalBody >

                <div className='d-flex align-items-center justify-content-center w-100'>
                    <div className='col-6'>
                        <label className="label"> Name </label>
                        <input type="text" name="name" className="custom-input-field mb-3" value={Name} onChange={(e) => {

                            if (preventSpecialCharsRegex.test(e.target.value) || e.target.value === "") {
                                setName(e.target.value)
                            }
                        }} />

                    </div>
                    <div style={{ marginBottom: "18px" }} className='col-6'>
                        <label className="label"> Type </label>
                        <div className="toggle-btn-with-text  target-modal-block-rd w-100 m-0">
                            <Buttons props={{ buttonText: '', toggleBtnOption: toggleTypeBtnOption, toggleBtnValue: type, buttonClassName: "", buttonEvent: settype, ImgSrc: () => <></>, isDisable: isDisable, buttonType: Literals.BTN_TOGGLE }} />
                        </div>

                    </div>



                </div>
                <div className='d-flex align-items-center justify-content-center w-100'>
                    {/* <div className='col-6'>
                        <label className="label">Description</label>
                        <input type="text" name="name" className="custom-input-field" value={descript} onChange={(e) => {
                            setDescript(e.target.value)
                        }} />
                    </div> */}
                    <div className='col-6'>
                        <SingleSelection props={{
                            label: "Size", placeholder: "", dropDownValue: size, onClickEvent: (value) => {
                                setSize(value)
                            }, optionList: SizeList?.slice(2, SizeList?.length), isSortOptionDisable: true, isDisable: false
                        }} />
                    </div>
                    <div style={{ marginBottom: "10px" }} className='col-6'>
                        <label className="label"> Plan </label>
                        <div className="toggle-btn-with-text  target-modal-block-rd w-100 m-0">
                            <Buttons props={{
                                buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: plan, buttonClassName: "",
                                buttonEvent: (value) => {
                                    setPlan(value);
                                    if (plan?.toLowerCase() === 'reserved') {
                                        const currentDate = moment.utc(); // Get the current date
                                        const nextYear = moment(currentDate).add(365, 'days'); // Add 1 year

                                        setvalidUntil(nextYear?.format('YYYY-MM-DD'))
                                    }
                                },

                                ImgSrc: () => <></>, isDisable: false, buttonType: Literals.BTN_TOGGLE
                            }} />
                        </div>

                    </div>

                </div>
                {plan?.toLowerCase() === 'reserved' ? <div style={{ textAlign: "end" }} className='col-6 float-right mr-1'>
                    <label style={{ fontSize: "12px", color: "#828282", fontWeight: "bold" }} className="label">* Reserved plan valid till {validUntil} </label>

                </div> : <div style={{ textAlign: "end" }} className='col-6 float-right mr-1'>
                    <label style={{ height: "18px" }} className="label"></label>

                </div>}

                {/* <div className='d-flex align-items-center justify-content-center w-100'>
                    <div className='col-6 mt-2'>
                        <label className="label"> Plan </label>
                        <div className="toggle-btn-with-text  target-modal-block-rd w-100 m-0">
                            <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: plan, buttonClassName: "", buttonEvent: setPlan, ImgSrc: () => <></>, isDisable: false, buttonType: Literals.BTN_TOGGLE }} />
                        </div>
                    </div>

                    {plan?.toLowerCase() === 'reserved' ? <div className='col-6 mt-1'>
                        <label className="label"> Valid Until </label>
                        <DatePicker 
                            picker="date"
                            placeholder="Please select date"
                            disabledDate={(current) => current && (current < moment().endOf('day') || current < moment().endOf('year'))}
                            className={
                                !validUntil
                                    ? "custom-input-field "
                                    : "custom-input-field"
                            }
                            value={validUntil ? dayjs(validUntil, "YYYY-MM-DD") : null}
                            format="YYYY-MM-DD"
                            onChange={(e, val) => {

                                //let value=e.target.value
                                let value = val;
                                setvalidUntil(value)
                            }}
                        />
                    </div> : null}

                </div> */}

            </ModalBody>
            <ModalFooter>

                <Buttons props={{ buttonText: 'Save', buttonClassName: "custom-btn-primary custom-btn text-center  btn-with-text", buttonEvent: () => { CreateCluster() }, ImgSrc: () => <SAVE />, isDisable: isDisable, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
            </ModalFooter>
        </Modal>
    )
}

export default CreateCluster
