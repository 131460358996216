import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  useStoreApi, useReactFlow, Panel,
  useNodesState, useEdgesState, Background, MiniMap, getRectOfNodes, getTransformForBounds, useViewport
} from "reactflow";
import Menubar from "./Menubar";

import Datasource from "./transformerIcons/DatasourceTransformers";
import Target from "./transformerIcons/TargetTransformers";
import Merge from "./transformerIcons/MergeTransformers";
import Filters from "./transformerIcons/FiltersTransformers";
import Script from "./transformerIcons/ScriptTransformers";
import SQL from "./transformerIcons/SQLTransformers";
import LowerMenubar from "./LowerMenubar";
import { connect, useDispatch, useStore, useSelector } from "react-redux";
import {
  updateFlowsElements,
  setFlowsCurrenttransformer,
  showFlowsTransformdetails,
  addEdges,
  setExecutedTransformerPayload,
  showFlowsLowerSection,
  setTransformerMaxId,
  setCurrentScriptoutputNode,
  setTransformerNames,
  showFlowsLogsLowerSection,
  saveFlow,
  showTimelineLowerSection,
} from "../../../../store/modules/flows/flowsActions";
import { setActivePodDetails } from "../../../../store/modules/common/VirtualDataTable/action";
import ScriptOutputTransformer from "./transformerIcons/ScriptOutputTransformer";
import AlertTransformer from "./transformerIcons/AlertTransformer";
import { setActiveTabKey } from "../../../../store/modules/common/DataGridTable/dataGridTableRedux";
import { resetScriptRedux } from "../../../../store/modules/flows/scriptTransformer/scriptAction";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import SchemaTransformer from "./transformerIcons/SchemaTransformer";
import AggregateTransformer from "./transformerIcons/AggregateTransformer";
import Databricks from "./transformerIcons/DatabricksTransformer";

import { Literals } from "../../common/literals";
import 'reactflow/dist/style.css';
import BricksOutputTransformer from "./transformerIcons/BricksOutputTransformer";

import ButtonDeleteEdge from "./ButtonDeleteEdge";
import { toPng } from "html-to-image";
import { useParams } from "react-router-dom";
import { getRelativeTime } from "../../common/helperFunctions";



const nodeTypes = {
  datasource: Datasource,
  scriptOutput: ScriptOutputTransformer,
  bricksOutput: BricksOutputTransformer,
  target: Target,
  merge: Merge,
  filters: Filters,
  schema: SchemaTransformer,
  aggregate: AggregateTransformer,
  alert: AlertTransformer,
  script: Script,
  sql: SQL,
  databricks: Databricks
}
const edgeTypes = {

  buttonedge: ButtonDeleteEdge,
};

const minimapStyle = {
  height: 120,
};

function Flow(props) {
  // you can access the internal state here

  const { isViewMode, saveFlowClick, scriptOutputDataset, bricksOutputDataset, showTransformDetails } = props;

  const flowStore = useStoreApi();
  const { zoomOut, zoomIn, setCenter, getNodes, getEdges } = useReactFlow();
  const { x, y, zoom } = useViewport();
  const reactFlowInstanceuse = useReactFlow();
  const { flowId } = useParams();

  const dispatch = useDispatch();

  const { flowsElements } = props;
  const reactFlowWrapper = useRef(null);
  const scriptOutput = useRef(0);
  const bricksOutput = useRef(0);
  const store = useStore();
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const saveApiCron = useRef(null)
  useEffect(() => {

    if (flowsElements && flowsElements.length) {

      updateLocalState(flowsElements)
    } else {
      updateLocalState([])
    }
  }, [flowsElements]);




  const getId = () => {
    let maxId = store.getState().FlowsReducer.maxTransformerId;

    return maxId + 1;
  };
  const saveNoteBookDoneFlag = useSelector(
    (state) => state.Flows.Script.saveNoteBookDone
  );

  const updateLocalState = (flowsElementscopy) => {
    if (flowsElementscopy?.length) {

      const initialNodes1 = flowsElementscopy.filter(item => !item.source)
      const initialEdges1 = flowsElementscopy.filter(item => item.source)
      setNodes([...initialNodes1])
      setEdges([...initialEdges1])
    } else {
      setNodes([])
      setEdges([])

    }
  }

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {

    if (showTransformDetails) {
      reactFlowInstanceuse.fitView()
      zoomOut();
    }
  }, [showTransformDetails])

  useEffect(() => {
    if (
      scriptOutputDataset &&
      scriptOutputDataset.length > 0 &&
      (window.location.href.includes("createFlows") ||
        window.location.href.includes("editFlows")) &&
      !saveNoteBookDoneFlag
    ) {
      let templist = [...flowsElements];
      let yposition;

      scriptOutputDataset.forEach((ele, i) => {
        if (templist && templist.length) {
          let isExistnode = templist.findIndex((item) =>
            item.id?.includes(ele.id)
          );

          if (isExistnode && isExistnode <= -1) {
            const { newnode, edge } = createNewOutputNode(ele.id, i);
            yposition = newnode?.position?.y;
            templist.forEach((ele) => {
              if (yposition && ele.position?.y === yposition) {  // if newly calculated y position is already found change to new y postion 
                newnode.position.y = yposition + 100;

              }
            });
            if (newnode && edge) {
              let nodeIndex = templist.findIndex(
                (ele) => ele.id === newnode.id
              );
              let edgeIndex = templist.findIndex((ele) => ele.id === edge.id);

              if (nodeIndex > -1) {
                templist.splice(nodeIndex, 1, newnode);
              } else {
                templist.push(newnode);
              }

              if (edgeIndex > -1) {
                templist.splice(edgeIndex, 1, newnode);
              } else {
                templist.push(edge);
              }
            }
            return templist;
          }
        }
      });
      if (templist.length) {
        dispatch(updateFlowsElements([...templist]));
        updateLocalState(templist)
      }
    }
  }, [scriptOutputDataset]);

  useEffect(() => {

    if (
      bricksOutputDataset &&
      bricksOutputDataset.length > 0 &&
      (window.location.href.includes("createFlows") ||
        window.location.href.includes("editFlows"))

    ) {
      let templist = [...flowsElements];
      bricksOutputDataset.forEach((ele, i) => {
        if (templist && templist.length) {
          let isExistnode = templist.findIndex((item) =>
            item.id?.includes(ele.id)
          );

          if (isExistnode && isExistnode <= -1) {
            const { newnode, edge } = createNewBricksOutputNode(ele.id, i);
            if (newnode && edge) {
              let nodeIndex = templist.findIndex(
                (ele) => ele.id === newnode.id
              );
              let edgeIndex = templist.findIndex((ele) => ele.id === edge.id);

              if (nodeIndex > -1) {
                templist.splice(nodeIndex, 1, newnode);
              } else {
                templist.push(newnode);
              }

              if (edgeIndex > -1) {
                templist.splice(edgeIndex, 1, newnode);
              } else {
                templist.push(edge);
              }
            }
            return templist;
          }
        }
      });
      if (templist.length) {

        dispatch(updateFlowsElements([...templist]));
        updateLocalState(templist)
      }
    }
  }, [bricksOutputDataset]);


  useEffect(() => {
    handleTransform();

    if (saveFlowClick) {

      dispatch(showFlowsTransformdetails(false));
      dispatch(showFlowsLowerSection(false));
      clearInterval(saveApiCron.current)
    }
  }, [saveFlowClick]);


  useEffect(() => {

    handleTransform();
  }, []);


  const createNewOutputNode = (elementId, index) => {

    let xposition = props.currentTransformer?.position?.x + 200;
    let yposition = props.currentTransformer?.position?.y - 200 + 100 * index;
    let allnodes = getNodes();
    if (allnodes?.length) {
      allnodes.forEach((ele) => {

        if (ele.position?.y === yposition) {  // if newly calculated y position is already found change to new y postion 
          yposition = yposition + 100;

        }
      });
    }
    let position = { x: xposition, y: yposition }

    const newNode = {
      id: elementId,
      type: "scriptOutput",
      position,
      content: {},
      transformerName: elementId?.split("|")[1]
        ? `${elementId?.split("|")[1]}`
        : `${elementId}`,
    };

    if (newNode.id !== undefined) {
      let payload = {
        flow_body: {
          Id: newNode.id?.split("|")[1]
            ? `${newNode.id?.split("|")[1]}`
            : `${newNode.id}`,
          NodeType: `scriptOutput`,
          currentTransformerID: `${newNode.id}`,
          Args: [
            {
              Name: "",
              Value: "",
            },
            {
              Name: "",
              Value: "",
            },
          ],
        },
      };
      // let templist = store.getState().FlowsReducer.executedTransformerPayload;
      // if (templist?.length) {
      //   let existedTransformerIndex = templist.findIndex(
      //     (ele) =>
      //       ele?.currentTransformerID?.toLowerCase() ===
      //       newNode?.id?.toLowerCase()
      //   );

      //   if (existedTransformerIndex > -1) {
      //     templist.splice(existedTransformerIndex, 1, payload["flow_body"]);
      //   } else {
      //     templist.push(payload["flow_body"]);
      //   }
      // }

      // dispatch(setExecutedTransformerPayload(templist));

      scriptOutput.current = scriptOutput.current + 1;

      let source = props?.currentTransformer?.id;
      let target = newNode.id;
      let params = {
        id: `reactflow__edge|e1_${target}`,
        source: source,
        target: target,
      };
      let reduxEdges = store.getState().FlowsReducer.edges

      let storedEdges = reduxEdges;

      if (storedEdges && storedEdges.length) {
        storedEdges.push({
          source: source,
          destination: target,
        });

        dispatch(addEdges(storedEdges));
      } else {
        dispatch(
          addEdges([
            {
              source: source,
              destination: target,
            },
          ])
        );
      }

      return { newnode: newNode, edge: params };
    }
  };

  const createNewBricksOutputNode = (elementId, index) => {

    let position = { x: props.currentTransformer?.position?.x + 200, y: props.currentTransformer?.position?.y - 200 + 100 * index }

    const newNode = {
      id: elementId,
      type: "bricksOutput",
      position,
      content: {},
      transformerName: "",
    };

    if (newNode.id !== undefined) {
      let payload = {
        flow_body: {
          Id: newNode.id?.split("|")[1]
            ? `${newNode.id?.split("|")[1]}`
            : `${newNode.id}`,
          NodeType: `scriptOutput`,
          currentTransformerID: `${newNode.id}`,
          Args: [
            {
              Name: "",
              Value: "",
            },
            {
              Name: "",
              Value: "",
            },
          ],
        },
      };
      let templist = store.getState().FlowsReducer.executedTransformerPayload;
      if (templist?.length) {
        let existedTransformerIndex = templist.findIndex(
          (ele) =>
            ele?.currentTransformerID?.toLowerCase() ===
            newNode?.id?.toLowerCase()
        );

        if (existedTransformerIndex > -1) {
          templist.splice(existedTransformerIndex, 1, payload["flow_body"]);
        } else {
          templist.push(payload["flow_body"]);
        }
      }

      dispatch(setExecutedTransformerPayload(templist));

      bricksOutput.current = bricksOutput.current + 1;

      let source = props?.currentTransformer?.id;
      let target = newNode.id;
      let params = {
        id: `reactflow__edge|e1_${target}`,
        source: source,
        target: target,
      };
      let reduxEdges = store.getState().FlowsReducer.edges

      let storedEdges = reduxEdges;

      if (storedEdges && storedEdges.length) {
        storedEdges.push({
          source: source,
          destination: target,
        });
        dispatch(addEdges(storedEdges));
      } else {
        dispatch(
          addEdges([
            {
              source: source,
              destination: target,
            },
          ])
        );
      }

      return { newnode: newNode, edge: params };
    }
  };

  const edgeConnectionAllow = (targetType, target, newFlowsElements) => {
    let connectedEdgeCount = getEdges().filter((item) => item.target === target)?.length;

    if (targetType === `merge`) {
      if (connectedEdgeCount < 2) {

        dispatch(updateFlowsElements(newFlowsElements))
      } else {
        emitToastNotification(
          "info",
          `Maximum two input source allowed to connect transformer `
        );
      }

    } else if ((targetType === `filters`
      || targetType === `schema`
      || targetType === `aggregate`
      || targetType === `alert`
      || targetType === `target`
      || targetType === `scriptOutput`)
    ) {
      if (connectedEdgeCount < 1) {

        dispatch(updateFlowsElements(newFlowsElements))
      } else {
        emitToastNotification(
          "info",
          `Maximum one input source allowed to connect transformer `
        );
      }
    } else {
      dispatch(updateFlowsElements(newFlowsElements))
    }
  }
  const onConnect = (params) => {
    dispatch(showFlowsLogsLowerSection(false));

    if (!window.location.href.includes("viewFlows")) {
      let source = params.source;
      let target = params.target;
      let newParams = {};
      newParams = {
        ...params,
      };
      let newFlowsElements;

      let targetType = flowsElements.find((ele) => ele.id === target)?.type;
      let sourceType = flowsElements.find((ele) => ele.id === source)?.type;

      let sourceStatus;
      let transformerProgress =
        store.getState().FlowsReducer.transformerProgress;

      if (transformerProgress && transformerProgress?.length) {
        sourceStatus = transformerProgress.find((element) => {
          return element.crnt_transformer === source;
        })?.state;
      }

      if (
        sourceStatus?.toLowerCase() !== 'running'
      ) {
        if (sourceType !== 'script') {
          newFlowsElements = addEdge(newParams, flowsElements);

          edgeConnectionAllow(targetType, target, newFlowsElements)
        }

      } else if (!sourceStatus || sourceStatus !== "success") {
        emitToastNotification(
          "info",
          `Source is in ${sourceStatus ? sourceStatus : "Initial"
          } status,transformer will not connect till your transformer have "SUCCESS" status `
        );
      }
    }
  };

  const onLoad = (instance) => {
    console.log(instance, x, y, zoom, instance.getViewport())
    setTimeout(() => {

      instance.fitView()
    });

    setReactFlowInstance(instance);


  }
  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onNodeDragStop = (event, node) => {
    if (node && node.id) {
      let updatedPositionElements = flowsElements.map((ele) => {
        if (ele.id === node.id) {
          ele.position = node.position;
        }
        return ele;
      });
      updateLocalState(updatedPositionElements)
      dispatch(updateFlowsElements(updatedPositionElements));
    }
  };


  const onElementClick = (event, element) => {

    let currentElements = flowsElements.find((item) => item.id === element.id);
    dispatch(setActivePodDetails({ podName: "", dataDomain: "" }));
    dispatch(showFlowsLowerSection(false));
    dispatch(showFlowsLogsLowerSection(false));
    dispatch(setActiveTabKey(""));

    if (currentElements?.type !== "scriptOutput") {
      dispatch(showFlowsTransformdetails(true));
      dispatch(setFlowsCurrenttransformer(currentElements));
      dispatch(setCurrentScriptoutputNode({}));
    } else if (currentElements?.type === "scriptOutput") {
      dispatch(setCurrentScriptoutputNode(currentElements));

      dispatch(showFlowsTransformdetails(true));
      dispatch(setFlowsCurrenttransformer(currentElements));
    }



    // zoomOut();

  };

  const onDrop = (event) => {
    console.log(event)
    const type = event.dataTransfer.getData("application/reactflow");
    const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
    const position = reactFlowInstance.project({ x: event.clientX - left - 75, y: event.clientY - top })
    const newNode = {
      id: getId().toString(),
      type,
      position,
      content: {},
      transformerName: `${type}_${getId().toString()}_${new Date().getTime()}`,
    };
    if (newNode.id !== undefined && newNode.type !== "") {
      let temp = [...flowsElements];

      let transformerNameList = store.getState().FlowsReducer.transformerName;
      transformerNameList[
        newNode.id
      ] = `${type}_${getId().toString()}_${new Date().getTime()}`;
      dispatch(setTransformerNames({ ...transformerNameList }));
      if (type.toLowerCase() === "script") {
        store.dispatch(resetScriptRedux());
      }
      dispatch(updateFlowsElements([...temp.concat(newNode)]));
      dispatch(setTransformerMaxId(getId()));
    }
  };
  const removeSelectedClass = () => {
    let reactNodeDiv = document.getElementsByClassName("react-flow__node");
    if (reactNodeDiv && reactNodeDiv.length) {
      for (let index = 0; index < reactNodeDiv.length; index++) {
        if (reactNodeDiv[index]?.classList.contains("selected")) {
          reactNodeDiv[index].classList.remove("selected");
        }
      }
    }
  };
  const onPaneClick = () => {
    removeSelectedClass();
    setEdges((eds) => eds.map((e) => { e.style = { stroke: "", strokeWidth: "" }; e.type = ''; return e }));
    dispatch(setFlowsCurrenttransformer({}));

    dispatch(showFlowsTransformdetails(false));
    dispatch(setCurrentScriptoutputNode({}));
    dispatch(showFlowsLowerSection(false));
    dispatch(showFlowsLogsLowerSection(false));
    dispatch(showTimelineLowerSection(false))
  };
  const handleTransform = () => {

    if (reactFlowInstance && reactFlowInstance !== null) {
      reactFlowInstance.zoomOut();
      reactFlowInstance.fitView({ padding: 0.25, includeHiddenNodes: true });
    }
  };
  const onEdgeClick = useCallback((_, edge) => {

    if (!window.location.href?.includes('viewFlows') && !edge?.id?.includes('edge|')) {
      setEdges((eds) => eds.map((e) => {
        if (e.id === edge.id) { e.style = { stroke: "red", strokeWidth: "1" }; e.type = 'buttonedge' } else { e.style = { stroke: "", strokeWidth: "" }; e.type = '' }
        return e
      }))
    }

  }, []);

  const defaultViewport = { x: 200, y: 200, zoom: 1 };

  const captureFlowsImage = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library

    const imageWidth = 1024;
    const imageHeight = 768;
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    toPng(document.querySelector('.react-flow__viewport'), {
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,

        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(dataUrl => {
      if (dataUrl) {
        console.log(dataUrl)
        // dispatch(saveFlow(flowId, false)).then((res)=>{
        //   // setsaveFlowTime(getRelativeTime(new Date().toUTCString()))
        // })
      }
    })

  };


  return (
    <ReactFlow
      ref={reactFlowWrapper}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeDragStop={onNodeDragStop}
      onConnect={onConnect}
      onInit={onLoad}
      onPaneClick={onPaneClick}
      onDrop={onDrop}
      nodeTypes={nodeTypes}
      snapToGrid
      fitView={true}

      onEdgeClick={onEdgeClick}
      viewportInitialized={true}
      edgeTypes={edgeTypes}
      onDragOver={onDragOver}
      onNodeClick={onElementClick}
      nodesDraggable={isViewMode ? false : true}

      elementsSelectable={isViewMode ? false : true}
      // defaultViewport={defaultViewport}
      minZoom={0.5}
      maxZoom={1}

      nodes={nodes} edges={edges}
    >

      <MiniMap style={minimapStyle} zoomable pannable position="top-right" />
      <Background gap={30} color="#2c834" variant="dots" />

      <Controls style={{ display: "flex", flexDirection: "row" }} onFitView={(bound) => { console.log(bound) }} />
    </ReactFlow>

  );
}
const RightSideUpperContent = (props) => {

  const { isViewMode, edges, saveFlowClick, scriptOutputDataset } = props;
  const searchParams = new URLSearchParams(document.location.search);


  const history_id = searchParams.get("history_id");
  return (<>
    <ReactFlowProvider>
      {!isViewMode ? <Menubar /> : null}
      <div
        className={`reactflow-wrapper flow-canvas ${history_id ? "hide-transformer" : ""
          }`}
        style={{ height: "calc(100vh - 10px)", width: "100%" }}
      >
        <Flow {...props} />

      </div>
      <LowerMenubar />
    </ReactFlowProvider></>)

};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    showTransformDetails: state.FlowsReducer.showTransformDetails,
    saveFlowClick: state.FlowsReducer.saveFlowClick,
    edges: state.FlowsReducer.edges,
    scriptOutputDataset: state.Flows.Script.scriptOutputDataset,
    bricksOutputDataset: state.Flows.Script.bricksOutputDataset,
  };
};
export default connect(mapStateToProps)(RightSideUpperContent);
