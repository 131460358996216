import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import { setSidebarActiveRoute } from '../../../store/actions';

import LandingPageFirstHeader from "../common/LandingpageFirstHeader"
import LandingPageMain from './common/LandingPageMain';
import './userLandingPage.scss';


const UserManagementLandingPage = () => {
    const store=useStore()
    useEffect(() => {
        store.dispatch(setSidebarActiveRoute('/userManagement'))
    }, [])

    
    return (<div className="user-management-data-domain">
        <LandingPageFirstHeader title={'User Management'} />
        <LandingPageMain />
        
    </div>)
}

export default UserManagementLandingPage;