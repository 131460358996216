import React from 'react'
import SearchInput from '../../search/Search'
import './landingSearchInput.scss'

const LandingSearchInput = ({
    searchTerm = '',
    setSearchTerm = () => {},
    searchPlaceholder = ''
}) => {
    return (
        <div id='landing-search-lg' className='landing-search-lg'>
            <SearchInput
                searchData={searchTerm}
                setSearchData={setSearchTerm}
                placeholder={searchPlaceholder}
                autoFocus={true}
            />
        </div>
    )
}

export default LandingSearchInput