import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "reactstrap";
import { fetchDataDomainDetails } from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useNavigate } from "react-router-dom";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { CLOSE } from "../../../../Common/iconSource";
import Buttons from "../../../../Common/button/Buttons";
import { Literals } from "../../../common/literals";
import DeleteModal from "../../../../Common/deleteModal/DeleteModal";
import { getDeleteMessage } from "../../../common/helperFunctions";
import { ICON_REDIRECT } from "../../../../Common/newIconSource";

const DeleteDomainModal = ({ openDeleteModal, setOpenDeleteModal, deleteValue, getDataDomainListData }) => {
    const toggle = () => setOpenDeleteModal(false);
    const store = useStore();
    const history = useNavigate();
    const [inputName, setInputName] = useState("")
    const id = deleteValue?.data?.data_domain_id;
    const [userDetails, setUserDetails] = useState([]);

    useEffect(() => {
        if(openDeleteModal === true && id !== undefined)
        {
            getDataDomainDetails();
        }
        
    }, [openDeleteModal])

    useEffect(() => {
        if(openDeleteModal === false)
        {
            setUserDetails([]);
        }
    }, [openDeleteModal])

    const getDataDomainDetails = async () => {
        let response;
        try {
            response = await store.dispatch(fetchDataDomainDetails(
                { method: "GET", endPoint: `data_domain/${id}` }));
            if (response.status === "success") {
                
                setUserDetails(response?.data?.group?.group_detail);
            }
        } catch (error) { }
    }
   
    const handleDelete = async () => {
        setOpenDeleteModal(false)
        
        let response;

        try {
            response = await store.dispatch(fetchDataDomainDetails({method:"DELETE", endPoint: "data_domain/"+deleteValue?.key}))
            
            if(response.status === "success")
            {
                emitToastNotification('success', response?.message);
                
                if(getDataDomainListData){
                getDataDomainListData();}
                else
                {
                    history('/userManagement/');
                }
            }
            
        }
        catch(error){}
        
    }
    useEffect(() => {
        setInputName("");
    }, [openDeleteModal])
   
  
    return (

        <DeleteModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            body={<>
                <p className="fontSizeHeading font-weight-bold mb-2">
                { getDeleteMessage({title: 'Domain'}) }
                </p>
                <div className="mb-2">
                    <a
                    className="text-underline"
                    href={`/viewLineage/domain/${id}`}
                    target="_blank"
                    >
                    <span className="mr-1"><ICON_REDIRECT height="12" width="12" /></span>Click here
                    </a>{" "}
                    to check its Lineage.
                </div>
                <p className="fontSizeHeading font-weight-normal">
                    To confirm deletion, type <em>domain name</em> in the text input field.
                </p>
                <input 
                    type="text" 
                    // onPaste={(e)=>e.preventDefault()} 
                    // onCopy={(e)=>e.preventDefault()} 
                    // onDrop={(e)=>e.preventDefault()} 
                    placeholder='Example: type "ABCD" to delete domain ABCD' 
                    value={inputName} 
                    onChange={(e) => {setInputName(e.target.value) }} 
                    className="form-control" 
                />
            
            </>}
            onConfirm={handleDelete}
            deleteBtnDisabled={!(deleteValue?.dataDomain?.toUpperCase() === inputName.toUpperCase())}
        />


        
        // <Modal size="md" isOpen={openDeleteModal} className="create-domain-modal delete-modal" centered={true}>
        //     <ModalHeader  style={{margin:"10px",padding:"5px",}} close={<Buttons props={{ buttonClassName: "custom-btn-outline custom-btn mt-1", buttonEvent: (e) => { setOpenDeleteModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType:Literals.BTN_TERTIARY}} />} toggle={toggle}>
        //         <span style={{fontSize:"16px"}}><i className="bx bx-error"></i> Delete</span>
                
        //         </ModalHeader>
        //     <ModalBody >
        //         <div className="list">
        //             {userDetails?.length > 0 ?
        //             <p>Groups: {userDetails && userDetails.map((file,i)=> <span key={i}>{file?.name}{userDetails?.length > i + 1 ? ", " : null}</span>)}</p>
        //             : null }
        //         </div>
        //         <div className="content-list" style={{fontFamily:'interReguler',fontSize:"12px"}}>
        //             <p className="paragraph">All the credentials of users, groups and PODs associated with the selected data domain will be erased permanently. <span>To delete, type the domain name.</span></p>
        //         </div>
        //         <input type="text" onPaste={(e)=>e.preventDefault()} onDrop={(e)=>e.preventDefault()} onCopy={(e)=>e.preventDefault()} placeholder='Example: type "ABCD" to delete domain ABCD' value={inputName} onChange={(e) => {setInputName(e.target.value) }} className="form-control" />
        //     </ModalBody>
        //     <ModalFooter>
        //         <button className="btn btn-default" onClick={() => setOpenDeleteModal(false)}>Cancel</button>
        //         <button disabled={!(deleteValue?.dataDomain?.toUpperCase() === inputName.toUpperCase())} onClick={()=>handleDelete()} className="btn btn-danger">Delete</button>
        //     </ModalFooter>
        // </Modal>
    )
}

export default DeleteDomainModal;