import { SET_LOADING_STATUS} from './loadingActionTypes'

const initialState = {
    loadingStatus : false,
}

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADING_STATUS:
            return {
            ...state,
            loadingStatus: payload,
        };
        break;
        default:
            return state;
    }
}

export default reducer;