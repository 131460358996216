import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../../Common/button/Buttons';
import { CLOSE, SAVE } from '../../../Common/iconSource';
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader';
import { Literals } from '../../common/literals';
import LoadingComponent from '../../common/loadingComponent';
import ManualCreationFields from './ManualCreationFields';

const ManualCreation = (props) => {

    const history = useNavigate()

    return (
        <>
            {props.loadingStatus ? <LoadingComponent /> : null}
            <LandingpageFirstHeader
                title={"Data Dictionary"}
                flag={false}
                backRoute={"/data-dictionary"}
            />
            <div className='d-flex justify-content-between padding-2 py-1 shadow-sm' style={{borderBottom: 'solid 1px #ebebeb'}}>
                <div className='d-flex'>
                    <strong className='subtitle'>Manual Creation</strong>
                </div>
                <div className='d-flex'>
                    <Buttons
                        props={{
                            buttonText: "",
                            buttonClassName:
                                "custom-btn-outline mr-2 custom-btn btn-with-icon",
                            buttonEvent: () => {
                                history(Literals.links.CLARISTA_NAVIGATOR + '#business')
                                // history(Literals.links.DATA_DICTIONARY)
                            },
                            tooltip: "Close",
                            ImgSrc: () =>  <CLOSE />,
                            isDisable: false,
                            buttonType: Literals.BTN_TERTIARY,
                        }}
                    />
                    {/* <Buttons
                        props={{
                            buttonText: "Save",
                            buttonClassName:
                                "custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: (e) => {
                                
                            },
                            tooltip: "Save",
                            ImgSrc: () =>  <SAVE />,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    /> */}
                </div>
            </div>
            <div className='padding-2 bg-light'>
                <ManualCreationFields/>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loadingStatus: state.LoadingReducer.loadingStatus,
    };
};
export default connect(mapStateToProps)(ManualCreation);