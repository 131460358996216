import React, { useState } from "react";
import { CLOSE, DELETEICON, EDIT, FILLEDSTAR, LINEAGE_ICON, STAR, TAG, UPDATED_BY, USER } from "../../Common/iconSource";
import { Dropdown, Popover, Tooltip } from "antd";
import { Card, CardBody } from "reactstrap";
import { ICON_DASHBOARD, ICON_LOADING_CIRCLE, ICON_MINUS, ICON_PLUS } from "../../Common/newIconSource";
import Highlighter from "react-highlight-words";
import { getRelativeTime, permissions } from "../common/helperFunctions";
import Buttons from "../../Common/button/Buttons";
import { assignDashboardTag, bookmarkDashboard, unassignDashboardTag } from "../../../store/modules/analytics/dashboard/dashboardActions";
import { Literals } from "../common/literals";
import { useDispatch, useSelector } from "react-redux";
import { tags } from "../dataCatalogue/landingPage/landingPageTagHelper";
import _ from "lodash";


export const DashboardGridView = ({ 
  openDeleteDialog = () => {}, 
  editDashboard, 
  viewDashboard, 
  getSchedulerList, 
  Selectedid, 
  loadingStatus, 
  schedulerData = [], 
  searchData, 
  fromRoute,
  setData = () => {},
  activeDomain
}) => {


  return (
    <>
      <div style={fromRoute.toLocaleLowerCase() === 'schedulermodal' ? { height: "calc(100vh - 13.625rem)" } : {}} className="scheduler-gridview-responsive new-box-style">
        {
          schedulerData.map((item, i) => (
            <div key={`sch-grd-${i}-${item}`}
             
             
            >
              <GridCard
                Selectedid={Selectedid}
                item={item}
                openDeleteDialog={openDeleteDialog}
                getSchedulerList={getSchedulerList}
                fromRoute={fromRoute}
                searchData={searchData}
                editDashboard={editDashboard}
                viewDashboard={viewDashboard}
                index={i}
                setData={setData}
                activeDomain={activeDomain}
              />
            </div>
          ))
        }
      </div>
    </>
  );
};

export const GridCard = (props) => {
  const { Selectedid, item, searchData, 
    getSchedulerList, editDashboard, viewDashboard, 
    openDeleteDialog, index ,
    setData = () => {}} = props
  const [bookmarkLoading, setbookmarkLoading] = useState(false)

  const dispatch = useDispatch();
  const [optionOpen, setOptionOpen] = useState(false)
  const [tagLoader, setTagLoader] = React.useState(false)
  const [showTagPop, setShowTagPop] = React.useState(false)
  const [tagMenu, setTagMenu] = React.useState([]);

  const isEditor = !!item?.permission?.find(v => v === permissions?.dashboard?.editor)

  const tagList = useSelector(state => state.DataCatalogue?.LandingPage?.tagList);
  // console.log({item})


  React.useEffect(() => {
    setTagSubMenu([...item?.tag])
  }, [item, tagList])

  React.useEffect(() => {
    if (!optionOpen) {
      setShowTagPop(false)
    }
  }, [optionOpen])

  const addRemoveTagToPOD = (tag, remove = false) => {
    // console.log({tag_id})
    const { id: tag_id } = tag
    setTagLoader(true)
    let requestBody = {
      "tag": [tag_id]
    }
    if (!remove) {
      dispatch(assignDashboardTag(requestBody, item?.id)).then(
        res => {
          if (res.status === 'success') {
            try {
              setTagLoader(false)
              setData(prev => prev?.map(v => ({ ...v, tag: v?.id === item?.id ? [...v?.tag, tag] : v?.tag })))
              // getSchedulerList();
            }
            catch (e) {
              setTagLoader(false)
            }
          }
        }
      )
        .catch(() => {
          setTagLoader(false)
        })
    }
    else {
      dispatch(unassignDashboardTag(tag_id, item?.id)).then(
        res => {
          if (res.status === 'success') {
            setTagLoader(false)
            setData(prev => prev?.map(v => ({ ...v, tag: v?.id === item.id ? v?.tag?.filter(t => t?.id !== tag_id) : v?.tag })))
            // getSchedulerList();
          }
        }
      )
        .catch(() => {
          setTagLoader(false)
        })
    }
  }

  const tagColor = (value) => {
    var res = value.split("#");
    let colorname = res[0];
    let color = '';
    for (let x in tags) {
      if (tags[x].id === colorname) {
        color = tags[x].color;
        break;
      }
    }
    return color + '95';
  }

  const setTagSubMenu = (tags) => {
    let podTag = tags.map(t => t?.name)
    let returnList = [];
    let availableTags = _.sortBy([...tagList], [function (o) { return o?.name; }])
    // console.log({availableTags, tagList})
    availableTags?.length && availableTags.forEach((tag, x) => {
      if (!podTag.includes(tag?.name)) {
        returnList.push(
          <div onClick={(e) => { e.stopPropagation() }} key={x} className={`${x === availableTags.length - 1 ? '' : 'custom-border-bottom'} d-flex justify-content-between align-items-center padding-top-1 padding-bottom-1`}>
            <div className="d-flex align-items-center">
              <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
              <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
              </Tooltip>
            </div>
            <Buttons
              props={{
                buttonText: '',
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2 ml-2",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false);
                  addRemoveTagToPOD(tag)
                },
                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE /> : <ICON_PLUS />,
                isDisable: tagLoader,
                buttonType: Literals.BTN_TERTIARY
              }}
            />
          </div>
        )
      } else {
        returnList.push(
          <div onClick={(e) => { e.stopPropagation() }} key={x} className='d-flex justify-content-between custom-border-bottom align-items-center padding-top-1 padding-bottom-1'>
            <div className="d-flex align-items-center">
              <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
              <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
              </Tooltip>
            </div>
            <Buttons
              props={{
                buttonText: '',
                buttonClassName: "danger-btn custom-btn btn-with-icon mr-2 ml-2 border-0",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false);
                  addRemoveTagToPOD(tag, true)
                },
                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE /> : <ICON_MINUS />,
                isDisable: tagLoader,
                buttonType: Literals.BTN_TERTIARY
              }}
            />
          </div>
        )
      }
    })
    if (returnList.length) {

      setTagMenu([...returnList])
    } else {
      setTagMenu([])

    }
  }

  const tagPopover = {
    header: () => {
      return <>
        <div className="d-flex justify-content-between custom-border-bottom padding-2">
          <p className="section-heading m-0">Tags</p>
          <div className="d-flex">
            <Tooltip title='Close' placement="bottom">
              <button className="custom-btn-outline custom-btn btn-with-icon"
                onClick={function (e) {
                  e.stopPropagation()
                  setShowTagPop(false)
                }}
              >
                <CLOSE />
              </button>
            </Tooltip>
            {/*  <Buttons
              props={{
                buttonText: 'TAG',
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                tooltip: 'Add Tag',
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false)
                  openAddTag()
                },
                ImgSrc: () => <PLUS />,
                isDisable: false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
              }}
            /> */}
          </div>
        </div>
      </>
    },
    body: () => {
      return <>
        <div className="padding-2 card-tags-auto-overflow tag-popover-body" style={{ maxHeight: '12.5rem', overflowY: 'auto' }}>
          {
            tagMenu.length
              ? tagMenu.map((val, i) => <span key={`tag-pop-itm-${i}`}>{val}</span>)
              : <div className="py-1 text-center text-black-50">No Tag Found</div>
          }
        </div>
      </>
    }
  }

  const handleVisibleChange = (visible) => {
    setShowTagPop(visible)
    if (visible) {
      setTagSubMenu(item?.tag)
    }
  }

  const optionList = [

    {
      label: <span className={`dbox-opt-itm ${!isEditor ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          editDashboard(item)
        }}>
        <EDIT />
        Edit
      </span>,
      key: '1',
      disabled: !isEditor
    },
    {
      label: <span className={`dbox-opt-itm ${!isEditor ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          let path = `/viewLineage/Dashboard/${item.id}`;
          window.open(path)
        }}>
        <LINEAGE_ICON />
        Show Lineage
      </span>,
      key: '2',
      disabled: !isEditor
    },
    {
      label: <Popover placement="right"
        title={tagPopover.header}
        content={tagPopover.body}
        trigger="hover"
        autoAdjustOverflow={true}
        open={showTagPop}
        onOpenChange={handleVisibleChange}
        destroyTooltipOnHide={true}
      >
        <span className="dbox-opt-itm"
          onClick={(e) => {
            e.stopPropagation();
            setShowTagPop(true)
            setTagSubMenu(item?.tag)
          }}>
          <TAG />
          Tags
        </span>
      </Popover>,
      key: '3',
    },
    {
      label: <span className={`dbox-opt-itm danger ${!isEditor ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!isEditor) return
          openDeleteDialog(item.id);
        }}>
        <DELETEICON />
        Delete
      </span>,
      key: '4',
      disabled: !isEditor
    },

  ]

  return <>
    <Card id={`dashboard${index}`} className={
      Selectedid && Selectedid === item.id
        ? "position-relative dashboard-grid selected-border cursor-pointer"
        : "position-relative dashboard-grid cursor-pointer "
    } onClick={() => { viewDashboard(item) }}>
      <CardBody>

        <div className='data-view-dbox' onClick={() => { }}>
          <div className='_src_img'>
            <div className="scheduler-icon-box">
              <span className="primary-svg-color">  <ICON_DASHBOARD height='18' width='24' /></span>
            </div>
          </div>
          <div className='desc-box'>
            <div className="d-flex align-items-start">
              <Tooltip placement='topLeft' title={item?.name?.toUpperCase()}>
                <label style={{ width: 'calc(100% - 3.25rem)' }} className=' paragraph mt-1 title-txt font-w-600 text-uppercase text-with-ellipsis'>
                  <Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.name ? item.name : ''}> {item.name}</Highlighter>
                </label>
              </Tooltip>
              {
                item?.published
                  ?
                  <span className="position-absolute" style={{ bottom: '-1.875rem',right:'0px' }}>
                    {/* right: -2, */}
                    <label
                      className={'publish-tag-pod'}
                    >
                      {'Published'}
                    </label>
                  </span>
                  : ''
              }
              <Buttons
                props={{
                  buttonText: '',
                  tooltip: item?.bookmark
                    ? Literals.REMOVE_FAVOURITES
                    : Literals.ADD_FAVOURITES,
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-4",
                  buttonEvent: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setbookmarkLoading(true)

                    dispatch(bookmarkDashboard(item?.bookmark ? 'DELETE' : "GET", item?.id)).then((res) => {
                      setbookmarkLoading(false)
                      getSchedulerList();
                    }).catch((e) => {
                      setbookmarkLoading(false)

                    })

                  },
                  ImgSrc: () => bookmarkLoading ? <ICON_LOADING_CIRCLE /> : item?.bookmark ? <FILLEDSTAR /> : <STAR />,
                  isDisable: false,
                  buttonType: Literals.BTN_TERTIARY
                }}
              />
              {
                <>
                  <Dropdown
                    menu={{
                      items: optionList,
                      onClick: (e) => e.domEvent.stopPropagation()
                    }}
                    trigger={["hover"]}
                    placement="bottomRight"
                    onOpenChange={(bool) => setOptionOpen(bool)}
                    overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                    destroyPopupOnHide={true}
                  >
                    <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                  </Dropdown>
                </>

              }
            </div>
            {/* <div>
              <Tooltip placement='topLeft' title={item.description}>
                <p className='mb-1 paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                  {item.description?.length > 0 ?
                    <Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.description ? item.description : ''}>{item.description}</Highlighter>
                    : "No description has been given yet."}
                </p>
              </Tooltip>
            </div> */}
            <ul className="landingCard-profile-list" style={{ marginTop: '1.125rem' }}>
              <li className="landingCard-profile-listitem d-flex align-items-center justify-content-between">
                <div style={{ flexBasis: 'calc(100% - 3.75rem)' }}>
                  <UPDATED_BY width="15 " height="15" />
                  <span className="label label-gray ml-2">{'Last Updated'} </span>
                  {/* <Tooltip placement="topLeft" title={getRelativeTime(new Date(item.updated_on), undefined)}> */}
                  <span className="paragraph ml-1 ant-tooltip-open">
                    <Tooltip placement="top" title={getRelativeTime(item?.updated_on, undefined)}>
                      {getRelativeTime(item?.updated_on)}
                    </Tooltip>

                  </span>
                  {/* </Tooltip> */}
                </div>

              </li>
              <li className="landingCard-profile-listitem">
                <div className="align-items-end d-flex w-100" style={{ paddingLeft: '0px' }}>
                  <USER width="22" height="15" />
                  <span className="label label-gray ml-2" style={{ flexBasis: '6.25rem' }}>{'Updated By'} </span>
                  <Tooltip placement="topLeft" title={item?.updated_by}>
                    <span className="paragraph d-block text-with-ellipsis ant-tooltip-open label-color ml-1">
                      {/* <span>{item?.updated_by_user}</span>  */}
                      <Highlighter
                        searchWords={[searchData?.toLocaleLowerCase()]}
                        autoEscape={true}
                        textToHighlight={item?.updated_by ?? ''}>
                        {item?.updated_by}
                      </Highlighter>
                    </span>
                  </Tooltip>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </CardBody>
    </Card>
  </>
}