import React from "react";
import { Literals } from "../../common/literals";
import { Tooltip } from "antd";

const DetailDescTile = ({ domainDetails }) => {
  const { icon, name, description } = domainDetails;
  return (
    <div className="card domain-details-container">
      <div className="d-flex align-items-center domain-details-header">
        {icon}
        <Tooltip placement="top" title={name}>
          <span className="domain-details-title text-uppercase">{name ? name : null}</span>
        </Tooltip>
      </div>
      <div className="domain-details-description">
        <label className="label">{Literals.DESCRIPTION}</label>
        <Tooltip placement="topLeft" trigger={"hover"} title={description}>
          <p className="custom-ellipsis two-line-ellipsis">

            {description}

          </p>
        </Tooltip>
      </div>
    </div>
  );
};

export default DetailDescTile;
