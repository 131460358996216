import React from 'react'
import './newDataGridTable.scss'
import SearchInput from '../../search/Search'
import { Badge, Checkbox, Empty } from 'antd'
import { ContentViewGridTable } from '../../contentGridTable/ContentViewGridTable'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux'
import { getPreview } from '../../../../store/modules/dataCatalogue/preview/previewActions'
import ClaristaLoader from '../../claristaLoader/ClaristaLoader'
import { getReloadErrorTemplate, pxToRem, remToPx } from '../../../modules/common/helperFunctions'
import _ from 'lodash'
import { ICON_LOADING_CIRCLE } from '../../newIconSource'

const DataGridFilterByValue = React.forwardRef(({
    column = {},
    colFilterRef = {},
    tableName = '',
    domainName = '',
    tableId = '',
    setParentFilterValues = () => { },
    setFilterByValData = () => { },
    isCustomSql = false,
    staticQuery,
    uniqUUID
}, ref) => {

    const dispatch = useDispatch()

    const [error, setError] = React.useState(undefined)
    const [loading, setLoading] = React.useState(false)
    const [bgLoading, setBgLoading] = React.useState(false)
    const [data, setData] = React.useState([])
    const [selectAll, setSelectAll] = React.useState(false)
    const [invert, setInvert] = React.useState(false)
    const [searchTerm, setSearchTerm] = React.useState('')
    const [sortSelected, setSortSelected] = React.useState(false)

    const activePodDetails = useSelector(state => state.CommonComponent?.VirtualDataTable?.activePodDetails)
    const filterColumnMap = useSelector(state => state.CommonComponent?.DataGridTable?.filterArrayMap)
    const filterColumnList = filterColumnMap[uniqUUID] ?? []

    const gridRef = React.useRef({})
    const searchInputRef = React.useRef({})

    const limit = 10000

    React.useImperativeHandle(ref, () => {
        return {
            setIsInvert: (invert) => {
                setInvert(invert)
            },
            resetSelectedValues: () => {
                setData(prev => prev?.map(d => ({ ...d, checked: false })))
            },
            setFilterValues: (arr) => {
                setData([...arr])
            }
        }
    }, [])

    React.useEffect(() => {
        if (colFilterRef.current[column?.name]?.valuesLoaded && filterColumnList?.length === 0) {
            setData([...colFilterRef.current[column?.name].column_values])
            setInvert(colFilterRef.current[column?.name]?.invert)
        }
        else if(filterColumnList?.length > 0) {
            fetchValues(colFilterRef.current[column?.name].column_values?.filter(c => c?.checked))
            setInvert(colFilterRef.current[column?.name]?.invert)
        }
        else fetchValues()
    }, [])

    React.useEffect(() => {
        if (searchTerm?.trim() !== '') {
            getSearchResults(searchTerm?.toLowerCase()?.trim())
        }
    }, [searchTerm])

    const getQuery = () => {
        if (isCustomSql && staticQuery && staticQuery !== '') {
            return `${staticQuery}`
            // return `SELECT DISTINCT ${column?.name} FROM (${staticQuery})`
        } else if (activePodDetails?.connectionName?.length && activePodDetails?.from === 'flows') {
            return `SELECT DISTINCT ${column?.name} FROM ${activePodDetails?.connectionName?.length && activePodDetails?.from === 'flows' ? activePodDetails?.connectionName + '.' : ''} ${domainName}.${tableName}`

        }
        return `SELECT DISTINCT ${column?.name} FROM ${domainName}.${tableName}`
    }

    const fetchValues = (col_val = []) => {
        setLoading(true)
        setError(undefined)
        let uuid = uuidv4()
        let request = {
            connection_name: (activePodDetails?.from === 'flows' && activePodDetails?.connectionName?.length)
                             ? activePodDetails?.connectionName : 'hive',
            uuid: uuid,
            page: 0,
            limit,
            do_count: false,
            distinct: true,
            table_ids: tableId?.length ? [tableId] : [],
            table_name: tableName,
            schema_name: domainName,
            column_list: [column?.name],
            sort_body: [
                {
                  "column": column?.name,
                  "sort_criteria": "asc"
                }
            ],
            filter_body: [
                ...filterColumnList?.filter(f => f?.column !== column?.name)
            ]
        }

        if(isCustomSql && staticQuery && staticQuery !== '') {
            request.query = getQuery()
        }

        dispatch(getPreview(request, false, false))
            .then((res) => {
                let dat = res?.data?.result?.data_record?.flat()?.map(d => (d === null || d?.toString()?.trim() === "") ? "" : d)
                dat = _.uniq(dat)

                if(col_val) {
                    dat = dat?.filter(c => !col_val?.map(v => v?.name)?.includes(c))
                }
                
                const list = dat?.map((d, i) => ({ name: d?.toString(), checked: false, id: i }))

                // Assinging Id's again as "col_val" and "list" id's can be same for selected values
                const combinedList = [...col_val, ...list]?.map((d, i) => ({ ...d, id: i }))

                colFilterRef.current[column?.name] = {
                    ...colFilterRef.current[column?.name],
                    column_values: [...combinedList],
                    valuesLoaded: true
                }

                setData([...combinedList])
                setLoading(false)

                searchInputRef.current.focus()
            })
            .catch((err) => {
                setError(err?.message ?? 'Error!')
                setLoading(false)
            })
    }

    const getSearchResults = (searchText) => {
        // LOWER(CAST("column_name" AS VARCHAR))

        if(data?.length < 10000) return

        let query = `SELECT DISTINCT ${column?.name} FROM ${domainName}.${tableName} 
        WHERE LOWER(CAST("${column?.name}" AS VARCHAR)) LIKE '%${searchText}%'`

        if (isCustomSql && staticQuery && staticQuery !== '') {
            query = `SELECT DISTINCT ${column?.name} FROM (${staticQuery}) 
            WHERE LOWER(CAST("${column?.name}" AS VARCHAR)) LIKE '%${searchText}%'`
        }

        setBgLoading(true)
        let uuid = uuidv4()
        let request = {
            uuid: uuid,
            page: 0,
            limit,
            do_count: false,
            distinct: true,
            table_ids: [tableId],
            table_name: tableName,
            schema_name: domainName,
            column_list: [column?.name],
            connection_name: (activePodDetails?.from === 'flows' && activePodDetails?.connectionName?.length)
                             ? activePodDetails?.connectionName : 'hive',
            sort_body: [
                {
                  "column": column?.name,
                  "sort_criteria": "asc"
                }
            ],
            filter_body: [
                {
                    "column": column?.name,
                    "criteria": "like",
                    "value": `%${searchText}%`,
                    "datatype": column?.dtype,
                    "operation": "and",
                    "objCriteria": "like",
                    "objValue": searchText,
                    "date_factor": ""
                },
                ...filterColumnList?.filter(f => f?.column !== column?.name)
            ]
        }

        if(isCustomSql && staticQuery && staticQuery !== '') {
            request.query = getQuery()
        }

        dispatch(getPreview(request, false, false))
            .then((res) => {
                const dat = res?.data?.result?.data_record?.flat()
                const list = dat?.map(d => ({ name: (d === null || d?.toString()?.trim() === "") ? "" : d?.toString(), checked: false, id: null }))
                setData(prev => {
                    let arr = [...prev, ...list]
                    arr = _.uniqBy(arr, 'name')
                    return arr?.map((d, i) => ({ ...d, id: i }))
                })

                setBgLoading(false)
                
                setTimeout(() => {
                    searchInputRef.current.focus()
                }, 500)
            })
            .catch(() => {
                setBgLoading(false)
            })
    }

    const setValues = (checked, id) => {
        setData(prev => {
            prev = prev?.map(v => ({ ...v, checked: v?.id === id ? checked : v?.checked }))
            colFilterRef.current[column?.name].column_values = prev
            return prev
        })
    }

    const onSelectAll = (e) => {
        let checked = e?.target?.checked
        setSelectAll(checked)
        setData(prev => {

            if (searchTerm?.trim() === "") {
                prev = prev?.map(v => ({ ...v, checked: checked }))
            }
            else {
                prev = prev?.map(v => ({
                    ...v,
                    checked: v?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ? checked : v?.checked
                }))
            }
            colFilterRef.current[column?.name].column_values = prev
            return prev
        })
    }

    
    const isJson = (string) => {
        try { 
          return !!(JSON?.parse(string))
        } catch { 
          return false 
        }
      }
  
    const isDoc = (json) => {
        try {
            let docNm = Object?.keys(json)?.includes('document_name')
            let docPfx = Object?.keys(json)?.includes('prefix')
            return docNm && docPfx
        }
        catch {
            return false
        }
    }

    const nameTemplate = (value) => {
        if(isJson(value) && isDoc(JSON.parse(value))) {
            return JSON.parse(value)?.document_name
        }
        return value
    }

    const valuesList = React.useMemo(() => {
        let dat = [...data]
        setFilterByValData([...dat])

        /**
         * Do not remove this sorting code
         */
        /* dat = dat.sort((a, b) => {
            if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
                return -1;
            }
            if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
                return 1;
            }
            return 0;
        }) */

        if (searchTerm !== '') {
            dat = _.sortBy(dat, [
                o => !o?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()?.trim())
            ])?.filter(d => d?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()?.trim()))
        }

        dat = _.sortBy(dat, [
            o => !(o?.name === undefined || o?.name?.trim() === "")
        ])

        if (dat?.length > 0)
            setSelectAll(dat?.every(d => d?.checked))

        if (sortSelected)
            dat = _.sortBy(dat, [
                o => !o?.checked
            ])

        setParentFilterValues([...dat])

        let list = dat?.map(d => (
            <div key={`val-${d?.id}-li-${d?.name}`}
                className='d-flex h-100 align-items-center'
                style={{ lineHeight: 1.4 }}
            >
                <Checkbox
                    key={`${d?.name}-checkbox`}
                    id={`${d?.name}Checkbox`}
                    value={d?.name}
                    checked={d?.checked}
                    onChange={e => {
                        let chk = e?.target?.checked
                        setValues(chk, d?.id)
                    }}
                    disabled={loading}
                />
                <label
                    htmlFor={`${d?.name}Checkbox`}
                    className='d-block fontSizeLabel mb-0 ml-2 text-dark text-with-ellipsis'
                    title={nameTemplate(d?.name?.toString())}
                    style={{ fontFamily: 'InterRegular', cursor: 'pointer', maxWidth: '100%', width: 'fit-content' }}
                >
                    {   
                        (d?.name === undefined || d?.name?.trim() === "") 
                        ? <span style={{lineHeight: '1.5'}} className='px-1 bg-light border rounded'>(BLANK/NULL)</span>
                        : nameTemplate(d?.name?.toString())
                    }
                </label>
            </div>
        )) ?? []

        return list

    }, [data, bgLoading, searchTerm, sortSelected])

    return (
        <div className='new-dgt-flt-val-comp' style={{ fontFamily: 'InterRegular' }}>
            <div className='d-flex align-items-center justify-content-between'>
                <div></div>
                <div className='action-part d-flex align-items-center mb-2'
                    title='Exculdes the selected values'
                >
                    <Checkbox
                        checked={invert}
                        onChange={(e) => {
                            setInvert(e?.target?.checked)
                            colFilterRef.current[column?.name].invert = e?.target?.checked
                        }}
                    /> <label className='mb-0 fontSizeLabel ml-1 pl-1'>Exclude</label>
                </div>
            </div>


            <div className='mb-2'>
                <SearchInput
                    searchData={searchTerm}
                    setSearchData={setSearchTerm}
                    useDelay={2000}
                    autoFocus={true}
                    isDisable={loading || bgLoading}
                    hideLoader={true}
                    ref={searchInputRef}
                />
            </div>
            <div className='d-flex align-items-center justify-content-between pb-1'>
                <div className='d-flex align-items-center'>
                    <Checkbox
                        checked={selectAll}
                        onChange={onSelectAll}
                        disabled={loading || data?.length === 0}
                    /> <label className='mb-0 fontSizeLabel ml-1 pl-1'>Select All</label>
                </div>
                <div className='d-flex align-items-center'>
                    <span
                        title='Show selected values on the top'
                        className={`d-flex align-items-center ${data?.filter(d => d?.checked)?.length === 0 ? '' : ''}`}>
                        <Checkbox
                            checked={sortSelected}
                            onChange={(e) => {
                                setSortSelected(e?.target?.checked)
                                gridRef?.current?.scrollToRow(0)
                            }}
                        /> <label className='mb-0 fontSizeLabel ml-1 pl-1'>Sort Selected</label>
                    </span>
                    {
                        data?.filter(d => d?.checked)?.length > 0
                            ? <div className='vertical-separator' style={{ height: 14 }}></div>
                            : ''
                    }
                    <Badge className='custom-tag' count={data?.filter(d => d?.checked)?.length} />
                </div>
            </div>
            <div className='flt-val-tbl' style={{ height: error ? 'auto' : 90 }}>
                {
                    loading
                        ?
                        <ClaristaLoader />
                        :
                        error
                            ?
                            getReloadErrorTemplate({ errorMessage: error, onReload: () => fetchValues() })
                            :
                            valuesList?.length === 0
                                ?
                                <div>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                                :
                                <ContentViewGridTable
                                    colsNumber={1}
                                    data={valuesList}
                                    rowHeight={remToPx(pxToRem(30))}
                                    ref={gridRef}
                                />
                }
            </div>
            {
                bgLoading
                    ? <div className='small py-2'><ICON_LOADING_CIRCLE height='13' width='13' /> <span className='ml-1'>Searching in database...</span></div>
                    : ''
            }
        </div>
    )
})

export default DataGridFilterByValue