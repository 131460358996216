import React, { useState, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  Modal,
  
  ModalBody,
  ModalHeader,
  Card,
  Form,
  Progress,
  ModalFooter,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { setUploadDocFlag } from "../../../../../store/modules/dataCatalogue/summary/summaryActions";
import "toastr/build/toastr.min.css";
import { uploadFile, setUploadedFiles } from "../../../../../store/modules/dataCatalogue/summary/summaryActions";
import { get } from "lodash";
import { uploadFile as uploadBussinessTerm,downloadTemplate } from "../../../../../store/modules/dataDictionary/action";

import { Literals } from "../../../common/literals";
import { BROWSEDOCUMENT, CLOSE, CSV_ICON, DOC_ICON, DRAGDOCUMENT, HTML_ICON, PDF_ICON, SAVE, TXT_ICON, UPLOADDOCUMENT ,RIGHTARROW, DOWNLOAD} from "../../../../Common/iconSource"
import Buttons from '../../../../Common/button/Buttons'
import { emitToastNotification } from "../../../../../helpers/toast_helper";

const UploadDocModal = ({ getSummaryDocument, tableId ,type,setUploadData,isDownload}) => {
  const store = useStore();
  const inputRef = useRef();
  const dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [, setIsDeleteIconVisible] = useState(false);
  const catalogueSummary = useSelector((state) => state.DataCatalogue.Summary);
  const uploadDocFlag = get(catalogueSummary, `uploadDocFlag`, "");
  const [checkbar, setCheckbar] = useState({});
  const filesUploaded = useSelector(state => state.DataCatalogue.Summary.uploadedFiles)

  const handleAcceptedFiles = (files) => {
    let uploadedFiles = selectedFiles;
    if (files.length === 0) {
      emitToastNotification('info', "file format not supported")
      
    }
    for (let x = 0; x < files.length; x++) {
      if (!fileNameCheckExistence(files[x].name) && fileTypeCheck(files[x].name)) {
        if (files[x].size <= 6291456) {
          uploadedFiles.push(files[x]);
          setCheckbar((preVal) => {
            return {
              ...preVal,
              [files[x].name]: 0,
            };
          });
          if(type)
            uploadBussinessTermSubmit([files[x]])
          else
            upoadFileSubmit([files[x]]);
        } else {
          emitToastNotification('info', "file size more than 6MB")
          
        }
      } else {

      }
    }

    setSelectedFiles([...uploadedFiles]);
  };


  function fileNameCheckExistence(fileName) {
    let value = false;
    selectedFiles.forEach((file) => {
      if (file.name === fileName) {
        value = true;
        emitToastNotification('info', "file already exist in the list")
        
      }
    });

    return value;
  }

  function fileTypeCheck(fileName) {
    let value = true;
    if (
      new RegExp(".*.pdf$").test(fileName) ||
      new RegExp(".*.htm$").test(fileName) ||
      new RegExp(".*.html$").test(fileName) ||
      new RegExp(".*.doc$").test(fileName) ||
      new RegExp(".*.docx$").test(fileName) ||
      new RegExp(".*.docm$").test(fileName) ||
      new RegExp(".*.dot$").test(fileName) ||
      new RegExp(".*.dotx$").test(fileName) ||
      new RegExp(".*.dotm$").test(fileName) ||
      new RegExp(".*.xml$").test(fileName) ||
      new RegExp(".*.xps$").test(fileName) ||
      new RegExp(".*.wps$").test(fileName) ||
      new RegExp(".*.rtf$").test(fileName) ||
      new RegExp(".*.odt$").test(fileName) ||
      new RegExp(".*.csv$").test(fileName) ||
      new RegExp(".*.xls$").test(fileName) ||
      new RegExp(".*.xlsx$").test(fileName) ||
      new RegExp(".*.txt$").test(fileName))
      {

    }
    else {
      value = false;
      emitToastNotification('info', "file format not supported")
      
    }
    return value;
  }


  const renderDocumentIconHandler = (fileName) => {
    if (new RegExp(".*.pdf$").test(fileName)) {
      return <PDF_ICON />
    }

    if (new RegExp(".*.doc$").test(fileName) ||
      new RegExp(".*.docx$").test(fileName) ||
      new RegExp(".*.docm$").test(fileName) ||
      new RegExp(".*.dot$").test(fileName) ||
      new RegExp(".*.dotx$").test(fileName) ||
      new RegExp(".*.wps$").test(fileName) ||
      new RegExp(".*.dotm$").test(fileName)) {
    }

    if (new RegExp(".*.txt$").test(fileName)) {
      return <TXT_ICON />
    }
    if (new RegExp(".*.html$").test(fileName)) {
      return <HTML_ICON />
    }
    if (new RegExp(".*.xls$").test(fileName) || new RegExp(".*.xlsx$").test(fileName)) {
      return <CSV_ICON />
    }

    return <PDF_ICON />
  }




  const uploadBussinessTermSubmit=async(filex)=>{
    filex.forEach(async (file) => {
      let response;
      let ext=file?.name?.split(".")[1];
      let requestBody = {
        
        file_type:ext??"csv"
        
      };
      let formData = new FormData();
      let obj = requestBody;
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          var val = obj[key];
          formData.append(key, val);
        }
      }
      formData.append("file", file);
      const onUploadProgress = (progress) => {
        const { loaded, total } = progress
        const percentageProgress = Math.floor((loaded / total) * 100)
    
        if (percentageProgress < 100) {
          setIsDeleteIconVisible(false);
          setCheckbar((preVal) => {
            return {
              ...preVal,
              [file.name]: percentageProgress,
            };
          });
        }
        else {
          setIsDeleteIconVisible(true);
        }
      };
    

      try {
        response = await dispatch(uploadBussinessTerm(formData,onUploadProgress));
       console.log(response);
       setCheckbar((preVal) => {
        return {
          ...preVal,
          [file.name]: 100,
        };
      });
      setUploadData(response.data);

      } catch (error) {
      closeModal();

        // setCheckbar((preVal) => {
        //   return {
        //     ...preVal,
        //     [file.name]: 0,
        //   };
        // });
      }
    });
  }
  const closeModal=()=>{
    if(type){
      setUploadData([])
    }
    dispatch(setUploadDocFlag(false)); 
    if(selectedFiles.length) {
      getSummaryDocument();
    }
    setSelectedFiles([]);
  }
  const upoadFileSubmit = async (filex) => {
    filex.forEach(async (file) => {
      let response;
      let ext=file.name.split(".")[1];
      let requestBody = {
        
        table: tableId,
        extension:ext ?JSON.stringify([ext]):JSON.stringify(["txt"]),
        file_name: file.name,
        
      };
      let formData = new FormData();
      let obj = requestBody;
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          var val = obj[key];
          formData.append(key, val);
        }
      }
      formData.append("file", file);
      const onUploadProgress = (progress) => {
        const { loaded, total } = progress
        const percentageProgress = Math.floor((loaded / total) * 100)
    
        if (percentageProgress < 100) {
          setIsDeleteIconVisible(false);
          setCheckbar((preVal) => {
            return {
              ...preVal,
              [file.name]: percentageProgress,
            };
          });
        }
        else {
          setIsDeleteIconVisible(true);
        }
      };
     

      try {
        response = await store.dispatch(uploadFile(formData, onUploadProgress));
        if (response?.status === "success") {
          dispatch(setUploadedFiles([...filesUploaded, file]))
          setCheckbar((preVal) => {
            return {
              ...preVal,
              [file.name]: 100,
            };
          });
        }
      } catch (error) {

        setCheckbar((preVal) => {

          return {
            ...preVal,
            [file.name]: -1,
          };
        });
      }
    });
  };
  return (
    <Modal size="md" isOpen={uploadDocFlag} className="custom-modal">
      <ModalHeader>
        <div className="d-flex align-items-center upload-doc-pod-det">
          <UPLOADDOCUMENT />
          <h2 className="section-heading">{Literals.UPLOAD_YOUR_FILES}</h2>
        </div>
        <Buttons props={{
          buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {
          closeModal();
          }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
        }} />
      </ModalHeader>
      <ModalBody>
        <Form>
          <Dropzone
            onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
          >
                        {/* accept=".pdf,.html,.doc,.txt" */}

            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <DRAGDOCUMENT />
                  <h4 className="label mt-1 mb-2">{Literals.DRAG_AND_DROP_YOUR_FILES}</h4>
                  <h6 className="paragraph m-0">
                    {type?Literals.UPLOAD_BUSSINESS_TERM_INFO : Literals.UPLOAD_DOC_INFO}
                  </h6>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews" id="file-previews">
            {selectedFiles &&
              selectedFiles.map((f, i) => {
                return (
                  <Card key={i + "-file"}>
                    <ul className="progress-list">
                      <li>
                        {renderDocumentIconHandler(f.name)}
                      </li>
                      <li>
                        <div className="d-flex mb-1">
                          <p className="label m-0 mr-2 custom-ellipsis one-line-ellipsis">
                            {f.name}
                          </p>
                          <p className="label m-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                          <p className="label m-0">
                            {checkbar[f.name] !== -1
                              ? checkbar[f.name] + "%"
                              : Literals.UPLOAD_DOC_FAIL}
                          </p>
                        </div>
                        <Progress
                          color={
                            new RegExp(".*.pdf$").test(f.name) || 
                            new RegExp(".*.doc$").test(f.name) || 
                            new RegExp(".*.docx$").test(f.name) || 
                            new RegExp(".*.docm$").test(f.name) || 
                            new RegExp(".*.dot$").test(f.name) || 
                            new RegExp(".*.dotx$").test(f.name) || 
                            new RegExp(".*.dotm$").test(f.name) || 
                            new RegExp(".*.xml$").test(f.name) || 
                            new RegExp(".*.xps$").test(f.name) || 
                            new RegExp(".*.wps$").test(f.name) || 
                            new RegExp(".*.rtf$").test(f.name) || 
                            new RegExp(".*.odt$").test(f.name) || 
                            new RegExp(".*.csv$").test(f.name) || 
                            new RegExp(".*.xls$").test(f.name) || 
                            new RegExp(".*.xlsx$").test(f.name) || 
                            new RegExp(".*.html$").test(f.name) ||
                            new RegExp(".*.htm$").test(f.name) ||
                            new RegExp(".*.txt$").test(f.name)
                              ? "success"
                              : "danger"
                          }
                          value={checkbar[f.name] !== -1 ? checkbar[f.name] : 0}
                        ></Progress>
                      </li>
                      <li>
                       
                      </li>
                    </ul>
                  </Card>
                );
              })}
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <input
          ref={inputRef}
          style={{ display: "none" }}
          type="file"
          
          multiple
          onChange={(e) => handleAcceptedFiles(e.target.files)}
        />

        {isDownload? <Buttons props={{
          buttonText: "Template", buttonClassName: "custom-btn-primary custom-btn btn-with-text mr-2", buttonEvent: () => {
           dispatch(downloadTemplate()).then(res=>{


            let content = res;
            const file = new File([content], `Template.zip`, { type: 'application/zip' })
            var FileSaver = require('file-saver');
            FileSaver.saveAs(file);
          
           })
          }, ImgSrc: () => <DOWNLOAD />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
        }}
        />:""}
      { selectedFiles.length>0?"": <Buttons props={{
          buttonText: Literals.BUTTON_BROWSE, buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => {
            inputRef.current
              .click()
          }, ImgSrc: () => <BROWSEDOCUMENT />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
        }}
        />}

        {selectedFiles?.length ? (
          <Buttons props={{
            buttonText:type?"Next": Literals.BUTTON_SAVE, buttonClassName: "custom-btn-primary custom-btn btn-with-text ml-2", buttonEvent: () => {
              dispatch(setUploadDocFlag(false));
              getSummaryDocument();
              setSelectedFiles([]);
            }, ImgSrc: () =>type?<RIGHTARROW/>: <SAVE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
          }}
          />
        ) :
          null
        }
      </ModalFooter>
    </Modal>
  );
};
export default UploadDocModal;
