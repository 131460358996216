import React from 'react'
import { useDispatch } from 'react-redux'
import { SET_ACTIVE_UM_TAB_NAME } from '../../../../../store/modules/UserManagement/userGroup/UMActionTypes'
import { Literals } from '../../../common/literals'
import DataDomainDetails from '../../dataDomains/components/DataDomainDetails'

function UserGroupDetailsView() {
  let dispatch=useDispatch()
  dispatch({type:SET_ACTIVE_UM_TAB_NAME,payload:Literals.USERGROUP})

    return (
        <div>
          <DataDomainDetails isUserGroups={true} tab={Literals.USERGROUP}/>  
        </div>
    )
}

export default UserGroupDetailsView
