
import {
    SET_CREATE_RULE_PAGE_DEFAULT,
    SET_CONDITION_CRITERIA,
    SET_DISTINCT_ROWS,
    SET_FILTER_CRITERIA,
    SET_SELECETED_RULES_COLUMN,
    SET_UNIQUENESS_RULES_COLUMN,
    SET_RULE_DETAIL,
    SET_RECENTRUNS_DATA,
    SET_ALERT_LIST,
    SET_RULES_CATEGORY,
    SET_RULES_TYPE,
    SET_RULES_QUERY_RESULT,
    SET_PREVIEW_TABLE_RESPONSE,
    SET_RULES_PUBLISH, SET_RULES_PREVIEW_QUERY,
    SET_RULES_PREVIEW_FLAG,
    SET_RULES_REFRESH,
    SET_RETRIVE_RULE_RESPONSE, SET_SEARCH_RESULT_NOT_FOUND,SET_SEARCH_VALUE,
    SET_RULES_PREVIEW_TABLE, SET_ALL_DATA_LOADED, RESET_NEXT_PAGE_COUNTER, SET_NEXT_PAGE_COUNTER, RESET_TABLE_RECORDS, SET_RULES_PREVIEW_TABLE_ROWSET, RESET_NEXT_RECORD_TOKEN, SET_RULES_PREVIEW_TABLE_RECORDS, SET_RULES_PREVIEW_INITIAL_STATE, SET_RULES_PREVIEW_NEXT_URI, SET_RULES_PREVIEW_FILTERS, SET_RULES_PREVIEW_SORT, SET_RULES_PREVIEW_SEARCH_TERM, SET_CORELATION_GRAPH, SET_QUERY_ERROR, SET_RULES_SCHEDULE_BODY, SET_RULES_LOGICAL_RULE, SET_RULES_RETRIEVE_DATA, SET_RULES_JSON_TREE, SET_RULES_COMPLEX_QUERY,
    SET_DOMAIN_LIST,
    SET_PURPOSE_LIST
} from './rulesActionTypes'

const initialState = {
    appliedFilterCriteria: [],
    completnessColumn: [],
    uniquenessColumn: [],
    conditionCriteria: [],
    distinctRows: [],
    previewPageData: {},
    previewTables: [],
    tableRecords: [],
    query: '',
    filters: [],
    sort: [],
    previewFlag: '',
    sqlEditorQuery: '',
    queryErrorDetail: '',
    pageCounter: 0,
    complexRuleQuery: '',
    isAllDataLoaded: false,
    ruleDetail: {},
    recentRunsData: [],
    alertList: undefined,
    isRulesPublish: false,
    ruleCategory: '',
    ruleType: '',
    queryResult: {},
    scheduleBody: {
        "frequency": '*',
        "startHour": '*',
        "startMinute": '*',
        "Month": '*',
        "DayMonth": '*',
        "Day": '*',
        "cron": '*'
    },
    logicalRule: [],
    rulesRefresh: false,
    rulesRetrieveData: [],
    retriveRule: {},
    JSONTree: {},
    searchResultNotFound: false,
    searchValue: "",
    domainList: [],
    purposeList: []
}


const RulesPageReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case SET_RULES_JSON_TREE:
            return {
                ...state,
                JSONTree: payload,
            };
        case SET_SEARCH_VALUE:
            return {
                ...state,
                searchValue: payload,
            };

        case SET_CREATE_RULE_PAGE_DEFAULT:
            return initialState;

        case SET_RETRIVE_RULE_RESPONSE:
            return {
                ...state,
                retriveRule: payload,
            };
        case SET_RULES_PREVIEW_TABLE:
            return {
                ...state,
                previewTables: payload,
            };
        case SET_RULES_SCHEDULE_BODY:
            // let body = { ...state.scheduleBody };
            // body[payload.key] = payload.value
            return {
                ...state,
                scheduleBody: payload,
            };
        case SET_FILTER_CRITERIA:
            return {
                ...state,
                appliedFilterCriteria: payload
            }
         
        case SET_RULES_COMPLEX_QUERY:
            return {
                ...state,
                complexRuleQuery: payload
            }
         
        case SET_CONDITION_CRITERIA:
            return {
                ...state,
                conditionCriteria: payload
            }
         
        case SET_RULES_PUBLISH:
            return {
                ...state,
                isRulesPublish: payload
            }
         
        case SET_RULES_REFRESH:
            return {
                ...state,
                rulesRefresh: payload
            }
         
            
        case SET_SEARCH_RESULT_NOT_FOUND:
            return {
                ...state,
                searchResultNotFound: payload
            }
            
        case SET_RULES_CATEGORY:
            return {
                ...state,
                ruleCategory: payload
            }
         
        case SET_RULES_TYPE:
            return {
                ...state,
                ruleType: payload
            }
         
        case SET_SELECETED_RULES_COLUMN:
            return {
                ...state,
                completnessColumn: payload
            }
         
        case SET_UNIQUENESS_RULES_COLUMN:
            return {
                ...state,
                uniquenessColumn: payload
            }
         
        case SET_RULES_LOGICAL_RULE:
            return {
                ...state,
                logicalRule: payload
            }
         
        case SET_RULES_RETRIEVE_DATA:
            return {
                ...state,
                rulesRetrieveData: payload
            }
         
        case SET_DISTINCT_ROWS:
            return {
                ...state,
                distinctRows: payload
            }
         

        case SET_NEXT_PAGE_COUNTER:

            return { ...state, pageCounter: state.pageCounter + 1 }
         

        case RESET_NEXT_PAGE_COUNTER:
            return { ...state, pageCounter: 0 }
         
        case SET_ALL_DATA_LOADED:
            return { ...state, isAllDataLoaded: payload }

        case RESET_NEXT_RECORD_TOKEN:
           
            let temp = { ...state.previewPageData };
            temp['nexturi'] = ''
            return { ...state, previewPageData: { ...temp } }

        case SET_PREVIEW_TABLE_RESPONSE:

            return {
                ...state,
                previewPageData: { ...payload },
            };
        case SET_RULES_PREVIEW_TABLE_ROWSET:
            return {
                ...state,
                previewPageData: { ...payload },
            };
        case SET_RULES_PREVIEW_TABLE_RECORDS:

            return {
                ...state,
                tableRecords: [...state.tableRecords, ...payload],
            };


         
        case SET_RULES_PREVIEW_INITIAL_STATE:

            return initialState

         
        case SET_RULES_PREVIEW_NEXT_URI:
            return {
                ...state,
                next_uri: payload.next_uri,
                query: payload.query
            };
         
        case SET_RULES_PREVIEW_FILTERS:
            return {
                ...state,
                filters: payload && payload.length
                    ? [...state.filters, ...payload] : []
            };
         
        case SET_RULES_PREVIEW_SORT:
            return {
                ...state,
                sort: payload && payload.length
                    ? [...payload] : []
            };
         
        case SET_RULES_PREVIEW_QUERY:
            return {
                ...state,
                sqlEditorQuery: payload
            };
         
        case SET_RULES_PREVIEW_SEARCH_TERM:
            return {
                ...state,
                searchKeyword: payload
            };
         
        case SET_RULES_PREVIEW_FLAG:
            return {
                ...state,
                previewFlag: payload
            };
         
        case SET_CORELATION_GRAPH:
            return {
                ...state,
                coGraphData: [...payload]
            };
         
        case SET_QUERY_ERROR:
            return {
                ...state,
                queryErrorDetail: payload
            };
         
        case RESET_TABLE_RECORDS:
            return {
                ...state,
                previewPageData: {},
                tableRecords: []
            };
         

        case SET_RULE_DETAIL:
            return {
                ...state,
                ruleDetail: payload
            }
         
        case SET_RECENTRUNS_DATA:
            return {
                ...state,
                recentRunsData: [...payload]
            }
         
        case SET_ALERT_LIST:
            return {
                ...state,
                alertList: [...payload]
            }
         
        case SET_RULES_QUERY_RESULT:
            return {
                ...state,
                queryResult: payload
            }
        case SET_DOMAIN_LIST:
            return {
                ...state,
                domainList: [...payload]
            }
        case SET_PURPOSE_LIST:
            return {
                ...state,
                purposeList: [...payload]
            }
        default:
            return state;
    }
}

export default RulesPageReducer;