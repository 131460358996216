export const SET_DICTIONARY_TABLE_DATA = "SET_DICTIONARY_TABLE_DATA";
export const EXPAND_DICTIONARY_TABLE = "EXPAND_DICTIONARY_TABLE";
export const EDIT_DICTIONARY_TABLE = "EDIT_DICTIONARY_TABLE";
export const SAVE_EDIT = "SAVE_EDIT";
export const SET_GRAPH_DATA = "SET_GRAPH_DATA";
export const EDIT_HEADER_DESCRIPTION = "EDIT_HEADER_DESCRIPTION";
export const EDIT_DICTIONARY_SEARCH = "EDIT_DICTIONARY_SEARCH";
export const UNABLE_GRAPH_VIEW = "UNABLE_GRAPH_VIEW";
export const DICTIONARY_GRAPH_LOADER = "DICTIONARY_GRAPH_LOADER";
export const RESET_REDUX_STATE = "RESET_REDUX_STATE";
export const SET_PREVIEW_POD_DATA = "SET_PREVIEW_POD_DATA";
export const SET_DICIONARY_DEFAULT = "SET_DICIONARY_DEFAULT";
export const SET_INITIAL_CATALOGUE_TAB = "SET_INITIAL_CATALOGUE_TAB";
export const SET_GRAPH_SAMPLE_SELECTED = "SET_GRAPH_SAMPLE_SELECTED";
export const RESET_GRAPH_SAMPLE_SELECTED = "RESET_GRAPH_SAMPLE_SELECTED";
export const SET_SELECTED_BUSINESS_TERM = "SET_SELECTED_BUSINESS_TERM";
export const SET_SELECTED_BUSINESS_TERM_TEMP = "SET_SELECTED_BUSINESS_TERM_TEMP";
export const SAVE_APPLIED_FILTERS = "SAVE_APPLIED_FILTERS";
export const RESET_SAVED_APPLIED_FILTERS = "RESET_SAVED_APPLIED_FILTERS";
