import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Steps } from "antd";
import EditorContainer from "../EditorContainer";
import { LoadingOutlined } from "@ant-design/icons";
import ClaristaLoader from "../../../../../Common/claristaLoader/ClaristaLoader";
import PreviewGrid from "./PreviewGrid";
import { useOnScreen } from "../../../../common/helperFunctions";
import { useSelector } from "react-redux";
import PreviewSummary from "./PreviewSummary";
const description = "This is a description.";

const PreviewSteps = forwardRef(
  (
    {
      schemaDetails,
      sqlTableDetails,
      summaryLoaded,
      summaryHeight,
      currentStep,
      Query,
      setQuery,
      isDataComing,
      setIsGridLoaded,
      isGridLoaded,
      search,
      setExpandDropdown,
      isError,
      podDetails,
      props
    },
    previewRef
  ) => {
    const ref = useRef();
    const [isIntersecting, setIntersecting] = React.useState(false);
    const isChatgpt = useSelector(
      (state) => state.DataCatalogue.PreviewPage.isChatgpt
    );
    React.useEffect(() => {
      let scrollElement = document.querySelector(".preview-timeline");

      scrollElement.addEventListener("scroll", (e) => {
        let summaryHeight = document.querySelector("#timeline-summary").offsetHeight;
        let offsetHeight = window.screen.availHeight > 1100 ? 10 : 0
        if (e.target.scrollTop > (summaryHeight - offsetHeight) && isIntersecting == false) {
          setIntersecting(true)
          setExpandDropdown(false);
        }
        else {
          setIntersecting(false);
        }
      })
    }, [])

    const onExecuteQueryClick = () => {
      previewRef?.current?.executeQueryApiParent();
    };
    const [items, setItems] = useState([
      {
        title: "",
        icon: (
          <div className={`timeline-mark ${isChatgpt ? "chatgpt" : ""}`}>
            <div className="timeline-dot"></div>
            <span>Summary</span>
          </div>
        ),
        description: (
          <PreviewSummary
            summaryHeight={summaryHeight}
            isVisible={isIntersecting}
            onExecuteQueryClick={onExecuteQueryClick}
            summaryLoaded={summaryLoaded}
            isDataComing={isDataComing}
            sqlTableDetails={sqlTableDetails}
            Query={Query}
            setQuery={setQuery}
            isGridLoaded={isGridLoaded}
          />
        ),
      },
    ]);

    useEffect(() => {
      if (!search || isError) {
        setItems([]);
      }
      else {
        if (summaryLoaded) {
          setItems([
            {
              title: "",
              icon: (
                <div className={`timeline-mark ${isChatgpt ? "chatgpt" : ""}`}>
                  <div className="timeline-dot"></div>
                  <span>Summary</span>
                </div>
              ),
              description: (
                <PreviewSummary
                  summaryHeight={summaryHeight}
                  isVisible={isIntersecting}
                  onExecuteQueryClick={onExecuteQueryClick}
                  summaryLoaded={summaryLoaded}
                  isDataComing={isDataComing}
                  sqlTableDetails={sqlTableDetails}
                  Query={Query}
                  setQuery={setQuery}
                  isGridLoaded={isGridLoaded}
                />
              ),
            },
            {
              title: "",
              icon: (
                <>
                  <div className={`timeline-mark ${isChatgpt ? "chatgpt" : ""}`}>
                    {currentStep !== 0 ? (
                      <div className="timeline-dot"></div>
                    ) : (
                      <div>
                        <ClaristaLoader />
                      </div>
                    )}
                    <span>Insights</span>
                  </div>
                </>
              ),
              description: (
                <div style={{ height: "75vh", position: "relative" }}>
                  {currentStep !== 0 ? (
                    <PreviewGrid
                      setIsGridLoaded={setIsGridLoaded}
                      ref={previewRef}
                      isVisible={isIntersecting}
                      schemaDetails={schemaDetails}
                      podDetails={podDetails}
                      props={props}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ),
            },
          ]);
        } else {

          setItems([
            {
              title: "",
              icon: (
                <div className={`timeline-mark ${isChatgpt ? "chatgpt" : ""}`}>
                  <div className="timeline-dot"></div>
                  <span>Summary</span>
                </div>
              ),
              description: (
                <PreviewSummary
                  summaryHeight={summaryHeight}
                  isVisible={isIntersecting}

                  onExecuteQueryClick={onExecuteQueryClick}
                  summaryLoaded={summaryLoaded}
                  sqlTableDetails={sqlTableDetails}
                  isDataComing={isDataComing}
                  Query={Query}
                  setQuery={setQuery}
                  isGridLoaded={isGridLoaded}
                />
              ),
            }
            ,
          ]);

        }

      }
    }, [
      summaryLoaded,
      isIntersecting,
      summaryHeight,
      sqlTableDetails,
      Query,
      isGridLoaded,
      isDataComing,
      search,
      isError
    ]);
    return (
      <div
        className={`preview-timeline ${isIntersecting && summaryLoaded ? "expand" : ""
          } ${isChatgpt ? "chatgpt" : ""}`}
      >
        <Steps className="preview-steps" direction="vertical" current={currentStep} items={items} />

        <div
          style={{
            width: "100%",
            height: "10px",
            background: "transparent",
            // position: "absolute",
            bottom: 0,
          }}
          ref={ref}
        ></div>
      </div>
    );
  }
);

export default PreviewSteps;
