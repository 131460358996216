import React, { useEffect, useMemo, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useCombinedRefs } from "./hooks/useCombinedRefs";

import { Badge, Progress, Tooltip } from "antd";
import { ASCENDING, DESCENDING, FILTER } from "../../Common/iconSource";
import { getColumnDataType, getObjectLength, progressBarFilterTypes } from "../../modules/common/helperFunctions";
import { useDispatch } from "react-redux";
import { fetchColummGraphData } from "../../../store/actions";
import CustomProgressBar from "../customProgressBar.js/CustomProgressBar";
import DataGridFilterPopover from "./newDataGridTable/DataGridFilterPopover";

let demoClassification = [{id:0, name:'Cats Cats'},{id:1, name:'Bills Kats'},{id:2, name:'Dogs'},{id:3, name:'Kuts Kuas'}]

export function DraggableHeaderRenderer({ 
  // onColumnsReorder = () => {}, 
  column, 
  uniqUUID, 
  filteredArray,
  extraDetails,
  showDictionaryDetails,
  showTechnicalTerm = false,
  requestedTableId,
  podColumnDetail,
  columnRegex,
  handleProgressBar = () => {},
  progressFilterColName = '',
  progressFilterType = '',
  handleResetProgressFilter = () => {},
  progressBarDataMapRef = {},
  stopColumnReorder = false,
  colToHighlight = undefined,
  index,
  colFilterRef = {},
  handleApplyFilters = () => {},
  isEnableResetAll = false,
  onClearFilter = () => {},
  domainName = '',
  tableName = '',
  isShowHeader = true,
  isShowFilter = true,
  staticQuery = undefined,
  isCustomSql = false
},) {
  const [filteredCols, setFilteredCols] = useState({})
  const [colStats, setColStats] = useState({health:0, anomaly: 0, empty: 0})
  const [progressData, setProgressData] = useState([
    {id: podColumnDetail?.column_id, title: '', percent: 0, onClick: () => {}},
    {id: podColumnDetail?.column_id, title: '', percent: 0, onClick: () => {}},
    // {id: podColumnDetail?.column_id, title: '', percent: 0, onClick: () => {}}
  ])

  const dispatch = useDispatch()

  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN_DRAG",
    item: { key: column?.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "COLUMN_DRAG",
    /**
     * Column reorder is managed under NewDataGrid Component
     */
    // drop({ key }) {
    //   onColumnsReorder(key, column?.key);
    // },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    filteredArray.forEach((el, i) => {
      if (el.column === column.name) {
        filteredCols[el.column] = 'showIcon'
      }
      else {
        filteredCols[el.column] = 'showIcon'
      }

      setFilteredCols({ ...filteredCols, ...filteredCols })
    })
  }, [filteredArray])

  useEffect(() => {
    if(showDictionaryDetails) {

      if(progressBarDataMapRef[column?.name?.toLowerCase()]) {
        // if data already present then access from ref.
        fillProgressBar(progressBarDataMapRef[column?.name?.toLowerCase()])
        return
      }

      if(podColumnDetail?.column_id === undefined) return
      
      dispatch(fetchColummGraphData(requestedTableId, podColumnDetail?.column_id, false))
      .then((res) => {
        let data = res?.data?.column_stats?.[0]
        if(getObjectLength(data)) { // saving to prevent recall of api on remount.
          const { health, percentage_na, column_id,anomaly } = data
          progressBarDataMapRef[column?.name?.toLowerCase()] = {health, percentage_na, column_id,anomaly}
        }
        fillProgressBar(data)
      })
      .catch(() => {
        setColStats({health: 0, anomaly: 0, empty: 0})
      })
    }
  }, [showDictionaryDetails])

  const fillProgressBar = (pdata) => {
    const data = {...pdata}
    let health = data?.health === null ? 0 : data?.health
    // let empty = data?.percentage_na === null ? 0 : data?.percentage_na

    // health = parseFloat(100 - empty).toFixed(2)

    // let a = parseInt(100 - health - empty)
    // let a=data?.anomaly===null?0:data.anomaly;
    // let e = data?.percentage_na??0

    // if(health === 0 && empty === 0) {
    //   a = 0
    // }

    let obj = {
      health: health??0,
      anomaly: Number(100 - (health ?? 0)).toFixed(2),
      //  anomaly: a < 0 ? 0 : a,
      // empty: e < 0 ? 0 : e
    }
    setColStats({...obj})

    let arr = []
    let titleMap = {health: 'Healthy', anomaly: 'Unhealthy'}
    let colorMapping = {health: '#2C83C4', anomaly: '#E19806', empty: '#444'}
    for (const key in obj) {        
      arr.push({
        id: data?.column_id, 
        title: titleMap[key], 
        percent: obj[key], 
        color: colorMapping[key],
        onClick: () => {
          handleProgressBar(column?.name,titleMap[key]?.toLowerCase(), columnRegex)
        }
      })
    }
    setProgressData([...arr])
  }

  const progressBarIndicatorList = useMemo(() => {
    return [
      {percent: colStats?.health, type: progressBarFilterTypes['health'], style: {background: '#2c83c4'}},
      {percent: colStats?.anomaly, type: progressBarFilterTypes['anomaly'], style: {background: '#E19806'}},
      // {percent: colStats?.empty, type: progressBarFilterTypes['missing'], style: {background: '#e7e7e7', border: 'solid 1px #999999'}}
    ]
  }, [colStats, progressFilterType])

  const handleStatIndicatorClick = (type, percent) => {
    if(percent <= 0 || (progressFilterType === type && isColProgressFilterActive)) return
    handleProgressBar(column?.name, type, columnRegex)
  }

  const isColProgressFilterActive = column?.name?.toLowerCase() === progressFilterColName?.toLowerCase()
  const isColFixed = colToHighlight && colToHighlight?.toLowerCase() === column?.name?.toLowerCase()
  // console.log({column, b:extraDetails?.data_dict_detail?.category_detail?.name, extraDetails})
  return (
    <div
      id={`prev-col-${index}`}
      className={`prev-col-wrap ${isColProgressFilterActive || isColFixed ? 'active' : ''} ${isColFixed ? 'col-fixed' : ''}`}
      ref={useCombinedRefs(drag, drop)}
      style={{
        fontWeight: 300,
        opacity: isDragging && !stopColumnReorder ? 0.5 : 1,
        backgroundColor: isOver && !stopColumnReorder ? "#ececec" : showDictionaryDetails ? 'transparent' : '#fff',
        cursor: stopColumnReorder ? "default" : "move", margin: "0 -0.875rem",
        padding: "0.125rem 0.5rem"
      }}
    >
        <div className="prev-col-cont">
          <div className={`prev-col-nm d-flex position-relative ${!showDictionaryDetails ? 'h-100 align-items-center' : ''}`}>
            <div className="w-100">
              {getColumnDataType(column?.dtype)}
              <Tooltip placement="topLeft" title={column?.name} >
                <span className="rdg-column-style text-with-ellipsis text-uppercase" style={{ fontSize: "0.625rem", fontFamily: 'InterMedium' }}>{column?.name}</span>
              </Tooltip>
            </div>
            {
              isShowHeader && isShowFilter
              ?
              <div className="prev-col-ac-ic">
                <DataGridFilterPopover 
                  colFilterRef={colFilterRef} 
                  column={column} 
                  handleApply={handleApplyFilters}
                  isEnableResetAll={isEnableResetAll}
                  onClearFilter={onClearFilter}
                  domainName={domainName}
                  tableName={tableName}
                  tableId={requestedTableId}
                  staticQuery={staticQuery}
                  isCustomSql={isCustomSql}
                  uniqUUID={uniqUUID}
                />
              </div>
              : ''
            }
            {/* <div className={!Object.keys(filteredCols).includes(column?.name) && !isSorted.state ? 'd-none' : "prev-col-ac-ic"}>
              <span className="float-right ml-1 filter-ic-rdg-custom-ic">
                {
                  Object.keys(filteredCols).includes(column?.name) ? <FILTER /> : ''
                }
              </span>
              <span className="float-right ml-1">
                {isSorted.state && isSorted.type === 'ASC' ? <ASCENDING /> : isSorted.type === 'DESC' ? <DESCENDING /> : ''}
              </span>
            </div> */}
          </div>
          {
            !showDictionaryDetails ? ''
            :
            (extraDetails && extraDetails !== '' && extraDetails !== null)
            ?
            <div className="prev-col-ex">
                <div>
                  {
                    !showTechnicalTerm ? '' :
                    extraDetails?.tech_term === undefined
                    ?
                    <p style={{lineHeight: '1.75rem'}} className="mb-0 text-black-50 px-2">No Technical Name</p>
                    :
                    <Tooltip title={`Technical Name : ${extraDetails?.tech_term}`} placement="topLeft">
                      <div className="categoryTag tc-tm text-with-ellipsis" style={{marginTop: '0.1875rem'}}>
                        {extraDetails?.tech_term}
                      </div>
                    </Tooltip>
                  }
                </div>
                <div className="" style={{height: '1.218rem'}}>
                  {/* {
                    extraDetails?.data_dict_detail?.category_detail?.name === undefined 
                    || extraDetails?.data_dict_detail?.category_detail?.name === null
                    || extraDetails?.data_dict_detail?.category_detail?.name === ''
                    ?
                    <label className="mb-0 text-black-50 px-2" style={{height: 18}}>No Category</label>
                    :
                    <Tooltip title={`Data Category : ${extraDetails?.data_dict_detail?.category_detail?.name}`} placement="topLeft">
                      <div className="preview-pills cap-pile cat categoryTag mr-1 rounded-pill text-with-ellipsis">
                        {extraDetails?.data_dict_detail?.category_detail?.name}
                      </div>
                    </Tooltip>
                  } */}

                  {
                    extraDetails?.data_dict_detail?.classification_detail &&
                    extraDetails?.data_dict_detail?.classification_detail?.length === 0
                    ? <label className="mb-0 text-black-50 px-2" style={{height: 18}}>No Classification</label>
                    :
                    extraDetails?.data_dict_detail?.classification_detail?.map((v,i)=>{
                      return <React.Fragment key={`cls-li-prv-${i*2}-${i}`} >
                        {
                          i < 2
                          ?
                          <div className="preview-pills cap-pile classificationTag cls rounded-pill">
                            <Tooltip title={`Classifier : ${v.name}`} placement="topLeft">
                              {v.name}
                            </Tooltip>
                          </div>
                          : ''
                        }
                        {
                          i === 2
                          ?
                          <Tooltip title={`Classifiers : ${extraDetails?.data_dict_detail?.classification_detail.map(v => v.name).slice(2).join(", ")}`} placement="topLeft">
                            <div className="preview-pills cap-pile classificationTag cls rounded-pill">
                                +{extraDetails?.data_dict_detail?.classification_detail.length - 2}
                            </div>
                          </Tooltip>
                          : ''
                        }
                      </React.Fragment>
                    })
                  }
                </div>
                <div className="prev-prog-wrap">
                  <div>
                      <CustomProgressBar
                        data={progressData}
                      />
                      <div className="d-flex align-items-center justify-content-between">
                        <Tooltip 
                          className="d-flex align-items-center" 
                          placement="right" 
                          title={
                            `Healthy: ${colStats?.health}%, Unhealthy: ${colStats?.anomaly}%`
                            // `Healthy: ${colStats?.health}%, Anomaly: ${colStats?.anomaly}%, Missing: ${colStats?.empty}%`
                          }
                        >
                          {
                            progressBarIndicatorList.map((el, idx) => (
                              <span key={`${podColumnDetail?.column_id}|${idx}`} className={`prev-stats-sphere mr-2 ${progressFilterType === el.type && isColProgressFilterActive ? 'active' : ''}`}
                                onClick={() => {handleStatIndicatorClick(el.type, el.percent)}} >
                                <span className='sphere mr-1' style={el.style}></span>
                                <label className='cursor-pointer mb-0 font-w-600 small pr-1'>{el.percent}%</label>
                              </span>
                            ))
                          }
                        </Tooltip>
                        {
                          progressFilterType && isColProgressFilterActive
                          ? <Tooltip placement="right" title={'Remove Filter'}>
                              <label onClick={() => handleResetProgressFilter()} className="cursor-pointer align-items-center bg-light d-flex mb-0 px-1 rounded-pill text-danger">
                                <i className="bx bx-reset mr-1"></i>Reset
                              </label>
                            </Tooltip>
                          : ''
                        }
                      </div>
                  </div>
                </div>
            </div>
            : <p className="mb-0 text-black-50 px-2 py-1" style={{lineHeight: '1.4'}}>Dictionary Details Not Found</p>
          }
        </div>
    </div>
  );
}
