import { emitToastNotification } from "../../../../helpers/toast_helper";
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../loadingActions";

const BASE_URL = window._env_.REACT_APP_API_BASE_URL;
export const getReportList = (payload) => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {

            dispatch(
                fetchData("GET", `${BASE_URL}query/v2/?published=${payload?.published}&data_domain_id=${payload?.data_domain_id ? payload?.data_domain_id : ''}`)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {



                        resolve(resp);
                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};

export const getReportDetails = (reportId) => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {

            dispatch(
                fetchData("GET", `${BASE_URL}query/v2/${reportId}/`)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {

                        reject(resp.message)
                    } else {



                        resolve(resp);
                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};

export const bookmarkReport = (type, reportId) => {

    return (dispatch) => {
        // dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {

            dispatch(
                fetchData(type, `${BASE_URL}query/v2/${reportId}/bookmark/`)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {

                        reject(resp.message)
                    } else {

                        emitToastNotification('success',resp.message)

                        resolve(resp);
                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};
export const publishPod = (podId, payload) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}catalog_home/v2/${podId}/publish/`, payload)
            )
                .then((resp) => {
                    if (resp.status !== "success") {
                        emitToastNotification("error", resp.message);
                        reject(resp.message);
                    } else {
                        emitToastNotification("success", resp.message);

                        resolve(resp);
                    }
                })
                .catch((err) => {
                    emitToastNotification("error", err.message);
                    reject(err);
                });
        });
    };
};

export const unpublishPod = (podId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}catalog_home/v2/${podId}/publish/`)
            )
                .then((resp) => {
                    if (resp.status !== "success") {
                        emitToastNotification("error", resp.message);
                        reject(resp.message);
                    } else {
                        emitToastNotification("success", resp.message);

                        resolve(resp);
                    }
                })
                .catch((err) => {
                    emitToastNotification("error", err.message);
                    reject(err);
                });
        });
    };
};

export const getChatList = (domainId, search = '', bookmark = false, pageNumber = 1, pageLimit = 50, showToast = true) => {
    let url = `${BASE_URL}chat/chat/?data_domain_id=${domainId}&page=${pageNumber}&limit=${pageLimit}&search=${search}`
    if(bookmark) {
        url += '&bookmark=true'
    }
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', url)
            )
                .then((resp) => {
                    // No need to show success toast message
                    // if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const deleteChat = (chatId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}chat/chat/${chatId}/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const updateChat = (chatId, payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('PUT', `${BASE_URL}chat/chat/${chatId}/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const bookmarkChat = (chatId, bookmark = true, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(bookmark ? 'GET' : 'DELETE', `${BASE_URL}chat/chat/${chatId}/bookmark/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const shareChat = (chatId, payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}chat/chat/${chatId}/share/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const unshareChat = (chatId, userId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}chat/chat/${chatId}/share/?user_id=${userId}`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const shareTracker = (trackerId, payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}chat/conversation/${trackerId}/share/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const unshareTracker = (trackerId, userId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}chat/conversation/${trackerId}/share/?user_id=${userId}`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const unfollowChat = (chatId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}chat/chat/${chatId}/share/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getChatConversation = (uuid, chatId, pageNumber = 1, pageLimit = 50, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/conversation/?uuid=${uuid}&page=${pageNumber}&limit=${pageLimit}${chatId ? '&chat_id=' + chatId : ''}`)
            )
                .then((resp) => {
                    // No Need to Show Success Message
                    // if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                   
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getTrackersList = (domainId, search = '', pageNumber = 1, pageLimit = 50, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/conversation/?data_domain_id=${domainId}&page=${pageNumber}&limit=${pageLimit}&tracked=true&search=${search}`)
            )
                .then((resp) => {
                    // No need to show success message
                    // if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getTrackerData = (trackerId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/conversation/${trackerId}/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const updateTracker = (trackerId, payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('PUT', `${BASE_URL}chat/conversation/${trackerId}/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const bookmarkTracker = (trackerId, bookmark = true, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(bookmark ? 'GET' : 'DELETE', `${BASE_URL}chat/conversation/${trackerId}/bookmark/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const startChartConversation = (payload, showToast = true, controller) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}chat/conversation/`, payload, null, null, controller)
            )
                .then((resp) => {
                    // if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if(err?.code === 'ERR_CANCELED') {
                        reject(err)
                        return
                    }
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getConversationKPI = (id, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/conversation/${id}/kpi/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getConversationChart = (id, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/conversation/${id}/chart/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};


export const updateConversationChartType = (id, payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('PUT', `${BASE_URL}chat/conversation/${id}/chart/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getConversationChartFromQuery = (id, payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}chat/conversation/${id}/chart/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const verifyConversation = (conversation_id, payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('PUT', `${BASE_URL}chat/feedback/${conversation_id}/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const deleteFeedback = (conversation_id, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}chat/feedback/${conversation_id}/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getFeedbackDomainCount = (showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/feedback/domain_count/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getChatData = (chatId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/chat/${chatId}/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const deleteChatConversation = (convId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}chat/conversation/${convId}/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const toggleConversationTrack = (convId, track = false, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(track ? 'GET' : 'DELETE', `${BASE_URL}chat/conversation/${convId}/track/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const continueDebug = (convId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/conversation/${convId}/continue_debug/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const continueAsk = (convId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/conversation/${convId}/continue_ask/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};


export const uploadImageLogo = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}data_domain/logo/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};


export const getImageLogo = (domainId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}data_domain/${domainId}/logo/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};


export const deleteImageLogo = (domainId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}data_domain/${domainId}/logo/`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const createFeedback = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}chat/feedback/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getTalkDataFeedback = (
    pageLimit = 1000,
    pageNumber = 1,
    domain, 
    search,
    is_verified = false, 
    showToast = true
    ) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/feedback/?limit=${pageLimit}&page=${pageNumber}${domain ? '&data_domain_id=' + domain : ''}&is_verified=${is_verified}${domain ? '&search=' + search : ''}`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getPersona = (pageNo, limit, dataDomainId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/domain_vocabulary/?type=PERSONA&page=${pageNo}&limit=${limit}&data_domain_id=${dataDomainId}`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const createPersona = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}chat/domain_vocabulary/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const updatePersona = (pId, payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('PUT', `${BASE_URL}chat/domain_vocabulary/${pId}/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const deletePersona = (pId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}chat/domain_vocabulary/${pId}/`,)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const bulkDeleteVocabulary = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}chat/domain_vocabulary/bulk_destroy/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getSynonyms = (pageNo, limit, dataDomainId, search, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/domain_vocabulary/?type=SYNONYM&page=${pageNo}&limit=${limit}&data_domain_id=${dataDomainId}&search=${search}`)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const createSynonym = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('POST', `${BASE_URL}chat/domain_vocabulary/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const updateSynonym = (pId, payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('PUT', `${BASE_URL}chat/domain_vocabulary/${pId}/`, payload)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const deleteSynonym = (pId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('DELETE', `${BASE_URL}chat/domain_vocabulary/${pId}/`,)
            )
                .then((resp) => {
                    if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};

export const getTopQuestions = (domainId, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData('GET', `${BASE_URL}chat/conversation/top-questions/?data_domain_id=${domainId}`)
            )
                .then((resp) => {
                    // No need to show success message
                    // if (showToast) emitToastNotification("success", resp.message);

                    resolve(resp);
                })
                .catch((err) => {
                    if (showToast) emitToastNotification("error", err.message);

                    reject(err);
                });
        });
    };
};


const initialState = {

}

const GenAiReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {




        default:
            return state;
    }
}
export default GenAiReducer;