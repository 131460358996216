import React, { useEffect } from 'react'
import { useSelector, useDispatch, useStore } from "react-redux"

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Buttons from '../../../Common/button/Buttons';
import { CHECK, CLOSE, PLUS } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import { SET_FLOWS_ALERT_TRANSFORMER_LIST } from '../../../../store/modules/alert/createAlert/createAlertActiontype';

import { Scheduler } from '../index'
import { setSelectedScheduler } from '../../../../store/modules/scheduler/action';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import {CategoryTable} from './DataCategory';
import DataCategoryModal from './DataCategoryModal';
import { Avatar, Checkbox, Tooltip } from 'antd';
import { getSettingsDataCategory } from '../../../../store/modules/dataDictionary/action';
import { LandingSimpleTableInner } from '../../../Common/landingSimpleTable/LandingSimpleTable';
import { getReloadErrorTemplate } from '../../common/helperFunctions';
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader';
import _ from 'lodash';



const CategoryModal = ({
  isModalOpen,
  onClose,
  save,
  reset,
  usersList = [],
  activeCategory
}) => {

  const dispatch = useDispatch()

  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(undefined);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [data, setData] = React.useState([])

  const rawData = React.useRef([])

  const onSaveHandler = () => {
    save(selected);
  };

  useEffect(() => {
    if(isModalOpen) {
      setSelectedIndex(activeCategory?.id ?? null)
      getList()
    }
  }, [isModalOpen])
  
  useEffect(() => {
    if (reset) setSelected([]);
  }, [reset]);

  const generateRow = (data) => {
    let temp=[...data];
     temp = temp.map((item, index) => {
      return {
        id: item.id,
        key: item.id,

        data: item,
        name: listTemplate.name(
          item.name
        ),
        data_owner: listTemplate.dataOwner(
          item?.owner_steward_detail?.owner ?? []
        ),
        data_stewards: listTemplate.dataStewards(
          item?.owner_steward_detail?.steward ?? []
        ),
        description: listTemplate.description(item.description),
      };
    });
    return temp;
  };

  const getList = () => {
    setLoading(true)
    dispatch(getSettingsDataCategory(false, false))
      .then((res) => {
        let temp = generateRow(res?.data);
        setData([...temp]);
        rawData.current = [...res?.data];
        setLoading(false)
      })
      .catch((err) => {
        setErrMsg(err?.message)
        setData([]);
        setLoading(false)
      });
  };
  
  const onRowClick = (record) => {
    setSelected([record?.data]);
    setSelectedIndex(record?.id);
    activeCategory = record?.id
}
  
  return (
    <Modal
      size="lg"
      isOpen={isModalOpen}
      className="custom-modal category-select-modal"
      centered={true}
    >
      <ModalHeader>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="modal-heading-logs">Select Category</div>

        {/* {hideAddButton?"":  <Buttons
            props={{
              tooltip: "",
              buttonText: "Add Category",

              buttonClassName:
                "custom-btn-outline custom-btn btn-with-text border mr-2",
              buttonEvent: () => { setOpenModal(true) },
              ImgSrc: () => <PLUS />,
              isDisable: false,
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
            }}
          />} */}
        </div>
        <Buttons
          props={{
            tooltip: "Close",
            buttonText: "",
            buttonClassName:
              "custom-btn-outline custom-btn btn-with-icon float-right",
            buttonEvent: () => {
              onClose();
            },
            ImgSrc: () => <CLOSE />,
            isDisable: false,
            buttonType: Literals.BTN_TERTIARY,
          }}
        />
      </ModalHeader>
      <ModalBody className="overflow-hidden">
        <div style={{ height: "413px" }}>
          {
            errMsg ? getReloadErrorTemplate({errorMessage: errMsg, onReload: getList})
            :
            loading ? <ClaristaLoader/>
            :
            <LandingSimpleTableInner
              rowsData={
                data
                // (() => {
                //     let dat = [...data]
                //     dat = _.sortBy(dat, [o => o?.id !== selectedIndex])
                //     return dat ?? []
                // })()
              }
              onRowClick={onRowClick}
              cols={modalColumns}
              tableHeight={"100%"}
              selectedRowIndex={selectedIndex}
            />
          }
        </div>
      </ModalBody>
      <ModalFooter>
      <Buttons
          props={{
            tooltip: "Clear",
            buttonText: "Clear",
            buttonClassName:
              " custom-btn-outline custom-btn btn-with-text mr-2",
            buttonEvent: () => {
              activeCategory = null
              save(null)
              onClose();
            },
            ImgSrc: () => <CLOSE />,
            isDisable: false,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
        <Buttons
          props={{
            tooltip: "Done",
            buttonText: "Done",
            buttonClassName:
              " custom-btn-primary custom-btn btn-with-text float-right",
            buttonEvent: () => {
              activeCategory = null
              onSaveHandler();
              onClose();
            },
            ImgSrc: () => <CHECK />,
            isDisable: !selectedIndex,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
      </ModalFooter>
      <DataCategoryModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        usersList={usersList}
      />
    </Modal>
  );
};

export default CategoryModal

const listTemplate = {
  name: (text = "") => {
    return (
      <>
        <div className="d-flex align-items-center h-100">
          
          <Tooltip placement="topLeft" title={text}>
            <div className="text-uppercase dict-color-dark text-with-ellipsis fontSizeLabel">
              {text}
            </div>
          </Tooltip>
        </div>
      </>
    );
  },
  description: (text = "") => {
    return (
      <>
        <Tooltip placement="topLeft" title={text}>
          <div className="text-with-ellipsis">{text}</div>
        </Tooltip>
      </>
    );
  },
  dataOwner: (owners = []) => {
    owners = owners.map((v) => `${v.first_name} ${v.last_name}`);
    return (
      <>
        <div className="d-flex category-table dict-custom-avt">
          {
            owners?.length === 0
            ?
            <div className='text-black-50 ml-2'>N/A</div>
            :
            <Avatar.Group maxCount={3}>
              {owners.map((name, i) => {
                let nameArr = name?.split(' ')?.map(v => v?.[0] ?? '')
                return (
                  <Tooltip placement="topLeft" title={name}>
                    <Avatar
                      style={{ backgroundColor: "#2c83c4", color: "white" }}
                    >
                      {
                        nameArr?.length === 2
                        ?
                        nameArr?.[0] + nameArr?.[1] ?? ''
                        :
                        nameArr?.[0] ?? ''
                      }
                    </Avatar>
                  </Tooltip>
                );
              })}
            </Avatar.Group>
          }
        </div>
      </>
    );
  },
  dataStewards: (stewards = []) => {
    stewards = stewards.map((v) => `${v.first_name} ${v.last_name}`);
    return (
      <>
        <div className="d-flex category-table dict-custom-avt">
          {
            stewards?.length === 0
            ?
            <div className='text-black-50 ml-2'>N/A</div>
            :
            <Avatar.Group maxCount={3}>
              {stewards.map((name, i) => {
                let nameArr = name?.split(' ')?.map(v => v?.[0] ?? '')
                return (
                  <Tooltip placement="topLeft" title={name}>
                    <Avatar
                      style={{ backgroundColor: "#2c83c4", color: "white" }}
                    >
                      {
                        nameArr?.length === 2
                        ?
                        nameArr?.[0] + nameArr?.[1] ?? ''
                        :
                        nameArr?.[0] ?? ''
                      }
                    </Avatar>
                  </Tooltip>
                );
              })}
            </Avatar.Group>
          }
        </div>
      </>
    );
  },
};

const modalColumns = [
  {
    name: "Name",
    key: "name",
    sortable: false,
    resizable: false,
    width: 150,
  },
  {
    name: "Description",
    key: "description",
    sortable: false,
    resizable: false,
    width: 300,
  },
  {
    name: "Data Owner(s)",
    key: "data_owner",
    sortable: false,
    resizable: false,
    width: 'auto',
  },
  {
    name: "Data Steward(s)",
    key: "data_stewards",
    sortable: false,
    width: "auto",

    resizable: false,
  },
];