import React from 'react'
import './twoSections.scss'

export const TwoSections = ({
    className = '',
    leftTemplate = <></>,
    rightTemplate = <></>,
    equalWidth = false,
    height = '100%',
    reverseCol = false,
    leftCardView = true,
    rightCardView = true,
    defaultRightSpace = true
}) => {
    return <>
        <div style={{height: height}} className={`${equalWidth?"":'d-flex'} ${className} ${reverseCol ? 'flex-row-reverse' : ''}`}>
            <div className={`w-${equalWidth ? '40' : '25'} ${defaultRightSpace?'pr-2':''}`    }>
                <div className={`h-100 ${leftCardView ? 'custom-card border p-0 bg-white' : ''}`}>
                    {leftTemplate}
                </div> 
            </div>
            <div className={`w-${equalWidth ? '60' : '75'}`}>
                <div className={`h-100 ${rightCardView ? 'custom-card border p-0 bg-white ' : ''}`}>
                    {rightTemplate}
                </div>  
            </div>
        </div>
    </>
}
