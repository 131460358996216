import { SET_ACCESS_MANAGENEBT_PAGE_DEFAULT, SET_TABLE_ID, SET_TEMPSELECTED_USERGROUP, SET_TEMP_MASKCOLUMN, SET_DATA_DOMAIN } from './accessManagementActionTypes'

const initialState = {
    tableID: '',
    tempSelectedUserGroup: [],
    tempMaskColumn: [],
    dataDomain: null
};

const AccessManagementReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_TABLE_ID:
            return {
                ...state,
                tableID: payload
            }
        case SET_TEMPSELECTED_USERGROUP:
            return {
                ...state,
                tempSelectedUserGroup: [...payload]
            }
        case SET_TEMP_MASKCOLUMN:
            return {
                ...state,
                tempMaskColumn: [...payload]
            }
        case SET_DATA_DOMAIN:
            return {
                ...state,
                dataDomain: payload
            }

        case SET_ACCESS_MANAGENEBT_PAGE_DEFAULT:
            return initialState;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default AccessManagementReducer;