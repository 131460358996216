import React from 'react'
import { Card, CardBody } from 'reactstrap';
import { ICON_CHAT, ICON_LOADING_CIRCLE, ICON_SHARE, ICON_USER } from '../../../Common/newIconSource';
import { Dropdown, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import Buttons from '../../../Common/button/Buttons';
import { Literals } from '../../common/literals';
import { DELETEICON, FILLEDSTAR, STAR, UPDATED_BY } from '../../../Common/iconSource';
import { getDeleteMessage, getRelativeTime } from '../../common/helperFunctions';
import DeleteModal from '../../../Common/deleteModal/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { bookmarkChat, deleteChat, unfollowChat } from '../../../../store/modules/common/GenAi/GenAiReducer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const ChatGridView = ({
    data,
    searchData,
    activeDomain,
    // isDeletePermission = true,
    reload = () => {},
    showFavourite = false,
    setData = () => {}
}) => {
    const dispatch = useDispatch()
    const history= useNavigate()

    let userDetails = useSelector((state) => state.UserReducer?.user?.userDetails)

    const [optionOpen, setOptionOpen] = React.useState(false)
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [isDeleting, setDeleting] = React.useState(false)
    const [bookmark, setBookmark] = React.useState(false)
    const [bookmarkLoading, setBookmarkLoading] = React.useState(false)

    const isDeletePermission = data?.updated_by_user?.id === userDetails?.id

    React.useEffect(() => {
        if(data?.bookmark) {
            setBookmark(data?.bookmark)
        }
    }, [data])

    const optionItems = () => {
        let optionList = [
            {
                label: <span className={`dbox-opt-itm ${userDetails?.id === data?.updated_by_user?.id ? 'disabled cursor-not-allowed' : ''} `}
                        onClick={(e) => { 
                            e.stopPropagation();
                            if(userDetails?.id === data?.updated_by_user?.id) return
                            handleUnfollow()
                            }}>
                        <ICON_SHARE />
                        Unfollow
                        </span>,
                key: '1',
            },
          {
            label: <span className={`dbox-opt-itm danger ${!isDeletePermission ? 'disabled' : ''}`}
                onClick={(e) => { 
                    e.stopPropagation(); 
                    e.preventDefault();
                    if(isDeletePermission) {
                        setDeleteModal(true)
                    }
                }}>
              <DELETEICON />
              Delete
            </span>,
            key: '4',
            disabled: !isDeletePermission
          },
        ]
    
        if(data?.source_type === 'upload') {
          return optionList.filter(v => v.key !== '3')
        }
        return optionList
    }

    const handleDelete = () => {
        setDeleting(true)
        dispatch(deleteChat(data?.id))
        .then(() => {
            setDeleting(false)
            reload()
        })
        .catch(() => {
            setDeleting(false)
        })
    }

    const handleBookmark = (book) => {
        const id = data?.id
        setBookmarkLoading(true)
        dispatch(bookmarkChat(id, book, true))
        .then(() => {
            setBookmarkLoading(false)
            if(showFavourite && !book) {
                setBookmark(book)
                setData(prev => prev?.filter(d => d?.id !== id))
            }
            else {
                setBookmark(book)
            }
        })
        .catch(() => {
            setBookmarkLoading(false)
        })
    }

    const handleUnfollow = () => {
        toast.promise(
            new Promise(async (resolve, reject) => {
                try {
                    const res = await dispatch(unfollowChat(data?.id))
                    if(res?.status === 'success') {
                        reload()
                    }
                    resolve()
                }
                catch {
                    reject()
                }
            }),
            {
                pending: 'Unfollowing please wait...',
                success: 'Successfully Unfollowed Chat',
                error: 'Failed to unfollow chat'
            },
            {
                position: 'bottom-right',
                autoClose: 5000,
            }
        )
    }

    const trimmedDescription = data?.description?.length > 86 ?
    data?.description?.substring(0, 86 - 3) + "..." :
    data?.description ?? "";

    return (
    <div className="h-100 position-relative">
        <Card className='border-0' 
            onClick={(e) => { 
                e.preventDefault(); 
                e.stopPropagation(); 

                // To Prevent Data Loss as We are using Pagination
                // We are using window.open() rather than history() to prevent reloading of landing page
                // window.open(Literals.links.PULSE_TALK + '?dm=' + activeDomain + "&chat=" + data?.id + "&uuid=" + data?.uuid + '&query=')

                history(Literals.links.PULSE_TALK + '?dm=' + activeDomain + "&chat=" + data?.id + "&uuid=" + data?.uuid)
            }}
            >
            <CardBody>
            <div className='data-view-dbox' onClick={() => {}}>
                <div className='_src_img'>
                    <ICON_CHAT/>
                    {/* <SourceTypeImage dataName={data.source_type}/> */}
                </div>
                <div className='desc-box'>
                    <div className="d-flex align-items-start">
                        <Tooltip placement='topLeft' title={data?.name}>
                            <label style={{width: 'calc(100% - 3.5rem)'}} className='paragraph mt-1 title-txt font-w-600 text-with-ellipsis'>
                            <Highlighter searchWords={searchData && searchData.length ? [searchData?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.name} />
                                {/* {`${data.table_name}`} */}
                            </label>
                        </Tooltip>
                        <Buttons
                            props={{
                            buttonText: '',
                            tooltip: data?.bookmark
                                ? Literals.REMOVE_FAVOURITES
                                : Literals.ADD_FAVOURITES,
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                            buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleBookmark(!bookmark)
                            },
                            ImgSrc: () => bookmarkLoading ? <ICON_LOADING_CIRCLE/> : bookmark ? <FILLEDSTAR /> : <STAR />,
                            isDisable: bookmarkLoading,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            }}
                        />
                        {/* <Buttons
                            props={{
                            buttonText: '',
                            tooltip: 'Chat GPT',
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1",
                            buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                // history(Literals.links.DATA_CATALOG_POD_DETAILS + data?.table_id + '#preview-search')
                            },
                            ImgSrc: () => <CHATGPT_ICON/>,
                            isDisable: false,
                            buttonType: Literals.BTN_TERTIARY
                            }}
                        /> */}


                        <Dropdown
                            menu={{
                            items: optionItems(),
                            onClick: (e) => e.domEvent.stopPropagation()
                            }}
                            trigger={[ "hover" ]}
                            placement="bottomRight"
                            onOpenChange={(bool) => setOptionOpen(bool)}
                            overlayStyle={{border:'solid 1px #e4e4e4'}}
                            destroyPopupOnHide={true}
                        >
                            <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                        </Dropdown>
                    </div>
                    <div className='p-2'>
                        {/* <Tooltip placement='topLeft' title={data?.description}>
                        <p className='paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                            {data?.description?.length > 0? 
                            <Highlighter searchWords={searchData && searchData.length ? [searchData?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={trimmedDescription ?? ''} />
                                // data.pod_description
                                :"No description has been given yet."}
                        </p>
                        </Tooltip> */}
                    </div>
                    <div className='tile-meta-info'>
                        <div>
                            <UPDATED_BY width="14" height="14" />
                        </div>
                        <div>
                            <span className="label label-gray ml-1">{'Last Updated'} </span>
                        </div>
                        <div>
                            <Tooltip placement="topLeft" title={getRelativeTime(data?.updated_on)}>
                                <span className="paragraph">
                                    {/* <Highlighter searchWords={connectionContext.contextSearchValue && connectionContext.contextSearchValue.length ? [connectionContext.contextSearchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.updated_by_user?.email} /> */}
                                    {getRelativeTime(data?.updated_on)}
                                    {/* {data?.updated_by_user?.email} */}
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <ICON_USER width="14" height="14" />
                        </div>
                        <div className='d-flex align-items-center'>
                            <span className="label label-gray ml-1">{Literals.UPDATED_BY} </span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Tooltip placement="topLeft" title={data?.updated_by_user?.email}>
                                <span className="paragraph d-block text-with-ellipsis" style={{width: 134}}>
                                <Highlighter searchWords={searchData && searchData.length ? [searchData?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.updated_by_user?.email} />

                                {/* {data?.updated_by_user?.email} */}
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                    {/* <ul className="landingCard-profile-list">
                        <li className="landingCard-profile-listitem">
                        <UPDATED_BY width="14" height="14" />
                        <span className="label label-gray ml-1">{'Last Updated'} </span>
                        <Tooltip placement="topLeft" title={getRelativeTime(data?.updated_on)}>
                            <span className="paragraph">
                            {getRelativeTime(data?.updated_on)}
                            </span>
                        </Tooltip>
                        </li>
                        <li className="landingCard-profile-listitem">
                        <ICON_USER width="14" height="14" />
                        <span className="label label-gray ml-1">{Literals.UPDATED_BY} </span>
                        <Tooltip placement="topLeft" title={data?.updated_by_user?.email}>
                            <span className="paragraph">
                            <Highlighter searchWords={searchData && searchData.length ? [searchData?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.updated_by_user?.email} />

                            </span>
                        </Tooltip>
                        </li>
                    </ul> */}
                </div>
            </div>
            </CardBody>
        </Card>
        <DeleteModal
            open={deleteModal}
            setOpen={setDeleteModal}
            body={getDeleteMessage({title: `Chat`})}
            deleteBtnDisabled={isDeleting}
            heading='Delete Chat'
            onConfirm={handleDelete}
            loading={isDeleting}
        />
        </div>
    )
}

export default ChatGridView