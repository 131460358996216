import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Buttons from '../../Common/button/Buttons';
import { CREATEFLOWS, CLOSE, CHECK, DASHBOARD } from '../../Common/iconSource'
import { Literals } from '../common/literals'
import { useStore } from 'react-redux'
import { createDashboardApi, createDashboardListApi } from '../../../store/modules/analytics/dashboard/dashboardActions';
import { emitToastNotification } from '../../../helpers/toast_helper';
import { useNavigate } from 'react-router-dom';


const DashboardNameModal = ({ 
    openModal, 
    setOpenModal, 
  
    activeDomain
}) => {
    const history = useNavigate()

    const [dashboard_name, setdashboard_name] = useState('')




    const store = useStore()

    const createDashboard = () => {
        if (dashboard_name !== '') {
            var VISUALIZATION_BASE_URL = window._env_.REACT_APP_VISUALIZATION_BASE_URL;

            store.dispatch(createDashboardListApi({ name: dashboard_name })).then((resp) => {
                if (resp.status === 'success' && resp.data) {
                    let editURL = `${VISUALIZATION_BASE_URL}superset/dashboard/${resp.data}/?edit=true&standalone=1`;

                    history(`${Literals.links.DASHBOARD}/${resp.data}`, { state: { dashboardURL: editURL, isNew: true, domainId: activeDomain } })
                }
            })
            setOpenModal(false)
        } else {
            emitToastNotification('warning', 'Please enter dashboard name')
        }
    }


    return (
        <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true}>
            <ModalHeader>

                <div className="d-flex align-items-center">
                    <DASHBOARD />
                    <h2 className="section-heading">{'Create Dashboard'}</h2>
                </div>
                <div className="tab-menu right-flow-header">
                    <Buttons props={{
                        buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {
                            setOpenModal(false)
                            setdashboard_name(``)

                        }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                    }} />
                </div>
            </ModalHeader>
            <ModalBody >
                <label className="label">Dashboard Name *</label>
                <input type="text" name="flowname" className="custom-input-field mb-3" value={dashboard_name} onChange={(e) => {
                    setdashboard_name(e.target.value)
                }} />

            </ModalBody>
            <ModalFooter>

                <Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { createDashboard() }, ImgSrc: () => <CHECK />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
            </ModalFooter>
        </Modal>
    )
}

export default DashboardNameModal
