import { InputNumber, Radio, Switch, TimePicker, Tooltip } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import SingleSelection from '../../../Common/dropDown/SingleSelection'
import { CATALOG, CLOCK, CRAWLER, DATE, DELETEICON, PLAY, PLUS, REFRESHBLACK, SCHEDULER, SCHEMATRANSFORMER, SWITCH } from '../../../Common/iconSource'
import { TwoSections } from '../../../Common/twoSections/TwoSections'
import timezones from '../../../../assets/timezones.json'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import CronDashboard from './CronDashboard'
import { v4 as uuidv4 } from 'uuid'
import { useSelector, useStore } from 'react-redux'
import noteIco from '../../../../assets/images/noteIco.png'
import { cloneDeep, isEmpty } from 'lodash'
import { SetSchedulerPayload, setSelectedScheduler, setSelectedTempScheduler } from '../../../../store/modules/scheduler/action'
import { getRelativeTime, toTitleCase } from '../../common/helperFunctions'
import SchedulerModal from './SchedulerModal'
import PODSelection from '../../../Common/PODSelection'
import SqlEditor from '../../dataCatalogue/preview/components/SqlEditor'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { dictionaryEndpoint } from '../../../../store/actions'
import { getPreviewTable, saveSQlTrigger } from '../../../../store/modules/dataCatalogue/preview/previewActions'
import { setSelectedPodList } from '../../../../store/modules/common/podSelectionModalAction'

import eventBasedIcon from "../../../../assets/images/trigger-event.png"
import eventBasedIconwhite from "../../../../assets/images/trigger-event-white.png"
import eventBasedIcongray from "../../../../assets/images/trigger-event-gray.png"

const timezones_list = timezones.map(v => {
    return {
        Name: v.text,
        Value: v.text
    }
})

export const Trigger = ({
    isEditor = false
}) => {
    const store = useStore()
    const queryParams = new URLSearchParams(window.location.search);

    let schedulerType = queryParams.get('schedulerType');


    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)
    const onetimeCallCountApi = useRef(0);

    const repeatSelectOptions = [
        { Name: <><REFRESHBLACK /> <label className='mb-0'>Daily</label></>, Value: 'daily' },
        { Name: <><REFRESHBLACK /> <label className='mb-0'>Weekly</label></>, Value: 'weekly' },
        { Name: <><REFRESHBLACK /> <label className='mb-0'>Monthly</label></>, Value: 'monthly' },
        { Name: <><REFRESHBLACK /> <label className='mb-0'>No Repeat</label></>, Value: 'no repeat' }
    ]
    const [triggerToggleValue, setTriggerToggleValue] = useState('TIME')

    const geteventImg = () => {
        if (schedulerType?.toLocaleLowerCase() === 'crawler' || triggerToggleValue === 'TIME') {
            return eventBasedIcongray
        } else if (triggerToggleValue === 'EVENT') {
            return eventBasedIconwhite
        } else {
            return eventBasedIcongray

        }
    }

    const triggerToggleBtnOption = [
        { label: <><CLOCK /> <label style={schedulerType?.toLocaleLowerCase() === 'crawler' ? { color: '#FFFF' } : {}} className='mb-0 small' onClick={() => schedulerType?.toLocaleLowerCase() !== 'crawler' && !window.location.href.includes('scheduler/view') ? setTriggerType('TIME') : ''}>Time</label></>, value: "TIME", disabled: window.location.href.includes('scheduler/view') || schedulerType === 'crawler' },
        { label: <><img style={{ height: "18px", marginRight: "3px" }} src={geteventImg()}></img><label className='mb-0 small' onClick={() => schedulerType?.toLocaleLowerCase() !== 'crawler' && !window.location.href.includes('scheduler/view') ? setTriggerType('EVENT') : ''}>Event</label></>, value: "EVENT", disabled: window.location.href.includes('scheduler/view') || schedulerType === 'crawler' },
        // { label: <><DATE /> <label className='mb-0 small' onClick={() => setTriggerType('crawler')}>crawler</label></>, value: "crawler" },
    ];

    const failureSelectOptions = [
        { Name: 'On Success', Value: 'SUCCESS' },
        { Name: 'On Run', Value: 'RUN' },
        { Name: 'On Failure', Value: 'FAILED' },

    ]
    const enventBasedToggleBtnOption = [
        { label: <div onClick={() => schedulerType?.toLocaleLowerCase() !== 'crawler' && !window.location.href.includes('scheduler/view') ? setEventType('SCHEDULER') : ''}><SCHEDULER height={13} width={13} /> <label className='mb-0 small' >Scheduler</label></div>, value: "SCHEDULER", disabled: window.location.href.includes('scheduler/view') || schedulerType === 'crawler' },
        { label: <div onClick={() => schedulerType?.toLocaleLowerCase() !== 'crawler' && !window.location.href.includes('scheduler/view') ? setEventType('POD') : ''}><SCHEMATRANSFORMER /> <label className='mb-0 small'>POD</label></div>, value: "POD", disabled: window.location.href.includes('scheduler/view') || schedulerType === 'crawler' },
    ];
    const [failureSelectValue, setFailureSelectValue] = useState(failureSelectOptions[0].Name)


    const timeZoneSelectOptions = timezones_list
    const [selectedTypeName, setselectedTypeName] = useState('')
    const [showError, setshowError] = useState(false)
    const [repeatSelectValue, setRepeatSelectValue] = useState(repeatSelectOptions[0].Value)
    const [timeZoneSelectValue, setTimeZoneSelectValue] = useState(timeZoneSelectOptions[0].Name)
    const [eventType, seteventType] = useState(enventBasedToggleBtnOption[0].value)
    const [schedulerOffsetTime, setschedulerOffsetTime] = useState()
    const [podId, setPodId] = useState(null)
    const [Query, setQuery] = useState('');
    const [toggleActive, settoggleActive] = useState(false);

    const [sqlTables, setsqlTables] = useState([])
    const selectedScheduler = useSelector(state => state.Scheduler.selectedScheduler)

    const [OpenSchedulerModal, setOpenSchedulerModal] = useState(false);
    const [OpenPODModal, setOpenPODModal] = useState(false);
    const [selectedPodDetails, setselectedPodDetails] = useState([]);
    const [sqlCount, setsqlCount] = useState(null);
    const [previoussqlCount, setprevioussqlCount] = useState('NA');
    const [lastCountFlag, setLastCountFlag] = useState('true')
    const [tagList, settagList] = useState([]);
    const [lastRunDate, setlastRunDate] = useState('');
    const [currentRunDate, setcurrentRunDate] = useState('');

    useEffect(() => {
        if (selectedPodDetails && selectedPodDetails.length) {
            settagList([...selectedPodDetails]);
            // setQuery('')



        } else {
            settagList([]);

        }

    }, [selectedPodDetails]);

    const removeTags = (id) => {

        setQuery('')

        setsqlCount(null)

        setcurrentRunDate('')
        setprevioussqlCount('NA')
        setlastRunDate('')
        // let newPayload = cloneDeep(schedulerPayload);
        // newPayload['dependant_query'] = ''
        // newPayload['cron_redux']['sqlEditorQuery'] = '';

        // store.dispatch(SetSchedulerPayload(newPayload))
        setselectedPodDetails((selectedPODsList) => {

            const newList = [...selectedPODsList];
            const index = newList.findIndex((ele) => ele.podId === id);

            if (index !== -1) {
                newList.splice(index, 1);
            }

            settagList(newList);
            store.dispatch(setSelectedPodList(newList))
            return newList;
        });
    };
    const [weekList, setWeekList] = useState([
        { name: 'Mon', value: 'Monday', isChecked: false },
        { name: 'Tue', value: 'Tuesday', isChecked: false },
        { name: 'Wed', value: 'Wednesday', isChecked: false },
        { name: 'Thu', value: 'Thursday', isChecked: false },
        { name: 'Fri', value: 'Friday', isChecked: false },
        { name: 'Sat', value: 'Saturday', isChecked: false },
        { name: 'Sun', value: 'Sunday', isChecked: false },
    ])
    const timestampString = useRef()
    const [timestampList, setTimestampList] = useState([])

    useEffect(() => {
       
        if (schedulerPayload['active']) {
            settoggleActive(schedulerPayload['active'])
        }

        if (schedulerPayload && schedulerPayload?.type?.toLocaleLowerCase() === 'time') {
            if (schedulerType && schedulerType?.toLocaleLowerCase() === 'crawler') {
                let newPayload = cloneDeep(schedulerPayload);
                newPayload['type'] = 'PARTITION'
                store.dispatch(SetSchedulerPayload(newPayload))

                setTriggerToggleValue('TIME')
            } else {

                setTriggerToggleValue(schedulerPayload['type'])
            }
        } else if (schedulerPayload && (schedulerPayload?.type?.toLocaleLowerCase() === 'scheduler' || schedulerPayload?.type?.toLocaleLowerCase() === 'pod')) {
            setTriggerToggleValue('EVENT');
            setFailureSelectValue(schedulerPayload?.dependant_status)
            // if (schedulerPayload?.dependant_status) {
            //     let temp = failureSelectOptions.find((item) => item.Name?.toLowerCase() === schedulerPayload['dependant_status']?.toLowerCase())
            //    if(temp && temp.Name){

            //        setFailureSelectValue(temp.Name)
            //    }
            // }

            seteventType(schedulerPayload['type']);
            // if (schedulerPayload['count']) {
            //     setsqlCount(schedulerPayload['count'])
            // }
            if (schedulerPayload['previous_count']) {
                setprevioussqlCount(schedulerPayload['previous_count'])
            }
            setLastCountFlag(schedulerPayload['last_count_flag']?.toString())
            if (schedulerPayload['cron_redux']?.selectedPOdList) {

                setselectedPodDetails(schedulerPayload['cron_redux']['selectedPOdList'])
                store.dispatch(setSelectedPodList(schedulerPayload['cron_redux']['selectedPOdList']))


            }

            if (schedulerPayload['cron_redux']?.sqlEditorQuery) {

                setQuery(schedulerPayload['cron_redux']['sqlEditorQuery'])
                if (onetimeCallCountApi.current === 0) {
                    onetimeCallCountApi.current = 1;
                    onExecuteQuery(schedulerPayload['cron_redux']['sqlEditorQuery'], false)

                }
            }

            if (schedulerPayload?.date_time && schedulerPayload?.date_time !== '') {
                // setcurrentRunDate(getRelativeTime(schedulerPayload?.date_time, 'datetime'))
            }
            if (schedulerPayload?.last_run_date && schedulerPayload?.last_run_date !== '') {
                setlastRunDate(getRelativeTime(schedulerPayload?.last_run_date, 'datetime'))
            }


        } if (schedulerPayload && schedulerPayload?.type?.toLocaleLowerCase() === 'partition') {
            if (schedulerPayload['cron_redux']?.selectedPOdList) {

                setselectedPodDetails(schedulerPayload['cron_redux']['selectedPOdList'])
                setselectedTypeName(schedulerPayload['cron_redux']['selectedPOdList'][0]?.podName)
                store.dispatch(setSelectedPodList(schedulerPayload['cron_redux']['selectedPOdList']))


            }
            setTriggerToggleValue('TIME')
            schedulerType = 'crawler'
        }

        if (schedulerPayload && schedulerPayload?.monitor_time) {
            setschedulerOffsetTime(schedulerPayload?.monitor_time)
        }

    }, [schedulerPayload])

    // useEffect(()=>{
    //     if (!sqlTables.length) {
    //         let sqlSuggestionList = createSqlTableSuggestion(selectedPodDetails)
    //         if (sqlSuggestionList?.length) {
    //             setsqlTables([...sqlSuggestionList])
    //         }
    //     }
    // },[selectedPodDetails])

    useEffect(() => {
        if (selectedScheduler && selectedScheduler.name) {

            setselectedTypeName(selectedScheduler.name)
            let newPayload = cloneDeep(schedulerPayload);
            newPayload['dependant_scheduler'] = selectedScheduler.id
            newPayload['cron_redux']['selectedScheduler'] = selectedScheduler

            store.dispatch(SetSchedulerPayload(newPayload))
            store.dispatch(setSelectedTempScheduler(selectedScheduler))

        }
    }, [selectedScheduler])

    // useEffect(() => {
    //     alert(`schedilertype=${ schedulerPayload.type}`)


    // }, [schedulerType])


    const handleWeek = (index, checked) => {
        let temp = [...weekList]
        temp[index]['isChecked'] = !checked
        setWeekList([...temp])
    }

    const setTriggerType = (value) => {
        let payload = store.getState().Scheduler.schedulerPayload;
        if (schedulerType !== 'crawler') {
            setTriggerToggleValue(value)

            let newPayload = cloneDeep(payload);
            newPayload['type'] = value.toLocaleLowerCase() === 'time' ? 'TIME' : value.toLocaleLowerCase() === 'event' ? eventType : schedulerType?.toLocaleLowerCase() === 'crawler' ? 'crawler' : ''
            store.dispatch(SetSchedulerPayload(newPayload))
        }


    }

    const setEventType = (value) => {
        seteventType(value)
        let newPayload = cloneDeep(schedulerPayload);
        newPayload['type'] = value

        store.dispatch(SetSchedulerPayload(newPayload))


    }

    const handleRepeatSelect = (value) => {
        let val = value?.props?.children[2]?.props?.children.toLowerCase()
        setRepeatSelectValue(val)
    }

    const handleTimeZoneSelect = (value) => {
        setTimeZoneSelectValue(value)
    }

    const onTimeStampChange = (time, timeString) => {
        timestampString.current = timeString
    }

    const handleAddTimestamp = () => {
        if (timestampString.current !== undefined && timestampString.current !== null && timestampString.current !== '') {
            setTimestampList([...timestampList, timestampString.current])
        }
    }

    const handleRemoveTimestamp = (index) => {
        let temp = [...timestampList]
        temp.splice(index, 1)
        setTimestampList([...temp])
    }

    const triggerLeftContent = useMemo(() => {

        return <>
            <div className=''>
                <div className='d-flex align-items-center justify-content-left'>
                    <div className={`${triggerToggleValue.toLocaleLowerCase() === 'time' || schedulerType?.toLocaleLowerCase() === 'crawler' ? 'col-5' : 'col-4'} px-0 mb-2`}>
                        <label htmlFor="triggerType" className='mt-0 grey-color label'>Trigger Type</label>
                        <Buttons
                            props={{
                                buttonText: "",
                                toggleBtnClassName: `full-width-toggle ${window.location.href.includes('scheduler/view') ? 'scheduler-toggle-disable' : ''}`,
                                buttonEvent: (e) => {

                                    setTriggerType(e)

                                },
                                toggleBtnValue: triggerToggleValue,
                                ImgSrc: () => <></>,
                                isDisable: window.location.href.includes('scheduler/view') || schedulerType === 'crawler',
                                buttonType: Literals.BTN_TOGGLE,
                                toggleBtnOption: triggerToggleBtnOption,
                            }}
                        />
                        {/* <div className="triple-toggle-btn overwrite-insert-toggle">
                            <Buttons
                                props={{
                                    buttonText: "",
                                    toggleBtnClassName: `btn custom-btn ${window.location.href.includes('scheduler/view') ? 'scheduler-toggle-disable' : ''}`,
                                    buttonEvent: (e) => { setTriggerType(e) },
                                    toggleBtnValue: triggerToggleValue,
                                    ImgSrc: () => <></>,
                                    isDisable: window.location.href.includes('scheduler/view'),
                                    buttonType: Literals.BTN_TOGGLE,
                                    toggleBtnOption: triggerToggleBtnOption,
                                }}
                            />
                        </div> */}

                    </div>
                    {triggerToggleValue.toLocaleLowerCase() === 'event' && <div className="col-8  d-flex  py-2">
                        <div className='px-0 mb-2 col-5'>
                            <label htmlFor="triggerType" className='mt-0 grey-color label'>Listen Events On</label>
                            <Buttons
                                props={{
                                    buttonText: "",
                                    toggleBtnClassName: `full-width-toggle ${window.location.href.includes('scheduler/view') ? 'scheduler-toggle-disable' : ''}`,
                                    buttonEvent: (e) => {
                                        setEventType(e);
                                    },
                                    toggleBtnValue: eventType,
                                    ImgSrc: () => <></>,
                                    isDisable: window.location.href.includes('scheduler/view'),
                                    buttonType: Literals.BTN_TOGGLE,
                                    toggleBtnOption: enventBasedToggleBtnOption,
                                }}
                            />


                        </div>
                        <div className='col-7 pl-3'>
                            <div>
                                <label className='mt-0 grey-color label'>Selected {toTitleCase(eventType)} Name</label>
                            </div>
                            <div className=''>
                                <div className='row'>
                                    {eventType.toLocaleLowerCase() === 'scheduler' ? <div className='col-9'>
                                        {/* <input
                                            required
                                            type="text"
                                            disabled={true}
                                            className={`custom-input-field`}
                                            value={selectedTypeName}

                                        /> */}
                                        <span style={{ padding: "1px", width: "100%", fontSize: "12px" }} className='mt-0 grey-color label d-flex align-items-center'><span className='mr-2'>{selectedScheduler && selectedScheduler?.type?.toLocaleLowerCase() === 'time' ? <CLOCK /> : selectedScheduler?.type?.toLocaleLowerCase() === 'partition' ? <div style={{
                                            width: "18px",
                                            height: " 18px",
                                            borderRadius: "50%",
                                            border: " 1.5px solid #2C83C4",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "transparent",
                                            zIndex: 1,
                                        }}><CRAWLER height='10' /></div> : <div style={{
                                            width: "18px",
                                            height: " 18px",
                                            borderRadius: "50%",
                                            border: " 1.5px solid #2C83C4",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "transparent",
                                            zIndex: 1,
                                        }}><img style={{ height: "11px" }} src={eventBasedIcon}></img></div>}</span> {selectedScheduler.name ? <div className='text-elipse-scheduler-name'>{selectedTypeName}</div> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px", fontSize: "9px" }}>Click on `+SELECT` to select scheduler.</span></label>}</span>
                                    </div> :
                                        <div className='podtag-list-scheduler'>
                                            {tagList.length ? <React.Fragment>
                                                {tagList.map((tag, index) => {
                                                    return index < 2 ? <div key={index} className="podTag">

                                                        <Tooltip placement="topLeft" title={tag?.podName?.toUpperCase()}><span className="">{`${tag?.podName?.slice(0, 10)?.toUpperCase()}...`}</span></Tooltip>
                                                        <i className='bx bx-x cursor-pointer' style={{ color: "white", fontSize: "10px" }} onClick={() => { removeTags(tag.podId) }}></i>

                                                    </div> : <></>
                                                })
                                                }
                                                {tagList.length > 2 ? <div className="podTag">

                                                    <span className="">{`+ ${tagList.length - 2}`}</span>

                                                </div> : null}
                                            </React.Fragment> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><CATALOG /> <span style={{ marginLeft: "3px", fontSize: "9px" }}>Click on `+SELECT` to select multiple pods.</span></label>}
                                        </div>}
                                    <div className='col-3 pl-0 pr-0'>
                                        {window.location.href.includes('scheduler/view') ? "" : <Buttons
                                            props={{
                                                buttonText: 'Select',
                                                buttonClassName: "custom-btn-primary custom-btn btn-with-text w-100",
                                                buttonEvent: (e) => {

                                                    eventType.toLocaleLowerCase() === 'scheduler' ? setOpenSchedulerModal(true) : setOpenPODModal(true)
                                                    e.stopPropagation();

                                                },
                                                ImgSrc: () => <PLUS />,
                                                isDisable: window.location.href.includes('scheduler/view'),
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        />}
                                    </div>
                                </div>
                                {showError ? <span className='error'>Please Select {toTitleCase(eventType)}  </span> : ''}

                            </div>
                        </div>
                    </div>}
                    {schedulerType?.toLocaleLowerCase() === 'crawler' && <div className="col-7  d-flex  py-2">
                        <div className='col-8 pl-3 mb-2'>
                            <div>
                                <label className='mt-0 grey-color label'>Selected Pod Name</label>
                            </div>
                            <div className=''>
                                <div className='row'>
                                    {eventType.toLocaleLowerCase() === 'scheduler' || schedulerType?.toLocaleLowerCase() === 'crawler' ? <div className={`col-8`}>
                                        {/* <input
                                            required
                                            type="text"
                                            disabled={true}
                                            className={`custom-input-field`}
                                            value={selectedTypeName}

                                        /> */}
                                        <span style={{ padding: "1px", width: "100%", fontSize: "12px" }} className='mt-0 grey-color label d-flex align-items-center'><CATALOG />  {selectedPodDetails.length ? selectedTypeName : <label className="font-w-400 grey-color ml-2 label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px", fontSize: "9px" }}>Click on `+SELECT` to select pod.</span></label>}</span>
                                    </div> :
                                        <div className='podtag-list-scheduler'>
                                            <React.Fragment>
                                                {tagList.map((tag, index) => {
                                                    return index < 2 ? <div key={index} className="podTag">

                                                        <Tooltip placement="topLeft" title={tag?.podName?.toUpperCase()}><span className="">{`${tag?.podName?.slice(0, 10)?.toUpperCase()}...`}</span></Tooltip>
                                                        <i className='bx bx-x cursor-pointer' style={{ color: "white", fontSize: "10px" }} onClick={() => { removeTags(tag.podId) }}></i>
                                                    </div> : <></>
                                                })
                                                }
                                                {tagList.length > 2 ? <div className="podTag">

                                                    <span className="">{`+ ${tagList.length - 2}`}</span>

                                                </div> : null}
                                            </React.Fragment>
                                        </div>}
                                    <div className={` col-4 pr-0`}>
                                        <Buttons
                                            props={{
                                                buttonText: 'Select',
                                                buttonClassName: "custom-btn-primary custom-btn btn-with-text w-100",
                                                buttonEvent: (e) => {

                                                    eventType.toLocaleLowerCase() === 'pod' || schedulerType?.toLocaleLowerCase() === 'crawler' ? setOpenPODModal(true) : setOpenSchedulerModal(true)
                                                    e.stopPropagation();

                                                },
                                                ImgSrc: () => <PLUS />,
                                                isDisable: window.location.href.includes('scheduler/view'),
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        />
                                    </div>
                                </div>
                                {showError ? <span className='error'>Please Select {toTitleCase(eventType)}  </span> : ''}

                            </div>
                        </div>
                    </div>}
                </div>



                {triggerToggleValue.toLocaleLowerCase() === 'time' && <div className="d-flex mt-1 p-y2">
                    <span className='dsrc-lf-icon mr-2'>
                        <img src={noteIco} height={15} width={15} alt='Note' />                                        </span>
                    <div>
                        <p className="paragraph mb-0" style={{ color: '#FFA501' }}>  All jobs are run in UTC timezone  </p>
                    </div>
                </div>}

                {/* <div className='col-6 px-2 mb-2'>
                    <label className='mt-0 grey-color label'>Repeat</label>
                    <SingleSelection
                        props={{
                            label: "",
                            placeholder: "",
                            dropDownValue: repeatSelectValue,
                            onClickEvent: (value) => handleRepeatSelect(value),
                            optionList: repeatSelectOptions,
                            isDisable: false,
                        }}
                    />
                </div> */}
                {/* <div className='col-12 px-2 mb-2'>
                    <label className='mt-0 grey-color label'>Time Zone</label>
                    <SingleSelection
                        props={{
                            label: "",
                            placeholder: "",
                            dropDownValue: timeZoneSelectValue,
                            onClickEvent: (value) => handleTimeZoneSelect(value),
                            optionList: timeZoneSelectOptions,
                            isDisable: false,
                            className: 'timezone-select',
                            optionClassName: 'timezone-select-option'
                        }}
                    />
                </div> */}
                {/* {
                    repeatSelectValue === repeatSelectOptions[3]['Value']
                    ?   <div className='col-6 px-2 mb-2'>
                            <label className='mt-0 grey-color label'>Date</label>
                            <input
                                type="date"
                                className={`custom-input-field`}
                                onChange={(e)=>{}}
                            />
                        </div>
                    : ''
                } */}
                {/* <div className={`${repeatSelectValue === repeatSelectOptions[3]['Value'] ? 'col-6' : 'col-12'} px-2 mb-2`}>
                    <label className='mt-0 grey-color label'>Offset Time (in Seconds)</label>
                    <div>
                        <InputNumber
                            min={0}
                            max={270}
                            keyboard={true}
                            defaultValue={120}
                            step={10}
                            className='custom-input-field w-100 p-0'
                        />
                    </div>
                </div> */}
            </div>
        </>
    }, [triggerToggleValue, Query, lastCountFlag, currentRunDate, repeatSelectValue, tagList, failureSelectValue, eventType, selectedTypeName])

    const changeQuery = (query) => {

        setQuery(query);

        let newPayload = cloneDeep(schedulerPayload);
        newPayload['dependant_query'] = `select count(*) from  (${query})`
        newPayload['cron_redux']['sqlEditorQuery'] = query;

        store.dispatch(SetSchedulerPayload(newPayload))

        if (!sqlTables.length && selectedPodDetails.length) {
            createSqlTableSuggestion(selectedPodDetails)
        }


    }
    const onExecuteQuery = (sqlEditorQuery, isStoredQuery = true) => {
        let uuid = uuidv4();

        if (sqlEditorQuery !== '') {
            setsqlCount(null)

            setcurrentRunDate('')
            let sqlQuery = `select count(*) from  (${sqlEditorQuery})`
            let payload = {
                uuid: uuid,
                table_name: '',
                page: 0,
                do_count: false,
                sort_body: [],
                filter_body: [],
                table_ids: [],
                query: sqlQuery,
            }
            try {
                store
                    .dispatch(getPreviewTable(payload, true))
                    .then((resp) => {
                        if (resp && resp.status === "success" && resp.data?.result) {
                            let responseData = resp.data?.result;

                            setsqlCount(responseData.data_record[0][0])
                            setcurrentRunDate(getCurrentDateTime())
                            if (isStoredQuery) {
                                let newPayload = cloneDeep(schedulerPayload);
                                newPayload['count'] = responseData.data_record[0][0];
                                newPayload['date_time'] = getCurrentDateTime()
                                setQuery(sqlEditorQuery);
                                newPayload['dependant_query'] = sqlQuery;
                                newPayload['cron_redux']['sqlEditorQuery'] = sqlEditorQuery;
                                store.dispatch(SetSchedulerPayload(newPayload))
                            }




                        } else {
                            setsqlCount(null)

                            setcurrentRunDate('')
                        }
                    })
                    .catch((err) => {
                        setsqlCount(null)

                        setcurrentRunDate('')
                    });
            } catch (error) { }
        }
    }

    const getCurrentDateTime = () => {
        return getRelativeTime(new Date().toISOString(), 'datetime')
    }

    const cronBodyRightContent = useMemo(() => {
        if (triggerToggleValue.toLocaleLowerCase() === 'time' || schedulerType?.toLocaleLowerCase() === 'crawler') {

            return <>
                <div className='row ml-0 p-0 m-0'>
                    <div className='col-12 mb-2 px-0'>
                        <CronDashboard />
                    </div>

                </div>
            </>
        } else if (eventType.toLocaleLowerCase() === 'scheduler') {
            return <>
                <div className='d-flex align-items-center '>
                    <div className='col-6 pl-0'>
                        <div>
                            <label className='mt-0 grey-color label'>Previous Steps status </label>
                        </div>
                        <div className='mb-2'>
                            <SingleSelection
                                props={{
                                    label: "",
                                    placeholder: "",
                                    dropDownValue: failureSelectValue,
                                    onClickEvent: (value) => {
                                        setFailureSelectValue(value);
                                        let newPayload = cloneDeep(store.getState().Scheduler.schedulerPayload);
                                        newPayload['dependant_status'] = value
                                        store.dispatch(SetSchedulerPayload(newPayload))

                                    },
                                    optionList: failureSelectOptions,
                                    isDisable: window.location.href.includes('scheduler/view'),
                                }}
                            />
                        </div>
                    </div>
                    {/* <div className='col-6 pl-0'>
                        <label className='mt-0 mb-0 grey-color label'>Offset Time (in Seconds)</label>
                        <div>
                            <InputNumber
                                min={900}
                                max={1200}

                                keyboard={true}
                                value={schedulerOffsetTime}
                                onChange={(e) => {

                                    setschedulerOffsetTime(e);
                                    let newPayload = cloneDeep(schedulerPayload);
                                    newPayload['offset'] = e;
                                    store.dispatch(SetSchedulerPayload(newPayload))
                                }}
                                disabled={window.location.href.includes('scheduler/view')}
                                step={10}
                                className='custom-input-field w-100 p-0'
                            />
                        </div>
                    </div> */}
                </div>

            </>
        }
        else if (eventType.toLocaleLowerCase() === 'pod') {
            return <>
                <div className='d-flex align-items-center' style={{ height: "200px" }}>
                    <div className='col-7 pl-0 mt-1'>
                        <label className='mt-0 grey-color label'>{`Select count(*) from (`}</label>
                        <div style={{ height: "145px" }} className="scheduler-sqlEdition position-relative">
                            <SqlEditor
                                height={'120px'} readOnly={window.location.href?.includes('scheduler/view/') ? true : false} ExecuteQueryClick={onExecuteQuery} sqlTableDetails={sqlTables} setQueryvalue={changeQuery} QueryValue={Query}
                            />
                            <div>

                                <Buttons
                                    props={{
                                        buttonText: "",
                                        buttonClassName:
                                            "custom-btn-primary position-absolute float-execute-btn float-right",
                                        buttonEvent: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            store.dispatch(saveSQlTrigger({ trigger: 'run_click' }))
                                            // onExecuteQuery()


                                        },
                                        tooltip: "Execute",
                                        ImgSrc: () => <PLAY width='15' height='15' />,
                                        isDisable: window.location.href?.includes('scheduler/view/') ? true : false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </div>
                        </div>


                        <div>
                            <label className='mt-0 grey-color label'>{`)`}</label>
                        </div>

                    </div>
                    <div style={{ height: "185px" }} className='col-5 p-0 m-0'>
                        {/* <div className='col-12 pl-0'>
                            <div>
                                <label className='mt-0 grey-color label'>Event Type</label>
                            </div>
                            <div className='mb-2'>
                                <InputNumber

                                    value={"On Change (SQL)"}
                                    onChange={(e) => {

                                    }}
                                    disabled={true}

                                    className='custom-input-field w-100 p-0'
                                />

                            </div>
                        </div> */}

                        <div className='col-12 pl-0'>
                            <label className='mt-0 mb-0 grey-color label'>Offset Time (in Seconds)</label>
                            <div>
                                <InputNumber
                                    min={2}
                                    keyboard={true}
                                    value={schedulerOffsetTime}
                                    onChange={(e) => {

                                        setschedulerOffsetTime(e)
                                        let newPayload = cloneDeep(schedulerPayload);
                                        newPayload['monitor_time'] = e
                                        newPayload['count'] = sqlCount
                                        store.dispatch(SetSchedulerPayload(newPayload))
                                    }}
                                    disabled={window.location.href.includes('scheduler/view')}
                                    step={10}
                                    className='custom-input-field w-100 p-0'
                                />
                            </div>
                        </div>

                        <div className='d-flex mt-2'>
                            <div className="col-6 pl-0 mt-1">
                                <label htmlFor="schedulerDesc" className='mt-1 grey-color label'>Current Count</label>
                                <p htmlFor="schedulerDesc" style={{ lineHeight: 0 }} className='mt-0 text-xl label'>{sqlCount}</p>
                                {currentRunDate !== '' ? <p htmlFor="schedulerDesc" className="small text-muted mt-0 mb-0" style={{ lineHeight: 1, fontSize: '8px' }}>{currentRunDate}</p> : ''}

                            </div>
                            <div className="col-6 pl-0 mt-1">
                                <label htmlFor="schedulerDesc" className='mt-1 grey-color label'>Last Run Count</label>
                                <p htmlFor="schedulerDesc" style={{ lineHeight: 0 }} className='mt-0 text-xl  label'>{previoussqlCount}</p>
                                {lastRunDate !== '' ? <p htmlFor="schedulerDesc" className="small text-muted mt-0 mb-0" style={{ lineHeight: 1, fontSize: '8px' }} >{lastRunDate
                                }</p> : ''}

                            </div>
                        </div>
                        <div className="col-12 d-flex p-0 mt-4">
                            <label htmlFor="schedulerDesc" className=' col-3 p-0 mt-1 mr-3 grey-color label'>Trigger On :
                            </label>
                            <Radio.Group disabled={window.location.href.includes('scheduler/view')} onChange={(e) => {
                                setLastCountFlag(e.target.value)
                                let newPayload = cloneDeep(schedulerPayload)
                                newPayload['count'] = sqlCount
                                newPayload['last_count_flag'] = e.target.value === 'true' ? true : false
                                store.dispatch(SetSchedulerPayload(newPayload))
                            }} value={lastCountFlag}>
                                <Radio style={{ fontSize: "12px" }} value={'false'}>Current  Count <b>{'>'}</b>  0 </Radio>
                                <Radio style={{ fontSize: "12px" }} value={'true'}>Current <b>{'!='}</b> Last Run Count</Radio>
                            </Radio.Group>

                        </div>

                    </div>

                </div>

            </>
        }
    }, [triggerToggleValue, failureSelectValue, lastCountFlag, sqlTables, sqlCount, previoussqlCount, lastRunDate, currentRunDate, Query, eventType, schedulerOffsetTime])

    const triggerRightContent = useMemo(() => {

        return <>
            <div className='row ml-0'>
                <div className='col-12 px-2 mb-2'>
                    <label className='mt-0 grey-color label'>Timestamp</label>
                    <div className='sch-timestamp-list'>
                        <ul className="list-group">
                            {
                                timestampList.length === 0
                                    ? <>
                                        <div className='text-center p-3 text-black-50'>No Timestamp</div>
                                    </>
                                    : timestampList.map((v, i) =>
                                        <li key={`time-stmp-${i}`} className="list-group-item px-2 py-0 small sch-timestamp-row">
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <label className="mb-0">
                                                    {v}
                                                </label>
                                                <div className='sch-del-timestamp'>
                                                    <Tooltip placement={"bottom"} title="Delete">
                                                        <button
                                                            className={
                                                                "custom-btn-outline custom-btn btn-with-icon error-red border-0 ml-2 bg-transparent"
                                                            }
                                                            onClick={() => {
                                                                handleRemoveTimestamp(i)
                                                            }}
                                                        >
                                                            <DELETEICON />
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </li>
                                    )
                            }
                        </ul>
                    </div>
                </div>
                <div className='col-12 px-2 mb-2'>
                    <div className='row'>
                        <div className='col-6 pr-1'>
                            <TimePicker use12Hours format="h:mm A" onChange={onTimeStampChange} className="w-100" />
                        </div>
                        <div className='col-6 pl-1'>
                            <Tooltip placement={'top'} title={'Add Timestamp'}>
                                <button
                                    type='button'
                                    className={`custom-btn-outline custom-btn btn-with-text w-100`}
                                    onClick={handleAddTimestamp}
                                >
                                    <PLUS /> <span className='ml-1'>Add Timestamp</span>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }, [timestampList])

    const createSqlTableSuggestion = (selectedPODsList) => {
        let sqlSuggestionList = []
        selectedPODsList.forEach(async (element) => {

            let response = await store.dispatch(
                dictionaryEndpoint(element.podId, false)
            );

            if (response?.data?.column_detail?.length) {

                response.data.column_detail.forEach(itm => {
                    sqlSuggestionList.push({ name: `${itm.column_name}`, description: '', type: 'Column', tableName: element.pod_name },)
                })
            }
            sqlSuggestionList.push({ name: `${element.dataDomain}.${element.pod_name}`, description: '', type: 'Table', tableName: '' })

            if (sqlSuggestionList?.length) {
                setsqlTables([...sqlSuggestionList])
            }
        })

    }

    const save = (selectedPODsList) => {

        if (selectedPODsList.length && schedulerType?.toLocaleLowerCase() !== 'crawler') {
            setselectedPodDetails([...selectedPODsList])
            store.dispatch(setSelectedPodList(selectedPODsList))
            // setselectedTypeName(selectedPODsList[0].pod_name)
            setOpenPODModal(false)
            let sqlSuggestionList = []
            selectedPODsList.forEach(async (element) => {

                let response = await store.dispatch(
                    dictionaryEndpoint(element.podId, false)
                );

                if (response?.data?.column_detail?.length) {

                    response.data.column_detail.forEach(itm => {
                        sqlSuggestionList.push({ name: `${itm.column_name}`, description: '', type: 'Column', tableName: element.pod_name },)
                    })
                }
                sqlSuggestionList.push({ name: `${element.dataDomain}.${element.pod_name}`, description: '', type: 'Table', tableName: '' })

                if (sqlSuggestionList?.length) {
                    setsqlTables([...sqlSuggestionList])
                }
            })

            // let sqlSuggestionList = createSqlTableSuggestion(selectedPODsList)
            // if (sqlSuggestionList.length)
            //     setsqlTables([...sqlSuggestionList])

            setQuery(`select * from ${selectedPODsList[0].dataDomain}.${selectedPODsList[0].pod_name}`)

            let newPayload = cloneDeep(schedulerPayload);
            newPayload['pod_ids'] = selectedPODsList.map((pod) => pod.podId)
            newPayload['cron_redux']['selectedPOdList'] = selectedPODsList;
            newPayload['cron_redux']['sqlEditorQuery'] = `select * from ${selectedPODsList[0].dataDomain}.${selectedPODsList[0].pod_name}`;

            newPayload['dependant_query'] = `select count(*) from ( select * from ${selectedPODsList[0].dataDomain}.${selectedPODsList[0].pod_name})`
            store.dispatch(SetSchedulerPayload(newPayload))

        } else if (selectedPODsList.length && schedulerType?.toLocaleLowerCase() === 'crawler') {
            setselectedTypeName(selectedPODsList[0].pod_name);
            store.dispatch(setSelectedPodList(selectedPODsList))
            // setselectedTypeName(selectedPODsList[0].pod_name)
            setOpenPODModal(false)
            let newPayload = cloneDeep(schedulerPayload);
            newPayload['pod_ids'] = selectedPODsList.map((pod) => pod.podId)
            newPayload['cron_redux']['selectedPOdList'] = selectedPODsList;

            store.dispatch(SetSchedulerPayload(newPayload))


        } else {
            emitToastNotification('info', 'Please select one pod')
        }


    }
    const onRowSelection = (record, rowIndex) => {

        setPodId(record.key)

    }

    return <>
        <Card className='card custom-card p-0 mb-2'>
            <CardHeader className='padding-2 bg-white'>
                <div className='d-flex align-items-center w-100 justify-content-between'>
                    <label className='mb-0 d-flex align-items-center font-weight-bold'>
                        <SWITCH />
                        <div className='p-1'></div>
                        Trigger
                    </label>
                    <div className='d-flex h-100 align-items-center justify-content-end'>
                        <Tooltip title={schedulerPayload['active'] ? 'Active' : 'Inactive'} >  <Switch
                            checked={toggleActive}
                            size="small"
                            onChange={(checked) => {
                                let newPayload = cloneDeep(schedulerPayload)
                                settoggleActive(checked)
                                newPayload['active'] = checked
                                store.dispatch(SetSchedulerPayload(newPayload))
                            }}
                            disabled={!isEditor || window.location.href.includes('scheduler/view')}
                        /></Tooltip>
                    </div>
                </div>
            </CardHeader>
            <CardBody className='padding-2'>
                <TwoSections
                    equalWidth={true}
                    leftTemplate={triggerLeftContent}
                    rightTemplate={cronBodyRightContent} // {triggerRightContent}
                    leftCardView={false}
                    rightCardView={false}
                    className={'py-0'}
                />
                {
                    repeatSelectValue === repeatSelectOptions[1]['Value']
                        ? <div className='row'>
                            <div className='col-12 mb-2'>
                                <label className='mt-0 grey-color label'>Repeat On</label>
                                <div>
                                    <div>
                                        {
                                            weekList.map((v, i) =>
                                                <div key={`weekname-${i}`} onClick={() => handleWeek(i, v.isChecked)} className={`align-items-center btn custom-btn-${v.isChecked ? 'primary' : 'outline'} d-inline-flex rounded-pill mr-2`}>
                                                    {v.name}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""
                }
            </CardBody>
        </Card>
        <SchedulerModal isModalOpen={OpenSchedulerModal} onClose={() => setOpenSchedulerModal(false)} fromRoute='schedulerModal' />
        <PODSelection isMultiSelection={schedulerType?.toLocaleLowerCase() === 'crawler' ? false : true} save={save} podId={podId} openModal={OpenPODModal} onClose={() => { setOpenPODModal(false) }} onRowSelection={onRowSelection} fromRoute={schedulerType?.toLocaleLowerCase()} />


    </>
}
