import React from 'react'
import { useSelector } from "react-redux"
import { Tooltip } from "antd";
import { get } from "lodash";
import "../summary.scss";
import "../../dictionary/components/dictionary.scss";
import { DATASET, RIGHTARROW, DOMAIN_SMALL, CATALOG, NEWDOMAINICON } from "../../../../Common/iconSource"
import { ICON_ARROW_RIGHT, ICON_CATALOG, ICON_CATALOG_DOMAIN } from '../../../../Common/newIconSource';
import { useNavigate } from 'react-router-dom';
import { Literals } from '../../../common/literals';

const PodMetaHeader = () => {

    const history = useNavigate()

    const catalogueDictionary = useSelector((state) => state.DataCatalogue.Dictionary);

    const tableName = get(
        catalogueDictionary,
        `dictionaryPodData.table_name`,
        ""
    );

    const domainName = get(
        catalogueDictionary,
        `dictionaryPodData.data_domain_detail.data_domain_name`,
        ""
    );

    const domainId = get(
        catalogueDictionary,
        `dictionaryPodData.data_domain_detail.data_domain_id`,
        ""
    );

    const tagDetails = get(
        catalogueDictionary,
        `dictionaryPodData.tag`,
        []
    );



    return (
        <div id='podMetaHead' className="pod-meta-headings">
            <p className="subtitle-datacatlog-icon m-0 text-uppercase d-flex align-items-center">
                <ICON_CATALOG_DOMAIN/> 
                <span className="ml-2 summary-pod-database pod-det-domain-lnk"
                    onClick={() => {
                        if(domainId && domainId !== "") {
                            history(Literals.links.CLARISTA_NAVIGATOR + '#pod', { state: { domainId } })
                        }
                    }}
                >
                    {domainName?.replaceAll('_', ' ')}
                </span> 
                <span className='mx-2'><ICON_ARROW_RIGHT /></span>
            </p>
            <p className="subtitle-datacatlog-icon m-0 d-flex align-items-center"><ICON_CATALOG /> <span className="ml-2 summary-pod-ellipsis">{tableName?.replaceAll('_', ' ')?.toUpperCase()}</span> </p>
        </div>
    )
}

export default PodMetaHeader