import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import {
  getUserGroupList, setUMLeadersList, setUserGroupDescription, setUserGroupId, setUserGroupName
} from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import { USERSGROUP } from "../../../../Common/iconSource";
import ActionPopUp from "../../../common/actionPopUp";
import { getRelativeTime,ErrorComponent,getDeleteMessage,NoDataComponent } from "../../../common/helperFunctions";
import { Literals } from "../../../common/literals";
import LoadingComponent from "../../../common/loadingComponent";
import NoSearchResultFound from "../../../common/NoSearchResultFound";
import UserManagementCard from "../../common/UserManagementCard";
import DeleteModal from "../../../../Common/deleteModal/DeleteModal";

const DataDomainListView = ({ searchData }) => {
  const history = useNavigate();
  const store = useStore();
  const dispatch = useDispatch();
  const [userGroupsList, setUserGroupsList] = useState([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
  const data = [];
  const [empty,setEmpty]=useState(false);
  const [errMsg,setErrMsg]=useState('');
  const loadingStatus = useSelector(
    (state) => state.LoadingReducer.loadingStatus
  );
  const [, setSelectedId] = useState("");
  const currentUsergroupRef = React.useRef({current: {}})

  useEffect(() => {
    getUserGroupListData();
  }, []);

  useEffect(() => {
    dispatch(setUserGroupDescription(""));
    dispatch(setUserGroupName(""));
    dispatch(setUserGroupId(""));
    dispatch(setUMLeadersList([]));
  }, []);

  function clickEdit(group) {
    history(Literals.links.USER_MANAGEMENT_EDIT_USER_GROUP + group.key,{state:{tabName:Literals.USERGROUP}});
    window.history.replaceState({state:{tabName:Literals.USERGROUP}},'')

  }

  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };
  function clickDelete(group) {

    currentUsergroupRef.current = group

    setSelectedId(group.key);
    setOpenDeletePopup(true);
    // setDeletePopUpContent({
    //   modalClass: "delete-popup",
    //   heading: "Delete",
    //   message: "This action will delete the group permanently.",
    //   headingIcon: "bx bx-error",
    //   button: [
    //     {
    //       name: "Delete",
    //       action: () => deleteId(group.key),
    //     },
    //     {
    //       name: "Cancel",
    //       action: closeDeletePopUp,
    //     },
    //   ],
    //   elements: [],
    //   closeActionPopup: closeDeletePopUp,
    // });
    setOpenDeletePopup(true);
  }

  function redirectTo(key) {
    history(Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS + key, {state: { tabName: Literals.USERGROUP } });
    window.history.replaceState({state:{tabName:Literals.USERGROUP}},'')

  }

  const deleteId = async (id) => {
    let response;
    try {
      response = await store.dispatch(
        getUserGroupList({
          method: "DELETE",
          endPoint: "group_manager/group/" + id + "/",
        })
      );
      if (response.status === "success") {
        emitToastNotification('success', response?.message);
        getUserGroupListData();
        setOpenDeletePopup(false);
      } else {
        emitToastNotification('error', response?.message);
      }
    } catch (error) {
      emitToastNotification('error', error?.message);
    }
  };

  const getUserGroupListData = async () => {
    let response;
    try {
      response = await store.dispatch(
        getUserGroupList({ method: "GET", endPoint: "group_manager/group/" })
      );
      if (response.status === "success") {
        if(response.data.length==0){
          setEmpty(true);
        }
        setUserGroupsList(response.data);
      }
    } catch (error) { 
        setErrMsg(error.message)
    }
  };

  const filterData =
    userGroupsList &&
    userGroupsList.filter((data) => {
      return (
        data?.name
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.description
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        getRelativeTime(data?.updated_on)
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase()) ||
        data?.created_by_user?.username
          ?.toLocaleLowerCase()
          .includes(searchData?.toLocaleLowerCase())
      );
    });

  filterData &&
    filterData.map((value) =>
      data.push({
        key: value?.id,
        userGroupName: value?.name?.toUpperCase(),
        description: value?.description,
        domainCount: value?.data_domain,
        updated_on: value?.updated_on,
        createdBy: value?.created_by_user?.username,
        users: value?.user?.count,
        data: value
      })
    );

  function getCardView() {
    return data.map((ele, i) => (
      <UserManagementCard
        key={i}
        searchData={searchData}
        domainDetails={ele}
        selectedDomainList={data}
        clickEdit={clickEdit}
        clickDelete={clickDelete}
        icon={<USERSGROUP color="#2c83c4" />}
        tabName="Usergroup"
        redirectTo={redirectTo}
      />
    ));
  }

  return (
    <div>
       { loadingStatus ? <LoadingComponent /> : errMsg.length>0?<ErrorComponent msg={errMsg}/> : empty?<NoDataComponent logo={<USERSGROUP width="60" height="60" color="black"/>} message="
      Click On (+) To Create User Group"/>:searchData?.length > 0 && filterData?.length === 0 ? (
        <NoSearchResultFound />
      ) :  (
        
       userGroupsList.length > 0? <div className="data-domain-gridview usr-manage-usr-home new-box-style">{getCardView()}</div>:<></>
      )}

      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={getDeleteMessage({
          title: 'Usergroup'
        })}
        onConfirm={() => deleteId(currentUsergroupRef?.current?.key)}
      />

      {/* <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent}
      /> */}
    
    </div>
  );
};

export default DataDomainListView;