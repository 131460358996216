import React, { useState, useEffect, useContext } from 'react'
import { useSelector, usestore, useStore } from "react-redux"

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Buttons from '../../../Common/button/Buttons';
import { CHECK, CLOSE } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import { ICON_DASHBOARD, ICON_FLOWS } from '../../../Common/newIconSource';
import { getUserSelectedDomain } from '../../../../store/actions';
import LandingDomainTabs from '../../../Common/mainLandingPage/components/LandingDomainTabs';
import SearchInput from '../../../Common/search/Search';
import DashboardGridListIndex from '../../../Common/claristaNewLandingPage/DashboardGridListIndex';
import { SET_SELECTED_DASHBOARD } from '../../../../store/modules/scheduler/SchedulerActionType';




const DashboardSelectionModal = ({ isModalOpen, onClose, fromRoute = '', data }) => {

    const store = useStore()
    const selectedScheduledDashboardTemp = useSelector(state => state.Scheduler?.selectedScheduledDashboardTemp)
    const [searchTerm, setSearchTerm] = useState('')
    const [activeTabKey, setActiveTabKey] = useState('pod')
    const [activeDomain, setActiveDomain] = useState('')
    const [isDomainFailed, setDomainFailed] = useState(false)
    const [isDataLoading, setDataLoading] = useState(false)
   
    const [filterList, setFilterList] = React.useState({ connections: [], tags: [] })

    let defaultTabKey = 'pod'
    useEffect(() => {
        if (defaultTabKey) {
            setActiveTabKey(defaultTabKey)
        }
    }, [defaultTabKey])

    useEffect(() => {
        if (data &&
            Object.keys(data).length > 0 &&
            data?.data_domain_detail?.data_domain_id
        ) {
            setActiveDomain(data?.data_domain_detail?.data_domain_id)
        } else {
            // if (currentTransformer && !getObjectLength(currentTransformer?.content) && !currentTransformer?.content?.table_id) {

            store.dispatch(getUserSelectedDomain())
                .then(res => {
                    const domain = res?.data?.domain ?? undefined
                    if (domain && domain?.id) {
                        setActiveDomain(domain?.id)
                    }
                    else if (domain) {
                        setActiveDomain(domain?.id ?? 0)
                    }
                })
                .catch(err => {
                    setDomainFailed(true)
                    console.error(err?.message)
                })
            // }

        }

    }, [data])
    return (
        <Modal size="xl" isOpen={isModalOpen} className="custom-modal scheduler-flow-modal" centered={true}>
            <ModalHeader>

                <div className="paragraph font-w-600 dash-ic"><ICON_DASHBOARD height='18' width='18'></ICON_DASHBOARD> Select Dashboard</div>
                <Buttons props={{ tooltip: 'Close', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon float-right", buttonEvent: () => { onClose() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

            </ModalHeader>
            <ModalBody className='overflow-hidden'>
                <div className='flow-select-modal'>
                    {/* <FlowsLandingPage isModal={true} fromRoute={fromRoute !== '' ? fromRoute : "alert"} /> */}
                    <div className='w-100'>
                        <div className=''>
                            <div className='domain-tabs'>
                                {
                                    // activeTabKey === 'business' ? ''
                                    //     :
                                    <LandingDomainTabs
                                        activeDomain={activeDomain}
                                        setActiveDomain={setActiveDomain}
                                        isDomainFailed={isDomainFailed}
                                        setDomainFailed={setDomainFailed}
                                        activeTabKey={activeTabKey}
                                        isDataLoading={isDataLoading}
                                    />
                                }
                                <div className='pod-filters d-flex justify-content-end'>

                                    <SearchInput
                                        searchData={searchTerm}
                                        setSearchData={setSearchTerm}
                                        placeholder={'Search here...'}
                                        autoFocus={true}
                                    />


                                </div>
                            </div>
                        </div>
                        <div className='pod-list-wrap'>

                            {<DashboardGridListIndex
                                activeDomain={activeDomain}
                                searchTerm={searchTerm}
                                activeTabKey={activeTabKey}
                                filterList={filterList}
                                setFilterList={setFilterList}
                                fromRoute={'scheduler'}
                                setDataLoading={setDataLoading}
                                isDataLoading={isDataLoading}
                            />}

                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Buttons props={{ tooltip: 'Done', buttonText: 'Done', buttonClassName: " custom-btn-primary custom-btn btn-with-text float-right", buttonEvent: () => { store.dispatch({ type: SET_SELECTED_DASHBOARD, payload: { ...selectedScheduledDashboardTemp } }); onClose() }, ImgSrc: () => <CHECK />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />

            </ModalFooter>

        </Modal >
    )
}

export default DashboardSelectionModal
