import {
  SET_DICTIONARY_TABLE_DATA,
  EXPAND_DICTIONARY_TABLE,
  EDIT_DICTIONARY_TABLE,
  SAVE_EDIT,
  SET_GRAPH_DATA,
  EDIT_HEADER_DESCRIPTION,
  EDIT_DICTIONARY_SEARCH,
  UNABLE_GRAPH_VIEW,
  DICTIONARY_GRAPH_LOADER,
  RESET_REDUX_STATE,
  SET_PREVIEW_POD_DATA,
  SET_DICIONARY_DEFAULT,
  SET_INITIAL_CATALOGUE_TAB,
  SET_GRAPH_SAMPLE_SELECTED,
  RESET_GRAPH_SAMPLE_SELECTED,
  SET_SELECTED_BUSINESS_TERM_TEMP,
  SET_SELECTED_BUSINESS_TERM,
  SAVE_APPLIED_FILTERS,
  RESET_SAVED_APPLIED_FILTERS
} from "./dictionaryActionTypes";

const initialState = {
  expandDictionaryTable: false,
  editDictionaryTable: false,
  saveEdit: false,
  graphData: [],
  editDescription: "",
  dictionarySearch: "",
  tableGraphView: false,
  dictionaryGraphLoader: false,
  dictionaryPodData: [],
  dictionaryTableData: [],
  previewPODdetails: [],
  initialCatalogueTab: "",
  sampleSelected: 1000,
  selectedBusinessTerm: {},
  selectedBusinessTermTemp: {},
  save_search_filter: {}

};
const DictionaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DICTIONARY_TABLE_DATA:
      state = { ...state };
      state.dictionaryPodData = action.payload.podData;
      state.previewPODdetails = action.payload.podData;
      state.dictionaryTableData = action.payload.podColumnDetail;
      state.podColumnCount = action.payload.columnCount;
      break;
    case SET_PREVIEW_POD_DATA:
      state = { ...state };

      state.previewPODdetails = action.payload.podData;

      break;
    case SAVE_APPLIED_FILTERS:
      state = { ...state };

      state.save_search_filter = action.payload;

      break;

    case RESET_SAVED_APPLIED_FILTERS:
      state = { ...state };

      state.save_search_filter = action.payload;
      break;
    case RESET_REDUX_STATE:
      return initialState;
    case EXPAND_DICTIONARY_TABLE:
      state = { ...state };
      state.expandDictionaryTable = action.payload;
      break;

    case SET_SELECTED_BUSINESS_TERM_TEMP:
      state = { ...state };
      state.selectedBusinessTermTemp = action.payload;
      break;

    case SET_SELECTED_BUSINESS_TERM:
      state = { ...state };
      state.selectedBusinessTerm = action.payload;
      break;
    case EDIT_DICTIONARY_TABLE:
      state = { ...state };
      state.editDictionaryTable = action.payload;
      break;
    case SAVE_EDIT:
      state = { ...state };
      state.saveEdit = action.payload;
      break;
    case SET_GRAPH_DATA:
      state = { ...state };
      state.graphData = action.payload.graphData;
      break;
    case EDIT_HEADER_DESCRIPTION:
      state = { ...state };
      state.editDescription = action.payload;
      break;
    case EDIT_DICTIONARY_SEARCH:
      state = { ...state };
      state.dictionarySearch = action.payload;
      break;
    case UNABLE_GRAPH_VIEW:
      state = { ...state };
      state.tableGraphView = action.payload;
      break;
    case DICTIONARY_GRAPH_LOADER:
      state = { ...state };
      state.dictionaryGraphLoader = action.payload;
      break;
    case SET_DICIONARY_DEFAULT:
      return initialState;

    case SET_INITIAL_CATALOGUE_TAB:
      return {
        ...state,
        initialCatalogueTab: action.payload,
      };

    case SET_GRAPH_SAMPLE_SELECTED:
      return {
        ...state,
        sampleSelected: action.payload,
      };


    case RESET_GRAPH_SAMPLE_SELECTED:
      return {
        ...state,
        sampleSelected: 1000,
      }

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DictionaryReducer;
