import React from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import ExplorerDirectoryTree from './ExplorerDirectoryTree'
import SmallConnDetails from './SmallConnDetails'
import SplitPane from 'split-pane-react/esm/SplitPane'
import FileOptions from './FileOptions'
import { Tabs, Tooltip } from 'antd'
import DataGridTable from '../../../../Common/dataGridTable/DataGridTable'
import { getObjectLength, getReloadErrorTemplate, getSimpleTutorialMessageTemplate } from '../../../common/helperFunctions'
import { ICON_CALENDAR, ICON_CROSS_FOLDER, ICON_EYECLOSE, ICON_EYEOPEN, ICON_FILE, ICON_LOADING_CIRCLE, ICON_SQLFILE, ICON_TABLE, ICON_TXT_FILE } from '../../../../Common/newIconSource'
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import FileUploadContainer from './FileUploadContainer'
import SchemaTableContainer from './SchemaTableContainer'
import _, { cloneDeep, isArray, sortBy } from 'lodash'
import QueryEditorContainer from './QueryEditorContainer'
import { describeTable, saveSQlTrigger } from '../../../../../store/modules/dataCatalogue/preview/previewActions'
import Walkthrough from '../../../../Common/walkthrough/Walkthrough'
import { setQueryKillFlag } from '../../../../../store/modules/common/DataGridTable/dataGridTableRedux'
import { ACTIONS, EVENTS } from 'react-joyride'
import Buttons from '../../../../Common/button/Buttons'
import { Literals } from '../../../common/literals'
import Draggable from 'react-draggable'
import ClaristaCopilot from '../../../copilot'
import { LandingSimpleTableInner } from '../../../../Common/landingSimpleTable/LandingSimpleTable'
import ContainsPII from '../../../dataDictionary/components/ContainsPII'
import { v4 as uuidv4 } from "uuid";
import { fetchUnstructuredPreview } from '../../../../../store/actions'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import UnstructuredTable from './UnstructuredTable'
import UnstructuredAddTopics from './UnstructuredAddTopics'
import { X } from 'react-bootstrap-icons'
import StoreCacheOption from './StoreCacheOption'

const getDataGridTable = (data) => {
    return <DataGridTable
        resp={data}
        isSplitPane={true}
        isShowHeader={false}
        isPreviewDataAlreadyLoading={true}
        stopColumnReorder={true}
    />
}

const getTutorialMessageTemplate = (title = '', content = '') => {
    return getSimpleTutorialMessageTemplate(title, content)
}

const PodCreationSource = ({
    previewUUID = '',
    setPreviewUUID = () => {},
    isEditMode = false,
    isEditing = false,
    podRetrievedConnection = '',
    isFileUpload = false,
    isPodOfPod = false,
    fileUploadUUID = '',
    setFileUploadUUID = () => {},
    isDetailsFetched = false,
    getDetails = () => {},
    setPodPreviewRunning = () => {},
    setStoredStringPattern = () => {},
    tutorialStepsIdMap = {},
    podCreationTutorialComponentIds = {},
    isTutorialActive = false,
    isDataDictionary = false,
    isCustomQuery = false,
    setIsCustomQuery = () => {},
    getActiveTabKey = () => {},
    getEditorQuery = () => {},
    setEditorQuery = () => {}, // to handle for Non Custom Query on Next Button to save unsaved query to redux,
    editorQuery = '',
    currentActiveTabKey = '',
    setCurrentActiveTabKey = () => {},
    isApiCompletedRef = false,
    autoClassifyObj = {},
    setAutoClassifyObj = () => {},
    sqlReady = false,
    setSqlReady = () => {},
    stringPattern = '',
    storedStringPattern = '',
    setUploadInProgress = () => {},
    isUploadInProgress = false,
    sqlChat = [],
    setSqlChat = () => {},
    isUnstructured = false,
    setStringPattern = () => {}
}) => {

    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)

    const { tableData, schemaQueryMap, clarista_engine } = reduxData
    const { result: previewTableResult } = tableData

    const [sizes, setSizes] = React.useState(["50%", "50%"])
    const [previewLoading, setPreviewLoading] = React.useState(false)
    const [previewError, setPreviewError] = React.useState(undefined)
    const [fileUploadConnName, setFileUploadConnName] = React.useState(reduxData?.fileUploadConnection ?? '')
    const [tabItems, setTabItems] = React.useState([])
    const [activeTabKey, setActiveTabKey] = React.useState(currentActiveTabKey ?? 'result')
    const [query, setQuery] = React.useState('')
    const [staticQuery, setStaticQuery] = React.useState(
            reduxData?.schemaQueryMap?.result?.trim() === "" && !isEditMode
            ?
            `-- Write Your SQL Here\n\n`
            // `-- Write Your SQL Here\n\n\n\n-- Here are some examples`
            :   reduxData?.schemaQueryMap?.result ?? ''
        )
    // const [staticQuery, setStaticQuery] = React.useState(reduxData?.schemaQueryMap?.result ?? '')
    const [sqlTableDetails, setSqlTableDetails] = React.useState([])
    const [describeResponse, setDescribeResponse] = React.useState([])
    const [previewOverlayLoading, setPreviewOverlayLoading] = React.useState(false)
    const [isAnyTableSelected, setAnyTableSelected] = React.useState(false)
    const [showTableSelectInfo, setShowTableSelectInfo] = React.useState(false)
    const [isSqlNotExecuted, setSqlNotExecuted] = React.useState(true)
    const [loadingTablesKeys, setLoadingTablesKeys] = React.useState([])
    const [loadingTablesUUID, setLoadingTablesUUID] = React.useState({})
    // const [checkedFiles, setCheckedFiles] = React.useState([])
    // const [checkedFolders, setCheckedFolders] = React.useState([])
    const [checkedElKeys, setCheckedElKeys] = React.useState(
        (() => {
            if(isFileUpload && reduxData?.fileUploadFiles) {
                return [...reduxData?.fileUploadFiles]
            }
            else if(reduxData?.selectedFolder) {
                return [...reduxData?.selectedFolder]
            }
            return []
        })()
    )
    const [topicsData, setTopicsData] = React.useState(
        (() => {
            if(reduxData?.tableData?.result?.topic_info) {
                return [...reduxData?.tableData?.result?.topic_info]
            }
            return []
        })()
    )
    // console.log({schemaQueryMap, isCustomQuery, staticQuery})

    const selectedConn = useSelector((state) => {
        return state.UserManagement.ConnectionReducer.selectedConn
    })

    const walkthroughSteps = React.useMemo(() => {
    
        const isLeastStep = isPodOfPod || isFileUpload

        let tutorialSteps = [
            {
                content:  <>
                    {getTutorialMessageTemplate('Welcome, To The Explorer', "Let's start")}
                </>,
                locale: { skip: <strong>SKIP</strong> },
                placement: "center",
                target: "body",
                continuous: true
            },
            {
                content: <>
                {
                    getTutorialMessageTemplate(
                        isFileUpload ? 'Upload File(s)' : selectedConn?.file_system ? 'Select Folder' : 'Select Table(s)'
                    , 
                        isFileUpload ? 'Upload your files here in the drop box or, by clicking on "Add File Button"'
                            :
                            selectedConn?.file_system
                            ?   'Select any folder from the explorer tree, you can expand and check the file(s) in the folder'
                            :   'Expand the tree and select table(s)'
                    )}
                </>,
                placement: "right",
                target: tutorialStepsIdMap.step3,
                title: isLeastStep ? "Step 1" : "Step 3",
                hideFooter: true,
                disableBeacon: true
            },
            {
                content: <>
                    {getTutorialMessageTemplate(
                        'Preview',
                        "The preview of selected table(s) will be visible here, you can switch tabs if multiple preview's are present"
                    )}
                </>,
                placement: "top",
                target: tutorialStepsIdMap.step4,
                title: isLeastStep ? "Step 2" : "Step 4",
                schema: true
            },
            {
                content: <>
                    {getTutorialMessageTemplate('Result', 'After running query result will appear here')}
                </>,
                placement: "top",
                target: tutorialStepsIdMap.step5,
                title: isLeastStep ? "Step 3" : "Step 5",
                schema: true
            },
            {
                content: <>
                    {
                        getTutorialMessageTemplate(
                            'SQL Editor',
                            'Selected tables sql query will appear here, you can write your own query by switching to "Result Tab" or can edit this query by executing the query'
                            // 'Write your own custom query in sql editor, by switching the preview tab to "Result" or, you can directly click on "Run Button" if query is in the editor'
                        )
                    }
                </>,
                placement: "bottom",
                target: tutorialStepsIdMap.step6,
                title: isLeastStep ? "Step 4" : "Step 6",
                schema: true
            },
            {
                content: <>
                    {
                        getTutorialMessageTemplate('Executing Query',
                        'Now click on this "Run" Button to execute this query'
                        )   
                    }
                </>,
                placement: "bottom",
                target: tutorialStepsIdMap.step7,
                title: isLeastStep ? "Step 5" : "Step 7",
                schema: true,
                hideFooter: true,
                disableBeacon: true
            }
        ]

        if(selectedConn?.file_system || isFileUpload) {
            tutorialSteps = tutorialSteps?.filter(t => !t?.schema)
            tutorialSteps = [
                ...tutorialSteps,
                {
                    content: <>
                        {getTutorialMessageTemplate('File Options', 'Select the file format and additional settings for the folder you have selected')}
                    </>,
                    placement: "bottom",
                    target: tutorialStepsIdMap.step4,
                    title: isLeastStep ? "Step 2" : "Step 4"
                },
                {
                    content: <>
                        {getTutorialMessageTemplate('Preview', 'Click on "Preview" button for the result')}
                    </>,
                    placement: "bottom",
                    target: tutorialStepsIdMap.step5,
                    title: isLeastStep ? "Step 3" : "Step 5",
                    hideFooter: true,
                    disableBeacon: true
                },
                {
                    content: <>
                        {getTutorialMessageTemplate('Result', `The data of ${isFileUpload ? 'uploaded file(s)' : 'selected folder'} will be shown here`)}
                    </>,
                    placement: "top",
                    target: tutorialStepsIdMap.step6,
                    title: isLeastStep ? "Step 4" : "Step 6"
                }
            ]
        }

        tutorialSteps = [
            ...tutorialSteps,
            {
                content: <>
                    {
                        getTutorialMessageTemplate('Schema',
                        "Good Work, Let's move to the Schema "
                        )  
                    }
                </>,
                placement: "bottom",
                target: tutorialStepsIdMap.step2,
                title: "Step To",
                locale: { last: "Got it" }
            }
        ]
        
        return tutorialSteps
    }, [
        selectedConn, 
        isTutorialActive
    ]) 

    const [{run, steps, stepIndex}, setWalkThrough] = React.useState({
        run: false,
        // run: isFileUpload && !isEditMode,
        steps: [...walkthroughSteps],
        stepIndex: 0
    })

    const dispatch = useDispatch()
    const store = useStore()

    const previewHandlerRef = React.useRef()

    let isQueryKilled = store.getState().CommonComponent?.DataGridTable?.isQueryKill;

    const podData = useSelector((state) => state.DataCatalogue.Dictionary.dictionaryPodData)

    const [showEditor, setShowEditor] = React.useState(isEditMode && podData?.pod_custom_query_flag ? true : false)
    // const connName = selectedConn?.connection_name??''
    // const connType = selectedConn?.source_type??''

    const [activeDrags, setActiveDrags] = React.useState(0)
    const [highlightSchemaPane, setHighlightSchemaPane] = React.useState(false)

    const schemaTableRef = React.useRef({})
    
    React.useEffect(() => {
        if(isUnstructured) {
            setSizes(['0%', '100%'])
        }
        else if(!selectedConn?.file_system && !isFileUpload) {
            // if(isEditMode && podData?.pod_custom_query_flag) {
            //     setShowEditor(true)
            //     setSizes(['50%', '50%'])
            //     return
            // }
            setSizes(showEditor ? ['50%', '50%'] : ['0%', '100%'])
        }
    }, [showEditor, selectedConn, podData])

    React.useEffect(() => {
        if(isDataDictionary) {
            if(!selectedConn?.file_system) {
                setSizes(['0%', '100%'])
                const keys = Object.keys(reduxData?.tableData)
                setActiveTabKey(keys[1] ?? keys[0])
            }
        }
    }, [selectedConn])

    React.useEffect(() => {
        setActiveTabKey(currentActiveTabKey)
    }, [currentActiveTabKey])

    React.useEffect(() => {
        if(isFileUpload) return

        let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
        if(isEditMode) {
            reduxData.podDetails = {
                name: podData?.table_name,
                domain: podData?.data_domain_detail?.data_domain_name,
                description: podData?.pod_description
            }
        }
        reduxData.currentSelectedConnection = selectedConn?.connection_name??''
        dispatch(setPodCreationData(reduxData))
    }, [selectedConn])

    React.useEffect(() => {
        getActiveTabKey(activeTabKey)

        /**
         * Do not remove this code
         * For Tab Based Query In Editor use this code
         */
            //     if(getObjectLength(schemaQueryMap)) {
            //         setQuery(schemaQueryMap[activeTabKey] ?? '')
            //     }
        /**
         * End of above comment
         */
    }, [activeTabKey])

    React.useEffect(() => {
        /**
         * As far new implementation we are commenting "if condition" from below code
         */
        // if(activeTabKey === 'result') {
            let reduxData = store.getState()?.DataCatalogue?.PodCreationData
            reduxData.schemaQueryMap.result = query
            dispatch(setPodCreationData(reduxData))
        // }
        /**
         * End of above comment
         */
        generateSqlTableInfo()
    }, [
        query, 
        // activeTabKey
    ])

    React.useEffect(() => {
        getEditorQuery(staticQuery)
    }, [staticQuery])

    React.useEffect(() => {
        setTimeout(() => {
            setShowTableSelectInfo(false)
        }, 2000)
    }, [showTableSelectInfo])

    React.useEffect(() => {
        generateSqlTableInfo()

        let temp = []
        for (const key in tableData) {
            temp.push(
                {
                    key,
                    label: key,
                    /**
                     * For Remove Button Use Below Code
                     */
                    /* label: <>
                        {key}
                        <span className='ml-1 pod-crt-tab-close-layer'></span>
                        <span className='ml-1 pod-crt-tab-close-btn'>
                            <X width={'12px'} height={'12px'} />
                        </span>
                    </> */
                }
            )
        }

        if(!isCustomQuery) {
            temp = temp?.filter(t => t?.key !== 'result')
        }

        setTabItems([...temp])
    }, [reduxData, isCustomQuery])
    
    React.useEffect(() => {
        if(isTutorialActive && stepIndex === 1) {
            const isAnySelected = checkIfAnySelected()
            if(isAnySelected) {
                setTimeout(() => {
                    setWalkThrough(prev => ({...prev, stepIndex: stepIndex + 1}))
                }, 1000)
            }
        }
    }, [reduxData, isTutorialActive, stepIndex])

    React.useEffect(() => {
        if(getObjectLength(reduxData?.tableData?.result) === 0) {
            setSqlNotExecuted(false)
        }
        else {
            let patternUnMatched = false
            if(storedStringPattern) {
                patternUnMatched = stringPattern !== storedStringPattern
            }
            setSqlNotExecuted(patternUnMatched)
        }
    }, [reduxData, stringPattern, storedStringPattern])

    React.useEffect(() => {
        if(
            isEditMode 
            && !isFileUpload 
            && podData?.table_id
        ) {

            (async() => {
                
                let isPoP = podData?.source_type === Literals.POD_OF_POD_API

                if(
                    isPoP && !isPodOfPod
                ) {
                    return
                }
                else if(
                    !isPoP && !isPodOfPod
                    &&
                    podData?.connection !== selectedConn?.connection_name
                ) {
                    return
                }
                else if(
                    isPodOfPod
                    && 
                    podData?.connection !== Literals.POD_OF_POD_API
                ) {
                    return
                }
                else if(
                    !isArray(selectedConn) && selectedConn?.file_system
                ) {
                    return
                }

                let list = podData?.source
                if(!isPoP) {
                    list = list?.map(v => v.split('.').slice(1).join('.'))
                }
                let columnLists = []
                let tableLists = []
                for (let i = 0; i < list.length; i++) {
                    let [ schema_name, table_name ] = list[i]?.split('.')
    
                    
                    tableLists = [...tableLists, list[i]]
    
                    let res = await getTablesDescription({schema_name, table_name})
                    columnLists = [...columnLists, ...res]
                    setDescribeResponse([...columnLists])
                }
                tableLists = tableLists?.map(key => {
                        return(
                            {
                                name: isPoP || !clarista_engine ? `${key?.split('.')?.map(k => `"${k}"`)?.join(".")}` : `"${selectedConn?.connection_name}".${key?.split('.')?.map(k => `"${k}"`)?.join(".")}`,
                                description: "",
                                type: "Table",
                                tableName: "",
                                key
                            }
                        )
                    }
                )
                columnLists = _.uniqBy(columnLists, "name")
                setSqlTableDetails([...columnLists, ...tableLists])
            })()
        }
    }, [isEditMode, podData])

    React.useEffect(() => {
        setStringPattern(() => {
            let a = "|" + sortBy(
                isFileUpload 
                ? checkedElKeys?.map(f => f?.path)
                : checkedElKeys
            )?.join('|')
            let b = ''
            if(topicsData?.length) {
                b = "|" + topicsData?.map(t => `t-${t?.topic_name}`)?.join('|')
            }
            return `${a}${b}`
        })
    }, [checkedElKeys, topicsData])

    // React.useEffect(() => {
    //     setStringPattern(prev => {
    //         prev += "|" + sortBy(checkedFiles, [s => s?.path])?.map(f => f?.path)?.join('|')
    //         return prev
    //     })
    // }, [checkedFiles])

    const getTablesDescription = async({schema_name, table_name}) => {
        let payload = {
            connection_name: isPodOfPod ? 'hive' : selectedConn?.connection_name,
            schema_name,
            table_name
        }
        try {
            const res = await dispatch(describeTable(payload, false))
            
            let data = res?.data
            let cols = []
            if(data) {
                cols = data?.data_record?.map(c => c[0])?.map(c => ({
                    name: `"${c}"`,
                    description: "",
                    type: "Column",
                    tableName: table_name,
                    key: `${schema_name}.${table_name}`,
                    columnName: c
                }))
            }
            return cols
        }
        catch {
            return []
        }
    }

    const checkIfAnySelected = () => {
        if(isFileUpload) {
            return reduxData?.fileUploadFiles?.length > 0 
            && reduxData?.fileUploadFilesStatus?.[reduxData?.fileUploadFiles?.[0]?.name]?.isUploaded
        }
        else if(selectedConn?.file_system) {
            return reduxData?.selectedFolder?.length > 0
        }
        else {
            return reduxData?.schemaListData?.some(s => s?.isChecked)
        }
    }

    const walkthroughCallback = ({action, index, type, ...rest}) => {
        if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
            setWalkThrough(prev => ({...prev, stepIndex: nextStepIndex}))
        }
    }

    const generateSqlTableInfo = () => {
        let tableDataObj = {...tableData}
        delete tableDataObj.result
        if (getObjectLength(tableDataObj)) {

            let columns = [], tables = [], infoArray = []
            for (const key in tableDataObj) {

                let condition = key !== 'result' && tableDataObj[key].data_header

                if(isEditMode) {
                    let alreadyPresentTable = sqlTableDetails?.find(s => s?.key === key)?.key ?? ''
                    condition = key !== 'result' && key !== alreadyPresentTable && tableDataObj[key].data_header
                }

                if(condition) {
                    const tableName = key?.split('.')?.[1] 
                    let temp = [...tableData[key]?.data_header]
                    temp = temp.map(c => ({
                                name: `"${c}"`,
                                description: "",
                                type: "Column",
                                tableName,
                                key,
                                columnName: c
                            }))
                    tables.push({
                        name: isPodOfPod || !clarista_engine ? `${key?.split('.')?.map(k => `"${k}"`)?.join(".")}` : `"${selectedConn?.connection_name}".${key?.split('.')?.map(k => `"${k}"`)?.join(".")}`,
                        description: "",
                        type: "Table",
                        tableName: "",
                        key
                    })
                    columns.push(temp)
                }
            }
            columns = _.uniqBy(columns.flat(), 'name')
            infoArray = [...columns, ...tables]   
            if(isEditMode) {
                setSqlTableDetails(prev => {
                    let tableKeys = Object.keys(tableDataObj)?.filter(t => t !== 'result')
                    prev = prev?.filter(t => tableKeys?.includes(t?.key))
                    return _.uniqBy([...prev, ...infoArray], 'name')
                });
            }
            else {
                setSqlTableDetails([...infoArray]);
            }
        }
    };

    // const onBlurSqlEvent = (res_query) => {
    //     console.log('blur ', res_query)
    //     let reduxData = store.getState()?.DataCatalogue?.PodCreationData
    //     reduxData.schemaQueryMap.result = res_query
    //     dispatch(setPodCreationData(reduxData))
    // }

    const fileSystemTabItem = [
        {
            key: 0,
            label: `Preview`,
            children: <>
                {
                    isUnstructured
                    ?
                    <>
                        {
                            previewLoading
                            ?
                            <ClaristaLoader />
                            :
                            previewError
                            ?
                            getReloadErrorTemplate({
                                errorMessage: previewError,
                                onReload: () => handleUnstructuredPreview(),
                                hideReloadBtn: true
                            })
                            :
                            !getObjectLength(previewTableResult)
                            ?
                            getInfoGraphic(isFileUpload ? 'file_upload' : 'file_system', isDataDictionary, isUnstructured)
                            :
                            <UnstructuredTable
                                key={`uns-table-${getObjectLength(previewTableResult?.data_header)}`}
                                response={previewTableResult}
                                setTopicsData={setTopicsData}
                                topicsData={topicsData}
                                sourceType={reduxData?.fileUploadSource}
                            />
                        }
                    </>
                    :
                    <DataGridTable
                        resp={previewTableResult}
                        isSplitPane={true}
                        isShowHeader={false}
                        isPreviewDataAlreadyLoading={true}
                        stopColumnReorder={true}
                    />
                }
                
            </>,
        },
    ]
    
    const handleUnstructuredPreview = (autoLoading = false, fileConnName) => {

        let files = checkedElKeys;

        if(isFileUpload) {
            files = checkedElKeys?.map(f => f?.path)
        }

        const chkKeys = autoLoading 
                        ?   isFileUpload 
                            ?   podData?.pod_file_info?.file_names?.map(f => _.last(f?.split('/')?.filter(v => v !== ""))) 
                            :   podData?.pod_file_info?.file_names
                        :   files
        
        const topicsPayload = autoLoading ? podData?.topic_info : topicsData
        
        if(chkKeys?.length === 0) {
            emitToastNotification("info", "Choose Folder(s) or File(s) To Preview")
            return
        }

        setPreviewLoading(true)
        setPodPreviewRunning(true)
        setPreviewError(undefined)
        let requestBody = {};
        requestBody = {
            connection_name: isFileUpload 
            ? fileConnName ?? fileUploadConnName 
            : selectedConn?.connection_name ?? '',
            prefix: '',
            file_properties: {
                format: "unstructured",
                file_names: isFileUpload
                            ?   [fileUploadUUID]
                            // ?   [...chkKeys?.map(v => fileUploadUUID + "/" + v)]
                            :   [...chkKeys]
            },
            topic_info: [
                ...topicsPayload,
            ]
        };

        // let uuid = uuidv4()
        // /**To Handle Cancel Preview**/
        // dispatch(setQueryKillFlag(false))
        // dispatch(setTableUUID(uuid))
        dispatch(fetchUnstructuredPreview(requestBody, '', false, false))
        .then(res => {
            const data = res?.data

            /***********To Store Schema Unsaved Changes***********/
            let uuid = uuidv4();
            setPreviewUUID(uuid)
            /***********To Store Schema Unsaved Changes***********/

            setTopicsData([...data?.topic_info])

            let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
            reduxData['output_regex'] = data?.output_regex??[]
            reduxData.tableData.result = {...data}
            reduxData['fileSystemPayload'] = requestBody
            setReduxData(reduxData)
            setPreviewLoading(false)
            setPodPreviewRunning(false)
            setStoredStringPattern(() => {
                let a = "|" + sortBy(chkKeys)?.join('|')
                let b = ""
                
                if(data?.topic_info?.length) {
                    b = "|" + data?.topic_info?.map(t => `t-${t?.topic_name}`)?.join('|')
                }
                
                return `${a}${b}`
            })
        })
        .catch(err => {
            let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
            reduxData['output_regex'] = []
            reduxData.tableData.result = {}
            setReduxData(reduxData)
            setPreviewError(err?.message)
            setPreviewLoading(false)
            setPodPreviewRunning(false)
        })
    
    }

    const onSaveTopic = (newData) => {
        let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
        let previewResult = cloneDeep(reduxData?.tableData?.result)

        setTopicsData(prev => (
            [
                ...prev,
                {
                    ...newData,
                    possible_values: newData?.possible_values?.split(',')?.map(v => v?.trim())
                }
            ]
        ))
        
        previewResult['data_header'] = [...previewResult?.data_header, newData?.topic_name]
        previewResult['data_dtype'] = [...previewResult?.data_dtype, 'varchar']
        previewResult['data_record'] = previewResult['data_record']?.map(r => [...r, ""])
        reduxData['tableData']['result'] = {...previewResult}
        setReduxData(reduxData)
    }

    const setReduxData = (data) => {
        dispatch(setPodCreationData(data))
    }

    const showResultTable = (key) => {
        if(tableData[key] && getObjectLength(tableData[key])) {
            if(key === 'result') {
                if(previewLoading) return <ClaristaLoader height='270px' isCancel={true} showErrorToast={true} />
                if(previewError) return schemaResultErrorTemplate()
            }
            return getDataGridTable(tableData[key])
        }
        else {
            if(key === 'result') {
                if(loadingTablesKeys?.includes('result') && !previewLoading) {
                    return <ClaristaLoader height='270px' isCancel={true} showErrorToast={true} />
                }
                if(previewLoading) return <ClaristaLoader height='270px' isCancel={true} showErrorToast={true} />
                if(previewError) return schemaResultErrorTemplate()
            }
            else if(loadingTablesKeys?.includes(key)) {
                return <ClaristaLoader 
                    height='270px' 
                    isCancel={true} 
                    showErrorToast={true} 
                    uuid={loadingTablesUUID[key]}
                />
            }
            return getInfoGraphic('', isDataDictionary)
        }
    }

    const schemaResultErrorTemplate = () => {
        return <div className='pt-5'>
                    {
                        getReloadErrorTemplate({
                            errorMessage: previewError, 
                            onReload: () => {
                                previewHandlerRef.current.executeQuery()
                            }
                        })
                    }
                </div>
    }

    const onlySingleTab = selectedConn?.file_system || isFileUpload

    const onTabChange = (key) => {
        dispatch(setQueryKillFlag(false))

        // setStaticQuery(schemaQueryMap[key] ?? '')
        setActiveTabKey(key)
        setCurrentActiveTabKey(key)
        if(
            isEditMode 
            && key !== 'result' 
            && getObjectLength(reduxData?.tableData[key]) === 0 
            && !loadingTablesKeys?.includes(key)
        ) {
            schemaTableRef?.current?.previewTableData(key)
        }
    }

    const onStart = () => {
        setActiveDrags(prev => ++prev)
    }

    const onStop = () => {
        setActiveDrags(prev => --prev)
    }

    const showCopilot = React.useMemo(() => {

        if(isEditMode) {
            return isEditing && !isFileUpload && !selectedConn?.file_system
        }
        return !isFileUpload && !selectedConn?.file_system

    }, [isFileUpload, selectedConn, isEditMode, isEditing])
    
    const previewButtonVisibility = React.useMemo(() => {
        
        return (isEditMode && !isEditing) 
            || checkedElKeys?.length === 0 
            || previewLoading
        // return (isEditMode && !isEditing) 
        //     || checkedFolders?.length === 0 
        //     || previewLoading || checkedFiles?.length === 0
    }, [
        isEditMode,
        isEditing,
        reduxData?.selectedFolder,
        previewLoading,
        checkedElKeys
        // checkedFiles,
        // checkedFolders
    ])

    const columnMap = React.useMemo(() => {
        const obj = describeResponse?.reduce((prev, curr) => {
            let cols = prev[curr?.key] ?? []
            return (
                {
                    ...prev, 
                    [curr?.key]: [...cols, curr?.columnName]
                })
        }, {})
        
        return obj ?? {}
    }, [describeResponse])

    return (
        <div style={{height: '100%'}}>
            <div className='pod-creation-source-grid h-100'>
                <div>
                    {
                        isFileUpload
                        ?   <FileUploadContainer
                                uniqueUUID={fileUploadUUID}
                                setUniqueUUID={setFileUploadUUID}
                                fileUploadConnName={fileUploadConnName}
                                setFileUploadConnName={setFileUploadConnName}
                                isEditMode={isEditMode}
                                isEditing={isEditing}
                                previewHandlerRef={previewHandlerRef}
                                getDetails={getDetails}
                                podCreationTutorialComponentIds={podCreationTutorialComponentIds}
                                setWalkThrough={setWalkThrough}
                                isTutorialActive={isTutorialActive}
                                setUploadInProgress={setUploadInProgress}
                                isUploadInProgress={isUploadInProgress}
                                previewError={previewError}
                                isUnstructured={isUnstructured}
                                setFilesList={setCheckedElKeys}
                                handleUnstructuredPreview={handleUnstructuredPreview}
                                setPreviewError={setPreviewError}
                                setTopicsData={setTopicsData}
                            />
                        :   <>
                                <SmallConnDetails isPodOfPod={isPodOfPod} />
                                <div className={`pod-crt-either-exp-sch ${highlightSchemaPane ? 'cpilot-highlight' : ''}`}>
                                    {
                                        selectedConn?.file_system
                                        ?    <ExplorerDirectoryTree
                                                isEditMode={isEditMode}
                                                previewLoading={previewLoading}
                                                podData={podData}
                                                isEditing={isEditing}
                                                podRetrievedConnection={podRetrievedConnection}
                                                previewHandlerRef={previewHandlerRef}
                                                podCreationTutorialComponentIds={podCreationTutorialComponentIds}
                                                setWalkThrough={setWalkThrough}
                                                isTutorialActive={isTutorialActive}
                                                isUnstructured={isUnstructured}
                                                checkedElementKeys={checkedElKeys}
                                                setCheckedElementKeys={setCheckedElKeys}
                                                handleUnstructuredPreview={handleUnstructuredPreview}
                                                setTopicsData={setTopicsData}
                                                // setCheckedFiles={setCheckedFiles}
                                                // checkedFiles={checkedFiles}
                                                // checkedFolders={checkedFolders}
                                                // setCheckedFolders={setCheckedFolders}
                                            />
                                        :  <SchemaTableContainer
                                                isEditMode={isEditMode}
                                                setActiveTabKey={setActiveTabKey}
                                                isPodOfPod={isPodOfPod}
                                                isReadOnly={isEditMode && !isEditing}
                                                setPreviewLoading={setPreviewLoading}
                                                setPreviewError={setPreviewError}
                                                setPreviewUUID={setPreviewUUID}
                                                setQuery={setQuery}
                                                getDetails={getDetails}
                                                setPreviewOverlayLoading={setPreviewOverlayLoading}
                                                setAnyTableSelected={setAnyTableSelected}
                                                isAnyTableSelected={isAnyTableSelected}
                                                showTableSelectInfo={showTableSelectInfo}
                                                setPodPreviewRunning={setPodPreviewRunning}
                                                setStoredStringPattern={setStoredStringPattern}
                                                setQueryToDisplay={setStaticQuery}
                                                setWalkThrough={setWalkThrough}
                                                isTutorialActive={isTutorialActive}
                                                isDataDictionary={isDataDictionary}
                                                editorQuery={editorQuery}
                                                setCurrentActiveTabKey={setCurrentActiveTabKey}
                                                isCustomQuery={isCustomQuery}
                                                isApiCompletedRef={isApiCompletedRef}
                                                autoClassifyObj={autoClassifyObj}
                                                setAutoClassifyObj={setAutoClassifyObj}
                                                isSqlNotExecuted={isSqlNotExecuted}
                                                setIsCustomQuery={setIsCustomQuery}
                                                setLoadingTablesKeys={setLoadingTablesKeys}
                                                setLoadingTablesUUID={setLoadingTablesUUID}
                                                ref={schemaTableRef}
                                            />
                                    }
                                </div>
                            </>
                    }
                </div>
                <div>
                    <SplitPane
                        split='horizontal'
                        sizes={sizes}
                        onChange={setSizes}
                        allowResize={
                            isDataDictionary ? selectedConn?.file_system : showEditor
                        }
                    >
                        <div style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'start',
                                background: '#FFF'
                            }}>
                                {
                                    isUnstructured ? ''
                                    :
                                    selectedConn?.file_system || isFileUpload
                                    ?   isEditMode && !isDetailsFetched ? <ClaristaLoader/>
                                        :
                                        <FileOptions 
                                            isEditMode={isEditMode}
                                            previewLoading={previewLoading}
                                            setPreviewLoading={setPreviewLoading}
                                            setPreviewError={setPreviewError}
                                            setPreviewUUID={setPreviewUUID}
                                            ref={previewHandlerRef}
                                            previewUUID={previewUUID}
                                            isEditing={isEditing}
                                            podRetrievedConnection={podRetrievedConnection}
                                            isFileUpload={isFileUpload}
                                            fileUploadConnName={fileUploadConnName}
                                            fileUploadPath={fileUploadUUID}
                                            setPodPreviewRunning={setPodPreviewRunning}
                                            podCreationTutorialComponentIds={podCreationTutorialComponentIds}
                                            setWalkThrough={setWalkThrough}
                                            walkthroughStepIndex={stepIndex}
                                            autoClassifyObj={autoClassifyObj}
                                            setAutoClassifyObj={setAutoClassifyObj}
                                            previewError={previewError}
                                            isUnstructured={isUnstructured}
                                        />
                                    :   isDataDictionary ? ''
                                        :
                                        <QueryEditorContainer
                                            setQuery={setQuery}
                                            query={query}
                                            isReadOnly={loadingTablesKeys?.includes('result')}
                                            // isReadOnly={activeTabKey !== 'result'}
                                            sqlTableDetails={sqlTableDetails}
                                            ref={previewHandlerRef}
                                            isEditMode={isEditMode}
                                            previewLoading={previewLoading}
                                            setPreviewLoading={setPreviewLoading}
                                            setPreviewError={setPreviewError}
                                            setPreviewUUID={setPreviewUUID}
                                            setActiveTabKey={setActiveTabKey}
                                            isPodOfPod={isPodOfPod}
                                            isEditing={isEditMode ? isEditing : true}
                                            isAnyTableSelected={isAnyTableSelected}
                                            showTableSelectInfo={showTableSelectInfo}
                                            setShowTableSelectInfo={setShowTableSelectInfo}
                                            setPodPreviewRunning={setPodPreviewRunning}
                                            setStoredStringPattern={setStoredStringPattern}
                                            queryToDisplay={staticQuery}
                                            podCreationTutorialComponentIds={podCreationTutorialComponentIds}
                                            setWalkThrough={setWalkThrough}
                                            walkthroughStepIndex={stepIndex}
                                            setIsCustomQuery={setIsCustomQuery}
                                            isCustomQuery={isCustomQuery}
                                            setEditorQuery={setEditorQuery}
                                            setStaticQuery={setStaticQuery}
                                            autoClassifyObj={autoClassifyObj}
                                            setAutoClassifyObj={setAutoClassifyObj}
                                            setCurrentActiveTabKey={setCurrentActiveTabKey}
                                            // onBlurSqlEvent={onBlurSqlEvent}
                                            sqlReady={sqlReady}
                                            setSqlReady={setSqlReady}
                                            setLoadingTablesKeys={setLoadingTablesKeys}
                                        />
                                }
                        </div>
                        <div style={{ ...layoutCSS }}>
                            <div id={podCreationTutorialComponentIds?.previewTabsId} className={`position-relative w-100 h-100 pod-crt-prev ${isEditMode ? 'src-view' : ''} custom-virtual-data-table`}>
                                {
                                    previewOverlayLoading ? <div className='d-block position-absolute h-100 w-100' 
                                        style={{top: 0, left: 0, zIndex: 2, background: '#fff'}}>
                                            <ClaristaLoader/>
                                    </div>
                                    :   ''
                                }
                                {
                                    /*** Code Works For Schema Based Table View ***/
                                    !onlySingleTab ? 
                                        tabItems?.length === 0
                                        ?
                                        // <LandingSimpleTableInner
                                        //     cols={unstructCols}
                                        //     rowsData={demoData()}
                                        //     tableHeight={'100%'}
                                        // />
                                        getInfoGraphic('')
                                        :
                                        <div className='sch-based-tab-wrap'>
                                            <Tabs 
                                                id={'pcrt-restab'}
                                                className={`new-custom-tabs
                                                 ${getObjectLength(tableData?.[activeTabKey] ?? {}) === 0 ? 'handle-tab-height' : ''}
                                                 ${!isCustomQuery ? 'non-custom-qry' : ''}
                                                 `}
                                                defaultActiveKey="1" 
                                                items={tabItems?.map(item => (
                                                        {
                                                            ...item,
                                                            label: item?.key === 'result' 
                                                                    ? isDataDictionary ? 'Preview' : 'Result' 
                                                                    : item?.label, 
                                                            children: showResultTable(item?.key)
                                                        }
                                                    ))?.filter(c => {
                                                        if(isDataDictionary) {
                                                            if(tabItems?.length > 1) {
                                                                return c?.key !== 'result'
                                                            }
                                                            return c?.key
                                                        }
                                                        return c?.key
                                                    })
                                                } 
                                                activeKey={activeTabKey}
                                                onChange={onTabChange}
                                            />
                                            {
                                                isDataDictionary ? ''
                                                :
                                                <span className='sql-tgl-btn'>
                                                    <Buttons
                                                        props={{
                                                            buttonText: showEditor ? "Hide SQL" : "Show SQL",
                                                            buttonClassName: `custom-btn-outline custom-btn btn-with-text border ${showEditor ? 'active' : ''}`,
                                                            buttonEvent: () => {
                                                                // console.log({isAnyTableSelected, showTableSelectInfo})
                                                                /* if(!isAnyTableSelected) {
                                                                    if(showTableSelectInfo) return
                                                                    setShowTableSelectInfo(true)

                                                                    return
                                                                } */

                                                                setShowEditor(!showEditor)
                                                            },
                                                            ImgSrc: () => showEditor ? <ICON_EYECLOSE/> : <ICON_EYEOPEN/>,
                                                            isDisable: false,
                                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                        }}
                                                    />
                                                </span>
                                            }
                                        </div>
                                    :
                                    /*** Code Works For File Upload & File System Explorer ***/
                                    !isUnstructured && previewLoading ? <ClaristaLoader/>
                                    :  
                                    getObjectLength(previewTableResult) > 0 || (isUnstructured)
                                    ?   <Tabs 
                                            id={'pcrt-restab'}
                                            className='new-custom-tabs'
                                            defaultActiveKey="1" 
                                            items={fileSystemTabItem} 
                                            onChange={onTabChange}
                                            tabBarExtraContent={
                                                !isUnstructured ? ''
                                                :
                                                <>
                                                    <div className='d-flex align-items-center'>
                                                        <StoreCacheOption
                                                            isEditMode={isEditMode}
                                                            isEditing={isEditing}
                                                        />

                                                        <div className='vertical-separator' style={{height: 20}} ></div>
                                                        {
                                                            getObjectLength(previewTableResult) > 0
                                                            ?
                                                            <UnstructuredAddTopics
                                                                onSave={onSaveTopic}
                                                            />
                                                            :   ''
                                                        }
                                                        <Buttons
                                                            props={{
                                                                buttonText: previewLoading ? "Previewing..." : "Preview",
                                                                buttonClassName: "custom-btn-primary custom-btn btn-with-text ml-2",
                                                                buttonEvent: () => {
                                                                    handleUnstructuredPreview()
                                                                },
                                                                ImgSrc: () => previewLoading ? <ICON_LOADING_CIRCLE/> : <ICON_EYEOPEN />,
                                                                isDisable: previewButtonVisibility,
                                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        />
                                    :   
                                        previewError
                                        ?   <div className='pt-5'>
                                            {
                                                getReloadErrorTemplate({
                                                    errorMessage: previewError, 
                                                    onReload: () => {
                                                        previewHandlerRef.current.executePreview()
                                                    }
                                                })
                                            }
                                            </div>
                                        :   getInfoGraphic(isFileUpload ? 'file_upload' : selectedConn?.file_system ? 'file_system' : '', isDataDictionary)
                                }
                            </div>
                        </div>

                    </SplitPane>
                </div>
            </div>

            
            {
                !showCopilot ? ''
                :
                <Draggable 
                    onStart={onStart}
                    onStop={onStop}
                    handle='.c-cpilot-sec-wrap, .cpilot-pop-head'
                    axis='x'
                    bounds={{top: 0, left: -(document.querySelector('.pod-crt-parent')?.clientWidth ? (document.querySelector('.pod-crt-parent')?.clientWidth - 400) : 700), right: 0, bottom: 0}}
                >
                    <div style={{zIndex: 12, position: 'relative'}}>
                        <ClaristaCopilot
                            type="sql"
                            extraProps={{
                                setHighlightSchemaPane,
                                isPodOfPod,
                                sqlChat,
                                setSqlChat,
                                columnMap
                            }}
                        />
                    </div>
                </Draggable>
            }


            <Walkthrough
                walkthrough={{run, steps, stepIndex}}
                stepIndex={stepIndex}
                spotlightClicks={true}
                showSkipButton={false}
                disableOverlayClose={true}
                callback={walkthroughCallback}
            />
        </div>
    )
}

export default PodCreationSource

const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    background: '#FFF'
};

const demoData = () => {
    let obj = {
        document_name: 'Marketing_Strategy_2024.ppt',
        effective_date: 'October 10, 2021',
        customer_termination: 'Termination for Convenience or for...',
        pricing_mechanism: 'DWS RREEF Real Assets A',
        customer_contact: 'marketing',
        pii: true
    }

    let arr = [...Array(40)]?.map((v, i) => ({
        ...obj,
        document_name: <div>
            <span className='rounded px-2 py-1'
                style={{background: '#EAEDEF'}}
            >
                <span className='mr-1 position-relative' style={{top: -1}}>
                    <ICON_FILE height='12' width='12' /> 
                </span>
                {obj?.document_name}
            </span>
        </div>,
        customer_contact: <div>
            <span className='rounded px-2 py-1 fontInterSemiBold'
                style={{background: i % 2 === 0 ? '#FBD8B1' : '#E8DAF9', color: "#00000098"}}
            >
                {i % 2 === 0 ? 'sales' : 'marketing'}
            </span>
        </div>
        ,
        pii: <div className='d-flex h-100 align-items-center'>
            <ContainsPII/>
        </div>
    }))

    return arr
}

const unstructCols = [
    {
        name: <div className='fontSizeHeading fontInterSemiBold'>Document Name</div>,
        key: 'document_name',
        resizeable: false,
        sortable: false
    },
    {
        name: <div className='fontSizeHeading fontInterSemiBold'>Effective Date</div>,
        key: 'effective_date',
        resizeable: false,
        sortable: false
    },
    {
        name: <div className='fontSizeHeading fontInterSemiBold'>Customer Termination</div>,
        key: 'customer_termination',
        resizeable: false,
        sortable: false
    },
    {
        name: <div className='fontSizeHeading fontInterSemiBold'>Pricing Mechanism</div>,
        key: 'pricing_mechanism',
        resizeable: false,
        sortable: false
    },
    {
        name: <div className='fontSizeHeading fontInterSemiBold'>Customer Contact</div>,
        key: 'customer_contact',
        resizeable: false,
        sortable: false
    },
    {
        name: <div className='fontSizeHeading fontInterSemiBold'>PII</div>,
        key: 'pii',
        resizeable: false,
        sortable: false
    }
]

const getInfoGraphic = (type, isDataDictionary = false, isUnstructured = false) => {
    return <div className='py-5 my-5'>
        <div className='text-center mb-2 pod-crt-info-icon'>
            {
                type === ''
                ?   <ICON_TABLE height='50' width='50' color='#828282' />
                :   <ICON_CROSS_FOLDER height='80' width='80' color='#828282' />
            }
        </div>
        <div className='w-50 mx-auto text-center'>
            <p className='fontSizeHeading text-dark' style={{fontFamily: 'InterRegular'}}>
                {
                    type === 'file_system'
                    ?   isUnstructured
                        ?
                        <>Select folder, choose files <br/>
                        click on the <strong>Preview</strong> button to see the results.</>
                        :
                        <>Select tables, choose the file format and <br/>
                        click on the <strong>Preview</strong> button to see the results.</>
                    :   type === 'file_upload'
                        ?   <>Upload files, choose the file format and <br/>
                            click on the <strong>Preview</strong> button to see the results.</>
                        :   isDataDictionary
                            ?   <>Select table(s) from schema list and click next button to move towards Schema Table</>
                            :   <>Select table(s) from schema list and run sql query for results</>
                }
            </p>
        </div>
    </div>
}