import { cloneDeep } from "lodash";
import {
  SET_LANDINGPAGE_DATA,
  SET_LANDINGPAGE_FILTER,
  SET_TAG_LIST,
  SET_LANDINGPAGE_TAG,
  CREATE_FOLDER,
  OPEN_MODEL_LANDING,
  SET_SELECTED_POD_LIST,
  SET_LANDING_PAGE_DEFAULT,
  IS_TAG_LIST,
  DATA_DOMAIN_LIST,
  DOMAIN_GROUP_DATA
  
} from "./landingPageActionTypes";

const initialState = {
  landingPageData: [],
  filter: [],
  tagList: [],
  tags: [],
  isTagList: false,
  openModel: "",
  selectedRulePODList: [],
  createFilePayload: null,
  landingPageDomainList:[],
  landingPageGroupData:[]
};

const LandingPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LANDINGPAGE_DATA:
      return {
        ...state,
        landingPageData: [...payload],
      };
    case SET_LANDINGPAGE_FILTER:
      return {
        ...state,
        filter: [...payload],
      };
    case SET_LANDINGPAGE_TAG:
      return {
        ...state,
        tags: [...payload],
      };
    case DOMAIN_GROUP_DATA:
      return {
        ...state,
        landingPageGroupData: [...payload],
      };
    case DATA_DOMAIN_LIST:
      return {
        ...state,
        landingPageDomainList: [...payload],
      };
    case SET_TAG_LIST:
      return {
        ...state,
        tagList: [ ...payload],
      };
    case SET_SELECTED_POD_LIST:
      return {
        ...state,
        selectedRulePODList: payload,
      };
    case OPEN_MODEL_LANDING:
      return {
        ...state,
        openModel: payload,
      };
    case SET_LANDING_PAGE_DEFAULT:
      return initialState;
    case IS_TAG_LIST:
      return {
        ...state,
        isTagList: payload,
      };
    case CREATE_FOLDER:
      return {
        ...state,
        createFilePayload: payload,
      };
    default:
      return state;
  }
};

export default LandingPageReducer;
