import { combineReducers } from "redux";
import Reducer from './appReducer';


const appReducer = combineReducers({
   Filters: Reducer
});



export default appReducer