import React from 'react'
import { columnNameRegex, getObjectLength, toTitleCase } from '../../../common/helperFunctions'
import { Input, Popover, Tooltip } from 'antd'
import { LandingSimpleTableInner } from '../../../../Common/landingSimpleTable/LandingSimpleTable'
import { ICON_DELETE, ICON_DOWNLOAD, ICON_EDITBOX, ICON_EYEOPEN, ICON_FILE, ICON_FILE_CSV, ICON_FILE_DOC, ICON_FILE_JPG, ICON_FILE_PDF, ICON_FILE_PPT, ICON_FILE_XLS, ICON_LIST, ICON_SAVE } from '../../../../Common/newIconSource'
import UnstructuredFileName from './UnstructuredFileName'
import { useDispatch, useSelector } from 'react-redux'
import Buttons from '../../../../Common/button/Buttons'
import { Literals } from '../../../common/literals'
import _, { cloneDeep } from 'lodash'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'

const FILE_ICON = {
    doc: <ICON_FILE_DOC width='14' height='14' color={'currentColor'}/>,
    pdf: <ICON_FILE_PDF width='14' height='14' color={'currentColor'}/>,
    csv: <ICON_FILE_CSV width='14' height='14' color={'currentColor'}/>,
    ppt: <ICON_FILE_PPT width='14' height='14' color={'currentColor'}/>,
    jpg: <ICON_FILE_JPG width='14' height='14' color={'currentColor'}/>,
    xls: <ICON_FILE_XLS width='14' height='14' color={'currentColor'}/>,
}
// const getFileIcon = {
//     doc: (props) => <ICON_FILE_DOC {...props}/>,
//     pdf: (props) => <ICON_FILE_PDF {...props}/>,
//     csv: (props) => <ICON_FILE_CSV {...props}/>,
//     ppt: (props) => <ICON_FILE_PPT {...props}/>,
//     jpg: (props) => <ICON_FILE_JPG {...props}/>,
//     xls: (props) => <ICON_FILE_XLS {...props}/>,
// }

const UnstructuredTable = ({
    response = {},
    key = 'unstructuredTable',
    topicsData = [],
    setTopicsData = () => {},
    sourceType
}) => {

    const dispatch = useDispatch()

    const [columns, setColumns] = React.useState([])
    const [rows, setRows] = React.useState([])
    const [delOpenIndex, setDelOpenIndex] = React.useState({})
    const [editOpenIndex, setEditOpenIndex] = React.useState({})
    const [currentTopic, setCurrentTopic] = React.useState({topic_name: '', description: '', possible_values: ''})

    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)

    const selectedConn = useSelector((state) => {
        return state.UserManagement.ConnectionReducer.selectedConn
    })

    React.useEffect(() => {
        if(getObjectLength(response) > 0) {
            const cols = response?.data_header?.map((header, index) => (
                {
                    name: header,
                    // name: headerTemplate(header, `${header}|${index}`, index),
                    key: `${header}|${index}`,
                    sortable: false,
                    width: response?.data_header.length > 6 ? 180 : undefined
                }
            ))

            let obj = {}
            cols?.forEach((c, idx) => {
                obj[idx] = false
            })
            setDelOpenIndex({...obj})
            setEditOpenIndex({...obj})

            const rowsData = response?.data_record?.map((record) => {
                const row = {};
          
                response.data_header.forEach((header, index) => {
                    let cell_data = record[index];
                    if (record[index] !== null && record[index] !== undefined) {
                        if(`${header}|${index}` === 'document_name|0') {
                            row[`${header}|${index}`] = (
                                <UnstructuredFileName
                                    key={`${header}|${index}`}
                                    dataObj={
                                        (() => { try { return JSON?.parse(cell_data) } catch { return cell_data } })()
                                    }
                                    connectionName={selectedConn?.connection_name}
                                    connectionType={sourceType}
                                />
                            );
                        }
                        else if(`${header}|${index}` === 'document_type|1') {
                            row[`${header}|${index}`] = (
                                <Tooltip placement="topLeft" title={cell_data?.toString()}>
                                <span>
                                    <span className='mr-1'>
                                        {FILE_ICON[cell_data?.toString()] ?? <ICON_FILE width='14' height='14' color='currentColor' />}
                                    </span>
                                    {cell_data?.toString() ?? ''}
                                </span>
                                </Tooltip>
                            );
                        }
                        else {
                            row[`${header}|${index}`] = (
                            <Tooltip placement="topLeft" title={cell_data?.toString()}>
                                {cell_data?.toString() ?? ''}
                            </Tooltip>
                            );
                        }
                    } else if (record[index] === null) {
                        row[`${header}|${index}`] = "null";
                    } else if (!record[index]) {
                        row[`${header}|${index}`] = "";
                    } else {
                        row[`${header}|${index}`] = record[index];
                    }
                });
          
                return row;
            });

            setColumns([...cols])
            setRows([...rowsData])
        }
        else {
            setColumns([])
            setRows([])
        }

    }, [response])
    
    const headerTemplate = (header, key, index) => {
        switch (header) {
            case 'document_name':
                return <div className='fontSizeHeading text-muted fontInterSemiBold text-capitalize d-flex align-items-center'>
                    <span className='mr-1 d-inline-flex align-items-center'>
                        <ICON_FILE width='14' height='14' color='currentColor' />
                    </span>
                    <span>
                        {toTitleCase(header?.replaceAll("_", " "))}
                    </span>
                </div>
            case 'document_type':
                return <div className='fontSizeHeading text-muted fontInterSemiBold text-capitalize d-flex align-items-center'>
                    <span className='mr-1 d-inline-flex align-items-center'>
                        <ICON_LIST width='14' height='14' color='currentColor' />
                    </span>
                    <span>
                        {toTitleCase(header?.replaceAll("_", " "))}
                    </span>
                </div>
            default:
                return <div className={`uns-table-col-hd ${delOpenIndex[index] || editOpenIndex[index] ? 'active' : ''} fontSizeHeading fontInterSemiBold text-capitalize align-items-center jusitfy-content-between w-100`}>
                    <span className='text-with-ellipsis text-muted' title={header}>
                        <span className='mr-1 d-inline-flex align-items-center position-relative' style={{top: 3}}>
                            <ICON_LIST width='14' height='14' color='currentColor' />
                        </span>
                        {header}
                    </span>
                    <span className={`ml-auto act-btn`}>
                        <Popover
                            open={editOpenIndex?.[index]}
                            onOpenChange={bool => {
                                setEditOpenIndex(prev => {
                                    return {...prev, [index]: bool}
                                })
                                if(!bool) {
                                    setCurrentTopic({topic_name: '', description: '', possible_values: ''})
                                }
                            }}
                            trigger={'click'}
                            placement='bottomRight'
                            destroyTooltipOnHide
                            title={
                                <div className='px-2 pt-2 pb-1 fontSizeHeading border-bottom'>
                                    Editing "{header}"
                                </div>
                            }
                            content={
                                <>
                                    <div className='p-2' style={{width: 200}}>
                                        <div className='mb-1'>
                                            <label className='mb-1 label-gray fontSizeLabel fontInterSemiBold'>
                                                Topic Name
                                            </label>
                                            <div>
                                                <TopicNameInput
                                                    currentTopic={currentTopic}
                                                    setCurrentTopic={setCurrentTopic}
                                                />
                                                {/* <Input
                                                    size='middle'
                                                    className='rounded px-1'
                                                    defaultValue={currentTopic?.topic_name}
                                                    onChange={e => {
                                                        e?.stopPropagation()
                                                        e?.preventDefault()
                                                        setCurrentTopic(prev => {
                                                            prev['topic_name'] = e?.target?.value
                                                            return {...prev}
                                                        })
                                                    }}
                                                /> */}
                                            </div>
                                        </div>
                                        <div className='mb-1'>
                                            <label className='mb-1 label-gray fontSizeLabel fontInterSemiBold'>
                                                Description
                                            </label>
                                            <div>
                                                <Input.TextArea
                                                    size='middle'
                                                    className='rounded px-1'
                                                    style={{height: 50, resize: 'none', lineHeight: 1.4}}
                                                    defaultValue={currentTopic?.description}
                                                    onChange={e => {
                                                        e?.stopPropagation()
                                                        e?.preventDefault()
                                                        setCurrentTopic(prev => {
                                                            prev['description'] = e?.target?.value
                                                            return {...prev}
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='mb-1'>
                                            <label className='mb-1 label-gray fontSizeLabel fontInterSemiBold'>
                                                Possible Values
                                            </label>
                                            <div>
                                                <Input
                                                    size='middle'
                                                    className='rounded px-1'
                                                    defaultValue={currentTopic?.possible_values}
                                                    onChange={e => {
                                                        e?.stopPropagation()
                                                        e?.preventDefault()
                                                        setCurrentTopic(prev => {
                                                            prev['possible_values'] = e?.target?.value
                                                            return {...prev}
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='py-1 d-flex justify-content-center'>
                                            <Buttons
                                                props={{
                                                    buttonText: "Save",
                                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                                    buttonEvent: () => {

                                                        let tableData = cloneDeep(reduxData?.tableData?.result)

                                                        tableData['data_header'] = tableData?.['data_header']?.map((h, i) => key === `${h}|${i}` ? currentTopic?.topic_name : h)

                                                        reduxData.tableData.result = {...tableData}
                                                        dispatch(setPodCreationData(reduxData))

                                                        setTopicsData(prev => {
                                                            prev = prev?.map(t => (
                                                                t?.topic_name === header 
                                                                ?   {
                                                                        ...currentTopic,
                                                                        possible_values: currentTopic?.possible_values?.split(",")?.map(v => v?.trim())
                                                                    }
                                                                :   {...t}
                                                            ))
                                                            return prev
                                                        })
                                                        setCurrentTopic({topic_name: '', description: '', possible_values: ''})
                                                    },
                                                    ImgSrc: () => <ICON_SAVE/>,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        >
                            <span>
                                <Buttons
                                    props={{
                                        buttonText: "",
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon border-0 mr-1",
                                        buttonEvent: () => {
                                            let topicObj = topicsData?.find(t => t?.topic_name === header) ?? {}
                                            setCurrentTopic({
                                                ...topicObj,
                                                possible_values: topicObj?.possible_values?.join(",")
                                            })
                                            setEditOpenIndex(prev => {
                                                return {...prev, [index]: true}
                                            })
                                        },
                                        ImgSrc: () => <ICON_EDITBOX/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </span>
                        </Popover>
                        <Popover
                            open={delOpenIndex?.[index]}
                            onOpenChange={bool => {
                                setDelOpenIndex(prev => {
                                    return {...prev, [index]: bool}
                                })
                            }}
                            trigger={'click'}
                            placement='bottom'
                            title={
                                <div className='px-2 pt-2 pb-1 fontSizeHeading'>
                                    Are you sure?
                                </div>
                            }
                            content={
                                <div className='p-2' style={{width: 180}}>
                                    <Buttons
                                        props={{
                                            buttonText: "Yes",
                                            buttonClassName: "custom-btn-primary error-red custom-btn btn-with-text border-0 w-100 mb-1",
                                            buttonEvent: () => {
                                                onDeleteCol(header, key, index)
                                            },
                                            ImgSrc: () => <></>,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                    <Buttons
                                        props={{
                                            buttonText: "No",
                                            buttonClassName: "custom-btn-outline border custom-btn btn-with-text w-100",
                                            buttonEvent: () => {
                                                setDelOpenIndex(prev => {
                                                    return {...prev, [index]: false}
                                                })
                                            },
                                            ImgSrc: () => <></>,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                </div>
                            }
                        >
                            <span>
                                <Buttons
                                    props={{
                                        buttonText: "",
                                        buttonClassName: "custom-btn-outline error-red custom-btn btn-with-icon border-0",
                                        buttonEvent: () => {
                                            setDelOpenIndex(prev => {
                                                return {...prev, [index]: true}
                                            })
                                        },
                                        ImgSrc: () => <ICON_DELETE/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </span>
                        </Popover>
                    </span>
                </div>
        }
    }

    const onDeleteCol = (header, key, index) => {
        
        let tableData = cloneDeep(reduxData?.tableData?.result)

        tableData['data_header'] = tableData?.['data_header']?.filter((h, i) => key !== `${h}|${i}`)
        tableData['data_dtype'] = tableData?.['data_dtype']?.filter((v, i) => i !== index)
        tableData['data_record'] = tableData?.['data_record']?.map(v => v?.filter((r, idx) => idx !== index))
        tableData['topic_info'] = tableData?.['topic_info']?.filter(r => r?.topic_name !== header)

        reduxData.tableData.result = {...tableData}
        dispatch(setPodCreationData(reduxData))

        setTopicsData(prev => {
            prev = prev?.filter(t => t?.topic_name !== header)
            return [...prev]
        })
    }

    const colsList = React.useMemo(() => {
        let c = [...columns]
        c = c?.map((col, idx) => {
            return {
                ...col,
                name: headerTemplate(col?.name, col?.key, idx),
                width: columns.length <= 8 
                    ? (() => {
                        /**
                         * Calculating Height Manually
                         */
                        const clientWidth = document?.querySelector(".custom-simple-landing-dgt")?.clientWidth
                        if(clientWidth)
                        return Number((clientWidth/columns?.length).toFixed(0))
                        return col?.width
                        })()
                    : col?.width
            }
        })
        return [...c]
    }, [columns, delOpenIndex, editOpenIndex, currentTopic])

    return (
        <>
            <LandingSimpleTableInner
                key={key}
                cols={colsList ?? []}
                rowsData={rows}
                tableHeight={'100%'}
                resizable={false}
                showCursor={false}
            />
        </>
    )
}

export default UnstructuredTable

const TopicNameInput = ({setCurrentTopic = () => {}, currentTopic = {}}) => {

    const [value, setValue] = React.useState('')

    React.useEffect(() => {
        setValue(currentTopic?.topic_name)
    }, [currentTopic])

    return <Input
                size='middle'
                className='rounded px-1'
                value={value}
                onChange={e => {
                    e?.stopPropagation()
                    e?.preventDefault()
                    let val = e?.target?.value
                    if(val === "" || val?.match(columnNameRegex)) {
                        setValue(val?.toLowerCase())
                    }
                }}
                onBlur={() => {
                    setCurrentTopic(prev => {
                        prev['topic_name'] = value
                        return {...prev}
                    })
                }}
            />
}