
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {

  setFlowsCurrenttransformer,
  updateFlowsElements,
} from "../../../../../store/modules/flows/NewFlowsRedux/flowsActions";
import Buttons from "../../../../Common/button/Buttons";
import {
  DESCRIPTION,
  DOMAIN_LARGE,
  PERSONCREATED,
  PERSONEDIT,
  PLUS,
} from "../../../../Common/iconSource";

import { Literals } from "../../../common/literals";
import SourceTypeImage from "../../../common/sourceTypeImage";

import SelectDataSource from "./SelectDataSource";
import PODSelectionList from "./PODSelectionList";
import { cloneDeep } from "lodash";
import { dictionaryEndpoint } from "../../../../../store/actions";
import { setSelectedPodList } from "../../../../../store/modules/common/podSelectionModalAction";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { getObjectLength } from "../../../common/helperFunctions";
import { checkIsEditorFlow, getPreviewResultColumns } from "../commonFunctions/FlowsHelperFunction";




const Leftpane = ({
  data
}) => {
  let podDetails = data;

  return (
    <>
      <div className="">

        {(
          <>
            <div className="d-flex align-items-center justify-content-between p-0 h-10 selec-pod-meta-info">
              <div className="p-2 d-flex align-items-center col-2">
                <SourceTypeImage
                  align="center"
                  dataName={
                    podDetails &&
                      Object.keys(podDetails).length > 0 ?
                      podDetails.source_type :
                      " "
                  }
                />
                <div className="w-100 ml-3">
                  <p className="label">POD Name</p>
                  <p className="paragraph two-line-ellipsis custom-ellipsis text-uppercase" style={{wordBreak: 'break-all'}}>
                    {podDetails &&
                      Object.keys(podDetails).length > 0 ?
                      podDetails.table_name : '-'}
                  </p>
                </div>
              </div>

              <div className="padding-2 d-flex align-items-center col-2">
                <span className="dsrc-lf-icon">
                  <DOMAIN_LARGE />
                </span>
                <div className="w-100 ml-3">
                  <p className="label">Domain Name</p>
                  <p className="paragraph two-line-ellipsis custom-ellipsis" style={{wordBreak: 'break-all'}}>
                    {podDetails &&
                      Object.keys(podDetails).length > 0 ?
                      podDetails?.data_domain_detail?.data_domain_name?.toUpperCase() : '-'}
                  </p>
                </div>
              </div>

              <div className="padding-2 d-flex align-items-center col-2">
                <span className="dsrc-lf-icon">
                  <DESCRIPTION />
                </span>
                <div className="w-100 ml-3">
                  <p className="label">Description</p>
                  <p className="paragraph single-line-ellipsis custom-ellipsis">
                    {podDetails &&
                      Object.keys(podDetails).length > 0 ?
                      podDetails.pod_description !== '' ? podDetails.pod_description : '_' : '-'}
                  </p>
                </div>
              </div>

              <div className="padding-2 d-flex align-items-center col-2">
                <span className="dsrc-lf-icon">
                  <PERSONCREATED />
                </span>
                <div className="w-100 ml-3">
                  <p className="label">Created By</p>
                  <p className="paragraph custom-ellipsis" style={{wordBreak: 'break-all'}}>
                    {podDetails ?
                      Object.keys(podDetails).length > 0 &&
                        podDetails.created_by_user?.username !== '' ? podDetails.created_by_user?.username : '-' : '-'}
                  </p>
                </div>
              </div>

              <div className="padding-2 d-flex align-items-center col-2">
                <span className="dsrc-lf-icon">
                  <PERSONEDIT />
                </span>
                <div className="w-100 ml-3">
                  <p className="label">Updated By</p>
                  <p className="paragraph custom-ellipsis" style={{wordBreak: 'break-all'}}>
                    {podDetails ?
                      Object.keys(podDetails).length > 0 &&
                      podDetails?.updated_by_user?.username : '-'}
                  </p>
                </div>
              </div>




            </div>
          </>
        )}

      </div>
    </>
  );
};

const DataSource = ({ setupdatedFlowRedux, updatedFlowRedux, setshowCloseWarning, isExecutePermission }) => {
  const [tempDictionaryTableData, setTempDictionaryTableData] = useState(null);

  const [showEditPod, setshowEditPod] = useState(false);
  const dispatch = useDispatch();
  let currentTransformer = useSelector(state => state.Lab.currentTransformer)
  let flowsElements = useSelector(state => state.Lab.flowsElements)

  const onRowSelection = (record, rowIndex) => {
    if (dispatch(checkIsEditorFlow())) {
      let isTransformerNameExist = false;
      let isPodIdPresent = flowsElements?.nodes?.findIndex(item => item.id !== currentTransformer?.id && item?.content?.table_id === record?.table_id)
      if (isPodIdPresent > -1) {
        isTransformerNameExist = true
      }

      if (!isTransformerNameExist) {
        setTempDictionaryTableData(record);

        dispatch(setSelectedPodList(record));
        let tempContent = cloneDeep(currentTransformer?.content)
        tempContent.table_id = record.table_id;
        // tempContent.outcomeColumns = []
        let cloneFlowsElements = cloneDeep(flowsElements)
        let datasourceName = `${record?.data_domain_detail?.data_domain_name}.${record?.table_name}`
        let newNodes = cloneFlowsElements?.nodes.map((item) => {
          return item.id === currentTransformer.id
            ? {
              ...item, transformerName
                : datasourceName,
              content: tempContent
            }
            : item;
        });
        cloneFlowsElements['nodes'] = newNodes
        setupdatedFlowRedux(cloneFlowsElements)
        // dispatch(updateFlowsElements(cloneFlowsElements));

        // currentTransformer['transformerName'] = datasourceName;
        // dispatch(setFlowsCurrenttransformer(currentTransformer));

        setshowCloseWarning(true)
      } else {
        setshowCloseWarning(true)
        emitToastNotification(
          "error",
          Literals.DATA_SOURCE_ALREADY_EXIST_PLEASE_SELECT_OTHER_DATASOURCE
        );
      }
    }

  };

  // useEffect(() => {
  //   console.log(updatedFlowRedux)

  // }, [updatedFlowRedux])


  useEffect(() => {
    let currentTransformerClone = {}
    if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {
      currentTransformerClone = updatedFlowRedux?.nodes?.find((ele) => ele.id?.toString() === currentTransformer?.id?.toString())

    } else if (getObjectLength(flowsElements) && flowsElements?.nodes?.length) {

      currentTransformerClone = flowsElements?.nodes?.find((ele) => ele.id?.toString() === currentTransformer?.id?.toString())
    }

    if (currentTransformerClone && currentTransformerClone?.content?.table_id) {
      setshowEditPod(true);
      dispatch(dictionaryEndpoint(currentTransformerClone?.content?.table_id, false, false)).then((resp) => {
        if (resp?.data) {
          dispatch(setSelectedPodList(resp?.data));
          setTempDictionaryTableData(resp?.data);

          let podDetails = {
            connectionName: 'hive',
            podName: resp?.data?.table_name,
            dataDomain: resp?.data?.data_domain_detail?.data_domain_name,
            podIds: [resp?.data?.table_id],
          }
          dispatch(getPreviewResultColumns(podDetails)).then((outcomeColumns) => {

            if (outcomeColumns.length) {
              let cloneflowsElements = cloneDeep(flowsElements);
              let nodes = cloneflowsElements?.nodes.map((item) => {
                if (item.id?.toString() === currentTransformer.id?.toString()) {
                  item["content"]["outcomeColumns"] = outcomeColumns;
                  item['transformerName'] = `${resp?.data?.data_domain_detail?.data_domain_name}.${resp?.data?.table_name}`

                }
                return item;
              });
              cloneflowsElements['nodes'] = nodes;
              dispatch(updateFlowsElements(cloneflowsElements));
            }
          })


        }
      })
    } else {
      dispatch(setSelectedPodList([]));
      setTempDictionaryTableData([])
    }
    return () => {
      dispatch(setSelectedPodList([]));
    }
  }, [])


  return (
    <>

      <div className="transformer-details-maindiv">
        {!showEditPod ? <div className="source-not-found">
          <SelectDataSource />
          <Buttons
            props={{
              buttonText: "POD",
              buttonClassName:
                "custom-btn-primary custom-btn btn-with-text",
              buttonEvent: () => {
                setshowEditPod(true);
              },
              ImgSrc: () => <PLUS />,
              isDisable: window.location.href.includes("viewFlows")
                ? true
                : false,
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
            }}
          />

        </div>
          : (
            <div className="selected-pod-details">
              <Leftpane

                data={tempDictionaryTableData}

              />
              <PODSelectionList

                onRowSelection={onRowSelection} data={tempDictionaryTableData} />
            </div>
          )}
      </div>
    </>
  );
};


export default DataSource;

