import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../loadingActions";
import toastr from "toastr";
import { RESET_VIRTUAL_TABLE_ACTIONS, RESET_VIRTUAL_TABLE_REDUX, SET_ACTION_TYPE, SET_ACTIVE_POD_DETAIL, SET_APPLIED_FLITERS_LIST, SET_CURRENT_TABLE_RESPONSE, SET_EDITOR_QUERY, SET_MULTIPLE_TABLE_DATA, SET_ROWS_COUNT, SET_SEARCH_KEYWORD, SET_SHOW_RESET_FILTER, SET_SORTED_COLUMN_LIST } from "./actionType";
import { setCurrentTableRowCount, setQueryKillFlag, setResponseQuery, setTableUUID } from "../DataGridTable/dataGridTableRedux";
import { emitToastNotification } from "../../../../helpers/toast_helper";

const BASE_URL = window._env_.REACT_APP_API_BASE_URL;
export const setCurrentTableResponse = (data) => {

    return {
        type: SET_CURRENT_TABLE_RESPONSE,
        payload: data
    };
};

export const setMultipleTableData = (data) => {

    return {
        type: SET_MULTIPLE_TABLE_DATA,
        payload: data
    };
};

export const setSortColumnList = (data) => {

    return {
        type: SET_SORTED_COLUMN_LIST,
        payload: data
    };
};
export const setFilterColumnList = (data) => {

    return {
        type: SET_APPLIED_FLITERS_LIST,
        payload: data
    };
};
export const setSearchKeyword = (data) => {

    return {
        type: SET_SEARCH_KEYWORD,
        payload: data
    };
};
export const setPreviewActionType = (data) => {

    return {
        type: SET_ACTION_TYPE,
        payload: data
    };
};
export const setShowResetFilterButton = (data) => {

    return {
        type: SET_SHOW_RESET_FILTER,
        payload: data
    };
};
export const resetVirtualTableRedux = () => {

    return {
        type: RESET_VIRTUAL_TABLE_REDUX,
        payload: {}
    };
};



export const setPreviewTableRowCount = (data) => {

    return {
        type: SET_ROWS_COUNT,
        payload: data
    };
};

export const setActivePodDetails = (data) => {

    return {
        type: SET_ACTIVE_POD_DETAIL,
        payload: data
    };
};



export const resetVirtualTableActions = () => {

    return {
        type: RESET_VIRTUAL_TABLE_ACTIONS,
        payload: {}
    };
};
export const setPreviewSqlEditorQuery = (data) => {

    return {
        type: SET_EDITOR_QUERY,
        payload: data
    };
};

export const fetchPreviewTable = (payload,showLoader=true,showError=true) => {

    return (dispatch) => {
        if(showLoader){
            dispatch(setLoadingStatus(true));

        }
        dispatch(setTableUUID(payload.uuid))
        dispatch(setQueryKillFlag(false));
        return new Promise((resolve, reject) => {
            dispatch(getPreviewTableRowCount(payload))
            dispatch(
                fetchData("POST",  `${BASE_URL}preview/v2/`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {

                        if (resp && resp.data) {
                            dispatch(setCurrentTableResponse(resp?.data))
                        }
                        dispatch(setResponseQuery(resp?.data?.query))
                        
                        resolve(resp);
                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                    if(showError){

                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
};

export const getPreviewTableRowCount = (payload) => {
    return (dispatch, getState) => {
        let newPayload = {...payload}
        newPayload['do_count'] = true
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}preview/v2/`, newPayload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        dispatch(setPreviewTableRowCount(resp?.data?.result?.data_record[0]))
                        dispatch(setCurrentTableRowCount(resp?.data?.result?.data_record[0]))
                        resolve(resp);

                    }

                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    // emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};