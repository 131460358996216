import { combineReducers } from "redux";
import Dictionary from "./dictionary/dictionaryReducer";
import LandingPage from "./landingPage/landingPageReducer";
import PreviewPage from "./preview/PreviewPageReducer";
import Summary from "./summary/summaryReducer";
import PodCreationData from "./createPOD/podCreationRedux";

const DataCatalogue = combineReducers({
    PreviewPage,
    LandingPage,
    Dictionary,
    Summary,
    PodCreationData
});
export default DataCatalogue
