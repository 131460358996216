import React, { useState } from 'react'
import './userConfig.scss'
import { Switch } from 'antd'
import { Literals } from '../../../common/literals'
import { ICON_ACCESS, ICON_ARROW_LEFT, ICON_CLOSE, ICON_DELETE, ICON_EDITBOX, ICON_EMAIL, ICON_GOOGLE_COLORED, ICON_LOADING_CIRCLE, ICON_MICROSOFT_COLORED, ICON_OKTA_COLORED, ICON_OPENAI, ICON_PLUS, ICON_SAML_COLORED } from '../../../../Common/newIconSource'
import Buttons from '../../../../Common/button/Buttons'
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import CommonModal from '../../../../Common/CommonModal/CommonModal'
import SMTPEmailClientForm from './SMTPEmailClientForm'
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader'
import OpenAiConfigForm from './OpenAiConfigForm'
import MicrosoftClientForm from './MicrosoftClientForm'
import { useStore } from 'react-redux'
import { changeOpenAiStatus, changeProviderStatus, deleteOpenAi, deleteSMTP, destroyProvider } from '../../../../../store/modules/UserManagement/users/usersActions'
import ActionPopUp from '../../../common/actionPopUp'
import { DeleteNoteContent } from '../../../common/helperFunctions'
import DeleteModal from '../../../../Common/deleteModal/DeleteModal'
import EmailLoginConfigForm from './EmailLoginConfigForm'

const UserConfig = React.forwardRef((props, ref) => {

    const [openModal, setModalOpen] = React.useState(true)
    const [loading, setLoading] = React.useState(false)
    const [activeSetting, setActiveSetting] = React.useState('smtp')
    const [itemValues, setItemValues] = React.useState({})
    const [loadingtoggle, setLoadingtoggle] = React.useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const [isSaving, setIsSaving] = React.useState(false)
    const [isActiveToggle, setisActiveToggle] = React.useState(false)
    const store = useStore()
    const smtpFormRef = React.useRef({})
    const microsoftFormRef = React.useRef({})
    const openaiFormRef = React.useRef({})
    const basicAuthRef = React.useRef({})

    React.useImperativeHandle(ref, () => {
        return {}
    }, [])

    const demo = [
        {
            id: 0,
            title: 'Email and Password',
            description: 'Each user will log in with their email and password',
            icon: <ICON_EMAIL color='#2c83c4' />
        },
        {
            id: 1,
            title: 'SAML SSO',
            description: 'Authentication via SAML',
            icon: <ICON_SAML_COLORED />
        },
        {
            id: 2,
            title: 'SMTP Email Client',
            description: 'SMTP Client Configuration',
            icon: <ICON_EMAIL color='currentColor' />
        },
        {
            id: 3,
            title: 'OpenAI',
            description: 'OpenAI Configuration',
            icon: <ICON_OPENAI color='#1da484' />
        },
    ]

    const idpList = [
        // {
        //     id: 0,
        //     key: 'google',
        //     title: 'Google Workspace',
        //     disable: true,
        //     description: 'Authentication via Google Workspace',
        //     icon: <ICON_GOOGLE_COLORED />
        // },
        {
            id: 1,
            key: 'microsoft',
            title: 'Microsoft Azure AD',
            description: 'Authentication via Azure AD',
            icon: <ICON_MICROSOFT_COLORED />
        },
        {
            id: 2,
            key: 'basic_auth',
            disable: false,
            title: 'Email and Password',
            description: 'Each user will log in with their email and selected password. On and Off Toggle',
            icon: <ICON_OKTA_COLORED />
        },
        // {
        //     id: 3,
        //     key: 'saml',
        //     title: 'SAML',
        //     description: 'Authentication via SAML',
        //     icon: <ICON_SAML_COLORED />
        // },
    ]

    const configList = [
        {
            id: 0,
            key: 'smtp',
            title: 'SMTP Email Client',
            description: 'SMTP Client Configuration',
            icon: <ICON_EMAIL color='#2c83c4' />
        },
        {
            id: 1,
            key: 'openai',
            title: 'OpenAI',
            description: 'OpenAI Configuration',
            icon: <ICON_OPENAI color='#1da484' />
        },
    ]

    const providerToggleAPI = (isOn) => {
        if (activeSetting === 'openai') { // added logic to call other api
            store.dispatch(changeOpenAiStatus(true, activeSetting, isOn)).then((resp) => {
                if (resp.status === 'success') {
                    setisActiveToggle(isOn)
                    setLoadingtoggle(false)

                }
            }).catch(() => {
                setLoadingtoggle(false)

            })

        } else {
            store.dispatch(changeProviderStatus(true, activeSetting, isOn)).then((resp) => {
                if (resp.status === 'success') {
                    setisActiveToggle(isOn)
                    setLoadingtoggle(false)

                }
            }).catch(() => {
                setLoadingtoggle(false)

            })
        }


    }

    const activeContent = React.useMemo(() => {
        switch (activeSetting) {
            case 'google':

            case 'saml':
            case 'smtp':
                return <>
                    <SMTPEmailClientForm
                        ref={smtpFormRef}
                        fieldValues={itemValues}
                        setFieldValues={setItemValues}
                        setLoading={setLoading}
                        loading={loading}
                        setisActiveToggle={setisActiveToggle}
                        saving={isSaving}
                        setSaving={setIsSaving}
                    />
                </>

            case 'microsoft':
                return <MicrosoftClientForm
                    ref={microsoftFormRef}
                    fieldValues={itemValues}
                    setFieldValues={setItemValues}
                    setLoading={setLoading}
                    setisActiveToggle={setisActiveToggle}
                    loading={loading}
                    saving={isSaving}
                    setSaving={setIsSaving}
                />
            case 'openai':
                return <OpenAiConfigForm
                    ref={openaiFormRef}
                    fieldValues={itemValues}
                    setFieldValues={setItemValues}
                    setLoading={setLoading}
                    setisActiveToggle={setisActiveToggle}
                    loading={loading}
                    saving={isSaving}
                    setSaving={setIsSaving}
                />
            case 'okta':
                return <>{activeSetting}</>
            case 'basic_auth':
                return <EmailLoginConfigForm
                    ref={basicAuthRef}
                    fieldValues={itemValues}
                    providerToggleAPI={providerToggleAPI}
                    setFieldValues={setItemValues}
                    setLoading={setLoading}
                    isActiveToggle={isActiveToggle}
                    setisActiveToggle={setisActiveToggle}
                    loadingtoggle={loadingtoggle}
                    setLoadingtoggle={setLoadingtoggle}
                    loading={loading}
                    saving={isSaving}
                    setSaving={setIsSaving} />
            default:
                return <>
                    <div>
                        <h5>Welcome!</h5>
                    </div>
                </>
        }
    }, [activeSetting, itemValues, isSaving, loading])

    const handleSubmit = () => {
        switch (activeSetting) {
            case 'smtp':
                return smtpFormRef?.current?.save()
            case 'microsoft':
                return microsoftFormRef?.current?.save()
            case 'openai':
                return openaiFormRef?.current?.save()
            default:
                return
        }
    }

    const formHeadingMap = {
        google: 'Google Workspace OAuth Setup',
        microsoft: 'Microsoft Azure AD OAuth Setup',
        okta: 'Okta OAuth Setup',
        saml: 'SAML Identity Provider',
        smtp: 'SMTP Email Client Setup',
        openai: 'OpenAI Setup',
    }

    const openDeleteDialog = () => {

        setDeletePopUpContent({
            modalClass: "delete-popup",
            heading: "Delete",
            message: `This action will delete the ${activeSetting?.toUpperCase()} permanently.`,
            button: [
                {
                    name: "Delete",
                    action: (e) => deleteProvider(),
                }
            ],
            elements: [],
            closeActionPopup: () => setOpenDeletePopup(false),
        });
        setOpenDeletePopup(true);
    };

    const deleteProvider = () => {
        if (activeSetting === 'openai') { // added logic to call other api
            store.dispatch(deleteOpenAi(true, activeSetting, false)).then((resp) => {
                if (resp.status === 'success') {
                    setItemValues(prev => (
                        {
                            ...prev,
                            openai: {
                                type: "openai",
                                model_name: "",
                                API_key: "",
                                is_active: false,
                                API_base: "",
                                API_version: "",
                                deployment_name: "",
                                organization_id: "",
                                embedding_model_name: "",
                                embedding_deployment_name: "",
                                // proxy: el?.proxy ?? ""
                            }
                        }
                    ))
                    setOpenDeletePopup(false);

                }
            }).catch(() => {
                setOpenDeletePopup(false);


            })

        } else if (activeSetting === 'smtp') { // added logic to call other api
            store.dispatch(deleteSMTP(true, activeSetting, false)).then((resp) => {
                if (resp.status === 'success') {
                    setItemValues(prev => (
                        {
                            ...prev,
                            smtp: {
                                host: '',
                                is_primary: false,
                                password: '',
                                is_active: false,
                                port: '',
                                sender_email: '',
                                sender_name: '',
                                use_tls: false,
                                username: ''
                            }
                        }
                    ))
                }
                setOpenDeletePopup(false);

            }).catch(() => {

                setOpenDeletePopup(false);
            })

        } else {
            store.dispatch(destroyProvider(true, activeSetting)).then((resp) => {
                if (resp.status === 'success') {
                    setItemValues(prev => (
                        {
                            ...prev,
                            microsoft: {
                                client_id: '',
                                client_secret: '',
                                valid_email_domain: [],
                                provider: 'microsoft',
                                tenant_id: '',
                            }
                        }
                    ))
                    setOpenDeletePopup(false);

                }

            })
            setOpenDeletePopup(false);

        }
    }
    return (
        <div>
            <div className='user-config-container'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <label className='conf-head fontInterSemiBold'>Identity Providers and Configuration</label>
                        <p className='fontSizeHeading fontInterSemiBold conf-head-desc'>Each user will log in with your organization’s Identity Provider.</p>
                    </div>
                    <div>
                        {/* <button className='custom-btn-large'
                            onClick={() => {
                                setModalOpen(!openModal)
                            }}
                        >
                            {
                                openModal ? <ICON_ARROW_LEFT color='currentColor' />
                                    :
                                    <ICON_PLUS color='currentColor' />
                            }
                            <span className='ml-2'>
                                {openModal ? 'Back to list' : 'Add Item'}
                            </span>
                        </button> */}
                    </div>
                </div>
                {/* <hr/> */}
                {
                    !openModal
                        ?
                        <div className='conf-list'>
                            {/* <div className='common-seperator'></div> */}
                            {
                                demo?.map(d => (
                                    <div key={`config-itm-${d?.id}`}>
                                        <div key={`config-itm-${d?.id}`} className='config-item'>
                                            <div>
                                                <span className='config-itm-ic'>
                                                    {d?.icon}
                                                </span>
                                            </div>
                                            <div>
                                                <div className='d-flex flex-column'>
                                                    <label className='fontSizeHeading text-dark mb-1'>
                                                        {d?.title}
                                                    </label>
                                                    <p className='fontSizeLabel text-muted mb-0'>
                                                        {d?.description}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                        <div className='bg-light px-2 py-2 rounded d-flex align-items-center justify-content-between'>
                                            <div>
                                                <label className='mb-0 text-muted fontSizeHeading'>
                                                    Two-Factor Authentication:
                                                </label>
                                                <label className='mb-0 text-success fontSizeHeading ml-1'>
                                                    On (TOTP Authenticator)
                                                </label>
                                            </div>
                                            <div>
                                                <Buttons
                                                    props={{
                                                        buttonText: 'Turn Off',
                                                        buttonClassName: `custom-btn-outline border custom-btn btn-with-text`,
                                                        buttonEvent: () => {

                                                        },
                                                        ImgSrc: () => <></>,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                    }} />
                                            </div>
                                        </div>
                                        <div className='common-seperator mb-1 mt-2'></div>
                                    </div>
                                ))
                            }
                        </div>
                        :
                        <div className='conf-add-item-container'>
                            <div className='d-flex align-items-center justify-content-between py-1'>
                                <div>
                                    <h5 className='mb-0 fontSizeLargeHeading text-dark fontInterSemiBold'>
                                        Choose Your Settings
                                    </h5>
                                </div>
                                <div>
                                    {/* <Buttons
                                        props={{
                                            buttonText: '',
                                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon`,
                                            buttonEvent: () => {
                                                setModalOpen(false)
                                            },
                                            ImgSrc: () => <ICON_CLOSE />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }} /> */}
                                    <div>
                                        <div className='d-flex align-items-center  justify-content-end'>
                                            {activeSetting !== 'smtp' ? <Switch
                                                size='small'
                                                checked={isActiveToggle}
                                                loading={loadingtoggle}
                                                onChange={(checked) => {
                                                    providerToggleAPI(checked);
                                                    setLoadingtoggle(true)
                                                    // setisActiveToggle(checked)

                                                }}
                                            /> : null}

                                            {activeSetting !== 'basic_auth' ? <Buttons
                                                props={{
                                                    buttonText: '',
                                                    buttonClassName: `error-red ml-2 custom-btn-outline custom-btn btn-with-icon`,
                                                    buttonEvent: () => {
                                                        // openDeleteDialog()
                                                        setOpenDeletePopup(true)
                                                    },
                                                    ImgSrc: () => <ICON_DELETE />,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                }} /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='conf-add-grid'>
                                <div>

                                    <div className='d-flex align-items-center justify-content-between py-1'>
                                        <div>
                                            <h5 className='mb-0 fontSizeHeading label-color fontInterSemiBold'>
                                                Configurations
                                            </h5>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            configList?.map(d => (
                                                <div key={`conf-itm-${d?.id}`}>
                                                    <div className={` ${d.disable ? 'disable-provider-item' : 'config-item-short'} ${d?.key === activeSetting ? 'active' : ''}`}
                                                        onClick={() => {
                                                            if (activeSetting === d?.key || d.disable) return
                                                            setActiveSetting(d?.key)
                                                        }}
                                                    >

                                                        <div>
                                                            <span className='config-itm-ic'>
                                                                {d?.icon}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex flex-column'>
                                                                <span className='fontSizeHeading text-disable mb-1'>
                                                                    {d?.title}
                                                                </span>
                                                                <p className='fontSizeLabel text-disable text-muted mb-0'>
                                                                    {d?.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between py-1'>
                                        <div>
                                            <h5 className='mb-0 fontSizeHeading label-color fontInterSemiBold'>
                                                Identity Providers
                                            </h5>
                                        </div>
                                    </div>
                                    {
                                        idpList?.map(d => (
                                            <div key={`idp=itm-${d?.id}`}>
                                                <div className={`${d.disable ? 'disable-provider-item' : 'config-item-short'} ${d?.key === activeSetting ? 'active' : ''}`}
                                                    onClick={() => {
                                                        if (activeSetting === d?.key || d.disable) return
                                                        setActiveSetting(d?.key)
                                                    }}
                                                >
                                                    <div>
                                                        <span className='config-itm-ic'>
                                                            {d?.icon}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='d-flex flex-column'>
                                                            <span className='fontSizeHeading text-disable mb-1'>
                                                                {d?.title}
                                                            </span>
                                                            <p className='fontSizeLabel text-disable text-muted mb-0'>
                                                                {d?.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        ))
                                    }<div>
                                    </div>
                                </div>
                                <div>
                                    <form
                                        className='h-100'
                                        onSubmit={(e) => {
                                            e?.preventDefault();
                                            handleSubmit()
                                        }}
                                    >
                                        <div style={{ position: 'fixed', left: -999 }}>
                                            <input type="email" />
                                            <input type="password" />
                                        </div>
                                        {
                                            !formHeadingMap[activeSetting] ? ''
                                                :
                                                <div>
                                                    <h5 className='fontInterSemiBold fontSizeLargeHeading color-primary'>
                                                        {formHeadingMap[activeSetting]}
                                                    </h5>
                                                </div>
                                        }
                                        <div className='py-2 position-relative'
                                            style={{ overflowX: 'hidden', overflowY: 'auto', height: 'calc(100% - 3.75rem)' }}>
                                            {
                                                loading ? <div className='d-block position-absolute h-100 w-100'
                                                    style={{ top: 0, left: 0, zIndex: 2, background: '#fff' }}>
                                                    <ClaristaLoader />
                                                </div>
                                                    : ''
                                            }
                                            {activeContent}
                                        </div>
                                        {
                                            activeSetting === '' ? ''
                                                :
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-end py-2 border-top'>
                                                        {/* <Buttons
                                                            props={{
                                                                actionType: 'button',
                                                                buttonText: 'Cancel',
                                                                buttonClassName: `custom-btn-outline border custom-btn btn-with-text mr-2`,
                                                                buttonEvent: () => {

                                                                },
                                                                ImgSrc: () => <></>,
                                                                isDisable: false,
                                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                            }} /> */}
                                                        {activeSetting !== 'basic_auth' ? <Buttons
                                                            props={{
                                                                actionType: 'submit',
                                                                buttonText: isSaving ? 'Saving' : 'Done',
                                                                buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                                                buttonEvent: () => {

                                                                },
                                                                ImgSrc: () => isSaving ? <ICON_LOADING_CIRCLE /> : <></>,
                                                                isDisable: isSaving || loading,
                                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                            }} /> : null}
                                                    </div>
                                                </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                }
            </div>
            <DeleteModal  //new modal added
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={DeleteNoteContent(null, `${activeSetting?.toUpperCase()} configuration`, `This action will delete the ${activeSetting?.toUpperCase()} permanently.`, false, false)}
                onConfirm={() => { deleteProvider() }}
            />
        </div>
    )
})

export default UserConfig