import React, { useState } from "react";
import { Card, Tooltip, Popover, Input, Dropdown } from "antd";
import sampleFlow from "../../../../assets/images/sampleFlow.png";
import FlowsEditor from "../../../../assets/images/FlowsEditor.png";
import { Literals } from "../../common/literals";
import Buttons from "../../../Common/button/Buttons";
import { useDispatch, useSelector } from "react-redux";
import DefaultFlow from "../../../../assets/images/emptyFlow.png";
import {
  DELETEICON,
  EDIT,
  FILLEDSTAR,
  STAR,
  COPY,
  CLOSE,
  SAVE,
  UPDATED_BY,
  USER,
  TAG,
  REDIRECTARROW,
  REDIRECT,
} from "../../../Common/iconSource";
import { getRelativeTime, permissions } from "../../common/helperFunctions";
import { useNavigate } from "react-router";
import { useStore } from "react-redux";
import { duplicate } from "../../../../store/modules/dataCatalogue/dictionary/dictionaryAction";

import { getFlowsAlertTransformerList } from "../../../../store/modules/alert/createAlert/createAlertAction";
import { SET_SELECTED_ALERT_TRANSFORMER } from "../../../../store/modules/alert/createAlert/createAlertActiontype";
import { EditFlowsAPI } from "../../../../store/modules/flows/flowsActions";
import Highlighter from "react-highlight-words";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { ICON_LOADING_CIRCLE, ICON_MINUS, ICON_PLUS } from "../../../Common/newIconSource";
import { createBookmark } from "../../../../store/modules/flows/landingPage/landingPageAction";
import _, { cloneDeep } from "lodash";
import { assignFlowTag, unassignFlowTag } from "../../../../store/modules/flows/NewFlowsRedux/flowsActions";
import { tags } from "../../dataCatalogue/landingPage/landingPageTagHelper";
const { Meta } = Card;

const flowsPermissions = permissions.flows

function FlowsCardView({
  data,
  getUpdatedFlowsList,
  showSelectedCardId,
  setshowSelectedCardId,
  // bookmarkData,
  listData,
  setData = () => { },
  handleDelete,
  setLoading,
  fromRoute,
  activeDomain
}) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();
  const [renameText, setRenameText] = useState(`${data?.allData?.name}_copy`);
  const [visible, setVisible] = useState(false);
  const [optionOpen, setOptionOpen] = useState(false)

  const [bookmark, setBookmark] = useState(false)
  const [bookmarkLoading, setBookmarkLoading] = useState(false)
  const [tagLoader, setTagLoader] = React.useState(false)
  const [showTagPop, setShowTagPop] = React.useState(false)
  const [tagMenu, setTagMenu] = React.useState([]);

  const tagList = useSelector(state => state.DataCatalogue?.LandingPage?.tagList);

  const checkIsEditor = () => {

    const isRoleEditor = !!data?.allData?.permission?.find(v => v === flowsPermissions.editor)

    if (isRoleEditor) return true
    return undefined
  }

  const isDeletePermission = checkIsEditor() ?? !!data?.allData?.permission?.find(v => v === flowsPermissions.delete)
  const isEditPermission = checkIsEditor() ?? !!data?.allData?.permission?.find(v => v === flowsPermissions.edit)
  React.useEffect(() => {
    if (data) {
      setBookmark(data?.bookmark)
    }
  }, [data])

  React.useEffect(() => {
    if (!optionOpen) {
      setVisible(false)
    }
  }, [optionOpen])

  React.useEffect(() => {
    setTagSubMenu([...data?.tag])
  }, [data, tagList])

  const addRemoveTagToPOD = (tag, remove = false) => {
    // console.log({tag_id})
    const { id: tag_id } = tag
    setTagLoader(true)
    let requestBody = {
      "tag": [tag_id]
    }
    if (!remove) {
      dispatch(assignFlowTag(requestBody, data?.allData?.id)).then(
        res => {
          if (res.status === 'success') {
            try {
              setTagLoader(false)
              setData(prev => prev?.map(v => ({ ...v, tag: v?.id === data?.allData?.id ? [...v?.tag, tag] : v?.tag })))
              // getUpdatedFlowsList();
            }
            catch (e) {
              setTagLoader(false)
            }
          }
        }
      )
        .catch(() => {
          setTagLoader(false)
        })
    }
    else {
      dispatch(unassignFlowTag(tag_id, data?.allData?.id)).then(
        res => {
          if (res.status === 'success') {
            setTagLoader(false)
            setData(prev => prev?.map(v => ({ ...v, tag: v?.id === data?.allData?.id ? v?.tag?.filter(t => t?.id !== tag_id) : v?.tag })))

            // getUpdatedFlowsList();
          }
        }
      )
        .catch(() => {
          setTagLoader(false)
        })
    }
  }

  const tagColor = (value) => {
    var res = value.split("#");
    let colorname = res[0];
    let color = '';
    for (let x in tags) {
      if (tags[x].id === colorname) {
        color = tags[x].color;
        break;
      }
    }
    return color + '95';
  }

  const setTagSubMenu = (tags) => {
    let podTag = tags.map(t => t?.name)
    let returnList = [];
    let availableTags = _.sortBy([...tagList], [function (o) { return o?.name; }])
    // console.log({availableTags, tagList})
    availableTags?.length && availableTags.forEach((tag, x) => {
      if (!podTag.includes(tag?.name)) {
        returnList.push(
          <div onClick={(e) => { e.stopPropagation() }} key={x} className={`${x === availableTags.length - 1 ? '' : 'custom-border-bottom'} d-flex justify-content-between align-items-center padding-top-1 padding-bottom-1`}>
            <div className="d-flex align-items-center">
              <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
              <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
              </Tooltip>
            </div>
            <Buttons
              props={{
                buttonText: '',
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2 ml-2",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false);
                  addRemoveTagToPOD(tag)
                },
                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE /> : <ICON_PLUS />,
                isDisable: tagLoader,
                buttonType: Literals.BTN_TERTIARY
              }}
            />
          </div>
        )
      } else {
        returnList.push(
          <div onClick={(e) => { e.stopPropagation() }} key={x} className='d-flex justify-content-between custom-border-bottom align-items-center padding-top-1 padding-bottom-1'>
            <div className="d-flex align-items-center">
              <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
              <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
              </Tooltip>
            </div>
            <Buttons
              props={{
                buttonText: '',
                buttonClassName: "danger-btn custom-btn btn-with-icon mr-2 ml-2 border-0",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false);
                  addRemoveTagToPOD(tag, true)
                },
                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE /> : <ICON_MINUS />,
                isDisable: tagLoader,
                buttonType: Literals.BTN_TERTIARY
              }}
            />
          </div>
        )
      }
    })
    if (returnList.length) {

      setTagMenu([...returnList])
    } else {
      setTagMenu([])

    }
  }


  const handleSaveClick = () => {
    try {
      setLoading(true)
      dispatch(duplicate({ name: renameText }, "flow", data.key)).then(
        (res) => {
          if (res.status === "success") {
            getUpdatedFlowsList();
            setLoading(false)

          }
        }
      );
    } catch (err) { }
  };
  const checkRoute = (data) => {
    if (fromRoute === "alert") {
      store.dispatch(getFlowsAlertTransformerList(data.key, data.allData)).then((resp) => {
        emitToastNotification('Success', 'Alert Fetched Successfully')
      })
    } else if (fromRoute === "scheduler") {
      store.dispatch({
        type: SET_SELECTED_ALERT_TRANSFORMER,
        payload: {
          flowId: data.key,
          alertList: [],
          selectedFlowDetails: data.allData,
        },
      });
    }
  };

  const bookmarkData = (id, bookmarkStatus = false) => {

    try {
      setBookmarkLoading(true)
      store.dispatch(createBookmark(id, bookmarkStatus)).then(
        res => {
          if (res.status === 'success') {
            setBookmark(bookmarkStatus)
            setBookmarkLoading(false)
            setData(prev => prev?.map(v => ({ ...v, bookmark: v?.id === data?.allData?.id ? bookmarkStatus : v?.bookmark })))
          }
        }
      ).catch(err => {
        setBookmarkLoading(false)
      })
    }
    catch (e) {
      setBookmarkLoading(false)
    }
  }

  const tagPopover = {
    header: () => {
      return <>
        <div className="d-flex justify-content-between custom-border-bottom padding-2">
          <p className="section-heading m-0">Tags</p>
          <div className="d-flex">
            <Tooltip title='Close' placement="bottom">
              <button className="custom-btn-outline custom-btn btn-with-icon"
                onClick={function (e) {
                  e.stopPropagation()
                  setShowTagPop(false)
                }}
              >
                <CLOSE />
              </button>
            </Tooltip>
            {/*  <Buttons
              props={{
                buttonText: 'TAG',
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                tooltip: 'Add Tag',
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false)
                  openAddTag()
                },
                ImgSrc: () => <PLUS />,
                isDisable: false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
              }}
            /> */}
          </div>
        </div>
      </>
    },
    body: () => {
      return <>
        <div className="padding-2 card-tags-auto-overflow tag-popover-body" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {
            tagMenu.length
              ? tagMenu.map((val, i) => <span key={`tag-pop-itm-${i}`}>{val}</span>)
              : <div className="py-1 text-center text-black-50">No Tag Found</div>
          }
        </div>
      </>
    }
  }

  const handleVisibleChange = (visible) => {
    setShowTagPop(visible)
    if (visible) {
      setTagSubMenu(data?.tag)
    }
  }

  const optionList = [
    {
      label: <span className={`dbox-opt-itm ${!isEditPermission ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!isEditPermission) return
          dispatch(EditFlowsAPI({ ApiType: 'GET', flow_Id: data.key })).then((res) => {
            if (res.status === 'success') {
              window.open(`${Literals.links.FLOWS}/${data.key}`, '_blank');
            }
          });
        }}>
        <REDIRECT />
        Open In New Tab
      </span>,
      key: '1',
      disabled: !isEditPermission
    },
    {
      label: <span className={`dbox-opt-itm ${!isEditPermission ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!isEditPermission) return
          dispatch(EditFlowsAPI({ ApiType: 'GET', flow_Id: data.key })).then((res) => {
            if (res.status === 'success') {
              history(`${Literals.links.FLOWS}/${data.key}`);
            }
          });
        }}>
        <EDIT />
        Edit
      </span>,
      key: '1',
      disabled: !isEditPermission
    },
    {
      label: <Popover
        destroyTooltipOnHide={true}
        content={
          <form
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <Tooltip title={renameText}>
              <Input
                onKeyDown={(e) => { e.stopPropagation() }}
                value={renameText}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setRenameText(e.target.value);
                }}
                className="custom-inputfield"
              />
            </Tooltip>
            <Buttons
              props={{
                actionType: "submit",
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline custom-btn btn-with-icon ml-1",
                buttonEvent: (e) => {
                  e.preventDefault();
                  handleSaveClick();
                },
                ImgSrc: () => <SAVE />,
                isDisable: false,
                buttonType: Literals.BTN_SECONDARY,
              }}
            />
          </form>
        }
        title={
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            style={{
              display: "flex",
              padding: "10px",
              justifyContent: "space-between",
            }}
          >
            <span>Create a Copy</span>{" "}
            <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  "custom-btn-outline custom-btn btn-with-icon mr-1",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setVisible(false);
                },
                ImgSrc: () => <CLOSE />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            />
          </div>
        }
        onOpenChange={(visible) => setVisible(visible)}
        trigger="click"
        open={isEditPermission && visible}
        placement="right"
      >
        <span className={`dbox-opt-itm ${!isEditPermission ? 'disabled' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isEditPermission) return
            setVisible(true)
          }}>
          <COPY />
          Duplicate
        </span>
      </Popover>,
      key: '2',
      disabled: !isEditPermission
    },
    {
      label: <Popover placement="right"
        title={tagPopover.header}
        content={tagPopover.body}
        trigger="hover"
        autoAdjustOverflow={true}
        open={showTagPop}
        onOpenChange={handleVisibleChange}
        destroyTooltipOnHide={true}
      >
        <span className="dbox-opt-itm"
          onClick={(e) => {
            e.stopPropagation();
            setShowTagPop(true)
            setTagSubMenu(data?.tag)
          }}>
          <TAG />
          Tags
        </span>
      </Popover>,
      key: '3',
    },
    {
      label: <span className={`dbox-opt-itm danger ${!isDeletePermission ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!isDeletePermission) return
          handleDelete(data.key)
        }}>
        <DELETEICON />
        Delete
      </span>,
      key: '4',
      disabled: !isDeletePermission
    },
  ]

  return (
    <div
      id={`flowCard_${data.key}`}
      className={
        showSelectedCardId && showSelectedCardId === data.key
          ? "position-relative flow-grid data-view-dbox selected-border"
          : "position-relative flow-grid data-view-dbox "
      }

      onClick={(e) => {
        if (!e?.target?.getAttribute('role') && e?.target?.getAttribute('role')?.toLowerCase() !== 'tooltip') {
          setshowSelectedCardId(data.key);
          fromRoute !== "alert" && fromRoute !== "scheduler"
            ? history(`${Literals.links.FLOWS}/${data.key}`, { state: { domainId: activeDomain ?? null } })
            : checkRoute(data);
        }
      }}
    >

      {fromRoute !== "alert" && fromRoute !== "scheduler" ? (
        <Tooltip title={data.bookmark ? Literals.REMOVE_FAVOURITES : Literals.ADD_FAVOURITES}>
          <div
            className={
              data.bookmark
                ? "table-icon cursor-pointer filled-star-icon"
                : "table-icon cursor-pointer star-icon"
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setBookmarkLoading(true);

              bookmarkData(data?.key, !data?.bookmark);
            }}
          >
            {bookmarkLoading ? <ICON_LOADING_CIRCLE /> : bookmark ? <FILLEDSTAR /> : <STAR />}
          </div>
        </Tooltip>
      ) : null}
      <Card
        hoverable
        cover={
          // <Tooltip

          //   placement="top"
          //   title={
          //     data.allData.description !== ""
          //       ? `${data.allData?.description?.slice(0, 300)}...`
          //       : "No description added for this flows."
          //   }
          // >
          data.thumbnail ? <img
            alt="example"
            src={data.thumbnail}
          /> : <div className="empty-flow pt-3">
            <img
              alt="example"
              src={DefaultFlow}
              height={30}
              width={30}
            />
            <span className="text-black-50">Empty Flow</span>
          </div>
          // </Tooltip>
        }
      >

        <Meta
          title={""}
          description={
            <div className="d-flex align-items-center justify-content-between mt-2">
              {
                fromRoute !== "alert" && fromRoute !== "scheduler"
                  ? <Dropdown
                    destroyPopupOnHide={true}
                    menu={{
                      items: optionList,
                      onClick: (e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault() }
                    }}
                    trigger={["hover"]}
                    placement="bottomRight"
                    onOpenChange={(bool) => setOptionOpen(bool)}
                    overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                  >

                    <span onClick={e => { e.preventDefault(); e.stopPropagation() }} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                  </Dropdown>
                  : ''
              }

              <div className="d-flex flex-column justify-content-center flow-details-container">
                <h2 className="section-heading block-custom-ellipsis one-line-ellipsis">
                  {data?.flow_name_only
                    ? data?.flow_name_only
                    : data.allData.name}
                </h2>

                <div className="description-data">
                  {/* {data?.description} */}

                  <p className="fl-desc small custom-ellipsis two-line-ellipsis">{
                    data?.description
                    // data.allData.description !== ""
                    // ? `${data.allData?.description?.slice(0, 300)}...`
                    // : "No description added for this flows."
                  }</p>
                  <div className="flow-meta-det">
                    <ul className="landingCard-profile-list">
                      <li className="landingCard-profile-listitem d-flex align-items-center justify-content-between">
                        <div style={{ flexBasis: 'calc(100% - 3.75rem)' }}>
                          <UPDATED_BY width="15 " height="15" />
                          <span className="label label-gray ml-2">{'Last Updated'} </span>
                          <Tooltip placement="topLeft" title={getRelativeTime(new Date(data?.allData?.updated_on), undefined)}>
                            <span className="paragraph ml-1 ant-tooltip-open">
                              {/* <Tooltip placement="top" title={getRelativeTime(data?.allData?.updated_on, undefined)}> */}
                              {getRelativeTime(data?.allData?.updated_on)}
                              {/* </Tooltip> */}

                            </span>
                          </Tooltip>
                        </div>

                      </li>
                      <li className="landingCard-profile-listitem mt-1">
                        <div className="d-flex w-100" style={{}}>
                          <USER width="22" height="15" />
                          <span className="label label-gray ml-2" style={{ flexBasis: '5rem' }}>{'Updated By'} </span>
                          {/* <Tooltip placement="topLeft" title={data?.allData?.updated_by_user?.email}> */}
                          <span className="paragraph d-block text-with-ellipsis ant-tooltip-open label-color">
                            <span>{data?.updated_by}</span>

                            {/* {data?.allData?.updated_by_user?.email} */}

                          </span>
                          {/* </Tooltip> */}
                        </div>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Card >

    </div >
  );
}


export default FlowsCardView;