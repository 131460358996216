import { Input, Select, Switch, Tooltip } from 'antd'
import _ from 'lodash'
import React, { useState } from 'react'
import SingleSelection from '../../../Common/dropDown/SingleSelection'
import { businessTermStatusOptions, columnNameRegex, highlightText, showOneLineError } from '../../common/helperFunctions'
import { SampleValuesModal } from './SampleValuesModal'
import { ICON_CLOSE, ICON_EDIT, ICON_INFO_FILLED, ICON_PLUS } from '../../../Common/newIconSource'
import { ClassificationsModal } from './ClassificationsModal'
import { NotesView } from './NotesView'
import { useDispatch, useSelector } from 'react-redux'
import { updateBusinessTermStatus } from '../../../../store/modules/dataDictionary/action'
import DataStewardsView from './DataStewardsView'
import ContainsPII from './ContainsPII'
import { AdditionalDetailsView } from './AdditionalDetailsView'

const statusOptions = [...businessTermStatusOptions]

export const BusinessTermView = ({
    fieldValues = {}, 
    setFieldValues = () => {},
    categories = [],
    classifications = [],
    isEdit = true,
    usersList = [],
    usersLoading = false,
    selectedOwners = [],
    setSelectedOwners = () => {},
    selectedStewards = [],
    setSelectedStewards = () => {},
    initialNote,
    initialData,
}) => {

    const dispatch = useDispatch()
    
    const [openSampleModal, setSampleModal] = useState(false)
    const [openClassificationModal, setClassificationModal] = useState(false)

    const [status, setStatus] = useState(statusOptions[1]?.value)
    const [statusLoading, setStatusLoading] = useState(false)
    const [statusError, setStatusError] = useState(undefined)
    const [statusUpdatedBy, setStatusUpdatedBy] = useState(undefined)

    const [BTFocus, setBTFocus] = useState(false)

    const activeClassifier = React.useRef(null)

    const userDetails = useSelector(
        (state) => state.UserReducer?.user?.userDetails
    );

    const stewards = fieldValues?.owner_steward_detail?.steward
    const owners = fieldValues?.owner_steward_detail?.owner

    React.useEffect(() => {
        setStatus(fieldValues?.status?.toLowerCase())
        setStatusUpdatedBy(fieldValues?.status_changed_by_user)
    }, [fieldValues])

    const classifiers = React.useMemo(() => {
        return fieldValues?.classification ?? []
    }, [fieldValues])
    
    const updateStatus = (newStatus) => {
        handleInput('status', newStatus?.toUpperCase())
        setStatus(newStatus)

        setTimeout(() => {
            handleInput(
                'status_changed_by_user', 
                {
                    first_name: userDetails.first_name, 
                    last_name: userDetails.last_name, 
                    id: userDetails.id
                }
            )
        }, 100)


        // const prevStatus = status
        // const prevStatusDet = statusUpdatedBy
        // setStatusError(undefined)
        // setStatusLoading(true)
        // setStatus(newStatus)
        // dispatch(updateBusinessTermStatus(fieldValues?.id, {status: newStatus?.toUpperCase()}))
        // .then(() => {
        //     setStatusLoading(false)
        //     setStatusUpdatedBy(userDetails)
        // })
        // .catch(err => {
        //     setStatus(prevStatus)
        //     setStatusUpdatedBy(prevStatusDet)
        //     setStatusError(showOneLineError(err?.message))
        //     setStatusLoading(false)
        // })
    }

    const handleInput = (key, value) => {
        if(key === 'business_term') {
            if(_.isEmpty(value?.trim())) value = ''
            if(!columnNameRegex.test(value) && !_.isEmpty(value?.trim())) return
            setFieldValues(prev=>(
                {
                    ...prev,
                    [key]:value?.toLowerCase()
                }
            ))
        }
        else
        setFieldValues(prev=>(
            {
                ...prev,
                [key]:value
            }
        ))
    }

    const removeClassifier = (c_id) => {
        setFieldValues(prev => {
            prev = {
                ...prev,
                classification: prev?.classification?.filter(c => c?.id !== c_id)
            }
            return prev
        })
    }

    const handlePII = (check) => {
        setFieldValues(prev => ({...prev, pii: check}))
    }

    const userOptions = React.useMemo(() => {
        let list = usersList?.map(v => ({label: `${v?.name} (${v?.email})`, value: v?.value}))
        return list
    }, [usersList])

    const currentCategoryName = categories?.find(c => c?.value === fieldValues?.category)?.Name ?? ''
    
    const fields = {
        businessTerm: <>
            <div className='d-flex align-items-center'>
                <label className='m-0 grey-color label first-element-col'>Name
                    {
                        !isEdit
                        ?
                        <Tooltip placement={'right'} title={'Only lowercase letters, numbers and underscore are allowed. Display Name should only start with a letter'}>
                            <span className='ml-1 mb-1'><ICON_INFO_FILLED width='11' height='11' color='#828282' /></span>
                        </Tooltip>
                        :   ''
                    }
                </label>
                {
                    !isEdit
                    ?
                    <>
                        <Input
                            required
                            type="text"
                            value={fieldValues?.business_term}
                            onChange={(e) => handleInput('business_term', e.target.value)}
                            className={`custom-input-field text-uppercase`}
                            onFocus={() => setBTFocus(true)}
                        />
                    </>
                    :
                    <span 
                        title={fieldValues?.business_term?.toUpperCase()} 
                        className='dict-bizterm-name text-uppercase paragraph dict-color-dark'>
                            {fieldValues?.business_term}
                    </span>
                }
            </div>
        </>,
        oldBusinessTerm: <>
            <div className='d-flex align-items-center h-100'>
                <>
                    <label className='m-0 grey-color label' style={{width: '6.25rem', flex: '0 0 6.25rem'}}>Previous Name</label>
                    <Tooltip title={initialData?.business_term?.toUpperCase()} placement='bottomLeft'>
                        <label className='mb-0 text-black-50 dict-color-dark text-with-ellipsis'>
                            {initialData?.business_term?.toUpperCase()}
                        </label> 
                    </Tooltip>
                </>
            </div>
        </>,
        status: <>
            <div className='d-flex'>
                <label className='m-0 grey-color label first-element-col'>Status</label>
                <div>

                {
                    !isEdit
                    ?
                    <>
                        <Select
                            showSearch={false}
                            optionFilterProp="children"
                            value={status}
                            loading={statusLoading}
                            disabled={statusLoading}
                            status={statusError ? 'error' : ''}
                            onChange={(val) => {
                                updateStatus(val)
                            }}
                            filterOption={(input, option) =>
                                option.value?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.value.toString().toLowerCase().localeCompare(optionB.value.toString().toLowerCase())
                            }
                            >
                            {
                                statusOptions?.map((ele, index) => (
                                    <Select.Option key={index} value={ele.value ? ele.value : ele.Name}>
                                        <div className='align-items-center option-select-customized d-flex w-100'>
                                            <span className='sphere' style={{background: ele?.color}}></span>
                                            <span className='small ml-2 dict-color-dark'>{ele.Name}</span>
                                        </div>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                        {statusError ?? ''}
                    </>
                    :
                    <span className='d-flex align-items-center'>
                        <span className='sphere' style={{background: statusOptions?.find(s => s.value === status)?.color}} ></span>
                        <span className='small ml-2 dict-color-dark'>{statusOptions?.find(s => s.value === status)?.Name}</span>
                    </span>

                }

                {
                    // statusUpdatedBy && statusUpdatedBy?.id !== 0
                    // ?
                    <div className='my-1'>
                        {
                            statusUpdatedBy && statusUpdatedBy?.id !== 0
                            ?   highlightText(`${msg[status?.toLowerCase()]} by ${statusUpdatedBy?.first_name} ${statusUpdatedBy?.last_name}`)
                            :   highlightText(`${msg[status?.toLowerCase()]} by Anonymous`)
                        }
                    </div>
                    // :   ''
                }
                </div>
            </div>
        </>,
        pii: <>
            <div className='d-flex align-items-center'>
                <label className='m-0 grey-color label first-element-col' style={{width: '6.25rem'}}>PII</label>
                {
                    isEdit 
                    ?
                    fieldValues?.pii ? <ContainsPII/> : <div className='text-black-50'>-</div>
                    :
                    <span>
                        <Switch
                            className='custom-switch-btn danger'
                            checkedChildren="Yes" unCheckedChildren="No" 
                            checked={fieldValues?.pii}
                            onChange={handlePII}
                            disabled={isEdit}
                        />
                    </span>
                }
            </div>
        </>,
        category : <>
            <div className='d-flex align-items-center' id='data-dict-select-category'>
                <label className='m-0 grey-color label first-element-col'>Category</label>
                <div className='d-flex align-items-center w-100'>
                {
                    !isEdit
                    ?   <>
                            <SingleSelection
                                props={{
                                    placeholder: "Select Category",
                                    dropDownValue: fieldValues?.category,
                                    onClickEvent: (value) => {
                                        handleInput('category', value)
                                    },
                                    optionList: categories,
                                    isDisable: isEdit,
                                    selectClassName: 'category-ddl',
                                    optionClassName: 'category-ddl'
                                }}
                            />
                            <Tooltip title="Clear Category" placement='right'>
                                <span 
                                    className='position-relative add-circle-btn ml-2'
                                    onClick={() => handleInput('category', '')}
                                    >
                                    <ICON_CLOSE/>
                                </span>
                            </Tooltip>
                        </> 
                    :   
                        fieldValues?.category === ''
                        ?   <label className="mb-0 text-black-50">N/A</label>
                        :
                        <Tooltip placement='topLeft' title={currentCategoryName}>
                            <label style={{ lineHeight: '1.4', maxWidth: '5.625rem', width: 'auto' }} className='text-with-ellipsis d-block mb-0 categoryTag text-uppercase'>
                                {currentCategoryName}
                                {/* {fieldValues?.category?.includes('::')?fieldValues?.category.split("::")[1]:fieldValues?.category} */}
                            </label>
                        </Tooltip>
                }
                </div>
            </div>
        </>,
        dataOwners: <>
            <div className={`d-flex ${isEdit ? 'align-items-center' : ''}`}>
                <label className='m-0 grey-color label first-element-col'>Data Owners</label>
                {
                    isEdit
                    ?
                    <span>
                        {
                            owners?.length === 0
                            ?
                            <p className='mb-0 text-black-50 paragraph'>No Owners</p>
                            :
                            <DataStewardsView data={owners} maxCount={2} />
                        }
                    </span>
                    :
                    <span className='w-100'>
                        <Select
                            loading={usersLoading}
                            disabled={usersLoading}
                            className='dict-owners-steward-mselect'
                            size={'middle'}
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Choose Owners"
                            options={userOptions}
                            value={selectedOwners}
                            onChange={(ids) => {
                                setSelectedOwners([...ids])
                            }}
                            filterOption={(input, option) => {
                                const name = userOptions?.find(d => d?.value === option?.value)?.label ?? ''
                                return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                            }
                            }
                            filterSort={(optionA, optionB) => {
                                    const nmA = userOptions?.find(d => d?.value === optionA?.value)?.label ?? ''
                                    const nmB = userOptions?.find(d => d?.value === optionB?.value)?.label ?? ''
                                    return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                                }
                            }
                        />
                    </span>
                }
            </div>
        </>,
        classifiers: <>
            <div className='d-flex align-items-center h-100'>
                <label className='m-0 grey-color label first-element-col'>Classifiers</label>
                <div>
                {
                    !classifiers || classifiers?.length === 0
                    ? <label className="mb-0 text-black-50 pr-2 classifier-na">N/A</label>
                    :
                    classifiers?.map((tag, index) => {
                        // return index < 2 ? <div key={index} className={`classificationTag cont font-w-600 mr-2`}>
                        return <div key={index} className={`classificationTag cont font-w-600`} style={{maxWidth: 90}}>

                            <Tooltip placement="topLeft" title={tag?.name ? tag?.name?.toUpperCase() : tag}>
                                <div className="text-with-ellipsis">
                                    {`${tag?.name ? tag?.name?.toUpperCase() : tag?.toUpperCase()}`}
                                    
                                    {/* {
                                        !isEdit
                                        ?
                                        <Tooltip placement="bottom" title={'Click to remove'}>
                                            <span className='ml-1 dict-rem-class' onClick={() => removeClassifier(tag?.id)}><ICON_CLOSE height='8' width='8' /></span>
                                        </Tooltip>
                                        :   ''
                                    } */}
                                </div>
                            </Tooltip>

                        </div> 
                        // : <></>
                    })
                }
                {/* {   classifiers?.length > 2 
                    ? <div className="classificationTag font-w-600 mx-1 text-center px-2">
                        <Tooltip title={classifiers?.map(v => v?.name)?.slice(2)?.join(", ")}> 
                            <span className="text-with-ellipsis">{`+${classifiers.length - 2}`}</span>
                        </Tooltip>
                    </div> 
                    : null
                } */}
                {
                    !isEdit
                    ?
                    <>
                        <Tooltip placement='top' title={classifiers?.length === 0 ? 'Add Classifier' : 'Change'}>
                            <span style={{bottom: -2}} 
                                className='position-relative add-circle-btn ml-1'
                                onClick={() => {
                                    activeClassifier.current = fieldValues?.classification?.[0] ?? null
                                    setClassificationModal(true)
                                }}
                                >
                                    {
                                        classifiers?.length === 0
                                        ? <ICON_PLUS/>
                                        :
                                        <ICON_EDIT/>
                                    }
                            </span>
                        </Tooltip>
                        {
                            classifiers?.length > 0
                            ?
                            <Tooltip placement='top' title={'Remove Classifier'}>
                                <span style={{bottom: -2}} 
                                    className='position-relative add-circle-btn ml-2'
                                    onClick={() => {
                                        removeClassifier(classifiers?.[0]?.id)
                                    }}
                                    >
                                        {
                                            <ICON_CLOSE/>
                                        }
                                </span>
                            </Tooltip>
                            :   ''
                        }
                    </>
                    :   ''
                }
                </div>
            </div>
        </>,
        dataStewards: <>
            <div className={`d-flex ${isEdit ? 'align-items-center' : ''}`}>
                <label className='m-0 grey-color label first-element-col'>Data Stewards</label>
                {
                    isEdit
                    ?
                    <span>
                        {
                            stewards?.length === 0
                            ?
                            <p className='mb-0 text-black-50 paragraph'>No Stewards</p>
                            :
                            <DataStewardsView data={stewards} maxCount={2} />
                        }
                    </span>
                    :
                    <span className='w-100'>
                        <Select
                            loading={usersLoading}
                            disabled={usersLoading}
                            className='dict-owners-steward-mselect'
                            size='middle'
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Choose Stewards"
                            options={userOptions}
                            value={selectedStewards}
                            onChange={(ids) => {
                                setSelectedStewards([...ids])
                            }}
                            filterOption={(input, option) => {
                                const name = userOptions?.find(d => d?.value === option?.value)?.label ?? ''
                                return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                            }
                            }
                            filterSort={(optionA, optionB) => {
                                    const nmA = userOptions?.find(d => d?.value === optionA?.value)?.label ?? ''
                                    const nmB = userOptions?.find(d => d?.value === optionB?.value)?.label ?? ''
                                    return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                                }
                            }
                        />
                    </span>
                }
            </div>
        </>,
        description: <>
            <div className='d-flex'>
                <label className='m-0 grey-color label first-element-col'>Description</label>
                <div style={{flex: '0 0 calc(100% - 5.9375rem)'}}>
                    {
                        !isEdit
                        ?
                        <Input.TextArea
                            id='biz-desc-input'
                            type="text"
                            value={fieldValues?.description}
                            className='paragraph'
                            rows={4}
                            onChange={(e) => handleInput('description', e.target.value)}
                            readOnly={isEdit}
                            style={{lineHeight: '1.4'}}
                        />
                        :
                        <span title={fieldValues?.description} className='paragraph dictbiz-description' style={{marginBottom:"0.125rem"}}>
                            {fieldValues?.description}
                        </span>
                    }
                </div>
            </div>
        </>,
        note: <>
            <div>
                <NotesView
                    fieldValues={initialNote}
                    setFieldValues={setFieldValues}
                    isEdit={isEdit} />
            </div>
        </>
    }

    return (
        <div>
            <div className='biz-term-primary-info pl-2 pr-3 py-3'>
                {
                    // isEdit ?
                    // <>
                        <div className='d-flex position-relative'>
                            <div style={{flex: '0 0 58%'}}>
                                {fields.businessTerm}
                            </div>
                            {
                                !isEdit ? 
                                <div className='ml-4'>
                                    {fields.pii}
                                </div>
                                :
                                fieldValues?.pii ? 
                                <div className='dict-pii'>
                                    <ContainsPII/>
                                </div>  : ''
                            }
                        </div>
                    // </>
                    // :
                    // <>
                    //     <div>
                    //         {fields.businessTerm}
                    //     </div>
                    // </>
                }
                {
                    BTFocus && initialData?.business_term?.toLowerCase() !== fieldValues?.business_term?.toLowerCase() ? 
                    <div>
                        {fields.oldBusinessTerm}
                    </div>
                    :   ''
                }
                <div>
                    {fields.status}
                </div>
                <div style={{minHeight: isEdit ? '5.25rem' : '4rem'}}>
                    {fields.description}
                </div>
                <div className='dict-title-strip mx-n2'>
                    <div className='d-flex align-items-center w-100 justify-content-between'>
                        <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                            Governance
                        </label>
                    </div>
                </div>
                {/* <div className='dict-f-category'>
                    {fields.category}
                </div> */}
                <div className={`dict-f-classifiers ${isEdit ? '' : 'edit'}`}>
                    {fields.classifiers}
                </div>
                {
                    isEdit ?
                    <>
                        <div className='d-flex dict-handle-avt'>
                            <div style={{flex: '0 0 58%'}}>{fields.dataOwners}</div>          
                            <div className={`aside-ele ${isEdit ? 'edit' : ''} ml-auto dat-stew`}>{fields.dataStewards}</div>          
                        </div>
                    </>
                    :
                    <>
                        <div>
                            {fields.dataOwners}
                        </div>
                        <div>
                            {fields.dataStewards}
                        </div>
                    </>
                }
                <div className='mx-n2'>
                    {/* <div className='dict-title-strip mb-2'>
                        <div className='d-flex align-items-center w-100 justify-content-between'>
                            <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                                Additional Details
                            </label>
                        </div>
                    </div> */}
                    <div className='dict-addfield-wrap'>
                        <AdditionalDetailsView
                            fieldValues={fieldValues}
                            setFieldValues={setFieldValues}
                            isEdit={isEdit}
                            hideHeaderText={true}
                            renderInTable={false}
                        />
                    </div>
                </div>
                {/* <div>
                    {fields.note}
                </div> */}
            </div>

            <SampleValuesModal
                openModal={openSampleModal}
                setOpenModal={setSampleModal}
            />
            <ClassificationsModal
                openModal={openClassificationModal}
                setOpenModal={setClassificationModal}
                classifications={classifications}
                fieldValues={fieldValues}
                setFieldValues={setFieldValues}
                isEdit={isEdit}
                activeClassifier={activeClassifier.current}
            />
        </div>
    )
}

const msg = {
    active: 'Activated',
    archived: 'Archived',
    pending: 'Updated'
}