import { 
    SET_CONNECTION_SELECTEDCONN, 
    SET_CONNECTION_DYNAMICFIELDS,
    SET_CONNECTION_DATALIST,
    SET_CONNECTION_DEFAULT,
    SET_CONNECTION_PAGE_DEFAULT,
    OPEN_MODEL_CONNECTION,
    SET_CONNECTION_OPTIONALFIELDS
 } from './connectionActionTypes'


 const selectedConnCache = sessionStorage.getItem("selectedConn") ? JSON.parse(sessionStorage.getItem("selectedConn")): [];


const initialState = {
    selectedConn : selectedConnCache,
    dynamicfields : [],
    optionalFields : [],
    dataList: [],
    openModel: '',
}

const ConnectionReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_CONNECTION_SELECTEDCONN:
            return {
                ...state,
                selectedConn: payload
            }
           
        case SET_CONNECTION_PAGE_DEFAULT:
            return  initialState ;

        case SET_CONNECTION_DYNAMICFIELDS:
            return {
                ...state,
                dynamicfields: payload
            }

        case SET_CONNECTION_OPTIONALFIELDS:
            return {
                ...state,
                optionalFields: payload
            }
           
        case SET_CONNECTION_DATALIST:
            return {
                ...state,
                dataList: [...payload]
            }

        case OPEN_MODEL_CONNECTION:
            return {
                ...state,
                openModel: payload
            }
           
        case SET_CONNECTION_DEFAULT:
            sessionStorage.removeItem("selectedConn")
            initialState.selectedConn=[];
            return initialState
           
        default:
            return state;
    }
}

export default ConnectionReducer;