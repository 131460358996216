import React, { useEffect, useState } from "react";
import { CROWN, DESIGNATION, EMAIL, EDIT, RESEND_EMAIL, PERSON, SMALLUSERS, UPDATED_BY } from "../../../../Common/iconSource";
import { getRelativeTime } from "../../../common/helperFunctions";
import { Literals } from "../../../common/literals";
import StatusTab from "../../common/StatusTab";
import InfoItem from "./InfoItem";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../../../Common/button/Buttons";
import { resendEmail } from "../../../../../store/modules/UserManagement/users/usersActions";
import { ICON_LOADING_CIRCLE, ICON_SAVE, ICON_SUPERUSER } from "../../../../Common/newIconSource";
import { Tooltip } from "antd";
import CommonModal from "../../../../Common/CommonModal/CommonModal";
import AutoCreatePassword from "./AutoCreatePassword";
import { encryptMessage } from "../../../../../store/userAuthentication/userActions";

const UserGeneralDetails = ({ userDetails }) => {
  const dispatch = useDispatch();

  const [userInfoItems, setUserInfoItems] = useState([]);
  const [openResetModal, setOpenResetModal] = React.useState(false)
  const [newPassword, setNewPassword] = React.useState('')
  const [saveBtnVisibility, setSaveBtnVisibility] = React.useState(false)
  const [isAutoCreate, setIsAutoCreate] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const CurrentUserDetails = useSelector(state => state.UserReducer.user.userDetails)

  let { id } = useParams();
  useEffect(() => {
    if (userDetails) {
      const infoItems = [
        {
          icon: <EMAIL width="18" />,
          label: Literals.EMAIL,
          value: userDetails['email']?.length > 30 ? userDetails['email']?.slice(0,30)+'...':userDetails['email'],
          fullValue:userDetails['email']
        },
        {
          icon: <DESIGNATION width="18" />,
          label: Literals.PERSONA,
          value: userDetails['designation'],
        },
        {
          icon: <SMALLUSERS />,
          label: Literals.CREATED_BY,
          value: userDetails?.created_by_user ? userDetails?.created_by_user['first_name'] + ' ' + userDetails?.created_by_user['last_name'] : 'N/A',
        }, {
          icon: <UPDATED_BY width="18" height="18" />,
          label: Literals.UPDATED_ON,
          value: userDetails['updated_on'] ? getRelativeTime(userDetails['updated_on']) : 'N/A',
        }
      ];

      setUserInfoItems(infoItems)

    }

  }, [userDetails]);

  const resetPasswordEmail = () => {
    setLoading(true)
    dispatch(resendEmail(
      { id: id, payload: { password: isAutoCreate ? null : newPassword } })
      // { id: id, payload: { password: isAutoCreate ? null : encryptMessage(newPassword) } })
    )
      .then(() => {
        setLoading(false)
        setOpenResetModal(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const getPasswordStatus = (status) => {
    setSaveBtnVisibility(status === 100)
  }

  const getAutoCreateStatus = (status) => {
    setIsAutoCreate(status)
  }

  return (
    <>
      <div className="user-general-details">

        {/* TO PREVENT AUTOFILL BY BROWSER */}
        <div style={{ position: 'fixed', left: -999 }}>
          <input type="text" />
          <input type="password" />
        </div>
        {/* TO PREVENT AUTOFILL BY BROWSER */}


        <div className="user-general-details-header">
          <p>
            <span>
              <PERSON />
            </span>
            <span className="text-capitalize">{`${userDetails?.first_name ?? ""} ${userDetails?.last_name ?? ""
              }`}</span>
            {userDetails?.is_superuser ? <Tooltip title="Super User" placement="bottom"><span className="cursor-pointer sup-user-ic"><ICON_SUPERUSER width="20" height="20" /></span></Tooltip> : null}
          </p>
          <div className="d-flex align-items-center">
            {
              CurrentUserDetails?.is_superuser
              ?
              <Buttons props={{
                buttonText: '', tooltip: 'Reset & Resend Invite', buttonClassName: "custom-btn-outline custom-btn resend-email mr-2", buttonEvent: (e) => {
                  e.stopPropagation(); e.preventDefault();
                  setOpenResetModal(true);
                },
                ImgSrc: () => <RESEND_EMAIL />,
                isDisable: !CurrentUserDetails?.is_superuser && CurrentUserDetails?.id !== userDetails?.id,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
              }} />
              : ''
            }
            <StatusTab
              withDot={true}
              type={userDetails?.is_verified ? "success" : "error"}
            >
              {userDetails?.is_verified ? "Active" : "Inactive"}
            </StatusTab>
          </div>
        </div>
        <div className="user-general-details-content">
          <div className="row">
            {userInfoItems.map((item) => (
              <div className="col-3">
                <InfoItem details={item} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <CommonModal
        open={openResetModal}
        setOpen={setOpenResetModal}
        header="Reset Password"
        size="md"
        body={
          <>
            <div className="user-reset-resend-wrap">
              <AutoCreatePassword
                password={newPassword}
                setPassword={setNewPassword}
                getPasswordStatus={getPasswordStatus}
                getAutoCreateStatus={getAutoCreateStatus}
              />
              <div className="d-flex justify-content-center pt-3 py-2">
                <Buttons
                  props={{
                    actionType: 'submit',
                    buttonText: "Save",
                    buttonClassName: "custom-btn-primary custom-btn btn-with-text pod-crt-save-btn",
                    buttonEvent: () => resetPasswordEmail(),
                    ImgSrc: () => loading ? <ICON_LOADING_CIRCLE /> : <ICON_SAVE />,
                    isDisable: loading || isAutoCreate ? !isAutoCreate : !saveBtnVisibility,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />
              </div>
            </div>
          </>
        }
        hideFooter
      />
    </>
  );
};

export default UserGeneralDetails;
