import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import {
  fetchData
} from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import {
  EDIT_DESCRIPTION, EDIT_SUMMARY_EDITOR, INSIGHT_CHART_STATE, RESET_SUMMARY_STATE, SELECT_INSIGHT_CHART_VIEW, SET_DOCUMENT_TABLE_DATA,
  SET_EDITOR_DATA,
  SET_EDITOR_DATA_COUNT, SET_EDITOR_HTML,
  SET_EDITOR_STATE, SET_INSIGHT_DATA, SET_INSIGHT_DATE, SET_PAGE_COUNT, SET_SUMMARY_PAGE_DEFAULT, SET_SUMMARY_POD_DATA, SET_SUMMARY_TIMELINE,
  SET_TIMELINE_COUNT, SET_UPLOADED_FILES, SET_UPLOAD_DOC_FLAG
} from "./summaryActionTypes";

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const editSummaryDescription = (value) => ({
  type: EDIT_DESCRIPTION,
  payload: value,
});

export const setSummaryPageDefault = () => {
  return {
    type: SET_SUMMARY_PAGE_DEFAULT,
  };
};

export const setPageCount = (payload) => {
  return {
    type: SET_PAGE_COUNT,
    payload: payload
  };
};

export const summaryPodDetails = ({ payload }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}pod_summary`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            setSummaryData(resp.data, dispatch);
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          if(err?.status !== 404){

            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

const setSummaryData = (data, dispatch) => {
  const podDataHeader = data.data_header;
  const podDataRecord = data.data_record[0].map((ele, i) => ({
    [podDataHeader[i]]: ele,
  }));
  dispatch(setSummaryPodData(podDataRecord));
};

export const SummaryPodDocuments = ({ payload }) => {
  return (dispatch) => {
    
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}pod_docs`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
            dispatch(setLoadingStatus(false));
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};
export const UpdateSummaryPodDocsV2=(payload)=>{
  return (dispatch) => {
    
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("PUT", `${BASE_URL}pod_docs/v2/${payload.id}/`,{...payload}))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
            dispatch(setLoadingStatus(false));
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };

}
export const DeletePodDoc=(payload)=>{
  return (dispatch) => {
    
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${BASE_URL}pod_docs/v2/${payload.id}/`))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
            dispatch(setLoadingStatus(false));
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };

}
export const SummaryPodDocumentsV2 = ({ payload }, showLoader = true) => {
  const searchParams = new URLSearchParams(document.location.search)
  const history_id=searchParams.get("history_id");
  const start_date=searchParams.get("start_date");
  const end_date=searchParams.get("end_date");
  const action=decodeURI(searchParams.get("action")??"")
  return (dispatch) => {
    if(showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}pod_docs/v2/?table_id=${payload.table_id}${history_id?`&history_id=${history_id}&start_date=${start_date}&end_date=${end_date}`:""}`))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
            if(showLoader) {
              dispatch(setLoadingStatus(false));
            }
          } else {
            emitToastNotification('error', resp.message);
            if(showLoader) {
              dispatch(setLoadingStatus(false));
            }
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          if(showLoader) {
            dispatch(setLoadingStatus(false));
          }
          reject(err);
        });
    });
  };
};

export const retreivePodDoc=({payload})=>{
  const searchParams = new URLSearchParams(document.location.search)
  const history_id=searchParams.get("history_id");
  const start_date=searchParams.get("start_date");
  const end_date=searchParams.get("end_date");
  return (dispatch) => {
    
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}pod_docs/v2/${payload.id}/${history_id?`&history_id=${history_id}&start_date=${start_date}&end_date=${end_date}`:""}`))
        .then((resp) => {
          if (resp.status === "success") {
          
            resolve(resp);
            dispatch(setLoadingStatus(false));
            const byteCharacters = atob(resp.data.file_content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray]);
            const href = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.download = resp.data.file_name;
            a.href = href;
            a.click();
            a.remove();
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };

}
export const downloadPodDocuments = ({ payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}pod_docs`, payload))
        .then((resp) => {
          if (resp && resp.status === 'success') {
            resolve(resp);
            dispatch(setLoadingStatus(false));
            const byteCharacters = atob(resp.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray]);
            const href = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.download = payload.file_name;
            a.href = href;
            a.click();
            a.remove();
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};

export const SummaryPodReadme = ({ payload }) => {
 
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}pod_readme`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};

export const SummaryPodReadmeV2 = ({ payload }, showLoader = true) => {
  const searchParams = new URLSearchParams(document.location.search)
  const history_id=searchParams.get("history_id");
  const start_date=searchParams.get("start_date");
  const end_date=searchParams.get("end_date");
  const action=decodeURI(searchParams.get("action")??"");

  return (dispatch) => {
    if(showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {

      
      dispatch(fetchData("GET", `${BASE_URL}pod_readme/v2/${payload.table_id}/${history_id?`?history_id=${history_id}&start_date=${start_date}&end_date=${end_date}`:""}`))
        .then((resp) => {
          if (resp.status === "success") {
            if(showLoader) {
              dispatch(setLoadingStatus(false));
            }
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            if(showLoader) {
              dispatch(setLoadingStatus(false));
            }
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          if(showLoader) {
            dispatch(setLoadingStatus(false));
          }
          reject(err);
        });
    });
  };
};
export const SaveSummaryPodReadmeV2 = ( payload ) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {

      
      dispatch(fetchData("POST", `${BASE_URL}pod_readme/v2/`,payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};
export const UpdateSummaryPodReadmeV2 = (payload ) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {

      
      dispatch(fetchData("PUT", `${BASE_URL}pod_readme/v2/${payload.table}/`,payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};



export const getPodInsights = ({ payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    dispatch(setInsightData([]));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}pod_summary`, payload))
        .then((resp) => {
          if (resp.status === "success" && resp.data.data_count > 0) {
            mapData(resp.data, dispatch, setInsightData);
            resolve(resp)
            dispatch(setLoadingStatus(false));
          } else if (resp.status === "success" && resp.data.data_count === 0) {
            emitToastNotification('info', "No insight data present");
            resolve(resp)
            dispatch(setLoadingStatus(false));
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};
export const getPodInsightsV2 = ({payload}) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}pod_summary/insight/?table_id=${payload.table_id}&granularity=${payload.granularity}&start_date=${payload.start_date}&end_date=${payload.end_date}`))
        .then((resp) => {
          
          if (resp.status === "success" && resp.data.count > 0) {
            mapData(resp.data, dispatch, setInsightData);
           
            resolve(resp)
            dispatch(setLoadingStatus(false));
          } else if (resp.status === "success" && resp.data.count === 0) {
            emitToastNotification('info', "No insight data present");
            resolve(resp)
            dispatch(setLoadingStatus(false));
          } else {
            emitToastNotification('error', resp.message);
            dispatch(setLoadingStatus(false));
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
          reject(err);
        });
    });
  };
};

export const getSummaryTimeline = ({ payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}pod_summary`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            dispatch(setSummaryTimelineData(resp.data));
            dispatch(setTimelineDataCount(resp.data.data_count));
            resolve(resp);
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};





export const getSummaryTimelineV2 = ({ payload }, onlyTimeline = false) => {

  let getTableId = payload?.table_id !== undefined && payload?.table_id.length ? '&table_id=' + payload?.table_id : ''
  let getUser = payload?.user !== undefined && payload?.user.trim().length ? '&user=' + payload?.user : ''
  let getTableName = payload?.table_name !== undefined && payload?.table_name.trim().length ? '&table_name=' + payload?.table_name : ''
  let getDomain = payload?.domain !== undefined && payload?.domain.trim().length ? '&domain=' + payload?.domain : ''
  let getStartDate = payload?.start_date !== undefined && payload?.start_date.trim().length ? '&start_date=' + payload?.start_date : ''
  let getEndDate = payload?.end_date !== undefined && payload?.end_date.trim().length ? '&end_date=' + payload?.end_date : ''
  let optionalQueries = getTableId + getUser + getTableName + getDomain + getStartDate + getEndDate
  return (dispatch) => {
    if(onlyTimeline) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}pod_summary/timeline/?limit=${Object.keys(payload).includes('limit') ? payload?.limit : '100'}&page=${payload.page !== undefined ? payload.page : 1}&action=${payload.action}${optionalQueries}`))
      .then((resp) => {
          if (resp.status === "success" && resp.message !== "No results found") {
            if(onlyTimeline) {
              dispatch(setLoadingStatus(false));
            }
            resolve(resp);
          }
          else if (resp.status === "success" && resp.message === "No results found") {
            if(onlyTimeline) {
              dispatch(setLoadingStatus(false));
            }
            resolve(resp);
          } else {
            if(onlyTimeline) {
              dispatch(setLoadingStatus(false));
            }
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};
export const killQuery = ({ payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${BASE_URL}pod_summary/kill/${payload.query_id}/`))
        .then((resp) => {
          if (resp.status === "success") {
           
            emitToastNotification('success', resp.message);
            dispatch(setLoadingStatus(false));
            
            resolve(resp);
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const uploadFile = (payload, onUploadProgress) => {
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}pod_docs/v2/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } else {
            emitToastNotification('error', resp.message);
            reject(resp)
          }
        })
        .catch((err) => {
          
          reject(err);
          emitToastNotification('error', err?.message);
        });
    });
  };
};

const mapData = (data, dispatch, action) => {
 
  dispatch(action(data.insights));
};

export const setSummaryTimelineData = (timeline) => ({
  type: SET_SUMMARY_TIMELINE,
  payload: timeline,
});

export const setUploadedFiles = (uploadedFiles) => ({
  type: SET_UPLOADED_FILES,
  payload: uploadedFiles,
})

export const setTimelineDataCount = (count) => ({
  type: SET_TIMELINE_COUNT,
  payload: count,
});

export const setSummaryPodData = (podData) => ({
  type: SET_SUMMARY_POD_DATA,
  payload: podData,
});

export const setDocumentTableData = (documentTableData) => ({
  type: SET_DOCUMENT_TABLE_DATA,
  payload: documentTableData,
});

export const setEditorData = (editorData) => ({
  type: SET_EDITOR_DATA,
  payload: editorData,
});

export const setEditorDataCount = (count) => ({
  type: SET_EDITOR_DATA_COUNT,
  payload: count,
});

export const setInsightData = (insightData) => ({
  type: SET_INSIGHT_DATA,
  payload: insightData,
});

export const setInsightDate = (startDate, endDate) => ({
  type: SET_INSIGHT_DATE,
  payload: { startDate, endDate },
});

export const setInsightChartState = (state) => ({
  type: INSIGHT_CHART_STATE,
  payload: state,
});

export const selectInsightChartView = (view) => ({
  type: SELECT_INSIGHT_CHART_VIEW,
  payload: view,
});

export const setUploadDocFlag = (value) => ({
  type: SET_UPLOAD_DOC_FLAG,
  payload: value,
});

export const editSummaryEditor = (value) => ({
  type: EDIT_SUMMARY_EDITOR,
  payload: value,
});

export const setEditorHtml = (html) => ({
  type: SET_EDITOR_HTML,
  payload: html,
});
export const setEditorState = (state) => ({
  type: SET_EDITOR_STATE,
  payload: state,
});

export const resetSummaryReduxState = () => ({
  type: RESET_SUMMARY_STATE,
});


