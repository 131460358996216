import _, { cloneDeep, isObject } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'
import { v4 as uuidv4 } from 'uuid'
import useDebounce from '../../../../../customHooks/useDebounce'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import { fetchDatabaseSchemaList } from '../../../../../store/actions'
import { fetchPreviewTable } from '../../../../../store/modules/common/VirtualDataTable/action'
import { updateFlowsElements } from '../../../../../store/modules/flows/flowsActions'
import { setTargetTableDetails } from '../../../../../store/modules/flows/NewFlowsRedux/targetTransformer/targetTransformer'
import Buttons from '../../../../Common/button/Buttons'
import DataGridTable from '../../../../Common/dataGridTable/DataGridTable'
import SingleSelection from '../../../../Common/dropDown/SingleSelection'
import { EXITFULLSCREEN, FULLSCREEN, NOTEINFO, PLAY, TABLEICON } from '../../../../Common/iconSource'

import { getObjectLength, podNameRegex, preventSpecialCharsRegex, showSimpleLoading } from '../../../common/helperFunctions'
import { Literals } from '../../../common/literals'
import SourceTypeImage from '../../../common/sourceTypeImage'
import '../../Newflow.scss';
import S3schemaTree from './S3schemaTree'
import SchemaTree from './SchemaTree'
import TargetColumnSchema from './TargetColumnSchema';
import { useParams } from 'react-router-dom';
import { checkOutcomeColumnPresent, getInputTransformerDetails } from '../commonFunctions/FlowsHelperFunction';
import { NoTableSelected } from '../../../flows/transformers/target/NoTableSelected';
import LowerFlowsSection from '../../createFlows/LowerFlowsSection';
import { setQueryKillFlag } from '../../../../../store/modules/common/DataGridTable/dataGridTableRedux';
import SqlEditor from '../../../dataCatalogue/preview/components/SqlEditor';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';
import S3DirectoryTree from './S3DirectoryTree';
import SchemaTreeMultiSelect from './SchemaTreeMultiSelect';
import NoResultsFound from '../../createFlows/NoResultsFound';
import DataTabs from '../../../../Common/dataTabs/DataTabs';
import { calc } from 'antd/es/theme/internal';

function TargetConnectionModalStep2({ NewFlowsRedux, setisrunDisabled, isExecutePermission, selectedMode = 'overwrite', setNewFlowsRedux, steps, onClose, connectionType }) {
    let connectionDetails = useSelector(state => state.LabTransformer.TargetTransformer.connectionDetails)
    const currentTransformer = useSelector(state => state.Lab.currentTransformer)

    const flowsElements = useSelector(state => state.Lab.flowsElements)
    const targetTableDetails = useSelector(state => state.LabTransformer?.TargetTransformer?.targetTableDetails)
    const podData = useSelector((state) => state.LabTransformer?.TargetTransformer?.podCreationData)

    const store = useStore();
    const { flowId } = useParams()
    const [connectionName, setconnectionName] = useState('')
    const [uniqUUID, setUniqUUID] = useState('')

    const [targetTableData, settargetTableData] = useState({})
    const [selectedSchema, setselectedSchema] = useState('')
    const [inputTableData, setinputTableData] = useState({})
    const [toggleValue, settoggleValue] = useState('Existing')
    const [schemaDropdownValue, setschemaDropdownValue] = useState([])
    const [newTableName, setnewTableName] = useState('')
    const [schemaTableName, setschemaTableName] = useState('')
    const [columnList, setcolumnList] = useState([])
    const [treeData, setTreeData] = useState([])
    const [directorytreeData, setdirectorytreeData] = useState([])
    // const [explorers3Tree, setexplorers3Tree] = useState([])
    const [selectedExplorerFolder, setselectedExplorerFolder] = useState({})
    const [showTableNameExistError, setshowTableNameExistError] = useState(false)


    const [activeTabKey, setactiveTabKey] = useState('input_table');
    const [getTableData, setgetTableData] = useState(false);
    const [showloader, setshowloader] = useState(false);
    const [tableData, settableData] = useState({});
    const [isPreviewDataLoading, setIsPreviewDataLoading] = useState(false);

    const [Query, setQuery] = useState("");
    const [expandTable, setexpandTable] = useState(false);
    const [firstTimeset, setfirstTimeset] = useState(false);

    const [sqlTables, setsqlTables] = useState([]);

    const [tabArray, settabArray] = useState([{
        key: 'input_table',
        tabTitle: 'Input Table',
        tabContent: null,
        tableResult: {},
        podName: '',
        schemaName: '',
        connectionName: '',
        closable: false,
        bookmark: false,
    }]);

    const executeQuery = () => {

        let id = uuidv4();
        setUniqUUID(id)
        let payload = {
            connectionName: connectionName,
            uuid: id,
            table_name: '',
            page: 0,
            do_count: false,
            sort_body: [],
            filter_body: [],
            table_ids: [],
            query: Query,
        }
        setinputTableData({})
        settableData({})

        setshowloader(true);


        if (payload?.uuid) {
            store.dispatch(fetchPreviewTable(payload, false)).then((response) => {
                if (response?.status === 'success' && response?.data?.result) {
                    let responseData = response.data.result;
                    let findInputIndex = tabArray.findIndex(item => item.key?.toString() === 'result')

                    if (responseData && responseData !== null) {

                        let cloneTabs = cloneDeep(tabArray)
                        if (findInputIndex > -1) {
                            cloneTabs[findInputIndex].tableResult = responseData;
                            settabArray(cloneTabs)
                        } else {
                            cloneTabs.unshift({
                                key: 'result',
                                tabTitle: 'Result',
                                tabContent: null,
                                tableResult: responseData,
                                podName: 'Result',
                                closable: false,
                                bookmark: false,
                            })
                            settabArray(cloneTabs)
                        }
                        settableData(responseData)
                        setshowloader(false);
                        setactiveTabKey('result')

                        setisrunDisabled(false)

                    }
                }
            }).catch((e) => {
                let findInputIndex = tabArray.findIndex(item => item.key?.toString() === 'result')

                let cloneTabs = cloneDeep(tabArray)
                if (findInputIndex > -1) {
                    cloneTabs[findInputIndex].tableResult = {};
                    settabArray(cloneTabs)
                } else {
                    cloneTabs.unshift({
                        key: 'result',
                        tabTitle: 'Result',
                        tabContent: null,
                        tableResult: {},
                        podName: 'Result',
                        closable: false,
                        bookmark: false,
                    })
                    settabArray(cloneTabs)
                }
                settableData({})
                setshowloader(false);
                setactiveTabKey('result')
            })
        }
        setexpandTable(false)
    };

    const setQueryvalue = (query) => {
        if (query) {
            setQuery(query)
            setisrunDisabled(false)
            let cloneDeepFlowsElements = {}
            if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes.length) {
                cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)

            } else {
                cloneDeepFlowsElements = cloneDeep(flowsElements)

            }

            let nodes = cloneDeepFlowsElements?.nodes.map((item) => {
                if (item.id === currentTransformer.id) {
                    item.content.editorQuery = query;
                }
                return item;
            });
            cloneDeepFlowsElements['nodes'] = nodes;
            setNewFlowsRedux(cloneDeepFlowsElements);
        }
    };


    const CreateSqlTableDetails = (
        columns = [],
        transformerId = "",
        transformerType,
        transformerName
    ) => {
        let sqlTable = [...sqlTables];

        let GlobalConnectionCache = localStorage.getItem("GlobalConnectionCache");
        if (!transformerType.includes("datasource")) {
            sqlTable.push({
                name: GlobalConnectionCache
                    ? `${GlobalConnectionCache}.flow_${flowId}.${transformerName}`
                    : `flow_${flowId}.${transformerName}`,
                description: "",
                type: "Table",
                tableName: transformerName,
            });
        } else {
            sqlTable.push({
                name: transformerName,
                description: "",
                type: "Table",
                tableName: "",
            });
        }



        if (columns && columns.length) {
            columns.forEach((element) => {
                if (isObject(element)) {
                    if (
                        transformerType.includes("datasource")
                    ) {
                        sqlTable.push({
                            name: `${transformerName?.split('.')[1]}.${element.Name}`,
                            description: "",
                            type: "Column",
                            tableName: transformerName,
                        });
                    } else {
                        sqlTable.push({
                            name: `${transformerName}.${element.Name}`,
                            description: "",
                            type: "Column",
                            tableName: transformerName,
                        });
                    }
                } else {
                    sqlTable.push({
                        name: `${transformerName}.${element}`,
                        description: "",
                        type: "Column",
                        tableName: transformerName,
                    });
                }

            });
        }
        if (sqlTable.length) {

            setsqlTables([...sqlTable]);
        } else {
            setsqlTables([]);

        }
    };


    const debounceTerm = useDebounce(newTableName, 500)

    useCallback(() => {

        if (toggleValue === 'New') {
            let tableList = targetTableDetails.connnectionTableList
            let isTransformerNameExist = false
            if (tableList && tableList.length) {
                tableList.forEach((ele) => {
                    if (ele[0] === debounceTerm) {
                        isTransformerNameExist = true
                    }
                })
                if (isTransformerNameExist) {
                    setshowTableNameExistError(true)

                } else {
                    setshowTableNameExistError(false)

                }
            }
            let tableDetails = { ...targetTableDetails }

            if (toggleValue === 'New') {
                tableDetails.tableName = debounceTerm
            }

            tableDetails.typeOfTable = toggleValue

            let cloneDeepFlowsElements = {}
            if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes.length) {
                cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)

            } else {
                cloneDeepFlowsElements = cloneDeep(flowsElements)

            }
            cloneDeepFlowsElements['nodes'] = cloneDeepFlowsElements?.nodes?.map(item => {
                if (item.id === currentTransformer.id) {

                    let newContent = {
                        ...item.content,
                        targetTableDetails: tableDetails,
                        connectionDetails: connectionDetails,
                        treeData: treeData,
                        // directorytreeData: directorytreeData,
                        mode: selectedMode,
                    }
                    item.content = newContent
                }
                return item

            })

            setNewFlowsRedux(cloneDeepFlowsElements)

        }
    }, [debounceTerm])


    useEffect(() => {
        let newcurrentTransformer;
        if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes?.length) {

            newcurrentTransformer = NewFlowsRedux?.nodes.find((item) => item.id === currentTransformer.id)
        }
        else {
            newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

        }
        let transformerContent = newcurrentTransformer?.content;

        if (connectionDetails?.connection_name === transformerContent?.connectionDetails?.connection_name) {

            if (transformerContent?.treeData) {
                setTreeData(transformerContent?.treeData)
            }

            if (transformerContent?.targetTableDetails?.directorytreeData) {
                setdirectorytreeData(transformerContent?.targetTableDetails?.directorytreeData)
            }
            if (transformerContent?.targetTableDetails?.selectedExplorerFolder) {
              
                setselectedExplorerFolder(transformerContent?.targetTableDetails?.selectedExplorerFolder)
            }
        } else {

            setTreeData([])
            setdirectorytreeData([])
            
        }
        if (connectionDetails && getObjectLength(connectionDetails) > 0) {
            setconnectionName(connectionDetails?.connection_name)
        }
    }, [connectionDetails])

    const onTabChange = (activeKey) => {

        setshowloader(true);
        settableData({});
        // if (activeKey !== 'result') {
        setTimeout(async () => {
            let getActiveTabDetails = tabArray?.find(item => item.key?.toString() === activeKey?.toString());

            if (getActiveTabDetails && getObjectLength(getActiveTabDetails?.tableResult)) {
                settableData(getActiveTabDetails?.tableResult);
            } else {
                let id = uuidv4();
                setUniqUUID(id)
                let requestBody = {
                    uuid: id,
                    connection_name: getActiveTabDetails?.connectionName,
                    schema_name: getActiveTabDetails?.schemaName,
                    table_name: getActiveTabDetails?.podName,
                    page: 0,
                    do_count: false,
                    sort_body: [],
                    filter_body: [],
                    table_ids: getActiveTabDetails?.table_ids ?? []

                }
                try {
                    const { data } = await store.dispatch(fetchPreviewTable(requestBody, false));
                    if (data?.result) {
                        settableData(data?.result);
                    }
                } catch (error) {
                    setactiveTabKey(activeKey);
                    settableData({});
                    store.dispatch(setQueryKillFlag(false))
                    setshowloader(false);
                }
            }
            setactiveTabKey(activeKey);
            store.dispatch(setQueryKillFlag(false))
            setshowloader(false);

        }, 500);
        // }else if (activeKey === 'result') {
        //     executeQuery();
        // }


    };

    useEffect(() => {

        let newcurrentTransformer = {}

        if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes?.length) {

            newcurrentTransformer = NewFlowsRedux?.nodes.find((item) => item.id === currentTransformer.id)
        }
        else {
            newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

        }
        let transformerContent = newcurrentTransformer?.content;

        if (getObjectLength(transformerContent?.targetTableDetails)) {
            if (transformerContent?.targetTableDetails.typeOfTable) {
                settoggleValue(transformerContent?.targetTableDetails?.typeOfTable)
            }
            if (transformerContent?.targetTableDetails.tableName !== '' && transformerContent?.targetTableDetails.typeOfTable === 'New') {

                setnewTableName(transformerContent?.targetTableDetails.tableName);

            }
            if (transformerContent?.targetTableDetails.tableName !== '' && transformerContent?.targetTableDetails.typeOfTable === 'Existing') {
                setschemaTableName(transformerContent?.targetTableDetails.tableName);

            }

            if (transformerContent?.targetTableDetails.schemaName !== '') {
                setselectedSchema(transformerContent?.targetTableDetails.schemaName);

            }
            if (transformerContent?.targetTableDetails?.selectedExplorerFolder) {
              
                setselectedExplorerFolder(transformerContent?.targetTableDetails?.selectedExplorerFolder)
            }

            setfirstTimeset(true)
        }
    }, [])



    useEffect(() => {
        let tableDetails = { ...targetTableDetails }
        let column = [];
        if (targetTableData && Object.keys(targetTableData).length && targetTableData.data_header?.length) {
            targetTableData.data_header.forEach(
                (item, index) => {
                    column.push({ id: index, Name: item, Type: targetTableData.data_dtype[index], checked: false, })
                }
            )
        }
        // /if ((toggleValue === 'Existing' && column.length) || (toggleValue === 'New' && newTableName !== '')) {
        tableDetails.targetColumns = toggleValue === 'Existing' ? column : columnList;
        tableDetails.schemaName = connectionType && connectionType !== 's3' && connectionType !== 'azure_adls' ? selectedSchema : `${connectionName}__temp__`;
        if (toggleValue === 'New' && newTableName !== '' && (connectionType && connectionType !== 's3' && connectionType !== 'azure_adls')) {
            tableDetails.tableName = newTableName
        } else if (toggleValue === 'Existing' && schemaTableName && (connectionType && connectionType !== 's3' && connectionType !== 'azure_adls')) {
            tableDetails.tableName = schemaTableName
        }

        if ((connectionType && connectionType === 's3' || connectionType === 'azure_adls') && selectedExplorerFolder?.selectedFolder?.length) {
            tableDetails.tableName = selectedExplorerFolder?.selectedFolder[0];
            setnewTableName(selectedExplorerFolder?.selectedFolder[0]);
        }

        tableDetails.sourceTypeImage = connectionDetails?.source_type
        tableDetails.connectionName = connectionName
        tableDetails.typeOfTable = toggleValue
        tableDetails.query = Query
        tableDetails.tabArray = tabArray.map((item) => {
            item.tableResult = {};
            return item
        })

        tableDetails.prefix = selectedExplorerFolder.folderPath;
        tableDetails.selectedExplorerFolder = selectedExplorerFolder;
        // tableDetails.explorers3Tree = explorers3Tree;
        tableDetails.directorytreeData = directorytreeData;

        store.dispatch(setTargetTableDetails(tableDetails))

        let cloneDeepFlowsElements = {}
        if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes?.length) {
            cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)

        } else {
            cloneDeepFlowsElements = cloneDeep(flowsElements)

        }
        cloneDeepFlowsElements['nodes'] = cloneDeepFlowsElements?.nodes?.map(item => {
            if (item.id === currentTransformer.id) {

                let newContent = {
                    ...item.content,
                    targetTableDetails: tableDetails,
                    connectionDetails: connectionDetails,
                    treeData: treeData,
                    mode: selectedMode,
                }
                item.content = newContent
            }
            return item

        })

        if (!firstTimeset) {
            setNewFlowsRedux(cloneDeepFlowsElements)
        } {
            setfirstTimeset(false)
        }
    }, [schemaTableName, selectedExplorerFolder, toggleValue, Query, tabArray, directorytreeData, columnList, selectedSchema, treeData, targetTableData])


    useEffect(() => {
        let tableDetails = { ...targetTableDetails }

        tableDetails.schemaName = connectionType && connectionType !== 's3' && connectionType !== 'azure_adls' ? selectedSchema : `${connectionName}__temp__`;
        if (toggleValue === 'New' && newTableName !== '' && (connectionType && connectionType !== 's3' && connectionType !== 'azure_adls')) {
            tableDetails.tableName = newTableName
        } else if (toggleValue === 'Existing' && schemaTableName && (connectionType && connectionType !== 's3' && connectionType !== 'azure_adls')) {
            tableDetails.tableName = schemaTableName
        }
        store.dispatch(setTargetTableDetails(tableDetails))

        let cloneDeepFlowsElements = {}
        if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes?.length) {
            cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)

        } else {
            cloneDeepFlowsElements = cloneDeep(flowsElements)

        }
        cloneDeepFlowsElements['nodes'] = cloneDeepFlowsElements?.nodes?.map(item => {
            if (item.id === currentTransformer.id) {

                let newContent = {
                    ...item.content,
                    targetTableDetails: tableDetails,
                    connectionDetails: connectionDetails,
                    mode: selectedMode,
                }
                item.content = newContent
            }
            return item

        })

        if (!firstTimeset) {
            setNewFlowsRedux(cloneDeepFlowsElements)
        }
    }, [newTableName])

    const fetchSchemaList = () => {
        try {
            const requestBody = {

                "connection_name": connectionName
            }

            store.dispatch(fetchDatabaseSchemaList(requestBody)).then(
                res => {
                    if (res) {
                        const data = res.data.data_record;
                        const schemaNames = data.flat();
                        let sourceDropdownList = []
                        schemaNames.forEach((schemaName, index) => {
                            sourceDropdownList.push({ key: index, Name: schemaName, Type: "", value: schemaName })

                        })
                        setschemaDropdownValue([...sourceDropdownList])
                    }
                })
        } catch (e) {

        }
    }
    useEffect(() => {
        if (toggleValue === 'New') {
            fetchSchemaList()
        }
    }, [toggleValue])

    useEffect(() => {
        if (selectedMode === 'update') {
            settoggleValue('Existing')
        }
    }, [selectedMode])

    useEffect(() => {
        const getColumn = async () => {
            let newcurrentTransformer = {}
            let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
            if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes?.length) {

                newcurrentTransformer = NewFlowsRedux?.nodes.find((item) => item.id === currentTransformer.id)
            } else {
                newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

            }


            let InputTransformer = store.dispatch(getInputTransformerDetails())

            if (newcurrentTransformer.content?.mode === 'update') {
                settoggleValue('Existing');
                if (
                    newcurrentTransformer &&
                    newcurrentTransformer.content &&
                    newcurrentTransformer.content?.targetTableDetails?.query
                ) {

                    setQuery(newcurrentTransformer.content?.targetTableDetails?.query)

                }
                if (
                    newcurrentTransformer &&
                    newcurrentTransformer.content &&
                    newcurrentTransformer.content?.targetTableDetails?.tabArray?.length
                ) {

                    settabArray(newcurrentTransformer.content?.targetTableDetails?.tabArray)

                }

            }


            if (InputTransformer && InputTransformer?.length) {


                let column = [];
                let inputColumns = [];

                let ele = InputTransformer[0];
                inputColumns = await store.dispatch(checkOutcomeColumnPresent(ele));
                if (selectedMode === 'update') {
                    if (inputColumns?.length) {
                        // column = [
                        // ...column,
                        CreateSqlTableDetails(
                            inputColumns,
                            ele.id,
                            ele.type,
                            ele.transformerName
                        )
                        // ];
                    }


                    // if (column?.length) {
                    //     setsqlTables([...column]);

                    // }
                }

                let newColumnList = []
                inputColumns.forEach(
                    item => {
                        newColumnList.push({ id: item.id, checked: false, Name: item.Name, Type: item.Type, Label: item.Name, columnValue: item.Name })
                    }
                )

                setcolumnList([...newColumnList])
                let podDetails = {}
                if (InputTransformer?.length) {
                    InputTransformer.forEach((item) => {

                        podDetails = { transformerId: item.id, podName: `${item?.transformerName}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: item?.transformerName, transformerType: item?.type, podId: item?.content?.table_id ?? '' }
                    })
                }

                if (podDetails) {

                    let id = uuidv4();
                    setUniqUUID(id)
                    let request = {
                        uuid: id,
                        connection_name: !podDetails.podId && podDetails.connectionName ? podDetails.connectionName : 'hive',
                        schema_name: podDetails.dataDomain,
                        table_name: podDetails.podName,
                        page: 0,
                        do_count: false,
                        sort_body: [],
                        filter_body: [],
                        table_ids: podDetails.podId ? [podDetails.podId?.toString()] : []

                    }

                    setinputTableData({})
                    settableData({})

                    setshowloader(true);
                    let tabArrayNew = []
                    if ((newcurrentTransformer.content?.connectionDetails?.source_type !== 's3' || newcurrentTransformer.content?.connectionDetails?.source_type !== 'azure_adls') && newcurrentTransformer.content?.targetTableDetails?.tabArray) {
                        tabArrayNew = newcurrentTransformer.content?.targetTableDetails?.tabArray;
                    } else {
                        tabArrayNew = tabArray;
                    }

                    let findInputIndex = tabArrayNew?.findIndex(item => item.key?.toString() === 'input_table')


                    if (request?.uuid) {
                        store.dispatch(fetchPreviewTable(request, false)).then((response) => {
                            if (response?.status === 'success' && response?.data?.result) {
                                let responseData = response.data.result;


                                if (responseData && responseData !== null) {

                                    setinputTableData(responseData)
                                    if (findInputIndex > -1) {
                                        let cloneTabs = cloneDeep(tabArrayNew)
                                        cloneTabs[findInputIndex].tableResult = responseData;
                                        cloneTabs[findInputIndex]['tabTitle'] = `${podDetails.dataDomain}.${podDetails.podName}`
                                        cloneTabs[findInputIndex]['podName'] = podDetails.podName
                                        cloneTabs[findInputIndex]['schemaName'] = podDetails.dataDomain
                                        cloneTabs[findInputIndex]['connectionName'] = !podDetails.podId && podDetails.connectionName ? podDetails.connectionName : 'hive'
                                        cloneTabs[findInputIndex]['table_ids'] = podDetails.podId ? [podDetails.podId?.toString()] : []

                                        settabArray(cloneTabs)
                                    }
                                    settableData(responseData)
                                    setshowloader(false);
                                    if (selectedMode === 'update') {
                                        CreateSqlTableDetails(response.data?.result?.data_header, '',
                                            podDetails?.transformerType,
                                            podDetails?.podName
                                        )
                                    }


                                } else {
                                    setinputTableData({})
                                    settableData({})
                                    setshowloader(false);

                                }
                            }
                        }).catch((e) => {
                            let findInputIndex = tabArrayNew.findIndex(item => item.key?.toString() === 'input_table')

                            setinputTableData({})
                            if (findInputIndex > -1) {
                                let cloneTabs = cloneDeep(tabArrayNew)
                                cloneTabs[findInputIndex].tableResult = {};
                                cloneTabs[findInputIndex]['tabTitle'] = `${podDetails.dataDomain}.${podDetails.podName}`
                                cloneTabs[findInputIndex]['podName'] = podDetails.podName
                                cloneTabs[findInputIndex]['schemaName'] = podDetails.dataDomain
                                cloneTabs[findInputIndex]['connectionName'] = !podDetails.podId && podDetails.connectionName ? podDetails.connectionName : 'hive'
                                cloneTabs[findInputIndex]['table_ids'] = podDetails.podId ? [podDetails.podId?.toString()] : []

                                settabArray(cloneTabs)
                            }

                            setshowloader(false);
                        })
                    }

                } else {
                    let findInputIndex = tabArray.findIndex(item => item.key?.toString() === 'input_table')

                    setinputTableData({})
                    if (findInputIndex > -1) {
                        let cloneTabs = cloneDeep(tabArray)
                        cloneTabs[findInputIndex].tableResult = {};
                        cloneTabs[findInputIndex]['tabTitle'] = `${podDetails.dataDomain}.${podDetails.podName}`
                        cloneTabs[findInputIndex]['podName'] = podDetails.podName
                        cloneTabs[findInputIndex]['schemaName'] = podDetails.dataDomain
                        cloneTabs[findInputIndex]['connectionName'] = !podDetails.podId && podDetails.connectionName ? podDetails.connectionName : 'hive'
                        cloneTabs[findInputIndex]['table_ids'] = podDetails.podId ? [podDetails.podId?.toString()] : []
                        settabArray(cloneTabs)
                    }

                    setshowloader(false);
                }

            }
        }

        getColumn();

    }, []);  //selectedMode,currentTransformer this for update

    let toggleBtnOption = [{ label: 'Existing Table', value: 'Existing', disabled: !isExecutePermission ? true : false }, { label: `New Table`, value: 'New', disabled: !isExecutePermission ? true : false },]

    const preventSpaceEvent = (e) => {
        if (e.keyCode === 32) {
            e.preventDefault();
        }
    }


    function checkRegex(e) {


        let keyCode = e.key

        //Regex to allow only Alphabets Numbers Dash Underscore and Space
        let pattern = /^[a-zA-Z0-9_]*$/;

        //Validating the textBox value against our regex pattern.
        let isValid = pattern.test(keyCode);

        if ((!e.target?.value?.length && (e.keyCode > 47 && e.keyCode < 58)) || (e.target?.selectionStart === 0 && (e.keyCode > 47 && e.keyCode < 58))) {
            e.preventDefault()
        } else if (!isValid) {
            e.preventDefault()
        }
    }


    const [sizes, setSizes] = useState([235, 265]);

    const layoutCSS = {
        //   height: '100%',
        //   display: 'flex',
        //   alignItems: 'center',
        //   justifyContent: 'center'
    };



    const getNewTableNameDiv = useMemo(() => <div className="mt-2">
        <label className='label'>Table Name</label>
        <input value={newTableName} className={!newTableName ? 'form-control is-invalid' : 'form-control'} onKeyDown={(e) => { checkRegex(e) }} onChange={(e) => setnewTableName(e?.target?.value?.toLowerCase())} type="text" disabled={!isExecutePermission}></input>
        <p className='tablename-note'><NOTEINFO /><span className='ml-1'>Only Lowercase and Underscores allowed</span></p>
        {showTableNameExistError ? <p className='error' style={{ fontSize: "10px" }}><NOTEINFO /> This Name Already Exist, Please Enter Another Table Name</p> : null}

    </div>,
        [newTableName])

    const SqlEditorPane = useMemo(() => <SqlEditor
        height={!expandTable ? 'calc(100vh - 8.25rem)' : 'calc(100vh - 3.25rem)'}
        readOnly={
            false
        }
        sqlTableDetails={sqlTables}
        fromRoute="sql_transformer"
        getInstantQuery={(query) => { setQueryvalue(query) }}//setQueryvalue(query)
        disableDebounce={true}
        ExecuteQueryClick={() => { }} //executeQuery
        QueryValue={Query}
    />, [sqlTables, Query])



    const resultTabsList = useMemo(() => {
        return <>
            {tabArray && tabArray.length ? (
                <div className="padding-top-2 padding-left-2 padding-right-2">
                    <DataTabs
                        props={{
                            activeKey: activeTabKey,
                            defaultKey: "",
                            onChange: (activeLeftPanelKey) => {

                                onTabChange(activeLeftPanelKey);

                            },
                            tabArray: tabArray,
                        }}
                    />
                </div>
            ) : null}

            {
                showloader ? <ClaristaLoader></ClaristaLoader> :
                    getObjectLength(tableData) ? <div className="flow-lower-section h-100">
                        <div id="flow-lower-table-sec" className="flow-lower-table bg-white custom-virtual-data-table h-100">
                            <DataGridTable key={'inputTable'} resp={tableData}
                                CurrentDomainName={() => { }}
                                CurrentPODName={() => { }}

                                isSplitPane={true}
                                splitSize={sizes[1] - 20}
                                uniqUUID={uniqUUID}
                                setUniqUUID={setUniqUUID}
                                fromRoute={"targetmodal2"}
                                isShowHeader={false}
                            />

                        </div>
                    </div> : <NoResultsFound></NoResultsFound>
            }
        </>



    }, [tabArray, activeTabKey, showloader, tableData])

    return (

        <div className='modal_section_with_left_form_target w-100'>

            <div className='form_left p-3 m-0 target-md-left-sd'>
                <div className="d-flex custom-border-bottom icon-circle pb-2 justify-content-start">
                    <div className='align-items-start icon-box target-md-ic  justify-content-start'>

                        <SourceTypeImage dataName={connectionDetails && getObjectLength(connectionDetails) > 0 ? connectionDetails?.source_type : null} />
                    </div>
                    <div className="pl-2 custom-flex-width">
                        <p className="label mb-0" style={{ color: '#828282', fontSize: '0.75rem' }}>Connection Name</p>
                        <p style={{ fontSize: '0.875rem' }} className="text-uppercase paragraph p-0 m-0 custom-ellipsis one-line-ellipsis">{connectionDetails && getObjectLength(connectionDetails) > 0 ? connectionDetails?.connection_name : ''}</p>
                    </div>
                </div>

                {selectedMode !== 'update' && (connectionType && connectionType?.toLowerCase() !== 's3' && connectionType?.toLowerCase() !== 'azure_adls') ?
                    <div className="d-flex justify-content-between padding-2 px-0 custom-border-bottom height-60">
                        <div className="toggle-btn-with-text target-modal-connection-details two-way-toggle-btn target-modal-block-rd w-100 m-0">
                            <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: toggleValue, buttonClassName: "", buttonEvent: settoggleValue, ImgSrc: () => <></>, isDisable: !isExecutePermission, buttonType: Literals.BTN_TOGGLE }} />
                        </div>
                    </div> : null
                }
                {toggleValue === 'Existing' || (connectionType && connectionType?.toLowerCase() === 's3' || connectionType?.toLowerCase() === 'azure_adls') ?
                    connectionType && connectionType?.toLowerCase() !== 's3' && connectionType?.toLowerCase() !== 'azure_adls' && selectedMode !== 'update'
                        ? <SchemaTree isExecutePermission={isExecutePermission} setactiveTabKey={setactiveTabKey} setIsPreviewDataLoading={setIsPreviewDataLoading} treeData={treeData} selectedMode={selectedMode} targetTableData={targetTableData} setTreeData={setTreeData} connectionName={connectionName} setSchemaDropdownValue={setschemaDropdownValue} settargetTableData={settargetTableData} setnewTableName={setschemaTableName} selectedSchema={setselectedSchema} />
                        :
                        connectionType && connectionType?.toLowerCase() !== 's3' && connectionType?.toLowerCase() !== 'azure_adls' && selectedMode === 'update' ? <SchemaTreeMultiSelect CreateSqlTableDetails={CreateSqlTableDetails} tabArray={tabArray} settabArray={settabArray} isExecutePermission={isExecutePermission} setactiveTabKey={setactiveTabKey} setIsPreviewDataLoading={setshowloader} isPreviewDataLoading={showloader} treeData={directorytreeData} selectedMode={selectedMode} targetTableData={tableData} setTreeData={setdirectorytreeData} connectionName={connectionName} setSchemaDropdownValue={setschemaDropdownValue} settargetTableData={settableData} />
                            :
                            <S3DirectoryTree toggleValue={toggleValue} podData={podData} connectionType={connectionType} isExecutePermission={isExecutePermission} connectionName={connectionName} selectedExplorerFolder={selectedExplorerFolder} setselectedExplorerFolder={setselectedExplorerFolder} />
                    //<><S3schemaTree isExecutePermission={isExecutePermission} setactiveTabKey={setactiveTabKey} setIsPreviewDataLoading={setIsPreviewDataLoading} treeData={treeData} targetTableData={targetTableData} setTreeData={setTreeData} connectionName={connectionName} setSchemaDropdownValue={setschemaDropdownValue} settargetTableData={settargetTableData} setnewTableName={setschemaTableName} selectedSchema={setselectedSchema} /></> 
                    :
                    <div>
                        {connectionType && connectionType?.toLowerCase() !== 's3' && connectionType?.toLowerCase() !== 'azure_adls' ? <div className="mt-2">
                            <SingleSelection props={{
                                label: "Select a schema", placeholder: "", dropDownValue: selectedSchema, onClickEvent: (value) => {
                                    setselectedSchema(value)
                                }, optionList: schemaDropdownValue, isDisable: !isExecutePermission
                            }} />

                        </div> : null}
                        {getNewTableNameDiv}
                        {/* <div className="mt-2">
                            <label className='label'>Table Name</label>
                            <input value={newTableName} className={!newTableName ? 'form-control is-invalid' : 'form-control'} onKeyDown={(e) => { checkRegex(e) }} onChange={(e) => setnewTableName(e?.target?.value?.toLowerCase())} type="text"></input>
                            <p className='tablename-note'><NOTEINFO /><span className='ml-1'>Only Lowercase and Underscores allowed</span></p>
                            {showTableNameExistError ? <p className='error'><NOTEINFO /> This Name Already Exist, Please Enter Another Table Name</p> : null}

                        </div> */}
                    </div>}



            </div>

            {connectionType?.toLowerCase() !== 's3' && connectionType?.toLowerCase() !== 'azure_adls' ? <div className='section_content_right p-0 m-0 '>

                {<div style={{ height: 'calc(100vh - 12.1875rem)' }} className="SplitPane target-md-spane horizontal">
                    <SplitPane
                        split="horizontal"
                        sizes={sizes}

                        onChange={(size) => setSizes(size)}
                        // minSize={200}
                        className={`flow-splitpane target-md`}
                    // defaultSize={200}

                    // maxSize={600}


                    // allowResize={true}
                    // onDragFinished={(size) => {

                    // }}
                    // size={`50%`}
                    >
                        {selectedMode !== 'update' ? <div className='h-100' style={{ ...layoutCSS }}>
                            {toggleValue === 'New' ?

                                <div className='w-100 h-100 target-col-sch'>
                                    {/* added tableHeight for split pane */}
                                    <TargetColumnSchema columnList={columnList} setColumnList={setcolumnList} tableHeight={sizes[0] - 50} /></div> :
                                <>
                                    <div style={{ flex: '0 0 100%' }} className='d-flex align-items-center padding-2 modal-ic-fix'><TABLEICON /> <span className='ml-2'>Target Table</span></div>

                                    {targetTableData && Object.keys(targetTableData).length ? <>
                                        {/* <div className='d-flex align-items-center'><TABLEICON /> <span className='ml-2'>Target Table</span></div> */}
                                        <div className="w-100 custom-virtual-data-table h-100 target-md-upper-tbl">
                                            <DataGridTable resp={targetTableData}
                                                key={'outputtable'}
                                                splitSize={sizes[0] - 50}
                                                fromRoute={"targetmodal2"}

                                                CurrentPODName={() => { }}
                                                isSplitPane={true}
                                                CurrentDomainName={() => { }}
                                                isShowHeader={false}
                                            // gridHeight={"calc(100vh - 420px)"}
                                            />


                                        </div>

                                    </> : <div style={{ flex: '0 0 100%' }}>{isPreviewDataLoading ? showSimpleLoading('Please wait target table is loading...') : <NoTableSelected fromModal={true} />}</div>}
                                </>
                            }
                        </div> :
                            <div className='h-100' >
                                <div className='d-flex p-2 align-items-center justify-content-between'>
                                    <span className='paragraph h5'> SQL </span>
                                    {/* <Buttons
                                        props={{
                                            buttonText: "Run",
                                            buttonClassName:
                                                "custom-btn-primary custom-btn btn-with-text",
                                            buttonEvent: () => {
                                                executeQuery()
                                            },
                                            ImgSrc: () => <PLAY />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    /> */}
                                </div>
                                <div className={`${expandTable ? "full-screen-active" : "h-100"
                                    } bg-white flow-filter-height flow-sql-prop-edit`}>
                                    <div className="float-right expand-btn-div padding-2 custom-border-bottom">
                                        {expandTable ? (
                                            <Buttons
                                                props={{
                                                    buttonText: "",
                                                    buttonClassName:
                                                        "custom-btn-outline custom-btn btn-with-icon",
                                                    buttonEvent: () => {
                                                        setexpandTable(false);
                                                    },
                                                    ImgSrc: () => <EXITFULLSCREEN />,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_TERTIARY,
                                                }}
                                            />
                                        ) : (
                                            <Buttons
                                                props={{
                                                    buttonText: "",
                                                    buttonClassName:
                                                        "custom-btn-outline custom-btn btn-with-icon",
                                                    buttonEvent: () => {
                                                        setexpandTable(true);
                                                    },
                                                    ImgSrc: () => <FULLSCREEN />,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_TERTIARY,
                                                }}
                                            />
                                        )}
                                    </div>
                                    {SqlEditorPane}
                                </div>
                            </div>
                        }
                        <>
                            <div style={{ ...layoutCSS }}>

                                {selectedMode !== 'update' ? <div className="flow-lower-table bg-white h-100">
                                    <div className='d-flex align-items-center padding-2 modal-ic-fix'><TABLEICON /> <span className='ml-2'>Input Table</span></div>
                                    <div className='custom-virtual-data-table w-100 h-100 target-md-lower-tbl'>
                                        {inputTableData && Object.keys(inputTableData).length ? <DataGridTable key={'inputTable'} resp={inputTableData}
                                            CurrentDomainName={() => { }}
                                            CurrentPODName={() => { }}

                                            isSplitPane={true}
                                            splitSize={sizes[1] - 20}
                                            uniqUUID={uniqUUID}
                                            setUniqUUID={setUniqUUID}
                                            fromRoute={"targetmodal2"}
                                            isShowHeader={false}
                                        /> : <div style={{ flex: '0 0 100%' }}>{showloader ? showSimpleLoading('Please wait input table is loading...') : <NoTableSelected fromModal={true} />}</div>}
                                    </div>


                                </div> :

                                    <div className="flow-lower-table bg-white h-100">
                                        {resultTabsList}

                                    </div>
                                }

                            </div>
                        </>
                    </SplitPane>
                </div>}
            </div> :
                <div className="flow-s3-input-table bg-white  w-100" style={{ height: "calc(100vh - 15.625rem)" }}>
                    <div className='d-flex align-items-center padding-2 modal-ic-fix'><TABLEICON /> <span className='ml-2'>Input Table</span></div>
                    <div className='custom-virtual-data-table w-100 h-100 target-md-lower-tbl'>
                        {inputTableData && Object.keys(inputTableData).length ? <DataGridTable key={'inputTable'} resp={inputTableData}
                            CurrentDomainName={() => { }}
                            CurrentPODName={() => { }}
                            gridHeight={'calc(100vh - 17.625rem)'}
                            isSplitPane={false}
                            uniqUUID={uniqUUID}
                            setUniqUUID={setUniqUUID}
                            fromRoute={"targetmodal2"}
                            isShowHeader={false}
                        /> : <div style={{ flex: '0 0 100%' }}>{showloader ? showSimpleLoading('Please wait input table is loading...') : <NoTableSelected fromModal={true} />}</div>}
                    </div>


                </div>}

        </div>
    )
}

export default TargetConnectionModalStep2
