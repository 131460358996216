import { Checkbox, Popover, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { MultipleColsDndList } from './MultipleColsDndList'

import { useRef } from 'react'
import { MERGE_LEFT_ICON, MERGE_RIGHT_ICON, SIXDOTS } from '../../../../../Common/iconSource'
import FormatColHelplinkTable from '../../../../dataCatalogue/createPOD2/components/FormatColHelplinkTable'
import { getColumnDataType, pxToRem, remToPx } from '../../../../common/helperFunctions'
import { cloneDeep } from 'lodash'

const DndMergeColumnList = ({ actualData,
    setupdatedcolumnList, reOrderRender,
    originalColumnList,

    setshowCloseWarning,
    isExecutePermission,
    setreOrderRender, stopDraggingRow = false, setNewColumnList = () => { }, Selectall = () => { } }) => {

    const [demo, setDemo] = useState([])
    const [columns, setColumns] = useState([])

    const [colsList, setColsList] = useState([
        { key: 'checked', title: '', resizable: false, isRequired: false, disabled: true, visible: true, width: 5, frozen: true },
        { key: 'Name', title: <div className='text-dark'>Column Name</div>, resizable: false, isRequired: true, disabled: true, visible: true, width: 90 },
        { key: 'Alias', title: <div className='text-dark'>Rename Column Name</div>, resizable: false, isRequired: true, disabled: window.location.href.includes("viewFlows") ? true : false, visible: true, width: 190 },
    ])


    function Validate(e) {

        let keyCode = e.key

        //Regex to allow only Alphabets Numbers Dash Underscore and Space
        let pattern = /^[a-zA-Z0-9_]*$/;

        //Validating the textBox value against our regex pattern.
        let isValid = pattern.test(keyCode);
        let match = keyCode.match(pattern)

        if ((!e.target?.value?.length && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106))) || (e.target?.selectionStart === 0 && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)))) {
            e.preventDefault()
        } else if (!isValid) {
            e.preventDefault()
        }
    }


    const generateColumns = (columns_list) => {
        let cols = [], colsListCopy = [...columns_list]
        cols = colsListCopy.filter(v => v.visible === true).map(v => {
            return v.key !== 'checked' ? {
                name: <label className='font-w-600 fontSizeHeading mb-0'>
                    {v.title}
                    {v.isRequired ? <span><sup>*</sup></span> : ''}
                </label>,
                key: v.key,
                sortable: false,
                resizable: false,
                width: v?.width ? remToPx(pxToRem(v?.width)) : undefined ,
                frozen: v.frozen ?? false
            } : {
                name: <div className='d-flex align-items-center pt-2'><Checkbox checked={isAllSelected()} disabled={window.location.href.includes("viewFlows") ? true : false} onChange={(e) => Selectall(e.target.checked)}></Checkbox></div>,
                key: v.key,
                sortable: false,
                resizable: false,
                width: v?.width ? remToPx(pxToRem(v?.width)) : undefined ,
                frozen: v.frozen ?? false
            }
        })

        return cols
    }


    const getFieldTemplate = (key, index, value = '', additionalClasses = '', isDisable = false, column) => {

        switch (key) {
            case 'checked':
                return <div className='d-flex align-items-center'>
                    <Checkbox checked={value} className="mr-2 " disabled={isDisable || !isExecutePermission} onChange={(e) => onInputChangeHandler(key, e.target.checked, index)}>
                    </Checkbox>
                    <div className='cursor d-block bx-rotate-90 merge-row-dragger six-dot-dnd-ic'> <SIXDOTS /></div>
                    <div className='merge-direction-ic'>{column.dataSource === 'left' ? <MERGE_LEFT_ICON /> : <MERGE_RIGHT_ICON />} </div>
                </div>

            case 'Name':
                return <div className={`${column?.showError ? 'merge-column-error' : ''} d-flex align-items-center justify-content-left`}>
                    {column.Type !== "" && (
                        <span className="element-type merge-dtype-ic">
                            {getColumnDataType(column.Type)}
                        </span>
                    )}
                    <Tooltip title={value}>

                        <div className={column?.showError ? 'Name' : ''} style={{ fontFamily: 'InterRegular', fontSize: "0.6875rem", textAlign: "left", color: "black" }}>{value}</div>

                    </Tooltip>
                </div>

            case 'Alias':

                return <div className='w-100 d-flex align-items-center h-100' onKeyDown={(e) => e.stopPropagation()}>
                        {reOrderRender ? <input
                            required
                            type="text"

                            defaultValue={value}
                            onKeyDown={(e) => Validate(e)}

                            onClick={(e) => e?.stopPropagation()}
                            onChange={(e) => {
                                onInputChangeHandler(key, e.target.value, index, column)
                            }}
                            placeholder=''
                            className={`custom-input-field text-dark ${additionalClasses}`}
                            disabled={isDisable || !isExecutePermission}
                        /> : null}

                    </div>
                

            default:
                break;
        }
    }


    const isAllSelected = () => { //return true
      if(actualData?.length){

          return actualData.every((ele) => ele?.checked === true)
      }else{
        return false
      }
    }
    useEffect(() => {
        let temp = [], cols = [], obj = {};
        let rows = [...actualData]
        temp = rows.map((v, i) => {

            Object.keys(v).forEach(key => obj[key] = getFieldTemplate(key, i, v[key], '', key === 'old_name' ? true : v[key]?.disabled, v))
            return { ...obj }

        })
        setDemo([...temp])
        cols = [...generateColumns(colsList)]
        setColumns([...cols])

    }, [actualData, reOrderRender])


    const onInputChangeHandler = (field, value, index, column) => {

        let dataCopy = [...actualData];

        dataCopy[index][field] = value


        if (field === 'Alias') {
            let clone = cloneDeep(originalColumnList);
            clone.forEach((item) => {
                if (item?.Name === column?.Name && item['frame'] === column['frame']) {
                    item['Alias'] = value;
                }
            })
            setupdatedcolumnList([...clone])
        }
        setshowCloseWarning(true)

        setNewColumnList([...dataCopy]);
        // setupdatedcolumnList([...dataCopy])

    }



    return (
        <>
            <div className='custom-card padding-2 bg-white pb-0' style={{ height: '100%', overflow: 'hidden' }}>
                <div className='merge-dnd-list' style={{ overflow: 'auto', height: 'calc(100vh - 240px)' }}>
                    <MultipleColsDndList
                        setreOrderRender={setreOrderRender}
                        colsList={columns}
                        rowsList={demo}
                        stopDraggingRow={stopDraggingRow}
                        setData={(data) => {
                            setNewColumnList(data);
                            setshowCloseWarning(true)

                            setupdatedcolumnList(data)
                        }}

                        rawData={actualData}
                    />
                </div>
            </div>
        </>
    )
}

export default DndMergeColumnList;
