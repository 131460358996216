import { Popover } from 'antd'
import React, { useMemo } from 'react'
import { ICON_ARROW_RIGHT, ICON_CATALOG_DOMAIN } from '../../../Common/newIconSource'
import CustomDropdownContent from '../../../Common/CustomDropdownContent'
import { useDispatch } from 'react-redux'
import { getDataDomainList, setUserSelectedDomain } from '../../../../store/actions'

const DomainDropdown = ({
    changeTab = false,
    popoverWrapperClass = '',
    selectedDomain = '',
    setselectedDomain = () => { },
    setdomainCount = () => { }
}) => {

    const dispatch = useDispatch()

    const [domains, setDomains] = React.useState([])
    const [menuList, setMenuList] = React.useState([{
        label: 'ALL',
        tooltip: 'ALL',
        key: 'all',
        active: true,
        disabled: false,
        data: {}
    }])
    const [searchDomain, setSearchDomain] = React.useState('')
    const getDomainList = () => {
        dispatch(getDataDomainList(false, false, false))
            .then(res => {
                let data = res?.data
                setDomains(data ?? [])
                setdomainCount(data?.length);
                let list = [...data]
                list = list?.map(item => (
                    {
                        label: item?.data_domain_name?.replaceAll("_", " ")?.toUpperCase(),
                        tooltip: item?.data_domain_name?.replaceAll("_", " ")?.toUpperCase(),
                        key: item?.data_domain_id,
                        active: false,
                        disabled: false,
                        data: item
                    }
                ));


                setMenuList([...menuList, ...list])
            }).catch(() => { })
    }


    React.useEffect(() => {

        getDomainList()
    }, [])


    useMemo(() => {
        if (changeTab) {
            getDomainList();
        }
    }, [changeTab])

    useMemo(() => {
        if (selectedDomain === 'all') {
            setMenuList(prev => {
                prev = prev?.map(m => ({ ...m, active: 'all' === m?.key }))
                return prev
            });
        }
    }, [selectedDomain])

    const items = React.useMemo(() => {
        let list = [...menuList]
        list = list?.filter(d => d?.label?.toLowerCase()?.includes(searchDomain?.toLowerCase()))
        return list
    }, [menuList, searchDomain])

    return (
        <Popover
            trigger={'hover'}
            title={''}
            destroyTooltipOnHide
            content={
                <CustomDropdownContent
                    items={items ?? []}
                    searchValue={searchDomain}
                    setSearchValue={setSearchDomain}
                    emptyDescription='No Domains'
                    searchPlaceholder='Search Domains...'
                    onItemClick={(itm) => {
                        setselectedDomain(itm.key);

                        if(itm.key !== 'all') {
                            dispatch(setUserSelectedDomain({ data_domain_id: itm.key }, false))
                                .catch(err => { })
                        }


                        setMenuList(prev => {
                            prev = prev?.map(m => ({ ...m, active: itm?.key === m?.key }))
                            return prev
                        });

                    }}

                />
            }
            onOpenChange={(visible) => {
                if (!visible) setSearchDomain("")
            }}
            placement='bottom'
            arrow={false}
            rootClassName={`nusg-domain-ddl ${popoverWrapperClass}`}
        >
             <label className='label'>Select Domain</label>
            <div className='d-flex cursor-pointer align-items-center nusg-dropdown-selected' style={{ width: '11.25rem' }}>
                <div className='nusg-dropdown-ic' style={{ background: '#FFE6CC' }}>
                    <ICON_CATALOG_DOMAIN color='#565656' />
                </div>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <span className='fontSizeHeading nusg-dropdown-label text-with-ellipsis'
                        title={menuList?.find(m => m?.active)?.label ?? "Select Domain"}
                        style={{ width: '7.25rem' }}>
                        {
                            menuList?.find(m => m?.active)?.label ?? "Select Domain"
                        }
                    </span>
                    <span className='bx-rotate-90 ml-2'>
                        <ICON_ARROW_RIGHT height='10' width='10' />
                    </span>
                </div>
            </div>
        </Popover>
    )
}

export default DomainDropdown