import { DatePicker, Popover, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';

import { useStore } from "react-redux";
import { useParams } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { getDataDomainList, getLandingPageData, getSummaryTimelineV2, killQuery } from '../../../../store/actions';
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions';
import Buttons from '../../../Common/button/Buttons';
import SingleSelection from '../../../Common/dropDown/SingleSelection';
import { CHECK, CLOSE, EDITOR2, FILTER, PREVIEW, REFRESH, RESETFILTER, STOPOCTAGON, USAGE, ALERT, PODICON, DOMAIN_SMALL, SMALLFLOWS, LOGIN_ICON, LOGOUT_ICON } from "../../../Common/iconSource";
import { LandingSimpleTable, LandingSimpleTableInner } from "../../../Common/landingSimpleTable/LandingSimpleTable";
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton';
import { ErrorComponent, getMonthName, getReadableExecutionTime, getTime, NoDataComponent, queryStatus, showSimpleLoading } from "../../common/helperFunctions";
import { Literals } from "../../common/literals";
import { getAuditData } from "../../../../store/app/appAction";
import SqlEditor from '../../dataCatalogue/preview/components/SqlEditor';
import './usageLogs.scss';
import { ICON_CATALOG_DOMAIN, ICON_LOGS, ICON_USAGE } from "../../../Common/newIconSource";



const LOG_TYPE_ENUM = {
    LOGIN: "LOGGED IN",
    LOGOUT: "LOGGED OUT"
}

const ACTION_TYPES = {
    DELETED: "DELETED",
    UPDATED: "UPDATED",
    CREATED: "CREATED"
}

const ICON_MAPPING = {
    ALERT: <ALERT width={10} height={10} />,
    POD: <PODICON width={10} height={10} />,
    "DATA DOMAIN": <ICON_CATALOG_DOMAIN width={10} height={10} />,
    FLOWS: <SMALLFLOWS width={10} height={10} />,
    LOGIN: <LOGIN_ICON />,
    LOGOUT: <LOGOUT_ICON />
}
export const UsageLogs = ({ 
    showFilters = false,
    isEditPermission = true
 }) => {

    const store = useStore();
    const { RangePicker } = DatePicker;
    const { podId } = useParams();
    const [timelineData, setTimelineData] = useState([])
    const [isRefreshing, setRefreshing] = useState(false)
    const [noDataFound, setNoDataFound] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [stopLazyload, setStopLazyload] = useState(false)
    const [logDetails, setLogDetails] = useState({})
    const RAW_USERS_LIST = useRef([]);
    const [pageNo, setPageNo] = useState(1)
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const [datePickerValue, setDatePickerValue] = useState([]);
    const [value, setValue] = useState(null);
    
    const defaultSelectValues = { domain: 'Select Domain', pod: 'Select POD', user: 'Select User', logType: "Select Log Type" }
    const [logTypeValue, setLogTypeValue] = useState(defaultSelectValues.logType);
    const [domainSelectValue, setDomainSelectValue] = useState(defaultSelectValues.domain);
    const [podSelectValue, setPodSelectValue] = useState(defaultSelectValues.pod);
    const [userSelectValue, setUserSelectValue] = useState(defaultSelectValues.user);
    const [domainsList, setDomainsList] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const logTypeList = [
        { Name: "LOGIN", Value: "LOGGED IN" },
        { Name: "LOGOUT", Value: "LOGGED OUT" },

        { Name: "DELETED", Value: "DELETED" },
        { Name: "QUERY", Value: "QUERY" },
        { Name: "CREATED", Value: "CREATED" },
        { Name: "UPDATED", Value: "UPDATED" }



    ]
    // let temp= Crypto.AES.encrypt("Clarista@007","PFcmclpRPzcoSVE4WEtHaTxbQHJuPDE/TShPbV0/SFhaQntqVkpWT3A4JElCYihDUmhGb3tGUXMzUHQzKS0jckQoSEVqS2Q+YTMqZWxnJGplcGojbzRjR0VfKzw1WDx3S0Yqalk9P09CQWxhMHFzdHFReU9GcmQhTHEpT2FLT0glcnFmcihMc0tIaDdAaEB6Z31FKGNFZiRyUGRKe19KX3ZaekB0Kj86cnUwKkx4azNoUylYUU4pQHtsRm5XaTd4UD8kPFEwVzZjVGFlUjdjNGl5e29hMmRpKktoaWJZXTNrT3k9dzMoSD9Oc0lWeUJqQ24oMHRRSHFINDhzKGNiSkM1YlhOczM3VzlncjFUMHVjZVhAXVcoJT1FRUJyRGJocWY8WUowVFN5TFNoZldKdmJHPHlAMSl3KW9fJEFkSGVFWDczdFFzM3QzPXozOFMoKnFYPHYzUEFVTFkzVTQtbSMqamU3fUxjNW5WRWFBem59dXdKaCVrI1h3UDRbZXtoTEJMKiU2ISVuN2xbX2ltQkBUelpCcSFmfXVXdVMzKXZ6N256eVozTjpsOWtYKkpkcE1Zen1pelY5OW8oPldNfUVobT1reyp6VkBBITZXOm13aDk4XV9ue09zdVtOdWVOWGFVYyEheWF6PjlVIWMkUTh4bHhUUiFIbUpMaFkhVk8=")

    const [podsList, setPodsList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [dataCount, setDataCount] = useState();
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filtersToSend, setFiltersToSend] = useState({ domain: '', user: '', pod: '', start_time: '', end_time: '', type: '' })
    const [showFilterPop, setFilterPop] = useState(false);
    const defaultPickerValue = [moment(new Date()).subtract(1, 'month'), moment(new Date())]

    useEffect(() => {
        setNoDataFound(dataCount === 0)
    }, [dataCount])

    const getSummaryTimelineData = async () => {

        let payload = {
            action: "Run Query",
            table_id: podId,
            table_name: filtersToSend.pod,
            page: pageNo,
            start_date: filtersToSend.start_time,
            end_date: filtersToSend.end_time,
            domain: filtersToSend.domain,
            user: filtersToSend.user
        };
        try {
            let response = {};
            if (filtersToSend.type) {
                // if( filtersToSend.type){
                let userId = RAW_USERS_LIST.current.find(item => item.username == userSelectValue)
                let id = userId?.id?.toString();
                response = await store.dispatch(getAuditData({
                    username: id,

                    page: pageNo,
                    category: ACTION_TYPES[filtersToSend.type] ? undefined : filtersToSend.type,
                    action: ACTION_TYPES[filtersToSend.type]
                    , table_name: filtersToSend.pod?.toLocaleLowerCase(),
                    start_date: filtersToSend.start_time,
                    end_date: filtersToSend.end_time,
                    domain: filtersToSend.domain?.toLocaleLowerCase(),
                }))
                // }
                //     else
                //     response = await store.dispatch(getSummaryTimelineV2({ payload }));

            }
            else {
                if (podId)
                    response = await store.dispatch(getSummaryTimelineV2({ payload }));
                else
                    response = await store.dispatch(getAuditData({
                        page: pageNo, start_date: filtersToSend.start_time,
                        end_date: filtersToSend.end_time,
                    }))
                //  console.log(response2);

            }
            if ((!response.data?.length || response.data?.timeline?.length <= 0) && pageNo === 1) {
                setDataCount(0)

            }
            if (response.status === "success" && response.message !== "Invalid Page Requested") {
                createLogsData(response?.data?.timeline ?? response?.data?.results, false)
                setLoadMore(false)
                setPageNo(prev => prev + 1)
                setDataCount(response?.data?.count)
                setRefreshing(false)
            }
            if (response.status === "success" && response.message === "Invalid Page Requested") {
                emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)

                setStopLazyload(true)
                setLoadMore(false)
            }
        } catch (error) {
            setErrMsg(error.message)
            setRefreshing(false);
            setLoadMore(false);
        }
    };

    const refreshSummaryTimelineData = async () => {
        setDataCount()
        setNoDataFound(false)
        setFiltersToSend({ domain: '', user: '', pod: '', start_time: '', end_time: '', type: '' })
        setDomainSelectValue(defaultSelectValues.domain)
        setUserSelectValue(defaultSelectValues.user)
        setLogTypeValue(defaultSelectValues.logType)
        setPodSelectValue(defaultSelectValues.pod)
        setFilterStartDate('')
        setFilterEndDate('')
        setTimelineData([])
        setRefreshing(true)
        setPageNo(1)
        let payload = {
            action: "Run Query",
            table_id: podId,
            table_name: '',
            page: 1,
            start_date: '',
            end_date: '',
            domain: '',
            user: ''
        };

        try {
            let response = {};
            // if(filtersToSend.type ){
            //     if( filtersToSend.type!=="QUERY")
            //      response= await store.dispatch(getAuditData({page:1, category:ACTION_TYPES[filtersToSend.type]?undefined:filtersToSend.type,action:ACTION_TYPES[filtersToSend.type]}))

            //     else
            //     response = await store.dispatch(getSummaryTimelineV2({ payload }));

            // }
            // else{
            if (podId)
                response = await store.dispatch(getSummaryTimelineV2({ payload }));
            else
                response = await store.dispatch(getAuditData({ page: 1 }))
            //  console.log(response2);

            //}
            if ((!response.data?.length || response.data?.timeline?.length <= 0) && pageNo === 1) {
                setDataCount(0)

            }
            if (response.status === "success" && response.message !== "Invalid Page Requested") {
                createLogsData(response?.data?.timeline ?? response?.data?.results, true)
                setLoadMore(false)
                setDataCount(response?.data?.count)
                setPageNo(prev => prev + 1)
                setStopLazyload(false)
                setRefreshing(false)
            }
            if (response.status === "success" && response.message === "Invalid Page Requested") {
                emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)

                setLoadMore(false)
                setDataCount(response?.data?.count)
                setRefreshing(false)
            }
        } catch (error) {
            setRefreshing(false);
            setLoadMore(false);
            emitToastNotification('error', Literals.UNABLE_TO_REFRESH_USAGE)

        }
    };

    const applyFilter = async () => {
        setDataCount()
        setNoDataFound(false)
        setTimelineData([])
        setRefreshing(true)
        setPageNo(1)
        let payload = {
            action: "Run Query",
            table_id: podId,
            table_name: filtersToSend.pod,
            page: 1,
            start_date: filtersToSend.start_time,
            end_date: filtersToSend.end_time,
            domain: filtersToSend.domain,
            user: filtersToSend.user
        };
        try {
            let response = {};
            if (filtersToSend.type) {
                // if(filtersToSend.type){
                let userId = RAW_USERS_LIST.current.find(item => item.username == userSelectValue)
                let id = userId?.id?.toString();
                response = await store.dispatch(getAuditData({
                    category: ACTION_TYPES[filtersToSend.type] ? undefined : filtersToSend.type,
                    action: ACTION_TYPES[filtersToSend.type],
                    username: id,
                    table_name: filtersToSend.pod?.toLocaleLowerCase(),
                    domain: filtersToSend.domain?.toLocaleLowerCase(),
                    start_date: filtersToSend.start_time,
                    end_date: filtersToSend.end_time,
                }))



                // }
                // else{
                //  response = await store.dispatch(getSummaryTimelineV2({ payload }));
                //}
            }
            else {

                if (podId)
                    response = await store.dispatch(getSummaryTimelineV2({ payload }));
                else
                    response = await store.dispatch(getAuditData({
                        page: 1, start_date: filtersToSend.start_time,
                        end_date: filtersToSend.end_time,
                    }))
                //  console.log(response2);

            }
            if (response.status === "success" && response.message !== "Invalid Page Requested" && response.message !== "No results found") {
                createLogsData(response?.data?.timeline ?? response?.data?.results, true)
                setLoadMore(false)
                setPageNo(prev => prev + 1)
                setDataCount(response?.data?.count)
                setStopLazyload(false)
                setRefreshing(false)
            }
            else if (response.status === "success" && response.message === "Invalid Page Requested") {
                emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)

                setDataCount(response?.data?.count)
                setLoadMore(false)
                setRefreshing(false)
            }
            else if (response.status === "success" && response.message === "No results found") {
                setDataCount(0)
                setLoadMore(false)
                setRefreshing(false)
            }

        } catch (error) {

            setRefreshing(false);
            setLoadMore(false);
            emitToastNotification('errpr', Literals.UNABLE_TO_REFRESH_USAGE)

        }
    };

    const killProcessingQuery = async (query_id) => {
        let payload = { query_id: query_id }
        let resp = await store.dispatch(killQuery({ payload }))

        if (resp.status === "success") {
            setTimeout(() => {
                refreshSummaryTimelineData()
            }, 5000);
        }
        else {
            emitToastNotification('error', Literals.CANT_KILL_THIS_QUERY_PLEASE_REFRESH_LOGS)

        }
    }


    const createLogsData = (data, isRefreshing) => {
        let dataList = [...data]
        dataList !== undefined && dataList?.length > 0 && dataList.forEach((item, index) => {
            dataList[index]['log_id'] = <Tooltip title={item?.log_id} placement="topLeft" className="text-with-ellpsis">{item?.log_id}</Tooltip>
            dataList[index]['action_btn'] =
                <div className="d-flex align-items-center justify-content-center h-100">
                    <Tooltip placement="bottom" title="Preview">
                        <button className="custom-btn-outline color-primary bg-transparent border-0 custom-btn btn-with-icon"
                            onClick={() => {
                                setOpenModal(true)
                            }
                            }
                        >
                            <PREVIEW />
                        </button>
                    </Tooltip>
                    {
                        (item?.status === queryStatus.running || item?.status === queryStatus.queued) ?
                                <button 
                                    disabled={!isEditPermission}
                                    className="custom-btn-outline error-red bg-transparent border-0 custom-btn btn-with-text ml-2"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if(!isEditPermission) return
                                        if (window.confirm('Are you sure want to kill this query?')) {
                                            killProcessingQuery(item?.query_id)
                                        }
                                    }
                                    }
                                >
                                    {/* <i className='bx bx-stop-circle h5 mb-0 text-danger'></i> */}
                                    <Tooltip placement="bottom" title="Kill Query">
                                        <span>
                                            <STOPOCTAGON />
                                        </span>
                                    </Tooltip>
                                </button>
                             : ''
                    }
                </div>

            dataList[index]['content_value'] = item.content_type == "QUERY" ? <>
                {
                    item.pod_detail?.table_name ? <div>
                        <label className={`d-block label ${["POD", "DATA DICTIONARY", "DATA DOMAIN"].includes(item.content_type) ? " text-uppercase" : ""}`} style={{ lineHeight: 2, marginBottom: 3 }}>
                            <Tooltip title={"POD"}>
                                <span className="mr-1"><PODICON width={10} height={10} /></span>
                            </Tooltip>
                            <Tooltip placement='topLeft' title={item.pod_detail?.table_name}>
                                <span>
                                    {item.pod_detail?.table_name}
                                </span></Tooltip>

                        </label>
                        <p className="small text-black-50 mb-0" style={{ lineHeight: 1 }}>
                            <Tooltip title="DOMAIN">
                                <span className="mr-2"><ICON_CATALOG_DOMAIN width={10} height={10} /></span>
                            </Tooltip>
                            <Tooltip placement='topLeft' title={item.pod_detail.data_domain_name}>
                                <span>
                                    {item.pod_detail.data_domain_name}
                                </span></Tooltip>
                        </p>
                    </div> : <label className="text-black-50">Not Applicable</label>
                }

            </> : item.content_type ? <div className="align-items-center d-flex h-100">
                <div>
                    <label className={`d-block label ${["POD", "DATA DICTIONARY", "DATA DOMAIN"].includes(item.content_type) ? " text-uppercase" : ""}`} style={{ lineHeight: 2, marginBottom: 3 }}>
                        <Tooltip placement='topLeft' title={item.content_name || item.content_type}>

                            {item.content_name || item.content_type}</Tooltip>

                    </label>
                    <p className="small text-black-50 mb-0" style={{ lineHeight: 1 }}>
                        <Tooltip title={item.content_type}> <span className="mr-1">{ICON_MAPPING[item.content_type]}</span>  </Tooltip>

                        <Tooltip placement='topLeft' title={item.content_type}>

                            <span>{item.content_type}
                            </span>
                        </Tooltip>
                    </p>
                </div>
            </div> : <label className="text-black-50">Not Applicable</label>


            dataList[index]['statusPresent'] = item?.status;

            dataList[index]['status'] = <label className={`mb-0 text-capitalize 
            ${item?.status === queryStatus.finished ? 'text-success'
                    : item?.status === queryStatus.running ? 'text-primary'
                        : item?.status === queryStatus.queued ? 'text-warning'
                            : item?.status === queryStatus.failed ? 'text-danger'
                                : 'text-black-50'} `}>

                <i className={`mr-1 bx bx-${item?.status === queryStatus.finished ? 'check-circle'
                    : item?.status === queryStatus.running ? 'run'
                        : item?.status === queryStatus.queued ? 'dots-horizontal-rounded'
                            : item?.status === queryStatus.failed ? 'x-circle'
                                : 'text-black-50'
                    }
                `}></i>

                {item?.status && item?.status.length > 0
                    ? item?.status.toLowerCase()
                    : 'No Status'}
            </label>

            dataList[index]['time'] = <div className="align-items-center d-flex h-100">

                <label className="d-block label text-dark" style={{ lineHeight: 1, marginBottom: 3 }}>
                    <i className="mr-1 bx bxs-calendar-alt"></i>
                    {item?.log_time === null ? '' : new Date(item?.log_time).getUTCDate()}
                    {item?.log_time === null ? '' : ' ' + getMonthName.short[new Date(item?.log_time).getUTCMonth()]}
                    {item?.log_time === null ? '' : ' ' + new Date(item?.log_time).getUTCFullYear()}
                </label>
                <label className=" text-black-50 mb-0" style={{ lineHeight: 1 }}>
                    <i className="mr-1 bx bx-time"></i>{item?.log_time === null ? '00:00' : getTime(item?.log_time)}
                </label>
            </div>
            dataList[index]['log_time'] = <div className="align-items-center d-flex h-100">
                <div>
                    <label className="d-block label text-dark" style={{ lineHeight: 1, marginBottom: 3 }}>
                        <i className="mr-1 bx bxs-calendar-alt"></i>
                        {item?.log_time === null ? '' : new Date(item?.log_time).getUTCDate()}
                        {item?.log_time === null ? '' : ' ' + getMonthName.short[new Date(item?.log_time).getUTCMonth()]}
                        {item?.log_time === null ? '' : ' ' + new Date(item?.log_time).getUTCFullYear()}
                    </label>
                    <p className="small text-black-50 mb-0" style={{ lineHeight: 1 }}>
                        <i className="mr-1 bx bx-time"></i>{item?.log_time === null ? '00:00' : getTime(item?.log_time)}
                    </p>
                </div>
            </div>

            dataList[index]['create_time'] = <div className="align-items-center d-flex h-100">
                <div>
                    <label className="d-block label text-dark" style={{ lineHeight: 1, marginBottom: 3 }}>
                        <i className="mr-1 bx bxs-calendar-alt"></i>
                        {item?.create_time === null ? '' : new Date(item?.create_time).getUTCDate()}
                        {item?.create_time === null ? '' : ' ' + getMonthName.short[new Date(item?.create_time).getUTCMonth()]}
                        {item?.create_time === null ? '' : ' ' + new Date(item?.create_time).getUTCFullYear()}
                    </label>
                    <p className="small text-black-50 mb-0" style={{ lineHeight: 1 }}>
                        <i className="mr-1 bx bx-time"></i>{item?.create_time === null ? '00:00' : getTime(item?.create_time)}
                    </p>
                </div>
            </div>
            dataList[index]['pod_detail_table_name'] = <Tooltip placement='topLeft' title={item?.pod_detail?.table_name?.toUpperCase()}>{item?.pod_detail?.table_name?.toUpperCase()}</Tooltip>
            dataList[index]['pod_detail_domain_name'] = <Tooltip placement='topLeft' title={item?.pod_detail?.data_domain_name?.toUpperCase()}>{item?.pod_detail?.data_domain_name?.toUpperCase()}</Tooltip>
            dataList[index]['query_string_to_view'] = <>{!item?.query_string ? <label className="text-black-50">Not Applicable</label> : <Tooltip placement="topLeft" title={item?.query_string}><div className='d-flex align-items-center h-100'>
                <label className='mb-0 text-with-ellipsis bg-light border rounded cursor-pointer' style={{ padding: '2px', lineHeight: '1.4' }}> {item?.query_string}</label>
            </div></Tooltip>}</>
            dataList[index]['user_name'] = <Tooltip placement='topLeft' title={item?.user_name}>{item?.user_name}</Tooltip>
        })
        if (!isRefreshing) {
            setTimelineData([...timelineData, ...dataList])
        }
        else {
            setTimelineData([...dataList])
        }
    }


    useEffect(() => {
        getSummaryTimelineData()
        getDomainsList()
        getPodsList()
        getUsersList()
    }, [])

    const getUsersList = async () => {
        let temp = []
        let response;
        try {
            response = await store.dispatch(
                getUserGroupList({ method: "GET", endPoint: "profile/" })
            );
            if (response.status === "success") {
                let list = response?.data
                list.forEach((ele) => {
                    temp.push({ Name: ele?.username, Value: ele?.username })
                })
                RAW_USERS_LIST.current = [...list];
                setUsersList(temp);
            }
        } catch (error) { }
    };

    const getPodsList = async () => {
        let temp = []
        let resp = await store.dispatch(getLandingPageData())
        if (resp.status === 'success') {
            let list = resp?.data
            list.forEach((ele) => {
                temp.push({ Name: ele?.table_name?.toUpperCase(), Value: ele?.table_name })
            })
            setPodsList(temp)
        }
    }

    const getDomainsList = async () => {
        let temp = []
        let resp = await store.dispatch(getDataDomainList())
        if (resp.status === 'success') {
            let list = resp?.data
            list.forEach((ele) => {
                temp.push({ Name: ele?.data_domain_name?.toUpperCase(), Value: ele?.data_domain_name })
            })
            setDomainsList(temp)
        }
    }

    const allColumns = [
        {
            name: (
                <div>
                    Log Id
                </div>
            ),
            key: "log_id",
            width: "5%",
            sortable: false,
            resizable: false,
        },

        {
            name: (
                <div>
                    Time
                </div>
            ),
            key: "log_time",
            width: "10%",
            sortable: false,
            resizable: false,
        },
        {
            name: (
                <div>
                    Name
                </div>
            ),
            key: "user_name",
            sortable: false,
            width: "15%",

            resizable: false,
        }, {
            name: (
                <div>
                    Event
                </div>
            ),
            key: "action",
            sortable: false,
            resizable: false,
        },
        {
            name: (
                <div>
                    Resource Name
                </div>
            ),
            key: "content_value",
            width: "15%",

            sortable: false,
            resizable: false,
        },
        {
            name: (
                <div>
                    Query
                </div>
            ),
            key: "query_string_to_view",
            width: "30%",
            sortable: false,
            resizable: false,
        },
        {
            name: (
                <div>
                    State
                </div>
            ),
            key: "status",
            sortable: false,
            resizable: false,
            width: 70
        },
        {
            name: (
                <div className='text-center'>
                    Action
                </div>
            ),
            key: "action_btn",
            sortable: false,
            resizable: false,
            width: 60
        }
    ]

    const logColumns = [
        {
            name: (
                <div>
                    Log Time
                </div>
            ),
            key: "log_time",
            sortable: false,
            resizable: false,
        },
        {
            name: (
                <div>
                    Name
                </div>
            ),
            key: "user_name",
            sortable: false,
            resizable: false,
        }, {
            name: (
                <div>
                    Description
                </div>
            ),
            key: "action_message",
            sortable: false,
            resizable: false,
        }
    ]

    const deleteColumns = [
        {
            name: (
                <div>
                    Time
                </div>
            ),
            key: "log_time",
            sortable: false,
            resizable: false,
        },
        {
            name: (
                <div>
                    Name
                </div>
            ),
            key: "user_name",
            sortable: false,
            resizable: false,
        }, {
            name: (
                <div>
                    Content Name
                </div>
            ),
            key: "content_name",
            sortable: false,
            resizable: false,
        },
        {
            name: (
                <div>
                    Content Type
                </div>
            ),
            key: "content_type",
            sortable: false,
            resizable: false,
        },
        {
            name: (
                <div>
                    Description
                </div>
            ),
            key: "action_message",
            sortable: false,
            resizable: false,
        }

    ]

    const columns = [
        {
            name: (
                <div>
                    Start Time
                    {(filtersToSend.start_time !== '' || filtersToSend.start_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "create_time",
            sortable: false,
            resizable: false,
            width: 120
        },
        {
            name: (
                <div>
                    {Literals.NAME}
                    {filtersToSend.user !== defaultSelectValues.user && filtersToSend.user !== ''
                        ? <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "user_name",
            sortable: false,
            resizable: false,
            width: 200
        },
        {
            name: (
                <div>
                    {Literals.POD_NAME}
                    {filtersToSend.pod !== defaultSelectValues.pod && filtersToSend.pod !== ''
                        ? <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "pod_detail_table_name",
            sortable: false,
            resizable: false,
            width: 200
        },
        {
            name: (
                <div>
                    {Literals.DATA_DOMAIN_NAME}
                    {filtersToSend.domain !== defaultSelectValues.domain && filtersToSend.domain !== ''
                        ? <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "pod_detail_domain_name",
            sortable: false,
            resizable: false,
            width: 200
        },
        {
            name: (
                <div>
                    Query
                </div>
            ),
            key: "query_string_to_view",
            sortable: false,
            resizable: false,
        },
        {
            name: (
                <div>
                    State
                </div>
            ),
            key: "status",
            sortable: false,
            resizable: false,
            width: 108
        },
        {
            name: (
                <div className='text-center'>
                    Action
                </div>
            ),
            key: "action_btn",
            sortable: false,
            resizable: false,
            width: 100
        }
    ];

    const leastColumns = [
        {
            name: (
                <div>
                    Start Time
                    {(filtersToSend.start_time !== '' || filtersToSend.start_time !== '') ?
                        <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "create_time",
            sortable: false,
            resizable: false,
            width: '15%'
        },
        {
            name: (
                <div>
                    {Literals.NAME}
                    {filtersToSend.user !== defaultSelectValues.user && filtersToSend.user !== ''
                        ? <i className='bx bx-filter-alt ml-2'></i> : ''}
                </div>
            ),
            key: "user_name",
            sortable: false,
            resizable: false,
            width: '15%'
        },
        {
            name: (
                <div>
                    Query
                </div>
            ),
            key: "query_string_to_view",
            sortable: false,
            resizable: false,
            width: '50%'

        },
        {
            name: (
                <div>
                    State
                </div>
            ),
            key: "status",
            sortable: false,
            resizable: false,
            // width: 108
        },
        {
            name: (
                <div className='text-center'>
                    Action
                </div>
            ),
            key: "action_btn",
            sortable: false,
            resizable: false,
            // width: 100
        }
    ];

    const RowSelection = (row) => {
        setLogDetails(row)
        setOpenModal(true)
    }

    useEffect(() => {
        if (!openModal) {
            setLogDetails({})
        }
    }, [openModal])

    const onScrollToBottom = (isAtBottom) => {
        if (!stopLazyload) {
            if (isAtBottom && !loadMore) {
                setLoadMore(true)
                getSummaryTimelineData()
            }
        }
    }

    const onCalendarChange = (dates, dateString, info) => {
        const currentDate = new Date();
        if (dateString[0] && dateString[1]) {
            let startDate = dateString[0];
            let endDate = dateString[1];

            if ((new Date(startDate) > currentDate || new Date(endDate) > currentDate)) {
                emitToastNotification('warning', Literals.CANNOT_SELECT_DATE_GREATER_THAN_CURRENT_DATE)

                return;
            }
            let temp = [moment(startDate, "YYYY-MM-DD"), moment(endDate, "YYYY-MM-DD")];
            setFilterStartDate(startDate)
            setFilterEndDate(endDate)
            setDatePickerValue(temp);
        }
        else {
            setFilterStartDate('')
            setFilterEndDate('')
        }
    }

    const disabledDate = (current) => {

        return current && (current > moment().endOf('day') || current < currentDate);
    }

    const handleUserSelect = (value) => {
        setUserSelectValue(value)
    }

    const handlePodSelect = (value) => {
        setPodSelectValue(value)
    }

    const handleLogTypeSelect = (value) => {

        if (!["LOGIN", "LOGOUT", "QUERY"].includes(value)) {
            setUserSelectValue(defaultSelectValues.user);
        }
        setLogTypeValue(value)
    }
    const handleDomainSelect = (value) => {
        setDomainSelectValue(value)
    }
    const onOpenChange = (open) => {
        if (open) {
            setDatePickerValue([null, null]);
        } else {
            setDatePickerValue(null);
        }
    };


    const resetFilter = (field) => {
        if (field === "logType") {
            setLogTypeValue(defaultSelectValues.logType)

        }
        if (field === 'domain') {
            setDomainSelectValue(defaultSelectValues.domain)
        }
        else if (field === 'user') {
            setUserSelectValue(defaultSelectValues.user)
        }
        else if (field === 'pod') {
            setPodSelectValue(defaultSelectValues.pod)
        }
        else if (field === 'date') {
            setFilterStartDate('')
            setFilterEndDate('')
            setDatePickerValue([])
        }
        else {
            setLogTypeValue(defaultSelectValues.logType)

            setDomainSelectValue(defaultSelectValues.domain)
            setUserSelectValue(defaultSelectValues.user)
            setPodSelectValue(defaultSelectValues.pod)
            setFilterStartDate('')
            setFilterEndDate('')
        }
    }

    const resetAllFilter = () => {

        setLogTypeValue(defaultSelectValues.logType)

        setDomainSelectValue(defaultSelectValues.domain)
        setUserSelectValue(defaultSelectValues.user)
        setPodSelectValue(defaultSelectValues.pod)
        setFilterStartDate('')
        setFilterEndDate('')
        setDatePickerValue([])
        refreshSummaryTimelineData('all')
        // }, 500);

    }

    const filterPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                tooltip: "Reset Filters",
                                buttonText: "",
                                buttonClassName: "custom-btn-outline danger-btn custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                    resetAllFilter()
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setFilterPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className='row mx-n1 pb-3' style={{ width: '324px' }}>


                    <div className='col-12 my-3' >
                        <div
                        //  className={`usage-filter-grid ${logTypeValue !== defaultSelectValues.logType
                        //     && logTypeList.length ? 'active' : ''
                        //     }`}
                        >
                            <>
                                <SingleSelection
                                    props={{
                                        label: "",
                                        placeholder: "Select Log Type",
                                        dropDownValue: logTypeValue?.toUpperCase(),
                                        onClickEvent: (value) => {
                                            handleLogTypeSelect(value)
                                        },
                                        optionList: logTypeList,
                                        isDisable: logTypeList.length ? false : true,
                                    }}
                                />
                                {/* {
                                            logTypeValue !== defaultSelectValues.logType
                                                && logTypeValue?.length ?
                                                <Buttons
                                                    props={{
                                                        tooltip: "Reset Domain Filter",
                                                        buttonText: "",
                                                        buttonClassName: "custom-btn-outline danger-btn custom-btn btn-with-icon",
                                                        buttonEvent: () => {
                                                            resetFilter('logType');
                                                        },
                                                        ImgSrc: () => <RESETFILTER />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_TERTIARY,
                                                    }}
                                                /> : ''
                                        } */}
                            </>
                        </div>

                    </div>
                    {logTypeValue == "QUERY" ? <div
                        className='col-12 mb-3'>
                        <div
                        // className={`usage-filter-grid ${domainSelectValue !== defaultSelectValues.domain
                        //     && domainSelectValue.length ? 'active' : ''
                        //     }`}
                        >
                            {
                                domainsList.length ?
                                    <>
                                        <SingleSelection
                                            props={{
                                                label: "",
                                                placeholder: "Select Domain Name",
                                                dropDownValue: domainSelectValue?.toUpperCase(),
                                                onClickEvent: (value) => handleDomainSelect(value),
                                                optionList: domainsList,
                                                isDisable: domainsList.length ? false : true,
                                            }}
                                        />
                                        {/* {
                                            domainSelectValue !== defaultSelectValues.domain
                                                && domainSelectValue.length ?
                                                <Buttons
                                                    props={{
                                                        tooltip: "Reset Domain Filter",
                                                        buttonText: "",
                                                        buttonClassName: "custom-btn-outline danger-btn custom-btn btn-with-icon",
                                                        buttonEvent: () => {
                                                            resetFilter('domain');
                                                        },
                                                        ImgSrc: () => <RESETFILTER />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_TERTIARY,
                                                    }}
                                                /> : ''
                                        } */}
                                    </> : ""
                                //  <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>

                    </div> : ""}
                    {logTypeValue == "QUERY" ? <div className='col-12 mb-3'>
                        <div
                        //  className={`usage-filter-grid ${podSelectValue !== defaultSelectValues.pod
                        //     && podSelectValue.length ? 'active' : ''
                        //     }`}
                        >
                            {
                                podsList.length ?
                                    <>
                                        <SingleSelection
                                            props={{
                                                label: "",
                                                placeholder: "Select Pod Name",
                                                dropDownValue: podSelectValue?.toUpperCase(),
                                                onClickEvent: (value) => handlePodSelect(value),
                                                optionList: podsList,
                                                isDisable: podsList.length ? false : true,
                                            }}
                                        />
                                        {/* {
                                            podSelectValue !== defaultSelectValues.pod
                                                && podSelectValue.length ?
                                                <Buttons
                                                    props={{
                                                        tooltip: "Reset Pod Filter",
                                                        buttonText: "",
                                                        buttonClassName: "custom-btn-outline danger-btn custom-btn btn-with-icon",
                                                        buttonEvent: () => {
                                                            resetFilter('pod');
                                                        },
                                                        ImgSrc: () => <RESETFILTER />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_TERTIARY,
                                                    }}
                                                /> : ''
                                        } */}
                                    </> : ""
                                //  <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>
                    </div> : ""}
                    {["LOGIN", "LOGOUT", "QUERY"].includes(logTypeValue) ? <div className='col-12 mb-3'>
                        <div
                        // className={`usage-filter-grid ${userSelectValue !== defaultSelectValues.user
                        //     && userSelectValue.length ? 'active' : ''
                        //     }`}
                        >
                            {
                                usersList.length ?
                                    <>
                                        <SingleSelection
                                            props={{
                                                label: "",
                                                placeholder: "Select User Name",
                                                dropDownValue: userSelectValue,
                                                onClickEvent: (value) => handleUserSelect(value),
                                                optionList: usersList,
                                                isDisable: usersList.length ? false : true,
                                            }}
                                        />
                                        {
                                            //     userSelectValue !== defaultSelectValues.user
                                            //         && userSelectValue.length ?
                                            //         <Buttons
                                            //             props={{
                                            //                 tooltip: "Reset User Filter",
                                            //                 buttonText: "",
                                            //                 buttonClassName: "custom-btn-outline danger-btn custom-btn btn-with-icon",
                                            //                 buttonEvent: () => {
                                            //                     resetFilter('user');
                                            //                 },
                                            //                 ImgSrc: () => <RESETFILTER />,
                                            //                 isDisable: false,
                                            //                 buttonType: Literals.BTN_TERTIARY,
                                            //             }}
                                            //         /> : ''
                                        }
                                    </> : ""
                                //  <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>
                    </div> : ""}
                    <div className='col-12 d-flex justify-content-between'>
                        <div id='usage-filter-range-picker' style={{ width: '215px' }}>
                            {
                                timelineData ?
                                    <>
                                        <RangePicker className='w-100 usage-filter-range-picker' value={datePickerValue || value}
                                            onCalendarChange={onCalendarChange}
                                            disabledDate={disabledDate}
                                            // onCalendarChange={(val) => {console.log(val);setDates(val)}}
                                            onChange={(val) => { setValue(val) }}
                                            onOpenChange={onOpenChange} />
                                        {/* <RangePicker className='w-100 usage-filter-range-picker' value={datePickerValue} onCalendarChange={onCalendarChange} disabledDate={disabledDate} /> */}
                                        {/* <RangePicker
                                            className='w-100 usage-filter-range-picker'
                                            defaultValue={datePickerValue}
                                            defaultPickerValue={defaultPickerValue}
                                            onCalendarChange={onCalendarChange}
                                            disabledDate={disabledDate}
                                            // disabled={logTypeValue!=="QUERY"}
                                        /> */}
                                    </>
                                    : <CustomSkeleton height='28px' width='100%' />
                            }
                        </div>
                        {
                            timelineData &&
                                podsList.length &&
                                domainsList.length &&
                                usersList.length ?
                                <Buttons
                                    props={{
                                        buttonText: "Apply",
                                        buttonClassName: "custom-btn-primary custom-btn btn-with-text h-100",
                                        buttonEvent: () => {

                                            filtersToSend.type = logTypeValue !== defaultSelectValues.logType ? logTypeValue : ''
                                            filtersToSend.domain = domainSelectValue !== defaultSelectValues.domain ? domainSelectValue : ''
                                            filtersToSend.pod = podSelectValue !== defaultSelectValues.pod ? podSelectValue : ''
                                            filtersToSend.user = userSelectValue !== defaultSelectValues.user ? userSelectValue : ''
                                            filtersToSend.start_time = filterStartDate
                                            filtersToSend.end_time = filterEndDate
                                            applyFilter()
                                            setFilterPop(false)
                                        },
                                        toggleBtnValue: "",
                                        ImgSrc: () => <CHECK />,
                                        isDisable: false,






                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        toggleBtnOption: null,
                                    }}
                                />
                                : <CustomSkeleton height='28px' width='75px' />
                        }
                    </div>
                </div>
            </>
        }
    }

    const handleFilterVisibleChange = (visible) => {
        setFilterPop(visible)
    }

    return <>
        <div className="usage-listing">
            <div id="usage-refresh-panel" className='py-2 px-3 d-flex align-items-center justify-content-end usage-refresh-panel'>
                {
                    isRefreshing ? <div className='py-2 label mr-2 mb-0' style={{ lineHeight: 1 }}><i className='bx bx-loader-alt bx-spin mr-1'></i>Refreshing...</div> : ''
                }
                {
                    showFilters ?
                        <Popover placement="bottomLeft"
                            title={filterPopover.header}
                            content={filterPopover.body}
                            trigger="click"
                            autoAdjustOverflow={true}
                            open={showFilterPop}
                            onOpenChange={handleFilterVisibleChange}
                        >
                            <Tooltip placement='bottom' title='Filter'>
                                <button className={`${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"
                                    } custom-btn btn-with-icon mr-2 ${(filtersToSend.domain !== '' || filtersToSend.pod !== '' ||
                                        filtersToSend.type !== '' ||
                                        filtersToSend.user !== '' ||
                                        filtersToSend.start_time !== '' || filtersToSend.end_time !== '') ? 'rdg-filter-active' : ''
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFilterPop(true)
                                    }
                                    }
                                >
                                    <FILTER />
                                </button>
                            </Tooltip>
                        </Popover> : ''
                }

                <Buttons
                    props={{
                        tooltip: "Refresh Logs",
                        buttonText: "",
                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                        buttonEvent: () => {
                            refreshSummaryTimelineData()
                        },
                        ImgSrc: () => <span className={`${isRefreshing ? 'bx-spin' : ''} d-flex`}><REFRESH /></span>,
                        isDisable: isRefreshing,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />
            </div>
            <div id={podId ? "pod-usage" : ""}>
                {
                    errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> :
                        noDataFound ? <NoDataComponent logo={<ICON_LOGS width="60" height="60" color="black" />}
                            message="No Usage Found" />
                            : timelineData && timelineData.length > 0 ?
                                <LandingSimpleTableInner
                                    cols={podId ? leastColumns : showFilters ? allColumns : leastColumns}
                                    rowsData={timelineData}
                                    autoWidth={true}
                                    onRowClick={RowSelection}
                                    onScrollToBottom={onScrollToBottom}
                                    // autoScroll={pageNo > 1}
                                    loadMore={loadMore}
                                /> : showSimpleLoading()
                }
            </div>


            <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true} onClosed={() => setOpenModal(false)}>
                <ModalHeader close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className='d-flex'>
                            <EDITOR2 />
                            <h2 className="section-heading">{logDetails?.content_type ?? "Query"}</h2>
                        </div>
                        <div className='mb-n1 mr-3 small'>
                            <label className='d-block mb-0 small text-right' style={{ lineHeight: 0 }}>{logDetails.statusPresent ? <>Status: {logDetails.status}</> : logDetails.time}</label>
                            <label className='mb-0 small text-black-50 d-block text-right'>{logDetails.user_name}</label>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='usage-drawer'>
                        <div>
                            <div className='py-2'>
                                <div className='row mx-n2'>
                                    <div className='col-12 mb-4'>
                                        <div className='usage-sql-editor-wrap border rounded p-1'>
                                            <SqlEditor
                                                QueryValue={logDetails.query_string ?? logDetails.action_message}
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                fromRoute={'usage'}
                                            />
                                        </div>
                                    </div>
                                    {logDetails.action == "RUN QUERY" ? <> <div className='col-3'>
                                        <div className='custom-card shadow-none border h-100'>
                                            <div>
                                                <label className='label'>Peak Memory (in bytes)</label>
                                            </div>
                                            <div>
                                                <label className='font-weight-bold h5'>{logDetails.peak_mem}</label>
                                            </div>
                                        </div>
                                    </div>
                                        <div className='col-3'>
                                            <div className='custom-card shadow-none border h-100'>
                                                <div>
                                                    <label className='label'>Total Rows</label>
                                                </div>
                                                <div>
                                                    <label className='font-weight-bold h5'>{logDetails.total_rows}</label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            logDetails?.total_time && logDetails?.total_time !== null ?
                                                <div className='col-3'>
                                                    <div className='custom-card shadow-none border h-100'>
                                                        <div>
                                                            <label className='label'>Total TIme</label>
                                                        </div>
                                                        <div>
                                                            <label className='font-weight-bold'>{logDetails?.total_time && logDetails?.total_time !== null ? getReadableExecutionTime(logDetails?.total_time) : 'NULL'}</label>
                                                        </div>
                                                    </div>
                                                </div> : ''
                                        }
                                        <div className='col-3'>
                                            <div className='custom-card shadow-none border h-100'>
                                                <div>
                                                    <label className='label'>Start Time</label>
                                                </div>
                                                <div>
                                                    <label className='font-weight-bold'>{logDetails.create_time}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""}
                                </div>

                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>

        </div>
    </>;
};
