import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, } from 'react-router-dom';
import { WithRouterHOC } from '../../../../services/WithRouterHOC';
import { setSidebarActiveRoute } from '../../../../store/actions';
import { LASTRUN, SETTINGS, STEPS } from '../../../Common/iconSource';
import { generateHtmlTitle, permissions } from '../../common/helperFunctions';
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader';
import { LastRun } from './LastRun';
import { SchedulerHeader } from './SchedulerHeader';
import { Settings } from './Settings';
import { Steps } from './Steps';
import LoadingComponent from '../../common/loadingComponent';

const CreateSchedulerIndex = ({
    pageType = 'create',
    loadingStatus
}) => {

    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("settings");
    const { schedulerId } = useParams()

    const [isRefreshing, setRefreshing] = useState(false)
    const [refreshButtonClick, setrefreshButtonClick] = useState(false)
    const [isEditor, setIsEditor] = useState(false)
    const location = useLocation()
    const executionId = useRef()
    const queryParams = new URLSearchParams(window.location.search);

    const schedulerType = queryParams.get('schedulerType');
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)

    const tabsMenuList = [
        {
            title: "Settings",
            key: "settings",
            Icon: () => <><SETTINGS /></>,
            onClickEvent: (key) => {
                setActiveTab(key);
            },
        },
        {
            title: "Steps",
            key: "steps",
            Icon: () => <><STEPS /></>,
            onClickEvent: (key) => {
                setActiveTab(key);
            },
        },

    ];

    const [tabsList, settabsList] = useState(tabsMenuList)

    useEffect(() => {
        let name = 'Create'
        if (schedulerPayload && schedulerPayload?.name) name = schedulerPayload?.name?.toUpperCase()
        let title = generateHtmlTitle(name, 'Scheduler')
        document.title = title


        setIsEditor(
                pageType === 'create' 
                ? true
                : !!schedulerPayload?.data?.permission?.find(v => v === permissions.scheduler.editor)
            )

    }, [schedulerPayload])

    useEffect(() => {

        if (schedulerId) {
            tabsMenuList.push({
                title: "Last Runs",
                key: "lastrun",
                Icon: () => <><LASTRUN /></>,
                onClickEvent: (key) => {
                    setActiveTab(key);
                },
            })
            settabsList([...tabsMenuList])

        }

    }, [schedulerId])

    useEffect(() => {

        if (schedulerType?.toLocaleLowerCase() === 'crawler') {
            let tabsMenuList1 = tabsMenuList.slice(0, 1)
            settabsList([...tabsMenuList1])

        }

    }, [schedulerType])

    useEffect(() => {
        if (location?.hash !== "") {
            let execeptionId = location.hash?.split('=')[1]
            if (execeptionId) {
                executionId.current = execeptionId
            }
            let hashParam = location.hash?.split('=')[0]?.replace('#', '')
            setActiveTab(hashParam)
        }
    }, [])


    useEffect(() => {
        dispatch(setSidebarActiveRoute("/scheduler"));
    }, []);

    const tabContent = useMemo(() => {
        console.log(isEditor)
        switch (activeTab) {
            case tabsList[0]['key']:
                return <Settings
                    isEditor={isEditor}
                />
            case tabsList[1]['key']:
                return <Steps
                    isEditor={isEditor}
                />
            case tabsList[2]['key']:
                return <LastRun
                    isEditor={isEditor}
                isRefreshing={isRefreshing} showRefresh={false} setRefreshButtonClick={setrefreshButtonClick} refreshButtonClick={refreshButtonClick} setRefreshing={setRefreshing} executionId={executionId.current} />
            default: break
        }
    }, [activeTab, refreshButtonClick, isRefreshing, isEditor])

    return <>
        <div className='scheduler-wrapper'>
            <LandingpageFirstHeader
                title={"Scheduler"}
                flag={false}
                backRoute={"/scheduler"}
            />
            <SchedulerHeader
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabsMenuList={tabsList}
                isRefreshing={isRefreshing} 
                refreshButtonClick={refreshButtonClick} 
                setRefreshing={setRefreshing} 
                setRefreshButtonClick={setrefreshButtonClick}
                isEditor={isEditor}
            />
            {tabContent}

            {loadingStatus ? <LoadingComponent /> : null}
        </div>

    </>
}
const mapStateToProps = (state) => {
    return {
      loadingStatus: state.LoadingReducer.loadingStatus,
    };
  };

export default WithRouterHOC(connect(mapStateToProps)(CreateSchedulerIndex));
