import _, { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector, useStore } from "react-redux";
import { useParams } from "react-router";
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'
import { v4 as uuidv4 } from "uuid";
import { setSidebarVisibilty } from "../../../../store/actions";
import {
  setActiveTabKey,
  setPreviewFlag,
  setQueryKillFlag,
} from "../../../../store/modules/common/DataGridTable/dataGridTableRedux";
import {
  resetVirtualTableActions,
  resetVirtualTableRedux,
  setActivePodDetails,
} from "../../../../store/modules/common/VirtualDataTable/action";
import {
  EditFlowsAPI,
  RetriveExecutionFlows,
  setTransformerMaxId,
  showFlowsLowerSection,
  updateFlowsBasicDetails,
} from "../../../../store/modules/flows/flowsActions";
import { SET_TRANSFORMER_PROGRESS } from "../../../../store/modules/flows/flowsTypes";
import { fetchFlowsLandingPageList } from "../../../../store/modules/flows/landingPage/landingPageAction";
import { setScriptPythonCode } from "../../../../store/modules/flows/scriptTransformer/scriptAction";
import useVirtualTableAction from "../../../Common/virtualDataTable/useVirtualTableAction";
import LoadingComponent from "../../common/loadingComponent";
import { getAuditData } from "../../../../store/app/appAction";

import JupyterNotebook from "../transformers/script/JupyterNotebook";
import Header from "./Header";
import LeftSideUpperContent from "./LeftSideUpperContent";
import LogsLowerFlowsSection from "./LogsLowerFlowsSection";
import TimelineLowerSection from "./LogsTimelineFlowsSection";
import LowerFlowsSection from "./LowerFlowsSection";
import RightSideUpperContent from "./RightSideUpperContent";
import { resetAllDataGridTableFilters } from "../../../../store/modules/jobsUsage/jobsAction";
import { generateHtmlTitle, getObjectLength, permissions } from "../../common/helperFunctions";
import DatabricksNotebook from "../transformers/databricks/DatabricksNotebook";
import { getInputTransformerDetails } from "../transformers/FlowsHelperFunction";
import { Literals } from "../../common/literals";

const flowsPermissions = permissions.flows

const CreateFlows = (props) => {
  const {
    isEditMode,
    isViewMode,
    currentTransformer,
    currentScriptOutput,
    flowsElements,
    flowData
  } = props;
  let TableHeight;
  if (window.innerHeight >= 750 && window.innerHeight < 1000) {
    TableHeight = Math.round(window.innerHeight - 550);
  } else if (window.innerHeight >= 1000) {
    TableHeight = Math.round(window.innerHeight - 250);
  } else {
    TableHeight = Math.round(window.innerHeight / 2 - 70);
  }
  const [VirtualTableHeight, setTableHeight] = useState(TableHeight);
  const {
    showLowerSection,
    showTransformDetails,
    atTop,
    isSidebarVisible,
    showLogsLowerSection,
    showTimelineLowerSection,
    showLeftSideDetails,
  } = props;
  const [getTableData, setgetTableData] = useState(false);
  const [showloader, setshowloader] = useState(false);
  const [tableData, settableData] = useState({});
  const [isPreviewDataLoading, setIsPreviewDataLoading] = useState(false);

  const checkIsEditor = () => {
    const isRoleEditor = flowData === undefined ? false : !!flowData?.permission?.find(v => v === flowsPermissions.editor)
    if (isRoleEditor) return true
    return undefined
  }

  const isDeletePermission = flowData === undefined ? true : checkIsEditor() ?? !!flowData?.permission?.find(v => v === flowsPermissions.delete)
  const isEditPermission = flowData === undefined ? true : checkIsEditor() ?? !!flowData?.permission?.find(v => v === flowsPermissions.edit)
  const isExecutePermission = flowData === undefined ? true : checkIsEditor() ?? !!flowData?.permission?.find(v => v === flowsPermissions.execute)
  const isJupyterPermission = flowData === undefined ? true : checkIsEditor() ?? !!flowData?.permission?.find(v => v === flowsPermissions.jupyter_server)

  const podDetails = useSelector(
    (state) => state.CommonComponent.VirtualDataTable.activePodDetails
  );
  const transformerLoading = useSelector(
    (state) => state.FlowsReducer.transformerLoading
  );
  const saveFlowClick = useSelector(
    (state) => state.FlowsReducer.saveFlowClick
  );
  const showJupyterNoteBook = useSelector(
    (state) => state.FlowsReducer.showJupyterNoteBook
  );
  const showDatabricksNotebook = useSelector(
    (state) => state.FlowsReducer.showDatabricksNotebook
  );

  let startTimeOut = useRef(null)
  let currentTableData = useRef({})
  const [logData, setLogData] = useState([]);

  const [tabArray, settabArray] = useState();
  const [tableTitle] = useState("");
  const store = useStore();
  const [activeTabKey, setactiveTabKey] = useState("");
  const { flowId } = useParams();
  let RetriveInterval = useRef();
  let currentTransformerTabList = useRef()
  // let uuid = useRef("");
  const [uuid, setuuid] = useState("")
  const getUUID = () => {
    let id = uuidv4();
    // uuid.current = id;
    setuuid(id)
    return id;
  };
  const { previewTableData } = useVirtualTableAction(
    getTableData,
    uuid
  );


  // useEffect(() => {
  //   const onBeforeUnload = (event) => {
  //     event.preventDefault();

  //     if (window.confirm('Unsaved changes will be lost. Are you sure, want to continue?')) {
  //       store.dispatch(EditFlowsAPI({ ApiType: "DELETE", flow_Id: flowId }));
  //     }
  //   };

  //   window.addEventListener('beforeunload', onBeforeUnload);
  //   // return () => {
  //   //   window.removeEventListener("beforeunload", onBeforeUnload);
  //   // };
  // }, []);
  // const beforeUnloadHandler = (event) => {
  //   // Recommended
  //   event.preventDefault();

  //   // Included for legacy support, e.g. Chrome/Edge < 119
  //   event.returnValue = true;
  // };
  // window.addEventListener("beforeunload", beforeUnloadHandler);

  const getAuditDataFunc = () => {
    store
      .dispatch(getAuditData({ category: "FLOW", id: flowId }))
      .then((res) => {
        let temp = res?.data?.results;
        setLogData(temp);
      });
  };

  useEffect(() => {
    if (props && props?.flowName) {
      let title = generateHtmlTitle(props.flowName.toUpperCase(), 'Flows')
      document.title = title
    }
  }, [props])

  useEffect(() => {
    getAuditDataFunc();
  }, []);

  useEffect(() => {
    store.dispatch(setSidebarVisibilty(false));

    if (!isEditMode && !isViewMode) {
      store.dispatch(fetchFlowsLandingPageList()).then((resp) => {
        if (resp && resp.data?.length) {
          let responseData = resp.data;
          let tempResponseData = responseData.find(
            (item) => item.id === Number(flowId)
          );
          if (tempResponseData) {
            let obj = {
              name: tempResponseData.name,
              description: tempResponseData.description,
              id: tempResponseData.id,
              bookmark: tempResponseData.bookmark,
            };
            store.dispatch(updateFlowsBasicDetails(obj));
          }
        }
      });
    }
    // store.dispatch(
    //   setScriptPythonCode({ code: "", id: currentTransformer?.id })
    // );
    return () => {
      store.dispatch(setSidebarVisibilty(true));
      // store.dispatch(
      //   setScriptPythonCode({ code: "", id: currentTransformer?.id })
      // );
      clearTimeout(startTimeOut?.current)
      clearTimeout(RetriveInterval.current);
    };
  }, [flowId]);

  useEffect(() => {
    clearTimeout(RetriveInterval.current);
    clearTimeout(startTimeOut?.current)
  }, [saveFlowClick]);

  useEffect(() => {
    let apiPayload = {
      page: "",
      limit: "",
      execution_id: "",
      flow_Id: flowId,
    };
    let currentState = "";

    store.dispatch(RetriveExecutionFlows(apiPayload)).then((res) => {
      if (res?.data?.length) {
        let data = res.data.map((ele) => {
          ele.state = ele.state ? ele.state?.toLowerCase() : "";
          return ele;
        });
        store.dispatch({ type: SET_TRANSFORMER_PROGRESS, payload: data });
        let ids = res?.data
          .map((ele) => Number(ele.crnt_transformer))
          .filter((item) => Number(item));
        if (ids?.length) {
          let maxId = store.getState().FlowsReducer.maxTransformerId;
          ids = ids.sort((a, b) => a - b);

          if (Number(ids[ids?.length - 1] > maxId)) {
            store.dispatch(setTransformerMaxId(Number(ids[ids?.length - 1])));
          }
        }
      }
      let responseData = res.data;
      let currentStatus = "";
      let finalFlowsElements = [...flowsElements];
      if (finalFlowsElements.length) {
        finalFlowsElements.forEach((item) => {
          currentStatus = responseData.find((element) => {
            return element.crnt_transformer === item.id;
          })?.state;

          item.status =
            currentStatus?.toLowerCase() === "initial" ||
              currentStatus?.toLowerCase() === null
              ? "initialState"
              : currentStatus?.toLowerCase();
        });
      }
      let flowsStatus = responseData.find(
        (ele) => ele.transformer === "__all__"
      );
      if (flowsStatus && flowsStatus?.state) {
        currentState = flowsStatus?.state;
        if (
          currentState.toLowerCase() === "done" ||
          currentState.toLowerCase() === "success" ||
          currentState.toLowerCase() === "error" ||
          currentState.toLowerCase() === "failed"
        ) {
        }
      }

      if (flowsStatus && flowsStatus?.state) {
        currentState = flowsStatus?.state;
        if (
          currentState.toLowerCase() === "done" ||
          currentState.toLowerCase() === "success" ||
          currentState.toLowerCase() === "error" ||
          currentState.toLowerCase() === "failed"
        ) {
        }
      }
      callLatestStatusApi()
    });

    // RetriveInterval.current = setInterval(() => {
    //   store.dispatch(RetriveExecutionFlows(apiPayload)).then((res) => {
    //     if (res?.data?.length) {
    //       let data = res.data.map((ele) => {
    //         ele.state = ele.state ? ele.state?.toLowerCase() : "";
    //         return ele;
    //       });
    //       store.dispatch({ type: SET_TRANSFORMER_PROGRESS, payload: data });
    //     }
    //     let responseData = res.data;
    //     let currentStatus = "";
    //     let finalFlowsElements = [...flowsElements];
    //     if (finalFlowsElements.length) {
    //       finalFlowsElements.forEach((item) => {
    //         currentStatus = responseData.find((element) => {
    //           return element.crnt_transformer === item.id;
    //         })?.state;

    //         item.status =
    //           currentStatus?.toLowerCase() === "initial" ||
    //             currentStatus?.toLowerCase() === null
    //             ? "initialState"
    //             : currentStatus?.toLowerCase();
    //       });
    //     }
    //     let flowsStatus = responseData.find(
    //       (ele) => ele.transformer === "__all__"
    //     );
    //     if (flowsStatus && flowsStatus?.state) {
    //       currentState = flowsStatus?.state;
    //       if (
    //         currentState.toLowerCase() === "done" ||
    //         currentState.toLowerCase() === "success" ||
    //         currentState.toLowerCase() === "error" ||
    //         currentState.toLowerCase() === "failed"
    //       ) {
    //       }
    //     }
    //   });
    // }, 3000);
    // callLatestStatusApi()
    return () => {
      clearTimeout(RetriveInterval.current)

    };
  }, []);

  const callLatestStatusApi = () => {


    let currentState = "";
    let apiPayload = {
      page: "",
      limit: "",
      execution_id: "",
      flow_Id: flowId,
    };

    if (!window.location.pathname?.toLowerCase().includes('viewflows') && !window.location.pathname?.toLowerCase().includes('editflows') && !window.location.pathname?.toLowerCase().includes('createflows')
    ) {
      clearTimeout(RetriveInterval.current);
      return
    }
    store.dispatch(RetriveExecutionFlows(apiPayload)).then((res) => {
      if (res?.data?.length) {
        let data = res.data.map((ele) => {
          ele.state = ele.state ? ele.state?.toLowerCase() : "";
          return ele;
        });
        store.dispatch({ type: SET_TRANSFORMER_PROGRESS, payload: data });
      }
      let responseData = res.data;
      let currentStatus = "";
      let finalFlowsElements = [...flowsElements];
      if (finalFlowsElements.length) {
        finalFlowsElements.forEach((item) => {
          currentStatus = responseData.find((element) => {
            return element.crnt_transformer === item.id;
          })?.state;

          item.status =
            currentStatus?.toLowerCase() === "initial" ||
              currentStatus?.toLowerCase() === null
              ? "initialState"
              : currentStatus?.toLowerCase();
        });
      }
      let flowsStatus = responseData.find(
        (ele) => ele.transformer === "__all__"
      );
      if (flowsStatus && flowsStatus?.state) {
        currentState = flowsStatus?.state;
        if (
          currentState.toLowerCase() === "done" ||
          currentState.toLowerCase() === "success" ||
          currentState.toLowerCase() === "error" ||
          currentState.toLowerCase() === "failed"
        ) {
        }
      }

      RetriveInterval.current = setTimeout(() => {
        if (!showJupyterNoteBook) {

          callLatestStatusApi();
        }
      }, 5000);
    }).catch((err) => {
      RetriveInterval.current = setTimeout(() => {
        if (!showJupyterNoteBook) {

          callLatestStatusApi();
        }
      }, 5000);

    })
  }

  /// this logic stop the call if notebook open and again after close notebook start the status call but it's create back to back call that's why comment it

  // useEffect(() => {
  //   // if (showJupyterNoteBook) {
  //   //   clearInterval(RetriveInterval.current);
  //   // } else {
  //   //   // callLatestStatusApi();
  //   // }
  // }, [showJupyterNoteBook])

  useEffect(() => {
    if (transformerLoading) {
      settableData({});
    }
    store.dispatch(showFlowsLowerSection(false));
  }, [transformerLoading]);

  const getTransformerTabArray = (element) => {
    return {
      key: element.transformerId,
      tabTitle: element.transformerName,
      tabContent: null,
      podName: element.podName,
      closable: false,
      bookmark: false,
    };
  };



  useEffect(() => {
    setgetTableData(false);
    if (previewTableData && Object.keys(previewTableData).length) {
      localStorage.setItem("previewFlag", "Preview");
      store.dispatch(setPreviewFlag("Preview"));
      store.dispatch(setActiveTabKey(activeTabKey));
    }
    clearTimeout(startTimeOut)
    currentTableData.current = previewTableData
    settableData(previewTableData);
  }, [previewTableData]);

  const dispatchActivePodDetails = (copyTabs) => {
    let currentTransformerTabs = cloneDeep(copyTabs);

    if (currentTransformerTabs && currentTransformerTabs.length) {
      setactiveTabKey(currentTransformerTabs[0]?.transformerId);
      let transformerProgress =
        store.getState().FlowsReducer.transformerProgress;

      let transformer = flowsElements.find(
        (item) => item.id?.toString() === currentTransformerTabs[0]?.transformerId?.toString()
      );
      let currentactiveStatus;
      if (transformerProgress && transformerProgress?.length) {
        currentactiveStatus = transformerProgress.find((element) => {
          return (
            element.crnt_transformer?.toString() ===
            currentTransformerTabs[0]?.transformerId?.toString()
          );
        })?.state;
      }

      console.log(transformer, currentTransformerTabs)
      // if (transformer?.type !== "script") {
      let GlobalConnectionCache = localStorage.getItem(
        "GlobalConnectionCache"
      );

      settableData({});
      settabArray([]);

      if (
        transformer?.type &&
        transformer?.type.includes("datasource") &&
        currentactiveStatus === "success"
      ) {
        if (transformer && transformer?.content?.table_id) {
          store.dispatch(
            setActivePodDetails({
              transformerType: 'datasource',

              podId: transformer?.content?.table_id,
              connectionName: "hive",
              podName: currentTransformerTabs[0].podName,
              dataDomain: currentTransformerTabs[0].dataDomain,
              from: 'flows'
            })
          );
        }
      } else if (
        transformer &&
        currentactiveStatus === "success" &&
        !transformer?.type.includes("scriptOutput") &&
        !transformer?.type.includes("target")
      ) {
        store.dispatch(
          setActivePodDetails({
            connectionName: GlobalConnectionCache
              ? GlobalConnectionCache
              : "application_cache",
            podName: `${transformer.transformerName}`,
            dataDomain: `flow_${flowId}`,
            from: 'flows'

          })
        );
      } else if (
        transformer &&
        currentactiveStatus === "success" &&
        transformer?.type.includes("scriptOutput")
      ) {
        store.dispatch(
          setActivePodDetails({
            connectionName: GlobalConnectionCache
              ? GlobalConnectionCache
              : "application_cache",
            podName: `${currentTransformerTabs[0]?.podName}`,
            dataDomain: `flow_${flowId}`,

            from: 'flows'
          })
        );
      } else if (
        transformer &&
        currentactiveStatus === "success" &&
        transformer?.type.includes("target")
      ) {
        store.dispatch(
          setActivePodDetails({
            connectionName: `${currentTransformerTabs[0]?.connectionName}`,
            podName: `${currentTransformerTabs[0]?.podName}`,
            dataDomain: `${currentTransformerTabs[0]?.dataDomain}`,
            from: 'flows'

          })
        );


      }
      else if (
        transformer &&
        !transformer?.status &&
        currentactiveStatus !== "success"
      ) {
        store.dispatch(
          setActivePodDetails({
            connectionName: GlobalConnectionCache
              ? GlobalConnectionCache
              : "application_cache",
            podName: "",
            dataDomain: "",
            from: 'flows'
          })
        );
      }



      // }

    }


  }

  const createTabArrayList = () => {
    let tablist = [];
    let currentTransformerTabs = [];

    if (currentTransformer && currentTransformer.id && flowsElements.length) {
      let inputSource = store.dispatch(getInputTransformerDetails())
      let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache');
      if (currentTransformer?.type === "target" && currentTransformer?.content?.targetTableDetails) {
        currentTransformerTabs.push({ transformerId: currentTransformer.id, podName: `${currentTransformer?.content?.targetTableDetails?.tableName}`, dataDomain: currentTransformer?.content?.targetTableDetails?.schemaName, connectionName: currentTransformer?.content?.connectionDetails?.connection_name ?? '', transformerName: currentTransformer?.transformerName, transformerType: currentTransformer?.type, podId: currentTransformer?.content?.table_id ?? '', from: 'flows' })
      } else if (currentTransformer?.type !== "script") {
        currentTransformerTabs.push({ transformerId: currentTransformer.id, podName: currentTransformer?.type === 'datasource' ? `${currentTransformer.transformerName?.split('.')[1]}` : `${currentTransformer?.transformerName}`, dataDomain: currentTransformer?.type === 'datasource' ? `${currentTransformer.transformerName?.split('.')[0]}` : `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: currentTransformer?.transformerName, transformerType: currentTransformer?.type, podId: currentTransformer?.content?.table_id ?? '', from: 'flows' })

      }
      if (inputSource?.length && currentTransformer?.type !== "scriptOutput") {
        inputSource.forEach((item) => {
          currentTransformerTabs.push({ transformerId: item.id, podName: item?.type === 'datasource' ? `${item.transformerName?.split('.')[1]}` : `${item?.transformerName}`, dataDomain: item?.type === 'datasource' ? `${item.transformerName?.split('.')[0]}` : `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: item?.transformerName, transformerType: item?.type, podId: item?.content?.table_id ?? '', from: 'flows' })
        })
      }
    }

    if (currentTransformerTabs && currentTransformerTabs?.length > 0) {
      currentTransformerTabList.current = currentTransformerTabs;
      currentTransformerTabs.forEach((ele) => {
        const tabelement = getTransformerTabArray(ele);
        if (
          currentTransformer &&
          currentTransformer.type.includes(`datasource`)
        ) {
          let temp = [tabelement];
          setactiveTabKey(temp[0]?.key.toString());

          return (tablist = temp);
        } else {
          return tablist.push(tabelement);
        }
      });
    } else {
      settabArray([]);
      settableData({});
    }
    if (tablist.length >= 1) {
      dispatchActivePodDetails(currentTransformerTabs)

      settabArray([...tablist]);


    }

  };
  useEffect(() => {
    if (
      showLowerSection &&
      currentScriptOutput &&
      currentScriptOutput.type === "scriptOutput"
    ) {
      settableData({});

      getUUID();
      store.dispatch(resetVirtualTableActions()); //check hang screen check hang screen
      setTableHeight(TableHeight);
      let currentTransformerTabs = currentScriptOutput.tabArray;
      let GlobalConnectionCache = localStorage.getItem("GlobalConnectionCache");
      if (currentTransformerTabs && currentTransformerTabs?.length > 0) {
        currentTransformerTabs.forEach((ele) => {
          const tabelement = getTransformerTabArray(ele);
          let temp = [tabelement];
          setactiveTabKey(temp[0]?.key);
          store.dispatch(
            setActivePodDetails({
              connectionName: GlobalConnectionCache
                ? GlobalConnectionCache
                : "application_cache",
              podName: `${temp[0].podName}`,
              
              dataDomain: `flow_${flowId}`,
              transformerType: podDetails?.transformerType ? podDetails?.transformerType : '',
              from: 'flows'
            })
          );
          settabArray([...temp]);
          setTimeout(() => {
            setIsPreviewDataLoading(true);
            setIsPreviewDataLoading(true);
            setgetTableData(true);
          }, 500);
        });
      }
    }
  }, [showLowerSection, currentScriptOutput]);

  useEffect(() => {

    if (showLowerSection) {

      store.dispatch(resetVirtualTableActions()); //check hang screen check hang screen
      setTableHeight(TableHeight);
      createTabArrayList();
      checkTransformerStatus()
    } else {
      currentTableData.current = {}
      clearTimeout(startTimeOut?.current)
    }

  }, [showLowerSection]);

  useEffect(() => {
    console.log(podDetails, showLowerSection)
    if (podDetails?.podName !== '' && showLowerSection) {
      getUUID();
      store.dispatch(resetAllDataGridTableFilters());

      setIsPreviewDataLoading(true);
      setgetTableData(true);

    }

  }, [podDetails])

  const resizeFunction = (size) => {
    const sqlEditor = document.querySelector(".Pane2");

    if (sqlEditor && sqlEditor.offsetHeight) {
      if (sqlEditor.offsetHeight > 700) {
        setTableHeight(sqlEditor.offsetHeight - 200);
      } else {
        if (size < 0) {
          size = 0;
        }
        const maxHeight = window.innerHeight - 190;
        const btmPaneHeight = maxHeight - size;
        setTableHeight(btmPaneHeight);
      }
    }
  };

  const onTabChange = (activeKey) => {

    setIsPreviewDataLoading(true);
    settableData({});
    setactiveTabKey(activeKey);
    store.dispatch(setQueryKillFlag(false))
    let transformerProgress = store.getState().FlowsReducer.transformerProgress;

    let currentactiveStatus;
    if (transformerProgress && transformerProgress?.length) {
      currentactiveStatus = transformerProgress.find((element) => {
        return element.crnt_transformer === activeKey;
      })?.state;
    }
    store.dispatch(resetVirtualTableRedux());
    let GlobalConnectionCache = localStorage.getItem("GlobalConnectionCache");
    if (currentTransformer && currentTransformer.id && flowsElements.length) {

      if (currentTransformerTabList.current && currentTransformerTabList.current?.length !== 0) {
        let podDetails = currentTransformerTabList.current.find(
          (ele) => ele.transformerId === activeKey
        );

        // if (
        //   transformer?.type &&
        //   transformer?.type.includes("datasource") &&
        //   currentactiveStatus === "success"
        // ) {
        //   if (transformer && transformer?.content?.table_id) {
        //     store.dispatch(
        //       setActivePodDetails({
        //         transformerType: 'datasource',

        //         podId: transformer?.content?.table_id,
        //         connectionName: "hive",
        //         podName: currentTransformerTabs[0].podName,
        //         dataDomain: currentTransformerTabs[0].dataDomain,
        //         from:'flows'
        //       })
        //     );
        //   }
        // }

        if (
          podDetails &&
          currentactiveStatus === "success" &&
          // activeKey !== "Result" &&
          !currentTransformer.type.includes("target")
        ) {
          store.dispatch(
            setActivePodDetails({
              connectionName:
                GlobalConnectionCache && !podDetails.podId
                  ? GlobalConnectionCache
                  : "hive",
              podName: podDetails?.transformerType === 'datasource' ? podDetails?.podName : podDetails.transformerName,
              dataDomain: podDetails.dataDomain,
              transformerType: podDetails?.transformerType ? podDetails?.transformerType : '',
              podId: podDetails.podId ? podDetails.podId : null,
              from: 'flows'
            })
          );
        } else if (
          podDetails &&
          currentactiveStatus === "success" &&
          // activeKey !== "Result" &&
          currentTransformer.type.includes("target")
        ) {
          store.dispatch(
            setActivePodDetails({
              connectionName: podDetails.connectionName,
              podName: podDetails.podName,
              transformerType: podDetails?.transformerType ? podDetails?.transformerType : '',
              dataDomain: podDetails.dataDomain,
              podId: podDetails.podId ? podDetails.podId : null,
              from: 'flows'
            })
          );
        } else if (
          podDetails &&
          currentactiveStatus === "success" &&
          // activeKey === "Result" &&
          !currentTransformer.type.includes("script")
        ) {
          store.dispatch(
            setActivePodDetails({
              connectionName: GlobalConnectionCache
                ? GlobalConnectionCache
                : "",
              podName: podDetails.transformerName,
              dataDomain: podDetails.dataDomain,
              transformerType: podDetails?.transformerType ? podDetails?.transformerType : '',

              podId: podDetails.podId ? podDetails.podId : null,
              from: 'flows'
            })
          );
        } else {
          settableData({});
        }
      }
    }
  };

  const checkTransformerStatus = () => {
    let transformerProgress = store.getState().FlowsReducer.transformerProgress;

    if (!showFlowsLowerSection) {
      clearTimeout(startTimeOut?.current);
      return;
    } else {
      let currentactiveStatus;
      if (transformerProgress && transformerProgress?.length) {
        currentactiveStatus = transformerProgress.find((element) => {
          return element.crnt_transformer === currentTransformer?.id;
        })?.state;
      }
      console.log(currentactiveStatus, activeTabKey, getObjectLength(currentTableData.current), currentTransformerTabList.current)
      if (getObjectLength(currentTableData.current)) {

        return;
      }
      if (currentactiveStatus === 'success' && showFlowsLowerSection && !getObjectLength(tableData)) {
        if (currentTransformerTabList.current && currentTransformerTabList.current?.length > 0) {
          let activePodDetails = currentTransformerTabList?.current.find((ele) => ele.transformerId === currentTransformer?.id);
          if (activePodDetails) {

            store.dispatch(setActivePodDetails(activePodDetails))
          }
        } else {

        }

      }

      startTimeOut.current = setTimeout(() => {
        checkTransformerStatus();
      }, 1000);
    }

  }
  const getCurrentPODName = () => {
    return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails
      ?.podName;
  };

  const getCurrentPODCategoryName = () => {
    return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails
      ?.dataDomain;
  };

  const getPODId = () => {
    return store.getState().CommonComponent?.VirtualDataTable?.activePodDetails
      ?.podId
      ? store.getState().CommonComponent?.VirtualDataTable?.activePodDetails
        ?.podId
      : null;
  };

  const setUniqUUID = (uid) => {
    // console.log({uid})
    // uuid.current = uid;
    setuuid(uid)
    // console.log(uuid.current)
  }
  const [sizes, setSizes] = useState(["100%", '0%']);
  const [innerSize, setInnerSizes] = useState(["50%", '50%']);

  const layoutCSS = {
    height: '100%',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  };
  useEffect(() => {
    if (!showLogsLowerSection && !showLowerSection && !showTimelineLowerSection) {
      setSizes(["100%", '0%'])
    }
    else {
      setSizes(["50%", '50%'])

    }
  }, [showLogsLowerSection, showLowerSection, showTimelineLowerSection])

  useEffect(() => {
    if (showTransformDetails) {
      setInnerSizes(["50%", "50%"])
    }
    else {
      setInnerSizes(["100%", '0%'])
    }


  }, [showTransformDetails, showLeftSideDetails])



  return (
    <>
      <div className="flows-section">
        <Header
          isEditMode={isEditMode}

          isViewMode={isViewMode}
          showJupyterNoteBook={showJupyterNoteBook}
          showDatabricksNotebook={showDatabricksNotebook}
          isEditPermission={isEditPermission}
          isDeletePermission={isDeletePermission}
          isExecutePermission={isExecutePermission}
        />
        {showJupyterNoteBook ? (
          <JupyterNotebook />
        ) : showDatabricksNotebook ? <DatabricksNotebook /> :
          (
            <div >
              {atTop === false && (
                <div style={{ height: "calc(100vh - 16px)", marginTop: "-35px" }} className="SplitPane horizontal">
                  <SplitPane
                    split="horizontal"
                    sizes={sizes}
                    onChange={showTransformDetails ? setSizes : () => { }}

                    className={`flow-splitpane Result-SplitPane ${!isSidebarVisible ? "flow-splitpane-expand" : ""}`}

                  >
                    <div style={{ ...layoutCSS }}>

                      <SplitPane
                        split="vertical"
                        sizes={innerSize}
                        onChange={showTransformDetails ? setInnerSizes : () => { }}

                      >


                        {showTransformDetails ? <LeftSideUpperContent
                          isExecutePermission={isExecutePermission}
                          isEditPermission={isEditPermission}
                          isJupyterPermission={isJupyterPermission}
                        />
                          :
                          <RightSideUpperContent
                            isEditMode={isEditMode}
                            isViewMode={isViewMode}

                          />}

                        <RightSideUpperContent
                          isEditMode={isEditMode}
                          isViewMode={isViewMode}


                        />

                      </SplitPane>

                    </div>
                    <div style={{ ...layoutCSS, width: "100%" }}>

                      {showLowerSection ? (
                        <LowerFlowsSection
                          tabArray={tabArray}
                          requestedTableId={getPODId()}
                          onTabChange={onTabChange}
                          getTableData={getTableData}
                          tableTitle={tableTitle}
                          tableData={tableData}
                          uuid={uuid}
                          setUniqUUID={setUniqUUID}
                          activeTabKey={activeTabKey}
                          VirtualTableHeight={VirtualTableHeight}
                          showLoader={showloader}
                          setshowLoader={setshowloader}
                          currentPODName={getCurrentPODName}
                          currentDomainName={getCurrentPODCategoryName}
                          isPreviewDataLoading={isPreviewDataLoading}
                          setIsPreviewDataLoading={setIsPreviewDataLoading}
                        />
                      ) : showLogsLowerSection ? (
                        <LogsLowerFlowsSection
                          flowId={flowId}
                        ></LogsLowerFlowsSection>
                      ) : showTimelineLowerSection ? (
                        <TimelineLowerSection id={flowId} />
                      ) : null}
                    </div>
                  </SplitPane>

                </div>
              )}
              {/* {showLowerSection === true && atTop === true && (
                <div className="minicanvas">
                  <RightSideUpperContent />
                </div>
              )} */}



            </div>

          )}
      </div>

      {props.loadingStatus ? <LoadingComponent /> : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    flowName: state.FlowsReducer.name,
    atTop: state.FlowsReducer.atTop,
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    currentScriptOutput: state.FlowsReducer.currentScriptOutput,
    flowId: state.FlowsReducer.id,
    showLowerSection: state.FlowsReducer.showLowerSection,
    showTimelineLowerSection: state.FlowsReducer.showTimelineLowerSection,
    showLogsLowerSection: state.FlowsReducer.showLogsLowerSection,
    showTransformDetails: state.FlowsReducer.showTransformDetails,
    isSidebarVisible: state.Layout.isSidebarVisible,

    loadingStatus: state.LoadingReducer.loadingStatus,
    showLeftSideDetails: state.FlowsReducer.showLeftSideDetails,
  };
};
export default connect(mapStateToProps)(CreateFlows);
