import React, { useCallback, useEffect, useState } from 'react';
import DataGrid from "react-data-grid";
import './DndVirtualList.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableRowRenderer } from './DraggableRowRenderer';
import { showFlowsLogsLowerSection, showFlowsLowerSection } from '../../../store/modules/flows/flowsActions';
import { useDispatch } from 'react-redux';

const createColumns = colsNumber => {
    let cols = []
    let temp = Array(colsNumber).fill(0)
    temp.map((el, i) => cols.push({
        name: `col_${el}${i}`,
        key: i,
        width: 'auto'
    }))

    return cols
}

const createRows = (data, cols) => {
    let r = [], s = [], chunk, d = [...data]

    while (d.length > 0) {
        chunk = d.splice(0, cols.length)
        r.push(chunk)
    }

    r.forEach(el => {
        let t = {}
        for (let j = 0; j < cols.length; j++) {
            t[j] = el[j] !== undefined ? el[j] : ''
        }
        s.push(t)
    })

    return s.length ? s : []
}

export const DndVirtualList = ({
    rawData = [],
    data = [],
    setData = () => { },
    colsNumber = 1,
    rowHeight = 40,
    stopDraggingRow = false,
    overflowXHidden = false,
    isHeight100=true
}) => {
  const dispatch = useDispatch()

    const [columns,] = useState([...createColumns(colsNumber)])
    const [rows, setRows] = useState([...createRows(data, columns)])
    const [raw, setRaw] = useState([...rawData])

    useEffect(() => {
        setRows([...createRows(data, columns)])
    }, [data])

    useEffect(() => {
        setRaw([...rawData])
    }, [rawData])



    const renderRow = useCallback((key, props) => {
        function onRowReorder(fromIndex, toIndex, isdrop, isDragging) {

            dispatch(showFlowsLogsLowerSection(false));
            dispatch(showFlowsLowerSection(false));
            setRows((rows) => {
                const newRows = [...rows];
                newRows.splice(toIndex, 0, newRows.splice(fromIndex, 1)[0]);
                return [...newRows];
            });
            setRaw((raw) => {
                const newRaw = [...raw];
                newRaw.splice(toIndex, 0, newRaw.splice(fromIndex, 1)[0]);

                setData([...newRaw])
                return newRaw;
            });


        }


        return <DraggableRowRenderer key={key} onlyFirstColumnDraggable={true} {...props} onRowReorder={(fromIndex, toIndex, isdrop, isDragging) => !stopDraggingRow ? onRowReorder(fromIndex, toIndex, isdrop, isDragging) : null} />;
    }, [stopDraggingRow]);

    // return (
    //   <DndProvider backend={HTML5Backend}>
    //     <DataGrid
    //       columns={columns}
    //       rows={rows}
    //       onRowsChange={setRows}
    //       renderers={{ rowRenderer }}

    //     />
    //   </DndProvider>
    // );
    // const RowRenderer = useCallback((props) => {
    //     function onRowReorder(fromIndex, toIndex) {

    //         setRows((rows) => {
    //             const newRows = [...rows];
    //             newRows.splice(toIndex, 0, newRows.splice(fromIndex, 1)[0]);
    //             return [...newRows];
    //         });
    //         setRaw((raw) => {
    //             const newRaw = [...raw];
    //             newRaw.splice(toIndex, 0, newRaw.splice(fromIndex, 1)[0]);

    //             setData([...newRaw])
    //             return newRaw;
    //         });
    //     }

    //     return <DraggableRowRenderer {...props} onRowReorder={(fromIndex, toIndex) => !stopDraggingRow ? onRowReorder(fromIndex, toIndex) : null} />;
    // }, []);

    return <>
        <DndProvider backend={HTML5Backend}>
            <div className={`${isHeight100?'h-100':''}`}>
                <DataGrid
                    className={`${isHeight100?'h-100':''} custom-vlist-dgt ${overflowXHidden ? 'overflow-x-hidden' : ''}`}
                    columns={columns}
                    rows={rows}
                    defaultColumnOptions={{
                        sortable: false,
                        resizable: false,
                    }}
                    noRowsFallback={<div className='p-2 text-black-50 text-center'>No Records Found</div>}
                    rowHeight={rowHeight}
                    headerRowHeight={0}

                    renderers={{ renderRow }}
                />
            </div>
        </DndProvider>
    </>;
};