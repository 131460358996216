import { Input, Popover, Tooltip } from 'antd'
import React from 'react'
import Buttons from '../../../../Common/button/Buttons'
import { ICON_INFO_FILLED, ICON_PLUS, ICON_SAVE } from '../../../../Common/newIconSource'
import { Literals } from '../../../common/literals'
import { columnNameRegex } from '../../../common/helperFunctions'

const UnstructuredAddTopics = ({
    onSave = () => {}
}) => {

    const [field, setField] = React.useState({topic_name: '', description: '', possible_values: ''})
    const [open, setOpen] = React.useState(false)
    
    return (
        <>
            <Popover
                open={open}
                onOpenChange={bool => setOpen(bool)}
                trigger={'click'}
                title={''}
                content={
                    <>
                        <div className='p-4' style={{width: 360}}>
                            <div className='mb-3'>
                                <label className='mb-1 label-gray fontSizeHeading fontInterSemiBold d-flex align-items-center'>
                                    Topic Name
                                    <Tooltip
                                        placement='right'
                                        title={"Only alphabets and underscores are allowed in lowercase"}
                                    >
                                        <span className='ml-1'>
                                            <ICON_INFO_FILLED height='10' width='10' color='currentColor' />
                                        </span>
                                    </Tooltip>
                                </label>
                                <div>
                                    <Input
                                        className='rounded'
                                        value={field?.topic_name}
                                        onChange={e => {
                                            let val = e?.target?.value
                                            if(val === "" || val?.match(columnNameRegex)) {
                                                setField(prev => {
                                                    return {
                                                        ...prev,
                                                        topic_name: e?.target?.value?.toLowerCase()
                                                    }
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <label className='mb-1 label-gray fontSizeHeading fontInterSemiBold'>
                                    Description
                                </label>
                                <div>
                                    <Input.TextArea
                                        className='rounded'
                                        style={{height: 80, resize: 'none', lineHeight: 1.4}}
                                        value={field?.description}
                                        onChange={e => {
                                            setField(prev => {
                                                return {
                                                    ...prev,
                                                    description: e?.target?.value
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className='d-flex align-items-center mb-1'>
                                    <label className='mb-0 label-gray fontSizeHeading fontInterSemiBold'>
                                        Possible Value 
                                    </label>
                                    <em className='text-black-50 ml-1 fontSizeHeading'>(optional)</em>
                                </div>
                                <div>
                                    <Input
                                        className='rounded'
                                        value={field?.possible_values}
                                        onChange={e => {
                                            setField(prev => {
                                                return {
                                                    ...prev,
                                                    possible_values: e?.target?.value
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className='fontSizeExtraSmall text-muted pt-1 fontInterSemiBold mt-1'>
                                    Enter Comma <code className='bg-white border px-1 rounded'>","</code> Separated Value, for ex: <code className='bg-white border px-1 rounded'>apple,orange,grapes</code>
                                </div>
                            </div>
                            <div className='pt-3 d-flex justify-content-center'>
                                <Buttons
                                    props={{
                                        buttonText: "Save",
                                        buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                        buttonEvent: () => {
                                            onSave(field)
                                            setOpen(false)
                                            setField({topic_name: '', description: '', possible_values: ''})
                                        },
                                        ImgSrc: () => <ICON_SAVE/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
                placement='bottomRight'
                arrow={false}
            >
                <span>
                    <Buttons
                        props={{
                            buttonText: "Add Topic",
                            buttonClassName: "custom-btn-outline border custom-btn btn-with-text",
                            buttonEvent: () => {
                                
                            },
                            ImgSrc: () => <ICON_PLUS/>,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </span>
            </Popover>
        </>
    )
}

export default UnstructuredAddTopics