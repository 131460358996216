import { combineReducers } from "redux";
// Front
import Layout from "./layout/reducer";
// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import costUsage from "./auth/costUsage/reducer";
// DataFunnels
// import DataFunnels from "./dataFunnels/reducer";
// DataCatalogue
import DataCatalogue from "./modules/dataCatalogue/DataCatalogue";
//DataCluster
// import DataCluster from "./dataCluster/reducer";
import UserReducer from "./userAuthentication/userReducer";
import Datasource from "./datasource/datasourceReducer";
import Rules from "./modules/dataRules/RulesRootReducer";
import Analytics from "./modules/analytics/analyticsRootReducer ";
import UserManagement from "./modules/UserManagement/UserManagementRootReducer";
import CreatePODReducer from "./modules/dataCatalogue/createPOD/createPODReducer";
import LoadingReducer from "./modules/common/loadingReducer";
import FlowsReducer from "./modules/flows/flowsReducer";
import Flows from "./modules/flows/flowsRootReducer";
import LabTransformer  from "./modules/flows/NewFlowsRedux/labRootReducer";
import Lab from "./modules/flows/NewFlowsRedux/flowsReducer";
import CommonComponent from "./modules/common/commonRootReducer";
import Alert from "./modules/alert/alertRootReducer";
import AccessManagement from "./modules/accessManagement/reducer";
import Entitlement from "./modules/entitlements/entitlementReducer";
import Scheduler from "./modules/scheduler/schedulerReducer";
import App from './app/appRootReducer';
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  App,
  costUsage,
  
  UserReducer,
  Datasource,
  Entitlement,
  LoadingReducer,
  CreatePODReducer,
  Rules,
  UserManagement,
  DataCatalogue,
  Analytics,
  FlowsReducer,
  Flows,
  Lab,
  LabTransformer,
  CommonComponent,
  Scheduler,
  Alert,
  AccessManagement,
});

export default rootReducer;
