import { Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react'
import { useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap'
import { ExecuteScheduler, SetSchedulerPayload } from '../../../../store/modules/scheduler/action';
import Buttons from '../../../Common/button/Buttons';
import { DELETEICON, PLAY } from '../../../Common/iconSource';
import ActionPopUp from '../../common/actionPopUp';
import { Literals } from '../../common/literals';

export const SchedulerDetailsForm = ({
    isEditor = false
}) => {

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const { schedulerId } = useParams();

    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)

    const store = useStore()
    const setpayload = (value, inputType) => {
        const newPayload = cloneDeep(schedulerPayload)
        if (inputType === 'name') {


            newPayload['name'] = value
        } else if (inputType === 'description') {

            newPayload['description'] = value


        }
        store.dispatch(SetSchedulerPayload(newPayload))

    }
    const openDeleteDialog = () => {
        setDeletePopUpContent({
            modalClass: "delete-popup",
            heading: "Delete",
            message: "This action will delete this flow.",
            button: [
                {
                    name: "Delete",
                    action: () => {

                    },
                },
            ],
            elements: [],
            closeActionPopup: () => setOpenDeletePopup(false),
        });
        setOpenDeletePopup(true);
    };

    return <>
        <Card className='border-0'>
            <CardHeader className='padding-2 bg-white'>
                <div className='d-flex align-items-center w-100 justify-content-between'>
                    <label className='mb-0 font-weight-bold'>
                        Scheduler Details
                    </label>
                    <div className='d-flex h-100 align-items-center justify-content-center'>
                        {/* <Tooltip placement={"bottom"} title="Delete">
                            <button
                                className={
                                    window.location.href.includes('scheduler/view')? "custom-btn  mr-2": "custom-btn-outline custom-btn btn-with-icon error-red red-border mr-2"
                                }
                                onClick={() => {
                                    openDeleteDialog()
                                }}
                                disabled={window.location.href.includes('scheduler/view')}
                            >
                                <DELETEICON />
                            </button>
                        </Tooltip> */}
                        <Buttons
                            props={{
                                buttonText: "Execute",
                                buttonClassName:
                                    "custom-btn-primary custom-btn btn-with-text",
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    // onExecute()
                                    schedulerId && store.dispatch(ExecuteScheduler(schedulerId))

                                },
                                tooltip: "Execute",
                                ImgSrc: () => <PLAY />,
                                isDisable: !isEditor || window.location.href.includes('scheduler/edit') || window.location.href.includes('scheduler/create') || schedulerPayload?.active === false,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </div>
                </div>
            </CardHeader>
            <CardBody className='padding-2'>
                <div className='row'>
                    <div className="col-12 mb-2">
                        <label htmlFor="schedulerName" className='mt-0 grey-color label'>Name</label>
                        <input
                            required
                            id="schedulerName"
                            type="text"
                            value={schedulerPayload['name']}
                            onChange={(e) => setpayload(e.target.value, 'name')}
                            className={`custom-input-field`}
                            disabled={!isEditor || window.location.href.includes('scheduler/view')}
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="schedulerDesc" className='mt-0 grey-color label'>Description</label>
                        <textarea
                            id="schedulerDesc"
                            value={schedulerPayload['description']}
                            type="text"
                            onChange={(e) => setpayload(e.target.value, 'description')}
                            disabled={!isEditor || window.location.href.includes('scheduler/view')}
                            className="custom-textarea-field"
                        />
                    </div>
                </div>
            </CardBody>
        </Card>
        <ActionPopUp
            openModal={openDeletePopup}
            actionData={deletePopUpContent}
        />
    </>
}
