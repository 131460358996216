import { Checkbox, Radio, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Buttons from "../../Common/button/Buttons";
import {
  ANDFILER,
  CHECK,
  ORFILTER, PLUS,
  RESETFILTER
} from "../../Common/iconSource";
import { Literals } from "../../modules/common/literals";
import { ICON_PLUS } from "../newIconSource";

function DataGridFilterHeader({ props }) {
  const {
    appliedFilter,
    isFilterTransformer = false,
    onRunFilter,
    onResetEvent,
    isPolicy = false,
    onAddFilter,
    isdisabled = false,
    onFilterToggle,
    onSampleEvent,
    defaultToggleValue
  } = props;
  const [toggleValue, setToggleValue] = useState("AND");

  useEffect(() => {
    if (defaultToggleValue) {
      setToggleValue(defaultToggleValue)
    }
  }, [defaultToggleValue])

  useEffect(() => {
    if (appliedFilter && appliedFilter.length) {

      if (appliedFilter[0].operation?.toLowerCase() === 'or') {

        setToggleValue(appliedFilter[0].operation)
      }

    }
  }, [])

  const setPageView = (view) => {
    onFilterToggle(view);
    setToggleValue(view);
  };

  let toggleBtnOption = [
    { label: <ANDFILER />, value: "AND", tooltip: "AND: Results satisfying all the below conditions." },
    { label: <ORFILTER />, value: "OR", tooltip: "OR: Results satisfying at least one of the below conditions." },
  ];
  return (
    <div className={`${!isFilterTransformer ? "custom-border-bottom" : ''} d-flex mt-2 justify-content-between card-padding  padding-2 flows-filter-padding`}>
      <div className="d-flex align-items-center ">

        {isdisabled ? "" :
          !isFilterTransformer ? <Buttons
            props={{
              buttonText: "Add Criteria",
              buttonClassName: "custom-btn-outline custom-btn btn-with-text mr-2",
              buttonEvent: onAddFilter,
              toggleBtnValue: "",
              ImgSrc: () => <PLUS />,
              isDisable: isdisabled,
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              toggleBtnOption: null,
            }}
          /> : null}
      </div>
      <div className="d-flex align-items-center justify-content-end w-20 ">
        {/* <Buttons
          props={{
            buttonText: "",
            buttonClassName: "",
            buttonEvent: setPageView,
            toggleBtnValue: toggleValue,
            ImgSrc: () => <></>,
            isDisable: isdisabled,
            buttonType: Literals.BTN_TOGGLE,
            toggleBtnOption: toggleBtnOption,
          }}
        /> */}


        <Radio.Group
          options={[
            {
              label: 'AND',
              value: 'AND',
            },
            {
              label: 'OR',
              value: 'OR',
            },
          ]}
          onChange={(e) => {
            setPageView(e.target.value)
          }}
          value={toggleValue}
          optionType="button"
          buttonStyle="solid"
          className='prv-and-or-tg p-1'
        />

        {isdisabled ? "" :
          isFilterTransformer ? <span className='underline-button only-text fontInterSemiBold ml-3'
            onClick={() => {
              onAddFilter()
            }}>
            <ICON_PLUS />
            <span className='fontSizeLabel'>Add Criteria</span>
          </span> : null}


        {isFilterTransformer && (
          <Buttons
            props={{
              tooltip: 'Reset',
              buttonText: "",
              buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2 mr-2",
              buttonEvent: onResetEvent,
              toggleBtnValue: "",
              ImgSrc: () => <RESETFILTER />,
              isDisable: isdisabled,
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              toggleBtnOption: null,
            }}
          />
        )}


        {isFilterTransformer && isFilterTransformer === true ? (
          <Buttons
            props={{
              buttonText:
                <div className='d-flex align-items-center justify-content-center'>
                  <Checkbox onChange={(e) => onSampleEvent(e)} />
                  <span className="custom-ellipsis one-line-ellipsis paragraph ml-1">{'Sample'}</span></div>,
              buttonClassName: "content-with-checkbox mr-2",
              buttonEvent: () => { },
              toggleBtnValue: "",
              ImgSrc: () => <></>,
              isDisable: isdisabled,
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              toggleBtnOption: null,
            }}
          />
        ) : (
          ""
        )}

        {!isFilterTransformer ? <>
          {isdisabled || isPolicy ? "" :
            (
              <Buttons
                props={{
                  buttonText: "Apply",
                  buttonClassName:
                    "custom-btn-primary custom-btn ml-2 btn-with-text",
                  buttonEvent: () => onRunFilter(false),
                  toggleBtnValue: "",
                  ImgSrc: () => <CHECK />,
                  isDisable: isdisabled,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  toggleBtnOption: null,
                }}
              />
            )}
        </> : (
          ""
        )}
      </div>
    </div>
  );
}

export default DataGridFilterHeader;
