import { List } from 'antd';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Buttons from '../../../Common/button/Buttons';
import SingleSelection from '../../../Common/dropDown/SingleSelection';
import { CLOSE, PIE } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';

export const SampleValuesModal = ({
    openModal = false,
    setOpenModal = () => {}
}) => {

    const [sampleValues, setSampleValues] = useState(
        [
            {
                Name: "10 Values",
                value: "10-values",
            },
            {
                Name: "25 Values",
                value: "25-values",
            },
            {
                Name: "50 Values",
                value: "50-values",
            },
        ]
    )

    const data = [
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152',
        '132-54-5152'
    ];

    return (
        <>
            <Modal size="xs" isOpen={openModal} className="custom-modal" centered={true} onClosed={()=>{setOpenModal(false);}}>
                <ModalHeader className='d-flex align-items-center' close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType:Literals.BTN_TERTIARY}} />}>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div className='d-flex'>
                            <PIE/>
                            <h2 className="section-heading">Sample Values for Issuer Name</h2>
                        </div>
                        <div className='mr-2'>
                            <SingleSelection
                                props={{
                                    placeholder: "Select",
                                    dropDownValue: sampleValues[0].Name,
                                    onClickEvent: (value) => {},
                                    optionList: sampleValues,
                                    isDisable: false,
                                }}
                            />
                        </div>
                    </div>                  
                </ModalHeader>
                <ModalBody>
                    <List
                        size="small"
                        dataSource={data}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}
