export const UPDATE_ACCOUNT_IDS = 'UPDATE_ACCOUNT_IDS';
export const UPDATE_KIBANA_URL = 'UPDATE_KIBANA_URL';
export const UPDATE_ACTIVE_ACCOUNT_INDEX = 'UPDATE_ACTIVE_ACCOUNT_INDEX';
export const UPDATE_AGREEGATED_COST = 'UPDATE_AGREEGATED_COST';
export const UPDATE_DAILY_COST_DETAILS = 'UPDATE_DAILY_COST_DETAILS';
export const UPDATE_MONTHLY_COST_DETAILS = 'UPDATE_MONTHLY_COST_DETAILS';
export const UPDATE_COST_TRENDS_YEARLY = 'UPDATE_COST_TRENDS_YEARLY';
export const UPDATE_COST_TRENDS_PAST_30_DAYS = 'UPDATE_COST_TRENDS_PAST_30_DAYS';

export const UPDATE_BUDGET_LIST = 'UPDATE_BUDGET_LIST';
