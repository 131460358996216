import React from 'react'
import { ICON_ALERT } from '../../Common/newIconSource'

const CopilotAlertCapsule = ({
    content = <></>,
    onClick = () => {}
}) => {
    return (
        <div>
            <div className='cpilot-alert' onClick={onClick}>
                <div className='d-flex'><ICON_ALERT width='20' height='20' color='#B83B3B' /></div>
                <div>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default CopilotAlertCapsule