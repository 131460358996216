import React, { useEffect, useState } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { connect } from 'react-redux'
import { createBookmark, fetchFlowsLandingPageList } from '../../../../store/modules/flows/landingPage/landingPageAction'
import { addEdges, EditFlowsAPI, fetchFlowsDetails, setExecutedTransformerPayload, setToDefault } from '../../../../store/modules/flows/flowsActions'
import Buttons from '../../../Common/button/Buttons'
import Filter from '../../../Common/filter/Filter'
import { DELETEICON, EDIT, FLOWTABICON, PLUS, STAR, FILLEDSTAR, SAVE, CLOSE, COPY, FLOWS, SMALLFLOWS } from '../../../Common/iconSource'
import LandingPageSubHeader from '../../../Common/landingPageSubHeader/LandingPageSubHeader'

import { DeleteNoteContent, getChartType, showSimpleLoading, NoDataComponent, ErrorComponent, permissions, getRelativeTime, getDeleteMessage } from '../../common/helperFunctions'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import { Literals } from '../../common/literals'
import LoadingComponent from '../../common/loadingComponent'

import FlowsCardView from './FlowsCardView'

import {
    duplicate
} from "../../../../store/modules/dataCatalogue/dictionary/dictionaryAction";

import ActionPopUp from '../../common/actionPopUp'
import { useSelector } from 'react-redux'
import NoSearchResultFound from '../../common/NoSearchResultFound'
import { resetScriptRedux } from '../../../../store/modules/flows/scriptTransformer/scriptAction'
import { resetTargetRedux } from '../../../../store/modules/flows/targetTransformer/targetTransformer'
import { useNavigate } from 'react-router-dom'
import { Space, Tooltip, Popover, Input } from 'antd'
import Highlighter from 'react-highlight-words'
import { resetVirtualTableActions, resetVirtualTableRedux } from '../../../../store/modules/common/VirtualDataTable/action'
import { setSidebarActiveRoute } from '../../../../store/layout/actions'
import { resetAllDataGridTableFilters } from '../../../../store/modules/common/DataGridTable/dataGridTableRedux'
import { SET_FLOWS_LANDINGPAGE_ACTIVE_TAB } from '../../../../store/modules/flows/flowsTypes'
import { getFlowsAlertTransformerList } from '../../../../store/modules/alert/createAlert/createAlertAction'
import { LandingSimpleTable } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import { resetGraphSampleSelected } from '../../../../store/actions'
import _ from 'lodash'
import { SET_SELECTED_ALERT_TRANSFORMER } from '../../../../store/modules/alert/createAlert/createAlertActiontype'
import { getGlobalConnectionCache } from '../../../../store/modules/UserManagement/connection/connectionActions'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'
import { ICON_FLOWS } from '../../../Common/newIconSource'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import NewFlowsPopUp from '../../flowsNew/createFlows/NewFlowsPopUp'

const flowsPermissions = permissions.flows

function FlowsLandingPage(props) {
    let { fromRoute = 'Flows', loadingStatus, isModal = false } = props
    const [openModal, setOpenModal] = useState(false)
    const history = useNavigate()
    const store = useStore()
    const [showDetailsView, setshowDetailsView] = useState(false)
    const [showGridView, setshowGridView] = useState(true)
    const [showLoadingMsg, setshowLoadingMsg] = useState(true)
    const [searchData, setSearchData] = useState('');
    const flowFilters = useSelector(state => state.App)
    const [errMsg, setErrorMsg] = useState("");
    const [responseData, setresponseData] = useState([])

    const [filterList, setfilterList] = useState([{

        header: '',
        selectAll: false,
        list: [{ id: 1, checked: false, name: 'Created By Me' }, { id: 2, checked: false, name: 'Favourites' }],
    },

    {
        header: 'Data Domain',
        selectAll: false,
        list: [],
    },
    {
        header: 'Data Domain',
        selectAll: false,
        list: [],
    }
    ])



    let TableHeight;
    if (window.innerHeight >= 750 && window.innerHeight < 1000) {
        TableHeight = Math.round((window.innerHeight - 280))
    } else if (window.innerHeight >= 1000) {
        TableHeight = Math.round((window.innerHeight - 250))
    } else {
        TableHeight = Math.round(window.innerHeight - 200)
    }

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const [listData, setlistData] = useState([])
    const [renameText, setRenameText] = useState({});
    const [visible, setVisible] = useState({});
    const [showListView, setshowListView] = useState(false)
    const [showFilter, setshowFilter] = useState(false)
    const [selectedFilters, setselectedFilters] = useState([])
    // { title: 'Favourites', key: 'favourite', Icon: () => <STAR />, onClickEvent: (key) => { showSelectedTabData(key) } }
    const tabsMenuList = [{ title: 'All Flows', key: 'allflows', Icon: () => <ICON_FLOWS height='18' width='18' />, onClickEvent: (key) => { showSelectedTabData(key) } },]
    const dispatch = useDispatch();
    const [empty, setEmpty] = useState(false);
    const [activeTab, setactiveTab] = useState('')
    const [showSelectedCardId, setshowSelectedCardId] = useState(null)

    const [favouriteList, setfavouriteList] = useState([]);

    const currentFlowIdRef = React.useRef({ current: {} })


    useEffect(() => {
        dispatch(getGlobalConnectionCache())

        if (fromRoute !== 'alert' && fromRoute !== 'scheduler') {
            dispatch(setSidebarActiveRoute('/flows'))
        }
    }, [])

    useEffect(() => {
        if (fromRoute === 'alert') {
            setshowSelectedCardId(store.getState().Alert.CreateAlert?.flowsAlertTransformerList?.flowId)
        } else if (fromRoute === 'scheduler') {
            setshowSelectedCardId(store.getState().Alert.CreateAlert.selectedAlertTransformer?.flowId)
        }
    }, [fromRoute])

    const showSelectedTabData = (key) => {

        setactiveTab(key)
        dispatch({
            type: SET_FLOWS_LANDINGPAGE_ACTIVE_TAB,
            payload: key
        })
        if (key === 'favourite' && responseData && responseData.length) {
            let favouriteList = responseData.filter((ele) => ele?.bookmark === true)

            if (favouriteList && favouriteList.length) {

                setData(favouriteList)
                setfavouriteList(favouriteList)

            } else {
                setData([])
                setfavouriteList([])

            }
        } else {


            setData(responseData)

        }
    }
    const openDeleteDialog = (id) => {

        currentFlowIdRef.current = id

        // setDeletePopUpContent({
        //     modalClass: "delete-popup",
        //     heading: "Delete",
        //     message: DeleteNoteContent(id, 'Flow', ` Warning: Deleting this flow may effect in 
        //     schedular and alert wherever it used.`),
        //     // headingIcon: "bx bx-error",
        //     button: [
        //         {
        //             name: "Delete",
        //             action: () => deleteFlow(id),
        //         },
        //     ],
        //     elements: [],
        //     closeActionPopup: closeDeletePopUp,
        // });
        setOpenDeletePopup(true);
    };
    const bookmarkData = (id, bookmarkStatus = false) => {
        store.dispatch(createBookmark(id, bookmarkStatus)).then((resp) => {
            if (resp.status === 'success') {
                getUpdatedFlowsList()
            }
        })
    }
    const handleSaveClick = (val) => {
        try {
            dispatch(duplicate({ name: renameText[val.id] ?? `${val.name}_copy` }, "flow", val.id)).then((res) => {
                if (res.status === 'success') {
                    getUpdatedFlowsList()
                }
            })
        }
        catch (err) {

        }
    }

    useEffect(() => {

        if (responseData?.length > 0 && !_.isEqual(flowFilters.Filters.flow?.selectedFilters ?? [], selectedFilters)) {
            setselectedFilters(flowFilters.Filters.flow?.selectedFilters)
        }
    }
        , [responseData])
    const setshowFilterPopup = () => {
        setshowFilter(!showFilter)
    }
    const closeDeletePopUp = () => {
        setOpenDeletePopup(false);
    };
    const setData = (data) => {

        if (isModal) {
            // Filtering for editor only access
            data = [...data].filter(el => !!el?.permission?.find(v => v === permissions.flows.editor))
        }

        let data_arr = data.map((ele) => {

            const checkIsEditor = () => {
                const isRoleEditor = !!ele?.permission?.find(v => v === flowsPermissions.editor)
                if (isRoleEditor) return true
                return undefined
            }

            const isDelete = checkIsEditor() ?? !!ele?.permission?.find(v => v === flowsPermissions.delete)
            const isEdit = checkIsEditor() ?? !!ele?.permission?.find(v => v === flowsPermissions.edit)
            return {
                flow_name: <div className='flexible-search-wrap d-flex align-items-center font-w-600 text-uppercase'><label className='pr-4 mb-0'>   <span className="flows-icon-in-list">  <FLOWS /></span></label><Tooltip placement="bottom" title={ele?.name}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.name ? ele?.name : ''} /></Tooltip></div>,
                flow_name_only: <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={ele?.name}><Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.name ? ele?.name : ''} /></Tooltip></div>,
                description: ele.description ? <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={`${ele?.description?.slice(0, 300)}...`}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.description ? ele?.description : ''} /></Tooltip></span> : <span style={{ marginLeft: "0px" }}>No description has been given yet.</span>,
                created_on: <Tooltip placement="top" title={getRelativeTime(ele?.created_on)}>{getRelativeTime(ele?.created_on)}</Tooltip>,
                // created_on: <Tooltip placement="right" title={getRelativeTime(ele?.created_on)}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={getRelativeTime(ele?.created_on) ? getRelativeTime(ele?.created_on) : ''} /></Tooltip>,
                created_by: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={ele?.created_by_user?.email}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.created_by_user?.email ? ele?.created_by_user?.email : ''} /></Tooltip></span>,
                updated_on: getRelativeTime(ele?.updated_on),
                updated_by: <span className='flexible-search-wrap'><Tooltip placement="bottom" title={ele?.updated_by_user?.email}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.updated_by_user?.email ? ele?.updated_by_user?.email : ''} /></Tooltip></span>,
                icon: fromRoute !== 'alert' && fromRoute !== 'scheduler' ? <div className='text-center'>
                    <Space size={4} className='justify-content-end'>
                        <Tooltip placement='bottom' title={ele?.bookmark ? 'Remove from bookmark' : 'Add to bookmark'}>
                            <button className={ele?.bookmark ? "custom-btn custom-btn-outline table-icon filled-star-icon" : 'custom-btn custom-btn-outline table-icon star-icon'} onClick={(e) => { e.stopPropagation(); bookmarkData(ele?.id, ele?.bookmark); }}>{ele?.bookmark ? <FILLEDSTAR /> : <STAR />}</button>
                        </Tooltip>
                        <Tooltip placement='bottom' title={'Edit'}>
                            <Buttons
                                props={{
                                    tooltip: 'Edit',
                                    tooltipPlacement: 'bottom',
                                    buttonText: "",
                                    buttonClassName: "custom-btn custom-btn-outline table-icon edit-icon",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        dispatch(EditFlowsAPI({ ApiType: 'GET', flow_Id: ele?.id })).then((res) => {
                                            if (res.status === "success") {
                                                history(`${Literals.links.FLOWS_EDIT}${ele?.id}`);
                                            }
                                        })
                                    },
                                    ImgSrc: () => <EDIT />,
                                    isDisable: !isEdit,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                            {/* <button className="custom-btn custom-btn-outline table-icon edit-icon" onClick={(e) => { e.stopPropagation(); history.push(`${Literals.links.FLOWS_EDIT}${ele?.id}`) }}>
                                <EDIT /></button> */}

                            {/* <button className="custom-btn custom-btn-outline table-icon edit-icon" onClick={(e) => {
                                e.stopPropagation(); dispatch(EditFlowsAPI({ ApiType: 'GET', flow_Id: ele?.id })).then((res) => {
                                    if (res.status === "success") {
                                        history.push(`${Literals.links.FLOWS_EDIT}${ele?.id}`);
                                    }
                                })
                            }}>
                                <EDIT /></button> */}
                        </Tooltip>
                        <Tooltip placement='bottom' title='Duplicate'>

                            <Tooltip placement="bottom" title="Duplicate">
                                <Popover

                                    content=

                                    {

                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }} style={{ display: "flex", padding: "10px", alignItems: "center" }}>

                                            <Input value={renameText[ele.id] ?? `${ele.name}_copy`} onChange={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setRenameText({ ...renameText, [ele.id]: e.target.value })
                                            }} />
                                            <Buttons
                                                props={{
                                                    buttonText: "",
                                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1",
                                                    buttonEvent: (e) => {
                                                        e.preventDefault();
                                                        handleSaveClick(ele);
                                                        // updateDocumentHandler();
                                                    },
                                                    ImgSrc: () => <SAVE />,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_SECONDARY,
                                                }}
                                            />
                                        </div>

                                    }


                                    title={
                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                            style={{ display: "flex", padding: "10px", justifyContent: "space-between" }}><span>Rename</span> <Buttons
                                                props={{
                                                    buttonText: "",
                                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                                                    buttonEvent: (e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setVisible({ ...visible, [ele.id]: false });
                                                    },
                                                    ImgSrc: () => <CLOSE />,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_TERTIARY,
                                                }}
                                            />
                                        </div>

                                    }
                                    onOpenChange={(vis) => setVisible({ ...visible, [ele.id]: vis })}
                                    trigger="click"
                                    open={visible[ele.id] ?? false}
                                >
                                    <Buttons
                                        props={{
                                            tooltip: 'Duplicate',
                                            tooltipPlacement: 'bottom',
                                            buttonText: "",
                                            buttonClassName: "custom-btn custom-btn-outline table-icon",
                                            buttonEvent: (e) => {
                                                e.stopPropagation();
                                                if (!isEdit) return
                                                setVisible({ ...visible, [ele.id]: true })
                                            },
                                            ImgSrc: () => <COPY />,
                                            isDisable: !isEdit,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                    {/* <button 
                                    className=" custom-btn-outline custom-btn btn-with-icon border-0" onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setVisible({ ...visible, [ele.id]: true })

                                    }}>

                                        <COPY />
                                    </button> */}
                                </Popover>
                            </Tooltip>

                        </Tooltip>
                        <Tooltip placement='bottom' title={'Delete'}>
                            <Buttons
                                props={{
                                    tooltip: 'Delete',
                                    tooltipPlacement: 'bottom',
                                    buttonText: "",
                                    buttonClassName: "custom-btn custom-btn-outline table-icon error-red",
                                    buttonEvent: (e) => { e.stopPropagation(); openDeleteDialog(ele?.id) },
                                    ImgSrc: () => <DELETEICON />,
                                    isDisable: !isDelete,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                            {/* <button disabled={!isDelete} className="custom-btn custom-btn-outline table-icon error-red" onClick={(e) => { e.stopPropagation(); openDeleteDialog(ele?.id) }}><DELETEICON /></button> */}
                        </Tooltip>
                    </Space>
                </div> : undefined,
                key: ele.id,
                bookmark: ele.bookmark,
                allData: ele,
                thumbnail: ele?.thumbnail
            }

        })
        setlistData([...data_arr])
    }

    const getUpdatedFlowsList = () => {
        store.dispatch(fetchFlowsLandingPageList('', fromRoute ? 'editor=true' : null)).then((resp) => {
            let currentActiveTab = store.getState().FlowsReducer.currentActiveTabFlowLanding
            setshowLoadingMsg(true)

            if (resp.data?.length <= 0) {
                setEmpty(true);
            }
            if (resp && resp.data?.length) {
                setEmpty(false);
                setshowLoadingMsg(false)

                let favouriteList = resp.data.filter((ele) => ele?.bookmark === true)
                if (favouriteList && favouriteList.length) {
                    setfavouriteList(favouriteList)
                } else {
                    setfavouriteList([])

                }

                if (favouriteList && favouriteList.length && currentActiveTab === 'favourite') {
                    setData(favouriteList)
                } else if (currentActiveTab !== 'favourite') {
                    setData(resp.data)
                } else {
                    setData([])

                }
                setresponseData(resp.data)


            } else if (resp && resp.data?.length === 0) {
                setshowLoadingMsg(false)
                setEmpty(true);

                setlistData([])
                setresponseData([])
            }
        }).catch((err) => {
            setshowLoadingMsg(false)
            setEmpty(false);

            setErrorMsg(err.message)

        })


    }
    const deleteFlow = async (id) => {
        try {
            dispatch(fetchFlowsDetails({ method: "DELETE", endPoint: `flow/${id}` })).then((resp) => {
                if (resp.status === 'success') {
                    // console.log(resp)
                    emitToastNotification('success', 'Flow deleted successfully')

                    getUpdatedFlowsList();
                    setOpenDeletePopup(false);
                }
            }).catch((error) => {
                emitToastNotification('error','Flow not deleted, please retry once')
            })

        } catch (error) {
            emitToastNotification()
        }

    }

    const getDataDomainList = (data) => {
        const dataDomainList = new Set();
        data.map(item => {
            for (let i = 0; i < item?.pod_detail?.length; i++) {
                dataDomainList.add(item?.pod_detail[i]?.data_domain_name)
            }
        });
        const tempList = [...dataDomainList];

        createDomainFilterList(tempList, false, {})

    }


    const createDomainFilterList = (list = [], isSelectedFilter = false, selectedItem) => {
        if (!isSelectedFilter && list.length) {
            let domainFilterList = [];
            list.forEach((item, index) => domainFilterList.push({ id: index, checked: false, name: getChartType(item), }));

            let temp = [...filterList];
            temp[1]['list'] = domainFilterList;

            setfilterList([...temp])
        } else {
            let temp = [...filterList];

            temp[1]['list'].forEach((item, index) => {
                if (item.name.toLowerCase() === selectedItem.name.toLowerCase) {
                    item.checked = selectedItem.checked;
                }
            });



            setfilterList([...temp])
        }

    }

    const filterData = () => {
        let dataDomain = []
        let favourites = []
        const myMail = store.getState().UserReducer.user.userDetails.email;
        let currentActiveTab = store.getState().FlowsReducer.currentActiveTabFlowLanding

        if (selectedFilters.length > 0) {
            selectedFilters.forEach(val => {
                if (val['Data Domain']) {
                    dataDomain = val['Data Domain'].map(val => val.name) || []
                }
                if (val['emptyheader']) {
                    favourites = val['emptyheader'].map(val => val.name) || []
                }
            })
        }
        let oldObj = []
        // if (currentActiveTab === 'favourite' && favouriteList.length) {
        //     oldObj = [...favouriteList]
        // } else { 

        oldObj = [...responseData]
        // }

        oldObj.forEach(val => {

            if (favourites.length) {

                if (favourites.includes('Created by me') && favourites.includes('Favourites')) {

                    oldObj = oldObj.filter(a => a.created_by_user?.email?.toLowerCase().includes(myMail.toLowerCase()) && a?.bookmark === true);

                } else if (favourites.includes('Favourites')) {

                    showSelectedTabData('favourite')

                    oldObj = oldObj.filter((ele) => ele?.bookmark === true)


                } else if (favourites.includes('Created by me')) {



                    oldObj = oldObj.filter(a => a.created_by_user?.email?.toLowerCase().includes(myMail.toLowerCase()))


                }
            }
            if (dataDomain.length) {
                oldObj = oldObj.filter(a => {
                    return a?.pod_detail?.length && a.pod_detail.find((item, i) => {
                        return dataDomain.includes(item?.data_domain_name)
                    });
                })
            }

        })
        if (selectedFilters.length) {
            return (oldObj)
        }
        //setAlertData(oldObj)
        else {
            return (responseData)
        }
        //setAlertData(alertDataClone)

    }




    useEffect(() => {
        store.dispatch(setToDefault(``));
        store.dispatch(setExecutedTransformerPayload([]))
        store.dispatch(addEdges([]))
        store.dispatch(resetScriptRedux(''))
        store.dispatch(resetTargetRedux({}))
        store.dispatch(resetVirtualTableRedux())
        store.dispatch(resetVirtualTableActions()) //check hang screen check hang screen

        store.dispatch(resetAllDataGridTableFilters())
        store.dispatch(resetGraphSampleSelected())

        store.dispatch(fetchFlowsLandingPageList()).then((resp, reject) => {

            if (resp.data?.length <= 0) {
                setEmpty(true);
            }
            if (resp && resp.data.length) {
                setshowLoadingMsg(false)
                setEmpty(false);
                // if (resp.data[0].view_type == "grid") {
                //     setshowGridView(true);
                //     setshowListView(false)

                // }
                // else {
                //     setshowListView(true)
                //     setshowGridView(false);

                // }
                setresponseData(resp.data)
                getDataDomainList(resp?.data);
                setData(resp.data)
            } else {
                setshowLoadingMsg(false)
                setEmpty(true);

            }

        }).catch((err) => {
            setshowLoadingMsg(false)
            setEmpty(false);

            setErrorMsg(err.message)
        })
    }, [])

    useEffect(() => {
        if (filterData().length && searchData !== '') {
            let filteredData = filterData().filter((data) => {
                return (data?.name?.toLowerCase().includes(searchData?.toLowerCase()) ||
                    data?.description?.toLowerCase().includes(searchData?.toLowerCase()) ||
                    getRelativeTime(data?.created_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
                    data?.created_by_user?.email?.toLowerCase().includes(searchData?.toLowerCase()) ||
                    getRelativeTime(data?.updated_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
                    data?.updated_by_user?.email?.toLowerCase().includes(searchData?.toLowerCase())) ||
                    data?.pod_detail?.find((item, i) => {

                        return (item?.data_domain_name?.toLowerCase().includes(searchData?.toLowerCase()) || item?.table_name?.toLowerCase().includes(searchData?.toLowerCase()))

                    });
            })
            setData(filteredData)
        } else {
            setData(filterData())

        }


        // getVirtualTableRecords(data,columns)
    }, [searchData, selectedFilters, visible, renameText])

    let columns = [
        {
            name: <span style={{ paddingLeft: '48px' }}>Flow Name</span>,
            dataIndex: "flow_name",

            resizable: fromRoute !== 'alert' && fromRoute !== 'scheduler' ? true : false,
            key: "flow_name",
            sorter: {
                compare: (a, b) => a?.flow_name?.props?.title !== undefined ? a?.flow_name?.props?.title?.localeCompare(b?.flow_name?.props?.title) : null,
            },
            width: "20%"
        },
        {
            name: Literals.DESCRIPTION,
            dataIndex: "description",
            key: "description",
            width: "30%",
            resizable: fromRoute !== 'alert' && fromRoute !== 'scheduler' ? true : false,

            className: "description",
            render: text => <div>
                <p>{text}</p>
            </div>,
            sortable: false

        },

        {
            name: Literals.CREATED_ON,
            dataIndex: "created_on",
            key: "created_on",
            resizable: false,
            // width: 200,
            sorter: {
                compare: (a, b) => a?.created_on?.props?.title !== undefined ? a?.created_on?.props?.title?.localeCompare(b?.created_on?.props?.title) : null

            },
        },
        {
            name: Literals.CREATED_BY,
            dataIndex: "created_by",
            key: "created_by",
            resizable: false,
            // width: 250,
            sorter: {
                compare: (a, b) => a?.created_by?.props?.title !== undefined ? a?.created_by?.props?.title?.localeCompare(b?.created_by?.props?.title) : null

            },
        },
        // {
        //     name: Literals.UPDATED_ON,
        //     dataIndex: "updated_on",
        //     key: "updated_on",
        //     width: "10%",
        //     sorter: {
        //         compare: (a, b) => a?.updated_on?.props?.title !== undefined ? a?.updated_on?.props?.title?.localeCompare(b?.updated_on?.props?.title) : null

        //     },
        // },
        {
            name: Literals.UPDATED_BY,
            dataIndex: 'updated_by',
            resizable: fromRoute !== 'alert' && fromRoute !== 'scheduler' ? true : false,

            // width: 250,
            key: "updated_by",
            sorter: {
                compare: (a, b) => a?.updated_by?.props?.title !== undefined ? a?.updated_by?.props?.title?.localeCompare(b?.updated_by?.props?.title) : null

            },
        },
        {
            dataIndex: 'icon',
            resizable: false,
            width: "12%",
            name: <div className='text-center'>Action</div>,
            key: 'icon',
            sortable: false,


        }
    ];

    if (fromRoute === 'alert' || fromRoute === 'scheduler') {
        columns = columns.slice(0, columns.length - 1)
    }

    const onRowSelection = (record, rowIndex, e) => {

        if (fromRoute !== 'alert' && fromRoute !== 'scheduler') {
            history(`${Literals.links.FLOWS}/${record.key}`)
        } else {
            setshowSelectedCardId(record.key)
            if (fromRoute === 'alert') {

                dispatch(getFlowsAlertTransformerList(record.key, record.allData)).then((resp) => {
                    emitToastNotification('Success', 'Alert Fetched Successfully')
                })
            } else if (fromRoute === 'scheduler') {
                store.dispatch({
                    type: SET_SELECTED_ALERT_TRANSFORMER,
                    payload: {
                        flowId: record.key,
                        alertList: [],
                        selectedFlowDetails: record.allData,
                    },
                });
            }
        }

    }

    const getComparator = (sortColumn) => {
        switch (sortColumn) {
            case 'flow_name':
                return (a, b) => a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight) : null
            case 'created_on':
                return (a, b) => a[sortColumn]?.props?.title !== undefined ? a[sortColumn]?.props?.title?.localeCompare(b[sortColumn]?.props?.title) : null
            case 'created_by':
                return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
            case 'updated_by':
                return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }

    const getFormattedData = (allData = []) => {

        let dataDomain = []
        let favourites = []
        if (selectedFilters.length > 0) {
            selectedFilters.forEach(val => {
                if (val['Data Domain']) {
                    dataDomain = val['Data Domain'].map(val => val.name) || []
                }
                if (val['emptyheader']) {
                    favourites = val['emptyheader'].map(val => val.name) || []
                }
            })
        }

        let categories = [];

        const dataDomainList = new Set();
        allData.map(item => {
            for (let i = 0; i < item?.pod_detail?.length; i++) {
                dataDomainList.add(item?.pod_detail[i]?.data_domain_name)
            }
        });

        dataDomainList.forEach((name, key) => categories.push({ id: key, checked: dataDomain.includes(name), name: name, category: name }))
        const obj2 = {
            header: 'Data Domain',
            selectAll: false,
            list: categories
        }
        const obj = {
            header: '',
            list: [{ id: 1, checked: favourites.length > 0 && favourites.includes('Created by me'), name: 'Created by me' }, { id: 2, checked: favourites.length > 0 && favourites.includes('Favourites'), name: 'Favourites' }]
        }
        return [obj, obj2]
    }
    const getFiltersStatus = () => {
        let filters = [...selectedFilters],
            status = null
        if (filters.length === 0) status = false
        else if (filters.length > 0) {
            status = !filters.map(v => v[Object.keys(v)[0]].length > 0).every(v => v === false)
        }
        console.log(status, selectedFilters)
        return status

    }

    return (
        <React.Fragment>
            {/* {
                !showDetailsView ? */}

            {showFilter ? <Filter props={{ from: "flowSelectionModal",  showPopover:"new", filterList: getFormattedData(responseData), type: "flow", selectedFilters: selectedFilters, setselectedFilters: setselectedFilters, setshowFilter: setshowFilter }} /> : null}
            {fromRoute !== 'alert' && fromRoute !== 'scheduler' ? <LandingpageFirstHeader title={Literals.FLOWS} flag={false} backRoute={"/flows"} /> : null}
            <LandingPageSubHeader props={{ defaultActiveTab: 'allflows', tabsMenuList: tabsMenuList, setSearchData: setSearchData, moduleType: "flow", defaultValue: showListView ? 'listView' : 'gridView', setshowFilterPopup: setshowFilterPopup, setshowGridView: setshowGridView, setshowListView: setshowListView, isFilterApplied: getFiltersStatus(), fromRoute: fromRoute, isDisableAllOptions: empty }} />
            {fromRoute !== 'alert' && fromRoute !== 'scheduler' ? <Buttons props={{
                tooltip: 'Create Flow',
                buttonText: '', buttonClassName: "custom-btn-primary custom-btn fixed-button", buttonEvent: () => {
                    setOpenModal(true)
                    store.dispatch(setToDefault(``))
                }, ImgSrc: () => <PLUS />,
                isDisable: false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
            }} /> : null}
            {errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> : listData && listData.length ? <div className="flow-landing-content">
                {
                    showGridView && <div className="flow-card-view" style={{ background: fromRoute === 'alert' ? "#FFFFFF" : '', height: fromRoute === 'alert' ? "405px" : '' }}>{
                        listData.map((data, index) => <FlowsCardView
                            key={index}
                            getUpdatedFlowsList={getUpdatedFlowsList}
                            setshowSelectedCardId={setshowSelectedCardId}
                            showSelectedCardId={showSelectedCardId}
                            bookmarkData={bookmarkData}
                            data={data}
                            handleDelete={openDeleteDialog}
                            setshowDetailsView={setshowDetailsView}
                            fromRoute={fromRoute} />)
                    }
                    </div>
                }

                {/* {
                    showListView && <div className="landing-page-accordion"><Accordian props={{ isDisable: false, accordionList: accordionList, Icon: () => <ACCORDIONDOWNARROW />, defaultExpandedPanel: [] }} /></div>
                } */}

                {showListView && <div className="padding-2">    <LandingSimpleTable
                    cols={columns}
                    rowsData={listData}
                    autoWidth={fromRoute === 'alert' || fromRoute === 'scheduler' ? false : true}
                    selectedRowIndex={showSelectedCardId}
                    onRowClick={(record, index, e) => { onRowSelection(record, index, e) }}
                    getComparator={getComparator}
                    tableHeight={'calc(100vh - 124px)'}
                // tableHeight={TableHeight}
                /> </div>}


            </div> :
                empty ?
                    <NoDataComponent logo={<SMALLFLOWS width="60" height="60" color="black" />}
                        message=" Click On (+) To Create Flow" /> :

                    (!loadingStatus ? (searchData !== '' || selectedFilters.length) &&
                        (listData && !listData.length) ? <NoSearchResultFound mainMsg={searchData === '' ? 'No Flow List Found' : ''} subMsg={'Please try Search / Filter with another term'} />
                        : showLoadingMsg ? showSimpleLoading("Please Wait Loading Flows List...") : null : null)}

            <NewFlowsPopUp openModal={openModal} setOpenModal={setOpenModal} />
            {props.loadingStatus ? <LoadingComponent /> : null}

            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={DeleteNoteContent(currentFlowIdRef?.current, 'Flow', ` Deleting this Flow might affect Alert, Scheduler.`)}
                // body={getDeleteMessage({
                //     title: 'Flow'
                // })}
                onConfirm={() => deleteFlow(currentFlowIdRef?.current)}
            />

            {/* <ActionPopUp
                openModal={openDeletePopup}
                actionData={deletePopUpContent} /> */}



        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        loadingStatus: state.LoadingReducer.loadingStatus,
    };
};
export default connect(mapStateToProps)(FlowsLandingPage)
// : <img alt='details' height="100%" width="100%" onClick={() => setshowDetailsView(false)} src={FlowDetailsView}></img> }