import moment from "moment-timezone";
import { SET_SCHEDULER_PAYLOAD, SET_SELECTED_DASHBOARD, RESET_SCHEDULER_REDUX_, SET_SELECTED_SCHEDULER, SET_TEMP_SELECTED_SCHEDULER, SET_SELECTED_DASHBOARD_TEMP } from "./SchedulerActionType";



const initialState = {
    schedulerPayload: {
        "name": "",
        "description": "",
        "trigger_type": "TIME",
        "task_type": "FLOW",
        "is_active": true,
        "cron": '0,8 13 * * 1',
        "cron_redux": {},
        "dependant_status": "SUCCESS",
        "dependant_scheduler": null,
        "pod_ids": [],
        "last_count_flag": false,
        "dependant_query": null,
        "monitor_frequency": 900,
        "last_count": 0,
        "offset": 10,
        "recipients": {},
        "selectedScheduledDashboard": {},
        "selectedScheduledDashboardTemp": {}
    },
    selectedTempScheduler: {},
    selectedScheduler: {}
}

const SchedulerReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SCHEDULER_PAYLOAD:
            return { ...state, schedulerPayload: payload }

        case SET_SELECTED_SCHEDULER:
            return { ...state, selectedScheduler: payload }
        case SET_TEMP_SELECTED_SCHEDULER:
            return { ...state, selectedTempScheduler: payload }

        case SET_SELECTED_DASHBOARD:
            return { ...state, selectedScheduledDashboard: payload }

        case SET_SELECTED_DASHBOARD_TEMP:
            return { ...state, selectedScheduledDashboardTemp: payload }

            
        case RESET_SCHEDULER_REDUX_:
            initialState['schedulerPayload'] = {
                "name": "",
                "description": "",
                "trigger_type": "TIME",
                "task_type": "FLOW",
                "is_active": true,
                "cron": '0,8 13 * * 1',
                "cron_redux": {
                    cron_timezone: moment.tz.guess()
                },
                "dependant_status": "SUCCESS",
                "dependant_scheduler": null,
                "pod_ids": [],
                "last_count_flag": false,
                "dependant_query": null,
                "monitor_frequency": 900,
                "last_count": 0,

                "offset": 900,

                "recipients": {}
            }
            return initialState

        default:
            return state;

    }
}

export default SchedulerReducer