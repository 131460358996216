export const SHOW_FLOWS_LOWERSECTION = 'SHOW_FLOWS_LOWERSECTION'
export const SHOW_FLOWS_LOGS_LOWERSECTION = 'SHOW_FLOWS_LOGS_LOWERSECTION'
export const SHOW_FLOWS_JUPYTERNOTEBOOK = 'SHOW_FLOWS_JUPYTERNOTEBOOK'
export const SHOW_FLOWS_DATABRICKSNOTEBOOK = 'SHOW_FLOWS_DATABRICKSNOTEBOOK'
export const SHOW_FLOWS_TRANSFORMDETAILS = 'SHOW_FLOWS_TRANSFORMDETAILS'
export const UPDATE_FLOWS_ELEMENTS = 'UPDATE_FLOWS_ELEMENTS'
export const SET_FLOWS_CURRENTTRANSFORMER = 'SET_FLOWS_CURRENTTRANSFORMER'
export const SHOW_FLOWS_ATTOP = 'SHOW_FLOWS_ATTOP'
export const UPDATE_FLOWS_BASICDETAILS = 'UPDATE_FLOWS_BASICDETAILS'
export const SET_TRANSFORMERLOADING_STATUS = 'SET_TRANSFORMERLOADING_STATUS'
export const SET_TRANSFORMER_PAYLOAD = 'SET_TRANSFORMER_PAYLOAD'
export const SET_DATASOURCE_PODS = 'SET_DATASOURCE_PODS'
export const SET_TO_DEFAULT = 'SET_TO_DEFAULT'
export const SET_FILTER_TRANSFORMER_CRITERIA = 'SET_FILTER_TRANSFORMER_CRITERIA'
export const ADD_EDGES = 'ADD_EDGES'
export const SHOW_FLOWS_TIMELINE_LOWERSECTION = "SHOW_FLOWS_TIMELINE_LOWERSECTION"
export const ADD_EDITOR_TEXT = 'ADD_EDITOR_TEXT'
export const SET_FLOWS_SHOWLEFTSIDEDETAILS = 'SET_FLOWS_SHOWLEFTSIDEDETAILS'
export const SET_SQL_TRANSFORMER_QUERY = 'SET_SQL_TRANSFORMER_QUERY'
export const SAVE_NOTE_CLICK = 'SAVE_NOTE_CLICK'
export const SET_TRANSFORMER_MAXID = 'SET_TRANSFORMER_MAXID'
export const SET_FLOWS_PERMISSIONS = 'SET_FLOWS_PERMISSIONS'
export const SET_TRANSFORMER_NAME = 'SET_TRANSFORMER_NAME'
export const SAVE_FLOW_CLICK = 'SAVE_FLOW_CLICK'
export const SET_CURRENT_SCRIPTOUTPUT_NODE = 'SET_CURRENT_SCRIPTOUTPUT_NODE'
export const SET_SQL_EDIT_MODE = 'SET_SQL_EDIT_MODE'
export const SET_FLOWS_LANDINGPAGE_ACTIVE_TAB = 'SET_FLOWS_LANDINGPAGE_ACTIVE_TAB'
export const SET_CURRENT_EXECUTING_TRANSFORMER_ID = 'SET_CURRENT_EXECUTING_TRANSFORMER_ID'
export const SET_ENTIREFLOW_EXECUTE_START = 'SET_ENTIREFLOW_EXECUTE_START'
export const SET_TRANSFORMER_PROGRESS = 'SET_TRANSFORMER_PROGRESS'
export const SET_TRANSFORMER_STATUS_CHANGE = 'SET_TRANSFORMER_STATUS_CHANGE'
export const SHOW_BRICKS_MENU = 'SHOW_BRICKS_MENU'
