import React, { useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useParams } from 'react-router'
import { setLoadingStatus } from '../../../../../store/modules/common/loadingActions'
import { showDatabricksNoteBook, showJupyterNoteBook } from '../../../../../store/modules/flows/flowsActions'
import { saveDatabricksNotebook, saveJupyterNotebook, setNotebookUrl, setScriptPythonCode } from '../../../../../store/modules/flows/scriptTransformer/scriptAction'
import Buttons from '../../../../Common/button/Buttons'
import { CLOSE, SAVE, SCRIPTEDITOR } from '../../../../Common/iconSource'
import { deleteCookies } from '../../../common/helperFunctions'
import { Literals } from '../../../common/literals'

function DatabricksNotebook() {
    const store = useStore()
    const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

    const { flowId } = useParams()
    const JupyterNotebookUrl = useSelector(state => state.Flows.Script.DatabricksNotebookURL)
    const currentTransformer = useSelector(state => state.FlowsReducer.currentTransformer)
    const flowsElements = useSelector(state => state.FlowsReducer.flowsElements)
    const [notebookUrl, setnotebookUrl] = useState('')
    const [hideNotebook, sethideNotebook] = useState(false)
    useEffect(() => {

        console.log(JupyterNotebookUrl)
        if (JupyterNotebookUrl !== "") {
            setnotebookUrl(JupyterNotebookUrl)

        }
    }, [JupyterNotebookUrl])


    const saveNoteBook = () => {

        let commonPayload = store.getState().Flows.Script.scriptCommonPayload
        if (Object.keys(commonPayload).length > 0) {
            store.dispatch(saveJupyterNotebook(flowId, commonPayload)).then((resp) => {
                if (resp && resp.status === 'success') {
                    deleteCookies();
                    setnotebookUrl(`${BASE_URL}jupyterhub/hub/logout`)
                    store.dispatch(setLoadingStatus(true))

                    store.dispatch(setNotebookUrl(`${BASE_URL}jupyterhub/hub/logout`))
                    setTimeout(() => {
                        sethideNotebook(false)
                        store.dispatch(setLoadingStatus(false))

                        store.dispatch(setScriptPythonCode({ code: resp.data.code, id: currentTransformer.id }))
                        store.dispatch(showJupyterNoteBook(false))

                    }, 2000);

                }


            })

        } else {

            let outputDataSetList = store.getState().Flows.Script.scriptOutputDataset;
            let Frame = []
            if (outputDataSetList.length && currentTransformer.id && currentTransformer.transformerName) {
                let outputDataSetValue = outputDataSetList.map((ele) => ele.value)


                let currentDataSource = flowsElements.find(item => item.id === currentTransformer.id)
                if (currentDataSource && currentDataSource.input_source && Object.keys(currentDataSource.input_source).length) {
                    let podDetails = store.getState().FlowsReducer.dataSourcePodDetails;

                    Object.keys(currentDataSource.input_source).forEach((inputTransformer, i) => {
                        let transformerDetails = flowsElements.find((ele) => ele.id === inputTransformer);

                        if (transformerDetails && transformerDetails.type.includes('datasource')) {
                            if (podDetails.length) {
                                let pod = podDetails.find((ele) => Object.keys(ele)[0] === inputTransformer)
                                if (pod) {
                                    Frame.push(`${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName}`)
                                }
                            }
                        } else {
                            Frame.push(transformerDetails?.transformerName)
                        }

                    })
                }
                let payload = {
                    "flow_body": {
                        "Id": currentTransformer.transformerName,
                        "NodeType": "databricks_script_nb_python3",
                        "Args": [
                            {
                                "Name": "input",
                                "Value": Frame
                            },
                            {
                                "Name": "output",
                                "Value": outputDataSetValue
                            },
                            {
                                "Name": "script_name",
                                "Value": ""
                            }
                        ]
                    }
                }
                store.dispatch(saveJupyterNotebook(flowId, payload)).then((resp) => {




                    if (resp && resp.status === 'success') {
                        deleteCookies();
                        setnotebookUrl(`${BASE_URL}jupyterhub/hub/logout`)

                        store.dispatch(setLoadingStatus(true))
                        store.dispatch(setNotebookUrl(`${BASE_URL}jupyterhub/hub/logout`))
                        setTimeout(() => {
                            sethideNotebook(false)
                            store.dispatch(setLoadingStatus(false))

                            store.dispatch(setScriptPythonCode({ code: resp.data.code, id: currentTransformer.id }))
                            store.dispatch(showJupyterNoteBook(false))


                        }, 2000);

                    }

                })
            }

        }

    }
    const onCloseNotebook = () => {
        let outputDataSetList = store.getState().Flows.Script.scriptOutputDataset;
        let Frame = []
        if (outputDataSetList.length && currentTransformer.id && currentTransformer.transformerName) {
            let outputDataSetValue = outputDataSetList.map((ele) => ele.value)


            let currentDataSource = flowsElements.find(item => item.id === currentTransformer.id)
            if (currentDataSource && currentDataSource.input_source && Object.keys(currentDataSource.input_source).length) {
                let podDetails = store.getState().FlowsReducer.dataSourcePodDetails;

                Object.keys(currentDataSource.input_source).forEach((inputTransformer, i) => {
                    let transformerDetails = flowsElements.find((ele) => ele.id === inputTransformer);

                    if (transformerDetails && transformerDetails.type.includes('datasource')) {
                        if (podDetails.length) {
                            let pod = podDetails.find((ele) => Object.keys(ele)[0] === inputTransformer)
                            if (pod) {
                                Frame.push(`${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName}`)
                            }
                        }
                    } else {
                        Frame.push(transformerDetails?.transformerName)
                    }

                })
            }
            let payload = {
                "flow_body": {
                    "Id": currentTransformer.transformerName,
                    "NodeType": "databricks_script_nb_python3",
                    "Args": [
                        {
                            "Name": "input",
                            "Value": Frame
                        },
                        {
                            "Name": "output",
                            "Value": outputDataSetValue
                        },
                        {
                            "Name": "script_name",
                            "Value": ""
                        }
                    ]
                }
            }
            store.dispatch(saveDatabricksNotebook(flowId, payload)).then((resp) => {

                store.dispatch(setScriptPythonCode({ code: resp.data.content, id: currentTransformer.id, path: resp.data.path }));

                sethideNotebook(false)
                store.dispatch(setLoadingStatus(false))

                store.dispatch(showDatabricksNoteBook(false))
            })

        }


    }

    console.log(notebookUrl)
    return (

        <div className="bg-white section-with-drag flow-filter-height w-100">
            <div className="p-2">
                {/* <div className="d-flex align-items-center">
                    <SCRIPTEDITOR />
                    <p className="subtitle mb-0 ml-2">CodeEditor</p>
                    <div className="alert alert-warning border-0 mb-1 px-2 py-1 rounded-0 small ml-2">
                        Note: Click on SAVE button of Jupyter notebook to save your changes and then click on SAVE FLOW button.
                    </div>
                </div> */}

                <div className="p-2">
                    <Buttons props={{ buttonText: '', buttonClassName: "mr-2 mb-2 float-right custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { onCloseNotebook() }, toggleBtnValue: '', ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY, toggleBtnOption: null }} />
                    {/* <Buttons props={{ buttonText: 'Save Flow', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { saveNoteBook() }, toggleBtnValue: '', ImgSrc: () => <SAVE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT, toggleBtnOption: null }} /> */}
                </div>
            </div>
            {notebookUrl !== '' && !hideNotebook ? <iframe id="my_databricks_iframe" title='Notebook' src={notebookUrl} width="100%" height="100%" /> : null}

        </div>

    )
}

export default DatabricksNotebook
