import React from 'react'
import { ICON_CONFIRM } from '../../Common/newIconSource'

const SuggestionPill = ({
    msg = '',
    onClick = () => {}
}) => {
    return (
        <div className='cpilot-sugg-pill d-flex align-items-center px-2 py-1 mb-2'
            onClick={() => onClick(msg)}
        >
            <span className='mr-1 d-flex'>
                <ICON_CONFIRM width='14' height='14'/>
            </span>
            <span className='fontSizeLabel'>
                {msg}
            </span>
        </div>
    )
}

export default SuggestionPill