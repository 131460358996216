import { Tooltip } from 'antd'
import React from 'react'
import { ICON_ARROW_RIGHT } from '../newIconSource'
import { useSelector } from 'react-redux'
import './leftsidepaneltoggler.scss'

const LeftSidePanelToggler = ({
    open = false,
    setOpen = () => {},
    callBack = () => {},
    customTooltip = 'Left Panel'
}) => {

    const isSidebarActive = useSelector(state => state.Layout.isSidebarVisible) // For handling when sidebar is not visible

    const handleToggle = () => {
        setOpen(!open)
        callBack()
    }

    return (
        <Tooltip title={`${open ? 'Hide' : 'Show'} ${customTooltip  ?? 'Left Panel'}`} placement="right">
            <div 
                className={`custom-panel-toggler ${open ? 'op' : 'cl'} ${!isSidebarActive ? 'cus-sidebar-hidden' : ''}`} 
                onClick={handleToggle}>
                <ICON_ARROW_RIGHT height="13" width="13" color="#676767" />
            </div>
        </Tooltip>
    )
}

export default LeftSidePanelToggler