import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Buttons from "../../../../Common/button/Buttons";
import { CLOSE, EDIT, LINEAGE_ICON, SAVE } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";

const ViewUserSecondaryHeader = ({ isDisabled, onEdit }) => {
  const history = useNavigate();
  const location = useLocation()
  const { id } = useParams();
  const onCloseHandler = () => {
    history(redirectBack());
  };
  const redirectBack = () => {

    return Literals?.links?.USER_MANAGEMENT + "#users"

    // if (location?.state && location?.state?.tabName) {
    //   return { pathname: Literals.links.USER_MANAGEMENT, state: { tabName: location?.state?.tabName } }
    // }
    // else {
    //   return { pathname: Literals.links.USER_MANAGEMENT }
    // }
  }
  return (
    <div className="sub-header create-pod-secondary-header">
      <div className="sub-header-left">
        <h2 className="section-heading">User</h2>
      </div>
      <div className="sub-header-right">

        {/* <Buttons
          props={{
            tooltip: `Show Lineage`,
            buttonText: '', 
            buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon",
            buttonEvent: () => {


              let path=`/viewLineage/user/${id}`;
            
              window.open(path)

            }, 
            ImgSrc: () => <LINEAGE_ICON/>, 
           
            buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
        }} 
        
        /> */}
        <Buttons
          props={{
            buttonText: "Edit",
            buttonClassName: "custom-btn-primary custom-btn btn-with-text mr-2",
            buttonEvent: () => {
              onEdit();
            },
            ImgSrc: () => <EDIT />,
            isDisable: isDisabled,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />

        <Buttons
          props={{
            buttonText: "",
            buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
            buttonEvent: () => {
              onCloseHandler();
            },
            ImgSrc: () => <CLOSE />,

            isDisable: false,
            buttonType: Literals.BTN_TERTIARY,
          }}
        />
      </div>
    </div>
  );
};

export default ViewUserSecondaryHeader;
