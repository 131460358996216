import React, { Fragment, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import "./CreateButton.scss";

import { PLUS, TABLEUPDATE, UPLOAD } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import Buttons from "../../../../Common/button/Buttons";

import ConnectionContext from "../../../connections/landingPage/components/ConnectionContext";
import { Tooltip } from "antd";
import logo_image from '../../../../../assets/images/new-logo-2.png'
import { ICON_CREATE_POD, ICON_MULTIPLE_FILES } from '../../../../Common/newIconSource';
import { useSelector } from 'react-redux';
import { getSimpleTutorialMessageTemplate } from '../../../common/helperFunctions';
import Walkthrough from '../../../../Common/walkthrough/Walkthrough';
import { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

const CreateButton = ({ openConnectionModal, isEditPermission = true }) => {
 
  const history = useNavigate();
  const { setFromConnectionRoute } = useContext(ConnectionContext);
  const [isButtonActive, setIsButtonActive] = useState(false);
  
  const isTutorialActive = useSelector(state => state?.CommonComponent?.TutorialRedux?.navigator?.create_pod)
  const isFileUploadTutorial = useSelector(state => state?.CommonComponent?.TutorialRedux?.navigator?.file_upload)
  const isPoPTutorial = useSelector(state => state?.CommonComponent?.TutorialRedux?.navigator?.pod_of_pod)

  const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

  const noOpenAiAccess = (userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false)

  const walkthroughSteps = React.useMemo(() => {
    let steps = [
        {
            content: <>
                {getSimpleTutorialMessageTemplate('Add Button', 
                  !isButtonActive ? 'Click on (+) this button' : 'Now click on "Next" button'
                )}
            </>,
            placement: "top",
            target: '#createPodBtn',
            title: "Step 1",
            disableBeacon: true,
            hideFooter: true
        },
        {
          content: <>
              {getSimpleTutorialMessageTemplate('Create Pod', 
                'After clicking (+) button, click on this button'
              )}
          </>,
          placement: "left",
          target: isFileUploadTutorial 
                  ? '#create-pod-fileupload' 
                  : isPoPTutorial ? '#create-pod-podofpod' : '#create-pod-btn',
          title: "Step 2",
          locale: { last: "Got it" },
          disableBeacon: true,
          hideFooter: true
        }
    ]
    return steps
  }, [isButtonActive, isPoPTutorial, isFileUploadTutorial])

  const [walkthrough, setWalkThrough] = React.useState({
      run: false,
      steps: [...walkthroughSteps],
      stepIndex: 0
  })

  React.useEffect(() => {
    setWalkThrough(prev => ({...prev, steps: walkthroughSteps}))
  }, [walkthroughSteps])

  React.useEffect(() => {    
    if(isButtonActive) {
      setIsButtonActive(false)
    }
    startWalkthrough(isTutorialActive)
  }, [isTutorialActive])

  const startWalkthrough = (bool) => {
    setWalkThrough(prev => ({...prev, run: bool}))
  }

  const toggleButtonHandler = () => {
    setIsButtonActive((isButtonActive) => !isButtonActive);
  };

  const closeButtonHandler = () => {
    setIsButtonActive(false);
  };

  const openConnectionModalHandler = () => {
    setFromConnectionRoute(true);
    openConnectionModal();
  };

  return (
    <Fragment>
      <Walkthrough
        walkthrough={walkthrough}
        showSkipButton={true}
        spotlightClicks={true}
        disableOverlayClose={true}
        stepIndex={walkthrough.stepIndex}
      />
      {isButtonActive && (
        <div
          className="rotate-button-backdrop"
          onClick={closeButtonHandler}
        ></div>
      )}
      <div className="options-wrapper">
        <Buttons
          props={{
            buttonId: 'createPodBtn',
            buttonText: "",
            buttonClassName: `custom-btn-primary custom-btn fixed-button addbutton_plusicon ${
              isButtonActive ? "rotate-icon" : ""
            }`,
            buttonEvent: () => {
              // if(!isEditPermission) return
              if(isTutorialActive) {
                setWalkThrough(prev => ({...prev, stepIndex: walkthrough.stepIndex + 1}))
              }
              toggleButtonHandler();
            },
            tooltip: "Create POD",
            tooltipPlacement:"left",
            ImgSrc: () => <PLUS />,
            // isDisable: !isEditPermission,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
        <ul
          className={`add-options-list ${isButtonActive ? "active-list" : ""}`}
        >
          {/* <Tooltip placement="left" title="File Upload">
            <li id='create-pod-fileupload' onClick={() => history(Literals.links.DATA_CATALOG_FILE_UPLOAD)}>
              <UPLOAD height='25' width='25'  />
            </li>
          </Tooltip>
          <Tooltip placement="left" title={Literals.POD_OF_POD_API}>
            <li id='create-pod-podofpod' onClick={() => history(Literals.links.DATA_CATALOG_POD_OF_POD)}>
              <img src={logo_image} className='img-fluid' alt='pod-of-pod' />
            </li>
          </Tooltip> */}
          <Tooltip placement="left" title={noOpenAiAccess ? "OpenAI Access Required!" : "Unstructured"}>
            <li id='unstructured-create-pod-btn' 
            onClick={() => {
              if(noOpenAiAccess) return
              history(Literals.links.UNSTRUCTURED_POD_CREATION)
            }}
              style={{
                opacity: noOpenAiAccess ? 0.6 : '',
                cursor: noOpenAiAccess ? 'not-allowed' : 'pointer',
                filter: noOpenAiAccess ? 'grayscale(1)' : 'none'
              }}  
            >
              <ICON_MULTIPLE_FILES height='25' width='25' />
            </li>
          </Tooltip>
          <Tooltip placement="left" title="Structured">
            <li id='create-pod-btn' onClick={() => history(Literals.links.POD_CREATION)}>
              <ICON_CREATE_POD height='25' width='25' />
            </li>
          </Tooltip>
        </ul>
      </div>
    </Fragment>
  );
};

export default CreateButton;
