import React from 'react'
import { useSelector } from 'react-redux'
import SourceTypeImage from '../../../common/sourceTypeImage'
import { Tooltip } from 'antd'
import Buttons from '../../../../Common/button/Buttons'
import { Literals } from '../../../common/literals'
import { EDIT } from '../../../../Common/iconSource'

const SmallConnDetails = ({
    isPodOfPod = false
}) => {

    const selectedConn = useSelector((state) => {
        return state.UserManagement.ConnectionReducer.selectedConn
    })

    const connName = selectedConn?.connection_name??''
    const connType = selectedConn?.source_type??''

    return (
        <div>
            <div className='pod-crt-src-conn-detail'>
                        <span className='cn-img'>
                            <SourceTypeImage dataName={isPodOfPod ? Literals.POD_OF_POD_API :connType} />
                        </span>
                        <div>
                            <label className='small text-black-50 mb-0 fontSizeLabel fontInterSemiBold'>Connection</label>
                            <Tooltip title={connName?.toUpperCase()??''} placement='topLeft'>
                                <p className='cn-txt mb-0 fontSizeHeading text-with-ellipsis text-uppercase'>
                                    {isPodOfPod ? 'Navigator' : connName}
                                </p>
                            </Tooltip>
                            {/* <span className='cn-edit-btn'>
                                <Buttons
                                    props={{
                                        tooltip: "Edit Connection",
                                        buttonText: "",
                                        tooltipPlacement: "left",
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                                        buttonEvent: () => {
                                            
                                        },
                                        ImgSrc: () => <EDIT />,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </span> */}
                        </div>
                    </div>
        </div>
    )
}

export default SmallConnDetails