import React from 'react'
import { useDispatch } from 'react-redux'
import { getTrackersList } from '../../../../store/modules/common/GenAi/GenAiReducer'
import Conversation from './ChatContentBox'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { NoDataComponent, getReloadErrorTemplate, pxToRem, remToPx } from '../../common/helperFunctions'
import { ICON_TRACK } from '../../../Common/newIconSource'
import { ContentViewGridTable } from '../../../Common/contentGridTable/ContentViewGridTable'
import NoSearchResultFound from '../../common/NoSearchResultFound'

const TrackerIndex = ({
    activeDomain,
    searchTerm = ''
}) => {

    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const [errMsg, setErrorMsg] = React.useState(undefined);
    const [stopLazyload, setStopLazyload] = React.useState(false);
    const [loadMore, setLoadMore] = React.useState(false);
    const [pageNo, setPageNo] = React.useState(1);
    const [data, setData] = React.useState([]);
    const [empty, setEmpty] = React.useState(false);
    
    const convLoadedRef = React.useRef({})
    const chartRefMap = React.useRef({})
    const kpiRefMap = React.useRef({})
    const searchRef = React.useRef('')

    React.useEffect(() => {
        if (pageNo !== 1) {
            setPageNo(1)
        }
        searchRef.current = searchTerm?.toLowerCase()
        // getUsersList()
        setLoading(true)
        fetchTrackers(true)
    }, [searchTerm, activeDomain])

    const fetchTrackers = (reset = false) => {
        if(!activeDomain || activeDomain === '') {
            setLoading(false)
            return
        }

        setStopLazyload(false)
        setErrorMsg(undefined)

        const pageNumber = reset ? 1 : pageNo
        const pageLimit  = 6
        const search     = searchRef?.current?.trim() ?? ''

        dispatch(getTrackersList(
                activeDomain,
                search,
                pageNumber, 
                pageLimit, 
                data?.length > 0
            )
        )
        .then(res => {
            const dat = res?.data

            if(dat) {
                if(dat?.results) {
                    setData(prev => reset ? [...dat?.results] : [...prev, ...dat?.results])
                }
    
                if (dat?.has_next === false) {
                    setStopLazyload(true)
                    setLoadMore(false)
                }
            }

            if (res?.status === "success") {
                setLoadMore(false)
                setPageNo(prev => prev + 1)
            }

            setEmpty(dat?.results?.length === 0)
            setLoading(false)
        })
        .catch(err => {
            console.error({err})
            setErrorMsg(err?.message)
            setEmpty(false)
            setLoading(false)
            setLoadMore(false)
        })
    }

    // const getUsersList = () => {
    //     setUsersLoading(true)
    //     dispatch(getUserGroupList({ method: "GET", endPoint: "profile/", payload: undefined, showLoader: false }))
    //     .then(res => {
    //         let list = res?.data?.map(ele => ({ name: `${ele?.first_name} ${ele?.last_name}`, value: ele?.id, email: ele?.email }))
    //         setUsersList([...list]);
    //         setUsersLoading(false)
    //     })
    //     .catch(err => {
    //         setUsersLoading(false)
    //         console.error(err)
    //     })
    // };

    const conversationArray = React.useMemo(() => {

        let list = [...data]
        // For Local Search
        // list = list?.filter(d => d?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()))

        return list.map((con, i) => (
            <Conversation 
                key={`conv-${i}`} 
                index={i} 
                data={con}
                reload={() => {
                    setPageNo(1)
                    setLoadMore(true)
                    fetchTrackers(true)
                }}
                convLoadedRef={convLoadedRef.current}
                chartRefMap={chartRefMap.current}
                kpiRefMap={kpiRefMap.current}
                isTracker={true}
                searchData={searchTerm}
                isLandingTile={true}
            />
        ))
    }, [data, searchTerm])

    const onScrollToBottom = (paginate) => {
        if (!stopLazyload) {
            if (paginate && !loadMore) {
                setLoadMore(true)
                fetchTrackers()
            }
        }
    }

    const handleColsNumber = () => {
        if(window && window.screen.availWidth <= 768)
        return 1
        return 2
    }

    return (
        <div>
            <div className='tracker-dgt-wrap'>
                {
                    loading 
                    ?   <ClaristaLoader height='200px' />
                    :
        
                    errMsg?.length > 0 && data?.length === 0 
                    ?   getReloadErrorTemplate({ 
                            errorMessage: errMsg, 
                            onReload: () => {
                                setPageNo(1)
                                setLoading(true)
                                fetchTrackers(true)
                            } 
                        })
                    :
        
                    empty 
                    ?   <NoDataComponent 
                            logo={<ICON_TRACK width="60" height="60" />}
                            message="Start tracking conversation" />
                    :

                    (searchTerm?.length > 0) && conversationArray?.length === 0 
                    ?   <NoSearchResultFound />
                    :
                    
                    <ContentViewGridTable
                        colsNumber={handleColsNumber()}
                        data={conversationArray}
                        rowHeight={remToPx(pxToRem(455))}
                        onScrollToBottom={onScrollToBottom}
                        loadMore={loadMore}
                    />
                }
            </div>
        </div>
    )
}

export default TrackerIndex