import { message, Popover, Switch, Tooltip, Select, InputNumber, Avatar } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import Buttons from '../../../Common/button/Buttons'
import { MultipleColsDndList } from '../../../Common/dndVirtualList/MultipleColsDndList'
import SingleSelection from '../../../Common/dropDown/SingleSelection'

import MultiSelection from '../../../Common/dropDown/MultiSelection';
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions';
import moment from 'moment';
import { CHECK, CLOSE, DELETEICON, EDIT, HIDEPREVIEW, MINUS, PLUS, PREVIEW, RESETFILTER, SAVE, SEARCH, SIXDOTS, UPLOAD } from '../../../Common/iconSource'
import { allowOnlyNumberRegex, dataTypeList, toTitleCase, columnNameRegex, capitalizeFirstChar, columnNameRegexExp, correctBusinessTerm } from '../../common/helperFunctions'
import { emitToastNotification } from "../../../../helpers/toast_helper";
import _, { cloneDeep, create, isArray, isInteger, set } from 'lodash';
import { Literals } from '../../common/literals'
import { getSettingsDataCategory, getClassificationData, getSettingsAdditionalDetailsList, createBussinessTerm, deleteBussinessTerm } from '../../../../store/modules/dataDictionary/action'
import { useDispatch, useSelector } from 'react-redux';
import CategoryModal from './categoryModal'
import ClassificationModal from './classificationModal'

import UploadDocModal from '../../dataCatalogue/summary/components/UploadDocModal';
import { setUploadDocFlag } from "../../../../store/modules/dataCatalogue/summary/summaryActions";
import { BusinessTermInput } from '../../dataCatalogue/createPOD2/components/BusinessTermInput'
import { ICON_DELETE, ICON_EYECLOSE, ICON_EYEOPEN } from '../../../Common/newIconSource'


const dummyData =
{

    "data_classification": [],
    "business_term": "",
    "data_category": "",
    "description": "",
    "data_owner": [],
    "data_stewards": [],
    "id": null
}


const ManualCreationFields = () => {

    const [dataTypes,] = useState([...dataTypeList])
    const [businessTerms,] = useState(
        [
            {
                Name: "Sec_ID",
                value: "sec_id",
            },
            {
                Name: "Issuer_Name",
                value: "issuer_name",
            },
            {
                Name: "Ticker",
                value: "ticker",
            },
        ]
    )
    const { Option } = Select;

    const [data, setData] = useState([dummyData])
    const [demo, setDemo] = useState([])
    const [columns, setColumns] = useState([])
    const catalogueSummary = useSelector((state) => state.DataCatalogue.Summary);

    const categoryRef = useRef([])
    const categoryIndexRef = useRef(null)
    const classificationRef = useRef(
        []
    )
    const uploadDocFlag = _.get(catalogueSummary, `uploadDocFlag`, "");

    const [dataCategoryList, setDataCategoryList] = useState([])
    const [dataClassificationList, setDataClassificationList] = useState([])
    const additionalFields = React.useRef([]);
    const [numberOfFieldsToAdd, setNumberOfFieldsToAdd] = useState(1)
    const [showHideFieldPop, setHideFieldPop] = useState(false);
    const [isAddingFields, setIsAddingFields] = useState(false);
    const [isInputReady, setIsInputReady] = useState(true);
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    const [openClassificationModal, setOpenClassificationModal] = useState(false);
    const [hideFieldSearchValue, setHideFieldSearchValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState({})
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showAllCols, setShowAllCols] = useState(true);

    const EMAIL_USER_MAPPING = React.useRef({});
    const [uploadData, setUploadData] = useState([]);
    const [isUploadScreen, setUploadScreen] = useState(false);
    // const [saveAllDisabled, setSaveAllDisabled] = useState(false);
    const [colsList, setColsList] = useState([
        // {key:'technical_term', title: 'Technical Term', isRequired: true, visible: true, width: 180, frozen: true},
        // {key:'datatype', title: 'Data Type', isRequired: true, visible: true, width: 180},
        { key: 'business_term', title: 'Display Name', isRequired: true, visible: true, width: 180, frozen: true },
        // { key: 'data_category', title: 'Data Category', isRequired: false, visible: true, width: 180 },
        { key: 'data_classification', title: 'Data Classification', isRequired: false, visible: true, width: 180 },

        { key: 'description', title: 'Description', isRequired: false, visible: true, width: 180 },
        { key: 'data_owner', title: 'Data Owner(s)', isRequired: false, visible: true, width: 180 },
        { key: 'data_stewards', title: 'Data Steward(s)', isRequired: false, visible: true, width: 180 },
        // {key:'database', title: 'Database', isRequired: false, visible: false, width: 180},
        // {key:'table', title: 'Table', isRequired: false, visible: false, width: 180},
        // {key:'data_domain', title: 'Data Domain', isRequired: false, visible: false, width: 180},
        // {key:'connector', title: 'Connector', isRequired: false, visible: false, width: 180}
    ])
    const saveCategory = (val) => {
        if(val === null || val === undefined) {
            setSelectedCategories(val)
            onInputChangeHandler('data_category', '', categoryIndexRef.current)
            categoryRef.current = ''
            return
        }
        if (val.length) {
            setSelectedCategories(val)
            onInputChangeHandler('data_category', val[0]['name'], categoryIndexRef.current)
            categoryRef.current = [...val]

        }
    }
    const saveClassification = (val) => {
        if(val === null || val === undefined) {
            setSelectedCategories([])
            onInputChangeHandler('data_classification', [], categoryIndexRef.current)
            classificationRef.current = []
            return
        }
        setSelectedCategories(val)
        onInputChangeHandler('data_classification', [val], categoryIndexRef.current)
        classificationRef.current = [val]
    }

    useEffect(() => {

        const key = 'business_term';
        let copyData = cloneDeep(uploadData)
        const arrayUniqueByKey = [...new Map(copyData.map(item =>
            [item[key], item])).values()];
        let uploadData1 = arrayUniqueByKey;
        if (uploadData.length !== arrayUniqueByKey.length && uploadDocFlag == false) {
            alert("Duplicates display names are found, and it's getting automatically removed")
        }


        if (uploadData1.length > 0 && uploadDocFlag == false) {
            setIsInputReady(false)
            setUploadScreen(true)

            let tempData = [];
            let additionalFieldsData = {};
            additionalFields.current.forEach((item) => {
                additionalFieldsData[item.name] = "";
            })
            uploadData1.forEach((item) => {

                let selectedCategoryTemp = dataCategoryList.filter(category => category.value == item.category);
                let data_owner = selectedCategoryTemp[0]?.owner_steward_detail.owner?.map(item => `${item.first_name} ${item.last_name}`) ?? [];
                let data_stewards = selectedCategoryTemp[0]?.owner_steward_detail?.steward?.map(item => `${item.first_name} ${item.last_name}`) ?? [];
                let data_classification = [];
                if (item.classification && item.classification !== "") {

                    data_classification = item.classification?.split(",").map(item => {

                        let present = dataClassificationList.find(classification => classification?.name?.toLowerCase() === item?.toLowerCase());
                        if (present)
                            return present;

                        return { name: item }
                    })
                    // data_classification=dataClassificationList.filter(classification=>item?.classification?.split(",").includes(classification.name));

                }
                tempData.push({
                    ...dummyData,
                    ...additionalFieldsData,
                    ...item,
                    business_term: item.business_term,
                    data_classification: data_classification,
                    data_category: item.category,
                    description: item.description,
                    data_owner: data_owner,
                    data_stewards: data_stewards
                })
            })
            let colsListTemp = [...colsList];
            colsListTemp.forEach((item) => {
                if (!item.visible && uploadData1[0] && uploadData1[0][item.key]) {
                    item.visible = true;
                }
            })
            setColsList([...colsListTemp]);
            setData([...tempData])
            setTimeout(() => setIsInputReady(true), 50)
        }
    }, [uploadData, uploadDocFlag])

    const checkFormat = (key, value, type, dropdownValues) => {


        if (value != "" && value !== undefined) {
            if (key == "data_category") {

                return dataCategoryList.map(item => item.Name).includes(value)
            }
            else if (key == "business_term") {
                return columnNameRegex.test(value)
            }
            else if (key == "data_classification") {
                let arr = value.map(item => item.name);
                let dataClassificationListTemp = dataClassificationList.map(item => item.Name)
                let isPresent = true;
                arr.forEach(item => {
                    if (!dataClassificationListTemp.includes(item)) {
                        isPresent = false
                    }
                })
                return isPresent;
            }
            else {
                switch (type) {

                    case 'TOGGLE':
                        let acceptedValue = ['true', 'false', 0, '0', 1, '1'];
                        return typeof value == "boolean" || acceptedValue.includes(value?.toLowerCase())
                    case 'NUMERIC':
                        return !isNaN(value)
                    case 'DATE':
                        return true// true
                    case 'DROPDOWN':
                        value = value && value?.toString()
                        if (!dropdownValues.map(v => v.value).includes(value)) {
                            return false
                        }
                        // let valueArr=value?.split(',')
                        // valueArr.forEach(item=>{
                        //     if (!dropdownValues.includes(item))
                        //     return false
                        // })
                        return true;
                    case 'TEXT':
                        return true;
                    default: break;
                }
            }
        }
    }

    const getFieldTemplate = (key, index, value = '', isLast = false, additionalClasses = '', isDisable = false, dataTemp, row) => {
        const selectedColumn = colsList.filter((item) => item.key == key);
        const selectedCategory = dataCategoryList.filter(item => item.value == dataTemp['data_category'])[0];
        if (selectedColumn[0]?.type) {
            const isValidated = checkFormat(key, value, selectedColumn[0]?.type, selectedColumn[0]?.value);
            if (isValidated == false) {
                row.valid = false;
            }
            switch (selectedColumn[0]?.type) {
                case 'DROPDOWN':
                    return <>
                        <div className={`d-flex align-items-center h-100 ${isValidated == false ? "error" : ""}`} onKeyDown={(e) => e.stopPropagation()}>
                            <SingleSelection
                                props={{
                                    placeholder: "Select",
                                    dropDownValue: value.toString(),

                                    onClickEvent: (val) => {
                                        onInputChangeHandler(key, val.toString(), index)

                                    },
                                    optionList: selectedColumn[0].value,
                                    isDisable: isDisable,
                                    selectClassName: 'w-100'
                                }}
                            />
                        </div>
                    </>
                case 'TOGGLE':
                    return <>
                        <div className={`d-flex align-items-center h-100 ${isValidated == false ? "error" : ""}`} onKeyDown={(e) => e.stopPropagation()}>

                            <Switch
                                checked={value}
                                onChange={(value) => onInputChangeHandler(key, value, index)}
                                size='small'
                                disabled={isDisable}
                            />
                        </div>
                    </>
                case 'TEXT':
                case 'NUMERIC':
                case 'DATE':
                    return <>
                        <div className='d-flex align-items-center h-100' onKeyDown={(e) => e.stopPropagation()}>
                            <input
                                required
                                type={selectedColumn[0]?.type === 'NUMERIC' ? 'number' : selectedColumn[0]?.type.toLocaleLowerCase() ?? 'text'}
                                // value={value}
                                defaultValue={value ?? ''}
                                onBlur={(e) => onInputChangeHandler(key, toTitleCase(e.target.value), index)}
                                // onChange={(e)=>onInputChangeHandler(key, toTitleCase(e.target.value), index)}
                                placeholder=''
                                className={`custom-input-field ${additionalClasses}`}
                                disabled={isDisable}
                            />
                        </div>
                    </>
                default: return <></>
            }
        }
        const isValidated = checkFormat(key, value);
        if (isValidated == false) {
            row.valid = false;
        }
        switch (key) {
            // case 'technical_term':





            // case 'database':
            // case 'schema':
            // case 'table':
            // case 'data_domain':
            case 'business_term':
                // case 'connector':

                return <>
                    <div key={value} className={isValidated == false ? "error" : ""} onKeyDown={(e) => e.stopPropagation()}>
                        <Tooltip title={isValidated == false ? "Invalid Bussiness Term" : ""}>
                            {
                                isInputReady
                                    ?
                                    <input
                                        required
                                        key={value}
                                        type="text"
                                        defaultValue={value.toUpperCase() ?? ''}
                                        // value={value}
                                        onBlur={(e) => onInputChangeHandler(key, correctBusinessTerm(e.target.value), index)}
                                        // onChange={(e)=>onInputChangeHandler(key, toTitleCase(e.target.value), index)}
                                        placeholder=''
                                        className={`custom-input-field ${additionalClasses}`}
                                        disabled={isDisable}
                                    />

                                    : ''
                            }
                        </Tooltip>
                    </div>
                </>
            case 'description':
                return <>
                    <div className='' key={value} onKeyDown={(e) => e.stopPropagation()}>
                        <input
                            required
                            type="text"
                            key={value}
                            defaultValue={value ?? ''}
                            // value={value}
                            onBlur={(e) => onInputChangeHandler(key, e.target.value, index)}
                            // onChange={(e)=>onInputChangeHandler(key, e.target.value, index)}
                            placeholder=''
                            className={`custom-input-field ${additionalClasses}`}
                            disabled={isDisable}
                        />
                    </div>
                </>
            case 'data_classification':
                return <>
                    <div className='d-flex h-100 align-items-center'>
                        {value.length ?
                            <React.Fragment>
                                <div className='tag-list d-flex align-items-center' style={{ border: "none" }}>

                                    {value.map((tag, index) => {
                                        return index < 1 ? <div key={index} className={`${!isValidated ? 'categoryTag-danger' : ""} classificationTag font-w-600`}>

                                            <Tooltip placement="topLeft" title={!isValidated ? "Classification Not Found" : tag.name ? tag?.name?.toUpperCase() : tag}><span style={{ maxWidth: '5rem' }} className="text-elipse">{`${tag.name ? tag?.name?.slice(0, 10)?.toUpperCase() : tag.toUpperCase()} ${tag?.name?.length > 10 || tag?.length > 10 ? '...' : ''} `}</span></Tooltip>

                                        </div> : <></>
                                    })
                                    }
                                    {value.length > 1 ? <div className={`${!isValidated ? 'categoryTag-danger' : ""} ml-1 classificationTag font-w-600 text-center`}>

                                        <Tooltip title={value?.slice(2).map(item => item.name.toUpperCase()).join(", ")}> <span className="text-with-ellipsis">{`+ ${value.length - 1}`}</span></Tooltip>

                                    </div> : null}
                                    <Buttons
                                        props={{
                                            buttonText: '',
                                            buttonClassName: `ml-1 rounded-circle sm-cr-hw custom-btn custom-btn-outline ${!isValidated ? "categoryTag-danger" : ""}`,
                                            buttonEvent: (e) => {
                                                categoryIndexRef.current = index
                                                setSelectedCategories(value);
                                                setOpenClassificationModal(true);

                                            },
                                            ImgSrc: () => <i className='bx bx-pencil'></i>,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }}
                                    />
                                </div>

                            </React.Fragment> : 
                            isDisable ? <div className='fontSizeLabel text-black-50'>N/A</div>
                            :
                            <div className='col-9 m-0 p-0 '> <Buttons
                                props={{
                                    buttonText: 'Select',
                                    buttonClassName: `custom-btn custom-btn-classification select  w-100 `,
                                    buttonEvent: (e) => {
                                        categoryIndexRef.current = index
                                        setSelectedCategories([]);
                                        setOpenClassificationModal(true);

                                    },
                                    ImgSrc: () => <PLUS />,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                            </div>

                        }
                    </div>

                </>
            case 'data_owner':
            case 'data_stewards':
                return <>
                    <div className='d-flex paragraph schema-table-avatar mt-1'>
                        {_.isEmpty(value) ? <div style={{ paddingLeft: "0.1875rem" }}><label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span >{key === 'data_owner' ? "No Data Owners" : "No Data Stewards"}</span></label></div> : ''}

                        {/* {_.isEmpty(value) ? <div style={{ paddingLeft: "28px" }}>No Data Owners</div> : ''} */}

                        {isArray(value) && value.length ? <Avatar.Group maxCount={2}>
                            {value.map((name, i) => {
                                return (

                                    <Tooltip placement='topLeft' title={toTitleCase(name)}>
                                        <Avatar style={{
                                            backgroundColor: "#2c83c4",
                                            color: "white"
                                        }}>{name[0].toUpperCase()}</Avatar>

                                    </Tooltip>


                                );
                            })}
                        </Avatar.Group> : ''}
                    </div>
                </>

            case 'data_category':
                return <>
                    <div className='d-flex h-100 align-items-center'>
                        {!value ? 
                        isDisable ? <div className='fontSizeLabel text-black-50'>N/A</div>
                        :
                        <div className='col-9 m-0 p-0 '> <Buttons
                            props={{
                                buttonText: 'Select',
                                buttonClassName: "custom-btn-category select custom-btn w-100",
                                buttonEvent: (e) => {
                                    categoryIndexRef.current = index
                                    setOpenCategoryModal(true);

                                },

                                ImgSrc: () => <PLUS />,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            }}
                        /></div> : ''}
                        <div className='tag-list p-1 m-0' style={{ border: "none" }}>
                            {value ? <div className='d-flex align-items-center h-100'> <div className={`${!isValidated ? "categoryTag-danger" : ""} categoryTag`}>

                                <Tooltip placement="topLeft" title={!isValidated ? "Category Not Found" : value.toUpperCase()}><div className={` text-with-ellipsis`}>{`${value?.toUpperCase()}`}</div></Tooltip>

                            </div> 
                            {
                                isDisable ? ''
                                :
                                <Buttons
                                        props={{
                                            buttonText: '',
                                            buttonClassName: `ml-1 rounded-circle sm-cr-hw custom-btn-outline custom-btn ${!isValidated ? "categoryTag-danger" : ""}`,
                                            buttonEvent: (e) => {
                                                categoryIndexRef.current = index
                                                setOpenCategoryModal(true);

                                            },

                                            ImgSrc: () => <i className='bx bx-pencil'></i>,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }}
                                    /> 
                            }
                            </div> : ''}

                        </div>

                    </div>
                </>
            case 'datatype':
                return <>
                    <Tooltip title={isValidated == false ? "Invalid Category" : ""}>
                        <div className={`d-flex align-items-center h-100 ${isValidated == false ? "error" : ""}`} onKeyDown={(e) => e.stopPropagation()}>

                            <SingleSelection
                                props={{
                                    placeholder: "Select",
                                    dropDownValue: value,

                                    onClickEvent: (val) => {
                                        onInputChangeHandler(key, val, index)
                                    },
                                    optionList: key == "data_category" ? dataCategoryList : key == "data_classification" ? dataClassificationList : dataTypes,
                                    isDisable: isDisable,
                                    selectClassName: 'w-100'
                                }}
                            />

                        </div>
                    </Tooltip>
                </>
            case 'action_btns':
                let allowAction = data[index]?.business_term?.length !== 0 && row.valid
                return <>

                    <div className=' data-dictionary align-items-center d-flex h-100 justify-content-center'>

                        {!data[index].id ?
                            //   <Tooltip title="Save"> 
                            <div>
                                <button style={
                                    {
                                        cursor: allowAction ? "pointer" : "not-allowed",
                                        opacity: allowAction ? '1' : '.6'
                                    }
                                }
                                    className={`custom-btn btn-with-icon border-0 mr-1 ${allowAction ? 'custom-btn-primary' : ''}`}
                                    disabled={(data[index]?.business_term?.length === 0) || row.valid == false}
                                    onClick={() => {
                                        if ((data[index]?.business_term?.length > 0)) {
                                            onSubmit(index);
                                        }
                                        else {
                                            emitToastNotification("error", Literals.FILL_REQUIRED_FIELD)
                                        }
                                    }}
                                >  <SAVE /></button></div>
                            //</Tooltip>
                            : ''
                            // <div style={{ width: "31px" }}></div>
                            }
                        {/* <Tooltip title="Delete">     */}
                        <div>
                            {
                                data[index].id
                                ?
                                <Buttons
                                    props={{
                                        buttonText: "",
        
                                        buttonClassName: " danger-btn custom-btn btn-with-icon border-0",
                                        buttonEvent: () => {
                                            onRemoveField(index)
                                        },
                                        toggleBtnValue: "",
                                        ImgSrc: () => <ICON_DELETE width={20} height={29} />,
                                        // isDisable: typeof data[index].id=="number"?false:true,
                                        // isDisable: data.length == 1 ? true : false,
                                        buttonType: Literals.BTN_TERTIARY,
                                        toggleBtnOption: null,
                                    }}
                                />
                                :   ''
                            } 
                        </div>
                        {/* </Tooltip> */}
                    </div>
                </>
            default:
                break;
        }
    }

    const generateColumns = (columns_list) => {
        let cols = [], colsListCopy = [...columns_list]
        cols = colsListCopy.filter(v => v.visible === true).map(v => {
            return {
                name: <label className='font-w-600 grey-color label mb-0 paragraph'>
                    {v.title}
                    {v.isRequired ? <span><sup>*</sup></span> : ''}
                </label>,
                key: v.key,
                sortable: false,
                resizable: true,
                width: v.width,
                frozen: v.frozen ?? false
            }
        })
        cols.unshift({
            name: <label className='w-100 text-center font-w-600 grey-color label mb-0 paragraph'>
                Action
            </label>,
            key: 'action_btns',
            sortable: false,
            resizable: false,
            width: 100,
            frozen: true
        })
        return cols
    }

    const dispatch = useDispatch();
    
    const onDelete = (id) => {
        dispatch(deleteBussinessTerm(id)).then((res) => {
            if (res.status == "success") {
                emitToastNotification('success', Literals.BUSSINESS_TERM_DELETE)
                setData(prev => {
                    prev = prev?.filter(v => v?.id !== id)
                    return prev
                })
            }
        }).catch((err) => {


        })
    }

    const onSubmit = (row) => {
        let payload = {}
        let index = row ?? data.length - 1;
        let dataCategoryTemp = dataCategoryList.filter(item => item.Name == data[index].data_category);
        let classificationArray = data[index].data_classification.map(item => item.name);
        let dataClassificationTemp = dataClassificationList.filter(item => classificationArray.includes(item.Name)).map(item => { return { classification: item.id } });

        payload.business_term = data[index].business_term?.toLowerCase();
        payload.category = dataCategoryTemp[0]?.id;
        payload.classification = dataClassificationTemp;
        payload.description = data[index].description;
        payload.add_field = [];
        payload.owner_steward = [];
        data[index].data_owner.forEach((item) => {
            payload.owner_steward.push({
                "user": EMAIL_USER_MAPPING.current[item]?.id,
                "user_type": "OWNER"
            })
        })
        data[index].data_stewards.forEach((item) => {
            payload.owner_steward.push({
                "user": EMAIL_USER_MAPPING.current[item]?.id,
                "user_type": "STEWARD"
            })
        })
        additionalFields.current.forEach(item => {
            let value = data[0][item.name];
            if (value && value != "") {
                payload.add_field.push({

                    "add_field": item.id,
                    "value": value
                })
            }
        })

        dispatch(createBussinessTerm(payload)).then((res) => {
            if (res.status == "success") {
                emitToastNotification('success', Literals.BUSSINESS_TERM_CREATED)
                let response_data = res.data;
                
                if (uploadData.length == 0)
                    onAddFields(response_data.id);
                else {
                    let newData = res.data

                    let allData = data.map(val => {
                        let obj = val.business_term?.toLocaleLowerCase() === newData.business_term?.toLocaleLowerCase() ? newData : undefined

                        return (
                            {
                                ...val,
                                id: val.id ? val.id : obj === undefined ? null : obj.id
                            }
                        )
                    })

                    let newList = [...allData.filter((item) => item.id && item.id), ...allData.filter((item) => !item.id)]
                    
                    setData([...newList])
                    // let tempData = [...data];
                    // tempData[row].id = response_data.id
                    // setData(tempData)
                }
            }

        })
    }

    const getDataCategoryList = () => {
        dispatch(getSettingsDataCategory()).then((res) => {
            let temp = [];

            res.data.forEach((item) => {
                // let fulllName=`${item.owner_steward_detail.owner} ${item.owner_steward_detail.last_name}`;

                let ownerName = item.owner_steward_detail.owner?.map((item) => {
                    return {
                        Name: item.email,
                        value: item.email
                    }
                })
                let stewardName = item.owner_steward_detail.steward?.map((item) => {
                    return {
                        Name: item.email,
                        value: item.email
                    }
                })

                temp.push({
                    ...item,

                    Name: item.name,
                    value: item.name

                })
            })
            setDataCategoryList(temp);
        })
    }

    const getDataClassificationList = () => {
        dispatch(getClassificationData()).then((res) => {
            let temp = [];

            res.data.forEach((item) => {
                temp.push({
                    ...item,
                    Name: item.name,
                    value: item.name,

                })
            })
            setDataClassificationList(temp);
        })
    }

    const getAdditionalDetailsList = () => {
        let additionalColumns = [];

        dispatch(getSettingsAdditionalDetailsList()).then((res) => {
            let tempData = {};

            res.data.forEach((item) => {
                let options = null;
                additionalFields.current.push(item);
                if (!_.isEmpty(item.value)) {
                    options = item.value.map(val => { return { Name: val, value: val } })
                }
                tempData[item.name] = "";
                additionalColumns.push({
                    key: item.name,
                    title: item.name,
                    isRequired: false,
                    visible: false,
                    width: item.type == 'TOGGLE' ? 60 : 180,
                    frozen: false,
                    type: item.type,
                    value: options
                },
                )
            })
            let temp = [...colsList, ...additionalColumns];
            let tempData2 = { ...data[0], ...tempData }
            setColsList(temp);
            setData([tempData2]);
        })

    }
    useEffect(() => {

        // getDataCategoryList(); // Data Category Deprecated
        getDataClassificationList();
        getAdditionalDetailsList();
    }, [])
    useEffect(() => {
        let temp = [], cols = [], obj = {};
        let rows = [...data]
        temp = rows.map((v, i) => {
            v['action_btns'] = ''
            let isLast = i === (rows.length - 1)
            // obj.valid=true;

            //START:: Code to identify valid row
            let validRow = Object.keys(v).map(key => {
                let col = colsList.find(col => col.key === key)
                let isValid = checkFormat(key, v[key.toLowerCase()], col === undefined ? '' : col.type ?? '', col === undefined ? '' : col.value ?? '');
                return isValid;
            }).filter(item => item !== undefined && item !== null)

            let isValidRow = validRow.every(v => v === true)
            obj.valid = isValidRow
            //END::

            Object.keys(v).forEach(key => {
                let isDisable = v.id;

                obj[key] = getFieldTemplate(key, i, v[key.toLowerCase()] ?? v[key], isLast, '', isDisable, v, obj)
            })
            return { ...obj }
        })
        setDemo([...temp])
        cols = [...generateColumns(colsList)]
        setColumns([...cols])
    }, [data, dataCategoryList.length, colsList, isInputReady, classificationRef.current])

    const onInputChangeHandler = (field, value, index) => {
        let dataCopy = [...data]
        if (field == "data_category") {
            if(value !== null && value !== undefined && value !== '') {
                dataCopy[index]['data_owner'] = dataCategoryList.find(cat => cat.name === value).owner_steward_detail.owner.map(v => `${v.first_name} ${v.last_name}`)
                dataCopy[index]['data_stewards'] = dataCategoryList.find(cat => cat.name === value).owner_steward_detail.steward.map(v => `${v.first_name} ${v.last_name}`)
            }

        }
        if (field == "data_classification") {
            let dataClassificationListTemp = dataClassificationList.map(item => item.name);
            let filteredValues = value.filter(item => dataClassificationListTemp.includes(item.name))
            dataCopy[index][field] = filteredValues;

        }
        if (field === 'business_term') {
            setIsInputReady(false)
        }
        if ((field == "business_term" && !(columnNameRegex.test(value) || value == ""))) {
            if (value.includes(' '))
                dataCopy[index][field] = value.replace(' ', '_').toLowerCase()
        }
        else {
            dataCopy[index][field] = value
        }



        setData([...dataCopy])
        if (field === 'business_term') {
            setTimeout(() => setIsInputReady(true), 50)
        }
    }



    const onAddFields = (id) => {
        setIsAddingFields(true)
        let additionalFieldTemp = {};
        additionalFields.current.forEach((item) => additionalFieldTemp[item.name] = "")
        let temp = [...Array(numberOfFieldsToAdd)].map(() => (
            {
                ...dummyData,
                ...additionalFieldTemp
            }
        ))
        let tempData = [...data];
        tempData[tempData.length - 1].id = id;
        
        setData([...tempData, ...temp])
        setIsAddingFields(false)
        message.info(`${numberOfFieldsToAdd} row(s) added. `)
    }

    const onRemoveField = (index) => {
        let dataCopy = [...data]
        
        if (dataCopy && dataCopy.length === 1) {
            
            setData([dummyData])
            setIsInputReady(true);

        } else {

            let deletedRow = dataCopy.splice(index, 1);
            
            let id = deletedRow[0].id

            if (id)
                onDelete(id);
            else {
                setData(prev => {
                    prev = prev?.filter((v, idx) => index !== idx)
                    return prev
                })
            }
            setIsInputReady(true);
        }


    }

    const onHideFieldSwitchChange = (checked, key) => {
        setColsList(prev => {
            prev = prev.map(item => ({ ...item, visible: item.key === key ? checked : item.visible }))
            setColumns([...generateColumns(prev)])
            return [...prev]
        })
    }

    const handleAllFields = (isVisible) => {
        let colsCopy = [...colsList].map(col => ({ ...col, visible: col.isRequired ? true : isVisible }))
        setColsList([...colsCopy])
        setColumns([...generateColumns(colsCopy)])
    }

    const hidePopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Hide Fields</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                    setHideFieldSearchValue('')
                                    handleAllFields(true)
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setHideFieldPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div>
                    <div className="new-hide-fields-popover-wrap">
                        <div className="pt-3 pb-0" style={{paddingLeft: '0.875rem', paddingRight: '0.875rem'}}>


                            <div
                                className="custom-search new-hide-field-search"
                            >
                                <input
                                    id="hide-field-searchbox"
                                    type="text"
                                    onChange={(e) => setHideFieldSearchValue(e.target.value)}
                                    className="custom-search-input"
                                    placeholder={Literals.PLACE_HOLDER}
                                    autoFocus
                                    value={hideFieldSearchValue}
                                />
                                <div className="custom-search-icon">
                                    <SEARCH />
                                </div>
                                <div className="custom-close-icon" onClick={() => setHideFieldSearchValue('')}>
                                    <CLOSE />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ padding: "0.1875rem 0.625rem 0.1875rem" }}>
                        <div className="d-flex align-items-center p-1 new-hide-show-hide-btn-prev"
                        onClick={() => {
                            setShowAllCols(!showAllCols)
                            handleAllFields(!showAllCols)
                        }}
                        >
                        <span className='hide-field-toggle-btn' style={{cursor: "pointer"}}>
                            {
                                showAllCols ?
                                <ICON_EYECLOSE height="14" width="14" color="currentColor" />
                                :
                                <ICON_EYEOPEN height="14" width="14" color="currentColor" />
                            }
                        </span>
                        <label 
                            className='position-relative new-hidefield-dgt-hide-cols-item d-block fontSizeLabel mb-0 ml-2 text-with-ellipsis'
                            style={{fontFamily: 'InterRegular', lineHeight: '0.8125rem', cursor: 'pointer'}}
                        >
                            {
                                showAllCols ? 'Hide All' : 'Show All'
                            }
                        </label>
                        </div>
                    </div>
                    <div className="mx-auto" style={{width: '90%', background: '#d9d9d9', height: 1}} ></div>
                    <div className="h-100">
                        <div
                            className="overflow-auto"
                            style={{ height: "11.25rem" }}
                        >
                            {
                                colsList.filter(item => item.title.toLocaleLowerCase().includes(hideFieldSearchValue.toLocaleLowerCase())).length > 0
                                    ?
                                    colsList.filter(item => item.title.toLocaleLowerCase().includes(hideFieldSearchValue.toLocaleLowerCase()))
                                        .map((column, index) => (
                                            <>
                                                <div key={`hide-field-map-${index}`} className="d-flex align-items-center position-relative" style={{padding: '0.25rem 0.875rem 0.25rem 0.875rem'}}>
                                                    <span
                                                        className="new-hide-field-control-btn hide-field-toggle-btn"
                                                        onClick={() => {
                                                            if(column?.isRequired) return
                                                            onHideFieldSwitchChange(!column.visible, column.key)
                                                        }}
                                                        style={
                                                            column?.isRequired
                                                            ? {cursor: 'not-allowed', color: '#333333 !important', opacity: '.6'} : {}
                                                        }
                                                    >
                                                        {
                                                            column.visible
                                                            ? <ICON_EYEOPEN height="14" width="14" color="currentColor"/>
                                                            : <ICON_EYECLOSE height="14" width="14" color="currentColor"/>
                                                        }
                                                    </span>
                                                <label 
                                                    className='position-relative new-hidefield-dgt-hide-cols-item d-block fontSizeLabel mb-0 ml-2 text-dark text-with-ellipsis'
                                                    title={column.title}
                                                    style={{fontFamily: 'InterRegular'}}
                                                >
                                                    {/* {getColumnDataType(column.Type)} */}
                                                    {column.title}
                                                </label>
                                                </div>
                                            </>
                                        ))
                                    :
                                    <div className="p-3 text-center text-black-50">No Match Found</div>
                            }
                        </div>
                    </div>
                </div>
            </>
        }
    }

    const handleHideFieldVisibleChange = (visible) => {
        setHideFieldPop(visible)
    }

    const handleSaveAllBusinessTerms = () => {
        let newBizTerms = [...data]
        newBizTerms = newBizTerms.filter(item => {
            let validRow = Object.keys(item).map(key => {
                let col = colsList.find(col => col.key === key)
                return checkFormat(key, item[key], col === undefined ? '' : col.type ?? '', col === undefined ? '' : col.value ?? '')
            }).filter(item => item !== undefined && item !== null)

            let isValidRow = validRow.every(v => v === true)
            return isValidRow
        })

        if (newBizTerms.length === 0) {
            emitToastNotification('info', Literals.NO_VALID_RECORD_FOUND)
            return
        }
        let payload = newBizTerms.map(item => (
            {
                business_term: item.business_term?.toLowerCase(),
                category: getCategoryId(item.data_category),
                classification: item.data_classification.length === 0 ? [] : isArray(item.data_classification) ? item.data_classification.map(v => ({ classification: v.id })) : [{ classification: getClassificationId(item.data_classification) }],
                description: item.description,
                add_field: Object.keys(item).map(key => (
                    {
                        add_field: getAdditionalFieldId(key),
                        value: item[key]
                    }
                )
                ).filter(v => v.add_field !== '')
            }
        )
        )
        dispatch(createBussinessTerm(payload))
            .then(res => {
                let newData = res.data


                let allData = data.map(val => {
                    let obj
                    // if (newData?.length) {

                    obj = newData.find(item => item.business_term.toLocaleLowerCase() === val.business_term.toLocaleLowerCase())
                    // }
                    return (
                        {
                            ...val,
                            id: obj === undefined ? null : obj.id
                        }
                    )
                })

                let newList = [...allData.filter((item) => !item.id), ...allData.filter((item) => item.id && item.id)]

                setData([...newList])
                // setData(prev => {
                //     prev = prev.map(val => {
                //         let obj = newData.find(item => item.business_term === val.business_term)
                //         return (
                //             {
                //                 ...val,
                //                 id: obj === undefined ? null : obj.id
                //             }
                //         )
                //     })
                //     console.log(prev)
                //     return [...prev]
                // })
            })
            .catch(err => {
                emitToastNotification('error', Literals.BUSSINESS_TERM_SAVE_FAILED)
                console.error(err)
            })
    }

    const getCategoryId = (category) => {
        let obj = dataCategoryList.find(cat => cat.name === category)
        return obj === undefined ? '' : obj.id
    }

    const getClassificationId = (classification) => {
        if (isArray(classification)) {
            return classification.map(item => dataClassificationList.find(cls => cls.name === item)?.id) ?? []
        }
        let obj = dataClassificationList.find(item => item.name === classification)
        return obj === undefined ? '' : obj.id
    }

    const getAdditionalFieldId = (field) => {
        let obj = additionalFields.current?.find(item => item.name === field)
        return obj === undefined ? '' : obj.id
    }

    return (
        <>
            <div className='custom-card padding-2 bg-white' style={{ height: 'calc(100vh - 5.75rem)' }}>
                <div className='d-flex justify-content-between mb-3'>
                    <div className='d-flex align-items-center'>


                    </div>
                    <div className='d-flex align-items-center'>
                        {
                            colsList.filter(col => col.visible === false).length > 0
                                ?
                                <p className='paragraph text-black-50 mr-2 mb-0'>
                                    {colsList.filter(col => col.visible === false).length} field(s) are hidden
                                </p>
                                : ''
                        }
                        <Popover
                            placement="bottomLeft"
                            key='dgt-hide-pop-over0001'
                            title={<></>}
                            content={hidePopover.body}
                            trigger="click"
                            autoAdjustOverflow={true}
                            open={showHideFieldPop}
                            onOpenChange={handleHideFieldVisibleChange}
                        >
                            <Buttons
                                props={{
                                    buttonText: "",
                                    buttonClassName: `custom-btn-outline mr-2 custom-btn btn-with-icon
                                     ${colsList.every(col => col.visible === true) ? '' : 'rdg-filter-active'}`,
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        setHideFieldPop(true)
                                    },
                                    tooltip: "Show Hidden Fields",
                                    ImgSrc: () => <HIDEPREVIEW />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_TERTIARY,
                                }}
                            />
                        </Popover>
                        {
                            (isUploadScreen && uploadData.length > 0) 
                                ? 
                                data?.length > 0 && data?.every(d => d?.id !== null)
                                ?
                                <Buttons
                                    props={{
                                        buttonText: "Upload More",
                                        buttonClassName:
                                            "custom-btn-primary custom-btn btn-with-text",
                                        buttonEvent: () => {
                                            dispatch(setUploadDocFlag(true))
                                        },
                                        tooltip: "",
                                        ImgSrc: () => <UPLOAD />,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                                :
                                <Buttons
                                    props={{
                                        tooltipPlacement: 'left',
                                        buttonText: "Save All",
                                        buttonClassName:
                                            "custom-btn-primary mr-2 custom-btn btn-with-text",
                                        buttonEvent: () => {
                                            handleSaveAllBusinessTerms()
                                        },
                                        tooltip: "Save all valid display names",
                                        ImgSrc: () => <SAVE />,
                                        isDisable: false,
                                        // isDisable: saveAllDisabled,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                                : <Buttons
                                    props={{
                                        buttonText: "Upload",
                                        buttonClassName:
                                            "custom-btn-primary custom-btn btn-with-text",
                                        buttonEvent: () => {
                                            dispatch(setUploadDocFlag(true))
                                        },
                                        tooltip: "",
                                        ImgSrc: () => <UPLOAD />,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                        }
                    </div>
                </div>

                <div style={{ overflow: 'auto', height: 'calc(100% - 2.875rem)' }} className="manual-creation-div-wrap">
                    <MultipleColsDndList
                        colsList={(() => {
                            let cols = [...columns]
                            let lim = 7
                            cols = cols.map(v => (
                                {
                                    ...v,
                                    width: v.key === 'action_btns' ? 90 : ['data_category', 'data_classification'].includes(v.key) ? 180 : cols.length > lim ? 250 : v.key === 'description' ? '30%' : v.key === 'business_term' ? '20%' : v.key === 'technical_term' ? '15%' : undefined,
                                    frozen: ['business_term', 'action_btns'].includes(v.key)
                                }
                            ))

                            return cols
                        })()}
                        rowsList={demo}
                        rawData={data}
                        setData={(newData) => setData([...newData])}
                        rowReorder={false}
                    />
                </div>
            </div>
            <UploadDocModal getSummaryDocument={() => { }} tableId={null} isDownload={true} type="manual_creation" setUploadData={setUploadData} />
            <CategoryModal 
                hideAddButton 
                save={saveCategory} 
                isModalOpen={openCategoryModal} 
                onClose={() => setOpenCategoryModal(false)} 
                activeCategory={categoryRef?.current?.[0]}
            />

            <ClassificationModal 
                hideAddButton 
                save={saveClassification} 
                isModalOpen={openClassificationModal} 
                onClose={() => setOpenClassificationModal(false)} 
                activeClassifier={classificationRef?.current?.[0]}
            />

        </>
    )
}

export default ManualCreationFields;