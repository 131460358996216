import {
    SET_USERS_LIST,
    SET_USERGROUPS_REMOVE_LEADER,
    SET_USERS_LIST_REFRESH,
    SET_USERGROUP_DESCRIPTION,
    SET_USER_ID,
    SAVE_USERGROUP,
    SET_USERS_PAGE_DEFAULT,
    OPEN_FILTER
} from "./usersActionTypes";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { disabledUMHeaderButtons } from "../dataDomains/dataDomainActions";
import { Literals } from "../../../../components/modules/common/literals";

var BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const openFilter = (payload) => {
    return {
        type: OPEN_FILTER,
        payload: payload
    }
}


export const createTenantEmail = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}control_centre/smtp/`, payload)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}

export const createTenantMicrosoft = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}control_centre/auth/`, payload)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}

export const createTenantBasic_auth = (payload, showToast = true, provider) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}control_centre/auth/`, payload)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}
export const getTenantBasic_auth = (showToast = true, provider) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}control_centre/auth/`)  //control_centre/auth/
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}



export const getTenantEmail = (showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}control_centre/smtp/`)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}

export const getTenantMicrosoft = (showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}control_centre/auth/`)  //control_centre/auth/
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}

export const changeProviderStatus = (showToast = false, providerName, isActive) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(isActive ? "GET" : "DELETE", `${BASE_URL}control_centre/auth/status/?provider=${providerName}`)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}

export const changeProviderMFAStatus = (showToast = false, providerName, isActive) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(isActive ? "GET" : "DELETE", `${BASE_URL}control_centre/auth/mfa/?provider=${providerName}`)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}
export const changeOpenAiStatus = (showToast = false, providerName, isActive) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(isActive ? "GET" : "DELETE", `${BASE_URL}control_centre/openai/status/`)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}

export const deleteOpenAi = (showToast = false, providerName, isActive) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(isActive ? "GET" : "DELETE", `${BASE_URL}control_centre/openai/destroy_client/`)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}
export const deleteSMTP = (showToast = false, providerName, isActive) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(isActive ? "GET" : "DELETE", `${BASE_URL}control_centre/smtp/destroy_client/`)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}
export const destroyProvider = (showToast = false, providerName) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("DELETE", `${BASE_URL}control_centre/auth/destroy_client?provider=${providerName}`)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}


export const createTenantOpenAi = (payload, showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}control_centre/openai/`, payload)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}

export const getTenantOpenAi = (showToast = true) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}control_centre/openai/`)
            )
                .then((resp) => {
                    if (showToast) {
                        emitToastNotification("success", resp.message)
                    }
                    resolve(resp)
                })
                .catch((err) => {
                    if (showToast) {
                        emitToastNotification('error', err?.message);
                    }
                    reject(err);
                })
        });
    };
}



export const getUserProfile = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(fetchData("GET", `${BASE_URL}profile/me/`, { module: "user_management" }))
                .then((resp) => {
                    if (resp.status === "success") {
                        resolve(resp)
                    } else {
                        emitToastNotification('error', resp?.message);
                        reject(resp?.message);
                    }
                }).catch((err) => {
                    emitToastNotification('error', err?.message);
                    reject(err);
                });
        })
    }


}




export const resendEmail = ({ id, payload }) => {
    return (dispatch) => {
        // dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}auth/createuser/${id}/resend_email/`, payload)
            )
                .then((resp) => {
                    // dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        emitToastNotification("success", "Email sent successfully")
                        resolve(resp);

                    }
                })
                .catch((err) => {
                    // dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
}
export const getUserGroupList = ({ method, endPoint, payload }) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(fetchData(method, `${BASE_URL}${endPoint}`, payload))
                .then((resp) => {
                    if (resp.status === "success") {
                        dispatch(setLoadingStatus(false));
                        if (resp.data.length <= 0) {
                            dispatch(disabledUMHeaderButtons({ isDisable: true, dataTab: Literals.USERS }))
                        } else {
                            dispatch(disabledUMHeaderButtons({ isDisable: false, dataTab: Literals.USERS }))

                        }
                        if(endPoint?.includes('group_manager/user') && resp?.data?.length){
                            resp.data=resp?.data?.map(element => {
                                element.first_name= element?.first_name?.split(' ').slice(0, 2).join(' ');
                                element.last_name= element?.last_name?.split(' ').slice(0, 2).join(' ');
                                return element
                            });
                            resolve(resp)
                        }else{

                            resolve(resp)
                        }
                    } else {
                        dispatch(setLoadingStatus(false));
                        // emitToastNotification('error', resp?.message);
                        reject(resp?.message);
                    }
                }).catch((err) => {
                    dispatch(setLoadingStatus(false));
                    // emitToastNotification('error', err?.message);
                    reject(err);
                });
        })
    }
}

export const createEditUser = ({ method, endPoint, payload }) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(fetchData(method, `${BASE_URL}${endPoint}/`, payload))
                .then((resp) => {
                    resolve(resp)
                    dispatch(setLoadingStatus(false));
                    if (resp?.data?.id) {
                        emitToastNotification('success', "User Created Successfully");
                    }
                    // else
                    // {
                    //     emitToastNotification('error', "error in creating user");
                    // }
                }).catch((err) => {
                    dispatch(setLoadingStatus(false));
                    emitToastNotification('error', err?.message);
                    reject(err);
                });
        })
    }
}
export const setUserList = (data) => {
    return {
        type: SET_USERS_LIST,
        payload: data,
    };
};

export const setUsersPageDefault = () => {
    return {
        type: SET_USERS_PAGE_DEFAULT,
    };
};

export const setRemovedLeader = (data) => {
    return {
        type: SET_USERGROUPS_REMOVE_LEADER,
        payload: data,
    };
};

export const setUsersListRefresh = (data) => {
    return {
        type: SET_USERS_LIST_REFRESH,
        payload: data,
    };
};

export const setUserId = (data) => {
    return {
        type: SET_USER_ID,
        payload: data,
    };
};

export const setUserGroupDescription = (data) => {
    return {
        type: SET_USERGROUP_DESCRIPTION,
        payload: data,
    };
};

export const saveUserGroup = (data) => {
    return {
        type: SAVE_USERGROUP,
        payload: data,
    };
};








