import React from 'react'
import LogsView from './LogsView'
import GraphView from './GraphView'
import StatsPreviewTable from './StatsPreviewTable'
import StatsHealthView from './StatsHealthView'
import SplitPane from 'split-pane-react/esm/SplitPane'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux'
import { getPreviewTable } from '../../../../store/modules/dataCatalogue/preview/previewActions'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { getObjectLength, getReloadErrorTemplate, pxToRem, remToPx, showSimpleLoading } from '../../common/helperFunctions'
import { getBusinessTermStats, getBusinessTermsOfPod, getPodRetrieveData, getTableLogsStats } from '../../../../store/modules/dataDictionary/action'
import { toast } from 'react-toastify'
import { dictionaryEndpoint } from '../../../../store/actions'
import { ICON_STATS } from '../../../Common/newIconSource'

const BusinessTermStats = ({
    dataDictionaryId,
    fieldValues = {},
    setFieldValues = () => {},
    activeTabKey,
    initialData = {},
    defaultDomain = null
}) => {

    const dispatch = useDispatch()

    const [sizes, setSizes] = React.useState([ remToPx(pxToRem(253))+'px', "auto"])

    const [uniqUUID, setUniqUUID] = React.useState('')
    const [previewData, setPreviewData] = React.useState({})
    const [dictSchemaData, setDictSchemaData] = React.useState([])
    const [previewError, setPreviewError] = React.useState(undefined)
    const [previewLoading, setPreviewLoading] = React.useState(false)

    const [statsData, setStatsData] = React.useState({})
    const [loadingStats, setLoadingStats] = React.useState(false)
    const [statsError, setStatsError] = React.useState(undefined)

    const [dictError, setDictError] = React.useState(undefined)
    const [dictLoading, setDictLoading] = React.useState(false)

    const [podDetails, setPodDetails] = React.useState(fieldValues?.col_term?.[0])
    const [activePod, setActivePod] = React.useState(undefined)
    
    const [logsLoading, setLogsLoading] = React.useState(false)
    const [logsError, setLogsError] = React.useState(undefined)
    const [logsData, setLogsData] = React.useState([])
    const [emptyLogs, setEmptyLogs] = React.useState(false)
    const [podHealthReloading, setPodHealthReloading] = React.useState(false)

    const previewRef = React.useRef()

    React.useEffect(() => {
        getStatsDetails()
    }, [])

    React.useEffect(() => {
        if(podDetails && getObjectLength(podDetails) > 0) {
            fetchLogs()
            fetchPreview()
        }
        else {
            setEmptyLogs(true)
        }
    }, [podDetails])

    const getStatsDetails = () => {
        setStatsError(undefined)
        setLoadingStats(true)
        toast.promise(
            new Promise(async(resolve, reject) => {
                try {
                    const res = await dispatch(getBusinessTermStats(dataDictionaryId))
                    if(res?.status === 'success') {
                        setStatsData(res?.data)
                    }
                    setLoadingStats(false)
                    resolve()
                }
                catch(err) {
                    setStatsError(err?.message ?? 'Error!')
                    setLoadingStats(false)
                    reject()
                }
            }),
            {
                pending: 'Loading Stats please wait...',
                success: 'Successfully loaded stats',
                error: 'Failed to fetch stats'
            },
            {
                position: 'bottom-right',
                autoClose: 5000,
            }
        )

        
    }

    const fetchLogs = () => {
        setLogsError(undefined)
        setLogsLoading(true)
        dispatch(getTableLogsStats(dataDictionaryId, podDetails?.table_id))
        .then((res) => {
            const dat = res?.data?.health ?? []
            setLogsData([...dat])
            setEmptyLogs(dat?.length === 0)
            setLogsLoading(false)
        })
        .catch((err) => {
            setLogsData([])
            setLogsError(err?.message ?? 'Error!')
            setLogsLoading(false)
            setEmptyLogs(false)
        })

        // toast.promise(
        //     new Promise(async(resolve, reject) => {
        //         try {
        //             const res = await dispatch(getTableLogsStats(dataDictionaryId, podDetails?.table_id))
        //             if(res?.status === 'success') {
        //                 const dat = res?.data?.health ?? []
        //                 setLogsData([...dat])
        //             }
        //             setLogsLoading(false)
        //             resolve()
        //         }
        //         catch(err) {
        //             setLogsData([])
        //             setLogsError(err?.message ?? 'Error!')
        //             setLogsLoading(false)
        //             reject()
        //         }
        //     }),
        //     {
        //         pending: 'Loading Stats please wait...',
        //         success: 'Successfully loaded stats',
        //         error: 'Failed to fetch stats'
        //     },
        //     {
        //         position: 'bottom-right',
        //         autoClose: 5000,
        //     }
        // )
    }

    const fetchPreview = () => {
        setDictError(undefined)
        setDictLoading(true)

        toast.promise(
            new Promise(async(resolve, reject) => {
                try {
                    const response = await dispatch(getPodRetrieveData(podDetails?.table_id, false))
                    if(response?.status === 'success') {
                        const columnDetails = response?.data?.column_detail ?? []
                        
                        dispatch(getBusinessTermsOfPod(podDetails?.table_id, false))
                        .then((res) => {
                            let data = res.data

                            data = data.map(v => (
                                {
                                    ...v, 
                                    technical_term: columnDetails?.find(c => c?.data_dict === v?.id)?.column_source_name ?? ''
                                }
                            ))
                
                            let leastData = data?.map(v => (
                                {
                                    business_term: v.business_term,
                                    technical_term: v?.technical_term ?? '', 
                                    category: v?.category_detail?.name ?? '', 
                                    classifications: v.classification_detail?.map(v => v.name),
                                    column_detail: columnDetails?.find(itm => itm?.column_name?.toLowerCase() === v?.business_term?.toLowerCase())
                                }
                            ))
                            setDictSchemaData([...leastData])
                            setDictLoading(false)
                
                            setPreviewLoading(true)
                            setPreviewError(undefined)
                            let uuid = uuidv4()
                            setUniqUUID(uuid)
                            let request = {
                                uuid: uuid,
                                table_name: podDetails?.table_name,
                                page: 0,
                                do_count: false,
                                sort_body: [],
                                filter_body: [],
                                table_ids: [podDetails?.table_id]
                            }
                            dispatch(getPreviewTable(request, false))
                            .then((res) => {
                                setPreviewData(res?.data?.result??{})
                                setPreviewLoading(false)
                            })
                            .catch((err) => {
                                setPreviewError(err?.message ?? 'Error!')
                                setPreviewLoading(false)
                            })
                        })
                        .catch((err) => {
                            setDictError(err?.message ?? 'Error!')
                            setDictSchemaData([])
                            setDictLoading(false)
                        })
                    }
                    resolve()
                }
                catch(err) {
                    setDictError(err?.message ?? 'Error!')
                    setDictLoading(false)
                    reject()
                }
            }),
            {
                pending: 'Retrieving Pod Details...',
                success: 'Successfully retrieved pod details',
                error: 'Failed to retrieve pod details'
            },
            {
                position: 'bottom-right',
                autoClose: 2000,
            }
        )



        // dispatch(getBusinessTermsOfPod(podDetails?.table_id, false))
        // .then((res) => {
        //     let data = res.data
        //     data = data.map(v => (
        //         {
        //             ...v, 
        //             technical_term: podDetails?.tech_term ?? ''
        //         }
        //     ))

        //     let leastData = data?.map(v => (
        //         {
        //             business_term: v.business_term,
        //             technical_term: podDetails?.tech_term ??'', 
        //             category: v?.category_detail?.name ?? ', 
        //             classifications: v.classification_detail?.map(v => v.name),
        //             column_detail: {column_id: podDetails?.column_id}
        //         }
        //     ))
        //     setDictSchemaData([...leastData])
        //     setDictLoading(false)

        //     setPreviewLoading(true)
        //     setPreviewError(undefined)
        //     let uuid = uuidv4()
        //     setUniqUUID(uuid)
        //     let request = {
        //         uuid: uuid,
        //         table_name: podDetails?.table_name,
        //         page: 0,
        //         do_count: false,
        //         sort_body: [],
        //         filter_body: [],
        //         table_ids: [podDetails?.table_id]
        //     }
        //     dispatch(getPreviewTable(request, false))
        //     .then((res) => {
        //         setPreviewData(res?.data?.result??{})
        //         setPreviewLoading(false)
        //     })
        //     .catch((err) => {
        //         setPreviewError(err?.message ?? 'Error!')
        //         setPreviewLoading(false)
        //     })
        // })
        // .catch((err) => {
        //     setDictError(err?.message ?? 'Error!')
        //     setDictSchemaData([])
        //     setDictLoading(false)
        // })
    }

    return (
        <div className='bizterm-stats-wrap'>
            <div className='biz-stats-grid h-100'>
                {/* <div className='h-100' style={{order: 3}}>
                    <LogsView
                        loading={logsLoading}
                        logsData={logsData}
                        error={logsError}
                        reloadLogs={() => {
                            setPodHealthReloading(true)
                            getStatsDetails()
                            fetchLogs()
                            setTimeout(() => {
                                setPodHealthReloading(false)
                            }, 500)
                        }}
                    />
                </div> */}
                <div className='pl-2' style={{order: 2, height: 'calc(100% - 0.75rem)'}}>
                    <SplitPane
                        split='horizontal'
                        sizes={sizes}
                        onChange={setSizes}
                        className='dict-middle-sep'
                    >
                        <div style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'start',
                                background: '#FFF',
                                padding: '0 0 0 10px'
                            }}>
                                {
                                    emptyLogs 
                                    // || logsData?.every(l => l?.overall_health === null)
                                    ?
                                    <div className='text-black-50 d-flex h-100 w-100 align-items-center justify-content-center flex-column'>
                                        <ICON_STATS height='50' width='50' color='currentColor' />
                                        <label className='mt-2 fontSizeLargeHeading fontInterSemiBold'>No Health Checks Found</label>
                                    </div>
                                    :
                                    <GraphView
                                        fieldValues={fieldValues}
                                        loading={logsLoading}
                                        logsData={logsData}
                                        error={logsError}
                                        reload={fetchLogs}
                                        reloadLogs={() => {
                                            setPodHealthReloading(true)
                                            getStatsDetails()
                                            fetchLogs()
                                            setTimeout(() => {
                                                setPodHealthReloading(false)
                                            }, 500)
                                        }}
                                    />
                                }
                        </div>
                        <div style={{ ...layoutCSS }}>
                            <div className={`dict-prev-tbl position-relative w-100 h-100 custom-virtual-data-table`}>
                                <StatsPreviewTable
                                    setPodDetails={setPodDetails}
                                    fieldValues={fieldValues}
                                    previewData={previewData}
                                    podDetails={podDetails}
                                    uniqUUID={uniqUUID}
                                    dictionaryData={dictSchemaData}
                                    setUniqUUID={setUniqUUID}
                                    previewLoading={previewLoading}
                                    previewError={previewError}
                                    reloadPreview={fetchPreview}
                                    dictError={dictError}
                                    dictLoading={dictLoading}
                                    getActivePod={(pod) => setActivePod(pod)}
                                    ref={previewRef}
                                />
                            </div>
                        </div>

                    </SplitPane>
                </div>
                <div style={{order: 1}}>
                    <StatsHealthView
                        overallHealth={
                            statsData?.health === null ? 100
                            :
                            statsData?.health?.[0]?.overall_health
                        }
                        fieldValues={fieldValues}
                        setFieldValues={setFieldValues}
                        activePod={activePod}
                        previewRef={previewRef}
                        loading={loadingStats}
                        activeTabKey={activeTabKey}
                        podHealthReloading={podHealthReloading}
                        initialData={initialData}
                        defaultDomain={defaultDomain}
                        previewLoading={dictLoading || previewLoading}
                    />
                </div>
            </div>
        </div>
    )
}

const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    background: '#FFF'
};

export default BusinessTermStats