import React from 'react'
import CopilotAlertCapsule from './CopilotAlertCapsule'
import ConversationSender from './ConversationSender'
import AutoGrowInput from '../connections/landingPage/components/autoGrowInput/AutoGrowInput'
import CopilotLoader from './CopilotLoader'
import { ICON_CLARISTA, ICON_CLOSE } from '../../Common/newIconSource'
import { useDispatch, useSelector } from 'react-redux'
import { createClassifiersCopilot } from '../../../store/modules/common/ClaristaCopilot/ClaristaCopilotReducer'
import CopilotMic from './CopilotMic'
import { Literals } from '../common/literals'
import Buttons from '../../Common/button/Buttons'

const ClassifierCopilot = React.forwardRef(({
    props
}, ref) => {

    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [conversation, setConversation] = React.useState([])
    const [hideMic, setHideMic] = React.useState(false)
    const [micListening, setMicListening] = React.useState(false)

    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const chatInputRef = React.useRef({})
    const scrollViewRef = React.useRef({})

    React.useImperativeHandle(ref, () => {
        // Future use
    }, [])

    // console.log({props})

    const generateClassifiers = (msg) => {
        chatInputRef?.current?.setInputValue('')
        chatInputRef?.current?.setInputDisabled(true)

        setConversation(prev => [...prev, {isSender: true, msg}])
        setHideMic(false)
        setLoading(true)
        setError(undefined)

        let payload = {
            organization_info: msg
        }
        dispatch(createClassifiersCopilot(payload, false))
        .then(res => {
            setLoading(false)

            chatInputRef?.current?.setInputDisabled(false)

            props?.getList()

            setConversation(prev => [...prev, {success: true}])

            setTimeout(() => {
                scrollViewRef?.current?.scrollTo(0, scrollViewRef?.current?.scrollHeight)
            }, 1000)
        })
        .catch(err => {
            console.log({err})

            chatInputRef?.current?.setInputValue(msg)
            chatInputRef?.current?.setInputDisabled(false)

            setConversation(prev => prev?.slice(0, prev?.length - 1))

            setError(err?.message)
            setLoading(false)
        })
    }

    return (
        <div className='sql-cpilot-wrap'>
            <div className='classi-cpilot-wrap' ref={scrollViewRef}>
                <div>
                    <h4 className='cpilot-head-lg fontInterSemiBold text-capitalize'>
                        Hello {`${userDetailsRedux?.first_name ?? ''} ${userDetailsRedux?.last_name ?? ''}`}!
                    </h4>
                    <h4 className='cpilot-head-md fontInterSemiBold'>
                        Classify your data instantly
                    </h4>
                </div>
                <div className='p-2'></div>
                <div className='pr-5 mb-2'>
                    <div className='cpilot-simple-msg-card'>
                        <div className='align-items-center d-flex fontSizeHeading fontInterSemiBold mb-1'>
                            Auto Generate Classifiers
                        </div>
                        <p className='mb-0 fontSizeLabel text-muted'>
                            Just Tell us about your data: its type, purpose, description & any key attributes. 
                        </p>
                    </div>
                </div>
                {
                    conversation?.map((c, i) => (
                        <div>
                        {    
                            c?.isSender
                            ?
                            <div>
                                <ConversationSender
                                    msg={c?.msg ?? ''}
                                />
                            </div>
                            :
                            <div className='pr-5 mb-2'>
                                <div className='cpilot-simple-msg-card d-flex align-items-center'>
                                    <div className='d-flex align-items-start'>
                                        <div className='d-flex mr-2'>
                                            <ICON_CLARISTA height='18' width='18'/>
                                        </div>
                                        <span className='fontSizeLabel'>
                                            Wohoo! Classification created. Now you can Review & Edit.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    ))
                }
            </div>


            {
                !loading ? ''
                :   <div className='my-3 px-3'>
                        <CopilotLoader/>
                    </div>
            }

            {
                error ? <CopilotAlertCapsule content={
                    <div className='fontSizeHeading'>
                        {error}
                    </div>
                } />
                :   ''
            }
            
            <div className={`cpilot-ask-inp`}>
                <AutoGrowInput
                    placeholder={`Eg: Health data: patient names, contact number...`}
                    onEnterCallback={val => {
                        generateClassifiers(val)
                    }}
                    setFocus={focus => {
                        if(micListening) {
                           setHideMic(false) 
                        }
                        else {
                            setHideMic(focus)

                        }
                    }}
                    ref={chatInputRef}
                />
                <div className={`cpilot-clearbtn ${!hideMic || micListening ? 'd-none' : ''}`}>
                    <Buttons
                        props={{
                            buttonText: '',
                            tooltip: '',
                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon bg-transparent`,
                            buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                chatInputRef?.current?.setInputValue("")
                                setHideMic(false)                             
                            },
                            ImgSrc: () => <ICON_CLOSE/>,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }}
                    />
                </div>
                <span className={hideMic && !micListening ? 'd-none' : ''}>
                    <CopilotMic
                        inputRef={chatInputRef}
                        getListening={listening => {
                            setMicListening(listening)
                        }}
                    />
                </span>
            </div>
        </div>
    )
})

export default ClassifierCopilot