
import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';

import './buttonedge.css';
import { ICON_DELETE } from '../../../Common/newIconSource';
import { DELETETRANSFORMER } from '../../../Common/iconSource';
import { useStore } from 'react-redux';
import { updateFlowsElements } from '../../../../store/modules/flows/flowsActions';
import { cloneDeep } from 'lodash';




function ButtonDeleteEdge({ id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd }) {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const store = useStore()

    const onEdgeClick = (evt, id) => {
        evt.stopPropagation();
        let flowsElements = store.getState().Lab.flowsElements;

        let newFlowsElements = cloneDeep(flowsElements)
        let newEdges = newFlowsElements?.edges.filter((item) => {
            return id !== item.id;
        });
        newFlowsElements['edges'] = newEdges
        store.dispatch(updateFlowsElements(newFlowsElements));

    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 15,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    <div className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
                        <DELETETRANSFORMER />
                    </div>
                </div>
            </EdgeLabelRenderer>
        </>
    );
}

export default ButtonDeleteEdge
