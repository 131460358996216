import React from 'react'
import FlowsGridListIndex from './FlowsGridListIndex';
import SchedulerGridListIndex from './SchedulerGridListIndex';
import '../../modules/flowsNew/Newflow.scss'
import "../../modules/flowsNew/flow.scss";

const CombineLandingIndex = (props) => {

    const GetCurrentLandingPage = () => {
        switch (props.activeTabKey) {
            case 'flows':
                return <FlowsGridListIndex  {...props} />
                break;
            // case 'alert':
            //     return <AlertGridListIndex {...props} />
            //     break; 
            case 'scheduler':
                return <SchedulerGridListIndex {...props} />
                break;

            default:
                return <></>
                break;
        }
    }
    return (
        <>
            { GetCurrentLandingPage() }
        </>
    )
}

export default CombineLandingIndex