
import {
  RESET_ANALYSIS_REDUX_STATE,
  SET_ANALYSIS_PREVIEW_TABLES,
  SET_GRAPH_COMMON_LAYOUT,
  SET_GRAPH_HEIGHT,
  SET_PREVIEW_RESPONSE_QUERY,
  SET_PREVIEW_TABLE_RESULT,
  SET_QUERY_EDITOR_QUERY,
  SET_QUERY_RESULT,
  SET_SELECTED_ANALYSIS_PODS,
  SET_SELECTED_CHART,
  SET_CHART_RESPONSE_QUERY,
  SET_GRAPH_PREVIEW_REQUEST,
  SET_PUBLISH_BUTTON_CLICK,
  DATA_DOMAIN_LIST,
  FILTER_CHART_LIST,
  SET_SORT_ORDER_LIST,
  SET_CHART_FILTER_RESULT,
  SET_COLUMNS_LIST,
  SET_FILTER_DATA,
  SET_GRAPH_DATA,
  SET_CONTROL_LIST,
  SET_APPLY_STYLE_CLICK,
  SET_KPI_PARAMETERS,
  SET_DONE_BTN_CLICK,
  CLEAR_SELECTED_ANALYSIS_PODS,
} from "./analytsisType";
const ChartMainTypes={
  BAR:'bar',
  LINE:'line',
  SCATTER:'scatter',
  HEATMAP:'heatmap',
  BOXPLOT:'boxplot',
  AREA:'area',
  PIE:'pie',
  TABLE:'table',
  KPI:"KPI",
  FILTERS:'filters',
  BUBBLE:'bubble'
}

  const ChartSubTypes={
  BASIC:'basic',
  STACK:'stack',
  WATERFALL:'waterfall',
  HORIZONTAL:'horizontalbar',
  GROUP:'group',
  MULTICATEGORY:'multicategory',
  DOUGHTNUT:'doughtnut',
  NIGHTANGLE:'nightanglerose',

}
const initialState = {
  selectedPodListAnalysis: [],
  queryResult: {},
  previewTableResult: {},
  sqlEditorQuery: "",
  responseQuery: "",
  previewAnalysisTables: [],
  selectedChart: {
    mainType: ChartMainTypes.LINE,
    subType: ChartSubTypes.BASIC,
  },
  graphHeight: "66vh",
  dataDomainList: [],
  filterChartList: [],
  graphCommonLayout: {
    xAxisLabel: "",
    yAxisLabel: "",
    chartTitle: "",
    areaMode: "lines",
    showLegend: true,
    orientation: "v",
    lineType: "solid",
    lineWidth: "2",
    curve: "spline",
    markerSize: 12,
    pieCentralText: "",
    showLabel: false,
  },
  graphPreviewRequest: {},
  applyStyle: false,
  chartResponseQuery: "",
  isPublishButtonClick: false,
  sortOrderList: [],
  chartFilterResult: [],
  columnsList: [],
  filterData: {},
  graphData: [],
  doneBtnClick: false,
  controlList: [],
  kpiParameters: {},
};

const AnalysisReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_ANALYSIS_REDUX_STATE:
      initialState["previewAnalysisTables"] = [];
      initialState["previewTableResult"] = {};
      return initialState;

    case SET_PUBLISH_BUTTON_CLICK:
      return {
        ...state,
        isPublishButtonClick: payload,
      };

    case SET_DONE_BTN_CLICK:
      return {
        ...state,
        doneBtnClick: payload,
      };
    case SET_APPLY_STYLE_CLICK:
      return {
        ...state,
        applyStyle: payload,
      };
    case SET_SORT_ORDER_LIST:
      return {
        ...state,
        sortOrderList: payload,
      };

    case SET_SELECTED_ANALYSIS_PODS:
      return {
        ...state,
        selectedPodListAnalysis: payload,
      };

    case CLEAR_SELECTED_ANALYSIS_PODS:
      return {
        ...state,
        selectedPodListAnalysis: [],
      };
    case SET_GRAPH_PREVIEW_REQUEST:
      return {
        ...state,
        graphPreviewRequest: payload,
      };
    case SET_PREVIEW_TABLE_RESULT:
      return {
        ...state,
        previewTableResult: payload,
      };
    case SET_QUERY_RESULT:
      return {
        ...state,
        queryResult: payload,
      };
    case SET_PREVIEW_RESPONSE_QUERY:
      return {
        ...state,
        responseQuery: payload,
      };
    case SET_QUERY_EDITOR_QUERY:
      return {
        ...state,
        sqlEditorQuery: payload,
      };

    case SET_ANALYSIS_PREVIEW_TABLES:
      return {
        ...state,
        previewAnalysisTables: payload,
      };
    case SET_GRAPH_COMMON_LAYOUT:
      return {
        ...state,
        graphCommonLayout: payload,
      };
    case SET_CHART_RESPONSE_QUERY:
      return {
        ...state,
        chartResponseQuery: payload,
      };
    case SET_SELECTED_CHART:
      return {
        ...state,
        selectedChart: payload,
      };
    case SET_GRAPH_HEIGHT:
      return {
        ...state,
        graphHeight: payload,
      };
    case DATA_DOMAIN_LIST:
      return {
        ...state,
        dataDomainList: payload,
      };
    case FILTER_CHART_LIST:
      return {
        ...state,
        filterChartList: payload,
      };
    case SET_CHART_FILTER_RESULT:
      return {
        ...state,
        chartFilterResult: payload,
      };
    case SET_COLUMNS_LIST:
      return {
        ...state,
        columnsList: payload,
      };
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: payload,
      };
    case SET_GRAPH_DATA:
      return {
        ...state,
        graphData: payload,
      };
    case SET_CONTROL_LIST:
      return {
        ...state,
        controlList: payload,
      };
    case SET_KPI_PARAMETERS:
      return {
        ...state,
        kpiParameters: payload,
      };
    default:
      return state;
  }
};

export default AnalysisReducer;
