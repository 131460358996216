import React, { useEffect, useState } from "react";
import { useStore, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GetAlertDataApi } from "../../../../store/modules/alert/alertLanding/alertLandingAction";
import {
  ResetCreateAlertRedux,
  setSelectedAlertPODList,
} from "../../../../store/modules/alert/createAlert/createAlertAction";
import {
  ALERT,
  FILTERBLUE,
  FLOWICON,
  FLOWS,
  LOGICALICON,
  SQLBLUE,
} from "../../../Common/iconSource";
import PODSelection from "../../../Common/PODSelection";
import LandingpageFirstHeader from "../../common/LandingpageFirstHeader";
import { Literals } from "../../common/literals";
import AlertGridView from "../components/alertGridView";
import AlertLandingHeader from "../components/AlertLandingHeader";
import AlertListView from "../components/alertListView";

import moment from "moment";

import { getRelativeTime, showSimpleLoading, NoDataComponent, ErrorComponent, permissions } from "../../common/helperFunctions";
import "../alerts.scss";
import Filter from "../../../Common/filter/Filter";
import { openFilter } from "../../../../store/modules/alert/alertLanding/alertLandingAction";

import NoSearchResultFound from "../../common/NoSearchResultFound";
import { setSelectedPodList } from "../../../../store/modules/common/podSelectionModalAction";
import {
  openModel,
  resetGraphSampleSelected,
  setSidebarActiveRoute,
} from "../../../../store/actions";
import { resetAllDataGridTableFilters } from "../../../../store/modules/common/DataGridTable/dataGridTableRedux";
import { getGlobalConnectionCache } from "../../../../store/modules/UserManagement/connection/connectionActions";
import MyAlertIndex from "../myAlert/MyAlertIndex";
import { resetVirtualTableActions } from "../../../../store/modules/common/VirtualDataTable/action";

function LandingPageIndex({
  fromRoute,
  setselectedAlertInfo,
  selectedAlertInfo = {},
  isModal = false
}) {
  const [isModalOpen,] = useState(false);
  const history = useNavigate();
  const location = useLocation()
  const store = useStore();
  const [layout, setLayout] = useState("grid");
  const [searchValue, setSearchValue] = useState("");
  const [showFilter, setShowFilter] = useState();
  const alertFilters = useSelector((state) => state.App);
  const [showLoadingMsg, setshowLoadingMsg] = useState(true)

  const [selectedFilters, setselectedFilters] = useState(
    alertFilters.Filters.alert?.selectedFilters ?? []
  );
  const [alertData, setAlertData] = useState([]);
  const [alertDataClone, setAlertDataClone] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [activeTab, setActiveTab] = useState("alerts");
  const [empty, setEmpty] = useState(false)
  const dispatch = useDispatch();
  const filterStatus = useSelector(
    (state) => state.Alert.GetAlertDataReducer.openFilter
  );
  const loadingStatus = useSelector(
    (state) => state.LoadingReducer.loadingStatus
  );
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [datePickerValue,] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState(currentDate);

  useEffect(() => {
    if (location?.state?.activeTab === "myalerts") {
      setActiveTab("myalerts");
    }
    GetData();
    store.dispatch(getGlobalConnectionCache())

    store.dispatch(ResetCreateAlertRedux());
    store.dispatch(setSelectedPodList([]));
    store.dispatch(openModel(false));
    dispatch(resetVirtualTableActions())// check hang screen check hang screen

    store.dispatch(resetAllDataGridTableFilters());
    store.dispatch(resetGraphSampleSelected());
    if (fromRoute !== "scheduler") {
      dispatch(setSidebarActiveRoute("/alerts"));
    }
  }, []);

  const GetData = () => {

    dispatch(GetAlertDataApi('', fromRoute === "scheduler" ? `editor=true` : ''))
      .then((res) => {

        setshowLoadingMsg(true)
        if (res.data.length == 0) {
          setEmpty(true);
        }
        else if (res.data.length) {
          setshowLoadingMsg(false)

          // if (res.data[0]?.view_type == "grid") {
          //   setLayout("grid");
          // } else {
          //   setLayout("list");
          // }
          // if (fromRoute === 'scheduler') {
          //   res.data = res.data.filter(ele => ele.type.toLowerCase() !== 'flow')
          // }
          let dat = res?.data

          if (isModal) {
            dat = dat?.filter(el => !!el?.permission?.find(v => v === permissions.alerts.editor))
          }

          if (filterData(dat)?.length && searchValue !== "") {

            let findArray = filterData(dat).filter((data) => {
              return (
                data?.name
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                data?.description
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                data?.priority
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                data?.purpose
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                getRelativeTime(data?.created_on)
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                data?.created_by_user?.email
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                getRelativeTime(data?.updated_on)
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                data?.updated_by_user?.email
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                data?.data_domain.find((item, i) => {
                  return (
                    item?.data_domain_name
                      ?.toLowerCase()
                      .includes(searchValue?.toLowerCase()) ||
                    item?.table_name
                      ?.toLowerCase()
                      .includes(searchValue?.toLowerCase())
                  );
                })
              );
            });


            setAlertData([...findArray]);
          } else {
            setAlertData(filterData(dat));
          }
          setAlertDataClone([...dat]);
        }
      })
      .catch((err) => {

        setshowLoadingMsg(false)
        setErrMsg(err.message)
      });
  };

  useEffect(() => {
    if (filterData()?.length && searchValue !== "") {

      let findArray = filterData().filter((data) => {
        return (
          data?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          data?.description
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          data?.priority?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          data?.purpose?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          getRelativeTime(data?.created_on)
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          data?.created_by_user?.email
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          getRelativeTime(data?.updated_on)
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          data?.updated_by_user?.email
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          data?.data_domain.find((item, i) => {
            return (
              item?.data_domain_name
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase()) ||
              item?.table_name
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase())
            );
          })
        );
      });


      setAlertData([...findArray]);
    } else {
      setAlertData(filterData());
    }

  }, [searchValue, selectedFilters]);

  const filterData = (responseData = []) => {
    let dataDomain = [];
    let favourites = [];
    if (selectedFilters.length > 0) {
      selectedFilters.forEach((val) => {
        if (val["Data Domain"]) {
          dataDomain = val["Data Domain"].map((val) => val.name) || [];
        }
        if (val["emptyheader"]) {
          favourites = val["emptyheader"].map((val) => val.name) || [];
        }
      });
    }

    let oldObj =
      responseData && responseData.length
        ? [...responseData]
        : [...alertDataClone];

    oldObj.forEach((val) => {
      if (favourites.length) {
        oldObj = oldObj.filter((a) => a.bookmark);
      }
      if (dataDomain.length) {
        oldObj = oldObj.filter((a) => {
          if (a?.data_domain?.length) {
            return a.data_domain.find((domain) =>
              dataDomain.includes(domain.data_domain_name)
            );
          }
        });
      }
    });
    if (selectedFilters.length) {
      return oldObj;
    }
    else {
      return responseData && responseData.length
        ? [...responseData]
        : [...alertDataClone];
    }
  };

  const toggleModalHandler = () => {
    history(Literals.links.CREATE_ALERT,
      {
        state: { exceptionid: null, from: "alert" },
      });
  };

  const onRowSelection = (record) => {

  };

  const onProceedHandler = (selectedPODsList) => {
    store.dispatch(setSelectedAlertPODList(selectedPODsList));
    store.dispatch(setSelectedPodList(selectedPODsList));
    history(Literals.links.CREATE_ALERT,
      {
        state: { exceptionid: null, from: "alert" },
      });
  };

  const getAccordion = (data) => {
    return layout === "grid" ? (
      <AlertGridView
        searchValue={searchValue}
        GetData={GetData}
        getType={getType}
        data={data}
        getRelativeTime={getRelativeTime}
      />
    ) : (
      <AlertListView
        data={data}
        searchValue={searchValue}
        GetData={GetData}
        getRelativeTime={getRelativeTime}
      />
    );
  };

  const getType = (val, from = "ListView") => {
    let value = val.toLowerCase();
    switch (value) {
      case "sql":
        return (
          <SQLBLUE
            width={from && from.toLowerCase() === "listview" ? "20" : "35"}
            height={from && from.toLowerCase() === "listview" ? "20" : "35"}
          />
        );
      case "filter":
        return (
          <FILTERBLUE
            width={from && from.toLowerCase() === "listview" ? "20" : "35"}
            height={from && from.toLowerCase() === "listview" ? "20" : "35"}
          />
        );
      case "logical":
        return (
          <LOGICALICON
            width={from && from.toLowerCase() === "listview" ? "20" : "35"}
            height={from && from.toLowerCase() === "listview" ? "20" : "35"}
          />
        );
      case "flow":
        return (
          <span className="">
            {/* <span className="primary-svg-color"> */}
            {
              from.toLowerCase() === "listview"
                ? <FLOWS
                  width={from && from.toLowerCase() === "listview" ? "20" : "35"}
                  height={from && from.toLowerCase() === "listview" ? "20" : "35"}
                  color="#2C83C4"
                />
                : <FLOWICON width="20" color="#2C83C4" />
            }
          </span>
        );
      default:
        return (
          <SQLBLUE
            width={from && from.toLowerCase() === "listview" ? "24" : "35"}
            height={from && from.toLowerCase() === "listview" ? "24" : "35"}
          />
        );
    }
  };

  const getFormattedData = (allData = []) => {
    let dataDomain = [];
    let favourites = [];
    if (selectedFilters.length > 0) {
      selectedFilters.forEach((val) => {
        if (val["Data Domain"]) {
          dataDomain = val["Data Domain"].map((val) => val.name) || [];
        }
        if (val["emptyheader"]) {
          favourites = val["emptyheader"].map((val) => val.name) || [];
        }
      });
    }

    let categories = [];
    let domainName = {};
    allData.forEach((el, key) => {
      if (el?.data_domain?.length) {
        el.data_domain.forEach((item) => {
          domainName[item?.data_domain_name] = item.data_domain_id;
        });
      }
    });
    Object.keys(domainName).forEach((name, key) =>
      categories.push({
        id: key,
        checked: dataDomain.includes(name),
        name: name,
        category: name,
      })
    );

    const obj2 = {
      header: "Data Domain",
      selectAll: false,
      list: categories,
    };
    const obj = {
      header: "",
      list: [{ id: 1, checked: favourites.length > 0, name: "Favourites" }],
    };
    return [obj, obj2];
  };
  const getFiltersStatus = () => {
    let filters = [...selectedFilters],
      status = null
    if (filters.length === 0) status = false
    else if (filters.length > 0) {
      status = !filters.map(v => v[Object.keys(v)[0]].length > 0).every(v => v === false)
    }

    return status

  }
  return (
    <div className="alert-dashboard">
      {!fromRoute && fromRoute !== "scheduler" && (
        <LandingpageFirstHeader
          title={Literals.ALERT}
          flag={false}
          backRoute={"/alert"}
        />
      )}
      <AlertLandingHeader
        searchValue={searchValue}
        isFilterApplied={getFiltersStatus()}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setSearchValue={setSearchValue}
        showFilter={showFilter}
        datePickerValue={datePickerValue}
        setstartDate={setstartDate}
        setendDate={setendDate}
        setShowFilter={setShowFilter}
        layout={layout}
        setLayout={setLayout}
        fromRoute={fromRoute}
        selectedFilters={selectedFilters}
        setselectedFilters={setselectedFilters}
        toggleModalHandler={toggleModalHandler}
        isDisableAllOptions={empty}
      />

      <div className="alert-landing-body">

        <div>
          {errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> : alertData.length ? (
            activeTab === "alerts" ? <>
              {layout === "grid" ? (
                <AlertGridView
                  selectedAlertInfo={selectedAlertInfo}
                  setselectedAlertInfo={setselectedAlertInfo}
                  searchValue={searchValue}
                  GetData={GetData}
                  getType={getType}
                  data={alertData}
                  getRelativeTime={getRelativeTime}
                  fromRoute={fromRoute}
                />
              ) : (
                <AlertListView
                  setselectedAlertInfo={setselectedAlertInfo}
                  selectedAlertInfo={selectedAlertInfo}

                  data={alertData}
                  searchValue={searchValue}
                  getType={getType}
                  GetData={GetData}
                  getRelativeTime={getRelativeTime}
                  fromRoute={fromRoute}
                />
              )}
            </> :
              <MyAlertIndex
                startDate={startDate}
                endDate={endDate}
                searchData={searchValue}
                empty={empty}
                setEmpty={setEmpty}
              />
          ) : empty ?
            <NoDataComponent logo={<ALERT width="60" height="60" color="black" />}
              message=" Click on (+) to Create Alert" /> :
            (!loadingStatus ? (searchValue !== '' || selectedFilters.length)
              &&
              (alertData && !alertData.length)
              ?
              <NoSearchResultFound mainMsg={searchValue === '' ? 'No Alert List Found' : ''} subMsg={'Please try Search / Filter with another term'} />

              :
              showLoadingMsg ? showSimpleLoading("Please Wait Loading Alert List...") :
                <NoSearchResultFound mainMsg={'No Alert List Found'} subMsg={'Please Create New Alert'} /> : null)}

        </div>

      </div>

      {filterStatus ? (
        <Filter
          props={{
            filterList: getFormattedData(alertDataClone),
            selectedFilters: selectedFilters,
            type: "alert",
            showPopover:"new",
            setselectedFilters: setselectedFilters,
            from: "alertModal",
            setshowFilter: () => dispatch(openFilter("")),
          }}
        />
      ) : null}

      <PODSelection
        isMultiSelection={true}
        save={onProceedHandler}
        podId={""}
        openModal={isModalOpen}
        onClose={() => {
          toggleModalHandler();
        }}
        onRowSelection={onRowSelection}
      />
    </div>
  );
}

export default LandingPageIndex;
