import React, { useState } from "react";
import { useSelector, useStore } from "react-redux";
import { Switch } from "antd";
import PreviewQuery from "./PreviewQuery";
import { Tooltip } from "antd";
import ClaristaLoader from "../../../../../Common/claristaLoader/ClaristaLoader";
import {
  CLARISTA_LOGO,
  CLOSE,
  SQLICON,
} from "../../../../../Common/iconSource";
const PreviewSummary = ({
  onExecuteQueryClick,
  summaryLoaded,
  sqlTableDetails,
  summaryHeight,
  isDataComing,
  isVisible,
  Query,
  setQuery,
  isGridLoaded,
}) => {
  const store = useStore()
  const [sqlMode, setSqlMode] = useState(false);
  const isChatgpt = useSelector(
    (state) => state.DataCatalogue.PreviewPage.isChatgpt
  );
  return (
    <div className={`preview-card ${sqlMode ? "active" : ""} ${isChatgpt ? "chatgpt" : ""}`}>
      <div className="preview-card-inner">
        <p
          className={`preview-summary ${isChatgpt ? "chatgpt" : ""}`}
          style={{ whiteSpace: "pre-line", display: "flex" }}
          id="timeline-summary"
        > <span>Your request is being processed, please wait</span><div className='dot-container'><div className='dot-typing'></div></div></p>
        
        <div className="preview-sql">
          <PreviewQuery
            onExecuteQueryClick={onExecuteQueryClick}
            summaryLoaded={summaryLoaded}
            sqlTableDetails={sqlTableDetails}
            Query={Query}
            setQuery={setQuery}
            isGridLoaded={isGridLoaded}
          />
        </div>
      </div>
      {summaryLoaded ? (
        <div
          style={{ marginTop: "30px", float: "right" }}
          onClick={() => {

            let scrollElement = document.querySelector(".preview-timeline");
            scrollElement?.scrollTo(0, 0)
            // scrollElement.addEventListener("scroll",(e)=>{
            //   let summaryHeight=document.querySelector("#timeline-summary").offsetHeight;
            //   let offsetHeight=window.screen.availHeight>1100?10:0
            //   if(e.target.scrollTop>(summaryHeight-offsetHeight) && isIntersecting==false){
            //     setIntersecting(true)
            //     setExpandDropdown(false);
            //   }
            //   else {
            //     setIntersecting(false);
            //   }
            // })

            let val = !sqlMode;
            let el = document.querySelector(".preview-card");

            if (val) {
              el.style.height = "320px";
            } else {
              el.style.height = `${summaryHeight}` || "200px";
            }
            setSqlMode(val);


          }}
          className={`sql-icon ${store.getState().DataCatalogue.PreviewPage.sqlEditorQuery?.trim() === '' ? 'd-none' : ''}`}
        >
          <div>
            <div className={`icon-card ${sqlMode ? "active" : ""}`}>
              <div className="icon-card-inner">
                <div className="card-front">
                  <SQLICON />
                </div>
                <div className="card-back">
                  <CLOSE width={20} height={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {!summaryLoaded && !isDataComing ? (
        <div style={{ position: "absolute", left: "50%" }}>
          {/* <ClaristaLoader /> */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PreviewSummary;
