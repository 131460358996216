import { Popover } from 'antd'
import React, { useMemo } from 'react'
import { ICON_ARROW_RIGHT, ICON_USER } from '../../../Common/newIconSource'
import CustomDropdownContent from '../../../Common/CustomDropdownContent'
import { useDispatch } from 'react-redux'
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions'

const UserDropdown = ({ changeTab = false,
    popoverWrapperClass = '',
    setselectedUser = () => { },
    selectedUser = ''

}) => {

    const dispatch = useDispatch()

    const [users, setUsers] = React.useState([])
    const [menuList, setMenuList] = React.useState([{
        label: 'ALL',
        tooltip: 'ALL',
        key: 'all',
        email: "all",
        active: true,
        disabled: false,
        data: {}
    }])
    const [searchUser, setSearchUser] = React.useState('')

    const getUserDetails = () => {
        dispatch(getUserGroupList({ method: "GET", endPoint: "profile/", payload: undefined, showLoader: false }))
            .then(res => {
                let data = res?.data ?? []
                let list = data?.map(ele => (
                    {
                        label: ele?.first_name ? `${ele?.first_name} ${ele?.last_name}` : '[Unnamed User]',
                        key: ele?.id,
                        email: ele?.email,
                        tooltip: `${ele?.first_name} ${ele?.last_name} (${ele?.email})`,
                        data: { ...ele }
                    }
                ))
                setUsers([...data]);
                setMenuList([...menuList, ...list])
            }).catch(() => { })
    }
    React.useEffect(() => {

        getUserDetails();
    }, [])

    useMemo(() => {
        if (selectedUser === 'all') {
            setMenuList(prev => {
                prev = prev?.map(m => ({ ...m, active: 'all' === m?.key }))
                return prev
            });
        }
    }, [selectedUser])

    useMemo(() => {
        if (changeTab) {
            getUserDetails();
        }
    }, [changeTab])

    const items = React.useMemo(() => {
        let list = [...menuList]
        list = list?.filter(d => d?.label?.toLowerCase()?.includes(searchUser?.toLowerCase()))
        return list
    }, [menuList, searchUser])

    return (
        <Popover
            trigger={'hover'}
            title={''}
            destroyTooltipOnHide
            content={
                <CustomDropdownContent
                    items={items ?? []}
                    searchValue={searchUser}
                    setSearchValue={setSearchUser}
                    emptyDescription='No Users'
                    searchPlaceholder='Search Users...'
                    onItemClick={(itm) => {
                        setselectedUser(itm)
                        setMenuList(prev => {
                            prev = prev?.map(m => ({ ...m, active: itm?.key === m?.key }))
                            return prev
                        })
                    }}
                />
            }
            onOpenChange={(visible) => {
                if (!visible) setSearchUser("")
            }}
            placement='bottom'
            arrow={false}
            rootClassName={`nusg-domain-ddl ${popoverWrapperClass}`}
        >
            <label className='label'>Select User</label>
            <div className='d-flex align-items-center nusg-dropdown-selected' style={{ width: '11.25rem' }}>
                <div className='nusg-dropdown-ic' style={{ background: '#E2FFCC' }}>
                    <ICON_USER color='#565656' />
                </div>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <span className='fontSizeHeading nusg-dropdown-label text-with-ellipsis'
                        title={menuList?.find(m => m?.active)?.label ?? "Select User"}
                        style={{ width: '7.25rem' }}>
                        {
                            menuList?.find(m => m?.active)?.label ?? "Select User"
                        }
                    </span>
                    <span className='bx-rotate-90 ml-2'>
                        <ICON_ARROW_RIGHT height='10' width='10' />
                    </span>
                </div>
            </div>
        </Popover>
    )
}

export default UserDropdown