import { Select, Switch } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { changeProviderMFAStatus, createTenantBasic_auth, createTenantOpenAi, getTenantBasic_auth, getTenantOpenAi } from '../../../../../store/modules/UserManagement/users/usersActions'
import { ICON_ALERT, ICON_CHECK, ICON_LOADING_CIRCLE } from '../../../../Common/newIconSource'

const EmailLoginConfigForm = React.forwardRef(({
    fieldValues = {},
    setFieldValues = () => { },
    loading = false,
    setLoading = () => { },
    isActiveToggle = false,
    providerToggleAPI = () => { },
    setisActiveToggle = () => { },
    saving = false,
    setSaving = () => { },
    loadingtoggle = false,
    setLoadingtoggle = () => { }
}, ref) => {

    const dispatch = useDispatch()

    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showError, setShowError] = React.useState(false)
    const [activeTypeToggle, setactiveTypeToggle] = React.useState(false)
    const [mfa_enabled, setmfa_enabled] = React.useState(false)
    const [type, setType] = React.useState('openai')

    React.useImperativeHandle(ref, () => {
        return {
            save: () => {
                handleSave()
            }
        }
    }, [fieldValues])

    React.useEffect(() => {
        setFieldValues(prev => (
            {
                ...prev,
                basic_auth: {
                    mfa_enabled: false,

                }
            }
        ))
        getDetails()
    }, [])

    const getDetails = () => {
        setLoading(true)
        dispatch(getTenantBasic_auth())
            .then(res => {
                let data = res?.data ?? []
               
                let el = {};
                if (data?.length) {
                    el = data?.find(item => item.provider === "basic_auth");
                }
                setType(el?.type)
                setFieldValues(prev => (
                    {
                        ...prev,
                        basic_auth: {
                            mfa_enabled: el?.mfa_enabled ?? false
                        }
                    }
                ))
                setisActiveToggle(el?.is_active)
                setactiveTypeToggle(el?.is_active)
                setmfa_enabled(el?.mfa_enabled)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const handleSave = () => {
        setShowError(false)
        setShowSuccess(false)
        setSaving(true)
        let values = fieldValues?.basic_auth
        let payload = {
            ...values
        }
        setTimeout(() => {
            dispatch(createTenantBasic_auth(payload))
                .then(res => {
                    setSaving(false)
                    setShowSuccess(true)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 5000)
                })
                .catch(err => {
                    setSaving(false)
                    setShowError(true)
                    setTimeout(() => {
                        setShowError(false)
                    }, 5000)
                })
        }, 5000)
    }

    const onInputHandler = (key, value) => {
        setFieldValues(prev => {
            return {
                ...prev,
                basic_auth: {
                    ...prev?.basic_auth,
                    [key]: value
                }
            }
        })
    }

    return (
        <div>
            <div className='row'>
                {/* <div className="col-3 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Type
                        </label>
                    </div>
                    <Switch
                        size='small'
                        checked={activeTypeToggle}
                        loading={loadingtoggle}
                        onChange={(checked) => {
                            // providerToggleAPI(checked);
                            setactiveTypeToggle(checked)
                            setLoadingtoggle(true)


                        }}
                    />
                </div> */}
                <div className="col-9 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Two-Factor Authentication<span style={{ fontSize: "9px", fontStyle: "italic", }}>{"On (TOTP Authenticator)"}</span>
                        </label>
                    </div>
                    <Switch
                        size='small'
                        checked={mfa_enabled}

                        onChange={(checked) => {
                            setmfa_enabled(checked)
                            onInputHandler('mfa_enabled', checked)
                            dispatch(changeProviderMFAStatus(true,'basic_auth', checked)).then((res)=>{
                                
                            })

                        }}
                    />
                </div>

            </div>
        </div>
    )
})

export default EmailLoginConfigForm