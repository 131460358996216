import { SET_USER, USER_LOGOUT } from "./actionType";

const UserReducer = (state = {}, action) => {

  const { type, payload } = action

  switch (type) {
    case SET_USER:
      return {
        user: { ...payload }
      }

    case USER_LOGOUT:
      return {
        user: { ...payload }
      }
    default: return state
  }

}


export default UserReducer