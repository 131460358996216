const SET_TUTORIAL_DATA = "SET_TUTORIAL_DATA"
const SET_NAVIGATOR_TUTORIAL_DATA = "SET_NAVIGATOR_TUTORIAL_DATA"
const SET_PULSE_TUTORIAL_DATA = "SET_PULSE_TUTORIAL_DATA"
const RESET_TUTORIAL_DATA = "RESET_TUTORIAL_DATA"

export const setTutorialStart = (data) => {

    return {
        type: SET_TUTORIAL_DATA,
        payload: data
    };
};

export const setNavigatorTutorialStart = (key = '' ,data) => {

    return {
        type: SET_NAVIGATOR_TUTORIAL_DATA,
        payload: {key, data}
    };
};

export const setPulseTutorialStart = (key = '' ,data) => {

    return {
        type: SET_PULSE_TUTORIAL_DATA,
        payload: {key, data}
    };
};

export const resetPodCreationData = () => {
    
    return {
        type: RESET_TUTORIAL_DATA,
        payload: {
            navigator: {
                create_pod: false,
                landing_page: false
            }
        },
        pulse: {
            dashboard: false
        }
    };
};

const initialState = {
    navigator: {
        create_pod: false,
        landing_page: false
    },
    pulse: {
        dashboard: false
    }
}

const TutorialRedux = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case SET_TUTORIAL_DATA:
            return {
                ...state,
                ...payload
            }

        case SET_NAVIGATOR_TUTORIAL_DATA:
            const {key, data} = payload
            return {
                ...state,
                navigator: {
                    ...state.navigator,
                    [key]: data
                }
            }

        case SET_PULSE_TUTORIAL_DATA:
            return {
                ...state,
                pulse: {
                    ...state.pulse,
                    [payload.key]: payload.data
                }
            }

        case RESET_TUTORIAL_DATA:
            return {...payload}

        default:
            return state;
    }
}
export default TutorialRedux;