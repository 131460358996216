import React, { useEffect } from 'react'
import { useSelector, useDispatch, useStore } from "react-redux"

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Buttons from '../../../Common/button/Buttons';
import { CHECK, CLOSE } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import { SET_FLOWS_ALERT_TRANSFORMER_LIST } from '../../../../store/modules/alert/createAlert/createAlertActiontype';

import { DataDictionary, Scheduler } from '../index'
import { setSelectedScheduler } from '../../../../store/modules/scheduler/action';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { CategoryTable } from './DataCategory';
import { SET_SELECTED_BUSINESS_TERM, SET_SELECTED_BUSINESS_TERM_TEMP } from '../../../../store/modules/dataCatalogue/dictionary/dictionaryActionTypes';



const BusinessTermModal = ({ 
    isModalOpen, 
    selectedIds, 
    onClose, 
    save, 
    isSingleSelection, 
    reset, 
    fromRoute = '',
    populateSearchData = ''
}) => {
    const [selected, setSelected] = React.useState([]);
    const store = useStore()
    const selectedBusinessTermTemp = useSelector(state => state.DataCatalogue.Dictionary.selectedBusinessTermTemp)
    useEffect(() => {
        if (selectedBusinessTermTemp && selectedBusinessTermTemp.id) {
            setSelected(selectedBusinessTermTemp)
        }
    }, [selectedBusinessTermTemp])
    const onSaveHandler = () => {
        save(selected);
        store.dispatch({ type: SET_SELECTED_BUSINESS_TERM, payload:selectedBusinessTermTemp })
        store.dispatch({ type: SET_SELECTED_BUSINESS_TERM_TEMP, payload: {} })

    }
    useEffect(() => {

        if (reset)
            setSelected([]);
    }, [reset])

    return (
        <Modal size="xl" isOpen={isModalOpen} className="custom-modal business-term-selection-modal" centered={true}>
            <ModalHeader>

                <div className="modal-heading-logs">Select Display Name</div>
                <Buttons props={{ tooltip: 'Close', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon float-right", buttonEvent: () => { onClose() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

            </ModalHeader>
            <ModalBody className='overflow-hidden'>
                <div className='businessterm-modal' style={{ height: "500px", overflow: "hidden", padding: "0px " }}>
                    <DataDictionary 
                        selectedIds={selectedIds} 
                        isSingleSelection={isSingleSelection} 
                        selected={selected} 
                        setSelected={setSelected} 
                        save={save} 
                        fromRoute={fromRoute} 
                        populateSearchData={populateSearchData}
                        />
                </div>
            </ModalBody>
            <ModalFooter>
                <Buttons 
                    props={{ 
                        tooltip: 'Done', 
                        buttonText: 'Done', 
                        buttonClassName: "custom-btn-primary custom-btn btn-with-text float-right", 
                        buttonEvent: () => { 
                            onSaveHandler(); 
                            onClose(); 
                        }, 
                        ImgSrc: () => <CHECK />, 
                        isDisable: selected?.length === 0, 
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT 
                    }} 
                />

            </ModalFooter>

        </Modal >
    )
}

export default BusinessTermModal
