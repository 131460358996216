import React, { useEffect,useState } from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

function Accordian({ props }) {
    const { accordionList=[],Icon,defaultExpandedPanel=[] ,style={}} = props
    const [defaultExpandedPanelList, setdefaultExpandedPanel] = useState(defaultExpandedPanel)
    useEffect(() => {
      
      setdefaultExpandedPanel([...defaultExpandedPanel])
    }, [defaultExpandedPanel])
    const renderAdditionalJSX = (addButton) => {
        if(addButton) {
            return <div className="add-icon-container">
                <span className="mr-1">
                    {addButton}
                </span>
                {Icon()}
            </div>
        }
        
        return Icon();
    }
    
    return (
        <div style={style} className="custom-accordion">
            <Collapse bordered={false} ghost accordion={false}   defaultActiveKey={defaultExpandedPanelList}  >         
                {accordionList?.length && accordionList.map((panel) =>
                    <Panel showArrow={false}  header={panel?.header} key={panel?.key} extra={renderAdditionalJSX(panel.addButton)} > 
                        {panel?.innerContent()}
                    </Panel>
                )}
            </Collapse>
        </div>
    )
}

export default Accordian
