import React from 'react'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { getJourneyDate, getReloadErrorTemplate, pxToRem, remToPx } from '../../common/helperFunctions'
import ReactECharts from 'echarts-for-react'
import Buttons from '../../../Common/button/Buttons'
import { ICON_REFRESH } from '../../../Common/newIconSource'
import { Literals } from '../../common/literals'

const GraphView = ({
    loading = false,
    logsData = [],
    error = undefined,
    reload = () => { },
    reloadLogs = () => { },
    fieldValues = {}
}) => {

    if (logsData && logsData?.length) {
        logsData = logsData.sort((a, b) => new Date(a.start_date_time).getTime() - new Date(b.start_date_time).getTime())
    }

    const options = React.useMemo(() => {
        const fs = remToPx(pxToRem(14))
        const dynamic = (px) => remToPx(pxToRem(px))
        return {
            textStyle: {
                fontSize: fs, // Set your desired global font size here
            },
            grid: { top: dynamic(38), right: dynamic(100), bottom: dynamic(60), left: dynamic(100) },
            legend: {
                data: [{name: fieldValues?.business_term?.toUpperCase()}],
                textStyle: {
                  fontSize: fs - 2, // Font size for legend text
                },
                // itemWidth: remToPx(pxToRem(12)), // Width of the legend icon
                itemHeight: remToPx(pxToRem(12)), // Height of the legend icon
            },
            xAxis: {
                name: 'Date',
                type: 'category',
                data: logsData?.map(d => getJourneyDate(new Date(d?.start_date_time)?.toDateString())),
                axisLabel: {
                    fontSize: fs
                }
            },
            yAxis: {
                type: 'value',
                name: 'Health(%)',
                axisLabel: {
                    fontSize: fs
                }
            },
            series: [{
                name: fieldValues?.business_term?.toUpperCase(),
                data: logsData?.map(d => `${d?.overall_health}`),
                type: 'line',
                smooth: true,
                // markPoint:{
                //     data: [{
                //       type: "max"
                //     }],
                //     symbol: "circle",
                // }
            }],
            tooltip: {
                trigger: 'axis',
                formatter: 'Health<br/>Date: {b}<br/>Percentage: {c}%',
                textStyle: {
                    fontStyle: "normal",
                    fontFamily: "InterSemibold",
                    fontSize: fs
                }
            },
        };        
    }, [logsData])

    return (
        <div className='h-100 w-100'>
            <div className='mb-2 position-relative '>
                <div className='dict-title-strip mb-2'>
                    <div className='d-flex align-items-center w-100 justify-content-between'>
                        <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                            Health Daily Trend
                        </label>
                        <div>
                            <Buttons
                                props={{
                                    tooltip: "",
                                    buttonWrapperClass: 'dict-health-ref-btn',
                                    tooltipPlacement: "left",
                                    buttonText: "Refresh",
                                    buttonClassName: `border custom-btn-outline custom-btn btn-with-text`,
                                    buttonEvent: () => reloadLogs(),
                                    ImgSrc: () => <ICON_REFRESH />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* <span className='bold-h-line'></span> */}
            </div>
            <div id='dictLogGraph' className='px-2 py-2' style={{ height: 'calc(100% - 3.125rem)', overflowX: 'hidden', overflowY: 'hidden' }}>
                {
                    loading
                        ? <ClaristaLoader />
                        : error ? getReloadErrorTemplate({ errorMessage: error, onReload: () => reload() })
                            :
                            <ReactECharts
                                opts={{renderer: 'canvas'}}
                                option={
                                    {
                                        ...options,
                                        // ...getChartsOption(chartData?.payload?.type?.toLowerCase() ?? 'multiline'),
                                        toolbox: {
                                            show: false,
                                            feature: {
                                            dataZoom: {
                                                yAxisIndex: "none"
                                            },
                                              magicType: {
                                                type: ["line", "bar"]
                                              },
                                            restore: { show: true },
                                            saveAsImage: {}
                                            },
                                        }
                                    }
                                }
                                theme={
                                    {
                                        color: [
                                            '#2078BA',
                                            '#C1DB36',
                                            '#542F99',
                                            '#E9B93C',
                                            '#7EE9F6',
                                            '#42BA72',
                                            '#114E7A',
                                            '#FFDB7E',
                                            '#CBE9F6',
                                            '#51545E'
                                        ]
                                    }
                                }
                                style={{height: `14.375rem`, width: '100%', margin: 0}}
                                className='pt-2'
                            />
                            // <Plot
                            //     config={{
                            //         displayModeBar: false,
                            //         responsive: true,
                            //     }}
                            //     data={[
                            //         {
                            //             x: logsData?.map(d => new Date(d?.start_date_time)?.toLocaleString()),
                            //             y: logsData?.map(d => `${d?.overall_health} `),

                            //             // fill: 'tozeroy',
                            //             // fillcolor: '#9DD3E8',
                            //             type: 'line',
                            //             mode: 'lines+markers',
                            //             // text: logsData?.map(d => `<br><b>Health</b>: ${d?.overall_health >= 0 ? d.overall_health + '%' : d?.overall_health}</br>
                            //             //  <br><b>Quality Count</b>: ${d?.quality_count}</br>
                            //             //  <br><b>Total Count</b>:${d?.total_count}
                            //             // `),
                            //             hovertemplate:logsData?.map(d => `<br><b>Health</b>: ${d?.overall_health  ? d.overall_health + '%' : d?.overall_health}</br>
                            //             <br><b>Quality Count</b>: ${d?.quality_count}</br>
                            //             <br><b>Total Count</b>:${d?.total_count}</br>
                            //            `),
                            //         }
                            //     ]}
                            //     layout={{
                            //         width: graphWidth ?? '100%',
                            //         height: 200,
                            //         margin: { l: 80, r: 80, b: 86, t: 10, pad: 5 },
                            //         xaxis: {
                            //             title: 'Execution Start Date',
                            //             // tickmode: "array", // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
                            //             tickfont: {
                            //                 size: 9,
                            //                 color: '#495057'
                            //             },
                            //             zeroline: true,
                            //         },
                            //         yaxis: {
                            //             title: "Health",
                            //             range: [0, 100],
                            //             // rangemode: "nonnegative",
                            //             tickfont: {
                            //                 size: 8,
                            //                 color: '#495057'
                            //             },
                            //         },
                            //         autoexpand: true,
                            //         autosize: true,
                            //     }}
                            // />
                }
            </div>
        </div>
    )
}

export default GraphView