import React, { useEffect, useState } from "react";
import Accordian from "../../../../Common/accordion/Accordian";
import { Row, Switch, Col } from "antd";
import {
  ACCORDIONDOWNARROW,
  ALERT,
  ANALYTICS,
  ANALYZE_MENU,
  CATALOG,
  CHART,
  CONNECTIONS,
  DASHBOARD,
  DATADICTIONARY,
  FLOWS,
  SCHEDULER,
  SMALLFLOWS,
  USAGE,
  ENTITLEMENTICON,
} from "../../../../Common/iconSource";
import MultiSelection from "../../../../Common/dropDown/MultiSelection";
import "../entitlement.scss";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useDispatch } from "react-redux";
import NoSearchResultFound from "../../../common/NoSearchResultFound";
import { NoDataComponent } from "../../../common/helperFunctions";
import { showSimpleLoading } from "../../../common/helperFunctions";
import { getPermissionData } from "../../../../../store/modules/entitlements/entitlementAction";

const MODULE_ICON_MAPPING = {
  "Data Catalog": <CATALOG />,
  "Data Dictionary": <DATADICTIONARY />,
  Visualization: <ANALYTICS />,
  Flows: <SMALLFLOWS />,
  Usage: <USAGE />,
  Chart: <CHART />,
  Dashboard: <DASHBOARD />,
  Scheduler: <SCHEDULER />,
  Analysis: <ANALYZE_MENU />,
  Connections: <CONNECTIONS />,
  Quality: <ALERT />,
};
const MODULES = {
  "Data Catalog": "pod_detail",
  "Data Dictionary": "data_dictionary",
  Quality: "rule",
  Flows: "flow",
  Chart: "chart",
  Dashboard: "dashboard",
};
const AccessRow = ({
  permissionState,
  roles,
  showLoadingMsg,
  setPermissionState,
  searchValue,
  selectedRole,
  empty,
}) => {
  const entitlementEditMode = useSelector((state) => state.Entitlement.isEdit);
  const dispatch = useDispatch();
  const loadingStatus = useSelector(
    (state) => state.LoadingReducer.loadingStatus
  );
  const [accordionList, setaccordionList] = useState([]);
  const [data, setData] = useState([
    {
      header: "Discover",
      children: [{ name: "Data Catalog" }, { name: "Data Dictionary" }],
    },
    {
      header: "Analyze",
      children: [
        {
          name: "Visualization",
          edit: "editVisualization",
          read: "viewVisualization",
          delete: "deleteVisualization",
          children: [{ name: "Chart" }, { name: "Dashboard" }],
        },
        { name: "Flows" },
      ],
    },
    {
      header: "Monitor",
      children: [
        { name: "Quality" },
        //{name:"Scheduler",edit:"editScheduler",read:"viewScheduler",delete:"deleteScheduler"}
      ],
    },
    // {
    //     header:"Admin",
    //     children:[{name:"Connections",edit:"editConnection",read:"viewConnection",delete:"deleteConnection"}]
    // },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      let temp = {};
      let tempData = [...data];
      // Object.keys(MODULES).forEach(async(item)=>{

      for (let item in MODULES) {
        await dispatch(getPermissionData(MODULES[item])).then((res) => {
          let checkData;
          if (item == "Data Catalog") checkData = tempData[0].children[0];
          if (item == "Data Dictionary") checkData = tempData[0].children[1];
          if (item == "Quality") checkData = tempData[2].children[0];
          if (item == "Flows") checkData = tempData[1].children[1];
          if (item == "Chart") checkData = tempData[1].children[0].children[0];
          if (item == "Dashboard")
            checkData = tempData[1].children[0].children[1];
          res.data.forEach((item) => {
            temp[item.codename] = { ...item, checked: false };
            if (item.codename.includes("view")) {
              checkData.read = item.codename;
            }
            if (item.codename.includes("change")) {
              checkData.edit = item.codename;
            }
            if (item.codename.includes("delete")) {
              checkData.delete = item.codename;
            }
            if (item.codename.includes("add")) {
              checkData.create = item.codename;
            }
            // })
          });
        });
      }
      setPermissionState({ ...temp });
      setData(tempData);
    };

    if (_.isEmpty(permissionState)) fetchData();
  }, []);
  const Element = ({ name, isChild, icon, permissionData }) => {
    return (
      <Row
        style={
          isChild
            ? {
                borderBottom: "1px solid lightgray",
                alignItems: "center",
                padding: "5px 10px",
              }
            : {
                borderBottom: "1px solid lightgray",
                alignItems: "center",
                padding: "10px",
              }
        }
      >
        {" "}
        <Col span={4} style={isChild ? { paddingLeft: "20px" } : {}}>
          {" "}
          <span
            className="mod_ic"
            style={{ padding: "8px 0px", textAlign: "center" }}
          >
            {MODULE_ICON_MAPPING[name]} {name}
          </span>{" "}
        </Col>{" "}
        {!isChild ? (
          <Col span={12}></Col>
        ) : (
          <>
            {" "}
            <Col style={{ textAlign: "center" }} span={3}>
              {" "}
              <Switch
                size="small"
                disabled={!entitlementEditMode}
                checked={permissionState[permissionData.create]?.checked}
                onChange={(e) => {
                  setPermissionState({
                    ...permissionState,
                    [permissionData.create]: {
                      ...permissionState[permissionData.create],
                      checked: e,
                    },
                  });
                }}
                className="entitlement"
              />{" "}
            </Col>{" "}
            <Col style={{ textAlign: "center" }} span={3}>
              {" "}
              <Switch
                size="small"
                disabled={!entitlementEditMode}
                checked={permissionState[permissionData.read]?.checked}
                onChange={(e) => {
                  setPermissionState({
                    ...permissionState,
                    [permissionData.read]: {
                      ...permissionState[permissionData.read],
                      checked: e,
                    },
                  });
                }}
                className="entitlement"
              />{" "}
            </Col>{" "}
            <Col style={{ textAlign: "center" }} span={3}>
              {" "}
              <Switch
                size="small"
                disabled={!entitlementEditMode}
                checked={permissionState[permissionData.edit]?.checked}
                onChange={(e) => {
                  if (e) {
                    setPermissionState({
                      ...permissionState,
                      [permissionData.edit]: {
                        ...permissionState[permissionData.edit],
                        checked: e,
                      },
                      [permissionData.read]: {
                        ...permissionState[permissionData.read],
                        checked: e,
                      },
                    });
                  } else {
                    setPermissionState({
                      ...permissionState,
                      [permissionData.edit]: {
                        ...permissionState[permissionData.edit],
                        checked: e,
                      },
                    });
                  }
                }}
                className="entitlement"
              />{" "}
            </Col>{" "}
            <Col style={{ textAlign: "center" }} span={3}>
              {" "}
              <Switch
                size="small"
                disabled={!entitlementEditMode}
                checked={permissionState[permissionData.delete]?.checked}
                onChange={(e) => {
                  setPermissionState({
                    ...permissionState,
                    [permissionData.delete]: {
                      ...permissionState[permissionData.delete],
                      checked: e,
                    },
                  });
                }}
                className="entitlement"
              />{" "}
            </Col>{" "}
          </>
        )}
        <Col style={{ textAlign: "center" }} span={6}>
          {" "}
          <MultiSelection
            props={{
              label: "",
              placeholder: "Select Options",
              className: "custom-select-dropdown",
              dropDownValue: [],
              onClickEvent: (value) => {},
              optionList: [],
              children: [],
              isDisable: !entitlementEditMode,
            }}
          />{" "}
        </Col>{" "}
      </Row>
    );
  };
  const NestedList = () => {
    let chartPermission = data[1].children[0].children[0];
    let dashboardPermission = data[1].children[0].children[1];
    return (
      <>
        {" "}
        <Element name="Visualization" />{" "}
        <Element name="Chart" permissionData={chartPermission} isChild={true} />{" "}
        <Element
          name="Dashboard"
          permissionData={dashboardPermission}
          isChild={true}
        />{" "}
      </>
    );
  };
  const List = (temp2) => {
    let rows = temp2.map((item, index) => {
      return (
        <>
          {" "}
          {item.name == "Visualization" ? (
            <NestedList />
          ) : (
            <Row
              style={
                index == temp2.length - 1
                  ? { alignItems: "center", padding: "10px" }
                  : {
                      borderBottom: "1px solid lightgray",
                      alignItems: "center",
                      padding: "10px",
                    }
              }
            >
              {" "}
              <Col span={4}>
                {" "}
                <span
                  className="mod_ic"
                  style={{ padding: "8px 0px", textAlign: "center" }}
                >
                  {MODULE_ICON_MAPPING[item.name]} {item.name}{" "}
                </span>{" "}
              </Col>{" "}
              <Col style={{ textAlign: "center" }} span={3}>
                {" "}
                <Switch
                  size="small"
                  disabled={!entitlementEditMode}
                  checked={permissionState[item.create]?.checked}
                  onChange={(e) => {
                    setPermissionState({
                      ...permissionState,
                      [item.create]: {
                        ...permissionState[item.create],
                        checked: e,
                      },
                    });
                  }}
                  className="entitlement"
                />{" "}
              </Col>{" "}
              <Col style={{ textAlign: "center" }} span={3}>
                {" "}
                <Switch
                  size="small"
                  disabled={!entitlementEditMode}
                  checked={permissionState[item.read]?.checked}
                  onChange={(e) => {
                    setPermissionState({
                      ...permissionState,
                      [item.read]: {
                        ...permissionState[item.read],
                        checked: e,
                      },
                    });
                  }}
                  className="entitlement"
                />{" "}
              </Col>{" "}
              <Col style={{ textAlign: "center" }} span={3}>
                {" "}
                <Switch
                  size="small"
                  disabled={!entitlementEditMode}
                  checked={permissionState[item.edit]?.checked}
                  onChange={(e) => {
                    if (e) {
                      setPermissionState({
                        ...permissionState,
                        [item.edit]: {
                          ...permissionState[item.edit],
                          checked: e,
                        },
                        [item.read]: {
                          ...permissionState[item.read],
                          checked: e,
                        },
                      });
                    } else {
                      setPermissionState({
                        ...permissionState,
                        [item.edit]: {
                          ...permissionState[item.edit],
                          checked: e,
                        },
                      });
                    }
                  }}
                  className="entitlement"
                />{" "}
              </Col>{" "}
              <Col style={{ textAlign: "center" }} span={3}>
                {" "}
                <Switch
                  size="small"
                  disabled={!entitlementEditMode}
                  checked={permissionState[item.delete]?.checked}
                  onChange={(e) => {
                    setPermissionState({
                      ...permissionState,
                      [item.delete]: {
                        ...permissionState[item.delete],
                        checked: e,
                      },
                    });
                  }}
                  className="entitlement"
                />{" "}
              </Col>{" "}
              <Col style={{ textAlign: "center" }} span={6}>
                {" "}
                <MultiSelection
                  props={{
                    label: "",
                    placeholder: "Select Options",
                    className: "custom-select-dropdown",
                    dropDownValue: [],
                    onClickEvent: (value) => {},
                    optionList: [],
                    children: [],
                    isDisable: !entitlementEditMode,
                  }}
                />{" "}
              </Col>{" "}
            </Row>
          )}
        </>
      );
    });
    return rows;
  };
  useEffect(() => {
    let temp = data.map((item, index) => {
      return {
        header: (
          <div>
            {" "}
            <p style={{ fontWeight: "500", margin: "0", paddingLeft: "10px" }}>
              {item.header}
            </p>{" "}
          </div>
        ),
        key: index,
        innerContent: () => List(item.children),
      };
    });
    setaccordionList(temp);
  }, [permissionState, entitlementEditMode, data]);
  return (
    <div style={{ overflowY: "auto" }} className="permission-div h-100 w-100">
      {" "}
      {roles && roles.length > 0 ? (
        <>
          <Row
            style={{ background: "white", borderBottom: "1px solid lightgray" }}
          >
            {" "}
            <Col span={4}>
              {" "}
              <div
                style={{
                  padding: "8px",
                  paddingLeft: "10px",
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                {" "}
                Module{" "}
              </div>{" "}
            </Col>{" "}
            <Col span={3}>
              {" "}
              <div
                style={{
                  padding: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                {" "}
                Create{" "}
              </div>{" "}
            </Col>{" "}
            <Col span={3}>
              {" "}
              <div
                style={{
                  padding: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                {" "}
                Read{" "}
              </div>{" "}
            </Col>{" "}
            <Col span={3}>
              {" "}
              <div
                style={{
                  padding: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                {" "}
                Edit{" "}
              </div>{" "}
            </Col>{" "}
            <Col span={3}>
              {" "}
              <div
                style={{
                  padding: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                {" "}
                Delete{" "}
              </div>{" "}
            </Col>{" "}
            <Col span={6}>
              {" "}
              <div
                style={{
                  padding: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                {" "}
                Options{" "}
              </div>{" "}
            </Col>{" "}
          </Row>{" "}
          <Accordian
            props={{
              isDisable: false,
              style: { height: "77.4vh" },
              accordionList: accordionList,
              Icon: () => <ACCORDIONDOWNARROW />,
              defaultExpandedPanel: ["0", "1", "2", "3"],
            }}
          />
        </>
      ) : empty ? (
        <NoDataComponent
          logo={<ENTITLEMENTICON width="100px" height="100px" color="black" />}
          message=" Click On (+) To Create Roles"
        />
      ) : !loadingStatus ? (
        searchValue !== "" && roles && !roles.length ? (
          <NoSearchResultFound
            mainMsg={searchValue === "" ? "No Roles List Found" : ""}
            subMsg={"Please try Search / Filter with another term"}
          />
        ) : showLoadingMsg ? (
          showSimpleLoading("Please Wait Loading Roles List...")
        ) : null
      ) : null}
    </div>
  );
};
export default AccessRow;
