import React from 'react'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import TalkDataQuestionHeader from './TalkDataQuestionHeader'
import PulseSearchBox from '../../../Common/mainLandingPage/components/PulseSearchBox'
import useHideSidebar from '../../../../customHooks/useHideSidebar'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorComponent, NoDataComponent, generateHtmlTitle, getDateToEPOC, getMonthName, getObjectLength, getQueryParams, getReloadErrorTemplate, showVerticalBarLoader, stringToColor } from '../../common/helperFunctions'
import TalkDataSuggestBar from './TalkDataSuggestBar'
import Conversation from './ChatContentBox'
import { ContentViewGridTable } from '../../../Common/contentGridTable/ContentViewGridTable'
import { useDispatch, useSelector } from 'react-redux'
import { getChatConversation, getChatData, getConversationChart, getConversationKPI, startChartConversation } from '../../../../store/modules/common/GenAi/GenAiReducer'
import { ICON_CHAT, ICON_CHAT_TAIL, ICON_CLARISTA, ICON_LOADING_CIRCLE, ICON_TWO_PART_CIRCLE_BOLD } from '../../../Common/newIconSource'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions'
import { v4 as uuidv4 } from "uuid";
import { Literals } from '../../common/literals'
import { Avatar } from 'antd'
import moment from 'moment'
import _, { isArray } from 'lodash'
import { SquareFill } from 'react-bootstrap-icons'

function getValidQueryParam(query_text, location_obj) {
    let qry = ''
    if(query_text) {
        if(location_obj?.hash !== "") {
            qry = query_text + location_obj?.hash
            return decodeURI(qry)
        }
        qry = decodeURI(query_text)
    }
    return qry
}

const TalkDataIndex = ({
    isFloating = false,
    dataDomainId,
    extraPayload = {},
    uuidProp
}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useNavigate()
    const {dm: domainId, chat: chatIdParam, uuid} = getQueryParams(location.search)
    const query = location?.state?.query ?? ''
    
    const [data, setData]                 = React.useState([])
    const [empty, setEmpty]               = React.useState(false);
    const [pageNo, setPageNo]             = React.useState(1)
    const [chatId, setChatId]             = React.useState(chatIdParam)
    const [loading, setLoading]           = React.useState(false)
    const [errMsg, setErrorMsg]           = React.useState("");
    const [chatData, setChatData]         = React.useState({})
    const [loadMore, setLoadMore]         = React.useState(false)
    const [convError, setConvError]       = React.useState(undefined)
    const [usersList, setUsersList]       = React.useState([])
    const [questValue, setQuestValue]     = React.useState(query)
    const [searchFocus, setSearchFocus]   = React.useState(false)
    const [stopLazyload, setStopLazyload] = React.useState(false)
    const [activeDomain, setActiveDomain] = React.useState(isFloating ? dataDomainId : domainId ?? '')
    const [usersLoading, setUsersLoading] = React.useState(false)
    const [fetchingConv, setFetchingConv] = React.useState(false)
    const [chatError, setChatError]       = React.useState("")
    const [replyTimer, setReplyTimer]     = React.useState(0)
    const [queryChoices, setQueryChoices] = React.useState([])

    const [apiController, setApiController] = React.useState(null)
    const [isReady, setIsReady] = React.useState(true)


    const uuidRef = React.useRef(isFloating ? uuidProp : uuid)
    const chartRefMap = React.useRef({})
    const kpiRefMap = React.useRef({})
    const dataRefMap = React.useRef({})
    const chatScrollRef = React.useRef({})
    const replyTimerRef = React.useRef({})
    const chatWindowRef = React.useRef({})
    const searchInputRef = React.useRef({})
    const headerRef = React.useRef({})

    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    useHideSidebar(true)

    React.useEffect(() => {
        if(chatId) fetchChatData()
        document.querySelector('#pulseTalkSearchInp').innerText = query
    }, [])

    React.useEffect(() => {
        if(dataDomainId) {
            setIsReady(false)
            setChatId(undefined)
            setErrorMsg("")
            setChatError("")
            setEmpty(false)
            setChatData({})
            setLoadMore(false)
            setLoading(false)
            setActiveDomain(dataDomainId)
            setQueryChoices([])
            
            if(apiController?.abort) {
                apiController?.abort()
                setTimeout(() => setData([]), 1000)
            }
            else {
                setData([])
            }

            setTimeout(() => setIsReady(true), 1000)
        }
    }, [dataDomainId])

    React.useEffect(() => {
        
        if(isFloating) {
            uuidRef.current = uuidProp
        }
        
    }, [uuidProp, isFloating])

    React.useEffect(() => {
        if (pageNo !== 1) {
            setPageNo(1)
        }
        if(!isFloating) {
            getUsersList()
        }
        if(chatId && chatId !== '') {
            if(!isFloating) {
                setLoading(true)
                fetchConversations(true)
            }
        }
        else {
            startConversation()
        }
    }, [activeDomain])

    let seconds = 0, milliseconds = 0;
    let timeUpdate;

    const updateTime = (prev_seconds, prev_milliseconds) => {
        var startTime = new Date();    // fetch current time
        
        timeUpdate = setInterval(function () {
            var timeElapsed = new Date().getTime() - startTime.getTime(); 
            
            
            // calculate seconds
            seconds = parseInt(timeElapsed / 1000) + prev_seconds;
            // if (seconds > 60) seconds %= 60;
            
            // calculate milliseconds 
            milliseconds = timeElapsed + prev_milliseconds;
            if (milliseconds > 1000) milliseconds %= 1000;
            
            // set the stopwatch
            setStopwatch(seconds, milliseconds);
            
        }, 25); // update time in stopwatch after every 25ms
        
    }

    const setStopwatch = (seconds, milliseconds) => {
        if(document.querySelector("#rep-sec")) {
            document.querySelector("#rep-sec").innerHTML = prependZero(seconds, 1);
        }
        if(document.querySelector("#rep-mil")) {
            document.querySelector("#rep-mil").innerHTML = prependZero(milliseconds, 3);
        }
    }

    function prependZero(time, length) {
        time = new String(time);    // stringify time
        return new Array(Math.max(length - time.length + 1, 0)).join("0") + time;
    }

    const startConversation = async(retryQuest = undefined) => {
        let quest = retryQuest ?? questValue
        if(quest?.trim() === '') return
        
        if(!activeDomain || activeDomain === '') {
            if(!isFloating) {
                history(Literals.links.PULSE_LANDING + '#chats')
                return
            }
        }

        // setReplyTimer(0)
        if(!uuidRef?.current || uuidRef?.current === '') {
            uuidRef.current = uuidv4()
        }
        let payload = {
            uuid: uuidRef.current,
            data_domain: activeDomain,
            question: quest
        }

        if(isFloating) {
            payload = {...payload, ...extraPayload}
        }

        if(chatId) payload = {...payload, chat: chatId }
        setErrorMsg(undefined)
        setConvError(undefined)
        setFetchingConv(true)
        setEmpty(false)

        setTimeout(() => {
            chatScrollRef?.current?.scrollTo(0, chatScrollRef?.current?.scrollHeight)
        }, 300)

        clearInterval(timeUpdate);

        const updTime = setTimeout(() => {
            updateTime(0,0);
        }, 500)

        if(retryQuest) {
            setData(prev => {
                prev = prev?.filter(v => v?.chat_failed === undefined || !v?.chat_failed)
                return prev
            })
        }



        // const replyTimerInterval = setInterval(() => {
        //     setReplyTimer(prev => prev + 1)
        // }, 1000)

        document.querySelector('#pulseTalkSearchInp').innerText = ' '

        const previousQueryChoices = queryChoices ?? []
        setQueryChoices([])

        const controller = new AbortController();
        setApiController(controller)

        dispatch(startChartConversation(payload, true, controller))
        .then(res => {
            const dat = res?.data
            const chat_id = dat?.chat_detail?.id
            if(!isFloating) {
                if(window.location.pathname === Literals.links.PULSE_TALK) {
                    if(chatId === undefined)
                        window.history.pushState(null, null, window?.location?.search + '&chat=' + chat_id)
                    if(uuid === undefined)
                        window.history.pushState(null, null, window?.location?.search + '&uuid=' + uuidRef.current)
                }
            }
            
            dataRefMap.current[dat?.id] = dat
            chartRefMap.current[dat?.id] = {
                ...dat?.chart_payload,
                type: dat?.selected_chart,
                possible_chart: dat?.possible_chart
            }
            
            setData(prev => {
                prev = [...prev, dat]
                prev = _.uniqBy(prev, 'id')
                return prev
            })

            setQueryChoices(dat?.response_choices ?? [])

            setTimeout(() => {
                chatScrollRef?.current?.scrollTo(0, chatScrollRef?.current?.scrollHeight)
            }, 300)

            setChatId(chat_id)
            setChatData(dat)
            setFetchingConv(false)

            clearInterval(timeUpdate)
            clearTimeout(updTime)
            // setReplyTimer(0)
            // clearInterval(replyTimerInterval)

            /**
             * Delete this below comment after testing
             */
            // setPageNo(1)
            // setLoading(true)
            // fetchConversations(true, chat_id)


            setQuestValue("")
            document.querySelector('#pulseTalkSearchInp').innerText = ''

            if(searchInputRef?.current?.focus) {
                setTimeout(() => {
                    searchInputRef?.current?.focus()
                }, 500)
            }
        })
        .catch(err => {
            setData(prev => {
                prev = [...prev, {question: quest, chat_failed: true, canceled: err?.code === "ERR_CANCELED", created_on: new Date()}]
                prev = _.uniqBy(prev, 'id')
                return prev
            })
            setQueryChoices(previousQueryChoices)

            setQuestValue("")

            setConvError(err?.message)
            setFetchingConv(false)
            clearTimeout(updTime)
            clearInterval(timeUpdate)
            setApiController(null)

            document.querySelector('#pulseTalkSearchInp').innerText = ''

            if(searchInputRef?.current?.focus) {
                setTimeout(() => {
                    searchInputRef?.current?.focus()
                }, 500)
            }

            // setReplyTimer(0)
            /**
             * Delete this below comment after testing
             */
            // setPageNo(1)
            // setLoading(true)
            // fetchConversations(true, chatId)
        })
    }

    const fetchChatData = () => {
        dispatch(getChatData(chatId, false))
        .then(res => {
            setChatData({chat_detail: res?.data})
            if(res?.data && res?.data?.name) {
                let title = generateHtmlTitle(res?.data?.name + ' | TALKdata', 'Pulse')
                document.title = title
            }
        })
        .catch(err => {
            let title = generateHtmlTitle('TALKdata', 'Pulse')
            document.title = title
            setChatError(err?.message)
            console.error({err})

            if(err?.status?.toString() === '404') {
                history('/page404')
            }
        })
    }
    // console.log({chartRefMap})
    const fetchConversations = (reset = false, chat_id = undefined) => {
        if(!activeDomain || activeDomain === '' || chatError?.length > 0) {
            setLoading(false)
            return
        }

        if(isFloating && uuidProp !== uuidRef.current) {
            setLoadMore(false)
            setLoading(false)
            return
        }
        // if(chatId === undefined && chat_id === undefined) {
        //     setLoading(false)
        //     return
        // }

        setStopLazyload(false)
        setErrorMsg(undefined)

        const pageNumber = reset ? 1 : pageNo
        const pageLimit  = 5

        dispatch(getChatConversation(
                uuidRef.current ?? '', 
                chat_id ?? chatId ?? null, 
                pageNumber, 
                pageLimit, 
                data?.length > 0
            )
        )
        .then(res => {
            const dat = res?.data

            if(dat) {
                if(dat?.results) {
                    let arr = []
                    setData(prev => {
                        if(reset) {
                            arr =  [...dat?.results]
                            return arr?.reverse()
                        }
                        arr = [...prev?.reverse(), ...dat?.results]
                        arr = _.uniqBy(arr, 'id')
                        return arr?.reverse()
                    })
                    if(chatId === undefined) {
                        if(window.location.pathname === Literals.links.PULSE_TALK) {
                            const new_chat_id = dat?.results?.[0]?.chat_detail?.id
                            if(!isFloating) {
                                window.history.pushState(null, null, window?.location?.search + '&chat=' + new_chat_id + '&uuid=' + dat?.results?.[0]?.uuid)
                            }
                            setChatId(new_chat_id)
                        }
                    }
                    
                    setTimeout(() => {
                        if(reset) {
                            chatScrollRef?.current?.scrollTo(0, chatScrollRef?.current?.scrollHeight)
                        }
                        else if(document?.querySelector(`#conversation-${dat?.results?.[0]?.id}-ts`)) {
                            document?.querySelector(`#conversation-${dat?.results?.[0]?.id}-ts`)?.scrollIntoView({behavior: 'instant'})
                        }
                    }, 800)
                }
    
                if (dat?.has_next === false) {
                    setStopLazyload(true)
                    setLoadMore(false)
                }
            }

            if (res?.status === "success") {
                setLoadMore(false)
                setPageNo(prev => prev + 1)
            }

            setEmpty(dat?.results?.length === 0)
            setLoading(false)
        })
        .catch(err => {
            console.error({err})
            setErrorMsg(err?.message)
            setEmpty(false)
            setLoading(false)
            setLoadMore(false)
        })
    }

    const getUsersList = () => {
        setUsersLoading(true)
        dispatch(getUserGroupList({ method: "GET", endPoint: "profile?data_domain_id=" + domainId, payload: undefined, showLoader: false }))
        .then(res => {
            let list = res?.data?.map(ele => ({ name: `${ele?.first_name} ${ele?.last_name}`, value: ele?.id, email: ele?.email, data: {...ele} }))
            setUsersList([...list]);
            setUsersLoading(false)
        })
        .catch(err => {
            setUsersLoading(false)
            console.error(err)
        })
    };

    const onSuggestBarClick = (quest) => {
        setQuestValue(quest)
        document.querySelector('#pulseTalkSearchInp').innerText = quest
    }

    const conversationArray = React.useMemo(() => {

        const getReadableData = (dat) => {
            let date = new Date(dat)
            return `${date?.getDate()} ${getMonthName.short[date.getMonth()]} ${date?.getFullYear()}`
        }

        let obj = data?.reduce((prev, curr) => ({
            ...prev, 
            [getDateToEPOC(curr?.created_on)]: [...data?.filter(d => getReadableData(d?.created_on) === getReadableData(curr?.created_on))]
        }), {})

        return Object.keys(obj)?.map((c, idx) => (
            <React.Fragment key={`conv-fragment-${idx}`}>
                <div className='chat-date-strip fontInterSemiBold fontSizeLabel mx-auto my-4 py-2 text-center'>
                    <span>
                        {   
                            moment(c, "YYYY-MM-DD").calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: '[Last] dddd',
                                sameElse: `DD MMM YYYY`
                            })
                        }
                    </span>
                </div>
                {
                    obj[c]?.map((con, i) => (
                        <Conversation 
                            key={`conv-${i}`} 
                            index={i} 
                            data={con}
                            reload={() => {
                                setPageNo(1)
                                setLoadMore(true)
                                setLoading(true)
                                fetchConversations(true)
                            }}
                            usersList={usersList}
                            chartRefMap={chartRefMap.current}
                            kpiRefMap={kpiRefMap.current}
                            dataRefMap={dataRefMap.current}
                            deleteFullChat={data?.length === 1}
                            fetchingConv={fetchingConv}
                            retryQuery={(qry) => {
                                updateTime(0,0);
                                clearInterval(timeUpdate)
                                startConversation(qry)
                            }}
                            setQuestValue={setQuestValue}
                            chatWindowRef={chatWindowRef}
                            setData={setData}
                            isFloating={isFloating}
                            refreshNotification={() => {
                                headerRef?.current?.refreshNotification()
                            }}
                            setQueryChoices={setQueryChoices}
                        />
                    ))
                }
            </React.Fragment>
        ))
    }, [data, usersList, fetchingConv])

    // const onScrollToBottom = (paginate) => {
        // if (!stopLazyload && !fetchingConv) {
        //     if (paginate && !loadMore) {
        //         setLoadMore(true)
        //         fetchConversations()
        //     }
        // }
    // }

    const onScrollToTop = (paginate) => {
        if (!stopLazyload && !fetchingConv) {
            if (paginate && !loadMore) {
                setLoadMore(true)
                fetchConversations()
            }
        }
    }

    const displayUserName = () => {
        let name = userDetailsRedux?.first_name
        if(userDetailsRedux?.last_name)
        name += " " + userDetailsRedux?.last_name
        return name ?? 'Unknown User'
    }

    return (
        <div className={`talk-index-main talkData-wrapper ${isFloating ? 'floating-talkdata-main-wrapper' : 'normal-talkdata-main-wrapper'}`}>
            {
                isFloating ? ''
                :
                <LandingpageFirstHeader title={'TALKdata'} isPulse={true} ref={headerRef} />
            }
            <div className='pulse-talk-wrap'>
                <TalkDataQuestionHeader
                    activeDomain={activeDomain}
                    setActiveDomain={setActiveDomain}
                    setSearchFocus={setSearchFocus}
                    usersList={usersList}
                    usersLoading={usersLoading}
                    questValue={questValue}
                    setQuestValue={setQuestValue}
                    chatData={chatData}
                    query={
                        (() => {
                            if(!query || query?.trim() === '') 
                            return "Let's talk, ask me anything about data"
                            else return query
                        })()
                    }
                    onSend={() => {
                        setSearchFocus(false)
                        startConversation()
                    }}
                    fetchingConv={fetchingConv}
                    convError={convError}
                    chatError={chatError}
                    ref={searchInputRef}
                />
                {/* <div className='p-1'></div>
                <PulseSearchBox
                    activeDomain={activeDomain}
                    setActiveDomain={setActiveDomain}
                /> */}
                {
                    // searchFocus && 
                    // chatData?.chat_detail?.top_question
                    // ?


                    /**
                     * Disabling Question Suggestion
                     */
                    true ? ''
                    :
                    <TalkDataSuggestBar
                        data={chatData?.chat_detail?.top_question ?? []}
                        onClick={onSuggestBarClick}
                        visibility={searchFocus}
                    /> 

                    // : ''
                }
                {/* {
                    fetchingConv
                    ?
                    <div className='p-5 my-2'>
                        {showVerticalBarLoader()}
                    </div>
                    :   ''
                } */}

                <div ref={chatWindowRef} className={`talk-conv-wrap mx-auto ${loadMore ? 'loading-more-chat' : ''}`}>
                    {
                        
                        loadMore
                        ?   <div className='py-2 d-flex align-items-center justify-content-center color-primary fontSizeHeading load-more-chat-box'>
                                <span className='mr-2'>
                                    <ICON_LOADING_CIRCLE height='14' width='14' color='currentColor' />
                                </span>
                                <span>
                                    Loading more..
                                </span>
                            </div>
                        :   ''
                    }
                    {
                        // convError
                        // ?   <ErrorComponent msg={convError}/>
                        // :   

                        loading || !isReady
                        ?   <ClaristaLoader height='200px' />
                        :

                        chatError?.length > 0
                        ?   getReloadErrorTemplate({ 
                                errorMessage: chatError,
                                hideReloadBtn: true
                            })
                        :
            
                        errMsg?.length > 0 && data?.length === 0 
                        ?   getReloadErrorTemplate({ 
                                errorMessage: errMsg, 
                                onReload: () => {
                                    setPageNo(1)
                                    setLoading(true)
                                    fetchConversations(true)
                                } 
                            })
                        :
            
                        empty && chatError?.length === 0
                        ?   <NoDataComponent 
                                logo={<ICON_CHAT width="60" height="60" />}
                                message="Start asking me questions" />
                        :

                        <div
                            ref={chatScrollRef}
                            className={`talkdata-conv-array-wrap ${isFloating && data?.length === 0 && !fetchingConv ? 'mb-auto' : ''}`}
                            onScroll={({currentTarget}) => {
                                onScrollToTop(currentTarget?.scrollTop === 0)
                            }}
                        >
                            {
                                isFloating && data?.length === 0 && !fetchingConv
                                ?
                                <section className='px-3 w-75 mx-auto d-flex flex-column h-100 justify-content-center'>
                                    <div>
                                        <h4 className='cpilot-head-lg fontInterSemiBold text-capitalize'>
                                            Hello {displayUserName()}!
                                        </h4>
                                        <h4 className='cpilot-head-md fontInterSemiBold'>
                                            {
                                                extraPayload?.dashboard_id
                                                ?
                                                "I can help you find information about the data on your dashboard."
                                                :
                                                "I'm here to help you with analyzing your pod data!"
                                            }
                                        </h4>
                                    </div>
                                    <div className='p-2'></div>
                                    <div className='pr-5 mb-2'>
                                        <div className='cpilot-simple-msg-card' style={{cursor: 'default'}}>
                                            <div className='align-items-center d-flex fontSizeHeading fontInterSemiBold mb-1'>
                                                {
                                                    extraPayload?.dashboard_id
                                                    ?
                                                    "Want to know more about your data?"
                                                    :
                                                    "Ready to unlock your pod's secrets?"
                                                }
                                            </div>
                                            <p className='mb-0 fontSizeLabel text-muted'>
                                                {
                                                    extraPayload?.dashboard_id
                                                    ?
                                                    "What do you want to know? Just ask! I can answer questions about any data on your dashboard"
                                                    :
                                                    "What do you want to analyze? Ask me any question about your pod data!"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </section>
                                :
                                isFloating && data?.length === 0 && fetchingConv ? ''
                                :
                                <div className='mb-0' style={data?.length === 0 ? {height: 'calc(100% - 204px'} : {}}></div>
                            }
                            {conversationArray}
                            {
                                !fetchingConv && data?.length !== 0 && queryChoices && queryChoices?.length > 0
                                ?
                                <div className='question-suggestion-stack'>
                                    {
                                        queryChoices && isArray(queryChoices) ? queryChoices?.map((question, i) => (
                                            <div 
                                                key={`suggestedQuest-${i}`}
                                                onClick={() => {
                                                    setQuestValue(question)
                                                    startConversation(question)
                                                }}
                                            >
                                                {question}
                                            </div>
                                        ))
                                        :   ''
                                    }
                                    {/* <div>I want to change my computer background</div>
                                    <div>How do I change the wallpaper on my phone?</div>
                                    <div>No, thanks. I'm good</div> */}
                                </div>
                                :   ''
                            }
                            {
                                fetchingConv
                                ?
                                <div className={`chat-usr-sender-box user-query position-relative mt-5 mb-3 mx-auto`}>
                                    <div className='chat-user-ask'>
                                        <span className={`chat-tail right`}>
                                            <ICON_CHAT_TAIL/>
                                        </span>
                                        {questValue}
                                    </div>
                
                                    <span className='chat-user-sender-avt'>
                                        <Avatar
                                            size={'default'}
                                            className='text-uppercase'
                                            style={{
                                                backgroundColor: stringToColor(`${userDetailsRedux?.first_name} ${userDetailsRedux?.last_name}`),
                                            }}
                                        >
                                            {userDetailsRedux?.first_name?.charAt(0)}
                                            {userDetailsRedux?.last_name?.charAt(0)}
                                        </Avatar>
                                    </span>
                                </div>
                                :   ''
                            }
                            {
                                fetchingConv
                                ?   <div className='replying-chat-box'>
                                        <span className='replying-avt'>
                                            <ICON_CLARISTA/>
                                        </span>

                                        <div className='reply-wait-box'>
                                            <ICON_TWO_PART_CIRCLE_BOLD className='bx-spin' color='currentColor' />
                                            Analyzing
                                        </div>

                                        {/* <span className='replying-avt-chatbox'>
                                            <span className={`chat-tail left`}>
                                                <ICON_CHAT_TAIL/>
                                            </span>
                                            <div style={{transform: 'scale(.7)', margin: '0 auto'}}>
                                                <div className='dot-typing'></div>
                                            </div>
                                        </span> */}
                                        {/* <span 
                                            ref={replyTimerRef}
                                            className='waiting-reply-tim fontInterSemiBold ml-2'>
                                                <span id='rep-sec'>00</span>.
                                                <span id='rep-mil' className='mr-1' style={{width: 10, overflow: 'hidden'}}>000</span> Sec
                                        </span> */}
                                        {/* <button
                                            className='cancel-chat-btn'
                                            onClick={() => {
                                                apiController.abort()
                                            }}>
                                            <SquareFill color='currentColor' />
                                        </button> */}
                                    </div>
                                :   ''
                            }
                            {/* <div style={{height: fetchingConv ? 0 : 30, marginBottom: 0}}></div> */}
                        </div>
                    }
                </div>
                {/* <Conversation/> */}
            </div>
        </div>
    )
}

export default TalkDataIndex