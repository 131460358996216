
import React, { useEffect, useRef, useState } from 'react'
import useDebounce from '../../../customHooks/useDebounce';
import { Literals } from '../../modules/common/literals';

import { CLOSE, SEARCH } from '../iconSource';

const SearchInput = React.forwardRef(({
    useDelay = true, 
    searchData, 
    setSearchData = () => {}, 
    isDisable = false, 
    searchBoxOpen = false, 
    placeholder = Literals.PLACE_HOLDER,
    autoFocus = true,
    onClearCallback = () => {},
    hideLoader = false,
    useButtonClick = false,
    onEnterCallBack = () => {},
    showSearchClose=true,
}, ref) => {
    const [searchOpen, setSearchOpen] = useState(true);
    const [focused, setFocused] = useState(false)
    const [value, setValue] = useState('');
    const [isReady, setIsReady] = useState(true)
    const debouncedTerm = useDebounce(value, 500);
    const counter = useRef(0)

    const inputRef = React.useRef({})

    React.useImperativeHandle(ref, () => {
        return {
            focus: () => {
                inputRef?.current?.focus()
                setFocused(true)
            },
            search: () => {
                setSearchData(value)
            },
            clear: () => {
                setValue(''); 
                setSearchData('')
                if(onClearCallback) onClearCallback()
            },
            getSearchValue: () => {
                return value
            }
        }
    }, [value])

    useEffect(() => {
        if (searchData === "" || searchData === '') {
            setValue('')
        }
    }, [searchData])


    useEffect(() => {
        if (focused && useDelay) {
            
            if (counter.current !== 0) setIsReady(false)

            setSearchData(debouncedTerm)

            if (counter.current !== 0) {
                setTimeout(() => { setIsReady(true) }, 100)
            }
            counter.current = counter.current + 1
            inputRef?.current?.focus()
        }
    }, [debouncedTerm, focused, useDelay])

    const callClose = () => {
        setSearchOpen(!searchOpen);
    };
    return (
        <div className='custom-search-width'>
            {(searchOpen ? (
                <div className="custom-search">
                    <input
                        ref={inputRef}
                        type="text"
                        onChange={(e) => {
                            if(useButtonClick) {
                                setValue(e.target.value)
                                return
                            }

                            if (!useDelay) setSearchData(e.target.value)

                            setValue(e.target.value)
                        }}
                        // onChange={(e) => { setSearchData(e.target.value);setsearchValue(e.target.value) }}
                        style={{ marginBottom: '2px' }}
                        value={useDelay || useButtonClick ? value : searchData}
                        // onChange={(e) => { dispatch(setSearchData(e.target.value)) }}
                        className="custom-search-input"
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        onFocus={() => setFocused(true)}
                        disabled={isDisable}
                        onClick={(e) => {
                            e?.stopPropagation()
                        }}
                        onKeyDown={(e) => {
                            e?.stopPropagation()
                            if(e?.key?.toLowerCase() === "enter" && useButtonClick) {
                                setSearchData(e?.target?.value)
                                onEnterCallBack(e?.target?.value)
                            }
                        }}
                    />
                    {/* <img
                        src={SearchIcon}
                        alt="SearchIcon"
                        
                    /> */}
                    <div className="custom-search-icon">
                        <SEARCH />
                    </div>
                    {
                        !isReady
                            ? 
                                hideLoader ? ''
                                :
                                <div className='custom-close-icon'>
                                    <i className='bx bx-loader-alt bx-spin'></i>
                                </div>
                            : value !== '' && showSearchClose
                                ? <div className="custom-close-icon" 
                                    onClick={(e) => { 
                                        e.stopPropagation()
                                        setValue(''); 
                                        setSearchData('')
                                        if(onClearCallback) onClearCallback()
                                    }} >
                                    {/* {searchData !== '' ? <div className="custom-close-icon" onClick={() => { setSearchData('') }} > */}
                                    <CLOSE />
                                </div>
                                : null
                    }
                   
                </div>
            ) : (
                null
              
            ))}
        </div>
    )
})

export default SearchInput