import React, { useEffect, useRef, useState } from 'react'
import { WithRouterHOC } from '../../../../services/WithRouterHOC';

import DataTabs from '../../../Common/dataTabs/DataTabs';
import { BELLALERT, FLOWS, SCHEDULER } from '../../../Common/iconSource';
import { AlertJobs } from './AlertJobs';
import { Jobs } from './Jobs';
import { SchedulerJobs } from './SchedulerJobs';

function JobsIndex(props) {
    const [viewTabsActiveKey, setviewTabsActiveKey] = useState("flows");
    const executionId = useRef('')
    const flowsexecutionId = useRef('')
    useEffect(() => {
        if (props?.location?.hash !== "") {
            let execeptionId = props.router.location.hash?.split('=')[1]

            let hashParam = props.router.location.hash?.split('=')[0]?.replace('#', '')
            if (hashParam === 'flows' && execeptionId) {
                flowsexecutionId.current = execeptionId
            } else if (hashParam === 'alert') {
                if (execeptionId) {
                    executionId.current = execeptionId

                }
            }
            if(hashParam!=="")
                setviewTabsActiveKey(hashParam)
        }
      
    }, [props])
    let JobsTabArray = [

        {
            key: "flows",
            tabTitle: (
                <div className="d-flex align-items-center">
                    <span className="tabs-icon margin-right-2">
                        <span className='primary-svg-color'><FLOWS width={"20"} height={"20"} /></span>
                    </span>
                    <span className="tabs-title">{"Flows"}</span>
                </div>
            ),
            tabContent: viewTabsActiveKey === 'flows' ? () => <Jobs showFilters={true} activeKey={viewTabsActiveKey} flowsexecutionId={flowsexecutionId.current} /> : () => <></>,
            closable: false,
        },
        // {
        //     key: "alert",
        //     tabTitle: (
        //         <div className="d-flex align-items-center">
        //             <span className="tabs-icon margin-right-2">
        //                 <BELLALERT />
        //             </span>
        //             <span className="tabs-title">{"Alert"}</span>
        //         </div>
        //     ),
        //     tabContent: viewTabsActiveKey === 'alert' ? () => <AlertJobs showFilters={true} activeKey={viewTabsActiveKey} executionId={executionId.current} /> : () => <></>,
        //     closable: false,
        // },
        {
            key: "scheduler",
            tabTitle: (
                <div className="d-flex align-items-center">
                    <span className="tabs-icon margin-right-2">
                        <SCHEDULER />
                    </span>
                    <span className="tabs-title">{"Scheduler"}</span>
                </div>
            ),
            tabContent: viewTabsActiveKey === 'scheduler' ? () => <SchedulerJobs showFilters={true} activeKey={viewTabsActiveKey} executionId={''} /> : () => <></>,
            closable: false,
        }
    ];

    const onViewTabChange = (activeKey) => {

        setviewTabsActiveKey(activeKey);

    };

    return (
        <div className="custom-tab-with-text jobs-flow-alert">
            <DataTabs
                props={{
                    activeKey: viewTabsActiveKey,
                    tabArray: JobsTabArray,
                    showTooltip: false,
                    onChange: (key) => {
                        onViewTabChange(key);
                    },
                    onEdit: () => { },
                }}
            />
        </div>
    )
}

export default WithRouterHOC(JobsIndex)