import React from "react";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "reactstrap";

const CreateDataDomainModal = ({ heading, openDomainModal, setOpenDomainModal, edit }) => {
    const toggle = () => setOpenDomainModal(false);

    return (
        <Modal size="md" isOpen={openDomainModal} className="create-domain-modal" centered={true}>
            <ModalHeader toggle={toggle}>{heading}</ModalHeader>
            <ModalBody >
                <div className="form-group">
                    <label>Name of Data Domain</label>
                    <input disabled={edit} className="form-control" />
                    <p className="note">Special characters and upper case not allowed and data domain cannot be renamed later</p>
                </div>
                <div className="form-group mb-0">
                    <label>Description</label>
                    <textarea className="form-control" />
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-default" onClick={() => setOpenDomainModal(false)}>Cancel</button>
                <button className="btn btn-primary">{edit ? "Save" : "Create"}</button>
            </ModalFooter>
        </Modal>
    )
}

export default CreateDataDomainModal;