import React, { useEffect, useState } from 'react';
import LandingPageTable from '../../../../Common/landingPageTable/LandingPageTable';
import { getColumnDataType } from '../../../common/helperFunctions';



const TargetColumnSchema = ({ columnList,setColumnList,tableHeight }) => {
    const [data, setdata] = useState([])
    let list = [];
    const setColumnValue = (e, i) => {
        let list=[...columnList]
        list[i].technical=e.target.value;
        setColumnList([...list])

    }

    useEffect(() => {
        if (columnList && columnList.length) {
            if (columnList !== undefined) {
                for (let i = 0; i < columnList.length; i++) {
                    list.push(
                        {
                            key: i,
                            column_name: <div className='custom-input-field' >{columnList[i].Name}</div>,
                            type: <div className='d-flex align-items-center'>{getColumnDataType(columnList[i].Type)}<span className='pl-1'>{columnList[i].Type}</span> </div>

                        }
                    )
                }
            }
        }

        setdata([...list])
    }, [columnList])



    const columns = [
        {
            title: 'Column Name',
            dataIndex: "column_name",
            key: "column_name",
         
            width: "20%",
            className: "single-line-ellipsis"
        },
        {
            title: 'Type',
            dataIndex: "type",
            key: "type",
            className: "type single-line-ellipsis",
           
            render: text => <div>
                {text}
            </div>,
            width: "20%"
        },

    ];



    return (

        <div className="analytics-tab-table">
            <div className="analytics-dash-table">

                <LandingPageTable
                    props={{
                        columns: columns, dataSource: data, tableHeight:tableHeight,
                        onRowSelection: () => { }
                    }}
                />


            </div>
        </div>
    )

}

export default TargetColumnSchema;