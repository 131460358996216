import { Checkbox, Popover, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { MultipleColsDndList } from './MultipleColsDndList'

import { useRef } from 'react'
import { dataTypeList, pxToRem, remToPx } from '../../../../common/helperFunctions'
import SingleSelection from '../../../../../Common/dropDown/SingleSelection'
import { GUIDEICON, SIXDOTS } from '../../../../../Common/iconSource'
import FormatColHelplinkTable from '../../../../dataCatalogue/createPOD2/components/FormatColHelplinkTable'
import { ICON_GUIDE } from '../../../../../Common/newIconSource'

const DndSchemaList = ({ actualData, isExecutePermission = true, reOrderRender = true, setshowCloseWarning, setreOrderRender, stopDraggingRow = false, setNewColumnList = () => { }, Selectall = () => { } }) => {

    const [dataTypes, setDataTypes] = useState([...dataTypeList])
    const [openformatHelpModal, setopenformatHelpModal] = useState(false);

    const [businessTerms, setBusinessTerms] = useState(
        [
            {
                Name: "Sec_ID",
                value: "sec_id",
            },
            {
                Name: "Issuer_Name",
                value: "issuer_name",
            },
            {
                Name: "Ticker",
                value: "ticker",
            },
        ]
    )

    const [demo, setDemo] = useState([])
    const [columns, setColumns] = useState([])

    const [colsList, setColsList] = useState([
        { key: 'checked', title: '', resizable: false, isRequired: false, disabled: true, visible: true, width: 5, frozen: true },
        { key: 'old_name', title: <div className='text-dark'>Column Name</div>, resizable: false, isRequired: true, disabled: true, visible: true, width: 90 },
        { key: 'new_name', title: <div className='text-dark'>Rename</div>, resizable: false, isRequired: true, disabled: window.location.href.includes("viewFlows") ? true : false, visible: true, width: 190 },
        { key: 'new_datatype', title: <div className='text-dark'>Data Type</div>, resizable: false, isRequired: true, disabled: window.location.href.includes("viewFlows") ? true : false, visible: true, width: 190 },
        {
            key: 'format', title:
                <div className='text-dark'>Format
                    <span onClick={() => setopenformatHelpModal(true)} className="m-1 text-black-50 cursor-pointer ">  <ICON_GUIDE height='10px' color='#2c83c4' width='10px'></ICON_GUIDE></span>
                </div>,

            resizable: false, isRequired: false, visible: true, disabled: false, width: 80
        },
    ])


    function Validate(e) {


        let keyCode = e.key

        //Regex to allow only Alphabets Numbers Dash Underscore and Space
        let pattern = /^[a-zA-Z0-9_]*$/;

        //Validating the textBox value against our regex pattern.
        let isValid = pattern.test(keyCode);
        let match = keyCode.match(pattern)
        if ((!e.target?.value?.length && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106))) || (e.target?.selectionStart === 0 && ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)))) {
            e.preventDefault()
        } else if (!isValid) {
            e.preventDefault()
        }
    }

    const new_name_aggrigate = useRef()

    const getFieldTemplate = (key, index, value = '', additionalClasses = '', isDisable = false, column) => {

        switch (key) {
            case 'checked':
                return <div className='d-flex align-items-center'>
                    <Checkbox checked={value} className="mr-2 " disabled={isDisable || !isExecutePermission} onChange={(e) => onInputChangeHandler(key, e.target.checked, index)}>
                    </Checkbox>
                    <div className='cursor d-block bx-rotate-90 ml-3 six-dot-dnd-ic'> <SIXDOTS /></div>
                </div>

            case 'old_name':
            case 'new_name':

                return <div className='d-flex align-items-center justify-content-left'>

                    {reOrderRender ? <div className='w-100' onKeyDown={(e) => e.stopPropagation()}>


                        {key === 'old_name' ? <div className={column?.showError ? 'old-name-error' : 'mt-2'}><div style={{ fontFamily: 'InterRegular', fontSize: "0.6875rem", textAlign: "left", color: "black", height: '0.75rem', marginTop: "-1rem", }}> <Tooltip title={value}>{value}</Tooltip></div>
                            <span style={{ fontFamily: 'InterRegular', fontSize: "0.5rem", textTransform: "uppercase", textAlign: "left", color: "#828282" }}>{column?.Type}</span>
                        </div> : <Tooltip title={value}> <input
                            required
                            type="text"
                            // ref={new_name_aggrigate}
                            // key={column.id}
                            defaultValue={value}
                            onKeyDown={(e) => Validate(e)}
                            onClick={(e) => e?.stopPropagation()}
                            onChange={(e) => {

                                // if (columnNameRegex.test(e.target.value) || e.target.value === "") {
                                onInputChangeHandler(key, e.target.value, index)

                                // if (key === 'format') {
                                //     onInputChangeHandler(key, e.target.value, index)
                                // }
                            }}
                            placeholder=''
                            className={`custom-input-field text-dark ${additionalClasses}`}
                            disabled={window.location.href.includes("viewFlows") || !isExecutePermission ? true : isDisable}
                        /></Tooltip>}

                    </div> : null}
                </div>

            case 'format':
                return <div style={{ marginTop:"0.125rem" }}  className='d-flex align-items-center justify-content-left'>

                    <div className='w-100' style={{ margin: "-0.1875rem" }} onKeyDown={(e) => e.stopPropagation()}>
                        {reOrderRender ? <Tooltip title={value}>
                            <input
                                required
                                type="text"
                                // ref={new_name_aggrigate}
                                // key={column.id}

                                defaultValue={value}

                                // onKeyDown={(e) => {
                                //     e.preventDefault()

                                // }}
                                onClick={(e) => e?.stopPropagation()}
                                onChange={(e) => {



                                    // if (columnNameRegex.test(e.target.value) || e.target.value === "") {
                                    onInputChangeHandler(key, e.target.value, index)

                                    // if (key === 'format') {
                                    //     onInputChangeHandler(key, e.target.value, index)
                                    // }
                                }}
                                placeholder=''
                                className={`custom-input-field text-dark ${additionalClasses}`}
                                disabled={window.location.href.includes("viewFlows") || !isExecutePermission ? true : isDisable}
                            />

                        </Tooltip> : <input
                            required
                            type="text"
                            // ref={new_name_aggrigate}
                            // key={column.id}

                            defaultValue={value}

                            // onKeyDown={(e) => {
                            //     e.preventDefault()

                            // }}
                            onClick={(e) => e?.stopPropagation()}
                            onChange={(e) => {



                                // if (columnNameRegex.test(e.target.value) || e.target.value === "") {
                                onInputChangeHandler(key, e.target.value, index)

                                // if (key === 'format') {
                                //     onInputChangeHandler(key, e.target.value, index)
                                // }
                            }}
                            placeholder=''
                            className={`custom-input-field text-dark ${additionalClasses}`}
                            disabled={window.location.href.includes("viewFlows") || !isExecutePermission ? true : isDisable}
                        />}
                    </div>
                </div>
            case 'new_datatype':
                return <>
                    <div className='d-flex align-items-center h-100' style={{ marginTop:"0.125rem" }} onKeyDown={(e) => e.stopPropagation()}>
                        <SingleSelection
                            props={{
                                placeholder: "Select",
                                dropDownValue: value,
                                onClickEvent: (val) => onInputChangeHandler(key, val, index),
                                optionList: key === 'new_name' ? businessTerms : dataTypes,
                                isDisable: window.location.href.includes("viewFlows") || !isExecutePermission ? true : isDisable,
                                isSortOptionDisable: true,
                                selectClassName: 'w-100'
                            }}
                        />
                    </div>
                </>

            default:
                break;
        }
    }

    const generateColumns = (columns_list) => {
        let cols = [], colsListCopy = [...columns_list]
        cols = colsListCopy.filter(v => v.visible === true).map(v => {
            return v.key !== 'checked' ? {
                name: <label className='font-w-600 fontSizeHeading mb-0'>
                    {v.title}
                    {v.isRequired ? <span><sup>*</sup></span> : ''}
                </label>,
                key: v.key,
                sortable: false,
                resizable: false,
                width: v?.width ? remToPx(pxToRem(v.width)) : undefined,
                frozen: v.frozen ?? false
            } : {
                name: <div className='d-flex align-items-center pt-2 mt-1'><Checkbox checked={isAllSelected()} disabled={window.location.href.includes("viewFlows") || !isExecutePermission ? true : false} onChange={(e) => Selectall(e.target.checked)}></Checkbox></div>,
                key: v.key,
                sortable: false,
                resizable: false,
                width: v?.width ? remToPx(pxToRem(v.width)) : undefined,
                frozen: v.frozen ?? false
            }
        })
        /* cols.push({
            name: <label className='w-100 text-center font-w-600 grey-color label mb-0 paragraph'>
                Action
            </label>,
            key: 'action_btns',
            sortable: false,
            resizable: falsee,
            width: 20,


        }) */
        return cols
    }

    const isAllSelected = () => {
        if (actualData?.length) {

            return actualData.every((ele) => ele?.checked === true)
        } else {
            return false;
        }
    }
    useEffect(() => {
        let temp = [], cols = [], obj = {};
        let rows = [...actualData]
        temp = rows.map((v, i) => {

            Object.keys(v).forEach(key => obj[key] = getFieldTemplate(key, i, v[key], '', key === 'old_name' ? true : v[key]?.disabled, v))
            return { ...obj }

        })
        setDemo([...temp])
        cols = [...generateColumns(colsList)]
        setColumns([...cols])

    }, [actualData, reOrderRender])


    const onInputChangeHandler = (field, value, index) => {

        let dataCopy = [...actualData]

        dataCopy[index][field] = value
        if (field === 'new_name') {
            // removeStartingUnderscores(value.replaceAll(columnNameRegexExp, '_'))

            dataCopy[index]['text'] = value
        }
        if (field === 'new_datatype') {
            // removeStartingUnderscores(value.replaceAll(columnNameRegexExp, '_'))
            setreOrderRender(false);

            dataCopy[index]['format'] = ''
            setTimeout(() => {
                setreOrderRender(true);
            }, 50);

        }
        setshowCloseWarning(true)
        setNewColumnList([...dataCopy])

    }

    return (
        <>
            <div className='custom-card padding-2 bg-white pb-0' style={{ height: '100%', overflow: 'hidden' }}>
                <div className='flows-dnd-list' style={{ overflow: 'auto', height: 'calc(100% - 0px)' }}>
                    <MultipleColsDndList
                        setreOrderRender={setreOrderRender}
                        colsList={columns}
                        rowsList={demo}
                        stopDraggingRow={stopDraggingRow}
                        setData={(data) => { setNewColumnList(data); setshowCloseWarning(true) }}
                        rawData={actualData}
                    />
                </div>
            </div>

            <FormatColHelplinkTable isModalOpen={openformatHelpModal} onClose={() => setopenformatHelpModal(false)}></FormatColHelplinkTable>
        </>
    )
}

export default DndSchemaList;
