import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Badge, message, Tooltip } from "antd";

import { RetrieveAllMyAlerts } from '../../../../store/modules/alert/createAlert/createAlertAction';

import { LandingSimpleTable } from '../../../Common/landingSimpleTable/LandingSimpleTable';

import { Literals } from '../../common/literals';
import { NoDataComponent, exceptionFormatter, getAlertCount, getAlertPriorityIcon, titleCase, getRelativeTime } from '../../common/helperFunctions';
import Highlighter from 'react-highlight-words';
import { ALERT, ALERTBELLLARGE, NOTIFICATION } from '../../../Common/iconSource';


import { useNavigate } from 'react-router-dom';

import { setSidebarActiveRoute } from '../../../../store/actions';


function MyAlertIndex({ startDate, endDate, searchData = '' }) {
    const store = useStore()
    const history = useNavigate()
    const [openFailureModal, setopenFailureModal] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [responseData, setresponseData] = useState([])
    const [tableColumns, settableColumns] = useState([])
    const [tableDatasource, settableDatasource] = useState([])
    const [pageCounter, setpageCounter] = useState(1)
    const [loadMore, setLoadMore] = useState(false)
    const [alertId, setalertId] = useState(49)
    const [isNextPageInvalid, setisNextPageInvalid] = useState(false)
    useEffect(() => {
        store.dispatch(setSidebarActiveRoute('/alerts'))
    }, [])
    const callNextPage = () => {

        if (!isNextPageInvalid) {

            store.dispatch(RetrieveAllMyAlerts(pageCounter + 1, 30, startDate, endDate)).then((resp) => {
                
                if (resp && resp.data && resp.data?.executions?.length) {
                    setresponseData([...responseData, ...resp.data.executions])
                    setpageCounter(pageCounter + 1)
                    setLoadMore(false)
                    // setEmpty(false)

                    createTable()
                } else {
                    // setEmpty(true)
                }


                if (resp.status === "success" && resp.message === "Invalid Page Requested") {
                    message.info('No more records')
                    setLoadMore(false)
                    setisNextPageInvalid(true)
                }

            }).catch((e) => {
                setpageCounter(1)
                setisNextPageInvalid(false)

                setLoadMore(false)

            })
        } else {
            setLoadMore(false)
        }

    }
    const createTable = () => {
        const columns = [
            {
                name: '',
                dataIndex: "alertCount",
                key: "alertCount",
                sorter: {
                    compare: (a, b) => a?.podName?.props?.children?.props?.children[1]?.props?.title !== undefined ? a?.podName?.props?.children?.props?.children[1]?.props?.title.localeCompare(b?.podName?.props?.children?.props?.children[1]?.props?.title) : null,
                },
                sortable: false,
                className: "description single-line-ellipsis",
                width: '9%',
                resizable: false
            },
            {
                name: 'Name',
                dataIndex: "name",
                key: "name",
                sorter: {
                    compare: (a, b) => a?.podName?.props?.children?.props?.children[1]?.props?.title !== undefined ? a?.podName?.props?.children?.props?.children[1]?.props?.title.localeCompare(b?.podName?.props?.children?.props?.children[1]?.props?.title) : null,
                },
                sortable: true,
                className: "description single-line-ellipsis",
                width: "20%",
                resizable: false
            },
            {
                name: Literals.DESCRIPTION,
                dataIndex: "description",
                key: "description",
                className: "description single-line-ellipsis",
                width: "30%",
                sortable: false,
                resizable: false
            },
            {
                name: 'Priority',
                dataIndex: "priority",
                key: "priority",
                width: "auto",
                sortable: false,
                resizable: false
            },

            {
                name: 'Last Run',
                dataIndex: "lastRun",
                key: "lastRun",
                sorter: {
                    compare: (a, b) => a?.createdOn?.props?.title !== undefined ? a?.createdOn?.props?.title?.localeCompare(b?.createdOn?.props?.title) : null

                },
                width: "auto",
                sortable: true,
                resizable: false
            },
            // {
            //     name: 'Purpose',
            //     dataIndex: "purpose",
            //     key: "purpose",
            //     width: "20%",
            //     sortable: false,
            //     resizable: false
            // },


        ];
        settableColumns([...columns])
    }
    useEffect(() => {
        setpageCounter(1)
        setLoadMore(false)
        setisNextPageInvalid(false)
        // startDate !== '' &&
        if (endDate !== '') {
            store.dispatch(RetrieveAllMyAlerts(1, 30, startDate, endDate)).then((resp) => {

                if (resp && resp.data && resp.data?.executions?.length) {
                    setresponseData(resp.data.executions)
                    setEmpty(false)
                    createTable()
                } else { setresponseData([]);
                setEmpty(true)
                }
            })
        }

    }, [startDate, endDate])


    const getPurposeIcon = (purpose) => {
        return <Tooltip placement='right' title={`${purpose} Alert`}>
            <span className={`noti-alert-prps-ic ${purpose.toLowerCase() === "quality"
                ? "quality"
                : "business"}`}>
                <ALERTBELLLARGE />
            </span>
        </Tooltip>
    }

    const setTableData = (data) => {
        let data_arr = data.map((ele) => {
            return {
                alertCount: ele.state.toLocaleLowerCase() === 'success'
                    ?
                    // <Tooltip placement="bottom" title={ele?.exception_count}>
                    <Badge title={ele?.exception_count} offset={[0, 0]} style={{ backgroundColor: '#FFBB01 !important' }} showZero={true} count={exceptionFormatter(ele?.exception_count, 1)} overflowCount={999}>
                        <span className='alert-bell-count pl-2' >{getPurposeIcon(ele?.rule_detail?.purpose)}</span>
                    </Badge>
                    // </Tooltip> 
                    : ele.state.toLocaleLowerCase() === 'error'
                        ?
                        // <Tooltip placement="bottom" title={ele?.exception_count}>
                        <Badge title={ele?.exception_count} count={<i className='bx bxs-x-circle' style={{ fontSize: "20px", color: '#eb1321' }} ></i>} overflowCount={999}>
                            <span className='alert-bell-count pl-2' >{getPurposeIcon(ele?.rule_detail?.purpose)}</span>
                        </Badge>
                        // </Tooltip> 
                        :
                        // <Tooltip placement="bottom" title={ele?.exception_count}>
                        <Badge title={ele?.exception_count} style={{ backgroundColor: '#52c41a' }} count={exceptionFormatter(ele?.exception_count, 1)} overflowCount={999}>
                            <span className='alert-bell-count pl-2' >{getPurposeIcon(ele?.rule_detail?.purpose)}</span>
                        </Badge>
                // </Tooltip>
                ,
                name: <span className='flexible-search-wrap text-uppercase font-w-600'><Tooltip placement="bottom" title={ele?.rule_detail?.name}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.rule_detail?.name ? ele?.rule_detail?.name : ''} /></Tooltip></span>,
                description: <span className='flexible-search-wrap'><Tooltip placement="bottom" title={ele?.rule_detail?.description}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.rule_detail?.description ? ele?.rule_detail?.description : ''} /></Tooltip></span>,
                priority: <div className="w-auto h-100 d-flex align-items-center">
                    <Tooltip placement="bottom" title={ele?.rule_detail?.priority}>
                        {getAlertPriorityIcon(ele?.rule_detail?.priority)}
                    </Tooltip>
                </div>,
                // priority: <Tooltip placement="bottom" title={ele?.rule_detail?.priority}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.rule_detail?.priority ? ele?.rule_detail?.priority : ''} /></Tooltip>,
                lastRun: <Tooltip placement="bottom" title={new Date(ele?.start_date_time).toLocaleString()}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={new Date(ele?.start_date_time).toLocaleString() ? new Date(ele?.start_date_time).toLocaleString() : ''} /></Tooltip>,
                purpose: <span className={ele?.rule_detail?.purpose?.toLowerCase() === 'quality' ? 'pl-0 quality' : 'pl-0 business'}><Highlighter searchWords={[searchData]} textToHighlight={titleCase(ele?.rule_detail?.purpose) + ' Alert'}></Highlighter>  </span>,
                key: ele?.rule_detail?.id,

                allData: ele,

            }

        })
        settableDatasource([...data_arr])
    }


    useEffect(() => {
        setisNextPageInvalid(false)

        if (responseData && responseData.length && searchData !== '') {
            let filterData = responseData.filter((data) => {

                return (data.rule_detail.name?.toLowerCase().includes(searchData?.toLowerCase()) ||
                    data?.rule_detail?.description?.toLowerCase().includes(searchData?.toLowerCase()) ||
                    getRelativeTime(data?.start_date_time)?.toLowerCase().includes(searchData?.toLowerCase()) ||
                    data?.rule_detail?.priority?.toLowerCase().includes(searchData?.toLowerCase()) ||
                    data?.rule_detail?.purpose?.toLowerCase().includes(searchData?.toLowerCase())
                )
            })

            setTableData([...filterData])
        } else {
            setTableData([...responseData])

        }


        // getVirtualTableRecords(data,columns)
    }, [searchData, responseData])




    const getComparator = (sortColumn) => {
        switch (sortColumn) {
            case 'name':
                return (a, b) => a['allData']?.rule_detail?.name !== undefined ? a['allData']?.rule_detail?.name?.localeCompare(b['allData']?.rule_detail?.name) : null
            case 'priority':
                return (a, b) => a[sortColumn]?.props?.title !== undefined ? a[sortColumn]?.props?.title?.localeCompare(b[sortColumn]?.props?.title) : null
            case 'purpose':
                return (a, b) => a[sortColumn]?.props?.title !== undefined ? a[sortColumn]?.props?.title?.localeCompare(b[sortColumn]?.props?.title) : null
            case 'lastRun':
                return (a, b) => a[sortColumn]?.props?.title !== undefined ? a[sortColumn]?.props?.title?.localeCompare(b[sortColumn]?.props?.title) : null
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }
    /* let TableHeight;
    if (window.innerHeight >= 750 && window.innerHeight < 1000) {
        TableHeight = Math.round(window.innerHeight - 180);
    } else if (window.innerHeight >= 1000) {
        TableHeight = Math.round(window.innerHeight - 150);
    } else {
        TableHeight = Math.round(window.innerHeight - 150);
    } */

    const onExceptionClick = (row) => {
        if (row && row.allData && row.allData.id) {

            history(`${Literals.links.ALERTS_VIEW}${row.key}`, { state: { exceptionid: row.allData.id, from: 'myalert' } })
        }

    }
    const onScrollToBottom = (isAtBottom) => {
        if (isAtBottom && !loadMore && !isNextPageInvalid) {
            setLoadMore(true)
            callNextPage()
        }
    }
  
    return (
        empty ? <NoDataComponent logo={<NOTIFICATION width="60" height="60" color="black" />}
            message=" No Data Found " />
            : <div className='myalert-landingpage-maindiv'>

                <div className='mt-2 myalert-ladingpage-table h-100'>
                    <LandingSimpleTable cols={tableColumns}
                        rowsData={tableDatasource}
                        onRowClick={(row) => { onExceptionClick(row) }}
                        onScrollToBottom={onScrollToBottom}
                        // autoWidth={true}
                        loadMore={loadMore}
                        setLoadMore={setLoadMore}
                        tableHeight={'100%'}
                        getComparator={getComparator} />

                </div>

            </div>

    )
}

export default MyAlertIndex

