import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Buttons from "../../../../Common/button/Buttons";
import {
  CLOSE,
  CODEEDITOR,
  EDIT,
  EDITOR,
  EXITFULLSCREEN,
  FULLSCREEN,
  PLAY,
  SAVE,
  SQLTRANSFORMERNEW,
} from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";


import { connect, useDispatch, useSelector, useStore } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import {
  fetchPreviewTable,
  setActivePodDetails,
} from "../../../../../store/modules/common/VirtualDataTable/action";
import {
  executeTransformerApi,
  saveTransformerNoteClick,
  setFlowsCurrenttransformer,
  setFlowsShowleftsidedetails,
  setSQLeditMode,
  setSQlTransformerQuery,
  showFlowsLowerSection,
  showFlowsTransformdetails,
  updateFlowsElements,
} from "../../../../../store/modules/flows/flowsActions";
import DatasetOutputNameInput from "../commonFunctions/useDatasetNameInput";
import EditorIndex from "../DataSource/editor/EditorIndex";
import { SCRIPTDEFAULTVIEW } from "../script/ScriptDefaultInfography";
import SqlEditor from "../../../dataCatalogue/preview/components/SqlEditor";
import { saveSQlTrigger } from "../../../../../store/modules/dataCatalogue/preview/previewActions";
import { getInputFrame, getInputTransformerDetails } from "../FlowsHelperFunction";
import { cloneDeep } from "lodash";
const { TabPane } = Tabs;

const SQLIndex = ({
  flowsElements,
  dataSourcePodDetails,
  currentTransformer,
  isExecutePermission,
}) => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("properties");
  const { flowId } = useParams();
  const [Query, setQuery] = useState("");
  const store = useStore();
  const [editMode, setEditMode] = useState(false);
  const [showBtnLoading, setShowBtnLoading] = useState(false);
  const [toggleDefaultView, setToggleDefaultView] = useState(true)
  const [disableExcute, setdisableExcute] = useState(false);
  const [transformerName, settransformerName] = useState("");
  const [expandTable, setexpandTable] = useState(false);
  const [showRedBtn, setshowRedBtn] = useState(false);

  const [readOnly, setreadOnly] = useState(false);
  const sqlQuery = useSelector(
    (state) => state.FlowsReducer.sqlTransformerQuery
  );
  const [sqlTables, setsqlTables] = useState([]);
  const getUUID = () => {
    let id = uuidv4();

    return id;
  };
  let transformerNameList = useSelector(
    (state) => state.FlowsReducer.transformerName
  );
  useEffect(() => {
    
    if (!transformerName) {
      setshowRedBtn(true)
    }
  }, [transformerName])
  const getPreviewResult = (podDetails) => {
    if (podDetails) {
      let request = {
        uuid: getUUID(),
        connection_name: podDetails.connectionName,
        table_name: podDetails.podName,
        page: 0,
        limit:1,
        schema_name: podDetails.dataDomain,
        do_count: false,
        sort_body: [],
        filter_body: [],
        table_ids: [],
      };
      return store
        .dispatch(fetchPreviewTable(request))
        .then((response) => {
          if (response?.status === "success" && response?.data?.result) {
            let responseData = response.data.result;

            if (responseData && responseData !== null) {
              let outcomeColumns = [];
              if (responseData?.data_header?.length) {
                responseData.data_header.forEach((item, index) => {
                  outcomeColumns.push({
                    id: `${item}_${index}`,
                    Name: item,
                    Type: responseData?.data_dtype[index],
                    checked: false,
                    // type: podDetails.podName,
                  });
                });
                if (outcomeColumns?.length) {
                  return outcomeColumns;
                } else {
                  return [];
                }
              }
            } else {
              return [];
            }
          }
        })
        .catch((err) => []);
    }
  };
  const executeQuery = async (sqlEditorQuery) => {

    if (sqlEditorQuery && sqlEditorQuery !== "" && transformerName !== "") {

      let Frame = [];
      let currentDataSource = flowsElements.find(
        (item) => item.id === currentTransformer.id
      );

      setShowBtnLoading(true);

      store.dispatch(showFlowsLowerSection(false));
      setdisableExcute(true);
      let payload = {
        flow_body: {
          Id: transformerName,
          NodeType: "sql",
          currentTransformerID: currentTransformer.id,

          Args: [
            {
              Name: "frames",
              Value: store.dispatch(getInputFrame()),
            },
            {
              Name: "query",
              Value: sqlEditorQuery,
            },
          ],
        },
      };
      try {
        let response =
          flowId &&
          (await store.dispatch(
            executeTransformerApi(flowId, payload, currentTransformer.id)
          ));
        if (!readOnly) {
          editSqlEditor();
        }


        if (response.status === "success") {
          let newStatusInterval = setInterval(async () => {
            let transformerProgress =
              store.getState().FlowsReducer.transformerProgress;

            let currentStatus = "";
            let error_msg = null;

            if (transformerProgress && transformerProgress.length) {
              currentStatus = transformerProgress.find((element) => {
                return element.crnt_transformer === currentTransformer.id;
              })?.state;
              error_msg = transformerProgress.find((element) => {
                return element.crnt_transformer === currentTransformer.id;
              })?.error_msg;
            }

            if (currentStatus?.toLowerCase() === "success") {
              clearInterval(newStatusInterval);
              setShowBtnLoading(false);
              setdisableExcute(false);
              setshowRedBtn(false)

              let outcomeColumns = [];

              let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
   
              outcomeColumns = await getPreviewResult({
                podName: transformerName,
                dataDomain: `flow_${flowId}`,
                connectionName: GlobalConnectionCache
                  ? GlobalConnectionCache
                  : "application_cache",
              });

              let flowsElements = store.getState().FlowsReducer.flowsElements;
              let finalFlowsElements = flowsElements.map((item) => {
                if (item.id === currentTransformer.id) {
                  item.transformerName = transformerName;
                  item.content.editorQuery = sqlEditorQuery;
                  item["content"]["outcomeColumns"] = [...outcomeColumns];
                  item.content.executed_DAG = payload.flow_body

                }
                return item;
              });

              store.dispatch(updateFlowsElements([...finalFlowsElements]));

            } else if (
              currentStatus?.toLowerCase() === "failed" ||
              currentStatus?.toLocaleLowerCase() === "error"
            ) {
              setShowBtnLoading(false);
              setdisableExcute(false);
              setshowRedBtn(false)

              let flowsElements = store.getState().FlowsReducer.flowsElements;
              let finalFlowsElements = flowsElements.map((item) => {
                if (item.id === currentTransformer.id) {
                  item.content.editorQuery = sqlEditorQuery;
                  item["content"]["outcomeColumns"] = [];
                  item.content.executed_DAG = payload.flow_body

                  item.transformerName = transformerName;
                }
                return item;
              });

              store.dispatch(updateFlowsElements([...finalFlowsElements]));
              clearInterval(newStatusInterval);
            }
          }, 1000);
        }
      } catch (error) {
        setShowBtnLoading(false);
        setdisableExcute(false);
        setshowRedBtn(false)

        if (error.status === "error") {
          let finalFlowsElements = flowsElements.map((item) => {
            if (item.id === currentTransformer.id) {
              item.content.editorQuery = sqlEditorQuery;
              item.transformerName = transformerName;
              item.content.executed_DAG = payload.flow_body
            }

            return item;
          });
          store.dispatch(updateFlowsElements([...finalFlowsElements]));
        }
      }
    } else if (!sqlEditorQuery && sqlEditorQuery === "") {
      emitToastNotification("warning", "Please write a query");
    } else if (transformerName === "") {
      emitToastNotification(
        "info",
        "Please enter transformer name to execute transformer"
      );
    }
  };


  useEffect(() => {
    let obj = { ...sqlQuery };
    setQuery(obj[currentTransformer.id]);
  }, [sqlQuery]);

  const setQueryvalue = (query) => {
    if (query !== "") {
      setQuery(query);
      let obj = { ...sqlQuery };
      obj[currentTransformer.id] = query;
      dispatch(setSQlTransformerQuery(obj));
    } else {
      setQuery(query);
      let obj = { ...sqlQuery };
      obj[currentTransformer.id] = "";
      dispatch(setSQlTransformerQuery(obj));
    }
  };

  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };


  const closeLeftSideContent = () => {
    dispatch(setFlowsCurrenttransformer({}));
    dispatch(showFlowsTransformdetails(false));
    dispatch(setFlowsShowleftsidedetails(false));
  };

  useEffect(() => {
    if (
      Object.keys(transformerNameList).length &&
      currentTransformer.id &&
      transformerNameList[currentTransformer.id]
    ) {
      setshowRedBtn(false)

      settransformerName(transformerNameList[currentTransformer.id]);
    }
    if (
      currentTransformer &&
      currentTransformer.content &&
      currentTransformer.content?.editorQuery
    ) {
      let obj = { ...sqlQuery };
      obj[currentTransformer.id] = currentTransformer.content?.editorQuery;
      store.dispatch(setSQlTransformerQuery(obj));
    } else {
      let obj = { ...sqlQuery };
      if (obj[currentTransformer.id] === "") {
        obj[currentTransformer.id] = "";
      }
      store.dispatch(setSQlTransformerQuery(obj));
    }

    let InputTransformer = store.dispatch(getInputTransformerDetails())


    if (InputTransformer && InputTransformer?.length) {

      let column = []

      InputTransformer.forEach((ele) => {
        column = [
          ...column,
          ...CreateSqlTableDetails(
            ele?.content?.outcomeColumns,
            ele.id,
            ele.type,
            ele.transformerName
          ),
        ];
      });

      if (column?.length) {

        setsqlTables([...column]);
        setToggleDefaultView(false)
        setShowBtnLoading(false);
        setdisableExcute(false);
      } else {
        setToggleDefaultView(true)
        setShowBtnLoading(false);
        setdisableExcute(true);
      }

    } else {
      setToggleDefaultView(true)
      setShowBtnLoading(false);
      setdisableExcute(true);
    }

  }, [currentTransformer]);

  const CreateSqlTableDetails = (
    columns = [],
    transformerId = "",
    transformerType,
    transformerName
  ) => {
    let sqlTable = [...sqlTables];
    let pod = {};
    let selectedPodList = cloneDeep(dataSourcePodDetails);
    if (selectedPodList && selectedPodList?.length && transformerId !== "") {
      pod = selectedPodList.find(
        (ele) => Object.keys(ele)[0] === transformerId
      );

      if (pod) {
        sqlTable.push({
          name: `${pod[transformerId].dataDomain}.${pod[transformerId].podName}`,
          description: "",
          type: "Table",
          tableName: "",
        });
      }
    }

    let GlobalConnectionCache = localStorage.getItem("GlobalConnectionCache");
    if (!transformerType.includes("datasource")) {
      sqlTable.push({
        name: GlobalConnectionCache
          ? `${GlobalConnectionCache}.flow_${flowId}.${transformerName}`
          : `flow_${flowId}.${transformerName}`,
        description: "",
        type: "Table",
        tableName: transformerName,
      });
    }

    if (columns && columns.length) {
      columns.forEach((element) => {
        if (
          transformerType.includes("datasource") &&
          pod &&
          pod[transformerId]?.dataDomain
        ) {
          sqlTable.push({
            name: `${pod[transformerId]?.podName}.${element.Name}`,
            description: "",
            type: "Column",
            tableName: transformerName,
          });
        } else {
          sqlTable.push({
            name: `${transformerName}.${element.Name}`,
            description: "",
            type: "Column",
            tableName: transformerName,
          });
        }
      });
    }
    console.log(sqlTable)
    if (sqlTable.length) {
      return sqlTable;
    } else {
      return [];
    }
  };
  const editSqlEditor = () => {
    if (readOnly) {
      dispatch(showFlowsLowerSection(true));

      setreadOnly(false);
      dispatch(setSQLeditMode(true));
      dispatch(showFlowsTransformdetails(true));
      dispatch(setFlowsShowleftsidedetails(true));
    } else {
      setreadOnly(true);
      dispatch(setSQLeditMode(false));
      dispatch(showFlowsTransformdetails(true));
      dispatch(setFlowsShowleftsidedetails(false));
    }
  };

  return (
    <>
      <div className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
        <div className="d-flex align-items-center">
          <SQLTRANSFORMERNEW />
          <h3 className="subtitle">SQL</h3>
          <Tabs
            onChange={onChange}
            activeKey={activeKey}
            className="custom-tab tab-with-icon"
          >
            <TabPane tab={<CODEEDITOR />} key="properties"></TabPane>
            <TabPane tab={<EDITOR />} key="sticky"></TabPane>
          </Tabs>
        </div>
        <div className="d-flex align-items-center">

          <DatasetOutputNameInput
            transformerName={transformerName}
            setTransformerName={settransformerName}
          />

          <Buttons props={{ buttonText: '', tooltip: 'close panel', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { closeLeftSideContent() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />

          {activeKey !== "sticky" ? (
            <Buttons
              props={{
                buttonText: "Execute",
                tooltip: "execute query transformer",
                buttonClassName: `${showRedBtn ? 'execute-btn-gradient' : ''} custom-btn-primary custom-btn btn-with-text`,
                showLoading: showBtnLoading,
                buttonEvent:
                  activeKey === "sticky"
                    ? () => {
                      setEditMode(true);
                    }
                    : () => {
                      dispatch(saveSQlTrigger({ trigger: 'run_click' }))
                      // executeQuery();
                    },
                ImgSrc:
                  activeKey === "sticky" ? () => <EDIT /> : () => <PLAY />,
                isDisable:
                  !isExecutePermission ||
                    disableExcute
                    ? true
                    : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
          {activeKey === "sticky" ? (
            <Buttons
              props={{
                buttonText: !editMode ? "Edit" : "Save",
                tooltip: "note",
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                buttonEvent: !editMode
                  ? () => {
                    setEditMode(true);
                    dispatch(saveTransformerNoteClick(false));
                  }
                  : () => {
                    dispatch(saveTransformerNoteClick(true));
                  },
                ImgSrc:
                  !editMode ? () => <EDIT /> : () => <SAVE />,
                isDisable: window.location.href.includes("viewFlows")
                  ? true
                  : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
        </div>
      </div>

      {activeKey === "properties" && (
        <>
          {!toggleDefaultView && (
            <div
              className={`${expandTable ? "full-screen-active" : "h-100"
                } bg-white flow-filter-height flow-sql-prop-edit`}
            >
              <div className="d-flex justify-content-between padding-2 custom-border-bottom">
                <div className="d-flex align-items-center">
                  <div>
                    <CODEEDITOR color="#2c83c4" height="16" width="16" />
                  </div>
                  <div className="paragraph ml-2">Code Editor</div>
                </div>

                {expandTable ? (
                  <Buttons
                    props={{
                      buttonText: "",
                      buttonClassName:
                        "custom-btn-outline custom-btn btn-with-icon",
                      buttonEvent: () => {
                        setexpandTable(false);
                      },
                      ImgSrc: () => <EXITFULLSCREEN />,
                      isDisable: false,
                      buttonType: Literals.BTN_TERTIARY,
                    }}
                  />
                ) : (
                  <Buttons
                    props={{
                      buttonText: "",
                      buttonClassName:
                        "custom-btn-outline custom-btn btn-with-icon",
                      buttonEvent: () => {
                        setexpandTable(true);
                      },
                      ImgSrc: () => <FULLSCREEN />,
                      isDisable: false,
                      buttonType: Literals.BTN_TERTIARY,
                    }}
                  />
                )}
              </div>

              <SqlEditor
                height={!expandTable ? 'calc(100vh - 132px)' : 'calc(100vh - 52px)'}
                readOnly={
                  !isExecutePermission ||
                    window.location.href.includes("viewFlows")
                    ? true
                    : false}
                sqlTableDetails={sqlTables}
                fromRoute="sql_transformer"
                setQueryvalue={setQueryvalue}
                ExecuteQueryClick={executeQuery}
                QueryValue={Query}
              />
            </div>
          )}
          {toggleDefaultView && (
            <div className="no-datasource">
              <SCRIPTDEFAULTVIEW />
            </div>
          )}
        </>
      )}
      {activeKey === "sticky" && (
        <EditorIndex editMode={editMode} setEditMode={setEditMode} />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    dataSourcePodDetails: state.FlowsReducer.dataSourcePodDetails,
  };
};

export default connect(mapStateToProps)(SQLIndex);