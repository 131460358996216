import React from 'react'
import { ICON_CHAT_TAIL } from '../../Common/newIconSource'
import { useSelector } from 'react-redux'
import { stringToColor } from '../common/helperFunctions'

const ConversationSender = React.forwardRef(({
    msg
}, ref) => {
    
    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const avatarInitials = React.useMemo(() => {
        if(userDetailsRedux) {
            if(userDetailsRedux?.first_name === "" && userDetailsRedux?.last_name === "") {
                return userDetailsRedux?.username?.charAt(0) ?? ''
            }
            return `${userDetailsRedux?.first_name?.charAt(0) ?? ''}${userDetailsRedux?.last_name?.charAt(0) ?? ''}`
        }
        return 'UU'
    }, [userDetailsRedux])

    return (
        <div className='cpilot-conv-wrap'>
            <div className='cpilot-conv-sender'>
                {msg}

                <span className='cpilot-conv-tail'>
                    <ICON_CHAT_TAIL/>
                </span>
            </div>
            <div className='cpilot-user-avt fontInterSemiBold text-uppercase' style={ userDetailsRedux ? {background: stringToColor(`${userDetailsRedux?.first_name} ${userDetailsRedux?.last_name}`)} : {}}>
                {avatarInitials}

                {/* {`${userDetailsRedux?.first_name?.charAt(0)} ${userDetailsRedux?.last_name?.chartAt(0)}`} */}
            </div>
        </div>
    )
})

export default ConversationSender