import React, { forwardRef } from 'react'
import DataGridTable from '../../../Common/dataGridTable/DataGridTable'
import { Select, Tooltip } from 'antd'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { getReloadErrorTemplate, showSimpleLoading } from '../../common/helperFunctions'
import { ICON_TABLE } from '../../../Common/newIconSource'

const StatsPreviewTable = forwardRef(({
    fieldValues = {},
    previewData,
    podDetails,
    uniqUUID,
    dictionaryData,
    setUniqUUID = () => {},
    setPodDetails = () => {},
    previewLoading = false,
    previewError = undefined,
    dictLoading = false,
    dictError = undefined,
    reloadPreview = () => {},
    getActivePod = () => {}
}, ref) => {
    
    const podOptions = React.useMemo(() => {
        return fieldValues?.col_term?.map(c => ({Name: c?.table_name?.toUpperCase(), value: c?.table_name}))
    }, []) 

    const [options, ] = React.useState(podOptions ?? [])
    const [selectedPod, setSelectedPod] = React.useState(options?.[0]?.value)

    React.useImperativeHandle(ref, () => ({
        setPod(pod) {
            setSelectedPod(pod)
            setPodDetails(fieldValues?.col_term?.find(c => c?.table_name === pod))
        },
    }))

    React.useEffect(() => {
        getActivePod(selectedPod)
    }, [selectedPod])
    
    return (
        <div className='pt-3 h-100'>
            <div className='mb-2 position-relative'>
                <div>
                    {
                        podOptions?.length === 0 ? ''
                        :
                        <div className='dict-title-strip mb-2'>
                            <div className='d-flex align-items-center w-100 justify-content-between'>
                                <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                                    Preview
                                </label>
                            </div>
                        </div>
                    }
                    {/* <span className='bold-h-line'></span> */}
                </div>
                {/* <div className='position-absolute' style={{width: 200, top: -4, right: 0}}>
                    <Select
                            showSearch={true}
                            optionFilterProp="children"
                            value={selectedPod}
                            placeholder="No Pods Found"
                            onChange={(val) => {
                                setSelectedPod(val)
                                setPodDetails(fieldValues?.col_term?.find(c => c?.table_name === val))
                            }}
                            filterOption={(input, option) =>
                                option.value?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                            }
                            // filterSort={(optionA, optionB) =>
                            //     optionA.value.toString().toLowerCase().localeCompare(optionB.value.toString().toLowerCase())
                            // }
                            >
                            {
                                options?.map((ele, index) => (
                                    <Select.Option key={index} value={ele.value ? ele.value : ele.Name}>
                                        <Tooltip title={ele?.Name} placement='topLeft'>
                                            <div className='fontInterSemiBold text-with-ellipsis fontSizeLabel'>
                                                {ele?.Name}
                                            </div>
                                        </Tooltip>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                </div> */}
            </div>
            <div style={{height: previewError || dictError ? 'calc(100% - 76px)' : 'calc(100% - 20px)'}}>
                {
                    dictLoading ? <div className='py-3'> {showSimpleLoading('Loading Dictionary...')} </div>
                    :   dictError ? getReloadErrorTemplate({errorMessage: dictError, onReload: () => reloadPreview()})
                        :
                        previewLoading
                        ?   <ClaristaLoader isCancel={true} />
                        :   previewError 
                            ?   getReloadErrorTemplate({
                                    errorMessage: previewError,
                                    onReload: () => reloadPreview()
                                })
                            :
                            podOptions?.length === 0 ? 
                            <div className='text-black-50 d-flex h-100 w-100 align-items-center justify-content-center flex-column'>
                                <ICON_TABLE height='50' width='50' color='currentColor' />
                                <label className='mt-2 fontSizeLargeHeading fontInterSemiBold'>No Pods found to "Preview"</label>
                            </div>
                            :
                            <DataGridTable
                                resp={previewData}
                                requestedTableId={podDetails?.table_id}
                                CurrentPODName={()=> {return podDetails?.table_name}}
                                CurrentDomainName={()=> {return podDetails?.data_domain_name}}
                                uniqUUID={uniqUUID}
                                setUniqUUID={setUniqUUID}
                                showDictionaryDetails={true}
                                showTechnicalTerm={true}
                                isPreviewPage={true}
                                dictSchemaData={dictionaryData}
                                columnNameToFix={fieldValues?.business_term}
                                isSmallTable={true}
                            />
                }
            </div>
        </div>
    )
})

export default StatsPreviewTable