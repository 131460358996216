import React, { useEffect } from 'react';
import LandingPageFirstHeader from "../../common/LandingpageFirstHeader";
import LandingPage from './components/LandingPage';
import LoadingComponent from "../../common/loadingComponent"
import { connect, useDispatch }  from  'react-redux'
import { setSidebarActiveRoute } from '../../../../store/actions';
const ConnectionsLandingPage = (props) => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSidebarActiveRoute('/connections'))
  },[])

  return (
      <>
      <div className="responsive-scroll">
          <LandingPageFirstHeader title={'Connections'} />
          <LandingPage />
      </div>
      {props.loadingStatus ? <LoadingComponent /> : null}
      </>
  )
}

const mapStateToProps = (state) => {
    return {
      loadingStatus: state.LoadingReducer.loadingStatus,
    };
  };

export default connect(mapStateToProps)(ConnectionsLandingPage);