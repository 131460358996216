import React from 'react'
import './newDataGridTable.scss'
import { ICON_RESET } from '../../newIconSource'

const DataGridClearFilter = React.forwardRef(({
    onClear = () => {}
}, ref) => {

    React.useImperativeHandle(ref, () => {
        // Future Use
        return {
            
        }
    }, [])

    return (
        <div className='new-dgt-clear-flt-comp'>
            <div className='p-2 d-flex align-items-center prv-flt-itm error-red'
                onClick={() => {
                    onClear()
                }}
            >
                <ICON_RESET width='15' height='15'/>
                <span className='mb-0 fontSizeHeading ml-2'>Clear Filter</span>
            </div>
        </div>
    )
})

export default DataGridClearFilter