import React, { useEffect, useRef } from 'react'
import SearchInput from './search/Search'
import { Empty } from 'antd'

const CustomDropdownContent = ({
    hideSearch = false,
    searchValue = '',
    setSearchValue = () => { },
    items = [],
    onItemClick = () => { },
    emptyDescription = 'No Data',
    searchPlaceholder = "Search here..."
}) => {
    const searchinputRef = useRef();
    useEffect(() => {
        searchinputRef?.current?.focus()

    }, [searchinputRef?.current])

    return (
        <div className='custom-dropdown-content'>
            <div className='custom-ddl-bottom'>
                {
                    hideSearch ? ''
                        :
                        <div className='pt-1 px-1 custom-ddl-search-wrap'>
                            <SearchInput
                                ref={searchinputRef}
                                autoFocus={true}
                                searchData={searchValue}
                                setSearchData={setSearchValue}
                                placeholder={searchPlaceholder}
                            />
                        </div>
                }

                <div className='custom-ddl-item-wrap'>
                    {
                        items?.length === 0
                            ?
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyDescription} />
                            :
                            items
                                ?.map((itm, i) => (
                                    <div key={`dom-ddl-itm-${i}`}
                                        title={itm?.tooltip}
                                        className={`text-with-ellipsis custom-ddl-item 
                                ${itm?.active ? 'active' : ''} ${itm?.disabled ? 'disabled' : ''}`}
                                        onClick={() => {
                                            if (itm?.disabled) return
                                            onItemClick(itm)
                                        }}
                                    >
                                        {itm?.label}
                                    </div>
                                ))
                    }
                </div>
            </div>
        </div>
    )
}

export default CustomDropdownContent