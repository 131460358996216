import { Checkbox, Tabs } from "antd";
import _, { cloneDeep, isFunction } from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { connect, useDispatch, useSelector, useStore } from "react-redux";
import { useParams } from "react-router";
import filter from "../../../../../assets/icons/Filter.svg";
import { setActivePodDetails } from "../../../../../store/modules/common/VirtualDataTable/action";
import {
  executeTransformerApi,
  saveTransformerNoteClick,
  setFilterTransformerFilterCriteria,
  setFlowsCurrenttransformer,
  showFlowsTransformdetails,
  updateFlowsElements,
} from "../../../../../store/modules/flows/flowsActions";
import Buttons from "../../../../Common/button/Buttons";
import {
  CLOSE,
  EDIT,
  EDITOR,
  PLAY,
  PROPERTIES,
  SAVE,
} from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import EditorIndex from "../DataSource/editor/EditorIndex";
import { SCRIPTDEFAULTVIEW } from "../script/ScriptDefaultInfography";
import SampleRows from "./SampleRows";

import DataGridAddFilter from "../../../../Common/dataGridTable/DataGridAddFilter";
import { stateReducer } from "../../../common/helperFunctions";
import DatasetOutputNameInput from "../commonFunctions/useDatasetNameInput";
import { ICON_SAVE } from "../../../../Common/newIconSource";
import { getInputFrame, getInputTransformerDetails } from "../FlowsHelperFunction";
import { emitToastNotification } from "../../../../../helpers/toast_helper";

const { TabPane } = Tabs;

// function useOutsideClick(ref, onOutsideClick) {
//   useEffect(() => {

//     function handleClickOutside(event) {
//       if (ref.current && !ref.current.contains(event.target)) {
//         onOutsideClick();
//       }
//     }
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => { document.removeEventListener('mousedown', handleClickOutside); };
//   }, [ref, onOutsideClick]);
// }


const FiltersIndex = ({
  flowsElements,
  transformerNameList,
  currentTransformer,
  isExecutePermission,
}) => {
  const [showSampleRow, setshowSampleRow] = useState(false);
  const [disableExcute, setdisableExcute] = useState(false);
  const [columnList, setcolumnList] = useState([]);
  const [activeKey, setActiveKey] = useState("properties");
  const [editMode, setEditMode] = useState(false);
  const [transformerName, settransformerName] = useState("");
  const [toggleValue, setToggleValue] = useState("count");
  const [rowCount, setrowCount] = useState("");
  const [showRedBtn, setshowRedBtn] = useState(false);

  // let initialState = {
  //   updateTabArray: false,
  //   updateStatus: false,
  //   updateContent: false,
  //   getinputFrame: false,
  //   transformerName: "",
  // };
  // const [helperfunctionProps, sethelperfunctionProps] = useReducer(
  //   stateReducer,
  //   initialState
  // );

  const wrapperRef = useRef(null);

  const [showBtnLoading, setShowBtnLoading] = useState(false);

  const [appliedFilter, setappliedFilter] = useState([])
  const store = useStore();
  const { flowId } = useParams();

  const dispatch = useDispatch();

  const transformerStatusChange = useSelector(
    (state) => state.FlowsReducer.transformerStatusChange
  );

  const [toggleDefaultView, setToggleDefaultView] = useState(true);

  const childAddFilterRef = useRef('');

  const criteriaRef = useRef('')
  const columnListRef = useRef([])
  const currentFlowsElements = useRef([])

  useEffect(() => {

    if (!transformerName) {
      setshowRedBtn(true)
    }
  }, [transformerName])

  const updateReduxDAG = async (filtergroup) => {
    let transformerName = transformerNameList[currentTransformer.id]

    if (
      filtergroup &&
      filtergroup.length &&
      transformerName !== "" &&
      !showSampleRow
    ) {
      settransformerName(transformerName)
      // setappliedFilter(filtergroup)


      const getType = (name) => {
        return columnList.find(item => item.Name === name)?.Type
      }
      let filterValueObj = {};
      filtergroup.forEach((element, i) => {
        filterValueObj[`filter${i}`] = [
          {
            Name: "column",
            Value: element.column,
          },
          {
            Name: "criteria",
            Value: element.criteria,
          },
          {
            Name: "datatype",
            Value: element.datatype !== '' ? element.datatype : getType(element.column),
          },
          {
            Name: "value",
            Value: element.value,
          },
          {
            Name: "operation",
            Value: element.operation,
          },
        ];
      });
      let inputFrame = dispatch(getInputFrame());

      if (inputFrame !== "") {

        let payload = {
          flow_body: {
            Id: `${transformerName}`,
            currentTransformerID: currentTransformer.id,
            NodeType: `filter`,
            Args: [
              {
                Name: "frame",
                Value: inputFrame,
              },
              {
                Name: "filter",
                Value: filterValueObj,
              },
            ],
          },
        };


        let finalFlowsElements = flowsElements.map((item) => {
          if (item.id === currentTransformer.id) {
            item.transformerName = transformerName;
            item.content.outcomeColumns = columnListRef.current
            item.content["appliedFilterCriteria"] = filtergroup;
            item.content["sampleRowCount"] = rowCount;
            item.content["showSampleRow"] = showSampleRow;
            item.content.selectedToggleValue = toggleValue;
            item.content.executed_DAG = payload.flow_body;
          }
          return item;
        });
        currentFlowsElements.current = [...finalFlowsElements]


      }
    } else if (transformerName !== "" && showSampleRow) {

      let inputFrame = dispatch(getInputFrame());

      let payload = {
        flow_body: {
          Id: `${transformerName}`,
          currentTransformerID: currentTransformer.id,
          NodeType: `filter`,
          Args: [
            {
              Name: "frame",
              Value: inputFrame,
            },
            {
              Name: "sample",
              Value: [],
            },
          ],
        },
      };

      if (toggleValue === "count") {
        payload["flow_body"]["Args"][1]["Value"] = [
          {
            Name: "count",
            Value: rowCount,
          },
        ];
      } else if (toggleValue === "percentage") {
        payload["flow_body"]["Args"][1]["Value"] = [
          {
            Name: "percentage",
            Value: rowCount,
          },
        ];
      }

      let finalFlowsElements = flowsElements.map((item) => {
        if (item.id === currentTransformer.id) {

          item.content.outcomeColumns = columnListRef.current

          item.transformerName = transformerName;
          item.content["appliedFilterCriteria"] = filtergroup;
          item.content["sampleRowCount"] = rowCount;
          item.content["showSampleRow"] = showSampleRow;
          item.content.selectedToggleValue = toggleValue;
          item.content.executed_DAG = payload.flow_body;
        }
        return item;
      });
      currentFlowsElements.current = [...finalFlowsElements]

    }
  };

  const executeFilterTransformer = async (filtergroup) => {
    if (
      filtergroup &&
      filtergroup.length &&
      transformerName !== "" &&
      !showSampleRow
    ) {
      settransformerName(transformerName)

      // setappliedFilter(filtergroup)
      criteriaRef.current = filtergroup;

      const getType = (name) => {
        return columnList.find(item => item.Name === name)?.Type
      }
      let filterValueObj = {};
      filtergroup.forEach((element, i) => {
        filterValueObj[`filter${i}`] = [
          {
            Name: "column",
            Value: element.column,
          },
          {
            Name: "criteria",
            Value: element.criteria,
          },
          {
            Name: "datatype",
            Value: element.datatype !== '' ? element.datatype : getType(element.column),
          },
          {
            Name: "value",
            Value: element.value,
          },
          {
            Name: "operation",
            Value: element.operation,
          },
        ];
      });
      let inputFrame = dispatch(getInputFrame());

      if (inputFrame !== "") {
        setShowBtnLoading(true);
        setdisableExcute(true);
        let payload = {
          flow_body: {
            Id: `${transformerName}`,
            currentTransformerID: currentTransformer.id,
            NodeType: `filter`,
            Args: [
              {
                Name: "frame",
                Value: inputFrame,
              },
              {
                Name: "filter",
                Value: filterValueObj,
              },
            ],
          },
        };
        try {
          let response =
            flowId &&
            (await store.dispatch(
              executeTransformerApi(flowId, payload, currentTransformer.id)
            ));

          setShowBtnLoading(false);
          setdisableExcute(false);
          let GlobalConnectionCache = localStorage.getItem(
            "GlobalConnectionCache"
          );
          if (response.status === "success") {
            setshowRedBtn(false)
            store.dispatch(
              setActivePodDetails({
                podName: `${transformerName}`,
                dataDomain: `flow_${flowId}`,
                connectionName: GlobalConnectionCache
                  ? GlobalConnectionCache
                  : "application_cache",
              })
            );

            settransformerName(transformerName)

            let finalFlowsElements = flowsElements.map((item) => {
              if (item.id === currentTransformer.id) {
                item.transformerName = transformerName;
                item.content.outcomeColumns = columnListRef.current
                item.content["appliedFilterCriteria"] = filtergroup;
                item.content["sampleRowCount"] = rowCount;
                item.content["showSampleRow"] = showSampleRow;
                item.content.selectedToggleValue = toggleValue;
                item.content.executed_DAG = payload.flow_body;
              }
              return item;
            });
            store.dispatch(updateFlowsElements([...finalFlowsElements]));
          }
        } catch (error) {
          setShowBtnLoading(false);
          setdisableExcute(false);

          if (error.status === "error") {
            let finalFlowsElements = flowsElements.map((item) => {
              if (item.id === currentTransformer.id)  {

                item.transformerName = transformerName;
                item.content["appliedFilterCriteria"] = filtergroup;
                item.content["sampleRowCount"] = rowCount;
                item.content["showSampleRow"] = showSampleRow;
                item.content.selectedToggleValue = toggleValue;
                item.content.executed_DAG = payload.flow_body; item.content.executed_DAG = payload.flow_body;

              }
              return item;
            });
            store.dispatch(updateFlowsElements([...finalFlowsElements]));
          }
        }
      } else {
        emitToastNotification('info', 'No input source found')
      }
    } else if (transformerName !== "" && showSampleRow) {
      setShowBtnLoading(true);
      setdisableExcute(true);
      settransformerName(transformerName)



      let inputFrame = dispatch(getInputFrame());

      let payload = {
        flow_body: {
          Id: `${transformerName}`,
          currentTransformerID: currentTransformer.id,
          NodeType: `filter`,
          Args: [
            {
              Name: "frame",
              Value: inputFrame,
            },
            {
              Name: "sample",
              Value: [],
            },
          ],
        },
      };

      if (toggleValue === "count") {
        payload["flow_body"]["Args"][1]["Value"] = [
          {
            Name: "count",
            Value: rowCount,
          },
        ];
      } else if (toggleValue === "percentage") {
        payload["flow_body"]["Args"][1]["Value"] = [
          {
            Name: "percentage",
            Value: rowCount,
          },
        ];
      }
      try {
        let response =
          flowId &&
          (await store.dispatch(
            executeTransformerApi(flowId, payload, currentTransformer.id)
          ));

        setShowBtnLoading(false);
        setdisableExcute(false);
        let GlobalConnectionCache = localStorage.getItem(
          "GlobalConnectionCache"
        );
        if (response.status === "success") {
          store.dispatch(
            setActivePodDetails({
              podName: `${transformerName}`,
              dataDomain: `flow_${flowId}`,
              connectionName: GlobalConnectionCache
                ? GlobalConnectionCache
                : "application_cache",
            })
          );

          settransformerName(transformerName)

          let finalFlowsElements = flowsElements.map((item) => {
            if (item.id === currentTransformer.id) {

              item.content.outcomeColumns = columnListRef.current

              item.transformerName = transformerName;
              item.content["appliedFilterCriteria"] = filtergroup;
              item.content["sampleRowCount"] = rowCount;
              item.content["showSampleRow"] = showSampleRow;
              item.content.selectedToggleValue = toggleValue;
              item.content.executed_DAG = payload.flow_body;
            }
            return item;
          });
          store.dispatch(updateFlowsElements([...finalFlowsElements]));
        }
      } catch (error) {
        setShowBtnLoading(false);
        setdisableExcute(false);
        let finalFlowsElements = flowsElements.map((item) => {
          if (item.id === currentTransformer.id) {

            item.transformerName = transformerName;
            item.content.outcomeColumns = columnListRef.current

            item.content["appliedFilterCriteria"] = filtergroup;
            item.content["sampleRowCount"] = rowCount;
            item.content["showSampleRow"] = showSampleRow;
            item.content.selectedToggleValue = toggleValue;
            item.content.executed_DAG = payload.flow_body;
          }
          return item;
        });
        store.dispatch(updateFlowsElements([...finalFlowsElements]));
      }
    }
  };




  useEffect(() => {
    if (
      Object.keys(transformerNameList).length &&
      currentTransformer.id &&
      transformerNameList[currentTransformer.id]
    ) {
      setshowRedBtn(false)

      settransformerName(transformerNameList[currentTransformer.id]);
    }

    let InputTransformer = dispatch(getInputTransformerDetails(currentTransformer, flowsElements))
    let inputColumns = []
    if (InputTransformer && InputTransformer?.length) {

      inputColumns = cloneDeep(InputTransformer[0]?.content?.outcomeColumns);
    }
    if (InputTransformer && InputTransformer?.length && inputColumns?.length) {


      let column = [];
      inputColumns.forEach((item) => {
        column.push({
          id: item.id,
          Name: item.Name,
          Type: item.Type,
          Label: item.Name,
          checked: false,
          columnValue: item.Name,
        });
      });
      if (column?.length) {
        setcolumnList([...column])
        columnListRef.current = [...column];
        setToggleDefaultView(false)
        setShowBtnLoading(false);
        setdisableExcute(false);
      } else {
        setcolumnList([])
        columnListRef.current = []

        setToggleDefaultView(true)
        setShowBtnLoading(false);
        setdisableExcute(true);
      }


      // let finalFlowsElements = flowsElements.map((item) => {
      //   if (item.id === currentTransformer.id) {

      //     item.content["outcomeColumns"] = inputColumns;
      //   }
      //   return item;
      // });
      // store.dispatch(updateFlowsElements([...finalFlowsElements]));

    } else {
      setToggleDefaultView(true)
      setShowBtnLoading(false);
      setdisableExcute(true);
    }


    let criteria = flowsElements.find(
      (item) => item.id === currentTransformer.id
    )?.content?.appliedFilterCriteria;

    if (currentTransformer?.content?.showSampleRow) {
      setshowSampleRow(true);
      setrowCount(currentTransformer?.content?.sampleRowCount)
      setToggleValue(currentTransformer?.content?.selectedToggleValue)
    }

    if (criteria && criteria.length) {
      setappliedFilter(criteria)
      criteriaRef.current = criteria
    } else { //// this full else need to remove after some time its used for backword compatability 
      let oldcriteria = flowsElements.find(
        (item) => item.id === currentTransformer.id
      )?.appliedFilterCriteria;
      if (!criteria && oldcriteria && oldcriteria?.length) {  //// this need to remove after some time its used for backword compatability
        setappliedFilter(oldcriteria)
        criteriaRef.current = oldcriteria
        let finalFlowsElements = flowsElements.map((item) => {
          if (item.id === currentTransformer.id) {

            // item.content["outcomeColumns"] = inputColumns;
            if (oldcriteria && oldcriteria?.length) {  //// this need to remove after some time its used for backword compatability
              item.content['appliedFilterCriteria'] = oldcriteria
            }

          }
          return item;
        });
        store.dispatch(updateFlowsElements([...finalFlowsElements]));

      } else {
        setappliedFilter([])
      }



    }


  }, [currentTransformer]); //showSampleRow

  useEffect(() => {  /// transformer level save 

    let criteriaInterval = setInterval(() => {
      if (childAddFilterRef.current && isFunction(childAddFilterRef.current?.getFilterGroup)) {

        // columnListRef.current = columnList;
        criteriaRef.current = childAddFilterRef.current?.getFilterGroup();

        updateReduxDAG(criteriaRef.current)


      }
    }, [100])
    return () => { /////////////update the executed_DAG once when component going to unmount

      if (currentFlowsElements.current?.length) {
        if (childAddFilterRef.current && isFunction(childAddFilterRef.current?.getFilterGroup)) {

          criteriaRef.current = childAddFilterRef.current?.getFilterGroup();

          updateReduxDAG(criteriaRef.current)

          store.dispatch(updateFlowsElements([...currentFlowsElements.current]));

        }
      }

      clearInterval(criteriaInterval)
    }
  }, [])

  useEffect(() => {

    updateReduxDAG(criteriaRef.current)

  }, [showSampleRow, rowCount, toggleValue])



  const onChange = (activeKey) => {
    setEditMode(false);
    setActiveKey(activeKey);
  };

  const closeLeftSideContent = () => {
    dispatch(setFlowsCurrenttransformer({}));
    dispatch(showFlowsTransformdetails(false));
  };


  const onSampleEvent = (e) => {
    setshowSampleRow(e.target.checked);
  };

  return (
    <>
      <div ref={wrapperRef} className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
        <div className="d-flex align-items-center">
          <img src={filter} alt={`filter`} />
          <h3 className="subtitle">Filter</h3>
          <Tabs
            onChange={onChange}
            activeKey={activeKey}
            className="custom-tab tab-with-icon"
          >
            <TabPane tab={<PROPERTIES />} key="properties"></TabPane>

            <TabPane tab={<EDITOR />} key="editor"></TabPane>
          </Tabs>
        </div>
        <div className="d-flex align-items-center">

          <DatasetOutputNameInput
            transformerName={transformerName}
            setTransformerName={settransformerName}
          />
          <Buttons
            props={{
              buttonText: "",
              buttonClassName:
                "custom-btn-outline custom-btn btn-with-icon mr-2",
              buttonEvent: () => {
                activeKey === "properties" ||
                  (activeKey === "editor" && !editMode)
                  ? closeLeftSideContent()
                  : setEditMode(false);
                onChange("properties");
              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_SECONDARY,
            }}
          />

          {activeKey === "editor" ? (
            <Buttons
              props={{
                buttonText: !editMode ? "Edit" : "Save",
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                buttonEvent: !editMode
                  ? () => {
                    setEditMode(true);
                    dispatch(saveTransformerNoteClick(false));
                  }
                  : () => {
                    dispatch(saveTransformerNoteClick(true));
                  },
                ImgSrc:
                  !editMode ? () => <EDIT /> : () => <ICON_SAVE />,
                isDisable: window.location.href.includes("viewFlows")
                  ? true
                  : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}

          {activeKey !== "editor" && !showSampleRow ? (
            <Buttons
              props={{
                buttonText: "Execute",
                buttonClassName: `${showRedBtn ? 'execute-btn-gradient' : ''} custom-btn-primary custom-btn btn-with-text`,
                showLoading: showBtnLoading,
                buttonEvent:
                  activeKey === "editor"
                    ? () => {
                      setEditMode(true);
                    }
                    : () => {
                      childAddFilterRef?.current &&
                        childAddFilterRef.current.executeFilter();
                    },
                ImgSrc:
                  activeKey === "editor" ? () => <EDIT /> : () => <PLAY />,
                isDisable:
                  !isExecutePermission || disableExcute || transformerName === "" ? true : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
          {activeKey !== "editor" && showSampleRow ? (
            <Buttons
              props={{
                buttonText: "Execute",
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                showLoading: showBtnLoading,
                buttonEvent:
                  activeKey === "editor"
                    ? () => {
                      setEditMode(true);
                    }
                    : () => {
                      executeFilterTransformer([]);
                    },
                ImgSrc:
                  activeKey === "editor" ? () => <EDIT /> : () => <PLAY />,
                isDisable:
                  !isExecutePermission || disableExcute || transformerName === "" ? true : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
        </div>
      </div>

      {activeKey === "properties" && (
        <>
          {" "}
          {!toggleDefaultView ? (
            <div style={{ height: "100%" }} className="bg-white ">
              <div style={{ height: "90%", overflow: "auto" }} className="">
                {!showSampleRow ? (
                  <div className="filter-parent-div h-100">
                    {" "}
                    <DataGridAddFilter
                      ref={childAddFilterRef}
                      props={{
                        icon: () => <PROPERTIES />,
                        title: "Properties",
                        onSampleEvent: onSampleEvent,
                        isFilterTransformer: true,
                        isdisabled: window?.location?.href?.includes(
                          "viewFlows"
                        )
                          ? true
                          : false,
                        initialColumn: columnList,
                        from: 'filterTransformer',
                        setAppliedFilter: executeFilterTransformer,
                        appliedFilter: appliedFilter,
                        ctx: ``,
                      }}
                    />{" "}
                  </div>
                ) : (
                  <div className="custom-border-bottom padding-2 py-0">
                    <div className="d-flex justify-content-end card-padding custom-border-bottom flows-filter-padding pr-2 py-1">
                      <Buttons
                        props={{
                          buttonText: (
                            <div className="d-flex align-items-center justify-content-center">
                              <Checkbox
                                checked={showSampleRow}
                                onChange={(e) => onSampleEvent(e)}
                                disabled={
                                  window?.location?.href?.includes("viewFlows")
                                    ? true
                                    : false
                                }
                              />
                              <span style={{ fontSize: '12px', fontFamily: 'InterRegular' }} className="custom-ellipsis paragraph one-line-ellipsis ml-1">
                                {"Sample"}
                              </span>
                            </div>
                          ),
                          buttonClassName: "content-with-checkbox mr-n2 pt-4 pr-3 pb-4",
                          buttonEvent: () => { },
                          toggleBtnValue: "",
                          ImgSrc: () => <></>,
                          isDisable: false,
                          buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                          toggleBtnOption: null,
                        }}
                      />
                    </div>
                    <SampleRows
                      rowCount={rowCount}
                      setrowCount={setrowCount}
                      setToggleValue={setToggleValue}
                      toggleValue={toggleValue}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="no-datasource">
              <SCRIPTDEFAULTVIEW />
            </div>
          )}
        </>
      )}

      {activeKey === "editor" && (
        <EditorIndex editMode={editMode} setEditMode={setEditMode} />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    appliedFilterCriteria: state.FlowsReducer.appliedFilterCriteria,
    transformerNameList: state.FlowsReducer.transformerName,
    transformerLoading: state.FlowsReducer.transformerLoading,
  };
};
export default connect(mapStateToProps)(FiltersIndex);
