import React from 'react'
import { Notification } from './Notification';
import { Trigger } from './Trigger';

export const SchedulerRightSide = ({
    isEditor = false
}) => {

    return <>
        <Trigger isEditor={isEditor}/>
        <Notification isEditor={isEditor}/>
    </>
}
