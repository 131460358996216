import React, { useState, useEffect } from "react";
import { Literals } from "../../../common/literals";
import UserGroupsTable from "../../../../Common/landingPageTable/LandingPageTable";
import { Avatar, Checkbox, Tooltip } from "antd";
import "./UserGroupSelectionTable.scss";
import NoSearchResultFound from "../../../common/NoSearchResultFound";

const UserGroupSelectionTable = ({
  searchData,
  userGroups = [],
  selectedUserGroups,
  setSelectedUserGroups,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);

  const generateGroupLeaders = (leaderNames = [], isLeader) => {
    return leaderNames.map((name) => (
      <Avatar style={{ backgroundColor: '#2c83c4' }}> <Tooltip title={name}>
        {name.charAt(0).toUpperCase()}
        {name.split(' ')?.[1]?.charAt(0)?.toUpperCase()}
      </Tooltip></Avatar>
    ));
  };

  const onSelectUserGroupHandler = (group) => {
    setSelectedUserGroups((groups) => {
      const newGroups = [...groups];
      const index = newGroups.findIndex((g) => g.id === group.id);

      if (index > -1) {
        newGroups.splice(index, 1);
      } else {
        newGroups.push(group);
      }

      return newGroups;
    });
  };

  useEffect(() => {
    const selectedIds = selectedUserGroups.map((group) => group.id);
    setSelectedIds(selectedIds);
  }, [selectedUserGroups]);

  const userGroupHeadings = [
    {
      title: Literals.USERGROUPNAME,
      dataIndex: "name",
      key: "name",
      width: "20%",
      sorter: {
        compare: (a, b) =>
          a.name?.props?.children[1]?.props?.title !== undefined
            ? a.name?.props?.children[1]?.props?.title.localeCompare(
              b.name?.props?.children[1]?.props?.title
            )
            : null,
      },
    },
    {
      title: Literals.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      width: "40%",
    },
    {
      title: Literals.LEADERS,
      dataIndex: "leaders",
      key: "leaders",
      width: "20%",
    },
    {
      title: Literals.LISTOFUSER,
      dataIndex: "listofuser",
      key: "listofuser",
      width: "20%",
    },
  ];

  const userGroupsData = [];

  userGroups.forEach((group) => {

    const leadersList = group.user.user_detail?.filter(e => e?.permissions?.includes('leader'))?.map((user) => `${user.first_name} ${user.last_name}`);
    const usersList = group.user.user_detail.map((user) => `${user.first_name} ${user.last_name}`);

    if (
      group.name.toLowerCase().includes(searchData.toLowerCase()) ||
      group.description.toLowerCase().includes(searchData.toLowerCase())
    ) {
      userGroupsData.push({
        name: (
          <div className="user-group-selection-name">
            <Checkbox
              checked={selectedIds.includes(group.id)}
              onChange={() => onSelectUserGroupHandler(group)}
            />
            <Tooltip placement="topLeft" title={group.name}>
              <p className='text-with-ellipsis mb-0'>  {group.name}</p>
            </Tooltip>
          </div>
        ),
        description: (
          <Tooltip placement="topLeft" title={group.description}>
            <p className='text-with-ellipsis mb-0'>    {group.description}</p>
          </Tooltip>
        ),
        leaders: <div style={{ minHeight: '36px' }}><Avatar.Group size={"small"} maxCount={2} maxStyle={{ color: '#FFFFF', backgroundColor: '#2c83c4' }}> {generateGroupLeaders(leadersList)}</Avatar.Group></div>,
        listofuser: <div style={{ minHeight: '36px' }}><Avatar.Group size={"small"} maxCount={2} maxStyle={{ color: '#FFFFF', backgroundColor: '#2c83c4' }}> {generateGroupLeaders(usersList)}</Avatar.Group></div>,
      });
    }
  });

  return (
    userGroupsData?.length === 0 ? <NoSearchResultFound />
      :
      <UserGroupsTable
        props={{
          pagination: false,
          size: "small",
          columns: userGroupHeadings,
          dataSource: userGroupsData,
          showSorterTooltip: false,
        }}
      />
  );
};

export default UserGroupSelectionTable;
