import React, { useEffect, useState } from 'react'
import dataset from '../../../../assets/icons/Dataset.svg'
import versions from '../../../../assets/icons/Versions.svg'
import logs from '../../../../assets/icons/Logs.svg'
import { showFlowsLogsLowerSection } from '../../../../store/modules/flows/NewFlowsRedux/flowsActions'
import { useStore } from 'react-redux'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd'

const LowerMenubar = () => {
    const store = useStore();
    const selectorState = useSelector(state => state.Lab.showLogsLowerSection)
    const selectorLogsState = useSelector(state => state.Lab.showLogsLowerSection)
    const [lowerSectionActive, setLowerSectionActive] = useState(store.getState().Lab.showLogsLowerSection)
    const [logsSectionActive, setlogsSectionActive] = useState(store.getState().Lab.showLogsLowerSection)


    const searchParams = new URLSearchParams(document.location.search)
    const history_id = searchParams.get("history_id");

    const toggleLowerSection = (btnType) => {
        if (btnType === 'logs') {
            store.dispatch(showFlowsLogsLowerSection(!store.getState().Lab.showLogsLowerSection))
            setlogsSectionActive(store.getState().Lab.showLogsLowerSection)
           
        }
       

    }

    useEffect(() => {
        setLowerSectionActive(selectorState)
    }, [selectorState])

    useEffect(() => {
        setlogsSectionActive(selectorLogsState)
    }, [selectorLogsState])

    return (
        <ul className="flow-control control-bottom">
            {/* <Tooltip title="Show Result Section" placement='right'>
                <li onClick={() => { toggleLowerSection('result') }} className={lowerSectionActive ? 'custom-btn-primary rounded-left' : ''}>
                    <img src={dataset} alt={`datasource`} className={lowerSectionActive ? 'filter-invert' : ''} />
                </li>
            </Tooltip> */}
            <Tooltip title="Show Logs" placement='right'>
                <li onClick={() => { toggleLowerSection('logs') }} className={logsSectionActive ? `custom-btn-primary ${history_id ? 'rounded-right' : 'rounded'}` : ''}>
                    <img src={logs} alt={`logs`} className={logsSectionActive ? 'filter-invert' : ''} />
                    Show Logs
                </li>
            </Tooltip>
            {/* {
                !history_id
                    ?
                    <Tooltip title="Show Timeline" placement='right'>
                        <li onClick={() => { toggleLowerSection('timeline') }} className={timelineSectionActive ? 'custom-btn-primary rounded-right' : ''}>
                            <TIMELINE_BLACK />
                        </li>
                    </Tooltip>
                    : ''
            } */}
            {/* <li>
                <img src={versions} alt={`versions`} />
            </li>
            */}
        </ul>
    )
}

export default LowerMenubar