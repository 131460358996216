import { Tooltip } from "antd";
import React from 'react';

import { Card, CardBody } from "reactstrap";
import Buttons from "../../../Common/button/Buttons";
import { ACCORDIONDOWNARROW, REDIRECTJOBS } from "../../../Common/iconSource";
import { Literals } from "../../common/literals";
import SourceTypeImage from "../../common/sourceTypeImage";
import { getNodeTypeIcon } from './helpers';

const BaseUrl_vis = window._env_.REACT_APP_VISUALIZATION_BASE_URL;

const chartlink = `${Literals.links.DATA_CATALOG_POD_DETAILS}`
const dashboardlink = `${Literals.links.DASHBOARD}/`
// const chartlink = `${BaseUrl_vis}superset/explore/`
// const dashboardlink = `${BaseUrl_vis}superset/dashboard/`

const URL_MAPPING = {
  'connection': "/connections",
  'data_domain': "/userManagement/dataDomain/",
  'pod': "/dataCatalog/podDetails/",
  'flow': `${Literals.links.FLOWS}/`,
  'rule': "",
  'alert': "",
  'group': "/userManagement/userGroupDetails/",
  'user': "/userManagement/user/",
  'scheduler': "/scheduler/view/",
  'chart': chartlink,  //'https://dev-visualization.clarista.co/superset/explore/',
  'dashboard': dashboardlink //'https://dev-visualization.clarista.co/superset/dashboard/'
}

let disabledNodes = {};

const LineageCard = ({ nodeDatum, loadData, setClickedNode, isConnected, headerColor, origin }) => {


  if (origin) {
    disabledNodes[nodeDatum.node_id] = { up: true, down: true }
  }
  let name = nodeDatum.name || nodeDatum.table_name;
  return (

    <Card onClick={(e) => {
      e.preventDefault();

      e.stopPropagation();

      setClickedNode(nodeDatum)
    }} className={`lin-tree-node custom-card p-0 ${isConnected ? "selectedNode" : ""}`}>

      <CardBody className="p-2 lin-main-card" style={{ borderTop: `solid 3px ${headerColor}` }}>
        {disabledNodes[nodeDatum.node_id]?.up || nodeDatum.desc?.toLowerCase()?.includes("domain")
          ?
          <div></div>
          // <div style={{ height: "28px" }}></div> 
          :
          <div className="d-flex align-items-center justify-content-center position-absolute lin-ex-btn">

            <Buttons
              props={{
                tooltip: 'Expand',
                buttonText: '',
                buttonClassName: "bg-white custom-btn custom-btn-outline btn-with-icon border rounded-circle",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  if (!disabledNodes[nodeDatum.node_id]?.up) {
                    disabledNodes[nodeDatum.node_id] = { ...disabledNodes[nodeDatum.node_id] ?? {}, up: true };
                    loadData(nodeDatum.id ?? nodeDatum[0]?.id, nodeDatum.desc, nodeDatum.node_id, true, nodeDatum.connection)
                  }

                },
                ImgSrc: () => <span className="bx-rotate-180" ><ACCORDIONDOWNARROW /></span>,
                isDisable: false,
                buttonType: Literals.BTN_SECONDARY
              }}
            />
          </div>
        }
        {/* <span style={{ flexGrow: 1, fontSize: "20px", fontWeight: "bold" }}>{nodeDatum?.desc?.toUpperCase()?.replaceAll('_', ' ') ?? ""}</span> */}
        <div className="align-items-center d-flex lin-cont-box">

          <div>
            <div className="lin-icon-wrap">
              {
                nodeDatum?.desc?.toLocaleLowerCase() === 'connection'
                  ?
                  <SourceTypeImage dataName={nodeDatum?.type || nodeDatum?.source_type} />
                  :
                  getNodeTypeIcon(nodeDatum.desc == "source" ? nodeDatum.type ?? nodeDatum.source_type : nodeDatum?.desc?.toLocaleLowerCase())
              }
            </div>
          </div>

          <div className={`ml-2 w-100 text-left`}>
            <Tooltip placement="topLeft" title={name?.toUpperCase()?.replaceAll('_', ' ') ?? ""}>


              <p className="mb-0 text-with-ellipsis lin-tree-node-heading text-dark font-w-600">{name?.toUpperCase()?.replaceAll('_', ' ') ?? ""}</p>

            </Tooltip>
            <Tooltip placement="topLeft" title={nodeDatum.desc === "group" ? "Usergroup" : ["data_domain", "domain"].includes(nodeDatum.desc) ? "Data Domain" : !nodeDatum.desc?.includes(" ") ? "" : nodeDatum.desc ?? ""}>
              {nodeDatum.name === "more" || nodeDatum.name === "less" ? "" : <p className="mb-0 small text-black-50 lin-tree-node-description">
                {
                  nodeDatum.desc === "flow" ? "flows" : nodeDatum.desc === "group" ? "Usergroup" : ["data_domain", "Domain"].includes(nodeDatum.desc) ? "Data Domain" : nodeDatum.desc ?? ""
                }

              </p>}
            </Tooltip>
          </div>

          {nodeDatum.desc == "source"
            ? <div></div>
            // ? <div style={{ height: "28px" }}></div> 
            :
            <span className="lin-redir-btn">

              {nodeDatum?.desc?.toLowerCase() === "chart" && (nodeDatum?.podId ? nodeDatum?.podId : nodeDatum?.pod_id) ? <Buttons
                props={{
                  tooltip: `Redirect to ${nodeDatum.desc}`,
                  buttonText:
                    '',
                  buttonClassName: `custom-btn custom-btn-outline btn-with-icon ml-2`,
                  buttonEvent: () => {

                    let path = ""

                    path = URL_MAPPING[nodeDatum.desc.toLowerCase()]
                   
                    path += `${nodeDatum?.podId ? nodeDatum?.podId : nodeDatum?.pod_id}?chartId=${nodeDatum.id}&chartName=${nodeDatum.name}#charts`



                    window.open(path)


                  },
                  ImgSrc: () => <REDIRECTJOBS />,

                  buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                }}
              /> : nodeDatum?.desc?.toLowerCase() !== "chart" ? <Buttons
                props={{
                  tooltip: `Redirect to ${nodeDatum.desc}`,
                  buttonText:
                    '',
                  buttonClassName: `custom-btn custom-btn-outline btn-with-icon ml-2`,
                  buttonEvent: () => {

                    let path = ""

                    path = URL_MAPPING[nodeDatum.desc.toLowerCase()]

                    // if (nodeDatum?.desc?.toLowerCase() == "chart") {


                    //   path += `${nodeDatum?.podId ? nodeDatum?.podId : nodeDatum?.pod_id}?chartId=${nodeDatum.id}&chartName=${nodeDatum.name}#charts`
                    // }
                    // else 
                    if (nodeDatum?.desc?.toLowerCase() !== "connection") {
                      path += nodeDatum?.id?.toString();
                    }
                    else {
                      path += `?connection_name=${nodeDatum.name}&open=true`
                    }


                    window.open(path)


                  },
                  ImgSrc: () => <REDIRECTJOBS />,

                  buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                }}
              /> : null}
            </span>
          }
        </div>

        {!disabledNodes[nodeDatum.node_id]?.down ?
          <div className="d-flex justify-content-center w-100 position-relative lin-col-btn">

            <Buttons
              props={{
                tooltip: 'Expand',
                buttonText: '',
                buttonClassName: "bg-white custom-btn custom-btn-outline btn-with-icon border rounded-circle",
                buttonEvent: (e) => {

                  e.stopPropagation();
                  if (!disabledNodes[nodeDatum.node_id]?.down) {
                    disabledNodes[nodeDatum.node_id] = { ...disabledNodes[nodeDatum.node_id] ?? {}, down: true };
                    loadData(nodeDatum.id ?? nodeDatum[0]?.id, nodeDatum.desc, nodeDatum.node_id, false, nodeDatum.connection)

                  }

                },
                ImgSrc: () => <span ><ACCORDIONDOWNARROW /></span>,
                isDisable: false,
                buttonType: Literals.BTN_SECONDARY
              }}
            />
          </div>
          :

          ""}
      </CardBody>
    </Card>
  )

}


export default LineageCard;