import React from 'react'

const NoResultsFound = ({ submsg = '', msg = "Sorry, we couldn't find any result ", height = '', imgwidth = "198", imgheight = "127" }) => <div className='d-flex noresultfound-wrapper flex-column justify-content-center align-items-center' style={{ height: height }}>
    <svg xmlns="http://www.w3.org/2000/svg" width={imgwidth} height={imgheight} viewBox="0 0 198 127" fill="none">
        <path d="M99 127C153.676 127 198 117.292 198 105.317C198 93.3416 153.676 83.6338 99 83.6338C44.3238 83.6338 0 93.3416 0 105.317C0 117.292 44.3238 127 99 127Z" fill="#F5F5F5" />
        <path d="M170.156 42.6225L138.767 6.99439C137.26 4.5659 135.061 3.09766 132.744 3.09766H65.2565C62.9393 3.09766 60.7396 4.5659 59.2329 6.99129L27.8438 42.6256V71.244H170.156V42.6225Z" stroke="#D9D9D9" />
        <path d="M128.74 52.4452C128.74 47.4736 131.815 43.3693 135.63 43.3662H170.156V99.5467C170.156 106.123 166.072 111.513 161.03 111.513H36.9703C31.9275 111.513 27.8438 106.12 27.8438 99.5467V43.3662H62.37C66.1846 43.3662 69.2598 47.4643 69.2598 52.4359V52.504C69.2598 57.4756 72.369 61.49 76.1805 61.49H121.819C125.631 61.49 128.74 57.4384 128.74 52.4668V52.4452Z" fill="#FAFAFA" stroke="#D9D9D9" />
    </svg>
    <p className='noresultmsg mb-0'>{msg}</p>
    <p style={{ color: "rgb(130 130 130)" }} className='noresultsubmsg dict-gray-color'>{submsg}</p>

</div>

export default NoResultsFound