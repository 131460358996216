import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Buttons from '../../../Common/button/Buttons';
import { CLOSE, CHECK } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import ManualCreationFields from './ManualCreationFields';

const ManualCreationModal = ({
    openModal, 
    setOpenModal=()=>{},
}) => {
    
    return (
        <Modal size="xl" isOpen={openModal} className="custom-modal" centered={true} onClosed={()=>{setOpenModal(false);}}>
            <ModalHeader className='d-flex align-items-center' close={<Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType:Literals.BTN_TERTIARY}} />}>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className='d-flex'>
                        <h2 className="section-heading">
                            Create Display Name
                        </h2>
                    </div>
                </div>                  
            </ModalHeader>
            <ModalBody className='p-0'>
                <div>
                    <ManualCreationFields/>
                </div>
            </ModalBody>
            <ModalFooter>
                <Buttons props={{ 
                        buttonText: 'Done', 
                        buttonClassName: "custom-btn-primary custom-btn btn-with-text", 
                        buttonEvent: () => setOpenModal(false), 
                        ImgSrc: () => <CHECK/>, 
                        isDisable: false,
                        buttonType:Literals.BTN_PRIMARY_WITH_TEXT
                    }} 
                />
            </ModalFooter>
        </Modal>
    )
}

export default ManualCreationModal