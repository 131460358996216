import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap';
import { ICON_CHAT, ICON_DRAFT, ICON_LOADING_CIRCLE, ICON_REPORT, ICON_SAVE, ICON_USER } from '../../../Common/newIconSource';
import { Dropdown, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import Buttons from '../../../Common/button/Buttons';
import { Literals } from '../../common/literals';
import { FILLEDSTAR, REDIRECTJOBS, REPORT_ICON, STAR, UPDATED_BY } from '../../../Common/iconSource';
import { getRelativeTime } from '../../common/helperFunctions';
import { useDispatch } from 'react-redux';
import { publishQueryAPI } from '../../../../store/modules/dataCatalogue/preview/previewActions';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { bookmarkReport } from '../../../../store/modules/common/GenAi/GenAiReducer';

const ReportsGridView = ({
    data,
    searchData,
    getReports,
    onClick = () => { }
}) => {

    const [optionOpen, setOptionOpen] = React.useState(false)
    const [bookmarkLoading, setbookmarkLoading] = useState(false)
    const dispatch = useDispatch()
    const optionItems = () => {
        let optionList = [
            {
                label: <span className="dbox-opt-itm"
                    onClick={(e) => {
                        dispatch(publishQueryAPI('DELETE', data?.id)).then((res) => {

                            if (res.status === 'success') {
                                getReports()
                                emitToastNotification('success', res.message)
                            }

                        })

                    }}>
                    <ICON_DRAFT />
                    Draft
                </span>,
                key: '1',
            },
            //   {
            //     label: <span className={`dbox-opt-itm danger ${!isDeletePermission ? 'disabled' : ''}`}
            //         onClick={(e) => { 
            //           e.stopPropagation(); 
            //           e.preventDefault();
            //           openDeleteDialog(data)
            //         }}>
            //       <DELETEICON />
            //       Delete
            //     </span>,
            //     key: '4',
            //     disabled: !isDeletePermission
            //   },
        ]

        if (data?.source_type === 'upload') {
            return optionList.filter(v => v.key !== '3')
        }
        return optionList
    }

    const trimmedDescription = data?.query_description?.length > 86 ?
        data?.query_description?.substring(0, 86 - 3) + "..." :
        data?.query_description ?? "";

    return (
        <div className="h-100 position-relative">
            <Card className='border-0' onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(data)
            }}>
                <CardBody>
                    <div className='data-view-dbox' onClick={() => { }}>
                        <div className='_src_img'>
                            <REPORT_ICON width='25' height='25' color='black' />
                            {/* <SourceTypeImage dataName={data.source_type}/> */}
                        </div>
                        <div className='desc-box'>
                            <div className="d-flex align-items-start">
                                <Tooltip placement='topLeft' title={data?.query_name}>
                                    <label style={{ width: 'calc(100% - 56px)' }} className='paragraph mt-1 title-txt font-w-600 text-with-ellipsis'>
                                        <Highlighter searchWords={searchData && searchData.length ? [searchData?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.query_name} />
                                        {/* {`${data.table_name}`} */}
                                    </label>
                                </Tooltip>
                                <Buttons
                                    props={{
                                        buttonText: '',
                                        tooltip: 'View POD',
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                                        ImgSrc: () => <REDIRECTJOBS />,
                                        buttonEvent: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            window.open(`${Literals.links.DATA_CATALOG_POD_DETAILS}${data?.table}#preview`)
                                        },
                                        isDisable: false,
                                        buttonType: Literals.BTN_TERTIARY
                                    }}
                                />
                                <Buttons
                                    props={{
                                        buttonText: '',
                                        tooltip: data?.bookmark
                                            ? Literals.REMOVE_FAVOURITES
                                            : Literals.ADD_FAVOURITES,
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-4",
                                        buttonEvent: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setbookmarkLoading(true)
                                            dispatch(bookmarkReport(data?.bookmark ? 'DELETE' : "GET", data?.id)).then((res) => {
                                                setbookmarkLoading(false)
                                                getReports();
                                            })
                                        },
                                        ImgSrc: () => bookmarkLoading ? <ICON_LOADING_CIRCLE/> :  data?.bookmark ? <FILLEDSTAR /> : <STAR />,
                                        isDisable: false,
                                        buttonType: Literals.BTN_TERTIARY
                                    }}
                                />

                                {/* <Buttons
                            props={{
                            buttonText: '',
                            tooltip: 'Chat GPT',
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1",
                            buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                // history(Literals.links.DATA_CATALOG_POD_DETAILS + data?.table_id + '#preview-search')
                            },
                            ImgSrc: () => <CHATGPT_ICON/>,
                            isDisable: false,
                            buttonType: Literals.BTN_TERTIARY
                            }}
                        /> */}


                                <Dropdown
                                    menu={{
                                        items: optionItems(),
                                        onClick: (e) => e.domEvent.stopPropagation()
                                    }}
                                    trigger={["hover"]}
                                    placement="bottomRight"
                                    onOpenChange={(bool) => setOptionOpen(bool)}
                                    overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                                    destroyPopupOnHide={true}
                                >
                                    <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                                </Dropdown>
                            </div>
                            <div>
                                <Tooltip placement='topLeft' title={data?.query_description}>
                                    <p className='paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                                        {data?.query_description?.length > 0 ?
                                            <Highlighter searchWords={searchData && searchData.length ? [searchData?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={trimmedDescription ?? ''} />

                                            : "No description has been given yet."}
                                    </p>
                                </Tooltip>
                            </div>
                            <div className='tile-meta-info'>
                                <div>
                                    <UPDATED_BY width="14" height="14" />
                                </div>
                                <div>
                                    <span className="label label-gray ml-1">{'Last Updated'} </span>
                                </div>
                                <div>
                                    <Tooltip placement="topLeft" title={getRelativeTime(data?.updated_on)}>
                                        <span className="paragraph">
                                            {/* <Highlighter searchWords={connectionContext.contextSearchValue && connectionContext.contextSearchValue.length ? [connectionContext.contextSearchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.updated_by_user?.email} /> */}
                                            {getRelativeTime(data?.updated_on)}
                                            {/* {data?.updated_by_user?.email} */}
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    <ICON_USER width="14" height="14" />
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className="label label-gray ml-1">{Literals.UPDATED_BY} </span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Tooltip placement="topLeft" title={data?.updated_by_user?.email}>
                                        <span className="paragraph d-block text-with-ellipsis" style={{ width: 134 }}>
                                            <Highlighter searchWords={searchData && searchData.length ? [searchData?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.updated_by_user?.email} />

                                            {/* {data?.updated_by_user?.email} */}
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                            {/* <ul className="landingCard-profile-list">
                        <li className="landingCard-profile-listitem">
                        <UPDATED_BY width="14" height="14" />
                        <span className="label label-gray ml-1">{'Last Updated'} </span>
                        <Tooltip placement="topLeft" title={getRelativeTime(data?.updated_on)}>
                            <span className="paragraph">
                            {getRelativeTime(data?.updated_on)}
                            </span>
                        </Tooltip>
                        </li>
                        <li className="landingCard-profile-listitem">
                        <ICON_USER width="14" height="14" />
                        <span className="label label-gray ml-1">{Literals.UPDATED_BY} </span>
                        <Tooltip placement="topLeft" title={data?.updated_by_user?.email}>
                            <span className="paragraph">
                            <Highlighter searchWords={searchData && searchData.length ? [searchData?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.updated_by_user?.email} />

                            </span>
                        </Tooltip>
                        </li>
                    </ul> */}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default ReportsGridView