import React, { useEffect, useState } from 'react'

import '../genAi.scss'
import ReportsGridView from './ReportsGridView'
import { Literals } from '../../common/literals'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { NoDataComponent, getRelativeTime, getReloadErrorTemplate } from '../../common/helperFunctions'
import NoSearchResultFound from '../../common/NoSearchResultFound'
import { getReportList } from '../../../../store/modules/common/GenAi/GenAiReducer'
import { ICON_REPORT } from '../../../Common/newIconSource'
import { resetAllDataGridTableFilters } from '../../../../store/modules/common/DataGridTable/dataGridTableRedux'
import { REPORT_ICON } from '../../../Common/iconSource'



const ReportsGridViewIndex = (props) => {
    let { activeDomain = '', searchTerm = '', loadingStatus, showFavourite } = props

    const history = useNavigate();
    const dispatch = useDispatch()
    const [empty, setEmpty] = useState(false);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [responseData, setresponseData] = useState([])
    const [errMsg, setErrorMsg] = useState("");
    useEffect(() => {
        if (activeDomain !== '') {

            getReports()
        }
    }, [activeDomain])


    React.useEffect(() => {
        dispatch(resetAllDataGridTableFilters())
    }, [])

    const getReports = () => {
        setLoading(true)
        setErrorMsg(undefined)

        let request = {
            published: true,
            data_domain_id: activeDomain
        }
        dispatch(getReportList(request))
            .then((res) => {
                setLoading(false)

                if (res.data?.length) {
                    setEmpty(false)

                    setData(res?.data ?? [])
                    setresponseData(res?.data ?? [])
                } else if (!res.data?.length) {
                    setEmpty(true)
                }
            })
            .catch((err) => {

                setErrorMsg(err?.message ?? 'Error!')
                setLoading(false)
            })
    }

    const filterData = () => {
        let oldObj = [...responseData]
        if (showFavourite && responseData?.length) {

            oldObj = oldObj.filter((ele) => ele?.bookmark === true);

        } else {


        }
        if (showFavourite) {
            return (oldObj)
        }

        else {
            return (responseData)
        }


    }
    useEffect(() => {
        if (filterData()?.length && searchTerm !== '') {

            let filteredData = filterData().filter((data) => {
                return (data?.query_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    data?.query_description?.toLowerCase().includes(searchTerm?.toLowerCase()) ||

                    getRelativeTime(data?.updated_on)?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    data?.updated_by_user?.email?.toLowerCase().includes(searchTerm?.toLowerCase()))

            })

            setData([...filteredData])
        } else {
            setData([...filterData()])
        }

    }, [searchTerm,showFavourite])



    const onTileClick = (record) => {
        console.log({ record })
        history(Literals.links.PULSE_REPORT_DETAIL_VIEW + record?.id)
        // fetchPreview()
    }

    return (
        <>
            {
                loading ? <ClaristaLoader height='200px' />
                    :
                    errMsg?.length > 0 && data?.length === 0 ? getReloadErrorTemplate({ errorMessage: errMsg, onReload: () => getReports(activeDomain) })
                        :
                        empty ? <NoDataComponent logo={<REPORT_ICON
                            width="60" height="60" color="black" />}
                            message="No data found, Please select another domain" />
                            :
                            (searchTerm?.length > 0 || showFavourite) && data?.length === 0 ? <NoSearchResultFound />
                                :
                                // <div className="flow-landing-content">
                                //     <ContentViewGridTable
                                //         colsNumber={2}
                                //         data={dataToView}
                                //         rowHeight={350}

                                //     />
                                // </div>
                                <div className='chat-gridview-responsive new-box-style p-0 position-relative'>
                                    {
                                        data?.map((item, index) => (
                                            <ReportsGridView
                                                key={`chat-${index}`}
                                                data={item}
                                                getReports={getReports}
                                                searchData={searchTerm}
                                                onClick={onTileClick}
                                            />
                                        ))
                                    }
                                </div>

            }

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loadingStatus: state.LoadingReducer.loadingStatus,
    };
};
export default connect(mapStateToProps)(ReportsGridViewIndex)
