import * as actionTypes from './actionTypes';

const initialState = {
    tenantMetadata: {
        accountIds: [],
        kibanaUrl: '',
        activeAccountIndex: 0
    },
    aggregatedCost: {
        dailyCost: 0,
        monthlyCost: 0,
        dailyAggregate: [],
        monthlyAggregate: [],
    },
    costTrends: {
        yearly: {
            data: [],
            year: ''
        },
        past30Days: {
            data: [],
            iteration: 3
        }
    },
    budgets: {
        listOfBudgets: []
    }
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ACCOUNT_IDS:
            state = {
                ...state,
            }
            state.tenantMetadata.accountIds = action.payload.accountIds
            break;
        case actionTypes.UPDATE_KIBANA_URL:
            state = {
                ...state,
            }
            state.tenantMetadata.kibanaUrl = action.payload.kibanaUrl
            break;
        case actionTypes.UPDATE_ACTIVE_ACCOUNT_INDEX:
            state = {
                ...state,
            }
            state.tenantMetadata.activeAccountIndex = action.payload.index
            break;
        case actionTypes.UPDATE_AGREEGATED_COST:
            state = {
                ...state,
            }
            state.aggregatedCost.dailyCost = action.payload.dailyCost
            state.aggregatedCost.dailyAggregate = action.payload.dailyAggregate
            state.aggregatedCost.monthlyCost = action.payload.monthlyCost
            state.aggregatedCost.monthlyAggregate = action.payload.monthlyAggregate
            break;
        case actionTypes.UPDATE_COST_TRENDS_YEARLY:
            state = {
                ...state,
            }
            state.costTrends.yearly.data = action.payload.data
            state.costTrends.yearly.year = action.payload.year
            break;
        case actionTypes.UPDATE_COST_TRENDS_PAST_30_DAYS:
            state = {
                ...state,
            }
            state.costTrends.past30Days.data = action.payload.data
            state.costTrends.past30Days.iteration = action.payload.iteration
            break;
        case actionTypes.UPDATE_BUDGET_LIST:
            state = {
                ...state,
            }
            state.budgets.listOfBudgets = action.payload.data
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;