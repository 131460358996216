import { Tree } from 'antd';
import React, { useEffect } from 'react'
import { ICON_CLOSE, ICON_DEAD, ICON_FILE, ICON_FOLDER, ICON_HOME, ICON_REFRESH, ICON_SEARCH, NOTEBOOK_ICON } from '../../../../Common/newIconSource';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { fetchFileSystem } from '../../../../../store/actions';

import { Literals } from '../../../common/literals';
import { getConfirmationModal, getObjectLength, getReloadErrorTemplate } from '../../../common/helperFunctions';

import _ from 'lodash';

import Highlighter from 'react-highlight-words';
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux';
import SearchInput from '../../../../Common/search/Search';
import Buttons from '../../../../Common/button/Buttons';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';
import { getDatabrickListWorkspace } from '../../../../../store/modules/flows/scriptTransformer/scriptAction';

const LoadExistingBricksNotebook = ({
    previewLoading = false,
    podData = {},
    isEditMode = false,
    isEditing = false,
    podRetrievedConnection,
    previewHandlerRef,
    hostValue1,
    createClusterConfig,
    setshowloadExisting,
    setshowDatabricksLandingPage,
    callListWorkspaceApi=false
}) => {

    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)

    const [error, setError] = React.useState(undefined)
    const [empty, setEmpty] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [treeData, setTreeData] = React.useState([...reduxData?.explorerTreeData])
    const [selectedKeys, setSelectedKeys] = React.useState([...reduxData?.selectedFolder]);
    const [expandedKeys, setExpandedKeys] = React.useState([...reduxData?.explorerExpandedKeys]);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [searchData, setSearchData] = React.useState('');
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [treeDisabled, setTreeDisabled] = React.useState(false);
    const [overlayLoading, setOverlayLoading] = React.useState(false);
    const [treeHeight, setTreeHeight] = React.useState(500); // For Virtual Scroll
    const [isTreeHeightCalculated, setTreeHeightCalculated] = React.useState(false); // For Virtual Scroll

    const currentSelectedInfo = React.useRef({});

    const dispatch = useDispatch()
    const store = useStore()

    const selectedConn = useSelector((state) => {
        return state.UserManagement.ConnectionReducer.selectedConn
    })

    const connName = selectedConn?.connection_name ?? ''

    useEffect(() => {
        console.log(callListWorkspaceApi)
        // alert(callListWorkspaceApi)
        // if (callListWorkspaceApi === true) {

            fetchAllData()
        // }
    }, [callListWorkspaceApi])

    React.useEffect(() => {
        if (treeData?.length > 0 && !isTreeHeightCalculated) {
            const el = document.querySelector('.exp-dir-wrap .ant-tree.ant-tree-directory')
            if (el?.scrollHeight) {
                setTreeHeight(el?.scrollHeight)
                setTreeHeightCalculated(true)
            }
        }
    }, [treeData, isTreeHeightCalculated])

    React.useEffect(() => {
        if (isEditMode) {
            setTreeDisabled(!isEditing || previewLoading)
        }
        else {
            setTreeDisabled(previewLoading)
        }
    }, [previewLoading, isEditing])

    const treeDataMemo = React.useMemo(() => {
        const loop = (data) =>
            data.map((item) => {
                const strTitle = item.title;
                const strTitleLower = item?.title?.toLowerCase();
                const index = strTitleLower.indexOf(searchData?.toLowerCase());
                const beforeStr = strTitle.substring(0, index);
                const afterStr = strTitle.slice(index + searchData.length);
                const title =
                    index > -1 ? (
                        <span title={strTitle}>
                            {
                                searchData === ''
                                    ? <>
                                        {beforeStr}
                                        <span className="site-tree-search-value">{searchData}</span>
                                        {afterStr}
                                    </>
                                    :
                                    <Highlighter
                                        searchWords={[searchData?.toLocaleLowerCase()]}
                                        autoEscape={true}
                                        textToHighlight={item?.name}
                                    >
                                        <span className="">{item?.name}</span>
                                    </Highlighter>
                            }
                            {/* {beforeStr}
                  <span className="site-tree-search-value">{searchData}</span>
                  {afterStr} */}
                        </span>
                    ) : (
                        <span title={strTitle}>{strTitle}</span>
                    );
                // if (item.children) {
                //   return {
                //       ...item,
                //     children: loop(item.children),
                //     name: strTitle,
                //     title
                //   };
                // }
                return {
                    ...item,
                    name: strTitle,
                    title
                };
            });

        const clone = [...treeData].filter(el => {
            if (el?.children) {
                return el?.title?.toLowerCase()?.includes(searchData?.toLowerCase()) || el?.children?.some(e => e?.title?.toLowerCase()?.includes(searchData?.toLowerCase()))
            }
            return el?.title?.toLowerCase()?.includes(searchData?.toLowerCase())
        })
        return loop(clone);
    }, [searchData, treeData]);

    const getFolderName = (path) => {
        let pathsplit = path.split('/');
        if (pathsplit?.length) {

            return pathsplit[pathsplit.length - 1]
        }
    }

    const fetchAllData = async () => {
        // let reduxData = store?.getState()?.DataCatalogue?.PodCreationData

        // if (reduxData?.explorerTreeData?.length > 0) return

        setLoading(true)


        const requestBody = {
            workspace_path: "/",
            databricks_host: hostValue1
        };
        dispatch(getDatabrickListWorkspace(requestBody))
            .then(res => {
                let folders = res?.data
                folders = folders.map(f => {
                    return {
                        title: getFolderName(f?.path),
                        name: getFolderName(f?.path),
                        key: `${getFolderName(f?.path)}`,
                        icon: f?.object_type?.toLowerCase() === 'notebook' ? <NOTEBOOK_ICON height='15' width='15' /> : <ICON_FOLDER height='14' width='14' />,
                        location: f?.path,
                        type: f?.object_type,
                        isLeaf: f?.object_type?.toLowerCase() === 'notebook' ? true : false,
                        selectable: true, //f?.object_type?.toLowerCase() === 'notebook' ? true : false,
                        checkable: f?.object_type?.toLowerCase() === 'notebook' ? true : false,

                    }
                })



                setEmpty(folders?.length === 0)
                setTreeData([...folders])

                // reduxData.explorerTreeData = [...folders]
                // setReduxData(reduxData)

                setLoading(false)
                // if (isEditMode) {
                //     if (connName !== podRetrievedConnection) return

                //     autoLoad(keysArr)
                // }
            })
            .catch(err => {
                setError(getReloadErrorTemplate(
                    {
                        errorMessage: err?.message,
                        onReload: fetchAllData
                    }
                ))
                setLoading(false)
            })
    }

    const autoLoad = async (keys) => {
        setOverlayLoading(true)
        const sourcePath = podData?.source_detail?.[0]
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            await onLoadData({ key })
        }
        onExpand(keys)
        onSelect([_.last(keys)], { node: { location: sourcePath, autoLoad: true } })
        setOverlayLoading(false)

        const { bucket, root_prefix } = podData?.source_info
        const sourceUrl = podData?.source_detail?.[0]
        const srcType = podData?.source_type
        const srcTypePrefix = srcType === 's3' ? 's3a://' : 'abfss://'
        let folderArr = sourceUrl.split(
            `${srcTypePrefix}${bucket}/${root_prefix}/`
        ).filter(v => v !== '')

        if (podRetrievedConnection !== connName) return

        previewHandlerRef?.current?.executePreview(folderArr[0])

    }

    const setReduxData = (data) => {
        dispatch(setPodCreationData(data))
    }

    const onSelect = (keys, info, fromModal = false) => {
        console.log(keys, info)

        if (treeDisabled) return
        let reduxData = {} //store?.getState()?.DataCatalogue?.PodCreationData
        // if (!info?.node?.autoLoad && !fromModal && getObjectLength(reduxData?.tableData?.result) > 0) {
        //     if (reduxData?.selectedFolder?.[0] === keys[0]) return
        //     currentSelectedInfo.current = { keys, info }
        //     setOpenConfirm(true)
        //     return
        // }
        if (info?.node?.type?.toLowerCase() === "directory") {
            // setSelectedKeys([...keys])

            onLoadData({
                key: keys.toString(), children: info?.node?.
                    children, ...info?.node
            })

        } else if (info?.node?.type?.toLowerCase() === "notebook") {

            setSelectedKeys([...keys]);
            // setshowloadExisting(false)
            setshowDatabricksLandingPage(false);

            createClusterConfig(info?.node)
        }

        // // reduxData.selectedFolder = [...keys]
        // // reduxData.folderPath = info?.node?.location
        // // reduxData.tableData.result = {}
        // setReduxData(reduxData)
    };

    const onExpand = (keys, info) => {
        console.log(keys, info)
        if (treeDisabled) return
        let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
        setExpandedKeys([...keys])

        reduxData.explorerExpandedKeys = [...keys]
        setReduxData(reduxData)
    };

    const onConfirmModal = () => {
        onSelect(currentSelectedInfo.current?.keys, currentSelectedInfo.current?.info, true)
    }

    const onCancelModal = () => {
        currentSelectedInfo.current = {}
    }

    // const onCheck = (checkedKeysValue) => {
    //     console.log('onCheck', checkedKeysValue);
    //     setCheckedKeys(checkedKeysValue);
    // };

    const onLoadData = ({ key, children, ...rest }) => {
        console.log(key, children, rest)
        // if(treeDisabled) return
        return new Promise((resolve) => {

            const parentName = key ?? rest?.name

            if (children) {
                resolve();
                return;
            }
            // console.log(rest)
            const requestBody = {
                workspace_path: rest.location,
                databricks_host: hostValue1
            }
            if (rest?.type && rest?.type?.toLowerCase() === 'directory') {
                // alert(rest.type)
                dispatch(getDatabrickListWorkspace(requestBody))
                    .then(res => {
                        // let reduxData = store?.getState()?.DataCatalogue?.PodCreationData

                        let folders = res?.data
                        // let objects = res?.data?.object
                        let temp = []
                        folders?.forEach(f => {
                            temp.push({
                                title: getFolderName(f?.path),
                                key: `${parentName}/${getFolderName(f?.path)}`,
                                path: `${parentName}/${getFolderName(f?.path)}`,
                                icon: f?.object_type?.toLowerCase() === 'notebook' ? <NOTEBOOK_ICON width='15' height='15' /> : <ICON_FOLDER height='14' width='14' />,
                                location: f?.path,
                                type: f?.object_type,
                                isLeaf: f?.object_type?.toLowerCase() === 'notebook' ? true : false,
                                selectable: true, //f?.object_type?.toLowerCase() === 'notebook' ? true : false,
                                checkable: f?.object_type?.toLowerCase() === 'notebook' ? true : false

                            })
                        })
                        // objects?.forEach(f => {
                        //     temp.push({
                        //         title: f?.name,
                        //         key: `${parentName}/${f?.name}`,
                        //         path: `${parentName}/${f?.name}`,
                        //         isLeaf: true,
                        //         disabled: true,
                        //         icon: <ICON_FILE height='14' width='14' />
                        //     })
                        // })
                        setTreeData((origin) => {
                            const newData = updateTreeData(origin, key, temp)

                            // reduxData.explorerTreeData = [...newData]
                            // setReduxData(newData)

                            return newData
                        });
                        resolve()
                    })
                    .catch(err => {

                    })
            }
        }
        )
    }

    return (
        <div className={`h-100 exp-dir-wrap ${isEditMode ? 'src-view' : ''}`}>
            {
                loading ? <ClaristaLoader />
                    :
                    error ? error
                        : empty
                            ? <div className='my-5 mx-auto w-50'>
                                <div className='alert alert-info text-center fontSizeHeading'>
                                    No Folders Found
                                </div>
                            </div>
                            :
                            <div className='position-relative'>
                                {
                                    overlayLoading ? <div className='d-block position-absolute h-100 w-100'
                                        style={{ top: 0, left: 0, zIndex: 2, background: '#ffffff6e' }}>
                                        <ClaristaLoader />
                                    </div>
                                        : ''
                                }
                                <div className='tree-header-pane d-flex align-items-center justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <ICON_HOME height='16' width='16' color='#2C83C4' />
                                        <label className='mb-0 color-primary ml-2 fontInterSemiBold fontSizeHeading'>Root</label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {
                                            openSearch
                                                ? <SearchInput
                                                    searchData={searchData}
                                                    setSearchData={setSearchData}
                                                    searchBoxOpen={false}
                                                />
                                                : ''
                                        }
                                        <Buttons
                                            props={{
                                                tooltip: openSearch ? "Close Search" : "Open Search",
                                                buttonText: "",
                                                tooltipPlacement: "right",
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                                                buttonEvent: () => {
                                                    setSearchData('')
                                                    setOpenSearch(!openSearch)
                                                },
                                                ImgSrc: () => openSearch ? <ICON_CLOSE /> : <ICON_SEARCH />,
                                                isDisable: false,
                                                buttonType: Literals.BTN_TERTIARY,
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    treeDataMemo.length === 0 ? <div className='text-center text-black-50 p-3 fontSizeHeading'>No Data Found</div>
                                        :
                                        <Tree.DirectoryTree

                                            // selectable={!treeDisabled}
                                            expandAction={!treeDisabled}
                                            selectedKeys={selectedKeys}
                                            expandedKeys={expandedKeys}
                                            // onCheck={onCheck}
                                            onSelect={onSelect}
                                            onExpand={onExpand}
                                            treeData={treeDataMemo}
                                            loadData={onLoadData}
                                            // checkedKeys={checkedKeys}
                                            disabled={treeDisabled}
                                            height={treeHeight}
                                        />
                                }
                                {/* {
                                treeDisabled ? <div className='position-absolute d-block h-100 w-100'
                                style={{top: 0, left: 0, zIndex: 2, background: '#ffffff6e', cursor: 'not-allowed'}}
                                >
                                </div>
                                :   ''
                            } */}
                            </div>
            }
            {
                getConfirmationModal({
                    open: openConfirm,
                    setOpen: setOpenConfirm,
                    body: 'This will reset the preview, are you sure want to continue?',
                    onConfirm: onConfirmModal,
                    onCancel: onCancelModal
                })
            }
        </div>
    )
}

export default LoadExistingBricksNotebook

const updateTreeData = (list, key, children) =>
    list.map((node) => {
        if (node.key === key) {
            return {
                ...node,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });