import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import _, { cloneDeep, sortBy } from 'lodash';
import { useNavigate } from "react-router-dom";


import { CRAWLER, PLUS, SCHEDULER, TAG } from "../iconSource";

import {
    getRelativeTime,
    NoDataComponent,
    ErrorComponent,
    getDeleteMessage,
    getReloadErrorTemplate
} from "../../modules/common/helperFunctions";
import { Collapse, Tooltip } from "antd";
import DeleteModal from "../deleteModal/DeleteModal";
import { GridCard, SchedulersGrid } from "../../modules/scheduler/components/SchedulersGrid";
import LoadingComponent from "../../modules/common/loadingComponent";
import { Literals } from "../../modules/common/literals";
import Buttons from "../button/Buttons";
import { setLoadingStatus } from "../../../store/modules/common/loadingActions";
import { getDomainTags, setSidebarActiveRoute, setTagList } from "../../../store/actions";
import { DeleteScheduler, getSchedulerLandingPage } from "../../../store/modules/scheduler/action";
import NoSearchResultFound from "../../modules/common/NoSearchResultFound";
import ClaristaLoader from "../claristaLoader/ClaristaLoader";
import { setSelectedPodList } from "../../../store/modules/common/podSelectionModalAction";
import { RESET_SCHEDULER_REDUX_ } from "../../../store/modules/scheduler/SchedulerActionType";
import { getGlobalConnectionCache } from "../../../store/modules/UserManagement/connection/connectionActions";
import { SET_FLOWS_ALERT_TRANSFORMER_LIST } from "../../../store/modules/alert/createAlert/createAlertActiontype";



function SchedulerGridListIndex(props) {
    const history = useNavigate();

    let { fromRoute = "scheduler", loadingStatus, selectedSchedulerFilter = '',
        searchTerm = '', activeDomain = '', setDataLoading = () => { }, groupByTags = true } = props;

    const [Selectedid, setshowSelectedCard] = useState(null);
    const [isButtonActive, setIsButtonActive] = useState(false);

    const currentSchedulerIdRef = React.useRef({ current: {} })

    const [errMsg, setErrMsg] = useState("");
    const [activeTab, setActiveTab] = useState("all");
    const [empty, setEmpty] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [schedulerData, setSchedulerData] = useState([]);
    const [schedulerOriginalData, setschedulerOriginalData] = useState([]);
    const [loading, setLoading] = React.useState(false)
    const [activeCollKeys, setActiveCollKeys] = React.useState([])

    const selectedTempScheduler = useSelector(state => state.Scheduler.selectedTempScheduler)

    const tagData = useSelector(state => state.DataCatalogue?.LandingPage?.tagList)

    useEffect(() => {

        if (selectedTempScheduler?.id) {
            setshowSelectedCard(selectedTempScheduler?.id)
        } else {
            setshowSelectedCard(null)
        }
    }, [selectedTempScheduler])

    useEffect(() => {
        if (fromRoute !== 'schedulerModal') {
            dispatch({ type: RESET_SCHEDULER_REDUX_, payload: {} })
            dispatch(setSelectedPodList([]));
            dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} })
        }

    }, [])


    const openDeleteDialog = (id) => {

        currentSchedulerIdRef.current = id

        setOpenDeletePopup(true);
    };

    const handleDelete = id => {
        dispatch(DeleteScheduler(id)).then((resp) => {
            if (resp.status === "success") {
                getSchedulerList();
                setOpenDeletePopup(false);
            }
        })
            .catch(() => { })
    }

    useEffect(() => {
        dispatch(getGlobalConnectionCache())
    }, [])

    const dispatch = useDispatch();

    const getSchedulerList = () => {
        setLoading(true)
        setDataLoading(true)
        let prevData = [...schedulerOriginalData]
        setschedulerOriginalData([])
        setSchedulerData([])
        dispatch(getSchedulerLandingPage(activeDomain)).then((resp) => {
            setErrMsg('')

            if (resp.data.length == 0) {
                setEmpty(true);

            }
            if (resp && resp.data?.length) {
                setEmpty(false);



                // setSchedulerData(resp.data);

                let clonedata = cloneDeep(resp?.data);
                clonedata.forEach(element => {
                    element['scheduler_status'] = element.is_active ? 'active' : 'inactive'
                });


                setschedulerOriginalData(clonedata);
            }
            else {
                dispatch(setLoadingStatus(false));

                // setSchedulerData([]);
                setschedulerOriginalData([]);
            }
            setLoading(false)
            setDataLoading(false)

        }).catch((e) => {
            setLoadingStatus(false);
            setLoading(false)
            setDataLoading(false)
            setschedulerOriginalData([...prevData])
            setErrMsg(e.message)
        })
    };
    useEffect(() => {
        if (activeDomain !== "") {
            getSchedulerList();
            callTagListAPI()
        }

    }, [activeDomain]);

    useEffect(() => {
        let filteredData = [...schedulerOriginalData]

        filteredData = filteredData?.map((d, i) => ({
            ...d,
            tag: d?.tag?.filter(t => t?.data_domain?.toString() === activeDomain?.toString()) ?? []

        }))

        if (selectedSchedulerFilter !== '') {
            filteredData = filteredData?.filter((data) => {
                return (
                    data?.scheduler_status?.toLowerCase() === selectedSchedulerFilter?.trim().toLowerCase()
                )
            })??[]
        }

        if (filteredData?.length && searchTerm !== "") {
            filteredData = filteredData.filter((data) => {

                return (
                    data?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    data?.description
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                    getRelativeTime(data?.created_on)
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                    data?.created_by_user?.email
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                    getRelativeTime(data?.updated_on)
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                    data?.updated_by_user?.email
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase())
                );
            });


            setSchedulerData([...filteredData]);
            setLoading(false)

        } else if (filteredData?.length) {
            setSchedulerData(filteredData);
            setLoading(false)

        }else{
            setSchedulerData([]);
        }

        
    }, [searchTerm, schedulerOriginalData, selectedSchedulerFilter]);


    const callTagListAPI = () => {
        // if (tagData?.length > 0) return

        dispatch(getDomainTags(activeDomain))
            .then(res => {
                const data = res?.data ?? []
                dispatch(setTagList([...data]))
            })
            .catch(() => { })
    };

    const dataListObj = React.useMemo(() => {

        if(
            _.isEmpty(searchTerm)
            && _.isEmpty(selectedSchedulerFilter)
        ) {
            setActiveCollKeys([])
        }

        const dat = [...schedulerData]

        let tags = new Set([...dat?.map(d => d?.tag)?.flat()])
        // let tags = _.uniqBy([...dat?.map(d => d?.tag)?.flat()], 'name')
        let tagWiseSech = [...tags]?.reduce((prev, curr) => {
            return {
                ...prev,
                [curr?.name]: {
                    data: dat?.filter(d => d?.tag?.map(t => t?.name)?.includes(curr?.name)),
                    tagDetail: { ...curr }
                }
            }
        }, {})

        let tagsSech = []
        for (const key in tagWiseSech) {
            const element = tagWiseSech[key];
            tagsSech.push({
                title: key,
                tagDetail: element?.tagDetail,
                data: element?.data
            })
        }

        tagsSech = sortBy(tagsSech, [o => o?.title])

        if(
            !_.isEmpty(searchTerm) || !_.isEmpty(selectedSchedulerFilter)
        ) {
            setActiveCollKeys(tagsSech?.map(d => d?.title))
        }

        return { tagsSech, allData: dat }
    }, [schedulerData, selectedSchedulerFilter])

    // console.log({dataListObj})

    //vh-100
    return (
        <>

            <div className="scheduler-wrapper">
                <div className="landingPage scheduler-pg">
                    <div className="options-wrapper">
                        {fromRoute.toLocaleLowerCase() !== 'schedulermodal' ? <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName: `custom-btn-primary custom-btn fixed-button addbutton_plusicon ${isButtonActive ? "rotate-icon" : ""
                                    }`,
                                buttonEvent: () => {
                                    history(Literals.links.CREATESCHEDULER_ALERTFLOWS)
                                    // setIsButtonActive((isButtonActive) => !isButtonActive);
                                },
                                tooltip: "Add",
                                tooltipPlacement: "left",
                                ImgSrc: () => <PLUS />,
                                isDisable: false,
                                buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                            }}
                        /> : null}
                        {/* <ul
                            className={`add-options-list ${isButtonActive ? "active-list" : ""}`}
                        >
                            <Tooltip placement="left" title={`Flows & Alert`}>
                                <li onClick={() => history(Literals.links.CREATESCHEDULER_ALERTFLOWS)}>
                                  
                                    <SCHEDULER height="20" width="20" />
                                </li>
                            </Tooltip>
                            <Tooltip placement="left" title="Crawler">
                                <li onClick={() => history(Literals.links.CREATESCHEDULER_CRAWLER)}>
                                    <CRAWLER height="20" width="20" />
                                </li>
                            </Tooltip>
                        </ul> */}
                    </div></div>

                {
                    loading ? <ClaristaLoader height='200px' />
                        :
                        errMsg?.length > 0 ? getReloadErrorTemplate({ errorMessage: errMsg, onReload: () => getSchedulerList(activeDomain) })
                            :
                            empty ? <NoDataComponent logo={<SCHEDULER
                                width="60" height="60" color="black" />}
                                message=" Click On (+) To Create Scheduler" />
                                :
                               ( searchTerm?.length > 0 || selectedSchedulerFilter !== '') && schedulerData?.length === 0  ? <NoSearchResultFound />
                                    :
                                    groupByTags
                                        ?
                                        <>
                                            {
                                                dataListObj?.allData?.length === 0 ? ''
                                                    :
                                                    <Collapse
                                                        key={`catalog-grid-collapse-${activeCollKeys?.length}`}
                                                        className='catalog-grid-collapse'
                                                        collapsible='header'
                                                        ghost={true}
                                                        // defaultActiveKey={
                                                        //     tagData?.map(t => t?.name)
                                                        //     // dataListObj?.tagsSech?.map(t => t?.title)
                                                        // }
                                                        // activeKey={dataListObj?.tagsPod?.map(t => t?.title)}
                                                        defaultActiveKey={activeCollKeys}
                                                        items={
                                                            dataListObj?.tagsSech?.map(t => ({
                                                                key: t?.title,
                                                                label: <>
                                                                    <div className='round-tags-container nav-landing'>
                                                                        <div className={`${t?.tagDetail?.color} round-tags-item`}>
                                                                            <span>{<TAG />}</span>
                                                                            <span>{t?.title}</span>
                                                                        </div>
                                                                    </div>
                                                                </>,
                                                                children:
                                                                    <div className="scheduler-gridview-responsive new-box-style">
                                                                        {
                                                                            t?.data?.map((d, i) => (
                                                                                <GridCard
                                                                                    key={`sech-${i}`}
                                                                                    Selectedid={Selectedid}
                                                                                    item={d}
                                                                                    openDeleteDialog={openDeleteDialog}
                                                                                    getSchedulerList={getSchedulerList}
                                                                                    fromRoute={fromRoute}
                                                                                    searchData={searchTerm}
                                                                                    setData={setschedulerOriginalData}
                                                                                    activeDomain={activeDomain}
                                                                                />
                                                                            ))
                                                                        }
                                                                    </div>
                                                            }))
                                                        }
                                                    />
                                            }
                                            {
                                                dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.length !== 0
                                                    ?
                                                    <div className="px-2">
                                                        <div className='bg-light px-3 py-2 fontInterSemiBold rounded border mb-2 fontSizeHeading'>
                                                            {`Untagged`}
                                                        </div>
                                                        {/* style={fromRoute.toLocaleLowerCase() === 'schedulermodal' ? { height: "calc(100vh - 332px)",overflow:"auto" } : {}} */}
                                                        <div className="scheduler-gridview-responsive new-box-style">
                                                            {
                                                                dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.map((d, i) => (
                                                                    <GridCard
                                                                        key={`untag-sech-${i}`}
                                                                        Selectedid={Selectedid}
                                                                        item={d}
                                                                        openDeleteDialog={openDeleteDialog}
                                                                        getSchedulerList={getSchedulerList}
                                                                        fromRoute={fromRoute}
                                                                        searchData={searchTerm}
                                                                        setData={setschedulerOriginalData}
                                                                        activeDomain={activeDomain}
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                    : ''
                                            }
                                        </>
                                        :
                                        <SchedulersGrid
                                            openDeleteDialog={openDeleteDialog}
                                            schedulerData={schedulerData}
                                            getSchedulerList={getSchedulerList}
                                            searchData={searchTerm}
                                            fromRoute={fromRoute}
                                            Selectedid={Selectedid}
                                            setData={setschedulerOriginalData}
                                            loadingStatus={loadingStatus}
                                            activeDomain={activeDomain}
                                        />


                }

                {/* <div className="" style={{ height: 'calc(100% - 72px)', overflow: 'auto' }}>
                    {errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> : activeTab === "all" && schedulerData.length ?
                        <SchedulersGrid
                            openDeleteDialog={openDeleteDialog}
                            schedulerData={schedulerData}
                            getSchedulerList={getSchedulerList}
                            searchData={searchTerm}
                            fromRoute={fromRoute}
                            Selectedid={Selectedid}

                            loadingStatus={loadingStatus}
                        />
                        : empty ?
                            <NoDataComponent logo={<SCHEDULER width="60" height="60" color="black" />}
                                message=" Click On (+) To Create Schedule" /> : (
                                !loadingStatus && (searchTerm !== '') ?
                                    <NoSearchResultFound mainMsg={searchTerm === '' ? 'No Scheduler List Found' : ''} subMsg={'Please try Search / Filter with another term'} /> :
                                    null
                            )
                    }
                </div>
                {props.loadingStatus ? <LoadingComponent /> : null} */}
            </div>
            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={getDeleteMessage({
                    title: 'Scheduler'
                })}
                onConfirm={() => handleDelete(currentSchedulerIdRef?.current)}
            />

        </>
    );
};
const mapStateToProps = (state) => {
    return {
        loadingStatus: state.LoadingReducer.loadingStatus,
    };
};
export default connect(mapStateToProps)(SchedulerGridListIndex)