import React, { useEffect, useRef, useState } from "react";

import { Menu } from "antd";

// const NavigationItem=({icon ,key, title,index,to , onRouteChangeHandler,activeRoute,collapsed})=>{

//       let isActive = false;

//     if (to === activeRoute) {
//         isActive = true;
//     }
//     else {
//         isActive = false;
//     }
//  return (
//   <div  style={collapsed?{paddingLeft:"0px"}:{}} className={`new-navigation-link  ${isActive ? "new-navigation-activelink" : ""} `} >
//   <Menu.Item
//            onClick={()=>{
//               onRouteChangeHandler(to);
//             }}
//           className={`new-navigation-item `}
//           // key={`subItem_${index}`}
//           key={key}

//           style={collapsed?{paddingLeft:"10"}:{}}

//         >

//       <span style={collapsed?{padding:"0 5px"}:{}} className={`new-navigation-icon  `}>{icon}</span>

//           {title}

//       </Menu.Item>
//     </div>
//   )
// }

const App = ({ collapsed, list, onRouteChange, activeRoute }) => {
  const [openKeys, setOpenKeys] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState("");

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    // if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //   setOpenKeys(keys);
    // } else {
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    //}
  };

  useEffect(() => {
    let activeIndex = null;
    let selectedIndex = null;
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      for (let j = 0; j < item?.children?.length; j++) {
        let child = item.children[j];
        if (child.to == activeRoute) {
          activeIndex = item.key;
          selectedIndex = child.key;
        }
      }
      if (item.to == activeRoute) {
        activeIndex = item.key;
        selectedIndex = item.key;
      }
    }

    setSelectedIndex([selectedIndex]);
    if (!collapsed) {
      setOpenKeys([activeIndex]);
    }
  }, [collapsed, activeRoute]);

  return (
    <Menu
      key="menu_1"
      className="new-sidebar-menu"
      mode="inline"
      openKeys={openKeys ?? []}
      theme="dark"
      onOpenChange={onOpenChange}
      selectedKeys={selectedIndex}
      onClick={(item) => {
        setOpenKeys([item.key]);

        onRouteChange(item.item.props.to);
      }}
      inlineCollapsed={collapsed}
      style={{ backgroundColor: "#17171A" }}
      items={list}
    />
  );
};

export default App;

// <Menu key="menu_1" mode="inline" openKeys={openKeys} theme="dark"
// onOpenChange={onOpenChange}

// inlineCollapsed={collapsed} style={{backgroundColor:"#17171A"}}>
//   {
//       list.map((item,index)=>{
//    return <Menu.SubMenu
//      key={item.newKey}
//      className={item.to?"home-link":""}
//      onTitleClick={()=>{
//       if(item.to)
//         onRouteChangeHandler(item.to);
//      }}
//      title={<b >{item.title.toUpperCase()}</b>}
//      icon={  <span className={`new-navigation-icon ${currentMenuIndex==index && collapsed?'active-menu':''}`} style={{fontWeight:"bold"}}>{selectedIndex==index?<span style={{padding:"0 5px"}}>{item.activeIcon}</span>: item.icon}</span>}
//      >

//       {
//       collapsed?
//       <Menu.Item
//       key={`${item.title}_${index}`}
//       onClick={()=>{
//         if(item.to)
//         onRouteChangeHandler(item.to);
//       }}
//         style={{backgroundColor:"#17171A",textAlign:"start",fontWeight:"bold", fontSize:"10px" , margin:"0", cursor:"auto"}}> {item.title.toUpperCase()}</Menu.Item>:""
//       }
//         {item?.children?.map(

//           (child,childIndex)=>

//             <NavigationItem key={child.newKey} to={child.to} collapsed={collapsed} icon={child.icon} title={child.title} index={childIndex} activeRoute={activeRoute} onRouteChangeHandler={onRouteChangeHandler}/>

//           )}

//         </Menu.SubMenu>
//       })
//   }

// </Menu>
