const SET_SELECTED_POD_LIST = "SET_SELECTED_POD_LIST"

export const setSelectedPodList = (data) => {

    return {
        type: SET_SELECTED_POD_LIST,
        payload: data
    };
};


const initialState = {
    podList: []
}
export const PodSelectionModalReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case SET_SELECTED_POD_LIST:
            return { ...state, podList: payload }
        default:
            return state;
    }
}