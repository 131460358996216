import { Checkbox } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { createTenantEmail, getTenantEmail } from '../../../../../store/modules/UserManagement/users/usersActions'
import { ICON_ALERT, ICON_CHECK, ICON_LOADING_CIRCLE } from '../../../../Common/newIconSource'

const SMTPEmailClientForm = React.forwardRef(({
    fieldValues = {},
    setFieldValues = () => {},
    setisActiveToggle=()=>{},
    loading = false,
    setLoading = () => {},
    saving = false,
    setSaving = () => {}
}, ref) => {

    const dispatch = useDispatch()

    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showError, setShowError] = React.useState(false)

    React.useImperativeHandle(ref, () => {
        return {
            save: () => {
                handleSave()
            }
        }
    }, [fieldValues])

    React.useEffect(() => {
        setFieldValues(prev => (
            {
                ...prev,
                smtp: {
                    host: '',
                    is_primary: true,
                    password: '',
                    is_active:false,
                    port: '',
                    sender_email: '',
                    sender_name: '',
                    use_tls: false,
                    username: ''
                }
            }
        ))
        getDetails()
    }, [])
    // console.log({fieldValues})
    const getDetails = () => {
        setLoading(true)
        dispatch(getTenantEmail())
        .then(res => {
            let data = res?.data ?? {}
            let el = data;
            setFieldValues(prev => (
                {
                    ...prev,
                    smtp: {
                        host: el?.host,
                        is_primary: el?.is_primary,
                        password: el?.password ?? '',
                        is_active:true,
                        port: el?.port,
                        sender_email: el?.sender_email,
                        sender_name: el?.sender_name,
                        use_tls: el?.use_tls,
                        username: el?.username
                    }
                }
            ))

            setLoading(false)
        })
        .catch(err => {
            setLoading(false)
        })
    }

    const handleSave = () => {
        setShowError(false)
        setShowSuccess(false)
        setSaving(true)
        let values = fieldValues?.smtp
        let payload = {
            ...values
        }
        setTimeout(() => {
            dispatch(createTenantEmail(payload))
            .then(res => {
                setSaving(false)
                setShowSuccess(true)
                setTimeout(() => {
                    setShowSuccess(false)
                }, 5000)
            })
            .catch(err => {
                setSaving(false)
                setShowError(true)
                setTimeout(() => {
                    setShowError(false)
                }, 5000)
            })
        }, 5000)
    }

    const onInputHandler = (key, value) => {
        setFieldValues(prev => {
            return {
                ...prev,
                smtp: {
                    ...prev?.smtp,
                    [key]: value
                }
            }
        })
    }

    return (
        <div>
            <div className='row'>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Host
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="text"
                        value={fieldValues?.smtp?.host ?? ''}
                        onChange={(e) => {
                            onInputHandler('host', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Port
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="text"
                        value={fieldValues?.smtp?.port ?? ''}
                        onChange={(e) => {
                            onInputHandler('port', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Username
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="text"
                        value={fieldValues?.smtp?.username ?? ''}
                        onChange={(e) => {
                            onInputHandler('username', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Sender Name
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="text"
                        value={fieldValues?.smtp?.sender_name}
                        onChange={(e) => {
                            onInputHandler('sender_name', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Sender Email
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="email"
                        value={fieldValues?.smtp?.sender_email}
                        onChange={(e) => {
                            onInputHandler('sender_email', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Password
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="password"
                        value={fieldValues?.smtp?.password}
                        onChange={(e) => {
                            onInputHandler('password', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                <div className="col-6 form-group mb-0">
                    <div className="d-flex align-items-center mt-1">
                        <Checkbox
                            checked={fieldValues?.smtp?.use_tls}
                            onChange={(e) => {
                                onInputHandler('use_tls', e?.target?.checked)
                            }}
                            disabled={saving}
                        />
                        <label className="label d-flex align-items-center mb-0 ml-2 text-muted">Use TLS</label>
                    </div>
                </div>
                {/* <div className="col-6 form-group mb-0">
                    <div className="d-flex align-items-center mt-1">
                        <Checkbox
                            checked={fieldValues?.smtp?.is_primary}
                            onChange={(e) => {
                                onInputHandler('is_primary', e?.target?.checked)
                            }}
                            disabled={saving}
                        />
                        <label className="label d-flex align-items-center mb-0 ml-2 text-muted">Is Primary</label>
                    </div>
                </div> */}
                {
                    showSuccess
                    ?
                    <div className='col-12 mt-2'>
                        <div className='bg-light border border-success fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-success'>
                            <span className='mr-2'><ICON_CHECK width='16' height='16' color='currentColor' /></span>
                            <span>
                                Succesfully created SMTP Client
                            </span>
                        </div>
                    </div>
                    :   ''
                }
                {
                    saving
                    ?
                    <div className='col-12 mt-2'>
                        <div className='bg-light border border-primary fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-primary'>
                            <span className='mr-2'><ICON_LOADING_CIRCLE width='16' height='16' color='currentColor' /></span>
                            <span>
                                Creating SMTP Client
                            </span>
                        </div>
                    </div>
                    :   ''
                }
                {
                    showError
                    ?
                    <div className='col-12 mt-2'>
                        <div className='bg-light border border-danger fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-danger'>
                            <span className='mr-2'><ICON_ALERT width='16' height='16' color='currentColor' /></span>
                            <span>
                                Failed to create SMTP Client
                            </span>
                        </div>
                    </div>
                    :   ''
                }
            </div>
        </div>
    )
})

export default SMTPEmailClientForm