import React, {Fragment, useEffect, useRef, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { EDITOR } from '../../../Common/iconSource'
import { EDIT, CLOSE } from "../../../Common/iconSource";
import {convertToRaw, EditorState,ContentState,} from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import Buttons from "../../../Common/button/Buttons";
import { Literals } from "../../common/literals";

import {CustomEditor} from '../../dataCatalogue/summary/components/TextEditor';
import { ICON_EDITBOX, ICON_NOTE, ICON_PLUS } from '../../../Common/newIconSource';
export const NotesView = ({fieldValues,setFieldValues, isEdit}) => {
  

  const [editorState,setEditorState]=useState( );

 const editorHtml=useRef(null);
  const onEditorStateChange = (editorState) => {
  let temp=draftToHtml(convertToRaw(editorState?.getCurrentContent()));
  editorHtml.current=temp;
    setFieldValues(prev=>{
        return {
            ...prev,
            notes: temp
        }
    }) ;
                
  setEditorState(editorState)
  };




  const createMarkup=()=>{
    return { __html:editorHtml.current?? fieldValues }
  }

  useEffect(()=>{

    if(fieldValues){
        let contentBlock = htmlToDraft(fieldValues);
        const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
        setEditorState( EditorState.createWithContent(contentState))
    }
  },[fieldValues])

  const isEditorEmpty = React.useMemo(() => (
    fieldValues === null || fieldValues === undefined
    ||
    fieldValues.trim() === '' 
    || fieldValues.trim() === "<p></p>"
    || fieldValues.trim() === "<h1></h1>"
    || fieldValues.trim() === "<h2></h2>"
    || fieldValues.trim() === "<h3></h3>"
    || fieldValues.trim() === "<h4></h4>"
    || fieldValues.trim() === "<h5></h5>"
    || fieldValues.trim() === "<small></small>"
    || fieldValues.trim() === "<strong></strong>"
    || fieldValues.trim() === "<em></em>"
    || fieldValues.trim() === "<blockquote></blockquote>"
    || fieldValues.trim() === "<code></code>"
  ), [fieldValues])

    return (
        <div>
            <Card className={`card custom-card p-0 shadow-none ${isEditorEmpty ? 'border-0' : ''}`}>
              {
                // isEdit
                // ?
                  isEdit && isEditorEmpty ? '' :
                  <CardHeader className='padding-2 dict-header-bg border-0'>
                      <div className='d-flex align-items-center w-100' style={{justifyContent:"space-between"}}>
                          <div className="d-flex align-items-center">
                              <ICON_NOTE height='16' width='16' color='#0A3053' />
                              <label className='mb-0 d-flex align-items-center section-heading-dark font-weight-bold ml-2'>
                                  Notes
                              </label>
                          </div>
                      </div>
                  </CardHeader>
                // : ''
              }
                <CardBody className={`padding-2 ${!isEdit ? 'dict-header-bg pt-0' : ''}`}>
                    <div className={`notes-section bg-white ${!isEdit ? 'p-2' : 'p-0'}`}>
                    {

                      !isEdit ?  
                        <CustomEditor
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                        />
                      : <div className="p-1" dangerouslySetInnerHTML={createMarkup()} />

                    }   
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}