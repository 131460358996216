import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid from "react-data-grid";
import './DndVirtualList.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableRowRenderer } from './DraggableRowRenderer';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';
import { useDispatch } from 'react-redux';
import { showFlowsLogsLowerSection, showFlowsLowerSection } from '../../../../../../store/modules/flows/NewFlowsRedux/flowsActions';
import { pxToRem, remToPx } from '../../../../common/helperFunctions';


export const MultipleColsDndList = ({
  rawData = [],
  rowsList = [],
  setreOrderRender = () => { },
  setData = () => { },
  colsList = [],
  rowHeight = 40,
  headerRowHeight=40,
  stopDraggingRow = false,
  overflowXHidden = false
}) => {
  const [columns, setColumns] = useState([...colsList])
  const [rows, setRows] = useState([...rowsList])
  const [raw, setRaw] = useState([...rawData])
  const dispatch = useDispatch()

  useEffect(() => {
    setColumns([...colsList])
  }, [colsList])

  useEffect(() => {
    setRows([...rowsList])
  }, [rowsList])

  useEffect(() => {
    setRaw([...rawData])
  }, [rawData])

  const draggableColumns = useMemo(() => {

    const HeaderRenderer = (props) => {

      return (
        <DraggableHeaderRenderer
          {...props}
          onColumnsReorder={handleColumnsReorder}
        />
      );
    };


    // column re-ordering
    const handleColumnsReorder = (sourceKey, targetKey) => {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);

      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
    };

    return columns.map((c) => ({ ...c, headerRenderer: HeaderRenderer }));
  }, [columns]);

  const renderRow = useCallback((key, props) => {

    function onRowReorder(fromIndex, toIndex, isdrop, isDragging) {
      setreOrderRender(false)
      dispatch(showFlowsLogsLowerSection(false));
      dispatch(showFlowsLowerSection(false));

      if (isdrop && !isDragging) {
        setRows((rows) => {
          const newRows = [...rows];
          newRows.splice(toIndex, 0, newRows.splice(fromIndex, 1)[0]);
          return [...newRows];
        });
        setRaw((raw) => {
          const newRaw = [...raw];
          newRaw.splice(toIndex, 0, newRaw.splice(fromIndex, 1)[0]);

          setData([...newRaw])
          return newRaw;
        });
      }

      setTimeout(() => {
        setreOrderRender(true);
      }, 50);

    }

    return <DraggableRowRenderer key={key} {...props} onRowReorder={(fromIndex, toIndex, isdrop, isDragging) => !stopDraggingRow ? onRowReorder(fromIndex, toIndex, isdrop, isDragging) : null} />;
  }, [stopDraggingRow]);

  return <>
    <DndProvider backend={HTML5Backend}>
      <div className={`flows-multicol h-100`}>
        <DataGrid
          className={`h-100 custom-vlist-dgt ${overflowXHidden ? 'overflow-x-hidden' : ''}`}
          columns={draggableColumns}
          rows={rows}
          defaultColumnOptions={{
            sortable: false,
            resizable: false,
          }}
          noRowsFallback={<div className='p-2 text-black-50 text-center'>No Records Found</div>}
          rowHeight={remToPx(pxToRem(rowHeight))}
          headerRowHeight={remToPx(pxToRem(headerRowHeight))}
          onCellClick={({ row, ...rest }, index, e) => {  }}
          renderers={{ renderRow }}
        />
      </div>
    </DndProvider>
  </>;
};