import React from 'react'
import { ICON_BAG, ICON_CATALOG } from '../../../Common/newIconSource'
import MainLandingPage from '../../../Common/mainLandingPage'
import NavigatorIndex from '../../../Common/claristaNewLandingPage/NavigatorIndex'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetDictionaryRedux, resetSummaryReduxState, setSidebarActiveRoute } from '../../../../store/actions'
import { Literals } from '../../common/literals'
import { resetPreviewTableState } from '../../../../store/modules/dataCatalogue/preview/previewActions'
import { setConnectionDefault } from '../../../../store/modules/UserManagement/connection/connectionActions'
import { resetAllDataGridTableFilters } from '../../../../store/modules/common/DataGridTable/dataGridTableRedux'

const CatalogLandingPage = () => {

    const location = useLocation()
    const [defaultTabKey, setDefaultTabKey] = React.useState('pod')

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(setSidebarActiveRoute(Literals.links.CLARISTA_NAVIGATOR))

        dispatch(resetPreviewTableState());
        dispatch(resetDictionaryRedux());
        dispatch(resetSummaryReduxState());
        dispatch(setConnectionDefault());
        dispatch(resetAllDataGridTableFilters());

    }, [])

    React.useEffect(() => {
        if (location?.hash) {
            let hashParam = location.hash?.replace('#', '')
            if(hashParam) {
                setDefaultTabKey(hashParam)
            }           
        }
    }, [location])

    const tabsMenuList = [
        {
            key: 'pod',
            label: <span> <ICON_CATALOG /> POD </span>
        },
        {
            key: 'business',
            label: <span> <ICON_BAG /> Business Glossary </span>
        }
    ]

    const Component = React.useMemo(() => {
        return <MainLandingPage
                    page='navigator'
                    tabsMenuList={tabsMenuList}
                    IndexComponent={(props) => <NavigatorIndex {...props} />}
                    searchPlaceholder='Search in navigator...'
                    defaultTabKey={defaultTabKey}
                />
    }, [defaultTabKey])

    return Component
}
export default CatalogLandingPage