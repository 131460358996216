import toastr from "toastr";
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import { setTransformerLoadingStatus } from "../flowsActions";
import { RESET_SCRIPT_REDUX, SET_DATABRICKS_NOTEBOOK_URL, SET_JUPYTER_NOTEBOOK_URL, SET_OUTPUT_DATASET, SET_BRICKS_OUTPUT_DATASET, SET_SAVE_NOTEBOOK_DONE, SET_SCRIPT_COMMON_PAYLOAD, SET_SCRIPT_PYTHON_CODE } from "./scriptActionTypes";
import { emitToastNotification } from "../../../../helpers/toast_helper";

const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const setNotebookUrl = (data) => {
    return {
        type: SET_JUPYTER_NOTEBOOK_URL,
        payload: data
    };

}

export const setDatabrickNBURL = (data) => {
    console.log(data)
    return {
        type: SET_DATABRICKS_NOTEBOOK_URL,
        payload: data
    };

}


export const setScriptOutputDataset = (data) => {
    return {
        type: SET_OUTPUT_DATASET,
        payload: data
    };
}

export const setBricksOutputDataset = (data) => {
    return {
        type: SET_BRICKS_OUTPUT_DATASET,
        payload: data
    };
}


export const setScriptPythonCode = (data) => {
    return {
        type: SET_SCRIPT_PYTHON_CODE,
        payload: data
    };
}
export const setScriptCommonPaylod = (data) => {
    return {
        type: SET_SCRIPT_COMMON_PAYLOAD,
        payload: data
    };
}

export const resetScriptRedux = (data) => {
    return {
        type: RESET_SCRIPT_REDUX,
        payload: data
    };
}

export const saveNoteBookDone = (data) => {
    return {
        type: SET_SAVE_NOTEBOOK_DONE,
        payload: data
    };
}
export const LoginNoteBook = (id, payload) => {

    return (dispatch, getState) => {

        dispatch(setNotebookUrl(''))
        let temp = getState().FlowsReducer.executedTransformerPayload;



        let edges = getState().FlowsReducer.edges
        let newPayload = {
            "dag": {
                nodes: temp ? temp : [],
                edges: edges ? edges : [],

            },
            flow_body: payload['flow_body']
        }

        //document.cookie = `jupyterhub-hub-login=`;
        // document.cookie = `jupyterhub-session-id=`
        dispatch(setLoadingStatus(true));
        dispatch(setTransformerLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/${id}/jupyter_server/`, newPayload)
            ).then((resp) => {
                dispatch(setTransformerLoadingStatus(false));

                dispatch(setLoadingStatus(false));
                if (resp.status === false) {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {

                    // let splitDomain = BASE_URL?.split('.');
                    // let lastDomain = splitDomain[splitDomain?.length - 1]
                    // if (splitDomain[splitDomain?.length - 1].includes('/')) {
                    //     lastDomain = lastDomain.replace('/', '')
                    // }

                    // let domainName = `${splitDomain[splitDomain?.length - 2]}.${lastDomain}`


                    // if (resp && resp.data['jupyterhub-hub-login']) {



                    //     document.cookie = `jupyterhub-hub-login=${resp.data['jupyterhub-hub-login']};domain=${domainName}`;
                    //     document.cookie = `jupyterhub-session-id= ${resp.data['jupyterhub-session-id']};domain=${domainName}`
                    //     document.cookie = `jupyterhub-hub-login=${resp.data['jupyterhub-hub-login']}`;
                    //     document.cookie = `jupyterhub-session-id= ${resp.data['jupyterhub-session-id']}`
                    //     document.cookie = `_xsrf= ${resp.data['_xsrf']};domain=${domainName}`
                    //     document.cookie = `_xsrf=${resp.data['_xsrf']}`;
                        
                    // }
                    if (resp && resp.data && resp.data.script_url !== '') {
                        dispatch(setNotebookUrl(resp.data.script_url))
                    }
                    resolve(resp);
                }
            })
                .catch((err) => {
                    reject(err)
                    dispatch(setLoadingStatus(false));
                    dispatch(setTransformerLoadingStatus(false));

                });
        });
    };
}

export const CreateNotebook = (id, payload) => {

    return (dispatch, getState) => {

        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/${id}/create_notebook/`, payload)
            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                if (resp.status === false) {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                });
        });
    };
}

export const saveJupyterNotebookURL = (id, payload, JupyterNotebookUrl, apiType) => {
    let newURL = JupyterNotebookUrl.replace('/notebooks/', '/api/contents/')
    return (dispatch, getState) => {
        dispatch(setLoadingStatus(true));
        dispatch(setTransformerLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData(apiType, `${newURL}`, payload, '', true)

            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                dispatch(setTransformerLoadingStatus(false));

                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {

                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setTransformerLoadingStatus(false));
                    dispatch(saveNoteBookDone(false))

                    dispatch(setLoadingStatus(false));
                });
        });
    };
}

export const saveJupyterNotebook = (id, payload) => {

    return (dispatch, getState) => {
        dispatch(setLoadingStatus(true));
        dispatch(setTransformerLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/${id}/update_script_code/`, payload)

            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                dispatch(setTransformerLoadingStatus(false));

                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {

                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setTransformerLoadingStatus(false));
                    dispatch(saveNoteBookDone(false))

                    dispatch(setLoadingStatus(false));
                });
        });
    };
}

export const logoutJupyterNotebook = (id, payload) => {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `https://dev.stellar-data.com/jupyterhub/hub/logout`)

            ).then((resp) => {


                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    dispatch(saveNoteBookDone(true))
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(saveNoteBookDone(false))

                });
        });
    };
}

export const LoginDatabricksNoteBook = (id, payload) => {

    return (dispatch, getState) => {

        dispatch(setNotebookUrl(''))
        let temp = getState().FlowsReducer.executedTransformerPayload;



        let edges = getState().FlowsReducer.edges
        let newPayload = {
            "dag": {
                nodes: temp ? temp : [],
                edges: edges ? edges : [],

            },
            flow_body: payload['flow_body']
        }

        //document.cookie = `jupyterhub-hub-login=`;
        // document.cookie = `jupyterhub-session-id=`
        dispatch(setLoadingStatus(true));
        dispatch(setTransformerLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/${id}/get_existing_databricks_notebook_url/`, newPayload)
            ).then((resp) => {
                dispatch(setTransformerLoadingStatus(false));

                dispatch(setLoadingStatus(false));
                if (resp.status === false) {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {





                    if (resp && resp.data && resp?.data?.nb_url !== '') {
                        dispatch(setDatabrickNBURL(resp.data.nb_url))
                    }
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                    dispatch(setTransformerLoadingStatus(false));

                });
        });
    };
}

export const CreateDataBricksNotebook = (id, payload) => {

    return (dispatch, getState) => {

        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/${id}/create_databricks_notebook/`, payload)
            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                if (resp.status === false) {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                });
        });
    };
}

export const getBricksNotebookURL = (id, payload) => {

    return (dispatch, getState) => {
   
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/${id}/get_existing_databricks_notebook_url/`, payload)
            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                if (resp.status === false) {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    resolve(resp);
                }
            })
                .catch((err) => {
                    emitToastNotification('error',err.message)

                    dispatch(setLoadingStatus(false));
                });
        });
    };
}


export const LoadDataBricksNotebook = (id, payload) => {

    return (dispatch, getState) => {

        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/${id}/load_existing_databricks_notebook/`, payload)
            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                if (resp.status === false) {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                });
        });
    };
}


export const ConnectionDataBricksSave = (payload) => {

    return (dispatch, getState) => {

        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}databricks/create_pat_mapping`, payload)
            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                if (resp.status === false) {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                    reject(err)

                });
        });
    };
}
export const saveDatabricksNotebook = (id, payload) => {

    return (dispatch, getState) => {
        dispatch(setLoadingStatus(true));
        dispatch(setTransformerLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/${id}/get_databricks_script_code/`, payload)

            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                dispatch(setTransformerLoadingStatus(false));

                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    dispatch(setScriptPythonCode({ code: resp.data.content, id: payload.flow_body.Id, path: resp.data.path }));


                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setTransformerLoadingStatus(false));
                    dispatch(saveNoteBookDone(false))

                    dispatch(setLoadingStatus(false));
                });
        });
    };
}

export const getDatabricksNotebookCode = (id, payload) => {

    return (dispatch, getState) => {
        // dispatch(setLoadingStatus(true));
        dispatch(setTransformerLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/${id}/get_databricks_script_code/`, payload)

            ).then((resp) => {
                dispatch(setLoadingStatus(false));
                dispatch(setTransformerLoadingStatus(false));

                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    // dispatch(setScriptPythonCode({ code: resp.data.content, id: payload.flow_body.Id, path: resp.data.path }));


                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(setTransformerLoadingStatus(false));
                    dispatch(saveNoteBookDone(false))

                    dispatch(setLoadingStatus(false));
                });
        });
    };
}
export const logoutDatabricksNotebook = (id, payload) => {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `https://dev.stellar-data.com/jupyterhub/hub/logout`)

            ).then((resp) => {


                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    dispatch(saveNoteBookDone(true))
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(saveNoteBookDone(false))

                });
        });
    };
}


export const getDatabrickClusters = (payload) => {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}databricks/list_cluster`, payload)

            ).then((resp) => {


                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    dispatch(saveNoteBookDone(true))
                    resolve(resp);
                }
            })
                .catch((err) => {
                    console.log(err)
                    reject(err)
                    dispatch(saveNoteBookDone(false))

                });
        });
    };
}

export const getBricksNotebookCode = (id, payload) => {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}flow/ ${id}/ get_databricks_script_code /`, payload)

            ).then((resp) => {


                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    dispatch(saveNoteBookDone(true))
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(saveNoteBookDone(false))

                });
        });
    };
}

export const getDatabrickListWorkspace = (payload) => {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}databricks/list_workspace`, payload)

            ).then((resp) => {


                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    dispatch(saveNoteBookDone(true))
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(saveNoteBookDone(false))

                });
        });
    };
}


export const updateClusterEnv = (payload) => {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}databricks/update_cluster_env`, payload)

            ).then((resp) => {


                if (resp.status !== 'success') {
                    reject(resp.message);
                    emitToastNotification('error',resp.message)
                } else {
                    dispatch(saveNoteBookDone(true))
                    resolve(resp);
                }
            })
                .catch((err) => {
                    dispatch(saveNoteBookDone(false))

                });
        });
    };
}

