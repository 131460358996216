import React from 'react'
import ReactJoyride from 'react-joyride'

const Walkthrough = ({
    walkthrough = { run: false, steps: [] },
    callback = () => {},
    disableOverlayClose = false,
    showProgress = false,
    showSkipButton = true,
    spotlightClicks = false,
    disableScrollParentFix = false,
    continuous = true,
    stepIndex = undefined,
}) => {
    return (
        <ReactJoyride
            stepIndex={stepIndex}
            continuous={continuous}
            callback={callback}
            run={walkthrough.run}
            steps={walkthrough.steps}
            disableOverlayClose={disableOverlayClose}
            hideCloseButton
            scrollToFirstStep
            disableScrollParentFix={disableScrollParentFix}
            showSkipButton={showSkipButton}
            showProgress={showProgress}
            disableScrolling={false}
            spotlightClicks={spotlightClicks}
            styles={{
                buttonNext: {
                    backgroundColor: '#2c83c4',
                    padding: '8px 10px'
                },
                buttonBack: {
                    color: '#2c83c4'
                },
                tooltipTitle: {
                    fontFamily: 'InterSemibold',
                    fontSize: '16px'
                },
                // beaconOuter: {
                //     backgroundColor: '#155e9417',
                //     border: '2px solid #2c83c4'
                // },
                // beaconInner: {
                //     backgroundColor: '#2c83c4'
                // }
            }}
        />
    )
}

export default Walkthrough