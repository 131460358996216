import React from "react";
import { Tree } from "antd";

import {
  SET_CREATEPOD_DEFAULT,
  SET_CREATEPOD_PAGE_DEFAULT,
  SET_CREATEPODFOLDER_SETSELECTEDFILES,
  SET_CONN_LIST,
  SET_UPLOADED_FILE_LIST,
  SET_ROW_SELECTED_INDEX,
  SET_CONNECTION_NAME2,
  SET_FILE_PROGRESS,
  SET_CREATEPOD_PROCEEDS,
  SET_ACTIVE_TAB_PUBLISH,
  SET_KEY,
  TOGGLE_NEW_CONNECTION,
  SET_NEWCONN_DATABASELIST,
  SET_CONNECTION_NAME,
  SET_CREATEPODTREE_MENUITEMS,
  SET_CREATEPOD_TABSARRAY,
  SET_CREATEPOD_PUBLISHPOD,
  SET_CREATEPOD_CUSTOMTABS,
  SET_CREATEPOD_SOURCETYPE,
  SET_CREATEPOD_TREEDATA,
  SET_PREVIEW_DATA,
  SET_DELIMETER,
  SET_HEADER,
  SET_CREATE_POD_TABLE_DATA_MAP,
  SET_ACTIVE_CREATE_POD_NAME,
  SET_GENERATE_QUERY,
  SET_QUERY,
  RESET_CREATE_POD_DATA,
  SET_CREATE_POD_USER_INPUT_DATA_MAP,
  SET_ACTIVE_POD_DATA_KEY,
  SET_INITIAL_TREE_DATA,
  SET_VIRTUAL_TABLES_DATA_MAP,
  SET_INITIAL_EXPLORE_TAB_DATA,
  SET_POD_TABS_LIST,
  SET_PREVIEW_TABLE_DATA,
  SET_TREE_DATA_SET,
  SET_INITIAL_SCHEMA_DATA,
  SET_FILE_NEXT_TOKEN,
  SET_IS_POD_UPDATE_VIEW,
  SET_POD_UPDATE_LEFT_SIDE_DATA,
  SET_POD_UPDATE_RIGHT_SIDE_DATA,
  SET_POD_UPDATE_TREE_DATA,
  SET_POD_RIGHT_SIDE_DATA,
  SET_POD_LEFT_SIDE_DATA,
  RESET_POD_RIGHT_SIDE_DATA,
  RESET_POD_LEFT_SIDE_DATA,
  RESET_VIRTUAL_TABLES_DATA_MAP,
  SET_UPLOAD_FILE_PROGRESS,
} from "./createPODActionTypes";

const initialState = {
  pageNavigateTo: "",
  newConnFlag: false,
  newConnDbList: {},
  connName: "",
  menuItems: ["Import as default"],
  tabsArray: [],
  publishPOD: [],
  source_type: "",
  treeData: [],
  selectedFiles: [],
  previewData: [],
  delimeter: [],
  fileUploadProgress: 0,
  header: [],
  activeTabPublish: "",
  key: "",
  connList: {},
  rowSelectedIndex: "",
  connName2: "",
  initialTreeData: [],
  initialExploreTabData: [],
  populateIntitalSchemaTable: false,

  //Managing Tabs
  podTabsList: [
    {
      key: "1",
      tabTitle: "Sample Pod Name 1",
      closable: false,
      tabContent: null,
    },
  ],
  activePodTabDataKey: "1",
  treeDataSet: [],
  podUserInputDataMap: [
    {
      key: "1",
      podLeftSide: {
        podTab: {
          activeTabKey: "1",
          podDetails: {
            podName: "",
            podDescription: "",
            dataDomain: "",
          },
          isGenerateQueryBtnVisible: false,
          treeData: [],
          treeDataJSX: (props) => {
            return <Tree {...props} />;
          },
          fileExplorer: {
            format: "CSV",
            delimeter: ",",
            containsHeader: false,
          },
        },
        connectionTab: {},
      },
      podRightSide: {
        activeTabKey: "1",
        activePODKey: "1",
        virtualTableTabs: [
          {
            key: "1",
            tabTitle: "Results",
            closable: false,
            tabContent: null,
            schemaName: "results",
          },
        ],
        virtualTableData: {},
        query: "",
        queryCreatingResults: "",
        generateQuery: false,
        isSchemaTableVisible: false,
        schemaTableData: [],
        schemaTableDataPayload: [],

        //Explore Tab
        activeExploreLocation: "root",
        exploreNavigationTabs: [{ name: "root", key: "root" }],
        exploreTabData: {
          root: [],
        },
        exploreTabSelectedFolderKey: "",
        exploreTabSelectedFiles: [],
        explorerTableTabs: [
          {
            key: "1",
            tabTitle: "Preview",
            closable: false,
            tabContent: null,
          },
        ],
      },
    },
  ],
  virtualTablesDataMap: {
    1: {
      results: {},
      activeCreatePodName: "Results",
    },
  },
  previewTableData: {},
  createPodTablesDataMap: {
    results: {},
  },
  activeCreatePodName: "Results",
  generateQuery: false,
  query: "",
  nextToken: "",
  isPodUpdateView: false,
  podUpdateLeftSideData: {},
  podUpdateRightSideData: {},
  podUpdateTreeData: [],
  podLeftSideData: {
    podDetails: {
      podName: "",
      podDescription: "",
      dataDomain: "",
    },
    isGenerateQueryBtnVisible: false,
    treeData: [],
    treeDataJSX: (props) => {
      return <Tree {...props} />;
    },
    fileExplorer: {
      format: "",
      delimeter: ",",
      containsHeader: false,
      headerCount: 1,
      escapechar: "",
      quotechar: "",
      skipfooter: "",
      skiprows: ""
    },
    selectedTreeNodes: []
  },
  podRightSideData: {
    activePODKey: "1",
    virtualTableTabs: [
      {
        key: "1",
        tabTitle: "Results",
        closable: false,
        tabContent: null,
        schemaName: "results",
      },
    ],
    virtualTableData: {},
    query: "",
    queryCreatingResults: "",
    generateQuery: false,
    isSchemaTableVisible: false,
    schemaTableData: [],
    schemaTableDataPayload: [],

    //Explore Tab
    activeExploreLocation: "root",
    exploreNavigationTabs: [{ name: "root", key: "root" }],
    exploreTabData: {
      root: [],
    },
    exploreTabSelectedFolderKey: "",
    exploreTabSelectedFiles: [],
    explorerTableTabs: [
      {
        key: "1",
        tabTitle: "Preview",
        closable: false,
        tabContent: null,
      },
    ],
    sourceType: "",
  },

  uploadedFileList: []
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CREATEPOD_PROCEEDS:
      return {
        ...state,
        pageNavigateTo: payload,
      };
    case SET_UPLOADED_FILE_LIST:
      return {
        ...state,
        uploadedFileList: payload,
      };

    case SET_ACTIVE_TAB_PUBLISH:
      return {
        ...state,
        activeTabPublish: payload,
      };
    case SET_ROW_SELECTED_INDEX:
      return {
        ...state,
        rowSelectedIndex: payload,
      };

    case SET_KEY:
      return {
        ...state,
        key: payload,
      };

    case TOGGLE_NEW_CONNECTION:
      return {
        ...state,
        newConnFlag: payload,
      };

    case SET_NEWCONN_DATABASELIST:
      return {
        ...state,
        newConnDbList: payload,
      };

    case SET_CONNECTION_NAME:
      return {
        ...state,
        connName: payload,
      };
    case SET_CONNECTION_NAME2:
      return {
        ...state,
        connName2: payload,
      };

    case SET_CREATEPODTREE_MENUITEMS:
      return {
        ...state,
        menuItems: [...payload],
      };

    case SET_CREATEPOD_TABSARRAY:
      return {
        ...state,
        tabsArray: [payload, ...state.tabsArray],
      };

    case SET_CREATEPOD_CUSTOMTABS:
      return {
        ...state,
        tabsArray: [...payload],
      };

    case SET_CREATEPOD_PUBLISHPOD:
      return {
        ...state,
        tabsArray: [...payload],
      };

    case SET_CREATEPOD_SOURCETYPE:
      return {
        ...state,
        source_type: payload,
      };

    case SET_FILE_PROGRESS:
      return {
        ...state,
        fileProgress: payload,
      };

    case SET_UPLOAD_FILE_PROGRESS:
      return {
        ...state,
        fileUploadProgress: payload,
      };


    case SET_CREATEPOD_TREEDATA:
      return {
        ...state,
        treeData: [...payload],
      };

    case SET_CREATEPODFOLDER_SETSELECTEDFILES:
      return {
        ...state,
        selectedFiles: [...payload],
      };

    case SET_CREATEPOD_DEFAULT:
      return {
        ...state,
        pageNavigateTo: "",
        newConnFlag: false,
        newConnDbList: {},

        menuItems: ["Import as default"],
        tabsArray: [],
        publishPOD: [],
        source_type: "",
        treeData: [],
        selectedFiles: [],
        previewData: [],
        delimeter: [],
        header: [],
        activeTabPublish: "",
        key: "",
      };

    case SET_CREATEPOD_PAGE_DEFAULT:
      return initialState;

    case SET_PREVIEW_DATA:
      return {
        ...state,
        //previewData : [...payload],
        previewData: payload,
      };
    case SET_DELIMETER:
      return {
        ...state,
        delimeter: [...payload],
      };
    case SET_HEADER:
      return {
        ...state,
        header: [...payload],
      };
    case SET_CONN_LIST:
      return {
        ...state,
        connList: payload,
      };

    case SET_CREATE_POD_TABLE_DATA_MAP:
      return {
        ...state,
        createPodTablesDataMap: action.payload,
      };

    case SET_ACTIVE_CREATE_POD_NAME:
      return {
        ...state,
        activeCreatePodName: action.payload,
      };

    case SET_GENERATE_QUERY:
      return {
        ...state,
        generateQuery: action.payload,
      };

    case SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case SET_CREATE_POD_USER_INPUT_DATA_MAP:
      return {
        ...state,
        podUserInputDataMap: action.payload,
      };

    case SET_ACTIVE_POD_DATA_KEY:
      return {
        ...state,
        activePodTabDataKey: action.payload,
      };

    case SET_INITIAL_TREE_DATA:
      return {
        ...state,
        initialTreeData: action.payload,
      };

    case RESET_CREATE_POD_DATA:
      return {
        ...state,
        initialTreeData: [],
        initialExploreTabData: [],

        podTabsList: [
          {
            key: "1",
            tabTitle: "Sample Pod Name 1",
            closable: true,
            tabContent: null,
          },
        ],
        activePodTabDataKey: "1",
        treeDataSet: [],
        podUserInputDataMap: [
          {
            key: "1",
            podLeftSide: {
              podTab: {
                activeTabKey: "1",
                podDetails: {
                  podName: "",
                  podDescription: "",
                  dataDomain: "",
                },
                isGenerateQueryBtnVisible: false,
                treeData: [],
                treeDataJSX: (props) => {
                  return <Tree {...props} />;
                },
                fileExplorer: {
                  format: "CSV",
                  delimeter: ",",
                  containsHeader: false,
                  headerCount: 1,
                  escapechar: '',
                  quotechar: '',
                  skiprows: '',
                  skipfooter: ''
                },
              },
              connectionTab: {},
            },
            podRightSide: {
              activeTabKey: "1",
              activePODKey: "1",
              virtualTableTabs: [
                {
                  key: "1",
                  tabTitle: "Results",
                  closable: false,
                  tabContent: null,
                  schemaName: "results",
                },
              ],
              virtualTableData: {},
              query: "",
              queryCreatingResults: "",
              generateQuery: false,
              isSchemaTableVisible: false,
              schemaTableData: [],
              schemaTableDataPayload: [],

              //Explore Tab
              activeExploreLocation: "root",
              exploreNavigationTabs: [{ name: "root", key: "root" }],
              exploreTabData: {
                root: [],
              },
              exploreTabSelectedFolderKey: "",
              exploreTabSelectedFiles: [],
              explorerTableTabs: [
                {
                  key: "1",
                  tabTitle: "Preview",
                  closable: false,
                  tabContent: null,
                },
              ],
            },
          },
        ],
        virtualTablesDataMap: {
          1: {
            results: {},
            activeCreatePodName: "Results",
          },
        },
      };

    case SET_VIRTUAL_TABLES_DATA_MAP:
      return {
        ...state,
        virtualTablesDataMap: { ...action.payload },
      };

    case SET_INITIAL_EXPLORE_TAB_DATA:
      return {
        ...state,
        initialExploreTabData: action.payload,
      };

    case SET_POD_TABS_LIST:
      return {
        ...state,
        podTabsList: action.payload,
      };

    case SET_PREVIEW_TABLE_DATA:
      return {
        ...state,
        previewTableData: action.payload,
      };

    case SET_TREE_DATA_SET:
      return {
        ...state,
        treeDataSet: action.payload,
      };

    case SET_INITIAL_SCHEMA_DATA:
      return {
        ...state,
        populateIntitalSchemaTable: action.payload,
      };

    case SET_FILE_NEXT_TOKEN:
      return {
        ...state,
        nextToken: action.payload,
      };

    case SET_IS_POD_UPDATE_VIEW:
      return {
        ...state,
        isPodUpdateView: action.payload,
      };

    case SET_POD_UPDATE_LEFT_SIDE_DATA:
      return {
        ...state,
        podUpdateLeftSideData: action.payload,
      };

    case SET_POD_UPDATE_RIGHT_SIDE_DATA:
      return {
        ...state,
        podUpdateRightSideData: action.payload,
      };

    case SET_POD_UPDATE_TREE_DATA:
      return {
        ...state,
        podUpdateTreeData: action.payload,
      };

    case SET_POD_LEFT_SIDE_DATA:
      return {
        ...state,
        podLeftSideData: action.payload,
      };

    case SET_POD_RIGHT_SIDE_DATA:
      return {
        ...state,
        podRightSideData: action.payload,
      };

    case RESET_VIRTUAL_TABLES_DATA_MAP:
      return {
        ...state,
        virtualTablesDataMap: initialState.virtualTablesDataMap,
      };

    case RESET_POD_LEFT_SIDE_DATA:
      return {
        ...state,
        podLeftSideData: {
          podDetails: {
            podName: "",
            podDescription: "",
            dataDomain: "",
          },
          isGenerateQueryBtnVisible: false,
          treeData: [],
          treeDataJSX: (props) => {
            return <Tree {...props} />;
          },
          fileExplorer: {
            format: "",
            delimeter: ",",
            containsHeader: false,
            headerCount: 1,
            escapechar: "",
            quotechar: "",
            skipfooter: "",
            skiprows: ""
          },
          selectedTreeNodes: []
        },
      };

    case RESET_POD_RIGHT_SIDE_DATA:
      return {
        ...state,
        podRightSideData: {
          activePODKey: "1",
          virtualTableTabs: [
            {
              key: "1",
              tabTitle: "Results",
              closable: false,
              tabContent: null,
              schemaName: "results",
            },
          ],
          virtualTableData: {},
          query: "",
          queryCreatingResults: "",
          generateQuery: false,
          isSchemaTableVisible: false,
          schemaTableData: [],
          schemaTableDataPayload: [],

          //Explore Tab
          activeExploreLocation: "root",
          exploreNavigationTabs: [{ name: "root", key: "root" }],
          exploreTabData: {
            root: [],
          },
          exploreTabSelectedFolderKey: "",
          exploreTabSelectedFiles: [],
          explorerTableTabs: [
            {
              key: "1",
              tabTitle: "Preview",
              closable: false,
              tabContent: null,
            },
          ],
          sourceType: "",
        },
      };
    default:
      return state;
  }
};

export default reducer;
