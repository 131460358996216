import { Tooltip } from 'antd';
import React from 'react'

function PodTaglist({ removeTags, tagList }) {


    return (<React.Fragment>
        {tagList.map((tag, index) => <div key={index} className="podTag">
            <Tooltip placement="topLeft" title={tag?.podName?.toUpperCase()}><span className="">{`${tag?.podName?.slice(0, 10)?.toUpperCase()}...`}</span></Tooltip>
            <i className='bx bx-x' onClick={() => { removeTags(tag.podId) }}></i>
        </div>)
        }
    </React.Fragment>

    )
}

export default PodTaglist
