import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setSidebarVisibilty } from "../store/layout/actions"

const useHideSidebar = (hide = true) => {
    const dispatch = useDispatch();
    const { isSidebarVisible } = useSelector(state => state.Layout);

    useEffect(() => {
        dispatch(setSidebarVisibilty(!hide));

        return () => {
            dispatch(setSidebarVisibilty(true));
        }
    },[]); 

}

export default useHideSidebar
