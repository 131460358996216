import React, { useEffect, useState } from "react";

import { Tooltip } from "antd";

import { Literals } from "../../common/literals";

import { useSelector } from "react-redux";
import Accordian from "../../../Common/accordion/Accordian";
import Buttons from "../../../Common/button/Buttons";
import { ACCORDIONDOWNARROW, DELETEICON, EDIT, FLOWTABICON, PLUS } from "../../../Common/iconSource";

import { getRelativeTime } from "../../common/helperFunctions";
import SourceTypeImage from "../../common/sourceTypeImage";
import { AddFlowDefaultView } from "./AddFlowDefaultView";
import FlowSelectionModal from "./FlowSelectionModal";
import SelectPODInfography from "./SelectPODInfography";
import { LandingSimpleTable } from "../../../Common/landingSimpleTable/LandingSimpleTable";
import _ from "lodash";
import { useLocation } from "react-router";

const ListofPodDetails = ({ podList, showLogs, readOnly, toggleModalHandler, onDeletePod, conditionType = "filter" }) => {

  const [data, setdata] = useState([]);
  const [, setlistData] = useState([]);
  const location = useLocation()
  let list = [];
  const selectedPodList = useSelector(
    (state) => state.Alert?.CreateAlert?.selectedAlertPodList
  );
  const [openFlowSelectionModal, setopenFlowSelectionModal] = useState(false)
  const flowsAlertTransformerList = useSelector(state => state.Alert.CreateAlert?.flowsAlertTransformerList)




  useEffect(() => {


    if (selectedPodList && selectedPodList.length) {
      for (let i = 0; i < selectedPodList.length; i++) {
        let domainName = _.uniq(selectedPodList[i]?.data_domain?.map((v) => v.data_domain_name));
        list.push({
          allData: selectedPodList,
          data: { ...selectedPodList[i], domain_name_for_sort: domainName && domainName.length ? domainName?.join(",") : "" },
          key: selectedPodList[i].podId,
          POD_Name: podNameFunction(selectedPodList[i].pod_name, [
            "grey#abc",
            "purple#tag3",
          ]),
          description: (

            selectedPodList[i].description !== "" ? <Tooltip placement="topLeft" title={selectedPodList[i].description}>
              {selectedPodList[i].description}
            </Tooltip> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px" }}>No description has been given yet.</span></label>
          ),
          sources: <SourceTypeImage dataName={selectedPodList[i]?.source} />,
          dataDomain: (
            <Tooltip placement="topLeft" title={selectedPodList[i].dataDomain?.toUpperCase()}>
              {selectedPodList[i].dataDomain?.toUpperCase()}
            </Tooltip>
          ),
          updatedOn: (
            <Tooltip
              placement="topLeft"
              title={getRelativeTime(selectedPodList[i].updatedOn)}
            >
              {getRelativeTime(selectedPodList[i].updatedOn)}
            </Tooltip>
          ),
          updatedBy: (
            <Tooltip placement="topLeft" title={selectedPodList[i].updatedBy?.email}>
              {selectedPodList[i].updatedBy?.email}
            </Tooltip>
          ),
          action:
            <div className="d-flex h-100 align-items-center">
              <Buttons
                props={{
                  tooltip: "Remove Pod",
                  buttonText: "",
                  buttonClassName: "mr-1 custom-btn custom-btn-outline btn-with-icon error-red",
                  buttonEvent: (e) => {
                    e.stopPropagation();
                    onDeletePod(selectedPodList[i].podId)
                  },
                  ImgSrc: () => <DELETEICON />,
                  isDisable: window.location.href.includes("viewAlert"),
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                }}
              />
            </div>
        });
      }


      setdata([...list]);

      setlistData([...selectedPodList]);
    }


  }, [selectedPodList, location]);

  useEffect(() => {
    if (readOnly) {

      if (podList && podList.length) {
        if (podList !== undefined) {
          for (let i = 0; i < podList.length; i++) {
            let domainName = _.uniq(podList[i]?.data_domain?.map((v) => v.data_domain_name));
            list.push({
              allData: podList,
              data: { ...podList[i], domain_name_for_sort: domainName && domainName.length ? domainName?.join(",") : "" },
              key: podList[i].table_id,
              POD_Name: podNameFunction(podList[i]?.table_name, [
                "grey#abc",
                "purple#tag3",
              ]),
              description: (
                podList[i].description !== "" ? <Tooltip placement="topLeft" title={podList[i].description}>
                  {podList[i].description}
                </Tooltip> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px" }}>No description has been given yet.</span></label>
              ),
              sources: <SourceTypeImage dataName={podList[i]?.source_type} />,
              dataDomain: (
                <Tooltip placement="topLeft" title={podList[i]?.data_domain_name?.toUpperCase()}>
                  {podList[i]?.data_domain_name?.toUpperCase()}
                </Tooltip>
              ),
              updatedOn: (
                <Tooltip
                  placement="topLeft"
                  title={getRelativeTime(podList[i]?.updated_on)}
                >
                  {getRelativeTime(podList[i]?.updated_on)}
                </Tooltip>
              ),
              updatedBy: (
                <Tooltip
                  placement="topLeft"
                  title={podList[i]?.updated_by_user?.username}
                >
                  {podList[i]?.updated_by_user?.username}
                </Tooltip>
              ),
              action:
                <Buttons
                  props={{
                    tooltip: "Remove Pod",
                    buttonText: "Remove Pod",
                    buttonClassName: !window.location.href.includes("viewAlert") ?
                      " mr-1 cursor-pointer custom-btn-outline custom-btn btn-with-icon error-red" : '',
                    buttonEvent: (e) => {
                      e.stopPropagation();
                      onDeletePod(selectedPodList[i].podId)
                    },
                    ImgSrc: () => <DELETEICON />,
                    isDisable: window.location.href.includes("viewAlert"),
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />
            });
          }
        }
      }
    }
    setdata([...list]);

  }, [podList]);
  const podNameFunction = (value, valueColor) => {
    return (
      <div>
        <Tooltip placement="topLeft" title={value?.toUpperCase()}>
          <p className="cursor-pointer mb-0">{value?.toUpperCase()}</p>
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      name: Literals.POD_NAME,
      dataIndex: "POD_Name",
      key: "POD_Name",
      sorter: {
        compare: (a, b) =>
          a?.POD_Name?.props?.children?.props?.title !== undefined
            ? a?.POD_Name?.props?.children?.props?.title?.localeCompare(
              b?.POD_Name?.props?.children?.props?.title
            )
            : null,
      },
      width: "20%",
      className: "single-line-ellipsis",
    },
    {
      name: Literals.DESCRIPTION,
      dataIndex: "description",
      sortable: false,

      key: "description",
      className: "description single-line-ellipsis",
      render: (text) => <div className="text-with-ellipsis">{text}</div>,
      width: "30%",
    },
    {
      name: Literals.SOURCES,
      dataIndex: "sources",
      key: "sources",
      sortable: false,

      width: "5%",
    },
    {
      name: "Data Domain",
      dataIndex: "dataDomain",
      key: "dataDomain",
      sortable: false,

      sorter: {
        compare: (a, b) =>
          a?.dataDomain?.props?.title !== undefined
            ? a?.dataDomain?.props?.title?.localeCompare(
              b?.dataDomain?.props?.title
            )
            : null,
      },
      width: "auto",
    },
    {
      name: Literals.UPDATED_BY,
      dataIndex: "updatedBy",

      key: "updatedBy",
      sorter: {

        compare: (a, b) =>
          a?.updatedBy?.props?.title !== undefined
            ? a?.updatedBy?.props?.title?.localeCompare(
              b?.updatedBy?.props?.title
            )
            : null,
      },
      width: "auto",
    },
    {
      name: Literals.ACTIONS,
      dataIndex: "action",
      sortable: false,

      key: "action",








      width: "auto",
    },
  ];

  const flowsColumns = [
    {
      name: "Flow Name",
      dataIndex: "flow_name",
      width: "20%",
      key: "flow_name",
      sortable: false,
      sorter: {
        compare: (a, b) => a?.flow_name?.props?.title !== undefined ? a?.flow_name?.props?.title?.localeCompare(b?.flow_name?.props?.title) : null,
      }
    },
    {
      name: Literals.DESCRIPTION,
      dataIndex: "description",
      sortable: false,
      key: "description",
      width: "35%",
      className: "description",
      render: text => <div>
        <p className="mb-0">{text}</p>
      </div>
    },

    {
      name: Literals.CREATED_ON,
      dataIndex: "created_on",
      key: "created_on",
      sortable: false,

      width: "10%",
      sorter: {
        compare: (a, b) => a?.created_on?.props?.title !== undefined ? a?.created_on?.props?.title?.localeCompare(b?.created_on?.props?.title) : null

      },
    },
    {
      name: Literals.CREATED_BY,
      dataIndex: "created_by",
      key: "created_by",
      width: "20%",
      sortable: false,

      sorter: {
        compare: (a, b) => a?.created_by?.props?.title !== undefined ? a?.created_by?.props?.title?.localeCompare(b?.created_by?.props?.title) : null

      },
    },
    {
      name: Literals.UPDATED_BY,
      dataIndex: 'updated_by',
      sortable: false,

      // width: "",
      key: "updated_by",
      sorter: {
        compare: (a, b) => a?.updated_by?.props?.title !== undefined ? a?.updated_by?.props?.title?.localeCompare(b?.updated_by?.props?.title) : null

      },
    },
    // {
    //   name: Literals.ACTIONS,
    //   dataIndex: "action",
    //   sortable: false,
    //   key: "action",
    //   width: "8%",
    // },

  ];
  const setFlowsTableData = (data) => {

    let data_arr = [{

      image: <FLOWTABICON />, flow_name: <Tooltip placement="bottom" title={data?.name}>{data?.name}</Tooltip>,

      description: data.description ? <Tooltip placement="bottom" title={data?.description}>{data?.description ? data?.description : ''}</Tooltip> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px" }}>No description has been given yet.</span></label>,
      created_on: <Tooltip placement="bottom" title={getRelativeTime(data?.created_on)}>{getRelativeTime(data?.created_on) ? getRelativeTime(data?.created_on) : ''} </Tooltip>,
      created_by: <Tooltip placement="bottom" title={data?.created_by_user?.email}>{data?.created_by_user?.email ? data?.created_by_user?.email : ''}</Tooltip>,
      updated_on: getRelativeTime(data?.updated_on),
      updated_by: <Tooltip placement="bottom" title={data?.updated_by_user?.email}>{data?.updated_by_user?.email ? data?.updated_by_user?.email : ''}</Tooltip>,
      icon: <div className='text-right'>


      </div>,
      key: data.id,
      action:
        <Buttons
          props={{
            tooltip: "Remove Pod",
            buttonText: "",
            buttonClassName: !window.location.href.includes("viewAlert") ?
              " mr-1 mt-1 cursor-pointer custom-btn-outline custom-btn btn-with-icon error-red" : '',
            buttonEvent: (e) => {
              e.stopPropagation();
              onDeletePod(data.id)
            },
            ImgSrc: () => <DELETEICON />,
            isDisable: window.location.href.includes("viewAlert"),
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
      ,

      bookmark: data.bookmark,
      allData: data,
      thumbnail: data?.thumbnail
    }]


    setdata([...data_arr])
  }
  useEffect(() => {
    if (conditionType === 'flow' && flowsAlertTransformerList?.selectedFlowDetails && flowsAlertTransformerList?.alertList?.length) {

      setFlowsTableData(flowsAlertTransformerList?.selectedFlowDetails)
    }

    if (!flowsAlertTransformerList?.alertList?.length && !selectedPodList.length) {
      setdata([])

    }
  }, [flowsAlertTransformerList])


  let newAccordianList = [
    {
      key: "listPod",
      header: conditionType.toLowerCase() !== 'flow' ? "PODs Selected" : "Flow Selected",
      innerContent: () => innerContent(),
      addButton: (
        conditionType.toLowerCase() !== 'flow' ? <div className="d-flex align-items-center">
          {/* {showDeletePod ? <Buttons
            props={{
              tooltip: "Remove Pod",
              buttonText: "Remove Pod",
              buttonClassName:
                " mr-1 cursor-pointer alert-remove-pod-btn custom-btn danger-btn btn-with-text",
              buttonEvent: (e) => {
                e.stopPropagation();
                onDeletePod(selectedPod)
              },
              ImgSrc: () => <DELETEICON />,
              isDisable: window.location.href.includes("viewAlert"),
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
            }}
          /> : null} */}
          {window.location.href.includes("viewAlert") ? "" : <Buttons
            props={{
              tooltip: "Add",
              buttonText: "Add",
              buttonClassName:
                "custom-btn-outline custom-btn  mr-2 btn-with-text",
              buttonEvent: (e) => {
                e.stopPropagation();
                toggleModalHandler();
              },
              ImgSrc: () => <PLUS />,
              isDisable: window.location.href.includes("viewAlert"),
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
            }}
          />}
        </div> : conditionType.toLowerCase() === 'flow' && !window.location.href.includes("viewAlert")  ? <Buttons
          props={{
            tooltip: "Add",
            buttonText: "Add",
            buttonClassName:
              "custom-btn-outline custom-btn  mr-2 btn-with-text",
            buttonEvent: (e) => {
              e.stopPropagation();
              setopenFlowSelectionModal(true);
            },
            ImgSrc: () =>  <PLUS />,
            isDisable: window.location.href.includes("viewAlert"),
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        /> : null

      ),
    },
  ];

  const getComparator = (sortColumn) => {
    switch (sortColumn) {

      case 'flow_name':
        return (a, b) => a['allData']['name'] !== undefined ? a['allData']['name']?.localeCompare(b['allData']['name']) : null
      case 'POD_Name':
        return (a, b) => a?.data['pod_name'] !== undefined ? a?.data['pod_name']?.localeCompare(b?.data['pod_name']) : null //a['allData'].sort((ele1, ele2) => ele1['pod_name'] !== undefined ? ele1['pod_name']?.localeCompare(ele2['pod_name']) : ele1['table_name'] !== undefined ? ele1['table_name']?.localeCompare(ele2['table_name']) : null)
      case 'created_on':
        return (a, b) => a?.data['created_on'] !== undefined ? a?.data['pod_name']?.localeCompare(b?.data['pod_name']) : null
      case 'created_by':
        return (a, b) => a?.data['pod_name'] !== undefined ? a?.data['pod_name']?.localeCompare(b?.data['pod_name']) : null
      case 'updated_by':
      case 'updatedBy':
        return (a, b) => a?.data['updatedBy']['email'] !== undefined ? a?.data['updatedBy']['email']?.localeCompare(b?.data['updatedBy']['email']) : null
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }

  const innerContent = () => {

    return (
      <div className="analytics-tab-table">
        <div className={!showLogs?"analytics-dash-table-fullwidth":"analytics-dash-table"}>
          {data.length ?
            //  <LandingPageTable
            //   props={{




            //     columns: conditionType.toLowerCase() === 'flow' ? flowsColumns : columns,
            //     dataSource: data,
            //     tableHeight: "",
            //     onRowSelection: (e) => { },
            //   }}
            // /> 
            <LandingSimpleTable
              cols={conditionType.toLowerCase() === 'flow' ? flowsColumns : columns}
              rowsData={data}
              showCursor={false}
              onRowClick={() => {
              }}
              getComparator={getComparator}
              tableHeight={conditionType.toLowerCase() !== 'sql' ? 100 : 200}
            // tableHeight={TableHeight}
            />
            : conditionType.toLowerCase() === 'flow' ? <AddFlowDefaultView openFlowModal={() => setopenFlowSelectionModal(true)} /> : <SelectPODInfography openAddPODModal={toggleModalHandler}></SelectPODInfography>}
        </div>
      </div>
    );
  };

  return (
    <>

      <Accordian
        props={{
          isDisable: false,
          accordionList: newAccordianList,
          Icon: () => <ACCORDIONDOWNARROW />,
          defaultExpandedPanel: selectedPodList && selectedPodList.length ? [] : ["listPod"],
        }}
      />
      <FlowSelectionModal isModalOpen={openFlowSelectionModal} onClose={() => setopenFlowSelectionModal(false)} />

    </>

  );
};

export default ListofPodDetails;
