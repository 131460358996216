import React from 'react'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import useHideSidebar from '../../../../customHooks/useHideSidebar'
import Conversation from './ChatContentBox'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { bookmarkTracker, getTrackerData, toggleConversationTrack, updateTracker } from '../../../../store/modules/common/GenAi/GenAiReducer'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { generateHtmlTitle, getConfirmationModal, getReloadErrorTemplate } from '../../common/helperFunctions'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import { FILLEDSTAR, STAR } from '../../../Common/iconSource'
import { ICON_ADD_TRACK, ICON_CATALOG_DOMAIN, ICON_CLOSE, ICON_EDITBOX, ICON_LOADING_CIRCLE, ICON_SAVE, ICON_SHARE, ICON_TRACK } from '../../../Common/newIconSource'
import ShareChatModal from './ShareChatModal'
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions'
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton'

const TrackedDataIndex = () => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const { trackerId } = useParams()

    const [loading, setLoading] = React.useState(false)
    const [errMsg, setErrorMsg] = React.useState(undefined);
    const [data, setData] = React.useState({});
    const [editName, setEditName] = React.useState(false);
    const [chatName, setChatName] = React.useState("")
    const [newName, setNewName] = React.useState("")
    const [isUpdatingName, setUpdatingName] = React.useState(false)
    const [shareModalOpen, setShareModalOpen] = React.useState(false)
    const [bookmark, setBookmark] = React.useState(false)
    const [bookmarkLoading, setBookmarkLoading] = React.useState(false)
    const [sharedUsers, setSharedUsers] = React.useState([])
    const [usersLoading, setUsersLoading] = React.useState([])
    const [usersList, setUsersList] = React.useState([])
    const [trackLoader, setTrackLoader] = React.useState(false)
    const [tracking, setTracking] = React.useState(false)
    const [openUntrackModal, setOpenUntrackModal] = React.useState(false)
    
    React.useEffect(() => {
        if(trackerId) {
            fetchTrackerData()
            getUsersList()
        }
    }, [])

    useHideSidebar(true)

    console.log({data})

    React.useEffect(() => {
        let title = generateHtmlTitle('Tracker', 'Pulse')
        document.title = title
    }, [])

    const fetchTrackerData = () => {
        setErrorMsg(undefined)
        setLoading(true)
        dispatch(getTrackerData(trackerId))
        .then((res) => {
            const dat = res?.data
            setData(dat)
            setChatName(dat?.name ?? '')
            setNewName(dat?.name ?? '')
            if(dat?.conversationusers) {
                let usrs = dat?.conversationusers?.map(u => u?.id)
                setSharedUsers(usrs)
            }
            setBookmark(dat?.bookmark ?? false)
            setTracking(dat?.tracked === true)
            setLoading(false)
        })
        .catch((err) => {
            setErrorMsg(err?.message)
            setLoading(false)
        })
    }

    const handleTrack = (id) => {
        setTrackLoader(true)
        dispatch(toggleConversationTrack(id, !tracking))
        .then(() => {
            setTracking(!tracking)
            setTrackLoader(false)
            history(Literals.links.PULSE_LANDING + '#trackers')
        })
        .catch(err => {
            console.error({err})
            setTrackLoader(false)
        })
    }

    const getUsersList = () => {
        setUsersLoading(true)
        dispatch(getUserGroupList({ method: "GET", endPoint: "profile/", payload: undefined, showLoader: false }))
        .then(res => {
            let list = res?.data?.map(ele => ({ name: `${ele?.first_name} ${ele?.last_name}`, value: ele?.id, email: ele?.email }))
            setUsersList([...list]);
            setUsersLoading(false)
        })
        .catch(err => {
            setUsersLoading(false)
            console.error(err)
        })
    };

    const handleUpdateName = () => {
        if(newName?.trim() === '') return
        setUpdatingName(true)
        let payload = {
            name: newName
        }
        dispatch(updateTracker(data?.id, payload))
        .then(() => {
            setChatName(newName)
            setUpdatingName(false)
            setEditName(false)
        })
        .catch(() => {
            setUpdatingName(false)
        })
    }

    const handleBookmark = (book) => {
        const id = data?.id
        setBookmarkLoading(true)
        dispatch(bookmarkTracker(id, book, true))
        .then(() => {
            setBookmarkLoading(false)
            setBookmark(book)
        })
        .catch(() => {
            setBookmarkLoading(false)
        })
    }
    
    return (
        <div className='talk-index-main talkData-wrapper'>
            <LandingpageFirstHeader title={'Tracker'} isPulse={true} />
            <div className='tracker-data-wrap'>
                {
                    errMsg ? ''
                    :
                    <div className='tracked-head'>
                        <div className='d-flex'>
                            <div className='d-flex align-items-center'>
                                <ICON_CATALOG_DOMAIN color='#275A89' width='22' height='22' />
                                <span className='p-1'></span>
                                <label className='mb-0 fontSizeLargeHeading fontInterSemiBold dark-color text-uppercase'>
                                    {
                                        loading
                                        ?
                                        <CustomSkeleton width='200px' height='24px' />
                                        :
                                        data?.data_domain_name
                                    }
                                </label>
                            </div>
                            <div className='vertical-separator'></div>
                            <div className='d-flex align-items-center'>
                                <ICON_TRACK width='24' height='24'/>
                                <span className='p-1'></span>
                                {
                                    editName
                                    ?
                                    <>
                                        <input
                                            className='custom-input-field fontInterSemiBold dark-color'
                                            defaultValue={chatName}
                                            value={newName}
                                            style={{fontSize: 16, width: 400}}
                                            onChange={(e) => {
                                                setNewName(e?.target?.value)
                                            }}
                                            disabled={isUpdatingName}
                                        />
                                    </>
                                    :
                                        loading
                                        ?
                                        <CustomSkeleton width='300px' height='24px' />
                                        :
                                        <label className='mb-0 mr-2 fontSizeLargeHeading fontInterSemiBold dark-color'>
                                            {chatName}
                                        </label>
                                }
                                <Buttons
                                    props={{
                                        buttonText: '',
                                        tooltip: isUpdatingName ? 'Updating...' : editName ? 'Save' : 'Edit',
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large ml-2",
                                        buttonEvent: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            if(editName) handleUpdateName()
                                            setEditName(true)
                                        },
                                        ImgSrc: () => isUpdatingName ? <ICON_LOADING_CIRCLE/> 
                                                        : editName ? <ICON_SAVE/> : <ICON_EDITBOX />,
                                        isDisable: isUpdatingName,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />
                            </div>
                        </div>
                        <div className='d-flex justify-content-end' style={{gap: 10}}>
                            {/* <Buttons
                                props={{
                                    buttonText: '',
                                    tooltip: bookmarkLoading ? 'Adding to Favourite' : bookmark ? Literals.REMOVE_FAVOURITES : Literals.ADD_FAVOURITES,
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleBookmark(!bookmark)
                                    },
                                    ImgSrc: () => bookmarkLoading ? <ICON_LOADING_CIRCLE/> : bookmark ? <FILLEDSTAR/> : <STAR />,
                                    isDisable: loading || bookmarkLoading,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                            <Buttons
                                props={{
                                    buttonText: '',
                                    tooltip: 'Share',
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setShareModalOpen(true)
                                    },
                                    ImgSrc: () => <ICON_SHARE />,
                                    isDisable: loading,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            /> */}
                            <Buttons
                                props={{
                                    buttonText: (tracking ? 'Untrack' : 'Track'),
                                    tooltip: '',
                                    buttonClassName: `border 
                                        ${tracking ? 'custom-btn-primary' : 'custom-btn-outline'} 
                                        custom-btn btn-with-text btn-large`,
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        if(tracking) {
                                            setOpenUntrackModal(true)
                                        }
                                    },
                                    ImgSrc: () => trackLoader ? <ICON_LOADING_CIRCLE/> : <ICON_ADD_TRACK />,
                                    isDisable: trackLoader,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                            <Buttons
                                props={{
                                    buttonText: '',
                                    tooltip: 'Close',
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        history(Literals.links.PULSE_LANDING + '#trackers')
                                    },
                                    ImgSrc: () => <ICON_CLOSE />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    loading 
                    ?   <ClaristaLoader height='200px' />
                    :
        
                    errMsg
                    ?   getReloadErrorTemplate({ 
                            errorMessage: errMsg, 
                            onReload: () => {
                                fetchTrackerData()
                            } 
                        })
                    :
        
                    <Conversation
                        index={1} 
                        data={data}
                        reload={() => {
                            fetchTrackerData()
                        }}
                    />
                }
            </div>

            <ShareChatModal
                open={shareModalOpen}
                setOpen={setShareModalOpen}
                usersList={usersList}
                usersLoading={usersLoading}
                chatId={data?.id}
                sharedUsers={sharedUsers}
                setSharedUsers={setSharedUsers}
                title={chatName ?? ''}
                isTracker={true}
            />

            {
                getConfirmationModal({
                    open: openUntrackModal,
                    setOpen: setOpenUntrackModal,
                    body: 'Do you really want to Untrack?',
                    onConfirm: () => handleTrack(data?.id),
                    confirmationText: 'Yes, Do it'
                })
            }
        </div>
    )
}

export default TrackedDataIndex