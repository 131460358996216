
import { showJupyterNoteBook } from './flowsActions';
import {
    SET_ENTIREFLOW_EXECUTE_START,
    SHOW_FLOWS_LOWERSECTION,
    SHOW_FLOWS_TRANSFORMDETAILS,
    UPDATE_FLOWS_ELEMENTS,
    SET_FLOWS_CURRENTTRANSFORMER,
    SHOW_FLOWS_ATTOP,
    SET_TRANSFORMER_STATUS_CHANGE,
    UPDATE_FLOWS_BASICDETAILS,
    SET_TRANSFORMERLOADING_STATUS,
    SET_TO_DEFAULT,
    SHOW_FLOWS_JUPYTERNOTEBOOK,
    SET_DATASOURCE_PODS,
    SET_TRANSFORMER_PAYLOAD,
    ADD_EDGES,
    SAVE_FLOW_CLICK,
    SET_TRANSFORMER_PROGRESS,
    SET_FILTER_TRANSFORMER_CRITERIA,
    SET_FLOWS_SHOWLEFTSIDEDETAILS,
    SET_SQL_TRANSFORMER_QUERY,
    ADD_EDITOR_TEXT,
    SAVE_NOTE_CLICK,
    SET_TRANSFORMER_MAXID,
    SET_TRANSFORMER_NAME,
    SET_CURRENT_SCRIPTOUTPUT_NODE,
    SHOW_FLOWS_TIMELINE_LOWERSECTION,
    SET_SQL_EDIT_MODE,
    SET_FLOWS_LANDINGPAGE_ACTIVE_TAB,
    SET_CURRENT_EXECUTING_TRANSFORMER_ID,
    SHOW_FLOWS_LOGS_LOWERSECTION,
    SHOW_FLOWS_DATABRICKSNOTEBOOK,
    SHOW_BRICKS_MENU
} from './flowsTypes'

const initialState = {
    atTop: false,
    showLowerSection: false,
    showTransformDetails: false,
    flowsElements: [],
    currentTransformer: {},
    name: null,
    description: null,
    id: null,
    transformerLoading: false,
    showJupyterNoteBook: false,
    dataSourcePodDetails: [],
    executedTransformerPayload: [],
    edges: [],
    note: [],
    appliedFilterCriteria: [],
    showLeftSideDetails: false,
    saveNoteClick: false,
    sqlTransformerQuery: {},
    saveFlowClick: false,
    transformerName: {},
    maxTransformerId: 0,
    currentScriptOutput: {},
    SQLEditMode: false,
    currentActiveTabFlowLanding: 'allflows',
    currentExecutingTransformerId: null,
    executeEntireFlowStart: false,
    transformerProgress: [],
    transformerStatusChange: { status: false },
    showLogsLowerSection: false,
    showTimelineLowerSection: false,
    showBricksIcon: false
}

const FlowsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_FLOWS_LOWERSECTION:
            return {
                ...state,
                showLowerSection: payload
            };
        case SHOW_FLOWS_TIMELINE_LOWERSECTION:
            return {
                ...state,
                showTimelineLowerSection: payload
            };

        case SHOW_FLOWS_LOGS_LOWERSECTION:
            return {
                ...state,
                showLogsLowerSection: payload
            };
        case SET_TRANSFORMER_STATUS_CHANGE:
            return {
                ...state,
                transformerStatusChange: payload
            };
        case SHOW_BRICKS_MENU:
            return {
                ...state,
                showBricksIcon: payload
            };
        case SET_CURRENT_EXECUTING_TRANSFORMER_ID:
            return {
                ...state,
                currentExecutingTransformerId: payload
            };
        case SET_FLOWS_LANDINGPAGE_ACTIVE_TAB:
            return {
                ...state,
                currentActiveTabFlowLanding: payload
            };
        case SET_ENTIREFLOW_EXECUTE_START:
            return {
                ...state,
                executeEntireFlowStart: payload
            };
        case SET_TRANSFORMER_PROGRESS:
            return {
                ...state,
                transformerProgress: payload
            };
        case SET_SQL_EDIT_MODE:
            return {
                ...state,
                SQLEditMode: payload
            };
        case SET_TRANSFORMER_MAXID:
            return {
                ...state,
                maxTransformerId: payload
            };
        case SET_TRANSFORMER_NAME:
            return {
                ...state,
                transformerName: payload
            };
        case SET_SQL_TRANSFORMER_QUERY:
            return {
                ...state,
                sqlTransformerQuery: payload
            };
        case SAVE_NOTE_CLICK:
            return {
                ...state,
                saveNoteClick: payload
            };
        case ADD_EDGES:
            return {
                ...state,
                edges: payload
            };
        case ADD_EDITOR_TEXT:
            return {
                ...state,
                note: payload
            };
        case SET_FILTER_TRANSFORMER_CRITERIA:
            return {
                ...state,
                appliedFilterCriteria: payload
            };
        case SET_TRANSFORMER_PAYLOAD:
            return {
                ...state,
                executedTransformerPayload: payload
            };
        case SET_DATASOURCE_PODS:
            return {
                ...state,
                dataSourcePodDetails: payload
            };
        case SHOW_FLOWS_JUPYTERNOTEBOOK:
            return {
                ...state,
                showJupyterNoteBook: payload
            };
        case SHOW_FLOWS_DATABRICKSNOTEBOOK:
            return {
                ...state,
                showDatabricksNotebook: payload
            };
        case SHOW_FLOWS_ATTOP:
            return {
                ...state,
                atTop: payload
            };
        case SHOW_FLOWS_TRANSFORMDETAILS:
            return {
                ...state,
                showTransformDetails: payload
            };
        case UPDATE_FLOWS_ELEMENTS:
            return {
                ...state,
                flowsElements: payload && payload.length ? [...payload] : []
            };
        case SET_FLOWS_CURRENTTRANSFORMER:
            return {
                ...state,
                currentTransformer: payload
            };
        case SET_CURRENT_SCRIPTOUTPUT_NODE:
            return {
                ...state,
                currentScriptOutput: payload
            };
        case UPDATE_FLOWS_BASICDETAILS:
            return {
                ...state,
                name: payload.name,
                description: payload.description,
                id: payload.id,
                bookmark: payload?.bookmark ? payload?.bookmark : false
            };
        case SET_TRANSFORMERLOADING_STATUS:
            return {
                ...state,
                transformerLoading: payload
            };
        case SET_FLOWS_SHOWLEFTSIDEDETAILS:
            return {
                ...state,
                showLeftSideDetails: payload
            };
        case SAVE_FLOW_CLICK:
            return {
                ...state,
                saveFlowClick: payload
            };
        case SET_TO_DEFAULT:
            initialState['transformerName'] = {}
            initialState['currentTransformer'] = {}
            initialState['flowsElements'] = []
            initialState['executedTransformerPayload'] = []
            initialState['dataSourcePodDetails'] = []
            initialState['edges'] = []
            initialState['appliedFilterCriteria'] = []
            initialState['sqlTransformerQuery'] = {}
            return initialState
        default:
            return state;

    }
}

export default FlowsReducer