import { Tooltip } from 'antd';
import classnames from "classnames";
import React, { useEffect, useState } from 'react';
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate } from 'react-router-dom';
import "toastr/build/toastr.min.css";
import LoadingComponent from "../../../common/loadingComponent";

import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { getUserGroupList, setUMLeadersList, setUserGroupDescription, setUserGroupId, setUserGroupName } from '../../../../../store/modules/UserManagement/userGroup/UMActions';
import Buttons from "../../../../Common/button/Buttons";
import { DELETEICON, EDIT, SMALLGROUP, USERSGROUP } from '../../../../Common/iconSource';
import { LandingSimpleTable } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import ActionPopUp from '../../../common/actionPopUp';
import { ErrorComponent, getDeleteMessage, getRelativeTime, NoDataComponent, permissions } from '../../../common/helperFunctions';
import { Literals } from "../../../common/literals";
import NoSearchResultFound from '../../../common/NoSearchResultFound';
import UsersInitials from '../../../common/UsersInitials';
import './userGroup.scss';
import DeleteModal from '../../../../Common/deleteModal/DeleteModal';

const UserGroupListView = ({ searchData, preData = [] }) => {
    const [userGroupsList, setUserGroupsList] = useState([])
    const store = useStore();
    const history = useNavigate()
    const dispatch = useDispatch();
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [empty, setEmpty] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const [, setSelectedId] = useState("");

    const isSuperUser = useSelector(state => state.UserReducer.user.userDetails.is_superuser)

    const currentGroupRef = React.useRef({ current: {} })

    let data = [];
    const loadingStatus = useSelector(
        (state) => state.LoadingReducer.loadingStatus
    );
    const colors = ['#5F5DE9', '#00CC92', '#FF565E'];

    useEffect(() => {
        getUserGroupListData()
    }, [])

    const closeDeletePopUp = () => {
        setOpenDeletePopup(false);
    };
    useEffect(() => {
        dispatch(setUserGroupDescription(""));
        dispatch(setUserGroupName(""));
        dispatch(setUserGroupId(""));
        dispatch(setUMLeadersList([]))
    }, []);

    const openDeleteDialog = (record) => {

        currentGroupRef.current = record

        // setDeletePopUpContent({
        //     modalClass: "delete-popup",
        //     heading: "Delete",
        //     message: "This action will delete the group permanently.",
        //     headingIcon: "bx bx-error",
        //     button: [
        //         {
        //             name: "Delete",
        //             action: () => deleteId(record?.id),
        //         },
        //         {
        //             name: "Cancel",
        //             action: closeDeletePopUp,
        //         },
        //     ],
        //     elements: [],
        //     closeActionPopup: closeDeletePopUp,
        // });
        setOpenDeletePopup(true);
    };
    const handleEdit = (key) => {
        history(Literals.links.USER_MANAGEMENT_EDIT_USER_GROUP + key, { state: { tabName: Literals.USERGROUP } });
        window.history.replaceState({ state: { tabName: Literals.USERGROUP } }, '')

    }
    const onRowClick = (record) => {
        history(Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS + record.key, { state: { tabName: Literals.USERGROUP } })
        window.history.replaceState({ state: { tabName: Literals.USERGROUP } }, '')

    }
    const getUserGroupListData = async () => {
        if (preData.length) {
            setUserGroupsList([...preData])
            setEmpty(false)
            return
        }
        let response;
        try {
            response = await store.dispatch(getUserGroupList(
                { method: "GET", endPoint: "group_manager/group/" }));
            if (response.status === "success") {
                if (response.data.length <= 0) {

                    setEmpty(true);
                } else {
                    setEmpty(false)
                    setUserGroupsList(response.data)
                }

            }
        } catch (error) {
            setErrMsg(error.message)
        }
    }

    const deleteId = async (id) => {
        let response;
        try {
            response = await store.dispatch(getUserGroupList(
                { method: "DELETE", endPoint: "group_manager/group/" + id + "/" }));
            if (response.status === "success") {
                emitToastNotification('success', response?.message);
                getUserGroupListData();
                setOpenDeletePopup(false);
            }
            else {
                emitToastNotification('error', response?.message);
            }
        } catch (error) { emitToastNotification('error', error?.message); }
    }

    const getMembersList = (type, details) => {
        let member = [];
        let leader = [];
        if (details && details.length > 0) {
            details.forEach((user) => {
                if (user?.permissions.includes("leader")) {
                    leader = [...leader, user?.username];
                } else {
                    member = [...member, user?.username];
                }
            })
        }
        if (type === "member") return member
        else if (type === "leader") return leader;
    }


    const filterData = userGroupsList && userGroupsList.filter((data) => {
        return (data?.name?.toLocaleLowerCase().includes(searchData?.toLocaleLowerCase()) ||
            data?.description?.toLocaleLowerCase().includes(searchData?.toLocaleLowerCase()) ||
            getRelativeTime(data?.updated_on)?.toLocaleLowerCase().includes(searchData?.toLocaleLowerCase()) ||
            data?.created_by_user?.username?.toLocaleLowerCase().includes(searchData?.toLocaleLowerCase()));
    })

    filterData && filterData.map((value) =>
        data.push(
            {
                key: value?.id,
                userGroupName: <div className=' d-flex align-items-center'><span className="icon-box-desc"><SMALLGROUP width='18' height='18' color='#2c83c4' /></span><Tooltip placement="topLeft" title={value?.name}><div className='text-with-ellipsis'>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.name?.toUpperCase()} /></div></Tooltip></div>,
                description: <Tooltip placement="topLeft" title={value?.description}>< Highlighter className='text-elipse' searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.description} /></Tooltip>,
                updatedOn: <Tooltip placement="topLeft" title={getRelativeTime(value?.updated_on)}>
                    {getRelativeTime(value?.updated_on)}
                </Tooltip>,
                // updatedOn: <Tooltip placement="topLeft" title={getRelativeTime(value?.updated_on)}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={getRelativeTime(value?.updated_on)} /></Tooltip>,
                createdBy: <Tooltip placement="topLeft" title={value?.created_by_user?.username}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.created_by_user?.username} /></Tooltip>,
                listofuser: <UsersInitials initialsArray={getMembersList("member", value?.user?.user_detail)} isLeadersList={false} colors={colors} />,
                action: <div className="d-flex justify-content-center align-items-center h-100">
                    <Buttons props={{
                        buttonText: '', buttonClassName: "custom-btn-outline ml-1 mr-1 custom-btn btn-with-icon", buttonEvent: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleEdit(value?.id)
                        }, tooltip: 'Edit',
                        ImgSrc: () => <EDIT />,
                        isDisable: !(!!value?.permission?.find(v => v === permissions.usergroup.leader)),
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }} />
                    <Buttons props={{
                        buttonText: '', buttonClassName: "custom-btn-outline ml-1 mr-1 custom-btn btn-with-icon error-red", buttonEvent: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            openDeleteDialog(value);
                            setSelectedId(value?.id)
                        }, tooltip: 'Delete',
                        ImgSrc: () => <DELETEICON />,
                        isDisable: !isSuperUser,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }} />
                </div>,
                data: value

            }
        )
    )

    const columns = [
        {
            name: Literals.USERGROUPNAME,
            dataIndex: "userGroupName",
            key: "userGroupName",
          
            resizable:false,
           
        },
        {
            name: Literals.DESCRIPTION,
            dataIndex: "description",
            key: "description",
            className: "description",
            sortable: false,
            resizable:false
        },
        {
            name: Literals.UPDATED_ON,
            dataIndex: "updatedOn",
            key: "updatedOn",
            resizable:false

        },
        {
            name: Literals.CREATED_BY,
            dataIndex: "createdBy",
            key: "createdBy",
            className: "single-line-ellipsis",
            resizable:false

        },
        {
            name: <div className='text-center'>Action</div>,
            key: 'action',
            sortable: false,
            dataIndex: "action",
            resizable:false

        }
    ];

    const getComparator = (sortColumn) => {
        switch (sortColumn) {

            case 'userGroupName':
                return (a, b) => a?.data?.name !== undefined ? a?.data?.name?.localeCompare(b?.data?.name) : null
            case 'updatedOn':
                return (a, b) => a?.data?.updated_on?.localeCompare(b?.data?.updated_on) ?? null
            case 'createdBy':
                return (a, b) => a?.data?.created_by_user?.username?.localeCompare(b?.data?.created_by_user?.username) ?? null
        
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }
    let TableHeight;
    if (window.innerHeight >= 750 && window.innerHeight < 1000) {
        TableHeight = Math.round((window.innerHeight - 280))
    } else if (window.innerHeight >= 1000) {
        TableHeight = Math.round((window.innerHeight - 150))
    } else {
        TableHeight = Math.round(window.innerHeight - 100)
    }

    const getColumns = () => {
        let cols = [...columns]
        if (preData.length > 0) {
            return cols.filter(v => v?.key !== 'action')
        }
        return cols
    }

    return (
        <div className={classnames("user-management-table h-100", searchData.length > 0 ? "search-table" : "")}>
            {loadingStatus ? <LoadingComponent /> : errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> : empty ? <NoDataComponent logo={<USERSGROUP width="60" height="60" color="black" />} message="
                Click On (+) To Create User Group"/> : searchData?.length > 0 && filterData?.length === 0 ?
                <NoSearchResultFound /> :

                <LandingSimpleTable
                    cols={getColumns()}
                    autoWidth={true}
                    rowsData={data}
                    selectedRowIndex={undefined}
                    onRowClick={(record, index) => { onRowClick(record, index) }}
                    getComparator={getComparator}
                    tableHeight={'100%'}

                />
            }

            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={getDeleteMessage({
                    title: 'Usergroup'
                })}
                onConfirm={() => deleteId(currentGroupRef?.current?.id)}
            />

            {/* <ActionPopUp
                openModal={openDeletePopup}
                actionData={deletePopUpContent}
            /> */}
        </div>
    )
}

export default UserGroupListView;