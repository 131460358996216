import { Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { TriggerAlertDataApi } from '../../../../store/modules/alert/alertLanding/alertLandingAction';
import { setOpenAnalysis } from '../../../../store/modules/analytics/dashboard/dashboardActions';
import Buttons from '../../../Common/button/Buttons';
import { ALERTBELL, CLOSE, EDIT, PLAY, SAVE, LINEAGE_ICON, CHANGELOG } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';

const AlertSubHeader = ({
    ruleName = '',
    createRule,
    isEditPermission = true,
    isSaveConfirm,
    setisSaveConfirm,
    openSaveChangesWarning,
    showLogs = true,
    setShowLogs = () => { },
    retriveAlertData = {},
    ...props
}) => {
    const { alertId } = useParams()
    const history = useNavigate();
    const store = useStore()
    const location = useLocation()

    useEffect(() => {
        if (isSaveConfirm === true) {

            if (location?.state?.from === 'alert') {
                history(`${Literals.links.CLARISTA_LAB}/#alert`)
            } else if (location?.state?.from === 'myalert') {
                history(Literals.links.ALERTS, { state: { activeTab: 'myalerts' } })
                store.dispatch(
                    setOpenAnalysis({ layout: "alerts", activeTab: "alerts" })
                );
            } else {
                history(`${Literals.links.CLARISTA_LAB}/#alert`)
            }
        }
    }, [isSaveConfirm])


    const backButton = () => {
        if (window.location.href.includes('editAlert')) {
            openSaveChangesWarning()

        } else {
            if (location?.state?.from === 'alert') {
                history(`${Literals.links.CLARISTA_LAB}/#alert`)
            } else if (location?.state?.from === 'myalert') {
                history(`${Literals.links.ALERTS}`, { state: { activeTab: 'myalerts' } })
                store.dispatch(
                    setOpenAnalysis({ layout: "alerts", activeTab: "alerts" })
                );
            } else {
                history(`${Literals.links.CLARISTA_LAB}/#alert`)
            }
        }
    }

    return (
        <div className="sub-header alert-headed">
            <div className={`sub-header-left d-flex align-items-center noti-alert-prps-ic ${retriveAlertData?.purpose?.toLowerCase() ?? ''}`}>
                <Tooltip title={`${retriveAlertData?.purpose ?? ''} Alert`} placement="right">
                    <span>
                        <ALERTBELL />
                    </span>
                </Tooltip>
                <span className="ml-1">{ruleName?.toUpperCase()}</span>
            </div>
            <div className="sub-header-right d-flex align-items-center justify-content-center">

                {/* <Buttons
                    props={{
                        tooltip: `${showLogs ? 'Hide Left Panel' : 'Show Left Panel'}`,
                        buttonText: "",
                        buttonClassName: `${showLogs ? 'rdg-filter-active' : ''} custom-btn-outline custom-btn btn-with-icon mr-2`,
                        buttonEvent: () => {
                            setShowLogs(!showLogs)
                        },
                        ImgSrc: () => <CHANGELOG />,
                        isDisable: false,
                        buttonType: Literals.BTN_TERTIARY,
                    }}
                /> */}

                {window.location.href.includes('viewAlert') ?

                    <Buttons props={{
                        tooltip: 'Execute Alert',
                        buttonText: '', buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon", buttonEvent: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            store.dispatch(TriggerAlertDataApi({ id: alertId })).then(() => {
                                // emitToastNotification('success', 'Execution started successfully!')
                            });
                        }, ImgSrc: () => <PLAY />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                    }} /> : null}
                {!window.location.href.includes('createAlert') ? <Buttons
                    props={{
                        tooltip: `Show Lineage`,
                        buttonText: 'Lineage',
                        buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon",
                        buttonEvent: () => {


                            let path = `/viewLineage/alert/${alertId}`;

                            window.open(path)

                        },
                        ImgSrc: () => <LINEAGE_ICON />,

                        buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                    }}

                /> : null}
                {window.location.href.includes('viewAlert') ?

                    <Buttons props={{
                        tooltip: 'Edit',
                        buttonText: 'Edit', buttonClassName: "custom-btn-primary custom-btn btn-with-text mr-2", buttonEvent: () => {
                            history(`${Literals.links.ALERTS_EDIT}${alertId}`, { state: { exceptionId: null, from: 'alert' }, replace: true })
                        }, ImgSrc: () => <EDIT />, isDisable: !isEditPermission, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }} /> :

                    <Buttons props={{
                        tooltip: window.location.href.includes('editAlert') ? 'Save' : 'Save',
                        buttonText: window.location.href.includes('editAlert') ? 'Save' : 'Save', buttonClassName: "custom-btn-primary mr-2 custom-btn btn-with-text", buttonEvent: () => {
                            createRule(window.location.href.includes('editAlert') ? 'Save' : 'Save')
                        }, ImgSrc: () => <SAVE />, isDisable: !isEditPermission || window.location.href.includes('viewAlert'), buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }} />
                }

                <Buttons props={{
                    tooltip: 'Close',
                    buttonText: '', buttonClassName: "custom-btn-outline  custom-btn btn-with-icon", buttonEvent: () => {
                        backButton()
                    }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                }} />

            </div>
        </div>
    )
}

export default AlertSubHeader;