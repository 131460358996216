import React from 'react'
import { Tabs } from "antd"

const { TabPane } = Tabs;

const CustomTabs = ({activeKey, defaultActiveKey, tabs, onChange, ctaButton, id = '' }) => {
   

    return  (
        <div className="custom-tab-with-text">
            <Tabs id={id} activeKey={activeKey} defaultActiveKey={defaultActiveKey} onChange={onChange} >
                {tabs.map((tab) => <TabPane
                    tab={
                    <div className="d-flex align-items-center">
                        <span className="tabs-icon mr-1">{tab.icon}</span>
                        <span className="tabs-title">{tab.title}</span>    
                    </div>
                    }
                    key={tab.id}
                >
                    {tab.content}
                </TabPane>)
                }
            </Tabs>
            {ctaButton && ctaButton}
        </div>
    )
}

export default CustomTabs