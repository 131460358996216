import React from 'react'
import './claristaLoader.scss'
import { CLOSE } from '../iconSource'
import { useDispatch, useStore } from 'react-redux'
import { CancelQueryAPI } from '../../../store/modules/dataCatalogue/preview/previewActions'
import { ICON_LOADING_CIRCLE } from '../newIconSource'
const ClaristaLoader = ({
    height = '100%',
    width = '100%',
    isCancel = false,
    showErrorToast = false,
    setCancelQueryDone = () => { },
    uuid,
    setKillQueryDone = () => { }

}) => {

    const [loading, setLoading] = React.useState(false)

    const dispatch = useDispatch();
    const store = useStore();

    let tableUUIDRedux = store.getState().CommonComponent?.DataGridTable?.uuid;

    const handleKill = () => {
        setLoading(true)
        dispatch(CancelQueryAPI(uuid ?? tableUUIDRedux, showErrorToast))
            .then(() => {
                setCancelQueryDone(false);
                setKillQueryDone(true)
                setLoading(false)
            })
            .catch(() => {
                setCancelQueryDone(false)

                setLoading(false)
            })
    }

    return (
        <div
            className='clarista-loader d-flex align-items-center justify-content-center'
            style={{
                height, width,
                flexDirection: "column"
            }}
        >
            <div className="loader-circle-2 d-flex align-items-center justify-content-center">
                <svg
                    className="blink2"
                    width="49"
                    height="30"
                    viewBox="0 0 29 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.0504 19.7771H26.5824C24.7842 24.3164 20.5021 27.2275 14.9357 27.2275C13.2219 27.2275 11.6425 26.935 10.2148 26.4117C13.4066 25.2464 15.8149 22.8962 17.0504 19.7771Z"
                        fill="#275A89"
                    />
                    <path
                        d="M10.2148 2.52444C11.6425 2.00109 13.2219 1.70859 14.9357 1.70859C20.5021 1.70859 24.7842 4.61976 26.5824 9.1582H17.0504C15.8149 6.03996 13.4066 3.69001 10.2148 2.52444Z"
                        fill="#275A89"
                    />
                    <path
                        d="M8.32048 11.1797H2.41797C3.53157 8.36887 6.18314 6.56605 9.63009 6.56605C10.6912 6.56605 11.6693 6.74714 12.5532 7.07115C10.5767 7.793 9.08546 9.24831 8.32048 11.1797Z"
                        fill="#2C83C4"
                    />
                    <path
                        d="M12.5532 21.8631C11.669 22.1871 10.6912 22.3682 9.62982 22.3682C6.18286 22.3682 3.53129 20.5656 2.41797 17.7551H8.32048C9.08546 19.6862 10.5767 21.1415 12.5532 21.8631Z"
                        fill="#2C83C4"
                    />
                </svg>
            </div>
            {isCancel
                ? <div onClick={() => handleKill()} className='query-cancel'
                    style={{
                        cursor: loading ? "not-allowed" : "pointer",
                        zIndex: 1,
                        opacity: loading ? '.6' : '1'
                    }}>
                    {
                        loading ? <ICON_LOADING_CIRCLE width='12' height='12' />
                            : <CLOSE width='12' height='12' />
                    }
                    <span className='ml-1'>
                        {loading ? 'Cancelling...' : 'Cancel'}
                    </span>
                </div>
                : ""
            }
        </div>
    )
}

export default ClaristaLoader