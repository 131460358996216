import { RESET_COMMONVIRTUALTABLE, RESET_VIRTUAL_TABLE_ACTIONS, RESET_VIRTUAL_TABLE_REDUX, SET_ACTION_TYPE, SET_ACTIVE_POD_DETAIL, SET_APPLIED_FLITERS_LIST, SET_CURRENT_TABLE_RESPONSE, SET_EDITOR_QUERY, SET_MULTIPLE_TABLE_DATA, SET_ROWS_COUNT, SET_SEARCH_KEYWORD, SET_SHOW_RESET_FILTER, SET_SORTED_COLUMN_LIST } from "./actionType";


const initialState = {
    showResetButton: false,
    currentPreviewTableData: {},
    sortColumnList: [],
    filterColumnList: [],
    previewActionType: "preview_query",
    multiplePreviewTableData: [],
    previewSqlEditorQuery: '',
    searchKeyword: '',
    tableRowsCount: '',
    activePodDetails: { podName: '', dataDomain: '' }
}
const VirtualDataTable = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case RESET_VIRTUAL_TABLE_REDUX:

            return initialState;
        case SET_ROWS_COUNT:
            return {
                ...state,
                tableRowsCount: payload,
            }

        case SET_ACTIVE_POD_DETAIL:
            return {
                ...state,
                activePodDetails: payload,
            }
        case RESET_VIRTUAL_TABLE_ACTIONS:
            let currentState = { ...state }
            // currentState['activePodDetails'] = { podName: '', dataDomain: '' }
            currentState['filterColumnList'] = []
            currentState['sortColumnList'] = []
            currentState['searchKeyword'] = ''
            return currentState;

        case SET_SHOW_RESET_FILTER:
            return {
                ...state,
                showResetButton: payload,
            }

        case SET_CURRENT_TABLE_RESPONSE:
            return {
                ...state,
                currentPreviewTableData: payload,
            }
        case SET_MULTIPLE_TABLE_DATA:
            return {
                ...state,
                multiplePreviewTableData: payload,
            }
        case SET_SORTED_COLUMN_LIST:
            return {
                ...state,
                sortColumnList: payload,
            }
        case SET_APPLIED_FLITERS_LIST:
            return {
                ...state,
                filterColumnList: payload,
            }
        case SET_ACTION_TYPE:
            return {
                ...state,
                previewActionType: payload,
            }
        case SET_EDITOR_QUERY:
            return {
                ...state,
                previewSqlEditorQuery: payload,
            }
        case SET_SEARCH_KEYWORD:
            return {
                ...state,
                searchKeyword: payload,
            }

        default:
            return state;
    }
}
export default VirtualDataTable;