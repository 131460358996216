import React from "react";

import DataTabs from "../../../Common/dataTabs/DataTabs";
import NoResultsFound from "../../flows/createFlows/NoResultsFound";
import DataGridTable from "../../../Common/dataGridTable/DataGridTable";
import { useSelector } from "react-redux";
import ClaristaLoader from "../../../Common/claristaLoader/ClaristaLoader";
import { ErrorComponent, QueryKillError, getReloadErrorTemplate } from "../../common/helperFunctions";
import SkeletonAvatar from "antd/es/skeleton/Avatar";
import { cloneDeep } from "lodash";

function ResultSection({
  tableData = {},
  VirtualTableHeight,
  tabArray,
  uuid,
  setUniqUUID = () => { },
  onTabChange,
  requestedTableId = '',
  tableTitle,
  activeTabKey,
  isDisableHideBtn = false,
  onEdit,
  isShowFilter = true,
  currentPODName = () => { },
  currentDomainName = () => { },
  showLoader = true,
  setshowLoader = () => { },
  setIsPreviewDataLoading = () => { },
  isPreviewDataLoading,
  parentError = undefined
}) {
  let isQueryKill = useSelector((state => state.CommonComponent?.DataGridTable?.isQueryKill))
  let responseUUID = useSelector((state => state.CommonComponent?.DataGridTable?.uuid))

  let cloneTableData = cloneDeep(tableData);
  // if(responseUUID !== uuid){
  //   cloneTableData={}
  // }
  return (
    !showLoader ? <div className="create-result-section mt-1">
      <div>
        <DataTabs
          props={{
            activeKey: activeTabKey,
            defaultKey: "",
            onEdit: onEdit,
            onChange: (activeLeftPanelKey) => {
              onTabChange(activeLeftPanelKey);
            },
            tabArray: tabArray,
          }}
        />
      </div>
      {(Object.keys(cloneTableData).length === 0 && (activeTabKey === '0' || activeTabKey === 'logs') && !showLoader) ? (
        <>
          <div className="no-flow-preview-data text-center">
            <NoResultsFound />
          </div>
        </>
      )
        : <>
          {isQueryKill ? <QueryKillError /> : <>{cloneTableData && uuid === responseUUID &&
            Object.keys(cloneTableData).length !== 0 ?
            (
              <div className="flow-lower-section" style={{ overflow: "hidden", height: "calc(100% - 28px)" }}>
                <div id="alert-lower-table-sec" className="flow-lower-table bg-white custom-virtual-data-table" >
                  <DataGridTable
                    hideReloadBtn={true}
                    parentError={parentError}
                    resp={cloneTableData}
                    CurrentPODName={currentPODName}
                    CurrentDomainName={currentDomainName}
                    uniqUUID={uuid}
                    setUniqUUID={setUniqUUID}
                    requestedTableId={requestedTableId}
                    isDisableHideBtn={isDisableHideBtn}
                    isShowFilter={isShowFilter}
                    setshowLoader={setshowLoader}
                    showLoader={showLoader}
                    setPreviewAlreadyLoading={setIsPreviewDataLoading}
                    isPreviewDataAlreadyLoading={isPreviewDataLoading}
                    showDictionaryDetails={false}

                  />

                </div>
              </div>
            ) : (parentError ? getReloadErrorTemplate({ errorMessage: parentError, hideReloadBtn: true }) : uuid !== responseUUID && !showLoader
              ? <div className="no-flow-preview-data text-center">
                <NoResultsFound />
              </div> : null)
          }
          </>

          }</>

      }

    </div> :
      showLoader && uuid ? <div className="create-result-section mt-3">
        <DataTabs
          props={{
            activeKey: activeTabKey,
            defaultKey: "",
            onEdit: onEdit,
            // onChange: (activeLeftPanelKey) => {
            //   onTabChange(activeLeftPanelKey);
            // },
            tabArray: tabArray,
          }}
        />

        <ClaristaLoader height="30%" width="100%" isCancel={true} uuid={uuid} setCancelQueryDone={setshowLoader} />



        {/* <SkeletonAvatar size={100} shape="square" block={true} loading={true} active={true} className="resultsection-skeleton p-0 m-0" />  */}
      </div> : null
  );
}

export default ResultSection;
