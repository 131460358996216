import { Radio, Tooltip, Tree } from 'antd';
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { fetchDatabaseTableList, fetchTableData } from '../../../../../store/actions';
import { setSchemaTreeMap, setTargetTableDetails } from '../../../../../store/modules/flows/targetTransformer/targetTransformer';
import { DATASET } from '../../../../Common/iconSource';
import { LandingSimpleTable, LandingSimpleTableInner } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';
import { showSimpleLoading } from '../../../common/helperFunctions';
import { fetchPreviewTable } from '../../../../../store/modules/common/VirtualDataTable/action';
import { v4 as uuidv4 } from 'uuid';


const S3schemaTree = ({
    connectionName,
    setSchemaDropdownValue,
    settargetTableData,
    setnewTableName,
    selectedSchema,
    treeData = [],
    setTreeData = () => { }
}) => {

    const store = useStore();
    const dispatch = useDispatch();
    const { schemaTreeMap } = useSelector(state => state.Flows.TargetTransformer);
    const targetTableDetails = useSelector(state => state.Flows.TargetTransformer.targetTableDetails)

    const sourceDropdownList = [

    ];

    const [data, setData] = useState([...treeData]);
    const [, setCheckedItems] = useState([]);
    const [, setIsGenerateQueryBtnVisible] = useState(false);
    const [showLoader, setshowLoader] = useState(false)
    useEffect(() => {
        setData([...treeData])
    }, [treeData])

    const fetchS3TableList = () => {
        setshowLoader(true)
        try {
            let requestBody = {

                "connection_name": connectionName,
                "schema_name": `${connectionName}__temp__`
            }

            dispatch(fetchDatabaseTableList(requestBody)).then(
                res => {
                    if (res) {

                        let targetDetails = { ...targetTableDetails }
                        targetDetails.connnectionTableList = res.data.data_record
                        store.dispatch(setTargetTableDetails(targetDetails))
                        const data = res.data.data_record;
                        const schemaNames = data.flat();
                        setData(() => {
                            let temp = schemaNames.map((v, i) => (
                                {
                                    name: v,
                                    rawName: v,
                                    id: i,
                                    isExpanded: false,
                                    isLoaded: false
                                }
                            ))
                            return temp
                        })
                        const { schemaTreeMap } = store.getState().Flows.TargetTransformer;
                        const newschemaTreeMap = cloneDeep(schemaTreeMap);

                        schemaNames.forEach((schemaName, index) => {
                            sourceDropdownList.push({ key: index, Name: schemaName, Type: "", value: schemaName })
                            newschemaTreeMap[schemaName] = {};
                        })
                        setSchemaDropdownValue([...sourceDropdownList])
                        dispatch(setSchemaTreeMap(newschemaTreeMap));
                        setshowLoader(false)

                    }
                }
            )
        }
        catch (error) {
            setshowLoader(false)

            console.error("Error fetching Schema List", error.message);
        }
    }

    // const createRequestBodyHandler = (schemaName, tableName) => {
    //     const requestBody = {
    //         "action": "preview_table",
    //         "connection_name": connectionName,
    //         "schema_name": schemaName,
    //         "table_name": tableName,

    //     }

    //     return requestBody;
    // }


    const createRequestBodyHandler = (schemaName, tableName) => {
        let id = uuidv4();

        let requestBody = {
            uuid: id,
            connection_name: connectionName,
            schema_name: schemaName,
            table_name: tableName,
            page: 0,
            do_count: false,
            sort_body: [],
            filter_body: [],
            table_ids: []

        }

        return requestBody;
    }

    const importPodInTable = async (schemaName, tableName, id) => {
        let dataList = [...data]
        try {
            const requestBody = createRequestBodyHandler(schemaName, tableName);
            const { data } = await dispatch(fetchPreviewTable(requestBody));
            settargetTableData(data.result)
            handleRow(dataList, id)
            selectedSchema(schemaName)
            setnewTableName(tableName)
        }
        catch (error) {
            console.error("Error fetching table Data", error.message);
        }
    }


    const setCheckedItemsHandler = () => {
        const checkedItems = [];

        for (let key in schemaTreeMap) {
            const schemaName = key;
            const schemaTableNames = Object.keys(schemaTreeMap[schemaName]).filter((tableKeys) => tableKeys !== "tableCount");

            const tableCount = schemaTreeMap[schemaName].tableCount;

            if (schemaTableNames.length === tableCount) {
                checkedItems.push(schemaName);
            }
            else {
                schemaTableNames.forEach((tableName) => {
                    checkedItems.push(`${schemaName}-${tableName}`);
                })
            }


        }
        setCheckedItems(checkedItems);
    }



    useEffect(() => {
        if (connectionName && treeData.length === 0) {
            fetchS3TableList();
        }
    }, [connectionName, treeData]);


    useEffect(() => {
        setCheckedItemsHandler();
    }, [schemaTreeMap])

    useEffect(() => {
        const schemaNames = Object.keys(schemaTreeMap);

        if (schemaNames.length > 1) {
            for (let schemaName of schemaNames) {
                if (Object.keys(schemaTreeMap[schemaName]).length > 1) {
                    setIsGenerateQueryBtnVisible(true);
                }
            }
        }
    }, [schemaTreeMap])

    function handleRow(rows, id) {
        const row = rows.find((r) => r.id === id);
        if (!row || !row.parentId) {
            setData([...rows])
        }
        const newRows = rows.map(r => ({ ...r, isChecked: r.id === id }));
        setData([...newRows])
        setTreeData([...newRows])
    }

    const onTableClick = (tableName, isChecked, id) => {
        const schemaName = `${connectionName}__temp__`
        if (!isChecked) {
            importPodInTable(schemaName, tableName, id);
        }
    };

    const childNameTemplate = ({ name, isChecked = false, ...row }) => {

        return (
            <React.Fragment>
                <Tooltip title={name} placement="left">
                    <p
                        className={`${isChecked ? 'color-primary font-w-600' : ''} paragraph mb-0 mx-n2 text-with-ellipsis position-relative`}
                        onClick={() => {
                            onTableClick(name, isChecked, row.id)
                        }}
                        style={{ paddingLeft: '51px' }}
                    >
                        <i style={{
                            position: 'absolute',
                            top: '4px',
                            fontSize: '24px',
                            left: '22px',
                            cursor:"pointer"
                        }}
                            className={`bx bx-radio-circle${isChecked ? '-marked color-primary' : ' text-black-50'}`}></i>
                        <DATASET width='18' height='18' /><span className="p-1"></span>
                        {name}
                    </p>
                </Tooltip>
            </React.Fragment>
        )
    }

    const columns = [
        {
            name: 'Tables',
            key: "name",
            sortable: false,
            resizable: false,
            renderCell({ row }) {

                return (
                    <>
                        <div className="rdg-cell-value">
                            {
                                childNameTemplate(row)
                            }
                        </div>
                    </>
                );
            }
        }
    ];

    return (
        <div className="create-pod-table-container custom-dgt-tree pt-2" style={{ height: 'calc(100% - 103px)' }}>
            <div className="create-pod-table-header">
                <h3>Select Tables</h3>
            </div>
            {data.length && !showLoader ? <LandingSimpleTable
                cols={columns}
                rowsData={data}
                showCursor={false}
                tableHeight={'calc(100% - 32px)'}
                rowHeight={30}
                headerRowHeight={0}
            /> : !showLoader ? <span className='label' style={{ justifyContent: "center", display: "flex", color: "#d3d3d3", font: "30px" }}>No Tables Found</span> : ''
            }
            {showLoader ? showSimpleLoading("Please Wait Loading Tables List...") : null}
        </div>
    )
}

export default S3schemaTree
