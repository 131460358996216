import { Tooltip } from 'antd'
import React from 'react'
import './customProgressBar.scss'

const CustomProgressBar = ({
    data = []
}) => {

    return (
        <React.Fragment>
            <div>
                <span className="custom-prog">
                    {
                        data.map((el, i) => (
                            <Tooltip 
                                key={`prog-bar-${el?.id}-${i}`}
                                color="#FFF"
                                placement="top" 
                                title={
                                    <span className='font-w-600 text-dark'>
                                    <span style={{color : `${el?.color??'#2C83C4'}`}}>{el?.percent??0}%</span> {el.title}
                                    </span>
                                }
                            >
                                <span onClick={() => {
                                    el.onClick()
                                    }} style={{width: `${el?.percent??0}%`}}></span>
                            </Tooltip>
                        ))
                    }
                </span>
                </div>
        </React.Fragment>
    )
}

export default CustomProgressBar