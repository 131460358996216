import React from 'react'
import { connect } from 'react-redux'
import FiltersIndex from '../transformers/filters/FiltersIndex';
import DataSource from '../transformers/DataSource/DataSource';
import MergeIndex from '../transformers/merge/MergeIndex';
import ScriptIndex from '../transformers/script/ScriptIndex';
import SQLIndex from '../transformers/sql/SQLIndex';
import TargetIndex from '../transformers/target/TargetIndex';
import ScriptOutputNodeIndex from '../transformers/scriptOutput/ScriptOutputNodeIndex';
import AlertTransformerIndex from '../transformers/alert/AlertTransformerIndex';
import SchemaTransformerIndex from '../transformers/schema/SchemaTransformerIndex';
import AggregateTransformerIndex from '../transformers/aggregate/AggregateTransformerIndex';
import DatabricksIndex from '../transformers/databricks/DatabricksIndex';
import DatabricksOutputDataSet from '../transformers/databricks/DatabricksOutputDataSet';
import DatabricksOutputNodeIndex from '../transformers/databricksOutput/DatabricksOutputNodeIndex';

const LeftSideUpperContent = (props) => {
    let { currentTransformer, isExecutePermission = true, isEditPermission = true, isJupyterPermission = true } = props


    const ShowSelectedPage = () => {

        switch (currentTransformer && currentTransformer.type) {
            case "datasource":
                return <DataSource isExecutePermission={isExecutePermission} />

            case "merge":
                return <MergeIndex isExecutePermission={isExecutePermission} />

            case "filters":
                return <FiltersIndex isExecutePermission={isExecutePermission} />

            case "script":
                return <ScriptIndex isJupyterPermission={isJupyterPermission} isEditPermission={isEditPermission} isExecutePermission={isExecutePermission} />

            case "scriptOutput":
                return <ScriptOutputNodeIndex isExecutePermission={isExecutePermission} />

            case "databricks":
                return <DatabricksIndex isJupyterPermission={isJupyterPermission} isEditPermission={isEditPermission} isExecutePermission={isExecutePermission} />

            case "bricksOutput":
                return <DatabricksOutputNodeIndex isExecutePermission={isExecutePermission} />

            case "sql":
                return <SQLIndex isExecutePermission={isExecutePermission} isEditPermission={isEditPermission} />

            case "target":
                return <TargetIndex isExecutePermission={isExecutePermission} />

            case "alert":
                return <AlertTransformerIndex isExecutePermission={isExecutePermission} />

            case "schema":
                return <SchemaTransformerIndex isExecutePermission={isExecutePermission} />

            case "aggregate":
                return <AggregateTransformerIndex isExecutePermission={isExecutePermission} />

            default:
                return <></>

        }

    }



    return (
        <>
            <ShowSelectedPage />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        currentTransformer: state.FlowsReducer.currentTransformer
    };
};
export default connect(mapStateToProps)(LeftSideUpperContent)