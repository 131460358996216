import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
//Import Images
// import logoImage from "../../assets/images/new-logo.png"

import logoImage from "../../../assets/images/new-logo.png"
import { LogoutSVG } from './LogoutSvg';



class Logout extends Component {
    render() {
        return (
            <React.Fragment>

                <div className="pt-4 logout-div">
                    <div className='pl-3'>
                        <img src={logoImage} alt="" />
                    </div>
                    <Container>

                        <Row>
                            <Col lg="12">
                                <div className="text-center mb-5">
                                    <LogoutSVG />
                                    <h4 style={{ fontFamily: 'interBold' }}>You have been logged out</h4>
                                    <div className="mt-5 d-flex justify-content-center">
                                        <Link className="custom-btn btn-primary btn-with-text col-2 " to={`/login${window.location.search}`}> Login</Link>  
                                        {/* <i class="bx bx-cirle bx-arrow-back" style={{color:"#FFF"}}></i> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

export default Logout;