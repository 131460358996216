import React from 'react'
import CopilotAlertCapsule from './CopilotAlertCapsule'
import ConversationSender from './ConversationSender'
import ReplyMessage from './ReplyMessage'
import SuggestionPill from './SuggestionPill'
import AutoGrowInput from '../connections/landingPage/components/autoGrowInput/AutoGrowInput'
import CopilotLoader from './CopilotLoader'
import { useDispatch, useSelector } from 'react-redux'
import { ICON_CHECK, ICON_CLOSE, ICON_EDIT, ICON_TABLE } from '../../Common/newIconSource'
import { createPodCopilot, dashboardCopilot } from '../../../store/modules/common/ClaristaCopilot/ClaristaCopilotReducer'
import CopilotMic from './CopilotMic'
import { getDataDomainPODList } from '../../../store/actions'
import { Checkbox } from 'antd'
import Buttons from '../../Common/button/Buttons'
import { Literals } from '../common/literals'
import SearchInput from '../../Common/search/Search'
import ClaristaCopilot from '.'
import ClaristaLoader from '../../Common/claristaLoader/ClaristaLoader'
import { getReloadErrorTemplate } from '../common/helperFunctions'

const DashboardCopilot = React.forwardRef(({
    props
}, ref) => {

    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [conversations, setConversations] = React.useState([])
    const [selectedTables, setSelectedTables] = React.useState([])
    const [loadingPods, setLoadingPods] = React.useState([])
    const [podError, setPodError] = React.useState(undefined)
    const [podList, setPodList] = React.useState([])
    const [podSearchValue, setPodSearchValue] = React.useState('')
    const [podModalOpen, setPodModalOpen] = React.useState(false)
    const [copilotData, setCopilotData] = React.useState({})
    const [hideMic, setHideMic] = React.useState(false)
    const [micListening, setMicListening] = React.useState(false)

    const chatInputRef = React.useRef({})
    const cpilotContentRef = React.useRef({})

    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    React.useImperativeHandle(ref, () => {
        return {
            
        }
    }, [])

    React.useEffect(() => {
        if(selectedTables?.length === 0) {
            chatInputRef?.current?.setInputDisabled(true)
            setConversations([])
        }
        else {
            chatInputRef?.current?.setInputDisabled(false)
        }
    }, [selectedTables])

    React.useEffect(() => {
        getDomainPod()
    }, [props?.dataDomainId])
    
    const getDomainPod = () => {
        setPodError(undefined)
        setLoadingPods(true)
        let payload = {
            data_domain_id: props?.dataDomainId
        }

        if(payload?.data_domain_id === undefined) {
            setLoadingPods(false)
            return
        }

        dispatch(getDataDomainPODList(payload, false))
        .then(res => {
            let data = res?.data
            setPodList([...data])
            setLoadingPods(false)
        })
        .catch(err => {
            setPodError(err?.message)
            setLoadingPods(false)
        })
    }

    // getDataDomainPODList

    const handleChat = (msg) => {
        chatInputRef?.current?.setInputValue('')
        setConversations(prev => ([...prev, {user_type: "HUMAN", message: msg}]))
        setHideMic(false)
        setError(undefined)
        setLoading(true)
        let payload = {
            dashboard_id: props?.dashboardData?.id,
            tables: selectedTables?.map(t => ({table_id: t?.table_id})),
            user_chat: [
                ...conversations,
                {
                    "user_type": "HUMAN",
                    "message": msg
                }
            ],
            copilot_context: copilotData?.copilot_context ?? {}
        }

        chatInputRef?.current?.setInputDisabled(true)
        setTimeout(() => {
            cpilotContentRef?.current?.scrollTo(0, cpilotContentRef?.current?.scrollHeight)
        }, 500)

        dispatch(dashboardCopilot(payload, false))
        .then(res => {

            setCopilotData(res?.data)

            let dat = res?.data?.user_chat
            setConversations([...dat])
            chatInputRef?.current?.setInputDisabled(false)
            setLoading(false)

            props?.setReady(false)

            setTimeout(() => {
                props?.setReady(true)
            }, 1000)

            setTimeout(() => {
                cpilotContentRef?.current?.scrollTo(0, cpilotContentRef?.current?.scrollHeight)
            }, 500)
        })
        .catch(err => {
            console.error({err})
            setLoading(false)
            setError(err?.message)
            setConversations(prev => prev?.slice(0, prev?.length - 1))
            chatInputRef?.current?.setInputValue(msg)
            chatInputRef?.current?.setInputDisabled(false)
            setTimeout(() => {
                cpilotContentRef?.current?.scrollTo(0, cpilotContentRef?.current?.scrollHeight)
            }, 500)
        })
    }

    const onDonePodChoose = () => {
        let list = [...podList]?.filter(p => p?.selected)
        setSelectedTables([...list])
        setPodModalOpen(false)
    }

    return (
        <div className='dash-cpilot-wrap position-relative'>
            {
                podModalOpen
                ?
                <div className='dashboard-select-table-pop px-0'>
                    <div className='px-2 pb-1'>
                        <SearchInput
                            searchData={podSearchValue}
                            setSearchData={setPodSearchValue}
                            autoFocus={true}
                        />
                    </div>
                    <div className='px-2' style={{maxHeight: 300, overflowY: 'auto'}}>
                        {
                            loadingPods ? <ClaristaLoader height='200px' />
                            :

                            podError ? getReloadErrorTemplate({
                                errorMessage: podError, 
                                onReload: () => {
                                    getDomainPod()
                                }
                            })
                            :

                            podList?.filter(p => p?.table_name?.toLowerCase()?.includes(podSearchValue?.toLowerCase()))?.length === 0
                            ?
                            <div className='py-3 text-black-50 text-center fontSizeLabel'>
                                No Pods Found
                            </div>
                            :
                            podList?.filter(p => p?.table_name?.toLowerCase()?.includes(podSearchValue?.toLowerCase()))
                            ?.map(p => (
                                <div key={`${p?.table_id}-pod-li`} className='py-1 fontInterSemiBold fontSizeLabel d-flex align-items-center'>
                                    <div className='mr-2'>
                                        <Checkbox
                                            id={`chktbl${p?.table_id}`}
                                            checked={p?.selected ?? false}
                                            onChange={(e) => {
                                                let chk = e?.target?.checked
                                                setPodList(prev => (
                                                    prev?.map(t => ({...t, selected: t?.table_id === p?.table_id ? chk : t?.selected ?? false}))
                                                ))
                                            }}
                                        />
                                    </div>
                                    <label htmlFor={`chktbl${p?.table_id}`} 
                                        className='mb-0 text-uppercase' style={{wordBreak: 'break-all', cursor: 'pointer'}}>
                                        {p?.table_name}
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                    <div className='mt-2 d-flex justify-content-end px-2'>
                        <Buttons
                            props={{
                                buttonText: "Close",
                                tooltip: "",
                                buttonClassName: `custom-btn-outline custom-btn btn-with-text mr-2 bg-light`,
                                buttonEvent: () => {
                                    setPodModalOpen(false)
                                },
                                ImgSrc: () => <ICON_CLOSE/>,
                                isDisable: false,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "Done",
                                tooltip: "",
                                buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                buttonEvent: () => {
                                    onDonePodChoose()
                                },
                                ImgSrc: () => <ICON_CHECK/>,
                                isDisable: !podList?.some(p => p?.selected !== undefined && p?.selected === true),
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </div>
                </div>
                :
                <>
                    <div className='cpilot-content-wrap' ref={cpilotContentRef}>

                        {
                            selectedTables?.length > 0 ? ''
                            :
                            <div className='pt-2'>
                                <h4 className='cpilot-head-lg fontInterSemiBold text-capitalize'>
                                    Hello {`${userDetailsRedux?.first_name ?? ''} ${userDetailsRedux?.last_name ?? ''}`}!
                                </h4>
                                <h4 className='cpilot-head-md fontInterSemiBold'>
                                    What Chart Can I Create for You Today?
                                </h4>
                            </div>
                        }
                        <div className='p-2'></div>
                        {
                            selectedTables?.length > 0 ? ''
                            :
                            <div className='mb-4'>
                                <CopilotAlertCapsule
                                    content={
                                        <div className='fontSizeHeading'>
                                            To create a chart instantly, simply select your table and ask your question by "Clicking" me
                                        </div>
                                    }
                                    onClick={() => {
                                        setPodModalOpen(true)
                                    }}
                                />
                            </div>
                        }
                        {
                            selectedTables?.length > 0
                            ?
                            <>
                                <div className='d-flex align-items-center mb-1'>
                                    <label className='mb-0 fontSizeLabel fontInterSemiBold mr-2'>Selected tables</label>
                                    {/* <Buttons
                                        props={{
                                            buttonText: "",
                                            tooltip: "",
                                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon`,
                                            buttonEvent: () => {
                                                setPodModalOpen(true)
                                            },
                                            ImgSrc: () => <ICON_EDIT/>,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    /> */}
                                    <button 
                                        className='align-items-center border-0 btn btn-light btn-sm d-flex p-1'
                                        style={{height: 20, width: 20}}
                                        onClick={() => {
                                            setPodModalOpen(true)
                                        }}
                                        >
                                        <ICON_EDIT width='10' height='10' />
                                    </button>
                                </div>
                                <div className='cpilot-simple-msg-card border-0 rounded p-2 mb-2' style={{width: 'fit-content'}}>
                                    <div className='bg-white rounded'>
                                        {
                                            selectedTables
                                            ?.map((t, i) => (
                                                <div key={`${t?.table_id}.${t?.table_name}-${i}`} className='d-flex px-2 py-1'>
                                                    <div className='d-flex mr-2'><ICON_TABLE width='14' height='14' /></div>
                                                    <div className='fontSizeLabel fontInterSemiBold'>
                                                        {t?.table_name}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </>
                            :   ''
                        }
                        <div>
                            {
                                conversations?.map((c, i) => (
                                    <div
                                        key={`copilot-conversation-${i + 1}`}
                                    >
                                        {
                                            c?.user_type?.toLowerCase() === "human"
                                            ?
                                            <ConversationSender
                                                msg={c?.message ?? ''}
                                            />
                                            :
                                            <ReplyMessage
                                                msg={c?.message ?? ''}
                                                sql={c?.sql_query ?? ''}
                                            />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        {
                            error ? <CopilotAlertCapsule content={<div className='fontSizeHeading'>{error}</div>}/> 
                            :
                            loading ? <div className='my-3 px-3'><CopilotLoader/></div> : ''
                        }

                        {
                            selectedTables?.length > 0 && conversations?.length === 0
                            ?
                            <div className='my-2'>
                                <SuggestionPill
                                    msg={"Table selected! Ask me to create a chart for you"}
                                />
                            </div>
                            : ''
                        }

                    </div>
                    <div className='cpilot-ask-inp'>
                        <AutoGrowInput
                            placeholder={selectedTables?.length === 0 
                                ? "Select table to get started" 
                                : "Start typing..."}
                            onEnterCallback={val => {
                                handleChat(val)
                            }}
                            setFocus={focus => {
                                if(micListening) {
                                   setHideMic(false) 
                                }
                                else {
                                    setHideMic(focus)
        
                                }
                            }}
                            ref={chatInputRef}
                        />
                        <div className={`cpilot-clearbtn ${!hideMic || micListening ? 'd-none' : ''}`}>
                            <Buttons
                                props={{
                                    buttonText: '',
                                    tooltip: '',
                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon bg-transparent`,
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        chatInputRef?.current?.setInputValue("")
                                        setHideMic(false)                             
                                    },
                                    ImgSrc: () => <ICON_CLOSE/>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        </div>
                        <span className={hideMic && !micListening ? 'd-none' : ''}>
                            <CopilotMic
                                inputRef={chatInputRef}
                                getListening={listening => {
                                    setMicListening(listening)
                                }}
                                disabled={selectedTables?.length === 0}
                            />
                        </span>
                    </div>
                </>
            }            
        </div>
    )
})

export default DashboardCopilot