import React from 'react';
import { Spin } from 'antd';
import './loadinComponent.scss'
import ClaristaLoader from '../../Common/claristaLoader/ClaristaLoader';
const LoadingComponent = () => {
    

    return (
        <div style={{
            position: 'fixed', 
            top: '34px', 
            left: 0, 
            zIndex: 1055, 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            // background: '#ffffff70'
            // background: '#000000d6'
        }}>
            <ClaristaLoader/>
        </div>
        // <div className="loader">
        //     <Spin tip="Loading...">
        //     </Spin>
        // </div>
    )
}
export default LoadingComponent