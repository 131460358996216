import React, { useEffect, useRef, useState } from "react";


import { connect, useDispatch, useStore } from "react-redux";


import { useParams } from "react-router";
import { Tabs } from "antd";

import { checkOutcomeColumnPresent, getInputTransformerDetails, orderRetainCheck } from "../commonFunctions/FlowsHelperFunction";
import _, { cloneDeep } from "lodash";
import { getConfirmationModal, getObjectLength, mergeArrayByProperty, showSimpleLoading } from "../../../common/helperFunctions";
import { SCRIPTDEFAULTVIEW } from "../commonFunctions/ScriptDefaultInfography";
import DndSchemaList from "../commonFunctions/dndMultiColumnsVirtualList/DndSchemaList";
import { RESETFILTER } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import Buttons from "../../../../Common/button/Buttons";



const SchemaTransformerIndex = ({
  flowsElements,
  setshowOverlay,
  currentTransformer,
  updatedFlowRedux,
  isExecutePermission,
  setupdatedFlowRedux,
  setisrunDisabled,
  setshowCloseWarning,
  ...props
}) => {
  const [columnList, setcolumnList] = useState([]);

  const [reOrderRender, setreOrderRender] = useState(true)
  const [openWarningModal, setopenWarningModal] = useState(false)
  const [showResetColumns, setshowResetColumns] = useState(false)
  const [showLoader, setshowLoader] = useState(false);

  const store = useStore();
  const { flowId } = useParams();

  const dispatch = useDispatch();

  const [toggleDefaultView, setToggleDefaultView] = useState(false);



  const columnListRef = useRef([])
  useEffect(() => {
    if (columnList && columnList.length) {

      columnListRef.current = columnList;


      // return () => {
      let currentFlowsElements = cloneDeep(store.getState().Lab.flowsElements)
      let nodes = currentFlowsElements?.nodes?.map(item => {
        if (item.id === currentTransformer.id) {
          let list = columnListRef.current.filter(item => item?.checked);
          // if (list?.length) {
          item.content.orderList = columnListRef.current;
          item.content.appliedFilterCriteria = list?.length ? list : [];
          // }

        }
        return item
      })
      // console.log(nodes,columnList)
      let finalFlowsElements = cloneDeep(flowsElements)
      finalFlowsElements['nodes'] = nodes;
      setupdatedFlowRedux(finalFlowsElements)
      // store.dispatch(updateFlowsElements(finalFlowsElements))
      // }

    }

  }, [columnList])



  const Selectall = (checked) => {
    if (checked) {
      let temp = columnList.map((ele) => {
        ele.checked = true;
        return ele;
      });
      setshowCloseWarning(true)

      setcolumnList([...temp]);
    } else {
      let temp = columnList.map((ele) => {
        ele.checked = false;
        return ele;
      });
      setshowCloseWarning(true)

      setcolumnList([...temp]);
    }
  };

  const getDataType = (actualDataType = '', index) => {

    if (actualDataType.includes('(')) {
      return actualDataType.split('(')[0]
    } else {
      return actualDataType
    }

  }
  const getDataTypeFormat = (actualDataType = '', index) => {

    if (actualDataType.includes('(')) {
      let splitStr = actualDataType.split('(');

      return splitStr[1]?.split(')')[0];
    } else {
      return ''
    }

  }

  const createColumnsList = (target = [], source = [], prop) => {

    let newColumnList = [];

    if (!target?.length) {
      source.forEach(sourceElement => {
        sourceElement['showError'] = false
        sourceElement['old_name'] = sourceElement.Name
        sourceElement['new_name'] = sourceElement.Name
        sourceElement['new_datatype'] = getDataType(sourceElement?.Type)
        sourceElement['dataType'] = getDataType(sourceElement?.Type)
        sourceElement['format'] = getDataTypeFormat(sourceElement?.Type)
        sourceElement['text'] = sourceElement.text
        newColumnList.push(sourceElement);
      })
      return newColumnList;
    } else {

      let elementFound = iselementFound();
      let noneFindElement = isnoneFindElement();


      if (elementFound?.length && noneFindElement?.length) {
        let mergeList = (mergeArrayByProperty(elementFound, noneFindElement, 'Name', true))
        newColumnList = [mergeList]
        // newColumnList = [...elementFound,...noneFindElement]


      }
      else if (elementFound?.length) {
        newColumnList = [...elementFound]

      } else if (noneFindElement?.length) {
        newColumnList = [...noneFindElement]
        // setshowResetColumns(true);
        // setisrunDisabled(true)


      } else {
        setshowResetColumns(false)
        setisrunDisabled(false)



      }
      let isShowErrorPresent = newColumnList?.findIndex((item) => item.showError)
      if (isShowErrorPresent > -1) {
        setshowResetColumns(true);
        setisrunDisabled(true)
      } else {
        setshowResetColumns(false)
        setisrunDisabled(false)

      }
      let cloneSource = cloneDeep(source);

      const getChecked = (column) => {
        if (elementFound?.length) {
          return elementFound.find(item => item?.Name === column?.Name)?.checked
        } else if (target?.length) {

          return target.find(item => item?.Name === column?.Name)?.checked
        }
      }

      cloneSource = cloneSource.map(sourceElement => {

        sourceElement['showError'] = false;
        sourceElement['checked'] = getChecked(sourceElement) ?? false;
        sourceElement['old_name'] = sourceElement.Name
        sourceElement['new_name'] = sourceElement.Name
        sourceElement['new_datatype'] = getDataType(sourceElement?.Type)
        sourceElement['dataType'] = getDataType(sourceElement?.Type)
        sourceElement['format'] = getDataTypeFormat(sourceElement?.Type)
        sourceElement['text'] = sourceElement?.text

        return sourceElement;
      })

      // return (mergeArrayByProperty(cloneSource, newColumnList, 'Name', true))

      let mergeList = (mergeArrayByProperty(cloneSource, newColumnList, 'Name', true))  //----------------------------this will return unique column remove duplicates from source columns i.e if duplicate found it will take newColumnList column
      let newcurrentTransformer = {}

      if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

        newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
      } else {
        newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

      }

      let orderList = newcurrentTransformer?.content?.orderList;
      return orderRetainCheck(orderList, mergeList);
    }

    function isnoneFindElement() {

      if (target?.length) {
        let targetElement = cloneDeep(target)
        source.forEach(sourceElement => {
          targetElement = targetElement.filter((item) => {

            if (item['Name'] !== sourceElement['Name']) {
              item['showError'] = true;
              item['checked'] = true;
              item['text'] = sourceElement['text']
             
              return item;
            }
            // else if (item['dataType'] && item['dataType'] !== sourceElement['Type']) {
            //   item['dataType'] = getDataType(sourceElement?.Type)
            //   item['new_datatype'] = getDataType(item?.new_datatype)

            //   return item;
            // }
          })
        })
        return targetElement
      }

    }

    function iselementFound() {

      if (target?.length) {
        let targetElement = cloneDeep(target)
        let foundEleList = []
        source.forEach(sourceElement => {
          targetElement.forEach((item) => {

            if (item['Name'] === sourceElement['Name']) {
              let obj = cloneDeep(sourceElement)
              obj['showError'] = false;
              obj['old_name'] = item.old_name
              obj['new_name'] = item.new_name
              obj['dataType'] = getDataType(sourceElement?.Type)
              obj['new_datatype'] = getDataType(item?.new_datatype)
              obj['format'] = item?.format
              obj['checked'] = true;
              obj['text'] = item['text']

              foundEleList.push(obj)
            }
          })
        })
        return foundEleList


      }

    }



  }


  useEffect(() => {
    setshowLoader(true)
    const getColumn = async () => {
      let InputTransformer = dispatch(getInputTransformerDetails());

      // let inputColumns = InputTransformer?.length ? cloneDeep(InputTransformer[0]?.content?.outcomeColumns) : [];
      let inputColumns = [];

      if (InputTransformer && InputTransformer?.length) {

        inputColumns = await dispatch(checkOutcomeColumnPresent(InputTransformer[0]));

      }
      let newcurrentTransformer = {}

      if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

        newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
      } else {
        newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

      }

      //console.log(newcurrentTransformer,updatedFlowRedux)

      if (InputTransformer && InputTransformer?.length && inputColumns?.length) {

        let column = []


        // if (!newcurrentTransformer?.content?.appliedFilterCriteria && newcurrentTransformer?.content?.outcomeColumns && newcurrentTransformer?.content?.outcomeColumns[0]?.text) {//// this need to remove after some time its used for backword compatability
        //   column = createColumnsList(newcurrentTransformer?.content?.outcomeColumns, inputColumns, 'Name')

        // } else {

        column = createColumnsList(newcurrentTransformer?.content?.appliedFilterCriteria, inputColumns, 'Name')
        // }

        if (column?.length) {

          setcolumnList([...column])
          setshowLoader(false)
          setToggleDefaultView(false);
          setshowOverlay(false)

        } else {

          setcolumnList([])
          setshowOverlay(true)
          setshowLoader(false)


        }


      } else if (!InputTransformer?.length) {
        setToggleDefaultView(true);
        setshowLoader(false)


      } else if (!inputColumns?.length) {
        setshowOverlay(true);
        setshowLoader(false)

      }
    }
    getColumn()

  }, []);


  const currentConditionTypeRef = useRef(null)
  const onResetEvent = () => {

    currentConditionTypeRef.current = {
      message: 'Columns that are not found in input source that will be removed',
      onConfirm: () => {
        setreOrderRender(false)
        let cloneCols = cloneDeep(columnList);
        // cloneCols = cloneCols.map((item, i) => {
        //   if (item.showError) {
        //     item.id = `emptyColumn_${new Date().getTime()}_${i}}`
        //     item.checked = false
        //     item.disabled = true
        //     item['old_name'] = ''
        //     item['new_name'] = ''
        //     item['new_datatype'] = ''
        //     item['dataType'] = ''
        //     item['format'] = ''
        //     item.Name = ""
        //     item.Type = ""
        //     item.showError = false
        //   }
        //   return item
        // });
        cloneCols = cloneCols.filter((item) => !item.showError);

        if (cloneCols?.length) {

          setcolumnList([...cloneCols])
        }
        setopenWarningModal(false);
        setshowResetColumns(false);
        setisrunDisabled(false)

        setTimeout(() => {
          setreOrderRender(true)
        }, 50);
        return true
      },
      onCancel: () => {
        setopenWarningModal(false);
        return false
      }
    }
    setopenWarningModal(true)
  }

  return (
    <>
      {
        getConfirmationModal({
          open: openWarningModal,
          setOpen: setopenWarningModal,
          body: currentConditionTypeRef?.current?.message,
          onConfirm: currentConditionTypeRef?.current?.onConfirm,
          onCancel: currentConditionTypeRef?.current?.onCancel
        })
      }
      {!showLoader ? <div className="transformer-details-maindiv pt-2">
        {showResetColumns ? (
          <Buttons
            props={{
              tooltip: 'Reset Columns',
              buttonText: "Reset Columns",
              buttonClassName: "custom-btn-outline btn-outline reset-col-btn custom-btn btn-with-text ml-2 mr-2",
              buttonEvent: onResetEvent,
              toggleBtnValue: "",
              ImgSrc: () => <RESETFILTER />,
              isDisable: !isExecutePermission,
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              toggleBtnOption: null,
            }}
          />
        ) : null}
        <>
          {!toggleDefaultView ? (
            <div className="bg-white section-with-drag flow-filter-height h-100">
              <div
                className="flows-filter-props-win h-100 schemaTransformer-table"
                style={{ overflow: "hidden" }}
              >
                <DndSchemaList
                  reOrderRender={reOrderRender} setreOrderRender={setreOrderRender}
                  actualData={columnList}
                  isExecutePermission={isExecutePermission}
                  stopDraggingRow={
                    window.location.href.includes("viewFlows") || !isExecutePermission ? true : false
                  }
                  setNewColumnList={setcolumnList}
                  Selectall={Selectall}
                  setshowCloseWarning={setshowCloseWarning}
                />
              </div>
            </div>
          ) : (
            <div className="source-not-found">
              <SCRIPTDEFAULTVIEW />
            </div>
          )}
        </>

      </div> :
        showSimpleLoading('Please wait loading transformer details...')
      }




    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.Lab.flowsElements,
    currentTransformer: state.Lab.currentTransformer,
  };
};
export default connect(mapStateToProps)(SchemaTransformerIndex);
