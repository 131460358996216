import React,{useEffect, useState,useRef} from 'react';
import { Col, Row ,List,Tabs, Tooltip,Typography} from 'antd';
import Buttons from '../../../../Common/button/Buttons';
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import AccessRow from './AccessRow';
import ActionPopUp from '../../../common/actionPopUp';
import { COPY, DELETEICON, ENTITLEMENTICON, PLUS,CLOSE,OUTBOUND, CONNECTIONS, DOMAIN_SMALL, USER, CHECK } from '../../../../Common/iconSource';
import { Literals } from '../../../common/literals';
import {
   InfoCircleFilled
  } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { RolePrivilegeModal, getDeleteMessage } from '../../../common/helperFunctions';
import { DeleteRoleContent, ErrorComponent } from '../../../common/helperFunctions';
import { useDispatch,useSelector } from 'react-redux';
import { setEditMode } from '../../../../../store/modules/entitlements/entitlementAction';
import { createEntitlement,getEntitlementList ,deleteEntitlement,updateEntitlement} from '../../../../../store/modules/entitlements/entitlementAction';
import { CreateRoleNoteContent, DeleteNoteContent, NoDataComponent, showSimpleLoading } from '../../../common/helperFunctions';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import DeleteModal from '../../../../Common/deleteModal/DeleteModal';
import CommonModal from '../../../../Common/CommonModal/CommonModal';
const { Title, Paragraph, Text, Link } = Typography;
const RolesComponent=({
    searchValue,setSearchValue
})=>{
    const dispatch=useDispatch();
    const entitlementEditMode=useSelector((state)=>
    state.Entitlement.isEdit
  )
    const [selectedRole,setSelectedRole]=useState({});
    const [permissionState,setPermissionState]=useState({});
    const saveClicked=useSelector((state)=>
    state.Entitlement.saveClicked
  )
    const [showLoadingMsg,setshowLoadingMsg]=useState(true);
    const [openModal,setOpenModal]=useState(false);
    const previousPermissionSate=useRef({});
    const [updatedName,setUpdatedName]=useState("");
    const [updatedDesc,setUpdatedDesc]=useState("")
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const [roles,setRoles]=useState([]);
    const [empty,setEmpty]=useState(false);
    const [errMsg,setErrMsg]=useState('');
    const newRoleData=useRef({name:"",description:""})
    const currentRoleRef = useRef({current: {}})
    const currentCopyItemRef = useRef({current: {}})


    const [additionalOptionModules,setAdditionalOptionModules]=useState({})

    useEffect(()=>{
        if(Object.keys(permissionState).length>0 && roles.length>0 && !selectedRole.id){
            let item=roles[0];
            let tempPermissionState={...permissionState};
            let activePermission=item.permission_detail.map(permission=>permission.id);

            Object.keys(tempPermissionState).forEach(permission=>{
                if(activePermission.includes(tempPermissionState[permission].id))
                    tempPermissionState[permission].checked=true;
                else
                    tempPermissionState[permission].checked=false;
            })

            clearAdditionalFieldData(tempPermissionState);


            activePermission.forEach(permissionId=>{
               Object.keys(additionalOptionModules).forEach(moduleName=>{
                if(additionalOptionModules[moduleName].includes(permissionId)){
                   if(tempPermissionState[`${moduleName}`]){
                    tempPermissionState[`${moduleName}`].push(permissionId);
                   }
                   else{
                    tempPermissionState[`${moduleName}`]=[permissionId];

                   }
                }
                })
            })

            setPermissionState(tempPermissionState);
            setUpdatedName(item.name);
            setUpdatedDesc(item.description)
            setSelectedRole(item)
        }
    },[permissionState,roles])


    const saveClickedFunc=()=>{
        let temp=Object.keys(permissionState).filter(item=>{
           return item!==undefined && permissionState[item]?.id && permissionState[item]?.checked && (item.includes("view") || item.includes("change") || item.includes("delete")||item.includes("add"));
        }).map(item=>{
            return {
                permission:permissionState[item].id,
                allow:true
            }
        })
        Object.keys(permissionState).filter(item=>{return item.includes("_options")}). forEach(item=>{
            permissionState[item].forEach(val=>{
                temp.push({
                    permission:val,
                    allow:true
                })
            })
        })
        let payload={
            "name": updatedName ?? selectedRole.name,
            "description":updatedDesc?? selectedRole.description,
            "entitlement_permission":temp
        }
        if(selectedRole.id)
            dispatch(updateEntitlement(selectedRole.id,payload)).then( async(res)=>{
        
             getEntitlementsListFunc();
            })
            .catch(() => {
                setPermissionState({...previousPermissionSate.current})
                })
    }

    useEffect(()=>{
        if(saveClicked)
        saveClickedFunc();
    },[saveClicked])

    useEffect(()=>{
        if(entitlementEditMode){
            previousPermissionSate.current={...permissionState};
        }
        else if(!entitlementEditMode && !saveClicked){
            setPermissionState({...previousPermissionSate.current})
        }
    },[entitlementEditMode,saveClicked])

    const createEntitlementFunction=(copyItem)=>{
    
        let tempPermissions=copyItem?copyItem.permission_detail.map(item=>{
            return {
                permission:item.id,
                allow:true
            }
        }):[]
        let payload={"name": newRoleData.current.name,
        "description": newRoleData.current.description,
        "entitlement_permission": tempPermissions};
        dispatch(createEntitlement(payload)).then(res=>{
            setOpenCreateModal(false)
            let item=res.data;

            if(!copyItem){
            let tempPermissionState={...permissionState};
            Object.keys(tempPermissionState).forEach(permission=>{
                    tempPermissionState[permission].checked=false;
            })
            setPermissionState(tempPermissionState);
            setUpdatedName(item.name);
            setUpdatedDesc(item.description)
            setSelectedRole(item)
            dispatch(setEditMode(true));
        }
        else{
            setSelectedRole(item);
        }
            getEntitlementsListFunc();
        })
        .catch(() => { 
            // emitToastNotification('error', 'Failed to create role') 
    })
    }
    const getEntitlementsListFunc=()=>{
  return   new Promise((resolve,reject)=>{
    setshowLoadingMsg(true);
        dispatch(getEntitlementList()).then(res=>{
            setshowLoadingMsg(false);
            setRoles(res.data);
            setEmpty(res?.data?.length === 0)
        })
        .catch((err) => { 
            // emitToastNotification('error', 'Failed to getch roles'); 
            setErrMsg(err.message)
        })
         
    })
}
    const deleteEntitlementFunc = (id) => {
        dispatch(deleteEntitlement(id)).then(res=>{
            if(res.status=="success"){
                getEntitlementsListFunc();
                closeDeletePopUp();
                emitToastNotification("success","Role Successfully Deleted")
            }
        })
        .catch(() => { 
           // emitToastNotification('error', 'Failed to delete role')
         })
    }
    useEffect(()=>{
        getEntitlementsListFunc();
    },[])

    const closeDeletePopUp = () => {
        setOpenDeletePopup(false);
    };

    const updateValue=(name,val)=>{
        newRoleData.current={...newRoleData.current,[name]:val}
    }
    const openDeleteContent = (id) => {
        currentRoleRef.current = id

        // setDeletePopUpContent({
        //     modalClass: "",
        //     heading: "Delete Role",
        //     message: DeleteRoleContent(
        //     ),
        //     button: [
        //       {
        //           name: "Cancel",
        //           action: () => {
        //               closeDeletePopUp();
        //           }, 
        //       },
        //       {
        //         name: "Delete",
        //         action: () => {
        //             deleteEntitlementFunc(id);
        //         },
        //       },
        //     ],
        //     elements: [],
        //     closeActionPopup: closeDeletePopUp,
        //   });
          setOpenDeletePopup(true);
      }

      const openCreateDialog = (copyItem) => {

        currentCopyItemRef.current = copyItem

        setOpenCreateModal(true)

        // setDeletePopUpContent({
        //   modalClass: "",
        //   heading: "Create new role",
        //   message: CreateRoleNoteContent(
        //     updateValue
        //   ),
        //   button: [
        //     {
        //         name: "Cancel",
        //         action: () => {
        //             closeDeletePopUp();
        //         }, 
        //     },
        //     {
        //       name: "Save",
        //       action: () => {
        //         createEntitlementFunction(copyItem);
        //       },
        //     },
        //   ],
        //   elements: [],
        //   closeActionPopup: closeDeletePopUp,
        // });
        // setOpenDeletePopup(true);
      };


      const clearAdditionalFieldData=(tempPermissionState)=>{
        Object.keys(tempPermissionState).filter(item=>item.includes("_options")).forEach(item=>{
            tempPermissionState[item]=[];
        })
        return tempPermissionState;
      }

    return (
        <div className='roles-component' >
        
            <div className="h-100">
        <div className="h-100 section_with_left_form">
            <div style={{width:"25%"}}  className='form_left'>
            <List  
                header={ <div style={{display:"flex",justifyContent:"space-between",padding:"2px 0px"}}>
                    <div style={{padding:"5px",paddingLeft:"10px", textAlign:"center",fontWeight:"bold" ,color:"#155e94"}}><span style={{display:"flex",alignItems:"center"}}>Roles 
              
                    </span></div>
                    </div>
                    }
            >
                {
                    errMsg.length>0?<ErrorComponent msg={errMsg}/>:
                    roles?.length === 0
                    ?
                        empty
                        ?
                        <NoDataComponent
                            logo={<ENTITLEMENTICON/>}
                            message={'Click On (+) To Create Roles'}
                        />
                        :
                        showSimpleLoading('Loading roles...')
                    :
                    roles.filter(item=>{
                    return    item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.description.toLowerCase().includes(searchValue.toLowerCase())
                    }).map(item=>{
                    const isSelected=selectedRole.name==item.name;
                    return(
                        <List.Item actions={[
                            <Buttons props={{
                                buttonText: '',
                                buttonClassName: `without-background custom-btn-outline custom-btn btn-with-icon`,
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    openCreateDialog(item);
                                },
                                tooltip: 'Copy',
                                ImgSrc: () => <COPY />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                            }} /> ,

                            <Buttons props={{
                                buttonText: '',
                                buttonClassName: `without-background custom-btn-outline custom-btn btn-with-icon error-red`,
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if(item.id)
                                        openDeleteContent(item.id);
                                    // deleteEntitlementFunc(item.id);
                                },
                                tooltip: 'Delete',
                                ImgSrc: () => <DELETEICON />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                            }} />

                        ]} 
                        
                        
                        onClick={()=>{
                            if(selectedRole.id !==item.id){
                            let tempPermissionState={...permissionState};
                            let activePermission=item.permission_detail.map(permission=>permission.id);
                            Object.keys(tempPermissionState).forEach(permission=>{
                                if(activePermission.includes(tempPermissionState[permission].id))
                                    tempPermissionState[permission].checked=true;
                                else
                                    tempPermissionState[permission].checked=false;
                            }) 
                            clearAdditionalFieldData(tempPermissionState);
                            activePermission.forEach(permissionId=>{
                               Object.keys(additionalOptionModules).forEach(moduleName=>{
                                if(additionalOptionModules[moduleName].includes(permissionId)){
                                   if(tempPermissionState[`${moduleName}`]){
                                    tempPermissionState[`${moduleName}`].push(permissionId);
                                   }
                                   else{
                                    tempPermissionState[`${moduleName}`]=[permissionId];

                                   }
                                }
                                })
                            })
                            setPermissionState(tempPermissionState);
                            setUpdatedName(item.name);
                            setUpdatedDesc(item.description)
                            setSelectedRole(item)
                        }
                        }
                        }  style={{borderBottom:"none !important",padding:"10px !important"}} 
                        className={`align-items-start ${selectedRole.name==item.name?"role-active":""}`}>
                         <List.Item.Meta
                                title={ 
                                    isSelected && entitlementEditMode
                                    ?   <input type={"text"} className="custom-input-field" style={{color:"black !important"}} value={updatedName}  onChange={(e)=>setUpdatedName(e.target.value)} />
                                    :   <Highlighter searchWords={[searchValue.toLowerCase()]} autoEscape={true} textToHighlight={item.name}> {item.name} </Highlighter>
                                }
                                description={ 
                                    isSelected && entitlementEditMode
                                    ?   <textarea 
                                            className='custom-input-field position-relative' 
                                            style={{color: 'black !important', width: 'calc(100% + 72px)'}} 
                                            value={updatedDesc} 
                                            onChange={(e)=>setUpdatedDesc(e.target.value)}
                                        />
                                    :   <Tooltip title={item.description}>
                                <div className='custom-ellipsis two-line-ellipsis' style={{position: 'relative', width: 'calc(100% + 72px)'}}>
                                    <Highlighter searchWords={[searchValue.toLowerCase()]} autoEscape={true} textToHighlight={item.description}>
                                    {item.description}
                                    </Highlighter>
                                    </div>
                                </Tooltip>}
                            />
                        </List.Item>
                    )
                })}
            </List>
            </div>
            {/* </Col>
            <Col  span={18}> */}
                <div className='custom-card p-0 w-75' style={{position:"relative"}}>
                {
                   
                <AccessRow showLoadingMsg={showLoadingMsg} roles={roles} setAdditionalOptionModules={setAdditionalOptionModules} searchValue={searchValue} empty={empty} selectedRole={selectedRole} permissionState={permissionState} setPermissionState={setPermissionState}/>}
                </div>
        </div>
        </div>

            
            <CommonModal
                open={openCreateModal}
                setOpen={setOpenCreateModal}
                header='Create New Role'
                body={CreateRoleNoteContent(updateValue)}
                footer={<>
                    <Buttons 
                        props={{ 
                            buttonText: 'Create', 
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text", 
                            buttonEvent: () => createEntitlementFunction(currentCopyItemRef?.current), 
                            ImgSrc: () => <CHECK />, 
                            isDisable: false, 
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT 
                        }} 
                    />
                </>}
                size='custom-sm'
            />


            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={getDeleteMessage({
                title: 'Role'
                })}
                onConfirm={() => deleteEntitlementFunc(currentRoleRef?.current)}
            />

            {/* <ActionPopUp
                openModal={openDeletePopup}
                actionData={deletePopUpContent}
            /> */}
            <Buttons
                props={{
                    buttonText: "",
                    buttonClassName: `custom-btn-primary custom-btn fixed-button addbutton_plusicon`,
                    buttonEvent: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openCreateDialog()
                    },
                    tooltip:  'Add Role',
                    ImgSrc: () => <PLUS />,
                    isDisable: false,
                    buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                }}
            />
            <RolePrivilegeModal openModal={openModal} setOpenModal={setOpenModal}/>
        </div>
    )
}
export default RolesComponent