import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Buttons from '../../../../Common/button/Buttons';
import { CLOSE, EDIT, LINEAGE_ICON } from '../../../../Common/iconSource';
import { Literals } from '../../../common/literals';
import { permissions } from '../../../common/helperFunctions';
import { useSelector } from 'react-redux';
const DataDomainHeader = ({ isUserGroup, details }) => {
    const history = useNavigate();
    
    const isSuperUser = useSelector(state => state?.UserReducer?.user?.userDetails?.is_superuser)

    const location = useLocation()
    const redirectBack = () => {

        if (location?.state && location?.state?.tabName) {
            return { pathname: '/userManagement', state: { tabName:location?.state?.tabName } }
        }
        else {
            return { pathname: '/userManagement' }
        }
    }

    const isEditor = !!details?.permission?.find(v => v === permissions.domain.editor)
    const isOwner = !!details?.permission?.find(v => v === permissions.domain.owner)
    const isLeader = !!details?.permission?.find(v => v === permissions.usergroup.leader)

    return (
        <div className="sub-header">
            <div className="custom-breadcrumb">
                <p>{isUserGroup ? 'User Groups' : 'Data Domains'}</p>
            </div>
            <div className="d-flex justify-content-end">

                {isUserGroup ? "" : <Buttons
                    props={{
                        tooltip: `Show Lineage`,
                        buttonText: '',
                        buttonClassName: "custom-btn-outline mr-2 custom-btn btn-with-icon",
                        buttonEvent: () => {


                            let path = `/viewLineage/${isUserGroup ? "group" : "domain"}/${isUserGroup ? details?.id : details.data_domain_id}`;

                            window.open(path)

                        },
                        ImgSrc: () => <LINEAGE_ICON />,

                        buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                    }}

                />}
                <Buttons props={{
                    tooltip: 'Edit',
                    buttonText: 'Edit', buttonClassName: "custom-btn-primary custom-btn btn-with-text mr-2", buttonEvent: () => {
                        isUserGroup ? history(Literals.links.USER_MANAGEMENT_EDIT_USER_GROUP + details?.id, { state: { tabName: Literals.USERGROUP } }) : history("/userManagement/editDataDomain/" + details?.data_domain_id, { state: { tabName: Literals.DATA_DOMAIN } })
                    }, ImgSrc: () => <EDIT />, 
                    isDisable: isUserGroup 
                                ? !(isSuperUser || isLeader)
                                : !(isSuperUser || isOwner), 
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }} />

                <Buttons props={{
                    tooltip: 'Close',
                    buttonText: '', buttonClassName: "custom-btn-outline  custom-btn btn-with-icon", buttonEvent: () => {
                        if(isUserGroup)
                        history(Literals?.links?.USER_MANAGEMENT + '#usergroup')
                        else
                        history(Literals?.links?.USER_MANAGEMENT + '#datadomain')
                        // history(redirectBack())



                    }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                }} />
            </div>

        </div>
    )
}

export default DataDomainHeader;