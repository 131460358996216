import { Tooltip } from 'antd';
import React from 'react';
import { Handle } from 'reactflow';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteTransformer } from '../../../../../store/modules/flows/flowsActions';
import { resetTargetRedux } from '../../../../../store/modules/flows/targetTransformer/targetTransformer';
import { ALERTTRANSFORMER, CHECKMARKCIRCLE, DELETETRANSFORMER, DISMISSCIRCLE, ERRORCIRCLE, LOADINGCIRCLE, LOADINGCIRCLEQUEUE, STOPOCTAGON } from '../../../../Common/iconSource';
import { getTransformerName, isTransformerNamePresent } from '../../transformers/FlowsHelperFunction';


const AlertTransformer = (props) => {
    const { flowId } = useParams();

    let { currentTransformer, executeEntireFlow, transformerProgress, id, flowsElements } = props
    const dispatch = useDispatch();

    // const getTransformerName = (valueFor = '') => {


    //     let transformerName = flowsElements.find((ele) => ele.id === id)?.transformerName;
    //     // if (transformerName && transformerName?.length > 10 && valueFor === '') {
    //     //     return `${transformerName?.slice(0, 6)}...`
    //     // } else {
    //     return transformerName ? transformerName : ''
    //     // }

    // }

    const getInitialStatus = () => {

        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        return currentStatus ? currentStatus?.toLowerCase() : 'initial'
    }
    const getStatus = () => {

        if (transformerProgress && transformerProgress.length) {
            let currentStatus = ''
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;

            if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'starting' || currentStatus?.toLowerCase() === 'running')) {
                return <span className='bx-spin d-flex'><LOADINGCIRCLE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'success' || currentStatus?.toLowerCase() === 'done' || currentStatus?.toLowerCase() === 'finished')) {
                return <CHECKMARKCIRCLE />
            }
            else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error')) {
                return <DISMISSCIRCLE />
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'queued')) {
                return <span className='bx-spin d-flex'><LOADINGCIRCLEQUEUE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'aborted')) {
                return <span className='d-flex'> <STOPOCTAGON /></span>
            }
            else {
                return <ERRORCIRCLE />
            }

        }
        else if (executeEntireFlow && !transformerProgress.length) {
            return <ERRORCIRCLE />

        } else if (!transformerProgress.length && !executeEntireFlow) {
            return <ERRORCIRCLE />
        }




    }
    // const getErrorMsg = () => {
    //     let error_msg = transformerProgress.find(element => {
    //         return element.crnt_transformer === id
    //     })?.error_msg;

    //     return error_msg
    // }
    const getErrorMsg = () => {
        let error_msg = transformerProgress.find(element => {
            return element.crnt_transformer === id
        })?.error_msg;
        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        return (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error' || currentStatus?.toLowerCase() === 'aborted') ? error_msg : null

    }
    return (
        <>
            <div className={`transformer-box merge-box ${currentTransformer.id === id ? `transformer-active` : ``}`}>
                <div>
                    <div className='transformer-icon'>

                        <ALERTTRANSFORMER color='#2C83C4' />
                    </div>
                    {!window.location.href.includes('viewFlows') && currentTransformer && currentTransformer.id === id ? <span className="transformer-bottom-img-delete cursor-pointer" onClick={(e) => { e.stopPropagation(); dispatch(resetTargetRedux()); dispatch(deleteTransformer(id, flowId)) }} >
                        <DELETETRANSFORMER />
                    </span> : null}
                    {getErrorMsg() ? <Tooltip title={getErrorMsg()}>  <div className="transformer-bottom-img" >
                        {getStatus()}
                    </div></Tooltip> : getInitialStatus() === 'initial' ? <Tooltip title={'Transformer not executed yet'}><div className="transformer-bottom-img" >
                        {getStatus()}
                    </div></Tooltip> : <div className="transformer-bottom-img" >
                        {getStatus()}
                    </div>}
                    <Tooltip title={dispatch(getTransformerName(id))}>
                    <div className={dispatch(isTransformerNamePresent(id)) ? "transformer-bottom-name" : "error-transformer-bottom-name"} >

                            {dispatch(getTransformerName(id))}

                        </div>
                    </Tooltip>
                </div>
                <Handle type="target" position="left" />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer,
        currentExecutingTransformerId: state.FlowsReducer.currentExecutingTransformerId,
        executeEntireFlow: state.FlowsReducer.executeEntireFlowStart,
        transformerProgress: state.FlowsReducer.transformerProgress,
        transformerLoading: state.FlowsReducer.transformerLoading
    };
};
export default connect(mapStateToProps)(AlertTransformer)