import "toastr/build/toastr.min.css";
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from '../../common/loadingActions';
import { OPEN_MODEL_CONNECTION, SET_CONNECTION_DATALIST, SET_CONNECTION_DEFAULT, SET_CONNECTION_DYNAMICFIELDS, SET_CONNECTION_OPTIONALFIELDS, SET_CONNECTION_PAGE_DEFAULT, SET_CONNECTION_SELECTEDCONN } from './connectionActionTypes';
import { saveAs } from 'file-saver';
const LANDING_PAGE_API_URL = window._env_.REACT_APP_API_BASE_URL;

export const setConnetionPageDefault = () => {
  return {
    type: SET_CONNECTION_PAGE_DEFAULT,
  };
};

export const setConnectionSelectedConn = (data) => {
  return {
    type: SET_CONNECTION_SELECTEDCONN,
    payload: data,
  };
}

export const openModelConnection = (data) => {
  return {
    type: OPEN_MODEL_CONNECTION,
    payload: data,
  };
};

export const setConnectionDataList = (data) => {
  return {
    type: SET_CONNECTION_DATALIST,
    payload: data,
  };
}

export const setConnectionDefault = (data) => {
  return {
    type: SET_CONNECTION_DEFAULT,
    payload: data,
  };
}

export const setConnectionDynamicFields = (data) => {
  return {
    type: SET_CONNECTION_DYNAMICFIELDS,
    payload: data,
  };
}

export const setConnectionOptionalDynamicFields = (data) => {
  return {
    type: SET_CONNECTION_OPTIONALFIELDS,
    payload: data,
  };
}

export const getConnectionProperties = (connectorName) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}connection/connector_property/?source_type=${connectorName}`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err)
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const getGlobalConnectionCache = (connectionName) => {
  return (dispatch) => {
    // dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}connection/get_cache_catalog/`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            // emitToastNotif ication('error', resp.message)
            localStorage.removeItem('GlobalConnectionCache')
          } else {
            if (resp.data !== '') {

              localStorage.setItem('GlobalConnectionCache', resp.data)
              resolve(resp);
            } else {
              localStorage.removeItem('GlobalConnectionCache')
            }
          }
        })
        .catch((err) => {
          localStorage.removeItem('GlobalConnectionCache')
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const getConnectionNameProperties = (connectionName, showLoader = true) => {
  return (dispatch) => {
    if(showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}connection/${connectionName}/`)
      )
        .then((resp) => {
          if(showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err)
          if(showLoader) {
            dispatch(setLoadingStatus(false));
          }
        });
    });
  };
};

export const configureConnection = (payload, isUpdate = false) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        isUpdate
          ? fetchData("POST", `${LANDING_PAGE_API_URL}connection/${payload?.connection_name}/configure/`, payload)
          : fetchData("POST", `${LANDING_PAGE_API_URL}connection/configure/`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message)
          dispatch(setLoadingStatus(false));
          reject(err)
        });
    });
  };
};
export const updateConnection = (payload, connectionName) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("PUT", `${LANDING_PAGE_API_URL}connection/${connectionName}/`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message ?? 'Network Error')
          dispatch(setLoadingStatus(false));
          reject(err)
        });
    });
  };
};


export const createConnection = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}connection/`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message)
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const deleteConnection = (connectionName) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("DELETE", `${LANDING_PAGE_API_URL}connection/${connectionName}/`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status !== 'success') {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message)
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const getOutboundConnections = () => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${LANDING_PAGE_API_URL}outbound_connection`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message)
          dispatch(setLoadingStatus(false));
          reject(err)
        });
    });
  };
};

export const createNewOutboundConnection = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}outbound_connection`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message);
         
          dispatch(setLoadingStatus(false));
          reject(err)
        });
    });
  };
};

export const deleteOutboundConnection = (id) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("DELETE", `${LANDING_PAGE_API_URL}outbound_connection/${id}/`)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message)
          dispatch(setLoadingStatus(false));
          reject(err)
        });
    });
  };
};

export const updateOutboundConnection = (payload, id) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("PUT", `${LANDING_PAGE_API_URL}outbound_connection/${id}/`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message)
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message)
          dispatch(setLoadingStatus(false));
          reject(err)
        });
    });
  };
};

export const DownloadGuideFileAPI = (fileName, endpoint) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true))

    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${LANDING_PAGE_API_URL}${endpoint}`, { download: true, endpoint: endpoint }))
        .then((resp) => {
          let content = resp;
          const file = new File([content], `${fileName}.zip`, { type: 'application/zip' })
          var FileSaver = require('file-saver');
          FileSaver.saveAs(file);
          dispatch(setLoadingStatus(false))
          resolve(resp);
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false))

          emitToastNotification('error', err.message);
          reject(err);
        });
    });
  };
};