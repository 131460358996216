import React, { useCallback, useState } from "react";
import { LIN_ALERT_ICON, LIN_ANALYTICS_ICON, LIN_DASHBOARD_ICON, LIN_DOMAIN_ICON, LIN_FLOWS_ICON, LIN_FOLDER_ICON, LIN_POD_ICON, LIN_SCHEDULER_ICON, LIN_USER_GROUP_ICON, LIN_USER_ICON, LIN_CHART_ICON, LIN_TABLE_ICON } from "../iconsUsedInLineage";
import { ICON_CATALOG, ICON_CATALOG_DOMAIN, ICON_CHART, ICON_DASHBOARD, ICON_USERS_GROUP } from "../../../Common/newIconSource";
import { ALERT } from "../../../Common/iconSource";

export const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: 19 });
    }
  }, []);
  return [translate, containerRef];
};

export const getNodeTypeIcon = (key) => {
  switch (key) {
    case 'pod':
    case 'business term':
      return <ICON_CATALOG />
    case 'data_domain':
    case 'domain':
    case 'data_domain':
      return <ICON_CATALOG_DOMAIN />
    case 'user':
      return <LIN_USER_ICON />
    case 'group':
    case 'usergroup':
      return <ICON_USERS_GROUP/>
    case 'flow':
      return <LIN_FLOWS_ICON />
    case 'analysis':
      return <LIN_ANALYTICS_ICON />
    case 'table':
      return <LIN_TABLE_ICON />
    case 'alert':
    case 'rule':
      return <ALERT />
    case 'dashboard':
      return <ICON_DASHBOARD />
    case 'scheduler':
      return <LIN_SCHEDULER_ICON />
    case 'chart':
      return <ICON_CHART />
    case 'folder':
      return <LIN_FOLDER_ICON />
    case 'source':
      return <ICON_CATALOG_DOMAIN />
    default:
      break;
  }
}