import React from 'react'
import { Literals } from "./literals";
import ResultNotFound from "../../../assets/images/icons/noResultsFound.svg";

export default function NoSearchResultFound({
    mainMsg = '',
    subMsg = '',
    fromRoute = '',
    width = "150",
    height = "150",
    showSubMsg = true,
    className = `no-result d-flex align-items-center justify-content-center`,
    style = {}
}) {
    return (
        <div className={`${className} ${fromRoute !== 'podselection' ? 'mt-5' : ''}`} style={style}>
            <img src={ResultNotFound} alt="NoRecordFound" width={width} height={height} />
            <h3>{mainMsg !== '' ? mainMsg : Literals.NO_RESULTS_FOUND}</h3>
            {showSubMsg ? <p>
                {subMsg !== '' ? subMsg : Literals.PLEASE_TRY_SEARCHING_WITH_ANOTHER_TERM}
            </p> : null}
        </div>
    )
}
