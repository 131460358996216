import { useDrag, useDrop } from "react-dnd";
import React from "react";
import { useCombinedRefs } from "../dataGridTable/hooks/useCombinedRefs";

export function DraggableHeaderRenderer({ onColumnsReorder, column}, ) {

  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN_DRAG",
    item: { key: column?.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "COLUMN_DRAG",
    drop({ key }) {
      onColumnsReorder(key, column?.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  
  return (
    <div
      ref={useCombinedRefs(drag, drop)}
      style={{
          fontWeight: 300,
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? "#ececec" : '#FFF',
        cursor: "move", margin: "0 -8px",
        padding: "0 8px"
      }}
    >
        {column?.name}
    </div>
  );
}
