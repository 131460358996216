import { Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap';
import Buttons from '../../../Common/button/Buttons';
import { DELETEICON, EDIT, EMAIL, PLUS, USER, USERSGROUP } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import SingleSelection from '../../../Common/dropDown/SingleSelection'
import MultiSelection from '../../../Common/dropDown/MultiSelection';
import { useSelector, useStore } from 'react-redux';
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions';
import { SetSchedulerPayload } from '../../../../store/modules/scheduler/action';
import _, { cloneDeep } from 'lodash';
import "../scheduler.scss"

export const Notification = ({
    isEditor = false
}) => {
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)


    const userToggleBtnOption = [
        { label: <><USERSGROUP /> <label className='mb-0 small'>Usergroup</label></>, value: "group" },
        { label: <><USER /> <label className='mb-0 small'>User</label></>, value: "user" },
    ];

    const userToggleBtnOptionFunc = (index) => {
        return [
            { label: <><USERSGROUP /> <label className='mb-0 small' onClick={() => setNotificationDetail('type', 'group', index)} >Usergroup</label></>, value: "group", disabled: window.location.href.includes('scheduler/view') },
            { label: <><USER /> <label className='mb-0 small' onClick={() => setNotificationDetail('type', 'user', index)}>User</label></>, value: "user", disabled: window.location.href.includes('scheduler/view') },
        ]
    }

    const triggerOptions = [
        { Name: 'SUCCESS', Value: "SUCCESS" },
        { Name: 'RUNNING', Value: "RUNNING" },
        { Name: 'FAILED', Value: "FAILED" }
    ];

    let notificationDefault = {
        uniqueID: '',
        type: 'group',
        name: [],
        ids: [],
        trigger: 'FAILED',
        message: ''
    }
    const assigneeDropdownChange = useRef(false)

    useEffect(() => {
        if (schedulerPayload?.cron_redux?.notificationList?.length) {
            let notification = schedulerPayload?.cron_redux?.notificationList;
            if (notification?.length) {
                assigneeDropdownChange.current = false;
                let tempList = []
                notification.forEach((element, index) => {
                    if (element?.type?.toLowerCase() === 'group' && !assignee[index]?.length) {

                        getUserGroupListData(index)
                    } else if (element?.type?.toLowerCase() === 'user' && !assignee[index]?.length) {


                        getUsersList(index)
                    }
                    //    if(tempList[index].uniqueID !== element.uniqueID){
                    //         temp.push(element)
                    //    }
                });

                if (!_.isEqual(notificationList, schedulerPayload?.cron_redux?.notificationList)) {

                    setNotificationList(schedulerPayload?.cron_redux?.notificationList)
                }



            } else {
                setNotificationList([notificationDefault])
            }
        }
        if (!schedulerPayload?.notify?.group?.length && !schedulerPayload?.notify?.user?.length && !assignee.length) {

            getUserGroupListData(0);
        }
    }, [schedulerPayload])

    const [notificationList, setNotificationList] = useState([notificationDefault])
    const [assignee, setassignee] = useState([])



    let currentAssigneeList = useRef([])
    const store = useStore();

    const handleAddNotification = () => {
        let temp = [...notificationList, notificationDefault]
        setNotificationList([...notificationList, notificationDefault])
        let payload = store.getState()?.Scheduler?.schedulerPayload
        payload['cron_redux']['notificationList'] = temp
        store.dispatch(SetSchedulerPayload(payload))
        // if (assignee[temp?.length]?.length <= 0) {

        getUserGroupListData(temp.length - 1);
        // }

    }


    const getUsersList = async (index) => {
        let response;
        try {
            response = await store.dispatch(
                getUserGroupList({ method: "GET", endPoint: "group_manager/user/", payload: {},showLoader: false })
            );
            if (response.status === "success") {
                let children = []
                let user = response.data;
                user.forEach((ele) => {

                    children.push({ key: ele.id, Name: ele.email, Type: "", value: ele.email })

                })
                let list = cloneDeep(currentAssigneeList.current)

                list[index] = [...children]
                currentAssigneeList.current = [...list]
                setassignee([...list]);
            }
        } catch (error) { }
    };

    const getUserGroupListData = async (index) => {
      

        let response;
        try {
            response = await store.dispatch(
                getUserGroupList({ method: "GET", endPoint: "group_manager/group/", payload: {}, showLoader: false })
            );
            if (response.status === "success") {
                let children = []
                let group = response.data;
                group.forEach((ele) => {

                    children.push({ key: ele.id, Name: ele.name, Type: "", value: ele.name })

                })
                let list = cloneDeep(currentAssigneeList.current)

                list[index] = [...children]
                currentAssigneeList.current = [...list]
            
                setassignee([...list]);
            }
        } catch (error) { }

    };
    const handleRemoveNotification = (index) => {
        let temp = [...notificationList]
        temp.splice(index, 1)
        setNotificationList([...temp]);
        let payload = store.getState()?.Scheduler?.schedulerPayload
        let notify

        if (payload.notify) {
            notify = payload?.notify

        } else {
            notify = {
                'group': [],
                'user': []
            }
        }

        payload['cron_redux']['notificationList'] = temp

        let tempArrayGroup = []
        let tempArrayUser = []

        if (temp.length) {
            temp.forEach((item) => {
                if (item.type === 'group') {
                    tempArrayGroup.push({ id: item.ids, status: item.trigger, uniqueID: item.uniqueID })
                    notify[item.type] = tempArrayGroup
                } else if (item.type === 'user') {
                    tempArrayUser.push({ id: item.ids, status: item.trigger, uniqueID: item.uniqueID })
                    notify[item.type] = tempArrayUser
                }

            })


        }
        payload['notify'] = notify
        store.dispatch(SetSchedulerPayload(payload))
    }

    const setNotificationDetail = (field, value, index) => {
        let temp = [...notificationList]

        temp[index][field] = value
        if (field === 'type') {
            temp[index]['name'] = []
        }
        temp[index]['uniqueID'] = `${temp[index].type}|${index}`
        setNotificationList([...temp])

        if (field === 'type' && value === 'user') {
            getUsersList(index)
        } else if (field === 'type' && value === 'group') {
            getUserGroupListData(index)
        }

        if (field === 'trigger') {
            let payload = store.getState()?.Scheduler?.schedulerPayload
            let notify

            if (payload.notify) {
                notify = payload?.notify

            } else {
                notify = {
                    'group': [],
                    'user': []
                }
            }





            payload['cron_redux']['notificationList'] = temp

            let tempArrayGroup = []
            let tempArrayUser = []

            if (temp.length) {
                temp.forEach((item) => {
                    if (item.type === 'group') {
                        tempArrayGroup.push({ id: item.ids, status: item.trigger, uniqueID: item.uniqueID })
                        notify[item.type] = tempArrayGroup
                    } else if (item.type === 'user') {
                        tempArrayUser.push({ id: item.ids, status: item.trigger, uniqueID: item.uniqueID })
                        notify[item.type] = tempArrayUser
                    }

                })


            }
            payload['notify'] = notify

            store.dispatch(SetSchedulerPayload(payload))
        }
    }


    const setSelectedAssigneeId = (value, index, keyName) => {

        assigneeDropdownChange.current = true

        let temp = [...notificationList]

        temp[index][keyName] = [...value]

        if (assignee.length) {


            let idList = assignee[index].filter((item) => {
                if (value.includes(item.value)) {
                    return item.key
                }
            }).map((ele) => ele.key)

            temp[index]['ids'] = [...idList]
            value.length ? temp[index]['name'] = [...value] : temp[index]['name'] = []
            temp[index]['uniqueID'] = `${temp[index]?.type}|${index}`
            setNotificationList([...temp])







            let payload = cloneDeep(store.getState()?.Scheduler?.schedulerPayload)
            let notify

            if (payload.notify) {
                notify = payload?.notify

            } else {
                notify = {
                    'group': [],
                    'user': []
                }
            }

            payload['cron_redux']['notificationList'] = temp

            let tempArrayGroup = []
            let tempArrayUser = []

            if (temp.length) {
                temp.forEach((item) => {
                    if (item.type === 'group') {
                        tempArrayGroup.push({ id: item.ids, status: item.trigger, uniqueID: item.uniqueID })
                        notify[item.type] = tempArrayGroup
                    } else if (item.type === 'user') {
                        tempArrayUser.push({ id: item.ids, status: item.trigger, uniqueID: item.uniqueID })
                        notify[item.type] = tempArrayUser
                    }

                })


            }
            payload['notify'] = notify
            store.dispatch(SetSchedulerPayload(payload))
        }
    }
    return <>
        <Card className='card custom-card p-0'>
            <CardHeader className='padding-2 bg-white'>
                <div className='d-flex align-items-center w-100 justify-content-between'>
                    <label className='mb-0 d-flex align-items-center font-weight-bold'>
                        <EMAIL />
                        <div className='p-1'></div>
                        Notifications
                    </label>
                    <div>
                        {window.location.href.includes('scheduler/view') ? "" : <Buttons
                            props={{
                                buttonText: 'Add Notification',
                                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                buttonEvent: () => handleAddNotification(),
                                ImgSrc: () => <PLUS />,
                                isDisable: !isEditor || window.location.href.includes('scheduler/view'),
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            }}
                        />}
                    </div>
                </div>
            </CardHeader>
            <CardBody className='padding-2'>
                <div className='row'>
                    <div className='col-3'>
                        <div>
                            <label className='mt-0 grey-color label'>Notify</label>
                        </div>
                    </div>
                    <div className='col-4 pl-0'>
                        <div>
                            <label className='mt-0 grey-color label'>Usergroup/User</label>
                        </div>
                    </div>
                    <div className='col-2 pl-0'>
                        <div>
                            <label className='mt-0 grey-color label'>Trigger</label>
                        </div>
                    </div>
                    <div className='col-2 pl-0'>
                        {/* <div>
                            <label className='mt-0 grey-color label invisible'>Message</label>
                        </div> */}
                    </div>
                </div>
                <div className=' notification-list-wrapper'>


                    {
                        notificationList.length === 0
                            ? <div className='p-3 text-center text-black-50'>No Notifications</div>
                            : notificationList.map((v, i) => {
                                return <div key={`noti-list-${i}`} className='row mb-2'>
                                    <div className='col-3'>
                                        <Buttons
                                            props={{
                                                buttonText: "",
                                                toggleBtnClassName: `full-width-toggle ${window.location.href.includes('scheduler/view') ? 'scheduler-toggle-disable' : ''}`,
                                                buttonEvent: (e) => { setNotificationDetail('type', e, i) },
                                                toggleBtnValue: v?.type,
                                                ImgSrc: () => <></>,
                                                isDisable: true,// window.location.href.includes('scheduler/view'),
                                                buttonType: Literals.BTN_TOGGLE,
                                                toggleBtnOption: userToggleBtnOptionFunc(i),
                                            }}
                                        />
                                    </div>
                                    <div className='col-4 pl-0'>
                                        <div>
                                            <div className="form-group">

                                                <MultiSelection props={{
                                                    label: "", placeholder: "Select assignee", className: "custom-select-dropdown", dropDownValue: v?.name, onClickEvent: (value) => {
                                                        setSelectedAssigneeId(value, i, 'name')
                                                    }, optionList: assignee[i], children: [], isDisable: window.location.href.includes('scheduler/view')
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 pl-0'>
                                        <div>
                                            <SingleSelection
                                                props={{
                                                    label: "",
                                                    placeholder: "Select trigger type",
                                                    dropDownValue: v?.trigger,
                                                    onClickEvent: (value) => setNotificationDetail('trigger', value, i),
                                                    optionList: triggerOptions,
                                                    isDisable: window.location.href.includes('scheduler/view'),
                                                    className: '',
                                                    optionClassName: 'timezone-select-option'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-1 pl-0'>
                                        {/* <div className='d-flex justify-content-between'> */}
                                        {/* <Tooltip placement={'top'} title={'Message'}>
                                            <button type='button' className={`custom-btn-outline custom-btn btn-with-text w-100`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }
                                                }
                                            >
                                                <EDIT /> <span className='ml-1'>Message</span>
                                            </button>
                                        </Tooltip> */}

                                        {/* <Tooltip placement={"bottom"} title="Delete"> */}
                                        {/* <button
                                                className={
                                                    "custom-btn-outline custom-btn btn-with-icon error-red red-border ml-2"
                                                }
                                                onClick={() => handleRemoveNotification(i)}
                                                disabled={window.location.href.includes('scheduler/view')}
                                            >
                                                <DELETEICON />
                                            </button> */}
                                        <Buttons
                                            props={{
                                                buttonText: "",
                                                tooltip: "Delete",
                                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red mr-2`,
                                                buttonEvent: () => {
                                                    handleRemoveNotification(i)
                                                },
                                                ImgSrc: () => <DELETEICON />,
                                                isDisable: !isEditor || window.location.href.includes('scheduler/view'),
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                            }}
                                        />
                                        {/* </Tooltip> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                            })
                    }  </div>
            </CardBody>
        </Card>
    </>
}
