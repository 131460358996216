import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
function UsersInitials({ initialsArray, isLeadersList, colors }) {
    const [initials] = useState(initialsArray)

    const initial = (name) => {
        return name.charAt(0).toUpperCase()
    }
    return (
        <div>
            <div className="avatar-group float-start">
                {initials.map((element, index) => <div key={`intials-${index}`}>
                    {!isLeadersList && index <= 2 ?
                        <div className="avatar-group-item">
                            <Link to="#" className="team-member d-inline-block">
                                <div className="avatar-xs" >
                                    <Tooltip placement="topLeft" title={element}>
                                        <span data-tip
                                            data-for={"queryTip" + index} className="avatar-title  border-white rounded-circle text-white" style={{ background: colors[index] }}>
                                            {initial(element)}
                                        </span>
                                    </Tooltip>
                                </div>
                            </Link> </div> : !isLeadersList && index === 3 ? <div className="avatar-group-item"> <Link to="#" className="team-member d-inline-block">
                                <div className="avatar-xs">
                                    {isLeadersList ? <i className='bx bxs-crown' style={{ color: '#ffbb01' }}  ></i> : null}
                                    <span className="avatar-title border-white rounded-circle bg-dark-primary text-white" style={{ background: '#5F5DE9' }}>
                                        {isLeadersList ? `+${initials.length - 1}` : `+${initials.length - 3}`}
                                    </span>
                                </div>
                            </Link></div> :

                            isLeadersList && index < 1 ?
                                <div className="avatar-group-item">
                                    <Link to="#" className="team-member d-inline-block">
                                        <div className="avatar-xs" >
                                            {isLeadersList ? <i className='bx bxs-crown' style={{ color: '#ffbb01' }}  ></i> : null}
                                            <Tooltip placement="topLeft" title={element}>
                                                <span data-tip
                                                    data-for={"queryTip" + index} className="avatar-title  border-white rounded-circle text-white" style={{ background: '#FAB43A' }}>
                                                    {initial(element)}
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </Link> </div> : index === 1 ? <div className="avatar-group-item"> <Link to="#" className="team-member d-inline-block">
                                        <div className="avatar-xs">
                                            {isLeadersList ? <i className='bx bxs-crown' style={{ color: '#ffbb01' }}  ></i> : null}
                                            <span className="avatar-title border-white rounded-circle bg-dark-primary text-white" style={{ background: '#5F5DE9' }}>
                                                {isLeadersList ? `+${initials.length - 1}` : `+${initials.length - 3}`}
                                            </span>
                                        </div>
                                    </Link></div> : null}
                </div>)}
            </div>

        </div>
    )
}

export default UsersInitials
