import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateDataDomainRight from './CreateDataDomainRight';
import LandingpageFirstHeader from '../../../common/LandingpageFirstHeader';
import CreateUserGroupMsg from '../../common/CreateUserGroupMsg'
import LoadingComponent from '../../../common/loadingComponent';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch, useStore } from 'react-redux';
import {
    setDataDomainId,
    setDataDomainDescription,
    setDataDomainName,
    fetchDataDomainDetails,
} from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions';
import { CLOSE, SAVE } from '../../../../Common/iconSource';
import { Literals } from '../../../common/literals';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Buttons from '../../../../Common/button/Buttons';
import './dataDomain.scss'
import { SET_ACTIVE_UM_TAB_NAME } from '../../../../../store/modules/UserManagement/userGroup/UMActionTypes';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { Tooltip } from 'antd';
import { podNameRegex } from '../../../common/helperFunctions';
const CreateDatadomain = () => {
    const loadingStatus = useSelector(state => state.LoadingReducer.loadingStatus);
    const dataDomainId = useSelector(state => state.UserManagement.DataDomain.dataDomainId);
    const dataDomainName = useSelector(state => state.UserManagement.DataDomain.dataDomainName)
    
    const dataDomainDescription = useSelector(state => state.UserManagement.DataDomain.dataDomainDescription);
    const { id } = useParams();
    const store = useStore();
    const dispatch = useDispatch();
    const history = useNavigate();
    var regexp = /^\S*$/;
    const [groupDetails, setGroupDetails] = useState([]);
    const [validateCheck, setValidateCheck] = useState("");
   

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_UM_TAB_NAME, payload: Literals.DATA_DOMAIN })

        if (id) {
            getDataDomainDetails();
        }
    }, []);

    useEffect(() => {
        if (dataDomainName && validateCheck === "dataDomainName") {
            setValidateCheck("");
        }
        if (dataDomainDescription && validateCheck === "dataDomainDescription") {
            setValidateCheck("");
        }
    }, [dataDomainName, dataDomainDescription])

    const createDataDomain = async () => {
        let response;
        let payload = {
            data_domain_name: dataDomainName,
            data_domain_description: dataDomainDescription,
        }
        if (dataDomainName === "") {
            setValidateCheck("dataDomainName");
        }
        else if (dataDomainDescription === "") {
            setValidateCheck("dataDomainDescription");
        }
        else {
            setValidateCheck("");
        }
        if (dataDomainName !== "" && dataDomainDescription !== "") {
            try {
                response = await store.dispatch(fetchDataDomainDetails(
                    { method: "POST", endPoint: "data_domain", payload }));
                if (response.status === "success") {
                   
                    dispatch(setDataDomainId(response?.data?.data_domain_id))
                    emitToastNotification('success', response?.message);
                    
                }
            } catch (error) {
               
            }
        }
    }

    const getDataDomainDetails = async () => {
        let response;
        try {
            response = await store.dispatch(fetchDataDomainDetails(
                { method: "GET", endPoint: `data_domain/${id}` }));
            if (response.status === "success") {
                dispatch(setDataDomainId(response?.data?.data_domain_id));
                dispatch(setDataDomainDescription(response?.data?.data_domain_description));
                dispatch(setDataDomainName(response?.data?.data_domain_name));
                setGroupDetails([...response?.data?.group?.group_detail])
            }
        } catch (error) { }
    }

    function renderEditDataDomainLeft() {
        return (
            <div>
                <p style={{borderBottom: 'solid 1px #eaeaea'}} className='mx-n3 px-3 mb-3 pb-2 fontSizeHeading'><strong>Data Domain Details</strong></p>
                <div className="form-group">
                    <label className='label'>
                        {Literals.DATA_DOMAIN_NAME}
                        <Tooltip placement={'right'} title={'Only alphabets, numbers and underscore are allowed. Domain names should only start with a letter'}><sup className="asterisk text-black-50" style={{top: 0}}><i className="bx bxs-info-circle ml-1"></i></sup></Tooltip>
                    </label>
                    <input className="form-control" value={dataDomainName} disabled={id}
                        onChange={(e) => {  if (podNameRegex.test(e.target.value) || e.target.value === "") { dispatch(setDataDomainName(e.target.value.toUpperCase())) } }}
                        // onChange={(e) => {  if (regexp.test(e.target.value)) { dispatch(setDataDomainName(e.target.value.toUpperCase())) } }}
                    />
                    <span className="warning">Data Domain name can't be edited</span>
                    {validateCheck === "dataDomainName" ? <p className="error">Data Domain Name required.</p> : null}
                </div>
                <div className="form-group">
                    <label className='label'>{Literals.DESCRIPTION}</label>
                    <textarea className="form-control" rows="4" value={dataDomainDescription}
                        onChange={(e) => {  dispatch(setDataDomainDescription(e.target.value)) }} />
                    {validateCheck === "dataDomainDescription" ? <p className="error">Description required.</p> : null}

                </div>
                <div className="d-flex justify-content-end">
                    <Buttons props={{
                        tooltip: 'Save',
                        buttonText: 'Save', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => {
                            createDataDomain()
                        }, ImgSrc: () => <SAVE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }} />
                </div>
            </div>
        )
    }

    function renderHeader() {
        return (
            <div className="sub-header">
                <div className="custom-breadcrumb">
                    <p><strong>Create Data Domain</strong></p>
                </div>
                <div className="d-flex justify-content-end">
                    <Buttons props={{
                        tooltip: 'Close',
                        buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {
                            history(Literals.links.USER_MANAGEMENT)
                        }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                    }} />

                </div>
            </div>
        )
    }

    return (
        <div className="responsive-scroll create-datadomain">
            <LandingpageFirstHeader title={'UserManagement'} flag={true} backRoute="/userManagement/?activeTab=dataDomain" />
            {renderHeader()}
            <div className="data-domain-edit">
                <div className="data-domain-edit-left-part">
                    {renderEditDataDomainLeft()}
                </div>
                <div className="data-domain-edit-right-part">
                    {dataDomainId ? <CreateDataDomainRight id={id} groupDetails={groupDetails} /> : <CreateUserGroupMsg forPage={"createDataDomain"} />}
                </div>
            </div>
            {loadingStatus ? <LoadingComponent /> : null}
        </div>
    )
}

export default CreateDatadomain;