
import { Button, Input, Tooltip,Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import Buttons from '../../../Common/button/Buttons'
import { CHECK, DELETEICON, DOCUMENT, DOWNLOAD, EDIT,CLOSE,SAVE } from '../../../Common/iconSource'
import { getRelativeTime} from '../../common/helperFunctions'
import { Literals } from '../../common/literals'

const FileExplorerTileView = ({
    info, 
    onDelete=()=>{}, 
    onDownload=()=>{},
    onEditButtonChange=()=>{},
    index
}) => {
    const [visible, setVisible] = useState(false);

    const [renameText, setRenameText] = useState("");

    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
      };

      useEffect(()=>{
        setRenameText(info.name?.split('.')[0]??"")
      },[info.name])


    return (
        <div className='border border-secondary d-inline-flex p-2 rounded flex-wrap' style={{width: '290px'}}>
                <div className='d-flex justify-content-between w-100'>
                    <div className='d-flex align-items-center'>
                        <div className=''>
                            <DOCUMENT width='30' height='30' />
                        </div>
                        {/* <div className='bx-border-circle p-2'>
                            <DOCUMENT/>
                        </div> */}
                        <div className='pl-2'>
                            <Tooltip title={info.name} placement={'topLeft'}>
                                {/* <h5 className='font-w-600 text-with-ellipsis' style={{maxWidth: '100px'}}>
                                    {info.name}
                                </h5> */}
                                <h5 className='font-w-600 text-with-ellipsis mb-0' style={{width: 'calc(100% - 6px)'}}>
                                    {/* <Input.Group compact>
                                        <Input
                                            style={{
                                                width: 'calc(100% - 30px)',
                                            }}
                                            defaultValue={info.name}
                                            className={'custom-input-field'}
                                            readOnly={!info.edit}
                                            onChange={(e)=>setNewName(e.target.value)}
                                        />
                                        <Tooltip title={info.edit?'Save':'Rename'}>
                                            <Button 
                                                style={{width: '28px'}} 
                                                className='custom-btn border-dark p-0' 
                                                icon={info.edit ? <CHECK /> : <EDIT/>}
                                                onClick={()=>onEditButtonChange(info, newName ,index)}
                                            />
                                        </Tooltip>
                                    </Input.Group> */}
                                    {info.name}
                                </h5>
                            </Tooltip>
                            {/* <div className='d-flex justify-content-between'>
                                <label className='mb-0 text-black-50 small text-uppercase'>
                                    {
                                        typeof info.size==='string'
                                        ?
                                        info.size
                                        :
                                        (info.size / 1024).toFixed(2) + ' kb'
                                    }
                                </label>
                                <label className='mb-0 text-black-50 small text-uppercase mr-2'>
                                    {
                                        getRelativeTime(info.file_upload_time)
                                    }
                                </label>
                            </div> */}
                        </div>
                    </div>
                    <div className='d-flex'>
                    <Popover
              content={<div style={{ display: "flex", padding: "10px", alignItems: "center" }}>

                <Input value={renameText} onChange={(e) => setRenameText(e.target.value)} />
                <Buttons
                  props={{
                    buttonText: "",
                    buttonClassName: "custom-btn-primary custom-btn btn-with-icon ml-1",
                    buttonEvent: (e) => {
                      onEditButtonChange({...info,edit:true},`${renameText}.${info.name?.split(".")[1]}`,index)
                      setVisible(false);

                    }
                    ,
                    ImgSrc: () => <SAVE />,
                    isDisable: false,
                    buttonType: Literals.BTN_SECONDARY,
                  }}
                />
              </div>}


              title={<div style={{ display: "flex", padding: "10px", justifyContent: "space-between" }}><span>Rename</span> <Buttons
                props={{
                  buttonText: "",
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                  buttonEvent: () => {
                    setVisible(false);
                  },
                  ImgSrc: () => <CLOSE />,
                  isDisable: false,
                  buttonType: Literals.BTN_TERTIARY,
                }}
              /></div>}
              onOpenChange={handleVisibleChange}
              trigger="click"
              open={visible}
            >
              <Buttons
                props={{
                  buttonText: "",
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon border-0",
                  buttonEvent: (e) => {
                    setVisible(true);
                  },
                  ImgSrc: () => <EDIT />,
                  isDisable: false,
                  buttonType: Literals.BTN_SECONDARY,
                }}
              />
            </Popover>
                        <Buttons
                            props={{
                                buttonText: "",
                                tooltip: "Download",
                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon border-0`,
                                buttonEvent: () => {
                                    onDownload(info.id)
                                },
                                ImgSrc: () => <DOWNLOAD />,
                                isDisable: false,
                                buttonType: Literals.BTN_SECONDARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                tooltip: "Delete",
                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red red-border border-0`,
                                buttonEvent: () => {
                                    onDelete(info.id)
                                },
                                ImgSrc: () => <DELETEICON />,
                                isDisable: false,
                                buttonType: Literals.BTN_SECONDARY,
                            }}
                        />
                    </div>
                </div>
                <div className='d-flex justify-content-between px-1 mt-2' style={{flex:'0 0 100%'}}>
                    <label className='font-w-600 mb-0 small text-black-50 text-uppercase'>
                        {
                            typeof info.size==='string'
                            ?
                            info.size
                            :
                            (info.size / 1024).toFixed(2) + ' kb'
                        }
                    </label>
                    <label className='font-w-600 mb-0 small text-black-50 text-uppercase'>
                        {
                            getRelativeTime(info.file_upload_time)
                        }
                    </label>
                </div>
        </div>
  )
}

export default FileExplorerTileView