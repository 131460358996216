import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GetSchedulerDetails, SetSchedulerPayload, setSelectedScheduler } from '../../../../store/modules/scheduler/action';
import CreateSchedulerIndex from './CreateSchedulerIndex';
import SchedulerDetailsNewPage from './SchedulerDetailsNewPage';
import { SET_FLOWS_ALERT_TRANSFORMER_LIST } from '../../../../store/modules/alert/createAlert/createAlertActiontype';
import { SET_SELECTED_DASHBOARD } from '../../../../store/modules/scheduler/SchedulerActionType';
import { generateHtmlTitle } from '../../common/helperFunctions';
import LoadingComponent from '../../common/loadingComponent';


const EditSchedulerIndex = () => {
    const { schedulerId } = useParams();
    const store = useStore()
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)
    const history = useNavigate();
    const [permission, setpermission] = useState([])
    const [showLoader, setshowLoader] = useState(false)

    useEffect(() => {
        if (schedulerId) {
            setshowLoader(true)
            store.dispatch(GetSchedulerDetails(schedulerId)).then((resp) => {
                setshowLoader(false)

                if (resp?.status?.toLowerCase() === 'success') {
                    let data = resp.data
                    setpermission(data?.permission);
                    const newPayload = cloneDeep(schedulerPayload)

                    newPayload['cron_redux'] = data.cron_redux
                    newPayload['old_flow_id'] = data?.flow;

                    store.dispatch(SetSchedulerPayload({ ...data, old_flow_id: data?.flow }))
                    let obj = {
                        selectedFlowDetails: data['flow_detail']

                    }
                    let dashboardobj = {
                        selectedDashboardDetails: data['dashboard_detail']

                    }

                    if (newPayload['cron_redux']['selectedScheduler']) {

                        store.dispatch(setSelectedScheduler(newPayload['cron_redux']['selectedScheduler']))
                    }
                    if (data['flow_detail']) {

                        store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: obj })
                    }
                    if (data['dashboard_detail']) {

                        store.dispatch({ type: SET_SELECTED_DASHBOARD, payload: dashboardobj })
                    }

                    let title = generateHtmlTitle(data?.name?.toUpperCase() + ' | Scheduler', 'Lab')
                    document.title = title
                }
            })
                .catch(() => {
                    setshowLoader(false)

                    let title = generateHtmlTitle('Scheduler', 'Lab')
                    document.title = title
                })
        }
    }, [schedulerId])

    return <>
        {/* <CreateSchedulerIndex pageType={'edit'} /> */}
        {showLoader ? <LoadingComponent /> : null}
        <SchedulerDetailsNewPage isExecutePermission={permission?.includes('editor_datadomain')} showLoader={showLoader} setshowLoader={setshowLoader} />
    </>
}
export default EditSchedulerIndex;