import {
    SET_USERGROUPS_LEADER_LIST,
    SET_USERGROUPS_REMOVE_LEADER,
    SET_USERGROUPS_USER_LIST,
    SET_USERGROUP_DESCRIPTION,
    SET_USERGROUP_NAME,
    SET_USER_GROUP_ID,
    SET_USERS_EXISTED_IN_GROUP,
    SET_USER_GROUP_PAGE_DEFAULT,
    SET_ACTIVE_UM_TAB_NAME
}
    from "./UMActionTypes";


const initialState = {
    userGroupLeaders: [],
    removedLeader: {},
    userGroupUserList: [],
    userGroupName: '',
    userGroupDescription: '',
    userGroupId: "",
    activeTabName:'',
    usersExistedInGroup: [],
}

const UserGroupReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case SET_USERGROUPS_LEADER_LIST:
            return {
                ...state,
                userGroupLeaders: payload
            }
            case SET_ACTIVE_UM_TAB_NAME:
                return {
                    ...state,
                    activeTabName: payload
                }
            
        case SET_USER_GROUP_PAGE_DEFAULT:
            return  initialState ;

        case SET_USERS_EXISTED_IN_GROUP:
            return {
                ...state,
                usersExistedInGroup: payload
            }
            
        case SET_USERGROUPS_REMOVE_LEADER:
            return {
                ...state,
                removedLeader: payload
            }
            
        case SET_USERGROUPS_USER_LIST:
            return {
                ...state,
                userGroupUserList: payload
            }
            
        case SET_USERGROUP_NAME:
            return {
                ...state,
                userGroupName: payload
            }
        case SET_USERGROUP_DESCRIPTION:
            return {
                ...state,
                userGroupDescription: payload
            }
        case SET_USER_GROUP_ID:
            return {
                ...state,
                userGroupId: payload
            }
        default:
            return state;
    }
}

export default UserGroupReducer;