export const SET_SELECTED_ALERT_PODLIST="SET_SELECTED_ALERT_PODLIST"
export const SET_ALERT_SQL_EDITOR_QUERY="SET_ALERT_SQL_EDITOR_QUERY"
export const SET_ALERT_RESPONSE_SQL_QUERY="SET_ALERT_RESPONSE_SQL_QUERY"
export const RESET_CREATE_ALERT_REDUX="RESET_CREATE_ALERT_REDUX"
export const SET_ALERT_PREVIEW_TABLES="SET_ALERT_PREVIEW_TABLES"
export const SET_ALERT_PREVIEW_TABLE_RESULT="SET_ALERT_PREVIEW_TABLE_RESULT"
export const SET_ALERT_QUERY_TABLES_RESULT="SET_ALERT_QUERY_TABLES_RESULT"
export const SET_ALERT_PREVIEW_TABLES_RESULT="SET_ALERT_PREVIEW_TABLES_RESULT"
export const SET_ALERT_APPLIED_FILTERS="SET_ALERT_APPLIED_FILTERS"
export const SET_FLOWS_ALERT_TRANSFORMER_LIST="SET_FLOWS_ALERT_TRANSFORMER_LIST"
export const SET_SELECTED_ALERT_TRANSFORMER="SET_SELECTED_ALERT_TRANSFORMER"
export const SET_SELECTED_ALERT_DETAILS="SET_SELECTED_ALERT_DETAILS"
