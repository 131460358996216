export const SET_MULTIPLE_TABLE_DATA="SET_MULTIPLE_TABLE_DATA"
export const SET_SORTED_COLUMN_LIST="SET_SORTED_COLUMN_LIST"
export const SET_SEARCH_KEYWORD="SET_SEARCH_KEYWORD"
export const SET_APPLIED_FLITERS_LIST="SET_APPLIED_FLITERS_LIST"
export const SET_EDITOR_QUERY="SET_EDITOR_QUERY"
export const SET_PAGE_COUNT="SET_PAGE_COUNT"
export const SET_SHOW_RESET_FILTER="SET_SHOW_RESET_FILTER"
export const RESET_VIRTUAL_TABLE_ACTIONS="RESET_VIRTUAL_TABLE_ACTIONS"
export const RESET_COMMONVIRTUALTABLE="RESET_COMMONVIRTUALTABLE"

export const DOWNLOAD_CLICK="DOWNLOAD_CLICK"
export const SET_CURRENT_TABLE_RESPONSE="SET_CURRENT_TABLE_RESPONSE"
export const SET_ACTION_TYPE="SET_ACTION_TYPE"
export const RESET_VIRTUAL_TABLE_REDUX="RESET_VIRTUAL_TABLE_REDUX"
export const SET_ROWS_COUNT="SET_ROWS_COUNT"
export const SET_ACTIVE_POD_DETAIL="SET_ACTIVE_POD_DETAIL"