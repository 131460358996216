import React, { Component } from "react";

import store from "../../store/index"



import { connect } from 'react-redux';
import {  setCreatePodPageDefault } from '../../store/modules/dataCatalogue/createPOD/createPODActions';
import { setDictionaryDefault } from '../../store/modules/dataCatalogue/dictionary/dictionaryAction';
import { setLandingPageDefault } from '../../store/modules/dataCatalogue/landingPage/landingPageActions';
import { setPreviewPageDefault } from '../../store/modules/dataCatalogue/preview/previewActions';
import { setSummaryPageDefault } from '../../store/modules/dataCatalogue/summary/summaryActions';
import { setCreateRulePageDefault } from '../../store/modules/dataRules/CreateRule/rulesAction';
import { setAccessManagementPageDefault } from '../../store/modules/UserManagement/accessManagement/accessManagementActions';
import { setConnetionPageDefault } from '../../store/modules/UserManagement/connection/connectionActions';
import { setDataDomainPageDefault } from '../../store/modules/UserManagement/dataDomains/dataDomainActions';
import { setUserGroupPageDefault } from '../../store/modules/UserManagement/userGroup/UMActions';
import { setUsersPageDefault } from '../../store/modules/UserManagement/users/usersActions';



import { setConnectionDefault } from '../../store/modules/UserManagement/connection/connectionActions'


export const setReduxDefault = () => {
    store.dispatch(setCreatePodPageDefault(""));
    store.dispatch(setDictionaryDefault(""));
    store.dispatch(setLandingPageDefault(""));
    store.dispatch(setPreviewPageDefault(""));
    store.dispatch(setSummaryPageDefault(""));
    store.dispatch(setCreateRulePageDefault(""));
    store.dispatch(setAccessManagementPageDefault(""));
    store.dispatch(setConnetionPageDefault(""));
    store.dispatch(setDataDomainPageDefault(""));
    store.dispatch(setUserGroupPageDefault(""));
    store.dispatch(setUsersPageDefault(""));
    store.dispatch(setConnectionDefault(""));


}

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            isOpenMenu: false,
            user: JSON.parse(localStorage.getItem('user'))
        };
        this.funnelIconStyle = {
            fontSize: "23px",
            textAlign: "left",
            marginRight: "1px",
            padding: "0px 3px 8px 3px",
        }
    }


    

    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });

    upadateProfile = () => {

        
        // this.handleShow()
    }


    componentDidMount() {
        // this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            // this.initMenu();
        }
    }

    // initMenu() {
    //     new MetisMenu("#side-menu");

    //     var matchingMenuItem = null;
    //     var ul = document.getElementById("side-menu");
    //     var items = ul.getElementsByTagName("a");
    //     for (var i = 0; i < items.length; ++i) {
    //         let splitPath = items[i].pathname.split('/');

    //         if (this.props.location.pathname.split('/')[1] === splitPath[1]) {
    //             matchingMenuItem = items[i];
    //             break;
    //         }
    //     }
    //     if (matchingMenuItem) {
    //         this.activateParentDropdown(matchingMenuItem);
    //     }
    // }

    // toggle = () => {
    //     this.setState({ isOpenMenu: !this.state.isOpenMenu });
    //     store.dispatch(changeSidebarType("condensed", this.state.isOpenMenu));

    // }
    // activateParentDropdown = item => {
    //     item.classList.add("mm-active");
    //     const parent = item.parentElement;
        
    //     if (parent) {
    //         parent.classList.add("mm-active");

            
    //         return false;
    //     }
    //     return false;
    // };

    render() {



        // const customSection = (
        //     <>
        //         <UpdateProfile show={this.state.show} handleClose={this.handleClose} />

                

        //         <li className="text-center">
        //             <img src={ClientIcon} className="client-icon" alt="client icon" style={{marginTop:'5px'}} />
        //         </li>

        //         <li>
        //             <Link to="/analytics"
        //                 onClick={() => {
        //                     store.dispatch(setConnectionDefault(''))
        //                 }}>
        //                 <div className="sidebar-icon">
        //                     <i className='bx bxs-bar-chart-square'></i>
        //                 </div>
        //                 <span>{this.props.t('Analytics')}</span>
        //             </Link>
        //         </li>

        //         <li>
        //             <Link to="/dataCatalogue"
        //                 onClick={() => {
        //                     store.dispatch(setConnectionName(''))
        //                     store.dispatch(setCreatePODProceeds(''))
        //                     store.dispatch(setCreatePODCustomTabs([]))
        //                     store.dispatch(setCreatePodSourcetype(''))
        //                     store.dispatch(setConnectionDefault(''))

        //                 }}>
        //                 <div className="sidebar-icon">
        //                     <i className='bx bxs-book-content'></i>
        //                 </div>
        //                 <span>{this.props.t('Data Catalog')}</span>
        //             </Link>
        //         </li>

        //         <li>

        //             <Link to="/flows" 
        //             >
        //                 <div className="sidebar-icon">
        //                     <i className='bx bx-filter'></i>
        //                 </div>
        //                 <span>{this.props.t('Flows')}</span>
        //             </Link>
        //         </li>

        //         <li>
        //             <Link to="/dataRules"
        //                 onClick={() => {
        //                     store.dispatch(setConnectionDefault(''))
        //                 }}
        //             >
        //                 <div className="sidebar-icon">
        //                     <i className='bx bx-list-check'></i>
        //                 </div>
        //                 <span>{this.props.t('Data Rules')}</span>
        //             </Link>
        //         </li>

        //         <li>
        //             <Link to="/" onClick={(event) => event.preventDefault()}  ///dataCluster
        //             // onClick={ () => {
        //             //     store.dispatch(setConnectionDefault(''))
        //             // }}
        //             >
        //                 <div className="sidebar-icon">
        //                     <i className='bx bx-server' ></i>
        //                 </div>
        //                 <span>{this.props.t('Data Cluster')}</span>
        //             </Link>
        //         </li>
                

        //         <li>
        //             <Link to="/#">
        //                 <div className="sidebar-icon">
        //                     <i className="bx bxs-lock-alt"></i>
        //                 </div>
        //                 <span>{this.props.t('Admin')}</span>
        //             </Link>
        //             <ul className="sub-menu" aria-expanded="true">
        //                 <li><Link  onClick={() => {
        //                     store.dispatch(setConnectionDefault(''))
        //                 }} to="/accessManagement">{this.props.t('Access Management')}</Link></li>
        //                 <li><Link  onClick={() => {
        //                     store.dispatch(setConnectionDefault(''))
        //                 }} to="/userManagement">{this.props.t('User Management')}</Link></li>
        //             </ul>
        //         </li>

        //         <li>
        //             <Link to="/connections"
        //                 onClick={() => {
        //                     store.dispatch(setConnectionDefault(''))
        //                 }}>
        //                 <div className="sidebar-icon">
        //                     <i className='bx bx-plug' ></i>
        //                 </div>
        //                 <span>{this.props.t('Connections')}</span>
        //             </Link>
        //         </li>

                
        //     </>
        // )
        return (
            <React.Fragment>
                <div id="vertical-side-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        

                        {/* {customSection} */}

                        
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        firstName: state.UserReducer?.user?.userDetails?.first_name,
        lastName: state.UserReducer?.user?.userDetails?.last_name,
        email: state.UserReducer?.user?.userDetails?.email,
        isSuperUser: state.UserReducer?.user?.userDetails?.is_superuser
    }
}
export default connect(mapStateToProps, null)(SidebarContent);
