import { Checkbox, Upload } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { deleteImageLogo, getImageLogo, uploadImageLogo } from '../../../../store/modules/common/GenAi/GenAiReducer'
import { ICON_CHECK, ICON_CONFIRM, ICON_DELETE, ICON_EDITBOX, ICON_EYEOPEN, ICON_LOADING_CIRCLE } from '../../newIconSource'
import './pulseCompanyLogo.scss'
import Buttons from '../../button/Buttons'
import { Literals } from '../../../modules/common/literals'
import { CustomSkeleton } from '../../skeleton/CustomSkeleton'
import CommonModal from '../../CommonModal/CommonModal'
import DeleteModal from '../../deleteModal/DeleteModal'
import clarista_logo from '../../../../assets/images/clarista_logo.png'

const PulseCompanyLogo = ({
    activeDomain = ''
}) => {

    const dispatch = useDispatch()

    const [fileList, setFilterList] = React.useState([])
    const [uploading, setUploading] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [imageSrc, setImageSrc] = React.useState(clarista_logo)
    const [imageData, setImageData] = React.useState({name: 'Clarista'})
    const [openViewModal, setOpenViewModal] = React.useState(false)
    const [openConfirmModal, setOpenConfirmModal] = React.useState(false)
    const [selectedFile, setSelectedFile] = React.useState(undefined)
    const [showGlobally, setShowGlobally] = React.useState(true)
    const [showDelete, setShowDelete] = React.useState(false)
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [deleting, setDeleting] = React.useState(false)

    const uploadRef = React.useRef({})

    const userDetails = useSelector((state) => state.UserReducer?.user?.userDetails)
    const isSuperUser = userDetails?.is_superuser

    React.useEffect(() => {
        fetchLogo()
    }, [activeDomain])

    const fetchLogo = () => {
        if (!activeDomain || activeDomain === undefined || activeDomain === '') {
            setImageSrc(clarista_logo)
            setImageData({name: 'Clarista'})
            return
        }

        setLoading(true)
        dispatch(getImageLogo(activeDomain, false))
            .then(res => {
                const data = res?.data
                if (data) {
                    setImageSrc(createImageSrc(data))
                    setImageData(data)
                    if (isSuperUser) {
                        setShowGlobally(data?.data_domain === null)
                    }
                    setShowDelete(!(data?.data_domain === null && !isSuperUser))
                }
                else {
                    setImageSrc(clarista_logo)
                    setImageData({name: 'Clarista'})
                    // setImageSrc(undefined)
                    // setImageData(undefined)
                    setShowGlobally(true)
                    setShowDelete(false)
                }
                setLoading(false)
            })
            .catch(err => {
                console.error({ err })
                setImageSrc(clarista_logo)
                setImageData({name: 'Clarista'})
                // setImageSrc(undefined)
                // setImageData(undefined)
                setLoading(false)
            })
    }

    const removeLogo = () => {
        if (!activeDomain || activeDomain === undefined || activeDomain === '') {
            setImageSrc(clarista_logo)
            setImageData({name: 'Clarista'})
            return
        }

        setDeleting(true)
        dispatch(deleteImageLogo(activeDomain, true))
            .then(() => {
                setImageSrc(clarista_logo)
                setImageData({name: 'Clarista'})
                // setImageSrc(undefined)
                // setImageData(undefined)
                setDeleting(false)
                setDeleteModal(false)
            })
            .catch(err => {
                console.error({ err })
                setDeleting(false)
            })
    }

    const handleUpload = (file) => {
        if (!activeDomain || activeDomain === undefined || activeDomain === '') {
            emitToastNotification('info', "Please Select Domain To Proceed")
            return
        }
        if (isSuperUser) {
            setSelectedFile(file)
            setOpenConfirmModal(true)
        }
        else {
            upload(file)
        }
    }

    const upload = (f) => {
        const file = f ?? selectedFile

        let formData = new FormData()
        formData.append('file', file)
        formData.append('name', file?.name)

        if (isSuperUser) {
            if (!showGlobally)
                formData.append('data_domain', showGlobally ? null : activeDomain)
        }
        else {
            formData.append('data_domain', activeDomain)
        }


        setUploading(true)
        dispatch(uploadImageLogo(formData))
            .then((res) => {
                const data = res?.data
                setUploading(false)
                setImageSrc(createImageSrc(data))
                setImageData(data)
                setOpenConfirmModal(false)
                setShowGlobally(true)
            })
            .catch((err) => {
                console.error({ err })
                if (err?.status === 403) {
                    emitToastNotification('error', err?.data?.message)
                }
                setUploading(false)
            })
    }

    const createImageSrc = (data) => {
        return `data:image/${data?.type};base64,${data?.logo}`
    }

    return (
        <div>
            {
                loading || uploading ? <CustomSkeleton width='270px' height='128px' />
                    :

                    imageSrc && imageSrc !== ''
                        ?
                        <div className={`pulse-logimg-wrap ${uploading ? '' : 'en'}`}>
                            <img className='' alt={imageData?.name} src={imageSrc} />
                            <div className='pulse-logimg-overlay'>

                                <div className='d-flex align-items-center justify-content-center' style={{ gap: 5 }}>
                                    <span
                                        className='img-act-btn'
                                        title='Preview Image'
                                        onClick={() => {
                                            setOpenViewModal(true)
                                        }}
                                    >
                                        <ICON_EYEOPEN color='currentColor' />
                                    </span>
                                    {
                                        isSuperUser
                                            ?
                                            <span
                                                className='img-act-btn'
                                                title='Change Image'
                                                onClick={uploadRef?.current?.upload?.uploader?.onClick}
                                            >
                                                <ICON_EDITBOX color='currentColor' />
                                            </span>
                                            : ''
                                    }
                                    {
                                        isSuperUser
                                            // showDelete
                                            ?
                                            <span
                                                className='img-act-btn'
                                                title='Remove Image'
                                                onClick={() => setDeleteModal(true)}
                                            >
                                                <ICON_DELETE color='currentColor' />
                                            </span>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                        : ''
            }
            {
                isSuperUser
                    ?
                    <Upload
                        action={handleUpload}
                        listType="picture-card"
                        fileList={fileList}
                        accept='image/png, image/jpeg'
                        disabled={uploading}
                        ref={uploadRef}
                        className={imageSrc || loading || uploading ? 'd-none' : ''}
                    >
                        {'+ Logo'}
                    </Upload>
                    : ''
            }
            {
                uploading ? <div className='small fontInterSemiBold mt-1'>
                    <ICON_LOADING_CIRCLE height='14' width='14' color='currentColor' />
                    <span className='p-1'></span>
                    uploading...
                </div>
                    : ''
            }

            {/* {CONFIRM MODAL} */}
            {
                <CommonModal
                    size='md'
                    open={openConfirmModal}
                    className="confirm-popup"
                    setOpen={setOpenConfirmModal}
                    header={<>
                        <span className="mr-2">
                            <ICON_CONFIRM color="#2c83c4" height="20" width="20" />
                        </span>
                        <span>{'Confirm'}</span>
                    </>}
                    body={
                        <>
                            <div className='d-flex align-items-start'>
                                <Checkbox
                                    checked={!showGlobally}
                                    onChange={(e) => {
                                        setShowGlobally(!e?.target?.checked)
                                    }}
                                />
                                <label className='ml-2 mb-0 fontSizeHeading fontInterSemiBold'>
                                    Select the checkbox to set the logo for selected Domain. If unchecked, the logo will be set globally for all domains
                                    {/* Upload and display the logo for selected Domain only, or by default it will be displayed globally  */}
                                    {/* Tick the checkbox option to display the logo exclusively for the selected domain; otherwise, it will be shown globally throughout the entire application.    */}
                                </label>
                            </div>
                        </>
                    }
                    footer={
                        <>
                            <Buttons
                                props={{
                                    tooltip: "",
                                    tooltipPlacement: "left",
                                    buttonText: "Done",
                                    buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                    buttonEvent: () => {
                                        upload()
                                    },
                                    ImgSrc: () => <></>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                        </>
                    }
                />
            }


            {/* {PREVIEW MODAL} */}
            <CommonModal
                size='md'
                open={openViewModal}
                setOpen={setOpenViewModal}
                header={imageData?.name}
                body={
                    <>
                        <div className='d-flex align-items-center justify-content-center w-100' style={{ height: 400 }}>
                            <img
                                alt={imageData?.name}
                                src={imageSrc}
                                style={{ objectFit: 'contain' }}
                                className='w-100 h-100'
                            />
                        </div>
                    </>
                }
                footer=''
            />

            <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                onConfirm={() => removeLogo()}
                loading={deleting}
                heading='Remove Logo'
            />

        </div>
    )
}

export default PulseCompanyLogo