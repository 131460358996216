import React, { useEffect } from 'react'
import { useStore } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { emitToastNotification } from '../../../../helpers/toast_helper';
import { CreateScheduler } from '../../../../store/modules/scheduler/action';
import Buttons from '../../../Common/button/Buttons';
import { CLOSE, EDIT, REFRESH, SAVE } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import { getConfirmationModal } from '../../common/helperFunctions';

export const SchedulerHeader = ({
    tabsMenuList = [],
    setActiveTab = () => { },
    activeTab = '',
    refreshButtonClick = {},
    setRefreshButtonClick,
    isRefreshing = false,
    isEditor = false
}) => {
    const { schedulerId } = useParams();
    const location = useLocation()
    const history = useNavigate()
    const store = useStore()

    const [open, setOpen] = React.useState(false)

    useEffect(() => {

        if (location?.state?.activeTab) {
            setActiveTab(location?.state?.activeTab)
        }
    }, [])

    const saveScheduler = () => {
        let payload = store.getState().Scheduler.schedulerPayload;

        if (payload && payload?.name !== '') {
            if (payload && !payload?.step?.length && payload?.type !== 'PARTITION') {
                emitToastNotification('info', Literals.PLEASE_ADD_ATLEAST_ONE_STEP_IN_SCHEDULER)
            } else if (payload && !payload?.pod_ids?.length && payload?.type === 'PARTITION') {
                emitToastNotification('info', Literals.PLEASE_SELECT_ONE_POD)
            } else if (payload.type.toLowerCase() === 'scheduler' && !payload?.dependant_scheduler) {
                emitToastNotification('info', Literals.PLEASE_SELECT_ONE_SCHEDULER)

            } else if (payload.type.toLowerCase() === 'pod' && !payload?.pod_ids.length) {
                emitToastNotification('info', Literals.PLEASE_SELECT_ATLEAST_ONE_SCHEDULER)

            } else {
                if (payload.type.toLowerCase() !== 'time' && payload?.type !== 'PARTITION') {
                    payload['cron'] = null
                }
                store.dispatch(CreateScheduler(window.location.href.includes('scheduler/edit') ? 'PUT' : 'POST', payload, window.location.href.includes('scheduler/edit') ? `scheduler/${schedulerId}/` : 'scheduler/')).then((resp) => {
                    if (resp.status?.toLowerCase() === 'success') {
                        emitToastNotification('success', resp.message)
                        if (window.location.href.includes('scheduler/edit')) {

                            history(Literals.links.SCHEDULER_VIEW + schedulerId, { state: { activeTab: activeTab } })
                        } else if (window.location.href.includes('scheduler/create')) {
                            // history(`${Literals.links.CLARISTA_LAB}#scheduler`)
                            history(`${Literals.links.SCHEDULER_VIEW}${resp.data}?schedulerType=${payload?.type?.toLowerCase() === 'partition' ? 'crawler' : 'alertflows'}`, { replace: true })
                        }
                    }
                })
            }

        } else if (payload?.name === '' && payload?.type !== 'PARTITION') {
            emitToastNotification('info', 'To create a scheduler please provide - scheduler name, minimum one step')

        } else if (payload?.name === '' && payload?.type === 'PARTITION') {
            emitToastNotification('info', 'To create a scheduler please provide - scheduler name')

        }


    }
    return <>
        <div className="sub-header padding-2 py-1 connection-header-wrap">
            <div className="sub-header-left">
                <div className="conn-tab-menu tab-menu">
                    {tabsMenuList.map((menu, i) =>
                        <div
                            key={`${i}-${menu}`}
                            onClick={() => { setActiveTab(menu.key); menu.onClickEvent(menu.key) }}
                            className={`custom-tab-menu-wrapper ${activeTab?.toLowerCase() === menu?.key?.toLowerCase() ? 'active subtitle' : 'subtitle'}`}
                        >
                            {menu.Icon()} <div className='p-1'></div> {menu.title}
                        </div>
                    )}

                </div>
            </div>
            <div className="sub-header-right d-flex">
                {activeTab === 'lastrun' ? <div id="usage-refresh-panel-jobs" className='mr-2 d-flex align-items-center justify-content-end usage-refresh-panel-jobs'>
                    {
                        isRefreshing ? <div className='py-2 label mr-2 mb-0' style={{ lineHeight: 1 }}><i className='bx bx-loader-alt bx-spin mr-1'></i>Refreshing...</div> : ''
                    }
                    {
                    /* {
                    showFilters ?
                        <Popover placement="bottomLeft"
                            title={filterPopover.header}
                            content={filterPopover.body}
                            trigger="click"
                            autoAdjustOverflow={true}
                            visible={showFilterPop}
                            onVisibleChange={handleFilterVisibleChange}
                        >
                            <Tooltip placement='bottom' title='Filter'>
                                <button className={`${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"
                                    } custom-btn btn-with-icon mr-2 ${(filtersToSend.domain !== '' || filtersToSend.pod !== '' ||
                                        filtersToSend.user !== '' ||
                                        filtersToSend.start_time !== '' || filtersToSend.end_time !== '') ? 'rdg-filter-active' : ''
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFilterPop(true)
                                    }
                                    }
                                >
                                    <FILTER />
                                </button>
                            </Tooltip>
                        </Popover> : ''
                }
            */}
                    <Buttons
                        props={{
                            tooltip: "Refresh Logs",
                            buttonText: "",
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                            buttonEvent: () => {

                                setRefreshButtonClick(true)
                            },
                            ImgSrc: () => <span className={`${isRefreshing ? 'bx-spin' : ''} d-flex`}><REFRESH /></span>,
                            isDisable: isRefreshing,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </div> : null}
                <Buttons props={{
                    tooltip: 'Close',
                    buttonText: '', buttonClassName: "custom-btn custom-btn-outline btn-with-icon ml-1", buttonEvent: () => {
                        if (window.location.href.includes('scheduler/edit') || window.location.href.includes('scheduler/create')) {
                            setOpen(true)

                            /* if (window.confirm(Literals.CONFIRMATION_MSG_FOR_UNSAVED)) {

                                history(Literals.links.SCHEDULER)
                            } */
                        } else {
                            history(`${Literals.links.CLARISTA_LAB}#scheduler`)

                        }
                    }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY
                }} />
                {window.location.href.includes('scheduler/view') ? <Buttons props={{
                    tooltip: 'Edit',
                    buttonText: 'Edit', buttonClassName: "custom-btn-primary ml-2 custom-btn btn-with-text", buttonEvent: () => {

                        history(Literals.links.SCHEDULER_EDIT + schedulerId, { state: { activeTab: activeTab } })


                    }, ImgSrc: () => <EDIT />,
                    isDisable: !isEditor,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }} /> : null}
                {window.location.href.includes('scheduler/edit') || window.location.href.includes('scheduler/create') ? <Buttons props={{
                    tooltip: window.location.href.includes('scheduler/edit') ? 'Save' : 'Save',
                    buttonText: window.location.href.includes('scheduler/edit') ? 'Save' : 'Save', buttonClassName: "custom-btn-primary ml-2 custom-btn btn-with-text", buttonEvent: () => {
                        saveScheduler()
                    }, ImgSrc: () => <SAVE />,
                    isDisable: !isEditor || window.location.href.includes('scheduler/view'), buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }} /> : null}
            </div>

            {getConfirmationModal({
                open,
                setOpen,
                heading: 'Unsaved Changes',
                onConfirm: () => history(`${Literals.links.CLARISTA_LAB}#scheduler`),
            })}
        </div>
    </>
}
