import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import useDebounce from '../../../../../customHooks/useDebounce';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { setTransformerNames, updateFlowsElements } from '../../../../../store/modules/flows/flowsActions';
import { setBricksOutputDataset, setScriptOutputDataset } from '../../../../../store/modules/flows/scriptTransformer/scriptAction';
import Accordian from '../../../../Common/accordion/Accordian';
import Buttons from '../../../../Common/button/Buttons';
import SingleSelection from '../../../../Common/dropDown/SingleSelection';
import { DATEDATATYPE, MINUS, PLUS, ACCORDIONDOWNARROW, REFRESH } from '../../../../Common/iconSource';
import { preventEnterEvent, preventSpaceEvent } from '../../../common/helperFunctions';
import { Literals } from '../../../common/literals';

function DatabricksOutputDataSet({
    additional_settings,
    setAdditionalSettings,
    setOutputDataset,
    setdisableExcute,
    showApplyButton,
    configuration,
    applyNewOuputDataSet,
    isEditPermission,
    createClusterConfig
}) {
    const currentTransformer = useSelector(state => state.FlowsReducer.currentTransformer)


    let dataSet = [{ value: "output1", id: `${currentTransformer?.id}|output1|0`, showError: false, showSuccess: false }];
    const [dataSetList, setdataSetList] = useState(dataSet);
    const store = useStore()
    const outputDataSetList = useSelector(state => state.Flows.Script.bricksOutputDataset)
    const flowsElements = useSelector(state => state.FlowsReducer.flowsElements)

    const [term, setTerm] = useState('')

    const [currentDataListIndex, setcurrentDataListIndex] = useState(0)

    const [tempDatasetValueObj, settempDatasetValueObj] = useState({ output1: 0 })
    const transformerNameList = useSelector(state => state.FlowsReducer.transformerName)

    const debouncedSearchTerm = useDebounce(term, 700);

    useEffect(() => {
        if (outputDataSetList && outputDataSetList.length > 0) {
            setdataSetList([...outputDataSetList]);
        }
    }, [outputDataSetList])


    const addDataSetValue = () => {
        let list = [...dataSetList]
        list.push({ value: '', id: `` })
        setdataSetList([...list])
        setdisableExcute(false)

    }

    const ChangeDataSetValue = (value, i) => {
        if (value && value.match(/^[a-z][a-z0-9_]*$/)) {
            let list = [...dataSetList]
            list[i] = { value: value, id: `${currentTransformer?.id}|${value}|${i}`, showSuccess: false, showError: false }
            setdataSetList([...list])

        } else if (value && !value.match(/^[a-z][a-z0-9_]*$/)) {
            emitToastNotification('info', 'Output dataset name must start with any alphabet, uppercase letters and  blank spaces are not allowed')
        } else if (!value && !value.match(/^[a-z][a-z0-9_]*$/)) {
            let list = [...dataSetList]
            list[i] = { value: value, id: `${currentTransformer?.id}|${value}|${i}`, showSuccess: false, showError: false }
            setdataSetList([...list])
        }

    }
    const checkTransformerNameExist = (debouncedSearchTerm) => {
        let list = [...dataSetList]
        let obj = { ...tempDatasetValueObj }
        if (!(debouncedSearchTerm in tempDatasetValueObj)) {
            list[currentDataListIndex]['showError'] = false;
            list[currentDataListIndex]['showSuccess'] = true
            setdataSetList([...list])
            for (const key in obj) {
                const element = obj[key];
                if (element === currentDataListIndex) {
                    delete obj[key]
                    obj[debouncedSearchTerm] = currentDataListIndex
                    settempDatasetValueObj(obj)
                } else {
                    obj[debouncedSearchTerm] = currentDataListIndex
                    settempDatasetValueObj(obj)
                }
            }

        }
        else {
            list[currentDataListIndex]['showError'] = true;
            list[currentDataListIndex]['showSuccess'] = false
            setdataSetList([...list])


        }

    }

    useEffect(
        () => {

            if (debouncedSearchTerm) {

                let list = [...dataSetList]
                if (Object.keys(transformerNameList)) {
                    let isTransformerNameExist = false
                    for (const key in transformerNameList) {
                        const element = transformerNameList[key];
                        if (element === debouncedSearchTerm) {
                            isTransformerNameExist = true
                        }
                    }
                    if (!isTransformerNameExist) {
                        checkTransformerNameExist(debouncedSearchTerm)
                    } else {
                        list[currentDataListIndex]['showError'] = true;
                        list[currentDataListIndex]['showSuccess'] = false
                        setdataSetList([...list])


                    }

                } else {
                    checkTransformerNameExist(debouncedSearchTerm)

                }


            }

        },
        [debouncedSearchTerm]
    );

    const removeDataSetValue = (i) => {
        let list = [...dataSetList]
        let deleteTransformerId = list[i].id
        let filteredDatasetList;
        if (outputDataSetList && outputDataSetList.length > 0) {
            filteredDatasetList = outputDataSetList.filter((ele) => !ele.id.includes(deleteTransformerId))
        }
        let transformerNameList = store.getState().FlowsReducer.transformerName
        delete transformerNameList[deleteTransformerId]

        store.dispatch(setTransformerNames(transformerNameList))

        let currentTabArray = flowsElements.find(item => item.id === currentTransformer.id).tabArray



        let index = currentTabArray.findIndex((item) => item.transformerId === deleteTransformerId);
        if (index > -1) {
            currentTabArray.splice(index, 1)
        }

        if (flowsElements && flowsElements.length > 0) {
            let newFilteredList = flowsElements.filter((ele) => !ele.id.includes(deleteTransformerId))

            if (newFilteredList && newFilteredList.length) {
                newFilteredList.forEach((item) => {
                    if (item.id === currentTransformer.id) {
                        item.content['outputdatasetList'] = filteredDatasetList;
                        item.tabArray = currentTabArray
                    }
                    if (item?.input_source && Object.keys(item.input_source).includes(deleteTransformerId)) {
                        delete item.input_source[deleteTransformerId]
                    }
                })
                store.dispatch(updateFlowsElements([...newFilteredList]))
            }
        }
        if (filteredDatasetList && filteredDatasetList?.length) {

            store.dispatch(setBricksOutputDataset(filteredDatasetList))
        }

        list.splice(i, 1)
        if (list.length > 0) {

            setdataSetList([...list])
            setdisableExcute(false)

        } else {
            setdisableExcute(true)
        }
    }


    const isAllreadyDeclare = (index) => {
        if (outputDataSetList && outputDataSetList.length && outputDataSetList[index] && outputDataSetList[index]['value'] !== '') {
            return true
        } else {
            return false
        }
    }

    const resetDataSet = () => {
        setdataSetList([])
    }
    const onInputChange = (e, i) => {
        setcurrentDataListIndex(i)
        let list = [...dataSetList]
        list[i] = { value: e.target.value, id: `${currentTransformer?.id}|${e.target.value}|${i}`, showSuccess: false, showError: false }
        setdataSetList([...list])
        setTerm(e.target.value);
    }

    let CPU_list = [
        // { key: 0, Name: "Select a condition type", Type: "", value: "" },
        { key: 1, Name: "4GB |2 CORE", Type: "", value: "4GB |2 CORE" },
        { key: 2, Name: "8GB |4 CORE", Type: "", value: "8GB |4 CORE" },
        { key: 3, Name: "16GB |8 CORE", Type: "", value: "16GB |8 CORE" },
        { key: 4, Name: "24GB |10 CORE", Type: "", value: "24GB |10 CORE" },
        { key: 5, Name: "24GB |12 CORE", Type: "", value: "24GB |12 CORE" },
        { key: 6, Name: "32GB |12 CORE", Type: "", value: "32GB |12 CORE" },
        { key: 7, Name: "32GB |14 CORE", Type: "", value: "32GB |14 CORE" },
        { key: 8, Name: "48GB |14 CORE", Type: "", value: "48GB |14 CORE" },
    ];
    // let Memory_list = [
    //     // { key: 0, Name: "Select a condition type", Type: "", value: "" },
    //     { key: 1, Name: "4", Type: "", value: "4G" },
    //     { key: 2, Name: "8", Type: "", value: "8G" },
    //     { key: 3, Name: "16", Type: "", value: "16G" },
    //     { key: 4, Name: "24", Type: "", value: "24G" },
    //     { key: 5, Name: "32", Type: "", value: "32G" },
    //     { key: 5, Name: "48", Type: "", value: "48G" },
    // ]
    const [Memory_list, setMemory_list] = useState([{ key: 1, Name: "4", Type: "", value: "4G" }])

    const innerContent = () => {
        return (
            <div className='additional-settings-parentdiv p-2'>
                <div>
                    {/* <label className='mt-0 grey-color label'>CPU request</label> */}
                    {/* <input
                        required
                        type="number"
                        className={`custom-input-field mt-1`}
                        min={2}
                        max={16}
                        disabled={window.location.href.includes('viewFlows') ? true : false}
                        value={additional_settings.cpu_request}
                        onChange={(e) => setAdditionalSettings({ ...additional_settings,cpu_request: value, cpu_request: e.target.value > 16 ? 16 : e.target.value })}
                    /> */}
                    <div className="form-group">
                        <SingleSelection props={{
                            label: "Cluster Configuration", className: "custom-select-dropdown", placeholder: "Select a cluster configuration", dropDownValue: additional_settings.cluster_id, onClickEvent: (value) => {
                                setAdditionalSettings({ ...additional_settings, cluster_id: value })
                            }, isDisable: window.location.href.includes('viewFlows') ? true : false, optionList: configuration, isSortOptionDisable: true
                        }} />
                    </div>
                    {/* <div className="form-group">
                        <SingleSelection props={{
                            label: "Memory Request", className: "custom-select-dropdown", placeholder: "Select a memory request", dropDownValue: additional_settings.mem_request, onClickEvent: (value) => {
                                setAdditionalSettings({ ...additional_settings, mem_request: value })
                            }, isDisable: window.location.href.includes('viewFlows') ? true : false, optionList: Memory_list
                        }} />
                    </div> */}
                    <label className='mt-0 grey-color label'>Python Additional Libraries <Tooltip title="Please list required python libraries. Multiple libraries should be comma seprated"> <i className="bx bx-help-circle m-1 text-black-50 cursor-pointer"></i></Tooltip></label>
                    <textarea
                        required
                        type="text"
                        className={`custom-textarea-field mt-1`}
                        disabled={window.location.href.includes('viewFlows') ? true : false}
                        value={additional_settings.user_libs}
                        onChange={(e) => setAdditionalSettings({ ...additional_settings, user_libs: `${e.target.value}` })}
                    />
                    {/* <label className='mt-0 grey-color label'>Extra resource limits</label>
                    <input
                        required
                        type="text"
                        disabled={window.location.href.includes('viewFlows') ? true : false}

                        className={`custom-input-field mt-1`}
                        value={additional_settings.extra_resource_limits}
                        onChange={(e) => setAdditionalSettings({ ...additional_settings, extra_resource_limits: `${e.target.value}` })}
                    /> */}
                </div>

            </div>
        )
    }
    let additional_settings_body = [
        {
            key: "Additional_settings",
            header: <div className='d-flex align-items-center'> <span>Additional Settings</span> <Buttons props={{ buttonText: '', tooltip: 'Refresh Cluster Configuration', buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1", buttonEvent: () => { createClusterConfig() }, toggleBtnValue: '', ImgSrc: () => <REFRESH />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_TERTIARY, toggleBtnOption: null }} />  </div>,
            innerContent: () => innerContent(),
            addButton: null
        },
    ];
    return (<>
        <div className='h-100 additional-settings-accord'>
            <Accordian
                props={{
                    isDisable: false,
                    accordionList: additional_settings_body,
                    Icon: () => <ACCORDIONDOWNARROW />,
                    defaultExpandedPanel: [''],
                }}
            />
            <div className="d-flex justify-content-between custom-border-bottom padding-2">
                <div className="d-flex align-items-center">
                    <DATEDATATYPE />
                    <p className="subtitle ml-2 mb-0">Output Datasets</p>
                </div>
                <div className="d-flex align-items-center">
                    {/* <Buttons props={{ buttonText: '', buttonClassName: "danger-btn custom-btn mr-2 btn-with-icon", buttonEvent: () => { resetDataSet() }, toggleBtnValue: '', ImgSrc: () => <RESETFILTER />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_TERTIARY, toggleBtnOption: null }} /> */}
                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-primary custom-btn btn-with-icon", buttonEvent: () => { addDataSetValue() }, toggleBtnValue: '', ImgSrc: () => <PLUS />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_TERTIARY, toggleBtnOption: null }} />
                </div>
            </div>
            <div className='flows-scr-op-props-win'>
                <div>
                    <div className="padding-2">
                        {dataSetList.length ? dataSetList.map((item, i) => <>
                            <div className="d-flex align-item-center mb-2">
                                {/* <DatasetOutputNameInput key={i} isdisabled={showApplyButton && isAllreadyDeclare(i)} setTransformerName={(e)=>ChangeDataSetValue(e, i)} transformerName={dataSetList[i][`value`]} transformerId={`${currentTransformer?.id}_${dataSetList[i][`value`]}_${i}`}/> */}
                                <input key={i} disabled={showApplyButton && isAllreadyDeclare(i)} onKeyDown={(e) => { preventEnterEvent(e); preventSpaceEvent(e) }} onChange={(e) => ChangeDataSetValue(e.target.value, i)} type="text" className="custom-input-field" value={dataSetList[i][`value`]}></input>
                                {/* <span className="position-relative mr-2">
                                    <Tooltip placement='bottom' title={!item.showError ? "Output Dataset Name" : "This name is already exist"}> <div className="custom-search">
                                        <input
                                            type="text"
                                            onChange={(e) => {
                                                onInputChange(e, i)
                                            }}
                                            onKeyDown={(e) => preventEnterEvent(e)}
                                            className={item.showSuccess ? "custom-search-input transformerName_input_success" : item.showError ? "custom-search-input transformerName_input_error" : 'custom-search-input'}
                                            value={item[`value`]}
                                            disabled={showApplyButton && isAllreadyDeclare(i)}
                                            autoFocus
                                        />

                                        <div className="custom-search-icon">
                                            <OUTPUTNAMEICON />
                                        </div>
                                        <div className="custom-close-icon">
                                            {item.showSuccess && <CHECKOUTPUTNAME />}
                                            {item.showError && <ERRORCOLORCLOSE />}
                                        </div>

                                    </div></Tooltip>

                                </span> */}


                                <Buttons props={{ buttonText: '', buttonClassName: "danger-btn ml-2 custom-btn btn-with-icon", buttonEvent: () => { removeDataSetValue(i) }, toggleBtnValue: '', ImgSrc: () => <MINUS />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_TERTIARY, toggleBtnOption: null }} />

                            </div>
                        </>) : <></>}
                    </div>
                    
                    <div className="padding-2">
                        {/* {!showApplyButton ? <Buttons props={{ buttonText: 'Integrate Notebook', buttonClassName: "custom-btn-primary w-100 custom-btn pl-1 btn-with-text", buttonEvent: () => { setOutputDataset(dataSetList) }, toggleBtnValue: '', ImgSrc: () => < ></>, isDisable: !isEditPermission || window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT, toggleBtnOption: null }} />
                            : */}
                            {/* applyNewOuputDataSet(dataSetList)  */}
                            <Buttons props={{ buttonText: 'Apply', buttonClassName: "custom-btn-primary w-100 custom-btn pl-1 btn-with-text", buttonEvent: () => { setOutputDataset(dataSetList) }, toggleBtnValue: '', ImgSrc: () => < ></>, isDisable: !isEditPermission || window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT, toggleBtnOption: null }} />
                        {/* } */}
                    </div>
                </div>

            </div>

        </div>
    </>
    )
}

export default DatabricksOutputDataSet
