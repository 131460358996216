import React, { useEffect, useState } from 'react'
import { Dropdown, Tooltip } from 'antd'


import { CREDITCLUSTER, NEWSTOPICON, PLAY, RUNICON, STOPICON, STOPOCTAGON, TERMINATEDICON } from '../../Common/iconSource'
import { ICON_CALENDAR } from '../../Common/newIconSource'
import { LandingSimpleTable } from '../../Common/landingSimpleTable/LandingSimpleTable'
import { dateformater, getObjectLength, pxToRem, remToPx } from '../common/helperFunctions'

const demo = [
    {
        id: 1,
        coins: 'C 100',
        start_time: '25 Apr 2024 10:00',
        state: 'stopped',
        user: "00:00:01",
        cluster_plan: "prokel@clarista.io"
    },
    {
        id: 2,
        coins: 'C 200',
        start_time: '30 Apr 2024 17:06',
        state: 'running',
        user: "00:00:02",
        cluster_plan: "bhagyashri@clarista.io"
    },
    {
        id: 3,
        coins: 'C 300',
        start_time: '30 Apr 2024 18:00',
        state: 'terminated',
        user: "00:00:03",
        cluster_plan: "prokel@clarista.io"
    },
]
export const HistoryTable = ({
    clusterList,
    setFieldValues = () => { },
    isEdit = false,
    isEditPermission = true
}) => {


    const [data, setData] = useState([])

    const dynamic = px => remToPx(pxToRem(px))

    const listTemplate = {


        coins: (text = '', item) => {
            return <>
                <Tooltip title={text?.toUpperCase()} placement='topLeft'>
                    <div className={` text-with-ellipsis ${item.transaction_type?.toLowerCase()}`}>
                        <span className={item.transaction_type?.toLowerCase()} >{text?.toUpperCase() ?? <div className='gray-color-NA'>N/A</div>}</span>
                        {/* {item.transaction_type?.toLowerCase() === 'debit'?'-':'+'}  */}
                        {/* </span><span className='ml-1 mr-1'><CREDITCLUSTER height="12px" width="12px" color={item.transaction_type?.toLowerCase() === 'debit'?'red':'#31c43b'} /></span> */}
                    </div>
                </Tooltip>

            </>
        },

        timeStamp: (text = '') => {
            return <>
                <Tooltip title={text} placement='topLeft'>
                    <div className='dict-color-dark text-with-ellipsis'>
                        {text ?? <div className='gray-color-NA'>N/A</div>}
                    </div>
                </Tooltip>

            </>
        },
        start_time: (text = '') => {
            return <>
                <div className='dict-color-dark position-relative'>
                    <Tooltip placement='topLeft' title={text}> {text ?? <div className='gray-color-NA'>N/A</div>}</Tooltip>

                </div>
            </>
        },

        user: (user_detail) => {

            return <>
                {/* ml-4  */}
                {user_detail ? <div className='dict-color-dark  position-relative '>
                    <Tooltip placement='topLeft' title={user_detail?.email}> {`${user_detail?.first_name} ${user_detail?.last_name}`}</Tooltip>

                </div> : <div className='gray-color-NA'>N/A</div>}
            </>
        },

        cluster_size: (text = '') => {
            return <>
                {/* ml-4 */}
                <div className='dict-color-dark  position-relative '>
                    <Tooltip placement='topLeft' title={text}> {text ?? <div className=' gray-color-NA'>N/A</div>}</Tooltip>

                </div>
            </>
        },
        cluster_type: (text = '') => {
            return <>
                {/* ml-4 */}
                <div className='dict-color-dark  position-relative '>
                    <Tooltip placement='topLeft' title={text}> {text ?? <div className=' gray-color-NA'>N/A</div>}</Tooltip>

                </div>
            </>
        },

    }

    const columns = [
        {
            name: <label className='mt-0 InterBold table-column-title'>Usage Date/Time</label>,
            key: "timeStamp",
            sortable: false,
            resizable: false,
            width: Math.round(document?.getElementById('credit-usage-modal')?.clientWidth / 6) ?? 200
            // width: 220
        },
        {
            name: <label className='mt-0 InterBold table-column-title'>Cluster Type</label>,
            key: "cluster_type",
            sortable: false,
            resizable: false,
            width: Math.round(document?.getElementById('credit-usage-modal')?.clientWidth / 6) - 30 ?? 150
        },
        {
            name: <label className='mt-0 InterBold table-column-title'>User</label>,
            key: "user",
            sortable: false,
            resizable: false,
            width: Math.round(document?.getElementById('credit-usage-modal')?.clientWidth / 6) - 10 ?? 200
        },
        {
            name: <label className='mt-0 InterBold table-column-title'>Total Cluster Size</label>,
            key: "cluster_size",
            sortable: false,
            resizable: false,
            width: Math.round(document?.getElementById('credit-usage-modal')?.clientWidth / 6) ?? 150
        },

        {
            name: <label className='mt-0 InterBold table-column-title'>Run Time</label>,
            key: "start_time",
            sortable: false,
            resizable: true,
            // width: 'auto'
            width: Math.round(document?.getElementById('credit-usage-modal')?.clientWidth / 6) ?? 150
        },

        {
            name: <label className='mt-0 InterBold table-column-title fw-800'>Coins{'(CC)'}</label>,
            key: "coins",
            sortable: false,
            resizable: false,
            width: Math.round(document?.getElementById('credit-usage-modal')?.clientWidth / 6) - 60 ?? 200
        },





    ]

    useEffect(() => {
        if (clusterList) {  //clusterList

            let temp = clusterList.map((item) => {
                return {
                    id: item.usage,
                    coins: listTemplate.coins(Number(item.amount)?.toFixed(2), item),
                    timeStamp: listTemplate.timeStamp(item.timestamp),
                    start_time: listTemplate.start_time(item.human_runtime),
                    user: listTemplate.user(getObjectLength(item.user_detail) ? item.user_detail : null),
                    cluster_type: listTemplate.cluster_type(item?.cluster_type ?? null),
                    cluster_size: listTemplate.cluster_size(item?.cluster_size ?? null)
                }


            })
            setData([...temp])

        }

    }, [clusterList])

  
    return (
        <div className='history-usage-table'>
            
            {data.length
                ? <LandingSimpleTable
                    cols={columns}
                    // onRowClick={onRowClick}
                    autoWidth={false}
                    removeBorders={true}
                    rowsData={data}
                    tableHeight={"calc(100vh - 30.875rem)"}  //(data?.length * 41) + 41
                    showCursor={false}
                />
                : <div className='p-3 text-center  text-black-50 no-datasrc-found'>No Clusters Found</div>}



        </div>
    )
}



