import React, { useEffect, useState } from 'react'


import { Modal, ModalBody,  ModalHeader } from "reactstrap";

import Buttons from '../../../../Common/button/Buttons';
import { CLOSE } from '../../../../Common/iconSource';
import { Literals } from '../../../common/literals';

import { LandingSimpleTable } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { dataTypeList } from '../../../common/helperFunctions';
import helpfile from '../../../../../assets/datatypes.rst'


const FormatColHelplinkTable = ({ isModalOpen, selectedIds, onClose, save, isSingleSelection, reset, fromRoute = '' }) => {
    const [data, setData] = useState([])

    const getValidValues = (datatype) => {
        switch (datatype.toLowerCase()) {
            case 'boolean':
                return <div className='w-100 text-center helplinktext'>True/False</div>
            case 'integer':
            case 'tinyint':
                return <div className='w-100 text-center helplinktext'>A 8-bit signed two's complement integer with a minimum value of
                    <strong> -128 </strong> and a maximum value of <strong> 127</strong>.</div>
            case 'smallint':
                return <div className='w-100 text-center helplinktext'> A 16-bit signed two's complement integer with a minimum value of

                    <strong> -32768 </strong> and a maximum value of <strong> 32767</strong>.</div>
            case 'number':

            case 'bigint':
                return <div className='w-100 text-center helplinktext'> A 32-bit signed two's complement integer with a minimum value of

                    <strong> -9223372036854775808  </strong> and a maximum value of <strong> 9223372036854775807</strong>.</div>

            case 'real':
            case 'float':
                return <div className='w-100 text-center helplinktext'>A real is a 32-bit inexact
                    Example literals: REAL '10.3', REAL '10.3e0', REAL '1.03e1'</div>
            case 'double':
                return <div className='w-100 text-center helplinktext'>A double is a 64-bit inexact
                    Example literals: Double '10.3', Double '10.3e0', Double '1.03e1'</div>
            case 'decimal':
                return <div className='w-100 text-center helplinktext'>
                  
                    - **precision** - total number of digits
                  
                    - **scale** - number of digits in fractional part. Scale is optional and defaults to 0.</div>
            case 'varchar':

                return <div className='w-100 text-center helplinktext'>
                    Variable length character data with an optional maximum length. SQL statements support simple literal, as well as Unicode usage</div>

            case 'char':
                return <div className='w-100 text-center helplinktext'>
                  A CHAR type without length specified has a default length of 1.
                    A <strong>CHAR(x)</strong> value always has <strong>x</strong> characters</div>


            case 'json':
                return <div className='w-100 text-center helplinktext'>
                    JSON value type, which can be a JSON object, a JSON array, a JSON number, a JSON string,
                    <strong>true</strong>, <strong>false</strong> or <strong>null</strong>.</div>

            case 'date':

                return <div className='w-100 text-center helplinktext'>
                    Calendar date (year, month, day).<strong>DATE '2001-08-22</strong>.</div>
            case 'time':

                return <div className='w-100 text-center helplinktext'>
                    Time of day (hour, minute, second) <strong>TIME '01:02:03.456'</strong>.</div>
            case 'timestamp':

                return <div className='w-100 text-center helplinktext'>
                    This type is effectively a combination of the DATE and TIME(P) types.
                </div>
            case 'array':

                return <div className='w-100 text-center helplinktext'>
                    An array of the given component type.

                    Example: ARRAY[1, 2, 3]'</div>
            case 'map':

                return <div className='w-100 text-center helplinktext'>
                    A map between the given component types.

                    Example:MAP(ARRAY['foo', 'bar'], ARRAY[1, 2])</div>
            default:
                break;
        }
    }
    const getFormatOption = (datatype) => {
        switch (datatype.toLowerCase()) {

            case 'decimal':
                return <div className='w-100 text-center helplinktext'>
                    (10,3), (20)  </div>
            case 'varchar':
            case 'char':
                return <div className='w-100 text-center helplinktext'>
                    (20)</div>

            case 'date':

                return <div className='w-100 text-center d-flex align-items-center justify-content-between helplinktext'>
                    <strong>%Y-%m-%d</strong> <a className='ml-2' href={helpfile} target="_blank" >More format option</a> </div>
            case 'time':
            case 'timestamp':

                return <div className='w-100 text-center d-flex align-items-center justify-content-between helplinktext'>
                    <strong>%H:%i:%s</strong> <a className='ml-2' href={helpfile} target="_blank" >More format option</a></div>
            default:
                break;
        }
    }
    const columns = [
        {
            name: 'Data type',
            key: "data_type",
            sortable: false,
            resizable: true,
            width: "15%",
        }
        ,
        {
            name: 'Valid values',
            key: "valid_values",
            sortable: false,
            resizable: true,
            width: "65%",
        },
        {
            name: 'Format option',
            key: "format_option",
            sortable: false,
            resizable: false,
            width: "20%",

        },

    ]
    let dataObj = []

    useEffect(() => {

        dataObj = dataTypeList.map((item) => {
            let obj = {
                data_type: <div className='w-100 text-left helplinktext'>{item.Name}</div>,
                valid_values: getValidValues(item.Name),
                format_option: getFormatOption(item.Name)
            }

            return obj

        })

        setData(dataObj)
    }, [])

    return (
        <Modal size="lg" isOpen={isModalOpen} className="custom-modal connection-modal" centered={true}>
            <ModalHeader>

                <div className="modal-heading-logs"></div>
                <Buttons props={{ tooltip: 'Close', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon float-right", buttonEvent: () => { onClose() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

            </ModalHeader>
            <ModalBody className='overflow-hidden'>
                <div style={{ height: "500px" }}>
                    <LandingSimpleTable
                        rowsData={data}
                        cols={columns}
                        rowHeight={35}
                        tableHeight={'100%'}
                        showCursor={false}
                    />
                </div>
            </ModalBody>

        </Modal >
    )
}

export default FormatColHelplinkTable
