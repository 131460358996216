import React, { useMemo } from 'react';
import DEFAULT from '../../../assets/images/connectorsIcon/default.svg'
import CLARISTA_DEMO from '../../../assets/images/connectorsIcon/clarista_demo.svg'

import { Tooltip } from "antd";
import { connectorsList } from './helperFunctions';
import { Literals } from './literals';

const sourceIconlist = connectorsList.icons;

const SourceTypeImage = ({ dataName, align = 'left', extraClassName }) => {
    // const imageName = useMemo(() => <div className={`text-${align} ${extraClassName}`}>
    //     {dataName === null || dataName === undefined || dataName === "" ?
    //         <span className='src-span'>
    //             <img src={DEFAULT} className='sources_circle' alt={''} />
    //         </span>
    //         :
    //         <Tooltip placement="topLeft" title={dataName === 'upload' ? 'File Upload' : dataName?.toString()?.replaceAll('_', ' ')}>
    //             <span className='src-span'>
    //                 <img
    //                     src={
    //                         (sourceIconlist[dataName] !== undefined)
    //                             ? sourceIconlist[dataName]
    //                             : (dataName?.toString() === Literals.POD_OF_POD_API || dataName?.toString() === 'POD of POD')
    //                                 ? CLARISTA_DEMO
    //                                 : DEFAULT
    //                     }
    //                     className='sources_circle'
    //                     alt={dataName?.toString()?.replaceAll('_', ' ')}
    //                 />
    //             </span>
    //         </Tooltip>
    //     }
    // </div>
    //     , [dataName]);

    return <div className={`text-${align} ${extraClassName}`}>
        {dataName === null || dataName === undefined || dataName === "" ?
            <span className='src-span'>
                <img src={DEFAULT} className='sources_circle' alt={''} />
            </span>
            :
            <Tooltip placement="topLeft" title={dataName === 'upload' ? 'File Upload' : dataName?.toString()?.replaceAll('_', ' ')}>
                <span className='src-span'>
                    <img
                        src={
                            (sourceIconlist[dataName] !== undefined)
                                ? sourceIconlist[dataName]
                                : (dataName?.toString() === Literals.POD_OF_POD_API || dataName?.toString() === 'POD of POD')
                                    ? CLARISTA_DEMO
                                    : DEFAULT
                        }
                        className='sources_circle'
                        alt={dataName?.toString()?.replaceAll('_', ' ')}
                    />
                </span>
            </Tooltip>
        }
    </div>

}

export default SourceTypeImage;