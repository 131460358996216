import React from 'react'
import './SchemaTableContainer.scss'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { fetchDatabaseSchemaList, fetchDatabaseTableList, fetchTableDataV2, getCreatePodPreviewTable, getDataDomainList, getLandingPageData } from '../../../../../store/actions'
import { getConfirmationModal, getObjectLength, getReloadErrorTemplate, permissions, remToPx } from '../../../common/helperFunctions'
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader'
import { DATASET } from '../../../../Common/iconSource'
import { Badge, Checkbox, Tooltip } from 'antd'
import { ICON_CATALOG_DOMAIN, ICON_CHECK } from '../../../../Common/newIconSource'
import { Literals } from '../../../common/literals'
import Highlighter from 'react-highlight-words'
import _, { cloneDeep, isArray } from 'lodash'
import SearchInput from '../../../../Common/search/Search'
import { LandingSimpleTableInner } from '../../../../Common/landingSimpleTable/LandingSimpleTable'
import { flushSync } from 'react-dom'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import { v4 as uuidv4 } from 'uuid'
import { getSchemaLevelStringPattern } from '../PodCreation'
import { setQueryKillFlag, setTableUUID } from '../../../../../store/modules/common/DataGridTable/dataGridTableRedux'
import { emitToastNotification } from '../../../../../helpers/toast_helper'

const SchemaTableContainer = React.forwardRef(({
    isReadOnly = false,
    isPodOfPod = false,
    isEditMode = false,
    setActiveTabKey = () => {},
    setPreviewUUID = () => {},
    setPreviewError = () => {},
    setPreviewLoading = () => {},
    setQuery = () => {},
    getDetails = () => {},
    setPreviewOverlayLoading = () => {},
    setAnyTableSelected = () => {},
    isAnyTableSelected = false,
    showTableSelectInfo = false,
    setPodPreviewRunning = () => {},
    setStoredStringPattern = () => {},
    setQueryToDisplay = () => {},
    setWalkThrough = () => {},
    isTutorialActive = false,
    isDataDictionary = false,
    editorQuery = '',
    setCurrentActiveTabKey = () => {},
    isCustomQuery = false,
    isApiCompletedRef = false,
    autoClassifyObj = {},
    setAutoClassifyObj = () => {},
    isSqlNotExecuted,
    setIsCustomQuery = () => {},
    setLoadingTablesKeys = () => {},
    setLoadingTablesUUID = () => {}
},
ref) => {
    const store = useStore()
    const dispatch = useDispatch()
    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)
    const selectedConn = useSelector((state) => state.UserManagement.ConnectionReducer.selectedConn)

    const connName = selectedConn?.connection_name??''

    const { keysToSelect, tableData, isTablesPreviewLoaded, clarista_engine } = reduxData

    const [error, setError] = React.useState(undefined)
    const [empty, setEmpty] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [innerLoading, setInnerLoading] = React.useState(false)
    const [data, setData] = React.useState([...reduxData?.schemaListData])
    const [searchResultData, setSearchResultData] = React.useState([])
    const [searchData, setSearchData] = React.useState('')
    const [scrollIndex, setScrollIndex] = React.useState(undefined);
    const [expandedKeys, setExpandedKeys] = React.useState([]);
    const [isPreloadDone, setPreloadDone] = React.useState(false);
    const [isDataLoaded, setDataLoaded] = React.useState(false);
    const [resetTableInfo, setResetTableInfo] = React.useState({});
    const [openConfirmModal, setConfirmModal] = React.useState(false);
    const [autoLoadingPreviewStatus, setAutoLoadingPreviewStatus] = React.useState([]);
    const [totalPreviewToLoad, setTotalPreviewToLoad] = React.useState(undefined);

    const dataRef = React.useRef([]);
    const queryRef = React.useRef(''); // Used in Edit Mode When Data is loading

    React.useImperativeHandle(ref, () => {
        return {
            previewTableData: (key) => {
                let [schemaName, tableName] = key?.split('.')
                importTableData({rawName: tableName, parentName: schemaName})
            }
        }
    }, [])

    React.useEffect(() => {
        if(reduxData?.schemaListData?.length === 0)
        fetchSchemaList()
    }, [])

    React.useEffect(() => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        if(isEditMode && isNotFileSystem() && !isConnectionChanged()) {
            if(selectedConn?.connection_name !== podData?.source?.[0]?.split('.')[0] && !isPodOfPod) {
                setPreviewOverlayLoading(false)
                return
            }

            if((isTablesPreviewLoaded || isPreloadDone) 
                && (totalPreviewToLoad === undefined && autoLoadingPreviewStatus?.length === 0)
                ) {
                setPreviewOverlayLoading(false)
                return
            }

            if(!isCustomQuery) {
                setPreviewOverlayLoading(totalPreviewToLoad !== autoLoadingPreviewStatus?.length)
            }
        }
    }, [totalPreviewToLoad, autoLoadingPreviewStatus])

    React.useEffect(() => {
        if(keysToSelect && isArray(keysToSelect)) {
            setExpandedKeys(keysToSelect)
        }
    }, [keysToSelect])

    React.useEffect(() => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        if(isPreloadDone || isTablesPreviewLoaded) return
        if(isEditMode && data?.length > 0) {
            if(isNotFileSystem() && !isConnectionChanged()) {
                if(selectedConn?.connection_name === podData?.source?.[0]?.split('.')[0] || isPodOfPod) {
                    autoLoad(expandedKeys)
                }
            }
        }
    }, [expandedKeys, isPreloadDone, isDataLoaded])

    React.useEffect(() => {
        if(!isAnyTableSelected) {
            setQuery('')
        }
    }, [isAnyTableSelected])

    React.useEffect(() => {
        setAnyTableSelected(
            isCustomQuery
            ?
            getObjectLength(reduxData?.tableData) > 1
            :
            getObjectLength(reduxData?.tableData) > 0
        )
    }, [reduxData, isCustomQuery])

    React.useEffect(() => {
        if(searchData.trim().length > 0) {
            let tempData = [...data]
            let resultList = tempData.filter(
                item => {
                    return  (
                            item?.children?.length 
                            && item?.children?.some(v => v?.name?.includes(searchData?.toLowerCase()))
                            ) 
                            || item?.name?.includes(searchData?.toLowerCase())
                }
            )
            setSearchResultData([...resultList])
        }   else setSearchResultData([])
    }, [searchData, data])

    const isNotFileSystem = () => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        return podData?.source_type !== 's3' && podData?.source_type !== 'azure_adls'
    }

    const isConnectionChanged = () => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        if(isPodOfPod) {
            if(podData?.source_type === Literals.POD_OF_POD_API) {
                return false
            }
            return podData?.connection !== selectedConn?.connection_name
        }
        /**
         * For Future use
         */
        // setIsCustomQuery(!(selectedConn?.file_system ?? true))
        return podData?.source?.[0].split('.')?.[0] !== selectedConn?.connection_name
    }

    const autoLoad = async (keysArr) => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        const resultQuery = podData?.original_query
        setQuery(resultQuery)
        let query = resultQuery ?? reduxData.schemaQueryMap.result
        setQueryToDisplay(query)
        reduxData.schemaQueryMap['result'] = query
        setReduxData(reduxData)
        if(isCustomQuery) {
            setLoadingTablesKeys(prev => [...prev, 'result'])
        }

        for (let i = 0; i < keysArr.length; i++) {
            let id = data.find(v => v.name === keysArr[i])?.id??null
            let schemaName = keysArr[i]
            await onLoadTables(schemaName, [], id, true, i === keysArr.length - 1)
        }
        setPreloadDone(true)
    }

    const autoLoadPreview = async() => {
        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData

        getDetails()

        const resultQuery = podData?.original_query === null ? podData?.query : podData?.original_query
        setQuery(resultQuery)
        // reduxData.schemaQueryMap.result = resultQuery
        // setReduxData(reduxData)

        if(isCustomQuery) {
            /*********************For Generating Result************************/
            const { parameters, clarista_engine } = reduxData
            let uuid = uuidv4()
            setPreviewUUID(uuid)
            /**To Handle Cancel Preview**/
            dispatch(setQueryKillFlag(false))
            dispatch(setTableUUID(uuid))
    
            let params = parameters.length === 0 ? undefined
                        : _.uniqBy(parameters, 'name')?.reduce((acc, curr) => ({...acc, [curr?.name]: curr?.value}), {})
            , connection_name = isPodOfPod ? undefined : selectedConn?.connection_name
            , query = resultQuery ?? reduxData.schemaQueryMap.result
            , selected_tables = [...podData?.source]

            let stringPattern = getSchemaLevelStringPattern(parameters, query, clarista_engine)
            setStoredStringPattern(stringPattern)
    
            const payload = { query, uuid, clarista_engine, params, connection_name, selected_tables }
            try {
                /**
                 * isApiCompletedRef is Ref
                 */
                isApiCompletedRef.current = false

                setPreviewLoading(true)
                setPodPreviewRunning(true)
                setPreviewError(undefined)
                const res = await dispatch(getCreatePodPreviewTable(payload, false, false))
                const { result, output_regex } = res?.data

                setAutoClassifyObj(prev => {
                    prev = {...prev, result: reduxData?.tableData?.['result']?.autoClassify ?? false}
                    return prev
                })

                isApiCompletedRef.current = true

                // setQueryToDisplay(query)

                /**
                 * For Comment Based Query follow this code
                 */
                // setQueryToDisplay(prevQuery => {
                //     let qry = `${prevQuery} \n \n${query}`
                //     reduxData['output_regex'] = output_regex ?? []
                //     reduxData.tableData['result'] = {...result}
                //     reduxData.schemaQueryMap['result'] = query
        
                //     reduxData.isTablesPreviewLoaded = true  // to prevent autoLoad, once done.
                //     setReduxData(reduxData)

                //     queryRef.current = qry

                //     return qry
                // })
                /**
                 * End of Above comment
                 */
    
                reduxData['output_regex'] = output_regex??[]
                reduxData.tableData['result'] = {...result, autoClassify: autoClassifyObj['result'] ?? false}
                // reduxData.schemaQueryMap['result'] = query
                reduxData.executeSqlPayload = payload
    
                reduxData.isTablesPreviewLoaded = true  // to prevent autoLoad, once done.
    
                setReduxData(reduxData)
                setPreviewLoading(false)
                setPodPreviewRunning(false)
                setLoadingTablesKeys(prev => prev?.filter(t => t !== 'result'))
    
                // let stringPattern = getSchemaLevelStringPattern(parameters, query, clarista_engine)
                // setStoredStringPattern(stringPattern)
            }
            catch(err) {
                isApiCompletedRef.current = true

                // setQueryToDisplay(query)
                // reduxData.schemaQueryMap['result'] = query
                reduxData.isTablesPreviewLoaded = true  // to prevent autoLoad, once done.
                setReduxData(reduxData)
    
                setPreviewError(err?.message)
                setPreviewLoading(false)
                setPodPreviewRunning(false)
                setLoadingTablesKeys(prev => prev?.filter(t => t !== 'result'))
            }
            /*********************For Generating Result************************/
        }
        else {
            reduxData.isTablesPreviewLoaded = true
            setReduxData(reduxData)
        }


        let list = podData?.source
        if(!isPodOfPod) {
            list = list?.map(v => v.split('.').slice(1).join('.'))
        }
        setTotalPreviewToLoad(list.length)
        for (let i = 0; i < list.length; i++) {
            if(isCustomQuery) {
                reduxData.tableData[list[i]] = {} 
            }
            else {
                let [ schema_name, table_name ] = list[i]?.split('.')
                await importTableData({rawName: table_name, parentName: schema_name, loadingTables: true})
            }
        }
        if(isCustomQuery) {
            setReduxData(reduxData)
        }
        
    }

    const treeViewData = React.useMemo(() => {
        if(searchData?.trim()?.length > 0 && searchResultData.length === 0) return []
        if(searchResultData.length) return searchResultData
        return data
    }, [data, searchResultData, searchData])

    const fetchSchemaList = () => {
        setError(undefined)
        setLoading(true)

        if(isPodOfPod) {
            dispatch(getDataDomainList(true, false)).then((res) => {
                if (res) {
                    const resData = res.data;

                    setData(() => {
                        let temp = resData.map((v, i) => {
                            return (
                                {
                                    name: v?.data_domain_name,
                                    rawName: v?.data_domain_name,
                                    id: v?.data_domain_id,
                                    isExpanded: false,
                                    isLoaded: false,
                                    children: []
                                }
                            )
                        })

                        reduxData['schemaListData'] = [...temp]
                        setReduxData(reduxData)

                        return temp
                    })
                    
                    if(!isEditMode && isTutorialActive) {
                        setTimeout(() => {
                            setWalkThrough(prev => {
                                prev = {...prev, run: isTutorialActive}
                                return prev
                            })
                        }, 1000)
                    }

                    setDataLoaded(true)
                    setLoading(false)
                }
            })
            .catch(err => {
                setError(getReloadErrorTemplate(
                    {
                        errorMessage: err?.message,
                        onReload: fetchSchemaList
                    }
                ))
                setLoading(false)
            })

            return
        }


        const requestBody = {
            connection_name: connName
        };
        dispatch(fetchDatabaseSchemaList(requestBody, false, false))
        .then(res => {
            const { data } = res
            const { data_record } = data
            const schemaNames = data_record?.flat()
            setEmpty(data_record?.length === 0)

            setData(() => {
                let temp = schemaNames.map((v, i) => (
                  {
                    name: v,
                    rawName: v,
                    id: i,
                    isExpanded: false,
                    isLoaded: false,
                    children: []
                  }
                ))
                reduxData['schemaListData'] = [...temp]
                setReduxData(reduxData)
                return temp
            })
            if(!isEditMode && isTutorialActive) {
                setTimeout(() => {
                    setWalkThrough(prev => {
                        prev = {...prev, run: isTutorialActive}
                        return prev
                    })
                }, 1000)  
            }
            setDataLoaded(true)
            setLoading(false)
        })
        .catch(err => {
            setError(getReloadErrorTemplate(
                {
                    errorMessage: err?.message,
                    onReload: fetchSchemaList
                }
            ))
            setLoading(false)
        })
    }

    const onLoadTables = async(key, children, id, preload = false, isLastCall = false) => {
    
        if (!isPodOfPod) {
            let temp = [...data]
            const schemaName = key;
            return await new Promise((resolve) => {
                if (children.length) {
                    resolve();
                    toggleSubRow(temp, id)
                    return;
                }
    
                let requestBody = {
                    connection_name: connName,
                    schema_name: schemaName
                };

                setData(prev => {
                    prev = prev.map(item => {
                        return (
                            {
                                ...item,
                                isLoading: item.name === schemaName
                            }
                        )
                    })
                    temp = [...prev]
                    return prev
                })
                setInnerLoading(true)
                dispatch(fetchDatabaseTableList(requestBody, false, true)).then((res) => {
                    if (res) {
        
                        let records = res?.data?.data_record
                        flushSync(() => {

                            setData(prev => {
                                prev = prev.map((item, i) => {
                                    return (
                                    item.name === schemaName
                                        ? {
                                        ...item,
                                        isLoaded: true,
                                        isLoading: false,
                                        children:
                                            records.map((d, c_idx) => (
                                            {
                                                name: d[0],
                                                rawName: d[0],
                                                isChecked: false,
                                                isChild: true,
                                                id: `${i}-${c_idx}`,
                                                parentId: item.id,
                                                parentName: item.name
                                            }
                                            ))
                                        }
                                        : { ...item }
                                    )
                                })
                                temp = [...prev]
                                reduxData['schemaListData'] = [...temp]
                                setReduxData(reduxData)
                                return prev
                            })
                        })
        
                        if (isEditMode && preload) {
                            const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
            
                            let selectedItems = podData?.source?.map(v => `${v.split('.')[1]}-${v.split('.')[2]}`)
            
                            temp = temp.map(itm => {
            
                            return (
                                itm.name === schemaName
                                ?
                                {
                                    ...itm,
                                    children: itm?.children?.map(v => (
                                    {
                                        ...v,
                                        isChecked: selectedItems.includes(`${itm.name}-${v.name}`)
                                    }
                                    ))
                                }
                                : { ...itm }
                            )
                            })

                            if(isLastCall) {
                                autoLoadPreview()
                            }

                        }
                    
                        toggleSubRow(temp, id)
                        setInnerLoading(false)
                        // reduxData['schemaListData'] = [...temp]
                        // setReduxData(reduxData)
                    }
                })
                .catch(() => {
                    setInnerLoading(false)
                })
            resolve();
          });
        }
        else {
            return await new Promise((resolve) => {
                let temp = dataRef.current.length ? [...dataRef.current] : [...data]

                if (children.length) {
                    resolve();
                    toggleSubRow(temp, id)
                    return;
                }

                setData(prev => {
                    prev = prev.map(item => {
                        return (
                            {
                                ...item,
                                isLoading: item.name === key
                            }
                        )
                    })
                    temp = [...prev]
                    return prev
                })
                setInnerLoading(true)
                dispatch(getLandingPageData(false, false, false, id))
                .then(res => {
                    const resData = res?.data
                    const pods = resData?.filter(p => !!p?.permission?.find(v => v === permissions.dataCatalog.editor))
                    flushSync(() => {

                        setData(prev => {
                            prev = prev.map((item, i) => {
                                return (
                                item.name === key
                                    ? {
                                    ...item,
                                    isLoaded: true,
                                    isLoading: false,
                                    children:
                                        pods.map((d, c_idx) => (
                                            {
                                                name: d?.table_name,
                                                rawName: d?.table_name,
                                                isChecked: false,
                                                isChild: true,
                                                id: `${item?.id}-${d?.table_id}`,
                                                parentId: item.id,
                                                parentName: item.name
                                            }
                                        ))
                                    }
                                    : { ...item }
                                )
                            })
                            temp = [...prev]
                            reduxData['schemaListData'] = [...temp]
                            setReduxData(reduxData)
                            return prev
                        })
                    })

                    if (isEditMode && preload) {
                        const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
        
                        let selectedItems = podData?.source?.map(v => `${v.split('.')[0]}-${v.split('.')[1]}`)
        
                        temp = temp.map(itm => {
        
                        return (
                            itm.name === key
                            ?
                            {
                                ...itm,
                                children: itm?.children?.map(v => (
                                {
                                    ...v,
                                    isChecked: selectedItems.includes(`${itm.name}-${v.name}`)
                                }
                                ))
                            }
                            : { ...itm }
                        )
                        })

                        if(isLastCall) {
                            autoLoadPreview()
                        }

                    }

                    toggleSubRow(temp, id)
                    setInnerLoading(false)

                })
                .catch(() => {
                    setInnerLoading(false)
                })
                dataRef.current = [...temp]
                resolve()
            })
        }
    };

    function toggleSubRow(rows, id) {
        const rowIndex = rows.findIndex((r) => r.id === id);
        const row = rows[rowIndex];
        const { children } = row;
        if (!children) return rows;
    
        const newRows = [...rows];
        newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
        if (!row.isExpanded) {
          newRows.splice(rowIndex + 1, 0, ...children);
        } else {
          newRows.splice(rowIndex + 1, children.length);
        }
        dataRef.current = [...newRows]
        reduxData['schemaListData'] = [...newRows]
        setReduxData(reduxData)

        setData([...newRows])
    }
    
    function handleSubRow(rows, id) {
        const row = rows.find((r) => r.id === id);
        if (!row || !row.parentId) {
          setData([...rows])
        }
    
        const newRows = rows.map(r => (r.id === id ? { ...r, isChecked: !r.isChecked, isLoading: false } : { ...r }));
    
        const parentRowIndex = newRows.findIndex((r) => r.id === row.parentId);
        const { children } = newRows[parentRowIndex];
    
        if (children) {
          const newChildren = children.map((sr) => (sr.id === id ? { ...sr, isChecked: !sr.isChecked, isLoading: false } : { ...sr }));
          newRows[parentRowIndex] = { ...newRows[parentRowIndex], children: newChildren };
        }

        reduxData['schemaListData'] = [...newRows]
        setReduxData(reduxData)
    
        setData([...newRows])
    }

    const parentNameTemplate = (val, isOpen = false, id, row) => {
        let anyChildActive = row?.children.some(v => v.isChecked)
        let selectedChilds = row?.children.filter(v => v.isChecked).length
        let colorChange = (isOpen || anyChildActive)
        let isEmpty = (row.isLoaded && row.children.length === 0)
        const onlyReadMode = isReadOnly || innerLoading
        return (
            <div style={{ opacity: onlyReadMode ? '0.6' : '1' }} className={`schema-name-wrap d-flex align-items-center ${colorChange ? 'color-primary' : ''}`}>
                <i onClick={() => {
                if (onlyReadMode) return
                if (!isOpen && !row.isLoaded) onLoadTables(val, row.children, id)
                else toggleSubRow(data, id)
                }}
                className={`cursor-pointer h6 mb-0 mr-1 bx bx-${row?.isLoading ? 'loader-alt bx-spin' : !isOpen ? 'caret-right' : 'caret-down'}`}></i>
                <ICON_CATALOG_DOMAIN height="16" width="20" />
                <Tooltip title={val.toUpperCase()} placement={'right'}>
                <p style={{ maxWidth: `calc(100% - ${!isEmpty ? '3.562' : '4.75'}rem)` }} className={`w-auto text-with-ellipsis mb-0 font-w-600 paragraph text-uppercase pl-1 ${colorChange ? 'color-primary' : ''}`}>
                    {`${val}`}
                </p>
                </Tooltip>
                {
                isEmpty
                    ?
                    <Badge style={{ backgroundColor: '#fbad58' }} title={`No Tables Found`} className="custom-tree-badge" count={'Empty'} />
                    : ''
                }
                <Badge
                title={`${selectedChilds} table${selectedChilds === 1 ? '' : 's'} selected`}
                className="custom-tree-badge"
                count={selectedChilds}
                onClick={() => {
                    let rowIndex = data.findIndex(item => item?.parentId === id && item?.isChecked)
                    setScrollIndex(rowIndex)
                    setTimeout(() => setScrollIndex(undefined), 500)
                }}
                />
            </div>
        )
      }
    
      const childNameTemplate = ({ name, isChecked = false, ...row }) => {
    
        const parentName = row.parentId !== undefined
          && data.find(
            item => (item.id === row.parentId)
          )?.name
        const onlyReadMode = isReadOnly || innerLoading || row?.isLoading
        return (
          <React.Fragment>
            {/* <Tooltip title={name} placement={'topLeft'}> */}
                <p
                    // title={name}
                    className="pl-4 paragraph mb-0 mx-n2 sch-cont-child"
                    onClick={() => {
                        if (onlyReadMode) return
                        onCheckboxClick({name, isChecked, ...row})
                    }}
                    style={{ opacity: onlyReadMode ? '0.6' : '1',
                        display: 'grid',
                        gridTemplateColumns: '1.75rem 1.25rem 0.125rem minmax(6.25rem, auto)',
                        alignItems: 'center', 
                        background: onlyReadMode && isChecked ? '#2c83c442' : undefined
                    }}
                >
                    {
                        row?.isLoading
                        ?   <i className='bx bx-loader-alt bx-spin' style={{height: '0.875rem', width: '0.875rem', fontSize: '0.875rem', marginLeft: 6}}></i>
                        :   onlyReadMode && isChecked
                            ?
                            <span className='d-flex align-items-center justify-content-center sch-chk-ic'>
                                <ICON_CHECK width='14' height='14' color='#2c83c4' />
                            </span>
                            :
                            <Checkbox disabled={onlyReadMode} checked={isChecked} className="ml-1 mr-2" />
                    }
                    <DATASET width={15} height={16} />
                    <span></span>
                    {/* <span className="p-1"></span> */}
                    {/* {name} */}
                <Tooltip title={name} placement={'right'}>
                    <Highlighter 
                        className="d-block text-with-ellipsis"
                        searchWords={[searchData?.toLocaleLowerCase()]} 
                        autoEscape={true} 
                        textToHighlight={name??''}> 
                        <span className="single-line-ellipsis">{name??''}</span>
                    </Highlighter>
                </Tooltip>
    
                </p>
            {/* </Tooltip> */}
          </React.Fragment>
        )
    }

    const importTableData = (info) => {
        const dataList = [...data]
        const table_name = info?.rawName
        const schema_name = info?.parentName

        //***** */ /* This code was in use when query as comment was added to Editor, Disabling this as Comment is removed. */

        //***** */ /** Setting Query, if user made any changes and selected another table without Running or Going to Schema.
        //***** */  ** As we are saving in Redux on Running the query and setting to Parent 'setEditorQuery' on Next button click
        //***** */  ** to prevent loss of editor data 
        //***** */  **/
        //***** */ // if(info?.loadingTables === undefined || info?.loadingTables === false) {
        //***** */ //     /** We are not setting this state while autoloading of records,
        //***** */ //      ** when we are in Edit Mode
        //***** */ //      **/
        //***** */ //     setQueryToDisplay(editorQuery) 
        //***** */ // }
        

        dispatch(setQueryKillFlag(false))

        let uuid = uuidv4()
        let payload = {
            uuid,
            table_name,
            schema_name,
            connection_name: isPodOfPod ? 'hive' : connName
        }
        setInnerLoading(true)
        if(!info?.loadingTables) {
            setInnerLoadingStatus(info?.id)
        }

        let tableKey = `${schema_name}.${table_name}`
        setLoadingTablesKeys(prev => [...prev, tableKey])
        setLoadingTablesUUID(prev => ({...prev, [tableKey]: uuid}))

        if(isPodOfPod) {
            isApiCompletedRef.current = false
            dispatch(getCreatePodPreviewTable(payload, false))
            .then(res => {
                const { result, output_regex } = res?.data

                isApiCompletedRef.current = true

                if(info?.id) {
                    handleSubRow(dataList, info?.id)
                }
                let key = `${schema_name}.${table_name}`
                let columns = result?.data_header?.map(c => `"${c}"`)
                let query = `SELECT ${columns?.join(', ')} FROM ${key?.split('.')?.map(k => `"${k}"`)?.join(".")}`

                setAutoClassifyObj(prev => {
                    prev = {...prev, [key]: reduxData?.tableData?.[key]?.autoClassify ?? false}
                    return prev
                })

                // reduxData.tableData[key] = {...result}
                // reduxData.schemaQueryMap[key] = query
                // setReduxData(reduxData)

                if(isEditMode && info?.loadingTables) {
                    if(!isCustomQuery) {
                        /* setQueryToDisplay(() => {
                            // let prevQuery = queryRef.current
                            let prevQuery = `-- Write Your SQL Here\n \n \n \n-- Here are some examples`
                            let qry = `${prevQuery} \n \n-- ${query}`
                            reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                            reduxData.schemaQueryMap[key] = query
                            reduxData.schemaQueryMap['result'] = qry

                            
                            const { parameters, clarista_engine } = reduxData
                            let stringPattern = getSchemaLevelStringPattern(parameters, qry, clarista_engine)
                            setStoredStringPattern(stringPattern)

                            // queryRef.current = qry
                            setReduxData(reduxData)
                            return qry
                        }) */

                        reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                        reduxData.schemaQueryMap[key] = query

                        // queryRef.current = qry
                        setReduxData(reduxData)
                    }
                    else {
                        reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                        reduxData.schemaQueryMap[key] = query
                        setReduxData(reduxData)
                    }

                    if(isCustomQuery) {
                        setActiveTabKey('result')
                    }
                    setAutoLoadingPreviewStatus(prev => [...prev, true])
                }
                else {
                    /* setQueryToDisplay(prevQuery => {
                        let qry = `${prevQuery} \n \n-- ${query}`
                        reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                        reduxData.schemaQueryMap[key] = query
                        reduxData.schemaQueryMap['result'] = qry
                        setReduxData(reduxData)
                        return qry
                    }) */

                    reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                    reduxData.schemaQueryMap[key] = query
                    setReduxData(reduxData)

                    setActiveTabKey(key)
                    setCurrentActiveTabKey(key)
                }
                // setActiveTabKey(key)
                setInnerLoading(false)
                setLoadingTablesKeys(prev => prev?.filter(t => t !== tableKey))
                setLoadingTablesUUID(prev => {
                    delete prev[tableKey]
                    return prev
                })
            })
            .catch(() => {
                isApiCompletedRef.current = true
                if(isEditMode && info?.loadingTables) {
                    setAutoLoadingPreviewStatus(prev => [...prev, false])
                }
                setInnerLoadingStatus(info?.id, true) 
                setInnerLoading(false)
                setLoadingTablesKeys(prev => prev?.filter(t => t !== tableKey))
                setLoadingTablesUUID(prev => {
                    delete prev[tableKey]
                    return prev
                })
            })
        }
        else {
            isApiCompletedRef.current = false
            dispatch(fetchTableDataV2(payload, false, true, true))
            // dispatch(fetchTableDataV2(payload, false, true, isDataDictionary))
            .then(res => {
                const { result, output_regex } = res?.data
                isApiCompletedRef.current = true

                if(info?.id) {
                    handleSubRow(dataList, info?.id)
                }

                let key = `${schema_name}.${table_name}`
                let columns = result?.data_header?.map(c => `"${c}"`)
                let query = `SELECT ${columns?.join(', ')} FROM ${clarista_engine ? `"${connName}".${key?.split('.')?.map(k => `"${k}"`)?.join(".")}` : key?.split('.')?.map(k => `"${k}"`)?.join(".")}`

                
                setAutoClassifyObj(prev => {
                    prev = {...prev, [key]: reduxData?.tableData?.[key]?.autoClassify ?? false}
                    return prev
                })

                // reduxData.tableData[key] = {...result, output_regex}
                // reduxData.schemaQueryMap[key] = query
                // setReduxData(reduxData)

                if(isEditMode && info?.loadingTables) {
                    if(!isCustomQuery) {
                        /* setQueryToDisplay(() => {
                            // let prevQuery = queryRef.current
                            let prevQuery = `-- Write Your SQL Here\n \n \n \n-- Here are some examples`
                            let qry = `${prevQuery} \n \n-- ${query}`
                            reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                            reduxData.schemaQueryMap[key] = query
                            reduxData.schemaQueryMap['result'] = qry

                            const { parameters, clarista_engine } = reduxData
                            let stringPattern = getSchemaLevelStringPattern(parameters, qry, clarista_engine)
                            setStoredStringPattern(stringPattern)

                            // queryRef.current = qry
                            setReduxData(reduxData)
                            return qry
                        }) */

                        reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                        reduxData.schemaQueryMap[key] = query

                        setReduxData(reduxData)
                    }
                    else {
                        reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                        reduxData.schemaQueryMap[key] = query
                        setReduxData(reduxData)
                    }


                    if(isCustomQuery) {
                        setActiveTabKey('result')
                    }
                    setAutoLoadingPreviewStatus(prev => [...prev, true])
                }
                else {

                    /* setQueryToDisplay(prevQuery => {
                        let qry = `${prevQuery} \n \n-- ${query}`
                        reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                        reduxData.schemaQueryMap[key] = query
                        reduxData.schemaQueryMap['result'] = qry
                        setReduxData(reduxData)
                        return qry
                    }) */

                    reduxData.tableData[key] = {...result, output_regex, autoClassify: autoClassifyObj[key] ?? false}
                    reduxData.schemaQueryMap[key] = query
                    setReduxData(reduxData)

                    setActiveTabKey(key)
                    setCurrentActiveTabKey(key)
                }
                // setActiveTabKey(key)
                setInnerLoading(false)
                setLoadingTablesKeys(prev => prev?.filter(t => t !== tableKey))
                setLoadingTablesUUID(prev => {
                    delete prev[tableKey]
                    return prev
                })
            })
            .catch(() => {
                isApiCompletedRef.current = true
                if(isEditMode && info?.loadingTables) {
                    setAutoLoadingPreviewStatus(prev => [...prev, false])
                }
                setInnerLoadingStatus(info?.id, true) 
                setInnerLoading(false)
                setLoadingTablesKeys(prev => prev?.filter(t => t !== tableKey))
                setLoadingTablesUUID(prev => {
                    delete prev[tableKey]
                    return prev
                })
            })
        }
    }

    const removeTableData = (info) => {
        const dataList = [...data]
        const table_name = info?.rawName
        const schema_name = info?.parentName

        handleSubRow(dataList, info?.id)
        let key = `${schema_name}.${table_name}`

        delete reduxData.tableData[key]
        delete reduxData.schemaQueryMap[key]
        setReduxData(reduxData)
        if(isEditMode && isCustomQuery) {
            setActiveTabKey('result')
        }
        else {
            setActiveTabKey(_.last(Object.keys(reduxData.tableData)))
        }
    }

    const setInnerLoadingStatus = (id, stopLoading = false) => {
        setData(prev => {
            prev = prev.map(item => ({
                ...item,
                isLoading: stopLoading ? false : id === item?.id
            }))

            reduxData['schemaListData'] = [...prev]
            setReduxData(reduxData)

            return prev
        })
    }

    const onCheckboxClick = (info) => {
        /**
         * No need to stop actions, keep this commented code for future understanding.
         */
        // if(isSqlNotExecuted) {
        //     emitToastNotification('info', 'Run SQL again to perform this action as "Query, Parameters Or Engine" have been changed')
        //     return
        // }

        if (!info?.isChecked) {
            importTableData(info);
        } else {
            if(getObjectLength(tableData?.result) > 0) {
                setResetTableInfo({...info})
                setConfirmModal(true)
                return
            }
            removeTableData(info)
        }
    }

    const onConfirm = () => {
        reduxData.tableData.result = {}
        reduxData.schemaQueryMap.result = ''
        removeTableData(resetTableInfo)
    }

    const setReduxData = (data) => {
        dispatch(setPodCreationData(data))
    }

    const columns = [
        {
          name: <SearchInput 
                  placeholder={'Search tables...'}
                  isDisable={isReadOnly} 
                  searchData={searchData} 
                  setSearchData={setSearchData} />,
          key: "name",
          sortable: false,
          resizable: false,
          renderCell: ({ row }) => {
            const hasChildren = row.children !== undefined;
            return (
              <>
                {hasChildren && (
                  parentNameTemplate(row.name, row.isExpanded, row.id, row)
                )}
                <div className="rdg-cell-value">
                  {
                    !hasChildren && (childNameTemplate(row))
                  }
                </div>
              </>
            );
          }
        }
    ];

    return (
        <div className='h-100 schema-table-container-wrap'>
            {
                loading ? <ClaristaLoader/>
                :
                error ? error
                :   empty 
                    ?   <div className='my-5 mx-auto w-50'>
                            <div className='alert alert-info text-center fontSizeHeading'>
                                No Folders Found
                            </div>
                        </div>
                    :  <div id={'schema-table-container'} className={`custom-pod-crt-tree ${isEditMode ? 'src-view' : ''} ${showTableSelectInfo ? 'highlight-schlist' : ''}`}>
                            {
                                showTableSelectInfo 
                                ?   <div className='text-center alert alert-danger p-1 small m-1'>Select Any Table To Continue</div>
                                :   ''
                            }
                            {
                                (isEditMode 
                                    && isNotFileSystem() 
                                    && !isConnectionChanged()
                                    && autoLoadingPreviewStatus.length !== totalPreviewToLoad && !isTablesPreviewLoaded)
                                ?   <div className='p-1 text-center small'>Please wait preview is loading...</div>
                                :   ''
                            }
                            <div className={`h-100 ${showTableSelectInfo ? 'blink2' : ''}`}>
                                <LandingSimpleTableInner
                                    cols={columns}
                                    autoWidth={true}
                                    rowsData={treeViewData}
                                    showCursor={false}
                                    tableHeight={'100%'}
                                    rowHeight={ remToPx(2.18) }
                                    scrollToRow={scrollIndex}
                                    // headerRowHeight={0}
                                />
                            </div>
                        </div>
            }
            {
                getConfirmationModal({
                    open: openConfirmModal,
                    setOpen: setConfirmModal,
                    body: <>
                    This action will reset the Result Query and Schema <br/>
                    Do you want to proceed?
                    </>,
                    onConfirm
                })
            }
        </div>
    )
})

export default SchemaTableContainer