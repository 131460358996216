import React from 'react'
import Buttons from '../../../Common/button/Buttons';
import { ICON_ADD_TRACK, ICON_ALERT, ICON_ARROW_LEFT, ICON_ARROW_RIGHT, ICON_BAR_CHART, ICON_CHAT_TAIL, ICON_CHECK, ICON_CLARISTA, ICON_CLOSE, ICON_DELETE, ICON_FILE, ICON_GRAPH_AREA, ICON_GRAPH_BAR, ICON_GRAPH_COLUMN, ICON_GRAPH_FUNNEL, ICON_GRAPH_KPI, ICON_GRAPH_LINE, ICON_GRAPH_MULTIBAR, ICON_GRAPH_MULTICOLUMN, ICON_GRAPH_MULTILINE, ICON_GRAPH_PIE, ICON_GRAPH_SCATTER, ICON_GRAPH_STACKEDAREA, ICON_GRAPH_STACKEDBAR, ICON_GRAPH_STACKEDCOLUMN, ICON_GRAPH_WORDCLOUD, ICON_GRAPH_WORDCLOUD_W, ICON_LIKE, ICON_LINK, ICON_LOADING_CIRCLE, ICON_QUOTES, ICON_REFRESH, ICON_SHARE, ICON_SQLFILE, ICON_SUPPORT, ICON_TABLE, ICON_TWO_PART_CIRCLE, ICON_TWO_PART_CIRCLE_BOLD, ICON_UNLIKE, ICON_VERIFIED } from '../../../Common/newIconSource';
import { Literals } from '../../common/literals';
import IconToggleButton from '../../../Common/iconToggleButton/IconToggleButton';
import ChatContentGraph from './ChatContentGraph';
import ChatContentPreviewTable from './ChatContentPreviewTable';
import { useDispatch, useSelector } from 'react-redux';
import { continueAsk, continueDebug, createFeedback, deleteChat, deleteChatConversation, getConversationChart, getConversationKPI, toggleConversationTrack, updateConversationChartType } from '../../../../store/modules/common/GenAi/GenAiReducer';
import CommonModal from '../../../Common/CommonModal/CommonModal';
import { getConfirmationModal, getDeleteMessage, getJourneyTime, getMonthName, getObjectLength, getQueryParams, getRelativeTime, getReloadErrorTemplate, numberAbbr, pxToRem, remToPx, showOneLineError, showVerticalBarLoader, stringToColor } from '../../common/helperFunctions';
import { Avatar, Button, Collapse, Input, Popover, Radio, Select, Space, Tooltip } from 'antd';
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import SqlEditor from '../../dataCatalogue/preview/components/SqlEditor';
import Highlighter from 'react-highlight-words';
import DeleteModal from '../../../Common/deleteModal/DeleteModal';
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader';
// import likeEmoji from '../../../../assets/images/emoji/Like_anim_gif.gif'
// import unlikeEmoji from '../../../../assets/images/emoji/Dislike_anim_gif.gif'
import likeEmoji from '../../../../assets/images/emoji/like_thumb_anim_gif.gif'
import MarkdownText from '../../../Common/MarkdownText';

const CHAT_STATE = {
    thinking: 'THINKING',
    thought_complete: 'THOUGHT_COMPLETE',
    success: 'SUCCESS',
    failed: 'FAILED',
    clarification: 'CLARIFICATION'
}

const ChatContentBox = ({
    index,
    data,
    reload = () => {},
    usersList = [],
    isTracker = false,
    chartRefMap = {},
    kpiRefMap,
    searchData = '',
    isLandingTile = false,
    showMinimal = true, // This is to toggle Minimal Info view
    deleteFullChat = false,
    fetchingConv = false,
    dataRefMap = {},
    retryQuery = () => {},
    setQuestValue = () => {},
    chatWindowRef = {},
    setData = () => {},
    isFloating = false,
    refreshNotification = () => {},
    setQueryChoices = () => {}
}) => {

    const dispatch = useDispatch()
    // const location = useLocation()
    const history = useNavigate()
    // const {chat: chatIdParam} = getQueryParams(location.search)
    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const [trackLoader, setTrackLoader]       = React.useState(false)
    const [tracking, setTracking]             = React.useState(false)
    const [view, setView]                     = React.useState('')
    const [kpiLoader, setKpiLoader]           = React.useState(false)
    const [chartLoader, setChartLoader]       = React.useState(false)
    const [kpiError, setKpiError]             = React.useState(undefined)
    const [chartError, setChartError]         = React.useState(undefined)
    const [kpiData, setKpiData]               = React.useState({})
    const [chartData, setChartData]           = React.useState({})
    const [expView, setExpView]               = React.useState('exp')
    const [isQueryValid, setIsQueryValid]     = React.useState(true)
    const [deleteModal, setDeleteModal]       = React.useState(false)
    const [isDeleting, setDeleting]           = React.useState(false)
    const [chartType, setChartType]           = React.useState('bar')
    const [isChartReady, setChartReady]       = React.useState(true)
    const [hideGraph, setHideGraph]           = React.useState(false)
    const [reactPopOpen, setReactPopOpen]     = React.useState(false)
    const [reaction, setReaction]             = React.useState('')
    const [sendingFeed, setSendingFeed]       = React.useState(false)
    const [graphData, setGraphData]           = React.useState({})
    const [resultPreview, setResultPreview]   = React.useState({})
    
    const [openUntrackModal, setOpenUntrackModal] = React.useState(false)
    const [feedbackComment, setFeedbackComment]   = React.useState('')
    const [feedbackSent, setFeedbackSent]         = React.useState(undefined)

    const [chatTime, setChatTime]                 = React.useState(getRelativeTime(data?.created_on))
    const [autoLoadingGraph, setAutoLoadingGraph] = React.useState(false)

    const [isVisible, setIsVisible] = React.useState(false)
    const [chartExpanded, setChartExpanded] = React.useState(false)
    const [debugSteps, setDebugStep] = React.useState([])
    const [debugOver, setDebugOver] = React.useState(false)
    const [isDebugging, setIsDebugging] = React.useState(false)
    const [isThinking, setIsThinking] = React.useState(false)
    const [thinkingDone, setThinkingDone] = React.useState(false)
    const [respSteps, setRespSteps] = React.useState([])
    //     {
    //         title: 'Doing Step 1',
    //         body: null
    //         // body: 'This report retrieves the countries in Asia with the highest average earthquake magnitudes. It calculates the average magnitude of earthquakes for each country in Asia and orders the results in descending order of average magnitude.'
    //     }
    // ])

    const containerRef = React.useRef(null)

    const [respInterval, setRespInterval] = React.useState(null)
    const [respIntervalDone, setRespIntervalDone] = React.useState(false)

    const callbackFunc = entries => {
        const [ entry ] = entries
        setIsVisible(entry?.isIntersecting)
    }

    const options = {
        root: null,
        rootMargin: "0px",
        thresold: 1.0
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(callbackFunc, options)
        if(containerRef.current) observer.observe(containerRef.current)

        return () => {

            if(containerRef.current) observer.unobserve(containerRef.current)
        }
    }, [])

    React.useEffect(() => {
        if(
            (
                data?.state?.toLowerCase() === CHAT_STATE.thinking?.toLowerCase()
                || data?.state?.toLowerCase() === CHAT_STATE.thought_complete?.toLowerCase()
            )
           && !thinkingDone
        ) {

            setIsThinking(true)
            if(data?.steps_history?.[0]) {
                setRespSteps(data?.steps_history?.map(s => ({title: s?.step_title, body: null, ...s})))
                /* setTimeout(() => {
                    setRespSteps(
                        [
                            ...data?.steps_history?.map(s => ({title: s?.step_title, body: s?.objective, ...s})),
                            { title: 'Thinking...', body: null }
                        ]
                    )
                    continueThinking()
                }, 1000) */
                continueThinking()
            }
            else {
                continueThinking()
            }
        }
        else {
            setRespSteps(data?.steps_history?.map(s => ({title: s?.step_title, body: s?.analysis_and_insights, ...s})))
            setThinkingDone(true)
        }
    }, [])

    React.useEffect(() => {

        const chatTimeInterval = setInterval(() => {
            setChatTime(getRelativeTime(data?.created_on))
        }, 30000)

            if(data?.state === 'DEBUG' && !debugOver) {
                setIsDebugging(true)
                debugConversation()
            }
            else if(data?.state === 'DEBUG_OVER') {
                setDebugStep(data?.debug_step)
                setDebugOver(true)
            }
            else {
                setDebugStep([])
                setDebugOver(false)
            }

    
            setChartType(data?.selected_chart?.toLowerCase())
            setHideGraph(data?.selected_chart?.toLowerCase() === 'table')

            if(getObjectLength(resultPreview) === 0 && data?.chart_payload?.result) {
                setResultPreview(data?.chart_payload?.result)
            }

            if(data?.selected_chart?.toLowerCase() === 'table') {
                setView('table')
            }
            else if(!(["SUCCESS", "CLARIFICATION"]?.includes(data?.state))) {
                setView('sql')
            }
            else {
                setView('graph')
            }
    
            if(data?.feedback?.feedback_type !== undefined && data?.feedback?.feedback_type !== null) {
                setReaction(data?.feedback?.feedback_type === 1 ? 'like' : 'unlike')
            }
            else {
                setReaction('')
            }
            setFeedbackComment(data?.feedback?.comment ?? '')
    
            if(data) setTracking(data?.tracked === true)
    
            setIsQueryValid(data?.sql_query?.is_query_valid ?? true)
    
            if(data?.sql_query?.is_query_valid === false) { // Prevent KPI and Chart
                return
            }

            if(getObjectLength(chartRefMap?.[data?.id] ?? {}) > 0) {
                setChartData(chartRefMap?.[data?.id] ?? {})
                setGraphData(dataRefMap?.[data?.id] ?? data)

                let res = chartRefMap?.[data?.id]

                setChartType(res?.type?.toLowerCase())
                setHideGraph(res?.type?.toLowerCase() === 'table')
                if(res?.type?.toLowerCase() === 'table') {
                    setView('table')
                }
                else if(!(["SUCCESS", "CLARIFICATION"]?.includes(data?.state))) {
                    setView('sql')
                }
                else setView('graph')

            }
    
            if(data?.error_msg) {
                setChartError(data?.error_msg)
                return
            }
    
            if(
                ![CHAT_STATE?.failed?.toLowerCase(), CHAT_STATE?.thinking?.toLowerCase(), CHAT_STATE?.thought_complete?.toLowerCase()]?.includes(data?.state?.toLowerCase())
                &&
                getObjectLength(chartRefMap?.[data?.id]) === 0 
                && isVisible && !autoLoadingGraph
            ) {
                setAutoLoadingGraph(true)
                fetchConversationChart()
            }

            if(chartRefMap?.[data?.id]) {
                if(chartRefMap?.[data?.id]?.error_msg !== "") {
                    setChartError(chartRefMap?.[data?.id]?.error_msg)
                }
            }


        return () => {
            clearInterval(chatTimeInterval)
        }

    }, [data, isTracker, showMinimal, isVisible])
    
    const setAllStates = (res) => {
        setGraphData(res?.data)
        setChartType(res?.data?.selected_chart?.toLowerCase())
        setChartData({
            ...res?.data?.chart_payload,
            type: res?.data?.selected_chart,
            possible_chart: res?.data?.possible_chart
        })

        if(res?.data?.chart_payload?.result) {
            setResultPreview(res?.data?.chart_payload?.result)
        }

        setChartType(res?.data?.selected_chart?.toLowerCase())
        setHideGraph(res?.data?.selected_chart?.toLowerCase() === 'table')
        if(res?.data?.selected_chart?.toLowerCase() === 'table') {
            setView('table')
        }
        else setView('graph')

        chartRefMap[data?.id] = {
            ...res?.data?.chart_payload,
            type: res?.data?.selected_chart,
            possible_chart: res?.data?.possible_chart,
            error_msg: res?.data?.error_msg
        }
        dataRefMap[data?.id] = res?.data

        if(data?.state === 'DEBUG') {
            debugConversation()
        }

        if(res?.data?.error_msg !== null && res?.data?.error_msg !== undefined) {
            setChartError(res?.data?.error_msg)
        }
    }

    const fetchConversationChart = (dat = data) => {

        if(
            // !isVisible 
            // || 
            // (chartRefMap[data?.id] && chartRefMap[data?.id]?.error_msg !== null) 
            // || 
            (dat?.state !== CHAT_STATE.success && dat?.state !== CHAT_STATE.thought_complete) 
        ) {
            setAutoLoadingGraph(false)
            return
        }

        setChartError(undefined)
        setChartLoader(true)   
        dispatch(getConversationChart(data?.id, false))
        .then(res => {
            setAllStates(res)
            setChartLoader(false)
            setAutoLoadingGraph(false)
        })
        .catch(err => {
        
            setChartError(err?.message)
            setChartLoader(false)
            setAutoLoadingGraph(false)
        })
    }

    const continueThinking = () => {
        dispatch(continueAsk(data?.id, false))
        .then(res => {
            let convState = res?.data?.state
            let steps = res?.data?.steps_history

            let latestStepHistory = []
            
            const arr = steps?.map(s => ({
                title: s?.step_title,
                body: s?.analysis_and_insights,
                ...s
            }))
            latestStepHistory = [...arr]

            let askAgainTimeout = null
            if(
                convState?.toLowerCase() === CHAT_STATE.thinking?.toLowerCase() 
                ||
                convState?.toLowerCase() === CHAT_STATE.thought_complete?.toLowerCase()
            ) {
                askAgainTimeout = setTimeout(() => {
                    setRespSteps(() => {
                        return [
                            ...latestStepHistory,
                        ]
                    })
                    continueThinking()
                }, 2000)
            }
            else {
                if(askAgainTimeout !== null) clearTimeout(askAgainTimeout)

                setAllStates(res)
                setData(prev => prev?.map(c => (c?.id === data?.id ? {...res?.data, state: convState, steps_history: steps} : {...c})))
                setThinkingDone(true)
                setRespSteps([...arr])
                setQueryChoices(res?.data?.response_choices ?? [])
            }

            if(isThinking) {
                setIsThinking(false)
            }

            setTimeout(() => {
                if(document?.querySelector('#respStepWrap-'+data?.id))
                document?.querySelector('#respStepWrap-'+data?.id)?.scrollIntoView()
            }, 500)
        })
        .catch(err => {
            console.error({err})
            setChartError(err?.message)
            setThinkingDone(true)
            if(isThinking) {
                setIsThinking(false)
            }
        })
    }

    const debugConversation = () => {
        dispatch(continueDebug(data?.id, false))
        .then(res => {
            // console.log({res})
            let convState = res?.data?.state
            let steps = res?.data?.debug_step

            setDebugStep([...steps])

            let debugTimeout = null
            if(convState === 'DEBUG') {
                debugTimeout = setTimeout(() => {
                    debugConversation()
                }, 2000)
            }
            else if(debugTimeout !== null){
                clearTimeout(debugTimeout)
                setData(prev => prev?.map(c => (c?.id === data?.id ? {...c, state: 'DEBUG_OVER', debug_step: steps} : {...c})))
                setDebugOver(true)
            }
            else {
                setData(prev => prev?.map(c => (c?.id === data?.id ? {...c, state: 'DEBUG_OVER', debug_step: steps} : {...c})))
                setDebugOver(true)
            }

            if(isDebugging) {
                setIsDebugging(false)
            }

            setTimeout(() => {
                if(document?.querySelector('#debugStepsOf-'+data?.id))
                document?.querySelector('#debugStepsOf-'+data?.id)?.scrollIntoView()
            }, 500)
        })
        .catch(err => {
            console.error({err})
            setDebugOver(true)
            if(isDebugging) {
                setIsDebugging(false)
            }
        })
    }

    const updateChartType = (c_type) => {
        let payload = {
            selected_chart: c_type
        }
        dispatch(updateConversationChartType(data?.id, payload, false))
        .then(() => {
            chartRefMap[data?.id]['type'] = c_type
        })
        .catch(() => {})
    }

    const dynamic = px => remToPx(pxToRem(px))

    const commonHeight = dynamic(isTracker ? showMinimal ? 330 : 260 : 420)

    const ChartLoaderTemplate = <>
        <div className='d-flex flex-wrap align-items-center justify-content-center w-100 align-content-center' style={{height: commonHeight}}>
            {showVerticalBarLoader()}
            <div className='fontInterSemiBold mt-4 color-primary fontSizeHeading pt-2'>Loading Chart...</div>
        </div>
    </>
    // console.log({id: data?.id, view, ref:chartRefMap?.[data?.id], graphData})
    const viewLayout = React.useMemo(() => {
        // if(!isVisible) return <>
        //     {ChartLoaderTemplate}
        // </>
        const showSQL = (() => {
            let show = false
            if(data?.state === "SUCCESS" && (!graphData?.query || graphData?.query?.trim() === '')) {
                if(!data?.query || data?.query?.trim() === "") {
                    show = false
                }
                else {
                    show = true
                }
            }
            else if(data?.query && data?.query !== ""){
                show = true
            }
            return show
        })()
        if(view === '') return <></>
        return <>
        <div className='h-100' style={{display: view === 'graph' ? 'block' : 'none'}}>
                {
                    !isChartReady ? 
                    ChartLoaderTemplate
                    // <ClaristaLoader height='420px' />
                    :
                    <ChatContentGraph 
                        index={index}
                        loading={chartLoader}
                        error={chartError}
                        setError={setChartError}
                        reload={() => fetchConversationChart()}
                        chartData={chartData}
                        isTracker={isTracker}
                        hideTools={isLandingTile}
                        showMinimal={showMinimal}
                        chartType={chartType}
                        chartExpanded={chartExpanded}
                        chatWindowRef={chatWindowRef}
                    />
                }
            </div>
            <div className='h-100' style={{display: view === 'table' ? 'block' : 'none'}}>
                {
                    chartLoader
                        ?
                        ChartLoaderTemplate
                        :
                            (   
                                graphData?.query === undefined
                                || graphData?.query === ""
                            ) 
                            && chartError?.length > 0
                        ?   getReloadErrorTemplate({errorMessage: chartError, hideReloadBtn: true})
                        :   data?.query !== null && data?.state === 'SUCCESS' 
                            ? <ChatContentPreviewTable 
                                data={graphData} 
                                isTracker={isTracker} 
                                chartRefMap={chartRefMap} 
                                show={view === 'table'}
                                initialResult={resultPreview}
                            /> 
                            : ''
                }
            </div>
            <div className='h-100' style={{display: view === 'sql' ? 'block' : 'none'}}>
                {

                    <div className='mt-3' style={{ height: 264 }}>
                        {
                            showSQL
                                ?
                                <SqlEditor
                                    wrapEnabled={true}
                                    QueryValue={
                                        data?.state === "SUCCESS"
                                            ? graphData?.query
                                            : data?.query
                                    }
                                    height={226}
                                    wrapClassName='conv-sql-pane'
                                    // isQueryViewMode={true}
                                    readOnly
                                    configEditorOptions={
                                        {
                                            contextmenu: !isFloating
                                        }
                                    }
                                />
                                :
                                <div className='py-5 text-center text-black-50 d-flex 
                            align-items-center justify-content-center align-content-center flex-wrap'
                                    style={{ minHeight: 240 }} >
                                    <div className='w-100 mb-3'>
                                        <ICON_SQLFILE height='50' width='50' color='#33333370' />
                                    </div>
                                    <div className='w-100' style={{ lineHeight: 1.4 }}>
                                        No Sql Query Found
                                    </div>
                                </div>
                        }
                    </div>
                }

            </div>
            </>
    }, [
        view, 
        chartLoader, 
        chartError, 
        data, 
        chartData, 
        isTracker, 
        showMinimal, 
        chartType,
        isChartReady,
        graphData,
        isVisible,
        chartExpanded,
        resultPreview
    ])

    const handleTrack = (id) => {
        setTrackLoader(true)
        dispatch(toggleConversationTrack(id, !tracking))
        .then(() => {
            setTracking(!tracking)
            setTrackLoader(false)
            if(isTracker) {
                reload()
            }
            else {
                setData(prev => prev?.map(v => ({...v, tracked: v?.id === id ? !tracking : v?.tracked})))
            }
        })
        .catch(err => {
            console.error({err})
            setTrackLoader(false)
        })
    }

    const handleDelete = () => {
        setDeleting(true)
        dispatch(deleteChatConversation(data?.id))
        .then(() => {
            setDeleting(false)
            setDeleteModal(false)
            if(deleteFullChat) {
                dispatch(deleteChat(data?.chat_detail?.id))
                .then(() => {
                    history(Literals?.links?.PULSE_LANDING + '#chats')
                })
                .catch(() => {
                    history(Literals?.links?.PULSE_LANDING + '#chats')
                })
            }
            else {
                setData(prev => prev?.filter(d => d?.id !== data?.id))
                // reload()
            }
        })
        .catch(() => {
            setDeleting(false)
        })
    }

    const readableDate = React.useMemo(() => {
        let date = new Date(data?.created_on)
        return `${date?.getDate()} ${getMonthName.short[date.getMonth()]} ${date?.getFullYear()}`
    }, [data])

    const kpiValueObj = React.useMemo(() => {
        let obj = kpiData?.value?.[0] ?? {}
        let kpi = Object.values(obj)?.[0]
        
        kpi = isNaN(kpi) ? '' : parseFloat(kpi)?.toFixed(2)

        return {
            tooltip: kpi ?? '',
            value: numberAbbr(kpi) ?? '-'
        }

    }, [kpiData])

    const assumptionArray = React.useMemo(() => {
        let obj = data?.sql_query?.assumption
        let arr = []
        for (const key in obj) {
            const element = obj[key];
            arr.push({
                name: key,
                value: element
            })
        }
        return arr
    }, [data])

    const chartsList = React.useMemo(() => {
        let list = [
            {name: 'Bar', type: 'bar', icon: <ICON_GRAPH_BAR/>, active: true, disabled: false},
            {name: 'Line', type: 'line', icon: <ICON_GRAPH_LINE/>, active: false, disabled: false},
            {name: 'Pie', type: 'pie', icon: <ICON_GRAPH_PIE/>, active: false, disabled: false},
            {name: 'Funnel', type: 'funnel', icon: <ICON_GRAPH_FUNNEL/>, active: false, disabled: false},
            {name: 'Column', type: 'column', icon: <ICON_GRAPH_COLUMN/>, active: false, disabled: false},
            {name: 'Area', type: 'area', icon: <ICON_GRAPH_AREA/>, active: false, disabled: false},
            {name: 'Multi Line', type: 'multiline', icon: <ICON_GRAPH_MULTILINE/>, active: false, disabled: false},
            {name: 'Multi Bar', type: 'multibar', icon: <ICON_GRAPH_MULTIBAR/>, active: false, disabled: false},
            {name: 'Multi Column', type: 'multicolumn', icon: <ICON_GRAPH_MULTICOLUMN/>, active: false, disabled: false},
            {name: 'Stacked Column', type: 'stackedcolumn', icon: <ICON_GRAPH_STACKEDCOLUMN/>, active: false, disabled: false},
            {name: 'Stacked Bar', type: 'stackedbar', icon: <ICON_GRAPH_STACKEDBAR/>, active: false, disabled: false},
            {name: 'Stacked Area', type: 'stackedarea', icon: <ICON_GRAPH_STACKEDAREA/>, active: false, disabled: false},
            {name: 'Scatter', type: 'scatter', icon: <ICON_GRAPH_SCATTER/>, active: false, disabled: false},
            {name: 'KPI', type: 'kpi', icon: <ICON_GRAPH_KPI/>, active: false, disabled: false},
            {name: 'Word Cloud', type: 'wordcloud', icon: <ICON_GRAPH_WORDCLOUD/>, active: false, disabled: false},
        ]
        
        list = list?.map(v => (
            {
                ...v, 
                active: chartType === v?.type, 
                disabled: !chartData?.possible_chart?.map(v => v?.toLowerCase())?.includes(v?.type)
            }))
        return list
    }, [chartType, chartData])

    const setReactionInData = (type) => {
        setData(prev => prev?.map(v => ( 
                v?.id === data?.id 
                ? {...v, feedback: {...v?.feedback, feedback_type: type}}
                : {...v}
            )
        ))
    }

    const reactionElement = <>
        {
            reaction === 'like'
            ?
            <span className='feed-reaction' title='Like'>
                <ICON_LIKE height='24' width='24' color='#098925' />
            </span>
            :
            reaction === ""
            ?
            <img 
                onClick={() => {
                    setReaction('like')
                }}
                className={`feed-reaction mr-1 like ${reaction === 'like' || reaction === "" ? 'active' : ''}`} 
                src={likeEmoji} 
                alt='like'
                title='Like'
                />
            :
            <span className='feed-reaction' title='Like'onClick={() => {
                setReaction('like')
            }}>
                <ICON_LIKE height='24' width='24' color='#33333380' />
            </span>
        }
        {
            reaction === 'unlike'
            ?
            <span className='feed-reaction' title='Dislike'>
                <ICON_UNLIKE height='24' width='24' color='#B10F0F' />
            </span>
            :
            reaction === ""
            ?
            <img 
                onClick={() => {
                    setReaction('unlike')
                }}
                className={`feed-reaction unlike ${reaction === 'unlike' || reaction === "" ? 'active' : ''}`} 
                src={likeEmoji} 
                alt='unlike' 
                title='Dislike' 
            />
            :
            <span className='feed-reaction' title='Dislike'onClick={() => {
                setReaction('unlike')
            }}>
                <ICON_UNLIKE height='24' width='24' color='#33333380' />
            </span>
        }
    </>

    const feedPopOver = {
        title: <>
            <div className='p-3 d-flex align-items-center justify-content-between'>
                <div>
                    <ICON_SUPPORT height='20' width='20' />
                    <label className='mb-0 ml-2 fontSizeHeading fontInterSemiBold'>
                        Request support from Data Team
                    </label>
                </div>
                <div>
                    <Buttons
                        props={{
                            buttonText: "",
                            tooltip: "",
                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon`,
                            buttonEvent: () => {
                                handleReactionOnUnchange()
                                setReactPopOpen(false)
                            },
                            ImgSrc: () => <ICON_CLOSE/>,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </div>
            </div>
        </>,
        content: <>
            <div className='p-3' style={{width: 360}}>
                <Input.TextArea
                    rows={10}
                    placeholder='Start writing here...'
                    style={{borderRadius: 3}}
                    className='w-100 custom-input-field h-auto'
                    value={feedbackComment}
                    onChange={(e) => {
                        setFeedbackComment(e?.target?.value)
                    }}
                    disabled={sendingFeed}
                    onKeyDown={(e) => {
                        if(e?.code?.toLowerCase() === 'enter') {
                            e?.preventDefault()
                            if(sendingFeed || feedbackComment?.trim() === '' || reaction === '') {
                                return
                            }
                            createFeedbackComment()
                        }
                    }}
                />
                {
                    reaction === ''
                    ?   <div className='small my-2 d-flex align-items-center'>
                            Please choose your reaction
                            <span className='ml-2 d-flex align-items-center'>
                                {/* {reactionElement} */}
                                <Buttons
                                    props={{
                                        buttonText: "Like",
                                        buttonClassName: `mr-2 custom-btn-outline custom-btn btn-with-text border`,
                                        buttonEvent: () => {
                                            setReaction('like')
                                        },
                                        ImgSrc: () => <></>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                                <Buttons
                                    props={{
                                        buttonText: "Dislike",
                                        buttonClassName: `custom-btn-outline custom-btn btn-with-text border`,
                                        buttonEvent: () => {
                                            setReaction('unlike')
                                        },
                                        ImgSrc: () => <></>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </span>
                        </div>
                    :   ''
                }
                <div style={{height: '2.125rem'}} className='d-flex align-items-center justify-content-end mt-3'>
                    {/* <Buttons
                        props={{
                            buttonText: "Skip",
                            buttonClassName: `mr-2 custom-btn-outline custom-btn btn-with-text border h-100`,
                            buttonEvent: () => {
                                handleReactionOnUnchange()
                                setReactPopOpen(false)
                            },
                            ImgSrc: () => <></>,
                            isDisable: sendingFeed,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    /> */}
                    <Buttons
                        props={{
                            buttonText: "Submit",
                            buttonClassName: `custom-btn-primary custom-btn btn-with-text h-100`,
                            buttonEvent: () => {
                                createFeedbackComment()
                            },
                            ImgSrc: () => sendingFeed ? <ICON_LOADING_CIRCLE/> : <></>,
                            isDisable: sendingFeed || reaction === '',
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </div>
            </div>
        </>
    }

    const createFeedbackComment = () => {
        setSendingFeed(true)
        let payload = {
            conversation: data?.id,
            feedback_type: reaction === 'like' ? 1 : -1,
            comment: feedbackComment
        }
        dispatch(createFeedback(payload))
        .then(() => {
            setSendingFeed(false)
            setReactPopOpen(false)
            setFeedbackSent(reaction)
            setReactionInData(payload?.feedback_type)
            setFeedbackComment(feedbackComment)
            setData(prev => prev?.map(v => (
                v?.id === data?.id
                ?
                {...v, feedback: { ...v?.feedback, comment: feedbackComment, feedback_type: payload?.feedback_type }}
                :
                {...v}
            )))
            refreshNotification()
        })
        .catch(() => {
            setSendingFeed(false)
        })
    }

    const handleReactionOnUnchange = () => {
        if(feedbackSent === undefined) {
            if(data?.feedback?.feedback_type !== undefined && data?.feedback?.feedback_type !== null) {
                setReaction(
                    data?.feedback?.feedback_type === 1 ? 'like' : 'unlike'
                )
            }
            else {
                setReaction('')
            }
        }
        else {
            setReaction(feedbackSent)
        }
    }

    const checkIsEditor = () => {
        const isRoleEditor = !!data?.permission?.find(v => v === 'editor_datadomain')
        if(isRoleEditor) return true
        return undefined
    }

    const explanationPresent = (isQueryValid 
    && data?.sql_query?.explanation !== undefined 
    && data?.sql_query?.explanation !== null 
    && data?.sql_query?.explanation !== '') ?? false

    const failedResponseQuery = data?.state === "FAILED" && (data?.query === "" || !data?.query)

    const hideChatContent = data?.chat_failed 
    || [CHAT_STATE?.thinking?.toLowerCase(), CHAT_STATE?.thought_complete?.toLowerCase()]?.includes(data?.state?.toLowerCase())
    ||  (
            (graphData?.selected_chart === null || data?.selected_chart === null) 
            && (
                (data?.state === 'CLARIFICATION') || failedResponseQuery
            )
        )

    const color_map = {
        0: {
            bg: 'rgba(219, 231, 239, 0.89)',
            border: '#C7DDEC'
        },
        1: {
            bg: '#FFFFFF',
            border: '#d4d4d4'
        },
        2: {
            bg: 'rgba(240, 220, 255, 0.90)',
            border: '#E6D3F5'
        },
        3: {
            bg: '#E7F9FF',
            border: '#D7EEF5'
        },
        4: {
            bg: 'rgba(255, 220, 243, 0.90)',
            border: '#F9D5ED'
        },
        5: {
            bg: 'rgba(226, 156, 91, 0.22)',
            border: '#FDDCBE'
        },
        6: {
            bg: 'rgba(112, 209, 14, 0.23)',
            border: '#B7F578'
        },
        7: {
            bg: 'rgba(102, 180, 206, 0.23)',
            border: '#B6E1EF'
        }
    }

    const state_color_map = {
        SUCCESS: '#333333' ?? '#34A817',
        FAILED: '#A81717',
        DEBUG: '#D9D330',
        DEBUG_OVER: '#D9D330',
        CLARIFICATION: '#2C83C4'
    }
    // console.log({[data?.id]:data, graphData, view})
    return (
        <div
            ref={containerRef} 
            id={`conversation-${data?.id}`}
            className={`${isVisible ? 'cont-vis' : ''} cht-cont-box position-relative mx-auto ${isTracker ? 'tracker-box' : ''} ${isTracker && (index % 2 === 0 ? 'pr-2' : 'pl-2')}`}>
            
            {/* <label className='my-3 text-danger'>{data?.id}</label> */}

            {
                isTracker ? ''
                :
                <div className={`chat-usr-sender-box position-relative mb-3 ${userDetailsRedux?.email === data?.created_by_user?.email || data?.chat_failed ? '' : 'left'}`}>
                    <div className={`chat-user-ask`}>
                        <span className={`chat-tail ${userDetailsRedux?.email === data?.created_by_user?.email || data?.chat_failed ? 'right' : 'left'}`}>
                            <ICON_CHAT_TAIL/>
                        </span>

                        {data?.question}
                        {
                            data?.chat_failed
                            ?
                            <>
                                <span className='chat-retry-btn'>
                                    <Buttons
                                        props={{
                                            buttonText: '',
                                            tooltipPlacement: 'left',
                                            tooltip: 'Retry',
                                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon error-red bg-transparent border-0",
                                            buttonEvent: (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setQuestValue(data?.question)
                                                retryQuery(data?.question)
                                            },
                                            ImgSrc: () => <ICON_REFRESH />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }}
                                    />
                                </span>
                                <span className='chat-failed-text fontInterSemiBold'>
                                    {/* <span className='mr-1'>
                                        <ICON_ALERT width='9' height='9' color='currentColor'/>
                                    </span> */}
                                    {
                                        data?.canceled ? 'Canceled!' : 'Failed!'
                                    }
                                </span>
                            </>
                            :   ''
                        }
                    </div>


                    <span className='chat-user-sender-avt'>
                        <Tooltip 
                            title={
                                data?.chat_failed
                                ?
                                `${userDetailsRedux?.first_name} ${userDetailsRedux?.last_name}`
                                :
                                `${data?.created_by_user?.first_name} ${data?.created_by_user?.last_name}`
                            } 
                            placement="topLeft">
                            <Avatar
                                size={'default'}
                                className='text-uppercase'
                                style={{
                                    backgroundColor: stringToColor(
                                        data?.chat_failed
                                        ?
                                        `${userDetailsRedux?.first_name} ${userDetailsRedux?.last_name}`
                                        :
                                        `${data?.created_by_user?.first_name} ${data?.created_by_user?.last_name}`
                                        ),
                                }}
                            >
                                {
                                    data?.chat_failed
                                    ?
                                    <>
                                        {userDetailsRedux?.first_name?.charAt(0)}
                                        {userDetailsRedux?.last_name?.charAt(0)}
                                    </>
                                    :
                                    <>
                                        {data?.created_by_user?.first_name?.charAt(0)}
                                        {data?.created_by_user?.last_name?.charAt(0)}
                                    </>
                                }
                            </Avatar>
                        </Tooltip>
                    </span>
                </div>
            }

            {
                isTracker || data?.chat_failed ? ''
                :
                <label 
                id={`conversation-${data?.id}-ts`}
                className={`ts-top position-relative`}>
                    

                    {
                        isTracker ? ''
                        :
                        <span className='chat-usr-sysreply'>
                            <ICON_CLARISTA/>
                        </span>
                    }
                    <span className='ts ml-1'>
                        {
                            `${readableDate}, ${getJourneyTime(data?.created_on)} (${chatTime})`
                        }
                    </span>
                </label>
            }
            {
                !isTracker
                ?
                <div className='talk-chat-ai-head'>
                    
                    {
                        isTracker || isFloating ? ''
                        :
                        <span className='chat-hover-action-btn'>
                            <Buttons
                                props={{
                                    buttonText: '',
                                    tooltipPlacement: 'top',
                                    tooltip: 'Delete',
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon error-red bg-transparent border-0",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setDeleteModal(true)
                                    },
                                    ImgSrc: () => <ICON_DELETE />,
                                    isDisable: fetchingConv || !(checkIsEditor() ?? !!data?.permission?.find(d => d === 'delete_datadomain')),
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        </span>
                    }

                    
                    {
                        /**
                         * Loading Response Animation Work To Be Done Here
                         */
                        [CHAT_STATE.clarification?.toLowerCase(), CHAT_STATE.failed?.toLowerCase()]?.includes(data?.state?.toLowerCase())
                        || respSteps?.length === 0
                        ? ''
                        :
                        <div id={`respStepWrap-${data?.id}`} className='pre-resp-anim-wrap mb-2'>
                            <Collapse 
                                key={`catalog-grid-collapse-${data?.id}-${data?.state}`}
                                className='chat-steps-collapse'
                                collapsible={thinkingDone ? 'header' : 'disabled'}
                                ghost={true}
                                defaultActiveKey={[CHAT_STATE?.thinking?.toLowerCase(), CHAT_STATE?.thought_complete?.toLowerCase()]?.includes(data?.state?.toLowerCase()) ? 1 : 0}
                                items={[
                                    {
                                        key: 1,
                                        label: <> { !thinkingDone ? <ICON_TWO_PART_CIRCLE_BOLD className='bx-spin' color='currentColor' /> : '' } { thinkingDone ? "Analysis" : 'Analyzing'}</>,
                                        showArrow: thinkingDone,
                                        children: respSteps?.map((obj, idx) => (
                                            <React.Fragment key={`resp-${idx}`}>
                                                <div id={`respStep-${data?.id}-${idx}`} className='pb-3 pre-resp-wrap'>
                                                    <div className='pre-resp-head mb-2'>
                                                        {/* <span className={`d-flex step-ic ${idx !== respSteps?.length - 1 || thinkingDone ? 'text-success' : ''}`} >
                                                            {
                                                                idx === respSteps?.length - 1 && !thinkingDone
                                                                ?
                                                                <ICON_TWO_PART_CIRCLE_BOLD className='bx-spin' color='currentColor' />
                                                                :
                                                                <ICON_CHECK color='currentColor'/>
                                                            }
                                                        </span> */}
                                                        <span className={`fontInterSemiBold ${idx === respSteps?.length - 1 && !thinkingDone ? 'blink-soft' : ''}`}>
                                                            {obj?.title}
                                                        </span>
                                                    </div>
                                                    <div className='pre-resp-body'>
                                                        {obj?.body}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                ]}
                            />
                        </div>
                    }


                    {
                        data?.chat_failed 
                        || 
                        [CHAT_STATE?.thinking?.toLowerCase(), CHAT_STATE?.thought_complete?.toLowerCase()]?.includes(data?.state?.toLowerCase())
                        ? ''
                        :
                        <>
                            <label className='mb-2 pb-1 fontInterSemiBold fontSizeLargeHeading text-muted talkdata-resp-type' >
                                {
                                    (() => {
                                        let text = {
                                            CLARIFICATION: "Need More Details",
                                            SUCCESS: "Here's Your Answer    ",
                                            FAILED: "Oops, Try Something Else",
                                            DEBUG: "Working On It",
                                            DEBUG_OVER: "Working On It"
                                        }
                                        return text[data?.state] ?? 'Clarista Response'
                                    })()
                                }
                            </label>
                            <div className='talk-chat-ai-quote-reply' style={ state_color_map[data?.state] === undefined ? {} : {borderColor: state_color_map[data?.state]} }>
                                {
                                    data?.state === 'DEBUG' || data?.state === 'DEBUG_OVER' 
                                    ? ''
                                    : //data?.response
                                    <MarkdownText markdown={data?.response ?? ""} />
                                }
                                {
                                    isDebugging
                                    ?
                                    <div className='d-flex align-items-center'>
                                        <span className='mr-2'>
                                            <ICON_LOADING_CIRCLE height='16' width='16' />
                                        </span>
                                        <label className='mb-0'>
                                            Looking into it...
                                        </label>
                                    </div>
                                    :   ''
                                }
                                {
                                    debugSteps?.length > 0
                                    ?
                                    <div id={`debugStepsOf-${data?.id}`} className={`${isDebugging ? 'mt-2' : ''}`}>

                                        {
                                            debugSteps
                                            .map((msg, i) => (
                                                <React.Fragment key={`${data?.id}-progs-${i}`}>
                                                    <div className='fontInterSemiBold position-relative mb-1'>
                                                        <div>{msg?.step}</div>
                                                    </div>
                                                    <div className={`position-relative ${i === debugSteps?.length - 1 && debugOver ? 'text-dark-warning fontInterSemiBold' : 'text-color-grey'}`} style={i !== debugSteps?.length - 1 ? {marginBottom: 8} : {}}>
                                                        <div>{msg?.response}</div>
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        }
                                    </div>
                                    :   ''
                                }
                                {
                                    debugSteps?.length > 0 && !debugOver && !isDebugging
                                    ?
                                    <div className='mt-2 d-flex align-items-center'>
                                        <ICON_LOADING_CIRCLE height='16' width='16'/>
                                        <label className='mb-0 ml-2'>Checking...</label>
                                    </div>
                                    :   ''
                                }
                            </div>
                        </>
                    }

                    {/* {
                        debugSteps?.length > 0
                        ?
                        <div id={`debugStepsOf-${data?.id}`} className={`chat-ai-progress-statements my-2`}>
                            <div>
                            {
                                debugSteps
                                .map((msg, i) => (
                                    <React.Fragment key={`${data?.id}-prog-${i}`}>
                                        <div className='ai-prg-st-lines position-relative'>
                                            <div className={`line-joiner`}>
                                                {
                                                    <i className='bx bxs-circle'></i>
                                                }
                                            </div>
                                            <div>{msg?.step}</div>
                                        </div>
                                        <div className='ai-prg-st-lines position-relative'>
                                            <div className={`line-joiner`}>
                                                {
                                                    <i className='bx bxs-circle'></i>
                                                }
                                            </div>
                                            <div>{msg?.response}</div>
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                            </div>
                        </div>
                        :   ''
                    } */}
                    {/* {
                        !debugOver ? ''
                        :
                        <div className='talk-chat-ai-quote-reply border-0 py-0'>
                            Let's find what you're looking for! Try adding more details.
                        </div>
                    } */}
                </div>
                :   ''
            }
            {
                !isTracker && explanationPresent && graphData?.selected_chart !== null
                ?   
                    <div className='talk-chat-content talk-data mb-2 below-explanation' style={{maxWidth: '70%'}}>
                        <span className='chat-tail left'>
                            <ICON_CHAT_TAIL/>
                        </span>
                        <p 
                            className='mb-0 text-dark' 
                            // className='fontInterSemiBold fontSizeHeading mb-0 text-dark mt-2 p-1 bg-light border rounded' 
                            style={{whiteSpace: 'pre-wrap'}}
                            dangerouslySetInnerHTML={{__html: `${data?.sql_query?.explanation}`}}
                        >
                        </p>
                    </div>
                :   ''
            }
            <div 
                style={{
                    maxWidth: isTracker || chartExpanded ? '100%' : '70%', 
                    marginRight: 'auto', 
                    transition: '.3s',
                }}
                className={`talk-chat-content ${isTracker ? '' : 'talk-data'} 
                    ${chartExpanded ? 'chart-expanded' : ''}
                    ${explanationPresent ? 'round-all' : ''} 
                    ${hideChatContent ? 'd-none' : ''}`}
                // className={`talk-chat-content ${isTracker ? '' : 'border-less with-divider'}`} // 'with-divider': for bottom Divider
                onClick={() => {
                    if(isTracker) {
                        history(Literals.links.PULSE_TRACKER_DETAIL_VIEW + data?.id)
                    }
                }}>
                    {/* <span className='chat-tail left'>
                        <ICON_CHAT_TAIL/>
                    </span> */}
                    {/* {
                        isTracker ? ''
                        :
                        <span className='chat-hover-action-btn'>
                            <Buttons
                                props={{
                                    buttonText: '',
                                    tooltipPlacement: 'top',
                                    tooltip: 'Delete',
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon error-red bg-transparent border-0",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setDeleteModal(true)
                                    },
                                    ImgSrc: () => <ICON_DELETE />,
                                    isDisable: fetchingConv || !(checkIsEditor() ?? !!data?.permission?.find(d => d === 'delete_datadomain')),
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        </span>
                    } */}
                <div className={`talk-chat-cont-head ${isTracker ? 'with-tracker' : ''} ${!isQueryValid ? 'd-none' : ''}`}
                    style={showMinimal ? { gridTemplateColumns: isTracker ? '1fr' : 'auto', marginBottom: isTracker ? 10 : 16 } : {}}
                >


                    {
                        showMinimal ? ''
                        :
                        <div>
                            <div>
                                <h2 className='chat-cont-head'>
                                    <Highlighter searchWords={searchData && searchData.length ? [searchData?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={data?.name} />
                                    {/* {data?.name} */}
                                    {/* <span>
                                        <Buttons
                                            props={{
                                                buttonText: '',
                                                tooltip: 'Edit',
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large",
                                                buttonEvent: (e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                },
                                                ImgSrc: () => <ICON_EDITBOX />,
                                                isDisable: false,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        />
                                    </span> */}
                                </h2>
                            </div>
                        </div>
                    }
                    <div>
                        <div className={`chat-meta-heading-wrap d-flex ${isTracker ? 'justify-content-between align-items-center' : 'justify-content-end'} justify-content-end`} style={{gap: 10}}>
                            
                            {
                                isTracker && view === 'table'
                                ? 
                                <Tooltip placement='topLeft' title={data?.name}>
                                    <div className='fontInterSemiBold color-primary chat-name-title text-with-ellipsis' style={{lineHeight: 1.4}}>
                                    {` ${data?.name}`}
                                    </div>
                                </Tooltip>
                                :   ''
                            }
                            {
                                true || isTracker ? ''
                                :
                                <div className='quest-asked up text-dark'>
                                    <span className='clst-ic'>
                                    <Tooltip 
                                        title={`${data?.updated_by_user?.first_name} ${data?.updated_by_user?.last_name}`} 
                                        placement="topLeft">
                                        <Avatar
                                            size={'default'}
                                            className='text-uppercase'
                                            style={{
                                                backgroundColor: stringToColor(`${data?.updated_by_user?.first_name} ${data?.updated_by_user?.last_name}`),
                                            }}
                                        >
                                            {data?.updated_by_user?.first_name?.charAt(0)}
                                            {data?.updated_by_user?.last_name?.charAt(0)}
                                        </Avatar>
                                    </Tooltip>
                                        {/* <ICON_CLARISTA/> */}
                                    </span>
                                    <span className='fontInterSemiBold text-dark'>{data?.updated_by_user?.first_name}</span> Asked 
                                    <span className='fontInterSemiBold position-relative' style={{fontSize: 14, top: -1}}> | </span>
                                    <span className='fontInterSemiBold color-primary'>
                                    {` ${data?.question}`}
                                    </span>
                                    {
                                        data?.feedback?.is_verified
                                        ?
                                        <Tooltip 
                                            color='#FFF'
                                            title={
                                                <>
                                                    <div className='fontSizeHeading fontInterSemiBold'
                                                        style={{color: '#03A932'}}
                                                    >Verified</div>
                                                    {
                                                        data?.feedback?.verify_comment
                                                        && data?.feedback?.verify_comment !== ""
                                                        ?
                                                        <div className='py-2 px-2 rounded bg-light mt-2' style={{minWidth: 180, maxWidth: 200}}>
                                                            <div className='fontSizeLabel text-black-50 fontInterSemiBold mb-1'>
                                                                Comment
                                                            </div>
                                                            <span className='text-dark fontSizeHeading'>
                                                                {data?.feedback?.verify_comment}
                                                            </span>
                                                        </div>
                                                        :   ''
                                                    }
                                                </>
                                            } 
                                            placement='top'
                                            >
                                            <span className='veri-ic'>
                                                <ICON_VERIFIED color='#03A932' />
                                            </span>
                                        </Tooltip>
                                        :   ''
                                    }
                                </div>
                            }
                            {
                                !isQueryValid || isTracker ? ""
                                :
                                    !showMinimal || data?.state !== "SUCCESS" ? ''
                                    :
                                    <>
                                        <Radio.Group 
                                            className={`icon-toggler shadow-toggle cht-act-tg`}
                                            size='small' 
                                            value={view} 
                                            onChange={(e) => {
                                                const val = e.target.value
                                                setView(val)
                                            }}
                                            disabled={chartLoader}
                                        >
                                            {
                                                [
                                                    {
                                                        value: 'graph', 
                                                        tooltip: 'Graph', 
                                                        icon: (() => {
                                                            let activeChartIcon = chartsList?.find(c => c?.active)?.icon
                                                            let activeChartType = chartsList?.find(c => c?.active)?.type
                                                            if(activeChartIcon) {
                                                                return <span className='graph-type-ic'>
                                                                    {
                                                                        activeChartType === 'wordcloud'
                                                                        ?   <ICON_GRAPH_WORDCLOUD_W/>
                                                                        :   activeChartIcon
                                                                    }
                                                                </span>
                                                            }
                                                            else return <ICON_BAR_CHART/>                                                     
                                                        })()
                                                    },
                                                    {value: 'table', tooltip: 'Preview', icon: <ICON_TABLE/>},
                                                    {value: 'sql', tooltip: 'SQL', icon: <ICON_SQLFILE/>}
                                                ]?.filter(v => hideGraph ? v.value !== 'graph' : v.value !== '')?.map((itm, i) => (
                                                    itm?.value === 'graph'
                                                    ?
                                                    <Popover 
                                                        placement="bottomLeft" 
                                                        title={''} 
                                                        content={
                                                            <>
                                                                <div className='chart-li-popgrid'>
                                                                {
                                                                    chartsList?.map((c, i) => (
                                                                        <div key={`chrt-li-${i}`}
                                                                            onClick={() => {
                                                                                if(c?.disabled) return
                                                                                setChartType(c?.type)
                                                                                updateChartType(c?.type)
                                                                                setChartReady(false)
                                                                                setTimeout(() => setChartReady(true), 500)
                                                                            }}
                                                                            className={`${c?.active ? 'active' : ''} ${c?.disabled ? 'disabled' : ''}`}
                                                                        >
                                                                            <div>{c?.icon}</div>
                                                                            <div className='chrt-li-lb'>{c?.name}</div>
                                                                        </div>
                                                                    ))
                                                                }
                                                                </div>
                                                            </>
                                                        }
                                                        // open={true}
                                                        rootClassName='custom-chart-li-pop'
                                                        destroyTooltipOnHide
                                                        >
                                                        <Radio.Button title={itm.value} key={`${itm}_${i}`} value={itm?.value}>
                                                            {itm?.icon}
                                                            {
                                                                itm?.label 
                                                                ?   <label className={`mb-0 fontSizeLabel ${view === itm?.value ? 'text-white' : ''} cursor-pointer`}
                                                                        onClick={() => {
                                                                            setView(itm?.value)
                                                                        }}
                                                                    >
                                                                        {itm?.label}
                                                                    </label> 
                                                                : ''
                                                            }
                                                        </Radio.Button>
                                                    </Popover>
                                                    :
                                                    <Tooltip title={itm?.tooltip ?? ''} placement={'top'} key={`tt_${itm}_${i}`}>
                                                        <Radio.Button title={itm.value} key={`${itm}_${i}`} value={itm?.value}>
                                                            {itm?.icon}
                                                            {
                                                                itm?.label 
                                                                ?   <label className={`mb-0 fontSizeLabel ${view === itm?.value ? 'text-white' : ''} cursor-pointer`}
                                                                        onClick={() => {
                                                                            setView(itm?.value)
                                                                        }}
                                                                    >
                                                                        {itm?.label}
                                                                    </label> 
                                                                : ''
                                                            }
                                                        </Radio.Button>
                                                    </Tooltip>
                                                ))
                                            }
                                        </Radio.Group>
                                        {/* <IconToggleButton
                                            data={[
                                                {value: 'graph', tooltip: 'Graph', icon: <ICON_BAR_CHART/>},
                                                {value: 'table', tooltip: 'Preview', icon: <ICON_TABLE/>},
                                                {value: 'sql', tooltip: 'SQL', icon: <ICON_SQLFILE/>}
                                            ]}
                                            onChange={(e) => {
                                                const val = e.target.value
                                                setView(val)
                                            }}
                                            value={view}
                                            className='shadow-toggle'
                                        /> */}
                                    </>
                            }

                            {
                                !isQueryValid || data?.state !== "SUCCESS" ? ''
                                :
                                <>
                                    <Buttons
                                        props={{
                                            buttonText: (tracking ? 'Untrack' : 'Track'),
                                            tooltip: '',
                                            buttonWrapperClass: isTracker || data?.feedback?.is_verified ? '' : 'mr-auto',
                                            buttonClassName: `border cht-act-bt
                                                ${tracking ? 'custom-btn-primary' : 'custom-btn-outline bg-white' } 
                                                custom-btn btn-with-text ${isTracker ? '' : 'btn-large'}`,
                                            buttonEvent: (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                if(isLandingTile && tracking) {
                                                    setOpenUntrackModal(true)
                                                    return
                                                }
                                                handleTrack(data?.id)
                                            },
                                            ImgSrc: () => trackLoader ? <ICON_LOADING_CIRCLE/> : <ICON_ADD_TRACK />,
                                            isDisable: trackLoader,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }}
                                    />
                                        {
                                            isTracker ? ''
                                            :
                                            data?.feedback?.is_verified
                                            ?
                                            <Tooltip 
                                                color='#FFF'
                                                title={
                                                    <>
                                                        <div className='fontSizeHeading fontInterSemiBold'
                                                            style={{color: '#03A932'}}
                                                        >Verified</div>
                                                        {
                                                            data?.feedback?.verify_comment
                                                            && data?.feedback?.verify_comment !== ""
                                                            ?
                                                            <div className='py-2 px-2 rounded bg-light mt-2' style={{minWidth: 180, maxWidth: 200}}>
                                                                <div className='fontSizeLabel text-black-50 fontInterSemiBold mb-1'>
                                                                    Comment
                                                                </div>
                                                                <span className='text-dark fontSizeHeading'>
                                                                    {data?.feedback?.verify_comment}
                                                                </span>
                                                            </div>
                                                            :   ''
                                                        }
                                                    </>
                                                } 
                                                placement='top'
                                                >
                                                <span className='veri-ic mr-auto d-flex align-items-center mb-n1'>
                                                    <ICON_VERIFIED color='#03A932' width='24' height='24' />
                                                </span>
                                            </Tooltip>
                                            :   ''
                                        }
                                    {
                                        isTracker ? ''
                                        :
                                        <Buttons
                                            props={{
                                                buttonWrapperClass: 'chat-chart-expand-btn',
                                                buttonText: '',
                                                tooltip: chartExpanded ? 'Shrink' : 'Expand',
                                                buttonClassName: "cht-act-bt custom-btn-outline custom-btn btn-with-icon bg-transparent border-0",
                                                buttonEvent: (e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setChartExpanded(!chartExpanded)
                                                },
                                                ImgSrc: () => chartExpanded ? <ICON_ARROW_LEFT/> : <ICON_ARROW_RIGHT />,
                                                isDisable: false,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        />
                                    }
                                </>
                            }
                            {/* <Buttons
                                props={{
                                    buttonText: '',
                                    tooltip: Literals.ADD_FAVOURITES,
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        // setShareModalOpen(true)
                                    },
                                    ImgSrc: () => <ICON_SHARE />,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            /> */}

                            {/* {
                                isTracker ? ''
                                :
                                <Buttons
                                    props={{
                                        buttonText: '',
                                        tooltip: 'Delete',
                                        buttonClassName: "cht-act-bt custom-btn-outline custom-btn btn-with-icon btn-large error-red",
                                        buttonEvent: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setDeleteModal(true)
                                        },
                                        ImgSrc: () => <ICON_DELETE />,
                                        isDisable: fetchingConv || !(checkIsEditor() ?? !!data?.permission?.find(d => d === 'delete_datadomain')),
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />
                            } */}
                        </div>
                    </div>
                </div>
                <div className={`${!isQueryValid ? 'd-none' : ''}`}>
                    {/* <hr/> */}
                    {
                        data?.sql_query?.assumption ?
                        <div className='assump-tag-wrap' style={assumptionArray?.length === 0 ? {margin: 0} : {}}>
                            {
                                assumptionArray?.map((v, i) => (
                                    <Tooltip 
                                        key={`assump-tag-tt-${data?.id}-${i}`} 
                                        placement='topLeft' title={`${v?.name}: ${v?.value}`}>
                                        <div 
                                        key={`assump-tag-${data?.id}-${i}`} 
                                        className='assump-tag d-block text-with-ellipsis'
                                        style={{
                                            background: color_map[i]?.bg ?? '#d3d3d3', 
                                            border: `solid 1px ${color_map[i]?.border ?? '#d3d3d3'}`,
                                            maxWidth: 350,
                                            width: 'auto'
                                        }}
                                        >
                                            <span className='fontInterSemiBold mr-1'>
                                                {v?.name}:
                                            </span>
                                            {v?.value}
                                        </div>
                                    </Tooltip>
                                ))
                            }
                        </div>
                        : ''
                    }
                </div>
                {
                    !isQueryValid || showMinimal ? ''
                    :
                    <div className='talk-chat-meta mt-2'>
                        <div className='lg-kpi'> 
                            {
                                kpiLoader
                                ?
                                <>
                                    <CustomSkeleton height='40px' width='115px' />
                                    <div className='pt-1'></div>
                                    <CustomSkeleton height='18px' width='115px' />
                                </>
                                :
                                kpiError
                                ?
                                <Tooltip placement='right' title={kpiError}>
                                    {showOneLineError('KPI ERROR')}
                                </Tooltip>
                                :
                                <>
                                    <Tooltip title={kpiValueObj?.tooltip} placement='topLeft'>
                                        <h2 className={`${kpiData?.color ?? 'text-dark'}`}>
                                            {kpiValueObj?.value}
                                        </h2>
                                    </Tooltip>
                                    <p className='fontInterSemiBold fontSizeHeading text-dark mb-0'>{kpiData?.title}</p>
                                </>
                            }

                        </div>
                        <div className='vertical-separator'></div>
                        <div className='w-100 conv-kpi-sec'>
                            {
                                kpiLoader
                                ?
                                <>
                                    <CustomSkeleton height='18px' width='90%' />
                                    <div className='pt-1'></div>
                                    <CustomSkeleton height='18px' width='75%' />
                                    <div className='pt-1'></div>
                                    <CustomSkeleton height='18px' width='45%' />
                                </>
                                :
                                kpiError
                                ?
                                "" // showOneLineError(kpiError)
                                :
                                <p 
                                    className='fontSizeLargeHeading mb-0 text-dark' style={{whiteSpace: 'pre-wrap'}}
                                    dangerouslySetInnerHTML={
                                        {
                                            __html: kpiData?.explanation
                                        }
                                    } 
                                >
                                </p>
                            }
                            

                            

                            {/* <p className='fontInterSemiBold fontSizeHeading text-dark mb-1'>Apr 28 - June 29</p>
                            <p className='fontInterSemiBold fontSizeHeading'>
                                <span className='down'>10% 
                                <i className='bx bx-arrow-back bx-rotate-270 mx-1'></i>
                                </span>
                                <span className='text-dark'>
                                below normal range
                                </span>
                            </p> */}
                        </div>
                    </div>
                }
                {
                    !isQueryValid || (graphData?.selected_chart === null && (graphData?.state === "CLARIFICATION" || data?.state === "CLARIFICATION")) ? ''
                    :
                    <div className='talk-chat-graph'>
                        {
                            isTracker || showMinimal ? ''
                            :
                            <div className='text-right'>
                                <IconToggleButton
                                    data={[
                                        {value: 'graph', tooltip: 'Graph', icon: <ICON_BAR_CHART/>},
                                        {value: 'table', tooltip: 'Preview', icon: <ICON_TABLE/>}
                                    ]}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        setView(val)
                                    }}
                                    value={view}
                                    className='shadow-toggle'
                                />
                            </div>
                        }
                        <div>
                            {viewLayout}
                        </div>
                    </div>
                }
                {
                    isTracker ? ''
                    :
                    <div className='below-explanation'>
                        {
                            !isQueryValid ? ''
                            :
                                showMinimal ? ''
                                :
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div>
                                        <label className='mb-1 text-dark fontSizeLargeHeading fontInterSemiBold'>
                                            {
                                                expView === 'exp' ? 'Explanation' : 'SQL'
                                            }
                                        </label>
                                        <span className='bold-h-line'></span>
                                    </div>
                                    {
                                        isTracker ? ''
                                        :
                                        <div className='text-right'>
                                            <IconToggleButton
                                                data={[
                                                    {value: 'exp', tooltip: 'Explanation', icon: <ICON_FILE/>},
                                                    {value: 'sql', tooltip: 'SQL', icon: <ICON_SQLFILE/>}
                                                ]}
                                                onChange={(e) => {
                                                    const val = e.target.value
                                                    setExpView(val)
                                                }}
                                                value={expView}
                                                className='shadow-toggle'
                                            />
                                        </div>
                                    }
                                </div>
                        }
                        {
                            expView === 'exp'
                            ?
                            data?.sql_query?.explanation?.trim() === '' || !data?.sql_query?.explanation
                            ?
                                showMinimal ? ''
                                :
                                <div className='py-5 text-center text-black-50 d-flex 
                                align-items-center justify-content-center align-content-center flex-wrap' 
                                    style={{minHeight: 240}} >
                                        <div className='w-100 mb-3'>
                                            <ICON_FILE height='50' width='50' color='#33333370' />
                                        </div>
                                        <div className='w-100' style={{lineHeight: 1.4}}>
                                        No Explanation Found
                                        </div>
                                </div>
                            :
                                isQueryValid
                                ?
                                    <>
                                        {/* <p 
                                            className='mb-0 text-dark mt-2' 
                                            // className='fontInterSemiBold fontSizeHeading mb-0 text-dark mt-2 p-1 bg-light border rounded' 
                                            style={{whiteSpace: 'pre-wrap'}}
                                            dangerouslySetInnerHTML={{__html: `${data?.sql_query?.explanation}`}}
                                        >
                                        </p> */}
                                    </>
                                :
                                <span className='d-block fontSizeLargeHeading pb-2 rounded text-dark' 
                                    style={{whiteSpace: 'pre-wrap', lineHeight: '1.5'}}>
                                    {
                                        data?.sql_query?.explanation
                                    }
                                </span>
                            :
                            <div className='mt-3'>
                                {
                                    !data?.query || data?.query?.trim() === ''
                                    ?
                                    <div className='py-5 text-center text-black-50 d-flex 
                            align-items-center justify-content-center align-content-center flex-wrap' 
                                style={{minHeight: 240}} >
                                    <div className='w-100 mb-3'>
                                        <ICON_SQLFILE height='50' width='50' color='#33333370' />
                                    </div>
                                    <div className='w-100' style={{lineHeight: 1.4}}>
                                    No Sql Query Found
                                    </div>
                                </div>
                                :
                                <SqlEditor
                                    wrapEnabled={true}
                                    QueryValue={data?.query}
                                    height={226}
                                    wrapClassName='conv-sql-pane'
                                    // isQueryViewMode={true}
                                    readOnly
                                    configEditorOptions={
                                        {
                                            contextmenu: !isFloating
                                        }
                                    }
                                />
                                }
                            </div>
                        }
                    </div>
                }
                {/* {
                    isTracker ? ''
                    :
                    <div className='quest-asked mt-4 text-dark'>
                        <span className='clst-ic'>
                            <ICON_CLARISTA/>
                        </span>
                        <span className='fontInterSemiBold text-dark'>{data?.updated_by_user?.first_name}</span> Asked 
                        <span className='fontInterSemiBold position-relative' style={{fontSize: 14, top: -1}}> | </span>
                        <span className='fontInterSemiBold color-primary'>
                        {` ${data?.question}`}
                        </span>
                    </div>
                } */}
                {
                    isTracker ? ''
                    :
                    <div className='d-flex align-items-center justify-content-end reaction-el'>
                        {/* <span className='small text-muted mr-auto'>
                            {chatTime}
                        </span> */}
                        <label style={{color: '#333333'}} className='mb-0 fontSizeHeading mr-1'>Did we get it right?</label>
                        <Popover
                            placement={'topRight'}
                            arrow={false}
                            trigger={'click'}
                            title={feedPopOver.title}
                            content={feedPopOver.content}
                            open={reactPopOpen}
                            onOpenChange={(bool) => {
                                if(!bool) {
                                    handleReactionOnUnchange()
                                }
                                setReactPopOpen(bool)
                            }}
                        >
                            {reactionElement}
                        </Popover>
                    </div>
                }
            </div>

            {/* {
                isTracker ? ''
                :
                <div className={`chat-usr-sender-box position-relative mt-3 ${userDetailsRedux?.email === data?.created_by_user?.email ? '' : 'left'}`}>
                    <div className='chat-user-ask'>
                        {data?.question}
                    </div>

                    <span className='chat-user-sender-avt'>
                        <Tooltip 
                            title={`${data?.created_by_user?.first_name} ${data?.created_by_user?.last_name}`} 
                            placement="topLeft">
                            <Avatar
                                size={'default'}
                                className='text-uppercase'
                                style={{
                                    backgroundColor: stringToColor(`${data?.created_by_user?.first_name} ${data?.created_by_user?.last_name}`),
                                }}
                            >
                                {data?.created_by_user?.first_name?.charAt(0)}
                                {data?.created_by_user?.last_name?.charAt(0)}
                            </Avatar>
                        </Tooltip>
                    </span>
                </div>
            } */}

            {
                getConfirmationModal({
                    open: openUntrackModal,
                    setOpen: setOpenUntrackModal,
                    body: 'Do you really want to Untrack?',
                    onConfirm: () => handleTrack(data?.id),
                    confirmationText: 'Yes, Do it'
                })
            }

            <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                body={getDeleteMessage({title: `Conversation`})}
                deleteBtnDisabled={isDeleting}
                heading='Delete Conversation'
                onConfirm={handleDelete}
                loading={isDeleting}
            />

        </div>
    )
}

export default ChatContentBox

const createMarkup = (html)=>{
    return { __html: html }
}