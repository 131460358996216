import React, { useState } from "react";
import StatusTab from "../../common/StatusTab";
import { RolePrivilegeModal } from "../../../common/helperFunctions";
import {
  InfoCircleFilled
} from '@ant-design/icons';
import { Tooltip } from "antd";
const UserList = ({ usersList }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="user-list-container">
      <div className="user-list-header " style={{ display: "flex", alignItems: "center" }}>Group Users  <InfoCircleFilled style={{ color: "#2C83C4" }}
        onClick={() => {
          setOpenModal(true);
        }}
        className="ml-2" />
      </div>
      <div className="user-list-wrapper">
        <ul className="user-list-list">
          {usersList.map((user) => {
            return (
              <li className="user-list-item">
                <div className="user-initials">
                  {user.first_name.charAt(0).toUpperCase()}
                </div>
                <div className="user-personal-details">
                  <div className="user-personal-details-top">
                    <span className="label">{`${user.first_name} ${user.last_name}`}</span>
                    {user.permissions.length ?
                      user.permissions[0] === "leader" && (
                        <StatusTab type="warn">Leader</StatusTab>
                      ) : null}
                  </div>
                  <div className="user-personal-details-bottom">
                    <Tooltip placement="bottomLeft" title={user.email}><div className="caption cursor-pointer">{user.email}</div></Tooltip>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <RolePrivilegeModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default UserList;
