export const SET_CREATEPOD_PROCEEDS = "SET_CREATEPOD_PROCEEDS";
export const TOGGLE_NEW_CONNECTION = "TOGGLE_NEW_CONNECTION";
export const SET_NEWCONN_DATABASELIST = "SET_NEWCONN_DATABASELIST";
export const SET_CONNECTION_NAME = "SET_CONNECTION_NAME";
export const SET_CONNECTION_NAME2 = "SET_CONNECTION_NAME2";
export const SET_CREATEPODTREE_MENUITEMS = "SET_CREATEPODTREE_MENUITEMS";
export const SET_CREATEPOD_TABSARRAY = "SET_CREATEPOD_TABSARRAY";
export const SET_CREATEPOD_CUSTOMTABS = "SET_CREATEPOD_CUSTOMTABS";
export const SET_CREATEPOD_PUBLISHPOD = "SET_CREATEPOD_PUBLISHPOD";
export const SET_FILE_PROGRESS = "SET_FILE_PROGRESS";
export const SET_CREATEPOD_SOURCETYPE = "SET_CREATEPOD_SOURCETYPE";
export const SET_CREATEPOD_TREEDATA = "SET_CREATEPOD_TREEDATA";
export const SET_CREATEPOD_DEFAULT = "SET_CREATEPOD_DEFAULT";
export const SET_CREATEPODFOLDER_SETSELECTEDFILES =
  "SET_CREATEPODFOLDER_SETSELECTEDFILES";
export const SET_PREVIEW_DATA = "SET_PREVIEW_DATA";
export const SET_UPLOAD_FILE_PROGRESS = "SET_UPLOAD_FILE_PROGRESS";
export const SET_ACTIVE_TAB_PUBLISH = "SET_ACTIVE_TAB_PUBLISH";
export const SET_KEY = "SET_KEY";
export const SET_DELIMETER = "SET_DELIMETER";
export const SET_HEADER = "SET_HEADER";
export const SET_CONN_LIST = "SET_CONN_LIST";
export const SET_ROW_SELECTED_INDEX = "SET_ROW_SELECTED_INDEX";
export const SET_CREATEPOD_PAGE_DEFAULT = "SET_CREATEPOD_PAGE_DEFAULT";

export const SET_INITIAL_TREE_DATA = "SET_INITIAL_TREE_DATA";
export const SET_VIRTUAL_TABLES_DATA_MAP = "SET_VIRTUAL_TABLES_DATA_MAP";
export const RESET_VIRTUAL_TABLES_DATA_MAP = "RESET_VIRTUAL_TABLES_DATA_MAP";
export const SET_CREATE_POD_TABLE_DATA_MAP = "SET_CREATE_POD_TABLE_DATA_MAP";
export const SET_ACTIVE_CREATE_POD_NAME = "SET_ACTIVE_CREATE_POD_NAME";
export const SET_GENERATE_QUERY = "SET_GENERATE_QUERY";
export const SET_QUERY = "SET_QUERY";
export const RESET_CREATE_POD_DATA = "RESET_CREATE_POD_DATA";

export const SET_FILE_NEXT_TOKEN = "SET_FILE_NEXT_TOKEN";

export const SET_CREATE_POD_USER_INPUT_DATA_MAP =
  "SET_CREATE_POD_USER_INPUT_DATA_MAP";
export const SET_ACTIVE_POD_DATA_KEY = "SET_ACTIVE_POD_DATA_KEY";

export const SET_INITIAL_EXPLORE_TAB_DATA = "SET_INITIAL_EXPLORE_TAB_DATA";
export const SET_POD_TABS_LIST = "SET_POD_TABS_LIST";
export const SET_PREVIEW_TABLE_DATA = "SET_PREVIEW_TABLE_DATA";
export const SET_TREE_DATA_SET = "SET_TREE_DATA_SET";
export const SET_INITIAL_SCHEMA_DATA = "SET_INITIAL_SCHEMA_DATA";

export const SET_IS_POD_UPDATE_VIEW = "SET_IS_POD_UPDATE_VIEW";
export const SET_POD_UPDATE_LEFT_SIDE_DATA = "SET_POD_UPDATE_LEFT_SIDE_DATA";
export const SET_POD_UPDATE_RIGHT_SIDE_DATA = "SET_POD_UPDATE_RIGHT_SIDE_DATA";
export const SET_POD_UPDATE_TREE_DATA = "SET_POD_UPDATE_TREE_DATA";

export const SET_POD_LEFT_SIDE_DATA = "SET_POD_LEFT_SIDE_DATA";
export const SET_POD_RIGHT_SIDE_DATA = "SET_POD_RIGHT_SIDE_DATA";
export const RESET_POD_LEFT_SIDE_DATA = "RESET_POD_LEFT_SIDE_DATA";
export const RESET_POD_RIGHT_SIDE_DATA = "RESET_POD_RIGHT_SIDE_DATA";
export const SET_UPLOADED_FILE_LIST = "SET_UPLOADED_FILE_LIST";
