import {
  SET_PREVIEW_TABLE_RESULT,
  SET_GRAPH_HEIGHT,
  SET_SELECTED_CHART,
  RESET_ANALYSIS_REDUX_STATE,
  SET_QUERY_RESULT,
  SET_QUERY_EDITOR_QUERY,
  SET_ANALYSIS_PREVIEW_TABLES,
  SET_SELECTED_ANALYSIS_PODS,
  SET_PREVIEW_RESPONSE_QUERY,
  DATA_DOMAIN_LIST,
  FILTER_CHART_LIST,
  SET_GRAPH_COMMON_LAYOUT,
  SET_GRAPH_PREVIEW_REQUEST,
  SET_CHART_RESPONSE_QUERY,
  SET_PUBLISH_BUTTON_CLICK,
  SET_SORT_ORDER_LIST,
  SET_CHART_FILTER_RESULT,
  SET_COLUMNS_LIST,
  SET_FILTER_DATA,
  SET_GRAPH_DATA,
  SET_APPLY_STYLE_CLICK,
  SET_CONTROL_LIST,
  SET_KPI_PARAMETERS,
  SET_DONE_BTN_CLICK,
  CLEAR_SELECTED_ANALYSIS_PODS,
} from "./analytsisType";
import { fetchData } from "../../../../services/apiService";
import "toastr/build/toastr.min.css";
import { setLoadingStatus } from "../../common/loadingActions";

import {
  setDashboardLoadingStatus,
} from "../dashboard/dashboardActions";
import { convertDOMElementToImage } from "../../../../components/modules/common/helperFunctions";
import { emitToastNotification } from "../../../../helpers/toast_helper";

const BASE_URL = window._env_.REACT_APP_API_BASE_URL;
export const setSelectedAnalysisPODList = (list) => {
  return {
    type: SET_SELECTED_ANALYSIS_PODS,
    payload: list,
  };
};
export const resetAnalysisRedux = () => {
  return {
    type: RESET_ANALYSIS_REDUX_STATE,
  };
};

export const setAnalysisPreviewTableS = (data) => {
  return {
    type: SET_ANALYSIS_PREVIEW_TABLES,
    payload: data,
  };
};

export const clearSelectedAnalysisPods = () => {
  return {
    type: CLEAR_SELECTED_ANALYSIS_PODS,
  };
};

export const setAnalysisEditorQuery = (data) => {
  return {
    type: SET_QUERY_EDITOR_QUERY,
    payload: data,
  };
};

export const setResponseQuery = (data) => {
  return {
    type: SET_PREVIEW_RESPONSE_QUERY,
    payload: data.query,
  };
};

export const setQueryResult = (payload) => {
  return {
    type: SET_QUERY_RESULT,
    payload: payload,
  };
};
export const setPreviewTableResult = (payload) => {
  return {
    type: SET_PREVIEW_TABLE_RESULT,
    payload: payload,
  };
};
export const setSelectedChart = (payload) => {
  return {
    type: SET_SELECTED_CHART,
    payload: payload,
  };
};
export const setChartResponseQuery = (payload) => {
  return {
    type: SET_CHART_RESPONSE_QUERY,
    payload: payload,
  };
};
export const applyStyleClick = (payload) => {
  return {
    type: SET_APPLY_STYLE_CLICK,
    payload: payload,
  };
};
export const setGraphHeight = (payload) => {
  return {
    type: SET_GRAPH_HEIGHT,
    payload: payload,
  };
};

export const setGraphCommonLayout = (layout) => {
  return {
    type: SET_GRAPH_COMMON_LAYOUT,
    payload: layout,
  };
};

export const setGraphPreviewRequest = (layout) => {
  return {
    type: SET_GRAPH_PREVIEW_REQUEST,
    payload: layout,
  };
};

export const publishButtonClick = (isClick) => {
  return {
    type: SET_PUBLISH_BUTTON_CLICK,
    payload: isClick,
  };
};

export const setSortOrderList = (list) => {
  return {
    type: SET_SORT_ORDER_LIST,
    payload: list,
  };
};

export const setChartFilterResult = (result) => ({
  type: SET_CHART_FILTER_RESULT,
  payload: result,
});

export const setColumnsList = (list) => ({
  type: SET_COLUMNS_LIST,
  payload: list,
});

export const doneButtonClick = (payload) => ({
  type: SET_DONE_BTN_CLICK,
  payload: payload,
});

export const setFilterData = (data) => ({
  type: SET_FILTER_DATA,
  payload: data,
});

export const setGraphData = (data) => ({
  type: SET_GRAPH_DATA,
  payload: data,
});

export const setControlList = (list) => ({
  type: SET_CONTROL_LIST,
  payload: list,
});

export const getAnalysisPreviewTable = (payload, isSearchPaylaod) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));

    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}preview`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status !== "success") {
            emitToastNotification('error', resp.message);
            reject(resp.message);
          } else {
            if (resp && resp.data) {
              dispatch(setResponseQuery(resp?.data));
            }
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const getAnalysis = () => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}chart/`))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const fetchAnalysisDetails = ({ method, endPoint, payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(method, `${BASE_URL}${endPoint}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp);
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const setDataDomainList = (list) => ({
  type: DATA_DOMAIN_LIST,
  payload: list,
});

export const setFilterChartList = (list) => ({
  type: FILTER_CHART_LIST,
  payload: list,
});

export const setKpiParameters = (data) => ({
  type: SET_KPI_PARAMETERS,
  payload: data,
});

export const getGraphData = (id) => {
  return (dispatch, getState) => {
    dispatch(setDashboardLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}chart/${id}/ `))
        .then((resp) => {
          dispatch(setDashboardLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setDashboardLoadingStatus(false));
        });
    });
  };
};

export const UpdateChart = (id, payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise(async (resolve, reject) => {
      const thumbnail = await convertDOMElementToImage(".graph-index", "exact");
      dispatch(
        fetchData("PATCH", `${BASE_URL}chart/${id}/ `, {
          ...payload,
          thumbnail,
        })
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
            dispatch(publishButtonClick(false));
          } else {
            dispatch(publishButtonClick(false));
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(publishButtonClick(false));
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const chartPreviewApi = (payload) => {
  return (dispatch) => {
    dispatch(setDashboardLoadingStatus(true));
    dispatch(setLoadingStatus(true));
   

    dispatch(setGraphPreviewRequest(payload));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}chart/chart_preview/`, payload))
        .then((resp) => {
          dispatch(setDashboardLoadingStatus(false));
          dispatch(setLoadingStatus(false));

          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            dispatch(setChartResponseQuery(resp.data.query));
            resolve(resp);
          }
        })
        .catch((err) => {
          
          resolve(err);
          emitToastNotification('error', err?.message);
          dispatch(setDashboardLoadingStatus(false));
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const SaveChart = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise(async (resolve, reject) => {
      const thumbnail = await convertDOMElementToImage(".graph-index", "exact");

      dispatch(
        fetchData("POST", `${BASE_URL}chart/ `, { ...payload, thumbnail })
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            dispatch(publishButtonClick(false));
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(publishButtonClick(false));
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const chartFilterValueApi = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}chart/filter_value/ `, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    });
  };
};

export const chartPreviewFilterApi = (payload) => {
  return (dispatch) => {
    
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}chart/chart_preview/ `, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    });
  };
};
