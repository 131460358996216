import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Select, Tooltip } from "antd";
import "./CreatePodConnectionTab.scss";

import {
  CONNECTIONS,
  LEFTGREYARROW,
  RIGHTGREYARROW,
} from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import Buttons from "../../../../Common/button/Buttons";
import MultiSelection from "../../../../Common/dropDown/MultiSelection";

import postGresImage from "../../../../../assets/images/icons/Post.png";
import mySqlImage from "../../../../../assets/images/icons/MySQL.png";
import S3Image from "../../../../../assets/images/icons/s3.png";

const { Option } = Select;

const initialSources = [
  { name: "postgresql", image: postGresImage, isActive: false },
  { name: "mysql", image: mySqlImage, isActive: false },
  { name: "s3", image: S3Image, isActive: false },
];
let initialActiveSource = 0;

const CreatePodConnectionTab = () => {
  const selectedConn = useSelector(
    (state) => state.UserManagement.ConnectionReducer.selectedConn
  );
  const { datadomainList: dataDomainList } = useSelector(
    (state) => state.UserManagement.DataDomain
  );

  const [connectionSources, ] = useState(() => {
    return initialSources.map((source, index) => {
      if (source.name === selectedConn[2]) {
        source.isActive = true;
        initialActiveSource = index;
      }
      return source;
    });
  });


  const [connectionName, ] = useState(selectedConn[0]);

  const [connectionDescription, ] = useState(
    selectedConn[1]
  );
  const [userName, ] = useState(selectedConn[3].username);
  const [password, ] = useState(selectedConn[3].password);
  const [host, ] = useState(selectedConn[3].host);
  const [databaseName, ] = useState(selectedConn[2]);
  const [port, ] = useState(selectedConn[3].port);






 

  return (
    <div className="connection-tab-content">
      <div className="form-group">
        <label className="label">Connection Name</label>
        <div className="connection-tab-carousel">
          <div className="connection-tab-carousel-arrow">
            <LEFTGREYARROW />
          </div>
          <div className="connection-tab-carousel-inner">
            {connectionSources.map((source) => (
              <Tooltip
                key={source.name}
                placement="top"
                title={source.name.toUpperCase()}
              >
                <div
                  id={source.name}
                  onClick={(e) => {}}
                  className={`${source.isActive ? "selected" : ""} mr-3 ml-3`}
                >
                  <img id={source.name} src={source.image} alt={source.name} />
                </div>
              </Tooltip>
            ))}
          </div>
          <div className="connection-tab-carousel-arrow">
            <RIGHTGREYARROW />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="label">Connection Name</label>
        <input
          disabled={true}
          value={connectionName}
          className="custom-input-field"
        ></input>
      </div>
      <div className="form-group">
        <MultiSelection
          props={{
            label: "Data Domain",
            placeholder: "Please select",
            dropDownValue: dataDomainList.map((v) => v.data_domain_id),
            isDisable: true,
          }}
        >
          {dataDomainList &&
            dataDomainList.map((v, i) => (
              <Option key={v.data_domain_id} value={v.data_domain_id}>
                {v?.data_domain_name}
              </Option>
            ))}
        </MultiSelection>
      </div>
      <div className="form-group">
        <label className="label">Connection Description</label>
        <textarea
          value={connectionDescription}
          disabled={true}
          className="custom-textarea-field"
        />
      </div>
      <div className="form-group">
        <label className="label">Username</label>
        <input
          value={userName}
          disabled={true}
          className="custom-input-field"
        ></input>
      </div>
      <div className="form-group">
        <label className="label">Password</label>
        <input
          value={password}
          disabled={true}
          className="custom-input-field"
        ></input>
      </div>
      <div className="form-group">
        <label className="label">Host</label>
        <input
          value={host}
          disabled={true}
          className="custom-input-field"
        ></input>
      </div>
      <div className="form-split-fields">
        <div className="form-group">
          <label className="label">Database Name</label>
          <input
            value={databaseName}
            disabled={true}
            className="custom-input-field"
          ></input>
        </div>
        <div className="form-group">
          <label className="label">Port</label>
          <input
            value={port}
            disabled={true}
            className="custom-input-field"
          ></input>
        </div>
      </div>
      <div className="button-wrapper">
        <Buttons
          props={{
            buttonText: "Test Connection",
            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
            buttonEvent: () => {},
            ImgSrc: () => <CONNECTIONS />,
            isDisable: false,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
      </div>
    </div>
  );
};

export default CreatePodConnectionTab;
