import React from 'react'
import PodCreation from '../PodCreation'

const PodCreationPodOfPod = () => {
  return (
    <React.Fragment>
        <PodCreation isPodOfPod={true} />
    </React.Fragment>
  )
}

export default PodCreationPodOfPod