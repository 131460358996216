// @flow
import { Literals } from "../../components/modules/common/literals";
import {
	CHANGE_LAYOUT,
	CHANGE_LAYOUT_WIDTH,
	CHANGE_SIDEBAR_THEME,
	CHANGE_SIDEBAR_TYPE,
	CHANGE_TOPBAR_THEME,
	TOGGLE_RIGHT_SIDEBAR,
	SHOW_RIGHT_SIDEBAR,
	CHANGE_PRELOADER,
	HIDE_RIGHT_SIDEBAR,
	SET_API_CALL_LOADER,
	SET_SIDEBAR_VISIBILTY,
	TOGGLE_SIDEBAR_COLLAPSE,
	SET_SIDEBAR_COLLAPSE,
	SET_SIDEBAR_ACTIVE_ROUTE
} from "./actionTypes";

const INIT_STATE = {
	layoutType: "horizontal",
	layoutWidth: "fluid",
	leftSideBarTheme: "dark",
	leftSideBarType: "condensed",
	topbarTheme: "light",
	isPreloader: false,
	showRightSidebar: false,
	isMobile: false,
	isShowLoader: false,
	isSidebarVisible: true,
	isSidebarCollapsed: true,
	activeRoute: Literals.links.HOME
};

const Layout = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LAYOUT:
			return {
				...state,
				layoutType: action.payload
			};
		case CHANGE_PRELOADER:
			return {
				...state,
				isPreloader: action.payload
			};

		case CHANGE_LAYOUT_WIDTH:
			return {
				...state,
				layoutWidth: action.payload
			};
		case CHANGE_SIDEBAR_THEME:
			return {
				...state,
				leftSideBarTheme: action.payload
			};
		case CHANGE_SIDEBAR_TYPE:
			return {
				...state,
				/* leftSideBarType: action.payload.sidebarType */
			};
		case CHANGE_TOPBAR_THEME:
			return {
				...state,
				topbarTheme: action.payload
			};
		case TOGGLE_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: !state.showRightSidebar
			};
		case SHOW_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: true
			};
		case HIDE_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: false
			};

		case SET_API_CALL_LOADER:
			return {
				isShowLoader: action.payload
			};

		case SET_SIDEBAR_VISIBILTY: 
			return {
				...state,
				isSidebarVisible: action.payload
			}

		case TOGGLE_SIDEBAR_COLLAPSE: 
			return {
				...state,
				isSidebarCollapsed: !state.isSidebarCollapsed
			}

		case SET_SIDEBAR_COLLAPSE: 
			return {
				...state,
				isSidebarCollapsed: action.payload
			}

		case SET_SIDEBAR_ACTIVE_ROUTE: 
			return {
				...state,
				activeRoute: action.payload
			}
		default:
			return state;
	}
};

export default Layout;
