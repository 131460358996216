import React from 'react'

const CopilotLoader = () => {
  return (
    <div>
        <div class="dot-flashing"></div>
    </div>
  )
}

export default CopilotLoader