import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import LandingpageFirstHeader from "../../common/LandingpageFirstHeader";
import eventBasedIcon from '../../../../assets/images/trigger-event.png';
import { Tooltip } from "antd";
import { useNavigate, useLocation } from 'react-router-dom';
import { getConnectionListV2, setSidebarActiveRoute } from '../../../../store/actions';
import { GetAlertDataApi } from '../../../../store/modules/alert/alertLanding/alertLandingAction';
import { getLineageData } from '../../../../store/modules/lineage/lineageAction';

import { getAnalysis } from '../../../../store/modules/analytics/analysis/analysisAction';
import { getLandingPageData, getLandingPageDataV2 } from '../../../../store/modules/dataCatalogue/landingPage/landingPageActions';
import { fetchFlowsLandingPageList } from '../../../../store/modules/flows/landingPage/landingPageAction';
import {
  getSchedulerLandingPage
} from "../../../../store/modules/scheduler/action";
import LoadingComponent from "../../common/loadingComponent";

import { getDataDomainList } from '../../../../store/modules/UserManagement/dataDomains/dataDomainActions';
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions';
import { getUserGroupList as getUsers } from '../../../../store/modules/UserManagement/users/usersActions';
import Dropdown from '../../../Common/dropDown/SingleSelection';
import Filter from '../../../Common/filter/Filter';
import { ALERT_LINEAGE, CONNECTOR, DATASET, DOMAIN_SMALL, FLOWS, LINEAGE, TABLEICON, LINEAGE_ICON, DASHBOARD, SCHEDULER, CHART, SMALLDATADOMAIN, SMALLFLOWS, SMALLUSERS, USERACTIVE, USERINACTIVE, USERMANAGEMENT, CRAWLER, CLOCK, DASHBOARDMAINICON, BLUE_DASHBOARD, ALERT } from '../../../Common/iconSource';
import Search from '../../../Common/search/LineageSearch';
import { getRelativeTime, ErrorComponent, NoDataComponent } from '../../common/helperFunctions';
import { Literals } from '../../common/literals';
import SourceTypeImage from '../../common/sourceTypeImage';
import Card from './Cards';
import {

  fetchAnalyticsDashboardDetails
} from "../../../../store/modules/analytics/dashboard/dashboardActions";
import { FILTERBLUE, LOGICALICON, SQLBLUE } from "../../../Common/iconSource";
import { LandingSimpleTable } from "../../../Common/landingSimpleTable/LandingSimpleTable";
import { titleCase } from "../../common/helperFunctions";

import Highlighter from "react-highlight-words";
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { ICON_CATALOG, ICON_CATALOG_DOMAIN, ICON_CRAWLER, ICON_DASHBOARD, ICON_EVENT } from '../../../Common/newIconSource';
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader';

const optionsList = [

  { Name: "Alert", value: "Alert", Type: "", key: 1, icon: () => <span className='mr-1'><ALERT width="18" height="18" color="black" /></span> },
  { Name: "Chart", value: "Chart", Type: "", key: 13, icon: () => <span className='mr-1'><CHART width="18" height="18" color="black" /></span> },

  { Name: "Connection", value: "Connection", Type: "", key: 5, icon: () => <span style={{ marginRight: "6px" }}><CONNECTOR width="16" height="16" color="black" /></span> },

  { Name: "Dashboard", value: "Dashboard", Type: "", key: 14, icon: () => <span className='mr-1'><ICON_DASHBOARD width="18" height="18" color="black" /></span> },

  { Name: "Data Domain", value: "Domain", Type: "", key: 6, icon: () => <span style={{ marginRight: "7px" }}><ICON_CATALOG_DOMAIN width="15" height="15" color="black" /></span> },

  { Name: "Flows", value: "Flow", Type: "", key: 7, icon: () => <span style={{ marginRight: "6px" }}><SMALLFLOWS width="18" height="18" color="black" /></span> },

  { Name: "Pod", value: "POD", Type: "", key: 9, icon: () => <span style={{ marginRight: "6px" }}><ICON_CATALOG width="18" height="18" color="black" /></span> },
  { Name: "Scheduler", value: "Scheduler", Type: "", key: 10, icon: () => <span style={{ marginRight: "6px" }}><SCHEDULER width="18" height="18" color="black" /></span> },

  { Name: "Source", value: "Source", Type: "", key: 15, icon: () => <span style={{ marginRight: "6px" }}><TABLEICON width="15" height="15" color="black" /></span> },

  // { Name: "User", value: "User", Type: "", key: 11, icon: () => <span className='mr-1'><SMALLUSERS width="18" height="18" color="black" /></span> },
  // { Name: "Usergroup", value: "Usergroup", Type: "", key: 12, icon: () => <span className='mr-1'><USERMANAGEMENT width="18" height="18" color="black" /></span> },


];


const DataMapping = {};

const LandingPage = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useNavigate();
  const [activeData, setActiveData] = useState([]);
  const [currentData, setCurrentData] = useState([])
  const [dropdownValue, setDropdownValue] = useState("");
  const [alertDataClone,] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [showLoadingMsg, setshowLoadingMsg] = useState(true)
  const [typeLoadingMsg, setTypeLoadingMsg] = useState(false);
  const [errMsg, setErrorMsg] = useState("");

  const [filterText, setFilterText] = useState("")
  const [selectedFilters, setSelectedFilters] = useState([]);
  const DATA_DOMAIN_ID_NAME_MAPPING = useRef({});
  const [filterList,] = useState([
    {
      header: "PRIORITY",
      selectAll: false,
      list: [{ id: 1, checked: false, name: "High", actualValue: "HIGH" }],
      list: [{ id: 2, checked: false, name: "Low", actualValue: "LOW" }],

    },

  ]);
  const { search } = useLocation();

  let query = new URLSearchParams(search);
  let type = query?.get("type")

  const analyticsColumns = [

    {
      title: Literals.ANALYSIS_NAME,
      dataIndex: "analysisName",
      key: "analysisName",

    },
    {
      title: Literals.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      className: "description",
    },
    {
      title: Literals.CHART_TYPE,
      dataIndex: "chartType",
      key: "chartType",

    },
    {
      title: Literals.CREATED_ON,
      dataIndex: "createdOn",
      key: "createdOn",
      className: "date-format",

    },
    {
      title: Literals.CREATED_BY,
      dataIndex: "createdBy",
      key: "createdBy",
      className: "single-line-ellipsis",

    },
    {
      title: Literals.UPDATED_ON,
      dataIndex: "updatedOn",
      key: "updatedOn",
      className: "date-format",

    },
    {
      title: Literals.UPDATED_BY,
      dataIndex: "updatedBy",
      key: "updatedBy",
      className: "single-line-ellipsis",

    }


  ];

  const SchedulerColumns = [
    {
      name: 'Scheduler Name',
      key: "name",
      sortable: true,
      resizable: false
    },
    {
      name: 'Description',
      key: "description",
      sortable: true,
      resizable: false,

    },
    {
      name: 'Last Run On',
      key: "created_on",
      sortable: true,
      resizable: false,
      width: 120
    },
    {
      name: <div >Last Run Status</div>,
      key: "latest_status",
      sortable: false,
      resizable: false
    },
    {
      name: 'Last Updated On',
      key: "updated_on",
      sortable: true,
      resizable: false
    },
    {
      name: <div >Active</div>,
      key: "active",
      sortable: false,
      resizable: false,

    }
  ];
  const ChartColumns = [
    {
      name: 'Chart Name',
      key: "name",
      sortable: true,
      resizable: false
    },
    {
      name: 'Data Source',
      key: "datasource",
      sortable: true,
      resizable: false,

    },
    {
      name: 'Chart Type',
      key: "chart_type",
      sortable: true,
      resizable: false,

    },
    {
      name: "Created On",
      key: "created_on",
      sortable: false,
      resizable: false
    },
    {
      name: "Created By",
      key: "created_by",
      sortable: true,
      resizable: false
    }

  ];
  const DashboardColumns = [
    {
      name: 'Dashboard Name',
      key: "name",
      sortable: true,
      resizable: true,
      width: '15'

    },
    {
      name: 'Chart Details',
      key: "chart_details",
      sortable: true,
      resizable: true,
      width: '25'


    },

    {
      name: "Created By",
      key: "created_by",
      sortable: true,
      resizable: true,
      width: '25'

    }

  ];
  const dashboardColumns = [

    {
      title: Literals.DASHBOARD_NAME,
      dataIndex: "dashboard_name",
      key: "dashboard_name",
      width: '25%'

    },
    {
      title: Literals.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      className: "description",
      width: '35%'

    },
    {
      title: Literals.CREATED_ON,
      dataIndex: "createdOn",
      key: "createdOn",

    },
    {
      title: Literals.CREATED_BY,
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: {
        compare: (a, b) =>
          a?.createdBy?.props?.children !== undefined
            ? a?.createdBy?.props?.children?.localeCompare(
              b?.createdBy?.props?.children
            )
            : null,
      },
    },
    {
      title: Literals.UPDATED_ON,
      dataIndex: "updatedOn",
      key: "updatedOn",
      sorter: {
        compare: (a, b) =>
          a?.updatedOn?.props?.children !== undefined
            ? a?.updatedOn?.props?.children?.localeCompare(
              b?.updatedOn?.props?.children
            )
            : null,
      },
    },
    {
      title: Literals.UPDATED_BY,
      dataIndex: "updatedBy",
      key: "updatedBy",
      sorter: {
        compare: (a, b) =>
          a?.updatedBy?.props?.children !== undefined
            ? a?.updatedBy?.props?.children?.localeCompare(
              b?.updatedBy?.props?.children
            )
            : null,
      },
    }
  ]


  const userColumns = [
    {
      name: Literals.FIRSTNAME,
      dataIndex: "first_name",
      key: "first_name",
      width: "20%",

    },
    {
      name: Literals.LASTNAME,
      dataIndex: "last_name",
      key: "last_name",
      width: "20%",
    },
    {
      name: Literals.EMAIL,
      dataIndex: "email",
      key: "email",
      width: "30%",
    },

    {
      name: Literals.TYPE,
      dataIndex: "designation",
      key: "designation",
      width: "30%",
    }
  ];
  const SourceColumns = [
    // {
    //   name: <div >{Literals.SOURCES}</div>,
    //   key: "connection_type",
    //   dataIndex: "connection_type",
    //   resizable: false,
    //   sortable: false,
    //   width: "20%"

    // },
    {
      name: "Source Name",
      dataIndex: "name",
      key: "name",

    },

    {
      name: "Connection Name",
      dataIndex: "connection_name",
      key: "connection_name",
    },


  ];
  const PodColumns = [
    {
      name: Literals.POD_NAME,
      dataIndex: "table_name",
      key: "table_name",
      sorter: {
        compare: (a, b) => a?.table_name !== undefined ? a.table_name?.localeCompare(b.table_name) : null,
      },
      render: text => <Tooltip placement="topLeft" title={text}>
        <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text?.toUpperCase()}> <span className="single-line-ellipsis">{text?.toUpperCase()}</span></Highlighter>
      </Tooltip>
    },
    {
      name: Literals.DESCRIPTION,
      dataIndex: "pod_description",
      key: "pod_description",
      sortable: false,
      render: text => <Tooltip placement="topLeft" title={text}>
        <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text}> <span className="single-line-ellipsis">{text}</span></Highlighter>
      </Tooltip>
    },
    {
      name: <div style={{ textAlign: "center" }} >{Literals.SOURCES}</div>,
      dataIndex: "source_type",
      key: "source_type",
      sortable: false,
      resizable: false,
      render: text => <Tooltip placement="topLeft" title={text}>
        <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text}> <span className="single-line-ellipsis">{text}</span></Highlighter>
      </Tooltip>
    },
    {
      name: Literals.CREATED_ON,
      dataIndex: "created_on",
      key: "created_on",
      // sorter: {
      //   compare: (a, b) => a?.created_on !== undefined ? a?.created_on?.localeCompare(b?.created_on) : null

      // },
      resizable: false

    },
    {
      name: Literals.CREATED_BY,
      dataIndex: "created_by_user",
      key: "created_by_user",

      resizable: false
    },
    {
      name: Literals.UPDATED_BY,
      dataIndex: "updated_by_user",
      key: "updated_by_user",

      resizable: false
    }
  ];
  const UserGroupColumns = [
    {
      name: Literals.USERGROUPNAME,
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => a?.userGroupName !== undefined ? a?.userGroupName.localeCompare(b?.userGroupName) : null
      }
      ,
    },
    {
      name: Literals.DESCRIPTION,
      dataIndex: "description",
      key: "description",

      sortable: false
    },
    {
      name: Literals.CREATED_ON,
      dataIndex: "created_on",
      key: "created_on",
    },
    {
      name: Literals.CREATED_BY,
      dataIndex: "created_by_user",
      key: "created_by_user",
      className: "single-line-ellipsis",
      render: data => <a>{data}</a>

    }
  ];
  let FlowColumns = [
    {
      name: "Flows Name",
      dataIndex: "name",

      resizable: true,
      key: "name",
      sorter: {
        compare: (a, b) => a?.flow_name !== undefined ? a?.flow_name?.localeCompare(b?.flow_name) : null,
      },

    },
    {
      name: Literals.DESCRIPTION,
      dataIndex: "description",
      key: "description",

      resizable: true,
      className: "description",
      render: text => <Tooltip placement="topLeft" title={text}>
        <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text}> <span className="single-line-ellipsis">{text}</span></Highlighter>
      </Tooltip>,
      sortable: false

    },

    {
      name: Literals.CREATED_ON,
      dataIndex: "created_on",
      key: "created_on",
      resizable: false,

      sorter: {
        compare: (a, b) => a?.created_on !== undefined ? a?.created_on.localeCompare(b?.created_on) : null

      },
    },
    {
      name: Literals.CREATED_BY,
      dataIndex: "created_by_user",
      key: "created_by",
      resizable: false,
      render: data => <div>{data?.username}</div>,

    },

    {
      name: Literals.UPDATED_BY,
      dataIndex: 'updated_by_user',
      key: "updated_by",
      sorter: {
        compare: (a, b) => a?.updated_by?.props?.title !== undefined ? a?.updated_by?.props?.title?.localeCompare(b?.updated_by?.props?.title) : null

      },
      render: data => <div>{data?.username}</div>,

    }
  ];
  const DataDomainColumns = [
    {
      name: Literals.DATA_DOMAIN,
      dataIndex: "data_domain_name",
      key: "data_domain_name",
      sorter: {
        compare: (a, b) => a?.data_domain_name !== undefined ? a?.data_domain_name.localeCompare(b?.data_domain_name) : null
      }
      ,
      render: text => <Tooltip placement="topLeft" title={text}>
        <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text?.toUpperCase()}> <span className="single-line-ellipsis">{text?.toUpperCase() ?? ''}</span></Highlighter>
      </Tooltip>
    },
    {
      name: Literals.DESCRIPTION,
      dataIndex: "data_domain_description",
      sortable: false,
      key: "data_domain_description",
      render: text => <Tooltip placement="topLeft" title={text}>
        <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text}> <span className="single-line-ellipsis">{text}</span></Highlighter>
      </Tooltip>
    },
    {
      name: Literals.CREATED_ON,
      dataIndex: "created_on",
      key: "created_on",
    },
    {
      name: Literals.CREATED_BY,
      dataIndex: "created_by_user",
      key: "created_by_user",
      className: "single-line-ellipsis",


    },
    {
      name: Literals.UPDATED_ON,
      dataIndex: "updated_on",
      key: "updated_on",
    },
    {
      name: Literals.UPDATED_BY,
      dataIndex: "updated_by_user",
      key: "updated_by_user",
      className: "single-line-ellipsis",

    }
  ];

  const connectionColumns = [
    // {
    //   name: <div >{Literals.SOURCES}</div>,
    //   key: "source_type",
    //   dataIndex: "source_type",
    //   resizable: false,
    //   sortable: false,
    //   width: "5%"

    // },
    {
      name: Literals.CONNECTION_NAME,
      key: "connection_name",
      dataIndex: "connection_name"

    },
    {
      name: 'Domain Name',
      dataIndex: "data_domain",
      key: "data_domain",
      width: "20%",

    },

    {
      name: Literals.DESCRIPTION,
      key: "description",
      dataIndex: "description",
      width: 350,
      resizable: true,
      sortable: false,


    },

    {
      name: Literals.CREATED_ON,
      key: "created_on",
      dataIndex: "created_on",
      resizable: false,


    },
    {
      name: Literals.CREATED_BY,
      key: "created_by_user",
      dataIndex: "created_by_user",
      resizable: false,



    },

  ];
  const AlertColumns = [


    {
      name: 'Alert Name',
      dataIndex: "name",
      key: "name",
      width: "15%",

      render: text => <Tooltip placement="topLeft" title={text}>
        <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text}> <span className="single-line-ellipsis">{text}</span></Highlighter>
      </Tooltip>
    },
    {
      name: 'Description',
      dataIndex: "description",
      key: "description",
      width: "25%",

      render: text => <Tooltip placement="topLeft" title={text}>
        <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text}> <span className="single-line-ellipsis">{text}</span></Highlighter>
      </Tooltip>
    },
    {
      name: 'Domain Name',
      dataIndex: "data_domain",
      key: "data_domain",
      width: "20%",

    },
    {
      name: 'Priority',
      dataIndex: "priority",
      key: "priority",
      width: "20%",

    },
    {
      name: 'Purpose',
      dataIndex: "purpose",
      key: "purpose",
      width: "20%",

      render: text => <span className={text?.toLowerCase() === 'quality' ? 'pl-0 quality' : 'pl-0 business'}><Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? titleCase(text) + ' Alert' : ''}> </Highlighter> </span>



    },

    {
      name: 'Created On',
      dataIndex: "created_on",
      key: "created_on",
      width: "13%",
      render: text => <><Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={getRelativeTime(new Date(text)) ? getRelativeTime(new Date(text)) : ''}>{getRelativeTime(new Date(text))}</Highlighter></>,

    }

  ]
  const [activeColumns, setActiveColumns] = useState(AlertColumns ?? []);

  const ColumnMapping = {
    Alert: AlertColumns,
    Domain: DataDomainColumns,
    Usergroup: UserGroupColumns,
    Flow: FlowColumns,
    User: userColumns,
    Dashboard: dashboardColumns,
    Analysis: analyticsColumns,
    POD: PodColumns,
    Connection: connectionColumns,
    Scheduler: SchedulerColumns,
    Chart: ChartColumns,
    Source: SourceColumns,
    Dashboard: DashboardColumns


  }

  useEffect(() => {

    async function fetchData() {
      try {
        // let dataPresent = DataMapping[dropdownValue];
        // if (dataPresent) {

        // let updated_data = [];
        // let data = currentData ?? dataPresent;
        // let temp = null // data.filter(item => JSON.stringify(Object.keys(item).map(attr => item[attr]?.key ?? "")).toLowerCase().includes(filterText.toLowerCase()));
        // if (dropdownValue.toLowerCase() === "alert") {
        //   updated_data = await GetData(temp);
        // }
        // else if (dropdownValue.toLocaleLowerCase() === "flow") {
        //   updated_data = await getFlowList(temp);
        // }
        // else if (dropdownValue.toLocaleLowerCase() === "user") {
        //   updated_data = await getUserListData(temp);
        // }
        // else if (dropdownValue.toLocaleLowerCase() === "usergroup") {
        //   updated_data = await getUserGroupListData(temp);
        // }
        // else if (dropdownValue.toLocaleLowerCase() === "domain") {
        //   updated_data = await getDataDomainListData(temp);
        // }
        // else if (dropdownValue.toLocaleLowerCase() === "pod") {
        //   updated_data = DataMapping["POD"]
        // }
        // else if (dropdownValue.toLocaleLowerCase() == "connection") {
        //   updated_data = await getConnectionData(temp);
        // }
        // else if (dropdownValue.toLowerCase() === "scheduler") {
        //   // updated_data = DataMapping["Scheduler"]
        //   updated_data = await getSchedularList(temp);

        // }
        // else if (dropdownValue.toLowerCase() === "chart") {
        //   // updated_data = DataMapping["Chart"]
        //   updated_data = await getChartData(temp);

        // }
        // else if (dropdownValue.toLowerCase() === "dashboard") {
        //   // updated_data = DataMapping["Dashboard"]
        //   updated_data = await getDashBoardData(temp);

        // }
        // else if (dropdownValue.toLowerCase() === "source") {
        //   // updated_data = DataMapping["Source"]
        //   updated_data = await getSourceData(temp);
        // }

        // console.log(updated_data)
        // if (updated_data?.length > 0) {
        //   setActiveData(updated_data)
        //   setEmpty(false)
        //   setActiveColumns(ColumnMapping[dropdownValue ?? "Alert"])
        // } else {


        //   setEmpty(true);

        // }

        // }
        // else {
        // alert('else', dataPresent)
        let data = [];
        setActiveData([])

        if (dropdownValue.toLowerCase() === "alert") {
          await GetData();
        } else if (dropdownValue.toLowerCase() == "flow") {
          await getFlowList();

        }
        else if (dropdownValue.toLocaleLowerCase() === "user") {
          await getUserListData();
        }
        else if (dropdownValue.toLocaleLowerCase() === "usergroup") {
          await getUserGroupListData();
        }
        else if (dropdownValue.toLocaleLowerCase() === "domain") {
          await getDataDomainListData();
        }
        else if (dropdownValue.toLocaleLowerCase() === "pod") {
          await getPodTableData();
        }
        else if (dropdownValue.toLocaleLowerCase() == "connection") {
          await getConnectionData();
        }
        else if (dropdownValue.toLowerCase() === "scheduler") {
          // DataMapping["Scheduler"]
          await getSchedularList();

        }
        else if (dropdownValue.toLowerCase() === "chart") {
          // DataMapping["Chart"]
          await getChartData();

        }
        else if (dropdownValue.toLowerCase() === "dashboard") {
          // DataMapping["Dashboard"]
          await getDashBoardData();

        }
        else if (dropdownValue.toLowerCase() === "source") {
          // DataMapping["Source"]
          await getSourceData();
        }

        data = DataMapping[dropdownValue];


        if (data?.length > 0) {
          setActiveData(data)
          setEmpty(false)
          setActiveColumns(ColumnMapping[dropdownValue ?? "Alert"])
        } else {
          setEmpty(true);

        }

        // }
      }
      catch (err) {
        console.log({ err })
        emitToastNotification('error', err.message)
      }
    }
    fetchData();


  }, [dropdownValue])



  useEffect(() => {

    async function fetchData() {
      try {
        let dataPresent = DataMapping[dropdownValue];
        if (dataPresent) {

          let updated_data = [];
          let data = currentData ?? dataPresent;
          let temp = data.filter(item => JSON.stringify(Object.keys(item).map(attr => item[attr]?.key ?? "")).toLowerCase().includes(filterText.toLowerCase()));
          if (dropdownValue.toLowerCase() === "alert") {
            updated_data = await GetData(temp);
          }
          else if (dropdownValue.toLocaleLowerCase() === "flow") {

            updated_data = await getFlowList(temp);
          }
          else if (dropdownValue.toLocaleLowerCase() === "user") {
            updated_data = await getUserListData(temp);
          }
          else if (dropdownValue.toLocaleLowerCase() === "usergroup") {
            updated_data = await getUserGroupListData(temp);
          }
          else if (dropdownValue.toLocaleLowerCase() === "domain") {
            updated_data = await getDataDomainListData(temp);
          }
          else if (dropdownValue.toLocaleLowerCase() === "pod") {
            updated_data = DataMapping["POD"]
          }
          else if (dropdownValue.toLocaleLowerCase() == "connection") {
            updated_data = await getConnectionData(temp);
          }
          else if (dropdownValue.toLowerCase() === "scheduler") {
            // updated_data = DataMapping["Scheduler"]
            updated_data = await getSchedularList(temp);

          }
          else if (dropdownValue.toLowerCase() === "chart") {
            // updated_data = DataMapping["Chart"]
            updated_data = await getChartData(temp);

          }
          else if (dropdownValue.toLowerCase() === "dashboard") {
            // updated_data = DataMapping["Dashboard"]
            updated_data = await getDashBoardData(temp);

          }
          else if (dropdownValue.toLowerCase() === "source") {
            // updated_data = DataMapping["Source"]
            updated_data = await getSourceData(temp);
          }

          console.log(updated_data)
          if (updated_data?.length > 0) {
            setActiveData(updated_data)
            setEmpty(false)
            setActiveColumns(ColumnMapping[dropdownValue ?? "Alert"])
          } else {


            setEmpty(true);

          }

        }

      }
      catch (err) {
        console.log({ err })
        emitToastNotification('error', err.message)
      }
    }
    if (filterText?.length) {

      fetchData();
    }


  }, [filterText?.length])

  useEffect(() => {
    dispatch(setSidebarActiveRoute('/lineage'))
    console.log(type, optionsList[0].value)
    setDropdownValue(type ?? optionsList[0].value)



  }, [])
  useEffect(() => {
    const BaseUrl = window._env_.REACT_APP_VISUALIZATION_BASE_URL;


    const link = `${BaseUrl}?token=${store.getState().UserReducer?.user?.token}`



  }, [])

  // useEffect(() => {


  //   (async () => {
  //     setTypeLoadingMsg(true);
  //     switch (type) {

  //       case "Domain":
  //         await getDataDomainListData();

  //       case "Chart":
  //         await getChartData();
  //       case "Connection":
  //         await getConnectionData();
  //       case "Dashboard":
  //         await getDashBoardData();
  //       case "Flow":
  //         await getFlowList();

  //       case "Scheduler":
  //         await getSchedularList();

  //       case "Source":
  //         await getSourceData();
  //       default:
  //         await GetData();

  //         await getPodTableData();


  //         setTypeLoadingMsg(false);






  //     }

  //   })();



  // }, [])

  const getSourceData = async (data) => {
    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);

      await dispatch(getLineageData(1, "source_list")).then((res) => {
        temp = res.data;
        setshowLoadingMsg(false);

      })
        .catch(err => {
          handleErrorMessage(err.message)
        })
    }
    else {
      temp = [...data];
    }

    let formattedData = [];

    if (temp) {
      formattedData = temp.map(item => {
        console.log(item)
        return {
          allData: item,
          key: !item.key ? item.connection_type == "pod of pods" ? item.id : item.name : item.key,
          name: <div key={item.name?.key ?? item.name} className='d-flex align-items-center'>
            <span className="mr-1" key={item.connection_type ?? item.allData?.connection_type}><SourceTypeImage dataName={item.connection_type ?? item.allData?.connection_type} /></span>
            <span key={item.name?.key ?? item.name}><Tooltip placement="topLeft" title={item.name?.key ?? item.name}>
              <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.name?.key ?? item.name}> <span className="single-line-ellipsis">{item.name?.key ?? item.name}</span></Highlighter>
            </Tooltip></span></div>,

          connection_name:
            <span key={item.connection_name?.key ?? item.connection_name}><Tooltip placement="topLeft" title={item.connection_name?.key?.toUpperCase() ?? item.connection_name?.toUpperCase()}>
              <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.connection_name?.key?.toUpperCase() ?? item.connection_name?.toUpperCase()}> <span className="single-line-ellipsis">{item.connection_name?.key?.toUpperCase() ?? item.connection_name?.toUpperCase()}</span></Highlighter>
            </Tooltip></span>
          ,

          // connection_type:,

        }

      })
      if (data) {
        return formattedData
      }
      else {
        DataMapping["Source"] = formattedData;
        if (type == "Source")
          setCurrentData(DataMapping["Source"]);
      }


    }
  }

  const getDashBoardData = async (data) => {
    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);

      await dispatch(getLineageData(1, "dashboard_list")).then((res) => {
        temp = res.data;
        setshowLoadingMsg(false);

        // temp=temp.filter(item=>item.pod);
      })
        .catch(err => {
          handleErrorMessage(err.message)
        })
    }
    else {
      temp = data;
    }

    let formattedData = [];

    if (temp) {
      formattedData = temp.map(item => {


        let chart_details = item.chart_details.length ? item.chart_details.map((chart) => chart?.name) : [];

        return {
          allData: item,
          key: item.key ?? item.id,
          name: <span key={item.name?.key ?? item.name}><label className='pr-1 mb-0'> <span className="primary-svg-color">  <ICON_DASHBOARD height='16' width='16' /></span></label><Tooltip placement="topLeft" title={item.name?.key ?? item.name}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.name?.key ?? item.name}> <span className="single-line-ellipsis">{item.name?.key ?? item.name}</span></Highlighter>
          </Tooltip></span>,

          chart_details: <span key={item.chart_details?.key ?? chart_details?.join(", ")}><Tooltip placement="topLeft" title={item.chart_details?.key ?? chart_details?.join(", ")}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.chart_details?.key ?? chart_details?.join(", ")}> <span className="single-line-ellipsis">{item.chart_details?.key ?? chart_details?.join(", ")}</span></Highlighter>
          </Tooltip></span>,

          created_by: <span key={item.created_by?.key ?? item.created_by}><Tooltip placement="topLeft" title={item.created_by?.key ?? item.created_by}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.created_by?.key ?? item.created_by}> <span className="single-line-ellipsis">{item.created_by?.key ?? item.created_by}</span></Highlighter>
          </Tooltip></span>,
        }

      })
      if (data) {
        return formattedData
      }
      else {
        DataMapping["Dashboard"] = formattedData;
        if (type == "Dashboard")
          setCurrentData(DataMapping["Dashboard"]);
      }


    }
  }
  const getChartData = async (data) => {
    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);

      await dispatch(getLineageData(1, "chart_list")).then((res) => {
        temp = res.data;
        temp = temp.filter(item => item.pod);
        setshowLoadingMsg(false);
      })
        .catch(err => {
          handleErrorMessage(err.message)
        })
    }
    else {
      temp = data;
    }

    let formattedData = [];

    if (temp) {
      formattedData = temp.map(item => {



        return {
          allData: item,
          key: item.key ?? item.id,
          name: <span key={item.name?.key ?? item.name}><label className='pr-1 mb-0'><CHART width='16' height='16' color='#2c83c4' /></label><Tooltip placement="topLeft" title={item.name?.key ?? item.name}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.name?.key ?? item.name}> <span className="single-line-ellipsis">{item.name?.key ?? item.name}</span></Highlighter>
          </Tooltip></span>,
          datasource: <span key={item.datasource?.key ?? item.datasource}><Tooltip placement="topLeft" title={item.datasource?.key ?? item.datasource}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.datasource?.key ?? item.datasource}> <span className="single-line-ellipsis">{item.datasource?.key ?? item.datasource}</span></Highlighter>
          </Tooltip></span>,
          chart_type: <span key={item.chart_type?.key ?? item.chart_type}><Tooltip placement="topLeft" title={item.chart_type?.key ?? item.chart_type}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.chart_type?.key ?? item.chart_type}> <span className="single-line-ellipsis">{item.chart_type?.key ?? item.chart_type}</span></Highlighter>
          </Tooltip></span>,
          created_on: <span key={item.created_on?.key ?? getRelativeTime(item.created_on)}><Tooltip placement="topLeft" title={item.created_on?.key ?? getRelativeTime(item.created_on)}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.created_on?.key ?? getRelativeTime(item.created_on)}> <span className="single-line-ellipsis">{item.created_on?.key ?? item.created_on}</span></Highlighter>
          </Tooltip></span>,
          created_by: <span key={item.created_by?.key ?? item.created_by}><Tooltip placement="topLeft" title={item.created_by?.key ?? item.created_by}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.created_by?.key ?? item.created_by}> <span className="single-line-ellipsis">{item.created_by?.key ?? item.created_by}</span></Highlighter>
          </Tooltip></span>,
        }

      })
      if (data) {
        return formattedData
      }
      else {
        if (dropdownValue.toLowerCase() === 'chart') {

          DataMapping["Chart"] = formattedData;
        }
        if (type == "Chart")
          setCurrentData(DataMapping["Chart"]);
      }


    }
  }
  const getConnectionData = async (data) => {
    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);

      await dispatch(getConnectionListV2()).then((res) => {
        temp = res.data;
        setshowLoadingMsg(false);
      })
        .catch(err => {
          handleErrorMessage(err.message)
        })
    }
    else {
      temp = data;
    }
    let formattedData = [];
    if (temp) {
      console.log(temp)
      formattedData = temp.map(row => {
        let domainName = []
        if (!data) {

          domainName = row.data_domain.length ? [...new Set(row.data_domain.map((item, i) => item?.data_domain_name?.toUpperCase()))] : [];
        }

        return {
          allData: row,
          key: row.key ?? row.connection_name,
          connection_name: <div className='d-flex align-items-center' key={row.connection_name?.key ?? row.connection_name}><span key={row.source_type?.key ?? row.source_type}><SourceTypeImage dataName={row.source_type?.key ?? row.source_type} /></span>
            <span className='flexible-search-wrap ml-2'><Tooltip placement="topLeft" title={row.connection_name?.key?.toUpperCase() ?? row.connection_name?.toUpperCase()}><Highlighter searchWords={[filterText.toLocaleLowerCase()]} autoEscape={false} textToHighlight={row.connection_name?.key?.toUpperCase() ?? row.connection_name?.toUpperCase()} /></Tooltip></span>
          </div>,
          description: <span key={row.description?.key ?? row.connection_description} className='flexible-search-wrap'><Tooltip placement="topLeft" title={row.description?.key ?? row.connection_description}><Highlighter searchWords={[filterText.toLocaleLowerCase()]} autoEscape={false} textToHighlight={row.description?.key ?? row.connection_description} /></Tooltip></span>,
          data_domain:
            <span key={row.data_domain?.key ?? domainName.join(', ')}><Tooltip placement="topLeft" title={row.data_domain?.key ?? domainName.join(', ')}>
              <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={row.data_domain?.key ?? domainName.join(', ')}> <span className="single-line-ellipsis">{row.data_domain?.key ?? domainName.join(', ')}</span></Highlighter>
            </Tooltip></span>,
          updated_by_user: <span key={row.updated_by?.key ?? row.updated_by_user.email}> <Tooltip placement="topLeft" title={row.updated_by?.key ?? row.updated_by_user.email}>
            {row.updated_by?.key ?? row.updated_by_user.email}
          </Tooltip></span>,
          source_type: <span key={row.source_type?.key ?? row.source_type}><SourceTypeImage dataName={row.source_type?.key ?? row.source_type} /></span>,
          created_on: <span key={row.created_on?.key ?? row.created_on}><Tooltip placement="topLeft" title={getRelativeTime(row.created_on?.key ?? row.created_on)}>
            {getRelativeTime(row.created_on?.key ?? row.created_on)}
          </Tooltip></span>,
          created_by_user: <span key={row.created_by_user?.key ?? row.created_by_user.email} className='flexible-search-wrap'><Tooltip placement="topLeft" title={row.created_by_user?.key ?? row.created_by_user.email}><Highlighter searchWords={[filterText.toLocaleLowerCase()]} autoEscape={false} textToHighlight={row.created_by_user?.key ?? row.created_by_user.email} /></Tooltip></span>,

        }
      })
    }



    if (data) {
      return formattedData;

    } else {





      DataMapping["Connection"] = formattedData;
      if (type == "Connection")
        setCurrentData(DataMapping["Connection"]);






    }
  }

  const onRowClick = (record) => {
    let connectionName = record?.connection_name?.key ?? null;
    let path = "";
    if (record.connection_type?.key == "pod of pods") {

      path = `/viewLineage/POD/${record.key}`

    }
    else {
      let key = dropdownValue == "Source" ? record?.key?.replaceAll("/", "__") : record.key;

      path = `/viewLineage/${dropdownValue === "Usergroup" ? "group" :
        dropdownValue}/${key}?connection_name=${connectionName}`;
    }


    history(path);





  }

  const getUserListData = async (data) => {
    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);

      await dispatch(getUsers({ method: "GET", endPoint: "group_manager/user" })).then((res) => {
        temp = res.data;
        setshowLoadingMsg(false);
      })
        .catch(err => {
          handleErrorMessage(err.message)
        })
    }
    else {
      temp = data;
    }

    let formattedData = [];

    if (temp) {
      formattedData = temp.map(item => {



        return {
          allData: item,
          key: item.key ?? item.id,
          first_name: <span key={item.first_name?.key ?? item.first_name}><Tooltip placement="topLeft" title={item.first_name?.key ?? item.first_name}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.first_name?.key ?? item.first_name}> <span className="single-line-ellipsis">{item.first_name?.key ?? item.first_name}</span></Highlighter>
          </Tooltip></span>,
          last_name: <span key={item.last_name?.key ?? item.last_name}><Tooltip placement="topLeft" title={item.last_name?.key ?? item.last_name}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.last_name?.key ?? item.last_name}> <span className="single-line-ellipsis">{item.last_name?.key ?? item.last_name}</span></Highlighter>
          </Tooltip></span>,
          email: <span key={item.email?.key ?? item.email}><Tooltip placement="topLeft" title={item.email?.key ?? item.email}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.email?.key ?? item.email}> <span className="single-line-ellipsis">{item.email?.key ?? item.email}</span></Highlighter>
          </Tooltip></span>,

          designation: <span key={item.designation?.key ?? item.designation}><Tooltip placement="topLeft" title={item.designation?.key ?? item.designation}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.designation?.key ?? item.designation}> <span className="single-line-ellipsis">{item.designation?.key ?? item.designation}</span></Highlighter>
          </Tooltip></span>,
        }

      })
      if (data) {
        return formattedData
      }
      else {
        DataMapping["User"] = formattedData;

      }


    }
  }
  const getPodTableData = async () => {
    let response;
    let payload = { action: "list_pod" };
    try {
      response = await dispatch(getLandingPageDataV2(payload));
      if (response?.status === "success") {


        let data = [...response.data];
        data.forEach((item) => {
          // item.allData = item,
          item.key = item.table_id;
          item.source_type = <SourceTypeImage dataName={item.source_type} align="center" />;
          item.table_name = item.table_name.toUpperCase();
          item.created_on = getRelativeTime(item.created_on);
          item.updated_on = getRelativeTime(item.updated_on);
          item.created_by_user = item.created_by_user.username;
          item.updated_by_user = item.updated_by_user.username;


        })

        if (dropdownValue.toLowerCase() === 'pod') {

          DataMapping["POD"] = data;

        }
        if (type == "POD")
          setCurrentData(DataMapping["POD"]);

        if (response.data?.length) {
          setEmpty(false)
        } else {
          setEmpty(true)

        }
        return data
      }
    } catch (error) {
      handleErrorMessage(error.message)
    }
  };
  const getTableData = async () => {
    let response;
    try {

      response = await dispatch(fetchAnalyticsDashboardDetails({ method: "GET", endPoint: "dashboard" }));
      if (response.status === "success") {

        let data = [];
        data = response.data.map(item => {
          const { name: dashboard_name, created_on: createdOn, created_by_user: createdBy, updated_on: updatedOn, updated_by_user: updatedBy, ...rest } = item;
          return { dashboard_name, createdOn: getRelativeTime(createdOn), createdBy: createdBy.username, updatedBy: updatedBy.username, updatedOn: getRelativeTime(updatedOn), ...rest };

        })
        DataMapping["Dashboard"] = data;
        if (type == "Dashboard")
          setCurrentData(DataMapping["Dashboard"]);

      }
    } catch (error) { }
  }

  const getSchedularList = async (data) => {
    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);

      await dispatch(getSchedulerLandingPage()).then((res) => {
        temp = res.data;
        setshowLoadingMsg(false);

      })
        .catch(err => {
          handleErrorMessage(err.message)
        })
    }
    else {
      temp = data;
    }
    let formattedData = [];

    if (temp) {

      formattedData = temp.map(item => {


        return {
          allData: item,
          key: item.key ?? item.id,
          type: item.type,
          name:




            <span key={item.name?.key?.toUpperCase() ?? item.name?.toUpperCase()}>
              {schedulerListTemplate.scheduler_name(
                item.type.toLocaleLowerCase() === 'time' ? <CLOCK width='20' height='20' /> : item.type.toLocaleLowerCase() === 'partition' ? <div style={{
                  width: "18px",
                  height: " 18px",
                  // borderRadius: "50%",
                  // border: " 1.5px solid #2C83C4",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                  zIndex: 1,
                }}><CRAWLER height='18' width='18' /></div> : <div style={{
                  width: "18px",
                  height: " 18px",
                  // borderRadius: "50%",
                  // border: " 1.5px solid #2C83C4",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                  zIndex: 1,
                }}><ICON_EVENT height='18' width='18' /></div>, item.name?.key?.toUpperCase() ?? item.name?.toUpperCase(), filterText)
              }
            </span>,
          description: <span key={item.description?.key ?? item.description}><Tooltip placement="topLeft" title={item.description?.key ?? item.description}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.description?.key ?? item.description}> <span className="single-line-ellipsis">{item.description?.key ?? item.description}</span></Highlighter>
          </Tooltip></span>,
          created_on: <span key={item.created_on?.key ?? item.created_on}><Tooltip placement="topLeft" title={getRelativeTime(item.created_on?.key ?? item.created_on)}>
            {getRelativeTime(item.created_on?.key ?? item.created_on)}
          </Tooltip></span>,
          latest_status: <span key={item.latest_status?.key ?? item.latest_status}> <Tooltip placement="topLeft" title={item.latest_status?.key ?? item.latest_status}>
            {item.latest_status?.key ?? item.latest_status}
          </Tooltip></span>,
          updated_on: <span key={item.updated_on?.key ?? item.updated_on}> <Tooltip placement="topLeft" title={getRelativeTime(item.updated_on?.key ?? item.updated_on)}>
            {getRelativeTime(item.updated_on?.key ?? item.updated_on)}
          </Tooltip></span>,
          active: <span className='text-center' key={item.active?.key ?? item.active}> <Tooltip placement="topLeft" title={item.active?.key ?? item.active}>
            {(item.active?.key ?? item.active) ? <USERACTIVE />
              : <USERINACTIVE />}
          </Tooltip></span>
        }

      })
    }
    if (data) {
      return formattedData
    }
    else {
      DataMapping["Scheduler"] = formattedData;
      if (type == "Scheduler")
        setCurrentData(DataMapping["Scheduler"]);
    }

    return formattedData

  }


  const getFlowList = async (data) => {
    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);

      await dispatch(fetchFlowsLandingPageList()).then((res) => {
        temp = res.data;
        setshowLoadingMsg(false);

      })
        .catch(err => {
          handleErrorMessage(err.message)
        })
    }
    else {
      temp = data;
    }
    let formattedData = [];

    if (temp) {

      formattedData = temp.map(item => {


        return {
          allData: item,
          key: item.key ?? item.id,
          name: <span key={item.name?.key?.toUpperCase() ?? item.name?.toUpperCase()}><label className='pr-1 mb-0'><SMALLFLOWS color={"#2c83c4"} /></label><Tooltip placement="topLeft" title={item.name?.key?.toUpperCase() ?? item.name?.toUpperCase()}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.name?.key?.toUpperCase() ?? item.name?.toUpperCase()}> <span className="single-line-ellipsis">{item.name?.key?.toUpperCase() ?? item.name?.toUpperCase()}</span></Highlighter>
          </Tooltip></span>,
          description: <span key={item.description?.key ?? item.description}><Tooltip placement="topLeft" title={item.description?.key ?? item.description}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.description?.key ?? item.description}> <span className="single-line-ellipsis">{item.description?.key ?? item.description}</span></Highlighter>
          </Tooltip></span>,
          created_on: <span key={item.created_on?.key ?? item.created_on}><Tooltip placement="topLeft" title={getRelativeTime(item.created_on?.key ?? item.created_on)}>
            {getRelativeTime(item.created_on?.key ?? item.created_on)}
          </Tooltip></span>,
          created_by: <span key={item.created_by?.key ?? item.created_by_user.email}> <Tooltip placement="topLeft" title={item.created_by?.key ?? item.created_by_user.email}>
            {item.created_by?.key ?? item.created_by_user.email}
          </Tooltip></span>,
          updated_by: <span key={item.updated_by?.key ?? item.updated_by_user.email}> <Tooltip placement="topLeft" title={item.updated_by?.key ?? item.updated_by_user.email}>
            {item.updated_by?.key ?? item.updated_by_user.email}
          </Tooltip></span>
        }

      })
    }
    if (data) {
      return formattedData
    }
    else {
      DataMapping["Flow"] = formattedData;
      if (type == "Flow")
        setCurrentData(DataMapping["Flow"]);
    }




  }


  const getUserGroupListData = async (data) => {
    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);

      await dispatch(getUserGroupList(
        { method: "GET", endPoint: "group_manager/group/" })).then((res) => {
          temp = res.data;
          setshowLoadingMsg(false);

        })
        .catch(err => {
          handleErrorMessage(err.message)
        })
    }
    else {
      temp = data;
    }

    let formattedData = [];
    if (temp) {


      formattedData = temp.map(item => {


        return {
          allData: item,
          key: item.key ?? item.id,
          name: <span key={item.name?.key ?? item.name}><Tooltip placement="topLeft" title={item.name?.key ?? item.name}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.name?.key ?? item.name}> <span className="single-line-ellipsis">{item.name?.key ?? item.name}</span></Highlighter>
          </Tooltip></span>,
          description: <span key={item.description?.key ?? item.description}>
            <Tooltip placement="topLeft" title={item.description}>
              <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.description?.key ?? item.description}>
                <span className="single-line-ellipsis">{item.description?.key ?? item.description}
                </span>
              </Highlighter>
            </Tooltip>
          </span>,
          created_on: <span key={item.created_on?.key ?? item.created_on}><Tooltip placement="topLeft" title={getRelativeTime(item.created_on)}>
            {getRelativeTime(item.created_on)}
          </Tooltip></span>,
          created_by_user: <span key={item.created_by_user?.key ?? item.created_by_user.username}><Tooltip placement="topLeft" title={item.created_by_user?.key ?? item.created_by_user.username}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.created_by_user?.key ?? item.created_by_user.username}>
              <span className="single-line-ellipsis">{item.created_by_user?.key ?? item.created_by_user.username}</span></Highlighter>
          </Tooltip></span>


        }
      })

      if (data) {
        return formattedData
      }
      else {
        DataMapping["Usergroup"] = formattedData;
        if (type == "Usergroup")
          setCurrentData(DataMapping["Usergroup"]);
      }

    }
  }
  const getDataDomainListData = async (data) => {
    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);

      await dispatch(getDataDomainList()).then((res) => {
        temp = res.data;
        temp.forEach((item) => {
          DATA_DOMAIN_ID_NAME_MAPPING.current[item.data_domain_id] = item.data_domain_name
        })
        setshowLoadingMsg(false);

      })
        .catch(err => {
          handleErrorMessage(err.message)
        })
    }
    else {
      temp = data;
    }

    let formattedData = [];


    if (temp) {

      formattedData = temp.map(item => {
        return {
          allData: item,
          key: item.key ?? item.data_domain_id,
          data_domain_name: <span key={item.data_domain_name?.key ?? item.data_domain_name.toUpperCase()}><span className="mr-1"><SMALLDATADOMAIN color="#398BC8" /></span><Tooltip placement="topLeft" title={item.data_domain_name?.key ?? item.data_domain_name.toUpperCase()}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.data_domain_name?.key ?? item.data_domain_name.toUpperCase()}> <span className="single-line-ellipsis">{item.data_domain_name?.key?.toUpperCase() ?? item.data_domain_name.toUpperCase()}</span></Highlighter>
          </Tooltip></span>,
          data_domain_description: <span key={item.data_domain_description?.key ?? item.data_domain_description}><Tooltip placement="topLeft" title={item.data_domain_description?.key ?? item.data_domain_description}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.data_domain_description?.key ?? item.data_domain_description}> <span className="single-line-ellipsis">{item.data_domain_description?.key ?? item.data_domain_description}</span></Highlighter>
          </Tooltip></span>,
          created_on: <span key={item.created_on?.key ?? item.created_on}><Tooltip placement="topLeft" title={getRelativeTime(item.created_on?.key ?? item.created_on)}>
            {getRelativeTime(item.created_on?.key ?? item.created_on)}
          </Tooltip></span>,
          created_by_user: <span key={item.created_by_user?.key ?? item.created_by_user.username}><Tooltip placement="topLeft" title={item.created_by_user?.key ?? item.created_by_user.username}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.created_by_user?.key ?? item.created_by_user.username}>
              <span className="single-line-ellipsis">{item.created_by_user?.key ?? item.created_by_user.username}</span></Highlighter>
          </Tooltip></span>,
          updated_by_user: <span key={item.updated_by_user?.key ?? item.updated_by_user.username}><Tooltip placement="topLeft" title={item.updated_by_user?.key ?? item.updated_by_user.username}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.updated_by_user?.key ?? item.updated_by_user.username}>
              <span className="single-line-ellipsis">{item.updated_by_user?.key ?? item.updated_by_user.username}</span></Highlighter>
          </Tooltip></span>,
          updated_on: <span key={item.updated_on?.key ?? item.updated_on}><Tooltip placement="topLeft" title={getRelativeTime(item.updated_on?.key ?? item.updated_on)}>
            {getRelativeTime(item.updated_on?.key ?? item.updated_on)}
          </Tooltip></span>,
        }
      })




    }
    if (data) {
      return formattedData
    }
    else {
      DataMapping["Domain"] = formattedData;
      if (type == "Domain")
        setCurrentData(DataMapping["Domain"]);
    }

  };


  const handleErrorMessage = (msg) => {
    setshowLoadingMsg(false)
    setErrorMsg(msg)
  }


  const GetData = async (data) => {

    let temp = null;
    if (!data) {
      setshowLoadingMsg(true);
      await dispatch(GetAlertDataApi()).then((res) => {
        temp = res.data;
        setshowLoadingMsg(false);
      })
        .catch(err => {
          setshowLoadingMsg(false)
          if(err?.message){

            handleErrorMessage(err.message)
          }else if(err.status === 403 && err?.data?.message){
            handleErrorMessage(err?.data?.message)
            

          }

        })
    }
    else {
      temp = data;
    }
    let formattedData = [];
    if (temp) {
      setshowLoadingMsg(false)

      formattedData = temp.map(row => {
        let domainName = row.data_domain.length ? [...new Set(row.data_domain.map((item, i) => item?.data_domain_name?.toUpperCase()))] : [];

        return {
          allData: row,
          key: row.key ?? row.id,
          type: row.type,
          name: <span key={row.name?.key ?? row.name}>
            <span className='mr-1'>
              {getType(row.type, 'listview')}
            </span><Tooltip placement="topLeft" title={row.name?.key?.toUpperCase() ?? row.name.toUpperCase()}>
              <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={row.name?.key?.toUpperCase() ?? row.name.toUpperCase()}> <span className="single-line-ellipsis">{row.name?.key?.toUpperCase() ?? row.name.toUpperCase()}</span></Highlighter>
            </Tooltip></span>
          ,
          description: <span key={row.description?.key ?? row.description}><Tooltip placement="topLeft" title={row.description?.key ?? row.description}>
            <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={row.description?.key ?? row.description}> <span className="single-line-ellipsis">{row.description?.key ?? row.description}</span></Highlighter>
          </Tooltip></span>,
          data_domain:
            <span key={row.data_domain?.key ?? domainName.join(',')}><Tooltip placement="topLeft" title={row.data_domain?.key ?? domainName.join(',')}>
              <Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={row.data_domain?.key ?? domainName.join(',')}> <span className="single-line-ellipsis">{row.data_domain?.key ?? domainName.join(',')}</span></Highlighter>
            </Tooltip></span>,

          priority: <span key={row.priority?.key ?? row.priority}><Tooltip placement="topLeft" title={row.priority?.key ?? row.priority}>{row.priority?.key ?? row.priority}</Tooltip></span>,
          purpose: <span key={row.purpose?.key ?? row.purpose} className={row.purpose?.key ?? row.purpose?.toLowerCase() === 'quality' ? 'pl-0 quality' : 'pl-0 business'}><Tooltip placement="topLeft" title={row.purpose?.key ?? row.purpose ? titleCase(row.purpose?.key ?? row.purpose) + ' Alert' : ''}><Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={row.purpose?.key ?? row.purpose ? titleCase(row.purpose?.key ?? row.purpose) + ' Alert' : ''}> </Highlighter></Tooltip> </span>,
          created_on: <span key={row.created_on?.key ?? row.created_on}><Tooltip title={getRelativeTime(new Date(row.created_on?.key ?? row.created_on)) ? getRelativeTime(new Date(row.created_on?.key ?? row.created_on)) : null}><Highlighter searchWords={[filterText?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={getRelativeTime(new Date(row.created_on?.key ?? row.created_on)) ? getRelativeTime(new Date(row.created_on?.key ?? row.created_on)) : ''}>{getRelativeTime(new Date(row.created_on?.key ?? row.created_on))}</Highlighter></Tooltip></span>,

        }
      }

      )

    }
    if (data) {

      return formattedData;

    } else {





      DataMapping["Alert"] = formattedData;

      if (type == "Alert" || !type)
        setCurrentData(DataMapping["Alert"]);










    }


    return formattedData

  }
  const getComparator = (sortColumn) => {

    return (a, b) => {
      return a[sortColumn]?.key !== undefined ? a[sortColumn]?.key?.localeCompare(b[sortColumn]?.key) : null

    }


  }


  const getType = (val, from = 'ListView') => {
    let value = val?.toLowerCase()
    switch (value) {
      case 'sql': return <SQLBLUE width={from && from.toLowerCase() === 'listview' ? "20" : "35"} height={from && from.toLowerCase() === 'listview' ? "20" : "35"} />
      case 'filter': return <FILTERBLUE width={from && from.toLowerCase() === 'listview' ? "20" : "35"} height={from && from.toLowerCase() === 'listview' ? "20" : "35"} />
      case 'logical': return <LOGICALICON width={from && from.toLowerCase() === 'listview' ? "20" : "35"} height={from && from.toLowerCase() === 'listview' ? "20" : "35"} />
      case 'flow': return <span className='primary-svg-color'><FLOWS width={from && from.toLowerCase() === 'listview' ? "20" : "35"} height={from && from.toLowerCase() === 'listview' ? "20" : "35"} /></span>
      default: return <SQLBLUE width={from && from.toLowerCase() === 'listview' ? "24" : "35"} height={from && from.toLowerCase() === 'listview' ? "24" : "35"} />
    }
  }


  return (<>
    <LandingpageFirstHeader title={"Lineage"}
      flag={false}
      backRoute={"/lineage"} />

    <>
      <div style={{ display: "flex", justifyContent: "space-between", padding: "3px 10px", borderBottom: '1px solid #e6e6e6' }}>
        <div style={{ width: "200px" }}>
          <Dropdown props={{
            label: "", className: "custom-select-dropdown", placeholder: "Select Value", dropDownValue: dropdownValue, onClickEvent: (value) => {
              setDropdownValue(value)
              setFilterText("");
              setCurrentData(DataMapping[value]);
              setActiveColumns(ColumnMapping[value] ?? [])
              setErrorMsg('')
            }, optionList: optionsList,
            isDisable: false
          }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Search searchData={filterText} setSearchData={setFilterText} />
          {/* <Filter
                  props={{
                    filterList: filterList,
                    selectedFilters: selectedFilters,
                    setselectedFilters: setSelectedFilters,
                  }}
                /> */}
        </div>

      </div>
      {/* <ClaristaLoader height='calc(100vh - 150px)' width='100%' /> */}

      {showLoadingMsg || typeLoadingMsg ? <LoadingComponent /> : errMsg?.length > 0 ? <ErrorComponent msg={errMsg} /> :
        empty ?
          <NoDataComponent logo={<LINEAGE_ICON width="60" height="60" color="black" />}
            message={`NO DATA FOUND FOR ${dropdownValue.toUpperCase()}`} />
          :
          <div style={{ padding: "10px", height: 'calc(100vh - 100px' }}>
            {/* <LandingPageTable  props={{ columns:activeColumns ,dataSource:activeData}}/> */}

            {dropdownValue == "POD" ? <Card DATA_DOMAIN_ID_NAME_MAPPING={DATA_DOMAIN_ID_NAME_MAPPING} setEmpty={setEmpty} filterText={filterText} data={currentData} type={dropdownValue} onRowClick={onRowClick} columns={activeColumns} /> :
              <LandingSimpleTable
                getComparator={getComparator}
                autoWidth={true}
                cols={activeColumns}
                onRowClick={onRowClick}
                tableHeight='100%'

                rowsData={activeData}
              />
            }


          </div>}
    </>

  </>

  )


}


const schedulerListTemplate = {
  scheduler_name: (icon, text = '', searchD) => {
    return <>
      <div className='sch-ls-name'>
        <span className='sch-ls-ic'>{icon}</span>
        <span className=' text-with-ellipsis'><Tooltip placement="topLeft" title={text}>< Highlighter searchWords={[searchD?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={text ? text : ''} /></Tooltip></span>
      </div>
    </>
  },

}

export default LandingPage;