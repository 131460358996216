import React from 'react'
import './autogrowinput.scss'

const AutoGrowInput = React.forwardRef(({
    setFocus = () => {},
    onEnterCallback = () => {},
    placeholder = 'Type here...'
}, ref) => {

    const [isDisabled, setDisabled] = React.useState(false)
    const [value, setValue] = React.useState('')

    const autoGrowInpRef = React.useRef({})

    React.useImperativeHandle(ref, () => (
        {
            setInputValue: (val) => {
                setValue(val)
                if(val === "") {
                    autoGrowInpRef.current.innerText = ""
                }
                else {
                    autoGrowInpRef.current.innerText = val
                }
            },
            setInputDisabled: (boolean) => {
                setDisabled(boolean)
            },
            focusInput: () => {
                autoGrowInpRef.current.focus()
            },
            element: autoGrowInpRef?.current
        }
    ), [])

    const onEnterPress = (e) => {
        e.preventDefault()
        onEnterCallback(value)
    }
    
    return (
        <>
            <div
                contentEditable={!isDisabled}
                className={`autogrow-input-field ${isDisabled ? 'disabled' : ''}`}
                ref={autoGrowInpRef}
                data-placeholder={placeholder}
                onFocus={() => {
                    setFocus(true)
                }}
                onBlur={() => {
                        if(value?.trim() === '') {
                            autoGrowInpRef.current.innerHTML = ''
                            autoGrowInpRef.current.innerText = ''
                            setValue('')
                        }
                        setTimeout(() => {
                            setFocus(false)
                        }, 1000)
                    }
                }
                onKeyDown={(e) => {
                    if (e.key?.toLowerCase() === 'enter' && e.shiftKey) {
                        const selection = window.getSelection();
                        const range = selection.getRangeAt(0);
                        const textNode = document.createTextNode('\n'); // Create a text node with a newline character
                        range.insertNode(textNode);
                        range.setStartAfter(textNode);
                        range.setEndAfter(textNode);
                        selection.removeAllRanges();
                        selection.addRange(range);

                    }
                    else if(e?.key?.toLowerCase() === 'enter' && !e.shiftKey) {
                        if(!isDisabled && value?.trim() !== '')
                        onEnterPress(e)
                    }
                }}
                onInput={(e) => {
                    e.preventDefault()
                    if(!isDisabled) {
                        let val = e?.target?.innerText
                        setValue(val)
                        
                        // if(!val.match(/\n/g)) {
                        //     setValue(val)
                        // }
                        // else {
                        //     setValue(value)
                        //     autoGrowInpRef.current.innerText = value
                        // }
                    }
                }}
                onPaste={(e) => {
                    e.preventDefault()
                    let text = e.clipboardData.getData("text/plain");
                    /**
                     * Removing line breaks
                     */
                    // text = text.replace(/(\r\n|\n|\r)/gm,"") // uncomment to use

                    document.execCommand('insertText', false, text)

                    /**
                     * In case execCommand is removed by Javscript in new version
                     * use this below code
                     */
                    /* text = text.replace(/(\r\n|\n|\r)/gm,"")
                    const selection = window.getSelection();
                    if (!selection.rangeCount) return;
                    selection.deleteFromDocument();
                    selection.getRangeAt(0).insertNode(document.createTextNode(text));
                    setSearchValue(selection?.anchorNode?.innerText)
                    selection.collapseToEnd() */
                }}
                >
            </div>
        </>
    )
})

export default AutoGrowInput