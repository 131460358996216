import React, { useState, useEffect, useRef } from 'react';

function useIntersection(paginate) {
    let bottomPrevY = 0;
    let bottomPrevRatio = 0;
    let topPrevRatio = 0;
    let topPrevY = 0;

    const rootRef = useRef();
    const bottomRef = useRef();
    const topRef = useRef();

    useEffect(() => {

        if (rootRef?.current) {

            const observer = new IntersectionObserver(handleIntersection, {
                root: rootRef?.current,
                rootMargin: "0px",
                threshold: [0.5],
            });
            if (bottomRef.current) {
                observer.observe(bottomRef.current);
            }
            if (topRef.current) {
                observer.observe(topRef.current);
            }
        }
    }, [bottomRef, rootRef]);

    const handleIntersection = ([entry]) => {

        if (entry.target.id === "table-top") {
            handlePreviousScroll(entry);
        } else if (entry.target.id === "table-bottom") {
            handleNextScroll(entry);
        }
    };
    const handleNextScroll = (entry) => {
        const currentY = entry.boundingClientRect.top;
        const currentRatio = entry.intersectionRatio;
        const isIntersecting = entry.isIntersecting;

        if (
            currentY < bottomPrevY &&
            currentRatio > bottomPrevRatio &&
            isIntersecting
        ) {
            
            paginate("next");


        }
        bottomPrevY = currentY;
        bottomPrevRatio = currentRatio;
    };
    const handlePreviousScroll = (entry) => {
        const currentY = entry.boundingClientRect.top;
        const currentRatio = entry.intersectionRatio;
        const isIntersecting = entry.isIntersecting;

        if (currentY > topPrevY && isIntersecting && currentRatio >= topPrevRatio) {
            paginate("last")
        }
        topPrevY = currentY;
        topPrevRatio = currentRatio;
    };

    return { rootRef, topRef, bottomRef };

}

export default useIntersection
