import React from 'react'
import './deleteModal.scss'
import CommonModal from '../CommonModal/CommonModal'
import { ICON_DELETE, ICON_LOADING_CIRCLE, ICON_WARNING } from '../newIconSource'
import { Literals } from '../../modules/common/literals'
import Buttons from '../button/Buttons'

const DeleteModal = ({
    open = false,
    setOpen = () => { },
    onConfirm = () => { },
    heading = 'Confirm Delete',
    body = 'Are you sure, want to continue?',
    deleteBtnDisabled = false,
    loading = false,
    extraContent = <></>
}) => {

    const handleConfirm = e => {
        e.stopPropagation()
        onConfirm()
    }

    const handleCancel = e => {
        e.stopPropagation()
        setOpen(false)
    }

    return (
        <CommonModal
            className='pro-custom-del-modal'
            open={open}
            setOpen={setOpen}
            header={
                <div className='text-danger'>
                    {/* <span className='mr-2'>
                        <ICON_WARNING color='#dc3545' height='20' width='20' />
                    </span> */}
                    <span>
                        {heading}
                    </span>
                </div>
            }
            body={
                <>
                    <div className='fontSizeHeading font-weight-bold'>
                        {body}
                    </div>
                    {extraContent}
                </>
            }
            footer={
                <>
                    {/* <Buttons 
                        props={{ 
                            buttonText: 'Cancel', 
                            buttonClassName: "custom-btn-outline custom-btn btn-with-text mr-1", 
                            buttonEvent: handleCancel, 
                            ImgSrc: () => <></>, 
                            isDisable: false, 
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT 
                        }} 
                    /> */}
                    <Buttons
                        props={{
                            buttonText: loading ? 'Deleting...' : 'Delete',
                            buttonClassName: "danger-btn custom-btn btn-with-text",
                            buttonId: "primary-focus-btn",
                            buttonEvent: handleConfirm,
                            ImgSrc: () => loading ? <ICON_LOADING_CIRCLE /> : <ICON_DELETE />,
                            isDisable: loading || deleteBtnDisabled,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }}
                    />
                </>
            }
            size='sm'
        />
    )
}

export default DeleteModal