
import { Tooltip,Checkbox } from 'antd'
import { isArray } from 'lodash'
import React, { useEffect, useState ,useRef} from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { deleteSettingsAdditionalDetails, getSettingsAdditionalDetailsList } from '../../../../store/modules/dataDictionary/action'
import Buttons from '../../../Common/button/Buttons'
import { COLUMN, DELETEICON, EDIT, PLUS } from '../../../Common/iconSource'
import { LandingSimpleTable, LandingSimpleTableInner } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import SearchInput from '../../../Common/search/Search'
import ActionPopUp from '../../common/actionPopUp'
import { NoDataComponent, getDeleteMessage, getFieldTypeIcon, getObjectLength, getReloadErrorTemplate, pxToRem, remToPx, showSimpleLoading } from '../../common/helperFunctions'
import { Literals } from '../../common/literals'
import CreateAdditionalFieldModal from './CreateAdditionalFieldModal'
import { ICON_ALERT, ICON_INFO, ICON_TXT_FILE } from '../../../Common/newIconSource'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'


export const AdditionalViewTable=({
    selected,
    setSelected,
    isEdit,
    alreadyPresent = [],
    getButtonDisabled = () => {}
})=>{
    const dispatch = useDispatch();
    const rawData = useRef([]);

    const [allUsed, setAllUsed] = React.useState(false)
    const [empty, setEmpty] = React.useState(false)
    const [selectAll, setSelectAll] = React.useState(false)

    const onSelect=(record)=>{
        const isChecked=selected.filter(item=>item.id==record.id).length>0;
        if(isChecked){
          setSelected(selected.filter(item=>item.id!==record.id));
        }
        else{
          setSelected([...selected,record])
        }
        
    }
      
    const generateRow = (data) => {
        if(alreadyPresent && alreadyPresent?.length > 0) {
            let ids = alreadyPresent?.map(f => f?.id)
            data = data?.filter(d => !ids.includes(d?.id))
            setAllUsed(data?.length === 0)
        }
        let temp = data.map((item) => {
            const isChecked=selected.filter(row=>item.id==row.id)[0];

            return {
                id:item.id,
                field_name: listTemplate.fieldName(item.name,true,item,isChecked,onSelect,isEdit),
                field_type: listTemplate.fieldType(item.type),
                default_value: listTemplate.defaultValues(item?.default_value?.toString() ?? ''),
                possible_values: listTemplate.possibleValues(isArray(item.value) ? item.value.join(',') : item.value),
                description: listTemplate.description(item?.description),
                data: {...item, isChecked: !!isChecked }
            }
        })
        return temp
    }
    const [data, setData] = useState([])

    React.useEffect(() => {
        if(allUsed) {
            getButtonDisabled(allUsed)
        }
        else {
            getButtonDisabled(!data?.some(d => d?.data?.isChecked))
        }

        setSelectAll(data?.every(d => d?.data?.isChecked))

    }, [allUsed, data])

    const getList = () => {
        dispatch(getSettingsAdditionalDetailsList())
        .then(res=>{
            let temp = generateRow(res?.data)
            setData([...temp])
            rawData.current=[...res?.data];
            setEmpty(res?.data?.length === 0)
        })
        .catch(err=>{
            setData([])
            setEmpty(false)
            console.log({err})
        })
    }
    useEffect(()=>{
        let temp=  generateRow(rawData.current)
        setData([...temp])
    },[selected])

    useEffect(()=>{
       getList();
    },[])
    
    
    const modalColumns = [
        {
            name: <>
                <Tooltip title={selectAll ? 'Deselect All' : 'Select All'} placement='top'>
                    <Checkbox 
                        checked={selectAll} 
                        onChange={e => {
                            setSelectAll(e?.target?.checked)
                            setSelected(e?.target?.checked ? [...rawData.current] : [])
                        }}
                        className='mr-2' 
                        disabled={data?.length === 0}
                    />
                </Tooltip>
                Field Name
            </>,
            key: "field_name",
            sortable: false,
            resizable: false
        },
        {
            name: 'Description',
            key: "description",
            sortable: false,
            width:"30%",
            resizable: false
        },
        {
            name: 'Field Type',

            key: "field_type",
            sortable: false,
            resizable: false,
            width: 140
        },
        {
            // name: 'Possible Values (Optional)',
            name: 'Default Values',
            key: "default_value",
            sortable: false,
            // width:"25%",
            resizable: false,
        },
        {
            // name: 'Possible Values (Optional)',
            name: 'Possible Values',
            key: "possible_values",
            sortable: false,
            width:"25%",
            resizable: false,
        }
    ]
    

    return (
        <>
            {
                allUsed && !empty
                ?
                <div className='fontSizeHeading text-black-50 p-5 text-center'>
                    It's seems all additional fields are in use
                </div>
                :
                <LandingSimpleTableInner
                    rowsData={data}
                    cols={modalColumns}
                    tableHeight={'100%'}
                />
            }
        </>
    )
}

export const SettingsAdditionalDetails = ({
    searchText = '',
    setAddFieldsModal = () => {},
    openAddFieldsModal = false,
    hideActions = false
}) => {

    const dispatch = useDispatch()

    const [data, setData] = useState(null)
    const [rawData, setRawData] = useState([])
    const [viewOnly,setViewOnly]=useState(false);
    const [searchData, setSearchData] = useState('')
    const [openModal, setOpenModal] = useState(openAddFieldsModal ?? false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedData, setSelectedData] = useState({name:'', type: '', value: []})
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [empty, setEmpty] = useState(false);

    const currentAddFieldRef = React.useRef({current: {}});

    useEffect(() => {
        getList()
    }, [])

    useEffect(() => {
        setSearchData(searchText)
    }, [searchText])

    useEffect(() => {
        setAddFieldsModal(openModal)
    }, [openModal])

    useEffect(() => {
        setOpenModal(openAddFieldsModal)
    }, [openAddFieldsModal])
    
    useEffect(()=>{
        if(data!==null) {
            const FILTER_COLUMNS = ['name', 'value', 'type']
            let clone = [...rawData]
            let newData = clone.filter(item=>FILTER_COLUMNS.some(key=>{
                let row = isArray(item[key]) ? item[key].join(',') : item[key]
                return row?.toLowerCase()?.includes(searchData.toLowerCase())
            }))
            let temp = generateRow(newData)
            setData([...temp])
        }
    },[searchData])

    const generateRow = (data) => {
        let temp = data.map((item) => {
            return {
                id:item.id,
                field_name: listTemplate.fieldName(item.name),
                field_type: listTemplate.fieldType(item.type),
                default_value: listTemplate.defaultValues(item?.default_value?.toString() ?? ''),
                possible_values: listTemplate.possibleValues(isArray(item.value) ? item.value.join(',') : item.value),
                description: listTemplate.description(item?.description),
                action: listTemplate.action(action, item)
            }
        })
        return temp
    }

    const getList = () => {
        setError(undefined)
        setEmpty(false)
        setLoading(true)
        dispatch(getSettingsAdditionalDetailsList(false, false))
        .then(res => {
            let temp = generateRow(res?.data)
            setData([...temp])
            setRawData([...res.data])
            setEmpty(res?.data?.length === 0)
            setLoading(false)
        })
        .catch(err => {
            setData([])
            setEmpty(false)
            setError(err?.message)
            setLoading(false)
        })
    }
    
    const openDeleteDialog = (data) => {
        currentAddFieldRef.current = data
        setOpenDeletePopup(true);
    };

    const handleDelete = (data) => {
        setData(null)
        dispatch(deleteSettingsAdditionalDetails(data.id))
        .then(()=>{
            getList()
            emitToastNotification('success',Literals.DELETE_SUCCESSFUL)
            setOpenDeletePopup(false);
        })
        .catch(()=>{
            getList()
            emitToastNotification('error',Literals.DELETE_FAILED)
        })
    }

    const action = {
        onEdit: (data) => {
            setIsEdit(true)
            setSelectedData({...data})
            setOpenModal(true)
        },
        onDelete: (data) => openDeleteDialog(data)
    }    

    const onRowClick=(data)=>{
        setIsEdit(true)
        let pureData=rawData.find(item=>item.id==data.id);
        setSelectedData({...pureData})
        setViewOnly(true);
        setOpenModal(true)
    }

    return (
        <div className='h-100'>
            <Card className='card custom-card p-0 h-100 add-det-card border-0 shadow-none'>
                {/* <CardHeader className='padding-2 bg-white'>
                    <div style={{height: 27}} className='d-flex align-items-center w-100 justify-content-between'>
                        <label className='mb-0 d-flex align-items-center font-weight-bold'>

                            {
                                searchData?.trim() !== ''
                                ?   <div className='dict-color-dark fontSizeLabel fontInterSemiBold'>
                                        Showing {data?.length} result(s) for "{searchData}"
                                    </div>
                                :   <div className='dict-color-dark fontSizeLabel fontInterSemiBold'>
                                        {data?.length} Record(s)
                                    </div>
                            }
                        </label>
                        {
                            hideActions ? ''
                            :
                            <div className='d-flex'>
                                <SearchInput searchData={searchData} setSearchData={setSearchData} />
                                <Buttons
                                    props={{
                                        buttonText: 'Entry',
                                        tooltip: '',
                                        buttonClassName: "custom-btn-primary custom-btn btn-with-text ml-2",
                                        buttonEvent: () => setOpenModal(true),
                                        ImgSrc: () => <PLUS/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />
                            </div>
                        }
                    </div>
                </CardHeader> */}
                <CardBody className='p-0 dict-addfields-items'>
                    {
                        empty ? <NoDataComponent logo={<ICON_INFO/>} message={'Click on "+" button to Add Fields'} />
                        : error ? getReloadErrorTemplate({errorMessage: error, onReload: () => getList()})
                          : loading ? <ClaristaLoader/>
                            :
                        data===null
                        ?
                        showSimpleLoading()
                        :
                        data.length
                        ?
                        <LandingSimpleTableInner
                            rowsData={data}
                            onRowClick={onRowClick}
                            cols={columns}
                            tableHeight={'100%'}
                        />
                        : <div className='p-3 text-center text-black-50'>No Additional Field Found</div>
                    }
                </CardBody>
            </Card>
            <CreateAdditionalFieldModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                getList={getList}
                selectedData={selectedData}
                viewOnly={viewOnly}
                setViewOnly={setViewOnly}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
            />

            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={getDeleteMessage({
                    title: 'Additional Field'
                })}
                onConfirm={() => handleDelete(currentAddFieldRef?.current)}
            />


            {/* <ActionPopUp
                openModal={openDeletePopup}
                actionData={deletePopUpContent}
            /> */}
        </div>
    )
}


const listTemplate = {
    fieldName: (text = '',check,row,isChecked,onSelect,isEdit) => {
        return <>
        <div className='d-flex align-items-center h-100'>

          {check? <Checkbox
            key={row.id}

            onChange={(e)=>{
             e.stopPropagation();
             e.preventDefault();
              onSelect(row)
            }}
            disabled={isEdit?true:false}
             checked={ isChecked}
            // onChange={(e) => {
            //   e.stopPropagation();
            //   e.preventDefault();
            //   addToSelectedPOD(row[0], e, row.table_name, row);
            // }}
            className="pr-2"

          ></Checkbox>:""}
            <Tooltip placement='topLeft' title={text}>
                <div className="text-uppercase dict-color-dark text-with-ellipsis fontSizeLabel">
                {text}
                </div>
                {/* <label style={{lineHeight: '1.4'}} className='mb-0 classificationTag primary text-uppercase'>
                {   
                    text.length>9
                    ? text?.slice(0, 8) + '...'
                    : text
                }  
                </label> */}
            </Tooltip>
        </div>
    </>
    },
    fieldType: (text = '') => {
        return <>
            <Tooltip placement='topLeft' title={text}>
                <div className='text-with-ellipsis'>
                    <span className='mr-1'>
                        {getFieldTypeIcon(text)}
                    </span>
                    {text}
                </div>
            </Tooltip>
        </>
    },
    defaultValues: (text = '') => {
        return <>
            <Tooltip placement='topLeft' title={text}>
                <div className='text-with-ellipsis'>
                    {text}
                </div>
            </Tooltip>
        </>
    },
    possibleValues: (text = '') => {
        return <>
            <Tooltip placement='topLeft' title={text}>
                <div className='text-with-ellipsis'>
                    {text}
                </div>
            </Tooltip>
        </>
    },
    description: (text = '') => {
        return <>
            <Tooltip placement='topLeft' title={text}>
                <div className='text-with-ellipsis'>
                    {text}
                </div>
            </Tooltip>
        </>
    },
    action: (action = {}, data = {}) => {
        return <>
            <div className='d-flex justify-content-end align-items-center h-100'>
                <Buttons
                    props={{
                        buttonText: "",
                        tooltip: "Edit",
                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent`,
                        buttonEvent: (e) => {
                            e.stopPropagation();
                            action.onEdit(data)
                        },
                        ImgSrc: () => <EDIT />,
                        isDisable: false,
                        buttonType: Literals.BTN_SECONDARY,
                    }}
                />
                <Buttons
                    props={{
                        buttonText: "",
                        tooltip: "Delete",
                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red bg-transparent border-0 ml-2`,
                        buttonEvent: (e) => {
                            e.stopPropagation();
                            action.onDelete(data)
                        },
                        ImgSrc: () => <DELETEICON />,
                        isDisable: false,
                        buttonType: Literals.BTN_SECONDARY,
                    }}
                />
            </div>
        </>
    }
}

const dynamic = (px) => remToPx(pxToRem(px))

const columns = [
    {
        name: 'Field Name',
        key: "field_name",
        sortable: false,
        resizable: false,
        width: dynamic(250)
    },
    {
        name: 'Description',
        key: "description",
        sortable: false,
        width:"30%",
        resizable: false
    },
    {
        name: 'Field Type',

        key: "field_type",
        sortable: false,
        resizable: false
    },
    {
        // name: 'Possible Values (Optional)',
        name: 'Default Values',
        key: "default_value",
        sortable: false,
        // width:"25%",
        resizable: false,
    },
    {
        // name: 'Possible Values (Optional)',
        name: 'Possible Values',
        key: "possible_values",
        sortable: false,
        width:"20%",
        resizable: false,
    },
    
    {
        name: <div className='text-right'>Actions</div>,
        key: "action",
        sortable: false,
        resizable: false
    }
]