import { Input, Switch } from 'antd'
import React from 'react'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ICON_CHECK } from '../../../../Common/newIconSource';

const AutoCreatePassword = ({
    password = '',
    setPassword = () => {},
    getPasswordStatus = () => {},
    getAutoCreateStatus = () => {}
}) => {

    const [hasNumber, sethasNumber] = React.useState(false);
    const [hasUpperCase, sethasUpperCase] = React.useState(false)
    const [hasLowerCase, sethasLowerCase] = React.useState(false)
    const [hasSpecialCharacters, sethasSpecialCharacters] = React.useState(false)
    const [hasMinimumCharacters, sethasMinimumCharacters] = React.useState(false)
    const [status, setStatus] = React.useState('')
    const [showPassword, setShowPassword] = React.useState(false)
    const [autoCreate, setAutoCreate] = React.useState(true)
    const [progressBarValue, setProgressBarValue] = React.useState(0)

    React.useEffect(() => {
        getPasswordStatus(progressBarValue)
    }, [progressBarValue])

    React.useEffect(() => {
        getAutoCreateStatus(autoCreate)
    }, [autoCreate])

    const onChangeListener = (event) => {
        const value = event?.target?.value?.trim()
        let localProgressBarValue = 0
        let hasNumberRegex = /[0-9]/;
        let hasUpperCaseRegex = /[A-Z]/;
        let hasLowerCaseRegex = /[a-z]/;
        let hasSpecialCharactersRegex = /[#?!@$%^&*-]/;
        let hasMinimumCharactersRegex = /.{8,}/
        if (value.match(hasNumberRegex) != null) {
            sethasNumber(true)
            localProgressBarValue += 20
        } else {
            sethasNumber(false)

        }

        if (value.match(hasUpperCaseRegex) != null) {
            sethasUpperCase(true)
            localProgressBarValue += 20
        } else {
            sethasUpperCase(false)
        }

        if (value.match(hasLowerCaseRegex) != null) {
            sethasLowerCase(true)
            localProgressBarValue += 20
        } else {
            sethasLowerCase(false)
        }

        if (value.match(hasSpecialCharactersRegex) != null) {
            sethasSpecialCharacters(true)
            localProgressBarValue += 20
        } else {
            sethasSpecialCharacters(false)

        }

        if (value.match(hasMinimumCharactersRegex) != null) {
            sethasMinimumCharacters(true)
            localProgressBarValue += 20
        } else {
            sethasMinimumCharacters(false)
        }

        switch (localProgressBarValue) {
            case 0:
                setStatus('Very Weak');

                break;
            case 20:
                setStatus('Very Weak')

                break;
            case 40:
                setStatus('Weak')

                break;
            case 60:
                setStatus('Average')

                break;
            case 80:
                setStatus('Good')

                break;
            case 100:
                setStatus('Great')

                break;
            default:
                break;
        }
        
        setPassword(value);
        
        setProgressBarValue(localProgressBarValue)
    }

    const STATUS_COLOR_CLS = {
        'Very Weak': 'text-danger',
        'Weak': 'text-danger',
        'Average': 'text-warning',
        'Good': 'text-success',
        'Great': 'text-primary'
    }

    return (
        <div className='auto-create-pass-wrap'>
            <div className="d-flex align-items-center mb-3">
                <Switch
                  className='custom-switch-btn'
                  checkedChildren="Yes" unCheckedChildren="No" 
                  checked={autoCreate}
                  onChange={(check) => {
                    setStatus('')
                    setProgressBarValue(0)
                    setPassword('')
                    setAutoCreate(check)
                  }}
                />
                <span className="mb-0 fontSizeHeading ml-2 text-dark fontInterSemiBold">
                  Automatically create a password
                </span>
            </div>
            {
                // autoCreate ? ''
                // :
                <div>
                    <span className="fontSizeHeading fontInterSemiBold text-dark mb-2 d-block">
                    Password
                    </span>
                    <div>
                    <Input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="enter password"
                        disabled={autoCreate}
                        value={password}
                        onChange={(e) => {
                            onChangeListener(e)
                        }}
                        suffix={
                            <>
                                <span className={`${STATUS_COLOR_CLS[status] ?? 'text-dark'} fontInterSemiBold fontSizeLabel`}>
                                    {
                                        status === 'Great'
                                        ? <>
                                            <span className='mr-1 text-success'>
                                                <ICON_CHECK width='13' height='13' color='currentColor' />
                                            </span>
                                            {status}
                                            </>
                                        : status
                                    }
                                </span>
                                <span className='d-flex align-items-center justify-content-center p-1 cursor-pointer'
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {
                                        showPassword
                                        ?
                                        <EyeTwoTone />
                                        :
                                        <EyeInvisibleOutlined />
                                    }
                                </span>
                            </>
                        }
                    />
                    </div>
                    <span className="mt-2 d-block fontSizeLabel text-muted">
                    Password must be 8 character long and use a combination of uppercase letters, lowercase letters, number and symbol
                    </span>
                </div>
            }
        </div>
    )
}

export default AutoCreatePassword