

import React, { forwardRef, useEffect, useState } from "react";
import PreviewPageLayout from "../PreviewPageLayout";
import { v4 as uuidv4 } from 'uuid';

import { QueryKillError, convertBase64ToString, getObjectLength, getTableSkeleton } from "../../../../common/helperFunctions";

import DataTabs from "../../../../../Common/dataTabs/DataTabs";
import ClaristaLoader from "../../../../../Common/claristaLoader/ClaristaLoader";
import { useDispatch, useSelector } from "react-redux";
import { getPreviewTable } from "../../../../../../store/modules/dataCatalogue/preview/previewActions";
import DataGridTable from "../../../../../Common/dataGridTable/DataGridTable";
const PreviewGrid = forwardRef(
  ({ schemaDetails, setIsGridLoaded, isVisible, podDetails, props }, ref) => {
    const [isDataGridReady, setIsDataGridReady] = useState(true);
    const dispatch = useDispatch()
    let isQueryKill = useSelector((state => state.CommonComponent?.DataGridTable?.isQueryKill))

    useEffect(() => {
      setIsDataGridReady(false);
      setTimeout(() => {
        setIsDataGridReady(true);
      }, 1000);
    }, [isVisible]);

    const [activeKey, setActiveKey] = useState("1");
    const [parentQuery, setparentQuery] = useState("");
    const [previewError, setPreviewError] = React.useState(undefined)
    const [previewLoading, setPreviewLoading] = React.useState(false)
    const [uniqUUID, setUniqUUID] = React.useState('')
    const [previewData, setPreviewData] = React.useState({});

    useEffect(() => {
      if (props && props?.from === 'reports') {
        if (props && props?.GPTSQLQuery && activeKey === '1') {
          setparentQuery(props?.GPTSQLQuery)
          fetchPreview(props?.GPTSQLQuery);
        } else {
          setparentQuery(convertBase64ToString(props?.responseData?.query_string))
          fetchPreview(convertBase64ToString(props?.responseData?.query_string));
        }
      }

    }, [props])

    const resultTabs = React.useMemo(() => {
      let tabs = [
        {
          podId: "0",
          key: "0",
          tabTitle: "All Records",
          podName: "Result",
          databaseName: "resultTab",
          tabContent: null,
          closable: false,
        },
        {
          podId: "0",
          key: "1",
          tabTitle: "Result",
          podName: "Result",
          databaseName: "resultTab",
          tabContent: null,
          closable: false,
        },
      ]

      if(props?.GPTSQLQuery === undefined || props?.GPTSQLQuery === null) {
        tabs = tabs?.filter(t => t?.key === '0')
      }

      return tabs
    }, [props]) 

    // console.log({props})
    const onTabChange = (key) => {
      setActiveKey(key);
      // console.log({key})
      if (key === '0') {
        setparentQuery(convertBase64ToString(props?.responseData?.query_string))
        fetchPreview(convertBase64ToString(props?.responseData?.query_string));
      } else {
        setparentQuery(props?.GPTSQLQuery)
        fetchPreview(props?.GPTSQLQuery);

      }
    }

    const fetchPreview = (query) => {
      setPreviewData({})

      setPreviewLoading(true)
      setPreviewError(undefined)
      let uuid = uuidv4()
      setUniqUUID(uuid)

      let request = {
        uuid: uuid,
        page: 0,
        do_count: false,
        sort_body: [],
        filter_body: [],
        query: query

      }
      dispatch(getPreviewTable(request, false))
        .then((res) => {
          setPreviewData(res?.data?.result ?? {})
          setPreviewLoading(false)
        })
        .catch((err) => {
          setPreviewError(err?.message ?? 'Error!')
          setPreviewLoading(false)
        })
    }
    // console.log({previewLoading})

    return (
      <div>
        {props && props?.from === 'reports' ? <div>
          <DataTabs
            props={{
              activeKey: activeKey,
              defaultKey: "",
              onEdit: () => { },
              onChange: (activeLeftPanelKey) => {
                onTabChange(activeLeftPanelKey);
              },
              tabArray: resultTabs,
            }}
          />
          {previewLoading ? <ClaristaLoader isCancel={true} setKillQueryDone={setPreviewLoading} /> :
            isQueryKill ? <QueryKillError /> :
              getObjectLength(previewData) > 0 ?
                <div className="flow-lower-section" style={{ overflow: "hidden", height: "calc(100% - 28px)" }}>
                  <div id="alert-lower-table-sec" className="flow-lower-table bg-white custom-virtual-data-table" >
                    <DataGridTable
                      resp={previewData}
                      showDictionaryDetails={false}
                      stopColumnReorder={true}
                      uniqUUID={uniqUUID}
                      parentQuery={parentQuery}
                      setUniqUUID={setUniqUUID}
                      isCustomSql={true}
                      requestedTableId={podDetails?.table_id}
                    /></div></div>
                : null

          }
        </div> :
          <PreviewPageLayout
            ref={ref}
            dictSchemaData={schemaDetails}
            isDeletePermission={false}
            isEditPermission={false}
            isDataGridReady={isDataGridReady}
            setIsGridLoaded={setIsGridLoaded}
            isPreviewGrid={true}
            gridHeight={"80%"}
            props={props}
          />
        }

      </div>
    );
  }
);

export default PreviewGrid;
