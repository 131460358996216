import { combineReducers } from "redux";
import CreateRule from "./CreateRule/rulesReducer";


const RulesReducer = combineReducers({
    CreateRule
});



export default RulesReducer