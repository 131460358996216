import { Tooltip } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getUserGroupList } from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import Buttons from "../../../../Common/button/Buttons";
import { CROWN, MINUS, USER } from "../../../../Common/iconSource";
import SelectedUsersTable from "../../../../Common/landingPageTable/LandingPageTable";
import { Literals } from "../../../common/literals";
import { RolePrivilegeModal } from "../../../common/helperFunctions";
import {
  InfoCircleFilled
} from '@ant-design/icons';
import IconToggleButton from "../../../../Common/iconToggleButton/IconToggleButton";
const UsersTable = ({
  selectedUsers,
  setSelectedUsers,
  onChangeUserType,
  viewMode,
  groupId,
  existingUserIds,
  isSuperUser = false,
  isLeader = false
}) => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const removeUserFromSelectedUsers = (userId) => {

    setSelectedUsers((users) => {
      const newUsers = [...users].filter((user) => user.id !== userId);
      return newUsers;
    });
  };

  const removeUserFromUserGroup = async (userId, isLeader) => {
    let endPoint;

    if (isLeader) {
      endPoint = `group_manager/group/${groupId}/remove_leader_user/`;
    } else {
      endPoint = `group_manager/group/${groupId}/remove_member_user/`;
    }

    try {
      const response = await dispatch(
        getUserGroupList({
          method: "POST",
          endPoint,
          payload: {
            ids: [userId],
          },
        })
      );

      if (response.status === "success") {
        removeUserFromSelectedUsers(userId);
      }
    } catch (error) {
      console.error("Error removing User");
    }
  };

  const usersHeadings = [
    {
      title: Literals.FIRSTNAME,
      dataIndex: "firstName",
      key: "firstName",
      width: "20%",
      sorter: {
        compare: (a, b) =>
          // a.name?.props?.children[1]?.props?.title !== undefined
          //   ? a.name?.props?.children[1]?.props?.title.localeCompare(
          //     b.name?.props?.children[1]?.props?.title
          //   )
          //   : null,
          a.allData?.first_name?.localeCompare(b.allData?.first_name)
      },
    },
    {
      title: Literals.LASTNAME,
      dataIndex: "lastName",
      key: "lastName",
      width: "20%",
    },

    {
      title: Literals.EMAIL,
      dataIndex: "email",
      key: "email",
      width: "30%",
    },







    {
      title: <div style={{ display: "flex", alignItems: "center" }}>{Literals.TYPE} <InfoCircleFilled style={{ color: "#2C83C4" }}
        onClick={() => {
          setOpenModal(true);
        }}
        className="ml-2" /></div>,
      dataIndex: "type",
      key: "type",
      width: "30%",
    },
    {
      title: <div className='text-right'>Remove</div>,
      dataIndex: "action",
      key: "action",
      width: "10%",
    },
  ];

  const usersData = selectedUsers.map((user, index) => {
    return {
      firstName: (
        <Tooltip placement="topLeft" title={user.first_name}>
          {user.first_name}
        </Tooltip>
      ),
      lastName: (
        <Tooltip placement="topLeft" title={user.last_name}>
          {user.last_name}
        </Tooltip>
      ),
      email: (
        <Tooltip placement="topLeft" title={user.email}>
          {user.email}
        </Tooltip>
      ),

      status: (
        <Tooltip
          placement="top"
          title={user.is_verified ? "Active" : "Inactive"}
        >
          {user.is_verified ? "Active" : "Inactive"}
        </Tooltip>
      ),
      allData: user,
      superUser: (
        <Tooltip placement="top" title={user.is_superuser ? "Yes" : "No"}>
          {user.is_superuser ? "Yes" : "No"}
        </Tooltip>
      ),
      type: (
        <div className="double-toggle-btn filter-toggle-btns">

          <IconToggleButton
              data={
                [
                  {value: 'leader', label: 'Leader', icon: <CROWN/>},
                  {value: 'member', label: 'Member', icon: <USER/>},
                ]
              }
              onChange={(e) => {
                  const val = e.target.value
                  if (!(isSuperUser || isLeader)) return
                  onChangeUserType(index, val)
              }}
              value={user.type === 'member' || user.type === 'leader' ? user.type : 'member'}
              disabled={!(isSuperUser || isLeader)}
          />

          {/* <Buttons

            props={{
              buttonText: "",
              toggleBtnOption: [
                {
                  label: (
                    <div onClick={() => {
                      if (!(isSuperUser || isLeader)) return
                      onChangeUserType(index, 'leader')
                    }
                    }>
                      <CROWN />
                      {"Leader"}
                    </div>
                  ),
                  value: "leader",
                  disabled: !(isSuperUser || isLeader)
                },
                {
                  label: (
                    <div onClick={() => {
                      if (!(isSuperUser || isLeader)) return
                      onChangeUserType(index, 'member')
                    }
                    }>
                      <USER />
                      {"User"}
                    </div>
                  ),
                  value: "member",
                  disabled: !(isSuperUser || isLeader)
                },
              ],
              toggleBtnValue: user.type === 'member' || user.type === 'leader' ? user.type : 'member',
              buttonClassName: "toggle-btn custom-btn btn-with-icon ",
              ImgSrc: () => <></>,
              isDisable: !(isSuperUser || isLeader),
              buttonType: Literals.BTN_TOGGLE,
            }}
          /> */}
        </div>
      ),
      action: (
        <div className="d-flex justify-content-end">
          <Buttons
            props={{
              buttonText: "",
              buttonClassName:
                "custom-btn-outline  custom-btn btn-with-icon error-red red-border",
              buttonEvent: (e) => {
  
                if (viewMode === "create") {
                  removeUserFromSelectedUsers(user.id);
                } else {
                  if (existingUserIds.includes(user.id)) {
                    removeUserFromUserGroup(user.id, user.is_superuser);
                  } else {
                    removeUserFromSelectedUsers(user.id);
                  }
                }
              },
              ImgSrc: () => <MINUS />,
              isDisable: !(isSuperUser || isLeader) || viewMode.includes("view"),
              buttonType: Literals.BTN_SECONDARY,
            }}
          />
        </div>
      ),
    };
  });

  return (<>
    <SelectedUsersTable
      props={{
        pagination: false,
        size: "small",
        columns: usersHeadings,
        dataSource: usersData,
        showSorterTooltip: false,
      }}
    />
    <RolePrivilegeModal openModal={openModal} setOpenModal={setOpenModal} />
  </>
  );
};

export default UsersTable;
