import React, { useEffect, useState } from 'react'
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useDispatch, useSelector } from 'react-redux';
import {  updateFlowsElements } from '../../../../../store/modules/flows/flowsActions';

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const MergeEditor = ({setEditMode}) => {
  const [editorState, setEditorState] = useState()
  const dispatch = useDispatch()
  const currentTransformer = useSelector(state => state.FlowsReducer.currentTransformer)
  const flowsElements = useSelector(state => state.FlowsReducer.flowsElements)
  const saveNoteClick = useSelector(state => state.FlowsReducer.saveNoteClick)


  
  
  
  
  
  const onEditorStateChange = (editorState) => {

    setEditorState(editorState)
  };

  useEffect(() => {
    if (saveNoteClick) {
      if (currentTransformer && currentTransformer.id) {

        let notes = [...flowsElements]

        if (notes.length) {
          let currentNoteIndex = notes.findIndex((ele) => ele.id === currentTransformer.id)
          if (currentNoteIndex > -1) {

            let data = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            notes[currentNoteIndex]['note'] = data;
          }
        }

        dispatch(updateFlowsElements(notes))
        setEditMode(false);
      }
    }
  }, [saveNoteClick])


  useEffect(() => {
    if (currentTransformer && currentTransformer.id) {
      let notes = [...flowsElements]


      let currentNote = notes.find((ele) => ele.id === currentTransformer?.id)
      if (currentNote && currentNote['note']) {
        const contentBlock = htmlToDraft(currentNote['note']);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );

        setEditorState(EditorState.createWithContent(contentState))
        

      } else {
        setEditorState('')

      }


    }
  }, [currentTransformer])
  const toolbarConfig = {
    options: ["inline", "textAlign", "link", "blockType", "list"],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ["bold", "italic", "underline"],
    },
    blockType: {
      inDropdown: true,
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code",
      ],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    list: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ["unordered", "ordered"],
    },
    textAlign: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ["justify", "right", "center", "left"],
    },
    link: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: true,
      defaultTargetOption: "_self",
      options: ["link"],
      linkCallback: undefined,
    },
  };

  return (
    <Editor
      editorState={editorState}
      toolbarClassName="bg-white custom-border-bottom padding-2"
      wrapperClassName="custom-text-editor"
      onEditorStateChange={onEditorStateChange}
      toolbar={toolbarConfig}
      width={`100%`}
      height={`100%`}
    />
  )
}

export default MergeEditor