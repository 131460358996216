import React, { useEffect, useState } from 'react';
import { CardBody, Collapse } from "reactstrap";
import '../../../src/components/modules/UserAuthentication/css/App.scss';
import eye_close from '../../assets/icons/Eye_close.svg';
import eye_open from '../../assets/icons/Eye_open.svg';
import { WHITECHECK } from './iconSource';


function PasswordPolicy({ passwordValue, 
    setPassword, 
    setprogressBarValue1, 
    loginClick, 
    login, 
    showLoading, 
    resendOTP, 
    updateProfile=false, 
    oldPassword, 
    setOldPassword,
    matchPassword, 
    code, 
    password, 
    showLoadingReset, 
    setconfirmPassword, 
    isPasswordMatch, 
    confirmPassword, 
    newPassword, 
    setNewPassword ,
    setReady = ()=>{}
}) {
        
    const [hasNumber, sethasNumber] = useState(false);
    const [hasUpperCase, sethasUpperCase] = useState(false)
    const [hasLowerCase, sethasLowerCase] = useState(false)
    const [hasSpecialCharacters, sethasSpecialCharacters] = useState(false)
    const [hasMinimumCharacters, sethasMinimumCharacters] = useState(false)
    const [progressBarValue, setprogressBarValue] = useState(0)
    const [status, setstatus] = useState('')
    const [progressBarColor, setprogressBarColor] = useState('');
    const [statusColor, setstatusColor] = useState('');
    const [showPassword, setShowPassword] = useState(false)



    const onChangeListener = (event) => {
        const value = event.target.value
        let localProgressBarValue = 0
        let hasNumberRegex = /[0-9]/;
        let hasUpperCaseRegex = /[A-Z]/;
        let hasLowerCaseRegex = /[a-z]/;
        let hasSpecialCharactersRegex = /[#?!@$%^&*-]/;
        let hasMinimumCharactersRegex = /.{8,}/
        if (value.match(hasNumberRegex) != null) {
            sethasNumber(true)
            localProgressBarValue += 20
        } else {
            sethasNumber(false)

        }

        if (value.match(hasUpperCaseRegex) != null) {
            sethasUpperCase(true)
            localProgressBarValue += 20
        } else {
            sethasUpperCase(false)
        }

        if (value.match(hasLowerCaseRegex) != null) {
            sethasLowerCase(true)
            localProgressBarValue += 20
        } else {
            sethasLowerCase(false)
        }

        if (value.match(hasSpecialCharactersRegex) != null) {
            sethasSpecialCharacters(true)
            localProgressBarValue += 20
        } else {
            sethasSpecialCharacters(false)

        }

        if (value.match(hasMinimumCharactersRegex) != null) {
            sethasMinimumCharacters(true)
            localProgressBarValue += 20
        } else {
            sethasMinimumCharacters(false)
        }

        switch (localProgressBarValue) {
            case 0:
                setstatus('Very Weak');
                setprogressBarColor('danger');
                setstatusColor('#F46A6A');

                break;
            case 20:
                setstatus('Very Weak')
                setprogressBarColor('danger')
                setstatusColor('#F46A6A');

                break;
            case 40:
                setstatus('Weak')
                setprogressBarColor('warning')
                setstatusColor('#F1B44C');

                break;
            case 60:
                setstatus('Average')
                setprogressBarColor('info')
                setstatusColor('#50A5F1');

                break;
            case 80:
                setstatus('Good')
                setprogressBarColor('success')
                setstatusColor('#34C38F');

                break;
            case 100:
                setstatus('Great')
                setprogressBarColor('primary')
                setstatusColor('#556EE6');

                break;
            default:
                break;
        }
        setprogressBarValue(localProgressBarValue);
        if(updateProfile) {
            setNewPassword(value)
        } else {
            setPassword(value);
        }
        
        // setprogressBarValue1(localProgressBarValue)
    }

    useEffect(()=>{
        if(
            hasNumber &&
            hasUpperCase &&
            hasLowerCase &&
            hasSpecialCharacters &&
            hasMinimumCharacters && isPasswordMatch) {
                setReady(true)
            }
    },[hasNumber,
        hasUpperCase,
        hasLowerCase,
        hasSpecialCharacters,
        hasMinimumCharacters,
        isPasswordMatch
    ])

    return (
        <React.Fragment>
            {!updateProfile ? <><div className="form-group mb-3 text-left">
                <label className="label label-color mb-1">Enter Password</label>
                <input type="password" placeholder="Enter Password" className={loginClick && !passwordValue ? "custom-input-field is-invalid form-control form-Fonts" : "custom-input-field form-control form-Fonts"} onChange={(e) => { onChangeListener(e) }} />
            </div></>: <div className="form-group mb-2 text-left">
                                <label htmlFor="exampleInputtext8" className="label label-color">Enter New Password again</label>
                                <input type="password" placeholder="Enter New Password" onChange={(e) => onChangeListener(e)} className={"custom-input-field form-control form-Fonts"} id="exampleInputtext5" />
                            </div>}
            <div className="form-group text-left">
                <label htmlFor="exampleInputtext8" className="label label-color mb-1">Confirm New Password</label>
                <span className="eye-indicator-wrap">
                    <input 
                    type={showPassword?"text":"password"} 
                    placeholder="Enter Confirm Password" 
                    onChange={(e) => setconfirmPassword(e.target.value)} 
                    className={(loginClick && !confirmPassword) || !isPasswordMatch ? "custom-input-field is-invalid form-control form-Fonts" : "custom-input-field form-control form-Fonts"} 
                    id="exampleInputtext5" />
                    <span className="pass-indicator" onClick={()=>setShowPassword(!showPassword)}>
                        <img src={ showPassword ? eye_open : eye_close} className="img-fluid" />
                    </span>
                </span>
                {isPasswordMatch  ? <div className="invalid-feedback">
                    Please provide a valid confirm password.
                </div> : <div className="invalid-feedback">
                    Password does not match.
                </div>}
            </div> 
            
            <div id="accordion">

                <Collapse isOpen={true}>

                    <CardBody className="col-lg-12 pl-0 pt-0 pb-0 pass-policy-container">
                        <div>
                            <p className="label-color" style={{fontSize:'12px'}}>In order to <span className="highlight-text">protect your account</span>, make sure your password has atleast:</p>
                        </div>

                        <div className="custom-control pl-0 d-flex form-check-success mb-1 ">
                            {!hasNumber ? <span className="custom-check-box unchecked"> </span> :
                            <span className="custom-check-box checked"><WHITECHECK /></span>}
                            <label className="label label-color" for="customCheckcolor1">one number</label>
                        </div>
                        <div className="custom-control pl-0 d-flex form-check-success mb-1 ">
                            {!hasUpperCase ? <span className="custom-check-box unchecked"> </span> :
                            <span className="custom-check-box checked"><WHITECHECK /></span>}
                            <label className="label label-color" for="customCheckcolor2">one uppercase character</label>
                        </div>
                        <div className="custom-control pl-0 d-flex form-check-success mb-1 ">
                            {!hasLowerCase ? <span className="custom-check-box unchecked"> </span> :
                            <span className="custom-check-box checked"><WHITECHECK /></span>}
                            <label className="label label-color" for="customCheckcolor3">one lowercase character</label>
                        </div>
                        <div className="custom-control pl-0 d-flex form-check-success mb-1 ">
                            {!hasSpecialCharacters ? <span className="custom-check-box unchecked"> </span> :
                            <span className="custom-check-box checked"><WHITECHECK /></span>}
                            <label className="label label-color" for="customCheckcolor4">one special character</label>
                        </div>
                        <div className="custom-control pl-0 d-flex form-check-success  mb-1 ">
                            {!hasMinimumCharacters ? <span className="custom-check-box unchecked"> </span> :
                            <span className="custom-check-box checked"><WHITECHECK /></span>}
                            <label className="label label-color" for="customCheckcolor5">8 characters minimum</label>
                        </div>
                    </CardBody>

                </Collapse>

                {!updateProfile?<><button onClick={() => { login(); matchPassword() }} type="button" className="btn mt-2 mb-3 custom-btn-primary auth-action-button btn-with-text btn-block" disabled={ !code || !password || !confirmPassword || showLoading || progressBarValue < 100}>{showLoading ? <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> : ''}Verify OTP</button>
                <div className="row justify-content-center" onClick={() => { resendOTP() }}>
                    <span className="" style={{ color: '#1F85DA', cursor: "pointer", fontWeight: 500 }} disabled={showLoadingReset}>{showLoadingReset ? <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> : ''}Resend</span>
                </div></> : ''}
            </div>
        </React.Fragment >
    )


}

export default PasswordPolicy
