import React from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Buttons from '../../Common/button/Buttons';
import { ICON_MIC_FILLED, ICON_MIC_MUTE } from '../../Common/newIconSource';
import { Literals } from '../common/literals';

const CopilotMic = ({
    inputRef = {},
    setValue = () => {},
    getListening = () => {},
    disabled
}) => {

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    React.useEffect(() => {
        // console.log({listening, transcript})
        getListening(listening)

        if (!listening && transcript !== "") {
            inputRef?.current?.setInputValue(transcript)
            setValue(transcript)

            if(inputRef?.current?.focus) {
                inputRef?.current?.focus()
            }

            inputRef?.current?.focusInput()
            
            // message.info({
            //     content: "Now Press Enter!"
            // })
            // message.config({
            //     top: 50,
            //     duration: 1
            // })

            resetTranscript();
        }
        if(listening) {
            inputRef?.current?.setInputValue("Speak Now, I'm Listening...")
            inputRef?.current?.setInputDisabled(true)
            setValue("Speak Now, I'm Listening...")
        }
        else {
            inputRef?.current?.setInputValue(transcript)
            inputRef?.current?.setInputDisabled(false)

            setTimeout(() => {
                inputRef?.current?.focusInput()
                
                if(inputRef?.current?.element) {
                    const range = document.createRange();
                    const sel = window.getSelection();
                    range.selectNodeContents(inputRef?.current?.element);
                    range.collapse(false); // Set to `true` if you want to move the cursor to the beginning
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            }, 1000)

            setValue(transcript)
        }
    }, [listening])

    return (
        <div className='cpilot-mic'>
            {
                !browserSupportsSpeechRecognition ? ''
                :
                <Buttons
                    props={{
                        buttonText: '',
                        tooltip: '',
                        buttonClassName: `${listening ? 'bx-flashing text-danger' : ''} custom-btn-outline custom-btn btn-with-icon btn-large bg-transparent`,
                        buttonEvent: (e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            inputRef?.current?.focusInput()
                            
                            if(!listening) {
                                SpeechRecognition.startListening()
                            }
                            else {
                                inputRef?.current?.setInputValue("")
                                setValue("")
                                SpeechRecognition.stopListening()
                            }
                        },
                        ImgSrc: () => 
                            listening
                            ?
                            <ICON_MIC_MUTE width='20' height='20' color='#dc3545' />
                            :
                            <ICON_MIC_FILLED width='20' height='20' color='#333333' />,
                        isDisable: disabled,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }}
                />
            }
        </div>
    )
}

export default CopilotMic