import React from 'react'
import CommonModal from '../../../Common/CommonModal/CommonModal'
import { Button, Input, Select, Space, Tooltip } from 'antd'
import { ICON_CLOSE, ICON_LINK, ICON_LOADING_CIRCLE, ICON_USER } from '../../../Common/newIconSource'
import { useDispatch } from 'react-redux'
import { shareChat, shareTracker, unshareChat, unshareTracker } from '../../../../store/modules/common/GenAi/GenAiReducer'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import copy from 'copy-to-clipboard';

const ShareChatModal = ({
    chatId,
    open = false,
    setOpen = () => {},
    usersList = [],
    usersLoading = false,
    sharedUsers = [],
    setSharedUsers = () => {},
    title = '',
    onClose = () => {},
    isTracker = false
}) => {

    const dispatch = useDispatch()

    const [selectedUsers, setSelectedUsers] = React.useState([])
    const [shareMessage, setShareMessage] = React.useState('')
    const [shareLoader, setShareLoader] = React.useState(false)
    const [unshareLoader, setUnshareLoader] = React.useState(false)
    const [urlCopied, setUrlCopied] = React.useState(false)

    const unsharingUserNameRef = React.useRef('')
    const location =  window?.location?.href

    React.useEffect(() => {
        if (urlCopied) {
            setTimeout(() => setUrlCopied(false), 3000)
        }
    }, [urlCopied])

    const userOptions = React.useMemo(() => {
        let list = usersList?.map(v => ({label: `${v?.name} (${v?.email})`, value: v?.value}))
        if(sharedUsers?.length > 0) {
            list = list.filter(u => !sharedUsers?.includes(u?.value))
        }
        return list
    }, [usersList, sharedUsers])

    const handleShare = () => {
        if(!chatId) return
        if(selectedUsers?.length === 0) {
            emitToastNotification('error', 'No User(s) selected')
            return
        }
        const payload = {
            user_id: selectedUsers,
            message: shareMessage
        }
        setShareLoader(true)
        dispatch(
            isTracker
            ?
            shareTracker(chatId, payload)
            :
            shareChat(chatId, payload)
            )
        .then(() => {
            setSharedUsers(prev => [...prev, ...selectedUsers])
            setShareLoader(false)
            setSelectedUsers([])
        })
        .catch((err) => {
            if(err?.status === 403) {
                emitToastNotification('error', err?.data?.message)
            }
            setShareLoader(false)
        })
    }

    const handleUnshare = (user_id, name) => {
        unsharingUserNameRef.current = name
        setUnshareLoader(true)
        dispatch(
            isTracker
            ?
            unshareTracker(chatId, user_id)
            :
            unshareChat(chatId, user_id)
            )
        .then(() => {
            setSharedUsers(prev => {
                prev = prev?.filter(u => u !== user_id)
                return prev
            })
            setUnshareLoader(false)
        })
        .catch(() => {
            setUnshareLoader(false)
        })
    }

    return (
        <>
            <CommonModal
                open={open}
                setOpen={setOpen}
                size='lg'
                onClose={() => {
                    setSelectedUsers([])
                    onClose()
                }}
                header={title}
                body={
                    <>
                        <div>
                            <div className='mb-3'>

                                <span className='fontSizeHeading fontInterSemiBold text-dark mb-2 d-block'>
                                    Users
                                </span>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <Select
                                        loading={usersLoading}
                                        disabled={usersLoading || shareLoader || unshareLoader}
                                        className='chat-share-mselect'
                                        size='large'
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: 'calc(100% - 96px)',
                                        }}
                                        placeholder="Choose Users"
                                        options={userOptions}
                                        value={selectedUsers}
                                        // value={usersList?.filter(u => selectedUsers?.includes(u?.value))?.map(v => v?.value)}
                                        onChange={(val) => {
                                            setSelectedUsers([...val])
                                        }}
                                        filterOption={(input, option) => {
                                            const name = userOptions?.find(d => d?.value === option?.value)?.label ?? ''
                                            return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                        }
                                        }
                                        filterSort={(optionA, optionB) => {
                                                const nmA = userOptions?.find(d => d?.value === optionA?.value)?.label ?? ''
                                                const nmB = userOptions?.find(d => d?.value === optionB?.value)?.label ?? ''
                                                return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                                            }
                                        }
                                    />

                                    <Button 
                                        style={{
                                            background: shareLoader ? '#FFF' : '#275A89',
                                            borderRadius: 3
                                        }} 
                                        type="primary" 
                                        size={'large'}
                                        onClick={handleShare}
                                        disabled={shareLoader || unshareLoader}
                                        icon={
                                            shareLoader
                                            ?
                                            <ICON_LOADING_CIRCLE height='24' width='24' color='currentColor' />
                                            :   <></>
                                        }
                                    >
                                        Share
                                    </Button>
                                </div>
                            </div>
                            {
                                sharedUsers?.length > 0
                                ?
                                <div className='pr-2 mb-2' style={{maxHeight: 166, overflowY: 'auto'}}>
                                    {
                                        sharedUsers?.map(u => {
                                            const user = usersList?.find(el => el?.value === u)
                                            
                                            return (
                                                <div key={`${u.id}-sharedUsrs`} className='px-2 py-2 card mb-1 position-relative custom-btn-outline border'>
                                                    <p className='mb-0 fontSizeHeading' style={{width: 'calc(100% - 100px)'}}>
                                                        <ICON_USER height='14' width='14' />
                                                        <span className='p-1'></span>
                                                        {`${user?.name} (${user?.email})`}
                                                    </p>
                                                    <span className='position-absolute' style={{right: 5, top: 5}}>
                                                        <Buttons
                                                            props={{
                                                                buttonText: '',
                                                                tooltip: 'Unshare',
                                                                buttonClassName: "custom-btn-outline error-red custom-btn btn-with-icon",
                                                                buttonEvent: (e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    handleUnshare(u, user?.name ?? '')
                                                                },
                                                                ImgSrc: () => <ICON_CLOSE />,
                                                                isDisable: unshareLoader,
                                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </div>
                                :   ''
                            }
                            {
                                unshareLoader
                                ?
                                <div className='px-2 py-1 text-danger fontSizeLabel'>
                                    <ICON_LOADING_CIRCLE height='12' width='12' color='currentColor' />
                                    <span className='p-1'></span>
                                    Removing {unsharingUserNameRef?.current}...
                                </div>
                                :   ''
                            }
                            <div className='mb-3'>
                                <span className='fontSizeHeading fontInterSemiBold text-dark mb-2 d-block'>
                                    Message
                                </span>
                                <div className='pb-2'>
                                    <Input.TextArea 
                                        rows={4} 
                                        placeholder="Write a message" 
                                        maxLength={2000} 
                                        showCount
                                        style={{
                                            resize: 'none',
                                            borderRadius: 3
                                        }}
                                        value={shareMessage}
                                        onChange={(e) => {
                                            setShareMessage(e?.target?.value)
                                        }}
                                        disabled={shareLoader}
                                        className='py-2 chat-share-msg-textarea'
                                    />
                                </div>
                            </div>
                            <div>
                                <Space.Compact
                                    style={{
                                        width: '100%',
                                    }}
                                    >
                                    <Input 
                                        value={location}
                                        size='large'
                                    />
                                    {/* <Tooltip title="Url Copied!" placement='right' trigger={'click'} open={urlCopied}> */}
                                        <Button 
                                            type={'default'}
                                            icon={
                                                <ICON_LINK height='24' width='24' color='currentColor' />
                                            }
                                            className='fontInterSemiBold'
                                            size='large'
                                            onClick={() => {
                                                copy(location)
                                                setTimeout(() => {
                                                    setUrlCopied(true)
                                                }, 300)
                                            }}
                                        >
                                            {
                                                urlCopied ? 'Copied!' : 'Copy Link'
                                            }
                                        </Button>
                                    {/* </Tooltip> */}
                                </Space.Compact>
                            </div>
                        </div>
                    </>
                }
                footer=''
            />
        </>
    )
}

export default ShareChatModal