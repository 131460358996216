import { cloneDeep, isNumber, reject, set } from "lodash";
import { setFlowsCurrenttransformer, updateFlowsElements } from "../../../../../store/modules/flows/NewFlowsRedux/flowsActions";
import { ICON_EYEOPEN, ICON_PLAY, ICON_SETTINGS } from "../../../../Common/newIconSource";
import { CHECKMARKCIRCLE, DELETEICON, DISMISSCIRCLE, ERRORCIRCLE, LOADINGCIRCLE, LOADINGCIRCLEQUEUE, STOPOCTAGON } from "../../../../Common/iconSource";
import { executeTransformerApi, getOutcomeColumns, saveFlow } from "../../../../../store/modules/flows/NewFlowsRedux/flowsActions";
import { v4 as uuidv4 } from "uuid";
import { getObjectLength, permissions } from "../../../common/helperFunctions";
import { SET_TRANSFORMER_PROGRESS } from "../../../../../store/modules/flows/NewFlowsRedux/flowsTypes";
import { setTransformerNames } from "../../../../../store/modules/flows/flowsActions";

export const getInputTransformerDetails = () => {
    return (dispatch, getState) => {
        let currentTransformer = getState().Lab?.currentTransformer;
        let flowsElements = getState().Lab?.flowsElements;

        if (flowsElements?.nodes?.length && currentTransformer?.id) {
            let InputTransformer = [];
            let edges = flowsElements?.edges.map(
                (item) => {
                    if (item?.target?.toString() === currentTransformer.id?.toString()) {
                        return item?.source;
                    }
                }
            ).filter((item) => item);

            if (edges?.length) {
                edges?.forEach((item) => {
                    let findElement = flowsElements?.nodes?.find((ele) => ele?.id?.toString() === item?.toString());
                    if (getObjectLength(findElement)) {
                        InputTransformer.push(findElement);
                    }
                })
            }


            return InputTransformer;

        }
    }
}

export const checkOutcomeColumnPresent = (currentTransformer) => {

    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            let newStatusInterval = setInterval(async () => {
                let transformerProgress =
                    getState().Lab.transformerProgress;

                let currentStatus = "";


                if (transformerProgress && transformerProgress.length) {
                    currentStatus = transformerProgress.find((element) => {
                        return element.crnt_transformer === currentTransformer.id;
                    })?.state;

                }

                // if (currentStatus?.toLowerCase() === "success") {
                clearInterval(newStatusInterval);

                let outcomeColumns = [];

                let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')

                if (currentTransformer?.type?.toLocaleLowerCase() !== 'script') {
                    outcomeColumns = await dispatch(getPreviewResultColumns({
                        podName: currentTransformer?.transformerName,
                        dataDomain: `flow_${getState()?.Lab?.id}`,
                        connectionName: GlobalConnectionCache
                            ? GlobalConnectionCache
                            : "application_cache",
                        podIds: currentTransformer?.type === 'datasource' ? [currentTransformer.content.table_id] : []
                    }));
                    let flowsElements = cloneDeep(getState().Lab.flowsElements);
                    let nodes = flowsElements?.nodes.map((item) => {
                        if (item.id === currentTransformer.id) {
                            item["content"]["outcomeColumns"] = [...outcomeColumns];

                        }
                        return item;
                    });
                    flowsElements['nodes'] = nodes;
                    dispatch(updateFlowsElements(flowsElements));
                    return resolve(outcomeColumns)

                } else {
                    let outputdatasetList = currentTransformer?.content?.outputdatasetList;
                    if (outputdatasetList?.length) {
                        outputdatasetList?.forEach(async (outputNode) => {
                            outcomeColumns = await dispatch(getPreviewResultColumns({
                                podName: outputNode?.value,
                                dataDomain: `flow_${getState()?.Lab?.id}`,
                                connectionName: GlobalConnectionCache
                                    ? GlobalConnectionCache
                                    : "application_cache",
                                podIds: []
                            }));
                            let flowsElements = cloneDeep(getState().Lab.flowsElements);
                            let nodes = flowsElements?.nodes.map((item) => {
                                if (item.id === outputNode.id) {
                                    item["content"]["outcomeColumns"] = [...outcomeColumns];

                                }
                                return item;
                            });
                            flowsElements['nodes'] = nodes;
                            dispatch(updateFlowsElements(flowsElements));
                            return resolve(outcomeColumns)

                        })
                    }
                }

                // } else {
                //     return resolve([])
                // }
            }, 500);

        })
    }


}

export const setTransformerNameinflowselement = (transformerName) => {
    return (dispatch, getState) => {
        let currentTransformer = getState().Lab?.currentTransformer;
        let flowsElements = cloneDeep(getState().Lab?.flowsElements);

        if (flowsElements?.nodes.length && currentTransformer?.id) {

            flowsElements?.nodes.forEach(
                (item) => {
                    if (item?.id === currentTransformer.id) {
                        item['transformerName'] = transformerName;

                    }
                }
            );
            dispatch(updateFlowsElements(flowsElements))

            currentTransformer['transformerName'] = transformerName;
            dispatch(setFlowsCurrenttransformer(currentTransformer));
        }
    }
}

export const getTransformerNameList = () => {
    return (dispatch, getState) => {
        let flowsElements = cloneDeep(getState().Lab?.flowsElements);
        if (flowsElements?.nodes.length) {
            let transformerNameList = {}
            flowsElements?.nodes.forEach(
                (item) => {
                    transformerNameList[item.id] = item.transformerName;

                }
            );
            dispatch(setTransformerNames(transformerNameList))
            return transformerNameList

        }
    }
}
export const isTransformerNameAlreadyPresent = (debounseSearch) => {
    return (dispatch, getState) => {

        let flowsElements = cloneDeep(getState().Lab?.flowsElements);
        let currentTransformer = cloneDeep(getState().Lab?.currentTransformer);
        let isTransformerNameExist = false


        if (flowsElements?.nodes.length) {

            flowsElements?.nodes.forEach(
                (item) => {

                    if (item?.transformerName === debounseSearch && item.id !== currentTransformer?.id) {

                        isTransformerNameExist = true;
                    }
                }
            );

        }
        return isTransformerNameExist
    }
}

export const getInputFrame = () => {
    return (dispatch, getState) => {
        let currentTransformer = getState().Lab?.currentTransformer;

        let Frame = "";
        let currentTransformerType = currentTransformer?.type;
        let inputTransformer = dispatch(getInputTransformerDetails())
        if (currentTransformerType !== 'sql' && currentTransformerType !== 'script' && currentTransformerType !== 'databricks') {

            if (inputTransformer && inputTransformer?.length) {

                let podType = inputTransformer[0]?.type;
                let transformerName = inputTransformer[0]?.transformerName;

                if (podType.includes("datasource")) {
                    let podDetails = getState().Lab.dataSourcePodDetails;

                    if (podDetails.length) {
                        let pod = podDetails.find((item) => Object.keys(item)[0] === inputTransformer[0]?.id);

                        if (pod) {
                            Frame = `${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName
                                }`;
                        }
                    }
                } else if (podType.includes("scriptOutput")) {
                    // let scriptOutputDataSet =
                    //     getState().Flows.Script.scriptOutputDataset;

                    // if (scriptOutputDataSet && scriptOutputDataSet.length) {
                    //     Frame = scriptOutputDataSet.find((item) => item.id === inputTransformer[0]?.id)?.value;
                    // } else {
                    Frame = inputTransformer[0]?.id.split("|")[1];
                    // }
                } else {
                    Frame = transformerName;
                }

                return Frame;
            } else {
                return ''
            }


        } else {
            let Frame = [];
            if (inputTransformer && inputTransformer.length) {
                inputTransformer.forEach((ele) => {
                    let podType = ele?.type;
                    let transformerName = ele?.transformerName;

                    if (podType.includes("datasource")) {
                        // let podDetails = getState().Lab.dataSourcePodDetails;

                        // if (podDetails.length) {
                        //     let pod = podDetails.find((item) => Object.keys(item)[0] === ele?.id);

                        //     if (pod) {
                        //         Frame.push(
                        //             `${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName
                        //             }`
                        //         );
                        //     }
                        // }
                        Frame.push(transformerName);
                    } else if (podType.includes("scriptOutput")) {

                        Frame.push(ele?.id?.split("|")[1]);

                    } else {
                        Frame.push(transformerName);
                    }

                });
                return Frame
            } else {
                return Frame
            }

        }
    }
};

const getUUID = () => {
    let id = uuidv4();

    return id;
};

export const createActivePodDetails = () => {
    return (dispatch, getState) => {

        let currentTransformerInputList = [];

        let flowsElements = cloneDeep(getState().Lab?.flowsElements);
        let currentTransformer = cloneDeep(getState().Lab?.currentTransformer);

        if (currentTransformer && currentTransformer.id && flowsElements?.nodes?.length) {
            let inputSource = dispatch(getInputTransformerDetails())
            let flowId = getState()?.Lab?.id
            let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache');
            if (currentTransformer?.type !== 'datasource') {
                if (inputSource?.length) {
                    inputSource.forEach((item) => {
                        if (item?.type === "target") {
                            currentTransformerInputList.push({ transformerId: item.id, podName: `${item?.content?.targetTableDetails?.tableName ?? ''}`, dataDomain: item?.content?.targetTableDetails.schemaName, connectionName: item?.content?.connectionDetails?.connection_name ?? '', transformerName: item?.transformerName, transformerType: item?.type, podId: item?.content?.table_id ?? '', from: 'flows' })
                        } else if (item?.type !== "script") {
                            currentTransformerInputList.push({ transformerId: item.id, podName: item?.type === 'datasource' ? `${item.transformerName?.split('.')[1]}` : `${item.transformerName}`, dataDomain: item?.type === 'datasource' ? `${item.transformerName?.split('.')[0]}` : `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: item.transformerName, transformerType: item.type, podId: item.content?.table_id ?? '', from: 'flows' })

                        } else if (item?.type !== "scriptOutput") {

                            currentTransformerInputList.push({ transformerId: item.id, podName: item?.type === 'datasource' ? `${item.transformerName?.split('.')[1]}` : `${item?.transformerName}`, dataDomain: item?.type === 'datasource' ? `${item.transformerName?.split('.')[0]}` : `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: item?.transformerName, transformerType: item?.type, podId: item?.content?.table_id ?? '', from: 'flows' })
                        }
                    })
                }
            }

        }


        return currentTransformerInputList


    }
};

export const createActivePodDetailsCurrentTransformer = () => {
    return (dispatch, getState) => {

        let currentTransformerInputList = [];

        let currentTransformerClone = cloneDeep(getState().Lab?.currentTransformer);
        let flowsElements = getState().Lab?.flowsElements
        let currentTransformer = {}

        if (flowsElements?.nodes?.length) {

            currentTransformer = flowsElements?.nodes?.find((ele) => ele.id?.toString() === currentTransformerClone?.id?.toString())
        }

        if (currentTransformer && currentTransformer.id) {
            let flowId = getState()?.Lab?.id
            let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache');
            if (currentTransformer?.type === "target") {
                currentTransformerInputList.push({ transformerId: currentTransformer.id, podName: `${currentTransformer?.content?.targetTableDetails?.tableName ?? ''}`, dataDomain: currentTransformer?.content?.targetTableDetails?.schemaName ?? '', connectionName: currentTransformer?.content?.connectionDetails?.connection_name ?? '', transformerName: currentTransformer?.transformerName, transformerType: currentTransformer?.type, podId: currentTransformer?.content?.table_id ?? '', from: 'flows' })
            } else if (currentTransformer?.type !== "script") {
                currentTransformerInputList.push({ transformerId: currentTransformer.id, podName: currentTransformer?.type === 'datasource' ? `${currentTransformer.transformerName?.split('.')[1]}` : `${currentTransformer?.transformerName}`, dataDomain: currentTransformer?.type === 'datasource' ? `${currentTransformer.transformerName?.split('.')[0]}` : `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: currentTransformer?.transformerName, transformerType: currentTransformer?.type, podId: currentTransformer?.content?.table_id ?? '', from: 'flows' })
            } else if (currentTransformer?.type === "script") {
                let outputdatasetList = currentTransformer?.content?.outputdatasetList;
                if (outputdatasetList?.length) {
                    outputdatasetList?.forEach((outputNode) => {
                        currentTransformerInputList.push({ transformerId: outputNode?.id, podName: `${outputNode?.value}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: outputNode?.value, transformerType: 'scriptOutput', podId: '', from: 'flows' })

                    })
                }
            }

        }
        return currentTransformerInputList
    }
};
export const getPreviewResultColumns = (podDetails) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            if (podDetails) {
                let request = {
                    uuid: getUUID(),
                    connection_name: podDetails.connectionName,
                    table_name: podDetails.podName,
                    page: 0,
                    limit: 1,
                    schema_name: podDetails.dataDomain,
                    do_count: false,
                    sort_body: [],
                    filter_body: [],
                    table_ids: podDetails.podIds,
                };
                return dispatch(getOutcomeColumns(request)).then((response) => {
                    if (response?.status === "success" && response?.data) {
                        let responseData = response.data?.result;

                        if (responseData && responseData?.length) {
                            let outcomeColumns = [];
                            responseData.forEach((item, index) => {
                                outcomeColumns.push({
                                    id: `${item?.Name}_${index}`,
                                    Name: item.Name,
                                    Type: item.Type,
                                    checked: false,
                                });
                            });
                            if (outcomeColumns?.length) {
                                resolve(outcomeColumns);
                            } else {
                                // return [];
                                resolve([])
                            }
                        }
                    } else {
                        // return [];
                        resolve([])
                    }

                }).catch((err) => {
                    resolve([])
                });
            }
        })
    }
};

export const RunTransformer = (clickfrom = '') => {
    return (dispatch, getState) => {
        let flowsElements = cloneDeep(getState().Lab?.flowsElements)

        let currentTransformerClone = cloneDeep(getState().Lab?.currentTransformer);
        let task_name = ''
        let currentTransformer = {}
        if (flowsElements?.nodes?.length) {

            currentTransformer = flowsElements?.nodes?.find((ele) => ele.id?.toString() === currentTransformerClone?.id?.toString())

            if (currentTransformer?.transformerName) {

                task_name = currentTransformer?.transformerName
            }
        }
        return new Promise((resolve, reject) => {

            dispatch(executeTransformerApi(getState().Lab?.id, clickfrom === 'entireflow' ? undefined : task_name, currentTransformer?.id)).then((executeResp) => {
                if (executeResp?.status === 'success') {
                    let newStatusInterval = setInterval(async () => {
                        let transformerProgress =
                            getState().Lab.transformerProgress;

                        let currentStatus = "";


                        if (transformerProgress && transformerProgress.length) {
                            currentStatus = transformerProgress.find((element) => {
                                return element.crnt_transformer === currentTransformer.id;
                            })?.state;

                        }

                        if (currentStatus?.toLowerCase() === "success") {
                            clearInterval(newStatusInterval);

                            let outcomeColumns = [];

                            let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')

                            if (currentTransformer?.type?.toLocaleLowerCase() !== 'script' && currentTransformer?.type?.toLocaleLowerCase() !== 'target') {
                                outcomeColumns = await dispatch(getPreviewResultColumns({
                                    podName: currentTransformer?.transformerName,
                                    dataDomain: `flow_${getState()?.Lab?.id}`,
                                    connectionName: GlobalConnectionCache
                                        ? GlobalConnectionCache
                                        : "application_cache",
                                    podIds: currentTransformer?.type === 'datasource' ? [currentTransformer.content.table_id] : []
                                }));
                                let flowsElements = cloneDeep(getState().Lab.flowsElements);
                                let nodes = flowsElements?.nodes.map((item) => {
                                    if (item.id === currentTransformer.id) {
                                        item["content"]["outcomeColumns"] = [...outcomeColumns];

                                    }
                                    return item;
                                });
                                flowsElements['nodes'] = nodes;
                                dispatch(updateFlowsElements(flowsElements));

                            } else if (currentTransformer?.type?.toLocaleLowerCase() !== 'target') {
                                let outputdatasetList = currentTransformer?.content?.outputdatasetList;
                                if (outputdatasetList?.length) {
                                    outputdatasetList?.forEach(async (outputNode) => {
                                        outcomeColumns = await dispatch(getPreviewResultColumns({
                                            podName: outputNode?.value,
                                            dataDomain: `flow_${getState()?.Lab?.id}`,
                                            connectionName: GlobalConnectionCache
                                                ? GlobalConnectionCache
                                                : "application_cache",
                                            podIds: []
                                        }));
                                        let flowsElements = cloneDeep(getState().Lab.flowsElements);
                                        let nodes = flowsElements?.nodes.map((item) => {
                                            if (item.id === outputNode.id) {
                                                item["content"]["outcomeColumns"] = [...outcomeColumns];

                                            }
                                            return item;
                                        });
                                        flowsElements['nodes'] = nodes;
                                        dispatch(updateFlowsElements(flowsElements));


                                    })
                                }

                            }
                        } else if (
                            currentStatus?.toLowerCase() === "failed" ||
                            currentStatus?.toLocaleLowerCase() === "error"
                        ) {
                            let flowsElements = cloneDeep(getState().Lab.flowsElements);
                            let nodes = flowsElements?.nodes.map((item) => {
                                if (item.id === currentTransformer.id) {
                                    item["content"]["outcomeColumns"] = [];

                                }
                                return item;
                            });
                            flowsElements['nodes'] = nodes;
                            dispatch(updateFlowsElements(flowsElements));
                            clearInterval(newStatusInterval);
                        }
                    }, 1000);

                }
                resolve('success')
            }).catch((err) => {
                reject('error')
            })

        })
    }

}

export const ExecuteTransformer = (clickfrom = '', canvasImage = undefined) => {

    return (dispatch, getState) => {
        let flowsElements = cloneDeep(getState().Lab?.flowsElements)

        let currentTransformerClone = cloneDeep(getState().Lab?.currentTransformer);
        let task_name = ''
        let currentTransformer = {}
        if (flowsElements?.nodes?.length) {

            currentTransformer = flowsElements?.nodes?.find((ele) => ele.id?.toString() === currentTransformerClone?.id?.toString())

            if (currentTransformer?.transformerName) {

                task_name = currentTransformer?.transformerName
            }
        }
        return new Promise((resolve, reject) => {
            dispatch(saveFlow(getState().Lab?.id, clickfrom !== 'menuClick' ? false : false, false, canvasImage)).then((saveFlowresp) => {
                if (saveFlowresp?.status === 'success') {
                    dispatch(executeTransformerApi(getState().Lab?.id, clickfrom === 'entireflow' ? undefined : task_name, currentTransformer?.id)).then((executeResp) => {
                        if (executeResp?.status === 'success') {
                            let newStatusInterval = setInterval(async () => {
                                let transformerProgress =
                                    getState().Lab.transformerProgress;

                                let currentStatus = "";


                                if (transformerProgress && transformerProgress.length) {
                                    currentStatus = transformerProgress.find((element) => {
                                        return element.crnt_transformer === currentTransformer?.id;
                                    })?.state;

                                }

                                if (currentStatus?.toLowerCase() === "success") {
                                    clearInterval(newStatusInterval);

                                    let outcomeColumns = [];

                                    let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')

                                    if (currentTransformer?.type?.toLocaleLowerCase() !== 'script' && currentTransformer?.type?.toLocaleLowerCase() !== 'target') {
                                        outcomeColumns = await dispatch(getPreviewResultColumns({
                                            podName: currentTransformer?.transformerName,
                                            dataDomain: `flow_${getState()?.Lab?.id}`,
                                            connectionName: GlobalConnectionCache
                                                ? GlobalConnectionCache
                                                : "application_cache",
                                            podIds: currentTransformer?.type === 'datasource' ? [currentTransformer.content.table_id] : []
                                        }));
                                        let flowsElements = cloneDeep(getState().Lab.flowsElements);
                                        let nodes = flowsElements?.nodes.map((item) => {
                                            if (item.id === currentTransformer.id) {
                                                item["content"]["outcomeColumns"] = [...outcomeColumns];

                                            }
                                            return item;
                                        });
                                        flowsElements['nodes'] = nodes;
                                        dispatch(updateFlowsElements(flowsElements));

                                    } else if (currentTransformer?.type?.toLocaleLowerCase() !== 'target') {
                                        let outputdatasetList = currentTransformer?.content?.outputdatasetList;
                                        if (outputdatasetList?.length) {
                                            outputdatasetList?.forEach(async (outputNode) => {
                                                outcomeColumns = await dispatch(getPreviewResultColumns({
                                                    podName: outputNode?.value,
                                                    dataDomain: `flow_${getState()?.Lab?.id}`,
                                                    connectionName: GlobalConnectionCache
                                                        ? GlobalConnectionCache
                                                        : "application_cache",
                                                    podIds: []
                                                }));
                                                let flowsElements = cloneDeep(getState().Lab.flowsElements);
                                                let nodes = flowsElements?.nodes.map((item) => {
                                                    if (item.id === outputNode.id) {
                                                        item["content"]["outcomeColumns"] = [...outcomeColumns];

                                                    }
                                                    return item;
                                                });
                                                flowsElements['nodes'] = nodes;
                                                dispatch(updateFlowsElements(flowsElements));


                                            })
                                        }

                                    }
                                } else if (
                                    currentStatus?.toLowerCase() === "failed" ||
                                    currentStatus?.toLocaleLowerCase() === "error"
                                ) {
                                    let flowsElements = cloneDeep(getState().Lab.flowsElements);
                                    let nodes = flowsElements?.nodes.map((item) => {
                                        if (item.id === currentTransformer.id) {
                                            item["content"]["outcomeColumns"] = [];

                                        }
                                        return item;
                                    });
                                    flowsElements['nodes'] = nodes;
                                    dispatch(updateFlowsElements(flowsElements));
                                    clearInterval(newStatusInterval);
                                }
                            }, 1000);

                        }
                        resolve('success')
                    }).catch((err) => {
                        reject('error')
                    })
                }

            }).catch((e) => {
                reject('saveFlowFail')
            })
        })
    }


}

export const getStatus = () => {
    return (dispatch, getState) => {
        let transformerProgress = getState().Lab.transformerProgress;
        let currentTransformer = getState().Lab.currentTransformer;
        let executeEntireFlow = getState().Lab.executeEntireFlowStart;
        if (transformerProgress && transformerProgress.length) {
            let currentStatus = ''
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === currentTransformer?.id
            })?.state;

            if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'starting' || currentStatus?.toLowerCase() === 'running')) {
                return <span><LOADINGCIRCLE className='bx-spin' /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'success' || currentStatus?.toLowerCase() === 'done' || currentStatus?.toLowerCase() === 'finished')) {
                return <CHECKMARKCIRCLE />
            }
            else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error')) {
                return <DISMISSCIRCLE />
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'queued')) {
                return <span className='bx-spin'><LOADINGCIRCLEQUEUE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'aborted')) {
                return <span className=''> <STOPOCTAGON /></span>
            }
            else {
                return <ERRORCIRCLE />
            }

        }
        else if (executeEntireFlow && !transformerProgress.length) {
            return <ERRORCIRCLE />//<span className='bx-spin d-flex'><LOADINGCIRCLE /></span>

        } else if (!transformerProgress.length && !executeEntireFlow) {
            return <ERRORCIRCLE />
        }

    }


}

export const getTextClass = () => {
    return (dispatch, getState) => {
        let transformerProgress = getState().Lab.transformerProgress;
        let currentTransformer = getState().Lab.currentTransformer;
        let flowsElements = getState().Lab.flowsElements;
        let content = flowsElements?.nodes?.find((item) => item.id?.toString() === currentTransformer?.id?.toString())?.content
        let executeEntireFlow = getState().Lab.executeEntireFlowStart;

        if (!getObjectLength(content)) {
            return 'text-yellow'
        }
        if (transformerProgress && transformerProgress.length) {
            let currentStatus = ''
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer?.toString() === currentTransformer?.id?.toString()
            })?.state;

            if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'starting' || currentStatus?.toLowerCase() === 'running')) {
                return 'text-blue'
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'success' || currentStatus?.toLowerCase() === 'done' || currentStatus?.toLowerCase() === 'finished')) {
                return 'text-green'
            }
            else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'queued')) {
                return 'text-blue'
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'failed') || (currentStatus?.toLowerCase() === 'error')) {
                return 'text-red'
            }  else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'initial')) {
                return 'text-yellow'
            }else{

            }

        }



    }
}
export const getStatusTooltipMsg = () => {
    return (dispatch, getState) => {
        let transformerProgress = getState().Lab.transformerProgress;
        let currentTransformer = getState().Lab.currentTransformer;
        let executeEntireFlow = getState().Lab.executeEntireFlowStart;
        if (transformerProgress && transformerProgress.length) {
            let currentStatus = ''
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === currentTransformer?.id
            })?.state;

            let error_msg = transformerProgress.find(element => {
                return element.crnt_transformer === currentTransformer?.id
            })?.error_msg;

            if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'starting' || currentStatus?.toLowerCase() === 'running')) {
                return 'Transformer Running'
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'success' || currentStatus?.toLowerCase() === 'done' || currentStatus?.toLowerCase() === 'finished')) {
                return 'Transformer Executed'
            }
            else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error')) {
                return error_msg ?? 'Transformer Failed'
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'queued')) {
                return 'Transformer Queued'
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'aborted')) {
                return 'Transformer Aborted'
            }
            else {
                return 'Transformer not executed'
            }

        }
        else if (executeEntireFlow && !transformerProgress.length) {
            return 'Transformer not executed'//<span className='bx-spin d-flex'><LOADINGCIRCLE /></span>

        } else if (!transformerProgress.length && !executeEntireFlow) {
            return 'Transformer not executed'
        }

    }


}

export const isTransformerNamePresent = (transformerId) => {
    return (dispatch, getState) => {

        let flowsElements = cloneDeep(getState().Lab?.flowsElements);
        return flowsElements?.nodes?.find((item) => item.id?.toString() === transformerId?.toString())?.transformerName !== '' ? true : false

    }
}


export const getTransformerName = (transformerId) => {
    return (dispatch, getState) => {
        let flowsElements = cloneDeep(getState().Lab?.flowsElements);

        return flowsElements?.nodes?.find((item) => item.id?.toString() === transformerId?.toString())?.transformerName;


    }
}

export const orderRetainCheck = (orderList, mergeList) => {
    let cloneMergeList;
    if (orderList?.length) {
        cloneMergeList = mergeList.sort((a, b) => {
            const indexA = orderList.findIndex(item => item?.Name === a?.Name);
            const indexB = orderList.findIndex(item => item?.Name === b?.Name);

            // Handle cases where the element is not in the preferred order
            if (indexA === -1 && indexB === -1) {
                return 0; // No change in order
            } else if (indexA === -1) {
                return 1; // Move element A to the end
            } else if (indexB === -1) {
                return -1; // Move element B to the end
            }

            return indexA - indexB;
        });


    }


    if (orderList?.length) {
        orderList?.forEach((item, Index) => {
            if (item?.id?.toString()?.includes("emptyColumn")) {
                cloneMergeList.splice(Index, 0, item)
            }
        })
    }

    if (cloneMergeList?.length) {

        return cloneMergeList;
    } else {
        return mergeList
    }
}

export const orderRetainMergeCheck = (orderList, mergeList) => {
    let cloneMergeList;
    if (orderList?.length && mergeList?.length) {
        cloneMergeList = mergeList?.sort((a, b) => {
            const indexA = orderList.findIndex(item => item?.id === a?.id);
            const indexB = orderList.findIndex(item => item?.id === b?.id);

            // Handle cases where the element is not in the preferred order
            if (indexA === -1 && indexB === -1) {
                return 0; // No change in order
            } else if (indexA === -1) {
                return 1; // Move element A to the end
            } else if (indexB === -1) {
                return -1; // Move element B to the end
            }

            return indexA - indexB;
        });


    }

    if (orderList?.length) {
        orderList?.forEach((item, Index) => {
            if (item?.id?.toString()?.includes("emptyColumn")) {
                cloneMergeList.splice(Index, 0, item)
            }
        })
    }

    if (cloneMergeList?.length) {

        return cloneMergeList;
    } else {
        return mergeList
    }
}
const flowsPermissions = permissions.flows

export const checkIsEditorFlow = () => {
    return (dispatch, getState) => {
        let flowDataPermission = cloneDeep(getState().Lab?.flowsPermissions);
        const isRoleEditorIndex = flowDataPermission === undefined ? false : flowDataPermission?.findIndex(v => v === flowsPermissions.editor)

        if (isRoleEditorIndex > -1) return true
        return false
    }
}

export const transformerMenuItem = (onRunClick = () => { }, onConfigureClick = () => { }, onPreviewClick = () => { }, onDeleteClick = () => { }, isEditPermission = true) => {
    return (dispatch, getState) => {
        // console.log(dispatch(getTextClass()))
        let optionList = [
            {
                label: <div className={`dbox-opt-itm w-100`}
                    onClick={(e) => {

                        if (dispatch(getTextClass()) !== 'text-blue' && dispatch(getTextClass()) !== 'text-yellow') {
                            e.stopPropagation();
                            e.preventDefault();
                            onPreviewClick('configure');
                            let transformerProgress = getState().Lab.transformerProgress;
                            let currentTransformer = getState().Lab.currentTransformer;

                            if (transformerProgress && transformerProgress.length) {
                                transformerProgress.forEach(element => {
                                    if (element.crnt_transformer === currentTransformer?.id) {
                                        element.state = 'running'
                                    }
                                });
                                dispatch({ type: SET_TRANSFORMER_PROGRESS, payload: transformerProgress })
                            }
                            onRunClick(true)
                        }

                    }}>
                    <ICON_PLAY />
                    Run
                </div >,
                key: '0',
                disabled: dispatch(getTextClass()) === 'text-blue' || dispatch(getTextClass()) === 'text-yellow' ? true : false
            },
            {
                label:
                    <span className={`dbox-opt-itm ${!isEditPermission ? 'disabled' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            onPreviewClick('configure')
                            onConfigureClick(true);
                        }}>
                        <ICON_SETTINGS />
                        Configure
                    </span>,
                key: '1',
                disabled: !isEditPermission

            },
            {
                label: <span className={`dbox-opt-itm ${!isEditPermission ? 'disabled' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onPreviewClick('preview')
                        onConfigureClick(true);
                    }}>
                    <ICON_EYEOPEN />
                    Preview
                </span>
                ,
                key: '3',
                disabled: !isEditPermission
            },
            {
                label: <div className={`dbox-opt-itm danger w-100 ${!isEditPermission ? 'disabled' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onPreviewClick('configure');

                        onDeleteClick()
                    }}>
                    <DELETEICON />
                    Delete
                </div>,
                key: '4',
                disabled: !isEditPermission
            },

        ]

        return optionList
    }
}


