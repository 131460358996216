import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { useSelector } from 'react-redux'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import { SCRIPTOUTPUT, SCRIPTOUTPUTBIG } from '../../../../Common/iconSource'


function ScriptOutputNodeIndex() {
    const currentTransformer = useSelector(state => state.FlowsReducer.currentTransformer)
    const store = useStore()
    const [scriptOutputDetails, setscriptOutputDetails] = useState('')
    useEffect(() => {
        if (!_.isEmpty(currentTransformer) && currentTransformer.type === 'scriptOutput') {
            let inputTransformerName = store.getState().FlowsReducer?.transformerName[currentTransformer?.id?.split('|')[0]];
            let details = `Output Dataset Name " ${currentTransformer?.id?.split('|')[1]} " of "${inputTransformerName}" Script Transformer`
            if (details) {
                setscriptOutputDetails(details)
            }
        }
        if (currentTransformer.type === 'scriptOutput' && ( Object.keys(currentTransformer?.content).length <= 0 || currentTransformer?.content?.outcomeColumns?.length <= 0)) {
            emitToastNotification('info','Please generate result for this output node by executing the input script transformer')
        }
        
        
    }, [currentTransformer])
    return (
        <div>
            <div className="no-datasource flex-column">
                <SCRIPTOUTPUTBIG height='65' width='55'/>
                <p style={{fontFamily:'InterSemibold',fontSize:'14px'}} className='font-weight-bold text-center text-secondary'>{scriptOutputDetails}</p>
            </div>
        </div>
    )
}

export default ScriptOutputNodeIndex
