import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { Radio, Switch, Tooltip } from 'antd'
import Accordian from '../../../Common/accordion/Accordian'
import { ACCORDIONDOWNARROW, DATAPODICON, REDIRECTJOBS } from '../../../Common/iconSource'
import { LandingSimpleTable } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import SourceTypeImage from '../../common/sourceTypeImage'
import { Literals } from '../../common/literals'
import { ICON_CATALOG } from '../../../Common/newIconSource'
import { getColumnDataType, pxToRem, remToPx } from '../../common/helperFunctions'

const demo = [
    {
        id: 1,
        source: 'clarista_demo',
        pod_name: 'ESG Ratings',
        description: 'Weekly datasets of covid postitive peopls, a demo data description.',
        business_term: 'Issuer Name',
        connector: 'carbon_risk',
        schema: 'risk',
        table: 'carbon_risk'
    }
]
export const PodsOfDomainsView = ({ 
    colData, 
    setFieldValues = () => {},
    isEdit = false
}) => {
   
    const [data, setData] = useState([])
    // const onRowClick = (data) => {
    //     console.log(data)
    //     window.open(`${Literals.links.DATA_CATALOG_POD_DETAILS}${data.table_id}`, '_blank')
    // }
    useEffect(() => {
        if (colData) {

            let temp = colData.map((item) => {
                return {
                    id: item.table_id,
                    table_id:item.table_id,
                    source: listTemplate.source(item.source_type),
                    domain_name: listTemplate.domain_name(item.data_domain_name),
                    table: listTemplate.table(item.table_name),
                    business_term: listTemplate.business_term(item.tech_term, item),
                    action: listTemplate.primaryKey(
                        () => handlePrimary(item?.table_id), 
                        item?.primary_key ?? false,
                        isEdit
                        ),
                    // pod_name: listTemplate.pod_name(item.pod_name),
                    // description: listTemplate.description(item.description),
                    // connector: listTemplate.connector(item.connector),
                    // schema: listTemplate.schema(item.tech_term),
                }


            })
            setData([...temp])

        }

    }, [colData, isEdit])

    const handlePrimary = (table_id) => {
        setFieldValues(prev => {
            return {
                ...prev,
                col_term: colData?.map(c => ({...c, primary_key: table_id === c?.table_id ? !c?.primary_key : c?.primary_key}))
            }
        })
    }

    return (
        <div className='px-2'>
            <Card className='card custom-card p-0 shadow-none border-0'>
                <div className='dict-title-strip'>
                    <div className='d-flex align-items-center w-100 justify-content-between'>
                        <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                            Data Source(s)
                        </label>
                    </div>
                </div>
                <CardBody className='p-0 podsdomain-acc-list-data-dict dict-detail-table domain-src-tbl mx-n2'>
                    {
                        data.length
                            ? <LandingSimpleTable
                                cols={columns}
                                // onRowClick={onRowClick}
                                rowsData={data}
                                tableHeight={(data?.length * 41) + 41}
                                showCursor={false}
                            />
                            : <div className='p-3 text-center text-black-50 no-datasrc-found'>No Domains & PODs Found</div>
                    }

                </CardBody>
            </Card>
        </div>
    )
}

const listTemplate = {
    source: (sourceType) => {
        let text = sourceType
        if (sourceType?.toLowerCase() === 'upload') text = 'File Upload'
        return <>
            <div className='d-flex'>
                <SourceTypeImage dataName={sourceType} />
                <label className='text-with-ellipsis text-uppercase ml-2 mb-0 dict-color-dark'>{text}</label>
            </div>
        </>
    },
    pod_name: (text = '') => {
        return <>
            <div className='dict-color-dark'>
                {text}
            </div>
        </>
    },
    description: (text = '') => {
        return <>
            <div className='dict-color-dark text-with-ellipsis'>
                {text}
            </div>
        </>
    },
    domain_name: (text = '') => {
        return <>
            <Tooltip title={text?.toUpperCase()} placement='topLeft'>
                <div className='dict-color-dark text-with-ellipsis'>
                    {text?.toUpperCase() ?? ""}
                </div>
            </Tooltip>

        </>
    },
    business_term: (text = '', obj) => {
        return <>
            <div className='d-flex align-items-center dict-color-dark biz-term-dtype'>
                {getColumnDataType(obj?.data_type)}
                <Tooltip title={text} placement='topLeft' className='d-block text-with-ellipsis ml-1'> {text ?? ""}</Tooltip>
            </div>
        </>
    },
    connector: (text = '') => {
        return <>
            <div>
                {text}
            </div>
        </>
    },
    schema: (text = '') => {
        return <>
            <div>
                {text}
            </div>
        </>
    },
    table: (text = '') => {
        return <>
            <div className='dict-color-dark position-relative text-uppercase text-with-ellipsis'>
                <Tooltip placement='topLeft' title={text?.toUpperCase()}> {text ?? ""}</Tooltip>
                {/* <ICON_CATALOG height='14' width='14' color='#0A3053' />
                <Tooltip title={text} placement='topLeft'>
                    <span className='text-with-ellipsis text-underline dict-table-nm'>{text}</span>
                </Tooltip> */}
            </div>
        </>
    },
    primaryKey: (onClick = () => { }, checked = false, isEdit = false) => {
        return <>
            <div className='h-100 d-flex align-items-center justify-content-start'>
                <Switch
                    className={`custom-switch-btn`}
                    checkedChildren="Yes" unCheckedChildren="No"
                    size='small'
                    checked={checked}
                    onChange={onClick}
                    disabled={isEdit}
                />
                {/* <Radio 
                    checked={checked} 
                    onChange={onClick}
                    disabled={isEdit}
                /> */}
                {/* <Tooltip placement="bottom" title="View Pod Details">
                    <button className="custom-btn-outline-blue custom-btn-outline bg-transparent custom-btn btn-with-icon"
                        onClick={(e) => {
                            e.stopPropagation()
                            onClick()
                        }
                        }
                    >
                        <REDIRECTJOBS />
                    </button>
                </Tooltip> */}
            </div>
        </>
    }
}

const columns = [
    {
        name: <label className='mt-0 grey-color label'>Source</label>,
        key: "source",
        sortable: false,
        resizable: false,
        width: remToPx(pxToRem(200))
    },
    {
        name: <label className='mt-0 grey-color label'>Technical Name</label>,
        key: "business_term",
        sortable: false,
        resizable: false,
        width: remToPx(pxToRem(200))
        // width: 220
    },
    {
        name: <label className='mt-0 grey-color label'> Domain Name</label>,
        key: "domain_name",
        sortable: false,
        resizable: false,
        width: remToPx(pxToRem(200))
    },
    {
        name: <label className='mt-0 grey-color label'>Pod Name</label>,
        key: "table",
        sortable: false,
        resizable: false,
        width: 'auto'
        // width: 150
    },
    // {
    //     name: <label className='mt-0 grey-color label'>Primary</label>,
    //     key: "action",
    //     sortable: false,
    //     resizable: false,
    //     width: 'auto'
    // },
]

// const columns = [
//     {
//         name: 'Source',
//         key: "source",
//         sortable: false,
//         resizable: false,
//         width: 80
//     },
//     {
//         name: 'Pod Name',
//         key: "pod_name",
//         sortable: false,
//         resizable: false
//     },
//     {
//         name: 'Domain Name',
//         key: "domain_name",
//         sortable: false,
//         resizable: false
//     },
//     {
//         name: 'Description',
//         key: "description",
//         sortable: false,
//         resizable: false
//     },
//     {
//         name: 'Business Term',
//         key: "business_term",
//         sortable: false,
//         resizable: false
//     },
//     {
//         name: 'Connector',
//         key: "connector",
//         sortable: false,
//         resizable: false
//     },
//     {
//         name: 'Schema',
//         key: "schema",
//         sortable: false,
//         resizable: false
//     },
//     {
//         name: 'Table',
//         key: "table",
//         sortable: false,
//         resizable: false
//     }
// ]