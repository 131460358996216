import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Row } from "react-data-grid";
import './DndVirtualList.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableRowRenderer } from './DraggableRowRenderer';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';
import { showFlowsLogsLowerSection, showFlowsLowerSection } from '../../../store/modules/flows/flowsActions';
import { useDispatch } from 'react-redux';

export const MultipleColsDndList = ({
  rawData = [],
  rowsList = [],
  setData = () => { },
  colsList = [],
  rowHeight = 40,
  customWrapClass = '',
  rowReorder = true,
  onRowClick = () => { },
  borderBottom = false,
  onlyFirstColumnDraggable = false,
  headerRowHeight
}) => {
  const [columns, setColumns] = useState([...colsList])
  const [rows, setRows] = useState([...rowsList])
  const [raw, setRaw] = useState([...rawData])
  const dispatch = useDispatch()
  useEffect(() => {
    setColumns([...colsList])
  }, [colsList])

  useEffect(() => {
    setRows([...rowsList])
  }, [rowsList])

  useEffect(() => {
    setRaw([...rawData])
  }, [rawData])

  const draggableColumns = useMemo(() => {

    const HeaderRenderer = (props) => {

      return (
        <DraggableHeaderRenderer
          {...props}
          onColumnsReorder={handleColumnsReorder}
        />
      );
    };


    // column re-ordering
    const handleColumnsReorder = (sourceKey, targetKey) => {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);

      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      setColumns(reorderedColumns);
    };

    return columns.map((c) => ({ ...c, headerRenderer: HeaderRenderer }));
  }, [columns]);

  const renderRow = useCallback((key, props) => {
    function onRowReorder(fromIndex, toIndex) {
      dispatch(showFlowsLogsLowerSection(false));
      dispatch(showFlowsLowerSection(false));
      setRows((rows) => {
        const newRows = [...rows];
        newRows.splice(toIndex, 0, newRows.splice(fromIndex, 1)[0]);
        return [...newRows];
      });
      setRaw((raw) => {
        const newRaw = [...raw];
        newRaw.splice(toIndex, 0, newRaw.splice(fromIndex, 1)[0]);

        setData([...newRaw])
        return newRaw;
      });
    }

    return <DraggableRowRenderer rowReorder={rowReorder} key={key} onlyFirstColumnDraggable={onlyFirstColumnDraggable} {...props} onRowReorder={onRowReorder} />;
  }, [rowReorder]);
  // const RowRenderer = useCallback((props) => {
  //     function onRowReorder(fromIndex, toIndex) {
  //       setRows((rows) => {
  //         const newRows = [...rows];
  //         newRows.splice(toIndex, 0, newRows.splice(fromIndex, 1)[0]);
  //         return [...newRows];
  //       });
  //       setRaw((raw) => {
  //         const newRaw = [...raw];
  //         newRaw.splice(toIndex, 0, newRaw.splice(fromIndex, 1)[0]);

  //         setData([...newRaw])
  //         return newRaw;
  //       });
  //     }
  //     if(rowReorder)
  //       return <DraggableRowRenderer onlyFirstColumnDraggable={onlyFirstColumnDraggable} {...props} onRowReorder={onRowReorder} />;
  //     else {
  //       return <Row {...props}/>
  //     }
  //   }, []);

  return <>
    <div className={`${customWrapClass} h-100`}>
      <DndProvider backend={HTML5Backend}>
        <DataGrid
          className={`h-100 custom-vlist-dgt  ${borderBottom ? 'border-bottom' : ''}`}
          columns={draggableColumns}
          rows={rows}
          onCellClick={({ row, ...rest }, index, e) => { onRowClick(row, index, e) }}

          defaultColumnOptions={{
            sortable: false,
            resizable: false,
          }}
          headerRowHeight={headerRowHeight ?? rowHeight}
          rowHeight={rowHeight}
          renderers={
            {
              renderRow,
              noRowsFallback: <div style={{ gridColumn: '1/-1' }} className='p-2 text-black-50 text-center'>No Records Found</div>
            }
          }
        />
      </DndProvider>
    </div>
  </>;
};