import { Tooltip } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    openModel,
    setSidebarActiveRoute,
} from "../../../../store/actions";
import Buttons from "../../../Common/button/Buttons";
import { CATALOGUE, DATADICTIONARY, IMPORTFROMDATABASE, MANUALCREATION, PLUS, SETTINGS } from "../../../Common/iconSource";
import LandingPageSubHeader from "../../../Common/landingPageSubHeader/LandingPageSubHeader";
import LandingpageFirstHeader from "../../common/LandingpageFirstHeader";
import { Literals } from "../../common/literals";
import LoadingComponent from "../../common/loadingComponent";
import { InternalDictionary } from "./InternalDictionary";
import { Settings } from "./Settings";
import '../../dataCatalogue/landingPage/components/CreateButton.scss'
import Filter from "../../../Common/filter/Filter";
import _ from "lodash";
import { getObjectLength, ErrorComponent } from "../../common/helperFunctions";
import { getClassificationData, getSettingsDataCategory } from "../../../../store/modules/dataDictionary/action";
import { getUserGroupList } from "../../../../store/modules/UserManagement/userGroup/UMActions";
import { setLoadingStatus } from "../../../../store/modules/common/loadingActions";
import ConnectionModal from "../../connections/landingPage/components/ConnectionModal";
import ConnectionContext from "../../connections/landingPage/components/ConnectionContext";
import { ICON_CATALOG } from "../../../Common/newIconSource";

function LandingPage({ ...props }) {

    let { fromRoute = "Data Dictionary", populateSearchData } = props;

    const history = useNavigate()

    let state = useSelector(state => state.App)

    const [showFilter, setshowFilter] = useState(false);
    const [showListView, setshowListView] = useState(false);
    const [showGridView, setshowGridView] = useState(false);
    const [searchData, setSearchData] = useState(populateSearchData??"");
    const [activeTab, setActiveTab] = useState(fromRoute === 'schema-table' ? "internal" : "settings");
    const [layout, setLayout] = useState("");
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isFilterApplied, setFilterApplied] = useState(false);
    const [isOptionsDisable, setOptionsDisable] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState(state.Filters.dataDictionary.selectedFilters ?? []);
    const [landingPageData, setLandingPageData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [classifications, setClassifications] = useState([]);
    const [usersList, setUsersList] = useState([])
    const [errMsg, setErrMsg] = useState("");
    const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false)
    const connectionContext = useContext(ConnectionContext)

    const dispatch = useDispatch();

    const tabsMenuList = React.useMemo(() => {
        let tabs = [
            {
                title: "Dictionary",
                key: "internal",
                Icon: () => (
                    <>
                        <ICON_CATALOG />
                    </>
                ),
                onClickEvent: (key) => {
                    setActiveTab(key);
                },
            },
            {
                title: "Settings",
                key: "settings",
                Icon: () => (
                    <>
                        <SETTINGS />
                    </>
                ),
                onClickEvent: (key) => {
                    setActiveTab(key);
                },
            }
        ];
        if (fromRoute === 'schema-table') {
            tabs = tabs?.filter(t => t?.key === 'internal')
        } else {
            tabs = tabs?.filter(t => t?.key === 'settings')
        }
        return tabs
    }, [fromRoute, activeTab]) 

    useEffect(() => {
        dispatch(setSidebarActiveRoute(Literals.links.DATA_DICTIONARY));

        // dispatch(getSettingsDataCategory())
        //     .then(res => {
        //         setCategories([...res.data])
        //     })
        //     .catch((err) => {
        //         setErrMsg(err.message)
        //         setLoadingStatus(false);
        //     })

        // dispatch(getClassificationData())
        //     .then(res => {
        //         setClassifications([...res.data])
        //     })
        //     .catch((err) => {
        //         setErrMsg(err.message)
        //         setLoadingStatus(false);
        //     })

        getUsersList()

        connectionContext.setFromConnectionRoute(true)
    }, []);

    useEffect(() => {
        let filters = [...selectedFilters],
            status = null
        if (filters.length === 0) status = false
        else if (filters.length > 0) {
            status = !filters.map(v => v[Object.keys(v)[0]].length > 0).every(v => v === false)
        }
        setFilterApplied(status)
    }, [selectedFilters])

    useEffect(() => {
        setLayout(() => {
            if (showGridView === true) {
                return 'grid'
            }
            else if (showListView === true) {
                return 'list'
            }
            else return 'grid'
        })
    }, [showGridView, showListView])

    const getUsersList = () => {
        dispatch(getUserGroupList({ method: "GET", endPoint: "profile/", payload: undefined, showLoader: false }))
            .then(res => {
                let list = res?.data?.filter(u => u?.is_active)
                list = res?.data?.map(ele => ({ Name: `${ele?.first_name} ${ele?.last_name}`, Value: ele?.id?.toString(), Email: ele?.email }))
                setUsersList([...list]);
            })
            .catch(err => console.error(err))
    };

    const setshowFilterPopup = () => {
        setshowFilter(!showFilter);
    };

    const getFormattedDataForFilter = () => {

        if (landingPageData !== undefined && landingPageData.length) {
            if (getObjectLength(state.Filters.dataDictionary) && getObjectLength(state.Filters.dataDictionary?.filterList)) {
                return state.Filters.dataDictionary.filterList
            }
            else {
                // let clone = [...landingPageData]
                let catObj, classObj, ownersObj, stewardsObj = {}

                catObj = {
                    header: 'Categories',
                    selectAll: false,
                    list: categories.map((v, i) => (
                        { id: i, checked: false, name: v.name, category: v.name }
                    ))
                }
                classObj = {
                    header: 'Classifications',
                    selectAll: false,
                    list: classifications.map((v, i) => (
                        { id: i, checked: false, name: v.name, category: v.name }
                    ))
                }
                ownersObj = {
                    header: 'Owners',
                    selectAll: false,
                    list: usersList.map((v) => (
                        { id: v.Value, checked: false, name: v.Name, category: v.Name }
                    ))
                }
                stewardsObj = {
                    header: 'Stewards',
                    selectAll: false,
                    list: usersList.map((v) => (
                        { id: v.Value, checked: false, name: v.Name, category: v.Name }
                    ))
                }

                const obj = {
                    header: '',
                    list: [{ id: 1, checked: false, name: 'Created by me' }]
                }

                return [
                    obj,
                    catObj,
                    classObj,
                    ownersObj,
                    stewardsObj
                ]
            }
        }
        else return []
    }

    const getDataStatus = (boolean) => {
        setOptionsDisable(boolean)
    }

    return (
        <>
            <div>
                {props.loadingStatus ? <LoadingComponent /> : null}
                <div className="data-dictionary-wrapper">
                    {fromRoute !== 'schema-table' ? <LandingpageFirstHeader
                        title={"Data Dictionary"}
                        flag={false}
                        backRoute={Literals.links.DATA_DICTIONARY}
                    /> : <></>}
                    {/* <LandingPageSubHeader
                        props={{
                            defaultActiveTab:activeTab,
                          
                            tabsMenuList: tabsMenuList,
                            moduleType: "data_dictionary",
                            defaultValue: showListView ? 'listView' : 'gridView',
                            isFilterApplied: isFilterApplied,
                            setSearchData: setSearchData,
                            setshowFilterPopup: setshowFilterPopup,
                            setshowGridView: setshowGridView,
                            setshowListView: setshowListView,
                            fromRoute: fromRoute,
                            hideAllOptions: activeTab === 'settings',
                            isDisableAllOptions: isOptionsDisable,
                            isDictionaryPage: true,
                            searchData: searchData,
                            setActiveTab: setActiveTab,
                            activeTab: activeTab
                        }}
                    /> */}
                    <div style={fromRoute === 'schema-table' ? { overflow: "hidden" } : {}} className="dict-main-wrap">
                        {errMsg.length > 0 ? <ErrorComponent msg={errMsg} /> :
                            <>
                                {
                                    activeTab === 'internal'
                                        ? <InternalDictionary
                                            searchData={searchData}
                                            fromRoute={fromRoute}
                                            setSearchData={setSearchData}
                                            layout={layout}
                                            setshowListView={setshowListView}
                                            setshowGridView={setshowGridView}
                                            setLandingPageData={setLandingPageData}
                                            selectedFilters={selectedFilters}
                                            getDataStatus={getDataStatus}
                                            categoryList={categories}
                                            classificationList={classifications}
                                            setErrMsg={setErrMsg}
                                            setCategories={setCategories}
                                            setClassifications={setClassifications}
                                        />
                                        : <Settings
                                            usersList={usersList}
                                        />
                                }
                            </>
                        }
                    </div>

                </div>

                {
                    activeTab === 'settings' ? ''
                        :
                        <div className="landingPage data-dict">

                            {isButtonActive && (
                                <div
                                    className="rotate-button-backdrop"
                                    onClick={() => setIsButtonActive(false)}
                                ></div>
                            )}
                            <div className="options-wrapper">
                                <Buttons
                                    props={{
                                        buttonText: "",
                                        buttonClassName: `custom-btn-primary custom-btn btn-with-text fixed-button addbutton_plusicon ${isButtonActive ? "rotate-icon" : ""
                                            }`,
                                        buttonEvent: () => {
                                            setIsButtonActive((isButtonActive) => !isButtonActive);
                                        },
                                        tooltip: "Add",
                                        tooltipPlacement: "left",
                                        ImgSrc: () => <PLUS />,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                                    }}
                                />
                                <ul
                                    className={`add-options-list ${isButtonActive ? "active-list" : ""}`}
                                >
                                    <Tooltip placement="left" title="Import from Database">
                                        {/* <li onClick={() => {}}>  */}
                                        <li onClick={() => setIsConnectionModalOpen(true)}>
                                            {/* setIsConnectionModalOpen(true) */}
                                            <IMPORTFROMDATABASE />
                                        </li>
                                    </Tooltip>
                                    <Tooltip placement="left" title="Manual Creation">
                                        <li onClick={() => history(Literals.links.DATA_DICTIONARY_MANUAL_CREATION)}>
                                            <MANUALCREATION />
                                        </li>
                                    </Tooltip>
                                </ul>
                            </div>
                        </div>
                }
            </div>


            {
                showFilter
                    ?
                    <Filter
                        props={{
                            filterList: getFormattedDataForFilter(),
                            type: "dataDictionary",
                            selectedFilters: selectedFilters,
                            setselectedFilters: setSelectedFilters,
                            setshowFilter: setshowFilter
                        }}
                    />
                    : ''
            }
            {/* <ConnectionModal
                isModalOpen={isConnectionModalOpen}
                onClose={() => setIsConnectionModalOpen(false)}
            /> */}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        loadingStatus: state.LoadingReducer.loadingStatus,
    };
};
export default connect(mapStateToProps)(LandingPage)