import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Prompt } from "react-router";
import "toastr/build/toastr.min.css";
import {
  SaveSummaryPodReadmeV2,
  setEditorHtml,
  setEditorState, UpdateSummaryPodReadmeV2
} from "../../../../../store/modules/dataCatalogue/summary/summaryActions";

import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { setUploadDocFlag } from "../../../../../store/modules/dataCatalogue/summary/summaryActions";
import Buttons from '../../../../Common/button/Buttons';
import CustomTabs from "../../../../Common/CustomTabs/CustomTabs";
import { CLOSE, DOCUMENTS, DOC_ICON, EDIT, FILE, HTML_ICON, OVERVIEW, PDF_ICON, PLUS, SAVE, TXT_ICON, UPLOAD, UPLOADDOCUMENT } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import FileContainer from "./fileContainer";
import UploadDocModal from "./UploadDocModal";

const toolbarConfig = {
  options: ["inline", "textAlign", "link", "blockType", "list"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline"],
  },
  blockType: {
    inDropdown: true,
    options: [
      "Normal",
      "H1",
      "H2",
      "H3",
      "H4",
      "H5",
      "H6",
      "Blockquote",
      "Code",
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered"],
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["justify", "right", "center", "left"],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link"],
    linkCallback: undefined,
  },
}

const UploadFileComponent = ({ 
  tableId,
  userId, 
  getSummaryDocument,
  isDeletePermission,
  isEditPermission,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getSummaryDocument()
  }, [])

  const fileTypeIcon = (type) => {
    switch(type) {
        case 'txt' : return <TXT_ICON /> 
        
        case 'pdf' : return <PDF_ICON />
        
        case 'doc' : return <DOC_ICON />
        
        case 'docx' : return <DOC_ICON />
        
        case 'docm' : return <DOC_ICON />
        
        case 'dot' : return <DOC_ICON />
        
        case 'dotm' : return <DOC_ICON />
        
        case 'dotx' : return <DOC_ICON />
        
        case 'wps' : return <DOC_ICON />
        
        case 'htm': return <HTML_ICON />
        
        case 'html': return <HTML_ICON />
        
        default : return <TXT_ICON />
    }
} 
  const summaryDocument = useSelector(state => state.DataCatalogue.Summary.documentTableData)
  return <> 
  
    {summaryDocument?.length ? <div className="summary_file_container">
      {summaryDocument.map(
        (f, i) => 
          <FileContainer
            f={f} 
            getSummaryDocument={getSummaryDocument} 
            i={i} 
            tableId={tableId} 
            userId={userId} 
            Icon={fileTypeIcon(f.file_name.split('.')[1])} 
            isDeletePermission={isDeletePermission}
            isEditPermission={isEditPermission}
          />
        )}
    </div> : 
    isEditPermission
    ?
    <div>
      <div onClick={()=>{dispatch(setUploadDocFlag(true))}} className="ver-hide mx-auto align-items-center d-flex flex-column justify-content-center pt-2 add-overview-graphics">
        <div className="ovr-ic"><DOCUMENTS/></div>
        <label className="mt-1 mb-0 d-flex align-items-center"><PLUS width="10"/>Add Document</label>
      </div>
    
    </div>
    : <p className="text-center text-black-50 paragraph mt-2 mb-0"><i className="bx bx-error-circle mr-1"></i>Edit permission needed</p>  
  }
  </>
}


export const CustomEditor=({editorState,onEditorStateChange,contentState})=>(
  <Editor
  initialContentState={contentState}
  editorState={editorState}
  wrapperClassName="demo-wrapper"
  editorClassName="demo-editor"
  onEditorStateChange={onEditorStateChange}
  toolbar={toolbarConfig}
/>

)

const TextEditor = ({ 
  getEditorData, 
  setSummaryEnable, 
  isSummaryEditorEnable,  
  getSummaryDocument, 
  documentCount,
  isDeletePermission,
  isEditPermission,
}) => {
  const store = useStore();
  const dispatch = useDispatch();
  const catalogueSummary = useSelector((state) => state.DataCatalogue.Summary);
  const user = useSelector((state) => state.UserReducer);
  const editorDataCount = get(catalogueSummary, `editorDataCount`, "");
  const filesUploaded = useSelector(state => state.DataCatalogue.Summary.uploadedFiles)
  const editorDataFileContent = get(
    catalogueSummary,
    `editorData[0].file_content`,
    ""
  );
  const [activeTab, setActiveTab] = useState(1)
  const [, setSummaryDocResponse] = useState(1)

  const editorHtml = catalogueSummary.editorHtml;
  const editorState = catalogueSummary.editorState;
  const userId = get(user, `user.username`, "");
  const tableId = get(catalogueSummary, `summaryPodData[0].table_id`, "");
  const [isSummaryEditorUnable, setIsSumaryEditorEnable] = useState(isSummaryEditorEnable ? isSummaryEditorEnable : false);
  const summaryDocument = useSelector(state => state.DataCatalogue.Summary.documentTableData)
  
  const contentBlock = htmlToDraft(editorHtml);
  useEffect(()=>{
    setSummaryDocResponse(getSummaryDocument())
  },[])
  
  useEffect(() => {
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      dispatch(setEditorState(EditorState.createWithContent(contentState)));
    }
  }, [isSummaryEditorUnable]);
  useEffect(() => {
    dispatch(setEditorHtml(editorDataFileContent));
  }, [editorDataFileContent]);
  const onEditorStateChange = (editorState) => {
    dispatch(setEditorState(editorState));
  };
  const createMarkup = () => {
    return { __html: editorHtml };
  };
;
  const sendEditorData = async () => {
    setSummaryEnable(true, editorState);
    let response;
  
    try {
      if(!isEditorEmpty){
        response= await store.dispatch(UpdateSummaryPodReadmeV2({table:tableId,file_content:draftToHtml(convertToRaw(editorState.getCurrentContent()))}))
      }
      else{
        response = await store.dispatch(SaveSummaryPodReadmeV2({ table:tableId,file_content:draftToHtml(convertToRaw(editorState.getCurrentContent())) }));

      }
      if (response?.status === "success") {
        emitToastNotification('success', response?.message)
        // emitToastNotification('success', response.message);
        setIsSumaryEditorEnable(false);
        setSummaryEnable(false)
        getEditorData();
        // getSummaryTimelineData()
      }
    } catch (error) { }
  };

  const onTabChangeHandler = (e) => {
    if(e === '2') {
      setIsSumaryEditorEnable(false);
      setSummaryEnable(false);
    }
    setActiveTab(e)
  }

  const isEditorEmpty = useMemo(() => (
         editorHtml.trim() === '' 
      || editorHtml.trim() === "<p></p>"
      || editorHtml.trim() === "<h1></h1>"
      || editorHtml.trim() === "<h2></h2>"
      || editorHtml.trim() === "<h3></h3>"
      || editorHtml.trim() === "<h4></h4>"
      || editorHtml.trim() === "<h5></h5>"
      || editorHtml.trim() === "<small></small>"
      || editorHtml.trim() === "<strong></strong>"
      || editorHtml.trim() === "<em></em>"
      || editorHtml.trim() === "<blockquote></blockquote>"
      || editorHtml.trim() === "<code></code>"
    ), [editorHtml])
    
  return (
    <div className="text-editor-section">
      <div className="text-editor-header">
        <CustomTabs 
          id="overviewTabs"
          activeTab={activeTab} 
          tabs={[{
              id: "1",
              title: Literals.OVERVIEW,
              icon: <FILE/>,
              content:  <>{!isSummaryEditorUnable ?
                <>
                  {
                    !isEditPermission ? <p className="text-center text-black-50 paragraph mt-2 mb-0"><i className="bx bx-error-circle mr-1"></i>Edit permission needed</p> :
                    isEditorEmpty ? <>
                      <div id="createOverview" onClick={()=>{setIsSumaryEditorEnable(true);setSummaryEnable(true);}} className="ver-hide mx-auto align-items-center d-flex flex-column justify-content-center pt-2 add-overview-graphics">
                        <div className="ovr-ic"><OVERVIEW/></div>
                        <label className="mt-1 mb-0 d-flex align-items-center"><PLUS width="10" />Add Overview</label>
                      </div>
                    </> : 
                    <div id="overviewContent" className="p-3" dangerouslySetInnerHTML={createMarkup()} />
                  }
                </>
                : <div className="overview-editor-wrap">
                    <CustomEditor
                      editorState={editorState}
                     
                      onEditorStateChange={onEditorStateChange}
                    />
                  </div>
              } </>
          }, 
          {   
              id: "2",
              title: `Documents (${documentCount}) `,
              icon: <UPLOADDOCUMENT/>,
              content: <UploadFileComponent 
                  tableId={tableId} 
                  userId={userId} 
                  getSummaryDocument={getSummaryDocument} 
                  filesUploaded = {filesUploaded} 
                  isDeletePermission={isDeletePermission}
                  isEditPermission={isEditPermission}
                />
               
          },
          ]} onChange={onTabChangeHandler}
        />
        <div className={`d-flex ${isSummaryEditorUnable?'justify-content-end':''}`}>
          {!isSummaryEditorUnable ?
            (activeTab !== "2" ? <Buttons 
              props={{
                buttonId: "overviewEditBtn",
                buttonText: Literals.BUTTON_EDIT, 
                buttonClassName: `custom-btn-primary custom-btn btn-with-text text-editor-button-setup ${isEditorEmpty ? 'd-none' : ''}`, 
                buttonEvent: () => {
                  setIsSumaryEditorEnable(true);
                  setSummaryEnable(true);
                },
                ImgSrc: () => <EDIT />, 
                isDisable: !isEditPermission, 
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
              }}
            /> : 
            <Buttons 
              props={{ 
                buttonText: Literals.BUTTON_UPLOAD, 
                buttonClassName: `custom-btn-primary custom-btn btn-with-text text-editor-button-setup upload-button ${summaryDocument?.length === 0 ? ' d-none' : ''}`, 
                buttonEvent: () => { 
                  dispatch(setUploadDocFlag(true))
                }, 
                ImgSrc: () => <UPLOAD />, 
                isDisable: !isEditPermission, 
                buttonType:Literals.BTN_PRIMARY_WITH_TEXT
              }}
            />
            )
            :
            <>
            <div className="mt-2 d-flex justify-content-end">
              <Buttons props={{
                buttonText: "Close", buttonClassName: "custom-btn-outline custom-btn btn-with-text mr-1", buttonEvent: () => {
                  setIsSumaryEditorEnable(false);
                  setSummaryEnable(false);
                },
                ImgSrc: () => <CLOSE />,
                isDisable: false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
              }}
              />
              <Buttons props={{ buttonText: Literals.BUTTON_SAVE, buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { sendEditorData() }, ImgSrc: () => <SAVE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
            </div>
            </>
          }
        </div>
      </div>
      
      {/* <Prompt
        when={isSummaryEditorUnable}
        message={() => `If you continue, all unsaved changes will be discarded`}
      /> */}

      <UploadDocModal getSummaryDocument = {getSummaryDocument} tableId={tableId} />
    </div>
  );
};

export default TextEditor;
