import React, { useEffect, useReducer, useRef, useState } from "react";
import Buttons from "../../../../Common/button/Buttons";
import { Literals } from "../../../common/literals";
import {
  CLOSE,
  EDIT,
  EDITOR,
  PLAY,
  PROPERTIES,
  RESETFILTER,
  SAVE,
} from "../../../../Common/iconSource";
import { connect, useDispatch, useSelector, useStore } from "react-redux";
import {
  executeTransformerApi,
  saveTransformerNoteClick,
  setFilterTransformerFilterCriteria,
  setFlowsCurrenttransformer,
  showFlowsTransformdetails,
  updateFlowsElements,
} from "../../../../../store/modules/flows/flowsActions";
import { useParams } from "react-router";
import { Tabs } from "antd";
import { SCRIPTDEFAULTVIEW } from "../script/ScriptDefaultInfography";
import EditorIndex from "../DataSource/editor/EditorIndex";
import DatasetOutputNameInput from "../commonFunctions/useDatasetNameInput";
import DndSchemaList from "../../dndMultiColumnsVirtualList/DndSchemaList";
import schema from "../../../../../assets/icons/Schema.svg";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { getInputFrame, getInputTransformerDetails, isTransformerNamePresent, orderRetainCheck } from "../FlowsHelperFunction";
import _, { cloneDeep } from "lodash";
import { getConfirmationModal, mergeArrayByProperty } from "../../../common/helperFunctions";

const { TabPane } = Tabs;

const SchemaTransformerIndex = ({
  flowsElements,
  transformerLoading,
  transformerNameList,
  currentTransformer,
  appliedFilterCriteria,
  isExecutePermission,
  ...props
}) => {
  const [showSampleRow, setshowSampleRow] = useState(false);
  const [disableExcute, setdisableExcute] = useState(false);
  const [columnList, setcolumnList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [transformerName, settransformerName] = useState("");
  const [showRedBtn, setshowRedBtn] = useState(false);

  const [showBtnLoading, setShowBtnLoading] = useState(false);
  const [reOrderRender, setreOrderRender] = useState(true)
  const [activeKey, setActiveKey] = useState('properties')
  const [openWarningModal, setopenWarningModal] = useState(false)
  const [showResetColumns, setshowResetColumns] = useState(false)

  const store = useStore();
  const { flowId } = useParams();

  const dispatch = useDispatch();

  const [toggleDefaultView, setToggleDefaultView] = useState(true);

  useEffect(() => {

    if (!transformerName) {
      setshowRedBtn(true)
    }
  }, [transformerName])


  const columnListRef = useRef([])
  useEffect(() => {
    if (columnList && columnList.length) {

      columnListRef.current = columnList;

    }
    return () => {
      let currentFlowsElements = cloneDeep(store.getState().FlowsReducer.flowsElements)
      let finalFlowsElements = currentFlowsElements.map(item => {
        if (item.id === currentTransformer.id) {
          let list = columnListRef.current.filter(item => item?.checked)
          if (list?.length) {
            item.content.orderList = columnList;
          }

        }
        return item
      })
      store.dispatch(updateFlowsElements([...finalFlowsElements]))
    }
  }, [columnList])


  const executeTransformer = async () => {
    let outcomeColumns = []
    let newColumnList = columnList.filter(
      (item, i) =>
        item.checked === true && {
          old_name: item.old_name,
          new_datatype: item.new_datatype,
          new_name: item.new_name,
          format: item.format,

        }
    );

    if (newColumnList && newColumnList.length && transformerName !== "") {
      settransformerName(transformerName)
      setShowBtnLoading(true);
      setdisableExcute(true);

      setcolumnList([...columnList]);

      let inputFrame = dispatch(getInputFrame())

      if (inputFrame !== "") {
        let payload = {
          flow_body: {
            Id: `${transformerName}`,
            currentTransformerID: currentTransformer.id,
            NodeType: `schema`,
            Args: [
              {
                Name: "frame",
                Value: inputFrame,
              },
              {
                Name: "columns",
                Value: newColumnList,
              },
            ],
          },
        };
        try {
          let response =
            flowId &&
            (await store.dispatch(
              executeTransformerApi(flowId, payload, currentTransformer.id)
            ));

          setShowBtnLoading(false);
          setdisableExcute(false);
          setshowRedBtn(false)

          if (response.status === "success") {

            settransformerName(transformerName)
            outcomeColumns = columnList.map(
              (item, i) =>
                item.checked === true && {
                  id: item.id,
                  Name: item.new_name,
                  Type: item.new_datatype,
                  checked: false,

                }
            ).filter(ele => ele);

            let finalFlowsElements = flowsElements.map((item) => {
              if (item.id === currentTransformer.id) {
                item.content.outcomeColumns = outcomeColumns;
                item.content.executed_DAG = payload.flow_body;
                item.content.appliedFilterCriteria = newColumnList;
                item.content.orderList = columnList;

                item.transformerName = transformerName;
              }
              return item;
            });
            store.dispatch(updateFlowsElements([...finalFlowsElements]));

          }
        } catch (error) {
          let finalFlowsElements = flowsElements.map((item) => {
            if (item.id === currentTransformer.id) {
              item.content.executed_DAG = payload.flow_body;
              item.content.appliedFilterCriteria = newColumnList;
              item.content.orderList = columnList;

              item.transformerName = transformerName;
            }
            return item;
          });
          store.dispatch(updateFlowsElements([...finalFlowsElements]));
          setShowBtnLoading(false);
          setdisableExcute(false);

        }
      }
    } else if (newColumnList && !newColumnList.length) {
      emitToastNotification("warning", "Please Select atleast one row");
    }
  };


  const Selectall = (checked) => {
    if (checked) {
      let temp = columnList.map((ele) => {
        ele.checked = true;
        return ele;
      });
      setcolumnList([...temp]);
    } else {
      let temp = columnList.map((ele) => {
        ele.checked = false;
        return ele;
      });
      setcolumnList([...temp]);
    }
  };

  const getDataType = (actualDataType = '', index) => {

    if (actualDataType.includes('(')) {
      return actualDataType.split('(')[0]
    } else {
      return actualDataType
    }

  }

  const createColumnsList = (target = [], source = [], prop) => {

    let newColumnList = [];

    if (!target?.length) {
      source.forEach(sourceElement => {
        sourceElement['showError'] = false
        sourceElement['old_name'] = sourceElement.Name
        sourceElement['new_name'] = sourceElement.Name
        sourceElement['new_datatype'] = getDataType(sourceElement?.Type)
        sourceElement['dataType'] = getDataType(sourceElement?.Type)
        sourceElement['format'] = ''

        newColumnList.push(sourceElement);
      })
      return newColumnList;
    } else {

      let elementFound = iselementFound();
      let noneFindElement = isnoneFindElement();


      if (elementFound?.length && noneFindElement?.length) {
        newColumnList = [...elementFound, ...noneFindElement]
        setshowResetColumns(true);

      }
      else if (elementFound?.length) {
        newColumnList = [...elementFound]

      } else if (noneFindElement?.length) {
        newColumnList = [...noneFindElement]
        setshowResetColumns(true);
        setdisableExcute(true)

      } else {
        setshowResetColumns(false)
        setdisableExcute(false)


      }
      let cloneSource = cloneDeep(source)
      const getChecked = (column) => {
        if (elementFound?.length) {
          return elementFound.find(item => item?.Name === column?.Name)?.checked
        } else if (target?.length) {

          return target.find(item => item?.Name === column?.Name)?.checked
        }
      }
      cloneSource = cloneSource.map(sourceElement => {
        sourceElement['showError'] = false;
        sourceElement['checked'] = getChecked(sourceElement);
        sourceElement['old_name'] = sourceElement.Name
        sourceElement['new_name'] = sourceElement.Name
        sourceElement['new_datatype'] = getDataType(sourceElement?.Type)
        sourceElement['dataType'] = getDataType(sourceElement?.Type)
        sourceElement['format'] = ''
        return sourceElement;
      })

      // return (mergeArrayByProperty(cloneSource, newColumnList, 'Name', true))

      let mergeList = (mergeArrayByProperty(cloneSource, newColumnList, 'Name', true))  //----------------------------this will return unique column remove duplicates from source columns i.e if duplicate found it will take newColumnList column
      let orderList = currentTransformer?.content?.orderList;
      return orderRetainCheck(orderList, mergeList);
    }

    function isnoneFindElement() {

      if (target?.length) {
        let targetElement = cloneDeep(target)
        source.forEach(sourceElement => {
          targetElement = targetElement.filter((item) => {
       
            if (item['Name'] !== sourceElement['Name'] && !item?.id?.toString()?.includes('emptyColumn_')) {
              item['showError'] = true;
              item['checked'] = true;
              return item;
            }
          })
        })
        return targetElement
      }

    }

    function iselementFound() {

      if (target?.length) {
        let targetElement = cloneDeep(target)
        let foundEleList = []
        source.forEach(sourceElement => {
          targetElement.forEach((item) => {

            if (item['Name'] === sourceElement['Name']) {
              let obj = cloneDeep(sourceElement)
              obj['showError'] = false;
              obj['old_name'] = item.old_name
              obj['new_name'] = item.new_name
              obj['new_datatype'] = getDataType(item?.new_datatype)
              obj['format'] = item?.format
              obj['checked'] = true;
              foundEleList.push(obj)
            }
          })
        })
        return foundEleList


      }

    }



  }


  useEffect(() => {
    if (
      Object.keys(transformerNameList).length &&
      currentTransformer.id &&
      transformerNameList[currentTransformer.id]
    ) {
      setshowRedBtn(false)

      settransformerName(transformerNameList[currentTransformer.id]);
    }

    let InputTransformer = dispatch(getInputTransformerDetails(currentTransformer, flowsElements))

    let inputColumns = cloneDeep(InputTransformer[0]?.content?.outcomeColumns);


    if (InputTransformer && InputTransformer?.length && inputColumns?.length) {

      let column = []


      if (!currentTransformer?.content?.appliedFilterCriteria && currentTransformer?.content?.outcomeColumns && currentTransformer?.content?.outcomeColumns[0]?.text) {//// this need to remove after some time its used for backword compatability
        column = createColumnsList(currentTransformer?.content?.outcomeColumns, inputColumns, 'Name')

      } else {

        column = createColumnsList(currentTransformer?.content?.appliedFilterCriteria, inputColumns, 'Name')
      }

      if (column?.length) {

        setcolumnList([...column])

        setToggleDefaultView(false)
        setShowBtnLoading(false);

      } else {

        setcolumnList([])
        setToggleDefaultView(true)
        setShowBtnLoading(false);
        setdisableExcute(true);
      }


    } else {
      setToggleDefaultView(true)
      setShowBtnLoading(false);
      setdisableExcute(true);
    }


  }, [currentTransformer]);

  const onChange = (activeKey) => {
    setEditMode(false);
    setActiveKey(activeKey);
  };

  const closeLeftSideContent = () => {
    dispatch(setFlowsCurrenttransformer({}));
    dispatch(showFlowsTransformdetails(false));
  };
  const currentConditionTypeRef = useRef(null)
  const onResetEvent = () => {

    currentConditionTypeRef.current = {
      message: 'Columns that are not found in input source that will be removed',
      onConfirm: () => {
        setreOrderRender(false)
        let cloneCols = cloneDeep(columnList);
        // cloneCols = cloneCols.map((item, i) => {
        //   if (item.showError) {
        //     item.id = `emptyColumn_${new Date().getTime()}_${i}}`
        //     item.checked = false
        //     item.disabled = true
        //     item['old_name'] = ''
        //     item['new_name'] = ''
        //     item['new_datatype'] = ''
        //     item['dataType'] = ''
        //     item['format'] = ''
        //     item.Name = ""
        //     item.Type = ""
        //     item.showError = false
        //   }
        //   return item
        // });
        cloneCols = cloneCols.filter((item) => !item.showError);

        if (cloneCols?.length) {

          setcolumnList([...cloneCols])
        }
        setopenWarningModal(false);
        setshowResetColumns(false)
        setdisableExcute(false)
        setTimeout(() => {
          setreOrderRender(true)

        }, 50);
        return true
      },
      onCancel: () => {
        setopenWarningModal(false);
        return false
      }
    }
    setopenWarningModal(true)
  }

  return (
    <>
      {
        getConfirmationModal({
          open: openWarningModal,
          setOpen: setopenWarningModal,
          body: currentConditionTypeRef?.current?.message,
          onConfirm: currentConditionTypeRef?.current?.onConfirm,
          onCancel: currentConditionTypeRef?.current?.onCancel
        })
      }
      <div className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
        <div className="d-flex align-items-center">
          <img src={schema} alt={`schema`} />
          <h3 className="subtitle">Schema</h3>
          <Tabs
            onChange={onChange}
            activeKey={activeKey}
            className="custom-tab tab-with-icon"
          >
            <TabPane tab={<PROPERTIES />} key="properties"></TabPane>

            <TabPane tab={<EDITOR />} key="editor"></TabPane>
          </Tabs>
        </div>
        <div className="d-flex align-items-center">
          {showResetColumns ? (
            <Buttons
              props={{
                tooltip: 'Reset Columns',
                buttonText: "",
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2 mr-2",
                buttonEvent: onResetEvent,
                toggleBtnValue: "",
                ImgSrc: () => <RESETFILTER />,
                isDisable: window.location.href.includes("viewFlows")
                  ? true
                  : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                toggleBtnOption: null,
              }}
            />
          ) : null}
          <DatasetOutputNameInput
            transformerName={transformerName}
            setTransformerName={settransformerName}
          />
          <Buttons
            props={{
              buttonText: "",
              buttonClassName:
                "custom-btn-outline custom-btn btn-with-icon mr-2",
              buttonEvent: () => {
                activeKey === "properties" ||
                  (activeKey === "editor" && !editMode)
                  ? closeLeftSideContent()
                  : setEditMode(false);
                onChange("properties");
              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_SECONDARY,
            }}
          />

          {activeKey === "editor" ? (
            <Buttons
              props={{
                buttonText: !editMode ? "Edit" : "Save",
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                buttonEvent: !editMode
                  ? () => {
                    setEditMode(true);
                    dispatch(saveTransformerNoteClick(false));
                  }
                  : () => {
                    dispatch(saveTransformerNoteClick(true));
                  },
                ImgSrc:
                  !editMode ? () => <EDIT /> : () => <SAVE />,
                isDisable: window.location.href.includes("viewFlows")
                  ? true
                  : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}

          {activeKey !== "editor" && !showSampleRow ? (
            <Buttons
              props={{
                buttonText: "Execute",
                buttonClassName: `${showRedBtn ? 'execute-btn-gradient' : ''} custom-btn-primary custom-btn btn-with-text`,   // dispatch(isTransformerNamePresent(currentTransformer?.id))?:"execute-btn-gradient custom-btn btn-with-text",
                showLoading: showBtnLoading,
                buttonEvent:
                  activeKey === "editor"
                    ? () => {
                      setEditMode(true);
                    }
                    : () => {
                      executeTransformer([]);
                    },
                ImgSrc:
                  activeKey === "editor" ? () => <EDIT /> : () => <PLAY />,
                isDisable:
                  !isExecutePermission || disableExcute || transformerName === "" ? true : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
          {activeKey !== "editor" && showSampleRow ? (
            <Buttons
              props={{
                buttonText: "Execute",
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                showLoading: showBtnLoading,
                buttonEvent:
                  activeKey === "editor"
                    ? () => {
                      setEditMode(true);
                    }
                    : () => {
                      executeTransformer([]);
                    },
                ImgSrc:
                  activeKey === "editor" ? () => <EDIT /> : () => <PLAY />,
                isDisable:
                  !isExecutePermission || disableExcute || transformerName === "" || showResetColumns ? true : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
        </div>
      </div>

      {activeKey === "properties" && (
        <>
          {" "}
          {!toggleDefaultView ? (
            <div className="bg-white section-with-drag flow-filter-height h-100">
              <div
                className="flows-filter-props-win schemaTransformer-table"
                style={{ overflow: "hidden" }}
              >
                <DndSchemaList
                  reOrderRender={reOrderRender} setreOrderRender={setreOrderRender}
                  actualData={columnList}
                  stopDraggingRow={
                    window.location.href.includes("viewFlows") ? true : false
                  }
                  setNewColumnList={setcolumnList}
                  Selectall={Selectall}
                />
              </div>
            </div>
          ) : (
            <div className="no-datasource">
              <SCRIPTDEFAULTVIEW />
            </div>
          )}
        </>
      )}

      {activeKey === "editor" && (
        <EditorIndex editMode={editMode} setEditMode={setEditMode} />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    appliedFilterCriteria: state.FlowsReducer.appliedFilterCriteria,
    transformerNameList: state.FlowsReducer.transformerName,
    transformerLoading: state.FlowsReducer.transformerLoading,
    // transformerProgress: state.FlowsReducer.transformerProgress,
  };
};
export default connect(mapStateToProps)(SchemaTransformerIndex);
