import {
  SET_POD_DETAILS,
  SET_TAGS_LIST,
  SET_USER_GROUPS_LIST,
  SET_POLICY_LIST,
  SET_SELECTED_POLICY,
} from "./actionTypes";

const selectedPolicyCache =
  JSON.parse(sessionStorage.getItem("selectedPolicy")) || null;

const initialState = {
  podDetails: {},
  tagsList: [],
  userGroups: [],
  policyList: [],
  columnDetails: [],
  selectedPolicy: selectedPolicyCache,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POD_DETAILS:
      return {
        ...state,
        podDetails: action.payload.podDetails,
        columnDetails: action.payload.columnDetails,
      };
    case SET_TAGS_LIST:
      return {
        ...state,
        tagsList: action.payload,
      };
    case SET_USER_GROUPS_LIST:
      return {
        ...state,
        userGroups: action.payload,
      };
    case SET_POLICY_LIST:
      return {
        ...state,
        policyList: action.payload,
      };
    case SET_SELECTED_POLICY:
      return {
        ...state,
        selectedPolicy: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
