import { Popover } from 'antd';
import React, { useEffect, useState } from 'react'
import Buttons from '../../../../../Common/button/Buttons';
import { CLOSE, SAVE } from '../../../../../Common/iconSource';
import SingleSelection from '../../../../../Common/dropDown/SingleSelection';
import { Literals } from '../../../../common/literals';

function ColumnSelectPopover({ clickIndex, index, saveClick, originalColumnList, selectedColumn, setselectedColumn, closePopup }) {

    const [open, setOpen] = useState();
    const hide = () => {
        setOpen(false);
    };
 

    useEffect(() => {
       
        if(clickIndex === index){
            setOpen(true)
        }else{
            setOpen(false)
        }
      
    }, [clickIndex,selectedColumn])

    const onColumnSelect = (val) => {

        setselectedColumn(val);


    }

    const popoverContent = () => {
        return (<div className="columSelect-popover">
            <label>COLUMN</label>
            <SingleSelection
                props={{
                    placeholder: "Select Column",
                    dropDownValue: selectedColumn === ''?[]:selectedColumn,
                    onClickEvent: (val) => {
                        onColumnSelect(val)
                    },
                    allowClear: true,
                    isSortOptionDisable: true,

                    optionList: originalColumnList,
                    isDisable: false,
                    selectClassName: " w-100 col-12 custom-select-dropdown  p-0",
                }}
            />
            <div className="d-flex align-item-center mt-4 justify-content-center w-100">
                <Buttons
                    props={{
                        buttonText: "Save",
                        buttonClassName: "custom-btn-primary custom-btn btn-with-text  ml-2",
                        buttonEvent: () => {
                            saveClick()
                            setOpen(false);

                        },
                        toggleBtnValue: "",
                        ImgSrc: () => <SAVE />,
                        isDisable: false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        toggleBtnOption: null,
                    }}
                />
                <Buttons
                    props={{
                        buttonText: "Cancel",
                        buttonClassName: "custom-btn-outline btn-outline custom-btn  btn-with-text ml-2",
                        buttonEvent: () => {
                            closePopup();
                            // hide();

                        },
                        toggleBtnValue: "",
                        ImgSrc: () => <CLOSE />,
                        isDisable: false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        toggleBtnOption: null,
                    }}
                />
            </div>
        </div>)
    }

   
    return <Popover
        content={popoverContent}
        title=""
        trigger="click"
        open={open}
        showArrow={false}
        placement="rightTop"
        // onOpenChange={(newOpen) => handleOpenChange(newOpen)}
    ></Popover>


}

export default ColumnSelectPopover