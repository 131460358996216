import { Space, Tooltip, Popover, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BookmarkAlertDataApi,
  DeleteAlertDataApi,
  TriggerAlertDataApi,
} from "../../../../store/modules/alert/alertLanding/alertLandingAction";
import Buttons from "../../../Common/button/Buttons";
import {
  ALERTBELL,
  BELLALERT,
  DELETEICON,
  EDIT,
  FILLEDSTAR,
  LINEDPLAY,
  STAR,
  COPY,
  CLOSE,
  SAVE,
  ALERT,
} from "../../../Common/iconSource";

import ActionPopUp from "../../common/actionPopUp";
import {
  DeleteNoteContent,
  getAlertCount,
  getAlertPriorityIcon,
  getRelativeTime,
  permissions,
  titleCase,
  toTitleCase,
} from "../../common/helperFunctions";
import { Literals } from "../../common/literals";
import "./alertGridView.scss";
import { duplicate } from "../../../../store/modules/dataCatalogue/dictionary/dictionaryAction";

import toastr from "toastr";
import { options } from "../../../../components/modules/common/toastrProperties";
import Highlighter from "react-highlight-words";
import { LandingSimpleTable } from "../../../Common/landingSimpleTable/LandingSimpleTable";
import _ from "lodash";
import DashboardIndex from "../../dashboard/DashboardIndex";
import { ICON_PLAY } from "../../../Common/newIconSource";
import DeleteModal from "../../../Common/deleteModal/DeleteModal";

toastr.options = {
  ...options,
};

const alertPermissions = permissions.alerts

const AlertListView = ({
  data,
  fromRoute,
  setselectedAlertInfo,
  searchValue,
  selectedAlertInfo,
  getRelativeTime,
  GetData,
  getType,
}) => {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const dispatch = useDispatch();
  const [renameText, setRenameText] = useState({});
  const [visible, setVisible] = useState({});
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
  const [rowData, setRowData] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(undefined)

  const currentAlertRef = React.useRef({current: {}})

  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };
  const history = useNavigate();

  useEffect(() => {
    let list = [...data];

    let temp = list?.map(item => {
      let domainName = _.uniq(item?.data_domain?.map((v) => v.data_domain_name));
      const checkIsEditor = () => {
        const isRoleEditor = !!item?.permission?.find(v => v === alertPermissions.editor)
        if(isRoleEditor) return true
        return undefined
      }
      const isDeletePermission = checkIsEditor() ?? !!item?.permission?.find(v => v === alertPermissions.delete)
      const isEditPermission = checkIsEditor() ?? !!item?.permission?.find(v => v === alertPermissions.edit)
      return {
        key: item.id,
        data: { ...item, domain_name_for_sort: domainName && domainName.length ? domainName?.join(",") : "" },
        name: (
          <div className="d-flex align-items-center pl-2 alert-prioricon-in-list">
            <Tooltip placement="top" title={item?.purpose + ' Alert'}>
              <span className={`noti-alert-prps-ic ${item?.purpose.toLowerCase() === "quality"
                ? "quality"
                : "business"}`}>
                <ALERT />
              </span>
            </Tooltip>
            {/* {getType(item?.type, "listview")} */}
            <Tooltip
              className="ml-4 text-with-ellipsis font-w-600 text-uppercase"
              placement="top"
              title={item?.name}
            >
              <Highlighter
                searchWords={[searchValue?.toLocaleLowerCase()]}
                autoEscape={true}
                textToHighlight={item?.name}
              >
                {" "}
                <span className="single-line-ellipsis">{item?.name}</span>
              </Highlighter>
            </Tooltip>
          </div>
        ),
        type: (
          <div className="d-flex align-items-center pl-2 alert-varicon-in-list justify-content-center h-100">
            <Tooltip
              placement="top"
              title={toTitleCase(item?.type ?? '')}
            >
              <span>
                {getType(item?.type, "listview")}
              </span>
            </Tooltip>
          </div>
        ),
        description: (
          item.description ? <Tooltip placement="top" title={item?.description}>
            <Highlighter
              searchWords={[searchValue?.toLocaleLowerCase()]}
              autoEscape={true}
              textToHighlight={item?.description}
            >
              {" "}
              <span className="single-line-ellipsis">{item?.description}</span>
            </Highlighter>
          </Tooltip> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px" }}>No description has been given yet.</span></label>
        ),
        domain_name: (
          <Tooltip
            placement="top"
            title={domainName?.join(",")?.toUpperCase()}
            className="text-capitalize"
          >
            <Highlighter
              searchWords={[searchValue?.toLocaleLowerCase()]}
              autoEscape={true}
              textToHighlight={
                domainName && domainName.length ? domainName?.join(", ")?.toUpperCase() : ""
              }
            >
              {/* {" "}
              <span className="single-line-ellipsis">
                {domainName && domainName.length ? domainName?.join(", ") : ""}
              </span> */}
            </Highlighter>
          </Tooltip>
        ),
        priority:
          <div className="w-auto h-100 d-flex align-items-center">
            <Tooltip placement="top" title={`${item?.priority}`}>
              {getAlertPriorityIcon(item?.priority?.toLowerCase())}
            </Tooltip>
          </div>,
        // priority: <div className="text-with-ellipsis">{item?.priority}</div>,
        purpose: (
          <span
            className={
              item?.purpose.toLowerCase() === "quality"
                ? "pl-0 quality"
                : "pl-0 business"
            }
          >
            <Highlighter
              searchWords={[searchValue?.toLocaleLowerCase()]}
              autoEscape={true}
              textToHighlight={
                item?.purpose ? titleCase(item?.purpose) + " Alert" : ""
              }
            >
              {" "}
            </Highlighter>{" "}
          </span>
        ),
        created_on: (
          <>
            <Tooltip placement="top" title={getRelativeTime(new Date(item?.created_on))}>
              {getRelativeTime(item?.created_on)}
            </Tooltip>
            {/* {moment(getRelativeTime(new Date(item.created_on), undefined), 'DD-MM-YYYY').fromNow()} */}
            {/* <Highlighter
              searchWords={[searchValue?.toLocaleLowerCase()]}
              autoEscape={true}
              textToHighlight={
                getRelativeTime(new Date(item?.created_on))
                  ? getRelativeTime(new Date(item?.created_on))
                  : ""
              }
            >
              {getRelativeTime(new Date(item?.created_on))}
            </Highlighter> */}
          </>
        ),
        action_btns:
          !fromRoute && fromRoute !== "scheduler" ? (
            <div style={{ gap: '5px' }} className="d-flex justify-content-center align-items-center h-100">
              <Tooltip
                placement="bottom"
                title={
                  item?.bookmark
                    ? Literals.REMOVE_FAVOURITES
                    : Literals.ADD_FAVOURITES
                }
              >
                <button
                  className="custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    dispatch(
                      BookmarkAlertDataApi(item?.id, item?.bookmark)
                    ).then(() => {
                      GetData();
                    });
                  }}
                >
                  {item?.bookmark ? <FILLEDSTAR /> : <STAR />}
                </button>
              </Tooltip>
              <Buttons
                  props={{
                    buttonText: "",
                    buttonClassName:
                      "custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent",
                    buttonEvent: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if(!isEditPermission) return
                      dispatch(TriggerAlertDataApi(item)).then(() => {
                        // emitToastNotification('success', 'Execution started successfully!')
                      });
                    },
                    tooltip: "Execute Alert",
                    ImgSrc: () => <ICON_PLAY />,
                    isDisable: !isEditPermission,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />

              {/* <Tooltip placement="bottom" title="Execute Alert">
                <button
                  className="custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    dispatch(TriggerAlertDataApi(item)).then(() => {
                      // emitToastNotification('success', 'Execution started successfully!')
                    });
                  }}
                >
                  <ICON_PLAY />
                </button>
              </Tooltip> */}
              <Tooltip placement="bottom" title="Edit Alert">
                <Buttons
                  props={{
                    buttonText: "",
                    buttonClassName:
                      "custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent",
                    buttonEvent: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleEdit(item);
                    },
                    tooltip: "Edit Alert",
                    ImgSrc: () => <EDIT />,
                    isDisable: !isEditPermission,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />
                {/* <button
                  className="custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleEdit(item);
                  }}
                >
                  <EDIT />
                </button> */}
              </Tooltip>
              <Tooltip placement="bottom" title="Duplicate">
                <Popover
                  content={
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      style={{
                        display: "flex",
                        padding: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title={renameText[item?.id] ?? `${item?.name}_copy`}>
                        <Input
                          value={renameText[item?.id] ?? `${item?.name}_copy`}
                          onChange={(e) => {

                            e.preventDefault();
                            e.stopPropagation();
                            setRenameText({
                              ...renameText,
                              [item?.id]: e.target.value,
                            });
                          }}
                        />
                      </Tooltip>
                      <Buttons
                        props={{
                          buttonText: "",
                          buttonClassName:
                            "custom-btn-outline custom-btn btn-with-icon ml-1",
                          buttonEvent: (e) => {
                            e.preventDefault();
                            handleSaveClick(item);
                            // updateDocumentHandler();
                          },
                          ImgSrc: () => <SAVE />,
                          isDisable: false,
                          buttonType: Literals.BTN_SECONDARY,
                        }}
                      />
                    </div>
                  }
                  title={
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      style={{
                        display: "flex",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Create a Copy</span>{" "}
                      <Buttons
                        props={{
                          buttonText: "",
                          buttonClassName:
                            "custom-btn-outline custom-btn btn-with-icon mr-1",
                          buttonEvent: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setVisible(false);
                          },
                          ImgSrc: () => <CLOSE />,
                          isDisable: false,
                          buttonType: Literals.BTN_TERTIARY,
                        }}
                      />
                    </div>
                  }
                  onOpenChange={(vis) =>
                    setVisible({ ...visible, [item?.id]: vis })
                  }
                  trigger="click"
                  open={isEditPermission && (visible[item?.id] ?? false)}
                >
                  <Buttons
                  props={{
                    buttonText: "",
                    buttonClassName:
                      "custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent",
                    buttonEvent: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if(!isEditPermission) return
                      setVisible({ ...visible, [item?.id]: true });
                    },
                    tooltip: "Duplicate",
                    ImgSrc: () => <COPY />,
                    isDisable: !isEditPermission,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />
                  {/* <button
                    className=" custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setVisible({ ...visible, [item?.id]: true });
                    }}
                  >
                    <COPY />
                  </button> */}
                </Popover>
              </Tooltip>
              <Tooltip placement="bottom" title="Delete">
                <Buttons
                  props={{
                    buttonText: "",
                    buttonClassName:
                      "error-red custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent",
                    buttonEvent: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      openDeleteDialog(item);
                    },
                    tooltip: "Delete Alert",
                    ImgSrc: () => <DELETEICON />,
                    isDisable: !isDeletePermission,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />
                {/* <button
                  className="error-red custom-btn-outline custom-btn btn-with-icon border-0 bg-transparent"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    openDeleteDialog(item);
                  }}
                >
                  {" "}
                  <DELETEICON />
                </button> */}
              </Tooltip>
            </div>
          ) : (
            <></>
          ),
      };
    });
    setRowData([...temp]);
  }, [data, visible, renameText]);

  const handleSaveClick = (val) => {
    try {
      dispatch(
        duplicate(
          { name: renameText[val.id] ?? `${val.name}_copy` },
          "rule",
          val.id
        )
      ).then((res) => {
        if (res.status == "success") {
          GetData();
        }
      });
    } catch (err) { }
  };

  const openDeleteDialog = (val) => {

    currentAlertRef.current = val
    
    // setDeletePopUpContent({
    //   modalClass: "delete-popup",
    //   heading: "Delete",
    //   message: DeleteNoteContent(
    //     val.id,
    //     "alert",
    //     ` Warning: Deleting this alert may effect in 
    //         schedular wherever it used.`
    //   ),
    //   button: [
    //     {
    //       name: "Delete",
    //       action: () => {
    //         dispatch(DeleteAlertDataApi(val.id)).then(() => {
    //           GetData();
    //           closeDeletePopUp();
    //         });
    //       },
    //     },
    //   ],
    //   elements: [],
    //   closeActionPopup: closeDeletePopUp,
    // });
    setOpenDeletePopup(true);
  };

  const handleDelete = data => {
    dispatch(DeleteAlertDataApi(data.id)).then(() => {
      GetData();
      closeDeletePopUp();
    })
    .catch(() => {})
  }

  let cols = [
    {
      name: <span style={{ paddingLeft: '48px' }}>Alert Name</span>,
      key: "name",
      sortable: true,
      resizable: fromRoute !== 'scheduler'? true:false,

      // width: "20%"
    },
    {
      name: <div className="text-center">Type</div>,
      key: "type",
      sortable: false,
      resizable: false,
    },
    {
      name: "Description",
      key: "description",
      sortable: true,
      resizable: false  ,
      // width: "30%"

    },
    {
      name: "Domain Name",
      key: "domain_name",
      sortable: true,
      resizable: false,
    },
    {
      name: <div className="text-center">Priority</div>,
      key: "priority",
      sortable: true,
      resizable: false,
    },

    // {
    //   name: "Purpose",
    //   key: "purpose",
    //   sortable: true,
    //   resizable: false,
    // },
    {
      name: "Created On",
      key: "created_on",
      sortable: true,
      resizable: false,
    },
    {
      name: <div className="text-center">Action</div>,
      key: "action_btns",
      sortable: false,
      resizable: false,
      // width: "12%"
    },
  ];

  const onRowClick = (record) => {
    // history.push(`/viewAlert/${record.id}`)
    if (!fromRoute && fromRoute !== "scheduler") {
      history(`${Literals.links.ALERTS_VIEW}${record?.data?.id}`,
        {
          state: { exceptionid: null, from: "alert" },
        });
    } else {
      setSelectedRowIndex(record?.data?.id)
      setselectedAlertInfo(record?.data);
    }
  };

  useEffect(() => {
    if (selectedAlertInfo && selectedAlertInfo?.id) {
      setSelectedRowIndex(selectedAlertInfo?.id)

    }
  }, [selectedAlertInfo])
  const handleEdit = (record) => {
    if (!fromRoute && fromRoute !== "scheduler") {
      history(`${Literals.links.ALERTS_EDIT}${record.id}`,
        {
          state: { exceptionid: null, from: "alert" },
        });
    } else {
      setselectedAlertInfo(record);
    }

    // history.push(`/editAlert/${id}`)
  };

  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'name':
      case 'description':
      case 'priority':
      case 'purpose':
      case 'created_on':
      case 'created_by':
      case 'updated_by':
        return (a, b) => a?.data[sortColumn] !== undefined ? a?.data[sortColumn]?.localeCompare(b?.data[sortColumn]) : null
      case 'domain_name':
        return (a, b) => a?.data['domain_name_for_sort'] !== undefined ? a?.data['domain_name_for_sort']?.localeCompare(b?.data['domain_name_for_sort']) : null
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }

  if (fromRoute && fromRoute === "scheduler") {
    cols = cols.slice(0, cols.length - 1)
  }
  return (
    <div className="alert-list-view" style={{ height: 'calc(100vh - 78px)' }}>
      <LandingSimpleTable
        cols={cols}
        autoWidth={true}
        rowsData={rowData}
        onRowClick={onRowClick}
        tableHeight={'100%'}
        getComparator={getComparator}
        selectedRowIndex={selectedRowIndex}
      />
      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={DeleteNoteContent(currentAlertRef?.current?.id, 'Alert', `Warning: Deleting this Alert might effect Scheduler or wherever it is used.`)}
        onConfirm={() => handleDelete(currentAlertRef?.current)}
      />      
      {/* <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent}
      /> */}
    </div>
  );
};

export default AlertListView;
