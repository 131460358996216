import React from "react";
import { Tooltip } from "antd";
import "./InfoItem.scss";

const InfoItem = ({ details }) => {
  const { icon, label, value ,fullValue} = details;

  return (
    <div className="info-item">
      <div className="icon-box">
        <span className="icon-span">{icon}</span>
      </div>
      <div className="info-item-content">
        <label className="label">{label}</label>
        <Tooltip placement="topLeft" title={fullValue??value}>
          <p>{value ? value : "N/A"}</p>
        </Tooltip>
      </div>
    </div>
  );
};

export default InfoItem;
