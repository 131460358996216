import {
  SET_SUMMARY_PAGE_DEFAULT,
  EDIT_DESCRIPTION,
  SET_SUMMARY_POD_DATA,
  SET_DOCUMENT_TABLE_DATA,
  SET_EDITOR_DATA,
  SET_EDITOR_DATA_COUNT,
  SET_INSIGHT_DATA,
  SET_SUMMARY_TIMELINE,
  SET_TIMELINE_COUNT,
  SET_INSIGHT_DATE,
  INSIGHT_CHART_STATE,
  SELECT_INSIGHT_CHART_VIEW,
  SET_UPLOAD_DOC_FLAG,
  EDIT_SUMMARY_EDITOR,
  SET_EDITOR_HTML,
  SET_EDITOR_STATE,
  RESET_SUMMARY_STATE,
  SET_ANALYZE_MODAL_VISIBILITY,
  SET_UPLOADED_FILES,
  SET_PAGE_COUNT
} from "./summaryActionTypes";

const initialState = {
  uploadDocFlag: false,
  editDescription: false,
  summaryTimeLine: [],
  insightChartView: "hour",
  editSummaryEditor: false,
  editorHtml: "",
  editorState: null,
  isAnalyzeModalVisible: false,
  uploadedFiles:[],
  pageCount: 0,
  insightChartState: {
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        id: "area-datetime",
        type: "area",
        height: 300,
        zoom: {
          enabled: false,
        },
      },
      annotations: {
        yaxis: [
          {
            borderColor: "#999",
            label: {
              show: false,
              style: {
                color: "#fff",
                background: "#00E396",
              },
            },
          },
        ],
        xaxis: [
          {
            borderColor: "#999",
            yAxisIndex: 0,
            format: "MM dd yyy",
            label: {
              show: true,
              text: "Rally",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
            },
          },
        ],
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "datetime",
        min: new Date("2021-05-01 00:00:00").getTime(),
        max: new Date("2021-05-29 00:00:00").getTime(),
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "MM dd yyyy",
        },
      },
    },
  },
};

const CatalogueSummary = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_DESCRIPTION:
      state = { ...state };
      state.editDescription = action.payload;
      break;
    case SET_UPLOADED_FILES: 
      state= {...state};
      state.uploadedFiles = action.payload;
      break;
    case SET_SUMMARY_POD_DATA:
      state = { ...state };
      state.summaryPodData = action.payload;
      break;
    case SET_DOCUMENT_TABLE_DATA:
      state = { ...state };
      state.documentTableData = action.payload;
      break;
    case SET_EDITOR_DATA:
      state = { ...state };
      state.editorData = action.payload;
      break;
    case SET_EDITOR_DATA_COUNT:
      state = { ...state };
      state.editorDataCount = action.payload;
      break;
    case SET_INSIGHT_DATA:
      state = { ...state };
      state.insightData = action.payload;
      break;
    case SET_SUMMARY_TIMELINE:
      state = { ...state };
      state.summaryTimeLine = [...action?.payload]
      break;
    case SET_TIMELINE_COUNT:
      state = { ...state };
      state.summaryTimeLineCount = action.payload;
      break;
    case SET_INSIGHT_DATE:
      state = { ...state };
      state.insightStartDate = action.payload.startDate;
      state.insightEndDate = action.payload.endDate;
      break;
    case INSIGHT_CHART_STATE:
      state = { ...state };
      state.insightChartState = action.payload;
      break;
    case SELECT_INSIGHT_CHART_VIEW:
      state = { ...state };
      state.insightChartView = action.payload;
      break;
    case SET_UPLOAD_DOC_FLAG:
      state = { ...state };
      state.uploadDocFlag = action.payload;
      break;
    case EDIT_SUMMARY_EDITOR:
      state = { ...state };
      state.editSummaryEditor = action.payload;
      break;
    case SET_EDITOR_HTML:
      state = { ...state };
      state.editorHtml = action.payload;
      break;
    case SET_EDITOR_STATE:
      state = { ...state };
      state.editorState = action.payload;
      break;
    case SET_PAGE_COUNT:
      state = { ...state };
      state.pageCount = action.payload;
      break;
    case RESET_SUMMARY_STATE:
      state = initialState;
      break;
  
    case SET_SUMMARY_PAGE_DEFAULT:
      return  initialState; 
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CatalogueSummary;
