import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { deleteBussinessTerm, getBusinessTerms, getClassificationData, getDictionaryList, getSettingsDataCategory } from '../../../../store/modules/dataDictionary/action'
import Accordian from '../../../Common/accordion/Accordian'
import { ACCORDIONDOWNARROW, CATEGORY, CLOSE, DATADICTIONARY, PLUS, FILTER, RESETFILTER, SEARCH, USERTAG } from '../../../Common/iconSource'
import ActionPopUp from '../../common/actionPopUp'
import { generateHtmlTitle, getDeleteMessage, getObjectLength, showSimpleLoading } from '../../common/helperFunctions'
import { DataDictionaryGridView } from './DataDictionaryGridView'
import { DataDictionaryListView } from './DataDictionaryListView'
import NoSearchResultFound from '../../common/NoSearchResultFound'
import { Input, Popover, Tooltip, Select, Row, Col } from 'antd'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import SingleSelection from '../../../Common/dropDown/SingleSelection'
import MultiSelection from '../../../Common/dropDown/MultiSelection'
import ClassificationModal from './classificationModal';
import CategoryModal from './categoryModal';


import useDebounce from '../../../../customHooks/useDebounce'
import { resetDictionaryRedux, setAppliedFiltersDictionaryLanding } from '../../../../store/actions'
import { setLoadingStatus } from '../../../../store/modules/common/loadingActions'
import { RESET_SAVED_APPLIED_FILTERS } from '../../../../store/modules/dataCatalogue/dictionary/dictionaryActionTypes'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'
import { DataDictionaryMiniListView } from './DataDictionaryMiniListVIew'


const demo = [
    {
        id: 1,
        domain_name: 'A ESG RATINGS',
        column_name: 'Issuer Name',
        column_dtype: 'varchar',
        column_techincal_terms: ['carbon_risk.Issuer_Name', 'risk_ratings.name'],
        column_classifications: 'PII 84%',
        column_description: 'Names of the legal entity of the company providing the insurance, b...',
        notification_count: 1,
        domains: ['ESG RATINGS']
    },
    {
        id: 2,
        domain_name: 'B ESG RATINGS',
        column_name: 'Carbon Total Emission',
        column_dtype: 'varchar',
        column_techincal_terms: ['carbon_risk.Carbon_Total_Emission'],
        column_classifications: null,
        column_description: 'Annual CO2 emission worldwide from 1940 to 2020 (in billion metric...',
        notification_count: 3,
        domains: ['ESG RATINGS']
    },
    {
        id: 3,
        domain_name: 'C ESG RATINGS',
        column_name: 'Issuer Address',
        column_dtype: 'varchar',
        column_techincal_terms: ['carbon_risk.Issuer_Address', 'risk_ratings.email', 'carbon_risk2.Issuer_Address', 'risk_ratings2.email', 'risk_ratings3.email'],
        column_classifications: 'Address  75%',
        column_description: 'Contact details of the issuer',
        notification_count: 3,
        domains: ['ESG RATINGS']
    }
]


const list = [
    'ESG RATINGS',
    'STOCKS',
    'SECURITIES'
]

const pageLimit = 100

export const InternalDictionary = ({
    categoryList,
    classificationList,
    searchData,
    layout,
    setshowListView,
    setshowGridView,
    fromRoute,
    setCategories,
    setClassifications,
    setLandingPageData,
    selectedFilters = [],
    getDataStatus = () => { },
    setSearchData = () => { },
    setErrMsg = () => { }
}) => {

    const dispatch = useDispatch()
    const [dataObj, setDataObj] = useState({})
    const [rawData, setRawData] = useState([])
    const [empty, setEmpty] = useState(false)
    const [defaultExpandedPanel, setDefaultExpandedPanel] = useState([])
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [stopLazyload, setStopLazyload] = useState(false);
    const [pageNo, setPageNo] = useState(1)
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    const [openClassificationModal, setOpenClassificationModal] = useState(false);

    const [reset, setReset] = useState(false);
    const [selectedIndex, setselectedIndex] = useState(null);

    const [listColumns, setListColumns] = useState([]);

    const [dataCount, setDataCount] = useState();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [loadMore, setLoadMore] = useState(false)
    const [isRefreshing, setRefreshing] = useState(false)
    const [isSearching, setSearching] = useState(false)
    const [noDataFound, setNoDataFound] = useState(false)
    const [apiCallTime, setApiCallTime] = useState("");
    const [isReady, setIsReady] = useState(false)
    const [colsList, setColsList] = useState([...originalColumns.slice(0, originalColumns.length - 1)]);

    const [showFilterPop, setFilterPop] = useState(false);
    const [filters, setFilters] = useState({ category: [], classification: [], add_field: '' });
    const [value, setValue] = useState(searchData)
    const [focused, setFocused] = useState(false)

    const debouncedTerm = useDebounce(value, 500)
    const appliedFilters = useSelector(state => state.DataCatalogue.Dictionary.save_search_filter)

    const searchRef = useRef({ current: '' })
    const categoryRef = useRef([])
    const classificationRef = useRef(
        []
    )

    const currentBusinessTermIdRef = useRef({current: {}})

    const selectedBusinessTerm = useSelector(state => state.DataCatalogue.Dictionary.selectedBusinessTerm)

    useEffect(() => {
        let title = generateHtmlTitle('Data Dictionary')
        document.title = title

        return () => {
            // dispatch(resetDictionaryRedux())
        }

    }, [])

    useEffect(() => {
        if (focused) {
            setIsReady(false)
            searchRef.current = debouncedTerm
            setSearchData(debouncedTerm)
            if (debouncedTerm.trim() !== '') setSearching(true)
            getList(true)
        }
    }, [debouncedTerm])

    useEffect(() => {

        if (selectedBusinessTerm && selectedBusinessTerm.id) {
            setselectedIndex(selectedBusinessTerm.id)
        }
    }, [selectedBusinessTerm])

    useEffect(() => {
        if (pageNo !== 1) {

            setPageNo(1)
        }
        searchRef.current = searchData.toLowerCase()

    }, [searchData])



    useEffect(() => {
        setNoDataFound(dataCount === 0)
    }, [dataCount])

    useEffect(() => {
        let val = [...selectedCategories];
        handleFilter('category', categoryRef, val)
        setIsReady(false)
        setFilters(prev => ({ ...prev, category: val }))
        categoryRef.current = val
        // getList(true)
    }, [selectedCategories])
    useEffect(() => getDataStatus(empty), [empty])


    const getList = (refresh = false) => {
        if (refresh) {
            setStopLazyload(false)
        }
        else {
            setIsReady(true)
        }
        let filterQuery = `&business_term=${searchRef.current}`
        // let filterQuery = `&search_term=${searchRef.current}`
        // if (categoryRef.current !== '') {
        //     filterQuery += `&category_id=${categoryRef?.current?.join(",")}`
        // }
        if (classificationRef.current !== '') {
            filterQuery += `&classification_id=${classificationRef?.current?.join(",")}`
        }
        let pageNumber = refresh ? 1 : pageNo
        const startTime = new Date();
        dispatch(setAppliedFiltersDictionaryLanding({ searchKeyword: searchRef.current, categoryFilter: categoryRef.current, classificationFilter: classificationRef.current }))
        dispatch(getDictionaryList(pageNumber, pageLimit, filterQuery, false))
            .then(res => {

                const endTime = new Date();
                setApiCallTime(
                    Math.abs(endTime - startTime) / 1000
                );
                if (res.data) {
                    if (res.data.has_next === false) {
                        setStopLazyload(true)
                        setLoadMore(false)
                    }
                }
                if ((!res.data?.length || res.data?.results?.length <= 0) && pageNo === 1) {
                    setDataCount(0)
                }
                let viewType = res.data.results[0]?.view_type;
                if (viewType === "grid") {
                    setshowListView(false);
                    setshowGridView(true);
                }
                else if (viewType === "list") {
                    setshowListView(true);
                    setshowGridView(false);
                }
                if (res.status === "success") {
                    setLoadMore(false)
                    setPageNo(prev => prev + 1)
                    setDataCount(res?.data?.count)
                    setRefreshing(false)
                }

                if (res.data.results.length === 0) {
                    if (refresh) {
                        setRawData([])
                    }
                    else {
                        setEmpty(true)
                    }
                    setIsReady(true)
                    setSearching(false)
                }
                else {
                    if (refresh) {
                        setRawData([...res.data.results])
                        setIsReady(true)
                        // setTimeout(()=>setIsReady(true), 50)
                        setSearching(false)

                    }
                    else {
                        setRawData(prev => [...prev, ...res.data.results])
                    }
                    // generateData(res.data.results)
                }
            })
            .catch(err => {
                console.error(err)
                setErrMsg(err.message)
                setIsReady(true)
                setSearching(false)
            })
    }

    const saveCategory = (val) => {
        let valId = val.map(item => item.id)
        setIsReady(false)
        setFilters(prev => ({ ...prev, category: valId }))
        categoryRef.current = val
        handleFilter('category', categoryRef, valId)

        // getList(true)

    }
    const saveClassification = (val) => {
        let valId = [val].map(item => item.id)

        setIsReady(false)
        setFilters(prev => ({ ...prev, classification: valId }))
        classificationRef.current = val
        handleFilter('classification', classificationRef, valId)

        // getList(true)

    }

    const openDeleteDialog = (id) => {

        currentBusinessTermIdRef.current = id

        // setDeletePopUpContent({
        //     modalClass: "delete-popup",
        //     heading: "Delete",
        //     message: "Delete this business term?",
        //     button: [
        //         {
        //             name: "Delete",
        //             action: () => deleteBusinessTerm(id),
        //         }
        //     ],
        //     elements: [],
        //     closeActionPopup: () => setOpenDeletePopup(false),
        // });
        setOpenDeletePopup(true);
    };

    const deleteBusinessTerm = (id) => {
        dispatch(deleteBussinessTerm(id))
            .then(() => {
                getList(true)
                setOpenDeletePopup(false)
            })
            .catch(() => {
                emitToastNotification('error', 'Failed to delete')
            })
    }

    const onPaginate = (paginate) => {
        if (!stopLazyload) {
            if (paginate && !loadMore) {
                setLoadMore(true)
                getList()
            }
        }
    }

    const filterPopover = (categoryList, classificationList) => ({
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                    classificationRef.current = []
                                    categoryRef.current = []
                                    setFilters({ category: [], classification: [] })
                                    setReset(true);
                                    setIsReady(false)
                                    getList(true)
                                    dispatch({ type: RESET_SAVED_APPLIED_FILTERS, payload: {} })
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setFilterPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                {/* <div className='padding-2' style={{ width: '302px' }}>
                    <div>
                        <Row>
                            <Col span={24} className='d-flex align-items-center justify-content-between'>
                                <label className='mb-0 label color-grey'><span><CATEGORY height='20' /></span> Category</label>

                                <Buttons
                                    props={{
                                        buttonText: '',
                                        buttonClassName: "custom-btn-primary align-items-center custom-btn btn-with-icon",
                                        buttonEvent: (e) => {
                                            setFilterPop(false);
                                            setOpenCategoryModal(true);

                                        },
                                        ImgSrc: () => <PLUS />,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className='d-flex'>

                        <React.Fragment>
                            {categoryRef.current.map((tag, index) => {
                                let catName = categoryList.find(v => v.id === tag).name ?? ''
                                return index < 2 ? <div
                                    //  key={index} 
                                    style={{ marginRight: "5px", marginTop: '5px' }} className="categoryTag">

                                    <Tooltip placement="topLeft" title={catName?.toUpperCase()}>
                                        <span className="d-block text-with-ellipsis" style={{ maxWidth: '104px' }}>
                                            {`${catName?.toUpperCase()}`}
                                        </span>
                                    </Tooltip>

                                </div> : <></>
                            })
                            }
                            {categoryRef.current.length > 2 ? <div style={{ marginTop: '5px' }} className="categoryTag">

                                <Tooltip
                                    placement="topLeft"
                                    title={categoryRef.current?.slice(2).map(item => categoryList.find(v => v.id === item)?.name?.toUpperCase()).join(", ")}>
                                    <span className="">{`+ ${categoryRef.current.length - 2}`}</span>
                                </Tooltip>

                            </div> : null}
                        </React.Fragment>

                    </div>




                </div> */}
                <div className='padding-2 border-top' style={{ width: '18.875rem' }}>
                    <Row>
                        <Col span={24} className='d-flex align-items-center justify-content-between'>
                            <label className='mb-0 label color-grey dict-fil-cls-ic'><span><USERTAG height='22' /></span>Classification</label>

                            <Buttons
                                props={{
                                    buttonText: '',
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-icon",
                                    buttonEvent: (e) => {
                                        setFilterPop(false);
                                        setOpenClassificationModal(true);

                                    },
                                    ImgSrc: () => <PLUS />,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        </Col>
                    </Row>


                    <div className='d-flex' >
                        {/* <div className='podtag-list-scheduler p-1'> */}

                        <React.Fragment>
                            {classificationRef.current.map((tag, index) => {
                                let claName = classificationList.find(v => v.id === tag).name ?? ''
                                return index < 2 ? <div
                                    // key={index}
                                    style={{ marginRight: "5px", marginTop: '5px' }} className="classificationTag">

                                    <Tooltip placement="topLeft" title={claName?.toUpperCase()}>
                                        <span className="d-block text-with-ellipsis" style={{ maxWidth: '104px' }}>
                                            {`${claName?.toUpperCase()}`}
                                        </span>
                                    </Tooltip>

                                </div> : <></>
                            })
                            }
                            {classificationRef.current.length > 2 ? <div style={{ marginTop: '5px' }} className="classificationTag">

                                <Tooltip
                                    placement="topLeft"
                                    title={classificationRef.current?.slice(2).map(item => classificationList.find(v => v.id === item).name?.toUpperCase()).join(", ")}>
                                    <span className="">{`+ ${classificationRef.current.length - 2}`}</span>
                                </Tooltip>


                            </div> : null}
                        </React.Fragment>

                    </div>
                </div>
                {/* <MultiSelection
                            props={{
                                placeholder: "Classification",
                                dropDownValue: filters.classification,
                                label:"",
                                onClickEvent: (val) => {
                                  
                                    handleFilter('classification', classificationRef, val)
                                    setIsReady(false)
                                    setFilters(prev => ({...prev, classification: val}))
                                    classificationRef.current = val
                                    getList(true)
                                },
                                isDisable: false,
                                selectClassName: 'w-100',
                                wrapClassName: 'w-100'
                            }}
                        >
                            {
                                classificationList.map((ele) =>
                                    <Select.Option key={ele.name} value={ele.id}>
                                       {ele.name}
                                    </Select.Option>
                                )
                            }
                        </MultiSelection>  */}


            </>
        }
    })

    const handleFilter = (key, ref, val) => {
        setIsReady(false)
        setFilters(prev => ({ ...prev, [key]: val }))
        ref.current = val
        // console.log({ appliedFilters }, categoryList, classificationList)
        if (!categoryList.length && !classificationList.length) {

            /* dispatch(getSettingsDataCategory())
                .then(res => {
                    setCategories([...res.data])

                })
                .catch((err) => {

                    setLoadingStatus(false);
                }) */

            dispatch(getClassificationData())
                .then(res => {
                    setClassifications([...res.data])
                })
                .catch((err) => {

                    setLoadingStatus(false);
                })
        }
        if (appliedFilters && appliedFilters?.searchKeyword && searchRef.current === '') {
            setValue(appliedFilters.searchKeyword)
            searchRef.current = appliedFilters.searchKeyword
        }
        if (appliedFilters && appliedFilters?.categoryFilter?.length && !categoryRef.current?.length) {
            categoryRef.current = appliedFilters.categoryFilter
            setSelectedCategories(appliedFilters.categoryFilter)


        }
        if (appliedFilters && appliedFilters?.classificationFilter?.length && !classificationRef.current?.length) {

            classificationRef.current = appliedFilters.classificationFilter
        }
        getList(true)
    }

    const onSearch = (val) => {
        if (val.trim() !== '') {
            setIsReady(false)
            setSearching(true)

            getList(true)
        }
    }

    const handleFilterVisibleChange = (visible) => {
        setFilterPop(visible)
    }

    return (
        <div className='h-100 dict-lg-page'>
            <div className='d-flex justify-content-center align-items-center padding-1' style={{ flexDirection: "column" }}>
                <Input.Search
                    prefix={<SEARCH height='20' width='20' />}
                    placeholder="Search dictionary..."
                    allowClear
                    onSearch={onSearch}
                    style={{
                        width: '25rem',
                    }}
                    enterButton={"Search"}
                    size='large'
                    onFocus={() => setFocused(true)}
                    onChange={(e) => {
                        if (/^[_a-zA-Z0-9]*$/g.test(e.target.value)) {
                            setValue(e.target.value)
                        }


                        // if(e.target.value === '') {
                        //     setIsReady(false)
                        //     searchRef.current = ''
                        //     setSearchData('')
                        //     getList(true)
                        // }
                        // else setSearchData(e.target.value)
                    }}
                    loading={isSearching}
                    value={value}
                    className='custom-dict-search-box'
                    suffix={
                        <Popover placement="bottomRight"
                            title={filterPopover(categoryList, classificationList).header}
                            content={filterPopover(categoryList, classificationList).body}
                            trigger="click"
                            autoAdjustOverflow={true}
                            open={showFilterPop}
                            onOpenChange={handleFilterVisibleChange}
                        >
                            <Tooltip placement='bottom' title='Filter'>
                                <button className={`${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"
                                    } custom-btn btn-with-icon border-0 p-0 ${(categoryRef?.current?.length > 0 || classificationRef?.current?.length > 0) ? 'rdg-filter-active' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFilterPop(true)
                                    }
                                    }
                                    style={{ height: 20, width: 20 }}
                                >
                                    <FILTER />
                                </button>
                            </Tooltip>
                        </Popover>
                    }
                />

                {
                    dataCount !== undefined
                        ? <div style={{ marginTop: "20px", color: "lightgray", fontSize: "13px" }}>
                            <p>{`${dataCount} results (${apiCallTime} seconds) `}</p>
                        </div>
                        : ''
                }
            </div>

            {
                empty ? <>
                    <div className='text-center p-5' style={{ opacity: '.7' }}>
                        <span>
                            <DATADICTIONARY width='60' height='60' />
                        </span>
                        <h2 className='h5 mt-2'>
                            Click on (+) to Create Display Name
                        </h2>
                    </div>
                </>
                    : isReady
                        ? <div className='padding-2 pt-0 dict-listing-page' style={{ height: 'calc(100% - 6.875rem)' }}>
                            {rawData && rawData.length? <>
                                
                                 {
                                    layout === 'list'
                                        ? <DataDictionaryListView
                                            listData={rawData}
                                            openDeleteDialog={openDeleteDialog}
                                            dataList={demo}
                                            setColumn={setListColumns}
                                            columns={listColumns}
                                            colsList={colsList}
                                            setColsList={setColsList}
                                            searchData={searchData}
                                            onScrollToBottom={onPaginate}
                                            loadMore={loadMore}
                                            fromRoute={fromRoute}
                                            setselectedIndex={setselectedIndex}
                                            selectedIndex={selectedIndex}
                                        />
                                        : 
                                        <DataDictionaryMiniListView
                                            listData={rawData}
                                            openDeleteDialog={openDeleteDialog}
                                            searchData={searchData}
                                            onScrollToBottom={onPaginate}
                                            loadMore={loadMore}
                                            hideAction={true}
                                            chooseableList={true}
                                            fromRoute={fromRoute}
                                            setselectedIndex={setselectedIndex}
                                            selectedIndex={selectedIndex}
                                        />
                                        
                                        // <DataDictionaryGridView
                                        //     data={rawData}
                                        //     openDeleteDialog={openDeleteDialog}
                                        //     dataList={demo}
                                        //     searchData={searchData}
                                        //     fromRoute={fromRoute}
                                        //     onScrollToBottom={onPaginate}
                                        //     loadMore={loadMore}
                                        //     setselectedIndex={setselectedIndex}
                                        //     selectedIndex={selectedIndex}
                                        // />
                                }
                            </>:
                            <NoSearchResultFound></NoSearchResultFound>}
                        </div>
                        : showSimpleLoading()
                // defaultExpandedPanel.length
                // ?
                //     accordionList.length
                //     ?
                //     <Accordian
                //         props={{
                //             isDisable: false,
                //             accordionList: accordionList,
                //             Icon: () => <ACCORDIONDOWNARROW />,
                //             defaultExpandedPanel: defaultExpandedPanel,
                //         }}
                //     />
                //     :  <NoSearchResultFound/>
                // :   showSimpleLoading('Loading dictionary...')
            }

            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={getDeleteMessage({
                    title: 'Display Name'
                })}
                onConfirm={() => deleteBusinessTerm(currentBusinessTermIdRef?.current)}
            />

            {/* < ActionPopUp
                openModal={openDeletePopup}
                actionData={deletePopUpContent}
            /> */}
            <CategoryModal hideAddButton={true} reset={reset} save={saveCategory} selectedIds={selectedCategories} setSelectedIds={setSelectedCategories} isModalOpen={openCategoryModal} onClose={() => setOpenCategoryModal(false)} />
            <ClassificationModal hideAddButton={true} reset={reset} save={saveClassification} isModalOpen={openClassificationModal} onClose={() => setOpenClassificationModal(false)} />

        </div>
    )
}

let originalColumns = [
    {
        name: 'Data Category',
        key: "name",
        sortable: false,
        isRequired: true,
        visible: true,
        frozen: true,
        resizable: false,
        width: "15%"

    },
    {
        name: 'Display Name',
        key: "business_term",
        sortable: true,
        isRequired: true,
        frozen: true,
        width: "15%",

        visible: true,
        resizable: false
    },
    {
        name: 'Technical Name',
        key: "tech_term",
        sortable: false,

        width: "15%",

        isRequired: true,
        frozen: true,
        visible: true,
        resizable: false
    },
    /* {
        name: 'Techincal Terms',
        key: "column_techincal_terms",
        sortable: false,
        resizable: false,
        width: 300
    }, */
    {
        name: 'Description',
        key: "description",
        sortable: true,
        visible: true,
        isRequired: true,
        resizable: false,
        width: "25%",
    },
    {
        name: 'Classifications',
        key: "classifications",
        sortable: true,
        isRequired: true,
        visible: true,
        resizable: false,
        width: "15%"
        // width:
    },

    {
        name: 'Owners',
        key: "owners",
        sortable: false,
        resizable: false,
        isRequired: true,
        visible: true,
        width: "10%"


    },
    {
        name: 'Stewards',
        key: "stewards",
        sortable: false,
        resizable: false,
        visible: true,
        isRequired: true,
        width: "10%"
    },
    /* {
        name: 'Domain Name',
        key: "domain_name",
        sortable: true,
        resizable: false
    }, */
    {
        name: <div className='text-center'>Action</div>,
        key: "action_btn",
        sortable: false,
        resizable: false,
        width: "10%"
    }
];