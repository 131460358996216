import { combineReducers } from "redux";

import UserGroupReducer from "./userGroup/UMReducer";
import DataDomain from './dataDomains/dataDomainReducer';
import AccessManagement from './accessManagement/accessManagementReducer';
import ConnectionReducer from './connection/connectionReducer'
import Users from "./users/usersReducer";

const UserManagement = combineReducers({
    DataDomain,
    UserGroupReducer,
    AccessManagement,
    ConnectionReducer,
    Users
});

export default UserManagement;
