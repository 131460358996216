export const SET_FILTER_CRITERIA='SET_FILTER_CRITERIA'
export const SET_SELECETED_RULES_COLUMN='SET_SELECETED_RULES_COLUMN'
export const SET_UNIQUENESS_RULES_COLUMN='SET_UNIQUENESS_RULES_COLUMN'
export const SET_CONDITION_CRITERIA='SET_CONDITION_CRITERIA'
export const SET_DISTINCT_ROWS='SET_DISTINCT_ROWS' 
export const SET_RULE_DETAIL='SET_RULE_DETAIL'
export const SET_RECENTRUNS_DATA = 'SET_RECENTRUNS_DATA'
export const SET_ALERT_LIST = 'SET_ALERT_LIST'
export const SET_RULES_CATEGORY = 'SET_RULES_CATEGORY'
export const SET_RULES_TYPE = 'SET_RULES_TYPE'
export const SET_RULES_SCHEDULE_BODY = 'SET_RULES_SCHEDULE_BODY'

export const SET_RULES_QUERY_RESULT = 'SET_RULES_QUERY_RESULT'
export const SET_RULES_LOGICAL_RULE = 'SET_RULES_LOGICAL_RULE'
export const SET_RULES_RETRIEVE_DATA = 'SET_RULES_RETRIEVE_DATA'

export const SET_RULES_PUBLISH="SET_RULES_PUBLISH"
export const SET_PREVIEW_TABLE_RESPONSE="SET_PREVIEW_TABLE_RESPONSE"
export const SET_RULES_PREVIEW_QUERY="SET_RULES_PREVIEW_QUERY"
export const SET_RULES_PREVIEW_FLAG="SET_RULES_PREVIEW_FLAG"

export const SET_RULES_PREVIEW_TABLE = "SET_RULES_PREVIEW_TABLE";
export const  SET_RULES_PREVIEW_TABLE_ROWSET=" SET_RULES_PREVIEW_TABLE_ROWSET"
export const  SET_RULES_PREVIEW_TABLE_RECORDS=" SET_RULES_PREVIEW_TABLE_RECORDS"
export const  SET_RULES_PREVIEW_INITIAL_STATE=" SET_RULES_PREVIEW_INITIAL_STATE"
export const  SET_RULES_PREVIEW_NEXT_URI=" SET_RULES_PREVIEW_NEXT_URI"
export const  SET_RULES_PREVIEW_FILTERS=" SET_RULES_PREVIEW_FILTERS"
export const  SET_RULES_PREVIEW_SORT=" SET_RULES_PREVIEW_SORT"
export const SET_RULES_REFRESH = "SET_RULES_REFRESH"
export const SET_RULES_JSON_TREE = "SET_RULES_JSON_TREE"
export const SET_RULES_COMPLEX_QUERY = "SET_RULES_COMPLEX_QUERY"


export const  SET_RULES_PREVIEW_SEARCH_TERM=" SET_RULES_PREVIEW_SEARCH_TERM"
export const RESET_NEXT_RECORD_TOKEN="RESET_NEXT_RECORD_TOKEN"
export const SET_RULES_QUERY_ERROR="SET_RULES_QUERY_ERROR"




export const SET_CORELATION_GRAPH="SET_CORELATION_GRAPH"
export const RESET_TABLE_RECORDS="RESET_TABLE_RECORDS"
export const SET_NEXT_PAGE_COUNTER="SET_NEXT_PAGE_COUNTER"

export const RESET_NEXT_PAGE_COUNTER="RESET_NEXT_PAGE_COUNTER"
export const SET_ALL_DATA_LOADED="SET_ALL_DATA_LOADED"
export const SET_ROW_COUNT="SET_ROW_COUNT"
export const SET_QUERY_ERROR="SET_QUERY_ERROR"

export const SET_CREATE_RULE_PAGE_DEFAULT = "SET_CREATE_RULE_PAGE_DEFAULT";


export const SET_RETRIVE_RULE_RESPONSE="SET_RETRIVE_RULE_RESPONSE"

export const SET_SEARCH_RESULT_NOT_FOUND = "SET_SEARCH_RESULT_NOT_FOUND";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";

export const SET_DOMAIN_LIST = "SET_DOMAIN_LIST";

export const SET_PURPOSE_LIST = "SET_PURPOSE_LIST";

