import React from "react";
import { useNavigate } from "react-router-dom";
import "./CreatePolicySecondaryHeader.scss";

import Buttons from "../../../../Common/button/Buttons";
import { Literals } from "../../../common/literals";
import {
  ACCESSMANAGMENT,
  CLOSE,
  DATADOMAIN,
  DATASET,
  DOMAIN_SMALL,
  PODICON,
  RIGHTARROW,
  SAVE,
} from "../../../../Common/iconSource";
import { ICON_EDITBOX } from "../../../../Common/newIconSource";

const CreatePolicySecondaryHeader = ({
  podId,
  policyName,
  domainName,
  tableName,
  policyAction,
  policyId,
  viewMode,
  isEditorPermission = false
}) => {
  const history = useNavigate();

  const onClosePageHandler = () => {
    history(`${Literals.links.DATA_CATALOG_POD_DETAILS}${podId}/`, { replace: true, state: { fromRoute: "accessmanagement" } });
  };

  const onEditPolicyHandler = () => {
    history(`${Literals.links.ACCESS_MANAGEMENT_EDIT_POLICY}${podId}?policyId=${policyId}`);
  };

  return (
    <div className="sub-header create-policy-secondary-header">
      <div className="sub-header-left pod-meta-headings">
        <span className="subtitle-datacatlog-icon">
          <DATADOMAIN height="16" width="16" />
          <span className="ml-1"> {domainName?.toUpperCase()}</span>
        </span>
        <span className="subtitle-datacatlog-icon mr-2">
        <span className="ml-0"> <RIGHTARROW /></span>
          <span className="ml-2"><PODICON height="16" width="16" /></span>
          <span className="ml-1"> {tableName?.toUpperCase()}</span>
        </span>
        {policyName && (
          <span className="policy-name">
            <RIGHTARROW />
            <ACCESSMANAGMENT />
            {policyName}
          </span>
        )}
      </div>
      <div className="sub-header-right">
        <Buttons
          props={{
            buttonText: "",
            buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
            buttonEvent: () => {
              onClosePageHandler();
            },
            ImgSrc: () => <CLOSE />,

            isDisable: false,
            buttonType: Literals.BTN_TERTIARY,
          }}
        />
        <Buttons
          props={{
            buttonText: viewMode.includes("view") ? "Edit" : "Save",
            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
            buttonEvent: () => {
              if (viewMode.includes("view")) {
                onEditPolicyHandler();
              } else {
                policyAction();
              }
            },
            ImgSrc: () => viewMode.includes("view") ? <ICON_EDITBOX/> : <SAVE />,
            isDisable: !isEditorPermission,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
      </div>
    </div>
  );
};

export default CreatePolicySecondaryHeader;
