import React, { useEffect, useMemo, useState } from 'react'
import DomainDropdown from './DomainDropdown'
import DurationDropdown from './DurationDropdown'
import UserDropdown from './UserDropdown'
import ModuleDropdown from './ModuleDropdown'
import { LandingSimpleTableInner } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import { DatePicker, Tooltip } from 'antd'
import SourceTypeImage from '../../common/sourceTypeImage'
import { ICON_CATALOG, ICON_SQLFILE } from '../../../Common/newIconSource'
import { useStore } from 'react-redux'
import moment from 'moment'
import { Literals } from '../../common/literals'
import { getUsageActivity } from '../../../../store/modules/jobsUsage/jobsAction'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { cloneDeep } from 'lodash'
import { dateformater, NoDataComponent, pxToRem, remToPx, showSimpleLoading } from '../../common/helperFunctions'
import NoResultsFound from '../../flowsNew/createFlows/NoResultsFound'
import { useNavigate } from 'react-router-dom'
import ActionDropdown from './ActionDropdown'
import { ModalBody, ModalFooter, Modal, ModalHeader } from 'reactstrap'
import { CLOSE, EDITOR2 } from '../../../Common/iconSource'
import SqlEditor from '../../dataCatalogue/preview/components/SqlEditor'
import Buttons from '../../../Common/button/Buttons'



const actionColorMap = {
    updated: '#28A745',
    created: '#275A89',
    deleted: '#CB1010',
    read: '#343A40',
    'logged in': '#0FA4D3',
    'logged out': '#CB8E18',
}


const mod = {
    pod: 'pod',
    flows: 'flow',
    scheduler: 'scheduler',
    dashboard: 'dashboard',
    chart: 'chart',
    connection: 'connection',
    domain: 'data_domain',
    user: 'user',
    usergroup: 'group',
    openAi: 'control_centre',
    downloaded: 'download',
}

const UsageActivity = ({ changeTab }) => {

    const [activeModule, setActiveModule] = React.useState('pod')
    const store = useStore();
    const { RangePicker } = DatePicker;
    const [timelineData, setTimelineData] = useState([])

    const [isRefreshing, setRefreshing] = useState(false)
    const [noDataFound, setNoDataFound] = useState(false)

    const [loadMore, setLoadMore] = useState(false)

    const [stopLazyload, setStopLazyload] = useState(false)

    const [pageNo, setPageNo] = useState(1)
    const [openModal, setOpenModal] = useState(false)
    const [logDetails, setlogDetails] = useState({})


    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const [datePickerValue, setDatePickerValue] = useState(null);

    const [dataCount, setDataCount] = useState();
    const [selectedUser, setselectedUser] = useState('');
    const [selectedDomain, setselectedDomain] = useState('');
    const [selectedAction, setselectedAction] = useState('');
    const [value, setValue] = useState(null);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');


    const getSummaryTimelineData = async (pageno, oldtimelineData) => {
        setRefreshing(true);

        let payload = {
            page: pageno ?? pageNo,
            content_type: activeModule?.toUpperCase(),
            userEmail: selectedUser?.email && selectedUser?.email !== 'all' ? selectedUser?.email : undefined,
            data_domain_id: selectedDomain && selectedDomain !== 'all' ? selectedDomain : undefined,
            start_date: filterStartDate ?? undefined,
            end_date: filterEndDate ?? undefined,
            event_type: selectedAction && selectedAction !== 'all' ? selectedAction?.toUpperCase() : undefined,
            limit: 50,

        };

        let url = `usage/log/?content_type=${payload?.content_type}&page=${payload.page}&limit=${payload.limit}${payload?.event_type ? '&event_type=' + payload?.event_type : ''}${payload?.userEmail ? '&email=' + payload?.userEmail : ''}${payload?.start_date ? '&date_range_after=' + payload?.start_date : ""}${payload?.end_date ? '&date_range_before=' + payload?.end_date : ""}${payload?.data_domain_id ? '&data_domain_id=' + payload?.data_domain_id : ""}`;

        try {
            let response = await store.dispatch(getUsageActivity(url));
            if (response.status === "success" && response?.data?.results) {

                setTimelineData([...oldtimelineData, ...response?.data?.results])


                setLoadMore(false);

                if (response?.data?.has_next) {

                    setPageNo(prev => prev + 1)
                }
                if (!response?.data?.has_next && response?.data?.results) {
                    setNoDataFound(true)
                }

                setDataCount(response?.data?.results?.length)


            }
            if (response.status === "success" && !response?.data?.has_next) {  // response.message === "Invalid Page Requested"
                if (loadMore) {

                    emitToastNotification('info', Literals.NO_MORE_USAGE_LOGS_TO_LOAD)
                }

                setStopLazyload(true)
                setLoadMore(false)
            }
            setRefreshing(false);

        } catch (error) {
            setRefreshing(false);
            setLoadMore(false);
        }
    };

    const onOpenChange = (open) => {
        if (open) {
            setDatePickerValue([null, null]);
        } else {
            setDatePickerValue(null);
        }
    };


    let isGodUser = JSON.parse(localStorage.getItem('user'))?.userDetails?.is_goduser ?? false//store.getState.UserReducer.user?.userDetails?.is_goduser;
    let isFirstUser = JSON.parse(localStorage.getItem('user'))?.userDetails?.is_firstuser ?? false //store.getState.UserReducer.user?.userDetails?.is_firstuser;
    let isSuperUser = JSON.parse(localStorage.getItem('user'))?.userDetails?.is_superuser ?? false //store.getState.UserReducer.user?.userDetails?.is_firstuser;



    const redirectTo = (id, element) => {

        switch (activeModule) {
            case mod.pod:

                return window.open(`${Literals.links.DATA_CATALOG_POD_DETAILS}${id}`, '_blank')

            case mod.flows:

                return window.open(`${Literals.links.FLOWS}/${id}`, '_blank')
            case mod.scheduler:

                return window.open(`${Literals.links.SCHEDULER_VIEW}${id}`, '_blank')

            case mod.dashboard:
                return window.open(`${Literals.links.DASHBOARD}/${id}`, '_blank')

            case mod.chart:
                return window.open(`${Literals.links.CHART}/${id}`, '_blank')

            // case mod.chart:
            //     return window.open(`${Literals.links.DATA_CATALOG_POD_DETAILS}${chartPodId}?chartId=${chartId}&chartName=${chartName}#charts`,'_blank')

            case mod.openAi:
                if (isSuperUser || isFirstUser || isGodUser) {

                    return window.open(`${Literals.links.USER_MANAGEMENT}/?openAi=true#users`, '_blank')
                } else {
                    return;
                }

            case mod.connection:

                return window.open(`${Literals.links.CONNECTIONS}/?connection_name=${element?.content_name}&open=true`, '_blank')

            case mod.usergroup:

                return window.open(`${Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS}${id}`, '_blank')

            // case mod.downloaded:
            //     return downDemoData()
            case mod.domain:

                return window.open(`${Literals.links.USER_MANAGEMENT_DATA_DOMAIN}${id}`, '_blank')

            case mod.user:

                return window.open(`${Literals.links.USER_MANAGEMENT_USER}${id}`, '_blank')


            default:
                return
            // return podDemoData()
        }
    }

    const dynamic = px => remToPx(pxToRem(px))

    const getFieldTemplate = (key, data, element, index = 0) => {
        switch (activeModule + '-' + key) {
            case mod.domain + '-changes':
                return <Tooltip title={data} placement='bottomLeft'>
                    <div className='text-with-ellipsis'>
                        <i style={{ fontSize: 7, color: '#D9D9D9' }} className='bx bxs-circle mx-1'></i> {data}  <span className='text-uppercase nusg-highlight-color fontInterSemiBold'>{element}</span>
                    </div>
                </Tooltip>
            case mod.connection + '-changes':
            case mod.usergroup + '-changes':
                return <Tooltip title={data} placement='bottomLeft'>
                    <div className='text-with-ellipsis'>
                        <i style={{ fontSize: 7, color: '#D9D9D9' }} className='bx bxs-circle mx-1'></i>  {data}  <span className='text-uppercase nusg-highlight-color fontInterSemiBold'>{element}</span>
                    </div>
                </Tooltip>
            case mod.connection + '-name':
                return <div className='d-flex'>
                    <SourceTypeImage dataName={element?.details?.source_type} />
                    <div className='text-with-ellipsis ml-1'>
                        <Tooltip title={data} placement='bottomLeft'><span style={{ cursor: element?.event_type?.toLowerCase() !== 'deleted' ? "pointer" : "default" }} className={`${'-' + key}`}
                            onClick={() => {
                                if (key === 'name' && element?.event_type?.toLowerCase() !== 'deleted') {
                                    redirectTo(element?.content_id, element)
                                }
                            }}>{data}</span></Tooltip>
                    </div>
                </div >

            case mod.downloaded + '-name':
                return <Tooltip title={element?.details?.query ? null : data} placement='bottomLeft'>
                    <div className='d-flex'>
                        <div className='d-flex align-items-center'>
                            {
                                element?.details?.query ? <ICON_SQLFILE height={dynamic(14)?.toString()} width={dynamic(14)?.toString()} />
                                    : <ICON_CATALOG height={dynamic(14)?.toString()} width={dynamic(14)?.toString()} />
                            }
                        </div>
                        <div className='w-90' style={{ lineHeight: element?.details?.query && element?.content_name ? 2 : 'unset' }}>
                            <div style={{ height: element?.details?.query && element?.content_name ? '1.0625rem' : 'auto' }} className='text-with-ellipsis ml-1'>
                                <span style={{ cursor: element?.details?.query ? "pointer" : "default" }} className={`${'-' + key}`}
                                    onClick={() => {
                                        if (element?.details?.query) {
                                            setlogDetails(element)
                                            setTimeout(() => {
                                                setOpenModal(true);
                                            }, 500);
                                        }

                                    }}
                                >{data}</span>
                            </div>
                            {element?.details?.query && element?.content_name ?
                                <label className='label text-with-ellipsis ml-1 mb-0' style={{ cursor: "default", lineHeight: 1, color: "#495057" }} >{element?.content_name}</label>
                                : null}
                        </div>


                    </div>
                </Tooltip>
            case mod.pod + '-name':
            case mod.flows + '-name':
            case mod.scheduler + '-name':
            case mod.dashboard + '-name':
            case mod.chart + '-name':
            case mod.openAi + '-name':
            case mod.domain + '-name':
            case mod.usergroup + '-name':
            case mod.user + '-name':
            case mod.pod + '-done_by':
            case mod.flows + '-done_by':
            case mod.scheduler + '-done_by':
            case mod.dashboard + '-done_by':
            case mod.chart + '-done_by':
            case mod.openAi + '-done_by':
            case mod.connection + '-done_by':
            case mod.domain + '-done_by':
            case mod.usergroup + '-done_by':
            case mod.user + '-done_by':
            case mod.downloaded + '-downloaded_by':
            case mod.user + '-email':
                return <div className={`text-with-ellipsis text-capitalize`}><Tooltip title={data} placement='bottomLeft'><span style={{ cursor: element?.event_type?.toLowerCase() !== 'deleted' ? "pointer" : "default" }} className={`${'-' + key}`}
                    onClick={() => {
                        if (key === 'name' && element?.event_type?.toLowerCase() !== 'deleted') {
                            redirectTo(element?.content_id, element)
                        }
                    }}>{data}</span> </Tooltip></div>

            case mod.pod + '-time':
            case mod.flows + '-time':
            case mod.scheduler + '-time':
            case mod.dashboard + '-time':
            case mod.chart + '-time':
            case mod.openAi + '-time':
            case mod.user + '-time':
            case mod.usergroup + '-time':
            case mod.downloaded + '-time':
            case mod.domain + '-time':
            case mod.connection + '-time':
            case mod.user + '-time':
                return <div className='text-left'>
                    {data}
                </div>
            case mod.pod + '-action':
            case mod.flows + '-action':
            case mod.scheduler + '-action':
            case mod.dashboard + '-action':
            case mod.chart + '-action':
            case mod.openAi + '-action':
            case mod.user + '-action':
            case mod.usergroup + '-action':
            case mod.downloaded + '-action':
            case mod.domain + '-action':
            case mod.connection + '-action':
                return <div className='fontInterSemiBold' style={{ color: actionColorMap[data?.toLowerCase()] }}>
                    {data}
                </div>

            default:
                return <></>
        }
    }

    const podDemoData = () => {
        let arr = cloneDeep(timelineData)
        // for (let index = 0; index < 40; index++) {
        //     arr?.push(
        //         podDemo[index]
        //         ??
        //         { name: 'SAME_NAME FLOW ' + index, action: index % 2 === 0 ? 'Created' : 'Deleted', done_by: 'Aman Kumar', time: '13 Sep 2024, 1:58 PM' }
        //     )
        // }

        arr = arr?.map(el => (
            {
                name: getFieldTemplate('name', el?.content_name, el),
                action: getFieldTemplate('action', el?.event_type),
                done_by: getFieldTemplate('done_by', `${el?.user_detail?.first_name} ${el?.user_detail?.last_name}`),
                time: getFieldTemplate('time', dateformater(el?.timestamp, 'datetime')),

            }
        ))
        return arr
    }

    const connDemoData = () => {
        let arr = cloneDeep(timelineData)
        // for (let index = 0; index < 40; index++) {
        //     arr?.push(
        //         connDemo[index]
        //         ??
        //         { source: 'snowflake', changes: 'Leader', name: 'CONN_SAME_NAME FLOW ' + index, action: index % 2 === 0 ? 'Created' : 'Deleted', done_by: 'Aman Kumar', time: '13 Sep 2024, 1:58 PM' }
        //     )
        // }

        arr = arr?.map(el => (
            {
                name: getFieldTemplate('name', el?.content_name, el),
                changes: getFieldTemplate('changes', el?.message?.split('|')[0]?.trim() ?? '', el?.message?.split('|')[1]?.trim() ?? ''),
                action: getFieldTemplate('action', el?.event_type),
                done_by: getFieldTemplate('done_by', `${el?.user_detail?.first_name} ${el?.user_detail?.last_name}`),
                time: getFieldTemplate('time', dateformater(el?.timestamp, 'datetime')),

            }
        ))

        return arr
    }

    const domainDemoData = () => {
        let arr = cloneDeep(timelineData)
        // for (let index = 0; index < 40; index++) {
        //     arr?.push(
        //         connDemo[index]
        //         ??
        //         { source: 'snowflake', changes: 'Leader', name: 'CONN_SAME_NAME FLOW ' + index, action: index % 2 === 0 ? 'Created' : 'Deleted', done_by: 'Aman Kumar', time: '13 Sep 2024, 1:58 PM' }
        //     )
        // }

        arr = arr?.map(el => (
            {
                name: getFieldTemplate('name', el?.content_name, el),
                changes: getFieldTemplate('changes', el?.message?.split('|')[0]?.trim() ?? '', el?.message?.split('|')[1]?.trim() ?? ''),
                action: getFieldTemplate('action', el?.event_type),
                done_by: getFieldTemplate('done_by', `${el?.user_detail?.first_name} ${el?.user_detail?.last_name}`),
                time: getFieldTemplate('time', dateformater(el?.timestamp, 'datetime')),
            }
        ))

        return arr

        return arr
    }





    const userDemoData = () => {
        let arr = cloneDeep(timelineData)
        // for (let index = 0; index < 40; index++) {
        //     arr?.push(
        //         { email: 'aman@clarista.io', name: 'CONN_SAME_NAME FLOW ' + index, action: index % 2 === 0 ? 'Created' : 'Deleted', done_by: 'Aman Kumar', time: '13 Sep 2024, 1:58 PM' }
        //     )
        // }

        arr = arr?.map(el => (
            {
                name: getFieldTemplate('name', `${el?.user_detail?.first_name} ${el?.user_detail?.last_name}`, el),
                email: getFieldTemplate('email', el?.content_name, el),
                action: getFieldTemplate('action', el?.event_type),
                done_by: getFieldTemplate('done_by', `${el?.user_detail?.first_name} ${el?.user_detail?.last_name}`),
                time: getFieldTemplate('time', dateformater(el?.timestamp, 'datetime')),
            }
        ))

        return arr
    }

    const downDemoData = () => {
        let arr = cloneDeep(timelineData)
        // for (let index = 0; index < 40; index++) {
        //     arr?.push(
        //         { name: index % 2 === 0 ? "SELECT * FROM PASSENGER WHERE ID = '122324434'" : 'ELAPSED TIME FLOW ' + index, type: index % 2 === 0 ? 'sql' : 'pod', downloaded_by: 'Aman Kumar', time: '13 Sep 2024, 1:58 PM' }
        //     )
        // }

        arr = arr?.map((el, index) => (
            {
                name: getFieldTemplate('name', el?.details?.query ? el?.details?.query : el?.content_name, el, index),
                downloaded_by: getFieldTemplate('downloaded_by', `${el?.user_detail?.first_name} ${el?.user_detail?.last_name}`, index),
                time: getFieldTemplate('time', dateformater(el?.timestamp, 'datetime'), index),
            }
        ))

        return arr
    }

    /**
     * Type1 Cols Modules: Pod, Flows, Scheduler, Dashboard, Chart, OpenAi
     */
    const columnsType1 = [
        {
            name: 'Name',
            key: "name",
            sortable: false,
            resizable: false,
        },
        {
            name: 'Action',
            key: "action",
            sortable: false,
            resizable: false,
        },
        {
            name: 'Done By',
            key: "done_by",
            sortable: false,
            resizable: false
        },
        {
            name: <div className='text-left'>Time</div>,
            key: "time",
            sortable: false,
            resizable: false,
        }
    ]

    /**
     * Type2 Cols Modules: Connection, Domain, Scheduler, Usergroup,
     */
    const columnsType2 = [
        {
            name: 'Name',
            key: "name",
            sortable: false,
            resizable: false,
        },
        {
            name: <div className='text-left pl-1'>Changes Made</div>,
            key: "changes",
            sortable: false,
            resizable: false,
        },
        {
            name: 'Action',
            key: "action",
            sortable: false,
            resizable: false,
        },
        {
            name: 'Done By',
            key: "done_by",
            sortable: false,
            resizable: false,
        },
        {
            name: <div className='text-left'>Time</div>,
            key: "time",
            sortable: false,
            resizable: false,
        }
    ]

    const userModuleColumns = [
        {
            name: 'Name',
            key: "name",
            sortable: false,
            resizable: false,
        },
        {
            name: 'Email Address',
            key: "email",
            sortable: false,
            resizable: false,
        },
        {
            name: 'Action',
            key: "action",
            sortable: false,
            resizable: false,
        },
        {
            name: 'Done By',
            key: "done_by",
            sortable: false,
            resizable: false
        },
        {
            name: <div className='text-left'>Time</div>,
            key: "time",
            sortable: false,
            resizable: false,
        }
    ]

    const dataDownloadedColumns = [
        {
            name: 'File Name',
            key: "name",
            sortable: false,
            resizable: false,
            width: '50%',
        },
        {
            name: 'Downloaded By',
            key: "downloaded_by",
            sortable: false,
            resizable: false,
            width: '25%',
        },
        {
            name: <div className='text-left'>Time</div>,
            key: "time",
            sortable: false,
            resizable: false,
            width: '25%',
        }
    ]


    const rowsData = React?.useMemo(() => {

        switch (activeModule) {
            case mod.pod:
            case mod.flows:
            case mod.scheduler:
            case mod.dashboard:
            case mod.chart:
            case mod.openAi:

                return podDemoData()
            case mod.connection:
            case mod.usergroup:
                return connDemoData()
            case mod.downloaded:
                return downDemoData()
            case mod.domain:
                return domainDemoData()
            case mod.user:
                return userDemoData()

            default:
                return podDemoData()
        }
    }, [timelineData])

    useMemo(() => {
        let el = document.querySelector('.custom-simple-landing-dgt')
        if (el) {
            el.scrollTop = 0;
        }
        setStopLazyload(false)
        setPageNo(1);

        getSummaryTimelineData(1, []);

        setTimeout(() => {
            let el = document.querySelector('.custom-simple-landing-dgt')
            if (el)
                el.scrollTop = 0;
        }, 1000);

    }, [activeModule, selectedDomain, selectedAction, selectedUser, filterStartDate, filterEndDate])


    const columns = React?.useMemo(() => {

        switch (activeModule) {
            case mod.pod:
            case mod.flows:
            case mod.scheduler:
            case mod.dashboard:
            case mod.chart:
            case mod.openAi:
                return columnsType1
            case mod.connection:
            case mod.domain:
            case mod.usergroup:
                return columnsType2
            case mod.user:
                return userModuleColumns
            case mod.downloaded:
                return dataDownloadedColumns
            default:
                return columnsType1
        }
    }, [activeModule])

    useEffect(() => {
        if (dataCount === 0) {
            setNoDataFound(true)
        }
        else {
            setNoDataFound(false)
        }
    }, [dataCount])


    const onScrollToBottom = (isAtBottom) => {

        if (!stopLazyload) {
            if (isAtBottom && !loadMore) {
                setLoadMore(true)
                getSummaryTimelineData(pageNo, timelineData)
            }
        }
    }

    const RowSelection = (row) => {

    }
    const onCalendarChange = (dates, dateString, info) => {
        const currentDate = new Date();
        if (dateString[0] && dateString[1]) {
            let startDate = dateString[0];
            let endDate = dateString[1];

            if ((new Date(startDate) > currentDate || new Date(endDate) > currentDate)) {
                emitToastNotification('warning', Literals.CANNOT_SELECT_DATE_GREATER_THAN_CURRENT_DATE)

                return;
            }
            let temp = [moment(startDate, "YYYY-MM-DD"), moment(endDate, "YYYY-MM-DD")];
            setFilterStartDate(startDate)
            setFilterEndDate(endDate)
            setDatePickerValue(temp);
        }
        else {
            setFilterStartDate('')
            setFilterEndDate('')
        }
    }

    const disabledDate = (current) => {

        return current && (current > moment().endOf('day') || current < currentDate);
    }

    const onModuleChange = (module) => {
        setActiveModule(module)
        setselectedDomain('all')
        setselectedUser('all')
        setselectedAction('all')
        setDatePickerValue([null, null]);
        setValue(null);
        setFilterStartDate('')
        setFilterEndDate('');
        setTimeout(() => {
            let el = document.querySelector('.custom-simple-landing-dgt')
            if (el)
                el.scrollTop = 0;
        }, 1000);
    }

    // function isEllipsisActive(elementid) {
    //     let element= document.querySelector(`${elementid}`)
    //     if (element && element.clientWidth < element.scrollWidth) {
    //         var style = element.currentStyle || window.getComputedStyle(element);
    //         return style.textOverflow === 'ellipsis'
    //     }
    //     return false;
    // }

    return (
        <div className='new-usage-ativity-wrapper h-100'>
            <div className='new-usg-heading-wrap d-flex align-items-center py-2 justify-content-between'>
                <div>
                    <label className='mb-0 usg-act-log-heading fontInterSemiBold text-capitalize fontSizeLargeHeading'>
                        {activeModule} Logs
                    </label>
                </div>
                <div className='d-flex align-items-center nusg-activity-filter-wrap'>

                    {activeModule?.toLocaleLowerCase() !== mod.downloaded && activeModule?.toLocaleLowerCase() !== mod.openAi ? <><DomainDropdown changeTab={changeTab} selectedDomain={selectedDomain}
                        setselectedDomain={setselectedDomain} />
                        <div className='vertical-separator'></div></> : null}
                    <UserDropdown changeTab={changeTab} setselectedUser={setselectedUser} selectedUser={selectedUser} />
                    <div className='vertical-separator'></div>
                    {activeModule?.toLocaleLowerCase() !== mod.downloaded ? <><ActionDropdown changeTab={changeTab} selectedAction={selectedAction}
                        setselectedAction={setselectedAction} activeModule={activeModule} />
                        <div className='vertical-separator'></div></> : null}
                    {/* <DurationDropdown
                        defaultSelectedKey='monthly'
                        menuItems={[
                            {
                                label: 'Hourly',
                                key: 'hourly',
                            },
                            {
                                label: 'Daily',
                                key: 'daily',
                            },
                            {
                                label: 'Monthly',
                                key: 'monthly',
                            },
                            {
                                label: 'Yearly',
                                key: 'yearly',
                            },
                        ]}
                    /> */}

                    <div id='usage-filter-range-picker' style={{ width: '12.5rem' }}>
                        {

                            <>
                                <label className='label'>Select Timerange</label>
                                <RangePicker className='w-100 usage-filter-range-picker' value={datePickerValue || value}
                                    onCalendarChange={onCalendarChange}
                                    disabledDate={disabledDate}
                                    onChange={(val) => { setValue(val) }}
                                    // format={'DD-MM-YYYY'}
                                    onOpenChange={onOpenChange} />
                            </>

                        }
                    </div>
                    <div className='vertical-separator'></div>
                    <ModuleDropdown
                        defaultActiveModule={'pod'}
                        setActiveModule={(module) => { onModuleChange(module) }}
                    />
                </div>
            </div>
            <div className={`nusg-activity-table-wrap col-holding-${columns?.length}`}>
                {timelineData && timelineData.length > 0 ? <LandingSimpleTableInner
                    key={activeModule + '-table'}
                    cols={columns ?? []}
                    rowsData={rowsData ?? []}
                    autoWidth={true}
                    showCursor={false}
                    onRowClick={RowSelection}
                    onScrollToBottom={onScrollToBottom}
                    autoScroll={pageNo > 1}
                    loadMore={loadMore}

                /> : isRefreshing ? showSimpleLoading('Please wait activity logs are loding...') : noDataFound ? <NoResultsFound height='calc(100vh - 20vh)' /> : null}
            </div>
            <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true} onClosed={() => setOpenModal(false)}>
                <ModalHeader close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className='d-flex'>
                            <ICON_SQLFILE height={dynamic(20)?.toString()} width={dynamic(20)?.toString()} />
                            <h2 className="section-heading">{"Query"}</h2>
                        </div>
                        <div className='mb-n1 mr-3 small'>
                            <label className='d-block mb-0  text-right' style={{ lineHeight: 2 }}>{logDetails?.details?.filename ? <div style={{ width: '300px' }} className='text-with-ellipsis'> File Name:  <span title={logDetails?.details?.filename} style={{ color: "#2c83c4", fontWeight: "500" }}  >{logDetails?.details?.filename}</span> </div> : null}</label>
                            <label className='mb-0 label text-black-50  d-block text-right'>Downloaded by: {`${logDetails?.user_detail?.first_name} ${logDetails?.user_detail?.last_name}`}</label>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='usage-drawer'>
                        <div>
                            <div className='py-2'>
                                <div className='row mx-n2'>
                                    <div className='col-12 mb-4'>
                                        <div className='usage-sql-editor-wrap border rounded p-1'>
                                            <SqlEditor
                                                QueryValue={logDetails?.details?.query}
                                                width={"100%"}
                                                readOnly={true}
                                                showGutter={false}
                                                wrapEnabled={true}
                                                fromRoute={'usage'}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
        </div>

    )
}

export default UsageActivity
