import { SET_NEW_DATABASE_CONNECTION_DATA } from "./actionType";

const Datasource = (state = { newConnection: {} }, action) => {
  // const { type, payload } = action;

  switch (action.type) {
    case SET_NEW_DATABASE_CONNECTION_DATA:
      state = { ...state };
      state.newConnection[action.payload.name] = action.payload.value;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Datasource;
