import { Popover } from 'antd'
import React from 'react'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { useDispatch, useSelector } from 'react-redux'
import { getDataDomainList, setDataDomainList } from '../../../../store/actions'
import { ICON_ARROW_RIGHT, ICON_CATALOG_DOMAIN } from '../../../Common/newIconSource'

const TraceDomainList = ({
    activeDomain = "",
    setParentDomain = () => {},
    userSelectedOomain = () => {},
    useCustomDomainList = false,
    customDomainList = []
}) => {

    const dispatch = useDispatch()

    const [loadingDomains, setLoadingDomains] = React.useState(false)
    const [domainError, setDomainError] = React.useState(undefined)
    const [domains, setDomains] = React.useState([])
    const [domainSearch, setDomainSearch] = React.useState('')

    const { datadomainList } = useSelector(state => state.UserManagement.DataDomain)

    React.useEffect(() => {
        if(datadomainList?.length === 0 && !useCustomDomainList) {
            setDomainError(undefined)
            setLoadingDomains(true)
            dispatch(getDataDomainList(false, false))
            .then(
                res => {
                    if(res?.data?.length) {
                        setDomains([...res?.data])
                    }
                    dispatch(setDataDomainList(res.data))
                    setLoadingDomains(false)
                }
            )
            .catch((err) => {
                setLoadingDomains(false)
                setDomainError(err?.message)
            })
        }
        else {
            setDomains([...datadomainList])
        }
    }, [])

    const domainOptions = React.useMemo(() => {

        if(useCustomDomainList) {
            return customDomainList
        }

        let list = domains?.map((item) => {
            return {
                key: item?.data_domain_id?.toString(),
                value: item.data_domain_id,
                Name: item?.data_domain_name?.replaceAll("_"," ")?.toUpperCase(),
                domain: item?.data_domain_name,
            }
        })
        return list
    }, [domains, useCustomDomainList, customDomainList])
    
    return (
        <>
            <Popover
                trigger={'hover'}
                placement='bottomLeft'
                arrow={false}
                rootClassName='feed-dm-li'
                className='d-flex align-items-center'
                title={
                    <>
                        <div className='d-flex fontInterSemiBold fontSizeHeading 
                        align-items-center justify-content-between p-2'>
                            <input
                                className='custom-input-field'
                                placeholder='Search...'
                                value={domainSearch}
                                onChange={e => {
                                    setDomainSearch(e?.target?.value)
                                }}
                            />
                        </div>
                    </>
                }
                content={
                    <>
                        {
                            loadingDomains ? <ClaristaLoader height='9.0625rem' />   
                            :
                            domainOptions?.filter(v => v?.Name?.toLowerCase()?.includes(domainSearch?.toLowerCase()))?.length === 0 ?
                            <div className='p-3 text-center text-black-50 fontSizeHeading'>
                                No Domains Found
                            </div>
                            :
                            <div className='p-2' style={{maxHeight: '11.25rem', overflowY: 'auto'}}>
                            {
                                domainOptions?.filter(v => v?.Name?.toLowerCase()?.includes(domainSearch?.toLowerCase()))
                                ?.map((d, i) => (
                                    <div 
                                        key={`${d?.value}-${d?.Name}=${i}`} 
                                        className={`trace-domain-sel-itm d-flex align-items-center my-1 px-2 py-1 feed-dm-li-itm
                                            ${activeDomain?.toString() === d?.key ? 'active' : ''}
                                        `}
                                        onClick={() => {
                                            if(activeDomain?.toString() !== d?.key) {
                                                setParentDomain(d?.key)
                                                userSelectedOomain(d?.key)
                                            }
                                        }}
                                    >
                                        <ICON_CATALOG_DOMAIN color='currentColor' height='14' width='14' />
                                        <label 
                                            title={d?.Name}
                                            className='mb-0 fontSizeLabel ml-2 text-uppercase text-with-ellipsis cursor-pointer'
                                            style={{width: '6.25rem'}}
                                            >
                                            {d?.Name}
                                        </label>
                                        {
                                            useCustomDomainList && ![undefined, null]?.includes(d?.pending_count)
                                            ?   <span title={`${d?.pending_count} Pending`} className='feed-dm-li-tg ml-2'>
                                                    {d?.pending_count ?? 0}
                                                </span>
                                            :   ''
                                        }
                                    </div>
                                ))
                            }
                            </div>
                        }
                    </>
                }
            >
                <ICON_CATALOG_DOMAIN height='20' width='20'/>
                <span style={{fontSize: '0.875rem'}} className='ml-2 fontInterSemiBold feedback-custom-domain'>
                    {
                        !domainOptions?.find(d => d?.key?.toString() === activeDomain?.toString())?.Name ? 'Select Domain'
                        :
                        domainOptions?.find(d => d?.key?.toString() === activeDomain?.toString())?.Name
                    }
                </span>
                <span className='d-inline-flex bx-rotate-90 color-primary ml-2'>
                    <ICON_ARROW_RIGHT color='currentColor' height='12' width='12' />
                </span>
            </Popover>
        </>
    )
}

export default TraceDomainList