import React, { useEffect, useState } from "react";
import CustomTimeline from "../../../Common/timeline/CustomTimeline";


const TimelineLowerSection = ({
id
}) => {

  return <div className="flow-lower-section h-100">
    <div id="flow-lower-table-sec" style={{padding:"0!important"}} className="flow-lower-table bg-white  h-100">
    
    <CustomTimeline id={id} type={"FLOW"} scrollbarHeight="90%" />
    </div>
  </div>
};

export default TimelineLowerSection;
