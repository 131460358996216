import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MergeEditor from '../../merge/MergeEditor'
import AddEdit from './AddEdit'

const EditorIndex = ({ editMode ,setEditMode}) => {
    const [currentNote, setcurrentNote] = useState('')
    const flowsElements = useSelector(state => state.FlowsReducer.flowsElements)
    const currentTransformer = useSelector(state => state.FlowsReducer.currentTransformer)

    useEffect(() => {
        if (currentTransformer && currentTransformer.id) {

            let flowsList = [...flowsElements]

            if (flowsList.length) {
                let currentNoteIndex = flowsList.findIndex((ele) => ele.id === currentTransformer.id)
                if (currentNoteIndex > -1 && flowsList[currentNoteIndex]['note']) {
                 
                    setcurrentNote(flowsList[currentNoteIndex]['note'])
                }
            }


        }

    }, [currentTransformer,editMode])
    const createMarkup = () => {
        return { __html: currentNote };
    };
    return (
        <>
            {!editMode && currentNote === '' ? <div className="no-datasource">
                <AddEdit />
            </div> : !editMode && currentNote !== '' ? <div className='editor-view' dangerouslySetInnerHTML={createMarkup()} /> : <MergeEditor  setEditMode={setEditMode}/>}

        </>
    )
}

export default EditorIndex