import { Checkbox, InputNumber, Tooltip } from 'antd';
import _, { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react'

import { useSelector, useStore } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap'
import { setSelectedAlertDetails } from '../../../../store/modules/alert/createAlert/createAlertAction';
import { SET_FLOWS_ALERT_TRANSFORMER_LIST, SET_SELECTED_ALERT_TRANSFORMER } from '../../../../store/modules/alert/createAlert/createAlertActiontype';
import Buttons from '../../../Common/button/Buttons';
import SingleSelection from '../../../Common/dropDown/SingleSelection';
import { ALERTBELL, ALERTBELLLARGE, CHECK, CLOSE, DELETEICON, FILTERBLUE, FLOWS, FLOWTABICON, LOGICALICON, PLUS, SAVE, SIXDOTS, SMALLFLOWS, SQLBLUE } from '../../../Common/iconSource';
import AlertSelectionModal from '../../alert/createAlert/AlertSelectionModal';
import FlowSelectionModal from '../../alert/createAlert/FlowSelectionModal';
import ActionPopUp from '../../common/actionPopUp';
import { toTitleCase } from '../../common/helperFunctions';
import { Literals } from '../../common/literals';

export const StepsRightSide = ({
    setaddStep,
    setSelectedRowIndex,
    editStepObj,
    stepObject,
    setstepObject,
    stepList,
    setstepList,
    data,
    setdata,
    isEditor = false
}) => {

    const failureSelectOptions = [
        { Name: 'SKIP', Value: 'SKIP' },
        { Name: 'RUN', Value: 'RUN' },

    ]
    const store = useStore()
    // failureSelectOptions[0].Value

    console.log(stepObject, editStepObj)
    const [failureSelectValue, setFailureSelectValue] = useState(failureSelectOptions[0].Value)
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const [openFlowSelectionModal, setopenFlowSelectionModal] = useState(false)
    const [openAlertSelectionModal, setopenAlertSelectionModal] = useState(false)
    const flowsAlertTransformerList = useSelector(state => state.Alert.CreateAlert?.flowsAlertTransformerList)
    const selectedAlertDetails = useSelector(state => state.Alert?.CreateAlert?.selectedAlertDetails)
    const [selectedTypeName, setselectedTypeName] = useState('')
    const [showError, setshowError] = useState(false)
    const stepTypeToggleBtnOption = [
        { label: <><ALERTBELL /> <label className='mb-0 small' onClick={() => setUserType('rule')}>Alert</label></>, value: "rule", disabled: window.location.href.includes('scheduler/view') || selectedTypeName !== '', },
        { label: <> <FLOWS /> <label className='mb-0 small' onClick={() => setUserType('flow')}>Flow</label></>, value: "flow", disabled: window.location.href.includes('scheduler/view') || selectedTypeName !== '', },
    ];
    const [stepTypeToggleValue, setStepTypeToggleValue] = useState(stepTypeToggleBtnOption[0]['value'])

    useEffect(() => {

        if (Object.keys(editStepObj).length) {

            setselectedTypeName(editStepObj.object_name)
            setFailureSelectValue(editStepObj.if_previous_fail)

            setStepTypeToggleValue(editStepObj.content_type)
            setstepObject(editStepObj)
        }
    }, [editStepObj])

    useEffect(() => {

        if (stepObject.object_name === '' && stepObject.object_id === null) {

            store.dispatch(setSelectedAlertDetails({}))
            store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} })
        }
        // setFailureSelectValue(stepObject.if_previous_fail)
        setselectedTypeName(stepObject.object_name)
        if (!Object.keys(editStepObj).length && stepObject.content_type) {
            setStepTypeToggleValue(stepObject.content_type)
        }
    }, [stepObject])

    const getType = (val, from = "ListView") => {
        let value

        if (stepTypeToggleValue === 'rule') {

            value = val?.toLowerCase();
        } else {
            value = "flow"
        }
        switch (value) {
            case "sql":
                return (
                    <SQLBLUE
                        width={from && from.toLowerCase() === "listview" ? "20" : "35"}
                        height={from && from.toLowerCase() === "listview" ? "20" : "35"}
                    />
                );
            case "filter":
                return (
                    <FILTERBLUE
                        width={from && from.toLowerCase() === "listview" ? "20" : "35"}
                        height={from && from.toLowerCase() === "listview" ? "20" : "35"}
                    />
                );
            case "logical":
                return (
                    <LOGICALICON
                        width={from && from.toLowerCase() === "listview" ? "20" : "35"}
                        height={from && from.toLowerCase() === "listview" ? "20" : "35"}
                    />
                );
            case "flow":
                return (
                    <span className="primary-svg-color">
                        <FLOWS
                            width={from && from.toLowerCase() === "listview" ? "20" : "35"}
                            height={from && from.toLowerCase() === "listview" ? "20" : "35"}
                        />
                    </span>
                );
            default:
                return (
                    <ALERTBELL
                        width={from && from.toLowerCase() === "listview" ? "20" : "35"}
                        height={from && from.toLowerCase() === "listview" ? "20" : "35"}
                        color={"#275A89"}
                    />
                );
        }
    };


    useEffect(() => {


        const newObj = cloneDeep(stepObject)

        if (Object.keys(flowsAlertTransformerList).length && flowsAlertTransformerList && stepTypeToggleValue === 'flow') {
            setselectedTypeName(flowsAlertTransformerList?.selectedFlowDetails?.name)
            newObj['object_id'] = flowsAlertTransformerList?.selectedFlowDetails?.id
            newObj['object_name'] = flowsAlertTransformerList?.selectedFlowDetails?.name
            newObj['description'] = flowsAlertTransformerList?.selectedFlowDetails?.description
            setstepObject(newObj)
            setshowError(false)

        } else if (Object.keys(selectedAlertDetails).length && selectedAlertDetails && stepTypeToggleValue === 'rule') {
            setselectedTypeName(selectedAlertDetails?.name)
            newObj['object_id'] = selectedAlertDetails?.id
            newObj['object_name'] = selectedAlertDetails?.name
            newObj['rule_type'] = selectedAlertDetails?.type
            // newObj['content_type'] = selectedAlertDetails?.type
            newObj['description'] = selectedAlertDetails?.description

            setstepObject(newObj)
            setshowError(false)

        }

    }, [flowsAlertTransformerList, selectedAlertDetails])

    const setUserType = (value) => {
        if (selectedTypeName === '') {

            setStepTypeToggleValue(value);
            const newObj = cloneDeep(stepObject)
            newObj['content_type'] = value
            setstepObject(newObj)

        }
        // setselectedTypeName('')
        // // if (stepTypeToggleValue === 'flow') {
        //     store.dispatch({
        //         type: SET_FLOWS_ALERT_TRANSFORMER_LIST,
        //         payload: {

        //         },
        //     });
        //     store.dispatch(setSelectedAlertDetails({}))
        // // } else if (stepTypeToggleValue === 'rule') {
        //     store.dispatch({
        //         type: SET_SELECTED_ALERT_TRANSFORMER,
        //         payload: {

        //         },
        //     });
        // // }


        // newObj['object_name'] = ''
        // newObj['rule_type'] = undefined
        // if(newObj['object_id']===null) {
        //     if(value==='rule')
        //         newObj['description'] = 'description of the alert'
        //     else if(value==='flow')
        //         newObj['description'] = 'description of the flow'
        //     else
        //         newObj['description'] = ''
        // }

        // }
    }

    const handleFailureSelect = (value) => {
        setFailureSelectValue(value)
        const newObj = cloneDeep(stepObject)
        newObj['if_previous_fail'] = value
        setstepObject(newObj)
    }

    const openDeleteDialog = () => {
        setDeletePopUpContent({
            modalClass: "delete-popup",
            heading: "Delete",
            message: "This action will delete this flow.",
            button: [
                {
                    name: "Delete",
                    action: () => {

                    },
                },
            ],
            elements: [],
            closeActionPopup: () => setOpenDeletePopup(false),
        });
        setOpenDeletePopup(true);
    };

    const createStepBody = (dataObj) => {

        if (Object.keys(editStepObj).length && editStepObj?.editIndex > -1) {

            if (dataObj['object_name'] && dataObj['object_name'] !== '' && dataObj['object_id'] && dataObj['object_id'] !== null) {
                setshowError(false)

                // dataList.push(<div className='d-flex align-items-center justify-content-between'><Checkbox  onChange={(e) => { dataObj['checked'] = e.target.checked }}></Checkbox> <label className='align-items-center d-flex mb-0'>{dataObj.content_type === 'flow' ? <FLOWTABICON /> : <ALERTBELLLARGE />}<div className='p-1'></div>{dataObj.name}</label><span className='pr-1'><label className='mb-0 small text-black-50 mr-2'>{dataObj.offset} Seconds</label><SIXDOTS /></span></div>)
                stepList[editStepObj?.editIndex] = dataObj
                setstepList([...stepList])
                // setdata([...dataList])
                setstepObject({
                    "object_id": null,
                    "content_type": "rule",
                    "if_previous_fail": "SKIP",
                    "order": 0,
                    "offset": 30,
                    "description": '',
                    'object_name': ''
                })

                store.dispatch(setSelectedAlertDetails({}))
                store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} })
                setaddStep(false)
            } else {
                setshowError(true)
            }



        } else {

            let dataList = [...data];
            let temp = [...stepList]
            if (temp && temp.length) {

                dataObj['order'] = temp[temp.length - 1]['order'] + 1
            } else {
                dataObj['order'] = 1
            }

            if (dataObj['object_name'] && dataObj['object_name'] !== '' && dataObj['object_id'] && dataObj['object_id'] !== null) {
                setshowError(false)

                // dataList.push(<div className='d-flex align-items-center justify-content-between'><Checkbox  onChange={(e) => { dataObj['checked'] = e.target.checked }}></Checkbox> <label className='align-items-center d-flex mb-0'>{dataObj.content_type === 'flow' ? <FLOWTABICON /> : <ALERTBELLLARGE />}<div className='p-1'></div>{dataObj.name}</label><span className='pr-1'><label className='mb-0 small text-black-50 mr-2'>{dataObj.offset} Seconds</label><SIXDOTS /></span></div>)
                temp.push(dataObj)
                setstepList([...temp])
                // setdata([...dataList])
                setstepObject({
                    "object_id": null,
                    "content_type": "rule",
                    "if_previous_fail": "SKIP",
                    "order": 0,
                    "offset": 30,
                    "description": '',
                    'object_name': ''
                })

                store.dispatch(setSelectedAlertDetails({}))
                store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} })
                setaddStep(false)
            } else {
                setshowError(true)
            }
        }

    }


    return <>
        <Card className='card custom-card p-0'>
            <CardHeader className='padding-2 bg-white'>
                <div className='d-flex align-items-center w-100 justify-content-between'>
                    <label className='mb-0 d-flex align-items-center font-weight-bold primary-svg-color'>
                        {stepTypeToggleValue.toLocaleLowerCase() === 'rule' ? <ALERTBELL /> : <FLOWS />}
                        <div className='p-1'></div>
                        {selectedTypeName}
                    </label>
                    {!Object.keys(editStepObj).length ? <div className='float-right d-flex align-items-center'>
                        {/* <Tooltip placement={"bottom"} title="SAVE STEP">
                            <button
                                className={
                                    window.location.href.includes('scheduler/view') ? " custom-btn ml-2" : "custom-btn-outline custom-btn btn-with-icon  ml-2"
                                }
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    createStepBody(stepObject)
                                }}
                                disabled={window.location.href.includes('scheduler/view')}
                            >
                                <SAVE />
                            </button>
                        </Tooltip> */}
                        <div>
                            {/* <Tooltip placement={"bottom"} title="Close Step">
                                <Buttons props={{
                                    tooltip: 'Close Step',
                                    buttonText: '', buttonClassName: "custom-btn custom-btn-outline btn-with-icon ml-2", buttonEvent: () => {
                                        setaddStep(false); store.dispatch(setSelectedAlertDetails({}))
                                        store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} })
                                    }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY
                                }} />
                            </Tooltip> */}
                        </div>
                    </div>

                        :
                        <div className='float-right d-flex align-items-center'>
                            {/* <div>
                                <Tooltip placement={"bottom"} title="SAVE STEP">
                                    <button
                                        className={
                                            window.location.href.includes('scheduler/view') ? " custom-btn mr-2" : "custom-btn-outline custom-btn btn-with-icon  mr-2"
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            createStepBody(stepObject)
                                        }}
                                        disabled={window.location.href.includes('scheduler/view')}
                                    >
                                        <SAVE />
                                    </button>
                                </Tooltip>
                            </div> */}
                            {/* <div>
                                <Tooltip placement={"bottom"} title="Close Step">
                                    <Buttons props={{
                                        tooltip: 'Close Step',
                                        buttonText: '', buttonClassName: "custom-btn custom-btn-outline btn-with-icon", buttonEvent: () => {
                                            setaddStep(false); store.dispatch(setSelectedAlertDetails({}))
                                            store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} })
                                        }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY
                                    }} />
                                </Tooltip>
                            </div> */}
                        </div>}
                </div>
            </CardHeader>
            <CardBody className='padding-2'>
                <div className='row'>
                    <div className='col-3'>
                        <div>
                            <label className='mt-0 grey-color label'>Step Type</label>
                        </div>
                        <div className='mb-2'>
                            <Buttons
                                props={{
                                    buttonText: "",
                                    toggleBtnClassName: `full-width-toggle ${window.location.href.includes('scheduler/view') ? 'scheduler-toggle-disable' : ''}`,
                                    buttonEvent: (e) => { setUserType(e) },
                                    toggleBtnValue: stepTypeToggleValue,
                                    ImgSrc: () => <></>,

                                    isDisable: window.location.href.includes('scheduler/view') || selectedTypeName !== '',
                                    buttonType: Literals.BTN_TOGGLE,
                                    toggleBtnOption: stepTypeToggleBtnOption,
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-3 pl-0'>
                        <div>
                            <label className='mt-0 grey-color label'>If Previous Step Fails</label>
                        </div>
                        <div className='mb-2'>
                            <SingleSelection
                                props={{
                                    label: "",
                                    placeholder: "",
                                    dropDownValue: failureSelectValue,
                                    onClickEvent: (value) => handleFailureSelect(value),
                                    optionList: failureSelectOptions,
                                    isDisable: window.location.href.includes('scheduler/view') || !stepList.length,  //stepObject['order'] === 1 || editStepObj['order'] === 1 
                                }}
                            />
                        </div>
                    </div>
                    {/* ${selectedTypeName !== '' ? 'col-4' : 'col-2'} */}
                    <div className={`col-4 pl-0`}>
                        <div>
                            <label className='mt-0 grey-color label'>Selected {stepTypeToggleValue === 'rule' ? 'Alert' : toTitleCase(stepTypeToggleValue)} Name</label>
                        </div>
                        <div className=''>
                            {/* selectedTypeName === '' ? 'd-none' :  */}
                            <div className='row'>
                                <div className={'col-8'}>
                                    {/* <input
                                        required
                                        type="text"
                                        disabled={true}
                                        className={`custom-input-field`}
                                        defaultValue={selectedTypeName}

                                    /> */}
                                    <span style={{ padding: "0px", width: "100%", fontSize: "12px" }} className='mt-0 grey-color label d-flex align-items-center ml-2'><span>{stepTypeToggleValue === 'rule' ? <ALERTBELL
                                        width="20"
                                        height="20"
                                        color={"#275A89"}
                                    /> : <FLOWS
                                        width="20"
                                        height="20"
                                        color={"#275A89"}
                                    />}</span> {selectedTypeName ? <span className='ml-1'> {selectedTypeName}</span> : <label className="font-w-400 grey-color label mb-0 paragraph text-with-ellipsis"><span style={{ marginLeft: "0px", fontSize: "9px" }}>{`Click on ${`+SELECT`} to select ${stepTypeToggleValue === 'rule' ? 'alert' : stepTypeToggleValue} .`}</span></label>}</span>

                                </div>
                                {/* ${selectedTypeName !== '' ? 'col-4 pl-0' : 'col-12'} */}
                                {!window.location.href.includes('scheduler/view') ? <div className={`col-4 pl-0`}>
                                    <Buttons
                                        props={{
                                            buttonText: 'Select',
                                            buttonClassName: "custom-btn-primary custom-btn btn-with-text w-100",
                                            buttonEvent: (e) => {
                                                stepTypeToggleValue === 'flow' ? setopenFlowSelectionModal(true) : setopenAlertSelectionModal(true)

                                                e.stopPropagation();

                                            },
                                            ImgSrc: () => <PLUS />,
                                            isDisable: !isEditor || window.location.href.includes('scheduler/view'),
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }}
                                    />
                                </div> : null}
                            </div>
                            {showError ? <span className='error' style={{fontSize:"10px"}}>Please Select {toTitleCase(stepTypeToggleValue)}  </span> : ''}

                        </div>
                    </div>
                    {/* ${selectedTypeName !== '' ? 'col-2' : 'col-4'} */}
                    <div className={`col-2 pl-0`}>
                        <label className='mt-0 grey-color label'>Offset Time (in Seconds)</label>
                        <div>

                            <input
                                min="10"
                                max="120"
                                type="number"
                                value={stepObject['offset']}
                                onChange={(e) => {
                                    let newObj = cloneDeep(stepObject)
                                    newObj['offset'] = e.target.value
                                    setstepObject(newObj)
                                }}
                                disabled={window.location.href.includes('scheduler/view')}  //|| stepObject['order'] === 1 || editStepObj['order'] === 1
                                step="10"
                                className='custom-input-field w-100 '
                            />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div>
                            <label className='mt-0 grey-color label'>Description</label>
                        </div>
                        <textarea
                            id="schedulerDesc"
                            type="text"
                            className="custom-textarea-field"
                            value={stepObject['description']}
                            onChange={(e) => {
                                let newObj = cloneDeep(stepObject)
                                newObj['description'] = e.target.value
                                setstepObject(newObj)
                            }}
                            disabled={!isEditor || window.location.href.includes('scheduler/view')}
                            placeholder="No description provided yet."
                        />
                    </div>
                </div>
            </CardBody>
            <CardFooter className='bg-white padding-2'>
                {!Object.keys(editStepObj).length ? <div className='float-right d-flex align-items-center'>
                    {!window.location.href.includes('scheduler/view') ? <Buttons
                        props={{
                            buttonText: 'Save Step',
                            buttonClassName: " custom-btn-primary  custom-btn btn-with-text",
                            buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                createStepBody(stepObject)
                            },
                            ImgSrc: () => <CHECK />,
                            isDisable: !isEditor || window.location.href.includes('scheduler/view'),
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }}
                    /> : null}
                    {!window.location.href.includes('scheduler/view') ? <Tooltip placement={"bottom"} title="Close Step">
                        <Buttons props={{
                            tooltip: 'Close Step',
                            buttonText: 'Close', buttonClassName: "custom-btn custom-btn-outline btn-with-text ml-2", buttonEvent: () => {
                                setaddStep(false); store.dispatch(setSelectedAlertDetails({}))
                                store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} });
                                setSelectedRowIndex(null)

                            }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }} />
                    </Tooltip> : null}
                </div>

                    :
                    <div className='float-right d-flex align-items-center'>
                        {!window.location.href.includes('scheduler/view') ? <Buttons
                            props={{
                                buttonText: 'Save Step',
                                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    createStepBody(stepObject)
                                },
                                ImgSrc: () => <CHECK />,
                                isDisable: !isEditor || window.location.href.includes('scheduler/view'),
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            }}
                        /> : null}
                        {!window.location.href.includes('scheduler/view') ? <Tooltip placement={"bottom"} title="Close Step">
                            <Buttons props={{
                                tooltip: 'Close Step',
                                buttonText: 'Close', buttonClassName: "custom-btn custom-btn-outline btn-with-text ml-2", buttonEvent: () => {
                                    setaddStep(false); store.dispatch(setSelectedAlertDetails({}))
                                    store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} });
                                    setSelectedRowIndex(null)

                                }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            }} />
                        </Tooltip> : null}
                    </div>}
            </CardFooter>
        </Card>
        <FlowSelectionModal isModalOpen={openFlowSelectionModal} onClose={() => setopenFlowSelectionModal(false)} fromRoute='scheduler' />
        <AlertSelectionModal isModalOpen={openAlertSelectionModal} onClose={() => setopenAlertSelectionModal(false)} fromRoute='scheduler' />

        <ActionPopUp
            openModal={openDeletePopup}
            actionData={deletePopUpContent}
        />
    </>
}
