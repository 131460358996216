import React, { useEffect, useState } from "react";
import EditorContainer from "../EditorContainer";
import { setPreviewTableQuery } from "../../../../../../store/modules/dataCatalogue/preview/previewActions";
import { useSelector, useDispatch } from "react-redux";
import ClaristaLoader from "../../../../../Common/claristaLoader/ClaristaLoader";
const PreviewQuery = ({
  summaryLoaded,
  sqlTableDetails,
  onExecuteQueryClick,
  Query,
  setQuery,
  isGridLoaded,
}) => {
  const [isQueryViewMode, setIsQueryViewMode] = useState(true);
  const podDetails = useSelector(
    (state) => state.DataCatalogue.Dictionary.dictionaryPodData
  );
  const dispatch = useDispatch();
  const setQueryvalue = (query) => {
    if (query) {
      dispatch(setPreviewTableQuery(query));

      setQuery(query);
    }
  };
  useEffect(() => {
    if (summaryLoaded) {
      dispatch(setPreviewTableQuery(Query));
      setTimeout(() => {
        onExecuteQueryClick();
      }, 0);
    }
  }, [summaryLoaded]);
  // useEffect(() => {
  //   console.log(sqlTableDetails);
  // }, [sqlTableDetails]);

  return (
    <>
      {summaryLoaded ? (
        <>
          <p style={{ color: "black" }}>
            This SQL query could help you find what you are looking for. 🤓
          </p>
          <div
            style={{
              border: "1px solid lightgray",
              borderRadius: "5px",
            }}
          >
            <EditorContainer
              showLoader={false}
              tableId={podDetails.table_id}
              isEditingQuery={false}
              closeEditor={() => {}}
              ExecuteQueryClick={onExecuteQueryClick}
              setIsEditingQuery={() => {}}
              sqlTableDetails={sqlTableDetails}
              previewQuery={true}
              setQueryvalue={setQueryvalue}
              Query={Query}
              isQueryViewMode={isQueryViewMode}
              debounceTime={1000}
              setIsQueryViewMode={setIsQueryViewMode}
              setIsEditorVisible={() => {}}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default PreviewQuery;
