import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Switch, Tooltip } from 'antd'


import { EDIT, PLAY, RUNICON, STOPICON, STOPOCTAGON, TERMINATEDICON } from '../../Common/iconSource'
import { LandingSimpleTable } from '../../Common/landingSimpleTable/LandingSimpleTable'
import { ICON_CALENDAR, ICON_CLOSE, ICON_EDIT, ICON_PLUS, ICON_SAVE } from '../../Common/newIconSource'
import { useStore } from 'react-redux'
import { getUserGroupList, saveUserGroup } from '../../../store/modules/UserManagement/userGroup/UMActions'
import { cloneDeep } from 'lodash'
import MultiSelection from '../../Common/dropDown/MultiSelection'
import { getConfirmationModal, pxToRem, remToPx } from '../common/helperFunctions'
import { saveClusterUserGroups } from '../../../store/modules/cluster/Cluster'


export const NotebookClusterList = ({
    clusterList,
    setCluterList = () => { },
    getClusterListApi = () => { },
    setFieldValues = () => { },
    isEdit = false,
    isEditPermission = true
}) => {

    const [data, setData] = useState([])

    const [editId, setEditId] = useState(null)
    const [showWarningPopup, setshowWarningPopup] = useState(false);

    const store = useStore();

    const selectedUserGroups = useRef([]);
    const currentId = useRef(null);
    const saveModalOpen = useRef(false)

    const [assignee, setassignee] = useState([])
    const [selectedAssigneeId, setSelectedAssigneeId] = useState({})

    const dynamic = px => remToPx(pxToRem(px))

    useEffect(() => {
        getUserGroupListData()
    }, [])

    const getUserGroupIds = (selectedUserGroups) => {

        if (selectedUserGroups?.length) {
            return assignee?.map((item) => {
                if (selectedUserGroups.includes(item.Name)) {
                    return item.key;
                }
            }).filter((item) => item);
        }
    }
    const getUserGroupListData = async () => {


        let response;
        try {
            response = await store.dispatch(
                getUserGroupList({ method: "GET", endPoint: "group_manager/group/", payload: {}, showLoader: false })
            );
            if (response.status === "success") {
                let children = []
                let group = response.data;
                group.forEach((ele) => {

                    children.push({ key: ele.id, Name: ele.name, value: ele.name })

                })

                setassignee([...children]);
            }
        } catch (error) { }

    };

    const onChangeUserGroup = (id, value) => {
        let clone = cloneDeep(selectedAssigneeId);
        clone[id] = value;
        currentId.current = id;
        selectedUserGroups.current = value;
        setSelectedAssigneeId(clone)
        // clone.forEach((item) => {
        //     if (item.id === id) {
        //         item['selectedUserGroups'] = value;
        //     }
        // });
        // setCluterList(clone)

    }

    const listTemplate = {


        usergroups: (item) => {

            // onBlur={(e) => {
            //     e.preventDefault();
            //     if (!saveModalOpen.current) {

            //         saveModalOpen.current = false
            //         currentId.current = null
            //         setEditId(null); selectedUserGroups.current = [];
            //         // selectedAssigneeId[item.id] = [];
            //         getClusterListApi(); 


            //     }
            // }}
            return <>
                <div className='table-content-color row w-100 ml-1  d-flex align-items-center justify-content-start' >

                    {item.id === editId || selectedAssigneeId[item.id]?.length ? <div className='p-0 m-0 mt-2 col-10'
                    // onBlur={(e) => {
                    //     e.preventDefault();
                    //     if (!saveModalOpen.current) {

                    //         // saveModalOpen.current = false
                    //         currentId.current = null
                    //         setEditId(null); 
                    //         // selectedUserGroups.current = [];
                    //         // selectedAssigneeId[item.id] = [];
                    //         getClusterListApi();


                    //     }
                    // }}
                    >

                        <MultiSelection props={{
                            label: "", placeholder: "Select user group", className: "custom-select-dropdown", dropDownValue: selectedAssigneeId[item?.id] ?? [], onClickEvent: (value) => {
                                onChangeUserGroup(item.id, value)
                            }, optionList: assignee, isDisable: item.id !== currentId.current
                        }} />

                    </div> : <div className=' gray-color-NA'>N/A</div>}



                </div>
            </>
        },

        size: (text = '') => {
            return <>
                <div className='table-content-color position-relative '>
                    <Tooltip placement='topLeft' title={text?.toUpperCase()
                    }> {text ?? ""}</Tooltip >

                </div >
            </>
        },

        capacity: (text = '') => {
            return <>
                <div className='table-content-color '>
                    <Tooltip placement='topLeft' title={text
                    }> {text}</Tooltip >

                </div >
            </>
        },

        action: (item) => {
            return <div className=' ml-1 '>
                {item.id !== editId ? <div className='cursor-pointer' style={{ width: "1rem", height: "1rem" }} onClick={() => { setEditId(item.id); currentId.current = item.id; setCluterList([...clusterList]) }} ><Tooltip title="Edit"> <EDIT height='0.875rem' width='0.875rem' color='#2c83c4'></EDIT></Tooltip></div>
                    :
                    <div className='d-flex align-items-center'>
                        <div className='cursor-pointer' style={{ width: "1rem", height: "1rem" }} onClick={(e) => {

                            e.stopPropagation();
                            e.preventDefault();
                            saveModalOpen.current = true
                            setshowWarningPopup(true)

                        }}> <Tooltip title="Save"> <ICON_SAVE height='0.9375rem' width='0.9375rem' color='#2c83c4'></ICON_SAVE></Tooltip></div>

                        <div className='cursor-pointer' style={{ width: "1rem", height: "1rem", marginLeft: "0.625rem" }} onClick={(e) => {

                            e.stopPropagation();
                            e.preventDefault();

                            // if (!saveModalOpen.current) {
                                // getClusterListApi();
                                setCluterList([...clusterList])

                                saveModalOpen.current = false
                                currentId.current = null
                                setEditId(null);
                                // selectedUserGroups.current = [];
                                // selectedAssigneeId[item.id] = [];
                            // }

                        }}><Tooltip title="Cancel">  <ICON_CLOSE height='0.8125rem' width='0.8125rem' color='#2c83c4'></ICON_CLOSE></Tooltip></div>
                    </div>}


            </div>
        }

    }
    const getCurrentGroups = () => {
        let newClusterList = cloneDeep(clusterList);
        newClusterList?.forEach((item) => {
            if (item?.group_detail?.length) {
                let list = item?.group_detail?.map((ele) => ele.name);
                if (list?.length) {
                    item['selectedUserGroups'] = list;
                    selectedAssigneeId[item?.id] = list
                } else {
                    item['selectedUserGroups'] = [];
                    selectedAssigneeId[item?.id] = []

                }

            } else {
                item['selectedUserGroups'] = [];
                selectedAssigneeId[item?.id] = []

            }
        });

        setSelectedAssigneeId(selectedAssigneeId)

    }

    useEffect(() => {
        if (clusterList?.length) {
            getCurrentGroups()
        }

        if (!assignee?.length) {
            getUserGroupListData()
        }

    }, [clusterList])

    useEffect(() => {
        if (clusterList?.length) {  //clusterList


            let temp = clusterList?.map((item) => {
                return {
                    id: item.table_id,
                    size: listTemplate.size(item.size),
                    capacity: listTemplate.capacity(item.capacity),
                    usergroups: listTemplate.usergroups(item),
                    action: listTemplate.action(item),

                }


            })
            setData([...temp])

        }


    }, [clusterList, assignee, selectedAssigneeId, selectedUserGroups.current, editId])


    return (
        <div className='px-2'>


            {data.length
                ? <LandingSimpleTable
                    cols={columns}
                    // onRowClick={onRowClick}
                    removeBorders={true}
                    autoWidth={false}
                    rowsData={data}
                    tableHeight={'calc(100vh - 60vh)'}
                    showCursor={false}
                />
                : <div className='p-3 text-center text-black-50 no-cluster-text no-datasrc-found'>No Clusters Found</div>}

            {getConfirmationModal({
                open: showWarningPopup,
                setOpen: setshowWarningPopup,
                onConfirm: () => {

                    store.dispatch(saveClusterUserGroups('POST', { 'group': getUserGroupIds(selectedUserGroups.current) }, currentId.current)).then((resp) => {
                        currentId.current = null
                        setEditId(null); selectedUserGroups.current = [];
                        saveModalOpen.current = false
                        getClusterListApi() //added showStopped status

                    }).catch(e => {
                        // saveModalOpen.current = false

                    })
                },
                onCancel: () => {
                    getClusterListApi();
                    currentId.current = null
                    setEditId(null); selectedUserGroups.current = []
                    saveModalOpen.current = false


                },
                heading: 'Warning',
                hideButton: false,
                body: <div>Are you sure, you want to add selected user groups?</div>
            })}

        </div>
    )
}




const columns = [

    {
        name: <label className='mt-0 dict-color-dark label'>Size</label>,
        key: "size",
        sortable: false,
        resizable: false,
        width: '20%'
    },
    {
        name: <label className='mt-0 dict-color-dark label'>Capacity</label>,
        key: "capacity",
        sortable: false,
        resizable: false,
        // width: 'auto'
        width: '20%'
    },
    {
        name: <label className='mt-0 dict-color-dark label'>Usergroup</label>,
        key: "usergroups",
        sortable: false,
        resizable: false,
        // width: 'auto'
        width: '40%'
    },
    {
        name: <label className='mt-0 dict-color-dark label'>Action</label>,
        key: "action",
        sortable: false,
        resizable: false,
        width: 'auto'
        // width: 150
    },
    // {
    //     name: <label className='mt-0 dict-color-dark label'></label>,
    //     key: "",
    //     sortable: false,
    //     resizable: false,
    //     width: 'auto'
    //     // width: 150
    // },
]

