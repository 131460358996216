import { Checkbox, Tooltip } from 'antd'
import { cloneDeep, set } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import { useSelector, useStore } from 'react-redux'
import { SetSchedulerPayload } from '../../../../store/modules/scheduler/action'
import { ALERTBELL, ALERTBELLLARGE, FLOWS, FLOWTABICON, SIXDOTS, STEPINFOGRAPHY } from '../../../Common/iconSource'
import { TwoSections } from '../../../Common/twoSections/TwoSections'
import { SchedulerStepsList } from './SchedulerStepsList'
import { StepsRightSide } from './StepsRightSide'

export const Steps = ({
    isEditor = false
}) => {
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)
    const store = useStore()
    const [selectedRowIndex, setselectedRowIndex] = useState(null)
    const [stepObject, setstepObject] = useState({
        "object_id": null,
        "content_type": "rule",
        "if_previous_fail": "SKIP",
        "order": 0,
        "offset": 30,
        "description": '',
        'object_name': '',
        'checked': false
    })
    const [stepList, setstepList] = useState([])
    const [data, setdata] = useState([])
    const [addStep, setaddStep] = useState(false)
    const [editStepObj, seteditStepObj] = useState({})
    useEffect(() => {
        if (schedulerPayload && schedulerPayload['step']?.length) {
            setselectedRowIndex(0)
            schedulerPayload['step'][0]['editIndex'] = 0
            seteditStepObj(schedulerPayload['step'][0]);
            setstepObject(schedulerPayload['step'][0]);

            setaddStep(true)
        
            store.dispatch({ type: 'SET_SELECTED_ALERT_DETAILS', payload: { id: schedulerPayload['step'][0]['object_id'], name: schedulerPayload['step'][0]['object_name'] } })
            store.dispatch({ type: 'SET_SELECTED_ALERT_TRANSFORMER', payload: { id: schedulerPayload['step'][0]['object_id'], flowId: schedulerPayload['step'][0]['object_id'], name: schedulerPayload['step'][0]['object_name'] } })
        }
    }, [])

    
    useEffect(() => {

        if (schedulerPayload && schedulerPayload['step']?.length) {
            setstepList(schedulerPayload['step']);
            let newData = schedulerPayload['step'].map((dataObj, index) =>
                <div style={{ height: 40, fontSize: "12px" }} className={`${selectedRowIndex === index && addStep ? 'selected-step-border ' : ''} d-flex align-items-center w-100 steps-outer-card text-dark `} >
                    <Checkbox disabled={window.location.href.includes('scheduler/view')} onChange={(e) => { dataObj['checked'] = e.target.checked;   let list = [...stepList];
                            list[index]['checked'] = e.target.checked;
                            setstepList([...list]);}}></Checkbox>

                    <div className='d-flex h-100 align-items-center cursor-pointer justify-content-between w-100 ' onClick={(e) => { setselectedRowIndex(index); dataObj['editIndex'] = index; seteditStepObj(dataObj); setstepObject(dataObj); setaddStep(true); store.dispatch({ type: 'SET_SELECTED_ALERT_DETAILS', payload: { id: dataObj['object_id'], name: dataObj['object_name'] } }); store.dispatch({ type: 'SET_SELECTED_ALERT_TRANSFORMER', payload: { id: dataObj['object_id'], flowId: dataObj['object_id'], name: dataObj['object_name'] } }) }} >
                        <label className='align-items-center d-flex text-dark mb-0'>
                            {
                                dataObj.content_type === 'flow'
                                    ? <div className='ml-2 p-1 steps-ls-ic black-svg-color'><FLOWS /></div>
                                    : <div className='ml-2 p-1 steps-ls-ic'><ALERTBELL width='10' height='15' /></div>
                            }
                            <Tooltip title={dataObj.object_name} className="text-with-ellipsis">
                                <label className='label mt-2'>   {dataObj.object_name?.length >= 15 ? `${dataObj.object_name?.slice(0, 15)}...` : dataObj.object_name}</label>
                            </Tooltip>
                        </label>
                        <span className='pr-1 cursor-move-stp-ls'>
                            <label className='mb-0 small text-black-50 mr-2'>{dataObj.offset} seconds</label>
                            <span className='cursor'>  <SIXDOTS /></span>
                        </span>
                    </div>

                </div>
            )
            setdata([...newData])
        }
    }, [schedulerPayload])


    useEffect(() => {

        if (stepList && stepList?.length) {
            let newPayload = cloneDeep(schedulerPayload)
            newPayload['step'] = stepList
            store.dispatch(SetSchedulerPayload(newPayload))
            let dataList = [...data];
            // dataList.push(<div className='d-flex align-items-center justify-content-between'><Checkbox  onChange={(e) => { dataObj['checked'] = e.target.checked }}></Checkbox> <label className='align-items-center d-flex mb-0'>{dataObj.content_type === 'flow' ? <FLOWTABICON /> : <ALERTBELLLARGE />}<div className='p-1'></div>{dataObj.name}</label><span className='pr-1'><label className='mb-0 small text-black-50 mr-2'>{dataObj.offset} Seconds</label><SIXDOTS /></span></div>)
            let newData = stepList.map((dataObj, i) =>
                <div key={new Date().getTime()} style={{ height: 40, fontSize: '12px' }} className={`${selectedRowIndex === i && addStep  ? 'selected-step-border ' : ''}'d-flex w-100 align-items-center '`} >
                    <Checkbox
                        disabled={!isEditor || window.location.href.includes('scheduler/view')}
                        checked={dataObj[i]}
                        className='pl-1'
                        onChange={(e) => {

                            let list = [...stepList];
                            list[i]['checked'] = e.target.checked;
                            setstepList([...list]);

                        }}
                    ></Checkbox>
                    <div className='d-flex h-100 cursor-pointer align-items-center w-100 justify-content-between' onClick={(e) => { setselectedRowIndex(i); seteditStepObj(dataObj); setaddStep(true) }}>
                        <div>
                            <label className='align-items-center d-flex text-dark mb-0'>
                                {
                                    dataObj.content_type === 'flow'
                                        ? <div className='ml-2 p-1 steps-ls-ic'><FLOWS /></div>
                                        : <div className='ml-2 p-1 steps-ls-ic'><ALERTBELL width='10' height='15' /></div>
                                }
                                <Tooltip title={dataObj.object_name} className="text-with-ellipsis">

                                    <label className='label mt-2 gray-color'>  {dataObj.object_name?.length >= 15 ? `${dataObj.object_name?.slice(0, 15)}...` : dataObj.object_name}</label>

                                </Tooltip>
                            </label>
                        </div>
                        <span className='pr-1 cursor-move-stp-ls'>
                            <label className='mb-0 small text-black-50 mr-2'>{data.offset} seconds </label>
                            <SIXDOTS />
                        </span>
                    </div>

                </div>
            )
            setdata([...newData])
        } else {
            let newPayload = cloneDeep(schedulerPayload)
            newPayload['step'] = []
            store.dispatch(SetSchedulerPayload(newPayload))
            setdata([])
        }
    }, [stepList, selectedRowIndex ,addStep])
    return <>
        <div className='scheduler-settings-wrapper'>
            <TwoSections
                leftTemplate={
                    <SchedulerStepsList
                        seteditStepObj={seteditStepObj}
                        stepObject={stepObject}
                        setstepObject={setstepObject}
                        data={data}
                        setdata={setdata}
                        addStep={addStep}
                        stepList={stepList}
                        setstepList={setstepList}
                        setaddStep={setaddStep}
                        isEditor={isEditor}
                    />
                }
                rightTemplate={
                    addStep
                        ? <StepsRightSide
                            stepObject={stepObject}
                            editStepObj={editStepObj}
                            setstepObject={setstepObject}
                            setSelectedRowIndex={setselectedRowIndex}
                            data={data}
                            setdata={setdata}
                            stepList={stepList}
                            setstepList={setstepList}
                            setaddStep={setaddStep}
                            isEditor={isEditor}
                        />
                        : <div className='d-flex align-items-center h-100 justify-content-center '><STEPINFOGRAPHY /></div>
                }
                height={'calc(100vh - 80px)'}
                rightCardView={false}
            />
        </div>
    </>
}
