import React, { forwardRef, Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useStore } from "react-redux";
import { SaveQueryAPI, savedQuerySelectedIndex, setPreviewTableQuery, DeleteQueryAPI, publishQueryAPI } from '../../../../../store/modules/dataCatalogue/preview/previewActions'
import { Tooltip, Dropdown, Menu, Tag } from "antd";
import { convertBase64ToString, showSimpleLoading, getRelativeTime } from '../../../common/helperFunctions';
import { Literals } from '../../../common/literals';
import LandingPageTable from '../../../../Common/landingPageTable/LandingPageTable';
import Buttons from "../../../../Common/button/Buttons"
import { CLOSE, EDIT, PLUS, SAVE } from '../../../../Common/iconSource';
import { ICON_CHECK, ICON_DELETE, ICON_EDITBOX, ICON_PLAY } from '../../../../Common/newIconSource';
import DeleteModal from '../../../../Common/deleteModal/DeleteModal';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
const { CheckableTag } = Tag;

const SavedQueryTable = forwardRef(({
    savedQueryResponse,
    table_id,
    getSavedQueries,
    setQueryvalue,
    onEdit,
    openEditor,
    setSplitview,
    ExecuteQueryClick,
    resetResultTab = () => {},
    isDeletePermission,
    isEditPermission,
    isViewPermission,
}, ref) => {

    const dispatch = useDispatch()
    const store = useStore();

    const [dataSource, setDataSource] = useState([])
    const [openModal, setopenModal] = useState(false)
    const [ModalData, setModalData] = useState(undefined)
    const [isDisableAddQuery, setisDisableAddQuery] = useState(false)
    const [activeQueryId, setActiveQueryId] = useState('')



    const [, setinputValueState] = useState([])
    const [, setselectedRenameData] = useState([])
    const [, setselectedRenameDataIndex] = useState(null)

    const currentSavedQuery = useRef({ current: {} })

    const sqlEditorQuery = store.getState().DataCatalogue?.PreviewPage?.sqlEditorQuery
    const { previewDataMap } = store.getState().DataCatalogue.PreviewPage;

    const closeDeletePopUp = () => {
        setopenModal(false);
    }

    const setrenameData = (renameData) => {
        setselectedRenameData([...renameData])
        let inputValue = [renameData[0], renameData[1]]
        setinputValueState([...inputValue]);
    }
    const resetRenameValues = () => {
        setinputValueState([...[]])
    }

    useEffect(() => {
        if (savedQueryResponse && savedQueryResponse?.length) {
            const dataSource = savedQueryResponse.map((record) => ({
                id: record?.id,

                name: record?.query_name,
                description: record?.query_description,
                createdBy: record?.created_by_user?.email,
                name_to_view: <Tooltip placement='topLeft' title={record?.query_name}><label className='mb-0 text-with-ellipsis'>{record?.query_name}</label></Tooltip>,
                description_to_view: <Tooltip placement='topLeft' title={record?.query_description}><label className='mb-0 text-with-ellipsis'>{record?.query_description}</label></Tooltip>,
                updated_on: getRelativeTime(record?.updated_on),
                updated_on_to_view: <Tooltip placement='topLeft' title={ getRelativeTime(record?.updated_on)}><label className='mb-0 text-with-ellipsis'>{getRelativeTime(record?.updated_on)}</label></Tooltip>,

                createdBy_to_view: <Tooltip placement='topLeft' title={record?.created_by_user?.email}><label className='mb-0 text-with-ellipsis'>{record?.created_by_user?.email}</label></Tooltip>,
                updatedBy: record?.updated_by_user?.email,
                queryString: convertBase64ToString(record?.query_string),
                ...record
            }))
            setDataSource(dataSource);
        }
        else if (savedQueryResponse === null) {
            setDataSource(null)
        }
    }, [savedQueryResponse]);



    const closeRenamePopUp = () => {
        setopenModal(false);
    }

    const deleteQueryHandler = (query) => {
        try {
            dispatch(DeleteQueryAPI(query?.id)).then((res) => {
                if (res?.status === 'success') {
                    if(activeQueryId?.toString() === query?.id?.toString()) {
                        resetResultTab()
                    }
                    getSavedQueries()
                }
                closeDeletePopUp();
            })
        } catch (error) {

        }
    }

    const publishQuery = (queryId, isPublish) => {

        try {
            dispatch(publishQueryAPI(isPublish ? 'GET' : 'DELETE', queryId)).then((res) => {

                if (res.status === 'success') {
                    getSavedQueries()
                    emitToastNotification('success', res.message)
                }

            })
        } catch (error) {

        }
    }

    const renameQuery = (newValuesState) => {
        let index = store.getState().DataCatalogue.PreviewPage.savedQuerySelectedIndex;

        let payload = {
            "action": "edit_query",
            "table_id": table_id ? table_id : "",
            "query_name": newValuesState[0] ? newValuesState[0] : "",
            "query_description": newValuesState[1] ? newValuesState[1] : "",
            old_query_name: savedQueryResponse?.data_record[index] && savedQueryResponse?.data_record[index][1] ? savedQueryResponse.data_record[index][1] : "",
            "query_string": savedQueryResponse?.data_record[index] && savedQueryResponse?.data_record[index][3] ? savedQueryResponse.data_record[index][3] : "",
            "updated_by": savedQueryResponse?.data_record[index] && savedQueryResponse?.data_record[index][6] ? savedQueryResponse.data_record[index][6] : ''
        }

        try {
            dispatch(SaveQueryAPI(payload)).then((res) => {

                if (res.status === 'success') {
                    getSavedQueries()
                }
                setselectedRenameData([...newValuesState])
                setopenModal(false);
            })
        } catch (error) {

        }
    }
    const openDeleteDialog = (query) => {

        currentSavedQuery.current = query

        // setModalData({
        //     heading: "Delete",
        //     message: "This action will delete Query permanently.",
        //     headingIcon: "bx bx-error",
        //     button: [
        //         {
        //             name: "Delete",
        //             action: () => deleteQueryHandler(query),
        //         },
        //     ],
        //     elements: [],
        //     closeActionPopup: closeDeletePopUp
        // })
        setopenModal(true);
    }


    const openRenameDialog = () => {
        setModalData({
            heading: "Rename",
            message: "",
            headingIcon: "",
            button: [
                {
                    name: "Ok",
                    action: renameQuery,
                },
                {
                    name: "Cancel",
                    action: closeRenamePopUp,
                }
            ],
            elements: [{
                label: 'Enter Query Name',
                type: 'input',
                currentValue: '',
                placeholder: 'Enter Query Name',


            },
            {
                label: 'Enter Query Discription',
                type: 'textarea',
                currentValue: '',
                placeholder: 'Enter Query Discription',

            }],
            closeActionPopup: closeRenamePopUp
        })
        setopenModal(true);
    }


    const setQueryonEditor = (index) => {

        if (savedQueryResponse?.data_record[index] && savedQueryResponse?.data_record[index][2]) {

            setselectedRenameDataIndex(index)

            setQueryvalue(savedQueryResponse.data_record[index][3], true)
        }
    }

    const connMenu = (
        <Menu className="list-view-menu">
            <Menu.Item key="1" onClick={() => openRenameDialog()}>
                <span ><i className="bx bx-edit-alt"></i>{Literals.RENAME}</span>
            </Menu.Item>
            <Menu.Item key="2" onClick={(e) => openDeleteDialog(e)}>
                <span ><i className="bx bx-trash-alt"></i>{Literals.DELETE}</span>
            </Menu.Item>
        </Menu>
    )


    const getTableData = (row, ind) => {

        if (row && row.length) {
            return (
                row.map((element, index) =>
                    element !== 'action' ? index === 2 ? <Tooltip placement="topLeft" title={getRelativeTime(element)}><td className="ml-2">{getRelativeTime(element)}</td></Tooltip> : <Tooltip placement="topLeft" title={element}><td className="ml-2">{element}</td></Tooltip> :
                        <td className="position-relative">

                            <div
                                style={{ textAlign: 'right', paddingRight: '2rem', cursor: 'pointer' }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setrenameData(row, index);
                                }}
                            >
                                <Dropdown trigger={['click']} menu={connMenu}>
                                    <i
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            setrenameData(row, index);
                                            dispatch(savedQuerySelectedIndex(ind));
                                        }
                                        } className='bx bx-dots-vertical-rounded' style={{ fontSize: '1.2rem' }}></i>
                                </Dropdown>

                            </div>

                        </td>
                )
            )
        } else {
            return null
        }
    }



    const isExecutedQuerySuccess = () => {
        // console.log(previewDataMap, isDisableAddQuery)
        if (previewDataMap &&
            previewDataMap?.execute_query !== null &&
            previewDataMap?.execute_query !== undefined &&
            Object.keys(previewDataMap.execute_query).length > 0 && previewDataMap.execute_query?.data_record?.length > 0) {

            setisDisableAddQuery(false)
        }

        else if (previewDataMap &&
            previewDataMap?.execute_query !== null &&
            previewDataMap?.execute_query !== undefined &&
            Object.keys(previewDataMap.execute_query).length > 0 && previewDataMap.execute_query?.data_header?.length > 0) {
            
            setisDisableAddQuery(false)
        }

        else if (previewDataMap &&
            previewDataMap?.execute_query !== null &&
            previewDataMap?.execute_query !== undefined &&
            Object.keys(previewDataMap.execute_query).length > 0 && previewDataMap.execute_query?.iserror) {

            setisDisableAddQuery(false)
        }

        return previewDataMap &&
            previewDataMap?.execute_query !== null &&
            previewDataMap?.execute_query !== undefined &&
            Object.keys(previewDataMap.execute_query).length > 0
    }

    const columns = [
        {
            title: Literals.NAME,
            dataIndex: "name_to_view",
            key: "name_to_view",
            width: '10%'
        },
        {
            title: Literals.DESCRIPTION,
            dataIndex: "description_to_view",
            key: "description_to_view",
            width: '20%'
        },
        {
            title: Literals.UPDATED_ON,
            dataIndex: "updated_on_to_view",
            key: "updated_on_to_view",
            // width: '40px'
        },
        {
            title: Literals.CREATED_BY,
            dataIndex: "createdBy_to_view",
            key: "createdBy_to_view",
            // width: '30px'
        },
        {

            title: Literals.ACTIONS,
            key: 'action',
            render: (text, record) => (
                <div className='d-flex align-items-center justify-content-start pr-1' style={{ gap: 8 }}>
                    {
                        record?.queryString === sqlEditorQuery &&
                            store.getState().LoadingReducer.loadingStatus
                            ? <label className='h6 text-success mb-0'><i className='bx bx-loader bx-spin'></i></label>
                            : <>
                                <Tooltip placement='bottom' title='Edit'>
                                    <button className="custom-btn-outline custom-btn btn-with-icon"
                                        style={{
                                            fontSize: "16px",
                                            opacity: isEditPermission || isViewPermission ? '1' : '0.6',
                                            cursor: isEditPermission || isViewPermission ? 'pointer' : 'not-allowed'
                                        }}
                                        onClick={() => {
                                            if (isEditPermission || isViewPermission) onEdit(record, true);
                                        }}><ICON_EDITBOX /></button>
                                </Tooltip>
                                <Tooltip placement='bottom' title='Delete'>
                                    <button 
                                        className="error-red custom-btn-outline custom-btn btn-with-icon" 
                                        style={{ fontSize: "16px", opacity: !isDeletePermission ? '0.6' : '1', cursor: !isDeletePermission ? 'not-allowed' : 'pointer' }} 
                                        onClick={() => { if (isDeletePermission) openDeleteDialog(record) 
                                        }}><ICON_DELETE /></button>
                                </Tooltip>
                                <Tooltip placement='bottom' title={record?.queryString === sqlEditorQuery ? 'Click to Re-run' : 'Run'}>
                                    <button style={{ fontSize: "16px" }} className={`custom-btn-outline custom-btn btn-with-icon`}
                                        onClick={() => {
                                            dispatch(setPreviewTableQuery(record?.queryString)); 
                                            setisDisableAddQuery(true); 
                                            ExecuteQueryClick(record?.queryString);
                                            setActiveQueryId(record?.id)
                                        }}>
                                            <ICON_PLAY/>
                                    </button>
                                </Tooltip>
                                <Tooltip placement='bottom' title={record?.published ? 'This report is published. Click to make it a draft.' : "This report is not published, it will not show up into the reports. Click here to publish this report."}>
                                    <CheckableTag
                                        key={'tag'}
                                        checked={record?.published}
                                        color={record?.published ? '#d3d3d3' : '#2c8348'}
                                        onChange={(checked) => publishQuery(record.id, checked)}
                                    >
                                        {record?.published ? 'Published' : 'Draft'}
                                    </CheckableTag>
                                </Tooltip>
                                {
                                    (isExecutedQuerySuccess() && record?.id === activeQueryId)
                                    ? <ICON_CHECK width='12' height='12' color='#4BB543' /> :''
                                }
                            </>
                    }
                </div>
            ),
            // width: '10%'
        }
    ]

    return (
        <Fragment>
            <div className="saved-queries-header padding-2 custom-border-bottom" ref={ref}>
                <span className="subtitle">{Literals.SAVED_QUERIES}</span>
                <div className="saved-queries-options">
                    <Buttons
                        props={{
                            buttonText: '',
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                            buttonEvent: () => {
                                setSplitview()
                            },
                            ImgSrc: () => <CLOSE />,
                            isDisable: false,
                            buttonType: Literals.BTN_TERTIARY
                        }}
                    />
                    <Buttons
                        props={{
                            buttonText: 'Report',
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: () => {
                                openEditor();
                                dispatch(setPreviewTableQuery(''));
                                setQueryvalue('')

                            },
                            ImgSrc: () => <PLUS />,
                            isDisable: (isEditPermission ? false : isViewPermission ? false : true) || isDisableAddQuery,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }} />

                    {/* sqlEditorQuery.length > 0 ? <EDIT /> : */}
                    {/* sqlEditorQuery.length > 0 ? 'Edit' :  */}
                </div>
            </div>

            <div className="w-100 overflow-auto savedQueryTable tableFixHead h-100 prev-sql-saved-qry-list">
                {
                    dataSource === 'error'
                        ?
                        <div className='p-3 text-center text-danger h5'><i className='bx bx-error mr-1'></i>Error</div>
                        :
                        dataSource === null ?
                            <div className='py-3 text-center text-black-50'>No Saved Report Found</div>
                            : dataSource.length > 0 ?
                                <LandingPageTable
                                    props={{
                                        columns, dataSource
                                    }}
                                /> : showSimpleLoading()
                }

                <DeleteModal
                    open={openModal}
                    setOpen={setopenModal}
                    body={
                        <>
                            <p className="font-w-600">Are you sure you want to delete this SQL Query?</p>
                            <p className="alert alert-warning mb-0">
                                Deleting this SQL Query might affect Charts & Dashboards.
                            </p>
                        </>
                    }
                    onConfirm={() => deleteQueryHandler(currentSavedQuery.current)}
                />
            </div>
        </Fragment>
    )
})





export default SavedQueryTable
