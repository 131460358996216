import React from "react";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "reactstrap";
import { Literals } from '../../../common/literals';
import { Select } from 'antd';
import classnames from "classnames";
import { CHECK, CLOSE, PLUS, SMALLGROUP, WHITEFILTER, WHITEPLUS } from "../../../../Common/iconSource";
import Buttons from "../../../../Common/button/Buttons";

const AddUserGroupModel = ({ openUserGroupModal, setOpenUserGroupModal,
    userGroups, selectedUGId, setSelectedUGId,
    setEntitlementId,
    entitlementId,
    entitlements,
    selectedUGType, 
    setSelectedUGType, 
    addGroupToDomain,
    setRole = () => {},
    isEditor = false
}) => {
    const toggle = () => setOpenUserGroupModal(false);
    const { Option } = Select;
  
    return (

        <Modal size="md" isOpen={openUserGroupModal} className="create-domain-modal" centered={true}>
            <ModalHeader close={<Buttons props={{ buttonClassName: "custom-btn-outline custom-btn mt-1", buttonEvent: (e) => { setOpenUserGroupModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType:Literals.BTN_TERTIARY}} />} toggle={toggle}>
                <SMALLGROUP/>
                <label className="section-heading ml-2 font-w-600 mb-0">{Literals.ADD_USER_GROUP}</label>
            </ModalHeader>
            <ModalBody >
                <div className="form-group">
                    <label className="modal-input-lbl">{Literals.SELECT_USER_GROUP}</label>
                    
                    <Select
                        showSearch
                        placeholder="Search user group name here..."
                        optionFilterProp="children"
                        value={userGroups?.find(u => u?.id === selectedUGId)?.name ?? null}
                        onChange={(value) => { setSelectedUGId(value) }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {userGroups.length > 0 && userGroups.map((option, index) => (
                            <Option key={index} value={option.id}>{option.name}</Option>
                        ))}
                    </Select>
                </div>
                <div className="form-group">
                    <label className="modal-input-lbl">{Literals.SELECT_ROLES}</label>
                    
                    <Select
                        showSearch
                        placeholder="Search role name here..."
                        optionFilterProp="children"
                        value={isEditor}
                        onChange={(value) => { setRole(value) }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {[{name: 'Editor', id: true}, {name: 'Viewer', id: false}].map((option, index) => (
                            <Option key={index} value={option.id}>{option.name}</Option>
                        ))}
                    </Select>
                    
                    {/* <Select
                        showSearch
                        placeholder="Search role name here..."
                        optionFilterProp="children"
                        
                        onChange={(value) => { setEntitlementId(value) }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {entitlements.length > 0 && entitlements.map((option, index) => (
                            <Option key={index} value={option.id}>{option.name}</Option>
                        ))}
                    </Select> */}
                </div>
                <div className="form-group mb-0">
                    <label className="modal-input-lbl">{Literals.SELECT_GROUP_TYPE}</label>
                    <div className="select-grp-types">
                        <p className={classnames({ 'owner-style': selectedUGType === 'owner' })} onClick={(e) => setSelectedUGType('owner')}><span className='filter'><WHITEFILTER/></span>Owner</p>
                        <p className={classnames({ 'user-style': selectedUGType === 'member' })} onClick={(e) => setSelectedUGType('member')}><span className='filter'><WHITEFILTER/></span>Member</p>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button 
                    onClick={() => addGroupToDomain(selectedUGId, selectedUGType, isEditor)} 
                    className="custom-btn-primary outline-none custom-btn btn-with-text"
                    disabled={!selectedUGId}
                >
                    {
                        // selectedUGId === null ? <><WHITEPLUS/>{Literals.SELECT_BUTTON}</> : 
                    <><CHECK/> Done</>}
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default AddUserGroupModel;