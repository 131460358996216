import {
    SET_USERS_LIST,
    SET_USERGROUPS_REMOVE_LEADER,
    SET_USERS_LIST_REFRESH,
    SET_USERGROUP_DESCRIPTION,
    SET_USER_ID,
    SET_USERS_PAGE_DEFAULT,
    OPEN_FILTER
}
    from "./usersActionTypes";


const initialState = {
    usersList: [],
    removedLeader: {},
    userGroupUserList: [],
    userId: '',
    openFilter: '',
    usersListRefresh: false,
}

const UsersReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_USERS_LIST:
            return {
                ...state,
                usersList: payload
            }
        
        case SET_USERS_PAGE_DEFAULT:
            return initialState ;

        case SET_USERGROUPS_REMOVE_LEADER:
            return {
                ...state,
                removedLeader: payload
            }
            
        case SET_USERGROUP_DESCRIPTION:
            return {
                ...state,
                userGroupUserList: payload
            }
        case OPEN_FILTER:
        return {
            ...state,
            openFilter: payload
        }
            
        case SET_USER_ID:
            return {
                ...state,
                userId: payload
            }
        case SET_USERS_LIST_REFRESH:
            return {
                ...state,
                usersListRefresh: payload
            }
        default:
            return state;
    }
}

export default UsersReducer;