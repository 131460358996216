import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { getConnectionListCreatePODAction } from '../../../../../store/modules/dataCatalogue/createPOD/createPODActions';
import { deleteConnection, getConnectionProperties, setConnectionDataList, setConnectionDynamicFields, setConnectionSelectedConn } from '../../../../../store/modules/UserManagement/connection/connectionActions';
import ActionPopUp from '../../../common/actionPopUp';
import ConnectionListView from './ConnectionListView';
import ConnectionGridView from './ConnectionsGridView';
import CreatePODNewConn from './createNewConn';
import DeleteModal from '../../../../Common/deleteModal/DeleteModal';
import { DeleteNoteContent } from '../../../common/helperFunctions';

const ConnectionListAccordion = ({
    searchValue,
    unqiueId,
    ele,
    dbDataWithDb,
    fromModal,
    fromModalRoute,
    openCreateConnection,
    setOpenCreateConnection,
    openEditConnection,
    setOpenEditConnection,
    layout,
    assignedConnections,
    setChangedConn = () => { },
    isChangeModal = false,
    disableUnselected = false,
    setWalkThroughStep = () => { },
    setEmpty = () => { }
}) => {

    const dispatch = useDispatch()

    const [type, setType] = useState("create");
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
    const [openModal, setOpenModal] = useState(false)
    const [isAlreadyAssigned, setIsAlreadyAssigned] = useState(false)

    const currentConnectionRef = React.useRef({ current: {} })
  

    useEffect(() => {
        if (dbDataWithDb?.length) {
            let boolean = dbDataWithDb.some(v => (v.assigned_as_cache === true || v.assigned_as_upload === true))
            setIsAlreadyAssigned(boolean)
        }
    }, [dbDataWithDb])

    const closeDeletePopUp = () => {
        setOpenDeletePopup(false);
    };
    const closeCreateConnection = () => {
        setType('create');
        setOpenCreateConnection(false);
        setOpenModal(false)
    };
    const closeEditConnection = () => {
        setType('create');
        setOpenEditConnection(false);
        setOpenModal(false)
    };
    const openDeleteDialog = (val) => {

        currentConnectionRef.current = val

        // setDeletePopUpContent({
        //     modalClass: "delete-popup",
        //     heading: "Delete",
        //     message: <>
        //                 <p>This action will delete this connection.</p>
        //                 <p className='alert alert-warning mb-0'>Warning: After deleting the connection, the pod, alerts, flows linked to this connection will not work</p>
        //             </>,
        //     button: [
        //     {
        //         name: "Delete",
        //         action: () => {
        //             handleDeleteConnection(val);
        //         },
        //     },
        //     ],
        //     elements: [],
        //     closeActionPopup: closeDeletePopUp,
        // });
        setOpenDeletePopup(true);
    };

    const handleDeleteConnection = (val) => {
        try {

            dispatch(deleteConnection(val?.connection_name)).then((res) => {
                if (res.status === "success") {
                    emitToastNotification('success', res?.message)
                    // emitToastNotification('success', res.message);
                    setOpenModal(false)
                    setOpenDeletePopup(false);

                    try {
                        dispatch(getConnectionListCreatePODAction()).then((res) => {
                            if (res?.data) {
                                setEmpty(res?.data?.length === 0)
                            }
                            dispatch(setConnectionDataList(res.data));
                        });
                    } catch (e) { }
                    setOpenEditConnection(false);
                }
            });
        } catch (e) { }
    };

    const enableEdit = () => {
        setOpenCreateConnection(false);
        setOpenEditConnection(true);
    };

    const handleEdit = (data) => {
        try {
            let requestBody = {
                action: "show_connector_properties",
                connector_name: data[2],
            };
            dispatch(getConnectionProperties(requestBody)).then((res) => {
                if (res?.data?.data_record?.[0]?.[1] !== undefined) {
                    dispatch(
                        setConnectionDynamicFields(res?.data?.data_record?.[0]?.[1])
                    );
                    dispatch(setConnectionSelectedConn(data));
                    enableEdit();
                    setOpenEditConnection(true);
                    setType('edit')
                    setOpenModal(true)
                } else {
                    dispatch(setConnectionDynamicFields([]));
                }
            });
        } catch (e) { }
    };

    // const layOutReturn = () => {
    //     return (
    //         /**
    //          * We are now using only Grid View in overall application
    //          */
    //         // layout === 'grid' ? 
    //         <ConnectionGridView
    //             searchValue={searchValue}
    //             unqiueId={unqiueId}
    //             openModal={openModal}
    //             listData={dbDataWithDb}
    //             openCreateConnection={openCreateConnection}
    //             setOpenCreateConnection={setOpenCreateConnection}
    //             openEditConnection={openEditConnection}
    //             setOpenEditConnection={setOpenEditConnection}
    //             openDeleteDialog={openDeleteDialog}
    //             setType={setType}
    //             setOpenModal={setOpenModal}
    //             setChangedConn={setChangedConn}
    //             isChangeModal={isChangeModal}
    //             optionsVisible={!fromModal}
    //             fromModal={fromModal}
    //             fromModalRoute={fromModalRoute}
    //             disableUnselected={disableUnselected}
    //             setWalkThroughStep={setWalkThroughStep}
    //         />
    //         // : <ConnectionListView 
    //         //     searchValue={searchValue} 
    //         //     listData={dbDataWithDb}
    //         //     openCreateConnection={openCreateConnection} 
    //         //     setOpenCreateConnection={setOpenCreateConnection}
    //         //     openEditConnection={openEditConnection} 
    //         //     setOpenEditConnection={setOpenEditConnection}
    //         //     openDeleteDialog={openDeleteDialog}
    //         //     setType={setType}
    //         //     setOpenModal={setOpenModal}
    //         //     closeEditConnection={closeEditConnection}
    //         //     handleEdit={handleEdit}
    //         // />
    //     )
    // }

    return (
        <>
            {
                dbDataWithDb?.length > 0
                    ? <ConnectionGridView
                        searchValue={searchValue}
                        unqiueId={unqiueId}
                        openModal={openModal}
                        listData={dbDataWithDb}
                        openCreateConnection={openCreateConnection}
                        setOpenCreateConnection={setOpenCreateConnection}
                        openEditConnection={openEditConnection}
                        setOpenEditConnection={setOpenEditConnection}
                        openDeleteDialog={openDeleteDialog}
                        setType={setType}
                        setOpenModal={setOpenModal}
                        setChangedConn={setChangedConn}
                        isChangeModal={isChangeModal}
                        optionsVisible={!fromModal}
                        fromModal={fromModal}
                        fromModalRoute={fromModalRoute}
                        disableUnselected={disableUnselected}
                        setWalkThroughStep={setWalkThroughStep}
                    />
                    : ''
            }


            {
                <CreatePODNewConn
                    connList={dbDataWithDb}
                    isAlreadyAssigned={isAlreadyAssigned}
                    closeConnection={openCreateConnection ? closeCreateConnection : openEditConnection ? closeEditConnection : () => { }}
                    type={type}
                    openDeleteDialog={openDeleteDialog}
                    enableEdit={setType}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setEmpty={setEmpty}
                />
            }

            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={DeleteNoteContent(
                    `${currentConnectionRef?.current?.connection_name}?connection_name=${currentConnectionRef?.current?.connection_name}`,
                    'connection',
                    'Warning: After deleting the connection, the Pod, Alerts, Flows linked to this connection will not work.', true)
                }
                onConfirm={() => handleDeleteConnection(currentConnectionRef?.current)}
            />


            {/* <ActionPopUp
                openModal={openDeletePopup}
                actionData={deletePopUpContent}
            /> */}
        </>
    )
}
export default ConnectionListAccordion;