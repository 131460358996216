import { Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import sticky from '../../../../../assets/icons/Sticky.svg';
import { CLOSE, DATABRICKSICON, EDIT, EXITFULLSCREEN, FOLDER, FULLSCREEN, PLAY, REDIRECTJOBS, REFRESH, SAVE, SCRIPT, SCRIPTEDITOR } from '../../../../Common/iconSource';
import { Literals } from "../../../common/literals";

import Buttons from '../../../../Common/button/Buttons';
import DatabricksOutputDataSet from './DatabricksOutputDataSet';
import '../../../../Common/CommonModal/commonModal.scss'


import { connect, useSelector, useStore } from 'react-redux';

import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { fetchPreviewTable, setActivePodDetails } from '../../../../../store/modules/common/VirtualDataTable/action';
import { executeTransformerApi, saveTransformerNoteClick, setExecutedTransformerPayload, setFlowsCurrenttransformer, setTransformerNames, showDatabricksNoteBook, showFlowsTransformdetails, showJupyterNoteBook, updateFlowsElements } from '../../../../../store/modules/flows/flowsActions';
import { ConnectionDataBricksSave, CreateDataBricksNotebook, CreateNotebook, LoadDataBricksNotebook, LoginDatabricksNoteBook, LoginNoteBook, getBricksNotebookCode, getBricksNotebookURL, getDatabrickClusters, getDatabricksNotebookCode, resetScriptRedux, saveNoteBookDone, setBricksOutputDataset, setScriptCommonPaylod, setScriptOutputDataset, setScriptPythonCode, updateClusterEnv } from '../../../../../store/modules/flows/scriptTransformer/scriptAction';
import { deleteCookies } from '../../../common/helperFunctions';
import DatasetOutputNameInput from '../commonFunctions/useDatasetNameInput';
import EditorIndex from '../DataSource/editor/EditorIndex';

import SqlEditor from '../../../dataCatalogue/preview/components/SqlEditor';
import DatabricksLandingPage from './DatabricksLandingPage';
import LoadExistingBricks from './LoadExistingBricks';
import LoadExistingBricksNotebook from './LoadExistingBricksNotebook';
import SingleSelection from '../../../../Common/dropDown/SingleSelection';
import { getConnectionListV2 } from '../../../../../store/actions';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { DATABRICKS_ICON } from '../../../../Common/newIconSource';
import { SCRIPTDEFAULTVIEW } from '../script/ScriptDefaultInfography';
import { Folder } from 'react-bootstrap-icons';


const { TabPane } = Tabs;
const DatabricksIndex = (props) => {
    const { flowsElements, currentTransformer, transformerNameList, isExecutePermission, isEditPermission, isJupyterPermission } = props
    const { flowId } = useParams()
    let script_code_ref = useRef(null);

    const [editMode, setEditMode] = useState(false)
    const [showBtnLoading, setShowBtnLoading] = useState(false)
    const [activeKey, setActiveKey] = useState('scriptEditor')
    const [disableExcute, setdisableExcute] = useState(false)
    const store = useStore();
    const [toggleDefaultView, setToggleDefaultView] = useState(true)
    const [showDatabricksLandingPage, setshowDatabricksLandingPage] = useState(false)
    const [showloadExisting, setshowloadExisting] = useState(false)
    const [callListWorkspaceApi, setcallListWorkspaceApi] = useState(false)
    const [selectedNotebookDetails, setselectedNotebookDetails] = useState(null)
    const [showModal, setshowModal] = useState(false)

    const [expandTable, setexpandTable] = useState(false)
    const [showApplyButton, setshowApplyButton] = useState(false)
    const [configuration, setconfiguration] = useState([])
    const [bricksList, setbricksList] = useState([])
    const [showRedBtn, setshowRedBtn] = useState(false);

    const [transformerName, settransformerName] = useState('')
    const onChange = activeKey => {
        setActiveKey(activeKey)
    };

    const [pythonCode, setpythonCode] = useState('')
    const [selectedBricksConnection, setselectedBricksConnection] = useState('')
    const [hostValue, sethostValue] = useState('')
    const [orgId, setorgId] = useState('')

    const [selectedBricksConnection1, setselectedBricksConnection1] = useState('')
    const [hostValue1, sethostValue1] = useState('')

    const [personalToken, setpersonalToken] = useState('')
    const [commonPayload, setcommonPayload] = useState({})
    const [additional_settings, setAdditionalSettings] = useState({
        "cpu_request": "",
        "mem_request": "",
        "configuration": "",
        "extra_resource_limits": "",
        "user_libs": "",
        "cluster_id": ""
    })
    const jupyterCode = useSelector(state => state.Flows.Script.scriptPythonCode);
    const jupyterCodeId = useSelector(state => state.Flows.Script.scriptCurrentId);
    const saveNoteBookDoneFlag = useSelector(state => state.Flows.Script.saveNoteBookDone);

    useEffect(() => {


        return () => {
            clearInterval(script_code_ref.current)
        }
    }, [])
    useEffect(() => {
    
        if (!transformerName) {
          setshowRedBtn(true)
        }
      }, [transformerName])

    useEffect(() => {
        deleteCookies()


        if (jupyterCode !== '' && jupyterCodeId === currentTransformer.id) {
            setpythonCode(jupyterCode);
            let finalFlowsElements = flowsElements.map(item => {
                if (item.id === currentTransformer.id) {
                    item.content['pythonCode'] = jupyterCode
                    // item.content['selectedBricksNotebook'] = selectedNotebookDetails


                }
                return item
            })
            store.dispatch(updateFlowsElements([...finalFlowsElements]))
        }



    }, [jupyterCode, saveNoteBookDoneFlag])


    const setOutputDataset = (outputDataSetList) => {
        let Frame = []
        let outputDataSetValue = outputDataSetList.map((ele) => ele.value)
        console.log(outputDataSetList, selectedNotebookDetails)
        if (outputDataSetList.length && currentTransformer.id && transformerName !== '' && selectedNotebookDetails) {


            let isNameAlreadyExist = checkTransformerNameExist(outputDataSetList, true)
            console.log(isNameAlreadyExist)
            if (!isNameAlreadyExist) {
                let transformerNameList = store.getState().FlowsReducer.transformerName;
                outputDataSetValue.forEach((item, i) => {
                    transformerNameList[outputDataSetList[i]?.id] = outputDataSetList[i]?.value
                    store.dispatch(setTransformerNames(transformerNameList))
                })
                outputDataSetValue = outputDataSetList.map((ele) => ele.value)
                if (outputDataSetList.length && transformerName !== '') {
                    let currentDataSource = flowsElements.find(item => item.id === currentTransformer.id)
                    if (currentDataSource && currentDataSource.input_source && Object.keys(currentDataSource.input_source).length) {
                        let podDetails = store.getState().FlowsReducer.dataSourcePodDetails;

                        Object.keys(currentDataSource.input_source).forEach((inputTransformer, i) => {
                            let transformerDetails = flowsElements.find((ele) => ele.id === inputTransformer);

                            if (transformerDetails && transformerDetails.type.includes('datasource')) {
                                if (podDetails.length) {
                                    let pod = podDetails.find((ele) => Object.keys(ele)[0] === inputTransformer)
                                    if (pod) {
                                        Frame.push(`${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName}`)
                                    }
                                }
                            } else {
                                Frame.push(transformerDetails?.transformerName)
                            }

                        })

                    }

                    let payload = {
                        "flow_body": {
                            "Id": transformerName,
                            "NodeType": "databricks_script_nb_python3",
                            currentTransformerID: currentTransformer.id,

                            "Args": [
                                {
                                    "Name": "input",
                                    "Value": Frame
                                },
                                {
                                    "Name": "output",
                                    "Value": outputDataSetValue
                                },
                                {
                                    "Name": "script_name",
                                    "Value": ""
                                },
                                {
                                    "Name": "kernel",
                                    "Value": "python3"
                                },
                                {
                                    "Name": "notebook_path",
                                    "Value": `/${selectedNotebookDetails?.path}`

                                },
                                {
                                    "Name": "databricks_host",
                                    "Value": hostValue1

                                },
                                {
                                    "Name": "cpu_request",
                                    "Value": ""
                                },
                                {
                                    "Name": "mem_request",
                                    "Value": `4G`
                                },
                                {
                                    "Name": "extra_resource_limits",
                                    "Value": ""
                                },
                                {
                                    "Name": "configuration",
                                    "Value": ""

                                },

                                {
                                    "Name": "user_libs",
                                    "Value": ""

                                },
                                {
                                    "Name": "cluster_id",
                                    "Value": additional_settings.cluster_id

                                },


                            ]
                        }
                    }


                    setcommonPayload({ ...payload })
                    let temp = store.getState().FlowsReducer.executedTransformerPayload;
                    if (temp?.length) {
                        let existedTransformerIndex = temp.findIndex((ele) => ele.currentTransformerID === currentTransformer.id);

                        if (existedTransformerIndex > -1) {
                            temp.splice(existedTransformerIndex, 1, payload['flow_body'])
                        }
                    }

                    store.dispatch(setExecutedTransformerPayload(temp))

                    store.dispatch(setScriptCommonPaylod(payload))
                    store.dispatch(LoadDataBricksNotebook(flowId, payload)).then((resp) => {

                        if (resp && resp.data && resp.data.code) {
                            setshowApplyButton(true);
                            setshowloadExisting(false)
                            setpythonCode(resp.data.code)
                            store.dispatch(setScriptPythonCode({ code: resp.data.code, id: currentTransformer.id, path: resp.data.path }));
                            store.dispatch(saveNoteBookDone(false
                            ))

                            let finalFlowsElements = flowsElements.map(item => {
                                if (item.id === currentTransformer.id) {
                                    item.paired = outputDataSetList.map((ele) => ele.id)
                                    item.content['additional_settings'] = additional_settings
                                    item.content['outputdatasetList'] = outputDataSetList;
                                    item.content['pythonCode'] = resp.data.code
                                    item.content['selectedBricksNotebook'] = selectedNotebookDetails
                                    item.content.executed_DAG = payload.flow_body



                                    item.transformerName = transformerName
                                }
                                return item
                            })

                            store.dispatch(updateFlowsElements([...finalFlowsElements]))
                            store.dispatch(setBricksOutputDataset(outputDataSetList))


                        }
                    }).catch((e) => {

                        outputDataSetValue.forEach((item, i) => {
                            delete transformerNameList[outputDataSetList[i]?.id]
                            store.dispatch(setTransformerNames(transformerNameList))
                        })

                    })



                }
            } else {
                // store.dispatch(setBricksOutputDataset(outputDataSetList))

            }

        } else if (transformerName === '') {
            emitToastNotification('info', 'Please enter script transformer name to create noetbook ')
        } else if (!selectedNotebookDetails) {
            emitToastNotification('info', 'Please select a Notebook from Notebook Explorer')
        }

    }


    const applyNewOuputDataSet = (outputDataSetList) => {
        console.log(outputDataSetList, additional_settings)

        if (outputDataSetList.length) {

            let isNameAlreadyExist = checkTransformerNameExist(outputDataSetList, true)
            if (!isNameAlreadyExist) {
                let transformerNameList = store.getState().FlowsReducer.transformerName
                
                outputDataSetList.forEach((ele) => {
                    transformerNameList[ele.id] = ele.value
                })
                
                store.dispatch(setBricksOutputDataset(outputDataSetList))

                let Frame = []
                let currentDataSource = flowsElements.find(item => item.id === currentTransformer.id)
                if (currentDataSource && currentDataSource.input_source && Object.keys(currentDataSource.input_source).length) {
                    let podDetails = store.getState().FlowsReducer.dataSourcePodDetails;

                    Object.keys(currentDataSource.input_source).forEach((inputTransformer, i) => {
                        let transformerDetails = flowsElements.find((ele) => ele.id === inputTransformer);

                        if (transformerDetails && transformerDetails.type.includes('datasource')) {
                            if (podDetails.length) {
                                let pod = podDetails.find((ele) => Object.keys(ele)[0] === inputTransformer)
                                if (pod) {
                                    Frame.push(`${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName}`)
                                }
                            }
                        } else {
                            Frame.push(transformerDetails?.transformerName)
                        }

                    })

                }


                let outputDataSetValue = outputDataSetList.map((ele) => ele.value)
                let payload = {
                    "flow_body": {
                        "Id": transformerName,
                        "NodeType": "databricks_script_nb_python3",
                        currentTransformerID: currentTransformer.id,

                        "Args": [
                            {
                                "Name": "input",
                                "Value": Frame
                            },
                            {
                                "Name": "output",
                                "Value": outputDataSetValue
                            },
                            {
                                "Name": "script_name",
                                "Value": ""
                            },
                            {
                                "Name": "kernel",
                                "Value": "python3"
                            },
                            {
                                "Name": "notebook_path",
                                "Value": selectedNotebookDetails?.path

                            },
                            {
                                "Name": "databricks_host",
                                "Value": hostValue1

                            },
                            {

                                "Name": "cpu_request",
                                "Value": additional_settings.cpu_request
                            },
                            {
                                "Name": "mem_request",
                                "Value": `${additional_settings.mem_request}`
                            },
                            {
                                "Name": "extra_resource_limits",
                                "Value": additional_settings.extra_resource_limits
                            },
                            {
                                "Name": "configuration",
                                "Value": additional_settings.configuration
                            },
                            {
                                "Name": "user_libs",
                                "Value": additional_settings.user_libs

                            },
                            {
                                "Name": "cluster_id",
                                "Value": additional_settings.cluster_id

                            },
                        ]
                    }
                }
                setcommonPayload({ ...payload })
                let finalFlowsElements = flowsElements.map(item => {
                    if (item.id === currentTransformer.id) {
                        item.paired = outputDataSetList.map((ele) => ele.id)
                        item.content['additional_settings'] = additional_settings
                        item.content.executed_DAG=payload.flow_body
                        item.content['outputdatasetList'] = outputDataSetList;
                    }
                    return item
                })
                store.dispatch(updateFlowsElements([...finalFlowsElements]))

                store.dispatch(setScriptCommonPaylod(payload))
            }
        }

    }


    const notebook_path = useSelector(state => state.Flows.Script.scriptPath)

    const getUpdateCode = () => {
        let outputDataSetList = store.getState().Flows.Script.bricksOutputDataset;
        console.log(outputDataSetList)
        let Frame = []
        if (outputDataSetList?.length && currentTransformer.id && transformerName) {
            let outputDataSetValue = outputDataSetList.map((ele) => ele.value)


            let currentDataSource = flowsElements.find(item => item.id === currentTransformer.id)
            if (currentDataSource && currentDataSource.input_source && Object.keys(currentDataSource.input_source).length) {
                let podDetails = store.getState().FlowsReducer.dataSourcePodDetails;

                Object.keys(currentDataSource.input_source).forEach((inputTransformer, i) => {
                    let transformerDetails = flowsElements.find((ele) => ele.id === inputTransformer);

                    if (transformerDetails && transformerDetails.type.includes('datasource')) {
                        if (podDetails.length) {
                            let pod = podDetails.find((ele) => Object.keys(ele)[0] === inputTransformer)
                            if (pod) {
                                Frame.push(`${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName}`)
                            }
                        }
                    } else {
                        Frame.push(transformerDetails?.transformerName)
                    }

                })

            }
            let payload = {
                "flow_body": {
                    "Id": transformerName,
                    "NodeType": "databricks_script_nb_python3",
                    currentTransformerID: currentTransformer.id,

                    "Args": [
                        {
                            "Name": "input",
                            "Value": Frame
                        },
                        {
                            "Name": "output",
                            "Value": outputDataSetValue
                        },
                        {
                            "Name": "script_name",
                            "Value": ""
                        },
                        {
                            "Name": "kernel",
                            "Value": "python3"
                        },

                        {
                            "Name": "notebook_path",
                            "Value": selectedNotebookDetails?.path

                        },
                        {
                            "Name": "databricks_host",
                            "Value": hostValue1

                        },
                        {
                            "Name": "cpu_request",
                            "Value": additional_settings.cpu_request
                        },
                        {
                            "Name": "mem_request",
                            "Value": `${additional_settings.mem_request}`
                        },
                        {
                            "Name": "extra_resource_limits",
                            "Value": additional_settings.extra_resource_limits
                        },
                        {
                            "Name": "configuration",
                            "Value": additional_settings.configuration
                        },
                        {
                            "Name": "user_libs",
                            "Value": additional_settings.user_libs

                        },

                        {
                            "Name": "databricks_host",
                            "Value": hostValue1

                        },
                        {
                            "Name": "cluster_id",
                            "Value": additional_settings.cluster_id

                        },
                    ]
                }
            }
            setcommonPayload({ ...payload })
            let temp = store.getState().FlowsReducer.executedTransformerPayload;
            if (temp?.length) {
                let existedTransformerIndex = temp.findIndex((ele) => ele.currentTransformerID === currentTransformer.id);

                if (existedTransformerIndex > -1) {
                    temp.splice(existedTransformerIndex, 1, payload['flow_body'])
                }
            }

            store.dispatch(setExecutedTransformerPayload(temp))

            store.dispatch(setScriptCommonPaylod(payload))
            console.log(additional_settings)

            // store.dispatch(updateClusterEnv({ cluster_id: additional_settings.cluster_id }))
            // script_code_ref.current = setInterval(() => {
            store.dispatch(getDatabricksNotebookCode(flowId, payload)).then((resp) => {

                if (resp && resp.data.content) {
                    setpythonCode(resp.data.content);
                    // store.dispatch(setScriptPythonCode({ code: resp.data.content, id: currentTransformer.id, path: '' }));

                    let finalFlowsElements = flowsElements.map(item => {
                        if (item.id === currentTransformer.id) {
                            item.paired = outputDataSetList.map((ele) => ele.id)
                            item.content['additional_settings'] = additional_settings
                            item.content['outputdatasetList'] = outputDataSetList;
                            item.content['pythonCode'] = resp.data.content
                            item.content['selectedBricksNotebook'] = selectedNotebookDetails
                            item.content.executed_DAG = payload.flow_body



                            item.transformerName = transformerName
                        }
                        return item
                    })

                    store.dispatch(updateFlowsElements([...finalFlowsElements]))




                }
            })
            // }, 30000);
            clearInterval(script_code_ref.current)
        }

    }

    const openJupyterNotebook = () => {



        let outputDataSetList = store.getState().Flows.Script.bricksOutputDataset;
        console.log(outputDataSetList)
        let Frame = []
        if (outputDataSetList?.length && currentTransformer.id && transformerName) {
            let outputDataSetValue = outputDataSetList.map((ele) => ele.value)


            let currentDataSource = flowsElements.find(item => item.id === currentTransformer.id)
            if (currentDataSource && currentDataSource.input_source && Object.keys(currentDataSource.input_source).length) {
                let podDetails = store.getState().FlowsReducer.dataSourcePodDetails;

                Object.keys(currentDataSource.input_source).forEach((inputTransformer, i) => {
                    let transformerDetails = flowsElements.find((ele) => ele.id === inputTransformer);

                    if (transformerDetails && transformerDetails.type.includes('datasource')) {
                        if (podDetails.length) {
                            let pod = podDetails.find((ele) => Object.keys(ele)[0] === inputTransformer)
                            if (pod) {
                                Frame.push(`${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName}`)
                            }
                        }
                    } else {
                        Frame.push(transformerDetails?.transformerName)
                    }

                })

            }
            let payload = {
                "flow_body": {
                    "Id": transformerName,
                    "NodeType": "databricks_script_nb_python3",
                    currentTransformerID: currentTransformer.id,

                    "Args": [
                        {
                            "Name": "input",
                            "Value": Frame
                        },
                        {
                            "Name": "output",
                            "Value": outputDataSetValue
                        },
                        {
                            "Name": "script_name",
                            "Value": ""
                        },
                        {
                            "Name": "kernel",
                            "Value": "python3"
                        },

                        {
                            "Name": "notebook_path",
                            "Value": selectedNotebookDetails?.path

                        },
                        {
                            "Name": "databricks_host",
                            "Value": hostValue1

                        },
                        {
                            "Name": "cpu_request",
                            "Value": additional_settings.cpu_request
                        },
                        {
                            "Name": "mem_request",
                            "Value": `${additional_settings.mem_request}`
                        },
                        {
                            "Name": "extra_resource_limits",
                            "Value": additional_settings.extra_resource_limits
                        },
                        {
                            "Name": "configuration",
                            "Value": additional_settings.configuration
                        },
                        {
                            "Name": "user_libs",
                            "Value": additional_settings.user_libs

                        },

                        {
                            "Name": "databricks_host",
                            "Value": hostValue1

                        },
                        {
                            "Name": "cluster_id",
                            "Value": additional_settings.cluster_id

                        },
                    ]
                }
            }
            setcommonPayload({ ...payload })
            let temp = store.getState().FlowsReducer.executedTransformerPayload;
            if (temp?.length) {
                let existedTransformerIndex = temp.findIndex((ele) => ele.currentTransformerID === currentTransformer.id);

                if (existedTransformerIndex > -1) {
                    temp.splice(existedTransformerIndex, 1, payload['flow_body'])
                }
            }

            store.dispatch(setExecutedTransformerPayload(temp))

            store.dispatch(setScriptCommonPaylod(payload))
            console.log(additional_settings)

            // store.dispatch(updateClusterEnv({ cluster_id: additional_settings.cluster_id }))

            store.dispatch(getBricksNotebookURL(flowId, payload)).then((resp) => {
                if (resp && resp.data['nb_url']) {

                    let finalFlowsElements = flowsElements.map(item => {
                        if (item.id === currentTransformer.id) {
                            item.paired = outputDataSetList.map((ele) => ele.id)
                            item.content['additional_settings'] = additional_settings
                            item.content['outputdatasetList'] = outputDataSetList;
                            item.content['pythonCode'] = resp.data.code
                            item.content['selectedBricksNotebook'] = selectedNotebookDetails

                            item.content.executed_DAG=payload.flow_body

                            item.transformerName = transformerName
                        }
                        return item
                    })

                    store.dispatch(updateFlowsElements([...finalFlowsElements]))

                    window.open(resp.data['nb_url'], '_blank', 'noreferrer');
                    getUpdateCode()


                }
            }).catch((err) => {
                emitToastNotification('error', err?.message)
            })
        }

    }


    const checkTransformerNameExist = (outputDatasetArray, isapplyNewNode = false) => {
        let key = 'value'
        let uniqueDatasetArray = [...new Map(outputDatasetArray.map(item =>
            [item[key], item])).values()];
        let isExist = false

        console.log(uniqueDatasetArray)
        if (uniqueDatasetArray?.length !== outputDatasetArray.length) {
            let tempId = uniqueDatasetArray.map((item) => item.id)

            let findDuplicateValue = outputDatasetArray.find((item) => !tempId.includes(item.id))?.value
            if (findDuplicateValue) {
                isExist = true
                emitToastNotification('error', `"${findDuplicateValue}" A transformer with this name already exists. Please rename the transformer.`
                )
            }
            return isExist
        } else {
            isExist = false

            let transformerNameList = store.getState().FlowsReducer.transformerName;
            if (isapplyNewNode) {
                for (const key in transformerNameList) {
                    const element = transformerNameList[key];
                    let findIndex = uniqueDatasetArray.findIndex((item) => item.value === element && item.id === key)
                    if (findIndex > -1) {
                        uniqueDatasetArray.splice(findIndex, 1)
                    }
                }
            }
            let tempValues = uniqueDatasetArray.map((item) => item.value);

            if (tempValues && tempValues.length) {
                for (const key in transformerNameList) {
                    const element = transformerNameList[key];
                    if (tempValues.includes(element)) {
                        emitToastNotification('error', `"${element}" A transformer with this name already exists. Please rename the transformer.`)

                        isExist = true
                    }
                }
                return isExist

            }

        }

    }
    const getTabArray = async (outputName) => {



        let currentTabArray = flowsElements.find(item => item.id === currentTransformer.id).tabArray
        let outputDataSetList = store.getState().Flows.Script.bricksOutputDataset;
        console.log(outputDataSetList, outputName)
        let outcomeColumns = {}
        let temp = []
        let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')

        for (let i = 0; i < outputName?.length; i++) {

            let index = currentTabArray.findIndex((item) => item.transformerId === outputDataSetList[i]?.id);
            if (index === -1 && outputName[i] && outputName[i] !== '') {

                currentTabArray.unshift({ transformerId: outputDataSetList[i].id, podName: `${outputName[i]}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: `${outputName[i]}` })
            }
            try {

                let transformerProgress = store.getState().FlowsReducer.transformerProgress

                let currentStatus = ''
                if (transformerProgress && transformerProgress.length) {
                    currentStatus = transformerProgress.find(element => {
                        return element.crnt_transformer === currentTransformer.id
                    })?.state;
                }
                let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')

                temp = await getPreviewResult({ podName: `${outputName[i]}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : '', })

                if (temp && temp.length) {
                    outcomeColumns[outputDataSetList[i].id] = temp;
                }

            } catch (error) {

            }
        }




        return ({ columnObj: outcomeColumns, currentTabArray: currentTabArray })



    }
    const executeTransformer = async () => {
        let outputName = ''

        let outputDataSetList = store.getState().Flows.Script.bricksOutputDataset;
        let Frame = []
        if (outputDataSetList.length && currentTransformer.id) {

            let outputDataSetValue = outputDataSetList.map((ele) => ele.value)


            let currentDataSource = flowsElements.find(item => item.id === currentTransformer.id)
            // if (currentDataSource?.content[`pythonCode`] !== '') {
            //     setpythonCode(currentDataSource?.content[`pythonCode`])
            //     store.dispatch(setScriptPythonCode({ code: currentDataSource?.content[`pythonCode`], id: currentTransformer.id }))
            // }
            if (currentDataSource && currentDataSource.input_source && Object.keys(currentDataSource.input_source).length) {
                let podDetails = store.getState().FlowsReducer.dataSourcePodDetails;

                Object.keys(currentDataSource.input_source).forEach((inputTransformer, i) => {
                    let transformerDetails = flowsElements.find((ele) => ele.id === inputTransformer);

                    if (transformerDetails && transformerDetails.type.includes('datasource')) {
                        if (podDetails.length) {
                            let pod = podDetails.find((ele) => Object.keys(ele)[0] === inputTransformer)
                            if (pod) {
                                Frame.push(`${pod[Object.keys(pod)[0]].dataDomain}.${pod[Object.keys(pod)[0]].podName}`)
                            }
                        }
                    } else {
                        Frame.push(transformerDetails?.transformerName)
                    }

                })

            }
            setdisableExcute(true)
            setShowBtnLoading(true)
            let payload = {
                "flow_body": {
                    "Id": transformerName,
                    "NodeType": "databricks_script_nb_python3",
                    currentTransformerID: currentTransformer.id,

                    "Args": [
                        {
                            "Name": "input",
                            "Value": Frame
                        },
                        {
                            "Name": "output",
                            "Value": outputDataSetValue
                        },
                        {
                            "Name": "script_name",
                            "Value": ""
                        }, {
                            "Name": "kernel",
                            "Value": "python3"
                        },
                        {
                            "Name": "notebook_path",
                            "Value": selectedNotebookDetails?.path

                        },
                        {
                            "Name": "databricks_host",
                            "Value": hostValue1

                        },
                        {
                            "Name": "cpu_request",
                            "Value": additional_settings.cpu_request
                        },
                        {
                            "Name": "mem_request",
                            "Value": `${additional_settings.mem_request}`
                        },
                        {
                            "Name": "extra_resource_limits",
                            "Value": additional_settings.extra_resource_limits
                        },
                        {
                            "Name": "user_libs",
                            "Value": additional_settings.user_libs

                        },
                        {
                            "Name": "configuration",
                            "Value": additional_settings.configuration
                        },
                        {
                            "Name": "cluster_id",
                            "Value": additional_settings.cluster_id

                        },
                    ]
                }
            }

            store.dispatch(executeTransformerApi(flowId, payload, currentTransformer.id)).then(async (resp) => {

                setdisableExcute(false)
                setShowBtnLoading(false)
                setshowRedBtn(false)


                if (resp.status === 'success') {
                    let newStatusInterval = setInterval(async () => {

                        let transformerProgress = store.getState().FlowsReducer.transformerProgress

                        let currentStatus = ''
                        if (transformerProgress && transformerProgress.length) {
                            currentStatus = transformerProgress.find(element => {
                                return element.crnt_transformer === currentTransformer.id
                            })?.state;
                        }

                        let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
                        if (currentStatus === 'success') {
                            outputName = payload?.flow_body?.Args[1]?.Value
                            store.dispatch(setActivePodDetails({ podName: `${outputName[0]}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', }))

                            if (outputName && outputName.length) {

                                let results = await getTabArray(outputName)
                                let flowsElements = store.getState().FlowsReducer.flowsElements
                                let finalFlowsElements = flowsElements.map(item => {
                                    if (item.id === currentTransformer.id) {
                                        item.status = "success"
                                        item.transformerName = transformerName
                                        item.content['outputdatasetList'] = outputDataSetList;
                                        item.content['additional_settings'] = additional_settings
                                        item.content.executed_DAG=payload.flow_body

                                        item.content['pythonCode'] = pythonCode;
                                        item.content['selectedBricksNotebook'] = selectedNotebookDetails


                                        item.scriptCommonPayload = store.getState().Flows.Script.scriptCommonPayload;
                                        item.scriptPythonCode = pythonCode;
                                        item.scriptOutputDataset = store.getState().Flows.Script.bricksOutputDataset;
                                        item.tabArray = [...results?.currentTabArray]
                                    }
                                    return item
                                })

                                if (Object.keys(results?.columnObj)?.length) {
                                    updateElementStatus({ ...results?.columnObj })
                                    // updatePairedElementContent([...outcomeColumns]);

                                }
                                store.dispatch(updateFlowsElements([...finalFlowsElements]))
                            }
                            clearInterval(newStatusInterval)

                        }
                    }, 1000);

                }
            }).catch((err) => {
                setdisableExcute(false)
                setShowBtnLoading(false)

                let finalFlowsElements = flowsElements.map(item => {
                    if (item.id === currentTransformer.id) {
                        item.status = "error"

                    }
                    return item
                })
                updateElementStatusToError()
                store.dispatch(updateFlowsElements([...finalFlowsElements]))
            })
        }
    }

    const getUUID = () => {
        let id = uuidv4();

        return id;

    }
    const getPreviewResult = (podDetails) => {
        if (podDetails) {


            let request = {
                uuid: getUUID(),
                connection_name: podDetails.connectionName,
                table_name: podDetails.podName,
                page: 0,
                schema_name: podDetails.dataDomain,
                do_count: false,
                sort_body: [],
                filter_body: [],
                table_ids: []

            }
            return store.dispatch(fetchPreviewTable(request)).then((response) => {
                if (response?.status === 'success' && response?.data) {
                    let responseData = response.data.result;

                    if (responseData && responseData !== null) {

                        let outcomeColumns = []
                        if (responseData?.data_header?.length) {
                            responseData.data_header.forEach((item, index) => {
                                outcomeColumns.push({
                                    id: `${item}_${index}`,
                                    text: item,
                                    technical: item,
                                    dataType: responseData?.data_dtype[index],
                                    checked: false,
                                    type: podDetails.podName,

                                })
                            })
                            if (outcomeColumns?.length) {
                                return outcomeColumns
                            } else {
                                return []
                            }
                        }

                    }
                }
            })

        }
    }
    const updateElementStatusToError = () => {

        let pairedTransformers = flowsElements.find((ele) => ele.id === currentTransformer.id)?.paired

        let newflowsElements = [...flowsElements]

        if (pairedTransformers && pairedTransformers.length) {
            newflowsElements.forEach((element) => {
                if (pairedTransformers.includes(element.id)) {
                    element.status = "error"
                    if (element.paired && element.paired.length) {
                        newflowsElements.forEach((ele) => {
                            if (element.paired.includes(ele.id)) {
                                ele.status = "initialState"

                            }
                        })
                    }
                }
            })
        }
        store.dispatch(updateFlowsElements(newflowsElements))
    }
    // const updatePairedElementContent = (outcomeColumns) => {
    //     // let outcomeColumns = flowsElements.find(item => item.id === currentTransformer.id)?.content?.outcomeColumns

    //     let pairedTransformers = flowsElements.find(
    //         (item) => item.id === currentTransformer.id
    //     ).paired;

    //     if (pairedTransformers && pairedTransformers.length > 0) {
    //         let finalFlowsElements = flowsElements.map((item) => {
    //             let index = pairedTransformers.findIndex((ele) => ele === item.id);
    //             if (index > -1) {
    //                 item[`input_source`][`${currentTransformer.id}`] = outcomeColumns;
    //             }
    //             return item;
    //         });

    //         dispatch(updateFlowsElements([...finalFlowsElements]));
    //     }
    // };

    const updateElementStatus = (outcomeColumns) => {
        let flowsElements = store.getState().FlowsReducer.flowsElements
        let pairedTransformers = flowsElements.find((ele) => ele.id === currentTransformer.id)?.paired
        let currentDataSource = flowsElements.find(item => item.id === currentTransformer.id)
        let newflowsElements = [...flowsElements]
        let outputDataSetList = store.getState().Flows.Script.bricksOutputDataset;
        if (pairedTransformers && pairedTransformers.length) {
            newflowsElements.forEach((element) => {
                if (pairedTransformers.includes(element.id)) {
                    if (currentDataSource.status === 'success') {

                        element.status = "success"
                    } else {
                        element.status = "error"

                    }

                    if (outcomeColumns) {

                        element['input_source'][currentTransformer.id] = outcomeColumns[element.id]
                        element.content.outcomeColumns = outcomeColumns[element.id]
                        if (outcomeColumns && outcomeColumns[element.id] && outcomeColumns[element.id]?.length > 0) {

                            element.status = "success"
                        } else {
                            element.status = "error"

                        }
                    }

                    if (outputDataSetList && outputDataSetList.length) {
                        let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
                        let outputValue = outputDataSetList.find((ele) => ele.id === element.id);
                        if (outputValue && outputValue.value) {
                            element.transformerName = outputValue.value
                            element.tabArray = [{ transformerId: outputValue.id, podName: `${outputValue.value}`, dataDomain: `flow_${flowId}`, connectionName: GlobalConnectionCache ? GlobalConnectionCache : 'application_cache', transformerName: outputValue.value }]
                        }
                    }
                    if (element.paired && element.paired.length) {
                        newflowsElements.forEach((ele) => {
                            if (element.paired.includes(ele.id)) {
                                ele.status = "initialState"


                                ele['input_source'][element.id] = outcomeColumns[element.id]


                            }
                        })
                    }
                }
            })
        }

        store.dispatch(updateFlowsElements(newflowsElements))
    }
    useEffect(() => {
        let currentTransformerContent = flowsElements.find(item => item.id === currentTransformer.id)?.content

        let temp = store.getState().FlowsReducer.executedTransformerPayload;

        if (temp?.length) {
            let existedTransformerpayload = temp.find((ele) => ele?.currentTransformerID === currentTransformer.id);


            if (existedTransformerpayload && existedTransformerpayload?.Args?.length) {
                setAdditionalSettings({
                    mem_request: '',
                    configuration: '',
                    cpu_request: '',
                    extra_resource_limits: '',
                    user_libs: existedTransformerpayload.Args[existedTransformerpayload?.Args?.length - 3]?.name === 'user_libs' ? existedTransformerpayload.Args[existedTransformerpayload?.Args?.length - 3]?.Value : '',
                    cluster_id: existedTransformerpayload?.Args[existedTransformerpayload?.Args?.length - 1]?.name === 'cluster_id' ? existedTransformerpayload.Args[existedTransformerpayload?.Args?.length - 1]?.Value : additional_settings.cluster_id,


                })
            }

        }

        // if (currentTransformerContent && Object.keys(currentTransformerContent).length !== 0) {
        //     let transformerProgress = store.getState().FlowsReducer.transformerProgress

        //     Object.keys(currentTransformerContent).forEach((item => {
        //         let sourceStatus = transformerProgress.find(element => {
        //             return element.crnt_transformer === item
        //         })?.state;

        //         if (sourceStatus === 'success') {

        //             setToggleDefaultView(false);

        //             setshowDatabricksLandingPage(true)

        //         } else {
        //             setToggleDefaultView(true);
        //         }
        //     }))
        // }

        if (currentTransformerContent?.additional_settings?.cluster_id) {
            let existedTransformerpayload = currentTransformerContent?.additional_settings

            if (existedTransformerpayload) {
                setAdditionalSettings({
                    ...additional_settings,
                    cluster_id: existedTransformerpayload?.cluster_id ?? '',
                    user_libs: existedTransformerpayload?.user_libs ?? ''
                })
            }
            // getUspdateCode()

        }

    }, [flowsElements])

    const createClusterConfig = (notebookDetails, selectedHost) => {
        if (notebookDetails) {
            setselectedNotebookDetails(notebookDetails)
        }

        store.dispatch(getDatabrickClusters({ databricks_host: selectedHost ?? hostValue1 })).then((resp) => {
            let data = resp.data.clusters;

            if (data?.length) {
                // setOutputDataset([{ value: "output1", id: `${currentTransformer?.id}|output1|0`, showError: false, showSuccess: false }], notebookDetails)
                let temp = data.map((ele, index) => ({ key: `${ele.cluster_id}-${index}`, Name: ele.cluster_name ? ele.cluster_name : 'Not Defined', Type: "", value: ele.cluster_id, subTitle: ele.cluster_id, state: ele.state }))
                if (temp?.length) {
                    setAdditionalSettings({ ...additional_settings, cluster_id: temp[0].value, cpu_request: "", mem_request: "", user_libs: '' })

                    setconfiguration([...temp])
                }

                if (hostValue1 && selectedBricksConnection1) {
                    let flowsElements = store.getState().FlowsReducer.flowsElements;
                    let finalFlowsElements = flowsElements.map(item => {
                        if (item.id === currentTransformer.id) {
                            item.status = "success"
                            item.transformerName = transformerName
                            item.content['additional_settings'] = { ...additional_settings, cluster_id: temp[0].value, cpu_request: "", mem_request: "", user_libs: '' }
                            item.content['selectedBricksConnection1'] = selectedBricksConnection1
                            item.content['hostValue1'] = hostValue1
                            item.content['selectedBricksNotebook'] = notebookDetails

                        }
                        return item
                    })
                    store.dispatch(updateFlowsElements([...finalFlowsElements]))

                }
            }
        }).catch((err) => {
            if (err?.status === 403) {

                fetchConnectionList();
                setshowModal(true)
            }

        })
    }

    const fetchConnectionList = () => {

        store.dispatch(getConnectionListV2()).then((res) => {
            if (res?.data?.length) {

                let bricksExistList = [];
                res?.data.forEach((ele, index) => {
                    if (ele.source_type?.toLowerCase() === 'databricks') {
                        return bricksExistList.push({ key: `${ele.connection_name}-${index}`, Name: ele.connection_name ? ele.connection_name : 'Not Defined', Type: "", value: ele.connection_name, subTitle: '', state: '', fullObj: ele })
                    }
                })
                if (bricksExistList.length) {
                    setselectedBricksConnection1(bricksExistList[0].value);
                    sethostValue1(bricksExistList[0]?.fullObj?.masked_properties?.host)
                    setbricksList([...bricksExistList]);

                } else {

                    setbricksList([])

                }
            }
        })
    }

    useEffect(() => {


        store.dispatch(resetScriptRedux())
        setpythonCode('')
        fetchConnectionList()

        let currentTransformerContent = flowsElements.find(item => item.id === currentTransformer.id)?.input_source
        let currentScriptContent = flowsElements.find(item => item.id === currentTransformer.id)?.content

        let temp = store.getState().FlowsReducer.executedTransformerPayload;


        if (temp?.length) {
            let existedTransformerpayload = temp.find((ele) => ele?.currentTransformerID === currentTransformer.id);


            if (existedTransformerpayload && existedTransformerpayload?.Args?.length > 3) {
                setAdditionalSettings({
                    ...additional_settings,
                    mem_request: '',
                    configuration: '',
                    cpu_request: '',
                    extra_resource_limits: '',
                    user_libs: existedTransformerpayload.Args[existedTransformerpayload?.Args?.length - 3]?.name === 'user_libs' ? existedTransformerpayload.Args[existedTransformerpayload?.Args?.length - 3]?.Value : '',
                    cluster_id: existedTransformerpayload.Args[existedTransformerpayload?.Args?.length - 1].name === 'cluster_id' ? existedTransformerpayload.Args[existedTransformerpayload?.Args?.length - 1]?.Value : additional_settings.cluster_id,

                })
            }
        }

        // createClusterConfig()

        if (Object.keys(transformerNameList).length && currentTransformer?.id && transformerNameList[currentTransformer?.id]) {
            setshowRedBtn(false)

            settransformerName(transformerNameList[currentTransformer.id])
        }

        if (currentTransformerContent && Object.keys(currentTransformerContent).length !== 0) {
            let transformerProgress = store.getState().FlowsReducer.transformerProgress

            Object.keys(currentTransformerContent).forEach((item => {
                let sourceStatus = transformerProgress.find(element => {
                    return element.crnt_transformer === item
                })?.state;

                if (sourceStatus === 'success') {

                    setToggleDefaultView(false);

                    setshowDatabricksLandingPage(true)

                } else {
                    setToggleDefaultView(true);
                }
            }))
            getUpdateCode();


            if (currentScriptContent && Object.keys(currentScriptContent)?.length !== 0) {
                setshowApplyButton(false)
                if (currentScriptContent[`outputdatasetList`] && currentScriptContent[`outputdatasetList`].length) {
                    store.dispatch(setBricksOutputDataset(currentScriptContent[`outputdatasetList`]))
                    setshowApplyButton(true)


                }
                if (currentScriptContent[`pythonCode`] !== "" && currentScriptContent[`selectedBricksNotebook`]) {
                    setshowDatabricksLandingPage(false)
                    setshowApplyButton(true)
                    // setpythonCode(currentScriptContent[`pythonCode`])
                    setselectedNotebookDetails(currentScriptContent[`selectedBricksNotebook`])
                    store.dispatch(setScriptPythonCode({ code: currentScriptContent[`pythonCode`], id: currentTransformer.id }))
                    getUpdateCode();
                }
                if (currentScriptContent[`hostValue1`] !== "" && currentScriptContent[`selectedBricksConnection1`] !== "") {
                    setselectedBricksConnection1(currentScriptContent[`selectedBricksConnection1`])
                    sethostValue1(currentScriptContent[`hostValue1`])

                    createClusterConfig(currentScriptContent[`selectedBricksNotebook`], currentScriptContent[`hostValue1`])

                    setshowDatabricksLandingPage(false)

                }

            } else {
                setshowApplyButton(false)

                store.dispatch(resetScriptRedux())
            }


        }


    }, [currentTransformer])

    const transformerStatusChange = useSelector(state => state.FlowsReducer.transformerStatusChange)
    const saveConnectionDetails = () => {

        // createClusterConfig();

        if (personalToken !== '') {
            store.dispatch(ConnectionDataBricksSave({

                "databricks_host": hostValue,

                "databricks_pat": personalToken,
                "datarbricks_org_id": orgId

            })).then((res) => {

                setshowloadExisting(true)

                setcallListWorkspaceApi(true)
                setshowModal(false);
            }).catch((e) => {
                emitToastNotification("error", e.message)

            })
        } else {
            emitToastNotification("warning", "Please Enter Personal Access Token To Create Databricks Connection")
        }

    }
    useEffect(() => {
        if (transformerStatusChange?.status) {

            let inputSource = flowsElements.find(item => item.id === currentTransformer.id)?.input_source
            let transformerProgress = store.getState().FlowsReducer.transformerProgress

            // let temp = store.getState().FlowsReducer.executedTransformerPayload;

            // if (temp?.length) {
            //     let existedTransformerpayload = temp.find((ele) => ele?.currentTransformerID === currentTransformer.id);


            //     if (existedTransformerpayload ) {
            //         setAdditionalSettings({
            //             cpu_request: existedTransformerpayload.Args[4].Value,
            //             mem_request: existedTransformerpayload.Args[5].Value,
            //             extra_resource_limits: existedTransformerpayload.Args[6].Value,


            //         })
            //     }
            // }
            let currentScriptContent = flowsElements.find(item => item.id === currentTransformer.id)?.content


            if (inputSource && Object.keys(inputSource).length) {



                inputSource && Object.keys(inputSource).forEach((element) => {
                    let sourceStatus = transformerProgress.find(ele => {
                        return ele.crnt_transformer === element
                    })?.state;

                    if (inputSource && inputSource[element].length > 0 && sourceStatus?.toLowerCase() && sourceStatus?.toLowerCase() === 'success') {

                        setToggleDefaultView(false);

                        if (currentScriptContent[`pythonCode`] === "" && currentScriptContent[`outputdatasetList`] && currentScriptContent[`outputdatasetList`].length) {
                            setshowDatabricksLandingPage(true)

                        }

                    }

                })


            }

        }
    }, [transformerStatusChange])


    const closeLeftSideContent = () => {
        store.dispatch(setFlowsCurrenttransformer({}))
        store.dispatch(showFlowsTransformdetails(false))
    }

    return (
        <>
            <div className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
                <div className="d-flex align-items-center">
                    {/* <img src={SCRIPT} alt={`filter`} /> */}
                    <DATABRICKSICON />
                    <h3 className="subtitle">Databricks</h3>


                    {!showDatabricksLandingPage ? <Tabs onChange={onChange} activeKey={activeKey} className="custom-tab tab-with-icon">
                        <TabPane tab={<SCRIPTEDITOR />} key="scriptEditor">
                        </TabPane>
                        <TabPane tab={<img src={sticky} alt={`sticky`} />} key="sticky">
                        </TabPane>

                    </Tabs> : null}
                </div>
                <div className="d-flex align-items-center">
                    <DatasetOutputNameInput transformerName={transformerName} setTransformerName={settransformerName} />

                    {!showDatabricksLandingPage ? <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { activeKey !== 'sticky' || (activeKey === 'sticky' && !editMode) ? closeLeftSideContent() : setEditMode(false); onChange('properties') }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} /> : null}

                    {activeKey === 'sticky' ? <Buttons props={{ buttonText: !editMode ? 'Edit' : 'Save', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: !editMode ? () => { setEditMode(true); store.dispatch(saveTransformerNoteClick(false)) } : () => { store.dispatch(saveTransformerNoteClick(true)) }, ImgSrc: !editMode ? () => <EDIT /> : () => <SAVE />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}

                    {/* <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2", buttonEvent: () => { }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} /> */}
                    {activeKey !== 'sticky' && !showDatabricksLandingPage ? <Buttons props={{ buttonText: 'Execute', buttonClassName:`${showRedBtn ? 'execute-btn-gradient' : ''} custom-btn-primary custom-btn btn-with-text`, showLoading: showBtnLoading, buttonEvent: activeKey === 'sticky' ? () => { setEditMode(true) } : () => { executeTransformer() }, ImgSrc: activeKey === 'editor' ? () => <EDIT /> : () => <PLAY />, isDisable: !isExecutePermission || transformerName === '' || disableExcute ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} /> : null}

                </div>
            </div>
            {/* {!showDatabricksLandingPage ? <> */}
            {activeKey === 'scriptEditor' && !toggleDefaultView && !showDatabricksLandingPage ?
                <div className="d-flex bg-white  section-with-drag flow-filter-height h-100">
                    <div className={expandTable ? "full-screen-active scriptEditor" : "w-60 custom-border-right scriptEditor"}>
                        <div className="d-flex justify-content-between custom-border-bottom padding-2">
                            <div className="d-flex align-items-center">
                                {showloadExisting ? <FOLDER height='16' width='16' color='#2c83c4' /> : <SCRIPTEDITOR />}
                                {showloadExisting ? <p className="subtitle mb-0 ml-2">Databricks Notebook Explorer</p> : <div className='d-flex align-items-center'><p className="subtitle mb-0 ml-2">CodeEditor</p> <Buttons props={{ buttonText: '', tooltip: 'Get updated code', buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1", buttonEvent: () => { getUpdateCode() }, toggleBtnValue: '', ImgSrc: () => <REFRESH />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_TERTIARY, toggleBtnOption: null }} /></div>}
                            </div>
                            <div className="d-flex justify-content-end ">
                                {expandTable ?
                                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { setexpandTable(false) }, ImgSrc: () => <EXITFULLSCREEN />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                    :
                                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { setexpandTable(true) }, ImgSrc: () => <FULLSCREEN />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />
                                }
                                {pythonCode !== '' && <div className='d-flex align-items-center'><Buttons props={{ buttonText: '', tooltip: "Show Notebook Explorer", buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2", buttonEvent: () => { setshowloadExisting(true) }, toggleBtnValue: '', ImgSrc: () => <Folder height={16} width={16} color='#2C83C4' />, isDisable: !isJupyterPermission || !isEditPermission || window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT, toggleBtnOption: null }} />
                                    <Buttons props={{ buttonText: '', tooltip: "", buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2", buttonEvent: () => { openJupyterNotebook() }, toggleBtnValue: '', ImgSrc: () => <REDIRECTJOBS />, isDisable: !isJupyterPermission || !isEditPermission || window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT, toggleBtnOption: null }} />
                                </div>}
                            </div>
                        </div>
                        {/* <div className=''> */}
                        {showloadExisting ? <LoadExistingBricksNotebook createClusterConfig={createClusterConfig} callListWorkspaceApi={callListWorkspaceApi} hostValue1={hostValue1} setshowDatabricksLandingPage={setshowDatabricksLandingPage} /> :
                            <SqlEditor readOnly={true} height={"550px"} QueryValue={pythonCode} setQueryvalue={() => { }} language={'python'} />}

                        {/* </div> */}
                    </div>
                    <div className="w-40 h-100">

                        <DatabricksOutputDataSet configuration={configuration} isEditPermission={isEditPermission} additional_settings={additional_settings} setAdditionalSettings={setAdditionalSettings} showApplyButton={showApplyButton} setOutputDataset={setOutputDataset} createClusterConfig={createClusterConfig} applyNewOuputDataSet={applyNewOuputDataSet} setdisableExcute={setdisableExcute} />
                    </div>


                </div>

                : activeKey === 'scriptEditor' && toggleDefaultView && !showDatabricksLandingPage ? <div className="no-datasource">
                    <SCRIPTDEFAULTVIEW />
                </div> : activeKey === 'scriptEditor' && !toggleDefaultView && showDatabricksLandingPage ? <DatabricksLandingPage createClusterConfig={createClusterConfig} setshowloadExisting={setshowloadExisting} bricksList={bricksList} sethostValue={sethostValue1} hostValue={hostValue1} selectedBricksConnection={selectedBricksConnection1} setselectedBricksConnection={setselectedBricksConnection1} setshowDatabricksLandingPage={setshowDatabricksLandingPage} /> : null
            }
            {/* </> : null} */}

            {/* {
                showloadExisting ? <LoadExistingBricksNotebook createClusterConfig={createClusterConfig} setshowloadExisting={setshowloadExisting} setshowDatabricksLandingPage={setshowDatabricksLandingPage} /> : null

            } */}

            {
                activeKey === 'sticky' && <EditorIndex editMode={editMode} setEditMode={setEditMode} />
            }

            <Modal
                isOpen={showModal}
                centered
                // backdrop="static"
                size={'sm'}
            >
                <ModalHeader className="modalHeaderConnection">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <h2 className={`pro-common-modal-heading`} id="exampleModalLabel">
                            <DATABRICKS_ICON height='15' width='15'></DATABRICKS_ICON>  Connection Details
                        </h2>
                        <Buttons
                            props={{
                                tooltip: 'Close',
                                buttonText: '',
                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => { setshowModal(false) },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY
                            }}
                        />
                    </div>

                </ModalHeader>
                <ModalBody>
                    <div className='' >
                        <label className='label'>Your personal access token to Databricks is not configured or might have expired, please enter the following details.</label>
                        <div className="form-group">
                            <SingleSelection props={{
                                label: "Databricks Connection", className: " custom-select-dropdown", placeholder: "Select a connection", dropDownValue: selectedBricksConnection, onClickEvent: (value) => {
                                    setselectedBricksConnection(value);
                                    let host = bricksList.find((item) => item.Name?.toLowerCase() === value?.toLowerCase())
                                    console.log(value, bricksList, host)
                                    if (host.fullObj?.masked_properties?.host !== '') {

                                        sethostValue(host.fullObj?.masked_properties?.host)
                                        setorgId(host.fullObj?.masked_properties?.organization_id)
                                    }

                                }, isDisable: window.location.href.includes('viewFlows') ? true : false, optionList: bricksList, isSortOptionDisable: true
                            }} />
                        </div>
                        <label className='label mt-1'> HOST </label>
                        <input className='custom-input-field ' type="text" disabled="true" value={hostValue}></input>

                        <label className='label mt-2'> Personal Access Token </label>
                        <input className='custom-input-field ' type="password" value={personalToken} onChange={(e) => setpersonalToken(e.target.value)}></input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Buttons
                        props={{
                            tooltip: 'Save',
                            buttonText: 'Save',
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: () => saveConnectionDetails(),
                            ImgSrc: () => <SAVE />,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }}
                    />
                </ModalFooter>
            </Modal>


        </>
    )
}

const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer,
        flowId: state.FlowsReducer.id,


        transformerNameList: state.FlowsReducer.transformerName

    };
};
export default connect(mapStateToProps)(DatabricksIndex);
