import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Countdown
import Countdown from "react-countdown";

//Import Images
import logoImage from "../../assets/images/new-logo.png"

import maintanence from "../../assets/images/maintenance.png";

class PagesComingsoon extends Component {
    constructor() {
        super();
        this.renderer.bind(this)
    }

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>You are good to go!</span>;
        } else {
            // Render a countdown
            return <>
            <div className="coming-box">{days} <span>Days</span></div> <div className="coming-box">{hours} <span>Hours</span></div> <div className="coming-box">{minutes} <span>Minutes</span></div> <div className="coming-box">{seconds} <span>Seconds</span></div>
            </>
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-white"><i className="fas fa-home h2"></i></Link>
                </div>

                <div className={this.props.className?? "my-5 pt-sm-5 cmg-soon-content-wrap"}>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="text-center">
                                    <Link to="/">
                                    <img src={logoImage} alt="Clarista" height='24px'/>
                                    </Link>
                                    <Row className="justify-content-center ">
                                        <Col sm="4">
                                            <div className="maintenance-img">
                                                <img src={maintanence} alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <h4 className="mt-5">Let's get started with Clarista</h4>
                                    <p className="text-muted">It will be as simple as Occidental in fact it will be Occidental.</p>

                                    <Row className="justify-content-center mt-5">
                                        <Col md="8">
                                            <div className="counter-number">
                                                <Countdown
                                                    date="2020/12/31"
                                                    renderer={this.renderer}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default PagesComingsoon;