import { Tooltip } from 'antd';
import React from 'react';
import { Handle } from 'reactflow';

import { connect, useDispatch } from 'react-redux';
import { CHECKMARKCIRCLE, DISMISSCIRCLE, ERRORCIRCLE, LOADINGCIRCLE, LOADINGCIRCLEQUEUE, SCRIPTOUTPUT, STOPOCTAGON } from '../../../../Common/iconSource';
import { getTransformerName, isTransformerNamePresent } from '../../transformers/FlowsHelperFunction';

const ScriptOutputTransformer = (props) => {

    let { currentTransformer, executeEntireFlow, transformerProgress, id, flowsElements } = props
const dispatch=useDispatch()
    const getStatus = () => {

        if (transformerProgress && transformerProgress.length) {
            let currentStatus = ''




            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;

            if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'starting' || currentStatus?.toLowerCase() === 'running')) {
                return <span className='bx-spin d-flex'><LOADINGCIRCLE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'success' || currentStatus?.toLowerCase() === 'done' || currentStatus?.toLowerCase() === 'finished')) {
                return <CHECKMARKCIRCLE />
            }
            else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error')) {
                return <DISMISSCIRCLE />
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'queued')) {
                return <span className='bx-spin d-flex'><LOADINGCIRCLEQUEUE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'aborted')) {
                return <span className='d-flex'> <STOPOCTAGON /></span>
            }
            else {
                return <ERRORCIRCLE />
            }

        }
        else if (executeEntireFlow && !transformerProgress.length) {
            return <ERRORCIRCLE />

        } else if (!transformerProgress.length && !executeEntireFlow) {
            return <ERRORCIRCLE />
        }




    }
    // const getTransformerName = (valueFor = '') => {


    //     let transformerName = flowsElements.find((ele) => ele.id === id)?.transformerName;
    //     // if (transformerName && transformerName?.length > 10 && valueFor === '') {
    //     //     return `${transformerName?.slice(0, 6)}...`
    //     // } else {
    //         return transformerName ? transformerName : ''
    //     // }




    // }
    // const getErrorMsg = () => {
    //     let error_msg = transformerProgress.find(element => {
    //         return element.crnt_transformer === id
    //     })?.error_msg;
    //     let currentStatus = 'INITIAL'
    //     if (transformerProgress && transformerProgress.length) {
    //         currentStatus = transformerProgress.find(element => {
    //             return element.crnt_transformer === id
    //         })?.state;
    //     }

    //     return (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error' || currentStatus?.toLowerCase() === 'aborted') ? error_msg : null

    // }
    const getErrorMsg = () => {
        let error_msg = transformerProgress.find(element => {
            return element.crnt_transformer === id
        })?.error_msg;
        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        return (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error' || currentStatus?.toLowerCase() === 'aborted') ? error_msg : null

    }
    const getInitialStatus = () => {

        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        return currentStatus ? currentStatus?.toLowerCase() : 'initial'
    }
    return (
        <>
            <div className={`transformer-box merge-box ${currentTransformer.id === id ? `transformer-active` : ``}`}>
                <Handle type="source" position="right" />
                <div>
                    <Tooltip title={id?.split('|')[1]}>
                        <div className='transformer-icon'>
                            <SCRIPTOUTPUT color='#2C83C4' />
                        </div>
                    </Tooltip>
                    {/* {!window.location.href.includes('viewFlows') ? <div className="transformer-bottom-img-delete cursor-pointer" onClick={(e) => { e.stopPropagation(); dispatch(deleteTransformer(id)) }} >
                        <DELETETRANSFORMER />
                    </div> : null} */}
                    {/* <div className="transformer-bottom-img" >
                        {flowsElements.find(item => item.id === id)?.status === 'success' ? <CHECKMARKCIRCLE /> : flowsElements.find(item => item.id === id)?.status === 'error' ? <DISMISSCIRCLE /> : transformerLoading ? <LOADINGCIRCLE /> : <ERRORCIRCLE />}
                    </div> */}
                    {getErrorMsg() ? <Tooltip title={getErrorMsg()}>  <div className="transformer-bottom-img" >
                        {getStatus()}
                    </div></Tooltip> : getInitialStatus() === 'initial' ? <Tooltip title={'Transformer not executed yet'}><div className="transformer-bottom-img" >
                        {getStatus()}
                    </div></Tooltip> : <div className="transformer-bottom-img" >
                        {getStatus()}
                    </div>}
                    <Tooltip title={dispatch(getTransformerName(id))}>
                    <div className={dispatch(isTransformerNamePresent(id)) ? "transformer-bottom-name" : "error-transformer-bottom-name"} >

                            {dispatch(getTransformerName(id))}

                        </div>
                    </Tooltip>
                </div>
                <Handle type="target" position="left" />
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer,
        currentExecutingTransformerId: state.FlowsReducer.currentExecutingTransformerId,
        executeEntireFlow: state.FlowsReducer.executeEntireFlowStart,
        transformerProgress: state.FlowsReducer.transformerProgress,
        transformerLoading: state.FlowsReducer.transformerLoading

    };
};

export default connect(mapStateToProps)(ScriptOutputTransformer)