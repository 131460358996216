import  { useState, useEffect } from 'react'
import { useSelector, useStore } from 'react-redux';

import { getPreviewTable } from '../../../store/modules/dataCatalogue/preview/previewActions';
import { emitToastNotification } from '../../../helpers/toast_helper';
import { setActivePodDetails } from '../../../store/modules/common/VirtualDataTable/action';

function useVirtualTableAction(getTableData, uuid, setparentError=()=>{}) {

    const actionType = useSelector(state => state.CommonComponent.VirtualDataTable.previewActionType)
    const searchKeyword = useSelector(state => state.CommonComponent.VirtualDataTable.searchKeyword)
    const sortColumnList = useSelector(state => state.CommonComponent.VirtualDataTable.sortColumnList)
    const filterColumnList = useSelector(state => state.CommonComponent.VirtualDataTable.filterColumnList)

    const [previewTableData, setpreviewTableData] = useState()

    const store = useStore();

    const applySort = () => {
        if (sortColumnList && sortColumnList.length) {
            setVirtualGridScrollToTop();
            let request = createRequestBody();
            getPreviewTableResult(request)
        }
    }

    useEffect(() => {
        applySort()
    }, [sortColumnList])

    useEffect(() => {
        applyFilter()
    }, [filterColumnList])

    const applySearch = () => {

        setVirtualGridScrollToTop();

        let requestBody = createRequestBody();
        if (actionType === 'search_query' && previewTableData?.data_header?.length) {

            getPreviewTableResult(requestBody, true);
        }


    }
    const applyFilter = () => {
        if (filterColumnList && filterColumnList.length) {
            let payload = createRequestBody();
            getPreviewTableResult(payload);
        }
    }
    useEffect(() => {

        if (searchKeyword !== '') {

            applySearch()
        }

    }, [searchKeyword])

    const mergeArray = (arrayElement) => {
        let merge = [];

        arrayElement.forEach((ele) => {

            merge = [...merge, ...ele[Object.keys(ele)[0]]];
        })

        return merge;
    }
   

    const createRequestBody = () => {
        let podName = store.getState().CommonComponent.VirtualDataTable?.activePodDetails?.podName
        let dataDomain = store.getState().CommonComponent.VirtualDataTable?.activePodDetails?.dataDomain
        let podId = store.getState().CommonComponent.VirtualDataTable?.activePodDetails?.podId
        let connectionName = store.getState().CommonComponent.VirtualDataTable?.activePodDetails?.connectionName
        let transformerType = store.getState().CommonComponent.VirtualDataTable?.activePodDetails?.transformerType
        if (podName !== '' && dataDomain !== '') {
            let filterColumnList;
            let filterArray = store.getState().CommonComponent.VirtualDataTable.filterColumnList;
            if (filterArray && filterArray.length) {
                filterColumnList = mergeArray(filterArray);
            }
            let sortColumnList = store.getState().CommonComponent.VirtualDataTable.sortColumnList;
           
            let request = {
                connection_name: connectionName && transformerType !== 'datasource'? connectionName : 'hive',
                table_ids: podId ? [podId.toString()] : [],
                uuid: uuid,
                table_name: podName,
                schema_name: transformerType && transformerType === 'datasource' ? '' : dataDomain,
                page: 0,
                do_count: false,
                sort_body: sortColumnList && sortColumnList.length ? sortColumnList : [],
                filter_body: filterColumnList && filterColumnList.length ? filterColumnList : [],

            }
            if (actionType === 'search_query') {
                request['keyword'] = store.getState().CommonComponent.VirtualDataTable.searchKeyword
                request['columns'] = previewTableData?.data_header;
            }
            if (actionType === 'execute_query') {
                request['query'] = store.getState().CommonComponent.VirtualDataTable.sqlEditorQuery;
            }

            if (transformerType && transformerType === 'datasource') {
                store.dispatch(
                    setActivePodDetails({
                        connectionName:'hive',
                        podName: podName,
                        dataDomain: dataDomain,
                        transformerType: transformerType,
                        podId: podId,
                        from:'flows'
                    })
                );
            }
            return request;

        }




    }
    useEffect(() => {

        if (getTableData === true) {
            let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')

            let request = createRequestBody();

            // alert(request.connection_name  )
            if (request?.connection_name?.toLowerCase() === 'application_cache' && !GlobalConnectionCache) {

                request['connection_name'] = ''
                emitToastNotification('error', `Global cache connection does not exist, please select one connection as a cache <br></br><a class="text-underline"
                              href="/connections"
                              target="_blank"> Go to connections </a>`, { timeOut: 0 })
                setpreviewTableData({})
                return;
            }
            // console.log(request)

            getPreviewTableResult(request)


            // } else {
            //     emitToastNotification('error', `Global cache connection does not exist, please select one connection as a cache <br></br><a class="text-underline"
            //               href="/connections"
            //               target="_blank"> Go to connections </a>`, { timeOut: 0 })

            //     return;
            // }
        }
    }, [getTableData])

    const setVirtualGridScrollToTop = () => {
        // let gridDiv = document.getElementsByClassName('virtual-data-table-grid');

        // if (gridDiv && gridDiv[0]) {
        //     gridDiv[0].scrollTop = 0;
        // }

    }

    const getPreviewTableResult = (payload) => {
       
        if (payload && (payload.table_name || payload?.table_ids )  && payload.uuid) {

            try {
                store.dispatch(getPreviewTable(payload, false))
                    .then((response) => {
                        if (response?.status === 'success' && response?.data?.result) {
                            let responseData = response.data.result;
                            setpreviewTableData(responseData)
                            setparentError(undefined)

                        } else {
                            setpreviewTableData({})
                            setparentError(undefined)


                        }
                    }).catch((e) => {
                        setpreviewTableData({})
                        setparentError(e?.message)
                    })

            } catch (error) {
                setpreviewTableData({})

                setparentError(error?.message)

            }
        }

    }
    // const refreshPage = () => {
    //     resetFilter(true);
    //     setshowLoader(true)
    //     let payload = createRequestBody();
    //     resetChunkCount();
    //     getPreviewTableResult(payload, false);
    // }


    return {
        
        previewTableData
    }
}

export default useVirtualTableAction
