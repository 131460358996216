import { Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { setSelectedAlertDetails } from '../../../../store/modules/alert/createAlert/createAlertAction';
import { SET_FLOWS_ALERT_TRANSFORMER_LIST } from '../../../../store/modules/alert/createAlert/createAlertActiontype';
import { SetSchedulerPayload } from '../../../../store/modules/scheduler/action';
import Buttons from '../../../Common/button/Buttons';
import { DndVirtualList } from '../../../Common/dndVirtualList/DndVirtualList';
import { ALERTBELLLARGE, DELETEICON, FLOWTABICON, PLUS, SIXDOTS, STEPS, RELOAD } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';

export const SchedulerStepsList = ({ 
    setaddStep, 
    seteditStepObj, 
    addStep, 
    stepObject, 
    setstepObject, 
    stepList, 
    setstepList, 
    data, 
    setdata,
    isEditor = false
}) => {
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)
    const store = useStore()
    const [isDeleteDisabled, setisDeleteDisabled] = useState(true)
    const setNewOrder = (newArray) => {

        newArray.forEach((item, index) => {
            item.order = index + 1
        });

        let newPayload = cloneDeep(schedulerPayload)
        newPayload['step'] = newArray
        store.dispatch(SetSchedulerPayload(newPayload))
    }



    const deleteSteps = () => {

        let temp = [...stepList];


        if (stepList && stepList.length) {
            temp = stepList.filter((item) => !item.checked)



            if (temp?.length) {

                setstepList([...temp])
            } else {
                setstepList([])
            }
            setaddStep(false)
        }


    }

    useEffect(() => {
        setisDeleteDisabled(isStepSelect())
    }, [stepList])


    const isStepSelect = () => {
        
        if (stepList && stepList.length) {
            return !stepList.some(item => item?.checked)
        }
        else return true
    }
    return <>
        <Card className='border-0'>
            <CardHeader className='padding-2 bg-white'>
                <div className='d-flex align-items-center w-100 justify-content-between'>
                    <label className='d-flex mb-0 font-weight-bold align-items-center'>
                        <STEPS />
                        <div className='p-1'></div>
                        Steps
                    </label>
                    <div className='d-flex h-100 align-items-center justify-content-center'>
                        <div className='col-4 pl-0'>
                            <Buttons
                                props={{
                                    buttonText: "",
                                    tooltip: "Delete Step",
                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red mr-2`,
                                    buttonEvent: () => {
                                        deleteSteps()
                                    },
                                    ImgSrc: () => <DELETEICON />,
                                    isDisable: !isEditor || window.location.href.includes('scheduler/view') ||isDeleteDisabled,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                            {/* <Tooltip placement={"bottom"} title="Delete Step">
                                <button
                                    className={
                                        window.location.href.includes('scheduler/view') 
                                        ? " custom-btn  mr-2" 
                                        : "custom-btn-outline custom-btn btn-with-icon error-red red-border mr-2"
                                    }
                                    onClick={() => {
                                        deleteSteps()
                                    }}
                                    disabled={window.location.href.includes('scheduler/view')}
                                >
                                    <DELETEICON />

                                </button>
                            </Tooltip> */}
                        </div>
                        {!addStep ? <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName: "custom-btn-primary custom-btn fixed-button",
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setstepObject({
                                        "object_id": null,
                                        "content_type": "rule",
                                        "if_previous_fail": "SKIP",
                                        "order": 0,
                                        "offset": 30,
                                        "description": '',
                                        'object_name': ''
                                    })
                                    seteditStepObj({})
                                    store.dispatch(setSelectedAlertDetails({}))
                                    store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} })
                                    setaddStep(true)
                                },
                                tooltip: addStep ? "Reset" : "Add Step",
                                ImgSrc: () => addStep ? <RELOAD /> : <PLUS />,
                                isDisable: window.location.href.includes('scheduler/view'),
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        /> : null}
                    </div>
                </div>
            </CardHeader>
            <CardBody className='padding-2'>
                <div className='sch-steps-dnd-list'>
                    <DndVirtualList
                        data={data}
                        rawData={stepList}
                        setData={setNewOrder}
                        stopDraggingRow={window.location.href.includes('scheduler/view')}
                    />
                </div>
            </CardBody>
        </Card>
    </>
}
