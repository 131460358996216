import {
  RESET_SCRIPT_REDUX,
  SET_BRICKS_OUTPUT_DATASET,
  SET_DATABRICKS_NOTEBOOK_URL,
  SET_JUPYTER_NOTEBOOK_URL,
  SET_OUTPUT_DATASET,
  SET_SAVE_NOTEBOOK_DONE,
  SET_SCRIPT_COMMON_PAYLOAD,
  SET_SCRIPT_PYTHON_CODE,
} from "./scriptActionTypes";

const initialState = {
  jupyterNotebookUrl: "",
  scriptCommonPayload: {},
  scriptPythonCode: "",
  scriptCurrentId: '',
  scriptPath: '',
  scriptOutputDataset: [],
  bricksOutputDataset: [],
  saveNoteBookDone: false,
  DatabricksNotebookURL: ""
};

const ScriptReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_DATABRICKS_NOTEBOOK_URL:
      console.log(state.DatabricksNotebookURL, payload)
      return {
        ...state,
        DatabricksNotebookURL: payload,
      };
    case SET_JUPYTER_NOTEBOOK_URL:
      return {
        ...state,
        jupyterNotebookUrl: payload,
      };
    case SET_SAVE_NOTEBOOK_DONE:
      return {
        ...state,
        saveNoteBookDone: payload,
      };
    case SET_OUTPUT_DATASET:
      return {
        ...state,
        scriptOutputDataset: payload,
      };
    case SET_BRICKS_OUTPUT_DATASET:
      return {
        ...state,
       bricksOutputDataset: payload,
      };
    case SET_SCRIPT_COMMON_PAYLOAD:
      return {
        ...state,
        scriptCommonPayload: payload,
      };
    case SET_SCRIPT_PYTHON_CODE:
      return {
        ...state,
        scriptCurrentId: payload.id,
        scriptPythonCode: payload.code,
        scriptPath: payload.path
      };
    case RESET_SCRIPT_REDUX:
      initialState["jupyterNotebookUrl"] = "";
      initialState["scriptCommonPayload"] = {};
      initialState["scriptCurrentId"] = "";
      initialState["scriptPythonCode"] = "";
      initialState["scriptOutputDataset"] = [];
      initialState['saveNoteBookDone'] = false
      initialState['bricksOutputDataset']=[]

      return initialState;
    default:
      return state;
  }
};

export default ScriptReducer;
