import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Buttons from "../../../../Common/button/Buttons";
import ActionPopUp from "../../../common/actionPopUp";
import SqlEditor from "./SqlEditor";

import { setLoadingStatus } from "../../../../../store/modules/common/loadingActions";
import {
  SaveQueryAPI,
  saveSQlTrigger,
  setPreviewTableQuery,
  sqlSaveTrigger,
  UpdateQueryAPI,
} from "../../../../../store/modules/dataCatalogue/preview/previewActions";
import {
  CLOSE,
  DOWNLOAD,
  PLAY,
  EDIT,
  SAVE,
} from "../../../../Common/iconSource";
import { TextFile } from "../../../common/helperFunctions";
import { Literals } from "../../../common/literals";

import { emitToastNotification } from "../../../../../helpers/toast_helper";

const EditorContainer = forwardRef(
  (
    {
      sqlTableDetails,
      showLoader,
      selectedQuery = {},
      setQueryvalue,
      Query,
      tableId,
      isEditingQuery,
      setIsQueryViewMode,
      setIsEditingQuery,
      getSavedQueries,
      closeEditor,
      ExecuteQueryClick,
      isQueryViewMode,
      previewQuery,
      height,
      debounceTime,
      setIsEditorVisible = () => { },
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [saveModalInput, setSaveModalInput] = useState(["", ""]);

    const [saveQueryState, setsaveQueryState] = useState("");
    const createPayloadHandler = () => {
      return {
        table: tableId,
        query_name: saveModalInput[0] ? saveModalInput[0] : "",
        query_string: selectedQuery.queryString,
        query_description: saveModalInput[1] ? saveModalInput[1] : "",
      };
    };

    useEffect(() => {
      if (
        saveModalInput[0].trim().length < 1
        // || saveModalInput[1].trim().length < 1
      ) {
        setIsSaveDisabled(true);
      } else {
        setIsSaveDisabled(false);
      }
    }, [isSaveModalOpen, saveModalInput]);

    const saveQueryHandler = () => {
      dispatch(setLoadingStatus(true));

      selectedQuery["queryString"] = Query;
      if (
        saveModalInput.length === 2 &&
        saveModalInput[0].trim() !== ""
        // && saveModalInput[1].trim() !== ""
      ) {
        const payload = createPayloadHandler();

        if (isEditingQuery) {
          dispatch(UpdateQueryAPI(payload, selectedQuery?.id))
            .then((res) => {
              if (res.status === "success") {
                getSavedQueries();
              }
              dispatch(setLoadingStatus(false));
              setQueryvalue("");
              dispatch(setPreviewTableQuery(""));
              closeSaveModalHandler();
              closeEditor();
            })
            .catch(() => {
              dispatch(setLoadingStatus(false))
            });
        }
        else {
          dispatch(SaveQueryAPI(payload, tableId))
            .then((res) => {
              if (res.status === "success" && !previewQuery) {
                getSavedQueries();
              }
              dispatch(setLoadingStatus(false));
              setQueryvalue("");
              dispatch(setPreviewTableQuery(""));
              closeSaveModalHandler();
              closeEditor();
            })
            .catch(() => {
              dispatch(setLoadingStatus(false))
            });
        }
      } else {
        dispatch(setLoadingStatus(false));
        emitToastNotification("info", `Provide report name and description`);
        // emitToastNotification('info', `Provide query name and description`)
      }
    };

    const closeSaveModalHandler = () => {
      setIsSaveModalOpen(false);
    };

    const openSaveModalHandler = (query) => {
      
      if (Query || query) {
        setQueryvalue(query)
        setIsSaveModalOpen(true);
      }
    };

    const downloadQueryHandler = () => {
      if (Query) {
        TextFile(Query);
      }
    };

    useEffect(() => {
      if (selectedQuery?.name) {
        setSaveModalInput([selectedQuery?.name, selectedQuery?.description]);
      }
    }, [selectedQuery]);

    const saveModalContent = {
      heading: "Save Report",
      message: "",
      headingIcon: "bx bx-save",
      button: [
        {
          name: "save",
          action: saveQueryHandler,
          isDisabled: isSaveDisabled,
        },
      ],
      elements: [
        {
          label: "Report Name",
          type: "input",
          currentValue: isEditingQuery ? selectedQuery.name : "",
          placeholder: "Enter Report Name",
        },
        {
          label: "Report Description",
          type: "textarea",
          currentValue: isEditingQuery ? selectedQuery.description : "",
          placeholder: "Enter Report Description",
        },
      ],
      closeActionPopup: closeSaveModalHandler,
    };

    return (
      <div style={{ height: height }}>
        <div
          className={`editor-options-header ${isQueryViewMode ? "disabled" : ""
            } custom-border-bottom padding-2`}
          ref={ref}
        >
          <div className="editor-options-title">
            <span className="subtitle">{Literals.SQL_EDITORS}</span>
          </div>
          <div className="d-flex align-items-center">
            {isQueryViewMode ? (
              <Buttons
                props={{
                  tooltip: "Edit",
                  buttonText: "",
                  buttonClassName:
                    "custom-btn-outline custom-btn btn-with-icon mr-2",
                  buttonEvent: () => {
                    setIsQueryViewMode(false);
                  },
                  ImgSrc: () => <EDIT />,
                  isDisable: false,
                  buttonType: Literals.BTN_TERTIARY,
                }}
              />
            ) : (
              <>
                <Buttons
                  props={{
                    tooltip: "Close",
                    buttonText: "",
                    buttonClassName:
                      "custom-btn-outline custom-btn btn-with-icon mr-2",
                    buttonEvent: () => {
                      dispatch(saveSQlTrigger({ trigger: "close_click" }));

                      if (isEditingQuery) {
                        setIsEditingQuery(false);
                        dispatch(setPreviewTableQuery(""));
                      }
                      if (setIsQueryViewMode) setIsQueryViewMode(true);
                      closeEditor();
                    },
                    ImgSrc: () => <CLOSE />,
                    isDisable: false,
                    buttonType: Literals.BTN_TERTIARY,
                  }}
                />
                <Buttons
                  props={{
                    tooltip: "Save",
                    buttonText: "",
                    buttonClassName:
                      "custom-btn-outline custom-btn btn-with-icon mr-2",
                    buttonEvent: () => {
                      // openSaveModalHandler();
                      dispatch(saveSQlTrigger({ trigger: 'save_click' }))

                    },
                    ImgSrc: () => <SAVE />,
                    isDisable: false,
                    buttonType: Literals.BTN_TERTIARY,
                  }}
                />
                <Buttons
                  props={{
                    tooltip: "Download",
                    buttonText: "",
                    buttonClassName:
                      "custom-btn-outline custom-btn btn-with-icon mr-2",
                    buttonEvent: () => {
                      // downloadQueryHandler();
                      dispatch(saveSQlTrigger({ trigger: 'download_click' }))

                    },
                    ImgSrc: () => <DOWNLOAD />,
                    isDisable: false,
                    buttonType: Literals.BTN_TERTIARY,
                  }}
                />
                <Buttons
                  props={{
                    tooltip: "Run",
                    buttonText: "RUN",
                    buttonClassName:
                      "custom-btn-primary custom-btn btn-with-text",
                    buttonEvent: () => {
                      // ExecuteQueryClick();

                      setIsEditorVisible(true);
                      dispatch(saveSQlTrigger({ trigger: 'run_click' }))

                    },
                    ImgSrc: () =>
                      showLoader ? (
                        <i className="bx bx-loader bx-spin fa fa-spinner mr-1"></i>
                      ) : (
                        <PLAY />
                      ),
                    isDisable: showLoader ? true : false,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  }}
                />
              </>
            )}
          </div>
        </div>
        <SqlEditor
          saveQuerytoState={saveQueryState}
          ExecuteQueryClick={ExecuteQueryClick}
          sqlTableDetails={sqlTableDetails}
          downloadQueryHandler={downloadQueryHandler}
          openSaveModalHandler={openSaveModalHandler}
          isQueryViewMode={isQueryViewMode}
          setQueryvalue={setQueryvalue}
          QueryValue={Query}
          readOnly={false}
          previewQuery={previewQuery}
          height={height}
          debounceTime={debounceTime}
        />
        <ActionPopUp
          openModal={isSaveModalOpen}
          actionData={saveModalContent}
          inputValueState={saveModalInput}
          setinputValueState={setSaveModalInput}
          modalSize="lg"
        />
      </div>
    );
  }
);

export default EditorContainer;
