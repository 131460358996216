import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "../../../connections/landingPage/components/ConnectionModal.scss";

import { BACKARROW, TICK } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import Buttons from "../../../../Common/button/Buttons";

import { getConnectionListCreatePODAction } from "../../../../../store/modules/dataCatalogue/createPOD/createPODActions";
import {
  getDataDomainList,
  setDataDomainList,
} from "../../../../../store/modules/UserManagement/dataDomains/dataDomainActions";
import { setConnectionDataList } from "../../../../../store/modules/UserManagement/connection/connectionActions";

import ConnectionList from "../../../connections/landingPage/components/ConnectionsList";
import ConnectionContext from "../../../connections/landingPage/components/ConnectionContext";
import ConnectionsHeader from "../../../connections/landingPage/components/ConnectionsHeader";
import TargetConnectionModalStep2 from "./TargetConnectionModalStep2";

import { setActiveTabKey } from "../../../../../store/modules/common/DataGridTable/dataGridTableRedux";
import { getFormattedDataConnectionFilter } from "../../../common/helperFunctions";

const TargetConnectionModal = ({ isModalOpen, onClose }) => {
  const dispatch = useDispatch();

  const { setFromConnectionRoute } = useContext(ConnectionContext);
  const { datadomainList } = useSelector(
    (state) => state.UserManagement.DataDomain
  );
  const { connectionStatus, setConnectionStatus } =
    useContext(ConnectionContext);
  const [searchValue, setSearchValue] = useState("");
  const [activeStep, setactiveStep] = useState(1);

  const [, setlayout] = useState("grid");
  const [doneBtnClick, setdoneBtnClick] = useState(false);
  const connectionType = useSelector(
    (state) => state.Flows.TargetTransformer.connectionDetails
  )?.source_type;

  const [selectedFilters, setselectedFilters] = useState([]);
  const [datadomainLabelClone, setDataDomainLabelClone] = useState([]);
  const [dbDataWithDbClone, setDbDataWithDbClone] = useState({});

  const getFormattedData = (c_data) => {
    return getFormattedDataConnectionFilter(
      c_data,
      datadomainLabelClone,
      selectedFilters
    );
  };

  useEffect(() => {
    setFromConnectionRoute(true);
    return () => {
      setFromConnectionRoute(false);
    };
  }, []);
  const listAllConnectionsHandler = () => {
    try {
      dispatch(getConnectionListCreatePODAction(true)).then((res) => {
        dispatch(setConnectionDataList(res.data));
      });
    } catch (error) {
      console.error("Error Fetching Connections List", error.message);
    }
  };

  const fetchDataDomainListHandler = () => {
    try {
      dispatch(getDataDomainList()).then((res) => {
        dispatch(setDataDomainList(res.data));
      });
    } catch (error) {
      console.error("Error Fetching Data domain List", error.message);
    }
  };

  const onCloseHandler = () => {
    onClose();
    setdoneBtnClick(false);
    setactiveStep(1);

    setConnectionStatus(null);
  };

  const onNext = () => {
    if (activeStep === 1) {
      setactiveStep(2);
    } else {
      setdoneBtnClick(true);
    }
  };

  useEffect(() => {
    listAllConnectionsHandler();
    dispatch(setActiveTabKey(""));
    if (datadomainList.length === 0) {
      fetchDataDomainListHandler();
    }
  }, []);

  return (
    <Modal
      size="xl"
      isOpen={isModalOpen}
      className="custom-modal connection-modal"
      centered={true}
    >
      <ModalHeader>
        <ConnectionsHeader
          setSearchValue={setSearchValue}
          dbDataWithDbClone={dbDataWithDbClone}
          getFormattedData={getFormattedData}
          setselectedFilters={setselectedFilters}
          selectedFilters={selectedFilters}
          activeStep={activeStep}
          layout="grid"
          route="target-transformer"
          onClose={onCloseHandler}
        />
        {/* <ConnectionsHeader setSearchValue={setSearchValue} setLayout={setlayout} activeStep={activeStep} layout="grid" route="target-transformer" onClose={onCloseHandler} /> */}
      </ModalHeader>
      <ModalBody>
        {activeStep === 1 ? (
          <ConnectionList
            searchValue={searchValue}
            setdbdataWithDbClone={setDbDataWithDbClone}
            selectedFilters={selectedFilters}
            setdatadomainlabelClone={setDataDomainLabelClone}
            layout="grid"
            setlayout={setlayout}
            fromModal={true}
            fromModalRoute={'target_transformer'}
          />
        ) : null}
        {activeStep === 2 ? (
          <TargetConnectionModalStep2
            onClose={onCloseHandler}
            setdoneBtnClick={setdoneBtnClick}
            isDoneBtnClick={doneBtnClick}
            connectionType={connectionType}
          />
        ) : null}
        {/* activeStep === 2 && connectionType && connectionType?.toLowerCase() === 's3' ?<div className='connection-modal-s3-step'> <S3ConnectionExplorer onClose={onCloseHandler} isDoneBtnClick={doneBtnClick}/></div> */}
      </ModalBody>
      <ModalFooter>
        {activeStep === 2 ? (
          <Buttons
            props={{
              buttonText: "",
              buttonClassName:
                "custom-btn-outline custom-btn btn-with-icon mr-2",
              buttonEvent: () => {
                setactiveStep(1);
              },
              ImgSrc: () => <BACKARROW />,
              isDisable: false,
              buttonType: Literals.BTN_TERTIARY,
            }}
          />
        ) : null}
        <Buttons
          props={{
            buttonText: activeStep === 1 ? "Next" : "Done",
            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
            buttonEvent: () => {
              onNext();
            },
            ImgSrc: () => <TICK />,
            isDisable: !connectionType && activeStep === 1?true:false,
            // connectionStatus && connectionStatus.color === "#00CC92" ? false : true,
            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default TargetConnectionModal;
