import React, { useEffect, useState } from "react";

import DataGridTable from "../../../Common/dataGridTable/DataGridTable";
import DataTabs from "../../../Common/dataTabs/DataTabs";
import { useSelector } from "react-redux";
import { ErrorComponent, QueryKillError } from "../../common/helperFunctions";
import NoResultsFound from "./NoResultsFound";
import ClaristaLoader from "../../../Common/claristaLoader/ClaristaLoader";

const LowerFlowsSection = ({
  tableData = {},
  VirtualTableHeight,
  tabArray,
  onTabChange,
  tableTitle,
  requestedTableId,
  activeTabKey,
  uuid,
  setUniqUUID,
  currentPODName,
  currentDomainName,
  showLoader,
  getTableData,
  setshowLoader,
  setIsPreviewDataLoading = () => { },
  isPreviewDataLoading
}) => {
  const [GridTableData, setGridTableData] = useState({})
  useEffect(() => {
    setGridTableData(tableData)
  }, [tableData])
  let isQueryKill = useSelector((state => state.CommonComponent?.DataGridTable?.isQueryKill))

  return (
    <>
      {tabArray && tabArray.length ? (
        <div className="padding-top-2 padding-left-2 padding-right-2">
          <DataTabs
            props={{
              activeKey: activeTabKey,
              defaultKey:activeTabKey,
              onChange: (activeLeftPanelKey) => {
              
                  onTabChange(activeLeftPanelKey);
          
              },
              tabArray: tabArray,
            }}
          />
        </div>
      ) : null}
      {isQueryKill ? <QueryKillError /> : Object.keys(GridTableData).length === 0 ? getTableData ? (
        <>
          <div className="no-flow-preview-data ">
            <ClaristaLoader isCancel={true} uuid={uuid} />
          </div>
        </>
      ) : <><NoResultsFound /></> : (
        GridTableData &&
        Object.keys(GridTableData).length !== 0 && (
          <div className="flow-lower-section h-100">
            <div id="flow-lower-table-sec" className="flow-lower-table bg-white custom-virtual-data-table h-100">
              <DataGridTable
                resp={GridTableData}
                uniqUUID={uuid}
                setUniqUUID={setUniqUUID}
                requestedTableId={requestedTableId}
                CurrentPODName={currentPODName}
                CurrentDomainName={currentDomainName}
                isPreviewDataAlreadyLoading={isPreviewDataLoading}
                setPreviewAlreadyLoading={setIsPreviewDataLoading}
                showDictionaryDetails={false}

              />

            </div>
          </div>
        )
      )}
    </>
  );
};

export default LowerFlowsSection;
