import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getDataDomainList, setDataDomainDescription, setDataDomainId, setDataDomainName } from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions';
import Buttons from "../../../../Common/button/Buttons";
import { DELETEICON, EDIT, SMALLDATADOMAIN ,DATADOMAIN} from "../../../../Common/iconSource";
import { LandingSimpleTable } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { NoDataComponent,ErrorComponent, getRelativeTime, permissions } from '../../../common/helperFunctions';
import { Literals } from "../../../common/literals";
import LoadingComponent from '../../../common/loadingComponent';
import NoSearchResultFound from '../../../common/NoSearchResultFound';
import './dataDomainPage.scss';
import DeleteDomainModal from './DeleteDomainModal';

const DataDomainListView = ({ searchData,setLayout }) => {
    const [domainList, setDomainList] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const history = useNavigate();
    const store = useStore();
    const dispatch = useDispatch();
    const domainData = [];
    const loadingStatus = useSelector(state => state.LoadingReducer.loadingStatus);
    const [deleteValue, setDeleteValue] = useState("");
    const [empty,setEmpty]=useState(false);
    const [errMsg,setErrMsg]=useState("");

    const isSuperUser = useSelector(state => state.UserReducer.user.userDetails.is_superuser)

    useEffect(() => {
        getDataDomainListData();
    }, [])

    useEffect(() => {
        dispatch(setDataDomainName(""));
        dispatch(setDataDomainDescription(""));
        dispatch(setDataDomainId(""));
    }, [])

    const handleEdit = (dataDomainIds) => {
        history(Literals.links.USER_MANAGEMENT_EDIT_DATA_DOMAIN + dataDomainIds,{state:{tabName:Literals.DATA_DOMAIN}})
        window.history.replaceState({state:{tabName:Literals.DATA_DOMAIN}},'')

    };
    const onRowClick = (record) => {
        history(Literals.links.USER_MANAGEMENT_DATA_DOMAIN + record.key,{state:{tabName:Literals.DATA_DOMAIN}})
        window.history.replaceState({state:{tabName:Literals.DATA_DOMAIN}},'')

    }
    const openDeleteDialog = (value) => {
       
        setDeleteValue(value);
        setOpenDeleteModal(true)

    };


    const getDataDomainListData = async () => {
        let response;
        try {
            response = await store.dispatch(getDataDomainList());
            if (response.status === "success") {
                setLayout(response.data[0]?.view_type??"grid");
                if(response.data.length==0){
                    setEmpty(true);
                }
                setDomainList(response.data);
            }
        } catch (error) {
            setErrMsg(error.message);
         }
    };



    const filterData = domainList && domainList.filter((data) => {
        return (data?.data_domain_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
            data?.data_domain_description?.toLowerCase().includes(searchData?.toLowerCase()) ||
            // getRelativeTime(data?.created_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
            data?.created_by_user?.username?.toLowerCase().includes(searchData?.toLowerCase()) ||
            getRelativeTime(data?.updated_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
            data?.updated_by_user?.username?.toLowerCase().includes(searchData?.toLowerCase()))
    })

    filterData && filterData.forEach((value) =>

        {
            const isEditor = !!value?.permission?.find(v => v === permissions.domain.editor)
            const isOwner= !!value?.permission?.find(v => v === permissions.domain.owner)
            domainData.push(
                {
                    key: value?.data_domain_id,
                    dataDomain: value?.data_domain_name.toUpperCase(),
                    dataDomainName: <div className='d-flex align-items-center w-100 h-100 font-w-600'><span className="icon-box-desc"><SMALLDATADOMAIN color='#2c83c4' /></span><Tooltip placement="top" title={value?.data_domain_name}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.data_domain_name?.toUpperCase()} /></Tooltip></div>,
                    description: <Tooltip placement="topLeft" title={value?.data_domain_description}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.data_domain_description ? value?.data_domain_description : ''} /></Tooltip>,
                    // createdOn: <Tooltip placement="top" title={getRelativeTime(value?.created_on)}>
                    //     {getRelativeTime(value?.created_on)}
                    //     </Tooltip>,
                    // createdOn: <Tooltip placement="top" title={getRelativeTime(value?.created_on)}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={getRelativeTime(value?.created_on) ? getRelativeTime(value?.created_on) : ''} /></Tooltip>,
                    createdBy: <Tooltip placement="top" title={value?.created_by_user?.username}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.created_by_user?.username ? value?.created_by_user?.username : ''} /></Tooltip>,
                    updatedOn: <Tooltip placement="top" title={getRelativeTime(value?.updated_on)}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={getRelativeTime(value?.updated_on) ? getRelativeTime(value?.updated_on) : ''} /></Tooltip>,
                    updatedBy: <Tooltip placement="top" title={value?.updated_by_user?.username}>{value?.updated_by_user?.username}</Tooltip>,
                    userGroups: <Tooltip placement="top" title={value?.group}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.group.toString() ?value?.group.toString() : ''} /></Tooltip>,
                    user: <Tooltip placement="top" title={value?.user}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={value?.user.toString() ? value?.user.toString() : ''} /></Tooltip>,
                    action: <div className="d-flex justify-content-center align-items-center h-100">
                        <Buttons props={{
                            buttonText: '', buttonClassName: "custom-btn-outline ml-1 mr-1 custom-btn btn-with-icon", buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleEdit(value?.data_domain_id)
                            }, tooltip: 'Edit', ImgSrc: () => <EDIT />, 
                            isDisable: !(isSuperUser || isOwner), 
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }} />
                        <Buttons props={{
                            buttonText: '', buttonClassName: "custom-btn-outline ml-1 mr-1 custom-btn btn-with-icon error-red", buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                openDeleteDialog({dataDomain: value?.data_domain_name, key: value?.data_domain_id, data_domain_id: value?.data_domain_id,})
                            }, tooltip: 'Delete', ImgSrc: () => <DELETEICON />, 
                            isDisable: !(isSuperUser && isOwner), 
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }} />
                    </div>,
                    data: value

                }
            )
        }
        )

    const columns = [
        {
            name: Literals.DATA_DOMAIN,
            dataIndex: "dataDomainName",
            key: "dataDomainName",
            width: 200,

            sortable: true
        },
        {
            name: Literals.DESCRIPTION,
            dataIndex: "description",
            key: "description",
            width: 300,
            resizable: true,
            sortable: false
        }, {
            name: Literals.USERGROUP,
            dataIndex: "userGroups",
            key: "userGroups",
            // width: 100,

            sortable: false


        },
        {
            name: Literals.USERS,
            dataIndex: "user",
            key: "user",
            className: "single-line-ellipsis",
            // width: 100,

            sortable: false

        },
        {
            name: Literals.UPDATED_ON,
            dataIndex: "updatedOn",
            key: "updatedOn",
            // width: 200,

            sortable: true

        },
        {
            name: Literals.CREATED_BY,
            dataIndex: "createdBy",
            key: "createdBy",
            width: 250,

            className: "single-line-ellipsis",
            sortable: true

        },
        {
            name: <div className='text-center'>Action</div>,
            dataIndex: "action",

            // width: 150,
            sortable: false,

            key: 'action',


        }
    ];
    let TableHeight;
    if (window.innerHeight >= 750 && window.innerHeight < 1000) {
        TableHeight = Math.round((window.innerHeight - 280))
    } else if (window.innerHeight >= 1000) {
        TableHeight = Math.round((window.innerHeight - 150))
    } else {
        TableHeight = Math.round(window.innerHeight - 100)
    }

    const getComparator = (sortColumn) => {
        switch (sortColumn) {
            case 'dataDomainName':
                return (a, b) => a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight) : null
            case 'userGroups':
                return (a, b) => a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight) : null
            case 'user':
                return (a, b) => a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children[1]?.props?.children?.props?.textToHighlight) : null
            case 'createdOn':
                return (a, b) => a?.data?.created_on?.localeCompare(b?.data?.created_on)??null
            case 'createdBy':
                return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
            case 'updated_by':
                return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight?.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }

    return (
        <div className="user-management-table h-100">
            {loadingStatus ? <LoadingComponent />:errMsg.length>0?<ErrorComponent msg={errMsg}/>:empty?<NoDataComponent logo={<DATADOMAIN width="60" height="60" color="black"/>} message="
                Click On (+) To Create Data Domain"/>:searchData?.length > 0 && filterData?.length === 0 ?
                <NoSearchResultFound /> :
                
                <LandingSimpleTable
                    cols={columns}
                    autoWidth={true}
                    rowsData={domainData}
                    selectedRowIndex={undefined}
                    onRowClick={(record, index) => { onRowClick(record, index) }}
                    getComparator={getComparator}
                    tableHeight={(domainData?.length * 41) + 41}
                    // tableHeight={TableHeight}
                />
            }

            <DeleteDomainModal openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} deleteValue={deleteValue} getDataDomainListData={getDataDomainListData} />
        </div>
    )

}

export default DataDomainListView;