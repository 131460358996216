import { combineReducers } from "redux";


const initialState={

    catalogHome:{},
    alert:{},
    flow:{},
    connection:{},
    user:{},
    scheduler:{},
    dataDictionary:{}

}

const AlertReducer = (state = initialState, action) => {

    switch(action.type)
    {

        case "SET_FILTER": 
        return  {
            ...state,...action.payload
        }

        default:
            return {...state}
    }

}


export default AlertReducer