import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { logoutUser } from ".././../../store/userAuthentication/userActions";
import store from "../../../store";
import { setCreatePodPageDefault } from ".././../../store/modules/dataCatalogue/createPOD/createPODActions";
import { setDictionaryDefault } from ".././../../store/modules/dataCatalogue/dictionary/dictionaryAction";
import { setLandingPageDefault } from ".././../../store/modules/dataCatalogue/landingPage/landingPageActions";
import { setPreviewPageDefault } from ".././../../store/modules/dataCatalogue/preview/previewActions";
import { setSummaryPageDefault } from ".././../../store/modules/dataCatalogue/summary/summaryActions";
import { setCreateRulePageDefault } from ".././../../store/modules/dataRules/CreateRule/rulesAction";
import { setAccessManagementPageDefault } from ".././../../store/modules/UserManagement/accessManagement/accessManagementActions";
import { setConnetionPageDefault } from ".././../../store/modules/UserManagement/connection/connectionActions";
import { setDataDomainPageDefault } from ".././../../store/modules/UserManagement/dataDomains/dataDomainActions";
import { setUserGroupPageDefault } from ".././../../store/modules/UserManagement/userGroup/UMActions";
import { setUsersPageDefault } from ".././../../store/modules/UserManagement/users/usersActions";
import { Literals } from "./literals";
import "./Profile.scss";
import { FEEDBACK, LOGOUT, PROFILE_ICON, RESET_PASSWORD } from "../../Common/iconSource";
import UpdateProfile from "../UserAuthentication/UpdateProfile";
import { Tooltip } from "antd";
import { getConfirmationModal, stringToColor } from "./helperFunctions";
import { useSocket } from "../../../services/SocketContextProvider";

function Profile() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);

  const navigate = useNavigate()
  const isMicrosoftAuthenticated = localStorage.getItem("isMicrosoftAuthenticated");
  const userDetails = useSelector(
    (state) => state.UserReducer?.user?.userDetails
  );

  const handleClose = () => {
    setShow(false);
  };

  const SocketContext = useSocket();

  const logoutHandler = async () => {
    let response;
    try {
      response = await store.dispatch(logoutUser());
      // if (SocketContext?.socket) {


      SocketContext?.closeAllSockets();
      // }
      if (response?.status === "success") {
        setReduxDefault();
        setShowWarningPopup(false)
      }
    } catch { }
  };

  const toggleMenuHandler = () => {
    setIsMenuOpen(isMenuOpen => !isMenuOpen);
  }


  const setReduxDefault = () => {
    store.dispatch(setCreatePodPageDefault(""));
    store.dispatch(setDictionaryDefault(""));
    store.dispatch(setLandingPageDefault(""));
    store.dispatch(setPreviewPageDefault(""));
    store.dispatch(setSummaryPageDefault(""));
    store.dispatch(setCreateRulePageDefault(""));
    store.dispatch(setAccessManagementPageDefault(""));
    store.dispatch(setConnetionPageDefault(""));
    store.dispatch(setDataDomainPageDefault(""));
    store.dispatch(setUserGroupPageDefault(""));
    store.dispatch(setUsersPageDefault(""));
  };

  return (
    <div>
      <UpdateProfile show={show} handleClose={handleClose} isEditMode={isEditMode} setIsEditMode={setIsEditMode} showPasswordChange={showPasswordChange} setShowPasswordChange={setShowPasswordChange} />
      {isMenuOpen && <div className="user-menu-backdrop" onClick={() => { toggleMenuHandler() }}></div>}
      <div className="user-menu" onClick={() => { toggleMenuHandler() }}>
        <Tooltip placement="left" className="text-capitalize"
          title={(() => {
            if (userDetails?.first_name && userDetails?.last_name && userDetails?.first_name !== "" && userDetails?.last_name !== "")
              return `${userDetails?.first_name} ${userDetails?.last_name}`
            return userDetails?.first_name
          })()
          }
        >
          <p className="login-user-initial"
            style={{ background: stringToColor(`${userDetails?.first_name} ${userDetails?.last_name}`), color: '#FFF', lineHeight: "1" }}
          >

            {
              userDetails?.first_name && userDetails?.last_name ? `${userDetails?.first_name?.charAt(0)?.toUpperCase()}${userDetails?.last_name?.charAt(0)?.toUpperCase()}`
                : userDetails?.first_name ? `${userDetails?.first_name?.charAt(0)?.toUpperCase()}`
                  : userDetails?.email?.charAt(0)?.toUpperCase()
            }
          </p>
        </Tooltip>

        <ul className={`p-2 user-sub-menu ${isMenuOpen ? "user-sub-menu-active" : ""}`}>
          {/* <li>
            <h5 className="paragraph mb-0">{`${userDetails?.first_name} ${userDetails?.last_name}`}</h5>
            <small>{userDetails?.email}</small>
          </li> */}
          <li className="d-flex align-items-center " onClick={() => { setShow(true) }}>
            {/* <Link className="w-100 px-0" to="#" > */}
            <>
              <span className="mr-2"><PROFILE_ICON /></span>
              {Literals.PROFILE}
            </>
            {/* </Link> */}
          </li>
          {isMicrosoftAuthenticated ? "" : <li className="d-flex align-items-center" onClick={() => { setShowPasswordChange(true); setShow(true) }}>
            {/* <Link className="w-100 px-0" to="#" > */}
            <>
              <span className="mr-2"><RESET_PASSWORD /></span>
              Change Password
            </>
            {/* </Link> */}
          </li>}

          {/* <li className="d-flex align-items-center">
            <Link className="w-100" to="#" onClick={() => setShow(true)}>
              <>
                <FEEDBACK />
                Feedback
              </>
            </Link>
          </li> */}
          <li className="d-flex align-items-center" onClick={() => setShowWarningPopup(true)}>
            {/* <Link className="w-100 px-0" to="#"> */}
            <>
              <span className="mr-2"><LOGOUT /></span>
              {Literals.LOGOUT}
            </>
            {/* </Link> */}
          </li>
        </ul>
      </div>
      {
        getConfirmationModal({
          open: showWarningPopup,
          setOpen: setShowWarningPopup,
          onConfirm: () => { logoutHandler() },
          confirmationText: "Logout",
          hideHeading: true,
          cancelText: "Cancel",
          heading: <div><LOGOUT color="#2c83c4" /> Logout?</div>,
          hideButton: false,
          body: <div>Are you sure you want to log out?</div>
        })

      }
    </div>
  );
}

export default Profile;
