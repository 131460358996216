import React, { useEffect, useRef, useState } from 'react'
//import 'antd/dist/antd.css';
import { VariableSizeGrid as Grid } from 'react-window';

import classNames from 'classnames';
import { Table } from 'antd';
import { Tooltip } from "antd";

import { SORTARROW } from '../iconSource';


function LandingPageTable({ props }) {


    const { columns, dataSource, tableHeight, onRowSelection = () => { }, scroll, size, logsRowSelectedIndex = null } = props

    const [tableWidth, setTableWidth] = useState(0);

    const [rowSelectedIndex, setRowSelectedIndex] = useState(logsRowSelectedIndex)

    const widthColumnCount = columns?.filter(({ width }) => !width).length;
    useEffect(() => {

        setRowSelectedIndex(logsRowSelectedIndex)
    }, [logsRowSelectedIndex])
    const mergedColumns = columns?.map((column) => {
        if (column.width) {
            return column;
        }

        let columnsWidth
        if (columns.length <= 8) {
            columnsWidth = Math.floor(tableWidth / widthColumnCount)
        } else {
            columnsWidth = 150;

        }

        return { ...column, width: columnsWidth }
    });
    const gridRef = useRef();


    const [connectObject] = useState(() => {
        const obj = {};
        Object.defineProperty(obj, 'scrollLeft', {
            get: () => null,
            set: (scrollLeft) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({
                        scrollLeft,
                    });
                }
            },
        });
        return obj;
    });

    const resetVirtualGrid = () => {
        if (gridRef && gridRef?.current) {
            gridRef.current.resetAfterIndices({
                columnIndex: 0,
                shouldForceUpdate: true,
            });
        }
    };

    useEffect(() => resetVirtualGrid, [tableWidth, dataSource]);

    const loadMore = () => {


    };

    const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
        ref.current = connectObject;

        return (
            <div >
                <Grid
                    ref={gridRef}
                    className={''}
                    columnCount={mergedColumns.length}
                    columnWidth={(index) => {
                        const { width } = mergedColumns[index];
                        return width;

                    }}
                    height={tableHeight}
                    autoHeight={true}
                    rowCount={rawData.length}
                    rowHeight={() => 30}
                    width={tableWidth + scrollbarSize + 5}
                    onScroll={({ scrollLeft }) => {

                        onScroll({

                            scrollLeft,
                        });


                        loadMore()


                    }}

                >
                    {({ columnIndex, rowIndex, style }) => {
                        style = {
                            ...style,
                            cursor: 'pointer'
                            // backgroundColor: getBackGroundColor(rowIndex),
                        };
                        let rowName = rawData[rowIndex][mergedColumns[columnIndex]?.dataIndex]//rawData[rowIndex][columnIndex];

                        if (typeof (rowName) === 'boolean') {
                            rowName = rowName.toString()
                        }

                        return (
                            <Tooltip placement="topLeft" title={rawData[rowIndex][mergedColumns[columnIndex]?.dataIndex]} >
                                <span
                                    className={classNames('virtual-data-table-cell', {
                                        'virtual-data-table-cell-last': columnIndex === mergedColumns.length - 1,
                                    })}
                                    onClick={() => {

                                    }}
                                    // history.push('/editAnalysis/' + rawData[rowIndex][rawData.length - 1])
                                    style={style}

                                >
                                    {rowName}
                                </span>
                            </Tooltip>

                        )
                    }}
                </Grid>

            </div>

        );

    };
  

    return (

        // <ResizeObserver
        //     onResize={({ width }) => {
        //         setTableWidth(width);
        //     }}
        // >
        <Table
            {...props}
            sortUpIcon={<SORTARROW />} sortDownIcon={<SORTARROW />}
            sortNoneIcon={<SORTARROW />}
            className="common-page-table"
            columns={mergedColumns}
            pagination={false}
            size={size}
            scroll={{ y:tableHeight?? "calc(100vh - 250px)" }}
            rowClassName={(record, index) => index === rowSelectedIndex ? 'table-selected-row' : ''}
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        // event.stopPropagation();
                        // event.preventDefault()
                        onRowSelection(record, rowIndex)
                        setRowSelectedIndex(rowIndex)
                    }
                }

            }}
        />
        // <LandingSimpleTable
        //     cols={mergedColumns}
        //     rowsData={dataSource}
        //     selectedRowIndex={rowSelectedIndex}
        //     onRowClick={(record, rowIndex, e) => {
        //         onRowSelection(record, rowIndex)
        //         setRowSelectedIndex(rowIndex)
        //     }}
        //     getComparator={getComparator}
        //     tableHeight={tableHeight}
        // // tableHeight={TableHeight}
        // />
        // </ResizeObserver>

    );
}

// LandingPageTable.defaultProps = {
//     props : {
//         onRowSelection : () => {}
//     }

// }


export default LandingPageTable
