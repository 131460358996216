import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
//   import FilterFormGroup from "./FilterFormGroup";
import DataGridFilterHeader from "./DataGridFilterHeader";
import { DefaultFilterInfography } from "../../modules/alert/createAlert/addFilterCondition/DefaultFilterInfography";
import FilterFormGroup from "../../modules/alert/createAlert/addFilterCondition/FilterFormGroup";
import { emitToastNotification } from "../../../helpers/toast_helper";
import _, { cloneDeep } from "lodash";
import { handleCriteria, handleCriteriaValue } from "../../modules/common/helperFunctions";

const DataGridAddFilter = forwardRef(({ props }, ref) => {
  const {
    initialColumn = [],
    setAppliedFilter = () => { },
    appliedFilter,
    isPolicy = false,
    setFilterPop = () => { },
    isdisabled = false,
    isFilterTransformer = '',
    defaultToggleValue = false,
    setshowCloseWarning = () => { },
  } = props;

  const [showFilterType, setshowFilterType] = useState("AND");
  const [formGroupData, setFormGroupData] = useState([]);
  const [submitedForm, setSubmitedForm] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    addFormGroup(false);
  }, []);

  useEffect(() => {

    if (formGroupData && formGroupData.length === 0) {
      setFormGroupData([
        {
          uid: new Date().getTime(),
          column: "",
          criteria: "",
          actualValue: "",
          value: "",
          datatype: "",
          operation: showFilterType,
          columnId: ''
        },
      ]);
    } else {
      if (isFilterTransformer === 'newFilterTransformer') {
        setAppliedFilter([...formGroupData], false);
      }
    }
  }, [formGroupData]);
  useEffect(() => {
    if (appliedFilter && appliedFilter.length) {

      if (appliedFilter[0]?.operation?.toLowerCase() === 'or') {

        setshowFilterType(appliedFilter[0].operation)
      }
      let cloneFilter = cloneDeep(appliedFilter);
      cloneFilter.forEach((item) => {
        if (item.value?.includes('%')) {
          item.actualValue = item.value?.replaceAll('%', '')
        } else {
          item.actualValue = item.value
        }
      });
      setFormGroupData([...cloneFilter]);
    }
  }, [appliedFilter]);

  useImperativeHandle(ref, () => ({
    executeFilter: () => {
      applyFilter(false);
    },
    resetFilter: () => {

      addFormGroup(true);

    },
    getFilterGroup: () => {
      return formGroupData
    }
  }));
  useEffect(() => {
    if (initialColumn.length) {
      setColumns([...initialColumn]);
    } else {
      setColumns([]);

    }
  }, [initialColumn]);

  const applyFilter = (clearAll) => {
    if (clearAll) {
    } else if (formGroupData.length) {
      let validateColumn = formGroupData.every((el) => {
        return el?.column?.trim().length > 0
      })
      let validateCriteria = formGroupData.every((el) => {
        return el?.criteria?.trim().length > 0 && el.criteria.trim() !== "Select Filter Criteria"
      })
      let validateValue = formGroupData.every((el) => {
        if (typeof el?.value === 'string') {
          return el?.value?.trim()?.length > 0
        }
        else if ((el?.criteria === 'IN' || el?.criteria === 'NOT IN') && el.value?.length) {
          return true
        }
        else if (typeof el?.value === 'object') {
          return el?.value?.[0]?.trim().length > 0 && el?.value?.[1]?.trim().length > 0
        }

      })

      if (!isPolicy && (!validateColumn || !validateCriteria || !validateValue)) {
        emitToastNotification('warning', 'Empty field found in filter.')
      }
      else if ((validateColumn && validateCriteria && validateValue) || isPolicy) {
        setAppliedFilter(formGroupData, false);
        setFilterPop(false);
      }
    }

  };

  const addFormGroup = (initialize) => {

    if (initialize) {
      let groupFormData = [];
      const formData = {
        uid: new Date().getTime(),
        column: "",
        criteria: "",
        value: "",
        datatype: "",
        operation: showFilterType,
        columnId: ''
      };
      groupFormData.push(formData);
      setFormGroupData([...groupFormData]);
    } else {
      let groupFormData = [...formGroupData];
      const formData = {
        uid: new Date().getTime(),
        column: "",
        criteria: "",
        value: "",
        datatype: "",
        operation: showFilterType,
        columnId: ''
      };
      groupFormData = [...groupFormData, formData]
      // groupFormData.push(formData);


      setFormGroupData([...groupFormData]);
    }
  };

  const removeFormGroup = (clearAll, index) => {
    setshowCloseWarning(true);
    setSubmitedForm(true);
    if (clearAll) {
      setFormGroupData([]);
      applyFilter(true);
    } else {
      const groupFormData = [...formGroupData];
      // setFormGroupData([]);

      if (groupFormData.length) {
        groupFormData.splice(index, 1);
        setFormGroupData([...groupFormData]);

        // if (!groupFormData.length) {
        //   setFormGroupData([]);

        //   applyFilter(true);
        // } else {
        //   setFormGroupData([...groupFormData]);
        // }
      }
    }
  };

  const setFormData = (
    formGroupIndex,
    columnValue,
    filterOption,
    filterValue,
    filterType,
    columnId,
    parameterActive = false,
    parameterValue = ''
  ) => {
    setshowCloseWarning(true)
    setSubmitedForm(false);
    const groupFormData = [...formGroupData];

    let data = {
      uid: groupFormData[formGroupIndex].uid,
      column: columnValue,
      criteria: handleCriteria(filterOption, filterValue),
      value:handleCriteriaValue(filterOption, filterValue),  // parameterActive ? `{{${filterValue}}}` : 
      actualValue: filterValue,
      actualCriteria: filterOption,
      datatype: filterType,
      operation: showFilterType,
      columnId: columnId,
      parameterActive: parameterActive,
      parameterValue: parameterValue
    };
    // if (filterOption === 'IN') data['value'] = _.compact(filterValue.split(','))
    groupFormData[formGroupIndex] = data;
    // if (isFilterTransformer) {
    //   setAppliedFilter([...groupFormData], false);
    // }
    setFormGroupData([...groupFormData]);
  };

  const onResetEvent = () => {
    setshowCloseWarning(true)
    removeFormGroup(true, "");
  };
  const onAddFilter = () => {
    setshowCloseWarning(true)
    addFormGroup(false);
  };
  const onFilterToggle = (view) => {
    setshowCloseWarning(true)
    setshowFilterType(view);
    formGroupData.forEach((el, i) => {
      formGroupData[i]['operation'] = view
    })
    // if (isFilterTransformer) {
    //   setAppliedFilter([...groupFormData], false);
    // }
    setFormGroupData([...formGroupData])
  };

  return (
    <div className="prev-filter-comp filter-section">
      <DataGridFilterHeader
        props={{
          ...props,
          onResetEvent: onResetEvent,
          onFilterToggle: onFilterToggle,
          onAddFilter: onAddFilter,
          onRunFilter: applyFilter,
          isPolicy: isPolicy,
          isFilterTransformer: isFilterTransformer,
          defaultToggleValue
        }}
      />

      <div className="flows-filter-wrapper  padding-2 dgt-custom-filter-pop">
        <div className="flows-filter-section">
          <div className="filter-options">
            <p className="label pl-2">Column Name</p>
            <p className="label pl-4 ml-3">Filter Criteria</p>
            <p className="label pl-4">Filter Value</p>
          </div>
          <div className="overflow-auto filter-min-max-height" style={{ maxHeight: "120px", minHeight: "40px" }}>
            {formGroupData.length ? (
              formGroupData.map((form, index) => (

                <FilterFormGroup
                  isdisabled={
                    isdisabled
                  }
                  key={form.uid}
                  formGroup={form}
                  formGroupIndex={index}
                  filterColumns={columns}
                  setFormData={setFormData}
                  removeFormGroup={removeFormGroup}
                  submitedForm={submitedForm}
                  from={props.from}
                  isFilterTransformer={isFilterTransformer}
                />
              ))
            ) : (
              <div className="text-center">
                <DefaultFilterInfography />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default DataGridAddFilter;
