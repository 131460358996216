import React, { useState } from "react";
import {
  ACCORDIONDOWNARROW,
  DOWNARROW,
  MAGNIFY,
} from "../../../../../Common/iconSource";
import { DndVirtualList } from "../../../../../Common/dndVirtualList/DndVirtualList";
import { Input, Tooltip } from "antd";
import { useEffect } from "react";

const PreviewDropdown = ({ schemaDetails ,expandDropdown,setExpandDropdown}) => {
  const [cards, setCards] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const convertToCamelCase = (term) => {
    let updatedTerm = term?.replaceAll("_", " ");
    return updatedTerm;
  };
  useEffect(() => {
    if (schemaDetails.length) {
      let card_list = schemaDetails
        .filter((item) => {
          return convertToCamelCase(item?.business_term)
            ?.toLowerCase()
            ?.includes(searchValue.toLowerCase());
        })
        .map((item) => {
          let formattedTerm = convertToCamelCase(item?.business_term);
          return (
            <Tooltip title={formattedTerm}>
              <div className="column-name text-with-ellipsis text-dark">
                {formattedTerm}
              </div>
            </Tooltip>
          );
        });
      setCards(card_list);
    }
  }, [schemaDetails, searchValue]);

  return (
    <div style={{ width: "250px", position: "relative" }}>
      <div className="preview-dropdown">
        <div>
          <div
            onClick={() => setExpandDropdown(!expandDropdown)}
            className="preview-dropdown-header"
          >
            <span>Columns</span>
            <span className={expandDropdown ? "rotate" : ""}>
              <DOWNARROW />
            </span>
          </div>
        </div>
        {expandDropdown ? (
          <div className={`dropdown-body pt-0`}>
            <hr style={{ marginTop: "0px" }} />
            <Input
              prefix={<MAGNIFY />}
              className="preview-dropdown-search"
              placeholder="Search"
              onChange={(e) => setSearchValue(e.target.value)}
              style={{ borderRadius: "20px", marginBottom: "15px" }}
            />
            <div style={{ height: "calc(100vh - 216px)", overflowY: "auto" }}>
              <DndVirtualList
                data={cards}
                rawData={schemaDetails}
                setData={() => {}}
                stopDraggingRow={true}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PreviewDropdown;
