import React, { Fragment, useState } from "react";
import Buttons from "../../../../Common/button/Buttons";
import { PLUS, USERMANAGEMENT } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import NoDataInfo from "../../../dataCatalogue/accessMangament/components/NoDataInfo";
import AddUserModal from "./AddUserModal";
import UsersTable from "./UsersTable";

const AddUsersTable = ({
  viewMode,
  selectedUsers,
  existingUserIds,
  setSelectedUsers,
  onChangeUserType,
  groupId,
  isSuperUser = false,
  isLeader = false
}) => {
  const [isAddUsersModalOpen, setIsAddUserModalOpen] = useState(false);

  const toggleAddUsersModal = () => {
    setIsAddUserModalOpen((isAddUsersModalOpen) => !isAddUsersModalOpen);
  };

  return (
    <Fragment>
      <AddUserModal
        isModalOpen={isAddUsersModalOpen}
        toggleModal={toggleAddUsersModal}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <div className="user-groups-table">
        <div className="user-groups-table-header">
          <div className="user-groups-table-title">
            <USERMANAGEMENT height="18" width="18"/>
            <h4>{Literals.ADD_USERS}</h4>
          </div>
          <div className="user-groups-table-options">
            <Buttons
              props={{
                buttonText: Literals.ADD,
                buttonClassName:
                  "custom-btn-primary custom-btn btn-with-text ml-2",
                buttonEvent: () => {
                  toggleAddUsersModal();
                },
                ImgSrc: () => <PLUS />,
                isDisable: viewMode.includes("view") || !(isSuperUser || isLeader),
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          </div>
        </div>
        <div className="user-groups-table-container">
          {selectedUsers.length ? (
            <UsersTable
              groupId={groupId}
              existingUserIds={existingUserIds}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              onChangeUserType={onChangeUserType}
              viewMode={viewMode}
              isLeader={isLeader}
              isSuperUser={isSuperUser}
            />
          ) : (
            <NoDataInfo category="users" />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AddUsersTable;
