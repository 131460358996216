import React from 'react'
import { Handle } from 'reactflow';
import filter from '../../../../../assets/icons/Filter.svg'

import { connect, useDispatch, useStore } from 'react-redux'
import { ERRORCIRCLE, LOADINGCIRCLE, CHECKMARKCIRCLE, DISMISSCIRCLE, DELETETRANSFORMER, FILTERICON, FILTERFLOW, FILTER, LOADINGCIRCLEQUEUE, SCHEMATRANSFORMER, SCHEMATRANSFORMERBLUE, AGGREGATETRANSFORMERBLUE, STOPOCTAGON } from '../../../../Common/iconSource'
import { deleteTransformer } from '../../../../../store/modules/flows/flowsActions';
import { useParams } from 'react-router-dom';
import { failedStatus, successStatus } from '../../../common/helperFunctions';
import { Tooltip } from 'antd';
import { getTransformerName, isTransformerNamePresent } from '../../transformers/FlowsHelperFunction';

const Aggrigate = (props) => {
    const store = useStore()
    const dispatch = useDispatch()
    const { flowId } = useParams();

    let { currentTransformer, executeEntireFlow, transformerProgress, id, currentExecutingTransformerId, transformerLoading, flowsElements } = props
    const getStatus = () => {

        if (transformerProgress && transformerProgress.length) {
            let currentStatus = ''
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;

            if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'starting' || currentStatus?.toLowerCase() === 'running')) {
                return <span className='bx-spin d-flex'><LOADINGCIRCLE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'success' || currentStatus?.toLowerCase() === 'done' || currentStatus?.toLowerCase() === 'finished')) {
                return <CHECKMARKCIRCLE />
            }
            else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error')) {
                return <DISMISSCIRCLE />
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'queued')) {
                return <span className='bx-spin d-flex'><LOADINGCIRCLEQUEUE /></span>
            } else if (currentStatus !== '' && (currentStatus?.toLowerCase() === 'aborted')) {
                return <span className='d-flex'> <STOPOCTAGON /></span>
            }
            else {
                return <ERRORCIRCLE />
            }

        }
        else if (executeEntireFlow && !transformerProgress.length) {
            return <ERRORCIRCLE />//<span className='bx-spin d-flex'><LOADINGCIRCLE /></span>

        } else if (!transformerProgress.length && !executeEntireFlow) {
            return <ERRORCIRCLE />
        }

        // }


    }
    // const getTransformerName = (valueFor = '') => {

    //     // if (currentTransformer?.id === id) {
    //     let transformerName = flowsElements.find((ele) => ele.id === id)?.transformerName;
    //     // if (transformerName && transformerName?.length > 10 && valueFor === '') {
    //     //     return `${transformerName?.slice(0, 8)}...`
    //     // } else {
    //     return transformerName ? transformerName : ''
    //     // }

    //     // } else {
    //     //     return ''
    //     // }
    // }
    const getInitialStatus = () => {

        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        return currentStatus ? currentStatus?.toLowerCase() : 'initial'
    }
    // const getErrorMsg = () => {
    //     let error_msg = transformerProgress.find(element => {
    //         return element.crnt_transformer === id
    //     })?.error_msg;

    //     return error_msg
    // }
    const getErrorMsg = () => {
        let error_msg = transformerProgress.find(element => {
            return element.crnt_transformer === id
        })?.error_msg;
        let currentStatus = 'INITIAL'
        if (transformerProgress && transformerProgress.length) {
            currentStatus = transformerProgress.find(element => {
                return element.crnt_transformer === id
            })?.state;
        }

        return (currentStatus?.toLowerCase() === 'failed' || currentStatus?.toLowerCase() === 'error' || currentStatus?.toLowerCase() === 'aborted') ? error_msg : null

    }
    return (
        <>
            <div className={`transformer-box merge-box ${currentTransformer.id === id ? `transformer-active` : ``}`}>
                <Handle type="source" position="right" />
                <div>
                    {/* <img src={filter} alt={`filter`} /> */}
                    <div className='transformer-icon'>

                        <AGGREGATETRANSFORMERBLUE />
                    </div>
                    {!window.location.href.includes('viewFlows') && currentTransformer && currentTransformer.id === id ? <span className="transformer-bottom-img-delete cursor-pointer" onClick={(e) => { e.stopPropagation(); store.dispatch(deleteTransformer(id, flowId)) }} >
                        <DELETETRANSFORMER />
                    </span> : null}
                    {/* <div className="transformer-bottom-img" >
                        {flowsElements.find(item => item.id === id)?.status === 'success' ? <CHECKMARKCIRCLE /> : flowsElements.find(item => item.id === id)?.status === 'error' ? <DISMISSCIRCLE /> : transformerLoading ? <LOADINGCIRCLE /> : <ERRORCIRCLE />}
                    </div> */}
                    {getErrorMsg() ? <Tooltip title={getErrorMsg()}>  <div className="transformer-bottom-img" >
                        {getStatus()}
                    </div></Tooltip> : getInitialStatus() === 'initial' ? <Tooltip title={'Transformer not executed yet'}><div className="transformer-bottom-img" >
                        {getStatus()}
                    </div></Tooltip> : <div className="transformer-bottom-img" >
                        {getStatus()}
                    </div>}
                    <Tooltip title={store.dispatch(getTransformerName(id))}>
                    <div className={dispatch(isTransformerNamePresent(id)) ? "transformer-bottom-name" : "error-transformer-bottom-name"} >

                            {store.dispatch(getTransformerName(id))}

                        </div>
                    </Tooltip>
                </div>
                <Handle type="target" position="left" />
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        flowsElements: state.FlowsReducer.flowsElements,
        currentTransformer: state.FlowsReducer.currentTransformer,
        currentExecutingTransformerId: state.FlowsReducer.currentExecutingTransformerId,
        executeEntireFlow: state.FlowsReducer.executeEntireFlowStart,
        transformerProgress: state.FlowsReducer.transformerProgress,
        transformerLoading: state.FlowsReducer.transformerLoading
    };
};

export default connect(mapStateToProps)(Aggrigate)