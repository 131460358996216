import React, { useState } from 'react'
import INNERJOIN from "../../../../../assets/images/mergeJoins/inner.svg";
import OUTERJOIN from "../../../../../assets/images/mergeJoins/outer.svg";
import LEFTJOIN from "../../../../../assets/images/mergeJoins/left.svg";
import RIGHTJOIN from "../../../../../assets/images/mergeJoins/right.svg";
import INNERJOINACTIVE from "../../../../../assets/images/mergeJoins/inner-active.svg";
import OUTERJOINACTIVE from "../../../../../assets/images/mergeJoins/outer-active.svg";
import LEFTJOINACTIVE from "../../../../../assets/images/mergeJoins/left-active.svg";
import RIGHTJOINACTIVE from "../../../../../assets/images/mergeJoins/right-active.svg";
import { Literals } from '../../../common/literals';
import { CHECK } from '../../../../Common/iconSource';
import { useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import { getObjectLength } from '../../../common/helperFunctions';
import { cloneDeep } from 'lodash';

const MERGE_TYPE = [{
    id: 1,
    Img: (selectedType) => selectedType?.name === Literals?.LEFT_JOIN ? <img src={LEFTJOINACTIVE} alt={selectedType?.join_type} /> :
        <img src={LEFTJOIN} alt={selectedType?.join_type} />,
    name: Literals.LEFT_JOIN,
    value: 'LEFT OUTER JOIN',
},
{
    id: 2,
    Img: (selectedType) => selectedType?.name === Literals?.INNER_JOIN ? <img src={INNERJOINACTIVE} alt={selectedType?.join_type} /> : <img src={INNERJOIN} alt={selectedType?.join_type} />,
    name: Literals.INNER_JOIN,
    value: 'INNER JOIN',
},
{
    id: 3,
    Img: (selectedType) => selectedType?.name === Literals?.RIGHT_JOIN ? <img src={RIGHTJOINACTIVE} alt={selectedType?.join_type} /> : <img src={RIGHTJOIN} alt={selectedType?.join_type} />,
    name: Literals.RIGHT_JOIN,
    value: 'RIGHT OUTER JOIN',
},
{
    id: 4,
    Img: (selectedType) => selectedType?.name === Literals?.OUTER_JOIN ? <img src={OUTERJOINACTIVE} alt={selectedType?.join_type} /> : <img src={OUTERJOIN} alt={selectedType?.join_type} />,
    name: Literals.OUTER_JOIN,
    value: 'FULL OUTER JOIN',

}
]

function MergeTypeSelection({ updatedFlowRedux, isExecutePermission, setshowCloseWarning, setupdatedFlowRedux }) {
    const [selectedType, setselectedType] = useState(MERGE_TYPE[0]);
    let flowsElements = useSelector(state => state.Lab.flowsElements);
    let currentTransformer = useSelector(state => state.Lab.currentTransformer);

    useEffect(() => {

        if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

            let selectedJoinType = updatedFlowRedux?.nodes.find((item) => item.id?.toString() === currentTransformer?.id?.toString())?.content?.selectedJoin;

            if (selectedJoinType?.length) {
                let selectedJoin = MERGE_TYPE?.find(item => item.value === selectedJoinType);

                if (getObjectLength(selectedJoin)) {
                    setselectedType(selectedJoin)
                }
            }

            if (!selectedJoinType?.length) {
                updateFlowRedux(MERGE_TYPE[0])
            }
        }else {
            updateFlowRedux(MERGE_TYPE[0])

        }

    }, [updatedFlowRedux])

    useEffect(() => {
        if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

            let selectedJoinType = updatedFlowRedux?.nodes.find((item) => item.id?.toString() === currentTransformer?.id?.toString())?.content?.selectedJoin;

            if (!selectedJoinType?.length) {
                updateFlowRedux(MERGE_TYPE[0])
            }
        } else {
            updateFlowRedux(MERGE_TYPE[0])

        }
    }, [])


    const updateFlowRedux = (type) => {
     
        if (getObjectLength(type)) {


            let cloneFlowsElements = {}
            if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {
                cloneFlowsElements = cloneDeep(updatedFlowRedux);

            } else {

                cloneFlowsElements = cloneDeep(flowsElements);
            }
           
            let newNode = cloneFlowsElements?.nodes.map((item) => {
                if (item.id?.toString() === currentTransformer?.id?.toString()) {
                    item.content['selectedJoin'] = type?.value;
                }
                return item;
            }); 
          
            cloneFlowsElements['nodes'] = newNode;
            setupdatedFlowRedux(cloneFlowsElements)
        }
    }

    return (
        <div className='custom-dashed-border-bottom pt-2 merge-type-main-div'>
            <div className="d-flex align-items-center m-0 p-0 w-100 justify-content-start">
                {
                    MERGE_TYPE?.map((type) => <div key={type.id}  onClick={() => {
                        if (isExecutePermission) {
                            setselectedType(type); updateFlowRedux(type); setshowCloseWarning(true)
                        }
                    }} className={`cursor-pointer ml-0 position-relative  ${selectedType?.name === type?.name ? 'joinType-selected' : 'joinType-container'}`}>
                        {type?.Img(selectedType)}
                        <label className="label text-center  cursor-pointer w-100 joinType-title">{type?.name}</label>
                        <span className='joinType-checkmark'> <CHECK color='#FFFF' width='12' height='12' /></span>
                    </div>)
                }
            </div>
        </div>
    )
}

export default MergeTypeSelection