import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import { setSelectedRulePODList } from "../../dataCatalogue/landingPage/landingPageActions";
import {
    RESET_NEXT_PAGE_COUNTER, RESET_TABLE_RECORDS, SET_ALERT_LIST, SET_CONDITION_CRITERIA, SET_CREATE_RULE_PAGE_DEFAULT, SET_DISTINCT_ROWS, SET_DOMAIN_LIST, SET_FILTER_CRITERIA, SET_NEXT_PAGE_COUNTER, SET_PREVIEW_TABLE_RESPONSE, SET_PURPOSE_LIST, SET_RECENTRUNS_DATA, SET_RETRIVE_RULE_RESPONSE, SET_RULES_CATEGORY, SET_RULES_COMPLEX_QUERY, SET_RULES_JSON_TREE, SET_RULES_LOGICAL_RULE, SET_RULES_PREVIEW_FLAG, SET_RULES_PREVIEW_INITIAL_STATE, SET_RULES_PREVIEW_NEXT_URI, SET_RULES_PREVIEW_QUERY, SET_RULES_PREVIEW_TABLE, SET_RULES_PREVIEW_TABLE_RECORDS, SET_RULES_PREVIEW_TABLE_ROWSET, SET_RULES_PUBLISH, SET_RULES_QUERY_RESULT, SET_RULES_REFRESH, SET_RULES_RETRIEVE_DATA, SET_RULES_SCHEDULE_BODY, SET_RULES_TYPE, SET_RULE_DETAIL, SET_SEARCH_RESULT_NOT_FOUND, SET_SEARCH_VALUE, SET_SELECETED_RULES_COLUMN,
    SET_UNIQUENESS_RULES_COLUMN
} from "./rulesActionTypes";


const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const setRulesPreviewTableData = (data) => {
    return {
        type: SET_PREVIEW_TABLE_RESPONSE,
        payload: data,
    };
};

export const setSearchData = (data) => {
    return {
        type: SET_SEARCH_VALUE,
        payload: data,
    };
};

export const setCreateRulePageDefault = () => {
    return {
      type: SET_CREATE_RULE_PAGE_DEFAULT
      ,
    };
  };

export const setRulesPreviewTableS = (data) => {
    return {
        type: SET_RULES_PREVIEW_TABLE,
        payload: data,
    };
};

export const setRulesPreviewNextPageCounter = () => {
    return {
        type: SET_NEXT_PAGE_COUNTER,
        payload: {},
    };
};

export const resetRulesPreviewNextPageCounter = () => {
    return {
        type: RESET_NEXT_PAGE_COUNTER,
        payload: {},
    };
};


export const setRulesPreviewTableQuery = (data) => {
    return {
        type: SET_RULES_PREVIEW_QUERY,
        payload: data,
    };
};
export const setRulesPreviewTableFlag = (data) => {
    return {
        type: SET_RULES_PREVIEW_FLAG,
        payload: data,
    };
};
export const setRulesPreviewTableRecords = (records, replacetableRecords) => {

    return {
        type: SET_RULES_PREVIEW_TABLE_RECORDS,
        payload: records,
        replacetableRecords: replacetableRecords
    };
};

export const resetRulesPreviewTableRecords = () => {

    return {
        type: RESET_TABLE_RECORDS,
        payload: {},
    };
};
export const setRulesPreviewTableRowset = (data) => {
    return {
        type: SET_RULES_PREVIEW_TABLE_ROWSET,
        payload: data,
    };
};
export const setRulesFilterCriteria = (data) => {
    return {
        type: SET_FILTER_CRITERIA,
        payload: data,
    };
};
export const setRulesConditionCriteria = (data) => {
    return {
        type: SET_CONDITION_CRITERIA,
        payload: data,
    };
};

export const setSelectedDistinctRows = (data) => {
    return {
        type: SET_DISTINCT_ROWS,
        payload: data,
    };
};

export const searchResultNotFound = (data) => {
    return {
        type: SET_SEARCH_RESULT_NOT_FOUND,
        payload: data,
    };
};

export const setCompletnessRulesColumns = (data) => {
    return {
        type: SET_SELECETED_RULES_COLUMN,
        payload: data,
    };
};

export const setUniquenessRulesColumns = (data) => {
    return {
        type: SET_UNIQUENESS_RULES_COLUMN,
        payload: data,
    };
};

export const resetRulesPreviewTableState = () => {
    return {
        type: SET_RULES_PREVIEW_INITIAL_STATE,
        payload: {},
    };
};
export const setNextUri = (data) => {

    return {
        type: SET_RULES_PREVIEW_NEXT_URI,
        payload: { next_uri: '', query: data.query },
    };
};

export const setRuleDetail = (data) => {
    return {
        type: SET_RULE_DETAIL,
        payload: data,
    };
};

export const setRecentRunsData = (data) => {
    return {
        type: SET_RECENTRUNS_DATA,
        payload: data,
    };
}

export const setAlertList = (data) => {
    return {
        type: SET_ALERT_LIST,
        payload: data,
    };
}

export const setRulePublish = (payload) => {
    return {
        type: SET_RULES_PUBLISH,
        payload: payload,
    };
}

export const setRuleRefresh = (payload) => {
    return {
        type: SET_RULES_REFRESH,
        payload: payload,
    };
}
export const setRuleCategory = (payload) => {
    return {
        type: SET_RULES_CATEGORY,
        payload: payload,
    };
}

export const setLogicalRule = (payload) => {
    return {
        type: SET_RULES_LOGICAL_RULE,
        payload: payload,
    };
}

export const setRulesRetrieveData = (payload) => {
    return {
        type: SET_RULES_RETRIEVE_DATA,
        payload: payload,
    };
}

export const setRulesJSONTree = (payload) => {
    return {
        type: SET_RULES_JSON_TREE,
        payload: payload,
    };
}
export const setRulesComplexRuleHumanQuery = (payload) => {
    return {
        type: SET_RULES_COMPLEX_QUERY,
        payload: payload,
    };
}

export const setRulesType = (payload) => {
    return {
        type: SET_RULES_TYPE,
        payload: payload,
    };
}
export const setRetriveRulesResponse = (payload) => {
    return {
        type: SET_RETRIVE_RULE_RESPONSE,
        payload: payload,
    };
}

export const setRulesScheduleBody = (payload) => {
    return {
        type: SET_RULES_SCHEDULE_BODY,
        payload: payload,
    };
}
export const setRulesQueryResult = (payload) => {
    return {
        type: SET_RULES_QUERY_RESULT,
        payload: payload,
    };
}
export const getRulesList = () => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rules`, {})
            )
                .then((resp) => {

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                        dispatch(setLoadingStatus(false));

                    }

                })
                .catch((err) => {

                    reject(err);
                    dispatch(setLoadingStatus(false));
                })
        });
    };
};


export const getAlertList = () => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rules/alert_list/`, {})
            )
                .then((resp) => {

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                        dispatch(setLoadingStatus(false));

                    }

                })
                .catch((err) => {

                    reject(err);
                    dispatch(setLoadingStatus(false));
                })
        });
    };
};

export const getRulesPreviewTable = (payload, isSearchPaylaod) => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}preview`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        
                        if (isSearchPaylaod) {
                            dispatch(resetRulesPreviewTableRecords());
                        }
                        if (resp && resp.data && resp.data.data_record?.length) {
                            dispatch(setRulesPreviewTableData(resp.data));
                            dispatch(setNextUri(resp?.data))
                            dispatch(setRulesPreviewTableRecords(resp.data.data_record, false));
                        } else if (resp && resp.next_uri) {

                        }

                        if (resp && resp.data) {
                              dispatch(setNextUri(resp?.data))
                         
                        }
                        resolve(resp);

                    }



                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};


export const RetrieveRule = (rule_name) => {

    return (dispatch) => {
        dispatch(setLoadingStatus(true));

        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rules/${rule_name}/`, {})
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                        reject(resp.message)
                    } else {
                        if (resp && resp.data) {
                            dispatch(setRulesType(resp.data.rule_type))
                            dispatch(setRuleCategory(resp.data.rule_category))
                            dispatch(setRulesPreviewTableQuery(resp.data.query));
                            dispatch(setNextUri({ query: resp.data.query }))
                            if (resp?.data?.pod_detail) {
                                let pod_detail = resp?.data?.pod_detail;
                                let selectedPODs = [];
                                pod_detail.forEach(element => {
                                    selectedPODs.push({ podId: element.table_id, podName: element.table_name, databaseName: element.data_domain_name, notification: true, pod_name: element.table_name, description: element.pod_description, source: element.source_type, updatedOn: element.updated_on, updatedBy: element.updated_by_user?.email, dataDomain: element.data_domain_name })

                                });
                                dispatch(setSelectedRulePODList([...selectedPODs]))
                            }
                            if (resp?.data?.filter_body && resp?.data?.filter_body?.appliedFilters?.length) {
                                dispatch(setRulesFilterCriteria(resp?.data?.filter_body?.appliedFilters))
                            }
                            if (resp?.data?.filter_body && resp?.data?.filter_body?.completeness?.length) {
                                dispatch(setCompletnessRulesColumns(resp?.data?.filter_body?.completeness))
                            }
                            if (resp?.data?.filter_body && resp?.data?.filter_body?.uniqueness?.length) {
                                dispatch(setUniquenessRulesColumns(resp?.data?.filter_body?.uniqueness))
                            }
                            if (resp?.data?.filter_body && resp?.data?.filter_body?.logical) {
                                dispatch(setRulesJSONTree(resp?.data?.filter_body?.logical))
                                dispatch(setRulesComplexRuleHumanQuery(resp?.data?.filter_body?.complexRuleQuery))
                            }
                            if (resp?.data?.filter_body && resp?.data?.filter_body?.condition) {
                                dispatch(setRulesConditionCriteria(resp?.data?.filter_body?.condition))
                            }
                          
                            dispatch(setRetriveRulesResponse(resp.data))
                            resolve(resp);
                        }


                    }



                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    emitToastNotification('error', err?.message);
                    reject(err);
                })
        });
    };
};
export const getRuleDetail = (rule_name) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rules/${rule_name}/`, {})
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                    }

                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};

export const getRuleAssignee = (payload) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}rules/list_assignee_user/`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                    }

                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};
export const CreateRule = (payload) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}rules/`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {
                        emitToastNotification('success', resp.message);

                        resolve(resp);
                    }
                    dispatch(setRulePublish(false))

                })
                .catch((err) => {
                    dispatch(setRulePublish(false))

                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};

export const UpdateRule = (payload, rule_name) => {
   
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("PUT", `${BASE_URL}rules/${rule_name}/`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {
                        emitToastNotification('success', resp.message);

                        resolve(resp);
                    }
                    dispatch(setRulePublish(false))

                })
                .catch((err) => {
                    dispatch(setRulePublish(false))

                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};


export const getRecentRuns = (rule_name) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rules/${rule_name}/execution_retrieve/`, {})
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                    }

                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};

export const getErrorLog = (rule_name, payload) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}rules/${rule_name}/get_error_log/`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                    }

                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};

export const getExceptionTableData = (payload) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("POST", `${BASE_URL}preview`, payload)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                    }

                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};

export const changeBookmark = (rule_name, bookmark_type) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rules/${rule_name}/${bookmark_type}/`)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));

                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)

                    } else {

                        resolve(resp);
                    }

                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));

                    reject(err);
                })
        });
    };
};

export const commonApiCalls = ({ method, endPoint, payload }) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(fetchData(method, `${BASE_URL}${endPoint}/`, payload))
                .then((resp) => {
                    if (resp.status === "success") {
                        dispatch(setLoadingStatus(false));
                        resolve(resp)
                    } else {
                        dispatch(setLoadingStatus(false));
                        emitToastNotification('error', resp?.message);
                        reject(resp?.message);
                    }
                }).catch((err) => {
                    dispatch(setLoadingStatus(false));
                    emitToastNotification('error', err?.message);
                    reject(err);
                });
        })
    }
}

export const executeRule = (rule_name) => {
    return (dispatch) => {
        dispatch(setLoadingStatus(true));
        return new Promise((resolve, reject) => {
            dispatch(
                fetchData("GET", `${BASE_URL}rules/${rule_name}/trigger_rule/`)
            )
                .then((resp) => {
                    dispatch(setLoadingStatus(false));
                    if (resp.status !== 'success') {
                        emitToastNotification('error', resp.message)
                    } else {
                        resolve(resp);
                    }
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                    reject(err);
                })
        });
    };
};


export const setRulesDomainList = (data) => {
    return {
        type: SET_DOMAIN_LIST,
        payload: data,
    };
}

export const setRulesPurposeList = (data) => {
    return {
        type: SET_PURPOSE_LIST,
        payload: data,
    };
}

