import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { CLOSE, MERGE, SELECT, } from '../../../../../Common/iconSource'
import Buttons from '../../../../../Common/button/Buttons'
import { Literals } from '../../../../common/literals'


const MergeTypeSelection = ({ openModal, onClose, handleSelect, currentSelected, MERGE_TYPE }) => {

    const [infoGraphic, setInfoGraphic] = useState([currentSelected])

    useEffect(() => {
       
        if (currentSelected) {
            setInfoGraphic([currentSelected])
        }
    }, [currentSelected])


    return (
        <Modal size="lg" isOpen={openModal} toggle={() => onClose(false)} className="custom-modal" centered={true}>
            <ModalHeader>
                <div className="d-flex align-items-center">
                    <MERGE />
                    <h2 className="section-heading">Select Merge type</h2>
                </div>
                <div className="tab-menu right-flow-header">
                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { onClose(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                </div>
            </ModalHeader>
            <ModalBody className="p-0">
                <div className="merge-join-content">
                    <div className="join-list custom-border-right">
                        {
                            MERGE_TYPE.map(
                                item => {
                                    const { id, Img, name, InfoGraphic } = item
                                    return (
                                        <>
                                            <div
                                                key={id}
                                                onClick={() => {
                                                    setInfoGraphic([item])
                                                }}
                                                className={`join-list-item ${infoGraphic[0].id === id ? `selected-list-item` : ``}`}
                                            >
                                                <Img />
                                                <p className="label mb-0">{name}</p>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        }
                    </div>
                    <div className="infographic">
                        {
                            infoGraphic.map(
                                item => {
                                    const { id, Img, name, InfoGraphic } = item
                                    return (
                                        <>
                                            <InfoGraphic />
                                        </>)
                                }
                            )
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Buttons props={{
                    buttonText: 'Select', buttonClassName: "custom-btn-primary m-0 custom-btn btn-with-text", buttonEvent: () => {


                        handleSelect(infoGraphic)
                        onClose(false)
                    }, ImgSrc: () => <SELECT />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }} />
            </ModalFooter>
        </Modal>
    )
}

export default MergeTypeSelection