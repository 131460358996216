import { useState, useMemo, useRef, useEffect } from "react";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableHeaderRenderer } from "./DraggableHeaderRenderer";
import { Input, List, Popover, Tooltip } from "antd";
import "./Datagridtable.scss";
import { Literals } from "../../modules/common/literals";
import Buttons from "../../Common/button/Buttons";
import { v4 as uuidv4 } from "uuid";
import "react-data-grid/lib/styles.css";
import {
  CHECK,
  CLOSE,
  DOWNLOAD,
  EXITFULLSCREEN,
  FULLSCREEN,
  RESETFILTER,
  SEARCH,
} from "../../Common/iconSource";
import {
  QueryKillError,
  getObjectLength,
  getReloadErrorTemplate,
  progressBarFilterTypes,
  pxToRem,
  remToPx,
} from "../../modules/common/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadQueryResultV2,
  CheckQueryStatusV2,
  PrepareQueryResultV2,
  getPreviewTable,
  setPreviewNextPageCounter,
  resetPreviewNextPageCounter,
  setQueryError,
  getPreviewTableRowCount,
} from "../../../store/modules/dataCatalogue/preview/previewActions";
import { useStore } from "react-redux";

import Analyze from "../../modules/dataCatalogue/preview/components/Analyze";
import {
  setFilteringColumns,
  setHidingColumns,
  setSortingColumns,
  setPreviewFlag,
  setAllColumnsList,
  setSortingColumnsByUUID,
  setFilteringColumnsByUUID,
  setHideColumnsByUUID,
  setAllColumnsByUUID,
  setRowCountByUUID,
  setCurrentTableRowCount,
} from "../../../store/modules/common/DataGridTable/dataGridTableRedux";
import { CustomSkeleton } from "../skeleton/CustomSkeleton";
import ClaristaLoader from "../claristaLoader/ClaristaLoader";
import { ICON_ARROW_RIGHT, ICON_EYECLOSE, ICON_EYEOPEN, ICON_LOADING_CIRCLE, ICON_PARAMETER } from "../newIconSource";
import SearchInput from "../search/Search";
import NewDataGridTable from "./newDataGridTable/NewDataGridTable";
import { ContentViewGridTable } from "../contentGridTable/ContentViewGridTable";
import { ArrowBarRight } from "react-bootstrap-icons";
import UnstructuredFileName from "../../modules/dataCatalogue/podCreation/components/UnstructuredFileName";

function isAtBottom({ currentTarget }) {
  return (
    currentTarget.scrollTop + 10 >=
    currentTarget.scrollHeight - currentTarget.clientHeight
  );
}

function getColumns({ data_header = undefined, data_dtype = undefined }) {
  if (
    data_header !== undefined &&
    data_header !== null &&
    data_header &&
    data_header.length
  ) {
    return [
      // SelectColumn,
      ...data_header?.map((header, index) => ({
        key: `${header}|${index}`,
        name: header,
        width: data_header.length > 6 ? remToPx(16.75) : undefined,
        frozen: false,
        resizable: true,
        dtype: data_dtype[index],
      })),
    ];
  }

  return [
    {
      key: "",
      name: "",
      frozen: false,
      resizable: false,
      dtype: "",
    },
  ];
}

const createTableColumns = (header, dataTypes) => {
  if (header !== undefined && header !== null) {
    let tableColumns = [];
    header &&
      header.length &&
      header.forEach((ele, i) => {
        tableColumns.push({
          Name: ele,
          Type: dataTypes.length > 0 ? dataTypes[i] : "",
          Label: ele,
          columnValue: `'${ele}'`,
          checked: true,
        });
      });
    return tableColumns;
  } else {
    return [];
  }
};

/* function createRows(data) {
  if (data !== undefined && data !== null) {
    const rows = data?.data_record?.map((record) => {
      const row = {};

      data.data_header.forEach((header, index) => {
        let cell_data = record[index];
        if (record[index] !== null) {
          row[`${header}|${index}`] = (
            <Tooltip placement="topLeft" title={cell_data?.toString()}>
              {cell_data.toString()}
            </Tooltip>
          );
        } else if (record[index] === null) {
          row[`${header}|${index}`] = "null";
        } else {
          row[`${header}|${index}`] = record[index];
        }
      });

      return row;
    });

    return rows;
  } else {
    return [];
  }
} */

const textDataTypes = [
  "char",
  "varchar",
  "string",
  "text",
  "binary",
  "map",
  "varbinary",
];

/**
 *   setPreviewNextPageCounter, resetPreviewNextPageCounter :: Deprecated :: Using [pageNo, setPageNo] State.
 * 
 * 
 */

const DataGridTable = ({
  resp,
  CurrentPODName = () => { },
  CurrentDomainName = () => { },
  isSplitPane,
  isShowFilter = true,
  setshowLoader = () => { },
  showLoader = false,
  isEnableAnalyze = false,
  isDisableHideBtn = false,
  isPreviewPage = false,
  isShowHeader = true,
  isPreviewDataAlreadyLoading = false,
  setPreviewAlreadyLoading = () => { },
  requestedTableId = "",
  uniqUUID,
  fromRoute = "",
  splitSize = "",
  setUniqUUID = () => { },
  isLoadingMore = () => { },
  showDictionaryDetails = false,
  dictSchemaData = [],
  showTechnicalTerm = false,
  columnsRegex = [],
  isDataGridReady = true,
  gridHeight,
  stopColumnReorder = false,
  columnNameToFix = undefined,
  isSmallTable = false,
  parentError = undefined,
  hideReloadBtn = false,
  parentQuery = undefined,
  autoAdjustColWidth = false,
  smallRowHeight = false,
  isCustomSql = false,
  manageMultiple = false, /** This is implemented to manage the screen where multiple DataGrid are in use, to get correct rowCount of respective api call */
  isUnstructured = false,
  connectionName,
  connectionType,
  parentQueryKilled,
  getLatestData = () => {},
  storedFilter = {},
  parentPageNo,
  downloadQuery,
  ...props
}) => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const store = useStore();
  const { isAnalyzeModalVisible } = useSelector(
    (state) => state.DataCatalogue.PreviewPage
  );
  // const rowCount = useSelector((state) => state.CommonComponent.DataGridTable.rowCount)
  const [rows, setRows] = useState(() =>
    createRows(
      resp !== undefined
        ? resp
        : { data_header: [], data_dtype: [], data_record: [] }
    )
  );
  const [columns, setColumns] = useState(() =>
    resp === null
      ? []
      : getColumns(
        resp !== undefined
          ? resp
          : { data_header: [""], data_dtype: [""], data_record: [] }
      )
  );
  const [initialFilterColumn, setInitialFilterColumn] = useState(() =>
    createTableColumns(resp?.data_header, resp?.data_dtype)
  );

  const rowCountMap = useSelector(
    (state) => state?.CommonComponent?.DataGridTable?.rowCountMap
  );

  const rowCountUseSelector = useSelector(
    (state) => state?.CommonComponent?.DataGridTable?.rowCount
  );
  
  const allColumnsSelector = useSelector(
    (state) => state?.CommonComponent?.DataGridTable?.allColsArrayMap
  );
  const queryMap = useSelector(
    (state) => state?.CommonComponent?.DataGridTable?.queryMap
  );
  
  const [originalColumns, setOriginalColumn] = useState((() => {
      if(allColumnsSelector[uniqUUID] && allColumnsSelector[uniqUUID]?.length) return allColumnsSelector[uniqUUID]
      return createTableColumns(resp?.data_header, resp?.data_dtype)
    })()
  );

  const [tableRowCount, setTableRowCount] = useState((() => {
    
      return rowCountMap[uniqUUID] ?? [0]
      // if(rowCountMap[uniqUUID] && rowCountMap[uniqUUID]?.length) return rowCountMap[uniqUUID]
      // return manageMultiple ? rowCountMap[uniqUUID] ?? [0] : rowCountUseSelector
      
    })()
  );

  const [disableHideFieldApplyBtn, setdisableHideFieldApplyBtn] =
    useState(false);
  const [paginateApiCall, setpaginateApiCall] = useState(false);
  const [showParamsPop, setParamsPop] = useState(false);
  const [showHideFieldPop, setHideFieldPop] = useState(false);
  const [sortArray, setsortArray] = useState([]);
  const [filterArray, setfilterArray] = useState([]);
  const [hideArray, setHideArray] = useState([]);
  const [downloadFileName, setDownloadFileName] = useState(null);
  const [recheckDownloadStatus, setrecheckDownloadStatus] = useState(false);
  const [isDownloadResult, setisDownloadResult] = useState(false);
  const [expandTable, setExpandTable] = useState(false);
  const [isAnyColumnHidden, setIsAnyColumnHidden] = useState(false);
  const [disableDownload, setDisableDownload] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [autoScroll, setAutoScroll] = useState(false);
  const [searchHideFields, setSearchHideFields] = useState("");
  const [currentRowsCount, setCurrentRowsCount] = useState(rows && rows.length);
  const [scrollValue, setScrollValue] = useState(currentRowsCount); // in use for auto scroll after load more data.
  const [tableUUID, setTableUUID] = useState(uniqUUID);
  const [progressFilterColName, setProgressFilterColName] = useState(window?.history?.state?.col_name ?? "");
  const [progressFilterType, setProgressFilterType] = useState(window?.history?.state?.healthy === undefined ? "" : window?.history?.state?.healthy ? 'healthy' : 'unhealthy');
  const [progressFilterRegex, setProgressFilterRegex] = useState("");
  const [isReady, setReady] = useState(true);
  const [error, setError] = useState(undefined);
  const [showAllCols, setShowAllCols] = useState(true);
  const [pageNo, setPageNo] = React.useState((() => {
      return parentPageNo ?? 0
    })()
  )

  const progressBarDataMap = useRef({});
  const progressFilterStatusRef = useRef({ current: false });

  let activeTab = store.getState().CommonComponent?.DataGridTable?.activeTabKey;
  let tableUUIDRedux = store.getState().CommonComponent?.DataGridTable?.uuid;

  const previewFlag = useSelector(
    (state) => state.CommonComponent?.DataGridTable?.previewFlag
  );
  const previewTableResponse = useSelector(
    (state) => state.DataCatalogue?.PreviewPage?.previewPageData
  );
  const previewDataMap = useSelector(
    (state) => state.DataCatalogue?.PreviewPage?.previewDataMap
  );
  const isApiError = useSelector(
    (state) => state.DataCatalogue?.PreviewPage?.isApiError
  );

  const podDetails = useSelector(
    (state) => state.DataCatalogue.Dictionary.dictionaryPodData
  );

  const { default_param } = podDetails

  const [params, setParams] = React.useState([])
  const [isQueryKilled, setQueryKilled] = React.useState(!!parentQueryKilled)

  // let isQueryKill = store.getState().CommonComponent?.DataGridTable?.isQueryKill;

  let childAddFilterRef = useRef();
  let childSortFilterRef = useRef();

  const colFilterRef = React.useRef((() => {
    if(storedFilter && getObjectLength(storedFilter)) {
      return storedFilter
    }
    return {}
  })()
  )
  
  const isPreviewDownloadedRef = useRef(false);

  let executedQuery = useRef("");
  // executedQuery.current = useSelector(
  //   (state) => state.CommonComponent?.DataGridTable?.query
  // );
  /* executedQuery.current = useSelector(state =>
      state.DataCatalogue?.PreviewPage?.query
  ) */
  function createRows(data) {

    const isJson = (cell_data) => {
      try { 
        return !!(JSON?.parse(cell_data))
      } catch { 
        return false 
      }
    }

    const isDoc = (json) => {
      try {
        let docNm = Object?.keys(json)?.includes('document_name')
        let docPfx = Object?.keys(json)?.includes('prefix')
        return docNm && docPfx
      }
      catch {
        return false
      }
    }

    if (data !== undefined && data !== null) {
      const rows = data?.data_record?.map((record) => {
        const row = {};
  
        data.data_header.forEach((header, index) => {
          let cell_data = record[index];
          if (record[index] !== null && isJson(cell_data) && isDoc(JSON?.parse(cell_data)) ) {
          // if (isUnstructured && record[index] !== null && `${header}|${index}` === 'document_name|0' ) {
            row[`${header}|${index}`] = (
              <UnstructuredFileName
                key={`unst-${header}|${index}`}
                dataObj={
                  (() => { try { return JSON?.parse(cell_data) } catch { return cell_data } })()
                }
                connectionName={connectionName ?? ""}
                connectionType={connectionType}
                smallRowHeight={smallRowHeight}
              />
            );
          } 
          else if (record[index] !== null) {
            row[`${header}|${index}`] = (
              <Tooltip className="custom-dgt-celldata" placement="topLeft" title={cell_data?.toString()}>
                {cell_data.toString()}
              </Tooltip>
            );
          } else if (record[index] === null) {
            row[`${header}|${index}`] = "null";
          } else {
            row[`${header}|${index}`] = record[index];
          }
        });
  
        return row;
      });
  
      return rows;
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (parentQueryKilled) {
      setQueryKilled(parentQueryKilled)
    }
  }, [parentQueryKilled])

  useEffect(() => {
    if (parentError) {
      setError(parentError)
    }
  }, [parentError])

  useEffect(() => {
    if(downloadQuery) {
      executedQuery.current = downloadQuery
    }
  }, [downloadQuery])
  useEffect(() => {
    if (isPreviewPage && default_param && default_param !== null) {
      let arr = []
      for (const key in default_param) {
        const value = default_param[key];
        const id = Object.keys(default_param).reduce((out, curr, curr_idx) => ({ ...out, [curr]: curr_idx }), {})
        arr.push({ id: id[key], key, value })
      }
      setParams([...arr])
    }

    if(window?.history?.state?.col_id) {
      setProgressFilterType(window?.history?.state?.healthy ? 'healthy' : 'unhealthy')
      setProgressFilterColName(window?.history?.state?.col_name)
    }
  }, [podDetails])

  useEffect(() => {

    setTableUUID(uniqUUID);
  }, [uniqUUID]);
  useEffect(() => {
    dispatch(setAllColumnsByUUID({uuid: uniqUUID, allColumns: originalColumns}));
    // dispatch(setAllColumnsList(originalColumns));

    return () => {
      // dispatch(setCurrentTableRowCount(''))
      dispatch(setSortingColumns([]));
      dispatch(setFilteringColumns([]));
      dispatch(setHidingColumns([]));
    };
  }, []);

  useEffect(() => {
    isLoadingMore(loadMore);
  }, [loadMore]);

  useEffect(() => {
    // console.log({parentQuery, vre: verifyUUID(), resp, rows, manageMultiple, tableUUIDRedux, uniqUUID, count: rowCountMap[uniqUUID]})
    if(manageMultiple) {
      setTableRowCount([rowCountMap[uniqUUID] ?? 0])
    }
    else {
      if (verifyUUID()) {
        if (tableUUIDRedux === uniqUUID) {
          setTableRowCount(rowCountMap[uniqUUID] ?? [0]);
          // setTableRowCount(rowCountUseSelector);
        }
      } else {
        setTableRowCount([0]);
      }
    }
  }, [manageMultiple, rowCountMap]);
  // }, [rowCountUseSelector, manageMultiple, rowCountMap]);

  useEffect(() => {
    // This row count is for auto scroll after loading more data
    setCurrentRowsCount(rows?.length);
  }, [rows]);

  let extraDetailsMapping = useRef({});
  let [extras] = useState([]);

  useEffect(() => {
    if (getObjectLength(extraDetailsMapping.current)) {
      setColumns((prev) => {
        prev = prev.map((c) => ({
          ...c,
          extra: extraDetailsMapping.current[c.name?.toLowerCase()] ?? null,
        }));
        return [...prev];
      });
    }
  }, [extras]);

  const draggableColumns = useMemo(() => {
    let bizTechMapping = {};

    const HeaderRenderer = (props, index) => {
      if (showDictionaryDetails) {
        if (dictSchemaData.length && isPreviewPage) {
          bizTechMapping = dictSchemaData.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.business_term?.toLowerCase()]: {
                technical_term: curr.technical_term,
                classifications: curr.classifications,
                category: curr.category,
              },
            }),
            {}
          );
        }

        /*************** DON'T DELETE THIS CODE, MAY BE USEFUL IN FUTURE ***************/

        // if(extraDetailsMapping.current[props.column.name?.toLowerCase()]===undefined) {
        //   extraDetailsMapping.current = {
        //     ...extraDetailsMapping.current,
        //     [props.column.name?.toLowerCase()]: null
        //   }
        //   let payload = { business_term: [props.column.name?.toLowerCase()] }
        //   dispatch(getSearchedBusinessTerms(payload, false))
        //     .then((res) => {
        //       setExtras(prev => new Set([...prev, props.column.name?.toLowerCase()]))
        //       extraDetailsMapping.current = {
        //         ...extraDetailsMapping.current,
        //         [props.column.name?.toLowerCase()]: res.data[0].data_dict_options[0] ?? null
        //       }
        //     }).catch((e) => {

        //     })
        //     .catch(() => { })
        // }

        /*************** DON'T DELETE THIS CODE, MAY BE USEFUL IN FUTURE ***************/
      }

      if (getObjectLength(bizTechMapping) > 0) {
        extraDetailsMapping.current = {
          ...extraDetailsMapping.current,
          [props.column.name?.toLowerCase()]: {
            tech_term:
              bizTechMapping[props.column.name.toLowerCase()]?.technical_term,
            data_dict_detail: {
              category_detail: {
                name: bizTechMapping[props.column.name?.toLowerCase()]
                  ?.category,
              },
              classification_detail: bizTechMapping[
                props.column.name?.toLowerCase()
              ]?.classifications?.map((v) => ({ name: v })),
            },
          },
        };
      }

      if(getObjectLength(colFilterRef?.current) !== columns 
      && colFilterRef.current[props?.column.name] === undefined) {
        colFilterRef.current[props?.column.name] = {
          sorting: null, 
          filterType: 'values', 
          filters: [], 
          operation: 'and',
          active: false, 
          dataType: props?.column?.dtype,
          invert: false,
          column_values: []
        }
      }

      return (
        <DraggableHeaderRenderer
          {...props}
          index={index}
          // onColumnsReorder={handleColumnsReorder}
          uniqUUID={uniqUUID}
          filteredArray={filterArray}
          extraDetails={
            extraDetailsMapping.current[props.column.name?.toLowerCase()]
          }
          showDictionaryDetails={showDictionaryDetails}
          showTechnicalTerm={showTechnicalTerm}
          requestedTableId={requestedTableId}
          podColumnDetail={
            podDetails?.column_detail
              ? podDetails?.column_detail?.find(
                (v) =>
                  v.column_name.toLowerCase() ===
                  props.column.name?.toLowerCase()
              )
              : dictSchemaData.find(
                (v) =>
                  v?.business_term.toLowerCase() ===
                  props?.column.name?.toLowerCase()
              )?.column_detail
          }
          columnRegex={
            columnsRegex.find(
              (v) =>
                v?.column_name.toLowerCase() ===
                props?.column.name?.toLowerCase()
            )?.regex ?? ""
          }
          handleProgressBar={progressBarHandler}
          handleResetProgressFilter={handleResetProgressFilter}
          progressFilterColName={progressFilterColName}
          progressFilterType={progressFilterType}
          progressBarDataMapRef={progressBarDataMap.current}
          stopColumnReorder={stopColumnReorder}
          colToHighlight={columnNameToFix}
          colFilterRef={colFilterRef}
          handleApplyFilters={handleApplyFilters}
          isEnableResetAll={isEnableResetAll()}
          onClearFilter={() => {
            ResetAllFilter();
            showAllColumns();
          }}
          domainName={CurrentDomainName?CurrentDomainName():()=>{}}
          tableName={CurrentPODName? CurrentPODName():()=>{}}
          isShowFilter={isShowFilter}
          isShowHeader={isShowHeader}
          isCustomSql={isCustomSql}
          staticQuery={parentQuery}
        />
      );
    };

    // column re-ordering
    // const handleColumnsReorder = (sourceKey, targetKey) => {
    //   const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
    //   const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);

    //   const reorderedColumns = [...columns];
    //   if (!stopColumnReorder) {
    //     reorderedColumns.splice(
    //       targetColumnIndex,
    //       0,
    //       reorderedColumns.splice(sourceColumnIndex, 1)[0]
    //     );
    //   }

    //   setColumns(reorderedColumns);
    // };

    return columns.map((c, i) => (
      {
        ...c,
        renderHeaderCell: (props) => HeaderRenderer(props, i),
        // headerRenderer: (props) => HeaderRenderer(props, i),
        frozen: columnNameToFix ? c?.name?.toLowerCase() === columnNameToFix?.toLowerCase()
                : false,
        width:  isSmallTable 
                ? columns.length === 3 ? '33.33%' : columns.length > 6 ? remToPx(15.6) : undefined 
                : autoAdjustColWidth && columns.length <= 7 
                  ? (() => {
                      /**
                       * Calculating Height Manually
                       */
                      const clientWidth = gridRef?.current?.element?.clientWidth
                      if(clientWidth)
                      return Number((clientWidth/columns?.length).toFixed(0))
                      return c?.width
                    })()
                  : c?.width
      }));
  }, [columns, uniqUUID, dictSchemaData, progressFilterColName, progressFilterType]);

  useEffect(() => {
    if (resp !== undefined && resp !== null) {
      setInitialFilterColumn(
        createTableColumns(resp?.data_header, resp?.data_dtype)
      );
      setColumns(getColumns(resp));
      setRows(createRows(resp));
    } else {
      // setTableRowCount([rowCount])
    }
  }, [resp]);

  // useEffect(() => {
  //   if (draggableColumns.length === 0) {
      // let reqBody = createRequestBody()
      // getPreviewTableResult(reqBody)
  //   }
  // }, [draggableColumns]);

  // infinite scroll
  const handleScroll = (event) => {
    if (isShowHeader && isShowHeader === true) {
      if (!isAtBottom(event)) return;

      if (!paginateApiCall) {
        // setshowLoader(true)

        /*----------To Stop Unnecessary api call on horizontal scroll---------*/
        /**/ const clientHeight = gridRef?.current?.element?.clientHeight
        /**/ const scrollHeight = gridRef?.current?.element?.scrollHeight
        /**/ if (scrollHeight <= clientHeight) {
        /**/    return
        /**/ }
        /*----------To Stop Unnecessary api call on horizontal scroll---------*/

        let payload;
        // let payload = createRequestBody();
        if (progressFilterColName !== "" && progressFilterType !== "") {
          payload = createRequestBody(uniqUUID, true, {
            type: progressFilterType,
            col_name: progressFilterColName,
            regex: progressFilterRegex,
          });
        } else {
          payload = createRequestBody();
        }
        // if (payload.query !== "") {
        setLoadMore(true);
        // let currentNextPageCounter =
        //   store.getState().DataCatalogue?.PreviewPage?.pageCounter;
        // payload["page"] = currentNextPageCounter + 1;

        payload["page"] = pageNo + 1

        dispatch(setPreviewNextPageCounter());
        setpaginateApiCall(true);
        dispatch(getPreviewTable(payload, false, false))
          .then((resp) => {
            if (resp && resp?.data?.result?.data_record?.length) {
              setshowLoader(false);
              setLoadMore(false);
              setpaginateApiCall(false);
              setTableUUID(resp?.data?.uuid);
              setColumns(getColumns(resp?.data?.result));
              setRows(() => {
                return [...rows, ...createRows(resp?.data?.result)]
              });

              getLatestData({
                response: resp?.data?.result,
                filterRef: colFilterRef?.current,
                pageNo: payload['page'],
                uuid: resp?.data?.uuid,
                query: executedQuery?.current,
                pagination: true
              })

              setAutoScroll(true);
              if (scrollValue === 0) {
                gridRef.current.scrollToRow(Number(0));
                setScrollValue(Number(currentRowsCount - 1));
              }
              if (scrollValue !== 0) {
                gridRef.current.scrollToRow(Number(currentRowsCount - 1));
              }
            } else {
              setpaginateApiCall(true);
              setshowLoader(false);
              setLoadMore(false);
              setAutoScroll(false);
            }
            setPageNo(prev => prev + 1)
          })
          .catch((e) => {
            setLoadMore(false);
            setpaginateApiCall(false);
            setError(e?.message)
          });
        // }
      }
    }
  };

  const getPreviewTableResult = (payload, enableShowLoader = false, delayCount = false) => {
    const currentRowCount = tableRowCount;
    // try {

      // TO HIGHLIGHT HIDE FIELD BUTTON
      let previousHideStatus = isAnyColumnHidden
      if(payload?.column_list?.length !== 0) {
        setIsAnyColumnHidden(payload?.column_list?.length < originalColumns?.length)
      }
      else setIsAnyColumnHidden(false)

      setError(undefined)
      setReady(false)
      dispatch(
        getPreviewTable(payload, isPreviewPage ? false : enableShowLoader, !delayCount)
      ).then((response) => {
        
          if (response?.status.toLowerCase() === "success" && response?.data) {
            setTableUUID(response?.data?.uuid);
            let responseData = response.data?.result;

            getLatestData({
              response: response?.data?.result,
              filterRef: colFilterRef?.current,
              pageNo: payload['page'],
              uuid: response?.data?.uuid,
              query: executedQuery?.current
            })

            if (delayCount && response.data?.result?.data_count === 200) {
              dispatch(getPreviewTableRowCount(payload)).catch((e) => { });
            }

            setColumns(getColumns(responseData));
            setInitialFilterColumn(
              createTableColumns(
                responseData.data_header,
                responseData.data_dtype
              )
            );
            setRows(createRows(responseData));
            setScrollValue(0);
            setTimeout(() => {
              setScrollValue(currentRowsCount);
            }, 1000);
            if (previewFlag === "Query") {
              dispatch(setQueryError(""));
            }
          }
          setshowLoader(false);
          setReady(true);
        })
        .catch((e) => {
          // IF API FAILED WITH HIDDEN FIELDS THEN RESTORING TO PREVIOUS STATE
          setIsAnyColumnHidden(previousHideStatus)


          setTableRowCount(currentRowCount);
          setshowLoader(false);
          if (previewFlag === "Query") {
            dispatch(setQueryError(e.message));
          }
          setReady(true);
          setError(e?.message)
        });
    // } catch (error) {
    //   setTableRowCount(currentRowCount);
    //   setshowLoader(false);
    //   setReady(true);
    // }
  };

  useEffect(() => {
    if (activeTab && activeTab !== "") {
      if (!isPreviewDataAlreadyLoading) {
        setTableRowCount([]);
      }
      setsortArray([]);
      setfilterArray([]);
      dispatch(setSortingColumns([]));
      dispatch(setFilteringColumns([]));
      dispatch(setHidingColumns([]));
      setpaginateApiCall(false);
      if (isPreviewPage === true) {
        if (activeTab && activeTab === "0") {
          dispatch(setPreviewFlag("Preview"));
          localStorage.setItem("previewFlag", "Preview");
        }
        if (activeTab && activeTab === "1") {
          dispatch(setPreviewFlag("Query"));
          localStorage.setItem("previewFlag", "Query");
        }
      }
      let newCols = [...originalColumns];
      if(!allColumnsSelector[uniqUUID] || allColumnsSelector[uniqUUID]?.length === 0) {
        newCols.forEach((ele) => {
          ele["checked"] = true;
        });
      }
      setOriginalColumn([...newCols]);
      if (!isPreviewDataAlreadyLoading) {
        if (previewDataMap?.execute_query !== null) {
          let reqBody = createRequestBody();
          getPreviewTableResult(reqBody, false);
        } else if (previewDataMap?.execute_query === null) {
          // let request = {
          //   action: 'preview_query',
          //   table_name: CurrentPODName(),
          //   data_domain_name: CurrentDomainName(),
          //   sort: [],
          //   filter: [],
          //   columns: "*",
          //   page: 0,
          // };
          // getPreviewTableResult(request)
        }
      } else if (isPreviewDataAlreadyLoading === true) {
        setPreviewAlreadyLoading(false);
      }
      if (!isPreviewPage) {
        setOriginalColumn(
          createTableColumns(resp?.data_header, resp?.data_dtype)
        );
      }
    }
  }, [activeTab]);

  const DownloadResult = () => {
    isPreviewDownloadedRef.current = false;
    setisDownloadResult(false);
    setrecheckDownloadStatus(false);

    let qry = executedQuery.current

    if(parentQuery && parentQuery !== '') {
      qry = parentQuery
    }
    else if(downloadQuery && downloadQuery !== '') {
      qry = downloadQuery
    }
    else if(qry === '') {
      qry = queryMap[uniqUUID] ?? ''
    }
    
    if (qry) {
      let prepareResult = {
        query: qry,
      };
      try {
        dispatch(PrepareQueryResultV2(prepareResult))
          .then((res) => {
            if (res?.data?.in_progress) {
              let f_name = res?.data?.file_name;
              if (f_name && f_name !== undefined) {
                setDownloadFileName(f_name);
                recheckStatusResult(f_name);
              }
            }
          })
          .catch((err) => console.error("Download failed:", err));
      } catch (error) { }
    }
  };

  const recheckStatusResult = (file_name) => {
    if (isPreviewDownloadedRef.current === true) return;
    let payload = {
      file_name: file_name ? file_name : downloadFileName,
    };
    dispatch(CheckQueryStatusV2(payload, { download: false }))
      .then((resp) => {
        setDisableDownload(true);
        if (resp?.status === "success") {
          if (resp?.data?.in_progress) {
            isPreviewDownloadedRef.current = false;
            setrecheckDownloadStatus(true);
          } else {
            isPreviewDownloadedRef.current = true;
            dispatch(DownloadQueryResultV2(payload))
              .then((resp) => {
                let content = resp;
                const file = new File([content], `${file_name}.zip`, {
                  type: "application/zip",
                });
                var FileSaver = require("file-saver");
                FileSaver.saveAs(file);
                setshowLoader(false);
                setisDownloadResult(true);
                setrecheckDownloadStatus(false);
                setDisableDownload(false);
              })
              .catch(() => {
                isPreviewDownloadedRef.current = false;
                setshowLoader(false);
                setisDownloadResult(false);
                setrecheckDownloadStatus(false);
              });
          }
        }
      })
      .catch(() => {
        setshowLoader(false);
        setisDownloadResult(false);
        setrecheckDownloadStatus(false);
        setDisableDownload(false);
      });
  };

  let downloadInterval;

  useEffect(() => {
    if (!isDownloadResult && recheckDownloadStatus) {
      downloadInterval = setInterval(() => {
        recheckStatusResult(downloadFileName);
      }, 2000);
    } else {
      setisDownloadResult(false);
      clearInterval(downloadInterval);
    }

    return () => {
      clearInterval(downloadInterval);
    };
  }, [recheckDownloadStatus]);

  useEffect(() => {
    progressFilterStatusRef.current = isProgressFilterActive;
  }, [progressFilterType, progressFilterRegex, progressFilterColName]);

  const resetProgressFilterStates = () => {
    progressFilterStatusRef.current = false;
    setProgressFilterColName("");
    setProgressFilterRegex("");
    setProgressFilterType("");
  };

  const handleResetProgressFilter = () => {
    resetProgressFilterStates();

    setScrollValue(0);
    setpaginateApiCall(false);
    setPageNo(0)
    dispatch(resetPreviewNextPageCounter());
    let payload = createRequestBody();

    setTableRowCount([]);
    getPreviewTableResult(payload, false);
  };

  const progressBarHandler = (col_name, type, col_regex) => {
    if (type === "" || col_name === "") return;

    setProgressFilterColName(col_name);
    setProgressFilterType(type);
    setProgressFilterRegex(col_regex);

    setScrollValue(0);
    setpaginateApiCall(false);
    setPageNo(0)
    dispatch(resetPreviewNextPageCounter());
    let uuid = refreshUUID();
    let payload = createRequestBody(uuid, true, {
      col_name,
      type,
      regex: col_regex,
    });

    setTableRowCount([]);
    getPreviewTableResult(payload, false);
  };

  const refreshUUID = () => {
    let uuid = uuidv4();
    setUniqUUID(uuid);
    setTableUUID(uuid);
    return uuid;
  };

  const handleCriteria = (crit, value) => {
    if(crit === 'date=' && value?.startsWith('@')) {
      return 'is'
    }
    return criteriaMap[crit] ?? crit
  }

  const criteriaMap = {
    'date=': "=",
    'date<': "<",
    'date>': ">",
    '==': "=",
    "s%": "like",
    "%s": "like"
  }

  const handleCriteriaValue = (crit, val) => {
    if(crit === "s%") return `${val}%`
    if(crit === "%s") return `%${val}`
    if(crit === "like" || crit === "not like") return `%${val}%`
    if(crit === "in" || crit === "not in") return val?.split(',')
    return val
  }

  const handleApplyFilters = () => {
    setScrollValue(0);
    setpaginateApiCall(false);
    setPageNo(0)
    dispatch(resetPreviewNextPageCounter());

    let __sort = [], __filter = []

    let object = colFilterRef?.current

    for (const key in object) {
      const element = object[key]
      if(element?.active) {
        const sortElement = object[key]?.sorting
        const filterElement = object[key]?.filters
        const valuesElement = object[key]?.column_values?.filter(v => v?.checked)?.map(v => v?.name) ?? []

        if(sortElement)
        __sort.push({column: key, sort_criteria: sortElement})

        if(filterElement && filterElement?.length > 0 && element?.filterType === 'condition')
        __filter = [
          ...__filter,
          ...filterElement?.filter(v => v?.criteria !== 'none')
          ?.map(f => ({
            column: key, 
            criteria: handleCriteria(f?.criteria, f?.value), 
            // criteria: criteriaMap[f?.criteria] ?? f?.criteria, 
            value: handleCriteriaValue(f?.criteria, f?.value),
            datatype: element?.dataType,
            operation: element?.operation,
            objCriteria: f?.criteria,
            objValue: f?.value,
            date_factor: f?.date_factor
          }))
        ]
        else if(filterElement && filterElement?.length > 0 && element?.filterType === 'values' && valuesElement?.length > 0) 
        __filter = [
          ...__filter,
          {
            column: key, 
            criteria: element?.invert ? 'not in' : 'in',  
            value: valuesElement?.includes("") ? [null, ...valuesElement] : valuesElement,
            datatype: element?.dataType,
            operation: element?.operation,
            filterByValue: true
          }
        ]
      }
        
    }
    
    let uuid = refreshUUID();

    setsortArray([...__sort]);
    // dispatch(setSortingColumns(__sort));
    dispatch(setSortingColumnsByUUID({uuid, sortArray: __sort}))

    
    setfilterArray([...__filter]);
    // dispatch(setFilteringColumns(__filter));
    dispatch(setFilteringColumnsByUUID({uuid, filterArray: __filter}))

    if(__filter?.length > 0) setTableRowCount([]);
    let payload = createRequestBody(uuid);
    setAutoScroll(false);
    getPreviewTableResult(payload, false);
  }

  const createRequestBody = (
    uuid = uniqUUID,
    fromProgressBar = false,
    progDetailObj = {
      type: progressFilterType,
      col_name: progressFilterColName,
      regex: progressFilterRegex,
    }
  ) => {
    let sortColumnList =
      store.getState().CommonComponent?.DataGridTable?.sortArrayMap?.[uuid] ?? [];
    let filterColumnList =
      store.getState().CommonComponent?.DataGridTable?.filterArrayMap?.[uuid] ?? [];
    let hiddenColumnList =
      store.getState().CommonComponent?.DataGridTable?.hideColsArrayMap?.[uuid] ?? [];
    /* let sortColumnList =
      store.getState().CommonComponent?.DataGridTable?.sortArray;
    let filterColumnList =
      store.getState().CommonComponent?.DataGridTable?.filterArray;
    let hiddenColumnList =
      store.getState().CommonComponent?.DataGridTable?.hiddenFieldsArray; */

    const isProgFilterActive =
      progDetailObj.type !== "" && progDetailObj.col_name;

    const appliedFilterCriteria =
      store.getState().Alert.CreateAlert.appliedFilters;

    // if (appliedFilterCriteria && appliedFilterCriteria.length) {
    //   filterColumnList = [...filterColumnList, ...appliedFilterCriteria];
    // }
    const currentPreviewFlag =
      store.getState().CommonComponent.DataGridTable?.previewFlag
    let actionType = "";
    
    if (currentPreviewFlag === "Preview") {
      actionType = "preview_query";
    } else if (currentPreviewFlag === "Query") {
      actionType = "execute_query";
    } else if (currentPreviewFlag === "Search") {
      actionType = "search_query";
    }
    let connectionName =
      store.getState().CommonComponent.VirtualDataTable?.activePodDetails
        ?.connectionName;
    let domainName =
      store.getState().CommonComponent.VirtualDataTable?.activePodDetails
        ?.dataDomain;

    let request = {
      uuid: uuid,
      connection_name: connectionName ? connectionName : "hive",
      schema_name: CurrentDomainName() ? CurrentDomainName() : domainName,
      table_name: CurrentPODName(),
      page: 0,
      sort_body: sortColumnList && sortColumnList.length ? sortColumnList : [],
      filter_body:
        filterColumnList && filterColumnList.length ? filterColumnList : [],
      column_list: hiddenColumnList?.length ? hiddenColumnList : [],
      table_ids: (() => {

        if(requestedTableId !== undefined && requestedTableId !== null && requestedTableId !== '') {  ///for empty  string   check
          return [requestedTableId]
        }
        return []
      })(),
    };

    if (
      fromProgressBar ||
      (isProgFilterActive && progressFilterStatusRef.current)
    ) {
      let type = progDetailObj?.type;
      let columnName = progDetailObj?.col_name;
      let columnRegex = progDetailObj?.regex;
      let columnDatatype =
        columns.find(
          (v) =>
            v?.name?.toLowerCase() === progDetailObj?.col_name?.toLowerCase()
        )?.dtype ?? "";
      if (columnDatatype.includes("(")) {
        columnDatatype = columnDatatype.split("(")[0];
      }

      request['health_column'] = [columnName]
      request['is_healthy'] = type === progressBarFilterTypes.health

      request["fromProgressBar"] = true; // Don't remove this code, is required for progress bar filtering.

      return request;
    }

    if (currentPreviewFlag === "Query") {
      request["query"] =
        store.getState().DataCatalogue.PreviewPage.sqlEditorQuery;
    }
    if (
      parentQuery 
      && parentQuery !== '' 
      && (["", null, undefined]?.includes(activeTab) || !isPreviewPage)) {
      request["query"] = parentQuery
    }
    return request;
  };

  const handleSwitch = (isChecked, columnDetails) => {
    setdisableHideFieldApplyBtn(false);
    const ogCol = originalColumns?.map((c, i) => ({ id: i, ...c }))
    let temp = [...ogCol];
    temp = temp?.map(t => ({ ...t, checked: t?.id === columnDetails?.id ? isChecked : t?.checked }))
    // temp[index]["checked"] = isChecked;

    setOriginalColumn([...temp]);

    let all_list = store.getState().CommonComponent?.DataGridTable?.allColsArrayMap[uniqUUID] ?? [];
    if (all_list?.length) {
      let findIndex = all_list.findIndex(
        (ele) => ele.Name === columnDetails.Name
      );

      if (findIndex > -1) {
        all_list[findIndex]["checked"] = isChecked;
      }

      dispatch(setAllColumnsByUUID({uuid: uniqUUID, allColumns: all_list}));
      // dispatch(setAllColumnsList(all_list));
    }
  };

  const applyHideFields = () => {
    setScrollValue(0);
    setpaginateApiCall(false);
    setPageNo(0)
    dispatch(resetPreviewNextPageCounter());
    let all_list = store.getState().CommonComponent?.DataGridTable?.allColsArrayMap[uniqUUID] ?? [];

    let addSelectedColumns = [];
    all_list.forEach((ele) => {
      if (ele.checked) {
        addSelectedColumns.push(ele.Name);
      }
    });

    /* if(addSelectedColumns.find(v => v?.toLowerCase() === progressFilterColName?.toLowerCase())) {
      if(!window.confirm(`Stats filter is active on "${progressFilterColName}", this action will reset the progress bar filter.\nDo you want to continue?`)) {
        return
      }
      else {
        resetProgressFilterStates()
      }
    } */
    if(all_list?.every(c => c?.checked)) {
      dispatch(setHideColumnsByUUID({uuid: uniqUUID, hideArray: []}));
    }
    else {
      dispatch(setHideColumnsByUUID({uuid: uniqUUID, hideArray: addSelectedColumns}));
    }
    // dispatch(setHidingColumns(addSelectedColumns));
    let payload = createRequestBody();
    getPreviewTableResult(payload, false);
  };

  const hideAllColumns = () => {
    let origCols = [...originalColumns];
    let columns = origCols.map((ele) => {
      if (
        hideSwitchCurrentState[ele?.Name]
        || columnNameToFix?.toLowerCase() === ele?.Name?.toLowerCase()
      ) return ele

      if (columnNameToFix && columnNameToFix?.toLowerCase() === ele?.Name?.toLowerCase()) {
        ele.checked = true
        return ele
      }
      ele.checked = false;
      return ele;
    });
    setInitialFilterColumn([...columns]);
    setOriginalColumn([...columns]);
    dispatch(setAllColumnsByUUID({uuid: uniqUUID, allColumns: [...columns]}));
    // dispatch(setAllColumnsList([...columns]));
    setdisableHideFieldApplyBtn(true);
  };

  const showAllColumns = () => {
    let columns = originalColumns.map((ele) => {
      ele.checked = true;
      return ele;
    });
    setOriginalColumn([...columns]);
    dispatch(setAllColumnsByUUID({uuid: uniqUUID, allColumns: [...columns]}));
    // dispatch(setAllColumnsList([...columns]));
    setdisableHideFieldApplyBtn(false);
  };

  const ResetAllFilter = () => {

    let object = colFilterRef?.current
    let obj = {}
    for (const key in object) {
      const element = object[key]
      obj[key] = {
        ...element,
        sorting: null, 
        filterType: 'values', 
        filters: [], 
        operation: 'and',
        active: false,
        invert: false,
        column_values: element?.column_values?.map(c => ({...c, checked: false})) ?? [],
        valuesLoaded: false
      }
    }
    colFilterRef.current = {...obj}

    setSearchHideFields('')
    setOriginalColumn(
      store.getState().CommonComponent?.DataGridTable?.allColsArrayMap[uniqUUID] ?? []
    );

    setScrollValue(0);
    setpaginateApiCall(false);
    setPageNo(0)
    dispatch(resetPreviewNextPageCounter());
    setHideFieldPop(false);
    setfilterArray([]);
    setsortArray([]);

    dispatch(setSortingColumnsByUUID({uuid: uniqUUID, sortArray: []}))
    dispatch(setFilteringColumnsByUUID({uuid: uniqUUID, filterArray: []}))
    dispatch(setHideColumnsByUUID({uuid: uniqUUID, hideArray: []}));

    /**
     * Delete AFTER testing
     */
    dispatch(setSortingColumns([]));
    dispatch(setFilteringColumns([]));
    dispatch(setHidingColumns([]));
    /**
     * End of Above Comment
     */

    if(parentQuery) {
      dispatch(setPreviewFlag("Query"));
      localStorage.setItem("previewFlag", "Query");
    }
    else {
      dispatch(setPreviewFlag("Preview"));
      localStorage.setItem("previewFlag", "Preview");
    }

    resetProgressFilterStates();

    let payload = createRequestBody();

    setTableRowCount([]);
    getPreviewTableResult(payload, false);
    childAddFilterRef &&
      childAddFilterRef.current &&
      childAddFilterRef.current.resetFilter();
    childSortFilterRef &&
      childSortFilterRef.current &&
      childSortFilterRef.current.resetFilter();
  };

  const hideSwitchCurrentState = useMemo(() => {
    let temp = {};
    let a = [];
    let b = [];
    let c = [];
    originalColumns &&
      originalColumns.length > 0 &&
      originalColumns.forEach((col) => {
        a.push(col?.Name);
      });
    sortArray &&
      sortArray.length > 0 &&
      sortArray.forEach((col) => {
        b.push(col?.column);
      });
    filterArray &&
      filterArray.length > 0 &&
      filterArray.forEach((col) => {
        c.push(col?.column);
      });
    a.forEach(
      (el) =>
      (temp[el] =
        b.includes(el) ||
        c.includes(el) ||
        el?.toLowerCase() === progressFilterColName?.toLowerCase())
    );
    return temp;
  }, [sortArray, filterArray, originalColumns, progressFilterColName]);

  const isEnableResetAll = () => {
    const sort = store.getState().CommonComponent?.DataGridTable?.sortArrayMap[uniqUUID] ?? [];
    const filter = store.getState().CommonComponent?.DataGridTable?.filterArrayMap[uniqUUID] ?? [];
    const hideFields =
      store.getState().CommonComponent?.DataGridTable?.hideColsArrayMap[uniqUUID] ?? [];
    const progressFilter =
      progressFilterColName !== "" && progressFilterType !== "";

    return (
      sort.length > 0 ||
      filter.length > 0 ||
      hideFields.length > 0 ||
      progressFilter
    );
  };

  const isProgressFilterActive =
    progressFilterColName !== "" && progressFilterType !== "";

  const hidePopover = {
    header: () => {
      return (
        <>
          <div className="d-flex hide-fields-popover-wrap justify-content-between card-padding padding-2">
            <p className="section-heading m-0">Hide Fields</p>
            <div className="d-flex">
              <Buttons
                props={{
                  buttonText: "",
                  buttonClassName:
                    "custom-btn-outline custom-btn btn-with-icon",
                  buttonEvent: () => {
                    setHideFieldPop(false);
                  },
                  ImgSrc: () => <CLOSE />,
                  isDisable: false,
                  buttonType: Literals.BTN_TERTIARY,
                }}
              />
            </div>
          </div>
        </>
      );
    },
    body: () => {
      const ogCol = originalColumns?.map((c, i) => ({ id: i, ...c }))
      const cols = ogCol?.filter(c => c?.Name?.toLowerCase()?.includes(searchHideFields?.toLowerCase()))
      const visiblility = ogCol?.some(c => c?.checked)

      return (
        <>
          <div className="hide-fields-popover-wrap">
            <div className="pt-3 pb-0" style={{paddingLeft: '0.875rem', paddingRight: '0.875rem'}}>
              <div className="">
                <div className="hide-field-cs custom-search">
                  <SearchInput
                    searchData={searchHideFields}
                    setSearchData={setSearchHideFields}
                    placeholder="Search here..."
                  />
                  <div className="custom-search-icon">
                    <SEARCH />
                  </div>
                  <div
                    className="custom-close-icon"
                    onClick={() => {
                      setSearchHideFields("");
                      setOriginalColumn(
                        store.getState().CommonComponent?.DataGridTable
                          ?.allColsArrayMap[uniqUUID] ?? []
                      );
                    }}
                  >
                    <CLOSE />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: "0.3125rem 0.625rem 0.625rem 0.625rem" }}>
                <div className="d-flex align-items-center p-1 show-hide-btn-prev"
                  onClick={() => {
                    setShowAllCols(!showAllCols)
                    if(showAllCols) hideAllColumns()
                    else showAllColumns()
                  }}
                >
                  <span className="hide-field-toggle-btn" style={{cursor: "pointer"}}>
                    {
                      showAllCols ?
                      <ICON_EYECLOSE height="14" width="14" color="currentColor" />
                      :
                      <ICON_EYEOPEN height="14" width="14" color="currentColor" />
                    }
                  </span>
                  <label 
                      className='position-relative dgt-hide-cols-item d-block fontSizeLabel mb-0 ml-2 text-with-ellipsis'
                      style={{fontFamily: 'InterRegular', lineHeight: '0.8125rem', cursor: 'pointer'}}
                  >
                    {
                      showAllCols ? 'Hide All' : 'Show All'
                    }
                  </label>
                </div>
            </div>
            <div className="mx-auto" style={{width: '90%', background: '#d9d9d9', height: 1}} ></div>
            <div className="h-100">
              <div
                className="overflow-auto dgt-hide-fl-ls"
                style={{ height: "11.25rem" }}
              >
                {cols.length === 0 ? (
                  <div className="p-3 text-center">No Match Found</div>
                ) : (
                  <ContentViewGridTable
                      colsNumber={1}
                      data={
                        cols?.filter(c => c?.Name?.toLowerCase()?.includes(searchHideFields?.toLowerCase()))
                    ?.map((column, index) => (
                          <React.Fragment
                            key={`prv-hide-fields-${index}-${column?.Name}`}
                          >
                              <div className='d-flex h-100 align-items-center position-relative pl-1'
                                style={{lineHeight: 1.4, paddingRight: '2.1875rem', opacity: hideSwitchCurrentState[column?.Name] || columnNameToFix?.toLowerCase() === column?.Name?.toLowerCase() ? .6 : 1}}
                              >
                                  <span
                                      className="hide-field-control-btn hide-field-toggle-btn"
                                      onClick={() => {
                                        if(hideSwitchCurrentState[column?.Name] || columnNameToFix?.toLowerCase() === column?.Name?.toLowerCase()) 
                                        return
                                        handleSwitch(!column.checked, column)
                                      }}
                                      style={
                                        hideSwitchCurrentState[column?.Name] || columnNameToFix?.toLowerCase() === column?.Name?.toLowerCase()
                                        ? {cursor: 'not-allowed', color: '#333333 !important'} : {}
                                      }
                                    >
                                        {
                                          column.checked
                                          ? <ICON_EYEOPEN height="14" width="14" color="currentColor"/>
                                          : <ICON_EYECLOSE height="14" width="14" color="currentColor"/>
                                        }
                                    </span>
                                  <label 
                                      className='position-relative dgt-hide-cols-item d-block fontSizeLabel mb-0 ml-2 text-dark text-with-ellipsis'
                                      title={column.Name}
                                      style={{fontFamily: 'InterRegular'}}
                                  >
                                      {/* {getColumnDataType(column.Type)} */}
                                      {column.Name}
                                  </label>

                                    <span style={{position: 'absolute', top: -3, right: 4}}>
                                      <span 
                                        title={`Scroll to column "${column.Name}"`}
                                        className={`col-redirect-btn
                                          ${draggableColumns?.findIndex(c => c?.name === column.Name) === -1 ? 'disabled' : ''}
                                        `}
                                        onClick={() => {
                                          let col_pos = draggableColumns?.findIndex(c => c?.name === column.Name)
                                          if(col_pos !== -1) {
                                            gridRef.current.scrollToColumn(col_pos)
                                            setHideFieldPop(false)
                                          }
                                        }}
                                      >
                                        <ArrowBarRight height={14} width={14} color="currentColor" />
                                        {/* <ICON_REDIRECT height="14" width="14" color="currentColor" /> */}
                                      </span>
                                    </span>
                              </div>
                          </React.Fragment>
                        ))
                      }
                      rowHeight={remToPx(1.8)}
                  />
                )}
              </div>
            </div>
            <div className="mx-auto mb-2" style={{width: '90%', background: '#d9d9d9', height: 1}} ></div>
            <p className='fontSizeLabel px-2 mx-1 mb-1' style={{color: originalColumns?.filter(c => c?.checked)?.length < originalColumns?.length ? '#ff7800' : '#6c757d' }}>
              {originalColumns?.filter(c => c?.checked)?.length} of {originalColumns?.length} Columns Visible
            </p>
            {/* 6c757d#ff7800 */}
            <div className="d-flex justify-content-center pt-2 pb-3">
              <Buttons
                  props={{
                    buttonText: "Apply",
                    buttonClassName:
                      "custom-btn-primary custom-btn btn-with-text ml-2",
                    buttonEvent: () => {
                      applyHideFields();
                      setHideFieldPop(false);
                    },
                    toggleBtnValue: "",
                    ImgSrc: () => <CHECK />,
                    isDisable: !visiblility || !isReady,
                    // isDisable: disableHideFieldApplyBtn,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    toggleBtnOption: null,
                  }}
                />
            </div>
          </div>
        </>
      );
    },
  };

  const paramsPopover = {
    header: () => {
      return (
        <>
          <div className="d-flex justify-content-between card-padding padding-2">
            <p className="section-heading m-0">Parameters</p>
            <div className="d-flex">
              <Buttons
                props={{
                  tooltip: "Reset To Default Values",
                  tooltipPlacement: "topLeft",
                  buttonText: "",
                  buttonClassName:
                    "custom-btn-outline custom-btn btn-with-icon",
                  buttonEvent: (e) => {
                    e.stopPropagation()
                    if (default_param && default_param !== null) {
                      let arr = []
                      for (const key in default_param) {
                        const value = default_param[key];
                        const id = Object.keys(default_param).reduce((out, curr, curr_idx) => ({ ...out, [curr]: curr_idx }), {})
                        arr.push({ id: id[key], key, value })
                      }
                      setParams([...arr])
                    }
                  },
                  ImgSrc: () => <RESETFILTER />,
                  isDisable: false,
                  buttonType: Literals.BTN_TERTIARY,
                }}
              />
              <Buttons
                props={{
                  buttonText: "",
                  buttonClassName:
                    "custom-btn-outline custom-btn btn-with-icon",
                  buttonEvent: () => {
                    setParamsPop(false);
                  },
                  ImgSrc: () => <CLOSE />,
                  isDisable: false,
                  buttonType: Literals.BTN_TERTIARY,
                }}
              />
            </div>
          </div>
        </>
      );
    },
    body: () => {
      return (
        <>
          <div style={{ width: 260 }}>
            <List
              className="params-li-prev"
              size="small"
              dataSource={params}
              renderItem={({ id, key, value }) => <List.Item>
                <div className="row w-100 m-0">
                  <div className="col-6 pl-0 text-with-ellipsis fontSizeHeading align-self-center">
                    <Tooltip title={key} placement="topLeft">
                      {key}
                    </Tooltip>
                  </div>
                  <div className="col-6 px-0">
                    <Input className="custom-input-field w-100"
                      value={value}
                      onChange={(e) => {
                        let val = e.target.value
                        setParams(prev => {
                          return prev?.map(p => ({
                            ...p,
                            value: id === p?.id ? val : p?.value
                          }))
                        })
                      }}
                    />
                  </div>
                </div>
              </List.Item>}
            />
            <div className='p-2 d-flex align-items-center justify-content-end border-top'>
              <Buttons
                props={{
                  buttonText: "Apply",
                  buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                  buttonEvent: (e) => {
                    e?.stopPropagation()
                    handleParams()
                  },
                  ImgSrc: () => <CHECK />,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                  isDisable: params?.some(p => p?.value?.trim() === '')
                }}
              />
            </div>
          </div>
        </>
      );
    },
  };

  const handleParams = () => {

    setScrollValue(0);
    setpaginateApiCall(false);
    setPageNo(0)
    dispatch(resetPreviewNextPageCounter());
    let uuid = refreshUUID();
    let payload = createRequestBody(uuid);

    if (isPreviewPage && default_param && getObjectLength(default_param) > 0) {
      payload['params'] = params?.filter(p => p?.value !== '')?.reduce((acc, curr) => ({ ...acc, [curr?.key]: curr?.value }), {})
    }

    setTableRowCount([]);
    getPreviewTableResult(payload, false, true);
    setParamsPop(false)
  }

  const verifyUUID = () => {
    /**
     * For Managing Multiple DataGrid Table in same page No Need to Check both the values (uniqUUID, tableUUID)
     */
    if(manageMultiple) return true
    return  uniqUUID === tableUUID
  }

  return (
    <>
      {error ? <div className="dgt-extra-err-space p-5"></div> : ''}
      {
        error ? getReloadErrorTemplate({
          hideReloadBtn: hideReloadBtn ? hideReloadBtn : false,
          errorMessage: error,
          onReload: () => {
            ResetAllFilter()
            showAllColumns()
          }
        })
          :
          <div
            className={
              expandTable
                ? "data-grid-expanded"
                : "data-grid-shrink position-relative"
            }
            style={{
              height: `${isSplitPane
                ? "calc(100% - 36px)"
                : !expandTable
                  ? showDictionaryDetails
                    ? "calc(100vh - 14.5rem)"
                    : "calc(100vh - 13rem)"
                  : showDictionaryDetails
                    ? "calc(100vh - 52px)"
                    : "calc(100vh - 40px)"
                }`,
            }}
          >
            {isShowHeader && !isQueryKilled ? (
              <div
                style={{ height: "2.1875rem", zIndex: "1", display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}
                className={`position-relative align-items-center py-1 dgt-status-pane ${draggableColumns &&
                  draggableColumns.length === 0 &&
                  !verifyUUID()
                  ? "d-none"
                  : ""
                  }`}
              >
                {isAnalyzeModalVisible ? (
                  <Analyze
                    setshowLoader={setshowLoader}
                    columns={initialFilterColumn}
                  />
                ) : (
                  ""
                )}

                <div className="">
                  <div className={"d-flex align-items-center"}>
                    {/* <div className={hideFilterOptions() ? 'd-none' : "d-flex"}> */}


                    {resp?.data_header?.[0] !== "" ? (
                      tableRowCount === null ? (
                        <p className="text-danger font-weight-bold pr-2 m-0">
                          <i className="text-danger bx bx-error ml-1"></i> Rows
                          count error
                        </p>
                      ) : tableRowCount?.flat()?.length ? (
                        <>
                          <p
                            id="prevRowCount"
                            className="fontSizeHeading custom-ellipsis one-line-ellipsis m-0 text-dark"
                          >
                            {`${tableRowCount[0]} Rows`}
                          </p>
                        </>
                      ) : (
                        <div className="">
                          <CustomSkeleton width="5rem" height="1.3125rem" />
                        </div>
                      )
                    ) : (
                      ""
                    )}
                    <div className="vertical-separator" style={{height: '1.3125rem'}}></div>
                    {resp?.data_header?.[0] !== "" ? (
                      draggableColumns.length ? (
                        <Popover
                          open={showHideFieldPop}
                          trigger={'hover'}
                          title=''
                          content={hidePopover.body}
                          placement="bottomRight"
                          arrow={false}
                          rootClassName="new-hide-field-pop"
                          onOpenChange={(bool) => setHideFieldPop(bool)}
                        >
                          <p
                            id="prevColCount"
                            className="d-flex align-items-center fontSizeHeading m-0 pr-3"
                            style={{
                              color: draggableColumns?.length < originalColumns?.length ? '#2c83c4' : '#212529'
                            }}
                          >
                            
                              {
                              isReady
                              ?
                              `${draggableColumns.length} of ${originalColumns?.length} Columns`
                              :
                              <>
                                <span className="mr-1 pr-1 col-exp-loader"><ICON_LOADING_CIRCLE height="14" width="14" /></span> of {originalColumns?.length} Columns
                              </>
                              }
                            <span className="bx-rotate-90 ml-1 col-exp-arrow"><ICON_ARROW_RIGHT height="10" width="10" color="currentColor"/></span>
                          </p>
                        </Popover>
                      ) : (
                        <>
                          <div className="pr-2">
                            <CustomSkeleton width="5rem" height="1.3125rem" />
                          </div>
                        </>
                      )
                    ) : (
                      ""
                    )}
                    {
                      isPreviewPage
                        &&
                        default_param && default_param !== null
                        && Object?.keys(default_param)?.length > 0
                        ?
                        <div className="rdgrid-tbl-filter-wrap">
                          {/* Parameter Popover */}
                          <Popover
                            placement="bottomLeft"
                            title={paramsPopover.header}
                            content={paramsPopover.body}
                            trigger="click"
                            autoAdjustOverflow={true}
                            open={showParamsPop}
                            onOpenChange={(bool) => setParamsPop(bool)}
                          >
                            <Tooltip placement="bottom" title="Parameters">
                              <button
                                id="prevParamBtn"
                                className={`custom-btn-outline custom-btn btn-with-icon active`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setParamsPop(true);
                                }}
                              >
                                <ICON_PARAMETER />
                              </button>
                            </Tooltip>
                          </Popover>
                        </div>
                        : ''
                    }
                  </div>
                </div>
                {isProgressFilterActive ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="small px-2 text-dark bg-light border rounded">
                      Showing <strong>{progressFilterType}</strong> data of
                      <Tooltip placement="topLeft" title={progressFilterColName}>
                        <strong> {progressFilterColName}</strong>
                      </Tooltip>
                      {/* {`Showing "${progressFilterType}" data of "${progressFilterColName}"`} */}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="d-flex flex-row-reverse">
                  <div className="d-flex align-items-center">
                    {isEnableResetAll() ? (
                      <Buttons
                        props={{
                          buttonText: "Reset All",
                          tooltip: "Reset All",
                          buttonClassName: `custom-btn-outline
                      custom-btn btn-with-icon mr-2`,
                          buttonEvent: () => {
                            ResetAllFilter();
                            showAllColumns();
                          },
                          ImgSrc: () => <RESETFILTER />,
                          isDisable: false,
                          buttonType: Literals.BTN_TERTIARY,
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <Buttons
                      props={{
                        buttonId: 'prevDownloadBtn',
                        tooltip: "Download",
                        buttonText: disableDownload ? "Downloading..." : "",
                        buttonClassName: `custom-btn-outline  custom-btn ${disableDownload ? "btn-with-text  " : "btn-with-icon "
                          } mr-2`,
                        buttonEvent: () => {
                          DownloadResult();
                        },
                        ImgSrc: () => disableDownload ? <ICON_LOADING_CIRCLE/> : <DOWNLOAD />,
                        isDisable: disableDownload,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                      }}
                    />

                    {
                      <Tooltip
                        placement={"bottomRight"}
                        title={
                          expandTable ? "Exit Full Screen" : "Toggle Full Screen"
                        }
                      >
                        <button
                          id="prevExpandBtn"
                          className={`custom-btn-outline custom-btn btn-with-icon`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setExpandTable(!expandTable);
                          }}
                        >
                          {expandTable ? <EXITFULLSCREEN /> : <FULLSCREEN />}
                        </button>
                      </Tooltip>
                    }
                  </div>
                </div>
              </div>
            ) : null}
{/* {<button
  onClick={() => {
    // Uncomment And Test the Props by using this Button
    console.log({ver: verifyUUID(), 
      rows, parentQuery, resp, 
      uniqUUID, 
      rowCountMap,
      map: rowCountMap[uniqUUID],
      tableRowCount,
      manageMultiple,
      draggableColumns,
      tableUUIDRedux
    })
  }}
  style={{height: 20}}
>TEST</button>} */}
            <DndProvider backend={HTML5Backend}>
              {
                isQueryKilled ? <QueryKillError /> :

                  verifyUUID() && !isReady ? (
                    <ClaristaLoader uuid={uniqUUID} setKillQueryDone={setQueryKilled} isCancel={true} />
                  ) : draggableColumns &&
                    draggableColumns.length > 0 &&
                    isDataGridReady ? (
                      <NewDataGridTable
                        ref={gridRef}
                        columns={draggableColumns}
                        rows={rows !== undefined ? rows : []}
                        onScroll={handleScroll}
                        rowHeight={smallRowHeight ? remToPx(1.75) : remToPx(2.37)}
                        className={`rdg-light custom-data-grid-table ${isSmallTable ? draggableColumns.length <= 3 ? "hide-overflow-x" : ""
                        :
                        draggableColumns.length <= 6 ? "hide-overflow-x" : ""
                        } `}
                        headerRowHeight={
                          showDictionaryDetails
                            ? showTechnicalTerm
                              ? remToPx(6.875)
                              : remToPx(5)
                            : smallRowHeight ? remToPx(1.875) : undefined
                        }
                        style={{
                          height:
                            gridHeight
                              ? expandTable ? '100%' : gridHeight
                              :
                              fromRoute && fromRoute === "targetmodal2"
                                ? splitSize
                                : "100%",
                        }}
                      />
                  ) : (
                    <>
                      <ClaristaLoader isCancel={true} />
                    </>
                  )}
              {loadMore ? (
                <div className="load-more-dgt-wrapper">
                  <div className="load-more-dgt">
                    <i className="bx bx-loader-alt bx-spin mr-2"></i>
                    <label className="small mb-0">Loading More...</label>
                  </div>
                </div>
              ) : (
                ""
              )}
            </DndProvider>
            {showDictionaryDetails && !isQueryKilled ? (
              <div
                className="data-dict-identity-pane small position-relative within-preview"
                style={{ paddingTop: "0.3125rem" }}
              >
                <div style={{ height: "0.875rem" }}>
                  <span className="sphere tech-term"></span>
                  <label className="mb-0 font-w-600">Technical Name</label>
                </div>
                {/* <div style={{ height: "14px" }}>
                  <span className="sphere category"></span>
                  <label className="mb-0 font-w-600">Data Category</label>
                </div> */}
                <div style={{ height: "0.875rem" }}>
                  <span className="sphere classifier"></span>
                  <label className="mb-0 font-w-600">Classifier</label>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
      }
    </>
  );
};

export default DataGridTable;
