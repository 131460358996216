import { Tooltip } from 'antd'
import React from 'react'
import CustomProgressBar from '../../../Common/customProgressBar.js/CustomProgressBar'
import { useDispatch } from 'react-redux'
import { fetchColummGraphData } from '../../../../store/actions'
import { Literals } from '../../common/literals'
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton'
import { ICON_TABLE } from '../../../Common/newIconSource'

const DictColumnStat = ({
    item,
    colStats = {},
    metaData = {},
    isActive = false,
    activeTabKey
}) => {
    const dispatch = useDispatch()
    const [data, setData] = React.useState({})
    const [fetching, setFetching] = React.useState(false)
    const [errMsg, setErrMsg] = React.useState(undefined)

    React.useEffect(() => {
        if(metaData?.column_id)
        getData()
    }, [metaData])

    const getData = () => {
        if(!window.location.pathname.includes(Literals.links.DATA_DICTIONARY)
           || (activeTabKey && activeTabKey !== '2')
        ) {
            return
        }
        if(colStats?.[metaData?.column_id]) {
            setData({...colStats[metaData?.column_id]})
            return
        }
        setFetching(true)
        dispatch(fetchColummGraphData(metaData?.table_id, metaData?.column_id, false))
        .then(res => {
            if(res.status === "success" && res?.message === "Stats calculation in progress") {
                setTimeout(() => {
                    getData()
                }, 3000)
            }
            else if(res.status === "success" && res?.data?.column_stats?.length === 0) {
                setTimeout(() => {
                    getData()
                }, 3000)
            }
            else {
                // Code
                colStats[metaData?.column_id] = res?.data?.column_stats?.[0]
                setData(res?.data?.column_stats?.[0])
                setFetching(false)
            }
        })
        .catch((err) => {
            colStats[metaData?.column_id] = false
            setErrMsg(err?.message)
            setFetching(false)
        })
    }


    const progressData = React.useMemo(() => {

        let healthy = data?.health ?? 0
        
        let unhealthy = Number(100 - (data?.health ?? 0)).toFixed(2)

        return [
            {id: 0, title: 'Healthy', percent: healthy, color: '#2C83C4', onClick: () => {}},
            {id: 0, title: 'Unhealthy', percent: unhealthy, color: '#E19806', onClick: () => {}},
            // {id: 0, title: 'Missing', percent: item?.missing, color: '#444', onClick: () => {}}
        ]
    }, [data])

    return (
        <div className={`dict-prg-data ${isActive ? "rounded" : ""}`} 
            style={{
                background: isActive ? "#2c83c421" : "transparent",
                borderLeft: isActive ? 'solid 3px #2c83c4' : 'none'
            }}
            >
            <div className='text-with-ellipsis dict-color-dark fontSizeLabel fontInterSemiBold text-uppercase mb-2'>
                {/* {item?.name} asasee aaswe sae asase  */}
                <Tooltip title={item?.table_name?.toUpperCase()?.replaceAll("_", " ")} placement='topLeft'>
                    {/* {
                        isActive ? 
                        <Tooltip title={'Previewing'} placement='left'>
                            <i className='bx bxs-circle mr-1 color-primary'></i> 
                        </Tooltip>
                        : ''
                    } */}
                    {item?.table_name?.replaceAll("_", " ")}
                </Tooltip>
            </div>
            <div className='dict-stats-prg'>
                {
                    errMsg ? <Tooltip title={errMsg} placement='top'>
                            <div className='text-with-ellipsis fontSizeExtraSmall text-danger fontInterSemiBold'>
                                Error: {errMsg}
                            </div>
                        </Tooltip>
                    :
                    fetching ? 
                        <div className='mt-2'>
                            <CustomSkeleton height='10px' width='100%' />
                        </div>
                    :
                    <CustomProgressBar
                        data={progressData}
                    />
                }
            </div>
        </div>
    )
}

export default DictColumnStat