import React from 'react'
import { ICON_CLOSE, ICON_FEEDBACK, ICON_SYNONYM, ICON_USER } from '../../../Common/newIconSource'
import { Tabs } from 'antd'
import { getQueryParams, getReloadErrorTemplate } from '../../common/helperFunctions'
import { useLocation, useNavigate } from 'react-router-dom'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import '../genAi.scss'
import TalkDataTrace from './TalkDataTrace'
import TracePersona from './TracePersona'
import TraceSynonym from './TraceSynonym'
import { Literals } from '../../common/literals'
import Buttons from '../../../Common/button/Buttons'
import { useDispatch } from 'react-redux'
import { getUserSelectedDomain, setUserSelectedDomain } from '../../../../store/actions'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'

const TalkDataTraceIndex = () => {

    const history = useNavigate()

    const location = useLocation()

    const dispatch = useDispatch()

    const {dm: domainId} = getQueryParams(location.search)

    const [activeTabKey, setActiveTabKey] = React.useState('feedback')
    const [activeDomain, setActiveDomain] = React.useState('')
    const [isDomainFailed, setDomainFailed] = React.useState(false)
    const [isDomainLoading, setDomainLoading] = React.useState(false)
    const [domainError, setDomainError] = React.useState(undefined)

    React.useEffect(() => {
        getActiveDomain()
    }, [location])

    const getActiveDomain = () => {
        if(location?.state?.domain) {
            let domain_id = location?.state?.domain?.toString()
            setActiveDomain(domain_id)
            dispatch(setUserSelectedDomain({ data_domain_id: domain_id }, false))
            .catch(err => console.error(err?.message))
            return
        }

        dispatch(getUserSelectedDomain())
        .then(res => {
            const domain = res?.data?.domain ?? undefined
            if (domain && domain?.id) {
                setActiveDomain(domain?.id?.toString())
            }
            else if (domain) {
                setActiveDomain(domain?.id ?? 0)
            }
        })
        .catch(err => {
            setDomainFailed(true)
            console.error(err?.message)
        })
    }

    const tabItems = React.useMemo(() => {
        let tabs = [
                {
                    key: 'feedback',
                    label: <span>
                        <ICON_FEEDBACK color='currentColor'/> Feedback
                    </span>,
                    children: activeTabKey !== 'feedback' ? <></>
                    : <>
                        <TalkDataTrace parentDomain={activeDomain} setParentDomain={setActiveDomain} />
                    </>,
                },
                {
                    key: 'persona',
                    label: <span>
                        <ICON_USER color='currentColor'/> Persona
                    </span>,
                    children: activeTabKey !== 'persona' ? <></>
                    : <>
                        <TracePersona parentDomain={activeDomain} setParentDomain={setActiveDomain} />
                    </>,
                },
                {
                    key: 'synonym',
                    label: <span>
                        <ICON_SYNONYM color='currentColor'/> Synonym
                    </span>,
                    children: activeTabKey !== 'synonym' ? <></>
                    : <>
                        <TraceSynonym parentDomain={activeDomain} setParentDomain={setActiveDomain} />
                    </>,
                },
            ]

        return tabs

    }, [activeDomain, activeTabKey]) 

    const onTabChange = key => {
        setActiveTabKey(key)
    }

    return (
        <React.Fragment>
            <div className='talk-index-main trace-index-wrapper'>
                <LandingpageFirstHeader 
                    title={'TALKdata'} 
                    isPulse={true}
                    activeDomain={domainId}
                    showFeedNotification={true}
                />
                <div className='trace-index-tab-wrap'>
                    {
                        isDomainLoading ? <ClaristaLoader/>
                        :
                        isDomainFailed ? getReloadErrorTemplate({
                            errorMessage: domainError,
                            onReload: () => getActiveDomain()
                        })
                        :
                        <Tabs 
                            className='trace-index-tab'
                            defaultActiveKey="1" 
                            activeKey={activeTabKey}
                            items={tabItems}
                            tabBarExtraContent={<>
                                <Buttons
                                    props={{
                                        buttonText: "",
                                        tooltip: "",
                                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon border-0`,
                                        buttonEvent: () => {
                                            history(Literals.links.CLARISTA_PULSE + '#chats')
                                        },
                                        ImgSrc: () => <ICON_CLOSE/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </>}
                            onChange={onTabChange} 
                        />
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default TalkDataTraceIndex