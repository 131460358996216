import React from 'react'
import PodCreation from '../PodCreation'

const PodCreationUnstructured = () => {
  return (
    <React.Fragment>
        <PodCreation isUnstructuredConn={true} />
    </React.Fragment>
  )
}

export default PodCreationUnstructured