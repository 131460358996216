import React from 'react'
import DataGrid from 'react-data-grid'

const NewDataGridTable = React.forwardRef(({
    columns = [],
    rows = [],
    className = '',
    onScroll = () => {},
    headerRowHeight,
    rowHeight,
    style,
    defaultColumnOptions = {
        sortable: true,
        resizable: true,
        draggable: true
    }
}, ref) => {

    const [gridRows, setGridRows] = React.useState(rows)
    const [columnsOrder, setColumnsOrder] = React.useState(() => columns.map((_, index) => index))
    const [selectedRows, setSelectedRows] = React.useState(() => new Set());

    const gridRef = React.useRef(null);

    React.useImperativeHandle(ref, () => {
        return {
            scrollToColumn: (idx) => {
                gridRef?.current?.scrollToCell({ idx });
            },
            scrollToRow: (rowIdx) => {
                gridRef?.current?.scrollToCell({ rowIdx });
            },
            scrollToCell: (idx, rowIdx) => {
                gridRef?.current?.scrollToCell({ idx, rowIdx });
            },
            ...gridRef?.current
        }
    }, [])
    
    React.useEffect(() => {
        setGridRows([...rows])
    }, [rows])

    const reorderedColumns = React.useMemo(() => {
        return columnsOrder.map((index) => columns[index]);
    }, [columnsOrder]);

    const onColumnsReorder = (sourceKey, targetKey) => {
        setColumnsOrder((columnsOrder) => {
          const sourceColumnOrderIndex = columnsOrder.findIndex(
            (index) => columns[index].key === sourceKey
          );
          const targetColumnOrderIndex = columnsOrder.findIndex(
            (index) => columns[index].key === targetKey
          );
          const sourceColumnOrder = columnsOrder[sourceColumnOrderIndex];
          const newColumnsOrder = columnsOrder.toSpliced(sourceColumnOrderIndex, 1);
          newColumnsOrder.splice(targetColumnOrderIndex, 0, sourceColumnOrder);
          return newColumnsOrder;
        });
    }

    const rowKeyGetter = (row) => {
        return row.id;
    }

    const EmptyRowsRenderer = () => {
        return (
          <div className='p-2 text-black-50 text-center' style={{ gridColumn: '1/-1' }}>
            No Records Found
          </div>
        );
    }

    return (
        <>
            <DataGrid
                ref={gridRef}
                columns={reorderedColumns}
                rows={gridRows}
                rowKeyGetter={rowKeyGetter}
                onRowsChange={setGridRows}
                rowHeight={rowHeight}
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
                className={`new-custom-data-grid ${className}`}
                onScroll={onScroll}
                onCellClick={(args, event) => {
                    if (args.column.key === 'title') {
                    event.preventGridDefault();
                    args.selectCell(true);
                    }
                }}
                defaultColumnOptions={defaultColumnOptions}
                onColumnsReorder={onColumnsReorder}
                headerRowHeight={headerRowHeight}
                style={style}
                renderers={{noRowsFallback: <EmptyRowsRenderer/>}}
            />
        </>
    )
})

export default NewDataGridTable