import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'
import "./CreatePodRightSide.scss";

import {
  COLUMN, EDITOR2,
  FOLDER,
  PLAY,
  RIGHTARROW
} from "../../../../Common/iconSource";
import SearchInput from '../../../../Common/search/Search';
import { Literals } from "../../../common/literals";


import DataTabs from "../../../../Common/dataTabs/DataTabs";
import CreatePodEditor from "./CreatePodEditor";

import { message, Steps, Tooltip } from 'antd';
import { cloneDeep } from "lodash";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import {
  getCreatePodPreviewTable, setPodRightSideData, setVirtualTablesDataMap
} from "../../../../../store/actions";
import Buttons from "../../../../Common/button/Buttons";
import DataGridTable from "../../../../Common/dataGridTable/DataGridTable";
import { getConfirmationModal, getObjectLength, getTableSkeleton, showSimpleLoading } from "../../../common/helperFunctions";
import ConnectionContext from "../../../connections/landingPage/components/ConnectionContext";
import CreatePodExplorerTab from "./CreatePodExplorerTab";
import CreatePodSchema from "./CreatePodSchema";
import { CreatePodSchemaView } from "./CreatePodSchemaView";
import { getUserGroupList } from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import { useParams } from "react-router-dom";
import { saveSQlTrigger } from "../../../../../store/modules/dataCatalogue/preview/previewActions";
import { CustomSkeleton } from "../../../../Common/skeleton/CustomSkeleton";
const { Step } = Steps;


const CreatePodRightSide = ({
  setSchemaTabActive,
  rightPopulateDetails = {},
  isEditEnabled,
  isPodWithinPod,
  isReadyToExecute = false,
  uniqUUID,
  isUpdateView,
  setNewBusinessTerms = () => { },
  isSchemaTableReadyToShow,
  schemaLoadingMessage,
  current,
  setCurrent,
  showLeftPanel = true,
  connectionChanged = false
}) => {

  const dispatch = useDispatch();
  const queryEditorRef = useRef();
  const store = useStore()
  const { selectedConn } = useSelector(
    (state) => state.UserManagement.ConnectionReducer
  );
  const {
    activePodTabDataKey,
    virtualTablesDataMap,
    podRightSideData,
    podLeftSideData
  } = useSelector((state) => state.CreatePODReducer);





  const { activeCreatePodName } = virtualTablesDataMap[activePodTabDataKey];
  const { activePODKey } = podRightSideData
  const {
    virtualTableTabs = [],
    virtualTableData = {},
    explorerTableTabs = {},
  } = podRightSideData;
  const { query, queryCreatingResults } = podRightSideData;
  const [defaultPaneSize,] = useState("54vh");
  const [, setVirtualTableHeight] = useState("");
  const [editorHeight, setEditorHeight] = useState(300);
  const [searchValue, setSearchValue] = useState("")
  const [isFileExplorer, setIsFileExplorer] = useState(false);
  const [showSchemaPop, setSchemaPop] = useState(false);
  const [schemaTooltipMsg,] = useState("Navigate to Schema");
  const [, setQueryResponse] = useState(virtualTablesDataMap);
  const [, setIsEditing] = useState(false);
  const [isExecutingQuery, setIsExecutingQuery] = useState(false);
  const [isFolderViewReady, setIsFolderViewReady] = useState(false);
  const [storedResultQuery, setStoredResultQuery] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [sqlEditorReady, setSqlEditorReady] = useState(true);
  const [dataGridReady, setDataGridReady] = useState(true);
  const [openResetPopup, setOpenResetPopup] = useState(false)
  const [showSqlEditor, setShowSqlEditor] = useState(activeCreatePodName.toLocaleLowerCase() === 'results')
  const connectionContext = useContext(ConnectionContext)
  const { fileExplorer } = podLeftSideData;
  const { format } = fileExplorer
  const { podId } = useParams()
  
  const explorerVisitRef = useRef(0)

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setSearchValue("");
    setCurrent(current - 1);
  };

  useEffect(() => {
    setQueryResponse(virtualTablesDataMap)
  }, [virtualTablesDataMap]);

  useEffect(() => {
    setShowSqlEditor(() => activeCreatePodName.toLocaleLowerCase() === 'results' || activeCreatePodName.toLocaleLowerCase() === 'results-results')
  }, [activeCreatePodName])

  useEffect(() => {
    setDataGridReady(false)
    setTimeout(() => {
      setDataGridReady(true)
    },1000)
  }, [showLeftPanel])

  const executeQueryHandler = async (query) => {
    setIsExecutingQuery(true)
    try {
      let podRightData = store.getState().CreatePODReducer.podRightSideData
      let podData = cloneDeep(podRightData)
      let data;


      let payload = {
        uuid: uniqUUID,

        query: query,
      }
      data = await dispatch(getCreatePodPreviewTable(payload, true))

      let tableData = store.getState().CreatePODReducer.virtualTablesDataMap
      let virtualTblData = cloneDeep(tableData)

      virtualTblData['1']["results"]["results"] = data?.data?.result;

      virtualTblData['1'].activeCreatePodName = "results-results";
      podData.queryCreatingResults = query;
      podData.query = query;
      console.log(query)

      podData['output_regex'] = data?.data?.output_regex ?? [];
      
      setStoredResultQuery(query)
      dispatch(setVirtualTablesDataMap(virtualTblData));
      dispatch(setPodRightSideData(podData));
      setIsEditing(false)
      if (data?.data?.result?.data_record.length > 0) {
        let colsList = data?.data?.result?.data_header
        let duplicateArr = colsList.filter((col, index) => colsList.indexOf(col) !== index)
        let isDuplicateFound = duplicateArr.length > 0

        if (isDuplicateFound) {
          emitToastNotification(
            'error',
            `Duplicate column (${duplicateArr.join(', ')}) found in result, try using alias in query with different term to proceed.`,
            { timeOut: 0 }
          )
        }
        else next()
      }
      message.success('Query executed successfully!')
      setIsExecutingQuery(false)
    } catch (error) {
      message.error('Query execution failed!')

      setIsExecutingQuery(false)
    }
  };

  const resizePanesHandler = (size) => {
    const virtualTableRef = document.querySelector(".Pane2");

    if (virtualTableRef.offsetHeight > 700) {
      setVirtualTableHeight(virtualTableRef.offsetHeight - 200);
    } else {
      if (size < 0) {
        size = 0;
      }
      const maxHeight = window.innerHeight - 200;
      const btmPaneHeight = maxHeight - size;
      setVirtualTableHeight(btmPaneHeight);
    }

    setEditorHeight(size - 65);
  };

  const calculateTableHeightHandler = () => {
    let virtualTableHeight;

    if (window.innerHeight >= 750 && window.innerHeight < 1000) {
      virtualTableHeight = Math.round(window.innerHeight - 280);
    } else if (window.innerHeight >= 1000) {
      virtualTableHeight = Math.round(window.innerHeight - 250);
    } else {
      virtualTableHeight = Math.round(window.innerHeight / 2 - 70);
    }

    setVirtualTableHeight(virtualTableHeight);
  };

  const onVirtualTablePodChangeHandler = (key) => {
    let tableData = store.getState().CreatePODReducer.virtualTablesDataMap
    let virtualTblData = cloneDeep(tableData)

    const newActiveTab = virtualTableTabs.find((tab) => tab.key === key);

    if (newActiveTab.tabTitle === "Results") {
      virtualTblData['1'].activeCreatePodName = "Results";
    } else {
      virtualTblData['1'].activeCreatePodName = `${newActiveTab.schemaName}-${newActiveTab.tabTitle}`;
    }

    dispatch(setVirtualTablesDataMap(virtualTblData));
  };

  const removePodFromTableHandler = (key) => {
    let tableData = store.getState().CreatePODReducer.virtualTablesDataMap
    let virtualTblData = cloneDeep(tableData)
    let podRightData = store.getState().CreatePODReducer.podRightSideData
    let podData = cloneDeep(podRightData)

    const newVirtualTableTabs = [...virtualTableTabs];
    const index = newVirtualTableTabs.findIndex((tab) => tab.key === key);
    const pod = newVirtualTableTabs[index];
    let newActivePodName;

    if (pod) {

      if (virtualTblData['1'][pod.schemaName][pod.tabTitle]) {
        delete virtualTblData['1'][pod.schemaName][pod.tabTitle];
      }


      if (newVirtualTableTabs[index + 1]) {
        newActivePodName = `${newVirtualTableTabs[index + 1].schemaName}-${newVirtualTableTabs[index + 1].tabTitle
          }`;
      } else {
        newActivePodName = `${newVirtualTableTabs[index - 1].schemaName}-${newVirtualTableTabs[index - 1].tabTitle
          }`;
      }

      newVirtualTableTabs.splice(index, 1);
      podData.virtualTableTabs = newVirtualTableTabs;
      virtualTblData['1'].activeCreatePodName = newActivePodName;

      dispatch(setPodRightSideData(podData))
      dispatch(setVirtualTablesDataMap(virtualTblData));
    }
  };

  useEffect(() => {
    calculateTableHeightHandler();
    getUsersList()
  }, []);

  useEffect(() => {
    if (!isFileExplorer) {
      let tableData = store.getState().CreatePODReducer.virtualTablesDataMap

      let virtualTblData = cloneDeep(tableData)
      let podRightData = store.getState().CreatePODReducer.podRightSideData
      let podData = cloneDeep(podRightData)

      const [activeSchemaName, activeTableName] = virtualTblData[1].activeCreatePodName.split("-");

      const allSchemasNames = Object.keys(
        virtualTblData['1']
      ).filter((name) => name !== "results" && name !== "activeCreatePodName");

      let tabs = [...virtualTableTabs];
      let newActivePodKey = '1';


      allSchemasNames.forEach((schemaName) => {
        const tableNames = Object.keys(
          virtualTblData['1'][schemaName]
        ).filter((name) => name !== "tableCount");

        if (tableNames.length) {
          tableNames.forEach((tableName) => {
            const existingTab = virtualTableTabs.find((tab) => {
              return tab.tabTitle === tableName;
            });

            let newKey;

            if (!existingTab) {
              newKey = (+tabs[tabs.length - 1].key + 1).toString();

              tabs = [
                ...tabs,
                {
                  key: newKey,
                  tabTitle: tableName,
                  closable: false,
                  tabContent: null,
                  schemaName: schemaName,
                },
              ];

              if (tableName === activeTableName) newActivePodKey = newKey;
            } else if (tableName === activeTableName)
              newActivePodKey = existingTab.key;
          });
        }
      });


      let allExisitngTableNames = [];
      allSchemasNames.forEach((schemaName) => {
        const tableNames = Object.keys(
          virtualTblData['1'][schemaName]
        ).filter((name) => name !== "tableCount");
        allExisitngTableNames = allExisitngTableNames.concat(tableNames);
      });

      const filteredTabs = [tabs[0]];
      tabs.slice(1).forEach((tab) => {
        if (allExisitngTableNames.includes(tab.tabTitle)) {
          filteredTabs.push(tab);
        }
      });

      podData.virtualTableTabs = filteredTabs;
      podData.activePODKey = newActivePodKey;


      if (!activeSchemaName.toLowerCase().includes("results")) {

        if (virtualTblData['1'][activeSchemaName][activeTableName] === undefined) {
          podData.activePODKey = "1"
          if (virtualTblData['1']["results"]["results"] === undefined) {
            podData.virtualTableData = {};
          }
          else {
            podData.virtualTableData = virtualTblData['1']?.["results"]?.["results"];
          }
        }
        else {
          podData.virtualTableData = virtualTblData['1']?.[activeSchemaName]?.[activeTableName];
        }
      } else {
        if (Object.keys(virtualTblData['1']["results"]).length) {
          podData.virtualTableData = virtualTblData['1']["results"]["results"];
        }
        else {
          podData.virtualTableData = {};
        }
      }

      dispatch(setPodRightSideData(podData));
    }
  }, [isFileExplorer, virtualTablesDataMap]);

  useEffect(() => {
    if (getObjectLength(selectedConn) > 0) {
      setIsFileExplorer(selectedConn?.file_system);
    }
  });

  useEffect(() => {
    if (current > 0) {
      setSchemaTabActive(true)
    }
    else {
      setSchemaTabActive(false)
    }
  }, [current])

  useEffect(() => {

    if (getObjectLength(rightPopulateDetails) > 0 && !connectionChanged) {
      connectionContext.setRightSideObject(rightPopulateDetails)
      dispatch(setPodRightSideData(rightPopulateDetails))
      if (rightPopulateDetails.sourceType === 's3' || rightPopulateDetails.sourceType === 'azure_adls') {
        setIsFileExplorer(true)
        next()
      }
      else {
        if (isReadyToExecute) {
          executeQueryHandler(rightPopulateDetails.query)
        }
      }
    }
  }, [rightPopulateDetails, isReadyToExecute, connectionChanged])

  const handleSchemaPopVisible = (visible) => {
    setSchemaPop(visible)
  }

  const getUsersList = () => {
    dispatch(getUserGroupList({ method: "GET", endPoint: "profile/" }))
      .then(res => {
        let list = res?.data?.map(ele => ({ Name: `${ele?.first_name} ${ele?.last_name}`, Value: ele?.id }))
        setUsersList([...list]);
      })
      .catch(err => console.error(err))
  };

  const steps = [
    {
      key: '1',
      title: <div className="d-flex align-items-center step-title" style={{ gap: 5 }}>{isFileExplorer ? <FOLDER height="20" /> : <EDITOR2 />}<label className={`mb-0 ${isFileExplorer ? '' : 'font-weight-bold'}`}>{isFileExplorer ? "Explorer" : "Query Editor"}</label></div>,
      content: <>
        {
          isFileExplorer
            ? <CreatePodExplorerTab
              setIsFolderViewReady={setIsFolderViewReady}
              isFolderViewReady={isFolderViewReady}
              rightPopulateDetails={rightPopulateDetails}
              isEditEnabled={isEditEnabled}
              isUpdateView={isUpdateView}
              connectionChanged={connectionChanged}
              visits={explorerVisitRef}
            />
            :
            sqlEditorReady
              ?
              <>
                <CreatePodEditor
                  editorHeight={editorHeight}
                  ref={queryEditorRef}
                  fetchQueryResults={executeQueryHandler}
                  setIsEditing={setIsEditing}
                  readOnly={isExecutingQuery || !isEditEnabled}
                  isPodWithinPod={isPodWithinPod}
                  showSqlEditor={showSqlEditor}
                  setStoredResultQuery={setStoredResultQuery}
                  storedResultQuery={storedResultQuery}
                />
              </>
              : ''
        }


      </>,
    },
    {
      key: '2',
      title: <div className="d-flex align-items-center step-title" style={{ gap: 5 }}><COLUMN /><label className="mb-0">Schema</label></div>,
      content: <>{
        // isSchemaTableReadyToShow
        //   ? 
        <CreatePodSchemaView
          podId={podId}
          dictionaryRefresh={isSchemaTableReadyToShow}
          showLeftPanel={showLeftPanel}
          isReadOnly={!isEditEnabled}
          showNote={true}
          setNewBusinessTerms={setNewBusinessTerms}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          usersList={usersList}
          isUpdateView={isUpdateView}
          connectionChanged={connectionChanged}
          disableRowReorder={format && ['csv', 'txt'].includes(format.toLowerCase())}
          disableCheckbox={format && ['csv', 'txt'].includes(format.toLowerCase())}
        />
        // : showSimpleLoading(schemaLoadingMessage)
      }</>,
      // content: <><CreatePodSchema searchValue={searchValue} isEditEnabled={isEditEnabled} isUpdateView={isUpdateView} /></>,
    }
  ];
  const [sizes, setSizes] = useState(["55%", '45%']);

  const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const handleRun = () => {
    let podData = store.getState().CreatePODReducer.podRightSideData
    setStoredResultQuery(queryCreatingResults)
    let podRightData = cloneDeep(podData)
    podRightData.query = queryCreatingResults;
    podRightData.queryCreatingResults = queryCreatingResults;
    dispatch(setPodRightSideData(podRightData));
  }

  return (
    <div className="right-bar-content create-pod-rside">
      <SplitPane
        split='horizontal'
        sizes={sizes}
        onChange={setSizes}
      // split="horizontal"
      // defaultSize={defaultPaneSize}
      // maxSize={600}
      // onDragFinished={(size) => {
      //   resizePanesHandler(size);
      // }}
      >

        {
          <div style={{ ...layoutCSS }}>

            <>
              <div className="h-100 w-100 p-2 create-pod-steps">
                <Steps current={current}
                  items={
                    steps.map(item => (
                      { key: item.title, title: item.title }
                    ))
                  }
                >

                </Steps>
                <div className="border-top my-2 mx-n2"></div>
                <div className={`mt-2 position-relative steps-content-wrap ${current !== 0 ? 'with-note' : isFileExplorer ? 'file-exp-wr' : ''}  ${!showSqlEditor ? 'sql-editor-disable' : ''}`}>
                  <div className="steps-content">{steps[current]?.content}</div>
                  <div className="steps-action">
                    {current < steps.length - 1 && (
                      <Tooltip placement="left" title={schemaTooltipMsg} open={showSchemaPop} onOpenChange={handleSchemaPopVisible} >
                        <Buttons
                          props={{
                            buttonText: isFileExplorer
                              ? "Next"
                              : isExecutingQuery
                                ? "Executing..."
                                : "Run & Proceed",
                            buttonClassName:
                              "custom-btn-primary custom-btn btn-with-text ver-show",
                            buttonEvent: () => {
                              isFileExplorer
                                ? next()
                                : dispatch(saveSQlTrigger({ trigger: 'run_click' }));/* queryEditorRef.current.executeQuery(); */
                            },
                            ImgSrc: () => isFileExplorer
                              ? <RIGHTARROW />
                              : isExecutingQuery
                                ? <i className="bx bx-loader bx-spin mr-1"></i>
                                : <PLAY />,

                            isDisable: !isFileExplorer
                              ? isExecutingQuery
                              : virtualTableData
                                && Object.keys(virtualTableData).length
                                && virtualTableData?.data_record?.length > 0
                                ? false
                                : true,

                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                          }}
                        />
                      </Tooltip>
                    )}

                    {current > 0 && (
                      <div className="d-flex">
                        <div style={{ margin: "0 10px" }}>
                          <SearchInput searchData={searchValue} setSearchData={setSearchValue} />
                        </div>
                        <Buttons
                          props={{

                            buttonText: "Previous",
                            buttonClassName:
                              "custom-btn-primary custom-btn btn-with-text ver-show",
                            buttonEvent: () => {
                              setSqlEditorReady(false)
                              setTimeout(() => setSqlEditorReady(true), 500)
                              if (!isEditEnabled) {
                                prev()
                                handleRun()
                              }
                              else {
                                setOpenResetPopup(true)
                              }
                              // else if (window.confirm('This action will reset all fields of the schema. \nDo you want to proceed?')) {
                              //   prev();
                              // }
                            },
                            ImgSrc: () => <span className="prev-ic"><RIGHTARROW /></span>,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        }
        <div style={{ ...layoutCSS }}>

          <div className="virtual-table-wrapper h-100 create-pod-bottom-dgt-table w-100">
            <DataTabs
              props={{
                activeKey: isFileExplorer ? "1" : activePODKey,

                defaultKey: "1",
                onChange: isFileExplorer
                  ? () => { }
                  : onVirtualTablePodChangeHandler,
                tabArray: isFileExplorer ? explorerTableTabs : virtualTableTabs,
                onEdit: isFileExplorer ? () => { } : removePodFromTableHandler,
              }}
            />
            {Object.keys(virtualTableData).length ? (
              <div className="custom-virtual-data-table h-100 mt-2">
                {
                  dataGridReady 
                  ? <DataGridTable
                      resp={virtualTableData}
                      isSplitPane={true}
                      isShowHeader={false}
                      isEnableAnalyze={true}
                      isPreviewDataAlreadyLoading={true}
                    />
                  : getTableSkeleton(10)
                }
              </div>
            ) :
              <div className="align-items-center d-flex h-100 justify-content-center">
                <div className="text-center">
                  <div>
                    <i className="bx bx-table h1 text-black-50"></i>
                  </div>
                  <div>
                    <label className="text-black-50">No Results To Show</label>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </SplitPane>

      {
        getConfirmationModal({
          open: openResetPopup,
          setOpen: setOpenResetPopup,
          onConfirm: () => { prev(); handleRun() },
          heading: 'Confirm',
          body: <span>
            This action will reset all fields of the schema. <br/>Do you want to proceed?
          </span>
        })
      }
    </div>
  );
};

export default CreatePodRightSide;
