import React, { useEffect, useRef, useState } from "react";

import DraggableList from "../../../draggableList/index.js";
import { connect, useDispatch } from "react-redux";
import SearchInput from "../../../../../Common/search/Search";
import { updateFlowsElements } from "../../../../../../store/modules/flows/flowsActions";

import { Tooltip } from "antd";

import { emitToastNotification } from "../../../../../../helpers/toast_helper.js";
import { getInputTransformerDetails } from "../../FlowsHelperFunction.js";
import { cloneDeep, forEach } from "lodash";
import { getConfirmationModal, mergeArrayByProperty } from "../../../../common/helperFunctions.js";
import { RESETFILTER } from "../../../../../Common/iconSource.js";
import Buttons from "../../../../../Common/button/Buttons.js";
import { Literals } from "../../../../common/literals.js";

const OutcomeColumns = (props) => {
  const {
    currentTransformer,

    flowsElements,
    setdisableExcute

  } = props;
  const [searchData, setSearchData] = useState("");
  const [allChecked, setallChecked] = useState(false);
  const [draggablecolumnList, setdraggablecolumnList] = useState([]);
  const [originalcolumnList, setOriginalcolumnList] = useState([]);
  const [showResetColumns, setshowResetColumns] = useState(false)
  const [mergeShowCheckBox, setmergeShowCheckBox] = useState([]);
  const [openWarningModal, setopenWarningModal] = useState(false)
  const dispatch = useDispatch();


  // useEffect(() => {
  //   let tempDraggableList = [];
  //   let podType = "";

  //   let currTransConn = flowsElements.find(
  //     (item) => item.id === currentTransformer.id
  //   ).content;

  //   let InputTransformer = dispatch(getInputTransformerDetails())
  //   if (
  //     currTransConn &&
  //     !currTransConn?.outcomeColumns &&
  //     !currTransConn?.outcomeColumns?.length
  //   ) {
  //     InputTransformer.forEach((element) => {
  //       let transformerName = element.transformerName;

  //       podType = `${transformerName}`;
  //       let inputColumns = cloneDeep(element?.content?.outcomeColumns);
  //       if (inputColumns?.length) {
  //         inputColumns.forEach((item) => {
  //           tempDraggableList.push({
  //             id: `${item.id}_${transformerName}`,
  //             Name: item.Name,
  //             Type: item.Type,
  //             checked: false,
  //             type: podType,
  //           });
  //         });
  //       }
  //     });

  //     setdraggablecolumnList([...tempDraggableList]);

  //     setOriginalcolumnList([...tempDraggableList]);

  //   } else {
  //     let draggableList = [];
  //     let customContent = flowsElements.find(
  //       (item) => item.id === currentTransformer.id
  //     ).content;
  //     let currentOutcomeColumnPodType = []
  //     let bussinessTerm = [];

  //     if (customContent && customContent?.outcomeColumns?.length) {

  //       currentOutcomeColumnPodType = customContent.outcomeColumns.map(
  //         (ele) => ele.type
  //       );
  //       bussinessTerm = customContent.outcomeColumns.map((ele) => ele.Name);
  //     }

  //     let isNotExist = false;
  //     InputTransformer.forEach((element) => {
  //       let transformerName = element?.transformerName;
  //       podType = `${transformerName}`;
  //       if (
  //         currentOutcomeColumnPodType &&
  //         currentOutcomeColumnPodType.length &&
  //         !currentOutcomeColumnPodType.includes(transformerName)
  //       ) {
  //         isNotExist = true;

  //       }
  //     });

  //     let temp = [];
  //     InputTransformer.forEach((element) => {
  //       let inputColumns = cloneDeep(element?.content?.outcomeColumns)
  //       if (inputColumns && inputColumns.length) {
  //         inputColumns.forEach((item) => {
  //           temp.push({
  //             Name: item.Name,
  //             Type: item.Type,
  //             Label: item.Name,
  //             columnValue: item.Name,
  //             type: item.transformerType,
  //           });
  //           if (!bussinessTerm.includes(item.Name)) {
  //             isNotExist = true;
  //           }

  //         });
  //       }
  //     });

  //     if (temp.length !== customContent?.outcomeColumns?.length) {
  //       isNotExist = true;

  //     }

  //     function getCheckedValue(columnName, podType) {
  //       if (customContent?.outcomeColumns?.length) {
  //         let tempOutcomeColumns = cloneDeep(customContent?.outcomeColumns)
  //         let checkedValueIndex = tempOutcomeColumns.findIndex(
  //           (item) => item.Name === columnName && item?.type === podType
  //         )

  //         if (checkedValueIndex > -1) {

  //           return true;
  //         } else {
  //           return false
  //         }
  //       }else{
  //         return false
  //       }
  //     }

  //     if (isNotExist) {
  //       InputTransformer.forEach((element) => {
  //         let inputColumns = cloneDeep(element?.content?.outcomeColumns)
  //         if (inputColumns && inputColumns.length) {
  //           let transformerName = element?.transformerName;
  //           podType = `${transformerName}`;
  //           inputColumns.forEach((item, index) => {
  //             draggableList.push({
  //               id: `${item.id}_${transformerName}`,
  //               Name: item.Name,
  //               Type: item.Type,
  //               checked: getCheckedValue(
  //                 item.Name, podType
  //               ),
  //               type: podType,

  //             });

  //           });
  //         }
  //       });
  //     }
  //     console.log(draggableList,customContent?.outcomeColumns)
  //     if (draggableList && draggableList.length) {
  //       setdraggablecolumnList([...draggableList]);
  //       setOriginalcolumnList([...draggableList]);
  //     } else {
  //       // setOriginalcolumnList([...customContent?.outcomeColumns]);
  //       // setdraggablecolumnList([...customContent?.outcomeColumns]);
  //     }
  //     // }
  //   }
  // }, [currentTransformer]);


  useEffect(() => {

    let podType = "";



    let InputTransformer = dispatch(getInputTransformerDetails())

    let draggableList = [];
    let customContent = flowsElements.find(
      (item) => item.id === currentTransformer.id
    )?.content;


    function getCheckedValue(columnName, podType) {
      if (customContent && customContent?.outcomeColumns?.length) {
        let tempOutcomeColumns = cloneDeep(customContent?.outcomeColumns)


        if (tempOutcomeColumns[0]['Name']) {
          let checkedValueIndex = tempOutcomeColumns.findIndex(
            (item) => item.Name === columnName && item?.type === podType
          )
          if (checkedValueIndex > -1) {

            return true;
          } else {
            return false
          }
        } else if (tempOutcomeColumns[0]['text']) { // need to remove use for old flows
          return tempOutcomeColumns.find(
            (item) => item.text === columnName && item?.type === podType
          )?.checked

        }


      } else {
        return false
      }
    }
    function getErrorColumns(draggablecolumnList) {
      if (customContent && customContent?.outcomeColumns?.length) {

        let tempOutcomeColumns = cloneDeep(customContent?.outcomeColumns)
        let clonedraggablecolumnList = cloneDeep(draggablecolumnList)
        let notFoundElementList = []
        clonedraggablecolumnList.forEach(
          (item) => {
            tempOutcomeColumns = tempOutcomeColumns.filter((col) => {

              if (col['Name'] && item['Name'] !== col['Name']) {
                col['showError'] = true;
                col['checked'] = true;
                return col
              } else if (col['text'] && item['Name'] !== col['text']) {
                col['showError'] = true;
                col['checked'] = true;
                return col
              }
            })

          })
        notFoundElementList = tempOutcomeColumns.filter(item => item?.showError)
        if (notFoundElementList?.length) {
          setshowResetColumns(true)
          setdisableExcute(true)
          return [...notFoundElementList, ...draggablecolumnList]
        } else {
          setshowResetColumns(false)
          setdisableExcute(false)

          return draggablecolumnList
        }
      } else {
        return draggablecolumnList
      }
    }


    InputTransformer.forEach((element) => {
      let inputColumns = cloneDeep(element?.content?.outcomeColumns)
      if (inputColumns && inputColumns.length) {
        let transformerName = element?.transformerName;
        podType = `${transformerName}`;
        inputColumns.forEach((item, index) => {
          draggableList.push({
            id: `${item.id}_${transformerName}`,
            Name: item.Name,
            Type: item.Type,
            checked: getCheckedValue(
              item.Name, podType
            ),
            type: podType,
            showError: false

          });

        });
      }
    });

    if (draggableList && draggableList.length) {
      let allCols = getErrorColumns(draggableList)
      setdraggablecolumnList([...allCols]);
      setOriginalcolumnList([...allCols]);
    } else {

    }


  }, [currentTransformer]);


  useEffect(() => {

    if (draggablecolumnList && draggablecolumnList.length) {

      let newClone = cloneDeep(draggablecolumnList)
      let checkedList = newClone.filter(item => item.checked === true && !item.showError);
      if (checkedList?.length) {
        let list = checkedList.map(item => {
          item.checked = false;
          return item
        })
        let newflowsElements = flowsElements.map((item) => {
          if (item.id === currentTransformer.id) {
            item.content.outcomeColumns = list
          }
          return item;
        });

        dispatch(updateFlowsElements([...newflowsElements]));
      }


      setOriginalcolumnList([...draggablecolumnList]);
      setallChecked(draggablecolumnList.every((ele) => ele?.checked === true));
    }
  }, [draggablecolumnList]);

  const selectall = (checked) => {
    if (checked) {
      setallChecked(true);
      let tempOutcomeColumns = draggablecolumnList; //flowsElements.find(item => item.id === currentTransformer.id).content.outcomeColumns

      var valueArr = tempOutcomeColumns.map(function (item) {
        return item.Name;
      });
      let isDuplicateIndex = valueArr
        .map(function (item, idx) {
          if (valueArr.indexOf(item) !== idx) {
            return idx;
          }
        })
        .filter((ele) => ele && ele);

      if (isDuplicateIndex && isDuplicateIndex.length > 0) {
        let duplicateColumnNames = isDuplicateIndex.map(
          (item) => valueArr[item]
        );
        emitToastNotification(
          "error",
          `Duplicates columns in merge transformers - Please resolve following duplicates : ${duplicateColumnNames.toString()}`,
          {
            closeButton: true,
            timeOut: 0,
            extendedTimeOut: "0",
          }
        );

      }

      tempOutcomeColumns.forEach((item) => (item.checked = true));
      // let customContent = flowsElements.find(
      //   (item) => item.id === currentTransformer.id
      // ).content;
      // customContent.outcomeColumns = [...tempOutcomeColumns];

      // let newflowsElements = flowsElements.map((item) => {
      //   if (item.id === currentTransformer.id) {
      //     item.content = customContent;
      //   }
      //   return item;
      // });

      // dispatch(updateFlowsElements([...newflowsElements]));
      setdraggablecolumnList([...tempOutcomeColumns]);
      setOriginalcolumnList([...tempOutcomeColumns]);
    } else {
      setallChecked(false);
      let tempOutcomeColumns = flowsElements.find(
        (item) => item.id === currentTransformer.id
      ).content.outcomeColumns;

      tempOutcomeColumns.forEach((item) => (item.checked = false));

      // let customContent = flowsElements.find(
      //   (item) => item.id === currentTransformer.id
      // ).content;
      // customContent.outcomeColumns = [...tempOutcomeColumns];

      // let newflowsElements = flowsElements.map((item) => {
      //   if (item.id === currentTransformer.id) {
      //     item.content = customContent;
      //   }
      //   return item;
      // });
      // dispatch(updateFlowsElements([...newflowsElements]));
      setdraggablecolumnList([...tempOutcomeColumns]);
      setOriginalcolumnList([...tempOutcomeColumns]);

    }
  };

  const onChecked = (
    text,
    type,
    checkedIndex,
    id
  ) => {
    let tempOutcomeColumns = [...draggablecolumnList];
    // if (tempOutcomeColumns[checkedIndex].Type === type && tempOutcomeColumns[checkedIndex].Name === text) {
    //   setallChecked(false)

    //   tempOutcomeColumns[checkedIndex]['checked'] = !tempOutcomeColumns[checkedIndex]['checked']
    // }
    if (tempOutcomeColumns && tempOutcomeColumns.length) {
      let checkedValueIndex = tempOutcomeColumns.findIndex(
        (item) => item.id === id
      );
      if (checkedValueIndex > -1) {
      setallChecked(false)

        tempOutcomeColumns[checkedValueIndex].checked =
          !tempOutcomeColumns[checkedValueIndex].checked;
      }


    }
    // else {
    //   tempOutcomeColumns.filter(item => (item.Type === type && item.Name === text))[0]['checked'] = true
    // }
    setdraggablecolumnList([...tempOutcomeColumns]);
    setOriginalcolumnList([...tempOutcomeColumns]);
  
    //   // let customContent = flowsElements.find(
    //   //   (item) => item.id === currentTransformer.id
    //   // ).content;
    //   // customContent.outcomeColumns = [...tempOutcomeColumns];

    //   // let newflowsElements = flowsElements.map((item) => {
    //   //   if (item.id === currentTransformer.id) {
    //   //     item.content = customContent;
    //   //   }
    //   //   return item;
    //   // });
    //   setdraggablecolumnList([...tempOutcomeColumns]);
    //   setOriginalcolumnList([...tempOutcomeColumns]);

    //   // dispatch(updateFlowsElements([...newflowsElements]));
    // }
  };

  const createdraggableList = (list) => {

    if (Array.isArray(list)) {
      if (searchData.length !== 0) {
        let cloneList = cloneDeep(originalcolumnList)
        let temp = cloneList.filter((itm) =>
          itm.Name.toLowerCase().includes(searchData.toLowerCase())
        );

        return temp.length ? temp : [];
      } else {
        return originalcolumnList;
      }
    } else {
      return [];
    }
  };
  const currentConditionTypeRef = useRef(null)
  const onResetEvent = () => {

    currentConditionTypeRef.current = {
      message: 'Columns that are not found in input source that will be removed',
      onConfirm: () => {
        let cloneCols = cloneDeep(draggablecolumnList);
        cloneCols = cloneCols.filter((item) => !item.showError);
        if (cloneCols?.length) {

          setdraggablecolumnList([...cloneCols])
          setOriginalcolumnList([...cloneCols])
        }
        setopenWarningModal(false);
        setshowResetColumns(false)


        return true
      },
      onCancel: () => {
        setopenWarningModal(false);
        return false
      }
    }
    setopenWarningModal(true)
  }

  return (

    <>
      {
        getConfirmationModal({
          open: openWarningModal,
          setOpen: setopenWarningModal,
          body: currentConditionTypeRef?.current?.message,
          onConfirm: currentConditionTypeRef?.current?.onConfirm,
          onCancel: currentConditionTypeRef?.current?.onCancel
        })
      }
      <div className="d-flex justify-content-between custom-border-bottom padding-2 py-1">
        <div className="d-flex align-items-center">
          {/* merge-select-checkbox */}
          <Tooltip title="Select All">
            {" "}
            <div className=" mr-2">
              <input
                type="checkbox"
                className="cursor-pointer"
                disabled={
                  window.location.href.includes("viewFlows") ? true : false
                }
                checked={allChecked}
                onChange={(e) => selectall(e.target.checked)}
              />
            </div>
          </Tooltip>

          {showResetColumns ? (
            <Buttons
              props={{
                tooltip: 'Reset Columns',
                buttonText: "",
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2 mr-2",
                buttonEvent: onResetEvent,
                toggleBtnValue: "",
                ImgSrc: () => <RESETFILTER />,
                isDisable: window.location.href.includes("viewFlows")
                  ? true
                  : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                toggleBtnOption: null,
              }}
            />
          ) : null}
          {/* <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.39615 4.81048C8.16831 4.81048 8.79427 5.43643 8.79427 6.20859V17.7929C8.79427 18.5651 8.16831 19.191 7.39615 19.191H5.39885C4.6267 19.191 4.00073 18.5651 4.00073 17.7929V6.20859C4.00073 5.47505 4.56566 4.87344 5.28418 4.81511L5.39885 4.81048H7.39615ZM18.6019 4.81048C19.3741 4.81048 20 5.43643 20 6.20859V17.7929C20 18.5651 19.3741 19.191 18.6019 19.191H16.6046C15.8324 19.191 15.2065 18.5651 15.2065 17.7929V6.20859C15.2065 5.43643 15.8324 4.81048 16.6046 4.81048H18.6019ZM12.9886 4.80981C13.7608 4.80981 14.3867 5.43577 14.3867 6.20793V17.7923C14.3867 18.5645 13.7608 19.1904 12.9886 19.1904H10.9913C10.2192 19.1904 9.59319 18.5645 9.59319 17.7923V6.20793C9.59319 5.43577 10.2192 4.80981 10.9913 4.80981H12.9886ZM7.39615 6.00886H5.39885L5.35305 6.01413C5.26482 6.03483 5.19912 6.11404 5.19912 6.20859V17.7929C5.19912 17.9033 5.28854 17.9927 5.39885 17.9927H7.39615C7.50647 17.9927 7.59588 17.9033 7.59588 17.7929V6.20859C7.59588 6.09828 7.50647 6.00886 7.39615 6.00886ZM18.6019 6.00886H16.6046C16.4943 6.00886 16.4049 6.09828 16.4049 6.20859V17.7929C16.4049 17.9033 16.4943 17.9927 16.6046 17.9927H18.6019C18.7122 17.9927 18.8016 17.9033 18.8016 17.7929V6.20859C18.8016 6.09828 18.7122 6.00886 18.6019 6.00886ZM12.9886 6.0082H10.9913C10.881 6.0082 10.7916 6.09762 10.7916 6.20793V17.7923C10.7916 17.9026 10.881 17.992 10.9913 17.992H12.9886C13.0989 17.992 13.1883 17.9026 13.1883 17.7923V6.20793C13.1883 6.09762 13.0989 6.0082 12.9886 6.0082Z"
              fill="#1F1F3F"
            />
          </svg> */}
          <p className="paragraph ml-2">Output Columns</p>
        </div>
        <div className="d-flex align-items-center">
          <SearchInput searchData={searchData} setSearchData={setSearchData} />
        </div>
        {/* <Buttons props={{ buttonText: '', buttonClassName: "custom-btn custom-btn-outline btn-with-icon", buttonEvent: () => { }, ImgSrc: () => <SEARCH />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} /> */}
      </div>

      <div className="outcome-cols-dndlist">
        {
          flowsElements.find((item) => item.id === currentTransformer.id)?.content
            .selectedFirstSecond !== undefined &&
            //    createdraggableList([...flowsElements.find(item => item.id === currentTransformer.id).content.outcomeColumns])
            createdraggableList(draggablecolumnList)?.length > 0 ? (
            <DraggableList
              draggableList={createdraggableList(draggablecolumnList)}
              onChecked={onChecked}
              setcolumnList={setdraggablecolumnList}
              mergeShowCheckBox={mergeShowCheckBox}
              stopDraggingRow={
                window.location.href.includes("viewFlows") || searchData?.trim()?.length > 0 ? true : false
              }
              selectedFirstSecond={
                flowsElements.find((item) => item.id === currentTransformer.id)
                  .content.selectedFirstSecond
              }
            />
          ) : (
            <div className="d-flex align-items-center justify-content-center text-muted h-100">
              No Result Found
            </div>
          )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    transformerLoading: state.FlowsReducer.transformerLoading,
  };
};
export default React.memo(connect(mapStateToProps)(OutcomeColumns));
