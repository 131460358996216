import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  useReactFlow,
  useNodesState, useEdgesState, Background, MiniMap, getRectOfNodes, getTransformForBounds, useViewport
} from "reactflow";
import Menubar from "./Menubar";

import Datasource from "./transformerIcons/DatasourceTransformers";
import Target from "./transformerIcons/TargetTransformers";
import Merge from "./transformerIcons/MergeTransformers";
import Filters from "./transformerIcons/FiltersTransformers";
import Script from "./transformerIcons/ScriptTransformers";
import SQL from "./transformerIcons/SQLTransformers";
import { connect, useDispatch, useStore, useSelector } from "react-redux";
import {
  updateFlowsElements,
  setFlowsCurrenttransformer,
  showFlowsTransformdetails,

  setTransformerMaxId,
  setCurrentScriptoutputNode,
  setTransformerNames,

} from "../../../../store/modules/flows/NewFlowsRedux/flowsActions";
import ScriptOutputTransformer from "./transformerIcons/ScriptOutputTransformer";
import AlertTransformer from "./transformerIcons/AlertTransformer";
import { resetScriptRedux } from "../../../../store/modules/flows/NewFlowsRedux/scriptTransformer/scriptAction";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import SchemaTransformer from "./transformerIcons/SchemaTransformer";
import AggregateTransformer from "./transformerIcons/AggregateTransformer";
import Databricks from "./transformerIcons/DatabricksTransformer";

import 'reactflow/dist/style.css';
import BricksOutputTransformer from "./transformerIcons/BricksOutputTransformer";

import ButtonDeleteEdge from "./ButtonDeleteEdge";
import { useParams } from "react-router-dom";
import LowerMenubar from "./LowerMenubar";
import { cloneDeep } from "lodash";
import { addSelectedTransformerClass, getObjectLength, pxToRem, remToPx } from "../../common/helperFunctions";

import { toPng } from 'html-to-image';

function downloadImage(dataUrl) {
  const a = document.createElement('a');

  a.setAttribute('download', 'reactflow.png');
  a.setAttribute('href', dataUrl);
  a.click();
}

const imageWidth = 1024;
const imageHeight = 768;

const nodeTypes = {
  datasource: Datasource,
  scriptOutput: ScriptOutputTransformer,
  bricksOutput: BricksOutputTransformer,
  target: Target,
  merge: Merge,
  filters: Filters,
  schema: SchemaTransformer,
  aggregate: AggregateTransformer,
  alert: AlertTransformer,
  script: Script,
  sql: SQL,
  databricks: Databricks
}
const edgeTypes = {

  buttonedge: ButtonDeleteEdge,
};

const minimapStyle = {
  height: remToPx(pxToRem(120)),
};

function Flow(props) {
  // you can access the internal state here

  const { isViewMode, saveFlowClick, showTransformDetails, scriptOutputDataset } = props;

  const { zoomOut, getNodes, getEdges } = useReactFlow();
  const { x, y, zoom } = useViewport();
  const reactFlowInstanceuse = useReactFlow();
  const { flowId } = useParams();
  const dispatch = useDispatch();

  const { flowsElements } = props;
  const reactFlowWrapper = useRef(null);

  const scriptOutput = useRef(0);

  const store = useStore();
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const saveApiCron = useRef(null)
  useEffect(() => {
    if (flowsElements && flowsElements?.nodes?.length) {

      updateLocalState(flowsElements)
    } else {
      updateLocalState([])
    }
  }, [flowsElements]);


  const onClick = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    toPng(document.querySelector('.react-flow__viewport'), {
      backgroundColor: '#1a365d',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(downloadImage);
  };

  const getId = () => {
    let maxId = store.getState().Lab.maxTransformerId;

    return maxId + 1;
  };
  const saveNoteBookDoneFlag = useSelector(
    (state) => state.LabTransformer.Script.saveNoteBookDone
  );

  const updateLocalState = (flowsElementscopy) => {
    if (flowsElementscopy?.nodes?.length) {

      const initialNodes1 = flowsElementscopy.nodes
      const initialEdges1 = flowsElementscopy.edges
      setNodes([...initialNodes1])
      setEdges([...initialEdges1])
    } else {
      setNodes([])
      setEdges([])

    }
  }



  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {

    if (showTransformDetails) {
      reactFlowInstanceuse.fitView()
      zoomOut();
    }
  }, [showTransformDetails])



  useEffect(() => {
    handleTransform();

    if (saveFlowClick) {
      // onClick()
      dispatch(showFlowsTransformdetails(false));
      clearInterval(saveApiCron.current)
    }
  }, [saveFlowClick]);


  useEffect(() => {

    handleTransform();
  }, []);


  useEffect(() => {
    if (
      scriptOutputDataset &&
      scriptOutputDataset.length > 0 &&
      !saveNoteBookDoneFlag
    ) {
      let templist = [...flowsElements?.nodes];
      let cloneFlowsElement = { ...flowsElements }

      let yposition;

      scriptOutputDataset.forEach((ele, i) => {
        if (templist && templist.length) {
          let isExistnode = templist.findIndex((item) =>
            item.id?.includes(ele.id)
          );

          if (isExistnode && isExistnode <= -1) {
            const { newnode, edge } = createNewOutputNode(ele.id, i);
            yposition = newnode?.position?.y;
            templist.forEach((ele) => {
              if (yposition && ele.position?.y === yposition) {  // if newly calculated y position is already found change to new y postion 
                newnode.position.y = yposition + 100;

              }
            });
            if (newnode && edge) {


              let nodeIndex = templist.findIndex(
                (ele) => ele.id === newnode.id
              );
              let edgeIndex = cloneFlowsElement?.edges.findIndex((ele) => ele.id === edge.id);

              if (nodeIndex > -1) {
                cloneFlowsElement?.nodes.splice(nodeIndex, 1, newnode);
              } else {
                cloneFlowsElement?.nodes.push(newnode);
              }

              if (edgeIndex > -1) {
                cloneFlowsElement?.edges?.splice(edgeIndex, 1, edge);
              } else {
                cloneFlowsElement?.edges?.push(edge);
              }
            }
            return cloneFlowsElement;
          }
        }
      });
      if (cloneFlowsElement?.nodes.length) {

        dispatch(updateFlowsElements(cloneFlowsElement));
        updateLocalState(cloneFlowsElement)
      }
    }
  }, [scriptOutputDataset]);

  const createNewOutputNode = (elementId, index) => {

    let xposition = props.currentTransformer?.position?.x + 200;
    let yposition = props.currentTransformer?.position?.y - 200 + 100 * index;
    let allnodes = getNodes();
    if (allnodes?.length) {
      allnodes.forEach((ele) => {

        if (ele.position?.y === yposition) {  // if newly calculated y position is already found change to new y postion 
          yposition = yposition + 100;

        }
      });
    }
    let position = { x: xposition, y: yposition }

    const newNode = {
      id: elementId,
      type: "scriptOutput",
      position,
      content: {},
      transformerName: elementId?.split("|")[1]
        ? `${elementId?.split("|")[1]}`
        : `${elementId}`,
    };

    if (newNode.id !== undefined) {


      scriptOutput.current = scriptOutput.current + 1;

      let source = props?.currentTransformer?.id;
      let target = newNode.id;
      let params = {
        id: `reactflow__edge|e1_${target}`,
        source: source,
        target: target,
      };

      let cloneFlowsElement = { ...flowsElements }
      let newEdge = addEdge(params, cloneFlowsElement?.edges);
      cloneFlowsElement['edges'] = newEdge;

      return { newnode: newNode, edge: params };
    }
  };

  const edgeConnectionAllow = (targetType, target, newFlowsElements, sourceElement) => {
    let connectedEdges = getEdges().filter((item) => item.target === target);
    let connectedEdgeCount = connectedEdges?.length;
    let sourceIds = connectedEdges?.map((item) => item.source);

    if (targetType === `merge`) {
      if (connectedEdgeCount < 2) {
        let cloneFlowsElement = cloneDeep(newFlowsElements);
        let selectedFirstSecond = {};
        let content = cloneDeep(cloneFlowsElement?.nodes.find((ele) => ele.id?.toString() === target?.toString())?.content)
        if (getObjectLength(content) && content?.selectedFirstSecond && sourceIds?.length) {
          let sourceTransformerName = cloneDeep(cloneFlowsElement?.nodes.find((ele) => sourceIds?.includes(ele.id?.toString()))?.transformerName);
          selectedFirstSecond = cloneDeep(content.selectedFirstSecond)
          if (selectedFirstSecond['datasource0'] === sourceTransformerName) {
            selectedFirstSecond['datasource1'] = sourceElement?.transformerName;
          } else if (selectedFirstSecond['datasource1'] === sourceTransformerName) {
            selectedFirstSecond['datasource0'] = sourceElement?.transformerName;
          }
          cloneFlowsElement?.nodes.forEach((ele) => {
            if (ele.id?.toString() === target?.toString()) {

              ele['content']['selectedFirstSecond'] = selectedFirstSecond
            }
          })

          dispatch(updateFlowsElements(cloneFlowsElement))
        } else {
          selectedFirstSecond[`datasource${connectedEdgeCount}`] = sourceElement?.transformerName
          cloneFlowsElement?.nodes.forEach((ele) => {
            if (ele.id?.toString() === target?.toString()) {
              ele['content']['selectedFirstSecond'] = selectedFirstSecond
            }
          })
          dispatch(updateFlowsElements(cloneFlowsElement))
        }
        // cloneFlowsElement?.nodes.forEach((ele) => {
        //   if (ele.id?.toString() === target?.toString()) {
        //     let selectedFirstSecond=ele['content']['selectedFirstSecond'];
        //     if(selectedFirstSecond['datasource0'] === sourceElement?.)
        //     ele['content']['selectedFirstSecond'] = { ...ele['content']['selectedFirstSecond'], ...selectedFirstSecond }
        //   }
        // })
        // console.log(cloneFlowsElement)
        // dispatch(updateFlowsElements(cloneFlowsElement))


      } else {
        emitToastNotification(
          "info",
          `Maximum two input source allowed to connect transformer `
        );
      }

    } else if ((targetType === `filters`
      || targetType === `schema`
      || targetType === `aggregate`
      || targetType === `alert`
      || targetType === `target`
      || targetType === `scriptOutput`)
    ) {
      if (connectedEdgeCount < 1) {

        dispatch(updateFlowsElements(newFlowsElements))
      } else {
        emitToastNotification(
          "info",
          `Maximum one input source allowed to connect transformer `
        );
      }
    } else {
      dispatch(updateFlowsElements(newFlowsElements))
    }
  }
  const onConnect = (params) => {
    let source = params.source;
    let target = params.target;
    let newParams = {};
    newParams = {
      ...params,
    };

    let cloneFlowsElement = { ...flowsElements }
    let targetType = cloneFlowsElement.nodes.find((ele) => ele.id === target)?.type;
    let sourceType = cloneFlowsElement.nodes.find((ele) => ele.id === source)?.type;

    let sourceStatus;
    let transformerProgress =
      store.getState().Lab.transformerProgress;

    if (transformerProgress && transformerProgress?.length) {
      sourceStatus = transformerProgress.find((element) => {
        return element.crnt_transformer === source;
      })?.state;
    }

    let sourceElement = cloneFlowsElement.nodes.find((ele) => ele.id?.toString() === source?.toString());

    if (
      sourceStatus?.toLowerCase() !== 'running'
    ) {
      if (sourceType !== 'script') {
        let newEdge = addEdge(newParams, cloneFlowsElement?.edges);
        cloneFlowsElement['edges'] = newEdge;
        edgeConnectionAllow(targetType, target, cloneFlowsElement, sourceElement)
      }

    } else if (!sourceStatus || sourceStatus !== "success") {
      emitToastNotification(
        "info",
        `The source transformer is in a ${sourceStatus ? sourceStatus : "Initial"
        } state, so it can't connect.`
      );
    }

  };

  const onLoad = (instance) => {
    setTimeout(() => {

      instance.fitView()
    });

    setReactFlowInstance(instance);


  }
  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onNodeDragStop = (event, node) => {
    if (node && node.id) {
      let newFlowsElements = { ...flowsElements }
      let updatedPositionElements = newFlowsElements?.nodes.map((ele) => {
        if (ele.id === node.id) {
          ele.position = node.position;
        }
        return ele;
      });
      newFlowsElements['nodes'] = updatedPositionElements;
      updateLocalState(newFlowsElements)
      dispatch(updateFlowsElements(newFlowsElements));
    }
  };



  const onElementClick = (event, element) => {

    let newFlowsElements = { ...flowsElements }
    let currentElements = newFlowsElements?.nodes.find((item) => item.id === element.id);
    addSelectedTransformerClass(currentElements?.id, currentElements?.type)
    dispatch(setFlowsCurrenttransformer(currentElements));

    setEdges((eds) => eds.map((e) => {
      if (e?.target?.toString() === element?.id?.toString() || e?.source?.toString() === element?.id?.toString()) {
        e.style = { stroke: "#2c83c4", strokeWidth: "2" }
      }
      else {
        e.style = { stroke: "", strokeWidth: "" }; e.type = ''
      }
      return e
    }))

  };

  const onDrop = (event) => {

    event.preventDefault();
    const type = event.dataTransfer.getData("application/reactflow");
    // const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
    const position = reactFlowInstance.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    }); // reactFlowInstance.project({ x: event.clientX - left - 75, y: event.clientY - top })
    const newNode = {
      id: getId().toString(),
      type,
      position,
      content: {},
      transformerName: `${type}_${getId().toString()}`,// ${new Date().getTime()}`,
    };
    if (newNode.id !== undefined && newNode.type !== "") {

      let temp = { ...flowsElements };

      let transformerNameList = store.getState().Lab.transformerName;
      transformerNameList[
        newNode.id
      ] = `${type}_${getId().toString()}`//_${new Date().getTime()}`;
      dispatch(setTransformerNames({ ...transformerNameList }));
      if (type.toLowerCase() === "script") {
        store.dispatch(resetScriptRedux());
      }
      temp['nodes'] = [...temp?.nodes?.concat(newNode)]

      dispatch(updateFlowsElements(temp));
      dispatch(setTransformerMaxId(getId()));
    }
  };
  const removeSelectedClass = () => {
    let reactNodeDiv = document.getElementsByClassName("react-flow__node");
    if (reactNodeDiv && reactNodeDiv.length) {
      for (let index = 0; index < reactNodeDiv.length; index++) {
        if (reactNodeDiv[index]?.classList.contains("selected")) {
          reactNodeDiv[index].classList.remove("selected");
        }
      }
    }
  };
  const onPaneClick = () => {
    removeSelectedClass();
    setEdges((eds) => eds.map((e) => { e.style = { stroke: "", strokeWidth: "" }; e.type = ''; return e }));


    dispatch(setFlowsCurrenttransformer({}));

    dispatch(showFlowsTransformdetails(false));

  };
  const handleTransform = () => {

    if (reactFlowInstance && reactFlowInstance !== null) {
      reactFlowInstance.zoomOut();
      reactFlowInstance.fitView({ padding: 0.25, includeHiddenNodes: true });
    }
  };
  const onEdgeClick = useCallback((_, edge) => {

    if (!edge?.id?.includes('edge|')) {
      setEdges((eds) => eds.map((e) => {
        if (e.id === edge.id) { e.style = { stroke: "red", strokeWidth: "1" }; e.type = 'buttonedge' } else { e.style = { stroke: "", strokeWidth: "" }; e.type = '' }
        return e
      }))
    }

  }, []);

  const onNodeContextMenu = (event, element) => {
    // Prevent native context menu from showing
    event.preventDefault();

    // Calculate position of the context menu. We want to make sure it
    // doesn't get positioned off-screen.
    let newFlowsElements = { ...flowsElements }
    let currentElements = newFlowsElements?.nodes.find((item) => item.id === element.id);

    addSelectedTransformerClass(currentElements?.id, currentElements?.type)
    dispatch(setFlowsCurrenttransformer(currentElements));

    setEdges((eds) => eds.map((e) => {
      if (e?.target?.toString() === element?.id?.toString() || e?.source?.toString() === element?.id?.toString()) {
        e.style = { stroke: "#2c83c4", strokeWidth: "2" }
      }
      else {
        e.style = { stroke: "", strokeWidth: "" }; e.type = ''
      }
      return e
    }))


  }





  return (
    <ReactFlow
      ref={reactFlowWrapper}

      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeDragStop={onNodeDragStop}
      onConnect={onConnect}
      onInit={onLoad}
      onPaneClick={onPaneClick}
      onDrop={onDrop}
      nodeTypes={nodeTypes}
      snapToGrid
      fitView={true}

      onEdgeClick={onEdgeClick}
      viewportinitialized={"true"}
      edgeTypes={edgeTypes}
      onDragOver={onDragOver}
      onNodeClick={onElementClick}

      onNodeContextMenu={onNodeContextMenu}
      nodesDraggable={isViewMode ? false : true}

      elementsSelectable={isViewMode ? false : true}
      // defaultViewport={defaultViewport}
      minZoom={0.5}
      maxZoom={1.5}

      nodes={nodes} edges={edges}
    >

      <MiniMap style={minimapStyle} zoomable pannable position="top-right" />
      <Background gap={30} color="#2c834" variant="dots" />

      <Controls style={{ display: "flex", flexDirection: "row" }} onFitView={(bound) => { console.log(bound) }} />
    </ReactFlow>

  );
}
const RightSideUpperContent = (props) => {

  const { isViewMode } = props;

  const searchParams = new URLSearchParams(document.location.search);

  const history_id = searchParams.get("history_id");
  return (<>
    <ReactFlowProvider>
      {!isViewMode ? <Menubar /> : null}
      <div
        className={`reactflow-wrapper flow-canvas ${history_id ? "hide-transformer" : ""
          }`}
        style={{ height: "calc(100vh - 33px)", width: "100%" }}
      >
        <Flow {...props} />

      </div>
      <LowerMenubar />
    </ReactFlowProvider></>)

};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.Lab.flowsElements,
    currentTransformer: state.Lab.currentTransformer,
    showTransformDetails: state.Lab.showTransformDetails,
    saveFlowClick: state.Lab.saveFlowClick,
    edges: state.Lab.edges,
    scriptOutputDataset: state.LabTransformer.Script.scriptOutputDataset,
    bricksOutputDataset: state.LabTransformer.Script.bricksOutputDataset,
  };
};
export default connect(mapStateToProps)(RightSideUpperContent);
